import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowRightIcon,
  ChatBubbleIcon,
  PlugConnectedIcon,
  SvgIcon,
} from '@teikametrics/tm-design-system';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { useDataSyncInfoContext } from '../../containers/dataSyncInfoProvider/dataSyncInfoProvider';
import { SubscriptionContext } from '../../containers/subscriptionProvider';
import {
  getCurrentAccountFromContext,
  hasPaymentInfo,
  isAIPlanEnabled,
  isSelfServiceAccount,
} from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import I18nKey from '../../lib/types/I18nKey';
import { EntityType } from '../../modules/account/containers/utils';
import { NavPaths } from '../../NavPaths';
import RiskFreeTrialModal from '../BillingModals/RiskFreeTrialModal/RiskFreeTrialModal';
import {
  EmptyBannerStatus,
  getEmptyBannerStatus,
} from '../EmptyStateBanner/emptyBannerStatus';
import { CTABanner } from './CTABanner';
import { Hero } from './Hero';
import {
  CHAT_LINK,
  HELP_LINK,
  UseMerchantSyncingReturnProps,
  useEmptyStateData,
} from './hooks';
import { Propositions } from './Propositions';
import { RiskFreeTrialHero } from './RiskFreeTrialHero';
import { PageType } from './types';
import {
  PageHeaderContext,
  PageHeaderContextState,
} from '../../containers/pageHeaderProvider';
import { EmptyStateV2 } from './V2';
import { FlywheelSalesChannel, MetadataTags } from '../../lib/types/Fam';

interface OwnProps {
  title: string;
  isInSync?: boolean;
  pageType: PageType;
  merchantsSyncing: UseMerchantSyncingReturnProps;
  canSeeFreeTrial?: boolean;
  readonly useV2?: boolean;
  readonly riskFreeTrialInit?: () => void;
  readonly riskFreeModal?: JSX.Element;
}

const EmptyState: React.FC<OwnProps> = ({
  title,
  isInSync = false,
  pageType,
  merchantsSyncing,
  canSeeFreeTrial,
  useV2 = false,
  riskFreeTrialInit,
  riskFreeModal,
}) => {
  const isProductConnected = merchantsSyncing.isProductConnected;
  const isAdsConnected = merchantsSyncing.isAdsConnected;
  const pageData = useEmptyStateData(
    pageType,
    isProductConnected,
    isAdsConnected
  );

  const userContext = useContext<UserContextState>(UserContext);

  const dataSyncInfo = useDataSyncInfoContext();
  const { salesChannelData } = useContext(SubscriptionContext);

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [showRiskFreeTrial, setShowRiskFreeTrial] = useState(false);

  const emptyBannerStatus = getEmptyBannerStatus({
    dataSyncInfo,
    salesChannelData,
  });

  const hasGivenPayment = hasPaymentInfo(userContext);
  const currentAccount = getCurrentAccountFromContext(userContext);
  const isAiEnabled = isAIPlanEnabled(userContext);
  const isSelfService = isSelfServiceAccount(currentAccount);

  const [hideFreeTrialContent, setHideFreeTrialContent] = useState<boolean>();

  useEffect(() => {
    const hideFreeTrialTag = userContext.accountMetadata?.find(
      (tag) => tag.tag === MetadataTags.HideFreeTrialContent
    );
    setHideFreeTrialContent(hideFreeTrialTag?.value === 'enabled');
  }, [userContext.accountMetadata]);

  const canSeeRiskFreeTrial =
    !hideFreeTrialContent ??
    canSeeFreeTrial ??
    (isInSync && !hasGivenPayment && !isAiEnabled && isSelfService);

  const CTA_LINK_CONFIG: Record<
    string,
    { entity?: EntityType; salesChannel?: FlywheelSalesChannel }
  > = {
    [EmptyBannerStatus.AdsOnlyAmazonSyncing]: {
      entity: EntityType.Products,
      salesChannel: FlywheelSalesChannel.Amazon,
    },
    [EmptyBannerStatus.AdsOnlyWalmartSyncing]: {
      entity: EntityType.Products,
      salesChannel: FlywheelSalesChannel.Walmart,
    },
    [EmptyBannerStatus.AmazonAdsNoProducts]: {
      entity: EntityType.Products,
      salesChannel: FlywheelSalesChannel.Amazon,
    },
    [EmptyBannerStatus.WalmartAdsNoProducts]: {
      entity: EntityType.Products,
      salesChannel: FlywheelSalesChannel.Walmart,
    },
  };

  const [
    PROPOSITIONS_TITLE,
    BANNER_LINK_LABEL,
    BANNER_CONNECT_BUTTON_LABEL,
    BANNER_CHAT_BUTTON_LABEL,
    PRODUCT_CATALOG_RISK_FREE_BANNER_TITLE,
    PRODUCT_CATALOG_RISK_FREE_BANNER_SUBTITLE,
    GET_STARTED_DESCRIPTION,
    RISK_FREE_TRIAL_BANNER_TITLE,
    RISK_FREE_TRIAL_BANNER_SUBTITLE,
  ] = [
    I18nKey.EMPTY_STATES_PROPOSITIONS_TITLE,
    I18nKey.EMPTY_STATES_BANNER_LINK_LABEL,
    I18nKey.EMPTY_STATES_BANNER_CONNECT_BUTTON_LABEL,
    I18nKey.EMPTY_STATES_BANNER_CHAT_BUTTON_LABEL,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_RISK_FREE_BANNER_TITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_RISK_FREE_BANNER_SUBTITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_RISK_FREE_BANNER_GET_STARTED_DESCRIPTION,
    I18nKey.EMPTY_STATES_RISK_FREE_TRIAL_BANNER_TITLE,
    I18nKey.EMPTY_STATES_RISK_FREE_TRIAL_BANNER_SUBTITLE,
  ].map((id) =>
    intl.formatMessage<ReactNode>(
      { id },
      {
        b: (text) => <b>{text}</b>,
      }
    )
  );

  const ctaBannerButtonProps: {
    label: React.ReactNode;
    icon: SvgIcon;
    iconPosition: 'left' | 'right';
  } = useMemo(() => {
    if (pageType === PageType.BusinessIntelligence) {
      return {
        label: BANNER_CHAT_BUTTON_LABEL,
        icon: ChatBubbleIcon,
        iconPosition: 'left',
      };
    }

    if (canSeeRiskFreeTrial || pageType === PageType.BudgetPacing) {
      return {
        label: GET_STARTED_DESCRIPTION,
        icon: ArrowRightIcon,
        iconPosition: 'right',
      };
    }

    return {
      label: BANNER_CONNECT_BUTTON_LABEL,
      icon: PlugConnectedIcon,
      iconPosition: 'left',
    };
  }, [pageType, isInSync]);

  const getBannerTitleProps = useMemo(() => {
    if (canSeeRiskFreeTrial) {
      if (
        [
          PageType.AIRecommendedKeywords,
          PageType.KeywordTargetActions,
          PageType.CampaignCreator,
          PageType.BusinessIntelligence,
        ].includes(pageType)
      ) {
        return {
          title: RISK_FREE_TRIAL_BANNER_TITLE,
          subtitle: RISK_FREE_TRIAL_BANNER_SUBTITLE,
        };
      }

      return {
        title: PRODUCT_CATALOG_RISK_FREE_BANNER_TITLE,
        subtitle: PRODUCT_CATALOG_RISK_FREE_BANNER_SUBTITLE,
      };
    }

    return {
      title: pageData.banner.title,
      subtitle: pageData.banner.subtitle,
    };
  }, [isInSync, pageType]);

  const handleHeroCTAClick = () => {
    if (pageType === PageType.BusinessIntelligence) {
      window.open(CHAT_LINK, '_blank');
    } else {
      const { salesChannel } = CTA_LINK_CONFIG[emptyBannerStatus] || {};

      navigate(
        `${NavPaths.SalesChannels}?scsRedirect=${location.pathname}${
          salesChannel ? `&channel=${salesChannel}` : ''
        }`
      );
    }
  };

  const handleBannerCTAClick = () => {
    if (pageType === PageType.BusinessIntelligence) {
      window.open(CHAT_LINK, '_blank');
    } else if (
      (isInSync && !hasGivenPayment && !isAiEnabled) ||
      pageType === PageType.BudgetPacing
    ) {
      setShowRiskFreeTrial(true);
    } else {
      navigate(
        `${NavPaths.SalesChannels}?scsRedirect=${location.pathname}&connect=1`
      );
    }
  };

  const { updatePageHeaderData } =
    useContext<PageHeaderContextState>(PageHeaderContext);

  useEffect(() => {
    updatePageHeaderData({
      title: title,
      dataTestId: 'empty_state',
    });
  }, []);

  return useV2 ? (
    <EmptyStateV2
      title={title}
      pageType={pageType}
      merchantsSyncing={merchantsSyncing}
      riskFreeTrialInit={riskFreeTrialInit}
      riskFreeModal={riskFreeModal}
    />
  ) : (
    <div className="relative">
      {canSeeRiskFreeTrial ? (
        <RiskFreeTrialHero
          title={pageData.hero.title}
          onStart={() => setShowRiskFreeTrial(true)}
        />
      ) : (
        <Hero
          icon={pageData.hero.icon}
          title={pageData.hero.title}
          subtitle={pageData.hero.subtitle}
          buttonProps={
            !isInSync
              ? {
                  icon: pageData.hero.buttonIcon,
                  label: pageData.hero.buttonLabel,
                  onClick: handleHeroCTAClick,
                }
              : undefined
          }
        />
      )}
      <Propositions
        title={PROPOSITIONS_TITLE}
        propositions={pageData.propositions}
      />
      <div className="mb-40">
        <CTABanner
          {...getBannerTitleProps}
          textLinkProps={{
            label: BANNER_LINK_LABEL,
            href: HELP_LINK,
          }}
          buttonProps={{
            ...ctaBannerButtonProps,
            onClick: handleBannerCTAClick,
          }}
        />
      </div>
      <img
        src={pageData.bgImage}
        alt="bg"
        className="absolute left-0 right-0 top-0 z-m1"
      />
      {showRiskFreeTrial && (
        <RiskFreeTrialModal
          onCancelClicked={() => setShowRiskFreeTrial(false)}
        />
      )}
    </div>
  );
};

EmptyState.displayName = 'EmptyState';
export default EmptyState;
