import './error.scss';

import React from 'react';

import { ReactComponent as WarningIcon } from './img/infinite-table-warning.svg';

interface TableErrorProps {
  readonly tryAgainClickHandler: () => void;
}

const Error: React.FC<TableErrorProps> = (props) => (
  <div className="table__error">
    <WarningIcon />
    <p>Data could not be loaded</p>
    <button
      onClick={props.tryAgainClickHandler}
      className="flywheel"
      data-test-id="tryAgain"
    >
      Try Again
    </button>
  </div>
);
Error.displayName = 'Error';
export default Error;
