import { StringMap } from '../../../lib/types';
import { Sort } from '../../../lib/types/Sort';
import { Filter } from '../../../lib/types/Filter';
import { IdentifiedAsyncRequest } from '../../../lib/utilities/asyncRequest';

export enum FullCountLoadingState {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
}

export interface TotalCount {
  readonly totalCount?: number;
  readonly loadingTotalCount?: boolean;
  readonly loadingState?: FullCountLoadingState;
}

export interface BaseTable<T> {
  // Required data used for calls to paginate data
  readonly sorts: Sort[];
  readonly filters: Filter[];
  readonly extraPaginationParams?: StringMap<string>;
  readonly extraQueryParams?: StringMap<string>;

  // Currently loaded pages for the table
  readonly pages: Array<IdentifiedAsyncRequest<T[], void>>;
  readonly itemsPerPage: number;
  readonly visiblePage: number;
  // Total items for the table, is optional because we do not know until after
  // the first page of data is fetched. This data is returned on every request
  // for paginated data. It represents the total items available for the given
  // combination of filters + extraPaginationParams that are passed within the
  // request
  readonly maybeTotalItems?: number;
  readonly changes: TableChange;
  readonly totalCount?: TotalCount;
  readonly lastSyncedAt?: string;
  // The list of buffer records to be loaded and kept at any point of time.
  // This should be refreshed just like when a refreshed (sort/filter/page change etc...)
  // Additionally this should be refreshed when an item is taken out from this.
  // And this would be basically always the next page data with eventual consistency w.r.t APIs
  readonly bufferItems?: T[];
  // Default to 0
  // If this buffer needs to be used. Then bufferItemsToLoad need to be updated during initialization of table.
  readonly bufferItemsToLoad?: number;
  readonly toggleBufferItemsChanged?: boolean;
}

// Used for a table with aggregationRow on the top
export interface TableWithAggregation<T, A> extends BaseTable<T> {
  readonly aggregationData?: IdentifiedAsyncRequest<A, void>;
  readonly additionalAggregationData?: IdentifiedAsyncRequest<A, void>;
}

export type Table<T, A> = BaseTable<T> | TableWithAggregation<T, A>;

export interface TableReducerState<T, A = void> {
  readonly [key: string]: Table<T, A>;
}

export interface WithTable<T, A = void> {
  readonly tableState: TableReducerState<T, A>;
}

interface TableId {
  readonly tableId: string;
}

export interface SetVisiblePagePayload extends TableId {
  readonly page: number;
}

export interface DataResetPayload extends TableId {}

export interface DataResetAggregationHeaderPayload extends TableId {}

export interface DataLoadCompletePayload<T> extends TableId {
  readonly items: T[];
  readonly totalItems: number;
  readonly lastSyncedAt?: string;
  readonly page: number;
  readonly requestId: string;
}

export interface DataLoadBufferCompletePayload<T> extends TableId {
  readonly items: T[];
  readonly requestId: string;
}

export interface ClearBufferPayload extends TableId {}

export interface SortAppliedPayload extends TableId {
  readonly sort: Sort;
}

export interface SortRemovedPayload extends TableId {
  readonly column: string;
}

export interface UpdateFiltersPayload extends TableId {
  readonly filters: Filter[];
  readonly replace: boolean;
}

export interface ClearFilterPayload extends TableId {
  readonly field: string;
}

export interface ClearSingleFilterOfMultiSelectionFiltersPayload
  extends TableId {
  readonly field: string;
  readonly value: string;
}

export interface ClearFiltersPayload extends TableId {}

export interface UpdateExtraPaginationParamsPayload extends TableId {
  readonly extraPaginationParams?: StringMap<string>;
}

export interface DataLoadInitiatedPayload extends TableId {
  readonly requestId: string;
  readonly page: number;
}

export interface DataLoadFailedPayload extends TableId {
  readonly requestId: string;
  readonly page: number;
}

export interface AggregationDataLoadInitiatedPayload extends TableId {
  readonly requestId: string;
}

export interface AggregationDataLoadCompletePayload<A> extends TableId {
  readonly requestId: string;
  readonly aggregationData: A;
}

export interface AggregationDataLoadFailedPayload extends TableId {
  readonly requestId: string;
}

export enum MissingRequiredField {
  MerchantCountries = 'MissingMerchantCountries',
  CurrencyCode = 'CurrencyCode',
  TimeFrame = 'TimeFrame',
}

export interface AdditionalAggregationDataLoadInitiatedPayload extends TableId {
  readonly requestId: string;
}

export interface AdditionalAggregationDataLoadCompletePayload<A>
  extends TableId {
  readonly requestId: string;
  readonly additionalAggregationData: A;
}

export interface AdditionalAggregationDataLoadFailedPayload extends TableId {
  readonly requestId: string;
}

export interface UpdateExtraQueryParamsPayload extends TableId {
  readonly extraQueryParams?: StringMap<string>;
}

export interface SetItemsPerPagePayload extends TableId {
  readonly itemsPerPage: number;
}

export type TableCell = {
  value: string;
  isValid?: boolean;
  isDirty?: boolean;
};

export type TableCellChange = {
  [key: string]: Record<string, TableCell>;
};

export interface TableChange {
  readonly cell: TableCellChange;
}

export interface UpdateCellPayload {
  readonly tableId: string;
  readonly rowId: string;
  readonly columnName: string;
  readonly value: string;
  readonly existingValue: string;
  readonly isValid: boolean;
  readonly numericValue?: boolean;
  readonly isDirty?: boolean;
}

export interface UpdateTableCellPayload {
  readonly tableId: string;
  readonly rowId: string;
  readonly columnName: string;
  readonly value: any;
}

export interface ClearAllChangesPayload {
  readonly tableId: string;
}

export interface PercentageBasedColumnInfo {
  readonly columnName: string;
  readonly maxDecimalDigits: number;
}

export interface ApplyBulkChangesPayload {
  readonly tableId: string;
  readonly bulkChanges: Record<string, any>;
}

export interface TotalCountPayload {
  readonly tableId: string;
  readonly requestId: string;
}

export interface TotalCountSuccessPayload {
  readonly tableId: string;
  readonly totalCount: number;
  readonly requestId: string;
}

export interface TotalCountFailurePayload {
  readonly tableId: string;
  readonly error: string | null;
  readonly requestId: string;
}

export enum TableDisplayState {
  DataLoadNotStarted = 'DataLoadNotStarted',
  LoadingInitialData = 'LoadingInitialData',
  LoadingAdditionalData = 'LoadingAdditionalData',
  Error = 'Error',
  Data = 'Data',
  NoData = 'NoData',
  FilteredWithNoData = 'FilteredWithNoData',
}

export const INITIAL_LOAD_KEY = 'initialLoad';

export interface PatchItemPayload<T> {
  readonly tableId: string;
  readonly itemIdentifier: (item: T) => boolean;
  readonly changes: Record<string, any>;
}

export interface DeleteItemPayload<T> {
  readonly tableId: string;
  readonly itemIdentifier: (item: T) => boolean;
}
