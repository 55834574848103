import {
  AdgroupActionType,
  ArrowsNextIcon,
  BadgeDisplayMode,
  BadgeType,
  CrossIcon,
  Icon,
  IconSize,
  SuggestedAdgroup,
  SuggestedAdgroupCampaign,
} from '@teikametrics/tm-design-system';
import {
  AdGroupInfoV2,
  CampaignInfoWithAdGroups,
  TargetingType,
} from '../../../../../lib/types/KeywordAction';
import noop from 'lodash/noop';
import { getTargetSegmentPills } from '../utils/keywordUtils';
import React from 'react';
import classNames from 'classnames';

export interface CampaignInfoWithAdgroupOptionProps {
  readonly campaign: CampaignInfoWithAdGroups;
  readonly selectable?: boolean;
  readonly showRemoveButton?: boolean;
  readonly selectionMap?: Record<string, CampaignInfoWithAdGroups>;
  readonly onChange?: (
    campaign: CampaignInfoWithAdGroups,
    adGroupId: string,
    selected?: boolean
  ) => void;
  readonly minimizePadding?: boolean;
}

const getIsAdgroupSelected = (
  selectionMap: Record<string, CampaignInfoWithAdGroups> | undefined,
  campaign: CampaignInfoWithAdGroups,
  adGroup: AdGroupInfoV2
) => {
  if (!selectionMap?.[campaign.id]) {
    return false;
  }
  return (
    (selectionMap?.[campaign.id]?.selectedAdGroupIds || []).findIndex(
      (id) => id === adGroup.id
    ) !== -1
  );
};

export const CampaignWithAdgroupOption: React.FC<
  CampaignInfoWithAdgroupOptionProps
> = ({
  campaign,
  selectable,
  showRemoveButton,
  onChange = noop,
  selectionMap = {},
  minimizePadding,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col bg-white p-16 border border-solid rounded border-grey-200',
        {
          'gap-12 p-8': !minimizePadding,
        }
      )}
    >
      <SuggestedAdgroupCampaign
        campaignName={campaign.name}
        targetingType={{
          badgeType:
            campaign?.targetingType === TargetingType.Auto
              ? BadgeType.CampaignTypeAuto
              : BadgeType.CampaignTypeManual,
          badgeNameDisplayMode: BadgeDisplayMode.OnlyInitial,
        }}
      />
      {campaign.adGroups.map((adGroup) => (
        <div
          key={adGroup.id}
          className="flex flex-row flex-grow items-start px-8"
        >
          <Icon
            size={IconSize.Medium}
            svg={ArrowsNextIcon}
            className={classNames('self-start text-grey-400', {
              'mt-8': selectable,
            })}
          />
          <SuggestedAdgroup
            action={
              selectable ? AdgroupActionType.Checkbox : AdgroupActionType.None
            }
            dataTestId={'create_keywords_adgroup'}
            showBorder={selectable}
            classname="w-auto flex-grow min-w-0"
            isSelected={
              selectable &&
              getIsAdgroupSelected(selectionMap, campaign, adGroup)
            }
            adGroupName={adGroup.name}
            onClick={() =>
              selectable &&
              onChange(
                campaign,
                adGroup.id,
                !getIsAdgroupSelected(selectionMap, campaign, adGroup)
              )
            }
            targetSegments={getTargetSegmentPills(adGroup.targetSegments)}
          />
          {showRemoveButton && (
            <Icon
              size={IconSize.Small}
              svg={CrossIcon}
              onClick={() => onChange(campaign, adGroup.id, false)}
              className={classNames('text-grey-400 cursor-pointer', {
                'mt-8': selectable,
              })}
            />
          )}
        </div>
      ))}
    </div>
  );
};
