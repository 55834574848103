import { fromNullable } from 'fp-ts/lib/Option';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';

import {
  Alignment,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Icon,
  IconSize,
  Placement,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import {
  tableSelectors,
  tableThunks,
} from '../../../../containers/tableV2/ducks';
import {
  TableDisplayState,
  WithTable,
} from '../../../../containers/tableV2/ducks/types';
import { FlywheelTableV2 } from '../../../../containers/tableV2/flywheelTable';
import {
  getCurrentAccountFromContext,
  getUserRoleMatch,
  isManagedOrAgencyAccount,
} from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import {
  FlywheelSalesChannel,
  Role,
  SalesChannelData,
} from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  AMAZON_FLASH_MODAL_SCREENS,
  AmazonFlashScreens,
} from '../../components/AmazonModalSteps/amazonFlashScreens';
import { SalesChannel } from '../../components/connectSalesChannelModal';
import { RowDataProps } from '../../components/types';
import {
  WALMART_FLASH_MODAL_SCREENS,
  WalmartFlashScreens,
} from '../../components/WalmartModalSteps/walmartFlashScreens';
import { AccountThunkDispatch } from '../../redux/types';
import { EntityType } from '../utils';
import { GetAccountListColumnsDefinition } from './tableColumns/columns';
import { SALES_CHANNELS_TABLE } from './types';

import { dataFetcherSalesChannelsTable } from './utils/hooks';

import './salesChannelsTable.scss';
import { ViewerRoleTooltip } from '../../../../lib/utilities/viewerRoleTooltipWrapper';

interface SalesChannelsTableProps {
  readonly accountId: string;
  readonly selectedSalesChannel: SalesChannel;
  readonly aoSubscribed: boolean | undefined;
  readonly isStepsModalOpen: boolean;
  readonly entity?: string;
  readonly notOwnedMerchant?: RowDataProps[];
  readonly connectedMerchantIds: string[];
  readonly userRole?: Role;
  readonly openConnectionInitialModal: () => void;
  readonly closeConnectionStepsModal: (openInitialModal?: boolean) => void;
}

const SalesChannelsTable: React.FC<SalesChannelsTableProps> = ({
  accountId,
  selectedSalesChannel,
  aoSubscribed,
  isStepsModalOpen,
  openConnectionInitialModal,
  entity = EntityType.Ads,
  notOwnedMerchant,
  connectedMerchantIds,
  userRole,
  closeConnectionStepsModal,
}) => {
  const ref = useRef<boolean>(false);
  const dispatch = useDispatch<AccountThunkDispatch>();
  const userContext = React.useContext<UserContextState>(UserContext);

  const isAmazon = selectedSalesChannel.name === FlywheelSalesChannel.Amazon;
  const isWalmart = selectedSalesChannel.name === FlywheelSalesChannel.Walmart;
  const isUserRoleViewOnly = getUserRoleMatch(
    Role.VIEW_ONLY,
    userContext.userInfo.userDetails
  );

  const getWalmartFlashScreen = (entity: string) =>
    entity === EntityType.Products
      ? WALMART_FLASH_MODAL_SCREENS.WMTSelectMerchantTypeScreen
      : WALMART_FLASH_MODAL_SCREENS.WMTInitialConnectScreen;

  const getAmazonFlashScreen = (entity: string) =>
    entity === EntityType.Products
      ? AMAZON_FLASH_MODAL_SCREENS.AMZAmazonProductModalScreen
      : AMAZON_FLASH_MODAL_SCREENS.AMZInitialConnectScreen;

  const refreshTable = () =>
    dispatch(
      tableThunks.refreshTable(
        SALES_CHANNELS_TABLE,
        dataFetcherSalesChannelsTable(
          userContext,
          accountId,
          selectedSalesChannel.id,
          selectedSalesChannel.name
        )
      )
    );

  useEffect(() => {
    if (ref.current) {
      refreshTable();
    } else {
      ref.current = true;
    }
  }, [selectedSalesChannel.id]);

  const closeAddConnectionModal = (openInitialModal?: boolean) => {
    closeConnectionStepsModal(openInitialModal);
    refreshTable();
  };

  const getFlashScreen = () => {
    if (isWalmart) {
      return (
        <WalmartFlashScreens
          initialScreen={getWalmartFlashScreen(entity)}
          salesChannel={selectedSalesChannel}
          refreshTable={refreshTable}
          onClose={closeAddConnectionModal}
        />
      );
    }
    if (isAmazon) {
      return (
        <AmazonFlashScreens
          initialScreen={getAmazonFlashScreen(entity)}
          refreshTable={refreshTable}
          connectedMerchantIds={connectedMerchantIds}
          notOwnedMerchant={notOwnedMerchant}
          onClose={closeAddConnectionModal}
        />
      );
    }
  };

  const renderSelectConnectionModal = () => {
    if (!isStepsModalOpen) return null;
    return getFlashScreen();
  };

  const currentAccount = getCurrentAccountFromContext(userContext);
  const showAutomationColumn =
    isManagedOrAgencyAccount(currentAccount) &&
    userRole != null &&
    userRole !== Role.EDITOR;

  const columns = GetAccountListColumnsDefinition(
    showAutomationColumn,
    selectedSalesChannel.name
  );

  const TABLE_ROW_DATA_FETCHER = dataFetcherSalesChannelsTable(
    userContext,
    accountId,
    selectedSalesChannel.id,
    selectedSalesChannel.name
  );

  const DEFAULT_CURRENCY = 'USD';

  const totalItems = useSelector<WithTable<SalesChannelData>, number>(
    ({ tableState }) =>
      fromNullable(
        tableSelectors.getTotalItemsSelector()(tableState, SALES_CHANNELS_TABLE)
      )
        .chain((items) => items)
        .getOrElse(0)
  );

  const displayState = useSelector<
    WithTable<SalesChannelData>,
    TableDisplayState
  >(({ tableState }) =>
    tableSelectors.getTableDisplayStateSelector()(
      tableState,
      SALES_CHANNELS_TABLE
    )
  );

  return (
    <>
      {renderSelectConnectionModal()}
      {userRole && userRole !== Role.EDITOR && (
        <div className="flex flex-row items-center p-16">
          <div className="flex-1 flex flex-row gap-8 items-center">
            {(displayState === TableDisplayState.LoadingAdditionalData ||
              displayState === TableDisplayState.LoadingInitialData) && (
              <Skeleton height={30} width={124} />
            )}
            {displayState === TableDisplayState.Data && (
              <>
                <Icon
                  size={IconSize.Large}
                  svg={selectedSalesChannel.icon}
                  className="text-grey-400 group-hover:text-purple-500"
                />
                <Typography
                  size={TypographySize.base}
                  lineHeight={TypographyLineHeight.none}
                  className="text-grey-900"
                  weight={TypographyWeight.regular}
                >
                  <FormattedMessage
                    id={I18nKey.GENERIC_MERCHANT_COUNT}
                    values={{ merchantCount: totalItems }}
                  />
                </Typography>
              </>
            )}
          </div>
          <ViewerRoleTooltip
            position={{
              placement: Placement.Left,
              alignment: Alignment.Center,
            }}
            disabled={!isUserRoleViewOnly}
          >
            <Button
              label={
                <FormattedMessage
                  id={I18nKey.SALES_CHANNELS_ADD_CONNECTION_BUTTON}
                />
              }
              variant={ButtonVariant.Primary}
              size={ButtonSize.Large}
              state={
                isUserRoleViewOnly ? ButtonState.Disabled : ButtonState.Enabled
              }
              dataTestId={'ss_addMerchant'}
              onClick={openConnectionInitialModal}
            />
          </ViewerRoleTooltip>
        </div>
      )}
      <FlywheelTableV2
        columns={columns}
        tableId={SALES_CHANNELS_TABLE}
        dataFetcher={TABLE_ROW_DATA_FETCHER}
        tableData={{ aoSubscribed, isAmazon, isUserRoleViewOnly }}
        currencyCode={DEFAULT_CURRENCY}
        tableClassName="border-solid border-b-0 h-salesChannelTable"
        loadingAndEmptyStateClassName="pt-140 mb-20"
        rowIdKeyName="id"
        isTableActionRowDisabled={true}
      />
    </>
  );
};
SalesChannelsTable.displayName = 'SalesChannelsTable';
export default SalesChannelsTable;
