import { MerchantCountry } from '../../lib/types/Fam';
import { MAP_SALES_CHANNEL_ID_TO_NAME } from '../../lib/types/SalesChannels';
import { TreUploadMerchantCountry } from './index';

export const getTransformedTreUploadMCs = (
  merchantCountries: MerchantCountry[]
): TreUploadMerchantCountry[] => {
  return merchantCountries.map((eachMc) => ({
    merchantCountryId: eachMc.merchantCountryId,
    merchantCountryName: eachMc.merchantName,
    saleChannelId: eachMc.salesChannelId || '',
    salesChannelName: MAP_SALES_CHANNEL_ID_TO_NAME[eachMc.salesChannelId || ''],
    merchantType: eachMc.merchantType,
  }));
};
