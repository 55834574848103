import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { Icon, IconSize, LightningIcon } from '@teikametrics/tm-design-system';

export const UpgradeBanner: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={classNames(
        `flex items-center justify-center bg-blue-100 h-32 text-sm text-blue-700 
        font-semibold rounded border border-solid border-blue-200`,
        'mt-16'
      )}
    >
      <Icon size={IconSize.Medium} svg={LightningIcon} className="m-4" />
      {children}
    </div>
  );
};
UpgradeBanner.displayName = 'UpgradeBanner';
