/* eslint-disable max-statements */
import { isEmpty } from 'fp-ts/lib/Array';
import { fromNullable } from 'fp-ts/lib/Option';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import isArray from 'lodash/isArray';

import { DateTime } from 'luxon';
import {
  Ref,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  CheckboxCheckedState,
  CheckboxState,
  CheckboxWithTextInputProps,
  ORIGINAL_DATE_FORMAT,
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
  TextInputState,
  TooltipProps,
  TypedPortaledTooltipProps,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import {
  PercentageBasedColumnInfo,
  TableCellChange,
  TableChange,
  TableSelectSettings,
  WithTable,
} from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeCheckboxWithTextInputColumn,
  makeTruncatedTextLinkColumn,
  representSmartCampaign,
} from '../../../../../containers/table/utils/makeTableCells';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import {
  AdLevel,
  AdType,
  AdvertisingGoal,
  AiBiddingValues,
  CampaignDetails,
  CampaignStatus,
  CampaignTargetingType,
  EntityType,
  FlywheelSalesChannel,
  MerchantCountryCode,
  Portfolio,
  ValidateUniqueValuesRequest,
} from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import { MerchantCountry, MerchantType } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  BulkEditModalElement,
  BulkEditModalGroupedElement,
} from '../bulkEditModal';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import {
  AdLevelI8nKeyMapper,
  EditedRowInfo,
  TableDataAdsManager,
} from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  fetchNonAsciiCharacters,
  getIncompleteCampaignCount,
  getMaxLengthForCampaignName,
  getSelectedMerchantCountryId,
  getTooltipContentForArchivedEntity,
  getValidDateCount,
  hasOnlySpecialCharacters,
  isBulkEditModalDisabledForPlacementBidMultipliersAmazonSP,
  isCampaignAdFormatProductCollectionOrSpotlight,
  isCampaignStatusArchived,
  isInvalidCampaignName,
  isValidAscii,
  isValidCampaignNameCharacterLength,
} from '../utils';

import { ViewTrendLink } from '../../../components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../components/ViewTrends';
import { getAdsManagerDetailsQueryParams } from '../utils/queryParamsUtils';
import {
  getMerchantFromLocalStorage,
  getSalesChannelFromLocalStorage,
} from '../utils/storageUtils';
import {
  getCampaignsTableDataWithUpdatedBidOptimization,
  getValidAutomationStatusCount,
  getValidCampaignStatusCount,
} from './campaignNameHelpGuide';
import { transformAdvertisingGoal } from '../adgroupTableColumns/utils';

export const ARRAY_BASED_COLUMNS: string[] = [CAMPAIGNS_API_COLUMN_NAME.Group];

export const PERCENTAGE_BASED_COLUMNS: PercentageBasedColumnInfo[] = [
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier,
    maxDecimalDigits: PERCENTAGE_FRACTION_DIGITS,
  },
];

export const BOOLEAN_COLUMNS: string[] = [
  CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization,
  CAMPAIGNS_API_COLUMN_NAME.AutomationStatus,
];

export enum CampaignBulkEditModalValues {
  'CampaignStatus' = 'CampaignStatus',
  'EndDate' = 'EndDate',
  'AmazonBidOptimization' = 'AmazonBidOptimization',
  'PlacementInclusion' = 'PlacementInclusion',
  'PlacementBidMultipliers' = 'PlacementBidMultipliers',
  'AutomationStatus' = 'AutomationStatus',
  'MacsTarget' = 'MacsTarget',
  'MinBid' = 'MidBid',
  'MaxBid' = 'MaxBid',
  'PlatformBidMultipliers' = 'PlatformBidMultipliers',
  'DailyBudget' = 'DailyBudget',
  'LifetimeBudget' = 'LifetimeBudget',
  'BudgetRollover' = 'BudgetRollover',
  'AmazonBiddingStrategy' = 'AmazonBiddingStrategy',
  'PlacementBidMultipliersAmazonSP' = 'PlacementBidMultipliersAmazonSP',
  'PlacementBidMultipliersAmazonSB' = 'PlacementBidMultipliersAmazonSB',
  'Portfolio' = 'Portfolio',
  'AdvertisingGoal' = 'AdvertisingGoal',
}

export enum endDateOptions {
  SetEndDate = 'setEndDate',
  NoEndDate = 'noEndDate',
}

type BulkEditMenuValue =
  | typeof CampaignBulkEditModalValues.CampaignStatus
  | typeof CampaignBulkEditModalValues.EndDate
  | typeof CampaignBulkEditModalValues.AmazonBidOptimization
  | typeof CampaignBulkEditModalValues.PlacementInclusion
  | typeof CampaignBulkEditModalValues.PlacementBidMultipliers
  | typeof CampaignBulkEditModalValues.AutomationStatus
  | typeof CampaignBulkEditModalValues.MacsTarget
  | typeof CampaignBulkEditModalValues.MinBid
  | typeof CampaignBulkEditModalValues.MaxBid
  | typeof CampaignBulkEditModalValues.DailyBudget
  | typeof CampaignBulkEditModalValues.LifetimeBudget
  | typeof CampaignBulkEditModalValues.BudgetRollover
  | typeof CampaignBulkEditModalValues.PlatformBidMultipliers
  | typeof CampaignBulkEditModalValues.AmazonBiddingStrategy
  | typeof CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSP
  | typeof CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSB
  | typeof CampaignBulkEditModalValues.Portfolio;

interface VisibilityCriteria {
  readonly adType: AdType[];
  readonly salesChannel: FlywheelSalesChannel[];
}

export interface BulkEditMenuOption {
  readonly header?: I18nKey;
  readonly label: string;
  readonly value: BulkEditMenuValue;
  readonly elements: Array<BulkEditModalElement | BulkEditModalGroupedElement>;
  readonly visibilityCriteria: VisibilityCriteria;
  readonly disabled?: boolean;
  readonly tooltip?: TooltipProps;
}

export interface CampaignNameAdditionalHeaderProps {
  readonly isEditMode: boolean;
  readonly salesChannel: FlywheelSalesChannel | null;
  readonly adType: AdType | null;
  readonly merchantCountryCode: MerchantCountryCode;
  readonly portfolioNames: Portfolio[];
  readonly allMerchants: MerchantCountry[];
  readonly aiEnabled: boolean;
}

export const ADTYPES_ARRAY_SB_SBV: readonly AdType[] = [
  AdType.SponsoredBrands,
  AdType.SponsoredBrandsVideo,
];

export const ADTYPES_ARRAY_SP_SD_SB_SBV: readonly AdType[] = [
  AdType.SponsoredProducts,
  AdType.SponsoredDisplay,
  AdType.SponsoredBrands,
  AdType.SponsoredBrandsVideo,
];

const isBulkEditModalDisabledForPlacementBidMultipliers = (
  campaignsTableData: CampaignDetails[],
  filteredElementsForBulkEditModal:
    | (BulkEditModalElement | BulkEditModalGroupedElement)[]
    | undefined,
  selectSettings: TableSelectSettings,
  tableChange: TableChange,
  bulkMenuValue: CampaignBulkEditModalValues | null
) => {
  const selectedRowData = campaignsTableData.filter((data) =>
    selectSettings.rows.includes(data.campaignId)
  );
  const currentCampaignsTableData =
    getCampaignsTableDataWithUpdatedBidOptimization(
      selectedRowData,
      tableChange.cell
    );
  let eligibleCampaigns = currentCampaignsTableData.filter(
    ({ campaignDetails: { campaignAdFormat } }) =>
      isCampaignAdFormatProductCollectionOrSpotlight(campaignAdFormat)
  );

  if (
    bulkMenuValue ===
    CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSB
  ) {
    eligibleCampaigns = eligibleCampaigns.filter(
      ({ channelSettings: { bidOptimization } }) => bidOptimization === false
    );
  }

  if (!eligibleCampaigns.length) {
    return true;
  }

  return filteredElementsForBulkEditModal?.some(
    (element: BulkEditModalElement | BulkEditModalGroupedElement) => {
      return (element as BulkEditModalElement).validators.some(
        ({ validator }) => !validator((element as BulkEditModalElement).value)
      );
    }
  );
};

export const getIsBulkEditModalDisabled = ({
  bulkMenuValue,
  filteredElementsForBulkEditModal,
  endDateOption,
  campaignsTableData,
  selectSettings,
  tableChange,
  values,
}: {
  bulkMenuValue: CampaignBulkEditModalValues | null;
  filteredElementsForBulkEditModal:
    | (BulkEditModalElement | BulkEditModalGroupedElement)[]
    | undefined;
  endDateOption: string;
  campaignsTableData: CampaignDetails[];
  selectSettings: TableSelectSettings;
  tableChange: TableChange;
  values: string[];
}) => {
  if (bulkMenuValue === CampaignBulkEditModalValues.CampaignStatus) {
    // disable apply button if all selected campaign status is completed
    return !getValidCampaignStatusCount(campaignsTableData, tableChange);
  }

  if (bulkMenuValue === CampaignBulkEditModalValues.AutomationStatus) {
    return !getValidAutomationStatusCount(
      campaignsTableData,
      tableChange,
      selectSettings
    );
  }

  if (bulkMenuValue === CampaignBulkEditModalValues.PlacementBidMultipliers) {
    const isAnyOfManualOrAutoPlacementBidMultipliersValid =
      filteredElementsForBulkEditModal?.some(
        (element: BulkEditModalElement | BulkEditModalGroupedElement) => {
          return (element as BulkEditModalGroupedElement).elements.some(
            (singleElement: BulkEditModalElement) => {
              return singleElement.validators.every(({ validator }) =>
                validator(singleElement.value)
              );
            }
          );
        }
      );
    return !isAnyOfManualOrAutoPlacementBidMultipliersValid;
  } else if (bulkMenuValue === CampaignBulkEditModalValues.PlacementInclusion) {
    return false;
  } else if (
    bulkMenuValue === CampaignBulkEditModalValues.PlatformBidMultipliers
  ) {
    const isAnyOfTheMultipliersValid = filteredElementsForBulkEditModal?.some(
      (singleElement: BulkEditModalElement | BulkEditModalGroupedElement) => {
        return (singleElement as BulkEditModalElement).validators.every(
          ({ validator }) =>
            validator((singleElement as BulkEditModalElement).value)
        );
      }
    );
    return !isAnyOfTheMultipliersValid;
  } else if (
    bulkMenuValue ===
    CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSP
  ) {
    return isBulkEditModalDisabledForPlacementBidMultipliersAmazonSP(
      filteredElementsForBulkEditModal
    );
  } else if (
    bulkMenuValue ===
      CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSB ||
    bulkMenuValue === CampaignBulkEditModalValues.AmazonBidOptimization
  ) {
    return isBulkEditModalDisabledForPlacementBidMultipliers(
      campaignsTableData,
      filteredElementsForBulkEditModal,
      selectSettings,
      tableChange,
      bulkMenuValue
    );
  } else if (
    bulkMenuValue === CampaignBulkEditModalValues.MinBid ||
    bulkMenuValue === CampaignBulkEditModalValues.MaxBid
  ) {
    const validatorHelper = (
      singleElement: BulkEditModalElement | BulkEditModalGroupedElement
    ) => {
      return (singleElement as BulkEditModalElement).validators.every(
        ({ validator }) => {
          return validator((singleElement as BulkEditModalElement).value);
        }
      );
    };
    const isAnyOfTheBidValid =
      filteredElementsForBulkEditModal?.some(validatorHelper);

    const isAnyOfTheBidEmpty = filteredElementsForBulkEditModal?.some(
      (singleElement: BulkEditModalElement | BulkEditModalGroupedElement) => {
        return (singleElement as BulkEditModalElement).value === '';
      }
    );

    const areAllBidsValid =
      filteredElementsForBulkEditModal?.every(validatorHelper);
    return !(
      isAnyOfTheBidValid &&
      ((isAnyOfTheBidEmpty && !areAllBidsValid) ||
        (!isAnyOfTheBidEmpty && areAllBidsValid))
    );
  } else if (bulkMenuValue === CampaignBulkEditModalValues.EndDate) {
    return handleBulkMenuFoeEndDate({
      filteredElementsForBulkEditModal,
      endDateOption,
      campaignsTableData,
      selectSettings,
      tableChange,
      values,
    });
  } else {
    return filteredElementsForBulkEditModal?.some(
      (element: BulkEditModalElement | BulkEditModalGroupedElement) => {
        return (element as BulkEditModalElement).validators.some(
          ({ validator }) => !validator((element as BulkEditModalElement).value)
        );
      }
    );
  }
};

const handleBulkMenuFoeEndDate = ({
  filteredElementsForBulkEditModal,
  endDateOption,
  campaignsTableData,
  selectSettings,
  tableChange,
  values,
}: {
  filteredElementsForBulkEditModal:
    | (BulkEditModalElement | BulkEditModalGroupedElement)[]
    | undefined;
  endDateOption: string;
  campaignsTableData: CampaignDetails[];
  selectSettings: TableSelectSettings;
  tableChange: TableChange;
  values: string[];
}) => {
  switch (endDateOption) {
    case endDateOptions.NoEndDate:
      const validNoEndDateCount = getIncompleteCampaignCount(
        campaignsTableData,
        selectSettings,
        tableChange
      );

      return !validNoEndDateCount;
    case endDateOptions.SetEndDate:
      const endDateAllowedLimit = DateTime.fromFormat(
        values[0],
        ORIGINAL_DATE_FORMAT
      );
      const validEndDateCount = getValidDateCount(
        endDateAllowedLimit,
        campaignsTableData,
        selectSettings,
        tableChange
      );

      return !validEndDateCount;
    default:
      return filteredElementsForBulkEditModal?.some(
        (element: BulkEditModalElement | BulkEditModalGroupedElement) => {
          return endDateOption === endDateOptions.NoEndDate
            ? false
            : (element as BulkEditModalElement).validators.some(
                ({ validator }) =>
                  !validator((element as BulkEditModalElement).value)
              );
        }
      );
  }
};

const getSelectSettings = ({ tableState }: WithTable<CampaignDetails>) =>
  tableSelectors.getSelectInfoSelector()(
    tableState,
    ADS_MANAGER_CAMPAIGNS_TABLE_ID
  );

export const getTableSelector = ({ tableState }: WithTable<CampaignDetails>) =>
  tableSelectors.getChangeSelector()(
    tableState,
    ADS_MANAGER_CAMPAIGNS_TABLE_ID
  );

const isPendingField = (details: CampaignDetails) =>
  details?.pendingFields &&
  details?.pendingFields.includes(CAMPAIGNS_API_COLUMN_NAME.CampaignName);

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({
  isEditMode,
  adTypes,
  salesChannel,
  merchantType,
  selectedMerchantCountries,
  aoApiClient,
  updateEditedRecords,
  allMerchants,
  showSmartCampaign,
  selectedAdType,
  aiEnabled,
  merchantsWithAutomationEnabled,
  ...campaignDetails
}) => {
  // Base branch to enable edits for Walmart SV
  const intl = useIntl();
  const selectSettings = useSelector<
    WithTable<CampaignDetails>,
    TableSelectSettings
  >(getSelectSettings);

  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);
  const [
    isCampaignNameUniqueAcrossAllAdTypes,
    setIsCampaignNameUniqueAcrossAllAdTypes,
  ] = useState<boolean>(true);

  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.CampaignName
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const campaignStatus = campaignDetails.channelSettings.status;

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const existingValue =
    campaignDetails.campaignDetails.campaignName?.toString() || '';

  const campaignName = changedValue ?? existingValue;

  const [value, setValue] = useState<string>(campaignName);
  const userContext = useContext<UserContextState>(UserContext);
  const userId = userContext.userInfo.userDetails?.id || '';
  const accountId = getCurrentAccountFromContext(userContext)?.id || '';

  const tableChanges = useSelector<WithTable<CampaignDetails>, TableChange>(
    getTableSelector
  );

  useEffect(() => {
    if (isEditMode) {
      setValue(campaignName);
    }
  }, [isEditMode, currentPage]);

  useEffect(() => {
    if (!isEditMode) {
      // reset the value
      setIsCampaignNameUniqueAcrossAllAdTypes(true);
    }
  }, [isEditMode]);

  const updateCellValue = (newValue: string) => {
    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.CampaignName,
        rowId: campaignDetails.campaignId,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        value: newValue,
        existingValue,
      })
    );
  };

  const debounceCellValueUpdate = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignDetails.campaignId]
  );

  const updatedCells = tableChanges.cell;

  const invokeCallback = (editrowInfo: EditedRowInfo) =>
    fromNullable(updateEditedRecords)
      .map((fn) => fn(editrowInfo))
      .toUndefined();

  const checkCampaignNameUniquenessAndUpdateState = async (
    request: ValidateUniqueValuesRequest
  ) => {
    try {
      const validationResult = await aoApiClient.validateUniqueValues(
        accountId,
        request
      );
      setIsCampaignNameUniqueAcrossAllAdTypes(validationResult.isValid);
      invokeCallback({
        isNameUnique: validationResult.isValid,
        rowId: campaignDetails.campaignId,
      });
    } catch (error) {
      invokeCallback({
        isNameUnique: false,
        rowId: campaignDetails.campaignId,
      });
    }
  };

  const debouncedIsCampaignNameUnique = useMemo(
    () => debounce(checkCampaignNameUniquenessAndUpdateState, 500),
    [updateEditedRecords]
  );

  const textInputref = useRef<HTMLInputElement>(null);
  const [isTextEclipsed, setIsTextEclipsed] = useState(false);
  useEffect(() => {
    if (
      textInputref.current?.scrollWidth &&
      textInputref.current?.scrollWidth > textInputref.current?.clientWidth
    ) {
      setIsTextEclipsed(true);
    } else {
      setIsTextEclipsed(false);
    }
  }, [textInputref.current?.value, isEditMode]);

  const updatedAdvertisingGoal = useSelector<
    WithTable<CampaignDetails>,
    string
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      campaignDetails.campaignId,
      CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal
    )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  ) as AdvertisingGoal;

  const aiBidding: AiBiddingValues =
    aiEnabled &&
    merchantsWithAutomationEnabled &&
    merchantsWithAutomationEnabled?.length > 0
      ? AiBiddingValues.Ai
      : AiBiddingValues.NotEnabled;

  const goal: AdvertisingGoal = transformAdvertisingGoal(
    aiBidding,
    updatedAdvertisingGoal ?? campaignDetails.campaignDetails.advertisingGoal
  );

  // If the values are blank default to Manual.
  const campaignTargetingType =
    campaignDetails.campaignDetails.targetingType ??
    CampaignTargetingType.Manual;

  if (isEditMode) {
    return handleRowCellElementForEditMode({
      intl,
      adTypes,
      value,
      updatedCells,
      salesChannel,
      merchantType,
      isCampaignNameUniqueAcrossAllAdTypes,
      selectedMerchantCountries,
      campaignDetails,
      selectSettings,
      existingValue,
      campaignStatus,
      setValue,
      debounceCellValueUpdate,
      setIsCampaignNameUniqueAcrossAllAdTypes,
      invokeCallback,
      debouncedIsCampaignNameUnique,
      dispatch,
      focussed,
      setFocussed,
      textInputref,
      isTextEclipsed,
      showSmartCampaign,
      goal,
      campaignTargetingType,
    });
  }

  const selectedSalesChannel = getSalesChannelFromLocalStorage(
    userId,
    accountId
  );

  const selectedMerchantCountryId = getSelectedMerchantCountryId(
    selectedMerchantCountries
  );

  return makeTruncatedTextLinkColumn<CampaignDetails>(
    ({ campaignDetails: { campaignName: name } }: CampaignDetails) =>
      name as string,
    ({ campaignId, campaignDetails: { targetingType } }: CampaignDetails) =>
      encodeURIComponent(campaignId) +
      getAdsManagerDetailsQueryParams(
        String(selectedSalesChannel),
        getMerchantFromLocalStorage({
          userId: userId,
          accountId: accountId,
          salesChannelId: String(selectedSalesChannel),
        }),
        adTypes,
        targetingType
      ),
    isPendingField(campaignDetails),
    <ViewTrendLink
      dataTestId="campaigns_campaign_name"
      campaignId={campaignDetails.campaignId}
      merchantCountryId={selectedMerchantCountryId}
      entityId={campaignDetails.campaignId}
      entityType={ViewTrendsEntityType.CAMPAIGN}
      adType={first(adTypes)}
      merchantType={merchantType}
      campaignName={campaignName}
      entityName={campaignName}
      merchantCountryName={
        allMerchants.find(
          (merchant) => merchant.merchantCountryId === selectedMerchantCountryId
        )?.merchantName
      }
      salesChannelId={selectedSalesChannel as string}
      targetingType={campaignTargetingType}
    />,
    true,
    representSmartCampaign(
      goal,
      campaignTargetingType,
      selectedAdType,
      showSmartCampaign,
      true
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

const getTooltipDisabledState = (
  isCampaignArchivedForAmazon: boolean | undefined,
  showTooltip: boolean,
  isTextEclipsed: boolean
) => {
  if (isCampaignArchivedForAmazon) return false;

  return showTooltip ? false : !isTextEclipsed;
};

const handleRowCellElementForEditMode = ({
  intl,
  adTypes,
  value,
  updatedCells,
  salesChannel,
  merchantType,
  isCampaignNameUniqueAcrossAllAdTypes,
  selectedMerchantCountries,
  campaignDetails,
  selectSettings,
  existingValue,
  campaignStatus,
  setValue,
  debounceCellValueUpdate,
  setIsCampaignNameUniqueAcrossAllAdTypes,
  invokeCallback,
  debouncedIsCampaignNameUnique,
  dispatch,
  focussed,
  setFocussed,
  textInputref,
  isTextEclipsed,
  showSmartCampaign,
  goal,
  campaignTargetingType,
}: {
  intl: IntlShape;
  adTypes: AdType[];
  value: string;
  updatedCells: TableCellChange;
  salesChannel: FlywheelSalesChannel;
  merchantType: MerchantType | undefined;
  isCampaignNameUniqueAcrossAllAdTypes: boolean;
  selectedMerchantCountries: string | (string | number)[];
  campaignDetails: CampaignDetails;
  selectSettings: TableSelectSettings;
  existingValue: string;
  campaignStatus: CampaignStatus | undefined;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  debounceCellValueUpdate: (newValue: string) => void;
  setIsCampaignNameUniqueAcrossAllAdTypes: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  invokeCallback: (editrowInfo: EditedRowInfo) => void;
  debouncedIsCampaignNameUnique: (
    request: ValidateUniqueValuesRequest
  ) => Promise<void> | void;
  dispatch: React.Dispatch<any>;
  focussed: boolean;
  setFocussed: React.Dispatch<React.SetStateAction<boolean>>;
  textInputref: Ref<HTMLInputElement>;
  isTextEclipsed: boolean;
  showSmartCampaign?: boolean;
  goal?: AdvertisingGoal;
  campaignTargetingType?: CampaignTargetingType;
}) => {
  const isCampaignNameDuplicateWithinEditedCells =
    checkIfCampaignNameIsDuplicateWithinEditedCells(value, updatedCells);

  const maxCampaignNameLength = getMaxLengthForCampaignName(
    salesChannel,
    merchantType
  );

  const selectedAdType = first(adTypes);
  const isSBA = selectedAdType === AdType.SearchBrandAmplifier;

  const isInputInvalid =
    !isValidCampaignNameCharacterLength(value.trim(), maxCampaignNameLength) ||
    isInvalidCampaignName(salesChannel, value, isSBA) ||
    isCampaignNameDuplicateWithinEditedCells ||
    !isCampaignNameUniqueAcrossAllAdTypes;

  const tooltipContent = () => {
    if (isCampaignArchivedForAmazon) {
      return getTooltipContentForArchivedEntity(
        intl.formatMessage({
          id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
        }),
        intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME,
        }),
        intl
      );
    } else if (showTooltip) {
      const tooltipData = getTooltipdata({
        intl,
        value,
        salesChannel,
        maxCampaignNameLength,
        isCampaignNameDuplicateWithinEditedCells,
        isCampaignNameUniqueAcrossAllAdTypes,
        isSBA,
      });
      if (tooltipData) {
        return tooltipData;
      }
    } else if (isTextEclipsed) {
      return <p className="w-250 text-center">{value}</p>;
    }
    return <></>;
  };

  const onCampaignNameChange = (inputValue: string) =>
    handleOncampaignNameChange({
      inputValue,
      setValue,
      debounceCellValueUpdate,
      selectedMerchantCountries,
      campaignDetails,
      setIsCampaignNameUniqueAcrossAllAdTypes,
      invokeCallback,
      debouncedIsCampaignNameUnique,
    });

  const isCampaignArchivedForAmazon =
    salesChannel === FlywheelSalesChannel.Amazon &&
    selectedAdType &&
    ADTYPES_ARRAY_SP_SD_SB_SBV.includes(selectedAdType) &&
    isCampaignStatusArchived(campaignDetails.channelSettings.status);

  const onCheckboxClick = () => {
    if (!isCampaignArchivedForAmazon) {
      dispatch(
        tableActions.toggleSelectedItem({
          rowId: campaignDetails.campaignId,
          tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        })
      );
    }
  };

  const onInputFocus = () => setFocussed(true);
  const onInputBlur = () => setFocussed(false);

  const showTooltip = focussed && isInputInvalid;

  const isRowSelected = selectSettings.rows.includes(
    campaignDetails.campaignId
  );

  const adCampaignNameChecboxWithTextInputProps: CheckboxWithTextInputProps &
    TypedPortaledTooltipProps = {
    value,
    checked: isRowSelected
      ? CheckboxCheckedState.Checked
      : CheckboxCheckedState.Unchecked,
    inputState: isInputInvalid ? TextInputState.Error : TextInputState.Default,
    onClick: onCheckboxClick,
    onChange: onCampaignNameChange,
    onFocus: onInputFocus,
    isDirty: value !== existingValue,
    onKeyPress: onInputFocus,
    onBlur: onInputBlur,
    tooltipContent: tooltipContent(),
    tooltipDisabled: getTooltipDisabledState(
      isCampaignArchivedForAmazon,
      showTooltip,
      isTextEclipsed
    ),
    overwrittenTooltipClassnames: 'w-full',
    tooltipTheme: PortaledTooltipTheme.Dark,
    portaledTooltipPlacement: PortaledTooltipPlacement.Right,
    disabled: isCampaignStatusArchived(campaignStatus),
    state: isCampaignArchivedForAmazon
      ? CheckboxState.Disabled
      : CheckboxState.Default,
    dataTestId: 'campaign_name',
    appendLeftElement: representSmartCampaign(
      goal,
      campaignTargetingType,
      selectedAdType,
      showSmartCampaign,
      true
    ),
  };

  return makeCheckboxWithTextInputColumn<CampaignDetails>(() => {
    return { ...adCampaignNameChecboxWithTextInputProps, textInputref };
  })(campaignDetails);
};

const handleOncampaignNameChange = ({
  inputValue,
  setValue,
  debounceCellValueUpdate,
  selectedMerchantCountries,
  campaignDetails,
  setIsCampaignNameUniqueAcrossAllAdTypes,
  invokeCallback,
  debouncedIsCampaignNameUnique,
}: {
  inputValue: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  debounceCellValueUpdate: (newValue: string) => void;
  campaignDetails: CampaignDetails;
  selectedMerchantCountries: string | (string | number)[];
  setIsCampaignNameUniqueAcrossAllAdTypes: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  invokeCallback: (editrowInfo: EditedRowInfo) => void;
  debouncedIsCampaignNameUnique: (
    request: ValidateUniqueValuesRequest
  ) => Promise<void> | void;
}) => {
  const generateValidateUniqueRequest = (
    uniqueRequestValue: string
  ): ValidateUniqueValuesRequest => {
    let merchantCountryId: string;

    if (isArray(selectedMerchantCountries)) {
      merchantCountryId = isEmpty(selectedMerchantCountries)
        ? ''
        : selectedMerchantCountries[0].toString();
    } else {
      merchantCountryId = selectedMerchantCountries;
    }

    return {
      column: CAMPAIGNS_API_COLUMN_NAME.CampaignName,
      entityType: EntityType.Campaign,
      value: uniqueRequestValue,
      merchantCountryId,
    };
  };

  const trimmedValue = inputValue.trim();
  setValue(inputValue);
  debounceCellValueUpdate(trimmedValue);
  const request = generateValidateUniqueRequest(trimmedValue);

  // don't make an API call with the original value
  if (campaignDetails.campaignDetails.campaignName === trimmedValue) {
    setIsCampaignNameUniqueAcrossAllAdTypes(true);
    invokeCallback({
      isNameUnique: true,
      rowId: campaignDetails.campaignId,
    });
  } else {
    debouncedIsCampaignNameUnique(request);
  }
};

export const getTooltipdata = ({
  intl,
  value,
  salesChannel,
  maxCampaignNameLength,
  isCampaignNameDuplicateWithinEditedCells,
  isCampaignNameUniqueAcrossAllAdTypes,
  isSBA,
}: {
  intl: IntlShape;
  value: string;
  salesChannel: FlywheelSalesChannel;
  maxCampaignNameLength: number;
  isCampaignNameDuplicateWithinEditedCells: boolean;
  isCampaignNameUniqueAcrossAllAdTypes: boolean;
  isSBA?: boolean;
}): JSX.Element | undefined => {
  if (!isValidCampaignNameCharacterLength(value, maxCampaignNameLength)) {
    return (
      <p>
        {intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME_INVALID_CHARACTER_LENGTH,
          },
          { maxLength: maxCampaignNameLength }
        )}
      </p>
    );
  }
  if (hasOnlySpecialCharacters(value, salesChannel) || !value) {
    return (
      <p>
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME_INVALID_ALL_SPECIAL_CHARACTERS,
        })}
      </p>
    );
  }
  if (!isSBA && !isValidAscii(value, salesChannel)) {
    const invalidCharacters = fetchNonAsciiCharacters(value, salesChannel);
    return (
      <p>
        {intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME_INVALID_CHARACTERS,
          },
          {
            invalidCount: invalidCharacters ? invalidCharacters.length : 0,
            invalidCharacters,
          }
        )}
      </p>
    );
  }
  if (
    isCampaignNameDuplicateWithinEditedCells ||
    !isCampaignNameUniqueAcrossAllAdTypes
  ) {
    return (
      <p>
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_DUPLICATE_CAMPAIGN_NAME,
        })}
      </p>
    );
  }
};

export const campaignNameColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.CampaignName,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '364px',
};

export const campaignNameFilter = createStringDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.CampaignName,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME,
  isValidString()
);

export function checkIfCampaignNameIsDuplicateWithinEditedCells(
  editedCampaignName: string,
  updatedCells: TableCellChange
): boolean {
  const allEditedCampaignNames = Object.values(updatedCells).map(
    (value) => value[CAMPAIGNS_API_COLUMN_NAME.CampaignName]
  );
  const maybeDuplicateCampaignName = allEditedCampaignNames.filter(
    (campaignName) => campaignName === editedCampaignName
  );

  // >1 because the one which is being compared needs to be ignored.
  return maybeDuplicateCampaignName.length > 1;
}

export const getBidPlaceholdersValues = (
  areAllProductCampaignsBidsEqual: boolean,
  areAllVideoCampaignsBidsEqual: boolean,
  firstProductCampaignBid: string | undefined,
  firstVideoCampaignBid: string | undefined,
  defaultBidAmazonSB: string,
  defaultBidAmazonSBV: string
) => {
  let bidAmazonSBPlaceholder = defaultBidAmazonSB;
  let bidAmazonSBVPlaceholder = defaultBidAmazonSBV;
  if (areAllProductCampaignsBidsEqual && firstProductCampaignBid) {
    bidAmazonSBPlaceholder = firstProductCampaignBid;
  }
  if (areAllVideoCampaignsBidsEqual && firstVideoCampaignBid) {
    bidAmazonSBVPlaceholder = firstVideoCampaignBid;
  }
  return [bidAmazonSBPlaceholder, bidAmazonSBVPlaceholder];
};
