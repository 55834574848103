import { useContext, useEffect, useState } from 'react';

import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';

import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { NotificationSettingsResponse } from '../../lib/types/Fam';
import { createSKUApiClient } from '../../lib/clients/SKUApiClient';
import { mergeNotificationSettingsAndIoSubscriptionSettings } from './helpers';
import { IntlShape } from 'react-intl';

const useNotificationSettings = (intl: IntlShape) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [settings, setSettings] = useState<NotificationSettingsResponse | null>(
    null
  );

  const { userInfo } = useContext<UserContextState>(UserContext);
  const famApiClient = createFAMApiClient(userInfo.idToken!);
  const skuApiClient = createSKUApiClient(userInfo.idToken!);
  const accountId = userInfo.currentAccountId;
  const userId = userInfo.userDetails?.id;

  const getNotificationSettings = async () => {
    setIsLoading(true);

    try {
      /**
       * FAM API to fetch the user notification settings
       * This API has the data of only settings which have been atleast toggled once.
       * Also the data it holds is just the key which points to the particular subscription.
       * So to get a full list of notification subscriptions we have to call the required micro services.
       * */
      const data = await famApiClient.getNotificationSettingsByUser(
        accountId!,
        userId!
      );

      /**
       * Call the IO to fetch the custom insight subscriptions.
       */
      const ioData = await skuApiClient.getCustomInsights(accountId!, [], 100);

      /**
       * Function which merges all IO related subscriptions into FAM notification settings.
       * It does the following.
       * 1. Looks at differnce in subscription ids and adds it.
       * 2. Update's the display names of already existing settings in the IO API.
       * 3. Predefined IO subscriptions such as OOS and Unsold are also handled.
       */
      const mergedData = mergeNotificationSettingsAndIoSubscriptionSettings(
        intl,
        data,
        ioData.elements
      );

      setSettings(mergedData);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotificationSettings();
  }, [accountId, userId]);

  return { isLoading, settings };
};

export default useNotificationSettings;
