import { useContext, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import first from 'lodash/first';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTruncatedTextLinkColumn } from '../../../../../containers/table/utils/makeTableCells';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../../containers/userProvider/userProvider';
import {
  FlywheelSalesChannel,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ViewTrendLink } from '../../../components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../components/ViewTrends';
import { getAdManagerUrl, getAdsLevelUri } from '../../../utils';
import {
  getAdType,
  getMerchantCountries,
  getSalesChannel,
} from '../adItemsTableColumns/utils';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
  TableDataAdsManager,
} from '../types';
import {
  TARGETS_API_COLUMN_NAME,
  getSelectedMerchantCountryId,
} from '../utils';
import { getAdsManagerDetailsQueryParams } from '../utils/queryParamsUtils';
import { getSalesChannelFromLocalStorage } from '../utils/storageUtils';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../../lib/types/SalesChannels';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = ({
  targetId,
  targetDetails,
  channelSettings,
  targetPerformance,
  pendingFields,
  adTypes,
  selectedMerchantCountries,
  merchantType,
  allMerchants,
  salesChannel,
}) => {
  const targetsDetails: TargetsDetails = {
    targetId,
    targetDetails,
    channelSettings,
    targetPerformance,
    pendingFields,
  };
  const location = useLocation();
  const baseUrl = useMemo(
    () => getAdManagerUrl(location.pathname),
    [location.pathname]
  );

  const searchQueryParams = new URLSearchParams(
    decodeURIComponent(location.search)
  );

  const userContext = useContext(UserContext);

  const userId = userContext.userInfo.userDetails?.id || '';
  const accountId = getCurrentAccountFromContext(userContext)?.id || '';

  const salesChannelId = searchQueryParams.get(SALES_CHANNEL_ID);
  const merchantCountryIds = searchQueryParams.get(MERCHANT_COUNTRY_IDS);
  const adTypesFromQueryParams = searchQueryParams.get(AD_TYPE);

  const selectedSalesChannel = getSalesChannelFromLocalStorage(
    userId || '',
    accountId || ''
  );

  const selectedMerchantCountryId = getSelectedMerchantCountryId(
    selectedMerchantCountries
  );

  return makeTruncatedTextLinkColumn(
    ({ targetDetails: details }: TargetsDetails) =>
      details?.adGroupName ? details?.adGroupName : undefined,
    ({ targetDetails: details }: TargetsDetails) =>
      details?.adGroupId !== undefined && details?.adGroupId !== 'undefined'
        ? getAdsLevelUri(
            baseUrl,
            ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups,
            details?.adGroupId
          ) +
          getAdsManagerDetailsQueryParams(
            getSalesChannel(salesChannelId, selectedSalesChannel),
            getMerchantCountries(
              merchantCountryIds,
              userId,
              accountId,
              salesChannelId,
              selectedSalesChannel
            ),
            getAdType(adTypesFromQueryParams, adTypes),
            details.campaignTargetingType,
            details.adGroupName
          )
        : '',
    pendingFields?.includes(TARGETS_API_COLUMN_NAME.AdGroupName),
    <ViewTrendLink
      dataTestId="targets_ad_group_name"
      campaignId={targetDetails.campaignId}
      merchantCountryId={selectedMerchantCountryId}
      entityId={targetDetails.adGroupId}
      entityType={ViewTrendsEntityType.ADGROUP}
      adType={first(adTypes)}
      merchantType={merchantType}
      campaignName={targetDetails.campaignName}
      entityName={targetDetails.adGroupName}
      merchantCountryName={
        allMerchants.find(
          (merchant) => merchant.merchantCountryId === selectedMerchantCountryId
        )?.merchantName
      }
      salesChannelId={
        salesChannel === FlywheelSalesChannel.Amazon
          ? AMAZON_SALES_CHANNEL_ID
          : WALMART_SALES_CHANNEL_ID
      }
      targetingType={targetDetails.campaignTargetingType}
    />
  )(targetsDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const adGroupNameColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.AdGroupName,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_GROUP_NAME,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const adGroupNameFilter = createStringDataFieldFilter(
  TARGETS_API_COLUMN_NAME.AdGroupName,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_GROUP_NAME,
  isValidString()
);
