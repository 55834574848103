import { UserResponseType } from '../../../../lib/types/Fam';

export const USER_MANAGEMENT_TABLE_ID = 'USER_MANAGEMENT_TABLE';

export interface UsersTableData {
  currentUserId?: string;
  accountId: string;
}

export enum NotificationStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export type RowCellElementProps = UserResponseType & UsersTableData;
