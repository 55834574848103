import {
  PortaledTooltipPlacement,
  createMultiSelectDataFieldFilter,
  AlertTriangleIcon as warningSVG,
} from '@teikametrics/tm-design-system';

import { MultiSelectOption } from '../../../../../components/Filter/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeTextColumn,
  makeTextWithIconColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  FlywheelSalesChannel,
  ProfileApiDataDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TooltipText } from '../reviewStatusTooltipText';
import { REVIEW_URLS, TableDataAdsManager } from '../types';
import { PROFILES_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<ProfileApiDataDetails> = ({
  profileDetails,
  profilePerformance,
  profileId,
  channelSettings,
}) => {
  const profileData: ProfileApiDataDetails = {
    profileDetails,
    profilePerformance,
    profileId,
    channelSettings,
  };

  const getColumnText = (data: ProfileApiDataDetails) =>
    data.profileDetails.profileReviewStatus?.reviewStatus;

  if (profileDetails.profileReviewStatus?.reviewReason) {
    return makeTextWithIconColumn(
      getColumnText,
      warningSVG,
      <TooltipText
        comments={profileDetails.profileReviewStatus.reviewReason}
        href={REVIEW_URLS.SBA_PROFILE_REVIEW_STATUS}
      />,
      true,
      PortaledTooltipPlacement.Right
    )(profileData);
  }

  return makeTextColumn((data: ProfileApiDataDetails) => getColumnText(data))(
    profileData
  );
};
RowCellElement.displayName = 'RowCellElement';

export const profileReviewStatusColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.ProfileReviewStatus,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_PROFILE_REVIEW_STATUS,
  RowCellElement,
  gridColumnWidth: '180px',
};

export const profileProfileReviewStatusFilter = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
) =>
  createMultiSelectDataFieldFilter(
    PROFILES_API_COLUMN_NAME.ProfileReviewStatus,
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_PROFILE_REVIEW_STATUS,
    getCampaignStatusOptions(selectedSalesChannel, selectedAdType)
  );

const getCampaignStatusOptions = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
): MultiSelectOption[] => {
  switch (selectedSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      switch (selectedAdType) {
        case AdType.SearchBrandAmplifier:
          return [
            SelectFilterOption.ProfileReviewStatusPending,
            SelectFilterOption.ProfileReviewStatusApproved,
            SelectFilterOption.ProfileReviewStatusRejected,
          ];
        // The fallback default should never occur hence safe to return [], can handle with default options in future
        default:
          return [];
      }
    /**
      The fallback default should never occur as Sales Channel will always fall in one of above 
      cases hence safe to return [], can handle with default options in future
    * 
    */
    default:
      return [];
  }
};
