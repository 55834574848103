import { Factory } from 'fishery';
import {
  BudgetResponseMerchantType,
  TypeBudgetAllocation,
} from '../../../lib/types/BudgetInsightsSharedTypes';
import { AdType } from '../../../lib/types/AOSharedTypes';

export const budgetResponseMerchantTypeResponse = Factory.define<
  BudgetResponseMerchantType[]
>(() => [
  {
    monthlyBudgetAllocation: { amount: '10000', currency: 'USD' },
    mtdBudgetAllocation: { amount: '5000', currency: 'USD' },
    mtdAdSpend: { amount: '4500', currency: 'USD' },
    mtdBudgetUtilization: 90,
    dailyBudgetAllocation: { amount: '300', currency: 'USD' },
    avgDailyAdSpend3d: { amount: '290', currency: 'USD' },
    dailyAdSpendRequired: { amount: '310', currency: 'USD' },
    forecastAdSpend3d: { amount: '930', currency: 'USD' },
    forecastAdSpend7d: { amount: '2170', currency: 'USD' },
    action: 'Increase budget',
    campaignName: 'Summer Sale',
    roas: '3.5',
    campaignId: 'CAMP12345',
    merchantCountryId: 'US',
    advertisingType: AdType.SponsoredProducts,
    groupLevelBudgeted: true,
    budgetAllocationType: TypeBudgetAllocation.MERCHANT,
    campaignGroupLabel: 'Seasonal Campaigns',
    campaignCount: 5,
    campaignGroupId: 'GRP67890',
    mtdRoas: 3.5,
    mtdAcos: 28.6,
    mtdAdsales: 50000,
    acos30d: 25.0,
    adSales30d: { amount: '100000', currency: 'USD' },
    pendingFields: ['dailyBudgetAllocation', 'avgDailyAdSpendRequired'],
    campaignStatus: 'Active',
    campaignTargetingType: 'Manual',
    mtdAdSales: { amount: '45000', currency: 'USD' },
    clicks30d: 20000,
    clickThroughRate30d: 0.05,
    impressions30d: 400000,
    costPerClick30d: { amount: '0.5', currency: 'USD' },
    unitsSold30d: 1000,
    adConversion30d: 500,
    adConversionRate30d: 0.025,
    mtdClicks: 18000,
    mtdClickThroughRate: 0.045,
    mtdImpressions: 360000,
    mtdCostPerClick: { amount: '0.55', currency: 'USD' },
    mtdUnitsSold: 900,
    mtdAdConversion: 450,
    mtdAdConversionRate: 0.025,
  },
]);
