import {
  TextLink,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../lib/types/I18nKey';
import { AUTO_NEGATE_HELP_CENTER_LINK } from '../../../../lib/types/KeywordAction';

interface OwnProps {
  readonly customProps?: JSX.Element;
}

export const AutoNegateDescriptionV2: React.FC<OwnProps> = ({
  customProps,
}) => {
  const intl = useIntl();

  return (
    <div className="flex">
      <p>
        <Typography
          size={TypographySize.lg}
          lineHeight={TypographyLineHeight.tight}
          weight={TypographyWeight.semibold}
          className="mb-8"
        >
          {intl.formatMessage({ id: I18nKey.KWA_AUTO_NEGATE_HEADER })}
        </Typography>
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.normal}
          weight={TypographyWeight.regular}
          className="text-grey-700"
        >
          {intl.formatMessage({ id: I18nKey.KWA_AUTO_NEGATE_DESCRIPTION })}
        </Typography>
        <p className={classNames('mt-4 mb-24')}>
          <TextLink
            openNewTab
            textLabel={intl.formatMessage({
              id: I18nKey.GENERIC_LEARN_MORE_IN_HELP_CENTER,
            })}
            href={AUTO_NEGATE_HELP_CENTER_LINK}
            dataTestId="auto-negate_help-center-link"
          />
        </p>
        {customProps}
      </p>
    </div>
  );
};
AutoNegateDescriptionV2.displayName = 'AutoNegateDescriptionV2';
