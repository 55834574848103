import { Factory } from 'fishery';
import { AdvertisingType } from '../../../types/AOSharedTypes';
import { RecommendationsSummaryResponse } from '../../../types/RecommendationsSharedTypes';

export const ViewRecommedationsResponse = Factory.define<
  RecommendationsSummaryResponse[]
>(() => [
  {
    merchantCountryId: '3c12642d-a484-41c8-b807-fa0dc040631e',
    advertisingType: AdvertisingType.SponsoredProducts,
    campaignId: '13f8bf6d-dc27-4a04-bffe-6e5b13c19ddf',
    campaignName: 'Grout Sealer Manual',
    subRows: [
      {
        campaignId: '13f8bf6d-dc27-4a04-bffe-6e5b13c19ddf',
        adgroupId: '9a83eeeb-9c7f-43c1-8266-9a86d08b7e4a',
        adGroupName: 'Grout Sealer 16oz PAT',
        smartAcos: 0,
        acos: 0.3,
        totalAdSales: {
          amount: '12',
          currency: 'USD',
        },
        adSpend: {
          amount: '10',
          currency: 'USD',
        },
      },
      {
        campaignId: '13f8bf6d-dc27-4a04-bffe-6e5b13c19ddf',
        adgroupId: '9a83eeeb-9c7f-43c1-8267-9a86d08b7e4a',
        adGroupName: 'Grout Sealer 16oz PAT',
        smartAcos: 0,
        acos: 0.3,
        totalAdSales: {
          amount: '12',
          currency: 'USD',
        },
        adSpend: {
          amount: '10',
          currency: 'USD',
        },
      },
    ],
  },
  {
    merchantCountryId: '92faa498-eccd-4c59-999f-9fec646a8967',
    advertisingType: AdvertisingType.SponsoredProducts,
    campaignId: '13f8bf6d-dc27-4a04-bffe-6e5b13c19ddf',
    campaignName: 'Grout Sealer Manual',
    subRows: [
      {
        campaignId: '13f8bf6d-dc27-4a04-bffe-6e5b13c19ddf',
        adgroupId: '9a83eeeb-9c7f-43c1-828-9a86d08b7e4a',
        adGroupName: 'Grout Sealer 16oz PAT',
        smartAcos: 0,
        acos: 0.3,
        totalAdSales: {
          amount: '12',
          currency: 'USD',
        },
        adSpend: {
          amount: '10',
          currency: 'USD',
        },
      },
      {
        campaignId: '13f8bf6d-dc27-4a04-bffe-6e5b13c19ddf',
        adgroupId: '9a83eeeb-9c7f-43c1-8269-9a86d08b7e4a',
        adGroupName: 'Grout Sealer 16oz PAT',
        smartAcos: 0,
        acos: 0.3,
        totalAdSales: {
          amount: '12',
          currency: 'USD',
        },
        adSpend: {
          amount: '10',
          currency: 'USD',
        },
      },
    ],
  },
  {
    merchantCountryId: '3c12642d-a484-41c8-b807-fa0dc040631e',
    advertisingType: AdvertisingType.SponsoredProducts,
    campaignId: '13f8bf6d-dc27-4a04-bffe-6e5b13c19ddf',
    campaignName: 'Grout Sealer Manual',
    subRows: [
      {
        campaignId: '13f8bf6d-dc27-4a04-bffe-6e5b13c19ddf',
        adgroupId: '9a83eeeb-9c7f-43c1-8261-9a86d08b7e4a',
        adGroupName: 'Grout Sealer 16oz PAT',
        smartAcos: 0,
        acos: 0.3,
        totalAdSales: {
          amount: '12',
          currency: 'USD',
        },
        adSpend: {
          amount: '10',
          currency: 'USD',
        },
      },
    ],
  },
  {
    merchantCountryId: '92faa498-eccd-4c59-999f-9fec646a8967',
    advertisingType: AdvertisingType.SponsoredProducts,
    campaignId: '13f8bf6d-dc27-4a04-bffe-6e5b13c19ddf',
    campaignName: 'Grout Sealer Manual',
    subRows: [
      {
        campaignId: '13f8bf6d-dc27-4a04-bffe-6e5b13c19ddf',
        adgroupId: '9a83eeeb-9c7f-43c1-8262-9a86d08b7e4a',
        adGroupName: 'Grout Sealer 16oz PAT',
        smartAcos: 0,
        acos: 0.3,
        totalAdSales: {
          amount: '12',
          currency: 'USD',
        },
        adSpend: {
          amount: '10',
          currency: 'USD',
        },
      },
    ],
  },
]);
