import capitalize from 'lodash/capitalize';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  MultiSelectOption,
  Placement,
  SelectOptionProps,
  SelectState,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/flywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupReviewStatus,
  AdGroupReviewStatusType,
  AdGroupStatus,
  AdLevel,
  AdType,
  CampaignStatus,
  FlywheelSalesChannel,
  ProductAdDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_AD_ITEMS_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  PRODUCT_ADS_API_COLUMN_NAME,
  ProductAdStatusOptions,
  ProductAdStatusValue,
  getTooltipContentForArchivedEntity,
  isAdGroupStatusArchived,
  isCampaignStatusArchived,
  isProductAdStatusArchived,
} from '../utils';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = ({
  adItemPerformance,
  adItemDetails,
  adItemId,
  channelSettings,
  isEditMode,
  salesChannel,
  pendingFields,
  selectedAdType,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const adItemData: ProductAdDetails = {
    adItemPerformance,
    adItemDetails,
    adItemId,
    channelSettings,
    pendingFields,
  };

  const updatedProductAdStatus = useSelector<
    WithTable<ProductAdDetails>,
    string
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      adItemId,
      PRODUCT_ADS_API_COLUMN_NAME.Status
    )(tableState, ADS_MANAGER_AD_ITEMS_TABLE_ID)
  );

  const currentAdStatus = channelSettings.status;

  const productAdStatus = updatedProductAdStatus ?? currentAdStatus;

  const currentRowIndex = useSelector<WithTable<ProductAdDetails>, number>(
    ({ tableState }) => {
      const visibleData = tableSelectors.getVisibleData(
        tableSelectors.getTableSelector<ProductAdDetails, void>()(
          tableState,
          ADS_MANAGER_AD_ITEMS_TABLE_ID
        )
      );
      const index = (function () {
        for (let i = 0; i < visibleData.length; i++) {
          if (visibleData[i].adItemId === adItemId) {
            return i;
          }
        }
        return -1;
      })();

      return index;
    }
  );

  if (isEditMode) {
    const productAdStatusOptions = getProductAdStatusSelectOptionsForEdit(
      salesChannel,
      intl
    );

    const dropDownStatus = getDropdownStatus(
      intl,
      adItemDetails.adGroupStatus,
      channelSettings.status,
      selectedAdType,
      adItemDetails.campaignStatus,
      adItemDetails.adGroupReviewStatus
    );

    return makeSelectColumn(
      (data: ProductAdDetails) => ({
        dataTestId: PRODUCT_ADS_API_COLUMN_NAME.Status,
        wrapperClassName: 'w-120',
        options: productAdStatusOptions,
        placeholder: '',
        value: productAdStatus,
        isDirty: productAdStatus !== currentAdStatus,
        state: dropDownStatus.status,
        onChange: (value: string) => {
          dispatch(
            tableActions.updateCell({
              tableId: ADS_MANAGER_AD_ITEMS_TABLE_ID,
              rowId: data.adItemId,
              columnName: PRODUCT_ADS_API_COLUMN_NAME.Status,
              value,
              existingValue: currentAdStatus,
            })
          );
        },
        showOnTop: currentRowIndex > 6,
      }),
      () => {
        return {
          tooltipContent: dropDownStatus.tooltipContent,
          controlledTooltip: dropDownStatus.tooltipContent
            ? ControlledTooltip.None
            : ControlledTooltip.Hide,
          tooltipPlacement: Placement.Bottom,
          style: ContentStyle.Bold,
        };
      }
    )(adItemData);
  }

  return makeTextColumn<ProductAdDetails>(
    ({ channelSettings: { status } }) =>
      status ? capitalize(status) : undefined,
    pendingFields?.includes(PRODUCT_ADS_API_COLUMN_NAME.Status)
  )(adItemData);
};
RowCellElement.displayName = 'RowCellElement';

export const productAdStatusColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  isSortable: true,
  columnName: PRODUCT_ADS_API_COLUMN_NAME.Status,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_PRODUCT_AD_STATUS,
  RowCellElement,
  gridColumnWidth: '160px',
};

export const productAdStatusFilter = (
  selectedSalesChannel: FlywheelSalesChannel
) =>
  createMultiSelectDataFieldFilter(
    PRODUCT_ADS_API_COLUMN_NAME.Status,
    I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_PRODUCT_AD_STATUS,
    getProductAdStatusFilterOptions(selectedSalesChannel)
  );

export const getProductAdStatusSelectOptionsForEdit = (
  selectedSalesChannel: FlywheelSalesChannel,
  intl: IntlShape
): SelectOptionProps<string>[] =>
  getProductAdStatusFilterOptions(selectedSalesChannel).map((option) => ({
    label: intl.formatMessage({ id: option.displayI18nKey }),
    value: option.value,
  }));

export const getProductAdStatusFilterOptions = (
  selectedSalesChannel: FlywheelSalesChannel
): MultiSelectOption[] => {
  switch (selectedSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      return [
        {
          value: ProductAdStatusOptions.Enabled.value,
          displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED,
        },
        {
          value: ProductAdStatusOptions.Disabled.value,
          displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_DISABLED,
        },
      ];
    case FlywheelSalesChannel.Amazon:
      return [
        {
          value: ProductAdStatusOptions.Enabled.value,
          displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED,
        },
        {
          value: ProductAdStatusOptions.Paused.value,
          displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PAUSED,
        },
        {
          value: ProductAdStatusOptions.Archived.value,
          displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ARCHIVED,
        },
      ];
  }
};

export const getIsProductArchived = (
  adGroupStatus: AdGroupStatus,
  status: ProductAdStatusValue,
  campaignStatus?: CampaignStatus
) =>
  isCampaignStatusArchived(campaignStatus) ||
  isAdGroupStatusArchived(adGroupStatus) ||
  isProductAdStatusArchived(status);

export const getDropdownStatus = (
  intl: IntlShape,
  adGroupStatus: AdGroupStatus,
  status: ProductAdStatusValue,
  adType: AdType,
  campaignStatus?: CampaignStatus,
  adGroupreviewStatus?: AdGroupReviewStatus
) => {
  if (getIsProductArchived(adGroupStatus, status, campaignStatus)) {
    return {
      status: SelectState.Disabled,
      tooltipContent: getTooltipContentForArchivedEntity(
        intl.formatMessage({
          id: AdLevelI8nKeyMapper[AdLevel.ProductAds],
        }),
        intl.formatMessage({
          id: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_PRODUCT_AD_STATUS,
        }),
        intl
      ),
    };
  }

  if (
    adType === AdType.SearchBrandAmplifier &&
    adGroupreviewStatus &&
    status === ProductAdStatusValue.Disabled
  ) {
    if (
      adGroupreviewStatus.reviewStatus === AdGroupReviewStatusType.Pending ||
      adGroupreviewStatus.reviewStatus === AdGroupReviewStatusType.InProgress
    ) {
      return {
        status: SelectState.Disabled,
        tooltipContent: (
          <p className="w-180 text-center">
            {intl.formatMessage({
              id: I18nKey.ADS_MANAGER_PRODUCTADS_TABLE_COLUMN_PRODUCT_AD_STATUS_TOOLTIP,
            })}
          </p>
        ),
      };
    }
  }

  return {
    status: SelectState.Default,
  };
};
