import isNil from 'lodash/isNil';

import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { PROFILES_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = (props) =>
  makeCurrencyColumn<ProfileApiDataDetails>(
    ({ profilePerformance: { relatedClickRevenue } }) => {
      const performanceMetricValue = relatedClickRevenue?.amount
        ? getPerformanceMetricValue(
            props.selectedEndDate,
            undefined,
            Number(relatedClickRevenue?.amount)
          )
        : undefined;

      return isNil(performanceMetricValue)
        ? undefined
        : {
            amount: performanceMetricValue,
            currency:
              getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
              relatedClickRevenue?.currency ||
              CURRENCY_CODE,
          };
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const relatedClickRevenueColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.RelatedClickRevenue,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_RELATED_CLICK_REVENUE,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
};

export const relatedClickRevenueFilterForProfiles = (currency: string) =>
  createMoneyDataFieldFilter(
    PROFILES_API_COLUMN_NAME.RelatedClickRevenue,
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_RELATED_CLICK_REVENUE,
    currency,
    isValidNumber(0)
  );
