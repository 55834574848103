import noop from 'lodash/noop';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../lib/types/I18nKey';

export interface SaveChangeButtonProps {
  readonly changedCount: number;
  readonly toggleTableMode: () => void;
  readonly buttonState: ButtonState;
  readonly onSaveHandler?: () => void;
  readonly dataTestId?: string;
}

export const SaveChangeButton: React.FC<SaveChangeButtonProps> = (
  props: SaveChangeButtonProps
) => {
  const intl = useIntl();

  const { changedCount, toggleTableMode, onSaveHandler = noop } = props;

  const buttonLabel = intl.formatMessage(
    {
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BUTTON_SAVE_CHANGE,
    },
    { count: changedCount }
  );

  const onSaveButtonClick = () => {
    toggleTableMode();
    onSaveHandler();
  };

  return (
    <Button
      variant={ButtonVariant.Primary}
      size={ButtonSize.Medium}
      label={buttonLabel}
      onClick={onSaveButtonClick}
      state={props.buttonState}
      dataTestId={props.dataTestId}
    />
  );
};
SaveChangeButton.displayName = 'SaveChangeButton';
