import { Factory } from 'fishery';
import { PaginatedResult } from '../../clients/types';
import { ActiveInventoryData } from '../../types/SKUSharedTypes';

export const ActiveInventoryTableDataResponse = Factory.define<
  PaginatedResult<ActiveInventoryData>
>(() => {
  return {
    totalItems: 2,
    items: [
      {
        productGroupId: '',
        productCatalogId: 'e66830ae-5bf7-5e2e-8c38-70d84b57727d',
        parentProductCatalogId: '3aac500e-a954-5f34-b812-c795189f95af',
        merchantCountryId: 'a13d7f23-5930-4d8b-bfe9-4aa08c69f548',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        skuDetailsFields: {
          sku: '195-0833-000062FBA',
          name: 'Pulsar G1200SG 1,200W Carrying Handle Gas-Powered Portable Generator, 1200w, Black/Gray',
          imageUrl: 'https://m.media-amazon.com/images/I/71q4v6mG2zL.jpg',
          extItemDetail: {
            extItemId: 'B0BLY7M1YL',
            extItemType: 'ASIN',
            salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
            salesChannelName: 'Amazon',
          },
        },
        performance: {
          inventoryValue: {
            amount: 714,
            currency: 'USD',
          },
          unitCogs: {
            amount: 6,
            currency: 'USD',
          },
          sellThroughRate: 1.3384970784073924,
          inventorySalesRatio: 0.0278948028445699,
          daysOfInventory: 41,
          inventoryQuantity: 119,
          rateOfSale: 3.03,
          unitsSold: 197,
          totalSales: {
            amount: 31657.51,
            currency: 'USD',
          },
          campaignCount: true,
          inventoryAge: {
            invAge0To90Days: 166,
            invAge91To180Days: 10,
            invAge181To270Days: 20,
            invAge271To365Days: 30,
            invAge365PlusDays: 40,
          },
        },
      },
      {
        productGroupId: '',
        productCatalogId: 'e66830ae-5bf7-5e2e-8c38-70d84b57727d',
        parentProductCatalogId: '3aac500e-a954-5f34-b812-c795189f95af',
        merchantCountryId: 'a13d7f23-5930-4d8b-bfe9-4aa08c69f548',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        skuDetailsFields: {
          sku: '195-0833-000062FBA',
          name: 'Pulsar G1200SG 1,200W Carrying Handle Gas-Powered Portable Generator, 1200w, Black/Gray',
          imageUrl: 'https://m.media-amazon.com/images/I/71q4v6mG2zL.jpg',
          extItemDetail: {
            extItemId: 'B0BLY7M1YL',
            extItemType: 'ASIN',
            salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
            salesChannelName: 'Amazon',
          },
        },
        performance: {
          inventoryValue: undefined,
          unitCogs: undefined,
          sellThroughRate: undefined,
          inventorySalesRatio: undefined,
          daysOfInventory: undefined,
          inventoryQuantity: undefined,
          rateOfSale: undefined,
          unitsSold: undefined,
          totalSales: undefined,
          campaignCount: false,
          inventoryAge: {
            invAge0To90Days: undefined,
            invAge91To180Days: undefined,
            invAge181To270Days: undefined,
            invAge271To365Days: undefined,
            invAge365PlusDays: undefined,
          },
        },
      },
    ],
  };
});
