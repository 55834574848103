import { LevelsController } from '@teikametrics/tm-design-system';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { NavPaths } from '../../../NavPaths';
import { MerchantCountry } from '../../../lib/types/Fam';
import I18nKey from '../../../lib/types/I18nKey';
import { AMAZON_SALES_CHANNEL_ID } from '../../../lib/types/SalesChannels';
import {
  KeywordLevel,
  getKeywordLevel,
  getKeywordTabPath,
} from './KeywordActionV4';
import { RecommendationsCountContext } from './RecommendationsCountProvider';
import { RecommendationsTableV3 } from './RecommendationsTableV3';
import { NegativeRecommendationsTableV2 } from './RecommendationsTableV3/negativeRecommendationsTableV2';
import { AdType } from '../../../lib/types/AOSharedTypes';

export interface KeywordsToAddProps {
  readonly merchantCountry: MerchantCountry;
  readonly hardRefreshNegTableToggle: boolean;
  readonly advertisingType: AdType;
  readonly merchantCountryId?: string;
}

export const KeywordsToAdd: React.FC<KeywordsToAddProps> = (props) => {
  const location = useLocation();

  const intl = useIntl();

  const {
    spKeywordsByPerformanceCount,
    spKeywordsByChannelCount,
    sbKeywordsByPerformanceCount,
    sbKeywordsByChannelCount,
    negativeKeywordsByChannelCount,
    negativeKeywordsByPerformanceCount,
    spPatKeywordsToAddCount,
    sbPatKeywordsToAddCount,
  } = useContext(RecommendationsCountContext);

  const selectedLevel = location.pathname.includes('keywords-by-channel')
    ? 'keywordsByChannel'
    : 'performance';

  const keywordLevel = getKeywordLevel(location.pathname);

  const getKeywordsPerformanceCount = () => {
    if (keywordLevel === KeywordLevel.Keywords) {
      return props.advertisingType === AdType.SponsoredProducts
        ? spKeywordsByPerformanceCount
        : sbKeywordsByPerformanceCount;
    } else if (keywordLevel === KeywordLevel.NegativeKeywords) {
      return negativeKeywordsByPerformanceCount;
    }
    return props.advertisingType === AdType.SponsoredProducts
      ? spPatKeywordsToAddCount
      : sbPatKeywordsToAddCount;
  };

  const navigate = useNavigate();

  const keywordsByChannelCount = () => {
    let byChannelCount;
    if (props.advertisingType === AdType.SponsoredProducts) {
      byChannelCount = spKeywordsByChannelCount;
    } else {
      byChannelCount = sbKeywordsByChannelCount;
    }

    return keywordLevel === KeywordLevel.Keywords
      ? byChannelCount
      : negativeKeywordsByChannelCount;
  };

  return (
    <div
      className="flex flex-col gap-12 pt-16"
      style={{
        width: 'calc(100vw - 300px) !important',
      }}
    >
      {keywordLevel !== KeywordLevel.PAT && (
        <LevelsController
          active={selectedLevel}
          onChange={(level) => {
            const url =
              NavPaths.KeywordsTargets +
              '/' +
              props.merchantCountry.merchantCountryId +
              '/' +
              getKeywordTabPath(keywordLevel) +
              '/' +
              (level === 'performance'
                ? 'keywords-by-performance'
                : 'keywords-by-channel');

            navigate(url);
          }}
          levels={[
            {
              name: intl.formatMessage({
                id: I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_KEYWORDS_BY_PERFORMANCE,
              }),
              value: 'performance',
              text: `${getKeywordsPerformanceCount()}`,
            },
            {
              name: intl.formatMessage(
                {
                  id: I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_KEYWORDS_BY_CHANNEL,
                },
                {
                  channel: intl.formatMessage({
                    id:
                      props.merchantCountry.salesChannelId ===
                      AMAZON_SALES_CHANNEL_ID
                        ? I18nKey.GENERIC_AMAZON
                        : I18nKey.GENERIC_WALMART,
                  }),
                }
              ),
              value: 'keywordsByChannel',
              text: `${keywordsByChannelCount()}`,
            },
          ]}
        />
      )}
      <div className="flex">
        <Routes>
          <Route
            path="/keywords-by-performance"
            Component={() => {
              if (keywordLevel === KeywordLevel.NegativeKeywords) {
                return (
                  <NegativeRecommendationsTableV2
                    merchantCountry={props.merchantCountry}
                    hardRefreshTable={props.hardRefreshNegTableToggle}
                  />
                );
              }
              return (
                <RecommendationsTableV3
                  keywordLevel={keywordLevel}
                  merchantCountry={props.merchantCountry}
                  advertisingType={props.advertisingType}
                />
              );
            }}
          />
          <Route
            path="/keywords-by-channel"
            Component={() =>
              keywordLevel === KeywordLevel.Keywords ? (
                <RecommendationsTableV3
                  keywordLevel={keywordLevel}
                  merchantCountry={props.merchantCountry}
                  hidePerformanceColumn
                  advertisingType={props.advertisingType}
                />
              ) : (
                <NegativeRecommendationsTableV2
                  merchantCountry={props.merchantCountry}
                  hardRefreshTable={props.hardRefreshNegTableToggle}
                />
              )
            }
          />
        </Routes>
      </div>
    </div>
  );
};
