import { DateTime } from 'luxon';
import { useState } from 'react';
import { FlywheelTableV2 } from '../../containers/tableV2/flywheelTable';
import { PaginatedRequest, PaginatedResult } from '../../lib/clients/types';
import { MerchantCountryCode } from '../../lib/types/AOSharedTypes';
import { EstPreAdGrossMarginItem } from '../../lib/types/CampaignFlexibilitySharedTypes';
import { MerchantCountry } from '../../lib/types/Fam';
import {
  CurrencyCode,
  getCurrencyCodeFromMerchantCountryCode,
} from '../../lib/utilities/currency';
import { SelectedTrendsProduct } from '../../modules/products/containers/skuCatalog';
import { PerformanceSlideover } from '../../modules/products/containers/skuCatalog/performanceSlideover';
import { PerformanceSlideoverContextProvider } from '../../modules/products/containers/skuCatalog/performanceSlideover/performanceSlideoverContextProvider';
import { getEstAdGrossMarginColumns } from './EstAdGrossMarginTableColumns';
import { DEFAULT_CURRENCY_EST_PRE_AD_MARGIN } from './EstAdGrossMarginTypes';

export type TableExtraProps = {
  readonly tableId: string;
  readonly bulkInputValue?: string;
  readonly selectedMerchant?: MerchantCountry;
  readonly selectedCurrency: CurrencyCode;
  readonly onViewTrendsSlideoverClick?: (
    product: SelectedTrendsProduct
  ) => void;
  readonly bulkUpdateInProgress: boolean;
};

interface EstAdGrossMarginTableProps {
  readonly tableId: string;
  readonly selectedMerchant: MerchantCountry;
  readonly dataFetcher: (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<EstPreAdGrossMarginItem>>;
  readonly bulkInputValue?: string;
  readonly bulkUpdateInProgress: boolean;
}

export const EstAdGrossMarginTable: React.FC<EstAdGrossMarginTableProps> = ({
  tableId,
  selectedMerchant,
  dataFetcher,
  bulkInputValue,
  bulkUpdateInProgress,
}) => {
  const [showTrendsSlideover, setShowTrendsSlideover] =
    useState<boolean>(false);
  const [selectedTrendsProduct, setSelectedTrendsProduct] =
    useState<SelectedTrendsProduct>();

  const selectedCurrency =
    (getCurrencyCodeFromMerchantCountryCode(
      selectedMerchant?.country as MerchantCountryCode
    ) as CurrencyCode) || CurrencyCode.USD;

  const columns = getEstAdGrossMarginColumns();

  const onViewTrendsSlideoverClick = (product: SelectedTrendsProduct) => {
    setSelectedTrendsProduct(product);
    setShowTrendsSlideover(true);
  };

  const onViewTrendsSlideoverClose = () => {
    setShowTrendsSlideover(false);
    setSelectedTrendsProduct(undefined);
  };

  const cellClassName = 'border-t-0 overflow-visible';
  const headerClass = 'bg-white';
  const tableClassName = 'border-solid h-estPreAdGrossMarginTable';

  return (
    <>
      {showTrendsSlideover && selectedTrendsProduct && (
        <PerformanceSlideoverContextProvider
          currency={selectedCurrency}
          defaultDateRange={{
            startDate: DateTime.now().minus({ days: 31 }),
            endDate: DateTime.now().minus({ day: 1 }),
          }}
          {...selectedTrendsProduct}
        >
          <PerformanceSlideover onClose={onViewTrendsSlideoverClose} />
        </PerformanceSlideoverContextProvider>
      )}
      <FlywheelTableV2<EstPreAdGrossMarginItem, {}, TableExtraProps, {}>
        columns={columns}
        currencyCode={DEFAULT_CURRENCY_EST_PRE_AD_MARGIN}
        dataFetcher={dataFetcher}
        tableId={tableId}
        tableData={{
          tableId,
          bulkInputValue,
          selectedMerchant,
          selectedCurrency: DEFAULT_CURRENCY_EST_PRE_AD_MARGIN,
          onViewTrendsSlideoverClick,
          bulkUpdateInProgress,
        }}
        tableClassName={tableClassName}
        isTableActionRowDisabled
        roundedTop={false}
        dataFields={[]}
        loadingAndEmptyStateClassName="pt-140 mb-20"
        tableEmptyStateClass="w-full border-solid border-t border-grey-200"
        cellClassName={cellClassName}
        headerClass={headerClass}
        isFooterDisabled
      />
    </>
  );
};

EstAdGrossMarginTable.displayName = 'EstAdGrossMarginTable';
