import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system';
import {
  getCurrentAccountFromContext,
  hasPaymentInfo,
  isAIPlanEnabled,
  isManagedAccount,
  isSelfServiceAccount,
} from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { ManagedEdition } from '../ManagedEdition';
import I18nKey from '../../lib/types/I18nKey';
import { ManagedEditionSlideOut } from '../ManagedEditionSlideOut';
import RiskFreeTrialModal from '../BillingModals/RiskFreeTrialModal/RiskFreeTrialModal';
import { segmentTrack } from '../../lib/utilities/segment';

interface AdditionalTagsProps {
  hideFreeTrialContent?: boolean;
}

export const AdditionalTags: React.FC<AdditionalTagsProps> = ({
  hideFreeTrialContent,
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const currentAccount = getCurrentAccountFromContext(userContext);

  const [showManagedEditionSlideOut, setShowManagedEditionSlideOut] =
    useState<boolean>(false);
  const [showRiskFreeTrial, setShowRiskFreeTrial] = useState(false);

  const onClick = () => {
    setShowManagedEditionSlideOut((previous) => !previous);
  };

  const isAiEnabled = isAIPlanEnabled(userContext);
  const hasGivenPayment = hasPaymentInfo(userContext);

  if (
    !hideFreeTrialContent &&
    isSelfServiceAccount(currentAccount) &&
    !isAiEnabled &&
    !hasGivenPayment
  ) {
    return (
      <>
        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          label={
            <FormattedMessage
              id={I18nKey.RISK_FREE_TRIAL_PILL_START_RISK_FREE_DESCRIPTION}
            />
          }
          className="mx-8"
          onClick={() => {
            setShowRiskFreeTrial(true);
            segmentTrack('start_risk_free_trial_clicked', {
              accountId: currentAccount?.id,
              accountType: currentAccount?.accountType,
            });
          }}
          dataTestId="upgrade"
        />
        {showRiskFreeTrial && (
          <RiskFreeTrialModal
            onCancelClicked={() => {
              setShowRiskFreeTrial(false);
            }}
          />
        )}
      </>
    );
  }
  if (isManagedAccount(userContext)) {
    return (
      <div className="flex mx-12">
        <ManagedEditionSlideOut
          showManagedEditionSlideOut={showManagedEditionSlideOut}
          closeManagedEditionSlideOut={onClick}
        />
        <ManagedEdition onManagedEditionClick={onClick} />
      </div>
    );
  }
  return <></>;
};

AdditionalTags.displayName = 'AdditionalTags';
