import './styles.scss';
import Highcharts from 'highcharts';
import React from 'react';
import {
  PieChart as PieChartSB,
  PieChartTitleElement,
} from '@teikametrics/tm-design-system';

export interface PieSliceData {
  readonly name: string;
  readonly color: string;
  readonly isVisible: boolean;
  readonly value: number;
  readonly tooltipElement?: React.ReactElement;
  readonly hoverColor?: string;
  readonly y?: number;
}

export interface PieChartProps {
  readonly onSliceClick?: (name: string, value?: number) => void;
  readonly pieSliceData: PieSliceData[];
  readonly titleElement?: PieChartTitleElement;
  readonly disabledPointColor?: string;
  readonly height?: string;
  readonly width?: string;
  readonly innerCircleSize?: string;
  readonly displayTooltipOutsideOfChart?: boolean;
  readonly distanceFromPointToTooltip?: number;
  readonly callback: (chartInstance: Highcharts.Chart) => void;
}

export const PieChart: React.FC<PieChartProps> = (props) => {
  return (
    <div className="pie-chart-container">
      <PieChartSB {...props} />
    </div>
  );
};
PieChart.displayName = 'PieChart';
