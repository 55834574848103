import React from 'react';

import { Icon, IconSize } from '@teikametrics/tm-design-system';

import { MerchantCountry } from '../../lib/types/Fam';
import { getCountryFlagIcon } from '../../lib/utilities/countryFlags';

interface MCListRowProps {
  readonly merchantCountry: MerchantCountry;
}

export const McListRow: React.FC<MCListRowProps> = ({ merchantCountry }) => {
  return (
    <div className="flex flex-row gap-x-8 items-center w-full">
      <Icon
        svg={getCountryFlagIcon(merchantCountry.country)}
        size={IconSize.Medium}
        className="min-w-16"
      />
      <div className="text-sm leading-none text-grey-900 font-normal">
        {merchantCountry.country}
      </div>
      <div className="pl-4 text-sm leading-none text-grey-900 font-normal break-words line-clamp-1">
        {merchantCountry.merchantName}
      </div>
    </div>
  );
};
