import { Factory } from 'fishery';

import { PaginatedRequest } from '../../clients/types';
import { SortOrder } from '../../types/Sort';

export default Factory.define<PaginatedRequest>(() => ({
  sorts: [{ column: 'created_at', direction: SortOrder.Desc }],
  filters: [],
  itemsPerPage: 25,
  page: 1,
}));
