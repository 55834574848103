import {
  ColumnAlign,
  EmDash,
  TableCellV2,
  TableCellV2Type,
} from '@teikametrics/tm-design-system';
import { FormattedMessage } from 'react-intl';

import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import { MerchantType, SalesChannelData } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { RowCellElementProps, SalesChannelTableData } from '../types';
import React from 'react';

export const typeColumn: FlywheelTableV2ColumnProps<
  SalesChannelData,
  {},
  SalesChannelTableData,
  {}
> = {
  header: I18nKey.MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_TYPE,
  cell: ({ row: { original } }) => <TypeCellElement {...original} />,
  accessorKey: 'ext_merchant_type',
  sortable: true,
  align: ColumnAlign.Left,
};

export const TypeCellElement: React.FC<RowCellElementProps> = ({
  extMerchantType,
}) => {
  const getCellValue = () => {
    if (!extMerchantType) {
      return <EmDash />;
    }

    if (extMerchantType.toLowerCase() === MerchantType.Seller) {
      return (
        <FormattedMessage
          id={I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_TYPE_SELLER}
        />
      );
    }

    return (
      <FormattedMessage
        id={I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_TYPE_VENDOR}
      />
    );
  };

  return (
    <TableCellV2
      tableCellData={{
        currentValue: getCellValue(),
        tableCellType: TableCellV2Type.Text,
      }}
      className="capitalize p-12"
    />
  );
};
