import {
  Alignment,
  CircleStrike,
  FormToggle,
  FormToggleSize,
  FormToggleState,
  Icon,
  IconSize,
  Placement,
  ToastVariant,
  Tooltip,
  Type,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  NotificationContext,
  NotificationContextState,
} from '../../containers/notificationProvider';
import { AOApiClient } from '../../lib/clients/AOApiClient';
import { UserAutoNegateSettings } from '../../lib/types/AOSharedTypes';
import I18nKey from '../../lib/types/I18nKey';

interface AutoNegateSettingsProps {
  readonly aoAPIClient: AOApiClient;
  readonly accountId: string;
  readonly userId: string;
  readonly fetchAutoNegateStatus: () => void;
  readonly isAutoNegateUpdating: boolean;
  readonly userPreferenceSettings?: UserAutoNegateSettings;
  readonly disabled?: boolean;
}

export const AutoNegateSettings: React.FC<AutoNegateSettingsProps> = ({
  userPreferenceSettings,
  aoAPIClient,
  accountId,
  userId,
  fetchAutoNegateStatus,
  isAutoNegateUpdating,
}) => {
  const intl = useIntl();

  const toasts = useContext<NotificationContextState>(NotificationContext);

  const [localEnabled, setLocalEnabled] = useState(
    userPreferenceSettings?.isAutoNegateEnabled
  );

  const [savingAutoNegateStatus, setSavingAutoNegateStatus] = useState(false);

  const onAutoNegateToggle = (isAutoNegateEnabled: boolean) => {
    if (userPreferenceSettings) {
      setSavingAutoNegateStatus(true);

      aoAPIClient
        .saveAutoNegateSettings(accountId, {
          userId,
          settings: [
            {
              ...userPreferenceSettings,
              isAutoNegateEnabled: isAutoNegateEnabled,
            },
          ],
        })
        .then(() => {
          fetchAutoNegateStatus();
          toasts.addNotification({
            headline: intl.formatMessage(
              {
                id: I18nKey.KWA_AUTO_NEGATE_TOAST_AUTO_NEGATE_SUCCESS,
              },
              {
                isDisabled: !isAutoNegateEnabled,
              }
            ),
            type: Type.Success,
            description: '',
            variant: ToastVariant.Simple,
          });
          setLocalEnabled(isAutoNegateEnabled);
        })
        .catch(() => {
          toasts.addNotification({
            headline: intl.formatMessage({
              id: I18nKey.GENERIC_SOMETHING_WENT_WRONG,
            }),
            type: Type.Attention,
            description: '',
            variant: ToastVariant.Simple,
          });
        })
        .finally(() => {
          setSavingAutoNegateStatus(false);
        });
    }
  };

  return (
    <div
      className={classNames(
        'flex justify-between',
        'h-32 bg-grey-50',
        'border border-solid border-grey-200',
        'text-base'
      )}
    >
      <div className="flex justify-center items-center w-210 gap-8">
        <div className="flex justify-center items-center gap-4">
          <Icon
            size={IconSize.Medium}
            svg={CircleStrike}
            className="text-grey-500"
          />
          <FormattedMessage id={I18nKey.KWA_AUTO_NEGATE_SETTINGS_HEADER} />
          <div className="w-16 h-16" id="kwa_auto_negate_tooltip"></div>
        </div>
        <div className="flex justify-center items-center gap-4 ml-4">
          <FormToggle
            size={FormToggleSize.Small}
            state={localEnabled ? FormToggleState.On : FormToggleState.Off}
            onClick={() => onAutoNegateToggle(!localEnabled)}
            disabled={isAutoNegateUpdating || savingAutoNegateStatus}
          />

          <div className="ml-4">
            {localEnabled ? (
              <FormattedMessage
                id={I18nKey.KWA_AUTO_NEGATE_SETTINGS_TOGGLE_ON}
              />
            ) : (
              <FormattedMessage
                id={I18nKey.KWA_AUTO_NEGATE_SETTINGS_TOGGLE_OFF}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export const AutoNegateSettingsWrapper: React.FC<AutoNegateSettingsProps> = (
  props
) => {
  const intl = useIntl();
  if (props.disabled) {
    return (
      <Tooltip
        content={
          <Typography
            size={TypographySize.sm}
            lineHeight={TypographyLineHeight.none}
            weight={TypographyWeight.regular}
            className="text-center"
          >
            {intl.formatMessage({
              id: I18nKey.KWA_AUTO_NEGATE_WALMART_NOT_AVAILABLE_TOOLTIP,
            })}
          </Typography>
        }
        position={{
          placement: Placement.Right,
          alignment: Alignment.Center,
        }}
      >
        <AutoNegateSettings {...props} />
      </Tooltip>
    );
  }

  return <AutoNegateSettings {...props} />;
};

AutoNegateSettingsWrapper.displayName = 'AutoNegateSettings';
