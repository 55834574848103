import {
  MultiSelectOption,
  SelectOptionProps,
  SelectState,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupStatus,
  ProductAdDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_SV_ADS_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import { ProductAdStatusOptions, isAdGroupStatusArchived } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const updatedAdStatus = useSelector<WithTable<ProductAdDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.adItemId,
        SV_ADS_API_COLUMN_NAME.AdStatus
      )(tableState, ADS_MANAGER_SV_ADS_TABLE_ID)
  );

  const currentAdStatus = props.channelSettings.status;

  const currentRowIndex = useSelector<WithTable<ProductAdDetails>, number>(
    ({ tableState }) => {
      const visibleData = tableSelectors.getVisibleData(
        tableSelectors.getTableSelector<ProductAdDetails, void>()(
          tableState,
          ADS_MANAGER_SV_ADS_TABLE_ID
        )
      );
      const index = (function () {
        for (let i = 0; i < visibleData.length; i++) {
          if (visibleData[i].adItemId === props.adItemDetails.adGroupId) {
            return i;
          }
        }
        return -1;
      })();

      return index;
    }
  );

  if (props.isEditMode) {
    const adStatusOptions = getAdStatusSelectOptionsForEdit(intl);

    const adStatus = updatedAdStatus ?? currentAdStatus;

    return makeSelectColumn((data: ProductAdDetails) => ({
      dataTestId: SV_ADS_API_COLUMN_NAME.AdStatus,
      wrapperClassName: 'w-120',
      options: adStatusOptions,
      placeholder: '',
      value: adStatus,
      isDirty: adStatus !== currentAdStatus,
      state: SelectState.Default,
      onChange: (value: string) => {
        dispatch(
          tableActions.updateCell({
            tableId: ADS_MANAGER_SV_ADS_TABLE_ID,
            rowId: data.adItemId,
            columnName: SV_ADS_API_COLUMN_NAME.AdStatus,
            value,
            existingValue: currentAdStatus,
          })
        );
      },
      showOnTop: currentRowIndex > 6,
    }))(props);
  }

  return makeTextColumn(
    ({ channelSettings }: ProductAdDetails) => channelSettings.status,
    props?.pendingFields?.includes(SV_ADS_API_COLUMN_NAME.AdStatus)
  )(props);
};

RowCellElement.displayName = 'AdGroupNameColumn';

export const adStatus: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: SV_ADS_API_COLUMN_NAME.AdStatus,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_AD_STATUS,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const adStatusFilter = createMultiSelectDataFieldFilter(
  SV_ADS_API_COLUMN_NAME.AdStatus,
  I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_AD_STATUS,
  [
    SelectFilterOption.CampaignStatusEnabled,
    SelectFilterOption.CampaignStatusPaused,
    SelectFilterOption.CampaignStatusArchived,
  ]
);

export const getAdStatusSelectOptionsForEdit = (
  intl: IntlShape
): SelectOptionProps<string>[] =>
  getAdStatusFilterOptions().map((option) => ({
    label: intl.formatMessage({ id: option.displayI18nKey }),
    value: option.value,
  }));

export const getAdStatusFilterOptions = (): MultiSelectOption[] => {
  return [
    {
      value: ProductAdStatusOptions.Enabled.value,
      displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED,
    },
    {
      value: ProductAdStatusOptions.Disabled.value,
      displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_DISABLED,
    },
  ];
};

export const getIsAdGroupArchived = (adGroupStatus: AdGroupStatus) =>
  isAdGroupStatusArchived(adGroupStatus);
