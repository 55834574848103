import classNames from 'classnames';
import { useIntl } from 'react-intl';

import {
  AlertTriangleIcon,
  Icon,
  IconSize,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../lib/types/I18nKey';

const WALMART_AUTHORIZATION_HELP_LINK = `https://developer.walmart.com/doc/us/mp/us-mp-authorize/`;

export const WalmartAccessDeniedBanner: React.FC = () => {
  const intl = useIntl();
  const authorizationFailureHeader = intl.formatMessage({
    id: I18nKey.FLYWHEEL_AUTHORIZE_TEXT,
  });
  const authorizationFailureMessage = intl.formatMessage({
    id: I18nKey.FLYWHEEL_AUTHORIZE_TEXT_INFO,
  });

  const helpLinkText = intl.formatMessage({
    id: I18nKey.FLYWHEEL_AUTHORIZE_HELP_LINK,
  });

  return (
    <div
      className={classNames(
        'flex flex-row w-full',
        'bg-orange-100 rounded-4 p-16 mt-12'
      )}
    >
      <div className="flex">
        <Icon
          svg={AlertTriangleIcon}
          size={IconSize.Large}
          className="text-orange-500"
        />
      </div>
      <div className="flex flex-col ml-12 pt-4">
        <h3
          className={classNames(
            'flex mb-4',
            'text-base leading-tight font-medium text-grey-900 text-left'
          )}
        >
          {authorizationFailureHeader}
        </h3>
        <label
          className={classNames(
            'mt-4',
            'text-sm leading-tight font-normal text-grey-900 text-left'
          )}
        >
          {authorizationFailureMessage}
          &nbsp;
          <a
            href={WALMART_AUTHORIZATION_HELP_LINK}
            className="underline"
            target="_blank"
            rel="noreferrer"
          >
            {helpLinkText}
          </a>
        </label>
      </div>
    </div>
  );
};
