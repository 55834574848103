import {
  getDataAvailability,
  getMerchantCountryDataAvailabilityInfo,
} from '../../../../../lib/utilities/connectionStatus';
import { createAOApiClient } from '../../../../../lib/clients/AOApiClient';
import { createFAMApiClient } from '../../../../../lib/clients/FAMApiClient';
import { createSKUApiClient } from '../../../../../lib/clients/SKUApiClient';
import { UserContextState } from '../../../../../containers/userProvider/userProvider';
import {
  FlywheelSalesChannel,
  SalesChannelData,
} from '../../../../../lib/types/Fam';
import { MerchantSyncData } from '../../../../../lib/types/AOSharedTypes';
import {
  PaginatedRequest,
  PaginatedResult,
} from '../../../../../lib/clients/types';

export const dataFetcherSalesChannelsTable =
  (
    userContext: UserContextState,
    accountIdForDatafetcher: string,
    salesChannelId: string,
    saleChannelName: FlywheelSalesChannel
  ) =>
  async (
    paginatedRequest: PaginatedRequest
  ): Promise<PaginatedResult<SalesChannelData & MerchantSyncData>> => {
    const famClient = createFAMApiClient(userContext.userInfo.idToken!);
    const aoClient = createAOApiClient(userContext.userInfo.idToken!);
    const skuClient = createSKUApiClient(userContext.userInfo.idToken!);

    const { items, totalItems } = await famClient.getSalesChannelTableData(
      accountIdForDatafetcher,
      salesChannelId
    )(paginatedRequest);

    const { aoDataAvailabilityInfo, skuDataAvailabilityInfo } =
      await getMerchantCountryDataAvailabilityInfo(
        famClient,
        aoClient,
        skuClient,
        accountIdForDatafetcher,
        items
      );

    // walmart does not need the data availability api, just use the returned status
    // this should be part of the refactor from https://teikametrics.atlassian.net/browse/PS-133
    if (saleChannelName === 'walmart') {
      const itemsWithAvailability = items.map((item) => ({
        ...item,
        adConnectionData: aoDataAvailabilityInfo.find(
          (info) => info.merchantCountryId === item.id
        ),
        productConnectionData: skuDataAvailabilityInfo.find(
          (info) => info.merchantCountryId === item.id
        ),
      }));
      return { items: itemsWithAvailability, totalItems };
    }

    const itemsWithAvailability = items.map((item) => ({
      ...item,
      adConnectionStatus: getDataAvailability(
        item.adConnectionStatus,
        item.id,
        aoDataAvailabilityInfo
      ),
      productConnectionStatus: getDataAvailability(
        item.productConnectionStatus,
        item.id,
        skuDataAvailabilityInfo
      ),
      adConnectionData: aoDataAvailabilityInfo.find(
        (info) => info.merchantCountryId === item.id
      ),
      productConnectionData: skuDataAvailabilityInfo.find(
        (info) => info.merchantCountryId === item.id
      ),
    }));

    return {
      items: itemsWithAvailability,
      totalItems,
    };
  };
