import { Money } from '@teikametrics/tm-design-system';
import { BackgroundJobEventTypes } from '../../modules/marketIntelligence/containers/reporting/types';
import { DateRange, PaginatedResult } from '../clients/types';
import { CurrencyCode } from '../utilities/currency';

export const DEFAULT_SELECTED_PLATFORM_INDEX: number = 0;
export const SEARCH_TERM_KEY_VALUE = 'search_term';
export const PLATFORM_KEY_VALUE = 'platform';
export const MI_BASE_URL = process.env.REACT_APP_MI_HOST;
export interface PlatformData {
  readonly id: number;
  readonly name: string;
  readonly active: boolean;
  readonly countries: string[] | null;
}

export enum SearchTermStatus {
  Fetching = 'Fetching',
  Active = 'Active',
}

export type PlatformType =
  | 'Amazon'
  | 'Walmart'
  | 'Target'
  | 'Google Shopping'
  | 'eBay';

export enum Platforms {
  Amazon = 'Amazon',
  Walmart = 'Walmart',
  Target = 'Target',
  Google_Shopping = 'Google Shopping',
  eBay = 'eBay',
}
export type SearchTermsData = {
  readonly search_term: string;
  readonly locale: string;
  readonly created_at: string;
  readonly status: SearchTermStatus;
  readonly platform: PlatformType;
  readonly is_group?: boolean;
  readonly is_grouped_term?: boolean;
  readonly group_name?: string;
  readonly group_id?: string;
  readonly child_search_terms?: SearchTermsData[];
  readonly search_term_id?: string | null;
  readonly subRows?: SearchTermsData[];
};

export interface NewlyIndentifiedSearchTerms {
  readonly search_term: string;
  readonly sales_channel: string;
  readonly country: PlatformType;
}

export interface ReportingRequestParams {
  readonly rop_id: string;
  readonly search_term: string;
  readonly locale: string;
  readonly platform: string;
  readonly start_date: string;
  readonly end_date: string;
  readonly includeUnIndentifiedBrands: string;
  readonly group_name?: string;
}
export interface BulkExportRequestPayload {
  readonly search_term: string;
  readonly country: string;
}
export interface BulkExportRequestParams {
  readonly rop_id: string;
  readonly platform: string;
  readonly start_date: string;
  readonly end_date: string;
  readonly payload: BulkExportRequestPayload[];
}

export interface BulkExportRequestSearchTerms {
  readonly groupName: string | null;
  readonly searchTerms: string;
  readonly country: string;
}
export interface BulkExportRequestParamsV2 {
  readonly ropId: string;
  readonly salesChannel: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly searchTerms: BulkExportRequestSearchTerms[];
}
export interface BCSChartsDataResponse {
  readonly total: BCSChartsData;
  readonly organic: BCSChartsData;
  readonly paid: BCSChartsData;
  readonly n_days_of_data: number;
  readonly n_days_in_time_range: number;
  readonly dates_of_data: string[];
  readonly timezone: string;
  readonly show_last_timing: boolean;
}

interface BCSChartsData {
  readonly bcs: PieChartData[];
  readonly daily_bcs: LineChartData[];
}

export interface PieChartData {
  readonly name: string;
  readonly value: number;
  readonly numOfOtherBrands?: string;
}

export interface LineChartSequenceData {
  readonly date: string;
  readonly value: number;
}

export interface LineChartData {
  readonly name: string;
  readonly sequence: LineChartSequenceData[];
}

export interface AverageProductRankData extends Record<string, unknown> {
  readonly product_id: string;
  readonly title: string;
  readonly image: string;
  readonly brand_name: string;
  readonly appearances_percentage: number;
  readonly avg_rank_total: number;
  readonly avg_rank_paid: number | null;
  readonly avg_rank_organic: number | null;
  readonly url: string;
}

export interface BrandCoverageOnSearchData extends Record<string, unknown> {
  readonly brand_name: string;
  readonly appearances_percentage: number;
  readonly total_bcs: number;
  readonly paid_bcs: number;
  readonly organic_bcs: number;
}

export interface SearchTermsChartsQueryParams {
  readonly search_term: string;
  readonly start_date: string;
  readonly end_date: string;
  readonly locale: string;
  readonly platform: string;
  readonly includeUnIndentifiedBrands: string;
}

export const MISSING_VALUE = 'is missing';
export const WRONG_VALUE = 'uses wrong';

export type CsvErrorType = typeof MISSING_VALUE | typeof WRONG_VALUE;

export interface ErrorsCsv {
  readonly row_index: number;
  readonly value: string;
  readonly error_type: CsvErrorType;
  readonly dataTestId?: string;
}

export interface Country {
  readonly countryCode: string;
  readonly countryName: string;
}

export interface NewlyAddedTermsGradient {
  readonly newlyAddedTerms: number;
  readonly showGradient: boolean;
}

export interface PlatformDataWithCountries {
  readonly id: number;
  readonly name: string;
  readonly active: boolean;
  readonly countries: Country[];
}

export interface NewlyAddedTermsGradientProps {
  readonly newlyAddedTerms: number;
  readonly showGradient: boolean;
}

export interface TermCountryChannel {
  readonly search_term: string;
  readonly country: string;
  readonly sales_channel: string;
}

export interface AddNewSearchTermsStatusRequestPayload {
  readonly search_terms: TermCountryChannel[];
  readonly account_id: string;
}

export interface AddNewSearchTermsRequestPayload {
  readonly search_terms: TermCountryChannel[];
  readonly account_id: string;
  readonly source: SearchTermSource;
  readonly suggested_search_term_data?: SuggestedSearchTermRequestData;
}

export interface IgnoreSuggestedSearchTermsRequestPayload {
  readonly search_terms: string[];
  readonly account_id: string;
  readonly mcid: string;
}

export interface SuggestedSearchTermRequestData {
  readonly mcid: string;
  readonly country: string;
  readonly sales_channel: string;
  readonly suggested_terms: SuggestedKeywords[];
}

export interface AddNewSearchTermsResponse {
  readonly n_search_terms: number;
  readonly last_updated: string;
  readonly max_free_tracked_search_term: number;
  readonly max_total_tracked_search_term: number;
}

interface AddNewTermsStatusError {
  readonly row_index: number;
  readonly error_type: number;
  readonly value: number;
}

export interface AddNewSearchTermsStatusResponse {
  readonly new_search_terms: TermCountryChannel[];
  readonly existing_search_terms: TermCountryChannel[];
  readonly duplicated_search_terms: TermCountryChannel[];
  readonly error_messages: AddNewTermsStatusError[];
  readonly n_input_search_terms: number;
}

export interface PublicAPISearchTermsData {
  readonly status: SearchTermStatus;
  readonly country: string;
  readonly created_at: string;
  readonly search_term: string;
  readonly sales_channel: PlatformType;
  readonly search_term_id: string;
}

export interface PublicAPISearchTermsAndGroupsDataSearchTerms {
  readonly group_id: string;
  readonly search_term_id: string | null;
  readonly group_name: string;
  readonly search_term: string;
  readonly group_created_at: string;
  readonly searchterm_created_at: string;
  readonly country: string;
  readonly sales_channel: PlatformType;
  readonly status: SearchTermStatus;
}

export interface PublicAPISearchTermsAndGroupsData
  extends PublicAPISearchTermsAndGroupsDataSearchTerms {
  readonly search_terms: PublicAPISearchTermsAndGroupsDataSearchTerms[];
}

export interface MIPublicAPISearchTermsResponse {
  readonly n_search_terms: number;
  readonly max_free_tracked_search_term: number;
  readonly max_total_tracked_search_term: number;
  readonly search_terms: PublicAPISearchTermsData[];
  readonly n_filtered_search_terms: number;
  readonly last_updated: string;
  readonly is_search_term_number_unlimited: boolean;
}

export interface MIPublicAPISearchTermsAndGroupsResponse {
  readonly n_search_terms: number;
  readonly max_free_tracked_search_term: number;
  readonly max_total_tracked_search_term: number;
  readonly search_terms_and_groups: PublicAPISearchTermsAndGroupsData[];
  readonly n_total_rows: number;
  readonly n_groups: number;
  readonly last_updated: string;
  readonly is_search_term_number_unlimited: boolean;
  readonly n_filtered_search_terms_and_groups: number;
}
export interface MIPublicAPITermsResponse {
  readonly search_terms_data: MIPublicAPISearchTermsResponse;
  readonly n_suggested_terms: number;
  readonly suggestions_available_for_mcids: string[];
}

interface MISearchTermsAdditionalMetadataResult {
  readonly totalSearchTerms: number;
  readonly lastUpdated: string;
  readonly searchTermsLimit: number;
  readonly freeSearchTermsLimit: number;
  readonly isSearchTermNumberUnlimited: boolean;
  readonly totalGroups: number;
  readonly filteredSearchTermsAndGroups: number;
}

export type MISearchTermsPaginatedResult = PaginatedResult<SearchTermsData> &
  MISearchTermsAdditionalMetadataResult;

export type SearchTermsDataResponse = PaginatedResult<SearchTermsData>;

export type BrandCoverageOnSearchResponse =
  PaginatedResult<BrandCoverageOnSearchData>;

export type AverageProductRankResponse =
  PaginatedResult<AverageProductRankData>;
export interface UploadSearchTermsStatusResponse {
  readonly n_input_search_terms: number;
  readonly new_search_terms: NewlyIndentifiedSearchTerms[];
  readonly existing_search_terms: NewlyIndentifiedSearchTerms[];
  readonly duplicated_search_terms: NewlyIndentifiedSearchTerms[];
  readonly error_messages: ErrorsCsv[];
}

export interface SuggestedTermsSelectedRowData {
  readonly id: string;
  readonly searchTerm: string;
  readonly selectedValues: string[];
  readonly allSelected: boolean;
}

export interface SummaryData extends Record<string, unknown> {
  readonly searchTerm: string;
  readonly selectedSalesChannelValue: string;
  readonly selectedCountryValueShort: string;
  readonly selectedCountryValueFull: string;
  readonly groupId?: string;
  readonly groupName?: string;
  readonly id?: string;
}

export enum SearchTermSource {
  Bulk = 'Bulk',
  Manual = 'Manual',
  Suggestion = 'Suggestion',
}

export interface SuggestedKeywords extends Record<string, unknown> {
  readonly id: string;
  readonly searchTerm: string;
  readonly acosTotal: number;
  readonly roasTotal: number;
  readonly adSalesTotal: number;
  readonly adSpend: number;
  readonly trackedOn?: TrackedOn;
}

export interface TrackedOn {
  [key: string]: [string];
}

export interface MoneyWithAmountInString {
  readonly amount: number;
  readonly currency: string;
}

export interface Step2Options {
  [key: string]: Step2Option[];
}

export interface Step2Option {
  readonly countryCode: string;
  readonly value: string;
}

export interface SuggestedSearchTermsRequestResponse {
  readonly suggestedTerms: SuggestedKeywords[];
  readonly hasNoRecommendations: boolean;
}

export interface SuggestedSearchTermsRequestPayload {
  readonly startDate: string;
  readonly endDate: string;
  readonly mcid: string;
  readonly salesChannel: string;
  readonly countryCode: string;
}

export interface SuggestedSearchTermsCountRequestPayload {
  readonly ropId: string;
  readonly mcids: string[];
  readonly startDate: string;
  readonly endDate: string;
}

export interface SuggestedSearchTermsCountResponse {
  readonly merchantCountryId: string;
  readonly countOfSuggestedTerms: number;
}
export interface GroupsData {
  readonly id: string;
  readonly groupName: string;
  readonly salesChannel: PlatformType | null;
  readonly country: string | null;
  readonly noOfTerms: number;
  readonly createdAt: string;
  readonly updatedAt: string;
}

export interface CreateGroupRequestPayload {
  readonly groupName: string;
  readonly searchTerms: SearchTerms[];
  readonly country: string;
  readonly salesChannel: PlatformType;
}

export interface AddOrRemoveSearchTermsToOrFromGroupRequestPayload {
  readonly searchTerms: SearchTerms[];
  readonly country: string;
  readonly salesChannel: PlatformType;
  readonly eventType: AddOrRemoveSearchTermsToOrFromGroupEventType;
  readonly shouldDeleteGroup: boolean;
}

export interface SearchTerms {
  search_term: string;
  search_term_id?: string | null;
}

export enum AddOrRemoveSearchTermsToOrFromGroupEventType {
  ADD_SEARCH_TERMS = 'ADD_SEARCH_TERMS',
  REMOVE_SEARCH_TERMS = 'REMOVE_SEARCH_TERMS',
}

export interface UpdateGroupRequestPayload {
  readonly groupName: string;
}

export interface DeleteGroupRequestPayload {
  readonly shouldDeleteSearchTerms: boolean;
}

export interface CheckedSearchTermsData {
  readonly search_term: string;
  readonly locale: string;
  readonly platform: PlatformType;
  readonly is_group?: boolean;
  readonly is_grouped_term?: boolean;
  readonly group_name?: string;
  readonly group_id?: string;
  readonly child_search_terms?: SearchTermsData[];
  readonly search_term_id?: string | null;
}

export interface EditGroupData {
  readonly groupName?: string;
  readonly groupId?: string;
  readonly locale: string;
  readonly platform: PlatformType;
}

export interface PublicAPISearchTermsRequest {
  filter?: string;
  sort?: string;
  offset?: string;
  limit?: string;
}

export interface PublicAPISearchTermsResponse {
  searchTerms: SearchTermsData[];
  totalSearchTerms: number;
}

export interface AutoCompleteSearchTermsRequestPayload {
  readonly ropId: string;
  readonly search_term: string;
  readonly limit?: number;
}

export interface SelectedTerm {
  readonly searchTerm: string;
  readonly localeShort: string;
  readonly platform: PlatformType;
  readonly isGroup?: boolean;
  readonly isGroupedTerm?: boolean;
  readonly groupName?: string;
  readonly groupId?: string | null;
  readonly childSearchTerms?: SearchTermsData[];
  readonly searchTermId?: string | null;
}

export interface AddOrRemoveSearchTermsToOrFromGroupResponse {
  readonly no_of_terms_added_or_removed: number;
  no_of_terms_given: number;
}

export interface SearchTermGroupData {
  readonly search_term: string;
  readonly country: string;
  readonly sales_channel: string;
  readonly group_id?: string;
  readonly group_name?: string;
}

export interface AddNewSearchTermsV3RequestPayload {
  readonly search_terms: SearchTermGroupData[];
  readonly account_id: string;
  readonly source: SearchTermSource;
  readonly suggested_search_term_data?: SuggestedSearchTermRequestData;
}

export interface AddNewSearchTermsV3Response {
  readonly n_search_terms: number;
  readonly last_updated: string;
  readonly max_free_tracked_search_term: number;
  readonly max_total_tracked_search_term: number;
  readonly error_message: string;
}

export interface DashboardQueryParams {
  readonly searchTerm: string | null;
  readonly platform: string | null;
  readonly country: string | null;
  readonly startDate: string | null;
  readonly endDate: string | null;
  readonly tab: string | null;
}

export interface TimeFrame extends DateRange {
  readonly title: string;
}

export interface ReportHistoryMetadata {
  readonly country: string;
  readonly endDate: string;
  readonly startDate: string;
  readonly searchTerms: string;
  readonly groupName: string;
  readonly salesChannel: PlatformType;
}

export interface ReportHistoryData {
  readonly bcsData: BCSChartsDataResponse;
  readonly brandsBcs: {
    brands_bcs: BrandCoverageOnSearchData[];
    n_of_brands: number;
  };
  readonly productsBcs: {
    products_bcs: AverageProductRankData[];
    n_of_products: number;
  };
}

export interface ReportHistory extends Record<string, unknown> {
  readonly data: ReportHistoryData;
  readonly metadata: ReportHistoryMetadata;
}

export interface ReportStatusAndData {
  readonly status: BackgroundJobEventTypes | null;
  readonly report: ReportHistory | null;
}

export interface BCSBackgroundRequests extends Record<string, unknown> {
  readonly background_job_id: string;
  readonly status: BackgroundJobEventTypes;
  readonly job_data: ReportHistoryMetadata;
  readonly created_at: string;
  readonly updated_at: string;
}

export interface SovProductMetadata {
  ext_item_id: string;
  title: string;
  image: string;
  sku: string;
  parent_sku?: string;
  parent_asin?: string;
}

export interface ShareOfVoice {
  organic?: number | null;
  paid?: number | null;
  total?: number | null;
}

export type SovProduct = {
  product_catalog_id: string;
  metadata: SovProductMetadata;
  share_of_voice?: ShareOfVoice;
  appearance_percent?: ShareOfVoice;
  pg1_avg_rank?: ShareOfVoice;
  sku_children: SovProduct[];
  is_tracked_product?: boolean;
};

export interface SovProductsResponse {
  readonly products: SovProduct[];
  readonly total_products: number;
  readonly last_updated_at: string;
}

export type SovKeyword = {
  keyword: string;
  appearance_percent?: number | null;
  search_volume?: number | null;
  share_of_voice?: number;
  pg1_avg_rank?: number | null;
  impressions?: number | null;
};

export interface SovKeywordRequest {
  startDate: string;
  endDate: string;
  performanceFieldType: string;
  performanceFields: string[];
}

export interface SovKeywordsResponse {
  readonly keywords: SovKeyword[];
  readonly total_keywords: number;
}

export interface SovTimeSeries extends ShareOfVoice {
  date: string;
}

export interface SovTrend {
  aggregated: ShareOfVoice;
  daily: SovTimeSeries[];
  weekly: SovTimeSeries[];
  monthly: SovTimeSeries[];
}

export interface SovTrendAdSpend extends SovTrend {
  currency_code: CurrencyCode | null;
}

export interface SovProductsTrendResponse {
  appearance_percent?: SovTrend | null;
  pg1_avg_rank?: SovTrend | null;
  share_of_voice?: SovTrend | null;
  ad_spend?: SovTrendAdSpend | null;
}

export interface SovProductsTrendRequestResponse {
  readonly current: SovProductsTrendResponse;
  readonly previous: SovProductsTrendResponse;
}

export enum PerformanceFieldTypes {
  PAID = 'paid',
  ORGANIC = 'organic',
  TOTAL = 'total',
}

export enum PerformanceFields {
  SHARE_OF_VOICE = 'share_of_voice',
  APPEARANCE_PERCENT = 'appearance_percent',
  PG1_AVG_RANK = 'pg1_avg_rank',
  AD_SPEND = 'ad_spend',
}

export interface SovTrendRequest {
  startDate: string;
  endDate: string;
  performanceFields: PerformanceFields[];
  performanceFieldTypes: PerformanceFieldTypes[];
  keyword?: string;
}

export interface SovExportRequest {
  startDate: string;
  endDate: string;
  extItemId: string;
  salesChannelId: string;
  merchantName: string;
  productTitle: string;
  country: string;
  sku: string;
  keyword?: string;
}
export interface MetricsData {
  readonly label: string;
  readonly metricsId: PerformanceFieldTypes;
  readonly isChecked: boolean;
}
export interface SovProductsRequest {
  salesChannelId: string;
  performanceFieldTypes: string[];
  performanceFields: string[];
}

export enum CampaignPerformanceFieldType {
  AD_SALES = 'ad_sales_total',
  AD_SPEND = 'ad_spend',
  ACOS = 'acos_total',
  ROAS = 'roas_total',
  AD_CONVERSIONS = 'ad_conversions',
  CONVERSION_RATE = 'conversion_rate',
  UNITS_SOLD = 'units_sold',
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  COST_PER_CLICK = 'avg_cost_per_click',
  CLICK_THROUGH_RATE = 'click_through_rate',
  APPEARANCE_PERCENT = 'appearance_percent',
}

export enum CampaignDetailsFieldType {
  CAMPAIGN_DAILY_BUDGET = 'campaign_daily_budget',
}

export interface SovKeywordCampaignsRequest {
  sales_channel_id: string;
  start_date: string;
  end_date: string;
  keyword: string;
  product_name: string;
  ext_item_id: string;
  sku: string;
  campaign_performance_fields: CampaignPerformanceFieldType[];
  campaign_details_fields: CampaignDetailsFieldType[];
}

export interface CampaignPerformanceFields {
  [CampaignPerformanceFieldType.AD_SALES]?: Money;
  [CampaignPerformanceFieldType.AD_SPEND]?: Money;
  [CampaignPerformanceFieldType.ACOS]?: number | null;
  [CampaignPerformanceFieldType.ROAS]?: number | null;
  [CampaignPerformanceFieldType.AD_CONVERSIONS]?: number | null;
  [CampaignPerformanceFieldType.CONVERSION_RATE]?: number | null;
  [CampaignPerformanceFieldType.UNITS_SOLD]?: number | null;
  [CampaignPerformanceFieldType.IMPRESSIONS]?: number | null;
  [CampaignPerformanceFieldType.CLICKS]?: number | null;
  [CampaignPerformanceFieldType.COST_PER_CLICK]?: Money;
  [CampaignPerformanceFieldType.CLICK_THROUGH_RATE]?: number | null;
  [CampaignPerformanceFieldType.APPEARANCE_PERCENT]?: number | null;
}

export interface CampaignDetailsFields {
  [CampaignDetailsFieldType.CAMPAIGN_DAILY_BUDGET]?: Money;
}

export enum MITargetSegment {
  BRAND = 'brand',
  COMPETITOR = 'competitor',
  GENERIC = 'generic',
  AUTO = 'auto',
  UNKNOWN = 'unknown',
}

export type AdGroup = {
  campaign_id: string;
  campaign_targeting_type: string;
  ad_group_id: string;
  ad_group_name: string;
  target_segments: string[];
  performance_fields: CampaignPerformanceFields;
  campaign_details_fields?: CampaignDetailsFields;
};

export type KeywordCampaign = {
  campaign_id: string;
  campaign_name: string;
  ad_groups: AdGroup[];
  campaign_targeting_type: string;
  performance_fields: CampaignPerformanceFields;
  campaign_details_fields: CampaignDetailsFields;
};

export interface SovKeywordCampaignsResponse {
  readonly items: KeywordCampaign[];
  readonly totalItems: number;
}

export interface SovTopProductsRequest {
  startDate: string;
  endDate: string;
  performanceFieldType: string;
  performanceField: string;
  limit: number;
  sort: string;
}

export interface SovTopProduct {
  product_catalog_id: string;
  merchant_country_id: string;
  metadata: SovProductMetadata;
  share_of_voice?: number | null;
  appearance_percent?: number | null;
  pg1_avg_rank?: number | null;
}

export interface SovTopProductResponse {
  last_updated_at: string;
  product_catalogs: SovTopProduct[];
}

export enum VariationType {
  PARENT = 'PARENT',
  CHILD = 'CHILD',
  SINGLETON = 'SINGLETON',
}

export interface SovProductHierarchy {
  product_catalog_id: string;
  merchant_country_id: string;
  variation_type: VariationType;
  metadata: SovProductMetadata;
  sku_children?: SovProductHierarchy[];
}
export interface SovPerformanceData {
  appearance_percent: ShareOfVoice;
  pg1_avg_rank: ShareOfVoice;
}
export interface SovMerchants {
  merchant_country_id: string;
  current: SovPerformanceData;
  previous: SovPerformanceData;
}
export interface SovMerchantsResponse {
  last_updated_at: string;
  merchant_countries: SovMerchants[];
}

export interface SovMerchantsRequest {
  startDate: string;
  endDate: string;
}
