import {
  TargetRecommendationsResponse,
  TargetSegment,
  TargetingType,
} from '../../types/KeywordAction';

export const KwaRecommendationResponse: TargetRecommendationsResponse = {
  elements: [
    {
      id: '60985534-217e-563f-8b69-db1120901b32',
      keyword: {
        text: 'zebra shirts women',
        suggestedMatchType: 'Exact',
        tag: 'Unknown',
        sources: ['AMZ_SP_SUGGESTED_KEYWORD'],
      },
      product: {
        id: '7a850bb1-23a1-57cf-b01a-563e4f92d362',
        productGroupId: 'c3cd3020-8dc3-59e7-b814-bb3eed4e9c71',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        name: 'SHOUT ABOUT Mushroom Shirt (S)',
        sku: 'AJ-B4L9-ZDG8',
        extItemId: 'B0B39QH83T',
        productImage:
          'https://m.media-amazon.com/images/I/41mYGxm8viL._SS60_.jpg',
      },
      validDestinations: [
        {
          id: '6937a263-ba99-5771-bb8d-57509e66fd6e',
          name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Generic|SP-KW|TM',
          campaign: {
            id: 'af9efc33-7689-5c3b-8b04-74ac73ab86db',
            name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Generic|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Auto,
          },
          targetSegment: TargetSegment.Brand,
        },
        {
          id: '24db9e1b-74df-5bf0-9fdf-80dcae5d6930',
          name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Brand|SP-KW|TM',
          campaign: {
            id: '8c09a61a-92db-5e72-a936-b39b0f40fb55',
            name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Unknown,
        },
      ],
      performance: { rank: 2556 },
    },
    {
      id: '95d82081-4d84-5830-907d-25c3806a43fa',
      keyword: {
        text: 'zebra shirts women',
        suggestedMatchType: 'Exact',
        tag: 'Unknown',
        sources: ['AMZ_SP_SUGGESTED_KEYWORD'],
      },
      product: {
        id: '6aa964c1-8f48-56b3-a03b-bfbe0d9943f1',
        productGroupId: '7f9f4b41-3a2c-55f6-848e-2f4a3f1afe7a',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        name: 'SHOUT ABOUT Mushroom Shirt (M)',
        sku: '2J-SXQO-BU9C',
        extItemId: 'B0B39PXDYF',
        productImage:
          'https://m.media-amazon.com/images/I/41mYGxm8viL._SS60_.jpg',
      },
      validDestinations: [
        {
          id: '6937a263-ba99-5771-bb8d-57509e66fd6e',
          name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Generic|SP-KW|TM',
          campaign: {
            id: 'af9efc33-7689-5c3b-8b04-74ac73ab86db',
            name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Generic|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Competitor,
        },
        {
          id: '24db9e1b-74df-5bf0-9fdf-80dcae5d6930',
          name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Brand|SP-KW|TM',
          campaign: {
            id: '8c09a61a-92db-5e72-a936-b39b0f40fb55',
            name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Competitor,
        },
      ],
      performance: { rank: 667 },
    },
    {
      id: 'a7b9810c-d54e-5538-8b83-92d47708d09f',
      keyword: {
        text: 'young mens shirts',
        suggestedMatchType: 'Exact',
        tag: 'Unknown',
        sources: ['AMZ_SP_SUGGESTED_KEYWORD'],
      },
      suggestedDestination: {
        adGroup: {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Auto,
          },
          targetSegment: TargetSegment.Generic,
        },
      },
      product: {
        id: '6f2d6644-3ced-59dd-9793-61a31682f45f',
        productGroupId: 'ac41f93c-c7e2-51d1-84e4-e7d984377c99',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        name: 'I Paused My Game to be here Shirt (Large, Gray)',
        sku: '3R-WODR-1KS5',
        extItemId: 'B09PNX3HH2',
        productImage:
          'https://m.media-amazon.com/images/I/41rwYs+29DL._SS60_.jpg',
      },
      validDestinations: [
        {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Unknown,
        },
      ],
      performance: { rank: 1839 },
    },
    {
      id: 'a7b9810c-d54e-5538-8b83-92d47708d09f',
      keyword: {
        text: 'young mens shirts',
        suggestedMatchType: 'Exact',
        tag: 'Unknown',
        sources: ['AMZ_SP_SUGGESTED_KEYWORD'],
      },
      suggestedDestination: {
        adGroup: {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Auto,
          },
          targetSegment: TargetSegment.Unknown,
        },
      },
      product: {
        id: '6f2d6644-3ced-59dd-9793-61a31682f45f',
        productGroupId: 'ac41f93c-c7e2-51d1-84e4-e7d984377c99',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        name: 'I Paused My Game to be here Shirt (Large, Gray)',
        sku: '3R-WODR-1KS5',
        extItemId: 'B09PNX3HH2',
        productImage:
          'https://m.media-amazon.com/images/I/41rwYs+29DL._SS60_.jpg',
      },
      validDestinations: [
        {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Unknown,
        },
      ],
      performance: { rank: 1839 },
    },
    {
      id: 'a7b9810c-d54e-5538-8b83-92d47708d09f',
      keyword: {
        text: 'young mens shirts',
        suggestedMatchType: 'Exact',
        tag: 'Unknown',
        sources: ['AMZ_SP_SUGGESTED_KEYWORD'],
      },
      suggestedDestination: {
        adGroup: {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Unknown,
        },
      },
      product: {
        id: '6f2d6644-3ced-59dd-9793-61a31682f45f',
        productGroupId: 'ac41f93c-c7e2-51d1-84e4-e7d984377c99',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        name: 'I Paused My Game to be here Shirt (Large, Gray)',
        sku: '3R-WODR-1KS5',
        extItemId: 'B09PNX3HH2',
        productImage:
          'https://m.media-amazon.com/images/I/41rwYs+29DL._SS60_.jpg',
      },
      validDestinations: [
        {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Unknown,
        },
      ],
      performance: { rank: 1839 },
    },
    {
      id: '60985534-217e-563f-8b69-db1120901b32',
      keyword: {
        text: 'zebra shirts women',
        suggestedMatchType: 'Exact',
        tag: 'Unknown',
        sources: ['AMZ_SP_SUGGESTED_KEYWORD'],
      },
      product: {
        id: '7a850bb1-23a1-57cf-b01a-563e4f92d362',
        productGroupId: 'c3cd3020-8dc3-59e7-b814-bb3eed4e9c71',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        name: 'SHOUT ABOUT Mushroom Shirt (S)',
        sku: 'AJ-B4L9-ZDG8',
        extItemId: 'B0B39QH83T',
        productImage:
          'https://m.media-amazon.com/images/I/41mYGxm8viL._SS60_.jpg',
      },
      validDestinations: [
        {
          id: '6937a263-ba99-5771-bb8d-57509e66fd6e',
          name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Generic|SP-KW|TM',
          campaign: {
            id: 'af9efc33-7689-5c3b-8b04-74ac73ab86db',
            name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Generic|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Auto,
          },
          targetSegment: TargetSegment.Brand,
        },
        {
          id: '24db9e1b-74df-5bf0-9fdf-80dcae5d6930',
          name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Brand|SP-KW|TM',
          campaign: {
            id: '8c09a61a-92db-5e72-a936-b39b0f40fb55',
            name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Brand,
        },
      ],
      performance: { rank: 2556 },
    },
    {
      id: '95d82081-4d84-5830-907d-25c3806a43fa',
      keyword: {
        text: 'zebra shirts women',
        suggestedMatchType: 'Exact',
        tag: 'Unknown',
        sources: ['AMZ_SP_SUGGESTED_KEYWORD'],
      },
      product: {
        id: '6aa964c1-8f48-56b3-a03b-bfbe0d9943f1',
        productGroupId: '7f9f4b41-3a2c-55f6-848e-2f4a3f1afe7a',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        name: 'SHOUT ABOUT Mushroom Shirt (M)',
        sku: '2J-SXQO-BU9C',
        extItemId: 'B0B39PXDYF',
        productImage:
          'https://m.media-amazon.com/images/I/41mYGxm8viL._SS60_.jpg',
      },
      validDestinations: [
        {
          id: '6937a263-ba99-5771-bb8d-57509e66fd6e',
          name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Generic|SP-KW|TM',
          campaign: {
            id: 'af9efc33-7689-5c3b-8b04-74ac73ab86db',
            name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Generic|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Competitor,
        },
        {
          id: '24db9e1b-74df-5bf0-9fdf-80dcae5d6930',
          name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Brand|SP-KW|TM',
          campaign: {
            id: '8c09a61a-92db-5e72-a936-b39b0f40fb55',
            name: 'SHOUT ABOUT Mushroom Shirt (M)|B0B39PXDYF|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Competitor,
        },
      ],
      performance: { rank: 667 },
    },
    {
      id: 'a7b9810c-d54e-5538-8b83-92d47708d09f',
      keyword: {
        text: 'young mens shirts',
        suggestedMatchType: 'Exact',
        tag: 'Unknown',
        sources: ['AMZ_SP_SUGGESTED_KEYWORD'],
      },
      suggestedDestination: {
        adGroup: {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Auto,
          },
          targetSegment: TargetSegment.Generic,
        },
      },
      product: {
        id: '6f2d6644-3ced-59dd-9793-61a31682f45f',
        productGroupId: 'ac41f93c-c7e2-51d1-84e4-e7d984377c99',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        name: 'I Paused My Game to be here Shirt (Large, Gray)',
        sku: '3R-WODR-1KS5',
        extItemId: 'B09PNX3HH2',
        productImage:
          'https://m.media-amazon.com/images/I/41rwYs+29DL._SS60_.jpg',
      },
      validDestinations: [
        {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Generic,
        },
      ],
      performance: { rank: 1839 },
    },
    {
      id: 'a7b9810c-d54e-5538-8b83-92d47708d09f',
      keyword: {
        text: 'young mens shirts',
        suggestedMatchType: 'Exact',
        tag: 'Unknown',
        sources: ['AMZ_SP_SUGGESTED_KEYWORD'],
      },
      suggestedDestination: {
        adGroup: {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Auto,
          },
          targetSegment: TargetSegment.Unknown,
        },
      },
      product: {
        id: '6f2d6644-3ced-59dd-9793-61a31682f45f',
        productGroupId: 'ac41f93c-c7e2-51d1-84e4-e7d984377c99',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        name: 'I Paused My Game to be here Shirt (Large, Gray)',
        sku: '3R-WODR-1KS5',
        extItemId: 'B09PNX3HH2',
        productImage:
          'https://m.media-amazon.com/images/I/41rwYs+29DL._SS60_.jpg',
      },
      validDestinations: [
        {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Unknown,
        },
      ],
      performance: { rank: 1839 },
    },
    {
      id: 'a7b9810c-d54e-5538-8b83-92d47708d09f',
      keyword: {
        text: 'young mens shirts',
        suggestedMatchType: 'Exact',
        tag: 'Unknown',
        sources: ['AMZ_SP_SUGGESTED_KEYWORD'],
      },
      suggestedDestination: {
        adGroup: {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Unknown,
        },
      },
      product: {
        id: '6f2d6644-3ced-59dd-9793-61a31682f45f',
        productGroupId: 'ac41f93c-c7e2-51d1-84e4-e7d984377c99',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        name: 'I Paused My Game to be here Shirt (Large, Gray)',
        sku: '3R-WODR-1KS5',
        extItemId: 'B09PNX3HH2',
        productImage:
          'https://m.media-amazon.com/images/I/41rwYs+29DL._SS60_.jpg',
      },
      validDestinations: [
        {
          id: '17e41680-7ad7-5960-b4a2-0a5e23a190b5',
          name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
          campaign: {
            id: 'ea520440-3112-5559-bc40-f4b690a2815c',
            name: 'I Paused My Game to be here Shirt|B09R4N3FMS|Brand|SP-KW|TM',
            campaignType: 'sponsoredProducts',
            targetingType: TargetingType.Manual,
          },
          targetSegment: TargetSegment.Unknown,
        },
      ],
      performance: { rank: 1839 },
    },
  ],
  filteredElements: 1794,
};
