import {
  BadgeCampaignGoal,
  BadgeType,
  PERCENTAGE_FRACTION_DIGITS,
} from '@teikametrics/tm-design-system';
import {
  MatchType,
  MatchTypeASIN,
  SelectMetricsType,
} from '../../../../lib/types/CampaignFlexibilitySharedTypes';
import I18nKey from '../../../../lib/types/I18nKey';
import { TargetSegments } from '../../../../core/campaignFlexibility/types';
import size from 'lodash/size';
import { IntlShape } from 'react-intl';

export const getAdvertisingGoalKey = (goal?: SelectMetricsType) => {
  switch (goal) {
    case SelectMetricsType.Grow:
      return BadgeCampaignGoal.Grow;
    case SelectMetricsType.Liquidate:
      return BadgeCampaignGoal.Liquidate;
    case SelectMetricsType.Product_Launch:
      return BadgeCampaignGoal.ProductLaunch;
    case SelectMetricsType.Profit:
      return BadgeCampaignGoal.Profit;
    case SelectMetricsType.ROAS:
      return BadgeCampaignGoal.ROAS;
    default:
      return BadgeCampaignGoal.None;
  }
};

export const getTargetSegmentPills = (targetSegments?: TargetSegments[]) => {
  return targetSegments && size(targetSegments)
    ? targetSegments
        .map((targetSegment, index) => ({
          badgeType: TARGET_SEGMENT_KEY_TO_BADGE_TYPE_MAPPER[targetSegment],
          dataTestId: `kwa_createInAdgroup_targetSegment_${index}`,
          className: 'mr-4',
        }))
        .sort((a, b) => (a.badgeType < b.badgeType ? -1 : 1))
    : [
        {
          badgeType:
            TARGET_SEGMENT_KEY_TO_BADGE_TYPE_MAPPER[TargetSegments.Unknown],
          dataTestId: `kwa_createInAdgroup_targetSegment_${0}`,
          className: 'mr-4',
        },
      ];
};

export const TARGET_SEGMENT_KEY_TO_BADGE_TYPE_MAPPER: Record<
  TargetSegments,
  BadgeType
> = {
  [TargetSegments.Auto]: BadgeType.TagAutomatic,
  [TargetSegments.Brand]: BadgeType.TagBrand,
  [TargetSegments.Competitor]: BadgeType.TagCompetitor,
  [TargetSegments.Generic]: BadgeType.TagGeneric,
  [TargetSegments.Unknown]: BadgeType.TagAutomatic,
  [TargetSegments.Category]: BadgeType.TagCategory,
};

export const MATCH_TYPE_I18N_MAPPER: Record<MatchType, string> = {
  [MatchType.Exact]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXACT,
  [MatchType.Broad]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BROAD,
  [MatchType.Phrase]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PHRASE,
  [MatchType.NegativeExact]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_EXACT,
  [MatchType.NegativePhrase]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_PHRASE,
  [MatchType.Expanded]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXPANDED,
};

export const MATCH_TYPE_I18N_MAPPER_ASIN: Record<MatchTypeASIN, string> = {
  [MatchTypeASIN.Exact]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXACT,
  [MatchTypeASIN.Expanded]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXPANDED,
};

export const MAP_ADTYPE_TO_VARIATION_TYPE: Record<MatchTypeASIN, string> = {
  [MatchTypeASIN.Exact]: 'exact',
  [MatchTypeASIN.Expanded]: 'expanded',
};

export const MAP_ASIN_MATCH_TYPE_TO_API: Record<MatchTypeASIN, string> = {
  [MatchTypeASIN.Exact]: 'EXACT',
  [MatchTypeASIN.Expanded]: 'EXPANDED',
};
export const roundOfPercentageToFloorOrCeil = (
  value: number,
  intl: IntlShape
) => {
  return +intl.formatNumber(value ?? 0, {
    minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
    maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
  });
};
