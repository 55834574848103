import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

import {
  getCurrentAccountFromContext,
  getOutstandingBalanceDateRange,
} from '../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../containers/userProvider/userProvider';
import { createBillingApiClient } from '../../../lib/clients/BillingApiClient';
import { BillingBalanceResponse } from '../../../lib/types/Billing';
import I18nKey from '../../../lib/types/I18nKey';
import { getCurrencySymbol } from '../../../lib/utilities/currency';

interface PayOutstandingBalanceProps {
  readonly loadingBalance: boolean;
  readonly outstandingBalanceDateRange: string;
  readonly currencySymbol: string;
  readonly balanceAmount: number;
}

const PayOutstandingBalanceWrapper: React.FC = () => {
  const [loadingBalance, setLoadingBalance] = useState<boolean>(false);
  const [billingBalance, setBillingBalance] =
    useState<BillingBalanceResponse>();

  const outstandingBalanceDateRange =
    getOutstandingBalanceDateRange(billingBalance) || '';
  const userContext = useContext<UserContextState>(UserContext);
  const account = getCurrentAccountFromContext(userContext)!;
  const currencySymbol = getCurrencySymbol(billingBalance?.balance?.currency!!);
  const billingClient = createBillingApiClient(userContext.userInfo.idToken!);

  const onGetBalance = async () => {
    setLoadingBalance(true);
    const balance = await billingClient.getBillingBalance(account.id);
    setBillingBalance(balance);
    setLoadingBalance(false);
  };

  useEffect(() => {
    onGetBalance();
  }, []);

  return (
    <PayOutstandingBalance
      loadingBalance={loadingBalance}
      outstandingBalanceDateRange={outstandingBalanceDateRange}
      currencySymbol={currencySymbol || ''}
      balanceAmount={billingBalance?.balance?.amount || 0}
    />
  );
};

PayOutstandingBalanceWrapper.displayName = 'PayOutstandingBalanceWrapper';

const PayOutstandingBalance: React.FC<PayOutstandingBalanceProps> = ({
  loadingBalance,
  outstandingBalanceDateRange,
  currencySymbol,
  balanceAmount,
}) => {
  return (
    <>
      <div className="font-semibold text-lg text-grey-900 mt-10">
        <FormattedMessage
          id={I18nKey.OUTSTANDING_BALANCE_MODAL_HEADER_PAY_BALANCE}
        />
      </div>
      <div className="text-sm text-grey-600 mt-4">
        <FormattedMessage
          id={I18nKey.OUTSTANDING_BALANCE_MODAL_HEADER_SUBTITLE_KEEP_ACCESS}
        />
      </div>
      <div className="border-1 border-grey-400 border-solid rounded-8 px-16 py-12 mt-6">
        <div className="text-grey-900 text-lg font-semibold">
          <FormattedMessage
            id={I18nKey.OUTSTANDING_BALANCE_MODAL_TITLE_BALANCE}
          />
        </div>
        {loadingBalance ? (
          <div className="relative top-1/2">
            <div className="w-1/2 h-24">
              <Skeleton
                height={20}
                style={{ borderRadius: 0 }}
                className=" radius"
              />
            </div>
            <div className="w-1/3 h-24 mt-10">
              <Skeleton height={18} style={{ borderRadius: 0 }} />
            </div>
          </div>
        ) : (
          <>
            <div className="text-grey-900 text-xl">
              {`${currencySymbol}${balanceAmount}`}
            </div>
            <div className="text-grey-600 text-base">
              {outstandingBalanceDateRange}
            </div>
          </>
        )}
      </div>
    </>
  );
};

PayOutstandingBalance.displayName = 'PayOutstandingBalance';

export default PayOutstandingBalanceWrapper;
