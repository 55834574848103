import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  Placement,
  SelectProps,
  SelectShowSearch,
  SelectState,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  CampaignDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  getTooltipContentForArchivedEntity,
  isCampaignStatusArchived,
} from '../utils';

const getCurrentRowIndex =
  (campaignDetails: CampaignDetails) =>
  ({ tableState }: WithTable<CampaignDetails>) => {
    const visibleData = tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<CampaignDetails, void>()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
    );
    const index = (function () {
      for (let i = 0; i < visibleData.length; i++) {
        if (visibleData[i].campaignId === campaignDetails.campaignId) {
          return i;
        }
      }
      return -1;
    })();

    return index;
  };

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, portfolioNames, ...campaignDetails }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const currentTableId = ADS_MANAGER_CAMPAIGNS_TABLE_ID;

  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.Portfolio
      )(tableState, currentTableId)
  );

  const existingPortfolioName =
    campaignDetails.campaignDetails.campaignPortfolioName?.toString() || '';

  const value = changedValue ?? existingPortfolioName;

  const updateCellValue = (newValue: string) => {
    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.Portfolio,
        rowId: campaignDetails.campaignId,
        tableId: currentTableId,
        value: newValue,
        existingValue: existingPortfolioName,
      })
    );
  };

  const currentRowIndex = useSelector<WithTable<CampaignDetails>, number>(
    getCurrentRowIndex(campaignDetails)
  );

  const isEditablePortfolioName = () => !isEmpty(portfolioNames);

  if (isEditMode && isEditablePortfolioName()) {
    const options =
      portfolioNames?.map(({ name }) => {
        return {
          label: name,
          value: name,
          groupName: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_EDIT_PORTFOLIO_GROUP_HEADER,
          }),
        };
      }) || [];

    const portfolioNameSelectProps: SelectProps<string> = {
      options,
      placeholder: '',
      value,
      isDirty: value !== existingPortfolioName,
      onChange: updateCellValue,
      showSearch: SelectShowSearch.Show,
      className: 'w-200',
      state: isCampaignStatusArchived(campaignDetails.channelSettings.status)
        ? SelectState.Disabled
        : SelectState.Default,
      hasStickyScroll: true,
      menuClassName: 'max-h-360',
      dataTestId: 'protfolio_name',
      showOnTop: currentRowIndex > 6,
    };

    return makeSelectColumn<CampaignDetails>(
      (_campaignDetails) => portfolioNameSelectProps,
      ({ channelSettings: { status } }) => {
        const isCampaignArchived = isCampaignStatusArchived(status);

        const tooltipContent = () => {
          if (isCampaignArchived) {
            return getTooltipContentForArchivedEntity(
              intl.formatMessage({
                id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
              }),
              intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PORTFOLIO_NAME,
              }),
              intl
            );
          }
        };

        return {
          tooltipContent: tooltipContent(),
          controlledTooltip: isCampaignArchived
            ? ControlledTooltip.None
            : ControlledTooltip.Hide,
          tooltipPlacement: Placement.Bottom,
          style: ContentStyle.Bold,
        };
      }
    )(campaignDetails);
  }

  return makeTextColumn<CampaignDetails>(
    ({ campaignDetails: { campaignPortfolioName } }) => campaignPortfolioName,
    campaignDetails.pendingFields?.includes(CAMPAIGNS_API_COLUMN_NAME.Portfolio)
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const portfolioNameColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.Portfolio,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PORTFOLIO_NAME,
  RowCellElement,
  gridColumnWidth: '240px',
};

export const portfolioNameFilter = createStringDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.Portfolio,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PORTFOLIO_NAME,
  isValidString()
);
