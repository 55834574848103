import { SPAction } from '../../../lib/types/TeikaReduxTypes';
import {
  AdditionalAggregationDataLoadCompletePayload,
  AdditionalAggregationDataLoadFailedPayload,
  AdditionalAggregationDataLoadInitiatedPayload,
  AggregationDataLoadCompletePayload,
  AggregationDataLoadFailedPayload,
  AggregationDataLoadInitiatedPayload,
  ApplyBulkChangesPayload,
  ClearAllChangesPayload,
  ClearBufferPayload,
  ClearFilterPayload,
  ClearFiltersPayload,
  ClearSingleFilterOfMultiSelectionFiltersPayload,
  DataLoadBufferCompletePayload,
  DataLoadCompletePayload,
  DataLoadFailedPayload,
  DataLoadInitiatedPayload,
  DataResetAggregationHeaderPayload,
  DataResetPayload,
  DeleteItemPayload,
  PatchItemPayload,
  SetItemsPerPagePayload,
  SetVisiblePagePayload,
  SortAppliedPayload,
  SortRemovedPayload,
  TotalCountFailurePayload,
  TotalCountPayload,
  TotalCountSuccessPayload,
  UpdateCellPayload,
  UpdateExtraPaginationParamsPayload,
  UpdateExtraQueryParamsPayload,
  UpdateFiltersPayload,
  UpdateTableCellPayload,
} from './types';

export const SET_VISIBLE_PAGE = 'app/table/setVisiblePage';
export const SORT_APPLIED = 'app/table/sortApplied';
export const SORT_REMOVED = 'app/table/sortRemoved';
export const UPDATE_FILTERS = 'app/table/updateFilters';
export const CLEAR_FILTER = 'app/table/clearFilter';
export const CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS =
  'app/table/clearSingleFilterOfMultiSelectionFilters';
export const CLEAR_FILTERS = 'app/table/clearFilters';
export const UPDATE_EXTRA_PAGINATION_PARAMS =
  'app/table/updateExtraPaginationParams';
export const DATA_RESET = 'app/table/dataReset';
export const DATA_RESET_AGGREGATION = 'app/table/dataResetAggregation';
export const DATA_LOAD_INITIATED = 'app/table/dataLoadInitiated';
export const DATA_LOAD_COMPLETE = 'app/table/dataLoadComplete';
export const DATA_LOAD_BUFFER_COMPLETE = 'app/table/dataLoadBufferComplete';
export const CLEAR_BUFFER = 'app/table/clearBuffer';
export const DATA_LOAD_FAILED = 'app/table/dataLoadFailed';
export const AGGREGATION_DATA_LOAD_INITIATED =
  'app/table/aggregationDataLoadInitiated';
export const AGGREGATION_DATA_LOAD_COMPLETE =
  'app/table/aggregationDataLoadComplete';
export const AGGREGATION_DATA_LOAD_FAILED =
  'app/table/aggregationDataLoadFailed';
export const ADDITIONAL_AGGREGATION_DATA_LOAD_INITIATED =
  'app/table/additionalAggregationDataLoadInitiated';
export const ADDITIONAL_AGGREGATION_DATA_LOAD_COMPLETE =
  'app/table/additionalAggregationDataLoadComplete';
export const ADDITIONAL_AGGREGATION_DATA_LOAD_FAILED =
  'app/table/additionalAggregationDataLoadFailed';
export const UPDATE_EXTRA_QUERY_PARAMS = 'app/table/updateExtraQueryParams';
export const SET_ITEMS_PER_PAGE = 'app/table/setItemsPerPage';
export const UPDATE_CELL = 'app/table/updateCell';
export const UPDATE_TABLE_CELL = 'app/table/updateTableCell';
export const CLEAR_ALL_CHANGES = 'app/table/clearAllChanges';
export const APPLY_BULK_CHANGES = 'app/table/applyBulkChanges';
export const TOTAL_COUNT = 'app/table/totalCount';
export const TOTAL_COUNT_SUCCESS = 'app/table/totalCountSuccess';
export const TOTAL_COUNT_FAILURE = 'app/table/totalCountFailure';
export const PATCH_SINGLE_ITEM = 'app/table/patchSingleItem';
export const DELETE_ITEMS = 'app/table/deleteItems';

export const setVisiblePage = (
  payload: SetVisiblePagePayload
): SPAction<typeof SET_VISIBLE_PAGE, SetVisiblePagePayload> => ({
  type: SET_VISIBLE_PAGE,
  payload,
});

export function dataLoadComplete<T>(
  payload: DataLoadCompletePayload<T>
): SPAction<typeof DATA_LOAD_COMPLETE, DataLoadCompletePayload<T>> {
  return {
    type: DATA_LOAD_COMPLETE,
    payload,
  };
}

export function dataLoadBufferComplete<T>(
  payload: DataLoadBufferCompletePayload<T>
): SPAction<
  typeof DATA_LOAD_BUFFER_COMPLETE,
  DataLoadBufferCompletePayload<T>
> {
  return {
    type: DATA_LOAD_BUFFER_COMPLETE,
    payload,
  };
}

export function clearBuffer(
  payload: ClearBufferPayload
): SPAction<typeof CLEAR_BUFFER, ClearBufferPayload> {
  return {
    type: CLEAR_BUFFER,
    payload,
  };
}

export const dataReset = (
  payload: DataResetPayload
): SPAction<typeof DATA_RESET, DataResetPayload> => ({
  type: DATA_RESET,
  payload,
});

export const dataResetAggregationHeader = (
  payload: DataResetAggregationHeaderPayload
): SPAction<
  typeof DATA_RESET_AGGREGATION,
  DataResetAggregationHeaderPayload
> => ({
  type: DATA_RESET_AGGREGATION,
  payload,
});

export const sortApplied = (
  payload: SortAppliedPayload
): SPAction<typeof SORT_APPLIED, SortAppliedPayload> => ({
  type: SORT_APPLIED,
  payload,
});

export const sortRemoved = (
  payload: SortRemovedPayload
): SPAction<typeof SORT_REMOVED, SortRemovedPayload> => ({
  type: SORT_REMOVED,
  payload,
});

export const updateFilters = (
  payload: UpdateFiltersPayload
): SPAction<typeof UPDATE_FILTERS, UpdateFiltersPayload> => ({
  type: UPDATE_FILTERS,
  payload,
});

export const clearFilter = (
  payload: ClearFilterPayload
): SPAction<typeof CLEAR_FILTER, ClearFilterPayload> => ({
  type: CLEAR_FILTER,
  payload,
});

export const clearSingleFilterOfMultiSelectionFilters = (
  payload: ClearSingleFilterOfMultiSelectionFiltersPayload
): SPAction<
  typeof CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS,
  ClearSingleFilterOfMultiSelectionFiltersPayload
> => ({
  type: CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS,
  payload,
});

export const clearFilters = (
  payload: ClearFiltersPayload
): SPAction<typeof CLEAR_FILTERS, ClearFiltersPayload> => ({
  type: CLEAR_FILTERS,
  payload,
});

export const updateExtraPaginationParams = (
  payload: UpdateExtraPaginationParamsPayload
): SPAction<
  typeof UPDATE_EXTRA_PAGINATION_PARAMS,
  UpdateExtraPaginationParamsPayload
> => ({
  type: UPDATE_EXTRA_PAGINATION_PARAMS,
  payload,
});

export const dataLoadInitiated = (
  payload: DataLoadInitiatedPayload
): SPAction<typeof DATA_LOAD_INITIATED, DataLoadInitiatedPayload> => ({
  type: DATA_LOAD_INITIATED,
  payload,
});

export const dataLoadFailed = (
  payload: DataLoadFailedPayload
): SPAction<typeof DATA_LOAD_FAILED, DataLoadFailedPayload> => ({
  type: DATA_LOAD_FAILED,
  payload,
});

export const aggregationDataLoadInitiated = (
  payload: AggregationDataLoadInitiatedPayload
): SPAction<
  typeof AGGREGATION_DATA_LOAD_INITIATED,
  AggregationDataLoadInitiatedPayload
> => ({
  type: AGGREGATION_DATA_LOAD_INITIATED,
  payload,
});

export function aggregationDataLoadComplete<A>(
  payload: AggregationDataLoadCompletePayload<A>
): SPAction<
  typeof AGGREGATION_DATA_LOAD_COMPLETE,
  AggregationDataLoadCompletePayload<A>
> {
  return {
    type: AGGREGATION_DATA_LOAD_COMPLETE,
    payload,
  };
}

export const aggregationDataLoadFailed = (
  payload: AggregationDataLoadFailedPayload
): SPAction<
  typeof AGGREGATION_DATA_LOAD_FAILED,
  AggregationDataLoadFailedPayload
> => ({
  type: AGGREGATION_DATA_LOAD_FAILED,
  payload,
});

export const additionalAggregationDataLoadInitiated = (
  payload: AdditionalAggregationDataLoadInitiatedPayload
): SPAction<
  typeof ADDITIONAL_AGGREGATION_DATA_LOAD_INITIATED,
  AdditionalAggregationDataLoadInitiatedPayload
> => ({
  type: ADDITIONAL_AGGREGATION_DATA_LOAD_INITIATED,
  payload,
});

export function additionalAggregationDataLoadComplete<A>(
  payload: AdditionalAggregationDataLoadCompletePayload<A>
): SPAction<
  typeof ADDITIONAL_AGGREGATION_DATA_LOAD_COMPLETE,
  AdditionalAggregationDataLoadCompletePayload<A>
> {
  return {
    type: ADDITIONAL_AGGREGATION_DATA_LOAD_COMPLETE,
    payload,
  };
}

export const additionalAggregationDataLoadFailed = (
  payload: AdditionalAggregationDataLoadFailedPayload
): SPAction<
  typeof ADDITIONAL_AGGREGATION_DATA_LOAD_FAILED,
  AdditionalAggregationDataLoadFailedPayload
> => ({
  type: ADDITIONAL_AGGREGATION_DATA_LOAD_FAILED,
  payload,
});

export const updateExtraQueryParams = (
  payload: UpdateExtraQueryParamsPayload
): SPAction<
  typeof UPDATE_EXTRA_QUERY_PARAMS,
  UpdateExtraQueryParamsPayload
> => ({
  type: UPDATE_EXTRA_QUERY_PARAMS,
  payload,
});

export const setItemsPerPage = (
  payload: SetItemsPerPagePayload
): SPAction<typeof SET_ITEMS_PER_PAGE, SetItemsPerPagePayload> => ({
  type: SET_ITEMS_PER_PAGE,
  payload,
});

export function updateCell(
  payload: UpdateCellPayload
): SPAction<typeof UPDATE_CELL, UpdateCellPayload> {
  return {
    type: UPDATE_CELL,
    payload,
  };
}

export function updateTableCell(
  payload: UpdateTableCellPayload
): SPAction<typeof UPDATE_TABLE_CELL, UpdateTableCellPayload> {
  return {
    type: UPDATE_TABLE_CELL,
    payload,
  };
}

export function clearAllChanges(
  payload: ClearAllChangesPayload
): SPAction<typeof CLEAR_ALL_CHANGES, ClearAllChangesPayload> {
  return {
    type: CLEAR_ALL_CHANGES,
    payload,
  };
}

export function applyBulkChanges(
  payload: ApplyBulkChangesPayload
): SPAction<typeof APPLY_BULK_CHANGES, ApplyBulkChangesPayload> {
  return {
    type: APPLY_BULK_CHANGES,
    payload,
  };
}

export const totalCount = (
  payload: TotalCountPayload
): SPAction<typeof TOTAL_COUNT, TotalCountPayload> => ({
  type: TOTAL_COUNT,
  payload,
});

export const totalCountSuccess = (
  payload: TotalCountSuccessPayload
): SPAction<typeof TOTAL_COUNT_SUCCESS, TotalCountSuccessPayload> => ({
  type: TOTAL_COUNT_SUCCESS,
  payload,
});

export const totalCountFailure = (
  payload: TotalCountFailurePayload
): SPAction<typeof TOTAL_COUNT_FAILURE, TotalCountFailurePayload> => ({
  type: TOTAL_COUNT_FAILURE,
  payload,
});

export const patchSingleItem = (
  payload: PatchItemPayload<any>
): SPAction<typeof PATCH_SINGLE_ITEM, PatchItemPayload<any>> => ({
  type: PATCH_SINGLE_ITEM,
  payload,
});

export function deleteRecordsInTable(
  payload: DeleteItemPayload<any>
): SPAction<typeof DELETE_ITEMS, DeleteItemPayload<any>> {
  return {
    type: DELETE_ITEMS,
    payload,
  };
}

export type TableDataAction =
  | ReturnType<typeof dataLoadComplete>
  | ReturnType<typeof dataLoadInitiated>
  | ReturnType<typeof dataLoadFailed>;

export type TableAction =
  | TableDataAction
  | ReturnType<typeof dataLoadBufferComplete>
  | ReturnType<typeof clearBuffer>
  | ReturnType<typeof setVisiblePage>
  | ReturnType<typeof dataReset>
  | ReturnType<typeof dataResetAggregationHeader>
  | ReturnType<typeof sortApplied>
  | ReturnType<typeof sortRemoved>
  | ReturnType<typeof updateFilters>
  | ReturnType<typeof clearFilter>
  | ReturnType<typeof clearFilters>
  | ReturnType<typeof clearSingleFilterOfMultiSelectionFilters>
  | ReturnType<typeof updateExtraPaginationParams>
  | ReturnType<typeof aggregationDataLoadInitiated>
  | ReturnType<typeof aggregationDataLoadFailed>
  | ReturnType<typeof aggregationDataLoadComplete>
  | ReturnType<typeof additionalAggregationDataLoadInitiated>
  | ReturnType<typeof additionalAggregationDataLoadFailed>
  | ReturnType<typeof additionalAggregationDataLoadComplete>
  | ReturnType<typeof updateExtraQueryParams>
  | ReturnType<typeof setItemsPerPage>
  | ReturnType<typeof updateCell>
  | ReturnType<typeof updateTableCell>
  | ReturnType<typeof clearAllChanges>
  | ReturnType<typeof applyBulkChanges>
  | ReturnType<typeof totalCount>
  | ReturnType<typeof totalCountSuccess>
  | ReturnType<typeof totalCountFailure>
  | ReturnType<typeof patchSingleItem>
  | ReturnType<typeof deleteRecordsInTable>;

export default {
  SET_VISIBLE_PAGE,
  SORT_APPLIED,
  SORT_REMOVED,
  CLEAR_FILTER,
  CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS,
  UPDATE_FILTERS,
  CLEAR_FILTERS,
  UPDATE_EXTRA_PAGINATION_PARAMS,
  DATA_LOAD_BUFFER_COMPLETE,
  DATA_LOAD_INITIATED,
  DATA_LOAD_COMPLETE,
  DATA_LOAD_FAILED,
  DATA_RESET,
  DATA_RESET_AGGREGATION,
  AGGREGATION_DATA_LOAD_INITIATED,
  AGGREGATION_DATA_LOAD_COMPLETE,
  AGGREGATION_DATA_LOAD_FAILED,
  ADDITIONAL_AGGREGATION_DATA_LOAD_INITIATED,
  ADDITIONAL_AGGREGATION_DATA_LOAD_COMPLETE,
  ADDITIONAL_AGGREGATION_DATA_LOAD_FAILED,
  UPDATE_EXTRA_QUERY_PARAMS,
  APPLY_BULK_CHANGES,
  CLEAR_BUFFER,
  DELETE_ITEMS,
  setVisiblePage,
  sortApplied,
  sortRemoved,
  clearFilter,
  clearSingleFilterOfMultiSelectionFilters,
  updateFilters,
  clearFilters,
  updateExtraPaginationParams,
  dataLoadInitiated,
  dataLoadComplete,
  dataLoadFailed,
  dataLoadBufferComplete,
  dataReset,
  dataResetAggregationHeader,
  aggregationDataLoadInitiated,
  aggregationDataLoadFailed,
  aggregationDataLoadComplete,
  additionalAggregationDataLoadInitiated,
  additionalAggregationDataLoadFailed,
  additionalAggregationDataLoadComplete,
  updateExtraQueryParams,
  setItemsPerPage,
  updateCell,
  updateTableCell,
  clearAllChanges,
  applyBulkChanges,
  patchSingleItem,
  deleteRecordsInTable,
  clearBuffer,
};
