import startCase from 'lodash/startCase';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { PRODUCT_ADS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<ProductAdDetails> = (props) => {
  return makeTextColumn<ProductAdDetails>((adItemDetails: ProductAdDetails) =>
    startCase(adItemDetails.adItemDetails.campaignType)
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignTypeColumn: FlywheelTableColumn<ProductAdDetails> = {
  isSortable: true,
  columnName: PRODUCT_ADS_API_COLUMN_NAME.CampaignType,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_TYPE,
  RowCellElement,
  gridColumnWidth: '200px',
};
