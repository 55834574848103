import { Factory } from 'fishery';

import {
  AdGroupReviewStatusType,
  AdGroupStatus,
  AddedBy,
  CampaignAdFormat,
  CampaignCostType,
  CampaignStatus,
  CampaignTactic,
  ExpressionType,
  KeywordReviewStatusType,
  KeywordTargetType,
  MatchType,
  TargetStatus,
  TargetsDetails,
} from '../../types/AOSharedTypes';

export interface Response {
  readonly elements: TargetsDetails[];
  readonly filteredElements: number;
}

export default Factory.define<Response>(() => ({
  elements: [
    {
      targetId: 'b816859b-691e-5b13-a6c5-4f2dd7d9621f',
      targetDetails: {
        keywordType: KeywordTargetType.CampaignNegativeKeyword,
        addedBy: AddedBy.FlywheelAutomated,
        isBiddable: true,
        targetText: 'party supply',
        matchType: MatchType.Broad,
        targetType: 'Generic',
        adGroupName: 'Party bundles_Manual_AdGroup',
        adGroupStatus: AdGroupStatus.Enabled,
        adGroupId: 'e04644af-b6d6-5023-9e8d-e9734ec8ca17',
        campaignDailyBudget: {
          amount: '1530',
          currency: 'USD',
        },
        viewsTargetText: [
          {
            type: ExpressionType.SimilarProduct,
          },
          {
            type: ExpressionType.Lookback,
            value: '30',
          },
        ],
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignPortfolioName: 'Campaign portfolio',
        campaignStatus: CampaignStatus.Enabled,
        campaignTargetingType: 'manual',
        campaignTactic: CampaignTactic.Views,
        campaignType: 'Auto',
        targetingExpressionType: 'Auto',
        createdAt: '2022-02-25',
        extCampaignId: '1285337',
        extAdGroupId: '305049',
        extTargetId: '4963081',
        startDate: '2020-05-16',
        merchantCountryId: '92faa498-eccd-4c59-999f-9fec646a8967',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.Complete,
          reviewComments:
            'We are rejecting a bunch of keywords for the following reasons:\na) Certain sets of keywords are irrelevant to the product/brand which is submitted (For example puyopuyo ps5,sackboy ps5, etc...)\nb) Certain set of keywords not meeting our conquesting policy guidelines (For example seagate 2tb game drive, etc...)\nPlease refer to the Guideline and Best Practices document for more information.',
        },
        keywordReviewStatus: {
          reviewStatus: KeywordReviewStatusType.Approved,
        },
        campaignTotalBudget: {
          amount: '300',
          currency: 'USD',
        },
      },
      channelSettings: {
        status: TargetStatus.Enabled,
        bid: {
          amount: '0.30',
          currency: 'USD',
        },
      },
      targetPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        avgCostPerClick: {
          amount: '1.80',
          currency: 'USD',
        },
        clickThroughRate: 0.582976903336184,
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        adConversions: 12,
        clicks: 741,
        impressions: 19729,
        newToBrandOrders: 12,
        newToBrandSales: {
          amount: '1.90',
          currency: 'USD',
        },
        brandClickRevenue: {
          amount: '2.90',
          currency: 'USD',
        },
        relatedClickRevenue: {
          amount: '3.90',
          currency: 'USD',
        },
        newToBrandOrderRate: 0.4,
        newToBrandAdUnitsSoldPercentage: 0.23,
        newToBrandOrdersPercentage: 0.53,
        newToBrandSalesPercentage: 0.1,
        newToBrandAdUnitsSold: 2,
        acosDirect: 0.56,
        adSalesDirect: {
          amount: '27.81',
          currency: 'USD',
        },
        roasDirect: 0.32,
        advertisedSkuUnits: 10,
        otherSkuUnits: 10,
        totalAttributedSales: 15000,
        orders: 500,
        videoImpressions: 20000,
        viewableImpressions: 15000,
        viewThroughRate: 0.75,
        clickThroughRateForViews: 3.0,
        viewThroughAttributedSales: {
          amount: '2000.00',
          currency: 'USD',
        },
        viewThroughOrders: 50,
        viewThroughUnitsSold: 70,
        totalCompleteViewAttributedSales: {
          amount: '2000.00',
          currency: 'USD',
        },
        advertisedCompleteViewAttributedSales: {
          amount: '2000.00',
          currency: 'USD',
        },
        otherCompleteViewAttributedSales: {
          amount: '2000.00',
          currency: 'USD',
        },
        completeViewOrders: 60,
        completeViewUnitsSold: 80,
        videoFirstQuartileViews: 18000,
        videoMidpointViews: 16000,
        videoThirdQuartileViews: 14000,
        videoCompleteViews: 12000,
        videoUnmutes: 5000,
        videoFiveSecondViews: 10000,
        videoFiveSecondViewsRate: 0.5,
      },
      pendingFields: [],
    },
    {
      targetId: 'b816859b-691e-5b13-a6c5-4f2dd7d9621f',
      targetDetails: {
        addedBy: AddedBy.FlywheelAccepted,
        targetText: 'party supply',
        matchType: MatchType.Broad,
        adGroupId: 'e04644af-b6d6-5023-9e8d-e9734ec8ca17',
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: 'manual',
        extCampaignId: '1285337',
        extAdGroupId: '305049',
        extTargetId: '4963081',
        startDate: '2020-05-16',
        campaignAdFormat: CampaignAdFormat.StoreSpotlight,
        campaignCostType: CampaignCostType.CPC,
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.Complete,
        },
        keywordReviewStatus: {
          reviewStatus: KeywordReviewStatusType.Rejected,
          reviewComments:
            'We are rejecting a bunch of keywords for the following reasons:\na) Certain sets of keywords are irrelevant to the product/brand which is submitted (For example puyopuyo ps5,sackboy ps5, etc...)\nb) Certain set of keywords not meeting our conquesting policy guidelines (For example seagate 2tb game drive, etc...)\nPlease refer to the Guideline and Best Practices document for more information.',
        },
      },
      channelSettings: {
        status: TargetStatus.Enabled,
        bid: {
          amount: '0.30',
          currency: 'USD',
        },
      },
      targetPerformance: {},
      pendingFields: [],
    },
    {
      targetId: 'b816859b-691e-5b13-a6c5-4f2dd7d9621f',
      targetDetails: {
        targetText: 'party supply',
        matchType: MatchType.Broad,
        adGroupName: 'Party bundles_Manual_AdGroup',
        adGroupId: 'e04644af-b6d6-5023-9e8d-e9734ec8ca17',
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: 'manual',
        extCampaignId: '1285337',
        extAdGroupId: '305049',
        extTargetId: '4963081',
        startDate: '2020-05-16',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.Cancelled,
        },
        keywordReviewStatus: {
          reviewStatus: KeywordReviewStatusType.Pending,
        },
      },
      channelSettings: {
        status: TargetStatus.Enabled,
        bid: {
          amount: '0.30',
          currency: 'USD',
        },
      },
      targetPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
      },
      pendingFields: [],
    },
    {
      targetId: 'b816859b-691e-5b13-a6c5-4f2dd7d9621f',
      targetDetails: {
        targetText: 'party supply',
        matchType: MatchType.Broad,
        adGroupName: 'Party bundles_Manual_AdGroup',
        adGroupId: 'e04644af-b6d6-5023-9e8d-e9734ec8ca17',
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: 'manual',
        extCampaignId: '1285337',
        extAdGroupId: '305049',
        extTargetId: '4963081',
        startDate: '2020-05-16',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.InProgress,
        },
      },
      channelSettings: {
        status: TargetStatus.Enabled,
        bid: {
          amount: '0.30',
          currency: 'USD',
        },
      },
      targetPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
      },
      pendingFields: [],
    },
    {
      targetId: 'b216859b-691e-5b14-a6c5-4f2dd7d2621f',
      targetDetails: {
        targetText: 'party supply',
        matchType: MatchType.Broad,
        adGroupName: 'Party bundles_Manual_AdGroup',
        adGroupId: 'e04644af-b6d6-5023-9e8d-e9734ec8ca17',
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: 'manual',
        campaignDailyBudget: {
          currency: 'USD',
          amount: '11.00',
        },
        extCampaignId: '1285337',
        extAdGroupId: '305049',
        extTargetId: '4963081',
        startDate: '2020-05-16',
        adGroupReviewStatus: undefined,
      },
      channelSettings: {
        status: TargetStatus.Enabled,
        bid: undefined,
      },
      targetPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
      },
      pendingFields: [],
    },
    {
      targetId: 'b816859b-691e-5b13-a6c5-4f2dd7d9621f',
      targetDetails: {
        targetText: 'party supply',
        extTargetId: '4963081',
        startDate: '2020-05-16',
        flywheelAiEnabled: true,
        matchType: MatchType.NegativePhrase,
      },
      channelSettings: {},
      targetPerformance: {},
      pendingFields: [],
    },
    {
      targetId: 'b236859b-691e-5b14-a6c5-4f2dd7d2621f',
      targetDetails: {
        isBiddable: true,
        targetText: 'party supply',
        matchType: MatchType.Broad,
        adGroupName: 'Party bundles_Manual_AdGroup',
        adGroupId: 'e04644af-b6d6-5023-9e8d-e9734ec8ca17',
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: 'manual',
        campaignAdFormat: CampaignAdFormat.Video,
        flywheelAiEnabled: true,
        campaignDailyBudget: {
          currency: 'USD',
          amount: '11.00',
        },
        extCampaignId: '1285337',
        extAdGroupId: '305049',
        extTargetId: '4963081',
        startDate: '2020-05-16',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.InProgress,
        },
      },
      channelSettings: {
        status: TargetStatus.Enabled,
        bid: {
          amount: '0.81',
          currency: 'USD',
        },
      },
      targetPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
      },
      pendingFields: [],
    },
    {
      targetId: 'b236359b-621e-5b34-a6c5-4f2dd7d2621f',
      targetDetails: {
        targetText: 'party supply',
        viewsTargetText: [
          {
            type: ExpressionType.SimilarProduct,
          },
          {
            type: ExpressionType.Lookback,
            value: '30',
          },
        ],
        matchType: MatchType.Broad,
        adGroupName: 'Party bundles_Manual_AdGroup',
        adGroupId: 'e04644af-b6d6-5023-9e8d-e9734ec8ca17',
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: 'manual',
        campaignCostType: CampaignCostType.VCPM,
        campaignAdFormat: CampaignAdFormat.StoreSpotlight,
        campaignDailyBudget: {
          currency: 'USD',
          amount: '11.00',
        },
        extCampaignId: '1285337',
        extAdGroupId: '305049',
        extTargetId: '4963081',
        startDate: '2020-05-16',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.InProgress,
        },
      },
      channelSettings: {
        status: TargetStatus.Enabled,
        bid: undefined,
      },
      targetPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
      },
      pendingFields: [],
    },
    {
      targetId: 'b236359b-621e-5b34-a6c5-3f2dd7d2621f',
      targetDetails: {
        targetText: 'party supply',
        matchType: MatchType.NegativePhrase,
        adGroupName: 'Party bundles_Manual_AdGroup',
        adGroupId: 'e04644af-b6d6-5023-9e8d-e9734ec8ca17',
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: 'manual',
        campaignDailyBudget: {
          currency: 'USD',
          amount: '11.00',
        },
        extCampaignId: '1285337',
        extAdGroupId: '305049',
        extTargetId: '4963081',
        startDate: '2020-05-16',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.InProgress,
        },
      },
      channelSettings: {
        status: TargetStatus.Enabled,
        bid: undefined,
      },
      targetPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
      },
      pendingFields: [],
    },
    {
      targetId: 'b236359b-621e-5b34-a6c5-3f2dd7d2621f',
      targetDetails: {
        targetText: 'party supply',
        entityType: 'SB Negative Product Target',
        adGroupName: 'Party bundles_Manual_AdGroup',
        adGroupId: 'e04644af-b6d6-5023-9e8d-e9734ec8ca17',
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: 'manual',
        campaignDailyBudget: {
          currency: 'USD',
          amount: '11.00',
        },
        extCampaignId: '1285337',
        extAdGroupId: '305049',
        extTargetId: '4963081',
        startDate: '2020-05-16',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.InProgress,
        },
      },
      channelSettings: {
        status: TargetStatus.Enabled,
        bid: undefined,
      },
      targetPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
      },
      pendingFields: [],
    },
  ],
  filteredElements: 3,
}));
