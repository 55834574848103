import { DateTime } from 'luxon';

import {
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makePercentColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  ProductAdDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import {
  PRODUCT_ADS_API_COLUMN_NAME,
  getPerformanceMetricValue,
} from '../utils';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) =>
  makePercentColumn<ProductAdDetails>(
    ({ adItemDetails: { startDate }, adItemPerformance: { acosDirect } }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        acosDirect
      );

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const acosDirectColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  isSortable: true,
  columnName: PRODUCT_ADS_API_COLUMN_NAME.DirectACOS,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_DIRECT_ACOS,
  RowCellElement,
  className: 'text-right',
  gridColumnWidth: '140px',
  columnHeaderClassName: 'justify-end',
};

export const acosDirectFilter = createPercentDataFieldFilter(
  PRODUCT_ADS_API_COLUMN_NAME.DirectACOS,
  I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_DIRECT_ACOS,
  isValidNumber()
);
