import {
  Alignment,
  Button,
  ButtonSize,
  ButtonVariant,
  KPICardV2,
  KPICardV2Props,
  KebabLinearIcon,
  KpiCardGroupV2,
  MetricCardV2CardState,
  Placement,
  TextLink,
  Theme,
  Tooltip,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { getCurrentAccountFromContext } from '../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../containers/userProvider/userProvider';
import {
  AOKPIMetrics,
  HeroMetricsAggregationPeriodType,
  HeroMetricsData,
  HeroMetricsItemV2,
  MerchantCountryCode,
} from '../../../lib/types/AOSharedTypes';
import { Filter } from '../../../lib/types/Filter';
import I18nKey from '../../../lib/types/I18nKey';
import {
  AsyncRequest,
  asyncRequestIsComplete,
  asyncRequestIsFailed,
  asyncRequestIsLoading,
  asyncRequestIsNotStarted,
} from '../../../lib/utilities/asyncRequest';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../lib/utilities/currency';
import { getDaysBetweenDates } from '../containers/adsManager/utils';
import {
  getManageSlideoverConfigFromLocalStorage,
  setManageSlideoverConfigFromLocalStorage,
} from '../containers/adsManager/utils/storageUtils';
import { HeroMetricsFailed } from './HeroMetricsFailed';
import { ManageMetricsSlideover, MetricsData } from './ManageMetricsSlideover';
import {
  getAOKPIMetricValue,
  getCardFooterProps,
  getCurrentValueContent,
  getDefaultMetricsConfigurationAO,
  getHeroMetricType,
  getTooltipContentForMetric,
  getUpdatedMetricsConfig,
  isHeroMetricsResultUndefined,
  toTitleCase,
} from './utils';

interface OwnProps {
  readonly currentMetricRequest: AsyncRequest<HeroMetricsData>;
  readonly previousMetricRequest: AsyncRequest<HeroMetricsData>;
  readonly merchantCountry: MerchantCountryCode;
  readonly earliestDataAvailibilityDate: DateTime;
  readonly tableFilters: Filter[];
  readonly loadHeroMetricsAttempts: number;
  readonly retryHeroMetricsLoad: () => void;
  readonly contactSupport: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  readonly dataTestId?: string;
}

export const HeroMetricsV2: React.FC<OwnProps> = ({
  currentMetricRequest,
  previousMetricRequest,
  contactSupport,
  loadHeroMetricsAttempts,
  retryHeroMetricsLoad,
  merchantCountry,
  earliestDataAvailibilityDate,
  tableFilters,
  dataTestId,
}) => {
  const intl = useIntl();
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  const userInfo = {
    userId: userContext.userInfo.userDetails?.id ?? '',
    accountId,
  };

  const [isSlideoverOpen, setIsSlideoverOpen] = useState<boolean>(false);
  const [selectedMetrics, setSelectedMetrics] = useState<MetricsData[]>(
    getUpdatedMetricsConfig(
      intl,
      getManageSlideoverConfigFromLocalStorage(userInfo)
    ) || getDefaultMetricsConfigurationAO(intl)
  );

  const setUpdatedMetricsSelection = (metrics: MetricsData[]) => {
    setSelectedMetrics(metrics);
    setManageSlideoverConfigFromLocalStorage(userInfo, metrics);
    setIsSlideoverOpen(false);
  };

  const heroMetricsFailedState =
    asyncRequestIsFailed(currentMetricRequest) ||
    (asyncRequestIsComplete(currentMetricRequest) &&
      isHeroMetricsResultUndefined(currentMetricRequest.result));

  const heroMetricsLoadingOrSuccessState =
    asyncRequestIsLoading(currentMetricRequest) ||
    asyncRequestIsNotStarted(currentMetricRequest) ||
    (asyncRequestIsComplete(currentMetricRequest) &&
      !isHeroMetricsResultUndefined(currentMetricRequest.result));

  const currentMetricResult =
    asyncRequestIsComplete(currentMetricRequest) && currentMetricRequest.result;

  const previousMetricResult =
    asyncRequestIsComplete(previousMetricRequest) &&
    previousMetricRequest.result;

  const heroMetricsItems: HeroMetricsItemV2[] = selectedMetrics.map(
    (metric: MetricsData): HeroMetricsItemV2 => {
      return {
        metricName: metric.metricsId as AOKPIMetrics,
        currentValue:
          currentMetricResult &&
          currentMetricResult.aggregationPeriodType ===
            HeroMetricsAggregationPeriodType.Current
            ? getAOKPIMetricValue(
                currentMetricResult.performanceMetrics,
                metric.metricsId as AOKPIMetrics
              )
            : undefined,

        previousValue:
          previousMetricResult &&
          previousMetricResult.aggregationPeriodType ===
            HeroMetricsAggregationPeriodType.Previous
            ? getAOKPIMetricValue(
                previousMetricResult.performanceMetrics,
                metric.metricsId as AOKPIMetrics
              )
            : undefined,
        heroMetricType: getHeroMetricType(metric.metricsId as AOKPIMetrics),
        isChecked: metric.isChecked,
        label: metric.label,
      };
    }
  );

  const kpiCardsData: KPICardV2Props[] = heroMetricsItems
    .filter((metric) => metric.isChecked)
    .map((metric) => ({
      header:
        metric.label && metric.label.split(' ').length > 1
          ? toTitleCase(metric.label)
          : metric.label,
      content: currentMetricResult
        ? getCurrentValueContent(
            intl,
            metric.heroMetricType,
            metric.metricName,
            metric.currentValue,
            currencyCode
          )
        : undefined,
      isFooterActive: true,
      showBorder: false,
      previousDaysLabel: previousMetricResult
        ? intl.formatMessage(
            { id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_PREV_DAYS_LABEL },
            {
              days: getDaysBetweenDates(
                DateTime.fromISO(
                  previousMetricResult.performanceMetrics.range.startDate
                ),
                DateTime.fromISO(
                  previousMetricResult.performanceMetrics.range.endDate
                )
              ),
            }
          )
        : undefined,
      previousValue: previousMetricResult
        ? getCurrentValueContent(
            intl,
            metric.heroMetricType,
            metric.metricName,
            metric.previousValue,
            currencyCode
          )
        : undefined,
      cardTrend: getCardFooterProps(
        intl,
        metric.heroMetricType,
        metric.metricName,
        metric.currentValue,
        metric.previousValue,
        previousMetricResult,
        earliestDataAvailibilityDate
      ),
      state: asyncRequestIsLoading(currentMetricRequest)
        ? MetricCardV2CardState.Loading
        : MetricCardV2CardState.Success,
      isSelectableMetricCard: false,
      tooltipProps: {
        content: getTooltipContentForMetric(metric.metricName),
        position: {
          placement: Placement.Bottom,
          alignment: Alignment.Center,
          placementOffsetInPixels: 8,
        },
        theme: Theme.Dark,
      },
      isNegativeUpTrend:
        metric.metricName === AOKPIMetrics.ACoS ||
        metric.metricName === AOKPIMetrics.CostPerClick,
      showInfoIcon: true,
    }));

  return (
    <>
      {heroMetricsFailedState && (
        <HeroMetricsFailed
          contactSupport={contactSupport}
          currentMetricRequest={currentMetricRequest}
          heroMetricsLoadAttempts={loadHeroMetricsAttempts}
          retryHeroMetricsLoad={retryHeroMetricsLoad}
          tableFilters={tableFilters}
          dataTestId={dataTestId}
        />
      )}
      {isSlideoverOpen && (
        <ManageMetricsSlideover
          showModal={isSlideoverOpen}
          onClose={() => setIsSlideoverOpen(false)}
          onMetricUpdate={setUpdatedMetricsSelection}
          allMetricsData={[...selectedMetrics]}
        />
      )}
      {heroMetricsLoadingOrSuccessState && (
        <div className="flex w-full justify-center mt-16 mb-8">
          <div
            className={classNames(
              'flex w-full justify-start items-center',
              'border border-solid border-grey-200 rounded my-16'
            )}
          >
            <div className="flex grow">
              <KpiCardGroupV2
                cards={kpiCardsData}
                onCardClick={noop}
                classnames="w-full"
              />
              {[...Array(5 - kpiCardsData.length)].map(() =>
                asyncRequestIsLoading(currentMetricRequest) ? (
                  <KPICardV2
                    state={MetricCardV2CardState.Loading}
                    showBorder={false}
                    isFooterActive
                  />
                ) : (
                  <p
                    className={classNames(
                      'flex min-w-236 justify-center items-center bg-grey-100 grow',
                      'border border-dashed border-grey-300 rounded cursor-pointer'
                    )}
                    onClick={() => setIsSlideoverOpen(true)}
                  >
                    <TextLink
                      textLabel={intl.formatMessage({
                        id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_ADD_METRIC_LINK,
                      })}
                    />
                  </p>
                )
              )}
            </div>
            <Tooltip
              content={intl.formatMessage({
                id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_HEADER,
              })}
              position={{
                alignment: Alignment.Center,
                placement: Placement.Left,
              }}
              delayShow={500}
            >
              <Button
                svgIcon={KebabLinearIcon}
                variant={ButtonVariant.BlackAndWhite}
                size={ButtonSize.InlineIcon}
                onClick={() => setIsSlideoverOpen(true)}
                className="mx-8"
                dataTestId="AO_select_metric_btn"
              />
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
};
HeroMetricsV2.displayName = 'AOHeroMetricsV2';
