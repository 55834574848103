import { MerchantType } from './Fam';
import { MoneyWithAmountInString } from './Money';
import { CurrencyCode } from '../utilities/currency';

export const API_REQUEST_DATE_FORMAT = 'yyyy-MM-dd';

// to be confirmed
export enum Metric {
  'adSpend' = 'adSpend',
  'adSalesTotal' = 'totalSales',
  'adSales' = 'adSales',
  'tacos' = 'tacos',
  'roas' = 'roas',
}

export enum AggregationPeriodType {
  Current = 'current',
  Previous = 'previous',
}

export enum HomepageResponseMetricKeys {
  TotalSales = 'totalSales',
  AdSales = 'adSales',
  DirectAdSales = 'adSalesDirect',
  AdSalesTotal = 'adSalesTotal',
  AdSpend = 'adSpend',
  Tacos = 'tacos',
  ACoSTotal = 'acos',
  ACoS = 'acosTotal',
  AdConversions = 'adConversions',
  AverageSalesPrice = 'averageOrderPrice',
  DirectACoS = 'acosDirect',
  Roas = 'roas',
  DirectROAS = 'roasDirect',
  Clicks = 'clicks',
  ClickThroughRate = 'clickThroughRate',
  ConversionRate = 'conversionRate',
  CPC = 'costPerClick',
  Impressions = 'impressions',
  UnitsSold = 'unitsSold',
}

export enum SalesChannelTmIndexKeys {
  Amazon = 'Amazon',
  Walmart = 'Walmart',
}

export interface HomePageDataRequest {
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: CurrencyCode;
  readonly metricsPeriodType: AggregationPeriodType;
  readonly merchantCountryIds: string[];
  readonly performanceFields: Metric[] | HomepageResponseMetricKeys[];
}

export interface TmIndexDataRequest {
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: CurrencyCode;
  readonly countries: string[];
  readonly salesChannelIds: string[];
}

export interface BusinessPerformanceDataRequest {
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: CurrencyCode;
  readonly performanceFields: HomepageResponseMetricKeys[];
  readonly isPerformanceSummaryIncluded?: boolean;
  readonly merchantCountries: MerchantDetails[];
}

export interface MerchantDetails {
  readonly salesChannelId: string;
  readonly salesChannelName: string;
  readonly merchantCountryId: string;
  readonly merchantType: MerchantType;
}

export interface DateRange {
  readonly startDate?: string;
  readonly endDate?: string;
}

export type PerformanceMetrics = {
  readonly range?: DateRange;
  readonly [HomepageResponseMetricKeys.TotalSales]?: MoneyWithAmountInString;
  readonly [HomepageResponseMetricKeys.AdSales]?: MoneyWithAmountInString;
  readonly [HomepageResponseMetricKeys.AdSalesTotal]?: MoneyWithAmountInString;
  readonly [HomepageResponseMetricKeys.ACoSTotal]?: number;
  readonly [HomepageResponseMetricKeys.ACoS]?: number;
  readonly [HomepageResponseMetricKeys.AdSpend]?: MoneyWithAmountInString;
  readonly [HomepageResponseMetricKeys.Tacos]?: number;
  readonly [HomepageResponseMetricKeys.Roas]?: number;
  readonly [HomepageResponseMetricKeys.AdConversions]?: number;
  readonly [HomepageResponseMetricKeys.AverageSalesPrice]?: MoneyWithAmountInString;
  readonly [HomepageResponseMetricKeys.DirectACoS]?: number;
  readonly [HomepageResponseMetricKeys.DirectAdSales]?: MoneyWithAmountInString;
  readonly [HomepageResponseMetricKeys.DirectROAS]?: number;
  readonly [HomepageResponseMetricKeys.Clicks]?: number;
  readonly [HomepageResponseMetricKeys.ClickThroughRate]?: number;
  readonly [HomepageResponseMetricKeys.ConversionRate]?: number;
  readonly [HomepageResponseMetricKeys.CPC]?: MoneyWithAmountInString;
  readonly [HomepageResponseMetricKeys.Impressions]?: number;
  readonly [HomepageResponseMetricKeys.UnitsSold]?: number;
};

export interface MetricSummary {
  readonly timePeriod: string;
  readonly value: string;
}

export interface OverviewSummary {
  readonly highest?: MetricSummary;
  readonly lowest?: MetricSummary;
}

export interface ChannelsData {
  readonly name?: string;
  readonly value?: number;
  readonly totalSales?: number;
  readonly adSpend?: number;
}

export interface GraphDataPoints {
  readonly date?: string;
  readonly totalValue?: string;
  readonly channels?: ChannelsData[];
  readonly [HomepageResponseMetricKeys.AdSpend]?: string;
  readonly [HomepageResponseMetricKeys.TotalSales]?: string;
}

export interface MerchantCountryInfoForInsights {
  readonly merchantCountryId: string;
  readonly merchantCountryName: string;
  readonly aggregation: number;
  readonly totalSales?: number;
  readonly adSpend?: number;
}

export interface InsightsData {
  readonly totalValue?: string;
  readonly channels?: ChannelsData[];
  readonly merchantCountries: MerchantCountryInfoForInsights[];
}

export enum OverviewTimePeriod {
  'Daily' = 'daily',
  'Weekly' = 'weekly',
  'Monthly' = 'monthly',
}
export interface TimePeriodOverviewSummary {
  [OverviewTimePeriod.Daily]?: OverviewSummary;
  [OverviewTimePeriod.Weekly]?: OverviewSummary;
  [OverviewTimePeriod.Monthly]?: OverviewSummary;
}

export interface TimePeriodGraphDataPoints {
  [OverviewTimePeriod.Daily]: GraphDataPoints[];
  [OverviewTimePeriod.Weekly]?: GraphDataPoints[];
  [OverviewTimePeriod.Monthly]?: GraphDataPoints[];
}

export interface MetricData {
  readonly overviewSummary?: TimePeriodOverviewSummary;
  readonly graphDataPoints?: TimePeriodGraphDataPoints;
  readonly insights?: InsightsData;
}

export interface HomePageData {
  readonly currency: CurrencyCode;
  readonly aggregationPeriodType: AggregationPeriodType;
  readonly performanceMetrics?: PerformanceMetrics;
  readonly [HomepageResponseMetricKeys.TotalSales]: MetricData;
  readonly [HomepageResponseMetricKeys.AdSales]: MetricData;
  readonly [HomepageResponseMetricKeys.AdSpend]: MetricData;
  readonly [HomepageResponseMetricKeys.Tacos]: MetricData;
  readonly [HomepageResponseMetricKeys.AdSalesTotal]?: MetricData;
  readonly [HomepageResponseMetricKeys.ACoSTotal]?: MetricData;
  readonly [HomepageResponseMetricKeys.ACoS]?: MetricData;
  readonly [HomepageResponseMetricKeys.AdConversions]?: MetricData;
  readonly [HomepageResponseMetricKeys.AverageSalesPrice]?: MetricData;
  readonly [HomepageResponseMetricKeys.Roas]?: MetricData;
  readonly [HomepageResponseMetricKeys.DirectACoS]?: MetricData;
  readonly [HomepageResponseMetricKeys.DirectAdSales]?: MetricData;
  readonly [HomepageResponseMetricKeys.DirectROAS]?: MetricData;
  readonly [HomepageResponseMetricKeys.Clicks]?: MetricData;
  readonly [HomepageResponseMetricKeys.ClickThroughRate]?: MetricData;
  readonly [HomepageResponseMetricKeys.ConversionRate]?: MetricData;
  readonly [HomepageResponseMetricKeys.CPC]?: MetricData;
  readonly [HomepageResponseMetricKeys.Impressions]?: MetricData;
  readonly [HomepageResponseMetricKeys.UnitsSold]?: MetricData;
  readonly aoRefreshNeeded?: boolean;
  readonly aoDataUnavailableCause?: string;
}

export interface ExportBusinessMetricsRequest {
  readonly merchantCountryIds: string[];
  readonly performanceFields:
    | PerformanceFieldsBusiness[]
    | HomepageResponseMetricKeys[];
  readonly metricsPeriodType: AggregationPeriodType;
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: CurrencyCode;
}

export interface ExportProductMetaDataResponse {
  readonly requestId: string;
  readonly statusApiUrl: string;
}

export interface ExportProductMetaDataStatusResponse {
  readonly status: string;
  readonly downloadApiUrl: string;
  readonly s3DownloadUrl: string;
}

export enum PerformanceFieldsBusiness {
  TotalSales = 'totalSales',
  AdSales = 'adSales',
  AdSpend = 'adSpend',
  Tacos = 'tacos',
}

export interface AiStatisticsData {
  readonly accountId: string;
  readonly aiBids: string;
  readonly startDate: string;
  readonly lastBidSubmittedDate?: string;
}

export interface AiStatisticsDataV2 {
  readonly aiBids: number;
}

export interface SeasonalEvent {
  readonly name: string;
  readonly eventDate: string;
  readonly category: string;
  readonly eventType: string;
}

export interface AiStatisticsGraphDataPoint {
  readonly date: string;
  readonly enabledAdsGroupsCount: number;
  readonly bidChanges: number;
}

export interface AiStatisticsGraphData extends AiStatisticsDataV2 {
  readonly startDate: string;
  readonly endDate: string;
  readonly graphDataPoints: AiStatisticsGraphDataPoint[];
}
