import { Factory } from 'fishery';
import { OOSGraphDataAPIResponse } from '../../../modules/inventoryOptimization/containers/inventoryDashboard/types';

export const OutOfStockGraphDataResponse =
  Factory.define<OOSGraphDataAPIResponse>(() => ({
    aggregatedEstimatedSales: {
      amount: 500,
      currency: 'USD',
    },
    aggregatedTotalSales: {
      amount: 21000,
      currency: 'USD',
    },
    daily: [
      {
        date: '2023-04-01',
        totalSales: {
          amount: 800,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 100,
          currency: 'USD',
        },
        rateOfSale: 5.5,
      },
      {
        date: '2023-04-02',
        totalSales: {
          amount: 750,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 120,
          currency: 'USD',
        },
        rateOfSale: 6.0,
      },
      {
        date: '2023-04-03',
        totalSales: {
          amount: 700,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 130,
          currency: 'USD',
        },
        rateOfSale: 6.5,
      },
      {
        date: '2023-04-04',
        totalSales: {
          amount: 650,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 140,
          currency: 'USD',
        },
        rateOfSale: 5.5,
      },
      {
        date: '2023-04-05',
        totalSales: {
          amount: 600,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 10,
          currency: 'USD',
        },
        rateOfSale: 6.0,
      },
      {
        date: '2023-04-06',
        totalSales: {
          amount: 800,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 40,
          currency: 'USD',
        },
        rateOfSale: 6.5,
      },
      {
        date: '2023-04-07',
        totalSales: {
          amount: 750,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 100,
          currency: 'USD',
        },
        rateOfSale: 5.5,
      },
      {
        date: '2023-04-08',
        totalSales: {
          amount: 700,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 90,
          currency: 'USD',
        },
        rateOfSale: 6.0,
      },
      {
        date: '2023-04-09',
        totalSales: {
          amount: 650,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 180,
          currency: 'USD',
        },
        rateOfSale: 6.5,
      },
      {
        date: '2023-04-10',
        totalSales: {
          amount: 600,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 90,
          currency: 'USD',
        },
        rateOfSale: 5.5,
      },
      {
        date: '2023-04-11',
        totalSales: {
          amount: 800,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 70,
          currency: 'USD',
        },
        rateOfSale: 6.0,
      },
      {
        date: '2023-04-12',
        totalSales: {
          amount: 750,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 60,
          currency: 'USD',
        },
        rateOfSale: 6.5,
      },
      {
        date: '2023-04-13',
        totalSales: {
          amount: 700,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 100,
          currency: 'USD',
        },
        rateOfSale: 5.5,
      },
      {
        date: '2023-04-14',
        totalSales: {
          amount: 650,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 200,
          currency: 'USD',
        },
        rateOfSale: 6.0,
      },
      {
        date: '2023-04-15',
        totalSales: {
          amount: 600,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 150,
          currency: 'USD',
        },
        rateOfSale: 6.5,
      },
      {
        date: '2023-04-16',
        totalSales: {
          amount: 800,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 300,
          currency: 'USD',
        },
        rateOfSale: 5.5,
      },
      {
        date: '2023-04-17',
        totalSales: {
          amount: 750,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 350,
          currency: 'USD',
        },
        rateOfSale: 6.0,
      },
      {
        date: '2023-04-18',
        totalSales: {
          amount: 700,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 225,
          currency: 'USD',
        },
        rateOfSale: 6.5,
      },
      {
        date: '2023-04-19',
        totalSales: {
          amount: 650,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 100,
          currency: 'USD',
        },
        rateOfSale: 5.5,
      },
      {
        date: '2023-04-20',
        totalSales: {
          amount: 600,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 0,
          currency: 'USD',
        },
        rateOfSale: 6.0,
      },
      {
        date: '2023-04-21',
        totalSales: {
          amount: 800,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 0,
          currency: 'USD',
        },
        rateOfSale: 6.5,
      },
      {
        date: '2023-04-22',
        totalSales: {
          amount: 750,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 0,
          currency: 'USD',
        },
        rateOfSale: 5.5,
      },
      {
        date: '2023-04-23',
        totalSales: {
          amount: 700,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 0,
          currency: 'USD',
        },
        rateOfSale: 6.0,
      },
      {
        date: '2023-04-24',
        totalSales: {
          amount: 650,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 0,
          currency: 'USD',
        },
        rateOfSale: 6.5,
      },
      {
        date: '2023-04-25',
        totalSales: {
          amount: 600,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 100,
          currency: 'USD',
        },
        rateOfSale: 5.5,
      },
      {
        date: '2023-04-26',
        totalSales: {
          amount: 800,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 0,
          currency: 'USD',
        },
        rateOfSale: 6.0,
      },
      {
        date: '2023-04-27',
        totalSales: {
          amount: 750,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 0,
          currency: 'USD',
        },
        rateOfSale: 6.5,
      },
      {
        date: '2023-04-28',
        totalSales: {
          amount: 700,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 0,
          currency: 'USD',
        },
        rateOfSale: 5.5,
      },
      {
        date: '2023-04-29',
        totalSales: {
          amount: 650,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 0,
          currency: 'USD',
        },
        rateOfSale: 6.0,
      },
      {
        date: '2023-04-30',
        totalSales: {
          amount: 600,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 0,
          currency: 'USD',
        },
        rateOfSale: 6.5,
      },
    ],
  }));
