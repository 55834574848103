import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { PROFILES_API_COLUMN_NAME } from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = ({
  merchantCountry,
  profileId,
  profileDetails,
  channelSettings,
  profilePerformance,
}) => {
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);
  const data: ProfileApiDataDetails = {
    profileId,
    profileDetails,
    channelSettings,
    profilePerformance,
  };

  return makeCurrencyColumn<ProfileApiDataDetails>(
    ({ profileDetails: { campaignDailyBudget } }) =>
      campaignDailyBudget
        ? {
            amount: Number(campaignDailyBudget.amount),
            currency:
              currencyCode || campaignDailyBudget.currency || CURRENCY_CODE,
          }
        : undefined
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignDailyBudgetColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.DailyBudget,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_DAILY_BUDGET,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
};

export const campaignDailyBudgetFilterForProfiles = (currency: string) =>
  createMoneyDataFieldFilter(
    PROFILES_API_COLUMN_NAME.DailyBudget,
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_DAILY_BUDGET,
    currency,
    isValidNumber(0)
  );
