import '@teikametrics/react-infinite-calendar/styles.css';
import './styles.scss';

import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';

import InfiniteCalendar, {
  Calendar,
  SelectParams,
  withRange,
} from '@teikametrics/react-infinite-calendar';
import {
  ArrowRightIcon,
  MIDateRange,
  MIDateRangeInterface,
  MIDateRangeType,
  ManagedInput,
  OffClickable,
} from '@teikametrics/tm-design-system';

import { STANDARD_DATE_FORMAT } from '../../../../lib/types/CommonSharedTypes';
import { convertToUTCDate } from '../../../../lib/utilities/datePickerUtilities';

export interface DatePickerProps extends MIDateRangeInterface {
  minDate?: DateTime;
  maxDate?: DateTime;
  onCancel?: () => void;
  onSubmit: (m1: DateTime, m2: DateTime) => void;
  selectableDates?: MIDateRangeInterface;
  quickLinks?: DatePickerQuickLink[];
  open?: boolean;
  dateRangeType?: MIDateRangeType;
  quickLinksOnly?: boolean;
  // cancel will revert the state back to the props
  resetOnCancel?: boolean;
}

export type InputChange = 'to' | 'from';

export interface DatePickerState extends MIDateRangeInterface {
  open: boolean;
}

export const createDefaultQuickLinks = (
  startDate: DateTime = DateTime.utc().startOf('day')
) => [
  {
    title: 'Past 7 Days',
    to: startDate,
    from: DateTime.fromISO(startDate.toISODate() as string).minus({ days: 6 }),
  },
  {
    title: 'Past 14 Days',
    to: startDate,
    from: DateTime.fromISO(startDate.toISODate() as string).minus({ days: 13 }),
  },
  {
    title: 'Past 30 Days',
    to: startDate,
    from: DateTime.fromISO(startDate.toISODate() as string).minus({ days: 29 }),
  },
  {
    title: 'Past 60 Days',
    to: startDate,
    from: DateTime.fromISO(startDate.toISODate() as string).minus({ days: 59 }),
  },
  {
    title: 'Past 3 Months',
    to: startDate,
    from: DateTime.fromISO(startDate.toISODate() as string).minus({
      months: 3,
    }),
  },
  {
    title: 'Past 6 Months',
    to: startDate,
    from: DateTime.fromISO(startDate.toISODate() as string).minus({
      months: 6,
    }),
  },
  {
    title: 'Past Year',
    to: startDate,
    from: DateTime.fromISO(startDate.toISODate() as string).minus({ year: 1 }),
  },
  {
    title: 'MTD',
    to: startDate,
    from: DateTime.fromISO(startDate.toISODate() as string).startOf('month'),
  },
  {
    title: 'YTD',
    to: startDate,
    from: DateTime.fromISO(startDate.toISODate() as string).startOf('year'),
  },
];

const RANGE_SELECTED = 3;

export interface DatePickerQuickLink {
  title: string;
  to: DateTime;
  from: DateTime;
}

/**
 * Does an inclusive comparison of a quick link against a daterange
 *
 * @param {DatePickerQuickLink} link
 * @param {DateTime | null} min
 * @param {DateTime | null } max
 * @returns {boolean}
 */
export const isLinkEnabled = (
  link: DatePickerQuickLink,
  min?: DateTime,
  max?: DateTime
): boolean => {
  const { from, to } = link;
  if (min && max) {
    return (
      from.startOf('day') >= min.startOf('day') &&
      from.startOf('day') <= max.startOf('day') &&
      to.startOf('day') >= min.startOf('day') &&
      to.startOf('day') <= max.startOf('day')
    );
  } else if (min) {
    return (
      from.startOf('day') >= min.startOf('day') &&
      to.startOf('day') >= min.startOf('day')
    );
  } else if (max) {
    return (
      from.startOf('day') <= max.startOf('day') &&
      to.startOf('day') <= max.startOf('day')
    );
  } else {
    return true;
  }
};

export const isSameDay = (m1?: DateTime, m2?: DateTime): boolean => {
  if (m1 && m2) {
    return m1.hasSame(m2, 'day');
  }

  return !m1 && !m2;
};

export const validateAndReturnIncomingDate = (
  input: InputChange,
  value: string,
  to: DateTime,
  from: DateTime
): { to: DateTime; from: DateTime } => {
  let date = DateTime.fromFormat(value, STANDARD_DATE_FORMAT);

  if (!date.isValid) {
    if (input === 'to') {
      date = DateTime.fromISO(to.toISO() as string);
    } else {
      date = DateTime.fromISO(from.toISO() as string);
    }
  }

  // No future dates
  if (date > DateTime.now()) {
    date = DateTime.now();
  }

  // User enters a 'to' date that is before the current 'from'
  if (input === 'to' && date < from) {
    return {
      to: date,
      from: DateTime.fromISO(date.toISO() as string),
    };
  }

  // User enters a 'to' date that is before the current 'from'
  if (input === 'from' && date > to) {
    return {
      from: date,
      to: DateTime.fromISO(date.toISO() as string),
    };
  }

  return {
    ...{ to, from },
    [input]: date,
  };
};

export class DatePicker extends React.Component<
  DatePickerProps,
  DatePickerState
> {
  constructor(props: DatePickerProps) {
    super(props);
    this.state = {
      from: props.from,
      to: props.to,
      open: props.open || false,
    };
  }

  public handleSelectedDate = (selectInfo: SelectParams): void => {
    if (selectInfo.eventType === RANGE_SELECTED) {
      this.setState({
        to: DateTime.fromJSDate(selectInfo.end),
        from: DateTime.fromJSDate(selectInfo.start),
      });
    }
  };

  public handleDateInputBlur = (input: InputChange, value: string): void => {
    const { to, from } = this.state;
    this.setState(validateAndReturnIncomingDate(input, value, to, from));
  };

  public handleToBlur = (value: string): void =>
    this.handleDateInputBlur('to', value);

  public handleFromBlur = (value: string): void =>
    this.handleDateInputBlur('from', value);

  public submitDates = (): void => {
    this.setState({ open: false });
    this.props.onSubmit(this.state.from, this.state.to);
  };

  public handleCancel = (): void => {
    this.setState({ open: false });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    if (this.props.resetOnCancel) {
      this.resetDateToProps();
    }
  };

  public openDatePicker = (): void => {
    this.setState({ open: true });
  };

  public renderQuickLink = (
    link: DatePickerQuickLink,
    index: number
  ): JSX.Element => {
    const { onSubmit, minDate, maxDate } = this.props;
    const { to, from } = this.state;
    const call = () => {
      this.setState({
        open: false,
        to: link.to,
        from: link.from,
      });
      onSubmit(link.from, link.to);
    };

    const isActive = isSameDay(link.to, to) && isSameDay(link.from, from);

    if (isLinkEnabled(link, minDate, maxDate)) {
      return (
        <li
          key={`${index}-${link.title}`}
          className={isActive ? 'active' : ''}
          onClick={call}
          role="list"
        >
          {link.title}
        </li>
      );
    }

    return (
      <li key={`${index}-${link.title}`} className="disabled">
        {link.title}
      </li>
    );
  };

  public renderCalendar = (): React.ReactNode => {
    const { from, to, open } = this.state;
    const { minDate, maxDate, quickLinks, quickLinksOnly } = this.props;
    const links = quickLinks || createDefaultQuickLinks();
    const CalendarWithRange = withRange(Calendar);

    if (!open) {
      return;
    }

    const calendarOptions: {
      minDate?: Date;
      min?: Date;
      maxDate?: Date;
      max?: Date;
    } = {};

    if (minDate) {
      calendarOptions.minDate = convertToUTCDate(minDate);
      calendarOptions.min = convertToUTCDate(
        DateTime.fromISO(minDate.toISO() as string).minus({ month: 1 })
      );
    }

    if (maxDate) {
      calendarOptions.maxDate = convertToUTCDate(maxDate);
      calendarOptions.max = convertToUTCDate(
        DateTime.fromISO(maxDate.toISO() as string).plus({ month: 1 })
      );
    }

    return (
      <div
        className={classNames('datepicker__widget', {
          'no-calendar': quickLinksOnly,
        })}
      >
        <div className="datepicker__quick-nav">
          <ul>
            {links.map((link, index) => this.renderQuickLink(link, index))}
          </ul>
        </div>
        {!quickLinksOnly && (
          <div className="datepicker__manual">
            <div className="datepicker__manual-inputs">
              <ManagedInput.default
                type={'text'}
                onBlur={this.handleFromBlur}
                value={from.toFormat('L')}
              />
              <ArrowRightIcon />
              <ManagedInput.default
                type={'text'}
                onBlur={this.handleToBlur}
                value={to.toFormat('L')}
              />
            </div>
            <div className="datepicker__calendar">
              <InfiniteCalendar
                {...calendarOptions}
                Component={CalendarWithRange}
                selected={{ start: from, end: to }}
                onSelect={this.handleSelectedDate}
                locale={{
                  headerFormat: 'MMMM Do',
                  weekdays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                }}
                displayOptions={{
                  showHeader: false,
                  showLabelsBetweenMonths: true,
                  showOverlay: false,
                }}
                height={366}
                width={304}
                rowHeight={32}
                theme={{
                  accentColor: '#448AFF',
                  floatingNav: {
                    background: '#fff',
                    chevron: '#51c1dc',
                    color: '#51c1dc',
                  },
                  headerColor: '#51c1dc',
                  selectionColor: '#51c1dc',
                  textColor: {
                    active: '#ffffff',
                    default: '#242a31',
                  },
                  todayColor: 'rgba(149, 159, 171, 0.75)',
                  weekdayColor: '#ffffff',
                }}
              />
            </div>
            <div className="datepicker__options">
              <a
                href="#"
                className="datepicker__options-cancel"
                onClick={this.handleCancel}
                role="button"
              >
                Cancel
              </a>
              <a
                href="#"
                className="datepicker__options-submit"
                onClick={this.submitDates}
                role="link"
              >
                Submit
              </a>
            </div>
          </div>
        )}
      </div>
    );
  };

  public render() {
    const { from, to } = this.state;
    return (
      <OffClickable className="datepicker" offClickHandler={this.handleCancel}>
        <>
          <div
            className={classNames({
              'datepicker__button-no-calendar': this.props.quickLinksOnly,
              'datepicker__button-open': this.state.open,
            })}
            onClick={this.openDatePicker}
          >
            <MIDateRange
              from={from}
              to={to}
              type={this.props.dateRangeType || MIDateRangeType.Calendar}
            />
          </div>
          {this.renderCalendar()}
        </>
      </OffClickable>
    );
  }

  public shouldComponentUpdate(
    nextProps: Readonly<DatePickerProps>,
    nextState: Readonly<DatePickerState>
  ): boolean {
    const { to, from, open } = this.state;
    const { maxDate, minDate, quickLinks } = this.props;
    const newLinks: boolean =
      nextProps.quickLinks !== undefined && nextProps.quickLinks !== quickLinks;
    return (
      (nextProps.open && open !== nextProps.open) ||
      open !== nextState.open ||
      !isSameDay(to, nextProps.to) ||
      !isSameDay(from, nextProps.from) ||
      !isSameDay(to, nextState.to) ||
      !isSameDay(from, nextState.from) ||
      !isSameDay(maxDate, nextProps.maxDate) ||
      !isSameDay(minDate, nextProps.minDate) ||
      newLinks
    );
  }

  private resetDateToProps() {
    this.setState({
      from: this.props.from,
      to: this.props.to,
    });
  }
}
