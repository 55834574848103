import { AxiosError, AxiosResponse } from 'axios';
import first from 'lodash/first';
import { DateTime } from 'luxon';
import React, { ChangeEvent, ReactNode, useContext, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { defer } from 'rxjs';

import {
  Alignment,
  FileUploadDownloadModalState as BiddingModalState,
  BulkManageLinear,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  DownloadFileStepProps,
  FileUploadDownloadModal as ManageBiddingModal,
  MoreInfoStepProps,
  Placement,
  Type,
  UploadErrorProps,
  UploadFileStepProps,
  UploadSuccessStepProps,
  ValidationErrorsProps,
  ValidationProgressProps,
} from '@teikametrics/tm-design-system';

import {
  NotificationContext,
  NotificationContextState,
} from '../../../../containers/notificationProvider';
import { tableSelectors } from '../../../../containers/table/ducks';
import { getCurrentAccountFromContext } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { HttpResponseCode } from '../../../../lib/clients/types';
import {
  AdLevel,
  AdType,
  FilterFieldMapper,
  FlywheelSalesChannel,
  ManageBiddingUploadStatusResponse,
} from '../../../../lib/types/AOSharedTypes';
import { MerchantType } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { Sort } from '../../../../lib/types/Sort';
import { AdOptimizationState } from '../../redux/types';
import { useDownloadFile } from '../../../../lib/hooks/downloadFile';
import {
  ADLEVEL_TO_ADLEVEL_API_MAPPER,
  AD_TYPE_SHORT_FORM,
  CSV_UPLOAD_SYNC_STATUS_POLLING_INTERVAL,
  CSV_UPLOAD_SYNC_STATUS_POLLING_MAX_TRIES,
  UploadStatusResponses,
  ValidateUploadErrorResponses,
  ValidateUploadErrorResponsesStructure,
  ValidationCount,
} from './types';
import { getTableIdByAdLevel, shouldShowSmartCampaigns } from './utils';
import polling from 'rx-polling';
import { Filter } from '../../../../lib/types/Filter';
import { talkToUs } from '../../../../lib/utilities/intercom';
import { ViewerRoleTooltip } from '../../../../lib/utilities/viewerRoleTooltipWrapper';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';

// Temporarily moving to confluence page as intercom link is not ready
// Originial URL - https://intercom.help/flywheel-20/en/articles/5580639-editing-flywheel-settings-in-bulk;
const EDIT_BIDDING_HELP_LINK =
  'https://teikametrics.atlassian.net/wiki/spaces/PROD/pages/1996095740/SOP+for+using+Bulk+edit+via+CSV+upload+Manage+bidding+feature';

interface ManageBiddingInternalProps<RequestBody> {
  readonly aoApiClient: AOApiClient;
  readonly request: RequestBody;
  readonly adLevel: AdLevel;
  readonly salesChannelName: FlywheelSalesChannel;
  readonly adType: AdType;
  readonly refreshTable: () => void;
  readonly salesChannelId: string;
  readonly merchantType: MerchantType;
  readonly filterFieldMapper?: FilterFieldMapper[];
  readonly additionalFilters?: Filter[];
  readonly merchantName?: string;
  readonly showAsIcon?: boolean;
  readonly isUserRoleViewOnly?: boolean;
}

const initialErrorAndTotalCount: ValidationCount = {
  errorCount: 0,
  totalCount: 0,
};

export const getUploadValidDataButtonState = (
  areThereNoValidationErrors: boolean,
  isValidDataUploading: boolean
) => {
  const buttonStateForValidationErrors = areThereNoValidationErrors
    ? ButtonState.Active
    : ButtonState.Disabled;
  return isValidDataUploading
    ? ButtonState.Loading
    : buttonStateForValidationErrors;
};

export const getFileName = (
  merchantName: string | undefined,
  salesChannelName: string,
  adType: AdType,
  adLevel: AdLevel,
  fileType: string
) =>
  merchantName
    ? `${merchantName}_${salesChannelName}_${AD_TYPE_SHORT_FORM[adType]}_${adLevel}_${fileType}`
    : `${salesChannelName}_${AD_TYPE_SHORT_FORM[adType]}_${adLevel}_${fileType}`;

export const getUploadErrorMessage = (
  intl: IntlShape,
  isColumnMismatchValidationError: boolean
) => {
  if (isColumnMismatchValidationError) {
    return intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_COLUMN_MISMATCH_ERROR,
    });
  } else {
    return intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_GENERIC_ERROR,
    });
  }
};

export const getValidDataUploadButtonState = (
  errorAndTotalCount: ValidationCount,
  uploadingValidData: boolean
) => {
  const uploadingButtonState = uploadingValidData
    ? ButtonState.Loading
    : ButtonState.Active;

  const areAllRowsInvalid =
    errorAndTotalCount.totalCount - errorAndTotalCount.errorCount === 0;

  return areAllRowsInvalid ? ButtonState.Disabled : uploadingButtonState;
};

export function ManageBiddingInternal<RequestBody>({
  aoApiClient,
  request,
  adLevel,
  filterFieldMapper,
  additionalFilters = [],
  salesChannelName,
  adType,
  merchantName,
  refreshTable,
  salesChannelId,
  merchantType,
  showAsIcon,
  isUserRoleViewOnly = false,
}: ManageBiddingInternalProps<RequestBody>) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [biddingModalState, setBiddingModalState] = useState<BiddingModalState>(
    BiddingModalState.Default
  );
  const [errorAndTotalCount, setErrorAndTotalCount] = useState<ValidationCount>(
    initialErrorAndTotalCount
  );
  const [noValidationError, setNoValidationError] = useState<boolean>(false);
  const [validRecordsFileName, setValidRecordsFileName] = useState<string>('');
  const [invalidRecordsFileName, setInvalidRecordsFileName] =
    useState<string>('');

  const [isColumnMismatchValidationError, setIsColumnMismatchValidationError] =
    useState<boolean>(false);

  const [
    missingColumnsOrBidConfigMessage,
    setMissingColumnsOrBidConfigMessage,
  ] = useState<string>('');

  const [isFileDownloaded, setIsFileDownloaded] = useState<boolean>(false);
  const [isErrorFileDownloaded, setIsErrorFileDownloaded] =
    useState<boolean>(false);
  const [isErrorFileDownloading, setIsErrorFileDownloading] =
    useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadingValidData, setUploadingValidData] = useState<boolean>(false);

  const toasts = useContext<NotificationContextState>(NotificationContext);
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;

  const intl = useIntl();

  const currentTableFilters = useSelector<AdOptimizationState, Filter[]>(
    ({ tableState }) => {
      return tableSelectors.getFiltersSelector()(
        tableState,
        getTableIdByAdLevel(adLevel || '')
      );
    }
  );

  const sorts = useSelector<AdOptimizationState, Sort[]>(({ tableState }) => {
    return tableSelectors.getSortsSelector()(
      tableState,
      getTableIdByAdLevel(adLevel || '')
    );
  });

  const openIntercomChat = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    talkToUs();
  };

  const exportBiddingTemplateApi = () => {
    const entity = ADLEVEL_TO_ADLEVEL_API_MAPPER[adLevel];
    const exportFilters = [...currentTableFilters, ...additionalFilters];
    return aoApiClient.exportAdLevelData(
      accountId,
      request,
      entity,
      exportFilters,
      sorts,
      filterFieldMapper
    );
  };

  const exportBiddingErrorFileApi = () => {
    return aoApiClient.manageBiddingErrorFileDownload(
      accountId,
      invalidRecordsFileName
    );
  };

  const onErrorDownloadFile = () => {
    setBiddingModalState(BiddingModalState.Default);
    setIsErrorFileDownloaded(false);
    setIsErrorFileDownloading(false);
    failureDownloadToast();
  };

  const preDownloadFile = () =>
    setBiddingModalState(BiddingModalState.Downloading);
  const postDownloadFile = () => setIsFileDownloaded(true);

  const { downloadFile } = useDownloadFile({
    asyncFunction: exportBiddingTemplateApi,
    preDownloadFile,
    postDownloadFile,
    onError: onErrorDownloadFile,
    fileName: getFileName(
      merchantName,
      salesChannelName,
      adType,
      adLevel,
      'settings.csv'
    ),
  });

  const preDownloadErrorFile = () => setIsErrorFileDownloading(true);
  const postDownloadErrorFile = () => {
    setIsErrorFileDownloading(false);
    setIsErrorFileDownloaded(true);
  };

  const { downloadFile: downloadErrorFile } = useDownloadFile({
    asyncFunction: exportBiddingErrorFileApi,
    preDownloadFile: preDownloadErrorFile,
    postDownloadFile: postDownloadErrorFile,
    onError: onErrorDownloadFile,
    fileName: getFileName(
      merchantName,
      salesChannelName,
      adType,
      adLevel,
      'errors.csv'
    ),
  });

  const failureDownloadToast = () =>
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_ERROR_HEADLINE,
      }),
      description: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_ERROR_DESCRIPTION,
      }),
      type: Type.Attention,
      dataTestId: 'ao_manageBiddingFileDownloadError',
    });

  const onClickUploadFile = () => {
    if (uploadedFile) {
      setBiddingModalState(BiddingModalState.Uploading);
      getSecureUrl(uploadedFile);
    }
  };

  const onCompleteUploadClick = (validDataFileName: string) => {
    setUploadingValidData(true);
    aoApiClient
      .manageBiddingValidDataUpload(
        accountId,
        adLevel,
        validDataFileName,
        salesChannelName,
        salesChannelId,
        adType,
        [merchantType],
        showSmartCampaigns
      )
      .then((res) => {
        setUploadingValidData(false);
        setBiddingModalState(BiddingModalState.Uploading);

        const uploadStatus = () =>
          aoApiClient.manageBiddingS3FileUploadStatus(
            accountId,
            String(res.data.jobId)
          );

        const syncStatusRunsObservable = defer(() => uploadStatus());
        const subscription = polling(syncStatusRunsObservable, {
          interval: CSV_UPLOAD_SYNC_STATUS_POLLING_INTERVAL,
          attempts: CSV_UPLOAD_SYNC_STATUS_POLLING_MAX_TRIES,
        }).subscribe(
          (response: AxiosResponse<ManageBiddingUploadStatusResponse, any>) => {
            const status = response.data.status;
            if (status === UploadStatusResponses.SUCCESS) {
              subscription.unsubscribe();
              setBiddingModalState(BiddingModalState.Uploaded);
            }
            if (
              status === UploadStatusResponses.FAILED ||
              status === UploadStatusResponses.UNKNOWN
            ) {
              subscription.unsubscribe();
              setBiddingModalState(BiddingModalState.ErrorInUpload);
            }
          }
        );

        return () => subscription.unsubscribe();
      })
      .catch((err) => {
        setBiddingModalState(BiddingModalState.ErrorInValidDataUpload);
        setUploadingValidData(false);
      });
  };

  const validateUpload = async (fileName: string) => {
    setBiddingModalState(BiddingModalState.Validating);
    aoApiClient
      .manageBiddingValidationRequest(
        accountId,
        adLevel,
        fileName,
        salesChannelName,
        salesChannelId,
        adType,
        [merchantType],
        showSmartCampaigns
      )
      .then((res) => {
        if (res.data.invalidRows) {
          setBiddingModalState(BiddingModalState.ValidationError);
          setErrorAndTotalCount({
            errorCount: res.data.invalidRows,
            totalCount: res.data.totalRows,
          });
          setInvalidRecordsFileName(res.data.validationErrorFileName!);
        } else {
          setBiddingModalState(BiddingModalState.Validating);
          setNoValidationError(true);
        }
        setValidRecordsFileName(res.data.successRecordFileName!);
      })
      .catch((err: AxiosError<ValidateUploadErrorResponsesStructure>) => {
        if (err.response?.status === HttpResponseCode.BadRequest) {
          setIsColumnMismatchValidationError(true);

          if (
            err.response.data.errorCode &&
            err.response.data.errorCode ===
              ValidateUploadErrorResponses.MissingMandatoryColumns
          ) {
            setMissingColumnsOrBidConfigMessage(
              err.response.data.message.substr(
                err.response.data.message.indexOf(':') + 1
              )
            );
          }
        }
        setBiddingModalState(BiddingModalState.ErrorInValidDataUpload);
      });
  };

  const uploadBiddingFile = async (
    file: File,
    fileName: string,
    uploadUrl: string
  ) => {
    aoApiClient
      .manageBiddingS3FileUpload(uploadUrl, file)
      .then((res) => validateUpload(fileName))
      .catch((err) =>
        setBiddingModalState(BiddingModalState.ErrorInValidDataUpload)
      );
  };

  const getSecureUrl = async (file: File) => {
    const timeInSeconds = DateTime.local().toMillis();
    const uniqueFileName = `${accountId}_${timeInSeconds}_${file.name}`;
    setBiddingModalState(BiddingModalState.Uploading);
    aoApiClient
      .manageBiddingGetS3SecureUrl(accountId, uniqueFileName)
      .then((res) => uploadBiddingFile(file, uniqueFileName, res.data.url))
      .catch((err) =>
        setBiddingModalState(BiddingModalState.ErrorInValidDataUpload)
      );
  };

  const onFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const files: FileList = (event.target.files as FileList) || [];
    const selectedFile = first(files);
    if (selectedFile) {
      setUploadedFile(selectedFile);
      setBiddingModalState(BiddingModalState.Default);
    }
  };

  const onFileRemove = () => {
    setBiddingModalState(BiddingModalState.Default);
    setUploadedFile(null);
  };

  const onUploadSuccess = () => {
    refreshTable();
    cleanUpBiddingModal();
  };

  const cleanUpBiddingModal = () => {
    resetState();
    setShowModal(false);
  };

  const resetState = () => {
    setIsFileDownloaded(false);
    setBiddingModalState(BiddingModalState.Default);
    setUploadedFile(null);
    setErrorAndTotalCount(initialErrorAndTotalCount);
    setIsColumnMismatchValidationError(false);
    setNoValidationError(false);
    setIsErrorFileDownloaded(false);
    setMissingColumnsOrBidConfigMessage('');
  };

  const onClickManageBidding = () => setShowModal(true);

  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);
  const showSmartCampaigns = shouldShowSmartCampaigns(optimizelyContext);

  const downloadStepProps: DownloadFileStepProps = {
    header: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_HEADER,
    }),
    description: intl.formatMessage<ReactNode>(
      {
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_DESCRIPTION,
      },
      {
        bold: (content) => <span className="font-semibold">{content}</span>,
      }
    ),
    instructions: intl.formatMessage({
      id: showSmartCampaigns
        ? I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_INSTRUCTIONS_SMART_ACOS
        : I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_INSTRUCTIONS,
    }),
    buttonText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_BUTTON,
    }),
    downloadSuccessText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_SUCESS,
    }),
    downloadButtonSize: ButtonSize.Small,
  };

  const moreInfoStepProps: MoreInfoStepProps = {
    header: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_HEADER,
    }),
    description: intl.formatMessage<ReactNode>(
      {
        id: showSmartCampaigns
          ? I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_DESCRIPTION_SMART_ACOS
          : I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_DESCRIPTION,
      },
      {
        bold: (content) => <span className="font-semibold">{content}</span>,
      }
    ),
    linkText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_LINK,
    }),
  };

  const uploadFileStepProps: UploadFileStepProps = {
    header: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_HEADER,
    }),
    description: intl.formatMessage<ReactNode>(
      {
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_DESCRIPTION,
      },
      {
        bold: (content) => <span className="font-semibold">{content}</span>,
      }
    ),
    errorText: getUploadErrorMessage(intl, isColumnMismatchValidationError),
    retryButtonText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_RETRY_BUTTON,
    }),
    attachFileText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_ATTACH_FILE,
    }),
    uploadFileText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_UPLOAD_BUTTON,
    }),
    contentUploadingMessage: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOADING_FILE_MESSAGE,
    }),
  };

  const uploadSuccessStepProps: UploadSuccessStepProps = {
    header: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_HEADER,
    }),
    description: intl.formatMessage<ReactNode>(
      {
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_DESCRIPTION,
      },
      {
        bold: (content) => <span className="font-semibold">{content}</span>,
      }
    ),
    buttonText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_BUTTON,
    }),
  };

  const getValidationErrorsProps = (
    validationCount: ValidationCount
  ): ValidationErrorsProps => ({
    header: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_ERRORS_HEADER,
    }),
    description: intl.formatMessage(
      {
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_ERRORS_DESCRIPTION,
      },
      {
        validCount: validationCount.totalCount - validationCount.errorCount,
        totalCount: validationCount.totalCount,
      }
    ),
    downloadFileButtonProps: {
      buttonState: isErrorFileDownloading
        ? ButtonState.Loading
        : ButtonState.Active,
      buttonText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_ERROR_DOWNLOAD_BUTTON,
      }),
      downloadSuccessText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_ERROR_DOWNLOAD_BUTTON_TEXT,
      }),
      isFileDownloaded: isErrorFileDownloaded,
      onClickDownloadFile: downloadErrorFile,
      downloadButtonSize: ButtonSize.Large,
    },
    uploadFileButtonProps: {
      onUploadValidClick: () => onCompleteUploadClick(validRecordsFileName),
      uploadButtonState: getValidDataUploadButtonState(
        errorAndTotalCount,
        uploadingValidData
      ),
      uploadButtonText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_STEP_ERRORS_BUTTON_TEXT,
      }),
    },
  });

  const getValidationProgressProps = (
    areThereNoValidationErrors: boolean,
    isValidDataUploading: boolean
  ): ValidationProgressProps => ({
    buttonText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_BUTTON_TEXT,
    }),
    onCompleteUploadClick: () => onCompleteUploadClick(validRecordsFileName),
    header: areThereNoValidationErrors
      ? intl.formatMessage({
          id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_NO_ERRORS_HEADER,
        })
      : intl.formatMessage({
          id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_HEADER,
        }),
    buttonVariant: ButtonVariant.Primary,
    noValidationErrors: areThereNoValidationErrors,
    buttonState: getUploadValidDataButtonState(
      areThereNoValidationErrors,
      isValidDataUploading
    ),
  });

  const getUploadErrorDescription = () => {
    if (
      isColumnMismatchValidationError &&
      missingColumnsOrBidConfigMessage.length
    ) {
      return intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_COLUMN_MISMATCH_ERROR_WITH_COLUMNS,
        },
        {
          columns: missingColumnsOrBidConfigMessage,
        }
      );
    }

    return isColumnMismatchValidationError
      ? intl.formatMessage({
          id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_COLUMN_MISMATCH_ERROR,
        })
      : intl.formatMessage<ReactNode>(
          {
            id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_STEP_ERRORS_DESCRIPTION,
          },
          {
            underline: (content) => (
              <span
                className="underline cursor-pointer"
                onClick={openIntercomChat}
              >
                {content}
              </span>
            ),
          }
        );
  };

  const getUploadErrorProps = (): UploadErrorProps => ({
    buttonText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_TRY_AGAIN,
    }),
    description: getUploadErrorDescription(),
    header: isColumnMismatchValidationError
      ? intl.formatMessage({
          id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_COLUMN_MISMATCH_ERROR_HEADER,
        })
      : intl.formatMessage({
          id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_STEP_ERRORS_HEADER,
        }),
    onTryAgainClick: resetState,
  });

  return (
    <>
      <ViewerRoleTooltip
        position={{
          placement: Placement.Left,
          alignment: Alignment.Center,
        }}
        disabled={!isUserRoleViewOnly}
      >
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.BlackAndWhiteBorder}
          label={
            showAsIcon
              ? undefined
              : intl.formatMessage({ id: I18nKey.ADS_MANAGER_MANAGE_BIDDING })
          }
          svgIcon={showAsIcon ? BulkManageLinear : undefined}
          onClick={isUserRoleViewOnly ? undefined : onClickManageBidding}
          dataTestId={'ao_manageBidding'}
          state={
            isUserRoleViewOnly ? ButtonState.Disabled : ButtonState.Enabled
          }
        />
      </ViewerRoleTooltip>
      <ManageBiddingModal
        showModal={showModal}
        onClickDownloadFile={downloadFile}
        externalUrl={EDIT_BIDDING_HELP_LINK}
        onClickUploadFile={onClickUploadFile}
        uploadedStateOnClick={onUploadSuccess}
        onFileSelected={onFileSelected}
        onFileRemove={onFileRemove}
        onClose={cleanUpBiddingModal}
        onClickRetry={resetState}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        modalState={biddingModalState}
        isFileDownloaded={isFileDownloaded}
        selectedFileName={uploadedFile?.name}
        modalHeader={intl.formatMessage({
          id: I18nKey.ADS_MANAGER_MANAGE_BIDDING,
        })}
        uploadSuccessStepProps={uploadSuccessStepProps}
        downloadFileStepProps={downloadStepProps}
        moreInfoStepProps={moreInfoStepProps}
        uploadFileStepProps={uploadFileStepProps}
        validationErrorsProps={getValidationErrorsProps(errorAndTotalCount)}
        validationProgressProps={getValidationProgressProps(
          noValidationError,
          uploadingValidData
        )}
        uploadErrorProps={getUploadErrorProps()}
      />
    </>
  );
}
ManageBiddingInternal.displayName = 'ManageBiddingInternal';
