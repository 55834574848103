import {
  ColumnAlign,
  TableCellV2,
  TableCellV2Type,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { MerchantsGroup } from '../../authProductData';
import { FlywheelTableV2ColumnProps } from 'containers/tableV2/types';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { AuthorizeMerchantTableProps } from '..';
import { FormattedMessage } from 'react-intl';

export const regionColumn: FlywheelTableV2ColumnProps<
  MerchantsGroup,
  {},
  Pick<AuthorizeMerchantTableProps, 'handleOauthPopup'>
> = {
  header: I18nKey.FLASH_MODALS_AMAZON_TABLE_REGION,
  cell: ({ row: { original } }) => {
    const { key } = original;

    return (
      <TableCellV2
        tableCellData={{
          currentValue: (
            <Typography
              size={TypographySize.base}
              lineHeight={TypographyLineHeight.none}
              weight={TypographyWeight.regular}
            >
              <FormattedMessage id={key} />
            </Typography>
          ),
          tableCellType: TableCellV2Type.Text,
        }}
      />
    );
  },
  accessorKey: 'region',
  sortable: true,
  size: 150,
  minSize: 150,
  align: ColumnAlign.Left,
};
