import React, { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Alignment,
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CheckboxCheckedState,
  CheckboxLabel,
  ConditionalWrapper,
  Placement,
  Theme,
  Tooltip,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { AuthorizeWalmartModalProps } from './';

export const ModalFooter: React.FC<
  Pick<AuthorizeWalmartModalProps, 'goBack' | 'authorize'> & {
    readonly authorizeButtonClicked?: boolean;
  }
> = ({ goBack, authorize, authorizeButtonClicked }) => {
  const intl = useIntl();
  const [trustedPartnerCheckboxState, setTrustedPartnerCheckboxState] =
    useState<CheckboxCheckedState>(CheckboxCheckedState.Unchecked);

  const toggleTrustedPartnerCheckboxState = () => {
    setTrustedPartnerCheckboxState(
      trustedPartnerCheckboxState === CheckboxCheckedState.Checked
        ? CheckboxCheckedState.Unchecked
        : CheckboxCheckedState.Checked
    );
  };

  return (
    <div className="flex flex-col -m-24">
      <div className="flex w-full flex-col items-center py-16 px-24 bg-grey-50 border-solid border-b-1 border-grey-200">
        <CheckboxLabel
          label={intl.formatMessage({
            id: I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_CONFIRM_CHECKBOX,
          })}
          onClick={() => {
            toggleTrustedPartnerCheckboxState();
          }}
          className="text-left"
          checked={trustedPartnerCheckboxState}
          dataTestId="flash1_walmart_authorize_account"
        />
      </div>
      <div className="flex justify-between p-24">
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.BlackAndWhiteBorder}
          label={intl.formatMessage({ id: I18nKey.BACK })}
          svgIcon={ArrowLeftIcon}
          onClick={goBack}
          dataTestId={`flash1_walmart_ads_auth_form_back`}
        />
        <ConditionalWrapper
          condition={
            trustedPartnerCheckboxState !== CheckboxCheckedState.Checked ||
            !authorizeButtonClicked
          }
          wrapper={(children: ReactNode) => (
            <Tooltip
              content={intl.formatMessage({
                id: I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_CONFIRM_TOOLTIP,
              })}
              theme={Theme.Dark}
              position={{
                placement: Placement.Left,
                alignment: Alignment.Center,
              }}
              overwrittenTooltipClassnames="min-w-max"
            >
              {children}
            </Tooltip>
          )}
        >
          <Button
            size={ButtonSize.Medium}
            variant={ButtonVariant.Primary}
            label={intl.formatMessage({ id: I18nKey.CONTINUE })}
            onClick={authorize}
            state={
              trustedPartnerCheckboxState === CheckboxCheckedState.Checked &&
              !!authorizeButtonClicked
                ? ButtonState.Enabled
                : ButtonState.Disabled
            }
            dataTestId={`flash1_walmart_ads_auth_form_continue`}
          />
        </ConditionalWrapper>
      </div>
    </div>
  );
};
