import './styles.scss';

import classNames from 'classnames';
import { fromNullable } from 'fp-ts/lib/Option';
import isUndefined from 'lodash/isUndefined';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  PortaledTooltip,
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../lib/types/I18nKey';

export interface Tab {
  readonly nameI18nKey: I18nKey;
  readonly theme?: TabTheme;
  readonly toolTipContent?: string | JSX.Element;
}
export enum TabStyle {
  Default = 'Default',
  Highlighter = 'Highlighter',
}

export enum TabTheme {
  Blue = 'Blue',
  Purple = 'Purple',
  LightPurple = 'LightPurple',
  Transparent = 'Transparent',
}

export interface TabProps {
  readonly className?: string;
  readonly tab: Tab;
  readonly index: number;
  readonly activeTabIndex: number;
  readonly onTabSelect: () => void;
}

export const TabDefault: React.FC<TabProps> = ({
  onTabSelect,
  tab,
  index,
  activeTabIndex,
  className,
}) => (
  <div
    onClick={onTabSelect}
    key={index}
    className={classNames([
      'tab-group__tab',
      {
        'tab-group__tab--blue':
          tab.theme === TabTheme.Blue && activeTabIndex === index,
        'tab-group__tab--purple':
          tab.theme === TabTheme.Purple && activeTabIndex === index,
        'tab-group__tab--light-purple':
          tab.theme === TabTheme.LightPurple && activeTabIndex === index,
        'tab-group__tab--transparent':
          (isUndefined(tab.theme) && activeTabIndex === index) ||
          (tab.theme === TabTheme.Transparent && activeTabIndex === index),
      },
      className,
    ])}
  >
    <FormattedMessage id={tab.nameI18nKey} />
  </div>
);

export const TabHighlighter: React.FC<TabProps> = ({
  onTabSelect,
  tab,
  index,
  activeTabIndex,
  className,
}) => (
  <div
    onClick={onTabSelect}
    key={index}
    className={classNames([
      'tab-group-with-highlighter__tab',
      {
        'tab-group-with-highlighter--purple':
          tab.theme === TabTheme.Purple && activeTabIndex === index,
      },
      className,
    ])}
  >
    <div className="tab-group-with-highlighter__highlight" />
    <div className="tab-group-with-highlighter__text">
      <FormattedMessage id={tab.nameI18nKey} />
    </div>
  </div>
);
TabHighlighter.displayName = 'TabHighlighter';

export interface TabComponentProps {
  readonly className?: string;
  readonly tab: Tab;
  readonly index: number;
  readonly activeTabIndex: number;
  readonly onTabSelect: (selectedTabIndex: number) => void;
  readonly tabStyle?: TabStyle;
}

const TabComponent: React.FC<TabComponentProps> = (props) => {
  const onTabSelect = () => props.onTabSelect(props.index);
  switch (props.tabStyle) {
    case TabStyle.Highlighter:
      return (
        <TabHighlighter
          onTabSelect={onTabSelect}
          tab={props.tab}
          index={props.index}
          activeTabIndex={props.activeTabIndex}
          className={props.className}
        />
      );
    case TabStyle.Default:
    default:
      return (
        <TabDefault
          onTabSelect={onTabSelect}
          tab={props.tab}
          index={props.index}
          activeTabIndex={props.activeTabIndex}
          className={props.className}
        />
      );
  }
};
TabComponent.displayName = 'TabComponent';

export interface TabGroupProps {
  readonly onTabSelect: (selectedTabIndex: number) => void;
  readonly className?: string;
  readonly maybeDefaultTabIndex?: number;
  readonly tabs: Tab[];
  readonly tabStyle?: TabStyle;
  readonly dataTestId?: string;
}

export const TabGroup: React.FC<TabGroupProps> = (props) => {
  const [activeTabIndex, updateTabIndex] = useState<number>(
    fromNullable(props.maybeDefaultTabIndex).getOrElse(0)
  );

  useEffect(() => {
    updateTabIndex(fromNullable(props.maybeDefaultTabIndex).getOrElse(0));
  }, [props.maybeDefaultTabIndex]);

  const { tabStyle = TabStyle.Default } = props;

  const onTabSelect = (selectedTabIndex: number) => {
    updateTabIndex(selectedTabIndex);
    props.onTabSelect(selectedTabIndex);
  };

  return (
    <div
      className={classNames({
        'tab-group': tabStyle === TabStyle.Default,
        'tab-group-with-highlighter': tabStyle === TabStyle.Highlighter,
      })}
      data-test-id={`${props.dataTestId}_tabGroup`}
    >
      {props.tabs.map((tab, index) => {
        return fromNullable(tab.toolTipContent)
          .map((toolTipContent) => (
            <PortaledTooltip
              content={toolTipContent}
              position={{
                placement: PortaledTooltipPlacement.Top,
                placementOffsetInPixels: 0,
              }}
              theme={PortaledTooltipTheme.Dark}
              key={index}
              className="tab-group__tool_tip"
            >
              <TabComponent
                tab={tab}
                index={index}
                className={props.className}
                activeTabIndex={activeTabIndex}
                onTabSelect={onTabSelect}
                tabStyle={tabStyle}
              />
            </PortaledTooltip>
          ))
          .getOrElse(
            <TabComponent
              tab={tab}
              key={index}
              index={index}
              className={props.className}
              activeTabIndex={activeTabIndex}
              onTabSelect={onTabSelect}
              tabStyle={tabStyle}
            />
          );
      })}
    </div>
  );
};
TabGroup.displayName = 'TabGroup';
