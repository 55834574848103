import { useContext, useEffect, useState } from 'react';
import {
  AlertTriangleIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Icon,
  IconSize,
  TextInput,
  TextLink,
  TextLinkSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, useIntl } from 'react-intl';
import I18nKey from './lib/types/I18nKey';
import NewAccountIcon from './img/new-account.png';
import NewAccountSuccessIcon from './img/new-account-success.png';
import {
  UserContext,
  UserContextState,
} from './containers/userProvider/userProvider';
import { createFAMApiClient } from './lib/clients/FAMApiClient';

export interface CreateNewClientAccountProps {
  readonly showClientModal: boolean;
  readonly setShowClientModal: (show: boolean) => void;
  readonly parentAccountId: string;
}

export const CreateNewClientAccount: React.FC<CreateNewClientAccountProps> = ({
  showClientModal,
  setShowClientModal,
  parentAccountId,
}) => {
  const intl = useIntl();
  const userContext = useContext<UserContextState>(UserContext);
  const [buttonState, setButtonState] = useState(ButtonState.Disabled);
  const [createAccountProcessing, setCreateAccountProcessing] = useState(false);
  const [createAccountSuccess, setCreateAccountSuccess] = useState(false);
  const [createAccountError, setCreateAccountError] = useState<string>('');
  const [accountName, setAccountName] = useState<string>('');
  const famApiClient = createFAMApiClient(userContext?.userInfo?.idToken!);

  const setCreateButtonState = () => {
    const buttonState = !isEmpty(accountName)
      ? ButtonState.Active
      : ButtonState.Disabled;
    setButtonState(createAccountProcessing ? ButtonState.Loading : buttonState);
  };

  const [
    CREATE_NEW_CLIENT_ACCOUNT,
    NEW_ACCOUNT_RELATIONSHIP_NAME_LABEL,
    CREATE,
    CANCEL,
    CREATE_NEW_CLIENT_ACCOUNT_SUCCESS,
    ERROR,
  ] = [
    I18nKey.CREATE_NEW_CLIENT_ACCOUNT,
    I18nKey.NEW_ACCOUNT_RELATIONSHIP_NAME_LABEL,
    I18nKey.GENERIC_CREATE,
    I18nKey.GENERIC_CANCEL,
    I18nKey.CREATE_NEW_CLIENT_ACCOUNT_SUCCESS,
    I18nKey.GENERIC_SOMETHING_WENT_WRONG,
  ].map((id) => intl.formatMessage({ id }));

  useEffect(() => {
    setCreateButtonState();
  }, [accountName, createAccountProcessing]);

  const onCreateClicked = async () => {
    setCreateAccountProcessing(true);

    try {
      await famApiClient.createAccountRelationship(
        parentAccountId,
        accountName
      );
      setCreateAccountSuccess(true);
      userContext.refreshUserContext();
      setTimeout(() => {
        setShowClientModal(false);
      }, 2000);
    } catch {
      setCreateAccountError(ERROR);
    } finally {
      setCreateAccountProcessing(false);
    }
  };

  return (
    <div className={classNames('absolute w-full h-full')}>
      <div className="absolute h-12 flex items-baseline right-20 top-16">
        <TextLink
          size={TextLinkSize.Small}
          textLabel={CANCEL}
          onClick={() => setShowClientModal(false)}
          className="text-sm leading-none font-normal text-grey-900"
        />
      </div>
      <div className="flex flex-col px-40 gap-40 self-stretch rounded-t-lg border border-solid border-purple-100 bg-purple-50 grow shrink-0 basis-0 h-406">
        <div
          className={classNames(
            'flex flex-row justify-center items-start w-full',
            {
              '-mt-16': showClientModal,
              'mt-0': !showClientModal,
            }
          )}
        >
          <div className="flex flex-col items-center w-full gap-16">
            <img
              alt="New account icon"
              src={
                createAccountSuccess ? NewAccountSuccessIcon : NewAccountIcon
              }
            />
            <Typography
              size={TypographySize.xl}
              lineHeight={TypographyLineHeight.none}
              weight={TypographyWeight.medium}
              className="text-purple-800 text-center"
            >
              {createAccountSuccess
                ? CREATE_NEW_CLIENT_ACCOUNT_SUCCESS
                : CREATE_NEW_CLIENT_ACCOUNT}
            </Typography>
            {createAccountSuccess && (
              <Typography
                size={TypographySize.base}
                lineHeight={TypographyLineHeight.normal}
                weight={TypographyWeight.regular}
                className="text-grey-900"
              >
                <FormattedMessage
                  id={I18nKey.NEW_ACCOUNT_RELATIONSHIP_CREATED}
                  values={{
                    name: accountName,
                  }}
                />
              </Typography>
            )}
          </div>
        </div>

        {!createAccountSuccess && (
          <div className="flex flex-col items-start w-full gap-16">
            <div className="flex flex-col items-start w-full gap-4">
              <label>
                <Typography
                  size={TypographySize.base}
                  lineHeight={TypographyLineHeight.tight}
                  weight={TypographyWeight.regular}
                >
                  {NEW_ACCOUNT_RELATIONSHIP_NAME_LABEL}
                </Typography>
              </label>
              <TextInput className="block w-full" onChange={setAccountName} />
            </div>
            <Button
              size={ButtonSize.Large}
              variant={ButtonVariant.Primary}
              onClick={onCreateClicked}
              label={CREATE}
              dataTestId={'as_create_new_client_account_button'}
              className="w-full"
              state={buttonState}
            />
            {createAccountError && (
              <div
                className={classNames(
                  'flex items-center gap-8 h-32 px-8 w-full',
                  'bg-orange-100 text-sm leading-none font-medium rounded'
                )}
              >
                <Icon
                  size={IconSize.Medium}
                  svg={AlertTriangleIcon}
                  className="text-orange-500"
                />
                <Typography
                  size={TypographySize.sm}
                  lineHeight={TypographyLineHeight.none}
                  weight={TypographyWeight.semibold}
                  className="text-orange-700"
                >
                  {createAccountError}
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
