import './styles.scss';

import { FormattedMessage } from 'react-intl';

import I18nKey from '../../../../lib/types/I18nKey';

export interface NoDataProps {
  readonly icon: JSX.Element;
  readonly title: I18nKey;
  readonly description?: JSX.Element;
}

export const NoData: React.FC<NoDataProps> = ({ icon, title, description }) => {
  return (
    <div className="no-data">
      <div className="no-data__icon">{icon}</div>
      <div className="no-data__title">
        <FormattedMessage id={title} values={{ separator: <br /> }} />
      </div>
      {description && <div className="no-data__description">{description}</div>}
    </div>
  );
};

NoData.displayName = 'NoData';
