import {
  AiStatus,
  Alignment,
  ColumnAlign,
  GraphTrendUpIcon,
  IconSize,
  Placement,
  TableCellV2,
  TableCellV2Type,
  Theme,
  TooltipSize,
} from '@teikametrics/tm-design-system';
import React, { useContext } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { NavPaths } from '../../../../../../NavPaths';
import { FlywheelTableV2ColumnProps } from '../../../../../../containers/tableV2/types';
import { getChannelIcon } from '../../../../../../lib/clients/utils';
import { MAP_AD_TYPE_TO_INTL_NAME } from '../../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../../lib/types/I18nKey';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
} from '../../../../../advertisingOptimization/containers/adsManager/types';
import {
  getMerchantCountry,
  getMerchantName,
} from '../../../../../advertisingOptimization/containers/adsManager/utils';
import { BusinessPerformanceColumnName } from '../../utils/constants';
import {
  CampaignCellData,
  CampaignMetrics,
  PerformanceTableExtraProps,
} from '../../utils/performance';
import { ViewTrendsContext } from '../../../../../advertisingOptimization/components/ViewTrends/ViewTrendsProvider';
import { buildUrlWithQueryParams } from '../../../../../../lib/utilities/buildUrlUtilities';

export const getCampaignUrl = (data: CampaignCellData) => {
  return buildUrlWithQueryParams(
    `${
      NavPaths.AOAdsManager
    }/${data.merchantCountryDetails.salesChannelName.toLowerCase()}/${
      AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER[
        data.campaignDetails.advertisingType
      ]
    }/${ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns}/${encodeURIComponent(
      data.campaignId
    )}`,
    {
      [SALES_CHANNEL_ID]: data.merchantCountryDetails.salesChannelId ?? '',
      [MERCHANT_COUNTRY_IDS]:
        data.merchantCountryDetails.merchantCountryId ?? '',
    }
  );
};

export const getTooltipContent = (
  intl: IntlShape,
  flywheelAiAddedDate?: string
) => {
  if (flywheelAiAddedDate) {
    return (
      <AiStatus
        title={intl.formatMessage({
          id: I18nKey.COMPASS_FLYWHEEL_AI_POWERED_TOOLTIP_MESSAGE,
        })}
        items={[]}
      />
    );
  }

  return intl.formatMessage({
    id: I18nKey.COMPASS_FLYWHEEL_AI_DISABLED_TOOLTIP_MESSAGE,
  });
};

export const RowCellElement: React.FC<CampaignCellData> = ({
  campaignId,
  campaignDetails,
  merchantCountryDetails,
  merchants,
}) => {
  const intl = useIntl();

  const viewTrendsContext = useContext(ViewTrendsContext);

  const toggleSlideover = () => {
    viewTrendsContext?.updateContext &&
      viewTrendsContext.updateContext({
        merchantCountryId: merchantCountryDetails.merchantCountryId,
        merchantName: merchantCountryDetails.merchantCountryName,
        campaignId,
        campaignName: campaignDetails.campaignName,
        targetingType: undefined,
        isViewTrendsOpen: !viewTrendsContext?.isViewTrendsOpen,
        entityId: campaignId,
        entityType: 'CAMPAIGN',
        entityName: campaignDetails.campaignName,
        adType: campaignDetails.advertisingType,
        merchantType: merchantCountryDetails.merchantType,
        isSKUSlideoverOpen: viewTrendsContext?.isSKUSlideoverOpen,
        targetSegments: viewTrendsContext?.targetSegments,
        channelId: merchantCountryDetails.salesChannelId,
      });
  };

  return (
    <TableCellV2
      tableCellData={{
        suffixIcon: {
          size: IconSize.Small,
          svg: GraphTrendUpIcon,
          dataTestId: 'business_performance_campaign_details',
          onClick: () => toggleSlideover(),
          className:
            'text-grey-600 font-bold cursor-pointer hover:text-purple-500 ml-8',
        },
        suffixTooltipProps: {
          content: <FormattedMessage id={I18nKey.GENERIC_VIEW_TRENDS} />,
          theme: Theme.Dark,
          hideArrow: true,
          tooltipSize: TooltipSize.Small,
          strategy: 'absolute',
          position: {
            placement: Placement.Bottom,
            alignment: Alignment.Center,
          },
          overwrittenTooltipClassnames: 'text-base',
        },
        tableCellType: TableCellV2Type.Campaign,
        title: campaignDetails?.campaignName,
        country:
          getMerchantCountry(
            merchants,
            merchantCountryDetails.merchantCountryId
          ) || '',
        adType: intl.formatMessage({
          id: MAP_AD_TYPE_TO_INTL_NAME[campaignDetails.advertisingType],
        }),
        url: getCampaignUrl({
          campaignId,
          campaignDetails,
          merchantCountryDetails,
          merchants,
        }),
        merchantName:
          getMerchantName(
            merchants,
            merchantCountryDetails.merchantCountryId
          ) ?? '',
        merchantIcon: {
          size: IconSize.Small,
          svg: getChannelIcon(merchantCountryDetails.salesChannelName)!,
        },
      }}
    />
  );
};

export const campaignDetails: FlywheelTableV2ColumnProps<
  CampaignMetrics,
  {},
  PerformanceTableExtraProps
> = {
  header: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CAMPAIGN,
  sortable: false,
  size: 200,
  align: ColumnAlign.Left,
  accessorKey: BusinessPerformanceColumnName.CampaignDetails,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <RowCellElement {...original} {...meta} />,
};
