import './styles.scss';

import classNames from 'classnames';
import React, { useState } from 'react';

import { OffClickable } from '@teikametrics/tm-design-system';

import { ReactComponent as CloseIcon } from '../../img/icons_deprecated/close.svg';
import { ReactComponent as DownArrowIcon } from '../../img/icons_deprecated/solid-triangle-down.svg';
import { isNotUndefined } from '../../lib/utilities/typeGuards';

export interface PillProps {
  readonly text: string | JSX.Element;
  readonly handleCloseClicked?: () => void;
  readonly handlePillClicked?: () => void;
  readonly prependedElement?: JSX.Element;
  readonly color?: PillColor;
  readonly hover?: boolean;
  readonly popover?: JSX.Element;
  readonly dataTestId?: string;
}

export enum PillColor {
  Purple = 'Purple',
  Grey = 'Grey',
  Green = 'Green',
  Red = 'Red',
  Gold = 'Gold',
  Blue = 'Blue',
}

export const Pill: React.FC<PillProps> = ({
  text,
  handleCloseClicked,
  handlePillClicked,
  prependedElement,
  color = PillColor.Purple,
  hover = false,
  popover,
}) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const togglePopover = () => setShowPopover((prevState) => !prevState);
  const closePopover = () => setShowPopover(false);

  const shouldShowPopoverContent: boolean =
    isNotUndefined(popover) && showPopover;
  const shouldShowPopoverButton: boolean =
    isNotUndefined(popover) && handleCloseClicked === undefined;
  const shouldShowCloseButton: boolean =
    isNotUndefined(handleCloseClicked) && popover === undefined;
  const onPillClicked: () => void = handlePillClicked || togglePopover;

  return (
    <OffClickable offClickHandler={closePopover}>
      <div className="pill-container">
        <div
          className={classNames('pill', `pill--${color.toLowerCase()}`, {
            [`pill--${color.toLowerCase()}--hover`]:
              hover && !shouldShowPopoverContent,
            [`pill--${color.toLowerCase()}--popover-show`]:
              shouldShowPopoverContent,
            [`pill--pointer`]:
              isNotUndefined(popover) || isNotUndefined(handlePillClicked),
          })}
        >
          <span className="click__container" onClick={onPillClicked}>
            {prependedElement && (
              <div className="pill__prepended-element">{prependedElement}</div>
            )}
            <div className="pill__text">{text}</div>
            {shouldShowPopoverButton && (
              <div className="pill__popover__arrow">
                <DownArrowIcon />
              </div>
            )}
          </span>
          {shouldShowCloseButton && (
            <div className="pill__close" onClick={handleCloseClicked}>
              <CloseIcon />
            </div>
          )}
        </div>
        {shouldShowPopoverContent && (
          <div className="pill__popover">{popover}</div>
        )}
      </div>
    </OffClickable>
  );
};
Pill.displayName = 'Pill';
