import capitalize from 'lodash/capitalize';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/flywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { PRODUCT_ADS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<ProductAdDetails> = (
  productAdDetails
) => {
  return makeTextColumn(
    (data: ProductAdDetails) =>
      data.adItemDetails.adGroupStatus
        ? capitalize(data.adItemDetails.adGroupStatus)
        : undefined,
    productAdDetails.pendingFields?.includes(
      PRODUCT_ADS_API_COLUMN_NAME.AdGroupStatus
    )
  )(productAdDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const adGroupStatusColumn: FlywheelTableColumn<ProductAdDetails> = {
  isSortable: true,
  columnName: PRODUCT_ADS_API_COLUMN_NAME.AdGroupStatus,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_AD_GROUP_STATUS,
  RowCellElement,
  gridColumnWidth: '140px',
};

export const adGroupStatusFilter = createMultiSelectDataFieldFilter(
  PRODUCT_ADS_API_COLUMN_NAME.AdGroupStatus,
  I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_AD_GROUP_STATUS,
  [
    SelectFilterOption.AdgroupStatusEnabled,
    SelectFilterOption.AdgroupStatusDisabled,
  ]
);
