import { Factory } from 'fishery';

import { AddNewSearchTermsStatusResponse } from '../../types/MISharedTypes';

export default Factory.define<AddNewSearchTermsStatusResponse>(() => ({
  new_search_terms: [],
  existing_search_terms: [],
  duplicated_search_terms: [],
  error_messages: [],
  n_input_search_terms: 0,
}));
