export const FADE_INTERVAL_MS = 1750;
export const FADE_OUT_INTERVAL_MS = 2500;

export type FadeProp = {
  fade: 'motion-safe:animate-fadeIn' | 'motion-safe:animate-fadeOut';
};

export enum AnimationSteps {
  Step1 = 'Step1',
  Step2 = 'Step2',
  Step3 = 'Step3',
}
