import {
  ArrowRightIcon,
  Badge,
  BadgeDisplayMode,
  BadgeType,
  Icon,
  IconSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavPaths } from '../../../NavPaths';
import { MerchantCountry, MerchantType } from '../../../lib/types/Fam';
import { RecommendationType } from '../../../lib/types/KeywordAction';
import { getCountryFlagIcon } from '../../../lib/utilities/countryFlags';
import { getSalesChannelIconFromId } from '../../recommendations/components/recommendationHubCards/recommendationCardsUtils';
import { KeywordTabPaths } from './KeywordsDashboard2';
import {
  RecommendationsCountContext,
  getKeywordCount,
} from './RecommendationsCountProvider';

interface MCIDDashboardLinkProps {
  readonly merchant: MerchantCountry;
}

export const MCIDDashboardLink: React.FC<MCIDDashboardLinkProps> = (props) => {
  const { merchant } = props;
  const navigate = useNavigate();

  const { recommendationsCount } = useContext(RecommendationsCountContext);

  const keywordsToAddCount = getKeywordCount(
    recommendationsCount,
    merchant.merchantCountryId,
    RecommendationType.KEYWORD,
    (acc, item) => acc + item.count
  );

  const patToAddCount = getKeywordCount(
    recommendationsCount,
    merchant.merchantCountryId,
    RecommendationType.PAT,
    (acc, item) => acc + item.count
  );

  const keywordsToNegateCount = getKeywordCount(
    recommendationsCount,
    merchant.merchantCountryId,
    RecommendationType.NEGATIVE_KEYWORD,
    (acc, item) => acc + item.count
  );

  // Function to construct the navigation path
  const constructNavPath = (merchantCountryId: string, relevantTab: string) => {
    return `${NavPaths.KeywordsTargets}/${merchantCountryId}/${relevantTab}`;
  };

  // Function to determine the relevant tab based on counts
  const getRelevantTabPathSuffix = () => {
    if (keywordsToNegateCount && !keywordsToAddCount) {
      return `${KeywordTabPaths.KeywordsToNegate}/keywords-by-performance`;
    } else if (patToAddCount) {
      return `${KeywordTabPaths.PatToAdd}/keywords-by-performance`;
    } else {
      return `${KeywordTabPaths.KeywordsToAdd}/keywords-by-performance`;
    }
  };

  // Refactored handleRedirect function
  const handleRedirect = () => {
    const relevantTabPathSuffix = getRelevantTabPathSuffix();
    const path = constructNavPath(
      merchant.merchantCountryId,
      relevantTabPathSuffix
    );
    navigate(path);
  };

  return (
    <div
      className={classNames(
        'flex w-full p-24 border-solid border-b last:border-b-0 border-grey-200 justify-between items-center cursor-pointer',
        'hover:bg-purple-50 transition-all ease-in duration-150'
      )}
      onClick={handleRedirect}
    >
      <div className="flex gap-8">
        <Icon
          size={IconSize.Medium}
          svg={getSalesChannelIconFromId(merchant.salesChannelId)}
        />
        <Icon
          size={IconSize.Medium}
          svg={getCountryFlagIcon(merchant.country)}
        />
        <Badge
          badgeType={
            merchant.merchantType === MerchantType.Seller
              ? BadgeType.TagThirdParty
              : BadgeType.TagFirstParty
          }
          badgeNameDisplayMode={BadgeDisplayMode.OnlyInitial}
        />
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.none}
          weight={TypographyWeight.regular}
        >
          {merchant.merchantName}
        </Typography>
      </div>
      <div className="flex gap-32 items-center">
        <div className="flex items-center rounded-56 px-12 py-4 gap-8 bg-purple-50 border-solid border border-grey-100">
          <Typography
            size={TypographySize.base}
            lineHeight={TypographyLineHeight.none}
            weight={TypographyWeight.regular}
          >
            {(keywordsToAddCount || 0) +
              (keywordsToNegateCount || 0) +
              (patToAddCount || 0)}
          </Typography>
        </div>
        <Icon
          size={IconSize.Small}
          svg={ArrowRightIcon}
          className="text-purple-500"
        />
      </div>
    </div>
  );
};
