import { Factory } from 'fishery';

import { MultiChannelCellData } from '../../../modules/advertisingOptimization/containers/dashboard/types';
import { AMAZON_SALES_CHANNEL_ID } from '../../../lib/types/SalesChannels';
import { MerchantType } from '../../../lib/types/Fam';

interface Response {
  readonly elements: MultiChannelCellData[];
  readonly filteredElements: number;
}

export default Factory.define<Response>(() => ({
  elements: [
    {
      salesChannelId: AMAZON_SALES_CHANNEL_ID,
      merchantCountries: [
        {
          merchantCountryId: '92faa498-eccd-4c59-999f-9fec646a8967',
          merchantCountryName: 'Merchant country 1',
          merchantType: MerchantType.Seller,
          salesChannelId: AMAZON_SALES_CHANNEL_ID,
          salesChannelName: 'Amazon',
          advertisingTypeMetrics: [],
          performance: {
            current: {},
            previous: {},
          },
        },
      ],
      performance: {
        current: {
          adConversions: 120,
          totalSales: {
            amount: '15000.00',
            currency: 'USD',
          },
          adSalesTotal: {
            amount: '5000.00',
            currency: 'USD',
          },
          adSpend: {
            amount: '1000.00',
            currency: 'USD',
          },
          roasTotal: 5.0,
          tacosTotal: 6.67,
          unitsSold: 300,
          conversionRate: 0.04,
          clicks: 3000,
          impressions: 50000,
          clickThroughRate: 0.06,
          avgCostPerClick: {
            amount: '0.33',
            currency: 'USD',
          },
          adSalesDirect: {
            amount: '2000.00',
            currency: 'USD',
          },
          roasDirect: 2.0,
          acosDirect: 0.5,
          adSpendPercentage: 20.0,
        },
        previous: {
          adConversions: 120,
          totalSales: {
            amount: '12500.00',
            currency: 'USD',
          },
          adSalesTotal: {
            amount: '6800.00',
            currency: 'USD',
          },
          adSpend: {
            amount: '875.00',
            currency: 'USD',
          },
          roasTotal: 4.0,
          tacosTotal: 6.37,
          unitsSold: 380,
          conversionRate: 0.4,
          clicks: 2500,
          impressions: 12500,
          clickThroughRate: 0.36,
          avgCostPerClick: {
            amount: '0.28',
            currency: 'USD',
          },
          adSalesDirect: {
            amount: '2100.00',
            currency: 'USD',
          },
          roasDirect: 1.0,
          acosDirect: 0.75,
          adSpendPercentage: 23.0,
        },
      },
      selectedMerchantCountries: [
        {
          merchantCountryId: '92faa498-eccd-4c59-999f-9fec646a8967',
          merchantCountryName: 'Merchant country 1',
          merchantType: MerchantType.Seller,
          salesChannelId: AMAZON_SALES_CHANNEL_ID,
          salesChannelName: 'Amazon',
          country: 'USD',
        },
      ],
      showPreviousData: false,
      isTableDataLoading: false,
    },
    {
      salesChannelId: AMAZON_SALES_CHANNEL_ID,
      merchantCountries: [],
      performance: {
        current: {},
        previous: {},
      },
      selectedMerchantCountries: [],
      showPreviousData: false,
      isTableDataLoading: false,
    },
  ],
  filteredElements: 2,
}));
