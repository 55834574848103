import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputState,
  Placement,
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import {
  makeCurrencyColumn,
  makeNumericInputColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  BudgetType,
  CampaignDetails,
  CampaignStatus,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import {
  CURRENCY_CODE,
  NumericValueConfig,
  TableDataAdsManager,
} from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
} from '../utils';
import {
  getCurrencyCodeFromMerchantCountryCode,
  getCurrencySymbol,
  getCurrencySymbolFromMerchantCountryCode,
} from '../../../../../lib/utilities/currency';

const getTotalBudgetTooltipForWalmart = (
  intl: IntlShape,
  isLessThanMinLimit: boolean,
  totalBudgetConfig: NumericValueConfig,
  currencySymbol: string,
  isDailyBudgetGreaterThanTotalBudget: boolean
) => {
  if (isLessThanMinLimit && totalBudgetConfig.min) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          { id: I18nKey.ADS_MANAGER_INVALID_NUMERIC_ATLEAST },
          {
            currency: currencySymbol,
            minValue: intl.formatNumber(totalBudgetConfig.min, {
              minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              maximumFractionDigits: MONETARY_FRACTION_DIGITS,
            }),
          }
        )}
      </p>
    );
  } else if (isDailyBudgetGreaterThanTotalBudget) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_LIFETIME_BUDGET_LESSER_THAN_DAILY_BUDGET,
        })}
      </p>
    );
  } else {
    return;
  }
};

const getTotalBudgetTooltipForAmazon = (
  intl: IntlShape,
  isLessThanMinLimit: boolean,
  isGreaterThanMaxLimit: boolean,
  totalBudgetConfig: NumericValueConfig,
  currencySymbol: string,
  isDailyBudgetGreaterThanTotalBudget: boolean
) => {
  if (
    (isLessThanMinLimit || isGreaterThanMaxLimit) &&
    totalBudgetConfig.min &&
    totalBudgetConfig.max
  ) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          { id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_LIFETIME_BUDGET },
          {
            minValue: intl.formatNumber(totalBudgetConfig.min, {
              minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              maximumFractionDigits: MONETARY_FRACTION_DIGITS,
            }),
            maxValue: intl.formatNumber(totalBudgetConfig.max, {
              minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              maximumFractionDigits: MONETARY_FRACTION_DIGITS,
            }),
            currencySymbol,
          }
        )}
      </p>
    );
  } else if (isDailyBudgetGreaterThanTotalBudget) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_LIFETIME_BUDGET_LESSER_THAN_DAILY_BUDGET,
        })}
      </p>
    );
  } else {
    return;
  }
};

const getTooltipContentBySalesChannel = (
  intl: IntlShape,
  flywheelSalesChannel: FlywheelSalesChannel,
  isLessThanMinLimit: boolean,
  isDailyBudgetGreaterThanTotalBudget: boolean,
  isGreaterThanMaxLimit: boolean,
  currencySymbol: string,
  totalBudgetConfig: NumericValueConfig
) => {
  switch (flywheelSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      return getTotalBudgetTooltipForWalmart(
        intl,
        isLessThanMinLimit,
        totalBudgetConfig,
        currencySymbol,
        isDailyBudgetGreaterThanTotalBudget
      );
    case FlywheelSalesChannel.Amazon:
      return getTotalBudgetTooltipForAmazon(
        intl,
        isLessThanMinLimit,
        isGreaterThanMaxLimit,
        totalBudgetConfig,
        currencySymbol,
        isDailyBudgetGreaterThanTotalBudget
      );
  }
};

export const getControlledTooltipState = (
  focussed: boolean,
  state: NumericInputState
) =>
  focussed && state === NumericInputState.Error
    ? ControlledTooltip.Show
    : ControlledTooltip.Hide;

export const getTotalBudgetValue =
  (currencyCode: string | null) =>
  ({ channelSettings: { totalBudget, budgetType: type } }: CampaignDetails) => {
    if (
      type === BudgetType.Total ||
      type === BudgetType.Lifetime ||
      type === BudgetType.Both
    ) {
      return (
        totalBudget && {
          amount: Number(totalBudget.amount),
          currency: currencyCode || totalBudget?.currency || CURRENCY_CODE,
        }
      );
    }
  };

export const getDailyBudget = (
  campaignDetails: CampaignDetails,
  dailyBudgetFromStore?: string
) =>
  dailyBudgetFromStore ??
  campaignDetails.channelSettings.dailyBudget?.amount ??
  '';

export const getTotalBudget = (
  campaignDetails: CampaignDetails,
  changedValue?: string
) => {
  const existingValue =
    campaignDetails.channelSettings.totalBudget?.amount?.toString() || '';
  return changedValue ?? existingValue ?? '';
};

export const shouldSetValue = (
  isEditMode: boolean,
  changedValue: string,
  value: string,
  totalBudget: string
) => isEditMode || (!isNil(changedValue) && value !== totalBudget);

export const isDirty = (value: string, existingValue: string) =>
  existingValue || value ? Number(existingValue) !== Number(value) : false;

const getPlaceholder = (
  totalBudgetConfig: NumericValueConfig,
  intl: IntlShape
) =>
  totalBudgetConfig.default
    ? intl.formatNumber(totalBudgetConfig.default, {
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      })
    : '';

const getInputState = (
  isInputInvalid: boolean,
  campaignStatus?: CampaignStatus
) => {
  if (isInputInvalid) {
    return NumericInputState.Error;
  }

  if (isCampaignStatusArchived(campaignStatus)) {
    return NumericInputState.Disabled;
  }
  return NumericInputState.Default;
};

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({
  isEditMode,
  selectedAdType,
  salesChannel,
  merchantCountry,
  allMerchants,
  ...campaignDetails
}) => {
  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.TotalBudget
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const dailyBudgetFromStore = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.DailyBudget
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );
  const bidConstraintsData = useBidConstraintsContext();
  let dailyBudget = getDailyBudget(campaignDetails, dailyBudgetFromStore);

  let budgetType = campaignDetails.channelSettings.budgetType;

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );
  const existingValue =
    campaignDetails.channelSettings.totalBudget?.amount?.toString() || '';

  let totalBudget = getTotalBudget(campaignDetails, changedValue);

  const [value, setValue] = useState<string>(totalBudget);
  const [isInputEdited, setIsInputEdited] = useState<boolean>(false);

  const onTotalBudgetAndPageChange = () => {
    if (shouldSetValue(isEditMode, changedValue, value, totalBudget)) {
      setValue(totalBudget);
    }

    if (value !== totalBudget) {
      setIsInputEdited(true);
    }
  };

  useEffect(onTotalBudgetAndPageChange, [isEditMode, currentPage, totalBudget]);

  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);
  const intl = useIntl();

  const currentMerchantType = allMerchants.find(
    ({ merchantCountryId }) =>
      merchantCountryId === campaignDetails.campaignDetails.merchantCountryId!
  )?.merchantType;

  const totalBudgetConfig = (() => {
    const constraints = getBidConstraint(
      bidConstraintsData.constraints,
      selectedAdType,
      salesChannel,
      CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
      merchantCountry,
      undefined,
      salesChannel === FlywheelSalesChannel.Walmart
        ? currentMerchantType
        : undefined
    );

    const {
      minTotalBudget = 0,
      maxTotalBudget = Infinity,
      defaultTotalBudget = 0,
    } = constraints;

    return {
      min: minTotalBudget,
      max: maxTotalBudget,
      default: defaultTotalBudget,
    };
  })();

  const merchantCurrency =
    getCurrencySymbolFromMerchantCountryCode(merchantCountry);

  const currencySymbol = getCurrencySymbol(
    merchantCurrency ||
      campaignDetails.channelSettings?.dailyBudget?.currency ||
      CURRENCY_CODE
  );

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  const updateCellValue = (inputValue: string) => {
    setIsInputEdited(Number(inputValue) !== Number(existingValue));
    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
        rowId: campaignDetails.campaignId,
        existingValue,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        value: inputValue,
        numericValue: true,
      })
    );
  };

  const debounceCellValueUpdate = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignDetails.campaignId]
  );

  const onInputBlur = () => {
    if (!isEmpty(value)) {
      const formattedValue = parseFloat(value).toFixed(
        MONETARY_FRACTION_DIGITS
      );
      setValue(formattedValue);
      updateCellValue(formattedValue);
    }
    setFocussed(false);
  };

  const onInputFocus = () => setFocussed(true);

  const isLessThanMinLimit: boolean =
    isEmpty(value) ||
    isCurrentValueLessThanRequiredMinValue(totalBudgetConfig.min, value);

  const isBudgetTypeBoth = budgetType === BudgetType.Both;

  const isTotalBudgetLessThanDailyBudget =
    checkIfTotalBudgetIsLessThanDailyBudget(
      salesChannel,
      isBudgetTypeBoth,
      dailyBudget,
      value
    );

  const isNewTotalBudgetInValid = checkIfTotalBudgetIsInvalid(
    salesChannel,
    value,
    totalBudgetConfig.max
  );

  const isInputInvalid: boolean =
    isInputEdited &&
    (isTotalBudgetLessThanDailyBudget ||
      isLessThanMinLimit ||
      isTotalBudgetInValid(
        salesChannel,
        value,
        totalBudgetConfig.min,
        totalBudgetConfig.max
      ));

  const state = getInputState(
    isInputInvalid,
    campaignDetails.channelSettings.status
  );

  if (
    isColumnEditable(
      salesChannel,
      isEditMode,
      campaignDetails.channelSettings.budgetType
    )
  ) {
    return makeNumericInputColumn<CampaignDetails>(() => ({
      dataTestId: CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
      value,
      placeholder: getPlaceholder(totalBudgetConfig, intl),
      tooltipContent: getTooltipContentBySalesChannel(
        intl,
        salesChannel,
        isLessThanMinLimit,
        isTotalBudgetLessThanDailyBudget,
        isNewTotalBudgetInValid,
        currencySymbol,
        totalBudgetConfig
      ),
      controlledTooltip: getControlledTooltipState(focussed, state),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus,
      onInputBlur,
      acceptOnlyPositiveNumbers: true,
      isDirty: isDirty(value, existingValue),
      onChange: (inputValue: string) => {
        setValue(inputValue);
        debounceCellValueUpdate(inputValue);
      },
      prependedElement: currencySymbol,
      state,
      minFractionDigits: MONETARY_FRACTION_DIGITS,
      maxFractionDigits: MONETARY_FRACTION_DIGITS,
    }))(campaignDetails);
  }

  return makeCurrencyColumn<CampaignDetails>(
    getTotalBudgetValue(currencyCode),
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.TotalBudget
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const totalBudgetColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_LIFETIME_BUDGET,
  RowCellElement,
  gridColumnWidth: '160px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const totalBudgetFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_LIFETIME_BUDGET,
    currency,
    isValidNumber(0)
  );

function isColumnEditable(
  salesChannel: FlywheelSalesChannel,
  isEditMode: boolean,
  budgetType?: string
): boolean {
  switch (salesChannel) {
    case FlywheelSalesChannel.Walmart:
      return (
        isEditMode &&
        (budgetType === BudgetType.Total || budgetType === BudgetType.Both)
      );
    case FlywheelSalesChannel.Amazon:
    default:
      return (
        isEditMode &&
        (budgetType === BudgetType.Total || budgetType === BudgetType.Lifetime)
      );
  }
}
export function isTotalBudgetInValid(
  salesChannel: FlywheelSalesChannel,
  totalBudget: string,
  minTotalBudget?: number,
  maxTotalBudget?: number
) {
  const isLessThanMinValue = isCurrentValueLessThanRequiredMinValue(
    minTotalBudget,
    totalBudget
  );
  const isGreaterThanMaxValue = checkIfTotalBudgetIsInvalid(
    salesChannel,
    totalBudget,
    maxTotalBudget
  );
  return isLessThanMinValue || isGreaterThanMaxValue;
}

function checkIfTotalBudgetIsLessThanDailyBudget(
  salesChannel: FlywheelSalesChannel,
  isBudgetTypeBoth: boolean,
  dailyBudget: string,
  totalBudget: string
): boolean {
  switch (salesChannel) {
    case FlywheelSalesChannel.Walmart:
      return isBudgetTypeBoth && Number(totalBudget) < Number(dailyBudget);
    case FlywheelSalesChannel.Amazon:
    default:
      return Number(totalBudget) < Number(dailyBudget);
  }
}

function checkIfTotalBudgetIsInvalid(
  salesChannel: FlywheelSalesChannel,
  totalBudget: string,
  maxTotalBudget?: number
): boolean {
  switch (salesChannel) {
    case FlywheelSalesChannel.Walmart:
      return false;
    case FlywheelSalesChannel.Amazon:
    default:
      return isCurrentValueGreaterThanRequiredMaxValue(
        maxTotalBudget,
        totalBudget
      );
  }
}
