import { Factory } from 'fishery';

import { SALES_CHANNEL_IDS } from '../';
import { AllSalesChannel } from '../../types/Fam';

interface AllSalesChannelTransientParam {
  readonly channelId?: string;
}

export default Factory.define<AllSalesChannel, AllSalesChannelTransientParam>(
  ({ transientParams }) => {
    const { channelId } = transientParams;
    const createdAt = '2021-03-09T15:57:20.328901+00:00';
    if (channelId) {
      if (channelId === SALES_CHANNEL_IDS.AMAZON) {
        return {
          createdAt: createdAt,
          id: SALES_CHANNEL_IDS.AMAZON,
          name: 'amazon',
        };
      } else {
        return {
          createdAt: createdAt,
          id: SALES_CHANNEL_IDS.WALMART,
          name: 'walmart',
        };
      }
    }
    return {
      createdAt: createdAt,
      id: '820d060b-1d53-4aab-99c2-03a12e3dcf29',
      name: 'salechannel-name',
    };
  }
);
