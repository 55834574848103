//https://stackoverflow.com/questions/12709074/how-do-you-explicitly-set-a-new-property-on-window-in-typescript

declare global {
  interface Window {
    analytics: {
      page: () => void;
      // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
      identify: (userIdOrTraits: string | object, traits?: object) => void;
      track: (event: string, properties?: object) => void;
      reset: () => void;
      setAnonymousId: (anonymousId: string) => void;
    };
  }
}

export const segmentIdentify = (
  userId: string | undefined,
  email: string,
  traits: object
) => {
  if (userId) {
    window.analytics.identify(userId, { email, ...traits });
  } else {
    window.analytics.identify({ email, ...traits });
  }
  window.analytics.page();
  // To trigger the hubspot update:
  // From their docs:
  // This function call stores the data in the tracker, but the data is not actually passed to HubSpot with this call.
  // The data will only be passed when tracking a page view or an event (with either the trackPageView or trackEvent functions).
};

export const segmentTrack = (event: string, properties?: object) => {
  window.analytics.track(event, properties);
};

/**
 * Clears the cookies and localStorage created by Segment
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-logout
 */
export const segmentReset = () => {
  window.analytics.reset();
};

/**
 * Allows the segment anonymousId to be set manually. we can track users by their Auth0 user_id
 * @param anonymousId
 */
export const segmentSetAnonymousId = (anonymousId: string) => {
  window.analytics.setAnonymousId(anonymousId);
};
