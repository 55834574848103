import {
  CampaignGoalGrowIcon,
  CampaignGoalLiquidateIcon,
  CampaignGoalProductLaunchIcon,
  CampaignGoalProfitIcon,
  CampaignGoalROASIcon,
  IconPlacement,
  QuestionCircleLinearIcon,
  SelectV3Controlled,
  SelectV3Visibility,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextWithIconColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdvertisingGoal,
  CampaignDetails,
  EntityType,
  MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY,
  MAP_ADVERTISING_GOAL_TO_ICON,
  MAP_ADVERTISING_GOAL_TO_II8NKEY,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CAMPAIGNS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { useIntl } from 'react-intl';
import { COGSUploadStatus, TableDataAdsManager } from '../types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import {
  ADS_MANAGER_CAMPAIGNS_TABLE_ID,
  EST_AD_GROSS_MARGIN_TABLE_ADS_ID,
} from '../ducks/types';
import { WithTable } from '../../../../../containers/table/ducks/types';
import EstAdGrossMarginSlideover from '../../../../../components/EstAdGrossMarginSlideover/EstAdGrossMarginSlideover';
import isArray from 'lodash/isArray';
import first from 'lodash/first';
import { SMART_CAMPAIGN_LEARN_MORE_INTERCOM_LINK } from '../../../../../lib/utilities/commonConsts';

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({
  selectedMerchantCountries,
  allMerchants,
  isEditMode,
  aoApiClient,
  invokeMissingCogsApi,
  cogsDetailsDataFetcher,
  ...campaignDetails
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  ) as AdvertisingGoal;

  const existingValue =
    campaignDetails.campaignDetails.advertisingGoal || AdvertisingGoal.External;
  const goal = changedValue ?? existingValue;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cogsStatus, setCogsStatus] = useState<COGSUploadStatus>(
    COGSUploadStatus.Loading
  );

  const mcid = isArray(selectedMerchantCountries)
    ? first(selectedMerchantCountries as string[])
    : selectedMerchantCountries;
  const merchant = allMerchants.find((item) => item.merchantCountryId === mcid);

  const [showMissingCogsSlideover, setShowMissingCogsSlideover] =
    useState<boolean>(false);

  const executeMissingCogsApiAndSetStatus = () => {
    if (invokeMissingCogsApi) {
      const resp = invokeMissingCogsApi([campaignDetails.campaignId]);
      resp?.then((res) => {
        const filteredResp = res.campaignMissingCogs.filter(
          (missingCogsData) =>
            missingCogsData.campaignId === campaignDetails.campaignId
        );
        if (filteredResp.length > 0) {
          filteredResp[0].isMissingCogs
            ? setCogsStatus(COGSUploadStatus.MissingCogs)
            : setCogsStatus(COGSUploadStatus.Success);
        } else {
          setCogsStatus(COGSUploadStatus.MissingCogs);
        }
      });
    }
  };

  useEffect(() => {
    if (isEditMode && isOpen) {
      executeMissingCogsApiAndSetStatus();
    }
  }, [isEditMode, isOpen]);

  if (
    isEditMode &&
    ![AdvertisingGoal.External, AdvertisingGoal.ExternalWithAi].includes(goal)
  ) {
    const updateCellValue = (newValue: AdvertisingGoal) => {
      dispatch(
        tableActions.updateCell({
          columnName: CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal,
          rowId: campaignDetails.campaignId,
          tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
          value: newValue,
          existingValue,
        })
      );
    };

    const onLearnMoreClick = () => {
      window.open(SMART_CAMPAIGN_LEARN_MORE_INTERCOM_LINK, '_blank');
    };

    const productLaunchOption = {
      value: AdvertisingGoal.ProductLaunch,
      label: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY[
          AdvertisingGoal.ProductLaunch
        ],
      }),
      groupName: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.ProductLaunch],
      }),
      optionLeftContent: CampaignGoalProductLaunchIcon,
    };
    const growOption = {
      value: AdvertisingGoal.Grow,
      label: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY[AdvertisingGoal.Grow],
      }),
      groupName: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.Grow],
      }),
      optionLeftContent: CampaignGoalGrowIcon,
    };
    const profitOptionLoading = {
      value: AdvertisingGoal.Profit,
      label: intl.formatMessage({
        id: I18nKey.GENERIC_SCANNING_COGS,
      }),
      groupName: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.Profit],
      }),
      optionLeftContent: CampaignGoalProfitIcon,
      loading: true,
    };
    const profitOptionLoaded = {
      value: AdvertisingGoal.Profit,
      label: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY[AdvertisingGoal.Profit],
      }),
      groupName: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.Profit],
      }),
      optionLeftContent: CampaignGoalProfitIcon,
    };
    const profitOptionMissingCogs = {
      value: AdvertisingGoal.Profit,
      label: intl.formatMessage({ id: I18nKey.GENERIC_MISSING_COGS }),
      groupName: `${intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.Profit],
      })} - ${intl.formatMessage({
        id: I18nKey.GENERIC_NOT_AVAILABLE,
      })}`,
      optionLeftContent: CampaignGoalProfitIcon,
      disabled: true,
      showLabelInErrorPill: true,
      onErrorPillClick: () => {
        setShowMissingCogsSlideover(true);
        executeMissingCogsApiAndSetStatus();
      },
      pillTooltipContent: intl.formatMessage({ id: I18nKey.GENERIC_EDIT_COGS }),
    };
    const roasOption = {
      value: AdvertisingGoal.ROAS,
      label: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY[AdvertisingGoal.ROAS],
      }),
      groupName: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.ROAS],
      }),
      optionLeftContent: CampaignGoalROASIcon,
    };
    const liquidateOption = {
      value: AdvertisingGoal.Liquidate,
      label: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY[
          AdvertisingGoal.Liquidate
        ],
      }),
      groupName: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.Liquidate],
      }),
      optionLeftContent: CampaignGoalLiquidateIcon,
    };
    const getCogsOption = () => {
      if (cogsStatus === COGSUploadStatus.Loading) {
        return profitOptionLoading;
      } else if (cogsStatus === COGSUploadStatus.MissingCogs) {
        return profitOptionMissingCogs;
      }
      return profitOptionLoaded;
    };
    const advertisingGoalDropDownOptions = [
      productLaunchOption,
      growOption,
      getCogsOption(),
      roasOption,
      liquidateOption,
    ];
    const onChange = (value: AdvertisingGoal) => {
      updateCellValue(value);
    };
    return (
      <div className="px-12 py-8 w-full">
        {showMissingCogsSlideover && cogsDetailsDataFetcher && (
          <EstAdGrossMarginSlideover
            tableId={EST_AD_GROSS_MARGIN_TABLE_ADS_ID}
            selectedMerchant={merchant!}
            fromAdsManager={true}
            dataFetcher={cogsDetailsDataFetcher(
              campaignDetails.campaignId,
              EntityType.Campaign
            )}
            closeModal={() => setShowMissingCogsSlideover(false)}
            onSaveSuccess={() => setCogsStatus(COGSUploadStatus.Loading)}
          />
        )}
        <SelectV3Controlled
          leftIcon={MAP_ADVERTISING_GOAL_TO_ICON[goal]}
          onToggle={() => setIsOpen(!isOpen)}
          visibility={
            isOpen ? SelectV3Visibility.Open : SelectV3Visibility.Closed
          }
          value={goal}
          options={advertisingGoalDropDownOptions}
          placeholder={intl.formatMessage({
            id: I18nKey.GENERIC_ADVERTISING_GOAL_COLUMN,
          })}
          onChange={onChange}
          className="rounded"
          menuClassName="rounded border border-solid border-grey-200"
          popperClassName="mx-0 border-0 rounded shadow-md z-1"
          dataTestId="advertising_goal_campaigns"
          learnMoreIcon={QuestionCircleLinearIcon}
          learnMoreText={intl.formatMessage({
            id: I18nKey.GENERIC_LEARN_ABOUT_SMART_GOALS,
          })}
          onLearnMoreClick={onLearnMoreClick}
          isDirty={!!changedValue}
        />
      </div>
    );
  }
  return makeTextWithIconColumn(
    () =>
      intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_II8NKEY[goal],
      }),
    MAP_ADVERTISING_GOAL_TO_ICON[goal],
    '',
    undefined,
    undefined,
    IconPlacement.Left
  )(campaignDetails);
};
RowCellElement.displayName = 'AdvertisingGoal';

export const advertisingGoalColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal,
  i18nKeyOrLabel: I18nKey.GENERIC_ADVERTISING_GOAL_COLUMN,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '200px',
};

export const advertisingGoalFilter = createMultiSelectDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal,
  I18nKey.GENERIC_ADVERTISING_GOAL_COLUMN,
  [
    SelectFilterOption.AdvertisingGoalProductLaunch,
    SelectFilterOption.AdvertisingGoalGrow,
    SelectFilterOption.AdvertisingGoalProfit,
    SelectFilterOption.AdvertisingGoalRoas,
    SelectFilterOption.AdvertisingGoalLiquidate,
  ]
);
