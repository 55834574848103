/* eslint-disable @typescript-eslint/no-unused-vars */
import { flatten, last } from 'fp-ts/lib/Array';
import { fromNullable } from 'fp-ts/lib/Option';
import { Filter, FilterOps } from '../../../lib/types/Filter';
import { exportUrlGenerator } from '../../../lib/utilities/buildUrlUtilities';
import { createDeepEqualSelector } from '../../../lib/utilities/createDeepEqualSelector';
import find from 'lodash/find';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import omit from 'lodash/omit';
import size from 'lodash/size';
import values from 'lodash/values';
import { createSelector } from 'reselect';

import { StringMap } from '../../../lib/types';
import { Sort } from '../../../lib/types/Sort';
import {
  AsyncRequest,
  AsyncRequestKinds,
  asyncRequestIsComplete,
} from '../../../lib/utilities/asyncRequest'; // TODO: Solve type issue
import { DEFAULT_TABLE_STATE } from './index';
import {
  Table,
  TableChange,
  TableDisplayState,
  TableReducerState,
  TableSelectSettings,
  UPDATE_CELL_VALIDATION_DATA_KEY,
  VisiblePageKind,
} from './types';

function allDataIsLoaded<T, F>(table: Table<T, F>): boolean {
  const allResults = getDataForAllPages(table);
  return table.maybeTotalItems === allResults.length;
}

function getDataForAllPages<T, F>(table: Table<T, F>): T[] {
  return flatten(
    table.pages.map((page) => {
      switch (page.kind) {
        case AsyncRequestKinds.NotStarted:
        case AsyncRequestKinds.Failed:
        case AsyncRequestKinds.Loading:
          return [];
        case AsyncRequestKinds.Completed:
          return page.result;
        default:
          return [];
      }
    })
  );
}

function getVisibleData<T, F>(table: Table<T, F>): T[] {
  if (isNumber(table.visiblePage)) {
    return fromNullable(table.pages[table.visiblePage - 1])
      .map((c) => (asyncRequestIsComplete(c) ? c.result : []))
      .getOrElse([]);
  }
  switch (table.visiblePage.kind) {
    case VisiblePageKind.AllPages:
      return getDataForAllPages(table);
    case VisiblePageKind.SinglePage:
      return fromNullable(table.pages[table.visiblePage.page - 1])
        .map((c) => (asyncRequestIsComplete(c) ? c.result : []))
        .getOrElse([]);
  }
}

function getNextPageForPartiallyLoadedTable<T, F>(table: Table<T, F>): number {
  return last(table.pages).fold(1, (currentPage) => {
    switch (currentPage.kind) {
      case AsyncRequestKinds.Completed:
        return table.pages.length + 1;
      case AsyncRequestKinds.Failed:
      case AsyncRequestKinds.Loading:
      case AsyncRequestKinds.NotStarted:
        return table.pages.length;
      default:
        return 0;
    }
  });
}

function getNextPage<T, F>(table: Table<T, F>): number | undefined {
  if (allDataIsLoaded(table)) {
    return undefined;
  } else {
    return getNextPageForPartiallyLoadedTable(table);
  }
}

function getTotalItems<T, F>(table: Table<T, F>) {
  return fromNullable(table.maybeTotalItems);
}

function getTable<T, F>(maybeTable?: Table<T, F>): Table<T, F> {
  return fromNullable(maybeTable).getOrElse(DEFAULT_TABLE_STATE);
}

function getExtraPaginationParams<T, F>(
  table: Table<T, F>
): StringMap<string> | undefined {
  return table.extraPaginationParams;
}

function getTableSorts<T, F>(table: Table<T, F>): Sort[] {
  return table.sorts;
}

function getTableFilters<T, F>(table: Table<T, F>): Filter[] {
  return table.filters;
}

function isFilterBeingApplied<T, F>(
  table: Table<T, F>,
  filterName: string
): boolean {
  return table.filters.some((filter) => filter.field === filterName);
}

function getFilterInTableByName<T, F>(
  table: Table<T, F>,
  filterName: string
): Filter | undefined {
  return table.filters.find((item) => item.field === filterName);
}

function anyFiltersBeingApplied<T, F>(table: Table<T, F>): boolean {
  return table.filters.length > 0;
}

function getTableDisplayState<T, F>(table: Table<T, F>): TableDisplayState {
  return last(table.pages).fold(
    TableDisplayState.DataLoadNotStarted,
    (lastPage: AsyncRequest<T[]>): TableDisplayState => {
      switch (lastPage.kind) {
        case AsyncRequestKinds.NotStarted:
          return TableDisplayState.DataLoadNotStarted;

        case AsyncRequestKinds.Loading:
          const isFirstPage = table.pages[0] === lastPage;
          if (isFirstPage) {
            return TableDisplayState.LoadingInitialData;
          } else {
            return TableDisplayState.LoadingAdditionalData;
          }

        case AsyncRequestKinds.Completed:
          const hasFiltersApplied = table.filters.length > 0;
          const tableIsEmpty = table.maybeTotalItems === 0;

          if (!hasFiltersApplied && tableIsEmpty) {
            return TableDisplayState.NoData;
          } else if (hasFiltersApplied && tableIsEmpty) {
            return TableDisplayState.FilteredWithNoData;
          } else {
            return TableDisplayState.Data;
          }

        case AsyncRequestKinds.Failed:
          return TableDisplayState.Error;
      }
    }
  );
}

function getTableSelector<T, F = void>() {
  return createSelector(
    [
      (tables: TableReducerState<T>, tableId: string): Table<T, F> =>
        tables[tableId],
    ],
    getTable
  );
}

const getPageValueForInputValue = (
  params: Readonly<{ pageInputValue: number | undefined; totalPages: number }>
): number | undefined => {
  const newPage = Math.floor(Number(params.pageInputValue));

  if (params.pageInputValue === undefined || 0) {
    return undefined;
  } else if (newPage > params.totalPages) {
    return params.totalPages;
  } else if (newPage >= 1) {
    return newPage;
  }
};

function shouldAutoPaginate<T, F>(table: Table<T, F>): boolean {
  return last(table.pages).fold(true, (lastPage) => {
    switch (lastPage.kind) {
      case AsyncRequestKinds.NotStarted:
      case AsyncRequestKinds.Completed:
        return true;
      case AsyncRequestKinds.Loading:
      case AsyncRequestKinds.Failed:
        return false;
      default:
        return false;
    }
  });
}

function shouldRetryPaginate<T, F>(table: Table<T, F>): boolean {
  return last(table.pages).fold(false, (lastPage) => {
    switch (lastPage.kind) {
      case AsyncRequestKinds.NotStarted:
      case AsyncRequestKinds.Loading:
        return false;
      case AsyncRequestKinds.Completed:
      case AsyncRequestKinds.Failed:
        return true;
      default:
        return false;
    }
  });
}

function getNextPageSelector<T, F>() {
  return createDeepEqualSelector([getTableSelector()], getNextPage);
}

function generateExportUrl(baseUrl: string) {
  function extractExportInfo<T, F>(table: Table<T, F>) {
    const { extraPaginationParams, filters } = table;
    return exportUrlGenerator(baseUrl, filters, extraPaginationParams);
  }

  return extractExportInfo;
}

function getExportUrlSelector<T, F>(baseUrl: string) {
  return createSelector([getTableSelector()], generateExportUrl(baseUrl));
}

function getTotalItemsSelector<T, F>() {
  return createDeepEqualSelector([getTableSelector()], getTotalItems);
}

function getSortsSelector<T, F>() {
  return createSelector([getTableSelector()], getTableSorts);
}

function getFiltersSelector<T, F>() {
  return createSelector([getTableSelector()], getTableFilters);
}

function getFilterInTableByNameSelector<T, F>() {
  return createSelector(
    [
      getTableSelector(),
      (
        tables: TableReducerState<T, F>,
        tableId: string,
        filterName: string
      ): string => filterName,
    ],
    getFilterInTableByName
  );
}

function getIsFilterAppliedSelector<T, F>() {
  return createSelector(
    [
      getTableSelector(),
      (
        tables: TableReducerState<T, F>,
        tableId: string,
        filterName: string
      ): string => filterName,
    ],
    isFilterBeingApplied
  );
}

function getAnyFiltersAppliedSelector<T, F>() {
  return createSelector([getTableSelector()], anyFiltersBeingApplied);
}

function getTableDisplayStateSelector<T, F>() {
  return createSelector([getTableSelector()], getTableDisplayState);
}

function shouldAutoPaginateSelector<T, F>() {
  return createDeepEqualSelector([getTableSelector()], shouldAutoPaginate);
}

function shouldRetryPaginateSelector<T, F>() {
  return createDeepEqualSelector([getTableSelector()], shouldRetryPaginate);
}

function getDataForAllPagesSelector<T, F>() {
  return createDeepEqualSelector(
    [getTableSelector<T, F>()],
    getDataForAllPages
  );
}

function getExtraPaginationParamsSelector() {
  return createSelector([getTableSelector()], getExtraPaginationParams);
}

function getCell<T, F>(rowId: string, columnName: string) {
  function getValue(table: Table<T, F>): string {
    return get(table.changes.cell, [rowId, columnName]);
  }

  return getValue;
}

function getCellSelector<T, F>(rowId: string, columnName: string) {
  return createDeepEqualSelector(
    [getTableSelector()],
    getCell(rowId, columnName)
  );
}

function getCurrentPageSelector<T, F>() {
  return createDeepEqualSelector([getTableSelector()], getCurrentPage());
}

function getCurrentPage<T, F>() {
  function getValue(table: Table<T, F>): number {
    if (isNumber(table.visiblePage)) {
      return table.visiblePage;
    } else {
      return table.visiblePage.kind === VisiblePageKind.SinglePage
        ? table.visiblePage.page
        : 1;
    }
  }

  return getValue;
}

function getCellChangedValue<T, F>(
  rowId: string,
  columnName: string,
  columnDataMapping: Record<string, string>,
  uniqKey: string
) {
  function getValue(table: Table<T, F>): string {
    const pageData = getVisibleData(table);
    const rowData = find(pageData, (elem: T) => get(elem, uniqKey) === rowId);
    const originalValue = get(rowData, columnDataMapping[columnName]) || '';
    const changedValue = get(table.changes.cell, [rowId, columnName]);
    return changedValue === undefined ? originalValue.toString() : changedValue;
  }

  return getValue;
}

function getCellValueSelector<T, F>(
  rowId: string,
  columnName: string,
  columnDataMapping: Record<string, string>,
  uniqKey: string
) {
  return createDeepEqualSelector(
    [getTableSelector()],
    getCellChangedValue(rowId, columnName, columnDataMapping, uniqKey)
  );
}

function getSelectedRows<T, F>(table: Table<T, F>): string[] {
  return table.changes.select.rows;
}

function getSelectedRowsSelector<T, F>() {
  return createSelector([getTableSelector()], getSelectedRows);
}

function getRowIsSelected<T, F>(rowId: string) {
  function getSelected(table: Table<T, F>): boolean {
    return table.changes.select.rows.includes(rowId);
  }

  return getSelected;
}

function getRowIsSelectedSelector<T, F>(rowId: string) {
  return createDeepEqualSelector([getTableSelector()], getRowIsSelected(rowId));
}

function getTableSelectSettings<T, F>(table: Table<T, F>): TableSelectSettings {
  return table.changes.select;
}

function getSelectInfoSelector<T, F>() {
  return createSelector([getTableSelector()], getTableSelectSettings);
}

function getTableChange<T, F>(table: Table<T, F>): TableChange {
  return table.changes;
}

function getChangeSelector<T, F>() {
  return createSelector([getTableSelector()], getTableChange);
}

function getTableChangedCount<T, F>() {
  function getCount<T, F>(table: Table<T, F>): number {
    return values(table.changes.cell).reduce((t, key) => {
      const newKey = omit(key, [UPDATE_CELL_VALIDATION_DATA_KEY]);
      return t + size(newKey);
    }, 0);
  }

  return getCount;
}

function getChangedCountSelector<T, F>() {
  return createSelector([getTableSelector()], getTableChangedCount());
}

export default {
  getTableSelector,
  getDataForAllPagesSelector,
  getVisibleData,
  getDataForAllPages,
  getNextPageSelector,
  getExportUrlSelector,
  getTotalItemsSelector,
  getSortsSelector,
  getFiltersSelector,
  getFilterInTableByNameSelector,
  getIsFilterAppliedSelector,
  getAnyFiltersAppliedSelector,
  getTableDisplayStateSelector,
  shouldAutoPaginateSelector,
  shouldRetryPaginateSelector,
  getExtraPaginationParamsSelector,
  getPageValueForInputValue,
  getCellValueSelector,
  getSelectedRowsSelector,
  getRowIsSelectedSelector,
  getSelectInfoSelector,
  getChangeSelector,
  getChangedCountSelector,
  getCellSelector,
  getCurrentPageSelector,
};
