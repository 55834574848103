import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { ExitIntentEditModal } from '@teikametrics/tm-design-system';

import { tableActions } from '../../../../containers/table/ducks';
import I18nKey from '../../../../lib/types/I18nKey';

export interface KeepEditingModalProps {
  tableId: string;
  showModal?: boolean;
  onKeepEditing: () => void;
  onDiscard: () => void;
}

export const KeepEditingModal = ({
  tableId,
  showModal = false,
  onKeepEditing,
  onDiscard,
}: KeepEditingModalProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const clearTableChanges = () =>
    dispatch(tableActions.clearAllChanges({ tableId }));

  const handleDiscard = () => {
    clearTableChanges();
    onDiscard();
  };

  return (
    <ExitIntentEditModal
      showModal={showModal}
      onKeepEditing={onKeepEditing}
      onDiscard={handleDiscard}
      header={intl.formatMessage({
        id: I18nKey.AO_EDIT_TABLE_SAVE_CHANGES_HEADER,
      })}
      body={intl.formatMessage({
        id: I18nKey.AO_EDIT_TABLE_SAVE_CHANGES_BODY,
      })}
      buttonPrimaryText={intl.formatMessage({
        id: I18nKey.AO_EDIT_TABLE_SAVE_CHANGES_PRIMARY,
      })}
      buttonSecondaryText={intl.formatMessage({
        id: I18nKey.AO_EDIT_TABLE_SAVE_CHANGES_SECONDARY,
      })}
    />
  );
};
KeepEditingModal.displayName = 'KeepEditingModal';
