import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CheckmarkIcon,
  CrossMiniIcon,
  Icon,
  IconSize,
  TextInput,
  TrashIcon,
} from '@teikametrics/tm-design-system';
import React, { useEffect, useState } from 'react';
import { Brand } from '../../../../../../lib/types/AOSharedTypes';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../../../lib/types/I18nKey';

export interface BrandNameProps {
  readonly brand: Brand;
  readonly isLoading: boolean;
  readonly onBrandDelete: (brand: Brand) => void;
  readonly onBrandUpdate: (brandId: string, brandName: string) => void;
}
export const BrandName: React.FC<BrandNameProps> = ({
  brand,
  onBrandDelete,
  onBrandUpdate,
  isLoading,
}) => {
  const intl = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(brand.brandName);

  useEffect(() => {
    if (brand.brandName !== value) {
      setValue(brand.brandName);
    }
  }, [brand.brandName]);

  const handleBrandUpdate = (brandName: string) => {
    onBrandUpdate(brand.brandId, brandName);
    setIsEditing(false);
  };

  return (
    <div className="flex flex-row items-center gap-4">
      {isEditing && (
        <>
          <TextInput
            className="h-32"
            value={value}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleBrandUpdate(value);
              }
            }}
            placeholder={
              intl.formatMessage(
                {
                  id: I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_ENTER_BRAND_TOOLTIP,
                },
                {
                  br: <br />,
                }
              ) as string
            }
            onChange={(newValue) => setValue(newValue)}
          />
          <Button
            state={!value ? ButtonState.Disabled : ButtonState.Enabled}
            size={ButtonSize.Medium}
            variant={ButtonVariant.Primary}
            svgIcon={CheckmarkIcon}
            iconClassName="text-white"
            onClick={() => handleBrandUpdate(value)}
          />
          <Button
            size={ButtonSize.Medium}
            variant={ButtonVariant.BlackAndWhiteBorder}
            svgIcon={CrossMiniIcon}
            onClick={() => onBrandDelete(brand)}
            iconClassName="text-grey-600"
          />
        </>
      )}
      {!isEditing && (
        <div className="h-20 flex flex-row items-center gap-8">
          <span className="text-base text-grey-900 leading-none font-normal tabular-nums">
            {brand.brandName}
          </span>
          <Icon
            size={IconSize.Small}
            svg={TrashIcon}
            className="text-grey-500 cursor-pointer"
            onClick={() => !isLoading && onBrandDelete(brand)}
          />
        </div>
      )}
    </div>
  );
};
