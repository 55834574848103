import { DateTime } from 'luxon';

import { createMoneyDataFieldFilter } from '@teikametrics/tm-design-system';

import isNil from 'lodash/isNil';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  ProductAdDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { getPerformanceMetricValue } from '../utils';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) =>
  makeCurrencyColumn<ProductAdDetails>(
    ({ adItemPerformance: { adSpend }, adItemDetails: { startDate } }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        adSpend?.amount ? Number(adSpend.amount) : undefined
      );

      return isNil(performanceMetricValue)
        ? undefined
        : {
            amount: performanceMetricValue,
            currency:
              getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
              adSpend?.currency ||
              CURRENCY_CODE,
          };
    }
  )(props);
RowCellElement.displayName = 'AdSpendColumn';

export const adSpend: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: SV_ADS_API_COLUMN_NAME.AdSpend,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_AD_SPEND,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const adSpendFilter = (currency?: string) =>
  createMoneyDataFieldFilter(
    SV_ADS_API_COLUMN_NAME.AdSpend,
    I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_AD_SPEND,
    currency ?? CURRENCY_CODE
  );
