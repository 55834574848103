import './styles.scss';

import classNames from 'classnames';
import { fromNullable } from 'fp-ts/lib/Option';
import noop from 'lodash/noop';
import React, { CSSProperties, PropsWithChildren, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { FixedSizeList as List } from 'react-window';

import I18nKey from '../../../../lib/types/I18nKey';
import { Button } from '../button';
import { ChevronDownIcon, SearchIcon } from '@teikametrics/tm-design-system';
import { isValueTypeSelectOption } from '../../../../lib/utilities/reactSelect';
export enum IconPosition {
  Left = 'Left',
  Right = 'Right',
}

export interface CustomSelectItemProps {
  readonly label: string | JSX.Element;
  readonly value: string | number;
  readonly icon?: JSX.Element;
  readonly isDisabled?: boolean;
  readonly className?: string;
  readonly dataLabelWithIconClassName?: string;
  readonly singleDataItemClassName?: string;
  readonly menuItemRightHandComponent?: JSX.Element | false;
  readonly optionLabelClassName?: string;
}

export interface SearchBoxProps {
  readonly placeholderKey: I18nKey;
  readonly value?: string;
  readonly className?: string;
  readonly iconClassName?: string;
  readonly iconPosition: IconPosition;
}

export interface CustomSelectProps {
  readonly options: CustomSelectItemProps[];
  readonly initialValue?: CustomSelectItemProps;
  readonly className?: string;
  readonly searchBox?: SearchBoxProps;
  readonly placeholderKey?: I18nKey;
  readonly onValueChange: (o: CustomSelectItemProps | undefined) => void;
  readonly isSearchable?: boolean;
  readonly isDropDownDisabled?: boolean;
  readonly dataTestId?: string;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  initialValue,
  className = '',
  searchBox,
  placeholderKey,
  onValueChange,
  isSearchable = false,
  isDropDownDisabled = false,
  dataTestId,
}) => {
  const [selectedValue, setSelectedValue] = useState<
    CustomSelectItemProps | undefined
  >(initialValue);
  const [isDropdownOpen, setDropdownState] = useState<boolean>(false);
  enum IconStateEnum {
    Disabled = 'Disabled',
    Enabled = 'Enabled',
  }

  const searchClassForIcon = searchBox
    ? 'custom-select__select__option-label-icon-search'
    : '';

  const getDataLabelWithIconClass = (
    option: CustomSelectItemProps,
    isOnMenu: boolean
  ): string =>
    `${addUserClassName(option.className ? option.className : '', [
      `${searchClassForIcon}`,
    ])} ${
      isOnMenu && option.dataLabelWithIconClassName
        ? option.dataLabelWithIconClassName
        : ''
    }`;

  const getSingleDataItemClass = (
    option: CustomSelectItemProps,
    isOnMenu: boolean
  ): string =>
    `custom-select__select__option-label ${
      isOnMenu && option.singleDataItemClassName
        ? option.singleDataItemClassName
        : ''
    }`;

  const getOptionLabelClass = (option: CustomSelectItemProps): string =>
    `custom-select__select__option-label ${
      option.optionLabelClassName ? option.optionLabelClassName : ''
    }`;

  const generateDataLabelWithIcon = (
    option: CustomSelectItemProps,
    isOnMenu: boolean
  ) => {
    const iconState =
      option && (option.isDisabled || isDropDownDisabled)
        ? IconStateEnum.Disabled
        : IconStateEnum.Enabled;

    return (
      <div className="flex">
        <div className={getDataLabelWithIconClass(option, isOnMenu)}>
          <div
            className={classNames(
              'custom-select__select__option__icon',
              `custom-select__select__option__icon-${option.value}-${iconState}`
            )}
          >
            {option.icon}
          </div>
          <div className={getOptionLabelClass(option)}>{option.label}</div>
        </div>
        {isOnMenu && option.menuItemRightHandComponent}
      </div>
    );
  };

  const generateSingleDataItem = (
    option: CustomSelectItemProps,
    isOnMenu: boolean
  ): CustomSelectItemProps => {
    return {
      ...option,
      label: option.icon ? (
        generateDataLabelWithIcon(option, isOnMenu)
      ) : (
        <div className={getSingleDataItemClass(option, isOnMenu)}>
          {option.label}
        </div>
      ),
    };
  };

  const generateDataItems = (): CustomSelectItemProps[] => {
    return options.map((option) => generateSingleDataItem(option, true));
  };

  React.useEffect(() => {
    if (initialValue) {
      setSelectedValue(generateSingleDataItem(initialValue, false));
    }
  }, [initialValue]);

  const selectOnChangeHandler = (option: ValueType<CustomSelectItemProps>) => {
    const maybeSelectedValue = fromNullable(option)
      .filter(isValueTypeSelectOption)
      .map((o) => o)
      .toUndefined();
    setSelectedValue(maybeSelectedValue);
    toggleOpen();
    onValueChange(maybeSelectedValue);
  };

  const addUserClassName = (
    customClassName: string,
    defaultClasses: string[]
  ): string => {
    return customClassName
      ? classNames(defaultClasses.join(), customClassName)
      : defaultClasses.join();
  };

  const isOptionDisabled = (option: CustomSelectItemProps): boolean => {
    return (option && option.isDisabled) || false;
  };

  const toggleOpen = () => setDropdownState(!isDropdownOpen);

  const getSearchIconPosition = () => {
    return searchBox && searchBox.iconPosition;
  };

  const DropdownIndicator = () => (
    <div
      className={`custom-select-indicator custom-select-indicator--search-${getSearchIconPosition()}`}
    >
      <SearchIcon className="custom-select-indicator--search" />
    </div>
  );

  const MenuList = (props: { children: any; maxHeight: number }) => {
    const { children, maxHeight } = props;
    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={35}
        width="inherit"
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  const drawSelectForSearch = (iconPosition?: string) => {
    const customStyles = {
      input: (styles: CSSProperties) => ({
        ...styles,
        [`padding${iconPosition}`]: 18,
      }),
    };

    return (
      <Select
        autoFocus={true}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen={true}
        tabSelectsValue={false}
        placeholder={getPlaceHolderText(placeholderKey)}
        className="teika-select"
        classNamePrefix="teika-select"
        components={{ DropdownIndicator, IndicatorSeparator: null, MenuList }}
        options={generateDataItems()}
        value={selectedValue}
        onChange={selectOnChangeHandler}
        isOptionDisabled={isOptionDisabled}
        styles={customStyles}
        onBlur={toggleOpen}
        dataTestId={`${dataTestId}_customSelect`}
      />
    );
  };

  const drawSelectWithSearch = () => {
    const iconPosition = getSearchIconPosition();
    const onClick = isDropDownDisabled ? noop : toggleOpen;
    return (
      <Dropdown
        isDropdownOpen={isDropdownOpen}
        toggleOpen={toggleOpen}
        position={iconPosition}
        target={
          <Button
            className={classNames(
              className,
              'custom-select__search-togglebtn',
              {
                'custom-select__search-togglebtn--open': isDropdownOpen,
                'custom-select__search-togglebtn--disabled': isDropDownDisabled,
              }
            )}
            onClick={onClick}
            dataTestId={`${dataTestId}_customSelect`}
          >
            {selectedValue ? (
              selectedValue.label
            ) : (
              <>{getPlaceHolderText(searchBox && searchBox.placeholderKey)}</>
            )}
            <ChevronDownIcon className="custom-select__search-togglebtn__chevron-down" />
          </Button>
        }
      >
        {drawSelectForSearch(iconPosition)}
      </Dropdown>
    );
  };

  return (
    <div
      className={classNames('custom-select', {
        'custom-select--disabled': isDropDownDisabled,
      })}
    >
      {searchBox ? (
        drawSelectWithSearch()
      ) : (
        <Select
          isSearchable={isSearchable}
          placeholder={getPlaceHolderText(placeholderKey)}
          className={addUserClassName('teika-select', [className])}
          classNamePrefix="teika-select"
          options={generateDataItems()}
          value={selectedValue}
          onChange={selectOnChangeHandler}
          isOptionDisabled={isOptionDisabled}
          isDisabled={isDropDownDisabled}
          dataTestId={dataTestId}
        />
      )}
    </div>
  );
};
CustomSelect.displayName = 'CustomSelect';

interface MenuProps {
  readonly iconposition?: string;
}

const Menu: React.FC<MenuProps & PropsWithChildren> = (props: MenuProps) => (
  <div
    className={classNames(
      'custom-select__menu',
      `custom-select_menu_search-${props.iconposition}`
    )}
    {...props}
  />
);
Menu.displayName = 'Menu';

interface BlanketProps {
  readonly onClick: () => void;
}
const Blanket: React.FC<BlanketProps> = (props: BlanketProps) => (
  <div className="custom-select__blanket" onClick={props.onClick}>
    <div className="custom-select__blanket-child" />
  </div>
);
Blanket.displayName = 'Blanket';

interface SearchDDProps {
  readonly isDropdownOpen: boolean;
  readonly toggleOpen: () => void;
  readonly target: JSX.Element;
  readonly children: React.ReactNode;
  readonly position?: string;
}

const Dropdown: React.FC<SearchDDProps> = (props: SearchDDProps) => (
  <>
    {props.target}
    {props.isDropdownOpen && (
      <Menu iconposition={props.position}>{props.children}</Menu>
    )}
    {props.isDropdownOpen && <Blanket onClick={props.toggleOpen} />}
  </>
);
Dropdown.displayName = 'Dropdown';

const getPlaceHolderText = (
  mayBeI18nPlaceholderKey?: I18nKey
): string | undefined => {
  return fromNullable(mayBeI18nPlaceholderKey)
    .map((i18nPlaceholderKey) =>
      useIntl().formatMessage({ id: i18nPlaceholderKey })
    )
    .toUndefined();
};
