import { BadgeType } from '@teikametrics/tm-design-system';
import { TargetSegments } from '../../../../../lib/types/KeywordAction';
import { MatchType } from '../../../../../lib/types/CampaignFlexibilitySharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { KeywordType } from '../createKeywords/createKeywordsMenu';
import { AdType } from '../../../../../lib/types/AOSharedTypes';

export const getTargetSegmentPills = (targetSegments?: TargetSegments[]) =>
  targetSegments
    ? targetSegments
        .map((targetSegment, index) => ({
          badgeType: TARGET_SEGMENT_KEY_TO_BADGE_TYPE_MAPPER[targetSegment],
          dataTestId: `kwa_createInAdgroup_targetSegment_${index}`,
          className: 'mr-4',
        }))
        .sort((a, b) => (a.badgeType < b.badgeType ? -1 : 1))
    : [];

export const TARGET_SEGMENT_KEY_TO_BADGE_TYPE_MAPPER: Record<
  TargetSegments,
  BadgeType
> = {
  [TargetSegments.Auto]: BadgeType.TagAutomatic,
  [TargetSegments.Brand]: BadgeType.TagBrand,
  [TargetSegments.Competitor]: BadgeType.TagCompetitor,
  [TargetSegments.Generic]: BadgeType.TagGeneric,
  [TargetSegments.Unknown]: BadgeType.TagGeneric,
  [TargetSegments.Category]: BadgeType.TagCategory,
};

export const MATCH_TYPE_I18N_MAPPER: Record<MatchType, string> = {
  [MatchType.Exact]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXACT,
  [MatchType.Broad]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BROAD,
  [MatchType.Phrase]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PHRASE,
  [MatchType.NegativeExact]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_EXACT,
  [MatchType.NegativePhrase]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_PHRASE,
  [MatchType.Expanded]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXPANDED,
};

export const getDefaultBrandTags = (
  keywordType: KeywordType,
  adType?: AdType
) => {
  if (keywordType === KeywordType.Product) {
    if (adType === AdType.SponsoredBrands) {
      return [MatchType.Exact];
    }

    return [MatchType.Expanded];
  }

  if (
    keywordType === KeywordType.Contextual ||
    keywordType === KeywordType.ChatGPT
  ) {
    return [MatchType.Exact];
  }

  if (keywordType === KeywordType.NegativeKeyword) {
    return [MatchType.NegativeExact];
  }
  return [MatchType.Broad, MatchType.Exact, MatchType.Phrase];
};

export const brandTagsSortingOrder = {
  [MatchType.Exact]: 0,
  [MatchType.Phrase]: 1,
  [MatchType.Broad]: 2,
  [MatchType.NegativeExact]: 0,
  [MatchType.NegativePhrase]: 1,
  [MatchType.Expanded]: 1,
};
