import { reducer } from './reducers';
import { FullCountLoadingState, INITIAL_LOAD_KEY, TableChange } from './types';
import { asyncRequestIdentifiedBy } from '../../../lib/utilities/asyncRequest';

export { default as tableActions } from './actions';
export { default as tableSelectors } from './selectors';
export { default as tableThunks } from './thunks';

export const DEFAULT_ITEMS_PER_PAGE = 100;
export const DEFAULT_VISIBLE_PAGE = 1;
const INITIAL_DEFAULT_ASYNC_STATE = asyncRequestIdentifiedBy<any, any>(
  INITIAL_LOAD_KEY
).AsyncRequestNotStarted();

export const DEFAULT_TABLE_CHANGES: TableChange = {
  cell: {},
};

export const DEFAULT_TABLE_STATE = {
  ...Object.freeze({
    sorts: [],
    filters: [],
    pages: [],
    aggregationData: INITIAL_DEFAULT_ASYNC_STATE,
    additionalAggregationData: INITIAL_DEFAULT_ASYNC_STATE,
    visiblePage: DEFAULT_VISIBLE_PAGE,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    changes: DEFAULT_TABLE_CHANGES,
    totalCount: {
      fullCount: 0,
      loadingTotalCount: false,
      loadingState: FullCountLoadingState.NotStarted,
    },
    missingRequiredData: [],
    bufferItems: [],
    bufferItemsToLoad: 0,
    toggleBufferItemsChanged: false,
  }),
};

export default reducer;
