import {
  AlertCircleIcon,
  Icon,
  IconSize,
  LightningIcon,
  PaletteColor,
  Pill,
  PillSize,
  SparkleIcon,
  Spinner,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
  UserIcon,
} from '@teikametrics/tm-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  KeywordsTabProps,
  MAP_TARGET_SEGMENT_TO_I18NKEYS,
  TargetsData,
} from './types';
import classNames from 'classnames';
import I18nKey from '../../lib/types/I18nKey';
import { AddedBy } from '../../lib/types/AOSharedTypes';
import { TargetSegment } from '../../lib/types/KeywordAction';

export const KeywordsTab: React.FC<KeywordsTabProps> = ({
  keywordsData,
  loadingKeywords,
}) => {
  const intl = useIntl();

  const filteredNoTagData = keywordsData.filter((t) => !t.tagType);

  const getTaggedKeywordList = (target: TargetsData) => {
    const getCreatedByIcon = () => {
      return target.createdBy === AddedBy.FlywheelAutomated
        ? LightningIcon
        : target.createdBy === AddedBy.FlywheelAccepted
        ? UserIcon
        : SparkleIcon;
    };

    return (
      <div className="flex justify-between ">
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.tight}
          weight={TypographyWeight.regular}
          className={'text-grey-900'}
          key={target.targetId}
        >
          <Icon
            size={IconSize.Small}
            svg={getCreatedByIcon()}
            className="text-grey-500 mb-2 mr-8"
          />
          {target.searchValue}
        </Typography>
        <Typography
          size={TypographySize.sm}
          lineHeight={TypographyLineHeight.tight}
          weight={TypographyWeight.regular}
          className={'text-grey-600 text-sm'}
          key={target.targetId}
        >
          {target.keywordType}
        </Typography>
      </div>
    );
  };

  return (
    <>
      {loadingKeywords && (
        <div className="relative  mt-12 ml-16 h-32">
          <Spinner />
        </div>
      )}
      {keywordsData.length ? (
        <div className="h-kwaCreateInAdGroupSlideoutTabV2 overflow-auto px-24">
          <div
            className={classNames(
              'w-464 h-28 bg-grey-50 mb-16 rounded',
              'flex items-center justify-center',
              'text-sm text-grey-900'
            )}
          >
            <div className="mr-24 text-grey-500">
              <FormattedMessage
                id={I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_ADDED_BY}
              />
            </div>
            <div className="mr-24">
              <Icon
                size={IconSize.Small}
                svg={LightningIcon}
                className="text-grey-500 mb-4 mr-8"
              />
              <FormattedMessage
                id={
                  I18nKey.AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AI_POWER_METER_TITLE
                }
              />
            </div>
            <div className="mr-24">
              <Icon
                size={IconSize.Small}
                svg={UserIcon}
                className="text-grey-500 mb-4 mr-8"
              />
              <FormattedMessage
                id={I18nKey.ADS_MANAGER_TARGET_ADDEDBY_USER_ADDED}
              />
            </div>
            <div>
              <Icon
                size={IconSize.Small}
                svg={SparkleIcon}
                className="text-grey-500 mb-4 mr-8"
              />
              <FormattedMessage
                id={I18nKey.ADS_MANAGER_TARGET_ADDEDBY_CHAT_GPT}
              />
            </div>
          </div>
          <div className="flex flex-col gap-24">
            {Object.values(TargetSegment).map((e) => {
              const filteredTargetSegmentData = keywordsData.filter(
                (t) => t.tagType === e
              );
              if (filteredTargetSegmentData.length) {
                return (
                  <div className="flex flex-col gap-8" key={e}>
                    <div className="flex flex-row gap-12 top-0 bg-white">
                      <Typography
                        size={TypographySize.lg}
                        lineHeight={TypographyLineHeight.tight}
                        weight={TypographyWeight.medium}
                        className={'text-grey-900'}
                      >
                        {intl.formatMessage({
                          id: MAP_TARGET_SEGMENT_TO_I18NKEYS[e],
                        })}
                      </Typography>
                      <Pill
                        color={PaletteColor.greyV2}
                        text={filteredTargetSegmentData.length}
                        isHoverable={false}
                        pillSize={PillSize.Small}
                      />
                    </div>
                    <div className="flex flex-col gap-8 ml-4">
                      {filteredTargetSegmentData.map((f) => {
                        return getTaggedKeywordList(f);
                      })}
                    </div>
                  </div>
                );
              }
              return null;
            })}
            {filteredNoTagData.length ? (
              <div className="flex flex-col gap-8">
                <div className="flex flex-row gap-12 top-0 bg-white">
                  <Typography
                    size={TypographySize.lg}
                    lineHeight={TypographyLineHeight.tight}
                    weight={TypographyWeight.medium}
                    className={'text-grey-900'}
                  >
                    {intl.formatMessage({
                      id: I18nKey.GENERIC_TARGET_SEGMENT_NO_TAG,
                    })}
                  </Typography>
                  <Pill
                    color={PaletteColor.greyV2}
                    text={filteredNoTagData.length}
                    isHoverable={false}
                    pillSize={PillSize.Small}
                  />
                </div>
                <div className="flex flex-col gap-8">
                  {filteredNoTagData.map((f) => {
                    return getTaggedKeywordList(f);
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-kwaCreateInAdGroupSlideoutTabV2">
          <span
            className={classNames(
              'flex items-center justify-center',
              'bg-grey-100 h-56 w-56 rounded-full'
            )}
          >
            <Icon
              size={IconSize.Large}
              svg={AlertCircleIcon}
              className="text-red-500"
            />
          </span>
          <div>
            <FormattedMessage
              id={
                I18nKey.ADS_MANAGER_TARGET_SKU_AND_KEYWORDS_MODAL_EMPTY_MESSAGE_TITLE
              }
            />
          </div>
          <div className="font-bold text-base w-360 text-center">
            <FormattedMessage
              id={
                I18nKey.ADS_MANAGER_TARGET_SKU_AND_KEYWORDS_MODAL_EMPTY_MESSAGE
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
