import { Factory } from 'fishery';
import {
  BudgetResponseMerchantPageType,
  TypeBudgetAllocation,
} from '../../../lib/types/BudgetInsightsSharedTypes';
import { TableExtraProps } from '../../../modules/budgetInsightsV2/types';
import { MerchantType } from '../../../lib/types/Fam';

export const budgetResponseMerchantPageTypeResponse = Factory.define<
  (BudgetResponseMerchantPageType & TableExtraProps)[]
>(() => [
  {
    monthlyBudgetAllocation: { amount: '10000', currency: 'USD' },
    mtdBudgetAllocation: { amount: '5000', currency: 'USD' },
    mtdAdSpend: { amount: '4500', currency: 'USD' },
    mtdBudgetUtilization: 90,
    dailyBudgetAllocation: { amount: '300', currency: 'USD' },
    avgDailyAdSpend3d: { amount: '290', currency: 'USD' },
    dailyAdSpendRequired: { amount: '310', currency: 'USD' },
    forecastAdSpend3d: { amount: '930', currency: 'USD' },
    forecastAdSpend7d: { amount: '2170', currency: 'USD' },
    action: 'Increase budget',
    roas: '3.5',
    merchantCountryId: 'merchant_country_id_101',
    budgetAllocationType: TypeBudgetAllocation.MERCHANT,
    mtdRoas: 3.5,
    mtdAcos: 28.6,
    acos30d: 25.0,
    adSales30d: { amount: '100000', currency: 'USD' },
    mtdAdSales: { amount: '45000', currency: 'USD' },
    tacos30d: 12,
    mtdTacos: 10,
    merchantCountries: [
      {
        merchantCountryId: 'merchant_country_id_101',
        merchantName: 'Merchant Country 101',
        merchantType: MerchantType.Seller,
        country: 'USD',
      },
    ],
  },
  {
    monthlyBudgetAllocation: { amount: '10000', currency: 'USD' },
    mtdBudgetAllocation: { amount: '5000', currency: 'USD' },
    mtdAdSpend: { amount: '4500', currency: 'USD' },
    mtdBudgetUtilization: 90,
    dailyBudgetAllocation: { amount: '300', currency: 'USD' },
    avgDailyAdSpend3d: { amount: '290', currency: 'USD' },
    dailyAdSpendRequired: { amount: '310', currency: 'USD' },
    budgetAllocationType: TypeBudgetAllocation.GROUP,
    forecastAdSpend3d: { amount: '930', currency: 'USD' },
    forecastAdSpend7d: { amount: '2170', currency: 'USD' },
    merchantCountryId: 'merchant_country_id_101',
  },
]);
