import first from 'lodash/first';
import isNull from 'lodash/isNull';
import {
  AdType,
  CampaignCostType,
  CampaignTargetingType,
  MerchantCountryCode,
} from '../../lib/types/AOSharedTypes';
import { FlywheelSalesChannel, MerchantType } from '../../lib/types/Fam';
import {
  AMAZON_SALES_CHANNEL_ID,
  MAP_SALES_CHANNEL_NAME_TO_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../lib/types/SalesChannels';
import {
  ADGROUPS_API_COLUMN_NAME,
  CAMPAIGNS_API_COLUMN_NAME,
  TARGETS_API_COLUMN_NAME,
} from '../../modules/advertisingOptimization/containers/adsManager/utils';

export type BidContraints = {
  maxBid: number;
  minBid: number;
  countryCode?: string;
  salesChannelId: string;
  currency?: string;
  currencySymbol?: string;
  variationType?: string;
  defaultMinBid: number;
  defaultMaxBid: number;
  defaultMacsTarget: number;
  minMacsTarget: number;
  maxMacsTarget: number;
  minDailyBudget?: number;
  defaultDailyBudget?: number;
  maxDailyBudget?: number;
  minTotalBudget?: number;
  defaultTotalBudget?: number;
  maxTotalBudget?: number;
  minProductPageBidMultiplier?: number;
  defaultProductPageBidMultiplier?: number;
  maxProductPageBidMultiplier?: number;
  minTopOfSearchBidMultiplier?: number;
  defaultTopOfSearchBidMultiplier?: number;
  maxTopOfSearchBidMultiplier?: number;
  minBelowTopOfTheSearchBidMultiplier?: number;
  defaultBelowTopOfTheSearchBidMultiplier?: number;
  maxBelowTopOfTheSearchBidMultiplier?: number;
};

export const MAP_ADTYPE_TO_VARIATION_TYPE = {
  [AdType.SponsoredProducts]: 'SP',
  [AdType.SponsoredBrands]: 'SB',
  [AdType.SponsoredDisplay]: 'SD',
  [AdType.SponsoredBrandsVideo]: 'SB_VIDEO',
  [AdType.SearchBrandAmplifier]: 'SB',
  [AdType.SponsoredVideos]: 'SV',
};

export const MAP_CAMPAIGN_COST_TYPE_TO_VARIATION_TYPE = {
  [CampaignCostType.CPC]: 'CPC',
  [CampaignCostType.VCPM]: 'V_CPM',
};

export const MAP_CAMPAIGN_TARGETING_TYPE_TO_VARIATION_TYPE = {
  [CampaignTargetingType.Auto]: 'AUTO',
  [CampaignTargetingType.Manual]: 'MANUAL',
};

export const MAP_MERCHANT_TYPE_TO_VARIATION_TYPE = {
  [MerchantType.Seller]: 'SELLER',
  [MerchantType.Vendor]: 'VENDOR',
};

export const getBidConstraintsForWalmart = (
  adType: AdType,
  columnName: string,
  bidConstraintsData: BidContraints[],
  campaignTargetingType?: CampaignTargetingType,
  merchantType?: MerchantType
) => {
  if (adType === AdType.SearchBrandAmplifier) {
    if (
      [
        ADGROUPS_API_COLUMN_NAME.MinBid,
        ADGROUPS_API_COLUMN_NAME.MaxBid,
        ADGROUPS_API_COLUMN_NAME.MACSTarget,
        TARGETS_API_COLUMN_NAME.Bid,
        ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
      ].includes(columnName)
    ) {
      return bidConstraintsData.filter(
        (d) => d.variationType === MAP_ADTYPE_TO_VARIATION_TYPE[adType]
      );
    }

    if (
      [
        ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget,
        ADGROUPS_API_COLUMN_NAME.TotalBudget,
      ].includes(columnName) &&
      merchantType
    ) {
      return bidConstraintsData.filter(
        (d) =>
          d.variationType ===
          MAP_ADTYPE_TO_VARIATION_TYPE[adType] +
            '_' +
            MAP_MERCHANT_TYPE_TO_VARIATION_TYPE[merchantType]
      );
    }
  }

  if (adType === AdType.SponsoredProducts) {
    if (
      [
        CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
        CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
        ADGROUPS_API_COLUMN_NAME.TotalBudget,
      ].includes(columnName)
    ) {
      if (merchantType) {
        return bidConstraintsData.filter(
          (d) =>
            d.variationType ===
            MAP_ADTYPE_TO_VARIATION_TYPE[adType] +
              '_' +
              MAP_MERCHANT_TYPE_TO_VARIATION_TYPE[merchantType]
        );
      }
    }

    if (
      [
        ADGROUPS_API_COLUMN_NAME.MinBid,
        ADGROUPS_API_COLUMN_NAME.MaxBid,
        ADGROUPS_API_COLUMN_NAME.MACSTarget,
        TARGETS_API_COLUMN_NAME.Bid,
        ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
      ].includes(columnName) &&
      campaignTargetingType
    ) {
      return bidConstraintsData.filter(
        (d) =>
          d.variationType ===
          MAP_ADTYPE_TO_VARIATION_TYPE[adType] +
            '_' +
            MAP_CAMPAIGN_TARGETING_TYPE_TO_VARIATION_TYPE[campaignTargetingType]
      );
    }

    return bidConstraintsData.filter(
      (d) =>
        d.variationType ===
        MAP_ADTYPE_TO_VARIATION_TYPE[adType] +
          '_' +
          MAP_CAMPAIGN_TARGETING_TYPE_TO_VARIATION_TYPE[
            CampaignTargetingType.Manual
          ]
    );
  }

  if (adType === AdType.SponsoredVideos) {
    if (
      [
        CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
        ADGROUPS_API_COLUMN_NAME.MinBid,
        ADGROUPS_API_COLUMN_NAME.MaxBid,
      ].includes(columnName)
    ) {
      return bidConstraintsData.filter(
        (d) => d.variationType === MAP_ADTYPE_TO_VARIATION_TYPE[adType]
      );
    }
  }

  return [];
};

export const getBidConstraintsForAmazonSD = (
  adType: AdType,
  columnName: string,
  bidConstraintsData: BidContraints[],
  merchantType?: MerchantType,
  campaignCostType?: CampaignCostType
) => {
  if (
    [
      ADGROUPS_API_COLUMN_NAME.MACSTarget,
      CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
      CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
    ].includes(columnName)
  ) {
    return bidConstraintsData.filter(
      (d) => d.variationType === MAP_ADTYPE_TO_VARIATION_TYPE[adType]
    );
  }

  if ([ADGROUPS_API_COLUMN_NAME.MaxBid].includes(columnName) && merchantType) {
    return bidConstraintsData.filter(
      (d) =>
        d.variationType ===
        MAP_ADTYPE_TO_VARIATION_TYPE[adType] +
          '_' +
          MAP_MERCHANT_TYPE_TO_VARIATION_TYPE[merchantType]
    );
  }

  if (
    [
      ADGROUPS_API_COLUMN_NAME.MinBid,
      TARGETS_API_COLUMN_NAME.Bid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ].includes(columnName) &&
    merchantType &&
    campaignCostType &&
    campaignCostType === CampaignCostType.CPC
  ) {
    return bidConstraintsData.filter(
      (d) =>
        d.variationType ===
        MAP_ADTYPE_TO_VARIATION_TYPE[adType] +
          '_' +
          MAP_CAMPAIGN_COST_TYPE_TO_VARIATION_TYPE[campaignCostType] +
          '_' +
          MAP_MERCHANT_TYPE_TO_VARIATION_TYPE[merchantType]
    );
  }
  // Bidder does not support FW settings for VCPM campaigns
  if (
    [
      TARGETS_API_COLUMN_NAME.Bid,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ].includes(columnName) &&
    campaignCostType &&
    campaignCostType === CampaignCostType.VCPM
  ) {
    return bidConstraintsData.filter(
      (d) =>
        d.variationType ===
        MAP_ADTYPE_TO_VARIATION_TYPE[adType] +
          '_' +
          MAP_CAMPAIGN_COST_TYPE_TO_VARIATION_TYPE[campaignCostType]
    );
  }

  if (merchantType) {
    return bidConstraintsData.filter(
      (d) =>
        d.variationType ===
        MAP_ADTYPE_TO_VARIATION_TYPE[adType] +
          '_' +
          MAP_CAMPAIGN_COST_TYPE_TO_VARIATION_TYPE[CampaignCostType.CPC] +
          '_' +
          MAP_MERCHANT_TYPE_TO_VARIATION_TYPE[merchantType]
    );
  }

  return [];
};

export const getBidConstraintsForAmazon = (
  adType: AdType,
  columnName: string,
  bidConstraintsData: BidContraints[],
  merchantType?: MerchantType,
  campaignCostType?: CampaignCostType
) => {
  if (
    [
      AdType.SponsoredBrandsVideo,
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
    ].includes(adType)
  ) {
    if (
      [
        ADGROUPS_API_COLUMN_NAME.MinBid,
        ADGROUPS_API_COLUMN_NAME.MaxBid,
        ADGROUPS_API_COLUMN_NAME.MACSTarget,
        CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
        TARGETS_API_COLUMN_NAME.Bid,
        CAMPAIGNS_API_COLUMN_NAME.MinBid,
        ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
        CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
        CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
        CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier,
        CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
        CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier,
      ].includes(columnName)
    ) {
      return bidConstraintsData.filter(
        (d) => d.variationType === MAP_ADTYPE_TO_VARIATION_TYPE[adType]
      );
    }
  }

  if (adType === AdType.SponsoredDisplay) {
    return getBidConstraintsForAmazonSD(
      adType,
      columnName,
      bidConstraintsData,
      merchantType,
      campaignCostType
    );
  }

  return [];
};

export const getBidConstraint = (
  bidContraintsData: BidContraints[],
  adType: AdType,
  salesChannel: FlywheelSalesChannel,
  columnName: string,
  countryCode: MerchantCountryCode,
  campaignTargetingType?: CampaignTargetingType,
  merchantType?: MerchantType,
  campaignCostType?: CampaignCostType
): BidContraints => {
  let bidConstraints: BidContraints[] = [];

  if (MAP_SALES_CHANNEL_NAME_TO_ID[salesChannel] === WALMART_SALES_CHANNEL_ID) {
    bidConstraints = getBidConstraintsForWalmart(
      adType,
      columnName,
      bidContraintsData.filter(
        (d) =>
          d.salesChannelId === WALMART_SALES_CHANNEL_ID &&
          d.countryCode === countryCode
      ),
      campaignTargetingType,
      merchantType
    );
  }

  if (MAP_SALES_CHANNEL_NAME_TO_ID[salesChannel] === AMAZON_SALES_CHANNEL_ID) {
    bidConstraints = getBidConstraintsForAmazon(
      adType,
      columnName,
      bidContraintsData.filter(
        (d) =>
          d.salesChannelId === AMAZON_SALES_CHANNEL_ID &&
          d.countryCode === countryCode
      ),
      merchantType,
      campaignCostType
    );
  }

  if (first(bidConstraints)) {
    let constraints = bidConstraints[0] as unknown as Record<
      string,
      number | undefined
    >;

    Object.keys(constraints).forEach((key) => {
      if (constraints[key] === -1) {
        constraints[key] = Infinity;
      }

      if (isNull(constraints[key])) {
        constraints[key] = undefined;
      }
    });
    return constraints as unknown as BidContraints;
  }

  return {
    maxBid: Infinity,
    minBid: 0,
    defaultMinBid: 0,
    defaultMaxBid: 0,
    defaultMacsTarget: 0,
    minMacsTarget: 0,
    maxMacsTarget: Infinity,
    salesChannelId: MAP_SALES_CHANNEL_NAME_TO_ID[salesChannel],
    defaultBelowTopOfTheSearchBidMultiplier: 0,
    defaultDailyBudget: 0,
    defaultProductPageBidMultiplier: 0,
    defaultTopOfSearchBidMultiplier: 0,
    defaultTotalBudget: 0,
    maxBelowTopOfTheSearchBidMultiplier: Infinity,
    maxDailyBudget: Infinity,
    maxProductPageBidMultiplier: Infinity,
    maxTopOfSearchBidMultiplier: Infinity,
    maxTotalBudget: Infinity,
    minBelowTopOfTheSearchBidMultiplier: 0,
    minDailyBudget: 0,
    minProductPageBidMultiplier: 0,
    minTopOfSearchBidMultiplier: 0,
    minTotalBudget: 0,
  };
};
