import {
  BreadCrumbLink,
  BreadCrumbs,
  CircleStrike,
  ContextSetterV3,
  Layout,
  PlusCircleIcon,
  SelectMenuVariant,
  SelectV2OptionProps,
  SidebarNav,
  SidebarNavType,
  Spinner,
  Timestamp,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import first from 'lodash/first';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { NavPaths } from '../../../NavPaths';
import { AutoNegateSettingsWrapper } from '../../../components/AutoNegateSettings/autoNegateSettings';
import { CriteriaSettings } from '../../../components/CriteriaSettings/criteriaSettings';
import { CriteriaSettingsSlideover } from '../../../components/CriteriaSettings/criteriaSettingsSlideover';
import { useMerchantsSyncing } from '../../../components/EmptyState/hooks';
import { SalesChannelContext } from '../../../containers/salesChannelProvider';
import { DataSyncInfoContext } from '../../../containers/dataSyncInfoProvider/dataSyncInfoProvider';
import {
  MerchantCountriesContext,
  MerchantCountriesContextState,
} from '../../../containers/merchantCountriesProvider/merchantCountriesProvider';
import {
  PageHeaderContext,
  PageHeaderContextState,
} from '../../../containers/pageHeaderProvider';
import {
  getCurrentAccountFromContext,
  isManagedAccount,
} from '../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../containers/userProvider/userProvider';
import { createAOApiClient } from '../../../lib/clients/AOApiClient';
import {
  AdType,
  MerchantCountryId,
  UserAutoNegateSettings,
  UserPreferenceType,
} from '../../../lib/types/AOSharedTypes';
import { MerchantCountry } from '../../../lib/types/Fam';
import I18nKey from '../../../lib/types/I18nKey';
import { WALMART_SALES_CHANNEL_ID } from '../../../lib/types/SalesChannels';
import { generateMerchantPickerOptions } from '../../products/containers/skuCatalog/utils/contextSetterUtilities';
import { storeSelectedMerchantRecommendationsToLocalStorage } from '../storageUtils';
import { KeywordTabPaths } from './KeywordsDashboard2';
import { KeywordsToAdd } from './KeywordsToAdd';
import {
  RecommendationsCountContext,
  RecommendationsCountState,
} from './RecommendationsCountProvider';
import { OptimizelyContext } from '../../../containers/optimizelyProvider/optimizelyProvider';
import { OptimizelyFlags } from '../../../lib/types/OptimizelyFlags';

const AD_CONVERSIONS = 1;
const AD_CONVERSIONS_NEG = 0;
const CLICKS = 1;
const DAYS = 30;
const DAYS_NEG = 60;
const AD_SPEND = 5;

interface NegativeUserPreference {
  readonly adConversions: number;
  readonly clicks: number;
  readonly days: number;
  readonly adSpend: number;
  readonly lastUpdated: DateTime;
}

export enum KeywordLevel {
  Keywords = 'Keywords',
  NegativeKeywords = 'NegativeKeywords',
  PAT = 'PatKeywords',
}

export enum RecommendationsCampaignType {
  All = 'All',
  Smart = 'Smart',
  External = 'External',
}

export enum KWATargetsType {
  SponsoredProductsKeywords = 'sponsoredProductsKeywords',
  SponsoredProductsPatKeywords = 'sponsoredProductsPatKeywords',
  SponsoredProductsNegativeKeywords = 'sponsoredProductsNegativeKeywords',
  SponsoredProductsNegativePatKeywords = 'sponsoredProductsNegativePatKeywords',
  SponsoredBrandsKeywords = 'sponsoredBrandsKeywords',
  SponsoredBrandsPatKeywords = 'sponsoredBrandsPatKeywords',
  SponsoredBrandsNegativeKeywords = 'sponsoredBrandsNegativeKeywords',
  SponsoredBrandsNegativePatKeywords = 'sponsoredBrandsNegativePatKeywords',
}

export const CAMPAIGN_TYPE_TO_I18NKEY_MAP: Record<
  RecommendationsCampaignType,
  I18nKey
> = {
  [RecommendationsCampaignType.All]:
    I18nKey.AI_RECOMMENDED_KEYWORD_AD_SALES_CAMPAIGN_TYPES_ALL,
  [RecommendationsCampaignType.Smart]:
    I18nKey.AI_RECOMMENDED_KEYWORD_AD_SALES_CAMPAIGN_TYPES_SMART,
  [RecommendationsCampaignType.External]:
    I18nKey.AI_RECOMMENDED_KEYWORD_AD_SALES_CAMPAIGN_TYPES_EXTERNAL,
};

export const getKeywordLevel = (level: string) => {
  if (level.includes(KeywordTabPaths.KeywordsToNegate)) {
    return KeywordLevel.NegativeKeywords;
  } else if (level.includes(KeywordTabPaths.KeywordsToAdd)) {
    return KeywordLevel.Keywords;
  }
  return KeywordLevel.PAT;
};

const getSidebarNavValue = (level: KeywordLevel) => {
  if (level === KeywordLevel.NegativeKeywords) {
    return KWATargetsType.SponsoredProductsNegativeKeywords;
  } else if (level === KeywordLevel.Keywords) {
    return KWATargetsType.SponsoredProductsKeywords;
  }
  return KWATargetsType.SponsoredProductsPatKeywords;
};

export const getKeywordTabPath = (level: KeywordLevel) => {
  if (level === KeywordLevel.NegativeKeywords) {
    return KeywordTabPaths.KeywordsToNegate;
  } else if (level === KeywordLevel.Keywords) {
    return KeywordTabPaths.KeywordsToAdd;
  }
  return KeywordTabPaths.PatToAdd;
};

const getKeywordsPathTabFromTargetType = (targetType: string) => {
  if (targetType === KWATargetsType.SponsoredProductsNegativeKeywords) {
    return KeywordTabPaths.KeywordsToNegate;
  } else if (
    targetType === KWATargetsType.SponsoredProductsKeywords ||
    targetType === KWATargetsType.SponsoredBrandsKeywords
  ) {
    return KeywordTabPaths.KeywordsToAdd;
  }

  return KeywordTabPaths.PatToAdd;
};

type OwnProps = {
  readonly dataTestId?: string;
};

const getKeywordActionsTableId = (
  level: KWATargetsType,
  allCounts: RecommendationsCountState
) => {
  switch (level) {
    case KWATargetsType.SponsoredBrandsKeywords:
      return allCounts.sbKeywordsByChannelCount > 0 &&
        allCounts.sbKeywordsByPerformanceCount === 0
        ? 'keywords-by-channel'
        : 'keywords-by-performance';
    case KWATargetsType.SponsoredProductsKeywords:
      return allCounts.spKeywordsByChannelCount > 0 &&
        allCounts.spKeywordsByPerformanceCount === 0
        ? 'keywords-by-channel'
        : 'keywords-by-performance';
    case KWATargetsType.SponsoredProductsNegativeKeywords:
      return allCounts.negativeKeywordsByChannelCount > 0 &&
        allCounts.negativeKeywordsByPerformanceCount === 0
        ? 'keywords-by-channel'
        : 'keywords-by-performance';
    case KWATargetsType.SponsoredProductsPatKeywords:
    case KWATargetsType.SponsoredBrandsNegativePatKeywords:
    case KWATargetsType.SponsoredProductsNegativePatKeywords:
    case KWATargetsType.SponsoredBrandsPatKeywords:
    default:
      return 'keywords-by-performance';
  }
};

export const KeywordActionsV4: React.FC<OwnProps> = () => {
  const intl = useIntl();
  const [TITLE] = [I18nKey.KEYWORD_ACTIONS_TITLE].map((id) =>
    intl.formatMessage({ id })
  );

  const navigate = useNavigate();

  const location = useLocation();

  const keywordLevel = getKeywordLevel(location.pathname);

  const [sidebarNavValue, setSidebarNavValue] = useState<string>(
    getSidebarNavValue(keywordLevel)
  );

  const userContext = useContext<UserContextState>(UserContext);

  const merchantCountriesContext = useContext<MerchantCountriesContextState>(
    MerchantCountriesContext
  );
  const salesChannelContext = useContext(SalesChannelContext);

  const { aoDataSyncInfo } = useContext(DataSyncInfoContext);

  const allCounts = useContext(RecommendationsCountContext);

  const {
    setMerchantCountryId,
    spKeywordsToAddCount,
    spKeywordsToNegateCount,
    spPatKeywordsToAddCount,
    sbKeywordsToAddCount,
    sbPatKeywordsToAddCount,
  } = allCounts;

  const currentAdType = sidebarNavValue.includes(AdType.SponsoredBrands)
    ? AdType.SponsoredBrands
    : AdType.SponsoredProducts;

  const aoApiClient = createAOApiClient(userContext.userInfo.idToken!);

  const isManaged = isManagedAccount(userContext);

  const { featureFlags } = useContext(OptimizelyContext);
  const kwaSponsoredBrandsEnabled =
    featureFlags[OptimizelyFlags.KWASponsoredBrands];

  const [userNegativePreference, setUserNegativePreference] = useState<
    NegativeUserPreference | undefined
  >();

  const [userPreferenceSettings, setUserPreferenceSettings] = useState<
    UserAutoNegateSettings | undefined
  >(undefined);

  const [isAutoNegateUpdating, setIsAutoNegateUpdating] =
    useState<boolean>(false);

  const [, setIsNkwrCriteriaUpdatedRecently] = useState<boolean>(false);

  const userId = userContext.userInfo.userDetails?.id || '';
  const { id: accountId } = getCurrentAccountFromContext(userContext)!;
  const { salesChannels: fw2SalesChannels } = salesChannelContext;

  const merchantCountries = merchantCountriesContext.merchantCountries!;
  const merchants = useMemo(() => {
    return generateMerchantPickerOptions(
      merchantCountries,
      fw2SalesChannels,
      intl
    );
  }, [merchantCountries]);

  const [hardRefreshNegTableToggle, setHardRefreshNegTableToggle] =
    useState<boolean>(false);

  const [selectedMerchantCountry, setSelectedMerchantCountry] = useState<
    MerchantCountry | undefined
  >(undefined);

  const [isCriteriaSlideoverOpen, setIsCriteriaSlideoverOpen] = useState(false);

  useEffect(() => {
    const forwardedMerchantCountryId = location.pathname.split('/')[3];
    if (forwardedMerchantCountryId !== 'settings') {
      handleMerchantChange(forwardedMerchantCountryId);
    }
  }, [merchants]);

  const handleMerchantChange = (id: string | number | (string | number)[]) => {
    const merchantCountry = merchantCountries.find(
      (merchantCountry) => merchantCountry.merchantCountryId === id
    );
    setSelectedMerchantCountry(merchantCountry);

    setMerchantCountryId(merchantCountry?.merchantCountryId || '');

    const selectedTabForKeywords = location.pathname.includes(
      'keywords-by-performance'
    )
      ? '/keywords-by-performance'
      : '/keywords-by-channel';

    const relevantTab =
      getKeywordTabPath(keywordLevel) + selectedTabForKeywords;
    navigate(
      NavPaths.KeywordsTargets +
        `/${merchantCountry?.merchantCountryId}` +
        `/${relevantTab}`
    );
    storeSelectedMerchantRecommendationsToLocalStorage({
      userId,
      accountId,
      merchantId: id as MerchantCountryId,
    });
  };

  const onSuccessfulSave = (clicks: number, adSpend: number) => {
    const now = DateTime.now();
    onNkwrCriteriaUpdateSuccess(now);
    userNegativePreference &&
      setUserNegativePreference({
        ...userNegativePreference,
        clicks,
        adSpend,
        lastUpdated: now,
      });
  };

  const onNkwrCriteriaUpdateSuccess = (dateTime: DateTime) => {
    setIsNkwrCriteriaUpdatedRecently(
      dateTime > DateTime.now().minus({ days: 1 })
    );
    setHardRefreshNegTableToggle(!hardRefreshNegTableToggle);
  };

  const merchantsSyncing = useMerchantsSyncing();

  const changeKeywordLevel = (level: string) => {
    const id = getKeywordActionsTableId(level as KWATargetsType, allCounts);
    const relevantTab = getKeywordsPathTabFromTargetType(level) + '/' + id;
    if (!location.pathname.includes(relevantTab)) {
      navigate(
        NavPaths.KeywordsTargets +
          `/${selectedMerchantCountry?.merchantCountryId}` +
          `/${relevantTab}`
      );
    }
  };

  const breadCrumbs = (
    <BreadCrumbs>
      <BreadCrumbLink
        onClick={(e) => {
          e.preventDefault();
          navigate?.(NavPaths.KeywordsTargets);
        }}
        textLabel={
          <FormattedMessage id={I18nKey.KEYWORD_ACTIONS_BREADCRUMB_LINK} />
        }
        dataTestId="keyword_targeting_breadcrumbs"
      />
    </BreadCrumbs>
  );

  const { updatePageHeaderData } =
    useContext<PageHeaderContextState>(PageHeaderContext);

  useEffect(() => {
    updatePageHeaderData({
      title: (
        <div className="flex items-center font-normal">
          <span className="font-semibold">{TITLE}</span>
        </div>
      ),
      titleString: TITLE,
      dataTestId: 'keyword_actions_v3',
      breadCrumbs,
    });
  }, []);

  const fetchAutoNegateStatus = () => {
    if (selectedMerchantCountry) {
      aoApiClient
        .getAutoNegateSettings(accountId, [
          selectedMerchantCountry.merchantCountryId,
        ])
        .then((resp) => {
          if (resp.length > 0) {
            setUserPreferenceSettings(first(resp));
          } else {
            if (
              selectedMerchantCountry.salesChannelId !==
              WALMART_SALES_CHANNEL_ID
            ) {
              setUserPreferenceSettings({
                merchantCountryId: selectedMerchantCountry.merchantCountryId,
                isAutoNegateEnabled: !!isManaged,
              });
              aoApiClient.saveAutoNegateSettings(accountId, {
                userId,
                settings: [
                  {
                    merchantCountryId:
                      selectedMerchantCountry.merchantCountryId,
                    isAutoNegateEnabled: !!isManaged,
                  },
                ],
              });
            }
          }
        })
        .finally(() => {
          setIsAutoNegateUpdating(false);
        });
    }
  };

  useEffect(() => {
    fetchAutoNegateStatus();
  }, [selectedMerchantCountry]);

  useEffect(() => {
    if (
      keywordLevel === KeywordLevel.NegativeKeywords &&
      selectedMerchantCountry?.merchantCountryId
    ) {
      setUserNegativePreference(undefined);
      aoApiClient
        .getUserPreference(accountId, UserPreferenceType.Negative, [
          selectedMerchantCountry?.merchantCountryId,
        ])
        .then((resp) => {
          const lastUpdated = DateTime.fromISO(resp.settings[0].lastUpdatedAt);
          setUserNegativePreference({
            adConversions: resp.settings[0].adConversions,
            clicks: resp.settings[0].clicks,
            days: resp.settings[0].duration,
            adSpend: Number(resp.settings[0].adSpend.amount),
            lastUpdated: lastUpdated,
          });
          onNkwrCriteriaUpdateSuccess(lastUpdated);
        })
        .catch(() =>
          setUserNegativePreference({
            adConversions: AD_CONVERSIONS_NEG,
            clicks: CLICKS,
            days:
              keywordLevel === KeywordLevel.NegativeKeywords ? DAYS_NEG : DAYS,
            adSpend: AD_SPEND,
            lastUpdated: DateTime.now(),
          })
        );
    } else {
      setUserNegativePreference({
        adConversions: AD_CONVERSIONS,
        clicks: CLICKS,
        days: keywordLevel === KeywordLevel.NegativeKeywords ? DAYS_NEG : DAYS,
        adSpend: AD_SPEND,
        lastUpdated: DateTime.now(),
      });
    }
  }, [keywordLevel, selectedMerchantCountry]);

  if (!merchantsSyncing.isMerchantSyncLoaded || !selectedMerchantCountry) {
    return <Spinner />;
  }

  const onSidebarNavChangeHandler = (value?: string) => {
    setSidebarNavValue(value ?? '');
    changeKeywordLevel(value ?? '');
  };

  const SIDEBAR_NAV_SECTION = getSidebarNavSections(
    intl,
    sidebarNavValue,
    spKeywordsToAddCount,
    spKeywordsToNegateCount,
    spPatKeywordsToAddCount,
    sbKeywordsToAddCount,
    sbPatKeywordsToAddCount,
    featureFlags[OptimizelyFlags.SPPatKWA] &&
      selectedMerchantCountry.salesChannelId !== WALMART_SALES_CHANNEL_ID,
    kwaSponsoredBrandsEnabled
  );

  return (
    <Layout classNames="mt-0 mb-0">
      <main className="mx-16 mt-8 z-10">
        <div className="w-full">
          <ContextSetterV3
            primaryDropdownProps={{
              primaryDropdownLabel: intl.formatMessage({
                id: I18nKey.CONTEXT_SETTER_SALES_CHANNEL_PICKER_MERCHANT,
              }),
              primaryDropdownPlaceholder: intl.formatMessage({
                id: I18nKey.AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_MERCHANT_COUNTRY_PLACEHOLDER,
              }),
              primaryDropdownOptions:
                merchants as SelectV2OptionProps<string>[],
              primaryDropdownValue:
                selectedMerchantCountry?.merchantCountryId || '',
              primaryDropdownShowSearch: true,
              primaryDropdownVariant: SelectMenuVariant.TextWithIconHighlight,
              onSelectPrimaryDropdown: handleMerchantChange,
            }}
            isLoading={!selectedMerchantCountry}
            dataTestId="keyword_actions_v2_context-setter"
            classnames="max-w-1280 mx-auto mb-16"
          />
        </div>
      </main>
      <div className="flex h-full m-16">
        <div className="flex pt-16 w-200 max-w-200 border-r border-solid border-grey-200 h-full shrink-0">
          <SidebarNav
            onChange={onSidebarNavChangeHandler}
            sections={SIDEBAR_NAV_SECTION}
            type={SidebarNavType.TextLink}
          />
        </div>
        <div className="flex flex-col w-full pl-16">
          {keywordLevel === KeywordLevel.NegativeKeywords &&
            userNegativePreference && (
              <>
                <CriteriaSettings
                  adConversions={String(userNegativePreference?.adConversions)}
                  clicks={String(userNegativePreference?.clicks)}
                  adSpend={String(userNegativePreference?.adSpend)}
                  previousByNumberOfDays={String(userNegativePreference?.days)}
                  onOpen={() => setIsCriteriaSlideoverOpen(true)}
                />
                <CriteriaSettingsSlideover
                  isOpen={isCriteriaSlideoverOpen}
                  onClose={() => setIsCriteriaSlideoverOpen(false)}
                  criteria={{
                    adConversions: String(
                      userNegativePreference?.adConversions
                    ),
                    clicks: String(userNegativePreference?.clicks),
                    adSpend: String(userNegativePreference?.adSpend),
                    previousByNumberOfDays: String(
                      userNegativePreference?.days
                    ),
                  }}
                  merchantCountryId={
                    selectedMerchantCountry?.merchantCountryId || ''
                  }
                  accountId={userContext.userInfo.currentAccountId || ''}
                  userId={userContext.userInfo.userDetails?.id || ''}
                  onSuccessfulSave={onSuccessfulSave}
                />
              </>
            )}

          {keywordLevel === KeywordLevel.Keywords && (
            <div
              className={classNames(
                'flex flex-row justify-between items-center'
              )}
            >
              <AutoNegateSettingsWrapper
                aoAPIClient={aoApiClient}
                accountId={userContext.userInfo.currentAccountId || ''}
                userId={userContext.userInfo.userDetails?.id || ''}
                fetchAutoNegateStatus={fetchAutoNegateStatus}
                isAutoNegateUpdating={
                  isAutoNegateUpdating ||
                  selectedMerchantCountry?.salesChannelId ===
                    WALMART_SALES_CHANNEL_ID
                }
                userPreferenceSettings={userPreferenceSettings}
                disabled={
                  selectedMerchantCountry?.salesChannelId ===
                  WALMART_SALES_CHANNEL_ID
                }
              />

              <Timestamp
                syncDate={
                  aoDataSyncInfo?.syncPerMerchantIds?.find(
                    (merchant) =>
                      merchant.merchantCountryId ===
                      selectedMerchantCountry?.merchantCountryId
                  )?.lastSyncedAt
                }
              />
            </div>
          )}
          {selectedMerchantCountry ? (
            <Routes>
              <Route
                path={'/' + KeywordTabPaths.KeywordsToAdd + '/*'}
                Component={() => (
                  <KeywordsToAdd
                    merchantCountry={selectedMerchantCountry}
                    hardRefreshNegTableToggle={hardRefreshNegTableToggle}
                    merchantCountryId={
                      selectedMerchantCountry?.merchantCountryId
                    }
                    advertisingType={currentAdType}
                  />
                )}
              />
              <Route
                path={'/' + KeywordTabPaths.KeywordsToNegate + '/*'}
                Component={() => (
                  <KeywordsToAdd
                    merchantCountry={selectedMerchantCountry}
                    hardRefreshNegTableToggle={hardRefreshNegTableToggle}
                    merchantCountryId={
                      selectedMerchantCountry?.merchantCountryId
                    }
                    advertisingType={currentAdType}
                  />
                )}
              />
              {featureFlags[OptimizelyFlags.SPPatKWA] && (
                <Route
                  path={'/' + KeywordTabPaths.PatToAdd + '/*'}
                  Component={() => (
                    <KeywordsToAdd
                      merchantCountry={selectedMerchantCountry}
                      hardRefreshNegTableToggle={hardRefreshNegTableToggle}
                      merchantCountryId={
                        selectedMerchantCountry?.merchantCountryId
                      }
                      advertisingType={currentAdType}
                    />
                  )}
                />
              )}
            </Routes>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

const getSidebarNavSections = (
  intl: IntlShape,
  value: string,
  spKeywordCount: number,
  negativeKeywordCount: number,
  spPatCount: number,
  sbKeywordCount: number,
  sbPatCount: number,
  isPatFeatureFlagEnabled?: boolean,
  kwaSponsoredBrandsEnabled?: boolean
) => {
  const items = [];

  if (!!kwaSponsoredBrandsEnabled) {
    items.push({
      sectionHeader: {
        svg: PlusCircleIcon,
        title: intl.formatMessage({
          id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_TARGETS_TO_ADD,
        }),
        type: SidebarNavType.TextLink,
      },
      subSections: [
        {
          subSectionHeader: intl.formatMessage({
            id: I18nKey.GENERIC_AD_TYPE_SPONSORED_PRODUCT,
          }),
          links: [
            {
              count: spKeywordCount,
              label: intl.formatMessage({
                id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_KEYWORDS,
              }),
              link: '/sponsoredProducts',
              selected: value === KWATargetsType.SponsoredProductsKeywords,
              value: KWATargetsType.SponsoredProductsKeywords,
              subHeader: intl.formatMessage({
                id: I18nKey.GENERIC_AD_TYPE_SPONSORED_PRODUCTS,
              }),
            },
            ...(isPatFeatureFlagEnabled
              ? [
                  {
                    count: spPatCount,
                    label: intl.formatMessage({
                      id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_PAT,
                    }),
                    link: '/sponsoredProducts',
                    selected:
                      value === KWATargetsType.SponsoredProductsPatKeywords,
                    value: KWATargetsType.SponsoredProductsPatKeywords,
                  },
                ]
              : []),
          ],
        },
        {
          subSectionHeader: intl.formatMessage({
            id: I18nKey.GENERIC_AD_TYPE_SPONSORED_BRAND,
          }),
          links: [
            {
              count: sbKeywordCount,
              label: intl.formatMessage({
                id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_KEYWORDS,
              }),
              link: '/sponsoredBrands',
              selected: value === KWATargetsType.SponsoredBrandsKeywords,
              value: KWATargetsType.SponsoredBrandsKeywords,
              subHeader: intl.formatMessage({
                id: I18nKey.GENERIC_AD_TYPE_SPONSORED_PRODUCTS,
              }),
            },
            ...(isPatFeatureFlagEnabled
              ? [
                  {
                    count: sbPatCount,
                    label: intl.formatMessage({
                      id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_PAT,
                    }),
                    link: '/sponsoredBrands',
                    selected:
                      value === KWATargetsType.SponsoredBrandsPatKeywords,
                    value: KWATargetsType.SponsoredBrandsPatKeywords,
                  },
                ]
              : []),
          ],
        },
      ],
    });

    items.push({
      subSections: [
        {
          subSectionHeader: intl.formatMessage({
            id: I18nKey.GENERIC_AD_TYPE_SPONSORED_PRODUCTS,
          }),
          links: [
            {
              count: negativeKeywordCount,
              label: intl.formatMessage({
                id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_KEYWORDS,
              }),
              link: '/sponsoredProducts',
              selected:
                value === KWATargetsType.SponsoredProductsNegativeKeywords,
              value: KWATargetsType.SponsoredProductsNegativeKeywords,
            },
          ],
        },
      ],
      sectionHeader: {
        svg: CircleStrike,
        title: intl.formatMessage({
          id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_TARGETS_TO_NEGATE,
        }),
        type: SidebarNavType.TextLink,
      },
    });
  } else {
    items.push({
      links: [
        {
          count: spKeywordCount,
          label: intl.formatMessage({
            id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_SPONSORED_PRODUCT_KEYWORDS,
          }),
          link: '/sponsoredProducts',
          selected: value === KWATargetsType.SponsoredProductsKeywords,
          value: KWATargetsType.SponsoredProductsKeywords,
        },
        ...(isPatFeatureFlagEnabled
          ? [
              {
                count: spPatCount,
                label: intl.formatMessage({
                  id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_SPONSORED_PRODUCT_PAT,
                }),
                link: '/sponsoredProducts',
                selected: value === KWATargetsType.SponsoredProductsPatKeywords,
                value: KWATargetsType.SponsoredProductsPatKeywords,
              },
            ]
          : []),
      ],
      sectionHeader: {
        svg: PlusCircleIcon,
        title: intl.formatMessage({
          id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_TARGETS_TO_ADD,
        }),
        type: SidebarNavType.TextLink,
      },
    });

    items.push({
      links: [
        {
          count: negativeKeywordCount,
          label: intl.formatMessage({
            id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_SPONSORED_PRODUCT_KEYWORDS,
          }),
          link: '/sponsoredProducts',
          selected: value === KWATargetsType.SponsoredProductsNegativeKeywords,
          value: KWATargetsType.SponsoredProductsNegativeKeywords,
        },
      ],
      sectionHeader: {
        svg: CircleStrike,
        title: intl.formatMessage({
          id: I18nKey.AI_RECOMMENDED_KEYWORD_SIDE_NAV_TARGETS_TO_NEGATE,
        }),
        type: SidebarNavType.TextLink,
      },
    });
  }

  return items;
};

KeywordActionsV4.displayName = 'KeywordActionsV4';
