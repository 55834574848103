import { CheckboxCheckedState } from '@teikametrics/tm-design-system';
import { CurrencyCode } from '../../../../../lib/utilities/currency';

export interface SkuDetailsV2DataRequestProps {
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: CurrencyCode;
  readonly merchantCountryId: string;
  readonly previousPeriodIncluded: boolean;
  readonly performanceFields: string[];
}

export enum SkuDetailsTab {
  EventLogs = 'eventLogs',
  Profitability = 'profitability',
  ProductSettings = 'productSettings',
}

export interface EventsLogsFiltersTypeData {
  [key: string]: { eventType: string; viewName: string; isChecked: boolean };
}

export interface EventsLogsFilterGroupData {
  selected: CheckboxCheckedState;
  expanded: boolean;
  eventTypes: EventsLogsFiltersTypeData[];
  selectedCount: number;
  viewName: string;
}

export interface EventsLogsFiltersData {
  [key: string]: EventsLogsFilterGroupData;
}
