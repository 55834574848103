import { useContext } from 'react';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  MerchantContext,
  MerchantContextState,
} from '../../../merchantsProvider';
import { PROFILES_API_COLUMN_NAME, getMerchantName } from '../utils';

export const RowCellElement: React.FC<ProfileApiDataDetails> = ({
  profileDetails,
  profilePerformance,
  profileId,
  channelSettings,
}) => {
  const campaignData: ProfileApiDataDetails = {
    profileDetails,
    profilePerformance,
    profileId,
    channelSettings,
  };

  const merchantContext = useContext<MerchantContextState>(MerchantContext);

  const merchantDetails = merchantContext.merchantDetails;

  return makeTextColumn((data: ProfileApiDataDetails) =>
    getMerchantName(merchantDetails, data.profileDetails.merchantCountryId)
  )(campaignData);
};
RowCellElement.displayName = 'RowCellElement';

export const merchantNameColumn: FlywheelTableColumn<ProfileApiDataDetails> = {
  columnName: PROFILES_API_COLUMN_NAME.MerchantName,
  isSortable: false,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_MERCHANT_NAME,
  RowCellElement,
  gridColumnWidth: '240px',
};
