import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { FAMApiClient } from '../../../../../lib/clients/FAMApiClient';
import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../../ConnectModalWrapper';
import { ModalContent } from './content';
import { ModalFooter } from './footer';

export interface AuthorizeWalmartModalProps {
  readonly famClient: FAMApiClient;
  readonly accountId: string;
  readonly goBack: () => void;
  readonly authorize: () => void;
  readonly closeModal?: () => void;
}

export const AuthorizeWalmartModal: React.FC<AuthorizeWalmartModalProps> = ({
  famClient,
  accountId,
  goBack,
  authorize,
  closeModal,
}) => {
  const intl = useIntl();
  const [authorizeButtonClicked, setAuthorizeButtonClicked] =
    useState<boolean>(false);

  const onAuthorizeButtonClicked = () => {
    setAuthorizeButtonClicked(true);
  };

  return (
    <ConnectModalWrapper
      dataTestId="authorize_walmart_modal"
      isOpen
      closeModal={closeModal}
      headerText={intl.formatMessage({
        id: I18nKey.FLASH_MODALS_WALMART_MODAL_HEADER,
      })}
      content={
        <ModalContent
          authorize={authorize}
          famClient={famClient}
          accountId={accountId}
          onAuthorizeButtonClicked={onAuthorizeButtonClicked}
        />
      }
      footer={
        <ModalFooter
          goBack={goBack}
          authorize={authorize}
          authorizeButtonClicked={authorizeButtonClicked}
        />
      }
      headerChannelType={FlywheelSalesChannel.Walmart}
    />
  );
};
AuthorizeWalmartModal.displayName = 'AuthorizeWalmartModal';
