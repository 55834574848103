import React, { useContext, useMemo, useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CheckmarkIcon,
  CrossMiniIcon,
  TextInput,
  TextInputState,
  Theme,
  Tooltip,
} from '@teikametrics/tm-design-system';
import { BrandName } from './brandName';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { CreateChatGPTKeywordsContext } from './createChatGPTKeywordsProvider';

export interface BrandNamesContainerProps {
  readonly isLoading: boolean;
}

export const BrandNamesContainer: React.FC<BrandNamesContainerProps> = ({
  isLoading,
}) => {
  const { brands, updateBrand, deleteBrand, createBrands, isBrandsLoading } =
    useContext(CreateChatGPTKeywordsContext);
  const [brandName, setBrandName] = useState<string>('');
  const intl = useIntl();

  const onBrandNameInputChange = (value: string) => {
    setBrandName(value);
  };

  const handleOnCreate = () => {
    if (!brandName) {
      return;
    }

    createBrands(brandName);
    setBrandName('');
  };

  const inputState = useMemo(() => {
    if (brands.length >= 5) {
      return TextInputState.Default;
    }

    if (brandName.split(',').length + brands.length > 5) {
      return TextInputState.Error;
    }
    return TextInputState.Default;
  }, [brandName, brands]);

  const isDisabled = useMemo(() => {
    return isLoading || isBrandsLoading || brands.length >= 5;
  }, [isLoading, brands, isBrandsLoading]);

  const tooltipProps = intl.formatMessage(
    {
      id: isLoading
        ? I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_LOADING_TOOLTIP
        : I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_ENTER_BRAND_TOOLTIP,
    },
    {
      br: <br />,
    }
  ) as string;

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-row items-center gap-4">
        <Tooltip
          overwrittenTooltipClassnames="w-auto min-w-344 max-w-400"
          theme={Theme.Dark}
          content={tooltipProps}
          disabled={!isDisabled}
        >
          <TextInput
            className="h-32"
            value={brandName}
            inputState={inputState}
            onKeyPress={(e) => {
              const isEnter = e.key === 'Enter';
              isEnter && handleOnCreate();
            }}
            isDirty={!brands.length || !!brandName}
            onChange={onBrandNameInputChange}
            disabled={isDisabled}
            placeholder={intl.formatMessage({
              id: I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_ENTER_BRAND_NAME,
            })}
          />
        </Tooltip>
        {brands.length < 5 && (
          <>
            <Button
              size={ButtonSize.Medium}
              variant={ButtonVariant.Primary}
              svgIcon={CheckmarkIcon}
              iconClassName="text-white"
              state={
                isLoading || inputState === TextInputState.Error
                  ? ButtonState.Disabled
                  : ButtonState.Enabled
              }
              onClick={handleOnCreate}
            />
            <Button
              size={ButtonSize.Medium}
              variant={ButtonVariant.BlackAndWhiteBorder}
              svgIcon={CrossMiniIcon}
              state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
              onClick={() => setBrandName('')}
              iconClassName="text-grey-600"
            />
          </>
        )}
      </div>
      {brands.map((brand) => (
        <BrandName
          key={brand.brandId}
          brand={brand}
          isLoading={isLoading}
          onBrandDelete={deleteBrand}
          onBrandUpdate={updateBrand}
        />
      ))}
    </div>
  );
};
