import uniqBy from 'lodash/uniqBy';
import React from 'react';

import {
  ColumnAlign,
  IconSize,
  PortaledTooltipPlacement,
  ProductSku2,
} from '@teikametrics/tm-design-system';

import { FlywheelTableV2ColumnProps } from '../../../../../../containers/tableV2/types';
import { getChannelIcon } from '../../../../../../lib/clients/utils';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { SkuDetailsFields } from '../../../../../../lib/types/SKUSharedTypes';
import {
  SALES_CHANNEL_TO_MULTI_STATE_ICON_MAP,
  SKU_CATALOG_EXT_ITEM_ID_FILTER_FIELD,
} from '../../../../../products/containers/skuCatalog/types';
import { BusinessPerformanceColumnName } from '../../utils/constants';
import {
  PerformanceTableExtraProps,
  ProductCellData,
  ProductRowData,
} from '../../utils/performance';
import { MAP_SALES_CHANNEL_ID_TO_NAME } from '../../../../../../lib/types/SalesChannels';
import { getAmazonItemPageUrl } from '../../../../../../core/campaignFlexibility/utils';
import { getWalmartItemPageUrl } from '../../../../../../lib/utilities/skuUtils';
import { MerchantCountryCode } from '../../../../../../lib/types/AOSharedTypes';
import { FlywheelSalesChannel } from '../../../../../../lib/types/Fam';

export const getProductItems = (skuDetailsFields: SkuDetailsFields) =>
  uniqBy(
    skuDetailsFields?.extItemDetails || [],
    SKU_CATALOG_EXT_ITEM_ID_FILTER_FIELD
  ).map((item) => ({
    itemId: item.extItemId,
    merchantIcon: {
      size: IconSize.Medium,
      svg: getChannelIcon(item.salesChannelName)!,
    },
  }));

export const RowCellElement: React.FC<ProductCellData> = ({
  skuDetailsFields,
  onViewTrendsSlideoverClick,
  merchantCountryId,
  merchants,
}) => {
  const merchantCountry = merchants?.find(
    (mc) => mc.merchantCountryId === merchantCountryId
  );
  const sku = skuDetailsFields?.sku || '';
  const name = skuDetailsFields?.name || '';
  const productImageUrl = skuDetailsFields?.imageUrl || '';
  const extItemDetails = skuDetailsFields?.extItemDetails?.[0];
  const onAsinClick = () => {
    const url =
      extItemDetails?.salesChannelName === FlywheelSalesChannel.Amazon
        ? getAmazonItemPageUrl(
            merchantCountry?.country as MerchantCountryCode,
            extItemDetails.extItemId
          )
        : getWalmartItemPageUrl(extItemDetails?.extItemId);

    window.open(url);
  };

  return (
    <ProductSku2
      key={extItemDetails?.extItemId}
      dataTestId="business_performance_productSku"
      asin={extItemDetails?.extItemId}
      canExpand={false}
      count={0}
      expanded={false}
      onAsinClick={() => onAsinClick()}
      productImageUrl={productImageUrl}
      productName={name}
      salesChannel={
        MAP_SALES_CHANNEL_ID_TO_NAME[extItemDetails?.salesChannelId || '']
      }
      salesChannelIcon={
        SALES_CHANNEL_TO_MULTI_STATE_ICON_MAP[
          extItemDetails?.salesChannelName || ''
        ].stateIcons['enabled']
      }
      merchantName={merchantCountry?.merchantName}
      merchantCountry={merchantCountry?.country}
      sku={sku}
      useSku={true}
      useAsin={
        MAP_SALES_CHANNEL_ID_TO_NAME[extItemDetails?.salesChannelId || ''] ===
        FlywheelSalesChannel.Amazon
      }
      isProductNameClickable={false}
      productNameTooltipPlacement={PortaledTooltipPlacement.Bottom}
      productNameTooltipPlacementOffset={0}
      className="max-w-460"
      skuLabelClassnames="truncate w-160"
      isProductNameLink={false}
      useTruncatedText
      showTrendIcon
      onTrendIconClick={() =>
        onViewTrendsSlideoverClick?.({
          sku,
          salesChannelId: extItemDetails?.salesChannelId ?? '',
          merchantCountryId: merchantCountryId!,
        })
      }
    />
  );
};

export const productDetails: FlywheelTableV2ColumnProps<
  ProductRowData,
  {},
  PerformanceTableExtraProps
> = {
  header: I18nKey.AI_PRODUCT_SETTINGS_PRODUCT,
  sortable: false,
  size: 340,
  maxSize: 340,
  accessorKey: BusinessPerformanceColumnName.ProductDetails,
  align: ColumnAlign.Left,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <RowCellElement {...original} {...meta} />,
};
