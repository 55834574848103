import { IdToken } from '@auth0/auth0-react';
import ProductDataSyncRequestType from './account/connectProductSyncFormFactory';
import FAMAllSalesChannel from './account/famAllSalesChannel';
import FAMMerchantCountry from './account/famMerchantCountry';
import FAMSalesChannel from './account/famSalesChannelCountry';
import adgroupsAdResponse from './advertisementOptimization/adgroupsAdResponse';
import aoDashboardResponse from './advertisementOptimization/aoDashboardResponse';
import adCatalogByChannel from './products/adCatalogByChannel';
import campaignsAdResponse from './advertisementOptimization/campaignsAdResponse';
import DataAvaialabilityInfoResponse from './advertisementOptimization/dataAvailabilityInfoResponse';
import DataInspectionResponse from './advertisementOptimization/dataInspectionResponse';
import FAMMerchantCountriesResponse from './advertisementOptimization/famMerchantCountriesResponse';
import keywordTargetsResponse from './advertisementOptimization/keywordTargetsResponse';
import { KwaRecommendationResponse } from './advertisementOptimization/kwaRecommendationsResponse';
import { KwaValidDestinationsResponse } from './advertisementOptimization/kwaValidDestinationsResponse';
import AOPortfolioResponse from './advertisementOptimization/portfolioResponse';
import ProductAdsDataResponse from './advertisementOptimization/productAdResponse';
import ProfilesDataResponse from './advertisementOptimization/profileAdLevelDataResponse';
import AOTargetTypeResponse from './advertisementOptimization/targetTypeResponse';
import { CampaignPerformanceResponse } from './compass/campaignPerformanceResponse';
import { GetProductSubscriptionEventResponse } from './compass/getProductSubscriptionEventResponse';
import {
  HomepageCurrentDataApiResponse,
  HomepagePreviousDataApiResponse,
} from './compass/homePageDataApiResponse';
import { MerchantPerformanceResponse } from './compass/merchantPerformanceResponse';
import {
  productPerformanceResponse,
  productPerformanceSummaryResponse,
} from './compass/productPerformanceResponse';
import { SkuPerformanceResponse } from './compass/skuPerformanceResponse';
import IODataAvailabilityResponse from './inventoryOptimization/dataAvailabilityResponse';
import { EventsData as IOProductSettingsEventsDataResponse } from './inventoryOptimization/eventsData';
import SalesChannelDataWithChannelName from './inventoryOptimization/salesChannelDataWithChannelName';
import {
  SkuDetailsV2Data,
  SkuDetailsV2SummaryData,
} from './inventoryOptimization/skuDetailsV2Data';
import AddNewSearchTermsResponse from './marketIntelligence/addNewSearchTermResponseData';
import AddNewSearchTermsStatusResponse from './marketIntelligence/addNewSearchTermsStatusResponseData';
import AddTermsTableRowDataType from './marketIntelligence/addTermsTableRowData';
import AverageProductRankData from './marketIntelligence/averageProductRankData';
import BCSChartsDataResponse from './marketIntelligence/bcsChartData';
import BrandCoverageOnSearchData from './marketIntelligence/brandCoverageOnSearchData';
import MIPublicAPISearchTermsResponse from './marketIntelligence/miPublicAPISearchTermsResponseData';
import MISearchTermsPaginatedResult from './marketIntelligence/miSearchTermsPaginatedResultData';
import PaginatedRequest from './marketIntelligence/paginatedRequestData';
import {
  PlatformDataFactory,
  PlatformDataWithCountriesFactory,
} from './marketIntelligence/platformData';
import ReportingRequestParams from './marketIntelligence/reportingRequestParamsData';
import SearchTermsData from './marketIntelligence/searchTermData';
import UploadSearchTermsStatusResponse from './marketIntelligence/uploadSearchTermsStatusResponseData';
import AIProductSettingSummaryResponse from './aiPowered/aiProductSettingSummaryResponse';
import AIProductSettingResponse from './aiPowered/aiProductSettingsResponse';
import { ViewRecommedationsResponse } from './compass/recommendations/viewRecommedationsResponse';
import SbAdsResponse from './advertisementOptimization/sbAdsResponse';
import { EligibleAdGroupsResponse } from './keywordsOnDemand/eligibleAdGroupsResponse';
import { getBenchmarkDataResponse } from './compass/benchmarkDataResponse';
import { DemandForecastDataResponse } from './inventoryOptimization/demandForecastsDataResponse';
import { OutOfStockGraphDataResponse } from './inventoryOptimization/outOfStockGraphDataResponse';
import { UnsoldInventoryDataResponse } from './inventoryOptimization/unsoldInventoryResponse';
import { OutOfStockTableDataResponse } from './inventoryOptimization/outOfStockTableDataResponse';
import { ActiveInventoryTableDataResponse } from './inventoryOptimization/activeInventoryTableDataResponse';
import { InventoryBreakdownResponse } from './inventoryOptimization/inventoryBreakdownResponse';
import { SellThroughRateAndSalesRatioResponse } from './inventoryOptimization/sellThroughRateAndSalesRatioResponse';
import { OutOfStockTopProductsResponse } from './inventoryOptimization/outOfStockTopProductsResponse';
import {
  RecentUploadsAPIResponse,
  RecentUploadsPaginatedResult,
} from './bulkActions/recentUploadsResponse';
import { MerchantsWithMissingTagCount } from './bacn/merchantsWithMissingTagCount';
import { BrandsAndCompetitors } from './bacn/brandsAndCompetitors';
import { BacnProducts } from './bacn/products';
import TemplatesResponse from './amc/templatesResponse';
import { budgetResponseMerchantTypeResponse } from './budgetPacing/budgetResponseMerchantType';
import { budgetResponseMerchantPageTypeResponse } from './budgetPacing/budgetResponseMerchantPageType';

const id_token: IdToken = {
  __raw: 'Test id token',
  'https://flywheel.teikametrics.com/metadata': {
    business_name: '',
    first_name: '',
    last_name: '',
  },
  nickname: 'dev',
  name: 'dev@teikametrics.com',
  picture:
    'https://s.gravatar.com/avatar/6949178ed32d4d63aa7132c23facfd11?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fsb.png',
  updated_at: '2021-02-04T05:23:47.297Z',
  email: 'dev@teikametrics.com',
  email_verified: true,
  iss: '',
  sub: 'asdsa1',
  aud: '',
  iat: 1231,
  exp: 1212,
  nonce: 'SlZD19ysna,s ',
};

export enum SALES_CHANNEL_IDS {
  WALMART = '820d060b-1d53-4aab-99c2-03a12e3dcf29',
  AMAZON = '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
}

const factories = {
  BCSChartsDataResponse,
  PlatformDataFactory,
  PlatformDataWithCountriesFactory,
  AverageProductRankData,
  BrandCoverageOnSearchData,
  AddNewSearchTermsResponse,
  MIPublicAPISearchTermsResponse,
  SearchTermsData,
  PaginatedRequest,
  MISearchTermsPaginatedResult,
  UploadSearchTermsStatusResponse,
  ReportingRequestParams,
  AddNewSearchTermsStatusResponse,
  AddTermsTableRowDataType,
  id_token,
  ProductDataSyncRequestType,
  DataInspectionResponse,
  FAMMerchantCountriesResponse,
  ProfilesDataResponse,
  FAMMerchantCountry,
  FAMSalesChannel,
  FAMAllSalesChannel,
  AOPortfolioResponse,
  AOTargetTypeResponse,
  DataAvaialabilityInfoResponse,
  SalesChannelDataWithChannelName,
  IODataAvailabilityResponse,
  ProductAdsDataResponse,
  keywordTargetsResponse,
  campaignsAdResponse,
  adgroupsAdResponse,
  aoDashboardResponse,
  adCatalogByChannel,
  HomepageCurrentDataApiResponse,
  HomepagePreviousDataApiResponse,
  GetProductSubscriptionEventResponse,
  MerchantPerformanceResponse,
  CampaignPerformanceResponse,
  productPerformanceResponse,
  productPerformanceSummaryResponse,
  SkuPerformanceResponse,
  AIProductSettingSummaryResponse,
  AIProductSettingResponse,
  KwaRecommendationResponse,
  KwaValidDestinationsResponse,
  ViewRecommedationsResponse,
  SkuDetailsV2Data,
  SkuDetailsV2SummaryData,
  IOProductSettingsEventsDataResponse,
  SbAdsResponse,
  EligibleAdGroupsResponse,
  getBenchmarkDataResponse,
  DemandForecastDataResponse,
  OutOfStockGraphDataResponse,
  UnsoldInventoryDataResponse,
  OutOfStockTableDataResponse,
  ActiveInventoryTableDataResponse,
  InventoryBreakdownResponse,
  SellThroughRateAndSalesRatioResponse,
  OutOfStockTopProductsResponse,
  RecentUploadsAPIResponse,
  RecentUploadsPaginatedResult,
  MerchantsWithMissingTagCount,
  BrandsAndCompetitors,
  BacnProducts,
  TemplatesResponse,
  budgetResponseMerchantTypeResponse,
  budgetResponseMerchantPageTypeResponse,
};

export default factories;
