import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { PROFILES_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = (props) =>
  makeNumericColumn<ProfileApiDataDetails>(
    ({ profilePerformance: { clicks } }) => {
      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        undefined,
        clicks
      );

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const clicksColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.Clicks,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICKS,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
};

export const clicksFilterForProfiles = createNumericDataFieldFilter(
  PROFILES_API_COLUMN_NAME.Clicks,
  I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICKS,
  isValidNumber(0)
);
