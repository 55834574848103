import {
  Badge,
  Button,
  ButtonSize,
  ButtonVariant,
  CrossIcon,
  CrossMiniIcon,
  Icon,
  IconSize,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import ReactModal from 'react-modal';
import {
  MatchType,
  TargetInfo,
} from '../../lib/types/CampaignFlexibilitySharedTypes';
import I18nKey from '../../lib/types/I18nKey';
import {
  MATCH_TYPE_I18N_MAPPER,
  getTargetSegmentPills,
} from '../../modules/campaignFlexibility/components/utils/keywordUtils';
import { AdGroupInfoV2 } from '../../lib/types/KeywordAction';
import { KeywordType } from '../../modules/advertisingOptimization/containers/adsManager/createKeywords/createKeywordsMenu';

interface KeywordsForAdGroupModalProps {
  readonly dataTestId?: string;
  readonly keywordType: KeywordType;
  readonly keywords: TargetInfo[];
  readonly adGroup: AdGroupInfoV2;
  readonly onClose: () => void;
  readonly onDeleteKeyword: (adGroupId: string, targetId?: string) => void;
}

export const KeywordsForAdGroupModal: React.FC<
  KeywordsForAdGroupModalProps
> = ({
  onClose,
  dataTestId = '',
  keywordType,
  keywords,
  adGroup,
  onDeleteKeyword,
}) => {
  const intl = useIntl();

  const [KEYWORDS, TARGETS, MATCH_TYPE, CLOSE] = [
    I18nKey.KEYWORD_RECOMMENDATIONS_VIEW_SKUS_KEYWORDS_SLIDEOUT_KEYWORDS,
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_TARGETS,
    I18nKey.CAMPAIGN_FLEXIBILITY_KEYWORD_RECOMMENDATIONS_TABLE_MATCH_TYPE_HEADER,
    I18nKey.CLOSE,
  ].map((id) => intl.formatMessage({ id }));

  const isProductTargeting =
    keywordType === KeywordType.Product ||
    keywordType === KeywordType.Contextual;

  const getHeader = () => (
    <div className="flex items-center px-12 py-8 border-b border-solid border-grey-200">
      <Button
        size={ButtonSize.Small}
        variant={ButtonVariant.BlackAndWhite}
        svgIcon={CrossIcon}
        onClick={onClose}
        dataTestId={`${dataTestId}_close`}
      />
      <div className="w-full text-center text-base leading-none font-medium text-grey-900">
        <span>{isProductTargeting ? TARGETS : KEYWORDS}</span>
      </div>
    </div>
  );

  const getFooter = () => (
    <div className="mt-auto flex items-center justify-end p-12 box-border border-t border-solid border-grey-200">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.Primary}
        label={CLOSE}
        onClick={onClose}
        dataTestId={`${dataTestId}_close`}
      />
    </div>
  );

  const getBody = () => {
    return (
      <div
        className={classNames(
          'flex flex-col items-start mt-24 gap-12',
          'px-16 py-12'
        )}
        data-test-id={`${dataTestId}_productsAdvertised`}
      >
        <div
          data-test-id={`${dataTestId}_suggestedAdgroup`}
          className={classNames('items-start gap-8 flex flex-row')}
        >
          <div className="flex flex-1 flex-col gap-8 min-w-0">
            <span className={classNames('flex justify-between')}>
              <span className="leading-none text-grey-900 font-normal text-base">
                {adGroup.name}
              </span>
            </span>
            {adGroup.targetSegments?.length > 0 && (
              <>
                <div className="flex flex-auto justify-between items-center leading-none">
                  <div>
                    {getTargetSegmentPills(adGroup.targetSegments)?.map(
                      (badgeProp) => (
                        <Badge
                          {...badgeProp}
                          className={classNames(badgeProp.className)}
                        />
                      )
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className="flex flex-col justify-center self-stretch overflow-y-scroll"
          data-test-id={dataTestId}
        >
          <div className="flex flex-col text-base leading-tight font-normal justify-center self-stretch bg-grey-50 rounded border border-solid border-grey-200">
            <div className="flex flex-row w-screen-full p-12 items-center rounded-t text-grey-600 border-b-1 border-solid border-grey-200">
              <div className="w-6/12">
                <span className="mr-8 ml-16 ">
                  {isProductTargeting ? TARGETS : KEYWORDS}
                </span>
              </div>
              <div className="w-4/12">
                <span className="mr-8 ml-16 ">{MATCH_TYPE}</span>
              </div>
              <div className="w-2/12">
                <span className="mr-8 ml-16 "></span>
              </div>
            </div>
            {keywords.map((tagData: TargetInfo) => {
              return (
                <div className="flex flex-col flex-start self-stretch  rounded bg-white  w-full border-b-1">
                  <div className="flex w-screen-full flex-row text-base leading-tight text-grey-900 font-normal px-12 py-8 items-center rounded  ">
                    <div className="w-6/12">
                      <span className="pr-8 w-410 mr-8 ml-16">
                        {tagData.searchText}
                      </span>
                    </div>
                    <div className="w-4/12">
                      <span className="mr-8 ml-16">
                        {intl.formatMessage({
                          id: MATCH_TYPE_I18N_MAPPER[
                            tagData.matchType as MatchType
                          ],
                        })}
                      </span>
                    </div>
                    <div className="w-2/12">
                      <span className="mr-8 ml-16">
                        <Icon
                          size={IconSize.Small}
                          className="cursor-pointer text-grey-500"
                          svg={CrossMiniIcon}
                          onClick={() =>
                            onDeleteKeyword(adGroup.id, tagData.targetId)
                          }
                          dataTestId={`${dataTestId}_TagRow_Delete`}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <ReactModal
      isOpen
      className={classNames(
        'absolute SlideOutModal top-0 right-0 bottom-0 max-h-full w-1/2 bg-white shadow-2xl',
        'box-border border border-solid border-grey-200 focus:outline-none'
      )}
      overlayClassName="bg-opacity-40 fixed inset-0 z-50 bg-grey-800"
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      preventScroll
    >
      <div className="flex flex-col h-full" data-test-id={`${dataTestId}_open`}>
        {getHeader()}
        {getBody()}
        {getFooter()}
      </div>
    </ReactModal>
  );
};
