import {
  AmazonLogomarkEnabledIcon as AmazonIcon,
  SvgIcon,
  WalmartLogomarkEnabledIcon as WalmartIcon,
} from '@teikametrics/tm-design-system';

import { AdType } from './AOSharedTypes';
import { FlywheelSalesChannel } from './Fam';
import I18nKey from './I18nKey';

export const MONETARY_FRACTION_DIGITS = 2;
export const PERCENTAGE_FRACTION_DIGITS = 1;
export const NO_FRACTION_DIGITS = 0;
export const STANDARD_DATE_FORMAT = 'MM/dd/yyyy';
export const API_REQUEST_RESPONSE_DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_CURRENCY = 'USD';
export const MIN_ACOS_TARGET = 0;
export const MIN_BID_MODIFIER_TARGET = 0.1;

export enum MerchantCountryCode {
  AE = 'AE',
  AU = 'AU',
  BR = 'BR',
  CA = 'CA',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  IN = 'IN',
  IT = 'IT',
  JP = 'JP',
  KSA = 'KSA',
  MX = 'MX',
  NL = 'NL',
  SG = 'SG',
  UK = 'UK',
  US = 'US',
  GB = 'GB',
  SE = 'SE',
  PL = 'PL',
  SA = 'SA',
  BE = 'BE',
}
export const MAP_SALES_CHANNEL_TO_AI_AVAILABILITY_TABLE_ICON: Record<
  FlywheelSalesChannel,
  SvgIcon
> = {
  [FlywheelSalesChannel.Amazon]: AmazonIcon,
  [FlywheelSalesChannel.Walmart]: WalmartIcon,
};
export const HOW_TO_CHANGE_PLAN_LINK = 'https://www.teikametrics.com/pricing/';
export const COMMON_ONLY_DATE_FORMAT = 'LLL dd, yyyy';

export const MAP_AD_TYPE_TO_INTL_NAME: Record<AdType, string> = {
  [AdType.SponsoredProducts]: I18nKey.GENERIC_AD_TYPE_SPONSORED_PRODUCTS,
  [AdType.SponsoredBrands]: I18nKey.GENERIC_AD_TYPE_SPONSORED_BRANDS,
  [AdType.SponsoredBrandsVideo]: I18nKey.GENERIC_AD_TYPE_SPONSORED_BRANDS_VIDEO,
  [AdType.SponsoredDisplay]: I18nKey.GENERIC_AD_TYPE_SPONSORED_DISPLAY,
  [AdType.SearchBrandAmplifier]: I18nKey.GENERIC_AD_TYPE_SEARCH_BRAND_AMPLIFIER,
  [AdType.SponsoredVideos]: I18nKey.GENERIC_AD_TYPE_SPONSORED_VIDEO,
};
