import React from 'react';
import { useIntl } from 'react-intl';

import {
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { AuthorizeProductsDataModalProps } from './';
import classNames from 'classnames';

export const ModalFooter: React.FC<
  Pick<
    AuthorizeProductsDataModalProps,
    'next' | 'goBack' | 'error' | 'isAllMerchantsConnected'
  >
> = ({ next, goBack, error, isAllMerchantsConnected }) => {
  const intl = useIntl();
  const [BACK, CONTINUE] = [
    I18nKey.BACK,
    I18nKey.AD_OPTIMIZER_WIZARD_HEADER_BUTTON_NEXT,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <div
      className={classNames('flex  mx-8', {
        'justify-end': !error,
        'justify-between': error,
      })}
    >
      {error && (
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.BlackAndWhite}
          label={BACK}
          svgIcon={ArrowLeftIcon}
          onClick={goBack}
          dataTestId={`flash1_amazon_initial_connect_back`}
        />
      )}
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.Primary}
        label={CONTINUE}
        onClick={next}
        state={
          isAllMerchantsConnected ? ButtonState.Active : ButtonState.Disabled
        }
      />
    </div>
  );
};
