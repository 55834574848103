import { createContext, useContext, useReducer } from 'react';
import { createRecommendationsApiClient } from '../../lib/clients/RecommendationsApiClient';
import { RecommendationsCountResponse } from '../../lib/types/RecommendationsSharedTypes';
import {
  MerchantCountriesContext,
  MerchantCountriesContextState,
} from '../merchantCountriesProvider/merchantCountriesProvider';
import { getCurrentAccountFromContext } from '../userProvider/selectors';
import { UserContext, UserContextState } from '../userProvider/userProvider';
import { RecommendationsActionTypes, RecommendationsActions } from './actions';
import { recommendationsreducer } from './reducers';
import { SubscriptionContext } from '../subscriptionProvider';
import { useQuery } from '@tanstack/react-query';
import { RecommendationsQuerykeys } from '../../lib/types/ReactQueryKeys';

export interface RecommendationsContextState {
  readonly recommendationsCount: RecommendationsCountResponse[];
  readonly homepageRecommendationsDismissed: boolean;
}

export interface RecommendationsStateContext {
  state: RecommendationsContextState;
  dispatch: React.Dispatch<RecommendationsActions>;
}

export interface RecommendationsProviderProps {
  children: JSX.Element;
}

const initialState: RecommendationsContextState = {
  recommendationsCount: [],
  homepageRecommendationsDismissed: false,
};

const RecommendationsContext = createContext<
  RecommendationsStateContext | undefined
>(undefined);

RecommendationsContext.displayName = 'RecommendationsContext';

const { Provider } = RecommendationsContext;

export const useRecommendationsContext = (): RecommendationsStateContext => {
  const context = useContext(RecommendationsContext);
  if (!context) {
    throw new Error(
      'useRecommendationsContext must be used within a MerchantCountriesProvider'
    );
  }
  return context;
};

const RecommendationsProvider: React.FC<RecommendationsProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(recommendationsreducer, initialState);

  const userContext = useContext<UserContextState>(UserContext);
  const subscriptionContext = useContext(SubscriptionContext);
  const { salesChannelData } = subscriptionContext;

  const account = getCurrentAccountFromContext(userContext)!;
  const merchantCountriesContext = useContext<MerchantCountriesContextState>(
    MerchantCountriesContext
  );
  const recommendationApiClient = createRecommendationsApiClient(
    userContext?.userInfo?.idToken!
  );

  useQuery({
    queryKey: [
      RecommendationsQuerykeys.GetRecommendationsBiddingCount,
      merchantCountriesContext.merchantCountries,
      salesChannelData,
      account.id,
    ],
    queryFn: () => {
      const aiEnabledMerchants = salesChannelData
        .flatMap((salesChannel) => salesChannel.items)
        .filter((item) => item.aiEnabled)
        .map((item) => item.id);

      dispatch({
        type: RecommendationsActionTypes.RESET_STATE,
        payload: initialState,
      });

      const recommendationsCountData = recommendationApiClient
        .recommendationAutomatedBiddingCount(
          account.id,
          aiEnabledMerchants || []
        )
        .then((data) => {
          dispatch({
            type: RecommendationsActionTypes.SET_RECOMMENDATIONS_COUNT,
            payload: data,
          });
        });
      return recommendationsCountData;
    },
    enabled: !!(
      userContext.userInfo.idToken &&
      merchantCountriesContext.merchantCountries?.length &&
      salesChannelData.length &&
      account.id
    ),
  });

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

RecommendationsProvider.displayName = 'RecommendationsProvider';
export { RecommendationsContext, RecommendationsProvider };
