import { ColumnManagerOption } from '@teikametrics/tm-design-system';

export const CUSTOM_INSIGHTS_PRODUCTS_TABLE_ID =
  'CUSTOM_INSIGHTS_PRODUCTS_TABLE_ID';

export enum CustomInsightProductColumns {
  Product = 'name',
  RateOfSale = 'rateOfSale',
  Deviation = 'rateOfSaleChangePercent',
  ChangeInUnitSoldPerDay = 'rateOfSaleChangeValue',
  TotalSales = 'sales',
  UnitsSold = 'unitsSold',
  PriorRateOfSale = 'priorRateOfSale',
  PriorTotalSales = 'priorSales',
  PriorUnitsSold = 'priorUnitsSold',
  CurrentInventoryLevel = 'inventoryQuantity',
  HealthyInventoryLevel = 'healthyInventoryLevel',
  SellThroughRate = 'sellThroughRate',
  RecommendedReplenishmentQuantity = 'replenishQuantity',
  ProjectedStockoutDate = 'daysOfInventory',
  ProjectedDaysToStockout = 'daysOfInventoryUnit',
  AdSales = 'adSales',
  Acos = 'acos',
  Tacos = 'tacos',
  EstGrossMargin = 'estGrossMargin',
  Clicks = 'clicks',
  PriorAdSales = 'priorAdSales',
  PriorAcos = 'priorAcos',
  PriorTacos = 'priorTacos',
  PriorEstGrossMargin = 'priorEstGrossMargin',
  PriorClicks = 'priorClicks',
  CampaignCount = 'isActiveCampaigns',
}

export enum MetricQueryOperator {
  cb = 'cb',
  gt = 'gt',
  lt = 'lt',
  bt = 'bt',
  eq = 'eq',
  gte = 'gte',
  lte = 'lte',
}

export interface ColumnManagerOptionForCustomInsightsTable
  extends ColumnManagerOption {
  readonly isSelected?: boolean;
  readonly value: CustomInsightProductColumns;
}

export const MIRROR_COLUMNS: CustomInsightProductColumns[] = [
  CustomInsightProductColumns.RateOfSale,
  CustomInsightProductColumns.TotalSales,
  CustomInsightProductColumns.AdSales,
  CustomInsightProductColumns.Acos,
  CustomInsightProductColumns.Tacos,
  CustomInsightProductColumns.UnitsSold,
  CustomInsightProductColumns.EstGrossMargin,
  CustomInsightProductColumns.Clicks,
];

export const CustomInsightMirrorColumns: Partial<
  Record<CustomInsightProductColumns, CustomInsightProductColumns>
> = {
  [CustomInsightProductColumns.RateOfSale]:
    CustomInsightProductColumns.PriorRateOfSale,
  [CustomInsightProductColumns.TotalSales]:
    CustomInsightProductColumns.PriorTotalSales,
  [CustomInsightProductColumns.AdSales]:
    CustomInsightProductColumns.PriorAdSales,
  [CustomInsightProductColumns.Acos]: CustomInsightProductColumns.PriorAcos,
  [CustomInsightProductColumns.Tacos]: CustomInsightProductColumns.PriorTacos,
  [CustomInsightProductColumns.UnitsSold]:
    CustomInsightProductColumns.PriorUnitsSold,
  [CustomInsightProductColumns.EstGrossMargin]:
    CustomInsightProductColumns.PriorEstGrossMargin,
  [CustomInsightProductColumns.Clicks]: CustomInsightProductColumns.PriorClicks,
};
export const LOCAL_STORAGE_KEY_FOR_UPDATED_AT_DATE =
  'customInsightsLastUpdatedAt';
