import {
  ButtonState,
  CheckboxCheckedState,
  CheckboxLabel,
  CheckboxState,
  CrossMiniIcon,
  Icon,
  IconSize,
  Modal,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import I18nKey from '../../../lib/types/I18nKey';
import React, { useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useIntl } from 'react-intl';
import { HomepageResponseMetricKeys } from '../../../lib/types/CompassSharedTypes';
import {
  hasExactlyMaxCheckedItems,
  oneToMaxMetricsChecked,
} from '../containers/compassDashboard/businessGraphV2/utils';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../lib/utilities/intercom';

export interface MetricsData {
  readonly label: string;
  readonly metricsId: HomepageResponseMetricKeys;
  readonly isChecked: boolean;
}

export interface OwnProps {
  readonly showModal: boolean;
  readonly onClose: () => void;
  readonly allMetricsData: MetricsData[];
  readonly onMetricUpdate: (metrics: MetricsData[]) => void;
}

export const CustomizeMetricsSlideover: React.FC<OwnProps> = ({
  showModal,
  onClose,
  allMetricsData,
  onMetricUpdate,
}) => {
  const intl = useIntl();
  const [metricsData, setMetricsData] = useState<MetricsData[]>(allMetricsData);
  const isFiveMetricChecked = useMemo(
    () => hasExactlyMaxCheckedItems(metricsData),
    [metricsData]
  );
  const atleastOneToFiveMetricsChecked = useMemo(
    () => oneToMaxMetricsChecked(metricsData),
    [metricsData]
  );

  const onMetricClick = (id: string) => {
    const newMetrics = metricsData.map((metric) => ({
      ...metric,
      isChecked: id === metric.metricsId ? !metric.isChecked : metric.isChecked,
    }));
    setMetricsData([...newMetrics]);
  };

  useEffect(() => {
    if (showModal) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [showModal]);

  return (
    <Modal
      showModal={showModal}
      className="flex flex-col w-512 min-w-512"
      slideOutModal={true}
      showOVerlay={true}
      formActionRowClassName="sticky border-t-1 border-grey-200 border-solid py-12 absolute bottom-0 px-12 bg-white"
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      hidePadding={true}
      mainAction={{
        label: I18nKey.GENERIC_APPLY,
        onClick: () => {
          onMetricUpdate(metricsData);
        },
        state:
          atleastOneToFiveMetricsChecked &&
          !isEqual(metricsData, allMetricsData)
            ? ButtonState.Enabled
            : ButtonState.Disabled,
      }}
      secondaryAction={{
        label: I18nKey.GENERIC_CANCEL_LABEL,
        onClick: onClose,
      }}
      dataTestId="customize_metrics_slideover"
    >
      <div
        className={classNames(
          'p-16 flex flex-row items-center',
          'border-solid border-b border-grey-200'
        )}
      >
        <div className="hover: cursor-pointer text-grey-600">
          <Icon size={IconSize.Small} svg={CrossMiniIcon} onClick={onClose} />
        </div>
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.none}
          weight={TypographyWeight.medium}
          className="w-full text-center"
        >
          {intl.formatMessage({
            id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_HEADER,
          })}
        </Typography>
      </div>
      <div className="flex flex-col h-screen p-24">
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.none}
          className="mb-24"
        >
          {intl.formatMessage({
            id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_SELECT_5_LABEL,
          })}
        </Typography>
        <div className="flex flex-col gap-y-8">
          {metricsData?.map((metric) => (
            <CheckboxLabel
              label={metric.label}
              checked={
                metric.isChecked
                  ? CheckboxCheckedState.Checked
                  : CheckboxCheckedState.Unchecked
              }
              onClick={() => onMetricClick(metric.metricsId)}
              state={
                isFiveMetricChecked && !metric.isChecked
                  ? CheckboxState.Disabled
                  : CheckboxState.Default
              }
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};
CustomizeMetricsSlideover.displayName = 'CompassCustomizeMetricsSlideover';
