import { useContext, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import first from 'lodash/first';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTruncatedTextLinkColumn } from '../../../../../containers/table/utils/makeTableCells';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../../containers/userProvider/userProvider';
import { AdGroupDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ViewTrendLink } from '../../../components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../components/ViewTrends';
import { getAdManagerUrl, getAdsLevelUri } from '../../../utils';
import {
  getAdType,
  getMerchantCountries,
  getSalesChannel,
} from '../adItemsTableColumns/utils';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
  TableDataAdsManager,
} from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  getSelectedMerchantCountryId,
} from '../utils';
import { getAdsManagerDetailsQueryParams } from '../utils/queryParamsUtils';
import { getSalesChannelFromLocalStorage } from '../utils/storageUtils';

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  adGroupId,
  adGroupDetails,
  channelSettings,
  flywheelSettings,
  adGroupPerformance,
  pendingFields,
  adTypes,
  merchantType,
  selectedMerchantCountries,
  allMerchants,
}) => {
  const data: AdGroupDetails = {
    adGroupId,
    adGroupDetails,
    channelSettings,
    flywheelSettings,
    adGroupPerformance,
    pendingFields,
  };

  const location = useLocation();
  const baseUrl = useMemo(
    () => getAdManagerUrl(location.pathname),
    [location.pathname]
  );

  const searchQueryParams = new URLSearchParams(
    decodeURIComponent(location.search)
  );

  const userContext = useContext(UserContext);

  const userId = userContext.userInfo.userDetails?.id || '';
  const accountId = getCurrentAccountFromContext(userContext)?.id || '';

  const salesChannelId = searchQueryParams.get(SALES_CHANNEL_ID);
  const merchantCountryIds = searchQueryParams.get(MERCHANT_COUNTRY_IDS);
  const adTypesFromQueryParams = searchQueryParams.get(AD_TYPE);

  const selectedSalesChannel = getSalesChannelFromLocalStorage(
    userId || '',
    accountId || ''
  );
  const selectedMerchantCountryId = getSelectedMerchantCountryId(
    selectedMerchantCountries
  );

  return makeTruncatedTextLinkColumn(
    ({ adGroupDetails: details }: AdGroupDetails) => details?.campaignName,
    ({ adGroupDetails: details }: AdGroupDetails) =>
      details?.campaignId !== undefined
        ? getAdsLevelUri(
            baseUrl,
            ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns,
            details?.campaignId
          ) +
          getAdsManagerDetailsQueryParams(
            getSalesChannel(salesChannelId, selectedSalesChannel),
            getMerchantCountries(
              merchantCountryIds,
              userId,
              accountId,
              salesChannelId,
              selectedSalesChannel
            ),
            getAdType(adTypesFromQueryParams, adTypes)
          )
        : '',
    data?.pendingFields &&
      data?.pendingFields.includes(ADGROUPS_API_COLUMN_NAME.CampaignName),
    <ViewTrendLink
      dataTestId="ad_groups_campaign_name"
      campaignId={adGroupDetails.campaignId}
      merchantCountryId={selectedMerchantCountryId}
      entityId={adGroupDetails.campaignId}
      entityType={ViewTrendsEntityType.CAMPAIGN}
      adType={first(adTypes)}
      merchantType={merchantType}
      campaignName={adGroupDetails.campaignName}
      entityName={adGroupDetails.campaignName}
      merchantCountryName={
        allMerchants.find(
          (merchant) => merchant.merchantCountryId === selectedMerchantCountryId
        )?.merchantName
      }
      salesChannelId={selectedSalesChannel as string}
      targetingType={adGroupDetails.campaignTargetingType}
    />,
    false
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignNameColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.CampaignName,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_NAME,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const campaignNameFilter = createStringDataFieldFilter(
  ADGROUPS_API_COLUMN_NAME.CampaignName,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME,
  isValidString()
);
