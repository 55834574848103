import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

/* eslint-disable max-lines */
import { IdToken } from '@auth0/auth0-react';
import {
  BreadCrumbLink,
  BreadCrumbs,
  Loader,
  Spinner,
} from '@teikametrics/tm-design-system';

import LogRocket from 'logrocket';
import {
  Location,
  NavigateFunction,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { BidConstraintsProvider } from '../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { useDataSyncInfoContext } from '../../containers/dataSyncInfoProvider/dataSyncInfoProvider';
import { MerchantCountriesContext } from '../../containers/merchantCountriesProvider/merchantCountriesProvider';
import { SalesChannelContext } from '../../containers/salesChannelProvider';
import { SubscriptionContext } from '../../containers/subscriptionProvider';
import tableReducer, {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_TABLE_CHANGES,
  DEFAULT_VISIBLE_PAGE,
} from '../../containers/table/ducks';
import { getItemsPerPageByTableIdFromStorage } from '../../containers/tableV2/utils';
import {
  getCurrentAccountFromContext,
  isProductSubscribed,
} from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { createAOApiClient } from '../../lib/clients/AOApiClient';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { DEFAULT_PAGE_SIZE } from '../../lib/types';
import {
  AdLevel,
  AddedBy,
  CampaignStatus,
  DataSyncInfoResponse,
  FlywheelSalesChannel,
  MerchantSyncDate,
  PRODUCT_SUBSCRIPTIONS,
  RedirectStateParams,
} from '../../lib/types/AOSharedTypes';
import {
  AccountSalesChannelPaginatedResult,
  AllSalesChannel,
  ConnectionStatus,
  FAMConnectionStatus,
  MerchantCountry,
  MerchantsBySalesChannel,
  ProductConnectionStatus,
  SalesChannelData,
} from '../../lib/types/Fam';
import { Filter } from '../../lib/types/Filter';
import I18nKey from '../../lib/types/I18nKey';
import { KeywordActionColumns } from '../../lib/types/KeywordAction';
import { SortOrder } from '../../lib/types/Sort';
import { getDataAvailability } from '../../lib/utilities/connectionStatus';
import { equalToFilter, inFilter } from '../../lib/utilities/filter';
import { NavPaths } from '../../NavPaths';
import {
  KEYWORD_RECOMMENDATIONS_TABLE_V2_ID,
  NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V2_ID,
  NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V3_ID,
} from '../keywordAction/components/RecommendationsTableV2/types';
import {
  KEYWORD_RECOMMENDATIONS_TABLE_V3_ID,
  KEYWORD_RECOMMENDATIONS_TABLE_V3_ID_BY_CHANNEL,
} from '../keywordAction/components/RecommendationsTableV3/types';
import {
  getKeywordsToAddFiltersFromLocalStorage,
  getKeywordsToNegateFiltersFromLocalStorage,
} from '../products/containers/utils/localStorage';
import AdsManager from './containers/adsManager';
import adsManagerReducer from './containers/adsManager/ducks/reducers';
import {
  ADS_MANAGER_ADGROUP_TABLE_ID,
  ADS_MANAGER_AD_ITEMS_TABLE_ID,
  ADS_MANAGER_CAMPAIGNS_TABLE_ID,
  ADS_MANAGER_PROFILES_TABLE_ID,
  ADS_MANAGER_SB_ADS_TABLE_ID,
  ADS_MANAGER_SV_ADS_TABLE_ID,
  ADS_MANAGER_TARGETS_TABLE_ID,
  EST_AD_GROSS_MARGIN_TABLE_ADS_ID,
} from './containers/adsManager/ducks/types';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  ADS_MANAGER_DASHBOARD,
  AD_LEVEL_PAGE_URI_TO_PAGE_URL_MAPPER,
  AD_LEVEL_TO_ADS_MANAGER_PAGE_URI_MAPPER,
  DEFAULT_TABLE_MODE,
} from './containers/adsManager/types';
import {
  ADGROUPS_API_COLUMN_NAME,
  AdLevelFromPathInfo,
  CAMPAIGNS_API_COLUMN_NAME,
  PRODUCT_ADS_API_COLUMN_NAME,
  PROFILES_API_COLUMN_NAME,
  TARGETS_API_COLUMN_NAME,
  getAdLevelInfo,
  getCampaignStatusFromUrl,
  getDropdownValuesFromPathname,
  getPreviousUrl,
  getProductAdStatusFromUrl,
  getSKUFromUrl,
  getSortingParamsFromUrl,
} from './containers/adsManager/utils';
import {
  getDetailsTargetAdLevelFromLocalStorage,
  getFiltersFromBrowserStorage,
  getMaxRowsPerPageFromStorage,
  getSalesChannelFromLocalStorage,
} from './containers/adsManager/utils/storageUtils';
import { AO_MULTI_CHANNEL_DASHBOARD_TABLE_ID } from './containers/dashboard/consts';
import { MultiChannelDashboardColumns } from './containers/dashboard/types';
import { MerchantProvider } from './merchantsProvider';
import {
  AdOptimizationAction,
  AdOptimizationExtraArgs,
  AdOptimizationState,
} from './redux/types';
import {
  generateUrlFromLocalStorageData,
  getUrlBySyncedSalesChannels,
  isIdentifiedAdlevelOneOfTargets,
} from './utils';

interface SalesChannelDataWithChannelName extends SalesChannelData {
  readonly salesChannelName: string;
}

interface AccountSalesChannelPaginatedResultWithError
  extends AccountSalesChannelPaginatedResult {
  readonly isError?: boolean;
  readonly salesChannel?: string;
}

interface AccountSalesChannelPaginatedResultWithChannelName
  extends AccountSalesChannelPaginatedResult {
  readonly isError?: boolean;
  items: SalesChannelDataWithChannelName[];
}

export interface AppHeaderTitleProps {
  campaignHeader?: string;
  adGroupHeader?: string;
}

export enum AOConnectionBanner {
  NoConnection = 'NoConnection',
  ConnectionInProgress = 'ConnectionInProgress',
  ConnectionSuccessful = 'ConnectionSuccessful',
}

const breadCrumbRedirect = ({
  location,
  breadCrumbCandidates,
  currentPageIndex,
  navigate,
  isAdLevelPageFlag,
  urlSalesChannel,
  urlAdType,
  adLevelBase,
  adLevelForBreadcrumb,
  isOldNavigation = true,
}: {
  location: Location;
  breadCrumbCandidates: string[];
  currentPageIndex: number;
  navigate: NavigateFunction;
  isAdLevelPageFlag: boolean;
  urlSalesChannel: string;
  urlAdType: string;
  adLevelBase: string;
  adLevelForBreadcrumb: AdLevel | '';
  isOldNavigation: boolean;
}) => {
  const adLevelUriToken = adLevelForBreadcrumb
    ? AD_LEVEL_TO_ADS_MANAGER_PAGE_URI_MAPPER[adLevelForBreadcrumb]
    : adLevelBase;

  const redirectIndex = breadCrumbCandidates.length - currentPageIndex - 1;
  // TODO: cleanup logic when we permanently move to new AppNav
  if (isAdLevelPageFlag) {
    navigate(
      `${ADS_MANAGER_DASHBOARD}${urlSalesChannel}/${urlAdType}/${adLevelUriToken}/`
    );
  } else if (!isOldNavigation || redirectIndex !== 0) {
    const previousUrl = getPreviousUrl(location.pathname) + location.search;
    navigate(previousUrl);
  }
};

export const getAppHeader = (
  appHeaderTitle: AppHeaderTitleProps,
  adLevel: string,
  currentPageIndex: number
) => {
  let appHeader = appHeaderTitle.campaignHeader;
  if (
    (adLevel === ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns &&
      currentPageIndex === 2) ||
    (adLevel === ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups &&
      currentPageIndex === 1)
  ) {
    appHeader = appHeaderTitle.adGroupHeader;
  }
  return appHeader;
};

export const getPageTitle = (
  adLevelInfo: AdLevelFromPathInfo,
  appHeaderTitle: AppHeaderTitleProps
) => {
  if (adLevelInfo?.adsManagerDetailsPageFlag) {
    if (adLevelInfo?.identifiedAdLevel === AdLevel.Campaigns) {
      return appHeaderTitle.campaignHeader;
    } else {
      if (adLevelInfo?.identifiedAdLevel === AdLevel.AdGroups) {
        return appHeaderTitle.adGroupHeader;
      } else {
        return <FormattedMessage id={I18nKey.ADVERTISING_OPTIMIZATION_TITLE} />;
      }
    }
  } else {
    return <FormattedMessage id={I18nKey.ADVERTISING_OPTIMIZATION_TITLE} />;
  }
};

const createBreadCrumbLinks = (
  location: Location,
  navigate: NavigateFunction,
  appHeaderTitle: AppHeaderTitleProps,
  adLevelForBreadcrumb: AdLevel | '',
  isOldNavigation: boolean = true
) => {
  const AO_ADS_MANAGER_FIRST_LEVEL_VIEW_URL_TEMPLATE =
    '/ads-optimization/ads-manager/{sales-channel}/{ad-type}';

  const urlParams = location.pathname.split(ADS_MANAGER_DASHBOARD);

  const relevantUrlParts = urlParams[urlParams.length - 1].split('/');

  const [salesChannelFromUrl, adTypeFromUrl, adLevelFromUrl] = relevantUrlParts;

  const fromIndex =
    AO_ADS_MANAGER_FIRST_LEVEL_VIEW_URL_TEMPLATE.split('/').length -
    NavPaths.AOAdsManager.split('/').length;

  const breadCrumbCandidates = relevantUrlParts.slice(fromIndex);
  const adLevels = Object.keys(AD_LEVEL_PAGE_URI_TO_PAGE_URL_MAPPER);

  if (!isOldNavigation) {
    breadCrumbCandidates.pop();
  }

  return breadCrumbCandidates.map(
    (breadCrumbCandidate: string, index: number) => {
      const isAdLevelPageFlag = adLevels.includes(breadCrumbCandidate);
      return (
        <BreadCrumbLink
          onClick={(e) => {
            e.preventDefault();
            breadCrumbRedirect({
              location,
              breadCrumbCandidates,
              currentPageIndex: index,
              navigate,
              isAdLevelPageFlag,
              urlSalesChannel: salesChannelFromUrl,
              urlAdType: adTypeFromUrl,
              adLevelBase: adLevelFromUrl,
              adLevelForBreadcrumb,
              isOldNavigation,
            });
          }}
          textLabel={
            isAdLevelPageFlag ? (
              <FormattedMessage id={I18nKey.ADVERTISING_OPTIMIZATION_TITLE} />
            ) : (
              getAppHeader(appHeaderTitle, adLevelFromUrl, index)
            )
          }
          key={index}
          dataTestId={`ao_${appHeaderTitle.adGroupHeader}`}
        />
      );
    }
  );
};

interface OwnProps {
  readonly idToken: IdToken | null;
}

export const getSalesChannelDataWithChannelName = (
  salesChannelData: AccountSalesChannelPaginatedResultWithError[]
) => {
  const merchantsWithSalesChannel = salesChannelData.reduce<
    SalesChannelDataWithChannelName[]
  >((prevValue, { items, salesChannel }) => {
    prevValue.push(
      ...items.map((item): SalesChannelDataWithChannelName => {
        return {
          ...item,
          salesChannelName: salesChannel ?? '',
        };
      })
    );

    return prevValue;
  }, []);

  const salesChannelDataWithChannelName: AccountSalesChannelPaginatedResultWithChannelName =
    {
      items: merchantsWithSalesChannel,
      totalItems: merchantsWithSalesChannel.length,
      isError: false,
    };

  return salesChannelDataWithChannelName;
};

const AO_DEFAULT_ITEMS_PER_PAGE = 100;

export const reducers = combineReducers<
  AdOptimizationState,
  AdOptimizationAction
>({
  tableState: tableReducer,
  adsManagerState: adsManagerReducer,
});

export const getItemsPerPage = (itemsPerPageFromStorage: string | null) =>
  isNil(itemsPerPageFromStorage)
    ? AO_DEFAULT_ITEMS_PER_PAGE
    : Number(itemsPerPageFromStorage);

export const getCurrentAdLevelForTarget = (
  adLevelInfo: AdLevelFromPathInfo,
  targetDetailsAdLevelInfoOnLocalStorage: string | null
) => {
  if (adLevelInfo.adsManagerDetailsPageFlag) {
    return targetDetailsAdLevelInfoOnLocalStorage;
  } else if (isIdentifiedAdlevelOneOfTargets(adLevelInfo.identifiedAdLevel)) {
    return adLevelInfo.identifiedAdLevel;
  } else {
    return AdLevel.KeywordTargets;
  }
};

export const getUserId = (userContext: UserContextState) =>
  userContext.userInfo.userDetails?.id || '';

/* eslint max-statements: [2, 100] */
const AdvertisingOptimization: React.FC<OwnProps> = (props) => {
  const aoApiClient = createAOApiClient(props.idToken);
  const famApiClient = createFAMApiClient(props.idToken!);

  const userContext = useContext<UserContextState>(UserContext);

  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const urlPathNameList = location.pathname.split('/');
  const adLevelInfo = getAdLevelInfo(urlPathNameList);

  const { salesChannelFromPathname, adTypeFromPathname } =
    getDropdownValuesFromPathname(location.pathname);

  const {
    sortBy,
    sortOrder,
    campaignLiveFilter,
    salesChannelName,
    addedByFilter,
  } = getSortingParamsFromUrl(location.search);

  const params = new URLSearchParams(location.search);

  const sku = getSKUFromUrl(location.search);
  const campaignStatus = getCampaignStatusFromUrl(location.search);
  const productAdStatus = getProductAdStatusFromUrl(location.search);

  const redirectProps = location?.state as RedirectStateParams;

  const defaultFlywheelSettingsFilter: Filter[] =
    getDefaultFiltersFromRedirectProps(redirectProps);

  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose;

  const [merchantsBySalesChannel, setMerchantsBySalesChannel] = useState<
    MerchantsBySalesChannel[]
  >([]);

  const thunkExtraArgs: AdOptimizationExtraArgs = {
    aoApiClient,
    window,
  };

  const enhancer = composeEnhancers(
    applyMiddleware<
      ThunkDispatch<
        AdOptimizationState,
        AdOptimizationExtraArgs,
        AdOptimizationAction
      >
    >(thunk.withExtraArgument(thunkExtraArgs), LogRocket.reduxMiddleware())
  );

  const userId = getUserId(userContext);
  const maybeAccount = getCurrentAccountFromContext(userContext);
  const accountId = maybeAccount?.id || '';

  const itemsPerPageFromStorage = getMaxRowsPerPageFromStorage(userId);
  const itemsPerPageFromStorageForAIKeywords =
    getItemsPerPageByTableIdFromStorage({
      userId,
      tableId: KEYWORD_RECOMMENDATIONS_TABLE_V2_ID,
      defaultPageSize: DEFAULT_PAGE_SIZE,
    });

  const selectedSalesChannelId = getSalesChannelFromLocalStorage(
    userId,
    accountId
  );

  const itemsPerPage = getItemsPerPage(itemsPerPageFromStorage);

  const filters = (): Filter[] => {
    if (campaignLiveFilter) {
      return [
        inFilter(CAMPAIGNS_API_COLUMN_NAME.CampaignStatus, [
          salesChannelName === FlywheelSalesChannel.Amazon
            ? CampaignStatus.Enabled
            : CampaignStatus.Live,
        ]),
      ];
    }

    if (
      addedByFilter &&
      [AddedBy.External, AddedBy.FlywheelOnDemand].includes(
        addedByFilter as AddedBy
      )
    ) {
      return [inFilter(CAMPAIGNS_API_COLUMN_NAME.AddedBy, [addedByFilter])];
    }

    if (isEmpty(defaultFlywheelSettingsFilter)) {
      return getFiltersFromBrowserStorage(
        userId,
        accountId,
        AdLevel.Campaigns,
        adTypeFromPathname,
        salesChannelFromPathname!,
        selectedSalesChannelId || '',
        adLevelInfo.currentAdLevelId
      );
    }

    return defaultFlywheelSettingsFilter;
  };

  const adsManagerCampaignsTableInitialState = {
    filters: filters(),
    sorts: [
      {
        column: sortBy ?? CAMPAIGNS_API_COLUMN_NAME.AdSpend,
        direction: (sortOrder as SortOrder) ?? SortOrder.Desc,
      },
    ],
    pages: [],
    itemsPerPage,
    visiblePage: DEFAULT_VISIBLE_PAGE,
    changes: {
      ...DEFAULT_TABLE_CHANGES,
      global: {
        currencyCode: {},
        dailyBudget: {},
        totalBudget: {},
        endDate: {},
        startDate: {},
      },
    },
  };

  const adsManagerAdgroupTableInitialState = {
    filters: isEmpty(defaultFlywheelSettingsFilter)
      ? getFiltersFromBrowserStorage(
          userId,
          accountId,
          AdLevel.AdGroups,
          adTypeFromPathname,
          salesChannelFromPathname!,
          selectedSalesChannelId || '',
          adLevelInfo.currentAdLevelId
        )
      : defaultFlywheelSettingsFilter,
    sorts: [
      {
        column: ADGROUPS_API_COLUMN_NAME.AdSpend,
        direction: SortOrder.Desc,
      },
    ],
    pages: [],
    itemsPerPage,
    visiblePage: DEFAULT_VISIBLE_PAGE,
    changes: {
      ...DEFAULT_TABLE_CHANGES,
      global: { campaignTargetingType: {}, currencyCode: {} },
    },
  };

  const campaignName = params.get('campaignName');

  const getProductAdsFilters = () => {
    if (campaignName) {
      return [
        equalToFilter(TARGETS_API_COLUMN_NAME.CampaignName, campaignName),
      ];
    }
    if (sku && campaignStatus && productAdStatus) {
      return [
        equalToFilter(PRODUCT_ADS_API_COLUMN_NAME.Sku, sku),
        inFilter(PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus, [
          campaignStatus as CampaignStatus,
        ]),
        inFilter(PRODUCT_ADS_API_COLUMN_NAME.Status, [productAdStatus]),
      ];
    }

    return getFiltersFromBrowserStorage(
      userId,
      accountId,
      AdLevel.ProductAds,
      adTypeFromPathname,
      salesChannelFromPathname!,
      selectedSalesChannelId || '',
      adLevelInfo.currentAdLevelId
    );
  };

  const getTargetAdsFilters = () => {
    if (campaignName) {
      return [
        equalToFilter(TARGETS_API_COLUMN_NAME.CampaignName, campaignName),
      ];
    }

    return getFiltersFromBrowserStorage(
      userId,
      accountId,
      currentAdLevelForTarget
        ? (currentAdLevelForTarget as AdLevel)
        : AdLevel.KeywordTargets,
      adTypeFromPathname,
      salesChannelFromPathname!,
      selectedSalesChannelId || '',
      adLevelInfo.currentAdLevelId
    );
  };

  const adsManagerProductAdsTableInitialState = {
    filters: getProductAdsFilters(),
    sorts: [
      {
        column: PRODUCT_ADS_API_COLUMN_NAME.AdSpend,
        direction: SortOrder.Desc,
      },
    ],
    pages: [],
    itemsPerPage,
    visiblePage: DEFAULT_VISIBLE_PAGE,
    changes: { ...DEFAULT_TABLE_CHANGES, global: { currencyCode: {} } },
  };

  const currentAdLevelForTarget = getCurrentAdLevelForTarget(
    adLevelInfo,
    getDetailsTargetAdLevelFromLocalStorage(
      userId,
      accountId,
      selectedSalesChannelId || ''
    )
  );

  const adsManagerTargetsTableInitialState = {
    filters: getTargetAdsFilters(),
    sorts: [
      {
        column: TARGETS_API_COLUMN_NAME.AdSpend,
        direction: SortOrder.Desc,
      },
    ],
    pages: [],
    itemsPerPage,
    visiblePage: DEFAULT_VISIBLE_PAGE,
    changes: DEFAULT_TABLE_CHANGES,
  };

  const adsManagerProfileTableInitialState = {
    filters: getFiltersFromBrowserStorage(
      userId,
      accountId,
      AdLevel.Profiles,
      adTypeFromPathname,
      salesChannelFromPathname!,
      selectedSalesChannelId || '',
      adLevelInfo.currentAdLevelId
    ),
    sorts: [
      {
        column: PROFILES_API_COLUMN_NAME.AdSpend,
        direction: SortOrder.Desc,
      },
    ],
    pages: [],
    itemsPerPage,
    visiblePage: DEFAULT_VISIBLE_PAGE,
    changes: DEFAULT_TABLE_CHANGES,
  };

  const adsManagerSbAdsTableInitialState = {
    filters: getFiltersFromBrowserStorage(
      userId,
      accountId,
      AdLevel.SbAds,
      adTypeFromPathname,
      salesChannelFromPathname!,
      selectedSalesChannelId || '',
      adLevelInfo.currentAdLevelId
    ),
    sorts: [
      {
        column: PROFILES_API_COLUMN_NAME.AdSpend,
        direction: SortOrder.Desc,
      },
    ],
    pages: [],
    itemsPerPage,
    visiblePage: DEFAULT_VISIBLE_PAGE,
    changes: DEFAULT_TABLE_CHANGES,
  };
  const initialRecommendationsTableV2State = {
    filters: getKeywordsToAddFiltersFromLocalStorage({
      userId,
      accountId,
    }),
    sorts: [
      {
        column: KeywordActionColumns.AD_CONVERSIONS,
        direction: SortOrder.Desc,
      },
    ],
    pages: [],
    visiblePage: 1,
    itemsPerPage: itemsPerPageFromStorageForAIKeywords,
    changes: DEFAULT_TABLE_CHANGES,
    bufferItems: [],
    bufferItemsToLoad: itemsPerPageFromStorageForAIKeywords,
    toggleBufferItemsChanged: false,
  };
  const initialNegativeRecommendationsTableV2State = {
    filters: getKeywordsToNegateFiltersFromLocalStorage({
      userId,
      accountId,
    }),
    sorts: [
      {
        column: KeywordActionColumns.AD_SPEND,
        direction: SortOrder.Desc,
      },
    ],
    pages: [],
    visiblePage: 1,
    itemsPerPage: itemsPerPageFromStorageForAIKeywords,
    changes: DEFAULT_TABLE_CHANGES,
    bufferItems: [],
    bufferItemsToLoad: itemsPerPageFromStorageForAIKeywords,
    toggleBufferItemsChanged: false,
  };

  const initialAoMultiChannelDashboardTableState = {
    filters: [],
    sorts: [
      {
        column: MultiChannelDashboardColumns.AdSales,
        direction: SortOrder.Desc,
      },
    ],
    pages: [],
    visiblePage: 1,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    changes: DEFAULT_TABLE_CHANGES,
  };

  const estPreAdGrossMarginInitialState = {
    filters: [],
    sorts: [],
    pages: [],
    visiblePage: 1,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    changes: DEFAULT_TABLE_CHANGES,
  };

  const svAdsInitialState = {
    filters: [],
    sorts: [],
    pages: [],
    visiblePage: 1,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    changes: DEFAULT_TABLE_CHANGES,
  };

  const storeState: AdOptimizationState = {
    tableState: {
      [EST_AD_GROSS_MARGIN_TABLE_ADS_ID]: estPreAdGrossMarginInitialState,
      [ADS_MANAGER_CAMPAIGNS_TABLE_ID]: adsManagerCampaignsTableInitialState,
      [ADS_MANAGER_ADGROUP_TABLE_ID]: adsManagerAdgroupTableInitialState,
      [ADS_MANAGER_TARGETS_TABLE_ID]: adsManagerTargetsTableInitialState,
      [ADS_MANAGER_AD_ITEMS_TABLE_ID]: adsManagerProductAdsTableInitialState,
      [ADS_MANAGER_PROFILES_TABLE_ID]: adsManagerProfileTableInitialState,
      [ADS_MANAGER_SB_ADS_TABLE_ID]: adsManagerSbAdsTableInitialState,
      [KEYWORD_RECOMMENDATIONS_TABLE_V2_ID]: initialRecommendationsTableV2State,
      [KEYWORD_RECOMMENDATIONS_TABLE_V3_ID]: {
        ...initialRecommendationsTableV2State,
        sorts: [
          {
            column: KeywordActionColumns.AD_CONVERSIONS,
            direction: SortOrder.Desc,
          },
        ],
      },
      [KEYWORD_RECOMMENDATIONS_TABLE_V3_ID_BY_CHANNEL]: {
        ...initialRecommendationsTableV2State,
        sorts: [
          {
            column: KeywordActionColumns.KEYWORD,
            direction: SortOrder.Desc,
          },
        ],
      },
      [NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V2_ID]:
        initialNegativeRecommendationsTableV2State,
      [NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V3_ID]:
        initialNegativeRecommendationsTableV2State,
      [AO_MULTI_CHANNEL_DASHBOARD_TABLE_ID]:
        initialAoMultiChannelDashboardTableState,
      [ADS_MANAGER_SV_ADS_TABLE_ID]: svAdsInitialState,
    },
    adsManagerState: {
      selectedSalesChannel: '',
      flywheelSalesChannels: [],
    },
  };

  const store = createStore<AdOptimizationState, AdOptimizationAction, {}, {}>(
    reducers,
    storeState,
    enhancer
  );

  const CAMPAIGN_BREADCRUMB_HEADER = intl.formatMessage({
    id: I18nKey.ADS_MANAGER_CAMPAIGN_DETAILS_BREADCRUMB_HEADER,
  });
  const ADGROUP_BREADCRUMB_HEADER = intl.formatMessage({
    id: I18nKey.ADS_MANAGER_ADGROUP_DETAILS_BREADCRUMB_HEADER,
  });

  const [appHeaderTitle, setAppHeaderTitle] = useState<AppHeaderTitleProps>({
    campaignHeader: CAMPAIGN_BREADCRUMB_HEADER,
    adGroupHeader: ADGROUP_BREADCRUMB_HEADER,
  });

  const [displayConnectionsBanner, setDisplayConnectionsBanner] =
    useState<AOConnectionBanner>(AOConnectionBanner.NoConnection);

  const { salesChannels } = useContext(SalesChannelContext);
  const { salesChannelData } = useContext(SubscriptionContext);
  const { merchantCountries } = useContext(MerchantCountriesContext);
  const { aoDataSyncInfo } = useDataSyncInfoContext();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayServerErrorMessage, setDisplayServerErrorMessage] =
    useState<boolean>(false);
  const [adLevelForBreadcrumb, setAdLevelForBreadcrumb] = useState<
    AdLevel | ''
  >('');

  useEffect(() => {
    merchantCountries &&
      merchantCountries.length > 0 &&
      setMerchantsBySalesChannel(
        salesChannelData.map(({ salesChannel, items }) => ({
          flywheelSalesChannel: salesChannel as FlywheelSalesChannel,
          merchants:
            salesChannel === FlywheelSalesChannel.Walmart
              ? items
              : items.map((item) => ({
                  ...item,
                  adConnectionStatus: getDataAvailability(
                    item.adConnectionStatus,
                    item.id,
                    aoDataSyncInfo?.syncPerMerchantIds || []
                  ),
                })),
        }))
      );

    merchantCountries && merchantCountries.length === 0 && setIsLoading(false);
  }, [merchantCountries, salesChannelData, aoDataSyncInfo]);

  useEffect(() => {
    if (
      userContext.userInfo.userDetails &&
      isProductSubscribed(
        PRODUCT_SUBSCRIPTIONS.AO,
        userContext.userInfo.userDetails
      ) &&
      merchantCountries
    ) {
      setFlywheelSalesChannels(salesChannels);
      const urlFromStorageData = generateUrlFromLocalStorageData(
        userId,
        accountId,
        salesChannels,
        location.pathname
      );
      const queryParamsInUrl = location.search.length > 0;

      const merchantsWithSalesChannel =
        getSalesChannelDataWithChannelName(salesChannelData);

      getSalesChannelConnectionStatus(
        merchantsWithSalesChannel,
        merchantCountries
      ).then((connectionState: AOConnectionBanner) => {
        setSalesChannelsConnectionStates(
          merchantsWithSalesChannel,
          connectionState,
          salesChannels
        );

        const salesChannelProductConnectionStatus =
          getSalesChannelProductConnectionStatus(merchantsWithSalesChannel);

        setProductConnectionStatus(salesChannelProductConnectionStatus);
        const connectedSalesChannels = getConnectedSalesChannels(
          merchantsWithSalesChannel
        );
        if (!isEmpty(connectedSalesChannels) || isEmpty(merchantCountries)) {
          setIsLoading(false);
        }

        const isNewDashboard = location.pathname.endsWith(NavPaths.AoDashboard);

        const shouldSetNewUrl =
          merchantsWithSalesChannel.totalItems !== 0 &&
          !merchantsWithSalesChannel.isError &&
          flywheelSalesChannels.length > 0 &&
          merchantCountries.length > 0 &&
          !queryParamsInUrl;

        if (isNewDashboard) {
          // Don't re-navigate -> pass
        } else if (urlFromStorageData && !queryParamsInUrl) {
          setUrlToNavigate(urlFromStorageData);
        } else if (shouldSetNewUrl) {
          const newUrl = getUrlBySyncedSalesChannels(
            flywheelSalesChannels,
            merchantCountries
          );
          setUrlToNavigate(newUrl);
        }
      });
    }
  }, [salesChannels, salesChannelData, merchantCountries, aoDataSyncInfo]);

  const setAdLevelForBreadcrumbWrapper = (adLevel: AdLevel) =>
    setAdLevelForBreadcrumb(adLevel);

  const [urlToNavigate, setUrlToNavigate] = useState<string>('');

  const [flywheelSalesChannels, setFlywheelSalesChannels] = useState<
    AllSalesChannel[]
  >([]);
  const [productConnectionStatus, setProductConnectionStatus] =
    useState<ConnectionStatus>(ConnectionStatus.NotConnected);

  const getSalesChannelConnectionStatus = async (
    salesChannelsTableData: AccountSalesChannelPaginatedResultWithChannelName,
    merchantCountriesInfo?: MerchantCountry[]
  ): Promise<AOConnectionBanner> => {
    salesChannelsTableData.items = getFilteredSalesChannelDataWithChannelName(
      salesChannelsTableData
    );

    if (shouldShowNoConnectionBanner(salesChannelsTableData)) {
      // If there are no connections then return "No Connection" banner
      return AOConnectionBanner.NoConnection;
    } else {
      const connectedSalesChannels = getConnectedSalesChannels(
        salesChannelsTableData
      );

      // If there are no connections with status "Active" and atleast one with status
      // "Syncing"/"Processing"/"PendingWalmart" then show "Connection is progress" banner
      if (isEmpty(connectedSalesChannels)) {
        if (!isEmpty(merchantCountriesInfo)) {
          setIsLoading(false);
          const maybeConnectedMerchants = getMayBeConnectedMerchants(
            salesChannelsTableData
          );
          const dataAvailabilityForSyncingMerchants =
            getDataAvailabilityForSyncingMerchants(
              maybeConnectedMerchants,
              aoDataSyncInfo
            );

          if (
            isAnyDateAvailableForAnyMerchant(
              dataAvailabilityForSyncingMerchants
            )
          ) {
            // If connection status is Syncing, check if we have data availability info for that merchant
            // return ConnectionSuccessful if it does
            return AOConnectionBanner.ConnectionSuccessful;
          }
        }

        return isAnyConnectionInProgress(salesChannelsTableData)
          ? AOConnectionBanner.ConnectionInProgress
          : AOConnectionBanner.NoConnection;
      } else {
        return AOConnectionBanner.ConnectionSuccessful;
      }
    }
  };

  const getSalesChannelProductConnectionStatus = (
    salesChannelsTableData: AccountSalesChannelPaginatedResultWithError
  ): ConnectionStatus => {
    if (salesChannelsTableData.isError) {
      return ConnectionStatus.Failed;
    }
    if (salesChannelsTableData?.totalItems === 0) {
      // If there are no connections then return "Not Connected Status"
      return ConnectionStatus.NotConnected;
    } else {
      const connectedProductSalesChannels = salesChannelsTableData.items.filter(
        (salesChannel) =>
          salesChannel.productConnectionStatus ===
          ProductConnectionStatus.Connected
      );
      // If there are no connections with status "Active" and atleast one with status
      if (connectedProductSalesChannels.length !== 0) {
        return ConnectionStatus.Connected;
      } else {
        return salesChannelsTableData.items.some(
          (channel) =>
            channel.productConnectionStatus === ProductConnectionStatus.Syncing
        )
          ? ConnectionStatus.Syncing
          : ConnectionStatus.NotConnected;
      }
    }
  };

  const setSalesChannelsConnectionStates = (
    salesChannelsTableData: AccountSalesChannelPaginatedResultWithError,
    connectionState: AOConnectionBanner,
    channels?: AllSalesChannel[]
  ) => {
    const serverErrorMessageState =
      salesChannelsTableData.isError || channels?.length === 0;

    if (serverErrorMessageState) {
      setDisplayServerErrorMessage(true);
      setDisplayConnectionsBanner(AOConnectionBanner.NoConnection);
    } else {
      setDisplayConnectionsBanner(connectionState);
    }
  };

  const getSalesChannelsTableData = async (
    _flywheelSalesChannels: AllSalesChannel[]
  ): Promise<AccountSalesChannelPaginatedResultWithChannelName> => {
    const allSalesChannelsTableData = salesChannelData;

    // check if there are 0 connections
    const areThereNoConnectionsAcrossSalesChannel = salesChannelData.every(
      (salesChannelDataItem) => salesChannelDataItem.totalItems === 0
    );

    if (areThereNoConnectionsAcrossSalesChannel) {
      return {
        totalItems: 0,
        items: [],
        isError: false,
      };
    } else {
      setMerchantsBySalesChannel(
        allSalesChannelsTableData.map(({ salesChannel, items }) => ({
          flywheelSalesChannel: salesChannel as FlywheelSalesChannel,
          merchants:
            salesChannel === FlywheelSalesChannel.Walmart
              ? items
              : items.map((itemSalesChannel) => ({
                  ...itemSalesChannel,
                  adConnectionStatus: getDataAvailability(
                    itemSalesChannel.adConnectionStatus,
                    itemSalesChannel.id,
                    aoDataSyncInfo?.syncPerMerchantIds || []
                  ),
                })),
        }))
      );

      let totalItems: number = 0;
      const salesChannelDataWithName: SalesChannelDataWithChannelName[] = [];
      let isError: boolean | undefined = true;

      allSalesChannelsTableData.forEach((salesChannel) => {
        totalItems += salesChannel.totalItems;
        const salesChannelMerchantData: SalesChannelDataWithChannelName[] =
          salesChannel.items.map((channel) => {
            return {
              ...channel,
              salesChannelName: salesChannel.salesChannel || '',
            };
          });
        salesChannelDataWithName.push(...salesChannelMerchantData);
        if (!salesChannel.isError) {
          // by default isError set to true, if at least one sales channel request returns success, set isError to false
          isError = false;
        }
      });

      return {
        totalItems,
        items: salesChannelDataWithName,
        isError,
      };
    }
  };

  const updateMerchantsBySalesChannelOnAIPlanFlagChange = (
    updateAllMerchants: boolean,
    salesChannelName?: FlywheelSalesChannel,
    merchants?: SalesChannelData[]
  ) =>
    updateAllMerchants
      ? getSalesChannelsTableData(flywheelSalesChannels)
      : salesChannelName &&
        merchants &&
        setMerchantsBySalesChannel((previousMerchantsBySalesChannel) => [
          ...previousMerchantsBySalesChannel.filter(
            ({ flywheelSalesChannel }) =>
              flywheelSalesChannel !== salesChannelName
          ),
          {
            flywheelSalesChannel: salesChannelName,
            merchants,
          },
        ]);

  useEffect(() => {
    if (
      !isLoading &&
      displayConnectionsBanner === AOConnectionBanner.ConnectionSuccessful &&
      !displayServerErrorMessage &&
      urlToNavigate &&
      location.pathname !== NavPaths.KeywordActions && //NavPaths.KeywordActions check to be removed once IFD-3582 changes are moved to production
      !location.pathname.includes(NavPaths.KeywordsTargets) &&
      !location.pathname.includes(
        NavPaths.AoAmazonSponsoredProductsKeywordTargetsChatGPT
      ) &&
      !location.pathname.includes(
        NavPaths.AoWalmartSponsoredProductsKeywordTargetsChatGPT
      )
    ) {
      navigate(NavPaths.AOAdsManager + '/' + urlToNavigate);
    }
  }, [
    isLoading,
    displayConnectionsBanner,
    displayServerErrorMessage,
    urlToNavigate,
  ]);

  const Dashboard = lazy(() => import('./containers/dashboard'));

  const KeywordsActionsScreen2 = lazy(
    () => import('../keywordAction/components/KeywordsActionsScreen2')
  );

  return (
    <Provider store={store}>
      <MerchantProvider>
        <BidConstraintsProvider>
          <>
            <Suspense fallback={<Spinner />}>
              {isLoading && (
                <Loader.default position="absolute" top="50%" left="50%" />
              )}
              {!isLoading && (
                <Routes>
                  <Route path={'/dashboard'} element={<Dashboard />} />
                  <Route
                    path={NavPaths.AdvertisingManager}
                    element={
                      <AdsManager
                        famApiClient={famApiClient}
                        aoApiClient={aoApiClient}
                        setAppHeaderTitle={setAppHeaderTitle}
                        appHeaderTitle={appHeaderTitle}
                        displayConnectionsBanner={displayConnectionsBanner}
                        shouldDisplayServerErrorMessageInBanner={
                          displayServerErrorMessage
                        }
                        setAdLevelForBreadcrumb={setAdLevelForBreadcrumbWrapper}
                        breadCrumbs={
                          adLevelInfo?.adsManagerDetailsPageFlag ? (
                            <BreadCrumbs>
                              {createBreadCrumbLinks(
                                location,
                                navigate,
                                appHeaderTitle,
                                adLevelForBreadcrumb,
                                false
                              )}
                            </BreadCrumbs>
                          ) : undefined
                        }
                        flywheelSalesChannels={flywheelSalesChannels}
                        productConnectionStatus={productConnectionStatus}
                        pageTitle={getPageTitle(adLevelInfo, appHeaderTitle)}
                        merchantsBySalesChannel={merchantsBySalesChannel}
                        updateMerchantsBySalesChannelOnAIPlanEnable={
                          updateMerchantsBySalesChannelOnAIPlanFlagChange
                        }
                        defaultTableMode={getDefaultTableModeFromRedirectProps(
                          redirectProps
                        )}
                      />
                    }
                  />{' '}
                  <Route
                    path={NavPaths.KeywordTargetsDashboard}
                    element={<KeywordsActionsScreen2 />}
                  />
                </Routes>
              )}
            </Suspense>
          </>
        </BidConstraintsProvider>
      </MerchantProvider>
    </Provider>
  );
};
AdvertisingOptimization.displayName = 'AdvertisingOptimization';

export default AdvertisingOptimization;

export const getFilteredSalesChannelDataWithChannelName = (
  salesChannelsTableData: AccountSalesChannelPaginatedResultWithChannelName
) =>
  salesChannelsTableData.items.filter(
    (merchant) =>
      merchant.aoEnabled ||
      (merchant.salesChannelName === FlywheelSalesChannel.Walmart &&
        (merchant.adConnectionStatus === FAMConnectionStatus.Processing ||
          merchant.adConnectionStatus === FAMConnectionStatus.PendingWalmart ||
          merchant.adConnectionStatus === FAMConnectionStatus.Syncing ||
          merchant.adConnectionStatus === ConnectionStatus.Processing))
  );

export const getConnectedSalesChannels = (
  salesChannelsTableData: AccountSalesChannelPaginatedResultWithChannelName
) =>
  salesChannelsTableData.items.filter(
    (salesChannel) =>
      salesChannel.adConnectionStatus === ConnectionStatus.Connected ||
      (salesChannel.salesChannelName === FlywheelSalesChannel.Walmart &&
        salesChannel.adConnectionStatus === FAMConnectionStatus.Active)
  );

export const getMayBeConnectedMerchants = (
  salesChannelsTableData: AccountSalesChannelPaginatedResultWithChannelName
) =>
  salesChannelsTableData.items.filter(
    (channel) =>
      channel.adConnectionStatus === FAMConnectionStatus.Active &&
      channel.salesChannelName === FlywheelSalesChannel.Amazon
  );
export const isAnyDateAvailableForAnyMerchant = (
  dataAvailabilityForSyncingMerchants: (MerchantSyncDate | undefined)[]
) =>
  dataAvailabilityForSyncingMerchants.some(
    (availability) =>
      availability?.earliestAvailableDate ||
      availability?.latestAvailableDate ||
      availability?.lastSyncedAt
  );

export const isAnyConnectionInProgress = (
  salesChannelsTableData: AccountSalesChannelPaginatedResultWithChannelName
) =>
  salesChannelsTableData.items.some(
    (channel) =>
      channel.adConnectionStatus === FAMConnectionStatus.Processing ||
      channel.adConnectionStatus === FAMConnectionStatus.Syncing ||
      channel.adConnectionStatus === FAMConnectionStatus.PendingWalmart ||
      channel.adConnectionStatus === ConnectionStatus.Processing ||
      channel.adConnectionStatus === ConnectionStatus.Syncing ||
      channel.adConnectionStatus === FAMConnectionStatus.Active
  );

export const getDataAvailabilityForSyncingMerchants = (
  maybeConnectedMerchants: SalesChannelDataWithChannelName[],
  dataAvailabilityInfoResponse: DataSyncInfoResponse | undefined
) =>
  maybeConnectedMerchants.map((merchant) => {
    return find(dataAvailabilityInfoResponse?.syncPerMerchantIds, {
      merchantCountryId: merchant.id,
    });
  });

export const shouldShowNoConnectionBanner = (
  salesChannelsTableData: AccountSalesChannelPaginatedResultWithChannelName
) =>
  salesChannelsTableData?.totalItems === 0 && !salesChannelsTableData.isError;

export const getDefaultFiltersFromRedirectProps = (
  redirectProps: RedirectStateParams
) => redirectProps?.filters || [];

export const getDefaultTableModeFromRedirectProps = (
  redirectProps: RedirectStateParams
) => redirectProps?.tableMode || DEFAULT_TABLE_MODE;
