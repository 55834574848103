import './campaignsTable.scss';

import first from 'lodash/first';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alignment,
  BulkEditConfirmationModal,
  ButtonSize,
  ButtonState,
  ColumnManager,
  ColumnManagerOption,
  ColumnManagerVariant,
  DualModes,
  FilterIcon,
  FiltersMenu,
  Placement,
  SearchInputSize,
  TableActionRow,
  Theme,
  Tooltip,
  TooltipSize,
  Variant,
} from '@teikametrics/tm-design-system';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';
import {
  SaveChangesFlagContext,
  SaveChangesFlagContextState,
} from '../../../../containers/saveChangesFlagProvider';
import {
  tableActions,
  tableSelectors,
  tableThunks,
} from '../../../../containers/table/ducks';
import {
  TableChange,
  WithTable,
} from '../../../../containers/table/ducks/types';
import UpdatedFlywheelTable, {
  FlywheelTableColumnGroup,
} from '../../../../containers/table/UpdatedFlywheelTable';
import { FlywheelSearchInput } from '../../../../containers/tableV2/flywheelSearchInput';
import { getUserRoleMatch } from '../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { FAMApiClient } from '../../../../lib/clients/FAMApiClient';
import { PaginatedDataFetcher } from '../../../../lib/clients/types';
import { useTime } from '../../../../lib/hooks/useTime';
import {
  AdLevel,
  AdType,
  FlywheelSalesChannel,
  MerchantCountryCode,
  Portfolio,
  ProfileApiDataDetails,
  ProfileApiDataRequest,
  REQUEST_DATE_FORMAT,
} from '../../../../lib/types/AOSharedTypes';
import { MerchantCountry, MerchantType, Role } from '../../../../lib/types/Fam';
import {
  COLUMNS_WITH_SINGLE_DECIMAL_FILTER,
  Filter,
} from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  CurrencyCode,
  getCurrencyCodeFromMerchantCountryCode,
} from '../../../../lib/utilities/currency';
import { MerchantContext, MerchantContextState } from '../../merchantsProvider';
import { areNoChangesDone, upgradeFiltersInStorage } from '../../utils';
import { getActionRowButtons } from './bulkEditModalUtils';
import { ADS_MANAGER_PROFILES_TABLE_ID } from './ducks/types';
import { ErrorTable } from './errorTable';
import ExportAdLevelData from './exportAdLevelData';
import { KeepEditingModal } from './keepEditingModal';
import { NoDataFoundTable } from './noDataFoundTable';
import { NoFilteredDataFoundTable } from './noFilteredDataFound';
import { generateProfilesColumns } from './profilesTableColumns';
import {
  AD_LEVEL_EXTERNAL_ID_MAPPER,
  ApiColumnsWithAdLevel,
  CURRENCY_CODE,
  ConfirmationModalClickKind,
  MERCHANT_NAME_COLUMN_ID_FOR_EXPORT,
  PROFILE_TABLE_SEARCH_KEY,
  ProfileDataApiColumnGroupIdentifier,
  REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES,
  TableCellChangeProfile,
  TableDataAdsManager,
} from './types';
import {
  ALL_AD_LEVELS_FILTER_FILED_MAPPER,
  PROFILES_API_COLUMN_NAME,
  PROFILE_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  getSearchInputPlaceholder,
  hasTypeheadParam,
  isInvalidBrandName,
  isInvalidClickUrl,
  isInvalidHeadline,
  isValidBrandNameCharacterLength,
  isValidHeadlineCharacterLength,
  shouldShowSmartCampaigns,
} from './utils';
import { getFilterColumnDefinitons } from './utils/filterDefinitions';

const TABLE_ID = ADS_MANAGER_PROFILES_TABLE_ID;

export interface TableProfileProps {
  readonly onEditSave: () => void;
  readonly toggleTableMode: () => void;
  readonly changeTableMode: (index: number) => void;
  readonly tableMode: DualModes;
  readonly apiColumnsWithAdLevel: ApiColumnsWithAdLevel;
  readonly aoApiClient: AOApiClient;
  readonly famApiClient: FAMApiClient;
  readonly exportButtonState: ButtonState;
  readonly adLevelDropDownSelectedValues: '' | AdLevel;
  readonly selectedSalesChannel: FlywheelSalesChannel;
  readonly selectedAdType: AdType;
  readonly activeSliderIndex: number;
  readonly request: () => ProfileApiDataRequest;
  readonly dataFetcher: PaginatedDataFetcher<ProfileApiDataDetails>;
  readonly setExportButtonState: React.Dispatch<
    React.SetStateAction<ButtonState>
  >;
  readonly selectedSalesChannelName: string;
  readonly onFilterUpdate?: (filters: Filter[]) => void;
  readonly filtersFromBrowserStorage?: Filter[];
  readonly storeFiltersInBrowserStorage?: (filters: Filter[]) => void;
  readonly adTypes: AdType[];
  readonly portfolioNames: Portfolio[];
  readonly merchantCountry: MerchantCountryCode;
  readonly merchantType?: MerchantType;
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly selectedMerchantCountries: Array<number | string> | string;
  readonly allMerchants: MerchantCountry[];
  readonly aiEnabled: boolean;
  readonly merchantsWithAutomationEnabled: string[];
  readonly onColumnSelectionChange?: (selectedColumns: string[]) => void;
  readonly selectedColumns?: string[];
  readonly columnManagerOptions?: ColumnManagerOption[];
  readonly switchLevelController: (adLevel: string) => void;
}

const getValidRowsCount = (updatedCells: TableCellChangeProfile) => {
  const updatedCellData = Object.values(updatedCells);
  const validBrandNameCount = updatedCellData.filter(
    (data) =>
      !isInvalidBrandName(data.brand) &&
      isValidBrandNameCharacterLength(data.brand)
  ).length;
  const validHeadlineCount = updatedCellData.filter(
    (data) =>
      !isInvalidHeadline(data.headline) &&
      isValidHeadlineCharacterLength(data.headline)
  ).length;
  const validClickUrlCount = updatedCellData.filter(
    (data) =>
      !isInvalidClickUrl(data.clickUrl) && !hasTypeheadParam(data.clickUrl)
  ).length;
  const validProfileStatusCount = updatedCellData.filter(
    (data) => !!data.profileStatus
  ).length;

  return (
    validBrandNameCount +
    validHeadlineCount +
    validClickUrlCount +
    validProfileStatusCount
  );
};

export const TableProfiles: React.FC<TableProfileProps> = ({
  apiColumnsWithAdLevel,
  aoApiClient,
  exportButtonState,
  adLevelDropDownSelectedValues,
  selectedSalesChannel,
  activeSliderIndex,
  dataFetcher,
  request,
  setExportButtonState,
  selectedSalesChannelName,
  onFilterUpdate,
  filtersFromBrowserStorage,
  storeFiltersInBrowserStorage,
  selectedAdType,
  adTypes,
  portfolioNames,
  merchantType,
  merchantCountry,
  startDate,
  endDate,
  selectedMerchantCountries,
  allMerchants,
  aiEnabled,
  merchantsWithAutomationEnabled,
  columnManagerOptions,
  onColumnSelectionChange = noop,
  selectedColumns,
  tableMode,
  onEditSave,
  changeTableMode,
  toggleTableMode,
  switchLevelController,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<boolean>(false);
  const firstApiCall = useRef<boolean>(true);
  const userContext = useContext(UserContext);

  const [isFilterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);
  const [confirmationModalVariant, setConfirmationModalVariant] =
    useState<Variant | null>(null);
  const [clickedOnView, setClickedOnView] = useState<boolean>(false);
  const merchantContext = useContext<MerchantContextState>(MerchantContext);
  const merchantDetails = merchantContext.merchantDetails;

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  const saveChangesFlagContext = useContext<SaveChangesFlagContextState>(
    SaveChangesFlagContext
  );

  const isTableDataEdited: boolean =
    saveChangesFlagContext.saveChangesData.editDataFlag;

  const changedCount = useSelector<WithTable<ProfileApiDataDetails>, number>(
    ({ tableState }) => {
      return tableSelectors.getChangedCountSelector()(tableState, TABLE_ID);
    }
  );

  const isUserRoleViewOnly = getUserRoleMatch(
    Role.VIEW_ONLY,
    userContext.userInfo.userDetails
  );

  const tableChange = useSelector<
    WithTable<ProfileApiDataDetails>,
    TableChange
  >(({ tableState }) =>
    tableSelectors.getChangeSelector()(
      tableState,
      ADS_MANAGER_PROFILES_TABLE_ID
    )
  );

  const updatedCells = tableChange.cell as TableCellChangeProfile;
  const validRowsCount = useMemo(
    () => getValidRowsCount(updatedCells) || 0,
    [updatedCells]
  );

  useEffect(() => {
    // Show prompt if table is edited and user is on edit mode
    initBeforeUnLoad(isTableDataEdited && activeSliderIndex === 1);
  }, [isTableDataEdited]);

  const initBeforeUnLoad = (showExitPrompt: boolean) => {
    window.onbeforeunload = (event: Event) => {
      if (showExitPrompt) {
        event.preventDefault();
        return '';
      }
    };
  };

  const getVisibleColumns = (): FlywheelTableColumnGroup<
    ProfileApiDataDetails,
    TableDataAdsManager
  >[] => {
    const PROFILE_COLUMNS = generateProfilesColumns();
    return PROFILE_COLUMNS.map((column) => {
      const columnInGroups = column.columnsInGroup.filter((subColumn) =>
        apiColumnsWithAdLevel.columns.includes(subColumn.columnName)
      );
      return {
        ...column,
        columnsInGroup: columnInGroups,
      };
    }).filter((column) => column.columnsInGroup.length > 0);
  };

  const onKeepEditingClick = () => {
    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      saveChangesModalIsOpen: false,
      aoAdLevel: undefined,
    });
    setClickedOnView(false);
  };

  const onLeaveWithoutSaveClick = () => {
    const adLevel = saveChangesFlagContext.saveChangesData.aoAdLevel || '';

    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
      aoAdLevel: undefined,
    });

    if (clickedOnView) {
      toggleTableMode();
      setClickedOnView(false);
    } else if (saveChangesFlagContext.saveChangesData.aoAdLevel) {
      switchLevelController(adLevel);
    } else {
      navigate(saveChangesFlagContext.saveChangesData.navPath);
    }
  };

  const clearAllChanges = () =>
    dispatch(
      tableActions.clearAllChanges({
        tableId: TABLE_ID,
      })
    );

  const discardChanges = () => {
    toggleTableMode();

    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
    });

    clearAllChanges();
  };

  const profilesDataRequest = request();
  // export api request:
  // channel setting columns for export request body that are selected in column manager but not mandatory for edit functionality
  const campaignChannelSettingWithoutMandatoryColumns = [
    ...apiColumnsWithAdLevel.columns.filter(
      (column) =>
        PROFILE_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        ProfileDataApiColumnGroupIdentifier.ChannelSettingsFields
    ),
  ];

  let campaignDetailsWithoutMandatoryColumns = [
    ...AD_LEVEL_EXTERNAL_ID_MAPPER.profiles,
    ...apiColumnsWithAdLevel.columns.filter(
      (column) =>
        PROFILE_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields
    ),
  ];

  if (
    campaignDetailsWithoutMandatoryColumns.includes(
      PROFILES_API_COLUMN_NAME.MerchantName
    )
  ) {
    campaignDetailsWithoutMandatoryColumns =
      campaignDetailsWithoutMandatoryColumns
        .filter((column) => column !== PROFILES_API_COLUMN_NAME.MerchantName)
        .concat([MERCHANT_NAME_COLUMN_ID_FOR_EXPORT]);
  }

  const refreshTable = () =>
    dispatch(
      tableThunks.refreshTable(ADS_MANAGER_PROFILES_TABLE_ID, dataFetcher)
    );

  const getSaveButtonState = (changedCount: number, validRowsCount: number) => {
    if (changedCount === 0) {
      return ButtonState.Disabled;
    }

    return validRowsCount === changedCount
      ? ButtonState.Enabled
      : ButtonState.Disabled;
  };

  const getCampaignLevelViewModeRightSideComponents = () => {
    return tableMode === DualModes.View
      ? [
          <Tooltip
            overwrittenTooltipClassnames="w-auto max-w-400"
            theme={Theme.Dark}
            content={intl.formatMessage({
              id: I18nKey.GENERIC_DOWNLOAD_CSV,
            })}
            position={{
              placement: Placement.Bottom,
              alignment: Alignment.Left,
            }}
            tooltipSize={TooltipSize.Small}
            hideArrow
          >
            <ExportAdLevelData<ProfileApiDataRequest>
              aoApiClient={aoApiClient}
              buttonState={exportButtonState}
              setButtonState={setExportButtonState}
              salesChannel={selectedSalesChannelName}
              request={{
                ...profilesDataRequest,
                profileDetailsFields: campaignDetailsWithoutMandatoryColumns,
                channelSettingsFields:
                  campaignChannelSettingWithoutMandatoryColumns,
              }}
              adLevelDropDownSelectedValue={
                adLevelDropDownSelectedValues || undefined
              }
              filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
              startDate={startDate}
              endDate={endDate}
              adType={selectedAdType}
              hideExportText={true}
            />
          </Tooltip>,
        ]
      : [];
  };

  const tableActionRowRightSideElements =
    getCampaignLevelViewModeRightSideComponents();

  useEffect(() => {
    if (!firstApiCall.current) {
      refreshTable();
    } else {
      firstApiCall.current = false;
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (ref.current && hasTimeElapsed()) {
      updateTime();
      refreshTable();
    } else {
      ref.current = true;
      if (location.state) {
        let { filter } =
          (location.state && (location.state as { filter: Filter[] })) || [];
        dispatch(
          tableActions.updateFilters({
            tableId: ADS_MANAGER_PROFILES_TABLE_ID,
            filters: filter || currentTableFilters,
            replace: true,
          })
        );
      }
    }
  }, [apiColumnsWithAdLevel.columns]);

  const { hasTimeElapsed, updateTime } = useTime(
    parseInt(
      process.env.REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES ||
        REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES
    )
  );

  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);

  const tableData = useMemo(
    () => ({
      isEditMode: tableMode === DualModes.Edit,
      adTypes,
      salesChannel: selectedSalesChannel,
      portfolioNames,
      merchantType,
      merchantCountry,
      selectedAdType,
      selectedMerchantCountries,
      aoApiClient,
      selectedEndDate: endDate,
      allMerchants,
      aiEnabled,
      merchantsWithAutomationEnabled,
      showSmartCampaign: shouldShowSmartCampaigns(optimizelyContext),
    }),
    [
      tableMode,
      adTypes,
      selectedSalesChannel,
      portfolioNames,
      merchantType,
      merchantCountry,
      selectedAdType,
      selectedMerchantCountries,
      aoApiClient,
      endDate,
      allMerchants,
      aiEnabled,
      merchantsWithAutomationEnabled,
    ]
  );

  const dataFields = getFilterColumnDefinitons(
    CURRENCY_CODE,
    selectedSalesChannelName as FlywheelSalesChannel,
    first(adTypes),
    adLevelDropDownSelectedValues || undefined,
    merchantDetails
  );

  const currentTableFilters = useSelector<
    WithTable<ProfileApiDataDetails>,
    Filter[]
  >(({ tableState }) => tableState[ADS_MANAGER_PROFILES_TABLE_ID].filters);

  let currentFilters = [
    ...currentTableFilters,
    ...(filtersFromBrowserStorage ?? []),
  ];

  const updateFilters = (filters: Filter[]) => {
    const existingSearchFilters: Filter[] = currentFilters.filter(
      (currentFilter) =>
        ALL_AD_LEVELS_FILTER_FILED_MAPPER.find(
          (filter) => filter.alias === currentFilter.field
        )
    );
    const newFilters: Filter[] = [...existingSearchFilters, ...filters];
    dispatch(
      tableActions.updateFilters({
        tableId: ADS_MANAGER_PROFILES_TABLE_ID,
        filters: newFilters,
        replace: true,
      })
    );
    upgradeFiltersInStorage(storeFiltersInBrowserStorage)(newFilters);
    if (onFilterUpdate) {
      onFilterUpdate(newFilters);
    }
  };

  const onActionClick = (actionType: Variant) => {
    if (actionType === Variant.Discard && areNoChangesDone(updatedCells)) {
      discardChanges();
      return;
    }
    setConfirmationModalVariant(actionType);
  };

  const confirmationModalClick = (kind: ConfirmationModalClickKind) => {
    switch (confirmationModalVariant) {
      case Variant.Apply:
        if (kind === ConfirmationModalClickKind.APPLY) {
          setConfirmationModalVariant(null);
          onEditSave();
        } else {
          setConfirmationModalVariant(null);
        }
        break;
      case Variant.Discard:
        if (kind === ConfirmationModalClickKind.APPLY) {
          setConfirmationModalVariant(null);
          discardChanges();
        } else {
          setConfirmationModalVariant(null);
        }
    }
  };

  return (
    <>
      {confirmationModalVariant && (
        <BulkEditConfirmationModal
          showModal={!!confirmationModalVariant}
          variant={confirmationModalVariant}
          onPrimaryButtonClick={() =>
            confirmationModalClick(ConfirmationModalClickKind.APPLY)
          }
          onSecondryButtonClick={() =>
            confirmationModalClick(ConfirmationModalClickKind.CANCEL)
          }
        />
      )}
      <KeepEditingModal
        showModal={
          saveChangesFlagContext.saveChangesData.saveChangesModalIsOpen
        }
        onKeepEditing={onKeepEditingClick}
        onDiscard={onLeaveWithoutSaveClick}
        tableId={TABLE_ID}
      />
      <TableActionRow
        className="mt-16"
        leftSideElements={[
          <FlywheelSearchInput
            tableId={ADS_MANAGER_PROFILES_TABLE_ID}
            inputSearchColumnName={PROFILE_TABLE_SEARCH_KEY}
            searchInputSize={SearchInputSize.Medium}
            searchInputPlaceholder={getSearchInputPlaceholder(
              selectedAdType,
              adLevelDropDownSelectedValues as AdLevel,
              intl
            )}
            onFilterUpdate={onFilterUpdate}
            upgradeFiltersInStorage={upgradeFiltersInStorage(
              storeFiltersInBrowserStorage
            )}
            hasSearchInputWithButton
            searchInputClassName="w-480"
            dataTestId={`${ADS_MANAGER_PROFILES_TABLE_ID}_search_input`}
          />,
          <FiltersMenu
            customFilterIcon={FilterIcon}
            currency={currencyCode as CurrencyCode}
            dataFields={dataFields}
            currentFilters={currentFilters}
            handleSave={updateFilters}
            filterButtonSize={ButtonSize.Medium}
            isOpen={isFilterMenuOpen}
            setOpen={setFilterMenuOpen}
            filterDateFormat={REQUEST_DATE_FORMAT}
            singleDecimalColumns={COLUMNS_WITH_SINGLE_DECIMAL_FILTER}
            dataTestId={`${ADS_MANAGER_PROFILES_TABLE_ID}_filters_menu`}
            filtersContainerClass={
              'transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:left-0'
            }
          />,
        ]}
        rightSideElements={[
          <Tooltip
            overwrittenTooltipClassnames="w-auto max-w-400"
            theme={Theme.Dark}
            content={intl.formatMessage({
              id: I18nKey.GENERIC_COLUMNS,
            })}
            tooltipSize={TooltipSize.Small}
            hideArrow
          >
            <ColumnManager
              variant={ColumnManagerVariant.ColumnGroupWithHeader}
              values={selectedColumns ?? []}
              onChange={onColumnSelectionChange}
              options={columnManagerOptions}
              dataTestId={`${ADS_MANAGER_PROFILES_TABLE_ID}_column_manager`}
              tooltipClass="right-0"
              hideColumnText={true}
            />
          </Tooltip>,
          ...tableActionRowRightSideElements,
        ]}
        fullWidthElementIndex={-1}
      />
      <UpdatedFlywheelTable<ProfileApiDataDetails, TableDataAdsManager>
        filtersContainerClass="transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:right-0"
        tableData={tableData}
        columns={getVisibleColumns()}
        tableId={TABLE_ID}
        currencyCode={currencyCode || CURRENCY_CODE}
        dataFetcher={dataFetcher}
        footerTotalItemsI18nKey={
          I18nKey.ADS_MANAGER_PROFILES_TABLE_FOOTER_TOTAL_ITEMS_TEXT
        }
        hasStickyHeader
        hasStickyLeftColumn
        dataFields={dataFields}
        actionRowRightSideComponents={tableActionRowRightSideElements}
        errorComponent={<ErrorTable />}
        yesFilterNoDataDisplayComponent={<NoFilteredDataFoundTable />}
        noFilterNoDataDisplayComponent={<NoDataFoundTable />}
        inputSearchColumnName={PROFILE_TABLE_SEARCH_KEY}
        onFilterUpdate={onFilterUpdate}
        filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
        filtersFromBrowserStorage={filtersFromBrowserStorage}
        storeFiltersInBrowserStorage={storeFiltersInBrowserStorage}
        filterDateFormat={REQUEST_DATE_FORMAT}
        searchInputSize={SearchInputSize.Medium}
        hasSearchInputWithButton
        searchInputPlaceholder={getSearchInputPlaceholder(
          selectedAdType,
          adLevelDropDownSelectedValues as AdLevel,
          intl
        )}
        selectedColumns={selectedColumns}
        columnManagerOptions={columnManagerOptions}
        onColumnSelectionChange={onColumnSelectionChange}
        dataTestId="table_profiles"
        showTableActionRow2={true}
        actionRowV2Props={{
          ...getActionRowButtons(
            tableMode,
            intl,
            getSaveButtonState(changedCount, validRowsCount),
            onActionClick
          ),
          onSliderModeChange: () => {
            if (!isUserRoleViewOnly) {
              toggleTableMode();
            }
          },
          sliderMode: tableMode,
          viewOnlyMode: !!isUserRoleViewOnly,
        }}
        openFilterMenuOnPillClick={() => setFilterMenuOpen(true)}
      />
    </>
  );
};
TableProfiles.displayName = 'TableProfiles';
