import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputState,
  Placement,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeNumericInputColumn,
  makePercentColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignDetails,
  CampaignTargetingType,
  PlacementBidMultiplier,
} from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
} from '../utils';
import { isDirty } from './totalBudget';

export const MIN_SEARCH_INGRID_BID = 0;

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, ...campaignDetails }) => {
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);
  const intl = useIntl();

  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const existingValue = getExistingValue(
    campaignDetails.channelSettings.placementBidMultiplier
      ?.searchIngridBidMultiplier
  );

  let placementBidMultiplierValue = changedValue ?? existingValue;

  const [value, setValue] = useState<string>(placementBidMultiplierValue);
  const [isInputEdited, setIsInputEdited] = useState<boolean>(false);

  useEffect(() => {
    if (
      isEditMode || //Changing between modes
      (!isNil(changedValue) && changedValue !== value) // Changes done due to bulk update. changedValue is latest, but state variable is not
    ) {
      setValue(placementBidMultiplierValue);
    }

    return () => {
      if (!isEditMode) {
        setIsInputEdited(false);
      }
    };
  }, [isEditMode, changedValue, currentPage]);

  const updateCellValue = (newValue: string) =>
    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier,
        rowId: campaignDetails.campaignId,
        existingValue,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        value: newValue,
        numericValue: true,
      })
    );

  const debounceCellValueUpdate = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignDetails.campaignId]
  );

  if (
    checkIfEditMode(isEditMode, campaignDetails.campaignDetails.targetingType)
  ) {
    const onInputFocus = () => setFocussed(true);

    const onInputBlur = () => {
      if (!isEmpty(value)) {
        debounceCellValueUpdate.cancel();
        const formattedValue = Number.parseFloat(value).toFixed(
          PERCENTAGE_FRACTION_DIGITS
        );
        setValue(formattedValue);
        updateCellValue(formattedValue);
      }
      setFocussed(false);
    };

    return makeNumericInputColumn<CampaignDetails>(() => {
      let state = NumericInputState.Default;
      const isInputInvalid: boolean = isEnteredValueInvalid(
        isInputEdited,
        value,
        existingValue
      );

      if (isInputInvalid) {
        state = NumericInputState.Error;
      }
      return {
        dataTestId: CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier,
        value,
        state,
        appendedElement: '%',
        onChange: (inputValue: string) => {
          setValue(inputValue);
          debounceCellValueUpdate(inputValue);
          setIsInputEdited(true);
        },
        tooltipContent: tooltipContent(isInputInvalid, intl),
        controlledTooltip: getTooltipState(focussed, state),
        tooltipPlacement: Placement.Bottom,
        style: ContentStyle.Bold,
        onInputFocus,
        onInputBlur,
        acceptOnlyPositiveNumbers: true,
        isDirty: isDirty(value, existingValue),
        placeholder: MIN_SEARCH_INGRID_BID.toFixed(PERCENTAGE_FRACTION_DIGITS),
        minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      };
    })(campaignDetails);
  }

  return makePercentColumn<CampaignDetails>(
    ({ channelSettings }) =>
      getColumnValue(channelSettings.placementBidMultiplier),
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const searchIngridBidMultiplierColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_SEARCH_INGRID,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const searchIngridBidMultiplierFilter = createPercentDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_SEARCH_INGRID,
  isValidNumber()
);

export const getExistingValue = (searchIngridBidMultiplier?: number) =>
  (searchIngridBidMultiplier?.toString() &&
    (searchIngridBidMultiplier * 100).toFixed(PERCENTAGE_FRACTION_DIGITS)) ||
  '';

export const tooltipContent = (isInvalid: boolean, intl: IntlShape) => {
  if (isInvalid) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          { id: I18nKey.ADS_MANAGER_INVALID_NUMERIC_ATLEAST_PERCENT },
          {
            minValue: intl.formatNumber(MIN_SEARCH_INGRID_BID, {
              minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
              maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
            }),
          }
        )}
      </p>
    );
  }
};

export const getTooltipState = (
  focussed: boolean,
  inputState: NumericInputState
) =>
  focussed && inputState === NumericInputState.Error
    ? ControlledTooltip.Show
    : ControlledTooltip.Hide;

export const getColumnValue = (
  placementBidMultiplier?: PlacementBidMultiplier
) =>
  placementBidMultiplier &&
  !isNil(placementBidMultiplier.searchIngridBidMultiplier)
    ? placementBidMultiplier.searchIngridBidMultiplier.toString()
    : '0';

export const isEnteredValueInvalid = (
  isInputEdited: boolean,
  value: string,
  existingValue: string
) =>
  (isNaN(Number(value)) ||
    Number(value) < MIN_SEARCH_INGRID_BID ||
    isEmpty(value)) &&
  (isInputEdited || isDirty(value, existingValue));

export const checkIfEditMode = (
  isEditMode: boolean,
  targetingType?: CampaignTargetingType
) =>
  isEditMode &&
  (targetingType === CampaignTargetingType.Auto ||
    targetingType === CampaignTargetingType.Manual);
