import { DashboardQueryParams } from '../../lib/types/MISharedTypes';
import { Fw2LocalStorage } from '../../lib/utilities/fw2LocalStorage';

export const storeDashboardFilters = (
  accountId: string,
  values: DashboardQueryParams
) => {
  const dashboardFiltersKey = `${accountId}_mi_dashboard_filters`;
  Fw2LocalStorage.setItem(dashboardFiltersKey, JSON.stringify(values));
};

export const getDashboardFilters = (accountId: string) => {
  const dashboardFiltersKey = `${accountId}_mi_dashboard_filters`;
  const filtersFromLocalStorage = Fw2LocalStorage.getItem(dashboardFiltersKey);
  try {
    return filtersFromLocalStorage
      ? (JSON.parse(filtersFromLocalStorage) as DashboardQueryParams)
      : null;
  } catch (e) {
    return null;
  }
};

export const removeDashboardFilters = (accountId: string) => {
  const dashboardFiltersKey = `${accountId}_mi_dashboard_filters`;
  Fw2LocalStorage.removeItem(dashboardFiltersKey);
};

const getLocalStorageKeyNameForSovMerchant = (
  userId: string,
  accountId: string
) => `${userId}_${accountId}_share_of_voice_merchant`;

export const getSovMerchantFromLocalStorage = (
  userId: string,
  accountId: string
): string | null => {
  try {
    const key = getLocalStorageKeyNameForSovMerchant(userId, accountId);
    const selectedMerchantId = Fw2LocalStorage.getItem(key);
    return selectedMerchantId ? JSON.parse(selectedMerchantId) : null;
  } catch (error) {
    return null;
  }
};

export const setSovMerchantInLocalStorage = (
  userId: string,
  accountId: string,
  merchantId: string
) =>
  Fw2LocalStorage.setItem(
    getLocalStorageKeyNameForSovMerchant(userId, accountId),
    JSON.stringify(merchantId)
  );
