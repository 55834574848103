import './campaignsTable.scss';

import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, {
  Dispatch,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  BulkEditConfirmationModal,
  BulkEditModalNumericValueOperator,
  BulkEditSlideOut,
  BulkEditValues,
  ButtonSize,
  ButtonState,
  ColumnManager,
  ColumnManagerOption,
  ColumnManagerVariant,
  DualModes,
  FilterIcon,
  FiltersMenu,
  SearchInputSize,
  TableActionRow,
  Theme,
  Tooltip,
  TooltipSize,
  Variant,
} from '@teikametrics/tm-design-system';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import {
  BidConstraintsContextState,
  useBidConstraintsContext,
} from '../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../containers/bidConstraintsProvider/biddingConstraints';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';
import {
  SaveChangesFlagContext,
  SaveChangesFlagContextState,
} from '../../../../containers/saveChangesFlagProvider';
import {
  tableActions,
  tableSelectors,
  tableThunks,
} from '../../../../containers/table/ducks';
import {
  TableCellChange,
  TableChange,
  WithTable,
} from '../../../../containers/table/ducks/types';
import UpdatedFlywheelTable, {
  FlywheelTableColumnGroup,
} from '../../../../containers/table/UpdatedFlywheelTable';
import { FlywheelSearchInput } from '../../../../containers/tableV2/flywheelSearchInput';
import {
  getUserRoleMatch,
  isAIPlanEnabled,
} from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { PaginatedDataFetcher } from '../../../../lib/clients/types';
import { useTime } from '../../../../lib/hooks/useTime';
import {
  AdLevel,
  AdType,
  CampaignTargetingType,
  FlywheelSalesChannel,
  MerchantCountryCode,
  REQUEST_DATE_FORMAT,
  TargetStatus,
  TargetsDataRequest,
  TargetsDetails,
} from '../../../../lib/types/AOSharedTypes';
import { MerchantType, Role } from '../../../../lib/types/Fam';
import {
  COLUMNS_WITH_SINGLE_DECIMAL_FILTER,
  Filter,
  FilterOps,
} from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import { OptimizelyFlags } from '../../../../lib/types/OptimizelyFlags';
import { MAP_SALES_CHANNEL_NAME_TO_ID } from '../../../../lib/types/SalesChannels';
import {
  CurrencyCode,
  getCurrencyCodeFromMerchantCountryCode,
} from '../../../../lib/utilities/currency';
import { showHideIntercomLauncher } from '../../../../lib/utilities/intercom';
import { MerchantContext, MerchantContextState } from '../../merchantsProvider';
import { areNoChangesDone, upgradeFiltersInStorage } from '../../utils';
import { getMerchantCountryId } from './adgroupTableColumns/adgroupName';
import { getActionRowButtons, getCheckBoxState } from './bulkEditModalUtils';
import { CreateKeywordsModalContainer } from './createKeywords';
import {
  CreateKeywordsMenu,
  KeywordType,
} from './createKeywords/createKeywordsMenu';
import { DATA_INSPECTOR_COLUMN_NAME } from './dataInspectorConfig';
import { ADS_MANAGER_TARGETS_TABLE_ID } from './ducks/types';
import { ErrorTable } from './errorTable';
import ExportAdLevelData from './exportAdLevelData';
import { KeepEditingModal } from './keepEditingModal';
import { NoDataFoundTable } from './noDataFoundTable';
import { NoFilteredDataFoundTable } from './noFilteredDataFound';
import { generatedTargetsColumns } from './targetsTableColumns';
import { getBulkChangesAndInvalidCountForRowId } from './targetsTableColumns/targetText';
import {
  AD_LEVEL_EXTERNAL_ID_MAPPER,
  ApiColumnsWithAdLevel,
  CURRENCY_CODE,
  ConfirmationModalClickKind,
  EXT_ADGROUP_ID,
  EXT_CAMPAIGN_ID,
  MERCHANT_NAME_COLUMN_ID_FOR_EXPORT,
  REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES,
  TARGETS_TABLE_SEARCH_KEY,
  TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION,
  TableCellChangeAdGroup,
  TableCellChangeCampaign,
  TableCellChangeProductAd,
  TableCellChangeProfile,
  TableCellChangeTarget,
  TableChangeGlobalAdLevelCurrency,
  TableDataAdsManager,
  TargetsDataApiColumnGroupIdentifier,
} from './types';
import {
  AD_LEVEL_API_COLUMNS_TO_EXCLUDE,
  ALL_AD_LEVELS_FILTER_FILED_MAPPER,
  EDIT_TARGETS_API_COLUMN_DATA_MAPPING,
  FLYWHEEL_SALES_CHANNEL_TO_DEFAULT_AD_LEVEL_COLUMNS_MAPPER,
  TABLE_UNIQ_KEY,
  TARGETS_API_COLUMN_NAME,
  TARGETS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  getAdLevelInfo,
  getSearchInputPlaceholder,
  getTargetingExpressionAndTargetLabelFilters,
  isAdGroupStatusArchived,
  isCampaignAdFormatProductCollectionOrSpotlight,
  isCampaignStatusArchived,
  isNegativeKeywordTarget,
  isNegativeProductTarget,
  isValueBetweenMinAndMaxValue,
  shouldShowSmartCampaigns,
} from './utils';
import { getBulkEditConfigurationForTargets } from './utils/bulkEditMenuUtils';
import { getFilterColumnDefinitons } from './utils/filterDefinitions';
import { SelectFilterOption } from './utils/selectFilterOptions';

const TABLE_ID = ADS_MANAGER_TARGETS_TABLE_ID;

interface TableTargetsProps {
  readonly apiColumnsWithAdLevel: ApiColumnsWithAdLevel;
  readonly tableMode: DualModes;
  readonly aoApiClient: AOApiClient;
  readonly exportButtonState: ButtonState;
  readonly activeSliderIndex: number;
  readonly request: TargetsDataRequest;
  readonly adLevelDropDownSelectedValues: '' | AdLevel;
  readonly dataFetcher: PaginatedDataFetcher<TargetsDetails>;
  readonly changeTableMode: (index: number) => void;
  readonly setExportButtonState: React.Dispatch<
    React.SetStateAction<ButtonState>
  >;
  readonly toggleTableMode: () => void;
  readonly selectedSalesChannelName: string;
  readonly onFilterUpdate: (filters: Filter[]) => void;
  readonly onEditSave: () => void;
  readonly detailsPageFiltersForTargets?: Filter[];
  readonly filtersFromBrowserStorage?: Filter[];
  readonly storeFiltersInBrowserStorage?: (filters: Filter[]) => void;
  readonly adTypes: AdType[];
  readonly selectedSalesChannel: FlywheelSalesChannel;
  readonly selectedAdLevel: AdLevel;
  readonly selectedAdType: AdType;
  readonly merchantCountry: MerchantCountryCode;
  readonly merchantType?: MerchantType;
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly selectedMerchantCountries: Array<number | string> | string;
  readonly onColumnSelectionChange?: (selectedColumns: string[]) => void;
  readonly selectedColumns?: string[];
  readonly columnManagerOptions?: ColumnManagerOption[];
  readonly switchLevelController: (adLevel: string) => void;
  readonly isAIEnabledForOneOfTheSelectedMerchants?: boolean;
  readonly isSaveChangesLoading?: boolean;
  readonly merchantsWithAutomationEnabled: string[];
}

const replaceMerchantCountryIdField = (
  selectedColumnsFromColumnManager: string[]
) => {
  if (
    selectedColumnsFromColumnManager.includes(
      TARGETS_API_COLUMN_NAME.MerchantName
    )
  ) {
    const filteredColumns = selectedColumnsFromColumnManager.filter(
      (column) => column !== TARGETS_API_COLUMN_NAME.MerchantName
    );
    return [...filteredColumns, MERCHANT_NAME_COLUMN_ID_FOR_EXPORT];
  }

  return selectedColumnsFromColumnManager;
};

const getInputSearchColumnName = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdLevel: AdLevel
) =>
  FLYWHEEL_SALES_CHANNEL_TO_DEFAULT_AD_LEVEL_COLUMNS_MAPPER[
    selectedSalesChannel
  ][selectedAdLevel].includes(TARGETS_API_COLUMN_NAME.TargetingExpression)
    ? TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION
    : TARGETS_TABLE_SEARCH_KEY;

export const getAllExternalIdColumns = (
  apiColumnsWithAdLevel: ApiColumnsWithAdLevel
) => {
  // Send external ad group id if ad group name is selected
  const adGroupsIdColumns = apiColumnsWithAdLevel.columns.includes(
    TARGETS_API_COLUMN_NAME.AdGroupName
  )
    ? [EXT_ADGROUP_ID]
    : [];

  // Send external campaign id if campaign name is selected
  const campaignsIdColumns = apiColumnsWithAdLevel.columns.includes(
    TARGETS_API_COLUMN_NAME.CampaignName
  )
    ? [EXT_CAMPAIGN_ID]
    : [];

  return [...adGroupsIdColumns, ...campaignsIdColumns];
};

const getSaveButtonState = (changedCount: number, validRowsCount: number) => {
  if (changedCount === 0) {
    return ButtonState.Disabled;
  }

  return validRowsCount === changedCount
    ? ButtonState.Enabled
    : ButtonState.Disabled;
};

const getCampaignAdFormatForTarget = (
  filteredtargetId: string,
  targetTableData: TargetsDetails[]
) =>
  targetTableData.find(({ targetId }) => targetId === filteredtargetId)
    ?.targetDetails.campaignAdFormat;

const getTargetColumns = (adLevelDropDownSelectedValues: '' | AdLevel) => {
  return {
    productTargetColumns:
      adLevelDropDownSelectedValues === AdLevel.ProductTargets
        ? [
            TARGETS_API_COLUMN_NAME.TargetText,
            TARGETS_API_COLUMN_NAME.ExpressionType,
          ]
        : [],
    targetColumns:
      adLevelDropDownSelectedValues === AdLevel.Targets
        ? [
            TARGETS_API_COLUMN_NAME.TargetText,
            TARGETS_API_COLUMN_NAME.ExpressionType,
          ]
        : [],
  };
};

const getColumnManagerOptions = (
  hasCampaignNegativeTargets: boolean,
  columnManagerOptions: ColumnManagerOption[] | undefined,
  isTargetSummaryEnabled: boolean | undefined,
  isSponsoredVideosEditable?: boolean
) => {
  if (!columnManagerOptions) return undefined;

  let updatedColumnManagerOption = [...columnManagerOptions];
  if (!isTargetSummaryEnabled) {
    updatedColumnManagerOption = updatedColumnManagerOption.filter(
      (option) => option.value !== DATA_INSPECTOR_COLUMN_NAME.DateAdded
    );
  }

  updatedColumnManagerOption = updatedColumnManagerOption.filter(
    (option) => option.value !== DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat
  );

  if (!hasCampaignNegativeTargets) {
    return updatedColumnManagerOption.filter((options) => {
      if (isSponsoredVideosEditable) {
        return true;
      }

      return ![
        DATA_INSPECTOR_COLUMN_NAME.KeywordReviewStatus,
        DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus,
        DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus,
        DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType,
        DATA_INSPECTOR_COLUMN_NAME.CampaignDailyBudget,
        DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget,
        DATA_INSPECTOR_COLUMN_NAME.CampaignStatus,
        DATA_INSPECTOR_COLUMN_NAME.DirectAdSales,
        DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue,
        DATA_INSPECTOR_COLUMN_NAME.DirectACOS,
        DATA_INSPECTOR_COLUMN_NAME.DirectROAS,
        DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits,
        DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits,
      ].includes(options.value);
    });
  }
  return updatedColumnManagerOption.filter((options) => {
    if (isSponsoredVideosEditable) {
      return (
        options.value !== DATA_INSPECTOR_COLUMN_NAME.AdGroupName &&
        options.value !== DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus
      );
    }

    if (
      options.value !== DATA_INSPECTOR_COLUMN_NAME.AdGroupName &&
      options.value !== DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus
    ) {
      return ![
        DATA_INSPECTOR_COLUMN_NAME.KeywordReviewStatus,
        DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus,
        DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus,
        DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType,
        DATA_INSPECTOR_COLUMN_NAME.CampaignDailyBudget,
        DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget,
        DATA_INSPECTOR_COLUMN_NAME.CampaignStatus,
        DATA_INSPECTOR_COLUMN_NAME.DirectAdSales,
        DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue,
        DATA_INSPECTOR_COLUMN_NAME.DirectACOS,
        DATA_INSPECTOR_COLUMN_NAME.DirectROAS,
        DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits,
        DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits,
      ].includes(options.value);
    }
    return false;
  });
};

const getExportRequest = (
  request: TargetsDataRequest,
  omittedTargetDetailsFieldsWithoutMandatoryColumns: string[],
  apiColumnsWithAdLevel: ApiColumnsWithAdLevel,
  hideCampaignAdFormat?: boolean
) => {
  const { channelSettingsFields, performanceFields } = request;

  const shouldPassViewTarget =
    apiColumnsWithAdLevel.adLevel === AdLevel.ProductTargets ||
    apiColumnsWithAdLevel.adLevel === AdLevel.Targets;

  let targetDetailsFields = [
    ...omittedTargetDetailsFieldsWithoutMandatoryColumns.map((column) =>
      column === TARGETS_API_COLUMN_NAME.Keyword
        ? TARGETS_API_COLUMN_NAME.TargetText
        : column
    ),
    ...(shouldPassViewTarget ? [TARGETS_API_COLUMN_NAME.ViewsTargetText] : []),
  ];

  if (hideCampaignAdFormat) {
    targetDetailsFields = targetDetailsFields.filter(
      (column) => column !== TARGETS_API_COLUMN_NAME.CampaignAdFormat
    );
  }

  return {
    ...request,
    targetDetailsFields,
    channelSettingsFields: channelSettingsFields.filter((column) =>
      apiColumnsWithAdLevel.columns.includes(column)
    ),
    performanceFields: performanceFields.filter((column) =>
      apiColumnsWithAdLevel.columns.includes(column)
    ),
  };
};

const getFilteredSelectedColumns = (
  selectedColumns: string[] | undefined,
  hasCampaignNegativeTargets: boolean,
  isTargetSummaryEnabled?: boolean
) => {
  if (!selectedColumns) return undefined;

  let updatedColumnManagerOption = [...selectedColumns];
  if (!isTargetSummaryEnabled) {
    updatedColumnManagerOption = updatedColumnManagerOption.filter(
      (option) => option !== DATA_INSPECTOR_COLUMN_NAME.DateAdded
    );
  }

  updatedColumnManagerOption = updatedColumnManagerOption.filter(
    (option) => option !== DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat
  );

  if (!hasCampaignNegativeTargets) {
    return updatedColumnManagerOption;
  }

  return updatedColumnManagerOption.filter(
    (column) =>
      column !== DATA_INSPECTOR_COLUMN_NAME.AdGroupName &&
      column !== DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus
  );
};

const updateCurrencyCode = (
  tableChange: TableChange,
  targetTableData: TargetsDetails[],
  dispatch: Dispatch<any>
) => {
  const orginalGlobal = cloneDeep(
    tableChange.global
  ) as TableChangeGlobalAdLevelCurrency;

  const currentVisibleCurrencyCode = targetTableData
    .filter((target) => target.channelSettings.bid?.amount)
    .reduce((currencyCodes, target) => {
      return {
        ...currencyCodes,
        [target.targetId]: target.channelSettings.bid?.currency,
      };
    }, {});

  const updatedCurrencyCode = {
    ...orginalGlobal.currencyCode,
    ...currentVisibleCurrencyCode,
  };

  const modifiedGlobal: TableChangeGlobalAdLevelCurrency = {
    ...orginalGlobal,
    currencyCode: updatedCurrencyCode,
  };

  const equal = isEqual(orginalGlobal, modifiedGlobal);

  if (!equal) {
    dispatch(
      tableActions.updateGlobal({
        tableId: TABLE_ID,
        updatedValues: modifiedGlobal,
      })
    );
  }
};

const getValidBidCount = (
  updatedCells: TableCellChangeTarget,
  selectedAdType: AdType,
  targetTableData: TargetsDetails[],
  selectedSalesChannel: FlywheelSalesChannel,
  merchantCountry: MerchantCountryCode,
  bidConstraintsData: BidConstraintsContextState,
  merchantType?: MerchantType
) => {
  return Object.keys(updatedCells).filter((targetId: string) => {
    if (!isNil(updatedCells[targetId].bid)) {
      const updatedCellDetails = {
        bid: updatedCells[targetId].bid,
        targetId: targetId,
      };

      const targetDetails = targetTableData.find(
        (row) => row.targetId === targetId
      )?.targetDetails;

      const { minBid, maxBid } = getBidConstraint(
        bidConstraintsData.constraints,
        selectedAdType === AdType.SponsoredBrands &&
          !isCampaignAdFormatProductCollectionOrSpotlight(
            getCampaignAdFormatForTarget(targetId, targetTableData)
          )
          ? AdType.SponsoredBrandsVideo
          : selectedAdType,
        selectedSalesChannel,
        TARGETS_API_COLUMN_NAME.Bid,
        merchantCountry,
        targetDetails?.campaignTargetingType as CampaignTargetingType,
        merchantType,
        targetDetails?.campaignCostType
      );

      const dailyBudget = targetTableData.find(
        (row) => row.targetId === targetId
      )?.targetDetails?.campaignDailyBudget?.amount;

      return (
        isValueBetweenMinAndMaxValue(
          Number(minBid),
          Number(maxBid),
          updatedCellDetails.bid
        ) &&
        (isUndefined(dailyBudget) ||
          Number(dailyBudget) >= Number(updatedCellDetails.bid))
      );
    }
    return false;
  }).length;
};

const shouldShowCreateKeywordsMenu = (
  selectedAdType: AdType,
  adLevelDropDownSelectedValues: AdLevel,
  optimizelyContext: OptimizelyContextState
) => {
  if (
    adLevelDropDownSelectedValues === AdLevel.ProductTargets &&
    (selectedAdType === AdType.SponsoredProducts ||
      selectedAdType === AdType.SponsoredBrands)
  ) {
    return true;
  }

  if (
    adLevelDropDownSelectedValues === AdLevel.Targets &&
    selectedAdType === AdType.SponsoredDisplay
  ) {
    return true;
  }

  if (
    adLevelDropDownSelectedValues === AdLevel.KeywordTargets &&
    selectedAdType === AdType.SponsoredVideos
  ) {
    return optimizelyContext.featureFlags[
      OptimizelyFlags.SponsoredVideosEditable
    ];
  }

  if (adLevelDropDownSelectedValues !== AdLevel.KeywordTargets) {
    return false;
  }

  return [
    AdType.SponsoredProducts,
    AdType.SponsoredBrands,
    AdType.SearchBrandAmplifier,
    AdType.SponsoredVideos,
  ].includes(selectedAdType);
};

const handleLeaveWithoutSaveClick = (
  toggleTableMode: () => void,
  setClickedOnView: (value: boolean) => void,
  switchLevelController: (adLevel: string) => void,
  saveChangesFlagContext: SaveChangesFlagContextState,
  navigate: NavigateFunction,
  adLevel?: string,
  clickedOnView?: boolean
) => {
  if (clickedOnView) {
    toggleTableMode();
    setClickedOnView(false);
  } else if (saveChangesFlagContext.saveChangesData.aoAdLevel) {
    switchLevelController(adLevel ?? '');
  } else {
    navigate(saveChangesFlagContext.saveChangesData.navPath);
  }
};

const isNegativeTarget = (
  adsManagerDetailsPageFlag: boolean,
  selectedAdType: AdType,
  identifiedAdLevel: AdLevel,
  selectedAdLevel: AdLevel
) =>
  adsManagerDetailsPageFlag &&
  selectedAdType === AdType.SponsoredProducts &&
  identifiedAdLevel === AdLevel.Campaigns &&
  selectedAdLevel === AdLevel.KeywordTargets;

const initBeforeUnLoad = (
  isTableDataEdited: boolean,
  activeSliderIndex: number
) => {
  window.onbeforeunload = (event: Event) => {
    if (isTableDataEdited && activeSliderIndex === 1) {
      event.preventDefault();
      return '';
    }
  };
};

const isTargetIdEligible = ({
  targetDetails: { adGroupStatus, campaignStatus },
}: TargetsDetails) =>
  !isAdGroupStatusArchived(adGroupStatus) &&
  !isCampaignStatusArchived(campaignStatus);

const getSelectedRows = (
  allEligibleTargetIds: string[],
  selectedRowIds: string[]
) =>
  allEligibleTargetIds.length === selectedRowIds.length
    ? []
    : allEligibleTargetIds;

const saveChangesData = (
  saveChangesFlagContext: SaveChangesFlagContextState
) => {
  if (saveChangesFlagContext.saveChangesData.editDataFlag) {
    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      saveChangesModalIsOpen: true,
    });
  }
};

const getCampaignType = (
  identifiedAdLevel: AdLevel,
  adLevelDropDownSelectedValues: AdLevel,
  targetTableData: TargetsDetails[]
) =>
  identifiedAdLevel === AdLevel.AdGroups &&
  adLevelDropDownSelectedValues === AdLevel.KeywordTargets
    ? targetTableData?.[0]?.targetDetails.campaignTargetingType
    : undefined;

const isExistingFilter = (currentFilter: Filter) =>
  ALL_AD_LEVELS_FILTER_FILED_MAPPER.find(
    (filter) => filter.alias === currentFilter.field
  );

const getCreateKeywordActionButton = (
  selectedAdType: AdType,
  optimizelyContext: OptimizelyContextState,
  onAddKeywords: (keyword: KeywordType) => void,
  showNegativeKeywords?: boolean,
  adLevelDropDownSelectedValues?: AdLevel,
  isUserRoleViewOnly?: boolean
) =>
  shouldShowCreateKeywordsMenu(
    selectedAdType,
    adLevelDropDownSelectedValues as AdLevel,
    optimizelyContext
  )
    ? [
        <CreateKeywordsMenu
          onAddKeywords={onAddKeywords}
          showKeywords={
            adLevelDropDownSelectedValues === AdLevel.KeywordTargets
          }
          showNegativeKeywords={showNegativeKeywords}
          showSPKeywordCampaign={false}
          showContextualTargets={
            adLevelDropDownSelectedValues === AdLevel.Targets
          }
          showChatGPT={
            selectedAdType === AdType.SponsoredProducts &&
            adLevelDropDownSelectedValues === AdLevel.KeywordTargets
          }
          showProducts={
            adLevelDropDownSelectedValues === AdLevel.ProductTargets
          }
          key={`${ADS_MANAGER_TARGETS_TABLE_ID}_CREATE_KEYWORDS_MENU`}
          openSelectMenuOnLeft={true}
          isUserRoleViewOnly={!!isUserRoleViewOnly}
        />,
      ]
    : [];

const getBulkEditModalConfirmation = (
  confirmationModalVariant: Variant | null,
  confirmationModalClick: (action: ConfirmationModalClickKind) => void
) =>
  confirmationModalVariant && (
    <BulkEditConfirmationModal
      showModal={!!confirmationModalVariant}
      variant={confirmationModalVariant}
      onPrimaryButtonClick={() =>
        confirmationModalClick(ConfirmationModalClickKind.APPLY)
      }
      onSecondryButtonClick={() =>
        confirmationModalClick(ConfirmationModalClickKind.CANCEL)
      }
    />
  );

const showEditButton = (tableMode: DualModes, selectedRowIds: string[]) =>
  tableMode === DualModes.Edit && selectedRowIds.length > 0;

const getOmittedTargetDetailsFieldsWithoutMandatoryColumns = (
  targetDetailsFieldsWithoutMandatoryColumns: string[],
  selectedSalesChannelName: string,
  adLevelDropDownSelectedValues?: AdLevel
) =>
  adLevelDropDownSelectedValues
    ? targetDetailsFieldsWithoutMandatoryColumns.filter((currentColumn) => {
        const toBeOmittedValues =
          AD_LEVEL_API_COLUMNS_TO_EXCLUDE[
            selectedSalesChannelName as FlywheelSalesChannel
          ][adLevelDropDownSelectedValues];

        return !toBeOmittedValues.includes(currentColumn);
      })
    : targetDetailsFieldsWithoutMandatoryColumns;

const getTableActionRowRightSideElements = ({
  intl,
  tableMode,
  aoApiClient,
  exportButtonState,
  setExportButtonState,
  selectedSalesChannelName,
  request,
  omittedTargetDetailsFieldsWithoutMandatoryColumns,
  apiColumnsWithAdLevel,
  hideCampaignAdFormat,
  adLevelDropDownSelectedValues,
  detailsPageFiltersForTargets,
  startDate,
  endDate,
  selectedAdType,
  transformFilters,
}: {
  intl: IntlShape;
  tableMode: DualModes;
  aoApiClient: AOApiClient;
  exportButtonState: ButtonState;
  setExportButtonState: (state: ButtonState) => void;
  selectedSalesChannelName: string;
  request: TargetsDataRequest;
  omittedTargetDetailsFieldsWithoutMandatoryColumns: string[];
  apiColumnsWithAdLevel: ApiColumnsWithAdLevel;
  hideCampaignAdFormat?: boolean;
  adLevelDropDownSelectedValues?: AdLevel;
  detailsPageFiltersForTargets?: Filter[];
  startDate: DateTime;
  endDate: DateTime;
  selectedAdType: AdType;
  transformFilters?: (filters: Filter[]) => Filter[];
}) =>
  tableMode === DualModes.View
    ? [
        <Tooltip
          overwrittenTooltipClassnames="w-auto max-w-400"
          theme={Theme.Dark}
          content={intl.formatMessage({
            id: I18nKey.GENERIC_DOWNLOAD_CSV,
          })}
          tooltipSize={TooltipSize.Small}
          hideArrow
        >
          <ExportAdLevelData<TargetsDataRequest>
            aoApiClient={aoApiClient}
            buttonState={exportButtonState}
            setButtonState={setExportButtonState}
            salesChannel={selectedSalesChannelName}
            request={getExportRequest(
              request,
              omittedTargetDetailsFieldsWithoutMandatoryColumns,
              apiColumnsWithAdLevel,
              hideCampaignAdFormat
            )}
            adLevelDropDownSelectedValue={
              adLevelDropDownSelectedValues || undefined
            }
            additionalFilters={detailsPageFiltersForTargets}
            filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
            startDate={startDate}
            endDate={endDate}
            adType={selectedAdType}
            transformFilters={transformFilters}
            key={'target_table_export_ad_level_data'}
            hideExportText={true}
          />
        </Tooltip>,
      ]
    : [];

const discardCurrentChanges = (
  actionType: Variant,
  updatedCells:
    | TableCellChangeProductAd
    | TableCellChangeAdGroup
    | TableCellChangeTarget
    | TableCellChangeCampaign
    | TableCellChangeProfile,
  discardChanges: () => void,
  setActionType: () => void
) => {
  if (actionType === Variant.Discard && areNoChangesDone(updatedCells)) {
    discardChanges();
    return;
  }
  setActionType();
};

const updateBulkEditChanges =
  (
    tableChange: TableChange,
    targetTableData: TargetsDetails[],
    bulkEditChanges: TableCellChange,
    selectedAdType: AdType
  ) =>
  ([bulkMenuValue, value]: [string, any]) => {
    let operator: BulkEditModalNumericValueOperator;
    let newValue = value;
    if (bulkMenuValue === TARGETS_API_COLUMN_NAME.Bid) {
      operator = value.operator;
      newValue = value.value;
    }
    tableChange.select.rows.forEach((rowId) => {
      const currentTarget = targetTableData.find(
        ({ targetId }) => targetId === rowId
      );

      const keywordType = currentTarget?.targetDetails?.keywordType;
      if (
        isAdGroupStatusArchived(currentTarget?.targetDetails?.adGroupStatus) ||
        (bulkMenuValue === TARGETS_API_COLUMN_NAME.Bid &&
          (isNegativeKeywordTarget(keywordType) ||
            isNegativeProductTarget(keywordType))) ||
        (newValue === TargetStatus.Paused &&
          (isNegativeKeywordTarget(keywordType) ||
            isNegativeProductTarget(keywordType)))
      ) {
        return;
      }
      const output = getBulkChangesAndInvalidCountForRowId(
        rowId,
        bulkMenuValue,
        tableChange,
        [newValue],
        targetTableData,
        0,
        Infinity,
        selectedAdType,
        operator ? [operator] : []
      );
      const { bulkChanges: bulkChangesForRowId } = output;

      if (isNil(bulkEditChanges[rowId])) {
        bulkEditChanges[rowId] = {};
      }
      bulkEditChanges[rowId] = {
        ...bulkEditChanges[rowId],
        ...bulkChangesForRowId[rowId],
      };
    });
  };

const getTargetDetailsFieldsWithoutMandatoryColumns = (
  targetDetailsFieldsWithoutMandatoryColumns: string[],
  hasCampaignNegativeTargets: boolean
) => {
  if (hasCampaignNegativeTargets) {
    return targetDetailsFieldsWithoutMandatoryColumns.filter(
      (column) =>
        ![
          TARGETS_API_COLUMN_NAME.AdGroupName,
          TARGETS_API_COLUMN_NAME.AdGroupStatus,
          EXT_ADGROUP_ID,
        ].includes(column)
    );
  }

  return targetDetailsFieldsWithoutMandatoryColumns;
};

const getNegativeKeywordsFlag = (
  selectedSalesChannel: FlywheelSalesChannel,
  adLevel: AdLevel
) =>
  adLevel === AdLevel.KeywordTargets &&
  selectedSalesChannel === FlywheelSalesChannel.Amazon;

const getExternalIdsColumns = (adLevelDropDownSelectedValues: AdLevel) =>
  adLevelDropDownSelectedValues
    ? AD_LEVEL_EXTERNAL_ID_MAPPER[adLevelDropDownSelectedValues]
    : [];

const targetDetailsFieldsFilter = (column: string) =>
  TARGETS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
  TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields;

export const TableTargets: React.FC<
  TableTargetsProps
  // eslint-disable-next-line max-statements
> = ({
  apiColumnsWithAdLevel,
  tableMode,
  aoApiClient,
  exportButtonState,
  request,
  adLevelDropDownSelectedValues,
  activeSliderIndex,
  dataFetcher,
  setExportButtonState,
  toggleTableMode,
  selectedSalesChannelName,
  onFilterUpdate,
  onEditSave,
  detailsPageFiltersForTargets,
  filtersFromBrowserStorage,
  storeFiltersInBrowserStorage,
  adTypes,
  selectedSalesChannel,
  selectedAdLevel,
  selectedAdType,
  merchantCountry,
  merchantType,
  startDate,
  endDate,
  selectedMerchantCountries,
  columnManagerOptions,
  onColumnSelectionChange = noop,
  selectedColumns,
  switchLevelController,
  isAIEnabledForOneOfTheSelectedMerchants = true,
  isSaveChangesLoading,
  merchantsWithAutomationEnabled,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef<boolean>(false);
  const firstApiCall = useRef<boolean>(true);
  const [clickedOnView, setClickedOnView] = useState<boolean>(false);
  const [isFilterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);
  const merchantContext = useContext<MerchantContextState>(MerchantContext);
  const bidConstraintsData = useBidConstraintsContext();

  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);

  const merchantDetails = merchantContext.merchantDetails;
  const { identifiedAdLevel, adsManagerDetailsPageFlag } = getAdLevelInfo(
    location.pathname.split('/')
  );
  const [showCreateKeywordModal, setShowCreateKeywordModal] = useState(false);
  const [keywordType, setKeywordType] = useState(KeywordType.Keyword);

  const [confirmationModalVariant, setConfirmationModalVariant] =
    useState<Variant | null>(null);

  const hasCampaignNegativeTargets = isNegativeTarget(
    adsManagerDetailsPageFlag,
    selectedAdType,
    identifiedAdLevel,
    selectedAdLevel
  );

  const userContext = useContext<UserContextState>(UserContext);
  const isAiEnabled = isAIPlanEnabled(userContext);

  const changedCount = useSelector<WithTable<TargetsDetails>, number>(
    ({ tableState }) => {
      return tableSelectors.getChangedCountSelector()(tableState, TABLE_ID);
    }
  );

  const isUserRoleViewOnly = getUserRoleMatch(
    Role.VIEW_ONLY,
    userContext.userInfo.userDetails
  );

  const tableChange = useSelector<WithTable<TargetsDetails>, TableChange>(
    ({ tableState }) =>
      tableSelectors.getChangeSelector()(
        tableState,
        ADS_MANAGER_TARGETS_TABLE_ID
      )
  );

  const targetTableData = useSelector<
    WithTable<TargetsDetails>,
    TargetsDetails[]
  >(({ tableState }) =>
    tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<TargetsDetails, void>()(
        tableState,
        ADS_MANAGER_TARGETS_TABLE_ID
      )
    )
  );

  useEffect(() => {
    updateCurrencyCode(tableChange, targetTableData, dispatch);
  }, [targetTableData]);

  useEffect(() => {
    showHideIntercomLauncher(showCreateKeywordModal);
  }, [showCreateKeywordModal]);

  const saveChangesFlagContext = useContext<SaveChangesFlagContextState>(
    SaveChangesFlagContext
  );

  useEffect(() => {
    saveChangesFlagContext.updateSaveChangesData({
      editDataFlag: changedCount > 0,
      saveChangesModalIsOpen: false,
      navPath: saveChangesFlagContext.saveChangesData.navPath,
    });
  }, [changedCount]);

  const isTableDataEdited: boolean =
    saveChangesFlagContext.saveChangesData.editDataFlag;

  useEffect(() => {
    initBeforeUnLoad(isTableDataEdited, activeSliderIndex);
  }, [isTableDataEdited]);

  const allEligibleTargetIds = targetTableData
    .filter(isTargetIdEligible)
    .map(({ targetId }) => targetId);

  const handleSelectChange = () => {
    dispatch(
      tableActions.changeSelect({
        tableId: ADS_MANAGER_TARGETS_TABLE_ID,
        selectedRows: getSelectedRows(allEligibleTargetIds, selectedRowIds),
      })
    );
  };

  const getVisibleColumnsTargets = (): FlywheelTableColumnGroup<
    TargetsDetails,
    TableDataAdsManager
  >[] => {
    const targetsColumns = generatedTargetsColumns(
      tableMode === DualModes.Edit,
      selectedSalesChannel,
      isAIEnabledForOneOfTheSelectedMerchants,
      hasCampaignNegativeTargets,
      isAiEnabled,
      {
        checked: getCheckBoxState(
          allEligibleTargetIds.length,
          selectedRowIds.length
        ),
        onSelectChange: handleSelectChange,
      }
    );

    return targetsColumns
      .map((column) => {
        const columnInGroups = column.columnsInGroup.filter((subColumn) =>
          apiColumnsWithAdLevel.columns.includes(subColumn.columnName)
        );
        return {
          ...column,
          columnsInGroup: columnInGroups.filter((col) => {
            if (
              selectedAdType !== AdType.SponsoredVideos ||
              optimizelyContext.featureFlags[
                OptimizelyFlags.SponsoredVideosEditable
              ]
            ) {
              return true;
            }

            return ![
              TARGETS_API_COLUMN_NAME.KeywordReviewStatus,
              TARGETS_API_COLUMN_NAME.AdGroupStatus,
              TARGETS_API_COLUMN_NAME.AdGroupReviewStatus,
              TARGETS_API_COLUMN_NAME.CampaignTargetingType,
              TARGETS_API_COLUMN_NAME.DailyBudget,
              TARGETS_API_COLUMN_NAME.TotalBudget,
              TARGETS_API_COLUMN_NAME.CampaignStatus,
              TARGETS_API_COLUMN_NAME.DirectAdSales,
              TARGETS_API_COLUMN_NAME.RelatedClickRevenue,
              TARGETS_API_COLUMN_NAME.DirectACOS,
              TARGETS_API_COLUMN_NAME.DirectROAS,
              TARGETS_API_COLUMN_NAME.AdvertisedSkuUnits,
              TARGETS_API_COLUMN_NAME.OtherSkuUnits,
            ].includes(col.columnName);
          }),
        };
      })
      .filter((column) => column.columnsInGroup.length > 0);
  };

  const onAddKeywords = (type: KeywordType) => {
    if (type === KeywordType.ChatGPT) {
      navigate(
        `${location.pathname}/create-chatgpt${
          location.search ? location.search : ''
        }`
      );
    } else {
      setShowCreateKeywordModal(true);
      setKeywordType(type);
    }
  };

  const onCloseAddKeywordsModal = (success?: boolean) => {
    setShowCreateKeywordModal(false);
    if (success) {
      updateFilters([
        {
          field: TARGETS_API_COLUMN_NAME.AddedBy.toString(),
          op: FilterOps.in,
          value: [
            keywordType === KeywordType.ChatGPT
              ? SelectFilterOption.ChatGPT.value
              : SelectFilterOption.FlywheelOnDemand.value,
          ],
        },
      ]);
    }
  };

  const onKeepEditingClick = () => {
    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      saveChangesModalIsOpen: false,
      aoAdLevel: undefined,
    });
    setClickedOnView(false);
  };

  const onLeaveWithoutSaveClick = () => {
    const adLevel = saveChangesFlagContext.saveChangesData.aoAdLevel;

    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
      aoAdLevel: undefined,
    });

    handleLeaveWithoutSaveClick(
      toggleTableMode,
      setClickedOnView,
      switchLevelController,
      saveChangesFlagContext,
      navigate,
      adLevel,
      clickedOnView
    );
  };

  const clearAllChanges = () =>
    dispatch(
      tableActions.clearAllChanges({
        tableId: TABLE_ID,
      })
    );

  const handleOperationsAsPerTableMode = (mode: DualModes) => {
    if (!isUserRoleViewOnly) {
      switch (mode) {
        case DualModes.View:
          setClickedOnView(true);
          if (saveChangesFlagContext.saveChangesData.editDataFlag) {
            saveChangesFlagContext.updateSaveChangesData({
              ...saveChangesFlagContext.saveChangesData,
              saveChangesModalIsOpen: true,
            });
          } else {
            toggleTableMode();
          }
          break;
        case DualModes.Edit:
          saveChangesData(saveChangesFlagContext);
          toggleTableMode();
          break;
        default:
          clearAllChanges();
          break;
      }
    }
  };

  const discardChanges = () => {
    toggleTableMode();

    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
    });

    clearAllChanges();
  };

  const updatedCells = tableChange.cell as TableCellChangeTarget;

  const validBidCount = getValidBidCount(
    updatedCells,
    selectedAdType,
    targetTableData,
    selectedSalesChannel,
    merchantCountry,
    bidConstraintsData,
    merchantType
  );

  const validTargetStatusCount = Object.values(updatedCells).filter(
    ({ targetStatus }) => !isNil(targetStatus)
  ).length;

  const validTargetLabelCount = Object.values(updatedCells).filter(
    ({ targetLabel }) => !isNil(targetLabel)
  ).length;

  const validRowsCount =
    validBidCount + validTargetStatusCount + validTargetLabelCount;

  // export api request:
  const externalIdColumns = getExternalIdsColumns(
    adLevelDropDownSelectedValues as AdLevel
  );

  // Only those columns should be exported which are present in column mananger
  let selectedColumnsFromColumnManager = apiColumnsWithAdLevel.columns.filter(
    targetDetailsFieldsFilter
  );

  // Extra columns specially required when ad level is product targets
  // MANDATORY_TARGETS_COLUMNS_TARGET_DETAILS can not be used since we do not want orher columns like campaign ad format
  // These columns can not go inside FLYWHEEL_SALES_CHANNEL_TO_DEFAULT_AD_LEVEL_COLUMNS_MAPPER, since these columns should not be present in apiColumnsWithAdLevel (because we do not want to display these column in the table)

  const { productTargetColumns, targetColumns } = getTargetColumns(
    adLevelDropDownSelectedValues
  );

  // replace merchant country id if selected with merchant merchant name
  selectedColumnsFromColumnManager = replaceMerchantCountryIdField(
    selectedColumnsFromColumnManager
  );

  let targetDetailsFieldsWithoutMandatoryColumns = [
    ...externalIdColumns,
    ...getAllExternalIdColumns(apiColumnsWithAdLevel),
    ...productTargetColumns,
    ...targetColumns,
    ...selectedColumnsFromColumnManager,
  ];

  targetDetailsFieldsWithoutMandatoryColumns =
    getTargetDetailsFieldsWithoutMandatoryColumns(
      targetDetailsFieldsWithoutMandatoryColumns,
      hasCampaignNegativeTargets
    );

  // TARGETS_API_COLUMN_NAME.TargetingExpression needs to be omitted, since this is custom made column used only in UI, and not meant for backend request
  const omittedTargetDetailsFieldsWithoutMandatoryColumns =
    getOmittedTargetDetailsFieldsWithoutMandatoryColumns(
      targetDetailsFieldsWithoutMandatoryColumns,
      selectedSalesChannelName,
      adLevelDropDownSelectedValues as AdLevel
    );

  const transformFilters = getTargetingExpressionAndTargetLabelFilters(intl, [
    TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION,
  ]);

  const tableActionRowRightSideElements = getTableActionRowRightSideElements({
    intl,
    tableMode,
    aoApiClient,
    apiColumnsWithAdLevel,
    endDate,
    exportButtonState,
    omittedTargetDetailsFieldsWithoutMandatoryColumns,
    request,
    selectedAdType,
    selectedSalesChannelName,
    setExportButtonState,
    startDate,
    adLevelDropDownSelectedValues: adLevelDropDownSelectedValues as AdLevel,
    detailsPageFiltersForTargets,
    hideCampaignAdFormat: true,
    transformFilters,
  });

  useEffect(() => {
    if (!firstApiCall.current) {
      dispatch(
        tableThunks.refreshTable(ADS_MANAGER_TARGETS_TABLE_ID, dataFetcher)
      );
    } else {
      firstApiCall.current = false;
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (ref.current && hasTimeElapsed()) {
      updateTime();
      dispatch(
        tableThunks.refreshTable(ADS_MANAGER_TARGETS_TABLE_ID, dataFetcher)
      );
    } else {
      ref.current = true;
      if (location.state && location.state.filter) {
        let { filter } =
          (location.state && (location.state as { filter: Filter[] })) || [];
        dispatch(
          tableActions.updateFilters({
            tableId: ADS_MANAGER_TARGETS_TABLE_ID,
            filters: filter || currentTableFilters,
            replace: true,
          })
        );
      }
    }
  }, [apiColumnsWithAdLevel.columns]);

  const { hasTimeElapsed, updateTime } = useTime(
    parseInt(
      process.env.REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES ??
        REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES
    )
  );

  // pass campaignType only when its Ad Group details page
  const campaignType = getCampaignType(
    identifiedAdLevel,
    adLevelDropDownSelectedValues as AdLevel,
    targetTableData
  );

  const dataFields = getFilterColumnDefinitons(
    CURRENCY_CODE,
    (selectedSalesChannelName as FlywheelSalesChannel) || null,
    first(adTypes),
    adLevelDropDownSelectedValues || undefined,
    merchantDetails,
    true,
    undefined,
    isAIEnabledForOneOfTheSelectedMerchants,
    campaignType,
    isAiEnabled,
    hasCampaignNegativeTargets,
    undefined,
    false,
    optimizelyContext.featureFlags[OptimizelyFlags.SponsoredVideosEditable]
  );

  const currentTableFilters = useSelector<WithTable<TargetsDetails>, Filter[]>(
    ({ tableState }) => tableState[ADS_MANAGER_TARGETS_TABLE_ID].filters
  );

  let currentFilters = [
    ...currentTableFilters,
    ...(filtersFromBrowserStorage ?? []),
  ];

  const updateFilters = (filters: Filter[]) => {
    const existingSearchFilters: Filter[] =
      currentFilters.filter(isExistingFilter);
    const newFilters: Filter[] = [...existingSearchFilters, ...filters];
    dispatch(
      tableActions.updateFilters({
        tableId: ADS_MANAGER_TARGETS_TABLE_ID,
        filters: newFilters,
        replace: true,
      })
    );
    upgradeFiltersInStorage(storeFiltersInBrowserStorage)(newFilters);
    if (onFilterUpdate) {
      onFilterUpdate(newFilters);
    }
  };
  const [selections, setSelections] = useState<Record<string, boolean>>({});
  const [isBulkEditSlideOutOpen, setBulkEditModalOpen] =
    useState<boolean>(false);

  const onSelect = (key: string) => (value: boolean) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      [key]: value,
    }));
  };

  const selectedRowIds = useSelector<WithTable<TargetsDetails>, string[]>(
    ({ tableState }) =>
      tableSelectors.getSelectedRowsSelector()(
        tableState,
        ADS_MANAGER_TARGETS_TABLE_ID
      )
  );

  const onActionClick = (actionType: Variant) =>
    discardCurrentChanges(actionType, updatedCells, discardChanges, () =>
      setConfirmationModalVariant(actionType)
    );

  const onSubmitHandler = (bulkEditValues: BulkEditValues) => {
    const bulkEditChanges: TableCellChange = {};

    Object.entries(bulkEditValues).forEach(
      updateBulkEditChanges(
        tableChange,
        targetTableData,
        bulkEditChanges,
        selectedAdType
      )
    );

    dispatch(
      tableActions.applyBulkChanges({
        tableId: ADS_MANAGER_TARGETS_TABLE_ID,
        bulkChanges: {
          ...tableChange.cell,
          ...bulkEditChanges,
        },
        columnDataMapping: EDIT_TARGETS_API_COLUMN_DATA_MAPPING,
        uniqKey: TABLE_UNIQ_KEY[ADS_MANAGER_TARGETS_TABLE_ID],
      })
    );
    setSelections({});
    setBulkEditModalOpen(false);
  };

  useEffect(() => {
    showHideIntercomLauncher(isBulkEditSlideOutOpen);
  }, [isBulkEditSlideOutOpen]);

  const onConfirmationModalAction = (
    kind: ConfirmationModalClickKind,
    discardOrSaveChanges: () => void
  ) => {
    setConfirmationModalVariant(null);
    if (kind === ConfirmationModalClickKind.APPLY) {
      discardOrSaveChanges();
    }
  };

  const confirmationModalClick = (kind: ConfirmationModalClickKind) => {
    switch (confirmationModalVariant) {
      case Variant.Apply:
        onConfirmationModalAction(kind, onEditSave);
        break;
      case Variant.Discard:
        onConfirmationModalAction(kind, discardChanges);
    }
  };

  const showNegativeKeywords = getNegativeKeywordsFlag(
    selectedSalesChannel,
    adLevelDropDownSelectedValues as AdLevel
  );

  if (
    [AdLevel.KeywordTargets, AdLevel.ProductTargets, AdLevel.Targets].includes(
      adLevelDropDownSelectedValues as AdLevel
    ) &&
    !location.pathname.includes('/create-chatgpt')
  ) {
    return (
      <>
        <KeepEditingModal
          showModal={
            saveChangesFlagContext.saveChangesData.saveChangesModalIsOpen
          }
          onKeepEditing={onKeepEditingClick}
          onDiscard={onLeaveWithoutSaveClick}
          tableId={TABLE_ID}
        />
        <TableActionRow
          className="mt-16"
          leftSideElements={[
            <FlywheelSearchInput
              tableId={ADS_MANAGER_TARGETS_TABLE_ID}
              inputSearchColumnName={
                adLevelDropDownSelectedValues === AdLevel.ProductTargets
                  ? TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION
                  : TARGETS_TABLE_SEARCH_KEY
              }
              searchInputSize={SearchInputSize.Medium}
              searchInputPlaceholder={getSearchInputPlaceholder(
                selectedAdType,
                adLevelDropDownSelectedValues as AdLevel,
                intl
              )}
              onFilterUpdate={onFilterUpdate}
              upgradeFiltersInStorage={upgradeFiltersInStorage(
                storeFiltersInBrowserStorage
              )}
              hasSearchInputWithButton
              searchInputClassName="w-480"
              dataTestId={`${ADS_MANAGER_TARGETS_TABLE_ID}_search_input`}
              key={`${ADS_MANAGER_TARGETS_TABLE_ID}_SEARCH_INPUT`}
            />,
            <FiltersMenu
              customFilterIcon={FilterIcon}
              currency={currencyCode as CurrencyCode}
              dataFields={dataFields}
              currentFilters={currentFilters}
              handleSave={updateFilters}
              filterButtonSize={ButtonSize.Medium}
              isOpen={isFilterMenuOpen}
              setOpen={setFilterMenuOpen}
              filterDateFormat={REQUEST_DATE_FORMAT}
              singleDecimalColumns={COLUMNS_WITH_SINGLE_DECIMAL_FILTER}
              dataTestId={`${ADS_MANAGER_TARGETS_TABLE_ID}_filters_menu`}
              filtersContainerClass={
                'transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:left-0'
              }
              key={`${ADS_MANAGER_TARGETS_TABLE_ID}_FILTERS_MENU`}
            />,
          ]}
          rightSideElements={[
            <Tooltip
              overwrittenTooltipClassnames="w-auto max-w-400"
              theme={Theme.Dark}
              content={intl.formatMessage({
                id: I18nKey.GENERIC_COLUMNS,
              })}
              tooltipSize={TooltipSize.Small}
              hideArrow
            >
              <ColumnManager
                variant={ColumnManagerVariant.ColumnGroupWithHeader}
                values={selectedColumns ?? []}
                onChange={onColumnSelectionChange}
                options={getColumnManagerOptions(
                  hasCampaignNegativeTargets,
                  columnManagerOptions,
                  isAIEnabledForOneOfTheSelectedMerchants,
                  (selectedAdType === AdType.SponsoredVideos &&
                    optimizelyContext.featureFlags[
                      OptimizelyFlags.SponsoredVideosEditable
                    ]) ||
                    selectedAdType !== AdType.SponsoredVideos
                )}
                tooltipClass="right-0"
                hideColumnText={true}
                dataTestId={`${ADS_MANAGER_TARGETS_TABLE_ID}_column_manager`}
                key={`${ADS_MANAGER_TARGETS_TABLE_ID}_COLUMN_MANAGER`}
              />
            </Tooltip>,
            ...tableActionRowRightSideElements,
            ...getCreateKeywordActionButton(
              selectedAdType,
              optimizelyContext,
              onAddKeywords,
              showNegativeKeywords,
              adLevelDropDownSelectedValues as AdLevel,
              isUserRoleViewOnly
            ),
          ]}
          fullWidthElementIndex={-1}
        />
        {getBulkEditModalConfirmation(
          confirmationModalVariant,
          confirmationModalClick
        )}
        <BulkEditSlideOut
          editControls={getBulkEditConfigurationForTargets(
            intl,
            selectedAdType,
            selectedAdLevel,
            selectedSalesChannel,
            selections,
            onSelect
          )}
          headerText={intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGNS_SLIDEOUT_HEADER,
          })}
          isOpen={isBulkEditSlideOutOpen}
          onClose={() => setBulkEditModalOpen(false)}
          onSubmit={onSubmitHandler}
          onCancel={() => setBulkEditModalOpen(false)}
          primaryButtonText={intl.formatMessage({
            id: I18nKey.GENERIC_SUBMIT,
          })}
          secondaryButtonText={intl.formatMessage({
            id: I18nKey.CANCEL,
          })}
        />
        {showCreateKeywordModal && (
          <CreateKeywordsModalContainer
            onClose={onCloseAddKeywordsModal}
            dataTestId={'create_keywords_modal'}
            salesChannelId={MAP_SALES_CHANNEL_NAME_TO_ID[selectedSalesChannel]}
            merchantCountryId={getMerchantCountryId(selectedMerchantCountries)}
            merchantType={merchantType!}
            adType={selectedAdType}
            keywordType={keywordType}
          />
        )}

        <UpdatedFlywheelTable<TargetsDetails, TableDataAdsManager>
          tableData={{
            isEditMode: tableMode === DualModes.Edit,
            salesChannel: selectedSalesChannel,
            adTypes,
            adLevel: selectedAdLevel,
            selectedAdType,
            merchantCountry,
            selectedMerchantCountries,
            aoApiClient,
            selectedEndDate: endDate,
            allMerchants: [],
            merchantType,
            aiEnabled: isAiEnabled,
            merchantsWithAutomationEnabled: merchantsWithAutomationEnabled,
            isManageTreAutomationEnabled: true,
            showSmartCampaign: shouldShowSmartCampaigns(optimizelyContext),
          }}
          filtersContainerClass="transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:right-0"
          columns={getVisibleColumnsTargets()}
          tableId={TABLE_ID}
          currencyCode={currencyCode ?? CURRENCY_CODE}
          dataFetcher={dataFetcher}
          footerTotalItemsI18nKey={
            I18nKey.ADS_MANAGER_TARGETS_TABLE_FOOTER_TOTAL_ITEMS_TEXT
          }
          hasStickyHeader
          hasStickyLeftColumn
          errorComponent={<ErrorTable />}
          yesFilterNoDataDisplayComponent={<NoFilteredDataFoundTable />}
          noFilterNoDataDisplayComponent={<NoDataFoundTable />}
          dataFields={dataFields}
          actionRowRightSideComponents={tableActionRowRightSideElements}
          inputSearchColumnName={getInputSearchColumnName(
            selectedSalesChannel,
            selectedAdLevel
          )}
          onFilterUpdate={onFilterUpdate}
          filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
          filtersFromBrowserStorage={filtersFromBrowserStorage}
          storeFiltersInBrowserStorage={storeFiltersInBrowserStorage}
          filterDateFormat={REQUEST_DATE_FORMAT}
          searchInputSize={SearchInputSize.Medium}
          hasSearchInputWithButton
          searchInputPlaceholder={getSearchInputPlaceholder(
            selectedAdType,
            selectedAdLevel,
            intl
          )}
          selectedColumns={getFilteredSelectedColumns(
            selectedColumns,
            hasCampaignNegativeTargets,
            isAIEnabledForOneOfTheSelectedMerchants
          )}
          columnManagerOptions={getColumnManagerOptions(
            hasCampaignNegativeTargets,
            columnManagerOptions,
            isAIEnabledForOneOfTheSelectedMerchants
          )}
          createKeywordsComponent={
            getCreateKeywordActionButton(
              selectedAdType,
              optimizelyContext,
              onAddKeywords,
              showNegativeKeywords,
              adLevelDropDownSelectedValues as AdLevel,
              isUserRoleViewOnly
            )[0]
          }
          onColumnSelectionChange={onColumnSelectionChange}
          dataTestId="table_targets"
          showTableActionRow2={true}
          actionRowV2Props={{
            loadingText: intl.formatMessage({
              id: I18nKey.ADVERTISING_OPTIMIZATION_TABLE_ACTION_ROW_LOADING_TEXT,
            }),
            isLoading: isSaveChangesLoading,
            isActive: selectedRowIds.length > 0,
            selectedCount: selectedRowIds.length,
            totalCount: targetTableData.length,
            showEditButton: showEditButton(tableMode, selectedRowIds),
            sliderMode: tableMode,
            onSliderModeChange: handleOperationsAsPerTableMode,
            showSelectedCount: tableMode === DualModes.Edit,
            className: 'border border-grey-200 rounded-t-sm',
            onEditClick: () => setBulkEditModalOpen(true),
            ...getActionRowButtons(
              tableMode,
              intl,
              getSaveButtonState(changedCount, validRowsCount),
              onActionClick
            ),
            viewOnlyMode: !!isUserRoleViewOnly,
            showSlider:
              selectedAdType !== AdType.SponsoredVideos ||
              (selectedAdType === AdType.SponsoredVideos &&
                optimizelyContext.featureFlags[
                  OptimizelyFlags.SponsoredVideosEditable
                ]),
          }}
          openFilterMenuOnPillClick={() => setFilterMenuOpen(true)}
        />
      </>
    );
  }
  return <></>;
};
TableTargets.displayName = 'TableTargets';
