import {
  ButtonSize,
  ButtonVariant,
  CalendarIcon,
  CashLinearIcon,
  CursorDoubleClickIcon,
  Icon,
  IconSize,
  NumericInput,
  ShoppingBagCheckMarkIcon,
  Slideover,
  getCurrencySymbol,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { createAOApiClient } from '../../lib/clients/AOApiClient';
import { UserPreferenceType } from '../../lib/types/AOSharedTypes';
import I18nKey from '../../lib/types/I18nKey';
import { DAYS_NEG } from '../../lib/types/KeywordAction';
import { CurrencyCode } from '../../lib/utilities/currency';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../lib/utilities/intercom';
import { DEFAULT_CURRENCY } from '../../modules/keywordAction/utils';

interface Criteria {
  readonly clicks: string;
  readonly adConversions: string;
  readonly adSpend?: string;
  readonly previousByNumberOfDays?: string;
}

interface CriteriaSettingsSlideoverProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly criteria: Criteria;
  readonly accountId: string;
  readonly merchantCountryId: string;
  readonly userId: string;
  readonly onSuccessfulSave: (clicks: number, adSpend: number) => void;
  readonly dataTestId?: string;
}

interface CriteriaSettingsContent {
  readonly criteria: Criteria;
  readonly localClicks: string;
  readonly setLocalClicks: Dispatch<SetStateAction<string>>;
  readonly localAdSpend?: string;
  readonly setLocalAdSpend?: Dispatch<SetStateAction<string | undefined>>;
}

enum CriteriaType {
  AdSpend = 'adSpend',
  Clicks = 'clicks',
  AdConversions = 'adConversions',
  PreviousByNumberOfDays = 'previousByNumberOfDays',
}

export const CriteriaSettingsSlideover: React.FC<
  CriteriaSettingsSlideoverProps
> = (props) => {
  const {
    isOpen,
    onClose,
    criteria,
    merchantCountryId,
    accountId,
    userId,
    onSuccessfulSave,
  } = props;
  const intl = useIntl();

  const userContext = useContext<UserContextState>(UserContext);

  const aoApiClient = createAOApiClient(userContext.userInfo.idToken!);

  const [localClicks, setLocalClicks] = useState(criteria.clicks);
  const [localAdSpend, setLocalAdSpend] = useState(criteria.adSpend);

  const title = intl.formatMessage({
    id: I18nKey.KWA_CRITERIA_SETTINGS_HEADER,
  });

  const onSubmit = () => {
    aoApiClient.saveUserPreference(accountId, {
      userId,
      criteriaType: UserPreferenceType.Negative,
      settings: [
        {
          adConversions: Number(criteria.adConversions),
          clicks: Number(localClicks),
          adSpend: {
            amount: localAdSpend?.toString() || '',
            currency: CurrencyCode.USD.toString(),
          },
          name: '',
          merchantCountryId: merchantCountryId,
          duration: DAYS_NEG,
        },
      ],
    });
    onClose();
    onSuccessfulSave(Number(localClicks), Number(localAdSpend));
  };

  useEffect(() => {
    if (isOpen) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [isOpen]);

  return (
    <Slideover
      isOpen={isOpen}
      title={title}
      content={
        <CriteriaSettingsContent
          criteria={criteria}
          localClicks={localClicks}
          setLocalClicks={setLocalClicks}
          localAdSpend={localAdSpend}
          setLocalAdSpend={setLocalAdSpend}
        />
      }
      onClose={onClose}
      dataTestId={props.dataTestId}
      contentClassName="w-512 overflow-y-auto"
      primaryButtonProps={{
        label: intl.formatMessage({ id: I18nKey.GENERIC_SUBMIT }),
        size: ButtonSize.Medium,
        variant: ButtonVariant.Primary,
        onClick: () => onSubmit(),
      }}
      secondaryButtonProps={{
        label: intl.formatMessage({ id: I18nKey.GENERIC_CANCEL }),
        size: ButtonSize.Medium,
        variant: ButtonVariant.BlackAndWhiteBorder,
        onClick: () => onClose(),
      }}
    />
  );
};

const CriteriaSettingsContent = ({
  criteria,
  localClicks,
  localAdSpend,
  setLocalAdSpend,
  setLocalClicks,
}: CriteriaSettingsContent) => {
  const ICON_MAPPER = {
    [CriteriaType.Clicks]: CursorDoubleClickIcon,
    [CriteriaType.AdConversions]: ShoppingBagCheckMarkIcon,
    [CriteriaType.AdSpend]: CashLinearIcon,
    [CriteriaType.PreviousByNumberOfDays]: CalendarIcon,
  };

  const I18NKEY_MAPPER = {
    [CriteriaType.Clicks]: I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_CLICKS,
    [CriteriaType.AdConversions]:
      I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_AD_CONVERSIONS,
    [CriteriaType.AdSpend]:
      I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_AD_SPEND,
    [CriteriaType.PreviousByNumberOfDays]:
      I18nKey.KWA_CRITERIA_SETTINGS_IN_THE_PAST,
  };

  const VALUE_MAPPER = {
    [CriteriaType.Clicks]: localClicks,
    [CriteriaType.AdConversions]: criteria.adConversions,
    [CriteriaType.AdSpend]: localAdSpend,
    [CriteriaType.PreviousByNumberOfDays]: criteria.previousByNumberOfDays,
  };

  return (
    <div className="flex flex-col space-y-16 text-base">
      {Object.keys(criteria).map((item) => (
        <div className="flex justify-between">
          <div className="flex justify-center items-center">
            <Icon
              size={IconSize.Medium}
              svg={ICON_MAPPER[item as CriteriaType]}
              className={classNames('mr-8', {
                'text-grey-400':
                  item === CriteriaType.AdConversions ||
                  item === CriteriaType.Clicks,
                'text-grey-500':
                  item === CriteriaType.AdSpend ||
                  item === CriteriaType.PreviousByNumberOfDays,
              })}
            />
            <FormattedMessage id={I18NKEY_MAPPER[item as CriteriaType]} />
          </div>
          <div className="flex justify-center items-center">
            {item === CriteriaType.Clicks || item === CriteriaType.AdSpend ? (
              <>
                <NumericInput
                  value={VALUE_MAPPER[item as CriteriaType]}
                  onChange={(val) => {
                    if (item === CriteriaType.Clicks) {
                      setLocalClicks(val);
                    }
                    if (item === CriteriaType.AdSpend && setLocalAdSpend) {
                      setLocalAdSpend(val);
                    }
                  }}
                  acceptOnlyIntegers
                  className="w-64 mr-8"
                  prependedElement={
                    item === CriteriaType.AdSpend ? (
                      getCurrencySymbol(DEFAULT_CURRENCY)
                    ) : (
                      <></>
                    )
                  }
                />
                <FormattedMessage id={I18nKey.KWA_CRITERIA_SETTINGS_OR_MORE} />
              </>
            ) : item === CriteriaType.AdConversions ? (
              criteria.adConversions
            ) : item === CriteriaType.PreviousByNumberOfDays ? (
              <FormattedMessage
                id={I18nKey.KWA_CRITERIA_SETTINGS_NUMBER_OF_DAYS}
                values={{
                  days: criteria.previousByNumberOfDays,
                }}
              />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

CriteriaSettingsSlideover.displayName = 'CriteriaSettingsSlideover';
