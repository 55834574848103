import {
  Alignment,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  ChartBuilder,
  ChartTime,
  ContentStyle,
  DownloadIcon,
  FormToggle,
  FormToggleSize,
  FormToggleState,
  Icon,
  IconSize,
  KPICardV2,
  KPICardV2Props,
  KebabLinearIcon,
  KpiCardBenchmarkGroup,
  KpiCardGroupV2,
  MetricCardV2CardState,
  MetricCardV2Props,
  Placement,
  PlotBandPoints,
  TextLink,
  TextLinkSize,
  Theme,
  ToggleTab,
  ToggleTabStyle,
  Tooltip,
  TooltipSize,
  Type,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import {
  CurrencyCode,
  getCurrencySymbol,
} from '../../../../../lib/utilities/currency';
import inRange from 'lodash/inRange';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { HomepageDataRequestResponse, TmIndexDataResponse } from '../../..';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../../../containers/notificationProvider';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import { useUserCredentials } from '../../../../../lib/hooks/useUserCredentials';
import {
  HomepageResponseMetricKeys,
  PerformanceMetrics,
  SalesChannelTmIndexKeys,
} from '../../../../../lib/types/CompassSharedTypes';
import { ProductSubscriptionEventResponse } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { getDaysBetweenDates } from '../../../../advertisingOptimization/containers/adsManager/utils';
import { CompassContextSetterContext } from '../../../compassContextSetterProvider';
import {
  CustomizeMetricsSlideover,
  MetricsData,
} from '../../../components/CustomizeMetricsSlideover';
import {
  getActiveMetricFromLocalStorage,
  getCustomizeSlideoverConfigFromLocalStorage,
  getIsShowPreviousDataFromLocalStorage,
  setActiveMetricFromLocalStorage,
  setCustomizeSlideoverConfigFromLocalStorage,
  setIsShowPreviousDataFromLocalStorage,
} from '../../../storageUtils';
import { ChartTrendMetricFormatSelector, CompassState } from '../../../types';
import { getCsvExportValues, getFileName } from '../exportWithOptionsBusiness';
import {
  getSeasonalEventsData,
  getShowLoading,
  getXAxisLabels,
  rotationXaxis,
} from '../utils/business';
import { BLUE_500, TM_BENCHMARK_HELP_LINK } from './types';
import {
  getCardFooterProps,
  getCompassKPIMetricValue,
  getCurrentValueContent,
  getDefaultMetricsConfiguration,
  getFirstMetricsField,
  getHeroMetricType,
  getKpiBenchmarkCardProps,
  getTickerIntervalPositions,
  getTooltipContentForMetric,
  getYAxisFormatV2,
  getYAxisMaxValue,
  isAtleastOneCountryUs,
  isTmIndexMetricDisabledForMetric,
  metricDataProcessor,
  shouldShowTmIndexDataOnGraph,
} from './utils';

export interface PlotDataItem {
  readonly y: number;
  readonly custom: {
    readonly total: string;
    readonly customDate: string;
    readonly date: string;
    readonly chartDataPeriod: string;
  };
}

interface OwnProps {
  readonly metricResponse: HomepageDataRequestResponse;
  readonly tmIndexMetricsResponse: TmIndexDataResponse;
  readonly chartsTabs: ToggleTab[];
  readonly onClickChartTime: Function;
  readonly currentChartTimeFormat: ChartTime;
  readonly compassState: CompassState;
  readonly trendFormatSelector: ChartTrendMetricFormatSelector;
  readonly subscriptionData: ProductSubscriptionEventResponse;
  readonly apiDefinition: (
    values: string[]
  ) => Promise<AxiosResponse<Blob, any>>;
  readonly disableAnimationOnAction: () => void;
  readonly isAnimationDisabled: boolean;
}

export const BusinessGraphV2: React.FC<OwnProps> = ({
  chartsTabs,
  metricResponse,
  tmIndexMetricsResponse,
  onClickChartTime,
  currentChartTimeFormat,
  compassState,
  trendFormatSelector,
  subscriptionData,
  apiDefinition,
  isAnimationDisabled,
  disableAnimationOnAction,
}) => {
  const intl = useIntl();
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;
  const userCredentials = useUserCredentials();
  const {
    contextSetterValues: {
      selectedCurrency,
      selectedDateRange,
      selectedCountries,
    },
    seasonalEvents,
  } = useContext(CompassContextSetterContext);

  const userInfo = {
    userId: userContext.userInfo.userDetails?.id ?? '',
    accountId,
  };
  const firstMetricFromStorage = getActiveMetricFromLocalStorage(
    userInfo,
    true
  );

  const [isSlideoverOpen, setIsSlideoverOpen] = useState<boolean>(false);
  const [showPreviousData, setShowPreviousData] = useState<boolean>(
    getIsShowPreviousDataFromLocalStorage(userInfo) || true
  );
  const [showSeasonalEvents, setShowSeasonalEvents] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState<MetricsData[]>(
    getCustomizeSlideoverConfigFromLocalStorage(userInfo) ||
      getDefaultMetricsConfiguration(intl)
  );
  const [activeMetric, setActiveMetric] = useState<
    HomepageResponseMetricKeys | undefined
  >(getFirstMetricsField(selectedMetrics, firstMetricFromStorage));

  const [salesChannelTmIndicesSelected, setSalesChannelTmIndicesSelected] =
    useState<SalesChannelTmIndexKeys[]>([]);

  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.Disabled
  );

  const toasts = useContext<NotificationContextState>(NotificationContext);
  const fileName = getFileName(
    'business-performance',
    DateTime.now(),
    userCredentials.companyName
  );

  const isCurrentMetricsActiveInSelection = (metrics: MetricsData[]) => {
    return metrics.find(
      (metric) =>
        metric.metricsId.toLowerCase() === activeMetric?.toLowerCase() &&
        metric.isChecked
    );
  };

  const setUpdatedMetricsSelection = (metrics: MetricsData[]) => {
    if (!isCurrentMetricsActiveInSelection(metrics)) {
      updateSelectedMetric(metrics.find((metric) => metric.isChecked));
    }

    setSelectedMetrics(metrics);
    setIsSlideoverOpen(false);
    setCustomizeSlideoverConfigFromLocalStorage(userInfo, metrics);
  };

  const csvExportDefaultValues = getCsvExportValues(
    true,
    selectedMetrics
      .filter((metric) => metric.isChecked)
      .map((metric) => metric.metricsId)
  );

  const postExportFile = () => {
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_FILE_DOWNLOAD_SUCCESS_TITLE,
      }),
      description: intl.formatMessage(
        { id: I18nKey.COMPASS_DASHBOARD_FILE_DOWNLOAD_SUCCESS_DESCRIPTION },
        {
          fileName: fileName,
        }
      ),
      type: Type.Success,
    });
  };
  const errorExportFile = () => {
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_FILE_DOWNLOAD_FAILD_TITLE,
      }),
      description: intl.formatMessage(
        { id: I18nKey.COMPASS_DASHBOARD_FILE_DOWNLOAD_FAILD_DESCRIPTION },
        {
          fileName: fileName,
        }
      ),
      type: Type.Attention,
    });
  };

  useEffect(() => {
    if (compassState === CompassState.Success)
      setButtonState(ButtonState.Enabled);
  }, [compassState]);

  const onExport = async () => {
    setButtonState(ButtonState.Loading);
    try {
      const getCsvData = await apiDefinition(csvExportDefaultValues);

      const fileUrl = URL.createObjectURL(new Blob([getCsvData.data]));
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(fileUrl);
      postExportFile();
    } catch {
      errorExportFile();
    }
    setButtonState(ButtonState.Enabled);
  };

  const isMetricActive = (metricId: string) => metricId === activeMetric;

  const kpiCardsProps: KPICardV2Props[] = useMemo(
    () =>
      selectedMetrics
        .filter((metric) => metric.isChecked)
        .map((metric) => {
          const metricType = getHeroMetricType(metric.metricsId);

          const currentValue = getCompassKPIMetricValue(
            metricResponse.current?.performanceMetrics as PerformanceMetrics,
            metric.metricsId
          );
          const currentValueFormatted = getCurrentValueContent(
            intl,
            metricType,
            metric.metricsId,
            currentValue
          );

          const previousValue = getCompassKPIMetricValue(
            metricResponse.previous?.performanceMetrics as PerformanceMetrics,
            metric.metricsId
          );
          const previousValueFormatted = getCurrentValueContent(
            intl,
            metricType,
            metric.metricsId,
            previousValue
          );

          return {
            header: metric.label,
            content: currentValueFormatted,
            isFooterActive: true,
            showBorder: true,
            isActive: isMetricActive(metric.metricsId),
            primaryMetric: metric.metricsId === activeMetric,
            previousDaysLabel: metricResponse.previous?.performanceMetrics
              ?.range
              ? intl.formatMessage(
                  {
                    id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_PREV_DAYS_LABEL,
                  },
                  {
                    days: getDaysBetweenDates(
                      DateTime.fromISO(
                        metricResponse.previous?.performanceMetrics?.range
                          .startDate!
                      ),
                      DateTime.fromISO(
                        metricResponse.previous?.performanceMetrics?.range
                          .endDate!
                      )
                    ),
                  }
                )
              : undefined,
            previousValue: previousValueFormatted,
            cardTrend: getCardFooterProps(
              intl,
              metricType,
              metric.metricsId,
              currentValue,
              previousValue
            ),
            state:
              compassState === CompassState.Loading
                ? MetricCardV2CardState.Loading
                : MetricCardV2CardState.Success,
            isSelectableMetricCard: true,
            isMaxMetricsSelected: !!activeMetric,
            onClick: () => onMetricCardClick(metric),
            isNegativeUpTrend:
              metric.metricsId === HomepageResponseMetricKeys.Tacos ||
              metric.metricsId === HomepageResponseMetricKeys.ACoSTotal ||
              metric.metricsId === HomepageResponseMetricKeys.CPC,
            tooltipProps: {
              content: getTooltipContentForMetric(metric.metricsId),
              position: {
                placement: Placement.Bottom,
                alignment: Alignment.Center,
                placementOffsetInPixels: 8,
              },
              theme: Theme.Dark,
            },
            showInfoIcon: true,
            dataTestId: `compass_metric_card_${metric.metricsId}`,
          };
        }),
    [selectedMetrics, activeMetric, showPreviousData, compassState]
  );

  const onMetricCardClick = (metric: MetricsData) => {
    if (metric.metricsId === activeMetric) {
      return;
    } else {
      updateSelectedMetric(metric);
    }
  };

  const onShowPreviousToggle = () => {
    setShowPreviousData((prev) => {
      setIsShowPreviousDataFromLocalStorage(userInfo, !prev);
      return !prev;
    });
  };

  const updateSelectedMetric = (metric?: MetricsData) => {
    setActiveMetric(metric?.metricsId);
    setActiveMetricFromLocalStorage(userInfo, true, metric?.metricsId);
  };

  interface xAxisLabels {
    readonly aiActivityBandPoints?: PlotBandPoints[];
    readonly markerPointsDataConnected?: never[];
    readonly xAxisDataPoints?: string[];
  }

  const xAxisLabels: xAxisLabels = useMemo(() => {
    const diff = selectedDateRange.endDate.diff(selectedDateRange.startDate, [
      'weeks',
    ]);

    return getXAxisLabels(
      intl,
      metricResponse,
      trendFormatSelector,
      currentChartTimeFormat,
      subscriptionData,
      compassState,
      selectedDateRange,
      inRange(Number(diff?.weeks.toFixed(0)), 40, 45) &&
        currentChartTimeFormat !== ChartTime.Monthly //To add spacing for scenario where x-axis label cutoff takes place
    );
  }, [selectedMetrics, activeMetric, currentChartTimeFormat, metricResponse]);

  const chartMetricsData: MetricCardV2Props[] = useMemo(() => {
    if (compassState === CompassState.Success) {
      return [
        ...metricDataProcessor(
          intl,
          metricResponse,
          tmIndexMetricsResponse,
          selectedCurrency,
          currentChartTimeFormat,
          trendFormatSelector,
          compassState,
          showPreviousData,
          xAxisLabels.xAxisDataPoints,
          selectedMetrics.find((metric) => metric.metricsId === activeMetric),
          shouldShowTmIndexDataOnGraph(
            SalesChannelTmIndexKeys.Amazon,
            salesChannelTmIndicesSelected,
            activeMetric
          ),
          shouldShowTmIndexDataOnGraph(
            SalesChannelTmIndexKeys.Walmart,
            salesChannelTmIndicesSelected,
            activeMetric,
            selectedCountries
          )
        ),
      ];
    } else {
      return [];
    }
  }, [
    selectedMetrics,
    activeMetric,
    showPreviousData,
    currentChartTimeFormat,
    metricResponse,
    salesChannelTmIndicesSelected,
    tmIndexMetricsResponse,
  ]);

  const addOrRemoveTmIndex = (channel: SalesChannelTmIndexKeys) => {
    if (channel === SalesChannelTmIndexKeys.Amazon) {
      if (
        salesChannelTmIndicesSelected.includes(SalesChannelTmIndexKeys.Amazon)
      ) {
        setSalesChannelTmIndicesSelected(
          salesChannelTmIndicesSelected.filter(
            (e) => e !== SalesChannelTmIndexKeys.Amazon
          )
        );
      } else {
        setSalesChannelTmIndicesSelected([
          ...salesChannelTmIndicesSelected,
          SalesChannelTmIndexKeys.Amazon,
        ]);
      }
    } else if (channel === SalesChannelTmIndexKeys.Walmart) {
      if (
        salesChannelTmIndicesSelected.includes(SalesChannelTmIndexKeys.Walmart)
      ) {
        setSalesChannelTmIndicesSelected(
          salesChannelTmIndicesSelected.filter(
            (e) => e !== SalesChannelTmIndexKeys.Walmart
          )
        );
      } else {
        setSalesChannelTmIndicesSelected([
          ...salesChannelTmIndicesSelected,
          SalesChannelTmIndexKeys.Walmart,
        ]);
      }
    }
  };

  const onAddOrRemoveTmIndex = (index: number) => {
    if (index === 0) {
      addOrRemoveTmIndex(SalesChannelTmIndexKeys.Amazon);
    } else {
      addOrRemoveTmIndex(SalesChannelTmIndexKeys.Walmart);
    }
  };

  const maxYAxisValue = useMemo(() => {
    if (compassState === CompassState.Success)
      return getYAxisMaxValue(
        metricResponse,
        tmIndexMetricsResponse,
        currentChartTimeFormat,
        shouldShowTmIndexDataOnGraph(
          SalesChannelTmIndexKeys.Amazon,
          salesChannelTmIndicesSelected,
          activeMetric
        ),
        shouldShowTmIndexDataOnGraph(
          SalesChannelTmIndexKeys.Walmart,
          salesChannelTmIndicesSelected,
          activeMetric,
          selectedCountries
        ),
        activeMetric
      );
  }, [
    compassState,
    metricResponse,
    tmIndexMetricsResponse,
    currentChartTimeFormat,
    activeMetric,
    salesChannelTmIndicesSelected,
  ]);

  const tickerIntervalPositions = useMemo(() => {
    if (maxYAxisValue) {
      return getTickerIntervalPositions(maxYAxisValue);
    }
  }, [maxYAxisValue]);

  const noCurrentData = !chartMetricsData[0]?.data[0]?.data?.data?.find(
    (item: PlotDataItem) => item.y
  );
  const noPreviousData = !chartMetricsData[0]?.data[2]?.data?.data?.find(
    (item: PlotDataItem) => item.y
  );

  const getYAxisIntervalValue = () => {
    if (showPreviousData && noPreviousData) {
      return 50;
    }
    if (!showPreviousData && noCurrentData) {
      return 50;
    }
    return undefined;
  };

  return (
    <>
      <div className={classNames('w-full min-h-96 bg-white')}>
        {isSlideoverOpen && (
          <CustomizeMetricsSlideover
            showModal={isSlideoverOpen}
            onClose={() => setIsSlideoverOpen(false)}
            onMetricUpdate={setUpdatedMetricsSelection}
            allMetricsData={[...selectedMetrics]}
          />
        )}

        <div className="flex w-full items-center justify-center mb-16">
          <div className="flex grow">
            <KpiCardGroupV2
              cards={[...kpiCardsProps]}
              classnames="w-full"
              hideTooltip
            />
            {[...Array(5 - kpiCardsProps.length)].map(() =>
              compassState === CompassState.Loading ? (
                <KPICardV2
                  state={MetricCardV2CardState.Loading}
                  showBorder
                  isFooterActive
                  hideTooltip
                />
              ) : (
                <p
                  className={classNames(
                    'flex ml-8 min-w-236 justify-center items-center bg-grey-100 grow',
                    'border border-dashed border-grey-300 rounded cursor-pointer'
                  )}
                  onClick={() => setIsSlideoverOpen(true)}
                >
                  <TextLink
                    textLabel={intl.formatMessage({
                      id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_ADD_METRIC_LINK,
                    })}
                  />
                </p>
              )
            )}
          </div>

          <Tooltip
            content={intl.formatMessage({
              id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_HEADER,
            })}
            position={{
              alignment: Alignment.Center,
              placement: Placement.Left,
              placementOffsetInPixels: 10,
            }}
            delayShow={300}
          >
            <div
              className="flex items-center bg-grey-50 px-8 h-120 ml-8 cursor-pointer w-32"
              onClick={() => setIsSlideoverOpen(true)}
            >
              <Icon
                svg={KebabLinearIcon}
                size={IconSize.Medium}
                className="text-grey-500"
              />
            </div>
          </Tooltip>
        </div>
        {compassState === CompassState.Loading && getShowLoading(compassState)}
        {compassState === CompassState.Success && (
          <ChartBuilder
            chartMetricsProps={[...chartMetricsData]}
            showGraphLineTooltip
            showPreviousDataOnGraphLineTooltip={showPreviousData}
            className="my-0"
            PlotBandPoints={[]}
            xAxisLabels={xAxisLabels.xAxisDataPoints}
            onClickChartTime={onClickChartTime}
            defaultTime={currentChartTimeFormat}
            actionButtonTabs={chartsTabs}
            tabGroupStyle={ToggleTabStyle.Condensed}
            aIActivity={false}
            showYAxisOnLeft={true}
            rotationXaxis={rotationXaxis(xAxisLabels.xAxisDataPoints || [])}
            yAxisLeftLabelColor={'text-grey-600'}
            yAxisRightLabelColor={BLUE_500}
            yAxisLeftMinValue={0}
            yAxisLeftTickPositions={tickerIntervalPositions}
            yAxisLeftMaxValue={maxYAxisValue}
            showEventLogs={showSeasonalEvents}
            eventLogs={getSeasonalEventsData(
              selectedDateRange,
              seasonalEvents,
              currentChartTimeFormat,
              intl
            )}
            yAxisOnLeftFormat={
              activeMetric ? getYAxisFormatV2(activeMetric) : undefined
            }
            currency={getCurrencySymbol(
              CurrencyCode[selectedCurrency as CurrencyCode]
            )}
            rightSideElements={
              <Tooltip
                content={intl.formatMessage({
                  id: I18nKey.COMPASS_CUSTOMIZE_METRICS_EXPORT_BTN_TOOLTIP,
                })}
                theme={Theme.Dark}
                position={{
                  placement: Placement.Left,
                  alignment: Alignment.Center,
                  placementOffsetInPixels: 10,
                }}
                delayShow={300}
              >
                <Button
                  svgIcon={DownloadIcon}
                  size={ButtonSize.InlineIcon}
                  state={buttonState}
                  onClick={() => onExport()}
                  variant={ButtonVariant.BlackAndWhiteBorder}
                  dataTestId="compass_graph_export_btn"
                  className="h-32 w-32"
                />
              </Tooltip>
            }
            disableAnimation={isAnimationDisabled}
            leftSideElements={
              <p>
                <div
                  onClick={onShowPreviousToggle}
                  className="cursor-pointer mt-8 inline-block mr-4"
                >
                  <FormToggle
                    label={intl.formatMessage({
                      id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SHOW_PREV_PERIOD,
                    })}
                    state={
                      showPreviousData
                        ? FormToggleState.On
                        : FormToggleState.Off
                    }
                    size={FormToggleSize.Small}
                  />
                </div>
                <div
                  onClick={() => {
                    setShowSeasonalEvents(() => !showSeasonalEvents);
                    disableAnimationOnAction();
                  }}
                  className="cursor-pointer mt-8 inline-block"
                >
                  <Tooltip
                    content={
                      seasonalEvents.length
                        ? ''
                        : intl.formatMessage({
                            id: I18nKey.COMPASS_CUSTOMIZE_METRICS_NO_SEASONAL_EVENTS_TOOLTIP,
                          })
                    }
                    tooltipSize={TooltipSize.Small}
                    style={ContentStyle.Normal}
                    position={{
                      placement: Placement.Bottom,
                      alignment: Alignment.Center,
                    }}
                  >
                    <FormToggle
                      label={intl.formatMessage({
                        id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SHOW_SEASONAL_EVENTS,
                      })}
                      state={
                        showSeasonalEvents
                          ? FormToggleState.On
                          : FormToggleState.Off
                      }
                      size={FormToggleSize.Small}
                    />
                  </Tooltip>
                </div>
              </p>
            }
            condensedElements
            yAxisLeftTickInterval={getYAxisIntervalValue()}
            yAxisFixedHeight={240}
            centerElements={
              <div className="flex items-center gap-12">
                <Typography
                  size={TypographySize.base}
                  lineHeight={TypographyLineHeight.tight}
                  weight={TypographyWeight.medium}
                  className="text-grey-900"
                >
                  {intl.formatMessage({
                    id: I18nKey.COMPASS_TM_INDEX_SHOW_INFO_TEXT,
                  })}
                </Typography>
                <KpiCardBenchmarkGroup
                  cards={[
                    getKpiBenchmarkCardProps(
                      SalesChannelTmIndexKeys.Amazon,
                      intl,
                      salesChannelTmIndicesSelected,
                      isTmIndexMetricDisabledForMetric(activeMetric)
                    ),
                    getKpiBenchmarkCardProps(
                      SalesChannelTmIndexKeys.Walmart,
                      intl,
                      salesChannelTmIndicesSelected,
                      isTmIndexMetricDisabledForMetric(activeMetric),
                      !isAtleastOneCountryUs(selectedCountries)
                    ),
                  ]}
                  onCardClick={onAddOrRemoveTmIndex}
                  showInfoIcon
                  infoTooltipProps={{
                    content: (
                      <>
                        <Typography
                          size={TypographySize.sm}
                          lineHeight={TypographyLineHeight.normal}
                          weight={TypographyWeight.regular}
                          className="text-white"
                        >
                          {intl.formatMessage({
                            id: I18nKey.COMPASS_TM_INDEX_INFO_TOOLTIP,
                          })}
                        </Typography>
                        <div className="mt-8 flex flex-wrap rounded py-4 px-8 bg-grey-700 justify-center">
                          <TextLink
                            textLabel={intl.formatMessage({
                              id: I18nKey.LEARN_MORE,
                            })}
                            href={TM_BENCHMARK_HELP_LINK}
                            className="text-white w-fit cursor-pointer hover:text-white"
                            openNewTab={true}
                            size={TextLinkSize.Small}
                          />
                        </div>
                      </>
                    ),
                    theme: Theme.Dark,
                    position: {
                      placement: Placement.Bottom,
                      alignment: Alignment.Center,
                    },
                    overwrittenTooltipClassnames: 'max-w-220 text-center',
                  }}
                />
              </div>
            }
          />
        )}
      </div>
    </>
  );
};
BusinessGraphV2.displayName = 'CompassBusinessGraphV2';
