import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Icon,
  IconSize,
  InfoCircleIcon,
  TextLink,
  TextLinkSize,
  Theme,
  Tooltip,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import I18nKey from '../../../../../lib/types/I18nKey';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { CreateKeywordsContext } from './createKeywordsProvider';
import { KeywordType } from './createKeywordsMenu';
import { SelectedAdgroupsModal } from './selectedAdgroupsModal';
import { useParams } from 'react-router-dom';

export interface SelectedAdgroupsListProps {
  readonly onSelectAdgroupClick: () => void;
  readonly hasErrors?: boolean;
}

export const SelectedAdgroupsList: React.FC<SelectedAdgroupsListProps> = ({
  onSelectAdgroupClick,
  hasErrors,
}) => {
  const intl = useIntl();
  const [showSelectedAdgroups, setShowSelectedAdgroups] = useState(false);
  const {
    selectedCampaigns,
    onCampaignSelectionChange,
    selectedCampaignsMap,
    keywordType,
    isLoading,
  } = useContext(CreateKeywordsContext);

  const { adGroupsId: adGroupsIdUrlParam } = useParams();

  const onToggleShowSelectedAdgroups = () => {
    setShowSelectedAdgroups((current) => !current);
  };

  const selectedAdgroupsCount = Object.values(selectedCampaignsMap).reduce(
    (prev, curr) => {
      return prev + (curr?.selectedAdGroupIds || []).length;
    },
    0
  );

  const [
    TOOLTIP,
    BODY_MESSAGE,
    BODY_MESSAGE_SELECTED,
    CHANGE,
    SELECT,
    SELECTED_COUNT,
  ] = [
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_TOOLTIP,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_BODY,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECTED_BODY,
    I18nKey.GENERIC_CHANGE,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECT_AD_GROUPS,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECTED_COUNT,
  ].map((key) =>
    intl.formatMessage(
      { id: key },
      {
        count: selectedAdgroupsCount,
        isNegative: keywordType === KeywordType.NegativeKeyword,
        keywordType,
      }
    )
  );

  const buttonState =
    isLoading && adGroupsIdUrlParam ? ButtonState.Loading : ButtonState.Active;

  const showAdGroupSelectedMessage = selectedAdgroupsCount > 0;

  return (
    <div
      className={classNames(
        'rounded-4 border border-solid border-grey-200 bg-grey-50 '
      )}
    >
      <div
        className={classNames(
          'gap-12 p-12',
          'flex flex-row items-center justify-between'
        )}
      >
        <div className="flex flex-col gap-8 items-start justify-center">
          <div className="flex flex-row items-center gap-8">
            <Tooltip
              theme={Theme.Dark}
              content={TOOLTIP}
              dataTestId={'create-keywords-modal-tooltip'}
              overwrittenTooltipClassnames="w-260"
            >
              <Icon
                svg={InfoCircleIcon}
                size={IconSize.Small}
                className="text-grey-500 mb-2"
              />
            </Tooltip>
            {showAdGroupSelectedMessage ? BODY_MESSAGE_SELECTED : BODY_MESSAGE}
          </div>
          {selectedAdgroupsCount > 0 && !adGroupsIdUrlParam && (
            <div
              data-test-id="create-keyword-modal-show-selected-adgroups"
              className="flex flex-row items-center gap-4 cursor-pointer"
              onClick={onToggleShowSelectedAdgroups}
            >
              <TextLink size={TextLinkSize.Small} textLabel={SELECTED_COUNT} />
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center">
          <Button
            size={ButtonSize.Medium}
            variant={ButtonVariant.BlackAndWhiteBorder}
            label={selectedAdgroupsCount ? CHANGE : SELECT}
            dataTestId="create-keyword-modal-select-adgroup"
            onClick={onSelectAdgroupClick}
            className={classNames({
              'border border-orange-500 hover:bg-grey-50 hover:text-grey-700 text-orange-500':
                hasErrors,
            })}
            state={buttonState}
          />
        </div>
      </div>
      {showSelectedAdgroups && (
        <SelectedAdgroupsModal
          onCampaignSelectionChange={onCampaignSelectionChange}
          selectedCampaigns={selectedCampaigns}
          selectedAdgroupsCount={selectedAdgroupsCount}
          onClose={() => {
            setShowSelectedAdgroups(false);
          }}
        />
      )}
    </div>
  );
};
