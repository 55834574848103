import { LogomarkIcon } from '@teikametrics/tm-design-system';
import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';
import React from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../../lib/types/I18nKey';
import { headerInfo } from '../../../containers/salesChannels/utils/constants';
import { SalesChannelHeader } from '../../SalesChannelSyncStatusSlideout/components/SalesChannelHeader';
import { MerchantInfo } from './MerchantInfo';

export const DataTimingAndSyncingContent: React.FC = () => {
  const intl = useIntl();

  const [
    amazonReportingDataLabel,
    amazonReportingDataContentLabel,
    walmartReportingDataLabel,
    walmartReportingDataContentLabel,
    flywheelDataSyncingLabel,
    flywheelDataSyncingContentLabel,
    sellerLabel,
    vendorLabel,
  ] = [
    I18nKey.SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_AMAZON_REPORTING_DATA_HEADER,
    I18nKey.SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_AMAZON_REPORTING_DATA_CONTENT,
    I18nKey.SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_WALMART_REPORTING_DATA_HEADER,
    I18nKey.SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_WALMART_REPORTING_DATA_CONTENT,
    I18nKey.SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_FLYWHEEL_SYNCING_DATA_HEADER,
    I18nKey.SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_FLYWHEEL_SYNCING_DATA_CONTENT,
    I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_TYPE_SELLER,
    I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_TYPE_VENDOR,
  ].map((i18nKey: I18nKey) =>
    intl.formatMessage({
      id: i18nKey,
    })
  );
  return (
    <div className="p-12 flex flex-col">
      <SalesChannelHeader
        header={headerInfo[FlywheelSalesChannel.Amazon]}
        hideLabel
      />
      <h1 className="text-lg leading-tight font-semibold text-grey-900 mt-8 mb-4">
        {amazonReportingDataLabel}
      </h1>
      <label className="text-base leading-normal font-normal text-grey-900 my-4">
        {amazonReportingDataContentLabel}
      </label>
      <MerchantInfo
        headerLabel={sellerLabel}
        advertisingDelayCount={1}
        productsDelayCount={1}
      />
      <MerchantInfo
        headerLabel={vendorLabel}
        advertisingDelayCount={1}
        productsDelayCount={5}
      />
      <SalesChannelHeader
        header={headerInfo[FlywheelSalesChannel.Walmart]}
        hideLabel
      />
      <h1 className="text-lg leading-tight font-semibold text-grey-900 mt-8 mb-4">
        {walmartReportingDataLabel}
      </h1>
      <label className="text-base leading-normal font-normal text-grey-900 my-4">
        {walmartReportingDataContentLabel}
      </label>
      <MerchantInfo
        headerLabel={sellerLabel}
        advertisingDelayCount={2}
        productsDelayCount={1}
      />
      <MerchantInfo
        headerLabel={vendorLabel}
        advertisingDelayCount={2}
        productsDelayCount={1}
      />
      <SalesChannelHeader
        header={{
          emptyStateTitleI18nKey: I18nKey.FLYWHEEL_ADD_ANOTHER_DISTRIBUTOR,
          salesChannelIcon: LogomarkIcon,
          header: I18nKey.FLYWHEEL_AUTHORIZE_TEXT,
        }}
        hideLabel
      />
      <h1 className="text-lg leading-tight font-semibold text-grey-900 mt-8 mb-4">
        {flywheelDataSyncingLabel}
      </h1>
      <label className="text-base leading-normal font-normal text-grey-900 my-4">
        {flywheelDataSyncingContentLabel}
      </label>
    </div>
  );
};
