import classNames from 'classnames';
import { useIntl } from 'react-intl';

import {
  TextLink,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../lib/types/I18nKey';

export interface PromptAuthFailedProps {
  readonly mainTextId: I18nKey;
  readonly subTextId?: I18nKey;
}

/**
 * @param param accepts 2 i18 key for main and secondary test
 * @example
 * <PromptAuthFailed
 *   mainTextId={
 *     I18nKey.CONNECT_ADS_MODAL_AMAZON_ERROR_PROMPT_MAIN_MESSAGE
 *   }
 *   subTextId={I18nKey.CONNECT_ADS_MODAL_AMAZON_ERROR_PROMPT_SUB_MESSAGE}
 * />
 */
export const PromptAuthFailed: React.FC<PromptAuthFailedProps> = ({
  mainTextId,
  subTextId,
}) => {
  const intl = useIntl();

  return (
    <div
      className={classNames(
        'flex flex-col justify-start items-baseline rounded-4 bg-orange-100 w-full px-12 py-16'
      )}
    >
      <Typography
        size={TypographySize.lg}
        lineHeight={TypographyLineHeight.tight}
        weight={TypographyWeight.semibold}
      >
        {intl.formatMessage({ id: mainTextId })}
      </Typography>
      {subTextId && (
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.normal}
          weight={TypographyWeight.regular}
        >
          {intl.formatMessage({ id: subTextId })}
        </Typography>
      )}
      <TextLink
        openNewTab
        textLabel={intl.formatMessage({ id: I18nKey.GENERIC_LEARN_MORE })}
        href={''}
        dataTestId="prompt-auth-failed"
      />
    </div>
  );
};
