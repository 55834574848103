import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  getPerformanceMetricValue,
  isCampaignTacticsNotEqualToLegacyOrViews,
} from '../utils';

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = (
  props
) =>
  makeNumericColumn<AdGroupDetails>(
    ({
      adGroupDetails: { startDate, campaignTactic },
      adGroupPerformance: { newToBrandOrders },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        newToBrandOrders
      );

      return isNil(performanceMetricValue) ||
        !isCampaignTacticsNotEqualToLegacyOrViews(campaignTactic)
        ? undefined
        : performanceMetricValue.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const newToBrandAdOrdersColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.NewToBrandOrders,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_NEW_TO_BRAND_AD_ORDERS,
  RowCellElement,
  gridColumnWidth: '124px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const newToBrandAdConversionsFilter = createNumericDataFieldFilter(
  ADGROUPS_API_COLUMN_NAME.NewToBrandOrders,
  I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_NEW_TO_BRAND_AD_ORDERS,
  isValidNumber(0)
);
