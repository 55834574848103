import { Factory } from 'fishery';

import { UploadSearchTermsStatusResponse } from '../../types/MISharedTypes';

export default Factory.define<UploadSearchTermsStatusResponse>(() => ({
  n_input_search_terms: 1,
  new_search_terms: [
    {
      search_term: 'my search term1',
      sales_channel: 'Amazon',
      country: 'Amazon',
    },
  ],
  existing_search_terms: [],
  duplicated_search_terms: [],
  error_messages: [],
}));
