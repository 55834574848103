import React from 'react';
import { useIntl } from 'react-intl';

import {
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';

export interface AmazonAuthFailedModalFooterProps {
  readonly goBack: () => void;
  readonly authorize: () => void;
}

export const ModalFooter: React.FC<AmazonAuthFailedModalFooterProps> = ({
  goBack,
  authorize,
}) => {
  const intl = useIntl();
  const [BACK, CONTINUE] = [
    I18nKey.BACK,
    I18nKey.MERCHANT_SUBSCRIPTION_CLAIM_TABLE_AUTHORIZES,
  ].map((id) => intl.formatMessage({ id }));
  return (
    <div className="flex justify-between mx-8">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.BlackAndWhite}
        label={BACK}
        svgIcon={ArrowLeftIcon}
        onClick={goBack}
        dataTestId={'flash1_amazon_ads_failed_back'}
      />
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.Primary}
        label={CONTINUE}
        onClick={authorize}
        dataTestId={'flash1_amazon_ads_failed_continue'}
      />
    </div>
  );
};
