import startCase from 'lodash/startCase';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { AdGroupDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADGROUPS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<AdGroupDetails> = (props) => {
  return makeTextColumn<AdGroupDetails>((adItemDetails: AdGroupDetails) =>
    startCase(adItemDetails.adGroupDetails.campaignType)
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignTypeColumn: FlywheelTableColumn<AdGroupDetails> = {
  columnName: ADGROUPS_API_COLUMN_NAME.CampaignType,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_TYPE,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '200px',
};
