import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../../containers/bidConstraintsProvider/biddingConstraints';
import { FlywheelTableColumn } from '../../../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
} from '../../../../../../containers/table/ducks';
import { WithTable } from '../../../../../../containers/table/ducks/types';
import {
  makeCurrencyColumn,
  makeNumericInputColumn,
} from '../../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupStatus,
  AdLevel,
  AdType,
  CampaignStatus,
  CampaignTargetingType,
  DetailTargets,
  TargetStatus,
  TargetsDetails,
} from '../../../../../../lib/types/AOSharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { ADS_MANAGER_TARGETS_TABLE_ID } from '../../ducks/types';
import { CURRENCY_CODE, TableDataAdsManager } from '../../types';
import {
  TARGETS_API_COLUMN_NAME,
  getOrDefaultIsBiddableFlag,
  isCampaignAdFormatProductCollectionOrSpotlight,
  isCurrentValueLessThanRequiredMinValue,
  isNegativeKeywordTarget,
  isNegativeProductTarget,
  isValueBetweenMinAndMaxValue,
} from '../../utils';
import { BidTooltipForInvalidValue } from './tooltip';
import {
  getCurrencyCodeFromMerchantCountryCode,
  getCurrencySymbolFromMerchantCountryCode,
} from '../../../../../../lib/utilities/currency';

interface TooltipContent {
  isTargetArchived: boolean;
  isInputInvalid: boolean;
  isInputGreaterThanDailyOrLifetimeBudget: boolean;
  merchantCurrency: string | null;
  isCurrentValueMoreThanHalfOfDailyBudget?: boolean | '';
  adLevel?: AdLevel;
  maxBid?: number;
  minBid?: number;
}

const getControlledTooltipState = (
  isTargetArchived: boolean,
  numericInputState: NumericInputState,
  focussed: boolean
) => {
  if (isTargetArchived) {
    return ControlledTooltip.None;
  }

  if (focussed && numericInputState === NumericInputState.Error) {
    return ControlledTooltip.Show;
  }

  return ControlledTooltip.Hide;
};

const getBid =
  (currencyCode: string | null, isNegativeKeyword?: boolean) =>
  ({ channelSettings }: TargetsDetails) => {
    if (isNegativeKeyword) {
      return;
    }

    return (
      channelSettings.bid && {
        amount: Number(channelSettings.bid.amount),
        currency: currencyCode || channelSettings.bid.currency || CURRENCY_CODE,
      }
    );
  };

const getSelectedAdType = (
  { targetDetails }: TargetsDetails,
  selectedAdType: AdType
) =>
  selectedAdType === AdType.SponsoredBrands &&
  !isCampaignAdFormatProductCollectionOrSpotlight(
    targetDetails.campaignAdFormat
  )
    ? AdType.SponsoredBrandsVideo
    : selectedAdType;

const shouldSetValue = (
  isEditMode: boolean,
  updatedBid: string,
  value: string
) =>
  isEditMode || // Changing between modes
  (!isNil(updatedBid) && updatedBid !== value); // Changes done due to bulk update. updatedBid is latest, but state variable is not

const getPlaceholderText = (
  intl: IntlShape,
  defaultBid?: number,
  minBid?: number,
  maxBid?: number
) => {
  const PLACEHOLDER_VALUE = defaultBid || minBid || maxBid;

  return PLACEHOLDER_VALUE
    ? intl.formatNumber(PLACEHOLDER_VALUE, {
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
      })
    : undefined;
};

const getIsInputInvalid = (
  isInputBetweenValidLimits: boolean,
  isBidNotANumber: boolean,
  isInputGreaterThanDailyOrLifetimeBudget: boolean,
  isCurrentValueMoreThanHalfOfDailyBudget?: boolean | ''
) =>
  !isInputBetweenValidLimits ||
  isBidNotANumber ||
  isCurrentValueMoreThanHalfOfDailyBudget ||
  isInputGreaterThanDailyOrLifetimeBudget;

const getIsCurrentValueMoreThanHalfOfDailyBudget = (
  selectedAdType: AdType,
  value: string,
  targetDetails: DetailTargets
) =>
  selectedAdType === AdType.SponsoredDisplay &&
  targetDetails.campaignDailyBudget?.amount &&
  !isEmpty(value) &&
  !isCurrentValueLessThanRequiredMinValue(
    Number(targetDetails.campaignDailyBudget?.amount) / 2,
    value
  );

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = ({
  targetPerformance,
  targetDetails,
  targetId,
  channelSettings,
  isEditMode,
  pendingFields,
  selectedAdType,
  salesChannel,
  merchantCountry,
  adLevel,
  merchantType,
}) => {
  const [inputFieldFocussed, setInputFieldFocussed] = useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const negativeKeyword =
    isNegativeKeywordTarget(targetDetails.matchType) ||
    isNegativeProductTarget(targetDetails.entityType);

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);
  const bidConstraintsData = useBidConstraintsContext();

  const {
    minBid,
    maxBid,
    defaultMinBid: defaultBid,
  } = getBidConstraint(
    bidConstraintsData.constraints,
    getSelectedAdType(
      { targetDetails, targetId, targetPerformance, channelSettings },
      selectedAdType
    ),
    salesChannel,
    TARGETS_API_COLUMN_NAME.Bid,
    merchantCountry,
    targetDetails.campaignTargetingType as CampaignTargetingType,
    merchantType,
    targetDetails.campaignCostType
  );

  const merchantCurrency =
    getCurrencySymbolFromMerchantCountryCode(merchantCountry);
  const targetsData: TargetsDetails = {
    targetPerformance,
    targetDetails,
    targetId,
    channelSettings,
    pendingFields,
  };

  const updatedBid = useSelector<WithTable<TargetsDetails>, string>(
    ({ tableState }) => {
      return tableSelectors.getCellSelector(
        targetId,
        TARGETS_API_COLUMN_NAME.Bid
      )(tableState, ADS_MANAGER_TARGETS_TABLE_ID);
    }
  );

  const currentPage = useSelector<WithTable<TargetsDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_TARGETS_TABLE_ID
      )
  );

  const existingValue = channelSettings.bid?.amount?.toString() || '';

  let bid = updatedBid ?? existingValue;

  const [value, setValue] = useState<string>(bid);

  useEffect(() => {
    if (shouldSetValue(isEditMode, updatedBid, value)) {
      setValue(bid);
    }
  }, [isEditMode, currentPage, updatedBid]);

  const updateCellValue = (newBid: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_TARGETS_TABLE_ID,
        columnName: TARGETS_API_COLUMN_NAME.Bid,
        rowId: targetId,
        value: newBid,
        existingValue,
        numericValue: true,
      })
    );
  };

  const debouncedUpdateCellValue = useCallback(debounce(updateCellValue, 500), [
    targetId,
  ]);

  let numericInputState = NumericInputState.Default;
  const isDirty: boolean = existingValue !== value;
  let isInputBetweenValidLimits = true;
  if (isDirty) {
    isInputBetweenValidLimits = isValueBetweenMinAndMaxValue(
      Number(minBid),
      Number(maxBid),
      value
    );
  }

  const isCurrentValueMoreThanHalfOfDailyBudget =
    getIsCurrentValueMoreThanHalfOfDailyBudget(
      selectedAdType,
      value,
      targetDetails
    );

  const isInputGreaterThanDailyOrLifetimeBudget =
    Number(targetDetails.campaignDailyBudget?.amount) < Number(value) ||
    (!isNil(targetDetails.campaignTotalBudget?.amount)
      ? Number(targetDetails.campaignTotalBudget?.amount) < Number(value)
      : false);

  const isBidNotANumber = isNaN(Number(value));

  const isInputInvalid = getIsInputInvalid(
    isInputBetweenValidLimits,
    isBidNotANumber,
    isInputGreaterThanDailyOrLifetimeBudget,
    isCurrentValueMoreThanHalfOfDailyBudget
  );

  const isTargetArchived =
    channelSettings.status === TargetStatus.Archived ||
    targetDetails.adGroupStatus === AdGroupStatus.Archived ||
    targetDetails.campaignStatus === CampaignStatus.Archived;

  if (isTargetArchived) {
    numericInputState = NumericInputState.Disabled;
  } else if (isInputInvalid) {
    numericInputState = NumericInputState.Error;
  }

  const onInputFocus = () => setInputFieldFocussed(true);
  const onInputBlur = () => {
    if (!isEmpty(value)) {
      debouncedUpdateCellValue.cancel();

      const formattedValue = parseFloat(value).toFixed(
        MONETARY_FRACTION_DIGITS
      );

      updateCellValue(formattedValue);
    }
    setInputFieldFocussed(false);
  };

  const onNumericInputChange = (newValue: string) => {
    setValue(newValue);
    debouncedUpdateCellValue(newValue);
  };

  const isEditableBid = isBidEditable(
    isEditMode,
    getOrDefaultIsBiddableFlag(targetDetails.isBiddable),
    negativeKeyword
  );
  if (isEditableBid) {
    const numericInputProps: NumericInputProps & TypedTooltipProps = {
      value,
      state: numericInputState,
      prependedElement: String(merchantCurrency),
      onChange: onNumericInputChange,
      onInputBlur,
      isDirty,
      tooltipContent: tooltipContent({
        isTargetArchived,
        isInputInvalid,
        isInputGreaterThanDailyOrLifetimeBudget,
        merchantCurrency,
        isCurrentValueMoreThanHalfOfDailyBudget,
        adLevel,
        maxBid,
        minBid,
      }),
      controlledTooltip: getControlledTooltipState(
        isTargetArchived,
        numericInputState,
        inputFieldFocussed
      ),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus: onInputFocus,
      acceptOnlyPositiveNumbers: true,
      placeholder: getPlaceholderText(intl, defaultBid, minBid, maxBid),
      minFractionDigits: MONETARY_FRACTION_DIGITS,
      maxFractionDigits: MONETARY_FRACTION_DIGITS,
      dataTestId: 'target-bid',
    };

    return makeNumericInputColumn(() => numericInputProps)(targetsData);
  }

  return makeCurrencyColumn(
    getBid(currencyCode, negativeKeyword),
    pendingFields?.includes(TARGETS_API_COLUMN_NAME.Bid)
  )(targetsData);
};
RowCellElement.displayName = 'RowCellElement';

export const bidColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.Bid,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_BID,
  RowCellElement,
  className: 'text-right',
  gridColumnWidth: '160px',
  columnHeaderClassName: 'justify-end',
};

export const bidFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    TARGETS_API_COLUMN_NAME.Bid,
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_BID,
    currency,
    isValidNumber(0)
  );

export const isBidEditable = (
  isEditMode: boolean,
  isBiddableTarget?: boolean,
  negativeKeyword?: boolean
) => isEditMode && isBiddableTarget && !negativeKeyword;

const tooltipContent = (tooltipDataContent: TooltipContent) => {
  const {
    isTargetArchived,
    isInputInvalid,
    isInputGreaterThanDailyOrLifetimeBudget,
    merchantCurrency,
    isCurrentValueMoreThanHalfOfDailyBudget,
    adLevel,
    maxBid,
    minBid,
  } = tooltipDataContent;

  if (isTargetArchived || isInputInvalid) {
    return (
      <BidTooltipForInvalidValue
        adLevel={adLevel}
        isCurrentValueMoreThanHalfOfDailyBudget={
          isCurrentValueMoreThanHalfOfDailyBudget
        }
        isInputGreaterThanDailyOrLifetimeBudget={
          isInputGreaterThanDailyOrLifetimeBudget
        }
        isTargetArchived={isTargetArchived}
        maxBid={maxBid}
        minBid={minBid}
        merchantCurrency={merchantCurrency}
      />
    );
  }
};
