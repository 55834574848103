import {
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonVariant,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { FormattedMessage } from 'react-intl';
import I18nKey from '../../../../../lib/types/I18nKey';

export interface DataTimingAndSyncingHeaderProps {
  readonly closeSlideOut: () => void;
}
export const DataTimingAndSyncingHeader: React.FC<
  DataTimingAndSyncingHeaderProps
> = ({ closeSlideOut }) => {
  return (
    <div className="flex flex-row items-center justify-center h-48 px-12 gap-8">
      <Button
        size={ButtonSize.InlineIcon}
        variant={ButtonVariant.ActionWithoutBorder}
        className="text-base leading-none font-normal text-grey-900"
        label={'Back'}
        svgIcon={ArrowLeftIcon}
        onClick={closeSlideOut}
      />
      <Typography
        size={TypographySize.base}
        lineHeight={TypographyLineHeight.none}
        className="text-grey-900 w-full flex flex-row items-center justify-center"
        weight={TypographyWeight.medium}
      >
        <FormattedMessage
          id={I18nKey.DATA_TIMING_SYNC_STATUS_SLIDE_OUT_HEADER}
        />
      </Typography>
    </div>
  );
};

DataTimingAndSyncingHeader.displayName = 'DataTimingAndSyncingHeader';
