import './styles.scss';

import React, { PropsWithChildren } from 'react';

export enum CardType {
  AboutThisStep = 'about-this-step',
}

export interface CardProps {
  /**
   * The type of the card, which determines its look and feel
   */
  readonly type: CardType;

  /**
   * The title of the card
   */
  readonly title?: string | JSX.Element;
}

export const Card: React.FC<CardProps & PropsWithChildren> = (props) => (
  <div className={`card card--${props.type}`}>
    <span className="card__title">{props.title}</span>
    <div className="card__content">{props.children}</div>
  </div>
);
