import {
  AmazonLogomarkEnabledIcon as AmazonLogo,
  LogomarkIcon as DefaultLogo,
  WalmartLogomarkEnabledIcon as WalmartLogo,
} from '@teikametrics/tm-design-system';

import {
  FlywheelSalesChannel,
  MerchantCountry,
} from '../../../../../../lib/types/Fam';
import I18nKey from '../../../../../../lib/types/I18nKey';

export const getChannelNameAndLogo = (
  salesChannelName?: FlywheelSalesChannel
) => {
  switch (salesChannelName) {
    case FlywheelSalesChannel.Amazon:
      return {
        logo: AmazonLogo,
        channelName: I18nKey.ADVERTISING_OPTIMIZATION_AMAZON,
        label: I18nKey.CHANNELS_MERCHANT_ASIN,
      };
    case FlywheelSalesChannel.Walmart:
      return {
        logo: WalmartLogo,
        channelName: I18nKey.ADVERTISING_OPTIMIZATION_WALMART,
        label: I18nKey.CHANNELS_MERCHANT_ID,
      };
    default:
      return {
        logo: DefaultLogo,
        channelName: I18nKey.UNKNOWN_CHANNEL,
        label: I18nKey.CHANNELS_MERCHANT_ID,
      };
  }
};

export const getMerchantCountryByCountryId = (
  merchantCountryId: string,
  merchants: MerchantCountry[]
) =>
  merchants.find(
    (merchant) => merchant.merchantCountryId === merchantCountryId
  );
