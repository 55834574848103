import {
  AdgroupActionType,
  AlertCircleIcon,
  ArrowsNextIcon,
  BadgeDisplayMode,
  BadgeType,
  Icon,
  IconSize,
  SuggestedAdgroup,
  SuggestedAdgroupCampaign,
  TextLink,
  TextLinkSize,
  Theme,
  Tooltip,
} from '@teikametrics/tm-design-system';
import {
  AdGroupInfoV2,
  CampaignInfoWithAdGroups,
  TargetingType,
} from '../../../../lib/types/KeywordAction';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import I18nKey from '../../../../lib/types/I18nKey';
import { getTargetSegmentPills } from '../RecommendationsTableV3/utils';

interface AdGroupSelectionV2Props {
  readonly campaign: CampaignInfoWithAdGroups;
  readonly onChange: (
    adGroup: AdGroupInfoV2,
    campaign: CampaignInfoWithAdGroups,
    checked?: boolean
  ) => void;
  readonly selectedAdGroupId?: string;
  readonly onCountClick?: (
    adGroup: AdGroupInfoV2,
    campaign: CampaignInfoWithAdGroups
  ) => void;
}

export const AdGroupSelectionV2: React.FC<AdGroupSelectionV2Props> = ({
  campaign,
  onChange,
  selectedAdGroupId,
  onCountClick,
}) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col gap-12 bg-white p-16 border border-solid rounded border-grey-200">
      <SuggestedAdgroupCampaign
        campaignName={campaign.name}
        targetingType={{
          badgeType:
            campaign?.targetingType === TargetingType.Auto
              ? BadgeType.CampaignTypeAuto
              : BadgeType.CampaignTypeManual,
          badgeNameDisplayMode: BadgeDisplayMode.OnlyInitial,
        }}
      />
      {campaign.adGroups.map((adGroup) => (
        <div className="flex items-center px-8">
          <Icon
            size={IconSize.Medium}
            svg={ArrowsNextIcon}
            className="text-grey-400"
          />
          <SuggestedAdgroup
            action={AdgroupActionType.Radio}
            showBorder={true}
            classname="w-full"
            isSelected={selectedAdGroupId === adGroup.id}
            adGroupName={adGroup.name}
            onClick={() =>
              onChange(adGroup, campaign, selectedAdGroupId === adGroup.id)
            }
            targetSegments={getTargetSegmentPills(adGroup.targetSegments)}
          >
            {!!onCountClick && (
              <div className="flex flex-row gap-8">
                {adGroup.totalTargets === 0 && (
                  <Tooltip
                    overwrittenTooltipClassnames="min-w-max"
                    theme={Theme.Dark}
                    content={intl.formatMessage({
                      id: I18nKey.CREATE_KEYWORDS_MODAL_INCOMPLETE_ADGROUP_MESSAGE,
                    })}
                  >
                    <Icon
                      size={IconSize.Small}
                      svg={AlertCircleIcon}
                      className="text-red-500"
                    />
                  </Tooltip>
                )}
                <TextLink
                  className="border-grey-400 font-normal text-sm mt-4"
                  size={TextLinkSize.Small}
                  onClick={(event) => {
                    onCountClick?.(adGroup, campaign);
                    event.stopPropagation();
                  }}
                  textLabel={
                    <FormattedMessage
                      id={
                        I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_SKU_AND_KEYWORDS_COUNT
                      }
                      values={{
                        skuCount: adGroup.totalSkus,
                        keywordCount: adGroup.totalTargets,
                        bold: (content) => (
                          <span className="font-bold">{content}</span>
                        ),
                      }}
                    />
                  }
                />
              </div>
            )}
          </SuggestedAdgroup>
        </div>
      ))}
    </div>
  );
};

AdGroupSelectionV2.displayName = 'AdGroupSelectionV2';
