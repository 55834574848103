import { useEffect, useState } from 'react';
import {
  AnimationSteps,
  FADE_INTERVAL_MS,
  FADE_OUT_INTERVAL_MS,
  FadeProp,
} from './types';
import { GetStarted } from './GetStarted';
import { GetStartedConnectChannel } from './GetStartedConnectChannel';
import { Confetti } from '@teikametrics/tm-design-system';

export interface AccountSuccessProps {
  readonly name?: string;
  readonly step: AnimationSteps;
  readonly setStep: (animationStep: AnimationSteps) => void;
  readonly isCommunity: boolean;
  readonly setShowModal: (show: boolean) => void;
}

export const AccountSuccess: React.FC<AccountSuccessProps> = ({
  name,
  step,
  setStep,
  isCommunity,
  setShowModal,
}) => {
  const [fadeProp, setFadeProp] = useState<FadeProp>({
    fade: 'motion-safe:animate-fadeIn',
  });
  const [isWelcomeBack, setIsWelcomeBack] = useState<boolean>(true);

  useEffect(() => {
    if (step === AnimationSteps.Step2) {
      setTimeout(() => {
        setFadeProp({ fade: 'motion-safe:animate-fadeOut' });
        setTimeout(() => {
          setIsWelcomeBack(false);
          setStep(AnimationSteps.Step3);
        }, FADE_OUT_INTERVAL_MS);
      }, FADE_INTERVAL_MS);
    } else if (step === AnimationSteps.Step3) {
      if (isCommunity) {
        setShowModal(false);
      }
    }
  }, [step]);

  return (
    <div className="flex flex-col items-center justify-center h-480 w-full">
      {step === AnimationSteps.Step1 && (
        <Confetti onAnimationComplete={() => setStep(AnimationSteps.Step2)} />
      )}
      {step === AnimationSteps.Step2 && (
        <GetStarted fade={fadeProp.fade} name={name} />
      )}
      {step === AnimationSteps.Step3 && !isCommunity && (
        <GetStartedConnectChannel name={name} isWelcomeBack={isWelcomeBack} />
      )}
    </div>
  );
};

AccountSuccess.displayName = 'AccountSuccess';
