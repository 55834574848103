import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import {
  getAdvertisedColumnText,
  getAdvertisedInfoTooltip,
} from '../skuCatalogColumns/advertised';
import {
  Alignment,
  Icon,
  IconSize,
  InfoCircleIcon,
  Placement,
  Tooltip,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../../../lib/types/I18nKey';

export interface AdvertisedStatusProps {
  readonly advertisedSkuCount?: number;
  readonly skuCount?: number;
  readonly showCount?: boolean;
}

export const AdvertisedStatus: React.FC<AdvertisedStatusProps> = ({
  skuCount,
  advertisedSkuCount,
  showCount,
}) => {
  const intl = useIntl();
  const isAdvertised = advertisedSkuCount ? advertisedSkuCount > 0 : false;
  const spEligble = advertisedSkuCount !== -1;

  return (
    <div className="w-144 flex flex-row items-center gap-4">
      <span
        className={classNames('w-8 h-8 rounded-full', {
          'bg-grey-500': !spEligble,
          'bg-green-500':
            spEligble && isAdvertised && advertisedSkuCount === skuCount,
          'bg-yellow-500':
            spEligble && isAdvertised && advertisedSkuCount !== skuCount,
          'bg-orange-500': spEligble && !isAdvertised,
        })}
      ></span>
      <span className="text-base leading-tight font-normal text-grey-900">
        {getAdvertisedColumnText(intl, isAdvertised, advertisedSkuCount)}
      </span>
      {!spEligble && (
        <Tooltip
          position={{
            alignment: Alignment.Center,
            placement: Placement.Bottom,
          }}
          content={intl.formatMessage({
            id: getAdvertisedInfoTooltip(!spEligble, false),
          })}
          overwrittenTooltipClassnames="min-w-max"
        >
          <Icon
            svg={InfoCircleIcon}
            size={IconSize.Medium}
            className="text-grey-900 pl-4 pb-2"
          />
        </Tooltip>
      )}
      {isAdvertised && showCount && (
        <span className="text-base leading-none font-normal text-grey-500">
          {intl.formatMessage(
            {
              id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED_TEXT_COUNT,
            },
            {
              showCount: true,
              advertisedCount: advertisedSkuCount,
              totalCount: skuCount,
            }
          )}
        </span>
      )}
    </div>
  );
};
