import {
  ColumnManagerOption,
  MultiSelectOption,
  SingleSelectOption,
} from '@teikametrics/tm-design-system';
import { MatchType } from '../../../../lib/types/AOSharedTypes';
import { MerchantCountry } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  ActionType,
  CampaignInfo,
  DropDownType,
  KeywordActionColumns,
  KeywordAdgroupInfo,
  KeywordRecommendationsData,
  RecommendationSourceV2,
  SelectedRecommendationsV2,
} from '../../../../lib/types/KeywordAction';

export const KEYWORD_RECOMMENDATIONS_TABLE_V3_ID =
  'KEYWORD_RECOMMENDATIONS_TABLE_V3_ID';

export const KEYWORD_RECOMMENDATIONS_TABLE_V3_ID_BY_CHANNEL =
  'KEYWORD_RECOMMENDATIONS_TABLE_V3_ID_BY_CHANNEL';

export const NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V2_ID =
  'NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V2_ID';

export const DESTINATION_AD_GROUP_NAME_SORT_KEY = 'adGroupName';
export const DESTINATION_AD_GROUP_SORT_KEY = 'destinationCampaign';
export const DESTINATION_AD_GROUP_TAG_SORT_KEY = 'destinationAdGroupTag';
export const DESTINATION_AD_GROUP_TARGETING_TYPE_SORT_KEY =
  'adGroupTargetingType';
export const SOURCE_TARGET_TEXT_FILTER_FIELD = 'sourceTarget';
export const SOURCE_CAMPAIGN_SORT_FIELD = 'sourceCampaign';
export const SOURCE_KEYWORD_TEXT_SORT_FIELD = 'sourceKeywordText';
export const SOURCE_AD_GROUP_TAG_FILTER_FIELD = 'sourceAdGroupTagOptions';
export const AD_GROUP_NO_TAG_FILTER_OPTION = 'notag';

export interface ActionRowDataProps {
  readonly dismissedRows: string[];
  readonly dismissProcessingRows: string[];
  readonly acceptedRows: string[];
  readonly acceptProcessingRows: string[];
}

export interface RecommendationsTableV2ExtraProps {
  readonly merchantCountry: MerchantCountry;
  readonly selectedRows: SelectedRecommendationsV2;
  readonly actionRowDataProps?: ActionRowDataProps;
  readonly onActionRowClick?: (
    rowIds: string[],
    action: ActionType,
    original: KeywordRecommendationsData
  ) => void;
  handleDropdownSelection: (
    row: KeywordRecommendationsData,
    option: DropDownType,
    value?: MatchType | CampaignInfo | KeywordAdgroupInfo
  ) => void;
  readonly isPat?: boolean;
}

export const SelectSourceV2Option: Record<
  RecommendationSourceV2,
  SingleSelectOption | MultiSelectOption
> = {
  [RecommendationSourceV2.AO]: {
    value: RecommendationSourceV2.AO,
    displayI18nKey: I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_AO,
  },
  [RecommendationSourceV2.AMZ_SP_SEARCH_TERM_REPORT]: {
    value: RecommendationSourceV2.AMZ_SP_SEARCH_TERM_REPORT,
    displayI18nKey:
      I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_AMZ_SP_SEARCH_TERM_REPORT,
  },
  [RecommendationSourceV2.AMZ_SP_TARGET_SEARCH_TERM_REPORT]: {
    value: RecommendationSourceV2.AMZ_SP_TARGET_SEARCH_TERM_REPORT,
    displayI18nKey:
      I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_AMZ_SP_SEARCH_TERM_REPORT,
  },
  [RecommendationSourceV2.AMZ_SP_SUGGESTED_KEYWORD]: {
    value: RecommendationSourceV2.AMZ_SP_SUGGESTED_KEYWORD,
    displayI18nKey:
      I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_AMZ_SP_SUGGESTED_KEYWORD,
  },
  [RecommendationSourceV2.WMT_SP_ITEM_KEYWORD_REPORT]: {
    value: RecommendationSourceV2.WMT_SP_ITEM_KEYWORD_REPORT,
    displayI18nKey:
      I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_WMT_SP_ITEM_KEYWORD_REPORT,
  },
  [RecommendationSourceV2.WMT_SP_KEYWORD_ANALYTIC]: {
    value: RecommendationSourceV2.WMT_SP_KEYWORD_ANALYTIC,
    displayI18nKey:
      I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_WMT_SP_KEYWORD_ANALYTIC,
  },
  [RecommendationSourceV2.WMT_SP_KEYWORD_PERFORMANCE_REPORT]: {
    value: RecommendationSourceV2.WMT_SP_KEYWORD_PERFORMANCE_REPORT,
    displayI18nKey:
      I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_WMT_SP_KEYWORD_PERFORMANCE_REPORT,
  },
};

export interface ColumnManagerOptionWithIsSelected extends ColumnManagerOption {
  readonly isSelected?: boolean;
  readonly value: KeywordActionColumns;
}
