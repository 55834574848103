import capitalize from 'lodash/capitalize';
import { useIntl } from 'react-intl';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignTargetingType,
  MatchType,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TARGETS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const MATCH_TYPE_I18N_KEY_MAPPER = {
  [MatchType.Exact]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXACT,
  [MatchType.Broad]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BROAD,
  [MatchType.Phrase]: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PHRASE,
  [MatchType.NegativeExact]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_EXACT,
  [MatchType.NegativePhrase]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_PHRASE,
};

export const RowCellElement: React.FC<TargetsDetails> = (targetsDetails) => {
  const intl = useIntl();

  const i18nKeyOrLabel =
    targetsDetails.targetDetails.matchType &&
    MATCH_TYPE_I18N_KEY_MAPPER[targetsDetails.targetDetails.matchType]
      ? MATCH_TYPE_I18N_KEY_MAPPER[targetsDetails.targetDetails.matchType]
      : targetsDetails.targetDetails.matchType;

  return makeTextColumn(
    (data: TargetsDetails) =>
      data.targetDetails.matchType
        ? capitalize(
            intl.formatMessage({
              id: i18nKeyOrLabel,
              defaultMessage: data.targetDetails.matchType,
            })
          )
        : undefined,
    undefined,
    'normal-case'
  )(targetsDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const matchTypeColumn: FlywheelTableColumn<TargetsDetails> = {
  columnName: TARGETS_API_COLUMN_NAME.MatchType,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_MATCH_TYPE,
  RowCellElement,
  gridColumnWidth: '140px',
};

export const matchTypeFilter = (
  salesChannel: FlywheelSalesChannel,
  campaignType?: string
) =>
  createMultiSelectDataFieldFilter(
    TARGETS_API_COLUMN_NAME.MatchType,
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_MATCH_TYPE,
    getMatchTypeFilterValues(salesChannel, campaignType)
  );

const getMatchTypeFilterValues = (
  salesChannel: FlywheelSalesChannel,
  campaignType?: string
) => {
  const defaultFilters = [
    SelectFilterOption.MatchTypeExact,
    SelectFilterOption.MatchTypePhrase,
    SelectFilterOption.MatchTypeBroad,
  ];

  const negativeKeywordFilters = [
    SelectFilterOption.MatchTypeNegativeExact,
    SelectFilterOption.MatchTypeNegativePhrase,
  ];

  switch (salesChannel) {
    case FlywheelSalesChannel.Walmart:
      return defaultFilters;

    case FlywheelSalesChannel.Amazon:
      if (campaignType === CampaignTargetingType.Auto) {
        return negativeKeywordFilters;
      }

      return [...defaultFilters, ...negativeKeywordFilters];
  }
};
