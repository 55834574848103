import {
  Icon,
  IconSize,
  SearchIcon,
  Spinner,
} from '@teikametrics/tm-design-system';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { getCurrentAccountFromContext } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { MIApiClient } from '../../../../lib/clients/MIApiClient';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  SEARCH_TERM_KEY_VALUE,
  SearchTermStatus,
  SearchTermsData,
} from '../../../../lib/types/MISharedTypes';
import { PlatformData } from '../../containers/reporting/types';
import {
  SEARCH_TERMS_TABLE_SEARCH_KEY,
  SEARCH_TERM_MAX_CHAR_LENGTH,
} from '../../containers/settings/utils';
import SearchPickerRow from './searchPickerRow';
import {
  filterEmptyValues,
  filtersToQueryString,
  generateSortQueryString,
} from '../../../../lib/utilities/buildUrlUtilities';
import { FilterOps } from '../../../../lib/types/Filter';
import { SortOrder } from '../../../../lib/types/Sort';

interface SearchPickerDropdownProps {
  initialOptions: SearchTermsData[];
  onOptionSelect: (val: SearchTermsData) => void;
  hasMerchantData?: boolean;
  selectedTerm?: SearchTermsData;
  useApiForSearch: boolean;
  platform?: PlatformData;
  miApiClient: MIApiClient;
}
const SearchPickerDropdown: React.FC<SearchPickerDropdownProps> = ({
  initialOptions,
  selectedTerm,
  onOptionSelect,
  useApiForSearch,
  hasMerchantData = false,
  platform,
  miApiClient,
}) => {
  const intl = useIntl();
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;

  const [filteredOptions, setFilteredOptions] =
    useState<SearchTermsData[]>(initialOptions);
  const [loading, setLoading] = useState(false);

  const searchPlaceholder = intl.formatMessage({
    id: I18nKey.MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_PLACEHOLDER,
  });

  useEffect(() => {
    if (!isEqual(initialOptions, filteredOptions)) {
      setFilteredOptions(initialOptions);
    }
  }, [initialOptions]);

  const getAllSearchTerms = async (searchValue?: string) => {
    setLoading(true);
    const params = {
      sort: generateSortQueryString([
        {
          column: SEARCH_TERM_KEY_VALUE,
          direction: SortOrder.Asc,
        },
      ]),
      filter: searchValue
        ? filtersToQueryString([
            {
              op: FilterOps.like,
              field: SEARCH_TERMS_TABLE_SEARCH_KEY,
              value: searchValue || '',
            },
          ])
        : '',
      limit: '1000',
      offset: '0',
    };

    const allParams = filterEmptyValues(params);

    const { searchTerms: searchTermsForAllPlatforms } =
      await miApiClient.getAllSearchTermsAndGroupsForPlatform(
        accountId,
        allParams
      );
    setLoading(false);
    return searchTermsForAllPlatforms || [];
  };

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value || '';
    if (value) {
      if (useApiForSearch) {
        const updatedTerms = await getAllSearchTerms(value);
        const filteredTerms = updatedTerms.filter((term) => {
          return (
            term.status === SearchTermStatus.Active &&
            term.platform === platform?.name
          );
        });
        setFilteredOptions(filteredTerms);
      } else {
        const updatedTerms = initialOptions.filter((item) => {
          return item.search_term?.toLowerCase()?.includes(value.toLowerCase());
        });
        setFilteredOptions(updatedTerms);
      }
    } else {
      setFilteredOptions(initialOptions);
    }
  };

  const debounceSearch = useCallback(debounce(handleSearch, 500), [
    initialOptions,
    useApiForSearch,
    accountId,
    platform,
  ]);

  const searchInput = useMemo(() => {
    return (
      <div className="group flex items-center space-x-4 rounded border border-solid border-grey-300 bg-white mx-12 px-12 py-8">
        <Icon
          size={IconSize.Medium}
          svg={SearchIcon}
          className="text-grey-400 group-hover:text-grey-500 group-focus:text-purple-500"
        />
        <input
          className="text-base text-grey-900 w-full outline-none"
          placeholder={searchPlaceholder}
          type="text"
          onChange={debounceSearch}
          maxLength={SEARCH_TERM_MAX_CHAR_LENGTH}
        />
      </div>
    );
  }, [initialOptions, accountId, useApiForSearch, platform]);

  const dropdownOptions = useMemo(() => {
    if (loading) {
      return (
        <div className="flex items-center justify-center py-8 px-10">
          <Spinner centerAlign={false} />
        </div>
      );
    }

    return (
      <div className="flex flex-col overflow-y-auto mt-8">
        {filteredOptions.map((option) => {
          const isSelected =
            selectedTerm?.search_term === option.search_term &&
            selectedTerm?.locale === option?.locale;

          const showPlusIcon = !option.is_group && !option.is_grouped_term;
          return (
            <SearchPickerRow
              option={option}
              onOptionSelect={onOptionSelect}
              isSelected={isSelected}
              hasMerchantData={hasMerchantData}
              showPlusIcon={showPlusIcon}
            />
          );
        })}
      </div>
    );
  }, [onOptionSelect, hasMerchantData, filteredOptions, loading, selectedTerm]);

  return (
    <>
      {searchInput}
      {dropdownOptions}
    </>
  );
};

export default SearchPickerDropdown;

SearchPickerDropdown.displayName = 'SearchPickerDropdown';
