import {
  Button,
  ButtonSize,
  ButtonVariant,
  CheckmarkIcon,
  ColumnAlign,
  Icon,
  IconSize,
  TableCellV2,
  TableCellV2Type,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { MerchantsGroup } from '../../authProductData';
import { FlywheelTableV2ColumnProps } from '../../../../../../containers/tableV2/types';
import { FormattedMessage } from 'react-intl';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { AuthorizeMerchantTableProps, MerchantCardVariant } from '..';

export interface MerchantConnectionStatusProps {
  readonly merchant: MerchantsGroup;
  readonly handleOauthPopup: (data: any) => void;
}

export const MerchantConnectionStatus: React.FC<
  MerchantConnectionStatusProps
> = ({ merchant, handleOauthPopup }) => {
  return (
    <>
      {merchant.variant === MerchantCardVariant.Completed ? (
        <div className="flex flex-row gap-4 py-4 px-8 items-center rounded-4 bg-green-100">
          <Icon
            svg={CheckmarkIcon}
            className="text-green-500"
            size={IconSize.Medium}
          />
          <Typography
            size={TypographySize.sm}
            lineHeight={TypographyLineHeight.none}
            weight={TypographyWeight.regular}
            className="text-grey-900"
          >
            <FormattedMessage id={I18nKey.CONNECTED} />
          </Typography>
        </div>
      ) : (
        <Button
          size={ButtonSize.Medium}
          label={<FormattedMessage id={I18nKey.GENERIC_CONNECT} />}
          onClick={() => handleOauthPopup(merchant)}
          variant={ButtonVariant.BlackAndWhiteBorder}
          dataTestId={`flash1_amazon_product_connect_authorize`}
        />
      )}
    </>
  );
};

export const statusColumn: FlywheelTableV2ColumnProps<
  MerchantsGroup,
  {},
  Pick<AuthorizeMerchantTableProps, 'handleOauthPopup'>
> = {
  header: '',
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => (
    <TableCellV2
      tableCellData={{
        currentValue: (
          <MerchantConnectionStatus
            merchant={original}
            handleOauthPopup={meta.handleOauthPopup}
          />
        ),
        tableCellType: TableCellV2Type.Text,
      }}
    />
  ),
  accessorKey: 'status',
  sortable: true,
  size: 60,
  minSize: 60,
  align: ColumnAlign.Right,
};
