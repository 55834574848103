import { StickyAlignment } from '@teikametrics/tm-design-system';
import compact from 'lodash/compact';

import {
  FlywheelSalesChannel,
  SalesChannelData,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { SalesChannelTableData } from '../types';
import { automationColumn } from './automation';
import { adConnectionColumn, productConnectionColumn } from './connections';
import { countryColumn } from './country';
import { merchantIdColumn } from './merchantId';
import { nameColumn } from './name';
import { typeColumn } from './type';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import { advertiserIdColumn } from './advertiserId';

export const GetAccountListColumnsDefinition = (
  showAutomationColumn: boolean,
  selectedSalesChannelName: FlywheelSalesChannel
): FlywheelTableV2ColumnProps<
  SalesChannelData,
  {},
  SalesChannelTableData,
  {}
>[] =>
  compact([
    {
      header: I18nKey.ACCOUNT_SALES_CHANNELS_GROUPING_HEADER_MERCHANT,
      sticky: StickyAlignment.Left,
      columns: [
        nameColumn,
        merchantIdColumn,
        ...(selectedSalesChannelName === FlywheelSalesChannel.Walmart
          ? [advertiserIdColumn]
          : []),
        countryColumn,
        typeColumn,
      ],
    },
    showAutomationColumn && {
      header: I18nKey.ACCOUNT_SALES_CHANNELS_GROUPING_HEADER_AUTOMATION,
      sticky: StickyAlignment.Left,
      columns: [automationColumn],
    },
    {
      header: I18nKey.ACCOUNT_SALES_CHANNELS_GROUPING_HEADER_DATA_CONNECTION,
      sticky: StickyAlignment.Left,
      columns: [adConnectionColumn, productConnectionColumn],
    },
  ]);
