import './campaignsTable.scss';

import { fromNullable } from 'fp-ts/lib/Option';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable react-hooks/exhaustive-deps */
import {
  BulkEditConfirmationModal,
  BulkEditSlideOut,
  BulkEditValues,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  ColumnManager,
  ColumnManagerOption,
  ColumnManagerVariant,
  DualModes,
  FilterIcon,
  FiltersMenu,
  SearchInputSize,
  TableActionRow,
  Theme,
  Tooltip,
  TooltipSize,
  Variant,
} from '@teikametrics/tm-design-system';
import { useLocation, useNavigate } from 'react-router-dom';

import isArray from 'lodash/isArray';
import EstAdGrossMarginSlideover from '../../../../components/EstAdGrossMarginSlideover/EstAdGrossMarginSlideover';
import { useBidConstraintsContext } from '../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../containers/bidConstraintsProvider/biddingConstraints';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';
import {
  SaveChangesFlagContext,
  SaveChangesFlagContextState,
} from '../../../../containers/saveChangesFlagProvider';
import UpdatedFlywheelTable, {
  FlywheelTableColumnGroup,
} from '../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
  tableThunks,
} from '../../../../containers/table/ducks';
import {
  TableChange,
  WithTable,
} from '../../../../containers/table/ducks/types';
import { FlywheelSearchInput } from '../../../../containers/tableV2/flywheelSearchInput';
import {
  getCurrentAccountFromContext,
  getUserRoleMatch,
  isAIPlanEnabled,
} from '../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../containers/userProvider/userProvider';
import { CampaignCreatorFullRoutes } from '../../../../core/campaignFlexibility/types';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { FAMApiClient } from '../../../../lib/clients/FAMApiClient';
import {
  PaginatedDataFetcher,
  PaginatedResponseExtraData,
} from '../../../../lib/clients/types';
import { useTime } from '../../../../lib/hooks/useTime';
import { StrictStringMap } from '../../../../lib/types';
import {
  AdLevel,
  AdType,
  BudgetType,
  CampaignDataRequest,
  CampaignDetails,
  CampaignStatus,
  EntityType,
  FlywheelSalesChannel,
  Groups,
  MerchantCountryCode,
  Portfolio,
  REQUEST_DATE_FORMAT,
} from '../../../../lib/types/AOSharedTypes';
import { EstPreAdGrossMarginItem } from '../../../../lib/types/CampaignFlexibilitySharedTypes';
import { MerchantCountry, MerchantType, Role } from '../../../../lib/types/Fam';
import {
  COLUMNS_WITH_SINGLE_DECIMAL_FILTER,
  Filter,
} from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import { OptimizelyFlags } from '../../../../lib/types/OptimizelyFlags';
import { MAP_SALES_CHANNEL_NAME_TO_ID } from '../../../../lib/types/SalesChannels';
import {
  CurrencyCode,
  getCurrencyCodeFromMerchantCountryCode,
} from '../../../../lib/utilities/currency';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../lib/utilities/intercom';
import { CampaignGroupingModal } from '../../components/CampaignGroupingModal';
import { CreateEditGroupSlideover } from '../../components/CreateEditGroupSlideover';
import { MerchantContext, MerchantContextState } from '../../merchantsProvider';
import { areNoChangesDone } from '../../utils';
import { SMART_GOALS } from './adgroupTableColumns/utils';
import { getActionRowButtons, getCheckBoxState } from './bulkEditModalUtils';
import { generateColumns } from './campaignsTableColumns';
import { MIN_APP_BID } from './campaignsTableColumns/appBidMultiplier';
import { isBelowTopOfTheSearchBidMultiplierInvalid } from './campaignsTableColumns/belowTopOfTheSearchBidMultiplier';
import { MIN_BUY_BOX_BID } from './campaignsTableColumns/buyBoxBidMultiplier';
import {
  BOOLEAN_COLUMNS,
  CampaignBulkEditModalValues,
  PERCENTAGE_BASED_COLUMNS,
  checkIfCampaignNameIsDuplicateWithinEditedCells,
} from './campaignsTableColumns/campaignName';
import { updateCampaignsBulkEditChanges } from './campaignsTableColumns/campaignNameUtils';
import { isDailyBudgetInvalid } from './campaignsTableColumns/dailyBudget';
import { MIN_DESKTOP_BID } from './campaignsTableColumns/desktopBidMultiplier';
import { MIN_HOME_PAGE_BID } from './campaignsTableColumns/homePageBidMultiplier';
import { isMacsTargetInvalid } from './campaignsTableColumns/macsTarget';
import { isMaxBidInvalid } from './campaignsTableColumns/maxBid';
import { isMinBidInvalid } from './campaignsTableColumns/minBid';
import { MIN_MOBILE_BID } from './campaignsTableColumns/mobileBidMultiplier';
import { isProductPageBidMultiplierInvalid } from './campaignsTableColumns/productPageBidMultiplier';
import { MIN_SEARCH_INGRID_BID } from './campaignsTableColumns/searchIngridBidMultiplier';
import { MIN_STOCKUP_BID } from './campaignsTableColumns/stockUpBidMultiplier';
import { isTopOfSearchBidMultiplierInvalid } from './campaignsTableColumns/topOfSearchBidMultiplier';
import { isTotalBudgetInValid } from './campaignsTableColumns/totalBudget';
import {
  CreateKeywordsMenu,
  KeywordType,
} from './createKeywords/createKeywordsMenu';
import { DATA_INSPECTOR_COLUMN_NAME } from './dataInspectorConfig';
import {
  ADS_MANAGER_CAMPAIGNS_TABLE_ID,
  EST_AD_GROSS_MARGIN_TABLE_ADS_ID,
} from './ducks/types';
import { ErrorTable } from './errorTable';
import ExportAdLevelData from './exportAdLevelData';
import { KeepEditingModal } from './keepEditingModal';
import { ManageBiddingInternal } from './manageBiddingInternal';
import { NoDataFoundTable } from './noDataFoundTable';
import { NoFilteredDataFoundTable } from './noFilteredDataFound';
import { SaveChangeButton } from './saveChange';
import {
  AD_LEVEL_EXTERNAL_ID_MAPPER,
  ApiColumnsWithAdLevel,
  CAMPAIGN_DATE_FORMAT,
  CAMPAIGN_TABLE_SEARCH_KEY,
  COGSUploadStatus,
  CURRENCY_CODE,
  CampaignDataApiColumnGroupIdentifier,
  CampaignTypeQuickFilterKey,
  ConfirmationModalClickKind,
  EditedRowInfo,
  MERCHANT_NAME_COLUMN_ID_FOR_EXPORT,
  REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES,
  TableCellChange,
  TableCellChangeAdGroup,
  TableCellChangeCampaign,
  TableChangeGlobalAdLevelCurrency,
  TableDataAdsManager,
} from './types';
import {
  ALL_AD_LEVELS_FILTER_FILED_MAPPER,
  CAMPAIGNS_API_COLUMN_NAME,
  CAMPAIGNS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  EDIT_CAMPAIGNS_API_COLUMN_DATA_MAPPING,
  TABLE_UNIQ_KEY,
  campaignDetailsFieldsForCSVExport,
  campaignflywheelSettingsFieldsForCSVExport,
  getCampaignTypeQuickFilters,
  getFlywheelSettingsColumn,
  getMaxLengthForCampaignName,
  getSearchInputPlaceholder,
  getTransformedAddedByFilter,
  isCampaignStatusArchived,
  isCampaignStatusCompleted,
  isInvalidCampaignName,
  isValidCampaignNameCharacterLength,
  shouldShowManageBidding,
  shouldShowSmartCampaigns,
} from './utils';
import { getBulkEditConfigurationForCampaigns } from './utils/bulkEditMenuUtils';
import { getFilterColumnDefinitons } from './utils/filterDefinitions';

const TABLE_ID = ADS_MANAGER_CAMPAIGNS_TABLE_ID;

interface TableCampaignsGlobal extends TableChangeGlobalAdLevelCurrency {
  readonly totalBudget: StrictStringMap<string>;
  readonly dailyBudget: StrictStringMap<string>;
  readonly endDate: StrictStringMap<string>;
  readonly startDate: StrictStringMap<string>;
}

export interface TableCampaignsProps {
  readonly apiColumnsWithAdLevel: ApiColumnsWithAdLevel;
  readonly tableMode: DualModes;
  readonly aoApiClient: AOApiClient;
  readonly famApiClient: FAMApiClient;
  readonly exportButtonState: ButtonState;
  readonly adLevelDropDownSelectedValues: '' | AdLevel;
  readonly selectedSalesChannel: FlywheelSalesChannel;
  readonly selectedAdType: AdType;
  readonly selectedSalesChannelId: string;
  readonly activeSliderIndex: number;
  readonly request: () => CampaignDataRequest;
  readonly dataFetcher: PaginatedDataFetcher<CampaignDetails>;
  readonly dataFetcherWithoutQuickFilter: PaginatedDataFetcher<CampaignDetails>;
  readonly setExportButtonState: React.Dispatch<
    React.SetStateAction<ButtonState>
  >;
  readonly toggleTableMode: () => void;
  readonly selectedSalesChannelName: string;
  readonly onEditSave: () => void;
  readonly onFilterUpdate?: (filters: Filter[]) => void;
  readonly filtersFromBrowserStorage?: Filter[];
  readonly storeFiltersInBrowserStorage?: (filters: Filter[]) => void;
  readonly adTypes: AdType[];
  readonly portfolioNames: Portfolio[];
  readonly groupNames: Groups[];
  readonly reloadGroupsData: () => Promise<void>;
  readonly merchantCountry: MerchantCountryCode;
  readonly merchantType?: MerchantType;
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly selectedMerchantCountries: Array<number | string> | string;
  readonly merchantCountryNameForCSVDownload?: string;
  readonly allMerchants: MerchantCountry[];
  readonly aiEnabled: boolean;
  readonly merchantsWithAutomationEnabled: string[];
  readonly onColumnSelectionChange?: (selectedColumns: string[]) => void;
  readonly selectedColumns?: string[];
  readonly columnManagerOptions?: ColumnManagerOption[];
  readonly switchLevelController: (adLevel: string) => void;
  readonly isSaveChangesLoading?: boolean | undefined;
  readonly checkavailability?: boolean;
  readonly campaignTypeQuickFilter: CampaignTypeQuickFilterKey;
  readonly onQuickFilterClick: (type: string) => void;
  readonly quickFilterData?: PaginatedResponseExtraData;
  readonly setQuickFilterData: (data: PaginatedResponseExtraData) => void;
  readonly cogsDetailsDataFetcher: (
    entityId: string,
    entityType: EntityType
  ) => PaginatedDataFetcher<EstPreAdGrossMarginItem>;
}

export const TableCampaigns: React.FC<TableCampaignsProps> = ({
  apiColumnsWithAdLevel,
  tableMode,
  aoApiClient,
  exportButtonState,
  adLevelDropDownSelectedValues,
  selectedSalesChannel,
  activeSliderIndex,
  toggleTableMode,
  onEditSave,
  dataFetcher,
  dataFetcherWithoutQuickFilter,
  request,
  setExportButtonState,
  selectedSalesChannelName,
  onFilterUpdate,
  filtersFromBrowserStorage,
  storeFiltersInBrowserStorage,
  selectedAdType,
  adTypes,
  portfolioNames,
  groupNames,
  reloadGroupsData,
  merchantType,
  merchantCountry,
  startDate,
  endDate,
  selectedMerchantCountries,
  merchantCountryNameForCSVDownload,
  allMerchants,
  aiEnabled,
  merchantsWithAutomationEnabled,
  selectedSalesChannelId,
  columnManagerOptions,
  onColumnSelectionChange = noop,
  selectedColumns,
  switchLevelController,
  isSaveChangesLoading,
  checkavailability = false,
  campaignTypeQuickFilter,
  onQuickFilterClick,
  quickFilterData,
  setQuickFilterData,
  cogsDetailsDataFetcher,
}) => {
  const [clickedOnView, setClickedOnView] = useState<boolean>(false);
  const [editedCampaignsInfo, setEditedCampaignsInfo] = useState<
    EditedRowInfo[]
  >([]);

  const [confirmationModalVariant, setConfirmationModalVariant] =
    useState<Variant | null>(null);

  const [showCreateEditSlideover, setShowCreateEditSlideover] =
    useState<boolean>(false);
  const [
    showCreateEditSlideoverBackButton,
    setShowCreateEditSlideoverBackButton,
  ] = useState<boolean>(false);

  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef<boolean>(false);
  const firstApiCall = useRef<boolean>(true);
  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);

  const merchantContext = useContext<MerchantContextState>(MerchantContext);
  const merchantDetails = merchantContext.merchantDetails;

  const bidConstraintsData = useBidConstraintsContext();
  const tableChange = useSelector<WithTable<CampaignDetails>, TableChange>(
    ({ tableState }) =>
      tableSelectors.getChangeSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const selectedRowIds = useSelector<WithTable<CampaignDetails>, string[]>(
    ({ tableState }) =>
      tableSelectors.getSelectedRowsSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const currentTableFilters = useSelector<WithTable<CampaignDetails>, Filter[]>(
    ({ tableState }) => tableState[ADS_MANAGER_CAMPAIGNS_TABLE_ID].filters
  );

  const checkValidFlywheelSetting = (
    currentFieldValue?: string,
    automationStatus?: boolean | string,
    ...otherFieldValues: (number | string | undefined)[]
  ) => {
    if (!isNil(automationStatus)) {
      return true;
    }
    return !!currentFieldValue && !otherFieldValues.filter((v) => !v).length;
  };

  const campaignTableData = useSelector<
    WithTable<CampaignDetails>,
    CampaignDetails[]
  >(({ tableState }) =>
    tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<CampaignDetails, void>()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
    )
  );

  const updateEditedRecords = useCallback(
    (editedCampaignInfo: EditedRowInfo) => {
      const resultsWithoutEditedCampaign = editedCampaignsInfo.filter(
        (rowInfo) => rowInfo.rowId !== editedCampaignInfo.rowId
      );
      setEditedCampaignsInfo([
        ...resultsWithoutEditedCampaign,
        editedCampaignInfo,
      ]);
    },
    [editedCampaignsInfo]
  );

  const updatedCells: TableCellChangeCampaign = tableChange.cell;

  const [globalTableCellChanges, setGlobalTableCellChanges] = useState<
    TableCellChangeAdGroup | undefined
  >();

  useEffect(() => {
    const allUpdatedCells = tableChange.cell as TableCellChangeAdGroup;
    setGlobalTableCellChanges((state: TableCellChangeAdGroup | undefined) => {
      const cells: any = {};
      Object.entries(allUpdatedCells).forEach(
        ([campaignId, { macsTarget, minBid, maxBid, automationStatus }]) => {
          const flywheelSettings = campaignTableData.find(
            (row) => row.campaignId === campaignId
          )?.flywheelSettings;

          cells[campaignId] = {
            minBid: minBid || flywheelSettings?.minBid?.amount!,
            maxBid: maxBid || flywheelSettings?.maxBid?.amount!,
            automationStatus:
              automationStatus || flywheelSettings?.automationStatus!,
            macsTarget: macsTarget || flywheelSettings?.macsTarget!,
          };
        }
      );

      return {
        ...state,
        ...cells,
      };
    });
  }, [tableChange.cell]);

  const updatedCellsData = Object.entries(updatedCells);

  const globalChanges = tableChange.global as TableCampaignsGlobal;

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  const userContext = useContext(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;
  const isAIPlan = isAIPlanEnabled(userContext);
  const isSBA = selectedAdType === AdType.SearchBrandAmplifier;

  const isUserRoleViewOnly = getUserRoleMatch(
    Role.VIEW_ONLY,
    userContext.userInfo.userDetails
  );

  const validCampaignsCount = updatedCellsData.filter(([campaignId, value]) => {
    const updatedCellDetails = {
      value: value.campaignName,
      campaignId,
    };
    const maybeEditedCampaignInfo = editedCampaignsInfo.find(
      (record) => record.rowId === updatedCellDetails.campaignId
    );

    const isCampaignNameUniqueAcrossAllAdTypes = fromNullable(
      maybeEditedCampaignInfo?.isNameUnique
    )
      .map((isUnique) => isUnique)
      .getOrElse(true);

    return (
      !isNil(updatedCellDetails.value) &&
      isValidCampaignNameCharacterLength(
        updatedCellDetails.value,
        getMaxLengthForCampaignName(selectedSalesChannel, merchantType)
      ) &&
      !isInvalidCampaignName(
        selectedSalesChannel,
        updatedCellDetails.value,
        isSBA
      ) &&
      !checkIfCampaignNameIsDuplicateWithinEditedCells(
        updatedCellDetails.value,
        updatedCells
      ) &&
      isCampaignNameUniqueAcrossAllAdTypes
    );
  }).length;

  const validDailyBudgetCount = updatedCellsData.filter(
    ([campaignId, value]) => {
      const updatedCellDetails = {
        value: value.campaignDailyBudget,
        totalBudget:
          updatedCells[campaignId].totalBudget ??
          globalChanges.totalBudget[campaignId],
        campaignId,
      };
      const { value: dailyBudgetValue, totalBudget } = updatedCellDetails;
      const budgetType = campaignTableData.find(
        (campaign) => campaignId === campaign.campaignId
      )?.channelSettings?.budgetType as BudgetType;

      if (selectedSalesChannel === FlywheelSalesChannel.Walmart) {
        const currentMerchantCountryId = campaignTableData.find(
          ({ campaignId: rowCampaignId }) => campaignId === rowCampaignId
        )?.campaignDetails.merchantCountryId;
        const currentMerchantType = allMerchants.find(
          ({ merchantCountryId }) =>
            merchantCountryId === currentMerchantCountryId
        )?.merchantType;

        const { minDailyBudget = 0, maxDailyBudget = Infinity } =
          getBidConstraint(
            bidConstraintsData.constraints,
            selectedAdType,
            selectedSalesChannel,
            CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
            merchantCountry,
            undefined,
            currentMerchantType
          );

        return (
          dailyBudgetValue &&
          !isDailyBudgetInvalid(
            dailyBudgetValue,
            totalBudget,
            minDailyBudget,
            maxDailyBudget,
            budgetType
          )
        );
      } else {
        const { minDailyBudget = 0, maxDailyBudget = Infinity } =
          getBidConstraint(
            bidConstraintsData.constraints,
            selectedAdType,
            selectedSalesChannel,
            CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
            merchantCountry,
            undefined
          );

        return (
          dailyBudgetValue &&
          !isDailyBudgetInvalid(
            dailyBudgetValue,
            totalBudget,
            minDailyBudget,
            maxDailyBudget,
            budgetType
          )
        );
      }
    }
  ).length;

  const validTotalBudgetCount = updatedCellsData.filter(
    ([campaignId, value]) => {
      const updatedCellDetails = {
        value: value.totalBudget,
        dailyBudget:
          value.campaignDailyBudget ?? globalChanges.dailyBudget[campaignId],
        campaignId,
      };

      const isTotalBudgetGreaterThanDailyBudget: boolean =
        updatedCellDetails.dailyBudget
          ? Number(updatedCellDetails.value) >=
            Number(updatedCellDetails.dailyBudget)
          : true;

      if (selectedSalesChannel === FlywheelSalesChannel.Walmart) {
        const currentMerchantCountryId = campaignTableData.find(
          ({ campaignId: rowCampaignId }) => campaignId === rowCampaignId
        )?.campaignDetails.merchantCountryId;
        const currentMerchantType = allMerchants.find(
          ({ merchantCountryId }) =>
            merchantCountryId === currentMerchantCountryId
        )?.merchantType;

        const validationConfig = (() => {
          const constrainsts = getBidConstraint(
            bidConstraintsData.constraints,
            selectedAdType,
            selectedSalesChannel,
            CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
            merchantCountry,
            undefined,
            currentMerchantType
          );

          return {
            min: (constrainsts.minTotalBudget = 0),
            max: (constrainsts.maxTotalBudget = Infinity),
            default: (constrainsts.defaultTotalBudget = 0),
          };
        })();

        return (
          updatedCellDetails.value &&
          isTotalBudgetGreaterThanDailyBudget &&
          !isTotalBudgetInValid(
            selectedSalesChannel,
            updatedCellDetails.value,
            validationConfig.min,
            validationConfig.max
          )
        );
      } else {
        const validationConfig = (() => {
          const constrainsts = getBidConstraint(
            bidConstraintsData.constraints,
            selectedAdType,
            selectedSalesChannel,
            CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
            merchantCountry
          );

          return {
            min: (constrainsts.minTotalBudget = 0),
            max: (constrainsts.maxTotalBudget = Infinity),
            default: (constrainsts.defaultTotalBudget = 0),
          };
        })();

        return (
          updatedCellDetails.value &&
          isTotalBudgetGreaterThanDailyBudget &&
          !isTotalBudgetInValid(
            selectedSalesChannel,
            updatedCellDetails.value,
            validationConfig.min,
            validationConfig.max
          )
        );
      }
    }
  ).length;

  const validMinBidCount = useMemo(
    () =>
      updatedCellsData.filter(([campaignId, value]) => {
        const updatedCellDetails = {
          value: value.minBid,
          campaignId,
          maxBid: value.maxBid,
          macsTarget: value.macsTarget,
          automationStatus: value.automationStatus,
        };

        const campaignDetails = campaignTableData.find(
          ({ campaignId: rowCampaignId }) => campaignId === rowCampaignId
        )?.campaignDetails;

        const validationConfig = (() => {
          const constraints = getBidConstraint(
            bidConstraintsData.constraints,
            selectedAdType,
            selectedSalesChannel,
            CAMPAIGNS_API_COLUMN_NAME.MinBid,
            merchantCountry,
            campaignDetails?.targetingType,
            merchantType,
            campaignDetails?.campaignCostType
          );

          return {
            min: constraints.minBid,
            max: constraints.maxBid,
            default: constraints.defaultMinBid,
          };
        })();

        const cellChanges = globalTableCellChanges?.[campaignId];

        const maxBid = updatedCellDetails.maxBid ?? cellChanges?.maxBid;
        const macsTarget =
          updatedCellDetails.macsTarget ?? cellChanges?.macsTarget;

        return (
          updatedCellDetails.value &&
          !isMinBidInvalid(
            updatedCellDetails.value,
            updatedCellDetails.maxBid,
            validationConfig.min
          ) &&
          checkValidFlywheelSetting(
            updatedCellDetails.value,
            updatedCellDetails.automationStatus,
            maxBid,
            macsTarget
          )
        );
      }).length,
    [
      updatedCellsData,
      globalTableCellChanges,
      selectedAdType,
      selectedSalesChannel,
      merchantCountry,
    ]
  );

  const validMaxBidCount = useMemo(
    () =>
      updatedCellsData.filter(([campaignId, value]) => {
        const updatedCellDetails = {
          value: value.maxBid,
          campaignId,
          minBid: value.minBid,
          macsTarget: value.macsTarget,
          automationStatus: value.automationStatus,
        };

        const cellChanges = globalTableCellChanges?.[campaignId];

        const minBid = updatedCellDetails.minBid ?? cellChanges?.minBid;
        const macsTarget =
          updatedCellDetails.macsTarget ?? cellChanges?.macsTarget;

        const targetingType = campaignTableData.find(
          ({ campaignId: rowCampaignId }) => campaignId === rowCampaignId
        )?.campaignDetails.targetingType;

        const validationConfig = (() => {
          const constraints = getBidConstraint(
            bidConstraintsData.constraints,
            selectedAdType,
            selectedSalesChannel,
            CAMPAIGNS_API_COLUMN_NAME.MaxBid,
            merchantCountry,
            targetingType,
            merchantType
          );

          return {
            min: constraints.minBid,
            max: constraints.maxBid,
            default: constraints.defaultMaxBid,
          };
        })();

        return (
          updatedCellDetails.value &&
          !isMaxBidInvalid(
            updatedCellDetails.value,
            updatedCellDetails.minBid,
            validationConfig.max,
            validationConfig.min
          ) &&
          checkValidFlywheelSetting(
            updatedCellDetails.value,
            updatedCellDetails.automationStatus,
            minBid,
            macsTarget
          )
        );
      }).length,
    [
      updatedCellsData,
      globalTableCellChanges,
      selectedAdType,
      selectedSalesChannel,
      merchantCountry,
    ]
  );

  const validMacsTargetCount = useMemo(
    () =>
      updatedCellsData.filter(([campaignId, value]) => {
        const updatedCellDetails = {
          value: value.macsTarget,
          campaignId,
          automationStatus: value.automationStatus,
          minBid: value.minBid,
          maxBid: value.maxBid,
        };

        const cellChanges = globalTableCellChanges?.[campaignId];

        const minBid = updatedCellDetails.minBid ?? cellChanges?.minBid;

        const maxBid = updatedCellDetails.maxBid ?? cellChanges?.maxBid;

        const targetingType = campaignTableData.find(
          ({ campaignId: rowCampaignId }) => campaignId === rowCampaignId
        )?.campaignDetails.targetingType;

        const validationConfig = (() => {
          const constraints = getBidConstraint(
            bidConstraintsData.constraints,
            selectedAdType,
            selectedSalesChannel,
            CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
            merchantCountry,
            targetingType,
            merchantType
          );

          return {
            min: constraints.minMacsTarget,
            max: constraints.maxMacsTarget,
            default: constraints.defaultMacsTarget,
          };
        })();

        return (
          updatedCellDetails.value &&
          !isMacsTargetInvalid(
            updatedCellDetails.value,
            validationConfig.min,
            validationConfig.max
          ) &&
          checkValidFlywheelSetting(
            updatedCellDetails.value,
            updatedCellDetails.automationStatus,
            minBid,
            maxBid
          )
        );
      }).length,
    [
      updatedCellsData,
      globalTableCellChanges,
      selectedAdType,
      selectedSalesChannel,
      merchantCountry,
    ]
  );

  const validBuyBoxBidsCount = useMemo(
    () =>
      updatedCellsData.filter(
        ([, { buyBoxBidMultiplier }]) =>
          !isEmpty(buyBoxBidMultiplier) &&
          !isNil(buyBoxBidMultiplier) &&
          Number(buyBoxBidMultiplier) >= MIN_BUY_BOX_BID
      ).length,
    [updatedCellsData]
  );

  const validDesktopBidsCount = useMemo(
    () =>
      updatedCellsData.filter(
        ([, { desktopBidMultiplier }]) =>
          !isEmpty(desktopBidMultiplier) &&
          !isNil(desktopBidMultiplier) &&
          Number(desktopBidMultiplier) >= MIN_DESKTOP_BID
      ).length,
    [updatedCellsData]
  );

  const validHomePageBidsCount = useMemo(
    () =>
      updatedCellsData.filter(
        ([, { homePageBidMultiplier }]) =>
          !isEmpty(homePageBidMultiplier) &&
          !isNil(homePageBidMultiplier) &&
          Number(homePageBidMultiplier) >= MIN_HOME_PAGE_BID
      ).length,
    [updatedCellsData]
  );

  const validMobileBidsCount = useMemo(
    () =>
      updatedCellsData.filter(
        ([, { mobileBidMultiplier }]) =>
          !isEmpty(mobileBidMultiplier) &&
          !isNil(mobileBidMultiplier) &&
          Number(mobileBidMultiplier) >= MIN_MOBILE_BID
      ).length,
    [updatedCellsData]
  );

  const validAppBidsCount = useMemo(
    () =>
      updatedCellsData.filter(
        ([, { appBidMultiplier }]) =>
          !isEmpty(appBidMultiplier) &&
          !isNil(appBidMultiplier) &&
          Number(appBidMultiplier) >= MIN_APP_BID
      ).length,
    [updatedCellsData]
  );

  const validSearchIngridBidsCount = useMemo(
    () =>
      updatedCellsData.filter(
        ([, { searchIngridBidMultiplier }]) =>
          !isEmpty(searchIngridBidMultiplier) &&
          !isNil(searchIngridBidMultiplier) &&
          Number(searchIngridBidMultiplier) >= MIN_SEARCH_INGRID_BID
      ).length,
    [updatedCellsData]
  );

  const validStockUpBidsCount = useMemo(
    () =>
      updatedCellsData.filter(
        ([, { stockUpBidMultiplier }]) =>
          !isEmpty(stockUpBidMultiplier) &&
          !isNil(stockUpBidMultiplier) &&
          Number(stockUpBidMultiplier) >= MIN_STOCKUP_BID
      ).length,
    [updatedCellsData]
  );

  const validEndDateCount = useMemo(
    () =>
      updatedCellsData.filter(([campaignId, value]) => {
        const campaignStatus = campaignTableData.find(
          (row) => row.campaignId === campaignId
        )?.channelSettings.status;

        const details = {
          endDate: value.endDate,
          startDate: globalChanges.startDate[campaignId],
          status: value.status,
          campaignId,
        };

        if (details.endDate === '') {
          return true;
        }

        if (!isNil(details.endDate)) {
          const endDateTime = DateTime.fromFormat(
            details.endDate,
            CAMPAIGN_DATE_FORMAT
          );
          const startDateTime = DateTime.fromFormat(
            details.startDate,
            CAMPAIGN_DATE_FORMAT
          );

          const now = DateTime.local().startOf('day');
          const currentOrStartDate = now > startDateTime ? now : startDateTime;

          return (
            endDateTime.isValid &&
            currentOrStartDate.isValid &&
            endDateTime.diff(currentOrStartDate, 'days').days >= 0 &&
            !isCampaignStatusCompleted(
              campaignStatus,
              details.status as CampaignStatus
            )
          );
        }
        return false;
      }).length,
    [campaignTableData, updatedCellsData]
  );

  const validCampaignStatusCount = useMemo(
    () => updatedCellsData.filter(([, value]) => !!value.status).length,
    [updatedCellsData]
  );

  const validPlacementInclusionCarouselStatusCount = useMemo(
    () => updatedCellsData.filter(([, value]) => !!value.carouselStatus).length,
    [updatedCellsData]
  );

  const validPlacementInclusionItemBuyboxCount = useMemo(
    () => updatedCellsData.filter(([, value]) => !!value.itemBuyBox).length,
    [updatedCellsData]
  );

  const validPlacementInclusionItemCarouselCount = useMemo(
    () => updatedCellsData.filter(([, value]) => !!value.itemCarousel).length,
    [updatedCellsData]
  );

  const validBudgetRolloverCount = useMemo(
    () => updatedCellsData.filter(([, value]) => !!value.budgetRollover).length,
    [updatedCellsData]
  );

  const validPortfolioNameCount = useMemo(
    () => updatedCellsData.filter(([, value]) => !!value.portfolio).length,
    [updatedCellsData]
  );

  const validGroupsCount = useMemo(
    () =>
      updatedCellsData.filter(([, value]) => value.groupLabel !== undefined)
        .length,
    [updatedCellsData]
  );

  const validBiddingStrategyCount = useMemo(
    () =>
      updatedCellsData.filter(([, value]) => !!value.biddingStrategy).length,
    [updatedCellsData]
  );

  const validProductPageBidMultiplierCount = useMemo(
    () =>
      updatedCellsData.filter(([campaignId, value]) => {
        const updatedCellDetails = {
          value: value.productPageBidMultiplier,
          campaignId,
        };

        const {
          min: minProductMulitplierValue,
          max: maxProductMulitplierValue,
        } = (() => {
          const constraints = getBidConstraint(
            bidConstraintsData.constraints,
            selectedAdType,
            selectedSalesChannel,
            CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier,
            merchantCountry
          );

          return {
            min: constraints.minProductPageBidMultiplier,
            max: constraints.maxProductPageBidMultiplier,
          };
        })();

        return updatedCellDetails.value
          ? !isProductPageBidMultiplierInvalid(
              updatedCellDetails.value,
              minProductMulitplierValue,
              maxProductMulitplierValue
            )
          : false;
      }).length,
    [updatedCellsData, selectedAdType, selectedSalesChannel, merchantCountry]
  );

  const validTopOfSearchBidMultiplierCount = useMemo(
    () =>
      updatedCellsData.filter(([campaignId, value]) => {
        const updatedCellDetails = {
          value: value.topOfSearchBidMultiplier,
          campaignId,
        };

        const {
          min: minTopOfSearchMulitplierValue,
          max: maxTopOfSearchMulitplierValue,
        } = (() => {
          const constraints = getBidConstraint(
            bidConstraintsData.constraints,
            selectedAdType,
            selectedSalesChannel,
            CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
            merchantCountry
          );

          return {
            min: constraints.minTopOfSearchBidMultiplier,
            max: constraints.maxTopOfSearchBidMultiplier,
          };
        })();

        return !isTopOfSearchBidMultiplierInvalid(
          updatedCellDetails.value,
          minTopOfSearchMulitplierValue,
          maxTopOfSearchMulitplierValue
        );
      }).length,
    [updatedCellsData, selectedAdType, selectedSalesChannel, merchantCountry]
  );

  const bidAutomationCount = useMemo(
    () =>
      updatedCellsData.filter(([_campaignId, value]) => value.automationStatus)
        .length,
    [updatedCellsData]
  );

  const bidOptimizationCount = useMemo(
    () =>
      updatedCellsData.filter(([_campaignId, value]) => value.bidOptimization)
        .length,
    [updatedCellsData]
  );

  const validAdvertisingCount = useMemo(
    () =>
      updatedCellsData.filter(([_campaignId, value]) => value.advertisingGoal)
        .length,
    [updatedCellsData]
  );

  const belowTopOfSearchBidMultiplierCount = useMemo(
    () =>
      updatedCellsData.filter(([campaignId, value]) => {
        const updatedCellDetails = {
          value: value.belowTopOfSearchBidMultiplier,
          campaignId,
        };

        const validationConfig = (() => {
          const constraints = getBidConstraint(
            bidConstraintsData.constraints,
            selectedAdType,
            selectedSalesChannel,
            CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier,
            merchantCountry
          );

          return {
            min: constraints.minBelowTopOfTheSearchBidMultiplier,
            max: constraints.maxBelowTopOfTheSearchBidMultiplier,
          };
        })();

        return (
          updatedCellDetails.value &&
          !isBelowTopOfTheSearchBidMultiplierInvalid(
            updatedCellDetails.value,
            validationConfig.max,
            validationConfig.min
          )
        );
      }).length,
    [updatedCellsData, selectedAdType, selectedSalesChannel, merchantCountry]
  );

  const validCount =
    validCampaignsCount +
    validDailyBudgetCount +
    validTotalBudgetCount +
    validBuyBoxBidsCount +
    validDesktopBidsCount +
    validHomePageBidsCount +
    validMobileBidsCount +
    validAppBidsCount +
    validSearchIngridBidsCount +
    validStockUpBidsCount +
    validEndDateCount +
    validCampaignStatusCount +
    validPlacementInclusionCarouselStatusCount +
    validPlacementInclusionItemBuyboxCount +
    validPlacementInclusionItemCarouselCount +
    validBudgetRolloverCount +
    validPortfolioNameCount +
    validGroupsCount +
    validBiddingStrategyCount +
    validProductPageBidMultiplierCount +
    validTopOfSearchBidMultiplierCount +
    validMinBidCount +
    validMaxBidCount +
    validMacsTargetCount +
    bidAutomationCount +
    bidOptimizationCount +
    belowTopOfSearchBidMultiplierCount +
    validAdvertisingCount;

  const inValidEndDateCountDueToExtendedCampaignStatus = useMemo(
    () =>
      updatedCellsData
        .map(([campaignId, value]) => ({
          newEndDate: value.endDate,
          campaignStatus: value.status,
          originalEndDate: globalChanges.endDate[campaignId],
        }))
        .filter(({ newEndDate, originalEndDate, campaignStatus }) => {
          if (campaignStatus === CampaignStatus.Extend) {
            const now = DateTime.local().startOf('day');
            if (
              newEndDate === '' || // new end date is "no end date"
              newEndDate || // user has set new end date (user always sets valid end date. So no need to check newEndDate-endDate>0)
              (isNil(newEndDate) && originalEndDate === '') || // user has not changed end date, and original end date is empty string, i.e. no end date
              DateTime.fromISO(originalEndDate).diff(now, 'days').days >= 0 // original end date >=0 now
            ) {
              return false;
            }
            return true;
          }
          return false;
        }).length,
    [updatedCellsData, globalChanges]
  );

  const getSaveButtonState = () => {
    if (
      changedCount === 0 ||
      inValidEndDateCountDueToExtendedCampaignStatus > 0
    ) {
      return ButtonState.Disabled;
    }
    return validCount === changedCount
      ? ButtonState.Enabled
      : ButtonState.Disabled;
  };

  const changedCount = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) => {
      return tableSelectors.getChangedCountSelector()(tableState, TABLE_ID);
    }
  );

  const saveChangesFlagContext = useContext<SaveChangesFlagContextState>(
    SaveChangesFlagContext
  );

  useEffect(() => {
    saveChangesFlagContext.updateSaveChangesData({
      editDataFlag: changedCount > 0,
      saveChangesModalIsOpen: false,
      navPath: saveChangesFlagContext.saveChangesData.navPath,
    });
  }, [changedCount]);

  useEffect(() => {
    updateGlobalCampaignsData();
  }, [campaignTableData, updatedCells]);

  const isTableDataEdited: boolean =
    saveChangesFlagContext.saveChangesData.editDataFlag;

  useEffect(() => {
    // Show prompt if table is edited and user is on edit mode
    initBeforeUnLoad(isTableDataEdited && activeSliderIndex === 1);
  }, [isTableDataEdited]);

  const initBeforeUnLoad = (showExitPrompt: boolean) => {
    window.onbeforeunload = (event: Event) => {
      if (showExitPrompt) {
        event.preventDefault();
        return '';
      }
    };
  };

  const { hasTimeElapsed, updateTime } = useTime(
    parseInt(
      process.env.REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES ||
        REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES
    )
  );

  const updateGlobalCampaignsData = () => {
    const orginalGlobal = cloneDeep(tableChange.global) as TableCampaignsGlobal;

    const currentVisibleCurrencyCode = campaignTableData
      .filter((campaign) => campaign.channelSettings.dailyBudget?.amount)
      .reduce((currencyCodes, campaign) => {
        return {
          ...currencyCodes,
          [campaign.campaignId]: campaign.channelSettings.dailyBudget?.currency,
        };
      }, {});

    const currentDailyBudget = campaignTableData
      .filter(
        (campaign) =>
          campaign.channelSettings.dailyBudget?.amount &&
          updatedCells[campaign.campaignId]
      )
      .reduce((dailyBudget, campaign) => {
        return {
          ...dailyBudget,
          [campaign.campaignId]: campaign.channelSettings.dailyBudget?.amount,
        };
      }, {});

    const currentTotalBudget = campaignTableData
      .filter(
        (campaign) =>
          campaign.channelSettings.totalBudget?.amount &&
          updatedCells[campaign.campaignId]
      )
      .reduce((totalBudget, campaign) => {
        return {
          ...totalBudget,
          [campaign.campaignId]: campaign.channelSettings.totalBudget?.amount,
        };
      }, {});

    const currentEndDate = campaignTableData.reduce((endDates, campaign) => {
      return {
        ...endDates,
        [campaign.campaignId]: campaign.channelSettings.endDate || '', // endDate = undefined means no end date. Hence ''
      };
    }, {});

    const currentStartDate = campaignTableData
      .filter((campaign) => campaign.channelSettings.startDate)
      .reduce((accStartDate, campaign) => {
        return {
          ...accStartDate,
          [campaign.campaignId]: campaign.channelSettings.startDate,
        };
      }, {});

    const updatedGlobalState: TableCampaignsGlobal = {
      currencyCode: {
        ...orginalGlobal.currencyCode,
        ...currentVisibleCurrencyCode,
      },
      dailyBudget: {
        ...orginalGlobal.dailyBudget,
        ...currentDailyBudget,
      },
      totalBudget: {
        ...orginalGlobal.totalBudget,
        ...currentTotalBudget,
      },
      endDate: {
        ...orginalGlobal.endDate,
        ...currentEndDate,
      },
      startDate: {
        ...orginalGlobal.startDate,
        ...currentStartDate,
      },
    };

    const modifiedGlobal: TableCampaignsGlobal = {
      ...orginalGlobal,
      ...updatedGlobalState,
    };

    const equal = isEqual(orginalGlobal, modifiedGlobal);

    if (!equal) {
      dispatch(
        tableActions.updateGlobal({
          tableId: TABLE_ID,
          updatedValues: modifiedGlobal,
        })
      );
    }
  };

  const eligibleCampaignIds = useMemo(
    () =>
      campaignTableData
        .filter(
          ({ channelSettings: { status } }) => !isCampaignStatusArchived(status)
        )
        .map(({ campaignId }) => campaignId),
    [campaignTableData]
  );
  const isAllRowsSelected = useMemo(
    () =>
      isEqual(
        [...cloneDeep(tableChange).select.rows].sort((a, b) =>
          a.localeCompare(b)
        ),
        [...eligibleCampaignIds].sort((a, b) => a.localeCompare(b))
      ),
    [eligibleCampaignIds, tableChange]
  );

  const handleSelectChange = (): void => {
    dispatch(
      tableActions.changeSelect({
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        selectedRows: isAllRowsSelected ? [] : eligibleCampaignIds,
      })
    );
  };

  const clearSelectedRows = () => {
    dispatch(
      tableActions.changeSelect({
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        selectedRows: [],
      })
    );
  };

  const getVisibleColumns = (
    isAIplan: boolean
  ): FlywheelTableColumnGroup<CampaignDetails, TableDataAdsManager>[] => {
    const CAMPAIGN_COLUMNS = generateColumns({
      isEditMode: tableMode === DualModes.Edit,
      isAIPlanEnabled: isAIplan,
      headerExtraProps: {
        checked: getCheckBoxState(
          eligibleCampaignIds.length,
          selectedRowIds.length
        ),
        onSelectChange: handleSelectChange,
      },
      hideCampaignAdFormat: true,
      showSmartCampaign: shouldShowSmartCampaigns(optimizelyContext),
    });
    return CAMPAIGN_COLUMNS.map((column) => {
      const columnInGroups = column.columnsInGroup.filter((subColumn) =>
        apiColumnsWithAdLevel.columns.includes(subColumn.columnName)
      );
      return {
        ...column,
        columnsInGroup: columnInGroups.filter((col) => {
          if (
            optimizelyContext.featureFlags[
              OptimizelyFlags.SponsoredVideosEditable
            ] ||
            selectedAdType !== AdType.SponsoredVideos
          ) {
            return true;
          }

          return ![
            CAMPAIGNS_API_COLUMN_NAME.CampaignTargetingType,
            CAMPAIGNS_API_COLUMN_NAME.Group,
            CAMPAIGNS_API_COLUMN_NAME.BudgetType,
            CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
            CAMPAIGNS_API_COLUMN_NAME.BudgetRollover,
            CAMPAIGNS_API_COLUMN_NAME.AdvertisedSkuUnits,
            CAMPAIGNS_API_COLUMN_NAME.DirectAdSales,
            CAMPAIGNS_API_COLUMN_NAME.DirectACOS,
            CAMPAIGNS_API_COLUMN_NAME.DirectROAS,
            CAMPAIGNS_API_COLUMN_NAME.RelatedClickRevenue,
            CAMPAIGNS_API_COLUMN_NAME.OtherSkuUnits,
            CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrders,
            CAMPAIGNS_API_COLUMN_NAME.PercentageOfOrdersNewToBrand,
            CAMPAIGNS_API_COLUMN_NAME.NewToBrandSales,
            CAMPAIGNS_API_COLUMN_NAME.PercentageOfSalesNewToBrand,
            CAMPAIGNS_API_COLUMN_NAME.NewToBrandAdUnitsSold,
            CAMPAIGNS_API_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand,
          ].includes(col.columnName);
        }),
      };
    }).filter((column) => column.columnsInGroup.length > 0);
  };

  const getColumnManagerOptions = (
    columnManagerOptions: ColumnManagerOption[] | undefined
  ) => {
    let updatedColumns = columnManagerOptions?.filter(
      (option) => option.value !== DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat
    );
    // Filter out smart campaigns columns if the FF is OFF
    updatedColumns = updatedColumns?.filter((option) =>
      shouldShowSmartCampaigns(optimizelyContext)
        ? true
        : ![
            DATA_INSPECTOR_COLUMN_NAME.AdvertisingGoal,
            DATA_INSPECTOR_COLUMN_NAME.SmartStructure,
            DATA_INSPECTOR_COLUMN_NAME.CampaignType,
          ].includes(option.value)
    );
    return updatedColumns?.filter((option) => {
      if (
        optimizelyContext.featureFlags[
          OptimizelyFlags.SponsoredVideosEditable
        ] ||
        selectedAdType !== AdType.SponsoredVideos
      ) {
        return true;
      }

      return ![
        DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType,
        DATA_INSPECTOR_COLUMN_NAME.Group,
        DATA_INSPECTOR_COLUMN_NAME.BudgetType,
        DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget,
        DATA_INSPECTOR_COLUMN_NAME.BudgetRollover,
        DATA_INSPECTOR_COLUMN_NAME.DirectAdSales,
        DATA_INSPECTOR_COLUMN_NAME.DirectACOS,
        DATA_INSPECTOR_COLUMN_NAME.DirectROAS,
        DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue,
        DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits,
        DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits,
        DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales,
        DATA_INSPECTOR_COLUMN_NAME.PercentageOfSalesNewToBrand,
        DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders,
        DATA_INSPECTOR_COLUMN_NAME.PercentageOfOrdersNewToBrand,
        DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold,
        DATA_INSPECTOR_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand,
      ].includes(option.value);
    });
  };

  const onKeepEditingClick = () => {
    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      saveChangesModalIsOpen: false,
      aoAdLevel: undefined,
    });
    setClickedOnView(false);
  };

  const onLeaveWithoutSaveClick = () => {
    const adLevel = saveChangesFlagContext.saveChangesData.aoAdLevel || '';

    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
      aoAdLevel: undefined,
    });

    if (clickedOnView) {
      toggleTableMode();
      setClickedOnView(false);
    } else if (saveChangesFlagContext.saveChangesData.aoAdLevel) {
      switchLevelController(adLevel);
    } else {
      navigate(saveChangesFlagContext.saveChangesData.navPath);
    }
  };

  const handleOperationsAsPerTableMode = (mode: DualModes) => {
    if (!isUserRoleViewOnly) {
      if (mode === DualModes.View) {
        setClickedOnView(true);
      }

      if (
        mode === DualModes.View &&
        saveChangesFlagContext.saveChangesData.editDataFlag
      ) {
        saveChangesFlagContext.updateSaveChangesData({
          ...saveChangesFlagContext.saveChangesData,
          saveChangesModalIsOpen: true,
        });
      } else {
        clearAllChanges();
        toggleTableMode();
      }
    }
  };

  const discardChanges = () => {
    toggleTableMode();

    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
    });

    clearAllChanges();
  };

  const clearAllChanges = () => {
    setGlobalTableCellChanges(undefined);
    dispatch(
      tableActions.clearAllChanges({
        tableId: TABLE_ID,
      })
    );
  };

  const campaignDataRequest = request();
  // export api request:
  // channel setting columns for export request body that are selected in column manager but not mandatory for edit functionality
  const campaignChannelSettingWithoutMandatoryColumns = [
    ...apiColumnsWithAdLevel.columns.filter(
      (column) =>
        CAMPAIGNS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields
    ),
  ];

  let campaignDetailsWithoutMandatoryColumns = [
    ...AD_LEVEL_EXTERNAL_ID_MAPPER.campaigns,
    ...apiColumnsWithAdLevel.columns.filter(
      (column) =>
        CAMPAIGNS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields
    ),
  ];

  if (
    campaignDetailsWithoutMandatoryColumns.includes(
      CAMPAIGNS_API_COLUMN_NAME.MerchantName
    )
  ) {
    campaignDetailsWithoutMandatoryColumns =
      campaignDetailsWithoutMandatoryColumns
        .filter((column) => column !== CAMPAIGNS_API_COLUMN_NAME.MerchantName)
        .concat([MERCHANT_NAME_COLUMN_ID_FOR_EXPORT]);
  }

  const getCampaignDetailsFieldsForCsvDownload = () => {
    const campaignDetailsFields = campaignDetailsFieldsForCSVExport;

    return [
      ...campaignDetailsFields,
      CAMPAIGNS_API_COLUMN_NAME.CampaignCostType,
      CAMPAIGNS_API_COLUMN_NAME.CampaignTargetingType,
      CAMPAIGNS_API_COLUMN_NAME.MerchantType,
    ];
  };

  const campaignDataReqestForCSVDownload: CampaignDataRequest = {
    ...campaignDataRequest,
    campaignDetailsFields: getCampaignDetailsFieldsForCsvDownload(),
    flywheelSettingsFields: campaignflywheelSettingsFieldsForCSVExport,
    performanceFields: apiColumnsWithAdLevel.columns.filter(
      (column) =>
        CAMPAIGNS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        CampaignDataApiColumnGroupIdentifier.PerformanceFields
    ),
    channelSettingsFields: [],
    placementSettingsFields: [],
  };

  const refreshTable = () =>
    dispatch(
      tableThunks.refreshTable(ADS_MANAGER_CAMPAIGNS_TABLE_ID, dataFetcher)
    );

  const getExportRequest = () => {
    const { performanceFields, placementSettingsFields } = campaignDataRequest;

    return {
      ...campaignDataRequest,
      campaignDetailsFields: campaignDetailsWithoutMandatoryColumns,
      channelSettingsFields: campaignChannelSettingWithoutMandatoryColumns,
      flywheelSettingsFields: getFlywheelSettingsColumn(
        AdLevel.Campaigns,
        merchantsWithAutomationEnabled.length > 0
      ),
      performanceFields: performanceFields.filter((column) =>
        apiColumnsWithAdLevel.columns.includes(column)
      ),
      placementSettingsFields: placementSettingsFields.filter((column) =>
        apiColumnsWithAdLevel.columns.includes(column)
      ),
    };
  };

  const getCampaignLevelViewModeRightSideComponents = () => {
    const campaignViewModeRightSideComponents = [
      <Tooltip
        overwrittenTooltipClassnames="w-auto max-w-400"
        theme={Theme.Dark}
        content={intl.formatMessage({
          id: I18nKey.GENERIC_DOWNLOAD_CSV,
        })}
        tooltipSize={TooltipSize.Small}
        hideArrow
      >
        <ExportAdLevelData<CampaignDataRequest>
          aoApiClient={aoApiClient}
          buttonState={exportButtonState}
          setButtonState={setExportButtonState}
          salesChannel={selectedSalesChannelName}
          request={getExportRequest()}
          adLevelDropDownSelectedValue={
            adLevelDropDownSelectedValues || undefined
          }
          filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
          startDate={startDate}
          endDate={endDate}
          adType={selectedAdType}
          transformFilters={getTransformedAddedByFilter}
          hideExportText={true}
        />
      </Tooltip>,
    ];

    campaignViewModeRightSideComponents.push(
      <CampaignGroupingModal
        aoApiClient={aoApiClient}
        merchantCountryId={selectedMerchantCountries as string}
        refreshTable={() => {
          reloadGroupsData();
          refreshTable();
        }}
        merchantType={merchantType}
        salesChannelId={selectedSalesChannelId}
        adType={selectedAdType}
        filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
        campaignExportRequest={getExportRequest}
        isUserRoleViewOnly={!!isUserRoleViewOnly}
      />
    );

    if (
      selectedAdType === AdType.SponsoredBrands &&
      aiEnabled &&
      shouldShowManageBidding(optimizelyContext, selectedAdType)
    ) {
      campaignViewModeRightSideComponents.push(
        <ManageBiddingInternal<CampaignDataRequest>
          aoApiClient={aoApiClient}
          adLevel={adLevelDropDownSelectedValues || AdLevel.Campaigns}
          filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
          request={campaignDataReqestForCSVDownload}
          salesChannelName={selectedSalesChannel}
          adType={selectedAdType}
          merchantName={merchantCountryNameForCSVDownload}
          refreshTable={refreshTable}
          salesChannelId={selectedSalesChannelId}
          merchantType={merchantType!}
          isUserRoleViewOnly={!!isUserRoleViewOnly}
        />
      );
    }

    return campaignViewModeRightSideComponents;
  };

  const tableActionRowRightSideElements =
    tableMode === DualModes.View
      ? getCampaignLevelViewModeRightSideComponents()
      : [
          <Button
            variant={ButtonVariant.BlackAndWhite}
            size={ButtonSize.Medium}
            label={intl.formatMessage({ id: I18nKey.CANCEL })}
            onClick={discardChanges}
            dataTestId={'ao_campaignTableCancel'}
          />,

          <SaveChangeButton
            toggleTableMode={toggleTableMode}
            changedCount={validCount}
            onSaveHandler={onEditSave}
            buttonState={getSaveButtonState()}
            dataTestId={'ao_campaignTableSave'}
          />,
        ];

  useEffect(() => {
    if (!firstApiCall.current) {
      refreshTable();
    } else {
      firstApiCall.current = false;
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (ref.current && hasTimeElapsed()) {
      updateTime();
      refreshTable();
    } else {
      ref.current = true;
      if (location.state && location.state.filter) {
        let { filter } =
          (location.state && (location.state as { filter: Filter[] })) || [];
        dispatch(
          tableActions.updateFilters({
            tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
            filters: filter || currentTableFilters,
            replace: true,
          })
        );
      }
    }
  }, [apiColumnsWithAdLevel.columns]);

  const dataFields = getFilterColumnDefinitons(
    CURRENCY_CODE,
    selectedSalesChannelName as FlywheelSalesChannel,
    first(adTypes),
    adLevelDropDownSelectedValues || undefined,
    merchantDetails,
    false,
    undefined,
    false,
    undefined,
    false,
    false,
    groupNames,
    shouldShowSmartCampaigns(optimizelyContext),
    optimizelyContext.featureFlags[OptimizelyFlags.SponsoredVideosEditable]
  );
  let currentFilters = [
    ...currentTableFilters,
    ...(filtersFromBrowserStorage ?? []),
  ];

  const upgradeFiltersInStorage = (filters: Filter[]) => {
    fromNullable(storeFiltersInBrowserStorage)
      .map((fn) => fn(filters))
      .toUndefined();
  };

  const updateFilters = (filters: Filter[]) => {
    clearSelectedRows();
    const existingSearchFilters: Filter[] = currentFilters.filter(
      (currentFilter) =>
        ALL_AD_LEVELS_FILTER_FILED_MAPPER.find(
          (filter) => filter.alias === currentFilter.field
        )
    );
    const newFilters: Filter[] = [...existingSearchFilters, ...filters];
    dispatch(
      tableActions.updateFilters({
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        filters: newFilters,
        replace: true,
      })
    );
    upgradeFiltersInStorage(newFilters);
    if (onFilterUpdate) {
      onFilterUpdate(newFilters);
    }
  };

  const [isFilterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);
  const [isBulkEditModalOpen, setBulkEditModalOpen] = useState<boolean>(false);
  const [bulkEditSelections, setBulkEditSelections] = useState<
    Record<string, boolean>
  >({});

  const getValidSelectedAdvertisingGoalCampaignIds = () => {
    return campaignTableData
      .filter(
        ({ campaignId, campaignDetails: { advertisingGoal } }) =>
          eligibleCampaignIds.includes(campaignId) &&
          selectedRowIds.includes(campaignId) &&
          advertisingGoal &&
          SMART_GOALS.includes(advertisingGoal)
      )
      .flatMap((validSmartCampaigns) => validSmartCampaigns.campaignId);
  };

  const onSelect = (columnKey: string) => (value: boolean) => {
    if (columnKey === CampaignBulkEditModalValues.AdvertisingGoal) {
      executeMissingCogsApiAndSetStatus();
    }
    setBulkEditSelections((prevValues: Record<string, boolean>) => ({
      ...prevValues,
      [columnKey]: value,
    }));
  };

  const [campaignBulkEditValues, setCampaignBulkEditValues] =
    useState<BulkEditValues>({});

  const [cogsStatus, setCogsStatus] = useState<COGSUploadStatus>(
    COGSUploadStatus.Loading
  );

  const executeMissingCogsApiAndSetStatus = () => {
    if (cogsStatus !== COGSUploadStatus.Loading) {
      setCogsStatus(COGSUploadStatus.Loading);
    }
    const campaignIds = getValidSelectedAdvertisingGoalCampaignIds();
    if (campaignIds.length === 0) {
      return;
    }
    const resp = invokeMissingCogsApi(campaignIds);
    resp?.then((res) => {
      const filteredResp = res.campaignMissingCogs.filter(
        (missingCogsData) => missingCogsData.isMissingCogs
      );
      if (filteredResp.length > 0) {
        setCogsStatus(COGSUploadStatus.MissingCogs);
      } else {
        setCogsStatus(COGSUploadStatus.Success);
      }
    });
  };
  const onMissingCogsClick = () => {
    setShowMissingCogsSlideover(true);
  };

  const campaignsBulkEditControlCongiguration = isBulkEditModalOpen
    ? getBulkEditConfigurationForCampaigns({
        intl,
        salesChannel: selectedSalesChannel,
        adType: selectedAdType,
        onSelect,
        cogsUploadStatus: cogsStatus,
        onMissingCogsClick,
        onChange: noop,
        selections: bulkEditSelections,
        portfolioNames,
        groupNames,
        openEditGroupsSlideout: () => {
          setShowCreateEditSlideoverBackButton(true);
          setShowCreateEditSlideover(true);
        },
        bidConstraintsData: [],
        selectedCount: selectedRowIds.length,
        selectSettings: tableChange.select,
        tableChange,
        updateCells: tableChange.cell,
        campaignsTableData: campaignTableData.filter(
          ({ campaignId }) =>
            eligibleCampaignIds.includes(campaignId) &&
            selectedRowIds.includes(campaignId)
        ),
        bulkEditValues: campaignBulkEditValues,
        showSmartCampaigns: shouldShowSmartCampaigns(optimizelyContext),
      })
    : [];

  const onSubmitHandler = (bulkEditValues: BulkEditValues) => {
    const bulkEditChanges: TableCellChange = cloneDeep(tableChange.cell);
    const selectedCampaigns = campaignTableData.filter(({ campaignId }) =>
      selectedRowIds.includes(campaignId)
    );
    const selectionEntries = Object.entries(bulkEditSelections);

    updateCampaignsBulkEditChanges({
      selectionEntries,
      campaignsBulkEditControlCongiguration,
      selectedCampaigns,
      bulkEditChanges,
      tableChange,
      bulkEditValues,
      selectedSalesChannel,
      selectedAdType,
    });

    dispatch(
      tableActions.applyBulkChanges({
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        bulkChanges: { ...tableChange.cell, ...bulkEditChanges },
        columnDataMapping: EDIT_CAMPAIGNS_API_COLUMN_DATA_MAPPING,
        uniqKey: TABLE_UNIQ_KEY[ADS_MANAGER_CAMPAIGNS_TABLE_ID],
        percentageBasedColumns: PERCENTAGE_BASED_COLUMNS,
        booleanColumns: BOOLEAN_COLUMNS,
      })
    );

    setBulkEditModalOpen(false);
    setBulkEditSelections({});
  };

  const onActionClick = (actionType: Variant) => {
    if (actionType === Variant.Discard && areNoChangesDone(updatedCells)) {
      discardChanges();
      return;
    }
    setConfirmationModalVariant(actionType);
  };
  const shouldShowCreateKeywordsMenu = (
    selectedAdType: AdType,
    adLevelDropDownSelectedValues: AdLevel,
    merchant?: MerchantType
  ) => {
    if (
      adLevelDropDownSelectedValues === AdLevel.Campaigns &&
      selectedAdType === AdType.SponsoredProducts
    ) {
      if (
        selectedSalesChannel === FlywheelSalesChannel.Amazon &&
        merchant === MerchantType.Vendor
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const onAddKeywords = (type: KeywordType) => {
    if (type === KeywordType.SPKeywordCampaign) {
      navigate(
        `${CampaignCreatorFullRoutes.Setup}?merchantCountrieId=${selectedMerchantCountries}`
      );
    }
  };

  const confirmationModalClick = (kind: ConfirmationModalClickKind) => {
    switch (confirmationModalVariant) {
      case Variant.Apply:
        if (kind === ConfirmationModalClickKind.APPLY) {
          setConfirmationModalVariant(null);
          onEditSave();
        } else {
          setConfirmationModalVariant(null);
        }
        break;
      case Variant.Discard:
        if (kind === ConfirmationModalClickKind.APPLY) {
          discardChanges();
          setConfirmationModalVariant(null);
        } else {
          setConfirmationModalVariant(null);
        }
    }
  };

  useEffect(() => {
    if (isBulkEditModalOpen) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
  }, [isBulkEditModalOpen]);

  const currentlySelectedMerchantCountryId = isArray(selectedMerchantCountries)
    ? first(selectedMerchantCountries as string[])
    : selectedMerchantCountries;

  const loadQuickFilterData = () => {
    if (
      shouldShowSmartCampaigns(optimizelyContext) &&
      selectedAdType === AdType.SponsoredProducts
    ) {
      const resp = dataFetcherWithoutQuickFilter({
        sorts: [],
        filters: currentFilters,
        itemsPerPage: 1,
        page: 1,
      });
      resp.then((res) => {
        setQuickFilterData({
          smartEntityCount: res.smartEntityCount,
          externalEntityCount: res.externalEntityCount,
        });
      });
    }
  };

  useEffect(() => {
    loadQuickFilterData();
    refreshTable();
  }, [campaignTypeQuickFilter]);

  const invokeMissingCogsApi = (campaignIds: string[]) => {
    if (currentlySelectedMerchantCountryId && merchantType) {
      return aoApiClient.getMissingCogs(accountId, {
        merchantCountryId: currentlySelectedMerchantCountryId,
        salesChannelId: MAP_SALES_CHANNEL_NAME_TO_ID[selectedSalesChannel],
        merchantType: merchantType,
        campaignIds,
      });
    }
  };
  const [showMissingCogsSlideover, setShowMissingCogsSlideover] =
    useState<boolean>(false);

  const mcid = isArray(selectedMerchantCountries)
    ? first(selectedMerchantCountries as string[])
    : selectedMerchantCountries;
  const merchant = allMerchants.find((item) => item.merchantCountryId === mcid);

  const cogsDetailsDataFetcherForMultipleCampaigns = () => {
    const currentMcId = isArray(selectedMerchantCountries)
      ? first(selectedMerchantCountries as string[])
      : selectedMerchantCountries;

    return aoApiClient.getCogsDetailsForMultipleCampaigns(accountId, {
      merchantCountryId: currentMcId || '',
      salesChannelId: selectedSalesChannelId,
      merchantType: merchantType || MerchantType.Seller,
      campaignIds: getValidSelectedAdvertisingGoalCampaignIds(),
    });
  };

  return (
    <>
      {showMissingCogsSlideover &&
        cogsDetailsDataFetcherForMultipleCampaigns && (
          <EstAdGrossMarginSlideover
            tableId={EST_AD_GROSS_MARGIN_TABLE_ADS_ID}
            selectedMerchant={merchant!}
            fromAdsManager={true}
            dataFetcher={cogsDetailsDataFetcherForMultipleCampaigns()}
            closeModal={() => setShowMissingCogsSlideover(false)}
            onSaveSuccess={() => {
              executeMissingCogsApiAndSetStatus();
            }}
          />
        )}
      <KeepEditingModal
        showModal={
          saveChangesFlagContext.saveChangesData.saveChangesModalIsOpen
        }
        onKeepEditing={onKeepEditingClick}
        onDiscard={onLeaveWithoutSaveClick}
        tableId={TABLE_ID}
      />
      {showCreateEditSlideover && (
        <CreateEditGroupSlideover
          aoApiClient={aoApiClient}
          showModal={true}
          onClose={() => {
            setShowCreateEditSlideover(false);
            setShowCreateEditSlideoverBackButton(false);
          }}
          allGroupsData={groupNames}
          afterGroupUpdateOrCreate={reloadGroupsData}
          merchantCountryId={currentlySelectedMerchantCountryId!}
          showBackButton={showCreateEditSlideoverBackButton}
          refreshTableData={refreshTable}
        />
      )}
      {confirmationModalVariant && (
        <BulkEditConfirmationModal
          showModal={!!confirmationModalVariant}
          variant={confirmationModalVariant}
          onPrimaryButtonClick={() =>
            confirmationModalClick(ConfirmationModalClickKind.APPLY)
          }
          onSecondryButtonClick={() =>
            confirmationModalClick(ConfirmationModalClickKind.CANCEL)
          }
        />
      )}
      {isBulkEditModalOpen && !isUserRoleViewOnly ? (
        <BulkEditSlideOut
          isOpen={isBulkEditModalOpen}
          onClose={() => {
            setBulkEditModalOpen(false);
            setBulkEditSelections({});
          }}
          primaryButtonText={intl.formatMessage({
            id: I18nKey.GENERIC_SUBMIT,
          })}
          secondaryButtonText={intl.formatMessage({
            id: I18nKey.GENERIC_CANCEL_LABEL,
          })}
          editControls={campaignsBulkEditControlCongiguration}
          headerText={intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGNS_SLIDEOUT_HEADER,
          })}
          onSubmit={onSubmitHandler}
          onCancel={() => setBulkEditModalOpen(false)}
          onChange={(bulkEditValues: BulkEditValues) =>
            setCampaignBulkEditValues(bulkEditValues)
          }
        />
      ) : null}
      <TableActionRow
        className="mt-16 rounded-t"
        spacingBetweenElements={8}
        leftSideElements={[
          <FlywheelSearchInput
            tableId={ADS_MANAGER_CAMPAIGNS_TABLE_ID}
            inputSearchColumnName={CAMPAIGN_TABLE_SEARCH_KEY}
            searchInputSize={SearchInputSize.Medium}
            searchInputPlaceholder={getSearchInputPlaceholder(
              selectedAdType,
              adLevelDropDownSelectedValues as AdLevel,
              intl
            )}
            clearSelectedRows={clearSelectedRows}
            onFilterUpdate={onFilterUpdate}
            upgradeFiltersInStorage={upgradeFiltersInStorage}
            hasSearchInputWithButton
            dataTestId={`${ADS_MANAGER_CAMPAIGNS_TABLE_ID}_search_input`}
            searchInputClassName="w-480"
          />,
          <FiltersMenu
            customFilterIcon={FilterIcon}
            currency={currencyCode as CurrencyCode}
            dataFields={dataFields}
            currentFilters={currentFilters}
            handleSave={updateFilters}
            filterButtonSize={ButtonSize.Medium}
            isOpen={isFilterMenuOpen}
            setOpen={setFilterMenuOpen}
            filterDateFormat={REQUEST_DATE_FORMAT}
            singleDecimalColumns={COLUMNS_WITH_SINGLE_DECIMAL_FILTER}
            dataTestId={`${ADS_MANAGER_CAMPAIGNS_TABLE_ID}_filters_menu`}
            filtersContainerClass={
              'transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:left-0'
            }
          />,
          getCampaignTypeQuickFilters(
            intl,
            selectedAdType,
            campaignTypeQuickFilter,
            onQuickFilterClick,
            shouldShowSmartCampaigns(optimizelyContext),
            quickFilterData?.smartEntityCount,
            quickFilterData?.externalEntityCount
          ),
        ]}
        rightSideElements={[
          <Tooltip
            overwrittenTooltipClassnames="w-auto max-w-400"
            theme={Theme.Dark}
            content={intl.formatMessage({
              id: I18nKey.GENERIC_COLUMNS,
            })}
            tooltipSize={TooltipSize.Small}
            hideArrow
          >
            <ColumnManager
              variant={ColumnManagerVariant.ColumnGroupWithHeader}
              values={selectedColumns ?? []}
              onChange={onColumnSelectionChange}
              options={getColumnManagerOptions(columnManagerOptions)}
              hideColumnText={true}
              tooltipClass="right-0"
              dataTestId={`${ADS_MANAGER_CAMPAIGNS_TABLE_ID}_column_manager`}
            />
          </Tooltip>,
          ...getCampaignLevelViewModeRightSideComponents(),
          ...(shouldShowCreateKeywordsMenu(
            selectedAdType,
            adLevelDropDownSelectedValues as AdLevel,
            merchantType
          )
            ? [
                <CreateKeywordsMenu
                  onAddKeywords={onAddKeywords}
                  showKeywords={false}
                  key={adLevelDropDownSelectedValues}
                  disabledSPKeywordCampaign={checkavailability}
                  showNegativeKeywords={false}
                  showSPKeywordCampaign={true}
                  openSelectMenuOnLeft={true}
                  isUserRoleViewOnly={!!isUserRoleViewOnly}
                />,
              ]
            : []),
        ]}
        fullWidthElementIndex={-1}
      />
      <UpdatedFlywheelTable<CampaignDetails, TableDataAdsManager>
        tableData={{
          isEditMode: tableMode === DualModes.Edit,
          adTypes,
          salesChannel: selectedSalesChannel,
          portfolioNames,
          groupNames,
          openCreateEditGroupSlideover: () => {
            setShowCreateEditSlideoverBackButton(false);
            setShowCreateEditSlideover(true);
          },
          merchantType,
          merchantCountry,
          selectedAdType,
          selectedMerchantCountries,
          aoApiClient,
          updateEditedRecords,
          selectedEndDate: endDate,
          allMerchants,
          aiEnabled,
          merchantsWithAutomationEnabled,
          showSmartCampaign: shouldShowSmartCampaigns(optimizelyContext),
          invokeMissingCogsApi,
          cogsDetailsDataFetcher,
        }}
        filtersContainerClass="transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:right-0"
        columns={getVisibleColumns(isAIPlan)}
        tableId={TABLE_ID}
        currencyCode={currencyCode || CURRENCY_CODE}
        dataFetcher={dataFetcher}
        footerTotalItemsI18nKey={
          I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_FOOTER_TOTAL_ITEMS_TEXT
        }
        hasStickyHeader
        hasStickyLeftColumn
        dataFields={dataFields}
        actionRowRightSideComponents={tableActionRowRightSideElements}
        errorComponent={<ErrorTable />}
        yesFilterNoDataDisplayComponent={<NoFilteredDataFoundTable />}
        noFilterNoDataDisplayComponent={<NoDataFoundTable />}
        inputSearchColumnName={CAMPAIGN_TABLE_SEARCH_KEY}
        onFilterUpdate={onFilterUpdate}
        filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
        filtersFromBrowserStorage={filtersFromBrowserStorage}
        storeFiltersInBrowserStorage={storeFiltersInBrowserStorage}
        filterDateFormat={REQUEST_DATE_FORMAT}
        searchInputSize={SearchInputSize.Medium}
        hasSearchInputWithButton
        searchInputPlaceholder={getSearchInputPlaceholder(
          selectedAdType,
          adLevelDropDownSelectedValues as AdLevel,
          intl
        )}
        selectedColumns={selectedColumns}
        columnManagerOptions={columnManagerOptions}
        onColumnSelectionChange={onColumnSelectionChange}
        dataTestId="table_campaigns"
        showTableActionRow2={true}
        actionRowV2Props={{
          loadingText: intl.formatMessage({
            id: I18nKey.ADVERTISING_OPTIMIZATION_TABLE_ACTION_ROW_LOADING_TEXT,
          }),
          isLoading: isSaveChangesLoading,
          isActive: selectedRowIds.length > 0,
          selectedCount: selectedRowIds.length,
          totalCount: campaignTableData.length,
          showEditButton:
            tableMode === DualModes.Edit && selectedRowIds.length > 0,
          sliderMode: tableMode,
          onSliderModeChange: handleOperationsAsPerTableMode,
          showSelectedCount: tableMode === DualModes.Edit,
          className: 'border border-grey-200 rounded-t-sm',
          onEditClick: () => setBulkEditModalOpen(true),
          ...getActionRowButtons(
            tableMode,
            intl,
            getSaveButtonState(),
            onActionClick
          ),
          viewOnlyMode: !!isUserRoleViewOnly,
          showSlider:
            selectedAdType !== AdType.SponsoredVideos ||
            (selectedAdType === AdType.SponsoredVideos &&
              optimizelyContext.featureFlags[
                OptimizelyFlags.SponsoredVideosEditable
              ]),
        }}
        openFilterMenuOnPillClick={() => setFilterMenuOpen(true)}
        callBackOnFilterUpdate={loadQuickFilterData}
      />
    </>
  );
};
TableCampaigns.displayName = 'TableCampaigns';
