import {
  BulkEditValues,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CheckmarkIcon,
  CrossExtraSmallIcon,
  LinkBrokenIcon,
  TableActionRowV2,
  TableActionRowV2Props,
  TableEmptyStateProps,
  ToastVariant,
  Type,
} from '@teikametrics/tm-design-system';
import { AxiosResponse } from 'axios';
import capitalize from 'lodash/capitalize';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ViewSkuAndKeywordSlideOutV2 } from '../../../../components/ViewSkuAndKeywordSlideoutV2';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../../containers/notificationProvider';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';
import {
  tableActions,
  tableSelectors,
  tableThunks,
} from '../../../../containers/tableV2/ducks';
import {
  TableDisplayState,
  WithTable,
} from '../../../../containers/tableV2/ducks/types';
import { FlywheelTableV2 } from '../../../../containers/tableV2/flywheelTable';
import { getCurrentAccountFromContext } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { LevelType } from '../../../../core/campaignFlexibility/types';
import { createAOApiClient } from '../../../../lib/clients/AOApiClient';
import { HttpCode } from '../../../../lib/clients/utils';
import {
  AdType,
  FlywheelSalesChannel,
  MatchType,
} from '../../../../lib/types/AOSharedTypes';
import { MerchantCountry } from '../../../../lib/types/Fam';
import { Filter } from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  ActionType,
  CampaignInfo,
  DropDownType,
  KeywordActionColumns,
  KeywordActionSubmissionResponse,
  KeywordActionSubmissionResponseStatus,
  KeywordAdgroupInfo,
  KeywordRecommendationsData,
  RecommendationsAction,
  SelectedRecommendationsV2,
  TargetingType,
} from '../../../../lib/types/KeywordAction';
import { OptimizelyFlags } from '../../../../lib/types/OptimizelyFlags';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../lib/types/SalesChannels';
import { CurrencyCode } from '../../../../lib/utilities/currency';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../lib/utilities/intercom';
import { ViewTrends } from '../../../advertisingOptimization/components/ViewTrends';
import { ViewTrendsContext } from '../../../advertisingOptimization/components/ViewTrends/ViewTrendsProvider';
import { SelectedTrendsProduct } from '../../../products/containers/skuCatalog';
import { PerformanceSlideover } from '../../../products/containers/skuCatalog/performanceSlideover';
import { PerformanceSlideoverContextProvider } from '../../../products/containers/skuCatalog/performanceSlideover/performanceSlideoverContextProvider';
import {
  getKeywordsToAddFiltersFromLocalStorage,
  setKeywordsToAddFiltersInLocalStorage,
} from '../../../products/containers/utils/localStorage';
import {
  getUpdatedAdgroupsFromLocalStorage,
  storeSelectedColumnsRecommendationsToLocalStorage,
  storeUpdatedAdgroupsToLocalStorage,
} from '../../storageUtils';
import { DEFAULT_CURRENCY } from '../../utils';
import { RecommendationsCampaignType } from '../KeywordActionV4';
import {
  KeywordTableStateIndicator,
  KeywordTableStateIndicatorType,
} from '../KeywordTableStateIndicator';
import { RecommendationsCountContext } from '../RecommendationsCountProvider';
import { rowsPerPageOptions } from './utils';
import { ActionModal } from './actionModal';
import { RecommendationTableBulkEditModal } from './bulkEditModal';
import { getColumnManagerOptionsRecommendationsTableV2 } from './columnManagerConfig';
import { getKWAColumnsDefinition } from './columns';
import { getKWRFilterColumnDefinitionsV2 } from './columns/utils';
import {
  ActionRowDataProps,
  KEYWORD_RECOMMENDATIONS_TABLE_V3_ID,
  KEYWORD_RECOMMENDATIONS_TABLE_V3_ID_BY_CHANNEL,
  RecommendationsTableV2ExtraProps,
} from './types';
import {
  MAX_ROWS,
  OVERSCAN_ROWS,
  dataFetcherRecommendationsTableV2,
  getRecommendationsDefaultSelectedOptionsV2,
  markAllAdgroupsUnchecked,
  submitRecommendations,
} from './utils';
import { KeywordLevel } from '../KeywordActionV4';

export interface RecommendationsTableV3Props {
  readonly merchantCountry: MerchantCountry;
  readonly hidePerformanceColumn?: boolean;
  readonly merchantCountryId?: string;
  readonly keywordLevel: KeywordLevel;
  readonly advertisingType: AdType;
}

const getSelectedKeywords = (selectedRows: SelectedRecommendationsV2) => {
  return Object.keys(selectedRows).reduce((acc, item) => {
    if (selectedRows[item]['isChecked']) {
      acc[item] = selectedRows[item];
    }
    return acc;
  }, {} as SelectedRecommendationsV2);
};

const getEditActions = (
  selectedRows: SelectedRecommendationsV2,
  alertDialogMode: ActionType | undefined,
  merchantCountry: MerchantCountry,
  isPat?: boolean
) => {
  const actions: RecommendationsAction[] = Object.keys(
    getSelectedKeywords(selectedRows)
  ).map((item) => {
    const keywordAction = selectedRows[item];
    return {
      recommendationId: keywordAction.recommendationId,
      adGroupId: keywordAction.adGroup?.id!,
      campaignId: keywordAction.campaign?.id!,
      matchType: isPat
        ? keywordAction.matchType
        : capitalize(keywordAction.matchType),
      action: alertDialogMode!,
      merchantCountryId: merchantCountry.merchantCountryId,
      salesChannelId: merchantCountry.salesChannelId!,
      tag: keywordAction.tag,
      merchantType: merchantCountry.merchantType,
    };
  });
  return actions;
};

const noDataState: TableEmptyStateProps = {
  icon: LinkBrokenIcon,
  titleI18nKey: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_NO_DATA_TITLE,
  descriptionI18nKey: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_NO_DATA_DESCRIPTION,
  className: 'pt-140 mb-20',
};

const getTableId = (hidePerformanceColumn: boolean) =>
  hidePerformanceColumn
    ? KEYWORD_RECOMMENDATIONS_TABLE_V3_ID_BY_CHANNEL
    : KEYWORD_RECOMMENDATIONS_TABLE_V3_ID;

const getActionSuccessMessage = (isPat: boolean, actionType?: ActionType) => {
  if (isPat) {
    return actionType === ActionType.Accepted
      ? I18nKey.KEYWORD_RECOMMENDATIONS_ADD_ASINS_SUCCESS
      : I18nKey.KEYWORD_RECOMMENDATIONS_DISMISS_ASINS_SUCCESS;
  }
  return actionType === ActionType.Accepted
    ? I18nKey.KEYWORD_RECOMMENDATIONS_ADD_KEYWORDS_SUCCESS
    : I18nKey.KEYWORD_RECOMMENDATIONS_DISMISS_KEYWORDS_SUCCESS;
};

const getActionRowLoadingText = (
  isPat: boolean,
  alertDialogMode?: ActionType
) => {
  if (isPat) {
    return alertDialogMode === ActionType.Accepted
      ? I18nKey.KEYWORD_RECOMMENDATIONS_ADDING_ASINS
      : I18nKey.KEYWORD_RECOMMENDATIONS_DISMISS_ASINS;
  }

  return alertDialogMode === ActionType.Accepted
    ? I18nKey.KEYWORD_RECOMMENDATIONS_ADDING_KEYWORDS
    : I18nKey.KEYWORD_RECOMMENDATIONS_DISMISS_KEYWORDS;
};

export const RecommendationsTableV3: React.FC<RecommendationsTableV3Props> = ({
  merchantCountry,
  hidePerformanceColumn = false,
  keywordLevel,
  advertisingType,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userContext = useContext<UserContextState>(UserContext);
  const { id: accountId } = getCurrentAccountFromContext(userContext)!;
  const userId = userContext.userInfo.userDetails?.id || '';
  const aoApiClient = createAOApiClient(userContext.userInfo.idToken!);
  const toasts = useContext<NotificationContextState>(NotificationContext);
  const viewTrendsContext = useContext(ViewTrendsContext);

  const isPat = keywordLevel === KeywordLevel.PAT;

  const {
    spKeywordsByChannelCount,
    spKeywordsByPerformanceCount,
    spPatKeywordsToAddCount,
    sbKeywordsByChannelCount,
    sbKeywordsByPerformanceCount,
    sbPatKeywordsToAddCount,
    refreshRecommendationsCount,
  } = useContext(RecommendationsCountContext);

  const [
    SEARCH_PLACEHOLDER,
    DISMISS,
    ADD_KEYWORDS,
    SUBMISSION_FAILURE_DESCRIPTION,
    FAILURE_ERROR_DESCRIPTION,
    SINGLE_SELECT_CHECKBOX_TOOLTIP,
    HEADER_CHECKBOX_TOOLTIP,
  ] = [
    isPat
      ? I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_ASIN_SEARCH_PLACEHOLDER
      : I18nKey.AI_RECOMMENDED_KEYWORD_AD_SALES_SEARCH_PLACEHOLDER,
    I18nKey.RECOMMENDATION_PAGE_AUTOMATED_BIDDING_DISMISS,
    I18nKey.KEYWORD_RECOMMENDATIONS_ADD_KEYWORDS,
    I18nKey.KEYWORD_RECOMMENDATIONS_ACTIONS_SUBMISSION_ERROR_DESCRIPTION,
    I18nKey.KEYWORD_RECOMMENDATIONS_ACTIONS_SUBMISSION_ERROR_VALIDATION_DESCRIPTION,
    isPat
      ? I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_CHECKBOX_SINGLE_SELECT_ASIN_TOOLTIP
      : I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_CHECKBOX_SINGLE_SELECT_TOOLTIP,
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_CHECKBOX_HEADER_TOOLTIP,
  ].map((id) => intl.formatMessage({ id }));

  const [selectedColumns, setSelectedColumns] = useState<
    KeywordActionColumns[]
  >(getRecommendationsDefaultSelectedOptionsV2(userId, accountId, intl));
  const [selectedRows, setSelectedRows] = useState<SelectedRecommendationsV2>(
    markAllAdgroupsUnchecked(
      getUpdatedAdgroupsFromLocalStorage({ userId, accountId })
    ) || {}
  );
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>();
  const [showEditSlideOut, setShowEditSlideOut] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>();
  const [alertDialogMode, setAlertDialogMode] = useState<ActionType>();
  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);
  const keywordActionsColumnEnabled =
    optimizelyContext.featureFlags[OptimizelyFlags.KeywordActionsIndividualRow];

  const updateAdgroupsInLocalStorage = (adGroups: SelectedRecommendationsV2) =>
    storeUpdatedAdgroupsToLocalStorage({
      userId,
      accountId,
      adGroups,
    });

  const onRowsSelection = (
    rowsId: string[],
    checked: boolean,
    original?: Map<string, KeywordRecommendationsData>
  ) => {
    if (!original) return;
    const updatedRowsSelectState = { ...selectedRows };
    rowsId.forEach(
      (id) =>
        (updatedRowsSelectState[id] = {
          recommendationId: id,
          matchType:
            selectedRows[id]?.matchType ||
            original?.get(id)?.keyword?.suggestedMatchType?.toLowerCase(),
          adGroup:
            selectedRows[id]?.adGroup ||
            original?.get(id)?.suggestedDestination.adGroup,
          campaign:
            selectedRows[id]?.campaign ||
            original?.get(id)?.suggestedDestination.adGroup.campaign,
          isChecked: checked,
          tag: selectedRows[id]?.tag || original?.get(id)?.keyword.tag!,
        })
    );
    setSelectedRows(updatedRowsSelectState);
    updateAdgroupsInLocalStorage(updatedRowsSelectState);
  };

  const onPaginationChange = () => {
    const updatedRowsSelectState = Object.keys(selectedRows).reduce(
      (acc: any, rowId: string) => {
        acc[rowId] = { ...selectedRows[rowId], isChecked: false };
        return acc;
      },
      {}
    );
    setSelectedRows(updatedRowsSelectState);
    updateAdgroupsInLocalStorage(updatedRowsSelectState);
  };

  const keywordDisplayState = useSelector<
    WithTable<KeywordRecommendationsData>,
    TableDisplayState
  >(({ tableState }) =>
    tableSelectors.getTableDisplayStateSelector()(
      tableState,
      getTableId(hidePerformanceColumn)
    )
  );

  const spOrSbNoDataCheck =
    advertisingType === AdType.SponsoredProducts
      ? spPatKeywordsToAddCount === 0 &&
        !isPat &&
        ((hidePerformanceColumn && spKeywordsByChannelCount <= 0) ||
          (!hidePerformanceColumn && spKeywordsByPerformanceCount <= 0))
      : sbPatKeywordsToAddCount === 0 &&
        !isPat &&
        ((hidePerformanceColumn && sbKeywordsByChannelCount <= 0) ||
          (!hidePerformanceColumn && sbKeywordsByPerformanceCount <= 0));

  const keywordsShowNoData =
    keywordDisplayState !== TableDisplayState.DataLoadNotStarted &&
    keywordDisplayState !== TableDisplayState.LoadingInitialData &&
    keywordDisplayState !== TableDisplayState.Error &&
    isPat &&
    spOrSbNoDataCheck;

  useEffect(() => {
    refreshTable();
  }, [merchantCountry.merchantCountryId]);

  const TABLE_ROW_DATA_FETCHER = dataFetcherRecommendationsTableV2(
    accountId,
    merchantCountry.merchantCountryId,
    DEFAULT_CURRENCY,
    aoApiClient,
    RecommendationsCampaignType.All,
    true,
    hidePerformanceColumn,
    hidePerformanceColumn &&
      (merchantCountry.salesChannelId === AMAZON_SALES_CHANNEL_ID ||
        merchantCountry.salesChannelId === WALMART_SALES_CHANNEL_ID),
    isPat,
    advertisingType
  );

  const refreshTable = () => {
    const dataFetcher = dataFetcherRecommendationsTableV2(
      accountId,
      merchantCountry.merchantCountryId,
      DEFAULT_CURRENCY,
      aoApiClient,
      RecommendationsCampaignType.All,
      true,
      hidePerformanceColumn,
      hidePerformanceColumn &&
        (merchantCountry.salesChannelId === AMAZON_SALES_CHANNEL_ID ||
          merchantCountry.salesChannelId === WALMART_SALES_CHANNEL_ID),
      isPat,
      advertisingType
    );
    dispatch(
      tableThunks.refreshTable(getTableId(hidePerformanceColumn), dataFetcher)
    );
  };

  const onColumnSelectionChange = (selectedValues: string[]) => {
    const newlySelectedColumns = selectedValues as KeywordActionColumns[];
    setSelectedColumns(newlySelectedColumns);
    storeSelectedColumnsRecommendationsToLocalStorage({
      userId,
      accountId,
      values: newlySelectedColumns,
      isV2: true,
    });
  };

  const selectedRowsLength = Object.keys(
    getSelectedKeywords(selectedRows)
  ).length;

  const recommendationTableData = useSelector<
    WithTable<KeywordRecommendationsData>,
    KeywordRecommendationsData[]
  >(({ tableState }) =>
    tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<KeywordRecommendationsData>()(
        tableState,
        getTableId(hidePerformanceColumn)
      )
    )
  );

  const columns = getKWAColumnsDefinition(
    selectedColumns,
    keywordActionsColumnEnabled,
    hidePerformanceColumn,
    isPat
  );

  const onBulkEditClick = () => {
    setShowEditSlideOut(true);
    hideIntercomLauncher();
  };

  const actionRowProps: TableActionRowV2Props = useMemo(() => {
    return {
      isActive: !!selectedRowsLength,
      isLoading: saving,
      loadingText: intl.formatMessage({
        id: getActionRowLoadingText(isPat, alertDialogMode),
      }),
      showEditButton: !!selectedRowsLength,
      onEditClick: onBulkEditClick,
      showCancelButton: false,
      showSelectedCount: true,
      selectedCount: selectedRowsLength,
      totalCount: recommendationTableData.length,
      primaryButtonProps: {
        label: ADD_KEYWORDS,
        svgIcon: CheckmarkIcon,
        variant: ButtonVariant.Primary,
        size: ButtonSize.Large,
        state: !!selectedRowsLength ? ButtonState.Active : ButtonState.Disabled,
        iconClassName: !!selectedRowsLength ? 'text-green-500' : '',
        onClick: () => {
          setAlertDialogMode(ActionType.Accepted);
          onApplyClick(ActionType.Accepted);
        },
      },
      secondaryButtonProps: {
        label: DISMISS,
        svgIcon: CrossExtraSmallIcon,
        variant: ButtonVariant.BlackAndWhiteBorder,
        size: ButtonSize.Large,
        state: !!selectedRowsLength ? ButtonState.Active : ButtonState.Disabled,
        onClick: () => {
          setAlertDialogMode(ActionType.Rejected);
          setShowAlertDialog(true);
        },
        iconClassName: 'text-orange-500',
        className: 'border border-grey-300 border-solid rounded-4',
      },
      className: 'border border-t-0 sticky top-0 z-5',
      roundedTop: true,
      overrideActionButtonPaddingClassName: 'gap-8',
    };
  }, [
    selectedRowsLength,
    recommendationTableData,
    saving,
    alertDialogMode,
    onBulkEditClick,
  ]);

  // Handle dropdown select changes
  const handleDropdownSelection = (
    row: KeywordRecommendationsData,
    option: DropDownType,
    value?: MatchType | CampaignInfo | KeywordAdgroupInfo
  ) => {
    const initialRow = {
      recommendationId: row.id,
      matchType: row?.keyword.suggestedMatchType?.toLowerCase(),
      campaign: row.suggestedDestination?.adGroup.campaign,
      adGroup: row.suggestedDestination?.adGroup,
      isChecked: false,
      tag: row.keyword.tag,
    };

    const prevRow = selectedRows[row.id];

    selectedRows[row.id] = {
      ...initialRow,
      ...prevRow,
      [option]: value,
    };
    setSelectedRows({
      ...selectedRows,
    });
    updateAdgroupsInLocalStorage({
      ...selectedRows,
    });
  };

  const onSubmitSuccess = (
    resp: AxiosResponse<KeywordActionSubmissionResponse, any>,
    rowIds: string[],
    actionType?: ActionType
  ) => {
    const relevantMode = actionType ? actionType : alertDialogMode;

    if (
      resp.data.status === KeywordActionSubmissionResponseStatus.Success ||
      resp.status === HttpCode.ACCEPTED
    ) {
      toasts.addNotification({
        headline: intl.formatMessage(
          {
            id: getActionSuccessMessage(isPat, relevantMode),
          },
          {
            count: rowIds.length,
          }
        ),
        description: '',
        onClose: noop,
        type: Type.Success,
        variant: ToastVariant.Simple,
      });
      setSelectedRows(() => ({}));
      updateAdgroupsInLocalStorage({});
      if (relevantMode && keywordActionsColumnEnabled) {
        updateAndDeleteProcessedIds(rowIds, relevantMode);
      } else {
        refreshTable();
      }
      refreshRecommendationsCount();
    } else if (
      resp.data.status === KeywordActionSubmissionResponseStatus.Error
    ) {
      toasts.addNotification({
        headline: FAILURE_ERROR_DESCRIPTION,
        description: '',
        onClose: noop,
        type: Type.Attention,
        variant: ToastVariant.Simple,
      });
      refreshTable();
    }
  };

  const onSubmitFailure = () => {
    toasts.addNotification({
      headline: SUBMISSION_FAILURE_DESCRIPTION,
      description: '',
      onClose: noop,
      type: Type.Attention,
      variant: ToastVariant.Simple,
    });
  };

  const onApplyClick = async (actionType?: ActionType) => {
    setShowAlertDialog(false);
    setSaving(true);
    const requiredActionType =
      typeof actionType === 'string' ? actionType : alertDialogMode;

    if (requiredActionType && keywordActionsColumnEnabled) {
      updateProcessingIds(Object.keys(selectedRows), requiredActionType);
    }
    const actions = getEditActions(
      selectedRows,
      requiredActionType,
      merchantCountry,
      isPat
    );

    await submitRecommendations(
      onSubmitSuccess,
      onSubmitFailure,
      aoApiClient,
      accountId,
      actions,
      setSaving,
      actionType,
      isPat
    );
  };

  const onBulkEditSlideOutClose = () => {
    setShowEditSlideOut(false);
    showIntercomLauncher();
  };

  const onBulkEditSave = (changes: BulkEditValues) => {
    const newRows = Object.keys(selectedRows)
      .filter((item) => selectedRows[item]['isChecked'])
      .reduce((acc, item) => {
        acc[item] = {
          ...selectedRows[item],
          ...changes,
        };
        return acc;
      }, {} as SelectedRecommendationsV2);
    setSelectedRows(newRows);
    updateAdgroupsInLocalStorage(newRows);
  };

  const [actionRowDataProps, setActionRowDataProps] =
    useState<ActionRowDataProps>({
      dismissedRows: [],
      dismissProcessingRows: [],
      acceptedRows: [],
      acceptProcessingRows: [],
    });

  const dispatchDeleteRecords = (rowIds: string[]) => {
    dispatch(
      tableActions.deleteRecordsInTable({
        tableId: getTableId(hidePerformanceColumn),
        itemIdentifier: (item: KeywordRecommendationsData) => {
          return rowIds.includes(item.id);
        },
      })
    );
  };

  const updateProcessingIds = (rowIds: string[], action: ActionType) => {
    const updatedActionData: ActionRowDataProps = {
      ...actionRowDataProps,
      dismissProcessingRows:
        action === ActionType.Rejected
          ? [...actionRowDataProps.dismissProcessingRows, ...rowIds]
          : actionRowDataProps.dismissProcessingRows,
      acceptProcessingRows:
        action === ActionType.Accepted
          ? [...actionRowDataProps.acceptProcessingRows, ...rowIds]
          : actionRowDataProps.acceptProcessingRows,
    };
    setActionRowDataProps(updatedActionData);
  };

  const updateAndDeleteProcessedIds = (
    rowIds: string[],
    action: ActionType
  ) => {
    const updatedActionData: ActionRowDataProps = {
      ...actionRowDataProps,
      dismissedRows:
        action === ActionType.Rejected
          ? [...actionRowDataProps.dismissedRows, ...rowIds]
          : actionRowDataProps.dismissedRows,
      acceptedRows:
        action === ActionType.Accepted
          ? [...actionRowDataProps.acceptedRows, ...rowIds]
          : actionRowDataProps.acceptedRows,
    };
    setActionRowDataProps(updatedActionData);
    dispatchDeleteRecords(rowIds);
  };

  const onActionRowClick = (
    rowIds: string[],
    actionType: ActionType,
    original: KeywordRecommendationsData
  ) => {
    updateProcessingIds(rowIds, actionType);
    const selectedRowInfo = selectedRows[original.id];
    const actions = getEditActions(
      {
        [original.id]: {
          recommendationId: original.id,
          matchType: selectedRowInfo
            ? selectedRowInfo.matchType
            : (original.keyword.suggestedMatchType?.toLowerCase() as MatchType),
          adGroup: selectedRowInfo
            ? selectedRowInfo.adGroup
            : original.suggestedDestination.adGroup,
          campaign: selectedRowInfo
            ? selectedRowInfo.campaign
            : original.suggestedDestination.adGroup.campaign,
          isChecked: true,
          tag: selectedRowInfo ? selectedRowInfo.tag : original.keyword.tag!,
        },
      },
      actionType,
      merchantCountry,
      isPat
    );
    submitRecommendations(
      onSubmitSuccess,
      onSubmitFailure,
      aoApiClient,
      accountId,
      actions,
      setSaving,
      actionType,
      isPat
    );
  };

  const rowIdsTobeProcessedAndDisabled = [
    ...actionRowDataProps.acceptProcessingRows,
    ...actionRowDataProps.acceptedRows,
    ...actionRowDataProps.dismissProcessingRows,
    ...actionRowDataProps.dismissedRows,
  ];

  const storeFiltersInLocalStorage = (filters: Filter[]) => {
    setKeywordsToAddFiltersInLocalStorage(
      {
        accountId,
        userId: userContext.userInfo.userDetails?.id!,
      },
      filters
    );
  };

  const filtersFromBrowserStorage = getKeywordsToAddFiltersFromLocalStorage({
    accountId,
    userId: userContext.userInfo.userDetails?.id!,
  });

  const [showTrendsSlideover, setShowTrendsSlideover] =
    useState<boolean>(false);

  const [selectedTrendsProduct, setSelectedTrendsProduct] =
    useState<SelectedTrendsProduct>();

  const onViewTrendsSlideoverClose = () => {
    setShowTrendsSlideover(false);
    setSelectedTrendsProduct(undefined);
  };

  const onViewTrendsSlideoverClick = (product: SelectedTrendsProduct) => {
    setSelectedTrendsProduct(product);
    setShowTrendsSlideover(true);
    if (viewTrendsContext.closeViewTrends) {
      viewTrendsContext.closeViewTrends();
    }
  };

  const onTextLinkClick = () =>
    viewTrendsContext.onTextLinkClick &&
    viewTrendsContext.onTextLinkClick(
      merchantCountry.salesChannelId === AMAZON_SALES_CHANNEL_ID
        ? FlywheelSalesChannel.Amazon
        : FlywheelSalesChannel.Walmart,
      viewTrendsContext.adType as AdType,
      LevelType.Keywords
    )();

  const onSkuSlideoverClose = () => {
    viewTrendsContext?.toggleSkuSlideover?.();
  };

  const closeActionModal = () => {
    setShowAlertDialog(false);
  };

  const onFilterUpdate = (filters: Filter[]) => {
    storeFiltersInLocalStorage(filters);
  };

  const additionalActionRow = () => {
    return <TableActionRowV2 {...actionRowProps} />;
  };

  if (keywordsShowNoData) {
    return (
      <div className="flex items-center justify-center h-keywordActionsTable w-keywordActionsTableWidth">
        <KeywordTableStateIndicator
          tableStateType={KeywordTableStateIndicatorType.KeywordNoData}
          isPat={isPat}
        />
      </div>
    );
  }

  return (
    <div className="w-keywordActionsTableWidth">
      <FlywheelTableV2<
        KeywordRecommendationsData,
        {},
        RecommendationsTableV2ExtraProps,
        {}
      >
        allowStickyHeader
        hideColumnManagerText
        additionalActionRow={additionalActionRow}
        tableId={getTableId(hidePerformanceColumn)}
        tableData={{
          handleDropdownSelection,
          selectedRows,
          actionRowDataProps,
          onActionRowClick,
          merchantCountry,
          isPat,
        }}
        virtualizationProps={{
          estimateSize: MAX_ROWS,
          overscan: OVERSCAN_ROWS,
        }}
        currencyCode={DEFAULT_CURRENCY}
        dataFetcher={TABLE_ROW_DATA_FETCHER}
        columns={columns}
        selectedColumns={selectedColumns}
        noDataState={noDataState}
        hasSearchInput
        dataFields={getKWRFilterColumnDefinitionsV2(DEFAULT_CURRENCY, isPat)}
        onColumnSelectionChange={onColumnSelectionChange}
        columnManagerOptions={
          hidePerformanceColumn
            ? undefined
            : getColumnManagerOptionsRecommendationsTableV2(intl, true)
        }
        searchInputClassName="w-auto"
        searchInputPlaceholder={SEARCH_PLACEHOLDER}
        inputSearchColumnName={KeywordActionColumns.KEYWORD}
        hasSearchInputWithButton
        showBulkSelection
        rowIdKeyName="id"
        rowIdsSelected={Object.entries(selectedRows)
          .filter(([key, { isChecked }]) => isChecked)
          .map(([key]) => key)}
        filtersFromBrowserStorage={filtersFromBrowserStorage}
        onFilterUpdate={onFilterUpdate}
        filterFieldMapper={[
          {
            alias: 'targetText',
            field: 'targetText',
          },
        ]}
        filtersContainerClass="left-0"
        storeFiltersInBrowserStorage={storeFiltersInLocalStorage}
        filtersMenuTooltipClass="right-0"
        columnManagerTooltipClass="right-0"
        loadingAndEmptyStateClassName="pt-140 mb-20"
        tableClassName="border-solid border-b-0 min-h-320"
        tableEmptyStateClass="w-keywordActionsTableWidth"
        onRowsSelection={onRowsSelection}
        onPaginationChange={onPaginationChange}
        rowsPerPageOptions={rowsPerPageOptions}
        headerClass="-mt-4 "
        rowCheckboxClassName="-mt-4"
        roundedTop
        bulkSelectCellTooltip={SINGLE_SELECT_CHECKBOX_TOOLTIP}
        bulkSelectHeaderTooltip={HEADER_CHECKBOX_TOOLTIP}
        rowIdsProcessing={rowIdsTobeProcessedAndDisabled}
        rowIdsDisabled={rowIdsTobeProcessedAndDisabled}
        actionRowFullWidthElementIndex={1}
        customStickyHeaderClassName="top-48"
        minColumnSize={100}
      />
      {showAlertDialog && alertDialogMode && (
        <ActionModal
          keywordLevel={keywordLevel}
          selectedCount={selectedRowsLength}
          actionType={alertDialogMode}
          onApplyClick={onApplyClick}
          onCancelClick={closeActionModal}
        />
      )}
      {showEditSlideOut && (
        <RecommendationTableBulkEditModal
          onClose={onBulkEditSlideOutClose}
          onSubmit={onBulkEditSave}
          isPat={isPat}
          merchantCountryId={merchantCountry.merchantCountryId}
        />
      )}
      {viewTrendsContext.isViewTrendsOpen && (
        <ViewTrends
          selectedDateRange={{
            initialEndDate: DateTime.now().minus({ days: 1 }),
            initialStartDate: DateTime.now().minus({ days: 8 }),
            minDate: DateTime.now().minus({ days: 99999 }),
            maxDate: DateTime.now().minus({ days: 1 }),
          }}
          adType={viewTrendsContext.adType as AdType}
          aoApiClient={aoApiClient}
          salesChannelId={viewTrendsContext.channelId}
        />
      )}
      {viewTrendsContext.isSKUSlideoverOpen &&
        viewTrendsContext.isViewTrendsOpen && (
          <ViewSkuAndKeywordSlideOutV2
            adGroupId={viewTrendsContext.entityId as string}
            isOpen={viewTrendsContext.isSKUSlideoverOpen as boolean}
            merchantCountry={merchantCountry}
            onClose={onSkuSlideoverClose}
            adTypeDropDownValue={viewTrendsContext.adType as AdType}
            onTextLinkClick={onTextLinkClick}
            suggestedAdgroup={{
              id: viewTrendsContext.entityId as string,
              campaign: {
                campaignType: viewTrendsContext.adType as AdType,
                id: viewTrendsContext.campaignId as string,
                name: viewTrendsContext.campaignName as string,
                targetingType: String(
                  viewTrendsContext.targetingType
                ) as TargetingType,
              },
              name: viewTrendsContext.entityName,
              targetSegments: viewTrendsContext.targetSegments,
            }}
            selectedSalesChannelName={
              viewTrendsContext.channelId === AMAZON_SALES_CHANNEL_ID
                ? FlywheelSalesChannel.Amazon
                : FlywheelSalesChannel.Walmart
            }
            onSKUTrendsLinkClick={onViewTrendsSlideoverClick}
            textLinkContent={intl.formatMessage({
              id: I18nKey.SKU_CATALOG_RELATED_FLYOVER_FOOTER_PRIMARY_BUTTON_TEXT,
            })}
          />
        )}

      {showTrendsSlideover && selectedTrendsProduct && (
        <PerformanceSlideoverContextProvider
          currency={CurrencyCode.USD}
          defaultDateRange={{
            startDate: DateTime.now().minus({ days: 7 }),
            endDate: DateTime.now().minus({ days: 1 }),
          }}
          {...selectedTrendsProduct}
        >
          <PerformanceSlideover onClose={onViewTrendsSlideoverClose} />
        </PerformanceSlideoverContextProvider>
      )}
    </div>
  );
};
