import { SelectMetricsType } from '../../../../../lib/types/SKUSharedTypes';
import { ThresholdOptions } from '../components/createQuery/createInsights.types';
import {
  getColumnsForCreateCustomInsightsRateOfSaleFromSessionStorage,
  getCustomInsightsRateOfSaleColumnManagerConfigFromLocalStorage,
  hasKeyCreateCustomInsightsRateOfSaleColumnManagerConfigFromSessionStorage,
  hasKeyCustomInsightsRateOfSaleColumnManagerConfigFromLocalStorage,
} from './storageUtils';
import {
  CustomInsightMirrorColumns,
  CustomInsightProductColumns,
  LOCAL_STORAGE_KEY_FOR_UPDATED_AT_DATE,
  MIRROR_COLUMNS,
} from './types';
import { Fw2LocalStorage } from '../../../../../lib/utilities/fw2LocalStorage';

export const getMetadataCriteriaByThresholdSettings = (
  thresholdOption?: string,
  value1?: string,
  value2?: string
) =>
  thresholdOption && value1
    ? [
        {
          operand: 'units_sold',
          operator: thresholdOption,
          value:
            thresholdOption === ThresholdOptions.BETWEEN
              ? `${value1},${value2}`
              : value1,
          group: 1,
        },
      ]
    : [];

export const getDefaultSelectedColumns = (
  insightTypeGroup: SelectMetricsType,
  userId: string,
  accountId: string
) => {
  if (insightTypeGroup === SelectMetricsType.RATE_OF_SALE) {
    const columnsFromLocalStorage =
      getCustomInsightsRateOfSaleColumnManagerConfigFromLocalStorage({
        userId,
        accountId,
      });
    if (
      hasKeyCustomInsightsRateOfSaleColumnManagerConfigFromLocalStorage({
        userId,
        accountId,
      })
    ) {
      return columnsFromLocalStorage;
    } else {
      const defaultRateOfSaleColumns = [
        CustomInsightProductColumns.TotalSales,
        CustomInsightProductColumns.UnitsSold,
      ];
      return defaultRateOfSaleColumns;
    }
  } else if (
    insightTypeGroup === SelectMetricsType.REORDER ||
    insightTypeGroup === SelectMetricsType.STOCK_OUTS
  ) {
    return [
      CustomInsightProductColumns.ProjectedDaysToStockout,
      CustomInsightProductColumns.CurrentInventoryLevel,
      CustomInsightProductColumns.ProjectedDaysToStockout,
      CustomInsightProductColumns.ProjectedStockoutDate,
      CustomInsightProductColumns.RateOfSale,
      CustomInsightProductColumns.TotalSales,
      CustomInsightProductColumns.UnitsSold,
    ];
  }
  return [];
};

export const getCreateInsightsDefaultSelectedColumns = (
  insightTypeGroup: SelectMetricsType,
  userId: string,
  accountId: string,
  isChangeInUnitsSoldInCriteria: boolean
) => {
  if (insightTypeGroup === SelectMetricsType.RATE_OF_SALE) {
    const columnsFromSessionStorage =
      getColumnsForCreateCustomInsightsRateOfSaleFromSessionStorage({
        userId,
        accountId,
      });
    if (
      hasKeyCreateCustomInsightsRateOfSaleColumnManagerConfigFromSessionStorage(
        {
          userId,
          accountId,
        }
      )
    ) {
      return [
        ...columnsFromSessionStorage,
        ...(isChangeInUnitsSoldInCriteria &&
        !columnsFromSessionStorage.includes(
          CustomInsightProductColumns.UnitsSold
        )
          ? [CustomInsightProductColumns.UnitsSold]
          : []),
      ];
    } else {
      const defaultRateOfSaleColumns = isChangeInUnitsSoldInCriteria
        ? [
            CustomInsightProductColumns.TotalSales,
            CustomInsightProductColumns.UnitsSold,
          ]
        : [CustomInsightProductColumns.TotalSales];
      return defaultRateOfSaleColumns;
    }
  } else if (
    insightTypeGroup === SelectMetricsType.REORDER ||
    insightTypeGroup === SelectMetricsType.STOCK_OUTS
  ) {
    return [
      CustomInsightProductColumns.ProjectedDaysToStockout,
      CustomInsightProductColumns.CurrentInventoryLevel,
      CustomInsightProductColumns.ProjectedDaysToStockout,
      CustomInsightProductColumns.ProjectedStockoutDate,
      CustomInsightProductColumns.RateOfSale,
      CustomInsightProductColumns.TotalSales,
      CustomInsightProductColumns.UnitsSold,
    ];
  }
  return [];
};

export const getSelectedCounterPartColumns = (selectedColumns: string[]) => {
  let duplicatedColumns: string[] = [];
  MIRROR_COLUMNS.forEach((column) => {
    const mirrorColumn = CustomInsightMirrorColumns[column];
    if (selectedColumns.includes(column) && mirrorColumn) {
      duplicatedColumns.push(mirrorColumn);
    }
  });

  return duplicatedColumns;
};
export const setUpdatedAtDateInLocalStorage = (date: string) =>
  Fw2LocalStorage.setItem(LOCAL_STORAGE_KEY_FOR_UPDATED_AT_DATE, date);

export const getUpdatedAtDateInLocalStorage = () =>
  Fw2LocalStorage.getItem(LOCAL_STORAGE_KEY_FOR_UPDATED_AT_DATE);
