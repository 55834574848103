import compact from 'lodash/compact';
import { DateTime } from 'luxon';
import {
  KnockNotification,
  Notification,
  NotificationStatus,
  NotificationType,
} from './types';
import {
  NotificationAlert,
  NotificationChannel,
  NotificationSettingsResponse,
} from '../../lib/types/Fam';
import { CustomInsightSubscriptionDetails } from '../../lib/types/SKUSharedTypes';
import { IntlShape } from 'react-intl';

export const getDateLabel = (
  date: string
): { diff: number; unit: string; group: string } => {
  const today = DateTime.now();

  const diffMinutes =
    today.diff(DateTime.fromISO(date), 'minutes').toObject().minutes || 0;
  const diffHours =
    today.diff(DateTime.fromISO(date), 'hours').toObject().hours || 0;
  const diffDays =
    today.diff(DateTime.fromISO(date), 'days').toObject().days || 0;
  const diffWeeks =
    today.diff(DateTime.fromISO(date), 'weeks').toObject().weeks || 0;

  // More than two weeks
  if (diffHours > 336) {
    return {
      diff: Math.ceil(diffWeeks as number),
      unit: 'w',
      group: 'older',
    };
  }

  // More than one week
  if (diffHours > 48) {
    return {
      diff: Math.ceil(diffDays as number),
      unit: 'd',
      group: diffHours > 168 ? 'lastWeek' : 'thisWeek',
    };
  }

  if (diffHours > 24) {
    return {
      diff: Math.ceil(diffDays as number),
      unit: 'd',
      group: 'yesterday',
    };
  }

  if (diffHours > 1) {
    return {
      diff: Math.ceil(diffHours as number),
      unit: 'h',
      group: 'today',
    };
  }

  return {
    diff: Math.ceil(diffMinutes as number),
    unit: 'm',
    group: 'today',
  };
};

export const parseKnockNotifications = (
  knockNotifications: KnockNotification[]
): Notification[] => {
  return compact(
    knockNotifications.map((knockNotification) => {
      const { id, inserted_at, read_at, blocks } = knockNotification;
      const messageBlock = blocks.find((block) => block.name === 'body');
      const actionBlock = blocks.find((block) => block.name === 'action_url');

      if (!messageBlock?.rendered) {
        return null;
      }

      return {
        id,
        message: messageBlock.rendered,
        action:
          actionBlock?.rendered && actionBlock?.url
            ? {
                url: actionBlock.url,
                rendered: actionBlock.rendered,
              }
            : undefined,
        type: NotificationType.Ads,
        status: NotificationStatus.Success,
        date: inserted_at,
        isRead: Boolean(read_at),
      };
    })
  );
};

export const mergeNotificationSettingsAndIoSubscriptionSettings = (
  intl: IntlShape,
  notificationSettings: NotificationSettingsResponse,
  ioData: CustomInsightSubscriptionDetails[]
): NotificationSettingsResponse => {
  // Iterate and update this for the final IO merged subscriptions
  const updatedNotificationSettings: NotificationSettingsResponse = {
    enabled: notificationSettings.enabled,
    alerts: [],
  };

  const uniqueIoAlertKeys = ioData.map(
    (ioSubscriptionData) => ioSubscriptionData.id
  );

  // This MAP is used to easily fetch it after comparison with FAM data.
  const mapIoAlertKeysToDisplayNames: { [x: string]: NotificationAlert } = {};
  ioData.forEach((ioSubscriptionData) => {
    mapIoAlertKeysToDisplayNames[ioSubscriptionData.id] = {
      key: ioSubscriptionData.id,
      displayName: ioSubscriptionData.customInsightName,
      visible: true,
      enabled: true,
      channel: NotificationChannel.Email,
      isIoModule: true,
      description: ioSubscriptionData.description,
    };
  });

  notificationSettings.alerts.forEach((famSubscriptions: NotificationAlert) => {
    if (uniqueIoAlertKeys.includes(famSubscriptions.key)) {
      // If a key matche's the key from IO API then update the display name accordingly.
      // Also delete the key from the map.
      // Why delete ? So that the remaining keys can be added into the list at the end.
      const ioSpecData = mapIoAlertKeysToDisplayNames[famSubscriptions.key];
      delete mapIoAlertKeysToDisplayNames[famSubscriptions.key];
      updatedNotificationSettings.alerts.push({
        ...famSubscriptions,
        displayName: ioSpecData.displayName,
        isIoModule: true,
        description: ioSpecData.description,
      });
    } else {
      updatedNotificationSettings.alerts.push({
        ...famSubscriptions,
      });
    }
  });

  // Any other left out keys in the IO data api push it into the settings.
  updatedNotificationSettings.alerts.push(
    ...Object.values(mapIoAlertKeysToDisplayNames)
  );
  return updatedNotificationSettings;
};
