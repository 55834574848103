import { SvgIcon } from '@teikametrics/tm-design-system';
import { AllSalesChannel, SalesChannelData } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';

export const SALES_CHANNELS_TABLE = 'SALES_CHANNELS_TABLE';

export interface SalesChannelTableData {
  dataTestId?: string;
  isAmazon?: boolean;
  aoSubscribed?: boolean;
  isUserRoleViewOnly?: boolean;
}

export type RowCellElementProps = SalesChannelData & SalesChannelTableData;

export interface StatusData {
  readonly text: I18nKey;
  readonly statusIconColor?: string;
  readonly showSecondaryDescription?: boolean;
}

export interface SecondarySyncStatus {
  readonly icon: SvgIcon;
  readonly label: I18nKey;
  readonly value?: string;
  readonly earliestSyncDateValue?: string;
}

export const DATE_FORMAT = 'MMM dd, yyyy HH:mm ZZZZ';

export const PAGE_SIZE = 50;

export interface SalesChannelProps {
  readonly salesChannel: AllSalesChannel;
  readonly showDataTimingAndSyncSlideout?: () => void;
}

export interface HeaderInfo {
  readonly header: I18nKey;
  readonly salesChannelIcon: SvgIcon;
  readonly emptyStateTitleI18nKey: I18nKey;
}
