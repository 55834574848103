import capitalize from 'lodash/capitalize';

import {
  MultiSelectOption,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/flywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  FlywheelSalesChannel,
  ProductAdDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { PRODUCT_ADS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<ProductAdDetails> = (
  productAdDetails
) => {
  return makeTextColumn(
    (data: ProductAdDetails) =>
      data.adItemDetails.campaignStatus
        ? capitalize(data.adItemDetails.campaignStatus)
        : undefined,
    productAdDetails?.pendingFields?.includes(
      PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus
    )
  )(productAdDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignStatusColumn: FlywheelTableColumn<ProductAdDetails> = {
  isSortable: true,
  columnName: PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_STATUS,
  RowCellElement,
  gridColumnWidth: '160px',
};

export const campaignStatusFilter = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
) =>
  createMultiSelectDataFieldFilter(
    PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus,
    I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_STATUS,
    getCampaignStatusOptions(selectedSalesChannel, selectedAdType)
  );

const getCampaignStatusOptions = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
): MultiSelectOption[] => {
  switch (selectedSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      return [
        SelectFilterOption.CampaignStatusPaused,
        SelectFilterOption.CampaignStatusCompleted,
        SelectFilterOption.CampaignStatusRescheduled,
        SelectFilterOption.CampaignStatusLive,
        SelectFilterOption.CampaignStatusProposal,
        SelectFilterOption.CampaignStatusScheduled,
      ];
    case FlywheelSalesChannel.Amazon:
      switch (selectedAdType) {
        case AdType.SponsoredProducts:
        case AdType.SponsoredDisplay:
          return [
            SelectFilterOption.CampaignStatusEnabled,
            SelectFilterOption.CampaignStatusPaused,
            SelectFilterOption.CampaignStatusArchived,
          ];

        default:
          return [];
      }
  }
};
