import { ColumnAlign, ProductSkuChild } from '@teikametrics/tm-design-system';
import React from 'react';
import { FlywheelTableV2ColumnProps } from '../../../containers/tableV2/types';
import { getAmazonItemPageUrl } from '../../../core/campaignFlexibility/utils';
import { MerchantCountryCode } from '../../../lib/types/AOSharedTypes';
import {
  EstPreAdGrossMarginItem,
  EstPreAdGrossMarginTableFields,
} from '../../../lib/types/CampaignFlexibilitySharedTypes';
import { FlywheelSalesChannel, MerchantType } from '../../../lib/types/Fam';
import I18nKey from '../../../lib/types/I18nKey';
import { MAP_SALES_CHANNEL_ID_TO_NAME } from '../../../lib/types/SalesChannels';
import { getWalmartItemPageUrl } from '../../../lib/utilities/skuUtils';
import { TableExtraProps } from '../EstAdGrossMarginTable';

export const Cell: React.FC<EstPreAdGrossMarginItem & TableExtraProps> = ({
  extItemId,
  imageUrl,
  name,
  sku,
  selectedMerchant,
  onViewTrendsSlideoverClick,
}) => {
  const merchantCountry = selectedMerchant?.country || MerchantCountryCode.US;
  const merchantType = selectedMerchant?.merchantType || MerchantType.Seller;
  const salesChannel = selectedMerchant?.salesChannelId
    ? MAP_SALES_CHANNEL_ID_TO_NAME[selectedMerchant?.salesChannelId]
    : FlywheelSalesChannel.Amazon;

  const onAsinClick = () => {
    const url =
      salesChannel === FlywheelSalesChannel.Amazon
        ? getAmazonItemPageUrl(
            (merchantCountry as MerchantCountryCode) ?? MerchantCountryCode.US,
            extItemId
          )
        : getWalmartItemPageUrl(extItemId);
    window.open(url);
  };

  return (
    <ProductSkuChild
      productName={name}
      asin={extItemId}
      onAsinClick={onAsinClick}
      parentAsin={extItemId}
      parentSku={sku}
      productImageUrl={imageUrl}
      sku={sku}
      useSku={merchantType !== MerchantType.Vendor}
      useAsin={salesChannel === FlywheelSalesChannel.Amazon}
      dataTestId="estPreAdGrossMargin_sku"
      showTrendIcon
      onTrendIconClick={() =>
        onViewTrendsSlideoverClick?.({
          sku: sku,
          merchantCountryId: selectedMerchant?.merchantCountryId!,
          salesChannelId: selectedMerchant?.salesChannelId!,
        })
      }
    />
  );
};

Cell.displayName = 'estPreAdGrossMargin_sku';

export const skuColumn: FlywheelTableV2ColumnProps<
  EstPreAdGrossMarginItem,
  {},
  TableExtraProps,
  {}
> = {
  header: I18nKey.CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_TABLE_COLUMN_SKU,
  accessorKey: EstPreAdGrossMarginTableFields.sku,
  size: 402,
  maxSize: 402,
  minSize: 402,
  sortable: false,
  align: ColumnAlign.Left,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell {...original} {...meta} />,
};
