import { IdToken } from '@auth0/auth0-spa-js';
import {
  AxiosResponse,
  CancelTokenSource,
  RawAxiosRequestConfig,
  default as axios,
} from 'axios';
import axiosRetry from 'axios-retry';
import uuid from 'uuid';
import {
  generateSortQueryString,
  getPaginationParamsFromRequest,
} from '../../lib/factories/apiDataFetcherFactory';
import {
  ActiveInventoryData,
  ActiveStockResponse,
  CreateInsightsData,
  CreateInsightsPayload,
  CustomGroupNameUpdateRequest,
  CustomInsightProductsForDashboardRequest,
  CustomInsightSubscriptionDetails,
  CustomInsightsProductsData,
  CustomInsightsProductsDataResponse,
  CustomInsightsProductsRequest,
  DataAvailabilityResponse,
  DemandForecastData,
  DemandForecastRequest,
  EstimatedMissedSalesGraphPointsResponse,
  EventLogConfig,
  EventLogDataRequestBody,
  EventLogExportRequest,
  EventLogRequestFilterKeys,
  ExistingSkuGroupResopnse,
  ExportInsightsPayload,
  InventoryBreakdownValueMetrics,
  InventoryByAgeMetricsNew,
  InventoryDashboardOOSData,
  InventoryDashboardOOSRequest,
  InventoryDashboardRequest,
  InventoryHealthMetricData,
  InventoryOptimizationCountsResponse,
  InventoryValueDetailsResponse,
  ManageCogsSecureUrlResponse,
  MetadataType,
  MissingCogsResponse,
  MultichannelCatalogPerformanceDataRequest,
  MultichannelCatalogResponse,
  OutOfStockProductsData,
  OutOfStockProductsRequest,
  OutOfStockProductsSummaryResponse,
  OutOfStockProductsSummaryResponseV2,
  ProductDetailsSummaryResponse,
  ProductDetailsSummaryResponseV2,
  ProductGroupIdsResponse,
  ProductInsightsRequestBody,
  ProductMetadataUploadResponse,
  ProductMetadataUploadStatusResponse,
  RelatedCampaignAndAdGroupRequest,
  RelatedCampaignAndAdGroupResponse,
  SKUCatalogExportDownloadUrlResponse,
  SKUCatalogExportResponse,
  SKUCatalogExportStatusResponse,
  SKUManageCogsResponse,
  SKUManageCogsStatusResponse,
  SKUPerformanceMetricsRequest,
  SKUPerformanceMetricsResponse,
  SelectMetricsType,
  SkuCatalogCogsTemplateRequest,
  SkuCatalogData,
  SkuCatalogDataV3,
  SkuCatalogDetailsRequest,
  SkuCatalogExportRequest,
  SkuCatalogGmroiRequest,
  SkuCatalogGmroiResponse,
  SkuCatalogSummary,
  SkuCatalogSummaryRequest,
  SkuDetailsEvents,
  SkuDetailsEventsResponse,
  SkuDetailsV2Data,
  SkuDetailsV2Summary,
  SkuEventRequest,
  SkuOverviewData,
  SkuOverviewRequest,
  SkuOverviewSummary,
  SkuOverviewSummaryRequest,
  SkuViewType,
  SlideoutProductDetails,
  SlideoutProductDetailsRequest,
  SlideoutProductGraphDataRequest,
  SlideoutProductsGraphDataResponse,
  TotalActiveInventoryValue,
  UnsoldInventoryDetailsData,
  UnsoldInventoryPreviousSummaryResponse,
  UnsoldInventorySummaryResponse,
} from '../../lib/types/SKUSharedTypes';
import { Sort } from '../../lib/types/Sort';
import { OOSGraphDataAPIResponse } from '../../modules/inventoryOptimization/containers/inventoryDashboard/types';
import {
  MultiChannelAdvertisingPenetrationDataSnapshotRequest,
  MultichannelAdvertisingPenetrationProductDataResponse,
} from '../../modules/products/containers/dashboard/types';
import { CustomInsightProductColumns } from '../../modules/inventoryOptimization/containers/inventoryInsights/customInsightsTable/types';
import { setUpdatedAtDateInLocalStorage } from '../../modules/inventoryOptimization/containers/inventoryInsights/customInsightsTable/utils';
import { SkuDetailsV2DataRequestProps } from '../../modules/products/containers/skuCatalog/skuCatalogDetails2/types';
import {
  UnadvertisedProductsRequest,
  UnadvertisedProductsResponse,
} from '../../modules/recommendations/components/recommendationCards';
import { GMROIReseponse } from '../factories/products/gmroiResponse';
import {
  ExportBusinessMetricsRequest,
  ExportProductMetaDataResponse,
  ExportProductMetaDataStatusResponse,
} from '../types/CompassSharedTypes';
import { AllSalesChannel, MerchantCountry } from '../types/Fam';
import { DEFAULT_RETRY_INTERVAL } from '../types/HttpErrorCodes';
import { getDefaultHeaders } from './';
import {
  FilteredRequest,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResult,
} from './types';
import {
  checkIfCustomInsightRequestEmpty,
  checkMerchantsRequestIsValid,
  getCustomGrouping,
  getMerchantCountriesData,
  removeTeikaGroupNameFilter,
} from './utils';
import { Filter } from '../types/Filter';
import {
  filterEmptyValues,
  filtersToQueryString,
} from '../utilities/buildUrlUtilities';

const SKU_BASE_URL = process.env.REACT_APP_SKU_HOST;
const AO_BASE_URL = process.env.REACT_APP_AO_HOST;

export interface SKUApiClient {
  readonly getDataAvailabilityInfo: (
    accountId: string,
    merchantCountryIds: string[]
  ) => Promise<DataAvailabilityResponse>;

  readonly getSkuCatalogDetails: (
    accountId: string,
    skuCatalogRequest: SkuCatalogDetailsRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<SkuCatalogData>>;

  readonly getSkuCatalogParentChildDataV3: (
    accountId: string,
    skuCatalogRequest: SkuCatalogDetailsRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<SkuCatalogData>>;

  readonly getSkuCatalogDetailsIndividual: (
    accountId: string,
    skuCatalogRequest: SkuCatalogDetailsRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<SkuCatalogData>>;

  readonly getSkuCatalogParentChildData: (
    accountId: string,
    skuCatalogRequest: SkuCatalogDetailsRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<SkuCatalogData>>;

  readonly getSkuCatalogSummary: (
    accountId: string,
    skuCatalogRequest: SkuCatalogSummaryRequest,
    queryParams: Record<string, string>
  ) => (filteredequest: FilteredRequest) => Promise<SkuCatalogSummary>;

  readonly getSkuCatalogIndividualSummary: (
    accountId: string,
    skuCatalogRequest: SkuCatalogSummaryRequest,
    queryParams: Record<string, string>
  ) => (filteredequest: FilteredRequest) => Promise<SkuCatalogSummary>;

  readonly getSkuCatalogParentChildSummary: (
    accountId: string,
    skuCatalogRequest: SkuCatalogSummaryRequest,
    queryParams: Record<string, string>
  ) => (filteredequest: FilteredRequest) => Promise<SkuCatalogSummary>;

  readonly getSkuCatalogCustomGroupingSummary: (
    accountId: string,
    skuCatalogRequest: SkuCatalogSummaryRequest,
    queryParams: Record<string, string>
  ) => (filteredequest: FilteredRequest) => Promise<SkuCatalogSummary>;

  readonly exportSkuCatalog: (
    accountId: string,
    skuCatalogRequest: SkuCatalogExportRequest,
    queryParams: Record<string, string>,
    currentView?: SkuViewType
  ) => Promise<SKUCatalogExportResponse>;

  readonly manageCogsUpload: (
    accountId: string,
    requestId: string,
    fileName: string
  ) => Promise<SKUManageCogsResponse>;

  readonly manageCogsUploadStatus: (
    accountId: string,
    requestId: string
  ) => Promise<SKUManageCogsStatusResponse>;

  readonly exportCogsTemplateV2: (
    accountId: string,
    skuCogsTemplateRequest: SkuCatalogCogsTemplateRequest,
    queryParams?: Record<string, string>
  ) => Promise<AxiosResponse<SKUManageCogsResponse>>;

  readonly exportCogsTemplateV2Status: (
    accountId: string,
    requestId: string
  ) => Promise<SKUManageCogsStatusResponse>;

  readonly getSkuOverviewDetails: (
    accountId: string,
    skuOverviewRequest: SkuOverviewRequest,
    productGroupId: string
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<SkuOverviewData>>;

  readonly getSkuOverviewSummary: (
    accountId: string,
    productGroupId: string,
    skuCatalogRequest: SkuOverviewSummaryRequest,
    queryParams: Record<string, string>
  ) => (filteredequest: FilteredRequest) => Promise<SkuOverviewSummary>;

  readonly getProductDetailsSummary: (
    accountId: string,
    productGroupId: string
  ) => Promise<ProductDetailsSummaryResponse>;

  readonly getProductDetailsSummaryV2: (
    accountId: string,
    productGroupId: string,
    merchantCountryId: string
  ) => Promise<ProductDetailsSummaryResponseV2>;

  readonly getProductGroupIds: (
    accountId: string,
    skus: string[]
  ) => Promise<ProductGroupIdsResponse>;

  readonly manageCogsGetS3SecureUrl: (
    accountId: string,
    fileName: string
  ) => Promise<AxiosResponse<ManageCogsSecureUrlResponse>>;

  readonly manageCogsS3FileUpload: (
    url: string,
    file: File
  ) => Promise<AxiosResponse<void>>;

  readonly getMissingCogs: (
    accountId: string,
    shouldRetry?: () => boolean
  ) => Promise<MissingCogsResponse>;

  readonly exportCompassBusinessPerformanceCsvData: (
    accountId: string,
    requestBody: ExportBusinessMetricsRequest
  ) => Promise<AxiosResponse<Blob>>;

  readonly exportProductMetadataTemplate: (
    requestId: string,
    accountId: string,
    metaDataType: MetadataType,
    merchantCountryIds: string[],
    merchantDetails: MerchantCountry[],
    salesChannels: AllSalesChannel[]
  ) => Promise<ExportProductMetaDataResponse>;

  readonly exportProductMetadataTemplateStatus: (
    accountId: string,
    metaDataType: MetadataType,
    requestId: string
  ) => Promise<ExportProductMetaDataStatusResponse>;

  readonly getProductMetadataTemplateS3DownloadUrl: (
    accountId: string,
    requestId: string
  ) => Promise<{ s3DownloadUrl: string }>;

  readonly downloadS3File: (url: string) => Promise<AxiosResponse<Blob>>;

  readonly productMetadataGetS3SecureUrl: (
    accountId: string,
    metaDataType: MetadataType,
    fileName: string
  ) => Promise<AxiosResponse<ManageCogsSecureUrlResponse>>;

  readonly productMetadataS3FileUpload: (
    url: string,
    file: File
  ) => Promise<AxiosResponse<void>>;

  readonly productMetadataUpload: (
    requestId: string,
    accountId: string,
    fileName: string,
    metaDataType: MetadataType,
    merchantCountryIds: string[],
    merchantDetails: MerchantCountry[]
  ) => Promise<ProductMetadataUploadResponse>;

  readonly productMetadataUploadStatus: (
    accountId: string,
    metaDataType: MetadataType,
    requestId: string
  ) => Promise<ProductMetadataUploadStatusResponse>;

  readonly exportSkuStatus: (
    accountId: string,
    requestId: string,
    useParentChildUrl?: boolean
  ) => Promise<SKUCatalogExportStatusResponse>;
  readonly downloadSkuCatalogExportFile: (
    accountId: string,
    requestId: string,
    useParentChildUrl?: boolean
  ) => Promise<SKUCatalogExportDownloadUrlResponse>;
  readonly getSKUPerformanceMetricsDataPoints: (
    accountId: string,
    productCatalogId: string,
    request: SKUPerformanceMetricsRequest
  ) => Promise<SKUPerformanceMetricsResponse>;

  readonly getSkuCatalogDetailsV2: (
    accountId: string,
    productCatalogId: string,
    skuDetailsV2Request: SkuDetailsV2DataRequestProps
  ) => (
    filteredRequest: FilteredRequest
  ) => Promise<PaginatedResult<SkuDetailsV2Data>>;

  readonly getSkuDetailsV2Summary: (
    accountId: string,
    productCatalogId: string,
    skuDetailsV2Request: SkuDetailsV2DataRequestProps
  ) => (filteredRequest: FilteredRequest) => Promise<SkuDetailsV2Summary>;

  readonly getSkuCatalogGmroi: (
    accountId: string,
    skuCatalogGmroiRequest: SkuCatalogGmroiRequest,
    useDummyResponse?: boolean
  ) => Promise<SkuCatalogGmroiResponse>;

  readonly getProductCatalogEvents: (
    accountId: string,
    productCatalogId: string,
    filters: Filter[],
    body: EventLogDataRequestBody,
    lastEvaluatedKey?: string
  ) => Promise<SkuDetailsEventsResponse>;

  readonly exportProductCatalogEvents: (
    accountId: string,
    productCatalogId: string,
    filters: Filter[],
    request: EventLogExportRequest
  ) => Promise<AxiosResponse<Blob>>;

  readonly createProductCatalogEvent: (
    accountId: string,
    productCatalogId: string,
    eventPayload: SkuEventRequest
  ) => Promise<AxiosResponse<void>>;

  readonly updateProductCatalogEvent: (
    accountId: string,
    productCatalogId: string,
    eventPayload: SkuEventRequest
  ) => Promise<AxiosResponse<void>>;

  readonly deleteProductCatalogEvent: (
    accountId: string,
    productCatalogId: string,
    eventId: string
  ) => Promise<AxiosResponse<void>>;

  readonly getProductCatalogGraphEvents: (
    accountId: string,
    productCatalogId: string,
    filters: Filter[],
    body: EventLogDataRequestBody
  ) => Promise<SkuDetailsEvents>;

  readonly getProductGroups: (
    accountId: string
  ) => Promise<ExistingSkuGroupResopnse>;
  readonly createProductGroup: (
    accountId: string,
    productName: string,
    productCatalogIds: string[],
    mcIds: string[]
  ) => Promise<{}>;
  readonly updateProductGroup: (
    accountId: string,
    productGroupId: string,
    productName: string,
    productCatalogId: string[],
    mcIds: string[]
  ) => Promise<{}>;
  readonly removeProductFromGroup: (
    accountId: string,
    productCatalogIds: [string, string][],
    mcIds: string[]
  ) => Promise<{}>;
  readonly deleteProductGroup: (
    accountId: string,
    productGroupIds: string[],
    mcIds: string[]
  ) => Promise<{}>;
  readonly customGroupNameUpdate: (
    accountId: string,
    groupId: string,
    teikaGroupName: string,
    mcIds: string[],
    pcIds: string[]
  ) => Promise<AxiosResponse<void>>;
  readonly getRelatedCampaignAndAdGroupData: (
    accountId: string,
    payload: RelatedCampaignAndAdGroupRequest
  ) => Promise<RelatedCampaignAndAdGroupResponse>;
  readonly getEventConfigs: (accountId: string) => Promise<EventLogConfig>;
  readonly getOutOfStockProducts: (
    accountId: string,
    request: OutOfStockProductsRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<OutOfStockProductsData>>;
  readonly getOutOfStockProductsV2: (
    accountId: string,
    request: InventoryDashboardOOSRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<InventoryDashboardOOSData>>;
  readonly exportOutOfStockProducts: (
    accountId: string,
    request: InventoryDashboardOOSRequest,
    filters: Filter[],
    sorts: Sort[]
  ) => Promise<SKUCatalogExportResponse>;
  readonly exportOutOfStockProductsStatus: (
    accountId: string,
    requestId: string
  ) => Promise<SKUCatalogExportStatusResponse>;
  readonly getActiveProductStock: (
    accountId: string,
    request: ProductInsightsRequestBody
  ) => Promise<ActiveStockResponse>;
  readonly getInventoryValueInsights: (
    accountId: string,
    request: ProductInsightsRequestBody
  ) => Promise<InventoryValueDetailsResponse>;
  readonly getOutOfStockProductsSummary: (
    accountId: string,
    request: ProductInsightsRequestBody,
    numOfRecords: number
  ) => Promise<OutOfStockProductsSummaryResponse>;

  readonly getEstimatedMissedSalesGraphPoints: (
    accountId: string,
    request: ProductInsightsRequestBody
  ) => Promise<EstimatedMissedSalesGraphPointsResponse>;

  readonly createCustomInsightSubscription: (
    accountId: string,
    request: CreateInsightsPayload
  ) => Promise<CreateInsightsData>;

  readonly updateCustomInsights: (
    accountId: string,
    customInsightSubscriptionId: string,
    request: CreateInsightsPayload
  ) => Promise<CreateInsightsData>;

  readonly deleteCustomInsight: (
    accountId: string,
    customInsightSubscriptionId: string
  ) => Promise<AxiosResponse<void>>;

  readonly exportCustomInsights: (
    accountId: string,
    customInsightSubscriptionId: string,
    payload: ExportInsightsPayload,
    sorts: Sort[],
    filters: Filter[]
  ) => Promise<AxiosResponse<Blob>>;

  readonly getCustomInsights: (
    accountId: string,
    filters: Filter[],
    limit: number
  ) => Promise<PaginatedResponse<CustomInsightSubscriptionDetails>>;

  readonly getCustomInsightProductsForDashboard: (
    accountId: string,
    customInsightSubscriptionId: string,
    request: CustomInsightProductsForDashboardRequest
  ) => Promise<CustomInsightsProductsDataResponse>;

  readonly getCustomInsightsProductListings: (
    accountId: string,
    request: CustomInsightsProductsRequest,
    insightTypeGroup: SelectMetricsType,
    customInsightSubscriptionId?: string,
    transformFilters?: (filters: Filter[]) => Filter[]
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<CustomInsightsProductsData>>;

  readonly getCustomInsightSubscriptionDetails: (
    accountId: string,
    customInsightSubscriptionId: string
  ) => Promise<CustomInsightSubscriptionDetails>;

  readonly getDemandForecastings: (
    accountId: string,
    request: DemandForecastRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<DemandForecastData>>;
  readonly exportDemandForecasts: (
    accountId: string,
    request: DemandForecastRequest,
    filters: Filter[],
    sorts: Sort[]
  ) => Promise<SKUCatalogExportResponse>;
  readonly exportDemandForecastsStatus: (
    accountId: string,
    requestId: string
  ) => Promise<SKUCatalogExportStatusResponse>;

  readonly getCampaignCreateRecommendation: (
    accountId: string,
    request: UnadvertisedProductsRequest
  ) => Promise<UnadvertisedProductsResponse[]>;

  readonly getActiveInventoryProducts: (
    accountId: string,
    request: InventoryDashboardRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<ActiveInventoryData>>;
  readonly exportActiveInventoryProductsData: (
    accountId: string,
    request: InventoryDashboardRequest,
    filters: Filter[],
    sorts: Sort[]
  ) => Promise<SKUCatalogExportResponse>;

  readonly getRecommendationNotSmartCampaign: (
    accountId: string,
    request: UnadvertisedProductsRequest
  ) => Promise<UnadvertisedProductsResponse[]>;

  readonly getUnsoldInventoryDetailsData: (
    accountId: string,
    request: InventoryDashboardRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<UnsoldInventoryDetailsData>>;
  readonly exportUnsoldInventoryDetailsData: (
    accountId: string,
    request: InventoryDashboardRequest,
    filters: Filter[],
    sorts: Sort[]
  ) => Promise<SKUCatalogExportResponse>;
  readonly getUnsoldInventorySummary: () => (
    accountId: string,
    request: InventoryDashboardRequest
  ) => Promise<UnsoldInventorySummaryResponse>;
  readonly getUnsoldInventoryPreviousSummary: (
    accountId: string,
    request: InventoryDashboardRequest,
    signal?: AbortSignal,
    source?: CancelTokenSource
  ) => Promise<UnsoldInventoryPreviousSummaryResponse>;
  readonly getInventoryBreakdownValue: () => (
    accountId: string,
    request: InventoryDashboardRequest
  ) => Promise<InventoryByAgeMetricsNew>;
  readonly getTotalInventoryHealthValue: () => (
    accountId: string,
    request: InventoryDashboardRequest
  ) => Promise<TotalActiveInventoryValue>;
  readonly getSellThroughRateAndSalesRatio: () => (
    accountId: string,
    request: InventoryDashboardRequest
  ) => Promise<InventoryHealthMetricData>;

  readonly getOOSGraphData: (
    accountId: string,
    request: InventoryDashboardOOSRequest
  ) => Promise<OOSGraphDataAPIResponse>;
  readonly getOutOfStockProductsSummaryV2: () => (
    accountId: string,
    request: InventoryDashboardRequest
  ) => Promise<OutOfStockProductsSummaryResponseV2>;
  readonly exportInventoryDetailsProductsStatus: (
    accountId: string,
    requestId: string
  ) => Promise<SKUCatalogExportStatusResponse>;

  readonly getOutOfStockCounts: (
    accountId: string,
    request: InventoryDashboardRequest
  ) => Promise<InventoryOptimizationCountsResponse>;
  readonly getUnsoldInventoryCounts: (
    accountId: string,
    request: InventoryDashboardRequest
  ) => Promise<InventoryOptimizationCountsResponse>;

  readonly getAdvertisedLowInventoryCounts: (
    accountId: string,
    request: InventoryDashboardRequest
  ) => Promise<InventoryOptimizationCountsResponse>;
  readonly getUnAdvertisedLowSellThroughRateCounts: (
    accountId: string,
    request: InventoryDashboardRequest
  ) => Promise<InventoryOptimizationCountsResponse>;

  readonly getMultichannelCatalogPerformanceData: (
    accountId: string
  ) => (
    request: MultichannelCatalogPerformanceDataRequest,
    controller?: AbortController,
    source?: CancelTokenSource
  ) => Promise<MultichannelCatalogResponse>;
  readonly exportMultichannelCatalogPerformanceData: (
    accountId: string,
    request: MultichannelCatalogPerformanceDataRequest
  ) => Promise<AxiosResponse<Blob>>;
  readonly getAdvertisingPenetrationMultiChannelDataSnapshot: (
    accountId: string,
    request: MultiChannelAdvertisingPenetrationDataSnapshotRequest,
    controller?: AbortController,
    source?: CancelTokenSource
  ) => Promise<MultichannelAdvertisingPenetrationProductDataResponse>;
  readonly getSlideoutProductDetails: (
    accountId: string,
    request: SlideoutProductDetailsRequest
  ) => Promise<SlideoutProductDetails>;
  readonly getSlideoutProductGraphData: (
    accountId: string,
    request: SlideoutProductGraphDataRequest
  ) => Promise<SlideoutProductsGraphDataResponse>;
  readonly exportSlideoutProductGraphData: (
    accountId: string,
    request: SlideoutProductGraphDataRequest
  ) => Promise<AxiosResponse<Blob>>;
}

export const PATHS = Object.freeze({
  DATA_AVAILABILITY_INFO: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/data-availability-info`,
  SKU_CATALOG_DETAILS: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${dummyEndPoint ? 'dummy/' : ''}${accountId}/sku-catalog/data`,
  SKU_CATALOG_CHILD_DATA: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${accountId}/${
      dummyEndPoint ? 'dummy/' : ''
    }sku-catalog/parent-child/data`,
  SKU_CATALOG_CUSTOM_GROUPING_DATA: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/custom-grouping/data`,
  SKU_CATALOG_INDIVIDUAL_GROUPING_DATA: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/individual/data`,
  SKU_CATALOG_SUMMARY: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/sku-catalog/data/aggregation`,
  SKU_CATALOG_PARENT_CHILD_SUMMARY: (
    accountId: string,
    dummyEndPoint?: boolean
  ) =>
    `/accounts/${accountId}/${
      dummyEndPoint ? 'dummy/' : ''
    }sku-catalog/parent-child/data/aggregation`,
  SKU_CATALOG_CUSTOM_GROUPING_SUMMARY: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/custom-grouping/data/aggregation`,
  SKU_CATALOG_INDIVIDUAL_GROUPING_SUMMARY: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/individual/data/aggregation`,
  SKU_CATALOG_EXPORT: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/export`,
  SKU_CATALOG_PARENT_CHILD_EXPORT: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/parent-child/data/export`,
  SKU_CATALOG_INDIVIDUAL_EXPORT: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/individual/data/export`,
  SKU_CATALOG_CUSTOM_EXPORT: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/custom-grouping/data/export`,
  MANAGE_COGS_UPLOAD: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${dummyEndPoint ? 'dummy/' : ''}${accountId}/manage-cogs/upload`,
  MANAGE_COGS_UPLOAD_STATUS: (accountId: string, requestId: string) =>
    `/accounts/${accountId}/manage-cogs/upload/${requestId}/status`,
  EXPORT_COGS_TEMPLATE: (accountId: string, v2?: boolean) =>
    `/accounts/${accountId}/cogs-template/export${v2 ? '/v2' : ''}`,
  EXPORT_COGS_STATUS: (accountId: string, requestId: string) =>
    `/accounts/${accountId}/cogs-template/export/v2/${requestId}/status`,
  SKU_OVERVIEW_DETAILS: (
    accountId: string,
    productGroupId: string,
    dummyEndPoint?: boolean
  ) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/sku-catalog/${productGroupId}/sku-details`,

  SKU_OVERVIEW_SUMMARY: (
    accountId: string,
    productGroupId: string,
    dummyEndPoint?: boolean
  ) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/sku-catalog/${productGroupId}/sku-details/aggregation`,

  PRODUCT_DETAILS_SUMMARY: (accountId: string, productGroupId: string) =>
    `/accounts/${accountId}/sku-catalog/${productGroupId}/skuDetailsSummary`,

  PRODUCT_DETAILS_SUMMARY_V2: (
    accountId: string,
    productGroupId: string,
    dummyApi?: boolean
  ) =>
    `/accounts/${accountId}/${
      dummyApi ? 'dummy/' : ''
    }sku-catalog/v2/${productGroupId}/sku-details`,

  PRODUCT_GROUP_IDS: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/productGroupId`,

  MANAGE_COGS_SECURE_URL: (accountId: string) =>
    `/accounts/${accountId}/manage-cogs/generate-signed-url`,

  MANAGE_COGS_S3_FILE_UPLOAD: (url: string) => url,

  MISSING_COGS: (accountId: string) => `/accounts/${accountId}/missing-cogs`,

  MISSING_COGS_RATIO: (accountId: string) =>
    `/accounts/${accountId}/missing-cogs/ratio`,

  EXPORT_BUSINESS_PERFORMANCE_CSV: (accountId: string) =>
    `/accounts/${accountId}/compass/business/dataPoints/export`,

  EXPORT_PRODUCT_METADATA_TEMPLATE: (
    accountId: string,
    metaDataType: MetadataType
  ) => `/accounts/${accountId}/metadata-template/${metaDataType}/export`,

  EXPORT_PRODUCT_METADATA_TEMPLATE_STATUS: (
    accountId: string,
    metaDataType: string,
    requestId: string
  ) =>
    `/accounts/${accountId}/metadata-template/${metaDataType}/export/${requestId}/status`,

  EXPORT_PRODUCT_METADATA_TEMPLATE_DOWNLOAD_URL: (
    accountId: string,
    requestId: string
  ) => `/accounts/${accountId}/product-metadata-template/export/${requestId}`,

  PRODUCT_METADATA_SECURE_URL: (
    accountId: string,
    metaDataType: MetadataType
  ) =>
    `/accounts/${accountId}/metadata-template/${metaDataType}/upload/generate-signed-url`,

  PRODUCT_METADATA_S3_FILE_UPLOAD: (url: string) => url,

  PRODUCT_METADATA_UPLOAD: (accountId: string, metaDataType: MetadataType) =>
    `/accounts/${accountId}/metadata-template/${metaDataType}/upload`,

  PRODUCT_METADATA_UPLOAD_STATUS: (
    accountId: string,
    metaDataType: MetadataType,
    requestId: string
  ) =>
    `/accounts/${accountId}/metadata-template/${metaDataType}/upload/${requestId}/status`,
  PRODUCT_DATA_EXPORT_STATUS: (accountId: string, requestId: string) =>
    `accounts/${accountId}/sku-catalog/export/${requestId}/status`,
  PRODUCT_DATA_PARENT_CHILD_EXPORT_STATUS: (
    accountId: string,
    requestId: string
  ) =>
    `accounts/${accountId}/sku-catalog/parent-child/data/export/${requestId}/status`,
  PRODUCT_DATA_S3_FILE_DOWNLOAD: (accountId: string, requestId: string) =>
    `accounts/${accountId}/sku-catalog/export/${requestId}`,
  PRODUCT_DATA_PARENT_CHILD_S3_FILE_DOWNLOAD: (
    accountId: string,
    requestId: string
  ) =>
    `accounts/${accountId}/sku-catalog/parent-child/data/export/${requestId}`,
  SKU_PERFORMANCE_METRICS_DATA_POINTS: (
    accountId: string,
    productCatalogId: string
  ) =>
    `/accounts/${accountId}/sku-catalog/v2/${productCatalogId}/sku-details/dataPoints`,
  SKU_DETAILS_V2_Data: (
    accountId: string,
    productCatalogId: string,
    dummyEndPoint?: boolean
  ) =>
    `/accounts/${accountId}/${
      dummyEndPoint ? 'dummy/' : ''
    }sku-catalog/v2/${productCatalogId}/sku-details/data`,
  SKU_DETAILS_V2_AGGREGATION_Data: (
    accountId: string,
    productCatalogId: string,
    dummyEndPoint?: boolean
  ) =>
    `/accounts/${accountId}/${
      dummyEndPoint ? 'dummy/' : ''
    }sku-catalog/v2/${productCatalogId}/sku-details/aggregation`,
  SKU_CHILD_SKU_GMROI: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/gmroi`,
  SKU_DETAILS_GET_EVENTS: (accountId: string, productCatalogId: string) =>
    `/accounts/${accountId}/productCatalogEvent/${productCatalogId}`,
  SKU_DETAILS_GET_EVENTS_EXPORT: (
    accountId: string,
    productCatalogId: string
  ) => `/accounts/${accountId}/productCatalogEvent/${productCatalogId}/export`,
  CREATE_PRODUCT_CATALOG_EVENT: (accountId: string, productCatalogId: string) =>
    `/accounts/${accountId}/productCatalogEvent/${productCatalogId}/create`,
  UPDATE_PRODUCT_CATALOG_EVENT: (accountId: string, productCatalogId: string) =>
    `/accounts/${accountId}/productCatalogEvent/${productCatalogId}/update`,
  DELETE_PRODUCT_CATALOG_EVENT: (
    accountId: string,
    productCatalogId: string,
    eventId: string
  ) =>
    `/accounts/${accountId}/productCatalogEvent/${productCatalogId}/${eventId}/delete`,
  GET_PRODUCT_CATALOG_GRAPH_EVENTS: (
    accountId: string,
    productCatalogId: string
  ) => `/accounts/${accountId}/productCatalogEvent/${productCatalogId}/graph`,
  GET_CUSTOM_SKU_GROUPING: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/custom-grouping`,
  CREATE_CUSTOM_SKU_GROUPING: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/custom-grouping/create`,
  UPDATE_CUSTOM_SKU_GROUPING: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/custom-grouping/update`,
  DISASSOCIATE_SKUS_FROM_GROUP: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/custom-grouping/un-associate`,
  DELETE_SKUS_GROUP: (accountId: string) =>
    `/accounts/${accountId}/sku-catalog/custom-grouping/delete`,
  GET_RELATED_CAMPAIGN_AND_ADGROUP_DATA: (accountId: string) =>
    `/accounts/${accountId}/sku/associated-campaigns`,
  GET_EVENT_CONFIGS: (accountId: string) =>
    `/accounts/${accountId}/productCatalogEvent/eventConfigs`,
  GET_OUT_OFSTOCK_PRODUCTS_DATA: (accountId: string) =>
    `/accounts/${accountId}/insights/out-of-stock/products/data`,
  GET_OUT_OFSTOCK_PRODUCTS_DATA_V2: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/out-of-stock/products/data`,
  GET_OUT_OF_STOCK_PRODUCTS_DATA_EXPORT: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/out-of-stock/data/export`,
  GET_OUT_OF_STOCK_PRODUCTS_DATA_EXPORT_STATUS: (
    accountId: string,
    requestId: string
  ) =>
    `/accounts/${accountId}/inventory/dashboard/out-of-stock/data/export/${requestId}/status`,
  ACTIVE_PRODUCT_STOCK: (accountId: string) =>
    `/accounts/${accountId}/insights/active-product-stock`,
  INVENTORY_VALUE_DETAILS: (accountId: string) =>
    `/accounts/${accountId}/insights/inventory-value`,
  OUT_OF_STOCK_SUMMARY: (accountId: string) =>
    `/accounts/${accountId}/insights/out-of-stock/products`,
  MISSED_SALES_GRAPH_POINT: (accountId: string) =>
    `/accounts/${accountId}/insights/out-of-stock/estimated-missed-sales-data-points`,
  CREATE_CUSTOM_INSIGHT: (accountId: string) =>
    `/accounts/${accountId}/customInsights/create`,
  UPDATE_CUSTOM_INSIGHT: (
    accountId: string,
    customInsightSubscriptionId: string
  ) =>
    `/accounts/${accountId}/customInsights/${customInsightSubscriptionId}/update`,
  DELETE_CUSTOM_INSIGHT: (
    accountId: string,
    customInsightSubscriptionId: string
  ) =>
    `/accounts/${accountId}/customInsights/${customInsightSubscriptionId}/delete`,
  EXPORT_CUSTOM_INSIGHT: (
    accountId: string,
    customInsightSubscriptionId: string
  ) =>
    `/accounts/${accountId}/customInsights/${customInsightSubscriptionId}/products/export`,
  GET_CUSTOM_INSIGHTS: (accountId: string) =>
    `/accounts/${accountId}/customInsights/subscriptions`,
  GET_CUSTOM_INSIGHTS_PRODUCTS_BY_SUBSCRIPTION_ID: (
    accountId: string,
    customInsightSubscriptionId: string
  ) =>
    `/accounts/${accountId}/customInsights/${customInsightSubscriptionId}/products`,
  GET_CUSTOM_INSIGHTS_PRODUCTS: (accountId: string) =>
    `/accounts/${accountId}/customInsights/querySnapshot`,
  GET_CUSTOM_INSIGHT_SUBSCRIPTION_DETAILS: (
    accountId: string,
    customInsightSubscriptionId: string
  ) => `accounts/${accountId}/customInsights/${customInsightSubscriptionId}`,
  GET_DEMAND_FORECASTINGS: (accountId: string) =>
    `/accounts/${accountId}/demand-forecast/data`,
  EXPORT_DEMAND_FORECASTS: (accountId: string) =>
    `/accounts/${accountId}/demand-forecast/data/export`,
  EXPORT_DEMAND_FORECASTS_STATUS: (accountId: string, requestId: string) =>
    `/accounts/${accountId}/demand-forecast/data/export/${requestId}/status`,

  GET_CAMPAIGN_CREATE_RECOMMENDATION_DETAILS_UNADVERTISED: (
    accountId: string
  ) => `/accounts/${accountId}/v1/campaign-create/recommendation/unadvertised`,
  GET_ACTIVE_INVENTORY_PRODUCTS: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/activeInventoryDetails`,
  EXPORT_ACTIVE_INVENTORY_PRODUCTS_DATA: (accountId: string) =>
    `accounts/${accountId}/inventory/dashboard/activeInventoryDetails/data/export`,
  GET_CAMPAIGN_CREATE_RECOMMENDATION_DETAILS_NOT_SMART_CAMPAIGN: (
    accountId: string
  ) =>
    `/accounts/${accountId}/v1/campaign-create/recommendation/not-smart-count`,
  GET_UNSOLD_INVENTORY_SUMMARY_DETAILS: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/unsoldInventoryvalue`,
  GET_UNSOLD_INVENTORY_PREVIOUS_SUMMARY_DETAILS: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/unsoldPreviousInventoryValue`,
  GET_UNSOLD_INVENTORY_DETAILS_DATA: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/unsoldInventoryvalue/data`,
  EXPORT_UNSOLD_INVENTORY_DETAILS_DATA: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/unsoldInventoryDetails/data/export`,
  GET_INVENTORY_BREAKDOWN_VALUE: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/inventoryBreakdownValue`,
  GET_TOTAL_INVENTORY_HEALTH_VALUE: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/totalActiveInventoryValue`,
  GET_SELL_THROUGH_RATE_AND_INVENTORY_SALES_RATIO: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/sellThroughRateInventorySalesRatio`,
  GET_OUT_OF_STOCK_INVENTORY_SUMMARY_DETAILS: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/out-of-stock/topProducts`,
  OOS_GRAPH_DATA: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/out-of-stock/estimated-missed-sales-data-points`,
  GET_INVENTORY_DETAILS_DATA_EXPORT_STATUS: (
    accountId: string,
    requestId: string
  ) =>
    `/accounts/${accountId}/inventory/dashboard/inventoryDetails/data/export/${requestId}/status`,
  GET_OUT_OF_STOCK_COUNTS: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/outOfStockCounts`,
  GET_UNSOLD_INVENTORY_COUNTS: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/unsoldInventoryCounts`,
  GET_ADVERTISED_LOW_INVENTORY_COUNTS: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/replenishAdvertisedInventoryCount`,
  GET_UN_ADVERTISED_LOW_SELL_THROUGH_RATE_COUNTS: (accountId: string) =>
    `/accounts/${accountId}/inventory/dashboard/lowSellThroughUnadvertisedCount`,
  MULTI_CHANNEL_ADVERTISING_PENETRATION_DATA_SNAPSHOT: (accountId: string) =>
    `/accounts/${accountId}/multi-channel/product-catalog/penetration`,
  MULTI_CHANNEL_CATALOG_PERFORMANCE_DATA: (accountId: string) =>
    `/accounts/${accountId}/multi-channel/product-catalog/data`,
  MULTI_CHANNEL_CATALOG_PERFORMANCE_DATA_EXPORT: (accountId: string) =>
    `/accounts/${accountId}/multi-channel/product-catalog/data/export`,
  GET_SLIDEOUT_PRODUCT_DETAILS: (accountId: string) =>
    `/accounts/${accountId}/productCatalog/details`,
  GET_SLIDEOUT_PRODUCT_GRAPH_DATA: (accountId: string) =>
    `/accounts/${accountId}/products/graph/datapoints`,
  EXPORT_SLIDEOUT_PRODUCT_GRAPH_DATA: (accountId: string) =>
    `/accounts/${accountId}/export/products/graph/datapoints`,
});

/* eslint max-statements: [2, 100] */
export const createSKUApiClient = (token: IdToken | null): SKUApiClient => {
  const config: RawAxiosRequestConfig = {
    headers: {
      ...getDefaultHeaders(),
      Authorization: `Bearer ${token?.__raw}`,
    },
    baseURL: SKU_BASE_URL,
  };

  const getDataAvailabilityInfo = async (
    accountId: string,
    merchantCountryIds: string[]
  ): Promise<DataAvailabilityResponse> => {
    const DEFAULT_DATA_AVAILABILITY_RESPONSE = {
      earliestAvailableDate: '',
      latestAvailableDate: '',
      lastSyncedAt: '',
      syncPerMerchantIds: [],
    };

    if (merchantCountryIds.length > 0 && accountId) {
      return axios
        .post<DataAvailabilityResponse>(
          PATHS.DATA_AVAILABILITY_INFO(accountId),
          { merchantCountryIds },
          { ...config }
        )
        .then((resp) => resp.data)
        .catch(() => DEFAULT_DATA_AVAILABILITY_RESPONSE);
    } else {
      return DEFAULT_DATA_AVAILABILITY_RESPONSE;
    }
  };

  const getSkuCatalogDetails =
    (accountId: string, skuCatalogRequest: SkuCatalogDetailsRequest) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<SkuCatalogData>> => {
      const allParams = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(allParams);

      const response = await axios.post<PaginatedResponse<SkuCatalogData>>(
        PATHS.SKU_CATALOG_DETAILS(accountId),
        skuCatalogRequest,
        { ...config, params }
      );

      return {
        items: response.data.elements,
        totalItems: response.data.filteredElements,
      };
    };

  const getSkuCatalogDetailsChildren =
    (accountId: string, skuCatalogRequest: SkuCatalogDetailsRequest) =>
    async (paginatedRequest: PaginatedRequest) => {
      const url = PATHS.SKU_CATALOG_CHILD_DATA(accountId);

      const allParams = getPaginationParamsFromRequest({
        ...paginatedRequest,
        filters: removeTeikaGroupNameFilter(paginatedRequest.filters),
      });
      const params = filterEmptyValues(allParams);

      const response = await axios.post<PaginatedResponse<SkuCatalogData>>(
        url,
        skuCatalogRequest,
        {
          ...config,
          params,
        }
      );

      return {
        items: response.data.elements.map((element) => ({
          ...element,
          subRows: element.skuChildren?.elements.map((childElement) => ({
            ...childElement,
            parentAsin: element.skuDetailsFields.extItemDetail?.extItemId,
            parentSku: element.skuDetailsFields.sku,
          })),
        })),
        totalItems: response.data.filteredElements,
      };
    };

  const getSkuCatalogDetailsChildrenV3 =
    (accountId: string, skuCatalogRequest: SkuCatalogDetailsRequest) =>
    async (paginatedRequest: PaginatedRequest) => {
      const url = PATHS.SKU_CATALOG_CUSTOM_GROUPING_DATA(accountId);

      const allParams = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(allParams);

      const { data } = await axios.post<PaginatedResponse<SkuCatalogDataV3>>(
        url,
        skuCatalogRequest,
        {
          ...config,
          params,
        }
      );

      const convertedResponse: SkuCatalogData[] = [];
      data.elements.forEach((e) =>
        convertedResponse.push({
          rowId: uuid(),
          productGroupId: e.teikaGroupId,
          teikaGroupId: e.teikaGroupId,
          skuDetailsFields: {
            sku: '',
            name: e.teikaGroupName,
            advertisedSkuCount: e.advertisedSkuCount,
            skuCount: e.skuCount,
            imageUrl: e.imageUrl,
          },
          currentMetrics: e.currentMetrics,
          previousMetrics: e.previousMetrics,
          salesChannels: e.salesChannels,
          skuChildren: {
            elements: e.productGroups,
            filteredElements: e.productGroups?.length || 0,
          },
          subRows: e.productGroups?.map((childElement) => ({
            ...childElement,
            rowId: uuid(),
            teikaGroupId: e.teikaGroupId,
            isSingleParent: e.productGroups.length <= 1,
            subRows: childElement.skuChildren?.elements.map(
              (childElement2) => ({
                ...childElement2,
                rowId: uuid(),
                parentAsin:
                  childElement.skuDetailsFields.extItemDetail?.extItemId,
                parentSku: childElement.skuDetailsFields.sku,
              })
            ),
            parentAsin: childElement.skuDetailsFields.extItemDetail?.extItemId,
            parentSku: childElement.skuDetailsFields.sku,
          })),
        })
      );
      const preparedResp: PaginatedResult<SkuCatalogData> = {
        items: convertedResponse,
        totalItems: data.filteredElements,
      };
      return preparedResp;
    };

  const getSkuCatalogDetailsIndividual =
    (accountId: string, skuCatalogRequest: SkuCatalogDetailsRequest) =>
    async (paginatedRequest: PaginatedRequest) => {
      const url = PATHS.SKU_CATALOG_INDIVIDUAL_GROUPING_DATA(accountId);

      const allParams = getPaginationParamsFromRequest({
        ...paginatedRequest,
        filters: removeTeikaGroupNameFilter(paginatedRequest.filters),
      });
      const params = filterEmptyValues(allParams);

      const { data } = await axios.post<PaginatedResponse<SkuCatalogData>>(
        url,
        skuCatalogRequest,
        {
          ...config,
          params,
        }
      );

      const preparedResp: PaginatedResult<SkuCatalogData> = {
        items: data.elements,
        totalItems: data.filteredElements,
      };
      return preparedResp;
    };

  const getSkuCatalogSummary =
    (
      accountId: string,
      request: SkuCatalogSummaryRequest,
      queryParams: Record<string, string>
    ) =>
    async (filteredRequest: FilteredRequest): Promise<SkuCatalogSummary> => {
      if (request.merchantCountries.length === 0) {
        return {
          advertisementFields: {},
          inventoryFields: {},
          platformFeesFields: {},
          productFields: {},
          profitabilityFields: {},
        };
      }

      const allParams = {
        ...filteredRequest.extraParams,
        filter: filtersToQueryString(filteredRequest.filters),
        sort: generateSortQueryString(filteredRequest.sorts),
      };

      const params = filterEmptyValues(allParams);
      const response = await axios.post<SkuCatalogSummary>(
        PATHS.SKU_CATALOG_SUMMARY(accountId),
        {
          ...request,
        },
        { ...config, params: { ...queryParams, ...params } }
      );
      return response.data;
    };

  const getSkuCatalogIndividualSummary =
    (
      accountId: string,
      request: SkuCatalogSummaryRequest,
      queryParams: Record<string, string>
    ) =>
    async (filteredRequest: FilteredRequest): Promise<SkuCatalogSummary> => {
      const allParams = {
        ...filteredRequest.extraParams,
        filter: filtersToQueryString(
          removeTeikaGroupNameFilter(filteredRequest.filters)
        ),
        sort: generateSortQueryString(filteredRequest.sorts),
      };

      const params = filterEmptyValues(allParams);
      const response = await axios.post<SkuCatalogSummary>(
        PATHS.SKU_CATALOG_INDIVIDUAL_GROUPING_SUMMARY(accountId),
        {
          ...request,
        },
        { ...config, params: { ...queryParams, ...params } }
      );
      return response.data;
    };

  const getSkuCatalogParentChildSummary =
    (
      accountId: string,
      request: SkuCatalogSummaryRequest,
      queryParams: Record<string, string>
    ) =>
    async (filteredRequest: FilteredRequest): Promise<SkuCatalogSummary> => {
      const allParams = {
        ...filteredRequest.extraParams,
        filter: filtersToQueryString(
          removeTeikaGroupNameFilter(filteredRequest.filters)
        ),
        sort: generateSortQueryString(filteredRequest.sorts),
      };

      const params = filterEmptyValues(allParams);
      const response = await axios.post<SkuCatalogSummary>(
        PATHS.SKU_CATALOG_PARENT_CHILD_SUMMARY(accountId),
        {
          ...request,
        },
        { ...config, params: { ...queryParams, ...params } }
      );
      return response.data;
    };

  const getSkuCatalogCustomGroupingSummary =
    (
      accountId: string,
      request: SkuCatalogSummaryRequest,
      queryParams: Record<string, string>
    ) =>
    async (filteredRequest: FilteredRequest): Promise<SkuCatalogSummary> => {
      const allParams = {
        ...filteredRequest.extraParams,
        filter: filtersToQueryString(filteredRequest.filters),
        sort: generateSortQueryString(filteredRequest.sorts),
      };

      const params = filterEmptyValues(allParams);
      const response = await axios.post<SkuCatalogSummary>(
        PATHS.SKU_CATALOG_CUSTOM_GROUPING_SUMMARY(accountId),
        {
          ...request,
        },
        { ...config, params: { ...queryParams, ...params } }
      );
      return response.data;
    };

  const exportSkuCatalog = async (
    accountId: string,
    skuCatalogRequest: SkuCatalogExportRequest,
    queryParams: Record<string, string>,
    currentView?: SkuViewType
  ): Promise<SKUCatalogExportResponse> => {
    const getExportUrl = () => {
      if (currentView) {
        if (currentView === SkuViewType.IndividualSKU) {
          return PATHS.SKU_CATALOG_INDIVIDUAL_EXPORT(accountId);
        } else if (currentView === SkuViewType.MultiChannel) {
          return PATHS.SKU_CATALOG_CUSTOM_EXPORT(accountId);
        } else {
          return PATHS.SKU_CATALOG_PARENT_CHILD_EXPORT(accountId);
        }
      } else {
        return PATHS.SKU_CATALOG_PARENT_CHILD_EXPORT(accountId);
      }
    };

    const response = await axios.post(
      getExportUrl(),
      {
        ...skuCatalogRequest,
      },
      { ...config, params: queryParams }
    );

    return response.data;
  };

  const exportSkuStatus = async (
    accountId: string,
    requestId: string
  ): Promise<SKUCatalogExportStatusResponse> => {
    const response = await axios.get(
      PATHS.PRODUCT_DATA_PARENT_CHILD_EXPORT_STATUS(accountId, requestId),
      { ...config }
    );
    return response.data;
  };

  const downloadSkuCatalogExportFile = async (
    accountId: string,
    requestId: string,
    useParentChildUrl?: boolean
  ): Promise<SKUCatalogExportDownloadUrlResponse> => {
    const url = useParentChildUrl
      ? PATHS.PRODUCT_DATA_PARENT_CHILD_S3_FILE_DOWNLOAD(accountId, requestId)
      : PATHS.PRODUCT_DATA_S3_FILE_DOWNLOAD(accountId, requestId);
    const response = await axios.get(url, {
      ...config,
    });

    return response.data;
  };

  const manageCogsUpload = async (
    accountId: string,
    requestId: string,
    fileName: string
  ): Promise<SKUManageCogsResponse> => {
    const body = {
      requestId,
      cogsCsvS3FileName: fileName,
    };
    const response = await axios.post(
      PATHS.MANAGE_COGS_UPLOAD(accountId),
      { ...body },
      { ...config }
    );
    return response.data;
  };

  const manageCogsUploadStatus = async (
    accountId: string,
    requestId: string
  ): Promise<SKUManageCogsStatusResponse> => {
    const response = await axios.get(
      PATHS.MANAGE_COGS_UPLOAD_STATUS(accountId, requestId),
      {
        ...config,
      }
    );
    return response.data;
  };

  const exportCogsTemplateV2 = async (
    accountId: string,
    skuCogsTemplateRequest: SkuCatalogCogsTemplateRequest,
    queryParams?: Record<string, string>
  ): Promise<AxiosResponse<SKUManageCogsResponse>> => {
    return axios.post(
      PATHS.EXPORT_COGS_TEMPLATE(accountId, true),
      skuCogsTemplateRequest,
      {
        ...config,
        params: queryParams,
      }
    );
  };

  const exportCogsTemplateV2Status = async (
    accountId: string,
    requestId: string
  ): Promise<SKUManageCogsStatusResponse> => {
    return axios
      .get<SKUManageCogsStatusResponse>(
        PATHS.EXPORT_COGS_STATUS(accountId, requestId),
        {
          ...config,
        }
      )
      .then((resp) => resp.data);
  };

  const getSkuOverviewDetails =
    (
      accountId: string,
      skuOverviewRequest: SkuOverviewRequest,
      productGroupId: string
    ) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<SkuOverviewData>> => {
      const allParams = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(allParams);

      const response = await axios.post<PaginatedResponse<SkuOverviewData>>(
        PATHS.SKU_OVERVIEW_DETAILS(accountId, productGroupId),
        skuOverviewRequest,
        { ...config, params }
      );
      return {
        items: response.data.elements,
        totalItems: response.data.filteredElements,
      };
    };

  const getSkuOverviewSummary =
    (
      accountId: string,
      productGroupId: string,
      request: SkuOverviewSummaryRequest,
      queryParams: Record<string, string>
    ) =>
    async (filteredRequest: FilteredRequest): Promise<SkuOverviewSummary> => {
      const allParams = {
        ...filteredRequest.extraParams,
        filter: filtersToQueryString(filteredRequest.filters),
        sort: generateSortQueryString(filteredRequest.sorts),
      };

      const params = filterEmptyValues(allParams);

      const response = await axios.post<SkuOverviewSummary>(
        PATHS.SKU_OVERVIEW_SUMMARY(accountId, productGroupId),
        {
          ...request,
        },
        { ...config, params: { ...queryParams, ...params } }
      );
      return response.data;
    };

  const getProductDetailsSummary = async (
    accountId: string,
    productGroupId: string
  ) => {
    const response = await axios.get(
      PATHS.PRODUCT_DETAILS_SUMMARY(accountId, productGroupId),
      {
        ...config,
      }
    );
    return response.data;
  };

  const getProductDetailsSummaryV2 = async (
    accountId: string,
    productGroupId: string,
    merchantCountryId: string
  ): Promise<ProductDetailsSummaryResponseV2> => {
    const response = await axios.post(
      PATHS.PRODUCT_DETAILS_SUMMARY_V2(accountId, productGroupId),
      { merchantCountryId },
      { ...config }
    );
    return response.data;
  };

  const getProductGroupIds = async (accountId: string, skus: string[]) => {
    const response = await axios.post(
      PATHS.PRODUCT_GROUP_IDS(accountId),
      {
        skus,
      },
      {
        ...config,
      }
    );
    return response.data;
  };

  const manageCogsGetS3SecureUrl = async (
    accountId: string,
    fileName: string
  ): Promise<AxiosResponse<ManageCogsSecureUrlResponse>> => {
    const params = { biddingCsvFileName: fileName };
    return axios.get(PATHS.MANAGE_COGS_SECURE_URL(accountId), {
      ...config,
      params,
    });
  };

  const manageCogsS3FileUpload = async (url: string, file: File) => {
    return axios.put(PATHS.MANAGE_COGS_S3_FILE_UPLOAD(url), file, {});
  };

  const getMissingCogs = async (
    accountId: string,
    shouldRetry?: () => boolean
  ): Promise<MissingCogsResponse> => {
    let axiosInstance = axios.create();
    axiosRetry(axiosInstance, {
      retries: 3,
      retryDelay: (retryCount: number) => retryCount * DEFAULT_RETRY_INTERVAL,
      retryCondition: shouldRetry,
    });

    const response = await axiosInstance.get<MissingCogsResponse>(
      PATHS.MISSING_COGS(accountId),
      {
        ...config,
      }
    );

    return response.data;
  };

  const exportCompassBusinessPerformanceCsvData = (
    accountId: string,
    requestBody: ExportBusinessMetricsRequest
  ) =>
    axios.post(PATHS.EXPORT_BUSINESS_PERFORMANCE_CSV(accountId), requestBody, {
      ...config,
      responseType: 'blob',
    });

  const exportProductMetadataTemplate = async (
    requestId: string,
    accountId: string,
    metaDataType: MetadataType,
    merchantCountryIds: string[],
    merchantDetails: MerchantCountry[],
    salesChannels: AllSalesChannel[]
  ): Promise<ExportProductMetaDataResponse> => {
    let payload: any = {
      requestId,
      merchantCountryIds,
    };
    let newConfig = { ...config };
    if (
      metaDataType === MetadataType.ADS ||
      metaDataType === MetadataType.DSP
    ) {
      newConfig = { ...config, baseURL: AO_BASE_URL };
      payload = {
        requestId,
        merchantCountries: getMerchantCountriesData(
          merchantDetails,
          merchantCountryIds,
          salesChannels
        ),
      };
    }

    return axios
      .post(
        PATHS.EXPORT_PRODUCT_METADATA_TEMPLATE(accountId, metaDataType),
        { ...payload },
        { ...newConfig }
      )
      .then((response) => response.data);
  };

  const exportProductMetadataTemplateStatus = async (
    accountId: string,
    metaDataType: MetadataType,
    requestId: string
  ): Promise<ExportProductMetaDataStatusResponse> => {
    let newConfig = { ...config };
    if (
      metaDataType === MetadataType.ADS ||
      metaDataType === MetadataType.DSP
    ) {
      newConfig = { ...config, baseURL: AO_BASE_URL };
    }
    return axios
      .get(
        PATHS.EXPORT_PRODUCT_METADATA_TEMPLATE_STATUS(
          accountId,
          metaDataType,
          requestId
        ),
        { ...newConfig }
      )
      .then((response) => response.data);
  };

  const downloadS3File = async (url: string): Promise<AxiosResponse<Blob>> => {
    return axios.get(url, {
      responseType: 'blob',
    });
  };

  const getProductMetadataTemplateS3DownloadUrl = async (
    accountId: string,
    requestId: string
  ): Promise<{ s3DownloadUrl: string }> => {
    return axios
      .get(
        PATHS.EXPORT_PRODUCT_METADATA_TEMPLATE_DOWNLOAD_URL(
          accountId,
          requestId
        ),
        { ...config }
      )
      .then((response) => response.data);
  };

  const productMetadataGetS3SecureUrl = async (
    accountId: string,
    metaDataType: MetadataType,
    fileName: string
  ): Promise<AxiosResponse<ManageCogsSecureUrlResponse>> => {
    let newConfig = { ...config };
    if (
      metaDataType === MetadataType.ADS ||
      metaDataType === MetadataType.DSP
    ) {
      newConfig = { ...config, baseURL: AO_BASE_URL };
    }
    const params = { uploadCsvFileName: fileName };
    return axios.get(
      PATHS.PRODUCT_METADATA_SECURE_URL(accountId, metaDataType),
      {
        ...newConfig,
        params,
      }
    );
  };

  const productMetadataS3FileUpload = async (url: string, file: File) => {
    return axios.put(PATHS.PRODUCT_METADATA_S3_FILE_UPLOAD(url), file, {});
  };

  const productMetadataUpload = async (
    requestId: string,
    accountId: string,
    fileName: string,
    metaDataType: MetadataType,
    merchantCountryIds: string[],
    merchantDetails: MerchantCountry[]
  ): Promise<ProductMetadataUploadResponse> => {
    const params = {
      metaDataCsvS3FileName: fileName,
    };
    let payload: any = {
      requestId,
      merchantCountryIds,
    };
    let newConfig = { ...config };
    if (
      metaDataType === MetadataType.ADS ||
      metaDataType === MetadataType.DSP
    ) {
      newConfig = { ...config, baseURL: AO_BASE_URL };
      payload = {
        requestId,
        merchantCountries: getMerchantCountriesData(
          merchantDetails,
          merchantCountryIds
        ),
      };
    }
    return axios
      .post(
        PATHS.PRODUCT_METADATA_UPLOAD(accountId, metaDataType),
        { ...payload },
        { ...newConfig, params }
      )
      .then((response) => response.data);
  };

  const productMetadataUploadStatus = async (
    accountId: string,
    metaDataType: MetadataType,
    requestId: string
  ): Promise<ProductMetadataUploadStatusResponse> => {
    let newConfig = { ...config };
    if (
      metaDataType === MetadataType.ADS ||
      metaDataType === MetadataType.DSP
    ) {
      newConfig = { ...config, baseURL: AO_BASE_URL };
    }
    return axios
      .get(
        PATHS.PRODUCT_METADATA_UPLOAD_STATUS(
          accountId,
          metaDataType,
          requestId
        ),
        {
          ...newConfig,
        }
      )
      .then((response) => response.data);
  };

  const getSKUPerformanceMetricsDataPoints = async (
    accountId: string,
    productCatalogId: string,
    request: SKUPerformanceMetricsRequest
  ): Promise<SKUPerformanceMetricsResponse> => {
    const url = PATHS.SKU_PERFORMANCE_METRICS_DATA_POINTS(
      accountId,
      productCatalogId
    );

    const response = await axios.post<SKUPerformanceMetricsResponse>(
      url,
      { ...request },
      {
        ...config,
      }
    );

    return response.data;
  };

  const getSkuCatalogDetailsV2 =
    (
      accountId: string,
      productCatalogId: string,
      skuDetailsV2Request: SkuDetailsV2DataRequestProps
    ) =>
    async (
      filteredRequest: FilteredRequest
    ): Promise<PaginatedResult<SkuDetailsV2Data>> => {
      const params = {
        sort: generateSortQueryString(filteredRequest.sorts),
      };

      const response = await axios.post(
        PATHS.SKU_DETAILS_V2_Data(accountId, productCatalogId, false),
        { ...skuDetailsV2Request },
        { ...config, params }
      );

      return {
        items: response.data.elements,
        totalItems: response.data.filteredElements,
      };
    };

  const getSkuDetailsV2Summary =
    (
      accountId: string,
      productCatalogId: string,
      skuDetailsV2Request: SkuDetailsV2DataRequestProps
    ) =>
    async (filteredRequest: FilteredRequest): Promise<SkuDetailsV2Summary> => {
      const params = {
        sort: generateSortQueryString(filteredRequest.sorts),
      };

      const response = await axios.post(
        PATHS.SKU_DETAILS_V2_AGGREGATION_Data(
          accountId,
          productCatalogId,
          false
        ),
        { ...skuDetailsV2Request },
        { ...config, params }
      );
      return response.data;
    };

  const getSkuCatalogGmroi = async (
    accountId: string,
    skuCatalogGmroiRequest: SkuCatalogGmroiRequest,
    useDummyResponse?: boolean
  ): Promise<SkuCatalogGmroiResponse> => {
    if (useDummyResponse) {
      return GMROIReseponse.build();
    }
    const url = PATHS.SKU_CHILD_SKU_GMROI(accountId);
    const response = await axios.post<SkuCatalogGmroiResponse>(
      url,
      {
        ...skuCatalogGmroiRequest,
      },
      {
        ...config,
      }
    );

    return response.data;
  };

  const getProductCatalogEvents = async (
    accountId: string,
    productCatalogId: string,
    filters: Filter[],
    body: EventLogDataRequestBody,
    lastEvaluatedKey?: string
  ): Promise<SkuDetailsEventsResponse> => {
    const queryParams = filtersToQueryString(filters);
    try {
      const { data } = await axios.post<SkuDetailsEventsResponse>(
        PATHS.SKU_DETAILS_GET_EVENTS(accountId, productCatalogId),
        body,
        {
          ...config,
          params: {
            filter: queryParams,
            [EventLogRequestFilterKeys.LastEvaluatedKey]: lastEvaluatedKey,
          },
        }
      );

      return data;
    } catch (error) {
      return {
        elements: [],
        filteredElements: 0,
        errorOccured: true,
      };
    }
  };

  const exportProductCatalogEvents = async (
    accountId: string,
    productCatalogId: string,
    filters: Filter[],
    request: EventLogExportRequest
  ): Promise<AxiosResponse<Blob>> => {
    const queryParams = filtersToQueryString(filters);
    return axios.post(
      PATHS.SKU_DETAILS_GET_EVENTS_EXPORT(accountId, productCatalogId),
      request,
      {
        ...config,
        params: {
          filter: queryParams,
        },
        responseType: 'blob',
      }
    );
  };

  const createProductCatalogEvent = async (
    accountId: string,
    productCatalogId: string,
    eventPayload: SkuEventRequest
  ): Promise<AxiosResponse<void>> => {
    const url = PATHS.CREATE_PRODUCT_CATALOG_EVENT(accountId, productCatalogId);
    const response = await axios.put(
      url,
      {
        ...eventPayload,
      },
      {
        ...config,
      }
    );
    return response.data;
  };

  const updateProductCatalogEvent = async (
    accountId: string,
    productCatalogId: string,
    eventPayload: SkuEventRequest
  ): Promise<AxiosResponse<void>> => {
    const url = PATHS.UPDATE_PRODUCT_CATALOG_EVENT(accountId, productCatalogId);
    const response = await axios.post(
      url,
      {
        ...eventPayload,
      },
      {
        ...config,
      }
    );
    return response.data;
  };

  const deleteProductCatalogEvent = async (
    accountId: string,
    productCatalogId: string,
    eventId: string
  ): Promise<AxiosResponse<void>> => {
    const url = PATHS.DELETE_PRODUCT_CATALOG_EVENT(
      accountId,
      productCatalogId,
      eventId
    );
    const response = await axios.delete(url, {
      ...config,
    });
    return response.data;
  };

  const getProductCatalogGraphEvents = async (
    accountId: string,
    productCatalogId: string,
    filters: Filter[],
    body: EventLogDataRequestBody
  ): Promise<SkuDetailsEvents> => {
    const url = PATHS.GET_PRODUCT_CATALOG_GRAPH_EVENTS(
      accountId,
      productCatalogId
    );

    const params = {
      filter: filtersToQueryString(filters),
    };

    const response = await axios.post(url, body, {
      ...config,
      params,
    });

    return response.data.elements;
  };

  const getProductGroups = async (
    accountId: string
  ): Promise<ExistingSkuGroupResopnse> => {
    try {
      const url = PATHS.GET_CUSTOM_SKU_GROUPING(accountId);
      const response = await axios.get(url, {
        ...config,
      });
      return response.data;
    } catch (e) {
      return {
        elements: [],
      };
    }
  };

  const createProductGroup = async (
    accountId: string,
    productName: string,
    productCatalogIds: string[],
    mcIds: string[]
  ): Promise<{}> => {
    const url = PATHS.CREATE_CUSTOM_SKU_GROUPING(accountId);
    const response = await axios.post(
      url,
      {
        teikaGroupName: productName,
        productCatalogIds: productCatalogIds,
        merchantCountries: mcIds,
      },
      {
        ...config,
      }
    );
    return response.data;
  };

  const updateProductGroup = async (
    accountId: string,
    productGroupId: string,
    productName: string,
    productCatalogId: string[],
    mcIds: string[]
  ): Promise<{}> => {
    const url = PATHS.UPDATE_CUSTOM_SKU_GROUPING(accountId);
    const response = await axios.put(
      url,
      {
        teikaGroupId: productGroupId,
        teikaGroupName: productName,
        productCatalogIds: productCatalogId,
        merchantCountries: mcIds,
      },
      {
        ...config,
      }
    );
    return response.data;
  };

  const removeProductFromGroup = async (
    accountId: string,
    productCatalogIds: [string, string][],
    mcIds: string[]
  ): Promise<{}> => {
    const url = PATHS.DISASSOCIATE_SKUS_FROM_GROUP(accountId);
    const response = await axios.post(
      url,
      {
        customProductGroups: getCustomGrouping(productCatalogIds),
        merchantCountries: mcIds,
      },
      {
        ...config,
      }
    );
    return response.data;
  };

  const deleteProductGroup = async (
    accountId: string,
    productGroupIds: string[],
    mcIds: string[]
  ): Promise<{}> => {
    const url = PATHS.DELETE_SKUS_GROUP(accountId);
    const response = await axios.post(
      url,
      {
        teikaGroupIds: productGroupIds,
        merchantCountries: mcIds,
      },
      {
        ...config,
      }
    );
    return response.data;
  };
  const customGroupNameUpdate = async (
    accountId: string,
    groupId: string,
    teikaGroupName: string,
    mcIds: string[],
    pcIds: string[]
  ): Promise<AxiosResponse<void>> => {
    const url = PATHS.UPDATE_CUSTOM_SKU_GROUPING(accountId);
    const payload: CustomGroupNameUpdateRequest = {
      teikaGroupId: groupId,
      teikaGroupName,
      merchantCountries: mcIds,
      productCatalogIds: pcIds,
    };
    const response = await axios.put(
      url,
      {
        ...payload,
      },
      {
        ...config,
      }
    );
    return response.data;
  };

  const getRelatedCampaignAndAdGroupData = async (
    accountId: string,
    payload: RelatedCampaignAndAdGroupRequest
  ): Promise<RelatedCampaignAndAdGroupResponse> => {
    const url = PATHS.GET_RELATED_CAMPAIGN_AND_ADGROUP_DATA(accountId);
    const response = await axios.post(
      url,
      {
        ...payload,
      },
      {
        ...config,
      }
    );
    return response.data;
  };

  const getEventConfigs = async (
    accountId: string
  ): Promise<EventLogConfig> => {
    const response = await axios.get(PATHS.GET_EVENT_CONFIGS(accountId), {
      ...config,
    });
    return response.data;
  };

  const getOutOfStockProducts =
    (accountId: string, request: OutOfStockProductsRequest) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<OutOfStockProductsData>> => {
      const allParams = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(allParams);

      const url = PATHS.GET_OUT_OFSTOCK_PRODUCTS_DATA(accountId);

      const response = await axios.post(
        url,
        {
          ...request,
        },
        {
          ...config,
          params,
        }
      );
      return {
        items: response.data.elements,
        totalItems: response.data.filteredElements,
      };
    };

  const getOutOfStockProductsV2 =
    (accountId: string, request: InventoryDashboardOOSRequest) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<InventoryDashboardOOSData>> => {
      const allParams = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(allParams);

      const url = PATHS.GET_OUT_OFSTOCK_PRODUCTS_DATA_V2(accountId);

      const response = await axios.post(
        url,
        {
          ...request,
        },
        {
          ...config,
          params,
        }
      );
      return {
        items: response.data.elements,
        totalItems: response.data.filteredElements,
        lastSyncedAt: response.data.lastSyncedAt,
      };
    };

  const exportOutOfStockProducts = async (
    accountId: string,
    request: InventoryDashboardOOSRequest,
    filters: Filter[],
    sorts: Sort[]
  ): Promise<SKUCatalogExportResponse> => {
    const queryParams = filtersToQueryString(filters);
    const response = await axios.post<SKUCatalogExportResponse>(
      PATHS.GET_OUT_OF_STOCK_PRODUCTS_DATA_EXPORT(accountId),
      {
        ...request,
      },
      {
        ...config,
        params: {
          filter: queryParams,
          sort: generateSortQueryString(sorts),
        },
      }
    );
    return response.data;
  };

  const exportOutOfStockProductsStatus = async (
    accountId: string,
    requestId: string
  ): Promise<SKUCatalogExportStatusResponse> => {
    const response = await axios.get<SKUCatalogExportStatusResponse>(
      PATHS.GET_OUT_OF_STOCK_PRODUCTS_DATA_EXPORT_STATUS(accountId, requestId),
      {
        ...config,
      }
    );
    return response.data;
  };

  const getActiveProductStock = async (
    accountId: string,
    request: ProductInsightsRequestBody
  ): Promise<ActiveStockResponse> => {
    const response = axios.post<ActiveStockResponse>(
      PATHS.ACTIVE_PRODUCT_STOCK(accountId),
      {
        ...request,
      },
      {
        ...config,
      }
    );
    const res = await response;
    return res.data;
  };

  const getInventoryValueInsights = async (
    accountId: string,
    request: ProductInsightsRequestBody
  ): Promise<InventoryValueDetailsResponse> => {
    const response = axios.post<InventoryValueDetailsResponse>(
      PATHS.INVENTORY_VALUE_DETAILS(accountId),
      {
        ...request,
      },
      {
        ...config,
      }
    );
    const res = await response;
    return res.data;
  };

  const getOutOfStockProductsSummary = async (
    accountId: string,
    request: ProductInsightsRequestBody,
    numOfRecords: number
  ): Promise<OutOfStockProductsSummaryResponse> => {
    const params = {
      limit: numOfRecords,
      offset: 0,
    };
    const response = await axios.post<OutOfStockProductsSummaryResponse>(
      PATHS.OUT_OF_STOCK_SUMMARY(accountId),
      {
        ...request,
      },
      {
        ...config,
        params,
      }
    );
    return response.data;
  };

  const getEstimatedMissedSalesGraphPoints = async (
    accountId: string,
    request: ProductInsightsRequestBody
  ): Promise<EstimatedMissedSalesGraphPointsResponse> => {
    const response = axios.post<EstimatedMissedSalesGraphPointsResponse>(
      PATHS.MISSED_SALES_GRAPH_POINT(accountId),
      {
        ...request,
      },
      {
        ...config,
      }
    );
    const res = await response;
    return res.data;
  };

  const createCustomInsightSubscription = async (
    accountId: string,
    request: CreateInsightsPayload
  ): Promise<CreateInsightsData> => {
    const response = await axios.post<CreateInsightsData>(
      PATHS.CREATE_CUSTOM_INSIGHT(accountId),
      {
        ...request,
      },
      {
        ...config,
      }
    );
    return response.data;
  };

  const getCustomInsights = async (
    accountId: string,
    filters: Filter[],
    limit: number
  ): Promise<PaginatedResponse<CustomInsightSubscriptionDetails>> => {
    const params = {
      filter: filtersToQueryString(filters),
      limit: limit,
      offset: 0,
    };
    const response = await axios.get<
      PaginatedResponse<CustomInsightSubscriptionDetails>
    >(PATHS.GET_CUSTOM_INSIGHTS(accountId), {
      ...config,
      params,
    });
    return response.data;
  };

  const updateCustomInsights = async (
    accountId: string,
    customInsightSubscriptionId: string,
    request: CreateInsightsPayload
  ): Promise<CreateInsightsData> => {
    const response = await axios.put<CreateInsightsData>(
      PATHS.UPDATE_CUSTOM_INSIGHT(accountId, customInsightSubscriptionId),
      {
        ...request,
      },
      {
        ...config,
      }
    );
    return response.data;
  };
  const getCustomInsightProductsForDashboard = async (
    accountId: string,
    customInsightSubscriptionId: string,
    request: CustomInsightProductsForDashboardRequest
  ): Promise<CustomInsightsProductsDataResponse> => {
    try {
      const paginationParams = '?limit=3&offset=0&sort=metricValue,desc';
      const response = await axios.post(
        PATHS.GET_CUSTOM_INSIGHTS_PRODUCTS_BY_SUBSCRIPTION_ID(
          accountId,
          customInsightSubscriptionId
        ) + paginationParams,
        {
          ...request,
        },
        {
          ...config,
        }
      );

      return response.data;
    } catch (e) {
      return Promise.resolve({
        customInsightSubscriptionId,
        elements: [],
        filteredElements: 0,
        totalElements: 0,
        lastUpdatedAt: '',
        restFailed: true,
      });
    }
  };

  const deleteCustomInsight = async (
    accountId: string,
    customInsightSubscriptionId: string
  ): Promise<AxiosResponse<void>> => {
    const url = PATHS.DELETE_CUSTOM_INSIGHT(
      accountId,
      customInsightSubscriptionId
    );
    const response = await axios.delete(url, {
      ...config,
    });
    return response.data;
  };

  const exportCustomInsights = async (
    accountId: string,
    customInsightSubscriptionId: string,
    payload: ExportInsightsPayload,
    sorts: Sort[],
    filters: Filter[]
  ): Promise<AxiosResponse<Blob>> => {
    const queryParams = filtersToQueryString(filters);
    const url = PATHS.EXPORT_CUSTOM_INSIGHT(
      accountId,
      customInsightSubscriptionId
    );
    sorts = sorts.map((sort) => {
      const isMetricvalueColumn = [
        CustomInsightProductColumns.ProjectedDaysToStockout,
      ].includes(sort.column as CustomInsightProductColumns);
      const column = isMetricvalueColumn
        ? CustomInsightProductColumns.ProjectedStockoutDate
        : sort.column;
      return { ...sort, column };
    });
    const params = {
      sort: generateSortQueryString(sorts),
      ...(queryParams && { filter: queryParams }),
    };

    return await axios.post(
      url,
      {
        ...payload,
      },
      {
        ...config,
        params,
      }
    );
  };

  const getCustomInsightsProductListings =
    (
      accountId: string,
      request: CustomInsightsProductsRequest,
      insightTypeGroup: SelectMetricsType,
      customInsightSubscriptionId?: string,
      transformFilters?: (filters: Filter[]) => Filter[]
    ) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<CustomInsightsProductsData>> => {
      try {
        if (transformFilters) {
          paginatedRequest.filters = transformFilters(paginatedRequest.filters);
        }

        if (
          request.merchantCountryIds.length === 0 ||
          ((!customInsightSubscriptionId ||
            customInsightSubscriptionId === '') &&
            checkIfCustomInsightRequestEmpty(request))
        )
          return Promise.resolve({
            items: [],
            totalItems: 0,
          });

        paginatedRequest.sorts = paginatedRequest.sorts.map((sort) => {
          const isMetricvalueColumn = [
            CustomInsightProductColumns.ProjectedDaysToStockout,
          ].includes(sort.column as CustomInsightProductColumns);

          const sortByDaysOfInventory =
            (insightTypeGroup !== SelectMetricsType.RATE_OF_SALE &&
              sort.column === CustomInsightProductColumns.Deviation) ||
            isMetricvalueColumn;

          const column = sortByDaysOfInventory
            ? CustomInsightProductColumns.ProjectedStockoutDate
            : sort.column;

          return { ...sort, column };
        });

        const allParams = getPaginationParamsFromRequest(paginatedRequest);
        const params = filterEmptyValues(allParams);

        const url = customInsightSubscriptionId
          ? PATHS.GET_CUSTOM_INSIGHTS_PRODUCTS_BY_SUBSCRIPTION_ID(
              accountId,
              customInsightSubscriptionId
            )
          : PATHS.GET_CUSTOM_INSIGHTS_PRODUCTS(accountId);

        const response = await axios.post(
          url,
          {
            ...request,
          },
          {
            ...config,
            params,
          }
        );
        setUpdatedAtDateInLocalStorage(response.data.lastUpdatedAt);
        return {
          items: response.data.elements,
          totalItems: response.data.totalElements,
        };
      } catch (e) {
        return Promise.reject({
          items: [],
          totalItems: 0,
        });
      }
    };

  const getCustomInsightSubscriptionDetails = async (
    accountId: string,
    customInsightSubscriptionId: string
  ): Promise<CustomInsightSubscriptionDetails> => {
    const response = axios.get<CustomInsightSubscriptionDetails>(
      PATHS.GET_CUSTOM_INSIGHT_SUBSCRIPTION_DETAILS(
        accountId,
        customInsightSubscriptionId
      ),
      {
        ...config,
      }
    );
    const res = await response;
    return res.data;
  };

  const getDemandForecastings = (
    accountId: string,
    request: DemandForecastRequest
  ) => {
    let controller = new AbortController();

    return async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<DemandForecastData>> => {
      if (request.merchantCountryIds.length) {
        if (controller) {
          controller.abort();
        }
        controller = new AbortController();
        const signal = controller.signal;

        const allParams = getPaginationParamsFromRequest(paginatedRequest);
        const params = filterEmptyValues(allParams);
        const response = await axios.post(
          PATHS.GET_DEMAND_FORECASTINGS(accountId),
          {
            ...request,
          },
          {
            ...config,
            params,
            signal: signal,
          }
        );
        return {
          items: response.data.elements,
          totalItems: response.data.filteredElements,
        };
      }
      return {
        items: [],
        totalItems: 0,
      };
    };
  };

  const exportDemandForecasts = async (
    accountId: string,
    request: DemandForecastRequest,
    filters: Filter[],
    sorts: Sort[]
  ): Promise<SKUCatalogExportResponse> => {
    const queryParams = filtersToQueryString(filters);
    const response = await axios.post<SKUCatalogExportResponse>(
      PATHS.EXPORT_DEMAND_FORECASTS(accountId),
      {
        ...request,
      },
      {
        ...config,
        params: {
          filter: queryParams,
          sort: generateSortQueryString(sorts),
        },
      }
    );
    return response.data;
  };

  const exportDemandForecastsStatus = async (
    accountId: string,
    requestId: string
  ): Promise<SKUCatalogExportStatusResponse> => {
    const response = await axios.get<SKUCatalogExportStatusResponse>(
      PATHS.EXPORT_DEMAND_FORECASTS_STATUS(accountId, requestId),
      {
        ...config,
      }
    );
    return response.data;
  };

  const getCampaignCreateRecommendation = async (
    accountId: string,
    request: UnadvertisedProductsRequest
  ): Promise<UnadvertisedProductsResponse[]> => {
    const response = await axios.post(
      PATHS.GET_CAMPAIGN_CREATE_RECOMMENDATION_DETAILS_UNADVERTISED(accountId),
      request,
      { ...config }
    );
    return response.data;
  };

  const getRecommendationNotSmartCampaign = async (
    accountId: string,
    request: UnadvertisedProductsRequest
  ): Promise<UnadvertisedProductsResponse[]> => {
    const response = await axios.post(
      PATHS.GET_CAMPAIGN_CREATE_RECOMMENDATION_DETAILS_NOT_SMART_CAMPAIGN(
        accountId
      ),
      request,
      { ...config }
    );
    return response.data;
  };

  const getOOSGraphData = async (
    accountId: string,
    request: InventoryDashboardOOSRequest
  ): Promise<OOSGraphDataAPIResponse> => {
    const response = await axios.post(
      PATHS.OOS_GRAPH_DATA(accountId),
      request,
      { ...config }
    );
    return response.data;
  };

  const getUnsoldInventoryDetailsData =
    (accountId: string, request: InventoryDashboardRequest) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<UnsoldInventoryDetailsData>> => {
      if (
        !request.merchantCountryDetails ||
        request.merchantCountryDetails.length === 0
      ) {
        return {
          items: [],
          totalItems: 0,
        };
      }
      const allParams = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(allParams);

      const response = await axios.post(
        PATHS.GET_UNSOLD_INVENTORY_DETAILS_DATA(accountId),
        {
          ...request,
        },
        {
          ...config,
          params,
        }
      );
      return {
        items: response.data.elements,
        totalItems: response.data.filteredElements,
      };
    };

  const exportUnsoldInventoryDetailsData = async (
    accountId: string,
    request: InventoryDashboardRequest,
    filters: Filter[],
    sorts: Sort[]
  ): Promise<SKUCatalogExportResponse> => {
    const queryParams = filtersToQueryString(filters);
    const response = await axios.post<SKUCatalogExportResponse>(
      PATHS.EXPORT_UNSOLD_INVENTORY_DETAILS_DATA(accountId),
      {
        ...request,
      },
      {
        ...config,
        params: {
          filter: queryParams,
          sort: generateSortQueryString(sorts),
        },
      }
    );
    return response.data;
  };

  const getActiveInventoryProducts =
    (accountId: string, request: InventoryDashboardRequest) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<ActiveInventoryData>> => {
      if (request.merchantCountryDetails.length === 0)
        return Promise.resolve({
          items: [],
          totalItems: 0,
        });

      const allParams = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(allParams);
      const response = await axios.post(
        PATHS.GET_ACTIVE_INVENTORY_PRODUCTS(accountId),
        {
          ...request,
        },
        {
          ...config,
          params,
        }
      );
      return {
        items: response.data.elements,
        totalItems: response.data.filteredElements,
      };
    };

  const exportActiveInventoryProductsData = async (
    accountId: string,
    request: InventoryDashboardRequest,
    filters: Filter[],
    sorts: Sort[]
  ): Promise<SKUCatalogExportResponse> => {
    const queryParams = filtersToQueryString(filters);
    const response = await axios.post<SKUCatalogExportResponse>(
      PATHS.EXPORT_ACTIVE_INVENTORY_PRODUCTS_DATA(accountId),
      {
        ...request,
      },
      {
        ...config,
        params: {
          filter: queryParams,
          sort: generateSortQueryString(sorts),
        },
      }
    );
    return response.data;
  };

  const getUnsoldInventorySummary = () => {
    let controller = new AbortController();
    return async (
      accountId: string,
      request: InventoryDashboardRequest
    ): Promise<UnsoldInventorySummaryResponse> => {
      if (checkMerchantsRequestIsValid(request.merchantCountryDetails)) {
        if (controller) {
          controller.abort();
        }
        controller = new AbortController();
        const signal = controller.signal;
        const response = await axios.post(
          PATHS.GET_UNSOLD_INVENTORY_SUMMARY_DETAILS(accountId),
          request,
          { ...config, signal }
        );
        return response.data;
      } else {
        return Promise.resolve({
          performanceAggregate: {},
          products: [],
          currency: 'USD',
        });
      }
    };
  };

  const getUnsoldInventoryPreviousSummary = async (
    accountId: string,
    request: InventoryDashboardRequest,
    signal?: AbortSignal,
    source?: CancelTokenSource
  ): Promise<UnsoldInventoryPreviousSummaryResponse> => {
    if (checkMerchantsRequestIsValid(request.merchantCountryDetails)) {
      const response = await axios.post(
        PATHS.GET_UNSOLD_INVENTORY_PREVIOUS_SUMMARY_DETAILS(accountId),
        request,
        { ...config, cancelToken: source?.token, signal: signal }
      );
      return response.data;
    } else {
      return Promise.resolve({
        value: {
          amount: 0,
          currency: 'USD',
        },
      });
    }
  };

  const getInventoryBreakdownValue = () => {
    let controller = new AbortController();
    return async (
      accountId: string,
      request: InventoryDashboardRequest
    ): Promise<InventoryBreakdownValueMetrics> => {
      if (controller) {
        controller.abort();
      }
      controller = new AbortController();
      const signal = controller.signal;
      // make sure all merchants have a salesChannelId and merchantCountryId present
      if (
        request.merchantCountryDetails.every(
          (merchant) =>
            !!merchant.salesChannelId && !!merchant.merchantCountryId
        )
      ) {
        const response = await axios.post(
          PATHS.GET_INVENTORY_BREAKDOWN_VALUE(accountId),
          request,
          { ...config, signal }
        );
        return response.data;
      }
      return {};
    };
  };

  const getTotalInventoryHealthValue = () => {
    let controller = new AbortController();
    return async (
      accountId: string,
      request: InventoryDashboardRequest
    ): Promise<TotalActiveInventoryValue> => {
      if (checkMerchantsRequestIsValid(request.merchantCountryDetails)) {
        if (controller) {
          controller.abort();
        }
        controller = new AbortController();
        const signal = controller.signal;
        const response = await axios.post(
          PATHS.GET_TOTAL_INVENTORY_HEALTH_VALUE(accountId),
          request,
          { ...config, signal }
        );
        return response.data;
      } else {
        return Promise.resolve({ inventoryValue: 0, currency: 'USD' });
      }
    };
  };

  const getSellThroughRateAndSalesRatio = () => {
    let controller = new AbortController();
    return async (
      accountId: string,
      request: InventoryDashboardRequest
    ): Promise<InventoryHealthMetricData> => {
      if (checkMerchantsRequestIsValid(request.merchantCountryDetails)) {
        if (controller) {
          controller.abort();
        }
        controller = new AbortController();
        const signal = controller.signal;
        const response = await axios.post(
          PATHS.GET_SELL_THROUGH_RATE_AND_INVENTORY_SALES_RATIO(accountId),
          request,
          { ...config, signal }
        );
        return response.data;
      } else {
        return Promise.resolve({
          sellThroughRateSummary: {},
          inventorySalesRatioSummary: {},
        });
      }
    };
  };

  const getOutOfStockProductsSummaryV2 = () => {
    let controller = new AbortController();
    return async (
      accountId: string,
      request: InventoryDashboardRequest
    ): Promise<OutOfStockProductsSummaryResponseV2> => {
      if (checkMerchantsRequestIsValid(request.merchantCountryDetails)) {
        if (controller) {
          controller.abort();
        }
        controller = new AbortController();
        const signal = controller.signal;
        const response = await axios.post(
          PATHS.GET_OUT_OF_STOCK_INVENTORY_SUMMARY_DETAILS(accountId),
          request,
          { ...config, signal }
        );
        return response.data;
      } else {
        return Promise.resolve({ performanceAggregate: {}, products: [] });
      }
    };
  };

  const exportInventoryDetailsProductsStatus = async (
    accountId: string,
    requestId: string
  ): Promise<SKUCatalogExportStatusResponse> => {
    const response = await axios.get<SKUCatalogExportStatusResponse>(
      PATHS.GET_INVENTORY_DETAILS_DATA_EXPORT_STATUS(accountId, requestId),
      {
        ...config,
      }
    );
    return response.data;
  };

  const getOutOfStockCounts = async (
    accountId: string,
    request: InventoryDashboardRequest
  ): Promise<InventoryOptimizationCountsResponse> => {
    if (request.merchantCountryDetails.length === 0) {
      return { counts: 0 };
    }
    const response = await axios.post<InventoryOptimizationCountsResponse>(
      PATHS.GET_OUT_OF_STOCK_COUNTS(accountId),
      request,
      {
        ...config,
      }
    );
    return response.data;
  };

  const getUnsoldInventoryCounts = async (
    accountId: string,
    request: InventoryDashboardRequest
  ): Promise<InventoryOptimizationCountsResponse> => {
    if (request.merchantCountryDetails.length === 0) {
      return { counts: 0 };
    }
    const response = await axios.post<InventoryOptimizationCountsResponse>(
      PATHS.GET_UNSOLD_INVENTORY_COUNTS(accountId),
      request,
      {
        ...config,
      }
    );
    return response.data;
  };

  const getAdvertisedLowInventoryCounts = async (
    accountId: string,
    request: InventoryDashboardRequest
  ): Promise<InventoryOptimizationCountsResponse> => {
    if (request.merchantCountryDetails.length === 0) {
      return { counts: 0 };
    }
    const response = await axios.post<InventoryOptimizationCountsResponse>(
      PATHS.GET_ADVERTISED_LOW_INVENTORY_COUNTS(accountId),
      request,
      {
        ...config,
      }
    );
    return response.data;
  };

  const getUnAdvertisedLowSellThroughRateCounts = async (
    accountId: string,
    request: InventoryDashboardRequest
  ): Promise<InventoryOptimizationCountsResponse> => {
    if (request.merchantCountryDetails.length === 0) {
      return { counts: 0 };
    }
    const response = await axios.post<InventoryOptimizationCountsResponse>(
      PATHS.GET_UN_ADVERTISED_LOW_SELL_THROUGH_RATE_COUNTS(accountId),
      request,
      {
        ...config,
      }
    );
    return response.data;
  };

  const getMultichannelCatalogPerformanceData =
    (accountId: string) =>
    async (
      request: MultichannelCatalogPerformanceDataRequest,
      controller?: AbortController,
      source?: CancelTokenSource
    ): Promise<MultichannelCatalogResponse> => {
      const url = PATHS.MULTI_CHANNEL_CATALOG_PERFORMANCE_DATA(accountId);
      const response = await axios.post<MultichannelCatalogResponse>(
        url,
        request,
        {
          ...config,
          cancelToken: source?.token,
          signal: controller?.signal,
        }
      );
      return response.data;
    };
  const getAdvertisingPenetrationMultiChannelDataSnapshot = async (
    accountId: string,
    request: MultiChannelAdvertisingPenetrationDataSnapshotRequest,
    controller?: AbortController,
    source?: CancelTokenSource
  ): Promise<MultichannelAdvertisingPenetrationProductDataResponse> => {
    const { data } = await axios.post(
      PATHS.MULTI_CHANNEL_ADVERTISING_PENETRATION_DATA_SNAPSHOT(accountId),
      request,
      { ...config, cancelToken: source?.token, signal: controller?.signal }
    );
    return data;
  };

  const exportMultichannelCatalogPerformanceData = async (
    accountId: string,
    request: MultichannelCatalogPerformanceDataRequest
  ): Promise<AxiosResponse<Blob>> => {
    const exportUrl =
      PATHS.MULTI_CHANNEL_CATALOG_PERFORMANCE_DATA_EXPORT(accountId);

    const response = await axios.post(
      exportUrl,
      {
        ...request,
      },
      {
        ...config,
      }
    );

    return response;
  };

  const getSlideoutProductDetails = async (
    accountId: string,
    request: SlideoutProductDetailsRequest
  ): Promise<SlideoutProductDetails> => {
    const { data } = await axios.post<SlideoutProductDetails>(
      PATHS.GET_SLIDEOUT_PRODUCT_DETAILS(accountId),
      request,
      { ...config }
    );
    return data;
  };

  const getSlideoutProductGraphData = async (
    accountId: string,
    request: SlideoutProductGraphDataRequest
  ): Promise<SlideoutProductsGraphDataResponse> => {
    const { data } = await axios.post<SlideoutProductsGraphDataResponse>(
      PATHS.GET_SLIDEOUT_PRODUCT_GRAPH_DATA(accountId),
      request,
      { ...config }
    );
    return data;
  };

  const exportSlideoutProductGraphData = async (
    accountId: string,
    request: SlideoutProductGraphDataRequest
  ): Promise<AxiosResponse<Blob>> => {
    return axios.post(
      PATHS.EXPORT_SLIDEOUT_PRODUCT_GRAPH_DATA(accountId),
      request,
      { ...config, responseType: 'blob' }
    );
  };

  return {
    getDataAvailabilityInfo,
    getSkuCatalogDetails,
    getSkuCatalogParentChildData: getSkuCatalogDetailsChildren,
    getSkuCatalogParentChildDataV3: getSkuCatalogDetailsChildrenV3,
    getSkuCatalogDetailsIndividual,
    getSkuCatalogSummary,
    getSkuCatalogParentChildSummary,
    getSkuCatalogCustomGroupingSummary,
    getSkuCatalogIndividualSummary,
    exportSkuCatalog,
    manageCogsUpload,
    manageCogsUploadStatus,
    exportCogsTemplateV2,
    exportCogsTemplateV2Status,
    getSkuOverviewDetails,
    getSkuOverviewSummary,
    getProductDetailsSummary,
    getProductDetailsSummaryV2,
    getProductGroupIds,
    manageCogsGetS3SecureUrl,
    manageCogsS3FileUpload,
    getMissingCogs,
    exportCompassBusinessPerformanceCsvData,
    exportProductMetadataTemplate,
    exportProductMetadataTemplateStatus,
    getProductMetadataTemplateS3DownloadUrl,
    downloadS3File,
    productMetadataGetS3SecureUrl,
    productMetadataS3FileUpload,
    productMetadataUpload,
    productMetadataUploadStatus,
    exportSkuStatus,
    downloadSkuCatalogExportFile,
    getSKUPerformanceMetricsDataPoints,
    getSkuCatalogDetailsV2,
    getSkuDetailsV2Summary,
    getSkuCatalogGmroi,
    getProductCatalogEvents,
    exportProductCatalogEvents,
    createProductCatalogEvent,
    updateProductCatalogEvent,
    deleteProductCatalogEvent,
    getProductCatalogGraphEvents,
    getProductGroups,
    createProductGroup,
    updateProductGroup,
    removeProductFromGroup,
    deleteProductGroup,
    customGroupNameUpdate,
    getRelatedCampaignAndAdGroupData,
    getEventConfigs,
    getOutOfStockProducts,
    getOutOfStockProductsV2,
    exportOutOfStockProducts,
    exportOutOfStockProductsStatus,
    getActiveProductStock,
    getInventoryValueInsights,
    getOutOfStockProductsSummary,
    getEstimatedMissedSalesGraphPoints,
    createCustomInsightSubscription,
    updateCustomInsights,
    deleteCustomInsight,
    exportCustomInsights,
    getCustomInsights,
    getCustomInsightProductsForDashboard,
    getCustomInsightsProductListings,
    getCustomInsightSubscriptionDetails,
    getDemandForecastings,
    exportDemandForecasts,
    exportDemandForecastsStatus,
    getCampaignCreateRecommendation,
    getActiveInventoryProducts,
    exportActiveInventoryProductsData,
    getRecommendationNotSmartCampaign,
    getUnsoldInventoryDetailsData,
    exportUnsoldInventoryDetailsData,
    getUnsoldInventorySummary,
    getUnsoldInventoryPreviousSummary,
    getInventoryBreakdownValue,
    getTotalInventoryHealthValue,
    getSellThroughRateAndSalesRatio,
    getOOSGraphData,
    getOutOfStockProductsSummaryV2,
    exportInventoryDetailsProductsStatus,
    getOutOfStockCounts,
    getUnsoldInventoryCounts,
    getAdvertisedLowInventoryCounts,
    getUnAdvertisedLowSellThroughRateCounts,
    getMultichannelCatalogPerformanceData,
    getAdvertisingPenetrationMultiChannelDataSnapshot,
    exportMultichannelCatalogPerformanceData,
    getSlideoutProductDetails,
    getSlideoutProductGraphData,
    exportSlideoutProductGraphData,
  };
};
