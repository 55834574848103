import { FormattedMessage, IntlShape } from 'react-intl';
import {
  INVENTORY_DASHBOARD_EM_DASH_VALUE,
  InventoryDashboardMetrics,
} from './types';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  Alignment,
  BellIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  DownloadIcon,
  FormToggleState,
  Formula,
  FormulaType,
  MetricCardV2CardState,
  MetricCardV2CardTrend,
  MetricCardV2Status,
  Placement,
  TextLink,
  TextLinkSize,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import {
  MerchantCountryWithConnectionStatus,
  Metric,
  MetricType,
} from '../../../products/containers/skuCatalog/types';
import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';
import { convertAmountToNumber } from '../../../products/containers/utils';
import { DateTime } from 'luxon';
import React from 'react';
import classNames from 'classnames';
import {
  MONETARY_FRACTION_DIGITS,
  PERCENTAGE_FRACTION_DIGITS,
} from '../../../../lib/types/CommonSharedTypes';

export const OUT_OF_STOCK_INVENTORTY_DATA_TABLE_ID =
  'out_of_stock_inventory_data_table';

export const INVENTORY_DASHBOARD_API_REQUEST_DATE_FORMAT = 'yyyy-MM-dd';

export const exportButton = (
  intl: IntlShape,
  exportButtonState: ButtonState,
  exportFn: () => void,
  dataTestId?: string
) => {
  return (
    <Tooltip
      content={intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_EXPORT_DATA_LABEL,
      })}
      theme={Theme.Dark}
      position={{
        placement: Placement.Left,
        alignment: Alignment.Center,
        placementOffsetInPixels: 10,
      }}
      delayShow={300}
    >
      <Button
        svgIcon={DownloadIcon}
        size={ButtonSize.Medium}
        state={exportButtonState}
        onClick={() => exportFn()}
        variant={ButtonVariant.BlackAndWhiteBorder}
        dataTestId={`${dataTestId}_export_btn`}
        className="h-32 w-32"
      />
    </Tooltip>
  );
};

export const getManageAlertsButton = (
  intl: IntlShape,
  toggleState: FormToggleState,
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void,
  dataTestId: string,
  showSmallInlineIcon?: boolean,
  disabled?: boolean
) => {
  const isBellHighlighted = toggleState === FormToggleState.On;
  return (
    <Tooltip
      content={intl.formatMessage({
        id: I18nKey.GENERIC_MANAGE_ALERTS,
      })}
      theme={Theme.Dark}
      position={{
        placement: Placement.Left,
        alignment: Alignment.Center,
        placementOffsetInPixels: 10,
      }}
      delayShow={300}
      disabled={disabled}
    >
      <Button
        svgIcon={BellIcon}
        size={
          showSmallInlineIcon
            ? ButtonSize.InlineIcon
            : ButtonSize.InlineIconMedium
        }
        state={disabled ? ButtonState.Disabled : ButtonState.Active}
        onClick={onClick}
        variant={
          showSmallInlineIcon
            ? ButtonVariant.BlackAndWhite
            : ButtonVariant.BlackAndWhiteBorder
        }
        dataTestId={`${dataTestId}_manage_alerts`}
        className={classNames({
          'bg-yellow-200 ': isBellHighlighted,
          'mr-8': showSmallInlineIcon,
          'hover:bg-yellow-200 focus:bg-yellow-200 focus:ring-yellow-200':
            !disabled,
        })}
        iconClassName={classNames('focus:bg-yellow-200 focus:ring-yellow-200', {
          'text-grey-600': !isBellHighlighted || disabled,
          'text-yellow-700': isBellHighlighted && !disabled,
        })}
      />
    </Tooltip>
  );
};

export const getInfoTooltipProps = (
  metricId: InventoryDashboardMetrics
): TooltipProps => ({
  showOnClick: true,
  position: {
    placement: Placement.Bottom,
    alignment: Alignment.Center,
    placementOffsetInPixels: 8,
  },
  theme: Theme.Dark,
  tooltipContentHoverable: true,
  content: getInfoTooltipContent(metricId),
});

export const getTooltipHelpCenterLinks = (
  metricId?: InventoryDashboardMetrics
) => {
  switch (metricId) {
    case InventoryDashboardMetrics.InventorySalesRatio:
    case InventoryDashboardMetrics.TotalInventoryValue:
    case InventoryDashboardMetrics.SellThroughRate:
      return 'https://intercom.help/flywheel-20/en/articles/8618423-inventory-dashboard-inventory-health-overview';
    case InventoryDashboardMetrics.UnsoldInventory:
      return 'https://intercom.help/flywheel-20/en/articles/8618450-inventory-unsold-inventory-in-l90-days-excess-stock';
    case InventoryDashboardMetrics.EstMissedSales:
      return 'https://intercom.help/flywheel-20/en/articles/8618441-inventory-out-of-stock-estimated-missed-sales';
    case InventoryDashboardMetrics.InventoryAge:
      return 'https://intercom.help/flywheel-20/en/articles/8618423-inventory-dashboard-inventory-health-overview';
    default:
      return 'https://intercom.help/flywheel-20/en/articles/8613831-inventory-optimization-overview';
  }
};

export const getInfoTooltipContent = (metricId: InventoryDashboardMetrics) => {
  switch (metricId) {
    case InventoryDashboardMetrics.TotalInventoryValue:
      return (
        <>
          <div className="leading-tight">
            <FormattedMessage
              id={
                I18nKey.INVENTORY_DASHBOARD_METRIC_CARD_TOTAL_ACTIVE_INVENTORY_VALUE_DEFINITION
              }
            />
          </div>
          <div className="mt-8 flex flex-wrap rounded py-4 px-8 bg-grey-800">
            <FormattedMessage
              id={
                I18nKey.INVENTORY_DASHBOARD_METRIC_CARD_TOTAL_ACTIVE_INVENTORY_VALUE_FORMULA
              }
              values={{
                multiply: <Formula type={FormulaType.Multiply} />,
              }}
            />
          </div>
        </>
      );
    case InventoryDashboardMetrics.SellThroughRate:
      return (
        <>
          <div className="leading-tight">
            <FormattedMessage
              id={
                I18nKey.INVENTORY_DASHBOARD_METRIC_CARD_SELL_THROUGH_RATE_DEFINITION
              }
            />
          </div>
          <div className="mt-8 flex justify-center flex-wrap rounded py-4 px-8 bg-grey-800">
            <TextLink
              textLabel={<FormattedMessage id={I18nKey.LEARN_MORE} />}
              className="text-white"
              openNewTab
              href={getTooltipHelpCenterLinks(metricId)}
            />
          </div>
        </>
      );
    case InventoryDashboardMetrics.InventorySalesRatio:
      return (
        <>
          <div className="leading-tight">
            <FormattedMessage
              id={
                I18nKey.INVENTORY_DASHBOARD_METRIC_CARD_INVENTORY_SALES_RATIO_DEFINITION
              }
            />
          </div>
          <div className="mt-8 flex justify-center flex-wrap rounded py-4 px-8 bg-grey-800">
            <TextLink
              textLabel={<FormattedMessage id={I18nKey.LEARN_MORE} />}
              className="text-white"
              openNewTab
              href={getTooltipHelpCenterLinks(metricId)}
            />
          </div>
        </>
      );
    case InventoryDashboardMetrics.UnsoldInventory:
      return (
        <>
          <div className="leading-tight">
            <FormattedMessage
              id={
                I18nKey.INVENTORY_DASHBOARD_METRIC_CARD_UNSOLD_INVENTORY_DEFINITION
              }
            />
          </div>
          <div className="mt-8 flex justify-center flex-wrap rounded py-4 px-8 bg-grey-800">
            <TextLink
              textLabel={<FormattedMessage id={I18nKey.LEARN_MORE} />}
              className="text-white"
              openNewTab
              href={getTooltipHelpCenterLinks(metricId)}
            />
          </div>
        </>
      );
    case InventoryDashboardMetrics.EstMissedSales:
      return (
        <>
          <div className="leading-tight">
            <FormattedMessage
              id={
                I18nKey.INVENTORY_DASHBOARD_METRIC_CARD_EST_MISSED_SALES_TOOLTIP_DEFINITION
              }
            />
          </div>
          <div className="mt-8 flex justify-center flex-wrap rounded py-4 px-8 bg-grey-800">
            <TextLink
              textLabel={<FormattedMessage id={I18nKey.LEARN_MORE} />}
              className="text-white"
              openNewTab
              href={getTooltipHelpCenterLinks(metricId)}
            />
          </div>
        </>
      );
    case InventoryDashboardMetrics.InventoryAge:
      return (
        <>
          <div className="leading-tight">
            <FormattedMessage
              id={I18nKey.INVENTORY_DASHBOARD_INVENTORY_AGE_TOOLTIP}
            />
          </div>
          <div className="mt-8 flex justify-center flex-wrap rounded py-4 px-8 bg-grey-800">
            <TextLink
              textLabel={<FormattedMessage id={I18nKey.LEARN_MORE} />}
              className="text-white"
              openNewTab
              href={getTooltipHelpCenterLinks(metricId)}
            />
          </div>
        </>
      );
  }
};

export const getPillText = (intl: IntlShape, delta: number): string => {
  return `${intl.formatNumber(delta, {
    style: 'percent',
    minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
    maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
    signDisplay: 'exceptZero',
  })}`;
};

export const getStatus = (delta: number): MetricCardV2Status => {
  if (isNaN(delta)) {
    return MetricCardV2Status.Negative;
  }
  if (delta === 0) {
    return MetricCardV2Status.Neutral;
  }
  return delta < 0 ? MetricCardV2Status.Negative : MetricCardV2Status.Positive;
};

const getMetricType = (metricId: InventoryDashboardMetrics) => {
  switch (metricId) {
    case InventoryDashboardMetrics.EstMissedSales:
    case InventoryDashboardMetrics.TotalInventoryValue:
    case InventoryDashboardMetrics.UnsoldInventory:
      return MetricType.Money;
    case InventoryDashboardMetrics.SellThroughRate:
      return MetricType.Percent;
    case InventoryDashboardMetrics.InventorySalesRatio:
    default:
      return MetricType.Numeric;
  }
};

export const calculateDelta = (
  metricId: InventoryDashboardMetrics,
  currentValue?: Metric,
  previousValue?: Metric
): number => {
  const metricType = getMetricType(metricId);

  if (isNil(previousValue) || isNil(currentValue)) return 0;
  let currentNumericValue = -1;
  let previousNumericValue = -1;
  if (metricType !== MetricType.Money) {
    currentNumericValue = currentValue as number;
    previousNumericValue = previousValue as number;
  }

  switch (metricType) {
    case MetricType.Money:
      const previousAmount = convertAmountToNumber(previousValue);
      const divideBy = previousAmount === 0 ? 1 : previousAmount;

      return (convertAmountToNumber(currentValue) - previousAmount) / divideBy;
    case MetricType.Percent:
      return (
        (Number(currentNumericValue * 100) -
          Number(previousNumericValue * 100)) /
        (Number(previousNumericValue * 100) === 0
          ? 1
          : Number(previousNumericValue * 100))
      );
    case MetricType.Numeric:
    default:
      return (
        (Number(currentNumericValue) - Number(previousNumericValue)) /
        (Number(previousNumericValue) === 0 ? 1 : Number(previousNumericValue))
      );
  }
};

export const getCardFooterProps = (
  intl: IntlShape,
  metricId: InventoryDashboardMetrics,
  currentValue?: Metric,
  previousValue?: Metric
): MetricCardV2CardTrend => {
  if (isNil(previousValue)) {
    return {
      pillText: undefined,
      status: undefined,
    };
  }
  const delta = calculateDelta(metricId, currentValue, previousValue);

  if (isNaN(delta)) {
    return {
      pillText: undefined,
      status: undefined,
    };
  }

  return {
    pillText: getPillText(intl, delta),
    status: getStatus(delta),
  };
};

export const getFormattedSellThroughRate = (intl: IntlShape, value: string) =>
  value === INVENTORY_DASHBOARD_EM_DASH_VALUE
    ? INVENTORY_DASHBOARD_EM_DASH_VALUE
    : intl.formatNumber(Number(value), {
        style: 'percent',
        minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      });

export const getFormattedInventorySalesRatio = (
  intl: IntlShape,
  value: string
) =>
  value === INVENTORY_DASHBOARD_EM_DASH_VALUE
    ? INVENTORY_DASHBOARD_EM_DASH_VALUE
    : intl.formatNumber(Number(value), {
        style: 'decimal',
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      });

export const getMetricCardState = (loading: boolean) =>
  loading ? MetricCardV2CardState.Loading : MetricCardV2CardState.Success;

export const getLast30DaysDateRange = (date?: string) => {
  // Get the current date and the date 30 days ago
  const now = date
    ? DateTime.fromISO(date).minus({ days: 1 })
    : DateTime.now().minus({ days: 1 });
  const thirtyDaysAgo = now.minus({ days: 29 });

  // Format the dates
  const formattedNow = now.toFormat('MMM d, yyyy');
  const formattedThirtyDaysAgo = thirtyDaysAgo.toFormat('MMM d');

  // Return the formatted date range
  return `${formattedThirtyDaysAgo} – ${formattedNow}`;
};

export const getPriorOOSTooltip = (intl: IntlShape, outOfStockDate: string) => {
  return (
    <div className="flex flex-col items-center text-sm leading-tight text-white">
      <span className="font-normal">
        {intl.formatMessage({
          id: I18nKey.INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_TOOLTIP_LABEL,
        })}
      </span>
      <span className="font-medium">
        {getLast30DaysDateRange(outOfStockDate)}
      </span>
    </div>
  );
};

export const getRateOfSaleTooLowTooltip = (
  isROSUnavailable: boolean,
  onLearnMoreClick?: () => void
) => {
  return (
    <>
      <div className="leading-tight break-words">
        <FormattedMessage
          id={
            isROSUnavailable
              ? I18nKey.INVENTORY_DASHBOARD_RATE_OF_SALE_UNAVAILABLE
              : I18nKey.INVENTORY_DASHBOARD_RATE_OF_SALE_TOO_LOW
          }
        />
      </div>
      <div className="mt-8 flex justify-center flex-wrap rounded py-4 px-8 bg-grey-800">
        <TextLink
          dataTestId="rateOfSale"
          textLabel={<FormattedMessage id={I18nKey.LEARN_MORE} />}
          className="text-white"
          onClick={onLearnMoreClick}
        />
      </div>
    </>
  );
};

export const getEstMissedSalesCardTooltip = () => {
  return (
    <>
      <div className="leading-tight">
        <FormattedMessage
          id={
            I18nKey.INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_EST_MISSED_SALES_CARD_TOOLTIP
          }
        />
      </div>
      <div className="mt-8 flex flex-wrap rounded py-4 px-8 bg-grey-800">
        <FormattedMessage
          id={
            I18nKey.INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_EST_MISSED_SALES_CARD_FORMULA
          }
          values={{
            multiply: <Formula type={FormulaType.Multiply} />,
          }}
        />
      </div>
    </>
  );
};

export const getNumberOrElseUndefined = (metric: string) =>
  metric === '-' ? undefined : Number(metric);

export const sortMerchantsByName = (
  mc1: MerchantCountryWithConnectionStatus,
  mc2: MerchantCountryWithConnectionStatus
) => {
  const mc1Name = mc1.merchantName.toLowerCase();
  const mc2Name = mc2.merchantName.toLowerCase();

  if (mc1Name < mc2Name) {
    return -1;
  }
  if (mc1Name > mc2Name) {
    return 1;
  }
  return 0;
};

export const getInventoryAgingTooltip = (
  onLearnMoreClick?: () => void,
  value?: number,
  showOnDashboard?: boolean
) => {
  return !isNil(value) && value > 0 ? (
    <>
      <Typography
        size={TypographySize.sm}
        lineHeight={TypographyLineHeight.normal}
        weight={TypographyWeight.regular}
      >
        <FormattedMessage
          id={
            showOnDashboard
              ? I18nKey.INVENTORY_DASHBOARD_INVENTORY_AGE_BREAKDOWN_TOOLTIP
              : I18nKey.INVENTORY_DASHBOARD_INVENTORY_AGE_TOOLTIP
          }
        />
      </Typography>
      <div className="mt-8 flex justify-center flex-wrap rounded py-4 px-8 bg-grey-800">
        <TextLink
          textLabel={<FormattedMessage id={I18nKey.LEARN_MORE} />}
          size={TextLinkSize.Small}
          className="text-white"
          dataTestId={'inventory_age_tooltip'}
          onClick={onLearnMoreClick}
        />
      </div>
    </>
  ) : undefined;
};
