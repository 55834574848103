import {
  Badge,
  BadgeDisplayMode,
  BadgeType,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React from 'react';
import { CampaignsAdGroupsSelectOptionProps } from '.';
import { getCampaignStatusMarkerColor } from './CampaignsDisplayLabel';
import { CampaignTargetingType } from '../../../lib/types/AOSharedTypes';

export const CampaignsSelectOption: React.FC<
  CampaignsAdGroupsSelectOptionProps & {
    readonly isLast: boolean;
  }
> = (props) => {
  return (
    <div
      className={classNames(
        'flex w-full pl-24 px-12 py-8 border-t first:border-t-0 border-solid border-grey-200 gap-12 bg-white hover:bg-grey-50 justify-between',
        {
          'rounded-b-lg': props.isLast,
        }
      )}
      onClick={() => props.onChange && props.onChange(props.campaignId)}
    >
      <div className="flex items-center gap-8 pl-24 py-16">
        <Badge
          badgeType={
            props.targetingType === CampaignTargetingType.Manual
              ? BadgeType.CampaignTypeManual
              : BadgeType.CampaignTypeAuto
          }
          badgeNameDisplayMode={BadgeDisplayMode.OnlyInitial}
        />
        <Typography
          lineHeight={TypographyLineHeight.none}
          size={TypographySize.lg}
          weight={
            props.selected
              ? TypographyWeight.semibold
              : TypographyWeight.regular
          }
        >
          {props.campaignName}
        </Typography>
      </div>
      <div className="flex items-center gap-8 w-152">
        <div
          className={classNames(
            'inline-block p-4 m-4 rounded-full',
            getCampaignStatusMarkerColor(props.status)
          )}
        ></div>
        <Typography
          lineHeight={TypographyLineHeight.none}
          size={TypographySize.base}
          weight={TypographyWeight.regular}
          className="capitalize"
        >
          {props.statusString}
        </Typography>
      </div>
    </div>
  );
};
