import classNames from 'classnames';
import { fromNullable } from 'fp-ts/lib/Option';
import React from 'react';

import Row from './row';
import { ColumnDataForRow, SubRows as SubRowsType } from './types';
import {
  AsyncRequest,
  AsyncRequestKinds,
} from '../../lib/utilities/asyncRequest';

interface SubRowsProps<SubRowDataType, SubRowChildRowDataType, TableDataType> {
  readonly columns: Array<ColumnDataForRow<SubRowDataType, TableDataType>>;
  readonly data: AsyncRequest<SubRowDataType[]>;
  readonly subRowChildRows?: SubRowsType<
    SubRowDataType,
    SubRowChildRowDataType,
    TableDataType
  >;
  readonly maybeLoadingComponent?: React.FC;
  readonly maybeErrorComponent?: React.FC;
  readonly tableData?: TableDataType;
}

function SubRows<SubRowDataType, SubRowChildRowDataType, TableDataType>(
  props: SubRowsProps<SubRowDataType, SubRowChildRowDataType, TableDataType>
) {
  const hasChildrenRow = props.subRowChildRows !== undefined;
  const className = `grid-table__subrow-cell-container-${
    hasChildrenRow ? 'parent' : 'child'
  }`;

  switch (props.data.kind) {
    case AsyncRequestKinds.Loading:
    case AsyncRequestKinds.NotStarted:
      return fromNullable<React.FC>(props.maybeLoadingComponent)
        .map((loadingComponent) => (
          <div
            key="grid-table__sub-row-loading"
            className={classNames([className, 'grid-table__full-width-column'])}
          >
            {loadingComponent({})}
          </div>
        ))
        .getOrElse(<></>);
    case AsyncRequestKinds.Completed:
      return (
        <>
          {props.data.result.map((subRow, subRowIdx) => (
            <React.Fragment key={`${className}-${subRowIdx}`}>
              <Row
                columns={props.columns}
                rowData={subRow}
                tableData={props.tableData}
                cellContainerClassName={className}
              />
              {props.subRowChildRows && (
                <SubRowsContainer
                  key="subrow-children"
                  tableData={props.tableData}
                  parentRowData={subRow}
                  subRows={props.subRowChildRows}
                />
              )}
            </React.Fragment>
          ))}
        </>
      );
    case AsyncRequestKinds.Failed:
      return fromNullable<React.FC>(props.maybeErrorComponent)
        .map((errorComponent) => (
          <div
            key="grid-table__sub-row-error"
            className={classNames([className, 'grid-table__full-width-column'])}
          >
            {errorComponent({})}
          </div>
        ))
        .getOrElse(<></>);
    default:
      return <></>;
  }
}

interface SubRowsContainerProps<
  ParentRowDataType,
  SubRowDataType,
  SubRowChildRowDataType,
  TableDataType
> {
  readonly tableData?: TableDataType;
  readonly parentRowData: ParentRowDataType;
  readonly subRows: SubRowsType<
    ParentRowDataType,
    SubRowDataType,
    TableDataType
  >;
  readonly subRowChildRows?: SubRowsType<
    SubRowDataType,
    SubRowChildRowDataType,
    TableDataType
  >;
}

function SubRowsContainer<
  ParentRowDataType,
  SubRowDataType,
  SubRowChildRowDataType,
  TableDataType
>(
  props: SubRowsContainerProps<
    ParentRowDataType,
    SubRowDataType,
    SubRowChildRowDataType,
    TableDataType
  >
) {
  const subRowData = props.subRows.dataResolver(
    props.parentRowData,
    props.tableData
  );
  const subRowsAreVisible = props.subRows.areSubRowsVisible(
    props.parentRowData,
    props.tableData
  );
  const hasHeaders = props.subRows.columns.some(
    (c) => c.HeaderCellElement !== undefined
  );

  return (
    <>
      {subRowsAreVisible && (
        <>
          {hasHeaders && (
            <Row
              columns={props.subRows.columns.map((c) => ({
                columnComponent: c.HeaderCellElement,
                columnClassName: c.className,
              }))}
              cellContainerClassName="grid-table__subheader-cell-container"
              rowData={{}}
              tableData={props.tableData}
            />
          )}
          <SubRows
            columns={props.subRows.columns.map((c) => ({
              columnComponent: c.RowCellElement,
              columnClassName: c.className,
              onCellClick: c.onCellClick,
            }))}
            data={subRowData}
            tableData={props.tableData}
            subRowChildRows={props.subRowChildRows}
            maybeLoadingComponent={props.subRows.maybeLoadingComponent}
            maybeErrorComponent={props.subRows.maybeErrorComponent}
          />
        </>
      )}
    </>
  );
}

export default SubRowsContainer;
