import {
  CycleIcon,
  Icon,
  IconSize,
  SearchIcon,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../lib/types/I18nKey';

export enum FetchingDataType {
  search = 'search',
  load = 'load',
}

export interface FetchingDataProps {
  readonly type: FetchingDataType;
}

const MAP_TYPE_TO_ICON = {
  [FetchingDataType.search]: SearchIcon,
  [FetchingDataType.load]: CycleIcon,
};

export const FetchingData: React.FC<FetchingDataProps> = ({ type }) => {
  const intl = useIntl();

  const [
    SEARCHING_LABEL,
    SEARCHING_DESCRIPTION,
    LOADING_LABEL,
    LOADING_DESCRIPTION,
  ] = [
    I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_SEARCHING_TEXT,
    I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_SEARCHING_DESCRIPTION,
    I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_LOADING_TEXT,
    I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_LOADING_DESCRIPTION,
  ].map((id) =>
    intl.formatMessage({
      id,
    })
  );

  const LABEL =
    type === FetchingDataType.search ? SEARCHING_LABEL : LOADING_LABEL;
  const DESCRIPTION =
    type === FetchingDataType.search
      ? SEARCHING_DESCRIPTION
      : LOADING_DESCRIPTION;

  return (
    <div className="flex flex-col justify-center items-center gap-8 h-full">
      <div className="w-56 h-56 bg-grey-100 rounded-full flex justify-center items-center">
        <Icon
          size={IconSize.Large}
          svg={MAP_TYPE_TO_ICON[type]}
          className="text-grey-500"
        />
      </div>
      <Typography
        size={TypographySize.base}
        weight={TypographyWeight.semibold}
        lineHeight={TypographyLineHeight.normal}
        className="text-grey-900"
      >
        {LABEL}
      </Typography>
      <Typography
        size={TypographySize.base}
        weight={TypographyWeight.regular}
        lineHeight={TypographyLineHeight.normal}
        className="text-grey-500"
      >
        {DESCRIPTION}
      </Typography>
    </div>
  );
};

FetchingData.displayName = 'FetchingData';
