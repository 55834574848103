import {
  AlertTriangleIcon,
  Button,
  ButtonSize,
  ButtonVariant,
  CrossMiniIcon,
  DotsCircleIcon,
  Icon,
  IconSize,
  SparkleIcon,
  TagLinearIcon,
} from '@teikametrics/tm-design-system';
import { MATCH_TYPE_I18N_MAPPER } from '../../utils/keywordUtils';
import React from 'react';
import {
  MatchType,
  TargetInfo,
  TargetSegment,
} from '../../../../../../lib/types/CampaignFlexibilitySharedTypes';
import classNames from 'classnames';
import { IntlShape, useIntl } from 'react-intl';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { EmptyState } from './emptyState';
import { KeywordsRequestState } from './createChatGPTKeywordsProvider';

const keywordsEmptyState = (
  keywordsState: KeywordsRequestState,
  intl: IntlShape
) => {
  const [
    GENERATING_KEYWORDS_PRIMARY,
    GENERATING_KEYWORDS_SECONDARY,
    KEYWORDS_EMPTY_MESSAGE,
    SOMETHING_WENT_WRONG,
    REQUEST_FAILED_MESSAGE,
    NO_KEYWORD_FOUND_HEADER,
    NO_KEYWORD_FOUND_MESSAGE,
  ] = [
    I18nKey.CREATE_KEYWORDS_MODAL_GENERATING_KEYWORDS_PRIMARY,
    I18nKey.CREATE_KEYWORDS_MODAL_GENERATING_KEYWORDS_SECONDARY,
    I18nKey.CREATE_KEYWORDS_MODAL_KEYWORDS_EMPTY_MESSAGE,
    I18nKey.COGS_MODAL_STEP_1_DOWNLOAD_HEADLINE,
    I18nKey.CREATE_KEYWORDS_CHATGPT_MODAL_REQUEST_FAILED_MESSAGE,
    I18nKey.CREATE_KEYWORDS_CHATGPT_MODAL_REQUEST_NO_KEYWORD_FOUND_HEADER,
    I18nKey.CREATE_KEYWORDS_CHATGPT_MODAL_REQUEST_NO_KEYWORD_FOUND_MESSAGE,
  ].map((id) =>
    intl.formatMessage(
      { id },
      {
        br: <br />,
      }
    )
  );

  if (keywordsState === KeywordsRequestState.Loading) {
    return (
      <EmptyState
        title={GENERATING_KEYWORDS_PRIMARY as string}
        description={GENERATING_KEYWORDS_SECONDARY as string}
        icon={DotsCircleIcon}
      />
    );
  } else if (keywordsState === KeywordsRequestState.Failed) {
    return (
      <EmptyState
        title={SOMETHING_WENT_WRONG as string}
        description={REQUEST_FAILED_MESSAGE as string}
        iconClassName="text-red-500"
        iconContainerClassName="bg-orange-200"
        icon={AlertTriangleIcon}
      />
    );
  } else if (keywordsState === KeywordsRequestState.NoResult) {
    return (
      <EmptyState
        title={NO_KEYWORD_FOUND_HEADER as string}
        description={NO_KEYWORD_FOUND_MESSAGE as string}
        iconContainerClassName="bg-blue-100"
        icon={TagLinearIcon}
      />
    );
  }
  return (
    <EmptyState title={KEYWORDS_EMPTY_MESSAGE as string} icon={SparkleIcon} />
  );
};
export interface KeywordsListProps {
  readonly targetSegments: TargetSegment[];
  readonly keywords: TargetInfo[];
  readonly keywordsRequestState: KeywordsRequestState;
  readonly onRemoveKeyword: (removeAll: boolean, targetId?: string) => void;
  readonly onFeedbackClick: () => void;
}

export const KeywordsList: React.FC<KeywordsListProps> = ({
  targetSegments,
  keywords,
  onRemoveKeyword,
  keywordsRequestState,
  onFeedbackClick,
}) => {
  const intl = useIntl();

  return (
    <div className="border border-solid border-grey-300 w-1/2 flex flex-1 flex-col">
      <div
        className={classNames(
          'border-t border-solid border-grey-200',
          'flex flex-row py-12 pl-16 items-center gap-8 bg-grey-50'
        )}
      >
        <span className="text-base leading-normal font-medium text-grey-700">
          {intl.formatMessage({
            id: I18nKey.KEYWORD_RECOMMENDATIONS_VIEW_SKUS_KEYWORDS_SLIDEOUT_KEYWORDS,
          })}
        </span>
        <div className="h-20 px-8 flex flex-row items-center justify-center bg-purple-100 rounded-20">
          <span className="text-sm text-purple-600 leading-none font-normal">
            {keywords.length}
          </span>
        </div>
        <span className="flex-auto"></span>
        <Button
          className="self-end bg-transparent underline custom-underline-px-grey-400 hover:custom-underline-purple-700 focus:custom-underline-purple-700"
          label={intl.formatMessage({
            id: I18nKey.DESIGN_SYSTEM_COMPONENT_FILTER_ROW_REMOVE_ALL_BUTTON_LABEL,
          })}
          onClick={() => onRemoveKeyword(true)}
          size={ButtonSize.Small}
          variant={ButtonVariant.ActionWithoutBorder}
        />
      </div>
      {keywordsRequestState !== KeywordsRequestState.Success &&
        keywordsEmptyState(keywordsRequestState, intl)}
      {keywordsRequestState === KeywordsRequestState.Success && (
        <>
          {keywords.length === 0 && (
            <EmptyState
              title={intl.formatMessage({
                id: I18nKey.CREATE_KEYWORDS_MODAL_KEYWORDS_EMPTY_MESSAGE,
              })}
              icon={TagLinearIcon}
            />
          )}
          {keywords.length > 0 && (
            <div className="px-12 py-16 flex flex-col gap-16 flex-auto h-0 overflow-auto">
              <div className="bg-blue-100 p-8 flex gap-8 flex-col text-sm leading-tight font-normal text-grey-900">
                <span>
                  {intl.formatMessage({
                    id: I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_BETA_MESSAGE,
                  })}
                </span>
                <span
                  onClick={onFeedbackClick}
                  className={classNames(
                    'border-b border-solid cursor-pointer border-grey-900 w-fit-content',
                    'hover:text-purple-500 hover:border-purple-500'
                  )}
                >
                  {intl.formatMessage({
                    id: I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_GIVE_FEEDBACK,
                  })}
                </span>
              </div>

              <div className="flex flex-col gap-8">
                {keywords.map((keyword) => {
                  return (
                    <div
                      key={keyword.targetId}
                      className="flex flex-row items-center gap-4 text-grey-900 text-base leading-tight font-normal"
                    >
                      <Icon
                        size={IconSize.Small}
                        className="text-grey-500 mr-8"
                        svg={SparkleIcon}
                      />
                      <span className="flex-auto overflow-hidden text-ellipsis whitespace-nowrap">
                        {keyword.searchText}
                      </span>
                      <div className="flex items-center gap-16">
                        <span className="">
                          {intl.formatMessage({
                            id: MATCH_TYPE_I18N_MAPPER[
                              keyword.matchType as MatchType
                            ],
                          })}
                        </span>
                        <Button
                          size={ButtonSize.InlineIcon}
                          variant={ButtonVariant.ActionWithoutBorder}
                          onClick={() =>
                            onRemoveKeyword(false, keyword.targetId)
                          }
                          svgIcon={CrossMiniIcon}
                          iconClassName="text-grey-500"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
