import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Placement,
  PortaledTooltipPlacement,
  SelectState,
  createMultiSelectDataFieldFilter,
  AlertTriangleIcon as warningSVG,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
  makeTextWithIconColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  AdGroupReviewStatusType,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import { TooltipText } from '../reviewStatusTooltipText';
import { ADGROUP_REVIEW_STATUS_MAPPER, TableDataAdsManager } from '../types';
import { ADGROUPS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { getCurrentRowIndex } from './utils';

export const getSelectOptions = (
  intl: IntlShape,
  currentValue?: AdGroupReviewStatusType
) => {
  if (currentValue === AdGroupReviewStatusType.InProgress) {
    return [
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_CANCELLED,
        }),
        value: AdGroupReviewStatusType.Cancelled,
      },
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_INPROGRESS,
        }),
        value: AdGroupReviewStatusType.InProgress,
      },
    ];
  }

  if (currentValue === AdGroupReviewStatusType.Pending) {
    return [
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_CANCELLED,
        }),
        value: AdGroupReviewStatusType.Cancelled,
      },
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
        }),
        value: AdGroupReviewStatusType.Pending,
      },
    ];
  }

  return [];
};

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  adGroupId,
  adGroupDetails,
  channelSettings,
  flywheelSettings,
  adGroupPerformance,
  isEditMode,
  pendingFields,
}) => {
  const intl = useIntl();
  const data: AdGroupDetails = {
    adGroupId,
    adGroupDetails,
    channelSettings,
    flywheelSettings,
    adGroupPerformance,
    pendingFields,
  };

  const dispatch = useDispatch();

  const updatedValue = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const value = updatedValue;

  const currentRowIndex = useSelector<WithTable<AdGroupDetails>, number>(
    getCurrentRowIndex(data)
  );

  const getColumnText = ({ channelSettings }: AdGroupDetails) =>
    channelSettings?.adGroupReviewStatus
      ? intl.formatMessage({
          id: ADGROUP_REVIEW_STATUS_MAPPER[
            channelSettings?.adGroupReviewStatus?.reviewStatus
          ],
        })
      : undefined;

  const isEditable = isEditMode;

  if (isEditable) {
    const reviewStatus = channelSettings?.adGroupReviewStatus?.reviewStatus;
    const isPendingOrInProgress =
      reviewStatus === AdGroupReviewStatusType.Pending ||
      reviewStatus === AdGroupReviewStatusType.InProgress;

    const getTooltipContent = () => {
      if (!isPendingOrInProgress) {
        return (
          <p className="w-180 text-center">
            {intl.formatMessage({
              id: I18nKey.ADS_MANAGER_ADGROUP_REVIEW_STATUS_DISABLED_TOOLTIP,
            })}
          </p>
        );
      }
    };

    return makeSelectColumn(
      (details: AdGroupDetails) => ({
        options: getSelectOptions(intl, reviewStatus),
        placeholder: getColumnText(data) || '',
        value: value?.toLowerCase(),
        className: 'w-136',
        menuClassName: 'text-left',
        isDirty: !!value,
        onChange: (inputValue: string) => {
          dispatch(
            tableActions.updateCell({
              tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
              rowId: data.adGroupId,
              columnName: ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus,
              value: inputValue,
              existingValue: reviewStatus || '',
            })
          );
        },
        state: !isPendingOrInProgress
          ? SelectState.Disabled
          : SelectState.Default,
        showOnTop: currentRowIndex > 6,
      }),
      () => {
        return {
          tooltipContent: getTooltipContent(),
          tooltipPlacement: Placement.Bottom,
        };
      }
    )(data);
  }

  if (
    channelSettings?.adGroupReviewStatus?.reviewStatus &&
    channelSettings?.adGroupReviewStatus?.reviewReason?.length
  ) {
    return makeTextWithIconColumn(
      getColumnText,
      warningSVG,
      <TooltipText
        comments={channelSettings?.adGroupReviewStatus?.reviewReason}
      />,
      true,
      PortaledTooltipPlacement.Right
    )(data);
  }

  return makeTextColumn(
    getColumnText,
    data.pendingFields?.includes(ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus),
    'normal-case'
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

export const adGroupReviewStatusColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_REVIEW_STATUS,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '200px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const adGroupReviewStatusFilter = createMultiSelectDataFieldFilter(
  ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus,
  I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_REVIEW_STATUS,
  [
    SelectFilterOption.AdGroupReviewStatusPending,
    SelectFilterOption.AdGroupReviewStatusInProgress,
    SelectFilterOption.AdGroupReviewStatusCompleted,
    SelectFilterOption.AdGroupReviewStatusCancelled,
  ]
);
