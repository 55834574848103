import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeExternalTextLinkColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { PROFILES_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = ({ profileId, profileDetails, channelSettings, profilePerformance }) => {
  const data: ProfileApiDataDetails = {
    profileId,
    profileDetails,
    channelSettings,
    profilePerformance,
  };
  return makeExternalTextLinkColumn(
    ({ channelSettings: profileChannelSetting }: ProfileApiDataDetails) =>
      profileChannelSetting.logo || '',
    ({ channelSettings: profileChannelSetting }: ProfileApiDataDetails) =>
      profileChannelSetting?.logo || ''
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

export const logoColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.Logo,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_LOGO,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const logoFilterForProfile = createStringDataFieldFilter(
  PROFILES_API_COLUMN_NAME.Logo,
  I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_LOGO,
  isValidString()
);
