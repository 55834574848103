import { Factory } from 'fishery';
import { DemandForecastData } from '../../../lib/types/SKUSharedTypes';

export const DemandForecastDataResponse = Factory.define<DemandForecastData[]>(
  () => [
    {
      merchantCountryId: '01cc0972-96e2-4cee-9f17-10fa9453c7da',
      forecastSkuDetailsFields: {
        extItemId: 'B00ISAPPLI',
        sku: 'S6-UJM9-A3QC',
        name: 'SKU test name 1',
        imageUrl: 'https://m.media-amazon.com/images/I/71iWVq0hEJL.jpg',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        productCatalogId: 'TEST_PRODUCT_CATALOG_ID',
        extItemDetail: {
          extItemId: 'B00ISAPPLI',
          extItemType: 'ASIN',
          salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
          salesChannelName: 'amazon',
        },
      },
      forecastInsight: {
        flyWheelRateOfSale: 1.2,
        currentInventoryLevel: 2,
        daysOfInventory: 3,
        flywheelUnitsSold: 4,
      },
    },
  ]
);
