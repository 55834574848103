import React from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../../lib/types/I18nKey';
interface MerchantInfoProps {
  readonly headerLabel: string;
  readonly advertisingDelayCount: number;
  readonly productsDelayCount: number;
}
export const MerchantInfo: React.FC<MerchantInfoProps> = (props) => {
  const intl = useIntl();
  const apiLabel = intl.formatMessage({
    id: I18nKey.SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_API,
  });

  const availableDataDelayLabel = intl.formatMessage({
    id: I18nKey.SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_AVAILABLE_DATA_DELAY,
  });

  const advertisingLabel = intl.formatMessage({
    id: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_AD,
  });

  const productLabel = intl.formatMessage({
    id: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_PRODUCT,
  });

  return (
    <div className="flex flex-col my-12">
      <div className="flex slef-stretch items-center">
        <label className="text-base whitespace-nowrap leading-normal font-semibold text-grey-900 pr-8">
          {props.headerLabel}
        </label>
        <hr className="border border-solid border-grey-300 w-full" />
      </div>
      <div className="grid grid-cols-2 grid-">
        <div>
          <label className="text-base leading-normal font-normal text-grey-500">
            {apiLabel}
          </label>
        </div>
        <div className="text-right">
          <label className="text-base leading-normal font-normal text-grey-500">
            {availableDataDelayLabel}
          </label>
        </div>
        <div>
          <label className="text-base leading-normal font-normal text-grey-900">
            {advertisingLabel}
          </label>
        </div>
        <div className="text-right">
          <label className="text-base leading-normal font-normal text-grey-900">
            {props.advertisingDelayCount} day
          </label>
        </div>
        <div>
          <label className="text-base leading-normal font-normal text-grey-900">
            {productLabel}
          </label>
        </div>
        <div className="text-right">
          <label className="text-base leading-normal font-normal text-grey-900">
            {props.productsDelayCount} day
          </label>
        </div>
      </div>
    </div>
  );
};

MerchantInfo.displayName = 'MerchantInfo';
