import { DateRange } from '../types';
import {
  MetricsPeriodType,
  SlideoutProductDetails,
  SlideoutProductMetrics,
  SlideoutProductsGraphDataResponse,
} from '../../../../../lib/types/SKUSharedTypes';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import { createSKUApiClient } from '../../../../../lib/clients/SKUApiClient';
import noop from 'lodash/noop';
import {
  ButtonState,
  ChartTime,
  KPICardV2Props,
  Type,
} from '@teikametrics/tm-design-system';
import { DateTime } from 'luxon';
import {
  AsyncRequest,
  AsyncRequestCompleted,
  AsyncRequestFailed,
  AsyncRequestLoading,
  AsyncRequestNotStarted,
  asyncRequestIsComplete,
  asyncRequestIsLoading,
} from '../../../../../lib/utilities/asyncRequest';
import { SeasonalEvent } from '../../../../../lib/types/CompassSharedTypes';
import { createCompassApiClient } from '../../../../../lib/clients/CompassApiClient';
import { NotificationContext } from '../../../../../containers/notificationProvider';
import I18nKey from '../../../../../lib/types/I18nKey';
import { useIntl } from 'react-intl';
import { useDownloadFile } from '../../../../../lib/hooks/downloadFile';
import { getAggregatedHeroMetrics, getKPICards } from './utils';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../lib/utilities/intercom';
import {
  getProductsSlideoverShowPreviousData,
  setProductsSlideoverShowPreviousDataInLocalStorage,
} from '../../utils/localStorage';
import { getUserId } from '../../../../advertisingOptimization';
import { CurrencyCode } from '../../../../../lib/utilities/currency';
import { DATE_QUERY_STRING_FORMAT } from '../../../../../lib/utilities/buildUrlUtilities';

export interface OwnProps {
  readonly sku: string;
  readonly defaultDateRange?: DateRange;
  readonly currency: CurrencyCode;
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
}

export interface PerformanceSlideoverContextState {
  readonly currentDateRange: DateRange;
  readonly currency: CurrencyCode;
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly productDetails?: SlideoutProductDetails;
  readonly isProductDetailsLoading?: boolean;
  readonly selectedProductId?: string;
  readonly showSeasonalEvents?: boolean;
  readonly comparePreviousPeriod?: boolean;
  readonly chartTimeFormat: ChartTime;
  readonly graphDataRequest: AsyncRequest<{
    readonly current: SlideoutProductsGraphDataResponse;
    readonly previous?: SlideoutProductsGraphDataResponse;
  }>;
  readonly selectedMetrics: SlideoutProductMetrics[];
  readonly selectedMetricIndex: number;
  readonly seasonalEventsRequest: AsyncRequest<SeasonalEvent[]>;
  readonly exportButtonState: ButtonState;

  readonly onMetricsChange: (metricIndex: number) => (value: string) => void;
  readonly setSelectedMetricIndex: (index: number) => void;
  readonly setSelectedProductId: (productId?: string) => void;
  readonly setCurrentDateRange: (dateRange: DateRange) => void;
  readonly setShowSeasonalEvents: (showSeasonalEvents: boolean) => void;
  readonly updateComparePreviousPeriod: (
    comparePreviousPeriod: boolean
  ) => void;
  readonly setChartTimeFormat: (chartTimeFormat: ChartTime) => void;
  readonly downloadFile: () => void;
  readonly kpiCards: KPICardV2Props[];
}

export const PerformanceSlideoverContext =
  createContext<PerformanceSlideoverContextState>({
    comparePreviousPeriod: true,
    salesChannelId: '',
    merchantCountryId: '',
    currency: CurrencyCode.USD,
    setSelectedProductId: noop,
    setCurrentDateRange: noop,
    setShowSeasonalEvents: noop,
    updateComparePreviousPeriod: noop,
    setChartTimeFormat: noop,
    setSelectedMetricIndex: noop,
    onMetricsChange: () => noop,
    graphDataRequest: AsyncRequestNotStarted(),
    selectedMetrics: [],
    selectedMetricIndex: 0,
    seasonalEventsRequest: AsyncRequestNotStarted(),
    chartTimeFormat: ChartTime.Daily,
    currentDateRange: {
      startDate: DateTime.now().minus({ days: 7 }),
      endDate: DateTime.now().minus({ days: 1 }),
    },
    exportButtonState: ButtonState.Enabled,
    downloadFile: noop,
    kpiCards: [],
  });

const { Provider } = PerformanceSlideoverContext;

export const PerformanceSlideoverContextProvider: React.FC<
  OwnProps & PropsWithChildren
> = ({
  sku,
  defaultDateRange = {
    startDate: DateTime.now().minus({ days: 7 }),
    endDate: DateTime.now().minus({ days: 1 }),
  },
  currency,
  merchantCountryId,
  salesChannelId,
  children,
}) => {
  const intl = useIntl();
  const userContext = useContext<UserContextState>(UserContext);
  const toasts = useContext(NotificationContext);
  const idToken = userContext.userInfo.idToken!;
  const account = getCurrentAccountFromContext(userContext)!;
  const accountId = account.id;
  const accountName = account.companyName;
  const userId = getUserId(userContext);
  const skuApiClient = createSKUApiClient(idToken);
  const compassApiClient = createCompassApiClient(idToken);

  const [isProductDetailsLoading, setIsProductDetailsLoading] = useState(false);
  const [productDetails, setProductDetails] =
    useState<SlideoutProductDetails>();
  const [selectedProductId, setSelectedProductId] = useState<string>();
  const [currentDateRange, setCurrentDateRange] =
    useState<DateRange>(defaultDateRange);
  const [showSeasonalEvents, setShowSeasonalEvents] = useState(true);
  const [comparePreviousPeriod, setComparePreviousPeriod] = useState(
    getProductsSlideoverShowPreviousData({
      userId,
      accountId,
    })
  );
  const [chartTimeFormat, setChartTimeFormat] = useState<ChartTime>(
    ChartTime.Daily
  );

  const [graphDataRequest, setGraphDataRequest] = useState<
    AsyncRequest<{
      readonly current: SlideoutProductsGraphDataResponse;
      readonly previous?: SlideoutProductsGraphDataResponse;
    }>
  >(AsyncRequestNotStarted());

  const [selectedMetrics, setSelectedMetrics] = useState<
    SlideoutProductMetrics[]
  >([
    SlideoutProductMetrics.TACoS,
    SlideoutProductMetrics.TotalSales,
    SlideoutProductMetrics.AdSales,
    SlideoutProductMetrics.AdSpend,
  ]);

  const [selectedMetricIndex, setSelectedMetricIndex] = useState<number>(0);

  const [seasonalEventsRequest, setSeasonalEventsRequest] = useState<
    AsyncRequest<SeasonalEvent[]>
  >(AsyncRequestNotStarted());

  const [exportButtonState, setExportButtonState] = useState<ButtonState>(
    ButtonState.Enabled
  );

  useEffect(() => {
    setIsProductDetailsLoading(true);
    skuApiClient
      .getSlideoutProductDetails(accountId, {
        merchantCountryId,
        sku,
        salesChannelId,
      })
      .then((response) => {
        setProductDetails(response);
        const selectedProductId =
          response.skuParentDetails.sku === sku
            ? response.skuParentDetails.productCatalogId
            : response.skuChildren?.find((child) => child.sku === sku)
                ?.productCatalogId;
        setSelectedProductId(selectedProductId);
        setIsProductDetailsLoading(false);
      })
      .catch(() => {
        setIsProductDetailsLoading(false);
      });
  }, [sku, merchantCountryId, salesChannelId]);

  useEffect(() => {
    setSeasonalEventsRequest(AsyncRequestLoading());
    compassApiClient
      .getSeasonalEvents(accountId, currentDateRange)
      .then((response) => {
        setSeasonalEventsRequest(AsyncRequestCompleted(response));
      })
      .catch((err) => setSeasonalEventsRequest(AsyncRequestFailed(err)));
  }, [accountId, currentDateRange]);

  useEffect(() => {
    if (!selectedProductId) {
      return;
    }

    const request = {
      salesChannelId,
      merchantCountryId,
      currency: currency,
      productCatalogId: selectedProductId!,
      endDate: currentDateRange.endDate.toFormat(DATE_QUERY_STRING_FORMAT),
      startDate: currentDateRange.startDate.toFormat(DATE_QUERY_STRING_FORMAT),
    };

    setGraphDataRequest(AsyncRequestLoading());

    Promise.all([
      skuApiClient.getSlideoutProductGraphData(accountId, {
        ...request,
        metricsPeriodType: MetricsPeriodType.Current,
      }),
      skuApiClient.getSlideoutProductGraphData(accountId, {
        ...request,
        metricsPeriodType: MetricsPeriodType.Previous,
      }),
    ]).then(([currentResponse, previousResponse]) => {
      setGraphDataRequest(
        AsyncRequestCompleted({
          current: currentResponse,
          previous: previousResponse,
        })
      );
    });
  }, [
    currentDateRange,
    accountId,
    selectedProductId,
    merchantCountryId,
    salesChannelId,
    currency,
  ]);

  useEffect(() => {
    hideIntercomLauncher();
    return () => {
      showIntercomLauncher();
    };
  });

  const fileName = useMemo(
    () =>
      `PRODUCTS PERFORMANCE SUMMARY_${accountName}_${DateTime.now().toFormat(
        DATE_QUERY_STRING_FORMAT
      )}.csv`,
    [accountName]
  );

  const postDownloadFile = () => {
    toasts.addNotification({
      headline: intl.formatMessage({ id: I18nKey.SUCCESS }),
      description: intl.formatMessage(
        {
          id: I18nKey.PRODUCTS_TOAST_MESSAGE_FILE_DOWNLOAD,
        },
        {
          filename: fileName,
        }
      ),
      type: Type.Success,
      dataTestId: 'products_slideout_summary_export_success',
    });
    setExportButtonState(ButtonState.Enabled);
  };

  const onErrorDownloadFile = () => {
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.PRODUCTS_TOAST_MESSAGE_EXPORT_FAILED_HEADER,
      }),
      description: intl.formatMessage({
        id: I18nKey.PRODUCTS_TOAST_MESSAGE_EXPORT_FAILED,
      }),
      type: Type.Attention,
      dataTestId: 'ao_export_ao_dashboard_export_failure',
    });
    setExportButtonState(ButtonState.Enabled);
  };

  const exportSlideoverSummary = async () => {
    return skuApiClient
      ?.exportSlideoutProductGraphData(accountId, {
        salesChannelId,
        merchantCountryId,
        currency: currency,
        productCatalogId: selectedProductId!,
        endDate: currentDateRange.endDate.toFormat(DATE_QUERY_STRING_FORMAT),
        startDate: currentDateRange.startDate.toFormat(
          DATE_QUERY_STRING_FORMAT
        ),
        metricsPeriodType: MetricsPeriodType.Current,
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        onErrorDownloadFile();
        return err;
      });
  };

  const { downloadFile } = useDownloadFile<string>({
    asyncFunction: exportSlideoverSummary,
    preDownloadFile: () => {
      setExportButtonState(ButtonState.Loading);
    },
    postDownloadFile,
    onError: noop,
    fileName,
  });

  const onMetricsChange = (metricIndex: number) => (value: string) => {
    setSelectedMetrics((prevMetrics) => {
      return [
        ...prevMetrics.map((metric, index) =>
          index === metricIndex ? (value as SlideoutProductMetrics) : metric
        ),
      ];
    });
  };

  const performanceMetrics = asyncRequestIsComplete(graphDataRequest)
    ? getAggregatedHeroMetrics(graphDataRequest.result)
    : {};

  const kpiCards = getKPICards(
    intl,
    selectedMetrics,
    performanceMetrics,
    asyncRequestIsLoading(graphDataRequest),
    selectedMetricIndex,
    comparePreviousPeriod
  );

  const updateComparePreviousPeriod = (showPreviousData: boolean) => {
    setComparePreviousPeriod(showPreviousData);
    setProductsSlideoverShowPreviousDataInLocalStorage(
      {
        userId,
        accountId,
      },
      showPreviousData
    );
  };

  return (
    <Provider
      value={{
        isProductDetailsLoading,
        productDetails,
        selectedProductId,
        setSelectedProductId,
        currency,
        merchantCountryId,
        salesChannelId,
        currentDateRange,
        comparePreviousPeriod,
        showSeasonalEvents,
        chartTimeFormat,
        setCurrentDateRange,
        setShowSeasonalEvents,
        updateComparePreviousPeriod,
        setChartTimeFormat,
        graphDataRequest,
        selectedMetrics,
        onMetricsChange,
        selectedMetricIndex,
        setSelectedMetricIndex,
        seasonalEventsRequest,
        exportButtonState,
        downloadFile,
        kpiCards,
      }}
    >
      <>{children}</>
    </Provider>
  );
};
