import {
  AlertCircleIcon,
  Icon,
  IconSize,
  LightningIcon,
  PaletteColor,
  Pill,
  PillSize,
  SparkleIcon,
  Spinner,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
  UserIcon,
} from '@teikametrics/tm-design-system';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  MAP_TARGET_SEGMENT_TO_I18NKEYS,
  SlideOverCommonProps,
} from '../../../../../keywordAction/components/viewSkuAndKeywordSlideout/types';
import {
  getAddedByIcon,
  getCommonEnityRequestFields,
} from '../../../../../keywordAction/components/viewSkuAndKeywordSlideout/utils';
import {
  PROFILES_API_COLUMN_NAME,
  TARGETS_API_COLUMN_NAME,
  TargetStatusOptions,
} from '../../utils';
import { KEYWORDS_RECORDS_PER_LOAD } from '../../../../../keywordAction/components/viewSkuAndKeywordSlideout/const';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { TargetSegment } from '../../../../../../lib/types/KeywordAction';
import { EmptyState } from './emptyState';
import {
  AdLevelApiEndpoint,
  KeywordTargetType,
  TargetsDataRequest,
  TargetsDetails,
} from '../../../../../../lib/types/AOSharedTypes';
import { FilterOps } from '../../../../../../lib/types/Filter';
import { PaginatedRequest } from '../../../../../../lib/clients/types';
import { SortOrder } from '../../../../../../lib/types/Sort';

export interface KeywordsTabV2Props extends SlideOverCommonProps {}
export const KeywordsTabV2: React.FC<KeywordsTabV2Props> = ({
  accountId,
  aoApiClient,
  merchantCountryId,
  adGroupId,
  salesChannelId,
  merchantType,
}) => {
  const intl = useIntl();

  const [loadingKeywords, setLoadingKeywords] = useState<boolean>(true);

  const [keywordsData, setKeywordsData] = useState<TargetsDetails[]>([]);

  const TARGET_REQUEST = {
    ...getCommonEnityRequestFields(
      merchantCountryId,
      salesChannelId,
      merchantType
    ),
    targetDetailsFields: [TARGETS_API_COLUMN_NAME.TargetText],
    channelSettingsFields: [],
    performanceFields: [],
  };

  const TARGET_DATA_FETCHER = aoApiClient.getAdLevelData<
    TargetsDataRequest,
    TargetsDetails
  >(accountId, AdLevelApiEndpoint.KeywordTargets, TARGET_REQUEST, [
    {
      op: FilterOps.eq,
      field: PROFILES_API_COLUMN_NAME.AdGroupId,
      value: adGroupId,
    },
    {
      op: FilterOps.eq,
      field: TARGETS_API_COLUMN_NAME.KeywordType,
      value: KeywordTargetType.Keyword,
    },
    {
      op: FilterOps.in,
      field: TARGETS_API_COLUMN_NAME.TargetStatus,
      value: [
        TargetStatusOptions.Enabled.value,
        TargetStatusOptions.Paused.value,
      ],
    },
  ]);

  const loadKeywordsDataData = async () => {
    const paginatedRequest: PaginatedRequest = {
      filters: [],
      sorts: [
        {
          column: TARGETS_API_COLUMN_NAME.TargetText,
          direction: SortOrder.Asc,
        },
      ],
      itemsPerPage: KEYWORDS_RECORDS_PER_LOAD,
      page: 1,
    };
    return TARGET_DATA_FETCHER(paginatedRequest).then((resp) => resp);
  };

  const loadAndProcessKeywordsData = async () => {
    const recentResponseData = await loadKeywordsDataData();
    setKeywordsData(
      recentResponseData.items.map((item) => {
        return {
          ...item,
          targetDetails: {
            ...item.targetDetails,
            targetType: item.targetDetails.targetType || TargetSegment.Unknown,
          },
        };
      })
    );
    setLoadingKeywords(false);
  };

  useEffect(() => {
    loadAndProcessKeywordsData();
  }, []);

  const [FLYWHEEL_AI, USER_ADDED, CHATGPT] = [
    I18nKey.BILLING_FLYWHEEL_AI_TITLE,
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_USER_ADDED,
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_CHAT_GPT,
  ].map((id) => intl.formatMessage({ id }));

  const targetSegments: TargetSegment[] = Object.values(TargetSegment);

  if (loadingKeywords) {
    return (
      <div className="relative mt-12 ml-16 h-32">
        <Spinner />
      </div>
    );
  }

  const hasNoKeywords =
    keywordsData.filter((keyword) =>
      targetSegments.includes(
        (keyword.targetDetails?.targetType as TargetSegment) || ''
      )
    ).length === 0;

  return (
    <>
      <div className="flex">
        <div className="flex flex-col flex-1 gap-24">
          {hasNoKeywords && (
            <EmptyState
              title={intl.formatMessage({
                id: I18nKey.CREATE_KEYWORDS_MODAL_NO_KEYWORDS_MESSAGE,
              })}
              icon={AlertCircleIcon}
              description={intl.formatMessage({
                id: I18nKey.CREATE_KEYWORDS_MODAL_NO_KEYWORDS_SUB_MESSAGE,
              })}
            />
          )}
          {!hasNoKeywords && (
            <>
              <div className="flex flex-row w-360 mx-2 gap-16 items-center px-12 py-8 rounded-4 bg-grey-50 shadow-businessIntelligenceCard">
                <div className="flex flex-row items-center gap-4">
                  <Icon
                    className="text-grey-500"
                    size={IconSize.Small}
                    svg={LightningIcon}
                  />
                  <span className="text-sm text-grey-900 leading-none font-normal">
                    {FLYWHEEL_AI}
                  </span>
                </div>

                <div className="flex flex-row items-center gap-4">
                  <Icon
                    className="text-grey-500"
                    size={IconSize.Small}
                    svg={UserIcon}
                  />
                  <span className="text-sm text-grey-900 leading-none font-normal">
                    {USER_ADDED}
                  </span>
                </div>
                <div className="flex flex-row items-center gap-4">
                  <Icon
                    className="text-grey-500"
                    size={IconSize.Small}
                    svg={SparkleIcon}
                  />
                  <span className="text-sm text-grey-900 leading-none font-normal">
                    {CHATGPT}
                  </span>
                </div>
              </div>
              <div className="h-kwaCreateInAdGroupSlideoutTab flex flex-col gap-24 overflow-auto">
                {targetSegments.map((e) => {
                  const filteredTargetSegmentData = keywordsData.filter(
                    (t) => t.targetDetails.targetType === e
                  );
                  if (filteredTargetSegmentData.length) {
                    return (
                      <div className="flex flex-col gap-8" key={e}>
                        <div className="flex flex-row gap-12 sticky top-0 bg-white">
                          <Typography
                            size={TypographySize.lg}
                            lineHeight={TypographyLineHeight.tight}
                            weight={TypographyWeight.medium}
                            className={'text-grey-900'}
                          >
                            {intl.formatMessage({
                              id: MAP_TARGET_SEGMENT_TO_I18NKEYS[e],
                            })}
                          </Typography>
                          <Pill
                            color={PaletteColor.greyV2}
                            text={filteredTargetSegmentData.length}
                            isHoverable={false}
                            pillSize={PillSize.Small}
                          />
                        </div>
                        <div className="flex flex-col gap-8">
                          {filteredTargetSegmentData.map((f) => {
                            return (
                              <div
                                key={f.targetId}
                                className="flex flex-row items-center gap-4"
                              >
                                <Icon
                                  size={IconSize.Small}
                                  className="text-grey-500"
                                  svg={getAddedByIcon(
                                    f.targetDetails.addedBy as string
                                  )}
                                />
                                <Typography
                                  size={TypographySize.base}
                                  lineHeight={TypographyLineHeight.tight}
                                  weight={TypographyWeight.regular}
                                  className={'text-grey-900'}
                                >
                                  {f.targetDetails.targetText}
                                </Typography>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
