import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import lowerCase from 'lodash/lowerCase';
import React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  Placement,
  SelectProps,
  SelectState,
  createMultiSelectDataFieldFilter,
  AlertTriangleIcon as warningSVG,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
  makeTextWithIconColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  AutomationStatus,
  CampaignDetails,
  CampaignStatus,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import {
  AdLevelI8nKeyMapper,
  NumericValueConfig,
  TableDataAdsManager,
} from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  getAutomationDisabledTooltipMessage,
  getTooltipContentForArchivedEntity,
  isCampaignStatusArchived,
  isValidMacsTarget,
  isValidMaxBid,
  isValidMinBid,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { getCurrentRowIndex } from './utils';
import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';

const getExistingValue = (
  automationStatus: boolean | undefined
): AutomationStatus => {
  switch (automationStatus) {
    case undefined:
      return AutomationStatus.Unset;
    case true:
      return AutomationStatus.Active;
    case false:
      return AutomationStatus.Inactive;
  }
};

const isAnyBidAutomationValid = (
  changedBidAutomationStatus: string,
  automationStatus: boolean | undefined
) => isUndefined(changedBidAutomationStatus) && !isNil(automationStatus);

const setInititalLocalBidAutomationStatusValue = (
  changedBidAutomationStatus: string,
  automationStatus: boolean | undefined
) => {
  if (isAnyBidAutomationValid(changedBidAutomationStatus, automationStatus)) {
    if (automationStatus === true) {
      return AutomationStatus.Active;
    } else {
      return AutomationStatus.Inactive;
    }
  } else {
    return changedBidAutomationStatus;
  }
};

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const bidConstraintsData = useBidConstraintsContext();

  const { campaignCostType, targetingType } = props.campaignDetails;

  const changedBidAutomationStatus = useSelector<
    WithTable<CampaignDetails>,
    string
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      props.campaignId,
      CAMPAIGNS_API_COLUMN_NAME.AutomationStatus
    )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  let localBidAutomationStatusValue: string =
    setInititalLocalBidAutomationStatusValue(
      changedBidAutomationStatus,
      props.flywheelSettings?.automationStatus
    );

  const { macsTarget, minBid, maxBid } = props.flywheelSettings;
  const maxBidAmount = maxBid?.amount;
  const minBidAmount = minBid?.amount;

  const macsTargetValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MACSTarget
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const minBidValue =
    useSelector<WithTable<CampaignDetails>, string>(({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MinBid
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
    ) || '';

  const maxBidValue =
    useSelector<WithTable<CampaignDetails>, string>(({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MaxBid
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
    ) || '';

  const minBidConfig = (() => {
    const constraints = getBidConstraint(
      bidConstraintsData.constraints,
      props.selectedAdType,
      props.salesChannel,
      CAMPAIGNS_API_COLUMN_NAME.MinBid,
      props.merchantCountry,
      targetingType,
      props.merchantType,
      campaignCostType
    );

    return {
      min: constraints.minBid,
      default: constraints.defaultMinBid,
      max: constraints.maxBid,
    };
  })();

  const maxBidConfig = (() => {
    const constraints = getBidConstraint(
      bidConstraintsData.constraints,
      props.selectedAdType,
      props.salesChannel,
      CAMPAIGNS_API_COLUMN_NAME.MaxBid,
      props.merchantCountry,
      targetingType,
      props.merchantType,
      campaignCostType
    );

    return {
      min: constraints.minBid,
      default: constraints.defaultMaxBid,
      max: constraints.maxBid,
    };
  })();

  const currentRowIndex = useSelector<WithTable<CampaignDetails>, number>(
    getCurrentRowIndex(props)
  );

  if (props.isEditMode) {
    const [ACTIVE, INACTIVE, UNSET] = [
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ACTIVE,
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_INACTIVE,
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_UNSET,
    ].map((id) => intl.formatMessage({ id }));

    const selectOptions = [
      {
        label: ACTIVE,
        value: AutomationStatus.Active,
      },
      {
        label: INACTIVE,
        value: AutomationStatus.Inactive,
      },
      {
        label: UNSET,
        value: AutomationStatus.Unset,
        disabled: true,
      },
    ];

    const existingValue: string = getExistingValue(
      props.flywheelSettings?.automationStatus
    );

    const onBidAutomationStatusChange = (value: string) => {
      dispatch(
        tableActions.updateCell({
          tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
          rowId: props.campaignId,
          columnName: CAMPAIGNS_API_COLUMN_NAME.AutomationStatus,
          value,
          existingValue,
        })
      );
    };

    const shouldSetAutomationStatusActive = getShouldSetAutomationStatusActive({
      macsTarget,
      macsTargetValue,
      minBidAmount,
      minBidValue,
      maxBidAmount,
      maxBidValue,
      minBidConfig,
      maxBidConfig,
    });

    if (shouldSetAutomationStatusActive) {
      if (!localBidAutomationStatusValue) {
        onBidAutomationStatusChange(AutomationStatus.Active);
      }
    } else if (isNil(props.flywheelSettings?.automationStatus)) {
      if (localBidAutomationStatusValue) {
        onBidAutomationStatusChange(AutomationStatus.Unset);
      } else {
        localBidAutomationStatusValue = AutomationStatus.Unset;
      }
    }

    const automationDisabledForMerchant = isAutomationDisabledForMerchant(
      props.aiEnabled,
      props.merchantsWithAutomationEnabled,
      props.campaignDetails.merchantCountryId
    );

    const bidAutomationStatusSelectState: SelectState =
      getBidAutomationStatusSelectState(
        localBidAutomationStatusValue,
        props.channelSettings.status,
        automationDisabledForMerchant
      );

    const bidAutomationStatusProps: SelectProps<string> = {
      options: selectOptions,
      placeholder: '',
      value: localBidAutomationStatusValue,
      state: bidAutomationStatusSelectState,
      className: 'w-120',
      isDirty: localBidAutomationStatusValue !== existingValue,
      onChange: onBidAutomationStatusChange,
      showOnTop: currentRowIndex > 6,
      dataTestId: 'bid_automation',
    };

    return makeSelectColumn(
      () => bidAutomationStatusProps,
      () => {
        const isCampaignArchived = isCampaignStatusArchived(
          props.channelSettings.status
        );

        return {
          tooltipContent: getTooltipContent(
            intl,
            automationDisabledForMerchant,
            isCampaignArchived,
            localBidAutomationStatusValue
          ),
          controlledTooltip: getControlledTooltipState(
            isCampaignArchived,
            localBidAutomationStatusValue
          ),
          tooltipPlacement: Placement.Bottom,
          style: ContentStyle.Bold,
        };
      }
    )(props);
  }

  if (isUndefined(props.flywheelSettings?.automationStatus)) {
    const UNSET_STATUS_TOOLTIP = (
      <FormattedMessage
        id={
          I18nKey.ADS_MANAGER_ADGROUP_TABLE_AUTOMATION_COLUMN_UNSET_STATUS_TOOLTIP
        }
        values={{ separator: <br /> }}
      />
    );
    return makeTextWithIconColumn(
      () => AutomationStatus.Unset,
      warningSVG,
      <div className="max-w-220">{UNSET_STATUS_TOOLTIP}</div>
    )(props);
  }

  return makeTextColumn<CampaignDetails>((data) =>
    getBidAutomationValue(data, intl)
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

const getBidAutomationValue = (data: CampaignDetails, intl: IntlShape) => {
  const automationStatus = data?.flywheelSettings?.automationStatus;
  if (isNil(automationStatus)) {
    return undefined;
  } else if (automationStatus) {
    return intl.formatMessage({
      id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ACTIVE,
    });
  } else {
    return intl.formatMessage({
      id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_INACTIVE,
    });
  }
};

export const bidAutomationStatusColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.AutomationStatus,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AUTOMATION_STATUS,
  RowCellElement,
  gridColumnWidth: '172px',
};

export const bidAutomationStatusFilter = createMultiSelectDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.AutomationStatus,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AUTOMATION_STATUS,
  [
    SelectFilterOption.BidAutomationStatusActive,
    SelectFilterOption.BidAutomationStatusInactive,
    SelectFilterOption.BidAutomationStatusUnset,
  ]
);

const getTooltipContent = (
  intl: IntlShape,
  automationDisabledForMerchant: boolean,
  isCampaignArchived: boolean,
  localBidAutomationStatusValue: string
) => {
  if (automationDisabledForMerchant) {
    return getAutomationDisabledTooltipMessage(
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AUTOMATION_STATUS,
      })
    );
  }

  if (isCampaignArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AUTOMATION_STATUS,
      }),
      intl
    );
  }
  if (localBidAutomationStatusValue === AutomationStatus.Unset) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_FLYWHEEL_SETTINGS_AUTOMATION_STATUS_UNSET_TOOLTIP,
          },
          {
            adLevel: lowerCase(
              intl.formatMessage({
                id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
              })
            ),
          }
        )}
      </p>
    );
  }
};

const getControlledTooltipState = (
  isCampaignArchived: boolean,
  localBidAutomationStatusValue: string
) =>
  isCampaignArchived || localBidAutomationStatusValue === AutomationStatus.Unset
    ? ControlledTooltip.None
    : ControlledTooltip.Hide;

const isCurrentOrUpdatedMacsTargetValueValid = (
  macsTarget: number | undefined,
  macsTargetValue: string
) => {
  if (!isNil(macsTarget)) {
    return isValidMacsTarget(String(macsTarget * 100));
  }
  return isValidMacsTarget(macsTargetValue);
};

const getBidAutomationStatusSelectState = (
  localBidAutomationStatusValue: string,
  status: CampaignStatus | undefined,
  automationDisabledForMerchant: boolean
) =>
  localBidAutomationStatusValue === AutomationStatus.Unset ||
  isCampaignStatusArchived(status) ||
  automationDisabledForMerchant
    ? SelectState.Disabled
    : SelectState.Default;

const isAutomationDisabledForMerchant = (
  aiEnabled: boolean | undefined,
  merchantsWithAutomationEnabled: string[] | undefined,
  merchantCountryId: string | undefined
) =>
  !aiEnabled &&
  !merchantsWithAutomationEnabled?.includes(merchantCountryId ?? '');

const isCurrentOrUpdatedMinBidValueValid = (
  minBidAmount: string | undefined,
  minBidValue: string,
  maxBidAmount: string | undefined,
  maxBidValue: string,
  minBidConfig: NumericValueConfig
) => {
  const minValue = minBidAmount || minBidValue;
  const maxValue = maxBidAmount || maxBidValue;

  return isValidMinBid(
    minValue,
    String(minBidConfig.max),
    String(minBidConfig.min),
    maxValue
  );
};

const isCurrentOrUpdatedMaxBidValueValid = (
  minBidAmount: string | undefined,
  minBidValue: string,
  maxBidAmount: string | undefined,
  maxBidValue: string,
  maxBidConfig: NumericValueConfig
) => {
  const minValue = minBidAmount || minBidValue;
  const maxValue = maxBidAmount || maxBidValue;

  return isValidMaxBid(
    maxValue,
    String(maxBidConfig.max),
    String(maxBidConfig.min),
    minValue
  );
};

const getShouldSetAutomationStatusActive = (args: {
  macsTarget: number | undefined;
  macsTargetValue: string;
  minBidAmount: string | undefined;
  minBidValue: string;
  maxBidAmount: string | undefined;
  maxBidValue: string;
  minBidConfig: NumericValueConfig;
  maxBidConfig: NumericValueConfig;
}) =>
  isCurrentOrUpdatedMacsTargetValueValid(
    args.macsTarget,
    args.macsTargetValue
  ) &&
  isCurrentOrUpdatedMinBidValueValid(
    args.minBidAmount,
    args.minBidValue,
    args.maxBidAmount,
    args.maxBidValue,
    args.minBidConfig
  ) &&
  isCurrentOrUpdatedMaxBidValueValid(
    args.minBidAmount,
    args.minBidValue,
    args.maxBidAmount,
    args.maxBidValue,
    args.maxBidConfig
  );
