import { Fw2LocalStorage } from '../../../../../lib/utilities/fw2LocalStorage';
import endsWith from 'lodash/endsWith';
import forIn from 'lodash/forIn';
import includes from 'lodash/includes';
import startsWith from 'lodash/startsWith';
import { DateTime } from 'luxon';
import { AppHeaderTitleProps } from '../../..';
import {
  AdLevel,
  AdType,
  DateRange,
  FlywheelSalesChannel,
  MerchantCountryId,
  SalesChannelId,
} from '../../../../../lib/types/AOSharedTypes';
import { UserInfo } from '../../../../compass/storageUtils';
import { MetricsData } from '../../../components/ManageMetricsSlideover';
import { DataInspectorColumnManagerConfigProps } from '../types';
import { getDefaultFilters } from './defaultFilters';
import { Fw2SessionStorage } from '../../../../../lib/utilities/fw2SessionStorage';
import { Filter } from '../../../../../lib/types/Filter';
import { CAMPAIGNS_API_COLUMN_NAME } from './index';

export const SESSION_STORAGE_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_RANGE_CONNECTOR = '_';
export const ADSMANAGER_STORAGE_KEY = 'adsmanager';

export interface UserProps {
  readonly userId: string;
  readonly accountId: string;
}

export type UserSalesChannelProp = UserProps & {
  readonly salesChannelId: SalesChannelId;
};

export const getLocalStorageKeyForStarDateEndDate = ({
  userId,
  accountId,
  salesChannelId,
}: UserSalesChannelProp) =>
  `${ADSMANAGER_STORAGE_KEY}_${userId}_${accountId}_${salesChannelId}_startDate_endDate`;

export const getLocalStorageKeyForSalesChannel = (
  userId: string,
  currentAccountId: string
) => `${userId}_${currentAccountId}_sales_channel`;

export const getSalesChannelFromLocalStorage = (
  userId: string,
  accountId: string
): string | null => {
  const salesChannelStorageKey = getLocalStorageKeyForSalesChannel(
    userId,
    accountId
  );
  return Fw2LocalStorage.getItem(salesChannelStorageKey);
};

export const storeSalesChannelToLocalStorage = ({
  userId,
  accountId,
  salesChannelId,
}: UserSalesChannelProp): void => {
  const salesChannelStorageKey = getLocalStorageKeyForSalesChannel(
    userId,
    accountId
  );
  Fw2LocalStorage.setItem(salesChannelStorageKey, String(salesChannelId));
};

export const getLocalStorageKeyForMerchant = ({
  userId,
  accountId,
  salesChannelId,
}: UserSalesChannelProp) =>
  `${ADSMANAGER_STORAGE_KEY}_${userId}_${accountId}_${salesChannelId}_merchant`;

export const getMerchantFromLocalStorage = ({
  userId,
  accountId,
  salesChannelId,
}: UserSalesChannelProp): MerchantCountryId => {
  const merchantStorageKey = getLocalStorageKeyForMerchant({
    userId,
    accountId,
    salesChannelId,
  });
  const merchantsFromLocalStorage = Fw2LocalStorage.getItem(merchantStorageKey);
  try {
    return JSON.parse(merchantsFromLocalStorage as string);
  } catch (e) {
    return '';
  }
};

export const storeMerchantToLocalStorage = (
  { userId, accountId, salesChannelId }: UserSalesChannelProp,
  selectedMerchants: Array<number | string> | string
): void => {
  const merchantStorageKey = getLocalStorageKeyForMerchant({
    userId,
    accountId,
    salesChannelId,
  });
  Fw2LocalStorage.setItem(
    merchantStorageKey,
    JSON.stringify(selectedMerchants)
  );
};

export const getLocalStorageKeyForAdType = ({
  userId,
  accountId,
  salesChannelId,
}: UserSalesChannelProp) =>
  `${ADSMANAGER_STORAGE_KEY}_${userId}_${accountId}_${salesChannelId}_adType`;

export const getAdTypeFromLocalStorage = ({
  userId,
  accountId,
  salesChannelId,
}: UserSalesChannelProp): string | null => {
  const adTypeStorageKey = getLocalStorageKeyForAdType({
    userId,
    accountId,
    salesChannelId,
  });
  return Fw2LocalStorage.getItem(adTypeStorageKey);
};

export const storeAdTypeToLocalStorage = (
  userId: string,
  accountId: string,
  salesChannelId: SalesChannelId,
  adTypeValue: string
): void => {
  const adTypeStorageKey = getLocalStorageKeyForAdType({
    userId,
    accountId,
    salesChannelId,
  });
  Fw2LocalStorage.setItem(adTypeStorageKey, adTypeValue);
};

export const getLocalStorageKeyForAdLevel = ({
  userId,
  accountId,
  salesChannelId,
}: UserSalesChannelProp) =>
  `${ADSMANAGER_STORAGE_KEY}_${userId}_${accountId}_${salesChannelId}_adLevel`;

export const getAdLevelFromLocalStorage = (
  userId: string,
  accountId: string,
  salesChannelId: SalesChannelId
): string | null => {
  const adLevelStorageKey = getLocalStorageKeyForAdLevel({
    userId,
    accountId,
    salesChannelId,
  });
  return Fw2LocalStorage.getItem(adLevelStorageKey);
};

export const storeAdLevelToLocalStorage = (
  userId: string,
  accountId: string,
  salesChannelId: SalesChannelId,
  adTypeValue: string
): void => {
  const adLevelStorageKey = getLocalStorageKeyForAdLevel({
    userId,
    accountId,
    salesChannelId,
  });
  Fw2LocalStorage.setItem(adLevelStorageKey, adTypeValue);
};

export const getSessionStorageKeyForDetailsPageAdLevel = (
  userId: string,
  currentAccountId: string
) => `${userId}_${currentAccountId}_details_page_ad_level`;

export const getDetailsPageSelectedAdLevelFromSessionStorage = (
  userId: string,
  accountId: string
): string => {
  const adLevelStorageKey = getSessionStorageKeyForDetailsPageAdLevel(
    userId,
    accountId
  );
  const adLevelFromSessionStorage =
    Fw2SessionStorage.getItem(adLevelStorageKey);
  try {
    return JSON.parse(adLevelFromSessionStorage as string);
  } catch (e) {
    return '';
  }
};

export const storeDetailsPageSelectedAdLevelToSessionStorage = (
  userId: string,
  accountId: string,
  selectedAdLevel: string
): void => {
  const adLevelStorageKey = getSessionStorageKeyForDetailsPageAdLevel(
    userId,
    accountId
  );
  Fw2SessionStorage.setItem(adLevelStorageKey, JSON.stringify(selectedAdLevel));
};

export const removeStoreDetailsTargetAdLevelSessionStorage = (
  userId: string,
  accountId: string
): void => {
  const adLevelStorageKey = getSessionStorageKeyForDetailsPageAdLevel(
    userId,
    accountId
  );
  Fw2SessionStorage.removeItem(adLevelStorageKey);
};

export const getStartDateEndDateFromLocalStorage = ({
  userId,
  accountId,
  salesChannelId,
}: UserSalesChannelProp):
  | Pick<DateRange, 'initialStartDate' | 'initialEndDate'>
  | undefined => {
  const userSalesChannelData: UserSalesChannelProp = {
    userId,
    accountId,
    salesChannelId,
  };
  const startDateEndateStorageKey =
    getLocalStorageKeyForStarDateEndDate(userSalesChannelData);

  const startDateEndDateFromLocalStorage = Fw2LocalStorage.getItem(
    startDateEndateStorageKey
  );

  if (startDateEndDateFromLocalStorage && userId) {
    const [previousStartDate, previousEndDate] =
      startDateEndDateFromLocalStorage.split(DATE_RANGE_CONNECTOR);

    if (previousStartDate && previousEndDate) {
      return {
        initialStartDate: DateTime.fromFormat(
          previousStartDate,
          SESSION_STORAGE_DATE_FORMAT
        ),
        initialEndDate: DateTime.fromFormat(
          previousEndDate,
          SESSION_STORAGE_DATE_FORMAT
        ),
      };
    }
  }
};

export const storeStartDateEndDateInLocalStorage = (
  { userId, accountId, salesChannelId }: UserSalesChannelProp,
  {
    initialEndDate,
    initialStartDate,
  }: Pick<DateRange, 'initialStartDate' | 'initialEndDate'>
): void => {
  const userSalesChannelData = { userId, accountId, salesChannelId };
  const startDateEndateStorageKey =
    getLocalStorageKeyForStarDateEndDate(userSalesChannelData);

  if (userId) {
    const formattedStartDate = initialStartDate.toFormat(
      SESSION_STORAGE_DATE_FORMAT
    );

    const formattedEndDate = initialEndDate.toFormat(
      SESSION_STORAGE_DATE_FORMAT
    );

    Fw2LocalStorage.setItem(
      startDateEndateStorageKey,
      `${formattedStartDate}${DATE_RANGE_CONNECTOR}${formattedEndDate}`
    );
  }
};

export const getBreadCrumbDetailsFromLocalStorage = (
  userId: string,
  accountId: string,
  campaignId: string
): AppHeaderTitleProps | null | '' => {
  const breadCrumbLocalStorageKey = getLocalStorageKeyForBreadCrumb(
    userId,
    accountId,
    campaignId
  );
  const appHeaderTitleFromSessionStorage = Fw2LocalStorage.getItem(
    breadCrumbLocalStorageKey
  );

  try {
    return (
      appHeaderTitleFromSessionStorage &&
      (JSON.parse(appHeaderTitleFromSessionStorage) as AppHeaderTitleProps)
    );
  } catch (e) {
    return '';
  }
};

export const storeBreadCrumbDetailsInLocalStorage = (
  userId: string,
  accountId: string,
  campaignId: string,
  appHeaderTitle: AppHeaderTitleProps
): void => {
  const breadCrumbLocalStorageKey = getLocalStorageKeyForBreadCrumb(
    userId,
    accountId,
    campaignId
  );
  Fw2LocalStorage.setItem(
    breadCrumbLocalStorageKey,
    JSON.stringify(appHeaderTitle)
  );
};

export const removeBreadCrumbDetailsInLocalStorage = (
  userId: string,
  accountId: string
): void => {
  forIn(window.localStorage, (value: string, objKey: string) => {
    if (
      startsWith(objKey, `${ADSMANAGER_STORAGE_KEY}_${userId}_${accountId}_`) &&
      endsWith(objKey, '_breadcrumb')
    ) {
      Fw2LocalStorage.removeItem(objKey);
    }
  });
};

export const getLocalStorageKeyForBreadCrumb = (
  userId: string,
  currentAccountId: string,
  campaignId: string
) => `${userId}_${currentAccountId}_${campaignId}_breadcrumb`;

export const getTabCheckboxColumnSelectionInfoInLocalStorage = (
  userId: string,
  accountId: string
): DataInspectorColumnManagerConfigProps | null | '' => {
  const adLevelManageColumnsStorageKey = getLocalStorageKeyForManageColumns({
    userId,
    accountId,
  });
  const allAdLevelColumnsFromLocalStorage = Fw2LocalStorage.getItem(
    adLevelManageColumnsStorageKey
  );
  return (
    allAdLevelColumnsFromLocalStorage &&
    (JSON.parse(
      allAdLevelColumnsFromLocalStorage
    ) as DataInspectorColumnManagerConfigProps)
  );
};

export const storeTabCheckboxColumnSelectionInfoInLocalStorage = (
  userId: string,
  accountId: string,
  allAdLevelcolumns: DataInspectorColumnManagerConfigProps
): void => {
  const allAdLevelColumnsFromLocalStorageKey =
    getLocalStorageKeyForManageColumns({
      userId,
      accountId,
    });
  Fw2LocalStorage.setItem(
    allAdLevelColumnsFromLocalStorageKey,
    JSON.stringify(allAdLevelcolumns)
  );
};

export const getLocalStorageKeyForManageColumns = ({
  userId,
  accountId,
}: UserProps) => `${userId}_${accountId}_manage_columns`;

/**
 * If details page, filters are fetched from session storage
 */
export const getFiltersFromBrowserStorage = (
  userId: string,
  accountId: string,
  adLevel: AdLevel,
  adType: AdType,
  salesChannel: FlywheelSalesChannel,
  salesChannelId: string = '',
  adLevelId?: string
): Filter[] => {
  const BrowserStorage = adLevelId ? Fw2SessionStorage : Fw2LocalStorage;
  const filtersBrowserStorageKey = getBrowserStorageKeyForFilters(
    userId,
    accountId,
    adType,
    adLevel,
    salesChannelId,
    adLevelId
  );
  const filtersFromBrowserStorage = BrowserStorage.getItem(
    filtersBrowserStorageKey
  );

  try {
    return filtersFromBrowserStorage
      ? (JSON.parse(filtersFromBrowserStorage) as Filter[]).filter(
          (filter) => filter.field !== CAMPAIGNS_API_COLUMN_NAME.Group
        )
      : getDefaultFilters(salesChannel, adType, adLevel);
  } catch (e) {
    return getDefaultFilters(salesChannel, adType, adLevel);
  }
};
/**
 * If details page, filters are stored in session and local storage
 */
export const storeFiltersInLocalStorage = (
  userId: string,
  accountId: string,
  adType: string,
  adLevel: string,
  filters: Filter[],
  salesChannelId: string = '',
  adLevelId?: string
): void => {
  if (adLevelId) {
    const filtersLocalStorageKeyForSessionStorage =
      getBrowserStorageKeyForFilters(
        userId,
        accountId,
        adType,
        adLevel,
        salesChannelId,
        adLevelId
      );
    Fw2SessionStorage.setItem(
      filtersLocalStorageKeyForSessionStorage,
      JSON.stringify(filters)
    );
  }
  const filtersLocalStorageKey = getBrowserStorageKeyForFilters(
    userId,
    accountId,
    adType,
    adLevel,
    salesChannelId,
    adLevelId
  );
  Fw2LocalStorage.setItem(filtersLocalStorageKey, JSON.stringify(filters));
};

export const getBrowserStorageKeyForFilters = (
  userId: string,
  accountId: string,
  adType: string,
  adLevel: string,
  salesChannelId: string = '',
  adLevelId?: string
) => {
  const adLevelIdWithKey = adLevelId
    ? `${DETAILS_PAGE_FILTER_KEY}-${adLevelId}`
    : '';
  return `${ADSMANAGER_STORAGE_KEY}_${userId}_${accountId}_${adType}_${adLevel}_${salesChannelId}_${adLevelIdWithKey}_filters`;
};

export const removeFiltersInLocalStorage = (
  userId: string,
  accountId: string,
  salesChannelId: string = '',
  adLevelId?: string
): void => {
  const adLevelList = Object.values(AdLevel);
  adLevelList.forEach((adLevel: string) => {
    Fw2LocalStorage.removeItem(
      getBrowserStorageKeyForFilters(
        userId,
        accountId,
        adLevel,
        salesChannelId,
        adLevelId
      )
    );
  });
};

export const removeAllDetailsPageFilters = (
  userId: string,
  accountId: string
) => {
  forIn(window.localStorage, (value: string, objKey: string) => {
    if (
      startsWith(objKey, `${ADSMANAGER_STORAGE_KEY}_${userId}_${accountId}_`) &&
      endsWith(objKey, '_filters') &&
      includes(objKey, DETAILS_PAGE_FILTER_KEY)
    ) {
      Fw2LocalStorage.removeItem(objKey);
      Fw2SessionStorage.removeItem(objKey);
    }
  });
};

const DETAILS_PAGE_FILTER_KEY = 'details-page-id';
const MAX_ROW_PER_PAGE_KEY = 'maxRowPerPage';

export const storeMaxRowPerPageInStorage = (
  userId: string,
  maxRowPerPage: string
): void => {
  const maxRowsPerPageStorageKey = `${ADSMANAGER_STORAGE_KEY}_${userId}_${MAX_ROW_PER_PAGE_KEY}`;
  Fw2LocalStorage.setItem(maxRowsPerPageStorageKey, maxRowPerPage);
};

export const getMaxRowsPerPageFromStorage = (userId: string): string | null => {
  const maxRowsStorageKey = `${ADSMANAGER_STORAGE_KEY}_${userId}_${MAX_ROW_PER_PAGE_KEY}`;
  return Fw2LocalStorage.getItem(maxRowsStorageKey);
};

export const getLocalStorageKeyForDetailsTargetAdLevel = ({
  userId,
  accountId,
  salesChannelId,
}: UserSalesChannelProp) =>
  `${ADSMANAGER_STORAGE_KEY}_${userId}_${accountId}_${salesChannelId}_details_page_target_adLevel`;

export const getDetailsTargetAdLevelFromLocalStorage = (
  userId: string,
  accountId: string,
  salesChannelId: SalesChannelId
): string | null => {
  const adLevelStorageKey = getLocalStorageKeyForDetailsTargetAdLevel({
    userId,
    accountId,
    salesChannelId,
  });
  return Fw2LocalStorage.getItem(adLevelStorageKey);
};

export const storeDetailsTargetAdLevelToLocalStorage = (
  userId: string,
  accountId: string,
  salesChannelId: SalesChannelId,
  adTypeValue: string
): void => {
  const adLevelStorageKey = getLocalStorageKeyForDetailsTargetAdLevel({
    userId,
    accountId,
    salesChannelId,
  });
  Fw2LocalStorage.setItem(adLevelStorageKey, adTypeValue);
};

const getLocalStorageKeyForManageSlideoverConfig = ({
  userId,
  accountId,
}: UserInfo) => `${userId}_${accountId}_ao_manage_slideover_config`;

export const getManageSlideoverConfigFromLocalStorage = (
  userInfo: UserInfo
): MetricsData[] | null => {
  try {
    const key = getLocalStorageKeyForManageSlideoverConfig(userInfo);
    const manageSlideoverConfig = Fw2LocalStorage.getItem(key);
    return manageSlideoverConfig ? JSON.parse(manageSlideoverConfig) : null;
  } catch (error) {
    return null;
  }
};

export const setManageSlideoverConfigFromLocalStorage = (
  userInfo: UserInfo,
  manageSlideoverConfig: MetricsData[]
) =>
  Fw2LocalStorage.setItem(
    getLocalStorageKeyForManageSlideoverConfig(userInfo),
    JSON.stringify(manageSlideoverConfig)
  );
