import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { TargetsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { TARGETS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) => {
  return makeTextColumn(
    ({ targetDetails }: TargetsDetails) =>
      targetDetails?.campaignPortfolioName
        ? targetDetails?.campaignPortfolioName
        : undefined,
    props.pendingFields?.includes(TARGETS_API_COLUMN_NAME.Portfolio)
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const portfolioNameColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.Portfolio,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_PORTFOLIO_NAME,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const portfolioNameFilter = createStringDataFieldFilter(
  TARGETS_API_COLUMN_NAME.Portfolio,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_PORTFOLIO_NAME,
  isValidString()
);
