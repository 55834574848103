import { useContext } from 'react';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  MerchantContext,
  MerchantContextState,
} from '../../../merchantsProvider';
import { PRODUCT_ADS_API_COLUMN_NAME, getMerchantName } from '../utils';

export const RowCellElement: React.FC<ProductAdDetails> = ({
  adItemDetails,
  adItemPerformance,
  adItemId,
  channelSettings,
}) => {
  const adItemData: ProductAdDetails = {
    adItemDetails,
    adItemPerformance,
    adItemId,
    channelSettings,
  };

  const merchantContext = useContext<MerchantContextState>(MerchantContext);

  const merchantDetails = merchantContext.merchantDetails;

  return makeTextColumn((data: ProductAdDetails) =>
    getMerchantName(merchantDetails, data.adItemDetails.merchantCountryId)
  )(adItemData);
};
RowCellElement.displayName = 'RowCellElement';

export const merchantNameColumn: FlywheelTableColumn<ProductAdDetails> = {
  columnName: PRODUCT_ADS_API_COLUMN_NAME.MerchantName,
  isSortable: false,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_MERCHANT_NAME,
  RowCellElement,
  gridColumnWidth: '240px',
};
