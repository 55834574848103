import {
  AmazonDataIcon,
  CycleDotIcon,
  Icon,
  IconSize,
  TextLink,
  WalmartDataIcon,
} from '@teikametrics/tm-design-system';
import { AMAZON_SALES_CHANNEL_ID } from '../../../../../lib/types/SalesChannels';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  MerchantSyncData,
  MerchantSyncDate,
} from '../../../../../lib/types/AOSharedTypes';
import {
  ConnectionStatus,
  EntityType,
  FAMConnectionStatus,
  FlywheelSalesChannel,
  SalesChannelData,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  getConnectionStatus,
  getRetryConnection,
  getStatusData,
  getSyncStatusIcon,
  isDataSyncBehind,
} from '../../../containers/salesChannels/utils';
import { AmazonWalmartFlashScreen } from '../../AmazonWalmartFlashScreen';

interface ConnectionStatusComponentProps {
  readonly adConnectionStatus?: ConnectionStatus;
  readonly productConnectionStatus?: ConnectionStatus;
  readonly lastSyncDate?: string;
  readonly adConnectionData?: MerchantSyncDate;
  readonly productConnectionData?: MerchantSyncDate;
  readonly salesChannelData: SalesChannelData & MerchantSyncData;
  readonly showDataTimingAndSyncSlideout?: () => void;
  readonly refreshList: () => Promise<void>;
}

const getSalesChannelDataLogo = (isAmazon: boolean) =>
  isAmazon ? AmazonDataIcon : WalmartDataIcon;

export const ConnectionStatusComponent: React.FC<
  ConnectionStatusComponentProps
> = (props) => {
  const intl = useIntl();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [entityType, setEntityType] = useState<EntityType>(EntityType.Ads);

  const onLinkClick = (entity: EntityType) => {
    setEntityType(entity);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    if (isAmazon) {
      props.refreshList();
    }
  };

  const { salesChannelData } = props;

  const advertisingLabel = intl.formatMessage({
    id: I18nKey.SYNC_STATUS_SLIDE_OUT_COLUMN_AD,
  });

  const productsLabel = intl.formatMessage({
    id: I18nKey.SYNC_STATUS_SLIDE_OUT_COLUMN_PRODUCT,
  });

  const moreInfoLabel = intl.formatMessage({
    id: I18nKey.SALES_CHANNELS_SYNCING_SLIDEOUT_MORE_INFO,
  });

  const connectionLabel = intl.formatMessage({
    id: I18nKey.SALES_CHANNELS_SYNCING_SLIDEOUT_CONNECTION,
  });

  const isAmazon = salesChannelData.salesChannelId === AMAZON_SALES_CHANNEL_ID;
  const adConnectionStatus = getConnectionStatus(
    EntityType.Ads,
    salesChannelData?.adConnectionStatus,
    salesChannelData?.productConnectionStatus
  );

  const isAdsNotConnected = [
    FAMConnectionStatus.Inactive,
    ConnectionStatus.NotConnected,
  ].includes(adConnectionStatus as FAMConnectionStatus | ConnectionStatus);
  const productConnectionStatus = getConnectionStatus(
    EntityType.Products,
    salesChannelData?.adConnectionStatus,
    salesChannelData?.productConnectionStatus
  );
  const isProductsNotConnected = [
    FAMConnectionStatus.Inactive,
    ConnectionStatus.NotConnected,
  ].includes(productConnectionStatus as FAMConnectionStatus | ConnectionStatus);
  const adStatus = getStatusData(
    adConnectionStatus,
    isAmazon,
    salesChannelData?.adConnectionData?.lastSyncedAt ?? ''
  );
  const productStatus = getStatusData(
    productConnectionStatus,
    isAmazon,
    salesChannelData?.productConnectionData?.lastSyncedAt ?? ''
  );
  const adRetryConnection = getRetryConnection(adConnectionStatus);
  const productRetryConnection = getRetryConnection(productConnectionStatus);
  const adSyncStatus = getSyncStatusIcon(
    adConnectionStatus,
    adRetryConnection,
    intl,
    EntityType.Ads,
    salesChannelData?.adConnectionData,
    salesChannelData?.productConnectionData
  );

  const productSyncStatus = getSyncStatusIcon(
    productConnectionStatus,
    productRetryConnection,
    intl,
    EntityType.Products,
    salesChannelData?.adConnectionData,
    salesChannelData?.productConnectionData
  );

  const isAdsDataBehind = isDataSyncBehind(
    salesChannelData?.adConnectionData?.lastSyncedAt ?? ''
  );
  const isProductsDataBehind = isDataSyncBehind(
    salesChannelData?.productConnectionData?.lastSyncedAt ?? ''
  );

  return (
    <div className="flex w-446 h-92">
      <AmazonWalmartFlashScreen
        {...salesChannelData}
        entity={entityType}
        channel={
          isAmazon ? FlywheelSalesChannel.Amazon : FlywheelSalesChannel.Walmart
        }
        closeModal={closeModal}
        showModal={showModal}
      />
      <div className="flex flex-col w-200 h-92">
        <h4 className="text-sm leading-tight font-medium text-grey-700 my-4">
          {advertisingLabel}
        </h4>
        <div className="flex my-4">
          <div className="flex self-stretch items-center pt-2 w-24">
            <Icon
              svg={getSalesChannelDataLogo(isAmazon)}
              size={IconSize.Large}
            />
          </div>
          <label className="text-grey-600 text-sm leading-tight font-normal flex self-stretch items-center ml-12 whitespace-nowrap">
            {adSyncStatus?.earliestSyncDateValue}
          </label>
        </div>
        <div className="flex my-4">
          <div className="flex self-stretch items-center pt-2 ml-4 w-24">
            <Icon
              svg={CycleDotIcon}
              size={IconSize.Small}
              className="text-grey-500"
            />
          </div>
          <label className="text-grey-600 text-sm leading-tight font-normal flex self-stretch items-center ml-8 whitespace-nowrap">
            {adSyncStatus?.value}
          </label>
        </div>
        <div className="flex mt-4">
          <div className="flex self-stretch items-center pt-2 ml-4 w-24">
            {adSyncStatus ? (
              <Icon svg={adSyncStatus.icon} size={IconSize.Small} />
            ) : null}
          </div>
          <label className="text-grey-600 text-sm leading-tight font-normal flex self-stretch items-center ml-8 whitespace-nowrap">
            <FormattedMessage id={adStatus?.text} />
            {isAdsDataBehind && !isAdsNotConnected ? (
              <TextLink
                textLabel={moreInfoLabel}
                className="text-grey-600 text-sm leading-tight font-normal ml-4 whitespace-nowrap"
                onClick={() => {
                  props.showDataTimingAndSyncSlideout &&
                    props.showDataTimingAndSyncSlideout();
                }}
              />
            ) : null}
            {isAdsNotConnected ? (
              <TextLink
                textLabel={connectionLabel}
                onClick={() => onLinkClick(EntityType.Ads)}
                className="text-grey-600 text-sm leading-tight font-normal ml-4"
              />
            ) : null}
          </label>
        </div>
      </div>
      <div className="w-1 border-collapse border-l border-solid border-grey-200 mt-8 mx-16 self-stretch"></div>
      <div className="flex flex-col w-200 h-92">
        <h4 className="text-sm leading-tight font-medium text-grey-700 my-4">
          {productsLabel}
        </h4>
        <div className="flex my-4">
          <div className="flex self-stretch items-center pt-2">
            <Icon
              svg={getSalesChannelDataLogo(isAmazon)}
              size={IconSize.Large}
            />
          </div>
          <label className="text-grey-600 text-sm leading-tight font-normal flex self-stretch items-center ml-12">
            {productSyncStatus?.earliestSyncDateValue}
          </label>
        </div>
        <div className="flex my-4">
          <div className="flex self-stretch items-center pt-2 ml-4">
            <Icon
              svg={CycleDotIcon}
              size={IconSize.Small}
              className="text-grey-500"
            />
          </div>
          <label className="text-grey-600 text-sm leading-tight font-normal flex self-stretch items-center ml-14 whitespace-nowrap">
            {productSyncStatus?.value}
          </label>
        </div>
        <div className="flex mt-4">
          <div className="flex self-stretch items-center pt-2 ml-4">
            {productSyncStatus ? (
              <Icon
                svg={productSyncStatus.icon}
                size={IconSize.Small}
                className="text-green-500 fill-green-500"
              />
            ) : null}
          </div>
          <label className="text-grey-600 text-sm leading-tight font-normal flex self-stretch items-center ml-14">
            <FormattedMessage id={productStatus.text} />
            {isProductsDataBehind && !isProductsNotConnected ? (
              <TextLink
                textLabel={moreInfoLabel}
                className="text-grey-600 text-sm leading-tight font-normal ml-4"
                onClick={() => {
                  props.showDataTimingAndSyncSlideout &&
                    props.showDataTimingAndSyncSlideout();
                }}
              />
            ) : null}
            {isProductsNotConnected ? (
              <TextLink
                textLabel={connectionLabel}
                onClick={() => onLinkClick(EntityType.Products)}
                className="text-grey-600 text-sm leading-tight font-normal ml-4"
              />
            ) : null}
          </label>
        </div>
      </div>
    </div>
  );
};
