import classNames from 'classnames';
import { useIntl } from 'react-intl';

import {
  AlertTriangleIcon,
  Icon,
  IconSize,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../lib/types/I18nKey';
import { talkToUs } from '../../../../lib/utilities/intercom';

export interface WalmartCommonFailureBannerProps {
  readonly errorMessage?: string;
}

export const WalmartCommonFailureBanner: React.FC<
  WalmartCommonFailureBannerProps
> = ({ errorMessage }) => {
  const intl = useIntl();

  const openIntercomChat = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    talkToUs();
  };

  const failureHeader = intl.formatMessage({
    id: I18nKey.GENERIC_SOMETHING_WENT_WRONG,
  });

  const failureMessage = errorMessage
    ? intl.formatMessage<React.ReactNode>(
        {
          id: I18nKey.FLASH_MODALS_WALMART_OAUTH_ERROR_API_RESPONSE,
        },
        {
          apiResponseMessage: errorMessage,
          underline: (content) => (
            <span
              className="underline cursor-pointer"
              onClick={openIntercomChat}
            >
              {content}
            </span>
          ),
        }
      )
    : intl.formatMessage<React.ReactNode>(
        {
          id: I18nKey.FLASH_MODALS_WALMART_OAUTH_ERROR_GENERIC,
        },
        {
          underline: (content) => (
            <span
              className="underline cursor-pointer"
              onClick={openIntercomChat}
            >
              {content}
            </span>
          ),
        }
      );

  return (
    <div
      className={classNames(
        'flex flex-row',
        'w-full bg-orange-100 rounded-4 p-16 mt-12'
      )}
    >
      <div className="flex">
        <Icon
          svg={AlertTriangleIcon}
          size={IconSize.Large}
          className="text-orange-500"
        />
      </div>
      <div className="flex flex-col ml-12 pt-4">
        <h3 className="flex text-base leading-tight font-medium text-grey-900">
          {failureHeader}
        </h3>
        <div className="mt-4 text-sm leading-tight font-normal text-grey-900 text-left">
          {failureMessage}
        </div>
      </div>
    </div>
  );
};
