import { AllSalesChannel } from '../../../../lib/types/Fam';

export const sortSalesChannels = (
  salesChannels: AllSalesChannel[],
  orderedSalesChannels: string[]
) => {
  return [...salesChannels].sort(
    (a, b) =>
      orderedSalesChannels.indexOf(a.id) - orderedSalesChannels.indexOf(b.id)
  );
};
