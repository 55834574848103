import capitalize from 'lodash/capitalize';

import {
  MultiSelectOption,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  FlywheelSalesChannel,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TARGETS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<TargetsDetails> = (targetDetails) => {
  return makeTextColumn(
    (data: TargetsDetails) =>
      data.targetDetails.campaignStatus
        ? capitalize(data.targetDetails.campaignStatus)
        : undefined,
    targetDetails?.pendingFields?.includes(
      TARGETS_API_COLUMN_NAME.CampaignStatus
    )
  )(targetDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignStatusColumn: FlywheelTableColumn<TargetsDetails> = {
  columnName: TARGETS_API_COLUMN_NAME.CampaignStatus,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_STATUS,
  RowCellElement,
  gridColumnWidth: '160px',
};

export const campaignStatusFilter = (
  selectedSalesChannel?: FlywheelSalesChannel,
  selectedAdType?: AdType
) =>
  createMultiSelectDataFieldFilter(
    TARGETS_API_COLUMN_NAME.CampaignStatus,
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_STATUS,
    getCampaignStatusOptions(selectedSalesChannel, selectedAdType)
  );

const getCampaignStatusOptions = (
  selectedSalesChannel?: FlywheelSalesChannel,
  selectedAdType?: AdType
): MultiSelectOption[] => {
  switch (selectedSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      return [
        SelectFilterOption.CampaignStatusPaused,
        SelectFilterOption.CampaignStatusCompleted,
        SelectFilterOption.CampaignStatusRescheduled,
        SelectFilterOption.CampaignStatusLive,
        SelectFilterOption.CampaignStatusProposal,
        SelectFilterOption.CampaignStatusScheduled,
      ];
    case FlywheelSalesChannel.Amazon:
      switch (selectedAdType) {
        case AdType.SponsoredProducts:
        case AdType.SponsoredDisplay:
        case AdType.SponsoredBrands:
          return [
            SelectFilterOption.CampaignStatusEnabled,
            SelectFilterOption.CampaignStatusPaused,
            SelectFilterOption.CampaignStatusArchived,
          ];
        // The fallback default should never occur hence safe to return [], can handle with default options in future
        default:
          return [];
      }
    /**
      The fallback default should never occur as Sales Channel will always fall in one of above 
      cases hence safe to return [], can handle with default options in future
    * 
    */
    default:
      return [];
  }
};
