import find from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Alignment,
  BulkEditModalElement,
  Button,
  ButtonSize,
  ButtonVariant,
  CheckboxCheckedState,
  ContentStyle,
  GraphTrendUpIcon,
  Placement,
  SelectVisibility,
  Theme,
  Tooltip,
  TooltipSize,
  createStringDataFieldFilter,
  isValidString,
  Type as toastType,
} from '@teikametrics/tm-design-system';

import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import { NotificationContextState } from '../../../../../containers/notificationProvider';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import {
  TableChange,
  TableReducerState,
  TableSelectSettings,
  WithTable,
} from '../../../../../containers/table/ducks/types';
import {
  makeCheckboxLabelColumn,
  makeTextWithSubtextAndImageColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupStatus,
  AdType,
  CampaignStatus,
  EMPTY_STRING,
  FlywheelSalesChannel,
  MerchantCountryCode,
  ProductAdDetails,
} from '../../../../../lib/types/AOSharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import { MerchantType } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../../lib/types/SalesChannels';
import { ADS_MANAGER_AD_ITEMS_TABLE_ID } from '../ducks/types';
import {
  ADITEM_BID_AUTO_MIN_VALUE,
  AD_ITEMS_EXT_ITEM_ID_FILTER_FIELD,
  TableDataAdsManager,
} from '../types';
import {
  PRODUCT_ADS_API_COLUMN_NAME,
  ProductAdStatus,
  ProductAdStatusOptions,
  ProductAdStatusValue,
  getCheckBoxLabelStateForProductAds,
  isMerchantTypeSeller,
} from '../utils';

type BulkEditMenuValue =
  | typeof PRODUCT_ADS_API_COLUMN_NAME.Status
  | typeof PRODUCT_ADS_API_COLUMN_NAME.Bid;

export interface VisibilityCriteria {
  readonly adTypes: AdType[];
  readonly salesChannel: FlywheelSalesChannel[];
}

export interface BulkEditMenuOption {
  readonly label: string;
  readonly value: BulkEditMenuValue;
  readonly elements: BulkEditModalElement[];
  readonly visibilityCriteria: VisibilityCriteria;
}

const BULK_EDIT_MODAL_MODAL_DESCRIPTION_HEADER_TO_I18_TEXT_MAPPER: Record<
  FlywheelSalesChannel,
  I18nKey
> = {
  [FlywheelSalesChannel.Walmart]:
    I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_BULK_EDIT_MODAL_BODY,
  [FlywheelSalesChannel.Amazon]:
    I18nKey.ADS_MANAGER_PRODUCT_AD_TABLE_BULK_EDIT_MODAL_BODY,
};

export const getState = (data: ProductAdDetails) => {
  if (data.channelSettings.status === ProductAdStatusValue.Archived) {
    return String(data.channelSettings.status);
  }
  if (data.adItemDetails.adGroupStatus === AdGroupStatus.Archived) {
    return String(data.adItemDetails.adGroupStatus);
  }
  if (data.adItemDetails.campaignStatus === CampaignStatus.Archived) {
    return String(data.adItemDetails.campaignStatus);
  }
  return EMPTY_STRING;
};

const getSelectSettings = (
  tableState: TableReducerState<ProductAdDetails, void>
) => {
  return tableSelectors.getSelectInfoSelector()(
    tableState,
    ADS_MANAGER_AD_ITEMS_TABLE_ID
  );
};

export const ProductViewTrendLink: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <div className="mr-8 mt-8">
      <Tooltip
        content={<FormattedMessage id={I18nKey.GENERIC_VIEW_TRENDS} />}
        theme={Theme.Dark}
        dataTestId="sb_tooltip"
        hideArrow={true}
        tooltipSize={TooltipSize.Small}
        showOnClick={false}
        disabled={false}
        style={ContentStyle.Normal}
        strategy="absolute"
        position={{
          placement: Placement.Left,
          alignment: Alignment.Center,
        }}
        overwrittenTooltipClassnames="text-base"
      >
        <Button
          size={ButtonSize.InlineIconMedium}
          variant={ButtonVariant.NoStyle}
          svgIcon={GraphTrendUpIcon}
          dataTestId={`product_ads_view_trends`}
          onClick={onClick}
        />
      </Tooltip>
    </div>
  );
};
ProductViewTrendLink.displayName = 'ProductNameViewTrendLink';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = ({
  adItemPerformance,
  adItemDetails,
  adItemId,
  channelSettings,
  isEditMode,
  salesChannel,
  merchantType,
  merchantCountry,
  onViewTrendsSlideoverClick,
  selectedMerchantCountries,
}) => {
  const dispatch = useDispatch();

  const selectSettings = useSelector<
    WithTable<ProductAdDetails>,
    TableSelectSettings
  >(({ tableState }) => {
    return getSelectSettings(tableState);
  });

  const adItemData: ProductAdDetails = {
    adItemPerformance,
    adItemDetails,
    adItemId,
    channelSettings,
  };

  const isRowSelected = selectSettings.rows.includes(adItemId);

  let productTextColumn = getProductTextColumn(
    salesChannel,
    adItemData,
    merchantType,
    merchantCountry
  );

  if (isEditMode) {
    productTextColumn = makeCheckboxLabelColumn((data: ProductAdDetails) => {
      const { label, subLabel } = getProductColumnCheckBoxLabels(
        salesChannel,
        data,
        merchantType
      );
      return {
        dataTestId: PRODUCT_ADS_API_COLUMN_NAME.ProductName,
        label,
        subLabel,
        checked: isRowSelected
          ? CheckboxCheckedState.Checked
          : CheckboxCheckedState.Unchecked,
        state: getCheckBoxLabelStateForProductAds(salesChannel, getState(data)),
        onClick: () =>
          dispatch(
            tableActions.toggleSelectedItem({
              tableId: ADS_MANAGER_AD_ITEMS_TABLE_ID,
              rowId: data.adItemId,
            })
          ),
        classNameLabel: 'flex-1',
        className: 'align-start',
        imageUrl:
          data.adItemDetails.productName && data.adItemDetails.productImage,
        externalUrl: getExternalUrl(salesChannel, data, merchantCountry),
      };
    })(adItemData);
  }

  return (
    <>
      <div className="w-full flex flex-row justify-between">
        {productTextColumn}
        <ProductViewTrendLink
          onClick={() =>
            onViewTrendsSlideoverClick?.({
              salesChannelId:
                salesChannel === FlywheelSalesChannel.Amazon
                  ? AMAZON_SALES_CHANNEL_ID
                  : WALMART_SALES_CHANNEL_ID,
              merchantCountryId: (isArray(selectedMerchantCountries)
                ? first(selectedMerchantCountries)
                : selectedMerchantCountries) as string,
              sku: adItemDetails.sku || '',
            })
          }
        />
      </div>
    </>
  );
};
RowCellElement.displayName = 'RowCellElement';

export const getProductColumnCheckBoxLabels = (
  salesChannel: FlywheelSalesChannel,
  data: ProductAdDetails,
  merchantType?: MerchantType
) => {
  switch (salesChannel) {
    case FlywheelSalesChannel.Amazon:
      let checkboxSubLabel: string | undefined;
      if (isMerchantTypeSeller(merchantType)) {
        checkboxSubLabel = data.adItemDetails.sku || '';
      } else {
        checkboxSubLabel = data.adItemDetails.extItemId || '';
      }
      return {
        label: data.adItemDetails.productName || checkboxSubLabel,
        subLabel: data.adItemDetails.productName ? checkboxSubLabel : '',
      };
    case FlywheelSalesChannel.Walmart:
    default:
      return {
        label: !isEmpty(data.adItemDetails.productName)
          ? data.adItemDetails.productName
          : data.adItemDetails.extItemId,
        subLabel:
          !isEmpty(data.adItemDetails.productName) &&
          data.adItemDetails.extItemId,
      };
  }
};

export const getExternalUrl = (
  salesChannel: FlywheelSalesChannel,
  data: ProductAdDetails,
  merchantCountryCode: MerchantCountryCode
) => {
  switch (salesChannel) {
    case FlywheelSalesChannel.Amazon:
      return getAmazonItemPageUrl(
        merchantCountryCode,
        data.adItemDetails?.extItemId
      );
    case FlywheelSalesChannel.Walmart:
    default:
      return data.adItemDetails.itemPageUrl;
  }
};

export const getAmazonItemPageUrl = (
  merchantCountryCode?: MerchantCountryCode,
  skuOrAsin?: string
): string | undefined => {
  let pageUrl;
  if (merchantCountryCode && skuOrAsin) {
    pageUrl = !isEmpty(
      MERCHANT_COUNTRY_CODE_TO_BASE_EXTERNAL_URL_MAPPER[merchantCountryCode]
    )
      ? MERCHANT_COUNTRY_CODE_TO_BASE_EXTERNAL_URL_MAPPER[merchantCountryCode] +
        skuOrAsin
      : undefined;
  }
  return pageUrl;
};
const MERCHANT_COUNTRY_CODE_TO_BASE_EXTERNAL_URL_MAPPER: Record<
  MerchantCountryCode,
  string
> = {
  [MerchantCountryCode.US]: 'https://amazon.com/dp/',
  [MerchantCountryCode.CA]: 'https://amazon.ca/dp/',
  [MerchantCountryCode.MX]: 'https://amazon.com.mx/dp/',
  [MerchantCountryCode.BR]: 'https://amazon.com.br/dp/',
  [MerchantCountryCode.UK]: 'https://amazon.co.uk/dp/',
  [MerchantCountryCode.GB]: 'https://amazon.co.uk/dp/',
  [MerchantCountryCode.DE]: 'https://amazon.de/dp/',
  [MerchantCountryCode.FR]: 'https://amazon.fr/dp/',
  [MerchantCountryCode.ES]: 'https://amazon.es/dp/',
  [MerchantCountryCode.IT]: 'https://amazon.it/dp/',
  [MerchantCountryCode.NL]: 'https://amazon.nl/dp/',
  [MerchantCountryCode.JP]: 'https://amazon.co.jp/dp/',
  [MerchantCountryCode.AU]: 'https://amazon.com.au/dp/',
  [MerchantCountryCode.SG]: 'https://amazon.sg/dp/',
  [MerchantCountryCode.AE]: 'https://amazon.ae/dp/',
  [MerchantCountryCode.IN]: 'https://amazon.in/dp/',
  [MerchantCountryCode.KSA]: 'https://amazon.sa/dp/',
  [MerchantCountryCode.SA]: 'https://amazon.sa/dp/',
  [MerchantCountryCode.SE]: 'https://amazon.se/dp/',
  [MerchantCountryCode.BE]: 'https://amazon.com.be',
};

export const toastsInValidChangesCount = (
  intl: IntlShape,
  toasts: NotificationContextState,
  inValidChangesCount: number,
  selectedRowsCount: number
) => {
  if (inValidChangesCount && selectedRowsCount) {
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.ADVERTISING_OPTIMIZATION_BULKEDIT_MODAL_ERROR_TOAST_TITLE,
      }),
      description: intl.formatMessage(
        {
          id: I18nKey.ADVERTISING_OPTIMIZATION_BULKEDIT_MODAL_ERROR_TOAST_MESSAGE,
        },
        {
          invalidNumber: inValidChangesCount,
          totalNumber: selectedRowsCount,
        }
      ),
      type: toastType.Attention,
      dataTestId: 'ao_productNameIncompleteChanges',
    });
  }
};

export const productNameColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  isSortable: true,
  columnName: PRODUCT_ADS_API_COLUMN_NAME.ProductName,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_PRODUCT,
  RowCellElement,
  className: 'cursor-pointer whitespace-normal',
  gridColumnWidth: '380px',
};

export const productNameFilter = createStringDataFieldFilter(
  PRODUCT_ADS_API_COLUMN_NAME.ProductName,
  I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_PRODUCT,
  isValidString()
);

export const extAdItemIdProductAdsFilter = createStringDataFieldFilter(
  AD_ITEMS_EXT_ITEM_ID_FILTER_FIELD,
  I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_AD_ITEM_ID,
  isValidString()
);
export const asinFilter = createStringDataFieldFilter(
  PRODUCT_ADS_API_COLUMN_NAME.ExtItemId,
  I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_ASIN,
  isValidString()
);

export const skuFilter = createStringDataFieldFilter(
  PRODUCT_ADS_API_COLUMN_NAME.Sku,
  I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_SKU,
  isValidString()
);

export const getProductAdsModelDescriptionI18nKey = (
  salesChannel: FlywheelSalesChannel,
  adStatus: ProductAdStatus
): I18nKey => {
  if (salesChannel === FlywheelSalesChannel.Amazon) {
    return PRODUCT_ADS_ADSTATUS_OPTIONS_TO_I18KEY_MAPPER[adStatus];
  } else {
    return ADTEM_ADSTATUS_OPTIONS_TO_I18KEY_MAPPER[adStatus];
  }
};

export const getProductTextColumn = (
  salesChannel: FlywheelSalesChannel,
  adItemData: ProductAdDetails,
  merchantType?: MerchantType,
  merchantCountryCode?: MerchantCountryCode
) => {
  switch (salesChannel) {
    case FlywheelSalesChannel.Amazon:
      if (isMerchantTypeSeller(merchantType)) {
        return makeTextWithSubtextAndImageColumn<ProductAdDetails>(
          (adItemDetails: ProductAdDetails) =>
            adItemDetails.adItemDetails.productName,
          (adItemDetails: ProductAdDetails) =>
            String(adItemDetails.adItemDetails.sku),
          (adItemDetails: ProductAdDetails) =>
            adItemDetails.adItemDetails.productImage,
          ({ adItemDetails }: ProductAdDetails) =>
            getAmazonItemPageUrl(merchantCountryCode, adItemDetails.extItemId),
          true
        )(adItemData);
      } else {
        return makeTextWithSubtextAndImageColumn<ProductAdDetails>(
          (adItemDetails: ProductAdDetails) =>
            adItemDetails.adItemDetails.productName,
          (adItemDetails: ProductAdDetails) =>
            String(adItemDetails.adItemDetails.extItemId),
          (adItemDetails: ProductAdDetails) =>
            adItemDetails.adItemDetails.productImage,
          ({ adItemDetails }: ProductAdDetails) =>
            getAmazonItemPageUrl(merchantCountryCode, adItemDetails.extItemId),
          true
        )(adItemData);
      }
    case FlywheelSalesChannel.Walmart:
    default:
      return makeTextWithSubtextAndImageColumn<ProductAdDetails>(
        (adItemDetails: ProductAdDetails) =>
          adItemDetails.adItemDetails.productName,
        (adItemDetails: ProductAdDetails) =>
          String(adItemDetails.adItemDetails.extItemId),
        (adItemDetails: ProductAdDetails) =>
          adItemDetails.adItemDetails.productImage,
        ({ adItemDetails: { itemPageUrl } }: ProductAdDetails) => itemPageUrl,
        true
      )(adItemData);
  }
};

export const ADTEM_ADSTATUS_OPTIONS_TO_I18KEY_MAPPER: Record<
  ProductAdStatus,
  I18nKey
> = {
  [ProductAdStatus.Enabled]:
    I18nKey.ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ENABLED,
  [ProductAdStatus.Paused]:
    I18nKey.ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_PAUSED,
  [ProductAdStatus.Archived]:
    I18nKey.ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ARCHIVED,
  [ProductAdStatus.Disabled]:
    I18nKey.ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_DISABLED,
};

export const PRODUCT_ADS_ADSTATUS_OPTIONS_TO_I18KEY_MAPPER: Record<
  ProductAdStatus,
  I18nKey
> = {
  [ProductAdStatus.Enabled]:
    I18nKey.ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ENABLED_PRODUCTADS,
  [ProductAdStatus.Paused]:
    I18nKey.ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_PAUSED_PRODUCTADS,
  [ProductAdStatus.Archived]:
    I18nKey.ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ARCHIVED_PRODUCTADS,
  [ProductAdStatus.Disabled]:
    I18nKey.ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_DISABLED_PRODUCTADS,
};

export const shouldShowBulkEditMenu = (
  isEditMode: boolean,
  numberOfSelectionOptions: number
) => isEditMode && numberOfSelectionOptions >= 1;

export const getModalHeader = (
  bulkMenuValue: string | null,
  intl: IntlShape,
  bulkMenuOptions: BulkEditMenuOption[],
  salesChannel: FlywheelSalesChannel
) => {
  if (bulkMenuValue) {
    return intl.formatMessage(
      {
        id:
          bulkMenuValue === PRODUCT_ADS_API_COLUMN_NAME.Bid
            ? I18nKey.ADS_MANAGER_PRODUCT_AD_TABLE_BULK_EDIT_MODAL_HEADER_TEXT
            : BULK_EDIT_MODAL_MODAL_DESCRIPTION_HEADER_TO_I18_TEXT_MAPPER[
                salesChannel
              ],
      },
      {
        column: get(
          find(bulkMenuOptions, { value: bulkMenuValue }),
          'value'
        )?.toLowerCase(),
      }
    );
  } else {
    return '';
  }
};

export const getBidPlaceholderInModal = (
  tableChange: TableChange,
  productAdTableData: ProductAdDetails[]
) => {
  const selectedRowsIds = tableChange.select.rows;
  const firstSelecetedItemId: string = first(selectedRowsIds)!;
  const firstSelectedItemDetails: ProductAdDetails | undefined =
    productAdTableData.find(
      ({ adItemId }) => adItemId === firstSelecetedItemId
    );
  const selectedItemDetails: ProductAdDetails[] = productAdTableData.filter(
    ({ adItemId }) => selectedRowsIds.includes(adItemId)
  );
  const firstItemBid = Number(
    tableChange.cell[firstSelecetedItemId]?.bid ??
      firstSelectedItemDetails?.channelSettings.bid?.amount
  );
  let areAllValuesEqual = selectedItemDetails.every((item) => {
    if (tableChange.cell[item.adItemId]) {
      return Number(tableChange.cell[item.adItemId].bid) === firstItemBid;
    } else {
      return Number(item.channelSettings.bid?.amount) === firstItemBid;
    }
  });

  return areAllValuesEqual && (firstItemBid || firstItemBid === 0)
    ? firstItemBid.toFixed(MONETARY_FRACTION_DIGITS)
    : ADITEM_BID_AUTO_MIN_VALUE.toFixed(MONETARY_FRACTION_DIGITS);
};

export const getEligibleProductAdIds = (
  productAdTableData: ProductAdDetails[]
) =>
  productAdTableData
    .filter((data) => getState(data) !== ProductAdStatusOptions.Archived.value)
    .map(({ adItemId }) => adItemId);

export const getFilteredBulkEditOptions = (
  bulkEditMenuOptionsList: BulkEditMenuOption[],
  adType: AdType | undefined,
  salesChannel: FlywheelSalesChannel
) =>
  bulkEditMenuOptionsList.filter(
    ({ visibilityCriteria }) =>
      adType &&
      salesChannel &&
      visibilityCriteria.salesChannel.includes(salesChannel) &&
      visibilityCriteria.adTypes.includes(adType)
  );

export const getCurrentValueBeingEdited = (
  tableChange: TableChange,
  productAdTableData: ProductAdDetails[],
  valueBeingEdited: string[]
) => {
  const selectedRowsIds = tableChange.select.rows;
  const firstSelectedAdGroupId: string = first(selectedRowsIds)!;

  const selectedAdItems: ProductAdDetails[] = productAdTableData.filter(
    ({ adItemId }) => selectedRowsIds.includes(adItemId)
  );
  const firstSelectedProductAd = first(selectedAdItems);
  const firstEditedProductAdStatus = tableChange.cell[firstSelectedAdGroupId];

  const firstProductAdStatus =
    firstEditedProductAdStatus?.status ||
    firstSelectedProductAd?.channelSettings.status;

  const allSelectedRowsHaveSameAdStatusValue = selectedAdItems.every(
    ({ channelSettings, adItemId }) => {
      const status =
        tableChange.cell[adItemId]?.status || channelSettings.status;
      return status === firstProductAdStatus;
    }
  );

  let currentValueBeingEdited = valueBeingEdited;
  if (
    (isUndefined(first(currentValueBeingEdited)) ||
      first(currentValueBeingEdited) === '') &&
    firstProductAdStatus
  ) {
    currentValueBeingEdited = allSelectedRowsHaveSameAdStatusValue
      ? [firstProductAdStatus]
      : [ProductAdStatusOptions.Enabled.value];
  }
  return currentValueBeingEdited;
};

export const getBulkMenuVisbility = (bulkMenuVisibility: SelectVisibility) =>
  bulkMenuVisibility === SelectVisibility.Closed
    ? SelectVisibility.Open
    : SelectVisibility.Closed;
