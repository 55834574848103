import {
  Alignment,
  Button,
  ButtonSize,
  ButtonVariant,
  ContentStyle,
  GraphTrendUpIcon,
  Placement,
  Theme,
  Tooltip,
  TooltipSize,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { MerchantType } from '../../../lib/types/Fam';
import I18nKey from '../../../lib/types/I18nKey';
import { CampaignTargetingType } from '../containers/adsManager/types';
import { ViewTrendsContext } from './ViewTrends/ViewTrendsProvider';

export const ViewTrendLink = ({
  campaignId,
  campaignName,
  targetingType,
  entityId,
  entityType,
  entityName,
  adType,
  merchantCountryId,
  merchantCountryName,
  merchantType,
  salesChannelId,
  additionalOnTrendsClick,
  dataTestId,
  classnames,
}: {
  readonly campaignId?: string;
  readonly campaignName?: string;
  readonly targetingType?: string;
  readonly merchantCountryId?: string;
  readonly merchantCountryName?: string;
  readonly entityId?: string;
  readonly entityType?: string;
  readonly entityName?: string;
  readonly adType?: string;
  readonly merchantType?: string;
  readonly salesChannelId?: string;
  readonly additionalOnTrendsClick?: () => void;
  readonly dataTestId?: string;
  readonly classnames?: string;
}) => {
  const viewTrendsContext = useContext(ViewTrendsContext);

  const toggleSlideover = () => {
    if (additionalOnTrendsClick) {
      additionalOnTrendsClick();
    }
    viewTrendsContext?.updateContext &&
      viewTrendsContext.updateContext({
        merchantCountryId,
        merchantName: merchantCountryName,
        campaignId,
        campaignName,
        targetingType: targetingType as CampaignTargetingType,
        isViewTrendsOpen: !viewTrendsContext?.isViewTrendsOpen,
        entityId,
        entityType,
        entityName,
        adType,
        merchantType: merchantType as MerchantType,
        isSKUSlideoverOpen: viewTrendsContext?.isSKUSlideoverOpen,
        targetSegments: viewTrendsContext?.targetSegments,
        channelId: salesChannelId,
      });
  };

  return (
    <div
      className={classNames(
        'flex items-center absolute -mt-2 right-0 w-100 justify-end mr-4 ml-4',
        classnames
      )}
    >
      <span className="cursor-pointer">
        <Tooltip
          content={<FormattedMessage id={I18nKey.GENERIC_VIEW_TRENDS} />}
          theme={Theme.Dark}
          dataTestId="sb_tooltip"
          tooltipSize={TooltipSize.Small}
          showOnClick={false}
          disabled={false}
          style={ContentStyle.Normal}
          strategy="absolute"
          position={{
            placement: Placement.Left,
            alignment: Alignment.Center,
            placementOffsetInPixels: 10,
          }}
          overwrittenTooltipClassnames="text-base"
        >
          <Button
            dataTestId={`${dataTestId}_view_trends`}
            size={ButtonSize.Medium}
            variant={ButtonVariant.NoStyle}
            svgIcon={GraphTrendUpIcon}
            onClick={toggleSlideover}
            className="rounded-4 bg-transparent hover:bg-purple-100"
            iconClassName="text-purple-500"
          />
        </Tooltip>
      </span>
    </div>
  );
};

ViewTrendLink.displayName = 'ViewTrendLink';
