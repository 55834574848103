import { DateTime } from 'luxon';

import {
  createDateFieldFilter,
  isValidDate,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { TargetsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  CAMPAIGN_DATE_FORMAT,
  CAMPAIGN_DISPLAY_DATE_FORMAT,
  CAMPAIGN_FILTER_DATE_FORMAT_MOMENT,
} from '../types';
import { TARGETS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<TargetsDetails> = (targetsDetails) => {
  return makeTextColumn(({ targetDetails: { createdAt } }: TargetsDetails) =>
    createdAt
      ? DateTime.fromFormat(createdAt, CAMPAIGN_DATE_FORMAT).toFormat(
          CAMPAIGN_DISPLAY_DATE_FORMAT
        )
      : undefined
  )(targetsDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const dateAddedColumn: FlywheelTableColumn<TargetsDetails> = {
  columnName: TARGETS_API_COLUMN_NAME.DateAdded,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_DATE_ADDED,
  RowCellElement,
  gridColumnWidth: '160px',
};

export const dateAddedFilter = createDateFieldFilter(
  TARGETS_API_COLUMN_NAME.DateAdded,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_DATE_ADDED,
  isValidDate(CAMPAIGN_FILTER_DATE_FORMAT_MOMENT)
);
