import {
  MatchType,
  TargetInfo,
} from '../../lib/types/CampaignFlexibilitySharedTypes';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import {
  KEYWORD_LIMIT,
  KeywordErrorType,
} from '../../core/campaignFlexibility/types';
import {
  Alignment,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CheckboxCheckedState,
  CheckboxState,
  Icon,
  IconSize,
  InfoCircleIcon,
  Placement,
  TextLink,
  TextLinkSize,
  Theme,
  Tooltip,
  TooltipProps,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/types/I18nKey';
import size from 'lodash/size';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import uniqBy from 'lodash/uniqBy';
import { TagRowLeftTextArea } from './TagRowLeft';
import { InvalidTagList, InvalidTagProps } from './InvalidTagList';
import ReactDOMServer from 'react-dom/server';
import { MATCH_TYPE_I18N_MAPPER } from '../../modules/advertisingOptimization/containers/adsManager/utils/keywordUtils';
import { validateKeyword } from '../../core/campaignFlexibility/utils';
import { AdGroupInfoV2 } from '../../lib/types/KeywordAction';
import uuid from 'uuid';
import { CheckboxLabelWithTooltip } from '../CheckboxLabelWithTooltip';
import { KeywordType } from '../../modules/advertisingOptimization/containers/adsManager/createKeywords/createKeywordsMenu';
import { AdType } from '../../lib/types/AOSharedTypes';

const brandTagsSortingOrder = {
  [MatchType.Exact]: 0,
  [MatchType.Phrase]: 1,
  [MatchType.Broad]: 2,
  [MatchType.NegativeExact]: 0,
  [MatchType.NegativePhrase]: 1,
  [MatchType.Expanded]: 1,
};

const getBrandTagOptions = (keywordType: KeywordType) => {
  if (keywordType === KeywordType.NegativeKeyword) {
    return [MatchType.NegativeExact, MatchType.NegativePhrase];
  } else if (
    keywordType === KeywordType.Product ||
    keywordType === KeywordType.Contextual
  ) {
    return [MatchType.Exact, MatchType.Expanded];
  }
  return [MatchType.Exact, MatchType.Phrase, MatchType.Broad];
};

interface BrandAndCompetitorListsProps {
  readonly userCreated: TargetInfo[];
  readonly targets: TargetInfo[];
  readonly setUserCreated: (data: TargetInfo[]) => void;
  readonly onRemoveAll: () => void;
  readonly dataTestId?: string;
  readonly newTagValue: string;
  readonly setNewTagValue: (newValue: string) => void;
  readonly brandTags: MatchType[];
  readonly setBrandTags: (_: MatchType[]) => void;
  readonly keywordType?: KeywordType;
  readonly selectedAdGroups?: AdGroupInfoV2[];
  readonly useAdGroups?: boolean;
  readonly adType?: AdType;
  readonly minimizeHeight?: boolean;
}

const isDuplicateTarget = (
  arr: TargetInfo[],
  searchText: string,
  matchType: MatchType,
  adGroupId?: string
) => {
  const result = arr.findIndex(
    (item: TargetInfo) =>
      searchText.toLowerCase() === item.searchText.toLowerCase() &&
      matchType === item.matchType &&
      (!!adGroupId ? item.adgroupId === adGroupId : true)
  );
  return result !== -1;
};

const validateAndCreateTargets = (
  data: string[],
  sortedBrandTags: MatchType[],
  targets: TargetInfo[],
  AddForData: TargetInfo[],
  errorTags: InvalidTagProps[],
  adGroup?: AdGroupInfoV2,
  keywordType?: KeywordType
) => {
  data.forEach((item) => {
    let dataString = item.trim();
    sortedBrandTags.forEach((tag) => {
      const errorType = validateKeyword(
        dataString,
        tag === MatchType.NegativePhrase,
        keywordType
      );
      if (!errorType) {
        const isDuplicate = isDuplicateTarget(
          [...targets, ...AddForData],
          dataString,
          tag,
          adGroup?.id
        );

        if (!isDuplicate) {
          AddForData.push({
            targetId: uuid.v4(),
            searchText: dataString,
            matchType: tag,
            isNew: true,
            selected: true,
            adgroupId: adGroup?.id,
            adgroupInfo: adGroup,
          });
        } else {
          errorTags.push({
            searchText: dataString,
            errorType: KeywordErrorType.Duplicate,
          });
        }
      } else {
        errorTags.push({
          searchText: dataString,
          errorType: errorType,
        });
      }
    });
  });
};

const getAddKeywordButtonState = (
  buttonSize: number,
  hasValueinTextArea: boolean,
  useAdGroups: boolean,
  adGroupsCount: number
) => {
  if (
    buttonSize &&
    hasValueinTextArea &&
    (useAdGroups ? adGroupsCount > 0 : true)
  ) {
    return ButtonState.Active;
  }

  return ButtonState.Disabled;
};

export const BrandAndCompetitorLists: React.FC<
  BrandAndCompetitorListsProps & PropsWithChildren
> = ({
  userCreated,
  setUserCreated,
  dataTestId = '',
  onRemoveAll,
  targets,
  newTagValue,
  setNewTagValue,
  brandTags,
  setBrandTags,
  keywordType = KeywordType.Keyword,
  children,
  selectedAdGroups = [],
  useAdGroups = false,
  adType = AdType.SponsoredProducts,
  minimizeHeight,
}) => {
  const intl = useIntl();
  const [statusData, setStatus] = useState<boolean>(false);
  const [errorStatusData, setErrorStatusData] = useState<boolean>(false);
  const [tagNewTotalValue, setNewTagTotalValue] = useState<number>(0);
  const [tagTotalValue, setTagTotalValue] = useState<number>(0);
  const [invalidTags, setInvalidTags] = useState<InvalidTagProps[]>([]);
  const [
    MATCH_TYPE_ERROR_TOOLTIP,
    TOOLTIP_MATCH_TYPE,
    TOOLTIP_AD_GROUP,
    TOOLTIP_ENTER_KEYWORD,
    ADD_KEYWORD,
    PLACEHOLDER_ADD_KEYWORDS,
    PLACEHOLDER_ADD_TARGETS,
    TOOLTIP_TARGETS_TARGETING_TYPE,
    TOOLTIP_TARGETS_TARGETING_TYPE_EXACT,
    TOOLTIP_TARGETS_TARGETING_TYPE_EXPANDED,
    TYPE,
    MATCH_TYPE,
    KEYWORD_LIMIT_MESSAGE,
  ] = [
    I18nKey.CREATE_KEYWORDS_MATCH_TYPE_ERROR_TOOLTIP,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_MATCH_TYPE,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_AD_GROUP,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_ENTER_TARGETS,
    I18nKey.CREATE_KEYWORDS_MODAL_ADD_KEYWORDS,
    I18nKey.CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_TEXTAREA_PLACEHOLDER,
    I18nKey.CREATE_KEYWORDS_PLACEHOLDER_ADD_TARGETS,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_TARGETING_TYPE,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_TARGETING_TYPE_EXACT,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_TARGETING_TYPE_EXPANDED,
    I18nKey.BUDGET_INSIGHTS_TABLE_COLUMN_TYPE,
    I18nKey.CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_MATCH_TYPE_LABEL,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_ADDED_KEYWORDS_COUNT,
  ].map((id) =>
    intl.formatMessage(
      { id },
      {
        isNegative: keywordType === KeywordType.NegativeKeyword,
        keywordType,
        count: KEYWORD_LIMIT,
      }
    )
  );

  const addTagsData = (newTagdata: string) => {
    const sortedBrandTags = brandTags.sort(
      (a, b) => brandTagsSortingOrder[a] - brandTagsSortingOrder[b]
    );

    const userCreatedDataNumber = userCreated.length;
    let data = newTagdata.split('\n').filter((item) => {
      return !!item.trim();
    });
    const AddForData: TargetInfo[] = [...userCreated];
    const errorTags: InvalidTagProps[] = [];
    if (useAdGroups) {
      selectedAdGroups.forEach((adGroup) => {
        validateAndCreateTargets(
          data,
          sortedBrandTags,
          targets,
          AddForData,
          errorTags,
          adGroup,
          keywordType
        );
      });
      setNewTagTotalValue(size(AddForData) - userCreatedDataNumber);
      setTagTotalValue(size(data) * size(brandTags) * selectedAdGroups.length);
    } else {
      validateAndCreateTargets(
        data,
        sortedBrandTags,
        targets,
        AddForData,
        errorTags,
        undefined,
        keywordType
      );
      setNewTagTotalValue(size(AddForData) - userCreatedDataNumber);
      setTagTotalValue(size(data) * size(brandTags));
    }
    setInvalidTags(uniqBy(errorTags, (tag) => tag.searchText));
    setStatus(false);
    setUserCreated([...AddForData]);
    setErrorStatusData(true);
  };

  const getAddStatus = () => {
    if (
      (errorStatusData && tagNewTotalValue < tagTotalValue) ||
      size(userCreated) > KEYWORD_LIMIT
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getStatusCheckbox = (value: MatchType) => {
    if (includes(brandTags, value)) {
      return CheckboxCheckedState.Checked;
    }
    return CheckboxCheckedState.Unchecked;
  };

  const checkBoxHandle = (value: MatchType) => {
    setErrorStatusData(false);
    const data = [...brandTags];
    if (includes(brandTags, value)) {
      remove(data, (Item: string) => Item === value);
    } else {
      data.push(value);
    }
    setBrandTags(data);
  };

  const addTagsDataListhandleTag = () => {
    if (newTagValue.length) {
      addTagsData(newTagValue);
    }
  };

  const adGroupsCount = selectedAdGroups.length;
  const hasValueinTextArea = !!newTagValue?.replaceAll('\n', '')?.trim();
  const showButtonErrorTooltip =
    !hasValueinTextArea || !brandTags.length || (useAdGroups && !adGroupsCount);
  const showMatchTypeTooltip = !brandTags.length;

  const addKeywordsButton = (
    <Button
      label={ADD_KEYWORD}
      state={getAddKeywordButtonState(
        size(brandTags),
        hasValueinTextArea,
        useAdGroups,
        adGroupsCount
      )}
      variant={ButtonVariant.BlackAndWhiteBorder}
      size={ButtonSize.Small}
      onClick={() => addTagsDataListhandleTag()}
    />
  );

  const checkboxesState =
    brandTags.length > 0 ? CheckboxState.Default : CheckboxState.Error;

  const noMatchTypesTooltipProps: TooltipProps = {
    content: MATCH_TYPE_ERROR_TOOLTIP,
    theme: Theme.Dark,
    position: {
      placement: Placement.Left,
      alignment: Alignment.Center,
    },
  };

  const invalidTagList = useMemo(
    () =>
      ReactDOMServer.renderToString(
        <InvalidTagList invalidTagProps={invalidTags} intl={intl} />
      ),
    [invalidTags, intl]
  );

  const isProductTargeting =
    keywordType === KeywordType.Product ||
    keywordType === KeywordType.Contextual;

  const negativeKeywordsOnSmallScreen =
    keywordType === KeywordType.NegativeKeyword && window.screen.width < 1565;

  return (
    <div
      className={classNames('flex-auto flex mt-16 w-full', {
        'h-392 overflow-hidden': !minimizeHeight,
        'h-100 overflow-scroll': minimizeHeight,
      })}
    >
      <div className="flex w-1/2 flex-column flex-1 justify-center">
        <div className="flex flex-col w-full h-full" data-test-id={dataTestId}>
          <div
            className={classNames([
              'flex flex-col border border-solid',
              'rounded-0 border-grey-200 w-full h-full',
            ])}
          >
            <div
              className={classNames(
                'bg-purple-50 min-h-46 max-h-46 border-b border-solid border-grey-300',
                {
                  'p-12': !negativeKeywordsOnSmallScreen,
                  'px-12 py-2': negativeKeywordsOnSmallScreen,
                }
              )}
            >
              {!isProductTargeting || adType === AdType.SponsoredProducts ? (
                <div className="flex flex-row items-center gap-12">
                  <div className="flex flex-row gap-8 items-center max-h-20">
                    {isProductTargeting ? (
                      <Tooltip
                        theme={Theme.Dark}
                        position={{
                          placement: Placement.Top,
                          alignment: Alignment.Center,
                        }}
                        content={
                          <div className="flex flex-col">
                            {TOOLTIP_TARGETS_TARGETING_TYPE}
                            <ul className="list-disc p-8 list-outside">
                              <li>{TOOLTIP_TARGETS_TARGETING_TYPE_EXACT}</li>
                              <li>{TOOLTIP_TARGETS_TARGETING_TYPE_EXPANDED}</li>
                            </ul>
                          </div>
                        }
                        dataTestId={'create-keywords-modal-tooltip'}
                        overwrittenTooltipClassnames="w-400"
                      >
                        <Icon
                          svg={InfoCircleIcon}
                          size={IconSize.Small}
                          className="text-grey-500 mb-2"
                        />
                      </Tooltip>
                    ) : undefined}
                    <span className="text-sm leading-none font-medium">
                      {isProductTargeting ? TYPE : MATCH_TYPE}
                    </span>
                  </div>

                  {getBrandTagOptions(keywordType).map((option) => (
                    <CheckboxLabelWithTooltip
                      key={option}
                      checkboxProps={{
                        state: checkboxesState,
                        checked: getStatusCheckbox(option),
                        onClick: () => checkBoxHandle(option),
                        label: intl.formatMessage({
                          id: MATCH_TYPE_I18N_MAPPER[option],
                        }),
                      }}
                      tooltipEnabled={showMatchTypeTooltip}
                      tooltipProps={noMatchTypesTooltipProps}
                    />
                  ))}
                </div>
              ) : undefined}
            </div>
            <TagRowLeftTextArea
              placeholder={
                isProductTargeting
                  ? PLACEHOLDER_ADD_TARGETS
                  : PLACEHOLDER_ADD_KEYWORDS
              }
              setNewTagValue={setNewTagValue}
              dataTestId={`${dataTestId}_NewTag`}
              setStatus={setStatus}
              statusData={statusData}
              defaultValue={invalidTagList}
            />

            <div className="p-8 border-t border-solid border-grey-300">
              <div className="flex flex-row items-center justify-between ">
                <div className="text-sm leading-none ">
                  <div className="flex flex-row items-center mr-8">
                    <span
                      className={classNames([
                        'text-base leading-none font-normal mr-2 text-grey-500',
                        {
                          'text-red-500': getAddStatus(),
                        },
                      ])}
                    >
                      {intl.formatMessage(
                        {
                          id: I18nKey.CREATE_KEYWORDS_TOOLTIP_ADDED_KEYWORDS,
                        },
                        {
                          value: tagNewTotalValue,
                          value2: tagTotalValue,
                          keywordType,
                          isNegative:
                            keywordType === KeywordType.NegativeKeyword,
                          addmessage:
                            size(userCreated) > KEYWORD_LIMIT
                              ? KEYWORD_LIMIT_MESSAGE
                              : '',
                        }
                      )}
                    </span>
                  </div>
                </div>
                {showButtonErrorTooltip ? (
                  <Tooltip
                    content={
                      <ul className="list-disc list-inside">
                        {adGroupsCount === 0 && <li>{TOOLTIP_AD_GROUP}</li>}
                        {showMatchTypeTooltip && <li>{TOOLTIP_MATCH_TYPE}</li>}
                        {!hasValueinTextArea && (
                          <li>{TOOLTIP_ENTER_KEYWORD}</li>
                        )}
                      </ul>
                    }
                    theme={Theme.Dark}
                    position={{
                      placement: Placement.Top,
                      alignment: Alignment.Center,
                      placementOffsetInPixels: 12,
                    }}
                  >
                    {addKeywordsButton}
                  </Tooltip>
                ) : (
                  addKeywordsButton
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-1/2 flex-column flex-1 justify-center">
        <div className="flex flex-col w-full h-full" data-test-id={dataTestId}>
          <div
            className={classNames([
              'flex flex-col border-r border-b border-t border-solid box-border',
              'rounded-0 border-grey-200 w-full h-full overflow-hidden',
            ])}
          >
            <div className="bg-purple-50 p-12 border-b border-solid border-grey-300">
              <div className="flex flex-row items-center justify-between m-1">
                <div className="text-sm leading-none font-medium">
                  {intl.formatMessage(
                    {
                      id: I18nKey.CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_ADDED_KEYWORDS_DONE,
                    },
                    {
                      value: size(userCreated),
                      isNegative: keywordType === KeywordType.NegativeKeyword,
                    }
                  )}
                </div>
                <TextLink
                  className="truncate"
                  textLabel={intl.formatMessage({
                    id: I18nKey.CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_LABEL_REMOVE_ALL,
                  })}
                  size={TextLinkSize.Small}
                  dataTestId={`${dataTestId}_removeAll`}
                  onClick={() => {
                    onRemoveAll();
                    setErrorStatusData(false);
                  }}
                />
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
BrandAndCompetitorLists.displayName = 'BrandAndCompetitorLists';
