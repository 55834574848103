import {
  Alignment,
  ArrowRightIcon,
  Button,
  ButtonSize,
  ButtonVariant,
  CalendarPlacement,
  CalendarRangeLinearIcon,
  ChartBuilder,
  ChartTime,
  ChevronDownIcon,
  ComparisonToggle,
  ContentStyle,
  DatePickerV2,
  DownloadIcon,
  FormToggle,
  FormToggleSize,
  FormToggleState,
  Icon,
  IconSize,
  KpiCardGroupV2,
  Placement,
  Theme,
  ToggleTab,
  ToggleTabGroupV2,
  ToggleTabStyle,
  Tooltip,
  TooltipSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import {
  asyncRequestIsComplete,
  asyncRequestIsLoading,
} from '../../../../../lib/utilities/asyncRequest';
import { DATE_QUERY_STRING_FORMAT } from '../../../../../lib/utilities/buildUrlUtilities';
import { getCurrencySymbol } from '../../../../../lib/utilities/currency';
import { DateTime } from 'luxon';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  CONVERTED_DATE_FORMAT_FOR_GRAPH,
  TOOLTIP_DATE_FORMAT_FOR_GRAPH,
  getInterval,
  getNoDataEmptyState,
  getShowLoading,
  rotationXaxis,
} from '../../../../advertisingOptimization/components/ViewTrends/Performance/utils';
import { getSeasonalEventsData } from '../../../../compass/containers/compassDashboard/utils/business';
import { getPreviousPeriodDateRange } from '../../utils';
import { PerformanceSlideoverContext } from './performanceSlideoverContextProvider';
import {
  checkIfNoDataPoints,
  getChartMetrics,
  getXAxisLabels,
  getYAxisFormat,
  getYAxisMinValue,
  isAllPerformanceMetricsEmpty,
} from './utils';

export interface PerformanceProps {}

const getComparisonToggleState = (value?: boolean) =>
  value ? FormToggleState.On : FormToggleState.Off;

export const Performance: React.FC<PerformanceProps> = (props) => {
  const {
    currentDateRange,
    setCurrentDateRange,
    currency,
    showSeasonalEvents,
    comparePreviousPeriod,
    chartTimeFormat,
    setChartTimeFormat,
    setShowSeasonalEvents,
    updateComparePreviousPeriod,
    graphDataRequest,
    selectedMetrics,
    selectedMetricIndex,
    setSelectedMetricIndex,
    onMetricsChange,
    seasonalEventsRequest,
    isProductDetailsLoading,
    exportButtonState,
    downloadFile,
    kpiCards,
  } = useContext(PerformanceSlideoverContext);

  const intl = useIntl();

  const previousDateRange = useMemo(
    () =>
      getPreviousPeriodDateRange(
        currentDateRange.startDate,
        currentDateRange.endDate
      ),
    [currentDateRange]
  );

  const xAxisLabels = asyncRequestIsComplete(graphDataRequest)
    ? getXAxisLabels(
        graphDataRequest.result.current,
        chartTimeFormat,
        intl,
        currentDateRange.startDate.year !== currentDateRange.endDate.year
      )
    : [];

  const options = useMemo(() => {
    return getChartMetrics(
      graphDataRequest,
      comparePreviousPeriod,
      selectedMetrics,
      selectedMetricIndex,
      chartTimeFormat,
      intl,
      currency,
      xAxisLabels.length
    );
  }, [
    graphDataRequest.kind,
    comparePreviousPeriod,
    selectedMetrics,
    selectedMetricIndex,
    chartTimeFormat,
    currency,
    xAxisLabels,
  ]);

  const onDateRangeChangeHandler = (startDate: string, endDate: string) => {
    setCurrentDateRange({
      ...currentDateRange,
      endDate: DateTime.fromFormat(endDate, DATE_QUERY_STRING_FORMAT),
      startDate: DateTime.fromFormat(startDate, DATE_QUERY_STRING_FORMAT),
    });
  };

  const onToggleClickHandler = () => {
    updateComparePreviousPeriod(!comparePreviousPeriod);
  };

  const onToggleSeasonalEventsClickHandler = () => {
    if (isDataLoading) {
      return;
    }

    setShowSeasonalEvents(!showSeasonalEvents);
  };

  const isDataLoading =
    asyncRequestIsLoading(graphDataRequest) ||
    asyncRequestIsLoading(seasonalEventsRequest) ||
    isProductDetailsLoading;

  const hasNoDataPoints = checkIfNoDataPoints(
    asyncRequestIsComplete(graphDataRequest) ? options : [],
    comparePreviousPeriod
  );

  return (
    <div className="flex flex-col w-full gap-12">
      <div className="bg-grey-50 rounded-4 flex px-12 py-8 gap-12 items-center">
        {isDataLoading && (
          <>
            <Skeleton width={314} height={20} />
            <Skeleton width={200} height={20} />
          </>
        )}
        {!isDataLoading && (
          <>
            <Icon
              size={IconSize.Medium}
              svg={CalendarRangeLinearIcon}
              className="text-grey-500"
            />
            <Typography
              lineHeight={TypographyLineHeight.none}
              size={TypographySize.base}
              weight={TypographyWeight.regular}
            >
              {intl.formatMessage({
                id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD,
              })}
            </Typography>
            <div>
              <DatePickerV2
                initialEndDate={currentDateRange.startDate}
                initialStartDate={currentDateRange.endDate}
                calendarPlacement={CalendarPlacement.Right}
                onChange={onDateRangeChangeHandler}
                maxDate={currentDateRange.maxDate}
                minDate={currentDateRange.minDate}
                showYearSwitchButton={true}
              >
                <div
                  className={
                    'flex border border-solid border-grey-200 bg-white px-12 py-6 rounded-4 items-center gap-8'
                  }
                >
                  <span className="text-base font-normal">
                    {currentDateRange.startDate.toFormat(
                      CONVERTED_DATE_FORMAT_FOR_GRAPH
                    )}
                  </span>
                  <span className="mx-4 text-grey-600">
                    <Icon size={IconSize.Small} svg={ArrowRightIcon} />
                  </span>
                  <span className="text-base font-normal">
                    {currentDateRange.endDate.toFormat(
                      TOOLTIP_DATE_FORMAT_FOR_GRAPH
                    )}
                  </span>
                  <Icon size={IconSize.Medium} svg={ChevronDownIcon} />
                </div>
              </DatePickerV2>
            </div>
            <ComparisonToggle
              size={FormToggleSize.Medium}
              dateRange={previousDateRange}
              state={getComparisonToggleState(comparePreviousPeriod)}
              onClick={onToggleClickHandler}
            />
          </>
        )}
      </div>
      <div className="flex gap-8 w-full items-center">
        <KpiCardGroupV2
          cards={kpiCards}
          classnames="w-full"
          onCardMetricChange={(index: number, metric: string) =>
            onMetricsChange(index)(metric)
          }
          onCardClick={(index) => setSelectedMetricIndex(index)}
        />
      </div>
      {isDataLoading && getShowLoading(true)}
      {asyncRequestIsComplete(graphDataRequest) &&
        isAllPerformanceMetricsEmpty(graphDataRequest.result.current) &&
        isAllPerformanceMetricsEmpty(graphDataRequest.result.previous) &&
        getNoDataEmptyState(() => {})}
      {asyncRequestIsComplete(graphDataRequest) &&
        (!isAllPerformanceMetricsEmpty(graphDataRequest.result.current) ||
          !isAllPerformanceMetricsEmpty(graphDataRequest.result.previous)) && (
          <ChartBuilder
            yAxisFixedHeight={170}
            chartMetricsProps={options}
            showGraphLineTooltip={true}
            PlotBandPoints={[]}
            defaultTime={chartTimeFormat}
            currency={getCurrencySymbol(currency)}
            xAxisLabels={xAxisLabels}
            xAxisTickInterval={getInterval(xAxisLabels.length)}
            showYAxisOnLeft
            yAxisOnLeftFormat={getYAxisFormat(
              selectedMetrics,
              selectedMetricIndex
            )}
            yAxisLeftMinValue={getYAxisMinValue(options)}
            yAxisLeftMaxValue={hasNoDataPoints ? 100 : undefined}
            showPreviousDataOnGraphLineTooltip={true}
            zIndexForTooltip={21}
            stackMode={'normal'}
            xAxisMarginBottom={16}
            legendEnabled={false}
            legendPadding={0}
            legendMarginTop={0}
            defaultBottomSpacing={2}
            className="w-full relative"
            rotationXaxis={rotationXaxis(xAxisLabels)}
            eventLogs={
              asyncRequestIsComplete(seasonalEventsRequest)
                ? getSeasonalEventsData(
                    currentDateRange,
                    seasonalEventsRequest.result,
                    chartTimeFormat,
                    intl
                  )
                : undefined
            }
            showEventLogs={
              showSeasonalEvents &&
              asyncRequestIsComplete(seasonalEventsRequest)
            }
            actionButtonTabs={[
              {
                nameI18nKey: ChartTime.Daily,
                theme: ToggleTabGroupV2.ToggleTabTheme.LightPurple,
              },
              {
                nameI18nKey: ChartTime.Weekly,
                theme: ToggleTabGroupV2.ToggleTabTheme.LightPurple,
              },
            ]}
            tabGroupStyle={ToggleTabStyle.Condensed}
            onClickChartTime={(name: ToggleTab) => {
              setChartTimeFormat(name.nameI18nKey as ChartTime);
            }}
            centerElements={<div className="flex flex-auto"></div>}
            leftSideElements={
              <p>
                <div
                  onClick={onToggleSeasonalEventsClickHandler}
                  className="cursor-pointer mt-8 inline-block"
                >
                  <Tooltip
                    content={
                      (asyncRequestIsComplete(seasonalEventsRequest)
                        ? seasonalEventsRequest.result
                        : []
                      ).length
                        ? ''
                        : intl.formatMessage({
                            id: I18nKey.COMPASS_CUSTOMIZE_METRICS_NO_SEASONAL_EVENTS_TOOLTIP,
                          })
                    }
                    tooltipSize={TooltipSize.Small}
                    style={ContentStyle.Normal}
                    position={{
                      placement: Placement.Bottom,
                      alignment: Alignment.Center,
                    }}
                  >
                    <FormToggle
                      label={intl.formatMessage({
                        id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SHOW_SEASONAL_EVENTS,
                      })}
                      disabled={isDataLoading}
                      state={getComparisonToggleState(showSeasonalEvents)}
                      size={FormToggleSize.Small}
                    />
                  </Tooltip>
                </div>
              </p>
            }
            rightSideElements={
              <Tooltip
                content={intl.formatMessage({
                  id: I18nKey.COMPASS_CUSTOMIZE_METRICS_EXPORT_BTN_TOOLTIP,
                })}
                theme={Theme.Dark}
                position={{
                  placement: Placement.Left,
                  alignment: Alignment.Center,
                  placementOffsetInPixels: 10,
                }}
                delayShow={300}
              >
                <Button
                  svgIcon={DownloadIcon}
                  size={ButtonSize.InlineIconMedium}
                  state={exportButtonState}
                  onClick={() => downloadFile()}
                  variant={ButtonVariant.BlackAndWhiteBorder}
                  dataTestId="compass_graph_export_btn"
                  className="h-32 w-32"
                />
              </Tooltip>
            }
            condensedElements
          />
        )}
    </div>
  );
};

Performance.displayName = 'Performance';
