import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  AdGroupDetails,
  EntityType,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADGROUPS_API_COLUMN_NAME } from '../utils';
import { makePercentColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AlertTriangleIcon,
  NotApplicable,
  PaletteColor,
  Pill,
  Tooltip,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import EstAdGrossMarginSlideover from '../../../../../components/EstAdGrossMarginSlideover/EstAdGrossMarginSlideover';
import { TableDataAdsManager } from '../types';
import isArray from 'lodash/isArray';
import first from 'lodash/first';
import { EST_AD_GROSS_MARGIN_TABLE_ADS_ID } from '../ducks/types';
import isUndefined from 'lodash/isUndefined';

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = (
  adGroupDetails
) => {
  const intl = useIntl();
  const [showMissingCogsSlideover, setShowMissingCogsSlideover] =
    useState<boolean>(false);

  const onClick = () => {
    setShowMissingCogsSlideover(true);
  };

  const mcId = isArray(adGroupDetails.selectedMerchantCountries)
    ? first(adGroupDetails.selectedMerchantCountries as string[])
    : adGroupDetails.selectedMerchantCountries;
  const merchant = adGroupDetails.allMerchants.find(
    (item) => item.merchantCountryId === mcId
  );

  const showProductInactive =
    isUndefined(adGroupDetails.flywheelSettings.avgPreAdGrossMargin) &&
    (adGroupDetails.flywheelSettings.isCogs ||
      isUndefined(adGroupDetails.flywheelSettings.isCogs));

  const showMissingCogs =
    isUndefined(adGroupDetails.flywheelSettings.avgPreAdGrossMargin) &&
    adGroupDetails.flywheelSettings.isCogs === false;

  const showNotApplicable = (text: I18nKey) => {
    return (
      <div className="px-12 py-8">
        <NotApplicable
          tooltipText={intl.formatMessage({ id: text })}
          tooltipClassnames="text-center"
        />
      </div>
    );
  };

  if (adGroupDetails.productConnectionMissingForSelectedMerchant) {
    return showNotApplicable(
      I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_ESTIMATED_PRE_AD_GROSS_MARGIN_TOOLTIP_NO_PROD_CONNECTION
    );
  } else if (showProductInactive) {
    return showNotApplicable(
      I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_ESTIMATED_PRE_AD_GROSS_MARGIN_TOOLTIP_NO_ACTIVE_PRODUCT
    );
  } else if (showMissingCogs) {
    return (
      <div className="px-12 py-8 flex">
        {showMissingCogsSlideover && adGroupDetails.cogsDetailsDataFetcher && (
          <EstAdGrossMarginSlideover
            tableId={EST_AD_GROSS_MARGIN_TABLE_ADS_ID}
            selectedMerchant={merchant!}
            fromAdsManager={true}
            dataFetcher={adGroupDetails.cogsDetailsDataFetcher(
              adGroupDetails.adGroupId,
              EntityType.AdGroup
            )}
            closeModal={() => setShowMissingCogsSlideover(false)}
          />
        )}
        <Tooltip
          content={intl.formatMessage({ id: I18nKey.GENERIC_EDIT_COGS })}
          delayShow={750}
        >
          <Pill
            color={PaletteColor.yellow}
            text={intl.formatMessage({ id: I18nKey.GENERIC_COGS })}
            icon={AlertTriangleIcon}
            iconClassName="text-orange-600"
            onPillClick={onClick}
            includeBorder
            isBorderRounded
            customBorderColor="border-yellow-500"
            textClassName="text-grey-900"
          />
        </Tooltip>
      </div>
    );
  }
  return makePercentColumn((details: AdGroupDetails) =>
    details.flywheelSettings.avgPreAdGrossMargin?.toString()
  )(adGroupDetails);
};

RowCellElement.displayName = 'estimated_pre_ad_gross_margin_column';

export const estimatedPreAdGrossMarginColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.EstimatedPreAdGrossMargin,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_ESTIMATED_PRE_AD_GROSS_MARGIN,
  RowCellElement,
  gridColumnWidth: '150px',
  className: 'text-right',
};

export const estimatedPreAdGrossMarginFilter = createPercentDataFieldFilter(
  ADGROUPS_API_COLUMN_NAME.EstimatedPreAdGrossMargin,
  I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_ESTIMATED_PRE_AD_GROSS_MARGIN,
  isValidNumber(),
  true
);
