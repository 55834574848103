import { DateTime } from 'luxon';

import {
  BulkEditModalNumericValueOperator,
  DualModes,
  EditControlProps,
} from '@teikametrics/tm-design-system';

import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { PaginatedDataFetcher } from '../../../../lib/clients/types';
import { PartialRecord, StrictStringMap } from '../../../../lib/types';
import {
  AdGroupReviewStatusType,
  AdGroupStatus,
  AdLevel,
  AdLevelApiEndpoint,
  AdType,
  AddedBy,
  AiRecommendedKeywordType,
  CampaignCostType,
  CampaignMissingCogsResponse,
  EntityType,
  EntityTypeRequestParameterForTargets,
  ExpressionType,
  FlywheelSalesChannel,
  Groups,
  KeywordReviewStatusType,
  KeywordTargetType,
  LabelValue,
  MerchantCountryCode,
  Portfolio,
  ProductReviewStatusType,
} from '../../../../lib/types/AOSharedTypes';
import { EstPreAdGrossMarginItem } from '../../../../lib/types/CampaignFlexibilitySharedTypes';
import {
  ConnectionStatus,
  MerchantCountry,
  MerchantType,
} from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { SelectedTrendsProduct } from '../../../products/containers/skuCatalog';
import {
  ADS_MANAGER_ADGROUP_TABLE_ID,
  ADS_MANAGER_AD_ITEMS_TABLE_ID,
  ADS_MANAGER_CAMPAIGNS_TABLE_ID,
  ADS_MANAGER_PROFILES_TABLE_ID,
  ADS_MANAGER_SB_ADS_TABLE_ID,
  ADS_MANAGER_TARGETS_TABLE_ID,
} from './ducks/types';
import {
  EditableColumnsAdGroup,
  EditableColumnsAdItem,
  EditableColumnsCampaign,
  EditableColumnsProfile,
  EditableColumnsSbAd,
  EditableColumnsSvAds,
  EditableColumnsTarget,
  ProductAdStatusValue,
} from './utils';

export const DEFAULT_TAB_INDEX_DATA_INSPECTOR = 0;
export const REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES = '60';
export const MILLISECONDS_TO_DAYS = 60 * 60 * 24 * 1000;

export const CAMPAIGN_GROUPS_LEARN_MORE_LINK =
  'https://intercom.help/flywheel-20/en/articles/8813654-manage-campaign-groups';

export const NO_GROUP_VALUE = 'empty_group_label';

export interface DataInspection {
  readonly parent: LabelValue<AdType>;
  readonly child: LabelValue<AdLevel>[];
}

export const AdTypeI8nKeyMapper: Record<AdType, I18nKey> = {
  [AdType.SearchBrandAmplifier]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SEARCH_BRAND_AMPLIFIER,
  [AdType.SponsoredProducts]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_PRODUCTS,
  [AdType.SponsoredBrands]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_BRANDS,
  [AdType.SponsoredDisplay]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_DISPLAY,
  //  This is just a placeholder. We don't really use SponsoredBrandsVideo anywhere
  [AdType.SponsoredBrandsVideo]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_DISPLAY,
  [AdType.SponsoredVideos]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_VIDEOS,
};

export const AdLevelI8nKeyMapper: Record<AdLevel, I18nKey> = {
  [AdLevel.Campaigns]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_CAMPAIGNS,
  [AdLevel.AdGroups]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_ADGROUPS,
  [AdLevel.Targets]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_TARGETS,
  [AdLevel.ProductAds]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PRODUCT_ADS,
  [AdLevel.Profiles]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PROFILES,
  [AdLevel.KeywordTargets]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_KEYWORDS_ADS,
  [AdLevel.ProductTargets]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PRODUCT_TARGETS,
  [AdLevel.SbAds]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_SB_ADS,
  [AdLevel.SvAds]:
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_SV_ADS,
};

export const CAMPAIGN_DATE_FORMAT = 'yyyy-MM-dd';
export const CAMPAIGN_DISPLAY_DATE_FORMAT = 'MM/dd/yy';
export const MERCHANT_COUNTRY_IDS = 'merchantCountryIds';
export const SALES_CHANNEL_ID = 'salesChannelId';
export const AD_TYPE = 'adType';
export const AD_LEVEL = 'adLevel';
export const TARGETING_TYPE = 'targetingType';
export const AD_GROUP_NAME = 'adGroupName';
export const SORT_ORDER = 'sortOrder';
export const SORT_BY = 'sortBy';
export const SALES_CHANNEL_NAME = 'salesChannelName';
export const CAMPAIGN_LIVE_FILTER = 'campaignLiveFilter';
export const CAMPAIGN_NAME_FILTER = 'campaignName';
export const ADDED_BY_FILTER = 'addedByFilter';
export const MACS_TARGET_IS_EMPTY_FILTER_VALUE = '-99999';
export const SP_CAMPAIGN_NEGATIVE_KEYWORD = 'SP Campaign Negative Keyword';
export const SP_NEGATIVE_KEYWORD = 'SP Negative Keyword Target';
export const KEYWORD_TARGETING = 'Keyword Targeting';
export const PRODUCT_ATTRIBUTE_TARGETING = 'Product Attribute Targeting';
export const SB_NEGATIVE_KEYWORD_TARGET = 'SB Negative Keyword Target';

export const SEARCH_TEXT = 'searchText';
export const CAMPAIGN_STATUS = 'campaignStatus';
export const PRODUCT_AD_STATUS = 'productAdStatus';

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export enum CampaignTargetingType {
  Auto = 'auto',
  Manual = 'manual',
}

/**
 * These below 2 ADITEM config-old's can't be moved to NUMERIC_CONFIG
 * Why? `PRODUCT_ADS_API_COLUMN_NAME.Bid` and `TARGETS_API_COLUMN_NAME.Bid`
 * Results in the same column name and hence can't co-exist so keeping it as is.
 * This issue will be fixed once the NUMERIC_CONFIG is powered by an API.
 */
export const ADITEM_BID_AUTO_MIN_VALUE = 0.2;
export const ADITEM_BID_MAX_VALUE = 100;

export const ADGROUP_DEFAULT_BID_FALLBACK_PLACEHOLDER_VALUE = 5;

export const CAMPAIGN_TABLE_SEARCH_KEY = 'searchCampaignName';
export const ADGROUP_TABLE_SEARCH_KEY = 'searchAdGroupName';
export const PRODUCTAD_TABLE_SEARCH_KEY = 'searchProductName';
export const PROFILE_TABLE_SEARCH_KEY = 'searchBrand';
export const TARGETS_TABLE_SEARCH_KEY = 'searchTargetText';
export const SB_ADS_TABLE_SEARCH_KEY = 'searchCreativeName';
export const TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION =
  'searchTargetingExpression';

export const CURRENCY_CODE = 'USD';

export const DEFAULT_TABLE_MODE = DualModes.View;

export const DEFAULT_MAX_METRICS_VISIBLE = 5;

export enum CampaignDataApiColumnGroupIdentifier {
  CampaignDetailsFields = 'campaignDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PlacementSettingsFields = 'placementSettingsFields',
  PerformanceFields = 'performanceFields',
  FlywheelSettingsFields = 'flywheelSettingsFields',
}

export enum AdgroupDataApiColumnGroupIdentifier {
  AdGroupDetailsFields = 'adGroupDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PlacementSettingsFields = 'placementSettingsFields',
  PerformanceFields = 'performanceFields',
  FlywheelSettingsFields = 'flywheelSettingsFields',
}

export enum ProductAdsApiColumnGroupIdentifier {
  AdItemDetailsFields = 'adItemDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PerformanceFields = 'performanceFields',
}

export enum ProfileDataApiColumnGroupIdentifier {
  ProfileDetailsFields = 'profileDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PerformanceFields = 'performanceFields',
}

export enum TargetsDataApiColumnGroupIdentifier {
  TargetsDetailsFields = 'targetsDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PerformanceFields = 'performanceFields',
}

export enum SbAdsApiColumnGroupIdentifier {
  CreativeDetailsFields = 'creativeDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PerformanceFields = 'performanceFields',
}

export enum SvAdApiColumnGroupIdentifier {
  CreativeDetailsFields = 'creativeDetailsFields',
  ChannelSettingsFields = 'channelSettingsFields',
  PerformanceFields = 'performanceFields',
}

export interface ApiColumnsWithAdLevel {
  readonly adLevel?: AdLevel;
  readonly columns: string[];
}

export const ADLEVEL_TO_ADLEVEL_API_MAPPER: Record<
  AdLevel,
  AdLevelApiEndpoint
> = {
  [AdLevel.Campaigns]: AdLevelApiEndpoint.Campaigns,
  [AdLevel.AdGroups]: AdLevelApiEndpoint.Adgroups,
  [AdLevel.ProductAds]: AdLevelApiEndpoint.ProductAds,
  [AdLevel.Profiles]: AdLevelApiEndpoint.Profiles,
  [AdLevel.Targets]: AdLevelApiEndpoint.Targets,
  [AdLevel.KeywordTargets]: AdLevelApiEndpoint.KeywordTargets,
  [AdLevel.ProductTargets]: AdLevelApiEndpoint.KeywordTargets,
  [AdLevel.SbAds]: AdLevelApiEndpoint.SbAds,
  [AdLevel.SvAds]: AdLevelApiEndpoint.SvAds,
};

export const API_TO_COLUMN_CAMPAIGN_TYPE_MAPPER: Record<string, string> =
  Object.freeze({
    sponsoredProducts: 'Sponsored products',
    sponsoredBrands: 'Sponsored brands',
  });

export enum ADS_MANAGER_AD_LEVEL_PAGES {
  WalmartSponsoredProductsAdGroups = '/ads-optimization/ads-manager/walmart/sponsored-products/ad-groups',
  WalmartSponsoredProductsTargets = '/ads-optimization/ads-manager/walmart/sponsored-products/keyword-targets',
  WalmartSponsoredProductsProductAds = '/ads-optimization/ads-manager/walmart/sponsored-products/product-ads',
  WalmartSponsoredProductsCampaigns = '/ads-optimization/ads-manager/walmart/sponsored-products/campaigns',
  WalmartSearchBrandAmplifierAdGroups = '/ads-optimization/ads-manager/walmart/search-brand-amplifier/ad-groups',
  WalmartSearchBrandAmplifierKeywordTargets = '/ads-optimization/ads-manager/walmart/search-brand-amplifier/keyword-targets',
  WalmartSearchBrandAmplifierProductAds = '/ads-optimization/ads-manager/walmart/search-brand-amplifier/product-ads',
  WalmartSearchBrandAmplifierProfiles = '/ads-optimization/ads-manager/walmart/search-brand-amplifier/profiles',
  WalmartSearchBrandAmplifierCampaigns = '/ads-optimization/ads-manager/walmart/search-brand-amplifier/campaigns',
  WalmartSponsoredVideosCampaigns = '/ads-optimization/ads-manager/walmart/sponsored-videos/campaigns',
  WalmartSponsoredVideosAdGroups = '/ads-optimization/ads-manager/walmart/sponsored-videos/ad-groups',
  WalmartSponsoredVideosKeywordTargets = '/ads-optimization/ads-manager/walmart/sponsored-videos/keyword-targets',
  WalmartSponsoredVideosSVAds = '/ads-optimization/ads-manager/walmart/sponsored-videos/sv-ads',
  AmazonSponsoredProductsAdGroups = '/ads-optimization/ads-manager/amazon/sponsored-products/ad-groups',
  AmazonSponsoredProductsProductAds = '/ads-optimization/ads-manager/amazon/sponsored-products/product-ads',
  AmazonSponsoredProductsTargets = '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets',
  AmazonSponsoredProductsCampaigns = '/ads-optimization/ads-manager/amazon/sponsored-products/campaigns',
  AmazonSponsoredBrandsProductsTarget = '/ads-optimization/ads-manager/amazon/sponsored-brands/product-targets',
  AmazonSponsoredBrandsCampaigns = '/ads-optimization/ads-manager/amazon/sponsored-brands/campaigns',
  AmazonSponsoredBrandsAdGroups = '/ads-optimization/ads-manager/amazon/sponsored-brands/ad-groups',
  AmazonSponsoredBrandsKeywordTargets = '/ads-optimization/ads-manager/amazon/sponsored-brands/keyword-targets',
  AmazonSponsoredBrandsSbAds = '/ads-optimization/ads-manager/amazon/sponsored-brands/sb-ads',
  AmazonSponsoredDisplayCampaigns = '/ads-optimization/ads-manager/amazon/sponsored-display/campaigns',
  AmazonSponsoredDisplayTargets = '/ads-optimization/ads-manager/amazon/sponsored-display/targets',
  AmazonSponsoredProductsProductsTarget = '/ads-optimization/ads-manager/amazon/sponsored-products/product-targets',
  AmazonSponsoredProductsKeywordTargets = '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets',
  AmazonSponsoredProductsKeywordTargetsChatGPT = '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets/create-chatgpt',
  AmazonSponsoredProductsKeywordTargetsChatGPTConfigure = '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets/create-chatgpt/configure',
  AmazonSponsoredProductsKeywordTargetsChatGPTSelectAdGroup = '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets/create-chatgpt/select-ad-group',
  AmazonSponsoredProductsKeywordTargetsChatGPTAdGroupDetails = '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets/create-chatgpt/ad-group-details',
  AmazonSponsoredProductsKeywordTargetsChatGPTFeedback = '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets/create-chatgpt/feedback',
  AmazonSponsoredDisplayAdGroups = '/ads-optimization/ads-manager/amazon/sponsored-display/ad-groups',
  AmazonSponsoredDisplayProductAds = '/ads-optimization/ads-manager/amazon/sponsored-display/product-ads',
  WalmartSponsoredProductsKeywordTargetsChatGPT = '/ads-optimization/ads-manager/walmart/sponsored-products/keyword-targets/create-chatgpt',
  WalmartSponsoredProductsKeywordTargetsChatGPTConfigure = '/ads-optimization/ads-manager/walmart/sponsored-products/keyword-targets/create-chatgpt/configure',
  WalmartSponsoredProductsKeywordTargetsChatGPTSelectAdGroup = '/ads-optimization/ads-manager/walmart/sponsored-products/keyword-targets/create-chatgpt/select-ad-group',
  WalmartSponsoredProductsKeywordTargetsChatGPTAdGroupDetails = '/ads-optimization/ads-manager/walmart/sponsored-products/keyword-targets/create-chatgpt/ad-group-details',
  WalmartSponsoredProductsKeywordTargetsChatGPTFeedback = '/ads-optimization/ads-manager/walmart/sponsored-products/keyword-targets/create-chatgpt/feedback',
  // TODO: Update  strings. Add ad type and channel name in the key.
  KeywordTargets = '',
  ProductTargets = '',
}

export const ADS_MANAGER_PAGE_TO_AD_TYPE_MAPPER: Record<
  ADS_MANAGER_AD_LEVEL_PAGES,
  AdType
> = {
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsAdGroups]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayAdGroups]:
    AdType.SponsoredDisplay,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsProductAds]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsTargets]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsCampaigns]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosCampaigns]:
    AdType.SponsoredVideos,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosAdGroups]:
    AdType.SponsoredVideos,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosKeywordTargets]:
    AdType.SponsoredVideos,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsAdGroups]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductAds]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsTargets]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsCampaigns]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsCampaigns]:
    AdType.SponsoredBrands,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsAdGroups]:
    AdType.SponsoredBrands,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayCampaigns]:
    AdType.SponsoredDisplay,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsKeywordTargets]:
    AdType.SponsoredBrands,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayTargets]:
    AdType.SponsoredDisplay,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayProductAds]:
    AdType.SponsoredDisplay,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsProductsTarget]:
    AdType.SponsoredBrands,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductsTarget]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.KeywordTargets]: AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierCampaigns]:
    AdType.SearchBrandAmplifier,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierAdGroups]:
    AdType.SearchBrandAmplifier,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProductAds]:
    AdType.SearchBrandAmplifier,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProfiles]:
    AdType.SearchBrandAmplifier,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierKeywordTargets]:
    AdType.SearchBrandAmplifier,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsSbAds]:
    AdType.SponsoredBrands,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPT]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTConfigure]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTSelectAdGroup]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTAdGroupDetails]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTFeedback]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPT]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTConfigure]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTSelectAdGroup]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTAdGroupDetails]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTFeedback]:
    AdType.SponsoredProducts,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosSVAds]:
    AdType.SponsoredVideos,
};

export const ADS_MANAGER_PAGE_TO_SALESCHANNEL_MAPPER: Partial<
  Record<ADS_MANAGER_AD_LEVEL_PAGES, FlywheelSalesChannel>
> = {
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsAdGroups]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayAdGroups]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsProductAds]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsTargets]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsCampaigns]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierCampaigns]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierAdGroups]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProductAds]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProfiles]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierKeywordTargets]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsAdGroups]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductAds]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsTargets]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsCampaigns]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsCampaigns]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsAdGroups]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayCampaigns]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsKeywordTargets]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayTargets]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayProductAds]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsProductsTarget]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductsTarget]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsSbAds]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPT]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTConfigure]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTSelectAdGroup]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTAdGroupDetails]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTFeedback]:
    FlywheelSalesChannel.Amazon,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPT]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTConfigure]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTSelectAdGroup]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTAdGroupDetails]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTFeedback]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosCampaigns]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosAdGroups]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosSVAds]:
    FlywheelSalesChannel.Walmart,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosKeywordTargets]:
    FlywheelSalesChannel.Walmart,
};

export const ADS_MANAGER_DASHBOARD = '/ads-optimization/ads-manager/';

export enum ADS_MANAGER_AD_LEVEL_PAGES_URI {
  Campaigns = 'campaigns',
  Targets = 'targets',
  KeywordTargets = 'keyword-targets',
  ProductTargets = 'product-targets',
  ProductAds = 'product-ads',
  AdGroups = 'ad-groups',
  Profiles = 'profiles',
  SbAds = 'sb-ads',
  SvAds = 'sv-ads',
}

export const ADLEVEL_TABLE_ID_TO_AD_LEVEL_MAPPER: Record<string, AdLevel> = {
  [ADS_MANAGER_ADGROUP_TABLE_ID]: AdLevel.AdGroups,
  [ADS_MANAGER_AD_ITEMS_TABLE_ID]: AdLevel.ProductAds,
  [ADS_MANAGER_TARGETS_TABLE_ID]: AdLevel.KeywordTargets,
  [ADS_MANAGER_CAMPAIGNS_TABLE_ID]: AdLevel.Campaigns,
  [ADS_MANAGER_PROFILES_TABLE_ID]: AdLevel.Profiles,
  [ADS_MANAGER_SB_ADS_TABLE_ID]: AdLevel.SbAds,
};

export const ADS_MANAGER_PAGE_TO_AD_LEVEL_MAPPER: Record<
  ADS_MANAGER_AD_LEVEL_PAGES,
  AdLevel
> = {
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsAdGroups]:
    AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayAdGroups]: AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsProductAds]:
    AdLevel.ProductAds,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsTargets]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsCampaigns]:
    AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosCampaigns]:
    AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosAdGroups]: AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosKeywordTargets]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsAdGroups]:
    AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductAds]:
    AdLevel.ProductAds,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsTargets]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsCampaigns]:
    AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsCampaigns]:
    AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsAdGroups]: AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayCampaigns]:
    AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsKeywordTargets]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsSbAds]: AdLevel.SbAds,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayTargets]: AdLevel.Targets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayProductAds]:
    AdLevel.ProductAds,
  [ADS_MANAGER_AD_LEVEL_PAGES.KeywordTargets]: AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsProductsTarget]:
    AdLevel.ProductTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductsTarget]:
    AdLevel.ProductTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierCampaigns]:
    AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierAdGroups]:
    AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProductAds]:
    AdLevel.ProductAds,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProfiles]:
    AdLevel.Profiles,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierKeywordTargets]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPT]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTConfigure]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTSelectAdGroup]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTAdGroupDetails]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTFeedback]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPT]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTConfigure]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTSelectAdGroup]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTAdGroupDetails]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTFeedback]:
    AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosSVAds]: AdLevel.SvAds,
};

export const ADS_MANAGER_DETAILS_PAGE_AD_LEVEL_MAPPER: Record<
  AdLevel,
  Array<AdLevel>
> = {
  [AdLevel.Campaigns]: [
    AdLevel.AdGroups,
    AdLevel.SbAds,
    AdLevel.KeywordTargets,
    AdLevel.ProductTargets,
  ],
  [AdLevel.AdGroups]: [
    AdLevel.Targets,
    AdLevel.ProductAds,
    AdLevel.SvAds,
    AdLevel.KeywordTargets,
    AdLevel.Profiles,
    AdLevel.ProductTargets,
  ],
  [AdLevel.ProductAds]: [],
  [AdLevel.Profiles]: [],
  [AdLevel.Targets]: [],
  [AdLevel.KeywordTargets]: [],
  [AdLevel.ProductTargets]: [],
  [AdLevel.SbAds]: [],
  [AdLevel.SvAds]: [],
};

export const AD_LEVEL_TO_ADS_MANAGER_PAGE_MAPPER: Record<
  AdLevel,
  ADS_MANAGER_AD_LEVEL_PAGES[]
> = {
  [AdLevel.AdGroups]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosAdGroups,
  ],
  [AdLevel.ProductAds]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayProductAds,
  ],
  [AdLevel.Campaigns]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosCampaigns,
  ],
  [AdLevel.KeywordTargets]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierKeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.KeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsKeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPT,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTConfigure,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTSelectAdGroup,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTAdGroupDetails,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPTFeedback,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPT,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTConfigure,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTSelectAdGroup,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTAdGroupDetails,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPTFeedback,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosKeywordTargets,
  ],
  [AdLevel.ProductTargets]: [
    ADS_MANAGER_AD_LEVEL_PAGES.ProductTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsProductsTarget,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductsTarget,
  ],
  [AdLevel.Targets]: [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayTargets],
  [AdLevel.Profiles]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProfiles,
  ],
  [AdLevel.SbAds]: [ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsSbAds],
  [AdLevel.SvAds]: [ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosSVAds],
};

export const ADS_MANAGER_PAGE_URI_TO_AD_LEVEL_MAPPER: Record<
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AdLevel
> = {
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups]: AdLevel.AdGroups,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Targets]: AdLevel.Targets,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns]: AdLevel.Campaigns,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.KeywordTargets]: AdLevel.KeywordTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductTargets]: AdLevel.ProductTargets,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductAds]: AdLevel.ProductAds,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Profiles]: AdLevel.Profiles,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.SbAds]: AdLevel.SbAds,
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.SvAds]: AdLevel.SvAds,
};

export const AD_LEVEL_TO_ADS_MANAGER_PAGE_URI_MAPPER: Record<
  AdLevel,
  ADS_MANAGER_AD_LEVEL_PAGES_URI
> = {
  [AdLevel.AdGroups]: ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups,
  [AdLevel.Targets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Targets,
  [AdLevel.ProductAds]: ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductAds,
  [AdLevel.KeywordTargets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.KeywordTargets,
  [AdLevel.Campaigns]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns,
  [AdLevel.ProductTargets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductTargets,
  [AdLevel.Profiles]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Profiles,
  [AdLevel.SbAds]: ADS_MANAGER_AD_LEVEL_PAGES_URI.SbAds,
  [AdLevel.SvAds]: ADS_MANAGER_AD_LEVEL_PAGES_URI.SvAds,
};

export enum ADS_MANAGER_EMBEDDED_ROUTES {
  WalmartSponsoredProductsAdGroups = 'walmart/sponsored-products/ad-groups',
  WalmartSponsoredProductsProductAds = 'walmart/sponsored-products/product-ads',
  WalmartSponsoredProductsTargets = 'walmart/sponsored-products/keyword-targets',
  WalmartSponsoredProductsCampaigns = 'walmart/sponsored-products/campaigns',
  WalmartSponsoredProductsCampaignDetails = 'walmart/sponsored-products/campaigns/:campaignId',
  WalmartSponsoredProductsAdGroupDetails = 'walmart/sponsored-products/ad-groups/:adGroupsId/*',
  WalmartSponsoredProductsCampaignAdGroupDetails = 'walmart/sponsored-products/campaigns/:campaignId/:adGroupsId/*',
  WalmartSearchBrandAmplifierCampaigns = 'walmart/search-brand-amplifier/campaigns',
  WalmartSearchBrandAmplifierAdGroups = 'walmart/search-brand-amplifier/ad-groups',
  WalmartSearchBrandAmplifierProductAds = 'walmart/search-brand-amplifier/product-ads',
  WalmartSearchBrandAmplifierKeywordTargets = 'walmart/search-brand-amplifier/keyword-targets',
  WalmartSearchBrandAmplifierProfiles = 'walmart/search-brand-amplifier/profiles',
  WalmartSearchBrandAmplifierCampaignsDetails = 'walmart/search-brand-amplifier/campaigns/:campaignId',
  WalmartSearchBrandAmplifierAdGroupsDetails = 'walmart/search-brand-amplifier/ad-groups/:adGroupsId',
  WalmartBrandAmplifierCampaignAdGroupDetails = 'walmart/search-brand-amplifier/campaigns/:campaignId/:adGroupsId',
  WalmartSponsoredVideosCampaigns = 'walmart/sponsored-videos/campaigns',
  WalmartSponsoredVideosAdGroups = 'walmart/sponsored-videos/ad-groups',
  WalmartSponsoredVideosKeywordTargets = 'walmart/sponsored-videos/keyword-targets',
  WalmartSponsoredVideosSVAds = 'walmart/sponsored-videos/sv-ads',
  WalmartSponsoredVideosCampaignDetails = 'walmart/sponsored-videos/campaigns/:campaignId',
  WalmartSponsoredVideosAdGroupDetails = 'walmart/sponsored-videos/ad-groups/:adGroupsId',
  WalmartSponsoredVideosCampaignAdGroupDetails = 'walmart/sponsored-videos/campaigns/:campaignId/:adGroupsId',
  AmazonSponsoredProductsAdGroups = 'amazon/sponsored-products/ad-groups',
  AmazonSponsoredProductsProductAds = 'amazon/sponsored-products/product-ads',
  AmazonSponsoredProductsTargets = 'amazon/sponsored-products/keyword-targets',
  AmazonSponsoredProductsCampaigns = 'amazon/sponsored-products/campaigns',
  AmazonSponsoredProductsCampaignDetails = 'amazon/sponsored-products/campaigns/:campaignId',
  AmazonSponsoredProductsAdGroupDetails = 'amazon/sponsored-products/ad-groups/:adGroupsId/*',
  AmazonSponsoredProductsProductsTarget = 'amazon/sponsored-products/product-targets',
  AmazonSponsoredProductsKeywordTargets = 'amazon/sponsored-products/keyword-targets',
  AmazonSponsoredProductsKeywordTargetsChatGPT = 'amazon/sponsored-products/keyword-targets/create-chatgpt',
  WalmartSponsoredProductsKeywordTargetsChatGPT = 'walmart/sponsored-products/keyword-targets/create-chatgpt',
  AmazonSponsoredProductsCampaignAdGroupDetails = 'amazon/sponsored-products/campaigns/:campaignId/:adGroupsId/*',
  AmazonSponsoredBrandsProductsTarget = 'amazon/sponsored-brands/product-targets',
  AmazonSponsoredBrandsCampaigns = 'amazon/sponsored-brands/campaigns',
  AmazonSponsoredBrandsAdGroups = 'amazon/sponsored-brands/ad-groups',
  AmazonSponsoredBrandsAdGroupDetails = 'amazon/sponsored-brands/ad-groups/:adGroupsId',
  AmazonSponsoredBrandsCampaignAdGroupDetails = 'amazon/sponsored-brands/campaigns/:campaignId/:adGroupsId',
  AmazonSponsoredBrandsKeywordTargets = 'amazon/sponsored-brands/keyword-targets',
  AmazonSponsoredBrandsCampaignDetails = 'amazon/sponsored-brands/campaigns/:campaignId',
  AmazonSponsoredBrandsSbAds = 'amazon/sponsored-brands/sb-ads',
  AmazonSponsoredDisplayCampaigns = 'amazon/sponsored-display/campaigns',
  AmazonSponsoredDisplayTargets = 'amazon/sponsored-display/targets',
  AmazonSponsoredDisplayAdGroups = 'amazon/sponsored-display/ad-groups',
  AmazonSponsoredDisplayProductAds = 'amazon/sponsored-display/product-ads',
  AmazonSponsoredDisplayCampaignDetails = 'amazon/sponsored-display/campaigns/:campaignId',
  AmazonSponsoredDisplayAdGroupDetails = 'amazon/sponsored-display/ad-groups/:adGroupsId',
  AmazonSponsoredDisplayCampaignAdGroupDetails = 'amazon/sponsored-display/campaigns/:campaignId/:adGroupsId',
}

export const AD_LEVEL_PAGE_URI_TO_PAGE_URL_MAPPER: Record<
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  ADS_MANAGER_AD_LEVEL_PAGES[]
> = {
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsAdGroups,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosAdGroups,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Targets]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayTargets,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductAds]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProductAds,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayProductAds,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.KeywordTargets]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierKeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsKeywordTargets,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsKeywordTargetsChatGPT,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsKeywordTargetsChatGPT,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosKeywordTargets,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredDisplayCampaigns,
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredProductsCampaigns,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductTargets]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsProductsTarget,
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredProductsProductsTarget,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Profiles]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierProfiles,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.SbAds]: [
    ADS_MANAGER_AD_LEVEL_PAGES.AmazonSponsoredBrandsSbAds,
  ],
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.SvAds]: [
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosSVAds,
  ],
};

export interface TableDataAdsManager {
  readonly isEditMode: boolean;
  readonly selectedAdType: AdType;
  readonly adTypes: AdType[];
  readonly merchantCountry: MerchantCountryCode;
  readonly salesChannel: FlywheelSalesChannel;
  readonly selectedMerchantCountries: Array<number | string> | string;
  readonly aoApiClient: AOApiClient;
  readonly adLevel?: AdLevel;
  readonly portfolioNames?: Portfolio[];
  readonly groupNames?: Groups[];
  readonly openCreateEditGroupSlideover?: () => void;
  readonly merchantType?: MerchantType;
  readonly productConnectionStatus?: ConnectionStatus;
  readonly updateEditedRecords?: (editedRowInfo: EditedRowInfo) => void;
  readonly selectedEndDate?: DateTime;
  readonly allMerchants: MerchantCountry[];
  readonly aiEnabled?: boolean;
  readonly merchantsWithAutomationEnabled?: string[];
  readonly aiDisabledForAllSelectedMerchants?: boolean;
  readonly isManageTreAutomationEnabled?: boolean;
  readonly onViewTrendsSlideoverClick?: (
    product: SelectedTrendsProduct
  ) => void;
  readonly showSmartCampaign?: boolean;
  readonly invokeMissingCogsApi?: (
    campaignIds: string[]
  ) => Promise<CampaignMissingCogsResponse> | undefined;
  readonly cogsDetailsDataFetcher?: (
    entityId: string,
    entityType: EntityType
  ) => PaginatedDataFetcher<EstPreAdGrossMarginItem>;
  readonly productConnectionMissingForSelectedMerchant?: boolean;
  readonly toggleTableMode?: () => void;
  readonly showSmartAdGroupBidOverrides?: boolean;
}

export enum ADLEVEL_DETAILS_FILTER_FIELD {
  campaignsId = 'campaignId',
  adGroupsId = 'adGroupId',
  entityType = 'entityType',
}

export interface TableCellChangeProductAd {
  [key: string]: PartialRecord<EditableColumnsAdItem, string>;
}

export type TableCellChange = {
  [key: string]: Record<string, string>;
};

export interface TableChangeGlobalAdLevelCurrency {
  readonly currencyCode: StrictStringMap<string>;
}
export interface TableCellChangeAdGroup {
  [key: string]: Record<EditableColumnsAdGroup, string>;
}
export interface TableCellChangeTarget {
  [key: string]: Record<EditableColumnsTarget, string>;
}
export interface TableCellChangeCampaign {
  [key: string]: PartialRecord<EditableColumnsCampaign, string>;
}

export interface TableCellChangeProfile {
  [key: string]: PartialRecord<EditableColumnsProfile, string>;
}

export interface TableCellChangeSbAd {
  [key: string]: PartialRecord<EditableColumnsSbAd, string>;
}

export interface TableCellChangeSvAd {
  [key: string]: Record<EditableColumnsSvAds, string>;
}

export const AD_LEVEL_EXTERNAL_ID_MAPPER: Record<AdLevel, Array<string>> = {
  [AdLevel.Campaigns]: ['extCampaignId'],
  [AdLevel.AdGroups]: ['extAdGroupId'],
  [AdLevel.ProductAds]: ['extItemId'],
  [AdLevel.Targets]: ['extTargetId'],
  [AdLevel.KeywordTargets]: ['extTargetId'],
  [AdLevel.ProductTargets]: ['extTargetId'],
  [AdLevel.Profiles]: ['extProfileId'],
  [AdLevel.SbAds]: ['extAdId'],
  [AdLevel.SvAds]: ['extSvAdId'],
};

export const AD_LEVEL_INTERNAL_ID_MAPPER: Partial<Record<AdLevel, string>> = {
  [AdLevel.Campaigns]: 'campaignId',
  [AdLevel.AdGroups]: 'adGroupId',
};

export const EXT_CREATIVE_ID = 'extCreativeId';
export const EXT_CAMPAIGN_ID = 'extCampaignId';
export const EXT_ADGROUP_ID = 'extAdGroupId';
export const END_DATE_PLACEHOLDER = 'MM/DD/YYYY';
export const AD_ITEM_SEARCH_KEY_FIELD = 'searchText';
export const AD_ITEMS_EXT_ITEM_ID_FILTER_FIELD = 'extItemId';

export enum AdTypeInUrl {
  SponsoredProducts = 'sponsored-products',
  SearchBrandAmplifier = 'search-brand-amplifier',
  SponsoredDisplay = 'sponsored-display',
  SponsoredBrands = 'sponsored-brands',
  SponsoredVideos = 'sponsored-videos',
}

export const AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER: Record<
  AdType,
  AdTypeInUrl
> = {
  [AdType.SponsoredProducts]: AdTypeInUrl.SponsoredProducts,
  [AdType.SearchBrandAmplifier]: AdTypeInUrl.SearchBrandAmplifier,
  [AdType.SponsoredBrands]: AdTypeInUrl.SponsoredBrands,
  [AdType.SponsoredDisplay]: AdTypeInUrl.SponsoredDisplay,
  // This is just a placeholder. We don't really use SponsoredBrandsVideo anywhere
  [AdType.SponsoredBrandsVideo]: AdTypeInUrl.SponsoredDisplay,
  [AdType.SponsoredVideos]: AdTypeInUrl.SponsoredVideos,
};

export const AD_LEVEL_VALUE_TO_AD_LEVEL_IN_URL_MAPPER: Record<
  AdLevel,
  ADS_MANAGER_AD_LEVEL_PAGES_URI
> = {
  [AdLevel.Campaigns]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns,
  [AdLevel.AdGroups]: ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups,
  [AdLevel.ProductAds]: ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductAds,
  [AdLevel.Targets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Targets,
  [AdLevel.KeywordTargets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.KeywordTargets,
  [AdLevel.ProductTargets]: ADS_MANAGER_AD_LEVEL_PAGES_URI.ProductTargets,
  [AdLevel.Profiles]: ADS_MANAGER_AD_LEVEL_PAGES_URI.Profiles,
  [AdLevel.SbAds]: ADS_MANAGER_AD_LEVEL_PAGES_URI.SbAds,
  [AdLevel.SvAds]: ADS_MANAGER_AD_LEVEL_PAGES_URI.SvAds,
};

export interface Checkbox {
  readonly [key: string]: {
    readonly isSelected: boolean;
    readonly isDisabled?: boolean;
  };
}

export interface TabCheckbox {
  readonly [key: string]: Checkbox;
}

export interface DataInspectionAdLevelConfigProps {
  adLevel: AdLevel;
  columns: TabCheckbox;
}

interface DataInspectionAdTypeConfigProps {
  adType: AdType;
  config: DataInspectionAdLevelConfigProps[];
}

export type DataInspectorColumnManagerConfigProps = {
  readonly [salesChannel in FlywheelSalesChannel]: DataInspectionAdTypeConfigProps[];
};

export const EXPRESSION_TYPE_TO_FILTER_I18NKEY_MAPPER: Record<
  ExpressionType,
  I18nKey
> = {
  [ExpressionType.Purchases]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_PURCHASES,
  [ExpressionType.Views]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_VIEWS,
  [ExpressionType.Audience]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_AUDIANCE,
  [ExpressionType.AsinSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_SAME_AS,
  [ExpressionType.ASIN_SAME_AS]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_SAME_AS,
  [ExpressionType.AsinExpandedFrom]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_EXPANDED_FROM,
  [ExpressionType.ASIN_EXPANDED_FROM]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_EXPANDED_FROM,
  [ExpressionType.AudienceSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_AUDEINCE_SAME_AS,
  [ExpressionType.AsinCategorySameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_CATEGORY_SAME_AS,
  [ExpressionType.ASIN_CATEGORY_SAME_AS]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_CATEGORY_SAME_AS,
  [ExpressionType.AsinBrandSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_BRAND_SAME_AS,
  [ExpressionType.ASIN_BRAND_SAME_AS]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_BRAND_SAME_AS,
  [ExpressionType.AsinPriceLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_LESS_THAN,
  [ExpressionType.ASIN_PRICE_LESS_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_LESS_THAN,
  [ExpressionType.AsinPriceGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_GREATER_THAN,
  [ExpressionType.ASIN_PRICE_GREATER_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_GREATER_THAN,
  [ExpressionType.AsinPriceBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_BETWEEN,
  [ExpressionType.ASIN_PRICE_BETWEEN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_BETWEEN,
  [ExpressionType.AsinReviewRatingBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_BETWEEN,
  [ExpressionType.AsinReviewRatingGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_GREATER_THAN,
  [ExpressionType.AsinReviewRatingLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_LESS_THAN,
  [ExpressionType.ASIN_REVIEW_RATING_BETWEEN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_BETWEEN,
  [ExpressionType.ASIN_REVIEW_RATING_GREATER_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_GREATER_THAN,
  [ExpressionType.ASIN_REVIEW_RATING_LESS_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_LESS_THAN,
  [ExpressionType.AsinIsPrimeShippingAvailable]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_IS_PRIME_SHIPPING_AVAILABLE,
  [ExpressionType.AsinAgeRangeSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_AGE_RANGE_SAME_AS,
  [ExpressionType.AsinGenreSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_GENRE_SAME_AS,
  [ExpressionType.Lookback]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_LOOKUP,
  [ExpressionType.QueryHighRelMatches]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_HIGH_REL_MATCHES,
  [ExpressionType.QUERY_HIGH_REL_MATCHES]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_HIGH_REL_MATCHES,
  [ExpressionType.QueryBroadRelMatches]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_BROAD_REL_MATCHES,
  [ExpressionType.QUERY_BROAD_REL_MATCHES]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_BROAD_REL_MATCHES,
  [ExpressionType.AsinSubstituteRelated]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SUBSTITUTE_RELATED,
  [ExpressionType.ASIN_SUBSTITUTE_RELATED]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SUBSTITUTE_RELATED,
  [ExpressionType.AsinAccessoryRelated]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_ACCESSORY_RELATED,
  [ExpressionType.ASIN_ACCESSORY_RELATED]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_ACCESSORY_RELATED,
  [ExpressionType.SimilarProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_SIMILAR_PRODUCT,
  [ExpressionType.ExactProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_EXACT_PRODUCT,
  [ExpressionType.RelatedProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_RELATED_PRODUCT,
  [ExpressionType.AsinIsPrimeShippingEligible]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_IS_PRIME_SHIPPING_AVAILABLE,
};

export const EXPRESSION_TYPE_TO_I18NKEY_MAPPER_WITHOUT_EXPRESSION_VALUE: Record<
  ExpressionType,
  I18nKey
> = {
  [ExpressionType.Purchases]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_PURCHASES,
  [ExpressionType.Views]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_VIEWS,
  [ExpressionType.Audience]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_AUDIANCE,
  [ExpressionType.AsinSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SAME_AS_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.ASIN_SAME_AS]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SAME_AS_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinExpandedFrom]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_EXPANDED_FROM,
  [ExpressionType.ASIN_EXPANDED_FROM]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_EXPANDED_FROM,
  [ExpressionType.AudienceSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_AUDIENCE_SAME_AS_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinCategorySameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_CATEGORY_SAME_AS_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.ASIN_CATEGORY_SAME_AS]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_CATEGORY_SAME_AS_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinBrandSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_BRAND_SAME_AS_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.ASIN_BRAND_SAME_AS]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_BRAND_SAME_AS_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinPriceLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_LESS_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.ASIN_PRICE_LESS_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_LESS_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinPriceGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_GREATER_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.ASIN_PRICE_GREATER_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_GREATER_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinPriceBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_BETWEEN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.ASIN_PRICE_BETWEEN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_BETWEEN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinReviewRatingBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_BETWEEN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinReviewRatingGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_GREATER_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinReviewRatingLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_LESS_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.ASIN_REVIEW_RATING_BETWEEN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_BETWEEN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.ASIN_REVIEW_RATING_GREATER_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_GREATER_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.ASIN_REVIEW_RATING_LESS_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_LESS_THAN_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinIsPrimeShippingAvailable]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SHIPPING_IS_PRIME_ELIGIBLE_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinAgeRangeSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_AGE_RANGE_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.AsinGenreSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_GENRE_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.Lookback]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_LOOKBACK_WITHOUT_EXPRESSION_VALUE,
  [ExpressionType.QueryHighRelMatches]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_HIGH_REL_MATCHES,
  [ExpressionType.QueryBroadRelMatches]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_BROAD_REL_MATCHES,
  [ExpressionType.AsinSubstituteRelated]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SUBSTITUTE_RELATED,
  [ExpressionType.AsinAccessoryRelated]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_ACCESSORY_RELATED,
  [ExpressionType.QUERY_HIGH_REL_MATCHES]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_HIGH_REL_MATCHES,
  [ExpressionType.QUERY_BROAD_REL_MATCHES]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_BROAD_REL_MATCHES,
  [ExpressionType.ASIN_SUBSTITUTE_RELATED]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SUBSTITUTE_RELATED,
  [ExpressionType.ASIN_ACCESSORY_RELATED]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_ACCESSORY_RELATED,
  [ExpressionType.SimilarProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_SIMILAR_PRODUCT,
  [ExpressionType.ExactProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_EXACT_PRODUCT,
  [ExpressionType.RelatedProduct]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_RELATED_PRODUCT,
  [ExpressionType.AsinIsPrimeShippingEligible]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_EXACT_ASIN_IS_PRIME_SHIPPING_ELIGIBLE,
};

export const EXPRESSION_TYPE_TO_CONDITIONAL_TEXT_I18NKEY_MAPPER: Partial<
  Record<ExpressionType, I18nKey>
> = {
  [ExpressionType.AsinSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.ASIN_SAME_AS]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinCategorySameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.ASIN_CATEGORY_SAME_AS]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinBrandSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.ASIN_BRAND_SAME_AS]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinPriceLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_LESS_THAN,
  [ExpressionType.ASIN_PRICE_LESS_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_LESS_THAN,
  [ExpressionType.AsinPriceGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_GREATER_THAN,
  [ExpressionType.ASIN_PRICE_GREATER_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_GREATER_THAN,
  [ExpressionType.AsinPriceBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_BETWEEN,
  [ExpressionType.ASIN_PRICE_BETWEEN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_BETWEEN,
  [ExpressionType.AsinReviewRatingBetween]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_BETWEEN,
  [ExpressionType.AsinReviewRatingGreaterThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_GREATER_THAN,
  [ExpressionType.AsinReviewRatingLessThan]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_LESS_THAN,
  [ExpressionType.ASIN_REVIEW_RATING_BETWEEN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_BETWEEN,
  [ExpressionType.ASIN_REVIEW_RATING_GREATER_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_GREATER_THAN,
  [ExpressionType.ASIN_REVIEW_RATING_LESS_THAN]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_LESS_THAN,
  [ExpressionType.AsinIsPrimeShippingAvailable]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinAgeRangeSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinGenreSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.Lookback]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AsinIsPrimeShippingEligible]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
  [ExpressionType.AudienceSameAs]:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS,
};

export const EXPRESSION_TYPE_NON_CONDITIONAL_TEXT_ARRAY: ExpressionType[] = [
  ExpressionType.QueryHighRelMatches,
  ExpressionType.QueryBroadRelMatches,
  ExpressionType.AsinSubstituteRelated,
  ExpressionType.AsinAccessoryRelated,
  ExpressionType.SimilarProduct,
  ExpressionType.ExactProduct,
  ExpressionType.RelatedProduct,
];

export const EXPRESSION_TYPE_BOOLEAN_KEYS: ExpressionType[] = [
  ExpressionType.AsinIsPrimeShippingEligible,
];

export const AD_TYPE_DEFAULT_VALUE: Record<FlywheelSalesChannel, AdType> = {
  [FlywheelSalesChannel.Walmart]: AdType.SponsoredProducts,
  [FlywheelSalesChannel.Amazon]: AdType.SponsoredProducts,
};

export const CAMPAIGN_FILTER_DATE_FORMAT_MOMENT = 'yyyy-MM-dd';

export interface AoAdsManagerUrlParts {
  readonly adLevel: string;
  readonly adType: string;
  readonly salesChannel: string;
}

export interface NumericValueConfig {
  readonly min?: number;
  readonly max?: number;
  readonly default?: number;
}

export interface NumericValueConfigProps extends NumericValueConfig {
  readonly adTypes: AdType[];
  readonly salesChannels: FlywheelSalesChannel[];
  readonly columnNames: string[];
  readonly countryCodes: MerchantCountryCode[];
  readonly campaignTargetingType?: CampaignTargetingType;
  readonly merchantType?: MerchantType;
  readonly campaignCostType?: CampaignCostType;
}

export const TARGET_TYPE_API_RESPONSE_TO_AD_LEVEL_MAPPER: Record<
  AdType,
  Record<string, AdLevel>
> = {
  [AdType.SponsoredBrands]: {
    [KEYWORD_TARGETING]: AdLevel.KeywordTargets,
    [SB_NEGATIVE_KEYWORD_TARGET]: AdLevel.KeywordTargets,
    [PRODUCT_ATTRIBUTE_TARGETING]: AdLevel.ProductTargets,
  },
  [AdType.SearchBrandAmplifier]: {},
  [AdType.SponsoredBrandsVideo]: {
    [KEYWORD_TARGETING]: AdLevel.KeywordTargets,
    [SB_NEGATIVE_KEYWORD_TARGET]: AdLevel.KeywordTargets,
    [PRODUCT_ATTRIBUTE_TARGETING]: AdLevel.ProductTargets,
  },
  [AdType.SponsoredProducts]: {
    [KEYWORD_TARGETING]: AdLevel.KeywordTargets,
    [SP_NEGATIVE_KEYWORD]: AdLevel.KeywordTargets,
    [SP_CAMPAIGN_NEGATIVE_KEYWORD]: AdLevel.KeywordTargets,
    [PRODUCT_ATTRIBUTE_TARGETING]: AdLevel.ProductTargets,
  },
  [AdType.SponsoredDisplay]: {
    [KEYWORD_TARGETING]: AdLevel.KeywordTargets,
    [PRODUCT_ATTRIBUTE_TARGETING]: AdLevel.Targets,
  },
  [AdType.SponsoredVideos]: {},
};

export const TARGET_TYPE_API_TO_AD_LEVEL_MAPPER: Record<AdLevel, string> = {
  [AdLevel.Campaigns]: 'campaign',
  [AdLevel.AdGroups]: 'adgroup',
  [AdLevel.Targets]: 'target',
  [AdLevel.ProductAds]: 'productAd',
  [AdLevel.KeywordTargets]: 'keywordTarget',
  [AdLevel.ProductTargets]: 'productTarget',
  [AdLevel.Profiles]: 'profile',
  [AdLevel.SbAds]: 'sbAds',
  [AdLevel.SvAds]: 'svAds',
};

export const ENTITY_TYPE_FOR_TARGET_MAPPER: Partial<
  Record<AdLevel, EntityTypeRequestParameterForTargets>
> = {
  [AdLevel.KeywordTargets]:
    EntityTypeRequestParameterForTargets.KeywordTargeting,
  [AdLevel.ProductTargets]:
    EntityTypeRequestParameterForTargets.ProductTargetting,
  [AdLevel.Targets]: EntityTypeRequestParameterForTargets.Targets,
};
export interface EditedRowInfo {
  readonly rowId: string;
  readonly isNameUnique: boolean;
}

export const TARGET_ADLEVEL_LIST = [
  AdLevel.Targets,
  AdLevel.KeywordTargets,
  AdLevel.ProductTargets,
];
export const MERCHANT_NAME_COLUMN_ID_FOR_EXPORT = 'merchantName';

export const AD_TYPE_SHORT_FORM: Record<AdType, string> = {
  [AdType.SponsoredProducts]: 'sp',
  [AdType.SponsoredDisplay]: 'sd',
  [AdType.SponsoredBrands]: 'sb',
  [AdType.SponsoredBrandsVideo]: 'sbv',
  [AdType.SearchBrandAmplifier]: 'sba',
  [AdType.SponsoredVideos]: 'sv',
};

export const CAMPAIGN_COST_TYPE_MAPPER: Record<CampaignCostType, I18nKey> = {
  [CampaignCostType.CPC]: I18nKey.ADS_MANAGER_CAMPAIGN_COST_TYPE_CPC,
  [CampaignCostType.VCPM]: I18nKey.ADS_MANAGER_CAMPAIGN_COST_TYPE_VCPM,
};

export const ADGROUP_AI_RECOMMENDATION_KEYWORD_TYPE: Record<
  AiRecommendedKeywordType,
  I18nKey
> = {
  [AiRecommendedKeywordType.AddAutomatically]:
    I18nKey.ADS_MANAGER_CAMPAIGN_AI_RECOMMENDED_KEYWORD_ADD_AUTOMATICALLY,
  [AiRecommendedKeywordType.ReviewRecommendations]:
    I18nKey.ADS_MANAGER_CAMPAIGN_AI_RECOMMENDED_KEYWORD_REVIEW_RECOMMENDATION,
};

export const KEYWORD_REVIEW_STATUS_MAPPER: Record<
  KeywordReviewStatusType,
  I18nKey
> = {
  [KeywordReviewStatusType.Approved]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_APPROVED,
  [KeywordReviewStatusType.Pending]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
  [KeywordReviewStatusType.Rejected]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_REJECTED,
};

export const ADGROUP_REVIEW_STATUS_MAPPER: Record<
  AdGroupReviewStatusType,
  I18nKey
> = {
  [AdGroupReviewStatusType.Pending]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
  [AdGroupReviewStatusType.InProgress]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_INPROGRESS,
  [AdGroupReviewStatusType.Complete]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_COMPLETED,
  [AdGroupReviewStatusType.Cancelled]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_CANCELLED,
};

export const PRODUCT_REVIEW_STATUS_MAPPER: Record<
  ProductReviewStatusType,
  I18nKey
> = {
  [ProductReviewStatusType.Approved]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_APPROVED,
  [ProductReviewStatusType.Pending]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
  [ProductReviewStatusType.Rejected]:
    I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_REJECTED,
};

export const REVIEW_URLS = {
  SBA_PROFILE_REVIEW_STATUS:
    'https://intercom.help/flywheel-20/en/articles/5831762-walmart-sba-ads',
  DEFAULT: 'https://www.teikametrics.com/help/ao-keyword-review-status-tooltip',
};

export const AddedByI8nKeyMapper: Record<string, I18nKey> = {
  [AddedBy.CampaignCreatorV1]: I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_AI_REC,
  [AddedBy.FlywheelAutomated]: I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_AI_REC,
  [AddedBy.FlywheelAccepted]: I18nKey.ADS_MANAGER_TARGET_ADDEDBY_TRE_REC,
  [AddedBy.CampaignCreatorAiRecommended]:
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_TRE_REC,
  [AddedBy.AdsManagerOnDemand]: I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_ON_DEMAND,
  [AddedBy.FlywheelOnDemand]: I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_ON_DEMAND,
  [AddedBy.CampaignCreatorOnDemand]:
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_ON_DEMAND,
  [AddedBy.External]: I18nKey.GENERIC_EXTERNAL,
};

export const KEYWORD_TARGET_TYPE_TO_TARGET_TYPE_I18NKEY_MAPPER: Record<
  KeywordTargetType,
  I18nKey
> = {
  [KeywordTargetType.Keyword]: I18nKey.ADS_MANAGER_TARGET_TYPE_KEYWORD,
  [KeywordTargetType.NegativeKeyword]:
    I18nKey.ADS_MANAGER_TARGET_TYPE_NEGATIVE_KEYWORD,
  [KeywordTargetType.CampaignNegativeKeyword]:
    I18nKey.ADS_MANAGER_TARGET_TYPE_CAMPAIGN_NEGATIVE_KEYWORD,
  [KeywordTargetType.NegativeProductTarget]:
    I18nKey.ADS_MANAGER_TARGET_TYPE_NEGATIVE_PRODUCT_TARGET,
  [KeywordTargetType.AudienceTarget]:
    I18nKey.ADS_MANAGER_TARGET_TYPE_AUDIENCE_TARGET,
  [KeywordTargetType.ProductTarget]:
    I18nKey.ADS_MANAGER_TARGET_TYPE_PRODUCT_TARGET,
  [KeywordTargetType.ProductAttribute]:
    I18nKey.ADS_MANAGER_TARGET_TYPE_PRODUCT_ATTRIBUTE,
};

export enum CampaignBulkEditControls {
  Portfolio = 'portfolio',
  Group = 'groupLabel',
  CampaignStatus = 'campaignStatus',
  EndDate = 'endDate',
  CampaignDailyBudget = 'campaignDailyBudget',
  CampaignLifetimeBudget = 'campaignLifetimeBudget',
  AmazonBiddingStrategy = 'amazonBiddingStrategy',
  PlacementBidMultipliers = 'placementBidMultipliers',
  AmazonBidOptimization = 'amazonBidOptimization',
  AutomatedBidding = 'automatedBidding',
  ACoSTarget = 'acosTarget',
  SBCampaignsMinBid = 'SBCampaignMinBid',
  SBVideoCampaignsMinBid = 'SBVideoCampaignsMinBid',
  SBCampaignsMaxBid = 'SBCampaignMaxBid',
  SBVideoCampaignsMaxBid = 'SBVideoCampaignsMaxBid',
  SBCampaignBids = 'SBCampaignBids',
  SBVideoCampaignBids = 'SBVideoCampaignBids',
  BudgetRollover = 'BudgetRollover',
  PlacementInclusion = 'placementInclusion',
  PlatformBidMultipliers = 'platformBidMultipliers',
  ManualCampaignsPlacementBidMultipliers = 'manualCampaignsPlacementBidMultipliers',
  AutoCampaignsPlacementBidMultipliers = 'autoCampaignsPlacementBidMultipliers',
  AutomationStatus = 'AutomationStatus',
  AdvertisingGoal = 'AdvertisingGoal',
}

export interface BulkEditItem {
  readonly bulkEditControl: EditControlProps;
  readonly adTypes: AdType[];
  readonly adLevels?: AdLevel[];
  readonly salesChannels: FlywheelSalesChannel[];
  readonly isValidForSelectedRows?: boolean;
}
export interface BulkEditModalNumericTypeValue {
  operator?: BulkEditModalNumericValueOperator;
  value?: string;
}

export type BulkEditModalValues = {
  [key: string]:
    | {
        operator?: BulkEditModalNumericValueOperator;
        value?: string;
      }
    | ProductAdStatusValue
    | AdGroupStatus
    | AiRecommendedKeywordType
    | string
    | string[];
};

export interface BulkEditSelectedOptions {
  [key: string]: boolean;
}

export enum ConfirmationModalClickKind {
  APPLY = 'APPLY',
  CANCEL = 'CANCEL',
}

export const CSV_UPLOAD_SYNC_STATUS_POLLING_INTERVAL = 2000;
export const CSV_UPLOAD_SYNC_STATUS_POLLING_MAX_TRIES = 50;

export interface ValidationCount {
  readonly totalCount: number;
  readonly errorCount: number;
}

export enum ValidateUploadErrorResponses {
  MissingBidConfig = 'MISSING_BID_CONFIG',
  MissingMandatoryColumns = 'MISSING_MANDATORY_COLUMNS',
}

export enum UploadStatusResponses {
  SUCCESS = 'success',
  IN_PROGRESS = 'in progress',
  FAILED = 'failed',
  UNKNOWN = 'unknown',
}

export interface ValidateUploadErrorResponsesStructure {
  readonly errorCode: ValidateUploadErrorResponses;
  readonly status: string;
  readonly message: string;
}

export enum ChatGPTEmbeddedURLs {
  AmazonSponsoredProductsKeywordTargetsChatGPT = 'amazon/sponsored-products/keyword-targets/create-chatgpt/*',
  AmazonSponsoredProductsAdGroupDetailsChatGPT = 'amazon/sponsored-products/ad-groups/:adGroupId/create-chatgpt/*',
  AmazonSponsoredProductsCampaignAdGroupDetailsChatGPT = 'amazon/sponsored-products/campaigns/:campaignId/:adGroupId/create-chatgpt/*',
  AmazonSponsoredProductsCampaignDetailsChatGPT = 'amazon/sponsored-products/campaigns/:campaignId/create-chatgpt/*',
  WalmartSponsoredProductsKeywordTargetsChatGPT = 'walmart/sponsored-products/keyword-targets/create-chatgpt/*',
}

export enum CampaignTypeQuickFilterKey {
  All = 'All',
  Smart = 'Smart',
  External = 'External',
}

export enum COGSUploadStatus {
  Loading = 'Loading',
  Success = 'Success',
  MissingCogs = 'MissingCogs',
}
