import classNames from 'classnames';
import I18nKey from '../../lib/types/I18nKey';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  CalendarIcon,
  CashLinearIcon,
  CursorDoubleClickIcon,
  Icon,
  IconSize,
  KebabLinearIcon,
  ShoppingBagCheckMarkIcon,
} from '@teikametrics/tm-design-system';

interface CriteriaSettingsProps {
  readonly adConversions?: string;
  readonly clicks?: string;
  readonly adSpend?: string;
  readonly previousByNumberOfDays?: string;
  readonly onOpen?: () => void;
}

export const CriteriaSettings: React.FC<CriteriaSettingsProps> = ({
  adConversions,
  clicks,
  adSpend,
  previousByNumberOfDays,
  onOpen,
}) => {
  return (
    <div
      className={classNames(
        'flex justify-between bg-grey-50',
        'w-fit h-32 px-8 py-4',
        'border border-solid border-grey-200',
        'text-base'
      )}
    >
      <div className="flex justify-center items-center gap-8">
        <Icon
          size={IconSize.Medium}
          svg={ShoppingBagCheckMarkIcon}
          className="text-grey-400  "
        />
        <div>
          <FormattedMessage
            id={I18nKey.KWA_CRITERIA_SETTINGS_AD_CONVERSIONS}
            values={{
              bold: (content) => (
                <span className="font-semibold ml-4">{content}</span>
              ),
              ad_conversions: adConversions,
            }}
          />
        </div>
        <div className="flex justify-center items-center gap-8 ml-4">
          <Icon
            size={IconSize.Medium}
            svg={CursorDoubleClickIcon}
            className="text-grey-400  "
          />
          <div>
            <FormattedMessage
              id={I18nKey.KWA_CRITERIA_SETTINGS_AD_CLICKS}
              values={{
                bold: (content) => (
                  <span className="font-semibold ml-4">{content}</span>
                ),
                clicks: clicks,
              }}
            />
          </div>
        </div>
        {adSpend && (
          <div className="flex justify-center items-center gap-8 ml-4">
            <Icon
              size={IconSize.Medium}
              svg={CashLinearIcon}
              className="text-grey-500 "
            />
            <div>
              <FormattedMessage
                id={I18nKey.KWA_CRITERIA_SETTINGS_AD_SPEND}
                values={{
                  bold: (content) => (
                    <span className="font-semibold ml-4">{content}</span>
                  ),
                  ad_spend: adSpend,
                }}
              />
            </div>
          </div>
        )}
        {previousByNumberOfDays && (
          <div className="flex justify-center items-center gap-8 ml-4">
            <Icon
              size={IconSize.Medium}
              svg={CalendarIcon}
              className="text-grey-500"
            />
            <div>
              <FormattedMessage
                id={I18nKey.KWA_CRITERIA_SETTINGS_PREVIOUS_DAYS}
                values={{
                  previousByNumberOfDays: previousByNumberOfDays,
                  bold: (content) => (
                    <span className="font-semibold ml-4">{content}</span>
                  ),
                }}
              />
            </div>
          </div>
        )}
        <div className="flex justify-center items-center gap-8 text-grey-500">
          <Button
            size={ButtonSize.InlineIcon}
            variant={ButtonVariant.BlackAndWhite}
            className="text-base leading-none font-normal text-grey-900"
            svgIcon={KebabLinearIcon}
            onClick={onOpen}
          />
        </div>
      </div>
    </div>
  );
};
