import {
  ColumnAlign,
  MerchantCountryInline,
  TableCellV2,
  TableCellV2Type,
} from '@teikametrics/tm-design-system';
import { SalesChannelTableData } from '../types';
import { FlywheelTableV2ColumnProps } from 'containers/tableV2/types';
import { getCountryFlagIcon } from '../../../../../../lib/utilities/countryFlags';
import { getSalesChannelIconFromId } from '../../../../../recommendations/components/recommendationHubCards/recommendationCardsUtils';
import { MerchantType } from '../../../../../../lib/types/Fam';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { SalesChannelData } from '../../../../../../lib/types/Fam';

export const merchantColumn: FlywheelTableV2ColumnProps<
  SalesChannelData,
  {},
  SalesChannelTableData,
  {}
> = {
  header: I18nKey.FLASH_MODALS_AMAZON_TABLE_MERCHANT,
  cell: ({ row: { original } }) => {
    let { country, salesChannelId, merchantType, extMerchantId } = original;

    return (
      <TableCellV2
        tableCellData={{
          currentValue: (
            <MerchantCountryInline
              flagIcon={getCountryFlagIcon(country)}
              channelIcon={getSalesChannelIconFromId(salesChannelId)}
              merchantType={merchantType === MerchantType.Seller ? '3P' : '1P'}
              merchantID={extMerchantId}
            />
          ),
          tableCellType: TableCellV2Type.Text,
        }}
      />
    );
  },
  accessorKey: 'merchant',
  sortable: true,
  size: 150,
  minSize: 150,
  align: ColumnAlign.Left,
};
