import {
  AsyncRequest,
  AsyncRequestCompleted,
  AsyncRequestLoading,
  AsyncRequestNotStarted,
  asyncRequestIsComplete,
  asyncRequestIsLoading,
} from '../../../../lib/utilities/asyncRequest';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ViewTrendsSlideoverWrapper } from '../../../../components/ViewTrendsSlideover';
import { CampaignsAdGroupsSelectOptionProps } from '../../../../components/ViewTrendsSlideover/CampaignSelect';
import { getCurrentAccount } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import {
  AdLevel,
  AdType,
  DateRange,
} from '../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../lib/types/I18nKey';
import { Performance } from './Performance';
import { getCampaignSelectOptions } from './Performance/utils';
import { ViewTrendsContext, ViewTrendsState } from './ViewTrendsProvider';

export interface ViewTrendsProps {
  readonly selectedDateRange: DateRange;
  readonly aoApiClient?: AOApiClient;
  readonly adType?: AdType;
  readonly salesChannelId?: string;
}

export enum ViewTrendsEntityType {
  CAMPAIGN = 'CAMPAIGN',
  ADGROUP = 'ADGROUP',
}

export const ViewTrends: React.FC<ViewTrendsProps> = (props) => {
  const { selectedDateRange, aoApiClient, salesChannelId = '' } = props;
  const intl = useIntl();

  const userContext = useContext<UserContextState>(UserContext);
  const currentAccount = getCurrentAccount(userContext);
  const accountId = currentAccount?.id;
  const {
    campaignId = '',
    campaignName = '',
    targetingType,
    merchantCountryId = '',
    merchantName = '',
    isViewTrendsOpen = false,
    updateContext,
    adType,
    entityId = '',
    entityType,
    entityName = '',
    merchantType,
    toggleSkuSlideover,
    targetSegments,
    channelId,
  } = useContext<ViewTrendsState>(ViewTrendsContext);

  const [campaignIdOrAdGroupId, setCampaignIdOrAdGroupId] =
    useState<string>(entityId);

  const [campaignRequest, setCampaignRequest] = useState<
    AsyncRequest<CampaignsAdGroupsSelectOptionProps[]>
  >(AsyncRequestNotStarted());

  useEffect(() => {
    if (campaignId && merchantCountryId && accountId) {
      setCampaignRequest(AsyncRequestLoading());
      aoApiClient
        ?.getCampaign(
          accountId ?? '',
          campaignId ?? ''
        )({
          merchantCountryId,
          campaignId,
        })
        .then((response) => {
          setCampaignIdOrAdGroupId(entityId);
          setCampaignRequest(
            AsyncRequestCompleted(
              getCampaignSelectOptions(intl, response, targetingType)
            )
          );
        })
        .catch((e) => {});
    }
  }, [campaignId, merchantCountryId]);

  const onCampaignSelectHandler = (value?: string) => {
    setCampaignIdOrAdGroupId(value ?? '');
    if (asyncRequestIsComplete(campaignRequest)) {
      const entity = campaignRequest.result.find(
        ({ campaignId, adGroupId }) =>
          campaignId === value || adGroupId === value
      );
      const newEntityType =
        entity?.adLevel === AdLevel.AdGroups
          ? ViewTrendsEntityType.ADGROUP
          : ViewTrendsEntityType.CAMPAIGN;
      const newEntityId = entity?.adGroupId ?? entity?.campaignId;
      const newEntityName =
        entity?.adLevel === AdLevel.AdGroups
          ? entity?.adGroupName
          : entity?.campaignName;
      updateContext &&
        updateContext({
          merchantCountryId,
          merchantName,
          campaignId,
          campaignName,
          targetingType,
          isViewTrendsOpen,
          entityId: newEntityId,
          entityType: newEntityType,
          entityName: newEntityName,
          adType,
          merchantType,
          isSKUSlideoverOpen: false,
          targetSegments: entity?.targetSegments,
          channelId,
        });
    }
  };

  const onAdGroupClick = () => {
    toggleSkuSlideover && toggleSkuSlideover();
  };

  const campaignsSelectProps = {
    options: asyncRequestIsComplete(campaignRequest)
      ? campaignRequest.result
      : [],
    onChange: onCampaignSelectHandler,
    value: campaignIdOrAdGroupId,
    onAdGroupClick: () => {
      onAdGroupClick();
    },
    isLoading: asyncRequestIsLoading(campaignRequest),
    entityType: entityType as ViewTrendsEntityType,
  };

  return (
    <ViewTrendsSlideoverWrapper
      dataTestId={`${entityType?.toLowerCase()}Performance`}
      isOpen={isViewTrendsOpen}
      onClose={() => {
        updateContext &&
          updateContext({
            merchantCountryId,
            merchantName,
            campaignId,
            campaignName,
            targetingType,
            isViewTrendsOpen: false,
            entityId,
            entityType,
            entityName,
            adType,
            merchantType,
            isSKUSlideoverOpen: false,
            targetSegments,
            channelId,
          });
      }}
      overlayZindexClassname="z-20"
      campaignsSelectProps={campaignsSelectProps}
      levelControllerProps={{
        active: 'performance',
        levels: [
          {
            name: intl.formatMessage({
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_PERFORMANCE_COLUMN_GROUP,
            }),
            value: 'performance',
          },
        ],
      }}
    >
      <Performance
        selectedDateRange={selectedDateRange}
        aoApiClient={props.aoApiClient}
        campaignId={campaignId}
        merchantCountryId={merchantCountryId}
        channelId={salesChannelId}
        adType={adType}
        entityId={entityId}
        entityType={entityType}
        merchantType={merchantType}
      />
    </ViewTrendsSlideoverWrapper>
  );
};
ViewTrends.displayName = 'CampaignAdGroupPerformanceSlideover';
