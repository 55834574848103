import { Slideover } from '@teikametrics/tm-design-system';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/types/I18nKey';
import { createAOApiClient } from '../../lib/clients/AOApiClient';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { MerchantsWithStatusV2 } from '../../modules/keywordAction/components/autoNegate/merchantsWithStatusV2';
import { AutoNegateDescriptionV2 } from '../../modules/keywordAction/components/autoNegate/descriptionV2';

interface AutoNegateSettingsSlideoverProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly dataTestId?: string;
}

export const AutoNegateSettingsSlideover: React.FC<
  AutoNegateSettingsSlideoverProps
> = (props) => {
  const { isOpen, onClose } = props;

  const intl = useIntl();

  const userContext = useContext<UserContextState>(UserContext);

  const title = intl.formatMessage({
    id: I18nKey.KWA_AUTO_NEGATE_SETTINGS_HEADER,
  });

  const aoApiClient = createAOApiClient(userContext.userInfo.idToken!);

  return (
    <Slideover
      isOpen={isOpen}
      title={title}
      content={
        <div className="flex flex-col space-y-24">
          <AutoNegateDescriptionV2
            customProps={<MerchantsWithStatusV2 aoAPIClient={aoApiClient} />}
          />
        </div>
      }
      onClose={onClose}
      dataTestId={props.dataTestId}
      contentClassName="w-720 overflow-y-auto"
      hideFooter
    />
  );
};

AutoNegateSettingsSlideover.displayName = 'AutoNegateSettingsSlideover';
