import { HomepageResponseMetricKeys } from '../../../../../lib/types/CompassSharedTypes';

export const ALL_COMPASS_METRIC_FIELDS = [
  HomepageResponseMetricKeys.ACoSTotal,
  HomepageResponseMetricKeys.AdConversions,
  HomepageResponseMetricKeys.AdSales,
  HomepageResponseMetricKeys.AdSpend,
  HomepageResponseMetricKeys.AverageSalesPrice,
  HomepageResponseMetricKeys.CPC,
  HomepageResponseMetricKeys.ClickThroughRate,
  HomepageResponseMetricKeys.Clicks,
  HomepageResponseMetricKeys.ConversionRate,
  HomepageResponseMetricKeys.Impressions,
  HomepageResponseMetricKeys.Roas,
  HomepageResponseMetricKeys.Tacos,
  HomepageResponseMetricKeys.TotalSales,
  HomepageResponseMetricKeys.UnitsSold,
  HomepageResponseMetricKeys.DirectACoS,
  HomepageResponseMetricKeys.DirectAdSales,
  HomepageResponseMetricKeys.DirectROAS,
];

export const DIRECT_COLUMNS = [
  HomepageResponseMetricKeys.DirectACoS,
  HomepageResponseMetricKeys.DirectAdSales,
  HomepageResponseMetricKeys.DirectROAS,
];

export const DEFAULT_MAX_METRICS_VISIBLE = 5;

export const PURPLE_600 = '#4B25EA';
export const PURPLE_300 = '#D3CBFB';
export const BLUE_500 = '#0AC2FF';
export const BLUE_300 = '#99E6FF';
export const YELLOW_500 = '#FFCE1C';
export const WHITE = '#FFFFFF';
export const GREY_600 = '#556575';

export const TM_BENCHMARK_HELP_LINK =
  'https://intercom.help/flywheel-20/en/articles/8400013-understanding-benchmark-data-in-compass';

export const ACTIVE_TM_INDEX_METRICS = [
  HomepageResponseMetricKeys.Roas,
  HomepageResponseMetricKeys.Tacos,
  HomepageResponseMetricKeys.CPC,
  HomepageResponseMetricKeys.ACoSTotal,
];
