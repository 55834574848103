import {
  ButtonState,
  Icon,
  IconSize,
  Modal,
  TrashIcon,
} from '@teikametrics/tm-design-system';
import { useIntl } from 'react-intl';
import I18nKey from '../../../lib/types/I18nKey';
import { useState } from 'react';

interface DeleteGroupsConfirmationModalProps {
  readonly onClose: () => void;
  readonly onApplyClick: () => void;
}

export const DeleteGroupsConfirmationModal: React.FC<
  DeleteGroupsConfirmationModalProps
> = ({ onClose, onApplyClick }) => {
  const intl = useIntl();
  const [primaryButtonState, setPrimaryButtonState] = useState<ButtonState>(
    ButtonState.Enabled
  );

  const onMainButtonClick = () => {
    setPrimaryButtonState(ButtonState.Loading);
    onApplyClick();
  };

  return (
    <Modal
      icon={
        <Icon
          svg={TrashIcon}
          size={IconSize.Large}
          className="text-purple-500"
        />
      }
      showModal={true}
      header={intl.formatMessage({
        id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_MODAL_HEADING,
      })}
      body={intl.formatMessage({
        id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_MODAL_DESCRIPTION,
      })}
      mainAction={{
        label: I18nKey.GENERAL_YES,
        onClick: onMainButtonClick,
        state: primaryButtonState,
      }}
      secondaryAction={{
        label: I18nKey.GENERAL_NO,
        onClick: onClose,
      }}
      dataTestId="delete_groups_modal"
    />
  );
};
