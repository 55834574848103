import { Money } from '@teikametrics/tm-design-system';
import { SelectedTrendsProduct } from '../../modules/products/containers/skuCatalog';
import { PaginatedResponse } from '../clients/types';
import { CurrencyCode } from '../utilities/currency';
import { AdType } from './AOSharedTypes';
import { MerchantCountry, MerchantType } from './Fam';
import { TargetSegments } from './KeywordAction';
import { MoneyWithAmountInString } from './Money';
import { SKUApiClient } from '../../lib/clients/SKUApiClient';

export interface DataAvailabilityResponse {
  readonly earliestAvailableDate: string;
  readonly latestAvailableDate: string;
  readonly lastSyncedAt: string;
  readonly syncPerMerchantIds: SyncPerMerchantId[];
}

export interface SyncPerMerchantId {
  readonly merchantCountryId: string;
  readonly earliestAvailableDate: string;
  readonly latestAvailableDate: string;
  readonly lastSyncedAt: string;
}

export interface MerchantCountrySalesChannel {
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
}

export interface SkuBaseRequest {
  readonly merchantCountries: MerchantCountrySalesChannel[];
  readonly productFields: string[];
  readonly profitabilityFields: string[];
  readonly platformFeesFields: string[];
  readonly advertisementFields: string[];
  readonly inventoryFields: string[];
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: CurrencyCode;
  readonly previousPeriodIncluded: boolean;
}

export interface SkuCatalogDetailsRequest extends SkuBaseRequest {
  readonly skuDetailsFields: string[];
}

export interface SkuCatalogCogsTemplateRequest {
  readonly merchantCountryIds: string[];
  readonly startDate?: string;
  readonly endDate?: string;
  readonly currency?: CurrencyCode;
  readonly requestId?: string;
  readonly isOnlyNullCogs?: boolean;
  readonly isOnlyActiveSkus?: boolean;
}

export interface SkuCatalogExportRequest
  extends Omit<SkuCatalogDetailsRequest, 'currency'> {}

export interface SkuCatalogSummaryRequest extends SkuBaseRequest {}

export enum MetricsPeriodType {
  Current = 'current',
  Previous = 'previous',
}

export type SkuCatalogDataV3 = {
  readonly teikaGroupId: string;
  readonly teikaGroupName: string;
  readonly skuCount: number;
  readonly advertisedSkuCount: number;
  readonly salesChannels: string[];
  readonly imageUrl: string;
  readonly productsCount: number;
  readonly currentMetrics: SkuCatalogMetrics;
  readonly previousMetrics?: SkuCatalogMetrics;
  readonly productGroups: SkuCatalogData[];
  readonly subRows?: SkuCatalogData[];
};

export type SkuCatalogData = {
  readonly productGroupId: string;
  readonly teikaGroupId?: string;
  readonly merchantCountryId?: string;
  readonly salesChannels?: string[];
  readonly skuDetailsFields: SkuDetailsFields;
  readonly currentMetrics: SkuCatalogMetrics;
  readonly previousMetrics?: SkuCatalogMetrics;
  readonly subRows?: SkuCatalogData[];
  readonly skuChildren?: PaginatedResponse<SkuCatalogData>;
  readonly parentSku?: string;
  readonly parentAsin?: string;
  readonly salesChannelId?: string;
  readonly productCatalogId?: string;
  readonly rowId?: string;
  readonly isSingleParent?: boolean;
  readonly associatedCampaignIds?: string[];
};

export interface SkuCatalogMetrics {
  readonly productFields: ProductFields;
  readonly profitabilityFields: ProfitabilityFields;
  readonly platformFeesFields: PlaformFeesFields;
  readonly advertisementFields: AdvertisementFields;
  readonly inventoryFields: InventoryFields;
}

export interface SkuDetailsFields {
  readonly sku: string;
  readonly name: string;
  readonly imageUrl?: string;
  readonly extItemDetails?: ExtItemDetails[];
  readonly extItemDetail?: ExtItemDetails;
  readonly extItemId?: string;
  readonly productCogs?: CogsDetailsFields;
  readonly skuCount?: number;
  readonly advertisedSkuCount?: number;
  readonly parentSku?: string;
  readonly parentAsin?: string;
  readonly variationType?: string;
  readonly status?: string;
  readonly productCategory?: string;
  readonly categoryPath?: string;
  readonly primaryVariant?: boolean;
  readonly productId?: string;
  readonly publishStatus?: string;
  readonly spEligble?: boolean;
  readonly salesChannelId?: string;
  readonly productCatalogId?: string;
  readonly parentProductCatalogId?: string;
  readonly activeCampaignsCount?: number;
  readonly hasActiveCampaigns?: boolean;
}

interface CogsDetailsFields {
  readonly cogs?: MoneyWithAmountInString;
  readonly lastUpdatedAt?: EpochTimeStamp;
  readonly productCatalogId?: string;
}

export interface ExtItemDetails {
  readonly extItemId: string;
  readonly extItemType: string;
  readonly salesChannelId: string;
  readonly salesChannelName: string;
  readonly extParentItemId?: string;
}

export interface ProductFields {
  readonly tacos?: number;
  readonly averageOrderPrice?: MoneyWithAmountInString;
  readonly category?: string;
  readonly status?: string;
  readonly categoryPath?: string;
}

export interface ProfitabilityFields {
  readonly totalSales?: MoneyWithAmountInString;
  readonly estimatedGrossProfit?: MoneyWithAmountInString;
  readonly unitsSold?: number;
  readonly estimatedGrossMargin?: number;
  readonly estimatedPreAdGrossMargin?: number;

  readonly shippedCogs?: MoneyWithAmountInString;
  readonly shippedUnits?: number;
}

export interface PlaformFeesFields {
  readonly cogs?: MoneyWithAmountInString;
  readonly unitCogs?: MoneyWithAmountInString;
  readonly estimatedFees?: MoneyWithAmountInString;
  readonly aggregatedCogsStatus?: AggregatedCogsStatus;
}

export enum AggregatedCogsStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
}

export interface AdvertisementFields {
  readonly adSales?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly costPerClick?: MoneyWithAmountInString;
  readonly acos?: number;
  readonly conversionRate?: number;
  readonly campaignCount?: number;
  readonly adGroupCount?: number;
  readonly roas?: number;
  readonly adConversions?: number;
  readonly adUnitsSold?: number;
  readonly impressions?: number;
  readonly clicks?: number;
  readonly clickThroughRate?: number;
  readonly shippedUnits?: number;
  readonly shippedCogs?: MoneyWithAmountInString;
}

export interface InventoryFields {
  readonly inventoryValue?: MoneyWithAmountInString;
  readonly inventoryQuantity?: number;
  readonly sellThroughRate?: number;
}

export type SkuCatalogSummary = SkuCatalogMetrics &
  Readonly<{
    totalSkuCount?: number;
    totalParentSkuCount?: number;
    totalChildSkuCount?: number;
    advertisedSkuCount?: number;
  }>;

export type SkuOverviewData = {
  readonly productCatalogId: string;
  readonly productGroupId: string;
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly currentMetrics: SkuOverviewMetrics;
  readonly previousMetrics?: SkuOverviewMetrics;
};

export type SkuOverviewMetrics = Pick<
  SkuCatalogMetrics,
  | 'advertisementFields'
  | 'inventoryFields'
  | 'platformFeesFields'
  | 'productFields'
  | 'profitabilityFields'
>;

export type SkuOverviewSummary = SkuOverviewMetrics;

export interface SkuOverviewBaseRequest {
  readonly merchantCountries: MerchantCountrySalesChannel[];
  readonly skuDetailsFields: string[];
  readonly productFields: string[];
  readonly profitabilityFields: string[];
  readonly platformFeesFields: string[];
  readonly advertisementFields: string[];
  readonly inventoryFields: string[];
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: string;
}

export interface SkuOverviewRequest extends SkuOverviewBaseRequest {
  readonly previousPeriodIncluded: boolean;
}

export interface SkuOverviewSummaryRequest extends SkuOverviewRequest {}

export interface ProductWithMerchantInfo {
  readonly salesChannelName: string;
  readonly merchantCountryName: string;
  readonly merchantCountryId: string;
  readonly productCatalogId: string;
  readonly productCategory?: string;
  readonly extItemId: string;
  readonly extItemType: string;
}

export interface ProductDetailsSummaryResponse {
  readonly name: string;
  readonly imageUrl: string;
  readonly sku: string;
  readonly elements?: ProductWithMerchantInfo[];
}

export interface SKUDetailsFieldsV2 {
  readonly sku: string;
  readonly name: string;
  readonly imageUrl: string;
  readonly extItemId: string;
  readonly extParentItemId?: string;
  readonly extItemIdType: string;
}
export interface ProductDetailsSummaryResponseV2 {
  readonly productCatalogId: string;
  readonly merchantCountryId: string;
  readonly variationType?: string;
  readonly skuDetailsFields?: SKUDetailsFieldsV2;
}

export enum SKUConnectionBanner {
  Evaluating = 'Evaluating',
  NoConnection = 'NoConnection',
  ConnectionInProgress = 'ConnectionInProgress',
  ConnectionSuccessful = 'ConnectionSuccessful',
}

export interface ProductGroupIdsResponse {
  readonly elements: SkuProductGroupId[];
}
export interface SkuProductGroupId {
  readonly sku: string;
  readonly productGroupId: string;
}
export interface ManageCogsSecureUrlResponse {
  readonly url: string;
}

export interface MissingCogsDetails {
  readonly merchantCountryId: string;
  readonly missingCogs: number;
  readonly records: number;
}

export interface MissingCogsResponse {
  readonly missingCogs: boolean;
  readonly missingCogsDetails: MissingCogsDetails[];
  readonly totalMissingCogs: number;
  readonly totalRecords: number;
}

export interface ProductMetadataUploadResponse {
  readonly requestId: string;
  readonly statusApiUrl: string;
}

export interface ProductMetadataUploadStatusResponse {
  readonly status: string;
}

export enum MetadataType {
  PRODUCT = 'Product',
  ADS = 'Ads',
  BUDGET = 'Budget',
  DSP = 'Dsp',
}

export enum APIType {
  SKU_CATALOG_PUBLIC = 'SKU_CATALOG_PUBLIC',
  SKU_CATALOG_INTERNAL = 'SKU_CATALOG_INTERNAL',
  SKU_DETAILS_PUBLIC = 'SKU_DETAILS_PUBLIC',
  SKU_DETAILS_INTERNAL = 'SKU_DETAILS_INTERNAL',
  SKU_SUMMARY_PUBLIC = 'SKU_SUMMARY_PUBLIC',
  SKU_SUMMARY_INTERNAL = 'SKU_SUMMARY_INTERNAL',
  SKU_CATALOG_HIERARCHY_PUBLIC = 'SKU_CATALOG_HIERARCHY_PUBLIC',
}

export interface TotalCountRequest {
  readonly merchantCountries: MerchantCountrySalesChannel[];
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: CurrencyCode;
  readonly apiType: APIType;
}

export interface TotalCountResponse {
  readonly fullCount: number;
}

export interface SKUCatalogExportResponse {
  readonly requestId: string;
  readonly statusUrl: string;
}

export interface SKUCatalogExportStatusResponse {
  readonly status: string;
  readonly s3FilePath: string;
}

export interface SKUCatalogExportDownloadUrlResponse {
  readonly s3DownloadUrl: string;
}

export interface SKUManageCogsStatusResponse {
  readonly status: string;
  readonly s3DownloadUrl?: string;
}

export interface SKUManageCogsResponse {
  readonly requestId: string;
  readonly statusApiUrl: string;
}

export enum AggregationPeriodType {
  Current = 'current',
  Previous = 'previous',
}

export interface DataPoint {
  readonly date: string;
  readonly totalValue: number;
}

export enum SKUPerformanceMetrics {
  TotalSales = 'totalSales',
  UnitsSold = 'unitsSold',
  Roas = 'roas',
  EstimatedGrossMargin = 'estimatedGrossMargin',
  EstimatedPreAdGrossMargin = 'estimatedPreAdGrossMargin',
}

export enum GraphDataPointType {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export interface GraphDataPoints {
  readonly [GraphDataPointType.Daily]: DataPoint[];
  readonly [GraphDataPointType.Monthly]: DataPoint[];
  readonly [GraphDataPointType.Weekly]: DataPoint[];
}

export interface PerformanceMetricData {
  readonly totalValue: number;
  readonly graphDataPoints: GraphDataPoints;
}

export interface SKUPerformanceMetricsResponse {
  readonly [key: string]:
    | PerformanceMetricData
    | CurrencyCode
    | AggregationPeriodType;
  readonly aggregationPeriodType: AggregationPeriodType;
  readonly currency: CurrencyCode;
  readonly totalSales: PerformanceMetricData;
  readonly unitsSold: PerformanceMetricData;
  readonly roas: PerformanceMetricData;
  readonly estimatedGrossMargin: PerformanceMetricData;
  readonly estimatedPreAdGrossMargin: PerformanceMetricData;
}

export interface SKUPerformanceMetricsRequest {
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: CurrencyCode;
  readonly merchantCountryId: string;
  readonly metricsPeriodType: AggregationPeriodType;
  readonly performanceFields: string[];
}

export type SkuDetailsV2Data = {
  readonly productGroupId: string;
  readonly merchantCountryId?: string;
  readonly skuDetailsFields: SkuDetailsFields;
  readonly currentMetrics: SkuDetailsV2Metrics;
  readonly previousMetrics?: SkuDetailsV2Metrics;
  readonly productCatalogId?: string;
};

export interface SkuDetailsV2Metrics {
  readonly profitabilityFields: ProfitabilityFields;
}

export type SkuDetailsV2Summary = {
  readonly currentMetrics: SkuDetailsV2Metrics;
  readonly previousMetrics: SkuDetailsV2Metrics;
  readonly childCount?: number;
};

export type SkuDetailsV2TableExtraProps = {
  readonly showPreviousData: boolean;
  readonly skuId?: string;
  readonly asinId?: string;
  readonly onViewTrendsSlideoverClick?: (
    product: SelectedTrendsProduct
  ) => void;
};

export interface SkuCatalogGmroiRequest {
  readonly merchantCountryId: string;
  readonly productCatalogIds: string[];
}

export interface SkuCatalogGmroi {
  readonly productCatalogId: string;
  readonly daysInInventory: number;
  readonly gmroi: number;
}

export interface SkuCatalogGmroiResponse {
  readonly skuCatalogGmroiList: SkuCatalogGmroi[];
}

export type ProductSettingsTableExtraProps = {
  readonly skuId?: string;
  readonly asinId?: string;
  readonly isFlyoverTable?: boolean;
};

interface SkuEventCommonParams {
  readonly eventId?: string;
  readonly category: string;
  readonly eventType: string;
  readonly sku: string;
  readonly externalId: string;
  readonly eventDate: string;
  readonly name?: string;
  readonly description?: string;
  readonly eventTypesViewNames?: string[];
  readonly viewName?: string;
}

export interface SkuDetailsEventResponse extends SkuEventCommonParams {
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly updatedBy: string;
  readonly createdBy: string;
  readonly ingestionType: string;
  readonly oldValue?: number;
  readonly newValue?: number;
  readonly isDummyEvent?: boolean;
  readonly productCatalogId?: string;
}

export interface SkuEventRequest extends SkuEventCommonParams {
  readonly userId: string;
  readonly userName: string;
  readonly oldValue?: number;
  readonly newValue?: number;
}

export type SkuDetailsEvents = SkuDetailsEventResponse[];

export enum EventLogRequestFilterKeys {
  StartDate = 'startDate',
  EndDate = 'endDate',
  EventType = 'eventType',
  LastEvaluatedKey = 'lastEvaluatedKey',
  Category = 'category',
  LastEventDate = 'lastEventDate',
  ConsiderSeasonalEvents = 'considerSeasonalEvents',
  ConsiderPriceUpdateEvents = 'considerPriceUpdateEvents',
  ConsiderOutOfStockEvents = 'considerOutOfStockEvents',
}

export enum EventLogCategoryNames {
  Inventory = 'inventory',
  Content = 'Content',
  SeasonalEvent = 'seasonal_event',
  ProductCatalog = 'product_catalog',
}

export interface SkuDetailsEventsResponse {
  readonly elements: SkuDetailsEvents;
  readonly filteredElements: number;
  readonly [EventLogRequestFilterKeys.LastEvaluatedKey]?: string;
  readonly [EventLogRequestFilterKeys.LastEventDate]?: string;
  readonly errorOccured?: boolean;
}

export interface EventLogExportRequest {
  readonly channel: string;
  readonly productName: string;
  readonly externalId: string;
  readonly merchant: string;
  readonly sku: string;
  readonly childParameters?: EventLogExportChildParams[];
  readonly localTimeZone?: string;
}

export interface EventLogExportChildParams {
  readonly productName: string;
  readonly externalId: string;
  readonly sku: string;
  readonly productCatalogId: string;
}

export interface ExistingSkuGroups {
  readonly teikaGroupId: string;
  readonly accountId?: string;
  readonly teikaGroupName: string;
  readonly groupType?: string;
  readonly reasonCode?: string;
}

export interface ExistingSkuGroupResopnse {
  readonly elements: ExistingSkuGroups[];
  readonly filteredElements?: number;
}

export interface CustomGroupNameUpdateRequest {
  readonly teikaGroupId: string;
  readonly teikaGroupName: string;
  readonly merchantCountries: string[];
  readonly productCatalogIds: string[];
}

export type GroupCatalogsMap = {
  [teikaGroupId: string]: string[];
};

export enum SkuViewType {
  ParantChild = 'ParantChild',
  MultiChannel = 'MultiChannel',
  IndividualSKU = 'IndividualSKU',
}

export interface RelatedCampaignAndAdGroupRequest {
  readonly productName: string;
  readonly sku: string;
  readonly asin: string;
  readonly parentSku?: string;
  readonly parentAsin?: string;
  readonly productCatalogId: string;
  readonly merchantCountryId: string;
  readonly associatedCampaignIds: string[];
  readonly skus?: string[];
}

export interface RelatedCampaignAndAdGroupResponse {
  readonly productName: string;
  readonly productCatalogId: string;
  readonly sku: string;
  readonly asin: string;
  readonly parentSku: string;
  readonly parentAsin: string;
  readonly associatedCampaigns: AssociatedCampaigns[];
}

export interface AssociatedCampaigns {
  readonly campaignId: string;
  readonly campaignName: string;
  readonly advertisingType: AdType;
  readonly adGroupId: string;
  readonly adGroupName: string;
  readonly targetingType: string;
  readonly targetSegments: TargetSegments[];
}

export interface EventTypeConfiguration {
  name: string;
  viewName: string;
}

export interface EventLogCategoryConfiguration {
  name: string;
  viewName: string;
  eventTypeConfiguration: EventTypeConfiguration[];
}
export interface EventLogConfig {
  eventLogCategoryConfiguration: EventLogCategoryConfiguration[];
}

export interface EventLogDataRequestBody {
  readonly pcidToLastEvaluatedKeyMap: { [childPcIds: string]: string };
}

export type OutOfStockProductsData = {
  readonly productGroupId: string;
  readonly productCatalogId: string;
  readonly parentProductCatalogId?: string;
  readonly skuDetailsFields: SkuDetailsFields;
  readonly performance: OutOfStockPerformanceMetrics;
  readonly inventoryFields: OutOfStockInventoryFieldsMetrics;
  readonly advertisementFields: OutOfStockAdvertisementFieldsMetrics;
  readonly merchantCountryId?: string;
};

export interface OutOfStockPerformanceMetrics {
  readonly estimatedMissedSales: Money;
  readonly unitsSold: number;
  readonly acos: number;
  readonly tacos: number;
  readonly sellThroughRate: number;
  readonly totalAdSales: number;
  readonly totalAdSpend: number;
  readonly totalSales: number;
  readonly lastSalePrice: number;
  readonly rateOfSale: number;
  readonly percentageAdSpend: number;
  readonly percentageAdSales: number;
  readonly percentageTotalSales: number;
  readonly adSpendPerUnit: number;
}

export interface OutOfStockInventoryFieldsMetrics {
  readonly outOfStockDate?: string;
  readonly healthyInventoryLevel?: number;
  readonly sellThroughRate?: number;
}

export interface OutOfStockAdvertisementFieldsMetrics {
  readonly campaignCount: boolean;
  readonly associatedCampaignIds: string[];
}

export interface OutOfStockProductsResponse {
  readonly filteredElements: number;
  readonly elements: OutOfStockProductsData[];
}

export interface OutOfStockProductsRequest {
  readonly merchantCountries?: OutOfStockMerchantCountries[];
  readonly currencyCode: string;
}

export interface InventoryDashboardOOSExportRequest {
  readonly merchantCountryDetails?: OutOfStockMerchantCountries[];
  readonly currency: string;
}

export interface InventoryDashboardOOSRequest {
  readonly merchantCountryDetails?: OutOfStockMerchantCountries[];
  readonly currency: string;
  readonly startDate: string;
  readonly endDate: string;
}

export interface OutOfStockMerchantCountries {
  readonly merchantCountryId?: string;
  readonly salesChannelId?: string;
  readonly merchantType?: string;
  readonly country?: string;
  readonly merchantName?: string;
  readonly extMerchantId?: string;
}

export interface OutOfStockProductMetrics {
  readonly outOfStockDate?: string;
  readonly estMissedSales?: MoneyWithAmountInString;
  readonly unitsSold?: number;
  readonly acosTotal?: number;
  readonly tacos?: number;
  readonly campaigns?: number;
  readonly sellThroughRate?: number;
  readonly healthyInventoryLevel?: number;
}

export interface ProductInsightsRequestBodyMC {
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
}

export interface ProductInsightsRequestBody {
  readonly merchantCountries: ProductInsightsRequestBodyMC[];
}

interface StockResponseInsights {
  readonly inStock: number;
  readonly lowStock: number;
  readonly outOfStock: number;
}

export interface ActiveStockResponse {
  readonly totalActiveProducts: number;
  readonly insights: StockResponseInsights;
}

interface InventoryValueResponseInsights {
  readonly availableToSell?: Money;
  readonly inbound?: Money;
  readonly reserved?: Money;
  readonly unfulfillable?: Money;
}

export interface InventoryValueDetailsResponse {
  readonly totalInventoryValue: Money;
  readonly insights: InventoryValueResponseInsights;
}

interface OutOfStockSummaryPerformanceResponse {
  readonly estimatedMissedSales?: MoneyWithAmountInString;
}
export interface OutOfStockProductSummaryDetails {
  readonly isDataLoading?: boolean;
  readonly productCatalogId: string;
  readonly merchantCountryId: string;
  readonly skuDetailsFields: SkuDetailsFields;
  readonly performance: OutOfStockSummaryPerformanceResponse;
}
export interface OutOfStockProductsSummaryResponse {
  readonly performanceAggregate: OutOfStockSummaryPerformanceResponse;
  readonly products: OutOfStockProductSummaryDetails[];
}

interface MissedSalesInfoResponse {
  readonly date: string;
  readonly totalSales: MoneyWithAmountInString;
}

export interface EstimatedMissedSalesGraphPointsResponse {
  readonly aggregatedEstimatedMissedSales?: MoneyWithAmountInString;
  readonly dataPoints: MissedSalesInfoResponse[];
}

export interface CustomInsightsProductDetails {
  readonly isDataLoading?: boolean;
  readonly productCatalogId: string;
  readonly merchantCountryId: string;
  readonly skuDetailsFields: SkuDetailsFields;
  readonly performance: string;
}

export interface CustomInsightsProductDetailsResponse {
  readonly customInsightSubscriptionId: string;
  readonly products: CustomInsightsProductDetails[];
}

export interface GetCustomInsightsResponse {
  readonly id: string;
  readonly insightTypeGroup: string;
  readonly customInsightName: string;
  readonly accountId: string;
  readonly metricCriteria: CustomInsightMetricAndMetaDataCriteria[];
  readonly metadataCriteria: CustomInsightMetricAndMetaDataCriteria[];
  readonly subscriptionType: string;
  readonly interval: number;
  readonly alertType: string[];
  readonly isSubscribed: boolean;
  readonly createdBy: string;
  readonly updatedBy: string;
  readonly description: string;
  readonly isAppAlert: boolean;
  readonly createdAt: string;
  readonly updatedAt: string;
}

export interface CreateInsightsPayload {
  readonly customInsightName: string;
  readonly insightTypeGroup: string;
  readonly description?: string;
  readonly interval: string;
  readonly subscriptionType?: string;
  readonly metricCriteria: CustomInsightMetricAndMetaDataCriteria[];
  readonly metadataCriteria: CustomInsightMetricAndMetaDataCriteria[];
  readonly isSubscribed: boolean;
  readonly isAppAlert: boolean;
  readonly alertType: string[];
  readonly userName?: string;
}

export interface CustomInsightMetricAndMetaDataCriteria {
  readonly operand: string;
  readonly operator: string;
  readonly value: string;
  readonly group: number;
}

export interface CreateInsightsData {
  readonly customInsightSubscriptionId: string;
  readonly customInsightName: string;
}
export enum CustomInsightAlertType {
  InApp = 'app',
  Email = 'email',
}

export interface CustomInsightProductDetails {
  readonly imageUrl: string;
  readonly sku: string;
  readonly externalItemId: string;
  readonly externalItemType: string;
  readonly name: string;
  readonly salesChannelId: string;
  readonly merchantCountryId: string;
  readonly productCatalogId: string;
}

export interface CustomInsightsProductMetric {
  readonly metricDetails?: {
    readonly availableQuantity?: number;
    readonly sales?: number;
    readonly unitsSold?: number;
    readonly rate_of_sale?: string;
    readonly ad_sales?: number;
    readonly acos?: number;
    readonly tacos?: number;
    readonly est_gross_margin?: number;
    readonly clicks?: number;
  };
}

export interface CustomInsightsPreviousProductMetric {
  readonly metricDetails?: {
    readonly priorSales?: number;
    readonly priorUnitsSold?: number;
    readonly prior_rate_of_sale?: string;
    readonly prior_ad_sales?: number;
    readonly prior_acos?: number;
    readonly prior_tacos?: number;
    readonly prior_est_gross_margin?: number;
    readonly prior_clicks?: number;
  };
}

export interface CustomInsightProductDetails {
  readonly imageUrl: string;
  readonly sku: string;
  readonly externalItemId: string;
  readonly externalItemType: string;
  readonly name: string;
  readonly salesChannelId: string;
  readonly merchantCountryId: string;
  readonly productCatalogId: string;
  readonly inventoryQuantity?: number;
  readonly healthyInventoryLevel?: number;
  readonly sellThroughRate?: number;
  readonly parentProductCatalogId?: string;
  readonly campaignCount?: number;
  readonly isActiveCampaigns?: boolean;
}

export interface CustomInsightMetricValues {
  readonly days_of_inventory?: string;
  readonly rate_of_sale_change_percent?: string;
  readonly rate_of_sale_change_value?: string;
  readonly rate_of_sale_cpd?: string;
}

export type CustomInsightsProductsData = {
  readonly currentMetrics: CustomInsightsProductMetric;
  readonly previousMetrics?: CustomInsightsPreviousProductMetric;
  readonly customInsightProductDetails: CustomInsightProductDetails;
  readonly metricValue: number;
  readonly metricValues?: CustomInsightMetricValues;
};

export interface MetricCriteria {
  readonly operand: string;
  readonly operator: string;
  readonly value: string;
  readonly group: number;
}

export enum SelectMetricsType {
  RATE_OF_SALE = 'rate_of_sale',
  REORDER = 'reorder',
  STOCK_OUTS = 'stockout',
}

export interface CustomInsightsProductsRequest {
  readonly interval?: string;
  readonly metricCriteria?: MetricCriteria[];
  readonly metadataCriteria?: MetricCriteria[];
  readonly merchantCountryIds: string[];
  readonly currency: string;
  readonly groupType?: SelectMetricsType;
}

export interface CustomInsightSubscriptionDetails {
  readonly id: string;
  readonly insightTypeGroup?: SelectMetricsType;
  readonly customInsightName: string;
  readonly accountId?: string;
  readonly metricCriteria?: MetricCriteria[];
  readonly metadataCriteria?: MetricCriteria[];
  readonly subscriptionType?: string;
  readonly interval?: string;
  readonly alertType?: string[];
  readonly isSubscribed: boolean;
  readonly createdBy?: string;
  readonly createdByUserName?: string;
  readonly updatedBy?: string;
  readonly updatedAt?: string;
  readonly description?: string;
  readonly isAppAlert?: boolean;
  readonly userName?: string;
  readonly updatedByUserName?: string;
}

export interface CustomInsightsProductsDataResponse {
  readonly customInsightSubscriptionId: string;
  readonly elements: CustomInsightsProductsData[];
  readonly filteredElements: number;
  readonly totalElements: number;
  readonly lastUpdatedAt: string;
  readonly restFailed?: boolean;
}

export interface CustomInsightProductsForDashboardRequest {
  readonly merchantCountryIds: string[];
  readonly currency: string;
  readonly homePageRequest: boolean;
}

export interface ExportInsightsPayload {
  readonly currency: string;
  readonly merchantCountryIdMap: Record<string, string>;
}

export interface DemandForecastRequest {
  readonly merchantCountryIds: string[];
}

export interface UnitProjectedToSell {
  readonly next30Days: number;
  readonly next60Days: number;
  readonly next90Days: number;
  readonly next180Days: number;
  readonly next270Days: number;
  readonly next365Days: number;
}

export interface ForecaseInsights {
  readonly daysOfInventory: number;
  readonly currentInventoryLevel: number;
  readonly flyWheelRateOfSale: number;
  readonly flywheelUnitsSold: number;
}
export interface DemandForecastData extends Record<string, unknown> {
  readonly merchantCountryId: string;
  readonly forecastSkuDetailsFields: SkuDetailsFields;
  readonly forecastInsight: ForecaseInsights;
  readonly unitProjectedToSell?: UnitProjectedToSell;
}

export interface DemandForecastTableData extends Record<string, unknown> {
  readonly onLearnMoreClick?: () => void;
  readonly onViewTrendsSlideoverClick?: (
    product: SelectedTrendsProduct
  ) => void;
  readonly skuApiClient?: SKUApiClient;
}

export interface UnsoldInventoryProductsData {
  readonly productCatalogId?: string;
  readonly merchantCountryId: string;
  readonly skuDetailsFields: SkuDetailsFields;
  readonly performance: InventoryPerformanceMetrics;
}

export interface UnsoldInventorySummaryProductsData {
  readonly productCatalogId?: string;
  readonly imageUrl?: string;
  readonly name?: string;
  readonly inventoryValue?: Money;
  readonly inventoryQuantity?: number;
}

export interface UnsoldInventoryDetailsData extends Record<string, unknown> {
  readonly productGroupId: string;
  readonly productCatalogId: string;
  readonly parentProductCatalogId?: string;
  readonly skuDetailsFields: SkuDetailsFields;
  readonly performance: InventoryPerformanceMetrics;
  readonly merchantCountryId?: string;
}

export interface UnsoldInventorySummaryResponse {
  readonly performanceAggregate: {
    readonly currentValue?: Money;
    readonly previousValue?: Money;
  };
  readonly products: UnsoldInventorySummaryProductsData[];
  readonly currency: string;
}

export interface UnsoldInventoryPreviousSummaryResponse {
  readonly value?: Money;
}

export type ActiveInventoryData = {
  readonly productGroupId: string;
  readonly productCatalogId: string;
  readonly parentProductCatalogId?: string;
  readonly skuDetailsFields: SkuDetailsFields;
  readonly performance: InventoryPerformanceMetrics;
  readonly merchantCountryId?: string;
};

export interface InventoryPerformanceMetrics {
  readonly inventoryValue?: Money;
  readonly campaignCount?: boolean;
  readonly sellThroughRate?: number;
  readonly inventorySalesRatio?: number;
  readonly daysOfInventory?: number;
  readonly inventoryQuantity?: number;
  readonly rateOfSale?: number;
  readonly unitsSold?: number;
  readonly totalSales?: Money;
  readonly inventoryAge?: InventoryByAgeMetricsNew;
  readonly unitCogs?: Money;
}

export interface InventoryByAgeMetricsNew {
  readonly invAge0To90Days?: number;
  readonly invAge91To180Days?: number;
  readonly invAge181To270Days?: number;
  readonly invAge271To365Days?: number;
  readonly invAge365PlusDays?: number;
  readonly currency?: string;
}

export interface InventoryBreakdownValueMetrics {
  readonly invVal0To90Days?: number;
  readonly invVal90To180Days?: number;
  readonly invVal180To270Days?: number;
  readonly invVal271To365Days?: number;
  readonly invVal365PlusDays?: number;
  readonly currency?: string;
}

export interface InventoryDashboardRequest {
  readonly merchantCountryDetails: InventoryMerchantCountries[];
  readonly currency: string;
  readonly startDate?: string;
  readonly endDate?: string;
}

export interface InventoryMerchantCountries {
  readonly merchantCountryId?: string;
  readonly salesChannelId?: string;
  readonly merchantType?: string;
}

export interface TotalActiveInventoryValue {
  readonly inventoryValue: number;
  readonly currency: string;
  readonly lastSyncedAt?: string;
}

export interface InventoryHealthMetricSummary {
  readonly currentValue?: number;
  readonly prevValue?: number;
}

export interface InventoryHealthMetricData {
  readonly sellThroughRateSummary: InventoryHealthMetricSummary;
  readonly inventorySalesRatioSummary: InventoryHealthMetricSummary;
}

export interface OutOfStockSummaryPerformanceResponseV2 {
  readonly estimatedMissedSales?: MoneyWithAmountInString;
  readonly rateOfSale?: number;
  readonly totalSales?: MoneyWithAmountInString;
  readonly unitsSold?: number;
}
export interface OutOfStockProductSummaryDetailsV2 {
  readonly lastSalePrice: MoneyWithAmountInString;
  readonly advertisedDetails: OutOfStockAdvertisementFieldsMetrics;
  readonly productCatalogId: string;
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly skuDetailsFields: SkuDetailsFields;
  readonly performance: OutOfStockSummaryPerformanceResponseV2;
  readonly outOfStockDate: string;
}

export interface OutOfStockProductsSummaryResponseV2 {
  readonly performanceAggregate: OutOfStockSummaryPerformanceResponseV2;
  readonly products: OutOfStockProductSummaryDetailsV2[];
}

interface OOSPerformance {
  readonly estimatedMissedSales: Money | null;
  readonly rateOfSale: number | null;
  readonly totalSales: Money | null;
  readonly unitsSold: number | null;
}

export type InventoryDashboardOOSData = {
  readonly productCatalogId: string;
  readonly parentProductCatalogId: string;
  readonly outOfStockDate: string;
  readonly lastSalePrice: Money | null;
  readonly advertisedDetails: OutOfStockAdvertisementFieldsMetrics;
  readonly merchantCountryId: string;
  readonly skuDetailsFields: SkuDetailsFields;
  readonly salesChannelId: string;
  readonly performance: OOSPerformance;
};

export interface InventoryOptimizationCountsResponse {
  readonly counts: number;
  readonly value?: Money;
}

export interface MultichannelCatalogPerformanceMetric {
  readonly [key: string]: number | MoneyWithAmountInString | null | undefined;
  readonly totalSales: MoneyWithAmountInString | null;
  readonly adSales: MoneyWithAmountInString | null;
  readonly adSpend: MoneyWithAmountInString | null;
  readonly estGrossProfit: MoneyWithAmountInString | null;
  readonly tacos: number | null;
  readonly totalSalesPercentage?: number | null;
  readonly estGrossProfitPercentage?: number | null;
  readonly inventoryQuantity?: number;
  readonly inventoryQuantityPercentage?: number | null;
  readonly inventoryValue?: MoneyWithAmountInString | null;
  readonly inventoryValuePercentage?: number | null;
  readonly totalSku?: number;
  readonly totalSkuPercentage?: number | null;
  readonly adSpendPercent?: number | null;
  readonly adSalesPercent?: number | null;
  readonly tacosPercentage?: number | null;
  readonly activeSku?: number | null;
  readonly activeSkuPercentage?: number | null;
}

export interface MerchantMetric {
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly country: string | null;
  readonly merchantName: string | null;
  readonly performance: {
    readonly current: MultichannelCatalogPerformanceMetric;
    readonly previous: MultichannelCatalogPerformanceMetric;
  };
  readonly merchantType: MerchantType;
}

export interface MerchantTypeMetric {
  readonly merchantType: MerchantType;
  readonly merchantCountries: MerchantMetric[];
  readonly performance: {
    readonly current: MultichannelCatalogPerformanceMetric;
    readonly previous: MultichannelCatalogPerformanceMetric;
  };
}

export interface ChannelMetric {
  readonly salesChannelId: string;
  readonly salesChannelName: string;
  readonly merchantTypeMetrics: MerchantTypeMetric[];
  readonly performance: {
    readonly current: MultichannelCatalogPerformanceMetric;
    readonly previous: MultichannelCatalogPerformanceMetric;
  };
}

export interface MultichannelCatalogResponse {
  readonly currency: string;
  readonly summaryMetrics: {
    readonly current: MultichannelCatalogPerformanceMetric;
    readonly previous: MultichannelCatalogPerformanceMetric;
  };
  readonly channelMetrics: ChannelMetric[];
}

export type MultichannelCatalogRowData = {
  readonly salesChannelID?: string;
  readonly merchantType?: MerchantType;
  readonly salesChannelName?: string;
  readonly merchantCountryName?: string;
  readonly merchantName?: string;
  readonly merchantCountryID?: string;
  readonly performance?: {
    readonly current: MultichannelCatalogPerformanceMetric;
    readonly previous: MultichannelCatalogPerformanceMetric;
  };
  readonly subRows?: MultichannelCatalogRowData[];
};

export interface MultichannelCatalogPerformanceDataRequest {
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: string;
  readonly merchantCountries: MerchantCountry[];
  readonly previousPeriodIncluded: boolean;
}

export interface SlideoutProductDetailsRequest {
  readonly salesChannelId: string;
  readonly sku: string;
  readonly merchantCountryId: string;
}

export interface SlideoutProductGraphDataRequest {
  readonly salesChannelId: string;
  readonly productCatalogId: string;
  readonly merchantCountryId: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: string;
  readonly metricsPeriodType: MetricsPeriodType;
}

export interface SlideoutProductDetails {
  readonly salesChannelId: string;
  readonly productCatalogId: string;
  readonly merchantCountryId: string;
  readonly skuParentDetails: SkuDetailsFields;
  readonly skuChildren?: SkuDetailsFields[];
}

export enum SlideoutProductMetrics {
  AdSales = 'adSales',
  TotalSales = 'totalSales',
  UnitsSold = 'unitSold',
  AdUnitsSold = 'adUnitsSold',
  AdSpend = 'adSpend',
  Clicks = 'clicks',
  ROAS = 'roas',
  TACoS = 'tacos',
  ACoS = 'acos',
  AggregateCOGS = 'aggregatedCogs',
  EstGrossProfit = 'estimatedGrossProfit',
  InventoryAvailableQuantity = 'inventoryQuantity',
  DaysOfInventory = 'daysOfInventory',
  SalesRatio = 'salesRatio',
  SellThroughRate = 'sellThroughRate',
}

export interface SlideoutProductsHeroMetricsData {
  readonly [key: string]: string | Money | number | null;
  readonly adSales: Money | null;
  readonly adSpend: Money | null;
  readonly totalSales: Money | null;
  readonly aggregatedCogs: Money | null;
  readonly estimatedGrossProfit: Money | null;
  readonly inventoryQuantity: number | null;
  readonly daysOfInventory: number | null;
  readonly clicks: number | null;
  readonly roas: number | null;
  readonly acos: number | null;
  readonly tacos: number | null;
  readonly unitsSold: number | null;
  readonly adUnitsSold: number | null;
}

export type SlideoutProductsGraphItem = SlideoutProductsHeroMetricsData & {
  readonly date: string;
};
export interface SlideoutProductsGraphDataResponse {
  readonly merchantCountryId: string;
  readonly currency: string;
  readonly aggregationPeriodType: MetricsPeriodType;
  readonly daily: SlideoutProductsGraphItem[];
  readonly weekly: SlideoutProductsGraphItem[];
}
