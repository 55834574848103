import {
  BadgeCampaignGoal,
  BadgeCampaignType,
  Button,
  ButtonSize,
  ButtonVariant,
  CampaignAdGroup,
  CampaignAdGroupVariant,
  ColumnAlign,
  KebabLinearIcon,
  KeywordRoutingType,
  Tooltip,
  createMultiSelectDataFieldFilter,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';
import isNil from 'lodash/isNil';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  DropDownType,
  KeywordActionColumns,
  KeywordAdgroupInfo,
  KeywordRecommendationsData,
  SelectedRecommendationsV2,
  TargetSegments,
  TargetingType,
} from '../../../../../lib/types/KeywordAction';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../lib/utilities/intercom';
import { ViewTrendLink } from '../../../../advertisingOptimization/components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../../advertisingOptimization/components/ViewTrends';
import { SelectFilterOption } from '../../../../advertisingOptimization/containers/adsManager/utils/selectFilterOptions';
import { KeywordTabPaths } from '../../KeywordsDashboard2';
import { AdGroupSelectionSlideoutModalV2 } from '../../adGroupSelectionSlideoutV2';
import { NewDestinationAdGroupModal } from '../NewDestinationAdGroupModal';
import {
  DESTINATION_AD_GROUP_NAME_SORT_KEY,
  DESTINATION_AD_GROUP_TAG_SORT_KEY,
  DESTINATION_AD_GROUP_TARGETING_TYPE_SORT_KEY,
  RecommendationsTableV2ExtraProps,
} from '../types';
import { TARGET_SEGMENT_KEY_TO_BADGE_TYPE_MAPPER } from './utils';

const getAdGroup = (
  original: KeywordRecommendationsData,
  selectedRows: SelectedRecommendationsV2
) => {
  const selectedAdGroup = selectedRows?.[original.id]?.adGroup;
  return selectedAdGroup ?? original.suggestedDestination.adGroup;
};

export const Cell: React.FC<{
  original: KeywordRecommendationsData;
  meta: RecommendationsTableV2ExtraProps;
}> = ({
  original,
  meta: { handleDropdownSelection, selectedRows, merchantCountry },
}) => {
  const adGroup = getAdGroup(original, selectedRows);

  const { campaign } = adGroup;
  const intl = useIntl();

  const [isViewSkuKeywordSlideOutOpen, setIsViewSkuKeywordSlideOutOpen] =
    useState<boolean>(false);
  const [isAdgroupSelectionSlideOutOpen, setIsAdgroupSelectionSlideOutOpen] =
    useState<boolean>(false);

  const location = useLocation();

  const isNegativeKeyword = location.pathname.includes(
    KeywordTabPaths.KeywordsToNegate
  );

  const onViewSkuKeywordClick = () => {
    setIsViewSkuKeywordSlideOutOpen(true);
    hideIntercomLauncher();
  };

  const onViewSkuKeywordSlideoutClose = () => {
    setIsViewSkuKeywordSlideOutOpen(false);
    showIntercomLauncher();
  };

  const onAdgroupChange = (info: KeywordAdgroupInfo) => {
    handleDropdownSelection(original, DropDownType.CAMPAIGN, info.campaign);
    handleDropdownSelection(original, DropDownType.AD_GROUP, info);
    setIsAdgroupSelectionSlideOutOpen(false);
    setIsViewSkuKeywordSlideOutOpen(false);
    showIntercomLauncher();
  };

  return (
    <div className="flex justify-between items-center">
      <NewDestinationAdGroupModal
        isOpen={isViewSkuKeywordSlideOutOpen}
        onClose={onViewSkuKeywordSlideoutClose}
        keyword={original.keyword.text ?? ''}
        merchantCountryId={merchantCountry.merchantCountryId}
        defaultAdGroup={adGroup}
        isNegativeKeyword={isNegativeKeyword}
        onApplyChange={onAdgroupChange}
      />

      {isAdgroupSelectionSlideOutOpen && (
        <>
          <AdGroupSelectionSlideoutModalV2
            merchantCountryId={merchantCountry.merchantCountryId}
            isOpen={isAdgroupSelectionSlideOutOpen}
            onClose={() => setIsAdgroupSelectionSlideOutOpen(false)}
            heading={intl.formatMessage({
              id: I18nKey.KEYWORD_RECOMMENDATIONS_AD_GROUP_SELECTION_COLUMN_PLACEHOLDER,
            })}
            onApplyChange={onAdgroupChange}
            defaultAdGroup={adGroup}
            isNegativeKeyword={isNegativeKeyword ? isNegativeKeyword : false}
          />
        </>
      )}
      <div className="flex">
        <CampaignAdGroup
          adGroupName={adGroup.name ?? ''}
          campaignGoal={getCampaignGoal(adGroup.campaign?.createdBy)}
          campaignName={adGroup?.campaign?.name ?? ''}
          campaignType={getCampaignType(adGroup?.campaign?.targetingType)}
          keywordRoutingTypes={getKeywordTargetingTypes(adGroup.targetSegments)}
          variant={CampaignAdGroupVariant.View}
          hidePadding
        />
      </div>
      <div className="flex items-center self-stretch">
        <ViewTrendLink
          dataTestId="keyword_recommendations_ad_group_name"
          adType={campaign?.campaignType}
          campaignId={campaign?.id}
          campaignName={campaign?.name}
          entityId={adGroup.id}
          entityName={adGroup.name}
          entityType={ViewTrendsEntityType.ADGROUP}
          merchantCountryId={merchantCountry.merchantCountryId}
          merchantCountryName={merchantCountry.merchantName}
          merchantType={merchantCountry.merchantType}
          salesChannelId={merchantCountry.salesChannelId}
          targetingType={campaign?.targetingType}
          classnames="relative"
        />
        <Tooltip
          content={intl.formatMessage({
            id: I18nKey.KWA_CHANGE_DESTINATION_TOOLTIP,
          })}
          delayShow={750}
        >
          <Button
            size={ButtonSize.InlineIcon}
            variant={ButtonVariant.BlackAndWhite}
            svgIcon={KebabLinearIcon}
            onClick={onViewSkuKeywordClick}
            dataTestId="view-sku-keyword-slideout"
          />
        </Tooltip>
      </div>
    </div>
  );
};
Cell.displayName = 'KWACellImpressions';

export const getCampaignGoal = (createdBy?: string): BadgeCampaignGoal => {
  if (createdBy === 'campaign_creator_v1' || createdBy === 'flywheel_ai') {
    return BadgeCampaignGoal.Smart;
  }

  return BadgeCampaignGoal.None;
};

const getCampaignType = (
  campaignTargetingType?: TargetingType
): BadgeCampaignType => {
  return campaignTargetingType === TargetingType.Manual
    ? BadgeCampaignType.Manual
    : BadgeCampaignType.Auto;
};

export const getKeywordTargetingTypes = (
  targetSegments?: string[]
): KeywordRoutingType[] => {
  const keywordRoutingTypes: KeywordRoutingType[] = [];
  if (targetSegments?.includes(TargetSegments.Auto)) {
    keywordRoutingTypes.push(KeywordRoutingType.Auto);
  }
  if (targetSegments?.includes(TargetSegments.Brand)) {
    keywordRoutingTypes.push(KeywordRoutingType.Brand);
  }
  if (
    targetSegments?.includes(TargetSegments.Generic) ||
    isNil(targetSegments) ||
    targetSegments?.length === 0
  ) {
    keywordRoutingTypes.push(KeywordRoutingType.Generic);
  }
  if (targetSegments?.includes(TargetSegments.Competitor)) {
    keywordRoutingTypes.push(KeywordRoutingType.Competitor);
  }
  return keywordRoutingTypes;
};

export const createInAdGroup: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  RecommendationsTableV2ExtraProps,
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_CREATE_IN_AD_GROUP_HEADER,
  accessorKey: KeywordActionColumns.CAMPAIGN_ADGROUP_NAME,
  sortable: true,
  size: 620,
  minSize: 620,
  align: ColumnAlign.Left,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell original={original} meta={meta} />,
};

export const getTargetSegmentPills = (targetSegments?: TargetSegments[]) =>
  targetSegments
    ? targetSegments.map((targetSegment, index) => ({
        badgeType: TARGET_SEGMENT_KEY_TO_BADGE_TYPE_MAPPER[targetSegment],
        dataTestId: `kwa_createInAdgroup_targetSegment_${index}`,
        className: 'mr-4',
      }))
    : [];

export const destinationAdGroupNameFilter = createStringDataFieldFilter(
  DESTINATION_AD_GROUP_NAME_SORT_KEY,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_DESTINATION_AD_GROUP_NAME_FILTER,
  isValidString()
);

export const destinationAdGroupTargetingTypeFilter =
  createMultiSelectDataFieldFilter(
    DESTINATION_AD_GROUP_TARGETING_TYPE_SORT_KEY,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_DESTINATION_AD_GROUP_TARGETING_TYPE_FILTER,
    [
      SelectFilterOption.DestinationAdGroupTargetingTypeAuto,
      SelectFilterOption.DestinationAdGroupTargetingTypeManual,
    ]
  );

export const destinationAdGroupTagFilter = createMultiSelectDataFieldFilter(
  DESTINATION_AD_GROUP_TAG_SORT_KEY,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_DESTINATION_AD_GROUP_TAG_FILTER,
  [
    SelectFilterOption.DestinationAdGroupTagBrand,
    SelectFilterOption.DestinationAdGroupTagCompetitor,
    SelectFilterOption.DestinationAdGroupTagGeneric,
    SelectFilterOption.DestinationAdGroupTagNoTag,
  ]
);
