export interface Fw2LocalStorageInterface {
  readonly setItem: (key: string, value: string) => void;
  readonly getItem: (key: string) => string | null;
  readonly removeItem: (key: string) => void;
}

const setItem = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {}
};

const getItem = (key: string) => localStorage.getItem(key);
const removeItem = (key: string) => localStorage.removeItem(key);

export const Fw2LocalStorage: Fw2LocalStorageInterface = Object.freeze({
  setItem,
  getItem,
  removeItem,
});
