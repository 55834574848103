import {
  Alignment,
  Placement,
  Position,
  Tooltip,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../lib/types/I18nKey';
import { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

export interface OwnProps {
  readonly position?: Position;
  readonly disabled?: boolean;
  readonly followCursor?: boolean;
  readonly customClassName?: string;
}

export const ViewerRoleTooltip: React.FC<PropsWithChildren<OwnProps>> = ({
  children,
  position,
  disabled,
  followCursor = true,
  customClassName,
}) => {
  const intl = useIntl();

  const getPlacementOffset = () => {
    switch (position?.placement) {
      case Placement.Bottom:
        return 40;
      case Placement.Left:
      case Placement.Right:
        return 26;
      case Placement.Top:
        return 12;
      default:
        return 40;
    }
  };

  return (
    <Tooltip
      content={intl.formatMessage({ id: I18nKey.VIEWER_ONLY_MODE_TOOLTIP })}
      position={
        position
          ? { ...position, placementOffsetInPixels: getPlacementOffset() }
          : {
              placement: Placement.Bottom,
              alignment: Alignment.Center,
              placementOffsetInPixels: 40,
            }
      }
      disabled={disabled}
      customMaxWidth={235}
      delayShow={750}
      className={customClassName}
      overwrittenTooltipClassnames="w-max"
      followCursor={followCursor}
    >
      {children}
    </Tooltip>
  );
};
