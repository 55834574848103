import {
  MatchType,
  TargetInfo,
} from '../../lib/types/CampaignFlexibilitySharedTypes';
import React, { useState } from 'react';
import first from 'lodash/first';
import { AdGroupForKeywordsModal } from './AdGroupForKeywordsModal';
import {
  CrossMiniIcon,
  Icon,
  IconSize,
  TextLink,
} from '@teikametrics/tm-design-system';
import size from 'lodash/size';
import { MATCH_TYPE_I18N_MAPPER } from '../../modules/campaignFlexibility/components/utils/keywordUtils';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

export interface GroupByKeywordRowProps {
  readonly keywords: TargetInfo[];
  readonly dataTestId?: string;
  readonly onDelete: (searchText: string, matchType: string) => void;
  readonly onDeleteKeyword: (adGroupId: string, targetId?: string) => void;
}
export const GroupByKeywordRow: React.FC<GroupByKeywordRowProps> = ({
  keywords,
  dataTestId,
  onDelete,
  onDeleteKeyword,
}) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const keyword = first(keywords)!;

  return (
    <>
      {showModal && (
        <AdGroupForKeywordsModal
          keywords={keywords}
          onClose={() => setShowModal(false)}
          onDeleteKeyword={onDeleteKeyword}
        />
      )}
      <div className="border-b border-solid border-grey-200 py-8 w-full">
        <div
          className="flex flex-col items-start"
          data-test-id={`${dataTestId}_TagRow`}
        >
          <div className="text-base w-full leading-tight font-normal text-grey-900 flex flex-row items-center">
            <div className="flex flex-auto flex-col min-w-0">
              <span className="truncate flex-1">{keyword.searchText}</span>
            </div>
            <div className="flex gap-16 justify-end items-center flex-row">
              <span>
                {intl.formatMessage({
                  id: MATCH_TYPE_I18N_MAPPER[keyword.matchType as MatchType],
                })}
              </span>
              <Icon
                size={IconSize.Small}
                svg={CrossMiniIcon}
                onClick={() => onDelete(keyword.searchText, keyword.matchType)}
                className={classNames('text-grey-500 mt-2 cursor-pointer')}
              />
            </div>
          </div>
        </div>
        <div className="py-4">
          <TextLink
            className=" text-grey-900 leading-tight"
            onClick={() => setShowModal(true)}
            textLabel={`${size(keywords)} ad groups`}
            dataTestId={`Edit_keywords`}
          />
        </div>
      </div>
    </>
  );
};
