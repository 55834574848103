import { Factory } from 'fishery';
import { SkuCatalogData, SkuCatalogSummary } from '../../types/SKUSharedTypes';

export const SkuDetailsV2Data = Factory.define<SkuCatalogData[]>(() => [
  {
    productGroupId: 'TEST_PRODUCT_GROUP_ID',
    productCatalogId: 'TEST_PRODUCT_CATALOG_ID',
    merchantCountryId: '15e967b3-f169-465c-a8d5-dea1969a4bb5',
    skuDetailsFields: {
      extItemId: 'B00ISAPPLI',
      sku: 'S6-UJM9-A3QC',
      name: 'SKU test name 1',
      imageUrl: 'https://m.media-amazon.com/images/I/71iWVq0hEJL.jpg',
      extItemDetail: {
        extItemId: 'B00ISAPPLI',
        extItemType: 'ASIN',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        salesChannelName: 'amazon',
      },
      extItemDetails: [],
      productCogs: {
        productCatalogId: 'TEST_PRODUCT_CATALOG_ID',
        cogs: {
          amount: '1',
          currency: 'USD',
        },
        lastUpdatedAt: 1672511400000,
      },
    },
    currentMetrics: {
      productFields: {},
      profitabilityFields: {
        totalSales: {
          amount: '30000',
          currency: 'USD',
        },
        estimatedGrossProfit: {
          amount: '450',
          currency: 'USD',
        },
        unitsSold: 80,
        estimatedGrossMargin: 0.34,
        estimatedPreAdGrossMargin: 1.2,
      },
      platformFeesFields: {},
      advertisementFields: {},
      inventoryFields: {},
    },
    previousMetrics: {
      productFields: {},
      profitabilityFields: {
        totalSales: {
          amount: '34500',
          currency: 'USD',
        },
        estimatedGrossProfit: {
          amount: '518',
          currency: 'USD',
        },
        unitsSold: 56,
        estimatedGrossMargin: 0.285,
        estimatedPreAdGrossMargin: 0.86,
      },
      platformFeesFields: {},
      advertisementFields: {},
      inventoryFields: {},
    },
    parentAsin: 'B00ISAPPLI',
    parentSku: 'S6-UJM9-A3QC',
  },
  {
    productGroupId: 'TEST_PRODUCT_GROUP_ID',
    skuDetailsFields: {
      sku: 'S6-UJM9-A3QC',
      name: 'SKU test name 2',
      imageUrl: 'https://m.media-amazon.com/images/I/71iWVq0hEJL.jpg',
      extItemDetail: {
        extItemId: 'B00ISAPPLI',
        extItemType: 'ASIN',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        salesChannelName: 'amazon',
      },
      extItemDetails: [],
      productCogs: {
        productCatalogId: 'ee9c4c76-9fc9-5c70-bdcc-9841e9d68093',
        cogs: {
          amount: '1',
          currency: 'USD',
        },
      },
    },
    currentMetrics: {
      productFields: {},
      profitabilityFields: {
        totalSales: {
          amount: '30000',
          currency: 'USD',
        },
        estimatedGrossProfit: {
          amount: '450',
          currency: 'USD',
        },
        unitsSold: 80,
        estimatedGrossMargin: 0.34,
        estimatedPreAdGrossMargin: 1.2,
      },
      platformFeesFields: {},
      advertisementFields: {},
      inventoryFields: {},
    },
    parentAsin: 'B00ISAPPLI',
    parentSku: 'S6-UJM9-A3QC',
  },
  {
    productGroupId: 'TEST_PRODUCT_GROUP_ID',
    skuDetailsFields: {
      sku: 'S6-UJM9-A3QC',
      name: 'SKU test name 3',
      imageUrl: 'https://m.media-amazon.com/images/I/71iWVq0hEJL.jpg',
      extItemDetail: {
        extItemId: 'B00ISAPPLI',
        extItemType: 'ASIN',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        salesChannelName: 'amazon',
      },
      extItemDetails: [],
      productCogs: {
        productCatalogId: 'ee9c4c76-9fc9-5c70-bdcc-9841e9d68093',
        cogs: {
          amount: '1',
          currency: 'USD',
        },
        lastUpdatedAt: 1680119340244,
      },
    },
    currentMetrics: {
      productFields: {},
      profitabilityFields: {
        totalSales: {
          amount: '30000',
          currency: 'USD',
        },
        estimatedGrossProfit: {
          amount: '450',
          currency: 'USD',
        },
        unitsSold: 80,
        estimatedGrossMargin: 0.34,
        estimatedPreAdGrossMargin: 1.2,
      },
      platformFeesFields: {},
      advertisementFields: {},
      inventoryFields: {},
    },
    parentAsin: 'B00ISAPPLI',
    parentSku: 'S6-UJM9-A3QC',
  },
]);

export const SkuDetailsV2SummaryData = Factory.define<SkuCatalogSummary>(
  () => ({
    productFields: {},
    profitabilityFields: {
      totalSales: {
        amount: '30000',
        currency: 'USD',
      },
      estimatedGrossProfit: {
        amount: '450',
        currency: 'USD',
      },
      unitsSold: 80,
      estimatedGrossMargin: 0.34,
      estimatedPreAdGrossMargin: 1.2,
    },
    platformFeesFields: {},
    advertisementFields: {},
    inventoryFields: {},
    totalSkuCount: 3,
  })
);

export const SkuDetailsV2SummaryDataPrevious =
  Factory.define<SkuCatalogSummary>(() => ({
    productFields: {},
    profitabilityFields: {
      totalSales: {
        amount: '34500',
        currency: 'USD',
      },
      estimatedGrossProfit: {
        amount: '520',
        currency: 'USD',
      },
      unitsSold: 72,
      estimatedGrossMargin: 0.29,
      estimatedPreAdGrossMargin: 1.04,
    },
    platformFeesFields: {},
    advertisementFields: {},
    inventoryFields: {},
    totalSkuCount: 3,
  }));
