import {
  Alignment,
  FormToggle,
  FormToggleSize,
  FormToggleState,
  Placement,
  Spinner,
  ToastVariant,
  Tooltip,
  Type,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import {
  MerchantCountriesContext,
  MerchantCountriesContextState,
} from '../../../../containers/merchantCountriesProvider/merchantCountriesProvider';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  getAutoNegateStatus,
  getAutoNegateStatusForMerchant,
  getMerchantCountryDetailsVeiw,
  getMerchantsByChannel,
  sortMerchantsByName,
} from './utils';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../../containers/notificationProvider';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import {
  getCurrentAccountFromContext,
  isManagedAccount,
} from '../../../../containers/userProvider/selectors';
import { UserAutoNegateSettings } from '../../../../lib/types/AOSharedTypes';
import classNames from 'classnames';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../lib/types/SalesChannels';

interface OwnProps {
  readonly aoAPIClient: AOApiClient;
}

export const MerchantsWithStatusV2: React.FC<OwnProps> = ({ aoAPIClient }) => {
  const intl = useIntl();

  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;
  const userId = userContext.userInfo.userDetails?.id || '';

  const isManaged = isManagedAccount(userContext);

  const toasts = useContext<NotificationContextState>(NotificationContext);
  const merchantCountriesContext = useContext<MerchantCountriesContextState>(
    MerchantCountriesContext
  );
  const merchantCountries = merchantCountriesContext.merchantCountries!;

  const amazonMerchants = useMemo(
    () => getMerchantsByChannel(merchantCountries, AMAZON_SALES_CHANNEL_ID),
    [merchantCountries]
  );
  const walmartMerchants = useMemo(
    () => getMerchantsByChannel(merchantCountries, WALMART_SALES_CHANNEL_ID),
    [merchantCountries]
  );

  const amazonMcIds = amazonMerchants.map((mc) => mc.merchantCountryId);

  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [mcIdLoading, setMcIdLoading] = useState<string>('');
  const [autoNegateStatus, setAutoNegateStatus] = useState<
    UserAutoNegateSettings[]
  >(
    amazonMerchants.map((mc) => ({
      merchantCountryId: mc.merchantCountryId,
      isAutoNegateEnabled: false,
    }))
  );

  // Methods
  const onFormToggleClick = (mcId: string) => {
    const userSettings = autoNegateStatus.find(
      (mc) => mc.merchantCountryId === mcId
    );

    if (userSettings) {
      setMcIdLoading(userSettings.merchantCountryId);
      aoAPIClient
        .saveAutoNegateSettings(accountId, {
          userId,
          settings: [
            {
              ...userSettings,
              isAutoNegateEnabled: !userSettings.isAutoNegateEnabled,
            },
          ],
        })
        .then(() => {
          fetchAutoNegateStatuses();
          toasts.addNotification({
            headline: intl.formatMessage(
              {
                id: I18nKey.KWA_AUTO_NEGATE_TOAST_AUTO_NEGATE_SUCCESS,
              },
              {
                isDisabled: !!autoNegateStatus.find(
                  (mc) => mc.merchantCountryId === mcId
                )?.isAutoNegateEnabled,
              }
            ),
            type: Type.Success,
            description: '',
            variant: ToastVariant.Simple,
          });
        })
        .catch(() => {
          toasts.addNotification({
            headline: intl.formatMessage({
              id: I18nKey.GENERIC_SOMETHING_WENT_WRONG,
            }),
            type: Type.Attention,
            description: '',
            variant: ToastVariant.Simple,
          });
        })
        .finally(() => {
          setMcIdLoading('');
        });
    }
  };

  const saveAutoNegateSettingsForPendingMerchants = (
    merchantStatuses: UserAutoNegateSettings[]
  ) => {
    const merchantsWithNullStatus = amazonMcIds.filter(
      (mc) =>
        merchantStatuses.findIndex(
          (mcStatus) => mcStatus.merchantCountryId === mc
        ) === -1
    );

    if (merchantsWithNullStatus.length) {
      aoAPIClient.saveAutoNegateSettings(accountId, {
        userId,
        settings: merchantsWithNullStatus.map((mc) => ({
          merchantCountryId: mc,
          isAutoNegateEnabled: !!isManaged,
        })),
      });
    }
  };

  const fetchAutoNegateStatuses = () => {
    aoAPIClient
      .getAutoNegateSettings(accountId, amazonMcIds)
      .then((resp) => {
        const mcIds: UserAutoNegateSettings[] = amazonMcIds.map((mc) => ({
          merchantCountryId: mc,
          isAutoNegateEnabled: getAutoNegateStatusForMerchant(
            mc,
            resp,
            isManaged
          ),
        }));
        saveAutoNegateSettingsForPendingMerchants(resp || []);
        setAutoNegateStatus([...mcIds]);
      })
      .finally(() => setLoading(false));
  };

  // Fetch auto negate statuses for all merchants
  useEffect(() => {
    setLoading(true);
    fetchAutoNegateStatuses();
  }, []);

  return (
    <div
      className={classNames('w-full', {
        'h-240': loading,
      })}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <p>
            {amazonMerchants.length > 0 && (
              <Typography
                size={TypographySize.base}
                lineHeight={TypographyLineHeight.none}
                weight={TypographyWeight.regular}
                className="text-grey-900 font-medium"
              >
                {intl.formatMessage({ id: I18nKey.GENERIC_AMAZON })}
              </Typography>
            )}
          </p>
          {amazonMerchants.sort(sortMerchantsByName).map((mc) => {
            const merchantAutoNegateStatus = getAutoNegateStatus(
              autoNegateStatus,
              mc.merchantCountryId
            );
            return (
              <div className="flex justify-between items-center py-12 text-grey-900">
                {getMerchantCountryDetailsVeiw(mc)}
                <div className="flex space-x-8">
                  <FormToggle
                    size={FormToggleSize.Small}
                    state={
                      merchantAutoNegateStatus
                        ? FormToggleState.On
                        : FormToggleState.Off
                    }
                    onClick={() => onFormToggleClick(mc.merchantCountryId)}
                    disabled={mc.merchantCountryId === mcIdLoading}
                    dataTestId={mc.merchantCountryId}
                  />
                  <Typography
                    size={TypographySize.base}
                    lineHeight={TypographyLineHeight.none}
                    weight={TypographyWeight.regular}
                    className="text-grey-900"
                  >
                    {intl.formatMessage({
                      id: merchantAutoNegateStatus
                        ? I18nKey.KWA_AUTO_NEGATE_SETTINGS_TOGGLE_ON
                        : I18nKey.KWA_AUTO_NEGATE_SETTINGS_TOGGLE_OFF,
                    })}
                  </Typography>
                </div>
              </div>
            );
          })}
          <p>
            {walmartMerchants.length > 0 && (
              <Typography
                size={TypographySize.base}
                lineHeight={TypographyLineHeight.none}
                weight={TypographyWeight.regular}
                className="text-grey-900 mt-24 font-medium"
              >
                {intl.formatMessage({ id: I18nKey.GENERIC_WALMART })}
              </Typography>
            )}
          </p>
          {walmartMerchants.sort(sortMerchantsByName).map((mc) => (
            <div className="flex justify-between items-center py-12">
              {getMerchantCountryDetailsVeiw(mc)}
              <Tooltip
                content={intl.formatMessage({
                  id: I18nKey.KWA_AUTO_NEGATE_WALMART_NOT_AVAILABLE_TOOLTIP,
                })}
                position={{
                  placement: Placement.Left,
                  alignment: Alignment.Center,
                  placementOffsetInPixels: 10,
                }}
                overwrittenTooltipClassnames="min-w-260"
              >
                <Typography
                  size={TypographySize.base}
                  lineHeight={TypographyLineHeight.none}
                  weight={TypographyWeight.regular}
                  className="text-grey-500"
                >
                  {intl.formatMessage({ id: I18nKey.GENERIC_NOT_AVAILABLE })}
                </Typography>
              </Tooltip>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
MerchantsWithStatusV2.displayName = 'KWAMerchantsWithStatusV2';
