import {
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../lib/types/I18nKey';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { ReactNode } from 'react';

export enum ManagedEditionSize {
  small = 'small',
  xl = 'xl',
}

export interface ManagedEditionProps {
  readonly onManagedEditionClick?: () => void;
  readonly size?: ManagedEditionSize;
}

export const ManagedEdition: React.FC<ManagedEditionProps> = ({
  onManagedEditionClick,
  size = ManagedEditionSize.small,
}) => {
  return (
    <div
      className={classNames('w-max bg-blue-gradient', {
        'p-2': size === ManagedEditionSize.small,
        'p-4': size === ManagedEditionSize.xl,
      })}
    >
      <div
        className={classNames(
          'flex flex-row items-center justify-center text-white uppercase',
          {
            'bg-yankee-blue': !onManagedEditionClick,
            'bg-purple-700 cursor-pointer hover:bg-purple-600':
              onManagedEditionClick,
            'px-12 h-24': size === ManagedEditionSize.small,
            'px-8 py-4 h-38': size === ManagedEditionSize.xl,
          }
        )}
        onClick={onManagedEditionClick ? onManagedEditionClick : noop}
      >
        <Typography
          size={
            size === ManagedEditionSize.small
              ? TypographySize.sm
              : TypographySize.xl
          }
          lineHeight={TypographyLineHeight.none}
          weight={TypographyWeight.regular}
        >
          <FormattedMessage
            id={I18nKey.MANAGED_EDITION_TITLE}
            values={{
              bold: (content: ReactNode) => (
                <span className="font-semibold">{content}</span>
              ),
            }}
          />
        </Typography>
      </div>
    </div>
  );
};

ManagedEdition.displayName = 'ManagedEdition';
