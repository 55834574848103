import { useIntl } from 'react-intl';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignAdFormat,
  CampaignDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CAMPAIGNS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<CampaignDetails> = ({
  ...campaignDetails
}) => {
  const intl = useIntl();
  return makeTextColumn<CampaignDetails>(
    ({ campaignDetails: { campaignAdFormat } }) =>
      (campaignAdFormat &&
        intl.formatMessage({
          id: CAMPAIGN_AD_FORMAT_I18NKEY_MAPPER[campaignAdFormat],
        })) ||
      undefined
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignAdFormatColumn: FlywheelTableColumn<CampaignDetails> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.CampaignAdFormat,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_FORMAT,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '180px',
};

export const campaignAdFormatFilter = createMultiSelectDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.CampaignAdFormat,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_FORMAT,
  [
    SelectFilterOption.CampaignAdFormatProductCollection,
    SelectFilterOption.CampaignAdFormatVideo,
    SelectFilterOption.CampaignAdFormatStoreSpotlight,
  ]
);

const CAMPAIGN_AD_FORMAT_I18NKEY_MAPPER: Record<CampaignAdFormat, I18nKey> = {
  [CampaignAdFormat.ProductCollection]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_AD_FORMAT_PRODUCT_COLLECTION,
  [CampaignAdFormat.Video]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_AD_FORMAT_VIDEO,
  [CampaignAdFormat.StoreSpotlight]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_AD_FORMAT_STORE_SPOTLIGHT,
};
