import first from 'lodash/first';
import { DateTime } from 'luxon';
import React, { ChangeEvent, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import uuid from 'uuid';

import {
  Alignment,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Placement,
  Type,
} from '@teikametrics/tm-design-system';

import { AOApiClient } from '../../lib/clients/AOApiClient';
import { MerchantCountry } from '../../lib/types/Fam';
import I18nKey from '../../lib/types/I18nKey';
import {
  NotificationContext,
  NotificationContextState,
} from '../notificationProvider';
import { getCurrentAccountFromContext } from '../userProvider/selectors';
import { UserContext, UserContextState } from '../userProvider/userProvider';
import { getTransformedTreUploadMCs } from './utils';
import { ViewerRoleTooltip } from '../../lib/utilities/viewerRoleTooltipWrapper';
import classNames from 'classnames';

export enum ShowTreUploadButtonIn {
  AO = 'ao',
  AI = ' ai',
}

const MAX_RETRIES = 5;
const RETRY_INTERVAL = 2000;

export interface TreUploadMerchantCountry {
  readonly merchantCountryId: string;
  readonly merchantCountryName: string;
  readonly saleChannelId: string;
  readonly salesChannelName: string;
  readonly merchantType: string;
}

export interface FileUploadProps {
  readonly aoApiClient: AOApiClient;
  readonly showTreUploadButtonIn: ShowTreUploadButtonIn;
  readonly merchantCountries: MerchantCountry[];
  readonly isUserRoleViewOnly?: boolean;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  aoApiClient,
  showTreUploadButtonIn,
  merchantCountries,
  isUserRoleViewOnly = false,
}) => {
  const intl = useIntl();
  const toasts = useContext<NotificationContextState>(NotificationContext);
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;

  const [uploading, setUploading] = useState<boolean>(false);

  const onFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    const files: FileList = (event.target.files as FileList) || [];
    const selectedFile = first(files);
    if (selectedFile) {
      uploadCSV(selectedFile);
    }
  };

  const validateTreUpload = (uniqueFileName: string) => {
    const requestId = uuid();
    const response = aoApiClient.treUpload(
      accountId,
      requestId,
      uniqueFileName,
      getTransformedTreUploadMCs(merchantCountries)
    );
    let retries = 0;
    response
      .then(() => {
        const id = setInterval(() => {
          retries += 1;
          const statusCheckResponse = aoApiClient.treUploadStatusCheck(
            accountId,
            requestId
          );
          statusCheckResponse
            .then((resp) => {
              if (resp.data.status.toLocaleLowerCase() === 'success') {
                successToast();
                clearInterval(id);
              } else if (
                resp.data.status.toLocaleLowerCase() === 'in progress' &&
                retries < MAX_RETRIES
              ) {
                // Go on with the polling
              } else {
                failureToast();
                clearInterval(id);
              }
            })
            .catch(() => {
              failureToast();
              clearInterval(id);
            });
        }, RETRY_INTERVAL);
      })
      .catch(() => {
        failureToast();
      });
  };

  const uploadToS3 = async (
    file: File,
    uniqueFileName: string,
    url: string
  ) => {
    try {
      await aoApiClient.treUploadS3FileUpload(url, file);
      validateTreUpload(uniqueFileName);
    } catch (error) {
      failureToast();
    }
  };

  const uploadCSV = async (file: File) => {
    const timeInSeconds = DateTime.local().toMillis();
    const uniqueFileName = `${accountId}_${timeInSeconds}_${file.name}`;
    try {
      const res = await aoApiClient.treUploadGetS3SecureUrl(
        accountId,
        uniqueFileName
      );
      uploadToS3(file, uniqueFileName, res.data.url);
    } catch (error) {
      failureToast();
    }
  };

  const failureToast = () => {
    setUploading(false);
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.GENERIC_SOMETHING_WENT_WRONG,
      }),
      description: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_TRE_UPLOAD_TOAST_ERROR_BODY,
      }),
      type: Type.Attention,
      dataTestId: `${showTreUploadButtonIn}_treUploadFileUploadError`,
    });
  };

  const successToast = () => {
    setUploading(false);
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.GENERIC_SUCCESS,
      }),
      description: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_TRE_UPLOAD_TOAST_SUCCESS_BODY,
      }),
      type: Type.Success,
      dataTestId: `${showTreUploadButtonIn}_treUploadFileUploadSuccess`,
    });
  };

  const buttonLabel = () => (
    <>
      <label
        htmlFor={'attach-file-tre-upload'}
        className={classNames({
          'cursor-pointer': !isUserRoleViewOnly,
          'cursor-not-allowed': isUserRoleViewOnly,
        })}
      >
        {intl.formatMessage({ id: I18nKey.AO_AI_TRE_UPLOAD })}
      </label>
      {!isUserRoleViewOnly && (
        <input
          id={'attach-file-tre-upload'}
          type="file"
          accept={'text/csv'}
          onChange={onFileSelected}
          className="hidden"
          onClick={(event: React.MouseEvent<HTMLInputElement> | undefined) => {
            if (event) {
              (event.target as HTMLInputElement).value = '';
            }
          }}
        />
      )}
    </>
  );

  const getButtonSize = () =>
    showTreUploadButtonIn === ShowTreUploadButtonIn.AO
      ? ButtonSize.Medium
      : ButtonSize.Large;

  return (
    <ViewerRoleTooltip
      disabled={!isUserRoleViewOnly}
      position={{
        placement: Placement.Right,
        alignment: Alignment.Center,
      }}
    >
      <Button
        size={getButtonSize()}
        variant={ButtonVariant.BlackAndWhiteBorder}
        state={
          isUserRoleViewOnly
            ? ButtonState.Disabled
            : uploading
            ? ButtonState.Loading
            : ButtonState.Enabled
        }
        label={buttonLabel()}
        dataTestId={`${showTreUploadButtonIn}_tre_upload`}
      />
    </ViewerRoleTooltip>
  );
};
