import {
  LevelsController,
  LevelsControllerProps,
  Slideover,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/types/I18nKey';
import { CampaignsSelect, CampaignsSelectProps } from './CampaignSelect';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../lib/utilities/intercom';

interface ViewTrendsSlideoverProps {
  readonly isOpen: boolean;
  readonly children?: JSX.Element;
  readonly onClose?: () => void;
  readonly className?: string;
  readonly campaignsSelectProps?: CampaignsSelectProps;
  readonly levelControllerProps?: LevelsControllerProps;
  readonly overlayZindexClassname?: string;
  readonly dataTestId?: string;
}

export const ViewTrendsSlideover: React.FC<ViewTrendsSlideoverProps> = (
  props
) => {
  const { isOpen, children, onClose, overlayZindexClassname } = props;
  const intl = useIntl();

  const trendsLabel = intl.formatMessage({
    id: I18nKey.GENERIC_TRENDS,
  });

  useEffect(() => {
    if (isOpen) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [isOpen]);

  return (
    <Slideover
      wrapperClassName={props.className}
      hideFooter
      isOpen={isOpen}
      title={trendsLabel}
      content={children}
      onClose={onClose}
      dataTestId={props.dataTestId}
      overlayZindexClassname={overlayZindexClassname}
      contentClassName="w-1040 overflow-y-auto"
    />
  );
};

export const ViewTrendsSlideoverWrapper: React.FC<ViewTrendsSlideoverProps> = (
  props
) => {
  const {
    isOpen,
    onClose,
    campaignsSelectProps,
    levelControllerProps,
    children,
    dataTestId,
  } = props;

  const [isCampaignSelectDropdownOpen, setCampaignSelectDropdownOpen] =
    useState<boolean>(false);

  return (
    <ViewTrendsSlideover
      isOpen={isOpen}
      onClose={onClose}
      dataTestId={dataTestId}
      overlayZindexClassname="z-56"
      className="max-w-1080"
    >
      <div className="flex flex-col w-full gap-24">
        <CampaignsSelect
          {...campaignsSelectProps}
          onToggle={(isSelectOpen: boolean) => {
            setCampaignSelectDropdownOpen(isSelectOpen);
          }}
          dataTestId={dataTestId}
        />
        <div
          className={classNames('flex flex-col w-full', {
            invisible: isCampaignSelectDropdownOpen,
          })}
        >
          {levelControllerProps && (
            <LevelsController {...levelControllerProps} />
          )}
        </div>
        <div
          className={classNames('flex flex-col w-full', {
            invisible: isCampaignSelectDropdownOpen,
          })}
        >
          {children}
        </div>
      </div>
    </ViewTrendsSlideover>
  );
};

ViewTrendsSlideoverWrapper.displayName = 'ViewTrendsSlideover';
