import { useState } from 'react';

export const useActionAnimationDisable = () => {
  const [disableAnimation, setDisableAnimation] = useState(false);

  const disableAnimationOnAction = () => {
    setDisableAnimation(true);
    setTimeout(() => setDisableAnimation(false), 100);
  };

  return {
    isAnimationDisabled: disableAnimation,
    disableAnimationOnAction,
  };
};
