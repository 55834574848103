import { useContext, useEffect, useState } from 'react';

import { AppNavV2 } from '@teikametrics/tm-design-system';

import {
  OptimizelyContext,
  OptimizelyContextState,
} from './containers/optimizelyProvider/optimizelyProvider';
import {
  SaveChangesFlagContext,
  SaveChangesFlagContextState,
} from './containers/saveChangesFlagProvider';
import { SubscriptionContext } from './containers/subscriptionProvider';
import { MetadataTags, Role } from './lib/types/Fam';
import {
  areAllMerchants1PAndAmazon,
  is1PMerchantsPresent,
  is3PMerchantsPresent,
} from './lib/utilities/connectionStatus';
import { getNavModulesForAccount } from './NavModules';
import { NavPaths } from './NavPaths';
import { useRecommendationsContext } from './containers/recommendationsProvider/recommendationsProvider';
import { getRecommendationsCount } from './containers/recommendationsProvider/utils';
import { SyncStatusNavItem } from './SyncStatusNavItem';
import {
  UserContext,
  UserContextState,
} from './containers/userProvider/userProvider';
import { createFAMApiClient } from './lib/clients/FAMApiClient';
import {
  getCurrentAccountFromContext,
  getCurrentAccountSubscriptions,
  getUserRoleMatch,
  isAIPlanEnabled,
  isSelfServiceAccount,
} from './containers/userProvider/selectors';
import {
  PageHeaderContext,
  PageHeaderContextState,
} from './containers/pageHeaderProvider';
import { PRODUCT_SUBSCRIPTIONS } from './lib/types/AOSharedTypes';
import { getMetadataTagsStatus } from './lib/utilities/inventoryUtils';
import { segmentTrack } from './lib/utilities/segment';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { GeneralQueryKeys } from './lib/types/ReactQueryKeys';

interface NavbarProps {
  readonly userRole: Role;
}

export const Navbar: React.FC<NavbarProps> = ({ userRole }) => {
  const location = useLocation();
  const [showRecsNotificationDot, setShowRecsNotificationDot] = useState(false);
  const [isUpsellPathEnabled, setIsUpsellPathEnabled] =
    useState<boolean>(false);

  const userContext = useContext<UserContextState>(UserContext);
  const saveChangesFlagContext = useContext<SaveChangesFlagContextState>(
    SaveChangesFlagContext
  );

  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);

  const { state: recommendationsState } = useRecommendationsContext();

  const subscriptionContext = useContext(SubscriptionContext);
  const { salesChannelData, isSubscriptionInformationLoaded } =
    subscriptionContext;

  const areAll1PMerchantsAndAmazon =
    isSubscriptionInformationLoaded &&
    areAllMerchants1PAndAmazon(salesChannelData);

  const has1PMerchants =
    isSubscriptionInformationLoaded && is1PMerchantsPresent(salesChannelData);
  const has3PMerchants =
    isSubscriptionInformationLoaded && is3PMerchantsPresent(salesChannelData);

  const currentAccount = getCurrentAccountFromContext(userContext);
  const showBilling = isSelfServiceAccount(currentAccount);

  const accountId = currentAccount!.id;

  const famApiClient = createFAMApiClient(userContext.userInfo.idToken!);

  const isAiEnabled = isAIPlanEnabled(userContext);

  const isInventoryOptimizationSubscribed = !!getCurrentAccountSubscriptions(
    PRODUCT_SUBSCRIPTIONS.INVENTORY_MODULE,
    userContext.userInfo.userDetails!
  )?.id;

  useEffect(() => {
    setShowRecsNotificationDot(
      getRecommendationsCount(recommendationsState.recommendationsCount) > 0 &&
        !recommendationsState.homepageRecommendationsDismissed
    );
  }, [recommendationsState]);

  useEffect(() => {
    famApiClient.trackLoggedInUser(accountId);
  }, [accountId]);

  const { updatePageHeaderData } =
    useContext<PageHeaderContextState>(PageHeaderContext);

  const { refetch: getMetadataTagStatus } = useQuery({
    queryKey: [GeneralQueryKeys.GetMetadataTagStatus, accountId],
    queryFn: () =>
      getMetadataTagsStatus(
        famApiClient,
        accountId,
        MetadataTags.UpsellInventoryModule
      ),
    enabled: false,
  });

  useEffect(() => {
    const setUpsellStatus = async () => {
      const { data: upsellMetadataTag } = await getMetadataTagStatus();
      setIsUpsellPathEnabled(!!upsellMetadataTag);
    };
    setUpsellStatus();
  }, []);

  const onClickNavLink = (
    path: string,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (path === NavPaths.BIMainNavigation) {
      e.preventDefault();
      segmentTrack('bi_nav_clicked');
    } else if (saveChangesFlagContext.saveChangesData.editDataFlag) {
      e.preventDefault();
      saveChangesFlagContext.updateSaveChangesData({
        ...saveChangesFlagContext.saveChangesData,
        navPath: path,
        saveChangesModalIsOpen: true,
      });
    } else {
      // Update the page header data to title from nav on switch.
      // The accurate data will be set when the module loads eventually.
      updatePageHeaderData({
        title: e.currentTarget.textContent,
        dataTestId: undefined,
        breadCrumbs: undefined,
      });
    }
  };

  const isUserRoleViewOnly = getUserRoleMatch(
    Role.VIEW_ONLY,
    userContext.userInfo.userDetails
  );

  return (
    <AppNavV2
      currentPath={location.pathname}
      modules={getNavModulesForAccount(
        userRole,
        optimizelyContext.featureFlags,
        areAll1PMerchantsAndAmazon,
        showRecsNotificationDot,
        accountId,
        showBilling,
        has1PMerchants,
        has3PMerchants,
        isAiEnabled,
        isInventoryOptimizationSubscribed,
        isUpsellPathEnabled,
        isUserRoleViewOnly
      )}
      onClickNavLink={onClickNavLink}
      helpComponent={<SyncStatusNavItem />}
    />
  );
};
Navbar.displayName = 'Navbar';
