import React from 'react';
import { IntlShape } from 'react-intl';

import {
  Alignment,
  Placement,
  SelectOptionProps,
  SelectVisibility,
  Theme,
  TooltipProps,
} from '@teikametrics/tm-design-system';

import { SKUApiClient } from '../../../../../lib/clients/SKUApiClient';
import { AdType, Range } from '../../../../../lib/types/AOSharedTypes';
import {
  BusinessPerformanceDataRequest,
  HomepageResponseMetricKeys,
  PerformanceMetrics,
} from '../../../../../lib/types/CompassSharedTypes';
import {
  FlywheelSalesChannel,
  MerchantCountry,
  MerchantType,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  SkuCatalogDetailsRequest,
  SkuCatalogMetrics,
  SkuDetailsFields,
} from '../../../../../lib/types/SKUSharedTypes';
import { SortOrder } from '../../../../../lib/types/Sort';
import { SkuCatalogApiColumnValue } from '../../../../products/containers/skuCatalog/types';
import { getCampaignColumns } from '../columns/campaigns';
import { getMerchantsColumns } from '../columns/merchants';
import { getProductColumns } from '../columns/products';
import { BusinessPerformanceColumnName } from './constants';
import { SelectedTrendsProduct } from '../../../../products/containers/skuCatalog';
import { CurrencyCode } from '../../../../../lib/utilities/currency';
import { roundOfPercentageToFloorOrCeil } from '../utils';

export enum ViewLevel {
  Merchants = 'merchants',
  Products = 'products',
  Campaigns = 'campaigns',
}

export enum RankedBy {
  HighPerformers = 'highPerformers',
  LowPerformers = 'lowPerformers',
}

export interface MerchantDetails {
  readonly merchantCountryId: string;
  readonly merchantCountryName?: string;
  readonly salesChannelId: string;
  readonly salesChannelName: string;
  readonly merchantType: MerchantType;
}

export interface CampaignDetails {
  readonly campaignName: string;
  readonly advertisingType: AdType;
}

export type MerchantMetrics = {
  readonly merchantCountryDetails: MerchantDetails;
  performanceMetrics?: PerformanceMetrics;
};

export const BUSINESS_PERFORMANCE_TABLE_ID = 'business_performance_id';

export type CampaignMetrics = {
  readonly campaignId: string;
  readonly campaignDetails: CampaignDetails;
  readonly merchantCountryDetails: MerchantDetails;
  campaignPerformance?: PerformanceMetrics;
};

export type SkuMetrics = {
  readonly merchantCountryId?: string;
  readonly productGroupId: string;
  readonly skuDetailsFields: SkuDetailsFields;
  readonly currentMetrics: SkuCatalogMetrics;
};

export interface BusinessPerformanceData<
  T extends MerchantMetrics | CampaignMetrics | SkuMetrics
> {
  readonly range: Range;
  readonly currency: string;
  readonly performanceSummary?: PerformanceMetrics;
  readonly elements: T[];
}

export type PerformanceTableExtraProps = PerformanceMetrics & {
  merchants: MerchantCountry[];
  activeColumn?: string;
  onViewTrendsSlideoverClick?: (product: SelectedTrendsProduct) => void;
};

export type PerformanceRowExtraProps = {
  flywheelAiAddedDate?: string;
  rank?: number;
};

export type MerchantRowData = MerchantMetrics & PerformanceRowExtraProps;

export type CampaignRowData = CampaignMetrics & PerformanceRowExtraProps;

export type ProductRowData = SkuMetrics & PerformanceRowExtraProps;

export type CampaignCellData = CampaignRowData & PerformanceTableExtraProps;

export type MerchantCellData = MerchantRowData & PerformanceTableExtraProps;

export type ProductCellData = ProductRowData & PerformanceTableExtraProps;

export const VIEW_LEVEL_COLUMN_MAP = {
  [ViewLevel.Merchants]: BusinessPerformanceColumnName.TotalSales,
  [ViewLevel.Campaigns]: BusinessPerformanceColumnName.AdSalesTotal,
  [ViewLevel.Products]: BusinessPerformanceColumnName.TotalSales,
};

export const VIEW_LEVEL_PERFORMANCE_FIELDS_MAP = {
  [ViewLevel.Merchants]: [
    HomepageResponseMetricKeys.TotalSales,
    HomepageResponseMetricKeys.AdSpend,
    HomepageResponseMetricKeys.AdSales,
    HomepageResponseMetricKeys.Tacos,
  ],
  [ViewLevel.Campaigns]: [
    HomepageResponseMetricKeys.AdSpend,
    HomepageResponseMetricKeys.AdSalesTotal,
    HomepageResponseMetricKeys.ACoS,
  ],
  [ViewLevel.Products]: [],
};

export const VIEW_LEVEL_COLUMN_I18NKEY_MAP = {
  [ViewLevel.Merchants]: I18nKey.COMPASS_DASHBOARD_TOTAL_SALES,
  [ViewLevel.Products]: I18nKey.COMPASS_DASHBOARD_TOTAL_SALES,
  [ViewLevel.Campaigns]: I18nKey.ADVERTISING_OPTIMIZATION_CAMPAIGNS_AD_SALES,
};

export const getNumberWithProgressBarColumnProps = (
  intl: IntlShape,
  currentValue: number,
  total: number,
  isActive: boolean,
  currency: string = CurrencyCode.USD.toString()
) => {
  const progressPercent = roundOfPercentageToFloorOrCeil(
    (currentValue * 100) / total
  );

  return {
    currentValue,
    progressText: intl.formatMessage(
      { id: I18nKey.COMPASS_FLYWHEEL_REVENUE_SHARE },
      { progressPercent }
    ),
    progressPercent: progressPercent,
    showProgress: isActive && total > 0,
    numericStyle: 'currency' as 'percent' | 'currency',
    className: 'bg-transparent',
    currency,
    intl,
  };
};

export const getColumns = (viewLevel: ViewLevel, intl: IntlShape) => {
  switch (viewLevel) {
    case ViewLevel.Merchants: {
      return getMerchantsColumns(intl);
    }
    case ViewLevel.Campaigns: {
      return getCampaignColumns(intl);
    }
    case ViewLevel.Products: {
      return getProductColumns(intl);
    }
    default: {
      return [];
    }
  }
};

export const getViewOptions = (intl: IntlShape, merchantsCount: number) => {
  return [
    ...(merchantsCount > 1
      ? [
          {
            label: intl.formatMessage({
              id: I18nKey.CONTEXT_SETTER_SALES_CHANNEL_PICKER_MERCHANTS,
            }),
            value: ViewLevel.Merchants,
          },
        ]
      : []),
    {
      label: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_TABS_PRODUCTS,
      }),
      value: ViewLevel.Products,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_CAMPAIGNS,
      }),
      value: ViewLevel.Campaigns,
    },
  ];
};

export const getSalesChannelFilters = (
  viewLevel: ViewLevel,
  rowData: (MerchantMetrics | CampaignMetrics | SkuMetrics)[] = [],
  isDisabled = false
) => {
  const salesChannelFilters = [
    {
      nameI18nKey: I18nKey.GENERIC_SELECT_ALL,
      count: rowData?.length,
      value: null,
    },
    {
      nameI18nKey: I18nKey.GENERIC_AMAZON,
      count: ((rowData as any[]) || [])?.filter((data) => {
        if (viewLevel !== ViewLevel.Products) {
          return (
            data?.merchantCountryDetails?.salesChannelName ===
            FlywheelSalesChannel.Amazon
          );
        } else {
          return !!(data as SkuMetrics)?.skuDetailsFields?.extItemDetails?.find(
            (item) => item.salesChannelName === FlywheelSalesChannel.Amazon
          );
        }
      }).length,
      value: FlywheelSalesChannel.Amazon,
    },
    {
      nameI18nKey: I18nKey.GENERIC_WALMART,
      count: (rowData as any[])?.filter((data) => {
        if (viewLevel !== ViewLevel.Products) {
          return (
            data?.merchantCountryDetails?.salesChannelName ===
            FlywheelSalesChannel.Walmart
          );
        } else {
          return !!(data as SkuMetrics)?.skuDetailsFields?.extItemDetails?.find(
            (item) => item.salesChannelName === FlywheelSalesChannel.Walmart
          );
        }
      }).length,
      value: FlywheelSalesChannel.Walmart,
    },
  ];

  if (isDisabled) {
    return salesChannelFilters.map((filter) => ({
      ...filter,
      isDisabled,
      count: 0,
    }));
  }

  if (salesChannelFilters.some((filter) => filter.count === 0)) {
    return [];
  }

  return salesChannelFilters;
};

export const getRankedByTooltipProps = (
  intl: IntlShape,
  viewLevel: ViewLevel,
  rankedBy: RankedBy,
  label: string
): TooltipProps => {
  return {
    content: (
      <div className="flex flex-col p-16">
        <span className="text-sm leading-normal font-semibold text-grey-900">
          {label}
        </span>
        <p className="text-sm leading-normal font-normal text-grey-700">
          {intl.formatMessage(
            {
              id: I18nKey.COMPASS_FLYWHEEL_RANKED_BY_TOOLTIP_MESSAGE,
            },
            {
              view: viewLevel,
              column: intl.formatMessage({
                id: VIEW_LEVEL_COLUMN_I18NKEY_MAP[viewLevel],
              }),
              position: intl.formatMessage({
                id:
                  rankedBy === RankedBy.HighPerformers
                    ? I18nKey.GENERIC_TOP
                    : I18nKey.GENERIC_BOTTOM,
              }),
            }
          )}
        </p>
      </div>
    ),
    theme: Theme.LightV2,
    hideArrow: true,
    overwrittenTooltipClassnames: 'max-w-212',
    position: {
      placementOffsetInPixels: 12,
      placement: Placement.Right,
      alignment: Alignment.Center,
    },
  };
};

export const getRankedByOptions = (
  intl: IntlShape,
  viewLevel: ViewLevel
): SelectOptionProps<RankedBy>[] => {
  const highPerformers = intl.formatMessage({
    id: I18nKey.COMPASS_RANKED_BY_HIGH_PERFORMERS,
  });
  const lowPerformers = intl.formatMessage({
    id: I18nKey.COMPASS_RANKED_BY_LOW_PERFORMERS,
  });

  return [
    {
      label: highPerformers,
      value: RankedBy.HighPerformers,
      tooltip: getRankedByTooltipProps(
        intl,
        viewLevel,
        RankedBy.HighPerformers,
        highPerformers
      ),
    },
    {
      label: lowPerformers,
      value: RankedBy.LowPerformers,
      tooltip: getRankedByTooltipProps(
        intl,
        viewLevel,
        RankedBy.LowPerformers,
        lowPerformers
      ),
    },
  ];
};

export const getNewSelectVisibility = (currentVisibility: SelectVisibility) =>
  currentVisibility === SelectVisibility.Open
    ? SelectVisibility.Closed
    : SelectVisibility.Open;

export const getSkuCatalogData = async (
  skuApiClient: SKUApiClient,
  accountId: string,
  request: BusinessPerformanceDataRequest,
  rankedBy: RankedBy,
  selectedMerchantIds: string[]
) => {
  const requestData: SkuCatalogDetailsRequest = {
    ...request,
    advertisementFields: [
      SkuCatalogApiColumnValue.AdSales,
      SkuCatalogApiColumnValue.AdSpend,
    ],
    profitabilityFields: [SkuCatalogApiColumnValue.TotalSales],
    productFields: [SkuCatalogApiColumnValue.Tacos],
    platformFeesFields: [],
    inventoryFields: [],
    skuDetailsFields: [
      SkuCatalogApiColumnValue.SKU,
      SkuCatalogApiColumnValue.Name,
      SkuCatalogApiColumnValue.ImageUrl,
      SkuCatalogApiColumnValue.ExternalItemId,
      SkuCatalogApiColumnValue.ExternalItemType,
      SkuCatalogApiColumnValue.SalesChannelId,
      SkuCatalogApiColumnValue.SalesChannelName,
    ],
    previousPeriodIncluded: false,
    merchantCountries: request.merchantCountries.filter((mc) =>
      selectedMerchantIds.includes(mc.merchantCountryId)
    ),
  };

  const dataRequest = skuApiClient.getSkuCatalogDetails(
    accountId,
    requestData
  )({
    filters: [],
    extraParams: {
      forCompassProductSnapshot: 'true',
    },
    sorts: [
      {
        column: SkuCatalogApiColumnValue.TotalSales,
        direction:
          rankedBy === RankedBy.LowPerformers ? SortOrder.Asc : SortOrder.Desc,
      },
    ],
    itemsPerPage: 6,
    page: 1,
  });
  const summaryRequest = skuApiClient.getSkuCatalogSummary(
    accountId,
    requestData,
    {}
  )({
    filters: [],
    sorts: [
      {
        column: SkuCatalogApiColumnValue.TotalSales,
        direction:
          rankedBy === RankedBy.LowPerformers ? SortOrder.Asc : SortOrder.Desc,
      },
    ],
  });

  return Promise.all([dataRequest, summaryRequest]).then(([data, summary]) => {
    let businessData: BusinessPerformanceData<SkuMetrics> = {
      range: {
        startDate: request.startDate,
        endDate: request.endDate,
      },
      currency: request.currency,
      elements: data.items,
    };
    if (summary) {
      businessData = {
        ...businessData,
        performanceSummary: {
          [HomepageResponseMetricKeys.TotalSales]:
            summary?.profitabilityFields?.totalSales,
          [HomepageResponseMetricKeys.AdSpend]:
            summary?.advertisementFields?.adSpend,
          [HomepageResponseMetricKeys.AdSales]:
            summary?.advertisementFields?.adSales,
          [HomepageResponseMetricKeys.Tacos]: summary?.productFields?.tacos,
        },
      };
    }
    return businessData;
  });
};
