export enum UPLOAD_TYPE {
  WALMART_SP_CREATE_IN_ONE_GO = 'create_campaign',
  WALMART_SP_EDIT_CREATE_UPDATE = 'update_campaign',
  WALMART_SP_CREATE_UPDATE_KEYWORDS = 'keyword',
  WALMART_SP_CREATE_UPDATE_BID_MULTIPLIERS = 'bid_multiplier',
  WALMART_SBA_UPLOAD_IMAGE = 'upload_image',
  WALMART_SBA_CREATE_IN_ONE_GO = 'create_sba_campaign',
  WALMART_SBA_EDIT_CREATE_UPDATE = 'update_sba_campaign',
  WALMART_SBA_CREATE_UPDATE_KEYWORDS_AND_BIDS = 'sba_keyword',
  WALMART_SV_UPLOAD_VIDEO_AND_CAPTION = 'upload_video_caption',
  WALMART_SV_CREATE_SPONSORED_VIDEO = 'create_sp_video',
  WALMART_SV_CREATE_IN_ONE_GO = 'create_sv_campaign',
  WALMART_SV_EDIT_CREATE_UPDATE = 'update_sv_campaign',
  WALMART_SV_CREATE_UPDATE_KEYWORDS_AND_BIDS = 'sv_keyword',
  WALMART_BUDGET_MANAGEMENT_DAILY = 'budget_management',
  WALMART_BUDGET_MANAGEMENT_MONTLY = 'advertiser_budget',
  WALMART_DAY_PARTING_CREATE_UPDATE = 'walmart_dayparting',
  WALMART_SP_CHECK_BASE_VARIANT_ITEMS = 'base_variant_items_check',
}

export enum UPLOAD_STATE {
  SUCCESS = 'success',
  FAILED = 'failed',
  INFO = 'info',
  INITIAL = '',
}

export enum UPLOAD_STATUS {
  INVALID_DOC = 'INVALID_DOC',
  VALIDATED = 'VALIDATED',
  UPLOADED = 'UPLOADED',
}

export interface HandleBulkActions {
  actionFunction: (params: any) => Promise<any>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadState: React.Dispatch<React.SetStateAction<UPLOAD_STATE>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  file: File | string;
  captionFile?: File | string | null;
  isVideoAndCaption?: boolean;
  isImage?: boolean;
}
