import {
  ColumnAlign,
  TableCellV2,
  TableCellV2Type,
} from '@teikametrics/tm-design-system';
import isNil from 'lodash/isNil';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { FlywheelTableV2ColumnProps } from '../../../containers/tableV2/types';
import {
  EstPreAdGrossMarginItem,
  EstPreAdGrossMarginTableFields,
} from '../../../lib/types/CampaignFlexibilitySharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../lib/types/I18nKey';
import { TableExtraProps } from '../EstAdGrossMarginTable';

export const Cell: React.FC<EstPreAdGrossMarginItem & TableExtraProps> = ({
  avgSellingPrice,
  selectedCurrency,
}) => {
  return (
    <TableCellV2
      tableCellData={{
        tableCellType: TableCellV2Type.Number,
        currentValue: isNil(avgSellingPrice) ? null : (
          <FormattedNumber
            value={avgSellingPrice}
            style="currency"
            currency={selectedCurrency}
            minimumFractionDigits={MONETARY_FRACTION_DIGITS}
            maximumFractionDigits={MONETARY_FRACTION_DIGITS}
          />
        ),
      }}
      className={'justify-center items-end h-full'}
    />
  );
};

Cell.displayName = 'estPreAdGrossMargin_avgUnitSellPrice';

export const avgUnitSellPriceColumn: FlywheelTableV2ColumnProps<
  EstPreAdGrossMarginItem,
  {},
  TableExtraProps,
  {}
> = {
  header: I18nKey.CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_TABLE_COLUMN_UNIT_PRICE,
  accessorKey: EstPreAdGrossMarginTableFields.avgUnitSellPrice,
  size: 108,
  maxSize: 108,
  minSize: 108,
  sortable: false,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell {...original} {...meta} />,
};
