import { DateTime } from 'luxon';

import { TotalCountResponse } from '../../containers/tableV2/types';
import { StringMap } from '../types';
import { MerchantCountry } from '../types/Fam';
import { Sort } from '../types/Sort';
import { Filter } from '../types/Filter';

export interface PaginatedResponseExtraData {
  readonly externalEntityCount?: number;
  readonly smartEntityCount?: number;
}

export interface PaginatedResponse<T> extends PaginatedResponseExtraData {
  elements: T[];
  filteredElements: number;
}

export interface FilteredRequest {
  filters: Filter[];
  extraParams?: StringMap<string>;
  sorts?: Sort[];
}

export interface PageableRequest {
  sorts: Sort[];
  itemsPerPage: number;
  page: number;
}

export type PaginatedRequest = FilteredRequest & PageableRequest;

export interface PaginatedResult<T> extends PaginatedResponseExtraData {
  items: T[];
  totalItems: number;
  lastSyncedAt?: string;
}

export type PaginatedDataFetcher<T> = (
  request: PaginatedRequest
) => Promise<PaginatedResult<T>>;

export type FilteredDataFetcher<T> = (request: FilteredRequest) => Promise<T>;

export interface DateRange {
  readonly from: DateTime;
  readonly to: DateTime;
}

export interface DateRangeMoment {
  readonly from: DateTime;
  readonly to: DateTime;
}

export type DatedDataFetcher<T> = (dateRange: DateRangeMoment) => Promise<T>;
export type DataUpdater<T> = (
  payload: T,
  options?: { [x: string]: boolean }
) => Promise<boolean>;

export interface DataUpdaterCreator {
  endpoint: string;
}

export interface DataFetcherCreator {
  endpoint: string;
  headers?: object;
  baseURL?: string;
}

export interface MerchantCountriesResponse {
  readonly merchantCountryInfoItems?: MerchantCountry[];
}

export enum HttpResponseCode {
  'OK' = 200,
  'RequestTimeout' = 408,
  'PayloadTooLarge' = 413,
  'BadRequest' = 400,
}

export type TotalCountFetcher = (
  request: FilteredRequest
) => Promise<TotalCountResponse>;
