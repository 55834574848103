import {
  DateRange as PreviousDateRange,
  SelectOptionProps,
  TableCellV2Type,
} from '@teikametrics/tm-design-system';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  LaunchAdsConsoleOptionValue,
  MultiChannelDashboardColumns,
  MultiChannelDataSnapshot,
  MultiChannelMerchantInfo,
} from './types';
import {
  AMAZON_SALES_CHANNEL_ID,
  MAP_SALES_CHANNEL_ID_TO_NAME,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../lib/types/SalesChannels';
import { PropsWithChildren } from 'react';
import Skeleton from 'react-loading-skeleton';
import { MerchantCountry } from '../../../../lib/types/Fam';
import { FlywheelSalesChannel } from '../../../../lib/types/AOSharedTypes';
import orderBy from 'lodash/orderBy';
import { Sort } from '../../../../lib/types/Sort';

export const getPreviousPeriodDateRange = (
  startDate: DateTime,
  endDate: DateTime
): PreviousDateRange => {
  const previousPeriodEndDate: DateTime = startDate.minus({ days: 1 });
  const rangeBetweenDates = endDate.diff(startDate, 'days');
  const previousPeriodStartDate = previousPeriodEndDate.minus({
    days: rangeBetweenDates.days,
  });

  return {
    from: previousPeriodStartDate,
    to: previousPeriodEndDate,
  };
};

export const getLaunchAdsConsoleOptions = (
  intl: IntlShape,
  isAiEnabled: boolean
) => {
  const consoleOptions: SelectOptionProps<string>[] = [];
  if (isAiEnabled) {
    consoleOptions.push({
      label: intl.formatMessage({
        id: I18nKey.AO_DASHBOARD_LAUNCH_CONSOLE_OPTION_CAMPAIGN_CREATOR,
      }),
      value: LaunchAdsConsoleOptionValue.CAMPAIGN_CREATOR,
    });
  }
  consoleOptions.push(
    ...[
      {
        label: intl.formatMessage({
          id: I18nKey.AO_DASHBOARD_LAUNCH_CONSOLE_OPTION_AMAZON_CONSOLE,
        }),
        value: LaunchAdsConsoleOptionValue.AMAZON_CONSOLE,
      },
      {
        label: intl.formatMessage({
          id: I18nKey.AO_DASHBOARD_LAUNCH_CONSOLE_OPTION_WALMART_CONSOLE,
        }),
        value: LaunchAdsConsoleOptionValue.WALMART_CONSOLE,
      },
    ]
  );
  return consoleOptions;
};

export const multichannelAdvertisingDashboardGlossaryConfig = () => [
  {
    columns: [
      {
        name: I18nKey.AO_DASHBOARD_TABLE_COLUMN_TOTAL_SALES,
        description: I18nKey.AO_DASHBOARD_GLOSSARY_TOTAL_SALES_DEFINITION,
        formula: I18nKey.AO_DASHBOARD_GLOSSARY_TOTAL_SALES_FORMULA,
      },
      {
        name: I18nKey.AO_DASHBOARD_TABLE_COLUMN_AD_SALES,
        description: I18nKey.AO_DASHBOARD_GLOSSARY_AD_SALES_DEFINITION,
        formula: I18nKey.AO_DASHBOARD_GLOSSARY_AD_SALES_FORMULA,
      },
      {
        name: I18nKey.AO_DASHBOARD_TABLE_COLUMN_ROAS,
        description: I18nKey.AO_DASHBOARD_GLOSSARY_ROAS_DEFINITION,
        formula: I18nKey.AO_DASHBOARD_GLOSSARY_ROAS_FORMULA,
      },
      {
        name: I18nKey.AO_DASHBOARD_TABLE_COLUMN_TACOS,
        description: I18nKey.AO_DASHBOARD_GLOSSARY_TACOS_DEFINITION,
        formula: I18nKey.AO_DASHBOARD_GLOSSARY_TACOS_FORMULA,
      },
      {
        name: I18nKey.AO_DASHBOARD_TABLE_COLUMN_AD_SPEND,
        description: I18nKey.AO_DASHBOARD_GLOSSARY_AD_SPEND_DEFINITION,
        formula: I18nKey.AO_DASHBOARD_GLOSSARY_AD_SPEND_FORMULA,
      },
      {
        name: I18nKey.AO_DASHBOARD_TABLE_COLUMN_PERCENTAGE_OF_TOTAL_AD_SPEND,
        formula:
          I18nKey.AO_DASHBOARD_GLOSSARY_PERCENTAGE_OF_TOTAL_AD_SPEND_FORMULA,
      },
    ],
  },
];

export const getInitialsFromName = (name?: string) => {
  if (!name) {
    return '';
  }

  return name
    .split(' ')
    .map((word) => (word[0] ? word[0].toUpperCase() : ''))
    .join('');
};

export const getLoadingMultiChannelSnapshotData = (
  selectedMerchantCountries: MultiChannelMerchantInfo[]
): MultiChannelDataSnapshot[] => {
  const amazonMerchants = selectedMerchantCountries.filter(
    (e) => e.salesChannelId === AMAZON_SALES_CHANNEL_ID
  );
  const walmartMerchants = selectedMerchantCountries.filter(
    (e) => e.salesChannelId === WALMART_SALES_CHANNEL_ID
  );

  const buildAdTypeLevelInfo = () => {
    return {
      adType: '',
      salesChannelId: '',
      salesChannelName: '',
      merchantCountryId: '',
      performance: {
        current: {},
        previous: {},
      },
    };
  };

  const buildMerchantLevelInfo = (channelId: string) => {
    return {
      performance: { current: {}, previous: {} },
      merchantCountryId: '',
      merchantCountryName: '',
      salesChannelId: channelId,
      salesChannelName: MAP_SALES_CHANNEL_ID_TO_NAME[channelId],
      merchantType: '',
      advertisingTypeMetrics:
        channelId === AMAZON_SALES_CHANNEL_ID
          ? [
              // For Amazon 3 Ad Types SP, SB and SD
              buildAdTypeLevelInfo(),
              buildAdTypeLevelInfo(),
              buildAdTypeLevelInfo(),
            ]
          : [
              // For Walmart 2 Ad Types SP & SBA
              buildAdTypeLevelInfo(),
              buildAdTypeLevelInfo(),
            ],
    };
  };

  const loadingResponse: MultiChannelDataSnapshot[] = [];

  if (amazonMerchants.length > 0) {
    loadingResponse.push({
      salesChannelId: AMAZON_SALES_CHANNEL_ID,
      merchantCountries: amazonMerchants.map((e) => {
        return buildMerchantLevelInfo(AMAZON_SALES_CHANNEL_ID);
      }),
      performance: { current: {}, previous: {} },
    });
  }
  if (walmartMerchants.length > 0) {
    loadingResponse.push({
      salesChannelId: WALMART_SALES_CHANNEL_ID,
      merchantCountries: walmartMerchants.map((e) =>
        buildMerchantLevelInfo(WALMART_SALES_CHANNEL_ID)
      ),
      performance: { current: {}, previous: {} },
    });
  }

  const updatedResp: MultiChannelDataSnapshot[] = [];
  // Add required subrows for the table to know the indentation
  loadingResponse.forEach((item) => {
    updatedResp.push({
      ...item,
      subRows: item.merchantCountries.map((merchantCountry) => {
        return {
          ...merchantCountry,
          subRows: merchantCountry.advertisingTypeMetrics,
        };
      }),
    });
  });

  return updatedResp;
};

export const getLoadingSkeleton = (type: TableCellV2Type) => {
  const SkeletonWrapper = (props: PropsWithChildren<{}>) => (
    <div className="h-12">{props.children}</div>
  );
  const warpperClassName =
    'h-full w-full px-12 py-8 flex flex-col gap-8 justify-center';
  if (type === TableCellV2Type.MultiIconWithText) {
    return (
      <div className={warpperClassName}>
        <Skeleton height={24} style={{ borderRadius: 0 }} count={1} />
      </div>
    );
  } else if (type === TableCellV2Type.Comparison) {
    return (
      <div className={warpperClassName}>
        <Skeleton
          height={12}
          style={{ borderRadius: 0 }}
          count={1}
          wrapper={SkeletonWrapper}
        />
        <Skeleton
          height={10}
          style={{ borderRadius: 0, width: '50%' }}
          count={1}
          wrapper={SkeletonWrapper}
        />
      </div>
    );
  } else if (type === TableCellV2Type.Number) {
    return (
      <div className={warpperClassName}>
        <Skeleton
          height={12}
          style={{ borderRadius: 0 }}
          count={1}
          wrapper={SkeletonWrapper}
        />
      </div>
    );
  }
  return <></>;
};

export const getMerchantDetailsForMultiChannelRequest = (
  merchantCountries: string[],
  merchants: MerchantCountry[]
): MultiChannelMerchantInfo[] => {
  return merchantCountries.map((mcId) => {
    const merchant = merchants.find((mc) => mc.merchantCountryId === mcId);
    return {
      merchantCountryId: mcId,
      salesChannelId: merchant?.salesChannelId!,
      salesChannelName:
        merchant?.salesChannelId === AMAZON_SALES_CHANNEL_ID
          ? FlywheelSalesChannel.Amazon
          : FlywheelSalesChannel.Walmart,
      merchantType: merchant?.merchantType!,
      country: merchant?.country!,
      merchantCountryName: merchant?.merchantName!,
    };
  });
};

export const getMultiChannelSortedRowData = (
  rowData: any[],
  sort?: Sort
): MultiChannelDataSnapshot[] => {
  if (!sort) {
    return rowData;
  }
  const getValue = (value?: string | number) => {
    if (value === null) {
      return -1;
    }
    return value ? parseFloat(value.toString()) : 0;
  };

  const primarySort = (e: any) => {
    const currentValue = e.performance.current;

    if (!currentValue) {
      return -1;
    }

    switch (sort.column) {
      case MultiChannelDashboardColumns.TotalSales: {
        return getValue(currentValue.totalSales?.amount);
      }
      case MultiChannelDashboardColumns.AdSales: {
        return getValue(currentValue.adSalesTotal?.amount);
      }
      case MultiChannelDashboardColumns.Roas: {
        return getValue(currentValue.roasTotal);
      }
      case MultiChannelDashboardColumns.Tacos: {
        return getValue(currentValue.tacosTotal);
      }
      case MultiChannelDashboardColumns.AdSpend: {
        return getValue(currentValue.adSpend?.amount);
      }
      case MultiChannelDashboardColumns.PercentOfTotalAdSpend: {
        return getValue(currentValue.adSpendPercentage);
      }
      default: {
        return 0;
      }
    }
  };

  const secondarySort = (e: any) => {
    const currentValue = e.performance.current;
    if (!currentValue) {
      return -1;
    }

    return getValue(currentValue.adSalesTotal?.amount);
  };

  return orderBy(rowData, [primarySort, secondarySort], [sort.direction]).map(
    (data) => ({
      ...data,
      subRows: data.subRows
        ? getMultiChannelSortedRowData(data.subRows, sort)
        : [],
    })
  );
};
