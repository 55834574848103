import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { SbAdsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { SB_ADS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<SbAdsDetails> = ({
  creativeDetails,
  creativePerformance,
  creativeId,
  channelSettings,
}) => {
  const creativeData: SbAdsDetails = {
    creativeDetails,
    creativePerformance,
    creativeId,
    channelSettings,
  };

  return makeTextColumn(
    ({ creativeDetails }: SbAdsDetails) =>
      creativeDetails?.creativeName ? creativeDetails?.creativeName : undefined,
    undefined,
    undefined,
    true
  )(creativeData);
};
RowCellElement.displayName = 'RowCellElement';

export const creativeNameColumn: FlywheelTableColumn<SbAdsDetails> = {
  columnName: SB_ADS_API_COLUMN_NAME.Name,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_SB_ADS_TABLE_COLUMN_CREATIVE_NAME,
  RowCellElement,
  gridColumnWidth: '240px',
};

export const creativeNameFilter = createStringDataFieldFilter(
  SB_ADS_API_COLUMN_NAME.Name,
  I18nKey.ADS_MANAGER_SB_ADS_TABLE_COLUMN_CREATIVE_NAME,
  isValidString()
);
