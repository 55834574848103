import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  AmazonLogomarkEnabledIcon as AmazonIcon,
  ArrowRightIcon,
  Icon,
  IconSize,
  SvgIcon,
  WalmartLogomarkEnabledIcon as WalmartIcon,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../lib/types/I18nKey';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../lib/types/SalesChannels';
import { ConnectModalWrapper } from './ConnectModalWrapper';
import { NeedHelp } from '../../../components/NeedHelp';
import { INTERCOM_AMAZON_ONBOARDING_HELP } from './constants';
import { FlywheelSalesChannel } from '../../../lib/types/Fam';

export type SalesChannel = {
  id: string;
  icon: SvgIcon;
  tabName: I18nKey;
  headerI18nKey: I18nKey;
  name: FlywheelSalesChannel;
};

export const DEFAULT_SALES_CHANNELS: SalesChannel[] = [
  {
    id: AMAZON_SALES_CHANNEL_ID,
    icon: AmazonIcon,
    tabName: I18nKey.ADVERTISING_OPTIMIZATION_AMAZON,
    headerI18nKey: I18nKey.CONNECT_ADS_MODAL_AMAZON_HEADER,
    name: FlywheelSalesChannel.Amazon,
  },
  {
    id: WALMART_SALES_CHANNEL_ID,
    icon: WalmartIcon,
    tabName: I18nKey.ADVERTISING_OPTIMIZATION_WALMART,
    headerI18nKey: I18nKey.CONNECT_ADS_MODAL_WALMART_HEADER,
    name: FlywheelSalesChannel.Walmart,
  },
];

interface ConnectSalesChannelModalProps {
  readonly salesChannels: SalesChannel[];
  readonly isOpen: boolean;
  readonly selectSalesChannel: (channel: SalesChannel) => void;
  readonly closeModal?: () => void;
}

export const ConnectSalesChannelModal: React.FC<
  ConnectSalesChannelModalProps
> = ({ salesChannels, isOpen, selectSalesChannel, closeModal }) => {
  const intl = useIntl();

  return (
    <ConnectModalWrapper
      dataTestId="connect_sales_channel_modal2"
      isOpen={isOpen}
      headerText={intl.formatMessage({
        id: I18nKey.PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_HEADER_CONNECT_DATA,
      })}
      content={
        <div className="my-24">
          <div className="mb-24 text-lg font-medium leading-normal text-grey-900">
            <FormattedMessage
              id={
                I18nKey.PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_SUB_HEADER_CONNECT_DATA
              }
            />
          </div>
          <div
            className={classNames(
              'rounded border border-solid border-grey-200',
              'text-lg text-grey-900 font-semibold leading-none',
              'cursor-pointer overflow-hidden'
            )}
          >
            {salesChannels.map((channel: SalesChannel, idx: number) => {
              return (
                <div
                  key={channel.name}
                  className={classNames(
                    `flex justify-between items-center h-56 px-16 group hover:bg-purple-50`,
                    {
                      'border-b border-solid border-grey-200':
                        idx !== salesChannels.length - 1,
                    }
                  )}
                  role="button"
                  onClick={() => selectSalesChannel(channel)}
                  data-test-id={`flash1_sales_channel_${channel.name}`}
                >
                  <span>
                    <Icon
                      svg={channel.icon}
                      size={IconSize.Large}
                      className="mr-8"
                    />
                    {intl.formatMessage({ id: channel.tabName })}
                  </span>
                  <Icon
                    svg={ArrowRightIcon}
                    size={IconSize.Small}
                    className="text-grey-400"
                  />
                </div>
              );
            })}
          </div>
        </div>
      }
      showFooterSeparator={false}
      footer={
        <NeedHelp
          url={INTERCOM_AMAZON_ONBOARDING_HELP}
          dataTestId="connect_sales_channel_modal_2"
        />
      }
      closeModal={closeModal}
    />
  );
};

ConnectSalesChannelModal.displayName = 'ConnectSalesChannelModal';
