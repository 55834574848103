import axios, { CancelTokenSource, RawAxiosRequestConfig } from 'axios';

import { IdToken } from '@auth0/auth0-react';

import { IBlog } from '../../lib/types/RSSFeed';
import { TmIndexDataResponse } from '../../modules/compass';
import { BusinessPerformanceColumnName } from '../../modules/compass/containers/compassDashboard/utils/constants';
import {
  BusinessPerformanceData,
  CampaignMetrics,
  MerchantMetrics,
  RankedBy,
} from '../../modules/compass/containers/compassDashboard/utils/performance';
import { DateRange } from '../../modules/compass/types';
import {
  AiStatisticsDataV2,
  AiStatisticsGraphData,
  BusinessPerformanceDataRequest,
  HomePageData,
  HomePageDataRequest,
  SeasonalEvent,
  TmIndexDataRequest,
} from '../types/CompassSharedTypes';
import { SortOrder } from '../types/Sort';
import { getDefaultHeaders } from './';

const COMPASS_BASE_URL = process.env.REACT_APP_SKU_HOST;
const AO_BASE_URL = process.env.REACT_APP_AO_HOST;
const RSS_FEED_URL = process.env.RSS_FEED_URL ?? '';

export interface CompassApiClient {
  readonly getHomePageData: (
    accountId: string
  ) => (
    request: HomePageDataRequest,
    controller?: AbortController,
    source?: CancelTokenSource
  ) => Promise<HomePageData>;
  readonly getAiStatisticsDataV2: (
    accountId: string,
    merchantCountryIds: string[],
    startDate: string,
    endDate: string
  ) => Promise<AiStatisticsDataV2>;
  readonly getAiStatisticsGraphData: (
    accountId: string,
    merchantCountryIds: string[],
    startDate: string,
    endDate: string,
    periodicity: string
  ) => Promise<AiStatisticsGraphData>;
  readonly getMerchantsPerformanceData: (
    accountId: string
  ) => (
    request: BusinessPerformanceDataRequest,
    rankedBy: RankedBy
  ) => Promise<BusinessPerformanceData<MerchantMetrics>>;
  readonly getCampaignsPerformanceData: (
    accountId: string
  ) => (
    request: BusinessPerformanceDataRequest,
    rankedBy: RankedBy
  ) => Promise<BusinessPerformanceData<CampaignMetrics>>;
  readonly getSeasonalEvents: (
    accountId: string,
    dateRange: DateRange
  ) => Promise<SeasonalEvent[]>;
  readonly getTmIndexMetricsData: (
    accountId: string
  ) => (request: TmIndexDataRequest) => Promise<TmIndexDataResponse>;
  readonly getBlogsRSSFeed: () => Promise<IBlog[]>;
}

export const PATHS = Object.freeze({
  HOMEPAGE_DATA: (accountId: string) =>
    `accounts/${accountId}/compass/business/dataPoints`,
  AI_STATISTICS_DATA_V2: (accountId: string) =>
    `accounts/${accountId}/v2/aiStatistics/bids`,
  AI_STATISTICS_DATA_V2_GRAPH_DATA: (accountId: string) =>
    `accounts/${accountId}/v2/aiStatistics/bids/dataPoints`,
  MERCHANTS_PERFORMANCE_DATA: (accountId: string) =>
    `accounts/${accountId}/compass/merchant-countries/data/snapshot`,
  CAMPAIGNS_PERFORMANCE_DATA: (accountId: string) =>
    `accounts/${accountId}/campaigns/data/snapshot`,
  SEASONAL_EVENTS: (accountId: string) =>
    `accounts/${accountId}/compass/business/seasonal-events`,
  TM_INDEX_DATA: (accountId: string) =>
    `accounts/${accountId}/compass/sales-channel/benchmark/metrics`,
});

export const createCompassApiClient = (
  token: IdToken | null
): CompassApiClient => {
  const config: RawAxiosRequestConfig = {
    headers: {
      ...getDefaultHeaders(),
      Authorization: `Bearer ${token?.__raw}`,
    },
    baseURL: COMPASS_BASE_URL,
  };

  const getHomePageData =
    (accountId: string) =>
    async (
      request: HomePageDataRequest,
      controller?: AbortController,
      source?: CancelTokenSource
    ) => {
      const signal = controller?.signal;
      const response = await axios.post<HomePageData>(
        PATHS.HOMEPAGE_DATA(accountId),
        request,
        {
          ...config,
          signal,
          cancelToken: source?.token,
        }
      );

      return response.data;
    };

  const getAiStatisticsDataV2 = async (
    accountId: string,
    merchantCountryIds: string[],
    startDate: string,
    endDate: string
  ) => {
    const requestBody = {
      merchantCountryIds,
      startDate,
      endDate,
    };
    const response = await axios.post<AiStatisticsDataV2>(
      PATHS.AI_STATISTICS_DATA_V2(accountId),
      requestBody,
      { ...config, baseURL: AO_BASE_URL }
    );
    return response.data;
  };

  const getAiStatisticsGraphData = async (
    accountId: string,
    merchantCountryIds: string[],
    startDate: string,
    endDate: string,
    periodicity: string
  ) => {
    const requestBody = {
      merchantCountryIds,
      startDate,
      endDate,
      periodicity,
    };
    const response = await axios.post<AiStatisticsGraphData>(
      PATHS.AI_STATISTICS_DATA_V2_GRAPH_DATA(accountId),
      requestBody,
      { ...config, baseURL: AO_BASE_URL }
    );
    return response.data;
  };

  const getMerchantsPerformanceData =
    (accountId: string) =>
    async (request: BusinessPerformanceDataRequest, rankedBy: RankedBy) => {
      const response = await axios.post<
        BusinessPerformanceData<MerchantMetrics>
      >(PATHS.MERCHANTS_PERFORMANCE_DATA(accountId), request, {
        ...config,
        params: {
          sort: `${BusinessPerformanceColumnName.TotalSales},${
            rankedBy === RankedBy.LowPerformers ? SortOrder.Asc : SortOrder.Desc
          }`,
          limit: 5,
          offset: 0,
        },
      });
      return response.data;
    };

  const getCampaignsPerformanceData =
    (accountId: string) =>
    async (request: BusinessPerformanceDataRequest, rankedBy: RankedBy) => {
      const response = await axios.post<
        BusinessPerformanceData<CampaignMetrics>
      >(PATHS.CAMPAIGNS_PERFORMANCE_DATA(accountId), request, {
        ...config,
        baseURL: AO_BASE_URL,
        params: {
          sort: `${BusinessPerformanceColumnName.AdSalesTotal},${
            rankedBy === RankedBy.LowPerformers ? SortOrder.Asc : SortOrder.Desc
          }`,
          limit: 6,
          offset: 0,
        },
      });
      return response.data;
    };

  const getSeasonalEvents = async (accountId: string, dateRange: DateRange) => {
    const params = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };
    const response = await axios.post<SeasonalEvent[]>(
      PATHS.SEASONAL_EVENTS(accountId),
      params,
      {
        ...config,
      }
    );
    return response.data;
  };

  const getTmIndexMetricsData =
    (accountId: string) => async (request: TmIndexDataRequest) => {
      try {
        const response = await axios.post<TmIndexDataResponse>(
          PATHS.TM_INDEX_DATA(accountId),
          request,
          config
        );
        return response.data;
      } catch {
        return Promise.resolve({ currency: '', elements: [] });
      }
    };

  const getBlogsRSSFeed = async () => {
    const rssFeedResponse = await axios.get<
      IBlog[],
      { readonly data: IBlog[] }
    >(RSS_FEED_URL);

    return rssFeedResponse.data;
  };

  return {
    getHomePageData,
    getAiStatisticsDataV2,
    getAiStatisticsGraphData,
    getMerchantsPerformanceData,
    getCampaignsPerformanceData,
    getSeasonalEvents,
    getTmIndexMetricsData,
    getBlogsRSSFeed,
  };
};
