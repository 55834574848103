import './styles.scss';

import classNames from 'classnames';
import noop from 'lodash/noop';
import React from 'react';

import { Spinner } from '@teikametrics/tm-design-system';

export enum ButtonState {
  'Enabled' = 'Enabled',
  'Disabled' = 'Disabled',
  'Loading' = 'Loading',
}

interface ButtonProps {
  /**
   * additional classes for button styling
   */
  readonly className?: string;
  /**
   * Button State
   * @default Enabled
   */
  readonly state?: ButtonState;
  /**
   * on click handler
   * @default noop
   */
  readonly onClick?: () => void;
  /**
   * children react children
   */
  readonly children?: React.ReactNode;
  /**
   * option data-test-id for tracking purposes
   */
  readonly dataTestId?: string;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    state = ButtonState.Enabled,
    onClick = noop,
    dataTestId,
  } = props;
  const isDisabled = state === ButtonState.Disabled;
  const isLoading = state === ButtonState.Loading;
  const buttonIsDisabled = isDisabled || isLoading;

  return (
    <button
      type="button"
      disabled={buttonIsDisabled}
      className={classNames([
        'button',
        {
          'button--disabled': isDisabled && !isLoading,
          'button--loading': isLoading,
        },
        props.className,
      ])}
      onClick={onClick}
      data-test-id={`${dataTestId}_button`}
    >
      <div
        className="button_content"
        style={{ opacity: isLoading ? '0' : 'initial' }}
      >
        {children}
      </div>
      {isLoading && <Spinner />}
    </button>
  );
};
Button.displayName = 'Button';

export default Button;
