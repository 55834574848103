import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { IdToken } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppMessageBanner,
  AppMessageBannerVariant,
} from '@teikametrics/tm-design-system';

import { SubscriptionContext } from '../../containers/subscriptionProvider';
import {
  getCurrentAccountFromContext,
  getDaysSinceTrialEnded,
  getFreeTrialDaysRemaining,
  getGracePeriodDate,
  isAiSubscribed,
} from '../../containers/userProvider/selectors';
import { UserContextState } from '../../containers/userProvider/userProvider';
import I18nKey from '../../lib/types/I18nKey';
import {
  SalesChannelConnectionResponse,
  getConnectionStatus,
} from '../../lib/utilities/connectionStatus';
import { NavPaths } from '../../NavPaths';
import { TrialBannerStatus, getTrialBannerState } from './bannerStates';
import {
  addDismissedBannerToStorage,
  getDismissedBannersFromStorage,
} from '../../lib/utilities/storageUtils';

export interface TrialBannerProps {
  idToken: IdToken;
  userContext: UserContextState;
}

export const TrialBanner: React.FC<TrialBannerProps> = ({
  idToken,
  userContext,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState<boolean>(false);
  const [dismissedBanners, setDismissedBanners] = useState<string[]>([]);

  const [bannerStatus, setBannerStatus] = useState<TrialBannerStatus | null>(
    null
  );

  const account = getCurrentAccountFromContext(userContext);
  const accountId = account!.id;

  const { salesChannelData } = useContext(SubscriptionContext);

  const daysRemainingInTrial =
    (account && getFreeTrialDaysRemaining(account)) || 0;

  const daysRemainingUntilEnforcement = Math.max(
    0,
    30 - getDaysSinceTrialEnded(account)
  );

  const isAccountLockedOut = !!account?.lockedAt;
  const isDelinquent = userContext?.billingInfo?.isDelinquent;

  const gracePeriod = getGracePeriodDate(userContext);

  const [
    TRIAL_FIRST_DAY_MESSAGE,
    TRIAL_HAPPENING_PASSIVE_MESSAGE,
    TRIAL_HAPPENING_ALERT_MESSAGE,
    TRIAL_HAPPENING_ALERT_LINK,
    TRIAL_HAPPENING_ALERT_EXTRA,
    TRIAL_AUTOMATIC_DOWNGRADE_LINK,
    TRIAL_AUTOMATIC_DOWNGRADE_EXTRA,
    TRIAL_DELINQUENT_LINK,
    TRIAL_ACCOUNT_LOCKOUT_LINK,
    TRIAL_OVER_LINK,
    DOWNGRADED_LINK,
  ] = [
    I18nKey.BANNER_TRIAL_FIRST_DAY_MESSAGE,
    I18nKey.BANNER_TRIAL_HAPPENING_PASSIVE_MESSAGE,
    I18nKey.BANNER_TRIAL_HAPPENING_ALERT_MESSAGE,
    I18nKey.BANNER_TRIAL_HAPPENING_ALERT_LINK,
    I18nKey.BANNER_TRIAL_HAPPENING_ALERT_EXTRA,
    I18nKey.BANNER_TRIAL_AUTOMATIC_DOWNGRADE_LINK,
    I18nKey.BANNER_TRIAL_AUTOMATIC_DOWNGRADE_EXTRA,
    I18nKey.BANNER_TRIAL_DELINQUENT_LINK,
    I18nKey.BANNER_TRIAL_ACCOUNT_LOCKOUT_LINK,
    I18nKey.BANNER_TRIAL_TRIAL_OVER_LINK,
    I18nKey.BANNER_TRIAL_DOWNGRADED_LINK,
  ].map((id) =>
    intl.formatMessage(
      { id },
      { daysRemaining: daysRemainingInTrial, daysRemainingUntilEnforcement }
    )
  );

  const handleLinkClick = () => {
    if (isDelinquent || isAccountLockedOut) {
      navigate(location, {
        state: { openDelinquentModal: true },
      });
    } else if (
      bannerStatus === TrialBannerStatus.HappeningAlert ||
      bannerStatus === TrialBannerStatus.AutomaticDowngrade ||
      bannerStatus === TrialBannerStatus.TrialEnded ||
      bannerStatus === TrialBannerStatus.TrialEndedAlert ||
      bannerStatus === TrialBannerStatus.Downgraded
    ) {
      navigate(NavPaths.Billing, {
        state: { openiFrame: true, selectPaymentTab: true },
      });
    }
  };

  const BANNER_PROPS = {
    [TrialBannerStatus.Downgraded]: {
      message: intl.formatMessage(
        { id: I18nKey.BANNER_TRIAL_DOWNGRADED_MESSAGE },
        {
          credit_card_link: (
            <a
              className="ml-4 mt-2 font-semibold underline cursor-pointer hover:text-purple-800"
              onClick={handleLinkClick}
            >
              {DOWNGRADED_LINK}
            </a>
          ),
        }
      ),
      link: '',
      afterLink: '',
      variant: AppMessageBannerVariant.Alert,
    },
    [TrialBannerStatus.FirstDay]: {
      message: TRIAL_FIRST_DAY_MESSAGE,
      link: '',
      afterLink: '',
      variant: AppMessageBannerVariant.Purple,
    },
    [TrialBannerStatus.Happening]: {
      message: TRIAL_HAPPENING_PASSIVE_MESSAGE,
      link: '',
      afterLink: '',
      variant: AppMessageBannerVariant.Purple,
    },
    [TrialBannerStatus.HappeningAlert]: {
      message: TRIAL_HAPPENING_ALERT_MESSAGE,
      link: TRIAL_HAPPENING_ALERT_LINK,
      afterLink: TRIAL_HAPPENING_ALERT_EXTRA,
      variant: AppMessageBannerVariant.Alert,
    },
    [TrialBannerStatus.Delinquent]: {
      message: intl.formatMessage(
        { id: I18nKey.BANNER_TRIAL_DELINQUENT_MESSAGE },
        {
          update_payment_method_link: (
            <a
              className="ml-4 mt-2 font-semibold underline cursor-pointer hover:text-purple-800"
              onClick={handleLinkClick}
            >
              {TRIAL_DELINQUENT_LINK}
            </a>
          ),
          gracePeriod,
        }
      ),
      link: '',
      afterLink: '',
      variant: AppMessageBannerVariant.Alert,
    },
    [TrialBannerStatus.LockedOut]: {
      message: intl.formatMessage(
        { id: I18nKey.BANNER_TRIAL_ACCOUNT_LOCKOUT_MESSAGE },
        {
          pay_outstanding_balance_link: (
            <a
              className="ml-4 mt-2 font-semibold underline cursor-pointer hover:text-purple-800"
              onClick={handleLinkClick}
            >
              {TRIAL_ACCOUNT_LOCKOUT_LINK}
            </a>
          ),
        }
      ),
      link: '',
      afterLink: '',
      variant: AppMessageBannerVariant.Alert,
    },
    [TrialBannerStatus.AutomaticDowngrade]: {
      message: TRIAL_HAPPENING_ALERT_MESSAGE,
      link: TRIAL_AUTOMATIC_DOWNGRADE_LINK,
      afterLink: TRIAL_AUTOMATIC_DOWNGRADE_EXTRA,
      variant: AppMessageBannerVariant.Alert,
    },
    [TrialBannerStatus.TrialEnded]: {
      message: intl.formatMessage(
        { id: I18nKey.BANNER_TRIAL_TRIAL_OVER_MESSAGE },
        {
          credit_card_link: (
            <a
              className="ml-4 mt-2 font-semibold underline cursor-pointer hover:text-purple-800"
              onClick={handleLinkClick}
            >
              {TRIAL_OVER_LINK}
            </a>
          ),
        }
      ),
      link: '',
      afterLink: '',
      variant: AppMessageBannerVariant.Purple,
    },
    [TrialBannerStatus.TrialEndedAlert]: {
      message: intl.formatMessage(
        { id: I18nKey.BANNER_TRIAL_TRIAL_OVER_MESSAGE },
        {
          credit_card_link: (
            <a
              className="ml-4 mt-2 font-semibold underline cursor-pointer hover:text-purple-800"
              onClick={handleLinkClick}
            >
              {TRIAL_OVER_LINK}
            </a>
          ),
        }
      ),
      link: '',
      afterLink: '',
      variant: AppMessageBannerVariant.Alert,
    },
  };

  useEffect(() => {
    loadConnectionStatus();
  }, [location.pathname]);

  useEffect(() => {
    salesChannelData.length > 0 && loadConnectionStatus();
  }, [userContext, salesChannelData]);

  useEffect(() => {
    navigate(location, {
      state: { openDelinquentModal: isDelinquent },
    });
  }, [isDelinquent]);

  const loadConnectionStatus = async () => {
    setError(false);
    try {
      const connection: SalesChannelConnectionResponse =
        await getConnectionStatus(idToken, accountId, salesChannelData);

      const status: TrialBannerStatus = getTrialBannerState({
        account,
        billingInfo: userContext?.billingInfo,
        billingEstimate: userContext?.billingEstimate,
        adStatus: connection.ads,
        isAiSubscribed: isAiSubscribed(userContext),
      });

      setBannerStatus(status);

      setDismissedBanners(getDismissedBannersFromStorage());
    } catch (error) {
      setError(true);
    }
  };

  if (
    error ||
    !bannerStatus ||
    dismissedBanners.includes(bannerStatus) ||
    !userContext.isBillingLoaded
  ) {
    return null;
  }

  const handleDismiss = () => {
    setDismissedBanners((prevState) => [...prevState, bannerStatus]);
    addDismissedBannerToStorage(bannerStatus);
  };

  if (bannerStatus === TrialBannerStatus.None) {
    return <></>;
  }

  const { message, link, variant, afterLink } = BANNER_PROPS[bannerStatus];

  return (
    <div id="trial-banner">
      <AppMessageBanner
        dismissable={false}
        variant={variant}
        content={
          <>
            {message && <span className="font-medium">{message}</span>}
            <a
              className="mx-4 mt font-semibold underline cursor-pointer hover:text-purple-800"
              onClick={handleLinkClick}
            >
              {link}
            </a>
            {afterLink && <span className="font-medium">{afterLink}</span>}
          </>
        }
        onDismiss={handleDismiss}
      />
    </div>
  );
};
TrialBanner.displayName = 'TrialBanner';
