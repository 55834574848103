import {
  BadgeCampaignGoal,
  BadgeCampaignType,
  BadgeV2,
  BadgeV2Types,
  Button,
  ButtonSize,
  ButtonVariant,
  CampaignAdGroup,
  CampaignAdGroupVariant,
  KeywordRoutingType,
  Modal,
  SearchInput,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getCurrentAccountFromContext } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { createAOApiClient } from '../../../../lib/clients/AOApiClient';
import { PaginatedResponse } from '../../../../lib/clients/types';

import first from 'lodash/first';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { useIntl } from 'react-intl';
import {
  AddedBy,
  CampaignTargetingType,
} from '../../../../lib/types/AOSharedTypes';
import { DEFAULT_CURRENCY } from '../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  AdGroupInfoV2,
  CampaignInfoWithAdGroups,
  KeywordAdgroupInfo,
  TargetSegments,
} from '../../../../lib/types/KeywordAction';
import { MAX_SLIDEOUT_RECORDS } from '../../../advertisingOptimization/containers/adsManager/createKeywords/createKeywordsProvider';
import { isManualCampaign } from '../../../advertisingOptimization/containers/adsManager/utils';
import isEmpty from 'lodash/isEmpty';

export interface NewDestinationAdGroupModalProps {
  readonly isOpen: boolean;
  readonly keyword: string;
  readonly defaultAdGroup?: KeywordAdgroupInfo;
  readonly merchantCountryId: string;
  readonly isNegativeKeyword?: boolean;
  readonly onClose?: () => void;
  readonly onApplyChange: (adGroupInfo: KeywordAdgroupInfo) => void;
  readonly hideKeywordRoutingInHeader?: boolean;
}

export const NewDestinationAdGroupModal: React.FC<
  NewDestinationAdGroupModalProps
> = (props) => {
  const {
    isOpen,
    keyword,
    merchantCountryId,
    defaultAdGroup,
    isNegativeKeyword = false,
    onApplyChange,
    hideKeywordRoutingInHeader = false,
  } = props;

  const intl = useIntl();

  const userContext = useContext<UserContextState>(UserContext);
  const { id: accountId } = getCurrentAccountFromContext(userContext)!;

  const aoApiClient = createAOApiClient(userContext.userInfo.idToken!);

  const [searchValue, setSearchValue] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasSearchResults, setHasSearchResults] = useState<boolean>(false);
  const [selectedCampaignWithAdGroup, setSelectedCampaignWithAdGroup] =
    useState<CampaignInfoWithAdGroups | undefined>();
  const [eligibleAdGroups, setEligibleAdGroups] =
    useState<PaginatedResponse<CampaignInfoWithAdGroups>>();
  const [selectedAdGroup, setSelectedAdGroup] = useState<AdGroupInfoV2>();

  useEffect(() => {
    if (defaultAdGroup) {
      const campaign: any = {
        ...defaultAdGroup?.campaign,
        adGroups: [defaultAdGroup],
        selectedAdGroupIds: [defaultAdGroup.id],
      };

      setSelectedAdGroup(defaultAdGroup as AdGroupInfoV2);
      setSelectedCampaignWithAdGroup(campaign);
    }
  }, [defaultAdGroup]);
  const resetSearchingAndLoading = () => {
    setIsLoading(false);
    setSearching(false);
  };

  const fetchEligibleAdGroups = (searchText?: string) => {
    if (merchantCountryId) {
      setHasSearchResults(!!searchText);
      aoApiClient
        .eligibleAdGroupsV2ForKeywordTargets(
          accountId,
          merchantCountryId,
          DEFAULT_CURRENCY,
          searchText || '',
          MAX_SLIDEOUT_RECORDS,
          undefined,
          isNegativeKeyword
        )
        .then((resp) => {
          setEligibleAdGroups(resp.data);
          resetSearchingAndLoading();
        })
        .catch(() => {
          resetSearchingAndLoading();
        });
    }
  };

  const [SEARCH_INPUT_PLACEHOLDER] = [
    I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_PLACEHOLDER,
    I18nKey.GENERIC_APPLY,
    I18nKey.GENERIC_CANCEL_LABEL,
    I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_USE_SEARCH_BAR,
    I18nKey.BACK,
  ].map((id) =>
    intl.formatMessage({
      id,
    })
  );

  const onSearchInputClear = () => {
    setIsLoading(true);
    setSearchValue('');
    fetchEligibleAdGroups();
  };

  const onSearchButtonClick = (text: string) => {
    setSearching(true);
    fetchEligibleAdGroups(text);
  };

  const onChange = (newValue: string) => {
    if (!!newValue) {
      setSearchValue(newValue);
    } else {
      onSearchInputClear();
    }
  };

  const searchButtonInputProps = {
    label: intl.formatMessage({ id: I18nKey.SEARCH }),
    loading: searching,
    onSearchButtonClick,
    onChange,
    size: ButtonSize.Default,
  };

  const onApplySuggestion = () => {
    //If default ad group does not exist in initial list loaded, then pass through item that matches campaign id if it exists on submission
    if (defaultAdGroup && !isEmpty(eligibleAdGroups?.elements)) {
      const adGroupsIds: string[] = [];

      eligibleAdGroups?.elements?.forEach((campaign) =>
        adGroupsIds.push(campaign.adGroups[0].id)
      );

      const adGroupExistsInList = adGroupsIds?.includes(
        defaultAdGroup?.id as string
      );

      const campaignMatch = eligibleAdGroups?.elements.find(
        (campaign) => campaign.id === defaultAdGroup.campaign?.id
      );

      const newAdGroupSelected = defaultAdGroup.id !== selectedAdGroup?.id;

      if (campaignMatch && !adGroupExistsInList && !newAdGroupSelected) {
        onApplyChange({
          ...campaignMatch.adGroups[0]!,
          campaign: campaignMatch,
        });
      } else {
        onApplyChange({
          ...selectedAdGroup!,
          campaign: selectedCampaignWithAdGroup,
        });
      }
    } else {
      onApplyChange({
        ...selectedAdGroup!,
        campaign: selectedCampaignWithAdGroup,
      });
    }
    props.onClose && props.onClose();
  };

  const onCancelSuggestion = () => {
    onSearchInputClear();
  };

  useEffect(() => {
    if (isOpen && isUndefined(eligibleAdGroups)) {
      fetchEligibleAdGroups();
    }
  }, [isOpen]);

  const onAdgroupSelectionChange = (
    adGroup: AdGroupInfoV2,
    campaign: CampaignInfoWithAdGroups,
    checked?: boolean
  ) => {
    if (!checked) {
      setSelectedCampaignWithAdGroup({
        ...campaign,
        selectedAdGroupIds: [
          ...(campaign.selectedAdGroupIds || []),
          adGroup.id,
        ],
      });
    } else {
      setSelectedCampaignWithAdGroup({
        ...campaign,
        selectedAdGroupIds: (campaign.selectedAdGroupIds || []).filter(
          (id) => id !== adGroup.id
        ),
      });
    }
    setSelectedAdGroup(adGroup);
  };

  const selectedAdgroupId = first(
    selectedCampaignWithAdGroup?.selectedAdGroupIds
  );

  const shouldShowDefaultAdGroup =
    !!selectedAdgroupId &&
    selectedAdgroupId === defaultAdGroup?.id &&
    !hasSearchResults;

  const filteredCampaigns = useMemo(() => {
    if (shouldShowDefaultAdGroup) {
      return eligibleAdGroups?.elements
        .map((campaign) => ({
          ...campaign,
          adGroups: campaign.adGroups.filter(
            (adGroup) => adGroup.id !== selectedAdgroupId
          ),
        }))
        .filter((campaign) => campaign.adGroups.length > 0);
    }

    return eligibleAdGroups?.elements;
  }, [eligibleAdGroups, selectedAdgroupId, shouldShowDefaultAdGroup]);

  console.log(filteredCampaigns);

  return (
    <Modal
      className="w-740 max-w-752 min-h-640"
      showModal={isOpen}
      hidePadding
      onClose={props.onClose}
      maxModalWidth="max-w-752"
    >
      <div className="flex w-full gap-8 p-16 justify-center border-solid border-b border-grey-200">
        {hideKeywordRoutingInHeader ? null : (
          <BadgeV2
            badgeType={BadgeV2Types.KeywordRoutingTags}
            keywordRoutingTypes={getKeywordTargetingTypes(
              defaultAdGroup?.targetSegments
            )}
          />
        )}
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.none}
          weight={TypographyWeight.semibold}
        >
          {keyword}
        </Typography>
      </div>
      <div className="px-24 my-8">
        <SearchInput
          onSearchInputClear={onCancelSuggestion}
          value={searchValue}
          onChange={(value: string) => setSearchValue(value)}
          placeholder={SEARCH_INPUT_PLACEHOLDER}
          searchButtonProps={searchButtonInputProps}
        />
      </div>
      <div className="flex flex-col p-24 gap-24 max-h-560 min-h-560 overflow-y-auto overflow-x-hidden">
        {!isLoading &&
          eligibleAdGroups?.elements?.map((campaign) => (
            <CampaignAdGroup
              adGroupName={campaign.adGroups[0].name}
              campaignGoal={
                [AddedBy.FlywheelAutomated, AddedBy.CampaignCreatorV1].includes(
                  campaign.createdBy as AddedBy
                )
                  ? BadgeCampaignGoal.Smart
                  : BadgeCampaignGoal.None
              }
              campaignName={campaign.name}
              campaignType={
                isManualCampaign(
                  String(campaign.targetingType) as CampaignTargetingType
                )
                  ? BadgeCampaignType.Manual
                  : BadgeCampaignType.Auto
              }
              dataTestId={`campaign-adgroup-${campaign.id}`}
              keywordRoutingTypes={getKeywordTargetingTypes(
                first(campaign.adGroups)?.targetSegments
              )}
              onCheck={() =>
                onAdgroupSelectionChange(
                  campaign.adGroups[0],
                  campaign,
                  !(selectedCampaignWithAdGroup?.id === campaign.id)
                )
              }
              isChecked={selectedCampaignWithAdGroup?.id === campaign.id}
              variant={CampaignAdGroupVariant.Radio}
            />
          ))}
        {isLoading && (
          <>
            <CampaignAdGroup
              adGroupName="Testing"
              campaignGoal={BadgeCampaignGoal.None}
              campaignName="Testing "
              campaignType={BadgeCampaignType.Auto}
              keywordRoutingTypes={[]}
              variant={CampaignAdGroupVariant.Radio}
              isLoading
            />
            <CampaignAdGroup
              adGroupName="Testing"
              campaignGoal={BadgeCampaignGoal.None}
              campaignName="Testing "
              campaignType={BadgeCampaignType.Auto}
              keywordRoutingTypes={[]}
              variant={CampaignAdGroupVariant.Radio}
              isLoading
            />
            <CampaignAdGroup
              adGroupName="Testing"
              campaignGoal={BadgeCampaignGoal.None}
              campaignName="Testing "
              campaignType={BadgeCampaignType.Auto}
              keywordRoutingTypes={[]}
              variant={CampaignAdGroupVariant.Radio}
              isLoading
            />
            <CampaignAdGroup
              adGroupName="Testing"
              campaignGoal={BadgeCampaignGoal.None}
              campaignName="Testing "
              campaignType={BadgeCampaignType.Auto}
              keywordRoutingTypes={[]}
              variant={CampaignAdGroupVariant.Radio}
              isLoading
            />
            <CampaignAdGroup
              adGroupName="Testing"
              campaignGoal={BadgeCampaignGoal.None}
              campaignName="Testing "
              campaignType={BadgeCampaignType.Auto}
              keywordRoutingTypes={[]}
              variant={CampaignAdGroupVariant.Radio}
              isLoading
            />
            <CampaignAdGroup
              adGroupName="Testing"
              campaignGoal={BadgeCampaignGoal.None}
              campaignName="Testing "
              campaignType={BadgeCampaignType.Auto}
              keywordRoutingTypes={[]}
              variant={CampaignAdGroupVariant.Radio}
              isLoading
            />
            <CampaignAdGroup
              adGroupName="Testing"
              campaignGoal={BadgeCampaignGoal.None}
              campaignName="Testing "
              campaignType={BadgeCampaignType.Auto}
              keywordRoutingTypes={[]}
              variant={CampaignAdGroupVariant.Radio}
              isLoading
            />
            <CampaignAdGroup
              adGroupName="Testing"
              campaignGoal={BadgeCampaignGoal.None}
              campaignName="Testing "
              campaignType={BadgeCampaignType.Auto}
              keywordRoutingTypes={[]}
              variant={CampaignAdGroupVariant.Radio}
              isLoading
            />
            <CampaignAdGroup
              adGroupName="Testing"
              campaignGoal={BadgeCampaignGoal.None}
              campaignName="Testing "
              campaignType={BadgeCampaignType.Auto}
              keywordRoutingTypes={[]}
              variant={CampaignAdGroupVariant.Radio}
              isLoading
            />
            <CampaignAdGroup
              adGroupName="Testing"
              campaignGoal={BadgeCampaignGoal.None}
              campaignName="Testing "
              campaignType={BadgeCampaignType.Auto}
              keywordRoutingTypes={[]}
              variant={CampaignAdGroupVariant.Radio}
              isLoading
            />
          </>
        )}
      </div>
      <div className="flex flex-row w-full justify-end items-center p-16 gap-8">
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.ActionWithoutBorder}
          label="Cancel"
          onClick={props.onClose}
        />
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Primary}
          label="Submit"
          onClick={onApplySuggestion}
          dataTestId="apply-suggestion"
        />
      </div>
    </Modal>
  );
};

const getKeywordTargetingTypes = (
  targetSegments?: string[]
): KeywordRoutingType[] => {
  const keywordRoutingTypes: KeywordRoutingType[] = [];
  if (targetSegments?.includes(TargetSegments.Auto)) {
    keywordRoutingTypes.push(KeywordRoutingType.Auto);
  }
  if (targetSegments?.includes(TargetSegments.Brand)) {
    keywordRoutingTypes.push(KeywordRoutingType.Brand);
  }
  if (
    targetSegments?.includes(TargetSegments.Generic) ||
    isNil(targetSegments) ||
    targetSegments?.length === 0
  ) {
    keywordRoutingTypes.push(KeywordRoutingType.Generic);
  }
  if (targetSegments?.includes(TargetSegments.Competitor)) {
    keywordRoutingTypes.push(KeywordRoutingType.Competitor);
  }
  return keywordRoutingTypes;
};

NewDestinationAdGroupModal.displayName = 'NewDestinationAdGroupModal';
