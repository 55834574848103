import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import {
  makeCurrencyColumn,
  makeNumericInputColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  AdType,
  CampaignDetails,
  CampaignStatus,
} from '../../../../../lib/types/AOSharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import {
  AdLevelI8nKeyMapper,
  CURRENCY_CODE,
  NumericValueConfig,
  TableDataAdsManager,
} from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getAutomationDisabledTooltipMessage,
  getTooltipContentForArchivedEntity,
  isCampaignAdFormatProductCollectionOrSpotlight,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
  isInputValueNumber,
  isValidFlywheelSetting,
} from '../utils';
import {
  getCurrencyCodeFromMerchantCountryCode,
  getCurrencySymbol,
  getCurrencySymbolFromMerchantCountryCode,
} from '../../../../../lib/utilities/currency';

const getControlledTooltipState = (
  isCampaignArchived: boolean,
  focussed: boolean,
  minBidNumericInputState: NumericInputState
) => {
  if (isCampaignArchived) {
    return ControlledTooltip.None;
  } else {
    return focussed && minBidNumericInputState === NumericInputState.Error
      ? ControlledTooltip.Show
      : ControlledTooltip.Hide;
  }
};

const getCurrencyCode = (currencyCode: string | null, data: CampaignDetails) =>
  currencyCode || data.flywheelSettings.minBid?.currency || CURRENCY_CODE;

const getNumericInputPlaceholder = (
  minBidConfig: NumericValueConfig,
  intl: IntlShape
) =>
  isNil(minBidConfig.default)
    ? undefined
    : intl.formatNumber(minBidConfig.default, {
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      });

const getTooltipContent = (args: {
  automationDisabledForMerchant: boolean;
  intl: IntlShape;
  isCampaignArchived: boolean;
  isValueGreaterThanRequiredMaxValue: boolean | '';
  isMinBidValid: boolean;
  minBidConfig: NumericValueConfig;
  currencySymbol: string;
  isDirty: boolean;
  isSettingsValid: boolean;
}) => {
  const {
    automationDisabledForMerchant,
    intl,
    isCampaignArchived,
    isValueGreaterThanRequiredMaxValue,
    isMinBidValid,
    minBidConfig,
    currencySymbol,
    isDirty,
    isSettingsValid,
  } = args;

  if (automationDisabledForMerchant) {
    return getAutomationDisabledTooltipMessage(
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MIN_BID,
      })
    );
  }

  if (isCampaignArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MIN_BID,
      }),
      intl
    );
  } else if (isDirty || !isSettingsValid) {
    if (isValueGreaterThanRequiredMaxValue) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_MINBID_SHOULD_BE_LESS_THAN_MAX_BID,
          })}
        </p>
      );
    } else if (!isMinBidValid && minBidConfig.min && minBidConfig.max) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_BID_BETWEEN,
            },
            {
              minValue: intl.formatNumber(minBidConfig.min, {
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
              maxValue: intl.formatNumber(minBidConfig.max, {
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
              currencySymbol,
            }
          )}
        </p>
      );
    } else if (!isMinBidValid && minBidConfig.min) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_MINBID,
            },
            {
              minValue: intl.formatNumber(minBidConfig.min, {
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
              currencySymbol,
            }
          )}
        </p>
      );
    }
  }
};

const getCurrency = (
  merchantCurrency: string | null,
  currency: string | undefined
) => getCurrencySymbol(merchantCurrency || currency || CURRENCY_CODE);

const isEditModeOrIsValueEdited = (
  isEditMode: boolean,
  updatedMinBid: string,
  value: string
) =>
  isEditMode || //Changing between modes
  (!isNil(updatedMinBid) && updatedMinBid !== value); // Changes done due to bulk update. updatedMinBid is latest, but state variable is not

const checkIfAutomationIsDisabledForMerchant = (
  data: CampaignDetails & TableDataAdsManager
) =>
  !data.aiEnabled &&
  !data.merchantsWithAutomationEnabled?.includes(
    data.campaignDetails?.merchantCountryId ?? ''
  );

const isInputDirty = (value: string, existingValue: string) =>
  value || existingValue
    ? parseFloat(value) !== parseFloat(existingValue)
    : false;

const numericInputStateForEditedValue = (isMinBidValid: boolean) =>
  isMinBidValid ? NumericInputState.Default : NumericInputState.Error;

const getMinBid = (updatedMinBid: string, existingValue: string) =>
  updatedMinBid ?? existingValue;

const shouldDisableInput = (
  campaignStatus: CampaignStatus | undefined,
  isAutomationDisabledForMerchant: boolean
) =>
  isCampaignStatusArchived(campaignStatus) || isAutomationDisabledForMerchant;

const getExistingValue = (minBidValue: string | undefined) => minBidValue || '';

const checkValidFlywheelSettings = (
  props: CampaignDetails & TableDataAdsManager,
  _currentBidAutomationStatus?: string,
  minBid?: string,
  maxBid?: string,
  macsTarget?: string
) =>
  isValidFlywheelSetting(
    props.flywheelSettings,
    {
      minBid,
      maxBid,
      macsTarget,
    },
    minBid
  );

const getNumbericInputState = (
  props: CampaignDetails & TableDataAdsManager,
  automationDisabledForMerchant: boolean,
  isInputEdited: boolean,
  isMinBidValid: boolean
) => {
  let minBidNumericInputState;
  if (
    shouldDisableInput(
      props.channelSettings.status,
      automationDisabledForMerchant
    )
  ) {
    minBidNumericInputState = NumericInputState.Disabled;
  } else if (isInputEdited) {
    minBidNumericInputState = numericInputStateForEditedValue(isMinBidValid);
  } else {
    minBidNumericInputState = NumericInputState.Default;
  }
  return minBidNumericInputState;
};

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) => {
  const { isEditMode } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);
  const bidConstraintsData = useBidConstraintsContext();

  const { campaignCostType, targetingType } = props.campaignDetails;

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  let updatedMinBid = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MinBid
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const minBidConfig = useMemo(() => {
    const constrainsts = getBidConstraint(
      bidConstraintsData.constraints,
      isCampaignAdFormatProductCollectionOrSpotlight(
        props.campaignDetails.campaignAdFormat
      )
        ? props.selectedAdType
        : AdType.SponsoredBrandsVideo,
      props.salesChannel,
      CAMPAIGNS_API_COLUMN_NAME.MinBid,
      props.merchantCountry,
      targetingType,
      props.merchantType,
      campaignCostType
    );

    return {
      min: constrainsts.minBid,
      max: constrainsts.maxBid,
      default: constrainsts.defaultMinBid,
    };
  }, [
    props.campaignDetails,
    props.selectedAdType,
    props.salesChannel,
    props.merchantCountry,
  ]);

  const merchantCurrency = getCurrencySymbolFromMerchantCountryCode(
    props.merchantCountry
  );

  const currencySymbol = getCurrency(
    merchantCurrency,
    props.flywheelSettings.minBid?.currency
  );
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(
    props.merchantCountry
  );

  const existingValue = getExistingValue(
    props.flywheelSettings?.minBid?.amount?.toString()
  );

  let minBid = getMinBid(updatedMinBid, existingValue);

  const isInputEdited = existingValue !== minBid;

  const [value, setValue] = useState<string>(minBid);

  useEffect(() => {
    if (isEditModeOrIsValueEdited(isEditMode, updatedMinBid, value)) {
      setValue(minBid);
    }
  }, [isEditMode, currentPage, updatedMinBid]);

  const currentMaxBid =
    useSelector<WithTable<CampaignDetails>, string>(({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MaxBid
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
    ) || props.flywheelSettings.maxBid?.amount;

  const currentBidAutomationStatus = useSelector<
    WithTable<CampaignDetails>,
    string
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      props.campaignId,
      CAMPAIGNS_API_COLUMN_NAME.AutomationStatus
    )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentMacsTarget = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MACSTarget
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const updatedCurrentMaxBid = currentMaxBid || '';

  const updateCellValue = (updateValue: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        rowId: props.campaignId,
        columnName: CAMPAIGNS_API_COLUMN_NAME.MinBid,
        value: updateValue,
        existingValue,
        numericValue: true,
      })
    );
  };

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [props.campaignId]
  );

  const onMinBidInputChange = (inputValue: string) => {
    if (isInputValueNumber(inputValue)) {
      setValue(inputValue);
      debouncedUpdateCellValue(inputValue);
    }
  };

  const onInputBlur = () => {
    if (!isEmpty(value)) {
      debouncedUpdateCellValue.cancel();
      const formattedValue = parseFloat(value).toFixed(
        MONETARY_FRACTION_DIGITS
      );
      setValue(formattedValue);
      updateCellValue(formattedValue);
    }
    setFocussed(false);
  };

  const isSettingsValid = useMemo(
    () =>
      checkValidFlywheelSettings(
        props,
        currentBidAutomationStatus,
        minBid,
        currentMaxBid,
        currentMacsTarget
      ),
    [
      props,
      currentBidAutomationStatus,
      minBid,
      currentMaxBid,
      currentMacsTarget,
    ]
  );

  if (isEditMode) {
    let minBidNumericInputState: NumericInputState;
    let isMinBidValid = !isMinBidInvalid(
      value,
      updatedCurrentMaxBid,
      minBidConfig.min,
      minBidConfig.max,
      existingValue
    );

    const automationDisabledForMerchant =
      checkIfAutomationIsDisabledForMerchant(props);

    minBidNumericInputState = getNumbericInputState(
      props,
      automationDisabledForMerchant,
      isInputEdited,
      isMinBidValid
    );

    const isCampaignArchived = isCampaignStatusArchived(
      props.channelSettings.status
    );

    if (!isSettingsValid) {
      isMinBidValid = false;
      minBidNumericInputState = NumericInputState.Error;
    }

    const isValueGreaterThanRequiredMaxValue =
      !isMinBidValid &&
      updatedCurrentMaxBid &&
      isCurrentValueGreaterThanRequiredMaxValue(
        Number(updatedCurrentMaxBid),
        value
      );

    const onInputFocus = () => setFocussed(true);

    const minBidNumericInputProps: NumericInputProps & TypedTooltipProps = {
      value,
      state: minBidNumericInputState,
      prependedElement: currencySymbol,
      onInputBlur: onInputBlur,
      onChange: onMinBidInputChange,
      isDirty: isInputDirty(value, existingValue),
      tooltipContent: getTooltipContent({
        automationDisabledForMerchant,
        intl,
        isCampaignArchived,
        isValueGreaterThanRequiredMaxValue,
        isMinBidValid,
        minBidConfig,
        currencySymbol,
        isDirty: isInputDirty(value, existingValue),
        isSettingsValid,
      }),
      controlledTooltip: getControlledTooltipState(
        isCampaignArchived,
        focussed,
        minBidNumericInputState
      ),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus: onInputFocus,
      acceptOnlyPositiveNumbers: true,
      placeholder: getNumericInputPlaceholder(minBidConfig, intl),
      minFractionDigits: MONETARY_FRACTION_DIGITS,
      maxFractionDigits: MONETARY_FRACTION_DIGITS,
      dataTestId: 'campaigns_minBid',
    };

    return makeNumericInputColumn(() => minBidNumericInputProps)(props);
  }

  return makeCurrencyColumn(
    (data: CampaignDetails) =>
      data.flywheelSettings?.minBid && {
        amount: Number(data.flywheelSettings.minBid.amount),
        currency: getCurrencyCode(currencyCode, data),
      }
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const minBidColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.MinBid,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MIN_BID,
  RowCellElement,
  gridColumnWidth: '160px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const minBidFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.MinBid,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MIN_BID,
    currency,
    isValidNumber(0),
    true
  );

export function isMinBidInvalid(
  minBidValue: string,
  maxBid?: string,
  minMinBidValue?: number,
  maxMinBidValue?: number,
  existingValue?: string
) {
  if (existingValue === '' && minBidValue === existingValue) {
    return false;
  } else {
    return (
      !minBidValue ||
      isCurrentValueLessThanRequiredMinValue(minMinBidValue, minBidValue) ||
      (maxBid
        ? isCurrentValueGreaterThanRequiredMaxValue(Number(maxBid), minBidValue)
        : false) ||
      isCurrentValueGreaterThanRequiredMaxValue(maxMinBidValue, minBidValue)
    );
  }
}
