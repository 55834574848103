import I18nKey from '../../lib/types/I18nKey';
import {
  ArrowRightIcon,
  Icon,
  IconSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { SalesChannelContext } from '../../containers/salesChannelProvider';
import {
  DEFAULT_SALES_CHANNELS,
  SalesChannel,
} from '../../modules/account/components/connectSalesChannelModal';
import compact from 'lodash/compact';
import find from 'lodash/find';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavPaths } from '../../NavPaths';
import { useContext, useEffect, useState } from 'react';
import { FADE_INTERVAL_MS } from './types';
import { parseQuery } from '../../lib/utilities/queryString';

export interface GetStartedConnectChannelProps {
  readonly name?: string;
  readonly isWelcomeBack?: boolean;
}

export const GetStartedConnectChannel: React.FC<
  GetStartedConnectChannelProps
> = ({ name, isWelcomeBack }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { backRedirect } = parseQuery(location?.search);
  const intl = useIntl();
  const { salesChannels } = useContext(SalesChannelContext);
  const [channels, setChannels] = useState<SalesChannel[]>([]);
  const [fade, setFade] = useState<boolean>(false);
  const [fadeActions, setFadeActions] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(
      () => {
        setFade(true);
        setTimeout(() => {
          setFadeActions(true);
        }, FADE_INTERVAL_MS);
      },
      backRedirect ? 0 : FADE_INTERVAL_MS
    );
  }, []);

  useEffect(() => {
    const filteredSalesChannels = compact(
      DEFAULT_SALES_CHANNELS.map((salesChannel: SalesChannel) => {
        const allSalesChannel = find(salesChannels, {
          name: salesChannel.name,
        });
        if (!allSalesChannel) {
          return null;
        }

        return { ...salesChannel, id: allSalesChannel.id };
      })
    );
    setChannels(filteredSalesChannels);
  }, [salesChannels]);

  const selectSalesChannel = async (channel: SalesChannel) => {
    await navigate(
      `${NavPaths.SalesChannels}?scsRedirect=${location.pathname}&connect=1&channel=${channel.name}`
    );
  };

  return (
    <div className="flex flex-col items-center w-512 gap-12">
      {!backRedirect && (
        <div className="flex flex-col justify-center gap-12 motion-safe:animate-fadeInUp">
          <Typography
            size={TypographySize.xl}
            lineHeight={TypographyLineHeight.tight}
            weight={TypographyWeight.semibold}
            className="text-purple-600"
          >
            <FormattedMessage
              id={
                isWelcomeBack
                  ? I18nKey.WELCOME_BACK_TITLE
                  : I18nKey.SUCCESS_CONTINUE_HEADER
              }
              values={{
                name: name,
              }}
            />
          </Typography>
        </div>
      )}
      <div
        className={classNames({
          'h-0': !fade,
          'transition-height duration-2000 ease-in h-44': fade,
        })}
      >
        <Typography
          size={TypographySize.lg}
          lineHeight={TypographyLineHeight.normal}
          weight={TypographyWeight.regular}
          className={classNames('text-grey-900', {
            hidden: !fade,
          })}
        >
          <FormattedMessage id={I18nKey.SUCCESS_CONTINUE_SUB_HEADER} />
        </Typography>
      </div>
      <div
        className={classNames('flex flex-col items-start gap-12', {
          'h-0': !fadeActions,
          'transition-height duration-2000 ease-in h-144': fadeActions,
        })}
      >
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.tight}
          weight={TypographyWeight.medium}
          className={classNames('text-grey-900', {
            hidden: !fadeActions,
          })}
        >
          <FormattedMessage id={I18nKey.SUCCESS_CONTINUE_TITLE} />
        </Typography>
        <div
          className={classNames(
            'w-512',
            'rounded border border-solid border-grey-200',
            'text-lg text-grey-900 font-semibold leading-none',
            'cursor-pointer overflow-hidden',
            {
              hidden: !fadeActions,
            }
          )}
        >
          {channels.map((channel: SalesChannel, idx: number) => {
            return (
              <div
                key={channel.name}
                className={classNames(
                  `flex justify-between items-center h-56 px-16 group hover:bg-purple-50`,
                  {
                    'border-b border-solid border-grey-200':
                      idx !== channels.length - 1,
                  }
                )}
                role="button"
                onClick={() => selectSalesChannel(channel)}
                data-test-id={`sales_channel_${channel.name}`}
              >
                <span>
                  <Icon
                    svg={channel.icon}
                    size={IconSize.Large}
                    className="mr-8"
                  />
                  {intl.formatMessage({
                    id: channel.tabName,
                  })}
                </span>
                <Icon
                  svg={ArrowRightIcon}
                  size={IconSize.Small}
                  className="text-grey-400"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

GetStartedConnectChannel.displayName = 'GetStartedConnectChannel';
