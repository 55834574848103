import random from 'lodash/random';
import times from 'lodash/times';
import { DateTime } from 'luxon';
import { convertToUTCDate } from '../../utilities/datePickerUtilities';

export function maybeData<T>(
  params: Readonly<{
    data: T;
    percentChanceOfData: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90;
  }>
): T | undefined {
  if (generateRandomInt(1, 100) > 100 - params.percentChanceOfData) {
    return params.data;
  }
  return undefined;
}

export function variableListOfData<T>(
  params: Readonly<{
    dataGenerator: () => T;
    minNumberOfElements?: number;
    maxNumberOfElements?: number;
  }>
): T[] {
  return times(
    generateRandomInt(params.minNumberOfElements, params.maxNumberOfElements),
    params.dataGenerator
  );
}

export const generateRandomNumber = (
  lowerBound: number = Number.MIN_VALUE,
  upperBound: number = Number.MAX_VALUE
): number => random(lowerBound, upperBound, true);

export const generateRandomInt = (
  lowerBound: number = Number.MIN_VALUE,
  upperBound: number = Number.MAX_VALUE
): number => random(lowerBound, upperBound, false);

export const generateRandomString = (
  lengthLowerBoundInclusive: number = 5,
  lengthUpperBoundExclusive: number = 12
): string => {
  const length = generateRandomInt(
    lengthLowerBoundInclusive,
    lengthUpperBoundExclusive
  );
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return Array.from(Array(length).keys()).reduce(
    (acc) =>
      `${acc}${possible.charAt(Math.floor(Math.random() * possible.length))}`,
    ''
  );
};

export const generateRandomBoolean = (): boolean =>
  generateRandomInt() % 2 === 0;

export const generateRandomDate = (
  dayLowerBoundInclusive?: Date,
  dayUpperBoundExclusive?: Date
): string => {
  const lowerBound = dayLowerBoundInclusive || new Date();
  const upperBound =
    dayUpperBoundExclusive ||
    convertToUTCDate(DateTime.fromJSDate(lowerBound).toUTC().plus({ days: 7 }));

  return new Date(
    lowerBound.getTime() +
      Math.random() * (upperBound.getTime() - lowerBound.getTime())
  ).toUTCString();
};
