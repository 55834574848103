import './salesChannelsTable.scss';

import compact from 'lodash/compact';
import find from 'lodash/find';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { LevelsController } from '@teikametrics/tm-design-system';
import { SalesChannelContext } from '../../../../containers/salesChannelProvider';
import { tableActions } from '../../../../containers/table/ducks';
import { getCurrentAccountPermissions } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { useDataSyncInfoContext } from '../../../../containers/dataSyncInfoProvider/dataSyncInfoProvider';
import { SubscriptionContext } from '../../../../containers/subscriptionProvider';
import {
  ConnectionStatus,
  FlywheelSalesChannel,
  Role,
  SalesChannelLocationState,
} from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { getAdsAndProductDataConnectionStatusDataForSetup } from '../../../../lib/utilities/connectionStatus';
import { MerchantsDataConnectionStatus } from '../../../../lib/types/FlywheelSetupSharedTypes';
import { NavPaths } from '../../../../NavPaths';
import {
  ConnectSalesChannelModal,
  DEFAULT_SALES_CHANNELS,
  SalesChannel,
} from '../../components/connectSalesChannelModal';
import { RowDataProps } from '../../components/types';
import { EntityType } from '../utils';
import SalesChannelsTable from './salesChannelsTable';
import { SALES_CHANNELS_TABLE } from './types';
import { headerInfo } from './utils/constants';
import {
  PageHeaderContext,
  PageHeaderContextState,
} from '../../../../containers/pageHeaderProvider';
import { parseQuery } from '../../../../lib/utilities/queryString';
import { storeCurrentPageToSessionStorage } from '../../../../lib/utilities/storageUtils';
import { useLocation, useNavigate } from 'react-router-dom';

interface OwnProps {
  readonly accountId: string;
}

const SalesChannels: React.FC<OwnProps> = ({ accountId }) => {
  const userContext = useContext<UserContextState>(UserContext);
  const { userInfo, aoSubscribed } = userContext;
  const { userDetails } = userInfo;
  const userId = userDetails?.id || '';
  const permissions = getCurrentAccountPermissions(userDetails);
  const userRole = permissions?.role;
  const intl = useIntl();
  const [SALES_CHANNEL_TITLE] = [I18nKey.SALES_CHANNELS_TITLE].map((id) =>
    intl.formatMessage({ id })
  );

  const location = useLocation();

  const locationState = location.state as SalesChannelLocationState;
  const { channel, connect, entity, scsRedirect } = parseQuery(
    location?.search
  );

  const [salesChannels, setSalesChannels] = useState<SalesChannel[]>([]);
  const [currentSalesChannel, setCurrentSalesChannel] =
    useState<SalesChannel>();

  const [entityType, setEntityType] = useState<EntityType>(
    locationState?.entity ?? EntityType.Ads
  );

  const [isConnectionInitialModalOpen, setIsConnectionInitialModalOpen] =
    useState<boolean>(false);
  const [isConnectionStepsModalOpen, setIsConnectionStepsModalOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subscriptionContext = useContext(SubscriptionContext);
  const { salesChannelData, isSubscriptionInformationLoaded } =
    subscriptionContext;

  const salesChannelContext = useContext(SalesChannelContext);

  const dataSyncInfo = useDataSyncInfoContext();

  const { salesChannels: flywheelSalesChannels } = salesChannelContext;

  const [connectedMerchantIds, setConnectedMerchantIds] = useState<string[]>(
    []
  );
  const [merchantCountry, setMerchantCountry] = useState<RowDataProps[]>([]);

  useEffect(() => {
    const filteredSalesChannels = compact(
      DEFAULT_SALES_CHANNELS.map((salesChannel: SalesChannel) => {
        const allSalesChannel = find(flywheelSalesChannels, {
          name: salesChannel.name,
        });
        if (!allSalesChannel) {
          return null;
        }

        return { ...salesChannel, id: allSalesChannel.id };
      })
    );

    if (userRole && userRole === Role.EDITOR) {
      setSalesChannels(
        filteredSalesChannels.filter(
          (chan) => chan.name === FlywheelSalesChannel.Amazon
        )
      );
    } else {
      setSalesChannels(filteredSalesChannels);
    }

    const isChannelValid = [
      FlywheelSalesChannel.Amazon,
      FlywheelSalesChannel.Walmart,
    ].includes(channel as FlywheelSalesChannel);

    const filteredChannelName = isChannelValid
      ? channel
      : FlywheelSalesChannel.Amazon;

    setCurrentSalesChannel(
      filteredSalesChannels.find(
        (salesChannel) => salesChannel.name === filteredChannelName
      )
    );

    if (connect) {
      if (channel && isChannelValid) {
        setIsConnectionStepsModalOpen(true);
      } else {
        setIsConnectionInitialModalOpen(true);
      }
    }
  }, [flywheelSalesChannels]);

  useEffect(() => {
    if (!subscriptionContext.isSubscriptionInformationLoaded) {
      subscriptionContext.updateSubscriptionInformation();
    }
  }, []);

  useEffect(() => {
    if (!isSubscriptionInformationLoaded || !currentSalesChannel) {
      return;
    }

    const adsAndProductConnectionStatusData =
      getAdsAndProductDataConnectionStatusDataForSetup(
        dataSyncInfo?.aoDataSyncInfo?.syncPerMerchantIds || [],
        dataSyncInfo?.skuDataAvailibilityInfo?.syncPerMerchantIds || [],
        salesChannelData
      );

    const connnectionStatusData = adsAndProductConnectionStatusData
      ? [
          ...adsAndProductConnectionStatusData[currentSalesChannel.name]
            .connectedData,
          ...adsAndProductConnectionStatusData[currentSalesChannel.name]
            .syncingData,
        ]
      : [];

    const merchantCountries: RowDataProps[] = [];
    connnectionStatusData?.forEach((item: MerchantsDataConnectionStatus) => {
      if (item.productsDataConnectionStatus !== ConnectionStatus.Connected) {
        merchantCountries.push({
          merchantId: item.merchantId || '',
          merchantCountryId: item.extMerchantId,
          country: item.country,
          merchantType: item.extMerchantType || '',
        });
      }
    });

    const merchantCountryIds: string[] =
      merchantCountries.map((item: RowDataProps) => item.merchantCountryId) ||
      [];

    setMerchantCountry(merchantCountries);
    setConnectedMerchantIds(merchantCountryIds);
  }, [currentSalesChannel, dataSyncInfo, salesChannelData]);

  useEffect(() => {
    if (
      (entity === EntityType.Ads || entity === EntityType.Products) &&
      entityType !== entity
    ) {
      setEntityType(entity);
    }
  }, [entity]);

  const onChangeChannel = (level: string) => {
    setCurrentSalesChannel(
      salesChannels.find((salesChannel) => salesChannel.name === level)
    );
    entityType === EntityType.Products && setEntityType(EntityType.Ads);
    dispatch(
      tableActions.setVisiblePage({
        page: 1,
        tableId: SALES_CHANNELS_TABLE,
      })
    );
    storeCurrentPageToSessionStorage(userId, accountId, 1);
  };

  const redirectAfterCloseConnectionModal = async () => {
    if (scsRedirect) {
      await navigate(`${scsRedirect}?backRedirect=true`);
    } else if (connect) {
      await navigate(NavPaths.SalesChannels);
    }
  };

  const openConnectionInitialModal = () => {
    setIsConnectionInitialModalOpen(true);
  };

  const closeConnectionInitialModal = async () => {
    await redirectAfterCloseConnectionModal();
    setIsConnectionInitialModalOpen(false);
  };

  const selectSalesChannel = (channel: SalesChannel) => {
    onChangeChannel(channel.name);
    setIsConnectionInitialModalOpen(false);
    setIsConnectionStepsModalOpen(true);
  };

  const closeConnectionStepsModal = async (openInitialModal?: boolean) => {
    await redirectAfterCloseConnectionModal();
    setIsConnectionStepsModalOpen(false);

    if (openInitialModal) {
      openConnectionInitialModal();
    }
  };

  const { updatePageHeaderData } =
    useContext<PageHeaderContextState>(PageHeaderContext);

  useEffect(() => {
    updatePageHeaderData({
      title: SALES_CHANNEL_TITLE,
      dataTestId: 'salesChannels',
    });
  }, []);

  return (
    <>
      {salesChannels.length > 0 && (
        <ConnectSalesChannelModal
          salesChannels={salesChannels}
          isOpen={isConnectionInitialModalOpen}
          selectSalesChannel={selectSalesChannel}
          closeModal={() => void closeConnectionInitialModal()}
        />
      )}
      <div className="sales-channels mx-16 mb-16 mt-8">
        {salesChannels.length > 0 && currentSalesChannel && (
          <LevelsController
            active={currentSalesChannel?.name}
            dataTestId="salesChannel"
            levels={salesChannels.map((salesChannel: SalesChannel) => ({
              name: intl.formatMessage({
                id: headerInfo[salesChannel.name].header,
              }),
              value: salesChannel.name,
            }))}
            onChange={onChangeChannel}
          />
        )}
        {currentSalesChannel && salesChannels.length > 0 && (
          <SalesChannelsTable
            accountId={accountId}
            selectedSalesChannel={currentSalesChannel}
            aoSubscribed={aoSubscribed}
            isStepsModalOpen={isConnectionStepsModalOpen}
            entity={entityType}
            connectedMerchantIds={[...connectedMerchantIds]}
            notOwnedMerchant={[...merchantCountry]}
            userRole={userRole}
            openConnectionInitialModal={openConnectionInitialModal}
            closeConnectionStepsModal={closeConnectionStepsModal}
          />
        )}
      </div>
    </>
  );
};
SalesChannels.displayName = 'SalesChannels';
export default SalesChannels;
