import React from 'react';
import { useIntl } from 'react-intl';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignDetails,
  CampaignServingStatus,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { CAMPAIGNS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ ...campaignDetails }) => {
  const intl = useIntl();

  return makeTextColumn<CampaignDetails>(
    ({ channelSettings: { campaignServingStatus } }) =>
      campaignServingStatus &&
      intl.formatMessage({
        id:
          CAMPAIGN_SERVING_STATUS_I18NKEY_MAPPER?.[campaignServingStatus] ??
          I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_OTHER,
      })
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignServingStatusColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.CampaignServingStatus,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS,
  RowCellElement,
  gridColumnWidth: '160px',
};

const CAMPAIGN_SERVING_STATUS_I18NKEY_MAPPER: Record<
  CampaignServingStatus,
  I18nKey
> = {
  [CampaignServingStatus.AsinNotBuyable]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ASIN_NOT_BUYABLE,
  [CampaignServingStatus.BillingError]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_BILLING_ERROR,
  [CampaignServingStatus.Ended]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ENDED,
  [CampaignServingStatus.LandingPageNotAvailable]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_LANDING_PAGE_NOT_AVAILABLE,
  [CampaignServingStatus.OutOfBudget]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_OUT_OF_BUDGET,
  [CampaignServingStatus.Paused]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PAUSED,
  [CampaignServingStatus.PendingReview]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PENDING_REVIEW,
  [CampaignServingStatus.Ready]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_READY,
  [CampaignServingStatus.Rejected]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_REJECTED,
  [CampaignServingStatus.Delivering]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_DELIVERING,
  [CampaignServingStatus.Scheduled]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_SCHEDULED,
  [CampaignServingStatus.Terminated]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_TERMINATED,
  [CampaignServingStatus.Other]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_OTHER,
  [CampaignServingStatus.Ineligible]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_INELIGIBLE,
  [CampaignServingStatus.CampaignArchived]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_ARCHIVED,
  [CampaignServingStatus.CampaignAdsNotDelivering]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_ADS_NOT_DELIVERING,
  [CampaignServingStatus.PortfolioOutOfBudget]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_OUT_OF_BUDGET,
  [CampaignServingStatus.AccountOutOfBudget]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ACCOUNT_OUT_OF_BUDGET,
  [CampaignServingStatus.PendingStartDate]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PENDING_START_DATE,
  [CampaignServingStatus.PortfolioEnded]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_ENDED,
  [CampaignServingStatus.CampaignStatusEnabled]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_STATUS_ENABLED,
  [CampaignServingStatus.AdvertiserPaymentFailure]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ADVERTISER_PAYMENT_FAILURE,
  [CampaignServingStatus.CampaignOutOfBudget]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_OUT_OF_BUDGET,
  [CampaignServingStatus.Unknown]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_UNKNOWN,
  [CampaignServingStatus.CampaignIncomplete]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_INCOMPLETE,
  [CampaignServingStatus.CampaignPaused]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PAUSED,
  [CampaignServingStatus.PortfolioPendingStartDate]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_PENDING_START_DATE,
  [CampaignServingStatus.AdvertiserArchived]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_ADVERTISER_ARCHIVED,
};

export const campaignServingStatusFilter = createMultiSelectDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.CampaignServingStatus,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS,
  [
    SelectFilterOption.CampaignServingStatusAsinNotBuyable,
    SelectFilterOption.CampaignServingStatusBillingError,
    SelectFilterOption.CampaignServingStatusEnded,
    SelectFilterOption.CampaignServingStatusLandingPageNotAvailable,
    SelectFilterOption.CampaignServingStatusOutOfBudget,
    SelectFilterOption.CampaignServingStatusPaused,
    SelectFilterOption.CampaignServingStatusPendingReview,
    SelectFilterOption.CampaignServingStatusReady,
    SelectFilterOption.CampaignServingStatusRejected,
    SelectFilterOption.CampaignServingStatusDelivering,
    SelectFilterOption.CampaignServingStatusScheduled,
    SelectFilterOption.CampaignServingStatusTerminated,
    SelectFilterOption.CampaignServingStatusIneligible,
    SelectFilterOption.CampaignServingStatusCampaignArchived,
    SelectFilterOption.CampaignServingStatusCampaignAdsNotDelivering,
    SelectFilterOption.CampaignServingStatusPortfolioOutOfBudget,
    SelectFilterOption.CampaignServingStatusAccountOutOfBudget,
    SelectFilterOption.CampaignServingStatusPendingStartDate,
    SelectFilterOption.CampaignServingStatusPortfolioEnded,
    SelectFilterOption.CampaignServingStatusCampaignStatusEnabled,
    SelectFilterOption.CampaignServingStatusAdvertiserPaymentFailure,
    SelectFilterOption.CampaignServingStatusUnknown,
    SelectFilterOption.CampaignServingStatusCampaignIncomplete,
    SelectFilterOption.CampaignServingStatusPortfolioPendingStartDate,
    SelectFilterOption.CampaignServingStatusAdvertiserArchived,
  ]
);
