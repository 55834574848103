import { useNavigate } from 'react-router-dom';
import {
  AlertCircleIcon,
  Alignment,
  BadgeDisplayMode,
  BadgeType,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CheckboxCheckedState,
  CheckboxState,
  GroupsLinearIcon,
  Icon,
  IconSize,
  PencilIcon,
  Placement,
  SparkleGradientIcon,
  SparkleIcon,
  SuggestedAdgroupCell,
  SuggestedAdgroupCellProps,
  TagLinearIcon,
  TextLink,
  TextLinkSize,
  Theme,
  Tooltip,
  TooltipProps,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { CheckboxLabelWithTooltip } from '../../../../../../components/CheckboxLabelWithTooltip';
import {
  MATCH_TYPE_I18N_MAPPER,
  getTargetSegmentPills,
} from '../../utils/keywordUtils';
import { BrandNamesContainer } from './brandNamesContainer';
import { KeywordsList } from './keywordsList';
import {
  defaultKeywordCheckboxes,
  defaultMatchTypeCheckboxes,
  getButtonTooltipProps,
} from './utils';
import I18nKey from '../../../../../../lib/types/I18nKey';
import {
  KeywordAdgroupInfo,
  TargetingType,
} from '../../../../../../lib/types/KeywordAction';
import { useContext, useMemo } from 'react';
import { KeywordType } from '../createKeywordsMenu';
import {
  CreateChatGPTKeywordsContext,
  KeywordsRequestState,
} from './createChatGPTKeywordsProvider';
import {
  MatchType,
  TargetSegment,
} from '../../../../../../lib/types/CampaignFlexibilitySharedTypes';
import includes from 'lodash/includes';
import remove from 'lodash/remove';

export interface ConfigureSettingsProps {
  readonly selectedAdGroup?: KeywordAdgroupInfo;
  readonly showBrandNamesContainer: boolean;
  readonly isSaving: boolean;
  readonly onAdGroupEditClick: () => void;
  readonly onCountClick: (_adGroup: KeywordAdgroupInfo) => void;
  readonly onKeywordTypeChange: (_targetSegment: TargetSegment) => void;
  readonly onMatchTypeChange: (_matchType: MatchType) => void;
  readonly onGenerateKeywordsClick: () => void;
  readonly baseUrl: string;
}

const getSuggestedAdgroupProps = (selectedAdGroup?: KeywordAdgroupInfo) => {
  return {
    campaignName: selectedAdGroup?.campaign?.name,
    adGroupName: selectedAdGroup?.name,
    targetSegments: getTargetSegmentPills(
      selectedAdGroup?.targetSegments as any[]
    ),
    targetingType: {
      badgeType:
        selectedAdGroup?.campaign?.targetingType === TargetingType.Auto
          ? BadgeType.CampaignTypeAuto
          : BadgeType.CampaignTypeManual,
      badgeNameDisplayMode: BadgeDisplayMode.OnlyInitial,
    },
    dataTestId: 'chatgpt_suggested_adgroup',
  };
};

const keywordTypeTooltipProps = (intl: IntlShape): TooltipProps => ({
  content: intl.formatMessage({
    id: I18nKey.CREATE_KEYWORDS_TAG_TYPE_ERROR_TOOLTIP,
  }) as string,
  theme: Theme.Dark,
  position: {
    placement: Placement.Left,
    alignment: Alignment.Center,
  },
});

export const ConfigureSettings: React.FC<ConfigureSettingsProps> = ({
  selectedAdGroup,
  showBrandNamesContainer,
  isSaving,
  onAdGroupEditClick,
  onCountClick,
  onKeywordTypeChange,
  onMatchTypeChange,
  onGenerateKeywordsClick,
  baseUrl,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    keywords,
    brandTags,
    targetSegments,
    brands,
    keywordsRequestState,
    setKeywords,
  } = useContext(CreateChatGPTKeywordsContext);
  const suggestedAdgroupProps: SuggestedAdgroupCellProps = useMemo(() => {
    return getSuggestedAdgroupProps(selectedAdGroup);
  }, [selectedAdGroup]);

  const [
    AD_GROUP,
    KEYWORD_TYPE,
    KEYWORD_TYPE_MESSAGE,
    EDIT,
    MATCH_TYPE,
    MATCH_TYPE_MESSAGE,
    GENERATE_KEYWORDS,
    FEEDBACK,
    ERROR_TOOLTIP_MATCH_TYPE,
    BRAND_NAME_MESSAGE,
    REQUEST_IN_PROGRESS,
    INCOMPLETE_AD_GROUP_MESSAGE,
    FEEDBACK_TOOLTIP_MESSAGE,
  ] = [
    I18nKey.AD_OPTIMIZER_SUGGESTIONS_AD_GROUP,
    I18nKey.CREATE_KEYWORDS_MODAL_KEYWORD_TYPE,
    I18nKey.CREATE_KEYWORDS_MODAL_KEYWORD_TYPE_MESSAGE,
    I18nKey.SLIDER_TABLE_EDIT,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_MATCH_TYPE_HEADER,
    I18nKey.CREATE_KEYWORDS_MODAL_MATCH_TYPE_MESSAGE,
    I18nKey.CREATE_KEYWORDS_MODAL_GENERATE_KEYWORDS,
    I18nKey.GENERIC_FEEDBACK,
    I18nKey.CREATE_KEYWORDS_MATCH_TYPE_ERROR_TOOLTIP,
    I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_BRAND_NAME_MESSAGE,
    I18nKey.CREATE_KEYWORDS_CHATGPT_MODAL_REQUEST_IN_PROGRESS,
    I18nKey.CREATE_KEYWORDS_MODAL_INCOMPLETE_ADGROUP_MESSAGE,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_GIVE_FEEDBACK_TOOLTIP,
  ].map((id) =>
    intl.formatMessage(
      { id },
      {
        keywordType: KeywordType.Keyword,
        isNegative: false,
        br: <br />,
        keywords: keywords.length,
        adGroups: 1,
      }
    )
  );

  const matchTypeCheckboxesState =
    brandTags.length > 0 ? CheckboxState.Default : CheckboxState.Error;

  const keywordTypeCheckboxesState =
    targetSegments.length > 0 ? CheckboxState.Default : CheckboxState.Error;

  const getKeywordTypeStatusCheckbox = (value: TargetSegment) => {
    if (includes(targetSegments, value)) {
      return CheckboxCheckedState.Checked;
    }
    return CheckboxCheckedState.Unchecked;
  };

  const isKeywordsLoading =
    keywordsRequestState === KeywordsRequestState.Loading;

  const isKeywordsLoaded =
    [KeywordsRequestState.Success, KeywordsRequestState.NoResult].indexOf(
      keywordsRequestState
    ) !== -1;

  const keywordTypeTooltipEnabled = !targetSegments.length;
  const matchTypeTooltipEnabled = !brandTags.length;

  const getGenerateButtonState = () => {
    if (isKeywordsLoading) {
      return ButtonState.Loading;
    }

    return isSaving ||
      keywordTypeTooltipEnabled ||
      matchTypeTooltipEnabled ||
      (showBrandNamesContainer && !brands.length)
      ? ButtonState.Disabled
      : ButtonState.Enabled;
  };

  const getMatchTypeStatusCheckbox = (value: MatchType) => {
    if (includes(brandTags, value)) {
      return CheckboxCheckedState.Checked;
    }
    return CheckboxCheckedState.Unchecked;
  };

  const handleRemoveKeyword = (removeAll: boolean, targetId?: string) => {
    if (removeAll) {
      setKeywords([]);
    } else {
      const oldKeywords = [...keywords];
      remove(oldKeywords, (keyword) => keyword.targetId === targetId!);
      setKeywords(oldKeywords);
    }
  };

  const navigateToFeedback = () => {
    navigate(baseUrl + '/feedback');
  };

  if (!selectedAdGroup) {
    return <></>;
  }

  return (
    <div className="flex flex-col overflow-auto gap-20 mb-80 flex-auto self-stretch">
      <div className="mx-56 rounded-4 shadow pt-1 bg-gradient-to-r from-purple-500 via-magenta-500 to-orange-500">
        <div className="p-12 flex flex-row items-center gap-8 rounded-4 bg-grey-50">
          <Icon size={IconSize.Small} svg={SparkleGradientIcon} />
          <span className="text-base text-grey-900 leading-normal font-normal">
            {intl.formatMessage({
              id: I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_BANNER_MESSAGE,
            })}
          </span>
        </div>
      </div>

      <div className="flex flex-row flex-1 gap-10 px-56">
        <div className="border border-solid border-grey-300 flex flex-1 flex-col">
          <div className="flex flex-row py-12 px-16 items-center gap-8 bg-grey-50">
            <Icon
              size={IconSize.Small}
              svg={GroupsLinearIcon}
              className="text-grey-500"
            />
            <span className="flex-auto text-base leading-normal font-medium text-grey-700">
              {AD_GROUP}
            </span>
            <Tooltip
              content={REQUEST_IN_PROGRESS as string}
              disabled={!isKeywordsLoading && !isSaving}
            >
              <div
                onClick={onAdGroupEditClick}
                className="flex flex-row items-center gap-8 group cursor-pointer"
              >
                <Icon
                  size={IconSize.Small}
                  svg={PencilIcon}
                  className={classNames({
                    'text-grey-300': isKeywordsLoading || isSaving,
                    'text-grey-500 group-hover:text-purple-500 group-focus:text-purple-600':
                      !isKeywordsLoading,
                  })}
                />
                <TextLink
                  className="border-grey-400 font-normal text-sm"
                  size={TextLinkSize.Small}
                  disabled={isKeywordsLoading || isSaving}
                  textLabel={EDIT}
                />
              </div>
            </Tooltip>
          </div>
          <div className="py-4 px-8">
            <SuggestedAdgroupCell
              {...suggestedAdgroupProps}
              adgroupDetailsClassname="w-420 flex-1"
            >
              <div className="flex flex-row gap-8">
                {selectedAdGroup.totalTargets === 0 && (
                  <Tooltip
                    overwrittenTooltipClassnames="min-w-max"
                    theme={Theme.Dark}
                    content={INCOMPLETE_AD_GROUP_MESSAGE as string}
                  >
                    <Icon
                      size={IconSize.Small}
                      svg={AlertCircleIcon}
                      className="text-red-500"
                    />
                  </Tooltip>
                )}
                <TextLink
                  className="mt-4 border-grey-400 font-normal text-sm"
                  size={TextLinkSize.Small}
                  onClick={() => onCountClick(selectedAdGroup)}
                  textLabel={
                    <FormattedMessage
                      id={
                        I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_SKU_AND_KEYWORDS_COUNT
                      }
                      values={{
                        skuCount: selectedAdGroup.totalSkus,
                        keywordCount: selectedAdGroup.totalTargets,
                        bold: (content) => (
                          <span className="font-bold">{content}</span>
                        ),
                      }}
                    />
                  }
                />
              </div>
            </SuggestedAdgroupCell>
          </div>
          <div
            className={classNames(
              'border-t border-solid border-grey-200',
              'flex flex-row py-12 pl-16 items-center gap-8 bg-grey-50'
            )}
          >
            <Icon
              size={IconSize.Small}
              svg={TagLinearIcon}
              className="text-grey-500"
            />
            <span className="flex-auto text-base leading-normal font-medium text-grey-700">
              {KEYWORD_TYPE}
            </span>
          </div>
          <div className="flex flex-col gap-8 py-8 px-16">
            <span className="text-sm leading-normal font-normal text-grey-800">
              {KEYWORD_TYPE_MESSAGE}
            </span>
          </div>
          <div className="px-16 py-8 flex flex-row gap-24">
            {defaultKeywordCheckboxes.map((checkbox, index) => {
              return (
                <CheckboxLabelWithTooltip
                  key={checkbox}
                  tooltipEnabled={keywordTypeTooltipEnabled}
                  checkboxProps={{
                    state: keywordTypeCheckboxesState,
                    checked: getKeywordTypeStatusCheckbox(checkbox),
                    onClick: () => onKeywordTypeChange(checkbox),
                    label: checkbox,
                  }}
                  tooltipProps={keywordTypeTooltipProps(intl)}
                />
              );
            })}
          </div>
          {showBrandNamesContainer && (
            <div className="flex flex-col gap-8 py-8 px-16">
              <span className="text-sm leading-normal font-normal text-grey-800">
                {BRAND_NAME_MESSAGE}
              </span>

              <BrandNamesContainer isLoading={isKeywordsLoading || isSaving} />
            </div>
          )}
          <div
            className={classNames(
              'border-t border-solid border-grey-200',
              'flex flex-row py-12 pl-16 items-center gap-8 bg-grey-50'
            )}
          >
            <Icon
              size={IconSize.Small}
              svg={TagLinearIcon}
              className="text-grey-500"
            />
            <span className="flex-auto text-base leading-normal font-medium text-grey-700">
              {MATCH_TYPE}
            </span>
          </div>
          <div className="flex flex-col gap-8 py-8 px-16">
            <span className="text-sm leading-normal font-normal text-grey-800">
              {MATCH_TYPE_MESSAGE}
            </span>
          </div>
          <div className="px-16 py-8 flex flex-row gap-24">
            {defaultMatchTypeCheckboxes.map((checkbox, index) => {
              return (
                <CheckboxLabelWithTooltip
                  key={checkbox}
                  checkboxProps={{
                    state: matchTypeCheckboxesState,
                    checked: getMatchTypeStatusCheckbox(checkbox),
                    onClick: () => onMatchTypeChange(checkbox),
                    label: intl.formatMessage({
                      id: MATCH_TYPE_I18N_MAPPER[checkbox],
                    }),
                  }}
                  tooltipEnabled={matchTypeTooltipEnabled}
                  tooltipProps={{
                    ...keywordTypeTooltipProps(intl),
                    content: ERROR_TOOLTIP_MATCH_TYPE as string,
                  }}
                />
              );
            })}
          </div>
          <span className="flex-auto"></span>
          <div className="border-t border-solid border-grey-200 flex flex-row justify-between items-center p-12">
            <Tooltip
              overwrittenTooltipClassnames="min-w-352 w-352 max-w-352"
              content={FEEDBACK_TOOLTIP_MESSAGE as string}
              disabled={isKeywordsLoaded}
            >
              <TextLink
                className={classNames('border-grey-400 font-normal text-base', {
                  'text-grey-400': !isKeywordsLoaded,
                })}
                size={TextLinkSize.Small}
                onClick={() => {
                  navigateToFeedback();
                }}
                disabled={!isKeywordsLoaded}
                textLabel={FEEDBACK}
              />
            </Tooltip>
            <Tooltip
              {...getButtonTooltipProps(
                brandTags,
                showBrandNamesContainer,
                brands,
                targetSegments,
                intl
              )}
              disabled={getGenerateButtonState() !== ButtonState.Disabled}
            >
              <Button
                state={getGenerateButtonState()}
                svgIcon={SparkleIcon}
                label={GENERATE_KEYWORDS}
                size={ButtonSize.Medium}
                onClick={onGenerateKeywordsClick}
                variant={ButtonVariant.Primary}
              />
            </Tooltip>
          </div>
        </div>
        <KeywordsList
          onFeedbackClick={() => navigateToFeedback()}
          targetSegments={targetSegments}
          keywords={keywords}
          keywordsRequestState={keywordsRequestState}
          onRemoveKeyword={handleRemoveKeyword}
        />
      </div>
    </div>
  );
};
