import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  Placement,
  SelectOptionProps,
  SelectProps,
  SelectState,
  TypedTooltipProps,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  AmazonBidOptimization,
  CampaignDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  getTooltipContentForArchivedEntity,
  isCampaignAdFormatEqualToVideo,
  isCampaignAdFormatProductCollectionOrSpotlight,
  isCampaignStatusArchived,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const getSelectState = (isCampaignArchived: boolean) =>
  isCampaignArchived ? SelectState.Disabled : SelectState.Default;

export const getColumnLabel = (
  bidOptimization: boolean | undefined,
  onLabel: string,
  offLabel: string
) =>
  (!isNil(bidOptimization) && bidOptimization ? onLabel : offLabel) ||
  undefined;

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isCampaignArchived = isCampaignStatusArchived(
    props.channelSettings.status
  );
  const selectState = getSelectState(isCampaignArchived);
  const selectedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const bidOptimizationOnLabel = intl.formatMessage({
    id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION_ON,
  });
  const bidOptimizationOffLabel = intl.formatMessage({
    id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION_OFF,
  });

  const getTextColumnLabel = ({
    channelSettings: { bidOptimization },
    campaignDetails: { campaignAdFormat },
  }: CampaignDetails) => {
    if (isCampaignAdFormatProductCollectionOrSpotlight(campaignAdFormat)) {
      return getColumnLabel(
        bidOptimization,
        bidOptimizationOnLabel,
        bidOptimizationOffLabel
      );
    }
  };

  if (!props.isEditMode) {
    return makeTextColumn<CampaignDetails>(
      getTextColumnLabel,
      props.pendingFields?.includes(
        CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization
      )
    )(props);
  }

  if (isCampaignAdFormatEqualToVideo(props.campaignDetails.campaignAdFormat)) {
    return makeTextColumn<CampaignDetails>(() => undefined)(props);
  }

  const getSelectProps = ({
    channelSettings: { bidOptimization },
  }: CampaignDetails) => {
    const getSelectOptions = (): SelectOptionProps<string>[] => {
      return [
        {
          label: bidOptimizationOnLabel,
          value: AmazonBidOptimization.True,
        },
        {
          label: bidOptimizationOffLabel,
          value: AmazonBidOptimization.False,
        },
      ];
    };

    const bidOptimizationSelectValue = bidOptimization
      ? AmazonBidOptimization.True
      : AmazonBidOptimization.False;

    const bidOptimizationPlaceholderValue = bidOptimization
      ? bidOptimizationOnLabel
      : bidOptimizationOffLabel;

    const selectValue = isNil(selectedValue)
      ? bidOptimizationSelectValue
      : selectedValue;

    const placeholderValue = isNil(selectedValue)
      ? bidOptimizationPlaceholderValue
      : selectedValue;

    const onChange = (newValue: string) => {
      dispatch(
        tableActions.updateCell({
          tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
          rowId: props.campaignId,
          columnName: CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization,
          existingValue: bidOptimizationSelectValue,
          value: newValue,
        })
      );
    };

    const isDirty = selectValue !== bidOptimizationSelectValue;

    const selectProps: SelectProps<string> & TypedTooltipProps = {
      options: getSelectOptions(),
      value: selectValue,
      onChange,
      placeholder: placeholderValue,
      className: 'w-120',
      isDirty,
      state: selectState,
      dataTestId: CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization,
    };
    return selectProps;
  };

  const toolTipContentForArchivedEntity = getTooltipContentForArchivedEntity(
    intl.formatMessage({
      id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
    }),
    intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION,
    }),
    intl
  );

  const getTooltipContent = (
    isCampaignInArchivedState: boolean,
    isCampaignAdFormatProductCollection: boolean
  ) => {
    if (isCampaignInArchivedState) {
      return toolTipContentForArchivedEntity;
    } else if (!isCampaignAdFormatProductCollection) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_DISABLED_AMAZON_BID_OPTIMIZATION,
          })}
        </p>
      );
    }
  };

  const getTootltipProps = ({
    campaignDetails: { campaignAdFormat },
  }: CampaignDetails) => {
    const isCampaignOfTypeProductCollection =
      isCampaignAdFormatProductCollectionOrSpotlight(campaignAdFormat);
    return {
      tooltipContent: getTooltipContent(
        isCampaignArchived,
        isCampaignOfTypeProductCollection
      ),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      controlledTooltip: ControlledTooltip.None,
    };
  };

  return makeSelectColumn<CampaignDetails>(
    getSelectProps,
    getTootltipProps
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const amazonBidOptimizationColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION,
  RowCellElement,
  gridColumnWidth: '140px',
};

export const amazonBidOptimizationFilter = createMultiSelectDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION,
  [
    SelectFilterOption.AmazonBidOptimizationOn,
    SelectFilterOption.AmazonBidOptimizationOff,
  ]
);
