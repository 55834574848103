import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  DownloadIcon,
  Error,
  Type,
} from '@teikametrics/tm-design-system';

import {
  NotificationContext,
  NotificationContextState,
} from '../../../../containers/notificationProvider';
import { getCurrentAccountFromContext } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { SKUApiClient } from '../../../../lib/clients/SKUApiClient';
import { useUserCredentials } from '../../../../lib/hooks/useUserCredentials';
import {
  API_REQUEST_DATE_FORMAT,
  AggregationPeriodType,
  ExportBusinessMetricsRequest,
  HomepageResponseMetricKeys,
  PerformanceFieldsBusiness,
} from '../../../../lib/types/CompassSharedTypes';
import I18nKey from '../../../../lib/types/I18nKey';
import { CompassContextSetterContext } from '../../compassContextSetterProvider';
import { useDownloadFile } from '../../../../lib/hooks/downloadFile';

export const EXPORT_DATE_FORMAT = 'MM-dd-yyyy';
const ERROR_MESSAGE_TIMEOUT = 5000;
const EXPORT_BUTTON_LABEL = (
  <>
    <FormattedMessage id={I18nKey.COMPASS_DASHBOARD_EXPORT_DATA_LABEL} />
  </>
);

interface ExportWithOptionsBusinessProps {
  readonly skuApiClient: SKUApiClient;
}
export const ExportWithOptionsBusiness: React.FC<
  ExportWithOptionsBusinessProps
> = ({ skuApiClient }) => {
  const intl = useIntl();
  const toasts = useContext<NotificationContextState>(NotificationContext);

  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;
  const userCredentials = useUserCredentials();

  const {
    contextSetterValues: {
      selectedDateRange: { startDate, endDate },
      selectedMerchantsId,
      selectedCurrency,
    },
  } = useContext(CompassContextSetterContext);

  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.Enabled
  );
  const [showError, setShowError] = useState<boolean>(false);

  const csvExportDefaultValues = getCsvExportValues();

  const fileName = getFileName(
    'business-performance',
    DateTime.now(),
    userCredentials.companyName
  );

  const apiDefinition = async (values: string[]) => {
    const requestBody: ExportBusinessMetricsRequest = {
      merchantCountryIds: selectedMerchantsId,
      performanceFields: values as PerformanceFieldsBusiness[],
      metricsPeriodType: AggregationPeriodType.Current,
      startDate: startDate.toFormat(API_REQUEST_DATE_FORMAT),
      endDate: endDate.toFormat(API_REQUEST_DATE_FORMAT),
      currency: selectedCurrency,
    };

    return skuApiClient.exportCompassBusinessPerformanceCsvData(
      accountId,
      requestBody
    );
  };

  const postExportFile = () => {
    setButtonState(ButtonState.Enabled);
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_FILE_DOWNLOAD_SUCCESS_TITLE,
      }),
      description: intl.formatMessage(
        { id: I18nKey.COMPASS_DASHBOARD_FILE_DOWNLOAD_SUCCESS_DESCRIPTION },
        {
          fileName: fileName,
        }
      ),
      type: Type.Success,
    });
  };

  const onExport = async () => {
    return apiDefinition(csvExportDefaultValues);
  };

  const { downloadFile } = useDownloadFile({
    asyncFunction: onExport,
    preDownloadFile: () => {
      setButtonState(ButtonState.Loading);
      setShowError(false);
    },
    postDownloadFile: postExportFile,
    onError: () => {
      setShowError(true);
      window.setTimeout(() => setShowError(false), ERROR_MESSAGE_TIMEOUT);
      setButtonState(ButtonState.Enabled);
    },
    fileName: fileName,
  });

  return (
    <div className="w-192 mt-12 ml-12">
      <Button
        variant={ButtonVariant.Primary}
        size={ButtonSize.Large}
        label={EXPORT_BUTTON_LABEL}
        onClick={() => downloadFile()}
        state={buttonState}
        svgIcon={DownloadIcon}
        className="w-full flex items-center justify-center"
        dataTestId="1com_BusinessPerformance_ExportCSV"
      />
      {showError && <Error />}
    </div>
  );
};
ExportWithOptionsBusiness.displayName = 'ExportWithOptionsBusiness';

export const getFileName = (
  currentTab: string,
  todaysDate: DateTime,
  companyName?: string
) =>
  `${companyName || ''}--${todaysDate.toFormat(
    EXPORT_DATE_FORMAT
  )}--${currentTab}.csv`;

export const getCsvExportValues = (
  includeSelectedFields?: boolean,
  selectedFields?: HomepageResponseMetricKeys[]
) =>
  includeSelectedFields
    ? selectedFields || []
    : [
        PerformanceFieldsBusiness.AdSales,
        PerformanceFieldsBusiness.AdSpend,
        PerformanceFieldsBusiness.Tacos,
        PerformanceFieldsBusiness.TotalSales,
      ];
