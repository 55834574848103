import { Factory } from 'fishery';

import { AverageProductRankData } from '../../types/MISharedTypes';

export default Factory.define<AverageProductRankData>(() => ({
  product_id: '803813618',
  url: 'https://www.walmart.com/ip/803813618',
  title: "New Balance Fresh Foam Arishi Trail Running Shoe (Women''s)",
  image:
    'https://i5.walmartimages.com/asr/6a7cfb55-3203-4ece-abfc-72c792537564_1.109bac0432df12877c80d7644322eb88.jpeg?odnHeight=376&odnWidth=282&odnBg=ffffff',
  brand_name: 'New Balance',
  appearances_percentage: 1,
  avg_rank_total: 17,
  avg_rank_paid: 16,
  avg_rank_organic: 17,
}));
