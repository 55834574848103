import {
  Button,
  ButtonSize,
  ButtonVariant,
  ColumnAlign,
  EmDash,
  EyeIcon,
  Tooltip,
} from '@teikametrics/tm-design-system';
import { useIntl } from 'react-intl';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  KeywordActionColumns,
  KeywordRecommendationsData,
} from '../../../../../lib/types/KeywordAction';
import { RecommendationsTableV2ExtraProps } from '../types';
import { SourcesSlideover } from '../../SourcesSlideover';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../lib/utilities/intercom';

export const Cell: React.FC<
  KeywordRecommendationsData & RecommendationsTableV2ExtraProps
> = ({
  merchantCountry,
  keyword: { sourcesV2, tag, text },
  suggestedDestination: {
    adGroup: { targetSegments },
  },
}) => {
  const intl = useIntl();
  const salesChannel = merchantCountry.salesChannelId!;

  const [showSources, setShowSources] = useState<boolean>(false);

  const onShowClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowSources(true);
  };

  const onClose = () => setShowSources(false);

  useEffect(() => {
    if (showSources) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [showSources]);

  if (!sourcesV2) {
    return <EmDash />;
  }

  return (
    <>
      <div className="flex h-full items-center justify-end gap-4">
        {sourcesV2.length}
        <Tooltip
          content={intl.formatMessage(
            { id: I18nKey.KWA_SOURCES_VIEW_TOOLTIP },
            { count: sourcesV2.length }
          )}
          delayShow={750}
        >
          <Button
            size={ButtonSize.InlineIcon}
            variant={ButtonVariant.BlackAndWhite}
            svgIcon={EyeIcon}
            className="group"
            iconClassName="text-grey-500 group-hover:text-grey-900"
            onClick={onShowClick}
          />
        </Tooltip>
      </div>
      {showSources && (
        <SourcesSlideover
          salesChannel={salesChannel}
          keyword={{ tag, text }}
          targetSegments={targetSegments}
          sources={sourcesV2}
          merchantCountry={merchantCountry}
          onClose={onClose}
        />
      )}
    </>
  );
};
Cell.displayName = 'KWACellSourcesV2';

export const sources: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  RecommendationsTableV2ExtraProps,
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SOURCES_HEADER,
  accessorKey: KeywordActionColumns.SOURCE,
  sortable: false,
  size: 80,
  minSize: 80,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell {...original} {...meta} />,
};
