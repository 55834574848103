import { PaginatedResponse } from '../../clients/types';
import {
  AdGroupInfo,
  TargetSegment,
  TargetingType,
} from '../../types/KeywordAction';

export const KwaValidDestinationsResponse: PaginatedResponse<AdGroupInfo> = {
  elements: [
    {
      name: 'adGroup 1',
      id: '1234',
      campaign: {
        id: '123',
        name: 'campaign 1',
        campaignType: '',
        targetingType: TargetingType.Auto,
      },
      targetSegment: TargetSegment.Brand,
    },
    {
      name: 'Official AdGroup NFT Logo T-Shirt - Non-Fungible Token|B09XJJ9PCL|Brand|SP-KW|TM',
      id: '1234',
      campaign: {
        id: '123',
        name: 'Official Campaign NFT Logo T-Shirt - Non-Fungible Token|B09XJJ9PCL|Brand|SP-KW|TM',
        campaignType: '',
        targetingType: TargetingType.Manual,
      },
      targetSegment: TargetSegment.Competitor,
    },
    {
      name: 'adGroup 2',
      id: '1234',
      campaign: {
        id: '123',
        name: 'campaign 1',
        campaignType: '',
        targetingType: TargetingType.Auto,
      },
      targetSegment: TargetSegment.Generic,
    },
    {
      name: 'adGroup 2',
      id: '1234',
      campaign: {
        id: '123',
        name: 'campaign 1',
        campaignType: '',
        targetingType: TargetingType.Auto,
      },
      targetSegment: TargetSegment.Unknown,
    },
    {
      name: 'adGroup 3',
      id: '1234',
      campaign: {
        id: '123',
        name: 'campaign 1',
        campaignType: '',
        targetingType: TargetingType.Auto,
      },
      targetSegment: TargetSegment.Brand,
    },
    {
      name: 'adGroup 2',
      id: '1234',
      campaign: {
        id: '123',
        name: 'campaign 1',
        campaignType: '',
        targetingType: TargetingType.Manual,
      },
      targetSegment: TargetSegment.Competitor,
    },
    {
      name: 'adGroup 2',
      id: '1234',
      campaign: {
        id: '123',
        name: 'campaign 1',
        campaignType: '',
        targetingType: TargetingType.Auto,
      },
      targetSegment: TargetSegment.Generic,
    },
    {
      name: 'adGroup 2',
      id: '1234',
      campaign: {
        id: '123',
        name: 'campaign 1',
        campaignType: '',
        targetingType: TargetingType.Auto,
      },
      targetSegment: TargetSegment.Unknown,
    },
  ],
  filteredElements: 499,
};
