import isUndefined from 'lodash/isUndefined';
import {
  TableCellChange,
  UPDATE_CELL_VALIDATION_DATA_KEY,
} from '../../../../../containers/table/ducks/types';
import { ADGROUPS_API_COLUMN_NAME } from '../utils';

export const isAdGroupNameColumnDuplicateWithinEditedCellsOfSameCampaign = (
  editedName: string,
  updatedCells: TableCellChange,
  adGroupId: string
): boolean => {
  const updatedRowData = updatedCells[adGroupId];

  const campaignIdOfTheEditedRow = !isUndefined(updatedRowData)
    ? updatedRowData[UPDATE_CELL_VALIDATION_DATA_KEY]
    : undefined;

  const adGroupNamesForCampaign = Object.values(updatedCells)
    .map((value) => ({
      adGroupName: value[ADGROUPS_API_COLUMN_NAME.AdGroupName],
      campaignId: value[UPDATE_CELL_VALIDATION_DATA_KEY],
    }))
    .filter((ele) => ele.campaignId === campaignIdOfTheEditedRow);

  const maybeDuplicateName = adGroupNamesForCampaign.filter(
    (ele) => ele.adGroupName === editedName
  );

  // >1 because the one which is being compared needs to be ignored.
  return maybeDuplicateName.length > 1;
};
