import { BadgeCampaignGoal } from '@teikametrics/tm-design-system';
import { CampaignTargetSegment } from './AIPoweredSharedTypes';
import I18nKey from './I18nKey';

export enum AvailabilityStatus {
  Ready = 'READY',
  Error = 'ERROR',
  NotReady = 'NOT_READY',
}
export enum TargetKeywordEditType {
  ALL = 'All',
  SINGLE = 'SINGLE',
}

export enum Status {
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

export enum campaignStrategyCard {
  BASIC = 'BASIC',
  BRAND_BASED = 'BRAND_BASED',
  ADVANCED = 'ADVANCED',
  CUSTOM = 'CUSTOM',
  EMPTY_STRING = '',
}

export enum campaignTypeCard {
  Keyword = 'Keyword',
  Pat = 'PAT',
  EMPTY_STRING = '',
}

export enum CampaignProductAdGroupProductGrouping {
  Parent = 'PARENT',
  Sku = 'SKU',
  Shelf = 'SHELF',
  Category = 'CATEGORY',
  BaseItem = 'BASE_ITEM',
}

export enum CampaignStructure {
  AutoManual = 'AUTO_MANUAL',
  AutoBrandNonBrand = 'AUTO_BRAND_NONBRAND',
  AutoBrandCompetitorGeneric = 'AUTO_BRAND_COMPETITOR_GENERIC',
  SinglePAT = 'AUTO_SINGLE',
  CompetitorCategoryPAT = 'AUTO_COMPETITOR',
  BrandCompetitorCategoryPAT = 'AUTO_BRAND_COMPETITOR',
}

export enum AdGroupStructure {
  Single = 'SINGLE',
  BrandNonBrand = 'BRAND_NONBRAND',
  BrandCompetitorGeneric = 'BRAND_COMPETITOR_GENERIC',
  CompetitorCategoryPAT = 'COMPETITOR',
  BrandCompetitorCategoryPAT = 'BRAND_COMPETITOR',
}

export enum ParentType {
  Parent = 'parent',
  Standalone = 'standalone',
}

export enum CampaignStructureGenerationStatus {
  Ready = 'READY',
  InProgress = 'IN_PROGRESS',
  Error = 'ERROR',
}

export enum MatchType {
  Exact = 'exact',
  Broad = 'broad',
  Phrase = 'phrase',
  NegativeExact = 'negativeExact',
  NegativePhrase = 'negativePhrase',
  Expanded = 'expanded',
}

export enum MatchTypeASIN {
  Exact = 'ASIN_SAME_AS',
  Expanded = 'ASIN_EXPANDED_FROM',
}

export enum TargetingType {
  Auto = 'Auto',
  Manual = 'Manual',
}

export enum TargetingTypeV2 {
  Auto = 'auto',
  Manual = 'manual',
}

export interface MerchantAvailabilityStatus {
  readonly merchantCountryId: string;
  readonly status: AvailabilityStatus;
}

export interface ItemDetails {
  readonly name: string;
  readonly productCatalogId: string;
  readonly sku: string;
  readonly imageUrl?: string;
  readonly extItemId: string;
  readonly productCategory: string;
  readonly topCategory: string;
  readonly productId: string;
  readonly extItemType: string;
  readonly campaignCount: number;
  readonly inventoryQuantity: number;
  readonly totalSales: number;
  readonly extParentItemId: string | null;
  readonly adSales: number;
  readonly adSpend: number;
  readonly unitsSold: number;
  readonly clicks: number;
  readonly adConversions: number;
  readonly averageOrderPrice: number | null;
  readonly impressions: number;
  readonly parentIdentifier: string;
  readonly estimatedPreAdGrossMargin?: number;
  readonly preAdGrossMarginFields?: PreAdGrossMarginFields;
  readonly unitCogs?: number;
}

export interface AdGroupProduct {
  readonly name: string;
  readonly id: string;
  readonly imageUrl: string;
  readonly extItemId: string;
  readonly productCatalogId: string;
  readonly sku: string;
}

export interface AdGroup {
  readonly id: string;
  readonly name: string;
  readonly acosTarget: number;
  readonly treAutomation: boolean;
  readonly targetSegment?: CampaignTargetSegment[];
  readonly products: AdGroupProduct[];
  readonly selected: boolean;
  readonly advertisingGoal?: string;
  readonly targetCount?: number;
  readonly negativeTargetCount?: number;
}

export interface MerchantAvailabilityResponse {
  readonly availability: MerchantAvailabilityStatus[];
}

export interface MerchantAvailabilityRequest {
  readonly merchantCountries: {
    merchantCountryId: string;
    salesChannelId?: string;
  }[];
}
export interface FilterItemItem {}

export interface ListEligibleProductRequest {
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly merchantType: string;
  readonly runId: string;
  readonly productGrouping: CampaignProductAdGroupProductGrouping;
  readonly searchText?: string;
  readonly advertisedStatus?: string[];
}

export interface ParentDetailsItem {
  readonly extItemId: string;
  readonly imageUrl?: string;
  readonly name: string;
  readonly sku?: string;
  readonly parentIdentifier: string;
  readonly preAdGrossMargin?: number;
}

export interface ParentDetails {
  [x: string]: ParentDetailsItem;
}

export interface ListEligibleProductResponseItem {
  readonly groupId: string;
  readonly children: ItemDetails[];
  readonly inventoryQuantity: number;
  readonly totalSales: number;
  readonly estimatedPreAdGrossMargin?: number;
  readonly preAdGrossMargin?: number;
  readonly unitCogs?: number;
  readonly unitsSold?: number;
  readonly productCatalogId?: string;
}

export interface ListEligibleProductResponse {
  readonly elements: ListEligibleProductResponseItem[];
  filteredElements: number;
  readonly groupType: CampaignProductAdGroupProductGrouping;
  readonly runId: string;
  readonly parentDetails: ParentDetails;
  readonly status?: any;
}

export interface CampaignBulkKeywordSaveRequest {
  updateAiKeywords: any[];
  deleteKeywords: any[];
  userCreatedKeywords: any[];
  runId: string;
  salesChannelId: string;
}

export interface CampaignBulkPATSaveRequest {
  updateTargets: any[];
  updateAiKeywords: any[];
  deleteTargetRequests: any[];
  userCreatedTargets: any[];
  runId: string;
  salesChannelId: string;
}

export interface RecommendedKeywordsResponse {
  readonly elements: any[];
  readonly filteredElements: number;
}

export interface EligibleAdGroupsResponse {
  readonly elements: any[];
  readonly filteredElements: number;
}

export interface EligibleProductCountResponse {
  readonly fullCount: number;
}

export type EligibleProductElement = {
  readonly runId: string;
  readonly groupType: CampaignProductAdGroupProductGrouping;
  readonly groupId: string;
  readonly totalSales: number;
  readonly name?: string;
  readonly productCatalogId?: string;
  readonly sku?: string;
  readonly imageUrl?: string;
  readonly extItemId?: string;
  readonly productCategory?: string;
  readonly topCategory?: string;
  readonly productId?: string;
  readonly extItemType?: string;
  readonly campaignCount: number;
  readonly inventoryQuantity: number;
  readonly subRows?: ItemDetails[];
  readonly extParentItemId?: string | null;
  readonly adSales?: number;
  readonly adSpend?: number;
  readonly unitsSold?: number;
  readonly clicks?: number;
  readonly adConversions?: number;
  readonly averageOrderPrice?: number | null;
  readonly impressions?: number;
  readonly estimatedPreAdGrossMargin?: number;
  readonly preAdGrossMargin?: number;
  readonly preAdGrossMarginFields?: PreAdGrossMarginFields;
  readonly unitCogs?: number;
  readonly isParent?: boolean;
  readonly parentDetails?: ParentDetailsItem;
  readonly parentIdentifier?: string;
  readonly status?: any;
};

export interface PreAdGrossMarginFields {
  readonly avgSalePrice?: number;
  readonly cogs?: number;
  readonly dailyRateOfSales?: number;
  readonly platformFees?: number;
  readonly preAdGrossMargin?: number;
}

export interface ProductDetails {
  readonly name?: string;
  readonly productCatalogId?: string;
  readonly sku?: string;
  readonly imageUrl?: string;
  readonly extItemId?: string;
  readonly productCategory?: string;
  readonly topCategory?: string;
  readonly groupId: string;
  readonly parent: boolean;
  readonly extParentItemId?: string | null;
  readonly parentIdentifier?: string;
  readonly parentProductName?: string;
  readonly totalSales?: number;
  readonly parentSku?: string;
  readonly adSales?: number;
  readonly adSpend?: number;
  readonly unitsSold?: number;
  readonly clicks?: number;
  readonly adConversions?: number;
  readonly avgPrice?: number | null;
  readonly impressions?: number;
  readonly preAdGrossMarginFields?: PreAdGrossMarginFields;
}

export interface GenerateCampaignStructureRequest {
  readonly salesChannelId: string;
  readonly runId: string;
  readonly products: ProductDetails[];
  readonly allProductsSelected: boolean;
  readonly campaignProductGrouping: CampaignProductAdGroupProductGrouping;
  readonly campaignStructure: CampaignStructure;
  readonly adGroupProductsGrouping: CampaignProductAdGroupProductGrouping;
  readonly adGroupStructure: AdGroupStructure;
  readonly preSelectedStructure?: string;
  readonly preSelectedTargetType?: string;
  readonly merchantType: string;
  readonly countryCode: string;
  readonly autoCampaignIncluded?: boolean;
}

export interface GenerateCampaignStructureV2Request {
  readonly salesChannelId: string;
  readonly runId: string;
  readonly products: ProductDetails[];
  readonly allProductsSelected: boolean;
  readonly campaignProductGrouping: CampaignProductAdGroupProductGrouping;
  readonly campaignStructure: CampaignStructure;
  readonly adGroupProductsGrouping: CampaignProductAdGroupProductGrouping;
  readonly adGroupStructure: AdGroupStructure;
  readonly preSelectedStructure?: string;
  readonly preSelectedTargetType?: string;
  readonly merchantType: string;
  readonly countryCode: string;
  readonly autoCampaignIncluded?: boolean;
  readonly groupSettings: GroupSettings[];
}

export interface GroupSettings {
  readonly campaignAdvertisingGoal: string;
  readonly groupId: string;
  readonly preAdGrossMargin?: number;
}

export interface GenerateCampaignStructureResponse {
  readonly status: string;
  readonly runId: string;
}

export interface CampaignStructureGenerationStatusResponse {
  readonly status: CampaignStructureGenerationStatus;
  readonly errorMessage: string;
}

export interface CampaignStructurePreviewRequest {
  readonly limit: number;
  readonly offset: number;
}

export interface CampaignStructureSummaryRequest {
  readonly salesChannelId?: string;
  readonly merchantType?: string;
}

export interface CampaignStructureSummaryResponse {
  adGroupAddAutomaticallyCount: number;
  adGroupReviewTargetsCount: number;
  currency: string;
  imageUrls: string[];
  keywordsIncluded: boolean;
  maxAcos: number;
  minAcos: number;
  runParallelProductCount: number;
  totalAdGroups: number;
  totalProductAds: number;
  totalCampaigns: number;
  totalDailyBudget: number;
  totalProducts: number;
  totalSelectedKeywords: number;
  totalUserAddedKeywords: number;
}

export interface CampaignCreateRequest {
  readonly id: string;
  readonly name: string;
  readonly dailyBudget: number;
  readonly currency: string;
  readonly selected: boolean;
  readonly adGroups: AdGroup[];
  readonly targetingType: TargetingType;
  readonly advertisingGoal?: string;
}

export interface CampaignStructurePreviewData {
  readonly groupId: string;
  readonly groupType: string;
  readonly campaigns: CampaignCreateRequest[];
  readonly minAcos: number;
  readonly maxAcos: number;
  readonly totalBudget: number;
  readonly totalSku: number;
  readonly preAdGrossMargin?: number;
  readonly parentItemDetails?: ParentDetailsItem;
}

export interface CampaignStructurePreviewResponse {
  readonly totalCount: number;
  readonly keywordsIncluded: boolean;
  readonly data: CampaignStructurePreviewData[];
}

export interface ModifyKeywordFlagResponse {
  readonly status: string;
  readonly isAiKeywordsSelectionChangeNeeded: boolean;
  readonly isTreAvailable: boolean;
}

export interface DailyAdBudgetResponse {
  campaignId: string;
  campaignName: string;
  dailyBudget: number;
  campaignTargetingType?: string;
  advertisingGoal?: string;
}

export interface DailyAdBudgetResponseV2 {
  readonly groupId: string;
  readonly campaignsBudget: DailyAdBudgetResponse[];
  readonly products: AdGroupProduct[];
}

export interface AdGroupACOSResponse {
  acosTarget: number;
  adGroupId: string;
  adGroupName: string;
  targetSegments?: string[];
}

export interface CampaignsAdgroupTarget {
  readonly campaignId: string;
  readonly campaignName: string;
  readonly advertisingGoal?: string;
  readonly campaignTargetingType: string;
  readonly adgroupAcosTarget: AdGroupACOSResponse[];
}

export interface AdGroupACOSResponseV2 {
  readonly groupId: string;
  readonly products: AdGroupProduct[];
  readonly campaignAdGroupAcosTarget: CampaignsAdgroupTarget[];
}

export interface AdGroupTreResponse {
  adGroupId: string;
  adGroupName: string;
  targetSegments: any[];
  treAutomation: boolean;
}

export interface AdProducts {
  readonly adProductId: string;
  readonly extItemId: string;
  readonly imageUrl?: string;
  readonly productName: string;
}

export interface AdGroupSKUsRunPause {
  readonly adGroupId: string;
  readonly adGroupName: string;
  readonly campaignId?: string;
  readonly campaignName?: string;
  readonly targetingType?: string;
  readonly targetSegments?: string[];
  readonly createdBy?: string;
  readonly toPause: boolean;
  readonly adProducts: AdProducts[];
}

export interface AdGroupSKUsRunPauseResponse {
  readonly adGroups: AdGroupSKUsRunPause[];
}

export interface ModifyTreAutomationFlagRequest {
  readonly adGroupId: string;
  readonly runId: string;
  readonly treAutomation: boolean;
}

export interface ModifyBulkDailyBudgetRequest {
  readonly merchantType?: string;
  readonly runId: string;
  readonly salesChannelId: string;
  readonly campaignDailyBudget: DailyAdBudgetResponse[];
}
export interface ModifyBulkACOsTargetRequest {
  readonly merchantType?: string;
  readonly runId: string;
  readonly salesChannelId?: string;
  readonly acosTargetRequests: {
    readonly adGroupId: string;
    readonly acosTarget: number;
  }[];
}

export interface ModifyBulkSKUsRunPauseRequest {
  readonly merchantType?: string;
  readonly runId: string;
  readonly salesChannelId?: string;
  readonly pausedAdGroups: {
    readonly adGroupId: string;
    readonly toPause: boolean;
    readonly groupId?: string;
  }[];
}

export interface ModifyBulkTreKeywordRequest {
  readonly merchantType?: string;
  readonly runId: string;
  readonly salesChannelId?: string;
  readonly adgroupTreAutomation: {
    readonly adGroupId: string;
    readonly treAutomationEnabled: boolean;
  }[];
}

export interface ModifyKeywordFlagRequest {
  readonly runId: string;
  readonly checkAiKeywordsSelectionChangeNeeded: boolean;
  readonly keywordsIncluded: boolean;
}

export interface ModifyAcosRequest {
  readonly campaignId: string;
  readonly adGroupId: string;
  readonly acosTarget: number;
  readonly runId: string;
}

export interface ModifyBudgetRequest {
  readonly campaignId: string;
  readonly salesChannelId: string | undefined;
  readonly dailyBudget: number;
  readonly runId: string;
  readonly merchantType: string;
}

export interface ModifyCampaignNameRequest {
  readonly campaignId: string;
  readonly newName: string;
  readonly runId: string;
}

export interface ModifyAdGroupNameRequest {
  readonly adGroupId: string;
  readonly newName: string;
  readonly runId: string;
}

export interface DeleteCampaignRequest {
  readonly campaignId: string;
}

export interface DeleteAdGroupRequest {
  readonly campaignId: string;
  readonly adGroupId: string;
}

export interface LaunchCampaignRequest {
  readonly runId: string;
  readonly startDate: string;
  readonly endDate: string | null;
}

export interface SelectedProducts {
  [id: string]: EligibleProductElement;
}

export interface ProductsStatus {
  [id: string]: string;
}

export interface SelectedProductsAggregation {
  readonly selectedRowIds: string[];
  readonly parentCount: number;
  readonly skuCount: number;
}
export interface PerformanceData {
  clicks: number;
  conversions: number;
}
export enum TargetSegment {
  Generic = 'Generic',
  Brand = 'Brand',
  Competitor = 'Competitor',
  Auto = 'Auto',
}
export interface TargetInfo {
  isNew?: boolean;
  searchText: string;
  matchType: MatchType | MatchTypeASIN;
  selected: boolean;
  targetId: string;
  adgroupId?: string;
  userCreated?: boolean;
  targetSegment?: TargetSegment;
  aggregatedPerformanceData?: PerformanceData;
  adgroupInfo?: any;
  totalTargets?: number;
  ownProduct?: boolean;
  asin?: string;
  productInfoMetadata?: any;
}

export interface KeywordTargetsRequest {
  readonly adGroupId: string;
  readonly salesChannelId: string;
}

export interface KeywordTargetsResponse {
  readonly status: CampaignStructureGenerationStatus;
  readonly targets: TargetInfo[];
  readonly errorMessage: string;
}

export interface ModifyExistingAdsPauseFlagRequest {
  readonly runId: string;
  readonly groupId: string;
  readonly productAdsPaused: boolean;
}

export interface UpdateCampaignSelectionRequest {
  readonly runId: string;
  readonly updates: { [x: string]: boolean };
}

export interface ModifyResponse {
  readonly status: Status;
  readonly message?: string;
}

export enum Errors {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
}

export const MAP_ERRORS_MESSAGE: Record<string, I18nKey> = {
  [Errors.ALREADY_EXISTS]: I18nKey.GENERIC_NAME_ALREADY_EXISTS_WENT_WRONG,
};

export interface CampaignData {
  [x: string]: { selected: boolean; adGroups: { [x: string]: boolean } };
}

export interface CampaignDataDailyBudgetConfig {
  [x: string]: {
    [x: string]: {
      name: string;
      dailyBudget: number;
      adGroups: {
        [x: string]: {
          acosTarget: number;
          name: string;
          treAutomation: boolean;
        };
      };
    };
  };
}

export interface CampaignAddKeywordsConfig {
  [x: string]: boolean;
}

interface AddRemoveKeywords {
  readonly targetId: string;
  readonly selected: boolean;
}
export interface CampaignAddRemoveKeywordsRequest {
  salesChannelId: string;
  runId: string;
  adgroupId: string;
  updates: AddRemoveKeywords[];
}

export interface KeywordActionsPayload {
  salesChannelId: string | undefined;
  runId: string;
  adgroupId: string;
  deletedKeywords: string[];
  userKeywords: {
    matchType: string;
    searchText: string;
  }[];
}

interface CampaignSelection {
  readonly selectedProductGrouping?: CampaignProductAdGroupProductGrouping;
  readonly selectedAddGroupSplit?: CampaignProductAdGroupProductGrouping;
  readonly selectedCampaignStructure?: CampaignStructure;
  readonly selectedAdGroupSetup?: AdGroupStructure;
}

export const MAP_AMAZON_CHANNEL_NAME_TO_ID_PAT: Record<
  campaignStrategyCard,
  CampaignSelection
> = {
  [campaignStrategyCard.BASIC]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.SinglePAT,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.ADVANCED]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.BrandCompetitorCategoryPAT,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.BRAND_BASED]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.CompetitorCategoryPAT,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.CUSTOM]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.SinglePAT,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.EMPTY_STRING]: {},
};

export const MAP_AMAZON_CHANNEL_NAME_TO_ID: Record<
  campaignStrategyCard,
  CampaignSelection
> = {
  [campaignStrategyCard.BASIC]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.AutoManual,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.ADVANCED]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.AutoBrandCompetitorGeneric,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.BRAND_BASED]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.AutoBrandNonBrand,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.CUSTOM]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.AutoManual,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.EMPTY_STRING]: {},
};

export const MAP_STRATEGY_NAME_TO_ID: Record<campaignStrategyCard, string> = {
  [campaignStrategyCard.BASIC]: 'Basic',
  [campaignStrategyCard.ADVANCED]: 'Advanced',
  [campaignStrategyCard.BRAND_BASED]: 'Intermediate',
  [campaignStrategyCard.CUSTOM]: 'Custom',
  [campaignStrategyCard.EMPTY_STRING]: '',
};

export const MAP_PRODUCT_ADDGROUP_STRATEGY_NAME_TO_ID: Record<
  CampaignProductAdGroupProductGrouping,
  string
> = {
  [CampaignProductAdGroupProductGrouping.BaseItem]: 'BaseItem',
  [CampaignProductAdGroupProductGrouping.Category]: 'Category',
  [CampaignProductAdGroupProductGrouping.Parent]: 'Parent Product',
  [CampaignProductAdGroupProductGrouping.Shelf]: 'Shelf',
  [CampaignProductAdGroupProductGrouping.Sku]: 'SKU',
};

export const MAP_TARGET_TYPE_ID: Record<campaignTypeCard, string> = {
  [campaignTypeCard.Keyword]: 'Sponsored products keyword targeting',
  [campaignTypeCard.Pat]: 'Product attribute targeting (PAT)',
  [campaignTypeCard.EMPTY_STRING]: '',
};

export const MAP_AD_GROUP_TARGET_STRATEGY_ID: Record<AdGroupStructure, string> =
  {
    [AdGroupStructure.Single]: 'Single ad group',
    [AdGroupStructure.BrandNonBrand]: 'Brand + Non brand',
    [AdGroupStructure.BrandCompetitorGeneric]: 'Brand + Competitor + Generic',
    [AdGroupStructure.CompetitorCategoryPAT]: 'Competitor PAT',
    [AdGroupStructure.BrandCompetitorCategoryPAT]: 'Brand PAT + Competitor PAT',
  };

export const MAP_CAMPAIGN_WITH_AUTO_TARGET_STRATEGY_ID: Record<
  CampaignStructure,
  string
> = {
  [CampaignStructure.AutoManual]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOMANUAL,
  [CampaignStructure.AutoBrandNonBrand]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOBRANDNONBRAND,
  [CampaignStructure.AutoBrandCompetitorGeneric]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOBRANDCOMPETITORGENERIC,
  [CampaignStructure.SinglePAT]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTO_SINGLE_PAT,
  [CampaignStructure.CompetitorCategoryPAT]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTO_COMPETITOR_CATEGORY,
  [CampaignStructure.BrandCompetitorCategoryPAT]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTO_BRAND_COMPETITOR_CATEGORY,
};

export const MAP_CAMPAIGN_WITHOUT_AUTO_TARGET_STRATEGY_ID: Record<
  CampaignStructure,
  string
> = {
  [CampaignStructure.AutoManual]:
    I18nKey.CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_MANUAL_TITLE,
  [CampaignStructure.AutoBrandNonBrand]:
    I18nKey.CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_BRAND_NONBRAND_TITLE,
  [CampaignStructure.AutoBrandCompetitorGeneric]:
    I18nKey.CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_BRAND_COMPETITOR_GENERIC_TITLE,
  [CampaignStructure.SinglePAT]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_SINGLE_PAT,
  [CampaignStructure.CompetitorCategoryPAT]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_COMPETITOR_CATEGORY,
  [CampaignStructure.BrandCompetitorCategoryPAT]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_BRAND_COMPETITOR_CATEGORY,
};

export const MAP_WALMART_CHANNEL_NAME_TO_ID: Record<
  campaignStrategyCard,
  CampaignSelection
> = {
  [campaignStrategyCard.BASIC]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.AutoManual,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.ADVANCED]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.AutoManual,
    selectedAdGroupSetup: AdGroupStructure.BrandCompetitorGeneric,
  },
  [campaignStrategyCard.BRAND_BASED]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.AutoManual,
    selectedAdGroupSetup: AdGroupStructure.BrandNonBrand,
  },
  [campaignStrategyCard.CUSTOM]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.AutoManual,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.EMPTY_STRING]: {},
};

export const MAP_WALMART_CHANNEL_NAME_TO_ID_PAT: Record<
  campaignStrategyCard,
  CampaignSelection
> = {
  [campaignStrategyCard.BASIC]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.SinglePAT,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.ADVANCED]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.BrandCompetitorCategoryPAT,
    selectedAdGroupSetup: AdGroupStructure.BrandCompetitorCategoryPAT,
  },
  [campaignStrategyCard.BRAND_BASED]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.CompetitorCategoryPAT,
    selectedAdGroupSetup: AdGroupStructure.CompetitorCategoryPAT,
  },
  [campaignStrategyCard.CUSTOM]: {
    selectedProductGrouping: CampaignProductAdGroupProductGrouping.Parent,
    selectedAddGroupSplit: CampaignProductAdGroupProductGrouping.Parent,
    selectedCampaignStructure: CampaignStructure.SinglePAT,
    selectedAdGroupSetup: AdGroupStructure.Single,
  },
  [campaignStrategyCard.EMPTY_STRING]: {},
};

export type KeywordRecommendationsData = {
  readonly targetId: string;
  readonly searchText: string;
  readonly userCreated?: boolean;
  readonly matchType?: MatchType;
  readonly selected: boolean;
  readonly targetSegment?: TargetSegment;
  readonly aggregatedPerformanceData: {
    conversions?: number;
    impressions?: number;
    adSpend?: number;
    sales?: number;
    clicks?: number;
  };
  readonly adgroupInfo: {
    campaignName: string;
    targetingType?: TargetingType;
    targetSegments: TargetSegment[];
    campaignId: string;
    adGroupId: string;
    adGroupName: string;
    advertisingGoal?: string;
  };
  readonly source?: KeywordSourceType;
};

export enum KeywordSourceType {
  SEARCH_TERM_REPORT = 'SEARCH_TERM_REPORT',
  AMZ_SP_SUGGESTED_KEYWORD = 'AMZ_SP_SUGGESTED_KEYWORD',
  WMT_SP_KEYWORD_ANALYTIC = 'WMT_SP_KEYWORD_ANALYTIC',
  CHATGPT_SP_SUGGESTED_KEYWORD = 'CHATGPT_SP_SUGGESTED_KEYWORD',
}

export const MAP_KW_SOURCE_TYPE_TO_I18: Record<KeywordSourceType, I18nKey> = {
  [KeywordSourceType.SEARCH_TERM_REPORT]:
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_REASON_SEARCH_TERMS_GENERATED,
  [KeywordSourceType.AMZ_SP_SUGGESTED_KEYWORD]:
    I18nKey.KEYWORD_RECOMMENDATIONS_SOURCE_AMAZON_SUGGESTED_KEYWORD,
  [KeywordSourceType.WMT_SP_KEYWORD_ANALYTIC]:
    I18nKey.KEYWORD_RECOMMENDATIONS_SOURCE_WALMART_SUGGESTED_KEYWORD,
  [KeywordSourceType.CHATGPT_SP_SUGGESTED_KEYWORD]:
    I18nKey.KEYWORD_RECOMMENDATIONS_SOURCE_CHAT_GPT_SUGGESTED_KEYWORD,
};

export interface KeywordRecommendationsTableV2ExtraProps {
  handleDropdownSelection: (
    row: KeywordRecommendationsData,
    value: MatchType
  ) => void;
  updatedMatchTypeRows: Record<string, MatchType>;
}

export enum KeywordRecommendationsColumns {
  KEYWORD = 'searchText',
  MATCH_TYPE = 'matchType',
  CAMPAIGN_TYPE = 'campaignType',
  CAMPAIGN_TARGETING_TYPE = 'campaignTargetingType',
  REASON = 'conversion',
  CAMPAIGN_ADGROUP_NAME = 'adGroupName',
}
export interface ProductAddResponse {
  readonly elements: ProductsResponse[];
  readonly filteredElements: number;
}

export interface ProductsResponse {
  readonly extItemId: string;
  readonly imageUrl: string;
  readonly itemName: string;
  readonly sku: string;
}

export interface PATSummaryData {
  adGroupWithAsinAndCategoryTarget: number;
  adGroupWithAsinTarget: number;
  adGroupWithCategoryTarget: number;
  adGroupWithoutTarget: number;
  manualAdGroupReadyToLaunch: number;
  totalAsinTarget: number;
  totalCategoryTarget: number;
}

export interface PATSummaryNegativeData {
  totalCategoryTarget: number;
  totalAsinTarget: number;
}

export interface KeywordSummaryData {
  readonly manualAdGroupCount: number;
  readonly adGroupCountWithNoKeywords: number;
  readonly totalSelectedKeywordCount: number;
  readonly aiRecommendedKeywordCount: number;
  readonly userSelectedKeywordCount: number;
  readonly brandKeywordCount: number;
  readonly competitorKeywordCount: number;
  readonly genericKeywordCount: number;
  readonly unknownKeywordCount: number;
}

export enum EntityType {
  KEYWORD_TARGETING = 'KEYWORD_TARGETING',
  SP_NEGATIVE_KEYWORD_TARGET = 'SP_NEGATIVE_KEYWORD_TARGET',
  PRODUCT_ATTRIBUTE_TARGETING = 'PRODUCT_ATTRIBUTE_TARGETING',
  NEGATIVE_PRODUCT_ATTRIBUTE_TARGETING = 'NEGATIVE_PRODUCT_ATTRIBUTE_TARGETING',
}

export enum GroupBy {
  ADGROUP = 'ADGROUP',
  TARGET = 'TARGET',
}
export interface NameChangeSecureUrlResponse {
  readonly url: string;
}

export enum StatusFilterStatus {
  NotAdvertised = 'Unadvertised',
  Advertised = 'Advertised',
  nonSmartFilter = 'nonSmartFilter',
}

export enum ProductTableFilds {
  nonSmartFilter = 'nonSmartFilter',
  sku = 'sku',
  advertisedStatus = 'advertisedStatus',
  advertisingGoal = 'AdvertisingGoal',
  totalSales = 'totalSales',
  inventoryQuantity = 'inventoryQuantity',
  estimatedPreAdGrossMargin = 'preAdGrossMargin',
  campaigns = 'campaigns',
  crossIcon = 'crossIcon',
}

export interface RecommendedAsinResponse {
  readonly elements: RecommendedAsinItem[];
  readonly filteredElements: number;
}

export type RecommendedAsinItem = {
  readonly targetId: string;
  readonly asin: string;
  readonly matchType?: string;
  readonly adgroupInfo: AsinAdGroupInfo;
  readonly aggregatedPerformanceData: AsinPerformance;

  readonly selected?: boolean;
};

export interface AsinPerformance {
  readonly conversions: number;
  readonly sales: number;
  readonly adSpend: number;
  readonly clicks: number;
  readonly impressions: number;
}

export interface AsinAdGroupInfo {
  readonly adGroupId: string;
  readonly campaignName?: string;
  readonly adGroupName?: string;
  readonly campaignId?: string;
  readonly targetSegments?: string[];
  readonly targetingType?: TargetingType;
  readonly advertisingGoal?: string;
}

export enum RecommendedAsinTableFields {
  productAsin = 'asin',
  matchType = 'matchType',
  targetAdGroup = 'adGroupName',
  reason = 'conversion',
}

export enum EstPreAdGrossMarginTableFields {
  sku = 'sku',
  avgUnitSellPrice = 'avgUnitSellPrice',
  estUnitFees = 'estUnitFees',
  lastUpdated = 'lastUpdated',
  unitCogs = 'unitCogs',
  estPreAdGrossMargin = 'estPreAdGrossMargin',
}

export interface EstPreAdGrossMarginRequest {
  readonly merchantCountryId: string;
  readonly productCatalogIds: string[];
  readonly merchantType: string;
  readonly salesChannelId: string;
}

export type EstPreAdGrossMarginResponse = {
  readonly productDetails: EstPreAdGrossMarginItem[];
  // readonly cogs: EstPreAdGrossMarginCogs;
};

export type EstPreAdGrossMarginItem = {
  readonly sku: string;
  readonly extItemId: string;
  readonly imageUrl: string;
  readonly name: string;
  readonly productCatalogId: string;
  readonly productId: string;

  readonly avgSellingPrice: number;
  readonly platformFees: number;
  readonly preAdGrossMargin: number;
  readonly unitCogs: number;
  readonly cogsLastUpdatedAt: string;
  readonly dailyRateOfSales: number;
};

export interface EstPreAdGrossMarginCogs {
  readonly sellingPrice: number;
  readonly platformFees: number;
  readonly cogs: number;
  readonly lastUpdatedAt: string;
  readonly preAdGrossMargin: number;
}

// To be in Sync with AdvertisingGoal in AOSharedTypes
export enum SelectMetricsType {
  Product_Launch = 'launch',
  Grow = 'grow',
  Profit = 'profit',
  ROAS = 'roas',
  Liquidate = 'liquidate',
}

export const MAP_SELECTED_METRIC_CAMPAIGN_GOAL: Record<
  SelectMetricsType,
  BadgeCampaignGoal
> = {
  [SelectMetricsType.Product_Launch]: BadgeCampaignGoal.ProductLaunch,
  [SelectMetricsType.Grow]: BadgeCampaignGoal.Grow,
  [SelectMetricsType.Profit]: BadgeCampaignGoal.Profit,
  [SelectMetricsType.ROAS]: BadgeCampaignGoal.ROAS,
  [SelectMetricsType.Liquidate]: BadgeCampaignGoal.Liquidate,
};

export interface CampaignReadyResponse {
  readonly campaignLaunchable: boolean;
  readonly message: string;
  readonly missingAcosLimitCount: number;
  readonly missingTargetCount: number;
  readonly status: string;
}
