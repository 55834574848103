import classNames from 'classnames';
import noop from 'lodash/noop';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Icon,
  IconSize,
  PlusCircleIcon,
  RadioButton,
  TextInput,
  TextLink,
} from '@teikametrics/tm-design-system';

import { FAMApiClient } from '../../../../lib/clients/FAMApiClient';
import {
  DistributorAction,
  FAMConnectionStatus,
  FlywheelSalesChannel,
  MerchantType,
  ProductDataSyncRequestType,
  ProductDataSyncRequestType1P,
  WalmartSellerType,
} from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { SellerType, WALMART_COUNTRY } from '../../containers/utils';
import { ConnectModalWrapper } from '../ConnectModalWrapper';
import { DistributorIdInput } from './distributorIdInput';
import { WalmartAccessDeniedBanner } from './walmartAccessDeniedBanner';
import { WalmartLoginFailureBanner } from './walmartLoginFailureBanner';

export const WALMART_1P_INTERCOM_LINK =
  'https://intercom.help/flywheel-20/en/articles/8202190-walmart-1p-supplier-dsv-how-to-connect-ads-product-data-to-flywheel-2-0#h_d2567830bf';
export const WALMART_CLIENT_ID_CLIENT_SECRET_INTERCOM_LINK =
  'https://intercom.help/flywheel-20/en/articles/5017860-generate-your-client-id-and-client-secret';
const MAX_RETRY_COUNT = 60;
const MERCHANT_CONNECT_INTERVAL = 1000;

interface ProductDataSyncFormForSupplierDetailsProps {
  readonly famClient: FAMApiClient;
  readonly accountId: string;
  readonly merchantType: MerchantType;
  readonly sellerId: string;
  readonly clientId: string;
  readonly clientSecret: string;
  readonly walmartSellerType: WalmartSellerType;
  readonly distributorIds: string[];
  readonly onSellerIdChange: (id: string) => void;
  readonly onClientIdChange: (id: string) => void;
  readonly onClientSecretChange: (id: string) => void;
  readonly onWalmartSellerTypeChange: (
    walmartSellerType: WalmartSellerType
  ) => void;
  readonly onDistributorIdChange: (id: string, index: number) => void;
  readonly onAddNewDistributorId: () => void;
  readonly onRemoveDistributorId: (index: number) => void;
  readonly onClearAllChanges: () => void;
  readonly onBack: () => void;
  readonly onContinue: () => void;
  readonly closeModal?: () => void;
}

interface ProductDataSyncFormContentProps
  extends ProductDataSyncFormForSupplierDetailsProps {
  readonly showAuthError: boolean;
  readonly showAuthorizationError: boolean;
  readonly isProductConnectionLoading?: boolean;
}

export const WalmartProductSyncFormForSupplierDetails: React.FC<
  ProductDataSyncFormForSupplierDetailsProps
> = (props) => {
  const intl = useIntl();
  const {
    famClient,
    accountId,
    merchantType,
    sellerId,
    clientId,
    clientSecret,
    walmartSellerType,
    distributorIds,
    onClearAllChanges,
    onBack,
    onContinue,
    closeModal,
  } = props;

  const btnState = useMemo(() => {
    const requiredFieldsEntered =
      sellerId !== '' && clientId !== '' && clientSecret !== '';

    if (!requiredFieldsEntered) {
      return ButtonState.Disabled;
    }

    if (
      merchantType === MerchantType.Vendor &&
      walmartSellerType === WalmartSellerType.DSV &&
      distributorIds.some((id) => id === '' || !/^\d+$/.test(id))
    ) {
      return ButtonState.Disabled;
    }

    return ButtonState.Enabled;
  }, [
    sellerId,
    clientId,
    clientSecret,
    walmartSellerType,
    distributorIds,
    merchantType,
  ]);

  const [showAuthError, setShowAuthError] = useState<boolean>(false);
  const [showAuthorizationError, setShowAuthorizationError] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingConnectionStatus, setLoadingConnectionStatus] =
    useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<number | undefined>(undefined);

  const checkConectionStatus = (correlationId: string) => {
    let retryCount = 0;
    setLoadingConnectionStatus(true);
    const id = window.setInterval(() => {
      famClient.merchantConnect(accountId, correlationId).then((res) => {
        if (res.status === FAMConnectionStatus.Active) {
          setShowAuthError(false);
          setLoadingConnectionStatus(false);
          setIsLoading(false);
          onClearAllChanges();
          onContinue();
        } else if (res.status === FAMConnectionStatus.Failed) {
          setShowAuthError(true);
          setLoadingConnectionStatus(false);
          setIsLoading(false);
          onClearAllChanges();
        }
      });

      if (retryCount >= MAX_RETRY_COUNT) {
        setShowAuthError(true);
        setLoadingConnectionStatus(false);
        setIsLoading(false);
        onClearAllChanges();
      }
      retryCount++;
    }, MERCHANT_CONNECT_INTERVAL);
    setIntervalId(id);
  };

  useEffect(() => {
    if (!loadingConnectionStatus) {
      window.clearInterval(intervalId);
      setIntervalId(undefined);
    }
  }, [loadingConnectionStatus]);

  const sendProductData = () => {
    setIsLoading(true);
    setShowAuthError(false);
    setShowAuthorizationError(false);

    if (merchantType === MerchantType.Seller) {
      const apiRequestBody: ProductDataSyncRequestType = {
        id: clientId,
        sellerId,
        secretKey: clientSecret,
        api: SellerType['3P'],
        country: WALMART_COUNTRY,
      };
      famClient
        .connectProductSyncForm(accountId, apiRequestBody)
        .then((data) => {
          if (!data.id) {
            setShowAuthorizationError(true);
            setTimeout(() => setShowAuthorizationError(false), 5000);
            setIsLoading(false);
            onClearAllChanges();
          } else {
            checkConectionStatus(data.id);
          }
        })
        .catch(() => {
          setShowAuthError(true);
          setTimeout(() => setShowAuthError(false), 5000);
          setIsLoading(false);
          onClearAllChanges();
        });
    } else {
      const apiRequestBody: ProductDataSyncRequestType1P = {
        clientId,
        clientSecret,
        country: WALMART_COUNTRY,
        sellerType: walmartSellerType,
        vendorId: sellerId,
        ...(walmartSellerType === WalmartSellerType.DSV && {
          supplierId: sellerId,
          distributorIds,
          distributorAction: DistributorAction.Added,
        }),
      };
      famClient
        .connectWalmartSellerProducts1P(accountId, apiRequestBody)
        .then((data) => {
          if (!data.correlationId) {
            setShowAuthorizationError(true);
            setTimeout(() => setShowAuthorizationError(false), 5000);
            setIsLoading(false);
            onClearAllChanges();
          } else {
            checkConectionStatus(data.correlationId);
          }
        })
        .catch(() => {
          setShowAuthError(true);
          setTimeout(() => setShowAuthError(false), 5000);
          setIsLoading(false);
          onClearAllChanges();
        });
    }
  };

  return (
    <ConnectModalWrapper
      dataTestId="walmart_product_sync_form_for_supplier_details"
      isOpen
      closeModal={isLoading ? undefined : closeModal}
      headerText={intl.formatMessage({
        id: I18nKey.FLASH_MODALS_WALMART_MODAL_HEADER,
      })}
      content={
        <ProductDataSyncFormForSupplierDetails
          {...props}
          showAuthError={showAuthError}
          showAuthorizationError={showAuthorizationError}
          isProductConnectionLoading={isLoading}
        />
      }
      footer={
        <div className="flex justify-between mx-8">
          <Button
            size={ButtonSize.Medium}
            variant={ButtonVariant.BlackAndWhiteBorder}
            label={intl.formatMessage({ id: I18nKey.BACK })}
            onClick={onBack}
            state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
            dataTestId={`flash1_walmart_product_form_back`}
          />
          <Button
            size={ButtonSize.Medium}
            variant={ButtonVariant.Primary}
            label={intl.formatMessage({ id: I18nKey.CONTINUE })}
            onClick={sendProductData}
            state={isLoading ? ButtonState.Loading : btnState}
            dataTestId={`flash1_walmart_product_form_continue`}
          />
        </div>
      }
      headerChannelType={FlywheelSalesChannel.Walmart}
    />
  );
};
export const ProductDataSyncFormForSupplierDetails: React.FC<
  ProductDataSyncFormContentProps
> = (props) => {
  const {
    merchantType = MerchantType.Seller,
    sellerId,
    clientId,
    clientSecret,
    walmartSellerType,
    distributorIds,
    onSellerIdChange,
    onClientIdChange,
    onClientSecretChange,
    onWalmartSellerTypeChange,
    onDistributorIdChange,
    onAddNewDistributorId,
    onRemoveDistributorId,
    showAuthError,
    showAuthorizationError,
    isProductConnectionLoading,
  } = props;
  const intl = useIntl();

  const [
    ADD_PRODUCTS_DATA,
    ADD_SUPPLIER_ID,
    ADD_SELLER_ID,
    ADD_CLIENT_ID,
    ADD_CLIENT_SECRET,
    HELP_ME_FIND_DETAILS,
    FLYWHEEL_ADD_ALL_DISTRIBUTOR_IDS,
    FLYWHEEL_ADD_ANOTHER_DISTRIBUTOR,
    FLYWHEEL_CHOOSE_SELLER_TYPE,
    FLYWHEEL_SELLER_TYPE_DSV,
    FLYWHEEL_SELLER_TYPE_WAREHOUSE,
  ] = [
    I18nKey.ADD_PRODUCTS_DATA,
    I18nKey.ADD_SUPPLIER_ID,
    I18nKey.ADD_SELLER_ID,
    I18nKey.ADD_CLIENT_ID,
    I18nKey.ADD_CLIENT_SECRET,
    I18nKey.HELP_ME_FIND_DETAILS,
    I18nKey.FLYWHEEL_ADD_ALL_DISTRIBUTOR_IDS,
    I18nKey.FLYWHEEL_ADD_ANOTHER_DISTRIBUTOR,
    I18nKey.FLYWHEEL_CHOOSE_SELLER_TYPE,
    I18nKey.FLYWHEEL_SELLER_TYPE_DSV,
    I18nKey.FLYWHEEL_SELLER_TYPE_WAREHOUSE,
  ].map((id) => intl.formatMessage({ id }));

  const SELLER_TYPES = [
    { label: FLYWHEEL_SELLER_TYPE_DSV, value: WalmartSellerType.DSV },
    {
      label: FLYWHEEL_SELLER_TYPE_WAREHOUSE,
      value: WalmartSellerType.Warehouse,
    },
  ];

  const onLinkClickHandler = () => {
    if (isProductConnectionLoading) {
      return;
    }

    onAddNewDistributorId();
  };

  return (
    <div className="overflow-y-auto max-h-flashModalContent py-24">
      <h2
        className={`flex text-lg text-grey-900
          leading-none font-semibold `}
      >
        {ADD_PRODUCTS_DATA}
      </h2>
      {showAuthError && <WalmartLoginFailureBanner />}
      {showAuthorizationError && <WalmartAccessDeniedBanner />}
      <label className={`flex mt-20 text-base leading-tight font-medium`}>
        {merchantType === MerchantType.Vendor ? ADD_SUPPLIER_ID : ADD_SELLER_ID}
      </label>
      <TextInput
        className="w-full mt-8"
        value={sellerId}
        onChange={onSellerIdChange}
        data-test-id={`flash1_walmart_product_form_${
          merchantType === MerchantType.Seller ? 'seller_id' : 'supplier_id'
        }_help_link`}
        disabled={isProductConnectionLoading}
      />
      <a
        href={getIntercomLink(merchantType)}
        target="_blank"
        className={`flex mt-12 text-grey-600 text-sm
          leading-none font-normal underline`}
        rel="noreferrer"
      >
        {HELP_ME_FIND_DETAILS}
      </a>
      <label className="flex mt-36 text-base leading-tight font-medium">
        {ADD_CLIENT_ID}
      </label>
      <TextInput
        className="w-full mt-8"
        value={clientId}
        onChange={onClientIdChange}
        data-test-id={`flash1_walmart_product_form_client_id_help_link`}
        disabled={isProductConnectionLoading}
      />
      <a
        href={getIntercomLink(merchantType)}
        target="_blank"
        className={`flex mt-12 text-grey-600 text-sm
          leading-none font-normal underline`}
        rel="noreferrer"
      >
        {HELP_ME_FIND_DETAILS}
      </a>
      <label className="flex mt-36 text-base leading-tight font-medium">
        {ADD_CLIENT_SECRET}
      </label>
      <TextInput
        className="w-full mt-8"
        value={clientSecret}
        onChange={onClientSecretChange}
        data-test-id={`flash1_walmart_product_form_client_secret_help_link`}
        disabled={isProductConnectionLoading}
      />
      <a
        href={getIntercomLink(merchantType)}
        target="_blank"
        className={`flex mt-12 text-grey-600 text-sm
          leading-none font-normal underline`}
        rel="noreferrer"
      >
        {HELP_ME_FIND_DETAILS}
      </a>
      {merchantType === MerchantType.Vendor && (
        <>
          <label
            className={`flex mt-28 mb-12 text-base
              leading-tight font-medium`}
          >
            {FLYWHEEL_CHOOSE_SELLER_TYPE}
          </label>
          <div className="flex gap-16">
            {SELLER_TYPES.map(({ label, value }) => (
              <div
                key={value}
                className={classNames(
                  'border border-solid rounded p-12 flex-1 cursor-pointer',
                  {
                    'border-purple-300 bg-purple-50':
                      walmartSellerType === value,
                    'border-grey-300 ': walmartSellerType !== value,
                  }
                )}
                onClick={() => onWalmartSellerTypeChange(value)}
              >
                <RadioButton
                  selected={walmartSellerType === value}
                  disabled={false}
                  label={label}
                  onChange={noop}
                />
              </div>
            ))}
          </div>
          {walmartSellerType === WalmartSellerType.DSV && (
            <>
              <label
                className={`flex mt-28 mb-12
                  text-base leading-tight font-medium`}
              >
                {FLYWHEEL_ADD_ALL_DISTRIBUTOR_IDS}
              </label>
              <div className="flex flex-col gap-8">
                {distributorIds.map((distributorId: string, index: number) => (
                  <DistributorIdInput
                    key={index}
                    index={index}
                    showDeleteButton={distributorIds.length > 1}
                    prefixLabel={String(index + 1)}
                    distributorId={distributorId}
                    isProductConnectionLoading={isProductConnectionLoading}
                    onChange={onDistributorIdChange}
                    onRemove={onRemoveDistributorId}
                  />
                ))}
              </div>
              <div
                className={classNames('flex items-center gap-8 mt-16 group', {
                  'pointer-events-none': isProductConnectionLoading,
                })}
                onClick={onLinkClickHandler}
              >
                <Icon
                  size={IconSize.Small}
                  svg={PlusCircleIcon}
                  className="text-grey-600 group-hover:text-purple-500"
                />

                <TextLink textLabel={FLYWHEEL_ADD_ANOTHER_DISTRIBUTOR} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export const getIntercomLink = (merchantType: MerchantType) =>
  merchantType === MerchantType.Seller
    ? WALMART_CLIENT_ID_CLIENT_SECRET_INTERCOM_LINK
    : WALMART_1P_INTERCOM_LINK;

export const isValidDistributorIds = (distributorIds: string[]) =>
  distributorIds.length > 0 &&
  distributorIds.every((id) => id !== '' && /^\d+$/.test(id));
