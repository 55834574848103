import React from 'react';
import { useIntl } from 'react-intl';

import {
  PaletteColor,
  Pill,
  TextLink,
  TextLinkSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ConnectionInitialModalProps } from './';
import {
  INTERCOM_AMAZON_ONBOARDING_ADS_HELP,
  INTERCOM_AMAZON_ONBOARDING_PRODUCTS_HELP,
  INTERCOM_WALMART_ONBOARDING_ADS_HELP,
  INTERCOM_WALMART_ONBOARDING_PRODUCTS_HELP,
  WALMART_SELLER_CENTER_LINK,
} from '../../constants';
import { NeedHelp } from '../../../../../components/NeedHelp';

export const ModalContent: React.FC<
  Pick<ConnectionInitialModalProps, 'channel'>
> = ({ channel }) => {
  const intl = useIntl();
  const COMMON_I18N_KEY = [I18nKey.REQUIRED, I18nKey.GENERIC_LEARN_MORE];
  const AMAZON_I18N_KEY = [
    I18nKey.FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_SUBHEADER,
    I18nKey.FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_ADS_CARD_HEADER,
    I18nKey.FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_ADS_CARD_SUBHEADER,
    I18nKey.FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_PRODUCTS_CARD_HEADER,
    I18nKey.FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_PRODUCTS_CARD_SUBHEADER,
  ];
  const WALMART_I18N_KEY = [
    I18nKey.FLASH_MODALS_WALMART_INITIAL_CONNECTION_CONTENT_SUBHEADER,
    I18nKey.FLASH_MODALS_WALMART_INITIAL_CONNECTION_CONTENT_ADS_CARD_HEADER,
    I18nKey.FLASH_MODALS_WALMART_INITIAL_CONNECTION_CONTENT_ADS_CARD_SUBHEADER,
    I18nKey.FLASH_MODALS_WALMART_INITIAL_CONNECTION_CONTENT_PRODUCTS_CARD_HEADER,
    I18nKey.FLASH_MODALS_WALMART_INITIAL_CONNECTION_CONTENT_PRODUCTS_CARD_SUBHEADER,
  ];
  const [
    HEADER,
    ADS_DATA,
    ADS_DATA_DESCRIPTION,
    PRODUCTS_DATA,
    PRODUCTS_DATA_DESCRIPTION,
    REQUIRED,
    LEARN_MORE,
  ] = (
    channel === FlywheelSalesChannel.Amazon
      ? [...AMAZON_I18N_KEY, ...COMMON_I18N_KEY]
      : [...WALMART_I18N_KEY, ...COMMON_I18N_KEY]
  ).map((id) => intl.formatMessage({ id }));

  const connectionData = [
    {
      header: ADS_DATA,
      description: ADS_DATA_DESCRIPTION,
      learnMore:
        channel === FlywheelSalesChannel.Amazon
          ? INTERCOM_AMAZON_ONBOARDING_ADS_HELP
          : INTERCOM_WALMART_ONBOARDING_ADS_HELP,
    },
    {
      header: PRODUCTS_DATA,
      description: PRODUCTS_DATA_DESCRIPTION,
      learnMore:
        channel === FlywheelSalesChannel.Amazon
          ? INTERCOM_AMAZON_ONBOARDING_PRODUCTS_HELP
          : INTERCOM_WALMART_ONBOARDING_PRODUCTS_HELP,
    },
  ];

  return (
    <div className="flex flex-col grow basis-0 shrink-0 text-left text-base text-grey-900 my-16">
      <div className="flex flex-col grow basis-0 shrink-0 gap-16">
        <Typography
          size={TypographySize.lg}
          lineHeight={TypographyLineHeight.normal}
          weight={TypographyWeight.semibold}
        >
          {HEADER}
        </Typography>
        {connectionData.map((element) => (
          <div className="border border-solid border-grey-200 rounded p-16 flex flex-col gap-8">
            <div className="flex flex-row items-center gap-8">
              <Typography
                size={TypographySize.base}
                lineHeight={TypographyLineHeight.normal}
                weight={TypographyWeight.semibold}
              >
                {element.header}
              </Typography>
              <Pill
                text={REQUIRED}
                color={PaletteColor.white}
                isHoverable={false}
              />
            </div>
            <Typography
              size={TypographySize.sm}
              lineHeight={TypographyLineHeight.normal}
              weight={TypographyWeight.regular}
            >
              {element.description}
            </Typography>
            <div className="flex flex-row">
              <TextLink
                openNewTab
                size={TextLinkSize.Small}
                textLabel={LEARN_MORE}
                href={element.learnMore}
                dataTestId={`${element}_connection_initial_modal_learn_more`}
              />
            </div>
          </div>
        ))}
      </div>
      {channel === FlywheelSalesChannel.Walmart && (
        <NeedHelp
          url={WALMART_SELLER_CENTER_LINK}
          dataTestId="connect_initial_modal"
          channel={channel}
        />
      )}
    </div>
  );
};
