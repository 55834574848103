import { IdToken } from '@auth0/auth0-react';

import { getCurrentAccountPermissions } from '../../containers/userProvider/selectors';
import { MerchantCountry, UserDetails } from '../types/Fam';

//https://stackoverflow.com/questions/12709074/how-do-you-explicitly-set-a-new-property-on-window-in-typescript
declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
  }
}

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

window.intercomSettings = {
  app_id: INTERCOM_APP_ID,
  custom_launcher_selector: '.launchIntercomMessenger',
};

export const onDocumentReady = (func: () => void) => {
  if (document.readyState === 'complete') {
    func();
  } else {
    document.addEventListener('DOMContentLoaded', func);
  }
};

export const intercomUserLoggedIn = (
  idToken: IdToken,
  userDetails: UserDetails
): void => {
  const logUserIntoIntercom = () => {
    const Intercom = window.Intercom;
    if (!Intercom || !INTERCOM_APP_ID) {
      return;
    }

    const accountInfo = getCurrentAccountPermissions(userDetails)!;

    Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      email: userDetails.email,
      created_at: idToken.updated_at,
      name: userDetails.firstName + ' ' + userDetails.lastName,
      first_name: userDetails.firstName,
      user_id: userDetails.id,
      account_id: accountInfo.account.id,
      company_name: accountInfo.account.companyName,
      user_role: accountInfo.role,
      account_type: accountInfo.account.accountType,
    });
  };

  onDocumentReady(logUserIntoIntercom);
};

export const talkToUs = (): void => {
  const openIntercomWindow = () => {
    const Intercom = window.Intercom;
    if (!Intercom || !INTERCOM_APP_ID) {
      return;
    }
    Intercom('show');
  };

  onDocumentReady(openIntercomWindow);
};

export const hideIntercomLauncher = () => {
  const Intercom = window.Intercom;
  Intercom('update', {
    hide_default_launcher: true,
  });
};

export const showIntercomLauncher = () => {
  const Intercom = window.Intercom;
  Intercom('update', {
    hide_default_launcher: false,
  });
};

export const showHideIntercomLauncher = (hide_default_launcher: boolean) => {
  const Intercom = window.Intercom;
  Intercom('update', {
    hide_default_launcher,
  });
};

export const updateIntercomMerchantCountry = (
  merchantCountries: MerchantCountry[]
) => {
  const Intercom = window.Intercom;
  if (!Intercom || !INTERCOM_APP_ID) {
    return;
  }
  Intercom('update', {
    merchant_countries: merchantCountries?.map(
      ({ merchantType, extMerchantId }) => ({
        merchant_type: merchantType,
        ext_merchant_id: extMerchantId,
      })
    ),
  });
};
