import { RecommendationsCountResponse } from '../../lib/types/RecommendationsSharedTypes';
import { RecommendationsContextState } from './recommendationsProvider';

export enum RecommendationsActionTypes {
  SET_HOMEPAGE_NOTIFICATION_DISMISSED = 'SET_HOMEPAGE_NOTIFICATION_DISMISSED',
  SET_RECOMMENDATIONS_COUNT = 'SET_RECOMMENDATIONS_COUNT',
  RESET_STATE = 'RESET_STATE',
}

export type RecommendationsActions =
  | {
      type: RecommendationsActionTypes.SET_HOMEPAGE_NOTIFICATION_DISMISSED;
      payload: boolean;
    }
  | {
      type: RecommendationsActionTypes.SET_RECOMMENDATIONS_COUNT;
      payload: RecommendationsCountResponse[];
    }
  | {
      type: RecommendationsActionTypes.RESET_STATE;
      payload: RecommendationsContextState;
    };
