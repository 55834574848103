import { Link } from 'react-router-dom';
import {
  FlagIconComponent,
  Icon,
  IconSize,
  PlusIcon,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { SearchTermsData } from '../../../../lib/types/MISharedTypes';
import React, { useCallback, useMemo } from 'react';
import { NavPaths } from '../../../../NavPaths';

interface SearchPickerRowProps {
  isSelected?: boolean;
  option: SearchTermsData;
  onOptionSelect: (val: SearchTermsData) => void;
  showPlusIcon?: boolean;
  hasMerchantData?: boolean;
}
const SearchPickerRow: React.FC<SearchPickerRowProps> = ({
  option,
  onOptionSelect,
  isSelected = false,
  showPlusIcon = false,
  hasMerchantData = false,
}) => {
  const handleSelect = useCallback((selectedTerm: SearchTermsData) => {
    return () => {
      onOptionSelect(selectedTerm);
    };
  }, []);

  const isGroup = option.is_group;
  const isGroupedTerm = option.is_grouped_term;
  const value = isGroup ? option.group_name : option.search_term;

  const navigateLink =
    NavPaths.MarketIntelligenceTrackedTerms +
    `?tab=${hasMerchantData ? 1 : 0}&term=${option.search_term}-${
      option.locale
    }-${option.platform}-${option.search_term_id}`;

  const plusIcon = useMemo(() => {
    if (!showPlusIcon) {
      return null;
    }
    return (
      <Link to={navigateLink} className="ml-auto">
        <Icon
          svg={PlusIcon}
          className="text-purple-500 mr-8"
          size={IconSize.ExtraSmall}
          dataTestId="mi_headerSearchTerms_plus"
        />
      </Link>
    );
  }, [showPlusIcon, hasMerchantData, option]);

  return (
    <div
      className="flex items-center space-x-4 px-12 py-8 hover:bg-grey-50"
      onClick={handleSelect(option)}
    >
      <div
        className={classNames('flex items-center space-x-8 flex-grow', {
          'pl-20': isGroupedTerm,
        })}
      >
        <FlagIconComponent
          country={option.locale}
          isImg
          iconClassName="flex items-center"
        />
        <span
          className={classNames('text-base text-grey-900', {
            'font-bold': isGroup,
            'text-purple-500': isSelected,
          })}
        >
          {value}
        </span>
      </div>
      {plusIcon}
    </div>
  );
};

export default SearchPickerRow;

SearchPickerRow.displayName = 'SearchPickerRow';
