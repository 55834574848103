import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  ProductAdDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import {
  PRODUCT_ADS_API_COLUMN_NAME,
  getPerformanceMetricValue,
} from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) =>
  makeCurrencyColumn<ProductAdDetails>(
    ({
      adItemDetails: { startDate },
      adItemPerformance: { avgCostPerClick },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        avgCostPerClick?.amount ? Number(avgCostPerClick?.amount) : undefined
      );

      return isNil(performanceMetricValue)
        ? undefined
        : {
            amount: performanceMetricValue,
            currency:
              getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
              avgCostPerClick?.currency ||
              CURRENCY_CODE,
          };
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const costPerClickColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  isSortable: true,
  columnName: PRODUCT_ADS_API_COLUMN_NAME.AvgCostPerClick,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_COST_PER_CLICK,
  RowCellElement,
  className: 'text-right',
  gridColumnWidth: '140px',
  columnHeaderClassName: 'justify-end',
};

export const costPerClickFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    PRODUCT_ADS_API_COLUMN_NAME.AvgCostPerClick,
    I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_COST_PER_CLICK,
    currency,
    isValidNumber(0)
  );
