import { Option, fromNullable } from 'fp-ts/lib/Option';
import { ThunkDispatch } from 'redux-thunk';

import { tableActions } from './ducks';
import { TableAction } from './ducks/actions';
import { Filter, FilterOps, LikeFilter } from '../../lib/types/Filter';

export const onSearchInputFilterChangeWrapper =
  (
    tableId: string,
    columnName: string,
    filters: Filter[],
    upgradeFiltersInStorage: (filters: Filter[]) => void,
    onFilterUpdate?: (filters: Filter[]) => void
  ) =>
  (
    maybeNewFilter: Option<LikeFilter>,
    dispatch: ThunkDispatch<{}, {}, TableAction>
  ): void => {
    const action = maybeNewFilter
      .map<TableAction>((newFilter) => {
        fromNullable(onFilterUpdate)
          .map((fn) => {
            // exclude previous search filter and call the handler with existing filters and new search filter
            const newFilters = filters
              .filter((filter) => filter.field !== columnName)
              .concat(newFilter);
            upgradeFiltersInStorage(newFilters);
            fn(newFilters);
            return newFilters;
          })
          .toUndefined();
        return tableActions.updateFilters({
          tableId,
          filters: [newFilter],
          replace: false,
        });
      })
      .getOrElseL(() => {
        fromNullable(onFilterUpdate)
          .map((fn) => {
            const newFilters = filters.filter(
              (filter) =>
                filter.field !== columnName && filter.op !== FilterOps.like
            );
            fn(newFilters);
            upgradeFiltersInStorage(newFilters);
            return newFilters;
          })
          .toUndefined();
        return tableActions.clearFilter({
          field: columnName,
          tableId,
        });
      });

    dispatch(action);
  };

export const createSearchFilter =
  (columnName: string, shouldAddWildcardsToFilterValue?: boolean) =>
  (newValue: string): Option<LikeFilter> => {
    const trimmedNewValue = newValue.trim();
    const trimmedValueWithWildcards = shouldAddWildcardsToFilterValue
      ? `%${trimmedNewValue}%`
      : trimmedNewValue;
    const isSearchCleared = trimmedNewValue === '';
    const newFilter: LikeFilter | undefined = isSearchCleared
      ? undefined
      : {
          op: FilterOps.like,
          field: columnName,
          value: trimmedValueWithWildcards,
        };
    return fromNullable(newFilter);
  };

export const updateTableFilters = (
  dispatch: ThunkDispatch<{}, {}, TableAction>,
  newValue: string,
  filters: Filter[],
  inputSearchColumnName: string,
  tableId: string,
  upgradeFiltersInStorage: (filters: Filter[]) => void,
  onFilterUpdate?: (filters: Filter[]) => void,
  shouldAddWildcardsToFilterValue?: boolean
) => {
  const newFilter = createSearchFilter(
    inputSearchColumnName,
    shouldAddWildcardsToFilterValue
  )(newValue);
  const dispatchAction = onSearchInputFilterChangeWrapper(
    tableId,
    inputSearchColumnName,
    filters,
    upgradeFiltersInStorage,
    onFilterUpdate
  );
  dispatchAction(newFilter, dispatch);
};
