import { DateTime } from 'luxon';

export const getTrailingDays = (m1: DateTime, m2: DateTime) => {
  return 1 + Math.abs(m1.diff(m2, 'days').days);
};
export const convertToUTCDate = (m: DateTime) => {
  const date = m.toJSDate();
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return date;
};
