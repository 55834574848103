import {
  ArrowRightIcon,
  ColumnAlign,
  Icon,
  IconSize,
  NumericInputState,
  TableCellV2,
  TableCellV2Type,
  Typography,
  TypographyLineHeight,
  TypographySize,
  getCurrencySymbol,
} from '@teikametrics/tm-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActions,
  tableSelectors,
} from '../../../containers/tableV2/ducks';
import { TableCell, WithTable } from '../../../containers/tableV2/ducks/types';
import { FlywheelTableV2ColumnProps } from '../../../containers/tableV2/types';
import {
  EstPreAdGrossMarginItem,
  EstPreAdGrossMarginTableFields,
} from '../../../lib/types/CampaignFlexibilitySharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../lib/types/I18nKey';
import { TableExtraProps } from '../EstAdGrossMarginTable';
import isNil from 'lodash/isNil';

export const Cell: React.FC<EstPreAdGrossMarginItem & TableExtraProps> = ({
  tableId,
  unitCogs,
  sku,
  productCatalogId,
  selectedCurrency,
  bulkUpdateInProgress,
  bulkInputValue,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currCogs = (unitCogs || 0).toString();

  const newValue = useSelector<WithTable<EstPreAdGrossMarginItem>, TableCell>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        sku,
        EstPreAdGrossMarginTableFields.unitCogs
      )(tableState, tableId)
  );

  /**
   * This section is required coz of the TableV1 and V2 cell changes.
   * If this is from TableV1. Then we don't need `.value` hence this change.
   * Check tableV1.updatedCell and tableV2.updatedCell reducers for more info.
   */
  const updatedValue = typeof newValue !== 'object' ? newValue : newValue.value;

  const updateCellValue = (newValue: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: tableId,
        rowId: sku,
        columnName: EstPreAdGrossMarginTableFields.unitCogs,
        value: newValue,
        existingValue: currCogs,
        isValid: true,
        isDirty: true,
      })
    );
  };

  const onCogsUpdate = (value: string) => {
    updateCellValue(value);
  };
  const displayValue = isNil(updatedValue) ? currCogs : updatedValue;

  return (
    <div className="flex justify-center flex-col h-full max-w-132">
      {bulkUpdateInProgress ? (
        <div className="flex gap-8 items-center justify-center">
          <div className="text-base leading-tight font-regular-tabular-nums">
            {intl.formatNumber(unitCogs || 0, {
              style: 'currency',
              currency: selectedCurrency,
              minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              maximumFractionDigits: MONETARY_FRACTION_DIGITS,
            })}
          </div>
          <Icon
            size={IconSize.Small}
            svg={ArrowRightIcon}
            className="text-grey-600"
          />
          {!!bulkInputValue ? (
            <>
              {intl.formatNumber(Number(bulkInputValue), {
                style: 'currency',
                currency: selectedCurrency,
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
              })}
            </>
          ) : (
            <Skeleton height={14} width={44} />
          )}
        </div>
      ) : (
        <>
          <TableCellV2
            tableCellData={{
              tableCellType: TableCellV2Type.NumericInputCurrency,
              placeholder: '',
              originalValue: currCogs,
              value: displayValue,
              min: 0,
              state: !displayValue
                ? NumericInputState.Error
                : NumericInputState.Default,
              prependedElement: getCurrencySymbol(selectedCurrency),
              onChange: onCogsUpdate,
              acceptOnlyPositiveNumbers: true,
              maxFractionDigits: MONETARY_FRACTION_DIGITS,
              minFractionDigits: MONETARY_FRACTION_DIGITS,
              dataTestId: 'estPreAdGrossMargin_unitCogs',
            }}
          />
          {!displayValue && (
            <Typography
              className="text-orange-700 mt-4"
              lineHeight={TypographyLineHeight.none}
              size={TypographySize.sm}
            >
              <FormattedMessage
                id={
                  I18nKey.AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_COGS_MISSING_COGS_VALUE
                }
              />
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

Cell.displayName = 'estPreAdGrossMargin_unitCogs';

export const unitCogsColumn: FlywheelTableV2ColumnProps<
  EstPreAdGrossMarginItem,
  {},
  TableExtraProps,
  {}
> = {
  header: I18nKey.CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_TABLE_COLUMN_UNIT_COGS,
  accessorKey: EstPreAdGrossMarginTableFields.unitCogs,
  size: 140,
  maxSize: 140,
  minSize: 140,
  sortable: false,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell {...original} {...meta} />,
};
