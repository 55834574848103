import { Factory } from 'fishery';

import { BacnProduct } from 'lib/types/AIPoweredSharedTypes';

export const BacnProducts = Factory.define<BacnProduct[]>(() => [
  {
    productCatalogId: 'pcId1',
    productDetails: {
      sku: '195-0833-000050FBA',
      name: 'Pulsar G450RN, 4500W Super Quiet Portable Inverter Generator with Remote Start & Parallel Capability, RV Ready, CARB Compliant',
      imageUrl: 'https://m.media-amazon.com/images/I/71f-F3TR4OL.jpg',
      extItemId: 'B08NFK4KZ6',
    },
    brand: [
      {
        id: '1',
        name: 'Brand 1',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId2',
    productDetails: {
      sku: '218-1998-000010',
      name: 'Phanteks NV5 (PH-NV523TG_DBK01) Showcase Mid-Tower Chassis, High Airflow Performance, Integrated D/A-RGB Lighting, Seamless Tempered Glass Design, 8 Fan Positions, Black',
      imageUrl: 'https://m.media-amazon.com/images/I/81MEgC5ZQvL.jpg',
      extItemId: 'B0CJZ5QY35',
    },
    brand: [
      {
        id: '3',
        name: 'Brand 3',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '3',
        name: 'Competitor 3',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId3',
    productDetails: {
      sku: '195-0697-000018',
      name: 'Ford FG2300iS 2300W Silent Series Inverter Generator, Blue',
      imageUrl: 'https://m.media-amazon.com/images/I/81mAt1-NGfS.jpg',
      extItemId: 'B093RB5XH4',
    },
    brand: [
      {
        id: '4',
        name: 'Brand 4',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId4',
    productDetails: {
      sku: '218-1917-000122',
      name: 'Fractal Design Terra Gaming Computer Case',
      imageUrl: 'https://m.media-amazon.com/images/I/61WrkoJ0dEL.jpg',
      extItemId: 'B0CD66CJ3N',
    },
    brand: [
      {
        id: '5',
        name: 'Brand 5',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId5',
    productDetails: {
      sku: '172-1780-000005',
      name: 'Pro Series 22 Ton Air Hydraulic Service Jack for Commercial Truck & Bus',
      imageUrl: 'https://m.media-amazon.com/images/I/619YA+TRBcL.jpg',
      extItemId: 'B06WRR5TKB',
    },
    brand: [
      {
        id: '1',
        name: 'Brand 1',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '3',
        name: 'Competitor 3',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId6',
    productDetails: {
      sku: '218-0818-000007',
      name: 'Toshiba MG08ACA16TE 16TB 7200RPM 512e 3.5" SATA Enterprise Desktop Hard Drive',
      imageUrl: 'https://m.media-amazon.com/images/I/71k4q76t1kL.jpg',
      extItemId: 'B0832BL1HC',
    },
    brand: [
      {
        id: '3',
        name: 'Brand 3',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId7',
    productDetails: {
      sku: '191-0312-000020',
      name: 'SNAP-LOC 1800 LB Super-Duty Dolly RED (USA!) with Steel Frame, 6 inch Casters and Optional E-Strap Attachment',
      imageUrl: 'https://m.media-amazon.com/images/I/61w0EuQtNFL.jpg',
      extItemId: 'B07DVR6RS9',
    },
    brand: [
      {
        id: '1',
        name: 'Brand 1',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '3',
        name: 'Competitor 3',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId8',
    productDetails: {
      sku: '218-1946-000002',
      name: 'LaCie Mobile Drive 2TB External Hard Drive HDD – Space Gray USB-C USB 3.0, for Mac and PC Computer Desktop Workstation Laptop (STHG2000402)',
      imageUrl: 'https://m.media-amazon.com/images/I/91uelBYpHBL.jpg',
      extItemId: 'B07QDGGPXJ',
    },
    brand: [
      {
        id: '4',
        name: 'Brand 4',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId9',
    productDetails: {
      sku: '218-1998-000011',
      name: 'Phanteks NV5 (PH-NV523TG_DMW01) Showcase Mid-Tower Chassis, High Airflow Performance, Integrated D/A-RGB Lighting, Seamless Tempered Glass Design, 8 Fan Positions, White',
      imageUrl: 'https://m.media-amazon.com/images/I/41Ipppuck2L.jpg',
      extItemId: 'B0CJW7BDMH',
    },
    brand: [
      {
        id: '5',
        name: 'Brand 5',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId10',
    productDetails: {
      sku: '028-0792-000004',
      name: 'United Pacific 40848 Heavy-Duty Chrome 12V Car Fan w/6" Fan Blade for Cars, Trucks, RVs, & Vans, All Metal, 2-Speeds, Adjustable Base - ONE Fan',
      imageUrl: 'https://m.media-amazon.com/images/I/712BkcTyP3L.jpg',
      extItemId: 'B00B2V2CFM',
    },
    brand: [
      {
        id: '1',
        name: 'Brand 1',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '3',
        name: 'Competitor 3',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId11',
    productDetails: {
      sku: '218-1935-000025',
      name: 'Western Digital 6TB My Book Desktop External Hard Drive, USB 3.0, External HDD with Password Protection and Auto Backup Software - WDBBGB0060HBK-NESN',
      imageUrl: 'https://m.media-amazon.com/images/I/31fb4AS9DbL.jpg',
      extItemId: 'B01LQQHKZY',
    },
    brand: [
      {
        id: '1',
        name: 'Brand 1',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId12',
    productDetails: {
      sku: '212-1780-000010',
      name: 'ESCO 10202 Pro Series Combination Bead Breaker Kit with 5 Quart Hydraulic Pump',
      imageUrl: 'https://m.media-amazon.com/images/I/61zUg1kIukL.jpg',
      extItemId: 'B000N5Q1AU',
    },
    brand: [
      {
        id: '4',
        name: 'Brand 4',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '3',
        name: 'Competitor 3',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId13',
    productDetails: {
      sku: '218-1998-000013',
      name: 'Phanteks NV9, Showcase Full-Tower Chassis, High Airflow Performance, Integrated D/A-RGB Lighting, Seamless Tempered Glass Design, 11x 140mm Fan Positions (Black)',
      imageUrl: 'https://m.media-amazon.com/images/I/71es0HRmF1L.jpg',
      extItemId: 'B0CPHB366C',
    },
    brand: [
      {
        id: '5',
        name: 'Brand 5',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId14',
    productDetails: {
      sku: '218-2438-000052',
      name: 'MONTECH Sky Two GX, E-ATX Mid Tower Case, High Airflow Performance, 3X140mm PWM ARGB Fans Pre-Installed, Tempered Glass Panel, Metal Mesh Front- Black',
      imageUrl: 'https://m.media-amazon.com/images/I/81U81pu2ulL.jpg',
      extItemId: 'B0CYTHMXQ1',
    },
    brand: [
      {
        id: '4',
        name: 'Brand 4',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '3',
        name: 'Competitor 3',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId15',
    productDetails: {
      sku: '218-1924-000002',
      name: 'Sama IM01 Case Sama Im01 R',
      imageUrl: 'https://m.media-amazon.com/images/I/61GRvZOiExL.jpg',
      extItemId: 'B09ZP2ZNC7',
    },
    brand: [
      {
        id: '5',
        name: 'Brand 5',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId16',
    productDetails: {
      sku: '218-0853-000037',
      name: 'Corsair SF Series, SF750, 750 Watt, SFX, 80+ Platinum Certified, Fully Modular Power Supply (CP-9020186-NA)',
      imageUrl: 'https://m.media-amazon.com/images/I/818VLN7WdxL.jpg',
      extItemId: 'B07M63H81H',
    },
    brand: [
      {
        id: '4',
        name: 'Brand 4',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '3',
        name: 'Competitor 3',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId17',
    productDetails: {
      sku: '218-1998-000010',
      name: 'Phanteks NV5 (PH-NV523TG_DBK01) Showcase Mid-Tower Chassis, High Airflow Performance, Integrated D/A-RGB Lighting, Seamless Tempered Glass Design, 8 Fan Positions, Black',
      imageUrl: 'https://m.media-amazon.com/images/I/81MEgC5ZQvL.jpg',
      extItemId: 'B0CJZ5QY35',
    },
    brand: [
      {
        id: '3',
        name: 'Brand 3',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId18',
    productDetails: {
      sku: '218-1998-000011',
      name: 'Phanteks NV5 (PH-NV523TG_DMW01) Showcase Mid-Tower Chassis, High Airflow Performance, Integrated D/A-RGB Lighting, Seamless Tempered Glass Design, 8 Fan Positions, White',
      imageUrl: 'https://m.media-amazon.com/images/I/41Ipppuck2L.jpg',
      extItemId: 'B0CJW7BDMH',
    },
    brand: [
      {
        id: '5',
        name: 'Brand 5',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId19',
    productDetails: {
      sku: '172-1780-000008',
      name: 'Esco 7.5 Ton Forklift Tractor Jack',
      imageUrl: 'https://m.media-amazon.com/images/I/61+cjGgXqRL.jpg',
      extItemId: 'B01EJDSRR6',
    },
    brand: [
      {
        id: '3',
        name: 'Brand 3',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId20',
    productDetails: {
      sku: '195-0833-000049',
      name: 'Pulsar 4500W, Portable Quiet Remote Start & Parallel Capability , CARB Compliant Inverter Backup Generator, PG4500iSR, White, RV ready',
      imageUrl: 'https://m.media-amazon.com/images/I/71OAnAsgh6L.jpg',
      extItemId: 'B08NFLV5VF',
    },
    brand: [
      {
        id: '1',
        name: 'Brand 1',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId21',
    productDetails: {
      sku: '218-2360-000006',
      name: 'NZXT H5 Flow Compact ATX Mid-Tower PC Gaming Case – High Airflow Perforated Tempered Glass Front/Side Panel – Cable Management – 2 x 120mm Fans Included – 280mm Radiator Support – Black',
      imageUrl: 'https://m.media-amazon.com/images/I/71SIs5kxpYL.jpg',
      extItemId: 'B0B6Y15C5L',
    },
    brand: [
      {
        id: '3',
        name: 'Brand 3',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId22',
    productDetails: {
      sku: '218-0853-000021',
      name: 'CORSAIR 4000D AIRFLOW Tempered Glass Mid-Tower ATX Case - High-Airflow - Cable Management System - Spacious Interior - Two Included 120 mm Fans - Black',
      imageUrl: 'https://m.media-amazon.com/images/I/41iFk62zhwL.jpg',
      extItemId: 'B08C7BGV3D',
    },
    brand: [
      {
        id: '5',
        name: 'Brand 5',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '3',
        name: 'Competitor 3',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId23',
    productDetails: {
      sku: '051-0792-000006',
      name: 'United Pacific 46129 Chrome Plated 3 Trumpet Train Horn, 12V Heavy-Duty Electric Solenoid, 150 PSI Max, Super Loud Output 145dB +/- 10 dB - ONE Set',
      imageUrl: 'https://m.media-amazon.com/images/I/616J28KdA3L.jpg',
      extItemId: 'B001JT3AKS',
    },
    brand: [
      {
        id: '4',
        name: 'Brand 4',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId24',
    productDetails: {
      sku: '218-1998-000008',
      name: 'Phanteks (PH-NV723TG_DBK01) NV7 Showcase Full-Tower Chassis, High Airflow Performance, Integrated D/A-RGB Lighting, Seamless Tempered Glass Design, 12 Fan Positions, Black',
      imageUrl: 'https://m.media-amazon.com/images/I/71YZBdiF1aL.jpg',
      extItemId: 'B0BYQXVG4M',
    },
    brand: [
      {
        id: '1',
        name: 'Brand 1',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '3',
        name: 'Competitor 3',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId25',
    productDetails: {
      sku: '212-1780-000028',
      name: 'Esco 10820 Pro Series Combi Bead Breaker Kit with Pump',
      imageUrl: 'https://m.media-amazon.com/images/I/81EGO4cEiXL.jpg',
      extItemId: 'B01DUXMU2O',
    },
    brand: [
      {
        id: '3',
        name: 'Brand 3',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId26',
    productDetails: {
      sku: '218-1998-000013',
      name: 'Phanteks NV9, Showcase Full-Tower Chassis, High Airflow Performance, Integrated D/A-RGB Lighting, Seamless Tempered Glass Design, 11x 140mm Fan Positions (Black)',
      imageUrl: 'https://m.media-amazon.com/images/I/71es0HRmF1L.jpg',
      extItemId: 'B0CPHB366C',
    },
    brand: [
      {
        id: '4',
        name: 'Brand 4',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '3',
        name: 'Competitor 3',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId27',
    productDetails: {
      sku: '218-2438-000012',
      name: 'Montech Sky Two, Dual Tempered Glass, 4X PWM ARGB Fans Pre-Installed, ATX Gaming Mid Tower Computer Case, Type C, High Airflow Performance- White',
      imageUrl: 'https://m.media-amazon.com/images/I/715Khd+IoDL.jpg',
      extItemId: 'B0BNTM5W5P',
    },
    brand: [
      {
        id: '5',
        name: 'Brand 5',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId28',
    productDetails: {
      sku: '111-0792-000093',
      name: 'United Pacific BHC01-15 Triple Chrome 15” Steel Baby Moon Hub Caps, Mirror Finish, Smooth Plain Face, Domed Hub Caps, Chrome Wheel Covers - Set of 4',
      imageUrl: 'https://m.media-amazon.com/images/I/41yzVmyTPaL.jpg',
      extItemId: 'B0721PR7HV',
    },
    brand: [
      {
        id: '1',
        name: 'Brand 1',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '3',
        name: 'Competitor 3',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
  {
    productCatalogId: 'pcId29',
    productDetails: {
      sku: '218-1917-000122',
      name: 'Fractal Design Terra Gaming Computer Case',
      imageUrl: 'https://m.media-amazon.com/images/I/61WrkoJ0dEL.jpg',
      extItemId: 'B0CD66CJ3N',
    },
    brand: [
      {
        id: '4',
        name: 'Brand 4',
      },
      {
        id: '2',
        name: 'Common Brand',
      },
    ],
    competitor: [
      {
        id: '1',
        name: 'Competitor 1',
      },
      {
        id: '2',
        name: 'Common Competitor',
      },
    ],
  },
]);
