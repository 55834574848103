import { DateTime } from 'luxon';

import { COMPASS_API_REQUEST_DATE_FORMAT, DateRange } from './types';
import { MetricsData } from './components/CustomizeMetricsSlideover';
import { HomepageResponseMetricKeys } from '../../lib/types/CompassSharedTypes';
import { Fw2LocalStorage } from '../../lib/utilities/fw2LocalStorage';
import { Filter } from '../../lib/types/Filter';
import { Fw2SessionStorage } from '../../lib/utilities/fw2SessionStorage';
import { CurrencyCode } from '../../lib/utilities/currency';

export interface UserInfo {
  readonly userId: string;
  readonly accountId: string;
}

const getLocalStorageKeyNameForCompassCatalogDateRange = ({
  userId,
  accountId,
}: UserInfo) => `${userId}_${accountId}_compass_catalog_date_range`;

export const setCompassCatalogDateRangeInLocalStorage = (
  userInfo: UserInfo,
  dateRange?: DateRange
) => {
  if (dateRange) {
    const formattedDateRange = {
      ...dateRange,
      startDate: dateRange.startDate.toFormat(COMPASS_API_REQUEST_DATE_FORMAT),
      endDate: dateRange.endDate.toFormat(COMPASS_API_REQUEST_DATE_FORMAT),
    };

    Fw2LocalStorage.setItem(
      getLocalStorageKeyNameForCompassCatalogDateRange(userInfo),
      JSON.stringify(formattedDateRange)
    );
  }
};

const getCompassCatalogFiltersLocalStorageKey = (
  userId: string,
  accountId: string
) => `${userId}_${accountId}_compass_catalog_filters`;

export const getCompassCatalogFiltersFromLocalStorage = (
  userId: string,
  accountId: string
): Filter[] => {
  const filtersLocalStorageKey = getCompassCatalogFiltersLocalStorageKey(
    userId,
    accountId
  );
  const filtersFromLocalStorage = Fw2LocalStorage.getItem(
    filtersLocalStorageKey
  );

  try {
    return filtersFromLocalStorage
      ? (JSON.parse(filtersFromLocalStorage) as Filter[])
      : [];
  } catch (e) {
    return [];
  }
};

const getLocalStorageKeyNameForCompassMerchants = ({
  userId,
  accountId,
}: UserInfo) => `${userId}_${accountId}_compass_catalog_merchants`;

const getSessionStorageKeyNameForCompassAPIFailCount = ({
  userId,
  accountId,
}: UserInfo) => `${userId}_${accountId}_compass_api_fail_count`;

export const getCompassCatalogMerchantsFromLocalStorage = (
  userInfo: UserInfo
): string[] | null => {
  try {
    const key = getLocalStorageKeyNameForCompassMerchants(userInfo);
    const selectedMerchants = Fw2LocalStorage.getItem(key);
    return selectedMerchants ? JSON.parse(selectedMerchants) : null;
  } catch (error) {
    return null;
  }
};

export const setCompassCatalogMerchantsInLocalStorage = (
  userInfo: UserInfo,
  merchants: string[]
) =>
  Fw2LocalStorage.setItem(
    getLocalStorageKeyNameForCompassMerchants(userInfo),
    JSON.stringify(merchants)
  );

export const incrementCompassAPIFailCount = (userInfo: UserInfo) => {
  Fw2SessionStorage.setItem(
    getSessionStorageKeyNameForCompassAPIFailCount(userInfo),
    (Number(getCompassAPIFailCount(userInfo)) + 1).toString()
  );
};

export const clearCompassAPIFailCount = (userInfo: UserInfo) => {
  Fw2SessionStorage.setItem(
    getSessionStorageKeyNameForCompassAPIFailCount(userInfo),
    '0'
  );
};

export const getCompassAPIFailCount = (userInfo: UserInfo) => {
  const count = Fw2SessionStorage.getItem(
    getSessionStorageKeyNameForCompassAPIFailCount(userInfo)
  );
  return count ? +count : 0;
};

export const setCompassCatalogCurrencyInLocalStorage = (
  userInfo: UserInfo,
  currency: CurrencyCode
) =>
  Fw2LocalStorage.setItem(
    getLocalStorageKeyNameForCompassCatalogCurrency(userInfo),
    currency
  );

const getLocalStorageKeyNameForCompassCatalogCurrency = ({
  userId,
  accountId,
}: UserInfo) => `${userId}_${accountId}_compass_catalog_currency`;

export const getCompassCatalogCurrencyFromLocalStorage = (userInfo: UserInfo) =>
  Fw2LocalStorage.getItem(
    getLocalStorageKeyNameForCompassCatalogCurrency(userInfo)
  ) as CurrencyCode;

export const getCompassCatalogDateRangeFromLocalStorage = (
  userInfo: UserInfo
) => {
  try {
    const key = getLocalStorageKeyNameForCompassCatalogDateRange(userInfo);

    const formattedDateRangeString = Fw2LocalStorage.getItem(key) || '';
    const formattedDateRange = JSON.parse(formattedDateRangeString);

    const dateRange: DateRange = {
      startDate: DateTime.fromFormat(
        formattedDateRange.startDate,
        COMPASS_API_REQUEST_DATE_FORMAT
      ),
      endDate: DateTime.fromFormat(
        formattedDateRange.endDate,
        COMPASS_API_REQUEST_DATE_FORMAT
      ),
    };

    return dateRange;
  } catch (error) {
    return null;
  }
};

const getLocalStorageKeyForCustomizeSlideoverConfig = ({
  userId,
  accountId,
}: UserInfo) => `${userId}_${accountId}_customize_slideover_config`;

export const getCustomizeSlideoverConfigFromLocalStorage = (
  userInfo: UserInfo
): MetricsData[] | null => {
  try {
    const key = getLocalStorageKeyForCustomizeSlideoverConfig(userInfo);
    const customizeSlideoverConfig = Fw2LocalStorage.getItem(key);
    return customizeSlideoverConfig
      ? JSON.parse(customizeSlideoverConfig)
      : null;
  } catch (error) {
    return null;
  }
};

export const setCustomizeSlideoverConfigFromLocalStorage = (
  userInfo: UserInfo,
  customizeSlideoverConfig: MetricsData[]
) =>
  Fw2LocalStorage.setItem(
    getLocalStorageKeyForCustomizeSlideoverConfig(userInfo),
    JSON.stringify(customizeSlideoverConfig)
  );

const getLocalStorageKeyForActiveMetric = (
  { userId, accountId }: UserInfo,
  isPrimary: boolean
) =>
  `${userId}_${accountId}_${isPrimary ? 'primary' : 'secondary'}_active_metric`;

export const getActiveMetricFromLocalStorage = (
  userInfo: UserInfo,
  isPrimary: boolean
): HomepageResponseMetricKeys | undefined => {
  try {
    const key = getLocalStorageKeyForActiveMetric(userInfo, isPrimary);
    const metric = Fw2LocalStorage.getItem(key);
    return (metric as HomepageResponseMetricKeys) || undefined;
  } catch (error) {
    return undefined;
  }
};

export const setActiveMetricFromLocalStorage = (
  userInfo: UserInfo,
  isPrimary: boolean,
  metric?: HomepageResponseMetricKeys
) =>
  metric
    ? Fw2LocalStorage.setItem(
        getLocalStorageKeyForActiveMetric(userInfo, isPrimary),
        metric
      )
    : Fw2LocalStorage.removeItem(
        getLocalStorageKeyForActiveMetric(userInfo, isPrimary)
      );

const getLocalStorageKeyForShowPreviousMetricData = ({
  userId,
  accountId,
}: UserInfo) => `${userId}_${accountId}_show_prev_metric_data`;

export const getIsShowPreviousDataFromLocalStorage = (
  userInfo: UserInfo
): boolean => {
  const prevPeriodStateStorageKey =
    getLocalStorageKeyForShowPreviousMetricData(userInfo);
  const prevPeriodStateFromLocalStorage = Fw2LocalStorage.getItem(
    prevPeriodStateStorageKey
  );

  try {
    return (
      JSON.parse(prevPeriodStateFromLocalStorage as string).toString() ===
      'true'
    );
  } catch (e) {
    return false;
  }
};

export const setIsShowPreviousDataFromLocalStorage = (
  userInfo: UserInfo,
  state: boolean
) => {
  const prevPeriodStateStorageKey =
    getLocalStorageKeyForShowPreviousMetricData(userInfo);
  Fw2LocalStorage.setItem(prevPeriodStateStorageKey, state.toString());
};
