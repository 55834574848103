import React from 'react';
import { useIntl } from 'react-intl';

import {
  AlertTriangleIcon,
  Icon,
  IconSize,
  LightbulbIcon,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';

const AMAZON_SELLER_CENTRAL_LINK = `https://intercom.help/flywheel-20/en/articles/6339628-how-do-i-know-if-i-have-access-to-amazon-seller-central`;

export const ModalContent: React.FC = () => {
  const intl = useIntl();
  const [HEADER, SUBHEADER, WARNING, WARNING_DESCRIPTION, HELP] = [
    I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_FAILED_CONTENT_HEADER,
    I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_FAILED_CONTENT_SUBHEADER,
    I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_FAILED_CONTENT_WARNING_HEADER,
    I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_FAILED_CONTENT_WARNING_SUBHEADER,
    I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_FAILED_CONTENT_HELP,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <div className="text-left flex flex-col justify-between text-base text-grey-900 gap-24">
      <div className="text-left flex flex-col flex-1">
        <div className="flex justify-center mt-48 m-24">
          <div className="w-64 h-64 flex justify-center items-center bg-orange-500  border-4 border-purple-100 border-solid rounded-full">
            <Icon
              svg={AlertTriangleIcon}
              size={IconSize.Medium}
              className="w-24 h-24 text-white"
            />
          </div>
        </div>
        <div className="px-12 mx-8 mb-24">
          <p className="text-center text-lg text-grey-900 leading-none font-bold">
            {HEADER}
          </p>
          <p className="text-center text-grey-900 my-24 text-align-center text-md leading-normal font-normal">
            {SUBHEADER}
          </p>
          <p className="text-center text-grey-600 text-align-center leading-normal font-normal text-sm underline">
            {HELP}
          </p>
        </div>
      </div>

      <div className="bg-yellow-100 align-bottom flex px-12 py-16 rounded mx-8 mt-16">
        <Icon
          svg={LightbulbIcon}
          size={IconSize.Medium}
          className="text-yellow-700 w-20 h-20"
        />
        <div className="block ml-12">
          <p className="font-bold">{WARNING}</p>
          <a
            className="text-sm mb-12 underline"
            href={AMAZON_SELLER_CENTRAL_LINK}
            target="_blank"
            rel="noreferrer"
          >
            {WARNING_DESCRIPTION}
          </a>
        </div>
      </div>
    </div>
  );
};
