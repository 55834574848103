import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import {
  ContentStyle,
  ControlledTooltip,
  Placement,
  SelectProps,
  SelectState,
  createMultiSelectDataFieldFilter,
  AlertTriangleIcon as warningSVG,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { UPDATE_CELL } from '../../../../../containers/table/ducks/actions';
import {
  UpdateCellPayload,
  WithTable,
} from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
  makeTextWithIconColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  AdGroupStatus,
  AdLevel,
  AutomationStatus,
  CampaignTargetingType,
  ChannelSettingsAdgroup,
  DetailAdGroup,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  getAutomationDisabledForLegacyCampaignTooltipMessage,
  getAutomationDisabledTooltipMessage,
  getOrDefaultIsBiddableFlag,
  getTooltipContentForArchivedEntity,
  isAdGroupStatusArchived,
  isCampaignCostTypeVcpm,
  isCampaignStatusArchived,
  isValidMacsTarget,
  isValidMaxBid,
  isValidMinBid,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { getCurrentRowIndex } from './utils';
import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import { SPAction } from '../../../../../lib/types/TeikaReduxTypes';

const getBidAutomationStatusText = (
  adGroupDetails: AdGroupDetails,
  intl: IntlShape
) => {
  if (!isNil(adGroupDetails.flywheelSettings.automationStatus)) {
    return startCase(
      adGroupDetails.flywheelSettings.automationStatus === true
        ? intl.formatMessage({
            id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ACTIVE,
          })
        : intl.formatMessage({
            id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_INACTIVE,
          })
    );
  }
};

const getBidAutomationStatus = ({
  flywheelSettings: { automationStatus },
}: AdGroupDetails) => {
  if (!isUndefined(automationStatus)) {
    return automationStatus === true
      ? AutomationStatus.Active
      : AutomationStatus.Inactive;
  }
  return AutomationStatus.Unset;
};

const getExtraValidationsForBid = (
  flywheelSalesChannel: FlywheelSalesChannel,
  adGroupStatus: AdGroupStatus | undefined,
  adGroupDetails: DetailAdGroup
) => {
  if (flywheelSalesChannel === FlywheelSalesChannel.Amazon) {
    return () =>
      !isAdGroupStatusArchived(adGroupStatus) &&
      !isCampaignStatusArchived(adGroupDetails.campaignStatus);
  }
};

const getExtraValidationsForMacsTarget = (
  value: string,
  flywheelSalesChannel: FlywheelSalesChannel,
  maxMacsTargetValue: number,
  adGroupStatus: AdGroupStatus | undefined,
  adGroupDetails: DetailAdGroup
) => {
  if (flywheelSalesChannel === FlywheelSalesChannel.Amazon) {
    return () =>
      Number(value) < maxMacsTargetValue &&
      !isAdGroupStatusArchived(adGroupStatus) &&
      !isCampaignStatusArchived(adGroupDetails.campaignStatus);
  }
};

const isCurrentOrUpdatedMacsTargetValueValid = (
  macsTarget: number | undefined,
  salesChannel: FlywheelSalesChannel,
  maxMacsTargetValue: number,
  adGroupStatus: AdGroupStatus | undefined,
  adGroupDetails: DetailAdGroup,
  macsTargetValue: string
) => {
  if (!isNil(macsTarget)) {
    return isValidMacsTarget(
      String(macsTarget * 100),
      getExtraValidationsForMacsTarget(
        String(macsTarget * 100),
        salesChannel,
        maxMacsTargetValue,
        adGroupStatus,
        adGroupDetails
      )
    );
  }
  return isValidMacsTarget(
    macsTargetValue,
    getExtraValidationsForMacsTarget(
      macsTargetValue,
      salesChannel,
      maxMacsTargetValue,
      adGroupStatus,
      adGroupDetails
    )
  );
};

const isCurrentOrUpdatedMinBidValueValid = (
  minBidAmount: string | undefined,
  minBidValue: string,
  maxBidAmount: string | undefined,
  maxBidValue: string,
  adGroupMaxBidValue: number,
  adGroupMinBidValue: number,
  extraValidations?: () => boolean
) => {
  const minValue = minBidAmount || minBidValue;
  const maxValue = maxBidAmount || maxBidValue;

  return isValidMinBid(
    minValue,
    String(adGroupMaxBidValue),
    String(adGroupMinBidValue),
    maxValue,
    extraValidations
  );
};

const isCurrentOrUpdatedMaxBidValueValid = (
  minBidAmount: string | undefined,
  minBidValue: string,
  maxBidAmount: string | undefined,
  maxBidValue: string,
  adGroupMaxBidValue: number,
  adGroupMinBidValue: number,
  extraValidations?: () => boolean
) => {
  const minValue = minBidAmount || minBidValue;
  const maxValue = maxBidAmount || maxBidValue;

  return isValidMaxBid(
    maxValue,
    String(adGroupMaxBidValue),
    String(adGroupMinBidValue),
    minValue,
    extraValidations
  );
};

const getBidAutomationStatusSelectState = (
  data: AdGroupDetails,
  isCampaignCostTypeEqualToVcpm: boolean,
  automationDisabledForMerchant: boolean,
  automationDisabledForLegacyRow: boolean,
  updatedBidAutomationStatus: string
) => {
  if (
    isAdGroupStatusArchived(data.channelSettings.status) ||
    isCampaignStatusArchived(data.adGroupDetails.campaignStatus) ||
    isCampaignCostTypeEqualToVcpm ||
    automationDisabledForMerchant ||
    automationDisabledForLegacyRow
  ) {
    return SelectState.Disabled;
  } else {
    return updatedBidAutomationStatus === AutomationStatus.Unset
      ? SelectState.Disabled
      : SelectState.Default;
  }
};

const getTooltipContent = (
  intl: IntlShape,
  isCampaignCostTypeEqualToVcpm: boolean,
  automationDisabledForMerchant: boolean,
  automationDisabledForLegacyRow: boolean,
  isAdGroupArchived: boolean,
  updatedBidAutomationStatus: string
) => {
  if (isCampaignCostTypeEqualToVcpm) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_FLYWHEEL_SETTINGS_UNSET_TOOLTIP,
        })}
      </p>
    );
  }
  if (automationDisabledForMerchant) {
    return getAutomationDisabledTooltipMessage(
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_AUTOMATION_STATUS,
      })
    );
  }

  if (automationDisabledForLegacyRow) {
    return getAutomationDisabledForLegacyCampaignTooltipMessage();
  }

  if (isAdGroupArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.AdGroups],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_AUTOMATION_STATUS,
      }),
      intl
    );
  }
  if (updatedBidAutomationStatus === AutomationStatus.Unset) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_FLYWHEEL_SETTINGS_AUTOMATION_STATUS_UNSET_TOOLTIP,
          },
          {
            adLevel: lowerCase(
              intl.formatMessage({
                id: AdLevelI8nKeyMapper[AdLevel.AdGroups],
              })
            ),
          }
        )}
      </p>
    );
  }
};

const tooltipState = (
  isAdGroupArchived: boolean,
  updatedBidAutomationStatus: string
) =>
  isAdGroupArchived || updatedBidAutomationStatus === AutomationStatus.Unset
    ? ControlledTooltip.None
    : ControlledTooltip.Hide;

const getBidAutomationValue = (
  automationDisabledForMerchant: boolean,
  existingValue: string,
  updatedBidAutomationStatus: string
) =>
  automationDisabledForMerchant ? existingValue : updatedBidAutomationStatus;

const getAutomationStatus = (data: AdGroupDetails) =>
  data.flywheelSettings.automationStatus === true
    ? AutomationStatus.Active
    : AutomationStatus.Inactive;

const getIsAdGroupArchived = (
  channelSettings: ChannelSettingsAdgroup,
  adGroupDetails: DetailAdGroup
) =>
  isAdGroupStatusArchived(channelSettings.status) ||
  isCampaignStatusArchived(adGroupDetails.campaignStatus);

const isMinAndMaxBidValueValid = (
  minBidAmount: string | undefined,
  minBidValue: string,
  maxBidAmount: string | undefined,
  maxBidValue: string,
  adGroupMaxBidValue: number,
  adGroupMinBidValue: number,
  extraValidations?: () => boolean
) => {
  return (
    isCurrentOrUpdatedMinBidValueValid(
      minBidAmount,
      minBidValue,
      maxBidAmount,
      maxBidValue,
      adGroupMaxBidValue,
      adGroupMinBidValue,
      extraValidations
    ) &&
    isCurrentOrUpdatedMaxBidValueValid(
      minBidAmount,
      minBidValue,
      maxBidAmount,
      maxBidValue,
      adGroupMaxBidValue,
      adGroupMinBidValue,
      extraValidations
    )
  );
};

const getBidValue = (value: string) => value || '';

const isAutomationDisabledForMerchant = (
  adGroupDetails: DetailAdGroup,
  merchantsWithAutomationEnabled: string[] | undefined
) =>
  !merchantsWithAutomationEnabled?.includes(
    adGroupDetails.merchantCountryId ?? ''
  );

const onValueChange = (
  automationDisabledForMerchant: boolean,
  data: AdGroupDetails,
  value: string,
  existingValue: string,
  dispatch: Dispatch<SPAction<typeof UPDATE_CELL, UpdateCellPayload>>
) => {
  automationDisabledForMerchant === false &&
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
        rowId: data.adGroupId,
        columnName: ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
        value,
        existingValue,
      })
    );
};

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  adGroupId,
  adGroupDetails,
  channelSettings,
  flywheelSettings,
  adGroupPerformance,
  isEditMode,
  salesChannel,
  selectedAdType,
  merchantCountry,
  merchantType,
  merchantsWithAutomationEnabled,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const bidConstraintsData = useBidConstraintsContext();

  const data: AdGroupDetails = {
    adGroupId,
    adGroupDetails,
    channelSettings,
    flywheelSettings,
    adGroupPerformance,
  };

  const { macsTarget, maxBid, minBid } = flywheelSettings;
  const maxBidAmount = maxBid?.amount;
  const minBidAmount = minBid?.amount;

  const { campaignCostType } = adGroupDetails;

  const currentRowIndex = useSelector<WithTable<AdGroupDetails>, number>(
    getCurrentRowIndex(data)
  );

  let updatedBidAutomationStatus = useSelector<
    WithTable<AdGroupDetails>,
    string
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      data.adGroupId,
      ADGROUPS_API_COLUMN_NAME.BidAutomationStatus
    )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  if (
    isUndefined(updatedBidAutomationStatus) &&
    !isNil(data.flywheelSettings.automationStatus)
  ) {
    updatedBidAutomationStatus = getAutomationStatus(data);
  }

  const macsTargetValue = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MACSTarget
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const isCampaignCostTypeEqualToVcpm =
    isCampaignCostTypeVcpm(campaignCostType);

  const campaignTargetingType: CampaignTargetingType | undefined = data
    .adGroupDetails.campaignTargetingType as CampaignTargetingType;

  const { minBid: adGroupMinBidValue } = getBidConstraint(
    bidConstraintsData.constraints,
    selectedAdType,
    salesChannel,
    ADGROUPS_API_COLUMN_NAME.MinBid,
    merchantCountry,
    campaignTargetingType,
    merchantType,
    campaignCostType
  );

  const { maxBid: adGroupMaxBidValue } = getBidConstraint(
    bidConstraintsData.constraints,
    selectedAdType,
    salesChannel,
    ADGROUPS_API_COLUMN_NAME.MaxBid,
    merchantCountry,
    campaignTargetingType,
    merchantType,
    campaignCostType
  );

  const { maxMacsTarget: maxMacsTargetValue } = getBidConstraint(
    bidConstraintsData.constraints,
    selectedAdType,
    salesChannel,
    ADGROUPS_API_COLUMN_NAME.MACSTarget,
    merchantCountry,
    campaignTargetingType,
    merchantType,
    campaignCostType
  );

  const adGroupStatus = channelSettings.status;

  const minBidValue = getBidValue(
    useSelector<WithTable<AdGroupDetails>, string>(({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MinBid
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
    )
  );

  const maxBidValue = getBidValue(
    useSelector<WithTable<AdGroupDetails>, string>(({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MaxBid
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
    )
  );

  if (isEditMode) {
    const [ACTIVE, INACTIVE, UNSET] = [
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ACTIVE,
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_INACTIVE,
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_UNSET,
    ].map((id) => intl.formatMessage({ id }));

    const options = [
      {
        label: ACTIVE,
        value: AutomationStatus.Active,
      },
      {
        label: INACTIVE,
        value: AutomationStatus.Inactive,
      },
      {
        label: UNSET,
        value: AutomationStatus.Unset,
        disabled: true,
      },
    ];

    const automationDisabledForMerchant = isAutomationDisabledForMerchant(
      adGroupDetails,
      merchantsWithAutomationEnabled
    );

    const automationDisabledForLegacyRow = !getOrDefaultIsBiddableFlag(
      adGroupDetails.isBiddable
    );

    const existingValue: string = getBidAutomationStatus(data);

    const onBidAutomationStatusChange = (value: string) => {
      onValueChange(
        automationDisabledForMerchant,
        data,
        value,
        existingValue,
        dispatch
      );
    };

    if (
      isCurrentOrUpdatedMacsTargetValueValid(
        macsTarget,
        salesChannel,
        maxMacsTargetValue,
        adGroupStatus,
        adGroupDetails,
        macsTargetValue
      ) &&
      isMinAndMaxBidValueValid(
        minBidAmount,
        minBidValue,
        maxBidAmount,
        maxBidValue,
        adGroupMaxBidValue,
        adGroupMinBidValue,
        getExtraValidationsForBid(salesChannel, adGroupStatus, adGroupDetails)
      )
    ) {
      if (!updatedBidAutomationStatus) {
        onBidAutomationStatusChange(AutomationStatus.Active);
      }
    } else if (isNil(data.flywheelSettings.automationStatus)) {
      if (updatedBidAutomationStatus) {
        onBidAutomationStatusChange(AutomationStatus.Unset);
      } else {
        updatedBidAutomationStatus = AutomationStatus.Unset;
      }
    }

    const bidAutomationStatusProps: SelectProps<string> = {
      options,
      placeholder: '',
      value: getBidAutomationValue(
        automationDisabledForMerchant,
        existingValue,
        updatedBidAutomationStatus
      ),
      state: getBidAutomationStatusSelectState(
        data,
        isCampaignCostTypeEqualToVcpm,
        automationDisabledForMerchant,
        automationDisabledForLegacyRow,
        updatedBidAutomationStatus
      ),
      isDirty: updatedBidAutomationStatus !== existingValue,
      onChange: onBidAutomationStatusChange,
      className: 'w-120',
      showOnTop: currentRowIndex > 6,
    };

    return makeSelectColumn(
      () => bidAutomationStatusProps,
      () => {
        const isAdGroupArchived = getIsAdGroupArchived(
          channelSettings,
          adGroupDetails
        );

        return {
          tooltipContent: getTooltipContent(
            intl,
            isCampaignCostTypeEqualToVcpm,
            automationDisabledForMerchant,
            automationDisabledForLegacyRow,
            isAdGroupArchived,
            updatedBidAutomationStatus
          ),
          controlledTooltip: tooltipState(
            isAdGroupArchived,
            updatedBidAutomationStatus
          ),
          tooltipPlacement: Placement.Bottom,
          style: ContentStyle.Bold,
        };
      }
    )(data);
  }

  if (isUndefined(data.flywheelSettings.automationStatus)) {
    return makeTextWithIconColumn(
      () => AutomationStatus.Unset,
      warningSVG,
      <div className="max-w-220">
        <FormattedMessage
          id={
            I18nKey.ADS_MANAGER_ADGROUP_TABLE_AUTOMATION_COLUMN_UNSET_STATUS_TOOLTIP
          }
          values={{ separator: <br /> }}
        />
      </div>
    )(data);
  }

  return makeTextColumn((details: AdGroupDetails) =>
    getBidAutomationStatusText(details, intl)
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

export const bidAutomationStatusColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_AUTOMATION_STATUS,
  RowCellElement,
  gridColumnWidth: '172px',
};

export const bidAutomationStatusFilter = createMultiSelectDataFieldFilter(
  ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
  I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_AUTOMATION_STATUS,
  [
    SelectFilterOption.BidAutomationStatusActive,
    SelectFilterOption.BidAutomationStatusInactive,
    SelectFilterOption.BidAutomationStatusUnset,
  ]
);
