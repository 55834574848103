import { useContext } from 'react';

import {
  getCurrentAccountPermissions,
  mapRoleToI18nKey,
} from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { Role, UserDetails } from '../types/Fam';

export const useUserCredentials = (): {
  userDetails: UserDetails;
  userRole: string;
  companyName?: string;
} => {
  const userContext = useContext<UserContextState>(UserContext);

  const userDetails = userContext.userInfo.userDetails!;
  const accountPermissions = getCurrentAccountPermissions(
    userContext.userInfo.userDetails
  );
  const userRole = mapRoleToI18nKey(
    accountPermissions?.role || Role.ACCOUNT_OWNER
  );
  const companyName = accountPermissions?.account.companyName;

  return { userDetails, userRole, companyName };
};
