import { Modal } from '@teikametrics/tm-design-system';
import { DataTimingAndSyncingContent } from './components/DataTimingAndSyncingContent';
import { DataTimingAndSyncingHeader } from './components/DataTimingAndSyncingHeader';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../lib/utilities/intercom';
import { useEffect } from 'react';

interface DataTimingAndSyncingSlideoutProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

export const DataTimingAndSyncingSlideout: React.FC<
  DataTimingAndSyncingSlideoutProps
> = (props) => {
  const { isOpen, onClose } = props;

  useEffect(() => {
    if (isOpen) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [isOpen]);

  return (
    <Modal
      className="flex flex-col h-screen min-w-512 max-w-512 w-512"
      showModal={isOpen}
      onClose={onClose}
      slideOutModal
      hidePadding
      dataTestId="data_timing_and_syncing_slide_out"
    >
      <div className="flex flex-col h-screen overflow-hidden">
        <header className="w-full border-b-1 border-solid border-grey-200">
          <DataTimingAndSyncingHeader
            closeSlideOut={onClose}
          ></DataTimingAndSyncingHeader>
        </header>
        <main className="flex-1 flex-start gap-32 overflow-y-auto scrollbar-thin scrollbar-thumb-grey-400 scrollbar-track-grey-100 scrollbar-thumb-rounded-full px-12">
          <DataTimingAndSyncingContent />
        </main>
      </div>
    </Modal>
  );
};

DataTimingAndSyncingSlideout.displayName = 'DataTimingAndSyncingSlideout';
