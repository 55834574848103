import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useNavigate } from 'react-router-dom';

import { FlywheelSalesChannel } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { UpgradeBanner } from './upgradeBanner';
import RiskFreeTrialModal from '../../../../components/BillingModals/RiskFreeTrialModal/RiskFreeTrialModal';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import {
  getCurrentAccountFromContext,
  isManagedOrAgencyAccount,
} from '../../../../containers/userProvider/selectors';

interface EnableAIBannerProps {
  readonly flywheelSalesChannel: FlywheelSalesChannel;
  readonly numberOfMerchantWithAIPlanEnabled: number;
  readonly totalNumberOfMerchantsForSalesChannel: number;
  readonly updateSubscription: () => void;
}

const SALES_CHANNEL_URL_FOR_AMAZON_TAB = `/account/sales-channels?channel=amazon`;

export const EnableAIBanner: React.FC<
  EnableAIBannerProps & PropsWithChildren
> = ({
  flywheelSalesChannel,
  numberOfMerchantWithAIPlanEnabled,
  totalNumberOfMerchantsForSalesChannel,
  updateSubscription,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const userContext = React.useContext<UserContextState>(UserContext);

  const [showRiskFreeTrial, setShowRiskFreeTrial] = useState(false);

  const currentAccount = getCurrentAccountFromContext(userContext);

  const enableAIPlanMessage = useMemo(() => {
    if (flywheelSalesChannel === FlywheelSalesChannel.Amazon) {
      return intl.formatMessage({
        id: I18nKey.BANNER_AUTOMATION_IS_DISABLED,
      });
    } else if (flywheelSalesChannel === FlywheelSalesChannel.Walmart) {
      return numberOfMerchantWithAIPlanEnabled === 0
        ? intl.formatMessage({
            id: I18nKey.BANNER_AUTOMATION_IS_DISABLED_FOR_ALL_MERCHANTS,
          })
        : intl.formatMessage(
            {
              id: I18nKey.BANNER_AUTOMATION_IS_DISABLED_FOR_FEW_MERCHANTS,
            },
            {
              numberOfMerchantsWithAIPlanDisabled:
                totalNumberOfMerchantsForSalesChannel -
                numberOfMerchantWithAIPlanEnabled,
              totalNumberOfMerchantsForSalesChannel,
            }
          );
    }
  }, [
    flywheelSalesChannel,
    numberOfMerchantWithAIPlanEnabled,
    totalNumberOfMerchantsForSalesChannel,
  ]);

  const onClickHandler = () => {
    const shouldEnableAutomationFromAO =
      flywheelSalesChannel === FlywheelSalesChannel.Amazon ||
      totalNumberOfMerchantsForSalesChannel -
        numberOfMerchantWithAIPlanEnabled ===
        1;

    if (
      shouldEnableAutomationFromAO &&
      !isManagedOrAgencyAccount(currentAccount)
    ) {
      setShowRiskFreeTrial(true);
    } else {
      navigate(SALES_CHANNEL_URL_FOR_AMAZON_TAB);
    }
  };

  return (
    <UpgradeBanner>
      {enableAIPlanMessage}
      <a
        href="#"
        className="underline m-4 hover:text-blue-700"
        onClick={onClickHandler}
      >
        {intl.formatMessage({
          id: I18nKey.BANNER_AUTOMATION_ENABLE_AI_PLAN,
        })}
      </a>
      {showRiskFreeTrial && (
        <RiskFreeTrialModal
          onCancelClicked={() => setShowRiskFreeTrial(false)}
          onPaymentSuccess={updateSubscription}
        />
      )}
    </UpgradeBanner>
  );
};
EnableAIBanner.displayName = 'EnableAIBanner';
