import { Factory } from 'fishery';

import {
  AdGroupDetails,
  AdGroupReviewStatusType,
  AdGroupStatus,
  AdvertisingGoal,
  AiBiddingValues,
  CampaignCostType,
  CampaignStatus,
  CampaignTactic,
  CampaignTargetingType,
} from '../../types/AOSharedTypes';

export interface Response {
  readonly elements: AdGroupDetails[];
  readonly filteredElements: number;
}

export default Factory.define<Response>(() => ({
  elements: [
    {
      adGroupId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec17',
      adGroupDetails: {
        isBiddable: true,
        campaignCostType: CampaignCostType.CPC,
        campaignType: CampaignTargetingType.Manual,
        adGroupName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignName: 'Test campaignName',
        campaignId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignTargetingType: CampaignTargetingType.Manual,
        targetSegments: ['brand', 'competitor'],
        campaignStatus: CampaignStatus.Live,
        campaignTactic: CampaignTactic.Product,
        campaignPortfolioName: 'TK - K2D3',
        campaignDailyBudget: {
          currency: 'USD',
          amount: '10',
        },
        campaignTotalBudget: {
          currency: 'USD',
          amount: '167.30',
        },
      },
      channelSettings: {
        status: AdGroupStatus.Enabled,
        isEditable: true,
        defaultBid: {
          amount: '0.30',
          currency: 'USD',
        },
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.InProgress,
          reviewReason: [],
        },
      },

      adGroupPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
        acosDirect: 0.6789,
        adConversions: 12,
        adSalesDirect: {
          amount: '25.90',
          currency: 'USD',
        },
        avgCostPerClick: {
          amount: '3.14',
          currency: 'USD',
        },
        brandClickRevenue: {
          amount: '6.02',
          currency: 'USD',
        },
        clickThroughRate: 0.04564102564102564,
        newToBrandOrders: 2048,
        newToBrandSales: {
          amount: '123.50',
          currency: 'USD',
        },
        newToBrandAdUnitsSold: 1024,
        relatedClickRevenue: {
          amount: '14.20',
          currency: 'USD',
        },
        roasDirect: 45.4682976903336184,
        advertisedSkuUnits: 10,
        otherSkuUnits: 10,
        totalAttributedSales: 15000,
        orders: 500,
        videoImpressions: 20000,
        viewableImpressions: 15000,
        viewThroughRate: 0.75,
        clickThroughRateForViews: 3.0,
        viewThroughAttributedSales: {
          amount: '2000.00',
          currency: 'USD',
        },
        viewThroughOrders: 50,
        viewThroughUnitsSold: 70,
        totalCompleteViewAttributedSales: {
          amount: '2000.00',
          currency: 'USD',
        },
        advertisedCompleteViewAttributedSales: {
          amount: '2000.00',
          currency: 'USD',
        },
        otherCompleteViewAttributedSales: {
          amount: '2000.00',
          currency: 'USD',
        },
        completeViewOrders: 60,
        completeViewUnitsSold: 80,
        videoFirstQuartileViews: 18000,
        videoMidpointViews: 16000,
        videoThirdQuartileViews: 14000,
        videoCompleteViews: 12000,
        videoUnmutes: 5000,
        videoFiveSecondViews: 10000,
        videoFiveSecondViewsRate: 0.5,
      },
      flywheelSettings: {
        automationStatus: true,
        macsTarget: 0.2,
        minBid: {
          amount: '0.31',
          currency: 'USD',
        },
        maxBid: {
          amount: '1.20',
          currency: 'USD',
        },
        aiBidding: AiBiddingValues.Ai,
        bidModifier: 0.8,
        avgPreAdGrossMargin: 0.5,
      },
      adItemPerformance: {
        adSalesTotal: {
          amount: '389.70',
          currency: 'USD',
        },
        adSpend: {
          amount: '167.94',
          currency: 'USD',
        },
        unitsSold: 30,
        roasTotal: 2.320471596998928,
        acosTotal: 0.4309468822170901,
        conversionRate: 0.056925996204933584,
        clicks: 527,
        impressions: 45381,
      },
      pendingFields: [],
    },
    {
      adGroupId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec18',
      adGroupDetails: {
        adGroupName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignId: '16fe821b-76df-446b-9c1f-b3206f779472',
        startDate: '2/3/2021',
      },
      channelSettings: { isEditable: true },
      adGroupPerformance: {
        adSalesTotal: undefined,
        adSpend: undefined,
        unitsSold: undefined,
        acosTotal: undefined,
        conversionRate: undefined,
        impressions: undefined,
      },
      flywheelSettings: {
        aiBidding: AiBiddingValues.Smart,
        isOverrideBidModifier: true,
        automationStatus: true,
        macsTarget: 0.2,
        minBid: {
          amount: '0.31',
          currency: 'USD',
        },
        maxBid: {
          amount: '1.20',
          currency: 'USD',
        },
      },
      adItemPerformance: {
        adSalesTotal: {
          amount: '389.70',
          currency: 'USD',
        },
        adSpend: {
          amount: '167.94',
          currency: 'USD',
        },
        unitsSold: 30,
        roasTotal: 2.320471596998928,
        acosTotal: 0.4309468822170901,
        conversionRate: 0.056925996204933584,
        clicks: 527,
        impressions: 45381,
      },
      pendingFields: [],
    },
    {
      adGroupId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec18',
      adGroupDetails: {
        isBiddable: true,
        adGroupName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignName: 'Test campaignName',
        campaignId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignTargetingType: CampaignTargetingType.Manual,
        campaignCostType: CampaignCostType.CPC,
      },
      channelSettings: { isEditable: true },
      adGroupPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: undefined,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
      },
      flywheelSettings: {
        automationStatus: undefined,
        macsTarget: undefined,
        minBid: {
          amount: '0.31',
          currency: 'USD',
        },
        maxBid: {
          amount: '1.20',
          currency: 'USD',
        },
        aiBidding: AiBiddingValues.NotEnabled,
        advertisingGoal: AdvertisingGoal.Liquidate,
      },
      adItemPerformance: {
        adSalesTotal: {
          amount: '389.70',
          currency: 'USD',
        },
        adSpend: {
          amount: '167.94',
          currency: 'USD',
        },
        unitsSold: 30,
        roasTotal: 2.320471596998928,
        acosTotal: 0.4309468822170901,
        conversionRate: 0.056925996204933584,
        clicks: 527,
        impressions: 45381,
      },
      pendingFields: [],
    },
    {
      adGroupId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec18',
      adGroupDetails: {
        isBiddable: true,
        adGroupName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignName: 'Test campaignName',
        campaignId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignTargetingType: CampaignTargetingType.Manual,
        campaignCostType: CampaignCostType.CPC,
      },
      channelSettings: { isEditable: true },
      adGroupPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
      },
      flywheelSettings: {
        automationStatus: undefined,
        macsTarget: 0.2,
        minBid: {
          amount: '0.31',
          currency: 'USD',
        },
        maxBid: undefined,
      },
      adItemPerformance: {
        adSalesTotal: {
          amount: '389.70',
          currency: 'USD',
        },
        adSpend: {
          amount: '167.94',
          currency: 'USD',
        },
        unitsSold: 30,
        roasTotal: 2.320471596998928,
        acosTotal: 0.4309468822170901,
        conversionRate: 0.056925996204933584,
        clicks: 527,
        impressions: 45381,
      },
      pendingFields: [],
    },
    {
      adGroupId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec18',
      adGroupDetails: {
        isBiddable: true,
        adGroupName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignName: 'Test campaignName',
        campaignId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignTargetingType: CampaignTargetingType.Manual,
        campaignCostType: CampaignCostType.CPC,
        status: CampaignStatus.Live,
      },
      channelSettings: {
        status: AdGroupStatus.Enabled,
        isEditable: true,
      },
      adGroupPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
      },
      flywheelSettings: {
        automationStatus: undefined,
        macsTarget: 0.3,
        minBid: undefined,
        maxBid: {
          amount: '1.20',
          currency: 'USD',
        },
      },
      adItemPerformance: {
        adSalesTotal: {
          amount: '389.70',
          currency: 'USD',
        },
        adSpend: {
          amount: '167.94',
          currency: 'USD',
        },
        unitsSold: 30,
        roasTotal: 2.320471596998928,
        acosTotal: 0.4309468822170901,
        conversionRate: 0.056925996204933584,
        clicks: 527,
        impressions: 45381,
      },
      pendingFields: [],
    },
    {
      adGroupId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec18',
      adGroupDetails: {
        adGroupName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignName: 'Test campaignName',
        campaignId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignTargetingType: CampaignTargetingType.Manual,
        campaignCostType: CampaignCostType.CPC,
        status: CampaignStatus.Live,
        campaignStatus: CampaignStatus.Archived,
      },
      channelSettings: {
        status: AdGroupStatus.Enabled,
        isEditable: true,
      },
      adGroupPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
      },
      flywheelSettings: {
        automationStatus: false,
        macsTarget: 0.3,
        minBid: undefined,
        maxBid: {
          amount: '1.20',
          currency: 'USD',
        },
      },
      adItemPerformance: {
        adSalesTotal: {
          amount: '389.70',
          currency: 'USD',
        },
        adSpend: {
          amount: '167.94',
          currency: 'USD',
        },
        unitsSold: 30,
        roasTotal: 2.320471596998928,
        acosTotal: 0.4309468822170901,
        conversionRate: 0.056925996204933584,
        clicks: 527,
        impressions: 45381,
      },
      pendingFields: [],
    },
    {
      adGroupId: 'cfa0e8ec-db55-57bb-9629-a4a58c51rc17',
      adGroupDetails: {
        isBiddable: true,
        campaignCostType: CampaignCostType.CPC,
        campaignType: CampaignTargetingType.Auto,
        adGroupName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignName: 'Test campaignName',
        campaignId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignTargetingType: CampaignTargetingType.Auto,
        campaignStatus: CampaignStatus.Live,
        campaignTactic: CampaignTactic.Product,
        campaignPortfolioName: 'TK - K2D3',
        campaignDailyBudget: {
          currency: 'USD',
          amount: '10',
        },
        campaignTotalBudget: {
          currency: 'USD',
          amount: '167.30',
        },
      },
      channelSettings: {
        status: AdGroupStatus.Enabled,
        isEditable: true,
        defaultBid: {
          amount: '0.30',
          currency: 'USD',
        },
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.Pending,
          reviewReason: [],
        },
      },

      adGroupPerformance: {
        adSalesTotal: {
          amount: '270.81',
          currency: 'USD',
        },
        adSpend: {
          amount: '233.80',
          currency: 'USD',
        },
        unitsSold: 19,
        roasTotal: 1.1582976903336184,
        acosTotal: 0.8633359181714118,
        conversionRate: 0.02564102564102564,
        clicks: 741,
        impressions: 19729,
        acosDirect: 0.6789,
        adConversions: 12,
        adSalesDirect: {
          amount: '25.90',
          currency: 'USD',
        },
        avgCostPerClick: {
          amount: '3.14',
          currency: 'USD',
        },
        brandClickRevenue: {
          amount: '6.02',
          currency: 'USD',
        },
        clickThroughRate: 0.04564102564102564,
        newToBrandOrders: 2048,
        newToBrandSales: {
          amount: '123.50',
          currency: 'USD',
        },
        newToBrandAdUnitsSold: 1024,
        relatedClickRevenue: {
          amount: '14.20',
          currency: 'USD',
        },
        roasDirect: 45.4682976903336184,
      },
      flywheelSettings: {
        automationStatus: true,
        macsTarget: 0.2,
        minBid: {
          amount: '0.31',
          currency: 'USD',
        },
        maxBid: {
          amount: '1.20',
          currency: 'USD',
        },
      },
      adItemPerformance: {
        adSalesTotal: {
          amount: '389.70',
          currency: 'USD',
        },
        adSpend: {
          amount: '167.94',
          currency: 'USD',
        },
        unitsSold: 30,
        roasTotal: 2.320471596998928,
        acosTotal: 0.4309468822170901,
        conversionRate: 0.056925996204933584,
        clicks: 527,
        impressions: 45381,
      },
      pendingFields: [],
    },
  ],
  filteredElements: 7,
}));
