import {
  AlertIcon,
  AlertTriangleIcon,
  CheckmarkIcon,
  CycleDotIcon,
  InfoCircleIcon,
  LightbulbIcon,
  LightningIcon,
  PauseIcon,
  PlugDisconnectedIcon,
} from '@teikametrics/tm-design-system';

export enum NotificationType {
  Ads = 'Ads',
  Product = 'Product',
  MI = 'MI',
  ProductSpecific = 'ProductSpecific',
  General = 'General',
  Amazon = 'Amazon',
  Walmart = 'Walmart',
  AmazonWalmart = 'AmazonWalmart',
  Teikametrics = 'Teikametrics',
  AI = 'AI',
}

export enum NotificationStatus {
  Error = 'Error',
  Success = 'Success',
  Warning = 'Warning',
  Info = 'Info',
  Recommendation = 'Recommendation',
  Pending = 'Pending',
  Pause = 'Pause',
  AI = 'AI',
  Connect = 'Connect',
}

export interface KnockNotificationBlock {
  name: string;
  rendered: string;
  type: string;
  content: string;
  url?: string;
}

export interface KnockNotification {
  id: string;
  inserted_at: string;
  read_at: string | null;
  blocks: KnockNotificationBlock[];
}

export interface NotificationAction {
  url?: string;
  rendered: string;
}

export interface Notification {
  id: string;
  message: string;
  action?: NotificationAction;
  type: NotificationType;
  status?: NotificationStatus;
  date: string;
  isRead: boolean;
}

export const IconMap = {
  [NotificationStatus.Error]: AlertIcon,
  [NotificationStatus.Success]: CheckmarkIcon,
  [NotificationStatus.Warning]: AlertTriangleIcon,
  [NotificationStatus.Info]: InfoCircleIcon,
  [NotificationStatus.Recommendation]: LightbulbIcon,
  [NotificationStatus.Pending]: CycleDotIcon,
  [NotificationStatus.Pause]: PauseIcon,
  [NotificationStatus.AI]: LightningIcon,
  [NotificationStatus.Connect]: PlugDisconnectedIcon,
};
