import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  CheckmarkIcon,
  Icon,
  IconSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../../ConnectModalWrapper';
import classNames from 'classnames';
import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';

interface DataConnectionCompleteModalProps {
  readonly channel: FlywheelSalesChannel;
  readonly authorize: () => void;
  readonly closeModal?: () => void;
}

export const DataConnectionCompleteModal: React.FC<
  DataConnectionCompleteModalProps
> = ({ channel, authorize, closeModal }) => {
  const intl = useIntl();
  return (
    <ConnectModalWrapper
      isOpen
      headerText={intl.formatMessage({
        id: I18nKey.FLASH_MODALS_AMAZON_MODAL_HEADER,
      })}
      content={<ModalContent />}
      headerChannelType={channel}
      footer={<ModalFooter authorize={authorize} />}
      closeModal={closeModal}
    />
  );
};
DataConnectionCompleteModal.displayName = 'DataConnectionCompleteModal';

const ModalContent: React.FC = () => {
  const intl = useIntl();
  const [HEADER, SUBHEADER, DESCRIPTION, AMAZON_ADS, AMAZON_SELLING_PARTNER] = [
    I18nKey.FLASH_MODALS_AMAZON_DATA_CONNECTION_HEADER,
    I18nKey.FLASH_MODALS_AMAZON_DATA_CONNECTION_SUB_HEADER,
    I18nKey.FLASH_MODALS_AMAZON_DATA_CONNECTION_DESCRIPTION,
    I18nKey.FLASH_MODALS_AMAZON_INITIAL_CONNECTION_PRODUCTS_CARD_HEADER,
    I18nKey.FLASH_MODALS_AMAZON_INITIAL_CONNECTION_ADS_CARD_HEADER,
  ].map((id) => intl.formatMessage({ id }));
  const steps = [AMAZON_ADS, AMAZON_SELLING_PARTNER];
  return (
    <div className="text-left text-base text-grey-900 flex flex-col py-24 overflow-y-auto min-h-420 gap-16">
      <Typography
        size={TypographySize.xl}
        lineHeight={TypographyLineHeight.tight}
        weight={TypographyWeight.semibold}
      >
        {HEADER}
      </Typography>
      <div className="flex flex-col pt-16 items-start">
        {steps.map((step, index) => (
          <div
            className={classNames(
              'flex flex-row p-12 gap-32 items-center self-stretch border-1 border-solid border-grey-200',
              {
                'border-t-transparent': steps.length - 1 === index,
              }
            )}
          >
            <Typography
              size={TypographySize.base}
              lineHeight={TypographyLineHeight.normal}
              weight={TypographyWeight.regular}
              className="flex grow basis-0 shrink-0"
            >
              {step}
            </Typography>
            <div className="flex flex-row gap-4 py-4 px-8 items-center rounded-4 bg-green-100">
              <Icon
                svg={CheckmarkIcon}
                className="text-green-500"
                size={IconSize.Medium}
              />
              <Typography
                size={TypographySize.sm}
                lineHeight={TypographyLineHeight.none}
                weight={TypographyWeight.regular}
                className="text-grey-900"
              >
                <FormattedMessage id={I18nKey.COMPLETE} />
              </Typography>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col pt-16 items-start gap-16">
        <Typography
          size={TypographySize.lg}
          lineHeight={TypographyLineHeight.tight}
          weight={TypographyWeight.semibold}
        >
          {SUBHEADER}
        </Typography>
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.normal}
          weight={TypographyWeight.regular}
        >
          {DESCRIPTION}
        </Typography>
      </div>
    </div>
  );
};

const ModalFooter: React.FC<
  Omit<DataConnectionCompleteModalProps, 'channel' | 'setAmazonLwaStatus'>
> = ({ authorize }) => {
  const intl = useIntl();
  const [FINISH] = [I18nKey.FINISH].map((id) => intl.formatMessage({ id }));
  return (
    <div className="flex justify-end mx-8">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.Primary}
        label={FINISH}
        onClick={authorize}
        dataTestId={`flash1_amazon_start_connect_screen_authorize_ads`}
      />
    </div>
  );
};
