import { Factory } from 'fishery';

import { ReportingRequestParams } from '../../types/MISharedTypes';

export default Factory.define<ReportingRequestParams>(() => ({
  rop_id: '71c28ff9-c40b-40c0-a2ea-09d9accbcd3',
  search_term: 'philips',
  locale: 'US',
  platform: 'Amazon',
  start_date: '2020-01-01',
  end_date: '2020-01-31',
  includeUnIndentifiedBrands: 'no',
}));
