import { Factory } from 'fishery';

import { AddNewSearchTermsResponse } from '../../types/MISharedTypes';

export default Factory.define<AddNewSearchTermsResponse>(() => ({
  n_search_terms: 18,
  last_updated: '2021-02-15, at 03:13:37 PST',
  max_free_tracked_search_term: 20,
  max_total_tracked_search_term: 20,
}));
