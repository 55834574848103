import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { PROFILES_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = ({ merchantCountry, ...profileData }) => {
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  return makeCurrencyColumn<ProfileApiDataDetails>(
    ({ profileDetails: { getCampaignLifetimeBudget } }) =>
      getCampaignLifetimeBudget && {
        amount: Number(getCampaignLifetimeBudget.amount),
        currency:
          currencyCode || getCampaignLifetimeBudget.currency || CURRENCY_CODE,
      }
  )(profileData);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignTotalBudgetColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.TotalBudget,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_LIFETIME_BUDGET,
  RowCellElement,
  gridColumnWidth: '160px',
  className: 'text-right',
};

export const campaignTotalBudgetFilterForProfile = (currency: string) =>
  createMoneyDataFieldFilter(
    PROFILES_API_COLUMN_NAME.TotalBudget,
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_LIFETIME_BUDGET,
    currency,
    isValidNumber(0)
  );
