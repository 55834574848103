import './styles.scss';

import classNames from 'classnames';
import React from 'react';
import { BookOpenIcon } from '@teikametrics/tm-design-system';

export enum InlineMessageTheme {
  Purple = 'Purple',
  Blue = 'Blue',
  Grey = 'Grey',
  Yellow = 'Yellow',
  Orange = 'Orange',
}

export interface InlineMessageProps {
  readonly icon?: JSX.Element;
  readonly message: JSX.Element;
  readonly theme?: InlineMessageTheme;
}

export const InlineMessage: React.FC<InlineMessageProps> = ({
  icon = <BookOpenIcon />,
  message,
  theme = InlineMessageTheme.Purple,
}) => (
  <div
    className={classNames('inline-message', `theme__${theme.toLowerCase()}`)}
  >
    <div className="inline-message__icon">{icon}</div>
    <div className="inline-message__msg">{message}</div>
  </div>
);
InlineMessage.displayName = 'InlineMessage';
