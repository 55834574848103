import {
  AmazonLogomarkEnabledIcon,
  BadgeCampaignType,
  BadgeV2,
  BadgeV2Types,
  CampaignAdGroupVariant,
  KeywordRoutingType,
  Slideover,
  Source,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import { MerchantCountry } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  KeywordProps,
  SourceV2,
  TargetSegments,
  TargetingType,
} from '../../../../lib/types/KeywordAction';
import { AMAZON_SALES_CHANNEL_ID } from '../../../../lib/types/SalesChannels';
import { ViewTrendLink } from '../../../advertisingOptimization/components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../advertisingOptimization/components/ViewTrends';
import { getRecommendationSourceDisplay } from '../RecommendationsTableV2/utils';
import { getCampaignGoal } from '../RecommendationsTableV3/columns/createInAdGroup';
import { getSourcePills } from './utils';

interface OwnProps {
  readonly sources?: SourceV2[];
  readonly salesChannel: string;
  readonly targetSegments?: string[];
  readonly keyword: KeywordProps;
  readonly merchantCountry: MerchantCountry;
  readonly onClose: () => void;
  readonly isPat?: boolean;
}

export const SourcesSlideover: React.FC<OwnProps> = ({
  sources = [],
  salesChannel,
  targetSegments = [],
  keyword,
  merchantCountry,
  onClose,
  isPat,
}) => {
  const intl = useIntl();
  const { tag, text } = keyword;

  return (
    <Slideover
      isOpen
      hideFooter
      hideHeader={false}
      title={intl.formatMessage(
        {
          id: isPat
            ? I18nKey.KWA_SOURCES_PAT_SOURCES
            : I18nKey.KWA_SOURCES_KEYWORD_SOURCES,
        },
        { count: sources.length }
      )}
      onClose={onClose}
      contentClassName="w-720"
      content={
        <div className="flex flex-col justify-center">
          <div
            className={classNames(
              'flex justify-center items-center gap-8 bg-purple-50',
              'border border-solid border-purple-300 rounded h-48'
            )}
          >
            <BadgeV2
              badgeType={BadgeV2Types.KeywordRoutingTags}
              keywordRoutingTypes={tag ? [tag as KeywordRoutingType] : []}
            />
            <Typography
              size={TypographySize.base}
              weight={TypographyWeight.medium}
              lineHeight={TypographyLineHeight.tight}
            >
              {text}
            </Typography>
          </div>
          {sources.map((source) => {
            return (
              <>
                <div className="w-1/2 h-16 border-r border-solid border-grey-200"></div>
                <Source
                  salesChannelIcon={
                    salesChannel === AMAZON_SALES_CHANNEL_ID
                      ? AmazonLogomarkEnabledIcon
                      : WalmartLogomarkEnabledIcon
                  }
                  searchTermText={getRecommendationSourceDisplay(
                    intl,
                    source?.source ?? ''
                  )}
                  pills={getSourcePills(intl, source, salesChannel)}
                  sources={[
                    {
                      variant: CampaignAdGroupVariant.View,
                      campaignName: source.campaign?.name,
                      adGroupName: source.adGroup?.name,
                      campaignType:
                        source.campaign?.targetingType === TargetingType.Auto
                          ? BadgeCampaignType.Auto
                          : BadgeCampaignType.Manual,
                      campaignGoal: getCampaignGoal(
                        source?.campaign?.createdBy
                      ),
                      keywordRoutingTypes: getKeywordTargetingTypes(
                        source?.adGroup?.targetSegments
                      ),
                      rightSideElements: [
                        <div className="relative pb-24 w-36">
                          <ViewTrendLink
                            dataTestId="keyword_recommendations_sources"
                            adType={source.campaign?.campaignType}
                            campaignId={source.campaign?.id}
                            campaignName={source.campaign?.name}
                            entityId={source.adGroup?.id}
                            entityName={source.adGroup?.name}
                            entityType={ViewTrendsEntityType.ADGROUP}
                            merchantCountryId={
                              merchantCountry.merchantCountryId
                            }
                            merchantCountryName={merchantCountry?.merchantName}
                            merchantType={merchantCountry?.merchantType}
                            salesChannelId={salesChannel}
                            targetingType={source.campaign?.targetingType}
                          />
                        </div>,
                      ],
                    },
                  ]}
                />
              </>
            );
          })}
        </div>
      }
    />
  );
};

const getKeywordTargetingTypes = (
  targetSegments?: string[]
): KeywordRoutingType[] => {
  const keywordRoutingTypes: KeywordRoutingType[] = [];
  if (targetSegments?.includes(TargetSegments.Auto)) {
    keywordRoutingTypes.push(KeywordRoutingType.Auto);
  }
  if (targetSegments?.includes(TargetSegments.Brand)) {
    keywordRoutingTypes.push(KeywordRoutingType.Brand);
  }
  if (
    targetSegments?.includes(TargetSegments.Generic) ||
    isNil(targetSegments) ||
    targetSegments?.length === 0
  ) {
    keywordRoutingTypes.push(KeywordRoutingType.Generic);
  }
  if (targetSegments?.includes(TargetSegments.Competitor)) {
    keywordRoutingTypes.push(KeywordRoutingType.Competitor);
  }
  return keywordRoutingTypes;
};
