import first from 'lodash/first';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

import {
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makePercentColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  FlywheelSalesChannel,
  REQUEST_DATE_FORMAT,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import {
  NEGETIVE_TARGETS_MATCH_TYPE,
  TARGETS_API_COLUMN_NAME,
  getPerformanceMetricValue,
  isCampaignAdFormatProductCollectionOrSpotlight,
} from '../utils';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) =>
  makePercentColumn<TargetsDetails>(
    ({
      targetDetails: { startDate, matchType, campaignAdFormat },
      targetPerformance: { newToBrandSalesPercentage },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        newToBrandSalesPercentage,
        matchType && NEGETIVE_TARGETS_MATCH_TYPE.includes(matchType)
      );

      const { adTypes, salesChannel } = props;
      const adType = first(adTypes);

      if (
        adType === AdType.SponsoredBrands &&
        salesChannel === FlywheelSalesChannel.Amazon
      ) {
        return (
          (isCampaignAdFormatProductCollectionOrSpotlight(campaignAdFormat) &&
            !isNil(performanceMetricValue) &&
            performanceMetricValue?.toString()) ||
          undefined
        );
      }

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const percentageOfSalesNewToBrandColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.PercentageOfSalesNewToBrand,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_PERCENTAGE_OF_SALES_NEW_TO_BRAND,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const percentageOfSalesNewToBrandFilter = createPercentDataFieldFilter(
  TARGETS_API_COLUMN_NAME.PercentageOfSalesNewToBrand,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_PERCENTAGE_OF_SALES_NEW_TO_BRAND,
  isValidNumber()
);
