import { Factory } from 'fishery';

import { DataAvailabilityResponse } from '../../../lib/types/SKUSharedTypes';

export default Factory.define<DataAvailabilityResponse>(() => ({
  earliestAvailableDate: '',
  lastSyncedAt: '',
  latestAvailableDate: '',
  syncPerMerchantIds: [
    {
      merchantCountryId: '123',
      lastSyncedAt: '2021-08-30',
      earliestAvailableDate: '2021-06-15',
      latestAvailableDate: '2021-08-30',
    },
  ],
}));
