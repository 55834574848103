import { IntlShape } from 'react-intl';
import { AiStatisticsGraphDataPoint } from '../../../../../lib/types/CompassSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { PURPLE_600, WHITE } from '../businessGraphV2/types';
import { ChartTrendMetricFormatSelector } from '../../../types';
import { getDateForMiniMetricProp } from '../utils/business';

const MAX_POINTS_WHICH_ARE_LEGIBLE_ON_X_AXIS = 30;

export const getXAxisLabels = (
  intl: IntlShape,
  dataPoints: AiStatisticsGraphDataPoint[],
  formatSelector: ChartTrendMetricFormatSelector
) => {
  const pointsToSkip = Math.ceil(
    dataPoints.length / MAX_POINTS_WHICH_ARE_LEGIBLE_ON_X_AXIS
  );
  let xAxisLabels: string[] = [];
  dataPoints.forEach((point) =>
    xAxisLabels.push(getDateForMiniMetricProp(intl, point.date, formatSelector))
  );

  return xAxisLabels.map((label, index) =>
    index % pointsToSkip === 0 ? label : ''
  );
};

export const getChartMetricsData = (
  intl: IntlShape,
  dataPoints: AiStatisticsGraphDataPoint[],
  formatSelector: ChartTrendMetricFormatSelector
) => {
  return [
    {
      isActive: true,
      header: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_AI_STATISTICS_VALUE_TOOLTIP_BID_CHANGES,
      }),
      className: 'bg-purple-600',
      data: [
        {
          label: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD,
          }),
          dataTestId: 'ai_bids_current_period',
          data: {
            color: PURPLE_600,
            data: dataPoints.map((pointData) => ({
              y: pointData.bidChanges,
              custom: {
                chartDataPeriod: 'current',
                customDate: getDateForMiniMetricProp(
                  intl,
                  pointData.date,
                  formatSelector
                ),
                name: intl.formatMessage({
                  id: I18nKey.COMPASS_DASHBOARD_AI_STATISTICS_VALUE_TOOLTIP_BID_CHANGES,
                }),
                total: intl.formatNumber(pointData.bidChanges),
              },
            })),
            marker: {
              symbol: 'circle',
            },
            name: intl.formatMessage({
              id: I18nKey.COMPASS_DASHBOARD_AI_STATISTICS_VALUE_TOOLTIP_BID_CHANGES,
            }),
            type: 'spline',
            visible: true,
            yAxis: 0,
            zIndex: 1,
          },
        },
      ],
    },
    {
      isActive: true,
      header: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_AI_STATISTICS_VALUE_TOOLTIP_AD_GROUPS_ENABLED,
      }),
      className: 'bg-white',
      data: [
        {
          label: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD,
          }),
          dataTestId: 'ad_groups_enabled_current_period',
          data: {
            color: WHITE,
            data: dataPoints.map((pointData) => ({
              y: pointData.enabledAdsGroupsCount,
              custom: {
                chartDataPeriod: 'current',
                customDate: getDateForMiniMetricProp(
                  intl,
                  pointData.date,
                  formatSelector
                ),
                name: intl.formatMessage({
                  id: I18nKey.COMPASS_DASHBOARD_AI_STATISTICS_VALUE_TOOLTIP_AD_GROUPS_ENABLED,
                }),
                total: intl.formatNumber(pointData.enabledAdsGroupsCount),
              },
            })),
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false,
                },
              },
            },
            name: intl.formatMessage({
              id: I18nKey.COMPASS_DASHBOARD_AI_STATISTICS_VALUE_TOOLTIP_AD_GROUPS_ENABLED,
            }),
            type: 'spline',
            visible: true,
            yAxis: 1,
            // This shouldn't be visible if the path's cross of 2 labels hence giving zIndex of 0
            zIndex: 0,
          },
        },
      ],
    },
  ];
};

export const getMaxYAxisValue = (dataPoints: AiStatisticsGraphDataPoint[]) =>
  dataPoints.reduce((accumulator, point) => {
    return Math.max(accumulator, point.bidChanges);
  }, 0);

export const calculateXAxisLabelTilt = (labels: string[] | number[]) =>
  labels.length > 6 ? -45 : 0;
