import capitalize from 'lodash/capitalize';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { CampaignDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CAMPAIGNS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<CampaignDetails> = (props) => {
  return makeTextColumn(
    ({ campaignDetails: { targetingType } }: CampaignDetails) =>
      targetingType ? capitalize(targetingType) : undefined
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignTargetingTypeColumn: FlywheelTableColumn<CampaignDetails> =
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.CampaignTargetingType,
    i18nKeyOrLabel:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE,
    isSortable: true,
    RowCellElement,
    gridColumnWidth: '140px',
  };

export const campaignTargetingTypeFilter = createMultiSelectDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.CampaignTargetingType,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE,
  [
    SelectFilterOption.CampaignTargetingTypeAuto,
    SelectFilterOption.CampaignTargetingTypeManual,
  ]
);
