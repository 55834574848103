import {
  Icon,
  IconSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import I18nKey from '../../lib/types/I18nKey';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ArrowRightGradientIcon } from '../../img/arrow-right-gradient.svg';
export interface SidebarProps {
  readonly isCommunity: boolean;
}

const COMMUNITY = [
  I18nKey.COMMUNITY_BLOCK_ONE,
  I18nKey.COMMUNITY_BLOCK_TWO,
  I18nKey.COMMUNITY_BLOCK_THREE,
  I18nKey.COMMUNITY_BLOCK_FOUR,
  I18nKey.COMMUNITY_BLOCK_FIVE,
];

const NON_COMMUNITY = [
  I18nKey.NON_COMMUNITY_BLOCK_ONE,
  I18nKey.NON_COMMUNITY_BLOCK_TWO,
  I18nKey.NON_COMMUNITY_BLOCK_THREE,
  I18nKey.NON_COMMUNITY_BLOCK_FOUR,
  I18nKey.NON_COMMUNITY_BLOCK_FIVE,
];

export const Sidebar: React.FC<SidebarProps> = ({ isCommunity }) => {
  return (
    <div
      className={classNames(
        'rounded-r-4 w-300 h-480 shrink-0 pt-48 pb-32 px-24 gap-24',
        {
          'bg-community-modal': isCommunity,
          'bg-non-community-modal': !isCommunity,
        }
      )}
    >
      <div className="flex flex-col gap-24">
        <Typography
          size={TypographySize.lg}
          lineHeight={TypographyLineHeight.tight}
          weight={TypographyWeight.medium}
          className="text-white"
        >
          <FormattedMessage id={I18nKey.SIDEBAR_TITLE} />
        </Typography>
        <div className="flex flex-col gap-16">
          {(isCommunity ? COMMUNITY : NON_COMMUNITY).map((item) => (
            <div className="flex flex-row items-center gap-16">
              <Icon size={IconSize.Small} svg={ArrowRightGradientIcon} />
              <Typography
                size={TypographySize.base}
                lineHeight={TypographyLineHeight.tight}
                weight={TypographyWeight.regular}
                className="text-white"
              >
                <FormattedMessage id={item} />
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Sidebar.displayName = 'Sidebar';
