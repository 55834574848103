import { Factory } from 'fishery';

import {
  MISearchTermsPaginatedResult,
  SearchTermStatus,
} from '../../types/MISharedTypes';

export default Factory.define<MISearchTermsPaginatedResult>(() => ({
  freeSearchTermsLimit: 20,
  isSearchTermNumberUnlimited: false,
  items: [
    {
      created_at: '2021-02-04, at 22:58:52 PST',
      locale: 'US',
      platform: 'Amazon',
      search_term: 'prestiges',
      status: SearchTermStatus.Active,
      group_id: 'UNGROUPED',
      is_group: false,
      is_grouped_term: false,
      search_term_id: '85d2662f-ced7-5f16-b2ba-71abfabdac6d',
    },
    {
      created_at: '2021-02-04, at 21:44:45 PST',
      locale: 'US',
      platform: 'Amazon',
      search_term: 'temper',
      status: SearchTermStatus.Fetching,
      group_id: 'UNGROUPED',
      is_group: false,
      is_grouped_term: false,
      search_term_id: '344c3af2-7009-55ee-a3a5-8b53a42b78f4',
    },
  ],
  lastUpdated: '2021-02-10, at 02:51:01 PST',
  searchTermsLimit: 0,
  totalItems: 0,
  totalSearchTerms: 2,
  totalGroups: 0,
  filteredSearchTermsAndGroups: 0,
}));
