import { getName } from 'country-list';

import {
  ColumnAlign,
  IconSize,
  TableCellV2,
  TableCellV2Type,
} from '@teikametrics/tm-design-system';

import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import { SalesChannelData } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { RowCellElementProps, SalesChannelTableData } from '../types';
import { getCountryFlagIcon } from '../../../../../lib/utilities/countryFlags';

export const countryColumn: FlywheelTableV2ColumnProps<
  SalesChannelData,
  {},
  SalesChannelTableData,
  {}
> = {
  header: I18nKey.MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_LOCALE,
  cell: ({ row: { original } }) => <CountryCellElement {...original} />,
  accessorKey: 'country',
  sortable: true,
  align: ColumnAlign.Left,
};

export const CountryCellElement: React.FC<RowCellElementProps> = ({
  country,
}) => {
  const value = country;
  if (!value) {
    return null;
  }

  return (
    <TableCellV2
      tableCellData={{
        currentValue: getName(value),
        tableCellType: TableCellV2Type.IconWithText,
        icon: {
          svg: getCountryFlagIcon(value),
          size: IconSize.Medium,
        },
      }}
      className="p-12"
    />
  );
};
