import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import { DateTime } from 'luxon';
import React from 'react';
import { FlywheelTableColumn } from '../../../../../containers/table/flywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { CAMPAIGNS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) => {
  return makeNumericColumn<CampaignDetails>(
    ({
      campaignPerformance: { videoCompleteViews },
      channelSettings: { startDate },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        videoCompleteViews
      );

      return performanceMetricValue?.toString();
    }
  )(props);
};

RowCellElement.displayName = 'videoCompeteViewsColumn';

export const videoCompleteViews: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.VideoCompleteViews,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_VIDEO_COMPLETE_VIEWS,
  RowCellElement,
  gridColumnWidth: '160px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
  isSortable: true,
};

export const videoCompleteViewsFilter = createNumericDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.VideoCompleteViews,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_VIDEO_COMPLETE_VIEWS,
  isValidNumber(0)
);
