import { DataSyncInfoContextState } from '../../containers/dataSyncInfoProvider/dataSyncInfoProvider';
import { AccountSalesChannelPaginatedResultWithError } from '../../modules/products/containers/skuCatalog/types';
import isEmpty from 'lodash/isEmpty';
import { getAdsProductConnectionData } from '../EmptyState/hooks';

export enum EmptyBannerStatus {
  NoData = 'NoData',
  AdsOnlyAmazonSyncing = 'AdsOnlyAmazonSyncing',
  AdsOnlyWalmartSyncing = 'AdsOnlyWalmartSyncing',
  AmazonDataSyncing = 'AmazonDataSyncing',
  AmazonAdsNoProducts = 'AmazonAdsNoProducts',
  WalmartAdsNoProducts = 'WalmartAdsNoProducts',
  GenericDataSyncing = 'GenericDataSyncing',
  None = 'None',
}

export const getAdsProductConnectionStatus = ({
  dataSyncInfo,
  salesChannelData,
}: {
  dataSyncInfo: DataSyncInfoContextState;
  salesChannelData: AccountSalesChannelPaginatedResultWithError[];
}) => {
  const {
    amazonData,
    walmartData,
    amazonAdsConnected,
    amazonAdsSyncing,
    walmartAdsSyncing,
    walmartAdsConnected,
    amazonProductsSyncing,
    walmartProductsSyncing,
    amazonProductConnected,
    walmartProductConnected,
  } = getAdsProductConnectionData({ dataSyncInfo, salesChannelData });

  return {
    isNoData:
      amazonData?.total === 0 &&
      walmartData?.total === 0 &&
      dataSyncInfo.isDataSyncInfoProviderLoaded,
    isAmazonAdsSyncing: !isEmpty(amazonAdsSyncing),
    isAmazonProductsSyncing: !isEmpty(amazonProductsSyncing),
    isAmazonAdsConnected: !isEmpty(amazonAdsConnected),
    isAmazonProductConnected: !isEmpty(amazonProductConnected),
    isWalmartAdsSyncing: !isEmpty(walmartAdsSyncing),
    isWalmartProductsSyncing: !isEmpty(walmartProductsSyncing),
    isWalmartAdsConnected: !isEmpty(walmartAdsConnected),
    isWalmartProductConnected: !isEmpty(walmartProductConnected),
    isProductSyncing:
      !isEmpty(amazonProductsSyncing) || !isEmpty(walmartProductsSyncing),
    isProductConnected:
      !isEmpty(amazonProductConnected) || !isEmpty(walmartProductConnected),
    isAmazonConnected:
      !isEmpty(amazonAdsConnected) && !isEmpty(amazonProductConnected),
    isWalmartConnected:
      !isEmpty(walmartAdsConnected) && !isEmpty(walmartProductConnected),
  };
};

export const getEmptyBannerStatus = ({
  dataSyncInfo,
  salesChannelData,
}: {
  dataSyncInfo: DataSyncInfoContextState;
  salesChannelData: AccountSalesChannelPaginatedResultWithError[];
}) => {
  const {
    isNoData,
    isAmazonAdsConnected,
    isAmazonAdsSyncing,
    isAmazonProductsSyncing,
    isWalmartAdsConnected,
    isWalmartAdsSyncing,
    isAmazonConnected,
    isWalmartConnected,
    isProductSyncing,
    isProductConnected,
  } = getAdsProductConnectionStatus({ dataSyncInfo, salesChannelData });

  if (isAmazonConnected || isWalmartConnected) {
    return EmptyBannerStatus.None;
  }

  if (isAmazonAdsSyncing && isAmazonProductsSyncing) {
    return EmptyBannerStatus.AmazonDataSyncing;
  }

  if (!isProductSyncing && !isProductConnected) {
    if (isAmazonAdsConnected) {
      return EmptyBannerStatus.AmazonAdsNoProducts;
    }

    if (isWalmartAdsConnected) {
      return EmptyBannerStatus.WalmartAdsNoProducts;
    }

    if (isAmazonAdsSyncing) {
      return EmptyBannerStatus.AdsOnlyAmazonSyncing;
    }

    if (isWalmartAdsSyncing) {
      return EmptyBannerStatus.AdsOnlyWalmartSyncing;
    }
  }

  if (isNoData) {
    return EmptyBannerStatus.NoData;
  }

  return EmptyBannerStatus.GenericDataSyncing;
};
