import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Theme,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  BudgetRollover,
  CampaignDetails,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import { CAMPAIGNS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { getCurrentRowIndex } from './utils';
import { useMemo } from 'react';

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, salesChannel, selectedAdType, ...campaignDetails }) => {
  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.BudgetRollover
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const dispatch = useDispatch();

  const intl = useIntl();

  const currentRowIndex = useSelector<WithTable<CampaignDetails>, number>(
    getCurrentRowIndex(campaignDetails)
  );

  const isSelectedSalesChannelWalmart =
    salesChannel === FlywheelSalesChannel.Walmart;

  const getTooltipContent = () => {
    return {
      content: intl.formatMessage({
        id: I18nKey.ADVERTISING_OPTIMIZATION_WALMART_BUDGET_ROLLOVER_FALSE_VALUE_TOOLTIP,
      }),
      theme: Theme.Dark,
      overwrittenTooltipClassnames: 'absolute w-200 -left-15 z-30',
    };
  };

  const isEditableColumn = useMemo(
    () => selectedAdType !== AdType.SponsoredVideos,
    [selectedAdType]
  );

  if (isEditMode && isEditableColumn) {
    return makeSelectColumn<CampaignDetails>(
      ({ channelSettings: { budgetRollover } }) => {
        const value = isNil(changedValue)
          ? String(budgetRollover)
          : changedValue;

        return {
          className: 'w-88',
          dataTestId: CAMPAIGNS_API_COLUMN_NAME.BudgetRollover,
          options: [
            {
              value: BudgetRollover.True,
              label: intl.formatMessage({
                id: I18nKey.GENERAL_BOOLEAN_TRUE,
              }),
            },
            {
              value: BudgetRollover.False,
              label: intl.formatMessage({
                id: I18nKey.GENERAL_BOOLEAN_FALSE,
              }),
              disabled: isSelectedSalesChannelWalmart, // should be disabled if selected sales channel is walmart
              tooltip: isSelectedSalesChannelWalmart
                ? getTooltipContent()
                : undefined,
            },
          ],
          placeholder: '',
          onChange: (inputValue: string) => {
            dispatch(
              tableActions.updateCell({
                tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
                columnName: CAMPAIGNS_API_COLUMN_NAME.BudgetRollover,
                existingValue: String(budgetRollover),
                rowId: campaignDetails.campaignId,
                value: inputValue,
              })
            );
          },
          value,
          isDirty:
            value !==
            (budgetRollover ? BudgetRollover.True : BudgetRollover.False),
          showOnTop: currentRowIndex > 6,
        };
      }
    )(campaignDetails);
  }

  return makeTextColumn<CampaignDetails>(
    ({ channelSettings: { budgetRollover } }) =>
      isNil(budgetRollover) ? undefined : capitalize(budgetRollover.toString()),
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.BudgetRollover
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const budgetRolloverColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.BudgetRollover,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_ROLLOVER,
  RowCellElement,
  gridColumnWidth: '140px',
};

export const budgetRolloverFilter = createMultiSelectDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.BudgetRollover,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_ROLLOVER,
  [
    SelectFilterOption.BudgetRolloverTrue,
    SelectFilterOption.BudgetRolloverFalse,
  ]
);
