import { DateTime } from 'luxon';

import {
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makePercentColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { CAMPAIGNS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) =>
  makePercentColumn<CampaignDetails>(
    ({
      campaignPerformance: { acosDirect },
      channelSettings: { startDate },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        acosDirect
      );

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const acosDirectColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.DirectACOS,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ACOS_DIRECT,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const acosDirectFilter = createPercentDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.DirectACOS,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ACOS_DIRECT,
  isValidNumber()
);
