import {
  Alignment,
  BadgeV2Types,
  Button,
  ButtonSize,
  ButtonVariant,
  Checkbox,
  CheckboxCheckedState,
  CheckboxState,
  Icon,
  IconProps,
  KebabLinearIcon,
  KeywordRoutingTagBadge,
  KeywordRoutingType,
  Placement,
  RadioButton,
  Tooltip,
  TooltipSize,
  TruncatedText,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import size from 'lodash/size';
import React, { PropsWithChildren } from 'react';

export enum AdgroupActionType {
  Radio,
  Checkbox,
  TooltipIcon,
  None,
}

export interface SuggestedAdgroupProps extends PropsWithChildren {
  readonly adGroupName?: string | React.ReactNode;
  readonly targetSegments: any[];
  readonly tooltipContent?: string | JSX.Element;
  readonly action?: AdgroupActionType;
  readonly showBorder?: boolean;
  readonly isSelected?: boolean;
  readonly onClick?: () => void;
  readonly onMenuClick?: () => void;
  readonly dataTestId?: string;
  readonly classname?: string;
  readonly disabled?: boolean;
  readonly iconProps?: IconProps;
  readonly iconTooltip?: string | JSX.Element;
  readonly iconStateDisabled?: boolean;
}

export const SuggestedAdgroup: React.FC<SuggestedAdgroupProps> = ({
  adGroupName,
  targetSegments,
  tooltipContent,
  onMenuClick,
  classname = 'w-480',
  dataTestId,
  action = AdgroupActionType.None,
  showBorder,
  isSelected = false,
  onClick,
  disabled = false,
  iconProps,
  iconTooltip,
  iconStateDisabled,
  children,
}) => {
  const handleOnClick = (event: any) => {
    if (event.target.type === 'radio' || event.target.type === 'checkbox') {
      return;
    }
    onClick?.();
  };

  return (
    <div
      data-test-id={`${dataTestId}_suggestedAdgroup`}
      onClick={handleOnClick}
      className={classNames(
        'ml-8 items-start gap-8 flex flex-row',
        {
          'border border-solid rounded p-8 border-grey-300': showBorder,
          'hover:border-grey-500 focus:border-purple-500':
            showBorder && !disabled && !isSelected,
          'border-purple-500': showBorder && isSelected,
          'cursor-pointer': !!onClick,
          'text-grey-900': !disabled,
          'text-grey-400': disabled,
        },
        classname
      )}
    >
      {action === AdgroupActionType.Radio && (
        <RadioButton
          dataTestId={dataTestId}
          selected={isSelected}
          disabled={disabled}
          onChange={handleOnClick}
          className="items-start"
        />
      )}
      {action === AdgroupActionType.Checkbox && (
        <Checkbox
          dataTestId={dataTestId}
          state={disabled ? CheckboxState.Disabled : CheckboxState.Default}
          checked={
            isSelected
              ? CheckboxCheckedState.Checked
              : CheckboxCheckedState.Unchecked
          }
          onClick={handleOnClick}
          className="items-start"
        />
      )}
      {action === AdgroupActionType.TooltipIcon && iconProps && (
        <Tooltip
          content={iconTooltip}
          position={{
            placement: Placement.Right,
            alignment: Alignment.Center,
            placementOffsetInPixels: 10,
          }}
          delayShow={500}
          delayHide={500}
          tooltipContentHoverable
          overwrittenTooltipClassnames="px-4 py-4"
        >
          <span
            className={classNames(
              'w-20 h-20 rounded-full flex justify-center items-center',
              {
                'bg-rainbow-gradient hover:ring-4 ring-purple-200':
                  !iconStateDisabled,
                'bg-grey-200 hover:ring-4 ring-grey-300': iconStateDisabled,
              }
            )}
          >
            <Icon
              {...iconProps}
              className={classNames(
                {
                  'text-white': !iconStateDisabled,
                  'text-grey-400': iconStateDisabled,
                },
                iconProps.className
              )}
            />
          </span>
        </Tooltip>
      )}

      <div className="flex flex-1 gap-8 min-w-0">
        {size(targetSegments) === 0 ? (
          <KeywordRoutingTagBadge
            badgeType={BadgeV2Types.KeywordRoutingTags}
            classes="mr-8"
            keywordRoutingTypes={[KeywordRoutingType.Auto]}
          />
        ) : (
          <KeywordRoutingTagBadge
            badgeType={BadgeV2Types.KeywordRoutingTags}
            classes="mr-8"
            keywordRoutingTypes={
              targetSegments as unknown as KeywordRoutingType[]
            }
          />
        )}

        <TruncatedText
          fontClassNames={classNames('text-base leading-tight font-normal', {
            'font-medium': isSelected,
            'font-normal': !isSelected,
          })}
          text={adGroupName as string}
        />
        {(targetSegments?.length > 0 || !!children || !!onMenuClick) && (
          <>
            <div className="flex flex-auto justify-between items-center leading-none">
              {onMenuClick && (
                <Tooltip
                  content={tooltipContent}
                  tooltipSize={TooltipSize.Small}
                  hideArrow
                  position={{
                    placement: Placement.Bottom,
                    alignment: Alignment.Right,
                  }}
                  delayShow={500}
                >
                  <Button
                    label=""
                    svgIcon={KebabLinearIcon}
                    size={ButtonSize.InlineIcon}
                    variant={ButtonVariant.BlackAndWhite}
                    onClick={onMenuClick}
                  />
                </Tooltip>
              )}
              {children}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
