import {
  Avatar,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../lib/types/I18nKey';
import { FormattedMessage } from 'react-intl';
import { MAP_SALES_CHANNEL_ID_TO_NAME } from '../../lib/types/SalesChannels';
import { AccountLeadAnalyst } from '../../lib/types/Fam';
import classNames from 'classnames';

export interface AnalystProfileProps {
  readonly analyst: AccountLeadAnalyst;
  readonly count: number;
}
export const AnalystProfile: React.FC<AnalystProfileProps> = ({
  analyst,
  count,
}) => {
  return (
    <div className="flex flex-col items-center justify-start gap-16">
      <Typography
        size={TypographySize.sm}
        lineHeight={TypographyLineHeight.none}
        weight={TypographyWeight.regular}
        className="uppercase text-grey-500"
      >
        <FormattedMessage
          id={
            count === 1
              ? I18nKey.MANAGED_EDITION_YOUR_ANALYST
              : I18nKey.MANAGED_EDITION_YOUR_CHANNEL_ANALYST
          }
          values={{
            channel: MAP_SALES_CHANNEL_ID_TO_NAME[analyst.salesChannelId],
          }}
        />
      </Typography>
      <div key={analyst.userId} className="flex flex-col items-center gap-8">
        <Avatar firstName={analyst.firstName} lastName={analyst.lastName} />
        <Typography
          size={TypographySize.xl}
          lineHeight={TypographyLineHeight.none}
          weight={TypographyWeight.regular}
          className="text-white pt-8"
        >
          {analyst.firstName} {analyst.lastName}
        </Typography>
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.none}
          weight={TypographyWeight.regular}
          className="text-white"
        >
          <a
            className={classNames(
              'border-b border-solid cursor-pointer border-grey-200'
            )}
            href={'mailto:' + analyst.email}
            target={'_blank'}
            rel="noreferrer"
          >
            {analyst.email}
          </a>
        </Typography>
      </div>
    </div>
  );
};
AnalystProfile.displayName = 'AnalystProfile';
