import { Factory } from 'fishery';
import { AmcTemplatesResponse } from '../../types/AmcSharedTypes';

export default Factory.define<AmcTemplatesResponse>(() => ({
  workflowTemplates: [
    {
      lookerTemplateId: null,
      createdAt: '2024-06-13T16:38:11.172938',
      workflowTypeName: 'Audience Overlap Analysis',
      workflowTypeDescription: 'Audience Overlap Analysis',
      updatedAt: null,
      customWorkflowCount: 0,
      workflowTypeId: 'audience_overlap_analysis',
      inputParameters: [
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'DSP Campaign IDs',
          columnType: 'DIMENSION',
          sqlName: 'dsp_campaigns',
        },
        {
          isMandatory: true,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Targeted segment ID/ Name',
          columnType: 'DIMENSION',
          sqlName: 'targeted_segment',
        },
      ],
    },
    {
      lookerTemplateId: null,
      createdAt: '2024-06-13T16:38:11.172938',
      customWorkflowCount: 1,
      workflowTypeName: 'ASIN Purchase Overlap for Upselling',
      workflowTypeDescription: 'ASIN Purchase Overlap for Upselling',
      updatedAt: null,
      workflowTypeId: 'asin_purchase_overlap_upsell',
      inputParameters: [
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Campaign IDs',
          columnType: 'DIMENSION',
          sqlName: 'campaign_id',
        },
      ],
    },
    {
      lookerTemplateId: null,
      createdAt: '2024-06-13T16:38:11.172938',
      customWorkflowCount: 0,
      workflowTypeName: 'New-to-brand gateway ASINs',
      workflowTypeDescription: 'New-to-brand gateway ASINs',
      updatedAt: null,
      workflowTypeId: 'ntb_gateway_asins',
      inputParameters: [
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Campaign IDs',
          columnType: 'DIMENSION',
          sqlName: 'campaign_id',
        },
      ],
    },
    {
      lookerTemplateId: null,
      createdAt: '2024-06-13T16:38:11.172938',
      customWorkflowCount: 1,
      workflowTypeName: 'DSP Impression Frequency and Conversions',
      workflowTypeDescription: 'DSP Impression Frequency and Conversions',
      updatedAt: null,
      workflowTypeId: 'dsp_impression_frequency_conversions',
      inputParameters: [
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'DSP Campaign IDs',
          columnType: 'DIMENSION',
          sqlName: 'dsp_campaigns',
        },
      ],
    },
    {
      lookerTemplateId: null,
      createdAt: '2024-06-18T06:23:01.419844',
      customWorkflowCount: 0,
      workflowTypeName: 'Path to Conversion by Campaign Groups',
      workflowTypeDescription: 'Path to Conversion by Campaign Groups',
      updatedAt: null,
      workflowTypeId: 'path_to_conversion_campaign_groups',
      inputParameters: [
        {
          isMandatory: true,
          isArray: false,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 1 name',
          columnType: 'DIMENSION',
          sqlName: 'group1name',
        },
        {
          isMandatory: true,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 1 campaigns',
          columnType: 'DIMENSION',
          sqlName: 'group1',
        },
        {
          isMandatory: true,
          isArray: false,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 2 name',
          columnType: 'DIMENSION',
          sqlName: 'group2name',
        },
        {
          isMandatory: true,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 2 campaigns',
          columnType: 'DIMENSION',
          sqlName: 'group2',
        },
        {
          isMandatory: false,
          isArray: false,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 3 name',
          columnType: 'DIMENSION',
          sqlName: 'group3name',
        },
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 3 campaigns',
          columnType: 'DIMENSION',
          sqlName: 'group3',
        },
        {
          isMandatory: false,
          isArray: false,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 4 name',
          columnType: 'DIMENSION',
          sqlName: 'group4name',
        },
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 4 campaigns',
          columnType: 'DIMENSION',
          sqlName: 'group4',
        },
        {
          isMandatory: false,
          isArray: false,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 5 name',
          columnType: 'DIMENSION',
          sqlName: 'group5name',
        },
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 5 campaigns',
          columnType: 'DIMENSION',
          sqlName: 'group5',
        },
      ],
    },
    {
      lookerTemplateId: null,
      createdAt: '2024-06-18T06:23:01.419844',
      customWorkflowCount: 0,
      workflowTypeName: 'Sponsored Ads and DSP Overlap',
      workflowTypeDescription: 'Sponsored Ads and DSP Overlap',
      updatedAt: null,
      workflowTypeId: 'sponsored_ads_dsp_overlap',
      inputParameters: [
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'DSP Campaign IDs',
          columnType: 'DIMENSION',
          sqlName: 'dsp_campaigns',
        },
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Sponsored ads campaign IDs',
          columnType: 'DIMENSION',
          sqlName: 'sa_campaigns',
        },
      ],
    },
    {
      lookerTemplateId: null,
      createdAt: '2024-06-18T06:23:01.419844',
      customWorkflowCount: 0,
      workflowTypeName: 'Overlap by Campaign Groups',
      workflowTypeDescription: 'Overlap by Campaign Groups',
      updatedAt: null,
      workflowTypeId: 'overlap_by_campaign_groups',
      inputParameters: [
        {
          isMandatory: true,
          isArray: false,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 1 name',
          columnType: 'DIMENSION',
          sqlName: 'group1name',
        },
        {
          isMandatory: true,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 1 campaigns',
          columnType: 'DIMENSION',
          sqlName: 'group1',
        },
        {
          isMandatory: true,
          isArray: false,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 2 name',
          columnType: 'DIMENSION',
          sqlName: 'group2name',
        },
        {
          isMandatory: true,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 2 campaigns',
          columnType: 'DIMENSION',
          sqlName: 'group2',
        },
        {
          isMandatory: false,
          isArray: false,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 3 name',
          columnType: 'DIMENSION',
          sqlName: 'group3name',
        },
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 3 campaigns',
          columnType: 'DIMENSION',
          sqlName: 'group3',
        },
        {
          isMandatory: false,
          isArray: false,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 4 name',
          columnType: 'DIMENSION',
          sqlName: 'group4name',
        },
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 4 campaigns',
          columnType: 'DIMENSION',
          sqlName: 'group4',
        },
        {
          isMandatory: false,
          isArray: false,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 5 name',
          columnType: 'DIMENSION',
          sqlName: 'group5name',
        },
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Group 5 campaigns',
          columnType: 'DIMENSION',
          sqlName: 'group5',
        },
      ],
    },
    {
      lookerTemplateId: null,
      createdAt: '2024-06-18T06:23:01.419844',
      customWorkflowCount: 0,
      workflowTypeName: 'DSP Path to Conversion by Device',
      workflowTypeDescription: 'DSP Path to Conversion by Device',
      updatedAt: null,
      workflowTypeId: 'dsp_path_to_conversion_device',
      inputParameters: [
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'DSP Campaign IDs',
          columnType: 'DIMENSION',
          sqlName: 'dsp_campaigns',
        },
      ],
    },
    {
      lookerTemplateId: null,
      createdAt: '2024-06-18T06:23:01.419844',
      customWorkflowCount: 0,
      workflowTypeName: 'Reach and Impression Frequency',
      workflowTypeDescription: 'Reach and Impression Frequency',
      updatedAt: null,
      workflowTypeId: 'reach_impression_frequency',
      inputParameters: [
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'DSP Campaign IDs',
          columnType: 'DIMENSION',
          sqlName: 'dsp_campaigns',
        },
        {
          isMandatory: false,
          isArray: true,
          description: null,
          dataType: 'STRING',
          displayName: 'Sponsored ads campaign IDs',
          columnType: 'DIMENSION',
          sqlName: 'sa_campaigns',
        },
      ],
    },
  ],
}));
