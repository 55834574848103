import isNil from 'lodash/isNil';
import { SourceV2, TargetingType } from '../../../../lib/types/KeywordAction';
import { AMAZON_SALES_CHANNEL_ID } from '../../../../lib/types/SalesChannels';
import { IntlShape } from 'react-intl';
import { PaletteColor } from '@teikametrics/tm-design-system';
import I18nKey from '../../../../lib/types/I18nKey';

export const getSourcePills = (
  intl: IntlShape,
  source: SourceV2,
  salesChannel: string
) => {
  return source.campaign?.targetingType === TargetingType.Auto &&
    isNil(source.keyword)
    ? salesChannel === AMAZON_SALES_CHANNEL_ID
      ? [
          {
            text: intl.formatMessage({
              id: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SUBSTITUTE_RELATED,
            }),
            color: PaletteColor.purple,
            isBorderRounded: false,
            isHoverable: false,
            className: 'border border-solid border-purple-200',
            textClassName: 'text-grey-900',
          },
        ]
      : []
    : [
        {
          text: source.keyword?.text,
          color: PaletteColor.purple,
          isBorderRounded: false,
          isHoverable: false,
          className: 'border border-solid border-purple-200',
          textClassName: 'text-grey-900',
        },
        {
          text: source.keyword?.matchType,
          color: PaletteColor.white,
          isBorderRounded: false,
          isHoverable: false,
          className: 'bg-grey-50 border border-solid border-grey-200',
        },
      ];
};
