import {
  Badge,
  BadgeDisplayMode,
  BadgeType,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { CampaignTargetingType } from '../../../lib/types/AOSharedTypes';
import Skeleton from 'react-loading-skeleton';
import {
  CampaignAdGroupAdvertisingStatus,
  CampaignsAdGroupsSelectOptionProps,
} from '.';

export const getCampaignStatusMarkerColor = (status?: string) => {
  switch (status) {
    case CampaignAdGroupAdvertisingStatus.Enabled:
      return 'bg-green-500';
    case CampaignAdGroupAdvertisingStatus.Partial:
      return 'bg-yellow-500';
    case CampaignAdGroupAdvertisingStatus.Paused:
      return 'bg-red-500';
    case CampaignAdGroupAdvertisingStatus.NotEligible:
    default:
      return 'bg-grey-500';
  }
};

export const CampaignsDisplayLabel: React.FC<
  CampaignsAdGroupsSelectOptionProps & {
    readonly onClick?: () => void;
  }
> = (props) => {
  return (
    <>
      <div className="flex gap-8 items-center py-16" onClick={props.onClick}>
        <Badge
          badgeType={
            props.targetingType === CampaignTargetingType.Manual
              ? BadgeType.CampaignTypeManual
              : BadgeType.CampaignTypeAuto
          }
          badgeNameDisplayMode={BadgeDisplayMode.OnlyInitial}
        />
        <Typography
          lineHeight={TypographyLineHeight.none}
          size={TypographySize.lg}
          weight={TypographyWeight.semibold}
        >
          {props.campaignName}
        </Typography>
      </div>
      <div
        className="flex h-full self-stretch items-center py-12 gap-8 w-152"
        onClick={props.onClick}
      >
        <div
          className={classNames(
            'py-4 px-4 rounded-full inline-block',
            getCampaignStatusMarkerColor(props.status)
          )}
        ></div>
        <label className="text-base leading-tight text-grey-900 font-normal whitespace-nowrap capitalize">
          {props.statusString}
        </label>
      </div>
    </>
  );
};

export const CampaignsLoadingSkeleton: React.FC = () => {
  return (
    <div className="flex py-24 gap-40">
      <div className="flex w-full gap-8">
        <Skeleton width={24} height={20} />
        <Skeleton width={640} height={20} />
      </div>
      <div className="flex">
        <Skeleton width={88} height={20} />
      </div>
    </div>
  );
};

CampaignsDisplayLabel.displayName = 'CampaignsDisplayLabel';
