import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import ReactModal from 'react-modal';

import {
  AlertTriangleIcon,
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../lib/types/I18nKey';

interface MerchantConnectionSavedWarningPopupProps {
  readonly onCancel: () => void;
  readonly onSubmit: () => void;
  readonly isOpen: boolean;
}

export const MerchantConnectionSavedWarningPopup: React.FC<
  MerchantConnectionSavedWarningPopupProps
> = (props) => {
  const intl = useIntl();

  const headerLabel = intl.formatMessage({
    id: I18nKey.SETUP_FLYWHEEL_MISSING_MERCHANT_CONNECTIONS_WARNING_HEADER,
  });
  const contentLabel = intl.formatMessage({
    id: I18nKey.SETUP_FLYWHEEL_MISSING_MERCHANT_CONNECTIONS_WARNING_CONTENT,
  });
  const cancelBtnLabel = intl.formatMessage({
    id: I18nKey.GENERIC_CANCEL_LABEL,
  });
  const submitBtnLabel = intl.formatMessage({
    id: I18nKey.SETUP_FLYWHEEL_MISSING_MERCHANT_CONNECTIONS_WARNING_SUBMIT_TEXT,
  });

  return (
    <ReactModal
      isOpen={props.isOpen}
      className={{
        base: classNames(
          'flex flex-col absolute',
          'bg-white outline-none rounded',
          'items-stretch text-center overflow-hidden p-32 w-448'
        ),
        afterOpen: '',
        beforeClose: '',
      }}
      overlayClassName={{
        base: 'fixed inset-0 bg-grey-900 bg-opacity-60 justify-center z-50 pt-160',
        afterOpen: 'flex',
        beforeClose: '',
      }}
    >
      <div className={'flex flex-col mb-12'}>
        <Icon
          size={IconSize.Large}
          svg={AlertTriangleIcon}
          className={'text-orange-500'}
        />
      </div>
      <Typography
        size={TypographySize.xl}
        lineHeight={TypographyLineHeight.tight}
        className="text-grey-900 mb-12 text-left"
        weight={TypographyWeight.semibold}
      >
        {headerLabel}
      </Typography>
      <div
        className={classNames(
          'flex flex-col justify-start items-start',
          'text-justify text-base leading-normal font-normal text-grey-900'
        )}
      >
        {contentLabel}
      </div>
      <div className="flex flex-row items-end justify-end mt-16">
        <Button
          size={ButtonSize.Medium}
          label={cancelBtnLabel}
          variant={ButtonVariant.ActionWithoutBorder}
          onClick={props.onCancel}
        />
        <Button
          size={ButtonSize.Medium}
          label={submitBtnLabel}
          variant={ButtonVariant.Primary}
          onClick={props.onSubmit}
        />
      </div>
    </ReactModal>
  );
};

MerchantConnectionSavedWarningPopup.displayName =
  'MerchantConnectionSavedWarningPopup';
