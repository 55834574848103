import {
  CheckmarkIcon,
  CycleDotIcon,
  Icon,
  IconSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
  UpdateClockIcon,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../lib/types/I18nKey';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

const DATE_FORMAT = 'LLL dd';
const TIME_FORMAT = 'hh:mm a';

export enum KeywordTableStateIndicatorType {
  CriteriaUpdated = 'CriteriaUpdated',
  KeywordNoData = 'KeywordNoData',
  NegKeywordNoData = 'NegKeywordNoData',
}

interface TableStateIndicatorProps {
  readonly lastUpdatedAt?: DateTime;
  readonly tableStateType: KeywordTableStateIndicatorType;
  readonly isPat?: boolean;
}

const KEYWORD_TABLE_STATE_INDICATOR_HEADER_I18NKEY_MAPPER: Record<
  KeywordTableStateIndicatorType,
  I18nKey
> = {
  [KeywordTableStateIndicatorType.CriteriaUpdated]:
    I18nKey.AI_RECOMMENDED_NEG_KEYWORD_RECENTLY_UPDATED_HEADER,
  [KeywordTableStateIndicatorType.KeywordNoData]:
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_STATE_INDICATOR_HEADER,
  [KeywordTableStateIndicatorType.NegKeywordNoData]:
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_STATE_INDICATOR_HEADER,
};

const KEYWORD_TABLE_STATE_INDICATOR_SUBHEADER_I18NKEY_MAPPER: Record<
  KeywordTableStateIndicatorType,
  I18nKey
> = {
  [KeywordTableStateIndicatorType.CriteriaUpdated]:
    I18nKey.AI_RECOMMENDED_NEG_KEYWORD_RECENTLY_UPDATED_DESCRIPTION,
  [KeywordTableStateIndicatorType.KeywordNoData]:
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_STATE_INDICATOR_SUBHEADER,
  [KeywordTableStateIndicatorType.NegKeywordNoData]:
    I18nKey.AI_RECOMMENDED_KEYWORD_NEGATIVE_TABLE_STATE_INDICATOR_SUBHEADER,
};

export const KeywordTableStateIndicator: React.FC<TableStateIndicatorProps> = ({
  lastUpdatedAt,
  tableStateType,
  isPat,
}) => {
  const intl = useIntl();

  const getTableStateIcon = (
    tableStateType: KeywordTableStateIndicatorType
  ) => {
    switch (tableStateType) {
      case KeywordTableStateIndicatorType.CriteriaUpdated:
        return CycleDotIcon;
      case KeywordTableStateIndicatorType.KeywordNoData:
      case KeywordTableStateIndicatorType.NegKeywordNoData:
        return CheckmarkIcon;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center pt-80">
      <span
        className={classNames(
          'flex items-center justify-center h-48 w-48 rounded-full',
          {
            'bg-blue-500':
              tableStateType === KeywordTableStateIndicatorType.CriteriaUpdated,
            'bg-green-500':
              tableStateType === KeywordTableStateIndicatorType.KeywordNoData ||
              KeywordTableStateIndicatorType.NegKeywordNoData,
          }
        )}
      >
        <Icon
          svg={getTableStateIcon(tableStateType)}
          size={IconSize.LargePlus}
          className="text-white"
        />
      </span>
      <Typography
        size={TypographySize['2xl']}
        lineHeight={TypographyLineHeight.normal}
        weight={TypographyWeight.semibold}
        className="text-grey-900 mt-8"
        data-test-id={`header_${tableStateType}`}
      >
        {intl.formatMessage({
          id: KEYWORD_TABLE_STATE_INDICATOR_HEADER_I18NKEY_MAPPER[
            tableStateType
          ],
        })}
      </Typography>

      <Typography
        size={TypographySize.lg}
        lineHeight={TypographyLineHeight.normal}
        weight={TypographyWeight.regular}
        className="text-grey-900"
      >
        {intl.formatMessage(
          {
            id: KEYWORD_TABLE_STATE_INDICATOR_SUBHEADER_I18NKEY_MAPPER[
              tableStateType
            ],
          },
          {
            isPat,
          }
        )}
      </Typography>
      {lastUpdatedAt && (
        <div
          className={classNames(
            'flex flex-row gap-4 items-center',
            'text-grey-500 text-sm bg-grey-50 px-12 py-8 rounded-full mt-8'
          )}
        >
          <Icon svg={UpdateClockIcon} size={IconSize.Small} />
          <span>
            {intl.formatMessage(
              {
                id: I18nKey.AI_RECOMMENDED_NEG_KEYWORD_RECENTLY_UPDATED_DATE_TIME_DATE,
              },
              { date: lastUpdatedAt.toFormat(DATE_FORMAT) }
            )}
          </span>
          <div className="w-4 h-4 rounded rounded-circle bg-grey-500" />
          <span className="mr-8">{lastUpdatedAt.toFormat(TIME_FORMAT)}</span>
          <span>
            {intl.formatMessage({
              id: I18nKey.AI_RECOMMENDED_NEG_KEYWORD_RECENTLY_UPDATED_REFRESHED_24_HOURS,
            })}
          </span>
        </div>
      )}
    </div>
  );
};

KeywordTableStateIndicator.displayName = 'KeywordTableStateIndicator';
