import { DateTime } from 'luxon';

import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  ProductAdDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { getPerformanceMetricValue } from '../utils';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) =>
  makeNumericColumn<ProductAdDetails>(
    ({
      adItemPerformance: { viewableImpressions },
      adItemDetails: { startDate },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        viewableImpressions
      );

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'OrdersColumn';

export const viewableImpressions: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: SV_ADS_API_COLUMN_NAME.ViewableImpressions,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIEWABLE_IMPRESSIONS,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const viewableImpressionsFilter = createNumericDataFieldFilter(
  SV_ADS_API_COLUMN_NAME.ViewableImpressions,
  I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIEWABLE_IMPRESSIONS,
  isValidNumber(0)
);
