import { IntlShape, useIntl } from 'react-intl';

import {
  PortaledTooltipPlacement,
  createMultiSelectDataFieldFilter,
  AlertTriangleIcon as warningSVG,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeTextColumn,
  makeTextWithIconColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupReviewStatusType,
  ProductAdDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TooltipText } from '../reviewStatusTooltipText';
import { ADGROUP_REVIEW_STATUS_MAPPER, TableDataAdsManager } from '../types';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';

export const getSelectOptions = (
  intl: IntlShape,
  currentValue?: AdGroupReviewStatusType
) => {
  if (currentValue === AdGroupReviewStatusType.InProgress) {
    return [
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_CANCELLED,
        }),
        value: AdGroupReviewStatusType.Cancelled,
      },
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_INPROGRESS,
        }),
        value: AdGroupReviewStatusType.InProgress,
      },
    ];
  }

  if (currentValue === AdGroupReviewStatusType.Pending) {
    return [
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_CANCELLED,
        }),
        value: AdGroupReviewStatusType.Cancelled,
      },
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
        }),
        value: AdGroupReviewStatusType.Pending,
      },
    ];
  }

  return [];
};

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) => {
  const intl = useIntl();

  const getColumnText = ({ adItemDetails }: ProductAdDetails) =>
    adItemDetails.adGroupReviewStatus
      ? intl.formatMessage({
          id: ADGROUP_REVIEW_STATUS_MAPPER[
            adItemDetails.adGroupReviewStatus.reviewStatus
          ],
        })
      : undefined;

  if (
    props.adItemDetails.adGroupReviewStatus?.reviewStatus &&
    props.adItemDetails.adGroupReviewStatus?.reviewStatus.length
  ) {
    return makeTextWithIconColumn(
      getColumnText,
      warningSVG,
      <TooltipText
        comments={props?.adItemDetails.adGroupReviewStatus?.reviewReason}
      />,
      true,
      PortaledTooltipPlacement.Right
    )(props);
  }

  return makeTextColumn(
    getColumnText,
    props.pendingFields?.includes(SV_ADS_API_COLUMN_NAME.AdGroupReviewStatus),
    'normal-case'
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const adGroupReviewStatusColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: SV_ADS_API_COLUMN_NAME.AdGroupReviewStatus,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_REVIEW_STATUS,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '200px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const adGroupReviewStatusFilter = createMultiSelectDataFieldFilter(
  SV_ADS_API_COLUMN_NAME.AdGroupReviewStatus,
  I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_REVIEW_STATUS,
  [
    SelectFilterOption.AdGroupReviewStatusPending,
    SelectFilterOption.AdGroupReviewStatusInProgress,
    SelectFilterOption.AdGroupReviewStatusCompleted,
    SelectFilterOption.AdGroupReviewStatusCancelled,
  ]
);
