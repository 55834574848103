import {
  ChevronDownIcon,
  FlagIconComponent,
  Icon,
  IconSize,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import I18nKey from '../../../../lib/types/I18nKey';
import React from 'react';
import { useIntl } from 'react-intl';
import { MerchantCountryCode } from '../../../../lib/types/AOSharedTypes';

interface SearchPickerLabelProps {
  locale?: MerchantCountryCode;
  isGroup?: boolean;
  value?: string;
  onClick: () => void;
}

const SearchPickerLabel: React.FC<SearchPickerLabelProps> = ({
  onClick,
  locale = MerchantCountryCode.US,
  isGroup = false,
  value,
}) => {
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    id: I18nKey.MARKET_INTELLIGENCE_REPORTING_SEARCH_TERM_NO_VALUE_PLACEHOLDER,
  });

  const selectedValue = value || placeholder;
  const disabled = !value;

  return (
    <div className="flex items-center h-40 p-12 space-x-4" onClick={onClick}>
      <div className="flex items-center space-x-8 flex-grow">
        <FlagIconComponent
          disabled={disabled}
          country={locale}
          isImg
          iconClassName="flex items-center"
        />
        <span
          className={classNames('text-base text-grey-900', {
            'font-bold': isGroup,
          })}
        >
          {selectedValue}
        </span>
      </div>
      <Icon size={IconSize.Medium} svg={ChevronDownIcon} />
    </div>
  );
};

export default SearchPickerLabel;

SearchPickerLabel.displayName = 'SearchPickerLabel';
