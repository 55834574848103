import { Factory } from 'fishery';

import {
  AggregationPeriodType,
  HomePageData,
  HomepageResponseMetricKeys,
} from '../../types/CompassSharedTypes';
import { CurrencyCode } from '../../utilities/currency';

export const HomepageCurrentDataApiResponse = Factory.define<HomePageData>(
  () => ({
    currency: CurrencyCode.USD,
    aggregationPeriodType: AggregationPeriodType.Current,
    performanceMetrics: {
      range: {
        startDate: '2021-11-22',
        endDate: '2021-11-28',
      },
      [HomepageResponseMetricKeys.AdSales]: {
        amount: '19901.00',
        currency: 'USD',
      },
      [HomepageResponseMetricKeys.TotalSales]: {
        amount: '7250.00',
        currency: 'USD',
      },
      [HomepageResponseMetricKeys.AdSpend]: {
        amount: '21134.00',
        currency: 'USD',
      },
      [HomepageResponseMetricKeys.Tacos]: 0.57,
    },
    [HomepageResponseMetricKeys.TotalSales]: {
      overviewSummary: {
        daily: {
          highest: {
            timePeriod: '2021-11-23',
            value: '1200.5',
          },
          lowest: {
            timePeriod: '2021-11-28',
            value: '500',
          },
        },
      },
      graphDataPoints: {
        daily: [
          {
            date: '2021-12-01',
            totalValue: '22.4',
            channels: [
              {
                name: 'amazon',
                value: 11.2,
              },
              {
                name: 'walmart',
                value: 11.2,
              },
            ],
          },
          {
            date: '2021-12-02',
            totalValue: '28.2',
            channels: [
              {
                name: 'amazon',
                value: 20.2,
              },
              {
                name: 'walmart',
                value: 8.0,
              },
            ],
          },
          {
            date: '2021-12-03',
            totalValue: '24.2',
            channels: [
              {
                name: 'amazon',
                value: 12.2,
              },
              {
                name: 'walmart',
                value: 12.2,
              },
            ],
          },
        ],
      },
      insights: {
        totalValue: '1058',
        channels: [
          {
            name: 'amazon',
            value: 1373,
          },
          {
            name: 'walmart',
            value: 260,
          },
        ],
        merchantCountries: [
          {
            merchantCountryId: '0a9b9919-407e-4147-8dac-d07f55f68e53',
            merchantCountryName: 'ABCXYZ',
            aggregation: 100,
          },
          {
            merchantCountryId: '0d72c6f6-edc2-449d-8bec-1ad1f2665fbc',
            merchantCountryName: 'ABCXYZ',
            aggregation: 160,
          },
          {
            merchantCountryId: '0190df9d-d01f-401d-af1d-9bdaf5cebdc0',
            merchantCountryName: 'ABCXYZ',
            aggregation: 100,
          },
          {
            merchantCountryId: '02b57941-2a61-485e-8064-be4746c588c2',
            merchantCountryName: 'ABCXYZ',
            aggregation: 130,
          },
          {
            merchantCountryId: '02e4cfe1-34c6-4d13-b742-5b26cba817ee',
            merchantCountryName: 'ABCXYZ',
            aggregation: 123,
          },
          {
            merchantCountryId: '030592f3-0907-4314-b074-88b1fb4f5467',
            merchantCountryName: 'ABCXYZ',
            aggregation: 140,
          },
          {
            merchantCountryId: '031b3f92-2047-40f8-97ca-c8631089801c',
            merchantCountryName: 'ABCXYZ',
            aggregation: 80,
          },
          {
            merchantCountryId: '039e7ab4-7906-49bf-a60e-7bbd0aa5f0df',
            merchantCountryName: 'ABCXYZ',
            aggregation: 800,
          },
        ],
      },
    },
    [HomepageResponseMetricKeys.AdSales]: {
      overviewSummary: {
        daily: {
          highest: {
            timePeriod: '2021-11-27',
            value: '11125',
          },
          lowest: {
            timePeriod: '2021-11-25',
            value: '1500.72',
          },
        },
      },
      graphDataPoints: {
        daily: [
          {
            date: '2021-12-01',
            totalValue: '42.4',
            channels: [
              {
                name: 'amazon',
                value: 21.2,
              },
              {
                name: 'walmart',
                value: 21.2,
              },
            ],
          },
          {
            date: '2021-12-02',
            totalValue: '22.2',
            channels: [
              {
                name: 'amazon',
                value: 16.2,
              },
              {
                name: 'walmart',
                value: 6.0,
              },
            ],
          },
          {
            date: '2021-12-03',
            totalValue: '15.2',
            channels: [
              {
                name: 'amazon',
                value: 12.2,
              },
              {
                name: 'walmart',
                value: 3.2,
              },
            ],
          },
        ],
      },
      insights: {
        totalValue: '1058',
        channels: [
          {
            name: 'amazon',
            value: 558,
          },
          {
            name: 'walmart',
            value: 558,
          },
        ],
        merchantCountries: [
          {
            merchantCountryId: '0190df9d-d01f-401d-af1d-9bdaf5cebdc0',
            merchantCountryName: 'ABCXYZ',
            aggregation: 100,
          },
          {
            merchantCountryId: '02b57941-2a61-485e-8064-be4746c588c2',
            merchantCountryName: 'ABCXYZ',
            aggregation: 130,
          },
        ],
      },
    },
    [HomepageResponseMetricKeys.AdSpend]: {
      overviewSummary: {
        daily: {
          highest: {
            timePeriod: '2021-11-23',
            value: '4425',
          },
          lowest: {
            timePeriod: '2021-11-26',
            value: '605',
          },
        },
      },
      graphDataPoints: {
        daily: [
          {
            date: '2021-12-01',
            totalValue: '5.4',
            channels: [
              {
                name: 'amazon',
                value: 2.2,
              },
              {
                name: 'walmart',
                value: 3.2,
              },
            ],
          },
          {
            date: '2021-12-02',
            totalValue: '37.2',
            channels: [
              {
                name: 'amazon',
                value: 20.2,
              },
              {
                name: 'walmart',
                value: 17.0,
              },
            ],
          },
          {
            date: '2021-12-03',
            totalValue: '88.2',
            channels: [
              {
                name: 'amazon',
                value: 40.2,
              },
              {
                name: 'walmart',
                value: 48.2,
              },
            ],
          },
        ],
      },
      insights: {
        totalValue: '1058',
        channels: [
          {
            name: 'amazon',
            value: 558,
          },
          {
            name: 'walmart',
            value: 558,
          },
        ],
        merchantCountries: [
          {
            merchantCountryId: '0190df9d-d01f-401d-af1d-9bdaf5cebdc0',
            merchantCountryName: 'ABCXYZ',
            aggregation: 100,
          },
          {
            merchantCountryId: '02b57941-2a61-485e-8064-be4746c588c2',
            merchantCountryName: 'ABCXYZ',
            aggregation: 130,
          },
        ],
      },
    },
    [HomepageResponseMetricKeys.Tacos]: {
      overviewSummary: {
        daily: {
          highest: {
            timePeriod: '2021-11-27',
            value: '12500',
          },
          lowest: {
            timePeriod: '2021-11-24',
            value: '1850',
          },
        },
      },
      graphDataPoints: {
        daily: [
          {
            date: '2021-09-01',
            totalValue: '22.4',
            adSpend: '100',
            totalSales: '1200',
            channels: [
              {
                name: 'amazon',
                value: 11.2,
              },
              {
                name: 'walmart',
                value: 11.2,
              },
            ],
          },
          {
            date: '2021-10-01',
            totalValue: '28.2',
            adSpend: '120',
            totalSales: '1100',
            channels: [
              {
                name: 'amazon',
                value: 20.2,
              },
              {
                name: 'walmart',
                value: 8.0,
              },
            ],
          },
          {
            date: '2021-11-01',
            totalValue: '24.2',
            adSpend: '130',
            totalSales: '1300',
            channels: [
              {
                name: 'amazon',
                value: 12.2,
              },
              {
                name: 'walmart',
                value: 12.2,
              },
            ],
          },
          {
            date: '2021-12-01',
            totalValue: '22.4',
            adSpend: '100',
            totalSales: '1200',
            channels: [
              {
                name: 'amazon',
                value: 11.2,
              },
              {
                name: 'walmart',
                value: 11.2,
              },
            ],
          },
          {
            date: '2022-01-01',
            totalValue: '28.2',
            adSpend: '120',
            totalSales: '1100',
            channels: [
              {
                name: 'amazon',
                value: 20.2,
              },
              {
                name: 'walmart',
                value: 8.0,
              },
            ],
          },
          {
            date: '2022-02-01',
            totalValue: '24.2',
            adSpend: '130',
            totalSales: '1300',
            channels: [
              {
                name: 'amazon',
                value: 12.2,
              },
              {
                name: 'walmart',
                value: 12.2,
              },
            ],
          },
        ],
      },
      insights: {
        totalValue: '1058',
        channels: [
          {
            name: 'amazon',
            value: 558,
          },
          {
            name: 'walmart',
            value: 558,
          },
        ],
        merchantCountries: [
          {
            merchantCountryId: '0190df9d-d01f-401d-af1d-9bdaf5cebdc0',
            merchantCountryName: 'ABCXYZ',
            aggregation: 100,
          },
          {
            merchantCountryId: '02b57941-2a61-485e-8064-be4746c588c2',
            merchantCountryName: 'ABCXYZ',
            aggregation: 130,
          },
        ],
      },
    },
  })
);

export const HomepagePreviousDataApiResponse = Factory.define<HomePageData>(
  () => ({
    currency: CurrencyCode.USD,
    aggregationPeriodType: AggregationPeriodType.Previous,
    performanceMetrics: {
      range: {
        startDate: '2021-11-22',
        endDate: '2021-11-30',
      },
      [HomepageResponseMetricKeys.AdSales]: {
        amount: '8789.00',
        currency: 'USD',
      },
      [HomepageResponseMetricKeys.TotalSales]: {
        amount: '22109.00',
        currency: 'USD',
      },
      [HomepageResponseMetricKeys.AdSpend]: {
        amount: '6289.00',
        currency: 'USD',
      },
      [HomepageResponseMetricKeys.Tacos]: 0.07,
    },
    [HomepageResponseMetricKeys.TotalSales]: {
      overviewSummary: {
        daily: {
          highest: {
            timePeriod: '2021-11-27',
            value: '12500',
          },
          lowest: {
            timePeriod: '2021-11-24',
            value: '1850',
          },
        },
      },
      graphDataPoints: {
        daily: [
          {
            date: '2021-12-01',
            totalValue: '20.4',
            channels: [
              {
                name: 'amazon',
                value: 10.2,
              },
              {
                name: 'walmart',
                value: 10.2,
              },
            ],
          },
          {
            date: '2021-12-02',
            totalValue: '18.2',
            channels: [
              {
                name: 'amazon',
                value: 10.2,
              },
              {
                name: 'walmart',
                value: 8.0,
              },
            ],
          },
          {
            date: '2021-12-03',
            totalValue: '4.2',
            channels: [
              {
                name: 'amazon',
                value: 2.2,
              },
              {
                name: 'walmart',
                value: 2.2,
              },
            ],
          },
        ],
      },
      insights: {
        totalValue: '1058',
        channels: [
          {
            name: 'amazon',
            value: 1038,
          },
          {
            name: 'walmart',
            value: 170,
          },
        ],
        merchantCountries: [
          {
            merchantCountryId: '0a9b9919-407e-4147-8dac-d07f55f68e53',
            merchantCountryName: 'ABCXYZ',
            aggregation: 50,
          },
          {
            merchantCountryId: '0d72c6f6-edc2-449d-8bec-1ad1f2665fbc',
            merchantCountryName: 'ABCXYZ',
            aggregation: 120,
          },
          {
            merchantCountryId: '0190df9d-d01f-401d-af1d-9bdaf5cebdc0',
            merchantCountryName: 'ABCXYZ',
            aggregation: 80,
          },
          {
            merchantCountryId: '02b57941-2a61-485e-8064-be4746c588c2',
            merchantCountryName: 'ABCXYZ',
            aggregation: 120,
          },
          {
            merchantCountryId: '02e4cfe1-34c6-4d13-b742-5b26cba817ee',
            merchantCountryName: 'ABCXYZ',
            aggregation: 113,
          },
          {
            merchantCountryId: '030592f3-0907-4314-b074-88b1fb4f5467',
            merchantCountryName: 'ABCXYZ',
            aggregation: 110,
          },
          {
            merchantCountryId: '031b3f92-2047-40f8-97ca-c8631089801c',
            merchantCountryName: 'ABCXYZ',
            aggregation: 65,
          },
          {
            merchantCountryId: '039e7ab4-7906-49bf-a60e-7bbd0aa5f0df',
            merchantCountryName: 'ABCXYZ',
            aggregation: 550,
          },
        ],
      },
    },
    [HomepageResponseMetricKeys.AdSales]: {
      overviewSummary: {
        daily: {
          highest: {
            timePeriod: 'week 1',
            value: '125',
          },
          lowest: {
            timePeriod: 'week 6',
            value: '105',
          },
        },
      },
      graphDataPoints: {
        daily: [
          {
            date: '2021-12-01',
            totalValue: '42.4',
            channels: [
              {
                name: 'amazon',
                value: 12.2,
              },
              {
                name: 'walmart',
                value: 12.2,
              },
            ],
          },
          {
            date: '2021-12-02',
            totalValue: '20.4',
            channels: [
              {
                name: 'amazon',
                value: 10.2,
              },
              {
                name: 'walmart',
                value: 10.2,
              },
            ],
          },
          {
            date: '2021-12-03',
            totalValue: '38.2',
            channels: [
              {
                name: 'amazon',
                value: 30.2,
              },
              {
                name: 'walmart',
                value: 8.0,
              },
            ],
          },
        ],
      },
      insights: {
        totalValue: '1058',
        channels: [
          {
            name: 'amazon',
            value: 558,
          },
          {
            name: 'walmart',
            value: 558,
          },
        ],
        merchantCountries: [
          {
            merchantCountryId: '0190df9d-d01f-401d-af1d-9bdaf5cebdc0',
            merchantCountryName: 'ABCXYZ',
            aggregation: 100,
          },
          {
            merchantCountryId: '02b57941-2a61-485e-8064-be4746c588c2',
            merchantCountryName: 'ABCXYZ',
            aggregation: 130,
          },
        ],
      },
    },
    [HomepageResponseMetricKeys.AdSpend]: {
      overviewSummary: {
        daily: {
          highest: {
            timePeriod: 'week 1',
            value: '125',
          },
          lowest: {
            timePeriod: 'week 6',
            value: '105',
          },
        },
      },
      graphDataPoints: {
        daily: [
          {
            date: '2021-12-01',
            totalValue: '5.4',
            channels: [
              {
                name: 'amazon',
                value: 11.2,
              },
              {
                name: 'walmart',
                value: 11.2,
              },
            ],
          },
          {
            date: '2021-12-02',
            totalValue: '28.2',
            channels: [
              {
                name: 'amazon',
                value: 20.2,
              },
              {
                name: 'walmart',
                value: 8.0,
              },
            ],
          },
          {
            date: '2021-12-03',
            totalValue: '24.2',
            channels: [
              {
                name: 'amazon',
                value: 12.2,
              },
              {
                name: 'walmart',
                value: 12.2,
              },
            ],
          },
        ],
      },
      insights: {
        totalValue: '1058',
        channels: [
          {
            name: 'amazon',
            value: 558,
          },
          {
            name: 'walmart',
            value: 558,
          },
        ],
        merchantCountries: [
          {
            merchantCountryId: '0190df9d-d01f-401d-af1d-9bdaf5cebdc0',
            merchantCountryName: 'ABCXYZ',
            aggregation: 100,
          },
          {
            merchantCountryId: '02b57941-2a61-485e-8064-be4746c588c2',
            merchantCountryName: 'ABCXYZ',
            aggregation: 130,
          },
        ],
      },
    },
    [HomepageResponseMetricKeys.Tacos]: {
      overviewSummary: {
        daily: {
          highest: {
            timePeriod: 'week 1',
            value: '125',
          },
          lowest: {
            timePeriod: 'week 6',
            value: '105',
          },
        },
      },
      graphDataPoints: {
        daily: [
          {
            date: '2021-12-01',
            totalValue: '12.4',
            channels: [
              {
                name: 'amazon',
                value: 11.2,
              },
              {
                name: 'walmart',
                value: 11.2,
              },
            ],
          },
          {
            date: '2021-10-02',
            totalValue: '18.2',
            channels: [
              {
                name: 'amazon',
                value: 20.2,
              },
              {
                name: 'walmart',
                value: 8.0,
              },
            ],
          },
          {
            date: '2021-08-03',
            totalValue: '14.2',
            channels: [
              {
                name: 'amazon',
                value: 12.2,
              },
              {
                name: 'walmart',
                value: 12.2,
              },
            ],
          },
          {
            date: '2021-06-01',
            totalValue: '12.4',
            channels: [
              {
                name: 'amazon',
                value: 11.2,
              },
              {
                name: 'walmart',
                value: 11.2,
              },
            ],
          },
          {
            date: '2021-04-02',
            totalValue: '18.2',
            channels: [
              {
                name: 'amazon',
                value: 20.2,
              },
              {
                name: 'walmart',
                value: 8.0,
              },
            ],
          },
          {
            date: '2021-02-03',
            totalValue: '14.2',
            channels: [
              {
                name: 'amazon',
                value: 12.2,
              },
              {
                name: 'walmart',
                value: 12.2,
              },
            ],
          },
        ],
      },
      insights: {
        totalValue: '1058',
        channels: [
          {
            name: 'amazon',
            value: 558,
          },
          {
            name: 'walmart',
            value: 558,
          },
        ],
        merchantCountries: [
          {
            merchantCountryId: '0190df9d-d01f-401d-af1d-9bdaf5cebdc0',
            merchantCountryName: 'ABCXYZ',
            aggregation: 100,
          },
          {
            merchantCountryId: '02b57941-2a61-485e-8064-be4746c588c2',
            merchantCountryName: 'ABCXYZ',
            aggregation: 130,
          },
        ],
      },
    },
  })
);
