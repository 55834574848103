import axios, { AxiosResponse, RawAxiosRequestConfig } from 'axios';
import base64url from 'base64url';
import noop from 'lodash/noop';

/* eslint-disable max-statements */
import { IdToken } from '@auth0/auth0-react';

import {
  SignUpData,
  getSignUpMethod,
} from '../../containers/userProvider/selectors';
import {
  createPaginatedApiDataFetcher,
  filterEmptyValues,
  getPaginationParamsFromRequest,
} from '../factories/apiDataFetcherFactory';
import {
  Account,
  AccountLeadAnalysts,
  AccountSalesChannelPaginatedResult,
  AllLookerReportsResponseBody,
  AllSalesChannel,
  AmazonAdsConnectResult,
  BIAmcInstanceResponse,
  BICustomDashboardsResponseBody,
  BIDashboardsResponseBody,
  BIFolderType,
  ChildAccounts,
  CreateLookerReportRequestBody,
  DataSyncStatusResponseType,
  DownloadLookerReportApiProps,
  KnockTokenResponse,
  LatestConnection,
  LookerEmbedUrlResponse,
  LookerReportResponseBody,
  MerchantClaimResponseType,
  MerchantCorrelationResponseType,
  MetadataResponse,
  NotificationAccountSettingsResponse,
  NotificationAlertUpdateRequestPayload,
  NotificationSettingsResponse,
  ProductDataSyncRequestType,
  ProductDataSyncRequestType1P,
  ProductSubscriptionEventResponse,
  ProductVertical,
  ReportState,
  SalesChannelData,
  SalesChannelResponse,
  SalesChannelUpdateData,
  UserCreateRequestType,
  UserDetails,
  UserFetchedResponseType,
  UserResponseType,
  WalmartAdsConnectRequest,
  WalmartAdsConnectResponse,
  WalmartProductConnectResult,
  WalmartProductConnectResult1P,
  WalmartSellerProductsRequestBody,
  WalmartSellerProductsResponseType,
} from '../types/Fam';
import { getDefaultHeaders } from './index';
import {
  MerchantCountriesResponse,
  PaginatedDataFetcher,
  PaginatedRequest,
} from './types';
import { Fw2LocalStorage } from '../utilities/fw2LocalStorage';
import { FWCookie, PERSISTED_KEYS } from '../utilities/fwCookie';

const HUBSPOT_PORTAL_ID = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
const REACT_APP_FAM_HOST = process.env.REACT_APP_FAM_HOST;

export interface FAMApiClient {
  readonly createAccount: (
    creationMethod: string,
    signUpData?: SignUpData
  ) => Promise<Account>;
  readonly getAccountDetails: (accountId: string) => Promise<Account>;
  readonly subscriptionsAdd: (
    accountId: string,
    productVerticals: string[]
  ) => Promise<void>;
  readonly getUserDetails: () => Promise<UserDetails | undefined>;
  readonly triggerVerificationEmail: () => Promise<void>;
  readonly triggerPasswordResetEmail: () => Promise<boolean>;
  readonly emailVerified: () => Promise<void>;
  readonly getProductVerticals: () => Promise<ProductVertical[]>;
  readonly getSalesChannelTableData: (
    accountId: string,
    salesChannelId: string
  ) => (
    request: PaginatedRequest
  ) => Promise<AccountSalesChannelPaginatedResult>;
  readonly getAllSalesChannels: () => Promise<AllSalesChannel[]>;
  readonly updateSalesChannel: (
    data: SalesChannelUpdateData,
    accountId: string,
    merchant_country_id: string
  ) => Promise<AxiosResponse<SalesChannelData>>;
  readonly enableAoForSalesChannels: (
    accountId: string,
    merchant_country_ids: string[]
  ) => Promise<SalesChannelData[]>;
  readonly sendInitialWalmartSyncEmail: (
    accountId: string,
    email?: string
  ) => Promise<void>;
  readonly createUser: (
    accountId: string,
    userData: UserCreateRequestType
  ) => Promise<UserResponseType>;
  readonly acceptInvite: (accountId: string) => Promise<void>;
  readonly fetchUserFromAccount: (
    accountId: string,
    email: string
  ) => Promise<UserFetchedResponseType>;
  readonly removeUserFromAccount: (
    accountId: string,
    email: string
  ) => Promise<void>;
  readonly connectProductSyncForm: (
    accountId: string,
    formData: ProductDataSyncRequestType
  ) => Promise<WalmartProductConnectResult>;
  readonly connectWalmartSellerProducts1P: (
    accountId: string,
    formData: ProductDataSyncRequestType1P
  ) => Promise<WalmartProductConnectResult1P>;
  readonly updateUsername: (
    firstName: string,
    lastName: string
  ) => Promise<boolean>;
  readonly getUsers: (
    accountId: string
  ) => PaginatedDataFetcher<UserResponseType>;
  readonly resendInvite: (accountId: string, email: string) => Promise<void>;
  readonly removeProductSubscription: (
    accountId: string,
    subscriptionId: string
  ) => Promise<ProductVertical[]>;
  readonly getProductSubscriptionEvents: (
    accountId: string,
    productVerticalId: string
  ) => Promise<ProductSubscriptionEventResponse>;
  readonly getMerchantCountries: (
    accountId: string,
    channelIds: string[]
  ) => Promise<MerchantCountriesResponse>;
  readonly updateCompanyName: (
    accountId: string,
    companyName: string
  ) => Promise<Account | undefined>;
  readonly addAmazonAdsConnect: (
    accountId: string,
    authCode: string
  ) => Promise<AmazonAdsConnectResult>;
  readonly amazonSellingPartnerConnect: (
    accountId: string,
    regionId: string,
    sellingPartnerId: string,
    merchantType: string,
    spApiOauthCode: string
  ) => Promise<AmazonAdsConnectResult>;
  readonly merchantConnect: (
    accountId: string,
    correlationId: string
  ) => Promise<MerchantCorrelationResponseType>;
  readonly claimMerchantCountries: (
    accountId: string,
    ids: string[]
  ) => Promise<MerchantClaimResponseType>;
  readonly getSignedLookerEmbedUrl: (
    accountId: string,
    embedUrl: string
  ) => Promise<LookerEmbedUrlResponse>;
  readonly amazon1pSellingPartnerConnect: (
    accountId: string,
    mcIds: string[],
    regionId: string,
    refreshToken: string,
    vendorGroupId: string
  ) => Promise<void>;
  readonly setupCompleted: (accountId: string) => Promise<Account | undefined>;
  readonly getLatestLookerReport: (
    accountId: string,
    date: string
  ) => Promise<LookerReportResponseBody[] | undefined>;
  readonly createLookerReport: (
    accountId: string,
    requestBody: CreateLookerReportRequestBody
  ) => Promise<LookerReportResponseBody | undefined>;
  readonly getLookerReportStatus: (
    accountId: string,
    reportId: string
  ) => Promise<ReportState | undefined>;
  readonly downloadReportFile: (
    props: DownloadLookerReportApiProps
  ) => Promise<AxiosResponse>;
  readonly createOpportunityAnalyzerReport: (
    accountId: string,
    requestBody: CreateLookerReportRequestBody
  ) => Promise<LookerReportResponseBody>;
  readonly getOpportunityAnalyzerReportStatus: (
    accountId: string,
    reportId: string
  ) => Promise<ReportState | undefined>;
  readonly downloadOpportunityAnalyzerReport: (
    accountId: string,
    reportId: string
  ) => Promise<AxiosResponse>;
  readonly downloadS3File: (url: string) => Promise<AxiosResponse<Blob>>;
  readonly getDataSyncStatus: (
    accountId: string
  ) => Promise<DataSyncStatusResponseType>;
  readonly sendFormToHubspot: (
    formId: string,
    userDetails: UserDetails,
    options?: { annualGMV: string; connectToSales: string }
  ) => Promise<void>;
  readonly getMerchantCountriesLatestConnections: (
    accountId: string
  ) => Promise<LatestConnection[]>;
  readonly trackLoggedInUser: (accountId: string) => Promise<void>;
  readonly getLeadAnalysts: (accountId: string) => Promise<AccountLeadAnalysts>;
  readonly addAccountMetaData: (
    accountId: string,
    metatag: string,
    metaValue: string
  ) => Promise<void>;
  readonly getNotificationSettingsByUser: (
    accountId: string,
    userId: string
  ) => Promise<NotificationSettingsResponse>;
  readonly getAccountNotificationSettings: (
    accountId: string
  ) => Promise<NotificationAccountSettingsResponse>;
  readonly updateNotificationAlert: (
    accountId: string,
    userId: string,
    alert: string,
    payload: NotificationAlertUpdateRequestPayload
  ) => Promise<boolean>;
  readonly getBIDashboards: (
    accountId: string
  ) => Promise<BIDashboardsResponseBody>;
  readonly getCustomBIDashboards: (
    accountId: string,
    bypassCache?: boolean
  ) => Promise<BICustomDashboardsResponseBody>;
  readonly createBIDashboard: (
    accountId: string,
    dashboardName: string
  ) => Promise<string>;
  readonly copyBIDashboard: (
    accountId: string,
    dashboardId: string,
    destinationFolder: BIFolderType
  ) => Promise<string>;
  readonly deleteBIDashboard: (
    accountId: string,
    dashboardId: string
  ) => Promise<string>;
  readonly getSignedLookerEmbedUrlV2: (
    accountId: string,
    embedUrl: string
  ) => Promise<LookerEmbedUrlResponse>;
  readonly updateUserNotifications: (
    accountId: string,
    userId: string,
    isNotificationsEnabled: boolean
  ) => Promise<void>;
  readonly updateUserRole: (
    accountId: string,
    userId: string,
    roleId: string
  ) => Promise<void>;
  readonly connectWalmartAdData: (
    accountId: string,
    request: WalmartAdsConnectRequest
  ) => Promise<WalmartAdsConnectResponse>;
  readonly getKnockToken: (email: string) => Promise<KnockTokenResponse>;
  readonly connectWalmartSellerProducts: (
    accountId: string,
    requestBody: WalmartSellerProductsRequestBody
  ) => Promise<WalmartSellerProductsResponseType>;
  readonly getAccountRelationships: (
    accountId: string
  ) => Promise<ChildAccounts>;
  readonly getParentAccount: (accountId: string) => Promise<Account>;
  readonly createAccountRelationship: (
    accountId: string,
    childAccountName: string
  ) => Promise<ChildAccounts>;
  readonly getMetadataTags: (
    accountId: string,
    tag: string
  ) => Promise<MetadataResponse[]>;
  readonly getAllMetadataTags: (
    accountId: string
  ) => Promise<MetadataResponse[]>;
  readonly getAmcInstances: (
    accountId: string
  ) => Promise<BIAmcInstanceResponse>;
}

export const PATHS = Object.freeze({
  ACCOUNTS: '/accounts',
  ACCOUNT_DETAILS: (accountId: string) => `/accounts/${accountId}`,
  ACCOUNT_USERS: (accountId: string) => `/accounts/${accountId}/users`,
  ACCOUNT_USER: (accountId: string, email: string) =>
    `/accounts/${accountId}/users/${email}`,
  SUBSCRIPTIONS_APPEND: (accountId: string) =>
    `/accounts/${accountId}/subscriptions/append`,
  USERS: (email: string) => `/users/${email}`,
  USERS_EMAIL_VERIFY: (email: string) => `/users/${email}/verify`,
  USERS_PASSWORD_RESET: (email: string) => `/users/${email}/password_reset`,
  PATCH_USER: (email: string) => `/users/${email}`,
  USERS_EMAIL_VERIFIED: (email: string) => `/users/${email}/verified`,
  KNOCK_TOKEN: (email: string) => `/users/${email}/knock_token`,
  PRODUCT_VERTICALS: '/product_verticals',
  GET_ALL_SALES_CHANNELS: () => `/sales-channels`,
  SALES_CHANNELS: (accountId: string) =>
    `/accounts/${accountId}/sales-channels`,
  MERCHANT_COUNTRY: (accountId: string, merchant_country_id: string) =>
    `/accounts/${accountId}/merchant-countries/${merchant_country_id}`,
  MERCHANT_COUNTRIES: (accountId: string) =>
    `/accounts/${accountId}/merchant-countries`,
  CLAIM_MERCHANT_COUNTRIES: (accountId: string) =>
    `/accounts/${accountId}/merchant-countries/claim`,
  CONNECT_PRODUCT_SYNC_FORM: (accountId: string) =>
    `/accounts/${accountId}/walmart-products-connect`,
  SEND_INITIAL_WALMART_SYNC_EMAIL: (accountId: string) =>
    `/accounts/${accountId}/walmart-ads-connect`,
  PRODUCT_SUBSCRIPTION: (accountId: string, subscription_id: string) =>
    `/accounts/${accountId}/subscriptions/${subscription_id}`,
  PRODUCT_SUBSCRIPTION_EVENTS: (accountId: string, productVerticalId: string) =>
    `/accounts/${accountId}/subscriptions/${productVerticalId}/history`,
  AMAZON_ADS_CONNECT: (accountId: string) =>
    `/accounts/${accountId}/amazon-ads-connect`,
  AMAZON_SELLING_PARTNER_CONNECT: (accountId: string) =>
    `/accounts/${accountId}/amazon-selling-partner-connect`,
  MERCHANT_CONNECT: (accountId: string, correlationId: string) =>
    `/accounts/${accountId}/sales-channels/connection/${correlationId}`,
  LOOKER_EMBED: (accountId: string) => `/bi/${accountId}/looker_embed`,
  AMAZON_1P_SELLING_PARTNER_CONNECT: (accountId: string) =>
    `/accounts/${accountId}/amazon-1p-selling-partner-connect`,
  SETUP_COMPLETED: (accountId: string) =>
    `/accounts/${accountId}/dismiss_setup`,
  LOOKER_REPORTS: (accountId: string) =>
    `/reports/accounts/${accountId}/change-log`,
  LOOKER_REPORT_STATUS: (accountId: string, reportId: string) =>
    `/reports/accounts/${accountId}/change-log/${reportId}`,
  LOOKER_REPORT_DOWNLOAD: (accountId: string, reportId: string) =>
    `/reports/accounts/${accountId}/change-log/${reportId}/download-url`,
  OPPORTUNITY_ANALYZER_REPORT: (accountId: string) =>
    `/reports/accounts/${accountId}/opportunity-analyzer`,
  OPPORTUNITY_ANALYZER_REPORT_STATUS: (accountId: string, reportId: string) =>
    `/reports/accounts/${accountId}/opportunity-analyzer/${reportId}`,
  OPPORTUNITY_ANALYZER_REPORT_DOWNLOAD: (accountId: string, reportId: string) =>
    `/reports/accounts/${accountId}/opportunity-analyzer/${reportId}/download-url`,
  DATA_SYNC_STATUS: (accountId: string) =>
    `/accounts/${accountId}/merchant-countries/data-sync-status`,
  SEND_FORM_TO_HUBSPOT: (portalId: string, formId: string) =>
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
  GET_MERCHANT_COUNTRIES_LATEST_CONNECTIONS: (accountId: string) =>
    `/accounts/${accountId}/mc-latest-mws-conns`,
  TRACK_LOGGED_IN_USER: (accountId: string) =>
    `/accounts/${accountId}/user-login`,
  LEAD_ANALYSTS: (accountId: string) => `/accounts/${accountId}/lead-analysts`,
  ADD_ACCOUNT_METADATA: (accountId: string) =>
    `/accounts/${accountId}/metadata`,
  GET_BI_DASHBOARDS: (accountId: string) =>
    `/bi/${accountId}/looker-dashboards`,
  GET_CUSTOM_BI_DASHBOARDS: (accountId: string) =>
    `/bi/${accountId}/custom-bi-dashboards`,
  CREATE_BI_DASHBOARD: (accountId: string) =>
    `/bi/${accountId}/looker-dashboards`,
  COPY_BI_DASHBOARD: (accountId: string, dashboardId: string) =>
    `/bi/${accountId}/looker-dashboards/${dashboardId}/copy`,
  DELETE_BI_DASHBOARD: (accountId: string, dashboardId: string) =>
    `/bi/${accountId}/looker-dashboards/${dashboardId}`,
  GET_SIGNED_EMBED_URL: (accountId: string) =>
    `/bi/${accountId}/user-looker-embed`,
  GET_NOTIFICATION_SETTINGS_BY_USER: (accountId: string, userId: string) =>
    `/notification_settings/accounts/${accountId}/users/${userId}`,
  GET_ACCOUNT_NOTIFICATION_SETTINGS: (accountId: string) =>
    `/notification_settings/accounts/${accountId}`,
  UPDATE_NOTIFICATION_ALERT: (
    accountId: string,
    userId: string,
    alert: string
  ) => `/notification_settings/accounts/${accountId}/users/${userId}/${alert}`,
  UPDATE_USER_NOTIFICATIONS: (accountId: string, userId: string) =>
    `/notification_settings/accounts/${accountId}/users/${userId}`,
  UPDATE_USER_ROLE: (accountId: string, userId: string) =>
    `/accounts/${accountId}/users/${userId}/role`,
  CONNECT_WALMART_AD_DATA: (accountId: string) =>
    `/accounts/${accountId}/walmart-api-ads-connect`,
  CONNECT_WALMART_SELLER_PRODUCTS: (accountId: string) =>
    `/accounts/${accountId}/walmart-3p-products-connect-v2`,
  ACCOUNT_RELATIONSHIPS: (accountId: string) =>
    `/account_relationships/${accountId}`,
  PARENT_ACCOUNT: (accountId: string) =>
    `/account_relationships/child/${accountId}`,
  CONNECT_WALMART_SELLER_PRODUCTS_1P: (accountId: string) =>
    `/accounts/${accountId}/walmart-1p-products-connect-v2`,
  GET_AMC_INSTANCES: (accountId: string) => `/bi/${accountId}/amc-instances`,
});

export const createFAMApiClient = (token: IdToken): FAMApiClient => {
  const config: RawAxiosRequestConfig = {
    baseURL: REACT_APP_FAM_HOST,
    headers: {},
  };

  if (process.env.REACT_APP_INCLUDE_API_GATEWAY_AUTH !== 'true') {
    // This will be removed when the code is minimized in staging and prod builds
    config.headers = {
      ...getDefaultHeaders(),
      'Teika-Verified-User': base64url.encode(
        JSON.stringify({
          version: '1.0',
          body: {
            email: token.email,
            ext_id: token.sub,
          },
        })
      ),
    };
  } else {
    config.headers = {
      ...getDefaultHeaders(),
      Authorization: `Bearer ${token?.__raw}`,
    };
  }

  const createAccount = (
    creationMethod: string,
    signUpData?: SignUpData
  ): Promise<Account> => {
    const signUpMethod = getSignUpMethod(token);
    const payload = Object.assign(
      {
        userEmail: token.email,
        userAuth0Id: token.sub,
        firstName: signUpData?.first_name || token.given_name,
        lastName: signUpData?.last_name || token.family_name,
        companyName: signUpData?.business_name?.trim() || '',
        creationMethod,
      },
      signUpMethod && {
        signupMethod: signUpMethod,
      }
    );

    return axios
      .post<Account>(PATHS.ACCOUNTS, payload, config)
      .then((res) => res.data);
  };

  const getAccountDetails = (accountId: string): Promise<Account> =>
    axios
      .get(PATHS.ACCOUNT_DETAILS(accountId), config)
      .then((res) => res.data)
      .catch(() => undefined);

  const subscriptionsAdd = (
    accountId: string,
    productVerticals: string[]
  ): Promise<void> =>
    axios
      .post<void>(
        PATHS.SUBSCRIPTIONS_APPEND(accountId),
        productVerticals.map((productVertical) => ({
          productVerticalId: productVertical,
        })),
        config
      )
      .then(noop);

  const getUserDetails = (): Promise<UserDetails | undefined> =>
    axios
      .get<UserDetails>(PATHS.USERS(token.email!), config)
      .then((res) => res.data)
      .catch(() => undefined);

  const getProductVerticals = (): Promise<ProductVertical[]> =>
    axios
      .get<ProductVertical[]>(PATHS.PRODUCT_VERTICALS, config)
      .then((res) => res.data);

  const triggerVerificationEmail = (): Promise<void> =>
    axios
      .post<void>(PATHS.USERS_EMAIL_VERIFY(token.email!), undefined, config)
      .then(noop);

  const triggerPasswordResetEmail = (): Promise<boolean> =>
    axios
      .post<void>(PATHS.USERS_PASSWORD_RESET(token.email!), undefined, config)
      .then(() => true)
      .catch(() => false);

  const updateUsername = (
    firstName: string,
    lastName: string
  ): Promise<boolean> =>
    axios
      .patch(PATHS.PATCH_USER(token.email!), { firstName, lastName }, config)
      .then(() => true)
      .catch(() => false);

  const emailVerified = (): Promise<void> =>
    axios
      .put(PATHS.USERS_EMAIL_VERIFIED(token.email!), undefined, config)
      .then(noop);

  const sendInitialWalmartSyncEmail = (
    accountId: string,
    email?: string
  ): Promise<void> =>
    axios
      .post(
        PATHS.SEND_INITIAL_WALMART_SYNC_EMAIL(accountId),
        email ? { email } : undefined,
        config
      )
      .then(noop);

  const createUser = (
    accountId: string,
    userData: UserCreateRequestType
  ): Promise<UserResponseType> =>
    axios
      .post(PATHS.ACCOUNT_USERS(accountId), userData, config)
      .then((res) => res.data);

  const addAmazonAdsConnect = (
    accountId: string,
    authCode: string
  ): Promise<AmazonAdsConnectResult> =>
    axios
      .post(PATHS.AMAZON_ADS_CONNECT(accountId), { authCode }, config)
      .then((res) => res.data);

  const amazonSellingPartnerConnect = (
    accountId: string,
    regionId: string,
    sellingPartnerId: string,
    merchantType: string,
    spApiOauthCode: string
  ): Promise<AmazonAdsConnectResult> =>
    axios
      .post(
        PATHS.AMAZON_SELLING_PARTNER_CONNECT(accountId),
        { regionId, sellingPartnerId, merchantType, spApiOauthCode },
        config
      )
      .then((res) => res.data)
      .catch((err) => err);

  const amazon1pSellingPartnerConnect = (
    accountId: string,
    mcIds: string[],
    regionId: string,
    refreshToken: string,
    vendorGroupId: string
  ): Promise<void> =>
    axios
      .post(
        PATHS.AMAZON_1P_SELLING_PARTNER_CONNECT(accountId),
        { mcIds, regionId, refreshToken, vendorGroupId },
        config
      )
      .then(noop)
      .catch((err) => err);

  const merchantConnect = (
    accountId: string,
    correlationId: string
  ): Promise<MerchantCorrelationResponseType> =>
    axios
      .get(PATHS.MERCHANT_CONNECT(accountId, correlationId), config)
      .then((res) => res.data);

  const claimMerchantCountries = (
    accountId: string,
    ids: string[]
  ): Promise<MerchantClaimResponseType> =>
    axios
      .post(PATHS.CLAIM_MERCHANT_COUNTRIES(accountId), { ids }, config)
      .then((res) => res.data);

  const acceptInvite = (accountId: string): Promise<void> =>
    axios
      .patch(
        PATHS.ACCOUNT_USER(accountId, token.email!),
        { state: 'active' },
        config
      )
      .then(noop)
      .catch((err) => err);

  const connectProductSyncForm = (
    accountId: string,
    formData: ProductDataSyncRequestType
  ): Promise<WalmartProductConnectResult> =>
    axios
      .post(PATHS.CONNECT_PRODUCT_SYNC_FORM(accountId), formData, config)
      .then((res) => res.data);

  const connectWalmartSellerProducts1P = (
    accountId: string,
    formData: ProductDataSyncRequestType1P
  ): Promise<WalmartProductConnectResult1P> =>
    axios
      .post(
        PATHS.CONNECT_WALMART_SELLER_PRODUCTS_1P(accountId),
        formData,
        config
      )
      .then((res) => res.data);

  const getSalesChannelTableData =
    (accountId: string, salesChannelId: string) =>
    (
      request: PaginatedRequest
    ): Promise<AccountSalesChannelPaginatedResult> => {
      const allParams = getPaginationParamsFromRequest(request);
      const params = filterEmptyValues(allParams);

      return axios
        .get<SalesChannelResponse>(
          `${PATHS.SALES_CHANNELS(
            accountId
          )}/${salesChannelId}/merchant-countries`,
          { ...config, params }
        )
        .then(({ data }) => {
          const { filteredElements, elements } = data;
          return {
            totalItems: filteredElements,
            items: elements,
          };
        });
    };

  const getAllSalesChannels = (): Promise<AllSalesChannel[]> =>
    axios.get(PATHS.GET_ALL_SALES_CHANNELS(), config).then((res) => res.data);

  const updateSalesChannel = (
    data: SalesChannelUpdateData,
    accountId: string,
    merchantCountryId: string
  ): Promise<AxiosResponse<SalesChannelData>> =>
    axios.patch<SalesChannelData>(
      PATHS.MERCHANT_COUNTRY(accountId, merchantCountryId),
      data,
      config
    );

  const enableAoForSalesChannels = (
    accountId: string,
    merchantCountryIds: string[]
  ): Promise<SalesChannelData[]> => {
    const updateSalesChannelRequests: Promise<
      AxiosResponse<SalesChannelData>
    >[] = merchantCountryIds.map<Promise<AxiosResponse<SalesChannelData>>>(
      (merchantCountryId) =>
        updateSalesChannel(
          { aoEnabled: true },
          accountId,
          merchantCountryId
        ).catch((err) => err)
    );

    return Promise.all(updateSalesChannelRequests).then((res) =>
      res.map((r) => r.data)
    );
  };

  const fetchUserFromAccount = (
    accountId: string,
    email: string
  ): Promise<UserFetchedResponseType> =>
    axios
      .get(PATHS.ACCOUNT_USER(accountId, email), config)
      .then((res) => res.data);

  const removeUserFromAccount = (
    accountId: string,
    email: string
  ): Promise<void> =>
    axios.delete(PATHS.ACCOUNT_USER(accountId, email), config).then(noop);

  const getUsers = (
    accountId: string
  ): PaginatedDataFetcher<UserResponseType> =>
    createPaginatedApiDataFetcher<UserResponseType>({
      endpoint: PATHS.ACCOUNT_USERS(accountId),
      headers: config.headers,
      baseURL: REACT_APP_FAM_HOST,
    });

  const resendInvite = (accountId: string, email: string): Promise<void> =>
    axios
      .patch(PATHS.ACCOUNT_USER(accountId, email), { state: 'pending' }, config)
      .then(noop);

  const removeProductSubscription = (
    accountId: string,
    subscriptionId: string
  ): Promise<ProductVertical[]> =>
    axios
      .delete(PATHS.PRODUCT_SUBSCRIPTION(accountId, subscriptionId), config)
      .then((res) => res.data);

  const getProductSubscriptionEvents = (
    accountId: string,
    productVerticalId: string
  ): Promise<ProductSubscriptionEventResponse> =>
    axios
      .get(
        PATHS.PRODUCT_SUBSCRIPTION_EVENTS(accountId, productVerticalId),
        config
      )
      .then((res) => res.data);

  const getMerchantCountries = (
    accountId: string,
    channelIds: string[]
  ): Promise<MerchantCountriesResponse> =>
    axios
      .post<MerchantCountriesResponse>(
        PATHS.MERCHANT_COUNTRIES(accountId),
        { ids: channelIds },
        config
      )
      .then((res) => res.data)
      .catch(() => ({ merchantCountryInfoItems: [] }));

  const updateCompanyName = (
    accountId: string,
    companyName: string
  ): Promise<Account | undefined> =>
    axios
      .patch(PATHS.ACCOUNT_DETAILS(accountId), { companyName }, config)
      .then((res) => res.data)
      .catch((error) => undefined);

  const getSignedLookerEmbedUrl = (
    accountId: string,
    embedUrl: string
  ): Promise<LookerEmbedUrlResponse> =>
    axios
      .get(
        `${PATHS.LOOKER_EMBED(accountId)}?embed_url=${encodeURIComponent(
          embedUrl
        )}`,
        config
      )
      .then((res) => res.data);

  const setupCompleted = (accountId: string): Promise<Account | undefined> =>
    axios
      .patch(PATHS.SETUP_COMPLETED(accountId), {}, config)
      .then((res) => res.data)
      .catch(() => undefined);

  const getLatestLookerReport = (
    accountId: string,
    date: string
  ): Promise<LookerReportResponseBody[] | undefined> =>
    axios
      .get<AllLookerReportsResponseBody>(
        `${PATHS.LOOKER_REPORTS(accountId)}?start_date=${date}`,
        config
      )
      .then((res) => res.data.reports)
      .catch(() => undefined);

  const createLookerReport = (
    accountId: string,
    requestBody: CreateLookerReportRequestBody
  ): Promise<LookerReportResponseBody | undefined> =>
    axios
      .post<LookerReportResponseBody>(
        PATHS.LOOKER_REPORTS(accountId),
        requestBody,
        config
      )
      .then((res) => res.data)
      .catch(() => undefined);

  const getLookerReportStatus = (
    accountId: string,
    reportId: string
  ): Promise<ReportState | undefined> =>
    axios
      .get<LookerReportResponseBody>(
        PATHS.LOOKER_REPORT_STATUS(accountId, reportId),
        config
      )
      .then((res) => res.data.state)
      .catch(() => undefined);

  const downloadReportFile = (
    props: DownloadLookerReportApiProps
  ): Promise<AxiosResponse> =>
    axios.get(
      PATHS.LOOKER_REPORT_DOWNLOAD(props.accountId, props.reportId),
      config
    );

  const createOpportunityAnalyzerReport = (
    accountId: string,
    requestBody: CreateLookerReportRequestBody
  ): Promise<LookerReportResponseBody> =>
    axios
      .post(PATHS.OPPORTUNITY_ANALYZER_REPORT(accountId), requestBody, config)
      .then((res) => res.data);

  const getOpportunityAnalyzerReportStatus = (
    accountId: string,
    reportId: string
  ): Promise<ReportState | undefined> =>
    axios
      .get<LookerReportResponseBody>(
        PATHS.OPPORTUNITY_ANALYZER_REPORT_STATUS(accountId, reportId),
        config
      )
      .then((res) => res.data.state)
      .catch(() => undefined);

  const downloadOpportunityAnalyzerReport = (
    accountId: string,
    reportId: string
  ): Promise<AxiosResponse> =>
    axios.get(
      PATHS.OPPORTUNITY_ANALYZER_REPORT_DOWNLOAD(accountId, reportId),
      config
    );

  const downloadS3File = (url: string): Promise<AxiosResponse<Blob>> =>
    axios.get(url, {
      responseType: 'blob',
    });

  const getDataSyncStatus = (
    accountId: string
  ): Promise<DataSyncStatusResponseType> =>
    axios
      .get(PATHS.DATA_SYNC_STATUS(accountId), config)
      .then((res) => res.data.data)
      .catch(() => ({}));

  const sendFormToHubspot = (
    formId: string,
    userDetails: UserDetails,
    options?: { annualGMV: string; connectToSales: string }
  ): Promise<void> => {
    const hubspotUtk =
      Fw2LocalStorage.getItem('hubspotutk') ||
      FWCookie.readCookie(PERSISTED_KEYS.hubspotutk);

    const profileFields = [
      {
        objectTypeId: '0-1',
        name: 'email',
        value: userDetails.email,
      },
      {
        objectTypeId: '0-1',
        name: 'firstname',
        value: userDetails.firstName,
      },
      {
        objectTypeId: '0-1',
        name: 'lastname',
        value: userDetails.lastName,
      },
    ];

    return axios
      .post(PATHS.SEND_FORM_TO_HUBSPOT(HUBSPOT_PORTAL_ID!, formId), {
        fields: options?.annualGMV
          ? [
              ...profileFields,
              {
                objectTypeId: '0-1',
                name: 'annual_gmv_range',
                value: options?.annualGMV,
              },
              {
                objectTypeId: '0-1',
                name: 'connect_to_sales',
                value: options?.connectToSales,
              },
            ]
          : profileFields,
        context: {
          hutk: hubspotUtk,
        },
      })
      .then(noop);
  };

  const getMerchantCountriesLatestConnections = (
    accountId: string
  ): Promise<LatestConnection[]> =>
    axios
      .get(PATHS.GET_MERCHANT_COUNTRIES_LATEST_CONNECTIONS(accountId), config)
      .then((res) => res.data);

  const trackLoggedInUser = (accountId: string): Promise<void> =>
    axios
      .post(PATHS.TRACK_LOGGED_IN_USER(accountId), undefined, config)
      .then(noop);

  const getLeadAnalysts = (accountId: string): Promise<AccountLeadAnalysts> =>
    axios
      .get<AccountLeadAnalysts>(PATHS.LEAD_ANALYSTS(accountId), config)
      .then((res) => res.data);

  const addAccountMetaData = (
    accountId: string,
    metaTag: string,
    metaValue: string
  ): Promise<void> =>
    axios
      .post<void>(
        PATHS.ADD_ACCOUNT_METADATA(accountId),
        { tag: metaTag, value: metaValue },
        config
      )
      .then(noop)
      .catch(() => undefined);

  const getBIDashboards = (
    accountId: string
  ): Promise<BIDashboardsResponseBody> =>
    axios
      .get<BIDashboardsResponseBody>(PATHS.GET_BI_DASHBOARDS(accountId), config)
      .then((res) => res.data);

  const getCustomBIDashboards = (
    accountId: string,
    bypassCache?: boolean
  ): Promise<BICustomDashboardsResponseBody> =>
    axios
      .get<BICustomDashboardsResponseBody>(
        PATHS.GET_CUSTOM_BI_DASHBOARDS(accountId),
        {
          ...config,
          params: { bypass_cache: bypassCache },
        }
      )
      .then((res) => res.data);

  const createBIDashboard = (
    accountId: string,
    dashboardName: string
  ): Promise<string> =>
    axios
      .post(PATHS.CREATE_BI_DASHBOARD(accountId), { dashboardName }, config)
      .then((res) => res.data.id);

  const copyBIDashboard = (
    accountId: string,
    dashboardId: string,
    destinationFolder: BIFolderType
  ): Promise<string> =>
    axios
      .post(
        PATHS.COPY_BI_DASHBOARD(accountId, dashboardId),
        { destinationFolder },
        config
      )
      .then((res) => res.data.id);

  const deleteBIDashboard = (
    accountId: string,
    dashboardId: string
  ): Promise<string> =>
    axios
      .delete(PATHS.DELETE_BI_DASHBOARD(accountId, dashboardId), config)
      .then((res) => res.data.id);

  const getSignedLookerEmbedUrlV2 = (
    accountId: string,
    embedUrl: string
  ): Promise<LookerEmbedUrlResponse> =>
    axios
      .get(
        `${PATHS.GET_SIGNED_EMBED_URL(
          accountId
        )}?embed_url=${encodeURIComponent(embedUrl)}`,
        config
      )
      .then((res) => res.data);

  const getNotificationSettingsByUser = async (
    accountId: string,
    userId: string
  ): Promise<NotificationSettingsResponse> =>
    axios
      .get<NotificationSettingsResponse>(
        PATHS.GET_NOTIFICATION_SETTINGS_BY_USER(accountId, userId),
        config
      )
      .then((res) => res.data);

  const getAccountNotificationSettings = async (
    accountId: string
  ): Promise<NotificationAccountSettingsResponse> =>
    axios
      .get<NotificationAccountSettingsResponse>(
        PATHS.GET_ACCOUNT_NOTIFICATION_SETTINGS(accountId),
        config
      )
      .then((res) => res.data);

  const updateNotificationAlert = async (
    accountId: string,
    userId: string,
    alert: string,
    payload: NotificationAlertUpdateRequestPayload
  ): Promise<boolean> =>
    axios
      .patch(
        PATHS.UPDATE_NOTIFICATION_ALERT(accountId, userId, alert),
        payload,
        config
      )
      .then(() => true)
      .catch(() => false);
  const updateUserNotifications = (
    accountId: string,
    userId: string,
    isNotificationsEnabled: boolean
  ): Promise<void> =>
    axios
      .patch<void>(
        PATHS.UPDATE_USER_NOTIFICATIONS(accountId, userId),
        { enabled: isNotificationsEnabled },
        config
      )
      .then((res) => res.data);

  const updateUserRole = (
    accountId: string,
    userId: string,
    roleId: string
  ): Promise<void> =>
    axios
      .patch<void>(
        PATHS.UPDATE_USER_ROLE(accountId, userId),
        { roleId },
        config
      )
      .then((res) => res.data);

  const connectWalmartAdData = (
    accountId: string,
    request: WalmartAdsConnectRequest
  ): Promise<WalmartAdsConnectResponse> =>
    axios
      .put(PATHS.CONNECT_WALMART_AD_DATA(accountId), request, config)
      .then((res) => res.data);

  const getKnockToken = (email: string) =>
    axios.get(PATHS.KNOCK_TOKEN(email), config).then((res) => res.data);

  const connectWalmartSellerProducts = (
    accountId: string,
    request: WalmartSellerProductsRequestBody
  ): Promise<WalmartSellerProductsResponseType> =>
    axios
      .post(PATHS.CONNECT_WALMART_SELLER_PRODUCTS(accountId), request, config)
      .then((res) => res.data);

  const getAccountRelationships = (accountId: string) =>
    axios
      .get(PATHS.ACCOUNT_RELATIONSHIPS(accountId), config)
      .then((res) => res.data);

  const getParentAccount = (accountId: string) =>
    axios
      .get(PATHS.PARENT_ACCOUNT(accountId), config)
      .then((res) => res.data.parentAccount);

  const createAccountRelationship = (
    accountId: string,
    childAccountName: string
  ) =>
    axios
      .post(
        PATHS.ACCOUNT_RELATIONSHIPS(accountId),
        { childAccountName },
        config
      )
      .then((res) => res.data);

  const getMetadataTags = (
    accountId: string,
    tag?: string
  ): Promise<MetadataResponse[]> =>
    axios
      .get(PATHS.ADD_ACCOUNT_METADATA(accountId), {
        ...config,
        params: { tag },
      })
      .then((res) => {
        return res.data?.metadata || [];
      });

  const getAllMetadataTags = (accountId: string): Promise<MetadataResponse[]> =>
    axios.get(PATHS.ADD_ACCOUNT_METADATA(accountId), config).then((res) => {
      return res.data?.metadata || [];
    });

  const getAmcInstances = (accountId: string): Promise<BIAmcInstanceResponse> =>
    axios
      .get<BIAmcInstanceResponse>(PATHS.GET_AMC_INSTANCES(accountId), config)
      .then((res) => res.data);

  return {
    createAccount,
    getAccountDetails,
    subscriptionsAdd,
    getUserDetails,
    triggerVerificationEmail,
    triggerPasswordResetEmail,
    emailVerified,
    getProductVerticals,
    getSalesChannelTableData,
    getAllSalesChannels,
    updateSalesChannel,
    enableAoForSalesChannels,
    sendInitialWalmartSyncEmail,
    createUser,
    acceptInvite,
    fetchUserFromAccount,
    removeUserFromAccount,
    connectProductSyncForm,
    connectWalmartSellerProducts1P,
    updateUsername,
    getUsers,
    resendInvite,
    removeProductSubscription,
    getProductSubscriptionEvents,
    getMerchantCountries,
    updateCompanyName,
    addAmazonAdsConnect,
    amazonSellingPartnerConnect,
    merchantConnect,
    claimMerchantCountries,
    getSignedLookerEmbedUrl,
    amazon1pSellingPartnerConnect,
    setupCompleted,
    getLatestLookerReport,
    createLookerReport,
    getLookerReportStatus,
    downloadReportFile,
    createOpportunityAnalyzerReport,
    getOpportunityAnalyzerReportStatus,
    downloadOpportunityAnalyzerReport,
    downloadS3File,
    getDataSyncStatus,
    sendFormToHubspot,
    getMerchantCountriesLatestConnections,
    trackLoggedInUser,
    getLeadAnalysts,
    addAccountMetaData,
    getBIDashboards,
    getCustomBIDashboards,
    createBIDashboard,
    copyBIDashboard,
    deleteBIDashboard,
    getSignedLookerEmbedUrlV2,
    getNotificationSettingsByUser,
    updateNotificationAlert,
    getAccountNotificationSettings,
    updateUserNotifications,
    updateUserRole,
    connectWalmartAdData,
    getKnockToken,
    connectWalmartSellerProducts,
    getAccountRelationships,
    createAccountRelationship,
    getParentAccount,
    getMetadataTags,
    getAllMetadataTags,
    getAmcInstances,
  };
};
