import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeCurrencyColumn,
  makeNumericInputColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  AdGroupStatus,
  AdLevel,
  AdType,
  CampaignTargetingType,
  DetailAdGroup,
  FlywheelSalesChannel,
  MerchantCountryCode,
  TeikaSettingsAdgroup,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import {
  ADGROUP_DEFAULT_BID_FALLBACK_PLACEHOLDER_VALUE,
  AdLevelI8nKeyMapper,
  CURRENCY_CODE,
  TableDataAdsManager,
} from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getAutomationDisabledForLegacyCampaignTooltipMessage,
  getAutomationDisabledTooltipMessage,
  getOrDefaultIsBiddableFlag,
  getTooltipContentForArchivedEntity,
  isAdGroupStatusArchived,
  isCampaignAdFormatEqualToVideo,
  isCampaignCostTypeVcpm,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
  isInputValueNumber,
  isValidFlywheelSetting,
  isValidMaxBid,
} from '../utils';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import {
  getCurrencyCodeFromMerchantCountryCode,
  getCurrencySymbolFromMerchantCountryCode,
} from '../../../../../lib/utilities/currency';

const getControlledTooltipState = (
  isAdGroupArchived: boolean,
  focussed: boolean,
  maxBidNumericInputState: NumericInputState
) => {
  if (isAdGroupArchived) {
    return ControlledTooltip.None;
  }

  return focussed && maxBidNumericInputState === NumericInputState.Error
    ? ControlledTooltip.Show
    : ControlledTooltip.Hide;
};

const getTooltipContent = (
  intl: IntlShape,
  value: string,
  isInValidSetting: boolean,
  minAndMaxBidValues: {
    minBidValue: number | undefined;
    maxBidValue: number | undefined;
  },
  tooltipParameters: {
    isCampaignCostTypeEqualToVcpm: boolean;
    automationDisabledForMerchant: boolean;
    automationDisabledForLegacyRow: boolean;
    isAdGroupArchived: boolean;
    isValueLessThanMinBidValue: boolean;
    isValueGreaterThanMaxBidValue: boolean;
    isValueLessThanCurrentMinBidValue: boolean;
  },
  currencySymbol: string | undefined
) => {
  if (tooltipParameters.isCampaignCostTypeEqualToVcpm) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_FLYWHEEL_SETTINGS_UNSET_TOOLTIP,
        })}
      </p>
    );
  }

  if (tooltipParameters.automationDisabledForMerchant) {
    return getAutomationDisabledTooltipMessage(
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MAX_BID,
      })
    );
  }

  if (tooltipParameters.automationDisabledForLegacyRow) {
    return getAutomationDisabledForLegacyCampaignTooltipMessage();
  }

  if (tooltipParameters.isAdGroupArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.AdGroups],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MAX_BID,
      }),
      intl
    );
  } else if (isInValidSetting) {
    if (
      minAndMaxBidValues.minBidValue &&
      minAndMaxBidValues.maxBidValue &&
      (tooltipParameters.isValueLessThanMinBidValue ||
        tooltipParameters.isValueGreaterThanMaxBidValue ||
        isEmpty(value))
    ) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_BID,
            },
            {
              minValue: intl.formatNumber(minAndMaxBidValues.minBidValue, {
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
              maxValue: intl.formatNumber(minAndMaxBidValues.maxBidValue),
              currencySymbol,
            }
          )}
        </p>
      );
    }
    if (tooltipParameters.isValueLessThanCurrentMinBidValue) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_INVALID_LESS_THAN_MIN_BID,
          })}
        </p>
      );
    }
  }
};

const getExtraValidations = (
  flywheelSalesChannel: FlywheelSalesChannel,
  adGroupStatus: AdGroupStatus | undefined,
  adGroupDetails: DetailAdGroup
) => {
  if (flywheelSalesChannel === FlywheelSalesChannel.Amazon) {
    return () =>
      !isAdGroupStatusArchived(adGroupStatus) &&
      !isCampaignStatusArchived(adGroupDetails.campaignStatus);
  }
};

const getMaxBidNumericInputState = (
  bidValue: { value: string; existingValue: string },
  minAndMaxBidValues: {
    minBidValue: number | undefined;
    maxBidValue: number | undefined;
  },
  updatedCurrentMinBidValue: string | number | undefined,
  isInputDisabled: boolean,
  isValidSettings: boolean,
  isInputEdited: boolean,
  extraValidations: (() => boolean) | undefined
) => {
  if (isInputDisabled) {
    return NumericInputState.Disabled;
  }

  if (!isValidSettings) {
    return NumericInputState.Error;
  }

  if (isInputEdited) {
    return isValidMaxBid(
      String(bidValue.value),
      String(minAndMaxBidValues.maxBidValue),
      String(minAndMaxBidValues.minBidValue),
      String(updatedCurrentMinBidValue),
      extraValidations,
      bidValue.existingValue
    )
      ? NumericInputState.Default
      : NumericInputState.Error;
  }

  return NumericInputState.Default;
};

const getExistingValue = (flywheelSettings: TeikaSettingsAdgroup) =>
  flywheelSettings.maxBid?.amount?.toString() || '';

const isValueDirty = (value: string, existingValue: string) =>
  value || existingValue
    ? parseFloat(value) !== parseFloat(existingValue)
    : false;

const getCurrencySymbol = (
  merchantCountry: MerchantCountryCode,
  flywheelSettings: TeikaSettingsAdgroup
) =>
  getCurrencySymbolFromMerchantCountryCode(merchantCountry) ||
  flywheelSettings.maxBid?.currency;

const getCurrencyCode = (
  currencyCode: string | null,
  details: AdGroupDetails
) =>
  currencyCode || details?.flywheelSettings?.maxBid?.currency || CURRENCY_CODE;

const getPlaceholderValue = (defaultMaxBidValue: number | undefined) =>
  defaultMaxBidValue || ADGROUP_DEFAULT_BID_FALLBACK_PLACEHOLDER_VALUE;

const getUpdatedCurrentMinBidValue = (
  currentMinBid: string,
  flywheelSettings: TeikaSettingsAdgroup,
  minBidValue: number | undefined
) => currentMinBid || flywheelSettings.minBid?.amount || minBidValue;

const getIsAutomationDisabledForMerchant = (
  merchantsWithAutomationEnabled: string[] | undefined,
  adGroupDetails: DetailAdGroup
) =>
  !merchantsWithAutomationEnabled?.includes(
    adGroupDetails.merchantCountryId ?? ''
  );

const checkIfInputIdDisabled = (
  adGroupStatus: AdGroupStatus | undefined,
  adGroupDetails: DetailAdGroup,
  isCampaignCostTypeEqualToVcpm: boolean,
  automationDisabledForMerchant: boolean,
  automationDisabledForLegacyRow: boolean
) => {
  return (
    isAdGroupStatusArchived(adGroupStatus) ||
    isCampaignStatusArchived(adGroupDetails.campaignStatus) ||
    isCampaignCostTypeEqualToVcpm ||
    automationDisabledForMerchant ||
    automationDisabledForLegacyRow
  );
};

export const shouldUpdateValue = (
  isEditMode: boolean,
  updatedMaxBid: string,
  value: string
) => {
  return (
    isEditMode || //Changing between modes
    (!isNil(updatedMaxBid) && updatedMaxBid !== value) // Changes done due to bulk update. updatedMaxBid is latest, but state variable is not
  );
};

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  adGroupId,
  adGroupDetails,
  channelSettings,
  flywheelSettings,
  adGroupPerformance,
  isEditMode,
  merchantCountry,
  salesChannel,
  selectedAdType,
  merchantType,
  merchantsWithAutomationEnabled,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);

  const bidConstraintsData = useBidConstraintsContext();

  const currentPage = useSelector<WithTable<AdGroupDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_ADGROUP_TABLE_ID
      )
  );

  const data: AdGroupDetails = {
    adGroupId,
    adGroupDetails,
    channelSettings,
    flywheelSettings,
    adGroupPerformance,
  };

  const { campaignCostType } = adGroupDetails;

  const updatedMaxBid = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MaxBid
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const existingValue = getExistingValue(flywheelSettings);

  let maxBid = updatedMaxBid ?? existingValue;

  const isInputEdited = existingValue !== maxBid;
  const isCampaignCostTypeEqualToVcpm = useMemo(
    () => isCampaignCostTypeVcpm(campaignCostType),
    [campaignCostType]
  );

  const [value, setValue] = useState<string>(maxBid);

  const isDirty = useMemo(
    () => isValueDirty(value, existingValue),
    [value, existingValue]
  );

  useEffect(() => {
    if (shouldUpdateValue(isEditMode, updatedMaxBid, value)) {
      setValue(maxBid);
    }
  }, [isEditMode, currentPage, updatedMaxBid]);

  const currencySymbol = getCurrencySymbol(merchantCountry, flywheelSettings);

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  const campaignTargetingType: CampaignTargetingType | undefined = data
    .adGroupDetails.campaignTargetingType as CampaignTargetingType;

  const {
    minBid: minBidValue,
    maxBid: maxBidValue,
    defaultMaxBid: defaultMaxBidValue,
  } = useMemo(
    () =>
      getBidConstraint(
        bidConstraintsData.constraints,
        isCampaignAdFormatEqualToVideo(adGroupDetails.campaignAdFormat)
          ? AdType.SponsoredBrandsVideo
          : selectedAdType,
        salesChannel,
        ADGROUPS_API_COLUMN_NAME.MaxBid,
        merchantCountry,
        campaignTargetingType,
        merchantType
      ),
    [
      selectedAdType,
      salesChannel,
      merchantCountry,
      campaignTargetingType,
      merchantType,
      adGroupDetails.campaignAdFormat,
    ]
  );

  const currentMinBid = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MinBid
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const updatedCurrentMinBidValue = getUpdatedCurrentMinBidValue(
    currentMinBid,
    flywheelSettings,
    minBidValue
  );

  const currentMacsTarget = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MACSTarget
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const adGroupStatus = channelSettings.status;

  const updateCellValue = useCallback(
    (inputValue: string) => {
      dispatch(
        tableActions.updateCell({
          tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
          rowId: data.adGroupId,
          columnName: ADGROUPS_API_COLUMN_NAME.MaxBid,
          value: inputValue,
          existingValue,
          numericValue: true,
        })
      );
    },
    [existingValue, data.adGroupId]
  );

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [data.adGroupId]
  );

  const isValidSettings = useMemo(
    () =>
      isValidFlywheelSetting(
        flywheelSettings,
        {
          minBid: currentMinBid,
          maxBid: maxBid,
          macsTarget: currentMacsTarget,
        },
        maxBid
      ),
    [flywheelSettings, currentMinBid, maxBid, currentMacsTarget]
  );

  if (isEditMode) {
    const isValueGreaterThanMaxBidValue =
      isCurrentValueGreaterThanRequiredMaxValue(maxBidValue, value);
    const isValueLessThanMinBidValue = isCurrentValueLessThanRequiredMinValue(
      minBidValue,
      value
    );
    const isValueLessThanCurrentMinBidValue =
      isCurrentValueLessThanRequiredMinValue(
        Number(updatedCurrentMinBidValue),
        value
      );

    const automationDisabledForMerchant = getIsAutomationDisabledForMerchant(
      merchantsWithAutomationEnabled,
      adGroupDetails
    );

    const automationDisabledForLegacyRow = !getOrDefaultIsBiddableFlag(
      adGroupDetails.isBiddable
    );

    const isInputDisabled = checkIfInputIdDisabled(
      adGroupStatus,
      adGroupDetails,
      isCampaignCostTypeEqualToVcpm,
      automationDisabledForMerchant,
      automationDisabledForLegacyRow
    );

    const maxBidNumericInputState: NumericInputState =
      getMaxBidNumericInputState(
        { value, existingValue },
        { minBidValue, maxBidValue },
        updatedCurrentMinBidValue,
        isInputDisabled,
        isValidSettings,
        isInputEdited,
        getExtraValidations(salesChannel, adGroupStatus, adGroupDetails)
      );

    const onMaxBidInputChange = (inputValue: string) => {
      if (isInputValueNumber(inputValue)) {
        setValue(inputValue);
        debouncedUpdateCellValue(inputValue);
      }
    };
    const isAdGroupArchived =
      isAdGroupStatusArchived(channelSettings.status) ||
      isCampaignStatusArchived(adGroupDetails.campaignStatus);

    const onInputFocus = () => setFocussed(true);
    const onInputBlur = () => {
      if (!isEmpty(value)) {
        debouncedUpdateCellValue.cancel();
        const formattedValue = parseFloat(value).toFixed(
          MONETARY_FRACTION_DIGITS
        );
        setValue(formattedValue);
        updateCellValue(formattedValue);
      }
      setFocussed(false);
    };

    const PLACEHOLDER_VALUE = getPlaceholderValue(defaultMaxBidValue);

    const maxBidNumericInputProps: NumericInputProps & TypedTooltipProps = {
      value,
      state: maxBidNumericInputState,
      prependedElement: currencySymbol,
      onChange: onMaxBidInputChange,
      isDirty,
      tooltipContent: getTooltipContent(
        intl,
        value,
        isDirty || !isValidSettings,
        { minBidValue, maxBidValue },
        {
          automationDisabledForLegacyRow,
          automationDisabledForMerchant,
          isAdGroupArchived,
          isCampaignCostTypeEqualToVcpm,
          isValueGreaterThanMaxBidValue,
          isValueLessThanCurrentMinBidValue,
          isValueLessThanMinBidValue,
        },
        currencySymbol
      ),
      controlledTooltip: getControlledTooltipState(
        isAdGroupArchived,
        focussed,
        maxBidNumericInputState
      ),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus: onInputFocus,
      onInputBlur: onInputBlur,
      acceptOnlyPositiveNumbers: true,
      placeholder: intl.formatNumber(PLACEHOLDER_VALUE, {
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      }),
      minFractionDigits: MONETARY_FRACTION_DIGITS,
      maxFractionDigits: MONETARY_FRACTION_DIGITS,
      dataTestId: 'max_bid',
    };

    return makeNumericInputColumn(() => maxBidNumericInputProps)(data);
  }

  return makeCurrencyColumn(
    (details: AdGroupDetails) =>
      details.flywheelSettings.maxBid && {
        amount: Number(details.flywheelSettings.maxBid.amount),
        currency: getCurrencyCode(currencyCode, details),
      }
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

export const maxBidColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.MaxBid,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MAX_BID,
  RowCellElement,
  gridColumnWidth: '160px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const maxBidFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    ADGROUPS_API_COLUMN_NAME.MaxBid,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MAX_BID,
    currency,
    isValidNumber(0),
    true
  );
