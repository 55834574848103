import React, { useEffect } from 'react';
import Modal from 'react-modal';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { Spinner } from '@teikametrics/tm-design-system';

import { UserProvider } from './containers/userProvider/userProvider';
import LoggedIn from './LoggedIn';
import Logout from './Logout';
import { Route, Routes } from 'react-router-dom';

const App: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  if (isAuthenticated) {
    return (
      <UserProvider>
        <Routes>
          <Route path="/login/callback" element={<AuthCallback />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<LoggedIn />} />
        </Routes>
      </UserProvider>
    );
  } else {
    return <Spinner />;
  }
};
App.displayName = 'App';

const AuthCallback: React.FC = () => <Spinner />;
AuthCallback.displayName = 'AuthCallback';

export default Sentry.withProfiler(
  withAuthenticationRequired(App, {
    returnTo: window.location.href,
    onRedirecting: () => <Spinner />,
  })
);
