import './campaignsTable.scss';

import { fromNullable } from 'fp-ts/lib/Option';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alignment,
  BulkEditConfirmationModal,
  BulkEditSlideOut,
  BulkEditValues,
  ButtonSize,
  ButtonState,
  ColumnManager,
  ColumnManagerOption,
  ColumnManagerVariant,
  DualModes,
  EditControlProps,
  FilterIcon,
  FiltersMenu,
  Placement,
  SearchInputSize,
  TableActionRow,
  Theme,
  Tooltip,
  TooltipSize,
  Variant,
} from '@teikametrics/tm-design-system';
import { useLocation, useNavigate } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import { useBidConstraintsContext } from '../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../containers/bidConstraintsProvider/biddingConstraints';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';
import {
  SaveChangesContextDataProps,
  SaveChangesFlagContext,
  SaveChangesFlagContextState,
} from '../../../../containers/saveChangesFlagProvider';
import UpdatedFlywheelTable, {
  FlywheelTableColumnGroup,
} from '../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
  tableThunks,
} from '../../../../containers/table/ducks';
import {
  TableChange,
  WithTable,
} from '../../../../containers/table/ducks/types';
import { FlywheelSearchInput } from '../../../../containers/tableV2/flywheelSearchInput';
import {
  FileUpload,
  ShowTreUploadButtonIn,
} from '../../../../containers/treUpload';
import {
  getUserRoleMatch,
  isAIPlanEnabled,
} from '../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import {
  PaginatedDataFetcher,
  PaginatedResponseExtraData,
} from '../../../../lib/clients/types';
import { useTime } from '../../../../lib/hooks/useTime';
import { StrictStringMap } from '../../../../lib/types';
import {
  AdGroupDataRequest,
  AdGroupDetails,
  AdLevel,
  AdType,
  AdvertisingGoal,
  AiBiddingValues,
  AiRecommendedKeywordType,
  CampaignCostType,
  EntityType,
  FlywheelSalesChannel,
  Groups,
  MerchantCountryCode,
  REQUEST_DATE_FORMAT,
} from '../../../../lib/types/AOSharedTypes';
import { EstPreAdGrossMarginItem } from '../../../../lib/types/CampaignFlexibilitySharedTypes';
import { MerchantCountry, MerchantType, Role } from '../../../../lib/types/Fam';
import {
  COLUMNS_WITH_SINGLE_DECIMAL_FILTER,
  Filter,
  FilterOps,
} from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import { OptimizelyFlags } from '../../../../lib/types/OptimizelyFlags';
import {
  CurrencyCode,
  getCurrencyCodeFromMerchantCountryCode,
  getCurrencySymbolFromMerchantCountryCode,
} from '../../../../lib/utilities/currency';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../lib/utilities/intercom';
import { MerchantContext, MerchantContextState } from '../../merchantsProvider';
import { areNoChangesDone, upgradeFiltersInStorage } from '../../utils';
import { generateAdGroupColumns } from './adgroupTableColumns';
import { isValidDefaultBid } from './adgroupTableColumns/adgroupDefaultBid';
import { isAdGroupNameColumnDuplicateWithinEditedCellsOfSameCampaign } from './adgroupTableColumns/adgroupName.utils';
import { isMinBidValid } from './adgroupTableColumns/minBid';
import {
  getActionRowButtons,
  getCheckBoxState,
  getEditControlsForAdGroupTable,
  getSelectedBulkMenuValues,
  setBulkChanges,
} from './bulkEditModalUtils';
import {
  BOOLEAN_COLUMNS,
  PERCENTAGE_BASED_COLUMNS,
} from './campaignsTableColumns/campaignName';
import { DATA_INSPECTOR_COLUMN_NAME } from './dataInspectorConfig';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from './ducks/types';
import { ErrorTable } from './errorTable';
import ExportAdLevelData from './exportAdLevelData';
import { KeepEditingModal } from './keepEditingModal';
import { ManageBiddingInternal } from './manageBiddingInternal';
import { NoDataFoundTable } from './noDataFoundTable';
import { NoFilteredDataFoundTable } from './noFilteredDataFound';
import {
  ADGROUP_TABLE_SEARCH_KEY,
  AD_LEVEL_EXTERNAL_ID_MAPPER,
  AdgroupDataApiColumnGroupIdentifier,
  ApiColumnsWithAdLevel,
  BulkEditModalValues,
  BulkEditSelectedOptions,
  CURRENCY_CODE,
  CampaignTargetingType,
  CampaignTypeQuickFilterKey,
  ConfirmationModalClickKind,
  EXT_CAMPAIGN_ID,
  EditedRowInfo,
  MERCHANT_NAME_COLUMN_ID_FOR_EXPORT,
  REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES,
  TableCellChangeAdGroup,
  TableDataAdsManager,
} from './types';
import {
  ADGROUPS_API_COLUMN_NAME,
  ADGROUP_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  AD_LEVEL_API_COLUMNS_TO_EXCLUDE,
  ALL_AD_LEVELS_FILTER_FILED_MAPPER,
  EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
  TABLE_UNIQ_KEY,
  adGroupDetailsFieldsForCSVExport,
  adGroupSmartAcosFieldsForCsvExport,
  adGroupflywheelSettingsFieldsForCSVExport,
  getCampaignTypeQuickFilters,
  getFlywheelSettingsColumn,
  getSearchInputPlaceholder,
  getTransformedAddedByFilter,
  isAdGroupStatusArchived,
  isCampaignAdFormatEqualToVideo,
  isCampaignStatusArchived,
  isCurrentValueLessThanRequiredMinValue,
  isInvalidAdGroupName,
  isValidAdGroupNameCharacterLength,
  isValidMacsTarget,
  isValidMaxBid,
  isValidSmartAcosFwSetting,
  shouldShowManageBidding,
  shouldShowSmartCampaigns,
} from './utils';
import { getFilterColumnDefinitons } from './utils/filterDefinitions';
import {
  SMART_GOALS,
  transformAdvertisingGoal,
} from './adgroupTableColumns/utils';

const TABLE_ID = ADS_MANAGER_ADGROUP_TABLE_ID;
const ARRAY_BASED_COLUMNS: string[] = [ADGROUPS_API_COLUMN_NAME.Tags];

interface VisibilityCriteria {
  readonly enabledByFeatureFlag: boolean;
}

export interface ExtendedAdGroupEditControlProps extends EditControlProps {
  readonly visibilityCriteria?: VisibilityCriteria;
}

interface GlobalCampaignTargetingType {
  [key: string]: CampaignTargetingType;
}

interface TableAdGroupGlobal {
  readonly campaignTargetingType: GlobalCampaignTargetingType;
  readonly currencyCode: StrictStringMap<string>;
}

export interface TableAdGroupProps {
  readonly apiColumnsWithAdLevel: ApiColumnsWithAdLevel;
  readonly tableMode: DualModes;
  readonly aoApiClient: AOApiClient;
  readonly exportButtonState: ButtonState;
  readonly adLevelDropDownSelectedValues: '' | AdLevel;
  readonly activeSliderIndex: number;
  readonly selectedSalesChannelId: string;
  readonly request: () => AdGroupDataRequest;
  readonly dataFetcher: PaginatedDataFetcher<AdGroupDetails>;
  readonly dataFetcherWithoutQuickFilter: PaginatedDataFetcher<AdGroupDetails>;
  readonly setExportButtonState: React.Dispatch<
    React.SetStateAction<ButtonState>
  >;
  readonly toggleTableMode: () => void;
  readonly onEditSave: () => void;
  readonly selectedSalesChannelName: string;
  readonly campaignDetailsFilter?: Filter[];
  readonly onFilterUpdate?: (filters: Filter[]) => void;
  readonly filtersFromBrowserStorage?: Filter[];
  readonly storeFiltersInBrowserStorage?: (filters: Filter[]) => void;
  readonly adTypes: AdType[];
  readonly selectedSalesChannel: FlywheelSalesChannel;
  readonly selectedAdType: AdType;
  readonly merchantCountry: MerchantCountryCode;
  readonly merchantType?: MerchantType;
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly selectedMerchantCountries: Array<number | string> | string;
  readonly allMerchantCountries: MerchantCountry[];
  readonly merchantCountryNameForCSVDownload?: string;
  readonly aiEnabled?: boolean;
  readonly merchantsWithAutomationEnabled: string[];
  readonly onColumnSelectionChange?: (selectedColumns: string[]) => void;
  readonly selectedColumns?: string[];
  readonly columnManagerOptions?: ColumnManagerOption[];
  readonly switchLevelController: (adLevel: string) => void;
  readonly selectedAdLevel?: AdLevel;
  readonly isSaveChangesLoading?: boolean;
  readonly groupNames: Groups[];
  readonly campaignTypeQuickFilter: CampaignTypeQuickFilterKey;
  readonly onQuickFilterClick: (type: string) => void;
  readonly quickFilterData?: PaginatedResponseExtraData;
  readonly setQuickFilterData: (data: PaginatedResponseExtraData) => void;
  readonly cogsDetailsDataFetcher: (
    entityId: string,
    entityType: EntityType
  ) => PaginatedDataFetcher<EstPreAdGrossMarginItem>;
  readonly isProductConnectedForSelectedMerchant?: boolean;
}

export const checkValidFlywheelSetting = (
  currentFieldValue?: string,
  automationStatus?: boolean | string,
  ...otherFieldValues: (number | string | undefined)[]
) => {
  if (!isNil(automationStatus)) {
    return true;
  }
  return !!currentFieldValue && !otherFieldValues.filter((v) => !v).length;
};

const getOmittedAdGroupDetailsFields = (
  adLevelDropDownSelectedValues: '' | AdLevel,
  adGroupDetailsExportColumns: string[],
  selectedSalesChannelName: FlywheelSalesChannel
) =>
  adLevelDropDownSelectedValues
    ? adGroupDetailsExportColumns.filter((currentColumn) => {
        const toBeOmittedValues =
          AD_LEVEL_API_COLUMNS_TO_EXCLUDE[selectedSalesChannelName][
            adLevelDropDownSelectedValues
          ];

        return !toBeOmittedValues.includes(currentColumn);
      })
    : adGroupDetailsExportColumns;

const getMerchantNameColumns = (apiColumnsWithAdLevel: ApiColumnsWithAdLevel) =>
  apiColumnsWithAdLevel.columns.includes(ADGROUPS_API_COLUMN_NAME.MerchantName)
    ? [MERCHANT_NAME_COLUMN_ID_FOR_EXPORT]
    : [];

const getExternalIdColumns = (adLevelDropDownSelectedValues: '' | AdLevel) =>
  adLevelDropDownSelectedValues
    ? AD_LEVEL_EXTERNAL_ID_MAPPER[adLevelDropDownSelectedValues]
    : [];

const getCampaignsIdColumns = (apiColumnsWithAdLevel: ApiColumnsWithAdLevel) =>
  apiColumnsWithAdLevel.columns.includes(ADGROUPS_API_COLUMN_NAME.CampaignName)
    ? [EXT_CAMPAIGN_ID]
    : [];

export const TableAdGroup: React.FC<
  TableAdGroupProps
  // eslint-disable-next-line max-statements
> = ({
  apiColumnsWithAdLevel,
  tableMode,
  aoApiClient,
  exportButtonState,
  adLevelDropDownSelectedValues,
  activeSliderIndex,
  toggleTableMode,
  onEditSave,
  dataFetcher,
  dataFetcherWithoutQuickFilter,
  request,
  setExportButtonState,
  selectedSalesChannelName,
  onFilterUpdate,
  campaignDetailsFilter,
  filtersFromBrowserStorage,
  storeFiltersInBrowserStorage,
  adTypes,
  selectedSalesChannel,
  selectedAdType,
  merchantCountry,
  merchantType,
  startDate,
  endDate,
  selectedMerchantCountries,
  allMerchantCountries,
  merchantCountryNameForCSVDownload,
  aiEnabled,
  merchantsWithAutomationEnabled,
  selectedSalesChannelId,
  columnManagerOptions,
  onColumnSelectionChange = noop,
  selectedColumns,
  switchLevelController,
  selectedAdLevel,
  isSaveChangesLoading,
  groupNames,
  campaignTypeQuickFilter,
  onQuickFilterClick,
  quickFilterData,
  setQuickFilterData,
  cogsDetailsDataFetcher,
  isProductConnectedForSelectedMerchant,
}) => {
  const [shoWBulkEditSlideOut, setShoWBulkEditSlideOut] =
    useState<boolean>(false);
  const [bulkEditValues, setBulkEditValues] = useState<BulkEditModalValues>({});
  const [bulkEditSelectedOptions, setBulkEditSelectedOptions] =
    useState<BulkEditSelectedOptions>({});

  const [clickedOnView, setClickedOnView] = useState<boolean>(false);
  const [editedAdGroupsInfo, setEditedAdGroupsInfo] = useState<EditedRowInfo[]>(
    []
  );
  const [confirmationModalVariant, setConfirmationModalVariant] =
    useState<Variant | null>(null);
  const [isFilterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);

  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef<boolean>(false);
  const firstApiCall = useRef<boolean>(true);
  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);
  const bidConstraintsData = useBidConstraintsContext();

  const showTreUpload =
    optimizelyContext.featureFlags[OptimizelyFlags.TreUpload];

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);
  const currencySymbol =
    getCurrencySymbolFromMerchantCountryCode(merchantCountry);

  const userContext = useContext(UserContext);
  const isAIPlan = isAIPlanEnabled(userContext);

  const isUserRoleViewOnly = getUserRoleMatch(
    Role.VIEW_ONLY,
    userContext.userInfo.userDetails
  );

  const minBidAutoValidationConfig = useMemo(
    () =>
      (() => {
        const constraints = getBidConstraint(
          bidConstraintsData.constraints,
          selectedAdType,
          selectedSalesChannel,
          ADGROUPS_API_COLUMN_NAME.MinBid,
          merchantCountry,
          CampaignTargetingType.Auto,
          merchantType
        );

        return {
          min: constraints.minBid,
          max: constraints.maxBid,
          default: constraints.defaultMinBid,
        };
      })(),
    [selectedAdType, selectedSalesChannel, merchantCountry, merchantType]
  );

  const minBidManualValidationConfig = useMemo(
    () =>
      (() => {
        const constraints = getBidConstraint(
          bidConstraintsData.constraints,
          selectedAdType,
          selectedSalesChannel,
          ADGROUPS_API_COLUMN_NAME.MinBid,
          merchantCountry,
          CampaignTargetingType.Manual,
          merchantType
        );

        return {
          min: constraints.minBid,
          default: constraints.defaultMinBid,
          max: constraints.maxBid,
        };
      })(),
    [selectedAdType, selectedSalesChannel, merchantCountry, merchantType]
  );

  const maxBidManualValidationConfig = useMemo(
    () =>
      (() => {
        const constraints = getBidConstraint(
          bidConstraintsData.constraints,
          selectedAdType,
          selectedSalesChannel,
          ADGROUPS_API_COLUMN_NAME.MaxBid,
          merchantCountry,
          CampaignTargetingType.Manual,
          merchantType
        );

        return {
          min: constraints.minBid,
          default: constraints.defaultMaxBid,
          max: constraints.maxBid,
        };
      })(),
    [selectedAdType, selectedSalesChannel, merchantCountry, merchantType]
  );

  const maxBidAutoValidationConfig = useMemo(
    () =>
      (() => {
        const constraints = getBidConstraint(
          bidConstraintsData.constraints,
          selectedAdType,
          selectedSalesChannel,
          ADGROUPS_API_COLUMN_NAME.MaxBid,
          merchantCountry,
          CampaignTargetingType.Auto,
          merchantType
        );

        return {
          min: constraints.minBid,
          default: constraints.defaultMaxBid,
          max: constraints.maxBid,
        };
      })(),
    [selectedAdType, selectedSalesChannel, merchantCountry, merchantType]
  );

  const adgroupDefaultBidValidationConfigCPC = useMemo(
    () =>
      (() => {
        const constraints = getBidConstraint(
          bidConstraintsData.constraints,
          selectedAdType,
          selectedSalesChannel,
          ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
          merchantCountry,
          undefined,
          merchantType,
          CampaignCostType.CPC
        );

        return {
          min: constraints.minBid,
          default: constraints.defaultMinBid,
          max: constraints.maxBid,
        };
      })(),
    [selectedAdType, selectedSalesChannel, merchantCountry, merchantType]
  );

  const adgroupDefaultBidValidationConfigVCPM = useMemo(
    () =>
      (() => {
        const constraints = getBidConstraint(
          bidConstraintsData.constraints,
          selectedAdType,
          selectedSalesChannel,
          ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
          merchantCountry,
          undefined,
          merchantType,
          CampaignCostType.VCPM
        );

        return {
          min: constraints.minBid,
          default: constraints.defaultMinBid,
          max: constraints.maxBid,
        };
      })(),
    [selectedAdType, selectedSalesChannel, merchantCountry, merchantType]
  );

  const adgroupMacsTargetValidationConfig = useMemo(
    () =>
      (() => {
        const constraints = getBidConstraint(
          bidConstraintsData.constraints,
          selectedAdType,
          selectedSalesChannel,
          ADGROUPS_API_COLUMN_NAME.MACSTarget,
          merchantCountry,
          undefined,
          merchantType
        );

        return {
          min: constraints.minMacsTarget,
          default: constraints.defaultMacsTarget,
          max: constraints.maxMacsTarget,
        };
      })(),
    [selectedAdType, selectedSalesChannel, merchantCountry, merchantType]
  );

  const changedCount = useSelector<WithTable<AdGroupDetails>, number>(
    ({ tableState }) => {
      return tableSelectors.getChangedCountSelector()(tableState, TABLE_ID);
    }
  );

  const { maxMacsTarget: maxMacsTargetValue } = getBidConstraint(
    bidConstraintsData.constraints,
    selectedAdType,
    selectedSalesChannel,
    ADGROUPS_API_COLUMN_NAME.MACSTarget,
    merchantCountry,
    undefined,
    merchantType
  );

  const adGroupTableData = useSelector<
    WithTable<AdGroupDetails>,
    AdGroupDetails[]
  >(({ tableState }) =>
    tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<AdGroupDetails, void>()(
        tableState,
        ADS_MANAGER_ADGROUP_TABLE_ID
      )
    )
  );

  const merchantContext = useContext<MerchantContextState>(MerchantContext);

  const merchantDetails = merchantContext.merchantDetails;

  useEffect(() => {
    updateTableChangeGlobal();
  }, [adGroupTableData]);

  const updateEditedRecords = useCallback(
    (editedRowInfo: EditedRowInfo) => {
      const resultsWithoutEditedRow = editedAdGroupsInfo.filter(
        (adGroupInfo) => adGroupInfo.rowId !== editedRowInfo.rowId
      );
      setEditedAdGroupsInfo([...resultsWithoutEditedRow, editedRowInfo]);
    },
    [editedAdGroupsInfo]
  );

  const tableChange = useSelector<WithTable<AdGroupDetails>, TableChange>(
    ({ tableState }) => tableSelectors.getChangeSelector()(tableState, TABLE_ID)
  );

  const updateTableChangeGlobal = useMemo(
    () => () => {
      const orginalAdgroupsGlobal = cloneDeep(
        tableChange.global
      ) as TableAdGroupGlobal;

      const currentCampaignTargetingTypes: { [key: string]: string } = {};
      const currentCurrencyCode: { [key: string]: string } = {};
      adGroupTableData.forEach((row: AdGroupDetails) => {
        row.adGroupDetails.campaignTargetingType &&
          (currentCampaignTargetingTypes[row.adGroupId] =
            row.adGroupDetails.campaignTargetingType);
        row.flywheelSettings?.minBid?.currency &&
          (currentCurrencyCode[row.adGroupId] =
            row.flywheelSettings.minBid.currency);
      });

      const updatedGlobalCampaignTargetngTypes = {
        ...orginalAdgroupsGlobal.campaignTargetingType,
        ...currentCampaignTargetingTypes,
      };

      const updatedGlobalCurrency = {
        ...orginalAdgroupsGlobal.currencyCode,
        ...currentCurrencyCode,
      };

      const modifiedAdGroupsGlobal = {
        ...orginalAdgroupsGlobal,
        [ADGROUPS_API_COLUMN_NAME.CampaignTargetingType]:
          updatedGlobalCampaignTargetngTypes,
        currencyCode: updatedGlobalCurrency,
      };

      if (!isEqual(orginalAdgroupsGlobal, modifiedAdGroupsGlobal)) {
        dispatch(
          tableActions.updateGlobal({
            tableId: TABLE_ID,
            updatedValues: modifiedAdGroupsGlobal,
          })
        );
      }
    },
    [tableChange, adGroupTableData]
  );
  const selectedRowsCount = tableChange.select.rows.length;

  const adGroupTableDataForAllPages = useSelector<
    WithTable<AdGroupDetails>,
    AdGroupDetails[]
  >(({ tableState }) =>
    tableSelectors.getDataForAllPages(
      tableSelectors.getTableSelector<AdGroupDetails>()(
        tableState,
        ADS_MANAGER_ADGROUP_TABLE_ID
      )
    )
  );

  const [globalTableCellChanges, setGlobalTableCellChanges] = useState<
    TableCellChangeAdGroup | undefined
  >();

  useEffect(() => {
    const allUpdatedCells = tableChange.cell as TableCellChangeAdGroup;
    setGlobalTableCellChanges((state: TableCellChangeAdGroup | undefined) => {
      const cells: any = {};
      Object.entries(allUpdatedCells).forEach(
        ([
          adGroupId,
          {
            macsTarget,
            minBid,
            maxBid,
            automationStatus,
            aiBidding,
            bidModifier,
            isOverrideBidModifier,
            overrideEndTime,
          },
        ]) => {
          const flywheelSettings = adGroupTableData.find(
            (row) => row.adGroupId === adGroupId
          )?.flywheelSettings;

          cells[adGroupId] = {
            minBid: minBid || flywheelSettings?.minBid?.amount!,
            maxBid: maxBid || flywheelSettings?.maxBid?.amount!,
            automationStatus:
              automationStatus || flywheelSettings?.automationStatus!,
            macsTarget: macsTarget || flywheelSettings?.macsTarget!,
            bidModifier: bidModifier || flywheelSettings?.bidModifier,
            aiBidding: aiBidding || flywheelSettings?.aiBidding,
            isOverrideBidModifier:
              isOverrideBidModifier || flywheelSettings?.isOverrideBidModifier,
            overrideEndTime:
              overrideEndTime || flywheelSettings?.overrideEndTime,
            advertisingGoal: flywheelSettings?.advertisingGoal,
          };
        }
      );

      return {
        ...state,
        ...cells,
      };
    });
  }, [tableChange.cell]);

  const updatedCells = tableChange.cell as TableCellChangeAdGroup;
  const updatedCellsIds = Object.keys(updatedCells);

  const getAdGroupReviewStatusCount = () => {
    return updatedCellsIds.filter(
      (adGroupId) => updatedCells[adGroupId].adGroupReviewStatus
    ).length;
  };

  const getValidAdgroupName = () =>
    Object.entries(updatedCells)
      .map(([adGroupId, value]) => ({
        adGroupId,
        adGroupName: value.adGroupName,
      }))
      .filter((adGroup) => {
        const maybeEditedAdGroupInfo = editedAdGroupsInfo.find(
          (record) => record.rowId === adGroup.adGroupId
        );

        const isAdGrupnameUnique = fromNullable(
          maybeEditedAdGroupInfo?.isNameUnique
        )
          .map((isUnique) => isUnique)
          .getOrElse(true);

        const isAdGroupNameDuplicateWithinEditedCells =
          isAdGroupNameColumnDuplicateWithinEditedCellsOfSameCampaign(
            adGroup.adGroupName,
            updatedCells,
            adGroup.adGroupId
          );

        return (
          !isNil(adGroup.adGroupName) &&
          !isInvalidAdGroupName(selectedSalesChannel, adGroup.adGroupName) &&
          isValidAdGroupNameCharacterLength(adGroup.adGroupName) &&
          isAdGrupnameUnique &&
          !isAdGroupNameDuplicateWithinEditedCells
        );
      }).length;

  const getValidAdgroupStatus = useCallback(
    () =>
      Object.values(updatedCells).filter(({ status }) => !isNil(status)).length,
    [updatedCells]
  );

  const getValidAiRecommendationKeyword = useCallback(
    () =>
      Object.entries(updatedCells)
        .map(([adGroupId, value]) => ({
          adGroupId,
          tags:
            value.adGroupLabel ||
            adGroupTableData
              .find((row) => row.adGroupId === adGroupId)
              ?.adGroupDetails.targetSegments?.toString(),
          aiRecommendationKeywordSetting: value.aiRecommendationKeywordSetting,
        }))
        .filter(({ aiRecommendationKeywordSetting, tags }) => {
          return (
            !isNil(aiRecommendationKeywordSetting) &&
            (aiRecommendationKeywordSetting ===
            AiRecommendedKeywordType.AddAutomatically
              ? tags?.length
              : true)
          );
        }).length,
    [updatedCells]
  );

  const getValidLabels = useCallback(
    () =>
      Object.values(updatedCells).filter(
        ({ adGroupLabel }) => !isNil(adGroupLabel)
      ).length,
    [updatedCells]
  );

  const getValidBidAutomationCount = useCallback(
    () =>
      Object.values(updatedCells).filter(
        ({ automationStatus }) => !isNil(automationStatus)
      ).length,
    [updatedCells]
  );

  const getValidAiBiddingCount = useCallback(() => {
    if (!shouldShowSmartCampaigns(optimizelyContext)) {
      return 0;
    }
    return Object.entries(updatedCells).filter(
      ([adGroupId, { macsTarget, bidModifier, aiBidding }]) => {
        if (!isNil(aiBidding)) {
          const cellChanges = globalTableCellChanges?.[adGroupId];

          const advertisingGoal =
            cellChanges &&
            transformAdvertisingGoal(
              (cellChanges?.aiBidding ??
                AiBiddingValues.NotEnabled) as AiBiddingValues,
              cellChanges.advertisingGoal as AdvertisingGoal,
              cellChanges.isOverrideBidModifier === 'true'
            );
          const isSmartGoal = advertisingGoal
            ? SMART_GOALS.includes(advertisingGoal)
            : false;

          const updatedAiBidding = aiBidding ?? cellChanges?.aiBidding;
          const updatedMacsTarget = macsTarget ?? cellChanges?.macsTarget;
          const updatedBidModifier =
            bidModifier ?? cellChanges?.bidModifier ?? isSmartGoal ? '1' : '';

          return isValidSmartAcosFwSetting({
            aiBidding: updatedAiBidding,
            acosLimit: updatedMacsTarget,
            bidModifier: updatedBidModifier,
          });
        }
        return false;
      }
    ).length;
  }, [updatedCells, globalTableCellChanges]);

  const getValidBidModifierCount = useCallback(() => {
    if (!shouldShowSmartCampaigns(optimizelyContext)) {
      return 0;
    }

    let totalCount = 0;
    Object.entries(updatedCells).forEach(
      ([
        adGroupId,
        {
          macsTarget,
          bidModifier,
          aiBidding,
          isOverrideBidModifier,
          overrideEndTime,
        },
      ]) => {
        const cellChanges = globalTableCellChanges?.[adGroupId];
        if (!isNil(bidModifier)) {
          const updatedAiBidding = aiBidding ?? cellChanges?.aiBidding;
          const updatedMacsTarget = macsTarget ?? cellChanges?.macsTarget;
          const updatedBidModifier = bidModifier ?? cellChanges?.bidModifier;

          totalCount += isValidSmartAcosFwSetting({
            aiBidding: updatedAiBidding,
            acosLimit: updatedMacsTarget,
            bidModifier: updatedBidModifier,
          })
            ? 1
            : 0;
        }
        if (!isNil(isOverrideBidModifier)) {
          const updatedOverrideEndTime =
            overrideEndTime ?? cellChanges?.overrideEndTime;

          const updatedIsOverrideBidModifier =
            isOverrideBidModifier ?? cellChanges?.isOverrideBidModifier;
          if (updatedIsOverrideBidModifier === 'true') {
            // Needs to count
            // The boolean and then the overtide endtime.
            // Or else 1.
            totalCount += updatedOverrideEndTime.length > 0 ? 2 : 1;
          } else if (updatedIsOverrideBidModifier === 'false') {
            totalCount += 1;
          }
        }
      }
    );
    return totalCount;
  }, [updatedCells, globalTableCellChanges]);

  const getValidMacsTargetCount = useCallback(() => {
    if (shouldShowSmartCampaigns(optimizelyContext)) {
      return Object.entries(updatedCells).filter(
        ([adGroupId, { macsTarget, bidModifier, aiBidding }]) => {
          if (!isNil(macsTarget)) {
            const cellChanges = globalTableCellChanges?.[adGroupId];
            const advertisingGoal =
              cellChanges &&
              transformAdvertisingGoal(
                (cellChanges?.aiBidding ??
                  AiBiddingValues.NotEnabled) as AiBiddingValues,
                cellChanges.advertisingGoal as AdvertisingGoal,
                cellChanges.isOverrideBidModifier === 'true'
              );
            const isSmartGoal = advertisingGoal
              ? SMART_GOALS.includes(advertisingGoal)
              : false;
            const updatedAiBidding = aiBidding ?? cellChanges?.aiBidding;
            const updatedMacsTarget = macsTarget ?? cellChanges?.macsTarget;
            const updatedBidModifier =
              bidModifier ?? cellChanges?.bidModifier ?? isSmartGoal ? '1' : '';

            return isValidSmartAcosFwSetting({
              aiBidding: updatedAiBidding,
              acosLimit: updatedMacsTarget,
              bidModifier: updatedBidModifier,
            });
          }

          return false;
        }
      ).length;
    }
    return Object.entries(updatedCells).filter(
      ([adGroupId, { macsTarget, minBid, maxBid, automationStatus }]) => {
        if (!isNil(macsTarget)) {
          const getExtraValidationsForMacsTarget = (
            salesChannel: FlywheelSalesChannel
          ) => {
            const cellChanges = globalTableCellChanges?.[adGroupId];

            const updatedMaxBid = maxBid ?? cellChanges?.maxBid;

            const updatedMinBid = minBid ?? cellChanges?.minBid;

            const updatedAutomationStatus =
              automationStatus ?? cellChanges?.automationStatus;

            const isValidSetting = checkValidFlywheelSetting(
              macsTarget,
              updatedAutomationStatus,
              updatedMinBid,
              updatedMaxBid
            );
            if (salesChannel === FlywheelSalesChannel.Amazon) {
              return () =>
                Number(macsTarget) < maxMacsTargetValue && isValidSetting;
            } else {
              return () => {
                return isValidSetting;
              };
            }
          };
          return isValidMacsTarget(
            macsTarget,
            getExtraValidationsForMacsTarget(selectedSalesChannel)
          );
        }

        return false;
      }
    ).length;
  }, [
    updatedCells,
    globalTableCellChanges,
    maxMacsTargetValue,
    selectedSalesChannel,
  ]);

  const globalChanges = tableChange.global as TableAdGroupGlobal;

  const getValidMinBidCount = useCallback(() => {
    if (shouldShowSmartCampaigns(optimizelyContext)) {
      return Object.entries(updatedCells).filter(
        ([adGroupId, { minBid, maxBid }]) => {
          if (!isNil(minBid)) {
            const cellChanges = globalTableCellChanges?.[adGroupId];

            const adGroup = adGroupTableData.find(
              (row) => row.adGroupId === adGroupId
            );

            const campaignCostType = adGroup?.adGroupDetails?.campaignCostType;

            const {
              minBid: minBidValueDefault,
              defaultMaxBid: maxBidValueDefault,
            } = getBidConstraint(
              bidConstraintsData.constraints,
              isCampaignAdFormatEqualToVideo(
                adGroup?.adGroupDetails?.campaignAdFormat
              )
                ? AdType.SponsoredBrandsVideo
                : selectedAdType,
              selectedSalesChannel,
              ADGROUPS_API_COLUMN_NAME.MinBid,
              merchantCountry,
              globalChanges.campaignTargetingType[adGroupId],
              merchantType,
              campaignCostType
            );

            const updatedMaxBid =
              maxBid ?? cellChanges?.maxBid ?? maxBidValueDefault;
            const updatedMinBid = minBid ?? cellChanges?.minBid;

            return (
              Number(updatedMaxBid) >= Number(updatedMinBid) &&
              Number(updatedMinBid) >= minBidValueDefault
            );
          }
          return false;
        }
      ).length;
    }
    return updatedCellsIds.filter((adGroupId: string) => {
      const updatedMinBid = {
        value: updatedCells[adGroupId].minBid,
        maxBid: updatedCells[adGroupId].maxBid,
        adGroupStatus: updatedCells[adGroupId].status,
        adGroupId: adGroupId,
        macsTarget: updatedCells[adGroupId].macsTarget,
        automationStatus: updatedCells[adGroupId].automationStatus,
      };

      const adGroup = adGroupTableData.find(
        (row) => row.adGroupId === adGroupId
      );

      const campaignCostType = adGroup?.adGroupDetails?.campaignCostType;

      const { minBid: minBidValue, maxBid: maxBidValue } = getBidConstraint(
        bidConstraintsData.constraints,
        isCampaignAdFormatEqualToVideo(
          adGroup?.adGroupDetails?.campaignAdFormat
        )
          ? AdType.SponsoredBrandsVideo
          : selectedAdType,
        selectedSalesChannel,
        ADGROUPS_API_COLUMN_NAME.MinBid,
        merchantCountry,
        globalChanges.campaignTargetingType[updatedMinBid.adGroupId],
        merchantType,
        campaignCostType
      );

      const cellChanges = globalTableCellChanges?.[adGroupId];

      const maxBid = updatedMinBid.maxBid ?? cellChanges?.maxBid;

      const macsTarget = updatedMinBid.macsTarget ?? cellChanges?.macsTarget;

      const automationStatus =
        updatedMinBid.automationStatus ?? cellChanges?.automationStatus;

      return (
        !isNil(updatedMinBid.value) &&
        isMinBidValid(updatedMinBid.value, maxBid, minBidValue, maxBidValue) &&
        checkValidFlywheelSetting(
          updatedMinBid.value,
          automationStatus,
          macsTarget,
          maxBid
        )
      );
    }).length;
  }, [
    updatedCellsIds,
    selectedAdType,
    selectedSalesChannel,
    merchantCountry,
    globalTableCellChanges,
  ]);

  const getValidMinBidOverrideEnabledCount = useCallback(() => {
    if (shouldShowSmartCampaigns(optimizelyContext)) {
      return updatedCellsIds.filter(
        (adGroupId) => updatedCells[adGroupId].minBidOverrideEnabled
      ).length;
    }
    return 0;
  }, [
    updatedCellsIds,
    selectedAdType,
    selectedSalesChannel,
    merchantCountry,
    globalTableCellChanges,
  ]);

  const getValidMaxBidCount = useCallback(() => {
    if (shouldShowSmartCampaigns(optimizelyContext)) {
      return Object.entries(updatedCells).filter(
        ([adGroupId, { minBid, maxBid }]) => {
          if (!isNil(maxBid)) {
            const cellChanges = globalTableCellChanges?.[adGroupId];

            const adGroup = adGroupTableData.find(
              (row) => row.adGroupId === adGroupId
            );

            const { minBid: minBidValueDefault, maxBid: maxBidValueDefault } =
              getBidConstraint(
                bidConstraintsData.constraints,
                isCampaignAdFormatEqualToVideo(
                  adGroup?.adGroupDetails?.campaignAdFormat
                )
                  ? AdType.SponsoredBrandsVideo
                  : selectedAdType,
                selectedSalesChannel,
                ADGROUPS_API_COLUMN_NAME.MaxBid,
                merchantCountry,
                globalChanges.campaignTargetingType[adGroupId],
                merchantType
              );

            const updatedMaxBid = maxBid ?? cellChanges?.maxBid;
            const updatedMinBid =
              minBid ?? cellChanges?.minBid ?? minBidValueDefault;

            return (
              Number(updatedMaxBid) >= Number(updatedMinBid) &&
              Number(updatedMaxBid) <= maxBidValueDefault
            );
          }
          return false;
        }
      ).length;
    }
    return updatedCellsIds.filter((adGroupId: string) => {
      const updatedMaxBid = {
        value: updatedCells[adGroupId].maxBid,
        minMid: updatedCells[adGroupId].minBid,
        adGroupStatus: updatedCells[adGroupId].status,
        adGroupId: adGroupId,
        macsTarget: updatedCells[adGroupId].macsTarget,
        automationStatus: updatedCells[adGroupId].automationStatus,
      };

      const adGroup = adGroupTableData.find(
        (row) => row.adGroupId === adGroupId
      );

      const { minBid: minBidValue, maxBid: maxBidValue } = getBidConstraint(
        bidConstraintsData.constraints,
        isCampaignAdFormatEqualToVideo(
          adGroup?.adGroupDetails?.campaignAdFormat
        )
          ? AdType.SponsoredBrandsVideo
          : selectedAdType,
        selectedSalesChannel,
        ADGROUPS_API_COLUMN_NAME.MaxBid,
        merchantCountry,
        globalChanges.campaignTargetingType[updatedMaxBid.adGroupId],
        merchantType
      );

      const cellChanges = globalTableCellChanges?.[adGroupId];

      const minBid =
        updatedMaxBid.minMid ?? cellChanges?.minBid! ?? String(minBidValue);

      const macsTarget = updatedMaxBid.macsTarget ?? cellChanges?.macsTarget;

      const automationStatus =
        updatedMaxBid.automationStatus ?? cellChanges?.automationStatus;

      return isValidMaxBid(
        updatedMaxBid.value,
        String(maxBidValue),
        String(minBidValue),
        minBid,
        () =>
          checkValidFlywheelSetting(
            updatedMaxBid.value,
            automationStatus,
            macsTarget,
            minBid
          )
      );
    }).length;
  }, [
    updatedCellsIds,
    selectedAdType,
    selectedSalesChannel,
    merchantCountry,
    globalTableCellChanges,
  ]);

  const getValidMaxBidOverrideEnabledCount = useCallback(() => {
    if (shouldShowSmartCampaigns(optimizelyContext)) {
      return updatedCellsIds.filter(
        (adGroupId) => updatedCells[adGroupId].maxBidOverrideEnabled
      ).length;
    }
    return 0;
  }, [
    updatedCellsIds,
    selectedAdType,
    selectedSalesChannel,
    merchantCountry,
    globalTableCellChanges,
  ]);

  const getDefaultBidCount = () => {
    const getExtraValidations = (
      salesChannel: FlywheelSalesChannel,
      defaultBid: string,
      dailybudget: string | undefined
    ) => {
      if (salesChannel === FlywheelSalesChannel.Amazon) {
        return () => {
          if (selectedAdType === AdType.SponsoredDisplay) {
            return isCurrentValueLessThanRequiredMinValue(
              Number(dailybudget) / 2,
              defaultBid
            );
          }
          if (selectedAdType === AdType.SponsoredProducts) {
            return isCurrentValueLessThanRequiredMinValue(
              Number(dailybudget),
              defaultBid
            );
          }
          return true;
        };
      }
    };

    return updatedCellsIds.filter((adGroupId: string) => {
      const adGroup = adGroupTableData.find(
        (row) => row.adGroupId === adGroupId
      );

      const campaignCostType = adGroup?.adGroupDetails?.campaignCostType;

      const { minBid: minBidValue, maxBid: maxBidValue } = getBidConstraint(
        bidConstraintsData.constraints,
        isCampaignAdFormatEqualToVideo(
          adGroup?.adGroupDetails?.campaignAdFormat
        )
          ? AdType.SponsoredBrandsVideo
          : selectedAdType,
        selectedSalesChannel,
        ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
        merchantCountry,
        globalChanges.campaignTargetingType[adGroupId],
        merchantType,
        campaignCostType
      );

      const updatedDefaultBid = {
        value: updatedCells[adGroupId].adGroupDefaultBid,
        adGroupStatus: updatedCells[adGroupId].status,
      };

      const campaignDailyBudget = adGroupTableData.find(
        (row) => row.adGroupId === adGroupId
      )?.adGroupDetails.campaignDailyBudget?.amount;

      return isValidDefaultBid(
        updatedDefaultBid.value,
        maxBidValue,
        minBidValue,
        getExtraValidations(
          selectedSalesChannel,
          updatedDefaultBid.value,
          campaignDailyBudget
        )
      );
    }).length;
  };

  const getvalidRowsCountForSelectedChannel = useCallback(() => {
    if (selectedSalesChannel === FlywheelSalesChannel.Walmart) {
      return (
        getValidAdgroupStatus() +
        getValidAdgroupName() +
        getValidBidAutomationCount() +
        getValidAiBiddingCount() +
        getValidBidModifierCount() +
        getValidMacsTargetCount() +
        getValidMinBidCount() +
        getValidMinBidOverrideEnabledCount() +
        getValidMaxBidCount() +
        getValidMaxBidOverrideEnabledCount() +
        getAdGroupReviewStatusCount() +
        getValidAiRecommendationKeyword() +
        getValidLabels()
      );
    }
    if (selectedSalesChannel === FlywheelSalesChannel.Amazon) {
      return (
        getValidAdgroupStatus() +
        getValidAdgroupName() +
        getValidBidAutomationCount() +
        getValidAiBiddingCount() +
        getValidBidModifierCount() +
        getValidMacsTargetCount() +
        getValidMinBidCount() +
        getValidMinBidOverrideEnabledCount() +
        getValidMaxBidCount() +
        getValidMaxBidOverrideEnabledCount() +
        getDefaultBidCount() +
        getValidAiRecommendationKeyword() +
        getValidLabels()
      );
    }
    return 0;
  }, [
    updatedCells,
    selectedAdType,
    selectedSalesChannel,
    merchantCountry,
    globalTableCellChanges,
    maxMacsTargetValue,
    editedAdGroupsInfo,
  ]);

  const validRowsCount = getvalidRowsCountForSelectedChannel();

  const saveChangesFlagContext = useContext<SaveChangesFlagContextState>(
    SaveChangesFlagContext
  );

  useEffect(() => {
    updateSaveChangesFlagContext({
      editDataFlag: changedCount > 0,
      saveChangesModalIsOpen: false,
      navPath: saveChangesFlagContext.saveChangesData.navPath,
    });
  }, [changedCount]);

  const getSaveButtonState: ButtonState = useMemo(() => {
    if (changedCount === 0) {
      return ButtonState.Disabled;
    }

    return validRowsCount === changedCount
      ? ButtonState.Enabled
      : ButtonState.Disabled;
  }, [validRowsCount, changedCount]);

  const isTableDataEdited: boolean =
    saveChangesFlagContext.saveChangesData.editDataFlag;

  useEffect(() => {
    // Show prompt if table is edited and user is on edit mode
    initBeforeUnLoad(isTableDataEdited && activeSliderIndex === 1);
  }, [isTableDataEdited]);

  const initBeforeUnLoad = (showExitPrompt: boolean) => {
    window.onbeforeunload = (event: Event) => {
      if (showExitPrompt) {
        event.preventDefault();
        return '';
      }
    };
  };

  const eligibleAdGroupIds = useMemo(() => {
    return adGroupTableData
      .filter(
        (adGroup) =>
          !isAdGroupStatusArchived(adGroup.channelSettings.status) &&
          !isCampaignStatusArchived(adGroup.adGroupDetails.campaignStatus)
      )
      .map((adgroup) => adgroup.adGroupId);
  }, [adGroupTableData]);

  const isAllRowsSelected = useMemo(
    () =>
      isEqual(
        [...cloneDeep(tableChange).select.rows].sort((a, b) =>
          a.localeCompare(b)
        ),
        [...eligibleAdGroupIds].sort((a, b) => a.localeCompare(b))
      ),
    [eligibleAdGroupIds, tableChange]
  );

  const handleSelectChange = (): void => {
    dispatch(
      tableActions.changeSelect({
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
        selectedRows: isAllRowsSelected ? [] : eligibleAdGroupIds,
      })
    );
  };

  const getVisibleColumnsAdGroups = (
    isAIPlan: boolean
  ): FlywheelTableColumnGroup<AdGroupDetails, TableDataAdsManager>[] => {
    const AD_GROUP_COLUMNS = generateAdGroupColumns({
      isEditMode: tableMode === DualModes.Edit,
      adType: selectedAdType,
      isAIPlanEnabled: isAIPlan,
      headerExtraProps: {
        onSelectChange: handleSelectChange,
        checked: getCheckBoxState(
          eligibleAdGroupIds.length,
          tableChange.select.rows.length
        ),
      },
      showSmartAcosInAdsManager: shouldShowSmartCampaigns(optimizelyContext),
      isSponsoredVideosEditable:
        optimizelyContext.featureFlags[OptimizelyFlags.SponsoredVideosEditable],
    });

    return AD_GROUP_COLUMNS.map((column) => {
      const columnInGroups = column.columnsInGroup.filter(
        (subColumn) =>
          apiColumnsWithAdLevel.columns.includes(subColumn.columnName) ||
          subColumn.columnName ===
            ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting ||
          subColumn.columnName === ADGROUPS_API_COLUMN_NAME.Tags
      );
      return {
        ...column,
        columnsInGroup: columnInGroups.filter((col) => {
          if (
            optimizelyContext.featureFlags[
              OptimizelyFlags.SponsoredVideosEditable
            ] ||
            selectedAdType !== AdType.SponsoredVideos
          ) {
            return true;
          }

          return ![
            ADGROUPS_API_COLUMN_NAME.CampaignTargetingType,
            ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget,
            ADGROUPS_API_COLUMN_NAME.TotalBudget,
            ADGROUPS_API_COLUMN_NAME.Group,
            ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus,
            ADGROUPS_API_COLUMN_NAME.DirectAdSales,
            ADGROUPS_API_COLUMN_NAME.RelatedClickRevenue,
            ADGROUPS_API_COLUMN_NAME.DirectACOS,
            ADGROUPS_API_COLUMN_NAME.DirectROAS,
            ADGROUPS_API_COLUMN_NAME.AdvertisedSkuUnits,
            ADGROUPS_API_COLUMN_NAME.OtherSkuUnits,
          ].includes(col.columnName);
        }),
      };
    }).filter((column) => column.columnsInGroup.length > 0);
  };

  const updateSaveChangesFlagContext = (
    data: Partial<SaveChangesContextDataProps>
  ) => {
    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      ...data,
    });
  };

  const onEditModeCancel = () => {
    toggleTableMode();

    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
    });

    clearAllChanges();
  };

  const clearAllChanges = () => {
    setGlobalTableCellChanges(undefined);
    dispatch(
      tableActions.clearAllChanges({
        tableId: TABLE_ID,
      })
    );
  };

  const handleOperationsAsPerTableMode = (mode: DualModes) => {
    if (!isUserRoleViewOnly) {
      if (mode === DualModes.View) {
        setClickedOnView(true);
      }

      if (
        mode === DualModes.View &&
        saveChangesFlagContext.saveChangesData.editDataFlag
      ) {
        saveChangesFlagContext.updateSaveChangesData({
          ...saveChangesFlagContext.saveChangesData,
          saveChangesModalIsOpen: true,
        });
      } else {
        clearAllChanges();
        toggleTableMode();
      }
    }
  };

  const onKeepEditingClick = () => {
    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      saveChangesModalIsOpen: false,
      aoAdLevel: undefined,
    });
    setClickedOnView(false);
  };

  const onLeaveWithoutSaveClick = () => {
    const adLevel = saveChangesFlagContext.saveChangesData.aoAdLevel || '';

    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
      aoAdLevel: undefined,
    });

    if (clickedOnView) {
      toggleTableMode();
      setClickedOnView(false);
    } else if (saveChangesFlagContext.saveChangesData.aoAdLevel) {
      switchLevelController(adLevel);
    } else {
      navigate(saveChangesFlagContext.saveChangesData.navPath);
    }
  };
  // export api request:
  const externalIdColumns = getExternalIdColumns(adLevelDropDownSelectedValues);

  // If campaign name column not selected in column manager
  // then drop 'extCampaignId' from details field
  const adGroupRequest = request();

  // Send external campaign id if campaign name is selected
  const campaignsIdColumns = getCampaignsIdColumns(apiColumnsWithAdLevel);

  const merchantNameColumns = getMerchantNameColumns(apiColumnsWithAdLevel);

  // Only those columns should be exported which are present in column mananger
  const adGroupDetailsSelectedColumnFromColumnManager =
    apiColumnsWithAdLevel.columns.filter(
      (column) =>
        ADGROUP_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields
    );

  const adGroupDetailsExportColumns = [
    ...externalIdColumns,
    ...campaignsIdColumns,
    ...merchantNameColumns,
    ...adGroupDetailsSelectedColumnFromColumnManager,
  ];

  const omittedAdGroupDetailsFields = getOmittedAdGroupDetailsFields(
    adLevelDropDownSelectedValues,
    adGroupDetailsExportColumns,
    selectedSalesChannelName as FlywheelSalesChannel
  );

  const getExportRequest = () => {
    const { channelSettingsFields, performanceFields } = adGroupRequest;

    // Remove this condition when we enable FW settings for Walmart SV
    const flywheelSettingsColumns =
      selectedAdType === AdType.SponsoredVideos
        ? []
        : getFlywheelSettingsColumn(
            AdLevel.AdGroups,
            merchantsWithAutomationEnabled.length > 0,
            shouldShowSmartCampaigns(optimizelyContext),
            optimizelyContext.featureFlags[
              OptimizelyFlags.SmartAdGroupsBidOverrides
            ]
          );

    return {
      ...adGroupRequest,
      adGroupDetailsFields: omittedAdGroupDetailsFields,
      channelSettingsFields: channelSettingsFields.filter((column) =>
        apiColumnsWithAdLevel.columns.includes(column)
      ),
      flywheelSettingsFields: flywheelSettingsColumns,
      performanceFields: performanceFields.filter((column) =>
        apiColumnsWithAdLevel.columns.includes(column)
      ),
      smartAcosFieldsIncluded: shouldShowSmartCampaigns(optimizelyContext),
    };
  };

  const getAdGroupDetailsFieldsForCsvDownload = () => {
    const adGroupDetailsFields = adGroupDetailsFieldsForCSVExport;

    return [
      ...adGroupDetailsFields,
      ADGROUPS_API_COLUMN_NAME.CampaignTargetingType,
      ADGROUPS_API_COLUMN_NAME.CampaignCostType,
      ADGROUPS_API_COLUMN_NAME.CampaignAdFormat,
      ADGROUPS_API_COLUMN_NAME.MerchantType,
      ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
    ];
  };

  const adGroupDataRequestForCSVDownload: AdGroupDataRequest = {
    ...adGroupRequest,
    adGroupDetailsFields: getAdGroupDetailsFieldsForCsvDownload(),
    flywheelSettingsFields: shouldShowSmartCampaigns(optimizelyContext)
      ? [
          ...adGroupflywheelSettingsFieldsForCSVExport,
          ...adGroupSmartAcosFieldsForCsvExport,
        ]
      : [...adGroupflywheelSettingsFieldsForCSVExport],
    performanceFields: apiColumnsWithAdLevel.columns.filter(
      (column) =>
        ADGROUP_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        AdgroupDataApiColumnGroupIdentifier.PerformanceFields
    ),
    channelSettingsFields: [],
    smartAcosFieldsIncluded: shouldShowSmartCampaigns(optimizelyContext),
  };

  const refreshTable = () =>
    dispatch(
      tableThunks.refreshTable(ADS_MANAGER_ADGROUP_TABLE_ID, dataFetcher)
    );

  const onBulkEditOptionSelection = (key: string, value: boolean) => {
    setBulkEditSelectedOptions((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const bulkEditModalEditControls = getEditControlsForAdGroupTable(
    intl,
    selectedAdType,
    selectedSalesChannel,
    bulkEditValues,
    tableChange,
    adGroupTableData,
    isAIPlan,
    currencySymbol || CURRENCY_CODE,
    merchantsWithAutomationEnabled,
    {
      adgroupDefaultBidValidationConfigCPC,
      adgroupDefaultBidValidationConfigVCPM,
      adgroupMacsTargetValidationConfig,
      maxBidAutoValidationConfig,
      maxBidManualValidationConfig,
      minBidAutoValidationConfig,
      minBidManualValidationConfig,
    },
    onBulkEditOptionSelection,
    merchantType,
    shouldShowSmartCampaigns(optimizelyContext)
  ).map((editControl, index) => ({
    ...editControl,
    controls: editControl?.controls,
    selected: editControl.editKey
      ? bulkEditSelectedOptions[editControl.editKey]
      : false,
  }));

  const onSubmitHandler = (bulkEditValues: BulkEditValues) => {
    setBulkEditValues(bulkEditValues as BulkEditModalValues);
    setShoWBulkEditSlideOut(!shoWBulkEditSlideOut);
  };

  const UpdateTableValues = (bulkEditValues: BulkEditModalValues) => {
    bulkEditValues = getSelectedBulkMenuValues(
      bulkEditValues,
      bulkEditSelectedOptions
    );

    if (isEmpty(bulkEditValues)) return;

    const bulkChanges = cloneDeep(tableChange.cell);
    const UpdatedBulkChanges = setBulkChanges(
      bulkChanges,
      bulkEditValues,
      tableChange,
      adGroupTableData,
      merchantsWithAutomationEnabled,
      adGroupTableDataForAllPages,
      selectedSalesChannel,
      selectedAdType,
      {
        adgroupDefaultBidValidationConfigCPC,
        adgroupDefaultBidValidationConfigVCPM,
        adgroupMacsTargetValidationConfig,
        maxBidAutoValidationConfig,
        maxBidManualValidationConfig,
        minBidAutoValidationConfig,
        minBidManualValidationConfig,
      }
    );

    dispatch(
      tableActions.applyBulkChanges({
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
        bulkChanges: UpdatedBulkChanges,
        columnDataMapping: EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
        uniqKey: TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID],
        percentageBasedColumns: PERCENTAGE_BASED_COLUMNS,
        booleanColumns: BOOLEAN_COLUMNS,
        arrayBasedColumns: ARRAY_BASED_COLUMNS,
      })
    );
  };

  const onActionClick = (actionType: Variant) => {
    if (actionType === Variant.Discard && areNoChangesDone(updatedCells)) {
      onEditModeCancel();
      return;
    }
    setConfirmationModalVariant(actionType);
  };

  const confirmationModalClick = (kind: ConfirmationModalClickKind) => {
    switch (confirmationModalVariant) {
      case Variant.Apply:
        if (kind === ConfirmationModalClickKind.APPLY) {
          setConfirmationModalVariant(null);
          onEditSave();
        } else {
          setConfirmationModalVariant(null);
        }
        break;
      case Variant.Discard:
        if (kind === ConfirmationModalClickKind.APPLY) {
          setConfirmationModalVariant(null);
          onEditModeCancel();
        } else {
          setConfirmationModalVariant(null);
        }
    }
  };

  const resetModal = () => {
    setBulkEditValues({});
    setBulkEditSelectedOptions({});
  };

  useEffect(() => {
    if (!isEmpty(bulkEditValues)) {
      UpdateTableValues(bulkEditValues);
      resetModal();
    }
  }, [bulkEditValues]);

  const getAdGroupLevelViewModeRightSideComponents = () => {
    const adGroupLevelViewModeRightSideComponents = [
      <Tooltip
        overwrittenTooltipClassnames="w-auto max-w-400"
        theme={Theme.Dark}
        content={intl.formatMessage({
          id: I18nKey.GENERIC_DOWNLOAD_CSV,
        })}
        tooltipSize={TooltipSize.Small}
        hideArrow
      >
        <ExportAdLevelData<AdGroupDataRequest>
          aoApiClient={aoApiClient}
          buttonState={exportButtonState}
          setButtonState={setExportButtonState}
          salesChannel={selectedSalesChannelName}
          request={getExportRequest()}
          adLevelDropDownSelectedValue={
            adLevelDropDownSelectedValues || undefined
          }
          additionalFilters={campaignDetailsFilter}
          filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
          startDate={startDate}
          endDate={endDate}
          adType={selectedAdType}
          transformFilters={getTransformedAddedByFilter}
          hideExportText={true}
        />
      </Tooltip>,
    ];
    if (
      shouldShowManageBidding(optimizelyContext, selectedAdType) &&
      aiEnabled
    ) {
      const isBiddableFilter: Filter = {
        op: FilterOps.eq,
        field: 'isBiddable',
        value: 'true',
      };
      let additionalFilters: Filter[] = [isBiddableFilter];
      if (campaignDetailsFilter) {
        additionalFilters.push(...campaignDetailsFilter);
      }
      if (selectedAdType === AdType.SponsoredDisplay) {
        const vCPMFilter: Filter = {
          op: FilterOps.notIn,
          field: ADGROUPS_API_COLUMN_NAME.CampaignCostType,
          value: [CampaignCostType.VCPM],
        };
        additionalFilters.push(vCPMFilter);
      }
      adGroupLevelViewModeRightSideComponents.push(
        <Tooltip
          content={intl.formatMessage({
            id: I18nKey.ADS_MANAGER_TABLE_MANAGE_GROUPING_BIDDING_TOOLTIP,
          })}
          position={{
            placement: Placement.Bottom,
            alignment: Alignment.Left,
          }}
          overwrittenTooltipClassnames="w-auto max-w-400"
          theme={Theme.Dark}
          tooltipSize={TooltipSize.Small}
          hideArrow
          disabled={!!isUserRoleViewOnly}
        >
          <ManageBiddingInternal<AdGroupDataRequest>
            aoApiClient={aoApiClient}
            adLevel={adLevelDropDownSelectedValues || AdLevel.AdGroups}
            filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
            additionalFilters={additionalFilters}
            request={adGroupDataRequestForCSVDownload}
            salesChannelName={selectedSalesChannel}
            adType={
              selectedAdType === AdType.SearchBrandAmplifier
                ? AdType.SponsoredBrands
                : selectedAdType
            }
            merchantName={merchantCountryNameForCSVDownload}
            refreshTable={refreshTable}
            merchantType={merchantType!}
            salesChannelId={selectedSalesChannelId}
            showAsIcon={true}
            isUserRoleViewOnly={!!isUserRoleViewOnly}
          />
        </Tooltip>
      );
    }

    return adGroupLevelViewModeRightSideComponents;
  };

  const tableActionRowRightSideElements =
    tableMode === DualModes.View
      ? getAdGroupLevelViewModeRightSideComponents()
      : [];

  useEffect(() => {
    if (!firstApiCall.current) {
      refreshTable();
    } else {
      firstApiCall.current = false;
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (ref.current && hasTimeElapsed()) {
      updateTime();
      refreshTable();
    } else {
      ref.current = true;
      if (location.state && location.state.filter) {
        let { filter } =
          (location.state && (location.state as { filter: Filter[] })) || [];
        dispatch(
          tableActions.updateFilters({
            tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
            filters: filter || currentTableFilters,
            replace: true,
          })
        );
      }
    }
  }, [apiColumnsWithAdLevel.columns]);

  const { hasTimeElapsed, updateTime } = useTime(
    parseInt(
      process.env.REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES ||
        REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES
    )
  );

  const tableData = useMemo(
    () => ({
      isEditMode: tableMode === DualModes.Edit,
      adTypes,
      selectedAdType,
      salesChannel: selectedSalesChannel,
      merchantCountry,
      merchantType,
      selectedMerchantCountries,
      aoApiClient,
      updateEditedRecords,
      selectedEndDate: endDate,
      allMerchants: allMerchantCountries,
      aiEnabled,
      merchantsWithAutomationEnabled,
      aiDisabledForAllSelectedMerchants:
        merchantsWithAutomationEnabled.length === 0,
      isManageTreAutomationEnabled: true,
      adLevel: selectedAdLevel,
      showSmartCampaign: shouldShowSmartCampaigns(optimizelyContext),
      cogsDetailsDataFetcher,
      productConnectionMissingForSelectedMerchant:
        !isProductConnectedForSelectedMerchant,
      toggleTableMode,
      showSmartAdGroupBidOverrides:
        optimizelyContext.featureFlags[
          OptimizelyFlags.SmartAdGroupsBidOverrides
        ],
    }),
    [
      tableMode,
      adTypes,
      selectedAdType,
      selectedSalesChannel,
      merchantCountry,
      merchantType,
      selectedMerchantCountries,
      aoApiClient,
      updateEditedRecords,
      endDate,
      aiEnabled,
      merchantsWithAutomationEnabled,
      selectedAdLevel,
    ]
  );

  useEffect(() => {
    shoWBulkEditSlideOut ? hideIntercomLauncher() : showIntercomLauncher();
  }, [shoWBulkEditSlideOut]);

  const isEditMode = tableMode === DualModes.Edit;

  const UploadTreComponent = showTreUpload ? (
    <FileUpload
      aoApiClient={aoApiClient}
      showTreUploadButtonIn={ShowTreUploadButtonIn.AO}
      merchantCountries={allMerchantCountries}
      isUserRoleViewOnly={isUserRoleViewOnly}
    />
  ) : undefined;

  const updateColumnSelection = (options: string[]) => {
    onColumnSelectionChange([
      ...options,
      DATA_INSPECTOR_COLUMN_NAME.AiRecommendationKeywordSetting,
    ]);
  };

  const dataFields = getFilterColumnDefinitons(
    CURRENCY_CODE,
    selectedSalesChannelName as FlywheelSalesChannel,
    first(adTypes),
    adLevelDropDownSelectedValues || undefined,
    merchantDetails,
    true,
    undefined,
    false,
    undefined,
    false,
    false,
    groupNames,
    shouldShowSmartCampaigns(optimizelyContext),
    optimizelyContext.featureFlags[OptimizelyFlags.SponsoredVideosEditable]
  );

  const currentTableFilters = useSelector<WithTable<AdGroupDetails>, Filter[]>(
    ({ tableState }) => tableState[ADS_MANAGER_ADGROUP_TABLE_ID].filters
  );

  let currentFilters = [
    ...currentTableFilters,
    ...(filtersFromBrowserStorage ?? []),
  ];

  const updateFilters = (filters: Filter[]) => {
    const existingSearchFilters: Filter[] = currentFilters.filter(
      (currentFilter) =>
        ALL_AD_LEVELS_FILTER_FILED_MAPPER.find(
          (filter) => filter.alias === currentFilter.field
        )
    );
    const newFilters: Filter[] = [...existingSearchFilters, ...filters];
    dispatch(
      tableActions.updateFilters({
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
        filters: newFilters,
        replace: true,
      })
    );
    upgradeFiltersInStorage(storeFiltersInBrowserStorage)(newFilters);
    if (onFilterUpdate) {
      onFilterUpdate(newFilters);
    }
  };

  const loadQuickFilterData = () => {
    if (
      shouldShowSmartCampaigns(optimizelyContext) &&
      selectedAdType === AdType.SponsoredProducts
    ) {
      const resp = dataFetcherWithoutQuickFilter({
        sorts: [],
        filters: currentFilters,
        itemsPerPage: 1,
        page: 1,
      });
      resp.then((res) => {
        setQuickFilterData({
          smartEntityCount: res.smartEntityCount,
          externalEntityCount: res.externalEntityCount,
        });
      });
    }
  };

  useEffect(() => {
    loadQuickFilterData();
    refreshTable();
  }, [campaignTypeQuickFilter]);

  return (
    <>
      <KeepEditingModal
        showModal={
          saveChangesFlagContext.saveChangesData.saveChangesModalIsOpen
        }
        onKeepEditing={onKeepEditingClick}
        onDiscard={onLeaveWithoutSaveClick}
        tableId={TABLE_ID}
      />
      {confirmationModalVariant && !isUserRoleViewOnly && (
        <BulkEditConfirmationModal
          showModal={!!confirmationModalVariant}
          variant={confirmationModalVariant}
          onPrimaryButtonClick={() =>
            confirmationModalClick(ConfirmationModalClickKind.APPLY)
          }
          onSecondryButtonClick={() =>
            confirmationModalClick(ConfirmationModalClickKind.CANCEL)
          }
        />
      )}
      <TableActionRow
        className="mt-16"
        leftSideElements={[
          <FlywheelSearchInput
            tableId={ADS_MANAGER_ADGROUP_TABLE_ID}
            inputSearchColumnName={ADGROUP_TABLE_SEARCH_KEY}
            searchInputSize={SearchInputSize.Medium}
            searchInputPlaceholder={getSearchInputPlaceholder(
              selectedAdType,
              adLevelDropDownSelectedValues as AdLevel,
              intl
            )}
            onFilterUpdate={onFilterUpdate}
            upgradeFiltersInStorage={upgradeFiltersInStorage(
              storeFiltersInBrowserStorage
            )}
            hasSearchInputWithButton
            searchInputClassName="w-480"
            dataTestId={`${ADS_MANAGER_ADGROUP_TABLE_ID}_search_input`}
          />,
          <FiltersMenu
            customFilterIcon={FilterIcon}
            currency={currencyCode as CurrencyCode}
            dataFields={dataFields}
            currentFilters={currentFilters}
            handleSave={updateFilters}
            filterButtonSize={ButtonSize.Medium}
            isOpen={isFilterMenuOpen}
            setOpen={setFilterMenuOpen}
            filterDateFormat={REQUEST_DATE_FORMAT}
            singleDecimalColumns={COLUMNS_WITH_SINGLE_DECIMAL_FILTER}
            dataTestId={`${ADS_MANAGER_ADGROUP_TABLE_ID}_filters_menu`}
            filtersContainerClass={
              'transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:left-0'
            }
          />,
          ...(isNil(UploadTreComponent) ? [] : [UploadTreComponent]),
          getCampaignTypeQuickFilters(
            intl,
            selectedAdType,
            campaignTypeQuickFilter,
            onQuickFilterClick,
            shouldShowSmartCampaigns(optimizelyContext),
            quickFilterData?.smartEntityCount,
            quickFilterData?.externalEntityCount
          ),
        ]}
        rightSideElements={[
          <Tooltip
            overwrittenTooltipClassnames="w-auto max-w-400"
            theme={Theme.Dark}
            content={intl.formatMessage({
              id: I18nKey.GENERIC_COLUMNS,
            })}
            tooltipSize={TooltipSize.Small}
            hideArrow
          >
            <ColumnManager
              variant={ColumnManagerVariant.ColumnGroupWithHeader}
              values={selectedColumns ?? []}
              onChange={onColumnSelectionChange}
              options={columnManagerOptions?.filter((item) => {
                if (
                  optimizelyContext.featureFlags[
                    OptimizelyFlags.SponsoredVideosEditable
                  ] ||
                  selectedAdType !== AdType.SponsoredVideos
                ) {
                  return (
                    item.value !==
                      DATA_INSPECTOR_COLUMN_NAME.AiRecommendationKeywordSetting &&
                    item.value !== DATA_INSPECTOR_COLUMN_NAME.Tags
                  );
                }

                return (
                  item.value !==
                    DATA_INSPECTOR_COLUMN_NAME.AiRecommendationKeywordSetting &&
                  item.value !== DATA_INSPECTOR_COLUMN_NAME.Tags &&
                  ![
                    DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType,
                    DATA_INSPECTOR_COLUMN_NAME.Group,
                    DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus,
                    DATA_INSPECTOR_COLUMN_NAME.DirectAdSales,
                    DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue,
                    DATA_INSPECTOR_COLUMN_NAME.DirectACOS,
                    DATA_INSPECTOR_COLUMN_NAME.DirectROAS,
                    DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits,
                    DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits,
                  ].includes(item.value)
                );
              })}
              tooltipClass="right-0"
              hideColumnText={true}
              dataTestId={`${ADS_MANAGER_ADGROUP_TABLE_ID}_column_manager`}
            />
          </Tooltip>,
          ...tableActionRowRightSideElements,
        ]}
        fullWidthElementIndex={-1}
      />
      {shoWBulkEditSlideOut && !isUserRoleViewOnly && (
        <BulkEditSlideOut
          headerText={intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_SLIDEOUT_MODAL_TITLE,
          })}
          isOpen={shoWBulkEditSlideOut}
          primaryButtonText={intl.formatMessage({
            id: I18nKey.GENERIC_SUBMIT,
          })}
          secondaryButtonText={intl.formatMessage({
            id: I18nKey.FILTER_ROW_CANCEL,
          })}
          editControls={bulkEditModalEditControls}
          onSubmit={onSubmitHandler}
          onClose={() => {
            setShoWBulkEditSlideOut(!shoWBulkEditSlideOut);
            resetModal();
          }}
          onCancel={() => {
            setShoWBulkEditSlideOut(!shoWBulkEditSlideOut);
            resetModal();
          }}
        />
      )}
      <UpdatedFlywheelTable<AdGroupDetails, TableDataAdsManager>
        actionRowV2Props={{
          loadingText: intl.formatMessage({
            id: I18nKey.ADVERTISING_OPTIMIZATION_TABLE_ACTION_ROW_LOADING_TEXT,
          }),
          isLoading: isSaveChangesLoading,
          isActive: selectedRowsCount > 0,
          selectedCount: selectedRowsCount,
          totalCount: adGroupTableData.length,
          showEditButton: isEditMode && selectedRowsCount > 0,
          showSlider:
            selectedAdType !== AdType.SponsoredVideos ||
            (selectedAdType === AdType.SponsoredVideos &&
              optimizelyContext.featureFlags[
                OptimizelyFlags.SponsoredVideosEditable
              ]),
          sliderMode: tableMode,
          onSliderModeChange: handleOperationsAsPerTableMode,
          showSelectedCount: isEditMode,
          className: 'border border-grey-200 rounded-t-sm',
          onEditClick: () => setShoWBulkEditSlideOut(!shoWBulkEditSlideOut),
          ...getActionRowButtons(
            tableMode,
            intl,
            getSaveButtonState,
            onActionClick
          ),
          viewOnlyMode: !!isUserRoleViewOnly,
        }}
        filtersContainerClass="transform -translate-x-2/4 xlmax:left-full xl:translate-x-0 xl:right-0"
        tableData={tableData}
        columns={getVisibleColumnsAdGroups(isAIPlanEnabled(userContext))}
        tableId={TABLE_ID}
        currencyCode={currencyCode || CURRENCY_CODE}
        dataFetcher={dataFetcher}
        footerTotalItemsI18nKey={
          I18nKey.ADS_MANAGER_ADGROUP_TABLE_FOOTER_TOTAL_ITEMS_TEXT
        }
        hasStickyHeader
        hasStickyLeftColumn
        errorComponent={<ErrorTable />}
        yesFilterNoDataDisplayComponent={<NoFilteredDataFoundTable />}
        noFilterNoDataDisplayComponent={<NoDataFoundTable />}
        inputSearchColumnName={ADGROUP_TABLE_SEARCH_KEY}
        dataFields={dataFields}
        onFilterUpdate={onFilterUpdate}
        filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
        filtersFromBrowserStorage={filtersFromBrowserStorage}
        storeFiltersInBrowserStorage={storeFiltersInBrowserStorage}
        filterDateFormat={REQUEST_DATE_FORMAT}
        searchInputSize={SearchInputSize.Medium}
        hasSearchInputWithButton
        searchInputPlaceholder={getSearchInputPlaceholder(
          selectedAdType,
          adLevelDropDownSelectedValues as AdLevel,
          intl
        )}
        columnManagerOptions={columnManagerOptions?.filter(
          (item) =>
            item.value !==
            DATA_INSPECTOR_COLUMN_NAME.AiRecommendationKeywordSetting
        )}
        onColumnSelectionChange={updateColumnSelection}
        selectedColumns={selectedColumns?.filter(
          (item) =>
            item !== DATA_INSPECTOR_COLUMN_NAME.AiRecommendationKeywordSetting
        )}
        treUploadComponent={UploadTreComponent}
        dataTestId="table_adGroup"
        showTableActionRow2={true}
        openFilterMenuOnPillClick={() => setFilterMenuOpen(true)}
        callBackOnFilterUpdate={loadQuickFilterData}
      />
    </>
  );
};
TableAdGroup.displayName = 'TableAdGroup';
