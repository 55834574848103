import classNames from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';

import {
  AmazonLogomarkEnabledIcon,
  ArrowsDownUpIcon,
  CrossIcon,
  Icon,
  IconSize,
  LogomarkIcon as TeikaLogoIcon,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';

import { FlywheelSalesChannel } from '../../../lib/types/Fam';

interface ConnectModalWrapperProps {
  readonly isOpen: boolean;
  readonly stepText?: string;
  readonly headerText: string | JSX.Element;
  readonly content: JSX.Element;
  readonly footer?: JSX.Element;
  readonly showFooterSeparator?: boolean;
  readonly headerChannelType?: FlywheelSalesChannel;
  readonly closeModal?: () => void;
  readonly dataTestId?: string;
  readonly isSecondaryModal?: boolean;
}

export const ConnectModalWrapper: React.FC<ConnectModalWrapperProps> = ({
  isOpen,
  stepText,
  headerText,
  content,
  footer,
  showFooterSeparator = true,
  headerChannelType,
  closeModal,
  dataTestId,
  isSecondaryModal = false,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={{
        base: classNames(
          'flex flex-col max-h-modal w-512 absolute',
          'bg-white outline-none rounded items-stretch',
          'text-center overflow-hidden min-h-flashModal'
        ),
        afterOpen: '',
        beforeClose: '',
      }}
      overlayClassName={{
        base: 'fixed inset-0 bg-grey-900 bg-opacity-60 justify-center z-50 pt-80',
        afterOpen: 'flex overflow-y-auto',
        beforeClose: '',
      }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={true}
      data-test-id={`${dataTestId}_connect_modal_wrapper`}
    >
      <div
        className={classNames(
          'h-80 flex justify-between bg-grey-50',
          'pb-16 border-b border-solid border-grey-200'
        )}
      >
        {!isSecondaryModal && <div className="w-16"></div>}
        <div
          className={classNames('flex flex-col pb-8 mt-16 gap-8', {
            'items-center justify-center flex-start pl-16': isSecondaryModal,
          })}
        >
          {!isSecondaryModal && (
            <div className="flex items-center justify-center">
              <div
                className={classNames(
                  'flex border border-solid border-grey-200',
                  'rounded-full w-32 h-32 mr-8 items-center justify-center'
                )}
              >
                <Icon size={IconSize.Medium} svg={TeikaLogoIcon} />
              </div>

              {headerChannelType && (
                <>
                  <Icon
                    size={IconSize.Small}
                    svg={ArrowsDownUpIcon}
                    className="text-grey-500 transform rotate-90"
                  />
                  <div
                    className={classNames(
                      'flex border border-solid border-grey-200 rounded-full',
                      'w-32 h-32 ml-8 items-center justify-center'
                    )}
                  >
                    <Icon
                      size={IconSize.Medium}
                      svg={
                        headerChannelType === FlywheelSalesChannel.Amazon
                          ? AmazonLogomarkEnabledIcon
                          : WalmartLogomarkEnabledIcon
                      }
                    />
                  </div>
                </>
              )}
            </div>
          )}
          <div className="flex flex-row items-center gap-8 justify-center">
            {stepText && (
              <Typography
                size={TypographySize.base}
                lineHeight={TypographyLineHeight.none}
                weight={TypographyWeight.regular}
                className="text-grey-900"
              >
                {stepText}
              </Typography>
            )}
            <Typography
              size={TypographySize.base}
              lineHeight={TypographyLineHeight.none}
              weight={TypographyWeight.medium}
              className="text-grey-900"
            >
              {headerText}
            </Typography>
          </div>
        </div>
        <div className="mr-10 mt-4 w-16">
          {closeModal && (
            <Icon
              size={IconSize.Small}
              svg={CrossIcon}
              onClick={closeModal}
              className="text-grey-600 cursor-pointer"
            />
          )}
        </div>
      </div>
      <div className="mx-24 flex flex-col flex-1 overflow-hidden">
        {content}
      </div>
      {footer && (
        <div
          className={classNames('p-24 flex-none', {
            'border-t-1 border-solid border-grey-200': showFooterSeparator,
          })}
        >
          {footer}
        </div>
      )}
    </ReactModal>
  );
};
ConnectModalWrapper.displayName = 'ConnectModalWrapper';
