import {
  COLUMNS_WITH_SINGLE_DECIMAL_FILTER,
  Filter,
  FilterOps,
} from '../types/Filter';
import { Sort } from '../types/Sort';
import { StringMap } from '../types';
import { DateTime } from 'luxon';
import isNumber from 'lodash/isNumber';

export const AMPERSAND_ESCAPE_SEQUENCE = '|||';
export const DATE_QUERY_STRING_FORMAT = 'yyyy-MM-dd';
export const sortToQueryString = (sort: Sort): string =>
  `${sort.column},${sort.direction}`;

export const generateSortQueryString = (sorts?: Sort[]): string =>
  sorts ? sorts.map(sortToQueryString).join(';') : '';

export interface RequestFilterSort {
  readonly sorts: Sort[];
  readonly filters: Filter[];
}

export interface ParamsFilterSort {
  readonly sort: string;
  readonly filter: string;
}

export const getValueWithFractions = (
  value: number,
  minDecimalDigits: number = 2
) => {
  const digitsAfterDecimal = value.toString().split('.')[1];
  const digitsAfterDecimalLength = digitsAfterDecimal
    ? digitsAfterDecimal.length
    : 0;
  if (digitsAfterDecimalLength < minDecimalDigits) {
    return Number(value).toFixed(minDecimalDigits);
  } else {
    return value.toString();
  }
};
const filterToFilterQueryValue = (filter: Filter): string => {
  switch (filter.op) {
    case FilterOps.like:
    case FilterOps.notLike:
    case FilterOps.gte:
    case FilterOps.lte:
    case FilterOps.gt:
    case FilterOps.lt:
    case FilterOps.eq:
    case FilterOps.isNot:
      if (DateTime.isDateTime(filter.value)) {
        return filter.value.toFormat(DATE_QUERY_STRING_FORMAT);
      } else if (
        isNumber(filter.value) &&
        COLUMNS_WITH_SINGLE_DECIMAL_FILTER.includes(filter.field)
      ) {
        return `${getValueWithFractions(filter.value, 1)}`;
      } else if (isNumber(filter.value)) {
        return `${getValueWithFractions(filter.value)}`;
      } else {
        return `${filter.value}`;
      }
    case FilterOps.is:
      return filter.value.value;
    case FilterOps.in:
    case FilterOps.notIn:
      return filter.value.join(',');
  }
};

const escapeAmpersandsInFilter = (filter: string): string =>
  filter
    .replace(AMPERSAND_ESCAPE_SEQUENCE, '') // Don't filter for a user-written escape
    .replace(/&/g, AMPERSAND_ESCAPE_SEQUENCE);

const filterToQueryString = (filter: Filter): string =>
  `${filter.field}${filter.op}${escapeAmpersandsInFilter(
    filterToFilterQueryValue(filter)
  )}`;
export const filtersToQueryString = (filters: Filter[]): string =>
  filters.map(filterToQueryString).join('&');
export const getFilterAndSort = ({
  filters,
  sorts,
}: RequestFilterSort): ParamsFilterSort => {
  const params = {
    filter: filtersToQueryString(filters),
    sort: generateSortQueryString(sorts),
  };
  return filterEmptyValues(params);
};

export const buildUrlWithQueryParams = (
  path: string,
  params: Record<string, string> = {}
) => {
  const queryString = new URLSearchParams(params).toString();
  return queryString ? path.concat('?', queryString) : path;
};

export function filterEmptyValues<
  T extends StringMap<string> | Record<string, string>
>(stringMap: T): T {
  return Object.keys(stringMap)
    .filter((key) => stringMap[key] !== '')
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: stringMap[key],
      }),
      {} as T
    );
}

export function exportUrlGenerator(
  url: string,
  filters: Filter[],
  extraPaginationParams?: StringMap<string>
): string {
  const allParams: StringMap<string> = {
    ...extraPaginationParams,
    filter: encodeURIComponent(filtersToQueryString(filters)),
  };
  const queryString = Object.keys(filterEmptyValues(allParams))
    .map((key) => key + '=' + allParams[key])
    .join('&');

  return `${url}?${queryString}`;
}
