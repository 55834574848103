import { CheckboxCheckedState } from '@teikametrics/tm-design-system';
import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import sortBy from 'lodash/sortBy';
import { tableSelectors } from '../../../../../containers/table/ducks';
import {
  TableChange,
  WithTable,
} from '../../../../../containers/table/ducks/types';
import {
  AdGroupDetails,
  AdvertisingGoal,
  AiBiddingValues,
  DetailAdGroup,
  TargetType,
} from '../../../../../lib/types/AOSharedTypes';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import { ADGROUPS_API_COLUMN_NAME } from '../utils';

export const getCurrentRowIndex =
  (adGroupDetails: AdGroupDetails) =>
  ({ tableState }: WithTable<AdGroupDetails>) => {
    const visibleData = tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<AdGroupDetails, void>()(
        tableState,
        ADS_MANAGER_ADGROUP_TABLE_ID
      )
    );
    const index = (function () {
      for (let i = 0; i < visibleData.length; i++) {
        if (visibleData[i].adGroupId === adGroupDetails.adGroupId) {
          return i;
        }
      }
      return -1;
    })();

    return index;
  };

export const getUpdatedValueForMultiSelect = (
  idx: number,
  prevValues: string[],
  value: string
) => {
  const newValues = [...prevValues];
  if (newValues[idx].includes(value)) {
    newValues[idx] = newValues[idx]
      .split(',')
      .filter((element) => element !== value)
      .toString();
  } else if (value === TargetType.NoTag) {
    newValues[idx] = TargetType.NoTag;
  } else {
    const updatedValue =
      !newValues[idx] ||
      newValues[idx] === '' ||
      newValues[idx] === TargetType.NoTag
        ? []
        : newValues[idx].split(',');
    updatedValue.push(value);
    newValues[idx] = updatedValue.toString();
  }
  return newValues;
};

export const isTargetTypeChecked = (values: string[], targetType: TargetType) =>
  first(values)?.includes(targetType)
    ? CheckboxCheckedState.Checked
    : CheckboxCheckedState.Unchecked;

const getUpdatedCurrentTags = (targetTags?: string[]) => {
  if (!isUndefined(targetTags)) {
    return targetTags.length > 0 ? targetTags.toString() : TargetType.Auto;
  } else {
    return '';
  }
};

const getFirstTagsForSelectedAdgroup = (
  tableChange: TableChange,
  firstSelectedAdgroup?: AdGroupDetails
) =>
  firstSelectedAdgroup &&
  tableChange.cell[firstSelectedAdgroup?.adGroupId] &&
  tableChange.cell[firstSelectedAdgroup?.adGroupId][
    ADGROUPS_API_COLUMN_NAME.Tags
  ]
    ? tableChange.cell[firstSelectedAdgroup?.adGroupId][
        ADGROUPS_API_COLUMN_NAME.Tags
      ]
    : getUpdatedCurrentTags(
        firstSelectedAdgroup?.adGroupDetails.targetSegments
      );

const areTagsEqualToFirst = (
  { adGroupDetails, adGroupId }: AdGroupDetails,
  tableChange: TableChange,
  firstSelectedTags?: string
) => {
  const editedStatus = tableChange.cell[adGroupId]?.adGroupLabel;
  const originalTargetSegment = getUpdatedCurrentTags(
    adGroupDetails.targetSegments
  );

  if (!isNil(editedStatus)) {
    if (editedStatus === '' || firstSelectedTags === '') {
      return false;
    }
    return isEqual(
      sortBy(editedStatus.split(',')),
      sortBy(firstSelectedTags?.split(','))
    );
  }
  return isEqual(
    sortBy(originalTargetSegment.split(',')),
    sortBy(firstSelectedTags?.split(','))
  );
};

export const getCurrentTags = (
  tableChange: TableChange,
  adGroupData: AdGroupDetails[],
  values: string[]
) => {
  const selectedRowsIds = tableChange.select.rows;
  const selectedAdgroups: AdGroupDetails[] = adGroupData.filter(
    ({ adGroupId }) => selectedRowsIds.includes(adGroupId)
  );
  const firstSelectedAdgroup = first(selectedAdgroups);
  const firstSelectedAdgroupTags = getFirstTagsForSelectedAdgroup(
    tableChange,
    firstSelectedAdgroup
  );

  const allSelectedRowsHaveSameTagsValue = selectedAdgroups.every(
    (selectedAdgroup) =>
      areTagsEqualToFirst(
        selectedAdgroup,
        tableChange,
        firstSelectedAdgroupTags
      )
  );
  let currentValueBeingEdited = values;
  if (first(currentValueBeingEdited) === '') {
    currentValueBeingEdited = allSelectedRowsHaveSameTagsValue
      ? firstSelectedAdgroupTags
        ? [firstSelectedAdgroupTags]
        : [TargetType.NoTag]
      : [''];
  }
  return currentValueBeingEdited;
};

export const isAutomationDisabledForMerchant = (
  merchantsWithAutomationEnabled: string[] | undefined,
  adGroupDetails: DetailAdGroup
) =>
  !merchantsWithAutomationEnabled?.includes(
    adGroupDetails.merchantCountryId ?? ''
  );

export const getTargetTypes = (targetTypes?: string[]) => {
  if (!isUndefined(targetTypes)) {
    return targetTypes.length > 0 ? targetTypes : [TargetType.Auto];
  } else {
    return [];
  }
};

export const isStandAloneAutoTargetType = (targetTypes: string[]) =>
  isEqual(targetTypes, [TargetType.Auto]);

export const getLatestLabelValues = (
  selectedTargetTypeValues: string[],
  updatedValue?: string
) => {
  if (updatedValue) {
    return updatedValue.length > 0 && updatedValue !== TargetType.NoTag
      ? sortBy(updatedValue.split(','))
      : [];
  } else {
    return selectedTargetTypeValues;
  }
};

export const SMART_GOALS = [
  AdvertisingGoal.ProductLaunch,
  AdvertisingGoal.Grow,
  AdvertisingGoal.Profit,
  AdvertisingGoal.ROAS,
  AdvertisingGoal.Liquidate,
];

export const transformAdvertisingGoal = (
  aiBiddingValue: AiBiddingValues,
  advertisingGoal?: AdvertisingGoal,
  isOverridden?: boolean
): AdvertisingGoal => {
  if (advertisingGoal && SMART_GOALS.includes(advertisingGoal)) {
    if (isOverridden) {
      return AdvertisingGoal.TempOverride;
    }
    return advertisingGoal;
  } else if (
    (isUndefined(advertisingGoal) ||
      advertisingGoal === AdvertisingGoal.External) &&
    aiBiddingValue?.toString() === AiBiddingValues.Ai
  ) {
    return AdvertisingGoal.ExternalWithAi;
  }
  return AdvertisingGoal.External;
};
