import { NavPaths } from '../../../../NavPaths';
import { AMAZON_CONSOLE_LINK, WALMART_CONSOLE_LINK } from './consts';
import { DateTime } from 'luxon';
import { MerchantType } from '../../../../lib/types/Fam';
import { MoneyWithAmountInString } from '../../../../lib/types/Money';
import {
  AggregationPeriodType,
  MetricsPeriodType,
} from '../../../../lib/types/SKUSharedTypes';
import I18nKey from '../../../../lib/types/I18nKey';
import { CurrencyCode } from '../../../../lib/utilities/currency';

export interface DashboardProps {}

export type MultiChannelMerchantInfo = {
  readonly merchantCountryId: string;
  readonly merchantCountryName: string;
  readonly salesChannelId?: string;
  readonly salesChannelName?: string;
  readonly merchantType: MerchantType;
  readonly country: string;
  readonly extMerchantId?: string;
};

export enum LaunchAdsConsoleOptionValue {
  CAMPAIGN_CREATOR = 'CAMPAIGN_CREATOR',
  AMAZON_CONSOLE = 'AMAZON_CONSOLE',
  WALMART_CONSOLE = 'WALMART_CONSOLE',
}

export const MAP_AD_CONSOLE_OPTION_TO_LINK: Record<
  LaunchAdsConsoleOptionValue,
  string
> = {
  [LaunchAdsConsoleOptionValue.CAMPAIGN_CREATOR]: NavPaths.CampaignFlexibility,
  [LaunchAdsConsoleOptionValue.AMAZON_CONSOLE]: AMAZON_CONSOLE_LINK,
  [LaunchAdsConsoleOptionValue.WALMART_CONSOLE]: WALMART_CONSOLE_LINK,
};

export const MAP_DEPTH_TO_METRIC_UNWAVAILABLE_TOOLTIP: Record<number, string> =
  {
    0: I18nKey.AO_DASHBOARD_TABLE_METRIC_NOT_AVAILABLE_TOOLTIP_CHANNEL,
    1: I18nKey.AO_DASHBOARD_TABLE_METRIC_NOT_AVAILABLE_TOOLTIP_MERCHANT,
    2: I18nKey.AO_DASHBOARD_TABLE_METRIC_NOT_AVAILABLE_TOOLTIP_AD_TYPE,
  };

export interface DateRange {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly minDate?: DateTime;
  readonly maxDate?: DateTime;
}

export interface MultiChannelDataSnapshotRequest {
  readonly merchantCountries: MultiChannelMerchantInfo[];
  readonly currency: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly metricsPeriodType: MetricsPeriodType;
  readonly performanceFields?: string[];
}
export interface MultiChannelPerformance {
  readonly adConversions?: number;
  readonly totalSales?: MoneyWithAmountInString;
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly roasTotal?: number;
  readonly tacosTotal?: number;
  readonly unitsSold?: number;
  readonly conversionRate?: number;
  readonly clicks?: number;
  readonly impressions?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
  readonly adSalesDirect?: MoneyWithAmountInString;
  readonly roasDirect?: number;
  readonly acosDirect?: number;
  readonly adSpendPercentage?: number;
}

export interface PerformanceComparison {
  current: MultiChannelPerformance;
  previous: MultiChannelPerformance;
}

export interface AdTypePerformance {
  readonly adType: string;
  readonly salesChannelId: string;
  readonly salesChannelName: string;
  readonly merchantCountryId: string;
  readonly performance: PerformanceComparison;
}
export interface MultiChannelMerchantSnapshot {
  readonly merchantCountryId: string;
  readonly merchantCountryName: string;
  readonly salesChannelId: string;
  readonly salesChannelName: string;
  readonly merchantType: string;
  readonly performance: PerformanceComparison;
  readonly advertisingTypeMetrics: AdTypePerformance[];
  readonly subRows?: AdTypePerformance[];
}

export interface MultiChannelDataSnapshot extends Record<string, unknown> {
  readonly salesChannelId: string;
  readonly merchantCountries: MultiChannelMerchantSnapshot[];
  readonly performance: PerformanceComparison;
  readonly subRows?: MultiChannelMerchantSnapshot[];
}

export interface MultiChannelDataSummary extends Record<string, unknown> {
  readonly aggregationPeriodType: AggregationPeriodType;
  readonly currency: CurrencyCode;
  readonly performanceMetrics: PerformanceComparison;
  readonly latestTimestamp?: string;
}

export interface MultiChannelTableData extends Record<string, unknown> {
  readonly selectedMerchantCountries: MultiChannelMerchantInfo[];
  readonly showPreviousData: boolean;
  readonly isTableDataLoading: boolean;
  readonly selectedDateRange?: DateRange;
}

export interface MultiChannelDataSnapshotResponse {
  readonly latestTimeStamp: string;
  readonly metricType: MetricsPeriodType;
  readonly currency: string;
  readonly channelMetrics: MultiChannelDataSnapshot[];
  readonly summaryMetrics: PerformanceComparison;
}

export enum MultiChannelDashboardColumns {
  MoreDetails = 'moreDetails',
  PercentOfTotalAdSpend = 'percentageAdspend',
  ChannelAndAdType = 'channelAndAdType',
  TotalSales = 'totalSales',
  AdSales = 'adSalesTotal',
  AdSpend = 'adSpend',
  Tacos = 'tacos',
  Roas = 'roasTotal',
}

export type MultiChannelCellData = MultiChannelDataSnapshot &
  MultiChannelTableData & {
    depth?: number;
    isExpanded?: boolean;
  };

export type MultiChannelCellPropsWithDepth =
  | ({ depth: 0 } & MultiChannelDataSnapshot & MultiChannelTableData)
  | ({ depth: 1 } & MultiChannelMerchantSnapshot & MultiChannelTableData)
  | ({ depth: 2 } & AdTypePerformance & MultiChannelTableData);
