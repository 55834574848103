import {
  Icon,
  IconSize,
  SvgIcon,
  TextLink,
} from '@teikametrics/tm-design-system';
import React from 'react';

interface TextLinkProps {
  label: React.ReactNode;
  href: string;
}

interface ButtonProps {
  label: React.ReactNode;
  icon: SvgIcon;
  iconPosition: 'left' | 'right';
  onClick: () => void;
}

interface CTABannerProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  textLinkProps?: TextLinkProps;
  buttonProps: ButtonProps;
}

export const CTABanner: React.FC<CTABannerProps> = ({
  title,
  subtitle,
  textLinkProps,
  buttonProps,
}) => {
  return (
    <div
      className={`flex items-center justify-between gap-20 px-78 xl:px-136 py-32
        bg-flywheel-orange-magenta-purple-gradient w-full`}
    >
      <div className="text-white whitespace-pre-line">
        <div className="text-xl leading-tight font-semibold xl:text-2xl">
          {title}
        </div>
        {subtitle && (
          <div className="text-base leading-normal font-normal xl:text-lg">
            {subtitle}
          </div>
        )}
      </div>
      <div className="flex items-center gap-20 flex-shrink-0">
        {!!textLinkProps && (
          <TextLink
            disabled
            textLabel={textLinkProps.label}
            href={textLinkProps.href}
            openNewTab
            className={`text-white hover:text-white
            flex-shrink-0 pointer-events-auto text-sm xl:text-base`}
          />
        )}

        <button
          className={`flex items-center flex-shrink-0 gap-8 h-32 px-16 
          bg-white/10 text-white text-base leading-none font-medium
          border border-solid border-white rounded `}
          onClick={buttonProps.onClick}
        >
          {buttonProps.iconPosition === 'left' ? (
            <>
              <Icon svg={buttonProps.icon} size={IconSize.Medium} />
              {buttonProps.label}
            </>
          ) : (
            <>
              {buttonProps.label}
              <Icon svg={buttonProps.icon} size={IconSize.Medium} />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

CTABanner.displayName = 'CTABanner';
