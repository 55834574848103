import {
  AdgroupActionType,
  BadgeDisplayMode,
  BadgeType,
  Button,
  ButtonSize,
  ButtonVariant,
  CrossMiniIcon,
  Modal,
  PortaledTooltipPlacement,
  ProductSku2,
  Spinner,
  SuggestedAdgroupCell,
  TextLink,
  TextLinkVariant,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import noop from 'lodash/noop';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavPaths } from '../../../NavPaths';
import { MerchantCountriesContext } from '../../../containers/merchantCountriesProvider/merchantCountriesProvider';
import { getCurrentAccountFromContext } from '../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../containers/userProvider/userProvider';
import { SKUApiClient } from '../../../lib/clients/SKUApiClient';
import {
  CampaignStatus,
  CampaignTargetingType,
  MerchantCountryCode,
} from '../../../lib/types/AOSharedTypes';
import I18nKey from '../../../lib/types/I18nKey';
import { TargetSegments } from '../../../lib/types/KeywordAction';
import {
  AssociatedCampaigns,
  RelatedCampaignAndAdGroupRequest,
  RelatedCampaignAndAdGroupResponse,
  SkuDetailsFields,
} from '../../../lib/types/SKUSharedTypes';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../lib/utilities/intercom';
import { getWalmartItemPageUrl } from '../../../lib/utilities/skuUtils';
import {
  CAMPAIGN_STATUS,
  MERCHANT_COUNTRY_IDS,
  PRODUCT_AD_STATUS,
} from '../../advertisingOptimization/containers/adsManager/types';
import { SkuDetailsLocationState } from '../containers/skuCatalog/types';
import {
  getBasePath,
  getUrlParamsForDetailsPage,
} from '../containers/skuCatalog/utils';
import { getAmazonItemPageUrl } from '../containers/skuCatalog/utils/skuDetailsUtils';
import { CampaignView } from './campaignsSlideout/CampaignView';
import groupBy from 'lodash/groupBy';
import {
  AsyncRequest,
  AsyncRequestCompleted,
  AsyncRequestFailed,
  AsyncRequestLoading,
  AsyncRequestNotStarted,
  asyncRequestIsComplete,
  asyncRequestIsLoading,
} from '../../../lib/utilities/asyncRequest';
import { AMAZON_SALES_CHANNEL_ID } from '../../../lib/types/SalesChannels';
import { buildUrlWithQueryParams } from '../../../lib/utilities/buildUrlUtilities';
import { useNavigate } from 'react-router-dom';

export interface RelatedCampaignsAndAdGroupFlyoverProps {
  readonly isOpen: boolean;
  readonly onClose?: () => void;
  readonly productGroupId: string;
  readonly productCatalogId: string;
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly skuDetailsFields: SkuDetailsFields;
  readonly skuApiClient: SKUApiClient;
  readonly associatedCampaignIds?: string[];
  readonly productNameTooltipPlacement?: PortaledTooltipPlacement;
  readonly header?: string;
  readonly closeAllOnNavigate?: () => void;
  readonly closeCurrentTrendsSlideover?: () => void;
  readonly campaignChildView?: boolean;
  readonly skus?: string[];
}

export interface CustomInsightProductDetails {
  readonly imageUrl: string;
  readonly sku: string;
  readonly externalItemId: string;
  readonly externalItemType: string;
  readonly name: string;
  readonly salesChannelId: string;
  readonly merchantCountryId: string;
  readonly productCatalogId: string;
}

export const RelatedCampaignsAndAdGroupFlyover: React.FC<
  RelatedCampaignsAndAdGroupFlyoverProps
> = ({
  isOpen,
  onClose = noop,
  productGroupId,
  productCatalogId,
  merchantCountryId,
  salesChannelId,
  skuDetailsFields,
  skuApiClient,
  associatedCampaignIds = [],
  productNameTooltipPlacement,
  header,
  closeAllOnNavigate = noop,
  closeCurrentTrendsSlideover,
  campaignChildView,
  skus = [],
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;
  const { merchantCountries } = useContext(MerchantCountriesContext);
  const merchant = merchantCountries?.find(
    (merchant) => merchant.merchantCountryId === merchantCountryId
  );

  const [relatedCampaignAndAdgroupData, setRelatedCampaignAndAdgroupData] =
    useState<AsyncRequest<RelatedCampaignAndAdGroupResponse>>(
      AsyncRequestNotStarted()
    );

  const loadData = async () => {
    try {
      setRelatedCampaignAndAdgroupData(AsyncRequestLoading());
      const payload: RelatedCampaignAndAdGroupRequest = {
        productName: skuDetailsFields?.name,
        sku: skuDetailsFields.sku,
        asin: skuDetailsFields?.extItemDetail?.extItemId!,
        parentSku: skuDetailsFields.parentSku,
        parentAsin: skuDetailsFields.parentAsin,
        merchantCountryId: merchantCountryId,
        productCatalogId: productCatalogId,
        associatedCampaignIds: associatedCampaignIds,
        skus: skus.length > 0 ? skus : [skuDetailsFields.sku],
      };
      const data = await skuApiClient?.getRelatedCampaignAndAdGroupData(
        accountId,
        payload
      );
      setRelatedCampaignAndAdgroupData(AsyncRequestCompleted(data));
    } catch {
      setRelatedCampaignAndAdgroupData(AsyncRequestFailed(undefined));
    }
  };

  useEffect(() => {
    hideIntercomLauncher();
    return () => showIntercomLauncher();
  }, []);

  useEffect(() => {
    if (skuDetailsFields?.extItemDetail?.extItemId) loadData();
  }, [skuDetailsFields?.extItemDetail?.extItemId]);

  const relatedData = asyncRequestIsComplete(relatedCampaignAndAdgroupData)
    ? relatedCampaignAndAdgroupData.result
    : ({} as RelatedCampaignAndAdGroupResponse);
  const {
    productName = '',
    sku = '',
    asin = '',
    associatedCampaigns = [],
  } = relatedData ?? {};

  const onAsinClick = () => {
    const externalItemId = skuDetailsFields.extItemDetail?.extItemId;
    const url =
      salesChannelId === AMAZON_SALES_CHANNEL_ID
        ? getAmazonItemPageUrl(
            (merchant?.country as MerchantCountryCode) ??
              MerchantCountryCode.US,
            externalItemId
          )
        : getWalmartItemPageUrl(externalItemId);
    window.open(url);
  };

  const basePath = getBasePath(merchant);

  const skuDetailsPageUrl = buildUrlWithQueryParams(
    `${basePath}/${encodeURIComponent(sku)}`,
    getUrlParamsForDetailsPage(productCatalogId, merchantCountryId)
  );

  const onProductNameClick = () => {
    const locationState: SkuDetailsLocationState = {
      name: productName,
      sku,
      imageUrl: skuDetailsFields.imageUrl,
      salesChannelId,
      asin: skuDetailsFields.extItemDetail?.extItemId,
      extItemType: skuDetailsFields.extItemDetail?.extItemType,
      channel: skuDetailsFields.extItemDetail?.salesChannelName,
    };
    return navigate(skuDetailsPageUrl, {
      state: locationState,
    });
  };

  const onViewInAdsManagerClick = () => {
    const basePath =
      salesChannelId === AMAZON_SALES_CHANNEL_ID
        ? NavPaths.AoAmazonSponsoredProductsProductAds
        : NavPaths.AoWalmartSponsoredProductsProductAd;
    const url = buildUrlWithQueryParams(basePath, {
      [MERCHANT_COUNTRY_IDS]: merchantCountryId ?? '',
      sku: sku,
      [CAMPAIGN_STATUS]:
        salesChannelId === AMAZON_SALES_CHANNEL_ID
          ? CampaignStatus.Enabled
          : CampaignStatus.Live,
      [PRODUCT_AD_STATUS]: 'enabled',
    });
    closeAllOnNavigate();
    return navigate(url);
  };

  const campaignToAdGroup = useMemo(() => {
    const group = groupBy(associatedCampaigns, ({ campaignId }) => campaignId);

    return group;
  }, [associatedCampaigns]);

  return (
    <Modal
      className="flex flex-col w-1/3"
      showModal={isOpen}
      slideOutModal={true}
      showOVerlay={true}
      formActionRowClassName="sticky border-t-1 border-grey-300 border-solid py-12 absolute bottom-0 -mx-24 px-12 bg-white"
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      hidePadding={true}
      maxModalWidth="max-w-md"
      dataTestId="related_campaigns_and_ad_group_flyover"
      preventScroll
    >
      <div className="flex flex-col h-screen">
        <div
          className={classNames(
            'flex flex-row items-center',
            'border-b-1 border-solid border-grey-200',
            'px-12 py-8'
          )}
        >
          <Button
            svgIcon={CrossMiniIcon}
            size={ButtonSize.InlineIconMedium}
            variant={ButtonVariant.NoStyle}
            iconClassName="text-grey-600"
            onClick={onClose}
          />
          <Typography
            size={TypographySize.base}
            lineHeight={TypographyLineHeight.none}
            weight={TypographyWeight.medium}
            className="text-center w-full text-grey-900"
          >
            {header ??
              intl.formatMessage({
                id: I18nKey.SKU_CATALOG_RELATED_FLYOVER_HEADING,
              })}
          </Typography>
        </div>
        <div className="flex flex-col px-24 pt-24 h-relatedCampaignAndAdGroupSlideout overflow-y-auto">
          <div className="text-lg leading-tight font-medium pb-24">
            {intl.formatMessage({
              id: I18nKey.SKU_CATALOG_RELATED_FLYOVER_SUB_HEADING,
            })}
          </div>
          <div
            className={classNames(
              'border border-solid border-grey-200',
              'py-8 px-28',
              'rounded',
              'flex flex-row'
            )}
          >
            <ProductSku2
              dataTestId="productCatalog"
              asin={asin}
              onAsinClick={onAsinClick}
              productImageUrl={skuDetailsFields.imageUrl}
              productName={productName}
              sku={sku}
              useSku={salesChannelId === AMAZON_SALES_CHANNEL_ID}
              onProductNameClick={onProductNameClick}
              useAsin={salesChannelId === AMAZON_SALES_CHANNEL_ID}
              isProductNameClickable={false}
              isProductNameLink={false}
              skuLabelClassnames="line-clamp-1 break-all"
              productNameTooltipPlacement={productNameTooltipPlacement}
            />
          </div>
          {asyncRequestIsLoading(relatedCampaignAndAdgroupData) ? (
            <Spinner />
          ) : (
            <div className="py-16">
              <div className="flex flex-col gap-12">
                {campaignChildView &&
                  associatedCampaigns.map((relatedCampaign) => (
                    <CampaignView
                      campaign={relatedCampaign}
                      className="w-full"
                      adgroupDetailsClassname="w-full"
                      associatedCampaigns={
                        campaignToAdGroup[relatedCampaign.campaignId]
                      }
                      merchant={merchant}
                      adType={relatedCampaign.advertisingType}
                      additionalOnTrendsClick={() => {
                        onClose();
                        closeCurrentTrendsSlideover?.();
                      }}
                    />
                  ))}
              </div>
              {!campaignChildView &&
                associatedCampaigns?.map(
                  (relatedCampaign: AssociatedCampaigns, index: number) => {
                    return (
                      <>
                        {index !== 0 && (
                          <div className="w-full mt-12 pb-8 border-solid border-t-1 border-grey-200" />
                        )}
                        <SuggestedAdgroupCell
                          action={AdgroupActionType.None}
                          classname="w-full"
                          adGroupName={relatedCampaign.adGroupName}
                          campaignName={relatedCampaign.campaignName}
                          targetingType={{
                            badgeType:
                              relatedCampaign.targetingType ===
                              CampaignTargetingType.Auto
                                ? BadgeType.CampaignTypeAuto
                                : BadgeType.CampaignTypeManual,
                            badgeNameDisplayMode: BadgeDisplayMode.OnlyInitial,
                            className: 'mr-4',
                          }}
                          targetSegments={getTargetSegmentPills(
                            relatedCampaign.targetSegments
                          )}
                        />
                      </>
                    );
                  }
                )}
            </div>
          )}
        </div>
        <div
          className={classNames(
            'flex p-12 space-x-12',
            'border-t border-solid border-grey-300',
            'w-full h-56',
            'justify-between'
          )}
        >
          <div className="flex items-center pl-12 text-base leading-tight font-normal">
            {asyncRequestIsLoading(relatedCampaignAndAdgroupData) ? (
              <div className="relative w-20 h-18">
                <Spinner />
              </div>
            ) : (
              <span>{associatedCampaigns?.length || 0}</span>
            )}
            <span className="text-grey-500 ml-4">
              {intl.formatMessage({
                id: I18nKey.SKU_CATALOG_RELATED_FLYOVER_FOOTER_TEXT,
              })}
            </span>
          </div>
          <TextLink
            textLabel={intl.formatMessage({
              id: I18nKey.SKU_CATALOG_RELATED_FLYOVER_FOOTER_PRIMARY_BUTTON_TEXT,
            })}
            variant={TextLinkVariant.ArrowRight}
            onClick={onViewInAdsManagerClick}
          />
        </div>
      </div>
    </Modal>
  );
};

RelatedCampaignsAndAdGroupFlyover.displayName =
  'RelatedCampaignsAndAdGroupFlyover';

const getTargetSegmentPills = (targetSegments?: TargetSegments[]) =>
  targetSegments && targetSegments.length
    ? targetSegments.map((targetSegment, index) => ({
        badgeType: getSegmentBadgeType(targetSegment),
        dataTestId: `targetSegment_relatedCampaign_${index}`,
        className: 'mr-4',
      }))
    : [];

const getSegmentBadgeType = (targetSegment: TargetSegments) => {
  switch (targetSegment.toLowerCase()) {
    case TargetSegments.Auto.toLowerCase():
      return BadgeType.TagAutomatic;
    case TargetSegments.Brand.toLowerCase():
      return BadgeType.TagBrand;
    case TargetSegments.Competitor.toLowerCase():
      return BadgeType.TagCompetitor;
    case TargetSegments.Generic.toLowerCase():
    case TargetSegments.Unknown.toLowerCase():
    default:
      return BadgeType.TagGeneric;
  }
};
