import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import I18nKey from '../../../../../lib/types/I18nKey';
import {
  TabsSimple,
  TextInput,
  TextLink,
  TextLinkSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { MerchantType } from '../../../../../lib/types/Fam';
import { WalmartOAuthStatus } from '../walmartFlashScreens';
import { WalmartCommonFailureBanner } from '../walmartCommonFailureBanner';
import {
  WALMART_FIND_1P_SUPPLIER_ID_LINK,
  WALMART_FIND_3P_SELLER_ID_LINK,
} from '../../constants';

export interface ConnectWalmartAdsData {
  advertiserId: string;
  sellerId: string;
  supplierId: string;
}

export const DEFAULT_CONNECT_ADS_DATA = {
  advertiserId: '',
  sellerId: '',
  supplierId: '',
};

export interface ConnectAdsDataWalmartModalContent {
  connectWalmartAdsData: ConnectWalmartAdsData;
  setConnectWalmartAdsData: (data: ConnectWalmartAdsData) => void;
  readonly advertisingId?: string;
  readonly extMerchantId?: string;
  readonly walmartMerchantType: MerchantType;
  readonly walmartOAuthStatus: WalmartOAuthStatus;
  readonly errorMessage?: string;
}

export const ModalContent: React.FC<ConnectAdsDataWalmartModalContent> = ({
  connectWalmartAdsData,
  setConnectWalmartAdsData,
  advertisingId,
  extMerchantId,
  walmartMerchantType,
  walmartOAuthStatus,
  errorMessage,
}) => {
  const intl = useIntl();
  const [
    HEADER,
    SUB_HEADER,
    SELLER_3P,
    VENDOR_1P,
    ADVERTISER_ID,
    SELLER_ID,
    SUPPLIER_ID,
    NOTE_SELLER_LINK,
    NOTE_SUPPLIER_LINK,
  ] = [
    I18nKey.FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_CONTENT_HEADER,
    I18nKey.FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_CONTENT_SUB_HEADER,
    I18nKey.FLASH_MODALS_WALMART_PRODUCT_CONNECT_3P_SELLER,
    I18nKey.FLASH_MODALS_WALMART_PRODUCT_CONNECT_1P_VENDOR,
    I18nKey.FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_ADVERTISER_ID,
    I18nKey.FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_SELLER_ID,
    I18nKey.FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_SUPPLIER_ID,
    I18nKey.FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_NOTE_SELLER_LINK,
    I18nKey.FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_NOTE_SUPPLIER_LINK,
  ].map((id) => intl.formatMessage({ id }));
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    walmartMerchantType === MerchantType.Seller ? 0 : 1
  );
  const onTabSelect = (selectedIndex: number) =>
    setActiveTabIndex(selectedIndex);

  useEffect(() => {
    setConnectWalmartAdsData({
      ...DEFAULT_CONNECT_ADS_DATA,
      advertiserId: advertisingId ?? '',
      ...(walmartMerchantType === MerchantType.Seller
        ? { sellerId: extMerchantId ?? '' }
        : { supplierId: extMerchantId ?? '' }),
    });
  }, [activeTabIndex]);

  return (
    <div className="flex flex-col gap-16 py-24">
      <div className="flex flex-col gap-8 items-start">
        <Typography
          size={TypographySize.lg}
          lineHeight={TypographyLineHeight.none}
          weight={TypographyWeight.semibold}
        >
          {HEADER}
        </Typography>
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.tight}
          weight={TypographyWeight.regular}
        >
          {SUB_HEADER}
        </Typography>
      </div>
      <div className="border-solid border-b-1 border-grey-200">
        <TabsSimple
          dataTestId="sb_tabsSimple"
          onTabSelect={onTabSelect}
          defaultTabIndex={activeTabIndex}
          tabs={[
            {
              label: SELLER_3P,
            },
            {
              label: VENDOR_1P,
            },
          ]}
        />
      </div>
      {walmartOAuthStatus === WalmartOAuthStatus.FAILED && (
        <WalmartCommonFailureBanner errorMessage={errorMessage} />
      )}
      <div className="flex flex-col items-start py-12 gap-16 w-full">
        <div className="flex flex-col items-start gap-4 w-full">
          <label>
            <Typography
              size={TypographySize.sm}
              lineHeight={TypographyLineHeight.normal}
              weight={TypographyWeight.regular}
            >
              {ADVERTISER_ID}
            </Typography>
          </label>
          <TextInput
            value={connectWalmartAdsData.advertiserId}
            className="block w-full"
            onChange={(value) =>
              setConnectWalmartAdsData({
                ...connectWalmartAdsData,
                advertiserId: value,
              })
            }
          />
        </div>
        {activeTabIndex === 0 && (
          <div className="flex flex-col items-start gap-4 w-full">
            <label>
              <Typography
                size={TypographySize.sm}
                lineHeight={TypographyLineHeight.normal}
                weight={TypographyWeight.regular}
              >
                {SELLER_ID}
              </Typography>
            </label>
            <TextInput
              value={connectWalmartAdsData.sellerId}
              className="block w-full"
              onChange={(value) =>
                setConnectWalmartAdsData({
                  ...connectWalmartAdsData,
                  sellerId: value,
                })
              }
            />
          </div>
        )}
        {activeTabIndex === 1 && (
          <div className="flex flex-col items-start gap-4 w-full">
            <label>
              <Typography
                size={TypographySize.sm}
                lineHeight={TypographyLineHeight.normal}
                weight={TypographyWeight.regular}
              >
                {activeTabIndex ? SUPPLIER_ID : SELLER_ID}
              </Typography>
            </label>
            <TextInput
              value={connectWalmartAdsData.supplierId}
              className="block w-full"
              onChange={(value) =>
                setConnectWalmartAdsData({
                  ...connectWalmartAdsData,
                  supplierId: value,
                })
              }
            />
          </div>
        )}
      </div>
      <div className="flex flex-col items-start p-16 gap-16 w-full bg-yellow-100">
        <TextLink
          size={TextLinkSize.Small}
          textLabel={activeTabIndex ? NOTE_SUPPLIER_LINK : NOTE_SELLER_LINK}
          dataTestId="flash1_walmart_connect_ads_note_seller_supplier"
          href={
            activeTabIndex
              ? WALMART_FIND_1P_SUPPLIER_ID_LINK
              : WALMART_FIND_3P_SELLER_ID_LINK
          }
          openNewTab
        />
      </div>
    </div>
  );
};
