import React from 'react';
import { useIntl } from 'react-intl';

import {
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../../ConnectModalWrapper';
import { Footer } from './footer';
import MerchantsChannelsTable from '../merchantsChannels/merchantsChannelsTable';
import { NeedHelp } from '../../../../../components/NeedHelp';
import { INTERCOM_AMAZON_ONBOARDING_HELP } from '../../constants';
import {
  FlywheelSalesChannel,
  MerchantCountries,
} from '../../../../../lib/types/Fam';

export enum SecondaryMerchantsModalType {
  AlreadyConnectedMerchants = 'AlreadyConnectedMerchants',
  UnavailableMerchants = 'UnavailableMerchants',
}
export interface SecondaryMerchantsModalProps {
  readonly onBackButtonClick: () => void;
  readonly merchantCountries: MerchantCountries[];
  readonly modalType: SecondaryMerchantsModalType;
  readonly closeModal?: () => void;
  readonly channel?: FlywheelSalesChannel;
}

export const SecondaryMerchantsModal: React.FC<
  SecondaryMerchantsModalProps
> = ({
  onBackButtonClick,
  merchantCountries,
  closeModal,
  modalType,
  channel,
}) => {
  return (
    <ConnectModalWrapper
      isOpen
      headerText={
        <Footer onButtonClick={onBackButtonClick} onlyBackButton={true} />
      }
      content={
        <ModalContent
          merchantCountries={merchantCountries}
          modalType={modalType}
          channel={channel}
        />
      }
      isSecondaryModal={true}
      closeModal={closeModal}
    />
  );
};

SecondaryMerchantsModal.displayName = 'SecondaryMerchantsModal';

interface ModalContentProps {
  readonly merchantCountries: MerchantCountries[];
  readonly modalType: SecondaryMerchantsModalType;
  readonly channel?: FlywheelSalesChannel;
}

const ModalContent: React.FC<ModalContentProps> = ({
  merchantCountries,
  modalType,
  channel,
}) => {
  const intl = useIntl();
  return (
    <div className="text-left text-base text-grey-900 flex flex-col pt-24 pb-16 gap-12 grow">
      <Typography
        size={TypographySize.xl}
        lineHeight={TypographyLineHeight.tight}
        weight={TypographyWeight.semibold}
      >
        {modalType === SecondaryMerchantsModalType.UnavailableMerchants
          ? intl.formatMessage({
              id: I18nKey.CONNECT_ADS_MODAL_AMAZON_SOME_UNAVAILABLE_MERCHANTS_HEADER,
            })
          : intl.formatMessage(
              {
                id: I18nKey.CONNECT_ADS_MODAL_AMAZON_MERCHANTS_ALREADY_CONNECT_HEADER,
              },
              { connectedCount: merchantCountries.length }
            )}
      </Typography>
      <MerchantsChannelsTable
        showSearchInput={false}
        showBulkSelection={false}
        tableClassName="border-solid overflow-hidden flex self-stretch grow shrink-0 basis-0"
        headerClass="bg-white h-42"
        rowData={merchantCountries}
      />
      <NeedHelp
        url={INTERCOM_AMAZON_ONBOARDING_HELP}
        dataTestId="secondary_merchants_modal"
        channel={channel}
      />
    </div>
  );
};
