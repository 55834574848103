import capitalize from 'lodash/capitalize';

import {
  MultiSelectOption,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  FlywheelSalesChannel,
  ProductAdDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) => {
  return makeTextColumn(
    (props: ProductAdDetails) =>
      props ? capitalize(props.adItemDetails.adGroupStatus) : undefined,
    props.pendingFields?.includes(SV_ADS_API_COLUMN_NAME.AdGroupStatus)
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const adgroupStatusColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: SV_ADS_API_COLUMN_NAME.AdGroupStatus,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_STATUS,
  RowCellElement,
  gridColumnWidth: '160px',
};

export const adGroupStatusFilter = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
) =>
  createMultiSelectDataFieldFilter(
    SV_ADS_API_COLUMN_NAME.AdGroupStatus,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_STATUS,
    getCampaignStatusOptions(selectedSalesChannel, selectedAdType)
  );

export const getCampaignStatusOptions = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
): MultiSelectOption[] => {
  switch (selectedSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      return [
        SelectFilterOption.AdgroupStatusEnabled,
        SelectFilterOption.AdgroupStatusDisabled,
      ];
    case FlywheelSalesChannel.Amazon:
      switch (selectedAdType) {
        case AdType.SponsoredProducts:
        case AdType.SponsoredBrands:
        case AdType.SponsoredDisplay:
          return [
            SelectFilterOption.CampaignStatusEnabled,
            SelectFilterOption.CampaignStatusPaused,
            SelectFilterOption.CampaignStatusArchived,
          ];
        // The fallback default should never occur hence safe to return [], can handle with default options in future
        default:
          return [];
      }
    /**
      The fallback default should never occur as Sales Channel will always fall in one of above 
      cases hence safe to return [], can handle with default options in future
    * 
    */
    default:
      return [];
  }
};
