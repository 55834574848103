import {
  CycleDotIcon,
  StarIcon,
  SvgIcon,
  SwordsCrossedIcon,
  UpcIcon,
} from '@teikametrics/tm-design-system';
import { CampaignInfo } from '../../lib/types/KeywordAction';
import { CurrencyCode } from '../../lib/utilities/currency';
import { ThunkDispatch } from 'redux-thunk';
import { TableAction } from '../../containers/tableV2/ducks/actions';
import { TableReducerState } from '../../containers/tableV2/ducks/types';
import { CampaignFlexibilityApiClient } from '../../lib/clients/CampaignFlexibilityClient';
import { MerchantCountryCode } from '../../lib/types/AOSharedTypes';
import {
  AdGroupStructure,
  CampaignProductAdGroupProductGrouping,
  CampaignStructure,
  EligibleProductElement,
  ProductsStatus,
  SelectedProductsAggregation,
  TargetSegment,
} from '../../lib/types/CampaignFlexibilitySharedTypes';
import {
  AllSalesChannel,
  FlywheelSalesChannel,
  MerchantCountry,
  MerchantType,
} from '../../lib/types/Fam';
import I18nKey from '../../lib/types/I18nKey';
import { SelectedTrendsProduct } from '../../modules/products/containers/skuCatalog';
import { SelectMetricsType } from '../../modules/campaignFlexibility/components/productTable/productTableColumns/advertisingGoal';

export const PRODUCTS_TABLE_SEARCH_BY_KEY = 'searchText';
export const DEBOUNCE_AFTER_IN_MILLISECONDS = 500;

export const MERCHANT_COUNTRY_IDS = 'merchantCountryIds';
export const SALES_CHANNEL_ID = 'salesChannelId';

export const SORT_ORDER = 'sortOrder';
export const SORT_BY = 'sortBy';
export const SALES_CHANNEL_NAME = 'salesChannelName';
export const ADDED_BY_FILTER = 'addedByFilter';

export interface BidConstraint {
  minBid: number;
  maxBid: number;
  minacs: number;
  maxacs: number;
  countryCodes: MerchantCountryCode[];
}

export const KEYWORD_LIMIT = 1000;

interface StepData {
  title: I18nKey;
  subTitle?: I18nKey;
  subTitleLink?: string;
  backButtonText: I18nKey;
  nextButtonText: I18nKey;
}

export enum CampaignFlexibilityStep {
  STEP_1 = 'Step1',
  STEP_2 = 'Step2',
  STEP_3 = 'Step3',
  STEP_4 = 'Step4',
  STEP_5 = 'Step5',
}

export enum ProductAdsAStatus {
  Pause = 'Pause',
  Running = 'Running',
}

export enum EligibleProductApiColumnValue {
  CampaignCount = 'campaignCount',
  CurrentInventoryQuantity = 'inventoryQuantity',
  SKU = 'sku',
  TotalSales = 'totalSales',
  Name = 'name',
  ImageUrl = 'imageUrl',
  ExternalItemId = 'extItemId',
  ExternalItemType = 'extItemType',
  SalesChannelId = 'salesChannelId',
  SalesChannelName = 'salesChannelName',
}

export enum KeywordsTableApiColumnValue {
  Keyword = 'keyword',
}

export enum KeywordErrorType {
  MaxCharacters = 'maxCharacters',
  Invalid = 'invalid',
  InvalidAsin = 'invalidAsin',
  Duplicate = 'duplicate',
  MaxParts = 'maxParts',
  MaxPartsNegative = 'maxPartsNegative',
}

export type TableExtraProps = {
  selectedMerchant?: MerchantCountry;
  selectedCurrency: CurrencyCode;
  isExpanded?: boolean;
  toggleRowExpanded?: (expanded?: boolean) => void;
  canExpand?: boolean;
  depth?: number;
  setIsOpenAdgroup?: (val: boolean) => void;
  onViewTrendsSlideoverClick?: (product: SelectedTrendsProduct) => void;
  readonly selectedRowIds?: string[];
  readonly updateAdvertisingGoal?: (
    updatedValue: Record<string, SelectMetricsType>
  ) => void;
  readonly onRowsSelection?: (
    rowsId: string[],
    checked: boolean,
    original?: Map<string, EligibleProductElement>
  ) => void;
  readonly advertisingGoalsData?: Record<string, SelectMetricsType>;
  readonly refreshTable?: () => void;
};

export type TableExtraPropsCampaign = {
  selectedMerchant: MerchantCountry;
  selectedCurrency: CurrencyCode;
  isExpanded?: boolean;
  toggleRowExpanded?: (expanded?: boolean) => void;
  canExpand?: boolean;
  depth?: number;
  setIsOpenAdgroup?: (val: boolean) => void;
  accountId?: string;
  selectedProductsData?: SelectedProductsAggregation;
  productsStatuData?: ProductsStatus;
  reloadTableData?: () => void;
  onViewTrendsSlideoverClick?: (product: SelectedTrendsProduct) => void;
};

export const MAP_CAMPAIGN_STEP_TO_DATA: Record<
  CampaignFlexibilityStep,
  StepData
> = {
  [CampaignFlexibilityStep.STEP_1]: {
    title: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_SETUPCAMPAIGNS_HEADER,
    // subTitle: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_SETUPCAMPAIGNS_SUBHEADER,
    // subTitleLink:
    //   'https://intercom.help/flywheel-20/en/articles/6289191-learn-more-about-flywheel-aligned-sponsored-products-keyword-campaigns',
    backButtonText: I18nKey.GENERIC_CLEAR_LABEL,
    nextButtonText: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_SETUPCAMPAIGNS_BUTTON,
  },
  [CampaignFlexibilityStep.STEP_2]: {
    title: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_HEADER,
    subTitle: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_SUBHEADER,
    backButtonText: I18nKey.BACK,
    nextButtonText: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_BUTTON,
  },
  [CampaignFlexibilityStep.STEP_3]: {
    title: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_HEADER,
    subTitle: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_SUBHEADER,
    backButtonText: I18nKey.BACK,
    nextButtonText:
      I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_BUTTON,
  },
  [CampaignFlexibilityStep.STEP_4]: {
    title: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_HEADER,
    subTitle: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_SUBHEADER,
    backButtonText: I18nKey.BACK,
    nextButtonText:
      I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_BUTTON,
  },
  [CampaignFlexibilityStep.STEP_5]: {
    title: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_HEADER,
    subTitle: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_SUBHEADER,
    backButtonText: I18nKey.BACK,
    nextButtonText:
      I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_BUTTON,
  },
};

export const NEW_MAP_CAMPAIGN_STEP_TO_DATA: Record<
  CampaignFlexibilityStep,
  StepData
> = {
  [CampaignFlexibilityStep.STEP_1]: {
    title: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_SETUPCAMPAIGNS_HEADER,
    backButtonText: I18nKey.GENERIC_CLEAR_LABEL,
    nextButtonText: I18nKey.GENERIC_CONTINUE,
  },
  [CampaignFlexibilityStep.STEP_2]: {
    title: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_HEADER,
    subTitle: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_SUBHEADER,
    backButtonText: I18nKey.BACK,
    nextButtonText: I18nKey.GENERIC_CONTINUE,
  },
  [CampaignFlexibilityStep.STEP_3]: {
    title: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_HEADER,
    subTitle: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_SUBHEADER,
    backButtonText: I18nKey.BACK,
    nextButtonText: I18nKey.GENERIC_CONTINUE,
  },
  [CampaignFlexibilityStep.STEP_4]: {
    title: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_HEADER,
    subTitle: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_SUBHEADER,
    backButtonText: I18nKey.BACK,
    nextButtonText: I18nKey.GENERIC_CONTINUE,
  },
  [CampaignFlexibilityStep.STEP_5]: {
    title: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_HEADER,
    subTitle: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_SUBHEADER,
    backButtonText: I18nKey.GENERIC_CANCEL_LABEL,
    nextButtonText:
      I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_BUTTON,
  },
};

export const MAP_CAMPAIGN_TO_STEPS: Record<CampaignFlexibilityStep, number> = {
  [CampaignFlexibilityStep.STEP_1]: 1,
  [CampaignFlexibilityStep.STEP_2]: 2,
  [CampaignFlexibilityStep.STEP_3]: 3,
  [CampaignFlexibilityStep.STEP_4]: 4,
  [CampaignFlexibilityStep.STEP_5]: 5,
};

export const MERCHANT_COUNTRY_CODE_TO_BASE_EXTERNAL_URL_MAPPER: Record<
  MerchantCountryCode,
  string
> = {
  [MerchantCountryCode.US]: 'https://amazon.com/dp/',
  [MerchantCountryCode.CA]: 'https://amazon.ca/dp/',
  [MerchantCountryCode.MX]: 'https://amazon.com.mx/dp/',
  [MerchantCountryCode.BR]: 'https://amazon.com.br/dp/',
  [MerchantCountryCode.UK]: 'https://amazon.co.uk/dp/',
  [MerchantCountryCode.GB]: 'https://amazon.co.uk/dp/',
  [MerchantCountryCode.DE]: 'https://amazon.de/dp/',
  [MerchantCountryCode.FR]: 'https://amazon.fr/dp/',
  [MerchantCountryCode.ES]: 'https://amazon.es/dp/',
  [MerchantCountryCode.IT]: 'https://amazon.it/dp/',
  [MerchantCountryCode.NL]: 'https://amazon.nl/dp/',
  [MerchantCountryCode.JP]: 'https://amazon.co.jp/dp/',
  [MerchantCountryCode.AU]: 'https://amazon.com.au/dp/',
  [MerchantCountryCode.SG]: 'https://amazon.sg/dp/',
  [MerchantCountryCode.AE]: 'https://amazon.ae/dp/',
  [MerchantCountryCode.IN]: 'https://amazon.in/dp/',
  [MerchantCountryCode.KSA]: 'https://amazon.sa/dp/',
  [MerchantCountryCode.SE]: 'https://amazon.se/dp/',
  [MerchantCountryCode.SA]: 'https://amazon.sa/dp/',
  [MerchantCountryCode.BE]: 'https://amaxon.com.be',
};

export interface CampaignSetupDetails {
  lable: string;
  value: string;
  type: string;
}

export interface AdvertisedDetails {
  imageUrl: string;
}

export const radioHeader = {
  ProductGrouping: 'productGrouping',
  AdGroupSplit: 'adGroupSplit',
  CampaignStructure: 'campaignStructure',
  AdGroupSetup: 'adGroupSetup',
  PatProductGrouping: 'patProductGrouping',
  PatAutoCampaignStructure: 'patAutoCampaignStructure',
  PatManualCampaignStructure: 'patManualCampaignStructure',
  PatProductGroupingAdGroup: 'patProductGroupingAdGroup',
  PatTargetingStrategyAdGroup: 'patTargetingStrategyAdGroup',
};

export const radioHeaderPAT = {
  PatProductGrouping: 'patProductGrouping',
  PatAutoCampaignStructure: 'patAutoCampaignStructure',
  PatManualCampaignStructure: 'patManualCampaignStructure',
  PatProductGroupingAdGroup: 'patProductGroupingAdGroup',
  PatTargetingStrategyAdGroup: 'patTargetingStrategyAdGroup',
};

export const radioOption = {
  ParentProduct: CampaignProductAdGroupProductGrouping.Parent,
  BaseItem: CampaignProductAdGroupProductGrouping.BaseItem,
  Sku: CampaignProductAdGroupProductGrouping.Sku,
  Category: CampaignProductAdGroupProductGrouping.Category,
  Shelf: CampaignProductAdGroupProductGrouping.Shelf,
  AutoManual: CampaignStructure.AutoManual,
  AutoBrandNonBrand: CampaignStructure.AutoBrandNonBrand,
  AutoBrandCompetitorGeneric: CampaignStructure.AutoBrandCompetitorGeneric,
  SingleAdGroup: AdGroupStructure.Single,
  BrandNonBrand: AdGroupStructure.BrandNonBrand,
  BrandCompetitorGeneric: AdGroupStructure.BrandCompetitorGeneric,
  PATCampaignStructureSingle: CampaignStructure.SinglePAT,
  PATCampaignStructureCompetitorCategory:
    CampaignStructure.CompetitorCategoryPAT,
  PATCampaignStructureBrandCompetitorCategory:
    CampaignStructure.BrandCompetitorCategoryPAT,
  PATCompetitorCategoryPAT: AdGroupStructure.CompetitorCategoryPAT,
  PATBrandCompetitorCategory: AdGroupStructure.BrandCompetitorCategoryPAT,
};

export interface ContextSetterValue {
  readonly selectedProductGrouping: CampaignProductAdGroupProductGrouping;
  readonly selectedAddGroupSplit: CampaignProductAdGroupProductGrouping;
  readonly selectedCampaignStructure: CampaignStructure;
  readonly selectedAdGroupSetup: AdGroupStructure;
  readonly salesChannels?: AllSalesChannel;
}

export type CampaignFlexibilityDispatch = ThunkDispatch<
  CampaignFlexibilityState,
  CampaignFlexibilityExtraArgs,
  CampaignFlexibilityAction
>;

export interface CampaignFlexibilityThunkExtraArgs {
  readonly campaignFlexibilityApiClient: CampaignFlexibilityApiClient;
}

export interface CampaignFlexibilityState {
  readonly tableState: TableReducerState<any, any>;
}

export type CampaignFlexibilityAction = TableAction;

export interface CampaignFlexibilityExtraArgs {
  readonly campaignFlexibilityClient: CampaignFlexibilityApiClient;
}

export const ELIGIBLE_PRODUCTS_TABLE_ID = 'ELIGIBLE_PRODUCTS_TABLE_ID';
export const ELIGIBLE_ENTER_LIST_PRODUCTS_TABLE_ID =
  'ELIGIBLE_ENTER_LIST_PRODUCTS_TABLE_ID';
export const EST_AD_GROSS_MARGIN_TABLE_ID = 'EST_AD_GROSS_MARGIN_TABLE_ID';
export const RECOMMENDED_ASIN_PRODUCTS_TABLE_ID =
  'RECOMMENDED_ASIN_PRODUCTS_TABLE_ID';
export const KEYWORDS_TABLE_ID = 'KEYWORDS_TABLE_ID';
export const ADD_PRODUCTS_TO_EXISTING_CAMPAIGNS_TABLE_ID =
  'ADD_PRODUCTS_TO_EXISTING_CAMPAIGNS_TABLE_ID';

export const MAP_CAMPAIGN_STRUCTURE_DATA: Record<CampaignStructure, string> = {
  [CampaignStructure.AutoManual]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOMANUAL,
  [CampaignStructure.AutoBrandNonBrand]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOBRANDNONBRAND,
  [CampaignStructure.AutoBrandCompetitorGeneric]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOBRANDCOMPETITORGENERIC,
  [CampaignStructure.SinglePAT]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOBRANDCOMPETITORGENERIC,
  [CampaignStructure.CompetitorCategoryPAT]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOBRANDCOMPETITORGENERIC,
  [CampaignStructure.BrandCompetitorCategoryPAT]:
    I18nKey.CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOBRANDCOMPETITORGENERIC,
};

export enum CampaignFlexibilityRoutes {
  Dashboard = 'dashboard',
  CampaignCreation = 'create',
  Success = 'create/success',
}

export enum CampaignCreatorRoutes {
  Setup = 'setup',
  AddProduct = 'add-product',
  Preview = 'preview',
  KeywordTargeting = 'Keyword_targeting',
  NegativeKeywordTargeting = 'NegativeKeyword_targeting',
  PATKeywordTargeting = ' PATKeyword_targeting',
  PATNegativeKeywordTargeting = ' PATNegativeKeyword_targeting',
}

export enum CampaignCreatorFullRoutes {
  Setup = '/campaign-creator/create/setup',
  AddProduct = '/campaign-creator/create/add-product',
  Preview = '/campaign-creator/create/preview',
  KeywordTargeting = '/campaign-creator/create/Keyword',
  ASINTargeting = '/campaign-creator/create/asin',
  NegativeKeywordTargeting = '/campaign-creator/create/Negative_Keyword',
  ASINNegativeTargeting = '/campaign-creator/create/AsinNegative',
  Success = '/campaign-creator/create/success',
}

export const MAP_CAMPAIGN_CREATOR_ROUTE_TO_FULL_ROUTES: Record<
  string,
  CampaignCreatorFullRoutes
> = {
  [CampaignCreatorRoutes.Setup]: CampaignCreatorFullRoutes.Setup,
  [CampaignCreatorRoutes.AddProduct]: CampaignCreatorFullRoutes.AddProduct,
  [CampaignCreatorRoutes.Preview]: CampaignCreatorFullRoutes.Preview,
  [CampaignCreatorRoutes.KeywordTargeting]:
    CampaignCreatorFullRoutes.KeywordTargeting,
  [CampaignCreatorRoutes.NegativeKeywordTargeting]:
    CampaignCreatorFullRoutes.NegativeKeywordTargeting,
  [CampaignCreatorRoutes.PATKeywordTargeting]:
    CampaignCreatorFullRoutes.ASINTargeting,
  [CampaignCreatorRoutes.PATNegativeKeywordTargeting]:
    CampaignCreatorFullRoutes.ASINNegativeTargeting,
  [CampaignFlexibilityRoutes.Success]: CampaignCreatorFullRoutes.Success,
};

export const BID_CONSTRAINTS = {
  [FlywheelSalesChannel.Amazon]: [
    {
      minBid: 1,
      maxBid: 1000000,
      minacs: 1,
      maxacs: 999,
      countryCodes: [
        MerchantCountryCode.US,
        MerchantCountryCode.FR,
        MerchantCountryCode.NL,
        MerchantCountryCode.GB,
        MerchantCountryCode.DE,
        MerchantCountryCode.ES,
        MerchantCountryCode.IT,
        MerchantCountryCode.CA,
        MerchantCountryCode.SG,
        MerchantCountryCode.MX,
        MerchantCountryCode.BR,
        MerchantCountryCode.SE,
        MerchantCountryCode.AE,
        MerchantCountryCode.IN,
        MerchantCountryCode.AU,
        MerchantCountryCode.JP,
      ],
    },
  ],
  [FlywheelSalesChannel.Walmart]: {
    [MerchantType.Vendor]: [
      {
        minBid: 50,
        maxBid: Infinity,
        minacs: 1,
        maxacs: 999,
        countryCodes: [MerchantCountryCode.US],
      },
    ],
    [MerchantType.Seller]: [
      {
        minBid: 10,
        maxBid: Infinity,
        minacs: 1,
        maxacs: 999,
        countryCodes: [MerchantCountryCode.US],
      },
    ],
  },
};

export const FLYWHEEL_SETTINGS_HELP_LINK =
  'https://intercom.help/flywheel-20/en/articles/5643813-what-is-an-acos-target';

export enum actiondailyBudget {
  edit = 'edit',
  select = 'select',
}

export const ROWS_PER_PAGE = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_ROWS_PER_PAGE = 5;

// Default Page Size of Eligible Products Table on Step 2
export const DEFAULT_PRODUCT_GROUPS_PER_PAGE = 25;

// Default Page Size of Eligible Products Table if Category or Shelf Grouping is selected
export const DEFAULT_CATEGORIES_PER_PAGE = 5;

export const CATEGORY_PER_PAGE_OPTIONS = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
];

interface AutomatedBiddingData {
  heading: I18nKey;
  subDescriptionText: I18nKey;
  description?: string;
}
export const MAP_AUTOMATED_BIDDING_TO_DATA: Record<
  FlywheelSalesChannel,
  AutomatedBiddingData
> = {
  [FlywheelSalesChannel.Amazon]: {
    heading: I18nKey.CAMPAIGN_FLEXIBILITY_BANNER_ADVANCED_HEADING,
    description: I18nKey.CAMPAIGN_FLEXIBILITY_BANNER_ADVANCED_MAIN_TEXT,
    subDescriptionText: I18nKey.CAMPAIGN_FLEXIBILITY_BANNER_ADVANCED_SUB_TEXT,
  },
  [FlywheelSalesChannel.Walmart]: {
    heading: I18nKey.CAMPAIGN_FLEXIBILITY_BANNER_ADVANCED_HEADING,
    description: I18nKey.CAMPAIGN_FLEXIBILITY_BANNER_WALMART_ADVANCED_MAIN_TEXT,
    subDescriptionText:
      I18nKey.CAMPAIGN_FLEXIBILITY_BANNER_WALMART_ADVANCED_SUB_TEXT,
  },
};

export enum LevelType {
  SKUs = 'SKUs',
  Keywords = 'Keywords',
  Categories = 'Categories',
  NegativeKeywords = 'Negative Keywords',
  ASIN = 'ASIN Targeting',
  NegativeASIN = 'Exclude Product',
}

export enum PatLevelType {
  ASIN_Targeting = 'ASIN_Targeting',
  Categories_Targeting = 'Categories_Targeting',
  ExcludeBrandTargeting = 'ExcludeBrandTargeting',
  ExcludeProductTargeting = 'ExcludeProductTargeting',
}

export enum KeywordsSubLevelType {
  Recommendedkeywords = 0,
  Enterlist = 1,
}

export enum CampaignPreviewLevelType {
  Summary = 0,
  Details = 1,
}

export const MAP_ICONS_TARGET_SEGMENTS: Record<TargetSegment, SvgIcon> = {
  [TargetSegment.Brand]: StarIcon,
  [TargetSegment.Competitor]: SwordsCrossedIcon,
  [TargetSegment.Generic]: UpcIcon,
  [TargetSegment.Auto]: CycleDotIcon,
};

export enum KeywordDeleteType {
  All = 'All',
  Single = 'Single',
  parentSku = 'parentSku',
  sku = 'sku',
}
export interface CurrentCampaignData {
  sku: string;
  extItemId: string;
}

export const MAP_AD_GROUP_STRUCTURE: Record<AdGroupStructure, I18nKey> = {
  [AdGroupStructure.Single]:
    I18nKey.CAMPAIGN_FLEXIBILITY_AD_GROUP_SETUP_SINGLE_AD_GROUP_TITLE,
  [AdGroupStructure.BrandNonBrand]:
    I18nKey.CAMPAIGN_FLEXIBILITY_AD_GROUP_SETUP_BRAND_NONBRAND_TITLE,
  [AdGroupStructure.BrandCompetitorGeneric]:
    I18nKey.CAMPAIGN_FLEXIBILITY_AD_GROUP_SETUP_BRAND_COMPETITOR_GENERIC_TITLE,
  [AdGroupStructure.CompetitorCategoryPAT]:
    I18nKey.CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_BRAND_NONBRAND_TITLE,
  [AdGroupStructure.BrandCompetitorCategoryPAT]:
    I18nKey.CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_BRAND_COMPETITOR_GENERIC_TITLE,
};

export enum TargetType {
  Generic = 'Generic',
  Competitor = 'Competitor',
  Brand = 'Brand',
  Auto = 'Automatic',
  Manual = 'Manual',
  NoBrand = 'Non Brand',
  BrandPAT = 'Brand PAT',
  CompetitorPAT = 'Competitor PAT',
  CategoryPAT = 'Category PAT',
  SinglePAT = 'Single PAT',
}

export interface CurrentCampaignDataIcon {
  heading: string;
  icon: SvgIcon;
  iconClass?: string;
}

export const MAP_CAMPAIGN_STRUCTURE: Record<
  CampaignStructure,
  CurrentCampaignDataIcon[]
> = {
  [CampaignStructure.AutoManual]: [
    {
      heading: TargetType.Auto,
      icon: CycleDotIcon,
      iconClass: 'text-blue-500',
    },
    {
      heading: TargetType.Manual,
      icon: CycleDotIcon,
      iconClass: 'text-grey-500',
    },
  ],
  [CampaignStructure.AutoBrandNonBrand]: [
    {
      heading: TargetType.Auto,
      icon: CycleDotIcon,
      iconClass: 'text-blue-500',
    },
    { heading: TargetType.Brand, icon: StarIcon, iconClass: 'text-purple-500' },
    {
      heading: TargetType.NoBrand,
      icon: StarIcon,
      iconClass: 'text-grey-500',
    },
  ],
  [CampaignStructure.AutoBrandCompetitorGeneric]: [
    {
      heading: TargetType.Auto,
      icon: CycleDotIcon,
      iconClass: 'text-blue-500',
    },
    { heading: TargetType.Brand, icon: StarIcon, iconClass: 'text-purple-500' },
    {
      heading: TargetType.Competitor,
      icon: SwordsCrossedIcon,
    },
    { heading: TargetType.Generic, icon: UpcIcon, iconClass: 'text-grey-500' },
  ],
  [CampaignStructure.SinglePAT]: [
    {
      heading: TargetType.Auto,
      icon: CycleDotIcon,
      iconClass: 'text-blue-500',
    },
    {
      heading: TargetType.SinglePAT,
      icon: CycleDotIcon,
      iconClass: 'text-grey-500',
    },
  ],
  [CampaignStructure.CompetitorCategoryPAT]: [
    {
      heading: TargetType.Auto,
      icon: CycleDotIcon,
      iconClass: 'text-blue-500',
    },
    {
      heading: TargetType.CompetitorPAT,
      icon: StarIcon,
      iconClass: 'text-purple-500',
    },
  ],
  [CampaignStructure.BrandCompetitorCategoryPAT]: [
    {
      heading: TargetType.Auto,
      icon: CycleDotIcon,
      iconClass: 'text-blue-500',
    },
    {
      heading: TargetType.BrandPAT,
      icon: StarIcon,
      iconClass: 'text-purple-500',
    },
    {
      heading: TargetType.CompetitorPAT,
      icon: SwordsCrossedIcon,
    },
  ],
};

export const MAP_CAMPAIGN_STRUCTURE_WITHOUT_AUTO: Record<
  CampaignStructure,
  CurrentCampaignDataIcon[]
> = {
  [CampaignStructure.AutoManual]: [
    {
      heading: TargetType.Auto,
      icon: CycleDotIcon,
      iconClass: 'text-blue-500',
    },
    {
      heading: TargetType.Manual,
      icon: CycleDotIcon,
      iconClass: 'text-grey-500',
    },
  ],
  [CampaignStructure.AutoBrandNonBrand]: [
    {
      heading: TargetType.Auto,
      icon: CycleDotIcon,
      iconClass: 'text-blue-500',
    },
    { heading: TargetType.Brand, icon: StarIcon, iconClass: 'text-purple-500' },
    {
      heading: TargetType.NoBrand,
      icon: StarIcon,
      iconClass: 'text-grey-500',
    },
  ],
  [CampaignStructure.AutoBrandCompetitorGeneric]: [
    {
      heading: TargetType.Auto,
      icon: CycleDotIcon,
      iconClass: 'text-blue-500',
    },
    { heading: TargetType.Brand, icon: StarIcon, iconClass: 'text-purple-500' },
    {
      heading: TargetType.Competitor,
      icon: SwordsCrossedIcon,
    },
    { heading: TargetType.Generic, icon: UpcIcon, iconClass: 'text-grey-500' },
  ],
  [CampaignStructure.SinglePAT]: [
    {
      heading: TargetType.SinglePAT,
      icon: CycleDotIcon,
      iconClass: 'text-grey-500',
    },
  ],
  [CampaignStructure.CompetitorCategoryPAT]: [
    {
      heading: TargetType.CompetitorPAT,
      icon: StarIcon,
      iconClass: 'text-purple-500',
    },
  ],
  [CampaignStructure.BrandCompetitorCategoryPAT]: [
    {
      heading: TargetType.BrandPAT,
      icon: StarIcon,
      iconClass: 'text-purple-500',
    },
    {
      heading: TargetType.CompetitorPAT,
      icon: SwordsCrossedIcon,
    },
  ],
};

export const MAP_CAMPAIGN_TO_STEPS_STATUS: Record<
  CampaignCreatorRoutes,
  I18nKey
> = {
  [CampaignCreatorRoutes.Setup]:
    I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PRE_SETUPCAMPAIGNS,
  [CampaignCreatorRoutes.AddProduct]:
    I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PRE_ADDPRODUCTS,
  [CampaignCreatorRoutes.KeywordTargeting]:
    I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PRE_ADDPRODUCTS,
  [CampaignCreatorRoutes.NegativeKeywordTargeting]:
    I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PRE_ADDPRODUCTS,
  [CampaignCreatorRoutes.PATKeywordTargeting]:
    I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PRE_ADDPRODUCTS,
  [CampaignCreatorRoutes.PATNegativeKeywordTargeting]:
    I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PRE_ADDPRODUCTS,
  [CampaignCreatorRoutes.Preview]:
    I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PRE_PREVIEW_AND_LAUNCH,
};

export enum TargetSegments {
  Auto = 'Auto',
  Brand = 'Brand',
  Competitor = 'Competitor',
  Generic = 'Generic',
  Unknown = 'Unknown',
  Category = 'Category',
}

export interface AdGroupInfoV2 {
  readonly id: string;
  readonly name: string;
  readonly campaign: CampaignInfo;
  readonly targetSegment: TargetSegments[];
  readonly targetSegments?: TargetSegments[];
}

export interface CampaignInfoWithAdGroups extends CampaignInfo {
  readonly advertisingGoal: any;
  readonly adGroups: AdGroupInfoV2[];
  readonly selectedAdGroupIds?: string[];
}

export enum TargetingType {
  Auto = 'auto',
  Manual = 'manual',
}
