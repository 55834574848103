import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import { CampaignDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  getEditModePlacementStatusOption,
  isManualCampaign,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, ...campaignDetails }) => {
  const selectedItemCarouselPlacement = useSelector<
    WithTable<CampaignDetails>,
    string
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      campaignDetails.campaignId,
      CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement
    )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const dispatch = useDispatch();

  const intl = useIntl();

  if (
    isEditMode &&
    isManualCampaign(campaignDetails.campaignDetails.targetingType)
  ) {
    return makeSelectColumn<CampaignDetails>(
      ({ channelSettings: { itemCarousel } }) => {
        const value = isNil(selectedItemCarouselPlacement)
          ? String(itemCarousel)
          : selectedItemCarouselPlacement;

        return {
          dataTestId: CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement,
          options: getEditModePlacementStatusOption(intl),
          className: 'w-108',
          placeholder: intl.formatMessage({
            id: I18nKey.SELECT_NOT_SET,
          }),
          onChange: (selectedValue: string) => {
            dispatch(
              tableActions.updateCell({
                tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
                columnName: CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement,
                rowId: campaignDetails.campaignId,
                existingValue: String(itemCarousel),
                value: selectedValue,
              })
            );
          },
          value,
          isDirty: value !== itemCarousel,
        };
      }
    )(campaignDetails);
  }

  return makeTextColumn<CampaignDetails>(
    ({ channelSettings: { itemCarousel } }) =>
      itemCarousel ? capitalize(itemCarousel) : undefined,
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const itemCarouselPlacementColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ITEM_CAROUSEL_PLACEMENT,
  RowCellElement,
  gridColumnWidth: '140px',
};

export const itemCarouselPlacementFilterCampaign =
  createMultiSelectDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ITEM_CAROUSEL_PLACEMENT,
    [
      SelectFilterOption.CampaignPlacementStatusExcluded,
      SelectFilterOption.CampaignPlacementStatusIncluded,
    ]
  );
