import { IdToken } from '@auth0/auth0-react';
import {
  default as Axios,
  RawAxiosRequestConfig,
  default as axios,
} from 'axios';
import axiosRetry from 'axios-retry';
import { getDefaultHeaders } from '.';
import { DEFAULT_RETRY_INTERVAL } from '../types/HttpErrorCodes';
import {
  AdGroupsSummary,
  EnterCogsRecommendationPayload,
  EnterCogsRecommendationResponse,
  RecommendationsActionRequest,
  RecommendationsActionResponse,
  RecommendationsCountResponse,
  RecommendationsRequest,
  RecommendationsSummaryPaginatedResponse,
} from '../types/RecommendationsSharedTypes';
import { PaginatedRequest } from './types';
import { transformRecommendationEnterCogsSummaryResponse } from './utils';
import { BudgetResponse } from '../../modules/recommendations/components/recommendationCards';
import size from 'lodash/size';
import { filtersToQueryString } from '../utilities/buildUrlUtilities';
import { FilterOps } from '../types/Filter';

const BASE_URL = process.env.REACT_APP_AO_HOST;
const IO_BASE_URL = process.env.REACT_APP_SKU_HOST;
const DEFAULT_LIMIT = 10;
const DEFAULT_OFFSET = 0;

export interface RecommendationsApiClient {
  readonly getAutomatedBiddingSummary: (
    accountId: string
  ) => (
    request: RecommendationsRequest,
    searchText?: string,
    offset?: number,
    limit?: number,
    shouldRetry?: () => boolean
  ) => Promise<RecommendationsSummaryPaginatedResponse>;

  readonly getEnterCogsSummary: () => (
    accountId: string
  ) => (
    request: PaginatedRequest,
    payload: { merchantCountryIds: string[] }
  ) => Promise<EnterCogsRecommendationResponse>;

  readonly recommendationsAction: (
    accountId: string
  ) => (
    request: RecommendationsActionRequest
  ) => Promise<RecommendationsActionResponse[]>;

  readonly recommendationAutomatedBiddingCount: (
    accountId: string,
    merchantCountryIds: string[],
    shouldRetry?: () => boolean
  ) => Promise<RecommendationsCountResponse[]>;
  readonly recommendationBudgetInsightsCount: (
    accountId: string
  ) => Promise<BudgetResponse>;
}

export const PATHS = Object.freeze({
  RECOMMENDATIONS_SUMMARY: (accountId: string) =>
    `accounts/${accountId}/recommendation-hub/automated-bidding/view-recommendations`,
  MISSING_COGS_SUMMARY: (accountId: string) =>
    `accounts/${accountId}/missing-cogs/products`,
  RECOMMENDATIONS_ACTION: (accountId: string) =>
    `accounts/${accountId}/recommendation-hub/automated-bidding/recommendations-action`,
  RECOMMENDATION_AUTOMATED_BIDDING_COUNT: (accountId: string) =>
    `/accounts/${accountId}/recommendation-hub/automated-bidding/recommendations-count`,
  RECOMMENDATION_UNADVERTISED_PRODUCT_COUNT: (accountId: string) =>
    `/accounts/${accountId}/recommendation-hub/unadvertised/products-count`,
  RECOMMENDATION_BUDGET_INSIGHTS_COUNT: (accountId: string) =>
    `/accounts/${accountId}/recommendation-hub/budget-insights/counts`,
});

export const createRecommendationsApiClient = (
  token: IdToken | null
): RecommendationsApiClient => {
  const config: RawAxiosRequestConfig = {
    baseURL: BASE_URL,
    headers: {
      ...getDefaultHeaders(),
      Authorization: `Bearer ${token?.__raw}`,
    },
  };

  const getAutomatedBiddingSummary =
    (accountId: string) =>
    async (
      request: RecommendationsRequest,
      searchText?: string,
      offset?: number,
      limit?: number,
      shouldRetry?: () => boolean
    ) => {
      let axiosInstance = Axios.create();

      if (offset && offset > 0) {
        axiosRetry(axiosInstance, {
          retries: 10,
          retryDelay: (retryCount: number) =>
            retryCount * DEFAULT_RETRY_INTERVAL,
          retryCondition: shouldRetry,
        });
      }

      const response =
        await axiosInstance.post<RecommendationsSummaryPaginatedResponse>(
          PATHS.RECOMMENDATIONS_SUMMARY(accountId),
          request,
          {
            ...config,
            params: {
              limit: limit || DEFAULT_LIMIT,
              offset: offset || DEFAULT_OFFSET,
              filter: searchText
                ? filtersToQueryString([
                    {
                      op: FilterOps.like,
                      field: 'searchText',
                      value: searchText,
                    },
                  ])
                : '',
            },
          }
        );
      return {
        ...response.data,
        recommendations: response.data.recommendations.map((recommendation) => {
          return {
            ...recommendation,
            subRows: [
              ...recommendation.adGroups!.map((adGroup: AdGroupsSummary) => ({
                ...adGroup,
                campaignId: recommendation.campaignId,
              })),
            ],
          };
        }),
      };
    };

  const getEnterCogsSummary = () => {
    let controller = new AbortController();
    return (accountId: string) =>
      async (
        request: PaginatedRequest,
        payload: EnterCogsRecommendationPayload
      ): Promise<EnterCogsRecommendationResponse> => {
        if (controller) {
          controller.abort();
        }
        if (
          !payload.merchantCountryIds ||
          payload.merchantCountryIds?.length === 0
        ) {
          return {
            items: [],
            totalItems: 0,
          };
        }
        controller = new AbortController();
        const signal = controller.signal;

        const response = await axios.post(
          PATHS.MISSING_COGS_SUMMARY(accountId),
          { ...request, ...payload },
          {
            ...config,
            params: {
              limit: request?.itemsPerPage || DEFAULT_LIMIT,
              offset:
                request.itemsPerPage && request.page
                  ? ((request.page as number) - 1) * request.itemsPerPage
                  : DEFAULT_OFFSET,
            },
            baseURL: IO_BASE_URL,
            signal,
          }
        );
        return transformRecommendationEnterCogsSummaryResponse(response.data);
      };
  };

  const recommendationsAction =
    (accountId: string) => async (request: RecommendationsActionRequest) => {
      const response = await axios.post<RecommendationsActionResponse[]>(
        PATHS.RECOMMENDATIONS_ACTION(accountId),
        request,
        config
      );
      return response.data;
    };

  const recommendationAutomatedBiddingCount = async (
    accountId: string,
    merchantCountryIds: string[],
    shouldRetry?: () => boolean
  ) => {
    if (size(merchantCountryIds)) {
      let axiosInstance = Axios.create();
      axiosRetry(axiosInstance, {
        retries: 3,
        retryDelay: (retryCount: number) => retryCount * DEFAULT_RETRY_INTERVAL,
        retryCondition: shouldRetry,
      });

      const response = await axiosInstance.post<RecommendationsCountResponse[]>(
        PATHS.RECOMMENDATION_AUTOMATED_BIDDING_COUNT(accountId),
        { merchantCountryIds },
        {
          ...config,
        }
      );
      return response.data;
    }
    return [];
  };

  const recommendationBudgetInsightsCount = async (accountId: string) => {
    const response = await axios.get<BudgetResponse>(
      PATHS.RECOMMENDATION_BUDGET_INSIGHTS_COUNT(accountId),
      config
    );
    return response.data;
  };

  return {
    getAutomatedBiddingSummary,
    getEnterCogsSummary,
    recommendationsAction,
    recommendationAutomatedBiddingCount,
    recommendationBudgetInsightsCount,
  };
};
