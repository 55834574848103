import {
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makePercentColumn } from '../../../../../containers/table/utils/makeTableCells';
import { SbAdsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { SB_ADS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<SbAdsDetails & TableDataAdsManager> = (
  props
) =>
  makePercentColumn<SbAdsDetails>(
    ({ creativePerformance: { newToBrandOrderRate } }) => {
      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        undefined,
        newToBrandOrderRate
      );

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const newToBrandOrdersRateColumn: FlywheelTableColumn<
  SbAdsDetails,
  TableDataAdsManager
> = {
  columnName: SB_ADS_API_COLUMN_NAME.NewToBrandOrdersRate,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS_RATE,
  RowCellElement,
  gridColumnWidth: '130px',
  className: 'text-right',
};

export const newToBrandOrdersRateFilter = createPercentDataFieldFilter(
  SB_ADS_API_COLUMN_NAME.NewToBrandOrdersRate,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS_RATE,
  isValidNumber()
);
