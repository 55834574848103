import capitalize from 'lodash/capitalize';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { MultiSelectOption } from '../../../../../components/Filter/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  FlywheelSalesChannel,
  ProfileApiDataDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { PROFILES_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<ProfileApiDataDetails> = (
  profileDetails
) => {
  return makeTextColumn((data: ProfileApiDataDetails) =>
    data.profileDetails.campaignStatus
      ? capitalize(data.profileDetails.campaignStatus)
      : undefined
  )(profileDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignStatusColumn: FlywheelTableColumn<ProfileApiDataDetails> =
  {
    columnName: PROFILES_API_COLUMN_NAME.CampaignStatus,
    isSortable: true,
    i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_STATUS,
    RowCellElement,
    gridColumnWidth: '160px',
  };

export const campaignStatusFilterForProfiles = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
) =>
  createMultiSelectDataFieldFilter(
    PROFILES_API_COLUMN_NAME.CampaignStatus,
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_STATUS,
    getCampaignStatusOptions(selectedSalesChannel, selectedAdType)
  );

const getCampaignStatusOptions = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
): MultiSelectOption[] => {
  switch (selectedSalesChannel) {
    case FlywheelSalesChannel.Walmart: {
      if (selectedAdType === AdType.SearchBrandAmplifier) {
        return [
          SelectFilterOption.CampaignStatusPaused,
          SelectFilterOption.CampaignStatusCompleted,
          SelectFilterOption.CampaignStatusRescheduled,
          SelectFilterOption.CampaignStatusLive,
          SelectFilterOption.CampaignStatusProposal,
          SelectFilterOption.CampaignStatusScheduled,
        ];
      }
      return [];
    }
    /**
      The fallback default should never occur as Sales Channel will always fall in one of above 
      cases hence safe to return [], can handle with default options in future
    * 
    */
    default:
      return [];
  }
};
