import React from 'react';
import { useIntl } from 'react-intl';

import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../lib/types/I18nKey';

interface FooterProps {
  readonly onCancelClick: () => void;
  readonly onContinueClick: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  onCancelClick,
  onContinueClick,
}) => {
  const intl = useIntl();
  return (
    <div className="flex flex-row items-center justify-end gap-x-8 w-full pt-8">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.BlackAndWhiteBorder}
        label={intl.formatMessage({
          id: I18nKey.GENERIC_CANCEL_LABEL,
        })}
        onClick={onCancelClick}
      />
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.Primary}
        label={intl.formatMessage({
          id: I18nKey.GENERIC_CONTINUE,
        })}
        onClick={onContinueClick}
      />
    </div>
  );
};
