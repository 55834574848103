import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeNumericInputColumn,
  makePercentColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  CampaignDetails,
  CampaignStatus,
} from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import {
  AdLevelI8nKeyMapper,
  NumericValueConfig,
  TableDataAdsManager,
} from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getAutomationDisabledTooltipMessage,
  getTooltipContentForArchivedEntity,
  isCampaignStatusArchived,
  isInputValueNumber,
  isValidFlywheelSetting,
} from '../utils';

const DEFAULT_PLACEHOLDER_VALUE = 0;

const checkValidFlywheelSettings = (
  props: CampaignDetails & TableDataAdsManager,
  currentBidAutomationStatus?: string,
  currentMacsTarget?: string,
  currentMinBid?: string,
  currentMaxBid?: string
) =>
  isValidFlywheelSetting(
    props.flywheelSettings,
    {
      minBid: currentMinBid,
      maxBid: currentMaxBid,
      macsTarget: currentMacsTarget,
    },
    currentMacsTarget
  );

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) => {
  const { isEditMode } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);
  const bidConstraintsData = useBidConstraintsContext();

  const { campaignCostType } = props.campaignDetails;

  const currentBidAutomationStatus = useSelector<
    WithTable<CampaignDetails>,
    string
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      props.campaignId,
      CAMPAIGNS_API_COLUMN_NAME.AutomationStatus
    )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentMinBid = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MinBid
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentMaxBid = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MaxBid
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const updatedMacsTarget = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MACSTarget
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const validationConfig = useMemo(() => {
    const constrainsts = getBidConstraint(
      bidConstraintsData.constraints,
      props.selectedAdType,
      props.salesChannel,
      CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
      props.merchantCountry,
      undefined,
      props.merchantType,
      campaignCostType
    );

    return {
      min: constrainsts.minMacsTarget,
      max: constrainsts.maxMacsTarget,
      default: constrainsts.defaultMacsTarget,
    };
  }, [props.selectedAdType, props.salesChannel, props.merchantCountry]);

  const existingValue = getExistingValue(props.flywheelSettings?.macsTarget);

  let macsTarget = getCurrentMacsTarget(updatedMacsTarget, existingValue);

  const isInputEdited = macsTarget !== existingValue;

  const [value, setValue] = useState<string>(macsTarget);

  useEffect(() => {
    if (
      isEditMode || //Changing between modes
      (!isNil(updatedMacsTarget) && updatedMacsTarget !== value) // Changes done due to bulk update. updatedMacsTarget is latest, but state variable is not
    ) {
      setValue(macsTarget);
    }
  }, [isEditMode, currentPage, updatedMacsTarget]);

  const updateCellValue = (newMacsTarget: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        columnName: CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
        rowId: props.campaignId,
        value: newMacsTarget,
        existingValue: existingValue || '',
        numericValue: true,
      })
    );
  };

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [props.campaignId]
  );

  const isValidSettings = useMemo(
    () =>
      checkValidFlywheelSettings(
        props,
        currentBidAutomationStatus,
        macsTarget,
        currentMinBid,
        currentMaxBid
      ),
    [props, currentBidAutomationStatus, currentMinBid, currentMaxBid]
  );

  if (isEditMode) {
    let isEditedMacsTargetInvalid = isMacsTargetInvalid(
      value,
      validationConfig.min,
      validationConfig.max,
      existingValue,
      () => !isValidSettings
    );

    const automationDisabledForMerchant = isAutomationDisabled(
      props.merchantsWithAutomationEnabled,
      props.aiEnabled,
      props.campaignDetails?.merchantCountryId
    );

    let macsNumericInputState: NumericInputState;
    const isCampaignArchived = isCampaignStatusArchived(
      props.channelSettings.status
    );

    macsNumericInputState = getNumericInputState(
      isInputEdited,
      isEditedMacsTargetInvalid,
      automationDisabledForMerchant,
      props.aiEnabled,
      props.channelSettings.status
    );

    if (!isValidSettings) {
      isEditedMacsTargetInvalid = true;
      macsNumericInputState = NumericInputState.Error;
    }

    const onMacsNumericInputChange = (inputValue: string) => {
      if (isInputValueNumber(inputValue)) {
        setValue(inputValue);
        debouncedUpdateCellValue(inputValue);
      }
    };

    const onInputFocus = () => setFocussed(true);
    const onInputBlur = () => {
      if (!isEmpty(value)) {
        debouncedUpdateCellValue.cancel();
        const formattedValue = Number.parseFloat(value).toFixed(
          PERCENTAGE_FRACTION_DIGITS
        );
        setValue(formattedValue);
        updateCellValue(formattedValue);
      }
      setFocussed(false);
    };

    const macsNumericInputProps: NumericInputProps & TypedTooltipProps = {
      value,
      state: macsNumericInputState,
      appendedElement: '%',
      onInputBlur: onInputBlur,
      onChange: onMacsNumericInputChange,
      tooltipContent: getTooltipContent({
        intl,
        isEditedMacsTargetInvalid,
        validationConfig,
        isCampaignArchived,
        value,
        existingValue,
        aiEnabled: !automationDisabledForMerchant,
        isValidSettings,
      }),
      controlledTooltip: getTootlipState(
        isCampaignArchived,
        focussed,
        macsNumericInputState
      ),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus: onInputFocus,
      isDirty: isDirty(value, existingValue),
      acceptOnlyPositiveNumbers: true,
      placeholder: intl.formatNumber(
        validationConfig.default ?? DEFAULT_PLACEHOLDER_VALUE,
        {
          minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
          maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        }
      ),
      minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      dataTestId: 'ao_acosTarget',
    };

    return makeNumericInputColumn(() => macsNumericInputProps)(props);
  }

  return makePercentColumn((data: CampaignDetails) =>
    data.flywheelSettings?.macsTarget?.toString()
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

const getTooltipContent = (args: {
  intl: IntlShape;
  isEditedMacsTargetInvalid: boolean;
  validationConfig: NumericValueConfig;
  isCampaignArchived: boolean;
  value: string;
  existingValue: string;
  aiEnabled?: boolean;
  isValidSettings?: boolean;
}) => {
  const {
    intl,
    isEditedMacsTargetInvalid,
    validationConfig,
    isCampaignArchived,
    value,
    existingValue,
    aiEnabled,
    isValidSettings,
  } = args;

  if (aiEnabled === false) {
    return getAutomationDisabledTooltipMessage(
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MACS_TARGET,
      })
    );
  }
  if (isCampaignArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MACS_TARGET,
      }),
      intl
    );
  } else if (!isValidSettings || isDirty(value, existingValue)) {
    if (
      isEditedMacsTargetInvalid &&
      !isNil(validationConfig.min) &&
      !isNil(validationConfig.max)
    ) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_MACS_VALUE,
            },
            {
              minValue: intl.formatNumber(validationConfig.min, {
                maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
              }),
              maxValue: intl.formatNumber(validationConfig.max, {
                maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
              }),
            }
          )}
        </p>
      );
    } else if (isEditedMacsTargetInvalid && !isNil(validationConfig.min)) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_INVALID_MACS_VALUE,
            },
            {
              minValue: intl.formatNumber(validationConfig.min, {
                maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
              }),
            }
          )}
        </p>
      );
    }
  }
};

const getExistingValue = (macsTarget?: number) =>
  !isNil(macsTarget)
    ? (macsTarget * 100).toFixed(PERCENTAGE_FRACTION_DIGITS)
    : '';

const getTootlipState = (
  isCampaignArchived: boolean,
  focussed: boolean,
  macsNumericInputState: NumericInputState
) => {
  if (isCampaignArchived) {
    return ControlledTooltip.None;
  } else {
    return focussed && macsNumericInputState === NumericInputState.Error
      ? ControlledTooltip.Show
      : ControlledTooltip.Hide;
  }
};

const getCurrentMacsTarget = (
  updatedMacsTarget: string,
  existingValue: string
) => updatedMacsTarget ?? existingValue;

const isAutomationDisabled = (
  merchantsWithAutomationEnabled?: string[],
  aiEnabled?: boolean,
  merchantCountryId?: string
) => {
  return (
    !aiEnabled &&
    !merchantsWithAutomationEnabled?.includes(merchantCountryId ?? '')
  );
};

const getNumericInputState = (
  isInputEdited: boolean,
  isEditedMacsTargetInvalid: boolean,
  automationDisabledForMerchant: boolean,
  aiEnabled?: boolean,
  campaignStatus?: CampaignStatus
) => {
  if (isInputEdited && aiEnabled) {
    return isEditedMacsTargetInvalid
      ? NumericInputState.Error
      : NumericInputState.Default;
  } else if (
    isCampaignStatusArchived(campaignStatus) ||
    automationDisabledForMerchant
  ) {
    return NumericInputState.Disabled;
  } else {
    return NumericInputState.Default;
  }
};

const isDirty = (value: string, existingValue: string) =>
  value || existingValue ? value !== existingValue : false;

export const macsTargetColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MACS_TARGET,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const macsTargetFilter = createPercentDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MACS_TARGET,
  isValidNumber(),
  true
);

export function isMacsTargetInvalid(
  macsTargetValue: string,
  minLimit?: number,
  maxLimit?: number,
  existingValue?: string,
  extraValidation?: () => boolean
): boolean {
  const isInvalidValidTarget =
    existingValue === '' && macsTargetValue === existingValue
      ? false
      : (!isNil(minLimit) && Number(macsTargetValue) < minLimit) ||
        (!isNil(maxLimit) && Number(macsTargetValue) > maxLimit);
  if (extraValidation) {
    return isInvalidValidTarget && !extraValidation();
  }
  return isInvalidValidTarget;
}
