import { useIntl } from 'react-intl';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { AdGroupDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  ADGROUPS_API_COLUMN_NAME,
  CAMPAIGN_TACTIC_I18NKEY_MAPPER,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<AdGroupDetails> = ({
  ...adGroupDetails
}) => {
  const intl = useIntl();

  return makeTextColumn<AdGroupDetails>(
    ({ adGroupDetails: { campaignTactic } }) =>
      (campaignTactic &&
        intl.formatMessage({
          id: CAMPAIGN_TACTIC_I18NKEY_MAPPER[campaignTactic],
        })) ||
      undefined
  )(adGroupDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignTacticColumn: FlywheelTableColumn<AdGroupDetails> = {
  columnName: ADGROUPS_API_COLUMN_NAME.CampaignTactic,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_TACTIC,
  RowCellElement,
  gridColumnWidth: '140px',
};

export const campaignTacticFilter = createMultiSelectDataFieldFilter(
  ADGROUPS_API_COLUMN_NAME.CampaignTactic,
  I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_TACTIC,
  [
    SelectFilterOption.CampaignTacticLegacy,
    SelectFilterOption.CampaignTacticProduct,
    SelectFilterOption.CampaignTacticAudiences,
    SelectFilterOption.CampaignTacticViews,
  ]
);
