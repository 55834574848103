import isNil from 'lodash/isNil';
import { useContext, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeTruncatedTextLinkColumn,
  representSmartCampaign,
} from '../../../../../containers/table/utils/makeTableCells';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../../containers/userProvider/userProvider';
import {
  AdType,
  AdvertisingGoal,
  AiBiddingValues,
  CampaignTargetingType,
  ProfileApiDataDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { getAdManagerUrl, getAdsLevelUri } from '../../../utils';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
  TableDataAdsManager,
} from '../types';
import { PROFILES_API_COLUMN_NAME } from '../utils';
import { getAdsManagerDetailsQueryParams } from '../utils/queryParamsUtils';
import {
  getMerchantFromLocalStorage,
  getSalesChannelFromLocalStorage,
} from '../utils/storageUtils';
import { transformAdvertisingGoal } from '../adgroupTableColumns/utils';

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = ({
  profileId,
  profileDetails,
  channelSettings,
  profilePerformance,
  adTypes,
  aiEnabled,
  merchantsWithAutomationEnabled,
  selectedAdType,
  showSmartCampaign,
}) => {
  const data: ProfileApiDataDetails = {
    profileId,
    profileDetails,
    channelSettings,
    profilePerformance,
  };

  const location = useLocation();
  const baseUrl = useMemo(
    () => getAdManagerUrl(location.pathname),
    [location.pathname]
  );

  const searchQueryParams = new URLSearchParams(
    decodeURIComponent(location.search)
  );

  const userContext = useContext(UserContext);

  const userId = userContext.userInfo.userDetails?.id ?? '';
  const accountId = getCurrentAccountFromContext(userContext)?.id ?? '';

  const salesChannelId = searchQueryParams.get(SALES_CHANNEL_ID);
  const merchantCountryIds = searchQueryParams.get(MERCHANT_COUNTRY_IDS);
  const adTypesFromQueryParams = searchQueryParams.get(AD_TYPE);

  const selectedSalesChannel = getSalesChannelFromLocalStorage(
    userId || '',
    accountId || ''
  );
  const salesChannel = isNil(salesChannelId)
    ? String(selectedSalesChannel)
    : salesChannelId;

  const merchantCountries = isNil(merchantCountryIds)
    ? (getMerchantFromLocalStorage({
        userId,
        accountId,
        salesChannelId: salesChannelId || String(selectedSalesChannel),
      }) as (string | number)[])
    : merchantCountryIds.split(',');

  const adType = isNil(adTypesFromQueryParams)
    ? adTypes
    : (adTypesFromQueryParams.split(',') as AdType[]);

  const aiBidding: AiBiddingValues =
    aiEnabled &&
    merchantsWithAutomationEnabled &&
    merchantsWithAutomationEnabled?.length > 0
      ? AiBiddingValues.Ai
      : AiBiddingValues.NotEnabled;

  const goal: AdvertisingGoal = transformAdvertisingGoal(
    aiBidding,
    profileDetails.advertisingGoal
  );
  return makeTruncatedTextLinkColumn(
    (profileData: ProfileApiDataDetails) =>
      profileData.profileDetails?.campaignName,
    (profileData: ProfileApiDataDetails) =>
      profileData.profileDetails?.campaignId !== undefined
        ? getAdsLevelUri(
            baseUrl,
            ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns,
            profileData.profileDetails?.campaignId
          ) +
          getAdsManagerDetailsQueryParams(
            salesChannel,
            merchantCountries,
            adType
          )
        : '',
    undefined,
    undefined,
    undefined,
    representSmartCampaign(
      goal,
      profileDetails.campaignTargetingType || CampaignTargetingType.Manual,
      selectedAdType,
      showSmartCampaign
    )
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignNameColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.CampaignName,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_NAME,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const profileCampaignNameFilter = createStringDataFieldFilter(
  PROFILES_API_COLUMN_NAME.CampaignName,
  I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_NAME,
  isValidString()
);
