import {
  AmazonLogomarkEnabledIcon,
  PaletteColor,
  SelectOptionProps,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';
import { WALMART_SALES_CHANNEL_ID } from '../../../../../lib/types/SalesChannels';
import {
  CurrencyCode,
  getCurrencySymbol,
} from '../../../../../lib/utilities/currency';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import {
  MerchantCountryCode,
  MerchantSyncDate,
} from '../../../../../lib/types/AOSharedTypes';
import {
  AllSalesChannel,
  ConnectionStatus,
  FAMConnectionStatus,
  FlywheelSalesChannel,
  IngestionStatus,
  MerchantCountry,
  MerchantType,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { DataAvailabilityResponse } from '../../../../../lib/types/SKUSharedTypes';
import {
  EUROPE_CURRENCY_SYMBOL,
  getCountryFlagIcon,
} from '../../../../../lib/utilities/countryFlags';
import { DateRange } from '../types';

export const SALES_CHANNEL_NAME_TO_I18NKEY_MAPPER = {
  [FlywheelSalesChannel.Amazon]: I18nKey.ADVERTISING_OPTIMIZATION_AMAZON,
  [FlywheelSalesChannel.Walmart]: I18nKey.ADVERTISING_OPTIMIZATION_WALMART,
};

const SALES_CHANNEL_TO_ICON_MAPPER = {
  [FlywheelSalesChannel.Amazon]: AmazonLogomarkEnabledIcon,
  [FlywheelSalesChannel.Walmart]: WalmartLogomarkEnabledIcon,
};

export const createMerchantDropdownOptionData = (
  merchant: MerchantCountry,
  salesChannels: AllSalesChannel[]
): Pick<SelectOptionProps<string>, 'groupName' | 'secondaryIcon' | 'icon'> => {
  const maybeMerchantSalesChannel = salesChannels.find(
    (salesChannel) => salesChannel.id === merchant.salesChannelId
  );

  return {
    groupName: !isUndefined(maybeMerchantSalesChannel)
      ? SALES_CHANNEL_NAME_TO_I18NKEY_MAPPER[
          maybeMerchantSalesChannel.name as FlywheelSalesChannel
        ]
      : undefined,
    icon: !isUndefined(maybeMerchantSalesChannel)
      ? SALES_CHANNEL_TO_ICON_MAPPER[
          maybeMerchantSalesChannel.name as FlywheelSalesChannel
        ]
      : undefined,
    secondaryIcon: !isUndefined(maybeMerchantSalesChannel)
      ? getCountryFlagIcon(merchant.country)
      : undefined,
  };
};

export const getEarliestSyncDateForSelectedMerchants = (
  merchantIds: string[],
  dataAvailabilityResponse?: DataAvailabilityResponse
): DateTime | undefined => {
  const DATE_FORMAT = 'yyyy-MM-dd';
  const today = DateTime.local();
  let minDate = today;

  if (
    isNil(dataAvailabilityResponse) ||
    dataAvailabilityResponse?.syncPerMerchantIds.length === 0
  ) {
    return;
  }

  const selectedMerchantsSyncInfo =
    dataAvailabilityResponse.syncPerMerchantIds.filter((syncPerMerchant) =>
      merchantIds.includes(syncPerMerchant.merchantCountryId)
    );

  for (const syncInfo of selectedMerchantsSyncInfo) {
    const date = DateTime.fromFormat(
      syncInfo.earliestAvailableDate,
      DATE_FORMAT
    ).toLocal();

    if (date < minDate) {
      minDate = date as DateTime<true>;
    }
  }
  const hasMinDateNotModified =
    minDate.hasSame(today, 'day') &&
    minDate.hasSame(today, 'month') &&
    minDate.hasSame(today, 'year');

  return hasMinDateNotModified ? undefined : minDate;
};

export const getPossibleStartAndEndDateBySyncDate = (
  dateRange: DateRange,
  minDate?: DateTime
): DateRange => {
  const today = DateTime.local().startOf('day');
  const yesterday = today.minus({ day: 1 });

  const lastSevenDays = yesterday.minus({ days: 6 });
  const lastFourteenDays = yesterday.minus({ days: 13 });
  const lastThirtyDays = yesterday.minus({ days: 29 });
  const lastSixtyDays = yesterday.minus({ days: 59 });
  const lastNintyDays = yesterday.minus({ days: 89 });
  const yearToDate = yesterday.startOf('year');

  const presets = [
    yearToDate,
    lastNintyDays,
    lastSixtyDays,
    lastThirtyDays,
    lastFourteenDays,
    lastSevenDays,
  ];
  let newDateRange: DateRange = { ...dateRange };

  // current start date is greater than the min date then return the same
  if (minDate && dateRange.startDate > minDate) {
    return {
      ...newDateRange,
      minDate,
    };
  }

  for (const date of presets) {
    if (minDate && minDate < date) {
      newDateRange = { ...dateRange, startDate: date };
      break;
    }
  }

  // chosen predefined date range is greater than min date
  if (minDate && minDate > newDateRange.startDate) {
    newDateRange = { ...dateRange, startDate: minDate };
  }

  // current end date is less than the new min date, return yesterday as new end date
  if (
    (minDate && dateRange.endDate < minDate) ||
    newDateRange.endDate < newDateRange.startDate
  ) {
    newDateRange = { ...newDateRange, endDate: yesterday };
  }

  return {
    ...newDateRange,
    minDate,
  };
};

export const getCurrencyPickerOptions = (): SelectOptionProps<string>[] => {
  const SUPPORTED_CURRENCIES = [
    CurrencyCode.USD,
    CurrencyCode.CAD,
    CurrencyCode.MXN,
    CurrencyCode.BRL,
    CurrencyCode.GBP,
    CurrencyCode.EUR,
    CurrencyCode.JPY,
    CurrencyCode.AUD,
    CurrencyCode.SGD,
    CurrencyCode.AED,
    CurrencyCode.INR,
  ];
  const SUPPORTED_FLAG_BY_CURRENCY: Record<
    string,
    MerchantCountryCode | string
  > = {
    [CurrencyCode.USD]: MerchantCountryCode.US,
    [CurrencyCode.CAD]: MerchantCountryCode.CA,
    [CurrencyCode.MXN]: MerchantCountryCode.MX,
    [CurrencyCode.BRL]: MerchantCountryCode.BR,
    [CurrencyCode.GBP]: MerchantCountryCode.GB,
    [CurrencyCode.EUR]: EUROPE_CURRENCY_SYMBOL,
    [CurrencyCode.JPY]: MerchantCountryCode.JP,
    [CurrencyCode.AUD]: MerchantCountryCode.AU,
    [CurrencyCode.SGD]: MerchantCountryCode.SG,
    [CurrencyCode.AED]: MerchantCountryCode.AE,
    [CurrencyCode.INR]: MerchantCountryCode.IN,
  };
  return SUPPORTED_CURRENCIES.map((code) => ({
    label: `${code} ${getCurrencySymbol(code)}`,
    value: code,
    icon: getCountryFlagIcon(SUPPORTED_FLAG_BY_CURRENCY[code]),
  }));
};

export const generateMerchantPickerOptions = (
  merchants: MerchantCountry[] = [],
  salesChannels: AllSalesChannel[],
  intl: IntlShape
): SelectOptionProps<string>[] => {
  return sortBy(
    merchants.map((merchant) => {
      const { groupName, icon, secondaryIcon } =
        createMerchantDropdownOptionData(merchant, salesChannels);
      return {
        value: merchant.merchantCountryId,
        label: merchant.merchantName,
        groupName: groupName && intl.formatMessage({ id: groupName }),
        icon,
        secondaryIcon,
        pillProps: {
          text: merchant.merchantType === MerchantType.Seller ? '3P' : '1P',
          color: PaletteColor.grey,
          className: 'ml-8',
        },
      };
    }),
    [({ groupName }) => groupName, ({ label }) => label]
  );
};

export const getMerchantSalesChannelName = (
  salesChannelId?: string,
  salesChannel: AllSalesChannel[] = []
) => {
  const merchantSalesChannel = salesChannel.find(
    (salesChannel) => salesChannel.id === salesChannelId
  );
  return !isUndefined(merchantSalesChannel)
    ? merchantSalesChannel.name
    : undefined;
};

export const getDataConnectionStatus = (
  isWalmartMc: boolean,
  connectionStatus: string,
  merchantCountryId: string,
  availabilityInfo: MerchantSyncDate[]
): string => {
  if (isWalmartMc) {
    return connectionStatus;
  }
  const availability = availabilityInfo.find(
    (info) => info.merchantCountryId === merchantCountryId
  );

  if (connectionStatus === FAMConnectionStatus.Active) {
    if (
      availability?.earliestAvailableDate ||
      availability?.latestAvailableDate ||
      availability?.lastSyncedAt
    ) {
      return ConnectionStatus.Connected;
    }
  }

  if (availability?.ingestionStatus === IngestionStatus.NoData) {
    return ConnectionStatus.Connected;
  }

  return connectionStatus;
};

export const filterMerchants = (
  merchantsInfo: MerchantCountry[],
  showOnly1PMerchants?: boolean,
  showOnly3PMerchants?: boolean
) => {
  let merchants = merchantsInfo;
  if (showOnly1PMerchants) {
    merchants = merchantsInfo.filter(
      (c) =>
        c.salesChannelId !== WALMART_SALES_CHANNEL_ID &&
        c.merchantType === MerchantType.Vendor
    );
  } else if (showOnly3PMerchants) {
    merchants = merchantsInfo.filter(
      (c) =>
        c.merchantType === MerchantType.Seller ||
        c.salesChannelId === WALMART_SALES_CHANNEL_ID
    );
  }
  return merchants;
};
