export const isNotUndefined = <T extends unknown>(
  value: T | undefined
): value is T => value !== undefined;

export const isEqual = <T extends unknown>(
  value: T | undefined,
  other: T | undefined
): boolean => {
  if (value === other) {
    return true;
  }

  const numValue = Number(value);
  const numOther = Number(other);

  if (!(isNaN(numValue) && isNaN(numOther))) {
    return numValue === numOther;
  }

  if (
    typeof value === 'string' &&
    typeof other === 'string' &&
    value === other
  ) {
    return true;
  }

  return false;
};
