export enum PredefinedDateRange {
  '7 days' = '7_days',
  '14 days' = '14_days',
  '30 days' = '30_days',
  '60 days' = '60_days',
  '90 days' = '90_days',
  '180 days' = '180_days',
  '1 year' = '1_year',
  '2 years' = '2_years',
  'gt 2 years' = 'gt_2_years',
}

type DateRangeDataTrackingProps = {
  readonly [dateRanges in PredefinedDateRange]: {
    readonly min: number;
    readonly max: number;
  };
};

export const DATE_RANGE_CONFIG_FOR_DATA_TRACKING: DateRangeDataTrackingProps = {
  [PredefinedDateRange['7 days']]: {
    min: 0,
    max: 7,
  },
  [PredefinedDateRange['14 days']]: {
    min: 8,
    max: 14,
  },
  [PredefinedDateRange['30 days']]: {
    min: 15,
    max: 30,
  },
  [PredefinedDateRange['60 days']]: {
    min: 31,
    max: 60,
  },
  [PredefinedDateRange['90 days']]: {
    min: 61,
    max: 90,
  },
  [PredefinedDateRange['180 days']]: {
    min: 91,
    max: 180,
  },
  [PredefinedDateRange['1 year']]: {
    min: 181,
    max: 365,
  },
  [PredefinedDateRange['2 years']]: {
    min: 366,
    max: 730,
  },
  [PredefinedDateRange['gt 2 years']]: {
    min: 731,
    max: Infinity,
  },
};
