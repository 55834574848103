import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Alignment,
  CycleDotIcon,
  Icon,
  IconSize,
  Placement,
  Theme,
  Tooltip,
} from '@teikametrics/tm-design-system';

import classNames from 'classnames';
import I18nKey from './lib/types/I18nKey';
import { DataTimingAndSyncingSlideout } from './modules/account/components/DataTimingAndSyncingSlideout';
import { SalesChannelSyncStatusSlideOut } from './modules/account/components/SalesChannelSyncStatusSlideout';

export const SyncStatusNavItem: React.FC = () => {
  const intl = useIntl();
  const [showSyncStatusSlideOut, setShowSyncStatusSlideOut] =
    useState<boolean>(false);
  const [showDataTimingAndSyncSlideout, setShowDataTimingAndSyncSlideout] =
    useState<boolean>(false);
  const TOOLTIP = intl.formatMessage({
    id: I18nKey.SYNC_STATUS_SLIDE_OUT_HEADER,
  });

  const onClick = () => {
    setShowSyncStatusSlideOut((previous) => !previous);
  };

  return (
    <div className="absolute bottom-14">
      <SalesChannelSyncStatusSlideOut
        showSalesChannelSyncStatusSlideOut={showSyncStatusSlideOut}
        closeSalesChannelSyncStatusSlideOut={onClick}
        showDataTimingAndSyncSlideout={() => {
          setShowDataTimingAndSyncSlideout(true);
        }}
      />
      <DataTimingAndSyncingSlideout
        isOpen={showDataTimingAndSyncSlideout}
        onClose={() => {
          setShowDataTimingAndSyncSlideout(false);
        }}
      />
      <Tooltip
        position={{
          alignment: Alignment.Center,
          placement: Placement.Right,
          placementOffsetInPixels: 25,
        }}
        theme={Theme.Dark}
        content={TOOLTIP}
        dataTestId={'sales_channel_sync_status_nav_item'}
        overwrittenTooltipClassnames="min-w-176"
      >
        <div
          className={classNames(
            'flex items-center justify-center cursor-pointer text-purple-300 w-44 h-44 rounded ',
            ' hover:text-purple-500 hover:bg-white'
          )}
          onClick={onClick}
        >
          <Icon svg={CycleDotIcon} size={IconSize.Medium} />
        </div>
      </Tooltip>
    </div>
  );
};
SyncStatusNavItem.displayName = 'SyncStatusNavItem';
