import { onDocumentReady } from './intercom';
import { UserDetails } from '../types/Fam';
import type { IdToken } from '@auth0/auth0-react';
import { getCurrentAccountPermissions } from '../../containers/userProvider/selectors';

declare global {
  interface Window {
    FS: any;
    onAmazonLoginReady: any;
    amazon: any;
  }
}

export enum AmazonLwaStatus {
  Success = 'success',
  Error = 'error',
}

interface LwaAuthorizeResponse {
  readonly error?: any;
  readonly code: string;
}

export const amazonLWA = (
  idToken: IdToken,
  userDetails: UserDetails,
  saveLWACorrelationId: (correlationId: string) => void,
  setLwaStatus: (status: AmazonLwaStatus) => void,
  famClient: any //TODO: Discuss this change amongst team
): void => {
  const amazonAuthorize = () => {
    const accountInfo = getCurrentAccountPermissions(userDetails)!;

    const options: any = {
      scope: 'profile cpc_advertising:campaign_management',
      popup: true,
      response_type: 'code',
    };

    window.amazon.Login.authorize(
      options,
      async (authorizeResponse: LwaAuthorizeResponse) => {
        if (authorizeResponse.error) {
          return setLwaStatus(AmazonLwaStatus.Error);
        }

        const result = await famClient.addAmazonAdsConnect(
          accountInfo.account.id,
          authorizeResponse.code
        );

        saveLWACorrelationId(result.id);
        return setLwaStatus(AmazonLwaStatus.Success);
      }
    );
  };
  onDocumentReady(amazonAuthorize);
};
