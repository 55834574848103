import { PropsWithChildren, createContext, useEffect, useState } from 'react';

import { IdToken } from '@auth0/auth0-react';

import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { AllSalesChannel, LatestConnection } from '../../lib/types/Fam';

export interface SalesChannelContextState {
  readonly salesChannels: AllSalesChannel[];
  readonly latestConnections: LatestConnection[];
  readonly areSalesChannelsLoaded: boolean;
}

const initialState: SalesChannelContextState = {
  salesChannels: [],
  latestConnections: [],
  areSalesChannelsLoaded: false,
};

export const SalesChannelContext =
  createContext<SalesChannelContextState>(initialState);

const { Provider } = SalesChannelContext;

export const SalesChannelProvider: React.FC<
  Readonly<
    {
      idToken: IdToken;
      accountId?: string;
    } & PropsWithChildren
  >
> = ({ children, idToken, accountId }) => {
  const [salesChannels, setSalesChannels] = useState<AllSalesChannel[]>([]);
  const [areSalesChannelsLoaded, setSalesChannelsLoaded] =
    useState<boolean>(false);
  const [latestConnections, setLatestConnections] = useState<
    LatestConnection[]
  >([]);

  const famApiClient = createFAMApiClient(idToken);

  useEffect(() => {
    famApiClient
      .getAllSalesChannels()
      .then((salesChannelsResponse) => {
        setSalesChannels(salesChannelsResponse);
        setSalesChannelsLoaded(true);
      })
      .catch(() => {
        setSalesChannelsLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (!accountId) {
      return;
    }

    famApiClient
      .getMerchantCountriesLatestConnections(accountId)
      .then((connections) => {
        setLatestConnections(connections);
      })
      .catch(() => {});
  }, [accountId]);

  return (
    <Provider
      value={{
        salesChannels,
        latestConnections,
        areSalesChannelsLoaded,
      }}
    >
      {children}
    </Provider>
  );
};
SalesChannelProvider.displayName = 'SalesChannelProvider';
