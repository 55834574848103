import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import React from 'react';
import { IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  CheckboxCheckedState,
  CheckboxState,
  SelectVisibility,
  createMultiSelectDataFieldFilter,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import {
  TableChange,
  TableSelectSettings,
  WithTable,
} from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeCheckboxLabelColumn,
  makeLongTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupReviewStatusType,
  AdGroupStatus,
  AdType,
  CampaignStatus,
  EMPTY_STRING,
  TargetStatus,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_TARGETS_TABLE_ID } from '../ducks/types';
import { TableCellChangeTarget, TableDataAdsManager } from '../types';
import {
  TABLE_UNIQ_KEY,
  TARGETS_API_COLUMN_NAME,
  TargetStatusOptions,
  getUpdatedValueFromOperator,
  isBetweenMinAndMaxValue,
  isCurrentValueGreaterThanRequiredMaxValue,
} from '../utils';
import { getBulkMenuValueForTargetLabels } from '../utils/bulkEditMenuUtils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { BulkEditModalElement, getElementValidation } from '../bulkEditModal';
import { BulkEditModalNumericValueOperator } from '@teikametrics/tm-design-system';

type BulkEditMenuValue =
  | typeof TARGETS_API_COLUMN_NAME.Bid
  | typeof TARGETS_API_COLUMN_NAME.TargetStatus;

interface BulkEditMenuOption {
  readonly label: string;
  readonly value: BulkEditMenuValue;
  readonly elements: BulkEditModalElement[];
}

export const getBidPlaceholderDefaultValue = (
  targetsTableData: TargetsDetails[],
  tableChange: TableChange,
  defaultBid?: number
): string => {
  let firstSelectedBid: number = 0;
  let areAllValuesSame: boolean = true;
  tableChange.select.rows &&
    tableChange.select.rows.forEach((rowId: string, index: number) => {
      if (!areAllValuesSame) return;
      const updatedTargetId = rowId;
      const targetData = targetsTableData.find(({ targetId }) =>
        isEqual(updatedTargetId, targetId)
      );
      if (targetData) {
        let currentValue = Number(
          tableChange.cell[updatedTargetId]?.bid ??
            targetData.channelSettings.bid?.amount
        );

        if (index === 0) {
          firstSelectedBid = currentValue;
        }
        areAllValuesSame = currentValue === firstSelectedBid;
      }
    });

  return areAllValuesSame && (firstSelectedBid || firstSelectedBid === 0)
    ? firstSelectedBid.toFixed(MONETARY_FRACTION_DIGITS)
    : Number(defaultBid).toFixed(MONETARY_FRACTION_DIGITS);
};

export const isModalReadyForSubmit = (
  bulkMenuOption?: BulkEditMenuOption,
  values?: string[]
): boolean => {
  if (!bulkMenuOption) {
    return false;
  }

  let readyForSubmit = true;

  values &&
    values.forEach((_, idx) => {
      const { isValid } = getElementValidation(bulkMenuOption.elements[idx]);
      if (!isValid) readyForSubmit = false;
    });

  return readyForSubmit;
};

const getInvalidCount = (
  campaignDailyBudget: string | undefined,
  bid: string,
  minBid: number,
  maxBid: number
) =>
  !isNil(campaignDailyBudget) &&
  !isNil(bid) &&
  (isCurrentValueGreaterThanRequiredMaxValue(
    Number(campaignDailyBudget),
    bid
  ) ||
    !isBetweenMinAndMaxValue(Number(bid), minBid, maxBid))
    ? 1
    : 0;

export const getBulkChangesAndInvalidCountForRowId = (
  rowId: string,
  bulkMenuValue: BulkEditMenuValue,
  tableChange: TableChange,
  values: string[],
  targetTableDataForAllPages: TargetsDetails[],
  minBid: number = 0,
  maxBid: number = Infinity,
  adType: AdType,
  operators?: BulkEditModalNumericValueOperator[]
) => {
  const bulkChanges = cloneDeep(tableChange.cell);

  if (!bulkChanges[rowId]) {
    bulkChanges[rowId] = {};
  }

  bulkChanges[rowId][bulkMenuValue] = values[0];
  let inValidChangesCount = 0;

  if (bulkMenuValue === TARGETS_API_COLUMN_NAME.Bid) {
    const rowDataFromAdGroupDataForAllPages = find(targetTableDataForAllPages, {
      [TABLE_UNIQ_KEY[ADS_MANAGER_TARGETS_TABLE_ID]]: rowId,
    });

    bulkChanges[rowId][bulkMenuValue] = getUpdatedValueFromOperator(
      rowDataFromAdGroupDataForAllPages?.channelSettings?.bid?.amount,
      tableChange.cell[rowId]?.[TARGETS_API_COLUMN_NAME.Bid],
      values[0],
      first(operators)
    );

    const campaignDailyBudget =
      rowDataFromAdGroupDataForAllPages?.targetDetails.campaignDailyBudget
        ?.amount;

    const bid = bulkChanges[rowId][TARGETS_API_COLUMN_NAME.Bid];

    inValidChangesCount = getInvalidCount(
      campaignDailyBudget,
      bid,
      minBid,
      maxBid
    );
  } else if (
    bulkMenuValue === TARGETS_API_COLUMN_NAME.TargetStatus &&
    adType === AdType.SearchBrandAmplifier
  ) {
    const adGroupDataForRow = targetTableDataForAllPages.find(
      ({ targetId }) => targetId === rowId
    );

    const isReviewStatusPendingOrInProgress =
      adGroupDataForRow?.targetDetails.adGroupReviewStatus?.reviewStatus ===
        AdGroupReviewStatusType.Pending ||
      adGroupDataForRow?.targetDetails.adGroupReviewStatus?.reviewStatus ===
        AdGroupReviewStatusType.InProgress;

    if (adGroupDataForRow?.channelSettings.status === TargetStatus.Enabled) {
      bulkChanges[rowId][bulkMenuValue] = values[0];
    } else if (
      !(
        adGroupDataForRow?.channelSettings.status === TargetStatus.Paused &&
        isReviewStatusPendingOrInProgress
      )
    ) {
      bulkChanges[rowId][bulkMenuValue] = values[0];
    } else {
      delete bulkChanges[rowId][bulkMenuValue];
    }
  } else if (bulkMenuValue === TARGETS_API_COLUMN_NAME.TargetLabels) {
    const rowDataFromAdGroupDataForAllPages = find(targetTableDataForAllPages, {
      [TABLE_UNIQ_KEY[ADS_MANAGER_TARGETS_TABLE_ID]]: rowId,
    });
    bulkChanges[rowId][bulkMenuValue] = getBulkMenuValueForTargetLabels(
      rowDataFromAdGroupDataForAllPages,
      values
    );
  } else {
    bulkChanges[rowId][bulkMenuValue] = values[0];
  }

  return {
    bulkChanges,
    inValidChangesCount,
  };
};

export const getCurrentValueBeingEdited = (
  tableChange: TableChange,
  targetTableData: TargetsDetails[],
  values: string[]
) => {
  const selectedRowsIds = tableChange.select.rows;
  const selectedTargets: TargetsDetails[] = targetTableData.filter(
    ({ targetId }) => selectedRowsIds.includes(targetId)
  );
  const updatedTargets: TableCellChangeTarget = tableChange.cell;

  const firstUpdatedTarget =
    selectedTargets &&
    selectedTargets[0] &&
    updatedTargets[selectedTargets[0]?.targetId] &&
    updatedTargets[selectedTargets[0]?.targetId].targetStatus;

  const firstSelectedTarget =
    firstUpdatedTarget || first(selectedTargets)?.channelSettings.status;

  const areAllSelectedStatusEqual = selectedTargets.every((selectedTarget) => {
    const editedBid = updatedTargets[selectedTarget.targetId]?.targetStatus;
    if (editedBid) {
      return editedBid === firstSelectedTarget;
    }
    const currentMaxBidAmount = selectedTarget.channelSettings?.status;
    return firstSelectedTarget === currentMaxBidAmount;
  });

  let currentValueBeingEdited = values;
  if (first(currentValueBeingEdited) === '') {
    currentValueBeingEdited = areAllSelectedStatusEqual
      ? [firstSelectedTarget!]
      : [TargetStatusOptions.Enabled.value];
  }
  return currentValueBeingEdited;
};

export const getSelectVisibility = (prevVisibilty: SelectVisibility) =>
  prevVisibilty === SelectVisibility.Closed
    ? SelectVisibility.Open
    : SelectVisibility.Closed;

export const getModalHeader = (
  intl: IntlShape,
  bulkMenuOptions: BulkEditMenuOption[],
  bulkMenuValue: BulkEditMenuValue | null
) =>
  bulkMenuValue
    ? intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_BODY,
        },
        {
          column: get(find(bulkMenuOptions, { value: bulkMenuValue }), 'label'),
        }
      )
    : '';

export const isValueFormatted = (
  formattedValuesWithDecimalPlaces: string[],
  index: number,
  decimalPlaces: number
) => {
  let isFormatted = false;
  if (formattedValuesWithDecimalPlaces[index]) {
    formattedValuesWithDecimalPlaces[index] = parseFloat(
      formattedValuesWithDecimalPlaces[index]
    ).toFixed(decimalPlaces);
    isFormatted = true;
  }
  return isFormatted;
};

export const onInputBlurBulkEditModal = (
  index: number,
  decimalPlaces: number,
  values: string[],
  setValues: (value: React.SetStateAction<string[]>) => void
) => {
  const formattedValuesWithDecimalPlaces = cloneDeep(values);

  if (
    isValueFormatted(formattedValuesWithDecimalPlaces, index, decimalPlaces)
  ) {
    setValues(formattedValuesWithDecimalPlaces);
  }
};

export const getState = (data: TargetsDetails) => {
  if (data.channelSettings.status === TargetStatus.Archived) {
    return String(data.channelSettings.status);
  }
  if (data.targetDetails.adGroupStatus === AdGroupStatus.Archived) {
    return String(data.targetDetails.adGroupStatus);
  }
  if (data.targetDetails.campaignStatus === CampaignStatus.Archived) {
    return String(data.targetDetails.campaignStatus);
  }
  return EMPTY_STRING;
};

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = ({
  targetPerformance,
  targetDetails,
  targetId,
  channelSettings,
  isEditMode,
}) => {
  const selectSettings = useSelector<
    WithTable<TargetsDetails>,
    TableSelectSettings
  >(({ tableState }) => {
    return tableSelectors.getSelectInfoSelector()(
      tableState,
      ADS_MANAGER_TARGETS_TABLE_ID
    );
  });

  const dispatch = useDispatch();
  const targetsData: TargetsDetails = {
    targetPerformance,
    targetDetails,
    targetId,
    channelSettings,
  };
  const isRowSelected = selectSettings.rows.includes(targetId);

  if (isEditMode) {
    const checkBoxState =
      getState(targetsData) === TargetStatusOptions.Archived.value
        ? CheckboxState.Disabled
        : CheckboxState.Default;

    return makeCheckboxLabelColumn((data: TargetsDetails) => {
      return {
        label: data.targetDetails.targetText,
        checked: isRowSelected
          ? CheckboxCheckedState.Checked
          : CheckboxCheckedState.Unchecked,
        state: checkBoxState,
        onClick: () =>
          dispatch(
            tableActions.toggleSelectedItem({
              tableId: ADS_MANAGER_TARGETS_TABLE_ID,
              rowId: data.targetId,
            })
          ),
        classNameLabel: 'flex-1 ml-12',
        dataTestId: 'target-text_tableCell',
      };
    })(targetsData);
  }

  const column = makeLongTextColumn;

  return column((data: TargetsDetails) =>
    data.targetDetails?.targetText
      ? data.targetDetails?.targetText?.toString()
      : undefined
  )(targetsData);
};
RowCellElement.displayName = 'RowCellElement';

export const targetTextColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.TargetText,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_TEXT,
  RowCellElement,
  gridColumnWidth: '360px',
};

export const targetTextFilter = createStringDataFieldFilter(
  TARGETS_API_COLUMN_NAME.TargetText,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_TEXT,
  isValidString()
);

export const flywheelAiEnabledFilter = createMultiSelectDataFieldFilter(
  TARGETS_API_COLUMN_NAME.FlywheelAiEnabled,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_FLYWHEEL_AI_ENABLED,
  [SelectFilterOption.FlywheelAiAdded, SelectFilterOption.ManuallyAdded]
);
