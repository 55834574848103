import React, { useCallback, useEffect } from 'react';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { DEBOUNCE_AFTER_IN_MILLISECONDS } from '../../core/campaignFlexibility/types';

export interface TagRowLeftTextAreaProps {
  readonly setNewTagValue: (vale: string) => void;
  readonly dataTestId?: string;
  readonly setStatus: (vale: boolean) => void;
  readonly statusData: boolean;
  readonly placeholder: string;
  readonly defaultValue?: string;
}

export const TagRowLeftTextArea: React.FC<TagRowLeftTextAreaProps> = ({
  setNewTagValue,
  setStatus,
  statusData,
  placeholder,
  defaultValue,
}) => {
  const divRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    setStatus(true);
  }, [statusData]);

  const onChange = () => {
    const newValue = divRef.current?.innerText || '';
    setNewTagValue(newValue);
  };

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    // get text representation of clipboard
    const text = e.clipboardData.getData('text/plain');

    // insert text manually
    document.execCommand('insertText', false, text);
  };

  useEffect(() => {
    if (divRef.current) {
      divRef.current.innerHTML = defaultValue || '';
    }
  }, [defaultValue, divRef, statusData]);

  const debouncedOnChange = useCallback(
    debounce(onChange, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [divRef]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (divRef.current) {
        document.execCommand('insertHTML', false, '<br><br>');
        debouncedOnChange();
      }
    }
  };

  return (
    <div
      className={classNames([
        'border-purple-500 rounded focus:border focus:border-solid',
        'text-base leading-tight font-normal',
        'inline-block w-full h-full resize-none border-0 p-8',
        'scrollbar-thin scrollbar-thumb-grey-300 scrollbar-track-grey-100 scrollbar-thumb-rounded-full',
      ])}
      contentEditable
      onKeyDown={handleKeyDown}
      onInput={debouncedOnChange}
      onBlur={onChange}
      onPaste={onPaste}
      placeholder={placeholder}
      ref={divRef}
    ></div>
  );
};
TagRowLeftTextArea.displayName = 'TagRowLeft';
