import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  CampaignDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  isNumericValueWithCampaignTacticAndCampaignAdFormatValid,
} from '../utils';

export const RowCellElement: React.FC<CampaignDetails> = (props) => {
  const isSponseredBrandsAdTypeOrSV =
    props.selectedAdType === AdType.SponsoredBrands ||
    props.selectedAdType === AdType.SearchBrandAmplifier ||
    props.selectedAdType === AdType.SponsoredVideos;

  return makeNumericColumn<CampaignDetails>(
    ({
      campaignPerformance: { newToBrandAdUnitsSold },
      campaignDetails: { campaignAdFormat, campaignTactic },
    }) =>
      isNumericValueWithCampaignTacticAndCampaignAdFormatValid(
        newToBrandAdUnitsSold,
        campaignTactic,
        isSponseredBrandsAdTypeOrSV
      )
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const newToBrandAdUnitsSoldColumn: FlywheelTableColumn<CampaignDetails> =
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.NewToBrandAdUnitsSold,
    isSortable: true,
    i18nKeyOrLabel:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_AD_UNITS_SOLD,
    RowCellElement,
    gridColumnWidth: '140px',
    className: 'text-right',
    columnHeaderClassName: 'justify-end',
  };

export const newToBrandAdUnitsSoldFilter = createNumericDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.NewToBrandAdUnitsSold,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_AD_UNITS_SOLD,
  isValidNumber(0)
);
