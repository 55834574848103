import { IntlShape } from 'react-intl';
import { FlywheelTableV2ColumnGroupProps } from '../../../../../../containers/tableV2/types';
import { SalesChannelData } from '../../../../../../lib/types/Fam';
import { SalesChannelTableData } from '../types';
import { merchantColumn } from './merchant';
import { statusColumn } from './status';

export const GetAccountListColumnsDefinition = (
  intl: IntlShape
): FlywheelTableV2ColumnGroupProps<
  SalesChannelData,
  SalesChannelTableData,
  {}
>[] =>
  [merchantColumn, statusColumn].map((column) => {
    if (column.header) {
      return {
        ...column,
        header: intl.formatMessage({ id: column.header }),
      };
    }
    return column;
  });
