import { DateTime } from 'luxon';

import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  ProductAdDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { getPerformanceMetricValue } from '../utils';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) =>
  makeNumericColumn<ProductAdDetails>(
    ({ adItemPerformance: { roasTotal }, adItemDetails: { startDate } }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        roasTotal
      );

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'RoasColumn';

export const roas: FlywheelTableColumn<ProductAdDetails, TableDataAdsManager> =
  {
    columnName: SV_ADS_API_COLUMN_NAME.ROAS,
    isSortable: true,
    i18nKeyOrLabel: I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_ROAS,
    RowCellElement,
    gridColumnWidth: '120px',
    className: 'text-right',
    columnHeaderClassName: 'justify-end',
  };

export const roasFilter = createNumericDataFieldFilter(
  SV_ADS_API_COLUMN_NAME.ROAS,
  I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_ROAS,
  isValidNumber(0)
);
