import classNames from 'classnames';
import { useIntl } from 'react-intl';

import {
  AlertTriangleIcon,
  Icon,
  IconSize,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../lib/types/I18nKey';

export const WalmartLoginFailureBanner: React.FC = () => {
  const intl = useIntl();
  const authenticationFailureHeader = intl.formatMessage({
    id: I18nKey.WALMART_LOGIN_FAILURE,
  });
  const authenticationFailureMessage = intl.formatMessage({
    id: I18nKey.WALMART_LOGIN_FAILURE_DISCLAIMER,
  });

  return (
    <div
      className={classNames(
        'flex flex-row',
        'w-full bg-orange-100 rounded-4 p-16 mt-12'
      )}
    >
      <div className="flex">
        <Icon
          svg={AlertTriangleIcon}
          size={IconSize.Large}
          className="text-orange-500"
        />
      </div>
      <div className="flex flex-col ml-12 pt-4">
        <h3
          className={classNames(
            'flex',
            'text-base leading-tight font-medium text-grey-900'
          )}
        >
          {authenticationFailureHeader}
        </h3>
        <label
          className={classNames(
            'flex mt-4',
            'text-sm leading-tight font-normal text-grey-900 text-left'
          )}
        >
          {authenticationFailureMessage}
        </label>
      </div>
    </div>
  );
};
