import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../lib/types/I18nKey';
import { IBlog } from '../../../../lib/types/RSSFeed';
import { Blog } from '../Blog';
import { BlogLoadingState } from '../Blog/BlogLoadingState';
import { ErrorState } from './ErrorState';

interface TeikametricsBlogsBannerProps {
  readonly feeds: IBlog[];
  readonly blogsHomeUrl: string;
  readonly blogsLoading?: boolean;
  readonly errorLoadingFeeds?: boolean;
  readonly refreshFeed: () => void;
}

export const TeikametricsBlogsBanner: React.FC<TeikametricsBlogsBannerProps> = (
  props
) => {
  const {
    feeds,
    blogsHomeUrl,
    blogsLoading = false,
    errorLoadingFeeds = false,
  } = props;
  const intl = useIntl();

  const header = intl.formatMessage({
    id: I18nKey.GENERIC_LATEST_NEWS_FROM_TEIKAMETRICS,
  });

  const viewMore = intl.formatMessage({
    id: I18nKey.GENERIC_VIEW_MORE,
  });

  const navigateToBlogsHome = () => {
    window.open(blogsHomeUrl, '_blank');
  };

  return (
    <div className="block w-full">
      <h2 className="m-0 py-16 text-left text-lg leading-tight font-semibold text-grey-900">
        {header}
      </h2>
      {errorLoadingFeeds ? (
        <ErrorState onLinkClick={props.refreshFeed} />
      ) : (
        <div className="border border-solid border-grey-200 rounded-8 p-16 w-full mb-16">
          <div className="flex flex-row w-full justify-between gap-16">
            {blogsLoading && (
              <>
                <BlogLoadingState />
                <BlogLoadingState />
                <BlogLoadingState />
              </>
            )}
            {feeds.map((feed) => {
              return (
                <Blog
                  blogDescription={
                    feed.excerpt && feed.excerpt.rendered
                      ? feed.excerpt.rendered
                      : intl.formatMessage({ id: I18nKey.GENERIC_EM_DASH })
                  }
                  blogHeader={
                    feed.title && feed.title.rendered
                      ? feed.title.rendered
                      : intl.formatMessage({
                          id: I18nKey.GENERIC_EM_DASH,
                        })
                  }
                  blogImage={
                    feed.yoast_head_json &&
                    feed.yoast_head_json.og_image &&
                    feed.yoast_head_json.og_image.length > 0
                      ? feed.yoast_head_json.og_image[0].url ?? ''
                      : ''
                  }
                  key={feed.blog}
                  blogUrl={feed.link ? feed.link : ''}
                />
              );
            })}
          </div>
          <div className="flex w-full justify-center mt-16">
            <Button
              size={ButtonSize.Medium}
              variant={ButtonVariant.Action}
              label={viewMore}
              onClick={navigateToBlogsHome}
            />
          </div>
        </div>
      )}
    </div>
  );
};

TeikametricsBlogsBanner.displayName = 'TeikametricsBlogsBanner';
