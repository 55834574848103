export const INVENTORY_OPTIMIZATION_DEMAND_FORECAST_HELP_CENTER_LINK =
  'https://intercom.help/flywheel-20/en/articles/8618459-inventory-demand-forecast';
export const INVENTORY_OPTIMIZATION_RATE_OF_SALE_HELP_CENTER_LINK =
  'https://intercom.help/flywheel-20/en/articles/8618465-inventory-insights-rate-of-sale';
export const INVENTORY_OPTIMIZATION_OUT_OF_STOCK_HELP_CENTER_LINK =
  'https://intercom.help/flywheel-20/en/articles/8618441-inventory-out-of-stock-estimated-missed-sales';
export const INVENTORY_OPTIMIZATION_EXCESS_STOCK_HELP_CENTER_LINK =
  'https://intercom.help/flywheel-20/en/articles/8618450-inventory-unsold-inventory-in-l90-days-excess-stock';
export const INVENTORY_OPTIMIZATION_INVENTORY_AGING_HELP_CENTER_LINK =
  'https://intercom.help/flywheel-20/en/articles/8618423-inventory-dashboard-inventory-health-overview';
export const INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_HELP_CENTER_LINK =
  'https://intercom.help/flywheel-20/en/articles/8618493-inventory-insights-stockout-reorder';
