import {
  DropDownType,
  KeywordActionColumns,
  KeywordRecommendationsData,
} from '../../../../../lib/types/KeywordAction';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import {
  ColumnAlign,
  MultiSelectOption,
  SelectOptionProps,
  SelectState,
  TableCellV2,
  TableCellV2Type,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../../../lib/types/I18nKey';
import capitalize from 'lodash/capitalize';
import { IntlShape, useIntl } from 'react-intl';
import { RecommendationsTableV2ExtraProps } from '../types';
import {
  capitalizeFirstLetter,
  isRowProcesing,
  splitByCapitalLetter,
} from './utils';
import { MatchType } from '../../../../../lib/types/AOSharedTypes';
import { MATCH_TYPE_I18N_MAPPER_ASIN } from '../../../../campaignFlexibility/components/utils/keywordUtils';
import { MatchTypeASIN } from '../../../../../lib/types/CampaignFlexibilitySharedTypes';

export const getMatchTypeOptions = (
  intl: IntlShape,
  isNegativeKeyword?: boolean,
  isPat?: boolean
): SelectOptionProps<string>[] => {
  if (isPat) {
    return [
      {
        label: intl.formatMessage({
          id: MATCH_TYPE_I18N_MAPPER_ASIN[MatchTypeASIN.Exact],
        }),
        value: MatchTypeASIN.Exact,
      },
      {
        label: intl.formatMessage({
          id: MATCH_TYPE_I18N_MAPPER_ASIN[MatchTypeASIN.Expanded],
        }),
        value: MatchTypeASIN.Expanded,
      },
    ];
  }

  return isNegativeKeyword
    ? [
        {
          label: capitalize(splitByCapitalLetter(MatchType.NegativePhrase)),
          value: capitalizeFirstLetter(MatchType.NegativePhrase),
        },
        {
          label: capitalize(splitByCapitalLetter(MatchType.NegativeExact)),
          value: capitalizeFirstLetter(MatchType.NegativeExact),
        },
      ]
    : [
        { label: capitalize(MatchType.Broad), value: MatchType.Broad },
        { label: capitalize(MatchType.Phrase), value: MatchType.Phrase },
        { label: capitalize(MatchType.Exact), value: MatchType.Exact },
      ];
};

export const Cell: React.FC<{
  original: KeywordRecommendationsData;
  meta: RecommendationsTableV2ExtraProps;
}> = ({ original, meta }) => {
  const intl = useIntl();

  const option = getMatchTypeOptions(intl, false, meta.isPat);

  const onDropdownSelect = (value: MatchType) => {
    meta.handleDropdownSelection(original, DropDownType.MATCH_TYPE, value);
  };

  let suggestedMatchType = original.keyword?.suggestedMatchType;
  let selectedMatchType = meta?.selectedRows[original.id]?.matchType as string;

  if (!meta.isPat) {
    suggestedMatchType = suggestedMatchType?.toLowerCase();
  } else {
    selectedMatchType = selectedMatchType?.toUpperCase();
  }

  return (
    <TableCellV2
      tableCellData={{
        tableCellType: TableCellV2Type.Select,
        state: isRowProcesing(original.id, meta.actionRowDataProps)
          ? SelectState.Disabled
          : SelectState.Default,
        placeholder: intl.formatMessage({
          id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_MATCH_TYPE_HEADER,
        }),
        options: option,
        value: selectedMatchType || suggestedMatchType,
        onChange: (value: MatchType) => onDropdownSelect(value),
      }}
      className="h-full flex justify-center"
    />
  );
};
Cell.displayName = 'KWACellMatchType';

export const matchType: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  RecommendationsTableV2ExtraProps,
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_MATCH_TYPE_HEADER,
  accessorKey: KeywordActionColumns.MATCH_TYPE,
  sortable: true,
  size: 172,
  minSize: 172,
  align: ColumnAlign.Left,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell original={original} meta={meta} />,
};

export const matchTypeFilter = (isPat?: boolean) => {
  let options: MultiSelectOption[] = [
    {
      value: MatchType.Broad,
      displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BROAD,
    },
    {
      value: MatchType.Phrase,
      displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PHRASE,
    },
    {
      value: MatchType.Exact,
      displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXACT,
    },
  ];

  if (isPat) {
    options = [
      {
        value: MatchTypeASIN.Exact,
        displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXACT,
      },
      {
        value: MatchTypeASIN.Expanded,
        displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXPANDED,
      },
    ];
  }

  return createMultiSelectDataFieldFilter(
    KeywordActionColumns.MATCH_TYPE,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_MATCH_TYPE_HEADER,
    options
  );
};
