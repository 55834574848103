import { TextLink, TextLinkProps } from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

export interface PropositionProps {
  imageLeftSide?: boolean;
  imageSource: string;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  textLinkProps?: TextLinkProps;
}

export const Proposition: React.FC<PropositionProps> = ({
  imageLeftSide,
  imageSource,
  title,
  subtitle,
  textLinkProps,
}) => {
  const ref = useRef<null | HTMLInputElement>(null);

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => entry.isIntersecting && setVisible(true));
    });

    observer.observe(ref.current);

    return () => {
      ref.current && observer.unobserve(ref.current);
    };
  }, []);

  const textContent = (
    <div className="flex-1">
      <div className="text-lg xl:text-xl leading-tight font-semibold w-full mb-16">
        {title}
      </div>
      <div className="text-base xl:text-lg leading-normal font-normal">
        {subtitle}
      </div>
      {textLinkProps && (
        <div className="mt-32">
          <TextLink {...textLinkProps} />
        </div>
      )}
    </div>
  );

  const imageContainer = (
    <div className="flex-shrink-0 min-w-320 lg:w-524 lg:h-242 xl:w-690 xl:h-320">
      <img src={imageSource} alt="proposition" />
    </div>
  );

  return (
    <div
      ref={ref}
      className={classNames('flex items-center justify-between gap-60 w-full', {
        'motion-safe:animate-empty-state-proposition': isVisible,
      })}
    >
      {imageLeftSide ? (
        <>
          {imageContainer}
          {textContent}
        </>
      ) : (
        <>
          {textContent}
          {imageContainer}
        </>
      )}
    </div>
  );
};

Proposition.displayName = 'Proposition';
