import {
  Icon,
  IconSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import React from 'react';
import { HeroData } from '../types';
import classNames from 'classnames';

interface HeroV2Props {
  readonly heroData: HeroData;
}

export const HeroV2: React.FC<HeroV2Props> = ({ heroData }) => {
  return (
    <div className="flex">
      <div
        className={classNames(
          'flex justify-center items-center p-16',
          'bg-magenta-100 w-1/2'
        )}
      >
        <div className="flex flex-col items-center text-center">
          <div
            className={classNames(
              'flex items-center justify-center rounded-full',
              'bg-flywheel-orange-magenta-purple-gradient w-40 h-40'
            )}
          >
            <Icon
              size={IconSize.Large}
              svg={heroData.icon}
              className="text-white"
            />
          </div>
          <Typography
            size={TypographySize['2xl']}
            lineHeight={TypographyLineHeight.tight}
            weight={TypographyWeight.semibold}
            className="pt-32"
          >
            {heroData.title}
          </Typography>
          <div
            className={classNames(
              'flex items-center gap-8 rounded-56',
              'mt-8 py-4 pl-4 pr-16 bg-magenta-300',
              'text-base leading-tight font-medium text-magenta-700'
            )}
          >
            <div
              className={classNames(
                'flex items-center justify-center',
                'w-24 h-24 rounded-full bg-magenta-500'
              )}
            >
              <Icon
                svg={heroData.buttonIcon}
                size={IconSize.Small}
                className="text-white"
              />
            </div>
            {heroData.buttonLabel}
          </div>

          <Typography
            size={TypographySize.lg}
            lineHeight={TypographyLineHeight.normal}
            weight={TypographyWeight.regular}
            className="max-w-420 pt-32"
          >
            {heroData.subtitle}
          </Typography>
        </div>
      </div>
      <div
        className={classNames('w-1/2', {
          'bg-grey-200': !heroData.imgPath,
        })}
      >
        {heroData.imgPath && (
          <img
            className="object-fill w-full"
            src={heroData.imgPath}
            alt="proposition"
          />
        )}
      </div>
    </div>
  );
};

HeroV2.displayName = 'HeroV2';
