import { Factory } from 'fishery';
import { InventoryByAgeMetricsNew } from '../../types/SKUSharedTypes';

export const InventoryBreakdownResponse =
  Factory.define<InventoryByAgeMetricsNew>(() => ({
    invAge0To90Days: 166,
    invAge91To180Days: 10,
    invAge181To270Days: 20,
    invAge271To365Days: 30,
    invAge365PlusDays: 40,
  }));
