import classNames from 'classnames';
import { CampaignWithAdgroupOption } from './campaignWithAdgroupOption';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  CrossIcon,
  Modal,
} from '@teikametrics/tm-design-system';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CampaignInfoWithAdGroups } from '../../../../../lib/types/KeywordAction';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../lib/utilities/intercom';
import { useEffect } from 'react';

export interface SelectedAdgroupsModalProps {
  readonly onCampaignSelectionChange: (
    campaign: CampaignInfoWithAdGroups,
    adgroupId: string,
    checked?: boolean
  ) => void;
  readonly selectedCampaigns: CampaignInfoWithAdGroups[];
  readonly selectedAdgroupsCount: number;
  readonly onClose: () => void;
}

export const SelectedAdgroupsModal: React.FC<SelectedAdgroupsModalProps> = ({
  onCampaignSelectionChange,
  selectedCampaigns,
  selectedAdgroupsCount,
  onClose,
}) => {
  const intl = useIntl();

  const getFooter = () => {
    return (
      <div
        className={classNames(
          'flex items-center justify-end',
          'bg-white gap-12 fixed bottom-0 left-0 right-0 p-12',
          'box-border border-t border-solid border-grey-200'
        )}
      >
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Primary}
          label={intl.formatMessage({
            id: I18nKey.CLOSE,
          })}
          onClick={onClose}
        />
      </div>
    );
  };

  const getHeader = () => (
    <div className="flex bg-white items-center px-12 py-8 border-b border-solid border-grey-200">
      <Button
        size={ButtonSize.Small}
        variant={ButtonVariant.BlackAndWhite}
        svgIcon={CrossIcon}
        onClick={onClose}
      />
      <div className="w-full text-center text-base leading-none font-medium text-grey-900">
        {intl.formatMessage(
          {
            id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECTED_COUNT,
          },
          {
            count: selectedAdgroupsCount,
          }
        )}
      </div>
    </div>
  );

  useEffect(() => {
    hideIntercomLauncher();
    return () => showIntercomLauncher();
  }, []);

  return (
    <Modal
      className="z-50 w-1/2 max-w-full"
      showModal
      slideOutModal={true}
      showOVerlay={false}
      formActionRowClassName="sticky border-t-1 border-grey-200 border-solid py-12 absolute bottom-0 px-12 bg-white"
      hidePadding={true}
      onClose={onClose}
      dataTestId="select_adgroups_list"
    >
      <div className="flex flex-col h-full bg-grey-50">
        {getHeader()}
        <div className="px-16 pb-16 pt-24 h-createProductInsightData overflow-x-hidden overflow-y-auto">
          <div>
            {selectedCampaigns
              .map((campaign) => {
                return {
                  ...campaign,
                  adGroups: campaign.adGroups.filter(
                    (ag) =>
                      (campaign?.selectedAdGroupIds ?? []).indexOf(ag.id) !== -1
                  ),
                };
              })
              .map((campaign, index) => (
                <div className="py-8" key={index}>
                  <CampaignWithAdgroupOption
                    key={campaign.id}
                    campaign={campaign}
                    showRemoveButton
                    onChange={onCampaignSelectionChange}
                  />
                </div>
              ))}
          </div>
        </div>
        {getFooter()}
      </div>
    </Modal>
  );
};
