import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import polling from 'rx-polling';
import { Subscription, defer } from 'rxjs';
import uuid from 'uuid';

import {
  FileUploadDownloadModalState as FileUploadState,
  Type,
} from '@teikametrics/tm-design-system';

import { createSKUApiClient } from '../../lib/clients/SKUApiClient';
import { useDownloadFile } from '../../lib/hooks/downloadFile';
import { ExportProductMetaDataStatusResponse } from '../../lib/types/CompassSharedTypes';
import I18nKey from '../../lib/types/I18nKey';
import { MetadataType } from '../../lib/types/SKUSharedTypes';
import {
  MerchantCountriesContext,
  MerchantCountriesContextState,
} from '../merchantCountriesProvider/merchantCountriesProvider';
import {
  NotificationContext,
  NotificationContextState,
} from '../notificationProvider';
import { SalesChannelContext } from '../salesChannelProvider';
import { getCurrentAccountFromContext } from '../userProvider/selectors';
import { UserContext, UserContextState } from '../userProvider/userProvider';
import {
  DATE_FORMAT,
  SYNC_STATUS_POLLING_INTERVAL,
  SYNC_STATUS_POLLING_MAX_TRIES,
  TEMPLATES_ALL,
} from './customUploadHelper';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../containers/optimizelyProvider/optimizelyProvider';
import { OptimizelyFlags } from '../../lib/types/OptimizelyFlags';

export interface CustomUploadContextState {
  readonly uploadState: FileUploadState;
  readonly setUploadState: (value: FileUploadState) => void;
  readonly isFileDownloaded: boolean;
  readonly currentTemplate: MetadataType;
  readonly uploadedFile: File | null;
  readonly setUploadedFile: (value: File | null) => void;
  readonly selectedMerchantsId: string[];
  readonly setSelectedMerchantsId: (value: string[]) => void;
  readonly setCheckUploadStatus: (value: UploadStatusState) => void;
  readonly startDownloadProcess: () => void;
  readonly startUploadProcess: () => void;
  readonly updateMerchant: (ids: string[]) => void;
  readonly updateTemplate: (ids: MetadataType) => void;
}

export interface CustomUploadProviderProps {
  children: JSX.Element;
}

interface UploadStatusState {
  makeCall: number;
  fileName: string;
}

const initialState: CustomUploadContextState = {
  uploadState: FileUploadState.Default,
  setUploadState: noop,
  isFileDownloaded: false,
  currentTemplate: MetadataType.PRODUCT,
  uploadedFile: null,
  setUploadedFile: noop,
  selectedMerchantsId: [],
  setSelectedMerchantsId: noop,
  setCheckUploadStatus: noop,
  startDownloadProcess: noop,
  startUploadProcess: noop,
  updateMerchant: noop,
  updateTemplate: noop,
};

let timer: NodeJS.Timeout | null = null;

const CustomUploadContext =
  createContext<CustomUploadContextState>(initialState);
CustomUploadContext.displayName = 'CustomUploadContext';

const { Provider } = CustomUploadContext;

const CustomUploadProvider: React.FC<CustomUploadProviderProps> = ({
  children,
}) => {
  const intl = useIntl();

  const { featureFlags } =
    useContext<OptimizelyContextState>(OptimizelyContext);

  const [uploadState, setUploadState] = useState<FileUploadState>(
    FileUploadState.Default
  );
  const updateUploadState = (value: FileUploadState) => {
    setUploadState(value);
  };
  const [isFileDownloaded, setIsFileDownloaded] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);
  const updateUploadFile = (value: File | null) => {
    setUploadedFile(value);
  };
  const [requestId, setRequestId] = useState<string | undefined>(undefined);
  const [pollingSubscription, setPollingSubscription] = React.useState<
    Subscription | undefined
  >();
  const [currentTemplate, setCurrentTemplate] = React.useState<MetadataType>(
    TEMPLATES_ALL(intl, featureFlags[OptimizelyFlags.BIProductMetaData])?.[0]
      ?.value
  );
  const [selectedMerchantsId, setSelectedMerchantsId] = React.useState<
    string[]
  >([]);
  const updateSelectedMetchantsId = (value: string[]) => {
    setSelectedMerchantsId(value);
  };
  const [restsInProgress, setRestsInProgress] = React.useState<any[]>([]);
  const updateRestsInProgress = (state: FileUploadState) => {
    const currentState = {
      restState: state,
      template: currentTemplate,
      selectedIds: selectedMerchantsId,
    };
    setRestsInProgress([...restsInProgress, currentState]);
  };

  const removeCurrentRestInProgress = (state: FileUploadState) => {
    const currentState = {
      restState: state,
      template: currentTemplate,
      selectedIds: selectedMerchantsId,
    };
    setRestsInProgress((currRests) => [
      ...currRests.filter((currApi) => !isEqual(currentState, currApi)),
    ]);
  };

  const checkForUpdateStateChange = (
    newValue: string[] | MetadataType,
    isTemplateChange: boolean
  ) => {
    const comparisonTemplate = isTemplateChange ? newValue : currentTemplate;
    const comparisonIds = !isTemplateChange ? newValue : selectedMerchantsId;
    const restInProgress = restsInProgress.find((currRest) => {
      return (
        currRest.template === comparisonTemplate &&
        isEqual(currRest.selectedIds, comparisonIds)
      );
    });
    if (restInProgress) {
      setUploadState(restInProgress.restState);
    } else {
      setUploadState(FileUploadState.Default);
    }
  };

  const updateMerchant = (newMerchants: string[]) => {
    setSelectedMerchantsId(newMerchants);
    resetState(0, true);
    checkForUpdateStateChange(newMerchants, false);
  };

  const updateTemplate = (newTemplate: MetadataType) => {
    setCurrentTemplate(newTemplate);
    updateTemplateName(newTemplate);
    resetState(0, true);
    checkForUpdateStateChange(newTemplate, true);
  };

  const [checkUploadStatus, setCheckUploadStatus] =
    React.useState<UploadStatusState>({
      makeCall: 0,
      fileName: '',
    });

  const updateUploadStatus = (value: UploadStatusState) => {
    setCheckUploadStatus(value);
  };

  const toasts = useContext<NotificationContextState>(NotificationContext);
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;
  const merchantContext = useContext<MerchantCountriesContextState>(
    MerchantCountriesContext
  );
  const merchantDetails = merchantContext.merchantCountries;
  const { salesChannels } = useContext(SalesChannelContext);

  const skuApiClient = createSKUApiClient(userContext.userInfo.idToken!);

  const genericToastPusher = (
    headlineKey: I18nKey,
    descriptionKey: I18nKey,
    type: Type
  ) =>
    toasts.addNotification({
      headline: intl.formatMessage({
        id: headlineKey,
      }),
      description: intl.formatMessage(
        {
          id: descriptionKey,
        },
        {
          template:
            currentTemplate === MetadataType.DSP
              ? intl.formatMessage({
                  id: I18nKey.CUSTOM_UPLOAD_TEMPLATE_DROPDOWN_DSP,
                })
              : currentTemplate,
        }
      ),
      type,
      dataTestId: 'bi-upload-custom-data-failure-toast',
    });

  const failureDownloadToast = () =>
    genericToastPusher(
      I18nKey.COGS_MODAL_STEP_1_DOWNLOAD_HEADLINE,
      I18nKey.COGS_MODAL_STEP_1_DOWNLOAD_DESCRIPTION,
      Type.Attention
    );

  const successDownloadToast = () =>
    genericToastPusher(
      I18nKey.COGS_MODAL_STEP_1_DOWNLOAD_SUCCESS_HEADLINE,
      I18nKey.COGS_MODAL_STEP_1_DOWNLOAD_SUCCESS_DESCRIPTION,
      Type.Success
    );

  const failureUploadToast = () =>
    genericToastPusher(
      I18nKey.COGS_MODAL_STEP_1_DOWNLOAD_HEADLINE,
      I18nKey.COGS_MODAL_STEP_1_UPLOAD_FAILED_DESCRIPTION,
      Type.Attention
    );

  const successUploadToast = () =>
    genericToastPusher(
      I18nKey.COGS_MODAL_STEP_1_UPLOAD_SUCCESS_HEADLINE,
      I18nKey.COGS_MODAL_STEP_1_UPLOAD_SUCCESS_DESCRIPTION,
      Type.Success
    );

  const onErrorDownloadFile = () => {
    setUploadState(FileUploadState.Default);
    setRequestId(undefined);
    setIsFileDownloaded(false);
    failureDownloadToast();
  };

  const exportProductMetaDataTemplateApi = (requestId: string) =>
    skuApiClient.exportProductMetadataTemplate(
      requestId,
      accountId,
      currentTemplate,
      selectedMerchantsId,
      merchantDetails || [],
      salesChannels
    );

  const postDownloadFile = () => {
    successDownloadToast();
  };

  const [templateFileName, setTemplateFileName] = useState<string>(
    `${currentTemplate}-custom-data-template-${DateTime.local().toFormat(
      DATE_FORMAT
    )}.csv`
  );

  const { downloadFile } = useDownloadFile<string>({
    asyncFunction: (url?: string) => {
      return skuApiClient.downloadS3File(url ?? '').then((resp) => {
        return resp;
      });
    },
    preDownloadFile: noop,
    postDownloadFile,
    onError: onErrorDownloadFile,
    fileName: templateFileName,
  });

  const updateStateAsPerResponse = (status: string) => {
    if (status === 'Success') {
      setUploadState(FileUploadState.Uploaded);
      successUploadToast();
      resetState();
    } else {
      setUploadState(FileUploadState.ErrorInUpload);
      failureUploadToast();
    }
  };

  const resetState = (
    delay: number = 5000,
    checkForUploadState: boolean = false
  ) => {
    timer = setTimeout(() => {
      if (!checkForUploadState) {
        setUploadState(FileUploadState.Default);
      }
      setIsFileDownloaded(false);
      setUploadedFile(null);
      setCheckUploadStatus({ makeCall: 0, fileName: '' });
    }, delay);
  };

  const statusRestForDownload = (downloadRequestId: string) => {
    return [
      () =>
        skuApiClient.exportProductMetadataTemplateStatus(
          accountId,
          currentTemplate,
          downloadRequestId
        ),
    ];
  };

  useEffect(() => {
    let subscription: Subscription;
    const currRequestId = requestId;
    (async () => {
      try {
        if (currRequestId) {
          setRequestId(undefined);
          setUploadState(FileUploadState.Downloading);
          updateRestsInProgress(FileUploadState.Downloading);

          // Doing this so downloadFileRef function remembers templateFileName
          // at the time of starting download
          const downloadFileRef = downloadFile;

          const statusRest = statusRestForDownload(currRequestId);
          await exportProductMetaDataTemplateApi(currRequestId);
          const syncStatusRunsObservable = defer(() => statusRest[0]());
          subscription = polling(syncStatusRunsObservable, {
            interval: SYNC_STATUS_POLLING_INTERVAL,
            attempts: SYNC_STATUS_POLLING_MAX_TRIES,
          }).subscribe(
            async (response: ExportProductMetaDataStatusResponse) => {
              if (response.status !== 'In Progress') {
                subscription?.unsubscribe();
                if (response.s3DownloadUrl) {
                  await downloadFileRef(response.s3DownloadUrl);
                  setUploadState(FileUploadState.Default);
                } else {
                  onErrorDownloadFile();
                }
                removeCurrentRestInProgress(FileUploadState.Downloading);
              }
            }
          );
          setPollingSubscription(subscription);
        }
      } catch (error) {
        onErrorDownloadFile();
      }
    })();
    function unsub() {
      return subscription && subscription.unsubscribe;
    }
    return unsub();
  }, [requestId]);

  const getUploadRestsURL = (
    uniqueFileName: string,
    url: string,
    uploadedFile: File,
    uploadRequestId: string
  ) => {
    return [
      () => skuApiClient.productMetadataS3FileUpload(url, uploadedFile),
      () =>
        skuApiClient.productMetadataUpload(
          uploadRequestId,
          accountId,
          uniqueFileName,
          currentTemplate,
          selectedMerchantsId,
          merchantDetails || []
        ),
      () =>
        skuApiClient.productMetadataUploadStatus(
          accountId,
          currentTemplate,
          uploadRequestId
        ),
    ];
  };

  const getUploadURL = (uniqueFileName: string) =>
    skuApiClient.productMetadataGetS3SecureUrl(
      accountId,
      currentTemplate,
      uniqueFileName
    );

  useEffect(() => {
    let subscription: Subscription;
    (async () => {
      try {
        if (uploadedFile && checkUploadStatus.makeCall) {
          setCheckUploadStatus({ makeCall: 0, fileName: '' });
          setUploadState(FileUploadState.Uploading);
          updateRestsInProgress(FileUploadState.Uploading);
          const timeInSeconds = DateTime.local().toMillis();
          const uniqueFileName = `${accountId}_${timeInSeconds}_${uploadedFile.name}`;
          const uploadRequestId = uuid();

          const res = await getUploadURL(uniqueFileName);
          const url = res.data.url;
          const rests = getUploadRestsURL(
            uniqueFileName,
            url,
            uploadedFile,
            uploadRequestId
          );
          await rests[0]();

          await rests[1]();
          const syncStatusRunsObservable = defer(() => rests[2]());
          subscription = polling(syncStatusRunsObservable, {
            interval: SYNC_STATUS_POLLING_INTERVAL,
            attempts: SYNC_STATUS_POLLING_MAX_TRIES,
          }).subscribe((response: any) => {
            if (response.status !== 'In Progress') {
              updateStateAsPerResponse(response.status);
              removeCurrentRestInProgress(FileUploadState.Uploading);
              subscription?.unsubscribe();
            }
          });
          setPollingSubscription(subscription);
        }
      } catch (error) {
        setUploadState(FileUploadState.ErrorInUpload);
        failureUploadToast();
      }
    })();
    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [checkUploadStatus.makeCall, uploadedFile]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      if (pollingSubscription && !pollingSubscription.closed) {
        pollingSubscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (!!merchantDetails?.length) {
      setSelectedMerchantsId([first(merchantDetails)!.merchantCountryId]);
    }
  }, [accountId, merchantDetails]);

  const startDownloadProcess = async () => {
    const requestUuid = uuid();
    setRequestId(requestUuid);
  };

  const startUploadProcess = () => {
    setCheckUploadStatus({
      makeCall: checkUploadStatus.makeCall + 1,
      fileName: '',
    });
  };

  const updateTemplateName = (template: string) => {
    setTemplateFileName(
      `${template}-custom-data-template-${DateTime.local().toFormat(
        DATE_FORMAT
      )}.csv`
    );
  };

  return (
    <Provider
      value={{
        uploadState,
        setUploadState: updateUploadState,
        isFileDownloaded,
        currentTemplate,
        uploadedFile,
        setUploadedFile: updateUploadFile,
        selectedMerchantsId,
        setSelectedMerchantsId: updateSelectedMetchantsId,
        setCheckUploadStatus: updateUploadStatus,
        startDownloadProcess,
        startUploadProcess,
        updateMerchant,
        updateTemplate,
      }}
    >
      {children}
    </Provider>
  );
};
CustomUploadProvider.displayName = 'CustomUploadProvider';

export { CustomUploadContext, CustomUploadProvider };
