import { useState } from 'react';
import { DateTime } from 'luxon';

export const useTime = (periodInMinutes: number) => {
  const [time, setTime] = useState<DateTime>(DateTime.now());

  const updateTime = () => setTime(DateTime.now());

  const hasTimeElapsed = () =>
    DateTime.now().diff(time, ['minutes']).minutes > periodInMinutes;

  return { time, updateTime, hasTimeElapsed };
};
