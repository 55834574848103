import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  DownloadIcon as ExportIcon,
  Type,
} from '@teikametrics/tm-design-system';

import {
  NotificationContext,
  NotificationContextState,
} from '../../../../containers/notificationProvider';
import { tableSelectors } from '../../../../containers/table/ducks';
import { getCurrentAccountFromContext } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import {
  AdLevel,
  AdLevelApiEndpoint,
  AdType,
  FilterFieldMapper,
} from '../../../../lib/types/AOSharedTypes';
import { Filter } from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import { Sort } from '../../../../lib/types/Sort';
import { AdOptimizationState } from '../../redux/types';
import { ADLEVEL_TO_ADLEVEL_API_MAPPER, AD_TYPE_SHORT_FORM } from './types';
import { getTableIdByAdLevel } from './utils';

export interface ExportAdLevelDataProps<RequestBody> {
  readonly aoApiClient: AOApiClient;
  readonly salesChannel: string;
  readonly request: RequestBody;
  readonly adLevelDropDownSelectedValue?: AdLevel;
  readonly adType: AdType;
  readonly buttonState: ButtonState;
  readonly setButtonState: (state: ButtonState) => void;
  readonly additionalFilters?: Filter[];
  readonly filterFieldMapper?: FilterFieldMapper[];
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  transformFilters?: (filters: Filter[]) => Filter[];
  readonly hideExportText?: boolean;
}

const EXPORT_DATE_FORMAT = 'yyyyddMM';

export const getFilename = (
  salesChannel: string,
  adType: AdType,
  adLevelDropDownSelectedValue: AdLevel | undefined,
  startDate: DateTime,
  endDate: DateTime
): string => {
  return `${salesChannel}-${
    AD_TYPE_SHORT_FORM[adType]
  }-${adLevelDropDownSelectedValue}-${startDate.toFormat(
    EXPORT_DATE_FORMAT
  )}-${endDate.toFormat(EXPORT_DATE_FORMAT)}`.toLowerCase();
};

function ExportAdLevelData<RequestBody>({
  aoApiClient,
  buttonState,
  salesChannel,
  request,
  adLevelDropDownSelectedValue,
  setButtonState,
  additionalFilters = [],
  filterFieldMapper,
  startDate,
  endDate,
  adType,
  transformFilters,
  hideExportText,
}: ExportAdLevelDataProps<RequestBody>) {
  const intl = useIntl();
  const toasts = useContext<NotificationContextState>(NotificationContext);
  const userContext = useContext<UserContextState>(UserContext);
  const userAccount = getCurrentAccountFromContext(userContext)!;

  const currentTableFilters = useSelector<AdOptimizationState, Filter[]>(
    ({ tableState }) => {
      return tableSelectors.getFiltersSelector()(
        tableState,
        getTableIdByAdLevel(adLevelDropDownSelectedValue || '')
      );
    }
  );

  const sorts = useSelector<AdOptimizationState, Sort[]>(({ tableState }) => {
    return tableSelectors.getSortsSelector()(
      tableState,
      getTableIdByAdLevel(adLevelDropDownSelectedValue || '')
    );
  });

  const onClick = () => {
    if (adLevelDropDownSelectedValue) {
      const entity =
        ADLEVEL_TO_ADLEVEL_API_MAPPER[adLevelDropDownSelectedValue];
      onCampaignsExport(entity, transformFilters);
    }
  };

  const onCampaignsExport = async (
    entity: AdLevelApiEndpoint,
    transformFilters?: (filters: Filter[]) => Filter[]
  ) => {
    try {
      const combinedAllFilters = [...currentTableFilters, ...additionalFilters];
      const exportFilters = transformFilters
        ? transformFilters(combinedAllFilters)
        : combinedAllFilters;

      setButtonState(ButtonState.Loading);
      const { data, headers }: AxiosResponse<Blob> =
        await aoApiClient.exportAdLevelData<RequestBody>(
          userAccount.id,
          request,
          entity,
          exportFilters,
          sorts,
          filterFieldMapper
        );

      const fileurl = URL.createObjectURL(new Blob([data]));
      const filename: string =
        headers['filename'] ||
        `${getFilename(
          salesChannel,
          adType,
          adLevelDropDownSelectedValue,
          startDate,
          endDate
        )}.csv`;

      const a = document.createElement('a');
      a.href = fileurl;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(fileurl);

      toasts.addNotification({
        headline: intl.formatMessage({ id: I18nKey.SUCCESS }),
        description: intl.formatMessage(
          {
            id: I18nKey.ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_FILE_DOWNLOAD,
          },
          {
            filename: filename,
          }
        ),
        type: Type.Success,
        dataTestId: 'exportAdLevelDataSuccess',
      });

      setButtonState(ButtonState.Enabled);
    } catch (error) {
      toasts.addNotification({
        headline: intl.formatMessage({
          id: I18nKey.ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_EXPORT_FAILED_HEADER,
        }),
        description: intl.formatMessage({
          id: I18nKey.ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_EXPORT_FAILED,
        }),
        type: Type.Attention,
        dataTestId: 'exportAdLevelDataError',
      });
      setButtonState(ButtonState.Enabled);
    }
  };

  return (
    <>
      <Button
        label={
          hideExportText ? undefined : (
            <FormattedMessage
              id={I18nKey.ADVERTISING_OPTIMIZATION_TABLE_ACTION_ROW_EXPORT}
            />
          )
        }
        svgIcon={ExportIcon}
        size={ButtonSize.Medium}
        variant={ButtonVariant.BlackAndWhiteBorder}
        state={buttonState}
        onClick={onClick}
        dataTestId={'ao_export'}
      />
    </>
  );
}
ExportAdLevelData.displayName = 'ExportAdLevelData';

export default ExportAdLevelData;
