import {
  AmazonIcon as AmazonDarkIcon,
  AmazonLogomarkEnabledIcon as AmazonIcon,
  EbayLogomarkEnabledIcon as EbayIcon,
  GoogleLogomarkEnabledIcon as GoogleShoppingIcon,
  SvgIcon,
  TargetLogomarkEnabledIcon as TargetIcon,
  WalmartIcon as WalmartDarkIcon,
  WalmartLogomarkEnabledIcon as WalmartIcon,
} from '@teikametrics/tm-design-system';
import {
  CustomInsightsProductsRequest,
  GroupCatalogsMap,
  InventoryMerchantCountries,
} from '../types/SKUSharedTypes';

import {
  AD_LEVEL_CONFIG,
  AD_TYPE_CONFIG,
} from '../../modules/advertisingOptimization/containers/adsManager/dataInspectorConfig';
import {
  AdLevel,
  AdType,
  AdvertisingTypeInfo,
  FlywheelSalesChannel,
  MerchantCountryCode,
  MerchantSalesChannel,
  SALES_CHANNEL_DETAILS_AD_LEVEL_CONFIG,
} from '../types/AOSharedTypes';
import { AllSalesChannel, MerchantCountry, SalesChannel } from '../types/Fam';
import { EnterCogsRecommendationSummary } from '../types/RecommendationsSharedTypes';
import { Filter } from '../types/Filter';

export enum HttpCode {
  SUCCESS = 200,
  CREATED = 201,
  ACCEPTED = 202,
}

export const getSalesChannelsWithMerchantCountriesInfo = (
  merchantCountryInfoItems: MerchantCountry[],
  flywheelSalesChannels: AllSalesChannel[]
): SalesChannel[] => {
  const salesChannelIds = flywheelSalesChannels.map((channel) => channel.id);
  const subscribedSalesChannelIds = salesChannelIds.filter(
    (channelId: string) =>
      merchantCountryInfoItems.some(
        (merchantCountryInfo: MerchantCountry) =>
          merchantCountryInfo.salesChannelId === channelId
      )
  );
  const subscribedChannelsInfo: SalesChannel[] = subscribedSalesChannelIds.map(
    (channelId: string) => ({
      salesChannelId: channelId,
      name: flywheelSalesChannels.find(
        (channelInfo) => channelInfo.id === channelId
      )!.name,
      merchantCountries: merchantCountryInfoItems.filter(
        (merchantCountryInfo: MerchantCountry) =>
          merchantCountryInfo.salesChannelId === channelId
      ),
    })
  );
  return subscribedChannelsInfo;
};

export const getAdTypeInfoItems = (
  adLevel: AdLevel,
  salesChannel: FlywheelSalesChannel,
  adsManagerDetailsPageFlag: boolean,
  adTypes: AdType[] | undefined = []
): AdvertisingTypeInfo[] =>
  adTypes.map((adType: AdType) => ({
    adType: adType,
    adLevels: getAdLevels(
      adLevel,
      adType,
      salesChannel,
      adsManagerDetailsPageFlag
    ),
  }));

export const getAdLevels = (
  adLevel: AdLevel,
  adType: AdType,
  salesChannel: FlywheelSalesChannel,
  adsManagerDetailsPageFlag: boolean
): AdLevel[] => {
  if (adsManagerDetailsPageFlag) {
    return getDetailsPageDropDownOptions(adType, adLevel, salesChannel);
  }
  const adLevels = AD_LEVEL_CONFIG?.[salesChannel]?.[adType] || [];
  return adLevels;
};

export const getDetailsPageDropDownOptions = (
  adType: AdType,
  adLevel: AdLevel,
  salesChannel: FlywheelSalesChannel
): AdLevel[] => {
  const options =
    SALES_CHANNEL_DETAILS_AD_LEVEL_CONFIG[salesChannel]?.[adType]?.[adLevel] ||
    [];
  return options;
};

export const getAdTypesForSalesChannel = (
  salesChannel: string,
  merchantCountry: string
) =>
  AD_TYPE_CONFIG[salesChannel as FlywheelSalesChannel]?.[
    merchantCountry as MerchantCountryCode
  ] || [];
// Sales Channel Helpers
export const getChannelIcon = (channelName: string): SvgIcon | undefined => {
  const platformIcons: Map<string, SvgIcon> = new Map();
  platformIcons.set(FlywheelSalesChannel.Amazon, AmazonIcon);
  platformIcons.set(FlywheelSalesChannel.Walmart, WalmartIcon);
  platformIcons.set('eBay', EbayIcon);
  platformIcons.set('google_shopping', GoogleShoppingIcon);
  platformIcons.set('target', TargetIcon);
  return platformIcons.get(channelName.toLowerCase());
};

export const ICON_BY_MERCHANT_NAME: Record<string, SvgIcon> = {
  [MerchantSalesChannel.Amazon]: AmazonDarkIcon,
  [MerchantSalesChannel.Walmart]: WalmartDarkIcon,
};

export const getMerchantlIcon = (merchantName: string): SvgIcon | undefined => {
  return ICON_BY_MERCHANT_NAME[merchantName];
};

export const getMerchantCountriesData = (
  merchantDetails: MerchantCountry[],
  merchantCountryIds: string[],
  salesChannels?: AllSalesChannel[]
) => {
  const channelsWithKeys: Record<string, string> = {};
  salesChannels?.forEach((channel) => {
    channelsWithKeys[channel.id] = channel.name;
  });
  return [
    ...merchantDetails
      .filter((country) =>
        merchantCountryIds.includes(country.merchantCountryId)
      )
      .map((country) => ({
        merchantCountryId: country.merchantCountryId,
        merchantCountryName: country.merchantName,
        saleChannelId: country.salesChannelId,
        merchantType: country.merchantType,
        salesChannelName: channelsWithKeys[country.salesChannelId || ''],
      })),
  ];
};

export const getCustomGrouping = (
  productCatalogAndGroupIds: [string, string][]
) =>
  Object.entries(
    productCatalogAndGroupIds.reduce((final: GroupCatalogsMap, current) => {
      const teikaGroupId = current[1];
      const productCatalogIds = current[0];
      if (!final[teikaGroupId]) {
        final[teikaGroupId] = [productCatalogIds];
      } else {
        final[teikaGroupId].push(productCatalogIds);
      }
      return final;
    }, {})
  ).map(([teikaGroupId, productCatalogIds]) => ({
    teikaGroupId,
    productCatalogIds,
  }));

export const removeTeikaGroupNameFilter = (filters: Filter[]) =>
  filters.filter((a) => a.field !== 'teikaGroupName');

export const transformRecommendationEnterCogsSummaryResponse = (response: {
  elements: EnterCogsRecommendationSummary[];
  filteredElements: number;
}) => {
  return response
    ? {
        items: response.elements.map(
          (element: EnterCogsRecommendationSummary) => {
            if (
              element?.missingCogsChildren?.elements?.length === 1 &&
              element.productCatalogId ===
                element?.missingCogsChildren?.elements?.[0]?.productCatalogId
            ) {
              return {
                ...element,
              };
            }
            return {
              ...element,
              subRows: element.missingCogsChildren?.elements.map(
                (childElement: EnterCogsRecommendationSummary) => ({
                  ...childElement,
                  parentAsin: element.exItemId,
                  parentSku: element.sku,
                })
              ),
            };
          }
        ),
        totalItems: response.filteredElements,
      }
    : {
        items: [],
        totalItems: 0,
      };
};

export const checkIfCustomInsightRequestEmpty = (
  request: CustomInsightsProductsRequest
) =>
  !request.metricCriteria ||
  request.metricCriteria.length === 0 ||
  !request.interval;

export const checkMerchantsRequestIsValid = (
  merchantDetails: InventoryMerchantCountries[]
) =>
  merchantDetails &&
  merchantDetails.length > 0 &&
  merchantDetails.every(
    (mcId) => !!mcId.merchantCountryId && !!mcId.salesChannelId
  );
