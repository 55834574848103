import { Factory } from 'fishery';
import { InventoryHealthMetricData } from '../../types/SKUSharedTypes';

export const SellThroughRateAndSalesRatioResponse =
  Factory.define<InventoryHealthMetricData>(() => ({
    sellThroughRateSummary: {
      currentValue: 4.16,
      prevValue: 2.8,
    },
    inventorySalesRatioSummary: {
      currentValue: 0.00122,
      prevValue: 0.0006453,
    },
  }));
