import React from 'react';
import { useIntl } from 'react-intl';

import { ColumnAlign } from '@teikametrics/tm-design-system';

import { FlywheelTableV2ColumnProps } from '../../../../../../containers/tableV2/types';
import { HomepageResponseMetricKeys } from '../../../../../../lib/types/CompassSharedTypes';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { makeNumberWithProgressBarColumn } from '../../../../../products/containers/utils/makeTableCellsUtils';
import { BusinessPerformanceColumnName } from '../../utils/constants';
import {
  PerformanceTableExtraProps,
  ProductCellData,
  ProductRowData,
} from '../../utils/performance';

export const RowCellElement: React.FC<ProductCellData> = (data) => {
  const intl = useIntl();
  return makeNumberWithProgressBarColumn<ProductCellData>(
    ({ currentMetrics }) => {
      const currentValue = +(
        currentMetrics?.productFields?.[HomepageResponseMetricKeys.Tacos] || 0
      );
      return {
        currentValue,
        numericStyle: 'percent',
        intl,
      };
    }
  )(data);
};

export const tacos: FlywheelTableV2ColumnProps<
  ProductRowData,
  {},
  PerformanceTableExtraProps
> = {
  header: I18nKey.COMPASS_DASHBOARD_TACOS,
  sortable: false,
  size: 135,
  minSize: 135,
  accessorKey: BusinessPerformanceColumnName.TACoS,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <RowCellElement {...original} {...meta} />,
};
