import {
  AsyncRequest,
  IdentifiedAsyncRequest,
} from '../../../lib/utilities/asyncRequest';
import { StringMap } from '../../../lib/types';
import { Sort } from '../../../lib/types/Sort';
import { Filter } from '../../../lib/types/Filter';

export enum VisiblePageKind {
  SinglePage = 'SinglePage',
  AllPages = 'AllPages',
}

interface SinglePage {
  readonly kind: VisiblePageKind.SinglePage;
  readonly page: number;
}

interface AllPages {
  readonly kind: VisiblePageKind.AllPages;
}

export interface TableSelectSettings {
  readonly all: boolean;
  readonly rows: string[];
}

export type TableCellChange = {
  [key: string]: Record<string, string>;
};

export interface TableChange {
  readonly select: TableSelectSettings;
  readonly global: {
    [key: string]: any;
  };
  readonly cell: TableCellChange;
}

// Having the visible page be a `number` does not make sense in the context
// of a component that shows results of all pages (infinite table).
// This type allows us to be explicit and correct about what pages are currently
// being displayed.
export type VisiblePage = SinglePage | AllPages | number;

interface BaseTable<T> {
  // Required data used for calls to paginate data
  readonly sorts: Sort[];
  readonly filters: Filter[];
  readonly extraPaginationParams?: StringMap<string>;

  // Currently loaded pages for the table
  readonly pages: Array<IdentifiedAsyncRequest<T[], void>>;
  readonly itemsPerPage: number;
  // Total items for the table, is optional because we do not know until after
  // the first page of data is fetched. This data is returned on every request
  // for paginated data. It represents the total items available for the given
  // combination of filters + extraPaginationParams that are passed within the
  // request
  readonly maybeTotalItems?: number;
  // Pages who use the table reducer state may need to keep track of what page
  // is currently being displayed (Example: Paginated Table)
  readonly visiblePage: VisiblePage;

  readonly changes: TableChange;

  // The list of buffer records to be loaded and kept at any point of time.
  // This should be refreshed just like when a refreshed (sort/filter/page change etc...)
  // Additionally this should be refreshed when an item is taken out from this.
  // And this would be basically always the next page data with eventual consistency w.r.t APIs
  readonly bufferItems?: T[];
  // Default to 0
  // If this buffer needs to be used. Then bufferItemsToLoad need to be updated during initialization of table.
  readonly bufferItemsToLoad?: number;
  readonly toggleBufferItemsChanged?: boolean;
}

export interface TableWithFooter<T, F> extends BaseTable<T> {
  readonly footerData: AsyncRequest<F, void>;
}

export interface WithTable<T, F = void> {
  readonly tableState: TableReducerState<T, F>;
}

export type Table<T, F> = BaseTable<T> | TableWithFooter<T, F>;

export interface TableReducerState<T, F = void> {
  readonly [key: string]: Table<T, F>;
}

export interface SetVisiblePagePayload {
  readonly tableId: string;
  readonly page: number;
}

export interface DataResetPayload {
  readonly tableId: string;
}

export interface DataLoadCompletePayload<T> {
  readonly items: T[];
  readonly tableId: string;
  readonly totalItems: number;
  readonly page: number;
  readonly requestId: string;
}

export interface SortAppliedPayload {
  readonly tableId: string;
  readonly sort: Sort;
}

export interface SortRemovedPayload {
  readonly tableId: string;
  readonly column: string;
}

export interface UpdateFiltersPayload {
  readonly tableId: string;
  readonly filters: Filter[];
  readonly replace: boolean;
}

export interface ClearFilterPayload {
  readonly tableId: string;
  readonly field: string;
}

export interface ClearSingleFilterOfMultiSelectionFiltersPayload {
  readonly tableId: string;
  readonly field: string;
  readonly value: string;
}

export interface ClearFiltersPayload {
  readonly tableId: string;
}

export interface UpdateExtraPaginationParamsPayload {
  readonly tableId: string;
  readonly extraPaginationParams?: StringMap<string>;
}

export interface DataLoadInitiatedPayload {
  readonly tableId: string;
  readonly requestId: string;
  readonly page: number;
}

export interface DataLoadFailedPayload {
  readonly tableId: string;
  readonly requestId: string;
  readonly page: number;
}

export interface FooterDataLoadInitiatedPayload {
  readonly tableId: string;
}

export interface FooterDataLoadCompletePayload<F> {
  readonly tableId: string;
  readonly footerData: F;
}

export interface FooterDataLoadFailedPayload {
  readonly tableId: string;
}

export interface SetItemsPerPagePayload {
  readonly tableId: string;
  readonly itemsPerPage: number;
}

export enum TableDisplayState {
  DataLoadNotStarted = 'DataLoadNotStarted',
  LoadingInitialData = 'LoadingInitialData',
  LoadingAdditionalData = 'LoadingAdditionalData',
  Error = 'Error',
  Data = 'Data',
  NoData = 'NoData',
  FilteredWithNoData = 'FilteredWithNoData',
}

export interface SetUpdatedItemsPayload<T> {
  readonly tableId: string;
  readonly page: number;
  readonly updatedItems: T[];
}

export interface PatchItemPayload<T> {
  readonly tableId: string;
  readonly itemIdentifier: (item: T) => boolean;
  readonly changes: any;
}

export interface SetCellChangePayload {
  readonly tableId: string;
  readonly rowId: string;
  readonly columnName: string;
  readonly columnDataMapping: Record<string, string>;
  readonly uniqKey: string;
  readonly value: string;
}

export interface UpdateCellPayload {
  readonly tableId: string;
  readonly rowId: string;
  readonly columnName: string;
  readonly value: string;
  readonly existingValue: string;
  readonly numericValue?: boolean;
  readonly validationData?: string;
}

export interface ClearAllChangesPayload {
  readonly tableId: string;
}

export interface ToggleSelectedItemPayload {
  readonly tableId: string;
  readonly rowId: string;
}

export interface PercentageBasedColumnInfo {
  readonly columnName: string;
  readonly maxDecimalDigits: number;
}

export interface ApplyBulkChangesPayload {
  readonly tableId: string;
  readonly bulkChanges: Record<string, any>;
  readonly columnDataMapping: Record<string, string>;
  readonly uniqKey: string;
  readonly percentageBasedColumns?: PercentageBasedColumnInfo[];
  readonly booleanColumns?: string[];
  readonly arrayBasedColumns?: string[];
}

export interface SelectAllItemsPayload {
  readonly tableId: string;
  readonly rowIds: string[];
}

export interface ChangeSelectPayload {
  readonly tableId: string;
  readonly selectedRows: string[];
}

export interface UpdateGlobalPayload {
  readonly tableId: string;
  readonly updatedValues: any;
}

export const UPDATE_CELL_VALIDATION_DATA_KEY = 'validationData';

export interface PercentageBasedColumnInfo {
  readonly columnName: string;
  readonly maxDecimalDigits: number;
}

export interface DeleteItemPayload<T> {
  readonly tableId: string;
  readonly itemIdentifier: (item: T) => boolean;
}

export interface DataLoadBufferCompletePayload<T> {
  readonly tableId: string;
  readonly items: T[];
  readonly requestId: string;
}

export interface ClearBufferPayload {
  readonly tableId: string;
}
