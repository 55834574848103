import axios, {
  default as Axios,
  AxiosResponse,
  RawAxiosRequestConfig,
} from 'axios';
import { fromNullable } from 'fp-ts/lib/Option';
import { DateTime } from 'luxon';

/* eslint-disable max-lines */
import { IdToken } from '@auth0/auth0-spa-js';
import { Filter, FilterOps } from '../types/Filter';

import axiosRetry from 'axios-retry';
import { GenericStatusResponse } from 'lib/types/AIPoweredSharedTypes';
import {
  KeywordDataResponse,
  SKUDataResponse,
} from '../../components/ViewSkuAndKeywordSlideoutV2/types';
import { BidContraints } from '../../containers/bidConstraintsProvider/biddingConstraints';
import { TreUploadMerchantCountry } from '../../containers/treUpload';
import { AdTargetType } from '../../lib/types/AdOptimizerSharedTypes';
import { DEFAULT_RETRY_INTERVAL } from '../../lib/types/HttpErrorCodes';
import { MAP_SALES_CHANNEL_ID_TO_NAME } from '../../lib/types/SalesChannels';
import { AD_TYPE_CONFIG } from '../../modules/advertisingOptimization/containers/adsManager/dataInspectorConfig';
import {
  MultiChannelDataSnapshotRequest,
  MultiChannelDataSnapshotResponse,
} from '../../modules/advertisingOptimization/containers/dashboard/types';
import {
  SmartCampaignCountsRequest,
  SmartCampaignCountsResponse,
} from '../../modules/recommendations/components/recommendationCards';
import {
  filterEmptyValues,
  generateSortQueryString,
  getPaginationParamsFromRequest,
} from '../factories/apiDataFetcherFactory';
import {
  ADLEVEL_TO_ADLEVEL_IN_MANAGE_BIDDING_UPLOAD_REQUEST_MAPPER,
  AdLevel,
  AdLevelApiEndpoint,
  AdLevelCogsDataRequest,
  AdType,
  BidConstraintsRequest,
  Brand,
  BrandCreateRequest,
  BrandUpdateRequest,
  CampaignDataRequest,
  CampaignGroupAssignRequest,
  CampaignGroupCreateRequest,
  CampaignGroupEditRequest,
  CampaignMissingCogsRequest,
  CampaignMissingCogsResponse,
  CampaignRequest,
  CampaignResponse,
  ChatGPTKeywordsRequest,
  ChatGPTRequestKeywordResponse,
  ChatGPTRequestKeywordResult,
  ChatGPTRequestKeywordStatus,
  CreateTargetKeywordsRequest,
  DataAvailabilityInfoApiResponse,
  DataAvailabilityInfoResponseType,
  DataInspectionList,
  DataInspectionRequest,
  DataInspectionResponse,
  DataSyncInfoResponse,
  DateRange,
  DeleteCampaignGroupRequest,
  DeleteCampaignGroupResponse,
  EditProductAdRequest,
  EditProfileRequest,
  EditTargetsRequest,
  FeedbackSubmitRequest,
  FilterFieldMapper,
  FlywheelSalesChannel,
  GetUserPreferenceResponse,
  Groups,
  GroupsResponse,
  HeroMetricsData,
  ManageBiddingProcessUploadResponse,
  ManageBiddingSecureUrlResponse,
  ManageBiddingUploadStatusResponse,
  ManageBiddingValidationResponse,
  MerchantCountryCode,
  MissingAcosTargetData,
  MultipleCampaignsCogsDataRequest,
  PerformanceMetricsRequest,
  Portfolio,
  PortfolioResponse,
  SaveAutoNegateSettings,
  SaveUserPreferenceRequest,
  SkuAdPerformanceData,
  SkuAdPerformanceRequest,
  SkuAdPerformanceSummary,
  TargetTypes,
  TreStatusCheckResponse,
  UpdateAdgroupChannelSettingsRequest,
  UpdateCampaignChannelSettingsRequest,
  UpdateCogsRequest,
  UpdateFlywheelSettingsRequest,
  UserAutoNegateSettings,
  UserPreferenceType,
  ValidateUniqueValuesRequest,
  ValidateUniqueValuesResponse,
  ViewTrendsGraphDataRequest,
  ViewTrendsGraphDataResponse,
} from '../types/AOSharedTypes';
import {
  EstPreAdGrossMarginItem,
  EstPreAdGrossMarginResponse,
} from '../types/CampaignFlexibilitySharedTypes';
import { SeasonalEvent } from '../types/CompassSharedTypes';
import { MerchantType } from '../types/Fam';
import {
  CampaignInfoWithAdGroups,
  KeywordActionSubmissionResponse,
  KeywordRecommendationsData,
  KeywordRecommendationsResponse,
  RecommendationsAction,
  TargetRecommendationCountResponse,
  TargetRecommendationCountResponseV2,
} from '../types/KeywordAction';
import { Sort } from '../types/Sort';
import { filtersToQueryString } from '../utilities/buildUrlUtilities';
import { getDefaultHeaders } from './';
import {
  FilteredRequest,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResult,
} from './types';
import { HttpCode, getAdTypeInfoItems } from './utils';

export interface AOApiClient {
  readonly getMetrics: (
    accountId: string,
    entityType: AdLevelApiEndpoint,
    filterFieldMapper?: FilterFieldMapper[],
    transformFilters?: (filters: Filter[]) => Filter[]
  ) => (request: PerformanceMetricsRequest) => Promise<HeroMetricsData>;
  readonly exportAdLevelData: <RequestBody>(
    accountId: string,
    requestBody: RequestBody,
    endPoint: AdLevelApiEndpoint,
    filters: Filter[],
    sorts: Sort[],
    filterFieldMapper?: FilterFieldMapper[]
  ) => Promise<AxiosResponse<Blob>>;
  readonly getDataInspection: (
    body: DataInspectionRequest
  ) => Promise<DataInspectionList[]>;
  readonly getDataAvailabilityInfo: (
    accountId: string,
    merchantCountryIds: Array<string>
  ) => Promise<DataAvailabilityInfoResponseType>;
  readonly getDataSyncInfo: (
    accountId: string,
    merchantCountryIds: Array<string>
  ) => Promise<DataSyncInfoResponse>;
  readonly getAdLevelData: <REQ, RES>(
    accountId: string,
    endPoint: AdLevelApiEndpoint,
    adsManagerEntityRequest: REQ,
    additionalFilters?: Filter[],
    filterFieldMapper?: FilterFieldMapper[],
    transormFilters?: (filters: Filter[]) => Filter[]
  ) => (request: PaginatedRequest) => Promise<PaginatedResult<RES>>;
  readonly getKeywordData: (
    accountId: string,
    merchantCountryId: string,
    adGroupId: string
  ) => Promise<AxiosResponse<KeywordDataResponse, any>>;
  readonly getSKUData: (
    accountId: string,
    merchantCountryId: string,
    adGroupId: string
  ) => Promise<AxiosResponse<SKUDataResponse, any>>;
  readonly updateCampaignChannelSettings: (
    accountId: string,
    request: UpdateCampaignChannelSettingsRequest
  ) => Promise<boolean>;
  readonly updateAdGroupChannelSettings: (
    accountId: string,
    request: UpdateAdgroupChannelSettingsRequest
  ) => Promise<boolean>;
  readonly updateFlywheelSettings: (
    accountId: string,
    request: UpdateFlywheelSettingsRequest
  ) => Promise<boolean>;
  readonly updateProductChannelSettings: (
    accountId: string,
    request: EditProductAdRequest
  ) => Promise<boolean>;
  readonly updateProfileChannelSettings: (
    accountId: string,
    request: EditProfileRequest
  ) => Promise<boolean>;
  readonly updateTargetsChannelSettings: (
    accountId: string,
    request: EditTargetsRequest
  ) => Promise<boolean>;
  readonly getPortfolios: (
    accountId: string,
    merchantCountryIds: string[]
  ) => Promise<Portfolio[]>;
  readonly getTargetTypes: (
    accountId: string,
    mcId: string,
    adLevel: string,
    entityId: string
  ) => Promise<TargetTypes>;
  readonly validateUniqueValues: (
    accountId: string,
    request: ValidateUniqueValuesRequest
  ) => Promise<ValidateUniqueValuesResponse>;

  readonly manageBiddingValidationRequest: (
    accountId: string,
    adLevel: AdLevel,
    fileName: string,
    salesChannelName: FlywheelSalesChannel,
    salesChannelId: string,
    adType: AdType,
    merchantType: MerchantType[],
    smartAcosFieldsIncluded: boolean
  ) => Promise<AxiosResponse<ManageBiddingValidationResponse>>;

  readonly manageBiddingValidDataUpload: (
    accountId: string,
    adLevel: AdLevel,
    fileName: string,
    salesChannelName: FlywheelSalesChannel,
    salesChannelId: string,
    adType: AdType,
    merchantType: MerchantType[],
    smartAcosFieldsIncluded: boolean
  ) => Promise<AxiosResponse<ManageBiddingProcessUploadResponse>>;

  readonly manageBiddingGetS3SecureUrl: (
    accountId: string,
    fileName: string
  ) => Promise<AxiosResponse<ManageBiddingSecureUrlResponse>>;

  readonly manageBiddingS3FileUpload: (
    url: string,
    file: File
  ) => Promise<AxiosResponse<{}>>;

  readonly manageBiddingS3FileUploadStatus: (
    accountId: string,
    requestId: string
  ) => Promise<AxiosResponse<ManageBiddingUploadStatusResponse>>;

  readonly treUploadGetS3SecureUrl: (
    accountId: string,
    fileName: string
  ) => Promise<AxiosResponse<ManageBiddingSecureUrlResponse>>;

  readonly treUploadS3FileUpload: (
    url: string,
    file: File
  ) => Promise<AxiosResponse<{}>>;

  readonly treUpload: (
    accountId: string,
    requestId: string,
    fileName: string,
    merchantCountries: TreUploadMerchantCountry[]
  ) => Promise<AxiosResponse<{}>>;

  readonly treUploadStatusCheck: (
    accountId: string,
    requestId: string
  ) => Promise<AxiosResponse<TreStatusCheckResponse>>;

  readonly getSkuAdPerformanceDetails: (
    accountId: string,
    skuId: string,
    skuAdPerformanceRequest: SkuAdPerformanceRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<SkuAdPerformanceData>>;

  readonly getSkuAdPerformanceSummary: (
    accountId: string,
    skuId: string,
    skuAdPerformanceRequest: SkuAdPerformanceRequest
  ) => (filteredequest: FilteredRequest) => Promise<SkuAdPerformanceSummary>;

  readonly manageBiddingErrorFileDownload: (
    accountId: string,
    fileName: string
  ) => Promise<AxiosResponse<Blob>>;

  readonly getMissingAcosTarget: (
    accountId: string
  ) => Promise<MissingAcosTargetData>;
  readonly getTargetRecommendationCountV3: (
    accountId: string,
    merchantCountryIds: string[]
  ) => Promise<TargetRecommendationCountResponse[]>;
  readonly getTargetRecommendationCountV3_1: (
    accountId: string,
    merchantCountryIds: string[]
  ) => Promise<TargetRecommendationCountResponseV2[]>;
  readonly getRecommendationsV2: (
    accountId: string,
    merchantCountryId: string,
    currency: string,
    filterFieldMappers?: FilterFieldMapper[],
    sortFieldMapper?: FilterFieldMapper[],
    transformFilters?: (filters: Filter[]) => Filter[],
    keywordSuggestionsByChannel?: boolean,
    isPat?: boolean,
    advertisingType?: AdType
  ) => (
    request: PaginatedRequest
  ) => Promise<PaginatedResult<KeywordRecommendationsData>>;
  readonly getNegativeRecommendations: (
    accountId: string,
    merchantCountryId: string,
    currency: string,
    filterFieldMappers?: FilterFieldMapper[],
    sortFieldMapper?: FilterFieldMapper[],
    transformFilters?: (filters: Filter[]) => Filter[]
  ) => (
    request: PaginatedRequest
  ) => Promise<PaginatedResult<KeywordRecommendationsData>>;
  readonly submitRecommendations: (
    accountId: string,
    actions: RecommendationsAction[],
    isPat?: boolean
  ) => Promise<AxiosResponse<KeywordActionSubmissionResponse>>;
  readonly submitNegativeRecommendations: (
    accountId: string,
    actions: RecommendationsAction[]
  ) => Promise<AxiosResponse<KeywordActionSubmissionResponse>>;
  readonly eligibleAdGroupsV2ForKeywordTargets: (
    accountId: string,
    merchantCountryId: string,
    currency: string,
    searchText: string,
    limit: number,
    adType?: AdType,
    isNegativeKeywords?: boolean
  ) => Promise<AxiosResponse<PaginatedResponse<CampaignInfoWithAdGroups>>>;
  readonly eligibleAdGroupsV2ForProductTargets: (
    accountId: string,
    merchantCountryId: string,
    currency: string,
    searchText: string,
    limit: number,
    adType?: AdType,
    isNegativeKeywords?: boolean
  ) => Promise<AxiosResponse<PaginatedResponse<CampaignInfoWithAdGroups>>>;
  readonly getBidConstraints: (
    accountId: string,
    request: BidConstraintsRequest
  ) => Promise<BidContraints[]>;
  readonly createTargets: (
    accountId: string,
    isProductTarget: boolean,
    request: CreateTargetKeywordsRequest
  ) => Promise<void>;
  readonly requestChatGPTKeywords: (
    accountId: string,
    request: ChatGPTKeywordsRequest
  ) => Promise<ChatGPTRequestKeywordResponse>;
  readonly getChatGPTKeywordsStatus: (
    accountId: string,
    request: ChatGPTRequestKeywordResponse
  ) => Promise<ChatGPTRequestKeywordStatus>;
  readonly getChatGPTKeywords: (
    accountId: string,
    request: ChatGPTRequestKeywordResponse
  ) => Promise<ChatGPTRequestKeywordResult>;
  readonly createBrands: (
    accountId: string,
    request: BrandCreateRequest
  ) => Promise<Brand[]>;
  readonly getBrands: (
    accountId: string,
    merchantCountryId: string
  ) => Promise<Brand[]>;
  readonly submitFeedback: (
    accountId: string,
    request: FeedbackSubmitRequest
  ) => Promise<void>;
  deleteBrand: (accountId: string, brand: BrandUpdateRequest) => Promise<void>;
  updateBrand: (accountId: string, brand: BrandUpdateRequest) => Promise<Brand>;
  readonly getUserPreference: (
    accountId: string,
    criteriaType: UserPreferenceType,
    mcIds: string[]
  ) => Promise<GetUserPreferenceResponse>;
  readonly saveUserPreference: (
    accountId: string,
    request: SaveUserPreferenceRequest
  ) => Promise<void>;
  readonly getAutoNegateSettings: (
    accountId: string,
    mcIds: string[]
  ) => Promise<UserAutoNegateSettings[]>;
  readonly saveAutoNegateSettings: (
    accountId: string,
    request: SaveAutoNegateSettings
  ) => Promise<void>;
  readonly getSmartCampaignCounts: (
    accountId: string,
    request: SmartCampaignCountsRequest
  ) => Promise<SmartCampaignCountsResponse>;

  readonly getAllGroups: (
    accountId: string,
    merchantCountryId?: string
  ) => Promise<Groups[]>;
  readonly updateCampaignGroups: (
    accountId: string,
    payload: CampaignGroupEditRequest
  ) => Promise<void>;
  readonly createCampaignGroups: (
    accountId: string,
    payload: CampaignGroupCreateRequest
  ) => Promise<void>;
  readonly deleteCampaignGroups: (
    accountId: string,
    payload: DeleteCampaignGroupRequest
  ) => Promise<DeleteCampaignGroupResponse>;
  readonly assignCampaignGroups: (
    accountId: string,
    payload: CampaignGroupAssignRequest
  ) => Promise<void>;

  readonly getMultiChannelDataSnapshot: (
    accountId: string,
    request: MultiChannelDataSnapshotRequest,
    sorts: Sort[]
  ) => Promise<MultiChannelDataSnapshotResponse>;
  readonly exportMultiChannelSnapshot: (
    accountId: string,
    request: MultiChannelDataSnapshotRequest,
    sorts: Sort[]
  ) => Promise<AxiosResponse<Blob>>;

  readonly exportCampaignGroupingCsv: (
    accountId: string,
    merchantCountryId: string,
    salesChannelId: string,
    adType: AdType,
    campaignExportRequest: CampaignDataRequest,
    filters: Filter[],
    sorts: Sort[],
    filterFieldMapper?: FilterFieldMapper[],
    merchantType?: MerchantType
  ) => Promise<AxiosResponse<Blob>>;
  readonly exportCampaignGroupingErrorCsv: (
    accountId: string,
    errorFileName: string
  ) => Promise<AxiosResponse<Blob>>;
  readonly getCampaignGroupingS3SecureUrl: (
    accountId: string,
    campaignGroupCsvFileName: string
  ) => Promise<AxiosResponse<ManageBiddingSecureUrlResponse>>;
  readonly campaignGroupingValidationRequest: (
    accountId: string,
    s3fileName: string
  ) => Promise<AxiosResponse<ManageBiddingValidationResponse>>;
  readonly customGroupingValidDataUpload: (
    accountId: string,
    validFileName: string
  ) => Promise<AxiosResponse<ManageBiddingProcessUploadResponse>>;
  readonly customGroupingS3FileUploadStatus: (
    accountId: string,
    jobId: string
  ) => Promise<AxiosResponse<ManageBiddingUploadStatusResponse>>;
  readonly getCampaign: (
    accountId: string,
    campaignId: string
  ) => (request: CampaignRequest) => Promise<CampaignResponse>;
  readonly getPerformanceDataPoints: (
    accountId: string
  ) => (
    request: ViewTrendsGraphDataRequest
  ) => Promise<ViewTrendsGraphDataResponse>;
  readonly getSeasonalEvents: (
    accountId: string,
    dateRange: DateRange
  ) => Promise<SeasonalEvent[]>;
  readonly exportPerformanceDataPoints: (
    accountId: string,
    request: ViewTrendsGraphDataRequest
  ) => Promise<AxiosResponse<Blob>>;
  readonly getMissingCogs: (
    accountId: string,
    request: CampaignMissingCogsRequest
  ) => Promise<CampaignMissingCogsResponse>;

  readonly getCogsDetailsForAdLevel: (
    accountId: string,
    request: AdLevelCogsDataRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<EstPreAdGrossMarginItem>>;
  readonly saveCogsDetailsForAdLevel: (
    accountId: string,
    request: UpdateCogsRequest
  ) => Promise<GenericStatusResponse>;
  readonly getCogsDetailsForMultipleCampaigns: (
    accountId: string,
    request: MultipleCampaignsCogsDataRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<EstPreAdGrossMarginItem>>;
}

const AO_BASE_URL = process.env.REACT_APP_AO_HOST;
const COMPASS_BASE_URL = process.env.REACT_APP_SKU_HOST;

/*eslint max-statements: ["error", 70, { "ignoreTopLevelFunctions": true }]*/
export const PATHS = Object.freeze({
  PERFOMANCE_METRICS: (accountId: string, entityType: string) =>
    `/accounts/${accountId}/${entityType}/data/aggregation`,
  EXPORT_AD_LEVEL_DATA: (
    accountId: string,
    entityType: string,
    dummyEndPoint?: boolean
  ) =>
    `accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/${entityType}/export`,
  DATA_SYNC_INFO: (accountId: string) =>
    `/accounts/${accountId}/data-availability-info`,
  PORTFOLIOS: (accountId: string) => `/accounts/${accountId}/portfolios`,
  TARGET_TYPES: (
    accountId: string,
    mcId: string,
    adLevel: string,
    entityId: string
  ) => {
    return `/accounts/${accountId}/${mcId}/${adLevel}/${entityId}/target-types`;
  },
  AD_LEVEL_VIEW: (
    accountId: string,
    endPoint: AdLevelApiEndpoint,
    dummyEndPoint?: boolean
  ): string =>
    `/accounts/${dummyEndPoint ? 'dummy/' : ''}${accountId}/${endPoint}/data`,
  KEYWORD_DATA: (accountId: string): string =>
    `/accounts/${accountId}/adgroups/targets`,
  SKU_DATA: (accountId: string): string =>
    `/accounts/${accountId}/adgroups/skus`,
  CAMPAIGNS_CHANNEL_SETTINGS: (accountId: string): string =>
    `/accounts/${accountId}/campaigns/channel-settings`,
  ADGROUPS_CHANNEL_SETTINGS: (accountId: string): string =>
    `/accounts/${accountId}/ad-groups/channel-settings`,
  UPDATE_FLYWHEEL_SETTINGS: (accountId: string): string =>
    `/accounts/${accountId}/flywheel-settings/bidding-strategies`,
  PRODUCT_ADS_CHANNEL_SETTINGS: (accountId: string): string =>
    `/accounts/${accountId}/ad-items/channel-settings`,
  PROFILE_CHANNEL_SETTINGS: (accountId: string): string =>
    `/accounts/${accountId}/profiles/channel-settings`,
  TARGETS_CHANNEL_SETTINGS: (accountId: string): string =>
    `/accounts/${accountId}/targets/channel-settings`,
  VALIDATE_UNIQUE_VALUES: (accountId: string): string =>
    `/accounts/${accountId}/validate-unique-values`,
  SKU_AD_PERFORMANCE_DETAILS: (accountId: string, dummyEndPoint?: boolean) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/sku-catalog/ad-performance`,
  SKU_AD_PERFORMANCE_SUMMARY: (
    accountId: string,
    skuId: string,
    dummyEndPoint?: boolean
  ) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/sku-catalog/${skuId}/ad-performance/aggregate`,
  SKU_AD_PERFORMANCE_SUMMARY_AGGREGATE: (
    accountId: string,
    dummyEndPoint?: boolean
  ) =>
    `/accounts/${
      dummyEndPoint ? 'dummy/' : ''
    }${accountId}/sku-catalog/ad-performance/aggregate`,
  MANAGE_BIDDING_UPLOAD: (accountId: string) =>
    `/accounts/${accountId}/manage-bidding/upload`,
  MANAGE_BIDDING_SECURE_URL: (accountId: string) =>
    `/accounts/${accountId}/manage-bidding/generate-signed-url`,
  MANAGE_BIDDING_S3_FILE_UPLOAD: (url: string) => url,
  MANAGE_BIDDING_S3_FILE_UPLOAD_STATUS: (accountId: string, jobId: string) =>
    `/accounts/${accountId}/manage-bidding/process-upload/${jobId}/status`,
  MANAGE_BIDDING_VALIDATE_UPLOAD: (accountId: string) =>
    `/accounts/${accountId}/manage-bidding/validate-upload`,
  MANAGE_BIDDING_VALID_DATA_UPLOAD: (accountId: string) =>
    `/accounts/${accountId}/manage-bidding/process-upload`,
  MANAGE_BIDDING_ERROR_FILE_DOWNLOAD: (accountId: string) =>
    `/accounts/${accountId}/manage-bidding/download-errors`,
  MISSING_ACOS_TARGET: (accountId: string) =>
    `/accounts/${accountId}/missing-acos-tagets`,
  MISSING_DAILY_BUDGET_TARGET: (accountId: string) =>
    `/accounts/${accountId}/missing-daily-budget`,
  LIST_TARGETS: (accountId: string) => `/accounts/${accountId}/targets/data`,
  TARGET_RECOMMENDATIONS_COUNT: (accountId: string) =>
    `/accounts/${accountId}/targets/recommendations/v2/count`,
  TARGET_RECOMMENDATIONS_COUNT_V4: (accountId: string) =>
    `/accounts/${accountId}/targets/recommendations/v4/count`,
  TARGET_RECOMMENDATIONS: (accountId: string, isV2: boolean, dummy?: boolean) =>
    `/accounts/${accountId}/targets/recommendations${isV2 ? '/v2' : ''}${
      dummy ? '/dummy' : ''
    }`,
  NEGATIVE_TARGET_RECOMMENDATIONS: (accountId: string) =>
    `/accounts/${accountId}/negative-targets/recommendations`,
  SUBMIT_TARGET_RECOMMENDATIONS: (accountId: string) => {
    return `/accounts/${accountId}/targets/recommendations/v2/user-action`;
  },
  SUBMIT_NEGATIVE_TARGET_RECOMMENDATIONS: (accountId: string) => {
    return `/accounts/${accountId}/negative-targets/recommendations/user-action`;
  },
  ELIGIBLE_ADGROUPS_KEYWORD_TARGETS: (accountId: string, isV2?: boolean) =>
    `accounts/${accountId}/targets/recommendations/${
      isV2 ? 'v2' : ''
    }/eligible-adgroups`,
  ELIGIBLE_ADGROUPS_PRODUCT_TARGETS: (accountId: string) =>
    `accounts/${accountId}/targets/recommendations/v2/eligible-adgroups/product-targets`,
  TRE_UPLOAD_SECURE_URL: (accountId: string) =>
    `/accounts/${accountId}/metadata-template/tre/upload/generate-signed-url`,
  TRE_UPLOAD: (accountId: string) =>
    `/accounts/${accountId}/metadata-template/tre/upload`,
  TRE_UPLOAD_STATUS_CHECK: (accountId: string, requestId: string) =>
    `/accounts/${accountId}/metadata-template/tre/upload/${requestId}/status`,
  TRE_UPLOAD_S3_FILE_UPLOAD: (url: string) => url,
  BID_CONSTRAINTS: (accountId: string) =>
    `/accounts/${accountId}/bid-constraint-config`,
  CREATE_KEYWORD_TARGETS_ON_DEMAND: (accountId: string) =>
    `/accounts/${accountId}/create-targets/v2/keyword`,
  CREATE_PRODUCT_TARGETS_ON_DEMAND: (accountId: string) =>
    `/accounts/${accountId}/create-targets/product`,
  LIST_AD_CREATIVES: (accountId: string) =>
    `/accounts/${accountId}/ad-creatives/data`,
  REQUEST_CHATGPT_RECOMMENDATIONS: (accountId: string) =>
    `/accounts/${accountId}/ai-chat/targets/recommendations`,
  CHATGPT_RECOMMENDATIONS_STATUS: (accountId: string) =>
    `/accounts/${accountId}/ai-chat/targets/recommendations/status`,
  CHATGPT_RECOMMENDATIONS_RESULT: (accountId: string) =>
    `/accounts/${accountId}/ai-chat/targets/recommendations/result`,
  SUBMIT_FEEDBACK: (accountId: string) =>
    `/accounts/${accountId}/ai-chat/targets/recommendations/feedback`,
  CREATE_BRANDS: (accountId: string) => `/accounts/${accountId}/brands/create`,
  UPDATE_BRANDS: (accountId: string) => `/accounts/${accountId}/brands/update`,
  DELETE_BRANDS: (accountId: string) => `/accounts/${accountId}/brands/delete`,
  LIST_BRANDS: (accountId: string, merchantCountryId: string) =>
    `/accounts/${accountId}/brands/${merchantCountryId}`,
  GET_USER_PREFERENCE: (accountId: string, criteriaType: string) =>
    `/accounts/${accountId}/user-preferences/${criteriaType}/settings`,
  SAVE_USER_PREFERENCE: (accountId: string) =>
    `/accounts/${accountId}/user-preferences/save`,
  GET_AUTO_NEGATE_SETTINGS: (accountId: string) =>
    `/accounts/${accountId}/user-preferences/auto-negate/settings`,
  SAVE_AUTO_NEGATE_SETTINGS: (accountId: string) =>
    `/accounts/${accountId}/user-preferences/auto-negate/save`,
  GET_CAMPAIGN_CREATE_SMART_COUNT: (accountId: string) =>
    `/accounts/${accountId}/recommendation-hub/campaign/counts`,

  ALL_GROUPS: (accountId: string) => `/accounts/${accountId}/campaign-groups`,
  CREATE_CAMPAIGN_GROUPS: (accountId: string) =>
    `/accounts/${accountId}/campaign-group/create`,
  UPDATE_CAMPAIGN_GROUPS: (accountId: string) =>
    `accounts/${accountId}/campaign-group/edit`,
  DELETE_CAMPAIGN_GROUPS: (accountId: string) =>
    `/accounts/${accountId}/campaign-group/delete`,
  ASSIGN_CAMPAIGN_GROUPS: (accountId: string) =>
    `/accounts/${accountId}/campaign-group/assign`,

  MULTI_CHANNEL_DATA_SNAPSHOT: (accountId: string) =>
    `/accounts/${accountId}/multichannel/merchant-countries/data/snapshot`,
  MULTI_CHANNEL_DATA_SNAPSHOT_EXPORT: (accountId: string) =>
    `/accounts/${accountId}/multichannel/merchant-countries/data/export`,

  GET_CAMPAIGN_GROUPING_TEMPLATE_CSV: (accountId: string) =>
    `/accounts/${accountId}/campaign-group/export`,
  GET_CAMPAIGN_GROUPING_ERROR_FILE_CSV: (accountId: string) =>
    `/accounts/${accountId}/campaign-group/download-errors`,
  GET_CAMPAIGN_GROUPING_SIGNED_S3_URL: (accountId: string) =>
    `/accounts/${accountId}/campaign-group/generate-signed-url`,
  VALIDATE_CAMPAIGN_GROUPING_REQUEST: (accountId: string) =>
    `/accounts/${accountId}/campaign-group/validate-upload`,
  UPLOAD_VALID_DATA_CAMPAIGN_GROUPING: (accountId: string) =>
    `/accounts/${accountId}/campaign-group/process-upload`,
  UPLOAD_VALID_DATA_CAMPAIGN_GROUPING_STATUS: (
    accountId: string,
    jobId: string
  ) => `/accounts/${accountId}/campaign-group/process-upload/${jobId}/status`,
  CAMPAIGN_URL: (accountId: string, campaignId: string) =>
    `/accounts/${accountId}/campaign/${campaignId}/details`,
  PERFORMANCE_GRAPH_DATA_POINTS: (accountId: string) =>
    `/accounts/${accountId}/entity/perf/graph/datapoint`,
  SEASONAL_EVENTS: (accountId: string) =>
    `accounts/${accountId}/compass/business/seasonal-events`,
  EXPORT_PERFORMANCE_GRAPH_DATA_POINTS: (accountId: string) =>
    `/accounts/${accountId}/export/entity/perf/graph/datapoint`,
  GET_MISSING_COGS: (accountId: string) =>
    `/accounts/${accountId}/campaigns/missing-cogs/count`,
  GET_COGS_DETAILS: (accountId: string) =>
    `/accounts/${accountId}/pagm/entity/product-details/data`,
  UPDATE_COGS: (accountId: string) => `/accounts/${accountId}/pagm/unit-cogs`,
  GET_MULTIPLE_CAMPAIGNS_COGS_DETAILS: (accountId: string) =>
    `/accounts/${accountId}/pagm/campaigns/product-details/data`,
});

/* eslint-disable max-statements */
export const createAOApiClient = (token: IdToken | null): AOApiClient => {
  const config: RawAxiosRequestConfig = {
    headers: {
      ...getDefaultHeaders(),
      Authorization: `Bearer ${token?.__raw}`,
    },
    baseURL: AO_BASE_URL,
  };

  const mapFiltersFromFilterFieldMapper = (
    filterFieldMapper: FilterFieldMapper[]
  ) => {
    return (filter: Filter) => {
      const maybeFilterFromMapper = filterFieldMapper.find(
        (filterToTransform) => filterToTransform.alias === filter.field
      );
      return fromNullable(maybeFilterFromMapper)
        .map((filterFromMapper) => ({
          ...filter,
          field: filterFromMapper.field,
        }))
        .getOrElse(filter);
    };
  };

  const mapSortsFromSortFieldMapper = (
    sortFieldMapper: FilterFieldMapper[]
  ) => {
    return (sort: Sort) => {
      const maybeSortFromMapper = sortFieldMapper.find(
        (sortToTransform) => sortToTransform.alias === sort.column
      );
      return fromNullable(maybeSortFromMapper)
        .map((sortFromMapper) => ({
          ...sort,
          column: sortFromMapper.field,
        }))
        .getOrElse(sort);
    };
  };

  const getMetrics =
    (
      accountId: string,
      entityType: AdLevelApiEndpoint,
      filterFieldMapper?: FilterFieldMapper[],
      transformFilters?: (filters: Filter[]) => Filter[]
    ) =>
    async (request: PerformanceMetricsRequest) => {
      let filters: Filter[] = [];

      if (transformFilters) {
        filters = transformFilters(request.filters);
      } else {
        filters = request.filters;
      }

      if (filterFieldMapper) {
        filters = (filters.length > 0 ? filters : request.filters).map(
          mapFiltersFromFilterFieldMapper(filterFieldMapper)
        );
      } else {
        filters = request.filters;
      }

      const allFilters = {
        filter: filtersToQueryString(filters),
      };
      const params = filterEmptyValues(allFilters);
      const response = await axios.post<HeroMetricsData>(
        PATHS.PERFOMANCE_METRICS(accountId, entityType),
        request,
        { ...config, params }
      );
      return response.data;
    };

  const exportAdLevelData = async <RequestBody>(
    accountId: string,
    requestBody: RequestBody,
    endPoint: AdLevelApiEndpoint,
    filters: Filter[],
    sorts: Sort[],
    filterFieldMapper?: FilterFieldMapper[],
    transformFilters?: (filters: Filter[]) => Filter[]
  ) => {
    const callDummyEnpoint = false;
    let exportFilters = filters;

    if (transformFilters) {
      exportFilters = transformFilters(exportFilters);
    }

    if (filterFieldMapper) {
      exportFilters = filters.map(
        mapFiltersFromFilterFieldMapper(filterFieldMapper)
      );
    }
    const allFilters = {
      filter: filtersToQueryString(exportFilters),
      sort: generateSortQueryString(sorts),
    };
    const params = filterEmptyValues(allFilters);
    return axios.post(
      PATHS.EXPORT_AD_LEVEL_DATA(accountId, endPoint, callDummyEnpoint),
      {
        ...requestBody,
      },
      { ...config, params, responseType: 'blob' }
    );
  };

  const getDataInspection = async (
    body: DataInspectionRequest
  ): Promise<DataInspectionList[]> => {
    const data = await getDummyDataInspectionResponse(
      body.salesChannel,
      body.adsManagerDetailsPageFlag,
      body.adLevel,
      body.showSponsoredVideos
    );
    return data.dataInspection;
  };

  const updateCampaignChannelSettings = async (
    accountId: string,
    request: UpdateCampaignChannelSettingsRequest
  ): Promise<boolean> => {
    try {
      const response: AxiosResponse = await axios.post(
        PATHS.CAMPAIGNS_CHANNEL_SETTINGS(accountId),
        request,
        { ...config }
      );

      return response.status === HttpCode.SUCCESS;
    } catch (error) {
      return false;
    }
  };

  const updateAdGroupChannelSettings = async (
    accountId: string,
    request: UpdateAdgroupChannelSettingsRequest
  ): Promise<boolean> => {
    try {
      const response: AxiosResponse = await axios.post(
        PATHS.ADGROUPS_CHANNEL_SETTINGS(accountId),
        request,
        { ...config }
      );

      return response.status === HttpCode.ACCEPTED;
    } catch (error) {
      return false;
    }
  };

  const updateFlywheelSettings = async (
    accountId: string,
    request: UpdateFlywheelSettingsRequest
  ): Promise<boolean> => {
    try {
      if (!request.merchantCountryId || request.merchantCountryId === '') {
        return false;
      }
      const response: AxiosResponse = await axios.post(
        PATHS.UPDATE_FLYWHEEL_SETTINGS(accountId),
        request,
        { ...config }
      );
      return response.status === HttpCode.ACCEPTED;
    } catch (error) {
      return false;
    }
  };

  const updateProductChannelSettings = async (
    accountId: string,
    request: EditProductAdRequest
  ): Promise<boolean> => {
    try {
      const response: AxiosResponse = await axios.post(
        PATHS.PRODUCT_ADS_CHANNEL_SETTINGS(accountId),
        request,
        { ...config }
      );
      return response.status === HttpCode.ACCEPTED;
    } catch (error) {
      return false;
    }
  };

  const updateProfileChannelSettings = async (
    accountId: string,
    request: EditProfileRequest
  ): Promise<boolean> => {
    try {
      const response: AxiosResponse = await axios.post(
        PATHS.PROFILE_CHANNEL_SETTINGS(accountId),
        request,
        { ...config }
      );
      return response.status === HttpCode.ACCEPTED;
    } catch (error) {
      return false;
    }
  };

  const updateTargetsChannelSettings = async (
    accountId: string,
    request: EditTargetsRequest
  ): Promise<boolean> => {
    try {
      const response: AxiosResponse = await axios.post(
        PATHS.TARGETS_CHANNEL_SETTINGS(accountId),
        request,
        { ...config }
      );
      return response.status === HttpCode.ACCEPTED;
    } catch (error) {
      return false;
    }
  };

  const getDataAvailabilityInfo = async (
    accountId: string,
    merchantCountryIds: Array<string>
  ): Promise<DataAvailabilityInfoResponseType> => {
    if (merchantCountryIds.length === 0 || !accountId) {
      return {
        syncPerMerchantIds: [],
      };
    }

    try {
      const response = await axios.post<DataAvailabilityInfoApiResponse>(
        PATHS.DATA_SYNC_INFO(accountId),
        { merchantCountryIds },
        { ...config }
      );

      const { syncPerMerchantIds } = response.data;

      return {
        syncPerMerchantIds: syncPerMerchantIds.map((syncPerMerchant) => {
          return {
            merchantCountryId: syncPerMerchant.merchantCountryId,
            latestAvailableDate: DateTime.fromISO(
              syncPerMerchant.latestAvailableDate
            ).toJSDate(),
            earliestAvailableDate: DateTime.fromISO(
              syncPerMerchant.earliestAvailableDate
            ).toJSDate(),
            lastSyncedAt: DateTime.fromISO(
              syncPerMerchant.lastSyncedAt
            ).toJSDate(),
          };
        }),
      };
    } catch (error) {
      return {
        syncPerMerchantIds: [],
      };
    }
  };

  const getPortfolios = async (
    accountId: string,
    merchantCountryIds: string[]
  ): Promise<Portfolio[]> => {
    if (merchantCountryIds?.length === 0) {
      return [];
    }

    try {
      const {
        data: { portfolios },
      } = await axios.post<PortfolioResponse>(
        PATHS.PORTFOLIOS(accountId),
        { merchantCountryIds },
        { ...config }
      );
      return portfolios;
    } catch (error) {
      return [];
    }
  };

  const getTargetTypes = async (
    accountId: string,
    mcId: string,
    adLevel: string,
    entityId: string
  ): Promise<TargetTypes> => {
    try {
      const { data } = await axios.get<TargetTypes>(
        PATHS.TARGET_TYPES(accountId, mcId, adLevel, entityId),
        {
          ...config,
        }
      );
      return data;
    } catch (error) {
      return { targetTypes: [] };
    }
  };

  const getDataSyncInfo = async (
    accountId: string,
    merchantCountryIds: Array<string>
  ): Promise<DataSyncInfoResponse> => {
    if (merchantCountryIds.length > 0 && accountId) {
      const response = await axios.post<DataSyncInfoResponse>(
        PATHS.DATA_SYNC_INFO(accountId),
        { merchantCountryIds },
        { ...config }
      );

      return response.data;
    } else {
      return {
        lastSyncedAt: '',
      };
    }
  };

  const validateUniqueValues = async (
    accountId: string,
    request: ValidateUniqueValuesRequest
  ): Promise<ValidateUniqueValuesResponse> => {
    if (request.value === '') {
      return {
        column: request.column,
        value: request.value,
        entityType: request.entityType,
        isValid: false,
      };
    }
    try {
      const response = await axios.post(
        PATHS.VALIDATE_UNIQUE_VALUES(accountId),
        request,
        { ...config }
      );
      return response.data;
    } catch (error) {
      return {
        column: request.column,
        value: request.value,
        entityType: request.entityType,
        isValid: true,
      };
    }
  };

  const getAdLevelData =
    <REQ, RES>(
      accountId: string,
      endPoint: AdLevelApiEndpoint,
      adsManagerEntityRequest: REQ,
      additionalFilters?: Filter[],
      filterFieldMapper?: FilterFieldMapper[],
      transformFilters?: (filters: Filter[]) => Filter[]
    ) =>
    async (request: PaginatedRequest): Promise<PaginatedResult<RES>> => {
      if (additionalFilters) {
        request = {
          ...request,
          filters: [...request.filters, ...additionalFilters],
        };
      }

      if (transformFilters) {
        request.filters = transformFilters(request.filters);
      }

      if (filterFieldMapper) {
        request.filters = request.filters.map(
          mapFiltersFromFilterFieldMapper(filterFieldMapper)
        );
      }
      const callDummyEnpoint = false;

      const allParams = getPaginationParamsFromRequest(request);
      const params = filterEmptyValues(allParams);

      if (
        endPoint === AdLevelApiEndpoint.Campaigns ||
        endPoint === AdLevelApiEndpoint.Adgroups ||
        endPoint === AdLevelApiEndpoint.ProductAds ||
        endPoint === AdLevelApiEndpoint.KeywordTargets ||
        endPoint === AdLevelApiEndpoint.Profiles ||
        endPoint === AdLevelApiEndpoint.SbAds
      ) {
        const response = await axios.post<PaginatedResponse<RES>>(
          PATHS.AD_LEVEL_VIEW(accountId, endPoint, callDummyEnpoint),
          adsManagerEntityRequest,
          { ...config, params }
        );

        return {
          items: response.data.elements,
          totalItems: response.data.filteredElements,
          smartEntityCount: response.data.smartEntityCount,
          externalEntityCount: response.data.externalEntityCount,
        };
      }

      return {
        items: [],
        totalItems: 0,
      };
    };

  const getKeywordData = async (
    accountId: string,
    merchantCountryId: string,
    adGroupId: string
  ): Promise<AxiosResponse<KeywordDataResponse>> => {
    try {
      const response = await axios.post(
        PATHS.KEYWORD_DATA(accountId),
        {
          merchantCountryId,
          adGroupId,
        },
        { ...config }
      );
      return response;
    } catch (err) {
      return Promise.reject({});
    }
  };

  const getSKUData = async (
    accountId: string,
    merchantCountryId: string,
    adGroupId: string
  ): Promise<AxiosResponse<SKUDataResponse>> => {
    try {
      const response = await axios.post(
        PATHS.SKU_DATA(accountId),
        {
          merchantCountryId,
          adGroupId,
        },
        { ...config }
      );
      return response;
    } catch (err) {
      return Promise.reject({});
    }
  };

  const getSkuAdPerformanceDetails =
    (accountId: string, skuId: string, request: SkuAdPerformanceRequest) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<SkuAdPerformanceData>> => {
      const allParams = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(allParams);

      const response = await axios.post<
        PaginatedResponse<SkuAdPerformanceData>
      >(
        PATHS.SKU_AD_PERFORMANCE_DETAILS(accountId),
        {
          skus: [skuId],
          ...request,
        },
        {
          ...config,
          params,
        }
      );

      return {
        items: response.data.elements,
        totalItems: response.data.filteredElements,
      };
    };

  const getSkuAdPerformanceSummary =
    (accountId: string, skuId: string, request: SkuAdPerformanceRequest) =>
    async (
      filteredRequest: FilteredRequest
    ): Promise<SkuAdPerformanceSummary> => {
      const allParams = {
        ...filteredRequest.extraParams,
        filter: filtersToQueryString(filteredRequest.filters),
        sort: generateSortQueryString(filteredRequest.sorts),
      };

      const params = filterEmptyValues(allParams);
      const response = await axios.post<SkuAdPerformanceSummary>(
        PATHS.SKU_AD_PERFORMANCE_SUMMARY_AGGREGATE(accountId),
        {
          skus: [skuId],
          ...request,
        },
        { ...config, params }
      );
      return response.data;
    };

  const manageBiddingValidationRequest = async (
    accountId: string,
    adLevel: AdLevel,
    fileName: string,
    salesChannelName: FlywheelSalesChannel,
    salesChannelId: string,
    adType: AdType,
    merchantType: MerchantType[],
    smartAcosFieldsIncluded: boolean
  ): Promise<AxiosResponse<ManageBiddingValidationResponse>> => {
    const params = {
      adLevel:
        ADLEVEL_TO_ADLEVEL_IN_MANAGE_BIDDING_UPLOAD_REQUEST_MAPPER[adLevel] ||
        '',
      s3fileName: fileName,
    };
    return axios.post(
      PATHS.MANAGE_BIDDING_VALIDATE_UPLOAD(accountId),
      {
        salesChannelId: salesChannelId,
        requestedChannelId: salesChannelName,
        requestedAdvertisingType: adType,
        merchantType: merchantType,
        smartAcosFieldsIncluded,
      },
      { ...config, params }
    );
  };

  const manageBiddingValidDataUpload = async (
    accountId: string,
    adLevel: AdLevel,
    fileName: string,
    salesChannelName: FlywheelSalesChannel,
    salesChannelId: string,
    adType: AdType,
    merchantType: MerchantType[],
    smartAcosFieldsIncluded: boolean
  ): Promise<AxiosResponse<any>> => {
    const params = {
      adLevel:
        ADLEVEL_TO_ADLEVEL_IN_MANAGE_BIDDING_UPLOAD_REQUEST_MAPPER[adLevel] ||
        '',
      validFileName: fileName,
    };
    return axios.post(
      PATHS.MANAGE_BIDDING_VALID_DATA_UPLOAD(accountId),
      {
        salesChannelId: salesChannelId,
        requestedChannelId: salesChannelName,
        requestedAdvertisingType: adType,
        merchantType: merchantType,
        smartAcosFieldsIncluded,
      },
      { ...config, params }
    );
  };

  const manageBiddingS3FileUpload = async (url: string, file: File) => {
    return axios.put(PATHS.MANAGE_BIDDING_S3_FILE_UPLOAD(url), file, {});
  };

  const manageBiddingS3FileUploadStatus = async (
    accountId: string,
    jobId: string
  ) => {
    return axios.get(
      PATHS.MANAGE_BIDDING_S3_FILE_UPLOAD_STATUS(accountId, jobId),
      {
        ...config,
      }
    );
  };

  const treUploadGetS3SecureUrl = async (
    accountId: string,
    fileName: string
  ): Promise<AxiosResponse<ManageBiddingSecureUrlResponse>> => {
    const params = { uploadCsvFileName: fileName };
    return axios.get(PATHS.TRE_UPLOAD_SECURE_URL(accountId), {
      ...config,
      params,
    });
  };

  const treUploadS3FileUpload = async (url: string, file: File) => {
    return axios.put(PATHS.TRE_UPLOAD_S3_FILE_UPLOAD(url), file, {});
  };

  const treUpload = async (
    accountId: string,
    requestId: string,
    fileName: string,
    merchantCountries: TreUploadMerchantCountry[]
  ) => {
    const params = {
      metaDataCsvS3FileName: fileName,
    };
    return axios.post(
      PATHS.TRE_UPLOAD(accountId),
      { requestId, merchantCountries },
      { ...config, params }
    );
  };

  const treUploadStatusCheck = async (
    accountId: string,
    requestId: string
  ): Promise<AxiosResponse<TreStatusCheckResponse>> => {
    return axios.get(PATHS.TRE_UPLOAD_STATUS_CHECK(accountId, requestId), {
      ...config,
    });
  };

  const manageBiddingGetS3SecureUrl = async (
    accountId: string,
    fileName: string
  ): Promise<AxiosResponse<ManageBiddingSecureUrlResponse>> => {
    const params = { biddingCsvFileName: fileName };
    return axios.get(PATHS.MANAGE_BIDDING_SECURE_URL(accountId), {
      ...config,
      params,
    });
  };

  const manageBiddingErrorFileDownload = async (
    accountId: string,
    fileName: string
  ) => {
    const params = { errorFileName: fileName };
    return axios.get(PATHS.MANAGE_BIDDING_ERROR_FILE_DOWNLOAD(accountId), {
      ...config,
      params,
    });
  };

  const getMissingAcosTarget = async (
    accountId: string
  ): Promise<MissingAcosTargetData> => {
    const response = await axios.get<MissingAcosTargetData>(
      PATHS.MISSING_ACOS_TARGET(accountId),
      {
        ...config,
      }
    );
    return response.data;
  };

  const getTargetRecommendationCountV3 = async (
    accountId: string,
    merchantCountryIds: string[]
  ) => {
    const response = await axios.post<TargetRecommendationCountResponse[]>(
      PATHS.TARGET_RECOMMENDATIONS_COUNT_V4(accountId),
      { merchantCountryIds },
      {
        ...config,
      }
    );

    return response.data;
  };

  const getTargetRecommendationCountV3_1 = async (
    accountId: string,
    merchantCountryIds: string[]
  ) => {
    const response = await axios.post<TargetRecommendationCountResponseV2[]>(
      PATHS.TARGET_RECOMMENDATIONS_COUNT_V4(accountId),
      { merchantCountryIds },
      {
        ...config,
      }
    );

    return response.data;
  };

  const getRecommendationsV2 =
    (
      accountId: string,
      merchantCountryId: string,
      currency: string,
      filterFieldMapper?: FilterFieldMapper[],
      sortFieldMapper?: FilterFieldMapper[],
      transformFilters?: (filters: Filter[]) => Filter[],
      keywordSuggestionsByChannel?: boolean,
      isPat?: boolean,
      advertisingType?: AdType
    ) =>
    async (
      request: PaginatedRequest
    ): Promise<PaginatedResult<KeywordRecommendationsData>> => {
      if (filterFieldMapper) {
        request.filters = request.filters.map(
          mapFiltersFromFilterFieldMapper(filterFieldMapper)
        );
      }

      if (sortFieldMapper) {
        request.sorts = request.sorts.map(
          mapSortsFromSortFieldMapper(sortFieldMapper)
        );
      }

      if (transformFilters) {
        request.filters = transformFilters(request.filters);
      }

      const allParams = getPaginationParamsFromRequest(request);
      const params = filterEmptyValues(allParams);
      try {
        const { filteredElements, elements } = await axios
          .post<KeywordRecommendationsResponse>(
            PATHS.TARGET_RECOMMENDATIONS(accountId, true, false),
            {
              merchantCountryId,
              advertisingType,
              currency,
              keywordSuggestionsByChannel,
              recommendationType: isPat
                ? 'Product Attribute Targeting'
                : undefined,
            },
            {
              ...config,
              params,
            }
          )
          .then((res) => {
            return res.data;
          });
        return {
          totalItems: filteredElements,
          items: elements,
        };
      } catch (e) {
        return {
          totalItems: 0,
          items: [],
        };
      }
    };

  const getNegativeRecommendations =
    (
      accountId: string,
      merchantCountryId: string,
      currency: string,
      filterFieldMapper?: FilterFieldMapper[],
      sortFieldMapper?: FilterFieldMapper[],
      transformFilters?: (filters: Filter[]) => Filter[]
    ) =>
    async (
      request: PaginatedRequest
    ): Promise<PaginatedResult<KeywordRecommendationsData>> => {
      if (filterFieldMapper) {
        request.filters = request.filters.map(
          mapFiltersFromFilterFieldMapper(filterFieldMapper)
        );
      }

      if (sortFieldMapper) {
        request.sorts = request.sorts.map(
          mapSortsFromSortFieldMapper(sortFieldMapper)
        );
      }

      if (transformFilters) {
        request.filters = transformFilters(request.filters);
      }

      const allParams = getPaginationParamsFromRequest(request);
      const params = filterEmptyValues(allParams);
      try {
        const { filteredElements, elements } = await axios
          .post<KeywordRecommendationsResponse>(
            PATHS.NEGATIVE_TARGET_RECOMMENDATIONS(accountId),
            {
              merchantCountryId,
              currency,
              recommendationType: AdTargetType.NegativeKeyword,
            },
            {
              ...config,
              params,
            }
          )
          .then((res) => {
            return res.data;
          });
        return {
          totalItems: filteredElements,
          items: elements,
        };
      } catch (e) {
        return {
          totalItems: 0,
          items: [],
        };
      }
    };

  const submitRecommendations = async (
    accountId: string,
    actions: RecommendationsAction[],
    isPat?: boolean
  ): Promise<AxiosResponse<KeywordActionSubmissionResponse>> => {
    return await axios.post<KeywordActionSubmissionResponse>(
      PATHS.SUBMIT_TARGET_RECOMMENDATIONS(accountId),
      {
        actions: actions.map((action) => ({
          ...action,
          matchType: isPat ? action.matchType.toUpperCase() : action.matchType,
          recommendationType: isPat ? 'Product Attribute Targeting' : undefined,
        })),
      },
      {
        ...config,
      }
    );
  };

  const submitNegativeRecommendations = async (
    accountId: string,
    actions: RecommendationsAction[]
  ): Promise<AxiosResponse<KeywordActionSubmissionResponse>> => {
    return await axios.post<KeywordActionSubmissionResponse>(
      PATHS.SUBMIT_NEGATIVE_TARGET_RECOMMENDATIONS(accountId),
      { actions },
      {
        ...config,
      }
    );
  };

  const eligibleAdGroupsV2ForKeywordTargets = async (
    accountId: string,
    merchantCountryId: string,
    currency: string,
    searchText: string,
    limit: number,
    adType: AdType = AdType.SponsoredProducts,
    isNegativeKeywords = false
  ): Promise<AxiosResponse<PaginatedResponse<CampaignInfoWithAdGroups>>> => {
    const allParams = {
      filter: searchText
        ? filtersToQueryString([
            {
              op: FilterOps.like,
              field: 'searchText',
              value: searchText,
            },
          ])
        : '',
      limit: limit.toString(),
      offset: '0',
    };
    const params = filterEmptyValues(allParams);
    return await axios.post<PaginatedResponse<CampaignInfoWithAdGroups>>(
      PATHS.ELIGIBLE_ADGROUPS_KEYWORD_TARGETS(accountId, true),
      {
        merchantCountryId,
        currency,
        advertisingType: adType,
        isNegativeKeywords,
        recommendationType: isNegativeKeywords
          ? AdTargetType.NegativeKeyword
          : AdTargetType.Keyword,
      },
      { ...config, params }
    );
  };

  const eligibleAdGroupsV2ForProductTargets = async (
    accountId: string,
    merchantCountryId: string,
    currency: string,
    searchText: string,
    limit: number,
    adType: AdType = AdType.SponsoredProducts,
    isNegativeKeywords = false
  ): Promise<AxiosResponse<PaginatedResponse<CampaignInfoWithAdGroups>>> => {
    const allParams = {
      filter: searchText
        ? filtersToQueryString([
            {
              op: FilterOps.like,
              field: 'searchText',
              value: searchText,
            },
          ])
        : '',
      limit: limit.toString(),
      offset: '0',
    };
    const params = filterEmptyValues(allParams);
    return await axios.post<PaginatedResponse<CampaignInfoWithAdGroups>>(
      PATHS.ELIGIBLE_ADGROUPS_PRODUCT_TARGETS(accountId),
      {
        merchantCountryId,
        currency,
        advertisingType: adType,
        isNegativeKeywords,
      },
      { ...config, params }
    );
  };

  const getBidConstraints = async (
    accountId: string,
    request: BidConstraintsRequest
  ): Promise<BidContraints[]> => {
    let axiosInstance = Axios.create();
    axiosRetry(axiosInstance, {
      retries: 3,
      retryDelay: (retryCount: number) => retryCount * DEFAULT_RETRY_INTERVAL,
      retryCondition: () => true,
    });
    const response = await axiosInstance.post<PaginatedResponse<BidContraints>>(
      PATHS.BID_CONSTRAINTS(accountId),
      request,
      {
        ...config,
      }
    );

    const { elements } = response.data;
    return elements;
  };

  const createTargets = async (
    accountId: string,
    isProductTarget: boolean,
    request: CreateTargetKeywordsRequest
  ): Promise<void> => {
    await axios.post<void>(
      isProductTarget
        ? PATHS.CREATE_PRODUCT_TARGETS_ON_DEMAND(accountId)
        : PATHS.CREATE_KEYWORD_TARGETS_ON_DEMAND(accountId),
      request,
      {
        ...config,
      }
    );
  };

  const requestChatGPTKeywords = async (
    accountId: string,
    request: ChatGPTKeywordsRequest
  ): Promise<ChatGPTRequestKeywordResponse> => {
    const response = await axios.post<ChatGPTRequestKeywordResponse>(
      PATHS.REQUEST_CHATGPT_RECOMMENDATIONS(accountId),
      request,
      {
        ...config,
      }
    );
    return response.data;
  };

  const getChatGPTKeywordsStatus = async (
    accountId: string,
    request: ChatGPTRequestKeywordResponse
  ): Promise<ChatGPTRequestKeywordStatus> => {
    const response = await axios.post<ChatGPTRequestKeywordStatus>(
      PATHS.CHATGPT_RECOMMENDATIONS_STATUS(accountId),
      request,
      {
        ...config,
      }
    );
    return response.data;
  };

  const getChatGPTKeywords = async (
    accountId: string,
    request: ChatGPTRequestKeywordResponse
  ): Promise<ChatGPTRequestKeywordResult> => {
    const response = await axios.post<ChatGPTRequestKeywordResult>(
      PATHS.CHATGPT_RECOMMENDATIONS_RESULT(accountId),
      request,
      {
        ...config,
      }
    );
    return response.data;
  };

  const createBrands = async (
    accountId: string,
    request: BrandCreateRequest
  ): Promise<Brand[]> => {
    const response = await axios.post<Brand[]>(
      PATHS.CREATE_BRANDS(accountId),
      request,
      {
        ...config,
      }
    );
    return response.data;
  };

  const updateBrand = async (
    accountId: string,
    request: BrandUpdateRequest
  ): Promise<Brand> => {
    const response = await axios.post<Brand>(
      PATHS.UPDATE_BRANDS(accountId),
      request,
      {
        ...config,
      }
    );
    return response.data;
  };

  const deleteBrand = async (
    accountId: string,
    request: BrandUpdateRequest
  ): Promise<void> => {
    await axios.post<Brand>(PATHS.DELETE_BRANDS(accountId), request, {
      ...config,
    });
  };

  const getBrands = async (
    accountId: string,
    merchantCountryId: string
  ): Promise<Brand[]> => {
    const response = await axios.get<Brand[]>(
      PATHS.LIST_BRANDS(accountId, merchantCountryId),
      {
        ...config,
      }
    );
    return response.data;
  };

  const submitFeedback = async (
    accountId: string,
    request: FeedbackSubmitRequest
  ): Promise<void> => {
    await axios.post<void>(PATHS.SUBMIT_FEEDBACK(accountId), request, {
      ...config,
    });
  };

  const getUserPreference = async (
    accountId: string,
    criteriaType: UserPreferenceType,
    mcIds: string[]
  ): Promise<GetUserPreferenceResponse> => {
    const respose = await axios.post<GetUserPreferenceResponse>(
      PATHS.GET_USER_PREFERENCE(accountId, criteriaType),
      { merchantCountryIds: mcIds },
      { ...config }
    );
    return respose.data;
  };

  const saveUserPreference = async (
    accountId: string,
    request: SaveUserPreferenceRequest
  ): Promise<void> => {
    await axios.post<GetUserPreferenceResponse>(
      PATHS.SAVE_USER_PREFERENCE(accountId),
      request,
      { ...config }
    );
  };

  const getAutoNegateSettings = async (
    accountId: string,
    mcIds: string[]
  ): Promise<UserAutoNegateSettings[]> => {
    if (!mcIds || mcIds.length === 0) {
      return [];
    }
    const respose = await axios.post<UserAutoNegateSettings[]>(
      PATHS.GET_AUTO_NEGATE_SETTINGS(accountId),
      { merchantCountryIds: mcIds },
      { ...config }
    );
    return respose.data;
  };

  const saveAutoNegateSettings = async (
    accountId: string,
    request: SaveAutoNegateSettings
  ): Promise<void> => {
    await axios.post<UserAutoNegateSettings[]>(
      PATHS.SAVE_AUTO_NEGATE_SETTINGS(accountId),
      request,
      { ...config }
    );
  };

  const getSmartCampaignCounts = async (
    accountId: string,
    request: SmartCampaignCountsRequest
  ): Promise<SmartCampaignCountsResponse> => {
    const { data } = await axios.post(
      PATHS.GET_CAMPAIGN_CREATE_SMART_COUNT(accountId),
      request,
      { ...config }
    );
    return data;
  };

  const getAllGroups = async (
    accountId: string,
    merchantCountryId?: string
  ): Promise<Groups[]> => {
    if (!merchantCountryId) {
      return [];
    }

    try {
      const {
        data: { campaignGroups },
      } = await axios.post<GroupsResponse>(
        PATHS.ALL_GROUPS(accountId),
        { merchantCountryId },
        { ...config }
      );
      if (!campaignGroups) return [];
      return Object.keys(campaignGroups).map((group) => ({
        name: group,
        groupId: campaignGroups[group],
        merchantCountryId: merchantCountryId,
      }));
    } catch (error) {
      return [];
    }
  };

  const createCampaignGroups = async (
    accountId: string,
    payload: CampaignGroupCreateRequest
  ): Promise<void> => {
    const response = await axios.post(
      PATHS.CREATE_CAMPAIGN_GROUPS(accountId),
      { ...payload },
      { ...config }
    );
    return response.data;
  };

  const updateCampaignGroups = async (
    accountId: string,
    payload: CampaignGroupEditRequest
  ): Promise<void> => {
    const response = await axios.post(
      PATHS.UPDATE_CAMPAIGN_GROUPS(accountId),
      { ...payload },
      { ...config }
    );
    return response.data;
  };

  const deleteCampaignGroups = async (
    accountId: string,
    payload: DeleteCampaignGroupRequest
  ): Promise<DeleteCampaignGroupResponse> => {
    const response = await axios.post(
      PATHS.DELETE_CAMPAIGN_GROUPS(accountId),
      { ...payload },
      { ...config }
    );
    return response.data;
  };

  const assignCampaignGroups = async (
    accountId: string,
    payload: CampaignGroupAssignRequest
  ): Promise<void> => {
    const response = await axios.post(
      PATHS.ASSIGN_CAMPAIGN_GROUPS(accountId),
      { ...payload },
      { ...config }
    );
    return response.data;
  };

  const getMultiChannelDataSnapshot = async (
    accountId: string,
    request: MultiChannelDataSnapshotRequest,
    sorts: Sort[]
  ): Promise<MultiChannelDataSnapshotResponse> => {
    const allFilters = {
      sort: generateSortQueryString(sorts),
    };
    const params = filterEmptyValues(allFilters);
    const { data } = await axios.post<MultiChannelDataSnapshotResponse>(
      PATHS.MULTI_CHANNEL_DATA_SNAPSHOT(accountId),
      request,
      { ...config, params }
    );
    return {
      ...data,
      channelMetrics: data.channelMetrics.map((item) => {
        return {
          ...item,
          subRows: item.merchantCountries.map((merchantCountry) => {
            return {
              ...merchantCountry,
              subRows: merchantCountry.advertisingTypeMetrics.map((metric) => {
                return {
                  ...metric,
                  salesChannelName: merchantCountry.salesChannelName,
                  salesChannelId: merchantCountry.salesChannelId,
                };
              }),
            };
          }),
        };
      }),
    };
  };

  const exportMultiChannelSnapshot = async (
    accountId: string,
    request: MultiChannelDataSnapshotRequest,
    sorts: Sort[]
  ): Promise<AxiosResponse<Blob>> => {
    const allFilters = {
      sort: generateSortQueryString(sorts),
    };
    const params = filterEmptyValues(allFilters);
    return axios.post<Blob>(
      PATHS.MULTI_CHANNEL_DATA_SNAPSHOT_EXPORT(accountId),
      request,
      { ...config, params }
    );
  };

  const exportCampaignGroupingCsv = (
    accountId: string,
    merchantCountryId: string,
    salesChannelId: string,
    adType: AdType,
    campaignExportRequest: CampaignDataRequest,
    filters: Filter[],
    sorts: Sort[],
    filterFieldMapper?: FilterFieldMapper[],
    merchantType?: MerchantType
  ) => {
    let exportFilters = filters;
    if (filterFieldMapper) {
      exportFilters = filters.map(
        mapFiltersFromFilterFieldMapper(filterFieldMapper)
      );
    }
    const allFilters = {
      filter: filtersToQueryString(exportFilters),
      sort: generateSortQueryString(sorts),
    };
    const params = filterEmptyValues(allFilters);
    const path = PATHS.GET_CAMPAIGN_GROUPING_TEMPLATE_CSV(accountId);
    return axios.post(
      path,
      {
        ...campaignExportRequest,
        merchantCountryIds: [merchantCountryId],
        channelId: MAP_SALES_CHANNEL_ID_TO_NAME[salesChannelId],
        advertisingType: adType,
        merchantType: merchantType || MerchantType.Seller,
      },
      { ...config, params }
    );
  };

  const exportCampaignGroupingErrorCsv = (
    accountId: string,
    errorFileName: string
  ) => {
    const path = PATHS.GET_CAMPAIGN_GROUPING_ERROR_FILE_CSV(accountId);
    return axios.get(path, { ...config, params: { errorFileName } });
  };

  const getCampaignGroupingS3SecureUrl = (
    accountId: string,
    campaignGroupCsvFileName: string
  ) => {
    const path = PATHS.GET_CAMPAIGN_GROUPING_SIGNED_S3_URL(accountId);
    return axios.get(path, {
      ...config,
      params: { campaignGroupCsvFileName },
    });
  };

  const campaignGroupingValidationRequest = (
    accountId: string,
    s3fileName: string
  ) => {
    const path = PATHS.VALIDATE_CAMPAIGN_GROUPING_REQUEST(accountId);
    return axios.get(path, { ...config, params: { s3fileName } });
  };

  const customGroupingValidDataUpload = (
    accountId: string,
    validFileName: string
  ) => {
    const path = PATHS.UPLOAD_VALID_DATA_CAMPAIGN_GROUPING(accountId);
    return axios.get(path, { ...config, params: { validFileName } });
  };

  const customGroupingS3FileUploadStatus = (
    accountId: string,
    jobId: string
  ) => {
    const path = PATHS.UPLOAD_VALID_DATA_CAMPAIGN_GROUPING_STATUS(
      accountId,
      jobId
    );
    return axios.get(path, { ...config });
  };

  const getCampaign =
    (accountId: string, campaignId: string) =>
    async (request: CampaignRequest): Promise<CampaignResponse> => {
      const url = PATHS.CAMPAIGN_URL(accountId, campaignId);
      const response = await axios.post(
        url,
        {
          ...request,
        },
        {
          ...config,
        }
      );

      return response.data;
    };

  const getPerformanceDataPoints =
    (accountId: string) => async (request: ViewTrendsGraphDataRequest) => {
      const url = PATHS.PERFORMANCE_GRAPH_DATA_POINTS(accountId);
      const response = await axios.post<ViewTrendsGraphDataResponse>(
        url,
        {
          ...request,
        },
        {
          ...config,
        }
      );
      return response.data;
    };

  const getSeasonalEvents = async (accountId: string, dateRange: DateRange) => {
    const params = {
      startDate: dateRange.initialStartDate,
      endDate: dateRange.initialEndDate,
    };
    const response = await axios.post<SeasonalEvent[]>(
      PATHS.SEASONAL_EVENTS(accountId),
      params,
      {
        ...config,
        baseURL: COMPASS_BASE_URL,
      }
    );
    return response.data;
  };

  const exportPerformanceDataPoints = async (
    accountId: string,
    request: ViewTrendsGraphDataRequest
  ): Promise<AxiosResponse<Blob>> => {
    const url = PATHS.EXPORT_PERFORMANCE_GRAPH_DATA_POINTS(accountId);

    return axios.post(url, { ...request }, { ...config });
  };

  const getMissingCogs = async (
    accountId: string,
    request: CampaignMissingCogsRequest
  ): Promise<CampaignMissingCogsResponse> => {
    const url = PATHS.GET_MISSING_COGS(accountId);

    const response = await axios.post(url, { ...request }, { ...config });
    return response.data;
  };

  const getCogsDetailsForAdLevel =
    (accountId: string, request: AdLevelCogsDataRequest) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<EstPreAdGrossMarginItem>> => {
      const allParams = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(allParams);

      const response = await axios.post<EstPreAdGrossMarginResponse>(
        PATHS.GET_COGS_DETAILS(accountId),
        {
          ...request,
        },
        {
          ...config,
          params,
        }
      );

      return {
        items: response.data.productDetails,
        totalItems: response.data.productDetails.length,
      };
    };

  const saveCogsDetailsForAdLevel = async (
    accountId: string,
    request: UpdateCogsRequest
  ): Promise<GenericStatusResponse> => {
    const response = await axios.put<GenericStatusResponse>(
      PATHS.UPDATE_COGS(accountId),
      {
        ...request,
      },
      { ...config }
    );
    return response.data;
  };

  const getCogsDetailsForMultipleCampaigns =
    (accountId: string, request: MultipleCampaignsCogsDataRequest) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<EstPreAdGrossMarginItem>> => {
      const allParams = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(allParams);

      const response = await axios.post<EstPreAdGrossMarginResponse>(
        PATHS.GET_MULTIPLE_CAMPAIGNS_COGS_DETAILS(accountId),
        {
          ...request,
        },
        {
          ...config,
          params,
        }
      );

      return {
        items: response.data.productDetails,
        totalItems: response.data.productDetails.length,
      };
    };

  return {
    getMetrics,
    exportAdLevelData,
    getDataInspection,
    getDataAvailabilityInfo,
    getAdLevelData,
    getKeywordData,
    getSKUData,
    updateCampaignChannelSettings,
    updateAdGroupChannelSettings,
    updateFlywheelSettings,
    updateProductChannelSettings,
    updateProfileChannelSettings,
    getDataSyncInfo,
    updateTargetsChannelSettings,
    getPortfolios,
    getTargetTypes,
    validateUniqueValues,
    getSkuAdPerformanceDetails,
    getSkuAdPerformanceSummary,
    manageBiddingValidationRequest,
    manageBiddingValidDataUpload,
    manageBiddingGetS3SecureUrl,
    manageBiddingS3FileUpload,
    manageBiddingS3FileUploadStatus,
    manageBiddingErrorFileDownload,
    getMissingAcosTarget,
    getRecommendationsV2,
    getNegativeRecommendations,
    submitRecommendations,
    submitNegativeRecommendations,
    treUpload,
    treUploadGetS3SecureUrl,
    treUploadS3FileUpload,
    treUploadStatusCheck,
    getBidConstraints,
    eligibleAdGroupsV2ForKeywordTargets,
    eligibleAdGroupsV2ForProductTargets,
    createTargets,
    requestChatGPTKeywords,
    getChatGPTKeywordsStatus,
    getChatGPTKeywords,
    createBrands,
    getBrands,
    deleteBrand,
    updateBrand,
    submitFeedback,
    getUserPreference,
    saveUserPreference,
    getAutoNegateSettings,
    saveAutoNegateSettings,
    getSmartCampaignCounts,
    getAllGroups,
    createCampaignGroups,
    updateCampaignGroups,
    deleteCampaignGroups,
    assignCampaignGroups,
    getMultiChannelDataSnapshot,
    exportMultiChannelSnapshot,
    exportCampaignGroupingCsv,
    exportCampaignGroupingErrorCsv,
    getCampaignGroupingS3SecureUrl,
    campaignGroupingValidationRequest,
    customGroupingValidDataUpload,
    customGroupingS3FileUploadStatus,
    getCampaign,
    getPerformanceDataPoints,
    getSeasonalEvents,
    exportPerformanceDataPoints,
    getTargetRecommendationCountV3,
    getTargetRecommendationCountV3_1,
    getMissingCogs,
    getCogsDetailsForAdLevel,
    saveCogsDetailsForAdLevel,
    getCogsDetailsForMultipleCampaigns,
  };
};

export const getDummyDataInspectionResponse = (
  salesChannel: FlywheelSalesChannel,
  adsManagerDetailsPageFlag: boolean,
  adLevel: AdLevel,
  showSponsoredVideos?: boolean
): Promise<DataInspectionResponse> => {
  const dataInspectionArray: DataInspectionList[] = Object.entries(
    AD_TYPE_CONFIG[salesChannel]
  ).map(([key, value]) => ({
    merchantCountryCode: key as MerchantCountryCode,
    advertisingTypeInfo: getAdTypeInfoItems(
      adLevel,
      salesChannel,
      adsManagerDetailsPageFlag,
      showSponsoredVideos
        ? value
        : value.filter((adType: AdType) => adType !== AdType.SponsoredVideos)
    ),
  }));

  return Promise.resolve({
    dataInspection: dataInspectionArray,
  });
};
