import React, { useEffect, useState } from 'react';
import { CreateKeywordsModal } from './createKeywordsModal';
import { CreateKeywordsProvider } from './createKeywordsProvider';
import { SelectAdgroupsList } from './selectAdgroupsList';
import { MerchantType } from '../../../../../lib/types/Fam';
import { AdType } from '../../../../../lib/types/AOSharedTypes';
import { KeywordType } from './createKeywordsMenu';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../lib/utilities/intercom';

export interface CreateKeywordsModalContainerProps {
  readonly onClose: (success?: boolean) => void;
  readonly dataTestId?: string;
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly merchantType: MerchantType;
  readonly adType: AdType;
  readonly keywordType: KeywordType;
}

export const CreateKeywordsModalContainer: React.FC<
  CreateKeywordsModalContainerProps
> = ({
  onClose,
  merchantCountryId,
  dataTestId,
  salesChannelId,
  merchantType,
  adType,
  keywordType,
}) => {
  const [showSelectAdgroupModal, setShowSelectAdgroupModal] = useState(false);

  const onSelectAdgroupClick = () => {
    setShowSelectAdgroupModal(true);
  };

  const onCloseSelectAdgroupModal = () => {
    setShowSelectAdgroupModal(false);
    onClose();
  };

  useEffect(() => {
    if (showSelectAdgroupModal) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [showSelectAdgroupModal]);

  return (
    <CreateKeywordsProvider
      merchantCountryId={merchantCountryId}
      salesChannelId={salesChannelId}
      merchantType={merchantType}
      adType={adType}
      keywordType={keywordType}
    >
      <div>
        <CreateKeywordsModal
          onSelectAdgroupClick={onSelectAdgroupClick}
          onClose={onClose}
          dataTestId={dataTestId}
        />
        <SelectAdgroupsList
          onClose={onCloseSelectAdgroupModal}
          onBack={() => setShowSelectAdgroupModal(false)}
          showModal={showSelectAdgroupModal}
        />
      </div>
    </CreateKeywordsProvider>
  );
};
