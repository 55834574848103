import { fromNullable } from 'fp-ts/lib/Option';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  AlertTriangleIcon,
  LinkBrokenIcon as EmptyStateIcon,
  QuestionCircleLinearIcon,
  SearchIcon,
  TMTableMeta,
  TableEmptyStateProps,
  Table as TableV2,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../lib/types/I18nKey';
import {
  asyncRequestIdentifiedBy,
  asyncRequestIsComplete,
  asyncRequestIsNotStarted,
} from '../../lib/utilities/asyncRequest';
import { tableActions, tableSelectors, tableThunks } from './ducks';
import {
  INITIAL_LOAD_KEY,
  Table,
  TableWithAggregation,
  WithTable,
} from './ducks/types';
import {
  FlywheelTableV2AggregationCellProps,
  PaginatedTableV2Props,
} from './types';
import {
  getCurrentActiveSortType,
  getSortDirection,
  isLastPage,
  isTableDataApiFailed,
  isTableDataEmpty,
  isTableLoading,
  isTableMissingMerchantCountries,
  isTableWithAggregationHeader,
  resetTableData,
} from './utils';

export const PaginatedTableV2 = <
  T extends Record<string, unknown>,
  A = {},
  R extends TMTableMeta<T> = {},
  S = {}
>(
  props: PaginatedTableV2Props<T, A, R, S>
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    loadingAndEmptyStateClassName = '',
    noDataState,
    missingRequiredFields = [],
  } = props;

  const table = useSelector<WithTable<T, A>, Table<T, A>>((state) => {
    return tableSelectors.getTableSelector<T, A>()(
      state.tableState,
      props.tableId
    );
  });

  const visiblePage = table.visiblePage;
  const currentPageData = fromNullable(table.pages[visiblePage - 1]).getOrElse(
    asyncRequestIdentifiedBy<T[], void>(
      INITIAL_LOAD_KEY
    ).AsyncRequestNotStarted()
  );
  const aggregationHeaderCurrentData = fromNullable(
    isTableWithAggregationHeader(table)
      ? (table as TableWithAggregation<T, A>).aggregationData
      : null
  ).getOrElse(
    asyncRequestIdentifiedBy<A, void>(INITIAL_LOAD_KEY).AsyncRequestNotStarted()
  );
  const aggregationAdditionalOfData = fromNullable(
    isTableWithAggregationHeader(table)
      ? (table as TableWithAggregation<T, S>).additionalAggregationData
      : null
  ).getOrElse(
    asyncRequestIdentifiedBy<S, void>(INITIAL_LOAD_KEY).AsyncRequestNotStarted()
  );

  const rowData: T[] = asyncRequestIsComplete(currentPageData)
    ? currentPageData.result
    : [];

  const aggregationRowCurrentData: A = asyncRequestIsComplete(
    aggregationHeaderCurrentData
  )
    ? aggregationHeaderCurrentData.result
    : ({} as A);

  const aggregationRowPreviousData: S = asyncRequestIsComplete(
    aggregationAdditionalOfData
  )
    ? aggregationAdditionalOfData.result
    : ({} as S);

  const aggregationHeaderData: FlywheelTableV2AggregationCellProps<A, S> = {
    current: aggregationRowCurrentData,
    previous:
      props.shouldExecuteAdditionalAggregationDataFetcher &&
      props.additionalAggregationHeaderDataFetcher
        ? aggregationRowPreviousData
        : aggregationRowCurrentData,
  };

  const loadBufferPage = (page: number) => {
    if (
      table.bufferItemsToLoad &&
      table.visiblePage &&
      missingRequiredFields.length === 0
    ) {
      if (!isLastPage(table)) {
        dispatch(
          tableThunks.refreshBufferData(
            props.tableId,
            {
              sorts: table.sorts,
              filters: table.filters,
              extraParams: {
                ...table.extraPaginationParams,
                ...table.extraQueryParams,
              },
              itemsPerPage: table.bufferItemsToLoad,
              page,
            },
            props.dataFetcher
          )
        );
      } else {
        dispatch(tableActions.clearBuffer({ tableId: props.tableId }));
      }
    }
  };

  const loadPage = (page: number): void => {
    dispatch(
      tableThunks.loadPageOfData(
        props.tableId,
        {
          sorts: table.sorts,
          filters: table.filters,
          extraParams: {
            ...table.extraPaginationParams,
            ...table.extraQueryParams,
          },
          itemsPerPage: table.itemsPerPage,
          page,
        },
        props.dataFetcher
      )
    );
    loadBufferPage(page + 1);
  };

  const loadAggregationHeaderData = (): void => {
    props.aggregationHeaderDataFetcher &&
      dispatch(
        tableThunks.loadAggregationData(
          props.tableId,
          props.aggregationHeaderDataFetcher,
          props.fetchAndStoreTotalKey
        )
      );

    if (
      props.shouldExecuteAdditionalAggregationDataFetcher &&
      props.additionalAggregationHeaderDataFetcher
    ) {
      dispatch(
        tableThunks.loadAdditionalAggregationData(
          props.tableId,
          props.additionalAggregationHeaderDataFetcher
        )
      );
    }
  };

  const loadCurrentPage = () => {
    loadPage(visiblePage);
  };

  useEffect(() => {
    if (
      asyncRequestIsNotStarted(currentPageData) &&
      missingRequiredFields.length === 0
    ) {
      loadCurrentPage();
    }
    if (
      asyncRequestIsNotStarted(aggregationHeaderCurrentData) &&
      missingRequiredFields.length === 0
    ) {
      loadAggregationHeaderData();
    }
  });

  useEffect(() => {
    loadBufferPage(visiblePage + 1);
  }, [table.toggleBufferItemsChanged]);

  useEffect(() => {
    if (missingRequiredFields.length > 0) {
      resetTableData(table, dispatch, props.tableId, true);
    }
  }, [missingRequiredFields]);

  useEffect(() => {
    resetTableData(table, dispatch, props.tableId, true);
  }, [
    table.filters,
    table.sorts,
    table.extraPaginationParams,
    table.itemsPerPage,
  ]);

  const onSortClick = (type: string, columnId: string) => {
    const sortDirection = getSortDirection(type);
    if (sortDirection) {
      dispatch(
        tableActions.sortApplied({
          sort: { column: columnId, direction: sortDirection },
          tableId: props.tableId,
        })
      );
    }
  };

  const currentSort = table.sorts.length > 0 ? table.sorts[0] : undefined;

  const loadingStateProps: TableEmptyStateProps = {
    titleI18nKey: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_TABLE_LOOKING_AROUND,
    }),
    descriptionI18nKey: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_TABLE_TAKING_A_LOOK,
    }),
    icon: SearchIcon,
    className: loadingAndEmptyStateClassName,
  };

  const defaultNoDataStateProps = {
    icon: EmptyStateIcon,
    titleI18nKey: I18nKey.TABLE_NO_DATA_TITLE,
    descriptionI18nKey: I18nKey.TABLE_NO_DATA_DESCIPTION,
    className: loadingAndEmptyStateClassName,
  };

  const getEmptyState = (): TableEmptyStateProps | undefined => {
    const apiFailedStateProps: TableEmptyStateProps = {
      icon: AlertTriangleIcon,
      titleI18nKey: I18nKey.TABLE_API_ERROR_TITLE,
      descriptionI18nKey: I18nKey.TABLE_API_ERROR_DESCRIPTION,
      className: loadingAndEmptyStateClassName,
      showPrimaryButton: true,
      onClickPrimaryButton: loadCurrentPage,
      primaryButtonI18nKey: I18nKey.TABLE_API_ERROR_TRY_AGAIN,
    };

    const missingMerchantCountryRequiredFieldStateProps: TableEmptyStateProps =
      {
        icon: QuestionCircleLinearIcon,
        titleI18nKey: I18nKey.NO_MERCHANTS_SELECTED,
        descriptionI18nKey: I18nKey.MISSING_DATA_SUGGESTION,
        className: loadingAndEmptyStateClassName,
        showPrimaryButton: false,
      };

    const noDataStateProps: TableEmptyStateProps =
      noDataState || defaultNoDataStateProps;

    const noDataWithFiltersProps: TableEmptyStateProps = {
      icon: AlertTriangleIcon,
      titleI18nKey: I18nKey.TABLE_NO_DATA_WITH_FILTER_TITLE,
      descriptionI18nKey: I18nKey.TABLE_NO_DATA_WITH_FILTER_DESCRIPTION,
      className: loadingAndEmptyStateClassName,
    };

    if (isTableMissingMerchantCountries(missingRequiredFields)) {
      return missingMerchantCountryRequiredFieldStateProps;
    } else if (isTableDataEmpty(currentPageData)) {
      return table.filters.length > 0
        ? noDataWithFiltersProps
        : noDataStateProps;
    } else if (isTableDataApiFailed(currentPageData.kind)) {
      return apiFailedStateProps;
    }
  };

  return (
    <TableV2<T, FlywheelTableV2AggregationCellProps<A, S>, R>
      columns={props.columns}
      data={rowData}
      dataTestId={props.tableId}
      onSort={onSortClick}
      activeSortType={getCurrentActiveSortType(currentSort)}
      activeSortColumnAccessor={currentSort?.column}
      activeSortCellClassName="bg-white"
      meta={props.tableData}
      total={aggregationHeaderData}
      className={props.tableClassName}
      emptyState={getEmptyState()}
      loadingState={
        isTableLoading(currentPageData.kind) ? loadingStateProps : undefined
      }
      showBulkSelection={props.showBulkSelection}
      rowIdsDisabled={props.rowIdsDisabled}
      rowIdsSelected={props.rowIdsSelected}
      rowIdKeyName={props.rowIdKeyName}
      onRowsSelection={props.onRowsSelection}
      bulkEditMenu={props.bulkEditMenu}
      rowCheckboxClassName={props.rowCheckboxClassName}
      cellClassName={props.cellClassName}
      subCellClassName={props.subCellClassName}
      tableEmptyStateClass={props.tableEmptyStateClass}
      bulkEditColumnWidth={props.bulkEditColumnWidth}
      headerClass={props.headerClass}
      allowStickyHeader={props.allowStickyHeader}
      customStickyHeaderClassName={props.customStickyHeaderClassName}
      allowHorizontalDrag={props.allowHorizontalDrag}
      onTableHeaderReachingTop={props.onFullPageTable}
      infiniteScrollProps={props.infiniteScrollProps}
      virtualizationProps={props.virtualizationProps}
      expandAllRows={props.expandAllRows}
      enableExpandSubRows={props.enableExpandSubRows}
      minColumnSize={props.minColumnSize}
      resetSelection={props.resetSelection}
      bulkSelectCellTooltip={props.bulkSelectCellTooltip}
      bulkSelectHeaderTooltip={props.bulkSelectHeaderTooltip}
      bulkSelectCellDisabledTooltip={props.bulkSelectCellDisabledTooltip}
      onToggleExpand={props.onToggleExpand}
      rowIdsProcessing={props.rowIdsProcessing}
      applyStickyOnOverflow={props.applyStickyOnOverflow}
      showExpandAllRowsButton={props.showExpandAllRowsButton}
      name={props.tableName}
    />
  );
};
PaginatedTableV2.displayName = 'PaginatedTableV2';
