import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import { SbAdsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { SB_ADS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<SbAdsDetails & TableDataAdsManager> = (
  props
) =>
  makeNumericColumn<SbAdsDetails>(
    ({ creativePerformance: { adConversions } }) => {
      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        undefined,
        adConversions
      );

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const adConversionsColumn: FlywheelTableColumn<
  SbAdsDetails,
  TableDataAdsManager
> = {
  columnName: SB_ADS_API_COLUMN_NAME.AdConversions,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_CONVERSIONS,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
};

export const adConversionsFilter = createNumericDataFieldFilter(
  SB_ADS_API_COLUMN_NAME.AdConversions,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_CONVERSIONS,
  isValidNumber(0)
);
