import {
  Button,
  ButtonSize,
  ButtonVariant,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import I18nKey from '../../../lib/types/I18nKey';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface OwnProps {
  readonly riskFreeTrialMessage?: React.ReactNode;
  readonly riskFreeTrialInit?: () => void;
  readonly riskFreeModal?: JSX.Element;
}

export const RiskFreeTrialBanner: React.FC<OwnProps> = ({
  riskFreeTrialMessage,
  riskFreeTrialInit,
  riskFreeModal,
}) => {
  return (
    <div className="sticky top-0 bg-purple-100 z-10">
      <div
        className={classNames(
          'flex justify-center items-center',
          'bg-purple-100 h-48 w-full mt-32'
        )}
      >
        <Typography
          size={TypographySize.sm}
          lineHeight={TypographyLineHeight.tight}
          weight={TypographyWeight.medium}
          className="text-purple-700 mr-24"
        >
          {riskFreeTrialMessage}
        </Typography>
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Primary}
          onClick={riskFreeTrialInit}
          label={
            <FormattedMessage id={I18nKey.GENERIC_TRY_RISK_FREE_ONE_MONTH} />
          }
        />
        {riskFreeModal}
      </div>
    </div>
  );
};
RiskFreeTrialBanner.displayName = 'RiskFreeTrialBanner';
