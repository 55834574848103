import { GenerateReportState } from './Fam';

export interface StringMap<V> {
  [n: string]: V | undefined;
}

export interface StrictStringMap<V> {
  [n: string]: V;
}

export interface NumberMap<V> {
  [n: number]: V | undefined;
}

export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

export const DEFAULT_PAGE_SIZE = 25;

export const DEFAULT_GENERATE_REPORT_STATE = GenerateReportState.NotStarted;
export const DEFAULT_NUMBER_OF_DAYS = 30;

export const LOOKER_REPORT_STATUS_POLLING_INTERVAL = 10000;
export const LOOKER_REPORT_STATUS_POLLING_MAX_TRIES = 10;
