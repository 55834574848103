import { Factory } from 'fishery';
import { SkuDetailsEventResponse } from '../../types/SKUSharedTypes';

export const EventsData = Factory.define<SkuDetailsEventResponse[]>(() => [
  {
    category: 'Content',
    name: 'randomName',
    eventType: 'A+ module(s) created, A+ module(s) updated',
    sku: 'abcdefgh',
    externalId: '1234',
    eventDate: '2022-12-01T14:15:22Z',
    createdAt: '2022-12-01T14:15:22Z',
    updatedAt: '2023-01-31T14:15:22Z',
    updatedBy: '67890876',
    createdBy: 'aa4f1457-4a4e-42ef-9f4e-8aca72bcaf8a',
    description: 'some description',
    ingestionType: 'user',
  },
  {
    category: 'Content',
    name: 'randomName',
    eventType: 'A+ module(s) created, Bullets updated',
    sku: 'abcdefgh',
    externalId: '1234',
    eventDate: '2022-12-01T14:15:22Z',
    createdAt: '2022-12-01T14:15:22Z',
    updatedAt: '2023-01-31T14:15:22Z',
    updatedBy: '67890876',
    createdBy: '19ac7cae-2c0f-4a29-bbd1-6da198c8bad4',
    description: 'some description',
    ingestionType: 'user',
  },
  {
    category: 'Content',
    name: 'randomName',
    eventType:
      'Description updated, Bullets updated, Other Image(s) updated, Primary image(s) updated',
    sku: 'abcdefgh',
    externalId: '1234',
    eventDate: '2022-12-01T14:15:22Z',
    createdAt: '2022-12-01T14:15:22Z',
    updatedAt: '2023-01-31T14:15:22Z',
    updatedBy: '67890876',
    createdBy: '19ac7cae-2c0f-4a29-bbd1-6da198c8bad4',
    description: 'some description',
    ingestionType: 'user',
  },
  {
    category: 'Content',
    name: 'randomName',
    eventType: 'Title Updated',
    sku: 'abcdefgh',
    externalId: '1234',
    eventDate: '2022-12-01T14:15:22Z',
    createdAt: '2022-12-01T14:15:22Z',
    updatedAt: '',
    updatedBy: '67890876',
    createdBy: 'aa4f1457-4a4e-42ef-9f4e-8aca72bcaf8a',
    description: 'some description',
    ingestionType: 'user',
  },
  {
    category: 'Product Catalog',
    name: 'randomName',
    eventType: 'Product Catalog List Price Change',
    sku: 'abcdefgh',
    externalId: '1234',
    eventDate: '2022-12-01T14:15:22Z',
    createdAt: '2022-12-01T14:15:22Z',
    updatedAt: '',
    updatedBy: '67890876',
    createdBy: 'aa4f1457-4a4e-42ef-9f4e-8aca72bcaf8a',
    description: 'some description',
    ingestionType: 'user',
  },
]);
