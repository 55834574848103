import { Factory } from 'fishery';

import { SearchTermStatus, SearchTermsData } from '../../types/MISharedTypes';

export default Factory.define<SearchTermsData>(() => ({
  created_at: '2021-02-04, at 22:58:52 PST',
  locale: 'US',
  platform: 'Amazon',
  search_term: 'prestiges',
  status: SearchTermStatus.Active,
}));
