import isNil from 'lodash/isNil';
import React, { useContext, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  SelectV2OptionProps,
  TablePagination,
} from '@teikametrics/tm-design-system';

import { TotalCountFetcher } from '../../lib/clients/types';
import { getCurrentAccountFromContext } from '../userProvider/selectors';
import { UserContext, UserContextState } from '../userProvider/userProvider';
import { tableActions, tableSelectors } from './ducks';
import { loadTotalCount } from './ducks/thunks';
import { FullCountLoadingState, TotalCount, WithTable } from './ducks/types';
import { storeItemsPerPageInStorage } from './utils';
import { Filter } from '../../lib/types/Filter';
import { storeCurrentPageToSessionStorage } from '../../lib/utilities/storageUtils';

interface FlywheelTablePaginationRowV2Props {
  readonly tableId: string;
  readonly loadAggregationDataOnPageNavigation: boolean;
  readonly onPaginationChange: (currentPage: number, pageSize: number) => void;
  readonly loadTotalFromAggregateData?: boolean;
  readonly totalCountFetcher?: TotalCountFetcher;
  readonly filters?: Filter[];
  readonly rowsPerPageOptions?: SelectV2OptionProps<number>[];
}

interface StateFromRedux {
  readonly itemsPerPage: number;
  readonly currentPage: number;
  readonly totalElements: number;
}

const DEFAULT_TOTAL_ELEMENTS = 0;
const DEFAULT_ROWS_PER_PAGE = 100;

export const FlywheelTablePaginationRowV2: React.FC<
  FlywheelTablePaginationRowV2Props
> = <T extends {}>(props: FlywheelTablePaginationRowV2Props) => {
  const dispatch = useDispatch();
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;
  const userId = userContext.userInfo.userDetails?.id || '';

  const {
    itemsPerPage,
    currentPage,
    totalElements: totalItems,
  } = useSelector<WithTable<T>, StateFromRedux>((state) => {
    const table = tableSelectors.getTableSelector()(
      state.tableState,
      props.tableId
    );

    return {
      currentPage: table.visiblePage,
      itemsPerPage: table.itemsPerPage,
      totalElements: table.maybeTotalItems ?? DEFAULT_TOTAL_ELEMENTS,
    };
  }, shallowEqual);

  const [currentRowsPerPage, setcurrentRowsPerPage] = useState<number>(
    DEFAULT_ROWS_PER_PAGE
  );

  const totalCount = useSelector<WithTable<T, {}>, TotalCount | undefined>(
    (state) => {
      const table = tableSelectors.getTableSelector()(
        state.tableState,
        props.tableId
      );
      return table.totalCount;
    }
  );

  React.useEffect(() => {
    props.filters &&
      props.totalCountFetcher &&
      dispatch(
        loadTotalCount(
          props.tableId,
          { filters: props.filters },
          props.totalCountFetcher
        )
      );
  }, [props.filters]);

  // When the user uses the "Arrow Navigation" to change pages we need to sync
  // up the value of the input
  React.useEffect(() => {
    setcurrentRowsPerPage(itemsPerPage);
  }, [itemsPerPage]);

  const onChangePage = (pageNumber: number, numberOfRows: number) => {
    if (pageNumber >= 0) {
      dispatch(
        tableActions.setVisiblePage({
          page: pageNumber,
          tableId: props.tableId,
        })
      );
      storeCurrentPageToSessionStorage(userId, accountId, pageNumber);

      if (props.loadAggregationDataOnPageNavigation) {
        dispatch(
          tableActions.dataResetAggregationHeader({ tableId: props.tableId })
        );
      }
      props.onPaginationChange(pageNumber, numberOfRows);
    }
  };

  const onChangeRowsPerPage = (rowsPerPage: number) => {
    if (rowsPerPage >= 0) {
      const pageSize = rowsPerPage.toString();
      storeItemsPerPageInStorage({
        userId,
        tableId: props.tableId,
        itemsPerPage: rowsPerPage.toString(),
      });
      dispatch(
        tableActions.setItemsPerPage({
          itemsPerPage: Number(pageSize),
          tableId: props.tableId,
        })
      );
      props.onPaginationChange(currentPage, rowsPerPage);
    }
  };

  return (
    <TablePagination
      totalItems={
        (props.totalCountFetcher || props.loadTotalFromAggregateData) &&
        totalCount &&
        totalCount.totalCount
          ? totalCount.totalCount
          : totalItems
      }
      currentPage={currentPage}
      onChangePage={onChangePage}
      currentRowsPerPage={currentRowsPerPage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      rowsPerPageOptions={props.rowsPerPageOptions}
      dataTestId={props.tableId}
      isPageCountLoading={
        totalCount?.loadingState !== FullCountLoadingState.Completed &&
        totalCount?.loadingState !== FullCountLoadingState.Failed &&
        totalCount?.loadingTotalCount &&
        !isNil(props.totalCountFetcher || props.loadTotalFromAggregateData)
      }
    />
  );
};
FlywheelTablePaginationRowV2.displayName = 'FlywheelTablePaginationRowV2';
