import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  CreativeServingStatus,
  SbAdsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import { SB_ADS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import I18nKey from '../../../../../lib/types/I18nKey';
import { useIntl } from 'react-intl';

const CREATIVE_SERVING_STATUS_I18NKEY_MAPPER: Record<
  CreativeServingStatus,
  I18nKey
> = {
  [CreativeServingStatus.AdStatusLive]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_STATUS_LIVE,
  [CreativeServingStatus.AdPolicingPendingReview]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_POLICING_PENDING_REVIEW,
  [CreativeServingStatus.AdPolicingSuspended]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_POLICING_SUSPENDED,
  [CreativeServingStatus.AdPaused]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_PAUSED,
  [CreativeServingStatus.AdArchived]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_ARCHIVED,
  [CreativeServingStatus.AdGroupStatusEnabled]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_STATUS_ENABLED,
  [CreativeServingStatus.AdGroupPaused]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_PAUSED,
  [CreativeServingStatus.AdGroupArchived]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_ARCHIVED,
  [CreativeServingStatus.AdGroupIncomplete]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_INCOMPLETE,
  [CreativeServingStatus.AdGroupPolicingPendingReview]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_POLICING_PENDING_REVIEW,
  [CreativeServingStatus.AdGroupPolicingCreativeRejected]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_POLICING_CREATIVE_REJECTED,
  [CreativeServingStatus.AdGroupLowBid]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_LOW_BID,
  [CreativeServingStatus.AdvertiserStatusEnabled]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_STATUS_ENABLED,
  [CreativeServingStatus.AdvertiserPolicingPendingReview]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_POLICING_PENDING_REVIEW,
  [CreativeServingStatus.AdvertiserPolicingSuspended]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_POLICING_SUSPENDED,
  [CreativeServingStatus.AdvertiserPaused]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_PAUSED,
  [CreativeServingStatus.AdvertiserArchived]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_ARCHIVED,
  [CreativeServingStatus.AdvertiserPaymentFailure]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_PAYMENT_FAILURE,
  [CreativeServingStatus.AdvertiserAccountOutOfBudget]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_ACCOUNT_OUT_OF_BUDGET,
  [CreativeServingStatus.AdvertiserOutOfPrepayBalance]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_OUT_OF_PREPAY_BALANCE,
  [CreativeServingStatus.AdvertiserExceedSpendsLimit]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_EXCEED_SPENDS_LIMIT,
  [CreativeServingStatus.CampaignStatusEnabled]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_STATUS_ENABLED,
  [CreativeServingStatus.CampaignPaused]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_PAUSED,
  [CreativeServingStatus.CampaignArchived]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_ARCHIVED,
  [CreativeServingStatus.CampaignIncomplete]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_INCOMPLETE,
  [CreativeServingStatus.CampaignOutOfBudget]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_OUT_OF_BUDGET,
  [CreativeServingStatus.PortfolioStatusEnabled]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_STATUS_ENABLED,
  [CreativeServingStatus.PortfolioPaused]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_PAUSED,
  [CreativeServingStatus.PortfolioArchived]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_ARCHIVED,
  [CreativeServingStatus.PortfolioOutOfBudget]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_OUT_OF_BUDGET,
  [CreativeServingStatus.PortfolioPendingStartDate]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_PENDING_START_DATE,
  [CreativeServingStatus.PortfolioEnded]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_ENDED,
  [CreativeServingStatus.Ineligible]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_INELIGIBLE,
  [CreativeServingStatus.Eligible]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ELIGIBLE,
  [CreativeServingStatus.Ended]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ENDED,
  [CreativeServingStatus.PendingReview]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PENDING_REVIEW,
  [CreativeServingStatus.PendingStartDate]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PENDING_START_DATE,
  [CreativeServingStatus.Rejected]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_REJECTED,
  [CreativeServingStatus.Unknown]:
    I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_UNKNOWN,
};

export const RowCellElement: React.FC<SbAdsDetails> = ({
  creativeDetails,
  creativePerformance,
  creativeId,
  channelSettings,
}) => {
  const intl = useIntl();
  const creativeData: SbAdsDetails = {
    creativeDetails,
    creativePerformance,
    creativeId,
    channelSettings,
  };

  return makeTextColumn<SbAdsDetails>(
    ({ creativeDetails: { creativeServingStatus } }) =>
      creativeServingStatus &&
      intl.formatMessage({
        id:
          CREATIVE_SERVING_STATUS_I18NKEY_MAPPER?.[creativeServingStatus] ??
          I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_UNKNOWN,
      })
  )(creativeData);
};
RowCellElement.displayName = 'RowCellElement';

export const servingStatusColumn: FlywheelTableColumn<SbAdsDetails> = {
  columnName: SB_ADS_API_COLUMN_NAME.ServingStatus,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_SB_ADS_TABLE_COLUMN_SERVING_STATUS,
  RowCellElement,
  gridColumnWidth: '240px',
};

export const servingStatusFilter = () =>
  createMultiSelectDataFieldFilter(
    SB_ADS_API_COLUMN_NAME.ServingStatus,
    I18nKey.ADS_MANAGER_SB_ADS_TABLE_COLUMN_SERVING_STATUS,
    [
      SelectFilterOption.CreativeServingStatusAdStatusLive,
      SelectFilterOption.CreativeServingStatusAdPolicingPendingReview,
      SelectFilterOption.CreativeServingStatusAdPolicingSuspended,
      SelectFilterOption.CreativeServingStatusAdPaused,
      SelectFilterOption.CreativeServingStatusAdArchived,
      SelectFilterOption.CreativeServingStatusAdGroupStatusEnabled,
      SelectFilterOption.CreativeServingStatusAdGroupPaused,
      SelectFilterOption.CreativeServingStatusAdGroupArchived,
      SelectFilterOption.CreativeServingStatusAdGroupIncomplete,
      SelectFilterOption.CreativeServingStatusAdGroupPolicingPendingReview,
      SelectFilterOption.CreativeServingStatusAdGroupPolicingCreativeRejected,
      SelectFilterOption.CreativeServingStatusAdGroupLowBid,
      SelectFilterOption.CreativeServingStatusAdvertiserStatusEnabled,
      SelectFilterOption.CreativeServingStatusAdvertiserPolicingPendingReview,
      SelectFilterOption.CreativeServingStatusAdvertiserPolicingSuspended,
      SelectFilterOption.CreativeServingStatusAdvertiserPaused,
      SelectFilterOption.CreativeServingStatusAdvertiserArchived,
      SelectFilterOption.CreativeServingStatusAdvertiserPaymentFailure,
      SelectFilterOption.CreativeServingStatusAdvertiserAccountOutOfBudget,
      SelectFilterOption.CreativeServingStatusAdvertiserOutOfPrepayBalance,
      SelectFilterOption.CreativeServingStatusAdvertiserExceedSpendsLimit,
      SelectFilterOption.CreativeServingStatusCampaignStatusEnabled,
      SelectFilterOption.CreativeServingStatusCampaignPaused,
      SelectFilterOption.CreativeServingStatusCampaignArchived,
      SelectFilterOption.CreativeServingStatusCampaignIncomplete,
      SelectFilterOption.CreativeServingStatusCampaignOutOfBudget,
      SelectFilterOption.CreativeServingStatusPortfolioStatusEnabled,
      SelectFilterOption.CreativeServingStatusPortfolioPaused,
      SelectFilterOption.CreativeServingStatusPortfolioArchived,
      SelectFilterOption.CreativeServingStatusPortfolioOutOfBudget,
      SelectFilterOption.CreativeServingStatusPortfolioPendingStartDate,
      SelectFilterOption.CreativeServingStatusPortfolioEnded,
      SelectFilterOption.CreativeServingStatusIneligible,
      SelectFilterOption.CreativeServingStatusEligible,
      SelectFilterOption.CreativeServingStatusEnded,
      SelectFilterOption.CreativeServingStatusPendingReview,
      SelectFilterOption.CreativeServingStatusPendingStartDate,
      SelectFilterOption.CreativeServingStatusRejected,
      SelectFilterOption.CreativeServingStatusUnknown,
    ]
  );
