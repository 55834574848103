import { MerchantCountry } from '../../lib/types/Fam';
import I18nKey from '../../lib/types/I18nKey';
import { TargetingType } from '../../lib/types/KeywordAction';
import { MerchantCountryWithCount } from './components/KeywordsDashboard2';

export const TargetingTypeToColor: Record<TargetingType, string> = {
  [TargetingType.Manual]: 'text-magenta-700',
  [TargetingType.Auto]: 'text-blue-700',
};

export const TargetingTypeToColorBg: Record<TargetingType, string> = {
  [TargetingType.Manual]: 'bg-magenta-100',
  [TargetingType.Auto]: 'bg-blue-200',
};

export const DEFAULT_CURRENCY = 'USD';

export enum KeywordLevel {
  Keywords = 'Keywords',
  NegativeKeywords = 'NegativeKeywords',
}

export const MAP_KEYWORD_TABS_TO_I18nKEY: Record<KeywordLevel, I18nKey> = {
  [KeywordLevel.Keywords]:
    I18nKey.AI_RECOMMENDED_KEYWORD_AD_SALES_KEYWORDS_TO_ADD,
  [KeywordLevel.NegativeKeywords]:
    I18nKey.AI_RECOMMENDED_KEYWORD_AD_SALES_NEGATIVE_KEYWORDS_TO_ADD,
};

export const sortMerchantsByName = (
  mc1: MerchantCountryWithCount,
  mc2: MerchantCountryWithCount
) => {
  const mc1Name = mc1.merchantName.toLowerCase();
  const mc2Name = mc2.merchantName.toLowerCase();

  if (mc1Name < mc2Name) {
    return -1;
  }
  if (mc1Name > mc2Name) {
    return 1;
  }
  return 0;
};

export const sortMerchantsByNameV1 = (
  mc1: MerchantCountry,
  mc2: MerchantCountry
) => {
  const mc1Name = mc1.merchantName.toLowerCase();
  const mc2Name = mc2.merchantName.toLowerCase();

  if (mc1Name < mc2Name) {
    return -1;
  }
  if (mc1Name > mc2Name) {
    return 1;
  }
  return 0;
};
