import './styles.scss';

import classNames from 'classnames';
import { fromNullable } from 'fp-ts/lib/Option';
import React, { PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Pill, PillColor } from '../../../../components/Pill';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  ErrorsCsv,
  MISSING_VALUE,
  WRONG_VALUE,
} from '../../../../lib/types/MISharedTypes';
import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
} from '@teikametrics/tm-design-system';

export interface CsvFileSearchTermStatus {
  readonly newTerms: number;
  readonly existingTerms: number;
  readonly totalTerms: number;
  readonly termsWIthError: number;
  readonly duplicatedTerms: number;
  readonly errorsCsv: ErrorsCsv[];
}

interface ManageUploadFileProps {
  readonly isFileChosen: boolean;
  readonly title: string | JSX.Element;
  readonly fileIcon?: JSX.Element;
  readonly subTitle?: string | JSX.Element;
  readonly fileName?: string | JSX.Element;
  readonly description?: string | JSX.Element;
  readonly buttonPrimaryText: string | JSX.Element;
  readonly buttonPrimaryOnClick?: () => void;
  readonly buttonPrimaryDisabled?: boolean;
  readonly buttonPrimaryLoading?: boolean;
  readonly buttonSecondaryText: string | JSX.Element;
  readonly buttonSecondaryOnClick: () => void;
  readonly errorWhileUploading?: boolean;
  readonly inputTypeFileId?: string;
  readonly fileInputOnChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  readonly csvFileSearchTermStatus?: CsvFileSearchTermStatus;
  readonly extraInformationBottom?: JSX.Element;
  readonly errors?: ErrorsCsv[];
  readonly dataTestId?: string;
}

export const ManageUploadFile: React.FC<
  ManageUploadFileProps & PropsWithChildren
> = (props) => (
  <div className="manage-upload-file">
    <div className="manage-upload-file__title">{props.title}</div>
    {props.isFileChosen && (
      <div
        className={classNames('manage-upload-file__information-container', {
          'manage-upload-file__error-while-uploading':
            props.errorWhileUploading,
        })}
      >
        <div className="manage-upload-file__information-data">
          <div
            className={classNames('manage-upload-file__icon', {
              'manage-upload-file__icon--error': props.errorWhileUploading,
            })}
          >
            {props.fileIcon}
          </div>
          <div className="manage-upload-file__information">
            <div className="manage-upload-file__subtitle">{props.subTitle}</div>
            {props.csvFileSearchTermStatus && (
              <CSVFileStatus
                csvFileSearchTermStatus={props.csvFileSearchTermStatus}
              />
            )}
            <div
              className={classNames('manage-upload-file__filename', {
                'manage-upload-file__filename--error':
                  props.errorWhileUploading,
              })}
            >
              {props.fileName}
            </div>
            {props.description && (
              <div
                className={classNames('manage-upload-file__description', {
                  'manage-upload-file__description--error':
                    props.errorWhileUploading,
                })}
              >
                {props.description}
              </div>
            )}
          </div>
        </div>
        {props.errors && props.errors.length > 0 && (
          <CSVErrors
            errorsCsv={props.errors}
            dataTestId={`${props.dataTestId}_manageUploadFile`}
          />
        )}
        {props.extraInformationBottom}
      </div>
    )}
    {props.children}
    <div className="manage-upload-file__divider" />
    <div className="manage-upload-file__buttons">
      <div className="manage-upload-file__button-secondary">
        <Button
          onClick={props.buttonSecondaryOnClick}
          dataTestId={`${props.dataTestId}_uploadFileSecondary`}
          size={ButtonSize.Large}
          variant={ButtonVariant.Primary}
          label={props.buttonSecondaryText}
        />
      </div>
      <div className="manage-upload-file__button-primary">
        {props.inputTypeFileId ? (
          <>
            <label
              htmlFor={props.inputTypeFileId}
              className="manage-upload-file__input-label"
            >
              {props.buttonPrimaryText}
            </label>
            <input
              id={props.inputTypeFileId}
              type="file"
              accept="text/csv"
              onChange={fromNullable(props.fileInputOnChange)
                .map((fileInputOnChange) => fileInputOnChange)
                .toUndefined()}
              className="manage-upload-file__input"
            />
          </>
        ) : (
          <Button
            onClick={fromNullable(props.buttonPrimaryOnClick)
              .map((buttonPrimaryOnClick) => buttonPrimaryOnClick)
              .toUndefined()}
            state={
              (props.buttonPrimaryDisabled && ButtonState.Disabled) ||
              (props.buttonPrimaryLoading && ButtonState.Loading) ||
              ButtonState.Enabled
            }
            dataTestId={`${props.dataTestId}_uploadFilePrimary`}
            size={ButtonSize.Large}
            variant={ButtonVariant.Primary}
            label={props.buttonPrimaryText}
          />
        )}
      </div>
    </div>
  </div>
);
ManageUploadFile.displayName = 'ManageUploadFile';

const CSVFileStatus: React.FC<{
  readonly csvFileSearchTermStatus: CsvFileSearchTermStatus;
}> = ({ csvFileSearchTermStatus }) => (
  <div className="manage-upload-file__csv-file-status">
    {csvFileSearchTermStatus.termsWIthError ? (
      <FormattedMessage
        id={
          I18nKey.MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_CSV_FILE_STATUS_WITH_ERROR
        }
        values={{
          termsWIthError: csvFileSearchTermStatus.termsWIthError,
          newTerms: csvFileSearchTermStatus.newTerms,
          existingTerms: csvFileSearchTermStatus.existingTerms,
          span: (errorSentence: ReactNode) => (
            <span className="manage-upload-file__csv-file-status-error">
              {errorSentence}
            </span>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        id={
          I18nKey.MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_CSV_FILE_STATUS
        }
        values={{
          newTerms: csvFileSearchTermStatus.newTerms,
          existingTerms: csvFileSearchTermStatus.existingTerms,
        }}
      />
    )}
  </div>
);
CSVFileStatus.displayName = 'CSVFileStatus';

const CSVErrors: React.FC<{
  readonly errorsCsv: ErrorsCsv[];
  readonly dataTestId?: string;
}> = ({ errorsCsv, dataTestId }) => (
  <div className="manage-upload-file__csv-errors">
    <div className="manage-upload-file__csv-errors-header">
      <FormattedMessage id={I18nKey.MARKET_INTELLIGENCE_REPORTING_ERRORS} />
    </div>
    <div className="manage-upload-file__csv-errors-list">
      <ul>
        {errorsCsv.map(({ error_type, value, row_index }) => (
          <CSVError
            error_type={error_type}
            value={value}
            row_index={row_index}
            key={row_index}
            dataTestId={dataTestId}
          />
        ))}
      </ul>
    </div>
  </div>
);
CSVErrors.displayName = 'CSVErrors';

const CSVError: React.FC<ErrorsCsv> = ({
  error_type: csvErrorType,
  value,
  row_index: rowNumber,
  dataTestId,
}) => {
  switch (csvErrorType) {
    case MISSING_VALUE:
      return (
        <li className="manage-upload-file__csv-error">
          <FormattedMessage
            id={
              I18nKey.MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_MISSING_VALUE
            }
            values={{
              rowNumber,
              pill: (
                <Pill
                  text={value}
                  color={PillColor.Red}
                  dataTestId={`${dataTestId}_csvErrorsMissingValue`}
                />
              ),
            }}
          />
        </li>
      );
    case WRONG_VALUE:
      return (
        <li className="manage-upload-file__csv-error">
          <FormattedMessage
            id={
              I18nKey.MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_WRONG_VALUE
            }
            values={{
              rowNumber,
              pill: (
                <Pill
                  text={value}
                  color={PillColor.Red}
                  dataTestId={`${dataTestId}_csvErrorsWrongValue`}
                />
              ),
            }}
          />
        </li>
      );
  }
};
CSVError.displayName = 'CSVError';
