import { LevelsController } from '@teikametrics/tm-design-system';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { ViewTrendsSlideover } from '../../../../../components/ViewTrendsSlideover';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import { createSKUApiClient } from '../../../../../lib/clients/SKUApiClient';
import I18nKey from '../../../../../lib/types/I18nKey';
import { Performance } from './performance';
import { PerformanceSlideoverContext } from './performanceSlideoverContextProvider';
import { ProductDropdown } from './productDropdown';
import { ProductDropdownSkeleton } from './productDropdownSkeleton';

export interface PerformanceSlideoverProps {
  readonly onClose: () => void;
}
export const PerformanceSlideover: React.FC<PerformanceSlideoverProps> = ({
  onClose,
}) => {
  const {
    isProductDetailsLoading,
    selectedProductId,
    productDetails,
    setSelectedProductId,
  } = useContext(PerformanceSlideoverContext);
  const intl = useIntl();
  const userContext = useContext<UserContextState>(UserContext);
  const idToken = userContext.userInfo.idToken!;
  const [isProductExpanded, setIsProductExpanded] = useState(false);

  const onExpandToggle = () => {
    setIsProductExpanded(!isProductExpanded);
  };

  return (
    <ViewTrendsSlideover
      className="w-3/4 max-w-1080"
      overlayZindexClassname="z-20"
      isOpen={true}
      onClose={onClose}
      dataTestId="productsPerformance"
    >
      <div className="flex flex-col w-full gap-20 items-start justify-center">
        {isProductDetailsLoading && <ProductDropdownSkeleton />}
        {!isProductDetailsLoading && productDetails && (
          <ProductDropdown
            isProductExpanded={isProductExpanded}
            productDetails={productDetails}
            skuApiClient={createSKUApiClient(idToken)}
            onExpandToggle={onExpandToggle}
            selectedProductId={selectedProductId}
            onProductSelect={(productCatalogId) => {
              setSelectedProductId(productCatalogId);
              setIsProductExpanded(false);
            }}
            closeTrendsSlideover={onClose}
          />
        )}
        {!isProductExpanded && !!selectedProductId && (
          <div className="flex flex-col gap-20">
            <LevelsController
              levels={[
                {
                  name: intl.formatMessage({
                    id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_PERFORMANCE,
                  }),
                  value: 'performance',
                },
              ]}
              active={'performance'}
            />
            <Performance />
          </div>
        )}
      </div>
    </ViewTrendsSlideover>
  );
};
PerformanceSlideover.displayName = 'ProductsPerformanceSlideover';
