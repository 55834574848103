import {
  ColumnAlign,
  TableCellV2,
  TableCellV2Type,
} from '@teikametrics/tm-design-system';
import React from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { FlywheelTableV2ColumnProps } from '../../../containers/tableV2/types';
import {
  EstPreAdGrossMarginItem,
  EstPreAdGrossMarginTableFields,
} from '../../../lib/types/CampaignFlexibilitySharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../lib/types/I18nKey';
import { TableExtraProps } from '../EstAdGrossMarginTable';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { TableCell, WithTable } from '../../../containers/tableV2/ducks/types';
import { tableSelectors } from '../../../containers/tableV2/ducks';
import { EST_AD_GROSS_MARGIN_TABLE_ID } from '../../../core/campaignFlexibility/types';

export const Cell: React.FC<EstPreAdGrossMarginItem & TableExtraProps> = ({
  sku,
  preAdGrossMargin,
  avgSellingPrice,
  platformFees,
  unitCogs,
  bulkUpdateInProgress,
  bulkInputValue,
}) => {
  const intl = useIntl();
  const estPreAdGrossMargin = preAdGrossMargin;

  const updatedEstPreAdGrossMargin =
    avgSellingPrice && platformFees && bulkInputValue
      ? (avgSellingPrice - platformFees - Number(bulkInputValue)) /
        avgSellingPrice
      : undefined;

  const shouldShowTooltip =
    isNil(estPreAdGrossMargin) && !isNil(unitCogs) && unitCogs > 0;

  const updatedCogs = useSelector<
    WithTable<EstPreAdGrossMarginItem>,
    TableCell
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      sku,
      EstPreAdGrossMarginTableFields.unitCogs
    )(tableState, EST_AD_GROSS_MARGIN_TABLE_ID)
  );
  /**
   * This section is required coz of the TableV1 and V2 cell changes.
   * If this is from TableV1. Then we don't need `.value` hence this change.
   * Check tableV1.updatedCell and tableV2.updatedCell reducers for more info.
   */
  const updatedCogsValue =
    typeof updatedCogs !== 'object' ? updatedCogs : updatedCogs.value;
  const inLineUpdatedEstPreAdGrossMargin =
    avgSellingPrice && platformFees && updatedCogsValue && !bulkUpdateInProgress
      ? (avgSellingPrice - platformFees - Number(updatedCogsValue)) /
        avgSellingPrice
      : undefined;

  const getDisplayValue = () => {
    if (bulkUpdateInProgress) {
      if (!updatedEstPreAdGrossMargin && bulkInputValue) return null;
      return updatedEstPreAdGrossMargin ? (
        <FormattedNumber
          value={updatedEstPreAdGrossMargin}
          style="percent"
          minimumFractionDigits={PERCENTAGE_FRACTION_DIGITS}
          maximumFractionDigits={PERCENTAGE_FRACTION_DIGITS}
        />
      ) : (
        <Skeleton height={14} width={48} />
      );
    }

    if (shouldShowTooltip) return null;

    if (inLineUpdatedEstPreAdGrossMargin || estPreAdGrossMargin) {
      return (
        <FormattedNumber
          value={(inLineUpdatedEstPreAdGrossMargin || estPreAdGrossMargin)!}
          style="percent"
          minimumFractionDigits={PERCENTAGE_FRACTION_DIGITS}
          maximumFractionDigits={PERCENTAGE_FRACTION_DIGITS}
        />
      );
    }

    return intl.formatMessage({
      id: I18nKey.CAMPAIGN_CREATOR_SLIDEOVER_ENTER_COGS,
    });
  };

  return (
    <TableCellV2
      tableCellData={{
        tableCellType: TableCellV2Type.Number,
        currentValue: getDisplayValue(),
        showEmptyText: true,
        notApplicableProps: {
          tooltipText: shouldShowTooltip ? (
            <>
              {intl.formatMessage(
                {
                  id: I18nKey.CAMPAIGN_CREATOR_EST_PRE_AD_GROSS_MARGIN_TOOLTIP,
                },
                { br: <br /> }
              )}
            </>
          ) : undefined,
          tooltipClassnames:
            'text-center leading-tight whitespace-normal min-w-max',
        },
        dataTestId: 'estPreAdGrossMargin_estPreAdGrossMargin',
      }}
      className="h-full flex justify-center items-end whitespace-pre-wrap break-all"
    />
  );
};

Cell.displayName = 'estPreAdGrossMargin_estPreAdGrossMargin';

export const estPreAdGrossMarginColumn: FlywheelTableV2ColumnProps<
  EstPreAdGrossMarginItem,
  {},
  TableExtraProps,
  {}
> = {
  header:
    I18nKey.CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_TABLE_COLUMN_EST_PRE_AD_MARGIN,
  accessorKey: EstPreAdGrossMarginTableFields.estPreAdGrossMargin,
  size: 112,
  maxSize: 112,
  minSize: 112,
  sortable: false,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell {...original} {...meta} />,
};
