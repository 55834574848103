import { useContext, useEffect, useState } from 'react';

import { Modal } from '@teikametrics/tm-design-system';
import { SalesChannelContext } from '../../../../containers/salesChannelProvider';
import { AllSalesChannel } from '../../../../lib/types/Fam';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../lib/types/SalesChannels';
import { sortSalesChannels } from '../utils';
import { SalesChannelSyncStatus } from './components/SalesChannelSyncStatus';
import { SalesChannelSyncStatusFooter } from './components/SalesChannelSyncStatusFooter';
import { SalesChannelSyncStatusHeader } from './components/SalesChannelSyncStatusHeader';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../lib/utilities/intercom';

export interface SalesChannelSyncStatusSlideOutProps {
  readonly showSalesChannelSyncStatusSlideOut: boolean;
  readonly closeSalesChannelSyncStatusSlideOut: () => void;
  readonly showDataTimingAndSyncSlideout: () => void;
}

export const SalesChannelSyncStatusSlideOut: React.FC<
  SalesChannelSyncStatusSlideOutProps
> = ({
  showSalesChannelSyncStatusSlideOut,
  closeSalesChannelSyncStatusSlideOut,
  showDataTimingAndSyncSlideout,
}) => {
  const { salesChannels } = useContext(SalesChannelContext);
  const [sortedSalesChannels, setSortedSalesChannels] = useState<
    AllSalesChannel[]
  >([]);

  useEffect(() => {
    setSortedSalesChannels(
      sortSalesChannels(salesChannels, [
        AMAZON_SALES_CHANNEL_ID,
        WALMART_SALES_CHANNEL_ID,
      ])
    );
  }, [salesChannels]);

  useEffect(() => {
    if (showSalesChannelSyncStatusSlideOut) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [showSalesChannelSyncStatusSlideOut]);

  return (
    <Modal
      className="flex flex-col h-screen min-w-500"
      showModal={showSalesChannelSyncStatusSlideOut}
      slideOutModal={true}
      hidePadding={true}
      secondaryAction={{
        label: '',
        onClick: closeSalesChannelSyncStatusSlideOut,
      }}
      dataTestId="sales_channel_sync_status_slide_out"
    >
      <div className="flex flex-col h-screen overflow-hidden">
        <header className="w-full border-b-1 border-solid border-grey-200">
          <SalesChannelSyncStatusHeader
            closeSalesChannelSyncStatusSlideOut={
              closeSalesChannelSyncStatusSlideOut
            }
          ></SalesChannelSyncStatusHeader>
        </header>
        <main className="flex-1 flex-start gap-32 overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-grey-400 scrollbar-track-grey-100 scrollbar-thumb-rounded-full">
          {sortedSalesChannels.map((salesChannel) => (
            <SalesChannelSyncStatus
              salesChannel={salesChannel}
              showDataTimingAndSyncSlideout={showDataTimingAndSyncSlideout}
            />
          ))}
        </main>
        <footer className="border-t-1 border-solid w-full border-grey-200">
          <SalesChannelSyncStatusFooter
            closeSalesChannelSyncStatusSlideOut={
              closeSalesChannelSyncStatusSlideOut
            }
          ></SalesChannelSyncStatusFooter>
        </footer>
      </div>
    </Modal>
  );
};

SalesChannelSyncStatusSlideOut.displayName = 'SalesChannelSyncStatusSlideOut';
