import classNames from 'classnames';
import { fromNullable } from 'fp-ts/lib/Option';
import React from 'react';

import { ColumnDataForRow, GRID_TABLE_CLASSES } from './types';

interface RowProps<RowDataType, TableDataType> {
  readonly columns: Array<ColumnDataForRow<RowDataType, TableDataType>>;
  readonly rowData?: RowDataType;
  readonly tableData: TableDataType | undefined;
  readonly cellContainerClassName: string;
  readonly cellStyles?: React.CSSProperties;
  readonly columnsWithGroupingBorder?: Set<number>;
}

function Row<RowDataType, TableDataType>(
  props: RowProps<RowDataType, TableDataType>
) {
  return (
    <div className="grid-table__row">
      {props.columns.map((column, colIdx) => {
        const isLeftMostCell = colIdx === 0;
        const isRightMostCell = colIdx === props.columns.length - 1;
        const rowCellData = {
          ...props.rowData,
          ...props.tableData,
          displayName: 'grid-table__cell',
        } as RowDataType & TableDataType & { displayName: string };
        const maybeOnCellClick = fromNullable(column.onCellClick)
          .map((onCellClick) => {
            return () => {
              onCellClick(rowCellData as RowDataType);
            };
          })
          .toUndefined();

        return (
          <div
            key={colIdx}
            style={props.cellStyles}
            className={classNames(
              props.cellContainerClassName,
              column.columnClassName,
              'grid-table__cell-container',
              {
                'grid-table__cell-with-grouping-border':
                  props.columnsWithGroupingBorder?.has(colIdx),
                [GRID_TABLE_CLASSES.LEFT_MOST_COLUMN]: isLeftMostCell,
                [GRID_TABLE_CLASSES.RIGHT_MOST_COLUMN]: isRightMostCell,
                'grid-table__cell-container--empty':
                  column.columnComponent === undefined,
              }
            )}
            onClick={maybeOnCellClick}
          >
            <div className={classNames('grid-table__cell')}>
              {column.columnComponent && (
                <column.columnComponent {...rowCellData} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
Row.displayName = 'Row';

export default Row;
