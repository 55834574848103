import { useContext, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import first from 'lodash/first';
import { FlywheelTableColumn } from '../../../../../containers/table/flywheelTable';
import {
  makeTruncatedTextLinkColumn,
  representSmartCampaign,
} from '../../../../../containers/table/utils/makeTableCells';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../../containers/userProvider/userProvider';
import {
  AdvertisingGoal,
  AiBiddingValues,
  CampaignTargetingType,
  ProductAdDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ViewTrendLink } from '../../../components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../components/ViewTrends';
import { getAdManagerUrl, getAdsLevelUri } from '../../../utils';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
  TableDataAdsManager,
} from '../types';
import {
  PRODUCT_ADS_API_COLUMN_NAME,
  getSelectedMerchantCountryId,
} from '../utils';
import { getAdsManagerDetailsQueryParams } from '../utils/queryParamsUtils';
import { getSalesChannelFromLocalStorage } from '../utils/storageUtils';
import { getAdType, getMerchantCountries, getSalesChannel } from './utils';
import { transformAdvertisingGoal } from '../adgroupTableColumns/utils';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = ({
  adItemId,
  adItemDetails,
  adItemPerformance,
  channelSettings,
  adTypes,
  pendingFields,
  selectedMerchantCountries,
  merchantType,
  allMerchants,
  showSmartCampaign,
  selectedAdType,
  aiEnabled,
  merchantsWithAutomationEnabled,
}) => {
  const data: ProductAdDetails = {
    adItemId,
    adItemDetails,
    adItemPerformance,
    channelSettings,
  };

  const location = useLocation();
  const baseUrl = useMemo(
    () => getAdManagerUrl(location.pathname),
    [location.pathname]
  );

  const searchQueryParams = new URLSearchParams(
    decodeURIComponent(location.search)
  );

  const userContext = useContext(UserContext);

  const userId = userContext.userInfo.userDetails?.id || '';
  const accountId = getCurrentAccountFromContext(userContext)?.id || '';

  const salesChannelId = searchQueryParams.get(SALES_CHANNEL_ID);
  const merchantCountryIds = searchQueryParams.get(MERCHANT_COUNTRY_IDS);
  const adTypesFromQueryParams = searchQueryParams.get(AD_TYPE);

  const selectedSalesChannel = getSalesChannelFromLocalStorage(
    userId || '',
    accountId || ''
  );
  const selectedMerchantCountryId = getSelectedMerchantCountryId(
    selectedMerchantCountries
  );

  const aiBidding: AiBiddingValues =
    aiEnabled &&
    merchantsWithAutomationEnabled &&
    merchantsWithAutomationEnabled?.length > 0
      ? AiBiddingValues.Ai
      : AiBiddingValues.NotEnabled;

  const goal: AdvertisingGoal = transformAdvertisingGoal(
    aiBidding,
    data.adItemDetails.advertisingGoal
  );

  return makeTruncatedTextLinkColumn(
    ({ adItemDetails }: ProductAdDetails) => adItemDetails?.campaignName ?? '',
    ({ adItemDetails }: ProductAdDetails) =>
      adItemDetails?.campaignId !== undefined
        ? getAdsLevelUri(
            baseUrl,
            ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns,
            adItemDetails?.campaignId
          ) +
          getAdsManagerDetailsQueryParams(
            getSalesChannel(salesChannelId, selectedSalesChannel),
            getMerchantCountries(
              merchantCountryIds,
              userId,
              accountId,
              salesChannelId,
              selectedSalesChannel
            ),
            getAdType(adTypesFromQueryParams, adTypes)
          )
        : '',
    pendingFields?.includes(PRODUCT_ADS_API_COLUMN_NAME.CampaignName),
    <ViewTrendLink
      dataTestId={'product_ads_campaign_name'}
      entityId={data.adItemDetails.campaignId}
      entityType={ViewTrendsEntityType.CAMPAIGN}
      adType={first(adTypes)}
      merchantCountryId={selectedMerchantCountryId}
      campaignId={data.adItemDetails.campaignId}
      merchantType={merchantType}
      campaignName={data.adItemDetails.campaignName}
      entityName={data.adItemDetails.campaignName}
      merchantCountryName={
        allMerchants?.find(
          ({ merchantCountryId }) =>
            merchantCountryId === selectedMerchantCountryId
        )?.merchantName
      }
      salesChannelId={salesChannelId as string}
      targetingType={data.adItemDetails.campaignTargetingType}
    />,
    false,
    representSmartCampaign(
      goal,
      data.adItemDetails.campaignTargetingType || CampaignTargetingType.Manual,
      selectedAdType,
      showSmartCampaign
    )
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignNameColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  isSortable: true,
  columnName: PRODUCT_ADS_API_COLUMN_NAME.CampaignName,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_NAME,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const campaignNameFilter = createStringDataFieldFilter(
  PRODUCT_ADS_API_COLUMN_NAME.CampaignName,
  I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_NAME,
  isValidString()
);
