import {
  Alignment,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CheckmarkIcon,
  ColumnAlign,
  CrossExtraSmallIcon,
  Placement,
  Spinner,
  Tooltip,
  TooltipSize,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  ActionType,
  KeywordActionColumns,
  KeywordRecommendationsData,
} from '../../../../../lib/types/KeywordAction';
import { RecommendationsTableV2ExtraProps } from '../types';

export const Cell: React.FC<{
  original: KeywordRecommendationsData;
  meta: RecommendationsTableV2ExtraProps;
}> = ({ original, meta: { actionRowDataProps, onActionRowClick } }) => {
  const isDismissedRow = actionRowDataProps?.dismissProcessingRows.includes(
    original.id
  );
  const isAcceptedRow = actionRowDataProps?.acceptProcessingRows.includes(
    original.id
  );
  const spinner = (
    <Spinner
      className="mt-2 w-16"
      barColor="bg-purple-500"
      centerAlign={false}
    />
  );
  return (
    <div className="flex h-full items-center justify-center space-x-8">
      <Tooltip
        content={
          <FormattedMessage
            id={I18nKey.KEYWORD_RECOMMENDATION_DISMISS_KEYWORDS}
          />
        }
        position={{
          placement: Placement.Left,
          alignment: Alignment.Center,
        }}
        delayShow={750}
      >
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.BlackAndWhiteBorder}
          state={isAcceptedRow ? ButtonState.Disabled : ButtonState.Enabled}
          label={isDismissedRow && spinner}
          svgIcon={isDismissedRow ? undefined : CrossExtraSmallIcon}
          iconClassName="text-orange-500"
          onClick={() => {
            onActionRowClick &&
              onActionRowClick([original.id], ActionType.Rejected, original);
          }}
          className={classNames(
            'border border-grey-300 border-solid rounded-4',
            {
              'w-32 px-0 inline-flex items-center justify-center':
                isDismissedRow,
            }
          )}
        />
      </Tooltip>
      <Tooltip
        tooltipSize={TooltipSize.Default}
        content={
          <FormattedMessage id={I18nKey.KEYWORD_RECOMMENDATIONS_ADD_KEYWORDS} />
        }
        position={{
          placement: Placement.Left,
          alignment: Alignment.Center,
        }}
        delayShow={750}
      >
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.BlackAndWhiteBorder}
          state={isDismissedRow ? ButtonState.Disabled : ButtonState.Enabled}
          label={isAcceptedRow && spinner}
          svgIcon={isAcceptedRow ? undefined : CheckmarkIcon}
          iconClassName="text-green-500 p-2"
          onClick={() => {
            onActionRowClick &&
              onActionRowClick([original.id], ActionType.Accepted, original);
          }}
          className={classNames({
            'w-32 px-0 inline-flex items-center justify-center': isAcceptedRow,
          })}
        />
      </Tooltip>
    </div>
  );
};
Cell.displayName = 'KWACellActions';

export const actions: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  RecommendationsTableV2ExtraProps,
  {}
> = {
  header: '',
  accessorKey: KeywordActionColumns.ACTIONS,
  sortable: true,
  size: 100,
  minSize: 100,
  maxSize: 100,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell original={original} meta={meta} />,
};
