import {
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makePercentColumn } from '../../../../../containers/table/utils/makeTableCells';
import { SbAdsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { SB_ADS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<SbAdsDetails & TableDataAdsManager> = (
  props
) =>
  makePercentColumn<SbAdsDetails>(({ creativePerformance: { acosTotal } }) => {
    const performanceMetricValue = getPerformanceMetricValue(
      props.selectedEndDate,
      undefined,
      acosTotal
    );

    return performanceMetricValue?.toString();
  })(props);
RowCellElement.displayName = 'RowCellElement';

export const acosColumn: FlywheelTableColumn<
  SbAdsDetails,
  TableDataAdsManager
> = {
  columnName: SB_ADS_API_COLUMN_NAME.ACOS,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_TOTAL_ACOS,
  RowCellElement,
  gridColumnWidth: '100px',
  className: 'text-right',
};

export const acosFilter = createPercentDataFieldFilter(
  SB_ADS_API_COLUMN_NAME.ACOS,
  I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_TOTAL_ACOS,
  isValidNumber()
);
