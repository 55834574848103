import React from 'react';
import {
  MultiSelectOption,
  SingleSelectOption,
} from '../../components/Filter/types';
import { AdType, MatchType } from './AOSharedTypes';
import { MerchantCountry } from './Fam';
import I18nKey from './I18nKey';
import { MoneyWithAmountInString } from './Money';

export const PRODUCT_GROUP_ID_QUERY_PARAM_KEY = 'product_group_id';
export const DAYS_NEG = 60;

interface Keyword {
  text: string;
  suggestedMatchType: string;
  tag: string;
  sources?: string[];
}

export enum TargetingType {
  Auto = 'auto',
  Manual = 'manual',
}

export enum TargetSegment {
  Brand = 'Brand',
  Competitor = 'Competitor',
  Generic = 'Generic',
  Unknown = 'Unknown',
}

export interface CampaignInfo {
  id: string;
  name: string;
  campaignType: string;
  targetingType: TargetingType;
  createdBy?: string;
}

export enum CampaignType {
  SponsoredProducts = 'sponsoredProducts',
  SponsoredBrands = 'sponsoredBrands',
  SponsoredBrandsVideo = 'sponsoredBrandsVideo',
  SponsoredDisplay = 'sponsoredDisplay',
}

export interface AdGroupInfo {
  id: string;
  name: string;
  campaign: CampaignInfo;
  targetSegment: TargetSegment;
  targetSegments?: TargetSegment[];
}

interface KeywordProduct {
  id: string;
  name: string;
  sku: string;
  // extId: string;
  productImage: string;
  salesChannelId: string;
  extItemId: string;
  productGroupId?: string; // TODO: confirm key once api response contains productGroupId
}

export interface Performance {
  rank: number;
  adConversions?: number;
  adSalesTotal?: MoneyWithAmountInString;
  adSpend?: MoneyWithAmountInString;
  conversionRate?: number;
  roasTotal?: number;
  acosTotal?: number;
  clicks?: number;
  impressions?: number;
  clickThroughRate?: number;
  avgCostPerClick?: MoneyWithAmountInString;
}

export type TargetRecommendations = {
  id: string;
  keyword: Keyword;
  suggestedDestination?: { adGroup: AdGroupInfo };
  product: KeywordProduct;
  validDestinations: AdGroupInfo[];
  performance: Performance;
};

export enum RecommendationType {
  KEYWORD = 'keyword',
  NEGATIVE_KEYWORD = 'negativekeyword',
  PAT = 'productAttributeTargeting',
}

interface RecommendationCountDataProps {
  readonly recommendationType: RecommendationType;
  readonly count: number;
  readonly lastUpdatedAt: string;
  readonly userLastUpdatedAt: string;
  readonly smartCampaignCount: number;
  readonly nonSmartCampaignCount: number;
  readonly type?: string;
  readonly byPerformanceCount?: number;
  readonly byChannelCount?: number;
}

export interface TargetRecommendationCountResponse {
  readonly merchantCountryId: string;
  readonly data: RecommendationCountDataProps[];
}

export enum KeywordType {
  Smart = 'Smart Campaigns',
  Basic = 'Standard/Basic Campaigns',
}

export interface RecommendationCount {
  readonly recommendationType: RecommendationType;
  readonly count: number;
  readonly lastUpdatedAt: string;
  readonly userLastUpdatedAt: string;
  readonly type: KeywordType;
  readonly byPerformanceCount: number;
  readonly byChannelCount: number;
  readonly advertisingType: AdType;
}

export interface TargetRecommendationCountResponseV2 {
  readonly merchantCountryId: string;
  readonly data: RecommendationCount[];
}

export interface TargetRecommendationsResponse {
  readonly filteredElements: number;
  readonly elements: TargetRecommendations[];
}

export type RecommendationsTableData = {
  merchantCountryId: string;
  selectedRows: SelectedRecommendations;
  setAdGroupsForSelectedCampaigns: React.Dispatch<
    React.SetStateAction<CampaignAdGroups>
  >;
  adGroupsForSelectedCampaigns: CampaignAdGroups;
  selectedMerchantCountry: MerchantCountry;
  handleDropdownSelection: (
    row: TargetRecommendations,
    option: DropDownType,
    value?: MatchType | CampaignInfo | AdGroupInfo
  ) => void;
};

export interface RecommendationsAction {
  recommendationId: string;
  adGroupId: string;
  campaignId: string;
  matchType: string;
  action: string;
  merchantCountryId: string;
  salesChannelId: string;
  tag: string;
  readonly merchantType: string;
}

export enum KeywordActionColumns {
  PRODUCT_ASIN = 'productAsin',
  KEYWORD = 'targetText',
  NEGATIVE_KEYWORD = 'targetText',
  MATCH_TYPE = 'matchType',
  KEYWORD_TAG = 'targetTag',
  PRODUCT = 'sku',
  CAMPAIGN_ADGROUP_NAME = 'campaignAdGroupName',
  CAMPAIGN_NAME = 'suggestedDestination.adGroup.campaign.name',
  CAMPAIGN_TYPE = 'campaignType',
  CAMPAIGN_TARGETING_TYPE = 'campaignTargetingType',
  AD_GROUP_NAME = 'suggestedDestination.adGroup.name',
  SOURCE = 'sources',
  AD_SALES = 'adSalesTotal',
  AD_SPEND = 'adSpend',
  CTR = 'clickThroughRate',
  CPC = 'avgCostPerClick',
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  ROAS = 'roasTotal',
  ACOS = 'acosTotal',
  CONVERSION_RATE = 'conversionRate',
  AD_CONVERSIONS = 'adConversions',
  PRODUCT_NAME = 'productName',
  EXTERNAL_ITEM_ID = 'extItemId',
  REASON = 'reason',
  ACTIONS = 'actions',
}

export enum RecommendationSource {
  AO = 'AO',
  AMZ_SP_SEARCH_TERM_REPORT = 'AMZ_SP_SEARCH_TERM_REPORT',
  AMZ_SP_TARGET_SEARCH_TERM_REPORT = 'AMZ_SP_TARGET_SEARCH_TERM_REPORT',
  AMZ_SP_SUGGESTED_KEYWORD = 'AMZ_SP_SUGGESTED_KEYWORD',
  WMT_SP_ITEM_KEYWORD_REPORT = 'WMT_SP_ITEM_KEYWORD_REPORT',
  WMT_SP_KEYWORD_ANALYTIC = 'WMT_SP_KEYWORD_ANALYTIC',
}

export interface CampaignAdGroups {
  [key: string]: AdGroupInfo[];
}

export enum ActionType {
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface SelectedRecommendations {
  [id: string]: {
    recommendationId: string;
    isChecked: boolean;
    matchType: MatchType;
    tag: string;
    adGroup?: AdGroupInfo;
    campaign?: CampaignInfo;
  };
}

export enum DropDownType {
  MATCH_TYPE = 'matchType',
  AD_GROUP = 'adGroup',
  CAMPAIGN = 'campaign',
}

enum SelectFilterSourceOptionType {
  AO = 'AO',
  AmazonSearchTerm = 'AmazonSearchTerm',
  AmazonTargetSearchTerm = 'AmazonTargetSearchTerm',
  AmazonSuggestedKeyword = 'AmazonSuggestedKeyword',
  WalmartItemKeyword = 'WalmartItemKeyword',
  WalmartKeywordAnalytic = 'WalmartKeywordAnalytic',
}

export const SelectSourceOption: Record<
  SelectFilterSourceOptionType,
  SingleSelectOption | MultiSelectOption
> = {
  [SelectFilterSourceOptionType.AO]: {
    value: RecommendationSource.AO,
    displayI18nKey: I18nKey.KEYWORD_RECOMMENDATIONS_SOURCE_AO,
  },
  [SelectFilterSourceOptionType.AmazonSearchTerm]: {
    value: RecommendationSource.AMZ_SP_SEARCH_TERM_REPORT,
    displayI18nKey:
      I18nKey.KEYWORD_RECOMMENDATIONS_SOURCE_AMAZON_SEARCH_TERM_REPORT,
  },
  [SelectFilterSourceOptionType.AmazonTargetSearchTerm]: {
    value: RecommendationSource.AMZ_SP_TARGET_SEARCH_TERM_REPORT,
    displayI18nKey:
      I18nKey.KEYWORD_RECOMMENDATIONS_SOURCE_AMAZON_SEARCH_TERM_REPORTS,
  },
  [SelectFilterSourceOptionType.AmazonSuggestedKeyword]: {
    value: RecommendationSource.AMZ_SP_SUGGESTED_KEYWORD,
    displayI18nKey:
      I18nKey.KEYWORD_RECOMMENDATIONS_SOURCE_AMAZON_SUGGESTED_KEYWORD,
  },
  [SelectFilterSourceOptionType.WalmartItemKeyword]: {
    value: RecommendationSource.WMT_SP_ITEM_KEYWORD_REPORT,
    displayI18nKey:
      I18nKey.KEYWORD_RECOMMENDATIONS_SOURCE_WALMART_ITEM_KEYWORD_REPORT,
  },
  [SelectFilterSourceOptionType.WalmartKeywordAnalytic]: {
    value: RecommendationSource.WMT_SP_KEYWORD_ANALYTIC,
    displayI18nKey:
      I18nKey.KEYWORD_RECOMMENDATIONS_SOURCE_WALMART_KEYWORD_ANALYTIC_REPORT,
  },
};

enum SelectFilterKeywordTagOptionType {
  Brand = 'Brand',
  Competitor = 'Competitor',
  Generic = 'Generic',
  Unknown = 'Unknown',
}

export const SelectKeywordTagOption: Record<
  SelectFilterKeywordTagOptionType,
  SingleSelectOption | MultiSelectOption
> = {
  [SelectFilterKeywordTagOptionType.Brand]: {
    value: SelectFilterKeywordTagOptionType.Brand,
    displayI18nKey: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_BRAND,
  },
  [SelectFilterKeywordTagOptionType.Competitor]: {
    value: SelectFilterKeywordTagOptionType.Competitor,
    displayI18nKey: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_COMPETITOR,
  },
  [SelectFilterKeywordTagOptionType.Generic]: {
    value: SelectFilterKeywordTagOptionType.Generic,
    displayI18nKey: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_GENERIC,
  },
  [SelectFilterKeywordTagOptionType.Unknown]: {
    value: SelectFilterKeywordTagOptionType.Unknown,
    displayI18nKey: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_UNKNOWN,
  },
};

export enum KeywordActionSubmissionResponseStatus {
  Success = 'success',
  Error = 'error',
}
export interface KeywordActionSubmissionResponse {
  readonly status: KeywordActionSubmissionResponseStatus;
}
export interface SelectedRecommendationsV2Edits {
  recommendationId: string;
  isChecked: boolean;
  matchType: MatchType;
  tag: string;
  adGroup?: KeywordAdgroupInfo;
  campaign?: CampaignInfo;
}

export interface SelectedRecommendationsV2 {
  [id: string]: SelectedRecommendationsV2Edits;
}

export type KeywordRecommendationsData = {
  readonly id: string;
  readonly keyword: KeywordProps;
  readonly suggestedDestination: { adGroup: KeywordAdgroupInfo };
  readonly performance: Performance;
};

export interface KeywordRecommendationsResponse {
  readonly filteredElements: number;
  readonly elements: KeywordRecommendationsData[];
}

export interface KeywordProps {
  readonly tag?: string;
  readonly text?: string;
  readonly targetingExpression?: string;
  readonly suggestedMatchType?: string;
  readonly sources?: (Sources | string)[];
  readonly sourcesV2?: SourceV2[];
}

export interface Sources {
  readonly reportName?: string;
  readonly keyword?: SourceKeyword;
  readonly adGroup?: KeywordAdgroupInfo;
  readonly campaign?: CampaignInfo;
}

export interface SourceV2 {
  readonly source: string;
  readonly keyword: SourceKeyword;
  readonly campaign: CampaignInfo;
  readonly adGroup: AdGroupInfo;
}

export interface SourceKeyword {
  readonly text?: string;
  readonly matchType?: string;
}

export interface KeywordAdgroupInfo {
  readonly id: string;
  readonly name?: string;
  readonly targetSegments?: string[];
  readonly campaign?: CampaignInfo;
  readonly totalSkus?: number;
  readonly totalTargets?: number;
}

export enum RecommendationSourceV2 {
  AO = 'AO',
  AMZ_SP_SEARCH_TERM_REPORT = 'AMZ_SP_SEARCH_TERM_REPORT',
  AMZ_SP_TARGET_SEARCH_TERM_REPORT = 'AMZ_SP_TARGET_SEARCH_TERM_REPORT',
  AMZ_SP_SUGGESTED_KEYWORD = 'AMZ_SP_SUGGESTED_KEYWORD',
  WMT_SP_ITEM_KEYWORD_REPORT = 'WMT_SP_ITEM_KEYWORD_REPORT',
  WMT_SP_KEYWORD_ANALYTIC = 'WMT_SP_KEYWORD_ANALYTIC',
  WMT_SP_KEYWORD_PERFORMANCE_REPORT = 'WMT_SP_KEYWORD_PERFORMANCE_REPORT',
}

export enum TargetSegments {
  Auto = 'Auto',
  Brand = 'Brand',
  Competitor = 'Competitor',
  Generic = 'Generic',
  Unknown = 'Unknown',
  Category = 'Category',
}

export interface AdGroupInfoV2 {
  readonly id: string;
  readonly name: string;
  readonly campaign: CampaignInfo;
  readonly targetSegments: TargetSegments[];
  readonly totalSkus?: number;
  readonly totalTargets?: number;
  readonly advertisingGoal?: string;
}

export interface CampaignInfoWithAdGroups extends CampaignInfo {
  readonly adGroups: AdGroupInfoV2[];
  readonly selectedAdGroupIds?: string[];
  readonly advertisingGoal?: string;
}

export enum KWAInternalPaths {
  Dashboard = '/',
  Settings = 'settings',
  Tabs = ':mcId/*',
}

export const AUTO_NEGATE_HELP_CENTER_LINK =
  'https://intercom.help/flywheel-20/en/articles/8469880-how-to-use-auto-negate-when-accepting-keywords';
