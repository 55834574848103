import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import first from 'lodash/first';
import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTruncatedTextLinkColumn } from '../../../../../containers/table/utils/makeTableCells';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../../containers/userProvider/userProvider';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../../lib/types/SalesChannels';
import { ViewTrendLink } from '../../../components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../components/ViewTrends';
import { getAdManagerUrl, getAdsLevelUri } from '../../../utils';
import {
  getAdType,
  getMerchantCountries,
  getSalesChannel,
} from '../adItemsTableColumns/utils';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
  TableDataAdsManager,
} from '../types';
import {
  PRODUCT_ADS_API_COLUMN_NAME,
  getSelectedMerchantCountryId,
} from '../utils';
import { getAdsManagerDetailsQueryParams } from '../utils/queryParamsUtils';
import { getSalesChannelFromLocalStorage } from '../utils/storageUtils';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = ({
  adItemId,
  adItemDetails,
  adItemPerformance,
  channelSettings,
  adTypes,
  pendingFields,
  selectedMerchantCountries,
  merchantType,
  salesChannel,
  allMerchants,
}) => {
  const data: ProductAdDetails = {
    adItemId,
    adItemDetails,
    adItemPerformance,
    channelSettings,
  };

  const location = useLocation();
  const baseUrl = useMemo(
    () => getAdManagerUrl(location.pathname),
    [location.pathname]
  );

  const searchQueryParams = new URLSearchParams(
    decodeURIComponent(location.search)
  );

  const userContext = useContext(UserContext);

  const userId = userContext.userInfo.userDetails?.id || '';
  const accountId = getCurrentAccountFromContext(userContext)?.id || '';

  const salesChannelId = searchQueryParams.get(SALES_CHANNEL_ID);
  const merchantCountryIds = searchQueryParams.get(MERCHANT_COUNTRY_IDS);
  const adTypesFromQueryParams = searchQueryParams.get(AD_TYPE);

  const selectedSalesChannel = getSalesChannelFromLocalStorage(
    userId || '',
    accountId || ''
  );
  const selectedMerchantCountryId = getSelectedMerchantCountryId(
    selectedMerchantCountries
  );

  return makeTruncatedTextLinkColumn(
    ({ adItemDetails }: ProductAdDetails) => adItemDetails?.adGroupName ?? '',
    ({ adItemDetails }: ProductAdDetails) =>
      adItemDetails?.adGroupId !== undefined
        ? getAdsLevelUri(
            baseUrl,
            ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups,
            adItemDetails?.adGroupId
          ) +
          getAdsManagerDetailsQueryParams(
            getSalesChannel(salesChannelId, selectedSalesChannel),
            getMerchantCountries(
              merchantCountryIds,
              userId,
              accountId,
              salesChannelId,
              selectedSalesChannel
            ),
            getAdType(adTypesFromQueryParams, adTypes),
            adItemDetails.campaignTargetingType,
            adItemDetails.adGroupName
          )
        : '',
    pendingFields?.includes(PRODUCT_ADS_API_COLUMN_NAME.AdGroupName),
    <ViewTrendLink
      dataTestId={'product_ads_ad_group_name'}
      campaignId={data.adItemDetails.campaignId}
      merchantCountryId={selectedMerchantCountryId}
      entityId={data.adItemDetails.adGroupId}
      entityType={ViewTrendsEntityType.ADGROUP}
      adType={first(adTypes)}
      merchantType={merchantType}
      campaignName={data.adItemDetails.campaignName}
      entityName={data.adItemDetails.adGroupName}
      merchantCountryName={
        allMerchants.find(
          (merchant) => merchant.merchantCountryId === selectedMerchantCountryId
        )?.merchantName
      }
      salesChannelId={
        salesChannel === FlywheelSalesChannel.Amazon
          ? AMAZON_SALES_CHANNEL_ID
          : WALMART_SALES_CHANNEL_ID
      }
      targetingType={data.adItemDetails.campaignTargetingType}
    />
  )(data);
};

RowCellElement.displayName = 'AdGroupNameColumn';

export const adGroupName: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: SV_ADS_API_COLUMN_NAME.AdGroupName,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_AD_GROUP_NAME,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const adGroupNameFilter = createStringDataFieldFilter(
  SV_ADS_API_COLUMN_NAME.AdGroupName,
  I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_AD_GROUP_NAME,
  isValidString()
);
