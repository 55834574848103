import remove from 'lodash/remove';
import React, { useContext, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Icon,
  IconSize,
  Spinner,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { FAMApiClient } from '../../../../lib/clients/FAMApiClient';
import I18nKey from '../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../ConnectModalWrapper';
import { Region, RowDataProps } from '../types';
import { AMAZON_FLASH_MODAL_SCREENS } from './amazonFlashScreens';
import MerchantsChannelsTable from './merchantsChannels/merchantsChannelsTable';
import { NeedHelp } from '../../../../components/NeedHelp';
import { INTERCOM_AMAZON_ONBOARDING_HELP } from '../constants';
import {
  FAMConnectionStatus,
  FlywheelSalesChannel,
  MerchantCorrelationResponseType,
  MerchantCountries,
} from '../../../../lib/types/Fam';
import { getCurrentAccountPermissions } from '../../../../containers/userProvider/selectors';
import { useInterval } from '../../../../lib/utilities/useIntervalHook';

interface ConnectionInitialModalProps {
  readonly goBack: () => void;
  readonly next?: () => void;
  readonly unavailableConnectedCount?: boolean;
  readonly headerText?: string;
  readonly subHeaderText?: string;
  readonly alreadyConnectedCount?: boolean;
  readonly secondaryButtonText?: string;
  readonly hideIconInSecondaryButton?: boolean;
  readonly primaryButtonText?: string;
  readonly showWarning?: boolean;
  readonly channel: FlywheelSalesChannel;
  readonly famClient: FAMApiClient;
  readonly lwaCorrelationId: string;
  readonly rowIdsSelected?: string[];
  readonly setCurrentModal: (countries: any) => void;
  readonly saveLWACorrelationId: (correlationId: string) => void;
  readonly setConnectedMerchants: (merchants: RowDataProps[]) => void;
  readonly setMerchantCountries: (countries: MerchantCountries[]) => void;
  readonly setConnectedProducts: (
    merchants: MerchantCountries[] | RowDataProps[]
  ) => void;
  readonly setOwnedMerchantAccountMerchants: (countries: any) => void;
  readonly setUnavailableMerchantAccountMerchants: (countries: any) => void;
  readonly setNotOwnedMerchantAccountMerchants: (countries: any) => void;
  readonly selectedMerchantRegion: Region;
  readonly closeModal?: () => void;
  readonly setLoadingMerchantData: (value: boolean) => void;
  readonly loadingMerchantData: boolean;
  readonly showConnectedMerchantsModal?: boolean;
}
const RETRY_MERCHANTS_ENDPOINT_COUNT = 1000;

const finishEnableSalesChannel = ({
  selectedMerchantRegion,
  filteredRows,
  setConnectedProducts,
  nextScreen,
}: {
  selectedMerchantRegion: Region;
  filteredRows: RowDataProps[];
  setConnectedProducts: (
    merchants: RowDataProps[] | MerchantCountries[]
  ) => void;
  nextScreen?: () => void;
}) => {
  // handles getting selected merchant for Amazon Ads Account only
  if (selectedMerchantRegion.name) {
    setConnectedProducts(filteredRows);
  }
  nextScreen && nextScreen();
};

export const ConnectedMerchantsModal: React.FC<ConnectionInitialModalProps> = ({
  goBack,
  next,
  headerText,
  subHeaderText,
  secondaryButtonText,
  showWarning = false,
  hideIconInSecondaryButton,
  primaryButtonText,
  channel,
  famClient,
  lwaCorrelationId,
  setCurrentModal,
  setMerchantCountries,
  setOwnedMerchantAccountMerchants,
  setUnavailableMerchantAccountMerchants,
  setNotOwnedMerchantAccountMerchants,
  setConnectedProducts,
  setConnectedMerchants,
  selectedMerchantRegion,
  closeModal,
  loadingMerchantData,
  setLoadingMerchantData,
  showConnectedMerchantsModal,
}) => {
  const intl = useIntl();
  const userContext = useContext<UserContextState>(UserContext);
  const { userInfo } = userContext;
  const accountInfo = getCurrentAccountPermissions(userInfo.userDetails)!;
  const [retryCount, setRetryCount] = React.useState<number>(0);
  const [merchantData, setMerchants] =
    useState<MerchantCorrelationResponseType>();
  const [ownedMerchantAccounts, setOwnedMerchantAccount] = useState<
    RowDataProps[]
  >([]);
  const [unavailableMerchantAccounts, setUnavailableMerchantAccount] =
    useState<MerchantCountries[]>();
  const [notOwnedMerchantAccount, setNotOwnedMerchantAccount] = useState<
    RowDataProps[]
  >([]);
  const [rowIdsSelected, setRowIdsSelected] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleMerchantData = ({
    res,
    setLoadingMerchantData,
    setMerchants,
    setMerchantCountries,
    goBack,
  }: {
    res: MerchantCorrelationResponseType;
    setLoadingMerchantData: (value: boolean) => void;
    setMerchants: React.Dispatch<
      React.SetStateAction<MerchantCorrelationResponseType | undefined>
    >;
    setMerchantCountries: (countries: MerchantCountries[]) => void;
    goBack: () => void;
  }) => {
    if (res.status === FAMConnectionStatus.Active) {
      setLoadingMerchantData(false);
      setMerchants(res);
      setMerchantCountries(res.merchantCountries);
    } else if (res.status === FAMConnectionStatus.Failed) {
      setLoadingMerchantData(false);
      goBack();
    }
  };

  useInterval(
    () => {
      famClient
        .merchantConnect(accountInfo.account.id, lwaCorrelationId)
        .then((res) => {
          handleMerchantData({
            res,
            setLoadingMerchantData,
            setMerchants,
            setMerchantCountries,
            goBack,
          });
        });

      setRetryCount(retryCount + 1);
      if (retryCount >= RETRY_MERCHANTS_ENDPOINT_COUNT) {
        setLoadingMerchantData(false);
        setCurrentModal(AMAZON_FLASH_MODAL_SCREENS.AMZAmazonAuthFailedScreen);
      }
    },
    loadingMerchantData ? 1000 : null
  );

  useEffect(() => {
    // reset if the correlation id changes due to logging into another account
    setRetryCount(0);
    setLoadingMerchantData(true);
  }, [lwaCorrelationId]);

  const NextAPICall = () => {
    if (showConnectedMerchantsModal) {
      return;
    }
    if (
      ownedMerchantAccounts.length !== 0 &&
      notOwnedMerchantAccount.length === 0
    ) {
      setCurrentModal(AMAZON_FLASH_MODAL_SCREENS.AMZAmazonAuthorizesScreen);
    }
    if (rowIdsSelected.length) {
      setIsProcessing(true);
      onSubmit(rowIdsSelected);
    }
  };
  const onSubmit = (selectedRows: any) => {
    famClient
      .claimMerchantCountries(accountInfo.account.id, rowIdsSelected)
      .then((res) => {
        const filteredRows = selectedRows.filter((row: any) => {
          return !(res.merchantCountriesNotClaimed || []).includes(row);
        });
        const ids = filteredRows.map((r: any) => r);
        famClient
          .enableAoForSalesChannels(accountInfo.account.id, ids)
          .then(() => {
            setConnectedMerchants(ids);
            finishEnableSalesChannel({
              selectedMerchantRegion,
              filteredRows,
              setConnectedProducts,
              nextScreen: next,
            });
          });
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const getAccounts = () => {
    const ownedAccount =
      merchantData?.merchantCountries
        .filter((item) => {
          return item.owningAccount?.id === accountInfo.account.id;
        })
        .map((mc) => ({
          country: mc.countryCode,
          merchantId: mc.extMerchantCountryId,
          merchantType: mc.merchantType,
          merchantCountryId: mc.merchantCountryId,
          extMerchantId: mc.extMerchantId,
          isConnected: true,
        })) ?? [];

    const unavailableAccount = merchantData?.merchantCountries
      .filter((item) => {
        return (
          item.owningAccount &&
          item.owningAccount?.id !== accountInfo.account.id
        );
      })
      .map((mc) => ({
        ...mc,
        country: mc.countryCode,
        isNotAvailable: true,
      }));

    const notOwnedAccount =
      merchantData?.merchantCountries
        .filter((mc) => mc.owningAccount === null)
        .map((mc) => ({
          country: mc.countryCode,
          merchantId: mc.extMerchantCountryId,
          merchantType: mc.merchantType,
          merchantCountryId: mc.merchantCountryId,
          extMerchantId: mc.extMerchantId,
        })) ?? [];

    setOwnedMerchantAccountMerchants(ownedAccount);
    setUnavailableMerchantAccountMerchants(unavailableAccount);
    setNotOwnedMerchantAccountMerchants(notOwnedAccount);
    setNotOwnedMerchantAccount(notOwnedAccount);
    setUnavailableMerchantAccount(unavailableAccount);
    setOwnedMerchantAccount(ownedAccount);
  };

  const onRowsSelection = (row: string[], action: boolean) => {
    if (action) {
      const update = [...rowIdsSelected];
      row.forEach((id: string) => {
        if (!rowIdsSelected.includes(id)) {
          update.push(id);
        }
      });
      setRowIdsSelected(update);
    } else {
      const update = [...rowIdsSelected];
      row.forEach((id: string) => {
        remove(update, (item) => {
          return item === id;
        });
      });
      setRowIdsSelected(update);
    }
  };

  useEffect(() => {
    getAccounts();
  }, [merchantData]);

  const isEmpty = (object: any) => {
    for (const property in object) {
      if (object[property] !== null) return false;
    }
    return true;
  };

  useEffect(() => {
    if (
      notOwnedMerchantAccount.length === 1 &&
      isEmpty(notOwnedMerchantAccount[0])
    ) {
      setCurrentModal(
        AMAZON_FLASH_MODAL_SCREENS.AMZAmazonNoMerchantsModalScreen
      );
    } else {
      setRowIdsSelected([
        ...notOwnedMerchantAccount.map((item: any) => {
          return item.merchantCountryId;
        }),
      ]);
    }
  }, [notOwnedMerchantAccount]);

  const shouldShowWarning =
    (ownedMerchantAccounts.length !== 0 ||
      unavailableMerchantAccounts?.length !== 0) &&
    notOwnedMerchantAccount.length === 0;

  return (
    <ConnectModalWrapper
      dataTestId="connected_merchants_modal"
      isOpen
      stepText={intl.formatMessage(
        {
          id: I18nKey.SETUP_FLYWHEEL_STEPS_TEXT_WITH_TOTAL,
        },
        {
          number: 1,
          total: 2,
        }
      )}
      headerText={intl.formatMessage({
        id: I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_CONNECT_ADVERTISING,
      })}
      headerChannelType={channel}
      content={
        <ModalContent
          ownedMerchantAccounts={ownedMerchantAccounts}
          unavailableMerchantAccounts={unavailableMerchantAccounts}
          notOwnedMerchantAccount={notOwnedMerchantAccount}
          setCurrentModal={setCurrentModal}
          loadingMerchantData={loadingMerchantData}
          setrowIdsSelected={setRowIdsSelected}
          rowIdsSelected={rowIdsSelected}
          onRowsSelection={onRowsSelection}
          showWarning={shouldShowWarning}
          showConnectedMerchantsModal={showConnectedMerchantsModal}
          channel={channel}
        />
      }
      footer={
        <ModalFooter
          goBack={goBack}
          next={next && NextAPICall}
          secondaryButtonText={secondaryButtonText}
          rowIdsSelected={rowIdsSelected}
          hideIconInSecondaryButton={hideIconInSecondaryButton}
          isProcessing={isProcessing}
        />
      }
      closeModal={closeModal}
    />
  );
};
ConnectedMerchantsModal.displayName = 'ConnectionInitialModal';

const ModalContent: React.FC<any> = ({
  ownedMerchantAccounts,
  unavailableMerchantAccounts,
  notOwnedMerchantAccount,
  loadingMerchantData,
  rowIdsSelected,
  onRowsSelection,
  setCurrentModal,
  showWarning,
  showConnectedMerchantsModal,
  channel,
}) => {
  const intl = useIntl();

  const modalHeaderSubHeaderText: {
    header: string;
    subHeader?: string;
  } = getHeaderSubHeaderText(
    intl,
    ownedMerchantAccounts,
    unavailableMerchantAccounts,
    notOwnedMerchantAccount
  );

  return loadingMerchantData ? (
    <div className="min-h-96 flex flex-col justify-center">
      <Spinner centerAlign={false} />
    </div>
  ) : (
    <div className="text-left text-base text-grey-900 flex flex-col pt-24 overflow-y-auto min-h-420 gap-12">
      {modalHeaderSubHeaderText && modalHeaderSubHeaderText.header && (
        <Typography
          size={TypographySize.xl}
          lineHeight={TypographyLineHeight.tight}
          weight={TypographyWeight.semibold}
        >
          {modalHeaderSubHeaderText.header}
        </Typography>
      )}
      {modalHeaderSubHeaderText && modalHeaderSubHeaderText.subHeader && (
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.normal}
          weight={TypographyWeight.regular}
        >
          {modalHeaderSubHeaderText.subHeader}
        </Typography>
      )}

      <MerchantsChannelsTable
        showSearchInput={
          !showConnectedMerchantsModal && notOwnedMerchantAccount.length > 5
        }
        showBulkSelection={
          !(showConnectedMerchantsModal || notOwnedMerchantAccount.length === 0)
        }
        tableClassName="border-solid overflow-hidden max-h-300 flex self-stretch grow shrink-0 basis-0"
        headerClass="bg-white h-42"
        {...notOwnedMerchantAccount}
        rowData={getRowData(
          ownedMerchantAccounts,
          unavailableMerchantAccounts,
          notOwnedMerchantAccount
        )}
        rowIdsSelected={rowIdsSelected}
        onRowsSelection={onRowsSelection}
        rowIdKeyName="merchantCountryId"
      />
      <NeedHelp
        url={INTERCOM_AMAZON_ONBOARDING_HELP}
        channel={channel}
        dataTestId="connected_merchants_modal"
      />
      {!showConnectedMerchantsModal && (
        <>
          {ownedMerchantAccounts.length !== 0 &&
            notOwnedMerchantAccount.length !== 0 && (
              <div
                className={`border border-solid border-grey-200 rounded
            flex flex-row justify-between items-center
            mt-16 py-16 px-24 cursor-pointer mb-16
            `}
                onClick={() =>
                  setCurrentModal(
                    AMAZON_FLASH_MODAL_SCREENS.AMZAmazonAllConnectMerchantsModalScreen
                  )
                }
              >
                <Typography
                  size={TypographySize.sm}
                  lineHeight={TypographyLineHeight.none}
                  weight={TypographyWeight.regular}
                >
                  {intl.formatMessage(
                    {
                      id: I18nKey.CONNECT_ADS_MODAL_AMAZON_MERCHANTS_ALREADY_CONNECT,
                    },
                    {
                      connectedCount: ownedMerchantAccounts.length,
                    }
                  )}
                </Typography>

                <Icon
                  svg={ArrowRightIcon}
                  className="text-grey-400"
                  size={IconSize.Small}
                />
              </div>
            )}
          {unavailableMerchantAccounts?.length !== 0 &&
            notOwnedMerchantAccount.length !== 0 && (
              <div
                className={`border border-solid border-grey-200 rounded
            flex flex-row justify-between items-center
            mt-16 py-16 px-24 cursor-pointer mb-16
            `}
                onClick={() =>
                  setCurrentModal(
                    AMAZON_FLASH_MODAL_SCREENS.AMZAmazonUnavailableMerchantsModal
                  )
                }
              >
                <Typography
                  size={TypographySize.sm}
                  lineHeight={TypographyLineHeight.none}
                  weight={TypographyWeight.regular}
                >
                  {intl.formatMessage(
                    {
                      id: I18nKey.CONNECT_ADS_MODAL_AMAZON_UNAVAILABLE_MERCHANTS_TO_CONNECT,
                    },
                    {
                      unavailableCount: unavailableMerchantAccounts?.length,
                    }
                  )}
                </Typography>
                <Icon
                  svg={ArrowRightIcon}
                  className="text-grey-400"
                  size={IconSize.Small}
                />
              </div>
            )}
        </>
      )}
    </div>
  );
};
ModalContent.displayName = 'ModalContent';

interface ModalFooterProps extends ConnectionInitialModalProps {
  readonly ownedMerchantAccounts: RowDataProps[];
  readonly isProcessing: boolean;
}

const ModalFooter: React.FC<
  Omit<
    ModalFooterProps,
    | 'channel'
    | 'famClient'
    | 'lwaCorrelationId'
    | 'setMerchantCountries'
    | 'setCurrentModal'
    | 'setOwnedMerchantAccountMerchants'
    | 'setUnavailableMerchantAccountMerchants'
    | 'setNotOwnedMerchantAccountMerchants'
    | 'selectedMerchantRegion'
    | 'setConnectedProducts'
    | 'setConnectedMerchants'
    | 'saveLWACorrelationId'
    | 'setLoadingMerchantData'
    | 'loadingMerchantData'
    | 'ownedMerchantAccounts'
  >
> = ({
  goBack,
  next,
  secondaryButtonText,
  hideIconInSecondaryButton,
  rowIdsSelected,
  isProcessing,
}) => {
  const intl = useIntl();
  const [BACK, CONTINUE] = [
    I18nKey.BACK,
    I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_CONNECT_BUTTON,
  ].map((id) => intl.formatMessage({ id }));

  const buttonState = () => {
    if (isProcessing) {
      return ButtonState.Loading;
    } else if (rowIdsSelected?.length === 0) {
      return ButtonState.Disabled;
    }
    return ButtonState.Active;
  };
  return (
    <div className="flex justify-between mx-8">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.BlackAndWhite}
        label={secondaryButtonText || BACK}
        svgIcon={hideIconInSecondaryButton ? undefined : ArrowLeftIcon}
        onClick={goBack}
        dataTestId={`flash1_amazon_ads_available_merchants_back`}
      />
      {next && (
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Primary}
          label={CONTINUE}
          state={buttonState()}
          onClick={next}
          dataTestId={`flash1_amazon_ads_available_merchants_continue`}
        />
      )}
    </div>
  );
};
ModalFooter.displayName = 'ModalFooter';

const getHeaderSubHeaderText = (
  intl: IntlShape,
  ownedMerchantAccounts: RowDataProps[],
  unavailableMerchantAccounts: RowDataProps[],
  notOwnedMerchantAccount: RowDataProps[]
) => {
  if (
    unavailableMerchantAccounts?.length > 0 &&
    ownedMerchantAccounts?.length === 0 &&
    notOwnedMerchantAccount?.length === 0
  ) {
    return {
      header: intl.formatMessage({
        id: I18nKey.FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_UNAVAILABLE_MERCHANTS,
      }),
      subHeader: intl.formatMessage({
        id: I18nKey.FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_UNAVAILABLE_MERCHANTS_SUBHEADER,
      }),
    };
  } else if (
    ownedMerchantAccounts?.length > 0 &&
    unavailableMerchantAccounts?.length === 0 &&
    notOwnedMerchantAccount?.length === 0
  ) {
    return {
      header: intl.formatMessage({
        id: I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_CONTENT_WARNING_ALREADY_CONNECT_HEADER,
      }),
      subHeader: intl.formatMessage({
        id: I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_CONTENT_WARNING_ALREADY_CONNECT_HEADER,
      }),
    };
  }
  return {
    header: intl.formatMessage({
      id: I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_CONTENT_HEADER,
    }),
  };
};

const getRowData = (
  ownedMerchantAccounts: RowDataProps[],
  unavailableMerchantAccounts: RowDataProps[],
  notOwnedMerchantAccount: RowDataProps[]
) => {
  if (
    unavailableMerchantAccounts?.length > 0 &&
    ownedMerchantAccounts?.length === 0 &&
    notOwnedMerchantAccount?.length === 0
  ) {
    return unavailableMerchantAccounts;
  } else if (
    ownedMerchantAccounts?.length > 0 &&
    unavailableMerchantAccounts?.length === 0 &&
    notOwnedMerchantAccount?.length === 0
  ) {
    return ownedMerchantAccounts;
  }
  return notOwnedMerchantAccount;
};
