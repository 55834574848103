import classNames from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LoadingCard: React.FC<{ count?: number }> = ({ count }) => (
  <Skeleton
    height={14}
    style={{ borderRadius: 0 }}
    count={count}
    className="radius"
  />
);

export const BusinessPerformanceLoadingState = () => {
  const commonCards = (
    <>
      <div className="p-12 w-180">
        <LoadingCard />
      </div>
      <div className="p-12 w-180">
        <LoadingCard />
      </div>
      <div className="p-12 w-180">
        <LoadingCard />
      </div>
      <div className="p-12 w-180">
        <LoadingCard />
      </div>
    </>
  );

  return (
    <div
      className={classNames(
        'flex flex-col rounded-4 border-grey-300 border-solid border'
      )}
    >
      <div className="flex flex-row items-start shadow-liteTable">
        <div className="p-12 w-shield">
          <LoadingCard />
        </div>
        <div className="p-12 w-400">
          <LoadingCard />
        </div>
        {commonCards}
      </div>
      {Array(5)
        .fill(undefined)
        .map((value, index) => (
          <div
            key={index}
            className="flex flex-row items-start shadow-liteTable"
          >
            <div className="p-12 w-shield">
              <LoadingCard />
            </div>
            <div className="p-12 w-400 flex flex-col">
              <LoadingCard count={2} />
            </div>
            {commonCards}
          </div>
        ))}
    </div>
  );
};
