import groupBy from 'lodash/groupBy';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import { createFAMApiClient } from '../../../../../lib/clients/FAMApiClient';
import { PaginatedRequest } from '../../../../../lib/clients/types';
import {
  FAMConnectionStatus,
  FlywheelSalesChannel,
  MerchantType,
  SalesChannelData,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../../ConnectModalWrapper';
import { Region } from '../authProductData';
import { ModalContent } from './content';
import { ModalFooter } from './footer';
import { Merchants } from '../../constants';
import { StrictStringMap } from '../../../../../lib/types';
import { AMAZON_SALES_CHANNEL_ID } from '../../../../../lib/types/SalesChannels';

interface SelectAdsProfileModalProps {
  readonly selectedMerchantRegion: Region;
  readonly closeModal: () => void;
  readonly goBack: () => void;
  readonly spapi_oauth_code: string | null | undefined;
  readonly vendorGroupId: string | null | undefined;
  readonly next: () => void;
  readonly setVendorProfileConnected: (status: boolean) => void;
}

export const SelectAdsProfileModal: React.FC<SelectAdsProfileModalProps> = ({
  selectedMerchantRegion,
  closeModal,
  goBack,
  spapi_oauth_code,
  vendorGroupId,
  next,
  setVendorProfileConnected,
}) => {
  const intl = useIntl();
  const userContext = React.useContext<UserContextState>(UserContext);
  const account = getCurrentAccountFromContext(userContext)!!;
  const famApiClient = createFAMApiClient(userContext.userInfo.idToken!);

  const [adAccounts, setAdAccount] =
    useState<StrictStringMap<SalesChannelData[]>>();
  const [loading, setLoading] = useState(false);
  const [profileIdSelected, setProfileIdSelected] = useState<string>('');
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const region = Merchants.filter(
    (item) => item.regionId === selectedMerchantRegion.regionId
  )[0];

  const mapCountryToRegionId = (countryCode: string) => {
    return Merchants.filter((item) =>
      item.country_code.includes(countryCode)
    )[0].regionId;
  };

  const getAdsProfiles = async () => {
    setLoading(true);
    const request: PaginatedRequest = {
      itemsPerPage: 1000,
      page: 1,
      sorts: [],
      filters: [],
    };

    try {
      const { items } = await famApiClient.getSalesChannelTableData(
        account.id,
        AMAZON_SALES_CHANNEL_ID
      )(request);
      const filteredMerchants = items.filter(
        (item) =>
          item.extMerchantType === MerchantType.Vendor &&
          mapCountryToRegionId(item.country) ===
            selectedMerchantRegion.regionId &&
          (item.productConnectionStatus === FAMConnectionStatus.Inactive ||
            !item.productConnectionStatus)
      );

      const groupMerchantsByCountry = groupBy<SalesChannelData>(
        filteredMerchants,
        'country'
      );
      setAdAccount(groupMerchantsByCountry);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onConnectAds = async () => {
    if (profileIdSelected && vendorGroupId && spapi_oauth_code) {
      setUpdatingProfile(loading);
      const ids = [profileIdSelected];
      try {
        await famApiClient.amazon1pSellingPartnerConnect(
          account.id,
          ids,
          region.regionId!,
          spapi_oauth_code,
          vendorGroupId
        );
        setUpdatingProfile(false);
        setVendorProfileConnected(true);
        next();
      } catch (err) {
        setUpdatingProfile(false);
        setVendorProfileConnected(false);
        goBack();
      }
    }
  };

  useEffect(() => {
    getAdsProfiles();
  }, []);

  return (
    <ConnectModalWrapper
      dataTestId="select_ads_profile_modal"
      isOpen
      headerText={intl.formatMessage({
        id: I18nKey.FLASH_MODALS_AMAZON_MODAL_HEADER,
      })}
      content={
        <ModalContent
          loading={loading}
          adAccounts={adAccounts}
          profileIdSelected={profileIdSelected}
          setProfileIdSelected={setProfileIdSelected}
        />
      }
      showFooterSeparator={false}
      headerChannelType={FlywheelSalesChannel.Amazon}
      closeModal={closeModal}
      footer={
        <ModalFooter
          goBack={goBack}
          updatingProfile={updatingProfile}
          onConnectAds={onConnectAds}
          hasSelectedMerchants={!!profileIdSelected}
        />
      }
    />
  );
};
SelectAdsProfileModal.displayName = 'SelectAdsProfile';
