import {
  Modal,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserContext } from '../../containers/userProvider/userProvider';
import I18nKey from '../../lib/types/I18nKey';
import { CollectProfileForm } from './CollectProfileForm';
import { Sidebar } from './Sidebar';
import { AccountSuccess } from './AccountSuccess';
import { AnimationSteps } from './types';
import {
  SignUpMethod,
  getSignUpMethod,
} from '../../containers/userProvider/selectors';

interface FinishProfileModalProps {
  isProfilePending: boolean;
  isCommunity: boolean;
  isConnected: boolean;
}

export const FinishProfileModal: React.FC<FinishProfileModalProps> = ({
  isProfilePending,
  isCommunity,
  isConnected,
}) => {
  const userContext = useContext(UserContext);
  const { userInfo } = userContext;
  const [showModal, setShowModal] = useState(isProfilePending || !isConnected);
  const [animationStep, setAnimationStep] = useState<AnimationSteps>(
    isProfilePending ? AnimationSteps.Step1 : AnimationSteps.Step3
  );
  const [isProfileUpdated, setIsProfileUpdated] = useState(!isProfilePending);
  const signUpMethod = getSignUpMethod(userInfo.idToken!!);
  return (
    <Modal
      showModal={showModal}
      maxModalWidth="max-w-900"
      hidePadding={true}
      minModalWidth="min-w-720"
      className="rounded-4 w-840 h-480 border-0"
      showClose={animationStep === AnimationSteps.Step3}
      onClose={() => setShowModal(false)}
      dataTestId="finish_profile_modal"
    >
      {!isProfileUpdated && (
        <div className="flex flex-row">
          <div className="flex flex-col py-40 px-40 gap-32 grow">
            <Typography
              size={TypographySize['2xl']}
              lineHeight={TypographyLineHeight.tight}
              weight={TypographyWeight.semibold}
              className="text-grey-900"
            >
              <FormattedMessage
                id={
                  signUpMethod === SignUpMethod.GoogleOauth2 && !isCommunity
                    ? I18nKey.SETUP_FLYWHEEL_FINISH_PROFILE_SOCIAL_TITLE
                    : I18nKey.SETUP_FLYWHEEL_FINISH_PROFILE_TITLE
                }
              />
            </Typography>
            <CollectProfileForm
              isCommunity={isCommunity}
              setIsProfileUpdated={setIsProfileUpdated}
            />
          </div>
          <Sidebar isCommunity={isCommunity} />
        </div>
      )}
      {isProfileUpdated && (
        <AccountSuccess
          name={userInfo?.userDetails?.firstName}
          step={animationStep}
          setStep={setAnimationStep}
          isCommunity={isCommunity}
          setShowModal={setShowModal}
        />
      )}
    </Modal>
  );
};

FinishProfileModal.displayName = 'FinishProfileModal';
