import {
  BulkEditModalElementType,
  BulkEditModalNumericValueOperator,
  BulkEditValues,
  EditControlProps,
  FormToggleState,
  IconSize,
  PortaledTooltipAlignment,
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
  RadioButtonProps,
} from '@teikametrics/tm-design-system';
import first from 'lodash/first';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import noop from 'lodash/noop';
import { ChangeEvent } from 'react';
import { IntlShape } from 'react-intl';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  TARGETS_API_COLUMN_NAME,
  TargetStatusOptions,
  isAutoCampaign,
  isCampaignStatusArchived,
  isCampaignStatusCompleted,
  isManualCampaign,
} from '.';
import {
  TableCellChange,
  TableChange,
  TableSelectSettings,
} from '../../../../../containers/table/ducks/types';
import { BidConstraint } from '../../../../../core/campaignFlexibility/types';
import {
  AdLevel,
  AdType,
  AdvertisingGoal,
  AmazonBidOptimization,
  AmazonBiddingStrategy,
  BudgetRollover,
  CampaignDetails,
  CampaignStatus,
  FlywheelSalesChannel,
  Groups,
  MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY,
  MAP_ADVERTISING_GOAL_TO_ICON,
  MAP_ADVERTISING_GOAL_TO_II8NKEY,
  PlacementStatus,
  Portfolio,
  TargetStatus,
  TargetType,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { SMART_GOALS } from '../adgroupTableColumns/utils';
import { CampaignBulkEditModalValues } from '../campaignsTableColumns/campaignName';
import { getHelpGuide } from '../campaignsTableColumns/campaignNameHelpGuide';
import {
  BulkEditItem,
  CAMPAIGN_GROUPS_LEARN_MORE_LINK,
  COGSUploadStatus,
  CampaignBulkEditControls,
  NO_GROUP_VALUE,
  TableCellChangeTarget,
} from '../types';

export const getCurrentValueBeingEdited = (
  tableChange: TableChange,
  targetTableData: TargetsDetails[],
  values: string[]
) => {
  const selectedRowsIds = tableChange.select.rows;
  const selectedTargets: TargetsDetails[] = targetTableData.filter(
    ({ targetId }) => selectedRowsIds.includes(targetId)
  );
  const updatedTargets: TableCellChangeTarget = tableChange.cell;

  const firstUpdatedTarget =
    selectedTargets?.[0] &&
    updatedTargets[selectedTargets[0]?.targetId]?.targetStatus;

  const firstSelectedTarget =
    firstUpdatedTarget || first(selectedTargets)?.channelSettings.status;

  const areAllSelectedStatusEqual = selectedTargets.every((selectedTarget) => {
    const editedBid = updatedTargets[selectedTarget.targetId]?.targetStatus;
    if (editedBid) {
      return editedBid === firstSelectedTarget;
    }
    const currentMaxBidAmount = selectedTarget.channelSettings?.status;
    return firstSelectedTarget === currentMaxBidAmount;
  });

  let currentValueBeingEdited = values;
  if (first(currentValueBeingEdited) === '') {
    currentValueBeingEdited = areAllSelectedStatusEqual
      ? [firstSelectedTarget!]
      : [TargetStatusOptions.Enabled.value];
  }
  return currentValueBeingEdited;
};

const getFirstLabelForSelectedTargets = (
  tableChange: TableChange,
  firstSelectedTarget?: TargetsDetails
) =>
  firstSelectedTarget &&
  tableChange.cell[firstSelectedTarget?.targetId] &&
  tableChange.cell[firstSelectedTarget?.targetId][
    TARGETS_API_COLUMN_NAME.TargetLabels
  ]
    ? tableChange.cell[firstSelectedTarget?.targetId][
        TARGETS_API_COLUMN_NAME.TargetLabels
      ]
    : firstSelectedTarget?.targetDetails.targetType;

const isTargetLabelEqualToFirst = (
  { targetDetails, targetId }: TargetsDetails,
  tableChange: TableChange,
  firstSelectedTargetStatus?: string
) => {
  const editedStatus = tableChange.cell[targetId]?.targetLabel;
  if (!isNil(editedStatus)) {
    if (editedStatus === TargetType.NoTag) {
      // NoTag and undifined are considered same
      return (
        isUndefined(firstSelectedTargetStatus) ||
        editedStatus === firstSelectedTargetStatus
      );
    }

    return editedStatus === firstSelectedTargetStatus;
  }

  if (
    isUndefined(firstSelectedTargetStatus) ||
    firstSelectedTargetStatus === TargetType.NoTag // NoTag and undifined are considered same
  ) {
    return (
      isUndefined(targetDetails.targetType) ||
      targetDetails.targetType === firstSelectedTargetStatus
    );
  }

  return targetDetails.targetType === firstSelectedTargetStatus;
};

export const getCurrentLabel = (
  tableChange: TableChange,
  targetTableData: TargetsDetails[],
  values: string[]
) => {
  const selectedRowsIds = tableChange.select.rows;
  const selectedTargets: TargetsDetails[] = targetTableData.filter(
    ({ targetId }) => selectedRowsIds.includes(targetId)
  );
  const firstSelectedTarget = first(selectedTargets);
  const firstSelectedTargetLabel = getFirstLabelForSelectedTargets(
    tableChange,
    firstSelectedTarget
  );

  const allSelectedRowsHaveSameTargetLabelValue = selectedTargets.every(
    (selectedTarget) =>
      isTargetLabelEqualToFirst(
        selectedTarget,
        tableChange,
        firstSelectedTargetLabel
      )
  );
  let currentValueBeingEdited = values;
  if (first(currentValueBeingEdited) === '') {
    currentValueBeingEdited = allSelectedRowsHaveSameTargetLabelValue
      ? [
          isUndefined(firstSelectedTargetLabel)
            ? TargetType.NoTag
            : firstSelectedTargetLabel,
        ]
      : [TargetType.Brand];
  }
  return currentValueBeingEdited;
};

export const getBulkMenuValueForTargetLabels = (
  rowData: TargetsDetails | undefined,
  values: string[]
) => {
  // If current value is undefined and if NoTag is selected
  // Set the value to undefined otherwise, it is counted in edited values
  if (
    isUndefined(rowData?.targetDetails.targetType) &&
    first(values) === TargetType.NoTag
  ) {
    return undefined as unknown as string;
  } else {
    return first(values)!;
  }
};

export const getCampaignStatusOptions = (
  intl: IntlShape,
  salesChannel: FlywheelSalesChannel,
  adType: AdType,
  onChange?: (value: string) => void,
  campaigns?: CampaignDetails[]
) => {
  const campaignStatusOptions = [
    {
      adTypes: [
        AdType.SponsoredProducts,
        AdType.SponsoredBrands,
        AdType.SponsoredDisplay,
      ],
      options: [
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_ENABLED_STATUS,
          }),
          value: CampaignStatus.Enabled,
          onChange,
        },
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_PAUSED,
          }),
          value: CampaignStatus.Paused,
          onChange,
        },
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_ARCHIVED_STATUS,
          }),
          value: CampaignStatus.Archived,
          onChange,
        },
      ],
      salesChannels: [FlywheelSalesChannel.Amazon],
    },
    {
      adTypes: [
        AdType.SponsoredProducts,
        AdType.SearchBrandAmplifier,
        AdType.SponsoredVideos,
      ],
      options: [
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_ENABLED,
          }),
          value: CampaignStatus.Live,
          onChange,
        },
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_PAUSED,
          }),
          value: CampaignStatus.Paused,
          onChange,
        },
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_COMPLETED,
          }),
          value: CampaignStatus.Completed,
          onChange,
        },
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_EXTEND,
          }),
          value: CampaignStatus.Extend,
          onChange,
        },
      ].filter(({ value }) => {
        if (
          campaigns?.every(
            ({ channelSettings: { status } }) =>
              status?.toLowerCase() === CampaignStatus.Completed
          )
        ) {
          return value === CampaignStatus.Extend;
        }

        if (
          campaigns?.every(
            ({ channelSettings: { status } }) =>
              status?.toLowerCase() !== CampaignStatus.Completed
          )
        ) {
          return value !== CampaignStatus.Extend;
        }

        return true;
      }),
      salesChannels: [FlywheelSalesChannel.Walmart],
    },
  ];

  return campaignStatusOptions.find(
    ({ adTypes, salesChannels }) =>
      adTypes.includes(adType) && salesChannels.includes(salesChannel)
  )?.options;
};

const manualCampaignExists = (campaignsTableData?: CampaignDetails[]) => {
  return (
    (campaignsTableData ?? []).filter(
      ({ campaignDetails: { targetingType } }) =>
        isManualCampaign(targetingType)
    ).length > 0
  );
};

const getProfitSlideOverOption = (
  intl: IntlShape,
  cogsUploadStatus: COGSUploadStatus,
  onChange?: (value: string) => void,
  onMissingCogsClick?: () => void
): RadioButtonProps => {
  if (cogsUploadStatus === COGSUploadStatus.Success) {
    return {
      selected: false,
      disabled: false,
      label: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.Profit],
      }),
      labelClassname: 'text-base leading-none font-normal text-grey-900',
      description: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY[AdvertisingGoal.Profit],
      }),
      descriptionClassName: 'text-grey-600',
      value: AdvertisingGoal.Profit,
      onChange: onChange ?? noop,
      appendLeftIconProps: {
        svg: MAP_ADVERTISING_GOAL_TO_ICON[AdvertisingGoal.Profit],
        size: IconSize.Small,
      },
    };
  } else if (cogsUploadStatus === COGSUploadStatus.MissingCogs) {
    return {
      selected: false,
      disabled: true,
      label: `${intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.Profit],
      })} - ${intl.formatMessage({
        id: I18nKey.GENERIC_NOT_AVAILABLE,
      })}`,
      labelClassname: 'text-base leading-none font-normal text-grey-900',
      description: intl.formatMessage({ id: I18nKey.GENERIC_MISSING_COGS }),
      showLabelInErrorPill: true,
      onErrorPillClick: onMissingCogsClick,
      pillTooltipContent: intl.formatMessage({ id: I18nKey.GENERIC_EDIT_COGS }),
      descriptionClassName: 'text-grey-600',
      value: AdvertisingGoal.Profit,
      onChange: onChange ?? noop,
      appendLeftIconProps: {
        svg: MAP_ADVERTISING_GOAL_TO_ICON[AdvertisingGoal.Profit],
        size: IconSize.Small,
      },
    };
  } else {
    return {
      selected: false,
      disabled: true,
      label: intl.formatMessage({
        id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.Profit],
      }),
      labelClassname: 'text-base leading-none font-normal text-grey-900',
      description: 'Scanning COGS',
      descriptionLoading: true,
      descriptionClassName: 'text-grey-600',
      value: AdvertisingGoal.Profit,
      onChange: onChange ?? noop,
      appendLeftIconProps: {
        svg: MAP_ADVERTISING_GOAL_TO_ICON[AdvertisingGoal.Profit],
        size: IconSize.Small,
      },
    };
  }
};

export const getAdvertisingGoalOptions = (
  intl: IntlShape,
  salesChannel: FlywheelSalesChannel,
  adType: AdType,
  cogsUploadStatus: COGSUploadStatus,
  onMissingCogsClick: () => void,
  onChange?: (value: string) => void
): RadioButtonProps[] => {
  const advertisingGoalOptions = [
    {
      adTypes: [AdType.SponsoredProducts],
      options: [
        {
          selected: false,
          disabled: false,
          label: intl.formatMessage({
            id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.ProductLaunch],
          }),
          labelClassname: 'text-base leading-none font-normal text-grey-900',
          description: intl.formatMessage({
            id: MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY[
              AdvertisingGoal.ProductLaunch
            ],
          }),
          descriptionClassName: 'text-grey-600',
          value: AdvertisingGoal.ProductLaunch,
          onChange: onChange ?? noop,
          appendLeftIconProps: {
            svg: MAP_ADVERTISING_GOAL_TO_ICON[AdvertisingGoal.ProductLaunch],
            size: IconSize.Small,
          },
        },
        {
          selected: false,
          disabled: false,
          label: intl.formatMessage({
            id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.Grow],
          }),
          labelClassname: 'text-base leading-none font-normal text-grey-900',
          description: intl.formatMessage({
            id: MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY[
              AdvertisingGoal.Grow
            ],
          }),
          descriptionClassName: 'text-grey-600',
          value: AdvertisingGoal.Grow,
          onChange: onChange ?? noop,
          appendLeftIconProps: {
            svg: MAP_ADVERTISING_GOAL_TO_ICON[AdvertisingGoal.Grow],
            size: IconSize.Small,
          },
        },
        getProfitSlideOverOption(
          intl,
          cogsUploadStatus,
          onChange,
          onMissingCogsClick
        ),
        {
          selected: false,
          disabled: false,
          label: intl.formatMessage({
            id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.ROAS],
          }),
          labelClassname: 'text-base leading-none font-normal text-grey-900',
          description: intl.formatMessage({
            id: MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY[
              AdvertisingGoal.ROAS
            ],
          }),
          descriptionClassName: 'text-grey-600',
          value: AdvertisingGoal.ROAS,
          onChange: onChange ?? noop,
          appendLeftIconProps: {
            svg: MAP_ADVERTISING_GOAL_TO_ICON[AdvertisingGoal.ROAS],
            size: IconSize.Small,
          },
        },
        {
          selected: false,
          disabled: false,
          label: intl.formatMessage({
            id: MAP_ADVERTISING_GOAL_TO_II8NKEY[AdvertisingGoal.Liquidate],
          }),
          labelClassname: 'text-base leading-none font-normal text-grey-900',
          description: intl.formatMessage({
            id: MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY[
              AdvertisingGoal.Liquidate
            ],
          }),
          descriptionClassName: 'text-grey-600',
          value: AdvertisingGoal.Liquidate,
          onChange: onChange ?? noop,
          appendLeftIconProps: {
            svg: MAP_ADVERTISING_GOAL_TO_ICON[AdvertisingGoal.Liquidate],
            size: IconSize.Small,
          },
        },
      ],
      salesChannels: [
        FlywheelSalesChannel.Walmart,
        FlywheelSalesChannel.Amazon,
      ],
    },
  ];

  return (
    advertisingGoalOptions.find(
      ({ adTypes, salesChannels }) =>
        adTypes.includes(adType) && salesChannels.includes(salesChannel)
    )?.options || []
  );
};

const getBulkEditControlAdvertisingGoal = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  cogsUploadStatus: COGSUploadStatus;
  onMissingCogsClick: () => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  const disabled = shouldDisableAdvertisingGoal(
    config.campaignsTableData?.filter(({ campaignId }) =>
      config.selectSettings?.rows.includes(campaignId)
    )
  );
  return {
    disabled,
    tooltipProps: disabled
      ? {
          content: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_WITH_ATLEAST_ONE_ADVERTISING_GOAL_TOOLTIP,
          }),
          position: {
            placement: PortaledTooltipPlacement.Bottom,
            alignment: PortaledTooltipAlignment.Center,
          },
          theme: PortaledTooltipTheme.Dark,
        }
      : undefined,
    controls: [
      {
        className: 'my-4 items-center',
        columnKey: CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal,
        type: BulkEditModalElementType.Radio,
        value: CampaignStatus.Enabled,
        element: {
          subHeaderText: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION,
          }),
          subHeaderClassName: 'mb-8',
        },
        options: getAdvertisingGoalOptions(
          config.intl,
          config.salesChannel,
          config.adType,
          config.cogsUploadStatus,
          config.onMissingCogsClick,
          config.onChange
        ),
        helpInfo: getHelpGuide({
          intl: config.intl,
          adType: config.adType,
          bulkMenuValue: CampaignBulkEditModalValues.AdvertisingGoal,
          campaignsTableData: config.campaignsTableData ?? [],
          endDateOption: 'NoDate',
          isSelectedSalesChannelWalmart:
            config.salesChannel === FlywheelSalesChannel.Walmart,
          salesChannel: config.salesChannel,
          selectedCount: config.selectedCount ?? 0,
          selectSettings: config.selectSettings ?? {
            all: false,
            rows: [],
          },
          tableChange: config.tableChange ?? {
            cell: {},
            global: {},
            select: config.selectSettings ?? {
              all: false,
              rows: [],
            },
          },
          updateCells: config.updateCells ?? {},
          values: config?.bulkEditValues?.[
            CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal
          ]
            ? [config.bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal]]
            : [],
        }),
      },
    ],
    onSelect: config.onSelect(CampaignBulkEditControls.AdvertisingGoal),
    headerText: config.intl.formatMessage({
      id: I18nKey.GENERIC_ADVERTISING_GOAL_COLUMN,
    }),
    selected: config.selections
      ? config.selections[CampaignBulkEditControls.AdvertisingGoal] ?? false
      : false,
    editKey: CampaignBulkEditControls.AdvertisingGoal,
  };
};

const getBulkEditControlPortfolio = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  return {
    controls: [
      {
        columnKey: CAMPAIGNS_API_COLUMN_NAME.Portfolio,
        type: BulkEditModalElementType.Select,
        value:
          config.portfolioNames && config.portfolioNames.length > 0
            ? config.portfolioNames[0].portFolioId ?? ''
            : '',
        element: {},
        options: config?.portfolioNames?.length
          ? config.portfolioNames.map((portfolio) => ({
              label: portfolio.name,
              value: portfolio.name,
            }))
          : [],
        placeholder: config.intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_NO_PORTFOLIO_LABEL,
        }),
      },
    ],
    headerText: config.intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PORTFOLIO_NAME,
    }),
    onChange: config.onChange,
    onSelect: config.onSelect(CampaignBulkEditControls.Portfolio),
    selected: config.selections
      ? config.selections[CampaignBulkEditControls.Portfolio] ?? false
      : false,
    editKey: CampaignBulkEditControls.Portfolio,
  };
};

const getBulkEditControlGroups = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  groupNames?: Groups[];
  openEditGroupsSlideout?: () => void;
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  return {
    controls: [
      {
        columnKey: CAMPAIGNS_API_COLUMN_NAME.Group,
        type: BulkEditModalElementType.BulkEditMultiSelectionCheckbox,
        value:
          config.groupNames && config.groupNames.length > 0
            ? config.groupNames?.[0]?.groupId ?? ''
            : '',
        element: {},
        options: config?.groupNames?.length
          ? config.groupNames
              ?.filter((item) => item.groupId !== NO_GROUP_VALUE)
              ?.map((group) => ({
                label: group.name,
                value: group.groupId || group.name,
              }))
          : [],
        placeholder: config.intl.formatMessage({
          id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_NO_GROUP,
        }),
        multiselectOptions: {
          description: config.intl.formatMessage({
            id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_BULK_EDIT_DESCRIPTION,
          }),
          separateNoneElementText: {
            label: config.intl.formatMessage({
              id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_NO_GROUP,
            }),
            value: NO_GROUP_VALUE,
          },
          linkText: config.intl.formatMessage({
            id: I18nKey.GENERIC_LEARN_MORE,
          }),
          onLinkClick: () =>
            window.open(CAMPAIGN_GROUPS_LEARN_MORE_LINK, '_blank'),
          onPencilIconClick: () => config.openEditGroupsSlideout?.(),
          pencilIconTooltipContent: config.intl.formatMessage({
            id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_BULK_EDIT_PENCIL_TOOLTIP,
          }),
        },
      },
    ],
    headerText: config.intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_GROUP,
    }),
    onChange: config.onChange,
    onSelect: config.onSelect(CampaignBulkEditControls.Group),
    selected: config.selections
      ? config.selections[CampaignBulkEditControls.Group] ?? false
      : false,
    editKey: CampaignBulkEditControls.Group,
  };
};

const getBulkEditControlEndDate = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  return {
    controls: [
      {
        columnKey: CAMPAIGNS_API_COLUMN_NAME.EndDate,
        type: BulkEditModalElementType.CalendarWithDatePicker,
        value: '',
        element: {
          setDateLabel: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SET_END_DATE,
          }),
          noDateLabel: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_SET_NO_END_DATE,
          }),
          onChange: (value: any) => {
            config.onChange?.(CAMPAIGNS_API_COLUMN_NAME.EndDate, value);
          },
        },
      },
    ],
    headerText: config.intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_END_DATE,
    }),
    selected: config.selections
      ? config.selections[CampaignBulkEditControls.EndDate] ?? false
      : false,
    onSelect: config.onSelect(CampaignBulkEditControls.EndDate),
    editKey: CampaignBulkEditControls.EndDate,
  };
};

const getBulkEditControlCampaignStatus = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  const disabled = shouldDisableCampaignStatus(
    config.salesChannel,
    config.campaignsTableData?.filter(({ campaignId }) =>
      config.selectSettings?.rows.includes(campaignId)
    )
  );
  return {
    disabled,
    tooltipProps: disabled
      ? {
          content: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_WITH_DIFFERENT_STATUS_TOOLTIP,
          }),
          position: {
            placement: PortaledTooltipPlacement.Bottom,
            alignment: PortaledTooltipAlignment.Center,
          },
          theme: PortaledTooltipTheme.Dark,
        }
      : undefined,
    controls: [
      {
        columnKey: CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
        type: BulkEditModalElementType.Radio,
        value: CampaignStatus.Enabled,
        element: {
          subHeaderText: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION,
          }),
        },
        options: getCampaignStatusOptions(
          config.intl,
          config.salesChannel,
          config.adType,
          noop,
          config.campaignsTableData?.filter(({ campaignId }) =>
            config.selectSettings?.rows.includes(campaignId)
          )
        ),
        helpInfo: getHelpGuide({
          intl: config.intl,
          adType: config.adType,
          bulkMenuValue: CampaignBulkEditModalValues.CampaignStatus,
          campaignsTableData: config.campaignsTableData ?? [],
          endDateOption: 'NoDate',
          isSelectedSalesChannelWalmart:
            config.salesChannel === FlywheelSalesChannel.Walmart,
          salesChannel: config.salesChannel,
          selectedCount: config.selectedCount ?? 0,
          selectSettings: config.selectSettings ?? {
            all: false,
            rows: [],
          },
          tableChange: config.tableChange ?? {
            cell: {},
            global: {},
            select: config.selectSettings ?? {
              all: false,
              rows: [],
            },
          },
          updateCells: config.updateCells ?? {},
          values: config?.bulkEditValues?.[
            CAMPAIGNS_API_COLUMN_NAME.CampaignStatus
          ]
            ? [config.bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.CampaignStatus]]
            : [],
        }),
      },
    ],
    onSelect: config.onSelect(CampaignBulkEditControls.CampaignStatus),
    headerText: config.intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS,
    }),
    selected: config.selections
      ? config.selections[CampaignBulkEditControls.CampaignStatus] ?? false
      : false,
    editKey: CampaignBulkEditControls.CampaignStatus,
  };
};

const getBulkEditControlDailyBudget = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  return {
    controls: [
      {
        columnKey: CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
        type: BulkEditModalElementType.Numeric,
        element: {
          value: '',
          extraProps: {
            operator: BulkEditModalNumericValueOperator.SET_TO,
            prependedElement: '$',
            acceptOnlyIntegers: false,
          },
          validators: [{ validator: () => true }],
          onChange: config.onChange,
          label: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_DAILY_BUDGET,
          }),
        },
        value: '',
        helpInfo: getHelpGuide({
          intl: config.intl,
          adType: config.adType,
          bulkMenuValue: CampaignBulkEditModalValues.DailyBudget,
          campaignsTableData: config.campaignsTableData ?? [],
          endDateOption: 'NoDate',
          isSelectedSalesChannelWalmart:
            config.salesChannel === FlywheelSalesChannel.Walmart,
          salesChannel: config.salesChannel,
          selectedCount: config.selectedCount ?? 0,
          selectSettings: config.selectSettings ?? {
            all: false,
            rows: [],
          },
          tableChange: config.tableChange ?? {
            cell: {},
            global: {},
            select: config.selectSettings ?? {
              all: false,
              rows: [],
            },
          },
          updateCells: config.updateCells ?? {},
          values: config.bulkEditValues?.[CAMPAIGNS_API_COLUMN_NAME.DailyBudget]
            ? [config.bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.DailyBudget]]
            : [],
        }),
      },
    ],
    headerText: config.intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_DAILY_BUDGET,
    }),
    selected: config.selections
      ? config.selections[CampaignBulkEditControls.CampaignDailyBudget] ?? false
      : false,
    onSelect: config.onSelect(CampaignBulkEditControls.CampaignDailyBudget),
    editKey: CampaignBulkEditControls.CampaignDailyBudget,
  };
};

const getBulkEditControlLifetimeBudget = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  return {
    controls: [
      {
        columnKey: CampaignBulkEditModalValues.LifetimeBudget,
        type: BulkEditModalElementType.Numeric,
        element: {
          value: '',
          extraProps: {
            operator: BulkEditModalNumericValueOperator.SET_TO,
            prependedElement: '$',
            acceptOnlyIntegers: false,
          },
          validators: [{ validator: () => true }],
          onChange: config.onChange,
          label: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_LIFETIME_BUDGET,
          }),
        },
        value: '',
      },
    ],
    headerText: config.intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_LIFETIME_BUDGET,
    }),
    selected: config.selections
      ? config.selections[CampaignBulkEditControls.CampaignLifetimeBudget] ??
        false
      : false,
    onSelect: config.onSelect(CampaignBulkEditControls.CampaignLifetimeBudget),
    editKey: CampaignBulkEditControls.CampaignLifetimeBudget,
  };
};

const getBulkEditControlBudgetRollover = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  return {
    controls: [
      {
        columnKey: CAMPAIGNS_API_COLUMN_NAME.BudgetRollover,
        type: BulkEditModalElementType.Radio,
        value: BudgetRollover.True,
        options: [
          {
            onChange: (event?: ChangeEvent<HTMLInputElement>) => {
              config.onChange?.(
                CAMPAIGNS_API_COLUMN_NAME.BudgetRollover,
                event?.target.value
              );
            },
            selected: true,
            borderClassName: '',
            disabled: false,
            value: BudgetRollover.True,
            label: config.intl.formatMessage({
              id: I18nKey.GENERAL_BOOLEAN_TRUE,
            }),
          },
          {
            onChange: (event?: ChangeEvent<HTMLInputElement>) => {
              config.onChange?.(
                CAMPAIGNS_API_COLUMN_NAME.BudgetRollover,
                event?.target.value
              );
            },
            selected: true,
            borderClassName: '',
            disabled: false,
            value: BudgetRollover.False,
            label: config.intl.formatMessage({
              id: I18nKey.GENERAL_BOOLEAN_FALSE,
            }),
          },
        ],
      },
    ],
    onSelect: config.onSelect(CampaignBulkEditControls.BudgetRollover),
    headerText: config.intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_ROLLOVER,
    }),
    selected: config.selections
      ? config.selections[CampaignBulkEditControls.BudgetRollover] ?? false
      : false,
    editKey: CampaignBulkEditControls.BudgetRollover,
  };
};

const getBulkEditControlAmazonBiddingStrategy = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  return {
    controls: [
      {
        columnKey: CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy,
        type: BulkEditModalElementType.Radio,
        value: AmazonBiddingStrategy.DynamicBidsDownOnly,
        element: {
          subHeaderText: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION,
          }),
        },
        options: [
          {
            onChange: (event?: ChangeEvent<HTMLInputElement>) => {
              config.onChange?.(
                CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy,
                event?.target.value
              );
            },
            selected: true,
            label: config.intl.formatMessage({
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_DOWN,
            }),
            disabled: false,
            value: AmazonBiddingStrategy.DynamicBidsDownOnly,
          },
          {
            onChange: (event?: ChangeEvent<HTMLInputElement>) => {
              config.onChange?.(
                CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy,
                event?.target.value
              );
            },
            selected: true,
            label: config.intl.formatMessage({
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_UP_AND_DOWN,
            }),
            disabled: false,
            value: AmazonBiddingStrategy.DynamicBidsUpAndDown,
          },
          {
            onChange: (event?: ChangeEvent<HTMLInputElement>) => {
              config.onChange?.(
                CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy,
                event?.target.value
              );
            },
            selected: true,
            label: config.intl.formatMessage({
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_FIXED,
            }),
            disabled: false,
            value: AmazonBiddingStrategy.FixedBid,
          },
        ],
      },
    ],
    headerText: config.intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BIDDING_STRATEGY,
    }),
    selected: config.selections
      ? config.selections[CampaignBulkEditControls.AmazonBiddingStrategy] ??
        false
      : false,
    onSelect: config.onSelect(CampaignBulkEditControls.AmazonBiddingStrategy),
    editKey: CampaignBulkEditControls.AmazonBiddingStrategy,
  };
};

const getBulkEditControlAmazonBidOptmisation = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  return {
    controls: [
      {
        columnKey: CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization,
        type: BulkEditModalElementType.Radio,
        value: AmazonBidOptimization.False,
        options: [
          {
            onChange: (event?: ChangeEvent<HTMLInputElement>) => {
              config.onChange?.(
                CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy,
                event?.target.value
              );
            },
            selected: true,
            label: config.intl.formatMessage({
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BID_OPTIMIZATION_OFF,
            }),
            disabled: false,
            value: AmazonBidOptimization.False,
          },
          {
            onChange: (event?: ChangeEvent<HTMLInputElement>) => {
              config.onChange?.(
                CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization,
                event?.target.value
              );
            },
            selected: true,
            label: config.intl.formatMessage({
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BID_OPTIMIZATION_ON,
            }),
            disabled: false,
            value: AmazonBidOptimization.True,
          },
        ],
      },
    ],
    headerText: config.intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION,
    }),
    selected: config.selections
      ? config.selections[CampaignBulkEditControls.AmazonBidOptimization] ??
        false
      : false,
    onSelect: config.onSelect(CampaignBulkEditControls.AmazonBidOptimization),
    editKey: CampaignBulkEditControls.AmazonBidOptimization,
  };
};

const getBulkEditControlAutoCampaignsPlacementBidMultipliers = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  return {
    editControlInfoLabel: config.intl.formatMessage(
      {
        id: I18nKey.ADS_AMANGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTO_CAMPAIGNS_PLACEMENT_BID_MULTIPLIERS_UPDATE_MESSAGE,
      },
      {
        count: config.campaignsTableData?.filter(
          ({
            campaignDetails: { targetingType },
            channelSettings: { status },
          }) =>
            isAutoCampaign(targetingType) &&
            !isCampaignStatusArchived(status) &&
            !isCampaignStatusCompleted(status)
        ).length,
      }
    ),
    controls: [
      {
        columnKey: `${CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier}-${CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers}`,
        type: BulkEditModalElementType.Numeric,
        element: {
          value: '',
          extraProps: {
            operator: BulkEditModalNumericValueOperator.SET_TO,
            prependedElement: '%',
            acceptOnlyIntegers: false,
          },
          validators: [{ validator: () => true }],
          onChange: config.onChange,
          subHeaderText: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ITEM_BUY_BOX_PLACEMENT,
          }),
        },
        value: '',
      },
      {
        columnKey: `${CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier}-${CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers}`,
        type: BulkEditModalElementType.Numeric,
        element: {
          value: '',
          extraProps: {
            operator: BulkEditModalNumericValueOperator.SET_TO,
            prependedElement: '%',
            acceptOnlyIntegers: false,
          },
          validators: [{ validator: () => true }],
          onChange: config.onChange,
          subHeaderText: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_SEARCH_INGRID,
          }),
        },
        value: '',
      },
      {
        columnKey: `${CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier}-${CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSP}`,
        type: BulkEditModalElementType.Numeric,
        element: {
          value: '',
          extraProps: {
            operator: BulkEditModalNumericValueOperator.SET_TO,
            prependedElement: '%',
            acceptOnlyIntegers: false,
          },
          validators: [{ validator: () => true }],
          onChange: config.onChange,
          subHeaderText: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_HOME_PAGE,
          }),
        },
        value: '',
      },
      {
        columnKey: `${CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier}-${CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSP}`,
        type: BulkEditModalElementType.Numeric,
        element: {
          value: '',
          extraProps: {
            operator: BulkEditModalNumericValueOperator.SET_TO,
            prependedElement: '%',
            acceptOnlyIntegers: false,
          },
          validators: [{ validator: () => true }],
          onChange: config.onChange,
          subHeaderText: config.intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_STOCK_UP,
          }),
        },
        value: '',
      },
    ],
    headerText: config.intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTO_CAMPAIGNS_PLACEMENT_BID_MULTIPLIER,
    }),
    selected: config.selections
      ? config.selections[
          CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers
        ] ?? false
      : false,
    onSelect: config.onSelect(
      CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers
    ),
    editKey: CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers,
  };
};

const getBulkEditControlPlacementInclusion = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
}): EditControlProps => {
  return {
    controls: [
      {
        columnKey: CampaignBulkEditModalValues.PlacementInclusion,
        type: BulkEditModalElementType.FormToggleList,
        value: '',
        element: {
          toggles: [
            {
              label: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_SEARCH_INGRID,
              }),
              value: CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier,
              disabled: true,
              state: FormToggleState.On,
            },
            {
              label: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_SEARCH_CAROUSEL,
              }),
              value: CAMPAIGNS_API_COLUMN_NAME.SearchCarouselInclusion,
            },
            {
              label: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_ITEM_BUYBOX,
              }),
              value: CAMPAIGNS_API_COLUMN_NAME.ItemBuyBoxPlacement,
            },
            {
              label: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_ITEM_CAROUSEL,
              }),
              value: CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement,
            },
          ],
        },
        helpInfo: getHelpGuide({
          intl: config.intl,
          adType: config.adType,
          bulkMenuValue: CampaignBulkEditModalValues.PlacementInclusion,
          campaignsTableData: config.campaignsTableData ?? [],
          endDateOption: 'NoDate',
          isSelectedSalesChannelWalmart:
            config.salesChannel === FlywheelSalesChannel.Walmart,
          salesChannel: config.salesChannel,
          selectedCount: config.selectedCount ?? 0,
          selectSettings: config.selectSettings ?? {
            all: false,
            rows: [],
          },
          tableChange: config.tableChange ?? {
            cell: {},
            global: {},
            select: config.selectSettings ?? {
              all: false,
              rows: [],
            },
          },
          updateCells: config.updateCells ?? {},
          values: getPlacementInclusionValues(config.bulkEditValues),
        }),
      },
    ],
    headerText: config.intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_INCLUSION,
    }),
    selected: config.selections
      ? config.selections[CampaignBulkEditControls.PlacementInclusion] ?? false
      : false,
    onSelect: config.onSelect(CampaignBulkEditControls.PlacementInclusion),
    editKey: CampaignBulkEditControls.PlacementInclusion,
  };
};

const getBulkEditItems = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  onMissingCogsClick: () => void;
  cogsUploadStatus: COGSUploadStatus;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  groupNames?: Groups[];
  openEditGroupsSlideout?: () => void;
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
  showSmartCampaigns?: boolean;
}): BulkEditItem[] => {
  return [
    {
      adTypes: [AdType.SponsoredProducts],
      bulkEditControl: getBulkEditControlAdvertisingGoal(config),
      salesChannels: [
        FlywheelSalesChannel.Amazon,
        FlywheelSalesChannel.Walmart,
      ],
      isValidForSelectedRows: config.showSmartCampaigns === true,
    },
    {
      adTypes: [
        AdType.SponsoredProducts,
        AdType.SponsoredBrands,
        AdType.SponsoredDisplay,
      ],
      bulkEditControl: getBulkEditControlPortfolio(config),
      salesChannels: [FlywheelSalesChannel.Amazon],
    },
    {
      adTypes: [
        AdType.SponsoredProducts,
        AdType.SponsoredBrands,
        AdType.SponsoredDisplay,
        AdType.SponsoredVideos,
      ],
      bulkEditControl: getBulkEditControlGroups(config),
      salesChannels: [
        FlywheelSalesChannel.Amazon,
        FlywheelSalesChannel.Walmart,
      ],
    },
    {
      adTypes: [
        AdType.SponsoredProducts,
        AdType.SponsoredBrands,
        AdType.SponsoredDisplay,
        AdType.SearchBrandAmplifier,
        AdType.SponsoredVideos,
      ],
      bulkEditControl: getBulkEditControlCampaignStatus(config),
      salesChannels: [
        FlywheelSalesChannel.Amazon,
        FlywheelSalesChannel.Walmart,
      ],
    },
    {
      adTypes: [
        AdType.SponsoredProducts,
        AdType.SponsoredDisplay,
        AdType.SponsoredBrands,
        AdType.SearchBrandAmplifier,
        AdType.SponsoredVideos,
      ],
      bulkEditControl: getBulkEditControlEndDate(config),
      salesChannels: [
        FlywheelSalesChannel.Amazon,
        FlywheelSalesChannel.Walmart,
      ],
    },
    {
      adTypes: [
        AdType.SponsoredBrands,
        AdType.SponsoredDisplay,
        AdType.SponsoredProducts,
        AdType.SearchBrandAmplifier,
        AdType.SponsoredVideos,
      ],
      bulkEditControl: getBulkEditControlDailyBudget(config),
      salesChannels: [
        FlywheelSalesChannel.Amazon,
        FlywheelSalesChannel.Walmart,
      ],
    },
    {
      adTypes: [AdType.SponsoredBrands],
      bulkEditControl: getBulkEditControlLifetimeBudget(config),
      salesChannels: [FlywheelSalesChannel.Amazon],
    },
    {
      adTypes: [AdType.SearchBrandAmplifier, AdType.SponsoredProducts],
      bulkEditControl: getBulkEditControlLifetimeBudget(config),
      salesChannels: [FlywheelSalesChannel.Walmart],
    },
    {
      adTypes: [AdType.SponsoredBrands],
      bulkEditControl: getBulkEditControlBudgetRollover(config),
      salesChannels: [FlywheelSalesChannel.Amazon],
    },
    {
      adTypes: [AdType.SponsoredProducts],
      bulkEditControl: getBulkEditControlAmazonBiddingStrategy(config),
      salesChannels: [FlywheelSalesChannel.Amazon],
    },
    {
      adTypes: [AdType.SponsoredBrands, AdType.SponsoredBrandsVideo],
      bulkEditControl: getBulkEditControlAmazonBidOptmisation(config),
      salesChannels: [FlywheelSalesChannel.Amazon],
    },
    {
      adTypes: [AdType.SponsoredProducts, AdType.SponsoredBrands],
      bulkEditControl:
        getBulkEditControlAutoCampaignsPlacementBidMultipliers(config),
      salesChannels: [],
    },
    {
      adTypes: [AdType.SponsoredProducts],
      bulkEditControl: {
        selected: config.selections
          ? config.selections['PlacementBidMultipliersAmazon']
          : false,
        onSelect: config.onSelect('PlacementBidMultipliersAmazon'),
        headerText: config.intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS,
        }),
        editKey: 'PlacementBidMultipliersAmazon',
        controls: [
          {
            columnKey: CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '%',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange: config.onChange,
              subHeaderText: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PRODUCT_PAGE_BID_MULTIPILER,
              }),
            },
            value: '',
          },
          {
            columnKey: CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '%',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange: config.onChange,
              subHeaderText: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOP_OF_SEARCH_BID_MULTIPLER,
              }),
            },
            value: '',
          },
        ],
      },
      salesChannels: [FlywheelSalesChannel.Amazon],
    },
    {
      adTypes: [AdType.SponsoredProducts],
      isValidForSelectedRows:
        manualCampaignExists(config.campaignsTableData) ?? false,
      bulkEditControl: getBulkEditControlPlacementInclusion(config),
      salesChannels: [FlywheelSalesChannel.Walmart],
    },
    {
      adTypes: [AdType.SponsoredProducts],
      isValidForSelectedRows:
        (config.campaignsTableData ?? []).filter(
          ({ campaignDetails: { targetingType } }) =>
            isManualCampaign(targetingType)
        ).length > 0,
      bulkEditControl: {
        editControlInfoLabel: config.intl.formatMessage(
          {
            id: I18nKey.ADS_AMANGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MANUAL_CAMPAIGNS_PLACEMENT_BID_MULTIPLIERS_UPDATE_MESSAGE,
          },
          {
            count: config.campaignsTableData?.filter(
              ({
                campaignDetails: { targetingType },
                channelSettings: { status },
              }) =>
                isManualCampaign(targetingType) &&
                !isCampaignStatusArchived(status) &&
                !isCampaignStatusCompleted(status)
            ).length,
          }
        ),
        controls: [
          {
            columnKey: `${CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier}-${CampaignBulkEditControls.ManualCampaignsPlacementBidMultipliers}`,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '%',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange: config.onChange,
              subHeaderText: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ITEM_BUY_BOX_PLACEMENT,
              }),
            },
            value: '',
          },
          {
            columnKey: `${CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier}-${CampaignBulkEditControls.ManualCampaignsPlacementBidMultipliers}`,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '%',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange: config.onChange,
              subHeaderText: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOP_OF_SEARCH_BID_MULTIPLER,
              }),
            },
            value: '',
          },
        ],
        headerText: config.intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MANUAL_CAMPAIGNS_PLACEMENT_BID_MULTIPLIER,
        }),
        selected: config.selections
          ? config.selections[
              CampaignBulkEditControls.ManualCampaignsPlacementBidMultipliers
            ] ?? false
          : false,
        onSelect: config.onSelect(
          CampaignBulkEditControls.ManualCampaignsPlacementBidMultipliers
        ),
        editKey:
          CampaignBulkEditControls.ManualCampaignsPlacementBidMultipliers,
      },
      salesChannels: [FlywheelSalesChannel.Walmart],
    },
    {
      adTypes: [AdType.SponsoredProducts],
      isValidForSelectedRows:
        (config.campaignsTableData ?? []).filter(
          ({ campaignDetails: { targetingType } }) =>
            isAutoCampaign(targetingType)
        ).length > 0,
      bulkEditControl: {
        editControlInfoLabel: config.intl.formatMessage(
          {
            id: I18nKey.ADS_AMANGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTO_CAMPAIGNS_PLACEMENT_BID_MULTIPLIERS_UPDATE_MESSAGE,
          },
          {
            count: config.campaignsTableData?.filter(
              ({
                campaignDetails: { targetingType },
                channelSettings: { status },
              }) =>
                isAutoCampaign(targetingType) &&
                !isCampaignStatusArchived(status) &&
                !isCampaignStatusCompleted(status)
            ).length,
          }
        ),
        controls: [
          {
            columnKey: `${CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier}-${CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers}`,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '%',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange: config.onChange,
              subHeaderText: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ITEM_BUY_BOX_PLACEMENT,
              }),
            },
            value: '',
          },
          {
            columnKey: `${CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier}-${CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers}`,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '%',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange: config.onChange,
              subHeaderText: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_SEARCH_INGRID,
              }),
            },
            value: '',
          },
          {
            columnKey: `${CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier}-${CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSP}`,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '%',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange: config.onChange,
              subHeaderText: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_HOME_PAGE,
              }),
            },
            value: '',
          },
          {
            columnKey: `${CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier}-${CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSP}`,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '%',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange: config.onChange,
              subHeaderText: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_STOCK_UP,
              }),
            },
            value: '',
          },
        ],
        headerText: config.intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTO_CAMPAIGNS_PLACEMENT_BID_MULTIPLIER,
        }),
        selected: config.selections
          ? config.selections[
              CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers
            ] ?? false
          : false,
        onSelect: config.onSelect(
          CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers
        ),
        editKey: CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers,
      },
      salesChannels: [FlywheelSalesChannel.Walmart],
    },
    {
      adTypes: [AdType.SponsoredProducts],
      bulkEditControl: {
        controls: [
          {
            columnKey: CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '%',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange: config.onChange,
              subHeaderText: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLATFORM_BID_MULTIPLIERS_DESKTOP,
              }),
            },
            value: '',
          },
          {
            columnKey: CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '%',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange: config.onChange,
              subHeaderText: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLATFORM_BID_MULTIPLIERS_APP,
              }),
            },
            value: '',
          },
          {
            columnKey: CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '%',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange: config.onChange,
              subHeaderText: config.intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLATFORM_BID_MULTIPLIERS_MOBILE,
              }),
            },
            value: '',
          },
        ],
        headerText: config.intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLATFORM_BID_MULTIPLEIRS,
        }),
        selected: config.selections
          ? config.selections[
              CampaignBulkEditControls.PlatformBidMultipliers
            ] ?? false
          : false,
        onSelect: config.onSelect(
          CampaignBulkEditControls.PlatformBidMultipliers
        ),
        editKey: CampaignBulkEditControls.PlatformBidMultipliers,
      },
      salesChannels: [FlywheelSalesChannel.Walmart],
    },
  ];
};

export const getBulkEditConfigurationForCampaigns = (config: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel;
  adType: AdType;
  onSelect: (columnKey: string) => (value: boolean) => void;
  cogsUploadStatus: COGSUploadStatus;
  onMissingCogsClick: () => void;
  onChange?: (key: string, value?: string | string[]) => void;
  selections?: Record<string, boolean>;
  portfolioNames?: Portfolio[];
  groupNames?: Groups[];
  openEditGroupsSlideout?: () => void;
  bidConstraintsData?: BidConstraint[];
  selectedCount?: number;
  selectSettings?: TableSelectSettings;
  tableChange?: TableChange;
  updateCells?: TableCellChange;
  campaignsTableData?: CampaignDetails[];
  bulkEditValues?: BulkEditValues;
  showSmartCampaigns?: boolean;
}): EditControlProps[] => {
  const bulkEditItems: BulkEditItem[] = getBulkEditItems(config);

  return bulkEditItems
    .filter(({ adTypes, salesChannels, isValidForSelectedRows }) => {
      if (isNil(isValidForSelectedRows)) {
        return (
          adTypes.includes(config.adType) &&
          salesChannels.includes(config.salesChannel)
        );
      }

      return (
        adTypes.includes(config.adType) &&
        salesChannels.includes(config.salesChannel) &&
        isValidForSelectedRows
      );
    })
    .map(({ bulkEditControl }) => bulkEditControl);
};

export const getBulkEditConfigurationForTargets = (
  intl: IntlShape,
  adType?: AdType,
  adLevel?: AdLevel,
  salesChannel?: FlywheelSalesChannel,
  selections?: Record<string, boolean>,
  onSelect?: (key: string) => (value: boolean) => void,
  onChange?: (key: string, value: any) => void
) => {
  const bulkEditControls: BulkEditItem[] = [
    {
      adTypes: [AdType.SponsoredProducts],
      salesChannels: [
        FlywheelSalesChannel.Walmart,
        FlywheelSalesChannel.Amazon,
      ],
      adLevels: [AdLevel.KeywordTargets],
      bulkEditControl: {
        controls: [
          {
            columnKey: TARGETS_API_COLUMN_NAME.TargetLabels,
            type: BulkEditModalElementType.Radio,
            element: {
              subHeaderText: intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION,
              }),
            },
            options: [
              {
                label: intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BRAND,
                }),
                value: TargetType.Brand,
              },
              {
                label: intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_COMPETITOR,
                }),
                value: TargetType.Competitor,
              },
              {
                label: intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_GENERIC,
                }),
                value: TargetType.Generic,
              },
              {
                label: intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_TARGET_LABELS_NO_TAG,
                }),
                value: TargetType.NoTag,
              },
            ],
            value: TargetType.Brand,
          },
        ],
        headerText: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_LABELS,
        }),
        onSelect: onSelect?.(TARGETS_API_COLUMN_NAME.TargetLabels),
        selected: selections
          ? selections[TARGETS_API_COLUMN_NAME.TargetLabels] ?? false
          : false,
      },
    },
    {
      adTypes: [
        AdType.SponsoredProducts,
        AdType.SearchBrandAmplifier,
        AdType.SponsoredBrands,
        AdType.SponsoredDisplay,
        AdType.SponsoredVideos,
      ],
      salesChannels: [
        FlywheelSalesChannel.Amazon,
        FlywheelSalesChannel.Walmart,
      ],
      bulkEditControl: {
        controls: [
          {
            columnKey: TARGETS_API_COLUMN_NAME.TargetStatus,
            type: BulkEditModalElementType.Radio,
            value: TargetStatus.Enabled,
            element: {
              subHeaderText: intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION,
              }),
            },
            options: getBulkEditTargetStatusOptions(intl, salesChannel, adType),
          },
        ],
        headerText: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_STATUS,
        }),
        selected: selections
          ? selections[TARGETS_API_COLUMN_NAME.TargetStatus] ?? false
          : false,
        onSelect: onSelect?.(TARGETS_API_COLUMN_NAME.TargetStatus),
      },
    },
    {
      adTypes: [
        AdType.SponsoredProducts,
        AdType.SearchBrandAmplifier,
        AdType.SponsoredBrands,
        AdType.SponsoredDisplay,
        AdType.SponsoredVideos,
      ],
      salesChannels: [
        FlywheelSalesChannel.Walmart,
        FlywheelSalesChannel.Amazon,
      ],
      bulkEditControl: {
        controls: [
          {
            columnKey: TARGETS_API_COLUMN_NAME.Bid,
            type: BulkEditModalElementType.Numeric,
            element: {
              value: '',
              extraProps: {
                operator: BulkEditModalNumericValueOperator.SET_TO,
                prependedElement: '$',
                acceptOnlyIntegers: false,
              },
              validators: [{ validator: () => true }],
              onChange,
            },
            value: '',
          },
        ],
        headerText: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_BID,
        }),
        onChange,
        onSelect: onSelect?.(TARGETS_API_COLUMN_NAME.Bid),
        selected: selections
          ? selections[TARGETS_API_COLUMN_NAME.Bid] ?? false
          : false,
      },
    },
  ];

  return bulkEditControls
    .filter(
      ({ adTypes, salesChannels, adLevels }) =>
        adTypes.includes(adType as AdType) &&
        salesChannels.includes(salesChannel as FlywheelSalesChannel) &&
        (adLevels ? adLevels.includes(adLevel as AdLevel) : true)
    )
    .map(({ bulkEditControl }) => bulkEditControl);
};

const getPlacementInclusionValues = (bulkEditValues?: BulkEditValues) => {
  if (
    isNil(bulkEditValues) ||
    isNil(bulkEditValues[CampaignBulkEditModalValues.PlacementInclusion])
  ) {
    return [];
  }

  return [
    bulkEditValues[CampaignBulkEditModalValues.PlacementInclusion].includes(
      CAMPAIGNS_API_COLUMN_NAME.SearchCarouselInclusion
    )
      ? PlacementStatus.Included
      : PlacementStatus.Excluded,
    bulkEditValues[CampaignBulkEditModalValues.PlacementInclusion].includes(
      CAMPAIGNS_API_COLUMN_NAME.ItemBuyBoxPlacement
    )
      ? PlacementStatus.Included
      : PlacementStatus.Excluded,
    bulkEditValues[CampaignBulkEditModalValues.PlacementInclusion].includes(
      CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement
    )
      ? PlacementStatus.Included
      : PlacementStatus.Excluded,
  ];
};

const getBulkEditTargetStatusOptions = (
  intl: IntlShape,
  salesChannel?: FlywheelSalesChannel,
  adType?: AdType
) => {
  const targetOptions = [
    {
      option: {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ENABLED,
        }),
        value: TargetStatus.Enabled,
      },
      salesChannels: [
        FlywheelSalesChannel.Amazon,
        FlywheelSalesChannel.Walmart,
      ],
      adTypes: [
        AdType.SponsoredBrands,
        AdType.SponsoredProducts,
        AdType.SponsoredDisplay,
        AdType.SearchBrandAmplifier,
        AdType.SponsoredVideos,
      ],
    },
    {
      option: {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_PAUSED,
        }),
        value: TargetStatus.Paused,
      },
      salesChannels: [FlywheelSalesChannel.Walmart],
      adTypes: [
        AdType.SearchBrandAmplifier,
        AdType.SponsoredProducts,
        AdType.SponsoredVideos,
      ],
    },
    {
      option: {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_PAUSED,
        }),
        value: TargetStatus.Paused,
      },
      salesChannels: [FlywheelSalesChannel.Amazon],
      adTypes: [
        AdType.SponsoredProducts,
        AdType.SponsoredDisplay,
        AdType.SponsoredBrands,
      ],
    },
    {
      option: {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ARCHIVED,
        }),
        value: TargetStatus.Archived,
      },
      salesChannels: [FlywheelSalesChannel.Amazon],
      adTypes: [
        AdType.SponsoredBrands,
        AdType.SponsoredProducts,
        AdType.SponsoredDisplay,
      ],
    },
  ];

  return targetOptions
    .filter(
      ({ salesChannels, adTypes }) =>
        salesChannels.includes(salesChannel as FlywheelSalesChannel) &&
        adTypes.includes(adType as AdType)
    )
    .map(({ option }) => option);
};

const shouldDisableCampaignStatus = (
  salesChannel: FlywheelSalesChannel,
  campaignDetails?: CampaignDetails[]
) => {
  if (salesChannel === FlywheelSalesChannel.Amazon) {
    return false;
  }

  const completedCampaignsCount =
    campaignDetails?.filter(
      ({ channelSettings: { status } }) =>
        status?.toLowerCase() === CampaignStatus.Completed
    ).length ?? 0;
  const numberOfCampaignsSelected = campaignDetails?.length ?? 0;

  return (
    completedCampaignsCount > 0 &&
    completedCampaignsCount < numberOfCampaignsSelected
  );
};

const shouldDisableAdvertisingGoal = (campaignDetails?: CampaignDetails[]) => {
  const validSmartAdveritsingGoals =
    campaignDetails?.filter(
      ({ campaignDetails: { advertisingGoal } }) =>
        advertisingGoal && SMART_GOALS.includes(advertisingGoal)
    ).length ?? 0;
  const numberOfCampaignsSelected = campaignDetails?.length ?? 0;

  return (
    validSmartAdveritsingGoals === 0 &&
    validSmartAdveritsingGoals < numberOfCampaignsSelected
  );
};
