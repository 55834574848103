import { Money } from '../../../../lib/types/Money';

import I18nKey from '../../../../lib/types/I18nKey';
import { ColumnGlossaryProps } from '../../../products/components/formulaSlideOutModal/columnGlossary';
import { SKUApiClient } from '../../../../lib/clients/SKUApiClient';
import { SelectedTrendsProduct } from '../../../products/containers/skuCatalog';

export enum OutOfStockProductColumns {
  Product = 'name',
  OutOfStockDate = 'outOfStockDate',
  EstimatedMissedSales = 'estimatedMissedSales',
  UnitsSold = 'unitsSold',
  ACOS = 'acos',
  TACOS = 'tacos',
  Campaigns = 'campaignCount',
  SellThroughRate = 'sellThroughRate',
  HealthyInventoryLevel = 'healthyInventoryLevel',
  AdSales = 'totalAdSales',
  AdSpend = 'totalAdSpend',
  PercentageAdSales = 'percentageAdSales',
  PercentageAdSpend = 'percentageAdSpend',
  PercentageTotalSales = 'percentageTotalSales',
  AdSpendPerUnit = 'adSpendPerUnit',
  RateOfSale = 'rateOfSale',
  TotalSales = 'totalSales',
  LastListPrice = 'lastSalePrice',
}

export enum InventoryDashboardMetrics {
  TotalInventoryValue = 'TotalInventoryValue',
  UnsoldInventory = 'UnsoldInventory',
  SellThroughRate = 'SellThroughRate',
  InventorySalesRatio = 'InventorySalesRatio',
  EstMissedSales = 'EstMissedSales',
  InventoryAge = 'InventoryAge',
}

export enum OOSGraphColorCodes {
  TotalSales = '#4B25EA',
  EstimatedMissedSales = '#FB590E',
  AverageRateOfSales = '#0AC2FF',
}

export interface OOSGraphData {
  readonly date: string;
  readonly totalSales: Money;
  readonly estimatedMissedSales: Money;
  readonly rateOfSale: number;
}
export type OOSTableData = {
  readonly skuApiClient: SKUApiClient;
  readonly openROSDefination?: (val: string) => void;
  readonly onViewTrendsSlideoverClick?: (
    product: SelectedTrendsProduct
  ) => void;
};

export interface OOSGraphDataAPIResponse {
  readonly aggregatedEstimatedSales?: Money;
  readonly aggregatedTotalSales?: Money;
  readonly daily: OOSGraphData[];
}
export interface InventoryGlossaryProps {
  readonly columnGroupHeader?: I18nKey;
  readonly columns: ColumnGlossaryProps[];
}

export const INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_ID =
  'INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_ID';

export const INVENTORY_DASHBOARD_EM_DASH_VALUE = '–';

export const INVENTORY_DATA_SYNC_BANNER_VISIBILITY_PERIOD_IN_HRS = 24;
