import { DateTime } from 'luxon';

import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { CAMPAIGNS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) =>
  makeNumericColumn<CampaignDetails>(
    ({
      campaignPerformance: { roasTotal },
      channelSettings: { startDate },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        roasTotal
      );

      return performanceMetricValue?.toString();
    },
    false,
    true
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const roasColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.ROAS,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_ROAS,
  RowCellElement,
  gridColumnWidth: '100px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const roasFilter = createNumericDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.ROAS,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_ROAS,
  isValidNumber(0)
);
