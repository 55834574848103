import {
  CampaignsData,
  MerchantCountry,
  SuggestionType,
} from './AIPoweredSharedTypes';
import { FlywheelSalesChannel } from './Fam';
import { MoneyWithAmountInString } from './Money';

export enum AdOptimizerAdTypeNames {
  SponsoredProducts = 'Sponsored Products',
  SponsoredProductsKeywords = 'Sponsored Products - Keywords',
  SponsoredProductsPat = 'Sponsored Products - PAT',
  SponsoredBrands = 'Sponsored Brands',
  SponsoredDisplay = 'Sponsored Display',
  SponsoredBrandAmplifier = 'Sponsored Brands Amplifier',
}

export enum AdOptimizerAdType {
  SponsoredProducts = 'sponsoredProducts',
  SponsoredBrands = 'sponsoredBrands',
  SponsoredDisplay = 'sponsoredDisplay',
  SearchBrandAmplifier = 'searchBrandAmplifier',
  SponsoredBrandsVideo = 'sponsoredBrandsVideo',
}

export enum AdTargetType {
  Keyword = 'Keyword Targets',
  Product = 'Product Targets',
  Target = 'Targets',
  NegativeKeyword = 'Negative Keyword Targets',
  NegativeProduct = 'Negative Product Targets',
  NegativeTarget = 'Negative Targets',
}

export interface AdOptimizerAdTypeInfo {
  readonly advertisingType: AdOptimizerAdType;
  readonly status: AdTypeStatus;
  readonly targetType?: AdTargetType;
}

export interface AdOptimizerAdTypes {
  readonly salesChannelId: string;
  readonly adTypeStatuses: AdOptimizerAdTypeInfo[];
}

export interface AdOptimizerSetupStatusRequest {
  readonly elements: AdOptimizerSetupStatusElements[];
}

export interface AdOptimizerSetupStatusElements {
  readonly salesChannelId: string;
  readonly merchantCountryIds: Partial<MerchantCountry>[];
}

export interface AdOptimizerSetupStatusRequestResponse {
  readonly elements: AdOptimizerAdTypes[];
  readonly completeStatusCount?: number;
  readonly totalStatusCount?: number;
}

export interface AdOptimizerSetupStatusUpdateResponse {
  readonly status: string;
}

export enum AdTypeStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETE',
}

export enum WizardStepStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export enum UpgradeSelection {
  FullAutomation = 'full_automation',
  BidderOnly = 'bidder_only',
  None = 'none',
}

export enum FullAutomationType {
  FullAutomation = 'full_automation',
  FullAutomationWithBidder = 'full_automation_with_bidder',
  BidderOnly = 'bidder_only',
}

export interface InprogressAdType extends AdOptimizerAdTypeInfo {
  readonly salesChannelName: FlywheelSalesChannel;
  readonly adTypeName: AdOptimizerAdTypeNames;
  readonly salesChannelId?: string;
}

export interface SuggestionsMetrics {
  readonly totalCampaignsPaused: number;
  readonly totalCampaignCreated: number;
  readonly totalCampaignKept: number;
  readonly totalAdGroupsCreated?: number;
  readonly totalAdGroupsKept?: number;
  readonly totalAdGroupsPaused?: number;
  readonly minAcos: number;
  readonly maxAcos: number;
  readonly minBid: number;
  readonly maxBid: number;
}

export interface Metrics {
  readonly fullAutomationSuggestionsMetrics: SuggestionsMetrics;
  readonly bidderOnlySuggestionsMetrics: SuggestionsMetrics;
  readonly noneSuggestionsMetrics: SuggestionsMetrics;
}

export interface ProductFamily {
  readonly productFamilyId: string;
  readonly campaignsToBeCreated?: CampaignsData[];
  readonly campaignsToBePaused?: CampaignsData[];
  readonly campaignsToBeKept?: CampaignsData[];
  readonly acosTargetMin: number;
  readonly acosTargetMax: number;
  readonly totalBudget: MoneyWithAmountInString;
  readonly upgradeSelection: UpgradeSelection;
  readonly adgroupCount?: number;
  readonly campaignCount?: number;
}

export interface Suggestions {
  readonly suggestionId: string;
  readonly merchantCountryId: string;
  readonly productFamilies: ProductFamily[];
  readonly metrics: Metrics;
}

export interface SuggestionsResponse {
  readonly suggestions: Suggestions[];
}

export interface SuggestionRequestData {
  readonly merchantCountryId: string;
  readonly suggestionId: string;
}

export interface SuggestionsRequest {
  readonly merchantCountriesSuggestions: SuggestionRequestData[];
}

export interface ClearSuggestionRequestData {
  readonly productFamilyId: string;
  readonly suggestionType: SuggestionType;
}

export interface ClearSuggestionsRequest {
  readonly applySuggestionsList: ClearSuggestionRequestData[];
}
export interface ProductFamiliesUpgradeSelection {
  readonly productFamilyId: string;
  readonly upgradeSelection: UpgradeSelection;
}

export interface MerchantCountriesUpgradeSelection {
  readonly merchantCountryId: string;
  readonly productFamiliesUpgradeSelections: ProductFamiliesUpgradeSelection[];
}

export interface SuggestionPreviewRequest {
  readonly merchantCountriesUpgradeSelections: MerchantCountriesUpgradeSelection[];
}

export interface SuggestionPreviewGroupCount {
  readonly [UpgradeSelection.FullAutomation]: number;
  readonly [UpgradeSelection.BidderOnly]: number;
  readonly [UpgradeSelection.None]: number;
}

export interface AdOptimizerDetailRequest {
  readonly targetType?: AdTargetType;
  readonly salesChannelId: string;
  readonly merchantCountries: Partial<MerchantCountry>[];
}

export interface AdOptimizerDetailResponse {
  readonly merchantCountries: AdOptimizerDetail[];
}
export interface AdOptimizerDetail {
  readonly merchantCountryId: string;
  readonly campaigns: CampaignsData[];
  readonly campaignCount: number;
  readonly adgroupCount: number;
  readonly suggestedAcosTargetMin: number;
  readonly suggestedAcosTargetMax: number;
}
