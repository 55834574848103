export enum SVAdsApiColumnIdentifier {
  Name = 'Name',
  AdGroupStatus = 'AdGroupStatus',
  AdGroupReviewStatus = 'AdGroupReviewStatus',
  CampaignTargetingType = 'CampaignTargetingType',
  CampaignDailyBudget = 'CampaignDailyBudget',
  LifetimeBudget = 'LifetimeBudget',
  CampaignStatus = 'CampaignStatus',
  CreativeType = 'CreativeType',
  ServingStatus = 'ServingStatus',
  AdGroupName = 'AdGroupName',
  CampaignName = 'CampaignName',
  AdStatus = 'AdStatus',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  ClickThroughRate = 'ClickThroughRate',
  AverageCostPerClick = 'AverageCostPerClick',
  DirectAdSales = 'DirectAdSales',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  OtherSkuUnits = 'OtherSkuUnits',
  AdvertisedSkuUnits = 'AdvertisedSkuUnits',
  RelatedClickRevenue = 'RelatedClickRevenue',
  AdSpend = 'AdSpend',
  ROAS = 'ROAS',
  TotalAttributedSales = 'TotalAttributedSales',
  Orders = 'Orders',
  UnitsSold = 'UnitsSold',
  ConversionRate = 'ConversionRate',
  VideoImpressions = 'VideoImpressions',
  ViewableImpressions = 'ViewableImpressions',
  ViewThroughRate = 'ViewThroughRate',
  ClickThroughRateForViews = 'ClickThroughRateForViews',
  ViewThroughAttributedSales = 'ViewThroughAttributedSales',
  ViewThroughOrders = 'ViewThroughOrders',
  ViewThroughUnitsSold = 'ViewThroughUnitsSold',
  TotalCompleteViewAttributedSales = 'TotalCompleteViewAttributedSales',
  AdvertisedCompleteViewsAttributedSales = 'AdvertisedCompleteViewsAttributedSales',
  OtherCompleteViewAttributedSales = 'OtherCompleteViewAttributedSales',
  CompleteViewOrders = 'CompleteViewOrders',
  CompleteViewUnitsSold = 'CompleteViewUnitsSold',
  VideoFirstQuartileViews = 'VideoFirstQuartileViews',
  VideoMidpointViews = 'VideoMidpointViews',
  VideoThirdQuartileViews = 'VideoThirdQuartileViews',
  VideoCompleteViews = 'VideoCompleteViews',
  VideoUnmutes = 'VideoUnmutes',
  FiveSecondViews = 'FiveSecondViews',
  FiveSecondViewRate = 'FiveSecondViewRate',
}

export const SV_ADS_API_COLUMN_NAME: Record<SVAdsApiColumnIdentifier, string> =
  {
    [SVAdsApiColumnIdentifier.Name]: 'productName',
    [SVAdsApiColumnIdentifier.AdGroupStatus]: 'adGroupStatus',
    [SVAdsApiColumnIdentifier.AdGroupReviewStatus]: 'adGroupReviewStatus',
    [SVAdsApiColumnIdentifier.CampaignTargetingType]: 'campaignTargetingType',
    [SVAdsApiColumnIdentifier.CampaignDailyBudget]: 'campaignDailyBudget',
    [SVAdsApiColumnIdentifier.LifetimeBudget]: 'campaignTotalBudget',
    [SVAdsApiColumnIdentifier.CampaignStatus]: 'campaignStatus',
    [SVAdsApiColumnIdentifier.CreativeType]: 'creativeType',
    [SVAdsApiColumnIdentifier.ServingStatus]: 'creativeServingStatus',
    [SVAdsApiColumnIdentifier.AdGroupName]: 'adGroupName',
    [SVAdsApiColumnIdentifier.CampaignName]: 'campaignName',
    [SVAdsApiColumnIdentifier.AdStatus]: 'status',
    [SVAdsApiColumnIdentifier.Impressions]: 'impressions',
    [SVAdsApiColumnIdentifier.Clicks]: 'clicks',
    [SVAdsApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
    [SVAdsApiColumnIdentifier.AverageCostPerClick]: 'avgCostPerClick',
    [SVAdsApiColumnIdentifier.DirectAdSales]: 'adSalesDirect',
    [SVAdsApiColumnIdentifier.DirectACOS]: 'acosDirect',
    [SVAdsApiColumnIdentifier.DirectROAS]: 'roasDirect',
    [SVAdsApiColumnIdentifier.OtherSkuUnits]: 'otherSkuUnits',
    [SVAdsApiColumnIdentifier.AdvertisedSkuUnits]: 'advertisedSkuUnits',
    [SVAdsApiColumnIdentifier.RelatedClickRevenue]: 'relatedClickRevenue',
    [SVAdsApiColumnIdentifier.AdSpend]: 'adSpend',
    [SVAdsApiColumnIdentifier.ROAS]: 'roasTotal',
    [SVAdsApiColumnIdentifier.TotalAttributedSales]: 'adSalesTotal',
    [SVAdsApiColumnIdentifier.Orders]: 'orders',
    [SVAdsApiColumnIdentifier.UnitsSold]: 'unitsSold',
    [SVAdsApiColumnIdentifier.ConversionRate]: 'conversionRate',
    [SVAdsApiColumnIdentifier.VideoImpressions]: 'videoImpressions',
    [SVAdsApiColumnIdentifier.ViewableImpressions]: 'viewableImpressions',
    [SVAdsApiColumnIdentifier.ViewThroughRate]: 'viewThroughRate',
    [SVAdsApiColumnIdentifier.ClickThroughRateForViews]:
      'clickThroughRateForViews',
    [SVAdsApiColumnIdentifier.ViewThroughAttributedSales]:
      'viewThroughAttributedSales',
    [SVAdsApiColumnIdentifier.ViewThroughOrders]: 'viewThroughOrders',
    [SVAdsApiColumnIdentifier.ViewThroughUnitsSold]: 'viewThroughUnitsSold',
    [SVAdsApiColumnIdentifier.TotalCompleteViewAttributedSales]:
      'totalCompleteViewAttributedSales',
    [SVAdsApiColumnIdentifier.AdvertisedCompleteViewsAttributedSales]:
      'advertisedCompleteViewsAttributedSales',
    [SVAdsApiColumnIdentifier.OtherCompleteViewAttributedSales]:
      'otherCompleteViewAttributedSales',
    [SVAdsApiColumnIdentifier.CompleteViewOrders]: 'completeViewOrders',
    [SVAdsApiColumnIdentifier.CompleteViewUnitsSold]: 'completeViewUnitsSold',
    [SVAdsApiColumnIdentifier.VideoFirstQuartileViews]:
      'videoFirstQuartileViews',
    [SVAdsApiColumnIdentifier.VideoMidpointViews]: 'videoMidpointViews',
    [SVAdsApiColumnIdentifier.VideoThirdQuartileViews]:
      'videoThirdQuartileViews',
    [SVAdsApiColumnIdentifier.VideoCompleteViews]: 'videoCompleteViews',
    [SVAdsApiColumnIdentifier.VideoUnmutes]: 'videoUnmutes',
    [SVAdsApiColumnIdentifier.FiveSecondViews]: 'videoFiveSecondViews',
    [SVAdsApiColumnIdentifier.FiveSecondViewRate]: 'videoFiveSecondViewRate',
  };
