import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  CampaignDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  isCampaignTacticsNotEqualToLegacyOrViews,
} from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ selectedAdType, merchantCountry, ...campaignDetails }) => {
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);
  const isSponseredBrandsAdTypeOrSV =
    selectedAdType === AdType.SponsoredBrands ||
    selectedAdType === AdType.SearchBrandAmplifier ||
    selectedAdType === AdType.SponsoredVideos;

  return makeCurrencyColumn<CampaignDetails>(
    ({
      campaignPerformance: { newToBrandSales },
      campaignDetails: { campaignTactic },
    }) =>
      (((campaignTactic &&
        isCampaignTacticsNotEqualToLegacyOrViews(campaignTactic)) ||
        isSponseredBrandsAdTypeOrSV) && {
        amount: Number(newToBrandSales?.amount || '0'),
        currency: currencyCode || newToBrandSales?.currency || CURRENCY_CODE,
      }) ||
      undefined
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

// If isCampaignTacticsNotEqualToLegacyOrViews/isCampaignAdFormatProductCollectionOrSpotlight = true -> show value received from the backend. If value is null/undefined, UI should show 0.
// If isCampaignTacticsNotEqualToLegacyOrViews and isCampaignAdFormatProductCollectionOrSpotlight = false -> show EmDash, hence undefined
export const newToBrandAdSalesColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.NewToBrandSales,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES,
  RowCellElement,
  gridColumnWidth: '130px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const newToBrandAdSalesFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.NewToBrandSales,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES,
    currency,
    isValidNumber(0)
  );
