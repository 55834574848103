import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { AdGroupDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { ADGROUPS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  merchantCountry,
  ...adGroupDetails
}) => {
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  return makeCurrencyColumn<AdGroupDetails>(
    ({ adGroupDetails: { campaignDailyBudget } }) =>
      campaignDailyBudget
        ? {
            amount: Number(campaignDailyBudget.amount),
            currency:
              currencyCode || campaignDailyBudget.currency || CURRENCY_CODE,
          }
        : undefined,
    adGroupDetails.pendingFields?.includes(
      ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget
    )
  )(adGroupDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignDailyBudgetColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_DAILY_BUDGET,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const campaignDailyBudgetFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_DAILY_BUDGET,
    currency,
    isValidNumber(0)
  );
