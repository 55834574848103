import React from 'react';

import { Proposition, PropositionProps } from './Proposition';

interface PropositionsProps {
  title?: React.ReactNode;
  propositions: PropositionProps[];
}

export const Propositions: React.FC<PropositionsProps> = ({
  title,
  propositions,
}) => {
  return (
    <div className="px-78 xl:px-136">
      {!!title && (
        <div
          className={`relative pb-2 motion-safe:animate-empty-state-proposition
        text-base leading-normal font-semibold text-center`}
        >
          {title}
          <div
            className={`h-2 bg-empty-state-propositions-divider
          absolute bottom-0 w-full left-1/2 -translate-x-1/2`}
          />
        </div>
      )}

      <div className="flex flex-col items-center gap-60 py-64">
        {propositions.map((proposition, idx) => (
          <Proposition
            key={proposition.imageSource}
            {...proposition}
            imageLeftSide={idx % 2 === 1}
          />
        ))}
      </div>
    </div>
  );
};
