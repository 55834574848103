import { applyMiddleware, compose, createStore } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { IdToken } from '@auth0/auth0-react';

import {
  DEFAULT_TABLE_CHANGES,
  DEFAULT_TABLE_STATE,
  DEFAULT_VISIBLE_PAGE,
} from '../containers/tableV2/ducks';
import { createAOApiClient } from '../lib/clients/AOApiClient';
import { createFAMApiClient } from '../lib/clients/FAMApiClient';
import { createSKUApiClient } from '../lib/clients/SKUApiClient';
import { SortOrder } from '../lib/types/Sort';
import { SALES_CHANNELS_TABLE } from '../modules/account/containers/salesChannels/types';
import { USER_MANAGEMENT_TABLE_ID } from '../modules/account/containers/users/types';
import { rootReducer } from './reducer';
import { AppAction, AppExtraArgs, AppState } from './types';
import LogRocket from 'logrocket';

const initialSalesChannelTableState = (pageSize: number) => ({
  ...DEFAULT_TABLE_STATE,
  filters: [],
  sorts: [
    {
      column: 'name',
      direction: SortOrder.Desc,
    },
  ],
  pages: [],
  itemsPerPage: pageSize,
  visiblePage: DEFAULT_VISIBLE_PAGE,
  changes: DEFAULT_TABLE_CHANGES,
});

const initialUsersTableState = (pageSize: number) => ({
  filters: [],
  sorts: [
    {
      column: 'last_name',
      direction: SortOrder.Asc,
    },
  ],
  pages: [],
  itemsPerPage: pageSize,
  visiblePage: DEFAULT_VISIBLE_PAGE,
  changes: DEFAULT_TABLE_CHANGES,
});

const storeState = (
  salesChannelPageSize: number,
  usersTablePageSize: number
) => ({
  tableState: {
    [SALES_CHANNELS_TABLE]: initialSalesChannelTableState(salesChannelPageSize),
    [USER_MANAGEMENT_TABLE_ID]: initialUsersTableState(usersTablePageSize),
  },
});

export const createAppStore = (
  token: IdToken,
  salesChannelPageSize: number,
  usersTablePageSize: number
) => {
  const famClient = createFAMApiClient(token);
  const aoClient = createAOApiClient(token);
  const skuClient = createSKUApiClient(token);

  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose;

  const thunkExtraArgs: AppExtraArgs = {
    famClient,
    aoClient,
    skuClient,
    window,
  };

  const enhancer = composeEnhancers(
    applyMiddleware<ThunkDispatch<AppState, AppExtraArgs, AppAction>>(
      thunk.withExtraArgument(thunkExtraArgs),
      LogRocket.reduxMiddleware()
    )
  );

  return createStore<AppState, AppAction, {}, {}>(
    rootReducer,
    storeState(salesChannelPageSize, usersTablePageSize),
    enhancer
  );
};
