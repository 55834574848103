import { CampaignInfoWithAdGroups } from '../../../../../lib/types/KeywordAction';
import React, { createContext, useContext, useMemo, useState } from 'react';
import noop from 'lodash/noop';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import { createAOApiClient } from '../../../../../lib/clients/AOApiClient';
import { DEFAULT_CURRENCY } from '../../../../keywordAction/utils';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import {
  MatchType,
  TargetInfo,
} from '../../../../../lib/types/CampaignFlexibilitySharedTypes';
import {
  AdType,
  AddedBy,
  CreateTargetKeywordsRequest,
  MAP_ADTYPE_TO_ADVERTISING_TYPE,
} from '../../../../../lib/types/AOSharedTypes';
import { MerchantType } from '../../../../../lib/types/Fam';
import { KeywordType } from './createKeywordsMenu';

export const MAX_SLIDEOUT_RECORDS = 100;
export interface CreateKeywordsState {
  readonly campaigns: CampaignInfoWithAdGroups[];
  readonly totalCampaignsCount: number;
  readonly searchValue?: string;
  readonly searching?: boolean;
  readonly isLoading?: boolean;
  readonly onSearchInputClear: () => void;
  readonly onApplyCampaignSelection: (edits: CampaignMap) => void;
  readonly onSearchValueChange: (value: string) => void;
  readonly onSearchButtonClick: (
    text: string,
    clearSearchAfterResponse?: boolean
  ) => void;
  readonly onCampaignSelectionChange: (
    campaign: CampaignInfoWithAdGroups,
    adgroupId: string,
    checked?: boolean
  ) => void;
  readonly selectedCampaignsMap: CampaignMap;
  readonly onClearSelection: () => void;
  readonly selectedCampaigns: CampaignInfoWithAdGroups[];
  readonly setCampaigns: (campaigns: CampaignInfoWithAdGroups[]) => void;
  readonly keywords: TargetInfo[];
  readonly setKeywords: (targets: TargetInfo[]) => void;
  readonly submitKeywords: () => Promise<void>;
  readonly fetchAdGroups: () => void;
  readonly keywordType: KeywordType;
  readonly adType: AdType;
}

export interface CreateKeywordsProviderProps {
  readonly merchantCountryId: string;
  readonly merchantType: MerchantType;
  readonly salesChannelId: string;
  readonly children: JSX.Element;
  readonly adType: AdType;
  readonly keywordType?: KeywordType;
}

interface CampaignMap {
  readonly [campaignId: string]: CampaignInfoWithAdGroups;
}

const initialState: CreateKeywordsState = {
  campaigns: [],
  onCampaignSelectionChange: noop,
  selectedCampaignsMap: {},
  onClearSelection: noop,
  setCampaigns: noop,
  selectedCampaigns: [],
  onSearchButtonClick: noop,
  onSearchInputClear: noop,
  onSearchValueChange: noop,
  onApplyCampaignSelection: noop,
  keywords: [],
  setKeywords: noop,
  fetchAdGroups: noop,
  submitKeywords: () => Promise.resolve(),
  totalCampaignsCount: 0,
  keywordType: KeywordType.Keyword,
  adType: AdType.SponsoredProducts,
};

export const CreateKeywordsContext =
  createContext<CreateKeywordsState>(initialState);
CreateKeywordsContext.displayName = 'CreateKeywordsContext';

const { Provider } = CreateKeywordsContext;

const getAdType = (adType: AdType) => {
  if (adType === AdType.SearchBrandAmplifier) {
    return AdType.SponsoredBrands;
  }
  return adType;
};

const getMatchType = (matchType: MatchType, isProductTarget: boolean) => {
  if (!isProductTarget) {
    return matchType;
  } else if (matchType === MatchType.Exact) {
    return 'ASIN_SAME_AS';
  } else {
    return 'ASIN_EXPANDED_FROM';
  }
};

export const CreateKeywordsProvider: React.FC<CreateKeywordsProviderProps> = ({
  merchantCountryId,
  salesChannelId,
  merchantType,
  children,
  adType,
  keywordType = KeywordType.Keyword,
}) => {
  const [campaigns, setCampaigns] = useState<CampaignInfoWithAdGroups[]>([]);
  const [totalCampaignsCount, setTotalCampaignsCount] = useState(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [keywords, setKeywords] = useState<TargetInfo[]>([]);
  const [selectedCampaignsMap, setSelectedCampaignsMap] = useState<CampaignMap>(
    {}
  );
  const userContext = useContext<UserContextState>(UserContext);
  const { id: accountId } = getCurrentAccountFromContext(userContext)!;

  const aoClient = createAOApiClient(userContext.userInfo.idToken!);

  const isNegativeKeywords = keywordType === KeywordType.NegativeKeyword;

  const resetSearchingAndLoading = () => {
    setIsLoading(false);
    setSearching(false);
  };

  const fetchEligibleAdGroups = (
    searchText?: string,
    clearSearchAfterResponse?: boolean
  ) => {
    if (merchantCountryId) {
      if (isProductTarget) {
        aoClient
          .eligibleAdGroupsV2ForProductTargets(
            accountId,
            merchantCountryId,
            DEFAULT_CURRENCY,
            searchText || '',
            MAX_SLIDEOUT_RECORDS,
            getAdType(adType),
            isNegativeKeywords
          )
          .then((resp) => {
            setCampaigns(resp.data.elements);
            setTotalCampaignsCount(resp.data.filteredElements);
            if (clearSearchAfterResponse) {
              onSearchInputClear();
            }
            resetSearchingAndLoading();
          })
          .catch(() => {
            resetSearchingAndLoading();
          });
      } else {
        aoClient
          .eligibleAdGroupsV2ForKeywordTargets(
            accountId,
            merchantCountryId,
            DEFAULT_CURRENCY,
            searchText || '',
            MAX_SLIDEOUT_RECORDS,
            getAdType(adType),
            isNegativeKeywords
          )
          .then((resp) => {
            setCampaigns(resp.data.elements);
            setTotalCampaignsCount(resp.data.filteredElements);
            if (clearSearchAfterResponse) {
              onSearchInputClear();
            }
            resetSearchingAndLoading();
          })
          .catch(() => {
            resetSearchingAndLoading();
          });
      }
    } else {
      resetSearchingAndLoading();
    }
  };

  const onSearchInputClear = () => {
    setIsLoading(true);
    setSearchValue('');
    fetchEligibleAdGroups();
  };

  const onSearchButtonClick = (
    text: string,
    clearSearchAfterResponse?: boolean
  ) => {
    setSearching(true);
    fetchEligibleAdGroups(text, clearSearchAfterResponse);
  };

  const onCampaignSelectionChange = (
    campaign: CampaignInfoWithAdGroups,
    adgroupId: string,
    checked?: boolean
  ) => {
    const updatedCampaign = selectedCampaignsMap[campaign.id] ?? campaign;

    if (checked) {
      setSelectedCampaignsMap((current) => ({
        ...current,
        [campaign.id]: {
          ...updatedCampaign,
          selectedAdGroupIds: [
            ...(current[campaign.id]?.selectedAdGroupIds || []),
            adgroupId,
          ],
        },
      }));
    } else {
      setSelectedCampaignsMap((current) => ({
        ...current,
        [campaign.id]: {
          ...updatedCampaign,
          selectedAdGroupIds: (
            current[campaign.id]?.selectedAdGroupIds || []
          ).filter((id) => id !== adgroupId),
        },
      }));
    }
  };

  const selectedCampaigns = useMemo(() => {
    return Object.keys(selectedCampaignsMap)
      .map((campaignId) => selectedCampaignsMap[campaignId])
      .filter((campaign) => (campaign?.selectedAdGroupIds || [])?.length > 0);
  }, [campaigns, selectedCampaignsMap]);

  const onClearSelection = () => {
    setSelectedCampaignsMap({});
  };

  const onSearchValueChange = (newValue: string) => {
    if (!!newValue) {
      setSearchValue(newValue);
    } else {
      onSearchInputClear();
    }
  };

  const onApplyCampaignSelection = (edits: CampaignMap) => {
    setSelectedCampaignsMap((current) => ({
      ...current,
      ...edits,
    }));
  };

  const isProductTarget =
    keywordType === KeywordType.Product ||
    keywordType === KeywordType.Contextual;

  const submitKeywords = async () => {
    const request: CreateTargetKeywordsRequest = {
      targets: keywords.map((keyword) => ({
        asin: isProductTarget ? keyword.searchText : undefined,
        searchValue: !isProductTarget ? keyword.searchText : undefined,
        matchType: getMatchType(
          keyword.matchType as MatchType,
          isProductTarget
        ),
        adGroup: {
          adGroupId: keyword.adgroupId!,
          campaignId: keyword.adgroupInfo.campaign.id!,
          targetSegments: keyword.adgroupInfo.targetSegments!,
        },
      })),
      advertisingType: MAP_ADTYPE_TO_ADVERTISING_TYPE[adType],
      merchantCountryId: merchantCountryId,
      salesChannelId: salesChannelId,
      merchantType,
      isNegativeKeywords,
      addedBy: AddedBy.AdsManagerOnDemand,
    };
    await aoClient.createTargets(accountId, isProductTarget, request);
  };

  return (
    <Provider
      value={{
        campaigns,
        setCampaigns,
        selectedCampaignsMap,
        onCampaignSelectionChange,
        selectedCampaigns,
        onClearSelection,
        searchValue,
        isLoading,
        searching,
        onSearchInputClear,
        onSearchButtonClick,
        onSearchValueChange,
        onApplyCampaignSelection,
        keywords,
        setKeywords,
        submitKeywords,
        totalCampaignsCount,
        fetchAdGroups: fetchEligibleAdGroups,
        keywordType,
        adType: getAdType(adType),
      }}
    >
      {children}
    </Provider>
  );
};
