import isNil from 'lodash/isNil';

import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { SbAdsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { SB_ADS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<SbAdsDetails & TableDataAdsManager> = (
  props
) =>
  makeCurrencyColumn<SbAdsDetails>(
    ({ creativePerformance: { avgCostPerClick } }) => {
      const performanceMetricValue = avgCostPerClick?.amount
        ? getPerformanceMetricValue(
            props.selectedEndDate,
            undefined,
            Number(avgCostPerClick?.amount)
          )
        : undefined;

      return isNil(performanceMetricValue)
        ? undefined
        : {
            amount: performanceMetricValue,
            currency:
              getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
              avgCostPerClick?.currency ||
              CURRENCY_CODE,
          };
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const averageCostPerClickColumn: FlywheelTableColumn<
  SbAdsDetails,
  TableDataAdsManager
> = {
  columnName: SB_ADS_API_COLUMN_NAME.CostPerClick,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_AVG_COST_PER_CLICK,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
};

export const averageCostPerClickFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    SB_ADS_API_COLUMN_NAME.CostPerClick,
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_AVG_COST_PER_CLICK,
    currency,
    isValidNumber(0)
  );
