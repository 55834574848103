import { CheckboxCheckedState } from '@teikametrics/tm-design-system';
import isNil from 'lodash/isNil';
import { FlywheelTableColumnGroup } from '../../../../../containers/table/UpdatedFlywheelTable';
import { CampaignDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { acosColumn } from './acos';
import { acosDirectColumn } from './acosDirect';
import { adConversionsColumn } from './adConversions';
import { adSalesColumn } from './adSales';
import { adSalesDirectColumn } from './adSalesDirect';
import { adSpendColumn } from './adSpend';
import { addedByColumn } from './addedBy';
import { advertisedSKUUnitsColumn } from './advertisedSkuUnits';
import { advertisingGoalColumn } from './advertisingGoal';
import { amazonBidOptimizationColumn } from './amazonBidOptimization';
import { amazonBiddingStrategyColumn } from './amazonBiddingStrategy';
import { appBidMultiplierColumn } from './appBidMultiplier';
import { avgCostPerClickColumn } from './avgCostPerClick';
import { belowTopOfTheSearchBidMultiplierColumn } from './belowTopOfTheSearchBidMultiplier';
import { bidAutomationStatusColumn } from './bidAutomation';
import { budgetRolloverColumn } from './budgetRollover';
import { budgetTypeColumn } from './budgetType';
import { buyBoxBidMultiplierColumn } from './buyBoxBidMultiplier';
import { campaignAdFormatColumn } from './campaignAdFormat';
import { campaignCostTypeColumn } from './campaignCostType';
import { campaignNameColumn } from './campaignName';
import { campaignServingStatusColumn } from './campaignServingStatus';
import { campaignStatusColumn } from './campaignStatus';
import { campaignTacticColumn } from './campaignTactic';
import { campaignTargetingTypeColumn } from './campaignTargetingType';
import { campaignTypeColumn } from './campaignType';
import { clickThroughRateColumn } from './clickThroughRate';
import { clicksColumn } from './clicks';
import { completeViewOrders } from './completeViewOrders';
import { conversionRateColumn } from './conversionRate';
import { createdAtColumn } from './createdAt';
import { dailyBudgetColumn } from './dailyBudget';
import { desktopBidMultiplierColumn } from './desktopBidMultiplier';
import { endDateColumn } from './endDate';
import { fiveSecondViews } from './fiveSecondViews';
import { groupColumn } from './group';
import { homePageBidMultiplierColumn } from './homePageBidMultiplier';
import { impressionsColumn } from './impressions';
import { itemBuyBoxPlacementColumn } from './itemBuyBoxPlacement';
import { itemCarouselPlacementColumn } from './itemCarouselPlacement';
import { macsTargetColumn } from './macsTarget';
import { maxBidColumn } from './maxBid';
import { merchantNameColumn } from './merchantName';
import { minBidColumn } from './minBid';
import { mobileBidMultiplierColumn } from './mobileBidMultiplier';
import { newToBrandAdUnitsSoldColumn } from './newToBrandAdUnitsSold';
import { newToBrandOrdersColumn } from './newToBrandOrders';
import { newToBrandOrdersRateColumn } from './newToBrandOrdersRate';
import { newToBrandAdSalesColumn } from './newToBrandSales';
import { ordersColumn } from './orders';
import { otherSKUUnitsColumn } from './otherSkuUnits';
import { percentageOfAdUnitsSoldNewToBrandColumn } from './percentageOfAdUnitsSoldNewToBrand';
import { percentageOfOrdersNewToBrandColumn } from './percentageOfOrdersNewToBrand';
import { percentageOfSalesNewToBrandColumn } from './percentageOfSalesNewToBrand';
import { portfolioNameColumn } from './portfolioName';
import { productPageBidMultiplierColumn } from './productPageBidMultiplier';
import { relatedClickRevenueColumn } from './relatedClickRevenue';
import { roasColumn } from './roas';
import { roasDirectColumn } from './roasDirect';
import { searchCarouselInclusionColumn } from './searchCarouselInclusion';
import { searchIngridBidMultiplierColumn } from './searchIngridBidMultiplier';
import { smartStructureColumn } from './smartStructure';
import { startDateColumn } from './startDate';
import { stockUpBidMultiplierColumn } from './stockUpBidMultiplier';
import { topOfSearchBidMultiplierColumn } from './topOfSearchBidMultiplier';
import { totalBudgetColumn } from './totalBudget';
import { unitsSoldColumn } from './unitsSold';
import { videoCompleteViews } from './videoCompleteViews';
import { videoFirstQuartileViews } from './videoFirstQuartileViews';
import { videoMidpointViews } from './videoMidpointViews';
import { videoThirdQuartileViews } from './videoThirdQuartileViews';
import { videoUnmutes } from './videoUnmutes';
import { viewThroughOrders } from './viewThroughOrders';
import { viewThroughUnitsSold } from './viewThroughUnitsSold';
import { viewableImpressions } from './viewableImpressions';

export interface GenerateColumnsArgs {
  readonly isEditMode: boolean;
  readonly isAIPlanEnabled: boolean;
  readonly headerExtraProps?: {
    readonly onSelectChange: React.MouseEventHandler<HTMLElement>;
    readonly checked: CheckboxCheckedState;
  };
  readonly hideCampaignAdFormat?: boolean;
  readonly showSmartCampaign?: boolean;
}

export const generateColumns = ({
  isAIPlanEnabled,
  isEditMode,
  headerExtraProps,
  hideCampaignAdFormat,
  showSmartCampaign,
}: GenerateColumnsArgs): FlywheelTableColumnGroup<
  CampaignDetails,
  TableDataAdsManager
>[] => {
  return [
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_DETAILS_COLUMN_GROUP,
      columnsInGroup: [
        {
          ...campaignNameColumn,
          ...(!isNil(headerExtraProps)
            ? {
                showCheckBox: isEditMode,
                checked: headerExtraProps.checked,
                onSelectChange: headerExtraProps.onSelectChange,
              }
            : {}),
        },
      ],
    },
    {
      columnsInGroup: [
        ...(hideCampaignAdFormat ? [] : [campaignAdFormatColumn]),
        merchantNameColumn,
        campaignTargetingTypeColumn,
        ...(showSmartCampaign
          ? [campaignTypeColumn, advertisingGoalColumn, smartStructureColumn]
          : []),
        ...(showSmartCampaign ? [] : [addedByColumn]),
        campaignTacticColumn,
        portfolioNameColumn,
        groupColumn,
        campaignCostTypeColumn,
      ],
    },
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_CHANNEL_SETTINGS_COLUMN_GROUP,
      columnsInGroup: [
        campaignStatusColumn,
        campaignServingStatusColumn,
        createdAtColumn,
        startDateColumn,
        endDateColumn,
        budgetTypeColumn,
        dailyBudgetColumn,
        totalBudgetColumn,
        amazonBidOptimizationColumn,
        belowTopOfTheSearchBidMultiplierColumn,
        budgetRolloverColumn,
        searchCarouselInclusionColumn,
        itemBuyBoxPlacementColumn,
        itemCarouselPlacementColumn,
        buyBoxBidMultiplierColumn,
        searchIngridBidMultiplierColumn,
        homePageBidMultiplierColumn,
        stockUpBidMultiplierColumn,
        desktopBidMultiplierColumn,
        appBidMultiplierColumn,
        mobileBidMultiplierColumn,
        amazonBiddingStrategyColumn,
        productPageBidMultiplierColumn,
        topOfSearchBidMultiplierColumn,
      ],
    },
    ...(isAIPlanEnabled
      ? [
          {
            groupingHeaderI18nKey:
              I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_FLYWHEEL_SETTINGS_COLUMN_GROUP,
            columnsInGroup: [
              bidAutomationStatusColumn,
              macsTargetColumn,
              minBidColumn,
              maxBidColumn,
            ],
          },
        ]
      : []),
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_PERFORMANCE_COLUMN_GROUP,
      columnsInGroup: [
        adSalesColumn,
        adSpendColumn,
        acosColumn,
        roasColumn,
        adConversionsColumn,
        conversionRateColumn,
        unitsSoldColumn,
        impressionsColumn,
        clicksColumn,
        avgCostPerClickColumn,
        clickThroughRateColumn,
        adSalesDirectColumn,
        acosDirectColumn,
        roasDirectColumn,
        relatedClickRevenueColumn,
        newToBrandOrdersColumn,
        percentageOfOrdersNewToBrandColumn,
        newToBrandOrdersRateColumn,
        newToBrandAdSalesColumn,
        percentageOfSalesNewToBrandColumn,
        newToBrandAdUnitsSoldColumn,
        percentageOfAdUnitsSoldNewToBrandColumn,
        advertisedSKUUnitsColumn,
        otherSKUUnitsColumn,
        ordersColumn,
        viewableImpressions,
        viewThroughOrders,
        viewThroughUnitsSold,
        completeViewOrders,
        videoFirstQuartileViews,
        videoMidpointViews,
        videoThirdQuartileViews,
        videoCompleteViews,
        videoUnmutes,
        fiveSecondViews,
      ],
    },
  ];
};
