import { AsyncRequest } from '../../lib/utilities/asyncRequest';
import React from 'react';

export enum TableDensity {
  Compact = 'Compact',
  Comfortable = 'Comfortable',
}

export interface ColumnDataForRow<RowDataType, TableDataType> {
  readonly columnComponent?: React.FC<RowDataType & TableDataType>;
  readonly columnClassName?: string;
  readonly onCellClick?: (
    params: RowDataType | (RowDataType & TableDataType)
  ) => void;
}

export interface BaseColumnElements<
  RowDataType,
  HeaderDataType = {},
  TableDataType = {}
> {
  readonly RowCellElement: React.FC<RowDataType & TableDataType>;
  readonly HeaderCellElement?: React.FC<HeaderDataType & TableDataType>;
  readonly className?: string;
  readonly onCellClick?: (
    params: RowDataType | (RowDataType & TableDataType)
  ) => void;
}

export interface Column<
  RowDataType,
  HeaderDataType = {},
  FooterDataType = {},
  TableDataType = {}
> extends BaseColumnElements<RowDataType, HeaderDataType, TableDataType> {
  readonly FooterCellElement?: React.FC<FooterDataType & TableDataType>;
  readonly gridColumnWidth?: string;
}

export interface ColumnGrouping<R, H = {}, F = {}, T = {}> {
  readonly columnsInGroup: Array<Column<R, H, F, T>>;
  readonly groupingHeaderComponent?: JSX.Element;
}

export const isColumnGrouping = <R, H, F, T>(
  columnOrColumnGrouping: ColumnGrouping<R, H, F, T> | Column<R, H, F, T>
): columnOrColumnGrouping is ColumnGrouping<R, H, F, T> =>
  'columnsInGroup' in columnOrColumnGrouping;

export const isArrayOfColumnGroups = <R, H, F, T>(
  columnsOrColumnGroupings:
    | Array<ColumnGrouping<R, H, F, T>>
    | Array<Column<R, H, F, T>>
): columnsOrColumnGroupings is Array<ColumnGrouping<R, H, F, T>> =>
  columnsOrColumnGroupings.length === 0 ||
  isColumnGrouping(columnsOrColumnGroupings[0]);

export interface SubRows<ParentRowDataType, RowDataType, TableDataType> {
  /**
   * Array of React components corresponding to each sub rows columns header cell,
   * and row cells
   */
  readonly columns: Array<BaseColumnElements<RowDataType, {}, TableDataType>>;
  /**
   * Component shown when `dataResolver` is still loading the data for the sub rows
   */
  readonly maybeLoadingComponent?: React.FC;
  /**
   * Component shown when the `dataResolver` has errored out while loading the data for the sub rows
   */
  readonly maybeErrorComponent?: React.FC;
  /**
   * function the component uses to get the sub rows data, the user of the table passes this function into
   * the component and the component will delegate to the user of the component asking it for the sub row
   * data based on the overall table data + the parent row the sub rows are associated with
   */
  readonly dataResolver: (
    rowData: ParentRowDataType,
    tableData?: TableDataType
  ) => AsyncRequest<RowDataType[]>;
  /**
   * function the component uses to determine whether or not the sub rows are currently visible
   * the component will delegate to the user of the component using this prop to ask whether or
   * not the sub rows should be visible based on the specific parent row as well as the current
   * data for the table
   */
  readonly areSubRowsVisible: (
    rowData: ParentRowDataType,
    tableData?: TableDataType
  ) => boolean;
}

export interface GridTableProps<
  RowDataType,
  SubRowDataType,
  SubRowChildRowDataType,
  HeaderDataType,
  FooterDataType,
  TableDataType
> {
  /**
   * Data related to the Header. It gets passed into the defined Column's
   * `HeaderCellElement`
   *
   * @default undefined
   */
  readonly headerData?: HeaderDataType;
  /**
   * Array of data that should be displayed in the table. Each element in the
   * array corresponds to a row in the table
   */
  readonly rowData: RowDataType[];
  /**
   * Data related to the Footer. It gets passed into the defined Column's
   * `FooterCellElement`
   *
   * @default undefined
   */
  readonly footerData?: FooterDataType;
  /**
   * Data related to the Table as a whole. It gets passed into the defined
   * Column's `HeaderCellElement`, `RowCellElement`, and `FooterCellElement`
   *
   * @default undefined
   */
  readonly tableData?: TableDataType;
  /**
   * Array of React components corresponding to each columns header cell,
   * footer cell, row cells, and sub row cells
   * Header Cell Component gets passed the specified header data + table data
   * Row Cell Component gets passed the specified row's data + table data
   * Sub Row Cell Component gets passed the specified row's sub row data + table data
   * Footer Cell Component gets passed the specified footer data + table data
   */
  readonly columns:
    | Array<Column<RowDataType, HeaderDataType, FooterDataType, TableDataType>>
    | Array<
        ColumnGrouping<
          RowDataType,
          HeaderDataType,
          FooterDataType,
          TableDataType
        >
      >;
  readonly maybeSubRows?: SubRows<RowDataType, SubRowDataType, TableDataType>;
  readonly maybeSubRowChildRows?: SubRows<
    SubRowDataType,
    SubRowChildRowDataType,
    TableDataType
  >;
  /**
   * Boolean to allow specifying the header should stick to the top of the table
   * while scrolling vertically
   *
   * @default false
   */
  readonly hasStickyHeader?: boolean;
  /**
   * Boolean to allow specifying the left most column should stick to the left
   * of the table while scrolling horizontally
   *
   * @default false
   */
  readonly hasStickyLeftColumn?: boolean;
  /**
   * Boolean to allow specifying the footer should stick to the bottom of the
   * table while scrolling vertically
   *
   * @default false
   */
  readonly hasStickyFooter?: boolean;
  /**
   * Density of the rows of the table
   *
   * @default TableDensity.Compact
   */
  readonly tableDensity?: TableDensity;
  /**
   * A component to display when no row data is provided
   *
   * @default undefined
   */
  readonly noDataDisplayComponent?: JSX.Element;
  readonly tableId?: string;
}

export const GRID_TABLE_CLASSES = {
  LEFT_MOST_COLUMN: 'grid-table__left-most-column',
  RIGHT_MOST_COLUMN: 'grid-table__right-most-column',
};
