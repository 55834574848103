import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  AdGroupDetails,
  AdType,
  AiBiddingValues,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  ADGROUPS_API_COLUMN_NAME,
  getOrDefaultIsBiddableFlag,
  isAdGroupStatusArchived,
  isCampaignStatusArchived,
} from '../utils';
import { makeTextWithIconColumn } from '../../../../../containers/table/utils/makeTableCells';
import { IntlShape, useIntl } from 'react-intl';
import {
  IconPlacement,
  LightningFWGradientIcon,
  LightningPurpleGradientIcon,
  LightningSlashIcon,
  SelectV3Controlled,
  SelectV3State,
  SvgIcon,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { ReactNode, useState } from 'react';
import { TableDataAdsManager } from '../types';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import { useDispatch, useSelector } from 'react-redux';
import { WithTable } from '../../../../../containers/table/ducks/types';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { SMART_GOALS, transformAdvertisingGoal } from './utils';

const PredictiveAiI8nKeyMapper: Record<AiBiddingValues, I18nKey> = {
  [AiBiddingValues.Smart]:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING_SMART,
  [AiBiddingValues.Ai]:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING_BIDDING_ONLY,
  [AiBiddingValues.NotEnabled]:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING_NO_AI,
};

const PredictiveAiSvgMapper: Record<AiBiddingValues, SvgIcon> = {
  [AiBiddingValues.Smart]: LightningPurpleGradientIcon,
  [AiBiddingValues.Ai]: LightningFWGradientIcon,
  [AiBiddingValues.NotEnabled]: LightningSlashIcon,
};

const getAiBiddingText = (intl: IntlShape, aiBidding: AiBiddingValues) => {
  const separator = {
    separator: <br />,
  };

  return {
    icon: PredictiveAiSvgMapper[aiBidding],
    text: intl.formatMessage<ReactNode>(
      { id: PredictiveAiI8nKeyMapper[aiBidding] },
      separator
    ),
  };
};

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = (
  adGroupDetails
) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    adGroupId,
    isEditMode,
    flywheelSettings: {
      aiBidding,
      advertisingGoal: goal,
      isOverrideBidModifier,
      avgPreAdGrossMargin,
    },
    aiEnabled,
    aiDisabledForAllSelectedMerchants,
    channelSettings: { status: adGroupStatus },
    adGroupDetails: { campaignStatus, isBiddable },
  } = adGroupDetails;

  const existingValue = aiBidding ?? AiBiddingValues.NotEnabled;

  const textAndIcon = getAiBiddingText(intl, existingValue);

  const updatedValue = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        adGroupId,
        ADGROUPS_API_COLUMN_NAME.AiBidding
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  ) as AiBiddingValues;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (isEditMode && existingValue.toString() !== AiBiddingValues.Smart) {
    const updateCellValue = (inputValue: string) => {
      dispatch(
        tableActions.updateCell({
          tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
          rowId: adGroupId,
          columnName: ADGROUPS_API_COLUMN_NAME.AiBidding,
          value: inputValue,
          existingValue: existingValue.toString(),
          numericValue: false,
        })
      );
    };

    const onChange = (value: string) => {
      updateCellValue(value);
      if (value === AiBiddingValues.Ai && isSmartGoal) {
        // Prefill AcosLimit to Est Pre ad Gross margin.
        dispatch(
          tableActions.updateCell({
            tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
            columnName: ADGROUPS_API_COLUMN_NAME.MACSTarget,
            rowId: adGroupId,
            value: avgPreAdGrossMargin?.toString() || '',
            existingValue: '',
            numericValue: true,
          })
        );
      } else if (value === AiBiddingValues.NotEnabled && isSmartGoal) {
        // Prefill AcosLimit to undefined
        dispatch(
          tableActions.updateCell({
            tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
            columnName: ADGROUPS_API_COLUMN_NAME.MACSTarget,
            rowId: adGroupId,
            value: '',
            existingValue: '',
            numericValue: true,
          })
        );
      }
    };

    const aiBidding = updatedValue ?? existingValue;

    const advertisingGoal = transformAdvertisingGoal(
      aiBidding,
      goal,
      isOverrideBidModifier
    );
    const isSmartGoal = SMART_GOALS.includes(advertisingGoal);

    const aiBasicBiddingSelectOption = {
      value: AiBiddingValues.Ai,
      label: '',
      groupName: intl.formatMessage({
        id: PredictiveAiI8nKeyMapper[AiBiddingValues.Ai],
      }),
      optionLeftContent: PredictiveAiSvgMapper[AiBiddingValues.Ai],
    };
    const noAiSelectOption = {
      value: AiBiddingValues.NotEnabled,
      label: '',
      groupName: intl.formatMessage({
        id: PredictiveAiI8nKeyMapper[AiBiddingValues.NotEnabled],
      }),
      optionLeftContent: PredictiveAiSvgMapper[AiBiddingValues.NotEnabled],
    };

    const smartAiSelectOption = {
      value: AiBiddingValues.Smart,
      label: '',
      groupName: intl.formatMessage({
        id: PredictiveAiI8nKeyMapper[AiBiddingValues.Smart],
      }),
      optionLeftContent: PredictiveAiSvgMapper[AiBiddingValues.Smart],
    };

    const aiBiddingOptions = isSmartGoal
      ? [smartAiSelectOption, noAiSelectOption]
      : [aiBasicBiddingSelectOption, noAiSelectOption];

    const isAiDisabled =
      !aiEnabled || aiDisabledForAllSelectedMerchants || false;

    const isArchivedAdGroup =
      isAdGroupStatusArchived(adGroupStatus) ||
      isCampaignStatusArchived(campaignStatus);

    const automationDisabledForLegacyRow =
      !getOrDefaultIsBiddableFlag(isBiddable);

    return (
      <div className="px-12 py-8 w-full">
        <SelectV3Controlled
          leftIcon={PredictiveAiSvgMapper[aiBidding]}
          onToggle={() => setIsOpen(!isOpen)}
          value={aiBidding.toString()}
          options={aiBiddingOptions}
          placeholder={intl.formatMessage({
            id: I18nKey.GENERIC_ADVERTISING_GOAL_COLUMN,
          })}
          onChange={onChange}
          className="rounded"
          menuClassName="rounded border border-solid border-grey-200"
          popperClassName="mx-0 border-0 rounded shadow-md z-1"
          dataTestId="ai_bidding_campaigns"
          state={
            isAiDisabled || isArchivedAdGroup || automationDisabledForLegacyRow
              ? SelectV3State.Disabled
              : SelectV3State.Default
          }
          isDirty={!!updatedValue}
        />
      </div>
    );
  }

  return makeTextWithIconColumn(
    () => {
      return textAndIcon?.text as any;
    },
    textAndIcon?.icon,
    '',
    undefined,
    undefined,
    IconPlacement.Left
  )(adGroupDetails);
};

export const aiBiddingColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.AiBidding,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING,
  RowCellElement,
  gridColumnWidth: '200px',
};

export const aiBiddingFilter = (adType: AdType) =>
  createMultiSelectDataFieldFilter(
    ADGROUPS_API_COLUMN_NAME.AiBidding,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING,
    getFilterOptions(adType)
  );

const getFilterOptions = (adType: AdType) => {
  switch (adType) {
    case AdType.SponsoredProducts:
      return [
        SelectFilterOption.AiBiddingSmart,
        SelectFilterOption.AiBiddingOnlyBidding,
        SelectFilterOption.AiBiddingNoAi,
      ];
    case AdType.SponsoredBrands:
    case AdType.SponsoredDisplay:
    default:
      return [SelectFilterOption.AiBiddingNoAi];
  }
};
