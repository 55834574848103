import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { User } from '@auth0/auth0-spa-js';
import { fromNullable } from 'fp-ts/lib/Option';
import { DateTime } from 'luxon';
import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { FWCookie, PERSISTED_KEYS } from './lib/utilities/fwCookie';
import { parseQuery } from './lib/utilities/queryString';

const SIGN_UP = 'signup';
export const Auth0ProviderWithHistory: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState) => {
    FWCookie.saveCookie(
      PERSISTED_KEYS.SHOW_BILLING_MODALS,
      'true',
      DateTime.now().plus({ hours: 2 })
    );
    const navigateTo = fromNullable(appState.returnTo).getOrElse('/');
    navigate(navigateTo.replace(window.location.origin, ''), {
      replace: true,
    });
  };

  const urlParams = new URLSearchParams(window.location.search);
  const SCREEN_HINT =
    urlParams.has('action') && urlParams.get('action') === SIGN_UP
      ? SIGN_UP
      : '';

  const getUtmQueryParams = () => {
    const parsedQuery = parseQuery(window.location.search);
    if (!parsedQuery) {
      return '';
    }

    const whitelist = [
      'utm_campaign',
      'utm_source',
      'utm_medium',
      'utm_content',
      'utm_term',
      'tap_a',
      'tap_s',
      'impacttest',
      'irclickid',
    ];
    const filteredQuery = {} as { [key: string]: any };
    Object.keys(parsedQuery).forEach((key) => {
      const name = decodeURIComponent(key);
      const value = decodeURIComponent(encodeURIComponent(parsedQuery[key]));

      if (whitelist.includes(name)) {
        filteredQuery[name] = value;
      }
    });

    return filteredQuery;
  };

  const utmParams = getUtmQueryParams();

  const redirectUri =
    window.location.origin + '/login/callback' + window.location.search;
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      // redirectUri={redirectUri}
      authorizationParams={{ redirectUri, SCREEN_HINT }}
      onRedirectCallback={
        onRedirectCallback as (
          appState?: AppState | undefined,
          user?: User | undefined
        ) => void
      }
      {...utmParams}
    >
      {children}
    </Auth0Provider>
  );
};
