import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import {
  KeywordActionColumns,
  KeywordRecommendationsData,
} from '../../../../../lib/types/KeywordAction';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  Alignment,
  BadgeV2Types,
  ColumnAlign,
  KeywordRoutingTagBadge,
  Placement,
  TextLink,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';
import React from 'react';
import { getKeywordTargetingTypes } from './keywordRecommendation';
import { getAmazonItemPageUrl } from '../../../../../core/campaignFlexibility/utils';
import { MerchantCountryCode } from '../../../../../lib/types/AOSharedTypes';
import { getWalmartItemPageUrl } from '../../../../../lib/utilities/skuUtils';
import { RecommendationsTableV2ExtraProps } from '../types';
import { AMAZON_SALES_CHANNEL_ID } from '../../../../../lib/types/SalesChannels';

export const Cell: React.FC<
  KeywordRecommendationsData & RecommendationsTableV2ExtraProps
> = ({
  merchantCountry,
  keyword: { targetingExpression, tag },
  suggestedDestination: { adGroup },
}) => {
  const parsedTargetingExpression = JSON.parse(targetingExpression || '')?.[0];
  const asin = parsedTargetingExpression?.['value'];

  const onAsinClick = () => {
    const url =
      merchantCountry?.salesChannelId === AMAZON_SALES_CHANNEL_ID
        ? getAmazonItemPageUrl(
            merchantCountry?.country as MerchantCountryCode,
            asin?.toUpperCase() || ''
          )
        : getWalmartItemPageUrl(asin);

    window.open(url);
  };

  return (
    <div className="flex py-8 items-center justify-start gap-8">
      <KeywordRoutingTagBadge
        badgeType={BadgeV2Types.KeywordRoutingTags}
        keywordRoutingTypes={getKeywordTargetingTypes(tag ? [tag] : [])}
        tooltipPosition={{
          placement: Placement.Right,
          alignment: Alignment.Center,
        }}
      />
      <TextLink
        textLabel={asin || ''}
        onClick={onAsinClick}
        className="text-base font-normal uppercase"
      />
    </div>
  );
};

export const productAsinsToAdd: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  RecommendationsTableV2ExtraProps,
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_PRODUCT_ASINS_TO_ADD_HEADER,
  accessorKey: KeywordActionColumns.PRODUCT_ASIN,
  sortable: true,
  size: 300,
  minSize: 300,
  maxSize: 300,
  align: ColumnAlign.Left,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell {...original} {...meta} />,
};

export const productAsinsToAddFilter = createStringDataFieldFilter(
  KeywordActionColumns.PRODUCT_ASIN,
  I18nKey.AD_OPTIMIZER_SUGGESTIONS_ASIN,
  isValidString()
);
