import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import {
  ArrowRightIcon,
  Button,
  ButtonIconLocation,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  KeyIcon,
  TextLink,
  TextLinkSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { AuthorizeWalmartModalProps } from './';
import { WALMART_SELLER_CENTRAL_SHOW_ME_HOW_LINK } from '../../constants';

const WALMART_AUTHORIZATION_LINK = 'https://advertising.walmart.com/view/admin';

export const ModalContent: React.FC<
  Omit<AuthorizeWalmartModalProps, 'goBack'> & {
    readonly onAuthorizeButtonClicked: () => void;
  }
> = ({ onAuthorizeButtonClicked }) => {
  const intl = useIntl();
  const [HEADER, SEND_AUTH_BTN, SHOW_ME_HOW] = [
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_HEADER,
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_SEND_AUTH_BTN,
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_SHOW_ME_HOW,
  ].map((id) => intl.formatMessage({ id }));

  const openInNewTab = () => {
    // set button as clicked
    onAuthorizeButtonClicked();
    window.open(WALMART_AUTHORIZATION_LINK, '_blank');
  };
  return (
    <div
      className={classNames(
        'flex flex-col items-center',
        'gap-16 py-24 mt-16',
        'text-left text-base text-grey-900'
      )}
    >
      <div className="flex flex-col items-center gap-16">
        <div
          className={classNames(
            'flex items-center justify-center',
            'bg-flash-modal-purple-magenta-gradient',
            'w-56 h-56 rounded-full',
            'border-solid border-4 border-purple-100'
          )}
        >
          <Icon svg={KeyIcon} size={IconSize.Large} className="text-white" />
        </div>
        <Typography
          size={TypographySize.lg}
          lineHeight={TypographyLineHeight.tight}
          weight={TypographyWeight.semibold}
          className="text-center"
        >
          {HEADER}
        </Typography>
      </div>
      <Typography
        size={TypographySize.base}
        lineHeight={TypographyLineHeight.normal}
        weight={TypographyWeight.regular}
        className="text-center"
      >
        {intl.formatMessage<ReactNode>(
          {
            id: I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_DESCRIPTION,
          },
          {
            b: (text: ReactNode) => <b>{text}</b>,
          }
        )}
      </Typography>
      <div className="flex flex-col items-center p-16 gap-12 w-full bg-purple-100 border-1 border-purple-200 rounded">
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.BlackAndWhiteBorder}
          label={SEND_AUTH_BTN}
          svgIcon={ArrowRightIcon}
          iconLocation={ButtonIconLocation.AfterLabel}
          onClick={openInNewTab}
          dataTestId={`flash1_walmart_ads_auth_form_send_form`}
          className="w-full flex flex-row justify-center"
        />
        <TextLink
          size={TextLinkSize.Small}
          textLabel={SHOW_ME_HOW}
          dataTestId="flash1_walmart_ads_show_me_how"
          href={WALMART_SELLER_CENTRAL_SHOW_ME_HOW_LINK}
          openNewTab
        />
      </div>
    </div>
  );
};
