import { Factory } from 'fishery';

import { BCSChartsDataResponse } from '../../types/MISharedTypes';

export default Factory.define<BCSChartsDataResponse>(() => ({
  total: {
    bcs: [
      { name: 'Philips Sonicare', value: 0.768 },
      { name: 'Aquasonic', value: 0.054 },
      { name: 'PHILIPS', value: 0.02 },
      { name: 'Philips', value: 0.019 },
      { name: 'Phlips Sonicare', value: 0.017 },
      { name: 'oral-b', value: 0.016 },
      { name: 'Sierra Clean', value: 0.015 },
      { name: 'sZs', value: 0.012 },
      { name: 'Fairywill', value: 0.011 },
      { name: 'Sboly', value: 0.01 },
      { name: 'Aoremon', value: 0.01 },
      { name: 'AWSL', value: 0.01 },
      { name: 'Dnsly', value: 0.005 },
      { name: 'SHENG', value: 0.005 },
      { name: 'Philps', value: 0.004 },
      { name: 'Phlilps', value: 0.003 },
      { name: 'Oclean', value: 0.003 },
      { name: 'Sonimesh', value: 0.002 },
      { name: 'PULUOMASI', value: 0.002 },
      { name: 'N/Q', value: 0.002 },
      { name: '7AM2M', value: 0.001 },
      { name: 'Dailycare', value: 0.001 },
      { name: 'Vochigh', value: 0.001 },
      { name: 'ITECHNIK', value: 0.001 },
      { name: 'GOBEST', value: 0.001 },
      { name: 'Sani', value: 0.001 },
      { name: 'Senyum', value: 0.001 },
      { name: 'Neurora', value: 0 },
      { name: 'TRILINK', value: 0 },
      { name: 'MOSPRO', value: 0 },
      { name: 'jemyda', value: 0 },
      { name: 'Sonimama', value: 0 },
      { name: 'Brand: Safcare', value: 0 },
      { name: 'Mailink', value: 0 },
      { name: 'Oral Basics', value: 0 },
      { name: 'null', value: 0 },
      { name: 'Initio', value: 0 },
    ],
    daily_bcs: [
      {
        name: '7AM2M',
        sequence: [
          { date: '2020-10-05', value: 0.003 },
          { date: '2020-10-06', value: 0.003 },
        ],
      },
      {
        name: 'Aoremon',
        sequence: [
          { date: '2020-10-05', value: 0.006 },
          { date: '2020-10-06', value: 0.017 },
          { date: '2020-10-07', value: 0.006 },
          { date: '2020-10-08', value: 0.006 },
          { date: '2020-10-09', value: 0.014 },
          { date: '2020-10-10', value: 0.014 },
          { date: '2020-10-11', value: 0.011 },
        ],
      },
      {
        name: 'Aquasonic',
        sequence: [
          { date: '2020-10-05', value: 0.053 },
          { date: '2020-10-06', value: 0.056 },
          { date: '2020-10-07', value: 0.05 },
          { date: '2020-10-08', value: 0.064 },
          { date: '2020-10-09', value: 0.067 },
          { date: '2020-10-10', value: 0.058 },
          { date: '2020-10-11', value: 0.011 },
        ],
      },
      {
        name: 'Brand: Safcare',
        sequence: [{ date: '2020-10-05', value: 0.003 }],
      },
      {
        name: 'Dnsly',
        sequence: [
          { date: '2020-10-05', value: 0.006 },
          { date: '2020-10-06', value: 0.003 },
          { date: '2020-10-07', value: 0.006 },
          { date: '2020-10-08', value: 0.006 },
          { date: '2020-10-09', value: 0.006 },
          { date: '2020-10-10', value: 0.006 },
          { date: '2020-10-11', value: 0.006 },
        ],
      },
      {
        name: 'Fairywill',
        sequence: [
          { date: '2020-10-05', value: 0.012 },
          { date: '2020-10-06', value: 0.006 },
          { date: '2020-10-07', value: 0.011 },
          { date: '2020-10-08', value: 0.011 },
          { date: '2020-10-09', value: 0.017 },
          { date: '2020-10-10', value: 0.014 },
          { date: '2020-10-11', value: 0.006 },
        ],
      },
      {
        name: 'Initio',
        sequence: [{ date: '2020-10-05', value: 0.003 }],
      },
      {
        name: 'ITECHNIK',
        sequence: [
          { date: '2020-10-05', value: 0.003 },
          { date: '2020-10-06', value: 0.006 },
        ],
      },
      {
        name: 'Mailink',
        sequence: [{ date: '2020-10-05', value: 0.003 }],
      },
      {
        name: 'N/Q',
        sequence: [
          { date: '2020-10-05', value: 0.009 },
          { date: '2020-10-07', value: 0.003 },
        ],
      },
      {
        name: 'Oclean',
        sequence: [
          { date: '2020-10-05', value: 0.006 },
          { date: '2020-10-07', value: 0.003 },
          { date: '2020-10-09', value: 0.003 },
          { date: '2020-10-10', value: 0.003 },
          { date: '2020-10-11', value: 0.006 },
        ],
      },
      {
        name: 'oral-b',
        sequence: [
          { date: '2020-10-05', value: 0.019 },
          { date: '2020-10-06', value: 0.025 },
          { date: '2020-10-07', value: 0.008 },
          { date: '2020-10-08', value: 0.014 },
          { date: '2020-10-09', value: 0.014 },
          { date: '2020-10-10', value: 0.019 },
          { date: '2020-10-11', value: 0.011 },
        ],
      },
      {
        name: 'Philips',
        sequence: [
          { date: '2020-10-05', value: 0.022 },
          { date: '2020-10-06', value: 0.025 },
          { date: '2020-10-07', value: 0.019 },
          { date: '2020-10-08', value: 0.014 },
          { date: '2020-10-09', value: 0.022 },
          { date: '2020-10-10', value: 0.017 },
          { date: '2020-10-11', value: 0.006 },
        ],
      },
      {
        name: 'PHILIPS',
        sequence: [
          { date: '2020-10-05', value: 0.012 },
          { date: '2020-10-06', value: 0.014 },
          { date: '2020-10-07', value: 0.019 },
          { date: '2020-10-08', value: 0.022 },
          { date: '2020-10-09', value: 0.025 },
          { date: '2020-10-10', value: 0.025 },
          { date: '2020-10-11', value: 0.028 },
        ],
      },
      {
        name: 'Philips Sonicare',
        sequence: [
          { date: '2020-10-05', value: 0.764 },
          { date: '2020-10-06', value: 0.739 },
          { date: '2020-10-07', value: 0.789 },
          { date: '2020-10-08', value: 0.783 },
          { date: '2020-10-09', value: 0.739 },
          { date: '2020-10-10', value: 0.764 },
          { date: '2020-10-11', value: 0.833 },
        ],
      },
      {
        name: 'Phlips Sonicare',
        sequence: [
          { date: '2020-10-05', value: 0.019 },
          { date: '2020-10-06', value: 0.017 },
          { date: '2020-10-07', value: 0.017 },
          { date: '2020-10-08', value: 0.017 },
          { date: '2020-10-09', value: 0.017 },
          { date: '2020-10-10', value: 0.017 },
          { date: '2020-10-11', value: 0.017 },
        ],
      },
      {
        name: 'Sani',
        sequence: [
          { date: '2020-10-05', value: 0.006 },
          { date: '2020-10-06', value: 0.003 },
        ],
      },
      {
        name: 'Sboly',
        sequence: [
          { date: '2020-10-05', value: 0.006 },
          { date: '2020-10-06', value: 0.014 },
          { date: '2020-10-07', value: 0.008 },
          { date: '2020-10-08', value: 0.008 },
          { date: '2020-10-09', value: 0.011 },
          { date: '2020-10-10', value: 0.014 },
          { date: '2020-10-11', value: 0.006 },
        ],
      },
      {
        name: 'Sierra Clean',
        sequence: [
          { date: '2020-10-05', value: 0.012 },
          { date: '2020-10-06', value: 0.017 },
          { date: '2020-10-07', value: 0.017 },
          { date: '2020-10-08', value: 0.014 },
          { date: '2020-10-09', value: 0.017 },
          { date: '2020-10-10', value: 0.014 },
          { date: '2020-10-11', value: 0.011 },
        ],
      },
      {
        name: 'sZs',
        sequence: [
          { date: '2020-10-05', value: 0.019 },
          { date: '2020-10-06', value: 0.022 },
          { date: '2020-10-07', value: 0.011 },
          { date: '2020-10-08', value: 0.008 },
          { date: '2020-10-09', value: 0.011 },
          { date: '2020-10-10', value: 0.006 },
          { date: '2020-10-11', value: 0.006 },
        ],
      },
      {
        name: 'TRILINK',
        sequence: [{ date: '2020-10-05', value: 0.003 }],
      },
      {
        name: 'Vochigh',
        sequence: [{ date: '2020-10-05', value: 0.006 }],
      },
      { name: 'null', sequence: [{ date: '2020-10-05', value: 0.003 }] },
      {
        name: 'AWSL',
        sequence: [
          { date: '2020-10-06', value: 0.011 },
          { date: '2020-10-07', value: 0.017 },
          { date: '2020-10-08', value: 0.011 },
          { date: '2020-10-09', value: 0.008 },
          { date: '2020-10-10', value: 0.014 },
        ],
      },
      {
        name: 'Oral Basics',
        sequence: [{ date: '2020-10-06', value: 0.003 }],
      },
      {
        name: 'Philps',
        sequence: [
          { date: '2020-10-06', value: 0.006 },
          { date: '2020-10-08', value: 0.011 },
          { date: '2020-10-09', value: 0.003 },
          { date: '2020-10-11', value: 0.011 },
        ],
      },
      {
        name: 'Phlilps',
        sequence: [
          { date: '2020-10-06', value: 0.011 },
          { date: '2020-10-07', value: 0.003 },
          { date: '2020-10-11', value: 0.006 },
        ],
      },
      {
        name: 'SHENG',
        sequence: [
          { date: '2020-10-06', value: 0.003 },
          { date: '2020-10-07', value: 0.011 },
          { date: '2020-10-08', value: 0.006 },
          { date: '2020-10-09', value: 0.011 },
        ],
      },
      {
        name: 'Sonimesh',
        sequence: [
          { date: '2020-10-06', value: 0.003 },
          { date: '2020-10-09', value: 0.003 },
          { date: '2020-10-10', value: 0.006 },
          { date: '2020-10-11', value: 0.006 },
        ],
      },
      {
        name: 'Dailycare',
        sequence: [
          { date: '2020-10-07', value: 0.003 },
          { date: '2020-10-08', value: 0.003 },
        ],
      },
      {
        name: 'Senyum',
        sequence: [
          { date: '2020-10-08', value: 0.003 },
          { date: '2020-10-09', value: 0.003 },
        ],
      },
      {
        name: 'GOBEST',
        sequence: [
          { date: '2020-10-09', value: 0.003 },
          { date: '2020-10-10', value: 0.003 },
          { date: '2020-10-11', value: 0.006 },
        ],
      },
      {
        name: 'PULUOMASI',
        sequence: [
          { date: '2020-10-09', value: 0.006 },
          { date: '2020-10-10', value: 0.008 },
        ],
      },
      {
        name: 'Sonimama',
        sequence: [{ date: '2020-10-09', value: 0.003 }],
      },
      {
        name: 'jemyda',
        sequence: [{ date: '2020-10-11', value: 0.006 }],
      },
      {
        name: 'MOSPRO',
        sequence: [{ date: '2020-10-11', value: 0.006 }],
      },
      {
        name: 'Neurora',
        sequence: [{ date: '2020-10-11', value: 0.006 }],
      },
    ],
  },
  paid: {
    bcs: [
      { name: 'Philips Sonicare', value: 0.509 },
      { name: 'Aquasonic', value: 0.145 },
      { name: 'Sierra Clean', value: 0.074 },
      { name: 'PHILIPS', value: 0.056 },
      { name: 'AWSL', value: 0.048 },
      { name: 'Philps', value: 0.019 },
      { name: 'oral-b', value: 0.017 },
      { name: 'SHENG', value: 0.017 },
      { name: 'sZs', value: 0.015 },
      { name: 'Phlilps', value: 0.013 },
      { name: 'Oclean', value: 0.011 },
      { name: 'PULUOMASI', value: 0.011 },
      { name: 'N/Q', value: 0.009 },
      { name: 'Sani', value: 0.006 },
      { name: 'GOBEST', value: 0.006 },
      { name: 'Fairywill', value: 0.006 },
      { name: 'Vochigh', value: 0.004 },
      { name: 'Sboly', value: 0.004 },
      { name: 'Senyum', value: 0.004 },
      { name: 'Neurora', value: 0.002 },
      { name: 'Sonimesh', value: 0.002 },
      { name: 'TRILINK', value: 0.002 },
      { name: 'Initio', value: 0.002 },
      { name: 'Brand: Safcare', value: 0.002 },
      { name: 'Sonimama', value: 0.002 },
      { name: 'jemyda', value: 0.002 },
      { name: 'Mailink', value: 0.002 },
      { name: 'MOSPRO', value: 0.002 },
      { name: 'Oral Basics', value: 0.002 },
      { name: 'null', value: 0.002 },
      { name: 'Dailycare', value: 0 },
      { name: '7AM2M', value: 0 },
      { name: 'Philips', value: 0 },
      { name: 'ITECHNIK', value: 0 },
      { name: 'Aoremon', value: 0 },
      { name: 'Phlips Sonicare', value: 0 },
      { name: 'Dnsly', value: 0 },
    ],
    daily_bcs: [
      {
        name: 'Aquasonic',
        sequence: [
          { date: '2020-10-05', value: 0.136 },
          { date: '2020-10-06', value: 0.139 },
          { date: '2020-10-07', value: 0.153 },
          { date: '2020-10-08', value: 0.181 },
          { date: '2020-10-09', value: 0.167 },
          { date: '2020-10-10', value: 0.153 },
          { date: '2020-10-11', value: 0.028 },
        ],
      },
      {
        name: 'Brand: Safcare',
        sequence: [{ date: '2020-10-05', value: 0.015 }],
      },
      {
        name: 'Initio',
        sequence: [{ date: '2020-10-05', value: 0.015 }],
      },
      {
        name: 'Mailink',
        sequence: [{ date: '2020-10-05', value: 0.015 }],
      },
      {
        name: 'N/Q',
        sequence: [
          { date: '2020-10-05', value: 0.045 },
          { date: '2020-10-07', value: 0.014 },
        ],
      },
      {
        name: 'Oclean',
        sequence: [
          { date: '2020-10-05', value: 0.03 },
          { date: '2020-10-07', value: 0.014 },
          { date: '2020-10-09', value: 0.014 },
          { date: '2020-10-11', value: 0.028 },
        ],
      },
      {
        name: 'oral-b',
        sequence: [
          { date: '2020-10-05', value: 0.03 },
          { date: '2020-10-08', value: 0.042 },
          { date: '2020-10-09', value: 0.014 },
          { date: '2020-10-10', value: 0.028 },
        ],
      },
      {
        name: 'PHILIPS',
        sequence: [
          { date: '2020-10-05', value: 0.03 },
          { date: '2020-10-06', value: 0.028 },
          { date: '2020-10-07', value: 0.056 },
          { date: '2020-10-08', value: 0.069 },
          { date: '2020-10-09', value: 0.069 },
          { date: '2020-10-10', value: 0.069 },
          { date: '2020-10-11', value: 0.083 },
        ],
      },
      {
        name: 'Philips Sonicare',
        sequence: [
          { date: '2020-10-05', value: 0.485 },
          { date: '2020-10-06', value: 0.542 },
          { date: '2020-10-07', value: 0.5 },
          { date: '2020-10-08', value: 0.5 },
          { date: '2020-10-09', value: 0.472 },
          { date: '2020-10-10', value: 0.528 },
          { date: '2020-10-11', value: 0.556 },
        ],
      },
      {
        name: 'Sani',
        sequence: [
          { date: '2020-10-05', value: 0.03 },
          { date: '2020-10-06', value: 0.014 },
        ],
      },
      {
        name: 'Sierra Clean',
        sequence: [
          { date: '2020-10-05', value: 0.061 },
          { date: '2020-10-06', value: 0.083 },
          { date: '2020-10-07', value: 0.083 },
          { date: '2020-10-08', value: 0.069 },
          { date: '2020-10-09', value: 0.083 },
          { date: '2020-10-10', value: 0.069 },
          { date: '2020-10-11', value: 0.056 },
        ],
      },
      {
        name: 'sZs',
        sequence: [
          { date: '2020-10-05', value: 0.045 },
          { date: '2020-10-06', value: 0.028 },
          { date: '2020-10-07', value: 0.014 },
          { date: '2020-10-11', value: 0.028 },
        ],
      },
      {
        name: 'TRILINK',
        sequence: [{ date: '2020-10-05', value: 0.015 }],
      },
      {
        name: 'Vochigh',
        sequence: [{ date: '2020-10-05', value: 0.03 }],
      },
      { name: 'null', sequence: [{ date: '2020-10-05', value: 0.015 }] },
      {
        name: 'AWSL',
        sequence: [
          { date: '2020-10-06', value: 0.056 },
          { date: '2020-10-07', value: 0.083 },
          { date: '2020-10-08', value: 0.056 },
          { date: '2020-10-09', value: 0.042 },
          { date: '2020-10-10', value: 0.069 },
        ],
      },
      {
        name: 'Oral Basics',
        sequence: [{ date: '2020-10-06', value: 0.014 }],
      },
      {
        name: 'Philps',
        sequence: [
          { date: '2020-10-06', value: 0.028 },
          { date: '2020-10-08', value: 0.056 },
          { date: '2020-10-09', value: 0.014 },
          { date: '2020-10-11', value: 0.056 },
        ],
      },
      {
        name: 'Phlilps',
        sequence: [
          { date: '2020-10-06', value: 0.056 },
          { date: '2020-10-07', value: 0.014 },
          { date: '2020-10-11', value: 0.028 },
        ],
      },
      {
        name: 'Sonimesh',
        sequence: [{ date: '2020-10-06', value: 0.014 }],
      },
      {
        name: 'Fairywill',
        sequence: [
          { date: '2020-10-07', value: 0.014 },
          { date: '2020-10-10', value: 0.014 },
          { date: '2020-10-11', value: 0.028 },
        ],
      },
      {
        name: 'SHENG',
        sequence: [
          { date: '2020-10-07', value: 0.056 },
          { date: '2020-10-08', value: 0.014 },
          { date: '2020-10-09', value: 0.042 },
        ],
      },
      {
        name: 'Senyum',
        sequence: [
          { date: '2020-10-08', value: 0.014 },
          { date: '2020-10-09', value: 0.014 },
        ],
      },
      {
        name: 'GOBEST',
        sequence: [
          { date: '2020-10-09', value: 0.014 },
          { date: '2020-10-10', value: 0.014 },
          { date: '2020-10-11', value: 0.028 },
        ],
      },
      {
        name: 'PULUOMASI',
        sequence: [
          { date: '2020-10-09', value: 0.028 },
          { date: '2020-10-10', value: 0.042 },
        ],
      },
      {
        name: 'Sboly',
        sequence: [
          { date: '2020-10-09', value: 0.014 },
          { date: '2020-10-10', value: 0.014 },
        ],
      },
      {
        name: 'Sonimama',
        sequence: [{ date: '2020-10-09', value: 0.014 }],
      },
      {
        name: 'jemyda',
        sequence: [{ date: '2020-10-11', value: 0.028 }],
      },
      {
        name: 'MOSPRO',
        sequence: [{ date: '2020-10-11', value: 0.028 }],
      },
      {
        name: 'Neurora',
        sequence: [{ date: '2020-10-11', value: 0.028 }],
      },
    ],
  },
  organic: {
    bcs: [
      { name: 'Philips Sonicare', value: 0.834 },
      { name: 'Aquasonic', value: 0.032 },
      { name: 'Philips', value: 0.023 },
      { name: 'Phlips Sonicare', value: 0.021 },
      { name: 'oral-b', value: 0.016 },
      { name: 'Fairywill', value: 0.013 },
      { name: 'Aoremon', value: 0.013 },
      { name: 'sZs', value: 0.011 },
      { name: 'Sboly', value: 0.011 },
      { name: 'PHILIPS', value: 0.011 },
      { name: 'Dnsly', value: 0.007 },
      { name: 'Sonimesh', value: 0.002 },
      { name: 'ITECHNIK', value: 0.002 },
      { name: 'SHENG', value: 0.002 },
      { name: '7AM2M', value: 0.001 },
      { name: 'Dailycare', value: 0.001 },
      { name: 'Oclean', value: 0.001 },
      { name: 'Sierra Clean', value: 0 },
      { name: 'Mailink', value: 0 },
      { name: 'Neurora', value: 0 },
      { name: 'Initio', value: 0 },
      { name: 'Sani', value: 0 },
      { name: 'Brand: Safcare', value: 0 },
      { name: 'TRILINK', value: 0 },
      { name: 'Senyum', value: 0 },
      { name: 'null', value: 0 },
      { name: 'GOBEST', value: 0 },
      { name: 'Oral Basics', value: 0 },
      { name: 'Phlilps', value: 0 },
      { name: 'MOSPRO', value: 0 },
      { name: 'jemyda', value: 0 },
      { name: 'Sonimama', value: 0 },
      { name: 'PULUOMASI', value: 0 },
      { name: 'N/Q', value: 0 },
      { name: 'Philps', value: 0 },
      { name: 'AWSL', value: 0 },
      { name: 'Vochigh', value: 0 },
    ],
    daily_bcs: [
      {
        name: '7AM2M',
        sequence: [
          { date: '2020-10-05', value: 0.004 },
          { date: '2020-10-06', value: 0.003 },
        ],
      },
      {
        name: 'Aoremon',
        sequence: [
          { date: '2020-10-05', value: 0.008 },
          { date: '2020-10-06', value: 0.021 },
          { date: '2020-10-07', value: 0.007 },
          { date: '2020-10-08', value: 0.007 },
          { date: '2020-10-09', value: 0.017 },
          { date: '2020-10-10', value: 0.017 },
          { date: '2020-10-11', value: 0.014 },
        ],
      },
      {
        name: 'Aquasonic',
        sequence: [
          { date: '2020-10-05', value: 0.031 },
          { date: '2020-10-06', value: 0.035 },
          { date: '2020-10-07', value: 0.024 },
          { date: '2020-10-08', value: 0.035 },
          { date: '2020-10-09', value: 0.042 },
          { date: '2020-10-10', value: 0.035 },
          { date: '2020-10-11', value: 0.007 },
        ],
      },
      {
        name: 'Dnsly',
        sequence: [
          { date: '2020-10-05', value: 0.008 },
          { date: '2020-10-06', value: 0.003 },
          { date: '2020-10-07', value: 0.007 },
          { date: '2020-10-08', value: 0.007 },
          { date: '2020-10-09', value: 0.007 },
          { date: '2020-10-10', value: 0.007 },
          { date: '2020-10-11', value: 0.007 },
        ],
      },
      {
        name: 'Fairywill',
        sequence: [
          { date: '2020-10-05', value: 0.016 },
          { date: '2020-10-06', value: 0.007 },
          { date: '2020-10-07', value: 0.01 },
          { date: '2020-10-08', value: 0.014 },
          { date: '2020-10-09', value: 0.021 },
          { date: '2020-10-10', value: 0.014 },
        ],
      },
      {
        name: 'ITECHNIK',
        sequence: [
          { date: '2020-10-05', value: 0.004 },
          { date: '2020-10-06', value: 0.007 },
        ],
      },
      {
        name: 'oral-b',
        sequence: [
          { date: '2020-10-05', value: 0.016 },
          { date: '2020-10-06', value: 0.031 },
          { date: '2020-10-07', value: 0.01 },
          { date: '2020-10-08', value: 0.007 },
          { date: '2020-10-09', value: 0.014 },
          { date: '2020-10-10', value: 0.017 },
          { date: '2020-10-11', value: 0.014 },
        ],
      },
      {
        name: 'Philips',
        sequence: [
          { date: '2020-10-05', value: 0.027 },
          { date: '2020-10-06', value: 0.031 },
          { date: '2020-10-07', value: 0.024 },
          { date: '2020-10-08', value: 0.017 },
          { date: '2020-10-09', value: 0.028 },
          { date: '2020-10-10', value: 0.021 },
          { date: '2020-10-11', value: 0.007 },
        ],
      },
      {
        name: 'PHILIPS',
        sequence: [
          { date: '2020-10-05', value: 0.008 },
          { date: '2020-10-06', value: 0.01 },
          { date: '2020-10-07', value: 0.01 },
          { date: '2020-10-08', value: 0.01 },
          { date: '2020-10-09', value: 0.014 },
          { date: '2020-10-10', value: 0.014 },
          { date: '2020-10-11', value: 0.014 },
        ],
      },
      {
        name: 'Philips Sonicare',
        sequence: [
          { date: '2020-10-05', value: 0.836 },
          { date: '2020-10-06', value: 0.788 },
          { date: '2020-10-07', value: 0.861 },
          { date: '2020-10-08', value: 0.854 },
          { date: '2020-10-09', value: 0.806 },
          { date: '2020-10-10', value: 0.823 },
          { date: '2020-10-11', value: 0.903 },
        ],
      },
      {
        name: 'Phlips Sonicare',
        sequence: [
          { date: '2020-10-05', value: 0.023 },
          { date: '2020-10-06', value: 0.021 },
          { date: '2020-10-07', value: 0.021 },
          { date: '2020-10-08', value: 0.021 },
          { date: '2020-10-09', value: 0.021 },
          { date: '2020-10-10', value: 0.021 },
          { date: '2020-10-11', value: 0.021 },
        ],
      },
      {
        name: 'Sboly',
        sequence: [
          { date: '2020-10-05', value: 0.008 },
          { date: '2020-10-06', value: 0.017 },
          { date: '2020-10-07', value: 0.01 },
          { date: '2020-10-08', value: 0.01 },
          { date: '2020-10-09', value: 0.01 },
          { date: '2020-10-10', value: 0.014 },
          { date: '2020-10-11', value: 0.007 },
        ],
      },
      {
        name: 'sZs',
        sequence: [
          { date: '2020-10-05', value: 0.012 },
          { date: '2020-10-06', value: 0.021 },
          { date: '2020-10-07', value: 0.01 },
          { date: '2020-10-08', value: 0.01 },
          { date: '2020-10-09', value: 0.014 },
          { date: '2020-10-10', value: 0.007 },
        ],
      },
      {
        name: 'SHENG',
        sequence: [
          { date: '2020-10-06', value: 0.003 },
          { date: '2020-10-08', value: 0.003 },
          { date: '2020-10-09', value: 0.003 },
        ],
      },
      {
        name: 'Dailycare',
        sequence: [
          { date: '2020-10-07', value: 0.003 },
          { date: '2020-10-08', value: 0.003 },
        ],
      },
      {
        name: 'Sonimesh',
        sequence: [
          { date: '2020-10-09', value: 0.003 },
          { date: '2020-10-10', value: 0.007 },
          { date: '2020-10-11', value: 0.007 },
        ],
      },
      {
        name: 'Oclean',
        sequence: [{ date: '2020-10-10', value: 0.003 }],
      },
    ],
  },
  n_days_of_data: 7,
  n_days_in_time_range: 7,
  dates_of_data: [
    '2020-10-05',
    '2020-10-06',
    '2020-10-07',
    '2020-10-08',
    '2020-10-09',
    '2020-10-10',
    '2020-10-11',
  ],
  timezone: 'US, PST',
  show_last_timing: true,
}));
