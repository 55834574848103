import { Factory } from 'fishery';
import {
  CampaignInfoWithAdGroups,
  CampaignType,
  TargetSegments,
  TargetingType,
} from '../../types/KeywordAction';

export const EligibleAdGroupsResponse = Factory.define<
  CampaignInfoWithAdGroups[]
>(() => [
  {
    id: 'a218a111-a07b-511c-a7e2-4788dc613300',
    name: "AB We Don't Talk About Bruno T-Shirt|B0B358KRY8|Manual|SP-KW|TM",
    campaignType: CampaignType.SponsoredProducts,
    targetingType: TargetingType.Manual,
    selectedAdGroupIds: ['5943c510-d156-56dc-aed6-368bebedd815'],
    adGroups: [
      {
        id: '5943c510-d156-56dc-aed6-368bebedd815',
        name: "ABD We Don't Talk About Bruno T-Shirt|B0B358KRY8|Manual|SP-KW|TM",
        campaign: {
          id: 'a218a111-a07b-511c-a7e2-4788dc613300',
          name: "AB We Don't Talk About Bruno T-Shirt|B0B358KRY8|Manual|SP-KW|TM",
          campaignType: CampaignType.SponsoredProducts,
          targetingType: TargetingType.Manual,
        },
        targetSegments: [
          TargetSegments.Generic,
          TargetSegments.Competitor,
          TargetSegments.Brand,
        ],
      },
      {
        id: '5933c510-d156-56dc-aed6-368bebedd815',
        name: "ABD We Don't Talk About Bruno T-Shirt|B0B358KRY8|Manual|SP-KW|TM - 2",
        campaign: {
          id: 'a218a111-a07b-511c-a7e2-4788dc613300',
          name: "AB We Don't Talk About Bruno T-Shirt|B0B358KRY8|Manual|SP-KW|TM",
          campaignType: CampaignType.SponsoredProducts,
          targetingType: TargetingType.Manual,
        },
        targetSegments: [TargetSegments.Brand],
      },
    ],
  },
  {
    id: '2eff7201-12fd-5bc5-aab5-b316ada3379f',
    name: 'Always Tired Novelty T Shirt Sarcastic Funny Joke|B0B3CJNMLD|Competitor|SP-KW|TM',
    campaignType: CampaignType.SponsoredProducts,
    targetingType: TargetingType.Manual,
    adGroups: [
      {
        id: '9e54a906-fdbf-5d31-82f4-fc8aa7c19238',
        campaign: {
          id: '2eff7201-12fd-5bc5-aab5-b316ada3379f',
          name: 'Always Tired Novelty T Shirt Sarcastic Funny Joke|B0B3CJNMLD|Competitor|SP-KW|TM',
          campaignType: CampaignType.SponsoredProducts,
          targetingType: TargetingType.Manual,
        },
        name: 'Always Tired Novelty T Shirt Sarcastic Funny Joke|B0B3CJNMLD|Competitor|SP-KW|TM',
        targetSegments: [TargetSegments.Brand],
      },
    ],
  },
]);
