import { DateTime } from 'luxon';
import { ReactNode, RefObject } from 'react';

import { DateRange } from '../../../../lib/clients/types';
import { StringMap } from '../../../../lib/types';
import {
  LineChartData,
  SearchTermsData,
} from '../../../../lib/types/MISharedTypes';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { ButtonState } from '@teikametrics/tm-design-system';
import { DatePicker } from '../../components/datePicker';
import { MIApiClient } from '../../../../lib/clients/MIApiClient';
export interface ReportingHeaderProps {
  readonly totalTermsCount: number;
  readonly queryParams: ReportingQueryParams;
  readonly searchTerms: SearchTermsData[];
  readonly platforms: PlatformData[];
  readonly setQueryParams: (queryParams: ReportingQueryParams) => void;
  readonly runQueryButtonState: ButtonState;
  readonly exportButtonState: ButtonState;
  readonly exportBulkButtonState: ButtonState;
  readonly resetButtonState: ButtonState;
  readonly setRunQueryButtonState: (state: ButtonState) => void;
  readonly setResetButtonState: (state: ButtonState) => void;
  readonly setExportButtonState: (state: ButtonState) => void;
  readonly setExportBulkButtonState: (state: ButtonState) => void;
  readonly fetchedQueryParams: ReportingQueryParams;
  readonly setFetchedQueryParams: (queryParams: ReportingQueryParams) => void;
  readonly defaultQueryParams: ReportingQueryParams;
  readonly exportBcsZip: (isSingleExport: boolean) => void;
  readonly openBulkExportSelectionModal: () => void;
  readonly platformChanged: ReportingFilterStatus;
  readonly searchTermChanged: ReportingFilterStatus;
  readonly dateRangeChanged: ReportingFilterStatus;
  readonly setPlatformChanged: (platformChanged: ReportingFilterStatus) => void;
  readonly setSearchTermChanged: (
    searchTermChanged: ReportingFilterStatus
  ) => void;
  readonly setDateRangeChanged: (
    dateRangeChanged: ReportingFilterStatus
  ) => void;
  readonly updateSearchTermsList: (platform?: PlatformData) => void;
  readonly hasMerchantData: boolean;
  readonly aoApiClient: AOApiClient;
  readonly miApiClient: MIApiClient;
  readonly ropId: string;
  readonly userId: string;
  readonly showCampaigns: boolean;
  readonly shouldRunQueryInBackground: boolean;
  readonly showImprovedReporting: boolean;
}

export interface ReportingQueryParams {
  readonly platform?: PlatformData;
  readonly searchTerm?: SearchTermsData;
  readonly dateRange: DateRange;
}

export interface PlatformSelectProps {
  readonly initialValue?: PlatformData;
  readonly platforms: PlatformData[];
  readonly queryParams: ReportingQueryParams;
  readonly fetchedQueryParams: ReportingQueryParams;
  readonly setQueryParams: (queryParams: ReportingQueryParams) => void;
  readonly toggleResetRunqueryButtonState: (
    currentProps: ReportingQueryParams
  ) => void;
  readonly platformChanged: ReportingFilterStatus;
  readonly updateSearchTermsList: (platform?: PlatformData) => void;
}

export interface SearchTermSelectProps {
  readonly initialValue?: SearchTermsData;
  readonly searchTerms: SearchTermsData[];
  readonly queryParams: ReportingQueryParams;
  readonly fetchedQueryParams: ReportingQueryParams;
  readonly setQueryParams: (queryParams: ReportingQueryParams) => void;
  readonly toggleResetRunqueryButtonState: (
    currentProps: ReportingQueryParams
  ) => void;
  readonly searchTermChanged: ReportingFilterStatus;
  readonly hasMerchantData: boolean;
}

export interface DatePickerProps {
  readonly queryParams: ReportingQueryParams;
  readonly setQueryParams: (queryParams: ReportingQueryParams) => void;
  readonly fetchedQueryParams: ReportingQueryParams;
  readonly from: DateTime;
  readonly to: DateTime;
  readonly toggleResetRunqueryButtonState: (
    currentProps: ReportingQueryParams
  ) => void;
  readonly dateRangeChanged: ReportingFilterStatus;
  readonly datePickerRef: RefObject<DatePicker>;
  readonly showImprovedReporting: boolean;
}

export interface PlatformData {
  readonly id: number;
  readonly name: string;
  readonly active: boolean;
}

export interface IconState {
  readonly enabledIcon: JSX.Element;
  readonly disabledIcon: JSX.Element;
}

export interface PlatformIconProps {
  readonly platform: string;
  readonly iconState: IconState;
}

export const AVERAGE_PRODUCT_RANK_TABLE = 'AVERAGE_PRODUCT_RANK_TABLE';
export const BRAND_COVERAGE_ON_SEARCH_TABLE = 'BRAND_COVERAGE_ON_SEARCH_TABLE';

export interface ReportingRequestParams {
  readonly rop_id: string;
  readonly search_term: string;
  readonly locale: string;
  readonly platform: string;
  readonly start_date: string;
  readonly end_date: string;
  readonly includeUnIndentifiedBrands: string;
}

export enum HelpLinkTypes {
  WarningMessage = 'WarningMessage',
  SettingsPageCard = 'SettingsPageCard',
  UploadTerms = 'UploadTerms',
}

export enum BackgroundJobEventTypes {
  QUEUED = 'QUEUED',
  PROCESSED = 'PROCESSED',
  RETRY = 'RETRY',
  FAILED = 'FAILED',
  EMAIL_FAILED = 'EMAIL_FAILED',
}

export const MIHelpLinks: Record<HelpLinkTypes, string> = {
  [HelpLinkTypes.WarningMessage]:
    'https://intercom.help/flywheel-20/en/articles/4925105-market-intelligence-faq#h_1e34bf8382',
  [HelpLinkTypes.SettingsPageCard]:
    'https://intercom.help/flywheel-20/en/collections/2742287-market-intelligence',
  [HelpLinkTypes.UploadTerms]:
    'https://intercom.help/flywheel-20/en/articles/4931480-getting-started-add-terms-to-track',
};
export const EXPORT_ZIP_BCS_NOTIFICATION = 'EXPORT_ZIP_BCS_NOTIFICATION';

export enum ReportingTableTabsIndex {
  initialTabIndex = -1,
  BrandCoverageOnSearch = 0,
  AverageProductRank = 1,
}

export enum ReportingTabsIndex {
  BCS = '0',
  History = '1',
}

export const EXPORT_ZIP_BCS_FILENAME_HEADER = 'bcs_zip_filename';

export const EXPORT_ZIP_BCS_DEFAULT_FILENAME = 'BCS-report.zip';

export interface AxiosResponseFile {
  readonly data: Blob;
  readonly headers: StringMap<string>;
}

export const DEFAULT_SELECTED_SEARCH_TERM_INDEX: number = 0;
export const DEFAULT_SELECTED_PLATFORM_INDEX: number = 0;
export const DEFAULT_SELECTED_LOCALE_INDEX: number = 0;
export const SEARCH_TERM_KEY_VALUE = 'search_term';

export enum ReportingFilterStatus {
  NoChange = 'NoChange',
  Changed = 'Changed',
  Error = 'Error',
}

export interface RowCellBcsChartProps {
  readonly numOfOtherBrands?: string;
  readonly isVisible: boolean;
  readonly color: string;
  readonly name: string;
  readonly value: ReactNode;
}

export interface ScrollAndViewBCSTable {
  readonly scrollAndViewBCSTable?: () => void;
}

export interface ChartMethods {
  readonly onScrollInCell: (brandName: string) => void;
  readonly onScrollOutOfTable: () => void;
}

export type RowCellElementTopBrandsProps = RowCellBcsChartProps &
  ScrollAndViewBCSTable &
  ChartMethods;

export interface HeaderDataTopBrandsProps {
  readonly brandName: string;
  readonly bcsPercentage: string;
}

export interface SeriesDataProps {
  readonly name?: string;
  readonly xAxis: number;
  readonly type: string;
  readonly data: Array<number | null>;
  readonly color?: string;
  readonly marker: MarkerDataProps;
  readonly connectNulls: boolean;
}

export interface MarkerDataProps {
  readonly symbol: string;
  readonly fillColor?: string;
  readonly radius: number;
  readonly lineWidth: number;
  readonly enabled?: boolean;
}

export type BCSTabType = 'total' | 'organic' | 'paid';

export enum BCSTabTypeEnum {
  Total = 'total',
  Organic = 'organic',
  Paid = 'paid',
}

export interface GenerateSeriesDataProps {
  readonly chartData: LineChartData[];
  readonly requiredBrands: string[];
  readonly colorMappingOfBrands: {
    [key: string]: string;
  };
  readonly xAxisLabels: string[];
}

export enum YAxisTickerLevels {
  TickerLevelFive = 5,
  TickerLevelTen = 10,
  TickerLevelTwenty = 20,
}

export enum UrlError {
  PLATFORM = 'platform',
  TERM = 'term',
  ACCOUNT_ID = 'account_id',
}

export enum ReportError {
  STATUS = 'STATUS',
  PROCESSING = 'PROCESSING',
}
