import './styles.scss';

import { fromNullable } from 'fp-ts/lib/Option';
import React, { PropsWithChildren } from 'react';

import { SortingIcon, SortingType } from '@teikametrics/tm-design-system';

import { ReactComponent as SortArrowIcon } from '../../img/icons_deprecated/sort-arrow.svg';
import { Sort, SortOrder } from '../../lib/types/Sort';
import { SortableTableHeaderProps } from './types';

const DEFAULT_CLASS_NAME = 'sortable-header';
const ASC_CLASS_NAME = 'sorted-asc';
const DESC_CLASS_NAME = 'sorted-desc';
const ASC_DATA_ID_NAME = 'asc';
const DESC_DATA_ID_NAME = 'desc';

type UnSorted = 'UnSorted';
const UNSORTED: UnSorted = 'UnSorted';

const getSortOrder = (
  columnName: string,
  maybeCurrentSort?: Sort
): SortingType => {
  const maybeSortOrder = fromNullable(maybeCurrentSort)
    .filter((currentSort) => currentSort.column === columnName)
    .map((currentSort) => currentSort.direction)
    .map((sortOrder) =>
      sortOrder === SortOrder.Asc
        ? SortingType.Ascending
        : SortingType.Descending
    )
    .toUndefined();
  return maybeSortOrder || SortingType.None;
};

const getSortedClassName = (sortOrder: SortOrder): string => {
  switch (sortOrder) {
    case SortOrder.Asc:
      return ASC_CLASS_NAME;
    case SortOrder.Desc:
      return DESC_CLASS_NAME;
  }
};
const getSortedDateId = (sortOrder: SortOrder): string => {
  switch (sortOrder) {
    case SortOrder.Asc:
      return ASC_DATA_ID_NAME;
    case SortOrder.Desc:
      return DESC_DATA_ID_NAME;
    default:
      return UNSORTED;
  }
};

const getClassNames = (columnName: string, maybeCurrentSort?: Sort): string => {
  const sortedClassName = fromNullable(maybeCurrentSort)
    .filter((currentSort) => currentSort.column === columnName)
    .map((currentSort) => currentSort.direction)
    .map(getSortedClassName)
    .getOrElse('');

  return `${DEFAULT_CLASS_NAME} ${sortedClassName}`;
};

const getDataIdNames = (
  columnName: string,
  maybeCurrentSort?: Sort
): string => {
  const dataIdNames = fromNullable(maybeCurrentSort)
    .filter((currentSort) => currentSort.column === columnName)
    .map((currentSort) => currentSort.direction)
    .map(getSortedDateId)
    .getOrElse(UNSORTED);

  return dataIdNames;
};

const getNextSortForColumn = (
  columnName: string,
  maybeCurrentSort?: Sort
): Sort | UnSorted => ({
  column: columnName,
  direction:
    maybeCurrentSort?.column === columnName &&
    maybeCurrentSort?.direction === SortOrder.Desc
      ? SortOrder.Asc
      : SortOrder.Desc,
});

export const SortableHeaderContent: React.FC<
  {
    applySort: () => void;
    currentSort?: Sort;
    columnName: string;
  } & PropsWithChildren
> = ({ children, applySort, columnName, currentSort }) => (
  <div key={columnName} className={getClassNames(columnName, currentSort)}>
    <div className="sortable-header__container" onClick={applySort}>
      <span className="sort-icon">
        <SortArrowIcon />
      </span>
      {children}
    </div>
  </div>
);
SortableHeaderContent.displayName = 'SortableHeaderContent';

export const FlywheelTableSortableHeaderContent: React.FC<
  {
    applySort: () => void;
    currentSort?: Sort;
    columnName: string;
    tableId?: string;
  } & PropsWithChildren
> = ({ children, applySort, columnName, currentSort, tableId }) => (
  <div
    key={columnName}
    className={getClassNames(columnName, currentSort)}
    data-test-id={`${tableId}_${columnName}_${getDataIdNames(
      columnName,
      currentSort
    )}`}
  >
    <div className="sortable-header__container" onClick={applySort}>
      {children}
      <div className="pl-8">
        <SortingIcon sortingType={getSortOrder(columnName, currentSort)} />
      </div>
    </div>
  </div>
);
FlywheelTableSortableHeaderContent.displayName =
  'FlywheelTableSortableHeaderContent';

export const SortableTableHeader: React.FC<
  SortableTableHeaderProps & PropsWithChildren
> = ({ columnName, currentSort, applySort, removeSort, children }) => {
  const nextSortForColumn = getNextSortForColumn(columnName, currentSort);

  const applySortHandler = () => {
    if (nextSortForColumn === UNSORTED) {
      removeSort(columnName);
    } else {
      applySort(nextSortForColumn);
    }
  };

  return (
    <SortableHeaderContent
      columnName={columnName}
      currentSort={currentSort}
      applySort={applySortHandler}
    >
      {children}
    </SortableHeaderContent>
  );
};
SortableTableHeader.displayName = 'SortableTableHeader';

export const FlywheelTableSortableTableHeaderComponent: React.FC<
  SortableTableHeaderProps & PropsWithChildren
> = ({ columnName, currentSort, applySort, removeSort, children, tableId }) => {
  const nextSortForColumn = getNextSortForColumn(columnName, currentSort);
  const applySortHandler = () => {
    if (nextSortForColumn === UNSORTED) {
      removeSort(columnName);
    } else {
      applySort(nextSortForColumn);
    }
  };

  return (
    <FlywheelTableSortableHeaderContent
      columnName={columnName}
      currentSort={currentSort}
      applySort={applySortHandler}
      tableId={tableId}
    >
      {children}
    </FlywheelTableSortableHeaderContent>
  );
};
FlywheelTableSortableTableHeaderComponent.displayName =
  'FlywheelTableSortableTableHeaderComponent';
