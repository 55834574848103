import './collapsableHeaderStyles.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  PortaledTooltip,
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
  TableHeaderGroupWrapper,
} from '@teikametrics/tm-design-system';

import { ReactComponent as ContractIcon } from '../../img/icons_deprecated/contract.svg';
import { ReactComponent as ExpandIcon } from '../../img/icons_deprecated/expand.svg';
import I18nKey from '../../lib/types/I18nKey';

export enum ColumnGroupingState {
  Contracted = 'Contracted',
  Expanded = 'Expanded',
}

const COLUMN_GROUPING_ICONS: Readonly<
  Record<ColumnGroupingState, JSX.Element>
> = {
  [ColumnGroupingState.Contracted]: <ExpandIcon />,
  [ColumnGroupingState.Expanded]: <ContractIcon />,
};

const CollapsableHeader: React.FC<
  Readonly<{
    i18nKey: I18nKey;
    onToggle: () => void;
    state: ColumnGroupingState;
    separator?: JSX.Element;
    toolTipContent?: string | JSX.Element;
    dataTestId?: string;
  }>
> = ({ i18nKey, onToggle, separator, state, toolTipContent, dataTestId }) => {
  const headerText = (
    <span className="fw-table__collapsable-header--text">
      <FormattedMessage id={i18nKey} values={{ separator }} />
    </span>
  );

  return (
    <div className="fw-table__collapsable-header">
      {toolTipContent ? (
        <PortaledTooltip
          position={{
            placement: PortaledTooltipPlacement.Bottom,
          }}
          theme={PortaledTooltipTheme.Dark}
          content={toolTipContent}
          className="fw-table__collapsable-header--tooltip"
          dataTestId={`${dataTestId}_collapsableHeader`}
        >
          <TableHeaderGroupWrapper title={headerText} />
        </PortaledTooltip>
      ) : (
        <TableHeaderGroupWrapper title={headerText} />
      )}
      <span className="fw-table__column-expander" onClick={onToggle}>
        {COLUMN_GROUPING_ICONS[state]}
      </span>
    </div>
  );
};
CollapsableHeader.displayName = 'CollapsableHeader';
export default CollapsableHeader;
