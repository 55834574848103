import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeNumericInputColumn,
  makePercentColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  AdGroupStatus,
  AdLevel,
  AdType,
  AiBiddingValues,
  DetailAdGroup,
  FlywheelSalesChannel,
  TeikaSettingsAdgroup,
} from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getAutomationDisabledForLegacyCampaignTooltipMessage,
  getAutomationDisabledTooltipMessage,
  getOrDefaultIsBiddableFlag,
  getTooltipContentForArchivedEntity,
  isAdGroupStatusArchived,
  isCampaignAdFormatEqualToVideo,
  isCampaignCostTypeVcpm,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isInputValueNumber,
  isValidMacsTarget,
} from '../utils';
import { isAutomationDisabledForMerchant } from './utils';

const getControlledTooltipState = (
  isAdGroupArchived: boolean,
  focussed: boolean,
  numericInputState: NumericInputState
) => {
  if (isAdGroupArchived) {
    return ControlledTooltip.None;
  }

  return focussed && numericInputState === NumericInputState.Error
    ? ControlledTooltip.Show
    : ControlledTooltip.Hide;
};

const getExtraValidationsForMacsTarget = (
  flywheelSalesChannel: FlywheelSalesChannel,
  value: string,
  maxMacsTargetValue: number | undefined,
  adGroupStatus: AdGroupStatus | undefined,
  adGroupDetails: DetailAdGroup,
  isValidSettings: boolean
) => {
  if (flywheelSalesChannel === FlywheelSalesChannel.Amazon) {
    return () =>
      !isCurrentValueGreaterThanRequiredMaxValue(maxMacsTargetValue, value) &&
      !isAdGroupStatusArchived(adGroupStatus) &&
      !isCampaignStatusArchived(adGroupDetails.campaignStatus) &&
      isValidSettings;
  } else {
    return () => isValidSettings;
  }
};

const getTooltipContent = (
  intl: IntlShape,
  macsTargetValues: { value: string; existingValue: string },
  isInValidSetting: boolean,
  tooltipParameters: {
    automationDisabledForMerchant: boolean;
    automationDisabledForLegacyRow: boolean;
    isAdGroupArchived: boolean;
    isCampaignCostTypeEqualToVcpm: boolean;
  },
  salesChannel: FlywheelSalesChannel,
  minAndMaxMacsTargetValues: {
    minMacsTargetValue: number | undefined;
    maxMacsTargetValue: number | undefined;
  },
  extraValidation: () => boolean
) => {
  if (tooltipParameters.automationDisabledForMerchant) {
    return getAutomationDisabledTooltipMessage(
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MACS_TARGET,
      })
    );
  }

  if (tooltipParameters.automationDisabledForLegacyRow) {
    return getAutomationDisabledForLegacyCampaignTooltipMessage();
  }

  if (tooltipParameters.isAdGroupArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.AdGroups],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MACS_TARGET,
      }),
      intl
    );
  } else if (isInValidSetting) {
    if (
      salesChannel === FlywheelSalesChannel.Walmart &&
      !isUndefined(minAndMaxMacsTargetValues.minMacsTargetValue) &&
      !isValidMacsTarget(
        macsTargetValues.value,
        extraValidation,
        macsTargetValues.existingValue
      )
    ) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_INVALID_MACS_VALUE,
            },
            {
              minValue: intl.formatNumber(
                minAndMaxMacsTargetValues.minMacsTargetValue,
                {
                  minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                  maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                }
              ),
            }
          )}
        </p>
      );
    }
    if (
      salesChannel === FlywheelSalesChannel.Amazon &&
      !isUndefined(minAndMaxMacsTargetValues.minMacsTargetValue) &&
      !isUndefined(minAndMaxMacsTargetValues.maxMacsTargetValue) &&
      !isValidMacsTarget(
        macsTargetValues.value,
        extraValidation,
        macsTargetValues.existingValue
      )
    ) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_MACS_VALUE,
            },
            {
              minValue: intl.formatNumber(
                minAndMaxMacsTargetValues.minMacsTargetValue,
                {
                  minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                  maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                }
              ),
              maxValue: intl.formatNumber(
                minAndMaxMacsTargetValues.maxMacsTargetValue,
                {
                  minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                  maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                }
              ),
            }
          )}
        </p>
      );
    }
  }
  if (tooltipParameters.isCampaignCostTypeEqualToVcpm) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_FLYWHEEL_SETTINGS_UNSET_TOOLTIP,
        })}
      </p>
    );
  }
};

const getMacsNumericInputState = (
  value: string,
  existingValue: string,
  isInputDisabled: boolean,
  isValidSettings: boolean,
  isInputEdited: boolean,
  extraValidationsForMacsTarget: () => boolean
) => {
  if (isInputDisabled) {
    return NumericInputState.Disabled;
  }

  if (!isValidSettings) {
    return NumericInputState.Error;
  }

  if (isInputEdited) {
    return isValidMacsTarget(
      value,
      extraValidationsForMacsTarget,
      existingValue
    )
      ? NumericInputState.Default
      : NumericInputState.Error;
  }

  return NumericInputState.Default;
};

const getPlaceholderText = (
  intl: IntlShape,
  placeholderValue: number | undefined
) =>
  !isUndefined(placeholderValue)
    ? intl.formatNumber(placeholderValue, {
        minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      })
    : undefined;

const isValueDirty = (value: string, existingValue: string) =>
  value || existingValue ? value !== existingValue : false;

const existingMacsTargetValue = (flywheelSettings: TeikaSettingsAdgroup) =>
  flywheelSettings.macsTarget
    ? (flywheelSettings.macsTarget * 100).toFixed(PERCENTAGE_FRACTION_DIGITS)
    : '';

const checkIfInputIdDisabled = (
  adGroupStatus: AdGroupStatus | undefined,
  adGroupDetails: DetailAdGroup,
  isCampaignCostTypeEqualToVcpm: boolean,
  automationDisabledForMerchant: boolean,
  automationDisabledForLegacyRow: boolean
) => {
  return (
    isAdGroupStatusArchived(adGroupStatus) ||
    isCampaignStatusArchived(adGroupDetails.campaignStatus) ||
    isCampaignCostTypeEqualToVcpm ||
    automationDisabledForMerchant ||
    automationDisabledForLegacyRow
  );
};

export const shouldUpdateValue = (
  isEditMode: boolean,
  updatedMacsTarget: string,
  value: string
) => {
  return (
    isEditMode || //Changing between modes
    (!isNil(updatedMacsTarget) && updatedMacsTarget !== value) // Changes done due to bulk update. updatedMacsTarget is latest, but state variable is not
  );
};

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  adGroupId,
  adGroupDetails,
  channelSettings,
  flywheelSettings,
  adGroupPerformance,
  isEditMode,
  salesChannel,
  merchantCountry,
  merchantType,
  selectedAdType,
  merchantsWithAutomationEnabled,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);

  const bidConstraintsData = useBidConstraintsContext();

  const data: AdGroupDetails = {
    adGroupId,
    adGroupDetails,
    channelSettings,
    flywheelSettings,
    adGroupPerformance,
  };

  const { campaignCostType, campaignTargetingType } = adGroupDetails;

  const currentPage = useSelector<WithTable<AdGroupDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_ADGROUP_TABLE_ID
      )
  );

  const updatedMacsTarget = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MACSTarget
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const isCampaignCostTypeEqualToVcpm =
    isCampaignCostTypeVcpm(campaignCostType);

  const existingValue = existingMacsTargetValue(flywheelSettings);

  const adGroupStatus = channelSettings.status;

  const {
    minMacsTarget: minMacsTargetValue,
    maxMacsTarget: maxMacsTargetValue,
    defaultMacsTarget: placeholderValue,
  } = useMemo(
    () =>
      getBidConstraint(
        bidConstraintsData.constraints,
        isCampaignAdFormatEqualToVideo(adGroupDetails.campaignAdFormat)
          ? AdType.SponsoredBrandsVideo
          : selectedAdType,
        salesChannel,
        ADGROUPS_API_COLUMN_NAME.MACSTarget,
        merchantCountry,
        campaignTargetingType,
        merchantType,
        campaignCostType
      ),
    [selectedAdType, salesChannel, merchantCountry]
  );

  const currentMinBid = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MinBid
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const currentMaxBid = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MaxBid
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  let macsTarget = updatedMacsTarget ?? existingValue;

  const isInputEdited = macsTarget !== existingValue;

  const [value, setValue] = useState<string>(macsTarget);

  const updatedAiBiddingValue = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        adGroupId,
        ADGROUPS_API_COLUMN_NAME.AiBidding
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  ) as AiBiddingValues;
  const aiBiddingValue =
    updatedAiBiddingValue ?? data.flywheelSettings.aiBidding;

  useEffect(() => {
    if (shouldUpdateValue(isEditMode, updatedMacsTarget, value)) {
      setValue(macsTarget);
    }
  }, [isEditMode, currentPage, updatedMacsTarget]);

  const isDirty = isValueDirty(value, existingValue);

  const isValidSettings = useMemo(() => {
    if (
      aiBiddingValue === AiBiddingValues.Ai ||
      aiBiddingValue === AiBiddingValues.Smart
    ) {
      if (!macsTarget) {
        return false;
      }
      return true;
    }
    return true;
  }, [
    flywheelSettings,
    currentMinBid,
    currentMaxBid,
    macsTarget,
    aiBiddingValue,
  ]);

  const updateCellValue = (newMacsTarget: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
        columnName: ADGROUPS_API_COLUMN_NAME.MACSTarget,
        rowId: data.adGroupId,
        value: newMacsTarget,
        existingValue: existingValue || '',
        numericValue: true,
      })
    );
  };

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [data.adGroupId]
  );

  if (
    isEditMode &&
    aiBiddingValue &&
    aiBiddingValue.toString() !== AiBiddingValues.NotEnabled
  ) {
    const automationDisabledForMerchant = isAutomationDisabledForMerchant(
      merchantsWithAutomationEnabled,
      adGroupDetails
    );

    const automationDisabledForLegacyRow = !getOrDefaultIsBiddableFlag(
      adGroupDetails.isBiddable
    );

    const onMacsNumericInputChange = (inputValue: string) => {
      if (isInputValueNumber(inputValue)) {
        setValue(inputValue);
        debouncedUpdateCellValue(inputValue);
      }
    };
    const isAdGroupArchived =
      isAdGroupStatusArchived(channelSettings.status) ||
      isCampaignStatusArchived(adGroupDetails.campaignStatus);

    const onInputFocus = () => setFocussed(true);

    const onInputBlur = () => {
      if (!isEmpty(value)) {
        debouncedUpdateCellValue.cancel();
        const formattedValue = Number.parseFloat(value).toFixed(
          PERCENTAGE_FRACTION_DIGITS
        );
        setValue(formattedValue);
        updateCellValue(formattedValue);
      }
      setFocussed(false);
    };

    const isInputDisabled = checkIfInputIdDisabled(
      adGroupStatus,
      adGroupDetails,
      isCampaignCostTypeEqualToVcpm,
      automationDisabledForMerchant,
      automationDisabledForLegacyRow
    );

    const macsNumericInputProps: NumericInputProps & TypedTooltipProps = {
      value,
      state: getMacsNumericInputState(
        value,
        existingValue,
        isInputDisabled,
        isValidSettings,
        isInputEdited,
        getExtraValidationsForMacsTarget(
          salesChannel,
          value,
          maxMacsTargetValue,
          adGroupStatus,
          adGroupDetails,
          isValidSettings
        )
      ),
      appendedElement: '%',
      onInputBlur: onInputBlur,
      onChange: onMacsNumericInputChange,
      tooltipContent: getTooltipContent(
        intl,
        { value, existingValue },
        !isValidSettings || isDirty,
        {
          automationDisabledForMerchant,
          automationDisabledForLegacyRow,
          isAdGroupArchived,
          isCampaignCostTypeEqualToVcpm,
        },
        salesChannel,
        { minMacsTargetValue, maxMacsTargetValue },
        getExtraValidationsForMacsTarget(
          salesChannel,
          value,
          maxMacsTargetValue,
          adGroupStatus,
          adGroupDetails,
          isValidSettings
        )
      ),
      controlledTooltip: getControlledTooltipState(
        isAdGroupArchived,
        focussed,
        getMacsNumericInputState(
          value,
          existingValue,
          isInputDisabled,
          isValidSettings,
          isInputEdited,
          getExtraValidationsForMacsTarget(
            salesChannel,
            value,
            maxMacsTargetValue,
            adGroupStatus,
            adGroupDetails,
            isValidSettings
          )
        )
      ),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus: onInputFocus,
      isDirty: isDirty,
      acceptOnlyPositiveNumbers: true,
      placeholder: getPlaceholderText(intl, placeholderValue),
      minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      dataTestId: 'ao_acosLimit',
    };

    return makeNumericInputColumn(() => macsNumericInputProps)(data);
  }

  return makePercentColumn((details: AdGroupDetails) =>
    aiBiddingValue && aiBiddingValue.toString() !== AiBiddingValues.NotEnabled
      ? details.flywheelSettings.macsTarget?.toString()
      : undefined
  )(data);
};
RowCellElement.displayName = 'AcosLimitColumn';

export const acosLimitColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.MACSTarget,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_ACOS_LIMIT,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const acosLimitFilter = createPercentDataFieldFilter(
  ADGROUPS_API_COLUMN_NAME.MACSTarget,
  I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_ACOS_LIMIT,
  isValidNumber(),
  true
);
