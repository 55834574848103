import React from 'react';
import { useIntl } from 'react-intl';

import {
  AlertTriangleIcon,
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Icon,
  IconSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { ConnectAdsDataWalmartModalProps } from './';

export const ModalFooter: React.FC<
  Pick<ConnectAdsDataWalmartModalProps, 'goBack' | 'authorize'> & {
    readonly isValid: boolean;
    readonly isProcessing: boolean;
    readonly tryAgain: boolean;
  }
> = ({ goBack, authorize, isValid, isProcessing, tryAgain }) => {
  const intl = useIntl();

  const [ERROR_MESSAGE, TRY_AGAIN] = [
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_API_ERROR_MESSAGE,
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_TRY_AGAIN,
  ].map((id) => intl.formatMessage({ id }));

  const buttonState = () => {
    if (!isValid) {
      return ButtonState.Disabled;
    } else if (isProcessing) {
      return ButtonState.Loading;
    }
    return ButtonState.Enabled;
  };

  return (
    <div className="flex flex-col -m-24">
      {tryAgain && (
        <div className="flex flex-row items-start bg-yellow-300 rounded w-full p-16 gap-12">
          <Icon
            size={IconSize.Medium}
            svg={AlertTriangleIcon}
            className="text-yellow-700"
          />
          <div className="flex flex-col items-start gap-4">
            <Typography
              size={TypographySize.base}
              lineHeight={TypographyLineHeight.normal}
              weight={TypographyWeight.semibold}
            >
              {ERROR_MESSAGE}
            </Typography>
            <Typography
              size={TypographySize.base}
              lineHeight={TypographyLineHeight.normal}
              weight={TypographyWeight.regular}
            >
              {TRY_AGAIN}
            </Typography>
          </div>
        </div>
      )}
      <div className="flex justify-between p-24">
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.BlackAndWhiteBorder}
          label={intl.formatMessage({ id: I18nKey.BACK })}
          svgIcon={ArrowLeftIcon}
          onClick={goBack}
          dataTestId={`flash1_walmart_connect_ads_auth_form_back`}
        />
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Primary}
          label={intl.formatMessage({ id: I18nKey.CONTINUE })}
          onClick={() => authorize()}
          state={buttonState()}
          dataTestId={`flash1_walmart_connect_ads_auth_form_continue`}
        />
      </div>
    </div>
  );
};
