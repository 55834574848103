import {
  Alignment,
  PaletteColor,
  PillSize,
  Placement,
  SelectControlled,
  SelectOptionProps,
  SelectState,
  SelectVisibility,
} from '@teikametrics/tm-design-system';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ViewerRoleTooltip } from '../../../../../lib/utilities/viewerRoleTooltipWrapper';

export interface CreateKeywordsMenuProps {
  readonly onAddKeywords: (type: KeywordType) => void;
  readonly showNegativeKeywords?: boolean;
  readonly showKeywords?: boolean;
  readonly showProducts?: boolean;
  readonly disabledSPKeywordCampaign?: boolean;
  readonly showSPKeywordCampaign?: boolean;
  readonly showContextualTargets?: boolean;
  readonly showChatGPT?: boolean;
  readonly openSelectMenuOnLeft?: boolean;
  readonly isUserRoleViewOnly?: boolean;
}

export enum KeywordType {
  NegativeKeyword = 'NegativeKeyword',
  Keyword = 'Keyword',
  SPKeywordCampaign = 'SP Keyword Campaign',
  Product = 'Product',
  Contextual = 'Contextual',
  NegativeProduct = 'NegativeProduct',
  ChatGPT = 'ChatGPT',
}

export const CreateKeywordsMenu: React.FC<CreateKeywordsMenuProps> = ({
  onAddKeywords,
  showKeywords = true,
  showNegativeKeywords = false,
  disabledSPKeywordCampaign = false,
  showSPKeywordCampaign = false,
  showProducts = false,
  showContextualTargets = false,
  showChatGPT = false,
  openSelectMenuOnLeft = false,
  isUserRoleViewOnly = false,
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const options: SelectOptionProps<KeywordType>[] = [
    ...(showChatGPT
      ? [
          {
            label: intl.formatMessage({
              id: I18nKey.CAMPAIGN_FLEXIBILITY_SUMMARY_KEYWORDS_HEADER,
            }),
            value: KeywordType.ChatGPT,
            groupName: intl.formatMessage({
              id: I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_HEADER,
            }),
            pillProps: {
              color: PaletteColor.blue,
              text: intl.formatMessage({
                id: I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_BETA,
              }),
              isBorderRounded: true,
              pillSize: PillSize.Small,
              className: 'ml-12 bg-rainbow-gradient',
              textClassName: 'text-white text-sm leading-none font-normal',
            },
            pillPosition: 'right' as any,
          },
        ]
      : []),
    ...(showKeywords
      ? [
          {
            label: intl.formatMessage({
              id: I18nKey.CAMPAIGN_FLEXIBILITY_SUMMARY_KEYWORDS_HEADER,
            }),
            groupName: intl.formatMessage({
              id: I18nKey.CREATE_KEYWORDS_MENU_FROM_LIST_HEADER,
            }),
            value: KeywordType.Keyword,
          },
        ]
      : []),
    ...(showNegativeKeywords
      ? [
          {
            label: intl.formatMessage({
              id: I18nKey.CREATE_KEYWORDS_MENU_NEGATIVE_KEYWORDS,
            }),
            groupName: intl.formatMessage({
              id: I18nKey.CREATE_KEYWORDS_MENU_FROM_LIST_HEADER,
            }),
            value: KeywordType.NegativeKeyword,
          },
        ]
      : []),
    ...(showSPKeywordCampaign
      ? [
          {
            label: intl.formatMessage({
              id: I18nKey.CREATE_SP_KEYWORDS_CAMPAIGN_MENU_NEW_KEYWORDS,
            }),
            groupName: intl.formatMessage({
              id: I18nKey.CREATE_KEYWORDS_MENU_FROM_LIST_HEADER,
            }),
            value: KeywordType.SPKeywordCampaign,
            disabled: disabledSPKeywordCampaign,
            ...(disabledSPKeywordCampaign
              ? {
                  tooltip: {
                    content: intl.formatMessage({
                      id: I18nKey.CREATE_SP_KEYWORDS_CAMPAIGN_MENU_NEW_KEYWORDS_DISABLE,
                    }),
                  },
                }
              : {}),
          },
        ]
      : []),
    ...(showProducts
      ? [
          {
            label: intl.formatMessage({
              id: I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PRODUCT_TARGETS,
            }),
            groupName: intl.formatMessage({
              id: I18nKey.CREATE_KEYWORDS_MENU_FROM_LIST_HEADER,
            }),
            value: KeywordType.Product,
          },
        ]
      : []),
    ...(showContextualTargets
      ? [
          {
            label: intl.formatMessage({
              id: I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_CONTEXTUAL_TARGETS,
            }),
            value: KeywordType.Contextual,
          },
        ]
      : []),
  ].map((option) => ({
    ...option,
    groupName: showChatGPT ? option.groupName : undefined,
  }));

  const onSelectToggle = () => {
    setIsOpen(!isOpen);
  };

  const onSelectChange = (value: KeywordType) => {
    onAddKeywords(value);
    setIsOpen(false);
  };

  return (
    <ViewerRoleTooltip
      position={{ placement: Placement.Left, alignment: Alignment.Center }}
      disabled={!isUserRoleViewOnly}
    >
      <SelectControlled
        dataTestId={'CreateKeywordsMenu'}
        selectHeaderHeight={32}
        menuClassName={showChatGPT ? 'w-240' : undefined}
        showGroupDivider={showChatGPT}
        groupHeaderClassnames={
          showChatGPT ? 'text-grey-700 leading-none font-normal' : undefined
        }
        defaultMenuClassnames={
          showChatGPT
            ? 'text-grey-900 text-base leading-none font-normal'
            : undefined
        }
        onToggle={onSelectToggle}
        options={options}
        visibility={isOpen ? SelectVisibility.Open : SelectVisibility.Closed}
        placeholder={intl.formatMessage({
          id: I18nKey.MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_CREATE,
        })}
        boldPlaceholder
        onChange={onSelectChange}
        selectMenuZIndexClass={
          openSelectMenuOnLeft ? 'z-20 left-auto right-0' : 'z-20'
        }
        state={isUserRoleViewOnly ? SelectState.Disabled : SelectState.Default}
      />
    </ViewerRoleTooltip>
  );
};
