import I18nKey from '../../../../../lib/types/I18nKey';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system';
import { useIntl } from 'react-intl';
import { NavPaths } from '../../../../../NavPaths';
import { useNavigate } from 'react-router-dom';

export interface SalesChannelSyncStatusFooterProps {
  readonly closeSalesChannelSyncStatusSlideOut: () => void;
}

export const SalesChannelSyncStatusFooter: React.FC<
  SalesChannelSyncStatusFooterProps
> = ({ closeSalesChannelSyncStatusSlideOut }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const goToSalesChannel = () => {
    closeSalesChannelSyncStatusSlideOut();
    navigate(NavPaths.SalesChannels);
  };
  return (
    <div className="flex flex-row items-center justify-end h-56 px-12">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.BlackAndWhiteBorder}
        label={intl.formatMessage({
          id: I18nKey.SYNC_STATUS_SLIDE_OUT_GO_TO_SALES_CHANNEL,
        })}
        onClick={goToSalesChannel}
        dataTestId={`sync_status_slide_out_navigate_to`}
      />
    </div>
  );
};

SalesChannelSyncStatusFooter.displayName = 'SalesChannelSyncStatusFooter';
