import {
  Alignment,
  AmazonLogomarkEnabledIcon,
  BoxCubeLinearIcon,
  BullhornLinearIcon,
  BullseyeLinearIcon,
  CogLinearIcon,
  Icon,
  IconSize,
  LightningSolidIcon,
  LogomarkIcon,
  Placement,
  TextLink,
  TextLinkSize,
  Tooltip,
  TooltipSize,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { useIntl } from 'react-intl';

import I18nKey from '../../lib/types/I18nKey';
import { getDateLabel } from './helpers';
import {
  IconMap,
  Notification,
  NotificationStatus,
  NotificationType,
} from './types';

interface NotificationRowProps {
  notification: Notification;
  showBorder?: boolean;
  onRead: () => void;
  onUnread: () => void;
}

const NotificationRow: React.FC<NotificationRowProps> = ({
  notification,
  showBorder,
  onRead,
  onUnread,
}) => {
  const { message, action, type, status, isRead, date } = notification;

  const dateLabel = getDateLabel(date);

  const intl = useIntl();

  const [MARK_AS_READ, MARK_AS_UNREAD] = [
    I18nKey.NOTIFICATIONS_PANEL_MARK_AS_READ,
    I18nKey.NOTIFICATIONS_PANEL_MARK_AS_UNREAD,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <div
      className="px-16 bg-white cursor-default hover:bg-grey-100"
      data-test-id="notification-row"
    >
      <div
        className={classNames('flex items-center gap-16 py-12', {
          'border-b border-solid border-grey-100': showBorder,
        })}
      >
        <div className="w-48 h-48 shrink-0 relative">
          <div
            className={classNames(
              `flex items-center justify-center
            w-full h-full rounded-full overflow-hidden`,
              {
                'bg-grey-200':
                  type !== NotificationType.ProductSpecific &&
                  type !== NotificationType.AI,
                'bg-white border-1 border-solid border-grey-100':
                  type === NotificationType.ProductSpecific,
                'bg-flywheel-orange-magenta-purple-gradient':
                  type === NotificationType.AI,
              }
            )}
          >
            {type === NotificationType.Ads && (
              <Icon
                svg={BullhornLinearIcon}
                className="text-grey-500"
                size={IconSize.Large}
              />
            )}
            {type === NotificationType.Product && (
              <Icon
                svg={BoxCubeLinearIcon}
                className="text-grey-500"
                size={IconSize.Large}
              />
            )}
            {type === NotificationType.MI && (
              <Icon
                svg={BullseyeLinearIcon}
                className="text-grey-500"
                size={IconSize.Large}
              />
            )}
            {type === NotificationType.General && (
              <Icon
                svg={CogLinearIcon}
                className="text-grey-500"
                size={IconSize.Large}
              />
            )}
            {type === NotificationType.Amazon && (
              <div
                className={`flex items-center justify-center
                w-32 h-32 bg-white rounded-full`}
              >
                <Icon svg={AmazonLogomarkEnabledIcon} size={IconSize.Medium} />
              </div>
            )}
            {type === NotificationType.Walmart && (
              <div
                className={`flex items-center justify-center
                w-32 h-32 bg-white rounded-full`}
              >
                <Icon svg={WalmartLogomarkEnabledIcon} size={IconSize.Medium} />
              </div>
            )}
            {type === NotificationType.AmazonWalmart && (
              <>
                <div
                  className={`flex items-center justify-center flex-shrink-0
                w-32 h-32 bg-white rounded-full`}
                >
                  <Icon
                    svg={AmazonLogomarkEnabledIcon}
                    size={IconSize.Medium}
                  />
                </div>
                <div
                  className={`flex items-center justify-center flex-shrink-0
                w-32 h-32 bg-white rounded-full -ml-4 border-l border-solid border-grey-200`}
                >
                  <Icon
                    svg={WalmartLogomarkEnabledIcon}
                    size={IconSize.Medium}
                  />
                </div>
              </>
            )}
            {type === NotificationType.Teikametrics && (
              <Icon svg={LogomarkIcon} size={IconSize.Large} />
            )}
            {type === NotificationType.AI && (
              <Icon
                svg={LightningSolidIcon}
                className="text-white"
                size={IconSize.Large}
              />
            )}
          </div>

          {status && (
            <div
              className={classNames(
                `flex items-center justify-center w-16 h-16
              absolute right-0 bottom-0 rounded-full text-white`,
                {
                  'bg-orange-500': status === NotificationStatus.Error,
                  'bg-green-500': status === NotificationStatus.Success,
                  'bg-yellow-500': status === NotificationStatus.Warning,
                  'bg-blue-500': status === NotificationStatus.Info,
                  'bg-purple-500':
                    status === NotificationStatus.Recommendation ||
                    status === NotificationStatus.Connect,
                  'bg-grey-700':
                    status === NotificationStatus.Pending ||
                    status === NotificationStatus.Pause,
                  'bg-flywheel-orange-magenta-purple-gradient':
                    status === NotificationStatus.AI,
                }
              )}
            >
              {status && <Icon svg={IconMap[status]} size={IconSize.Small} />}
            </div>
          )}
        </div>
        <div className="flex-1 py-4">
          <div
            className={classNames('text-base leading-tight', {
              'font-medium': !isRead,
            })}
            dangerouslySetInnerHTML={{ __html: message }}
          />
          {action && (
            <TextLink textLabel={action.rendered} size={TextLinkSize.Small} />
          )}
        </div>
        <div className="flex flex-col justify-end w-24 shrink-0">
          <Tooltip
            content={isRead ? MARK_AS_UNREAD : MARK_AS_READ}
            position={{
              placement: Placement.Left,
              alignment: Alignment.Center,
            }}
            tooltipSize={TooltipSize.Small}
            hideArrow
          >
            <div
              className={`flex items-center justify-center
              w-24 h-24 rounded-full cursor-pointer
              hover:border hover:border-solid hover:border-grey-300`}
              data-test-id={
                isRead
                  ? 'notification-unread-button'
                  : 'notification-read-button'
              }
              onClick={isRead ? onUnread : onRead}
            >
              {!isRead && (
                <div className="w-8 h-8 bg-purple-500 rounded-full" />
              )}
            </div>
          </Tooltip>
          <div className="flex items-end justify-end h-24 pr-8">
            <Tooltip
              content={DateTime.fromISO(date).toFormat('DD · t')}
              position={{
                placement: Placement.Left,
                alignment: Alignment.Center,
              }}
              tooltipSize={TooltipSize.Small}
              hideArrow
            >
              <div className="w-full text-sm leading-none">
                {dateLabel.diff}
                {dateLabel.unit}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

NotificationRow.displayName = 'NotificationRow';

export default NotificationRow;
