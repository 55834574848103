import {
  CheckboxCheckedState,
  CheckboxState,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableSelectSettings,
  WithTable,
} from '../../../../../containers/table/ducks/types';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { ADS_MANAGER_SV_ADS_TABLE_ID } from '../ducks/types';
import { makeCheckboxLabelColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  ProductViewTrendLink,
  getExternalUrl,
  getProductTextColumn,
} from '../adItemsTableColumns/productName';
import isEmpty from 'lodash/isEmpty';
import { WALMART_SALES_CHANNEL_ID } from '../../../../../lib/types/SalesChannels';
import isArray from 'lodash/isArray';
import first from 'lodash/first';

const getTableSettings = ({ tableState }: WithTable<ProductAdDetails>) => {
  return tableSelectors.getSelectInfoSelector()(
    tableState,
    ADS_MANAGER_SV_ADS_TABLE_ID
  );
};

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) => {
  const dispatch = useDispatch();

  const selectSettings = useSelector<
    WithTable<ProductAdDetails>,
    TableSelectSettings
  >(getTableSettings);

  const isRowSelected = selectSettings.rows.includes(props.adItemId);

  const adItemData: ProductAdDetails = {
    adItemPerformance: props.adItemPerformance,
    adItemDetails: props.adItemDetails,
    adItemId: props.adItemId,
    channelSettings: props.channelSettings,
  };

  let nameColumn = getProductTextColumn(
    props.salesChannel,
    adItemData,
    props.merchantType,
    props.merchantCountry
  );

  if (props.isEditMode) {
    nameColumn = makeCheckboxLabelColumn((data: ProductAdDetails) => {
      return {
        dataTestId: SV_ADS_API_COLUMN_NAME.Name,
        label: !isEmpty(data.adItemDetails.productName)
          ? data.adItemDetails.productName
          : data.adItemDetails.extItemId,
        subLabel:
          !isEmpty(data.adItemDetails.productName) &&
          data.adItemDetails.extItemId,
        checked: isRowSelected
          ? CheckboxCheckedState.Checked
          : CheckboxCheckedState.Unchecked,
        state: CheckboxState.Default,
        onClick: () =>
          dispatch(
            tableActions.toggleSelectedItem({
              tableId: ADS_MANAGER_SV_ADS_TABLE_ID,
              rowId: data.adItemId,
            })
          ),
        classNameLabel: 'flex-1',
        className: 'align-start',
        imageUrl:
          data.adItemDetails.productName && data.adItemDetails.productImage,
        externalUrl: getExternalUrl(
          props.salesChannel,
          data,
          props.merchantCountry
        ),
      };
    })(props);
  }
  return (
    <div className="w-full flex flex-row justify-between">
      {nameColumn}
      <ProductViewTrendLink
        onClick={() =>
          props.onViewTrendsSlideoverClick?.({
            salesChannelId: WALMART_SALES_CHANNEL_ID,
            merchantCountryId: (isArray(props.selectedMerchantCountries)
              ? first(props.selectedMerchantCountries)
              : props.selectedMerchantCountries) as string,
            sku: props.adItemDetails.sku || '',
          })
        }
      />
    </div>
  );
};

RowCellElement.displayName = 'NameColumn';

export const name: FlywheelTableColumn<ProductAdDetails, TableDataAdsManager> =
  {
    columnName: SV_ADS_API_COLUMN_NAME.Name,
    i18nKeyOrLabel: I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_NAME,
    isSortable: true,
    RowCellElement,
    gridColumnWidth: '380px',
  };

export const nameFilter = createStringDataFieldFilter(
  SV_ADS_API_COLUMN_NAME.Name,
  I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_NAME,
  isValidString()
);
