import { MerchantCountry, MerchantType } from '../../lib/types/Fam';
import {
  TableCell,
  UpdateCellPayload,
} from '../../containers/tableV2/ducks/types';
import { PartialRecord } from '../../lib/types';
import { SvgIcon, TMTableMeta } from '@teikametrics/tm-design-system';
import I18nKey from '../../lib/types/I18nKey';
import {
  AdGroupsSummary,
  RecommendationsSummaryResponse,
} from '../../lib/types/RecommendationsSharedTypes';
import { Money } from '../../lib/types/Money';

export enum RECOMMENDATION_CARDS_PATH {
  AUTO_BIDDING = 'auto-bidding',
  ENTER_COGS = 'enter-cogs',
}

export const MISSING_PERCENT = 'missingPercent';

export const API_REQUEST_DATE_FORMAT = 'yyyy-MM-dd';

export interface ViewRecommendationTableData extends TMTableMeta<any> {
  readonly merchants: MerchantCountry[];
  readonly onUpdate: (updatePayload: UpdateCellPayload) => void;
}

export type ViewRecommendationRowData = ViewRecommendationTableData &
  RecommendationsSummaryResponse & {
    depth: number;
  };

export type ViewRecommendationSubRowData = ViewRecommendationTableData &
  AdGroupsSummary & {
    depth: number;
  };

export enum RecommendationActionType {
  TURN_ON_BID = 'Enable',
  DISMISS = 'Dismiss',
}

export enum EditableColumnsViewRecommendations {
  smartAcos = 'smartAcos',
  action = 'action',
  bidModifier = 'bidModifier',
}

export interface TableCellChangeViewRecommendations {
  [key: string]: PartialRecord<EditableColumnsViewRecommendations, TableCell>;
}

export enum ViewRecommendationTableColumns {
  SMART_ACOS = 'smartAcos',
  MERCHANT_NAME = 'merchantCountryId',
  CAMPAIGN_NAME = 'campaignName',
  AD_TYPE = 'adType',
  AD_SALES = 'adSale',
  AD_SPEND = 'adSpend',
  CURRENT_ACOS = 'currentAcos',
  ACTION = 'action',
  BID_MODIFIER = 'bidModifier',
}

export enum EnterCogsRecommendationTableColumns {
  SKU = 'sku',
  UPDATED_AT = 'updatedAt',
  UNIT_COGS = 'unitCogs',
}

export const MI_CARD_ADD_TERMS_SHOW_ME_HOW_LINK =
  'https://intercom.help/flywheel-20/en/articles/4931480-getting-started-add-terms-to-track';

export enum RecommendationHubCardTypes {
  AUTOMATED_BIDDING = 'Automated Bidding',
  UNADVERTISED_PRODUCTS = 'Unadvertised Products',
  CONNECT_PRODUCTS_DATA = 'Connect Products Data',
  MARKET_INTELLIGENCE = 'Market Intelligence',
  ADD_COGS = 'Add Cost Of Goods Sales',
  OPPORTUNITY_ANALYZER_REPORT = 'Opportunity Analyzer Report',
  PRODUCTS_CATALOG_CONNECT = 'Product Catalog Connect',
  PRODUCTS_API_CONNECT = 'Product API Connect',
  PRODUCTS_COGS = 'Product Cost Of Goods Sales',
  PRODUCTS_UNADVERTISED_PRODUCTS = 'Product Unadvertised Productst',
  PRODUCTS_NOT_IN_SMART_CAMPAIGNS = 'Product Not In Smart Campaigns',
  PRODUCTS_TURN_ON_AIBIDDING = 'Turn on AI Bidding',
  PRODUCTS_KEYWORD_AND_TARGET = 'Keyword & Target Actions',
  PRODUCTS_BUDGET_MANAGEMENT = 'Budget Management',
  OUT_OF_STOCK = 'Out of Stock',
  UNSOLD_INVENTORY = 'Unsold Inventory L90',
  LIQUIDATE_ON_HIIVE = 'Liquidate On Hiive',
  DEMAND_FORECAST = 'Demand Forecast',
  RATE_OF_SALE_INSIGHTS = 'Rate of Sale Insights',
  OUT_OF_STOCK_DETECTION = 'Out of Stock Detection',
  EXCESS_STOCK_RECOGNITION = 'Excess Stock Recognition',
  ANALYZE_AGING_INVENTORY = 'Analyze Aging Inventory',
  CUSTOM_STOCKOUT_AND_REORDER_ALERT = 'Custom Stockout and Reorder Alert',
  HIIVE_CLEARANCE = 'Hiive Clearance',
  ADVERTISED_PRODUCTS_WITH_LESS_THAN_30_DAYS_INV = 'Less than 30 Days of Inventory',
  UN_ADVERTISED_PRODUCTS_WITH_LOW_SELL_THROUGH_RATE = 'Less than 40% Sell Through Rate',
  BRANDS_AND_COMPETITORS = 'Brands & Competitors',
}

export enum CardCategory {
  IMPROVE_ADVERTISING = 'Improve Advertising',
  ACCOUNT_SETUP = 'Account Setup',
  PRODUCTS_CATALOG = 'Product Catalog',
  ADVERTISING_OPTIMIZATION = 'Advertising Optimization',
  CAMPAIGN_MAINTENANCE = 'Campaign Maintenance',
  INVENTORY_OPTIMIZATION = 'Inventory Optimization',
  INVENTORY_DASHBOARD = 'INVENTORY_DASHBOARD',
}

export interface CardDetails {
  readonly salesChannelId?: string;
  readonly isDismissed?: boolean;
  readonly count?: number;
  readonly moneyValue?: Money;
  readonly type: RecommendationHubCardTypes;
  readonly category: CardCategory;
  readonly querryParam?: string;
  readonly merchantType?: MerchantType;
  readonly merchantCountryIds?: string[];
  readonly miSuggestionsCount?: number;
  readonly miSuggestionsAvailableForMCIDs?: string[];
  readonly missingPercent?: number;
  readonly merchantCount?: number;
  readonly salesChannelI18Key?: I18nKey;
  readonly isLoading?: boolean;
  readonly salesChannelAmazon?: number;
  readonly salesChannelWalmart?: number;
}

export interface RecommendationCardDetails {
  readonly displayIcon?: SvgIcon;
  readonly pillIcon: SvgIcon;
  readonly pillText: I18nKey;
  readonly mainText: I18nKey;
  readonly subText: I18nKey;
  readonly primarySalesChannelIcon?: SvgIcon;
  readonly secondarySalesChannelIcon?: SvgIcon;
  readonly primaryButtonText?: I18nKey;
  readonly secondaryButtonText?: I18nKey;
  readonly newTabOnSecondryButtonClick?: boolean;
  readonly secondryButtonHref?: string;
  readonly secondaryButtonLoading?: boolean;
  readonly footerIcon?: SvgIcon;
  readonly footerText?: I18nKey;
  readonly subTextCompleted?: I18nKey;
  readonly showNewDesign?: boolean;
  readonly cardContainerClasses?: string;
  readonly classCardCss?: string;
  readonly cardBgColor?: string;
}
