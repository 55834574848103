import {
  ContextSetterV3,
  DotsCircleIcon,
  Layout,
  PageLoader,
  SelectOptionProps,
  SelectV2OptionProps,
  Spinner,
} from '@teikametrics/tm-design-system';
import axios, { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router';
import { FinishProfileModal } from '../../../../components/FinishProfileModal';
import RiskFreeTrialModal from '../../../../components/BillingModals/RiskFreeTrialModal/RiskFreeTrialModal';
import EmptyState from '../../../../components/EmptyState';
import {
  useEmptyStateData,
  useMerchantsSyncing,
} from '../../../../components/EmptyState/hooks';
import { PageType } from '../../../../components/EmptyState/types';
import { SubscriptionContext } from '../../../../containers/subscriptionProvider';
import { useDataSyncInfoContext } from '../../../../containers/dataSyncInfoProvider/dataSyncInfoProvider';
import {
  PageHeaderContext,
  PageHeaderContextState,
} from '../../../../containers/pageHeaderProvider';
import {
  getCurrentAccountFromContext,
  hasPaymentInfo,
  isAIPlanEnabled,
  isSelfServiceAccount,
} from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { MIApiClient } from '../../../../lib/clients/MIApiClient';
import { SKUApiClient } from '../../../../lib/clients/SKUApiClient';
import { FlywheelSalesChannel } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { getAdsDataConnectionStatusForCompass } from '../../../../lib/utilities/connectionStatus';
import { CurrencyCode } from '../../../../lib/utilities/currency';
import { CompassContextSetterContext } from '../../compassContextSetterProvider';
import {
  UserInfo,
  setCompassCatalogCurrencyInLocalStorage,
  setCompassCatalogDateRangeInLocalStorage,
  setCompassCatalogMerchantsInLocalStorage,
} from '../../storageUtils';
import {
  COMPASS_API_REQUEST_DATE_FORMAT,
  CompassState,
  DateRange,
  HomePageDataRequestExceptMetricAndPerformance,
} from '../../types';
import {
  generateMerchantPickerOptions,
  getCurrencyPickerOptions,
  getEarliestSyncDateForSelectedMerchants,
  getPossibleStartAndEndDateBySyncDate,
} from '../../utils';

export interface CompassDashboardProps {
  readonly aoApiClient: AOApiClient;
  readonly skuApiClient: SKUApiClient;
  readonly miApiClient: MIApiClient;
  readonly getHomepageData: (
    reqData: HomePageDataRequestExceptMetricAndPerformance,
    controller?: AbortController,
    source?: CancelTokenSource,
    clearController?: () => void
  ) => {};
  readonly getAiStatisticsDataV2: (
    merchantCountryIds: string[],
    dateRange: DateRange
  ) => {};
  readonly reloadAiStatisticsDataV2: boolean;
  readonly compassState?: CompassState;
}

export const CompassDashboard: React.FC<
  CompassDashboardProps & PropsWithChildren
> = (props) => {
  const userContext = useContext<UserContextState>(UserContext);
  const account = getCurrentAccountFromContext(userContext);
  const accountId = account!.id;
  const companyName = account?.companyName;
  const userDetails = userContext.userInfo.userDetails;
  const userInfo: UserInfo = {
    userId: userDetails?.id ?? '',
    accountId,
  };
  const intl = useIntl();

  const appHeaderTitle = intl.formatMessage({
    id: I18nKey.NAV_BAR_LABEL_COMPASS,
  });

  const onMerchantChange = (
    newMerchantSelection: string | number | (string | number)[]
  ) => {
    const earliestSyncDate = getEarliestSyncDateForSelectedMerchants(
      newMerchantSelection as string[],
      dataSyncInfoForAllMerchants
    );

    const newDateRange = getPossibleStartAndEndDateBySyncDate(
      selectedDateRange,
      earliestSyncDate
    );

    updateContextSetterValue({
      selectedCurrency,
      selectedMerchantsId: newMerchantSelection as string[],
      selectedDateRange: newDateRange,
    });

    setCompassCatalogMerchantsInLocalStorage(
      userInfo,
      newMerchantSelection as string[]
    );
    setCompassCatalogDateRangeInLocalStorage(userInfo, newDateRange);
  };

  const onCurrencyChange = (
    currency: string | number | (string | number)[]
  ) => {
    updateContextSetterValue({
      selectedCurrency: currency as CurrencyCode,
      selectedMerchantsId,
      selectedDateRange,
    });

    setCompassCatalogCurrencyInLocalStorage(userInfo, currency as CurrencyCode);
  };

  const onDateRangeChange = (newStartDate: string, newEndDate: string) => {
    const startDateInLuxon = DateTime.fromFormat(
      newStartDate,
      COMPASS_API_REQUEST_DATE_FORMAT
    );
    const endDateInLuxon = DateTime.fromFormat(
      newEndDate,
      COMPASS_API_REQUEST_DATE_FORMAT
    );

    const newDateRange: DateRange = {
      ...selectedDateRange,
      startDate: startDateInLuxon,
      endDate: endDateInLuxon,
    };

    updateContextSetterValue({
      selectedCurrency,
      selectedMerchantsId,
      selectedDateRange: newDateRange,
    });

    setCompassCatalogDateRangeInLocalStorage(userInfo, newDateRange);
  };

  const {
    merchants,
    updateContextSetterValue,
    contextSetterValues: {
      selectedCurrency,
      selectedMerchantsId,
      selectedDateRange,
    },
    dataSyncInfoForAllMerchants,
    fw2SalesChannels,
    isContextDataLoading,
  } = useContext(CompassContextSetterContext);

  const controllerRef = useRef<AbortController>();
  const sourceRef = useRef<CancelTokenSource>();

  useEffect(() => {
    if (
      !isContextDataLoading &&
      selectedDateRange &&
      selectedMerchantsId.length > 0
    ) {
      const { getHomepageData } = props;
      if (controllerRef.current) {
        controllerRef.current.abort();
        controllerRef.current = undefined;
      }

      if (sourceRef.current) {
        sourceRef.current.cancel();
        sourceRef.current = undefined;
      }

      controllerRef.current = new AbortController();

      sourceRef.current = axios.CancelToken.source();

      getHomepageData(
        {
          startDate: selectedDateRange.startDate.toISODate() as string,
          endDate: selectedDateRange.endDate.toISODate() as string,
          currency: selectedCurrency || CurrencyCode.USD,
          merchantCountryIds: selectedMerchantsId,
        },
        controllerRef.current,
        sourceRef.current,
        () => {
          controllerRef.current = undefined;
          sourceRef.current = undefined;
        }
      );
    }
  }, [
    isContextDataLoading,
    selectedCurrency,
    selectedMerchantsId,
    selectedDateRange,
  ]);

  const MERCHANT_PICKER_OPTIONS: SelectOptionProps<string>[] = useMemo(
    () =>
      generateMerchantPickerOptions(merchants || [], fw2SalesChannels, intl),
    [merchants, fw2SalesChannels, intl]
  );

  const [showRiskFreeTrial, setShowRiskFreeTrial] = useState(false);

  const merchantsSyncing = useMerchantsSyncing();
  const pageData = useEmptyStateData(
    PageType.Compass,
    merchantsSyncing.isProductConnected,
    merchantsSyncing.isAdsConnected
  );

  const hasGivenPayment = hasPaymentInfo(userContext);
  const currentAccount = getCurrentAccountFromContext(userContext);
  const isAiEnabled = isAIPlanEnabled(userContext);

  const isSelfService = isSelfServiceAccount(currentAccount);

  const canSeeRiskFreeTrial = useMemo(() => {
    if (isContextDataLoading || !userContext.isBillingLoaded) {
      return false;
    }

    return (
      !hasGivenPayment &&
      !isAiEnabled &&
      isSelfService &&
      !merchantsSyncing.isNoData
    );
  }, [
    userContext.isBillingLoaded,
    isContextDataLoading,
    hasGivenPayment,
    isAiEnabled,
    isSelfService,
    merchantsSyncing.isNoData,
  ]);

  const CURRENCY_PICKER_OPTIONS = getCurrencyPickerOptions();

  const dataSyncInfo = useDataSyncInfoContext();
  const subscriptionContext = useContext(SubscriptionContext);

  const { salesChannelData } = subscriptionContext;

  const connectionData = getAdsDataConnectionStatusForCompass(
    dataSyncInfo?.aoDataSyncInfo?.syncPerMerchantIds || [],
    salesChannelData
  );

  const areAllMerchantSyncing =
    connectionData[FlywheelSalesChannel.Amazon]?.syncing ===
      connectionData[FlywheelSalesChannel.Amazon].total &&
    connectionData[FlywheelSalesChannel.Walmart].syncing ===
      connectionData[FlywheelSalesChannel.Walmart].total &&
    (connectionData[FlywheelSalesChannel.Walmart].total > 0 ||
      connectionData[FlywheelSalesChannel.Amazon].total > 0);

  useEffect(() => {
    if (canSeeRiskFreeTrial) {
      setShowRiskFreeTrial(true);
    } else {
      setShowRiskFreeTrial(false);
    }
  }, [canSeeRiskFreeTrial]);

  const { updatePageHeaderData } =
    useContext<PageHeaderContextState>(PageHeaderContext);

  useEffect(() => {
    updatePageHeaderData({
      title: (
        <div className="flex items-center font-normal">
          <span className="font-semibold">{appHeaderTitle}</span>
        </div>
      ),
      titleString: appHeaderTitle,
      dataTestId: 'compass',
    });
  }, []);

  if (!merchantsSyncing.isMerchantSyncLoaded) {
    return <Spinner />;
  }

  if (!pageData.isVisible && !isContextDataLoading) {
    return (
      <>
        {(!companyName || merchantsSyncing.isNoData) && (
          <FinishProfileModal
            isProfilePending={!companyName}
            isCommunity={false}
            isConnected={
              merchantsSyncing.isProductConnected ||
              merchantsSyncing.isAdsConnected
            }
          />
        )}
        <EmptyState
          title={appHeaderTitle}
          isInSync={merchantsSyncing.isInSync}
          pageType={PageType.Compass}
          merchantsSyncing={merchantsSyncing}
        />
        {showRiskFreeTrial && companyName && (
          <RiskFreeTrialModal
            onCancelClicked={() => setShowRiskFreeTrial(false)}
          />
        )}
      </>
    );
  }

  return (
    <>
      {areAllMerchantSyncing ? (
        <PageLoader
          icon={DotsCircleIcon}
          className={'h-pageLoaderScreen'}
          title={intl.formatMessage({
            id: I18nKey.PRODUCTS_SYNCHING_CONNECTION_TITLE,
          })}
          description={intl.formatMessage({
            id: I18nKey.PRODUCTS_SYNCHING_CONNECTION_DESCRIPTION,
          })}
        />
      ) : (
        <Layout classNames="xl:mx-auto pb-0 mt-0">
          <div
            className={classNames(
              'flex',
              'max-w-full mx-64 lg:mx-32 md:mx-16 sm:mx-0'
            )}
          >
            <div className={classNames('block w-full space-y-16')}>
              <ContextSetterV3
                primaryDropdownProps={{
                  primaryDropdownLabel: intl.formatMessage({
                    id: I18nKey.CONTEXT_SETTER_SALES_CHANNEL_PICKER_MERCHANTS,
                  }),
                  primaryDropdownPlaceholder: '',
                  primaryDropdownOptions:
                    MERCHANT_PICKER_OPTIONS as SelectV2OptionProps<
                      string | number
                    >[],
                  primaryDropdownValue: selectedMerchantsId,
                  primaryDropdownShowSearch: true,
                  onSelectPrimaryDropdown: onMerchantChange,
                }}
                secondaryDropdownProps={{
                  secondaryDropdownLabel: intl.formatMessage({
                    id: I18nKey.CONTEXT_SETTER_SALES_REPORTING_CURRENCY_PICKER_CHANNEL,
                  }),
                  secondaryDropdownOptions:
                    CURRENCY_PICKER_OPTIONS as SelectV2OptionProps<
                      string | number
                    >[],
                  secondaryDropdownValue: selectedCurrency,
                  onSelectSecondaryDropdown: onCurrencyChange,
                  secondaryDropdownShowSearch: true,
                  secondaryDropDownAddValueToDataTestId: true,
                  secondaryDropdownPlaceholder: '',
                }}
                timeFrameDropdownProps={{
                  onDateRangeChange: onDateRangeChange,
                  timeFrameLabel: intl.formatMessage({
                    id: I18nKey.CONTEXT_SETTER_TIMEFRAME_LABEL,
                  }),
                  showCustomCalendarOption: true,
                  initialStartDate: selectedDateRange.startDate,
                  initialEndDate: selectedDateRange.endDate,
                  minDate: selectedDateRange.minDate,
                  maxDate: selectedDateRange.maxDate,
                }}
                isLoading={isContextDataLoading}
                dataTestId="1com"
                classnames={classNames('max-w-1024 mx-auto', 'lg:px-0')}
              />
              <div className="mt-20">
                <Outlet />
              </div>
            </div>
          </div>
        </Layout>
      )}
      {showRiskFreeTrial && (
        <RiskFreeTrialModal
          onCancelClicked={() => setShowRiskFreeTrial(false)}
        />
      )}
    </>
  );
};
CompassDashboard.displayName = 'CompassDashboard';
