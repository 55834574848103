import { CurrencyCode } from '../../lib/utilities/currency';
import { MerchantType } from '../../lib/types/Fam';

export interface UnitCogsEstAdMarginEdits {
  sku: string;
  productCatalogId: string;
  merchantCountryId: string;
  unitCogs?: string;
  orignalCogs?: number;
}

export interface UnitCogsEstAdMargin {
  [id: string]: UnitCogsEstAdMarginEdits;
}

export const DEFAULT_CURRENCY_EST_PRE_AD_MARGIN = CurrencyCode.USD;

export const MAP_MERCHANT_TYPE_TO_VALUE = {
  [MerchantType.Seller]: 'SELLER',
  [MerchantType.Vendor]: 'VENDOR',
};

export const DEFAULT_BULK_INPUT_VALUE = '5';
