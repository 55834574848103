import {
  PaletteColor,
  Pill,
  PillSize,
  Theme,
  Tooltip,
  TruncatedText,
  createMultiSelectDataFieldFilter,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { AdGroupDetails, Groups } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { NO_GROUP_VALUE, TableDataAdsManager } from '../types';
import { ADGROUPS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = (
  props
) => {
  const {
    isEditMode,
    aoApiClient,
    groupNames,
    openCreateEditGroupSlideover,
    selectedMerchantCountries,
    ...adGroupDetails
  } = props;

  const intl = useIntl();

  const labels = adGroupDetails.adGroupDetails?.groupLabel || [];
  const displayValue = labels?.[0];
  const moreText = intl.formatMessage(
    {
      id: I18nKey.AD_OPTIMIZER_TABLE_GROUP_MORE_TEXT,
    },
    {
      count: labels.length - 1,
    }
  );

  return (
    <div
      className={classNames(
        'flex items-center justify-between gap-8',
        'h-full w-full p-12',
        'text-base leading-tight font-normal'
      )}
    >
      <TruncatedText
        text={
          displayValue ||
          intl.formatMessage({
            id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_NO_GROUP,
          })
        }
        fontClassNames="text-base leading-tight font-normal"
        hoverClassNames="text-base leading-tight font-normal"
      />
      {labels.length > 1 && (
        <div>
          <Tooltip
            overwrittenTooltipClassnames="w-auto max-w-400"
            theme={Theme.Dark}
            content={getTooltipContent(labels)}
            disabled={false}
          >
            <Pill
              color={PaletteColor.greyV2}
              pillSize={PillSize.Small}
              text={moreText}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
RowCellElement.displayName = 'RowCellElement';

export const groupColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.Group,
  isSortable: false,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_GROUP,
  RowCellElement,
  gridColumnWidth: '240px',
};

export const groupFilter = (groups?: Groups[] | undefined) => {
  if (!groups || !groups.length) {
    return createStringDataFieldFilter(
      ADGROUPS_API_COLUMN_NAME.Group,
      I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_GROUP,
      isValidString()
    );
  }

  const options = groups.map((group, index) => ({
    value: index !== 0 ? group.name : NO_GROUP_VALUE,
    displayI18nKey: group.name || '-',
  }));
  return createMultiSelectDataFieldFilter(
    ADGROUPS_API_COLUMN_NAME.Group,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_GROUP,
    [...options]
  );
};

export const getTooltipContent = (labels: string[]) => {
  return (
    <div className="flex flex-col text-sm leading-tight font-normal break-all">
      {labels.map((label) => {
        return (
          <div className="flex flex-row gap-4 items-start">
            <div className="h-4 w-4 text-white">•</div>
            <div>{label}</div>
          </div>
        );
      })}
    </div>
  );
};
