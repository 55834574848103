import { Factory } from 'fishery';

import factories from '../';
import { MerchantCountry } from '../../types/Fam';

export default Factory.define<{ merchantCountryInfoItems: MerchantCountry[] }>(
  () => {
    const merchantCountry: MerchantCountry = factories.FAMMerchantCountry.build(
      {}
    );
    return {
      merchantCountryInfoItems: [merchantCountry],
    };
  }
);
