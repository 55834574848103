import {
  Icon,
  IconSize,
  TextLink,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import I18nKey from '../../../../../lib/types/I18nKey';
import { HeaderInfo } from '../../../containers/salesChannels/types';

export interface SalesChannelHeaderProps {
  readonly header: HeaderInfo;
  readonly showDataSyncingAndTimingSlideout?: () => void;
  readonly totalItems?: number;
  readonly hideLabel?: boolean;
}

export const SalesChannelHeader: React.FC<SalesChannelHeaderProps> = ({
  header,
  totalItems = 0,
  hideLabel = false,
  showDataSyncingAndTimingSlideout,
}) => {
  const intl = useIntl();

  const dataSyncingAndTimingLabel = intl.formatMessage({
    id: I18nKey.SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_HEADER,
  });

  return (
    <div className="flex flex-row items-start gap-12 w-full">
      <div className="flex items-center justify-center border-1 border-solid border-grey-200 rounded-full h-40 w-40 min-w-40">
        <Icon size={IconSize.Large} svg={header?.salesChannelIcon} />
      </div>
      {hideLabel ? null : (
        <>
          <div className="flex flex-col items-start justify-center whitespace-nowrap">
            <Typography
              size={TypographySize.lg}
              lineHeight={TypographyLineHeight.tight}
              className="text-grey-900"
              weight={TypographyWeight.medium}
            >
              <FormattedMessage id={header?.header} />
            </Typography>
            <Typography
              size={TypographySize.lg}
              lineHeight={TypographyLineHeight.tight}
              className="text-grey-900"
              weight={TypographyWeight.medium}
            >
              <Typography
                size={TypographySize.base}
                lineHeight={TypographyLineHeight.none}
                className="text-grey-900"
                weight={TypographyWeight.regular}
              >
                <FormattedMessage
                  id={I18nKey.GENERIC_MERCHANT_COUNT}
                  values={{
                    merchantCount: totalItems,
                  }}
                />
              </Typography>
            </Typography>
          </div>
          <div className="flex w-full justify-end items-center self-stretch">
            <TextLink
              textLabel={dataSyncingAndTimingLabel}
              onClick={showDataSyncingAndTimingSlideout}
              className="text-sm leading-none font-normal text-grey-900"
            />
          </div>
        </>
      )}
    </div>
  );
};

SalesChannelHeader.displayName = 'SalesChannelHeader';
