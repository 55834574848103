import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ButtonVariant } from '@teikametrics/tm-design-system';

import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { createFAMApiClient } from '../../../../lib/clients/FAMApiClient';
import {
  FlywheelSalesChannel,
  MerchantCountries,
} from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { AmazonAuthFailedModal } from '../../components/AmazonModalSteps/authFailed';
import { ConnectedMerchantsModal } from '../../components/AmazonModalSteps/connectedMerchants';
import { ConnectionInitialModal } from '../../components/AmazonModalSteps/ConnectInitialModal';
import { ConnectionSuccessModal } from '../../components/AmazonModalSteps/connectionSuccess';
import { NoMerchantsModal } from '../../components/AmazonModalSteps/noMerchantsAvailable';
import { PromptAuthFailed } from '../../components/AmazonModalSteps/promptAuthFailed';
import { SellerType } from '../../containers/utils';
import { MerchantConnectionSavedWarningPopup } from '../MerchantConnectionSavedWarningPopup';
import { ProductsDataConnectionWarningPopup } from '../ProductsDataConnectionWarningPopup';
import { RowDataProps } from '../types';
import { AuthorizeProductsDataModal, Region } from './authProductData';
import { SelectAdsProfileModal } from './selectAdsProfile/selectAdsProfileModal';
import {
  SecondaryMerchantsModal,
  SecondaryMerchantsModalType,
} from './SecondaryMerchantsModal';
import { DataConnectionCompleteModal } from './DataConnectionComplete';
import {
  AmazonLwaStatus,
  amazonLWA,
} from '../../../../lib/utilities/amazonLWA';

export interface AmazonFlashProps {
  readonly onClose: (openInitialModal?: boolean) => void;
  readonly initialScreen?: AMAZON_FLASH_MODAL_SCREENS;
  readonly refreshTable?: () => void;
  readonly connectedMerchantIds: string[];
  readonly notOwnedMerchant?: RowDataProps[];
}

export enum AMAZON_FLASH_MODAL_SCREENS {
  AMZInitialConnectScreen = 'AMZInitialConnectScreen',
  AMZAmazonAuthorizesScreen = 'AMZAmazonAuthorizesScreen',
  AMZAmazonAuthFailedScreen = 'AMZAmazonAuthFailedScreen',
  AMZAmazonAuthorizesFailedScreen = 'AMZAmazonAuthorizesFailedScreen',
  AMZAmazonNoMerchantsModalScreen = 'AMZAmazonNoMerchantsModalScreen',
  AMZAmazonAllConnectMerchantsModalScreen = 'AMZAmazonAllConnectMerchantsModalScreen',
  AMZAmazonMerchantsModalScreen = 'AMZAmazonMerchantsModalScreen',
  AMZAmazonProductModalScreen = 'AMZAmazonProductModalScreen',
  AMZAuthorizationSuccessScreen = 'AMZAuthorizationSuccessScreen',
  AMZAmazonProductModalSuccessScreenScreen = 'AMZAmazonProductModalSuccessScreenScreen',
  AMZAmazonDataConnectionSuccessScreen = 'AMZAmazonDataConnectionSuccessScreen',
  AMZAmazonUnavailableMerchantsModal = 'AMZAmazonUnavailableMerchantsModal',
  AMAZON_FLASH_MODAL_SCREENS = 'AMAZON_FLASH_MODAL_SCREENS',
  AMZSelectAdsProfile = 'AMZSelectAdsProfile',
}

const DATA_BOXES_AMAZON_ADS_SUCCESS = [
  {
    isItemComplated: true,
    primaryText:
      I18nKey.FLASH_MODALS_AMAZON_AUTHORIZATION_SUCCESS_ADS_PRIMARY_TEXT,
    secondaryText:
      I18nKey.FLASH_MODALS_AMAZON_AUTHORIZATION_SUCCESS_ADS_SECONDARY_TEXT,
  },
  {
    isItemComplated: false,
    primaryText:
      I18nKey.FLASH_MODALS_AMAZON_AUTHORIZATION_SUCCESS_PRODUCTS_PRIMARY_TEXT,
    secondaryText:
      I18nKey.FLASH_MODALS_AMAZON_AUTHORIZATION_SUCCESS_PRODUCTS_SECONDARY_TEXT,
  },
];

export const AmazonFlashScreens: React.FC<AmazonFlashProps> = ({
  onClose,
  initialScreen,
  refreshTable,
  connectedMerchantIds,
  notOwnedMerchant,
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const [merchantCountries, setMerchantCountries] = useState<
    MerchantCountries[]
  >([]);
  const [ownedMerchantAccounts, setOwnedMerchantAccount] = useState<
    MerchantCountries[]
  >([]);
  const [unavailableMerchantAccounts, setUnavailableMerchantAccount] =
    useState<MerchantCountries[]>();
  const [notOwnedMerchantAccount, setNotOwnedMerchantAccount] = useState<
    RowDataProps[]
  >([]);
  const [connectedMerchants, setConnectedMerchants] = useState<RowDataProps[]>(
    []
  );
  const [loadingMerchantData, setLoadingMerchantData] =
    React.useState<boolean>(true);
  const intl = useIntl();
  const famClient = createFAMApiClient(userContext.userInfo.idToken!);
  const [amazonLwaStatus, setAmazonLwaStatus] = useState<AmazonLwaStatus>();
  const [currentModal, setCurrentModal] =
    useState<AMAZON_FLASH_MODAL_SCREENS | null>(initialScreen || null);
  const [error, setError] = useState<boolean>(false);
  const [lwaCorrelationId, saveLWACorrelationId] = useState<string>('');
  const [connectedProductCount, setConnectProductCount] = useState<
    Record<string, number>
  >({});
  const [connectedProducts, setConnectedProducts] = useState<
    MerchantCountries[] | RowDataProps[]
  >([]);
  const [spapi_oauth_code, setSpapiOauthCode] = useState<string | null>();
  const [vendorProfileConnected, setVendorProfileConnected] = useState(false);
  const [selectedMerchantRegion, setSelectedMerchantRegion] = useState<Region>(
    {} as Region
  );
  const [sellingPartnerId, setSellingPartnerId] = useState<string | null>();
  const [sellingPartnerType, setSellingPartnerType] = useState<SellerType>(
    SellerType['3P']
  );
  const [previousModal, setPreviousModal] =
    useState<AMAZON_FLASH_MODAL_SCREENS | null>(initialScreen || null);

  const [
    showProductsDataConnectionWarningPopup,
    setShowProductsDataConnectionWarningPopup,
  ] = useState<boolean>(false);
  const [
    showMissingMerchantConnectionsWarningPopup,
    setShowMissingMerchantConnectionsWarningPopup,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (Array.isArray(notOwnedMerchant)) {
      setNotOwnedMerchantAccount(notOwnedMerchant);
    }
  }, [notOwnedMerchant]);

  useEffect(() => {
    if (amazonLwaStatus === AmazonLwaStatus.Success) {
      setLoadingMerchantData(true);
      setCurrentModal(AMAZON_FLASH_MODAL_SCREENS.AMZAmazonMerchantsModalScreen);
    }
  }, [amazonLwaStatus]);

  useEffect(() => {
    if (!loadingMerchantData) {
      setPreviousModal(
        AMAZON_FLASH_MODAL_SCREENS.AMZAmazonMerchantsModalScreen
      );
    }
  }, [merchantCountries]);

  useEffect(() => {
    if (!!connectedProducts.length) {
      setConnectProductCount({
        ...connectedProductCount,
        [selectedMerchantRegion.name]: connectedProducts.length,
      });
    }
  }, [connectedProducts]);

  const closeModal = () => {
    setCurrentModal(AMAZON_FLASH_MODAL_SCREENS.AMZInitialConnectScreen);
    onClose();
  };

  const authorize = () => {
    setAmazonLwaStatus(undefined);
    amazonLWA(
      userContext.userInfo.idToken!,
      userContext.userInfo.userDetails!,
      saveLWACorrelationId,
      setAmazonLwaStatus,
      famClient
    );
  };

  const getDisplayScreen = (
    currentModal: AMAZON_FLASH_MODAL_SCREENS | null
  ) => {
    switch (currentModal) {
      case AMAZON_FLASH_MODAL_SCREENS.AMZAmazonAuthFailedScreen:
        return (
          <AmazonAuthFailedModal
            channel={FlywheelSalesChannel.Amazon}
            goBack={() => {
              setCurrentModal(null);
              onClose(true);
            }}
            authorize={() => {
              setCurrentModal(
                AMAZON_FLASH_MODAL_SCREENS.AMZAmazonAuthorizesScreen
              );
            }}
            closeModal={closeModal}
          />
        );
      case AMAZON_FLASH_MODAL_SCREENS.AMZInitialConnectScreen:
        return (
          <ConnectionInitialModal
            channel={FlywheelSalesChannel.Amazon}
            goBack={() => {
              setCurrentModal(null);
              onClose(true);
            }}
            authorize={authorize}
            closeModal={closeModal}
          />
        );
      case AMAZON_FLASH_MODAL_SCREENS.AMZAmazonMerchantsModalScreen:
        return (
          <>
            <ConnectedMerchantsModal
              goBack={() => {
                setCurrentModal(
                  AMAZON_FLASH_MODAL_SCREENS.AMZInitialConnectScreen
                );
              }}
              loadingMerchantData={loadingMerchantData}
              setLoadingMerchantData={setLoadingMerchantData}
              setMerchantCountries={setMerchantCountries}
              famClient={famClient}
              lwaCorrelationId={lwaCorrelationId}
              channel={FlywheelSalesChannel.Amazon}
              primaryButtonText={''}
              setCurrentModal={setCurrentModal}
              selectedMerchantRegion={selectedMerchantRegion}
              saveLWACorrelationId={saveLWACorrelationId}
              setConnectedProducts={setConnectedProducts}
              setConnectedMerchants={setConnectedMerchants}
              setOwnedMerchantAccountMerchants={setOwnedMerchantAccount}
              setUnavailableMerchantAccountMerchants={
                setUnavailableMerchantAccount
              }
              setNotOwnedMerchantAccountMerchants={setNotOwnedMerchantAccount}
              next={() => {
                setCurrentModal(
                  AMAZON_FLASH_MODAL_SCREENS.AMZAuthorizationSuccessScreen
                );
              }}
              closeModal={() => {
                if (
                  ownedMerchantAccounts.length !== 0 &&
                  notOwnedMerchantAccount.length === 0
                ) {
                  closeModal();
                } else {
                  setShowMissingMerchantConnectionsWarningPopup(true);
                }
              }}
            />
            <MerchantConnectionSavedWarningPopup
              isOpen={showMissingMerchantConnectionsWarningPopup}
              onCancel={() => {
                setShowMissingMerchantConnectionsWarningPopup(false);
              }}
              onSubmit={() => {
                setShowMissingMerchantConnectionsWarningPopup(false);
                closeModal();
              }}
            />
          </>
        );

      case AMAZON_FLASH_MODAL_SCREENS.AMZAmazonAllConnectMerchantsModalScreen:
        return (
          <SecondaryMerchantsModal
            onBackButtonClick={() => {
              setCurrentModal(previousModal);
              setPreviousModal(null);
            }}
            merchantCountries={ownedMerchantAccounts || []}
            closeModal={closeModal}
            modalType={SecondaryMerchantsModalType.AlreadyConnectedMerchants}
            channel={FlywheelSalesChannel.Amazon}
          />
        );

      case AMAZON_FLASH_MODAL_SCREENS.AMZAmazonUnavailableMerchantsModal:
        return (
          <SecondaryMerchantsModal
            onBackButtonClick={() => {
              setCurrentModal(previousModal);
              setPreviousModal(null);
            }}
            merchantCountries={unavailableMerchantAccounts || []}
            closeModal={closeModal}
            modalType={SecondaryMerchantsModalType.UnavailableMerchants}
            channel={FlywheelSalesChannel.Amazon}
          />
        );

      case AMAZON_FLASH_MODAL_SCREENS.AMZAmazonNoMerchantsModalScreen:
        return (
          <NoMerchantsModal
            goBack={() => {
              setCurrentModal(
                AMAZON_FLASH_MODAL_SCREENS.AMZAmazonAuthorizesScreen
              );
            }}
            channel={FlywheelSalesChannel.Amazon}
            closeModal={closeModal}
          />
        );
      case AMAZON_FLASH_MODAL_SCREENS.AMZAmazonAuthorizesFailedScreen:
        return (
          <PromptAuthFailed
            mainTextId={
              I18nKey.CONNECT_ADS_MODAL_AMAZON_ERROR_PROMPT_MAIN_MESSAGE
            }
            subTextId={
              I18nKey.CONNECT_ADS_MODAL_AMAZON_ERROR_PROMPT_SUB_MESSAGE
            }
          />
        );
      case AMAZON_FLASH_MODAL_SCREENS.AMZAuthorizationSuccessScreen:
        return (
          <>
            <ConnectionSuccessModal
              isNextStepPresent={true}
              channel={FlywheelSalesChannel.Amazon}
              primaryButtonProps={{
                label: intl.formatMessage({
                  id: I18nKey.AMAZON_CONNECT_SELLING_PARTNER_BUTTON,
                }),
                variant: ButtonVariant.Primary,
                onClick: () =>
                  setCurrentModal(
                    AMAZON_FLASH_MODAL_SCREENS.AMZAmazonProductModalScreen
                  ),
              }}
              textLinkProps={{
                textLabel: intl.formatMessage({
                  id: I18nKey.FLASH_MODALS_BTN_CANCEL_CONNECT_PRODUCTS_DATA,
                }),
                className: 'text-left',
                onClick: () => {
                  setShowProductsDataConnectionWarningPopup(true);
                },
              }}
              dataBoxes={DATA_BOXES_AMAZON_ADS_SUCCESS}
              primaryButtonDataTestId={`flash1_amazon_ads_success_authorize_products`}
              closeModal={closeModal}
            />
            <ProductsDataConnectionWarningPopup
              isOpen={showProductsDataConnectionWarningPopup}
              onCancel={() => {
                setShowProductsDataConnectionWarningPopup(false);
              }}
              onSubmit={() => {
                setShowProductsDataConnectionWarningPopup(false);
                closeModal();
              }}
            />
          </>
        );
      case AMAZON_FLASH_MODAL_SCREENS.AMZAmazonProductModalScreen:
        return (
          <AuthorizeProductsDataModal
            channel={FlywheelSalesChannel.Amazon}
            famClient={famClient}
            connectedProductsCount={connectedProductCount}
            selectedMerchantRegion={selectedMerchantRegion}
            saveLWACorrelationId={saveLWACorrelationId}
            spapi_oauth_code={spapi_oauth_code}
            setSpapiOauthCode={setSpapiOauthCode}
            setSelectedMerchantRegion={setSelectedMerchantRegion}
            setSellingPartnerId={setSellingPartnerId}
            sellingPartnerId={sellingPartnerId}
            sellingPartnerType={sellingPartnerType}
            setSellingPartnerType={setSellingPartnerType}
            vendorProfileConnected={vendorProfileConnected}
            setVendorProfileConnected={setVendorProfileConnected}
            next={() =>
              setCurrentModal(
                sellingPartnerType === SellerType['3P']
                  ? AMAZON_FLASH_MODAL_SCREENS.AMZAmazonProductModalSuccessScreenScreen
                  : AMAZON_FLASH_MODAL_SCREENS.AMZSelectAdsProfile
              )
            }
            connectedMerchants={
              connectedMerchantIds.length > 0
                ? connectedMerchantIds
                : connectedMerchants
            }
            notOwnedMerchantAccount={notOwnedMerchantAccount}
            ownedMerchantAccounts={ownedMerchantAccounts}
            error={error}
            setError={(val) => setError(val)}
            closeModal={closeModal}
            goBack={() =>
              setCurrentModal(
                AMAZON_FLASH_MODAL_SCREENS.AMZAuthorizationSuccessScreen
              )
            }
          />
        );
      case AMAZON_FLASH_MODAL_SCREENS.AMZSelectAdsProfile:
        return (
          <SelectAdsProfileModal
            selectedMerchantRegion={selectedMerchantRegion}
            vendorGroupId={sellingPartnerId}
            spapi_oauth_code={spapi_oauth_code}
            setVendorProfileConnected={setVendorProfileConnected}
            closeModal={closeModal}
            next={() =>
              setCurrentModal(
                AMAZON_FLASH_MODAL_SCREENS.AMZAmazonProductModalSuccessScreenScreen
              )
            }
            goBack={() =>
              setCurrentModal(
                AMAZON_FLASH_MODAL_SCREENS.AMZAmazonProductModalScreen
              )
            }
          />
        );
      case AMAZON_FLASH_MODAL_SCREENS.AMZAmazonProductModalSuccessScreenScreen:
        return (
          <ConnectionSuccessModal
            isNextStepPresent={false}
            channel={FlywheelSalesChannel.Amazon}
            primaryButtonProps={{
              label: intl.formatMessage({
                id: I18nKey.AD_OPTIMIZER_WIZARD_HEADER_BUTTON_NEXT,
              }),
              onClick: () => {
                setCurrentModal(
                  AMAZON_FLASH_MODAL_SCREENS.AMZAmazonDataConnectionSuccessScreen
                );
              },
              variant: ButtonVariant.Primary,
            }}
            closeModal={closeModal}
            primaryButtonDataTestId={`flash1_amazon_products_success_close_and_exit`}
          />
        );
      case AMAZON_FLASH_MODAL_SCREENS.AMZAmazonDataConnectionSuccessScreen:
        return (
          <DataConnectionCompleteModal
            channel={FlywheelSalesChannel.Amazon}
            authorize={() => {
              setCurrentModal(null);
              onClose();
              if (refreshTable) {
                refreshTable();
              }
            }}
            closeModal={closeModal}
          />
        );
      default:
        return <></>;
    }
  };

  return <>{getDisplayScreen(currentModal)}</>;
};
