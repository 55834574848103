import React from 'react';
import { useIntl } from 'react-intl';

import { ColumnAlign } from '@teikametrics/tm-design-system';

import { FlywheelTableV2ColumnProps } from '../../../../../../containers/tableV2/types';
import { HomepageResponseMetricKeys } from '../../../../../../lib/types/CompassSharedTypes';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { makeNumberWithProgressBarColumn } from '../../../../../products/containers/utils/makeTableCellsUtils';
import { BusinessPerformanceColumnName } from '../../utils/constants';
import {
  PerformanceTableExtraProps,
  ProductCellData,
  ProductRowData,
  getNumberWithProgressBarColumnProps,
} from '../../utils/performance';

export const RowCellElement: React.FC<ProductCellData> = (data) => {
  const intl = useIntl();
  return makeNumberWithProgressBarColumn<ProductCellData>(
    ({ currentMetrics, totalSales, activeColumn }) => {
      const currentValue = +(
        currentMetrics?.profitabilityFields?.[
          HomepageResponseMetricKeys.TotalSales
        ]?.amount || 0
      );
      const totalSalesAmount = +(totalSales?.amount || 0);
      return getNumberWithProgressBarColumnProps(
        intl,
        currentValue,
        totalSalesAmount,
        activeColumn === BusinessPerformanceColumnName.TotalSales,
        currentMetrics?.profitabilityFields?.[
          HomepageResponseMetricKeys.TotalSales
        ]?.currency
      );
    }
  )(data);
};

export const totalSales: FlywheelTableV2ColumnProps<
  ProductRowData,
  {},
  PerformanceTableExtraProps
> = {
  header: I18nKey.COMPASS_DASHBOARD_TOTAL_SALES,
  sortable: false,
  size: 135,
  minSize: 135,
  accessorKey: BusinessPerformanceColumnName.TotalSales,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <RowCellElement {...original} {...meta} />,
};
