import { AxiosInstance, AxiosResponse, RawAxiosRequestConfig } from 'axios';

import { IdToken } from '@auth0/auth0-react';

import {
  filterEmptyValues,
  getPaginationParamsFromRequest,
} from '../factories/apiDataFetcherFactory';
import {
  AddedTagsResponse,
  AddedTagsResponseV2,
  AdsRequest,
  AdsResponse,
  AssignmentMode,
  BacnMerchantTagsUpdateRequest,
  BacnMissingTagsRequest,
  BacnProduct,
  BacnProductTagsUpdateRequest,
  BacnUpdateSettingsRequest,
  CogsRequest,
  FlywheelSolution,
  GenericStatusResponse,
  MissingTagsResponse,
  ProductSettingsCOGSResponse,
  ProductSettingsV2Request,
  ProductSettingsV2Response,
  SuggestionApplyRequest,
  SyncStatusRequest,
  SyncStatusResponse,
  TagsRequest,
  UpdateAdsRequest,
  UpdateCogsCORequest,
  UpdateCogsRequest,
  UpdateTagsRequest,
} from '../types/AIPoweredSharedTypes';
import { getDefaultHeaders } from './';
import { PaginatedRequest, PaginatedResponse, PaginatedResult } from './types';
import { filtersToQueryString } from '../utilities/buildUrlUtilities';
import { LikeFilter } from '../types/Filter';

const BASE_URL_CO = process.env.REACT_APP_CORTEX_HOST;
const BASE_URL_IO = process.env.REACT_APP_SKU_HOST;
const BASE_URL_AO = process.env.REACT_APP_AO_HOST;
const BASE_URL_TRE = process.env.REACT_APP_TRE_HOST;
export interface FlowV2ApiClient {
  readonly getProductSettings: (
    accountId: string,
    productSettingsRequest: ProductSettingsV2Request
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<ProductSettingsV2Response>;

  readonly getFlywheelSuggestions: (
    accountId: string,
    productFamilyId: string,
    merchantCountryId: string
  ) => Promise<FlywheelSolution>;

  readonly applyFlywheelSolution: (
    accountId: string,
    productFamilyId: string,
    merchantCountryId: string,
    suggestionId: string
  ) => Promise<FlywheelSolution>;
  readonly getCogs: (
    accountId: string,
    request: CogsRequest
  ) => Promise<ProductSettingsCOGSResponse>;
  readonly updateCogs: (
    accountId: string,
    request: UpdateCogsRequest
  ) => Promise<GenericStatusResponse>;
  readonly updateCogsInCO: (
    accountId: string,
    merchantCountryId: string,
    runId: string,
    groupId: string,
    request: UpdateCogsCORequest
  ) => Promise<GenericStatusResponse>;
  readonly getAdvertisingCampaigns: (
    accountId: string,
    request: AdsRequest
  ) => Promise<AdsResponse>;
  readonly getTags: (
    accountId: string,
    request: TagsRequest
  ) => Promise<AddedTagsResponse>;
  readonly updateTags: (
    accountId: string,
    request: UpdateTagsRequest
  ) => Promise<GenericStatusResponse>;
  readonly updateAds: (
    accountId: string,
    request: UpdateAdsRequest
  ) => Promise<GenericStatusResponse>;
  readonly syncStatus: (
    accountId: string,
    request: SyncStatusRequest
  ) => Promise<SyncStatusResponse>;
  readonly suggestionApply: (
    accountId: string,
    request: SuggestionApplyRequest
  ) => Promise<GenericStatusResponse>;
  readonly getAdProductAddSuggestion: (
    accountId: string,
    productFamilyId: string,
    merchantCountryId: string
  ) => Promise<FlywheelSolution>;
  readonly adProductAddSuggestionApply: (
    accountId: string,
    productFamilyId: string,
    merchantCountryId: string,
    suggestionId: string
  ) => Promise<GenericStatusResponse>;
  readonly getBacnMissingTags: (
    request: BacnMissingTagsRequest
  ) => Promise<MissingTagsResponse>;
  readonly updateProductBacnTags: (
    request: BacnProductTagsUpdateRequest
  ) => Promise<void>;
  readonly updateMerchantBacnTags: (
    request: BacnMerchantTagsUpdateRequest
  ) => Promise<void>;
  readonly getBacnProducts: (
    merchantCountryId: string
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<BacnProduct>>;
  readonly getBacnActiveProductTags: (
    productId: string,
    merchantCountryId: string
  ) => Promise<AddedTagsResponseV2>;
  readonly updateBacnSettings: (
    merchantCountryId: string,
    request: BacnUpdateSettingsRequest
  ) => Promise<void>;
  readonly getBacnActiveMerchantTags: (
    merchantCountryId: string
  ) => Promise<AddedTagsResponseV2>;
  readonly exportBacnTemplate: (
    merchantCountryId: string,
    assignMode: AssignmentMode
  ) => Promise<AxiosResponse<Blob>>;
  readonly bacnCsvUpload: (
    merchantCountryId: string,
    assignMode: AssignmentMode,
    file: File
  ) => Promise<AxiosResponse<void>>;
}

export const PATHS = Object.freeze({
  PRODUCT_SETTINGS: (accountId: string, dummyEndpoint?: boolean) =>
    `/${
      dummyEndpoint ? 'dummy/' : ''
    }accounts/${accountId}/product-settings/product-families`,
  AI_COGS: (accountId: string) =>
    `/accounts/${accountId}/product-settings/product-family/cogs`,
  AI_UPDATE_COGS: (accountId: string) =>
    `/accounts/${accountId}/product-settings/product-family/update-cogs`,
  AI_UPDATE_COGS_IN_CO: (
    accountId: string,
    merchantCountryId: string,
    runId: string,
    groupId: string
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/group-id/${groupId}/pagm`,
  AI_ADVERTISING_CAMPAIGNS: (accountId: string, dummyEndpoint?: boolean) =>
    `/${
      dummyEndpoint ? 'dummy/' : ''
    }accounts/${accountId}/product-settings/product-family/ads`,
  AI_TAGS: (accountId: string, dummyEndpoint?: boolean) =>
    `/${
      dummyEndpoint ? 'dummy/' : ''
    }accounts/${accountId}/product-settings/product-family/list-tags`,
  AI_UPDATE_TAGS: (accountId: string, dummyEndpoint?: boolean) =>
    `/${
      dummyEndpoint ? 'dummy/' : ''
    }accounts/${accountId}/product-settings/product-family/update-tags`,
  FLYWHEEL_SUGGESTIONS: (accountId: string, dummyEndpoint?: boolean) =>
    `/v1/accounts/${accountId}/${
      dummyEndpoint ? 'dummy/' : ''
    }product-settings/product-family/align-campaigns/suggestion`,
  APPLY_FLYWHEEL_SOLUTION: (accountId: string, dummyEndpoint?: boolean) =>
    `/v1/accounts/${accountId}/${
      dummyEndpoint ? 'dummy/' : ''
    }product-settings/product-family/align-campaigns`,
  AI_UPDATE_ADS: (accountId: string) =>
    `/accounts/${accountId}/product-settings/product-family/update-ads`,
  SYNC_STATUS: (accountId: string, dummyEndpoint?: boolean) =>
    `/v1/accounts/${accountId}/${
      dummyEndpoint ? 'dummy/' : ''
    }product-settings/product-family/sync-status`,
  SUGGESTION_APPLY: (accountId: string, dummyEndpoint?: boolean) =>
    `/accounts/${accountId}/${
      dummyEndpoint ? 'dummy/' : ''
    }product-settings/product-family/suggestion/apply`,
  ADD_PRODUCT_AD_SUGGESTIONS: (accountId: string, dummyEndpoint?: boolean) =>
    `/v1/accounts/${accountId}/${
      dummyEndpoint ? 'dummy/' : ''
    }product-family/product-ads/suggestions`,
  ADD_PRODUCT_AD_SUGGESTION_APPLY: (
    accountId: string,
    dummyEndpoint?: boolean
  ) =>
    `/v1/accounts/${accountId}/${
      dummyEndpoint ? 'dummy/' : ''
    }product-family/product-ads/suggestions/apply`,
  MISSING_TAGS: () => '/product-tagging/merchant-country-details',
  UPDATE_PRODUCT_TAGS: () => '/product-tagging/products/update-tags',
  UPDATE_MERCHANT_TAGS: () => '/product-tagging/update-tags',
  BACN_PRODUCTS: (merchantCountryId: string) =>
    `/product-tagging/${merchantCountryId}/products`,
  ACTIVE_PRODUCT_TAGS: (productId: string, merchantCountryId: string) =>
    `/product-tagging/${merchantCountryId}/products/${productId}/tags`,
  UPDATE_BACN_SETTINGS: (merchantCountryId: string) =>
    `/product-tagging/${merchantCountryId}/settings`,
  ACTIVE_MERCHANT_TAGS: (merchantCountryId: string) =>
    `/product-tagging/${merchantCountryId}/tags`,
  EXPORT_BACN_TEMPLATE: (merchantCountryId: string) =>
    `product-tagging/${merchantCountryId}/templates`,
  UPLOAD_BACN_TEMPLATE: (merchantCountryId: string) =>
    `product-tagging/${merchantCountryId}/upload`,
  UPLOAD_BACN_TEMPLATE_PRODUCTS: (merchantCountryId: string) =>
    `product-tagging/${merchantCountryId}/products/upload`,
});

export const createFlowV2ApiClient = (
  token: IdToken | null,
  axios: AxiosInstance
): FlowV2ApiClient => {
  const config: RawAxiosRequestConfig = {
    headers: {
      ...getDefaultHeaders(),
      Authorization: `Bearer ${token?.__raw}`,
    },
    baseURL: BASE_URL_IO,
  };

  const getProductSettings =
    (accountId: string, productSettingsRequest: ProductSettingsV2Request) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<ProductSettingsV2Response> => {
      if (productSettingsRequest.merchantCountries.length === 0) {
        return { merchantCountries: [] };
      }
      const allParams = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(allParams);

      const result = await axios.post<ProductSettingsV2Response>(
        PATHS.PRODUCT_SETTINGS(accountId),
        productSettingsRequest,
        {
          ...config,
          params,
        }
      );
      return result.data;
    };

  const getCogs = async (
    accountId: string,
    request: CogsRequest
  ): Promise<ProductSettingsCOGSResponse> => {
    const response = await axios.post<ProductSettingsCOGSResponse>(
      PATHS.AI_COGS(accountId),
      {
        ...request,
      },
      { ...config, baseURL: BASE_URL_IO }
    );
    return response.data;
  };

  const updateCogs = async (
    accountId: string,
    request: UpdateCogsRequest
  ): Promise<GenericStatusResponse> => {
    const response = await axios.post<GenericStatusResponse>(
      PATHS.AI_UPDATE_COGS(accountId),
      {
        ...request,
      },
      { ...config, baseURL: BASE_URL_IO }
    );
    return response.data;
  };

  const updateCogsInCO = async (
    accountId: string,
    merchantCountryId: string,
    runId: string,
    groupId: string,
    request: UpdateCogsCORequest
  ): Promise<GenericStatusResponse> => {
    const response = await axios.post<GenericStatusResponse>(
      PATHS.AI_UPDATE_COGS_IN_CO(accountId, merchantCountryId, runId, groupId),
      {
        ...request,
      },
      { ...config, baseURL: BASE_URL_CO }
    );
    return response.data;
  };

  const getAdvertisingCampaigns = async (
    accountId: string,
    request: AdsRequest
  ): Promise<AdsResponse> => {
    const response = await axios.post<AdsResponse>(
      PATHS.AI_ADVERTISING_CAMPAIGNS(accountId),
      {
        ...request,
      },
      {
        ...config,
        baseURL: BASE_URL_AO,
      }
    );
    return response.data;
  };

  const getTags = async (
    accountId: string,
    request: TagsRequest
  ): Promise<AddedTagsResponse> => {
    const response = await axios.post<AddedTagsResponse>(
      PATHS.AI_TAGS(accountId),
      {
        ...request,
      },
      {
        ...config,
        baseURL: BASE_URL_TRE,
      }
    );
    return response.data;
  };

  const getFlywheelSuggestions = async (
    accountId: string,
    productFamilyId: string,
    merchantCountryId: string
  ) => {
    const response = await axios.post<FlywheelSolution>(
      PATHS.FLYWHEEL_SUGGESTIONS(accountId),
      {
        flowV2: false,
        productFamilyId,
        merchantCountryId,
      },
      {
        ...config,
        baseURL: BASE_URL_CO,
      }
    );
    return response.data;
  };

  const updateTags = async (
    accountId: string,
    request: UpdateTagsRequest
  ): Promise<GenericStatusResponse> => {
    const response = await axios.post<GenericStatusResponse>(
      PATHS.AI_UPDATE_TAGS(accountId),
      {
        ...request,
      },
      {
        ...config,
        baseURL: BASE_URL_TRE,
      }
    );
    return response.data;
  };

  const applyFlywheelSolution = async (
    accountId: string,
    productFamilyId: string,
    merchantCountryId: string,
    suggestionId: string
  ) => {
    const response = await axios.post<FlywheelSolution>(
      PATHS.APPLY_FLYWHEEL_SOLUTION(accountId),
      {
        flowV2: false,
        suggestionId,
        productFamilyId,
        merchantCountryId,
      },
      {
        ...config,
        baseURL: BASE_URL_CO,
      }
    );
    return response.data;
  };

  const updateAds = async (
    accountId: string,
    request: UpdateAdsRequest
  ): Promise<GenericStatusResponse> => {
    const response = await axios.post<GenericStatusResponse>(
      PATHS.AI_UPDATE_ADS(accountId),
      {
        ...request,
      },
      { ...config, baseURL: BASE_URL_AO }
    );
    return response.data;
  };

  const syncStatus = async (
    accountId: string,
    request: SyncStatusRequest
  ): Promise<SyncStatusResponse> => {
    const response = await axios.post<SyncStatusResponse>(
      PATHS.SYNC_STATUS(accountId),
      {
        ...request,
      },
      {
        ...config,
        baseURL: BASE_URL_CO,
      }
    );
    return response.data;
  };

  const suggestionApply = async (
    accountId: string,
    request: SuggestionApplyRequest
  ): Promise<GenericStatusResponse> => {
    const response = await axios.post<GenericStatusResponse>(
      PATHS.SUGGESTION_APPLY(accountId),
      {
        ...request,
      },
      { ...config, baseURL: BASE_URL_IO }
    );
    return response.data;
  };

  const getAdProductAddSuggestion = async (
    accountId: string,
    productFamilyId: string,
    merchantCountryId: string
  ): Promise<FlywheelSolution> => {
    const response = await axios.post<FlywheelSolution>(
      PATHS.ADD_PRODUCT_AD_SUGGESTIONS(accountId),
      {
        flowV2: false,
        productFamilyId,
        merchantCountryId,
      },
      {
        ...config,
        baseURL: BASE_URL_CO,
      }
    );
    return response.data;
  };

  const adProductAddSuggestionApply = async (
    accountId: string,
    productFamilyId: string,
    merchantCountryId: string,
    suggestionId: string
  ): Promise<GenericStatusResponse> => {
    const response = await axios.post<GenericStatusResponse>(
      PATHS.ADD_PRODUCT_AD_SUGGESTION_APPLY(accountId),
      {
        flowV2: false,
        suggestionId,
        productFamilyId,
        merchantCountryId,
      },
      {
        ...config,
        baseURL: BASE_URL_CO,
      }
    );
    return response.data;
  };

  const getBacnMissingTags = async (
    request: BacnMissingTagsRequest
  ): Promise<MissingTagsResponse> => {
    const response = await axios.post<MissingTagsResponse>(
      PATHS.MISSING_TAGS(),
      {
        ...request,
      },
      {
        ...config,
        baseURL: BASE_URL_TRE,
      }
    );
    return response.data;
  };

  const updateProductBacnTags = async (
    request: BacnProductTagsUpdateRequest
  ): Promise<void> => {
    await axios.post<void>(
      PATHS.UPDATE_PRODUCT_TAGS(),
      {
        ...request,
      },
      {
        ...config,
        baseURL: BASE_URL_TRE,
      }
    );
  };

  const updateMerchantBacnTags = async (
    request: BacnMerchantTagsUpdateRequest
  ): Promise<void> => {
    await axios.post<void>(
      PATHS.UPDATE_MERCHANT_TAGS(),
      {
        ...request,
      },
      {
        ...config,
        baseURL: BASE_URL_TRE,
      }
    );
  };

  const getBacnProducts =
    (merchantCountryId: string) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<BacnProduct>> => {
      const allParams = {
        ...getPaginationParamsFromRequest(paginatedRequest),
        filter: filtersToQueryString(
          paginatedRequest.filters.map((filter) => {
            if (filter.field === 'search') {
              return {
                ...filter,
                value: `%${filter.value}%`,
              } as LikeFilter;
            }
            return filter;
          })
        ),
      };
      const params = filterEmptyValues(allParams);

      const response = await axios.get<PaginatedResponse<BacnProduct>>(
        PATHS.BACN_PRODUCTS(merchantCountryId),
        {
          ...config,
          baseURL: BASE_URL_TRE,
          params,
        }
      );
      return {
        totalItems: response.data.filteredElements,
        items: response.data.elements,
      };
    };

  const getBacnActiveProductTags = async (
    productId: string,
    merchantCountryId: string
  ): Promise<AddedTagsResponseV2> => {
    const response = await axios.get<AddedTagsResponseV2>(
      PATHS.ACTIVE_PRODUCT_TAGS(productId, merchantCountryId),
      {
        ...config,
        baseURL: BASE_URL_TRE,
      }
    );
    return response.data;
  };

  const updateBacnSettings = async (
    merchantCountryId: string,
    request: BacnUpdateSettingsRequest
  ): Promise<void> => {
    await axios.post<void>(
      PATHS.UPDATE_BACN_SETTINGS(merchantCountryId),
      {
        ...request,
      },
      {
        ...config,
        baseURL: BASE_URL_TRE,
      }
    );
  };

  const getBacnActiveMerchantTags = async (
    merchantCountryId: string
  ): Promise<AddedTagsResponseV2> => {
    const response = await axios.get<AddedTagsResponseV2>(
      PATHS.ACTIVE_MERCHANT_TAGS(merchantCountryId),
      {
        ...config,
        baseURL: BASE_URL_TRE,
      }
    );
    return response.data;
  };

  const exportBacnTemplate = async (
    merchantCountryId: string,
    assignMode: AssignmentMode
  ): Promise<AxiosResponse<Blob>> => {
    return axios.post(
      PATHS.EXPORT_BACN_TEMPLATE(merchantCountryId),
      {
        assignMode,
      },
      {
        ...config,
        responseType: 'blob',
        baseURL: BASE_URL_TRE,
      }
    );
  };

  const bacnCsvUpload = async (
    merchantCountryId: string,
    assignMode: AssignmentMode,
    file: File
  ) => {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(
      assignMode === AssignmentMode.All
        ? PATHS.UPLOAD_BACN_TEMPLATE(merchantCountryId)
        : PATHS.UPLOAD_BACN_TEMPLATE_PRODUCTS(merchantCountryId),
      formData,
      {
        ...config,
        headers: {
          ...config.headers,
          'content-type': 'multipart/form-data',
        },
        baseURL: BASE_URL_TRE,
      }
    );
  };

  return {
    getProductSettings,
    getFlywheelSuggestions,
    applyFlywheelSolution,
    getCogs,
    updateCogs,
    updateCogsInCO,
    getAdvertisingCampaigns,
    getTags,
    updateTags,
    updateAds,
    syncStatus,
    suggestionApply,
    getAdProductAddSuggestion,
    adProductAddSuggestionApply,
    getBacnMissingTags,
    updateProductBacnTags,
    updateMerchantBacnTags,
    getBacnProducts,
    getBacnActiveProductTags,
    updateBacnSettings,
    getBacnActiveMerchantTags,
    exportBacnTemplate,
    bacnCsvUpload,
  };
};
