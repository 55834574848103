export enum OptimizelyFlags {
  AOManageBidding = 'ao_manage_bidding',
  BIProductMetaData = 'bi_product_meta_data',
  MiBulkExport = 'mi_bulk_export',
  TreUpload = 'tre_upload',
  ShowGmroiColumn = 'show_gmroi_column',
  MiCampaings = 'mi_campaings',
  RecommendationHubShowAoCardFor1p = 'recommendation_hub_show_ao_card_for_1p',
  KnockNotification = 'knock_notification',
  CampaignPatType = 'pat_campaign',
  ManagedEditionPlusAccount = 'managed_edition_plus_account',
  BulkProductUpload = 'bulk_product_upload',
  WalmartAdvertisingConnection = 'walmart_advertising_connection',
  NotificationInbox = 'notification_inbox',
  MIShareOfVoice = 'mi_share_of_voice',
  CampaignCreatorHelp = 'campaign_creator_help',
  KeywordActionsIndividualRow = 'keyword_actions_individual_row_actions',
  CoCampaignName = 'co_campaign_name',
  InventoryOptimization = 'inventory_optimization',
  BulkActions = 'bulk_actions',
  AnalystBICustomReporting = 'analyst_bi_custom_reporting',
  RecHubIOTwoNewCards = 'rec_hub_io_2_new_cards',
  CampaignKeywordColdStart = 'campaign_keyword_cold_start',
  SmartCampaignCreator = 'smart_campaign_creator',
  EmbeddedBiAmcDashboards = 'embedded_bi_amc_dashboards',
  BACNTagging = 'bacn_tagging',
  EmbeddedBiAmcDashboardsV2 = 'embedded_bi_amc_dashboards_v2',
  ShowSponsoredVideos = 'show_sponsored_videos',
  SelfServiceAnnualBilling = 'self-service_annual_billing',
  SPPatKWA = 'sp_pat_kwa',
  SponsoredVideosEditable = 'ao_sponsored_videos_edits',
  DemandForecastActiveCampaigns = 'demand_forecast_active_campaigns',
  KWASponsoredBrands = 'kwa_sponsored_brands',
  SmartAdGroupsBidOverrides = 'smart_adgroups_bid_overrides',
}
