import isEmpty from 'lodash/isEmpty';
import { MerchantCountryCode } from '../../../../../lib/types/AOSharedTypes';
import { MERCHANT_COUNTRY_CODE_TO_BASE_EXTERNAL_URL_MAPPER } from '../types';

export const getAmazonItemPageUrl = (
  merchantCountryCode?: MerchantCountryCode,
  skuOrAsin?: string
): string | undefined => {
  let pageUrl;
  if (merchantCountryCode && skuOrAsin) {
    pageUrl = !isEmpty(
      MERCHANT_COUNTRY_CODE_TO_BASE_EXTERNAL_URL_MAPPER[merchantCountryCode]
    )
      ? MERCHANT_COUNTRY_CODE_TO_BASE_EXTERNAL_URL_MAPPER[merchantCountryCode] +
        skuOrAsin
      : undefined;
  }
  return pageUrl;
};
