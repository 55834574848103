import { useIntl } from 'react-intl';

import { AdLevel } from '../../../../../../lib/types/AOSharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { AdLevelI8nKeyMapper } from '../../types';
import { getTooltipContentForArchivedEntity } from '../../utils';

export const BidTooltipForInvalidValue: React.FC<{
  readonly isTargetArchived?: boolean;
  readonly adLevel?: AdLevel;
  readonly isCurrentValueMoreThanHalfOfDailyBudget?: boolean | '';
  readonly isInputGreaterThanDailyOrLifetimeBudget?: boolean;
  readonly merchantCurrency?: string | null;
  readonly minBid?: number;
  readonly maxBid?: number;
}> = ({
  isTargetArchived,
  adLevel,
  isCurrentValueMoreThanHalfOfDailyBudget,
  isInputGreaterThanDailyOrLifetimeBudget,
  merchantCurrency,
  minBid,
  maxBid,
}) => {
  const intl = useIntl();
  const getInvalidErrorText = () => {
    if (isCurrentValueMoreThanHalfOfDailyBudget) {
      return I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_INVALID_VALUE_GREATER_THAN_HALF_OF_DAILY_BUDGET;
    } else if (isInputGreaterThanDailyOrLifetimeBudget) {
      return I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_INVALID_VALUE_GREATER_THAN_DAILY_BUDGET;
    } else {
      return I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_INVALID_VALUE_ERROR_TEXT;
    }
  };

  if (isTargetArchived) {
    return getTooltipContentForArchivedEntity(
      adLevel
        ? intl.formatMessage({
            id: AdLevelI8nKeyMapper[adLevel],
          })
        : '',
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_BID,
      }),
      intl
    );
  }

  return (
    <p className="w-180 text-center">
      {intl.formatMessage(
        {
          id: getInvalidErrorText(),
        },
        {
          currency: merchantCurrency,
          minBid:
            minBid &&
            intl.formatNumber(minBid, {
              minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              maximumFractionDigits: MONETARY_FRACTION_DIGITS,
            }),
          maxBid: maxBid && intl.formatNumber(maxBid),
        }
      )}
    </p>
  );
};
BidTooltipForInvalidValue.displayName = 'BidTooltipForInvalidValue';
