import { RawAxiosRequestHeaders } from 'axios';
import { FWCookie, PERSISTED_KEYS } from '../utilities/fwCookie';

export const getDefaultHeaders = () => {
  const headers: RawAxiosRequestHeaders = {
    Accept: 'application/json',
  };

  const accountId = FWCookie.readCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID);

  if (accountId) {
    headers['tm-account-id'] = accountId;
  }

  return headers;
};

export const DEFAULT_AXIOS_CONFIG = Object.freeze({
  headers: getDefaultHeaders(),
});
