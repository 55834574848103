import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import uuid from 'uuid';

import {
  FilteredDataFetcher,
  PaginatedDataFetcher,
  PaginatedRequest,
  PaginatedResult,
} from '../../../lib/clients/types';
import {
  TableAction,
  dataLoadComplete,
  dataLoadFailed,
  dataLoadInitiated,
  dataReset,
  footerDataLoadComplete,
  footerDataLoadFailed,
  footerDataLoadInitiated,
} from './actions';
import { tableSelectors } from './index';
import { WithTable } from './types';

export function loadPageOfData<S extends WithTable<R, F>, R, F, E>(
  tableId: string,
  paginatedRequest: PaginatedRequest,
  dataFetcher: PaginatedDataFetcher<R>
): ThunkAction<Promise<PaginatedResult<R>>, S, E, TableAction> {
  return (
    dispatch: ThunkDispatch<S, E, TableAction>
  ): Promise<PaginatedResult<R>> => {
    const requestId = uuid.v4();

    dispatch(
      dataLoadInitiated({
        tableId,
        requestId,
        page: paginatedRequest.page,
      })
    );

    return dataFetcher(paginatedRequest)
      .then((result) => {
        dispatch(
          dataLoadComplete({
            tableId,
            page: paginatedRequest.page,
            requestId,
            items: result.items,
            totalItems: result.totalItems,
          })
        );
        return result;
      })
      .catch((e) => {
        dispatch(
          dataLoadFailed({
            page: paginatedRequest.page,
            tableId,
            requestId,
          })
        );
        return e;
      });
  };
}

export function refreshTable<S extends WithTable<T>, T, F, E>(
  tableId: string,
  dataFetcher: PaginatedDataFetcher<T>,
  footerDataFetcher?: FilteredDataFetcher<F>
): ThunkAction<Promise<PaginatedResult<T>>, S, E, TableAction> {
  return (
    dispatch: ThunkDispatch<S, E, TableAction>,
    getState: () => S
  ): Promise<PaginatedResult<T>> => {
    const requestId = uuid.v4();
    const page = 1;
    const state = getState();

    const { sorts, filters, extraPaginationParams, itemsPerPage } =
      tableSelectors.getTableSelector()(state.tableState, tableId);

    dispatch(
      dataReset({
        tableId,
      })
    );

    dispatch(dataLoadInitiated({ tableId, requestId, page }));

    let footerDataPromise = Promise.resolve({}) as Promise<F>;
    if (footerDataFetcher) {
      footerDataPromise = loadFooterData(tableId, footerDataFetcher)(
        dispatch,
        getState,
        {}
      );
    }

    return dataFetcher({
      filters,
      extraParams: extraPaginationParams,
      sorts,
      itemsPerPage,
      page,
    })
      .then(async (result) => {
        dispatch(
          dataLoadComplete({
            tableId,
            page,
            requestId,
            items: result.items,
            totalItems: result.totalItems,
          })
        );

        await footerDataPromise;
        return result;
      })
      .catch(async (e) => {
        dispatch(
          dataLoadFailed({
            tableId,
            page,
            requestId,
          })
        );

        await footerDataPromise;
        return e;
      });
  };
}

export function loadFooterData<S extends WithTable<T, F>, T, F, E>(
  tableId: string,
  footerDataFetcher: FilteredDataFetcher<F>
): ThunkAction<Promise<F>, S, E, TableAction> {
  return (
    dispatch: ThunkDispatch<S, E, TableAction>,
    getState: () => S
  ): Promise<F> => {
    const { filters, extraPaginationParams } =
      tableSelectors.getTableSelector()(getState().tableState, tableId);

    dispatch(footerDataLoadInitiated({ tableId }));

    return footerDataFetcher({ filters, extraParams: extraPaginationParams })
      .then((footerData) => {
        dispatch(footerDataLoadComplete({ tableId, footerData }));
        return footerData;
      })
      .catch((e) => {
        dispatch(footerDataLoadFailed({ tableId }));
        return e;
      });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loadPageOfData,
  refreshTable,
};
