import { SbAdsDetails } from 'lib/types/AOSharedTypes';
import { FlywheelTableColumnGroup } from '../../../../../containers/table/UpdatedFlywheelTable';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { creativeNameColumn } from './creativeName';
import { campaignNameColumn } from './campaignName';
import { campaignStatusColumn } from './campaignStatus';
import { adGroupNameColumn } from './adGroupName';
import { adSpendColumn } from './adSpend';
import { adConversionsColumn } from './adConversions';
import { unitsSoldColumn } from './unitsSold';
import { impressionsColumn } from './impressions';
import { clicksColumn } from './clicks';
import { clickThroughRateColumn } from './clickThroughRate';
import { percentageOfAdUnitsSoldNewToBrandColumn } from './percentageOfAdUnitsSoldNewToBrand';
import { newToBrandAdUnitsSoldColumn } from './newToBrandAdUnitsSold';
import { percentageOfSalesNewToBrandColumn } from './percentageOfSalesNewToBrand';
import { newToBrandOrdersRateColumn } from './newToBrandOrdersRate';
import { newToBrandOrdersColumn } from './newToBrandOrders';
import { percentageOfOrdersNewToBrandColumn } from './percentageOfOrdersNewToBrand';
import { newToBrandAdSalesColumn } from './newToBrandAdSales';
import { adSalesColumn } from './adSales';
import { campaignDailyBudgetColumn } from './campaignDailyBudget';
import { adGroupStatusColumn } from './adGroupStatus';
import { acosColumn } from './acos';
import { roasColumn } from './roas';
import { adConversionsRateColumn } from './adConversionsRate';
import { averageCostPerClickColumn } from './averageCostPerClick';
import { portfolioNameColumn } from './portfolioName';
import { creativeTypeColumn } from './creativeType';
import { servingStatusColumn } from './creativeServingStatus';
import { adStatusColumn } from './adStatus';
import { CheckboxCheckedState } from '@teikametrics/tm-design-system';

export interface GenerateColumnsArgs {
  readonly isEditMode: boolean;
  readonly showBulkEditSlideOut?: boolean;
  readonly headerExtraProps?: {
    readonly onSelectChange: React.MouseEventHandler<HTMLElement>;
    readonly checked: CheckboxCheckedState;
  };
}

export const generateSbAdsColumns = (): FlywheelTableColumnGroup<
  SbAdsDetails,
  TableDataAdsManager
>[] => {
  return [
    {
      groupingHeaderI18nKey: I18nKey.ADS_MANAGER_SB_ADS_TABLE_GROUP_DETAILS,
      columnsInGroup: [creativeNameColumn],
    },
    {
      columnsInGroup: [
        creativeTypeColumn,
        servingStatusColumn,
        adGroupNameColumn,
        adGroupStatusColumn,
        campaignNameColumn,
        campaignDailyBudgetColumn,
        campaignStatusColumn,
        portfolioNameColumn,
      ],
    },
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_SB_ADS_TABLE_GROUP_CHANNEL_SETTING,
      columnsInGroup: [adStatusColumn],
    },
    {
      groupingHeaderI18nKey: I18nKey.ADS_MANAGER_SB_ADS_TABLE_GROUP_PERFORMANCE,
      columnsInGroup: [
        adSalesColumn,
        adSpendColumn,
        acosColumn,
        roasColumn,
        adConversionsColumn,
        adConversionsRateColumn,
        unitsSoldColumn,
        impressionsColumn,
        clicksColumn,
        averageCostPerClickColumn,
        clickThroughRateColumn,
        newToBrandOrdersColumn,
        percentageOfOrdersNewToBrandColumn,
        newToBrandOrdersRateColumn,
        newToBrandAdSalesColumn,
        percentageOfSalesNewToBrandColumn,
        newToBrandAdUnitsSoldColumn,
        percentageOfAdUnitsSoldNewToBrandColumn,
      ],
    },
  ];
};
