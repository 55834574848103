import React, { useContext, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import {
  ColumnAlign,
  TableCellV2,
  TableCellV2Type,
} from '@teikametrics/tm-design-system';

import { useDispatch } from 'react-redux';
import { tableThunks } from '../../../../../containers/tableV2/ducks';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import {
  FAMConnectionStatus,
  FlywheelSalesChannel,
  SalesChannelData,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { EntityType, getSalesChannelId } from '../../utils';
import {
  RowCellElementProps,
  SALES_CHANNELS_TABLE,
  SalesChannelTableData,
  StatusData,
} from '../types';
import { MerchantSyncDate } from '../../../../../lib/types/AOSharedTypes';
import { dataFetcherSalesChannelsTable } from '../utils/hooks';
import {
  getConnectionStatus,
  getRetryConnection,
  getStatusData,
  getSyncStatusIcon,
} from '../utils';
import { AmazonWalmartFlashScreen } from '../../../components/AmazonWalmartFlashScreen';
import { ConnectionStatus } from '../../../components/ConnectionStatus';

const getShouldShowConnectLink = (
  isAmazon: boolean,
  adConnectionStatus: string,
  connectionStatus: string,
  retryConnection: boolean,
  entity?: EntityType,
  isUserRoleViewOnly?: boolean,
  extMerchantType?: string
) => {
  if (isUserRoleViewOnly) {
    return false;
  }
  let shouldShowConnectLink = false;
  if (!isAmazon) {
    //if walmart channel
    if (entity === EntityType.Products) {
      //if entity is products
      if (
        adConnectionStatus === FAMConnectionStatus.Active &&
        connectionStatus !== FAMConnectionStatus.Active
      ) {
        //if adConnectionStatus is active and connectionStatus is not active
        shouldShowConnectLink = true;
      } else if (
        adConnectionStatus === FAMConnectionStatus.Active &&
        extMerchantType === 'seller'
      ) {
        //if adConnectionStatus is active and extMerchantType is seller, always show connect link
        shouldShowConnectLink = true;
      }
    } else if (!connectionStatus || retryConnection) {
      //if entity is ads and (if connectionStatus is null or retryConnection is true)
      shouldShowConnectLink = true;
    }
  } else if (!connectionStatus || retryConnection) {
    //if amazon channel and (if connectionStatus is null or retryConnection is true)
    shouldShowConnectLink = true;
  }
  return shouldShowConnectLink;
};

const getCell = (
  connectionStatus: string,
  retryConnection: boolean,
  isAmazon: boolean,
  textProp: StatusData,
  onLinkClick: () => void,
  intl: IntlShape,
  adConnectionStatus: string,
  entity?: EntityType,
  adConnectionData?: MerchantSyncDate,
  productConnectionData?: MerchantSyncDate,
  isUserRoleViewOnly?: boolean,
  extMerchantType?: string
) => {
  const shouldShowConnectLink = getShouldShowConnectLink(
    isAmazon,
    adConnectionStatus,
    connectionStatus,
    retryConnection,
    entity,
    isUserRoleViewOnly,
    extMerchantType
  );

  const status = getSyncStatusIcon(
    connectionStatus,
    retryConnection,
    intl,
    entity,
    adConnectionData,
    productConnectionData
  );

  return {
    currentValue: (
      <ConnectionStatus
        shouldShowConnectLink={shouldShowConnectLink}
        status={status}
        textProp={textProp}
        onLinkClick={onLinkClick}
        isUserRoleViewOnly={isUserRoleViewOnly}
      />
    ),
  };
};

export const DataConnectionRowCell: React.FC<
  RowCellElementProps & {
    isAmazon: boolean;
    entity: EntityType;
    adConnectionData?: MerchantSyncDate;
    productConnectionData?: MerchantSyncDate;
    isUserRoleViewOnly?: boolean;
  }
> = (props) => {
  const {
    adConnectionStatus,
    productConnectionStatus,
    adConnectionData,
    productConnectionData,
    isAmazon,
    entity,
    isUserRoleViewOnly,
    extMerchantType,
  } = props;
  const dispatch = useDispatch();
  const userContext = useContext<UserContextState>(UserContext);
  const userAccount = getCurrentAccountFromContext(userContext)!;

  const [showModal, setShowModal] = useState<boolean>(false);

  const intl = useIntl();

  const connectionStatus = getConnectionStatus(
    entity,
    adConnectionStatus,
    productConnectionStatus
  );

  const retryConnection = getRetryConnection(connectionStatus);

  const status = getStatusData(connectionStatus, isAmazon);

  const refreshTable = () => {
    dispatch(
      tableThunks.refreshTable(
        SALES_CHANNELS_TABLE,
        dataFetcherSalesChannelsTable(
          userContext,
          userAccount.id,
          getSalesChannelId(isAmazon),
          isAmazon ? FlywheelSalesChannel.Amazon : FlywheelSalesChannel.Walmart
        )
      )
    );
  };

  const closeModal = (channel: FlywheelSalesChannel) => {
    setShowModal(false);
    refreshTable();
  };

  const onLinkClick = () => {
    setShowModal(true);
  };

  const cell = getCell(
    connectionStatus,
    retryConnection,
    isAmazon,
    status,
    onLinkClick,
    intl,
    adConnectionStatus,
    entity,
    adConnectionData,
    productConnectionData,
    isUserRoleViewOnly,
    extMerchantType
  );

  return (
    <>
      <AmazonWalmartFlashScreen
        {...props}
        entity={entity}
        channel={
          isAmazon ? FlywheelSalesChannel.Amazon : FlywheelSalesChannel.Walmart
        }
        closeModal={closeModal}
        showModal={showModal}
      />
      <TableCellV2
        tableCellData={{
          ...cell,
          tableCellType: TableCellV2Type.IconWithText,
        }}
        className="h-full justify-center"
      />
    </>
  );
};
DataConnectionRowCell.displayName = 'DataConnectionRowCell';

export const adConnectionColumn: FlywheelTableV2ColumnProps<
  SalesChannelData,
  {},
  SalesChannelTableData,
  {}
> = {
  header: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_AD,
  cell: ({
    row: { original },
    table: {
      options: {
        meta: { isAmazon, isUserRoleViewOnly },
      },
    },
  }) => (
    <DataConnectionRowCell
      {...original}
      isAmazon={isAmazon || false}
      entity={EntityType.Ads}
      isUserRoleViewOnly={isUserRoleViewOnly}
    />
  ),
  accessorKey: 'ad_connection_status',
  size: 250,
  sortable: true,
  align: ColumnAlign.Left,
};

export const productConnectionColumn: FlywheelTableV2ColumnProps<
  SalesChannelData,
  {},
  SalesChannelTableData,
  {}
> = {
  header: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_PRODUCT,
  cell: ({
    row: { original },
    table: {
      options: {
        meta: { isAmazon, isUserRoleViewOnly },
      },
    },
  }) => (
    <DataConnectionRowCell
      {...original}
      isAmazon={isAmazon || false}
      entity={EntityType.Products}
      isUserRoleViewOnly={isUserRoleViewOnly}
    />
  ),
  accessorKey: 'product_connection_status',
  size: 250,
  sortable: true,
  align: ColumnAlign.Left,
};
