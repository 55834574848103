import {
  CogSolidIcon as AccountActiveIcon,
  CogLinearIcon as AccountInactiveIcon,
  BullhornSolidIcon as AdOptimizationActiveIcon,
  BullhornLinearIcon as AdOptimizationInactiveIcon,
  ChartGraphSolidIcon as BusinessIntelligenceActiveIcon,
  ChartGraphLinearIcon as BusinessIntelligenceInactiveIcon,
  CompassSolidIcon as CompassActiveIcon,
  CompassLinearIcon as CompassInactiveIcon,
  DollySolidIcon as InventoryOptimizationActiveIcon,
  DollyLinearIcon as InventoryOptimizationInactiveIcon,
  BullseyeSolidIcon as MarketIntelligenceActiveIcon,
  BullseyeLinearIcon as MarketIntelligenceInactiveIcon,
  NavLink,
  NavModule,
  BoxCubeSolidIcon as ProductsActiveIcon,
  BoxCubeLinearIcon as ProductsInactiveIcon,
  LightbulbSolidIcon as RecommendationsActiveIcon,
  LightbulbIcon as RecommendationsInactiveIcon,
} from '@teikametrics/tm-design-system';
import { NavPaths } from './NavPaths';
import { FeatureFlagState } from './containers/optimizelyProvider/optimizelyProvider';
import { Role } from './lib/types/Fam';
import I18nKey from './lib/types/I18nKey';
import { OptimizelyFlags } from './lib/types/OptimizelyFlags';
import { getMIDashboardURL } from './modules/marketIntelligence/containers/reporting/utils';
import { getDashboardFilters } from './modules/marketIntelligence/storageUtils';

const compassModule = (): NavModule => ({
  icon: {
    active: CompassActiveIcon,
    inactive: CompassInactiveIcon,
  },
  moduleName: I18nKey.NAV_BAR_LABEL_COMPASS,
  navLinks: [
    {
      i18nKey: I18nKey.NAV_BAR_LABEL_COMPASS,
      path: NavPaths.Compass,
      dataTestId: '1com_CompassMainMenu1',
      matchTo: NavPaths.Compass,
    },
  ],
});

const RecommendationsModule = (
  showNotificationDot: boolean,
  isUserRoleViewOnly?: boolean
): NavModule => ({
  icon: {
    active: RecommendationsActiveIcon,
    inactive: RecommendationsInactiveIcon,
  },
  showNotificationDot,
  moduleName: I18nKey.NAV_BAR_LABEL_RECOMMENDATIONS,
  navLinks: [
    {
      i18nKey: I18nKey.NAV_BAR_LABEL_RECOMMENDATIONS,
      path: NavPaths.Recommendation,
      dataTestId: '1com_Recommendations',
      matchTo: NavPaths.Recommendation,
    },
  ],
  isUserRoleViewOnly: isUserRoleViewOnly,
});

const businessIntelligenceModule = (
  customDataUploadEnabledFeatureFlag: boolean | undefined
): NavModule | undefined => {
  const navLinks: NavLink[] = [];

  navLinks.push({
    i18nKey: I18nKey.NAV_BAR_LABEL_BUSINESS_INTELLIGENCE,
    path: NavPaths.BusinessIntelligence,
    matchTo: NavPaths.BusinessIntelligence,
    dataTestId: 'bi_businessIntelligence',
  });

  if (customDataUploadEnabledFeatureFlag) {
    navLinks.push({
      i18nKey: I18nKey.NAV_BAR_LABEL_UPLOAD_CUSTOM_DATA,
      path: `${NavPaths.BusinessIntelligence}${NavPaths.UploadCustomData}`,
      matchTo: `${NavPaths.BusinessIntelligence}${NavPaths.UploadCustomData}`,
      dataTestId: 'bi_uploadCustomData',
    });
  }
  if (navLinks.length > 0) {
    return {
      icon: {
        active: BusinessIntelligenceActiveIcon,
        inactive: BusinessIntelligenceInactiveIcon,
      },
      moduleName: I18nKey.NAV_BAR_LABEL_BUSINESS_INTELLIGENCE,
      navLinks: navLinks,
    };
  }
};

const productsModule = (
  navLinks: NavLink[],
  show1P?: boolean,
  show3P?: boolean,
  showBacn?: boolean,
  isUserRoleViewOnly?: boolean
) => {
  navLinks.push({
    i18nKey: I18nKey.MULTICHANNEL_CATALOG_DASHBOARD_HEADER,
    path: NavPaths.IODashboard,
    matchTo: NavPaths.IODashboard,
    dataTestId: 'pc_multichannel_catalog_dashboard',
  });

  if (show1P) {
    navLinks.push({
      i18nKey: I18nKey.NAV_BAR_LABEL_SKU_CATALOG_VENDOR,
      path: NavPaths.IOSkuVendor,
      matchTo: NavPaths.IOSkuVendor,
      dataTestId: 'pc_skuCatalogVendor',
    });
  }

  if (show3P) {
    navLinks.push({
      i18nKey: I18nKey.NAV_BAR_LABEL_SKU_CATALOG_SELLER,
      path: NavPaths.IOSkuSeller,
      matchTo: NavPaths.IOSkuSeller,
      dataTestId: 'pc_skuCatalogSeller',
    });
  }

  if (showBacn) {
    navLinks.push({
      i18nKey: I18nKey.NAV_BAR_LABEL_BRANDS_AND_COMPETITORS,
      path: NavPaths.BrandAndCompetitors,
      matchTo: NavPaths.BrandAndCompetitors,
      dataTestId: 'pc_brandsAndCompetitors',
      isUserRoleViewOnly,
    });
  }

  if (navLinks.length === 0) {
    navLinks.push({
      i18nKey: I18nKey.NAV_BAR_LABEL_SKU_CATALOG,
      path: NavPaths.IOSku,
      matchTo: NavPaths.IOSku,
      dataTestId: 'pc_skuCatalog',
    });
  }

  if (navLinks.length === 1) {
    navLinks = [
      {
        ...navLinks[0],
        i18nKey: I18nKey.NAV_BAR_LABEL_SKU_CATALOG,
      },
    ];
  }
};

const getProductsModule = (
  show1P?: boolean,
  show3P?: boolean,
  showBacn?: boolean,
  isUserRoleViewOnly?: boolean
): NavModule => {
  const navLinks: NavLink[] = [];

  productsModule(navLinks, show1P, show3P, showBacn, isUserRoleViewOnly);

  return {
    icon: {
      active: ProductsActiveIcon,
      inactive: ProductsInactiveIcon,
    },
    moduleName: I18nKey.NAV_BAR_LABEL_PRODUCTS,
    navLinks,
  };
};

const advertisingOptimizationModule = (
  featureFlags: FeatureFlagState,
  isAiEnabled?: boolean,
  isUserRoleViewOnly?: boolean
): NavModule => {
  const navLinks: NavLink[] = [];
  navLinks.push({
    i18nKey: I18nKey.AO_DASHBOARD_HEADER,
    path: NavPaths.AoDashboard,
    dataTestId: 'ao_dashboard',
    matchTo: NavPaths.AoDashboard,
  });
  if (isAiEnabled) {
    navLinks.push({
      i18nKey: I18nKey.CAMPAIGN_FLEXIBILITY_TITLE,
      path: NavPaths.CampaignFlexibility,
      dataTestId: 'campaign_flexibility',
      matchTo: NavPaths.CampaignFlexibility,
      isUserRoleViewOnly: isUserRoleViewOnly,
    });
  }
  navLinks.push({
    i18nKey: I18nKey.ADVERTISING_OPTIMIZATION_TITLE,
    path: NavPaths.AOAdsManager,
    matchTo: NavPaths.AOAdsManager,
    dataTestId: 'ao_adsManager',
  });
  navLinks.push({
    i18nKey: I18nKey.KEYWORD_ACTIONS_TITLE,
    path: NavPaths.KeywordsTargets,
    matchTo: NavPaths.KeywordsTargets,
    dataTestId: 'keywords_targets',
    isUserRoleViewOnly: isUserRoleViewOnly,
  });
  navLinks.push({
    i18nKey: I18nKey.BUDGET_INSIGHTS_PAGE_HEADER,
    path: NavPaths.BudgetInsights,
    matchTo: NavPaths.BudgetInsights,
    dataTestId: 'budget-insights',
  });
  if (featureFlags[OptimizelyFlags.BulkActions]) {
    navLinks.push({
      i18nKey: I18nKey.NAV_BAR_LABEL_BULK_ACTIONS,
      path: NavPaths.BulkActions,
      matchTo: NavPaths.BulkActions,
      dataTestId: 'bulk_actions',
    });
  }
  return {
    icon: {
      active: AdOptimizationActiveIcon,
      inactive: AdOptimizationInactiveIcon,
    },
    moduleName: I18nKey.NAV_BAR_LABEL_ADVERTISING_OPTIMIZATION,
    navLinks: navLinks,
  };
};

const marketIntelligenceModule = (
  accountId: string,
  featureFlags: FeatureFlagState,
  has3PMerchants?: boolean
): NavModule => {
  const dashboardFilters = getDashboardFilters(accountId);
  //Check if localstirage has dashboard filter data
  const dashboardURL = dashboardFilters
    ? getMIDashboardURL(accountId, dashboardFilters)
    : NavPaths.MarketIntelligenceDashboard + '/' + accountId;

  const navLinks = [
    {
      i18nKey:
        I18nKey.MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_DASHBOARD_LINK,
      path: dashboardURL,
      dataTestId: 'mi_dashboard',
      matchTo: NavPaths.MarketIntelligenceDashboard,
    },
    {
      i18nKey: I18nKey.MARKET_INTELLIGENCE_PAGE_HEADER_SETTINGS,
      path: `${NavPaths.MarketIntelligenceTrackedTerms}${
        has3PMerchants ? '?tab=1' : ''
      }`,
      matchTo: NavPaths.MarketIntelligenceTrackedTerms,
      dataTestId: 'mi_termsTracker',
    },
  ];

  if (featureFlags[OptimizelyFlags.MIShareOfVoice]) {
    navLinks.push({
      i18nKey: I18nKey.MARKET_INTELLIGENCE_SIDEBAR_HEADER_MARKET_INSIGHTS,
      path: NavPaths.MarketIntelligenceMarketInsightsDashboard,
      matchTo: NavPaths.MarketIntelligenceMarketInsightsDashboard,
      dataTestId: 'mi_shareOfVoice',
    });
  }

  return {
    icon: {
      active: MarketIntelligenceActiveIcon,
      inactive: MarketIntelligenceInactiveIcon,
    },
    moduleName: I18nKey.NAV_BAR_LABEL_MARKET_INTELLIGENCE,
    navLinks: navLinks,
  };
};

const buildAccountNavLinks = (
  userRole: Role,
  showBilling: boolean,
  showEventLog?: boolean
): NavLink[] => {
  let accountLinks: NavLink[] = [];

  if (userRole === Role.EDITOR) {
    return [
      {
        i18nKey: I18nKey.SALES_CHANNELS_TITLE,
        path: NavPaths.SalesChannels,
        matchTo: NavPaths.SalesChannels,
        dataTestId: 'us_salesChannels',
      },
    ];
  }

  if (showBilling && userRole !== Role.VIEW_ONLY) {
    accountLinks = [
      ...accountLinks,
      {
        i18nKey: I18nKey.BILLING_TITLE,
        path: NavPaths.Billing,
        matchTo: NavPaths.Billing,
      },
    ];
  }

  accountLinks = [
    ...accountLinks,
    ...[
      {
        i18nKey: I18nKey.SALES_CHANNELS_TITLE,
        path: NavPaths.SalesChannels,
        matchTo: NavPaths.SalesChannels,
        dataTestId: 'us_salesChannels',
      },
    ],
  ];

  if (userRole !== Role.VIEW_ONLY) {
    accountLinks = [
      ...accountLinks,
      {
        i18nKey: I18nKey.USERS_TITLE,
        path: NavPaths.Users,
        matchTo: NavPaths.Users,
        dataTestId: 'us_users',
      },
    ];
  }

  if (userRole === Role.ACCOUNT_OWNER) {
    accountLinks = [
      ...accountLinks,
      {
        i18nKey: I18nKey.COMPANY_PAGE_TITLE,
        path: NavPaths.Company,
        dataTestId: 'us_company',
      },
    ];
  }

  if (showEventLog) {
    accountLinks = [
      ...accountLinks,
      {
        i18nKey: I18nKey.EVENT_LOG_PAGE_TITLE,
        path: NavPaths.EventLog,
        dataTestId: 'us_event_log',
      },
    ];
  }

  return accountLinks;
};

const accountModule: NavModule = {
  icon: {
    active: AccountActiveIcon,
    inactive: AccountInactiveIcon,
  },
  moduleName: I18nKey.NAV_BAR_LABEL_ACCOUNT,
  navLinks: [],
};

const getInventoryOptimizationNavLinks = (
  featureFlags: FeatureFlagState,
  isIOSubscribed?: boolean,
  isUpsellPathEnabled?: boolean
) => {
  const inventoryOptimizationModules = [
    {
      i18nKey: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_DASHBOARD,
      path: NavPaths.InventoryOptimizationDashboard,
      matchTo: NavPaths.InventoryOptimizationDashboard,
      dataTestId: 'pc_inventory_optimization_dashboard',
    },
    {
      i18nKey: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_INSIGHTS,
      path: NavPaths.InventoryOptimizationInsights,
      matchTo: NavPaths.InventoryOptimizationInsights,
      dataTestId: 'pc_inventory_optimization_insights',
    },
    {
      i18nKey: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_DEMAND_FORECASTING,
      path: NavPaths.InventoryOptimizationDemandForecasting,
      matchTo: NavPaths.InventoryOptimizationDemandForecasting,
      dataTestId: 'pc_inventory_optimization_demand_forecasting',
    },
    {
      i18nKey: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE,
      path: NavPaths.InventoryOptimizationHiiveClearance,
      matchTo: NavPaths.InventoryOptimizationHiiveClearance,
      dataTestId: 'pc_inventory_optimization_hiive_clearance',
    },
  ];

  return isUpsellPathEnabled
    ? isIOSubscribed
      ? [...inventoryOptimizationModules]
      : [
          {
            i18nKey: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION,
            path: NavPaths.InventoryOptimization,
            matchTo: NavPaths.InventoryOptimization,
            dataTestId: 'pc_inventory_optimization',
          },
        ]
    : [
        ...(featureFlags[OptimizelyFlags.ManagedEditionPlusAccount] &&
        featureFlags[OptimizelyFlags.InventoryOptimization]
          ? [...inventoryOptimizationModules]
          : []),
      ];
};

export const getNavModulesForAccount = (
  userRole: Role,
  featureFlags: FeatureFlagState,
  areAll1PMerchantsAndAmazon: boolean,
  showNotificationDotOnRecsIcon: boolean,
  accountId: string,
  showBilling: boolean,
  has1PMerchants?: boolean,
  has3PMerchants?: boolean,
  isAiEnabled?: boolean,
  isIOSubscribed?: boolean,
  isUpsellPathEnabled?: boolean,
  isUserRoleViewOnly?: boolean
): NavModule[] => {
  const navModules: NavModule[] = [];

  if (userRole === Role.EDITOR) {
    accountModule.navLinks = buildAccountNavLinks(userRole, false, false);
    navModules.push(accountModule);

    return navModules;
  }

  navModules.push(compassModule());

  navModules.push(
    RecommendationsModule(showNotificationDotOnRecsIcon, isUserRoleViewOnly)
  );

  navModules.push(
    getProductsModule(
      has1PMerchants,
      has3PMerchants,
      featureFlags[OptimizelyFlags.BACNTagging],
      isUserRoleViewOnly
    )
  );

  /** Add IO module to navbar only if account is ME+ & inventory_optimization flag is enabled
   * or upsell_inventory_module metadata tag is enabled */
  if (
    (featureFlags[OptimizelyFlags.InventoryOptimization] &&
      featureFlags[OptimizelyFlags.ManagedEditionPlusAccount]) ||
    isUpsellPathEnabled
  ) {
    navModules.push({
      icon: {
        active: InventoryOptimizationActiveIcon,
        inactive: InventoryOptimizationInactiveIcon,
      },
      moduleName: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION,
      navLinks: getInventoryOptimizationNavLinks(
        featureFlags,
        isIOSubscribed,
        isUpsellPathEnabled
      ),
    });
  }

  navModules.push(
    advertisingOptimizationModule(
      featureFlags,
      isAiEnabled,
      isUserRoleViewOnly
    ),
    marketIntelligenceModule(accountId, featureFlags, has3PMerchants)
  );

  const biModule = businessIntelligenceModule(
    featureFlags[OptimizelyFlags.BIProductMetaData]
  );
  if (biModule && isAiEnabled) {
    navModules.push(biModule);
  }

  accountModule.navLinks = buildAccountNavLinks(userRole, showBilling, true);
  navModules.push(accountModule);

  return navModules;
};
