import { RecommendationsActionTypes, RecommendationsActions } from './actions';
import { RecommendationsContextState } from './recommendationsProvider';

export const recommendationsreducer = (
  state: RecommendationsContextState,
  action: RecommendationsActions
): RecommendationsContextState => {
  if (
    action.type ===
    RecommendationsActionTypes.SET_HOMEPAGE_NOTIFICATION_DISMISSED
  ) {
    return {
      ...state,
      homepageRecommendationsDismissed: action.payload,
    };
  }

  if (action.type === RecommendationsActionTypes.SET_RECOMMENDATIONS_COUNT) {
    return {
      ...state,
      recommendationsCount: action.payload,
    };
  }

  if (action.type === RecommendationsActionTypes.RESET_STATE) {
    return {
      recommendationsCount: [],
      homepageRecommendationsDismissed: false,
    };
  }

  return state;
};
