import {
  BreadCrumbsProps,
  ButtonProps,
  TextLinkProps,
} from '@teikametrics/tm-design-system';
import noop from 'lodash/noop';
import React, { createContext, useState } from 'react';

export interface PageHeaderContextDataProps {
  readonly overRideScrollToTopToggle?: boolean;
  readonly title?: string | React.ReactNode;
  readonly titleString?: string;
  readonly breadCrumbs?: React.ReactElement<BreadCrumbsProps>;
  readonly dataTestId?: string;
  readonly returnLink?: TextLinkProps;
  readonly buttonLink?: ButtonProps;
}

export interface PageHeaderProviderProps {
  readonly children: JSX.Element;
}

export interface PageHeaderContextState {
  readonly pageHeaderData: PageHeaderContextDataProps;
  readonly updatePageHeaderData: (props: PageHeaderContextDataProps) => void;
  /**
   * This property is used to move the view to top of the screen.
   * Read comments below while setting this state to understand how this works.
   */
  readonly scrollToTopToggle: boolean;
}

const initialState: PageHeaderContextState = {
  pageHeaderData: {
    overRideScrollToTopToggle: false,
    title: undefined,
    breadCrumbs: undefined,
    dataTestId: undefined,
    returnLink: undefined,
    buttonLink: undefined,
  },
  updatePageHeaderData: noop,
  scrollToTopToggle: false,
};

const PageHeaderContext = createContext<PageHeaderContextState>(initialState);
const { Provider } = PageHeaderContext;

const PageHeaderProvider: React.FC<PageHeaderProviderProps> = ({
  children,
}) => {
  /**
   * This property is used to move the used to top of the page.
   * This is flipped whenever the prop `overRideScrollToTopToggle` = true.
   * */
  const [scrollToTopToggle, setScrolltoTopToggle] = useState<boolean>(false);
  const [pageHeaderData, setPageHeaderData] =
    useState<PageHeaderContextDataProps>({
      ...initialState.pageHeaderData,
    });

  const updatePageHeaderData = (props: PageHeaderContextDataProps) => {
    /**
     * This property will never by TRUE unless passed on from the consumer.
     * NOTE: While saving the context data this property is always set as false.
     * So that in the next set again this prop can be used to move the page to top.
     *
     * Hence if we need to move top of page while changing the context pass in this property.
     * */
    if (props.overRideScrollToTopToggle) {
      setScrolltoTopToggle(!scrollToTopToggle);
    }
    setPageHeaderData({ ...props, overRideScrollToTopToggle: false });
  };

  return (
    <Provider
      value={{ pageHeaderData, updatePageHeaderData, scrollToTopToggle }}
    >
      {children}
    </Provider>
  );
};

PageHeaderProvider.displayName = 'PageHeaderProvider';
export { PageHeaderContext, PageHeaderProvider };
