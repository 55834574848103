import isNil from 'lodash/isNil';
import React from 'react';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { SbAdsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { SB_ADS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';
import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<SbAdsDetails & TableDataAdsManager> = (
  props
) =>
  makeCurrencyColumn<SbAdsDetails>(
    ({ creativePerformance: { newToBrandSales } }) => {
      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        undefined,
        newToBrandSales?.amount ? Number(newToBrandSales?.amount) : undefined
      );

      return isNil(performanceMetricValue)
        ? undefined
        : {
            amount: performanceMetricValue,
            currency:
              getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
              newToBrandSales?.currency ||
              CURRENCY_CODE,
          };
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const newToBrandAdSalesColumn: FlywheelTableColumn<
  SbAdsDetails,
  TableDataAdsManager
> = {
  columnName: SB_ADS_API_COLUMN_NAME.NewToBrandSales,
  isSortable: true,
  RowCellElement,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES,
  className: 'text-right',
  gridColumnWidth: '130px',
};

export const newToBrandAdSalesFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    SB_ADS_API_COLUMN_NAME.NewToBrandSales,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES,
    currency,
    isValidNumber(0)
  );
