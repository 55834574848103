import { useKnockFeed } from '@knocklabs/react-notification-feed';
import { TextLink, TextLinkSize } from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import I18nKey from '../../lib/types/I18nKey';
import { getDateLabel } from './helpers';
import NotificationRow from './NotificationRow';
import { Notification } from './types';

interface NotificationListProps {
  notifications: Notification[];
  action?: {
    classNames?: string;
    element: React.ReactNode;
  };
  onViewAll?: () => void;
}

const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
  action,
  onViewAll,
}) => {
  const { feedClient, useFeedStore } = useKnockFeed();
  const knockNotifications = useFeedStore((state) => state.items);

  const intl = useIntl();

  const [TODAY, YESTERDAY, THIS_WEEK, LAST_WEEK, OLDER, VIEW_ALL] = [
    I18nKey.NOTIFICATIONS_PANEL_TODAY,
    I18nKey.NOTIFICATIONS_PANEL_YESTERDAY,
    I18nKey.NOTIFICATIONS_PANEL_THIS_WEEK,
    I18nKey.NOTIFICATIONS_PANEL_LAST_WEEK,
    I18nKey.NOTIFICATIONS_PANEL_OLDER,
    I18nKey.NOTIFICATIONS_PANEL_VIEW_ALL,
  ].map((id) => intl.formatMessage({ id }));

  const groupedNotifications = useMemo(() => {
    const groups: Record<
      string,
      { label: string; notifications: Notification[] }
    > = {
      today: { label: TODAY, notifications: [] },
      yesterday: { label: YESTERDAY, notifications: [] },
      thisWeek: { label: THIS_WEEK, notifications: [] },
      lastWeek: { label: LAST_WEEK, notifications: [] },
      older: { label: OLDER, notifications: [] },
    };

    notifications.forEach((notification) => {
      const { group } = getDateLabel(notification.date);
      groups[group].notifications.push(notification);
    });

    return Object.values(groups).filter(
      (group) => group.notifications.length > 0
    );
  }, [notifications]);

  const handleRead = (id: string) => {
    const notification = knockNotifications.find(
      (notification) => notification.id === id
    );

    feedClient.markAsRead([notification!]);
  };

  const handleUnread = (id: string) => {
    const notification = knockNotifications.find(
      (notification) => notification.id === id
    );

    feedClient.markAsUnread([notification!]);
  };

  return (
    <div className="flex-1 overflow-y-auto relative">
      {groupedNotifications.map((group, idx) => (
        <div key={group.label}>
          <div className="flex items-center justify-between px-16 pt-16 pb-8">
            <span
              className="
              text-grey-500 text-base leading-none font-semibold"
            >
              {group.label}
            </span>

            {idx === 0 && action && (
              <div
                data-test-id="notification-list-action"
                className={classNames(
                  `flex items-center justify-center cursor-pointer
                text-grey-600 hover:text-purple-500`,
                  action.classNames
                )}
              >
                {action.element}
              </div>
            )}
          </div>

          {group.notifications.map((notification, idx) => (
            <NotificationRow
              key={notification.id}
              notification={notification}
              showBorder={idx !== group.notifications.length - 1}
              onRead={() => handleRead(notification.id)}
              onUnread={() => handleUnread(notification.id)}
            />
          ))}
        </div>
      ))}

      {onViewAll && notifications.length > 0 && (
        <div
          className={`mx-16 py-12 text-center leading-none
              border-t border-solid border-grey-100`}
        >
          <TextLink
            textLabel={VIEW_ALL}
            size={TextLinkSize.Small}
            dataTestId="view-all-link"
            onClick={onViewAll}
          />
        </div>
      )}
    </div>
  );
};

NotificationList.displayName = 'NotificationList';

export default NotificationList;
