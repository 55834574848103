import { fromNullable } from 'fp-ts/lib/Option';
import first from 'lodash/first';
import sortBy from 'lodash/sortBy';

import {
  AdLevel,
  AddedBy,
  FlywheelSalesChannel,
} from '../../lib/types/AOSharedTypes';
import { AllSalesChannel, MerchantCountry } from '../../lib/types/Fam';
import { AD_LEVEL_CONFIG } from './containers/adsManager/dataInspectorConfig';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_LEVEL_VALUE_TO_AD_LEVEL_IN_URL_MAPPER,
  AD_TYPE_DEFAULT_VALUE,
  AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER,
  AoAdsManagerUrlParts,
  TableCellChangeAdGroup,
  TableCellChangeCampaign,
  TableCellChangeProductAd,
  TableCellChangeProfile,
  TableCellChangeTarget,
} from './containers/adsManager/types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  getDropdownValuesFromPathname,
} from './containers/adsManager/utils';
import {
  getAdLevelFromLocalStorage,
  getAdTypeFromLocalStorage,
  getSalesChannelFromLocalStorage,
} from './containers/adsManager/utils/storageUtils';
import isEmpty from 'lodash/isEmpty';
import { SKU_PARAM } from '../../core/types';
import {
  Filter,
  FilterOps,
  InFilter,
  NotInFilter,
} from '../../lib/types/Filter';

export const getAdManagerUrl = (path: string): string => {
  const passedUrl = Object.values(ADS_MANAGER_AD_LEVEL_PAGES_URI).find(
    (eachUrl) => path.split('/').indexOf(eachUrl) !== -1
  );
  return passedUrl ? path.slice(0, path.indexOf(passedUrl)) : path;
};

export const getAdsLevelUri = (
  baseUrl: string,
  adLevel: ADS_MANAGER_AD_LEVEL_PAGES_URI,
  uniqueId: string
) => baseUrl + adLevel + '/' + encodeURIComponent(uniqueId);

export function generateUrlPartsByCountryCodeAndSalesChannelConfig(
  salesChannel: FlywheelSalesChannel
): AoAdsManagerUrlParts {
  const adLevelConfigForCurrentSalesChannel = AD_LEVEL_CONFIG[salesChannel];
  const firstAvailableAdType = AD_TYPE_DEFAULT_VALUE[salesChannel];

  const adTypeForUrl =
    AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER[firstAvailableAdType];

  const firstAvailableADLevel = first(
    adLevelConfigForCurrentSalesChannel[firstAvailableAdType]
  )!;

  return {
    salesChannel: salesChannel.toLowerCase(),
    adLevel: AD_LEVEL_VALUE_TO_AD_LEVEL_IN_URL_MAPPER[firstAvailableADLevel],
    adType: adTypeForUrl,
  };
}

export function generateUrlFromLocalStorageData(
  userId: string,
  accountId: string,
  flywheelSalesChannels: AllSalesChannel[],
  pathname: string
): string {
  const { salesChannelFromPathname, adTypeFromPathname, adLevelFromPathname } =
    getDropdownValuesFromPathname(pathname);

  const salesChannelIdFromPathname = flywheelSalesChannels.find(
    (salesChannel) => salesChannel.name === salesChannelFromPathname
  )?.id;

  const salesChannelFromPathnameOrStorage =
    salesChannelIdFromPathname ||
    getSalesChannelFromLocalStorage(userId, accountId);

  if (salesChannelFromPathnameOrStorage) {
    const adTypeFromPathnameOrLocalStorage =
      adTypeFromPathname ||
      getAdTypeFromLocalStorage({
        accountId: accountId,
        userId: userId,
        salesChannelId: salesChannelFromPathnameOrStorage,
      });

    const adLevelFromPtahnameOrLocalStorage =
      adLevelFromPathname ||
      getAdLevelFromLocalStorage(
        userId,
        accountId,
        salesChannelFromPathnameOrStorage
      );

    const previouslySelectedsalesChannelInfo = flywheelSalesChannels.find(
      (salesChannel) => salesChannel.id === salesChannelFromPathnameOrStorage
    );
    if (
      adTypeFromPathnameOrLocalStorage &&
      adLevelFromPtahnameOrLocalStorage &&
      previouslySelectedsalesChannelInfo
    ) {
      const salesChannelForUrl =
        previouslySelectedsalesChannelInfo.name.toLowerCase();
      const adTypeForUrl =
        AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER[
          adTypeFromPathnameOrLocalStorage
        ];
      const adLevelForUrl =
        AD_LEVEL_VALUE_TO_AD_LEVEL_IN_URL_MAPPER[
          adLevelFromPtahnameOrLocalStorage
        ];

      return `${salesChannelForUrl}/${adTypeForUrl}/${adLevelForUrl}`;
    } else {
      return '';
    }
  }
  return '';
}

export const getUrlBySyncedSalesChannels = (
  salesChannelsResponse: AllSalesChannel[],
  merchantCountries: MerchantCountry[]
) => {
  let urlParts: AoAdsManagerUrlParts;
  const amazonSalesChannelInfo = salesChannelsResponse.find(
    (channel) => channel.name.toLowerCase() === FlywheelSalesChannel.Amazon
  );

  const walmartSalesChannelInfo = salesChannelsResponse.find(
    (channel) => channel.name.toLowerCase() === FlywheelSalesChannel.Walmart
  );

  const sortedMerchants = sortBy(
    merchantCountries,
    (merchantCountry) => merchantCountry.merchantName
  );

  const firstAmazonMerchantInfo = sortedMerchants.find(
    (merchantCountry) =>
      amazonSalesChannelInfo?.id === merchantCountry.salesChannelId
  );

  const firstWalmartMerchantInfo = sortedMerchants.find(
    (merchantCountry) =>
      walmartSalesChannelInfo?.id === merchantCountry.salesChannelId
  );

  if (firstAmazonMerchantInfo) {
    urlParts = generateUrlPartsByCountryCodeAndSalesChannelConfig(
      FlywheelSalesChannel.Amazon
    );
  } else if (firstWalmartMerchantInfo) {
    urlParts = generateUrlPartsByCountryCodeAndSalesChannelConfig(
      FlywheelSalesChannel.Walmart
    );
  } else {
    // TODO: Handle this edge case
    return '';
  }
  return `${urlParts.salesChannel}/${urlParts.adType}/${urlParts.adLevel}`;
};

export const isIdentifiedAdlevelOneOfTargets = (identifiedAdLevel: AdLevel) => {
  return [
    AdLevel.KeywordTargets,
    AdLevel.ProductTargets,
    AdLevel.Targets,
  ].includes(identifiedAdLevel);
};

export const upgradeFiltersInStorage =
  (storeFiltersInBrowserStorage?: (filters: Filter[]) => void) =>
  (filters: Filter[]) => {
    fromNullable(storeFiltersInBrowserStorage)
      .map((fn) => fn(filters))
      .toUndefined();
  };

export const areNoChangesDone = (
  updatedCells:
    | TableCellChangeProductAd
    | TableCellChangeAdGroup
    | TableCellChangeTarget
    | TableCellChangeCampaign
    | TableCellChangeProfile
) =>
  isEmpty(updatedCells) ||
  Object.entries(updatedCells).every(([key, v]) => isEmpty(v));

export const transFormAdItemsFilters = (filters: Filter[]) => {
  return filters.map((f) => {
    if (f.field === CAMPAIGNS_API_COLUMN_NAME.AddedBy) {
      let newValue: string[] = [];
      if ((f.value as string[]).includes(AddedBy.FlywheelAutomated)) {
        newValue = [AddedBy.CampaignCreatorV1, AddedBy.FlywheelAutomated];
      }
      if ((f.value as string[]).includes(AddedBy.FlywheelOnDemand)) {
        newValue.push(
          AddedBy.AdsManagerOnDemand,
          AddedBy.CampaignCreatorOnDemand,
          AddedBy.FlywheelOnDemand
        );
      }

      if ((f.value as string[]).includes(AddedBy.External)) {
        newValue.push(AddedBy.External);
      }

      return {
        ...f,
        op: f.op === FilterOps.in ? FilterOps.in : FilterOps.notIn,
        value: newValue,
      } as InFilter | NotInFilter;
    }
    if (f.field === SKU_PARAM) {
      if ((f.value as string).includes(',')) {
        let newFilter: any = {
          ...f,
        };
        newFilter.value = (f.value as string).split(',');
        newFilter.op = FilterOps.in;
        return newFilter;
      }
    }
    return f;
  });
};
