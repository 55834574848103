import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { SbAdsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { SB_ADS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<SbAdsDetails> = ({
  creativeDetails,
  creativePerformance,
  creativeId,
  channelSettings,
}) => {
  const creativeData: SbAdsDetails = {
    creativeDetails,
    creativePerformance,
    creativeId,
    channelSettings,
  };

  return makeTextColumn(({ channelSettings }: SbAdsDetails) =>
    channelSettings?.status ? channelSettings?.status : undefined
  )(creativeData);
};
RowCellElement.displayName = 'RowCellElement';

export const adStatusColumn: FlywheelTableColumn<SbAdsDetails> = {
  columnName: SB_ADS_API_COLUMN_NAME.AdStatus,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_SB_ADS_TABLE_COLUMN_AD_STATUS,
  RowCellElement,
  gridColumnWidth: '240px',
};

export const adStatusFilter = () =>
  createMultiSelectDataFieldFilter(
    SB_ADS_API_COLUMN_NAME.AdStatus,
    I18nKey.ADS_MANAGER_SB_ADS_TABLE_COLUMN_AD_STATUS,
    [
      SelectFilterOption.CampaignStatusEnabled,
      SelectFilterOption.CampaignStatusPaused,
      SelectFilterOption.CampaignStatusArchived,
    ]
  );
