import classNames from 'classnames';
import React, { createContext } from 'react';
import { noop } from 'react-select/lib/utils';

import {
  Toast,
  ToastProps,
  ToastVariant,
} from '@teikametrics/tm-design-system';

const AUTO_DISMISS_MILLIS = 3500;
const FADE_OUT_MILLIS = 500;

type ToastInfo = ToastProps & {
  id: number;
  timerId?: number;
  dismissed: boolean;
  itemNo: number;
};

export interface NotificationContextState {
  readonly notifications: ToastInfo[];
  addNotification: (info: ToastProps) => void;
}

export interface TeikaNotificationProviderProps {
  children: React.ReactNode;
}

const initialState = {
  notifications: [],
  addNotification: noop,
  setNotificationPosition: noop,
};

const NotificationContext =
  createContext<NotificationContextState>(initialState);
NotificationContext.displayName = 'Notification Context';

const { Provider } = NotificationContext;

function notificationReducer(
  state: ToastInfo[],
  action: { type: 'add' | 'remove' | 'update'; notification: ToastInfo }
) {
  const { type, notification } = action;
  switch (type) {
    case 'add':
      return [...state, notification];
    case 'remove':
      return state.filter((n) => n.id !== notification.id);
    case 'update':
      return state.map((n) => (n.id !== notification.id ? n : notification));
  }
}

const NotificationProvider: React.FC<TeikaNotificationProviderProps> = ({
  children,
}) => {
  const [notifications, dispatch] = React.useReducer(notificationReducer, []);

  const handleOnClose = (toastToClose: ToastInfo) => () => {
    clearTimeout(toastToClose.timerId);
    dispatch({ type: 'remove', notification: toastToClose });
    toastToClose.onClose && toastToClose.onClose();
  };

  const addNotification = (toastProps: ToastProps) => {
    let notification: ToastInfo = {
      ...toastProps,
      dismissed: false,
      id: Math.random(),
      itemNo: notifications.length + 1,
    };
    const dismissNotification = function () {
      notification.dismissed = true;
      notification.timerId = window.setTimeout(
        handleOnClose(notification),
        FADE_OUT_MILLIS
      );
      dispatch({ type: 'update', notification });
    };

    notification.timerId = window.setTimeout(
      dismissNotification,
      AUTO_DISMISS_MILLIS
    );
    dispatch({ type: 'add', notification });
  };

  return (
    <Provider value={{ notifications, addNotification }}>
      <div id="notification-area">
        {notifications.map((props, index) => (
          <div
            className={classNames('fixed h-auto flex flex-col-reverse z-100', {
              'top-24 left-1/2 -translate-x-1/2 mt-20':
                props.variant === ToastVariant.Simple,
              'bottom-24 left-56 ml-8': props.variant !== ToastVariant.Simple,
            })}
          >
            <div
              key={`${props.id}${props.description}`}
              className={classNames({
                'animate-slide-right': props.variant !== ToastVariant.Simple,
                'animate-slide-down':
                  props.variant === ToastVariant.Simple &&
                  props.itemNo === notifications.length,
                'animate-fade': props.dismissed,
              })}
            >
              <Toast key={index} {...props} onClose={handleOnClose(props)} />
            </div>
          </div>
        ))}
      </div>
      {children}
    </Provider>
  );
};
NotificationProvider.displayName = 'NotificationProvider';
export { NotificationContext, NotificationProvider };
