import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';

import {
  AdLevel,
  AdType,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import {
  DATA_INSPECTOR_COLUMN_NAME,
  DataInspectionColumnGroupIdentifier,
} from '../dataInspectorConfig';
import {
  DataInspectionAdLevelConfigProps,
  DataInspectorColumnManagerConfigProps,
  TabCheckbox,
} from '../types';

function selectAndUpdateFwSettingsColumnsConfig(
  perAdLevelConfig: WritableDraft<DataInspectionAdLevelConfigProps>,
  shouldDisableFwSettingsColumns: boolean
) {
  let newColumnsConfig = { ...perAdLevelConfig.columns };
  const fwSettingsKeyName =
    DataInspectionColumnGroupIdentifier.FlywheelSettings;

  if (newColumnsConfig[fwSettingsKeyName]) {
    for (const columnName in newColumnsConfig[fwSettingsKeyName]) {
      newColumnsConfig[fwSettingsKeyName][columnName].isDisabled =
        shouldDisableFwSettingsColumns;
      newColumnsConfig[fwSettingsKeyName][columnName].isSelected = true;
    }
  }
  return {
    ...perAdLevelConfig,
    columns: newColumnsConfig,
  };
}

// Traverse through config-old and disable Fw settings columns wherever present
export const disableFwSettingsColumnsIfBasicPlan = (
  isAIPlanEnabled: boolean,
  config: DataInspectorColumnManagerConfigProps
) => {
  if (isAIPlanEnabled) {
    return config;
  }

  return produce(config, (draft) => {
    for (const key in draft) {
      const salesChannel = key as FlywheelSalesChannel;
      draft[salesChannel] = draft[salesChannel].map((perAdTypeConfig) => {
        return {
          ...perAdTypeConfig,
          config: perAdTypeConfig.config.map((perAdLevelConfig) =>
            selectAndUpdateFwSettingsColumnsConfig(perAdLevelConfig, true)
          ),
        };
      });
    }
  });
};

// Traverse through config-old and enable Fw settings columns wherever present
export const enableFwSettingsColumns = (
  config: DataInspectorColumnManagerConfigProps
) =>
  produce(config, (draft) => {
    for (const key in draft) {
      const salesChannel = key as FlywheelSalesChannel;
      draft[salesChannel] = draft[salesChannel].map((perAdTypeConfig) => {
        return {
          ...perAdTypeConfig,
          config: perAdTypeConfig.config.map((perAdLevelConfig) =>
            selectAndUpdateFwSettingsColumnsConfig(perAdLevelConfig, false)
          ),
        };
      });
    }
  });

export const filterTargetLabelandAddedByColumn = (
  isAiEnabled: boolean | undefined,
  config: DataInspectorColumnManagerConfigProps
) => {
  const updatedConfig: any = {};
  for (const key in config) {
    const salesChannel = key as FlywheelSalesChannel;
    let perAdTypeConfigArray: any = [];
    config[salesChannel].forEach((perAdTypeConfig) => {
      if (
        perAdTypeConfig.adType === AdType.SponsoredProducts ||
        perAdTypeConfig.adType === AdType.SponsoredDisplay ||
        perAdTypeConfig.adType === AdType.SponsoredBrands
      ) {
        const result = perAdTypeConfig.config.map(({ adLevel, columns }) =>
          getColumnsExceptTargetLabels(
            adLevel,
            columns,
            salesChannel,
            isAiEnabled
          )
        );
        perAdTypeConfigArray.push({
          adType: perAdTypeConfig.adType,
          config: result,
        });
      } else {
        perAdTypeConfigArray.push(perAdTypeConfig);
      }
    });
    updatedConfig[salesChannel] = perAdTypeConfigArray;
  }
  return updatedConfig;
};

const getColumnsExceptTargetLabels = (
  adLevel: AdLevel,
  columns: TabCheckbox,
  salesChannel: FlywheelSalesChannel,
  isAiEnabled: boolean | undefined
) => {
  if (
    adLevel === AdLevel.KeywordTargets ||
    adLevel === AdLevel.ProductTargets ||
    adLevel === AdLevel.Targets
  ) {
    const detailColumns = columns[DataInspectionColumnGroupIdentifier.Details];
    const copyOfDetailsColumns = { ...detailColumns };

    if (isAiEnabled) {
      delete copyOfDetailsColumns[DATA_INSPECTOR_COLUMN_NAME.TargetLabels];
    } else {
      if (salesChannel === FlywheelSalesChannel.Walmart) {
        delete copyOfDetailsColumns[DATA_INSPECTOR_COLUMN_NAME.TargetLabels];
        delete copyOfDetailsColumns[DATA_INSPECTOR_COLUMN_NAME.Tags];
      }
      delete copyOfDetailsColumns[DATA_INSPECTOR_COLUMN_NAME.AddedBy];
    }

    return {
      adLevel,
      columns: {
        ...columns,
        [DataInspectionColumnGroupIdentifier.Details]: copyOfDetailsColumns,
      },
    };
  }
  return { adLevel, columns };
};

// Traverse through config and Remove Smart ACOS Related fields
export const updateSmartAcosColumnsConfig = (
  config: DataInspectorColumnManagerConfigProps,
  showSmartCampaigns?: boolean
) => {
  if (!showSmartCampaigns) {
    return config;
  }

  return produce(config, (draft) => {
    for (const key in draft) {
      const salesChannel = key as FlywheelSalesChannel;
      draft[salesChannel] = draft[salesChannel].map((perAdTypeConfig) => {
        return {
          ...perAdTypeConfig,
          config: perAdTypeConfig.config.map((perAdLevelConfig) =>
            updateTheSmartAcosColumnConfig(perAdLevelConfig, true)
          ),
        };
      });
    }
  });
};

const updateTheSmartAcosColumnConfig = (
  perAdLevelConfig: WritableDraft<DataInspectionAdLevelConfigProps>,
  showSmartCampaigns?: boolean
) => {
  if (!showSmartCampaigns) {
    return perAdLevelConfig;
  }
  let newColumnsConfig = { ...perAdLevelConfig.columns };
  const fwSettingsKeyName = DataInspectionColumnGroupIdentifier.Details;

  if (newColumnsConfig[fwSettingsKeyName]) {
    for (const columnName in newColumnsConfig[fwSettingsKeyName]) {
      // Added any other columnName to be removed for SmartAcosFields
      if ([DATA_INSPECTOR_COLUMN_NAME.AddedBy].includes(columnName)) {
        delete newColumnsConfig[fwSettingsKeyName][columnName];
      }
    }
  }
  return {
    ...perAdLevelConfig,
    columns: newColumnsConfig,
  };
};
