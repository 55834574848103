import {
  Alignment,
  ArrowRightIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CalendarPlacement,
  CalendarRangeLinearIcon,
  ChartBuilder,
  ChartDataPeriod,
  ChartTime,
  ChevronDownIcon,
  ComparisonToggle,
  ContentStyle,
  DatePickerV2,
  DownloadIcon,
  FormToggle,
  FormToggleSize,
  FormToggleState,
  Icon,
  IconSize,
  KpiCardGroupV2,
  MetricCardV2Props,
  PeriodLabels,
  Placement,
  Theme,
  ToggleTab,
  ToggleTabGroupV2,
  ToggleTabStyle,
  Tooltip,
  TooltipSize,
  Type,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ViewTrendsEntityType } from '..';
import { NotificationContext } from '../../../../../containers/notificationProvider';
import { getCurrentAccount } from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../../../lib/clients/AOApiClient';
import { useDownloadFile } from '../../../../../lib/hooks/downloadFile';
import {
  AOKPIMetrics,
  AdLevelApiEndpoint,
  AdType,
  DateRange,
  GraphItem,
  HeroMetricType,
  HeroMetricsAggregationPeriodType,
  HeroMetricsData,
  SALES_CHANNEL_TO_PERFORMANCE_FIELD_MAPPER_V2,
  ViewTrendsGraphDataResponse,
  ViewTrendsPriod,
} from '../../../../../lib/types/AOSharedTypes';
import { SeasonalEvent } from '../../../../../lib/types/CompassSharedTypes';
import {
  FlywheelSalesChannel,
  MerchantType,
} from '../../../../../lib/types/Fam';
import { FilterOps } from '../../../../../lib/types/Filter';
import I18nKey from '../../../../../lib/types/I18nKey';
import { MoneyWithAmountInString } from '../../../../../lib/types/Money';
import { AMAZON_SALES_CHANNEL_ID } from '../../../../../lib/types/SalesChannels';
import {
  AsyncRequest,
  AsyncRequestCompleted,
  AsyncRequestLoading,
  AsyncRequestNotStarted,
  asyncRequestIsComplete,
  asyncRequestIsLoading,
} from '../../../../../lib/utilities/asyncRequest';
import { DATE_QUERY_STRING_FORMAT } from '../../../../../lib/utilities/buildUrlUtilities';
import {
  CurrencyCode,
  getCurrencySymbol,
} from '../../../../../lib/utilities/currency';
import { getPreviousPeriodDateRange } from '../../../containers/dashboard/utils';
import {
  AOKPIMETRICS_TO_DECIMAL_POINT_LIMIT,
  AOKPIMETRICS_TO_HERO_METRIC_TYPE,
  AOKPIMETRICS_TO_I18NKEY,
  CONVERTED_DATE_FORMAT_FOR_GRAPH,
  TOOLTIP_DATE_FORMAT_FOR_GRAPH,
  getCoordinateValue,
  getInterval,
  getKPICards,
  getNoDataEmptyState,
  getSeasonalEventsData,
  getShowLoading,
  getXAxisLabels,
  getYAxisFormat,
  getYAxisMinValue,
  hasSelectedMetricHaveData,
  isAllPerformanceMetricsEmpty,
  rotationXaxis,
} from './utils';

export interface PerformanceProps {
  readonly selectedDateRange: DateRange;
  readonly isLoading?: boolean;
  readonly selectedCurrency?: CurrencyCode;
  readonly aoApiClient?: AOApiClient;
  readonly channelId?: string;
  readonly merchantCountryId?: string;
  readonly campaignId?: string;
  readonly entityType?: string;
  readonly entityId?: string;
  readonly adType?: string;
  readonly merchantType?: MerchantType;
}

const CHART_TIME = ['daily', 'weekly'];

export const Performance: React.FC<PerformanceProps> = (props) => {
  const {
    selectedDateRange,
    selectedCurrency = CurrencyCode.USD,
    aoApiClient,
    entityId,
    entityType,
    adType,
    merchantType,
  } = props;

  const intl = useIntl();

  const [currentDateRange, setCurrentDateRange] =
    useState<DateRange>(selectedDateRange);
  const [formToggleState, setFormToggleState] = useState<FormToggleState>(
    FormToggleState.On
  );

  const [seasonalEventsToggleState, setSeasonalEventsToggleState] =
    useState<FormToggleState>(FormToggleState.On);

  const [heroMetricDataRequest, setHeroMetricsCurrentMetricRequest] = useState<
    AsyncRequest<{
      readonly current?: HeroMetricsData;
      readonly previous?: HeroMetricsData;
    }>
  >(AsyncRequestNotStarted());

  const [graphDataRequest, setGraphDataRequest] = useState<
    AsyncRequest<{
      readonly current?: ViewTrendsGraphDataResponse;
      readonly previous?: ViewTrendsGraphDataResponse;
    }>
  >(AsyncRequestNotStarted());

  const [selectedMetrics, setSelectedMetrics] = useState<AOKPIMetrics[]>([
    AOKPIMetrics.AdSales,
    AOKPIMetrics.AdSpend,
    AOKPIMetrics.ACoS,
    AOKPIMetrics.ROAS,
  ]);

  const [selectedMetricIndex, setSelectedMetricIndex] = useState<number>(0);

  const [seasonalEvents, setSeasonalEvents] = useState<
    AsyncRequest<SeasonalEvent[]>
  >(AsyncRequestNotStarted());

  const previousDateRange = useMemo(
    () =>
      getPreviousPeriodDateRange(
        currentDateRange.initialStartDate,
        currentDateRange.initialEndDate
      ),
    [currentDateRange]
  );
  const userContext = useContext<UserContextState>(UserContext);
  const currentAccount = getCurrentAccount(userContext);
  const accountId = currentAccount?.id;
  const accountName = currentAccount?.companyName;

  const fileName = useMemo(
    () =>
      `AO_SLIDEOVER_SUMMARY_${accountName}_${entityType}_${DateTime.now().toFormat(
        DATE_QUERY_STRING_FORMAT
      )}.csv`,
    [accountName]
  );

  useEffect(() => {
    if (accountId) {
      aoApiClient
        ?.getSeasonalEvents(accountId, currentDateRange)
        .then((response) => {
          setSeasonalEvents(AsyncRequestCompleted(response));
        });
    }
  }, [accountId, currentDateRange]);

  useEffect(() => {
    if (
      accountId &&
      props.merchantCountryId &&
      props.channelId &&
      props.campaignId &&
      merchantType &&
      adType
    ) {
      setHeroMetricsCurrentMetricRequest(AsyncRequestLoading());
      const request = {
        merchantType: merchantType as MerchantType,
        advertisingType:
          adType === AdType.SearchBrandAmplifier
            ? AdType.SponsoredBrands
            : (adType as AdType),
        salesChannelId: props.channelId,
        channelId:
          props.channelId === AMAZON_SALES_CHANNEL_ID
            ? FlywheelSalesChannel.Amazon
            : FlywheelSalesChannel.Walmart,
        endDate: currentDateRange?.initialEndDate.toFormat(
          DATE_QUERY_STRING_FORMAT
        ),
        filters: [
          {
            field:
              entityType === ViewTrendsEntityType.CAMPAIGN
                ? 'campaignId'
                : 'adGroupId',
            op: FilterOps.eq,
            value: entityId ?? '',
          },
        ],
        merchantCountryIds: [props.merchantCountryId ?? ''],
        performanceFields:
          SALES_CHANNEL_TO_PERFORMANCE_FIELD_MAPPER_V2[
            props.channelId === AMAZON_SALES_CHANNEL_ID
              ? FlywheelSalesChannel.Amazon
              : FlywheelSalesChannel.Walmart
          ],
        startDate: currentDateRange.initialStartDate.toFormat(
          DATE_QUERY_STRING_FORMAT
        ),
      };

      Promise.all([
        aoApiClient?.getMetrics(
          accountId ?? '',
          entityType === ViewTrendsEntityType.CAMPAIGN
            ? AdLevelApiEndpoint.Campaigns
            : AdLevelApiEndpoint.Adgroups
        )({
          ...request,
          filters: [
            {
              field:
                entityType === ViewTrendsEntityType.CAMPAIGN
                  ? 'campaignId'
                  : 'adGroupId',
              op: FilterOps.eq,
              value: entityId ?? '',
            },
          ],
          aggregationPeriodType: HeroMetricsAggregationPeriodType.Current,
        }),
        aoApiClient?.getMetrics(
          accountId ?? '',
          entityType === ViewTrendsEntityType.CAMPAIGN
            ? AdLevelApiEndpoint.Campaigns
            : AdLevelApiEndpoint.Adgroups
        )({
          ...request,
          filters: [
            {
              field:
                entityType === ViewTrendsEntityType.CAMPAIGN
                  ? 'campaignId'
                  : 'adGroupId',
              op: FilterOps.eq,
              value: entityId ?? '',
            },
          ],
          aggregationPeriodType: HeroMetricsAggregationPeriodType.Previous,
        }),
      ]).then(([currentResponse, previousResponse]) => {
        setHeroMetricsCurrentMetricRequest(
          AsyncRequestCompleted({
            current: currentResponse,
            previous: previousResponse,
          })
        );
      });
    }
  }, [currentDateRange, entityId, merchantType]);

  useEffect(() => {
    if (accountId && entityType && entityId && adType) {
      const request = {
        advertisingType:
          adType === AdType.SearchBrandAmplifier
            ? AdType.SponsoredBrands
            : (adType as AdType),
        channelId:
          props.channelId === AMAZON_SALES_CHANNEL_ID
            ? FlywheelSalesChannel.Amazon
            : FlywheelSalesChannel.Walmart,
        endDate: currentDateRange.initialEndDate.toFormat(
          DATE_QUERY_STRING_FORMAT
        ),
        entityId,
        entityType,
        merchantCountryId: props.merchantCountryId ?? '',
        merchantType: merchantType as MerchantType,
        startDate: currentDateRange.initialStartDate.toFormat(
          DATE_QUERY_STRING_FORMAT
        ),
      };

      setGraphDataRequest(AsyncRequestLoading());

      Promise.all([
        aoApiClient?.getPerformanceDataPoints(accountId)({
          ...request,
          aggregationPeriodType: ViewTrendsPriod.Current,
        }),
        aoApiClient?.getPerformanceDataPoints(accountId)({
          ...request,
          aggregationPeriodType: ViewTrendsPriod.Previous,
        }),
      ]).then(([currentResponse, previousResponse]) => {
        setGraphDataRequest(
          AsyncRequestCompleted({
            current: currentResponse,
            previous: previousResponse,
          })
        );
      });
    }
  }, [currentDateRange, entityId, entityType]);

  const toasts = useContext(NotificationContext);

  const [exportButtonState, setExportButtonState] = useState<ButtonState>(
    ButtonState.Enabled
  );

  const postDownloadFile = () => {
    toasts.addNotification({
      headline: intl.formatMessage({ id: I18nKey.SUCCESS }),
      description: intl.formatMessage(
        {
          id: I18nKey.PRODUCTS_TOAST_MESSAGE_FILE_DOWNLOAD,
        },
        {
          filename: fileName,
        }
      ),
      type: Type.Success,
      dataTestId: 'ao_export_ao_dashboard_export_success',
    });
    setExportButtonState(ButtonState.Enabled);
  };

  const onErrorDownloadFile = () => {
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.PRODUCTS_TOAST_MESSAGE_EXPORT_FAILED_HEADER,
      }),
      description: intl.formatMessage({
        id: I18nKey.PRODUCTS_TOAST_MESSAGE_EXPORT_FAILED,
      }),
      type: Type.Attention,
      dataTestId: 'ao_export_ao_dashboard_export_failure',
    });
    setExportButtonState(ButtonState.Enabled);
  };

  const exportSlideoverSummary = async () => {
    return props.aoApiClient
      ?.exportPerformanceDataPoints(accountId ?? '', {
        advertisingType:
          adType === AdType.SearchBrandAmplifier
            ? AdType.SponsoredBrands
            : (adType as AdType),
        aggregationPeriodType: ViewTrendsPriod.Current,
        channelId:
          props.channelId === AMAZON_SALES_CHANNEL_ID
            ? FlywheelSalesChannel.Amazon
            : FlywheelSalesChannel.Walmart,
        endDate: currentDateRange.initialEndDate.toFormat(
          DATE_QUERY_STRING_FORMAT
        ),
        startDate: currentDateRange.initialStartDate.toFormat(
          DATE_QUERY_STRING_FORMAT
        ),
        entityId: props.entityId ?? '',
        entityType: props.entityType ?? '',
        merchantCountryId: props.merchantCountryId ?? '',
        merchantType: props.merchantType as MerchantType,
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        onErrorDownloadFile();
        return err;
      });
  };

  const { downloadFile } = useDownloadFile<string>({
    asyncFunction: exportSlideoverSummary,
    preDownloadFile: () => {
      setExportButtonState(ButtonState.Loading);
    },
    postDownloadFile,
    onError: noop,
    fileName,
  });

  const [getMaybeDefaultTabIndex, setMaybeDefaultTabIndex] =
    useState<number>(0);

  const options = useMemo((): MetricCardV2Props[] => {
    if (asyncRequestIsLoading(graphDataRequest)) {
      return [];
    }
    if (asyncRequestIsComplete(graphDataRequest)) {
      const data =
        (getMaybeDefaultTabIndex === 1
          ? graphDataRequest.result.current?.weekly
          : graphDataRequest.result.current?.daily) ?? [];
      const prevData =
        (getMaybeDefaultTabIndex === 1
          ? graphDataRequest.result.previous?.weekly
          : graphDataRequest.result.previous?.daily) ?? [];
      return [
        {
          className: 'bg-orange-500',
          header: intl.formatMessage({
            id: AOKPIMETRICS_TO_I18NKEY[selectedMetrics[selectedMetricIndex]],
          }),
          data: [
            {
              label: PeriodLabels.Current,
              data: {
                color: '#4B25EA',
                type: 'spline',
                yAxis: 0,
                name: selectedMetrics[selectedMetricIndex],
                data: [
                  ...data.map((graphItem, index) => {
                    const metric = selectedMetrics[selectedMetricIndex];
                    const metricType = AOKPIMETRICS_TO_HERO_METRIC_TYPE[metric];
                    const value =
                      metricType === HeroMetricType.Money
                        ? (graphItem[metric] as MoneyWithAmountInString)?.amount
                        : graphItem[metric];
                    const updatedValue = !isNil(value)
                      ? (value as string)
                      : undefined;
                    const formattedDate =
                      graphItem && graphItem.reportDate
                        ? DateTime.fromISO(graphItem.reportDate).toFormat(
                            TOOLTIP_DATE_FORMAT_FOR_GRAPH
                          )
                        : undefined;
                    let style: 'currency' | 'decimal' | 'percent' | 'units' =
                      metricType === HeroMetricType.Money
                        ? 'currency'
                        : 'decimal';

                    if (metricType === HeroMetricType.Percent) {
                      style = 'percent';
                    }

                    const customOpposingDate =
                      prevData[index] && prevData[index].reportDate
                        ? DateTime.fromFormat(
                            prevData[index].reportDate,
                            DATE_QUERY_STRING_FORMAT
                          )
                        : undefined;

                    return {
                      custom: {
                        customDate: formattedDate,
                        name: intl.formatMessage({
                          id: AOKPIMETRICS_TO_I18NKEY[metric],
                        }),
                        chartDataPeriod: ChartDataPeriod.CURRENT,
                        total:
                          !isNil(updatedValue) &&
                          !isNaN(parseFloat(updatedValue)) &&
                          intl.formatNumber(parseFloat(updatedValue), {
                            currency:
                              style === 'currency'
                                ? selectedCurrency
                                : undefined,
                            style,
                            maximumFractionDigits:
                              AOKPIMETRICS_TO_DECIMAL_POINT_LIMIT[
                                selectedMetrics[selectedMetricIndex]
                              ],
                            minimumFractionDigits:
                              AOKPIMETRICS_TO_DECIMAL_POINT_LIMIT[
                                selectedMetrics[selectedMetricIndex]
                              ],
                          }),
                        customOpposingDate: customOpposingDate?.toFormat(
                          TOOLTIP_DATE_FORMAT_FOR_GRAPH
                        ),
                      },
                      y:
                        !isNil(updatedValue) && !isNaN(parseFloat(updatedValue))
                          ? getCoordinateValue(
                              parseFloat(updatedValue),
                              getYAxisFormat(
                                selectedMetrics,
                                selectedMetricIndex
                              )
                            )
                          : undefined,
                    };
                  }),
                ],
              },
            },
            {
              label: PeriodLabels.Previous,
              header: intl.formatMessage({
                id: AOKPIMETRICS_TO_I18NKEY[
                  selectedMetrics[selectedMetricIndex]
                ],
              }),
              data: {
                color: '#D3CBFB',
                type: 'spline',
                yAxis: 0,
                name: selectedMetrics[selectedMetricIndex],
                visible: formToggleState === FormToggleState.On,
                data: [
                  ...prevData
                    .filter(
                      (_, _index) =>
                        data.length === prevData.length ||
                        _index < data.length - 1
                    )
                    .map((graphItem, index) => {
                      const metric = selectedMetrics[selectedMetricIndex];
                      const metricType =
                        AOKPIMETRICS_TO_HERO_METRIC_TYPE[metric];
                      const value =
                        metricType === HeroMetricType.Money
                          ? (graphItem[metric] as MoneyWithAmountInString)
                              ?.amount
                          : graphItem[metric];
                      const updatedValue = !isNil(value)
                        ? (value as string)
                        : undefined;
                      const formattedDate =
                        graphItem && graphItem.reportDate
                          ? DateTime.fromISO(graphItem.reportDate).toFormat(
                              CONVERTED_DATE_FORMAT_FOR_GRAPH
                            )
                          : undefined;
                      let style: 'currency' | 'decimal' | 'percent' | 'unit' =
                        metricType === HeroMetricType.Money
                          ? 'currency'
                          : 'decimal';

                      if (metricType === HeroMetricType.Percent) {
                        style = 'percent';
                      }
                      const customOpposingDate =
                        data[index] && data[index].reportDate
                          ? DateTime.fromFormat(
                              data[index].reportDate,
                              DATE_QUERY_STRING_FORMAT
                            ).toFormat(TOOLTIP_DATE_FORMAT_FOR_GRAPH)
                          : undefined;
                      return {
                        custom: {
                          customDate: formattedDate,
                          name: intl.formatMessage({
                            id: AOKPIMETRICS_TO_I18NKEY[metric],
                          }),
                          chartDataPeriod: ChartDataPeriod.PREVIOUS,
                          total:
                            !isNil(updatedValue) &&
                            !isNaN(parseFloat(updatedValue)) &&
                            intl.formatNumber(parseFloat(updatedValue), {
                              currency:
                                style === 'currency'
                                  ? selectedCurrency
                                  : undefined,
                              style,
                              maximumFractionDigits:
                                AOKPIMETRICS_TO_DECIMAL_POINT_LIMIT[
                                  selectedMetrics[selectedMetricIndex]
                                ],
                              minimumFractionDigits:
                                AOKPIMETRICS_TO_DECIMAL_POINT_LIMIT[
                                  selectedMetrics[selectedMetricIndex]
                                ],
                            }),
                          customOpposingDate,
                        },
                        y:
                          !isNil(updatedValue) &&
                          !isNaN(parseFloat(updatedValue))
                            ? getCoordinateValue(
                                parseFloat(updatedValue),
                                getYAxisFormat(
                                  selectedMetrics,
                                  selectedMetricIndex
                                )
                              )
                            : undefined,
                      };
                    }),
                ],
              },
            },
          ],
        },
      ];
    }
    return [];
  }, [
    graphDataRequest.kind,
    formToggleState,
    selectedMetrics,
    selectedMetricIndex,
    getMaybeDefaultTabIndex,
  ]);

  const onMetricSelectChangeHandler =
    (metricIndex: number) => (value: string) => {
      setSelectedMetrics((prevMetrics) => {
        return [
          ...prevMetrics.map((metric, index) =>
            index === metricIndex ? (value as AOKPIMetrics) : metric
          ),
        ];
      });
    };

  const kpiCards = useMemo(() => {
    return getKPICards(
      intl,
      props.channelId === AMAZON_SALES_CHANNEL_ID
        ? FlywheelSalesChannel.Amazon
        : FlywheelSalesChannel.Walmart,
      selectedMetrics,
      {
        current: asyncRequestIsComplete(heroMetricDataRequest)
          ? heroMetricDataRequest.result?.current
          : undefined,
        previous: asyncRequestIsComplete(heroMetricDataRequest)
          ? heroMetricDataRequest.result?.previous
          : undefined,
      },
      asyncRequestIsLoading(heroMetricDataRequest),
      selectedMetricIndex,
      formToggleState === FormToggleState.On
    );
  }, [
    heroMetricDataRequest,
    selectedMetrics,
    formToggleState,
    selectedMetricIndex,
  ]);

  const onDateRangeChangeHandler = (startDate: string, endDate: string) => {
    setCurrentDateRange({
      ...currentDateRange,
      initialEndDate: DateTime.fromFormat(endDate, DATE_QUERY_STRING_FORMAT),
      initialStartDate: DateTime.fromFormat(
        startDate,
        DATE_QUERY_STRING_FORMAT
      ),
    });
  };

  const onToggleClickHandler = () => {
    setFormToggleState((prevState) =>
      prevState === FormToggleState.On
        ? FormToggleState.Off
        : FormToggleState.On
    );
  };

  const xAxisLabels = asyncRequestIsComplete(graphDataRequest)
    ? getXAxisLabels(
        intl,
        (
          (graphDataRequest.result.current?.[
            CHART_TIME[getMaybeDefaultTabIndex]
          ] as GraphItem[]) || []
        ).map((item) => item.reportDate),
        getMaybeDefaultTabIndex === 1 ? ChartTime.Weekly : ChartTime.Daily,
        currentDateRange.initialStartDate.year !==
          currentDateRange.initialEndDate.year
      )
    : [];

  return (
    <div className="flex flex-col w-full gap-20">
      <div className="flex px-12 py-4 gap-8 items-center bg-grey-50 rounded-4">
        <Icon
          size={IconSize.Medium}
          svg={CalendarRangeLinearIcon}
          className="text-grey-500"
        />
        <Typography
          lineHeight={TypographyLineHeight.none}
          size={TypographySize.base}
          weight={TypographyWeight.regular}
          className="capitalize"
        >
          {intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD,
          })}
        </Typography>
        <div>
          <DatePickerV2
            initialEndDate={currentDateRange.initialEndDate}
            initialStartDate={currentDateRange.initialStartDate}
            calendarPlacement={CalendarPlacement.Right}
            onChange={onDateRangeChangeHandler}
            maxDate={currentDateRange.maxDate}
            minDate={currentDateRange.minDate}
            showYearSwitchButton={true}
          >
            <div
              className={
                'flex border border-solid border-grey-200 bg-white px-12 py-6 rounded-4 items-center gap-8'
              }
            >
              <span className="text-base font-normal">
                {currentDateRange.initialStartDate.toFormat(
                  CONVERTED_DATE_FORMAT_FOR_GRAPH
                )}
              </span>
              <span className="mx-4 text-grey-600">
                <Icon size={IconSize.Small} svg={ArrowRightIcon} />
              </span>
              <span className="text-base font-normal">
                {currentDateRange.initialEndDate.toFormat(
                  TOOLTIP_DATE_FORMAT_FOR_GRAPH
                )}
              </span>
              <Icon size={IconSize.Medium} svg={ChevronDownIcon} />
            </div>
          </DatePickerV2>
        </div>
        <ComparisonToggle
          size={FormToggleSize.Medium}
          dateRange={previousDateRange}
          state={formToggleState}
          onClick={onToggleClickHandler}
        />
      </div>
      <div className="flex gap-8 items-center">
        <KpiCardGroupV2
          cards={kpiCards}
          onCardMetricChange={(index: number, metric: string) =>
            onMetricSelectChangeHandler(index)(metric)
          }
          onCardClick={(index) => setSelectedMetricIndex(index)}
        />
      </div>
      {asyncRequestIsLoading(graphDataRequest) && getShowLoading(true)}
      {asyncRequestIsComplete(graphDataRequest) &&
        isAllPerformanceMetricsEmpty(
          getMaybeDefaultTabIndex === 1 ? ChartTime.Weekly : ChartTime.Daily,
          graphDataRequest.result.current
        ) &&
        isAllPerformanceMetricsEmpty(
          getMaybeDefaultTabIndex === 1 ? ChartTime.Weekly : ChartTime.Daily,
          graphDataRequest.result.previous
        ) &&
        getNoDataEmptyState(() => {})}
      {asyncRequestIsComplete(graphDataRequest) &&
        (!isAllPerformanceMetricsEmpty(
          getMaybeDefaultTabIndex === 1 ? ChartTime.Weekly : ChartTime.Daily,
          graphDataRequest.result.current
        ) ||
          !isAllPerformanceMetricsEmpty(
            getMaybeDefaultTabIndex === 1 ? ChartTime.Weekly : ChartTime.Daily,
            graphDataRequest.result.previous
          )) && (
          <ChartBuilder
            yAxisFixedHeight={170}
            chartMetricsProps={options}
            showGraphLineTooltip={true}
            onClickChartTime={(toggleTab: ToggleTab) => {
              setMaybeDefaultTabIndex(
                toggleTab.nameI18nKey === ChartTime.Daily ? 0 : 1
              );
            }}
            rotationXaxis={rotationXaxis(xAxisLabels)}
            PlotBandPoints={[]}
            actionButtonTabs={[
              {
                nameI18nKey: ChartTime.Daily,
                theme: ToggleTabGroupV2.ToggleTabTheme.LightPurple,
              },
              {
                nameI18nKey: ChartTime.Weekly,
                theme: ToggleTabGroupV2.ToggleTabTheme.LightPurple,
              },
            ]}
            tabGroupStyle={ToggleTabStyle.Condensed}
            defaultTime={
              getMaybeDefaultTabIndex === 1 ? ChartTime.Weekly : ChartTime.Daily
            }
            currency={getCurrencySymbol(selectedCurrency)}
            xAxisTickInterval={getInterval(xAxisLabels.length)}
            xAxisLabels={xAxisLabels}
            showYAxisOnLeft
            yAxisOnLeftFormat={getYAxisFormat(
              selectedMetrics,
              selectedMetricIndex
            )}
            yAxisLeftMinValue={getYAxisMinValue(options)}
            yAxisLeftMaxValue={
              asyncRequestIsComplete(graphDataRequest) &&
              (hasSelectedMetricHaveData(
                selectedMetrics,
                selectedMetricIndex,
                (graphDataRequest.result.current
                  ? graphDataRequest.result.current[
                      CHART_TIME[getMaybeDefaultTabIndex]
                    ]
                  : []) as GraphItem[]
              ) ||
                hasSelectedMetricHaveData(
                  selectedMetrics,
                  selectedMetricIndex,
                  (graphDataRequest.result.previous
                    ? graphDataRequest.result.previous[
                        CHART_TIME[getMaybeDefaultTabIndex]
                      ]
                    : []) as GraphItem[]
                ))
                ? undefined
                : 100
            }
            showPreviousDataOnGraphLineTooltip={true}
            zIndexForTooltip={57}
            stackMode={'normal'}
            xAxisMarginBottom={16}
            legendEnabled={false}
            legendPadding={0}
            legendMarginTop={0}
            defaultBottomSpacing={2}
            className="w-full relative"
            eventLogs={
              asyncRequestIsComplete(seasonalEvents)
                ? getSeasonalEventsData(
                    currentDateRange,
                    seasonalEvents.result,
                    getMaybeDefaultTabIndex === 0
                      ? ChartTime.Daily
                      : ChartTime.Weekly,
                    intl
                  )
                : undefined
            }
            showEventLogs={
              asyncRequestIsComplete(seasonalEvents) &&
              seasonalEventsToggleState === FormToggleState.On
            }
            centerElements={<div className="flex"></div>}
            leftSideElements={
              <p>
                <div
                  onClick={() => {
                    setSeasonalEventsToggleState((prevState) =>
                      prevState === FormToggleState.On
                        ? FormToggleState.Off
                        : FormToggleState.On
                    );
                  }}
                  className="cursor-pointer mt-8 inline-block"
                >
                  <Tooltip
                    content={
                      (asyncRequestIsComplete(seasonalEvents)
                        ? seasonalEvents.result
                        : []
                      ).length
                        ? ''
                        : intl.formatMessage({
                            id: I18nKey.COMPASS_CUSTOMIZE_METRICS_NO_SEASONAL_EVENTS_TOOLTIP,
                          })
                    }
                    tooltipSize={TooltipSize.Small}
                    style={ContentStyle.Normal}
                    position={{
                      placement: Placement.Bottom,
                      alignment: Alignment.Center,
                    }}
                  >
                    <FormToggle
                      label={intl.formatMessage({
                        id: I18nKey.COMPASS_CUSTOMIZE_METRICS_SHOW_SEASONAL_EVENTS,
                      })}
                      state={seasonalEventsToggleState}
                      size={FormToggleSize.Small}
                    />
                  </Tooltip>
                </div>
              </p>
            }
            rightSideElements={
              <Tooltip
                content={intl.formatMessage({
                  id: I18nKey.COMPASS_CUSTOMIZE_METRICS_EXPORT_BTN_TOOLTIP,
                })}
                theme={Theme.Dark}
                position={{
                  placement: Placement.Left,
                  alignment: Alignment.Center,
                  placementOffsetInPixels: 10,
                }}
                delayShow={300}
              >
                <Button
                  svgIcon={DownloadIcon}
                  size={ButtonSize.InlineIconMedium}
                  state={exportButtonState}
                  onClick={() => downloadFile()}
                  variant={ButtonVariant.BlackAndWhiteBorder}
                  dataTestId="compass_graph_export_btn"
                  className="h-32 w-32"
                />
              </Tooltip>
            }
            condensedElements
          />
        )}
    </div>
  );
};

Performance.displayName = 'AOViewTrendsPerformance';
