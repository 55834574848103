import React, { useEffect, useState } from 'react';

import {
  PlugDisconnectedIcon,
  SearchInput,
  SearchInputSize,
  TableEmptyStateProps,
  TableProperties,
} from '@teikametrics/tm-design-system';

import { LiteTable } from '../../../../../containers/tableV2/liteTable';
import I18nKey from '../../../../../lib/types/I18nKey';
import { GetAccountListColumnsDefinition } from './tableColumns/columns';
import { SALES_CHANNELS_TABLE } from './types';
import { SalesChannelData } from '../../../../../lib/types/Fam';
import { useIntl } from 'react-intl';

export interface MerchantsChannelsTableProps {
  showSearchInput: boolean;
  showBulkSelection: boolean;
  tableClassName?: string;
  headerClass?: string;
  rowData: any;
  rowIdsSelected?: string[];
  rowIdKeyName?: string;
  onRowsSelection?: (row: any) => void;
}

const MerchantsChannelsTable: React.FC<MerchantsChannelsTableProps> = ({
  showSearchInput,
  showBulkSelection,
  tableClassName = 'border-solid overflow-hidden',
  headerClass = 'bg-white h-42',
  rowIdsSelected = [],
  rowIdKeyName = '',
  rowData = [],
  onRowsSelection,
}) => {
  const intl = useIntl();
  const columns = GetAccountListColumnsDefinition(intl);
  const DEFAULT_CURRENCY = 'USD';
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [updatedRowData, setUpdatedRowData] = useState(rowData);

  const onChange = (val: string) => setSearchValue(val);
  const onSearchInputClear = () => {
    setSearchValue('');
    updateTableDataBySearch(true);
  };
  useEffect(() => {
    setUpdatedRowData(rowData);
  }, [rowData]);
  const updateTableDataBySearch = (resetData?: boolean) => {
    if (resetData) {
      setUpdatedRowData(rowData);
      return;
    }

    setUpdatedRowData([
      ...rowData.filter((data: any) => {
        return Object.values(data).find(
          (value: any) =>
            value &&
            searchValue &&
            value.toLowerCase().includes(searchValue.toLowerCase())
        );
      }),
    ]);
  };

  const searchButtonInputProps = {
    label: 'Search',
    loading: false,
    onSearchButtonClick: () => updateTableDataBySearch(false),
    onChange,
  };

  const emptyStateProps: TableEmptyStateProps = {
    titleI18nKey: I18nKey.PAGINATED_TABLE_NO_DATA_HEADER,
    descriptionI18nKey: I18nKey.TABLE_NO_DATA_WITH_FILTER_DESCRIPTION_TRY_NEW,
    icon: PlugDisconnectedIcon,
    className: 'h-240 w-full',
  };

  return (
    <>
      {showSearchInput && (
        <div className="mb-16">
          <SearchInput
            inputClassName={'w-full'}
            value={searchValue}
            size={SearchInputSize.Medium}
            onSearchInputClear={onSearchInputClear}
            placeholder={'Search'}
            searchButtonProps={searchButtonInputProps}
            dataTestId={`flash1_amazon_ads_available_merchants_search`}
          />
        </div>
      )}
      <LiteTable
        columns={
          [
            {
              id: 'FLASH_MERCHANTS__TABLE_ID',
              columns,
            },
          ] as TableProperties<SalesChannelData>['columns']
        }
        tableId={SALES_CHANNELS_TABLE}
        tableData={[]}
        currencyCode={DEFAULT_CURRENCY}
        tableClassName={tableClassName}
        headerClass={headerClass}
        rowData={updatedRowData}
        rowIdKeyName={rowIdKeyName}
        rowIdsSelected={rowIdsSelected}
        showBulkSelection={showBulkSelection}
        tableEmptyStateClass={'w-full'}
        emptyState={emptyStateProps}
        onRowsSelection={onRowsSelection}
      />
    </>
  );
};
MerchantsChannelsTable.displayName = 'MerchantsChannelsTable';
export default MerchantsChannelsTable;
