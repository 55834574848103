/*
  Taken from this article by Dan Abramov
  https://overreacted.io/making-setinterval-declarative-with-react-hooks/
*/
import { useEffect, useRef } from 'react';
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();
  // Remember the latest function.
  useEffect(() => {
    if (callback) {
      savedCallback.current = callback;
    }
  }, [callback]);
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
