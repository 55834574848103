import './styles.scss';

import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import Modal from 'react-modal';
import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
} from '@teikametrics/tm-design-system';

interface ModalWithIconProps {
  readonly showModal: boolean;
  readonly icon: JSX.Element;
  readonly title: string | JSX.Element;
  readonly description: string | JSX.Element;
  readonly buttonPrimaryText: string | JSX.Element;
  readonly buttonPrimaryOnClick: () => void;
  readonly buttonSecondaryText: string | JSX.Element;
  readonly buttonSecondaryOnClick: () => void;
  readonly modalHeightInPixels?: number;
  readonly modalWidthInPixels?: number;
  readonly buttonPrimaryState?: ButtonState;
  readonly modalIsForm?: boolean;
  readonly dataTestId?: string;
  readonly modalFooterClassName?: string;
}

export const ModalWithIcon: React.FC<
  ModalWithIconProps & PropsWithChildren
> = ({
  showModal,
  icon,
  title,
  description,
  buttonPrimaryText,
  buttonPrimaryOnClick,
  buttonSecondaryText,
  buttonSecondaryOnClick,
  children,
  modalHeightInPixels,
  modalWidthInPixels,
  buttonPrimaryState = ButtonState.Enabled,
  modalIsForm = false,
  dataTestId,
  modalFooterClassName = '',
}) => {
  return (
    <Modal
      isOpen={showModal}
      className="modal-with-icon__modal"
      overlayClassName="modal-with-icon__overlay"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={buttonSecondaryOnClick}
      style={{
        content: {
          height: modalHeightInPixels ? `${modalHeightInPixels}px` : 'auto',
          width: modalWidthInPixels ? `${modalWidthInPixels}px` : 'auto',
        },
      }}
    >
      <div className="modal-with-icon__icon">{icon}</div>
      <div className="modal-with-icon__content">
        <div className="modal-with-icon__title">{title}</div>
        <div className="modal-with-icon__description">{description}</div>
        {children}
        <div
          className={classNames(
            'modal-with-icon__buttons',
            `${modalFooterClassName}`,
            {
              'modal-with-icon__buttons--left-shifted': modalIsForm,
            }
          )}
        >
          <div>
            <Button
              className="button--no-outline"
              onClick={buttonSecondaryOnClick}
              dataTestId={`${dataTestId}_secondary`}
              size={ButtonSize.Large}
              variant={ButtonVariant.Primary}
              label={buttonSecondaryText}
            >
              {buttonSecondaryText}
            </Button>
          </div>
          <div className="ml-16">
            <Button
              onClick={buttonPrimaryOnClick}
              state={buttonPrimaryState}
              dataTestId={`${dataTestId}_primary`}
              size={ButtonSize.Large}
              variant={ButtonVariant.Primary}
              label={buttonPrimaryText}
            >
              {buttonPrimaryText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
ModalWithIcon.displayName = 'ModalWithIcon';
