import {
  ButtonSize,
  ColumnManager,
  ColumnManagerVariant,
  ConditionalType,
  FilterDataType,
  FilterRow,
  FiltersMenu,
  RowData,
  SegmentedLinks,
  TMTableMeta,
  TableActionRow,
  convertToMap,
  filterOpsToRows,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { fromNullable } from 'fp-ts/lib/Option';
import noop from 'lodash/noop';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  COLUMNS_WITH_SINGLE_DECIMAL_FILTER,
  Filter,
  FilterOps,
} from '../../lib/types/Filter';
import { getUniqueFilters } from '../../modules/advertisingOptimization/containers/adsManager/utils';
import { tableActions } from './ducks';
import { WithTable } from './ducks/types';
import { FlywheelSearchInput } from './flywheelSearchInput';
import { PaginatedTableV2 } from './paginatedTable';
import { FlywheelTablePaginationRowV2 } from './paginationFooter';
import { FlywheelTableV2Props } from './types';
import { convertColumns } from './utils';

export function FlywheelTableV2<
  T extends Record<string, unknown>,
  A = {},
  R extends TMTableMeta<T> = {},
  S = {}
>(props: FlywheelTableV2Props<T, A, R, S>) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const columns = convertColumns<T, A, R, S>({
    tableId: props.tableId,
    columns: props.columns,
    intl,
  });

  const {
    storeFiltersInBrowserStorage,
    filterDateFormat,
    filtersFromBrowserStorage = [],
    filterFieldMapper = [],
    dataFields = [],
    areFilterPillsClearable = true,
    currencyCode,
    tableActionComponents = [],
    onColumnSelectionChange = noop,
    selectedColumns = [],
    searchInputClassName,
    loadAggregationDataOnPageNavigation = true,
    hasSearchInputWithButton = false,
    filtersMenuTooltipClass = '',
    columnManagerTooltipClass = '',
    onPaginationChange = noop,
    rowCheckboxClassName = 'mt-8',
    roundedTop = true,
    isTableActionRowDisabled = false,
    isFooterDisabled = false,
    filtersContainerClass = 'right-0',
    allowStickyHeader = false,
    customStickyHeaderClassName,
    allowHorizontalDrag = false,
    missingRequiredFields = [],
    actionRowLeftSideComponents = [],
    expandAllRows,
    enableExpandSubRows = false,
    bulkSelectCellTooltip,
    bulkSelectHeaderTooltip,
    bulkSelectCellDisabledTooltip,
    actionRowClassnames = '',
    onToggleExpand,
    rowIdsProcessing,
    actionRowFullWidthElementIndex,
    customFilterIcon,
    additionalActionRow,
    extraRightSideElements,
    hideColumnManagerText = false,
    segmentedFilterLinks,
    defaultSelectedSegmentedFilterValue,
    onSegmentedLinkChange,
  } = props;

  const [isFilterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);
  const [showStickyFooter, setShowStickyFooter] = useState<boolean>(false);
  const onFullPageTable = (value: boolean) => {
    setShowStickyFooter(value);
  };

  const currentTableFilters: Filter[] = useSelector<
    WithTable<T, Filter>,
    Filter[]
  >((state) => state.tableState[props.tableId].filters);

  let currentFilters = [...currentTableFilters, ...filtersFromBrowserStorage];

  currentFilters = getUniqueFilters(currentFilters);

  /*
    FIXME: find a better way if possible. Current logic-
    updateFilters is the function which is called on click of apply filter button.
    Problem lies with those tables which have search filter, but do not provide filterFieldMapper as a prop.
    In the above case existingSearchFilters will be an empty array.
    Hence, redux is only updated with the newFilters (and not the existing search filters).

    Side effect -
    API is called only with the applied filters present in filter menu.
    It skips search filter query parameters in API request.
    */
  const updateFilters = (filters: Filter[]) => {
    const existingSearchFilters: Filter[] = currentFilters.filter(
      (currentFilter) =>
        filterFieldMapper.find((filter) => filter.alias === currentFilter.field)
    );
    const newFilters: Filter[] = [...existingSearchFilters, ...filters];
    dispatch(
      tableActions.updateFilters({
        tableId: props.tableId,
        filters: newFilters,
        replace: true,
      })
    );
    upgradeFiltersInStorage(newFilters);
    if (props.onFilterUpdate) {
      props.onFilterUpdate(newFilters);
    }
  };

  const upgradeFiltersInStorage = (filters: Filter[]) => {
    fromNullable(storeFiltersInBrowserStorage)
      .map((fn) => fn(filters))
      .toUndefined();
  };

  const getNewFilters = (row: RowData) => {
    const field = row.dataField?.field;

    return currentFilters
      .map((filter: Filter) => {
        if (
          field === filter.field &&
          (filter.op === FilterOps.in || filter.op === FilterOps.notIn)
        ) {
          const value = filter.value.filter(
            (val: string) => val !== row.singleSelectedValue!.value
          );
          return {
            ...filter,
            value,
          };
        }

        return filter;
      })
      .filter(
        (filter) =>
          ((filter.op === FilterOps.in || filter.op === FilterOps.notIn) &&
            filter.value.length > 0) ||
          (filter.op !== FilterOps.in &&
            filter.op !== FilterOps.notIn &&
            filter.field !== field)
      );
  };

  const onPillClose = (row: RowData) => {
    const field = row.dataField?.field;
    let updatedFilters: Filter[] = [];

    /**
     * If FilterDataType a `singleSelect` and `Is` OR `IsNot` Filter being removed
     * Then remove only that paricular filter-value from the currentFilters
     */
    if (
      field &&
      (row.conditional === ConditionalType.Is ||
        row.conditional === ConditionalType.IsNot) &&
      row.dataField?.dataType === FilterDataType.SingleSelect
    ) {
      dispatch(
        tableActions.clearSingleFilterOfMultiSelectionFilters({
          field,
          value: row.singleSelectedValue!.value,
          tableId: props.tableId,
        })
      );

      updatedFilters = getNewFilters(row);
    } else {
      updatedFilters = currentFilters.filter(
        (filter) => filter.field !== field
      );
    }

    dispatch(
      tableActions.updateFilters({
        tableId: props.tableId,
        filters: updatedFilters,
        replace: true,
      })
    );
    props.onFilterUpdate?.(updatedFilters);
    upgradeFiltersInStorage(updatedFilters);
  };

  const clearAllFilters = () => {
    const existingSearchFilters: Filter[] = currentFilters.filter(
      (currentFilter) =>
        filterFieldMapper.find((filter) => filter.alias === currentFilter.field)
    );
    dispatch(
      tableActions.updateFilters({
        tableId: props.tableId,
        filters: existingSearchFilters,
        replace: true,
      })
    );
    upgradeFiltersInStorage(existingSearchFilters);

    if (props.onFilterUpdate) {
      props.onFilterUpdate(existingSearchFilters);
    }
  };

  const onPillClick = () => setFilterMenuOpen(true);

  /**
   * Preference of tableActionRow Left side components are as (if any)
   * 1. Search Input
   * 2. Filter (if datafields are present)
   */
  const tableActionRowSearchComponent = (
    <FlywheelSearchInput
      tableId={props.tableId}
      inputSearchColumnName={
        props.inputSearchColumnName ?? 'search_column_name'
      }
      dataTestId={props.tableId}
      shouldAddWildcardsToFilterValue={props.shouldAddWildcardsToFilterValue}
      searchInputSize={props.searchInputSize}
      searchInputPlaceholder={props.searchInputPlaceholder}
      onFilterUpdate={props.onFilterUpdate}
      upgradeFiltersInStorage={upgradeFiltersInStorage}
      searchInputClassName={searchInputClassName}
      hasSearchInputWithButton={hasSearchInputWithButton}
    />
  );

  const tableActionRowFilterComponent = (
    <FiltersMenu
      customFilterIcon={customFilterIcon}
      currency={currencyCode}
      dataFields={dataFields}
      currentFilters={currentFilters}
      handleSave={updateFilters}
      filterButtonSize={
        hasSearchInputWithButton ? ButtonSize.Medium : ButtonSize.Large
      }
      isOpen={isFilterMenuOpen}
      setOpen={setFilterMenuOpen}
      filterDateFormat={filterDateFormat}
      singleDecimalColumns={COLUMNS_WITH_SINGLE_DECIMAL_FILTER}
      tooltipClass={filtersMenuTooltipClass}
      filtersContainerClass={filtersContainerClass}
      dataTestId={props.tableId}
    />
  );

  const columnManagerComponent = (
    <ColumnManager
      variant={ColumnManagerVariant.ColumnGroupWithHeader}
      values={selectedColumns}
      onChange={onColumnSelectionChange}
      options={props.columnManagerOptions}
      tooltipClass={columnManagerTooltipClass}
      hideColumnText={hideColumnManagerText}
    />
  );

  const internalActionComponents: JSX.Element[] = [];

  props.hasSearchInput &&
    internalActionComponents.push(tableActionRowSearchComponent);

  dataFields &&
    dataFields.length > 0 &&
    internalActionComponents.push(tableActionRowFilterComponent);

  const segmentedFilter = useMemo(
    () =>
      segmentedFilterLinks &&
      defaultSelectedSegmentedFilterValue &&
      onSegmentedLinkChange ? (
        <SegmentedLinks
          defaultSelectedKey={defaultSelectedSegmentedFilterValue}
          links={segmentedFilterLinks}
          value={defaultSelectedSegmentedFilterValue}
          onChange={onSegmentedLinkChange}
        />
      ) : undefined,
    [defaultSelectedSegmentedFilterValue, segmentedFilterLinks]
  );

  segmentedFilter && internalActionComponents.push(segmentedFilter);

  props.columnManagerOptions &&
    props.columnManagerOptions.length &&
    internalActionComponents.push(columnManagerComponent);

  const dataFieldMap = convertToMap(dataFields);
  const filterRows = filterOpsToRows(dataFieldMap, true)(currentFilters);

  const allTableActionRowLeftComponents = [
    ...actionRowLeftSideComponents,
    ...internalActionComponents,
  ];

  const fullWidthElementIndex =
    actionRowFullWidthElementIndex ||
    allTableActionRowLeftComponents.indexOf(tableActionRowSearchComponent);

  return (
    <>
      {!isTableActionRowDisabled && (
        <TableActionRow
          leftSideElements={allTableActionRowLeftComponents}
          rightSideElements={tableActionComponents}
          roundedTop={roundedTop}
          fullWidthElementIndex={fullWidthElementIndex}
          className={actionRowClassnames}
          extraRightSideElements={extraRightSideElements}
        />
      )}
      {additionalActionRow?.()}
      <FilterRow
        filterRows={filterRows}
        onPillClose={areFilterPillsClearable ? onPillClose : undefined}
        clearAllFilters={clearAllFilters}
        onPillClick={onPillClick}
        currencyCode={props.currencyCode}
        customRowClassname="border-t-0"
        filterDateFormat={filterDateFormat}
        dataTestId={props.tableId}
      />
      <PaginatedTableV2<T, A, R, S>
        columns={columns}
        tableId={props.tableId}
        dataFetcher={props.dataFetcher}
        aggregationHeaderDataFetcher={props.aggregationHeaderDataFetcher}
        additionalAggregationHeaderDataFetcher={
          props.additionalAggregationHeaderDataFetcher
        }
        shouldExecuteAdditionalAggregationDataFetcher={
          props.shouldExecuteAdditionalAggregationDataFetcher
        }
        tableData={props.tableData}
        tableClassName={props.tableClassName}
        loadingAndEmptyStateClassName={props.loadingAndEmptyStateClassName}
        showBulkSelection={props.showBulkSelection}
        rowIdsDisabled={props.rowIdsDisabled}
        rowIdsSelected={props.rowIdsSelected}
        rowIdKeyName={props.rowIdKeyName}
        onRowsSelection={props.onRowsSelection}
        bulkEditMenu={props.bulkEditMenu}
        noDataState={props.noDataState}
        rowCheckboxClassName={rowCheckboxClassName}
        cellClassName={props.cellClassName}
        subCellClassName={props.subCellClassName}
        tableEmptyStateClass={props.tableEmptyStateClass}
        bulkEditColumnWidth={props.bulkEditColumnWidth}
        headerClass={props.headerClass}
        onFullPageTable={onFullPageTable}
        allowStickyHeader={allowStickyHeader}
        customStickyHeaderClassName={customStickyHeaderClassName}
        allowHorizontalDrag={allowHorizontalDrag}
        missingRequiredFields={missingRequiredFields}
        infiniteScrollProps={props.infiniteScrollProps}
        virtualizationProps={props.virtualizationProps}
        expandAllRows={expandAllRows}
        enableExpandSubRows={enableExpandSubRows}
        minColumnSize={props.minColumnSize}
        resetSelection={props.resetSelection}
        bulkSelectCellTooltip={bulkSelectCellTooltip}
        bulkSelectHeaderTooltip={bulkSelectHeaderTooltip}
        bulkSelectCellDisabledTooltip={bulkSelectCellDisabledTooltip}
        onToggleExpand={onToggleExpand}
        rowIdsProcessing={rowIdsProcessing}
        applyStickyOnOverflow={props.applyStickyOnOverflow}
        showExpandAllRowsButton={props.showExpandAllRowsButton}
        tableName={props.tableName}
        fetchAndStoreTotalKey={props.fetchAndStoreTotalKey}
      />
      {!isFooterDisabled && (
        <div
          className={classNames({
            'sticky bottom-0 z-10': allowStickyHeader && showStickyFooter,
          })}
        >
          <FlywheelTablePaginationRowV2
            tableId={props.tableId}
            loadAggregationDataOnPageNavigation={
              loadAggregationDataOnPageNavigation
            }
            onPaginationChange={onPaginationChange}
            loadTotalFromAggregateData={props.loadTotalFromAggregateData}
            totalCountFetcher={props.totalCountFetcher}
            filters={currentTableFilters}
            rowsPerPageOptions={props.rowsPerPageOptions}
          />
        </div>
      )}
    </>
  );
}
FlywheelTableV2.displayName = 'FlywheelTableV2';
