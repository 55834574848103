import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { SbAdsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { SB_ADS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<SbAdsDetails & TableDataAdsManager> = (
  props
) => {
  return makeTextColumn(({ creativeDetails }: SbAdsDetails) =>
    creativeDetails?.portfolio ? creativeDetails?.portfolio : undefined
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const portfolioNameColumn: FlywheelTableColumn<
  SbAdsDetails,
  TableDataAdsManager
> = {
  columnName: SB_ADS_API_COLUMN_NAME.Portfolio,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_PORTFOLIO_NAME,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const portfolioNameFilter = createStringDataFieldFilter(
  SB_ADS_API_COLUMN_NAME.Portfolio,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_PORTFOLIO_NAME,
  isValidString()
);
