import {
  firstDayOfFreeTrial,
  getDaysSinceTrialEnded,
  getFreeTrialDaysRemaining,
  isInTrial,
} from '../../containers/userProvider/selectors';
import {
  AccountStatusResponse,
  BillingEstimateResponseType,
  PaymentPlanType,
} from '../../lib/types/Billing';
import { Account } from '../../lib/types/Fam';
import { SalesChannelConnectionStatus } from '../../lib/utilities/connectionStatus';

export enum TrialBannerStatus {
  Downgraded = 'Downgraded',
  FirstDay = 'FirstDay',
  Happening = 'Happening',
  HappeningAlert = 'HappeningAlert',
  Delinquent = 'Delinquent',
  LockedOut = 'LockedOut',
  None = 'None',
  TrialEnded = 'TrialEnded',
  TrialEndedAlert = 'TrialEndedAlert',
  AutomaticDowngrade = 'AutomaticDowngrade',
}

type GetTrialBannerStateArgs = {
  adStatus: SalesChannelConnectionStatus;
  account?: Account;
  billingInfo?: AccountStatusResponse;
  billingEstimate?: BillingEstimateResponseType;
  isAiSubscribed: boolean;
};

const getInTrialStatus = (
  account: Account,
  hasPayment: boolean,
  daysRemainingInTrial: number
) => {
  if (firstDayOfFreeTrial(account)) {
    return TrialBannerStatus.FirstDay;
  }
  if (daysRemainingInTrial > 5 || hasPayment) {
    return TrialBannerStatus.Happening;
  }
  if (daysRemainingInTrial <= 5 && daysRemainingInTrial >= 1) {
    return TrialBannerStatus.HappeningAlert;
  }

  return TrialBannerStatus.None;
};

const getTrialEndStatus = (freemium: boolean, daysSinceTrialEnded: number) => {
  if (freemium) {
    return TrialBannerStatus.TrialEnded;
  }
  if (daysSinceTrialEnded >= 25) {
    return TrialBannerStatus.AutomaticDowngrade;
  } else {
    return TrialBannerStatus.TrialEndedAlert;
  }
};

export function getTrialBannerState({
  account,
  billingInfo,
  billingEstimate,
  adStatus,
  isAiSubscribed,
}: GetTrialBannerStateArgs): TrialBannerStatus {
  const { lockedAt = '', enforcedAt = '', accountType = '' } = account || {};

  const { isDelinquent = false, hasPayment = false } = billingInfo || {};

  const { isFreemium = false } = billingEstimate || {};

  if (
    !account ||
    !account.freeTrialStartedAt ||
    accountType === PaymentPlanType.Managed ||
    accountType === PaymentPlanType.LegacySelfService ||
    accountType === PaymentPlanType.Agency ||
    accountType === PaymentPlanType.AgencyClient
  ) {
    return TrialBannerStatus.None;
  }

  if (lockedAt) {
    return TrialBannerStatus.LockedOut;
  }

  if (isDelinquent) {
    return TrialBannerStatus.Delinquent;
  }

  if (enforcedAt && !hasPayment) {
    return TrialBannerStatus.Downgraded;
  }

  const daysRemainingInTrial = getFreeTrialDaysRemaining(account);

  const daysSinceTrialEnded = getDaysSinceTrialEnded(account);

  const outOfTrialNoPayment = daysRemainingInTrial === 0 && !hasPayment;

  if (isInTrial(account) && isAiSubscribed) {
    // The Free Trial Flow (with Ai)
    return getInTrialStatus(account, hasPayment, daysRemainingInTrial);
  }

  if (outOfTrialNoPayment && isAiSubscribed) {
    return getTrialEndStatus(isFreemium, daysSinceTrialEnded);
  }

  return TrialBannerStatus.None;
}
