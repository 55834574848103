import { Factory } from 'fishery';

import { PortfolioResponse } from '../../types/AOSharedTypes';

export default Factory.define<PortfolioResponse>(() => ({
  portfolios: [
    {
      merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
      name: 'Portfolio 2',
      portFolioId: 'uuid1',
    },
    {
      merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
      name: 'Portfolio 1',
      portFolioId: 'uuid2',
    },
    {
      merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
      name: 'Portfolio 3',
      portFolioId: 'uuid3',
    },
  ],
}));
