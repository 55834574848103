import {
  CheckboxCheckedState,
  CheckboxLabelProps,
  CheckboxState,
  CheckboxWithTextInputProps,
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
  TextInputState,
  TypedPortaledTooltipProps,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';
import { fromNullable } from 'fp-ts/lib/Option';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Location, useLocation } from 'react-router-dom';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import {
  TableCellChange,
  TableChange,
  TableSelectSettings,
  WithTable,
} from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeCheckboxLabelColumn,
  makeCheckboxWithTextInputColumn,
  makeTruncatedTextLinkColumn,
  representSmartCampaign,
} from '../../../../../containers/table/utils/makeTableCells';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import {
  AdGroupDetails,
  AdGroupStatus,
  AdLevel,
  AdType,
  AiBiddingValues,
  AutomationStatus,
  CampaignCostType,
  CampaignTargetingType,
  DetailAdGroup,
  EntityType,
  FlywheelSalesChannel,
  ValidateUniqueValuesRequest,
} from '../../../../../lib/types/AOSharedTypes';
import { MerchantType } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';

import {
  BulkEditModalElement,
  BulkEditModalNumericValueOperator,
  NumericInputType,
} from '@teikametrics/tm-design-system';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../../lib/types/SalesChannels';
import { ViewTrendLink } from '../../../components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../components/ViewTrends';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE,
  AdLevelI8nKeyMapper,
  EditedRowInfo,
  MERCHANT_COUNTRY_IDS,
  NumericValueConfig,
  SALES_CHANNEL_ID,
  TableDataAdsManager,
} from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
  TABLE_UNIQ_KEY,
  fetchNonAsciiCharacters,
  getTooltipContentForArchivedEntity,
  getUpdatedValueFromOperator,
  hasOnlySpecialCharacters,
  isAdGroupStatusArchived,
  isBetweenMinAndMaxValue,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
  isInvalidAdGroupName,
  isMerchantTypeVendor,
  isValidAdGroupNameCharacterLength,
  isValidAscii,
} from '../utils';
import { getAdsManagerDetailsQueryParams } from '../utils/queryParamsUtils';
import { getMerchantFromLocalStorage } from '../utils/storageUtils';
import { isAdGroupNameColumnDuplicateWithinEditedCellsOfSameCampaign } from './adgroupName.utils';
import { transformAdvertisingGoal } from './utils';

export type BulkEditMenuValue =
  | typeof ADGROUPS_API_COLUMN_NAME.AdGroupStatus
  | typeof ADGROUPS_API_COLUMN_NAME.BidAutomationStatus
  | typeof ADGROUPS_API_COLUMN_NAME.MACSTarget
  | typeof ADGROUPS_API_COLUMN_NAME.MaxBid
  | typeof ADGROUPS_API_COLUMN_NAME.MinBid
  | typeof ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting
  | typeof ADGROUPS_API_COLUMN_NAME.Tags;

export enum RowsCampaignTargetingType {
  Auto = 'Auto',
  Manual = 'Manual',
  Both = 'Both',
}

interface RowCellElementInterface {
  isPageCampaignFlag: boolean;
  details: AdGroupDetails;
  salesChannelId: string | null;
  salesChannel: FlywheelSalesChannel;
  merchantCountryIds: string | null;
  userId: string;
  accountId: string;
  existingUrl: string;
  adType: string | null;
  adTypes: AdType[];
}

interface VisibilityCriteria {
  readonly enabledByFeatureFlag: boolean;
}

export interface BulkEditMenuOption {
  readonly header: I18nKey;
  readonly label: string;
  readonly value: BulkEditMenuValue;
  readonly elements: BulkEditModalElement[];
  readonly visibilityCriteria?: VisibilityCriteria;
}

export const getInValidChangesCount = (
  inValidChangesCount: number,
  adGroupDefaultBid: string,
  adType: AdType.SponsoredProducts | AdType.SponsoredDisplay,
  campaignCostType: CampaignCostType | undefined,
  campaignDailyBudget?: string,
  currentAdGroupDefaultBid: string = '',
  validationConfig?: {
    adgroupDefaultBidValidationConfigCPC?: NumericValueConfig;
    adgroupDefaultBidValidationConfigVCPM?: NumericValueConfig;
  }
) => {
  if (
    !adGroupDefaultBid ||
    parseFloat(currentAdGroupDefaultBid) === parseFloat(adGroupDefaultBid)
  )
    return inValidChangesCount;

  let minRequiredValue =
    validationConfig?.adgroupDefaultBidValidationConfigCPC?.min;
  let maxRequiredValue =
    validationConfig?.adgroupDefaultBidValidationConfigCPC?.max;

  if (campaignCostType === CampaignCostType.VCPM) {
    minRequiredValue =
      validationConfig?.adgroupDefaultBidValidationConfigVCPM?.min;
    maxRequiredValue =
      validationConfig?.adgroupDefaultBidValidationConfigVCPM?.max;
  }

  return !isNil(campaignDailyBudget) &&
    !isNil(adGroupDefaultBid) &&
    isDefaultBidLessThanDailyBudget(
      adType,
      campaignDailyBudget,
      adGroupDefaultBid
    ) &&
    isBetweenMinAndMaxValue(
      Number(adGroupDefaultBid),
      minRequiredValue || 0,
      maxRequiredValue || Infinity
    )
    ? inValidChangesCount
    : inValidChangesCount + 1;
};

export const setIsCampaignCostTypeVCPMAndFlywheelSettingIsSelected = (
  isCampaignCostTypeVCPMAndFlywheelSettingIsSelected: boolean,
  bulkChanges: TableCellChange,
  rowId: string,
  bulkMenuValue: string,
  values: string[],
  rowData: AdGroupDetails | undefined,
  operator?: BulkEditModalNumericValueOperator
) => {
  if (
    !isCampaignCostTypeVCPMAndFlywheelSettingIsSelected &&
    bulkMenuValue !== ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid
  ) {
    if (
      [
        ADGROUPS_API_COLUMN_NAME.MinBid,
        ADGROUPS_API_COLUMN_NAME.MaxBid,
        ADGROUPS_API_COLUMN_NAME.MACSTarget,
        ADGROUPS_API_COLUMN_NAME.BidModifier,
        ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget,
        ADGROUPS_API_COLUMN_NAME.TotalBudget,
      ].includes(bulkMenuValue)
    ) {
      const existingValue =
        (get(
          rowData,
          EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING[bulkMenuValue]
        ) as number) || 0;
      bulkChanges[rowId][bulkMenuValue] = getUpdatedValueFromOperator(
        existingValue,
        bulkChanges[rowId][bulkMenuValue],
        values[0],
        operator,
        [
          ADGROUPS_API_COLUMN_NAME.MACSTarget,
          ADGROUPS_API_COLUMN_NAME.BidModifier,
        ].includes(bulkMenuValue)
          ? NumericInputType.Percent
          : NumericInputType.Currency
      );
    } else {
      bulkChanges[rowId][bulkMenuValue] = values[0];
    }
  }
};
export const getCampaignCostTypeVCPM = (
  values: string[],
  bulkChanges: string,
  currentValue?: string,
  campaignCostType?: CampaignCostType,
  operators?: BulkEditModalNumericValueOperator[]
) => {
  const updatedValue =
    campaignCostType === CampaignCostType.VCPM
      ? getUpdatedValueFromOperator(
          currentValue,
          bulkChanges,
          values[0],
          first(operators)
        )
      : getUpdatedValueFromOperator(
          currentValue,
          bulkChanges,
          values[1],
          operators?.[1]
        );
  return !isNaN(Number(updatedValue)) ? updatedValue : currentValue!;
};

const getMinBidInvalidCount = (
  minBid: string,
  config: NumericValueConfig,
  inValidChangesCount: number,
  requiredMaxBid?: string
) =>
  !isNil(requiredMaxBid) &&
  !isNil(minBid) &&
  (isCurrentValueGreaterThanRequiredMaxValue(Number(requiredMaxBid), minBid) ||
    !isBetweenMinAndMaxValue(
      Number(minBid),
      config?.min || 0,
      config?.max || Infinity
    ))
    ? inValidChangesCount + 1
    : inValidChangesCount;

const getMaxBidInvalidCount = (
  maxBid: string,
  config: NumericValueConfig,
  inValidChangesCount: number,
  requiredMinBid?: string
) =>
  !isNil(requiredMinBid) &&
  !isNil(maxBid) &&
  (isCurrentValueLessThanRequiredMinValue(Number(requiredMinBid), maxBid) ||
    !isBetweenMinAndMaxValue(
      Number(maxBid),
      config?.min || 0,
      config?.max || Infinity
    ))
    ? inValidChangesCount + 1
    : inValidChangesCount;

export const getMacsTargetInvalidCount = (
  macsTarget: string,
  config: NumericValueConfig,
  inValidChangesCount: number
) =>
  !isNil(config?.min) &&
  !isBetweenMinAndMaxValue(
    Number(macsTarget),
    config.min || 0,
    config.max || Infinity
  )
    ? inValidChangesCount + 1
    : inValidChangesCount;

export const getMinBidInvalidChangesCount = (
  rowId: string,
  bulkChanges: TableCellChange,
  minBidManualValidationConfig: NumericValueConfig,
  minBidAutoValidationConfig: NumericValueConfig,
  inValidChangesCount: number,
  rowData?: AdGroupDetails
) => {
  const maxBid = rowData?.flywheelSettings.maxBid?.amount;
  const updatedMaxBid = bulkChanges[rowId][ADGROUPS_API_COLUMN_NAME.MaxBid];
  const minBid = bulkChanges[rowId][ADGROUPS_API_COLUMN_NAME.MinBid];
  const requiredMaxBid = updatedMaxBid || maxBid;
  const campaignTargetingType = rowData?.adGroupDetails.campaignTargetingType;
  const config =
    campaignTargetingType === CampaignTargetingType.Manual
      ? minBidManualValidationConfig
      : minBidAutoValidationConfig;
  return getMinBidInvalidCount(
    minBid,
    config,
    inValidChangesCount,
    requiredMaxBid
  );
};

export const getMaxBidInvalidChangesCount = (
  rowId: string,
  bulkChanges: TableCellChange,
  maxBidManualValidationConfig: NumericValueConfig,
  maxBidAutoValidationConfig: NumericValueConfig,
  inValidChangesCount: number,
  rowData?: AdGroupDetails
) => {
  const minBid = rowData?.flywheelSettings.minBid?.amount;
  const updatedMinBid = bulkChanges[rowId][ADGROUPS_API_COLUMN_NAME.MinBid];
  const maxBid = bulkChanges[rowId][ADGROUPS_API_COLUMN_NAME.MaxBid];
  const requiredMinBid = updatedMinBid || minBid;
  const campaignTargetingType = rowData?.adGroupDetails.campaignTargetingType;
  const config =
    campaignTargetingType === CampaignTargetingType.Manual
      ? maxBidManualValidationConfig
      : maxBidAutoValidationConfig;

  return getMaxBidInvalidCount(
    maxBid,
    config,
    inValidChangesCount,
    requiredMinBid
  );
};

export const getUpdatedCampaignTargetingTypeRowValue = (
  values: string[],
  bulkChanges: string,
  currentValue?: string,
  campaignTargetingType?: CampaignTargetingType,
  operators?: BulkEditModalNumericValueOperator[]
) => {
  const updatedValue =
    campaignTargetingType === CampaignTargetingType.Auto
      ? getUpdatedValueFromOperator(
          currentValue,
          bulkChanges,
          values[0],
          first(operators)
        )
      : getUpdatedValueFromOperator(
          currentValue,
          bulkChanges,
          values[1],
          operators?.[1]
        );
  return !isNaN(Number(updatedValue)) ? updatedValue : currentValue!;
};

export const getInValidChangesCounts = (
  inValidChangesCount: number,
  bulkChanges: TableCellChange,
  rowId: string,
  bulkMenuValue: string,
  rowData?: AdGroupDetails,
  rowDataFromAdGroupDataForAllPages?: AdGroupDetails,
  validationConfig?: {
    maxBidAutoValidationConfig?: NumericValueConfig;
    maxBidManualValidationConfig?: NumericValueConfig;
    minBidManualValidationConfig?: NumericValueConfig;
    minBidAutoValidationConfig?: NumericValueConfig;
  }
) => {
  if (bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MinBid) {
    const maxBid =
      rowDataFromAdGroupDataForAllPages?.flywheelSettings.maxBid?.amount;
    const updatedMaxBid = bulkChanges[rowId][ADGROUPS_API_COLUMN_NAME.MaxBid];
    const minBid = bulkChanges[rowId][ADGROUPS_API_COLUMN_NAME.MinBid];
    const campaignTargetingType = rowData?.adGroupDetails.campaignTargetingType;
    const config =
      campaignTargetingType === CampaignTargetingType.Manual
        ? validationConfig?.minBidManualValidationConfig
        : validationConfig?.minBidAutoValidationConfig;

    inValidChangesCount = getInValidChangesCountMaxValue(
      minBid,
      inValidChangesCount,
      config,
      maxBid,
      updatedMaxBid
    );
  }

  if (bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MaxBid) {
    const minBid = rowData?.flywheelSettings.minBid?.amount;
    const updatedMinBid = bulkChanges[rowId][ADGROUPS_API_COLUMN_NAME.MinBid];
    const maxBid = bulkChanges[rowId][ADGROUPS_API_COLUMN_NAME.MaxBid];
    const campaignTargetingType = rowData?.adGroupDetails.campaignTargetingType;
    const config =
      campaignTargetingType === CampaignTargetingType.Manual
        ? validationConfig?.maxBidManualValidationConfig
        : validationConfig?.maxBidAutoValidationConfig;
    inValidChangesCount = getInValidChangesCountMinValue(
      maxBid,
      inValidChangesCount,
      config,
      minBid,
      updatedMinBid
    );
  }
  return inValidChangesCount;
};

export const getInValidChangesCountMinValue = (
  maxBid: string,
  inValidChangesCount: number,
  config?: NumericValueConfig,
  minBid?: string,
  updatedMinBid?: string
) => {
  const requiredMinBid = updatedMinBid || minBid;
  return !isNil(requiredMinBid) &&
    !isNil(maxBid) &&
    (isCurrentValueLessThanRequiredMinValue(Number(requiredMinBid), maxBid) ||
      !isBetweenMinAndMaxValue(
        Number(maxBid),
        config?.min || 0,
        config?.max || Infinity
      ))
    ? inValidChangesCount + 1
    : inValidChangesCount;
};

export const getInValidChangesCountMaxValue = (
  minBid: string,
  inValidChangesCount: number,
  config?: NumericValueConfig,
  maxBid?: string,
  updatedMaxBid?: string
) => {
  const requiredMaxBid = updatedMaxBid || maxBid;
  return !isNil(requiredMaxBid) &&
    !isNil(minBid) &&
    (isCurrentValueGreaterThanRequiredMaxValue(
      Number(requiredMaxBid),
      minBid
    ) ||
      !isBetweenMinAndMaxValue(
        Number(minBid),
        config?.min || 0,
        config?.max || Infinity
      ))
    ? inValidChangesCount + 1
    : inValidChangesCount;
};

export const getAutomationStatusForSingleAdGroup = (
  adGroupDetails?: AdGroupDetails
) => {
  if (adGroupDetails?.flywheelSettings?.automationStatus) {
    return AutomationStatus.Active;
  }

  return adGroupDetails?.flywheelSettings?.automationStatus === false
    ? AutomationStatus.Inactive
    : AutomationStatus.Unset;
};

export const isDefaultBidLessThanDailyBudget = (
  adType: AdType,
  campaignDailyBudget: string,
  adGroupDefaultBid: string
) => {
  if (adType === AdType.SponsoredDisplay) {
    return isCurrentValueLessThanRequiredMinValue(
      Number(campaignDailyBudget) / 2,
      adGroupDefaultBid
    );
  }

  return adType === AdType.SponsoredProducts
    ? isCurrentValueLessThanRequiredMinValue(
        Number(campaignDailyBudget),
        adGroupDefaultBid
      )
    : true;
};
export const getExistingValue = (adGroupName?: string) => adGroupName || '';

export const getAdgroupName = (changedValue: string, existingValue: string) =>
  changedValue ?? existingValue;

export const getContextId = (id?: string) => id || '';

const getExistingUrl = (isPageAdGroupFlag: boolean, location: Location) => {
  if (isPageAdGroupFlag) {
    return location.pathname.slice(
      0,
      location.pathname.indexOf(ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups)
    );
  }
  return '';
};

const getIsPageAdFlag = (pageAd: string, location: Location) => {
  return location.pathname.indexOf(pageAd) !== -1;
};

export const getIsInputInvalid = (
  salesChannel: FlywheelSalesChannel,
  value: string,
  isAdGroupNameDuplicate: boolean,
  isAdGroupNameUniqueWithinCampaign: boolean
) => {
  return (
    isInvalidAdGroupName(salesChannel, value) ||
    !isValidAdGroupNameCharacterLength(value.trim()) ||
    isAdGroupNameDuplicate ||
    !isAdGroupNameUniqueWithinCampaign
  );
};

export const getIsAdGroupArchived = (
  adGroupDetails: DetailAdGroup,
  status?: AdGroupStatus
) =>
  isCampaignStatusArchived(adGroupDetails.campaignStatus) ||
  isAdGroupStatusArchived(status);

const getRowCellElement = ({
  isPageCampaignFlag,
  details,
  salesChannelId,
  salesChannel,
  merchantCountryIds,
  userId,
  accountId,
  existingUrl,
  adType,
  adTypes,
}: RowCellElementInterface) => {
  return isPageCampaignFlag
    ? encodeURIComponent(details.adGroupId) +
        getAdsManagerDetailsQueryParams(
          salesChannelId || String(salesChannel),
          merchantCountryIds?.split(',') ||
            (getMerchantFromLocalStorage({
              userId,
              accountId,
              salesChannelId: salesChannelId || String(salesChannel),
            }) as (string | number)[]),
          (adType?.split(',') as AdType[]) || adTypes,
          details.adGroupDetails.campaignTargetingType,
          details.adGroupDetails.adGroupName
            ? encodeURIComponent(details.adGroupDetails.adGroupName)
            : details.adGroupDetails.adGroupName
        )
    : existingUrl +
        ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups +
        '/' +
        encodeURIComponent(details.adGroupId) +
        getAdsManagerDetailsQueryParams(
          String(salesChannel),
          getMerchantFromLocalStorage({
            userId,
            accountId,
            salesChannelId: salesChannelId || String(salesChannel),
          }) as (string | number)[],
          adTypes,
          details.adGroupDetails.campaignTargetingType,
          details.adGroupDetails.adGroupName
            ? encodeURIComponent(details.adGroupDetails.adGroupName)
            : details.adGroupDetails.adGroupName
        );
};

export const getTooltipContent = (
  intl: IntlShape,
  isAdGroupArchived: boolean,
  showTooltip: boolean,
  value: string,
  salesChannel: FlywheelSalesChannel,
  isAdGroupNameDuplicate: boolean,
  isTextEclipsed: boolean,
  isSBA?: boolean
) => {
  if (isAdGroupArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.AdGroups],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME,
      }),
      intl
    );
  } else if (showTooltip) {
    if (
      salesChannel === FlywheelSalesChannel.Walmart &&
      !isValidAdGroupNameCharacterLength(value)
    ) {
      return (
        <p>
          {intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_CHARACTER_LENGTH,
          })}
        </p>
      );
    }
    if (isAdGroupNameDuplicate) {
      return (
        <p>
          {intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_DUPLICATE_AMAZON,
          })}
        </p>
      );
    }
    if (
      salesChannel === FlywheelSalesChannel.Amazon &&
      !isValidAdGroupNameCharacterLength(value)
    ) {
      return (
        <p>
          {intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_CHARACTER_LENGTH_AMAZON,
          })}
        </p>
      );
    }
    if (hasOnlySpecialCharacters(value, salesChannel) || !value) {
      return (
        <p>
          {intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_ALL_SPECIAL_CHARACTERS,
          })}
        </p>
      );
    }
    return getIsValidAscii(intl, value, salesChannel);
  } else if (isTextEclipsed) {
    return <p className="w-250 text-center">{value}</p>;
  }
  return <></>;
};

export const getIsValidAscii = (
  intl: IntlShape,
  value: string,
  salesChannel: FlywheelSalesChannel
) => {
  if (!isValidAscii(value, salesChannel)) {
    const invalidCharacters = fetchNonAsciiCharacters(value, salesChannel);
    return (
      <p>
        {intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_CHARACTERS,
          },
          {
            invalidCount: invalidCharacters ? invalidCharacters.length : 0,
            invalidCharacters,
          }
        )}
      </p>
    );
  }
};

export const getIsTooltipDisabled = (
  isAdGroupArchived: boolean,
  showTooltip: boolean,
  isTextEclipsed: boolean
) => {
  if (isAdGroupArchived) return false;

  return showTooltip ? false : !isTextEclipsed;
};

export const getMerchantCountryId = (
  selectedMerchantCountries: string | (string | number)[]
) => {
  let merchantCountryId: string;
  if (isArray(selectedMerchantCountries)) {
    merchantCountryId = isEmpty(selectedMerchantCountries)
      ? ''
      : selectedMerchantCountries[0].toString();
  } else {
    merchantCountryId = selectedMerchantCountries;
  }
  return merchantCountryId;
};

export const getIsColumnEditDisabled = (
  adGroupDetails: DetailAdGroup,
  status?: AdGroupStatus
) =>
  isCampaignStatusArchived(adGroupDetails.campaignStatus) ||
  isAdGroupStatusArchived(status);

export const getState = (
  adGroupDetails: DetailAdGroup,
  status?: AdGroupStatus
) => {
  return isCampaignStatusArchived(adGroupDetails.campaignStatus) ||
    isAdGroupStatusArchived(status)
    ? CheckboxState.Disabled
    : CheckboxState.Default;
};

export const getIsSponsoredDisplayAndVendor = (
  selectedAdType: AdType,
  merchantType?: MerchantType
) =>
  isMerchantTypeVendor(merchantType) &&
  selectedAdType === AdType.SponsoredDisplay;

export const getshowTooltip = (focussed: boolean, isInputInvalid: boolean) =>
  focussed && isInputInvalid;

export const getChecked = (isRowSelected: boolean) =>
  isRowSelected ? CheckboxCheckedState.Checked : CheckboxCheckedState.Unchecked;

export const getInputState = (isInputInvalid: boolean) =>
  isInputInvalid ? TextInputState.Error : TextInputState.Default;

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  adGroupId,
  adGroupDetails,
  channelSettings,
  flywheelSettings,
  adGroupPerformance,
  isEditMode,
  pendingFields,
  adTypes,
  salesChannel,
  aoApiClient,
  selectedMerchantCountries,
  updateEditedRecords,
  merchantType,
  selectedAdType,
  allMerchants,
  showSmartCampaign,
}) => {
  const intl: IntlShape = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);
  const location = useLocation();
  const [
    isAdGroupNameUniqueWithinCampaign,
    setIsAdGroupNameUniqueWithinCampaign,
  ] = useState<boolean>(true);

  const data: AdGroupDetails = {
    adGroupId,
    adGroupDetails,
    channelSettings,
    flywheelSettings,
    adGroupPerformance,
    pendingFields,
  };

  const selectSettings = useSelector<
    WithTable<AdGroupDetails>,
    TableSelectSettings
  >(({ tableState }) =>
    tableSelectors.getSelectInfoSelector()(
      tableState,
      ADS_MANAGER_ADGROUP_TABLE_ID
    )
  );

  const currentPage = useSelector<WithTable<AdGroupDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_ADGROUP_TABLE_ID
      )
  );

  const changedValue = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellValueSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.AdGroupName,
        EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
        TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );
  const existingValue = getExistingValue(adGroupDetails.adGroupName);
  const adgroupName = getAdgroupName(changedValue, existingValue);

  const [value, setValue] = useState<string>(adgroupName);

  const userContext = useContext<UserContextState>(UserContext);
  const userId = getContextId(userContext.userInfo.userDetails?.id);
  const accountId = getContextId(getCurrentAccountFromContext(userContext)?.id);

  useEffect(() => {
    isEditMode && setValue(adgroupName);
  }, [isEditMode, currentPage]);

  useEffect(() => {
    !isEditMode && setIsAdGroupNameUniqueWithinCampaign(true);
  }, [isEditMode]);

  const updateCellValue = (inputValue: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
        rowId: data.adGroupId,
        columnName: ADGROUPS_API_COLUMN_NAME.AdGroupName,
        value: inputValue,
        existingValue: existingValue,
        validationData: data.adGroupDetails.campaignId,
      })
    );
  };

  const tableChange = useSelector<WithTable<AdGroupDetails>, TableChange>(
    tableChangeSelector
  );

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [data.adGroupId]
  );

  const invokeCallback = (editrowInfo: EditedRowInfo) =>
    fromNullable(updateEditedRecords)
      .map((fn) => fn(editrowInfo))
      .toUndefined();

  const checkAdGroupNameUniquenessAndUpdateState = async (
    request: ValidateUniqueValuesRequest
  ) => {
    const validationResult = await aoApiClient.validateUniqueValues(
      accountId,
      request
    );
    setIsAdGroupNameUniqueWithinCampaign(validationResult.isValid);
    invokeCallback({
      isNameUnique: validationResult.isValid,
      rowId: adGroupId,
    });
  };

  const debouncedIsAdGroupNameUnique = useMemo(
    //@ts-ignore
    () => debounce(checkAdGroupNameUniquenessAndUpdateState, 500),
    [updateEditedRecords]
  );

  const getPageUrl = (details: AdGroupDetails): string => {
    let existingUrl: string = '';

    const isPageAdGroupFlag = getIsPageAdFlag(
      ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups,
      location
    );

    const isPageCampaignFlag = getIsPageAdFlag(
      ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns,
      location
    );

    existingUrl = getExistingUrl(isPageAdGroupFlag, location);

    const urlSearchParams = new URLSearchParams(
      decodeURIComponent(location.search)
    );

    const salesChannelId = urlSearchParams.get(SALES_CHANNEL_ID);

    const merchantCountryIds = urlSearchParams.get(MERCHANT_COUNTRY_IDS);

    const adType = urlSearchParams.get(AD_TYPE);

    return getRowCellElement({
      isPageCampaignFlag,
      details,
      salesChannelId,
      salesChannel,
      merchantCountryIds,
      userId,
      accountId,
      existingUrl,
      adType,
      adTypes,
    });
  };

  const textInputref = useRef<HTMLInputElement>(null);
  const [isTextEclipsed, setIsTextEclipsed] = useState(false);
  useEffect(() => {
    if (
      textInputref.current?.scrollWidth &&
      textInputref.current?.scrollWidth > textInputref.current?.clientWidth
    ) {
      setIsTextEclipsed(true);
    } else {
      setIsTextEclipsed(false);
    }
  }, [textInputref.current?.value, isEditMode]);

  const updatedAiBiddingValue = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        adGroupId,
        ADGROUPS_API_COLUMN_NAME.AiBidding
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  ) as AiBiddingValues;
  const aiBiddingValue =
    updatedAiBiddingValue ?? data.flywheelSettings.aiBidding;

  const updatedOverridenBid = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        adGroupId,
        ADGROUPS_API_COLUMN_NAME.BidModifierOverRide
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const isOverrideBidModifier =
    updatedOverridenBid === 'true'
      ? true
      : updatedOverridenBid === 'false'
      ? false
      : data.flywheelSettings.isOverrideBidModifier;

  const advertisingGoal = transformAdvertisingGoal(
    aiBiddingValue,
    data.flywheelSettings.advertisingGoal,
    isOverrideBidModifier
  );

  if (isEditMode) {
    const isAdGroupNameDuplicate =
      isAdGroupNameColumnDuplicateWithinEditedCellsOfSameCampaign(
        value,
        tableChange.cell,
        adGroupId
      );

    const isSBA = selectedAdType === AdType.SearchBrandAmplifier;

    const isInputInvalid = getIsInputInvalid(
      salesChannel,
      value,
      isAdGroupNameDuplicate,
      isAdGroupNameUniqueWithinCampaign
    );

    const isAdGroupArchived = getIsAdGroupArchived(
      adGroupDetails,
      channelSettings.status
    );

    const isRowSelected = selectSettings.rows.includes(data.adGroupId);

    const generateValidateUniqueRequest = (
      newAdGroupName: string
    ): ValidateUniqueValuesRequest => {
      let merchantCountryId: string = getMerchantCountryId(
        selectedMerchantCountries
      );

      return {
        column: ADGROUPS_API_COLUMN_NAME.AdGroupName,
        entityType: EntityType.AdGroup,
        value: newAdGroupName,
        merchantCountryId,
        parentEntityId: adGroupDetails.campaignId,
      };
    };

    const onAdGroupNameChange = (newValue: string) => {
      const trimmedValue = newValue.trim();
      setValue(newValue);
      debouncedUpdateCellValue(trimmedValue);
      const request = generateValidateUniqueRequest(trimmedValue);

      // don't make an API call with the original value
      if (adGroupDetails.adGroupName === trimmedValue) {
        setIsAdGroupNameUniqueWithinCampaign(true);
        invokeCallback({
          rowId: adGroupId,
          isNameUnique: true,
        });
      } else {
        debouncedIsAdGroupNameUnique(request);
      }
    };

    const onAdGroupNameClick = () =>
      dispatch(
        tableActions.toggleSelectedItem({
          tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
          rowId: data.adGroupId,
        })
      );

    const onInputFocus = () => setFocussed(true);
    const onInputBlur = () => setFocussed(false);

    const showTooltip = getshowTooltip(focussed, isInputInvalid);

    const adGroupNameChecboxWithTextInputProps: CheckboxWithTextInputProps &
      TypedPortaledTooltipProps = {
      value,
      checked: getChecked(isRowSelected),
      inputState: getInputState(isInputInvalid),
      onClick: onAdGroupNameClick,
      onChange: onAdGroupNameChange,
      onFocus: onInputFocus,
      onBlur: onInputBlur,
      isDirty: value !== existingValue,
      tooltipContent: getTooltipContent(
        intl,
        isAdGroupArchived,
        showTooltip,
        value,
        salesChannel,
        isAdGroupNameDuplicate || !isAdGroupNameUniqueWithinCampaign,
        isTextEclipsed,
        isSBA
      ),
      tooltipDisabled: getIsTooltipDisabled(
        isAdGroupArchived,
        showTooltip,
        isTextEclipsed
      ),
      overwrittenTooltipClassnames: 'w-full',
      tooltipTheme: PortaledTooltipTheme.Dark,
      portaledTooltipPlacement: PortaledTooltipPlacement.Right,
      disabled: getIsColumnEditDisabled(adGroupDetails, channelSettings.status),
      state: getState(adGroupDetails, channelSettings.status),
      dataTestId: 'adgroup_name',
      appendLeftElement: representSmartCampaign(
        advertisingGoal,
        adGroupDetails.campaignTargetingType || CampaignTargetingType.Manual,
        selectedAdType,
        showSmartCampaign,
        true
      ),
    };

    const isSponsoredDisplayAndVendor = getIsSponsoredDisplayAndVendor(
      selectedAdType,
      merchantType
    );

    if (isSponsoredDisplayAndVendor) {
      const checkboxLabel: CheckboxLabelProps = {
        label: data.adGroupDetails?.adGroupName,
        checked: getChecked(isRowSelected),
        onClick: onAdGroupNameClick,
        state: getState(adGroupDetails, channelSettings.status),
        internalUrl: getPageUrl(data),
      };
      return makeCheckboxLabelColumn<AdGroupDetails>(() => checkboxLabel)(data);
    }
    return makeCheckboxWithTextInputColumn(() => {
      return { ...adGroupNameChecboxWithTextInputProps, textInputref };
    })(data);
  }

  const selectedMerchantCountryId = getMerchantCountryId(
    selectedMerchantCountries
  );

  return makeTruncatedTextLinkColumn<AdGroupDetails>(
    (details: AdGroupDetails) => details.adGroupDetails?.adGroupName ?? '',
    getPageUrl,
    pendingFields?.includes(ADGROUPS_API_COLUMN_NAME.AdGroupName),
    <ViewTrendLink
      dataTestId="ad_groups_ad_group_name"
      campaignId={adGroupDetails.campaignId}
      merchantCountryId={selectedMerchantCountryId}
      entityId={adGroupId}
      entityType={ViewTrendsEntityType.ADGROUP}
      adType={first(adTypes)}
      merchantType={merchantType}
      campaignName={adGroupDetails.campaignName}
      entityName={adGroupDetails.adGroupName}
      merchantCountryName={
        allMerchants.find(
          (merchant) => merchant.merchantCountryId === selectedMerchantCountryId
        )?.merchantName
      }
      salesChannelId={
        salesChannel === FlywheelSalesChannel.Amazon
          ? AMAZON_SALES_CHANNEL_ID
          : WALMART_SALES_CHANNEL_ID
      }
      targetingType={adGroupDetails.campaignTargetingType}
    />,
    true,
    representSmartCampaign(
      advertisingGoal,
      adGroupDetails.campaignTargetingType || CampaignTargetingType.Manual,
      selectedAdType,
      showSmartCampaign,
      true
    )
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

const tableChangeSelector = ({ tableState }: WithTable<AdGroupDetails>) =>
  tableSelectors.getChangeSelector()(tableState, ADS_MANAGER_ADGROUP_TABLE_ID);

export const getMaxBid = (
  tableChange: TableChange,
  updatedAdGroupId: string,
  adGroupData: AdGroupDetails
) => {
  return Number(
    tableChange.cell[updatedAdGroupId]?.maxBid ??
      adGroupData.flywheelSettings.maxBid?.amount
  );
};

export const getMinBid = (
  tableChange: TableChange,
  updatedAdGroupId: string,
  adGroupData: AdGroupDetails
) => {
  return Number(
    tableChange.cell[updatedAdGroupId]?.minBid ??
      adGroupData.flywheelSettings.minBid?.amount
  );
};

export const getAdgroupDefaultBid = (
  tableChange: TableChange,
  updatedAdGroupId: string,
  adGroupData: AdGroupDetails
) => {
  return Number(
    tableChange.cell[updatedAdGroupId]?.adGroupDefaultBid ??
      adGroupData.channelSettings.defaultBid?.amount
  );
};

export const isTableChangeSelectRows = (tableChange: TableChange) => {
  return tableChange.select.rows.length > 0;
};

export const getCampaignNameHeader = (
  isEditMode: boolean,
  selectedCount: number,
  getbulkEditMenu: JSX.Element,
  getBulkEditModal?: JSX.Element
) => {
  return isEditMode && selectedCount ? (
    <div className="absolute -bottom-8 -left-12 bg-white">
      {getbulkEditMenu}
      {getBulkEditModal}
    </div>
  ) : (
    <></>
  );
};

export const getCheckboxCheckedState = (isAllRowsSelected: boolean) =>
  isAllRowsSelected ? CheckboxCheckedState.Checked : CheckboxCheckedState.Some;

export const isFlywheelSettingsColumn = (column: string | null) => {
  return (
    column === ADGROUPS_API_COLUMN_NAME.MinBid ||
    column === ADGROUPS_API_COLUMN_NAME.MaxBid ||
    column === ADGROUPS_API_COLUMN_NAME.MACSTarget ||
    column === ADGROUPS_API_COLUMN_NAME.BidAutomationStatus
  );
};

export const adgroupNameColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.AdGroupName,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME,
  isSortable: true,
  RowCellElement,
  className: 'cursor-pointer',
  gridColumnWidth: '360px',
};

export const adGroupNameFilter = createStringDataFieldFilter(
  ADGROUPS_API_COLUMN_NAME.AdGroupName,
  I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME,
  isValidString()
);
