import { Money } from './Money';

export interface ZuoraSignature {
  readonly token: string;
  readonly tenantId: string;
  readonly signature: string;
  readonly key: string;
  readonly success: boolean;
}

export interface AccountStatusResponse {
  id: string;
  hasPayment: boolean;
  isDelinquent: boolean;
  isFreemium: boolean;
  nextBillingDate: string;
  createdAt: string;
  delinquentSince: string | null;
  paymentPlanType?: PaymentPlanType;
  billingAnchor: string | null;
  currentPlatformSubscription?: PlatformSubscriptionType | null;
  upcomingPlatformSubscription?: PlatformSubscriptionType | null;
  nextPaymentDate?: string | null;
}

export enum PaymentPlanType {
  SelfService = 'SELF_SERVICE',
  Managed = 'MANAGED',
  LegacySelfService = 'LEGACY_SELF_SERVICE',
  Agency = 'AGENCY',
  AgencyClient = 'AGENCY_CLIENT',
}

export interface BillingInfoResponse {
  zuoraAccountId: string;
  zuoraAccountNumber: string;
  zuoraBillingInfo: PaymentInfo;
}

export interface BillingBalanceResponse {
  startDate: string;
  endDate: string;
  balance: Money;
}

export type BillingInvoicesResponse = {
  invoices: BillingInvoicesType[];
};

export type BillingInvoicesType = {
  invoiceDate: string;
  invoiceId: string;
  pdfUrlPath: string;
  total: number;
  status: string;
};

export interface PaymentInfo {
  cardNumber: string;
  expDate: string;
  zuoraCardType?: string;
  cardType: CreditCardType;
  country: string;
  state: string;
}

export enum CreditCardType {
  Amex = 'amex',
  Barclays = 'barclays',
  Citi = 'citi',
  Diners = 'diners',
  Discover = 'discover',
  Jcb = 'jcb',
  Mastercard = 'mastercard',
  Visa = 'visa',
  Generic = 'generic',
}

export interface BillingEstimateDetailsMerchantCountryAds {
  SponsoredBrands?: Money;
  SponsoredProducts?: Money;
  SponsoredDisplay?: Money;
}

export interface BillingEstimateDetailsResponseType {
  ads: Record<string, BillingEstimateDetailsMerchantCountryAds>;
  startDate: string;
  endDate: string;
  gms: Record<string, Money>;
}

export enum BillingRateType {
  Percentage = 'Percentage',
  TieredFlat1 = 'TieredFlat1',
  TieredFlat2 = 'TieredFlat2',
  TieredFlatPlusVar = 'TieredFlatPlusVar',
  NoAdSpend = 'NoAdSpend',
}

export interface ItemizedEstimate {
  readonly amount: Money;
  readonly quantity: Money | number;
  readonly rate: string;
}
export interface BillingEstimateResponseType {
  adSpend: BillingAdSpend;
  endDate: string;
  gms?: Money;
  isFreemium: boolean;
  startDate: string;
  terms: BillingTerms;
  totalBill: Money;
  billingRateType: BillingRateType;
  combinedAdSpend: ItemizedEstimate;
}

export interface BillingAdSpend {
  readonly amazonAdSpend: ItemizedEstimate;
  readonly walmartAdSpend: ItemizedEstimate;
}

export interface BillingTerms {
  readonly amazonTerms?: ItemizedEstimate;
  readonly walmartTerms?: ItemizedEstimate;
  readonly freeTerms?: ItemizedEstimate;
  readonly freeTermsAmazon?: ItemizedEstimate;
  readonly freeTermsWalmart?: ItemizedEstimate;
}

export enum PlatformSubscriptionType {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
}
