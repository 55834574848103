import { Factory } from 'fishery';

import { ProductAdStatusValue } from '../../../modules/advertisingOptimization/containers/adsManager/utils';
import {
  AdGroupReviewStatusType,
  AdGroupStatus,
  AddedBy,
  CampaignCostType,
  CampaignStatus,
  CampaignTactic,
  CampaignTargetingType,
  ProductAdDetails,
  ProductReviewStatusType,
} from '../../types/AOSharedTypes';

export interface Response {
  readonly elements: ProductAdDetails[];
  readonly filteredElements: number;
}

export default Factory.define<Response>(() => ({
  elements: [
    {
      adItemId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec17',
      adItemDetails: {
        productName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        productImage:
          'https://i5.walmartimages.com/asr/b22dcb0f-740e-4711-bddc-f74023fb917e_1.efef0a8a8680dd2a5718bbdaca1294ba.png?odnHeight=450&odnWidth=450&odnBg=ffffff',
        itemPageUrl: 'https://www.walmart.com/ip/897052455',
        addedBy: AddedBy.FlywheelAutomated,
        adGroupName: 'Party props_Manual_AdGroup',
        adGroupId: '3f193e1e-3acf-5a35-8097-7e8f237fcf63',
        adGroupStatus: AdGroupStatus.Disabled,
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: CampaignTargetingType.Auto,
        extAdItemId: '22188242',
        extItemId: '897052455',
        extAdGroupId: '305050',
        extCampaignId: '1285337',
        campaignDailyBudget: {
          amount: '100.00',
          currency: 'USD',
        },
        extItemType: 'walmart itemId',
        startDate: '2020-05-16',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.Complete,
          reviewReason: [
            {
              commentType: 'Brand',
              comments:
                'Brand name does not match with the logo w.r.t. Relevancy. For example, the Brand name states "Cuisinart Outdoor Grilling" while the logo has "Cuisinart" which has no relevancy.',
            },
          ],
          reviewComments:
            'We are rejecting a bunch of keywords for the following reasons:\na) Certain sets of keywords are irrelevant to the product/brand which is submitted (For example puyopuyo ps5,sackboy ps5, etc...)\nb) Certain set of keywords not meeting our conquesting policy guidelines (For example seagate 2tb game drive, etc...)\nPlease refer to the Guideline and Best Practices document for more information.',
        },
        productReviewStatus: {
          reviewStatus: ProductReviewStatusType.Rejected,
          reviewComments:
            'We are rejecting a bunch of keywords for the following reasons:\na) Certain sets of keywords are irrelevant to the product/brand which is submitted (For example puyopuyo ps5,sackboy ps5, etc...)\nb) Certain set of keywords not meeting our conquesting policy guidelines (For example seagate 2tb game drive, etc...)\nPlease refer to the Guideline and Best Practices document for more information.',
        },
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignStatus: CampaignStatus.Pending,
        campaignType: CampaignTargetingType.Auto,
        campaignCostType: CampaignCostType.CPC,
        campaignTactic: CampaignTactic.Product,
        campaignPortfolioName: 'Portfolio 1',
        campaignTotalBudget: { amount: '10.89', currency: 'USD' },
      },
      channelSettings: {
        status: ProductAdStatusValue.Enabled,
        bid: { amount: '10', currency: 'USD' },
      },
      adItemPerformance: {
        adSalesTotal: {
          amount: '389.70',
          currency: 'USD',
        },
        adSpend: {
          amount: '167.94',
          currency: 'USD',
        },
        unitsSold: 30,
        roasTotal: 2.320471596998928,
        acosTotal: 0.4309468822170901,
        conversionRate: 0.056925996204933584,
        clicks: 527,
        impressions: 45381,
        adConversions: 12,
        avgCostPerClick: {
          amount: '10.00',
          currency: 'USD',
        },
        clickThroughRate: 0.4,
        adSalesDirect: {
          amount: '389.70',
          currency: 'USD',
        },
        acosDirect: 0.43,
        roasDirect: 30,
        relatedClickRevenue: {
          amount: '20.00',
          currency: 'USD',
        },
        brandClickRevenue: {
          amount: '20.00',
          currency: 'USD',
        },
        newToBrandOrders: 10,
        newToBrandSales: {
          amount: '20.00',
          currency: 'USD',
        },
        newToBrandAdUnitsSold: 10,
        advertisedSkuUnits: 10,
        otherSkuUnits: 10,
      },
      pendingFields: [],
    },
    {
      adItemId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec18',
      adItemDetails: {
        productName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        productImage:
          'https://i5.walmartimages.com/asr/b22dcb0f-740e-4711-bddc-f74023fb917e_1.efef0a8a8680dd2a5718bbdaca1294ba.png?odnHeight=450&odnWidth=450&odnBg=ffffff',
        itemPageUrl: 'https://www.walmart.com/ip/897052455',
        adGroupName: 'Party props_Manual_AdGroup',
        adGroupId: '3f193e1e-3acf-5a35-8097-7e8f237fcf63',
        adGroupStatus: AdGroupStatus.Disabled,
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: CampaignTargetingType.Auto,
        extAdItemId: '22188242',
        extItemId: '897052455',
        extAdGroupId: '305050',
        extCampaignId: '1285337',
        campaignDailyBudget: {
          amount: '100.00',
          currency: 'USD',
        },
        extItemType: 'walmart itemId',
        startDate: '2020-05-16',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.Pending,
        },
        productReviewStatus: {
          reviewStatus: ProductReviewStatusType.Pending,
        },
        adItemReviewStatus: {
          reviewStatus: ProductReviewStatusType.Rejected,
          reviewComments:
            'We are rejecting a bunch of keywords for the following reasons:\na) Certain sets of keywords are irrelevant to the product/brand which is submitted (For example puyopuyo ps5,sackboy ps5, etc...)\nb) Certain set of keywords not meeting our conquesting policy guidelines (For example seagate 2tb game drive, etc...)\nPlease refer to the Guideline and Best Practices document for more information.',
        },
        merchantCountryId: '32869590-a45e-4041-bd2b-538844a1781b',
        campaignStatus: undefined,
        campaignType: CampaignTargetingType.Auto,
      },
      channelSettings: {
        status: ProductAdStatusValue.Disabled,
        bid: undefined,
      },
      adItemPerformance: {
        unitsSold: 0,
        roasTotal: undefined,
        clicks: 0,
        impressions: 0,
        adConversions: 0,
      },
      pendingFields: [],
    },
    {
      adItemId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec19',
      adItemDetails: {
        productName: '',
        productImage:
          'https://i5.walmartimages.com/asr/b22dcb0f-740e-4711-bddc-f74023fb917e_1.efef0a8a8680dd2a5718bbdaca1294ba.png?odnHeight=450&odnWidth=450&odnBg=ffffff',
        itemPageUrl: 'https://www.walmart.com/ip/897052455',
        adGroupName: '',
        adGroupId: '3f193e1e-3acf-5a35-8097-7e8f237fcf63',
        adGroupStatus: AdGroupStatus.Disabled,
        campaignName: '',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: CampaignTargetingType.Manual,
        extAdItemId: '22188242',
        extItemId: '897052455',
        extAdGroupId: '305050',
        extCampaignId: '1285337',
        campaignDailyBudget: undefined,
        extItemType: 'walmart itemId',
        startDate: '2020-05-16',
        adGroupReviewStatus: undefined,
        productReviewStatus: {
          reviewStatus: ProductReviewStatusType.Approved,
        },
        adItemReviewStatus: {
          reviewStatus: ProductReviewStatusType.Approved,
        },
        merchantCountryId: '02869590-a45e-4041-bd2b-538844a1781c',
        campaignStatus: CampaignStatus.Pending,
        campaignType: CampaignTargetingType.Auto,
      },
      channelSettings: {
        status: ProductAdStatusValue.Archived,
        bid: { amount: '10', currency: 'USD' },
      },
      flywheelSettings: {
        automationStatus: true,
        macsTarget: 0.2,
        minBid: {
          amount: '0.31',
          currency: 'USD',
        },
        maxBid: {
          amount: '1.20',
          currency: 'USD',
        },
      },
      adItemPerformance: {
        adSalesTotal: {
          amount: '389.70',
          currency: 'USD',
        },
        roasTotal: 2.320471596998928,
        acosTotal: 0.4309468822170901,
        conversionRate: 0.056925996204933584,
      },
      pendingFields: [],
    },
    {
      adItemId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ef28',
      adItemDetails: {
        productName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        productImage:
          'https://i5.walmartimages.com/asr/b22dcb0f-740e-4711-bddc-f74023fb917e_1.efef0a8a8680dd2a5718bbdaca1294ba.png?odnHeight=450&odnWidth=450&odnBg=ffffff',
        itemPageUrl: 'https://www.walmart.com/ip/897052455',
        adGroupName: 'Party props_Manual_AdGroup',
        adGroupId: '3f193e1e-3acf-5a35-8097-7e8f237fcf63',
        adGroupStatus: AdGroupStatus.Archived,
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690774b',
        campaignTargetingType: CampaignTargetingType.Auto,
        extAdItemId: '22188242',
        extItemId: '897052455',
        extAdGroupId: '305050',
        extCampaignId: '1285337',
        campaignDailyBudget: {
          amount: '100.00',
          currency: 'USD',
        },
        extItemType: 'walmart itemId',
        startDate: '2020-05-16',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.InProgress,
        },
        productReviewStatus: {
          reviewStatus: ProductReviewStatusType.Pending,
        },
        adItemReviewStatus: {
          reviewStatus: ProductReviewStatusType.Rejected,
          reviewComments:
            'We are rejecting a bunch of keywords for the following reasons:\na) Certain sets of keywords are irrelevant to the product/brand which is submitted (For example puyopuyo ps5,sackboy ps5, etc...)\nb) Certain set of keywords not meeting our conquesting policy guidelines (For example seagate 2tb game drive, etc...)\nPlease refer to the Guideline and Best Practices document for more information.',
        },
        merchantCountryId: '32869590-a45e-4041-bd2b-538844a1781b',
        campaignStatus: CampaignStatus.Pending,
        campaignType: CampaignTargetingType.Auto,
      },
      channelSettings: {
        status: ProductAdStatusValue.Disabled,
        bid: { amount: '10', currency: 'USD' },
      },
      adItemPerformance: {
        unitsSold: 0,
        roasTotal: undefined,
        clicks: 0,
        impressions: 0,
        adConversions: 0,
      },
      pendingFields: [],
    },
    {
      adItemId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ef48',
      adItemDetails: {
        productName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        productImage:
          'https://i5.walmartimages.com/asr/b22dcb0f-740e-4711-bddc-f74023fb917e_1.efef0a8a8680dd2a5718bbdaca1294ba.png?odnHeight=450&odnWidth=450&odnBg=ffffff',
        itemPageUrl: 'https://www.walmart.com/ip/897052455',
        adGroupName: 'Party props_Manual_AdGroup',
        adGroupId: '3f193e1e-3acf-5a35-8097-7e8f237fcfc3',
        adGroupStatus: AdGroupStatus.Disabled,
        campaignName: 'Party props and bundles_Manual',
        campaignId: '8c5e4421-a00e-5820-851a-4b6c8690772b',
        campaignTargetingType: CampaignTargetingType.Auto,
        extAdItemId: '22188242',
        extItemId: '897052455',
        extAdGroupId: '305050',
        extCampaignId: '1285337',
        campaignDailyBudget: {
          amount: '100.00',
          currency: 'USD',
        },
        extItemType: 'walmart itemId',
        startDate: '2020-05-16',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.Complete,
        },
        productReviewStatus: {
          reviewStatus: ProductReviewStatusType.Pending,
        },
        adItemReviewStatus: {
          reviewStatus: ProductReviewStatusType.Rejected,
          reviewComments:
            'We are rejecting a bunch of keywords for the following reasons:\na) Certain sets of keywords are irrelevant to the product/brand which is submitted (For example puyopuyo ps5,sackboy ps5, etc...)\nb) Certain set of keywords not meeting our conquesting policy guidelines (For example seagate 2tb game drive, etc...)\nPlease refer to the Guideline and Best Practices document for more information.',
        },
        merchantCountryId: '32869590-a45e-4041-bd2b-538844a1781b',
        campaignStatus: CampaignStatus.Archived,
        campaignType: CampaignTargetingType.Auto,
      },
      channelSettings: {
        status: ProductAdStatusValue.Disabled,
        bid: { amount: '10', currency: 'USD' },
      },
      adItemPerformance: {
        unitsSold: 0,
        roasTotal: undefined,
        clicks: 0,
        impressions: 0,
        adConversions: 0,
      },
      pendingFields: [],
    },
  ],
  filteredElements: 5,
}));
