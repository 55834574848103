import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavPaths } from '../../../../../NavPaths';
import { LevelType } from '../../../../../core/campaignFlexibility/types';
import {
  AdType,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import { MerchantType } from '../../../../../lib/types/Fam';
import { buildUrlWithQueryParams } from '../../../../../lib/utilities/buildUrlUtilities';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../lib/utilities/intercom';
import {
  ADS_MANAGER_AD_LEVEL_PAGES,
  CampaignTargetingType,
} from '../../../containers/adsManager/types';

const AMAZON_URL_MAP: Record<string, string> = {
  [AdType.SponsoredProducts]: NavPaths.AoAmazonSponsoredProductsTarget,
  [AdType.SponsoredBrands]: NavPaths.AoAmazonSponsoredBrandsKeywordTargets,
  [AdType.SponsoredDisplay]: NavPaths.AoAmazonSponsoredDisplayTargets,
};

const WALMART_URL_MAP_KEYWORDS: Record<string, string> = {
  [AdType.SponsoredProducts]: NavPaths.AoWalmartSponsoredProductsTarget,
  [AdType.SearchBrandAmplifier]:
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSearchBrandAmplifierKeywordTargets,
  [AdType.SponsoredVideos]:
    ADS_MANAGER_AD_LEVEL_PAGES.WalmartSponsoredVideosKeywordTargets,
};

const WALMART_URL_MAP_SKUs: Record<string, string> = {
  [AdType.SponsoredProducts]: NavPaths.AoWalmartSponsoredProductsProductAd,
  [AdType.SearchBrandAmplifier]: NavPaths.AoWalmartSponsoredBrandsProductAd,
};

const AMAZON_PRODUCT_URL_MAP: Record<string, string> = {
  [AdType.SponsoredProducts]: NavPaths.AoAmazonSponsoredProductsProductAds,
  [AdType.SponsoredBrands]: NavPaths.AoAmazonSponsoredBrandsProductsTarget,
  [AdType.SponsoredDisplay]: NavPaths.AoAmazonSponsoredDisplayProductAds,
};

export interface UpdateContext {
  merchantCountryId?: string;
  merchantName?: string;
  campaignId?: string;
  campaignName?: string;
  targetingType?: CampaignTargetingType;
  isViewTrendsOpen?: boolean;
  entityId?: string;
  entityType?: string;
  entityName?: string;
  adType?: string;
  merchantType?: MerchantType;
  isSKUSlideoverOpen?: boolean;
  targetSegments?: string[];
  channelId?: string;
}

export interface ViewTrendsState {
  readonly campaignId?: string;
  readonly campaignName?: string;
  readonly targetingType?: CampaignTargetingType;
  readonly merchantCountryId?: string;
  readonly merchantName?: string;
  readonly updateContext?: (props: UpdateContext) => void;
  readonly isViewTrendsOpen?: boolean;
  readonly entityType?: string;
  readonly entityId?: string;
  readonly entityName?: string;
  readonly adType?: string;
  readonly merchantType?: MerchantType;
  readonly isSKUSlideoverOpen?: boolean;
  readonly toggleSkuSlideover?: () => void;
  readonly onTextLinkClick?: (
    salesChannel: FlywheelSalesChannel,
    adTypeDropDownValue: AdType,
    currentLevel: LevelType
  ) => () => void;
  readonly targetSegments?: string[];
  readonly channelId?: string;
  readonly closeViewTrends?: () => void;
}

export const ViewTrendsContext = createContext<ViewTrendsState>({});

const { Provider } = ViewTrendsContext;

export const ViewTrendsContextProvider: React.FC<PropsWithChildren> = (
  props
) => {
  const [state, setState] = useState<ViewTrendsState>({
    isSKUSlideoverOpen: false,
  });
  const updateContext = ({
    merchantCountryId,
    merchantName,
    campaignId,
    campaignName,
    targetingType,
    isViewTrendsOpen,
    entityId,
    entityType,
    entityName,
    adType,
    merchantType,
    isSKUSlideoverOpen,
    targetSegments,
    channelId,
  }: UpdateContext) => {
    setState({
      merchantCountryId,
      merchantName,
      campaignId,
      campaignName,
      targetingType,
      isViewTrendsOpen,
      entityId,
      entityType,
      entityName,
      adType,
      merchantType,
      isSKUSlideoverOpen,
      targetSegments,
      channelId,
    });
  };

  useEffect(() => {
    if (state?.isViewTrendsOpen || state?.isSKUSlideoverOpen) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
  }, [state?.isViewTrendsOpen, state?.isSKUSlideoverOpen]);

  const toggleSkuSlideover = () => {
    setState({
      ...state,
      isSKUSlideoverOpen: !state?.isSKUSlideoverOpen,
    });
  };

  const navigate = useNavigate();

  const onTextLinkClick =
    (
      salesChannel: FlywheelSalesChannel,
      adTypeDropDownValue: AdType,
      currentLevel: LevelType
    ) =>
    () => {
      const basePath = () => {
        if (salesChannel === FlywheelSalesChannel.Amazon) {
          if (
            currentLevel === LevelType.SKUs &&
            (adTypeDropDownValue === AdType.SponsoredBrands ||
              adTypeDropDownValue === AdType.SponsoredProducts)
          ) {
            return AMAZON_PRODUCT_URL_MAP[adTypeDropDownValue];
          }
          return AMAZON_URL_MAP[adTypeDropDownValue];
        } else {
          if (currentLevel === LevelType.SKUs) {
            return WALMART_URL_MAP_SKUs[adTypeDropDownValue];
          }
          return WALMART_URL_MAP_KEYWORDS[adTypeDropDownValue];
        }
      };
      setState({
        ...state,
        isViewTrendsOpen: false,
        isSKUSlideoverOpen: false,
      });
      if (basePath && state.campaignName) {
        const url = buildUrlWithQueryParams(basePath(), {
          campaignName: state.campaignName,
          merchantCountryIds: state.merchantCountryId as string,
          salesChannelId: state.channelId as string,
        });
        setTimeout(() => {
          navigate(url);
        }, 0);
      }
    };

  const closeViewTrends = () => {
    setState({
      ...state,
      isViewTrendsOpen: false,
      isSKUSlideoverOpen: false,
    });
  };

  return (
    <Provider
      value={{
        ...state,
        updateContext,
        toggleSkuSlideover,
        onTextLinkClick,
        closeViewTrends,
      }}
    >
      {props.children}
    </Provider>
  );
};
