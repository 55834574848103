import { Fw2LocalStorage } from '../../../../../lib/utilities/fw2LocalStorage';
import { UserInfo } from '../../../../products/containers/utils/localStorage';
import { Fw2SessionStorage } from '../../../../../lib/utilities/fw2SessionStorage';

export const getLocalStorageKeyNameForCustomInsightsRateOfSaleColumnManagerConfig =
  ({ userId, accountId }: UserInfo) =>
    `${userId}_${accountId}_custom_insights_rate_of_sale_column_manager_config`;

export const getCustomInsightsRateOfSaleColumnManagerConfigFromLocalStorage = ({
  userId,
  accountId,
}: UserInfo): string[] => {
  try {
    const key =
      getLocalStorageKeyNameForCustomInsightsRateOfSaleColumnManagerConfig({
        userId,
        accountId,
      });
    const selectedColumns = Fw2LocalStorage.getItem(key) || JSON.stringify([]);
    const requiredSelectedColumns = JSON.parse(selectedColumns);
    return requiredSelectedColumns;
  } catch (error) {
    return [];
  }
};

export const hasKeyCustomInsightsRateOfSaleColumnManagerConfigFromLocalStorage =
  ({ userId, accountId }: UserInfo): boolean => {
    try {
      const key =
        getLocalStorageKeyNameForCustomInsightsRateOfSaleColumnManagerConfig({
          userId,
          accountId,
        });
      const selectedColumns = Fw2LocalStorage.getItem(key);
      return !!selectedColumns;
    } catch (error) {
      return false;
    }
  };

export const storeSelectedColumnsCustomInsightsRateOfSaleToLocalStorage = ({
  userId,
  accountId,
  values,
}: UserInfo &
  Readonly<{
    values: string[];
  }>) => {
  const key =
    getLocalStorageKeyNameForCustomInsightsRateOfSaleColumnManagerConfig({
      userId,
      accountId,
    });
  Fw2LocalStorage.setItem(key, JSON.stringify(values));
};

export const getSessionStorageKeyNameForCreateCustomInsightsRateOfSaleColumnManagerConfig =
  ({ userId, accountId }: UserInfo) =>
    `${userId}_${accountId}_create_custom_insights_rate_of_sale_column_manager_config`;

export const storeColumnsForCreateCustomInsightsRateOfSaleToSessionStorage = ({
  userId,
  accountId,
  values,
}: UserInfo &
  Readonly<{
    values: string[];
  }>) => {
  const key =
    getSessionStorageKeyNameForCreateCustomInsightsRateOfSaleColumnManagerConfig(
      {
        userId,
        accountId,
      }
    );
  Fw2SessionStorage.setItem(key, JSON.stringify(values));
};

export const getColumnsForCreateCustomInsightsRateOfSaleFromSessionStorage = ({
  userId,
  accountId,
}: UserInfo): string[] => {
  try {
    const key =
      getSessionStorageKeyNameForCreateCustomInsightsRateOfSaleColumnManagerConfig(
        {
          userId,
          accountId,
        }
      );
    const selectedColumns =
      Fw2SessionStorage.getItem(key) || JSON.stringify([]);
    const requiredSelectedColumns = JSON.parse(selectedColumns);
    return requiredSelectedColumns;
  } catch (error) {
    return [];
  }
};

export const hasKeyCreateCustomInsightsRateOfSaleColumnManagerConfigFromSessionStorage =
  ({ userId, accountId }: UserInfo): boolean => {
    try {
      const key =
        getSessionStorageKeyNameForCreateCustomInsightsRateOfSaleColumnManagerConfig(
          {
            userId,
            accountId,
          }
        );
      const selectedColumns = Fw2SessionStorage.getItem(key);
      return !!selectedColumns;
    } catch (error) {
      return false;
    }
  };

export const clearColumnsForCreateCustomInsightsRateOfSaleToSessionStorage = ({
  userId,
  accountId,
}: UserInfo) => {
  const key =
    getSessionStorageKeyNameForCreateCustomInsightsRateOfSaleColumnManagerConfig(
      {
        userId,
        accountId,
      }
    );
  Fw2SessionStorage.removeItem(key);
};
