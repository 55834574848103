import {
  DropDownType,
  KeywordActionColumns,
  KeywordRecommendationsData,
} from '../../../../../lib/types/KeywordAction';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import {
  ColumnAlign,
  SelectOptionProps,
  SelectState,
  TableCellV2,
  TableCellV2Type,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../../../lib/types/I18nKey';
import capitalize from 'lodash/capitalize';
import { useIntl } from 'react-intl';
import { RecommendationsTableV2ExtraProps } from '../types';
import { capitalizeFirstLetter, splitByCapitalLetter } from './utils';
import { MatchType } from '../../../../../lib/types/AOSharedTypes';

export const getNegativeMatchTypeOptions = (): SelectOptionProps<string>[] => [
  {
    label: capitalize(splitByCapitalLetter(MatchType.NegativePhrase)),
    value: capitalizeFirstLetter(MatchType.NegativePhrase),
  },
  {
    label: capitalize(splitByCapitalLetter(MatchType.NegativeExact)),
    value: capitalizeFirstLetter(MatchType.NegativeExact),
  },
];

export const Cell: React.FC<{
  original: KeywordRecommendationsData;
  meta: RecommendationsTableV2ExtraProps;
}> = ({ original, meta }) => {
  const intl = useIntl();

  const option = getNegativeMatchTypeOptions();

  const onDropdownSelect = (value: MatchType) => {
    meta.handleDropdownSelection(original, DropDownType.MATCH_TYPE, value);
  };

  return (
    <TableCellV2
      tableCellData={{
        tableCellType: TableCellV2Type.Select,
        state: SelectState.Default,
        placeholder: intl.formatMessage({
          id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_MATCH_TYPE_HEADER,
        }),
        options: option,
        value:
          meta?.selectedRows[original.id]?.matchType ||
          original.keyword?.suggestedMatchType ||
          capitalizeFirstLetter(MatchType.NegativeExact),
        onChange: (value: MatchType) => onDropdownSelect(value),
      }}
      className="h-full flex justify-center"
    />
  );
};
Cell.displayName = 'KWACellMatchType';

export const negativeMatchType: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  RecommendationsTableV2ExtraProps,
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_MATCH_TYPE_HEADER,
  accessorKey: KeywordActionColumns.MATCH_TYPE,
  sortable: true,
  size: 183,
  minSize: 183,
  align: ColumnAlign.Left,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell original={original} meta={meta} />,
};

export const negativeMatchTypeFilter = createMultiSelectDataFieldFilter(
  KeywordActionColumns.MATCH_TYPE,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_MATCH_TYPE_HEADER,
  [
    {
      value: MatchType.NegativePhrase,
      displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_PHRASE,
    },
    {
      value: MatchType.NegativeExact,
      displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_EXACT,
    },
  ]
);
