import {
  Button,
  ButtonSize,
  ButtonVariant,
  LinkBrokenIcon,
  SelectControlled,
  SelectMenuVariant,
  SelectState,
  SelectVisibility,
  TogglePillGroup,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { buildUrlWithQueryParams } from '../../../../lib/utilities/buildUrlUtilities';
import { CurrencyCode } from '../../../../lib/utilities/currency';
import capitalize from 'lodash/capitalize';
import first from 'lodash/first';
import last from 'lodash/last';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ViewSkuAndKeywordSlideOutV2 } from '../../../../components/ViewSkuAndKeywordSlideoutV2';
import { MerchantCountriesContext } from '../../../../containers/merchantCountriesProvider/merchantCountriesProvider';
import { SubscriptionContext } from '../../../../containers/subscriptionProvider';
import { LiteTable } from '../../../../containers/tableV2/liteTable';
import { LevelType } from '../../../../core/campaignFlexibility/types';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { CompassApiClient } from '../../../../lib/clients/CompassApiClient';
import { SKUApiClient } from '../../../../lib/clients/SKUApiClient';
import { AdType } from '../../../../lib/types/AOSharedTypes';
import {
  FlywheelSalesChannel,
  MerchantCountry,
  MerchantCountryEvent,
  MerchantType,
  SubscriptionActionType,
} from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { TargetingType } from '../../../../lib/types/KeywordAction';
import { AMAZON_SALES_CHANNEL_ID } from '../../../../lib/types/SalesChannels';
import { SortOrder } from '../../../../lib/types/Sort';
import { NavPaths } from '../../../../NavPaths';
import { ViewTrends } from '../../../advertisingOptimization/components/ViewTrends';
import { ViewTrendsContext } from '../../../advertisingOptimization/components/ViewTrends/ViewTrendsProvider';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER,
  CAMPAIGN_LIVE_FILTER,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
  SALES_CHANNEL_NAME,
  SORT_BY,
  SORT_ORDER,
} from '../../../advertisingOptimization/containers/adsManager/types';
import { SelectedTrendsProduct } from '../../../products/containers/skuCatalog';
import { PerformanceSlideover } from '../../../products/containers/skuCatalog/performanceSlideover';
import { PerformanceSlideoverContextProvider } from '../../../products/containers/skuCatalog/performanceSlideover/performanceSlideoverContextProvider';
import { getProductCatalogNavPath } from '../../../products/containers/utils';
import { COMPASS_API_REQUEST_DATE_FORMAT, DateRange } from '../../types';
import { BusinessPerformanceLoadingState } from './businessPerformaceLoadingState';
import { getI18nKeyForEmptyState } from './utils/business';
import { BUSINESS_PERFORMANCE_MAX_ROWS_COUNT } from './utils/constants';
import {
  BUSINESS_PERFORMANCE_TABLE_ID,
  BusinessPerformanceData,
  CampaignMetrics,
  CampaignRowData,
  MerchantMetrics,
  MerchantRowData,
  PerformanceTableExtraProps,
  ProductRowData,
  RankedBy,
  SkuMetrics,
  VIEW_LEVEL_COLUMN_I18NKEY_MAP,
  VIEW_LEVEL_COLUMN_MAP,
  VIEW_LEVEL_PERFORMANCE_FIELDS_MAP,
  ViewLevel,
  getColumns,
  getNewSelectVisibility,
  getRankedByOptions,
  getSalesChannelFilters,
  getSkuCatalogData,
  getViewOptions,
} from './utils/performance';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { CompassQueryKeys } from '../../../../lib/types/ReactQueryKeys';

export interface BusinessPerformanceTableProps {
  readonly selectedDateRange: DateRange;
  readonly selectedCurrency: string;
  readonly merchants: MerchantCountry[];
  readonly merchantCountryEvents: MerchantCountryEvent[];
  readonly compassApiClient: CompassApiClient;
  readonly skuApiClient: SKUApiClient;
  readonly accountId: string;
  readonly eventsLoading: boolean;
  readonly aoApiClient?: AOApiClient;
  readonly selectedMerchantIds?: string[];
}

type BusinessPerformanceDataType = (
  | MerchantRowData
  | CampaignRowData
  | ProductRowData
)[];

const BusinessPerformanceTable: React.FC<BusinessPerformanceTableProps> = ({
  selectedDateRange,
  selectedCurrency,
  merchants = [],
  merchantCountryEvents,
  compassApiClient,
  skuApiClient,
  accountId,
  eventsLoading,
  aoApiClient,
  selectedMerchantIds = [],
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [viewDropdownVisibility, setViewDropdownVisibility] =
    useState<SelectVisibility>(SelectVisibility.Closed);

  const [rankedByDropdownVisibility, setRankedByDropdownVisibility] =
    useState<SelectVisibility>(SelectVisibility.Closed);

  const [viewLevel, setViewLevel] = useState<ViewLevel | undefined>(undefined);
  const [rankedBy, setRankedBy] = useState<RankedBy>(RankedBy.HighPerformers);
  const { has1PMerchants, has3PMerchants } = useContext(
    MerchantCountriesContext
  );

  const viewTrendsContext = useContext(ViewTrendsContext);

  const { salesChannelData } = useContext(SubscriptionContext);

  const [selectedSalesChannel, setSelectedSalesChannel] =
    useState<FlywheelSalesChannel | null>();
  const [apiResponse, setApiResponse] =
    useState<
      BusinessPerformanceData<MerchantMetrics | CampaignMetrics | SkuMetrics>
    >();
  const [data, setData] = useState<BusinessPerformanceDataType>([]);

  const [showTrendsSlideover, setShowTrendsSlideover] =
    useState<boolean>(false);
  const [selectedTrendsProduct, setSelectedTrendsProduct] =
    useState<SelectedTrendsProduct>();

  useEffect(() => {
    if (merchants.length === 1 && viewLevel !== ViewLevel.Products) {
      onViewLevelChange(ViewLevel.Products);
    } else {
      onViewLevelChange(ViewLevel.Merchants);
    }
  }, [merchants]);

  const {
    data: merchantPerformanceData,
    isLoading: isLoadingMerchantPerformanceData,
  } = useQuery({
    queryKey: [
      `${CompassQueryKeys.GetMerchantPerformanceData + `_${rankedBy}`}`,
      merchants,
      viewLevel,
      rankedBy,
      selectedCurrency,
      selectedDateRange,
      accountId,
    ],
    queryFn: () => {
      if (viewLevel) {
        const requestData = {
          startDate: selectedDateRange.startDate.toFormat(
            COMPASS_API_REQUEST_DATE_FORMAT
          ),
          endDate: selectedDateRange.endDate.toFormat(
            COMPASS_API_REQUEST_DATE_FORMAT
          ),
          currency: selectedCurrency as CurrencyCode,
          merchantCountries: merchants.map((merchant) => ({
            salesChannelId: merchant.salesChannelId ?? '',
            merchantCountryId: merchant.merchantCountryId ?? '',
            merchantType: merchant.merchantType ?? '',
            salesChannelName: merchant.merchantName ?? '',
          })),
          performanceFields: VIEW_LEVEL_PERFORMANCE_FIELDS_MAP[viewLevel],
        };

        return compassApiClient.getMerchantsPerformanceData(accountId)(
          requestData,
          rankedBy
        );
      }
    },
    enabled: !!(
      viewLevel &&
      viewLevel === ViewLevel.Merchants &&
      selectedDateRange &&
      merchants.length > 0
    ),
  });

  const {
    data: campaignPerformanceData,
    isLoading: isLoadingCampaignPerformanceData,
  } = useQuery({
    queryKey: [
      CompassQueryKeys.GetCampaignPerformanceData,
      merchants,
      rankedBy,
      viewLevel,
      selectedCurrency,
      selectedDateRange,
      accountId,
    ],
    queryFn: () => {
      if (viewLevel) {
        const requestData = {
          startDate: selectedDateRange.startDate.toFormat(
            COMPASS_API_REQUEST_DATE_FORMAT
          ),
          endDate: selectedDateRange.endDate.toFormat(
            COMPASS_API_REQUEST_DATE_FORMAT
          ),
          currency: selectedCurrency as CurrencyCode,
          merchantCountries: merchants.map((merchant) => ({
            salesChannelId: merchant.salesChannelId ?? '',
            merchantCountryId: merchant.merchantCountryId ?? '',
            merchantType: merchant.merchantType ?? '',
            salesChannelName: merchant.merchantName ?? '',
          })),
          performanceFields: VIEW_LEVEL_PERFORMANCE_FIELDS_MAP[viewLevel],
        };

        return compassApiClient.getCampaignsPerformanceData(accountId)(
          requestData,
          rankedBy
        );
      }
    },
    enabled: !!(
      viewLevel &&
      viewLevel === ViewLevel.Campaigns &&
      selectedDateRange &&
      merchants.length > 0
    ),
  });

  const {
    data: productPerformanceData,
    isLoading: isLoadingProductPerformanceData,
  } = useQuery({
    queryKey: [
      CompassQueryKeys.GetProductPerformanceData,
      merchants,
      rankedBy,
      viewLevel,
      selectedCurrency,
      selectedDateRange,
      accountId,
    ],
    queryFn: () => {
      if (viewLevel) {
        const requestData = {
          startDate: selectedDateRange.startDate.toFormat(
            COMPASS_API_REQUEST_DATE_FORMAT
          ),
          endDate: selectedDateRange.endDate.toFormat(
            COMPASS_API_REQUEST_DATE_FORMAT
          ),
          currency: selectedCurrency as CurrencyCode,
          merchantCountries: merchants.map((merchant) => ({
            salesChannelId: merchant.salesChannelId ?? '',
            merchantCountryId: merchant.merchantCountryId ?? '',
            merchantType: merchant.merchantType ?? '',
            salesChannelName: merchant.merchantName ?? '',
          })),
          performanceFields: VIEW_LEVEL_PERFORMANCE_FIELDS_MAP[viewLevel],
        };

        return getSkuCatalogData(
          skuApiClient,
          accountId,
          requestData,
          rankedBy,
          selectedMerchantIds
        );
      }
    },
    enabled: !!(
      viewLevel &&
      viewLevel === ViewLevel.Products &&
      selectedDateRange &&
      merchants.length > 0
    ),
  });

  const isLoading =
    eventsLoading ||
    isLoadingMerchantPerformanceData ||
    isLoadingCampaignPerformanceData ||
    isLoadingProductPerformanceData;

  useEffect(() => {
    if (!isLoading && viewLevel) {
      let tempApiResponse =
        viewLevel === ViewLevel.Merchants
          ? merchantPerformanceData
          : viewLevel === ViewLevel.Campaigns
          ? campaignPerformanceData
          : productPerformanceData;

      let filteredData: (MerchantRowData | CampaignRowData | ProductRowData)[] =
        (tempApiResponse?.elements || []).map((element, index) => {
          let merchantEvent: MerchantCountryEvent | undefined = undefined;
          if (viewLevel === ViewLevel.Merchants) {
            merchantEvent = last(
              merchantCountryEvents.filter(
                (event) =>
                  event?.merchantCountryId ===
                    (element as MerchantMetrics)?.merchantCountryDetails
                      .merchantCountryId &&
                  event.action === SubscriptionActionType.Created
              )
            );
          }

          return {
            ...element,
            flywheelAiAddedDate: merchantEvent?.date,
            rank: index + 1,
          };
        });

      if (!!selectedSalesChannel) {
        filteredData = filteredData.filter(
          (data: MerchantRowData | CampaignRowData | ProductRowData) => {
            if (viewLevel !== ViewLevel.Products) {
              return (
                (data as MerchantRowData | CampaignRowData)
                  ?.merchantCountryDetails?.salesChannelName ===
                selectedSalesChannel
              );
            } else {
              return !!(
                data as ProductRowData
              )?.skuDetailsFields?.extItemDetails?.find(
                (item) => item.salesChannelName === selectedSalesChannel
              );
            }
          }
        );
      }
      setData(filteredData.slice(0, BUSINESS_PERFORMANCE_MAX_ROWS_COUNT));
    }
  }, [
    apiResponse,
    selectedSalesChannel,
    merchantCountryEvents,
    isLoading,
    viewLevel,
    rankedBy,
  ]);

  const onViewLevelChange = (level: ViewLevel) => {
    if (level !== viewLevel) {
      setViewDropdownVisibility(SelectVisibility.Closed);
      setData([]);
      setViewLevel(level);
      setSelectedSalesChannel(null);
      setApiResponse(undefined);
    }
  };

  const onRankedByChange = (value: RankedBy) => {
    if (value !== rankedBy) {
      setRankedByDropdownVisibility(SelectVisibility.Closed);
      setRankedBy(value);
      setData([]);
      setSelectedSalesChannel(null);
      setApiResponse(undefined);
    }
  };

  const onFilterChange = (selectedIndex: number) => {
    const selectedFilter = salesChannelFilters[selectedIndex];
    setSelectedSalesChannel(selectedFilter.value || null);
  };

  const onSeeMoreClick = () => {
    if (viewLevel === ViewLevel.Campaigns) {
      const topRowData = first(data) as CampaignRowData;

      const url = buildUrlWithQueryParams(
        `${
          NavPaths.AOAdsManager
        }/${topRowData?.merchantCountryDetails?.salesChannelName?.toLowerCase()}/${
          AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER[
            topRowData?.campaignDetails?.advertisingType
          ]
        }/${ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns}`,
        {
          [SALES_CHANNEL_ID]:
            topRowData?.merchantCountryDetails?.salesChannelId ?? '',
          [MERCHANT_COUNTRY_IDS]:
            topRowData?.merchantCountryDetails?.merchantCountryId ?? '',
          [SORT_BY]: VIEW_LEVEL_COLUMN_MAP[viewLevel] ?? '',
          [SORT_ORDER]:
            rankedBy === RankedBy.LowPerformers
              ? SortOrder.Asc
              : SortOrder.Desc,
          [CAMPAIGN_LIVE_FILTER]: 'true',
          [SALES_CHANNEL_NAME]:
            topRowData?.merchantCountryDetails?.salesChannelName,
        }
      );

      navigate(url);
    } else if (viewLevel === ViewLevel.Products) {
      navigate(getProductCatalogNavPath(has1PMerchants, has3PMerchants));
    }
  };

  const salesChannelFilters = useMemo(
    () =>
      getSalesChannelFilters(
        viewLevel!,
        (apiResponse?.elements || []).slice(
          0,
          BUSINESS_PERFORMANCE_MAX_ROWS_COUNT
        ),
        isLoading
      ),
    [viewLevel, apiResponse, isLoading]
  );

  if (!viewLevel) {
    return <></>;
  }

  const extMerchantIds = merchants.map(({ extMerchantId }) => extMerchantId);

  const { titleI18nKey, descriptionI18nKey } = getI18nKeyForEmptyState(
    salesChannelData
      .filter(({ items }) =>
        items.some(({ extMerchantId }) =>
          extMerchantIds.includes(extMerchantId)
        )
      )
      .map((data) => ({
        ...data,
        items: data.items.filter(({ extMerchantId }) =>
          extMerchantIds.includes(extMerchantId)
        ),
      }))
  );

  const onViewTrendsSlideoverClick = (product: SelectedTrendsProduct) => {
    setSelectedTrendsProduct(product);
    setShowTrendsSlideover(true);
  };

  const onViewTrendsSlideoverClose = () => {
    setShowTrendsSlideover(false);
    setSelectedTrendsProduct(undefined);
  };

  return (
    <>
      {showTrendsSlideover && selectedTrendsProduct && (
        <PerformanceSlideoverContextProvider
          currency={selectedCurrency as CurrencyCode}
          defaultDateRange={selectedDateRange}
          {...selectedTrendsProduct}
        >
          <PerformanceSlideover onClose={onViewTrendsSlideoverClose} />
        </PerformanceSlideoverContextProvider>
      )}
      <div className={classNames('flex flex-col gap-20')}>
        <div className={classNames('flex flex-row justify-between')}>
          <div className={classNames('flex flex-row gap-8')}>
            <SelectControlled
              variant={SelectMenuVariant.TextWithRadioButton}
              options={getViewOptions(intl, merchants.length)}
              onChange={onViewLevelChange}
              state={isLoading ? SelectState.Disabled : SelectState.Default}
              visibility={viewDropdownVisibility}
              onToggle={() =>
                setViewDropdownVisibility(
                  (currentVisibility: SelectVisibility) =>
                    getNewSelectVisibility(currentVisibility)
                )
              }
              value={viewLevel}
              label={intl.formatMessage({ id: I18nKey.GENERIC_VIEW })}
              placeholder={''}
              dataTestId={`1_5com_view`}
              addValueToDataTestId={true}
            />
            <SelectControlled
              variant={SelectMenuVariant.TextWithRadioButton}
              options={getRankedByOptions(intl, viewLevel)}
              value={rankedBy}
              state={isLoading ? SelectState.Disabled : SelectState.Default}
              onChange={onRankedByChange}
              visibility={rankedByDropdownVisibility}
              onToggle={() =>
                setRankedByDropdownVisibility(
                  (currentVisibility: SelectVisibility) =>
                    getNewSelectVisibility(currentVisibility)
                )
              }
              placeholder={''}
              label={intl.formatMessage(
                { id: I18nKey.COMPASS_RANKED_BY },
                {
                  column: capitalize(
                    intl.formatMessage({
                      id: VIEW_LEVEL_COLUMN_I18NKEY_MAP[viewLevel],
                    })
                  ),
                }
              )}
              dataTestId={`1_5com_rank_by`}
              addValueToDataTestId={true}
            />
          </div>
          {salesChannelFilters.length > 0 && (
            <TogglePillGroup
              onPillSelect={onFilterChange}
              pills={salesChannelFilters}
              maybeDefaultPillIndex={0}
              dataTestId={'1_5com_quick_filter'}
            />
          )}
        </div>
        {!isLoading && (
          <>
            <div>
              <LiteTable<any, {}, PerformanceTableExtraProps> // any = CampaignRowData | MerchantRowData | ProductRowData
                columns={getColumns(viewLevel, intl)}
                tableId={BUSINESS_PERFORMANCE_TABLE_ID}
                currencyCode={selectedCurrency}
                tableData={{
                  ...(apiResponse?.performanceSummary || {}),
                  merchants,
                  onViewTrendsSlideoverClick,
                }}
                rowData={data}
                tableClassName="border-solid border-b-0 max-w-924"
                tableEmptyStateClass="max-w-924 p-12"
                emptyState={{
                  titleI18nKey,
                  descriptionI18nKey: intl.formatMessage(
                    { id: descriptionI18nKey },
                    { view: viewLevel }
                  ),
                  icon: LinkBrokenIcon,
                  dataTestId: '1_5com_business_performance_table_no_data',
                }}
              />
            </div>
            <div
              className={classNames('flex flex-row items-start justify-center')}
            >
              {viewLevel !== ViewLevel.Merchants &&
                (apiResponse?.elements || [])?.length >=
                  BUSINESS_PERFORMANCE_MAX_ROWS_COUNT && (
                  <Button
                    variant={ButtonVariant.BlackAndWhiteBorder}
                    size={ButtonSize.Medium}
                    onClick={onSeeMoreClick}
                    label={<FormattedMessage id={I18nKey.GENERIC_SEE_MORE} />}
                    dataTestId={`1_5com_${viewLevel}_table_see_more`}
                  />
                )}
            </div>
            {viewTrendsContext.isViewTrendsOpen && (
              <ViewTrends
                selectedDateRange={{
                  initialStartDate: selectedDateRange.startDate,
                  initialEndDate: selectedDateRange.endDate,
                  maxDate:
                    selectedDateRange.maxDate ?? selectedDateRange.endDate,
                  minDate:
                    selectedDateRange.minDate ?? selectedDateRange.startDate,
                }}
                adType={viewTrendsContext.adType as AdType}
                aoApiClient={aoApiClient}
                salesChannelId={viewTrendsContext.channelId}
              />
            )}
            {viewTrendsContext.isViewTrendsOpen &&
              viewTrendsContext.isSKUSlideoverOpen && (
                <ViewSkuAndKeywordSlideOutV2
                  adGroupId={viewTrendsContext.entityId as string}
                  merchantCountry={{
                    merchantCountryId:
                      viewTrendsContext.merchantCountryId as string,
                    merchantName: viewTrendsContext.merchantName as string,
                    country: merchants.find(
                      (merchant) =>
                        merchant.merchantCountryId ===
                        viewTrendsContext.merchantCountryId
                    )?.country as string,
                    merchantType:
                      viewTrendsContext.merchantType as MerchantType,
                    extMerchantId: merchants.find(
                      (merchant) =>
                        merchant.merchantCountryId ===
                        viewTrendsContext.merchantCountryId
                    )?.extMerchantId as string,
                    salesChannelId: viewTrendsContext.channelId,
                  }}
                  isOpen={viewTrendsContext.isSKUSlideoverOpen}
                  onClose={() =>
                    viewTrendsContext.toggleSkuSlideover &&
                    viewTrendsContext.toggleSkuSlideover()
                  }
                  adTypeDropDownValue={viewTrendsContext.adType as AdType}
                  onTextLinkClick={() => {
                    viewTrendsContext.onTextLinkClick &&
                      viewTrendsContext.onTextLinkClick(
                        viewTrendsContext.channelId === AMAZON_SALES_CHANNEL_ID
                          ? FlywheelSalesChannel.Amazon
                          : FlywheelSalesChannel.Walmart,
                        viewTrendsContext.adType as AdType,
                        LevelType.Keywords
                      )();
                  }}
                  suggestedAdgroup={{
                    name: viewTrendsContext.entityName,
                    targetSegments: viewTrendsContext.targetSegments,
                    id: viewTrendsContext.adType as AdType,
                    campaign: {
                      campaignType: viewTrendsContext.targetingType as string,
                      id: viewTrendsContext.campaignId as string,
                      name: viewTrendsContext.campaignName as string,
                      targetingType: String(
                        viewTrendsContext.targetingType
                      ) as TargetingType,
                    },
                  }}
                  textLinkContent={intl.formatMessage({
                    id: I18nKey.SKU_CATALOG_RELATED_FLYOVER_FOOTER_PRIMARY_BUTTON_TEXT,
                  })}
                  showTextLink
                />
              )}
          </>
        )}
        {isLoading && <BusinessPerformanceLoadingState />}
      </div>
    </>
  );
};
BusinessPerformanceTable.displayName = 'BusinessPerformanceTable';

export default BusinessPerformanceTable;
