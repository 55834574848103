import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Confetti,
  Modal,
  RadioButtonGroup,
  Spinner,
  TextLink,
  TextLinkSize,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../lib/types/I18nKey';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  GradientText,
  RiskFreeTrialModalCurrentStep,
} from './RiskFreeTrialModal';
import { ReactComponent as Rocket } from './rocket.svg';
import classNames from 'classnames';
import { PlatformSubscriptionType } from '../../../lib/types/Billing';

export interface BillingCadencesModalProps {
  readonly onContinueClicked?: () => void;
  readonly onPaymentSuccess?: () => void;
  readonly onCancelClicked?: () => void;
  readonly showIframe: boolean;
  readonly loadingIframe: boolean;
  readonly showErrorText: boolean;
  readonly currentStep: RiskFreeTrialModalCurrentStep;
  readonly onSetCurrentStep: (step: RiskFreeTrialModalCurrentStep) => void;
  readonly onConfettiSuccess?: () => void;
  updatingAccount: boolean;
  readonly selectedPlatformSubscription: PlatformSubscriptionType | null;
  readonly onPlatformSubscriptionTypeChange: (
    value: PlatformSubscriptionType
  ) => void;
}

export const BillingCadencesModal: React.FC<BillingCadencesModalProps> = ({
  onContinueClicked,
  onCancelClicked,
  loadingIframe,
  currentStep,
  showErrorText,
  onSetCurrentStep,
  onConfettiSuccess,
  updatingAccount,
  onPlatformSubscriptionTypeChange,
  selectedPlatformSubscription,
}) => {
  const intl = useIntl();
  const [successLoading, setSuccessLoading] = useState(false);

  const [
    TITLE,
    SUBTITLE,
    BREAKDOWN_HEADLINE,
    NO_THANKS_DESCRIPTION,
    ADD_PAYMENT_DESCRIPTION,
    CONGRATULATIONS,
    RISK_FREE_STARTED,
    GET_STARTED,
    CLOSE,
    TRY_AGAIN,
    ERROR_MESSAGE,
    DUE_TODAY,
    BILLING_OPTION_HEPER_TEXT_1,
    BILLING_OPTION_HEPER_TEXT_2,
    BILLING_OPTION_ERROR,
    BILLING_OPTION_AD_SPEND,
    BILLING_OPTION_AD_SPEND_BELOW_5K,
    BILLING_OPTION_AD_SPEND_BETWEEN_5K_10K,
    BILLING_OPTION_AD_SPEND_OVER_10K,
    BILLING_OPTION_PER_MONTH,
    BILLING_OPTION_ANNUALLY_1,
    BILLING_OPTION_ANNUALLY_2,
    BILLING_OPTION_MONTHLY,
  ] = [
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_TITLE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_SUBTITLE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BREAKDOWN_HEADLINE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_NO_THANKS_DESCRIPTION,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_ADD_PAYMENT_DESCRIPTION,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_CONGRATULATIONS_TITLE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_RISK_FREE_STARTED_TITLE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_GET_STARTED_BUTTON,
    I18nKey.CLOSE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_TRY_AGAIN_DESCRIPTION,
    I18nKey.ERROR_MESSAGE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_DUE_TODAY,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_HEPER_TEXT_1,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_HEPER_TEXT_2,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_ERROR,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_AD_SPEND,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_AD_SPEND_BELOW_5K,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_AD_SPEND_BETWEEN_5K_10K,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_AD_SPEND_OVER_10K,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_PER_MONTH,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_ANNUALLY_1,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_ANNUALLY_2,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_MONTHLY,
  ].map((id) => intl.formatMessage({ id }));

  const AD_SPEND_RANGES = [
    BILLING_OPTION_AD_SPEND_BELOW_5K,
    BILLING_OPTION_AD_SPEND_BETWEEN_5K_10K,
    BILLING_OPTION_AD_SPEND_OVER_10K,
  ];

  const pricingPlans = (
    plans: { price: string; period: string }[],
    bold: boolean
  ) => (
    <div
      className={classNames(
        'grid grid-cols-3 gap-0 min-w-260 text-center text-base  leading-tight',
        { 'font-medium': bold },
        { 'font-normal': !bold }
      )}
    >
      {plans.map((plan: { price: string; period: string }, index: number) => (
        <div
          key={index}
          className={index < 2 ? 'border-solid border-r border-grey-200' : ''}
        >
          {plan.price}
          <span className="text-xs font-normal text-grey-500">
            {plan.period}
          </span>
        </div>
      ))}
    </div>
  );

  const options = [
    {
      id: PlatformSubscriptionType.ANNUAL,
      title: (
        <div className="flex justify-between text-grey-900 w-full">
          <div className="text-base font-medium leading-normal">
            {BILLING_OPTION_ANNUALLY_1}
            <span className="text-purple-500 font-semibold">
              {BILLING_OPTION_ANNUALLY_2}
            </span>
          </div>
          {pricingPlans(
            [
              { price: '$79', period: BILLING_OPTION_PER_MONTH },
              { price: '$159', period: BILLING_OPTION_PER_MONTH },
              { price: '$399', period: BILLING_OPTION_PER_MONTH },
            ],
            true
          )}
        </div>
      ),
    },
    {
      id: PlatformSubscriptionType.MONTHLY,
      title: (
        <div className="flex justify-between text-grey-900 w-full">
          <div className="text-base font-normal leading-tight">
            {BILLING_OPTION_MONTHLY}
          </div>
          {pricingPlans(
            [
              { price: '$99', period: BILLING_OPTION_PER_MONTH },
              { price: '$199', period: BILLING_OPTION_PER_MONTH },
              { price: '$499', period: BILLING_OPTION_PER_MONTH },
            ],
            false
          )}
        </div>
      ),
    },
  ];

  const renderPlatformSubscriptionTypes = () => {
    return options.map((option) => {
      return {
        onChange: () => onPlatformSubscriptionTypeChange(option.id),
        label: option.title,
        disabled: false,
        selected: selectedPlatformSubscription === option.id,
        value: option.id,
        name: option.id,
        showBorder: true,
        borderClassName: `flex-col py-16 pl-16${
          showErrorText ? ' border-orange-500' : ''
        }`,
        dataTestId: `free_trial_modal_radiobuttongroup_${option.id}`,
        className: 'w-full items-center',
        labelClassname: 'w-full',
      };
    });
  };

  return (
    <Modal
      showModal={true}
      className="min-w-640 max-w-800 max-h-580 m-0 rounded-8 border-none"
      hidePadding={true}
      maxModalWidth="max-w-800"
      dataTestId="risk_free_trial_modal"
    >
      <>
        {currentStep === RiskFreeTrialModalCurrentStep.GET_STARTED && (
          <>
            <div className="p-32 min-w-640 min-h-580 flex flex-col gap-16">
              <Rocket />

              <div className="flex flex-col gap-4 text-2xl leading-tight font-semibold text-center">
                <GradientText>{TITLE}</GradientText>
                <div className="text-grey-900">{SUBTITLE}</div>
              </div>

              <div className="flex flex-col gap-12 bg-grey-50 p-24">
                <div className="flex justify-end text-grey-700 mr-28">
                  <div className="grid grid-cols-3 gap-0 min-w-260 text-right text-sm font-medium leading-none">
                    {AD_SPEND_RANGES.map((text, index) => (
                      <div key={index} className="flex flex-col">
                        {text}
                        <span className="font-normal">
                          {BILLING_OPTION_AD_SPEND}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                <RadioButtonGroup
                  className="flex flex-col gap-16 w-full"
                  dataTestId="free_trial_modal_radiobuttongroup"
                  radioButtons={renderPlatformSubscriptionTypes()}
                />
                {showErrorText && (
                  <div className="text-xs font-normal text-center text-red-500">
                    {BILLING_OPTION_ERROR}
                  </div>
                )}
                <div className="flex flex-col gap-4 text-grey-900 text-xs font-normal text-center">
                  <div>{BILLING_OPTION_HEPER_TEXT_1}</div>
                  <div>{BILLING_OPTION_HEPER_TEXT_2}</div>
                </div>
              </div>

              <div className="flex flex-col gap-8 font-semibold text-center">
                <GradientText className="text-l leading-tight">
                  {BREAKDOWN_HEADLINE}
                </GradientText>
                <div className="text-xl text-grey-900 leading-normal">
                  {DUE_TODAY}
                </div>
              </div>

              <div className="flex items-end justify-between">
                <TextLink
                  textLabel={NO_THANKS_DESCRIPTION}
                  size={TextLinkSize.Small}
                  onClick={onCancelClicked}
                  dataTestId="no_thanks_text_link"
                />
                <Button
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.Medium}
                  label={GET_STARTED}
                  onClick={onContinueClicked}
                  state={
                    updatingAccount ? ButtonState.Loading : ButtonState.Active
                  }
                />
              </div>
            </div>
          </>
        )}
        {currentStep === RiskFreeTrialModalCurrentStep.PAYMENT && (
          <div className="p-12 min-h-580 flex flex-col">
            <div className="text-lg leading-normal font-semibold mb-24">
              {ADD_PAYMENT_DESCRIPTION}
            </div>

            {loadingIframe && <Spinner centerAlign={true} />}
            <div
              id="zuora_payment"
              className={`fs-exclude overflow-y-scroll w-full scrollbar-thin
                  scrollbar-thumb-grey-400 scrollbar-track-grey-100
                  scrollbar-thumb-rounded-full min-h-432 max-h-560`}
            />

            {showErrorText ? (
              <div className="flex flex-row justify-between">
                <div className="font-semibold text-base mt-8">
                  {ERROR_MESSAGE}
                </div>
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Primary}
                  onClick={() =>
                    onSetCurrentStep(RiskFreeTrialModalCurrentStep.GET_STARTED)
                  }
                  className="text-grey-900"
                  label={TRY_AGAIN}
                />
              </div>
            ) : (
              <div className=" flex justify-between flex-1 items-end">
                <div>
                  <TextLink
                    textLabel={NO_THANKS_DESCRIPTION}
                    onClick={onCancelClicked}
                    className="text-sm leading-none font-normal text-grey-900"
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {currentStep === RiskFreeTrialModalCurrentStep.CONFETTI && (
          <div className="min-w-640 max-w-800 min-h-580 p-32">
            {successLoading ? (
              <Spinner centerAlign={true} />
            ) : (
              <Confetti
                onAnimationComplete={async () => {
                  if (onConfettiSuccess) {
                    setSuccessLoading(false);
                    onConfettiSuccess();
                    setSuccessLoading(true); // This is just incase the animation finished before the endpoint call is returned
                  }
                  onSetCurrentStep(RiskFreeTrialModalCurrentStep.CONGRATS);
                }}
              />
            )}
          </div>
        )}
        {currentStep === RiskFreeTrialModalCurrentStep.CONGRATS && (
          <div
            className={`flex flex-col justify-center
              min-w-640 max-w-800 h-580 p-32`}
          >
            <div
              className={`flex justify-center flex-col items-center h-412 mt-32`}
            >
              <div className="flex flex-row items-center">
                <GradientText className="text-2xl leading-normal font-semibold">
                  {CONGRATULATIONS}
                </GradientText>
                <div className="ml-4">🎉</div>
              </div>

              <div className="text-2xl leading-normal font-semibold">
                {RISK_FREE_STARTED}
              </div>
            </div>

            <div className="flex justify-end">
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.Primary}
                onClick={onCancelClicked}
                className="text-grey-900"
                label={CLOSE}
              />
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};
