import React from 'react';
import { IntlShape, useIntl } from 'react-intl';

import {
  Alignment,
  ColumnAlign,
  Icon,
  IconSize,
  InfoCircleIcon,
  MultiSelectOption,
  Placement,
  TableCellV2,
  TableCellV2Type,
  Tooltip,
  Typography,
  TypographyLineHeight,
  TypographySize,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import classNames from 'classnames';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { TableExtraProps } from '..';
import {
  FlywheelTableV2AggregationCellProps,
  FlywheelTableV2ColumnProps,
} from '../../../../../containers/tableV2/types';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  SkuCatalogData,
  SkuCatalogSummary,
  SkuViewType,
} from '../../../../../lib/types/SKUSharedTypes';
import { GlossaryColumnProps, SkuCatalogApiColumnValue } from '../types';
import { isRowChild, isRowCustomTeikaGroup, isRowParent } from '../utils';

export const Cell: React.FC<SkuCatalogData & TableExtraProps> = ({
  skuDetailsFields,
  currentMetrics,
  currentView = SkuViewType.ParantChild,
  skuChildren,
  depth = 0,
}) => {
  const intl = useIntl();

  const { skuCount = 0, advertisedSkuCount = 0 } = skuDetailsFields;
  const isAdvertised = advertisedSkuCount > 0;
  const spEligble = advertisedSkuCount !== -1;

  const isOutOfInventory: boolean =
    isNil(currentMetrics?.inventoryFields?.inventoryQuantity) ||
    currentMetrics.inventoryFields.inventoryQuantity === 0;

  return (
    <div className="flex flex-col justify-center h-full gap-4">
      <div className="flex flex-row items-center">
        <div
          className={classNames('h-8 w-8 rounded-full inline-block mr-12', {
            'bg-grey-500': !spEligble,
            'bg-green-500':
              spEligble &&
              isAdvertised &&
              (advertisedSkuCount === skuCount ||
                isRowChild(currentView, depth)),
            'bg-yellow-500':
              spEligble &&
              isAdvertised &&
              advertisedSkuCount !== skuCount &&
              !isRowChild(currentView, depth),
            'bg-orange-500': spEligble && !isAdvertised,
          })}
        ></div>
        <Typography
          className="whitespace-nowrap"
          lineHeight={TypographyLineHeight.none}
          size={TypographySize.base}
        >
          {getAdvertisedColumnText(intl, isAdvertised, advertisedSkuCount)}
        </Typography>
        {(!spEligble || isOutOfInventory) && (
          <Tooltip
            position={{
              alignment: Alignment.Center,
              placement: Placement.Bottom,
            }}
            content={intl.formatMessage({
              id: getAdvertisedInfoTooltip(!spEligble, isOutOfInventory),
            })}
            overwrittenTooltipClassnames="min-w-max"
            dataTestId={'sales_channel_sync_status_nav_item'}
          >
            <Icon
              svg={InfoCircleIcon}
              size={IconSize.Medium}
              className="text-grey-600 pl-4 pb-2"
            />
          </Tooltip>
        )}
      </div>
      <span>
        <Typography
          className="whitespace-nowrap text-grey-500 ml-20"
          lineHeight={TypographyLineHeight.none}
          size={TypographySize.base}
        >
          {intl.formatMessage(
            {
              id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED_TEXT_COUNT,
            },
            {
              showCount:
                (isRowCustomTeikaGroup(currentView, depth) ||
                  isRowParent(currentView, depth)) &&
                skuChildren &&
                skuChildren?.elements.length > 0,
              advertisedCount: advertisedSkuCount,
              totalCount: skuCount,
            }
          )}
        </Typography>
      </span>
    </div>
  );
};
Cell.displayName = 'CellAdvertised';

export const Total: React.FC<
  FlywheelTableV2AggregationCellProps<SkuCatalogSummary, SkuCatalogSummary> &
    TableExtraProps
> = ({ current, currentView }) => {
  const intl = useIntl();

  const advertisedProducts = current.advertisedSkuCount;
  const totalSkus =
    currentView === SkuViewType.IndividualSKU
      ? current.totalSkuCount
      : current.totalChildSkuCount;

  const percentage =
    totalSkus && advertisedProducts
      ? (advertisedProducts / totalSkus) * 100
      : 0;

  const currentValue = isUndefined(advertisedProducts)
    ? undefined
    : intl.formatNumber(percentage / 100, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

  return (
    <TableCellV2
      className="bg-purple-50"
      tableCellData={{
        tableCellType: TableCellV2Type.Text,
        currentValue,
      }}
    />
  );
};
Total.displayName = 'TotalAdvertised';

export const advertisedColumn: FlywheelTableV2ColumnProps<
  SkuCatalogData,
  SkuCatalogSummary,
  TableExtraProps,
  SkuCatalogSummary
> = {
  header: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED,
  id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED,
  accessorKey: SkuCatalogApiColumnValue.AdvertisedStatus,
  cell: ({
    row: { original, depth },
    table: {
      options: { meta },
    },
  }) => {
    return (
      <Cell
        {...original}
        {...meta}
        depth={depth}
        skuDetailsFields={original.skuDetailsFields}
      />
    );
  },
  Total: ({
    total,
    table: {
      options: { meta },
    },
  }) => <Total {...total} {...meta} />,
  align: ColumnAlign.Left,
  sortable: true,
  size: 160,
};

const AdvertisedFilterOptions: MultiSelectOption[] = [
  {
    value: 'advertised',
    displayI18nKey:
      I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED_FILTER_ADVERTISED,
  },
  {
    value: 'unadvertised',
    displayI18nKey:
      I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED_FILTER_NOT_ADVERTISED,
  },
  {
    value: 'ad_ineligible',
    displayI18nKey:
      I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED_FILTER_AD_INELIGIBLE,
  },
];

export const advertisedFilter = createMultiSelectDataFieldFilter(
  SkuCatalogApiColumnValue.AdvertisedStatus,
  I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED,
  AdvertisedFilterOptions
);

export const advertisedGlossary: GlossaryColumnProps = {
  name: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED,
  description: I18nKey.PRODUCTS_GLOSSARY_ADVERTISED,
};

export const getAdvertisedColumnText = (
  intl: IntlShape,
  isAdvertised: boolean,
  advertisedSkuCount?: number
) => {
  if (advertisedSkuCount === -1) {
    return intl.formatMessage({
      id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED_INELIGIBLE,
    });
  }
  return intl.formatMessage(
    {
      id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED_TEXT,
    },
    {
      isAdvertised,
    }
  );
};

export const getAdvertisedInfoTooltip = (
  spIneligible: boolean,
  isOutOfInventory: boolean
) => {
  if (spIneligible) {
    return I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED_INELIGIBLE_TOOLTIP;
  }
  if (isOutOfInventory) {
    return I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED_OUT_OF_INVENTORY_TOOLTIP;
  }
  return;
};
