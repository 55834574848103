import { Factory } from 'fishery';

import {
  PlatformData,
  PlatformDataWithCountries,
} from '../../types/MISharedTypes';

const PlatformDataWithCountriesFactory =
  Factory.define<PlatformDataWithCountries>(() => ({
    id: 1,
    name: 'Amazon',
    active: true,
    countries: [],
  }));

const PlatformDataFactory = Factory.define<PlatformData>(() => ({
  id: 1,
  name: 'Amazon',
  active: true,
  countries: [],
}));

export { PlatformDataWithCountriesFactory, PlatformDataFactory };
