import { MerchantCountry } from '../../../../lib/types/Fam';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  KeywordAdgroupInfo,
  TargetSegment,
} from '../../../../lib/types/KeywordAction';

export interface ViewSkuAndKeywordSlideoutModalProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly goToChangeAdGroup: () => void;
  readonly defaultAdGroupId: string;
  readonly suggestedAdgroup?: KeywordAdgroupInfo;
  readonly merchantCountry: MerchantCountry;
}

export interface SlideOverCommonProps {
  readonly aoApiClient: AOApiClient;
  readonly accountId: string;
  readonly merchantCountryId: string;
  readonly adGroupId: string;
  readonly salesChannelId: string;
  readonly merchantType: string;
}

export interface SkuTabProps extends SlideOverCommonProps {
  merchantCountryCode?: string;
}
export interface KeywordsTabProps extends SlideOverCommonProps {}

export enum SlideOutTab {
  Sku = 0,
  Keyword = 1,
}

export interface KeywordsPieChartDataProps {
  readonly type: TargetSegment;
  readonly count: number;
}

export const MAP_TARGET_SEGMENT_TO_PIE_CHART_COLOR: Record<
  TargetSegment,
  number
> = {
  [TargetSegment.Brand]: 600,
  [TargetSegment.Competitor]: 500,
  [TargetSegment.Generic]: 400,
  [TargetSegment.Unknown]: 300,
};

export const MAP_TARGET_SEGMENT_TO_RAW_PIE_CHART_COLOR: Record<
  TargetSegment,
  string
> = {
  [TargetSegment.Brand]: '#260c8e',
  [TargetSegment.Competitor]: '#2d10bc',
  [TargetSegment.Generic]: '#4b25ea',
  [TargetSegment.Unknown]: '#9892f4',
};

export const MAP_TARGET_SEGMENT_TO_I18NKEYS: Record<string, string> = {
  [TargetSegment.Brand]: I18nKey.GENERIC_TARGET_SEGMENT_BRAND,
  [TargetSegment.Competitor]: I18nKey.GENERIC_TARGET_SEGMENT_COMPTETITOR,
  [TargetSegment.Generic]: I18nKey.GENERIC_TARGET_SEGMENT_GENERIC,
  [TargetSegment.Unknown]: I18nKey.GENERIC_TARGET_SEGMENT_NO_TAG,
};
