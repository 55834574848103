import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  Placement,
  SelectProps,
  SelectState,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  AmazonBiddingStrategy,
  CampaignDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  getTooltipContentForArchivedEntity,
  isCampaignStatusArchived,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { getCurrentRowIndex } from './utils';

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, ...campaignDetails }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const campaignId = campaignDetails.campaignId;
  const currentTableId = ADS_MANAGER_CAMPAIGNS_TABLE_ID;
  const flywheelSetttings = campaignDetails.flywheelSettings;

  const updatedAmazonBiddingStrategy = useSelector<
    WithTable<CampaignDetails>,
    string
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      campaignId,
      CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy
    )(tableState, currentTableId)
  );

  const currentRowIndex = useSelector<WithTable<CampaignDetails>, number>(
    getCurrentRowIndex(campaignDetails)
  );

  const existingValue = campaignDetails.channelSettings.biddingStrategy || '';

  const isAutomated = flywheelSetttings.automationStatus;

  const value = isAutomated
    ? AmazonBiddingStrategy.DynamicBidsDownOnly
    : updatedAmazonBiddingStrategy ?? existingValue;

  if (isEditMode) {
    const options = [
      {
        label: intl.formatMessage({
          id: AMAZON_BIDDING_STRATEGY_I18NKEY_MAPPER[
            AmazonBiddingStrategy.DynamicBidsDownOnly
          ],
        }),
        value: AmazonBiddingStrategy.DynamicBidsDownOnly,
      },
      {
        label: intl.formatMessage({
          id: AMAZON_BIDDING_STRATEGY_I18NKEY_MAPPER[
            AmazonBiddingStrategy.DynamicBidsUpAndDown
          ],
        }),
        value: AmazonBiddingStrategy.DynamicBidsUpAndDown,
      },
      {
        label: intl.formatMessage({
          id: AMAZON_BIDDING_STRATEGY_I18NKEY_MAPPER[
            AmazonBiddingStrategy.FixedBid
          ],
        }),
        value: AmazonBiddingStrategy.FixedBid,
      },
    ];

    const onAmazonBiddingStrategyChange = (amazonBiddingValue: string) => {
      dispatch(
        tableActions.updateCell({
          tableId: currentTableId,
          rowId: campaignId,
          columnName: CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy,
          value: amazonBiddingValue,
          existingValue,
        })
      );
    };

    const isCampaignArchived = isCampaignStatusArchived(
      campaignDetails.channelSettings.status
    );

    const tooltipContent = () => {
      if (isCampaignArchived) {
        return getTooltipContentForArchivedEntity(
          intl.formatMessage({
            id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
          }),
          intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BIDDING_STRATEGY,
          }),
          intl
        );
      }
    };

    const bidAutomationStatusSelectProps: SelectProps<string> = {
      options,
      placeholder: '',
      value,
      isDirty: value !== existingValue,
      onChange: onAmazonBiddingStrategyChange,
      wrapperClassName: 'w-140',
      state:
        isCampaignStatusArchived(campaignDetails.channelSettings.status) ||
        isAutomated
          ? SelectState.Disabled
          : SelectState.Default,
      dataTestId: 'amazon_bidding_stratergy',
      showOnTop: currentRowIndex > 6,
    };

    return makeSelectColumn(
      (_selectColumnCampaignDetails: CampaignDetails) =>
        bidAutomationStatusSelectProps,
      () => {
        return {
          tooltipContent: tooltipContent(),
          controlledTooltip: isCampaignArchived
            ? ControlledTooltip.None
            : ControlledTooltip.Hide,
          tooltipPlacement: Placement.Bottom,
          style: ContentStyle.Bold,
        };
      }
    )(campaignDetails);
  }

  return makeTextColumn<CampaignDetails>(
    ({ channelSettings: { biddingStrategy } }) =>
      (biddingStrategy &&
        AMAZON_BIDDING_STRATEGY_I18NKEY_MAPPER[biddingStrategy] &&
        intl.formatMessage({
          id: AMAZON_BIDDING_STRATEGY_I18NKEY_MAPPER[biddingStrategy],
        })) ||
      biddingStrategy ||
      undefined,
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

const HeaderToolTip: React.FC = () => {
  return (
    <label className="w-100 whitespace-normal">
      <FormattedMessage
        id={
          I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BIDDING_STRATEGY_TOOLTIP
        }
      />
    </label>
  );
};

export const amazonBiddingStrategyColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BIDDING_STRATEGY,
  RowCellElement,
  gridColumnWidth: '160px',
  toolTipContent: <HeaderToolTip />,
};

export const amazonBiddingStrategyFilter = createMultiSelectDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BIDDING_STRATEGY,
  [
    SelectFilterOption.AmazonBiddingStrategyDynamicBidsDown,
    SelectFilterOption.AmazonBiddingStrategyDynamicBidsUpAndDown,
    SelectFilterOption.AmazonBiddingStrategyDynamicBidsFixed,
  ]
);

const AMAZON_BIDDING_STRATEGY_I18NKEY_MAPPER: Record<
  AmazonBiddingStrategy,
  I18nKey
> = {
  [AmazonBiddingStrategy.DynamicBidsDownOnly]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_DOWN,
  [AmazonBiddingStrategy.DynamicBidsUpAndDown]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_UP_AND_DOWN,
  [AmazonBiddingStrategy.FixedBid]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_FIXED,
};
