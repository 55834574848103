import { DateTime } from 'luxon';

export const HEADER_HELP_INTERCOM_LINK =
  'https://intercom.help/flywheel-20/en/articles/8751428-multichannel-advertising-optimization-dashboard-navigating-your-advertising-performance';
export const DATA_TEST_ID = 'ao_dashboard';

export const AMAZON_CONSOLE_LINK = 'https://advertising.amazon.com/sign-in';
export const WALMART_CONSOLE_LINK =
  'https://advertisinghelp.walmart.com/s/login/';
export const DATE_FORMAT_FULL = 'yyyy-MM-dd';
export const DEFAULT_MIN_DATE: DateTime = DateTime.fromISO(
  '1970-01-01T00:00:00.000'
);

export const AO_MULTI_CHANNEL_DASHBOARD_TABLE_ID =
  'AO_MULTI_CHANNEL_DASHBOARD_TABLE_ID';
