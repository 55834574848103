import {
  CheckboxCheckedState,
  IconSize,
  LightningGradientIcon,
  LightningPurpleGradientIcon,
} from '@teikametrics/tm-design-system';
import { FlywheelTableColumnGroup } from '../../../../../containers/table/UpdatedFlywheelTable';
import { AdGroupDetails, AdType } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { acosColumn } from './acos';
import { acosDirectColumn } from './acosDirect';
import { acosLimitColumn } from './acosLimit';
import { adConversionsColumn } from './adConversions';
import { adGroupReviewStatusColumn } from './adGroupReviewStatus';
import { adSalesColumn } from './adSales';
import { adSalesDirectColumn } from './adSalesDirect';
import { adSpendColumn } from './adSpend';
import { addedByColumn } from './addedBy';
import { adgroupDefaultBidColumn } from './adgroupDefaultBid';
import { adgroupNameColumn } from './adgroupName';
import { adgroupStatusColumn } from './adgroupStatus';
import { advertisedSKUUnitsColumn } from './advertisedSkuUnits';
import { aiBiddingColumn } from './aiBidding';
import {
  aiRecommendedKeywordColumn,
  aiRecommendedKeywordColumnUpdatedHeader,
} from './aiRecommendedKeyword';
import { avgCostPerClickColumn } from './avgCostPerClick';
import { bidAutomationStatusColumn } from './bidAutomationStatus';
import { bidModifierColumn } from './bidModifier';
import { campaignCostTypeColumn } from './campaignCostType';
import { campaignDailyBudgetColumn } from './campaignDailyBudget';
import { campaignNameColumn } from './campaignName';
import { campaignStatusColumn } from './campaignStatus';
import { campaignTacticColumn } from './campaignTactic';
import { campaignTargetingTypeColumn } from './campaignTargetingType';
import { campaignTypeColumn } from './campaignType';
import { clickThroughRateColumn } from './clickThroughRate';
import { clicksColumn } from './clicks';
import { conversionRateColumn } from './conversionRate';
import { createdAtColumn } from './createdAt';
import { estimatedPreAdGrossMarginColumn } from './estimatedPreAdGrossMargin';
import { groupColumn } from './group';
import { impressionsColumn } from './impressions';
import { labelsColumn, labelsColumnUpdatedHeader } from './labels';
import { macsTargetColumn } from './macsTarget';
import { maxBidColumn } from './maxBid';
import { maxBidNewColumn } from './maxBidNewColumn';
import { merchantNameColumn } from './merchantName';
import { minBidColumn } from './minBid';
import { minBidNewColumn } from './minBidNewColumn';
import { newToBrandAdOrdersColumn } from './newToBrandAdOrders';
import { newToBrandAdSalesColumn } from './newToBrandAdSales';
import { newToBrandAdUnitsSoldColum } from './newToBrandAdUnitsSold';
import { ordersColumn } from './orders';
import { otherSKUUnitsColumn } from './otherSkuUnits';
import { portfolioNameColumn } from './portfolioName';
import { relatedClickRevenueColumn } from './relatedClickRevenue';
import { roasColumn } from './roas';
import { roasDirectColumn } from './roasDirect';
import { totalBudgetColumn } from './totalBudget';
import { unitsSoldColumn } from './unitsSold';

const FLYWHEEL_SETTINGS_HELP_LINK =
  'https://intercom.help/flywheel-20/en/articles/5643813-what-is-an-acos-target';

export const generateAdGroupColumns = (args: {
  isEditMode: boolean;
  adType: AdType;
  isAIPlanEnabled: boolean;
  headerExtraProps?: {
    onSelectChange: React.MouseEventHandler<HTMLElement>;
    checked: CheckboxCheckedState;
  };
  showSmartAcosInAdsManager: boolean;
  isSponsoredVideosEditable?: boolean;
}): FlywheelTableColumnGroup<AdGroupDetails, TableDataAdsManager>[] => {
  const {
    isEditMode,
    adType,
    isAIPlanEnabled,
    headerExtraProps,
    showSmartAcosInAdsManager,
    isSponsoredVideosEditable,
  } = args;

  return [
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_ADGROUP_TABLE_DETAILS_COLUMN_GROUP,
      columnsInGroup: [
        {
          ...adgroupNameColumn,
          ...(headerExtraProps
            ? {
                showCheckBox: isEditMode,
                checked: headerExtraProps.checked,
                onSelectChange: headerExtraProps.onSelectChange,
              }
            : {}),
        },
      ],
    },
    {
      columnsInGroup: [
        merchantNameColumn,
        campaignNameColumn,
        campaignTargetingTypeColumn,
        ...(showSmartAcosInAdsManager ? [] : [addedByColumn]),
        campaignTypeColumn,
        campaignTacticColumn,
        campaignDailyBudgetColumn,
        totalBudgetColumn,
        campaignStatusColumn,
        portfolioNameColumn,
        campaignCostTypeColumn,
        groupColumn,
      ],
    },
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_ADGROUP_TABLE_CHANNEL_SETTINGS_COLUMN_GROUP,
      columnsInGroup: [
        adgroupDefaultBidColumn,
        adgroupStatusColumn,
        adGroupReviewStatusColumn,
        createdAtColumn,
      ],
    },
    ...(isAIPlanEnabled && !showSmartAcosInAdsManager
      ? [
          {
            groupingHeaderI18nKey:
              I18nKey.ADS_MANAGER_ADGROUP_TABLE_FLYWHEEL_SETTINGS_COLUMN_GROUP,
            columnsInGroup: [
              bidAutomationStatusColumn,
              macsTargetColumn,
              minBidColumn,
              maxBidColumn,
              ...(adType === AdType.SponsoredProducts
                ? [labelsColumn, aiRecommendedKeywordColumn]
                : []),
            ],
            icon: {
              svg: LightningGradientIcon,
              size: IconSize.Small,
            },
            help: {
              title: I18nKey.ADS_MANAGER_FLYWHEEL_SETTINGS_HELP_TITLE,
              description:
                I18nKey.ADS_MANAGER_FLYWHEEL_SETTINGS_HELP_DESCRIPTION,
              link: {
                text: I18nKey.ADS_MANAGER_FLYWHEEL_SETTINGS_HELP_LINK_TEXT,
                url: FLYWHEEL_SETTINGS_HELP_LINK,
              },
            },
          },
        ]
      : []),
    ...((isAIPlanEnabled &&
      showSmartAcosInAdsManager &&
      adType === AdType.SponsoredVideos &&
      isSponsoredVideosEditable) ||
    (isAIPlanEnabled &&
      showSmartAcosInAdsManager &&
      adType !== AdType.SponsoredVideos)
      ? [
          {
            groupingHeaderI18nKey:
              I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_GROUP,
            columnsInGroup: [
              aiBiddingColumn,
              ...(adType === AdType.SponsoredProducts
                ? [estimatedPreAdGrossMarginColumn]
                : []),
              acosLimitColumn,
              bidModifierColumn,
              minBidNewColumn,
              maxBidNewColumn,
            ],
            icon: {
              svg: LightningPurpleGradientIcon,
              size: IconSize.Small,
            },
          },
        ]
      : []),
    ...(isAIPlanEnabled &&
    showSmartAcosInAdsManager &&
    adType !== AdType.SponsoredVideos
      ? [
          {
            groupingHeaderI18nKey:
              I18nKey.ADS_MANAGER_ADGROUP_TABLE_KEYWORDS_COLUMN_GROUP,
            columnsInGroup: [
              labelsColumnUpdatedHeader,
              aiRecommendedKeywordColumnUpdatedHeader,
            ],
          },
        ]
      : []),
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_ADGROUP_TABLE_PERFORMANCE_COLUMN_GROUP,
      columnsInGroup: [
        adSalesColumn,
        adSpendColumn,
        acosColumn,
        roasColumn,
        adConversionsColumn,
        conversionRateColumn,
        unitsSoldColumn,
        impressionsColumn,
        clicksColumn,
        avgCostPerClickColumn,
        clickThroughRateColumn,
        adSalesDirectColumn,
        acosDirectColumn,
        roasDirectColumn,
        relatedClickRevenueColumn,
        newToBrandAdOrdersColumn,
        newToBrandAdSalesColumn,
        newToBrandAdUnitsSoldColum,
        advertisedSKUUnitsColumn,
        otherSKUUnitsColumn,
        ordersColumn,
      ],
    },
  ];
};
