import React from 'react';

import {
  AlertTriangleIcon,
  TableEmptyState,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../lib/types/I18nKey';

export const ErrorTable: React.FC = () => {
  const onPrimaryButtonClick = () => {
    window.location.reload();
  };

  return (
    <TableEmptyState
      icon={AlertTriangleIcon}
      titleI18nKey={
        I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_DATA_CONNECTION_ERROR_TITLE
      }
      className="absolute top-1/4 left-2/4"
      dataTestId={'ao_errorTable'}
      primaryButtonI18nKey={
        I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_DATA_CONNECTION_ERROR_DESCRIPTION
      }
      showPrimaryButton={true}
      onClickPrimaryButton={onPrimaryButtonClick}
    />
  );
};
ErrorTable.displayName = 'ErrorTable';
