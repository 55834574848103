import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignDetails,
  MAP_SMART_STRUCTURE_TO_II8NKEY,
  SmartStructure,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CAMPAIGNS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { useIntl } from 'react-intl';

export const RowCellElement: React.FC<CampaignDetails> = ({
  ...campaignDetails
}) => {
  const intl = useIntl();
  const smartStructure =
    campaignDetails.campaignDetails.smartStructure || SmartStructure.External;
  return makeTextColumn(() =>
    intl.formatMessage({ id: MAP_SMART_STRUCTURE_TO_II8NKEY[smartStructure] })
  )(campaignDetails);
};
RowCellElement.displayName = 'SmartStructure';

export const smartStructureColumn: FlywheelTableColumn<CampaignDetails> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.SmartStructure,
  i18nKeyOrLabel: I18nKey.GENERIC_SMART_STRUCTURE_COLUMN,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '150px',
};

export const smartStructureFilter = createMultiSelectDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.SmartStructure,
  I18nKey.GENERIC_SMART_STRUCTURE_COLUMN,
  [
    SelectFilterOption.SmartStructureBasic,
    SelectFilterOption.SmartStructureIntermediate,
    SelectFilterOption.SmartStructureAdvanced,
    SelectFilterOption.SmartStructureCustom,
  ]
);
