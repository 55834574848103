interface ColumnGroupDetails {
  readonly numberOfColumnsInGroup: number;
}

export type ColumnGroups = Readonly<ColumnGroupDetails[]>;

const getColumnsWithGroupingBorder = (
  columnGroups: ColumnGroups
): Set<number> => {
  let currentColumnGroupIdx = 0;
  let currentColumnIdx = 0;
  const columnsWithGroupingBorders: Set<number> = new Set<number>();

  while (
    columnGroups[currentColumnGroupIdx] !== undefined &&
    currentColumnGroupIdx !== columnGroups.length - 1
  ) {
    const numberOfColumnsInCurrentColumnGroup =
      columnGroups[currentColumnGroupIdx].numberOfColumnsInGroup;
    const currentColumnGroupEnd =
      currentColumnIdx + numberOfColumnsInCurrentColumnGroup - 1;

    columnsWithGroupingBorders.add(currentColumnGroupEnd);

    currentColumnIdx = currentColumnGroupEnd + 1;
    currentColumnGroupIdx += 1;
  }

  return columnsWithGroupingBorders;
};

export default getColumnsWithGroupingBorder;
