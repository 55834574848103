import {
  AlertCircleIcon,
  Icon,
  IconSize,
  Modal,
  ModalAlign,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { getCurrentAccountFromContext } from './containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from './containers/userProvider/userProvider';
import { useContext, useState } from 'react';
import { BROADCAST_ACCOUNT_SWITCH_NAME } from './lib/utilities/commonUtils';
import { useIntl } from 'react-intl';
import I18nKey from './lib/types/I18nKey';

interface AccountSwitchedModalProps {
  readonly channel: BroadcastChannel;
}

export const AccountSwitchedModal: React.FC<AccountSwitchedModalProps> = ({
  channel,
}) => {
  const intl = useIntl();
  const [showRefreshModal, setShowRefreshModal] = useState(false);

  const userContext = useContext<UserContextState>(UserContext);

  const currentAccount = getCurrentAccountFromContext(userContext);
  const accountId = currentAccount!.id;

  channel.onmessage = function (e) {
    const target = e.target as BroadcastChannel;
    /**
     * Confirm that the broadcasted message meets the following conditions.
     * a. It is from the same origin/hostname
     *    This will help us stay put if some other website/host tries to broadcast message.
     * b. Check for the exact broadcast name.
     * c. Check if the existing account Id and the message account id are different
     * d. If the modal is not already set
     */
    if (
      e.origin === window.location.origin &&
      target.name === BROADCAST_ACCOUNT_SWITCH_NAME &&
      e.data !== accountId &&
      !showRefreshModal
    ) {
      setShowRefreshModal(true);
    }
  };

  const onRefresh = () => {
    window.location.reload();
  };

  return (
    <Modal
      showModal={showRefreshModal}
      align={ModalAlign.Left}
      icon={
        <Icon
          svg={AlertCircleIcon}
          className="text-red-500"
          size={IconSize.Large}
        />
      }
      header={intl.formatMessage({
        id: I18nKey.ACCOUNT_SWITCHED_MODAL_HEADER,
      })}
      headerClass="text-xl font-semibold leading-normal"
      dataTestId="cross_tab_acc_switch_modal"
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      mainAction={{
        label: intl.formatMessage({ id: I18nKey.GENERIC_REFRESH }),
        onClick: onRefresh,
      }}
      // This z-index is used to provide this modal the highest priority.
      overlayZindexClassName="z-100"
      id="account_switched_modal"
    >
      <Typography
        size={TypographySize.base}
        weight={TypographyWeight.regular}
        lineHeight={TypographyLineHeight.normal}
      >
        {intl.formatMessage({
          id: I18nKey.ACCOUNT_SWITCHED_MODAL_DESCRIPTION,
        })}
      </Typography>
    </Modal>
  );
};
