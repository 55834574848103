import {
  PortaledTooltipPlacement,
  ProductSku2,
  Spinner,
} from '@teikametrics/tm-design-system';
import { MAP_SALES_CHANNEL_ID_TO_NAME } from '../../lib/types/SalesChannels';
import { SkuTabProps } from './types';
import { getAmazonItemPageUrl } from '../../core/campaignFlexibility/utils';
import { getWalmartItemPageUrl } from '../../lib/utilities/skuUtils';
import { SelectedTrendsProduct } from '../../modules/products/containers/skuCatalog';
import { MerchantCountryCode } from '../../lib/types/AOSharedTypes';
import { FlywheelSalesChannel } from '../../lib/types/Fam';

export const SkuTab: React.FC<SkuTabProps> = ({
  merchantCountryId,
  salesChannelId,
  merchantCountryCode,
  skuData,
  loadingSkus,
  onSKUTrendsLinkClick,
}) => {
  const onAsinClick = (
    salesChannelName: string,
    merchantCountryCode: MerchantCountryCode,
    extItemId: string
  ) => {
    const url =
      salesChannelName === FlywheelSalesChannel.Amazon
        ? getAmazonItemPageUrl(merchantCountryCode, extItemId)
        : getWalmartItemPageUrl(extItemId);

    window.open(url);
  };

  const onTrendIconClick = (product: SelectedTrendsProduct) => {
    if (onSKUTrendsLinkClick) {
      onSKUTrendsLinkClick(product);
    }
  };

  const getSkuData = () => {
    return (
      <div className="flex flex-col gap-12 px-24 mr-12">
        {skuData &&
          skuData.map((eachSku) => (
            <div className="flex justify-between">
              <ProductSku2
                key={eachSku.extItemId}
                dataTestId="product_kwa_2_slideover"
                asin={eachSku.extItemId}
                canExpand={false}
                count={0}
                expanded={false}
                merchantCountry={merchantCountryId}
                onAsinClick={() => {
                  onAsinClick(
                    MAP_SALES_CHANNEL_ID_TO_NAME[salesChannelId],
                    merchantCountryCode as MerchantCountryCode,
                    eachSku.extItemId || ''
                  );
                }}
                productImageUrl={eachSku.imageUrl}
                productName={eachSku.name}
                salesChannel={MAP_SALES_CHANNEL_ID_TO_NAME[salesChannelId]}
                sku={eachSku.sku}
                useSku={true}
                useAsin={
                  MAP_SALES_CHANNEL_ID_TO_NAME[salesChannelId] ===
                  FlywheelSalesChannel.Amazon
                }
                isProductNameClickable={false}
                productNameTooltipPlacement={PortaledTooltipPlacement.Bottom}
                productNameTooltipPlacementOffset={0}
                className="max-w-460"
                skuLabelClassnames="truncate w-160"
                isProductNameLink={false}
                useTruncatedText
                showTrendIcon
                onTrendIconClick={() =>
                  onTrendIconClick({
                    sku: eachSku.sku,
                    salesChannelId,
                    merchantCountryId,
                  })
                }
              />
            </div>
          ))}
      </div>
    );
  };

  return (
    <div
      className="h-kwaCreateInAdGroupSlideoutTabV2 overflow-y-auto overflow-x-hidden"
      id="kwa_2_sku_slideout_content"
    >
      {loadingSkus && (
        <div className="relative mt-12 ml-16 h-32">
          <Spinner />
        </div>
      )}
      {!loadingSkus && getSkuData()}
    </div>
  );
};

SkuTab.displayName = 'ProductDetailsSlideoutSkuTab';
