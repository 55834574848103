import {
  MultiSelectOption,
  SingleSelectOption,
} from '@teikametrics/tm-design-system';

import {
  AdGroupReviewStatusType,
  AdGroupStatus,
  AddedBy,
  AdvertisingGoal,
  AiBiddingValues,
  AiRecommendedKeywordType,
  AmazonBidOptimization,
  AmazonBiddingStrategy,
  AutomationStatusFilterValue,
  BudgetRollover,
  BudgetType,
  CampaignAdFormat,
  CampaignCostType,
  CampaignServingStatus,
  CampaignStatus,
  CampaignTactic,
  CampaignTargetingType,
  CreativeServingStatus,
  CreativeType,
  ExpressionType,
  FlywheelAIEnabled,
  KeywordReviewStatusType,
  KeywordTargetType,
  MatchType,
  PlacementStatus,
  ProductReviewStatusType,
  ProfileStatus,
  ReviewStatus,
  SmartStructure,
  TargetLabels,
  TargetStatus,
  TargetTypeFilter,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ProductAdStatusOptions } from '.';
import {
  TargetSegments,
  TargetingType,
} from '../../../../../lib/types/KeywordAction';
import { AD_GROUP_NO_TAG_FILTER_OPTION } from '../../../../keywordAction/components/RecommendationsTableV2/types';

enum SelectFilterOptionType {
  AdgroupStatusEnabled = 'AdgroupStatusEnabled',
  AdgroupStatusDisabled = 'AdgroupStatusDisabled',
  CampaignStatusPaused = 'CampaignStatusPaused',
  CampaignStatusCompleted = 'CampaignStatusCompleted',
  CampaignStatusRescheduled = 'CampaignStatusRescheduled',
  CampaignStatusLive = 'CampaignStatusLive',
  CampaignStatusProposal = 'CampaignStatusProposal',
  CampaignStatusScheduled = 'CampaignStatusScheduled',
  CampaignStatusEnabled = 'CampaignStatusEnabled',
  CampaignStatusArchived = 'CampaignStatusArchived',
  CampaignStatusPending = 'CampaignStatusPending',
  CampaignStatusDraft = 'CampaignStatusDraft',
  CampaignTacticLegacy = 'CampaignTacticLegacy',
  CampaignTacticProduct = 'CampaignTacticProduct',
  CampaignTacticAudiences = 'CampaignTacticAudiences',
  CampaignTacticViews = 'CampaignTacticViews',
  CampaignTargetingTypeAuto = 'CampaignTargetingTypeAuto',
  CampaignTargetingTypeManual = 'CampaignTargetingTypeManual',
  ProductAdStatusEnabled = 'ProductAdStatusEnabled',
  ProductAdStatusDisabled = 'ProductAdStatusDisabled',
  ProductAdStatusArchived = 'ProductAdStatusArchived',
  AmazonBiddingStrategyDynamicBidsDown = 'AmazonBiddingStrategyDynamicBidsDown',
  AmazonBiddingStrategyDynamicBidsUpAndDown = 'AmazonBiddingStrategyDynamicBidsUpAndDown',
  AmazonBiddingStrategyDynamicBidsFixed = 'AmazonBiddingStrategyDynamicBidsFixed',
  AmazonBidOptimizationOn = 'AmazonBidOptimizationOn',
  AmazonBidOptimizationOff = 'AmazonBidOptimizationOff',
  BudgetRolloverTrue = 'BudgetRolloverTrue',
  BudgetRolloverFalse = 'BudgetRolloverFalse',
  BudgetTypeDaily = 'BudgetTypeDaily',
  BudgetTypeTotal = 'BudgetTypeTotal',
  BudgetTypeBoth = 'BudgetTypeBoth',
  BudgetTypeLifetime = 'BudgetTypeLifetime',
  CampaignAdFormatProductCollection = 'CampaignAdFormatProductCollection',
  CampaignAdFormatVideo = 'CampaignAdFormatVideo',
  CampaignAdFormatStoreSpotlight = 'CampaignAdFormatStoreSpotlight',
  MatchTypeExact = 'MatchTypeExact',
  MatchTypePhrase = 'MatchTypePhrase',
  MatchTypeBroad = 'MatchTypeBroad',
  MatchTypeNegativeExact = 'MatchTypeNegativeExact',
  MatchTypeNegativePhrase = 'MatchTypeNegativePhrase',
  TargetTypeGeneric = 'TargetTypeGeneric',
  TargetTypeCompetitor = 'TargetTypeCompetitor',
  TargetTypeBrand = 'TargetTypeBrand',
  TargetTypeAuto = 'TargetTypeAuto',
  FlywheelAiAdded = 'FlywheelAiAdded',
  ManuallyAdded = 'ManuallyAdded',
  TargetStatusEnabled = 'TargetStatusEnabled',
  TargetStatusPaused = 'TargetStatusPaused',
  TargetStatusArchived = 'TargetStatusArchived',
  TargetStatusDeleted = 'TargetStatusDeleted',
  TargetStatusPending = 'TargetStatusPending',
  BidAutomationStatusActive = 'BidAutomationStatusActive',
  BidAutomationStatusInactive = 'BidAutomationStatusInactive',
  BidAutomationStatusUnset = 'BidAutomationStatusUnset',
  CampaignServingStatusAsinNotBuyable = 'CampaignServingStatusAsinNotBuyable',
  CampaignServingStatusBillingError = 'CampaignServingStatusBillingError',
  CampaignServingStatusEnded = 'CampaignServingStatusEnded',
  CampaignServingStatusLandingPageNotAvailable = 'CampaignServingStatusLandingPageNotAvailable',
  CampaignServingStatusOutOfBudget = 'CampaignServingStatusOutOfBudget',
  CampaignServingStatusPaused = 'CampaignServingStatusPaused',
  CampaignServingStatusPendingReview = 'CampaignServingStatusPendingReview',
  CampaignServingStatusReady = 'CampaignServingStatusReady',
  CampaignServingStatusRejected = 'CampaignServingStatusRejected',
  CampaignServingStatusDelivering = 'CampaignServingStatusDelivering',
  CampaignServingStatusScheduled = 'CampaignServingStatusScheduled',
  CampaignServingStatusTerminated = 'CampaignServingStatusTerminated',
  CampaignServingStatusIneligible = 'CampaignServingStatusIneligible',
  CampaignServingStatusCampaignArchived = 'CampaignServingStatusCampaignArchived',
  CampaignServingStatusCampaignAdsNotDelivering = 'CampaignServingStatusCampaignAdsNotDelivering',
  CampaignServingStatusPortfolioOutOfBudget = 'CampaignServingStatusPortfolioOutOfBudget',
  CampaignServingStatusAccountOutOfBudget = 'CampaignServingStatusAccountOutOfBudget',
  CampaignServingStatusPendingStartDate = 'CampaignServingStatusPendingStartDate',
  CampaignServingStatusPortfolioEnded = 'CampaignServingStatusPortfolioEnded',
  CampaignServingStatusCampaignStatusEnabled = 'CampaignServingStatusCampaignStatusEnabled',
  CampaignServingStatusAdvertiserPaymentFailure = 'CampaignServingStatusAdvertiserPaymentFailure',
  CampaignServingStatusUnknown = 'CampaignServingStatusUnknown',
  CampaignServingStatusCampaignIncomplete = 'CampaignServingStatusCampaignIncomplete',
  CampaignServingStatusPortfolioPendingStartDate = 'CampaignServingStatusPortfolioPendingStartDate',
  CampaignServingStatusAdvertiserArchived = 'CampaignServingStatusAdvertiserArchived',
  ExpressionTypeAsinSameAs = 'ExpressionTypeAsinSameAs',
  ExpressionTypeAsinCategorySameAs = 'ExpressionTypeAsinCategorySameAs',
  ExpressionTypeAsinBrandSameAs = 'ExpressionTypeAsinBrandSameAs',
  ExpressionTypeAsinPriceBetween = 'ExperssionTypeAsinPriceBetween',
  ExpressionTypeAsinPriceGreaterThan = 'ExpressionTypeAsinPriceGreaterThan',
  ExpressionTypeAsinPriceLessThan = 'ExpressionTypeAsinPriceLessThan',
  ExpressionTypeAsinReviewRatingBetween = 'ExpressionTypeAsinReviewRatingBetween',
  ExpressionTypeAsinReviewRatingGreaterThan = 'ExpressionTypeAsinReviewRatingGreaterThan',
  ExpressionTypeAsinReviewRatingLessThan = 'ExpressionTypeAsinReviewRatingLessThan',
  ExpressionTypeAsinIsPrimeShippingAvailable = 'ExpressionTypeAsinIsPrimeShippingAvailable',
  ExpressionTypeAsinAgeRangeSameAs = 'ExpressionTypeAsinAgeRangeSameAs',
  ExpressionTypeAsinGenreSameAs = 'ExpressionTypeAsinGenreSameAs',
  CampaignPlacementStatusExcluded = 'CampaignPlacementStatusExcluded',
  CampaignPlacementStatusIncluded = 'CampaignPlacementStatusIncluded',
  CampaignCostTypeCPC = 'CampaignCostTypeCPC',
  CampaignCostTypeVCPM = 'CampaignCostTypeVCPM',
  KeywordReviewStatusApproved = 'KeywordReviewStatusApproved',
  KeywordReviewStatusPending = 'KeywordReviewStatusPending',
  KeywordReviewStatusRejected = 'KeywordReviewStatusRejected',
  ProductReviewStatusApproved = 'ProductReviewStatusApproved',
  ProductReviewStatusPending = 'ProductReviewStatusPending',
  ProductReviewStatusRejected = 'ProductReviewStatusRejected',
  AdGroupReviewStatusPending = 'AdGroupReviewStatusPending',
  AdGroupReviewStatusInProgress = 'AdGroupReviewStatusInProgress',
  AdGroupReviewStatusCompleted = 'AdGroupReviewStatusCompleted',
  AdGroupReviewStatusCancelled = 'AdGroupReviewStatusCancelled',
  ProfileReviewStatusPending = 'ProfileReviewStatusPending',
  ProfileReviewStatusApproved = 'ProfileReviewStatusApproved',
  ProfileReviewStatusRejected = 'ProfileReviewStatusRejected',
  ProfileStatusEnabled = 'ProfileStatusEnabled',
  ProfileStatusDisabled = 'ProfileStatusDisabled',
  TargetLabelAIAdded = 'TargetLabelAIAdded',
  TargetLabelNegTarget = 'TargetLabelNegTarget',
  TargetLabelNoLabels = 'TargetLabelNoLabels',
  AddAutomatically = 'AddAutomatically',
  ReviewRecommendations = 'ReviewRecommendations',
  NoTag = 'NoTag',
  FlywheelAutomated = 'FlywheelAutomated',
  FlywheelAccepted = 'FlywheelAccepted',
  FlywheelOnDemand = 'FlywheelOnDemand',
  FlywheelAutoNegate = 'FlywheelAutoNegate',
  ChatGPT = 'ChatGPT',
  External = 'External',
  Keyword = 'Keyword',
  NegativeKeyword = 'Negativekeyword',
  CampaignNegativeKeyword = 'CampaignNegativeKeyword',
  NegativeProductTarget = 'NegativeProductTarget',
  AudienceTarget = 'AudienceTarget',
  ContextualTarget = 'ContextualTarget',
  ProductTarget = 'ProductTarget',
  CreativeTypeProductCollection = 'CreativeTypeProductCollection',
  CreativeTypeVideo = 'CreativeTypeVideo',
  CreativeTypeStoreSpotlight = 'CreativeTypeStoreSpotlight',

  CreativeServingStatusAdStatusLive = 'CreativeServingStatusAdStatusLive',
  CreativeServingStatusAdPolicingPendingReview = 'CreativeServingStatusAdPolicingPendingReview',
  CreativeServingStatusAdPolicingSuspended = 'CreativeServingStatusAdPolicingSuspended',
  CreativeServingStatusAdPaused = 'CreativeServingStatusAdPaused',
  CreativeServingStatusAdArchived = 'CreativeServingStatusAdArchived',
  CreativeServingStatusAdGroupStatusEnabled = 'CreativeServingStatusAdGroupStatusEnabled',
  CreativeServingStatusAdGroupPaused = 'CreativeServingStatusAdGroupPaused',
  CreativeServingStatusAdGroupArchived = 'CreativeServingStatusAdGroupArchived',
  CreativeServingStatusAdGroupIncomplete = 'CreativeServingStatusAdGroupIncomplete',
  CreativeServingStatusAdGroupPolicingPendingReview = 'CreativeServingStatusAdGroupPolicingPendingReview',
  CreativeServingStatusAdGroupPolicingCreativeRejected = 'CreativeServingStatusAdGroupPolicingCreativeRejected',
  CreativeServingStatusAdGroupLowBid = 'CreativeServingStatusAdGroupLowBid',
  CreativeServingStatusAdvertiserStatusEnabled = 'CreativeServingStatusAdvertiserStatusEnabled',
  CreativeServingStatusAdvertiserPolicingPendingReview = 'CreativeServingStatusAdvertiserPolicingPendingReview',
  CreativeServingStatusAdvertiserPolicingSuspended = 'CreativeServingStatusAdvertiserPolicingSuspended',
  CreativeServingStatusAdvertiserPaused = 'CreativeServingStatusAdvertiserPaused',
  CreativeServingStatusAdvertiserArchived = 'CreativeServingStatusAdvertiserArchived',
  CreativeServingStatusAdvertiserPaymentFailure = 'CreativeServingStatusAdvertiserPaymentFailure',
  CreativeServingStatusAdvertiserAccountOutOfBudget = 'CreativeServingStatusAdvertiserAccountOutOfBudget',
  CreativeServingStatusAdvertiserOutOfPrepayBalance = 'CreativeServingStatusAdvertiserOutOfPrepayBalance',
  CreativeServingStatusAdvertiserExceedSpendsLimit = 'CreativeServingStatusAdvertiserExceedSpendsLimit',
  CreativeServingStatusCampaignStatusEnabled = 'CreativeServingStatusCampaignStatusEnabled',
  CreativeServingStatusCampaignPaused = 'CreativeServingStatusCampaignPaused',
  CreativeServingStatusCampaignArchived = 'CreativeServingStatusCampaignArchived',
  CreativeServingStatusCampaignIncomplete = 'CreativeServingStatusCampaignIncomplete',
  CreativeServingStatusCampaignOutOfBudget = 'CreativeServingStatusCampaignOutOfBudget',
  CreativeServingStatusPortfolioStatusEnabled = 'CreativeServingStatusPortfolioStatusEnabled',
  CreativeServingStatusPortfolioPaused = 'CreativeServingStatusPortfolioPaused',
  CreativeServingStatusPortfolioArchived = 'CreativeServingStatusPortfolioArchived',
  CreativeServingStatusPortfolioOutOfBudget = 'CreativeServingStatusPortfolioOutOfBudget',
  CreativeServingStatusPortfolioPendingStartDate = 'CreativeServingStatusPortfolioPendingStartDate',
  CreativeServingStatusPortfolioEnded = 'CreativeServingStatusPortfolioEnded',
  CreativeServingStatusIneligible = 'CreativeServingStatusIneligible',
  CreativeServingStatusEligible = 'CreativeServingStatusEligible',
  CreativeServingStatusEnded = 'CreativeServingStatusEnded',
  CreativeServingStatusPendingReview = 'CreativeServingStatusPendingReview',
  CreativeServingStatusPendingStartDate = 'CreativeServingStatusPendingStartDate',
  CreativeServingStatusRejected = 'CreativeServingStatusRejected',
  CreativeServingStatusUnknown = 'CreativeServingStatusUnknown',
  DestinationAdGroupTargetingTypeAuto = 'DestinationAdGroupTargetingTypeAuto',
  DestinationAdGroupTargetingTypeManual = 'DestinationAdGroupTargetingTypeManual',
  DestinationAdGroupTagBrand = 'DestinationAdGroupTagBrand',
  DestinationAdGroupTagCompetitor = 'DestinationAdGroupTagCompetitor',
  DestinationAdGroupTagGeneric = 'DestinationAdGroupTagGeneric',
  DestinationAdGroupTagNoTag = 'DestinationAdGroupTagNoTag',
  AdvertisingGoalProductLaunch = 'AdvertisingGoalProductLaunch',
  AdvertisingGoalGrow = 'AdvertisingGoalGrow',
  AdvertisingGoalProfit = 'AdvertisingGoalProfit',
  AdvertisingGoalRoas = 'AdvertisingGoalRoas',
  AdvertisingGoalLiquidate = 'AdvertisingGoalLiquidate',
  SmartStructureBasic = 'SmartStructureBasic',
  SmartStructureIntermediate = 'SmartStructureIntermediate',
  SmartStructureAdvanced = 'SmartStructureAdvanced',
  SmartStructureCustom = 'SmartStructureCustom',
  AiBiddingSmart = 'AiBiddingSmart',
  AiBiddingOnlyBidding = 'AiBiddingOnlyBidding',
  AiBiddingNoAi = 'AiBiddingNoAi',
}

export const SelectFilterOption: Record<
  SelectFilterOptionType,
  SingleSelectOption | MultiSelectOption
> = {
  //AdvertisingGoal Options
  [SelectFilterOptionType.AdvertisingGoalProductLaunch]: {
    value: AdvertisingGoal.ProductLaunch,
    displayI18nKey: I18nKey.GENERIC_ADVERTISING_GOAL_PRODUCT_LAUNCH,
  },
  [SelectFilterOptionType.AdvertisingGoalGrow]: {
    value: AdvertisingGoal.Grow,
    displayI18nKey: I18nKey.GENERIC_ADVERTISING_GOAL_GROW,
  },
  [SelectFilterOptionType.AdvertisingGoalProfit]: {
    value: AdvertisingGoal.Profit,
    displayI18nKey: I18nKey.GENERIC_ADVERTISING_GOAL_PROFIT,
  },
  [SelectFilterOptionType.AdvertisingGoalRoas]: {
    value: AdvertisingGoal.ROAS,
    displayI18nKey: I18nKey.GENERIC_ADVERTISING_GOAL_ROAS,
  },
  [SelectFilterOptionType.AdvertisingGoalLiquidate]: {
    value: AdvertisingGoal.Liquidate,
    displayI18nKey: I18nKey.GENERIC_ADVERTISING_GOAL_LIQUIDATE,
  },

  //Smart Structure
  [SelectFilterOptionType.SmartStructureBasic]: {
    value: SmartStructure.Basic,
    displayI18nKey: I18nKey.GENERIC_SMART_STRUCTURE_BASIC,
  },
  [SelectFilterOptionType.SmartStructureIntermediate]: {
    value: SmartStructure.Intermediate,
    displayI18nKey: I18nKey.GENERIC_SMART_STRUCTURE_INTERMEDIATE,
  },
  [SelectFilterOptionType.SmartStructureAdvanced]: {
    value: SmartStructure.Advanced,
    displayI18nKey: I18nKey.GENERIC_SMART_STRUCTURE_ADVANCED,
  },
  [SelectFilterOptionType.SmartStructureCustom]: {
    value: SmartStructure.Custom,
    displayI18nKey: I18nKey.GENERIC_SMART_STRUCTURE_CUSTOM,
  },

  // AI Bidding
  [SelectFilterOptionType.AiBiddingSmart]: {
    value: AiBiddingValues.Smart,
    displayI18nKey:
      I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING_SMART,
  },
  [SelectFilterOptionType.AiBiddingOnlyBidding]: {
    value: AiBiddingValues.Ai,
    displayI18nKey:
      I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING_BIDDING_ONLY,
  },
  [SelectFilterOptionType.AiBiddingNoAi]: {
    value: AiBiddingValues.NotEnabled,
    displayI18nKey:
      I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING_NO_AI,
  },

  // Adgroup status
  [SelectFilterOptionType.AdgroupStatusEnabled]: {
    value: AdGroupStatus.Enabled,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED,
  },
  [SelectFilterOptionType.AdgroupStatusDisabled]: {
    value: AdGroupStatus.Disabled,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_DISABLED,
  },

  // Campaign status
  [SelectFilterOptionType.CampaignStatusPaused]: {
    value: CampaignStatus.Paused,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PAUSED,
  },
  [SelectFilterOptionType.CampaignStatusCompleted]: {
    value: CampaignStatus.Completed,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_COMPLETED,
  },
  [SelectFilterOptionType.CampaignStatusRescheduled]: {
    value: CampaignStatus.Rescheduled,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_RESCHEDULED,
  },
  [SelectFilterOptionType.CampaignStatusLive]: {
    value: CampaignStatus.Live,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_LIVE,
  },
  [SelectFilterOptionType.CampaignStatusProposal]: {
    value: CampaignStatus.Proposal,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PROPOSAL,
  },
  [SelectFilterOptionType.CampaignStatusScheduled]: {
    value: CampaignStatus.Scheduled,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_SCHEDULED,
  },
  [SelectFilterOptionType.CampaignStatusEnabled]: {
    value: CampaignStatus.Enabled,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_ENABLED,
  },
  [SelectFilterOptionType.CampaignStatusArchived]: {
    value: CampaignStatus.Archived,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_ARCHIVED,
  },
  [SelectFilterOptionType.CampaignStatusPending]: {
    value: CampaignStatus.Pending,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_PENDING,
  },
  [SelectFilterOptionType.CampaignStatusDraft]: {
    value: CampaignStatus.Draft,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_DRAFT,
  },

  // Campaign tactic
  [SelectFilterOptionType.CampaignTacticLegacy]: {
    value: CampaignTactic.Legacy,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_LEGACY,
  },
  [SelectFilterOptionType.CampaignTacticProduct]: {
    value: CampaignTactic.Product,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PRODUCT,
  },
  [SelectFilterOptionType.CampaignTacticAudiences]: {
    value: CampaignTactic.Audiences,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AUDIENCES,
  },
  [SelectFilterOptionType.CampaignTacticViews]: {
    value: CampaignTactic.Views,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_VIEWS,
  },

  // Campaign targeting type
  [SelectFilterOptionType.CampaignTargetingTypeAuto]: {
    value: CampaignTargetingType.Auto,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AUTO,
  },
  [SelectFilterOptionType.CampaignTargetingTypeManual]: {
    value: CampaignTargetingType.Manual,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_MANUAL,
  },

  // Product ad status
  [SelectFilterOptionType.ProductAdStatusEnabled]: {
    value: ProductAdStatusOptions.Enabled.value,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED,
  },
  [SelectFilterOptionType.ProductAdStatusDisabled]: {
    value: ProductAdStatusOptions.Paused.value,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PAUSED,
  },
  [SelectFilterOptionType.ProductAdStatusArchived]: {
    value: ProductAdStatusOptions.Archived.value,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ARCHIVED,
  },

  // Amazon Bidding Strategy
  [SelectFilterOptionType.AmazonBiddingStrategyDynamicBidsDown]: {
    value: AmazonBiddingStrategy.DynamicBidsDownOnly,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_DOWN,
  },
  [SelectFilterOptionType.AmazonBiddingStrategyDynamicBidsUpAndDown]: {
    value: AmazonBiddingStrategy.DynamicBidsUpAndDown,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_UP_AND_DOWN,
  },
  [SelectFilterOptionType.AmazonBiddingStrategyDynamicBidsFixed]: {
    value: AmazonBiddingStrategy.FixedBid,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_FIXED,
  },

  // Amazon Bid Optimization
  [SelectFilterOptionType.AmazonBidOptimizationOn]: {
    value: AmazonBidOptimization.True,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BID_OPTIMIZATION_ON,
  },
  [SelectFilterOptionType.AmazonBidOptimizationOff]: {
    value: AmazonBidOptimization.False,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BID_OPTIMIZATION_OFF,
  },

  // Budget Rollover
  [SelectFilterOptionType.BudgetRolloverTrue]: {
    value: BudgetRollover.True,
    displayI18nKey: I18nKey.GENERAL_BOOLEAN_TRUE,
  },
  [SelectFilterOptionType.BudgetRolloverFalse]: {
    value: BudgetRollover.False,
    displayI18nKey: I18nKey.GENERAL_BOOLEAN_FALSE,
  },

  // Budget Type
  [SelectFilterOptionType.BudgetTypeDaily]: {
    value: BudgetType.Daily,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_DAILY,
  },
  [SelectFilterOptionType.BudgetTypeTotal]: {
    value: BudgetType.Total,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_TOTAL,
  },
  [SelectFilterOptionType.BudgetTypeBoth]: {
    value: BudgetType.Both,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_BOTH,
  },
  [SelectFilterOptionType.BudgetTypeLifetime]: {
    value: BudgetType.Lifetime,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_LIFETIME,
  },

  // Campaign Ad Format
  [SelectFilterOptionType.CampaignAdFormatProductCollection]: {
    value: CampaignAdFormat.ProductCollection,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_CAMPAIGN_AD_FORMAT_PRODUCT_COLLECTION,
  },
  [SelectFilterOptionType.CampaignAdFormatVideo]: {
    value: CampaignAdFormat.Video,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_CAMPAIGN_AD_FORMAT_VIDEO,
  },

  [SelectFilterOptionType.CampaignAdFormatStoreSpotlight]: {
    value: CampaignAdFormat.StoreSpotlight,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_CAMPAIGN_AD_FORMAT_STORE_SPOTLIGHT,
  },

  // Match Type
  [SelectFilterOptionType.MatchTypeExact]: {
    value: MatchType.Exact,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXACT,
  },
  [SelectFilterOptionType.MatchTypePhrase]: {
    value: MatchType.Phrase,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PHRASE,
  },
  [SelectFilterOptionType.MatchTypeBroad]: {
    value: MatchType.Broad,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BROAD,
  },
  [SelectFilterOptionType.MatchTypeNegativeExact]: {
    value: MatchType.NegativeExact,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_EXACT,
  },
  [SelectFilterOptionType.MatchTypeNegativePhrase]: {
    value: MatchType.NegativePhrase,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_PHRASE,
  },

  // Flywheel AI Enabled
  [SelectFilterOptionType.FlywheelAiAdded]: {
    value: FlywheelAIEnabled.FlywheelAIAdded,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_FLYWHEEL_AI_ADDED,
  },
  [SelectFilterOptionType.ManuallyAdded]: {
    value: FlywheelAIEnabled.ManuallyAdded,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_MANUALLY_ADDED,
  },

  // Target Status
  [SelectFilterOptionType.TargetStatusEnabled]: {
    value: TargetStatus.Enabled,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED,
  },
  [SelectFilterOptionType.TargetStatusPaused]: {
    value: TargetStatus.Paused,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PAUSED,
  },
  [SelectFilterOptionType.TargetStatusArchived]: {
    value: TargetStatus.Archived,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ARCHIVED,
  },
  [SelectFilterOptionType.TargetStatusDeleted]: {
    value: TargetStatus.Deleted,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_DELETED,
  },
  [SelectFilterOptionType.TargetStatusPending]: {
    value: TargetStatus.Pending,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PENDING,
  },

  // Bid Automation Status
  [SelectFilterOptionType.BidAutomationStatusActive]: {
    value: AutomationStatusFilterValue.Active,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ACTIVE,
  },
  [SelectFilterOptionType.BidAutomationStatusInactive]: {
    value: AutomationStatusFilterValue.Inactive,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_INACTIVE,
  },
  [SelectFilterOptionType.BidAutomationStatusUnset]: {
    value: AutomationStatusFilterValue.Unset,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_UNSET,
  },

  // Campaign Serving Status
  [SelectFilterOptionType.CampaignServingStatusAsinNotBuyable]: {
    value: CampaignServingStatus.AsinNotBuyable,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ASIN_NOT_BUYABLE,
  },
  [SelectFilterOptionType.CampaignServingStatusBillingError]: {
    value: CampaignServingStatus.BillingError,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_BILLING_ERROR,
  },
  [SelectFilterOptionType.CampaignServingStatusEnded]: {
    value: CampaignServingStatus.Ended,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ENDED,
  },
  [SelectFilterOptionType.CampaignServingStatusLandingPageNotAvailable]: {
    value: CampaignServingStatus.LandingPageNotAvailable,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_LANDING_PAGE_NOT_AVAILABLE,
  },
  [SelectFilterOptionType.CampaignServingStatusOutOfBudget]: {
    value: CampaignServingStatus.OutOfBudget,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_OUT_OF_BUDGET,
  },
  [SelectFilterOptionType.CampaignServingStatusPaused]: {
    value: CampaignServingStatus.Paused,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PAUSED,
  },
  [SelectFilterOptionType.CampaignServingStatusPendingReview]: {
    value: CampaignServingStatus.PendingReview,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PENDING_REVIEW,
  },
  [SelectFilterOptionType.CampaignServingStatusReady]: {
    value: CampaignServingStatus.Ready,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_READY,
  },
  [SelectFilterOptionType.CampaignServingStatusRejected]: {
    value: CampaignServingStatus.Rejected,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_REJECTED,
  },
  [SelectFilterOptionType.CampaignServingStatusDelivering]: {
    value: CampaignServingStatus.Delivering,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_DELIVERING,
  },
  [SelectFilterOptionType.CampaignServingStatusScheduled]: {
    value: CampaignServingStatus.Scheduled,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_SCHEDULED,
  },
  [SelectFilterOptionType.CampaignServingStatusTerminated]: {
    value: CampaignServingStatus.Terminated,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_TERMINATED,
  },
  [SelectFilterOptionType.CampaignServingStatusIneligible]: {
    value: CampaignServingStatus.Ineligible,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_INELIGIBLE,
  },
  [SelectFilterOptionType.CampaignServingStatusCampaignArchived]: {
    value: CampaignServingStatus.CampaignArchived,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_ARCHIVED,
  },
  [SelectFilterOptionType.CampaignServingStatusCampaignAdsNotDelivering]: {
    value: CampaignServingStatus.CampaignAdsNotDelivering,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_ADS_NOT_DELIVERING,
  },
  [SelectFilterOptionType.CampaignServingStatusPortfolioOutOfBudget]: {
    value: CampaignServingStatus.PortfolioOutOfBudget,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_OUT_OF_BUDGET,
  },
  [SelectFilterOptionType.CampaignServingStatusAccountOutOfBudget]: {
    value: CampaignServingStatus.AccountOutOfBudget,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ACCOUNT_OUT_OF_BUDGET,
  },
  [SelectFilterOptionType.CampaignServingStatusPendingStartDate]: {
    value: CampaignServingStatus.PendingStartDate,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PENDING_START_DATE,
  },
  [SelectFilterOptionType.CampaignServingStatusPortfolioEnded]: {
    value: CampaignServingStatus.PortfolioEnded,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_ENDED,
  },
  [SelectFilterOptionType.CampaignServingStatusCampaignStatusEnabled]: {
    value: CampaignServingStatus.CampaignStatusEnabled,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_STATUS_ENABLED,
  },
  [SelectFilterOptionType.CampaignServingStatusAdvertiserPaymentFailure]: {
    value: CampaignServingStatus.AdvertiserPaymentFailure,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ADVERTISER_PAYMENT_FAILURE,
  },
  [SelectFilterOptionType.CampaignServingStatusUnknown]: {
    value: CampaignServingStatus.Unknown,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_UNKNOWN,
  },
  [SelectFilterOptionType.CampaignServingStatusCampaignIncomplete]: {
    value: CampaignServingStatus.CampaignIncomplete,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_INCOMPLETE,
  },
  [SelectFilterOptionType.CampaignServingStatusPortfolioPendingStartDate]: {
    value: CampaignServingStatus.PortfolioPendingStartDate,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_PENDING_START_DATE,
  },
  [SelectFilterOptionType.CampaignServingStatusAdvertiserArchived]: {
    value: CampaignServingStatus.AdvertiserArchived,
    displayI18nKey:
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_ADVERTISER_ARCHIVED,
  },

  // Expression Type
  [SelectFilterOptionType.ExpressionTypeAsinAgeRangeSameAs]: {
    value: ExpressionType.AsinAgeRangeSameAs,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_AGE_RANGE_SAME_AS,
  },
  [SelectFilterOptionType.ExpressionTypeAsinBrandSameAs]: {
    value: ExpressionType.AsinBrandSameAs,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_BRAND_SAME_AS,
  },
  [SelectFilterOptionType.ExpressionTypeAsinCategorySameAs]: {
    value: ExpressionType.AsinCategorySameAs,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_CATEGORY_SAME_AS,
  },
  [SelectFilterOptionType.ExpressionTypeAsinGenreSameAs]: {
    value: ExpressionType.AsinGenreSameAs,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_GENRE_SAME_AS,
  },
  [SelectFilterOptionType.ExpressionTypeAsinIsPrimeShippingAvailable]: {
    value: ExpressionType.AsinIsPrimeShippingAvailable,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_IS_PRIME_SHIPPING_AVAILABLE,
  },
  [SelectFilterOptionType.ExpressionTypeAsinPriceBetween]: {
    value: ExpressionType.AsinPriceBetween,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_BETWEEN,
  },
  [SelectFilterOptionType.ExpressionTypeAsinPriceGreaterThan]: {
    value: ExpressionType.AsinPriceGreaterThan,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_GREATER_THAN,
  },
  [SelectFilterOptionType.ExpressionTypeAsinPriceLessThan]: {
    value: ExpressionType.AsinPriceLessThan,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_LESS_THAN,
  },
  [SelectFilterOptionType.ExpressionTypeAsinReviewRatingBetween]: {
    value: ExpressionType.AsinReviewRatingBetween,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_BETWEEN,
  },
  [SelectFilterOptionType.ExpressionTypeAsinReviewRatingGreaterThan]: {
    value: ExpressionType.AsinReviewRatingGreaterThan,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_GREATER_THAN,
  },
  [SelectFilterOptionType.ExpressionTypeAsinReviewRatingLessThan]: {
    value: ExpressionType.AsinReviewRatingLessThan,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_LESS_THAN,
  },
  [SelectFilterOptionType.ExpressionTypeAsinSameAs]: {
    value: ExpressionType.AsinSameAs,
    displayI18nKey:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_SAME_AS,
  },
  [SelectFilterOptionType.CampaignPlacementStatusExcluded]: {
    value: PlacementStatus.Excluded,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS_FILTER_EXCLUDED,
  },
  [SelectFilterOptionType.CampaignPlacementStatusIncluded]: {
    value: PlacementStatus.Included,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS_FILTER_INCLUDED,
  },

  // Added By
  [SelectFilterOptionType.FlywheelAutomated]: {
    value: AddedBy.FlywheelAutomated,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_AI_REC,
  },
  [SelectFilterOptionType.FlywheelAccepted]: {
    value: AddedBy.FlywheelAccepted,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_ADDEDBY_TRE_REC,
  },
  [SelectFilterOptionType.FlywheelOnDemand]: {
    value: AddedBy.FlywheelOnDemand,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_ON_DEMAND,
  },
  [SelectFilterOptionType.External]: {
    value: AddedBy.External,
    displayI18nKey: I18nKey.GENERIC_EXTERNAL,
  },
  [SelectFilterOptionType.ChatGPT]: {
    value: AddedBy.ChatGPT,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_ADDEDBY_CHAT_GPT,
  },
  [SelectFilterOptionType.FlywheelAutoNegate]: {
    value: AddedBy.FlywheelAutoNegate,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_AUTO_NEGATE,
  },

  // Campaign cost type
  [SelectFilterOptionType.CampaignCostTypeCPC]: {
    value: CampaignCostType.CPC,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_COST_TYPE_CPC,
  },
  [SelectFilterOptionType.CampaignCostTypeVCPM]: {
    value: CampaignCostType.VCPM,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_COST_TYPE_VCPM,
  },

  // Keyword review status
  [SelectFilterOptionType.KeywordReviewStatusApproved]: {
    value: KeywordReviewStatusType.Approved,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_APPROVED,
  },
  [SelectFilterOptionType.KeywordReviewStatusPending]: {
    value: KeywordReviewStatusType.Pending,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
  },
  [SelectFilterOptionType.KeywordReviewStatusRejected]: {
    value: KeywordReviewStatusType.Rejected,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_REJECTED,
  },

  // Product review status
  [SelectFilterOptionType.ProductReviewStatusApproved]: {
    value: ProductReviewStatusType.Approved,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_APPROVED,
  },
  [SelectFilterOptionType.ProductReviewStatusPending]: {
    value: ProductReviewStatusType.Pending,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
  },
  [SelectFilterOptionType.ProductReviewStatusRejected]: {
    value: ProductReviewStatusType.Rejected,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_REJECTED,
  },

  // Ad group review status
  [SelectFilterOptionType.AdGroupReviewStatusPending]: {
    value: AdGroupReviewStatusType.Pending,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
  },
  [SelectFilterOptionType.AdGroupReviewStatusInProgress]: {
    value: AdGroupReviewStatusType.InProgress,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_INPROGRESS,
  },
  [SelectFilterOptionType.AdGroupReviewStatusCompleted]: {
    value: AdGroupReviewStatusType.Complete,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_COMPLETED,
  },
  [SelectFilterOptionType.AdGroupReviewStatusCancelled]: {
    value: AdGroupReviewStatusType.Cancelled,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_CANCELLED,
  },

  //Profile status types
  [SelectFilterOptionType.ProfileStatusEnabled]: {
    value: ProfileStatus.Enabled,
    displayI18nKey: I18nKey.ADS_MANAGER_PROFILE_STATUS_ENABLED,
  },
  [SelectFilterOptionType.ProfileStatusDisabled]: {
    value: ProfileStatus.Disabled,
    displayI18nKey: I18nKey.ADS_MANAGER_PROFILE_STATUS_DISABLED,
  },

  // Profile Review status types
  [SelectFilterOptionType.ProfileReviewStatusPending]: {
    value: ReviewStatus.Pending,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING,
  },
  [SelectFilterOptionType.ProfileReviewStatusApproved]: {
    value: ReviewStatus.Approved,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_APPROVED,
  },
  [SelectFilterOptionType.ProfileReviewStatusRejected]: {
    value: ReviewStatus.Rejected,
    displayI18nKey: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_REJECTED,
  },

  // Target Labels
  [SelectFilterOptionType.TargetLabelNegTarget]: {
    value: TargetLabels.NegTarget,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_LABELS_NEG_TARGET,
  },
  [SelectFilterOptionType.NoTag]: {
    value: TargetTypeFilter.NoLabel,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_LABELS_NO_TAG,
  },
  [SelectFilterOptionType.TargetTypeCompetitor]: {
    value: TargetTypeFilter.Competitor,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_COMPETITOR,
  },
  [SelectFilterOptionType.TargetTypeGeneric]: {
    value: TargetTypeFilter.Generic,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_GENERIC,
  },
  [SelectFilterOptionType.TargetTypeBrand]: {
    value: TargetTypeFilter.Brand,
    displayI18nKey: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BRAND,
  },
  [SelectFilterOptionType.TargetTypeAuto]: {
    value: TargetTypeFilter.Auto,
    displayI18nKey: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_AUTO,
  },
  [SelectFilterOptionType.TargetLabelAIAdded]: {
    value: TargetLabels.AIAdded,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_LABELS_AI_ADDED,
  },
  [SelectFilterOptionType.TargetLabelNoLabels]: {
    value: TargetLabels.NoLabels,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_LABELS_NO_LABELS,
  },

  // Target type
  [SelectFilterOptionType.Keyword]: {
    value: KeywordTargetType.Keyword,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_TYPE_KEYWORD,
  },
  [SelectFilterOptionType.NegativeKeyword]: {
    value: KeywordTargetType.NegativeKeyword,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_TYPE_NEGATIVE_KEYWORD,
  },
  [SelectFilterOptionType.CampaignNegativeKeyword]: {
    value: KeywordTargetType.CampaignNegativeKeyword,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_TYPE_CAMPAIGN_NEGATIVE_KEYWORD,
  },
  [SelectFilterOptionType.NegativeProductTarget]: {
    value: KeywordTargetType.NegativeProductTarget,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_TYPE_NEGATIVE_PRODUCT_TARGET,
  },
  [SelectFilterOptionType.AudienceTarget]: {
    value: KeywordTargetType.AudienceTarget,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_TYPE_AUDIENCE_TARGET,
  },
  [SelectFilterOptionType.ContextualTarget]: {
    value: KeywordTargetType.ProductAttribute,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_TYPE_PRODUCT_ATTRIBUTE,
  },
  [SelectFilterOptionType.ProductTarget]: {
    value: KeywordTargetType.ProductTarget,
    displayI18nKey: I18nKey.ADS_MANAGER_TARGET_TYPE_PRODUCT_TARGET,
  },

  // AI Recommended Keyword
  [SelectFilterOptionType.AddAutomatically]: {
    value: AiRecommendedKeywordType.AddAutomatically,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGN_AI_RECOMMENDED_KEYWORD_ADD_AUTOMATICALLY,
  },
  [SelectFilterOptionType.ReviewRecommendations]: {
    value: AiRecommendedKeywordType.ReviewRecommendations,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CAMPAIGN_AI_RECOMMENDED_KEYWORD_REVIEW_RECOMMENDATION,
  },

  // Creative Type
  [SelectFilterOptionType.CreativeTypeProductCollection]: {
    value: CreativeType.ProductCollection,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_TYPE_PRODUCT_COLLECTION,
  },

  [SelectFilterOptionType.CreativeTypeVideo]: {
    value: CreativeType.Video,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_TYPE_VIDEO,
  },

  [SelectFilterOptionType.CreativeTypeStoreSpotlight]: {
    value: CreativeType.StoreSpotlight,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_TYPE_STORE_SPOTLIGHT,
  },

  // Creative Serving Status
  [SelectFilterOptionType.CreativeServingStatusAdStatusLive]: {
    value: CreativeServingStatus.AdStatusLive,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_STATUS_LIVE,
  },
  [SelectFilterOptionType.CreativeServingStatusAdPolicingPendingReview]: {
    value: CreativeServingStatus.AdPolicingPendingReview,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_POLICING_PENDING_REVIEW,
  },
  [SelectFilterOptionType.CreativeServingStatusAdPolicingSuspended]: {
    value: CreativeServingStatus.AdPolicingSuspended,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_POLICING_SUSPENDED,
  },
  [SelectFilterOptionType.CreativeServingStatusAdPaused]: {
    value: CreativeServingStatus.AdPaused,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_PAUSED,
  },
  [SelectFilterOptionType.CreativeServingStatusAdArchived]: {
    value: CreativeServingStatus.AdArchived,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_ARCHIVED,
  },
  [SelectFilterOptionType.CreativeServingStatusAdGroupStatusEnabled]: {
    value: CreativeServingStatus.AdGroupStatusEnabled,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_STATUS_ENABLED,
  },
  [SelectFilterOptionType.CreativeServingStatusAdGroupPaused]: {
    value: CreativeServingStatus.AdGroupPaused,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_PAUSED,
  },
  [SelectFilterOptionType.CreativeServingStatusAdGroupArchived]: {
    value: CreativeServingStatus.AdGroupArchived,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_ARCHIVED,
  },
  [SelectFilterOptionType.CreativeServingStatusAdGroupIncomplete]: {
    value: CreativeServingStatus.AdGroupIncomplete,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_INCOMPLETE,
  },
  [SelectFilterOptionType.CreativeServingStatusAdGroupPolicingPendingReview]: {
    value: CreativeServingStatus.AdGroupPolicingPendingReview,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_POLICING_PENDING_REVIEW,
  },
  [SelectFilterOptionType.CreativeServingStatusAdGroupPolicingCreativeRejected]:
    {
      value: CreativeServingStatus.AdGroupPolicingCreativeRejected,
      displayI18nKey:
        I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_POLICING_CREATIVE_REJECTED,
    },
  [SelectFilterOptionType.CreativeServingStatusAdGroupLowBid]: {
    value: CreativeServingStatus.AdGroupLowBid,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_LOW_BID,
  },
  [SelectFilterOptionType.CreativeServingStatusAdvertiserStatusEnabled]: {
    value: CreativeServingStatus.AdvertiserStatusEnabled,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_STATUS_ENABLED,
  },
  [SelectFilterOptionType.CreativeServingStatusAdvertiserPolicingPendingReview]:
    {
      value: CreativeServingStatus.AdvertiserPolicingPendingReview,
      displayI18nKey:
        I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_POLICING_PENDING_REVIEW,
    },
  [SelectFilterOptionType.CreativeServingStatusAdvertiserPolicingSuspended]: {
    value: CreativeServingStatus.AdvertiserPolicingSuspended,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_POLICING_SUSPENDED,
  },
  [SelectFilterOptionType.CreativeServingStatusAdvertiserPaused]: {
    value: CreativeServingStatus.AdvertiserPaused,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_PAUSED,
  },
  [SelectFilterOptionType.CreativeServingStatusAdvertiserArchived]: {
    value: CreativeServingStatus.AdvertiserArchived,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_ARCHIVED,
  },
  [SelectFilterOptionType.CreativeServingStatusAdvertiserPaymentFailure]: {
    value: CreativeServingStatus.AdvertiserPaymentFailure,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_PAYMENT_FAILURE,
  },
  [SelectFilterOptionType.CreativeServingStatusAdvertiserAccountOutOfBudget]: {
    value: CreativeServingStatus.AdvertiserAccountOutOfBudget,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_ACCOUNT_OUT_OF_BUDGET,
  },
  [SelectFilterOptionType.CreativeServingStatusAdvertiserOutOfPrepayBalance]: {
    value: CreativeServingStatus.AdvertiserOutOfPrepayBalance,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_OUT_OF_PREPAY_BALANCE,
  },
  [SelectFilterOptionType.CreativeServingStatusAdvertiserExceedSpendsLimit]: {
    value: CreativeServingStatus.AdvertiserExceedSpendsLimit,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_EXCEED_SPENDS_LIMIT,
  },
  [SelectFilterOptionType.CreativeServingStatusCampaignStatusEnabled]: {
    value: CreativeServingStatus.CampaignStatusEnabled,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_STATUS_ENABLED,
  },
  [SelectFilterOptionType.CreativeServingStatusCampaignPaused]: {
    value: CreativeServingStatus.CampaignPaused,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_PAUSED,
  },
  [SelectFilterOptionType.CreativeServingStatusCampaignArchived]: {
    value: CreativeServingStatus.CampaignArchived,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_ARCHIVED,
  },
  [SelectFilterOptionType.CreativeServingStatusCampaignIncomplete]: {
    value: CreativeServingStatus.CampaignIncomplete,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_INCOMPLETE,
  },
  [SelectFilterOptionType.CreativeServingStatusCampaignOutOfBudget]: {
    value: CreativeServingStatus.CampaignOutOfBudget,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_OUT_OF_BUDGET,
  },
  [SelectFilterOptionType.CreativeServingStatusPortfolioStatusEnabled]: {
    value: CreativeServingStatus.PortfolioStatusEnabled,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_STATUS_ENABLED,
  },
  [SelectFilterOptionType.CreativeServingStatusPortfolioPaused]: {
    value: CreativeServingStatus.PortfolioPaused,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_PAUSED,
  },
  [SelectFilterOptionType.CreativeServingStatusPortfolioArchived]: {
    value: CreativeServingStatus.PortfolioArchived,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_ARCHIVED,
  },
  [SelectFilterOptionType.CreativeServingStatusPortfolioOutOfBudget]: {
    value: CreativeServingStatus.PortfolioOutOfBudget,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_OUT_OF_BUDGET,
  },
  [SelectFilterOptionType.CreativeServingStatusPortfolioPendingStartDate]: {
    value: CreativeServingStatus.PortfolioPendingStartDate,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_PENDING_START_DATE,
  },
  [SelectFilterOptionType.CreativeServingStatusPortfolioEnded]: {
    value: CreativeServingStatus.PortfolioEnded,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_ENDED,
  },
  [SelectFilterOptionType.CreativeServingStatusIneligible]: {
    value: CreativeServingStatus.Ineligible,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_INELIGIBLE,
  },
  [SelectFilterOptionType.CreativeServingStatusEligible]: {
    value: CreativeServingStatus.Eligible,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ELIGIBLE,
  },
  [SelectFilterOptionType.CreativeServingStatusEnded]: {
    value: CreativeServingStatus.Ended,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_ENDED,
  },
  [SelectFilterOptionType.CreativeServingStatusPendingReview]: {
    value: CreativeServingStatus.PendingReview,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PENDING_REVIEW,
  },
  [SelectFilterOptionType.CreativeServingStatusPendingStartDate]: {
    value: CreativeServingStatus.PendingStartDate,
    displayI18nKey:
      I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_PENDING_START_DATE,
  },
  [SelectFilterOptionType.CreativeServingStatusRejected]: {
    value: CreativeServingStatus.Rejected,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_REJECTED,
  },
  [SelectFilterOptionType.CreativeServingStatusUnknown]: {
    value: CreativeServingStatus.Unknown,
    displayI18nKey: I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_UNKNOWN,
  },

  [SelectFilterOptionType.DestinationAdGroupTargetingTypeAuto]: {
    value: TargetingType.Auto,
    displayI18nKey: I18nKey.GENERIC_AUTO,
  },
  [SelectFilterOptionType.DestinationAdGroupTargetingTypeManual]: {
    value: TargetingType.Manual,
    displayI18nKey: I18nKey.GENERIC_MANUAL,
  },

  [SelectFilterOptionType.DestinationAdGroupTagBrand]: {
    value: TargetSegments.Brand,
    displayI18nKey: I18nKey.GENERIC_TARGET_SEGMENT_BRAND,
  },
  [SelectFilterOptionType.DestinationAdGroupTagCompetitor]: {
    value: TargetSegments.Competitor,
    displayI18nKey: I18nKey.GENERIC_TARGET_SEGMENT_COMPTETITOR,
  },
  [SelectFilterOptionType.DestinationAdGroupTagGeneric]: {
    value: TargetSegments.Generic,
    displayI18nKey: I18nKey.GENERIC_TARGET_SEGMENT_GENERIC,
  },
  [SelectFilterOptionType.DestinationAdGroupTagNoTag]: {
    value: AD_GROUP_NO_TAG_FILTER_OPTION,
    displayI18nKey: I18nKey.GENERIC_TARGET_SEGMENT_NO_TAG,
  },
};
