import {
  BadgeDisplayMode,
  BadgeType,
  BulkEditModalElementType,
  BulkEditSlideOut,
  BulkEditValues,
  ButtonState,
  EditControlProps,
} from '@teikametrics/tm-design-system';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  KeywordActionColumns,
  KeywordAdgroupInfo,
  TargetSegments,
  TargetingType,
} from '../../../../lib/types/KeywordAction';
import { NewDestinationAdGroupModal } from './NewDestinationAdGroupModal';
import { getTargetSegmentPills } from './columns/createInAdGroup';
import { getMatchTypeOptions } from './columns/matchType';

export interface RecommendationTableBulkEditModalProps {
  readonly onClose: () => void;
  readonly onSubmit: (values: BulkEditValues) => void;
  readonly merchantCountryId: string;
  readonly isNegativeKeyword?: boolean;
  readonly isPat?: boolean;
}

export const RecommendationTableBulkEditModal: React.FC<
  RecommendationTableBulkEditModalProps
> = ({ onClose, onSubmit, merchantCountryId, isNegativeKeyword, isPat }) => {
  const intl = useIntl();
  const [selectedValues, setSelectedValues] = useState<BulkEditValues>({});
  const [isAdGroupSelectionDialogOpen, setIsAdGroupSelectionDialogOpen] =
    useState(false);
  const [suggestedAdgroup, setSuggestedAdgroup] =
    useState<KeywordAdgroupInfo>();

  const [
    EDIT_SELECTED,
    CANCEL,
    APPLY,
    MATCH_TYPE,
    CHOOSE,
    DESTINATION_AD_GROUP,
  ] = [
    I18nKey.KEYWORD_RECOMMENDATIONS_EDIT_SELECTED,
    I18nKey.GENERIC_CANCEL_LABEL,
    I18nKey.GENERIC_APPLY,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_MATCH_TYPE_HEADER,
    I18nKey.GENERIC_CHOOSE,
    I18nKey.KEYWORD_RECOMMENDATIONS_DESTINATION_AD_GROUP,
  ].map((id) => intl.formatMessage({ id }));

  const bulkEditControls: EditControlProps[] = [
    {
      controls: [
        {
          type: BulkEditModalElementType.Select,
          options: getMatchTypeOptions(intl, isNegativeKeyword, isPat),
          placeholder: CHOOSE,
          value: undefined,
          columnKey: KeywordActionColumns.MATCH_TYPE,
        },
      ],
      headerText: MATCH_TYPE,
      selected: selectedValues?.[KeywordActionColumns.MATCH_TYPE],
      onSelect: (newValue: boolean) => {
        setSelectedValues((value: BulkEditValues) => {
          return {
            ...value,
            [KeywordActionColumns.MATCH_TYPE]: newValue,
          };
        });
      },
    },
    {
      controls: [
        {
          type: BulkEditModalElementType.SuggestedAdgroup,
          value: undefined,
          columnKey: KeywordActionColumns.CAMPAIGN_ADGROUP_NAME,
          element: {
            suggestedAdgroup: !!suggestedAdgroup && {
              campaignName: suggestedAdgroup.campaign?.name,
              adGroupName: suggestedAdgroup.name,
              targetingType: {
                badgeType:
                  suggestedAdgroup.campaign?.targetingType ===
                  TargetingType.Auto
                    ? BadgeType.CampaignTypeAuto
                    : BadgeType.CampaignTypeManual,
                dataTestId: 'kwa_createInAdgroup',
                badgeNameDisplayMode: BadgeDisplayMode.OnlyInitial,
                className: 'mr-4',
              },
              targetSegments: getTargetSegmentPills(
                suggestedAdgroup?.targetSegments as TargetSegments[]
              ),
            },
            onClick: () => setIsAdGroupSelectionDialogOpen(true),
            onReset: () => setSuggestedAdgroup(undefined),
          },
        },
      ],
      headerText: DESTINATION_AD_GROUP,
      selected: selectedValues?.[KeywordActionColumns.CAMPAIGN_ADGROUP_NAME],
      onSelect: (newValue: boolean) => {
        setSelectedValues((value: BulkEditValues) => {
          return {
            ...value,
            [KeywordActionColumns.CAMPAIGN_ADGROUP_NAME]: newValue,
          };
        });
      },
    },
  ];

  const onDialogClose = () => {
    setSelectedValues({});
    onClose();
  };

  const onAdGroupSelectionDialogClose = () => {
    setIsAdGroupSelectionDialogOpen(false);
  };

  const onAdGroupSelectionApply = (info: KeywordAdgroupInfo) => {
    setSuggestedAdgroup(info);
  };

  const onBulkEditSlideOutSubmit = (bulkEditValues: BulkEditValues) => {
    if (
      selectedValues[KeywordActionColumns.CAMPAIGN_ADGROUP_NAME] &&
      suggestedAdgroup
    ) {
      onSubmit({
        ...bulkEditValues,
        campaign: suggestedAdgroup.campaign,
        adGroup: suggestedAdgroup,
      });
    }
    if (selectedValues[KeywordActionColumns.MATCH_TYPE]) {
      onSubmit({
        ...bulkEditValues,
      });
    }
    onDialogClose();
  };

  return (
    <div>
      <BulkEditSlideOut
        isOpen
        onClose={onDialogClose}
        headerText={EDIT_SELECTED}
        secondaryButtonText={CANCEL}
        primaryButtonText={APPLY}
        editControls={bulkEditControls}
        onSubmit={onBulkEditSlideOutSubmit}
        onCancel={onDialogClose}
        valuesHandledExternally={
          !selectedValues[KeywordActionColumns.MATCH_TYPE]
        }
        primaryButtonState={
          (selectedValues[KeywordActionColumns.CAMPAIGN_ADGROUP_NAME] &&
            suggestedAdgroup) ||
          selectedValues[KeywordActionColumns.MATCH_TYPE]
            ? ButtonState.Enabled
            : ButtonState.Disabled
        }
      />
      {isAdGroupSelectionDialogOpen && (
        <NewDestinationAdGroupModal
          isOpen={isAdGroupSelectionDialogOpen}
          keyword="Destination Ad Group"
          merchantCountryId={merchantCountryId}
          onApplyChange={onAdGroupSelectionApply}
          defaultAdGroup={suggestedAdgroup}
          isNegativeKeyword={isNegativeKeyword}
          onClose={onAdGroupSelectionDialogClose}
        />
      )}
    </div>
  );
};
