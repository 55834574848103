import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { AdGroupDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { ADGROUPS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  merchantCountry,
  ...adGroupDetails
}) => {
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  return makeCurrencyColumn<AdGroupDetails>(
    ({ adGroupDetails: { campaignTotalBudget } }) =>
      campaignTotalBudget && {
        amount: Number(campaignTotalBudget.amount),
        currency: currencyCode || campaignTotalBudget.currency || CURRENCY_CODE,
      },
    adGroupDetails.pendingFields?.includes(ADGROUPS_API_COLUMN_NAME.TotalBudget)
  )(adGroupDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const totalBudgetColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.TotalBudget,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_TOTAL_BUDGET,
  RowCellElement,
  gridColumnWidth: '160px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const totalBudgetFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    ADGROUPS_API_COLUMN_NAME.TotalBudget,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_TOTAL_BUDGET,
    currency,
    isValidNumber(0)
  );
