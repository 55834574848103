import {
  AlertTriangleIcon,
  Icon,
  IconSize,
  TextLink,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../../lib/types/I18nKey';

interface ErrorStateProps {
  readonly onLinkClick: () => void;
}

export const ErrorState: React.FC<ErrorStateProps> = (props) => {
  const intl = useIntl();

  const messageTitle = intl.formatMessage({
    id: I18nKey.TABLE_API_ERROR_TITLE,
  });

  const messageDescription = intl.formatMessage({
    id: I18nKey.TABLE_API_ERROR_DESCRIPTION,
  });

  return (
    <div className="flex flex-col w-full justify-center items-center border border-solid border-grey-200 rounded-8 p-16 mb-8">
      <div
        className={classNames(
          'w-56 h-56',
          'bg-purple-100',
          'rounded-full',
          'flex justify-center items-center'
        )}
      >
        <Icon
          svg={AlertTriangleIcon}
          size={IconSize.Large}
          className="text-grey-500"
        />
      </div>
      <h3 className="flex text-base leading-normal font-semibold text-grey-900 capitalize">
        {messageTitle}
      </h3>
      <label className="flex text-sm text-grey-500 leading-normal font-normal justify-center">
        {messageDescription}
      </label>
      <TextLink
        textLabel={intl.formatMessage({
          id: I18nKey.TABLE_API_ERROR_TRY_AGAIN,
        })}
      />
    </div>
  );
};

ErrorState.displayName = 'ErrorState';
