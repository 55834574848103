import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';
import first from 'lodash/first';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React from 'react';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  FlywheelSalesChannel,
  REQUEST_DATE_FORMAT,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import {
  NEGETIVE_TARGETS_MATCH_TYPE,
  TARGETS_API_COLUMN_NAME,
  getPerformanceMetricValue,
  isCampaignAdFormatProductCollectionOrSpotlight,
} from '../utils';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) =>
  makeCurrencyColumn<TargetsDetails>(
    ({
      targetDetails: { startDate, matchType, campaignAdFormat },
      targetPerformance: { newToBrandSales },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        newToBrandSales?.amount ? Number(newToBrandSales?.amount) : undefined,
        matchType && NEGETIVE_TARGETS_MATCH_TYPE.includes(matchType)
      );

      const { adTypes, salesChannel } = props;
      const adType = first(adTypes);

      if (
        adType === AdType.SponsoredBrands &&
        salesChannel === FlywheelSalesChannel.Amazon
      ) {
        return (
          (isCampaignAdFormatProductCollectionOrSpotlight(campaignAdFormat) &&
            !isNil(performanceMetricValue) && {
              amount: performanceMetricValue,
              currency:
                getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
                newToBrandSales?.currency ||
                CURRENCY_CODE,
            }) ||
          undefined
        );
      }

      return isNil(performanceMetricValue)
        ? undefined
        : {
            amount: performanceMetricValue,
            currency:
              getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
              newToBrandSales?.currency ||
              CURRENCY_CODE,
          };
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const newToBrandAdSalesColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.NewToBrandSales,
  isSortable: true,
  RowCellElement,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES,
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
  gridColumnWidth: '130px',
};
