import { useContext, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { getCurrentAccountPermissions } from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { SellerType } from '../../modules/account/containers/utils';
import { NavPaths } from '../../NavPaths';
import { Merchants } from '../../modules/account/components/constants';

export const useSpapi = () => {
  const userContext = useContext<UserContextState>(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const famApiClient = createFAMApiClient(userContext.userInfo.idToken!);
  const accountInfo = getCurrentAccountPermissions(
    userContext.userInfo.userDetails
  )!;

  const sendOauthCode = async (
    regionId: string,
    sellingPartnerId: string,
    merchantType: string,
    spApiOauthCode: string
  ) => {
    await famApiClient.amazonSellingPartnerConnect(
      accountInfo.account.id,
      regionId,
      sellingPartnerId,
      merchantType,
      spApiOauthCode
    );

    navigate(NavPaths.Compass);
  };

  const findRegionId = (url: string) => {
    const foundMerchant = Merchants.filter((item) => {
      return item.url?.find((link) => url.includes(link));
    });
    return foundMerchant[0].regionId!;
  };

  useEffect(() => {
    if (location.search.includes('amazon_callback_uri')) {
      let params = new URLSearchParams(location.search);
      const vendorType = location.search.includes('sellercentral')
        ? SellerType['3P']
        : SellerType['1P'];
      const foundRegionId = findRegionId(window.location.href);

      const urlParams = { vendorType, regionId: foundRegionId };

      window.location.href =
        params.get('amazon_callback_uri') +
        `?redirect_uri=${window.location.origin}/sp/callback` +
        `&amazon_state=${params.get('amazon_state')}` +
        `&state=${JSON.stringify(urlParams)}`;
    }

    if (location.search.includes('spapi_oauth_code')) {
      let params = new URLSearchParams(location.search);
      const state = JSON.parse(params.get('state')!);

      sendOauthCode(
        state.regionId,
        params.get('selling_partner_id')!,
        state.vendorType,
        params.get('spapi_oauth_code')!
      );
    }
  }, [location.pathname]);
};
