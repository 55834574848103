import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  MultiSelectDataFieldFilter,
  MultiSelectOption,
  Placement,
  SelectOptionProps,
  SelectState,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupReviewStatus,
  AdGroupReviewStatusType,
  AdGroupStatus,
  AdLevel,
  AdType,
  CampaignStatus,
  FlywheelSalesChannel,
  MatchType,
  TargetStatus,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_TARGETS_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  TARGETS_API_COLUMN_NAME,
  TargetStatusMapping,
  TargetStatusOptions,
  getCurrentRowIndexForTargetTable,
  getTooltipContentForArchivedEntity,
  isAdGroupStatusArchived,
  isCampaignStatusArchived,
  isNegativeCampaignTarget,
  isNegativeKeywordTarget,
  isNegativeProductTarget,
  isTargetStatusArchived,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const isValidTargetStatusOption =
  (selectedAdType?: AdType, matchType?: MatchType, entityType?: string) =>
  (option: SelectOptionProps<string | number | undefined>) => {
    if (
      isNil(selectedAdType) ||
      ![AdType.SponsoredBrands, AdType.SponsoredDisplay].includes(
        selectedAdType
      ) ||
      (!isNegativeKeywordTarget(matchType) &&
        !isNegativeProductTarget(entityType))
    ) {
      return true;
    }

    return option.value !== TargetStatusOptions.Paused.value;
  };

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = ({
  targetPerformance,
  targetDetails,
  targetId,
  channelSettings,
  isEditMode,
  pendingFields,
  selectedAdType,
  salesChannel,
  adLevel,
}) => {
  const isCampaignNegativeTarget = isNegativeCampaignTarget(
    targetDetails.entityType
  );

  const targetsData: TargetsDetails = {
    targetPerformance,
    targetDetails,
    targetId,
    channelSettings,
    pendingFields,
  };

  const updatedTargetStatus = useSelector<WithTable<TargetsDetails>, string>(
    ({ tableState }) => {
      return tableSelectors.getCellSelector(
        targetId,
        TARGETS_API_COLUMN_NAME.TargetStatus
      )(tableState, ADS_MANAGER_TARGETS_TABLE_ID);
    }
  );

  const currentTargetStatus = channelSettings.status;

  const targetStatus = updatedTargetStatus ?? currentTargetStatus;

  const dispatch = useDispatch();
  const intl = useIntl();

  const selectedSalesChannel = salesChannel;
  const adType = selectedAdType;
  const selectedAdLevel = adLevel as AdLevel;

  const currentRowIndex = useSelector<WithTable<TargetsDetails>, number>(
    getCurrentRowIndexForTargetTable(targetsData)
  );

  const filteredTargetStatusOption = getTargetStatusOptions(
    intl,
    isCampaignNegativeTarget
  ).find(
    (targetStatusOption: TargetStatusOptionsProps) =>
      targetStatusOption.salesChannels.includes(selectedSalesChannel) &&
      targetStatusOption.adTypes.includes(adType) &&
      targetStatusOption.adLevel.includes(selectedAdLevel)
  );

  const dropDownStatus = getDropdownStatus(
    intl,
    selectedAdType,
    targetsData.targetDetails.campaignStatus,
    targetsData.targetDetails.adGroupStatus,
    targetsData.channelSettings.status,
    targetDetails.adGroupReviewStatus,
    selectedAdLevel
  );

  if (isEditMode) {
    return makeSelectColumn(
      (data: TargetsDetails) => ({
        options:
          filteredTargetStatusOption?.options.filter(
            isValidTargetStatusOption(
              selectedAdType,
              targetDetails.matchType,
              targetDetails.entityType
            )
          ) || [],
        placeholder: '',
        value: targetStatus,
        state: dropDownStatus.status,
        className: 'w-110',
        isDirty: targetStatus !== currentTargetStatus,
        onChange: (value: string) => {
          dispatch(
            tableActions.updateCell({
              tableId: ADS_MANAGER_TARGETS_TABLE_ID,
              columnName: TARGETS_API_COLUMN_NAME.TargetStatus,
              rowId: data.targetId,
              value,
              existingValue: currentTargetStatus as string,
            })
          );
        },
        showOnTop: currentRowIndex > 6,
        dataTestId: 'target-status_tableCell',
      }),
      () => {
        return {
          tooltipContent: dropDownStatus.tooltipContent,
          tooltipPlacement: Placement.Bottom,
          style: ContentStyle.Bold,
          controlledTooltip: dropDownStatus.tooltipContent
            ? ControlledTooltip.None
            : ControlledTooltip.Hide,
        };
      }
    )(targetsData);
  }

  return makeTextColumn(
    (data: TargetsDetails) =>
      data.channelSettings.status
        ? capitalize(
            TargetStatusMapping[data.channelSettings.status] ??
              data.channelSettings.status
          )
        : undefined,
    pendingFields?.includes(TARGETS_API_COLUMN_NAME.TargetStatus)
  )(targetsData);
};

RowCellElement.displayName = 'RowCellElement';

const getIsTargetArchived = (
  campaignStatus?: CampaignStatus,
  adGroupStatus?: AdGroupStatus,
  status?: TargetStatus
) =>
  isCampaignStatusArchived(campaignStatus) ||
  isAdGroupStatusArchived(adGroupStatus) ||
  isTargetStatusArchived(status);

export const getDropdownStatus = (
  intl: IntlShape,
  adType: AdType,
  campaignStatus?: CampaignStatus,
  adGroupStatus?: AdGroupStatus,
  status?: TargetStatus,
  adGroupreviewStatus?: AdGroupReviewStatus,
  adLevel?: AdLevel
) => {
  if (getIsTargetArchived(campaignStatus, adGroupStatus, status)) {
    return {
      status: SelectState.Disabled,
      tooltipContent: getTooltipContentForArchivedEntity(
        adLevel
          ? intl.formatMessage({
              id: AdLevelI8nKeyMapper[adLevel],
            })
          : '',
        intl.formatMessage({
          id: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_STATUS,
        }),
        intl
      ),
    };
  }

  if (
    adType === AdType.SearchBrandAmplifier &&
    adGroupreviewStatus &&
    status === TargetStatus.Paused
  ) {
    if (
      adGroupreviewStatus.reviewStatus === AdGroupReviewStatusType.Pending ||
      adGroupreviewStatus.reviewStatus === AdGroupReviewStatusType.InProgress
    ) {
      return {
        status: SelectState.Disabled,
        tooltipContent: (
          <p className="w-180 text-center">
            {intl.formatMessage({
              id: I18nKey.ADS_MANAGER_PRODUCTADS_TABLE_COLUMN_TARGETSTATUS_TOOLTIP,
            })}
          </p>
        ),
      };
    }
  }

  return {
    status: SelectState.Default,
  };
};

export const targetStatusColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.TargetStatus,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_STATUS,
  RowCellElement,
  gridColumnWidth: '160px',
};

const getTargetStatusFilterOptionsBySalesChannel = (
  salesChannel: FlywheelSalesChannel
): MultiSelectOption[] => {
  switch (salesChannel) {
    case FlywheelSalesChannel.Amazon:
      return [
        SelectFilterOption.TargetStatusEnabled,
        SelectFilterOption.TargetStatusPaused,
        SelectFilterOption.TargetStatusArchived,
        SelectFilterOption.TargetStatusPending,
      ];
    case FlywheelSalesChannel.Walmart:
      return [
        SelectFilterOption.TargetStatusEnabled,
        SelectFilterOption.TargetStatusPaused,
        SelectFilterOption.TargetStatusPending,
      ];
    default:
      return [];
  }
};

export const getTargetStatusFilter = (
  salesChannel: FlywheelSalesChannel
): MultiSelectDataFieldFilter =>
  createMultiSelectDataFieldFilter(
    TARGETS_API_COLUMN_NAME.TargetStatus,
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_STATUS,
    getTargetStatusFilterOptionsBySalesChannel(salesChannel)
  );

export interface TargetStatusOptionsProps {
  readonly adTypes: AdType[];
  readonly salesChannels: FlywheelSalesChannel[];
  readonly adLevel: AdLevel[];
  readonly options: SelectOptionProps<string | number>[];
}

const getTargetStatusOptions = (
  intl: IntlShape,
  isCampaignNegativeTarget: boolean
): TargetStatusOptionsProps[] => {
  return [
    {
      adTypes: [
        AdType.SponsoredProducts,
        AdType.SearchBrandAmplifier,
        AdType.SponsoredVideos,
      ],
      salesChannels: [FlywheelSalesChannel.Walmart],
      adLevel: [AdLevel.KeywordTargets],
      options: [
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED,
          }),
          value: TargetStatusOptions.Enabled.value,
        },
        ...(!isCampaignNegativeTarget
          ? [
              {
                label: intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PAUSED,
                }),
                value: TargetStatusOptions.Paused.value,
              },
            ]
          : []),
      ],
    },
    {
      adTypes: [
        AdType.SponsoredProducts,
        AdType.SponsoredBrands,
        AdType.SponsoredDisplay,
      ],
      salesChannels: [FlywheelSalesChannel.Amazon],
      adLevel: [
        AdLevel.ProductTargets,
        AdLevel.KeywordTargets,
        AdLevel.Targets,
      ],
      options: [
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED,
          }),
          value: TargetStatusOptions.Enabled.value,
        },
        ...(!isCampaignNegativeTarget
          ? [
              {
                label: intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PAUSED,
                }),
                value: TargetStatusOptions.Paused.value,
              },
              {
                label: intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ARCHIVED,
                }),
                value: TargetStatusOptions.Archived.value,
              },
            ]
          : [
              {
                label: intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ARCHIVED,
                }),
                value: TargetStatusOptions.Deleted.value,
              },
            ]),
      ],
    },
  ];
};
