import isNil from 'lodash/isNil';
import { IntlShape } from 'react-intl';
import { NO_FRACTION_DIGITS } from '../types/CommonSharedTypes';

export const getWalmartItemPageUrl = (itemId?: string) =>
  `https://www.walmart.com/ip/${itemId}`;

export const getDaysOfInventoryValue = (
  intl: IntlShape,
  daysOfInventory?: number
) => {
  return isNil(daysOfInventory)
    ? null
    : daysOfInventory > 365
    ? '365+'
    : intl.formatNumber(Number(daysOfInventory), {
        minimumFractionDigits: NO_FRACTION_DIGITS,
        maximumFractionDigits: NO_FRACTION_DIGITS,
      });
};

export const getUnitProjectedToSell = (
  intl: IntlShape,
  unitsProjectedToSell?: number
) => {
  return isNil(unitsProjectedToSell)
    ? undefined
    : intl.formatNumber(unitsProjectedToSell, {
        minimumFractionDigits: NO_FRACTION_DIGITS,
        maximumFractionDigits: NO_FRACTION_DIGITS,
      });
};
