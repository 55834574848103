import { getName, overwrite } from 'country-list';
import { DateTime } from 'luxon';

import I18nKey from '../../../../lib/types/I18nKey';
import {
  Country,
  PlatformData,
  PlatformDataWithCountries,
  SearchTermsData,
  Step2Option,
  Step2Options,
} from '../../../../lib/types/MISharedTypes';
import { IconPosition, SearchBoxProps, Tab, TabTheme } from '../../components';
import { AddTermsTableRowDataType } from './types';

import { IntlShape } from 'react-intl';

import {
  AmazonLogomarkEnabledIcon,
  EbayLogomarkEnabledIcon,
  GoogleLogomarkEnabledIcon,
  StepStatus,
  TargetLogomarkEnabledIcon,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';

import { MerchantCountriesContextState } from '../../../../containers/merchantCountriesProvider/merchantCountriesProvider';
import {
  CHARGES_PER_SEARCH_TERM,
  FREE_TERMS_PER_CHANNEL,
  PlatformType,
} from './types';

const COUNTRY_OVERRIDES = [
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'UK',
    name: 'United Kingdom',
  },
];

overwrite(COUNTRY_OVERRIDES);

export const ADD_NEW_TERMS_TABS: Tab[] = [
  {
    nameI18nKey: I18nKey.MARKET_INTELLIGENCE_ADD_NEW_TERMS_TAB_MANUALLY,
    theme: TabTheme.LightPurple,
  },
  {
    nameI18nKey: I18nKey.MARKET_INTELLIGENCE_ADD_NEW_TERMS_TAB_BULK_UPLOAD,
    theme: TabTheme.LightPurple,
  },
];

export const ADD_NEW_TERMS_SEARCHBOX_PROPS: SearchBoxProps = {
  value: '',
  iconPosition: IconPosition.Left,
  placeholderKey:
    I18nKey.MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_SEARCH_DROPDOWN_PLACEHOLDER,
};

export const SEARCH_TERMS_TABLE_SEARCH_KEY =
  'search_term_status_sales_channel_country';

export const SELECT_ALL_SEARCH_TERMS_COLUMN_ID = 'Select All';

export const SEARCH_TERM_MAX_CHAR_LENGTH = 255;

export const isSearchTermValid = (searchTerm: string): boolean =>
  !new RegExp(/^B(?=.+\d)(?=.+[A-Z])([A-Z0-9]{9})$/).test(searchTerm.trim());

export const doesSearchTermSalesChannelCountryCombinationExist = (
  rowData: AddTermsTableRowDataType[],
  searchTerm: string,
  selectedSalesChannel: string,
  selectedCountryValueShort: string
) => {
  return (
    rowData.filter(
      (rowCell) =>
        rowCell.searchTerm === searchTerm &&
        rowCell.selectedSalesChannelValue === selectedSalesChannel &&
        rowCell.selectedCountryValueShort === selectedCountryValueShort
    ).length > 0
  );
};

export const mapCountryCodeToCountryName = (
  platforms: PlatformData[]
): PlatformDataWithCountries[] => {
  return platforms.map((platform) => {
    let countries: Country[] = [];

    if (platform.countries) {
      countries = platform.countries.map((country) => {
        return {
          countryCode: country,
          countryName: getCountryName(country) || '',
        };
      });
    }

    return {
      id: platform.id,
      active: platform.active,
      name: platform.name,
      countries: countries,
    };
  });
};

export const getCountryName = (countryCode: string): string =>
  getName(countryCode) || '';

/**
 * Returns the humanized local date format.
 * @param {string} originalUTCDate
 * @returns {string} humazined local date
 * @example <caption>Example usage of formatUTCDateToLocal</caption>
 * formatUTCDateToLocal("2023-03-23T10:56:00.609Z") returns formatted date "Mar 23, 2023 at 04:26 PM"
 * formatUTCDateToLocal("2021-02-10 05:10:30 PST") returns original date "2021-02-10 05:10:30 PST"
 */
export const formatUTCDateToLocal = (originalUTCDate: string) => {
  const DESIRED_DATE_FORMAT = "LLL dd, yyyy' at 'hh:mm a'";
  const date = DateTime.fromISO(originalUTCDate);
  const humanReadable = date.toFormat(DESIRED_DATE_FORMAT);
  return humanReadable;
};

export const getSteps = (activeStep: number, intl: IntlShape) => {
  return [
    {
      stepLabel: '1',
      stepName: intl.formatMessage({
        id: I18nKey.MARKET_INTELLIGENCE_SUGGESTED_TERMS_STEPPER_SELECT_TERMS,
      }),
      stepStatus: activeStep === 0 ? StepStatus.Active : StepStatus.Complete,
    },
    {
      stepLabel: '2',
      stepName: intl.formatMessage({
        id: I18nKey.MARKET_INTELLIGENCE_SUGGESTED_TERMS_STEPPER_SELECT_MARKETPLACE,
      }),
      stepStatus: getStepStatus(activeStep),
    },
    {
      stepLabel: '3',
      stepName: intl.formatMessage({
        id: I18nKey.MARKET_INTELLIGENCE_SUGGESTED_TERMS_STEPPER_SUMMARY,
      }),
      stepStatus: activeStep === 2 ? StepStatus.Active : StepStatus.Inactive,
    },
  ];
};

export const getTermsTrackerTabs = (
  merchantCountriesContext: MerchantCountriesContextState,
  intl: IntlShape
) => [
  ...(Boolean(merchantCountriesContext.merchantCountries?.length)
    ? [
        intl.formatMessage({
          id: I18nKey.MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABS_SUGGESTED_TERMS,
        }),
      ]
    : []),
  intl.formatMessage({
    id: I18nKey.MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABS_TRACKED_TERMS,
  }),
  intl.formatMessage({
    id: I18nKey.MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABS_GROUPS,
  }),
];

export const getOptions = (platforms: PlatformDataWithCountries[]) => {
  const options: Step2Options = {};
  platforms.forEach((platform) => {
    if (platform.active) {
      platform.countries.forEach((country) => {
        if (options[platform.name]) {
          options[platform.name].push({
            countryCode: country.countryCode,
            value:
              platform.name +
              '_' +
              country.countryCode +
              '_' +
              country.countryName,
          });
        } else {
          options[platform.name] = [
            {
              countryCode: country.countryCode,
              value:
                platform.name +
                '_' +
                country.countryCode +
                '_' +
                country.countryName,
            },
          ];
        }
      });
    }
  });
  return options;
};

export const getAllFilteredOptions = (
  step2Options: Step2Options,
  searchTerm: string,
  searchTermsForAllPlatforms: SearchTermsData[]
) => {
  const allOptions: Step2Option[] = Object.values(step2Options).reduce(
    (acc: Step2Option[], val) => acc.concat(val),
    []
  );
  // ignore disabled checkboxes
  return allOptions.filter((option: { value: string }) => {
    const text = option.value.split('_');
    if (isTermFound(searchTermsForAllPlatforms, searchTerm, text[1], text[0]))
      return false;
    return true;
  });
};

export const isTermFound = (
  searchTermsForAllPlatforms: SearchTermsData[],
  searchTerm: string,
  countryCode: string,
  platform: string
) => {
  return searchTermsForAllPlatforms.find(
    (activeTerm: SearchTermsData) =>
      activeTerm.search_term.toLowerCase() === searchTerm.toLowerCase() &&
      activeTerm.locale === countryCode &&
      activeTerm.platform.toLowerCase() === platform.toLowerCase()
  );
};

const getStepStatus = (activeStep: number) => {
  if (activeStep === 1) {
    return StepStatus.Active;
  } else {
    return activeStep > 1 ? StepStatus.Complete : StepStatus.Inactive;
  }
};

export const getPlatformIcon = (platform: string) => {
  const iconMap = {
    Amazon: AmazonLogomarkEnabledIcon,
    Walmart: WalmartLogomarkEnabledIcon,
    Target: TargetLogomarkEnabledIcon,
    eBay: EbayLogomarkEnabledIcon,
    'Google Shopping': GoogleLogomarkEnabledIcon,
  };

  return iconMap[platform as PlatformType];
};

export const getSearchTermsBill = (
  existingTermsCount: number,
  newTermsCount: number
) => {
  return (
    Math.abs(FREE_TERMS_PER_CHANNEL - (existingTermsCount + newTermsCount)) *
    CHARGES_PER_SEARCH_TERM
  );
};

export const getTermsTotalAmount = (
  newTermsCount: number,
  existingTermsCount: number
) =>
  newTermsCount !== 0 &&
  existingTermsCount + newTermsCount > FREE_TERMS_PER_CHANNEL
    ? getSearchTermsBill(existingTermsCount, newTermsCount)
    : 0;
