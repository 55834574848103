import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  MultiSelectOption,
  Placement,
  SelectOptionProps,
  SelectState,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  AdType,
  CampaignDetails,
  CampaignStatus,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  getTooltipContentForArchivedEntity,
  isCampaignStatusArchived,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { getCurrentRowIndex } from './utils';

const tooltipContent = (isCampaignArchived: boolean, intl: IntlShape) => {
  if (isCampaignArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS,
      }),
      intl
    );
  }
};

const getControlledTooltipState = (isCampaignArchived: boolean) =>
  isCampaignArchived ? ControlledTooltip.None : ControlledTooltip.Hide;

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, salesChannel, selectedAdType, ...campaignDetails }) => {
  const selectedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.CampaignStatus
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentRowIndex = useSelector<WithTable<CampaignDetails>, number>(
    getCurrentRowIndex(campaignDetails)
  );

  const dispatch = useDispatch();

  const intl = useIntl();

  if (isEditMode) {
    return makeSelectColumn<CampaignDetails>(
      ({ channelSettings: { status } }) => {
        const value = isNil(selectedValue) ? status : selectedValue;

        return {
          options: getCampaignStatusDropDownOptions(
            intl,
            salesChannel,
            selectedAdType,
            status?.toLowerCase()
          ),
          value: value?.toLowerCase(),
          placeholder: value
            ? capitalize(value)
            : intl.formatMessage({ id: I18nKey.SELECT_NOT_SET }),
          onChange: (inputValue: string) => {
            dispatch(
              tableActions.updateCell({
                tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
                columnName: CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
                existingValue: status?.toLowerCase() || '',
                rowId: campaignDetails.campaignId,
                value: inputValue,
              })
            );
          },
          className: 'w-120',
          boldPlaceholder: true,
          isDirty: status !== value,
          state: isCampaignStatusArchived(status)
            ? SelectState.Disabled
            : SelectState.Default,
          dataTestId: 'campaignStatus_testId',
          showOnTop: currentRowIndex > 5,
        };
      },
      ({ channelSettings: { status } }) => {
        const isCampaignArchived = isCampaignStatusArchived(status);

        return {
          tooltipContent: tooltipContent(isCampaignArchived, intl),
          controlledTooltip: getControlledTooltipState(isCampaignArchived),
          tooltipPlacement: Placement.Bottom,
          style: ContentStyle.Bold,
        };
      }
    )(campaignDetails);
  }

  return makeTextColumn<CampaignDetails>(
    ({ channelSettings: { status } }) =>
      status ? capitalize(status) : undefined,
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.CampaignStatus
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignStatusColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS,
  RowCellElement,
  gridColumnWidth: '160px',
};

export const campaignStatusFilter = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
) =>
  createMultiSelectDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS,
    getCampaignStatusFilterOptions(selectedSalesChannel, selectedAdType)
  );

const getCampaignStatusFilterOptions = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
): MultiSelectOption[] => {
  switch (selectedSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      return [
        SelectFilterOption.CampaignStatusPaused,
        SelectFilterOption.CampaignStatusCompleted,
        SelectFilterOption.CampaignStatusRescheduled,
        SelectFilterOption.CampaignStatusLive,
        SelectFilterOption.CampaignStatusProposal,
        SelectFilterOption.CampaignStatusScheduled,
      ];
    case FlywheelSalesChannel.Amazon:
      switch (selectedAdType) {
        case AdType.SponsoredBrands:
          return [
            SelectFilterOption.CampaignStatusEnabled,
            SelectFilterOption.CampaignStatusPaused,
            SelectFilterOption.CampaignStatusPending,
            SelectFilterOption.CampaignStatusArchived,
            SelectFilterOption.CampaignStatusDraft,
          ];

        case AdType.SponsoredProducts:
        case AdType.SponsoredDisplay:
          return [
            SelectFilterOption.CampaignStatusEnabled,
            SelectFilterOption.CampaignStatusPaused,
            SelectFilterOption.CampaignStatusArchived,
          ];
        // The fallback default should never occur hence safe to return [], can handle with default options in future
        default:
          return [];
      }
  }
};

export const getCampaignStatusDropDownOptions = (
  intl: IntlShape,
  salesChannel: FlywheelSalesChannel,
  selectedAdType: AdType,
  status?: string
) => {
  const CampaignStatusSelectOptions: Record<
    CampaignStatus,
    SelectOptionProps<string>
  > = {
    [CampaignStatus.Enabled]: {
      value: CampaignStatus.Enabled,
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_ENABLED,
      }),
    },
    [CampaignStatus.Paused]: {
      value: CampaignStatus.Paused,
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_PAUSED,
      }),
    },
    [CampaignStatus.Completed]: {
      value: CampaignStatus.Completed,
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_COMPLETED,
      }),
    },
    [CampaignStatus.Live]: {
      value: CampaignStatus.Live,
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_LIVE,
      }),
    },
    [CampaignStatus.Extend]: {
      value: CampaignStatus.Extend,
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_EXTEND,
      }),
    },
    [CampaignStatus.Rescheduled]: {
      value: CampaignStatus.Rescheduled,
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_RESCHEDULED,
      }),
    },
    [CampaignStatus.Scheduled]: {
      value: CampaignStatus.Scheduled,
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_SCHEDULED,
      }),
    },
    [CampaignStatus.Proposal]: {
      value: CampaignStatus.Proposal,
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_PROPOSAL,
      }),
    },
    [CampaignStatus.Archived]: {
      value: CampaignStatus.Archived,
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_ARCHIVED,
      }),
    },
    [CampaignStatus.Pending]: {
      value: CampaignStatus.Pending,
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_PENDING,
      }),
    },
    [CampaignStatus.Draft]: {
      value: CampaignStatus.Draft,
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_DRAFT,
      }),
    },
  };

  if (salesChannel === FlywheelSalesChannel.Amazon) {
    switch (selectedAdType) {
      case AdType.SponsoredBrands:
        const enabledStatus: CampaignStatus[] = [
          CampaignStatus.Enabled,
          CampaignStatus.Paused,
          CampaignStatus.Archived,
        ];
        const disabledStatus: CampaignStatus[] = [
          CampaignStatus.Pending,
          CampaignStatus.Draft,
        ];
        const enabledOptions = enabledStatus.map(
          (campaignStatus) => CampaignStatusSelectOptions[campaignStatus]
        );
        const disabledOptions = disabledStatus.map((campaignStatus) => ({
          ...CampaignStatusSelectOptions[campaignStatus],
          disabled: true,
        }));

        return [...enabledOptions, ...disabledOptions];

      case AdType.SponsoredProducts:
      default:
        return [
          CampaignStatusSelectOptions[CampaignStatus.Enabled],
          CampaignStatusSelectOptions[CampaignStatus.Paused],
          CampaignStatusSelectOptions[CampaignStatus.Archived],
        ];
    }
  } else {
    switch (status) {
      case CampaignStatus.Completed:
        return [
          CampaignStatusSelectOptions[CampaignStatus.Completed],
          CampaignStatusSelectOptions[CampaignStatus.Extend],
        ];
      case CampaignStatus.Rescheduled:
        return [
          CampaignStatusSelectOptions[CampaignStatus.Rescheduled],
          CampaignStatusSelectOptions[CampaignStatus.Paused],
          CampaignStatusSelectOptions[CampaignStatus.Completed],
        ];
      case CampaignStatus.Scheduled:
        return [
          CampaignStatusSelectOptions[CampaignStatus.Scheduled],
          CampaignStatusSelectOptions[CampaignStatus.Paused],
          CampaignStatusSelectOptions[CampaignStatus.Completed],
        ];
      case CampaignStatus.Proposal:
        return [
          CampaignStatusSelectOptions[CampaignStatus.Proposal],
          CampaignStatusSelectOptions[CampaignStatus.Paused],
          CampaignStatusSelectOptions[CampaignStatus.Live],
          CampaignStatusSelectOptions[CampaignStatus.Completed],
        ];
      case CampaignStatus.Live:
      case CampaignStatus.Paused:
      default:
        return [
          CampaignStatusSelectOptions[CampaignStatus.Live],
          CampaignStatusSelectOptions[CampaignStatus.Paused],
          CampaignStatusSelectOptions[CampaignStatus.Completed],
        ];
    }
  }
};
