/* eslint-disable max-statements */
import { IdToken } from '@auth0/auth0-react';
import Axios, { AxiosResponse, RawAxiosRequestConfig } from 'axios';
import { generateSortQueryString } from '../factories/apiDataFetcherFactory';
import {
  AdGroupACOSResponse,
  AdGroupACOSResponseV2,
  AdGroupSKUsRunPauseResponse,
  AdGroupTreResponse,
  CampaignAddRemoveKeywordsRequest,
  CampaignBulkKeywordSaveRequest,
  CampaignBulkPATSaveRequest,
  CampaignProductAdGroupProductGrouping,
  CampaignReadyResponse,
  CampaignStructureGenerationStatus,
  CampaignStructureGenerationStatusResponse,
  CampaignStructurePreviewRequest,
  CampaignStructurePreviewResponse,
  CampaignStructureSummaryRequest,
  CampaignStructureSummaryResponse,
  DailyAdBudgetResponse,
  DailyAdBudgetResponseV2,
  DeleteAdGroupRequest,
  DeleteCampaignRequest,
  EligibleAdGroupsResponse,
  EligibleProductCountResponse,
  EntityType,
  EstPreAdGrossMarginItem,
  EstPreAdGrossMarginRequest,
  FilterItemItem,
  GenerateCampaignStructureRequest,
  GenerateCampaignStructureResponse,
  GenerateCampaignStructureV2Request,
  GroupBy,
  KeywordActionsPayload,
  KeywordRecommendationsData,
  KeywordSummaryData,
  KeywordTargetsRequest,
  KeywordTargetsResponse,
  LaunchCampaignRequest,
  ListEligibleProductRequest,
  ListEligibleProductResponse,
  MerchantAvailabilityRequest,
  MerchantAvailabilityResponse,
  ModifyAcosRequest,
  ModifyAdGroupNameRequest,
  ModifyBudgetRequest,
  ModifyBulkACOsTargetRequest,
  ModifyBulkDailyBudgetRequest,
  ModifyBulkSKUsRunPauseRequest,
  ModifyBulkTreKeywordRequest,
  ModifyCampaignNameRequest,
  ModifyExistingAdsPauseFlagRequest,
  ModifyKeywordFlagRequest,
  ModifyKeywordFlagResponse,
  ModifyResponse,
  ModifyTreAutomationFlagRequest,
  NameChangeSecureUrlResponse,
  PATSummaryData,
  PATSummaryNegativeData,
  ProductAddResponse,
  RecommendedAsinItem,
  RecommendedAsinResponse,
  RecommendedKeywordsResponse,
  Status,
  UpdateCampaignSelectionRequest,
} from '../types/CampaignFlexibilitySharedTypes';
import { getDefaultHeaders } from './index';
import { PaginatedRequest, PaginatedResponse, PaginatedResult } from './types';
import { HttpCode } from './utils';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { Filter } from '../types/Filter';
import {
  filterEmptyValues,
  filtersToQueryString,
} from '../utilities/buildUrlUtilities';

export interface CampaignFlexibilityApiClient {
  readonly getMerchantAvailability: (
    accountId: string,
    request: MerchantAvailabilityRequest
  ) => Promise<MerchantAvailabilityResponse>;
  readonly getEligibleProducts: (
    accountId: string,
    request: ListEligibleProductRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<ListEligibleProductResponse>;

  readonly getEstPreAdGrossMarginProducts: (
    accountId: string,
    request: EstPreAdGrossMarginRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<EstPreAdGrossMarginItem>>;

  readonly generateCampaignStructure: (
    accountId: string,
    merchantCountryId: string,
    request: GenerateCampaignStructureRequest
  ) => Promise<GenerateCampaignStructureResponse>;
  readonly generateCampaignStructureV2: (
    accountId: string,
    merchantCountryId: string,
    request: GenerateCampaignStructureV2Request
  ) => Promise<GenerateCampaignStructureResponse>;
  readonly getCampaignStructureGenerationStatus: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => Promise<CampaignStructureGenerationStatusResponse>;
  readonly getCampaignStructurePreview: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    request: CampaignStructurePreviewRequest
  ) => Promise<CampaignStructurePreviewResponse>;
  readonly modifyTreAutomationFlag: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyTreAutomationFlagRequest
  ) => Promise<boolean>;
  readonly modifyAcosTarget: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyAcosRequest
  ) => Promise<boolean>;
  readonly modifyBudget: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyBudgetRequest
  ) => Promise<boolean>;
  readonly modifyCampaignName: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyCampaignNameRequest
  ) => Promise<ModifyResponse>;
  readonly modifyAdGroupName: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyAdGroupNameRequest
  ) => Promise<boolean>;
  readonly deleteCampaign: (
    accountId: string,
    merchantCountryId: string,
    request: DeleteCampaignRequest
  ) => Promise<boolean>;
  readonly deleteAdGroup: (
    accountId: string,
    merchantCountryId: string,
    request: DeleteAdGroupRequest
  ) => Promise<boolean>;
  readonly launchSuggestedCampaign: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: LaunchCampaignRequest
  ) => Promise<boolean>;
  readonly getKeywordTargets: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    request: KeywordTargetsRequest,
    isNegative: boolean
  ) => Promise<KeywordTargetsResponse>;
  readonly getAsinTargets: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    request: KeywordTargetsRequest,
    isNegative: boolean
  ) => Promise<KeywordTargetsResponse>;
  readonly modifyExistingAdsPauseFlag: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyExistingAdsPauseFlagRequest
  ) => Promise<boolean>;
  readonly updateCampaignSelection: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: UpdateCampaignSelectionRequest
  ) => Promise<boolean>;
  readonly updateAdGroupSelection: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: UpdateCampaignSelectionRequest
  ) => Promise<boolean>;

  readonly cancelSuggestedCampaign: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string
  ) => Promise<boolean>;

  readonly modifyKeywordFlag: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyKeywordFlagRequest
  ) => Promise<ModifyKeywordFlagResponse>;
  readonly modifyAsinFlag: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyKeywordFlagRequest
  ) => Promise<ModifyKeywordFlagResponse>;

  readonly addRemoveKeyword: (
    accountId: string,
    merchantCountryId?: string,
    request?: CampaignAddRemoveKeywordsRequest
  ) => Promise<ModifyResponse>;
  readonly addRemoveAsin: (
    accountId: string,
    merchantCountryId?: string,
    request?: CampaignAddRemoveKeywordsRequest
  ) => Promise<ModifyResponse>;
  readonly newUserAddRemoveKeyword: (
    accountId: string,
    merchantCountryId?: string,
    request?: KeywordActionsPayload
  ) => Promise<boolean>;
  readonly getCampaignStructureSummary: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    request: CampaignStructureSummaryRequest
  ) => Promise<CampaignStructureSummaryResponse>;
  readonly getDailyBudget: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => Promise<DailyAdBudgetResponse[]>;
  readonly getDailyBudgetV2: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => Promise<DailyAdBudgetResponseV2[]>;
  readonly getAdgroupAcosTarget: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => Promise<AdGroupACOSResponse[]>;
  readonly getAdgroupAcosTargetV2: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => Promise<AdGroupACOSResponseV2[]>;

  readonly getAdgroupTre: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => Promise<AdGroupTreResponse[]>;

  readonly getAdgroupSKUs: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => Promise<AdGroupSKUsRunPauseResponse>;

  readonly getAdgroupProductSKUs: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    groupId: string | null
  ) => Promise<AdGroupSKUsRunPauseResponse>;

  readonly postBulkEditDailyBudget: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyBulkDailyBudgetRequest
  ) => Promise<ModifyResponse>;
  readonly postBulkEditAdgroupAcosTarget: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyBulkACOsTargetRequest
  ) => Promise<ModifyResponse>;
  readonly postBulkEditAdGroupTre: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyBulkTreKeywordRequest
  ) => Promise<ModifyResponse>;
  readonly postBulkEditAdGroupSKUs: (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyBulkSKUsRunPauseRequest
  ) => Promise<ModifyResponse>;

  readonly getBulkEditAdGroup: (
    accountId: string,
    merchantCountryId: string,
    searchText: string,
    runId: string,
    isNegativeTarget: boolean
  ) => Promise<PaginatedResponse<EligibleAdGroupsResponse>>;
  readonly postBulkKeywordSaveAdGroup: (
    accountId: string,
    merchantCountryId: string,
    request: CampaignBulkKeywordSaveRequest
  ) => Promise<ModifyResponse>;
  readonly postBulkASINSaveAdGroup: (
    accountId: string,
    merchantCountryId: string,
    request: CampaignBulkPATSaveRequest
  ) => Promise<ModifyResponse>;
  readonly getBulkKeywordAllAdGroup: (
    accountId: string,
    merchantCountryId: string,
    runId: string,
    isNegativeCampaign: boolean,
    offset: number,
    limit: number,
    groupBy: GroupBy
  ) => Promise<PaginatedResponse<any>>;

  readonly getBulkPatKeywordAllAdGroup: (
    accountId: string,
    merchantCountryId: string,
    runId: string,
    isNegativeCampaign: boolean,
    offset: number,
    limit: number
  ) => Promise<PaginatedResponse<any>>;
  readonly getBulkKeywordAllAdGroupPaginated: (
    accountId: string,
    merchantCountryId: string,
    runId: string
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<KeywordRecommendationsData>>;
  readonly getCampaignKeywordSummary: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string,
    isNegativeTarget?: boolean
  ) => Promise<KeywordSummaryData>;
  readonly getCampaignPATSummary: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string
  ) => Promise<PATSummaryData>;
  readonly getCampaignNegativePATSummary: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string
  ) => Promise<PATSummaryNegativeData>;
  readonly getAddProducts: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    offset: number,
    limit: number
  ) => Promise<ProductAddResponse>;
  readonly removeAllTargets: (
    accountId: string,
    merchantCountryId: string,
    runId: string,
    entityType: EntityType
  ) => Promise<ModifyResponse>;
  readonly postBulkProductSave: (
    accountId: string,
    request: any
  ) => Promise<any>;
  readonly exportDataNameChange: (
    accountId: string,
    runId: string,
    merchantCountryId: string
  ) => Promise<AxiosResponse<Blob>>;
  readonly exportDataNameChangeError: (
    accountId: string,
    runId: string,
    merchantCountryId: string,
    fileName: string
  ) => Promise<AxiosResponse<Blob>>;
  readonly nameChangeGetS3SecureUrl: (
    accountId: string,
    runId: string,
    merchantCountryId: string,
    fileName: string
  ) => Promise<AxiosResponse<NameChangeSecureUrlResponse>>;
  readonly nameChangeS3FileUpload: (
    url: string,
    file: File
  ) => Promise<AxiosResponse<{}>>;
  readonly nameChangeValidationRequest: (
    accountId: string,
    runId: string,
    merchantCountryId: string,
    fileName: string
  ) => Promise<AxiosResponse<any>>;

  readonly getRecommendedAsinProducts: (
    accountId: string,
    merchantCountryId: string,
    runId: string
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<RecommendedAsinItem>>;

  readonly setCampaignGole: (
    accountId: string,
    merchantCountryId: string,
    request: any
  ) => Promise<any>;

  readonly getCampaignReady: (
    accountId: string,
    merchantCountryId: string,
    runId: string
  ) => Promise<CampaignReadyResponse>;
}

const CO_BASE_URL = process.env.REACT_APP_CORTEX_HOST;

const IO_BASE_URL = process.env.REACT_APP_SKU_HOST;

const DUMMYENDPOINT = false;

export const PATHS = Object.freeze({
  GET_MERCHANT_AVAILABILITY: (accountId: string, dummyEndpoint?: boolean) =>
    `/v1/campaign-create/accounts/${accountId}/${
      dummyEndpoint ? 'mock/' : ''
    }availability`,

  LIST_ELIGIBLE_PRODUCTS: (accountId: string) =>
    `/accounts/${accountId}/v3/campaign-create/products`,

  ELIGIBLE_PRODUCTS_COUNT: (accountId: string) =>
    `/accounts/${accountId}/v3/campaign-create/products/count`,

  EST_PRE_AD_GROSS_MARGIN_PRODUCTS: (accountId: string) =>
    `/accounts/${accountId}/product-details/pagm`,

  GENERATE_CAMPAIGN_STRUCTURE: (
    accountId: string,
    merchantCountryId: string,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/${
      dummyEndpoint ? 'mock/' : ''
    }structure/generate`,
  GENERATE_CAMPAIGN_STRUCTURE_V2: (
    accountId: string,
    merchantCountryId: string
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/v2/structure/generate`,
  CAMPAIGN_STRUCTURE_GENERATION_STATUS: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/${
      dummyEndpoint ? 'mock/' : ''
    }run-id/${runId}/structure/generation-status`,
  CAMPAIGN_STRUCTURE_PREVIEW: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/${
      dummyEndpoint ? 'mock/' : ''
    }run-id/${runId}/structure/preview`,
  MODIFY_TRE_AUTOMATION_FLAG: (
    accountId: string,
    merchantCountryId: string | undefined,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/${
      dummyEndpoint ? 'mock/' : ''
    }edit/flywheel-ai`,
  MODIFY_ACOGS: (
    accountId: string,
    merchantCountryId?: string,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/${
      dummyEndpoint ? 'mock/' : ''
    }edit/acos-target`,
  MODIFY_BUDGET: (
    accountId: string,
    merchantCountryId: string | undefined,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/${
      dummyEndpoint ? 'mock/' : ''
    }edit/daily-budget`,
  MODIFY_CAMPAIG_NAME: (
    accountId: string,
    merchantCountryId: string | undefined,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/${
      dummyEndpoint ? 'mock/' : ''
    }edit/campaign-name`,
  MODIFY_ADGROUP_NAME: (
    accountId: string,
    merchantCountryId: string | undefined,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/${
      dummyEndpoint ? 'mock/' : ''
    }edit/adgroup-name`,

  DELETE_CAMPAIGN: (
    accountId: string,
    merchantCountryId: string,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/${
      dummyEndpoint ? 'mock/' : ''
    }delete/campaign`,
  DELETE_ADGROUP: (accountId: string, merchantCountryId: string) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/delete/adGroup`,
  LAUNCH_SUGGESTED_CAMPAIGN: (
    accountId: string,
    merchantCountryId: string | undefined,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/${
      dummyEndpoint ? 'mock/' : ''
    }structure/apply`,
  GET_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    dummyEndpoint?: boolean,
    isNegativeTarget?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/${
      dummyEndpoint ? 'mock/' : ''
    }targets?isNegativeTarget=${isNegativeTarget}`,
  GET_ASIN_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    isNegative: boolean = false
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/pat-targets?isNegativeTarget=${isNegative}`,
  MODIFY_PAUSE_FLAG: (
    accountId: string,
    merchantCountryId: string | undefined,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/edit/${
      dummyEndpoint ? 'mock/' : ''
    }products-ads`,
  UPDATE_CAMPAIGN_SELECTION: (
    accountId: string,
    merchantCountryId: string | undefined,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/edit/${
      dummyEndpoint ? 'mock/' : ''
    }campaign-selection`,
  UPDATE_ADGROUP_SELECTION: (
    accountId: string,
    merchantCountryId: string | undefined,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/edit/${
      dummyEndpoint ? 'mock/' : ''
    }adgroup-selection`,
  CANCAL_SUGGESTED_CAMPAIGN: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId?: string
  ) => `v1/accounts/${accountId}/mcids/${merchantCountryId}/runs/${runId}`,
  // waiting for Backend CO-1031
  MODIFY_KEYWORD_FLAG: (
    accountId: string,
    merchantCountryId: string | undefined
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/include-keywords`,
  MODIFY_ASIN_FLAG: (
    accountId: string,
    merchantCountryId: string | undefined
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/include-pat`,

  ADD_REMOVE_MODIFY_KEYWORD: (accountId: string, merchantCountryId?: string) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/edit/target-selections`,
  ADD_REMOVE_MODIFY_ASIN: (accountId: string, merchantCountryId?: string) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/edit/pat-selections`,

  CAMPAIGN_STRUCTURE_SUMMARY: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    dummyEndpoint?: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/${
      dummyEndpoint ? 'mock/' : ''
    }run-id/${runId}/structure/summary`,
  GET_DAILYBUDGET: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/get/daily-budget`,
  GET_DAILY_BUDGET_V2: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/v2/get/daily-budget`,

  GET_ADGROUP_TRE_AUTOMATION: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/get/adgroup-tre-automation`,

  BULKEDIT_POST_ADGROUP_TRE_AUTOMATION: (
    accountId: string,
    merchantCountryId: string | undefined
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/v2/edit/flywheel-ai`,

  BULKEDIT_POST_DAILYBUDGET: (
    accountId: string,
    merchantCountryId: string | undefined
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/v2/edit/daily-budget`,

  GET_ADSGROUP_ACOS_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/get/adgroup-acos-target`,
  GET_ADSGROUP_ACOS_TARGETS_V2: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/v2/get/adgroup-acos-target`,

  BULKEDIT_POST_ADSGROUP_ACOS_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/v2/edit/acos-target`,

  GET_ADD_PRODUCT: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    offset: number,
    limit: number
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/products?offset=${offset}&limit=${limit}`,

  GET_ADSGROUP_SKUS_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/get/product-ads`,

  GET_ADSGROUP_PRODUCT_SKUS_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    groupId: string | null
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/group-id/${groupId}/get/product-ads`,

  BULKEDIT_POST_ADSGROUP_SKUS_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/edit/status/ad-groups`,
  BULKEDIT_GET_ADSGROUP_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string,
    searchText: string,
    isNegativeTarget: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/adgroups?isNegativeTarget=${isNegativeTarget}&name=${searchText}`,

  BULKEDIT_SAVE_KEYWORDS_ADSGROUP_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/save/keywords`,
  BULKEDIT_SAVE_PAT_ADSGROUP_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/save/pat`,

  GET_USER_ADSGROUP_TARGETS_PAT: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string,
    isNegative: boolean = false,
    offset: number,
    limit: number
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/user-created-adgroup-pat?isNegativeTarget=${isNegative}&offset=${offset}&limit=${limit}`,

  GET_USER_ADSGROUP_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string,
    isNegativeKeywordTarget: boolean = false,
    offset: number,
    limit: number,
    groupBy: GroupBy
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/user-created-targets?isNegativeTarget=${isNegativeKeywordTarget}&groupBy=${groupBy}&offset=${offset}&limit=${limit}`,

  GET_ADSGROUP_TARGETS: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string,
    isUserAdded: boolean
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/ai-keywords`,

  GET_CAMPAIGN_KEYWORDS_SUMMARY: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string,
    isNegativeKeywordTarget: boolean = false
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/summary/keywords?isNegativeTarget=${isNegativeKeywordTarget}`,

  GET_CAMPAIGN_PAT_SUMMARY: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/summary/pat`,

  GET_CAMPAIGN_NEGATIVE_PAT_SUMMARY: (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/negative-pat-summary`,

  REMOVE_ALL_TARGETS: (
    accountId: string,
    merchantCountryId: string,
    runId: string,
    entityType: EntityType
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/remove-all-targets`,

  GET_BULK_ADD_PRODUCT: (accountId: string) =>
    `accounts/${accountId}/v1/campaign-create/product-upload`,
  GET_EXPORT: (accountId: string, merchantCountryId: string, runId: string) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/export/name-change`,

  GET_EXPORT_ERROR: (
    accountId: string,
    merchantCountryId: string,
    runId: string
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/export/name-change-error`,

  GET_UPLOAD_ERROR: (
    accountId: string,
    merchantCountryId: string,
    runId: string
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/export/name-change-error`,

  MANAGE_BIDDING_SECURE_URL: (
    accountId: string,
    merchantCountryId: string,
    runId: string
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/upload/generate/url`,
  MANAGE_BIDDING_S3_FILE_UPLOAD: (url: string) => url,

  MANAGE_BIDDING_VALIDATE_UPLOAD: (
    accountId: string,
    merchantCountryId: string,
    runId: string
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/upload/process/name-change`,

  RECOMMENDED_ASIN_PRODUCTS_LIST: (
    accountId: string,
    merchantCountryId: string,
    runId: string
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/ai-pat`,

  CAMPAIGN_GOAL: (accountId: string, merchantCountryId: string) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/edit/campaign-goal`,

  CAMPAIGN_STRUCTURE_READY: (
    accountId: string,
    merchantCountryId: string,
    runId: string
  ) =>
    `/v1/campaign-create/accounts/${accountId}/mcids/${merchantCountryId}/run-id/${runId}/structure/apply`,
});

export const createCampaignFlexibilityApiClient = (
  token: IdToken | null
): CampaignFlexibilityApiClient => {
  const config: RawAxiosRequestConfig = {
    headers: {
      ...getDefaultHeaders(),
      Authorization: `Bearer ${token?.__raw}`,
    },
    baseURL: CO_BASE_URL,
  };

  const axiosInstance = Axios.create(config);

  const getMerchantAvailability = async (
    accountId: string,
    request: MerchantAvailabilityRequest
  ) => {
    if (size(request.merchantCountries) === 0) {
      return {
        availability: [],
      };
    }
    try {
      const { data } = await axiosInstance.post<MerchantAvailabilityResponse>(
        PATHS.GET_MERCHANT_AVAILABILITY(accountId, DUMMYENDPOINT),
        request
      );
      return data;
    } catch (error) {
      return {
        availability: [],
      };
    }
  };

  const getFilterItem = (paginatedRequest: Filter[]) => {
    return paginatedRequest.reduce(
      (currentItem: FilterItemItem, nextItem: Filter) => {
        if (nextItem.field === 'nonSmartFilter') {
          return {
            ...currentItem,
            [nextItem.field]: !isEmpty(nextItem?.value),
          };
        } else if (nextItem.field === 'searchText') {
          return {
            ...currentItem,
            [nextItem.field]: nextItem?.value,
          };
        } else {
          return {
            ...currentItem,
          };
        }
      },
      {}
    );
  };

  const getFilters = (paginatedRequest: Filter[]) => {
    return paginatedRequest.filter((filter: Filter) => {
      if (filter.field === 'nonSmartFilter' || filter.field === 'searchText') {
        return false;
      }
      return true;
    });
  };

  const getEligibleProducts =
    (accountId: string, request: ListEligibleProductRequest) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<ListEligibleProductResponse> => {
      const allParams = {
        limit: paginatedRequest.itemsPerPage.toString(),
        offset: (
          paginatedRequest.itemsPerPage *
          (paginatedRequest.page - 1)
        ).toString(),
        sort: generateSortQueryString(paginatedRequest.sorts),
        filter: filtersToQueryString(getFilters(paginatedRequest.filters)),
      };

      const params = filterEmptyValues(allParams);
      let axiosInstance = Axios.create({
        ...config,
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
      });
      const { data } = await axiosInstance.post<
        Promise<ListEligibleProductResponse>
      >(
        PATHS.LIST_ELIGIBLE_PRODUCTS(accountId),
        {
          ...request,
          ...getFilterItem(paginatedRequest.filters),
        },
        {
          ...config,
          baseURL: DUMMYENDPOINT ? CO_BASE_URL : IO_BASE_URL,
          params,
        }
      );

      if (
        request.productGrouping === CampaignProductAdGroupProductGrouping.Parent
      ) {
        const {
          data: { fullCount },
        } = await axiosInstance.post<EligibleProductCountResponse>(
          PATHS.ELIGIBLE_PRODUCTS_COUNT(accountId),
          {
            ...request,
            ...getFilterItem(paginatedRequest.filters),
          },
          {
            ...config,
            baseURL: IO_BASE_URL,
            params,
          }
        );
        if ((await data).filteredElements) {
          (await data).filteredElements = fullCount;
        }
      }
      return data;
    };

  const getEstPreAdGrossMarginProducts =
    (accountId: string, request: EstPreAdGrossMarginRequest) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<EstPreAdGrossMarginItem>> => {
      try {
        const allParams = {
          limit: paginatedRequest.itemsPerPage.toString(),
          offset: (
            paginatedRequest.itemsPerPage *
            (paginatedRequest.page - 1)
          ).toString(),
          sort: generateSortQueryString(paginatedRequest.sorts),
          filter: filtersToQueryString(getFilters(paginatedRequest.filters)),
        };

        const params = filterEmptyValues(allParams);
        let axiosInstance = Axios.create({
          ...config,
          maxContentLength: 100000000,
          maxBodyLength: 1000000000,
        });

        const { data } = await axiosInstance.post(
          PATHS.EST_PRE_AD_GROSS_MARGIN_PRODUCTS(accountId),
          {
            ...request,
          },
          {
            ...config,
            baseURL: IO_BASE_URL,
            params,
          }
        );
        return {
          items: data.productDetails || [],
          totalItems: data.productDetails?.length || 0,
        };
      } catch {
        return {
          items: [],
          totalItems: 0,
        };
      }
    };

  const generateCampaignStructure = async (
    accountId: string,
    merchantCountryId: string,
    request: GenerateCampaignStructureRequest
  ) => {
    const { data } =
      await axiosInstance.post<GenerateCampaignStructureResponse>(
        PATHS.GENERATE_CAMPAIGN_STRUCTURE(
          accountId,
          merchantCountryId,
          DUMMYENDPOINT
        ),
        request
      );
    return data;
  };

  const generateCampaignStructureV2 = async (
    accountId: string,
    merchantCountryId: string,
    request: GenerateCampaignStructureV2Request
  ) => {
    const { data } =
      await axiosInstance.post<GenerateCampaignStructureResponse>(
        PATHS.GENERATE_CAMPAIGN_STRUCTURE_V2(accountId, merchantCountryId),
        request
      );
    return data;
  };

  const getCampaignStructureGenerationStatus = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => {
    try {
      const { data } =
        await axiosInstance.get<CampaignStructureGenerationStatusResponse>(
          PATHS.CAMPAIGN_STRUCTURE_GENERATION_STATUS(
            accountId,
            merchantCountryId,
            runId,
            DUMMYENDPOINT
          )
        );
      return data;
    } catch (e: any) {
      return {
        status: CampaignStructureGenerationStatus.Error,
        errorMessage: e?.message,
      };
    }
  };

  const getCampaignStructurePreview = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    request: CampaignStructurePreviewRequest
  ) => {
    const { data } = await axiosInstance.get<CampaignStructurePreviewResponse>(
      PATHS.CAMPAIGN_STRUCTURE_PREVIEW(
        accountId,
        merchantCountryId,
        runId,
        DUMMYENDPOINT
      ),
      {
        ...config,
        params: {
          limit: request.limit,
          offset: request.offset,
        },
      }
    );
    return data;
  };

  const modifyTreAutomationFlag = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyTreAutomationFlagRequest
  ): Promise<boolean> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.MODIFY_TRE_AUTOMATION_FLAG(
        accountId,
        merchantCountryId,
        DUMMYENDPOINT
      ),
      request
    );
    return data.status === Status.Success;
  };

  const modifyAcosTarget = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyAcosRequest
  ): Promise<boolean> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.MODIFY_ACOGS(accountId, merchantCountryId, DUMMYENDPOINT),
      request
    );
    return data.status === Status.Success;
  };

  const modifyBudget = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyBudgetRequest
  ): Promise<boolean> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.MODIFY_BUDGET(accountId, merchantCountryId, DUMMYENDPOINT),
      request
    );
    return data.status === Status.Success;
  };

  const modifyCampaignName = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: DeleteCampaignRequest
  ): Promise<ModifyResponse> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.MODIFY_CAMPAIG_NAME(accountId, merchantCountryId, DUMMYENDPOINT),
      request
    );
    return data;
  };

  const modifyAdGroupName = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyAdGroupNameRequest
  ): Promise<boolean> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.MODIFY_ADGROUP_NAME(accountId, merchantCountryId, DUMMYENDPOINT),
      request
    );
    return data.status === Status.Success;
  };

  const deleteCampaign = async (
    accountId: string,
    merchantCountryId: string,
    request: DeleteCampaignRequest
  ): Promise<boolean> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.DELETE_CAMPAIGN(accountId, merchantCountryId, DUMMYENDPOINT),
      request
    );
    return data.status === Status.Success;
  };

  const deleteAdGroup = async (
    accountId: string,
    merchantCountryId: string,
    request: DeleteAdGroupRequest
  ): Promise<boolean> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.DELETE_ADGROUP(accountId, merchantCountryId),
      request
    );
    return data.status === Status.Success;
  };

  const launchSuggestedCampaign = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: LaunchCampaignRequest
  ): Promise<boolean> => {
    const response = await axiosInstance.post(
      PATHS.LAUNCH_SUGGESTED_CAMPAIGN(
        accountId,
        merchantCountryId,
        DUMMYENDPOINT
      ),
      request
    );
    return response.status === HttpCode.CREATED;
  };

  const getKeywordTargets = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    request: KeywordTargetsRequest,
    isNegativeTarget?: boolean
  ): Promise<KeywordTargetsResponse> => {
    try {
      const { data } = await axiosInstance.post<KeywordTargetsResponse>(
        PATHS.GET_TARGETS(
          accountId,
          merchantCountryId,
          runId,
          DUMMYENDPOINT,
          isNegativeTarget
        ),
        request
      );
      return data;
    } catch (e: any) {
      return {
        targets: [],
        status: CampaignStructureGenerationStatus.Error,
        errorMessage: e?.message,
      };
    }
  };

  const getAsinTargets = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    request: KeywordTargetsRequest,
    isNegative: boolean
  ): Promise<KeywordTargetsResponse> => {
    try {
      const { data } = await axiosInstance.post<KeywordTargetsResponse>(
        PATHS.GET_ASIN_TARGETS(accountId, merchantCountryId, runId, isNegative),
        request
      );
      return data;
    } catch (e: any) {
      return {
        targets: [],
        status: CampaignStructureGenerationStatus.Error,
        errorMessage: e?.message,
      };
    }
  };

  const modifyExistingAdsPauseFlag = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyExistingAdsPauseFlagRequest
  ): Promise<boolean> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.MODIFY_PAUSE_FLAG(accountId, merchantCountryId, DUMMYENDPOINT),
      request
    );
    return data.status === Status.Success;
  };

  const updateCampaignSelection = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: UpdateCampaignSelectionRequest
  ): Promise<boolean> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.UPDATE_CAMPAIGN_SELECTION(
        accountId,
        merchantCountryId,
        DUMMYENDPOINT
      ),
      request
    );
    return data.status === Status.Success;
  };

  const updateAdGroupSelection = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: UpdateCampaignSelectionRequest
  ): Promise<boolean> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.UPDATE_ADGROUP_SELECTION(
        accountId,
        merchantCountryId,
        DUMMYENDPOINT
      ),
      request
    );
    return data.status === Status.Success;
  };
  const cancelSuggestedCampaign = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string
  ): Promise<boolean> => {
    const { data } = await axiosInstance.delete<ModifyResponse>(
      PATHS.CANCAL_SUGGESTED_CAMPAIGN(accountId, merchantCountryId, runId)
    );
    return data.status === Status.Success;
  };

  const modifyKeywordFlag = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyKeywordFlagRequest
  ): Promise<ModifyKeywordFlagResponse> => {
    const { data } = await axiosInstance.post<ModifyKeywordFlagResponse>(
      PATHS.MODIFY_KEYWORD_FLAG(accountId, merchantCountryId),
      request
    );
    return data;
  };

  const modifyAsinFlag = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyKeywordFlagRequest
  ): Promise<ModifyKeywordFlagResponse> => {
    const { data } = await axiosInstance.post<ModifyKeywordFlagResponse>(
      PATHS.MODIFY_ASIN_FLAG(accountId, merchantCountryId),
      request
    );
    return data;
  };

  const addRemoveKeyword = async (
    accountId: string,
    merchantCountryId?: string,
    request?: CampaignAddRemoveKeywordsRequest
  ): Promise<ModifyResponse> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.ADD_REMOVE_MODIFY_KEYWORD(accountId, merchantCountryId),
      request
    );
    return data;
  };
  const addRemoveAsin = async (
    accountId: string,
    merchantCountryId?: string,
    request?: CampaignAddRemoveKeywordsRequest
  ): Promise<ModifyResponse> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.ADD_REMOVE_MODIFY_ASIN(accountId, merchantCountryId),
      request
    );
    return data;
  };

  const newUserAddRemoveKeyword = async (
    accountId: string,
    merchantCountryId?: string,
    request?: KeywordActionsPayload
  ): Promise<boolean> => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.ADD_REMOVE_MODIFY_KEYWORD(accountId, merchantCountryId),
      request
    );
    return data.status === Status.Success;
  };

  const getCampaignStructureSummary = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    request: CampaignStructureSummaryRequest
  ) => {
    const { data } = await axiosInstance.post<CampaignStructureSummaryResponse>(
      PATHS.CAMPAIGN_STRUCTURE_SUMMARY(
        accountId,
        merchantCountryId,
        runId,
        DUMMYENDPOINT
      ),
      request
    );
    return data;
  };

  const getDailyBudget = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => {
    const { data } = await axiosInstance.get<DailyAdBudgetResponse[]>(
      PATHS.GET_DAILYBUDGET(accountId, merchantCountryId, runId)
    );
    return data;
  };
  const getDailyBudgetV2 = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => {
    const { data } = await axiosInstance.get<DailyAdBudgetResponseV2[]>(
      PATHS.GET_DAILY_BUDGET_V2(accountId, merchantCountryId, runId)
    );
    return data;
  };

  const getAdgroupTre = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => {
    const { data } = await axiosInstance.get<AdGroupTreResponse[]>(
      PATHS.GET_ADGROUP_TRE_AUTOMATION(accountId, merchantCountryId, runId)
    );
    return data;
  };
  const getAdgroupAcosTarget = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => {
    const { data } = await axiosInstance.get<AdGroupACOSResponse[]>(
      PATHS.GET_ADSGROUP_ACOS_TARGETS(accountId, merchantCountryId, runId)
    );
    return data;
  };
  const getAdgroupAcosTargetV2 = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => {
    const { data } = await axiosInstance.get<AdGroupACOSResponseV2[]>(
      PATHS.GET_ADSGROUP_ACOS_TARGETS_V2(accountId, merchantCountryId, runId)
    );
    return data;
  };

  const getAdgroupSKUs = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null
  ) => {
    const { data } = await axiosInstance.get<AdGroupSKUsRunPauseResponse>(
      PATHS.GET_ADSGROUP_SKUS_TARGETS(accountId, merchantCountryId, runId)
    );
    return data;
  };

  const getAdgroupProductSKUs = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    groupId: string | null
  ) => {
    const { data } = await axiosInstance.get<AdGroupSKUsRunPauseResponse>(
      PATHS.GET_ADSGROUP_PRODUCT_SKUS_TARGETS(
        accountId,
        merchantCountryId,
        runId,
        groupId
      )
    );
    return data;
  };

  const postBulkEditDailyBudget = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyBulkDailyBudgetRequest
  ) => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.BULKEDIT_POST_DAILYBUDGET(accountId, merchantCountryId),
      request
    );
    return data;
  };

  const postBulkEditAdGroupTre = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyBulkTreKeywordRequest
  ) => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.BULKEDIT_POST_ADGROUP_TRE_AUTOMATION(accountId, merchantCountryId),
      request
    );
    return data;
  };

  const postBulkEditAdgroupAcosTarget = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyBulkACOsTargetRequest
  ) => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.BULKEDIT_POST_ADSGROUP_ACOS_TARGETS(accountId, merchantCountryId),
      request
    );
    return data;
  };

  const postBulkEditAdGroupSKUs = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: ModifyBulkSKUsRunPauseRequest
  ) => {
    const { data } = await axiosInstance.post<ModifyResponse>(
      PATHS.BULKEDIT_POST_ADSGROUP_SKUS_TARGETS(accountId, merchantCountryId),
      request
    );
    return data;
  };

  const getBulkEditAdGroup = async (
    accountId: string,
    merchantCountryId: string | undefined,
    searchText: string,
    runId: string,
    isNegativeTarget: boolean
  ) => {
    const { data } = await axiosInstance.get<EligibleAdGroupsResponse>(
      PATHS.BULKEDIT_GET_ADSGROUP_TARGETS(
        accountId,
        merchantCountryId,
        runId,
        searchText,
        isNegativeTarget
      )
    );
    return data;
  };

  const getBulkKeywordAllAdGroup = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string,
    isNegativeCampaign: boolean,
    offset: number,
    limit: number,
    groupBy: GroupBy
  ) => {
    const { data } = await axiosInstance.get<any>(
      PATHS.GET_USER_ADSGROUP_TARGETS(
        accountId,
        merchantCountryId,
        runId,
        isNegativeCampaign,
        offset,
        limit,
        groupBy
      )
    );
    return data;
  };

  const getBulkPatKeywordAllAdGroup = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string,
    isNegative: boolean,
    offset: number,
    limit: number
  ) => {
    const { data } = await axiosInstance.get<any>(
      PATHS.GET_USER_ADSGROUP_TARGETS_PAT(
        accountId,
        merchantCountryId,
        runId,
        isNegative,
        offset,
        limit
      )
    );
    return data;
  };

  const getBulkKeywordAllAdGroupPaginated =
    (accountId: string, merchantCountryId: string | undefined, runId: string) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<any>> => {
      const allParams = {
        limit: paginatedRequest.itemsPerPage.toString(),
        offset: (
          paginatedRequest.itemsPerPage *
          (paginatedRequest.page - 1)
        ).toString(),
        sort: generateSortQueryString(paginatedRequest.sorts),
      };
      const params = filterEmptyValues(allParams);

      const {
        data: { elements: items, filteredElements: totalItems },
      } = await axiosInstance.get<RecommendedKeywordsResponse>(
        PATHS.GET_ADSGROUP_TARGETS(accountId, merchantCountryId, runId, false),
        {
          ...config,
          params,
        }
      );

      return {
        items,
        totalItems,
      };
    };

  const postBulkKeywordSaveAdGroup = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: CampaignBulkKeywordSaveRequest
  ) => {
    const { data } = await axiosInstance.post<any>(
      PATHS.BULKEDIT_SAVE_KEYWORDS_ADSGROUP_TARGETS(
        accountId,
        merchantCountryId
      ),
      request
    );
    return data;
  };

  const postBulkASINSaveAdGroup = async (
    accountId: string,
    merchantCountryId: string | undefined,
    request: CampaignBulkPATSaveRequest
  ) => {
    const { data } = await axiosInstance.post<any>(
      PATHS.BULKEDIT_SAVE_PAT_ADSGROUP_TARGETS(accountId, merchantCountryId),
      request
    );
    return data;
  };

  const getCampaignKeywordSummary = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string,
    isNegative?: boolean
  ) => {
    const { data } = await axiosInstance.get<KeywordSummaryData>(
      PATHS.GET_CAMPAIGN_KEYWORDS_SUMMARY(
        accountId,
        merchantCountryId,
        runId,
        isNegative
      )
    );

    return data;
  };

  const getCampaignPATSummary = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string
  ) => {
    const { data } = await axiosInstance.get<PATSummaryData>(
      PATHS.GET_CAMPAIGN_PAT_SUMMARY(accountId, merchantCountryId, runId)
    );

    return data;
  };

  const getCampaignNegativePATSummary = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string
  ) => {
    const { data } = await axiosInstance.get<PATSummaryNegativeData>(
      PATHS.GET_CAMPAIGN_NEGATIVE_PAT_SUMMARY(
        accountId,
        merchantCountryId,
        runId
      )
    );

    return data;
  };
  const getAddProducts = async (
    accountId: string,
    merchantCountryId: string | undefined,
    runId: string | null,
    offset: number,
    limit: number
  ) => {
    const { data } = await axiosInstance.get<ProductAddResponse>(
      PATHS.GET_ADD_PRODUCT(accountId, merchantCountryId, runId, offset, limit)
    );
    return data;
  };

  const postBulkProductSave = async (accountId: string, request: any) => {
    const { data } = await axiosInstance.post<any>(
      PATHS.GET_BULK_ADD_PRODUCT(accountId),
      { ...request },
      {
        ...config,
        baseURL: IO_BASE_URL,
      }
    );
    return data;
  };

  const removeAllTargets = async (
    accountId: string,
    merchantCountryId: string,
    runId: string,
    entityType: EntityType
  ) => {
    const { data } = await axiosInstance.delete<ModifyResponse>(
      PATHS.REMOVE_ALL_TARGETS(accountId, merchantCountryId, runId, entityType),
      { params: { entityType } }
    );
    return data;
  };

  const exportDataNameChange = async (
    accountId: string,
    merchantCountryId: string,
    runId: string
  ) => {
    return await axiosInstance.get<any>(
      PATHS.GET_EXPORT(accountId, merchantCountryId, runId)
    );
  };

  const exportDataNameChangeError = async (
    accountId: string,
    merchantCountryId: string,
    runId: string,
    fileName: string
  ) => {
    const params = { fileName: fileName };
    return await axiosInstance.get<any>(
      PATHS.GET_EXPORT_ERROR(accountId, merchantCountryId, runId),
      {
        ...config,
        params,
      }
    );
  };

  const nameChangeGetS3SecureUrl = async (
    accountId: string,
    merchantCountryId: string,
    runId: string,
    fileName: string
  ): Promise<AxiosResponse<any>> => {
    const params = { fileName: fileName };
    const config: RawAxiosRequestConfig = {
      headers: {
        Accept: 'text/plain',
        Authorization: `Bearer ${token?.__raw}`,
      },
      baseURL: CO_BASE_URL,
    };

    const axiosInstance = Axios.create(config);
    return axiosInstance.post(
      PATHS.MANAGE_BIDDING_SECURE_URL(accountId, merchantCountryId, runId),
      {},
      {
        ...config,
        params,
      }
    );
  };
  const nameChangeS3FileUpload = async (url: string, file: File) => {
    const config: RawAxiosRequestConfig = {
      headers: {},
      baseURL: CO_BASE_URL,
    };

    const axiosInstance = Axios.create(config);
    return axiosInstance.put(
      PATHS.MANAGE_BIDDING_S3_FILE_UPLOAD(url),
      file,
      {}
    );
  };

  const nameChangeValidationRequest = async (
    accountId: string,
    runId: string,
    fileName: string,
    merchantCountryId: string
  ): Promise<AxiosResponse<any>> => {
    const params = {
      fileName: fileName,
    };
    return axiosInstance.post(
      PATHS.MANAGE_BIDDING_VALIDATE_UPLOAD(accountId, merchantCountryId, runId),
      {},
      { ...config, params }
    );
  };

  const getRecommendedAsinProducts =
    (accountId: string, merchantCountryId: string, runId: string) =>
    async (paginatedRequest: PaginatedRequest): Promise<any> => {
      const allParams = {
        limit: paginatedRequest.itemsPerPage.toString(),
        offset: (
          paginatedRequest.itemsPerPage *
          (paginatedRequest.page - 1)
        ).toString(),
        sort: generateSortQueryString(paginatedRequest.sorts),
        filter: filtersToQueryString(getFilters(paginatedRequest?.filters)),
      };

      const params = filterEmptyValues(allParams);
      let axiosInstance = Axios.create({
        ...config,
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
      });

      const { filteredElements, elements } = await axiosInstance
        .get<RecommendedAsinResponse>(
          PATHS.RECOMMENDED_ASIN_PRODUCTS_LIST(
            accountId,
            merchantCountryId,
            runId
          ),
          {
            ...config,
            params,
          }
        )
        .then((res) => {
          return res.data;
        });

      return {
        totalItems: filteredElements,
        items: elements,
      };
    };

  const setCampaignGole = (
    accountId: string,
    merchantCountryId: string,
    reqauest: any
  ) => {
    return axiosInstance.post(
      PATHS.CAMPAIGN_GOAL(accountId, merchantCountryId),
      reqauest
    );
  };

  const getCampaignReady = (
    accountId: string,
    merchantCountryId: string,
    runId: string
  ): Promise<CampaignReadyResponse> => {
    return axiosInstance
      .get(PATHS.CAMPAIGN_STRUCTURE_READY(accountId, merchantCountryId, runId))
      .then((res) => {
        return res.data;
      });
  };

  return {
    getMerchantAvailability,
    getEligibleProducts,
    getEstPreAdGrossMarginProducts,
    generateCampaignStructure,
    generateCampaignStructureV2,
    getCampaignStructureGenerationStatus,
    getCampaignStructurePreview,
    modifyTreAutomationFlag,
    modifyAcosTarget,
    modifyBudget,
    modifyCampaignName,
    modifyAdGroupName,
    deleteCampaign,
    deleteAdGroup,
    launchSuggestedCampaign,
    getKeywordTargets,
    modifyExistingAdsPauseFlag,
    updateCampaignSelection,
    updateAdGroupSelection,
    cancelSuggestedCampaign,
    modifyKeywordFlag,
    modifyAsinFlag,
    addRemoveKeyword,
    addRemoveAsin,
    newUserAddRemoveKeyword,
    getCampaignStructureSummary,
    getDailyBudget,
    getDailyBudgetV2,
    postBulkEditDailyBudget,
    getAdgroupAcosTarget,
    getAdgroupAcosTargetV2,
    postBulkEditAdgroupAcosTarget,
    getAdgroupTre,
    postBulkEditAdGroupTre,
    getAdgroupSKUs,
    getAdgroupProductSKUs,
    postBulkEditAdGroupSKUs,
    getBulkEditAdGroup,
    postBulkKeywordSaveAdGroup,
    getBulkKeywordAllAdGroup,
    getBulkKeywordAllAdGroupPaginated,
    getCampaignKeywordSummary,
    getAddProducts,
    postBulkASINSaveAdGroup,
    getAsinTargets,
    getBulkPatKeywordAllAdGroup,
    getCampaignPATSummary,
    getCampaignNegativePATSummary,
    removeAllTargets,
    postBulkProductSave,
    exportDataNameChange,
    exportDataNameChangeError,
    nameChangeGetS3SecureUrl,
    nameChangeS3FileUpload,
    nameChangeValidationRequest,
    getRecommendedAsinProducts,
    setCampaignGole,
    getCampaignReady,
  };
};
