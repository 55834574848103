import { UserDetails } from '../types/Fam';

export const userTrackingKeyWhitelist = [
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_content',
  'utm_term',
  'tap_a',
  'tap_s',
  'irclickid',
  'impacttest',
  'irgwc',
];

export const WORKFLOW_PARAM = 'workflow';

export const handleSocialSignOn = async (
  searchParams: URLSearchParams,
  userDetails: UserDetails,
  famClient: any
): Promise<{
  utmParams: { [key: string]: string };
  workflow: string | null;
}> => {
  const hubspotFormId = searchParams.get('hs_form_id');
  if (hubspotFormId) {
    await famClient.sendFormToHubspot(hubspotFormId, userDetails);
  }

  return {
    utmParams: getUtmArgs(searchParams),
    workflow: searchParams.get(WORKFLOW_PARAM),
  };
};

export function getUtmArgs(searchParams: URLSearchParams): {
  [key: string]: string;
} {
  return userTrackingKeyWhitelist.reduce((argObj, term) => {
    const value = searchParams.get(term);
    return {
      ...argObj,
      ...(value ? { [term]: value } : {}),
    };
  }, {});
}

export const WORKFLOW_REDIRECTIONS: Record<string, string> = {
  'welcome-marketing-launch-event': 'community/courses/',
};
