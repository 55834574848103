import {
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makePercentColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  CampaignDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  isNumericValueWithCampaignTacticAndCampaignAdFormatValid,
} from '../utils';

export const RowCellElement: React.FC<CampaignDetails> = (props) => {
  const isSponseredBrandsAdTypeOrSV =
    props.selectedAdType === AdType.SponsoredBrands ||
    props.selectedAdType === AdType.SearchBrandAmplifier ||
    props.selectedAdType === AdType.SponsoredVideos;
  return makePercentColumn<CampaignDetails>(
    ({ campaignPerformance: { newToBrandOrdersPercentage } }) =>
      isNumericValueWithCampaignTacticAndCampaignAdFormatValid(
        newToBrandOrdersPercentage,
        undefined,
        isSponseredBrandsAdTypeOrSV
      )
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const percentageOfOrdersNewToBrandColumn: FlywheelTableColumn<CampaignDetails> =
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.PercentageOfOrdersNewToBrand,
    isSortable: true,
    i18nKeyOrLabel:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PERCENTAGE_OF_ORDERS_NEW_TO_BRAND,
    RowCellElement,
    gridColumnWidth: '140px',
    className: 'text-right',
    columnHeaderClassName: 'justify-end',
  };

export const percentageOfOrdersNewToBrandFilter = createPercentDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.PercentageOfOrdersNewToBrand,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PERCENTAGE_OF_ORDERS_NEW_TO_BRAND,
  isValidNumber()
);
