import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ButtonState,
  Modal,
  TextLink,
  TextLinkSize,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../lib/types/I18nKey';
import { AiFeatureRow, ShadowyAlertBox } from './disableAutomationModal';

export interface UpgradePlanModalProps {
  trialDaysRemaining?: number;
  freeTrialAvailable: boolean;
  onUpgrade: () => Promise<void>;
  onCancel: () => void;
  dataTestId?: string;
}

export const UpgradePlanModal: React.FC<UpgradePlanModalProps> = ({
  trialDaysRemaining,
  freeTrialAvailable,
  onUpgrade,
  onCancel,
  dataTestId,
}) => {
  const intl = useIntl();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [
    TRIAL_TITLE,
    NON_TRIAL_TITLE,
    BODY,
    LIST_HEADER,
    NON_TRIAL_NOTE,
    TRIAL_NOTE_BOLD,
    TRIAL_NOTE,
    START_TRIAL_BUTTON,
    NON_TRIAL_BUTTON,
    FIRST_FEATURE,
    SECOND_FEATURE,
  ] = [
    I18nKey.BILLING_UPGRADE_PLAN_TRIAL_TITLE,
    I18nKey.BILLING_UPGRADE_PLAN_NON_TRIAL_TITLE,
    I18nKey.BILLING_UPGRADE_PLAN_BODY,
    I18nKey.BILLING_UPGRADE_PLAN_LIST_HEADER,
    I18nKey.BILLING_UPGRADE_PLAN_NON_TRIAL_NOTE,
    I18nKey.BILLING_UPGRADE_PLAN_TRIAL_NOTE_BOLD,
    I18nKey.BILLING_UPGRADE_PLAN_TRIAL_NOTE,
    I18nKey.BILLING_UPGRADE_PLAN_START_TRIAL_BUTTON,
    I18nKey.BILLING_UPGRADE_PLAN_NON_TRIAL_BUTTON,
    I18nKey.BILLING_PLAN_FEATURES_BIDDER,
    I18nKey.BILLING_PLAN_FEATURES_TRE,
  ].map((id) =>
    intl.formatMessage({ id }, { trialDaysRemaining: trialDaysRemaining ?? 30 })
  );

  const onDisable = async () => {
    setLoading(true);
    onUpgrade();
  };

  return (
    <Modal
      showModal={true}
      header={freeTrialAvailable ? TRIAL_TITLE : NON_TRIAL_TITLE}
      body={BODY}
      mainAction={{
        state: isLoading ? ButtonState.Loading : ButtonState.Enabled,
        label: freeTrialAvailable ? START_TRIAL_BUTTON : NON_TRIAL_BUTTON,
        onClick: onDisable,
      }}
      secondaryAction={{
        state: isLoading ? ButtonState.Disabled : ButtonState.Enabled,
        label: I18nKey.CANCEL,
        onClick: onCancel,
      }}
      dataTestId="upgrade_plan_modal"
    >
      <div className="pt-20 flex flex-col space-y-8">
        <p className="text-base font-medium text-grey-900">{LIST_HEADER}</p>
        <AiFeatureRow text={FIRST_FEATURE} isUpgrade={true} />
        <AiFeatureRow text={SECOND_FEATURE} isUpgrade={true} />
      </div>
      <div className="text-grey-700 text-sm mt-28">
        <FormattedMessage
          id={I18nKey.BILLING_UPGRADE_PLAN_DESCRIPTION}
          values={{
            learnMore: (
              <TextLink
                textLabel={intl.formatMessage({ id: I18nKey.LEARN_MORE })}
                href="https://www.teikametrics.com/flywheel"
                openNewTab={true}
                size={TextLinkSize.Small}
                dataTestId={`${dataTestId}_upgradePlanModalLearnMore`}
              />
            ),
          }}
        />
      </div>

      <ShadowyAlertBox shouldCenterIcon={!freeTrialAvailable}>
        {freeTrialAvailable ? (
          <>
            <span className="font-medium">{TRIAL_NOTE_BOLD}</span>
            {TRIAL_NOTE}
          </>
        ) : (
          NON_TRIAL_NOTE
        )}
      </ShadowyAlertBox>
    </Modal>
  );
};
UpgradePlanModal.displayName = 'UpgradePlanModal';
