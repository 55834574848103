import axios, { RawAxiosRequestConfig } from 'axios';

import { IdToken } from '@auth0/auth0-spa-js';

import {
  AccountStatusResponse,
  BillingBalanceResponse,
  BillingEstimateDetailsResponseType,
  BillingEstimateResponseType,
  BillingInfoResponse,
  BillingInvoicesResponse,
  PlatformSubscriptionType,
  ZuoraSignature,
} from '../types/Billing';
import { getDefaultHeaders } from './index';
import { buildUrlWithQueryParams } from '../utilities/buildUrlUtilities';

const REACT_APP_BILLING_HOST = process.env.REACT_APP_BILLING_HOST;

export const PATHS = Object.freeze({
  ACCOUNT_STATUS: (accountId: string) => `billing/${accountId}`,
  ZUORA_SIGNATURE: 'billing/zuora-signature',
  BILLING_CONFIGURATION: (accountId: string) => `billing/${accountId}/zuora`,
  BILLING_ESTIMATE_DETAILS: (accountId: string) =>
    `billing/${accountId}/estimate/details`,
  BILLING_ESTIMATE: (accountId: string) => `/billing/${accountId}/estimate`,
  BILLING_BALANCE: (accountId: string) => `billing/${accountId}/balance`,
  BILLING_INVOICES: (accountId: string) =>
    `/billing/${accountId}/invoice-history`,
  INVOICE_PDF: (pdfUrl: string) => `${pdfUrl}`,
  UPDATE_PLATFORM_SUBSCRIPTION: (accountId: string) =>
    `/billing/${accountId}/platform-subscription/update`,
  RENEW_ANNUAL_PLATFORM_SUBSCRIPTION: (accountId: string) =>
    `/billing/${accountId}/platform-subscription/reactivate`,
  CANCEL_ANNUAL_PLATFORM_SUBSCRIPTION: (accountId: string) =>
    `/billing/${accountId}/platform-subscription/cancel`,
});

export interface BillingApiClient {
  readonly getZuoraSignature: () => Promise<ZuoraSignature>;
  readonly createZuoraAccount: (
    accountId: string,
    refId: string,
    billingContact: {
      firstName: string;
      lastName: string;
      email: string;
    },
    platformSubscription?: PlatformSubscriptionType | null
  ) => Promise<any>;
  readonly getBillingEstimateDetails: (
    accountId: string
  ) => Promise<BillingEstimateDetailsResponseType>;
  getBillingEstimate: (
    accountId: string,
    startDate?: string,
    endDate?: string
  ) => Promise<BillingEstimateResponseType>;
  getBillingInfo: (accountId: string) => Promise<BillingInfoResponse>;
  getAccountStatus: (accountId: string) => Promise<AccountStatusResponse>;
  getBillingBalance: (accountId: string) => Promise<BillingBalanceResponse>;
  getBillingInvoices: (accountId: string) => Promise<BillingInvoicesResponse>;
  getInvoicesPdf: (pdfUrl: string) => Promise<any>;
  updatePlatformSubscription: (
    accountId: string,
    newSubscription: PlatformSubscriptionType
  ) => Promise<void>;
  readonly renewPlatformSubscription: (
    accountId: string,
    platformSubscriptionType: PlatformSubscriptionType
  ) => Promise<void>;
  cancelAnnualPlatformSubscription: (accountId: string) => Promise<void>;
}

export const createBillingApiClient = (token: IdToken): BillingApiClient => {
  const config: RawAxiosRequestConfig = {
    headers: {
      ...getDefaultHeaders(),
      Authorization: `Bearer ${token?.__raw}`,
    },
    baseURL: REACT_APP_BILLING_HOST,
  };

  const getZuoraSignature = () => {
    return axios
      .get<ZuoraSignature>(PATHS.ZUORA_SIGNATURE, config)
      .then((response) => response.data);
  };

  const createZuoraAccount = (
    accountId: string,
    refId: string,
    billingContact: {
      firstName: string;
      lastName: string;
      email: string;
    },
    platformSubscription?: PlatformSubscriptionType | null
  ) => {
    return axios
      .post<void>(
        PATHS.BILLING_CONFIGURATION(accountId),
        { paymentRef: refId, contact: billingContact, platformSubscription },
        config
      )
      .then((response) => response.data);
  };

  const getBillingInfo = (accountId: string) =>
    axios
      .get<BillingInfoResponse>(PATHS.BILLING_CONFIGURATION(accountId), config)
      .then((response) => response.data)
      .catch((err) => err);

  const getAccountStatus = (accountId: string) =>
    axios
      .get<AccountStatusResponse>(PATHS.ACCOUNT_STATUS(accountId), config)
      .then((response) => response.data);

  const getBillingEstimateDetails = (
    accountId: string
  ): Promise<BillingEstimateDetailsResponseType> =>
    axios
      .get<BillingEstimateDetailsResponseType>(
        PATHS.BILLING_ESTIMATE_DETAILS(accountId),
        config
      )
      .then((response) => response.data);

  const getBillingEstimate = (
    accountId: string,
    startDate?: string,
    endDate?: string
  ): Promise<BillingEstimateResponseType> => {
    const endpoint = buildUrlWithQueryParams(
      PATHS.BILLING_ESTIMATE(accountId),
      startDate && endDate
        ? {
            startDate,
            endDate,
          }
        : {}
    );

    return axios.get(endpoint, { ...config }).then((response) => response.data);
  };

  const getBillingBalance = (accountId: string) =>
    axios
      .get<BillingBalanceResponse>(PATHS.BILLING_BALANCE(accountId), config)
      .then((response) => response.data);

  const getBillingInvoices = (accountId: string) =>
    axios
      .get<BillingInvoicesResponse>(PATHS.BILLING_INVOICES(accountId), config)
      .then((response) => response.data);

  const getInvoicesPdf = (pdfUrl: string) =>
    axios.get<any>(PATHS.INVOICE_PDF(pdfUrl), {
      ...config,
      responseType: 'blob',
    });

  const updatePlatformSubscription = (
    accountId: string,
    newSubscription: PlatformSubscriptionType
  ) => {
    return axios
      .patch<void>(
        PATHS.UPDATE_PLATFORM_SUBSCRIPTION(accountId),
        { newSubscription },
        config
      )
      .then((response) => response.data);
  };

  const renewPlatformSubscription = (
    accountId: string,
    platformSubscriptionType: PlatformSubscriptionType
  ) => {
    return axios
      .post<void>(
        PATHS.RENEW_ANNUAL_PLATFORM_SUBSCRIPTION(accountId),
        { platformSubscription: platformSubscriptionType },
        config
      )
      .then((response) => response.data);
  };

  const cancelAnnualPlatformSubscription = (accountId: string) => {
    return axios
      .patch<void>(
        PATHS.CANCEL_ANNUAL_PLATFORM_SUBSCRIPTION(accountId),
        {},
        config
      )
      .then((response) => response.data);
  };

  return {
    getZuoraSignature,
    createZuoraAccount,
    getBillingEstimateDetails,
    getBillingEstimate,
    getBillingInfo,
    getAccountStatus,
    getBillingBalance,
    getBillingInvoices,
    getInvoicesPdf,
    updatePlatformSubscription,
    renewPlatformSubscription,
    cancelAnnualPlatformSubscription,
  };
};
