import { Factory } from 'fishery';

import { BrandCoverageOnSearchData } from '../../types/MISharedTypes';

export default Factory.define<BrandCoverageOnSearchData>(() => ({
  brand_name: 'Toptheway',
  appearances_percentage: 0.05,
  total_bcs: 0.05,
  paid_bcs: 0.05,
  organic_bcs: 0.05,
}));
