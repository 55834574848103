import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useDataSyncInfoContext } from '../../containers/dataSyncInfoProvider/dataSyncInfoProvider';
import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { AllSalesChannel, MerchantCountry } from '../../lib/types/Fam';
import { DataAvailabilityResponse } from '../../lib/types/SKUSharedTypes';
import {
  UserInfo,
  setCompassCatalogDateRangeInLocalStorage,
} from './storageUtils';
import { DateRange } from './types';
import {
  getEarliestSyncDateForSelectedMerchants,
  getMerchantCountriesSelection,
  getPreviousCurrencySelection,
  getPreviousDateSelection,
  getPreviousMerchantSelection,
} from './utils';
import { SeasonalEvent } from '../../lib/types/CompassSharedTypes';
import { createCompassApiClient } from '../../lib/clients/CompassApiClient';
import { CurrencyCode } from '../../lib/utilities/currency';
import { useQuery } from '@tanstack/react-query';
import { CompassQueryKeys } from '../../lib/types/ReactQueryKeys';

const DEFAULT_START_DATE = DateTime.local().minus({ day: 30 });
const DEFAULT_END_DATE = DateTime.local().minus({ day: 1 });
const MILLISECONDS_TO_DAYS = 60 * 60 * 24 * 1000;

export interface ContextSetterValue {
  readonly selectedMerchantsId: string[];
  readonly selectedDateRange: DateRange;
  readonly selectedCurrency: CurrencyCode;
  readonly selectedCountries?: string[];
}

interface OwnContextSetterValueProps extends ContextSetterValue {
  readonly selectedDateRangeDiffInDays: number;
}

export interface CompassContextSetterState {
  readonly merchants: MerchantCountry[];
  readonly dataSyncInfoForAllMerchants?: DataAvailabilityResponse;
  readonly contextSetterValues: OwnContextSetterValueProps;
  readonly updateContextSetterValue: (
    newContextSetterValue: ContextSetterValue
  ) => void;
  readonly fw2SalesChannels: AllSalesChannel[];
  readonly isContextDataLoading: boolean;
  readonly seasonalEvents: SeasonalEvent[];
}

export const DEFAULT_COMPASS_CONTEXT_SETTER_STATE: CompassContextSetterState = {
  merchants: [],
  contextSetterValues: {
    selectedCurrency: CurrencyCode.USD,
    selectedMerchantsId: [],
    selectedCountries: [],
    selectedDateRange: {
      startDate: DEFAULT_START_DATE,
      endDate: DEFAULT_END_DATE,
    },
    selectedDateRangeDiffInDays: 0,
  },
  updateContextSetterValue: noop,
  fw2SalesChannels: [],
  isContextDataLoading: true,
  seasonalEvents: [],
};

const CompassContextSetterContext = createContext<CompassContextSetterState>(
  DEFAULT_COMPASS_CONTEXT_SETTER_STATE
);

CompassContextSetterContext.displayName = 'CompassContextSetterContext';

const { Provider } = CompassContextSetterContext;

const CompassContextSetterDataProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const idToken = userContext.userInfo.idToken!;
  const userId = userContext.userInfo.userDetails?.id;
  const accountId = getCurrentAccountFromContext(userContext)!.id;

  const userInfo: UserInfo = {
    userId: userId ?? '',
    accountId,
  };

  // const [merchants, setMerchants] = useState<MerchantCountry[]>([]);

  const [dataSyncInfoForAllMerchants, setDataSyncInfoForAllMerchants] =
    useState<DataAvailabilityResponse | undefined>();

  const [contextSetterValue, setContextSetterValue] =
    useState<OwnContextSetterValueProps>({
      selectedCurrency: getPreviousCurrencySelection(
        CurrencyCode.USD,
        userInfo
      ),
      selectedDateRange: getPreviousDateSelection(
        {
          startDate: DEFAULT_START_DATE,
          endDate: DEFAULT_END_DATE,
        },
        userInfo
      ),
      selectedMerchantsId: [],
      selectedCountries: [],
      selectedDateRangeDiffInDays: 0,
    });

  const { skuDataAvailibilityInfo } = useDataSyncInfoContext();

  const famApiClient = createFAMApiClient(idToken);
  const compassApiClient = createCompassApiClient(idToken);

  const getMerchantsInfo = async (fwSalesChannels: AllSalesChannel[]) => {
    const salesChannelIds = fwSalesChannels.map(
      (flywheelSalesChannel) => flywheelSalesChannel.id
    );

    const { merchantCountryInfoItems } =
      await famApiClient.getMerchantCountries(accountId, salesChannelIds);

    return merchantCountryInfoItems ?? [];
  };

  const { data: seasonalEvents } = useQuery({
    queryKey: [
      CompassQueryKeys.GetSeasonalEvents,
      contextSetterValue.selectedDateRange.startDate,
      contextSetterValue.selectedDateRange.endDate,
      contextSetterValue.selectedCountries,
      accountId,
    ],
    queryFn: () =>
      compassApiClient.getSeasonalEvents(
        accountId,
        contextSetterValue.selectedDateRange
      ),
  });

  const getSalesChannels = async () => {
    const response = await famApiClient.getAllSalesChannels();

    return response;
  };

  const { data: fw2SalesChannels, isLoading: isLoadingSalesChannel } = useQuery(
    {
      queryKey: [CompassQueryKeys.GetAllSalesChannels, accountId],
      queryFn: () => getSalesChannels(),
    }
  );

  const { data: merchants, isLoading: isLoadingAllMerchants } = useQuery({
    queryKey: [CompassQueryKeys.GetAllMerchants, fw2SalesChannels, accountId],
    queryFn: () => getMerchantsInfo(fw2SalesChannels as AllSalesChannel[]),
    enabled: !!fw2SalesChannels,
  });

  useEffect(() => {
    if (merchants) {
      const merchantIds = merchants?.map(
        (merchantInfo) => merchantInfo.merchantCountryId
      );

      const earliestSyncDate = getEarliestSyncDateForSelectedMerchants(
        merchantIds as string[],
        skuDataAvailibilityInfo
      );

      const newDateRange: DateRange = {
        ...contextSetterValue.selectedDateRange,
        minDate: earliestSyncDate,
        maxDate: DEFAULT_END_DATE,
      };

      const previousMerchantSelections = getPreviousMerchantSelection(
        merchantIds as string[],
        userInfo
      );

      updateContextSetterValue({
        ...contextSetterValue,
        selectedDateRange: newDateRange,
        selectedMerchantsId: previousMerchantSelections,
        selectedCountries: getMerchantCountriesSelection(
          merchants || [],
          previousMerchantSelections
        ),
      });
      setDataSyncInfoForAllMerchants(skuDataAvailibilityInfo);
      setCompassCatalogDateRangeInLocalStorage(userInfo, newDateRange);
    }
  }, [skuDataAvailibilityInfo, merchants, accountId]);

  const updateContextSetterValue = (setterValue: ContextSetterValue) => {
    const selectedDateRangeDiffInDays =
      setterValue.selectedDateRange.endDate.diff(
        setterValue.selectedDateRange.startDate
      ).milliseconds / MILLISECONDS_TO_DAYS;

    const requiredContextSetterValue = {
      ...setterValue,
      selectedCountries: getMerchantCountriesSelection(
        merchants || [],
        setterValue.selectedMerchantsId
      ),
      selectedDateRangeDiffInDays: selectedDateRangeDiffInDays + 1,
    };
    setContextSetterValue(requiredContextSetterValue);
  };

  const contextValue: CompassContextSetterState = useMemo(() => {
    return {
      dataSyncInfoForAllMerchants,
      merchants: merchants || [],
      contextSetterValues: {
        selectedCurrency: contextSetterValue.selectedCurrency,
        selectedDateRange: contextSetterValue.selectedDateRange,
        selectedMerchantsId: contextSetterValue.selectedMerchantsId,
        selectedCountries: contextSetterValue.selectedCountries,
        selectedDateRangeDiffInDays:
          contextSetterValue.selectedDateRangeDiffInDays,
      },
      updateContextSetterValue,
      fw2SalesChannels: fw2SalesChannels || [],
      isContextDataLoading: isLoadingSalesChannel || isLoadingAllMerchants,
      seasonalEvents: seasonalEvents || [],
    };
  }, [
    dataSyncInfoForAllMerchants,
    merchants,
    contextSetterValue.selectedCurrency,
    contextSetterValue.selectedDateRange,
    contextSetterValue.selectedMerchantsId,
    contextSetterValue.selectedCountries,
    updateContextSetterValue,
    fw2SalesChannels,
    isLoadingAllMerchants,
    isLoadingSalesChannel,
    seasonalEvents,
  ]);

  return <Provider value={contextValue}>{children}</Provider>;
};
CompassContextSetterDataProvider.displayName =
  'CompassContextSetterDataProvider';

export { CompassContextSetterContext, CompassContextSetterDataProvider };
