import upperFirst from 'lodash/upperFirst';
import { IntlShape, useIntl } from 'react-intl';

import {
  ContentStyle,
  ControlledTooltip,
  MultiSelectDataFieldFilter,
  PaletteColor,
  Placement,
  SelectOptionProps,
  SelectState,
  StarIcon,
  TagLinearIcon,
  TargetIcon,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import isUndefined from 'lodash/isUndefined';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makePillColumn,
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupStatus,
  AdLevel,
  AdType,
  AddedBy,
  CampaignStatus,
  FlywheelSalesChannel,
  TargetStatus,
  TargetType,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_TARGETS_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  TARGETS_API_COLUMN_NAME,
  getCurrentRowIndexForTargetTable,
  getTooltipContentForArchivedEntity,
  isAdGroupStatusArchived,
  isCampaignStatusArchived,
  isTargetStatusArchived,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

const getIsTargetArchived = (
  campaignStatus?: CampaignStatus,
  adGroupStatus?: AdGroupStatus,
  status?: TargetStatus
) =>
  isCampaignStatusArchived(campaignStatus) ||
  isAdGroupStatusArchived(adGroupStatus) ||
  isTargetStatusArchived(status);

const getDropdownStatus = (
  intl: IntlShape,
  campaignStatus?: CampaignStatus,
  adGroupStatus?: AdGroupStatus,
  status?: TargetStatus,
  adLevel?: AdLevel,
  addedBy?: AddedBy
) => {
  if (
    getIsTargetArchived(campaignStatus, adGroupStatus, status) ||
    addedBy === AddedBy.FlywheelAutomated
  ) {
    return {
      status: SelectState.Disabled,
      tooltipContent: getTooltipContentForArchivedEntity(
        adLevel
          ? intl.formatMessage({
              id: AdLevelI8nKeyMapper[adLevel],
            })
          : '',
        intl.formatMessage({
          id: I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TAGS,
        }),
        intl,
        addedBy
      ),
    };
  }

  return {
    status: SelectState.Default,
  };
};

const getLabelsDropdownOptions = (
  intl: IntlShape,
  addedBy?: AddedBy
): SelectOptionProps<string>[] => [
  {
    value: TargetType.Brand,
    label: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BRAND,
    }),
    disabled: addedBy === AddedBy.FlywheelAutomated,
    tooltip:
      addedBy === AddedBy.FlywheelAutomated
        ? {
            content: intl.formatMessage({
              id: I18nKey.ADS_MANAGER_TARGETS_TAGS_TOOLTIP_NO_TAG_DISABLED_MESSAGE,
            }),
            overwrittenTooltipClassnames: 'w-220',
          }
        : undefined,
  },
  {
    value: TargetType.Competitor,
    label: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_COMPETITOR,
    }),
    disabled: addedBy === AddedBy.FlywheelAutomated,
    tooltip:
      addedBy === AddedBy.FlywheelAutomated
        ? {
            content: intl.formatMessage({
              id: I18nKey.ADS_MANAGER_TARGETS_TAGS_TOOLTIP_NO_TAG_DISABLED_MESSAGE,
            }),
            overwrittenTooltipClassnames: 'w-220',
          }
        : undefined,
  },
  {
    value: TargetType.Generic,
    label: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_GENERIC,
    }),
    disabled: addedBy === AddedBy.FlywheelAutomated,
    tooltip:
      addedBy === AddedBy.FlywheelAutomated
        ? {
            content: intl.formatMessage({
              id: I18nKey.ADS_MANAGER_TARGETS_TAGS_TOOLTIP_NO_TAG_DISABLED_MESSAGE,
            }),
            overwrittenTooltipClassnames: 'w-220',
          }
        : undefined,
  },
  {
    value: TargetType.NoTag,
    label: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_TARGET_LABELS_NO_TAG,
    }),
    disabled: addedBy === AddedBy.FlywheelAutomated,
    tooltip:
      addedBy === AddedBy.FlywheelAutomated
        ? {
            content: intl.formatMessage({
              id: I18nKey.ADS_MANAGER_TARGETS_TAGS_TOOLTIP_NO_TAG_DISABLED_MESSAGE,
            }),
            overwrittenTooltipClassnames: 'w-220',
          }
        : undefined,
  },
];

const getDropdownValue = (updatedLabel: string, currentLable?: string) => {
  if (isUndefined(updatedLabel) && isUndefined(currentLable)) {
    return TargetType.NoTag;
  }

  return updatedLabel ?? currentLable;
};

const getIsDirty = (updatedLabel: string, currentLable: string | undefined) =>
  !!updatedLabel && currentLable !== updatedLabel;

const getTooltipState = (dropDownStatus: {
  status?: SelectState;
  tooltipContent?: JSX.Element;
}) =>
  dropDownStatus.tooltipContent
    ? ControlledTooltip.None
    : ControlledTooltip.Hide;

const getIsEditable = (isEditMode: boolean, adType: AdType) =>
  isEditMode && adType === AdType.SponsoredProducts;

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { isEditMode, targetId } = props;

  const currentRowIndex = useSelector<WithTable<TargetsDetails>, number>(
    getCurrentRowIndexForTargetTable(props)
  );

  const updatedLabel = useSelector<WithTable<TargetsDetails>, string>(
    ({ tableState }) => {
      return tableSelectors.getCellSelector(
        targetId,
        TARGETS_API_COLUMN_NAME.TargetLabels
      )(tableState, ADS_MANAGER_TARGETS_TABLE_ID);
    }
  );

  if (getIsEditable(isEditMode, props.selectedAdType)) {
    const currentLable = props.targetDetails.targetType;
    const dropDownStatus = getDropdownStatus(
      intl,
      props.targetDetails.campaignStatus,
      props.targetDetails.adGroupStatus,
      props.channelSettings.status,
      props.adLevel,
      props.targetDetails.addedBy
    );

    return makeSelectColumn(
      (data: TargetsDetails) => ({
        options: getLabelsDropdownOptions(intl, props.targetDetails?.addedBy),
        placeholder: '',
        value: getDropdownValue(updatedLabel, currentLable),
        state: dropDownStatus.status,
        className: 'w-128',
        isDirty: getIsDirty(updatedLabel, currentLable),
        onChange: (value: string | undefined) => {
          // If current value is undefined, set value to undefined if notag is selected
          if (isUndefined(currentLable) && value === TargetType.NoTag) {
            value = undefined;
          }

          dispatch(
            tableActions.updateCell({
              tableId: ADS_MANAGER_TARGETS_TABLE_ID,
              columnName: TARGETS_API_COLUMN_NAME.TargetLabels,
              rowId: data.targetId,
              value: value as string,
              existingValue: currentLable as string,
            })
          );
        },
        showOnTop: currentRowIndex > 6,
        dataTestId: 'target-tags_tableCell',
      }),
      () => {
        return {
          tooltipContent: dropDownStatus.tooltipContent,
          tooltipPlacement: Placement.Bottom,
          style: ContentStyle.Bold,
          controlledTooltip: getTooltipState(dropDownStatus),
        };
      }
    )(props);
  }

  if (props.targetDetails.targetType) {
    return makePillColumn((data: TargetsDetails) => {
      return {
        text: upperFirst(data.targetDetails.targetType),
        color: getColorForTargetTypePill(data.targetDetails.targetType!),
        isHoverable: false,
        icon: getIconForTargetTypePill(data.targetDetails.targetType!),
        className: 'ml-4',
      };
    })(props);
  }

  return makeTextColumn(
    () => intl.formatMessage({ id: I18nKey.ADS_MANAGER_TARGET_LABELS_NO_TAG }),
    false,
    'text-grey-500 text-base normal-case'
  )(props);
};

RowCellElement.displayName = 'RowCellElementTags';

export const tagsColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.TargetLabels,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TAGS,
  RowCellElement,
  gridColumnWidth: '150px',
  className: 'text-left',
};

export const getTagsFilter = (
  salesChannel: FlywheelSalesChannel,
  adType: AdType,
  adLevel: AdLevel,
  isAiEnabled?: boolean
): MultiSelectDataFieldFilter =>
  createMultiSelectDataFieldFilter(
    TARGETS_API_COLUMN_NAME.TargetLabels,
    I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TAGS,
    getTagsFilterOptions(salesChannel, adType, adLevel, isAiEnabled)
  );

export const getTagsFilterOptions = (
  salesChannel: FlywheelSalesChannel,
  adType: AdType,
  adLevel: AdLevel,
  isAiEnabled?: boolean
) => {
  if (salesChannel === FlywheelSalesChannel.Amazon) {
    return adType === AdType.SponsoredProducts &&
      adLevel === AdLevel.KeywordTargets &&
      isAiEnabled
      ? [
          SelectFilterOption.TargetTypeBrand,
          SelectFilterOption.TargetTypeCompetitor,
          SelectFilterOption.TargetTypeGeneric,
          SelectFilterOption.NoTag,
        ]
      : [SelectFilterOption.NoTag];
  } else {
    return isAiEnabled
      ? [
          SelectFilterOption.TargetTypeBrand,
          SelectFilterOption.TargetTypeCompetitor,
          SelectFilterOption.TargetTypeGeneric,
          SelectFilterOption.NoTag,
        ]
      : [];
  }
};

const getIconForTargetTypePill = (targetType: string) => {
  switch (targetType) {
    case TargetType.Brand:
      return StarIcon;
    case TargetType.Generic:
      return TagLinearIcon;
    case TargetType.Competitor:
      return TargetIcon;
    default:
      return StarIcon;
  }
};

const getColorForTargetTypePill = (targetType: string) => {
  switch (targetType) {
    case TargetType.Brand:
      return PaletteColor.magenta;
    case TargetType.Generic:
      return PaletteColor.greyV2;
    case TargetType.Competitor:
      return PaletteColor.yellow;
    default:
      return PaletteColor.magenta;
  }
};
