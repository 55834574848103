import {
  BadgeCampaignGoal,
  BadgeCampaignType,
  BadgeV2,
  BadgeV2Types,
  TruncatedText,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React from 'react';

export interface SuggestedAdgroupCampaignProps {
  readonly campaignName?: string | React.ReactNode;
  readonly BadgeCampaignType: BadgeCampaignType;
  readonly targetingTypeClassname?: string;
  readonly dataTestId?: string;
  readonly classname?: string;
  readonly hidePadding: boolean;
  readonly campaignGoal: BadgeCampaignGoal;
}

export const SuggestedAdgroupCampaign: React.FC<
  SuggestedAdgroupCampaignProps
> = ({ campaignName, BadgeCampaignType, campaignGoal, hidePadding }) => {
  return (
    <>
      <div
        className={classNames('flex justify-start items-center gap-8 h-24', {
          'pb-8': !hidePadding,
        })}
      >
        <BadgeV2
          badgeType={BadgeV2Types.CampaignType}
          campaignType={BadgeCampaignType}
          campaignGoal={campaignGoal}
        />
        <TruncatedText text={campaignName as string} />
      </div>
    </>
  );
};
