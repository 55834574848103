import {
  AdgroupActionType,
  ArrowsNextIcon,
  BadgeCampaignGoal,
  BadgeCampaignType,
  CrossIcon,
  Icon,
  IconSize,
} from '@teikametrics/tm-design-system';
import noop from 'lodash/noop';
import React from 'react';
import classNames from 'classnames';
import lowerCase from 'lodash/lowerCase';
import {
  MAP_SELECTED_METRIC_CAMPAIGN_GOAL,
  SelectMetricsType,
  TargetingType,
} from '../../../../../lib/types/CampaignFlexibilitySharedTypes';
import { SuggestedAdgroupCampaign } from './suggestedAdgroupCampaign';
import { SuggestedAdgroup } from './suggetedAdgroup';
import {
  CampaignInfo,
  CampaignInfoWithAdGroups,
  TargetSegments,
} from '../../../../../lib/types/KeywordAction';

export interface AdGroupInfoV2 {
  readonly id: string;
  readonly name: string;
  readonly campaign: CampaignInfo;
  readonly targetSegments?: TargetSegments[];
}

export interface CampaignInfoWithAdgroupOptionProps {
  readonly campaign: CampaignInfoWithAdGroups;
  readonly selectable?: boolean;
  readonly showRemoveButton?: boolean;
  readonly selectionMap?: Record<string, CampaignInfoWithAdGroups>;
  readonly onChange?: (
    campaign: CampaignInfoWithAdGroups,
    adGroupId: string,
    selected?: boolean
  ) => void;
  readonly minimizePadding?: boolean;
}

const getIsAdgroupSelected = (
  selectionMap: Record<string, CampaignInfoWithAdGroups> | undefined,
  campaign: CampaignInfoWithAdGroups,
  adGroup: AdGroupInfoV2
) => {
  if (!selectionMap?.[campaign.id]) {
    return false;
  }
  return (
    (selectionMap?.[campaign.id]?.selectedAdGroupIds || []).findIndex(
      (id) => id === adGroup.id
    ) !== -1
  );
};

export const CampaignWithAdgroupOptionV2: React.FC<
  CampaignInfoWithAdgroupOptionProps
> = ({
  campaign,
  selectable,
  showRemoveButton,
  onChange = noop,
  selectionMap = {},
  minimizePadding,
}) => {
  const targetingType =
    lowerCase(campaign?.targetingType) === TargetingType.Auto
      ? BadgeCampaignType.Auto
      : BadgeCampaignType.Manual;
  return (
    <div
      className={classNames(
        'flex flex-col bg-white p-16 border border-solid rounded border-grey-200',
        {
          'gap-12 p-8': !minimizePadding,
        }
      )}
    >
      <SuggestedAdgroupCampaign
        campaignName={campaign.name}
        BadgeCampaignType={targetingType}
        hidePadding={false}
        campaignGoal={
          campaign.advertisingGoal
            ? MAP_SELECTED_METRIC_CAMPAIGN_GOAL[
                campaign.advertisingGoal as SelectMetricsType
              ]
            : BadgeCampaignGoal.None
        }
      />
      {campaign.adGroups.map((adGroup) => (
        <div
          key={adGroup.id}
          className="flex flex-row flex-grow items-start px-8"
        >
          <Icon
            size={IconSize.Medium}
            svg={ArrowsNextIcon}
            className={classNames('self-start text-grey-400', {
              'mt-8': selectable,
            })}
          />
          <SuggestedAdgroup
            dataTestId="create_keywords_adgroup"
            action={
              selectable ? AdgroupActionType.Checkbox : AdgroupActionType.None
            }
            showBorder={selectable}
            classname="w-auto flex-grow min-w-0"
            isSelected={
              selectable &&
              getIsAdgroupSelected(selectionMap, campaign, adGroup)
            }
            adGroupName={adGroup.name}
            onClick={() =>
              selectable &&
              onChange(
                campaign,
                adGroup.id,
                !getIsAdgroupSelected(selectionMap, campaign, adGroup)
              )
            }
            targetSegments={adGroup.targetSegments}
          />
          {showRemoveButton && (
            <Icon
              size={IconSize.Small}
              svg={CrossIcon}
              onClick={() => onChange(campaign, adGroup.id, false)}
              className={classNames('text-grey-400 cursor-pointer', {
                'mt-8': selectable,
              })}
            />
          )}
        </div>
      ))}
    </div>
  );
};
