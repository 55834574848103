import {
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Checkbox,
  CheckboxCheckedState,
  CheckmarkIcon,
  CrossMiniIcon,
  Icon,
  IconSize,
  Modal,
  PencilIcon,
  PlusCircleIcon,
  PortaledTooltip,
  PortaledTooltipAlignment,
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
  SearchInput,
  SearchInputSize,
  Spinner,
  TextInput,
  TextInputState,
  TextLink,
  TextLinkSize,
  Theme,
  ToastVariant,
  Tooltip,
  Type,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../containers/notificationProvider';
import { getCurrentAccountFromContext } from '../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../lib/clients/AOApiClient';
import { Groups } from '../../../lib/types/AOSharedTypes';
import I18nKey from '../../../lib/types/I18nKey';
import { NO_GROUP_VALUE } from '../containers/adsManager/types';
import { CAMPAIGN_GROUPS_LEARN_MORE_LINK } from '../containers/adsManager/types';
import { DeleteGroupsConfirmationModal } from './DeleteGroupsConfirmationModal';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../lib/utilities/intercom';

enum RestState {
  NOT_STARTED = 'NOT_STARTED',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

const MAX_GROUP_NAME_LENGTH = 80;

export interface GroupsData {
  readonly label: string;
  readonly groupId?: string;
  readonly isSelected?: boolean;
}

export interface CreateEditGroupSlideoverProps {
  readonly showModal: boolean;
  readonly aoApiClient: AOApiClient;
  readonly onClose: () => void;
  readonly allGroupsData?: Groups[];
  readonly afterGroupUpdateOrCreate: () => Promise<void>;
  readonly merchantCountryId: string;
  readonly showBackButton?: boolean;
  readonly refreshTableData: () => void;
}

export const CreateEditGroupSlideover: React.FC<
  CreateEditGroupSlideoverProps
> = ({
  aoApiClient,
  showModal,
  onClose,
  allGroupsData,
  afterGroupUpdateOrCreate,
  merchantCountryId,
  showBackButton,
  refreshTableData,
}) => {
  const intl = useIntl();

  const toasts = useContext<NotificationContextState>(NotificationContext);
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)?.id || '';

  const errorToast = (message?: string) => {
    toasts.addNotification({
      headline:
        message ??
        intl.formatMessage({
          id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_TOAST_FAILED,
        }),
      description: null,
      type: Type.Attention,
      variant: ToastVariant.Simple,
    });
  };

  const successToast = () => {
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_TOAST_SUCCESS,
      }),
      description: null,
      type: Type.Success,
      variant: ToastVariant.Simple,
    });
  };

  const [groupsData, setGroupsData] = useState<GroupsData[]>(
    allGroupsData
      ?.filter((item) => item.groupId !== NO_GROUP_VALUE)
      .map((item) => {
        return {
          label: item.name,
          groupId: item.groupId,
          isSelected: false,
        };
      }) || []
  );

  useEffect(() => {
    setGroupsData(
      allGroupsData
        ?.filter((item) => item.groupId !== NO_GROUP_VALUE)
        .map((item) => {
          return {
            label: item.name,
            groupId: item.groupId,
            isSelected: false,
          };
        }) || []
    );
  }, [allGroupsData]);

  const [searchValue, setSearchValue] = useState<string>('');
  const onSearchInputClear = () => {
    setSearchValue('');
  };

  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [addGroupRestState, setAddGroupRestState] = useState<RestState>(
    RestState.NOT_STARTED
  );
  const [addTextInputState, setAddTextInputState] = useState<TextInputState>(
    TextInputState.Default
  );
  const [addNewGroupText, setAddNewGroupText] = useState<string>('');
  const updateAddGroupText = (value: string) => {
    if (value && value.length > MAX_GROUP_NAME_LENGTH) return;

    setAddGroupRestState(RestState.NOT_STARTED);
    const existingGroupNames = groupsData.map((item) =>
      item.label.toLowerCase()
    );
    if (existingGroupNames.includes(value?.toLowerCase())) {
      setAddTextInputState(TextInputState.Error);
    } else {
      setAddTextInputState(TextInputState.Default);
    }
    setAddNewGroupText(value);
  };
  const handleAddNewGroup = async () => {
    try {
      setAddGroupRestState(RestState.LOADING);
      await aoApiClient.createCampaignGroups(accountId, {
        campaignGroups: [addNewGroupText],
        merchantCountryId,
      });

      await afterGroupUpdateOrCreate();
      setRecentlyAddedGroupValue(addNewGroupText);
      setAddGroupRestState(RestState.SUCCESS);
      setIsAddMode(false);
    } catch {
      setAddGroupRestState(RestState.FAILED);
    }
  };

  const [recentlyAddedGroupValue, setRecentlyAddedGroupValue] = useState<
    string | undefined
  >(undefined);
  useEffect(() => {
    if (recentlyAddedGroupValue?.length) {
      setTimeout(() => {
        setRecentlyAddedGroupValue(undefined);
      }, 3000);
    }
  }, [recentlyAddedGroupValue]);

  const [editGroupRestState, setEditGroupRestState] = useState<RestState>(
    RestState.NOT_STARTED
  );
  const [editingGroupId, setEditingGroupId] = useState<string>('');
  const [editingGroupValue, setEditingGroupValue] = useState<string>('');
  const [editTextInputState, setEditTextInputState] = useState<TextInputState>(
    TextInputState.Default
  );
  const updateEditGroupValue = (value: string) => {
    if (value && value.length > MAX_GROUP_NAME_LENGTH) return;

    setEditGroupRestState(RestState.NOT_STARTED);
    const existingGroupNames = groupsData.map((item) =>
      item.label.toLowerCase()
    );
    if (existingGroupNames.includes(value?.toLowerCase())) {
      setEditTextInputState(TextInputState.Error);
    } else {
      setEditTextInputState(TextInputState.Default);
    }
    setEditingGroupValue(value);
  };

  const handleEditNewGroup = async () => {
    try {
      setEditGroupRestState(RestState.LOADING);
      await aoApiClient.updateCampaignGroups(accountId, {
        campaignGroupId: editingGroupId,
        newCampaignGroupName: editingGroupValue,
        merchantCountryId,
      });

      await afterGroupUpdateOrCreate();
      setRecentlyUpdatedGroupValue(editingGroupValue);
      setEditGroupRestState(RestState.SUCCESS);
      setEditingGroupId('');
      setEditingGroupValue('');
    } catch {
      setEditGroupRestState(RestState.FAILED);
    }

    setEditingGroupId('');
    setEditingGroupValue('');
  };

  const [recentlyUpdatedGroupValue, setRecentlyUpdatedGroupValue] = useState<
    string | undefined
  >(undefined);
  useEffect(() => {
    if (recentlyUpdatedGroupValue?.length) {
      setTimeout(() => {
        setRecentlyUpdatedGroupValue(undefined);
      }, 3000);
    }
  }, [recentlyUpdatedGroupValue]);

  const [isRemoveMode, setIsRemoveMode] = useState<boolean>(false);
  const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] =
    useState<boolean>(false);

  const handleGroupRemove = async () => {
    setShowRemoveConfirmationModal(true);
    try {
      const campaignsToRemove: Record<string, string> = {};
      groupsData
        .filter((item) => item.isSelected)
        .forEach((item) => {
          campaignsToRemove[item.groupId!] = item.label;
        });

      const response = await aoApiClient.deleteCampaignGroups(accountId, {
        campaignGroups: campaignsToRemove,
        merchantCountryId,
      });
      refreshTableData();
      await afterGroupUpdateOrCreate();
      setShowRemoveConfirmationModal(false);
      setIsRemoveMode(false);
      if (response.status === 'FAILED') {
        errorToast(
          intl.formatMessage(
            {
              id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_TOAST_PARTIAL_FAILED,
            },
            {
              groups: response.detailedMessage?.split(',')?.join(', '),
            }
          )
        );
      } else {
        successToast();
      }
    } catch {
      setShowRemoveConfirmationModal(false);
      errorToast();
    }
  };

  const onRemoveCancel = () => {
    setGroupsData(
      groupsData.map((item) => {
        return {
          ...item,
          isSelected: false,
        };
      }) || []
    );
    setIsRemoveMode(false);
  };

  const onCancelEditing = () => {
    setEditingGroupId('');
    setEditingGroupValue('');
  };

  const onCancelAddNewGroup = () => {
    setAddNewGroupText('');
    setIsAddMode(false);
  };

  useEffect(() => {
    if (showModal) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [showModal]);

  return (
    <>
      {showRemoveConfirmationModal && (
        <DeleteGroupsConfirmationModal
          onClose={() => setShowRemoveConfirmationModal(false)}
          onApplyClick={handleGroupRemove}
        />
      )}
      <Modal
        showModal={showModal}
        className="flex flex-col w-512 min-w-512"
        slideOutModal={true}
        showOVerlay={true}
        formActionRowClassName="sticky border-t-1 border-grey-200 border-solid py-12 absolute bottom-0 px-12 bg-white"
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        hidePadding={true}
        dataTestId="CreateEditGroup_slideover"
      >
        <div
          className={classNames(
            'flex flex-row items-center',
            'border-solid border-b border-grey-200',
            'text-base leading-none font-medium',
            {
              'p-8': isRemoveMode || showBackButton,
              'p-16': !isRemoveMode && !showBackButton,
            }
          )}
        >
          <div className="hover: cursor-pointer text-grey-600">
            {!isRemoveMode && !showBackButton ? (
              <Icon
                size={IconSize.Medium}
                svg={CrossMiniIcon}
                onClick={onClose}
              />
            ) : (
              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.BlackAndWhite}
                state={ButtonState.Active}
                svgIcon={ArrowLeftIcon}
                label={intl.formatMessage({
                  id: I18nKey.BACK,
                })}
                iconClassName="text-grey-600"
                onClick={isRemoveMode ? onRemoveCancel : onClose}
                dataTestId={'removeMode_back_button'}
              />
            )}
          </div>
          <Typography
            size={TypographySize.base}
            lineHeight={TypographyLineHeight.none}
            weight={TypographyWeight.medium}
            className="w-full text-center"
          >
            {!isRemoveMode
              ? intl.formatMessage({
                  id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_HEADING,
                })
              : intl.formatMessage({
                  id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_CONFIRM_REMOVE_BUTTON,
                })}
          </Typography>
          <div
            className={classNames({
              'w-96 min-w-96': isRemoveMode || showBackButton,
              'w-16 min-w-16': !isRemoveMode && !showBackButton,
            })}
          />
        </div>

        <div
          className={classNames(
            'flex flex-col h-createEditGroupAO p-24 relative',
            {
              'gap-20': isAddMode,
              'gap-16': !isAddMode,
            }
          )}
        >
          {isRemoveMode && (
            <div
              className={classNames(
                'text-sm leading-tight font-normal text-grey-700',
                'flex w-full items-center justify-start'
              )}
            >
              {intl.formatMessage({
                id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_SUB_HEADING,
              })}
            </div>
          )}
          {!isAddMode && (
            <div
              className={classNames('flex', {
                'flex-row gap-12': !!groupsData.length,
                'flex-col gap-8': !groupsData.length,
              })}
            >
              {!!groupsData.length && (
                <SearchInput
                  inputClassName={'w-full'}
                  value={searchValue}
                  size={SearchInputSize.Medium}
                  onSearchInputClear={onSearchInputClear}
                  placeholder={intl.formatMessage({
                    id: I18nKey.GENERIC_SEARCH_LABEL,
                  })}
                  onDebounceChange={undefined}
                  onChange={setSearchValue}
                  dataTestId={'editGroup_slideout_search_input'}
                />
              )}
              {!groupsData.length && (
                <div
                  className={classNames(
                    'text-sm leading-tight font-normal text-grey-700',
                    'flex w-full items-center justify-start'
                  )}
                >
                  {intl.formatMessage({
                    id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_GET_STARTED_ADD_GROUP_TEXT,
                  })}
                </div>
              )}
              {!isRemoveMode && !editingGroupId && (
                <div>
                  <Button
                    size={ButtonSize.Medium}
                    variant={ButtonVariant.BlackAndWhiteBorder}
                    state={ButtonState.Active}
                    svgIcon={PlusCircleIcon}
                    iconClassName="text-grey-600"
                    label={intl.formatMessage({
                      id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_CREATE_BUTTON,
                    })}
                    onClick={() => {
                      onRemoveCancel();
                      onCancelEditing();
                      setIsAddMode(true);
                    }}
                    dataTestId={'editGroup_slideout_create_button'}
                  />
                </div>
              )}
            </div>
          )}
          {isAddMode && (
            <div className="flex flex-col gap-4">
              <div className="text-sm leading-tight font-normal text-grey-700">
                {intl.formatMessage({
                  id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_ADD_GROUP_TEXT,
                })}
              </div>
              <TextInputWitSaveAndCancel
                testInputState={addTextInputState}
                textInputValue={addNewGroupText}
                updateTextInputValue={updateAddGroupText}
                onApply={handleAddNewGroup}
                onCancel={onCancelAddNewGroup}
                isLoading={addGroupRestState === RestState.LOADING}
                isSaveFailed={addGroupRestState === RestState.FAILED}
                isCurrentEditingGroup={false}
                messageOnFail={intl.formatMessage({
                  id: I18nKey.AD_OPTIMIZER_FLYOVER_GROUP_ADD_GROUP_FAILED_MESSAGE,
                })}
              />
            </div>
          )}
          <div className="flex flex-col gap-4">
            {groupsData
              .filter((item) =>
                item.label?.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map((group) => {
                return (
                  <div
                    className={classNames('flex flex-row gap-8 items-center', {
                      'h-32 pl-12': editingGroupId !== group.groupId,
                    })}
                  >
                    {isRemoveMode && (
                      <div className="flex items-center">
                        <Checkbox
                          onClick={() => {
                            const updatedGroupsViewDuringRemove =
                              groupsData.map((item) => {
                                return {
                                  ...item,
                                  isSelected:
                                    group.groupId === item.groupId
                                      ? !item.isSelected
                                      : item.isSelected,
                                };
                              });
                            setGroupsData(updatedGroupsViewDuringRemove);
                          }}
                          checked={
                            group.isSelected
                              ? CheckboxCheckedState.Checked
                              : CheckboxCheckedState.Unchecked
                          }
                          dataTestId={'AdGroup_selection'}
                        />
                      </div>
                    )}
                    <div
                      className={classNames('flex items-center', {
                        'w-full': editingGroupId === group.groupId,
                        'font-medium': isRemoveMode && group.isSelected,
                        'font-normal': !isRemoveMode || !group.isSelected,
                      })}
                    >
                      {editingGroupId !== group.groupId ? (
                        <PortaledTooltip
                          theme={PortaledTooltipTheme.LightV2}
                          position={{
                            placement: PortaledTooltipPlacement.Bottom,
                            alignment: PortaledTooltipAlignment.Center,
                            placementOffsetInPixels: 0,
                          }}
                          content={group.label}
                          disabled={(group.label?.length ?? 0) < 42}
                        >
                          <div
                            className={classNames(
                              'flex items-center',
                              'text-base leading-none'
                            )}
                          >
                            <div className="flex items-center min-h-16 line-clamp-1 break-all whitespace-pre-wrap">
                              {group.label}
                            </div>
                          </div>
                        </PortaledTooltip>
                      ) : (
                        <TextInputWitSaveAndCancel
                          testInputState={editTextInputState}
                          textInputPlaceholder={intl.formatMessage({
                            id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_EDIT_GROUP_INPUT_PLACEHOLDER,
                          })}
                          textInputValue={editingGroupValue}
                          updateTextInputValue={updateEditGroupValue}
                          onApply={handleEditNewGroup}
                          onCancel={onCancelEditing}
                          isLoading={editGroupRestState === RestState.LOADING}
                          isSaveFailed={editGroupRestState === RestState.FAILED}
                          isCurrentEditingGroup={
                            editingGroupId === group.groupId &&
                            editingGroupValue === group.label
                          }
                          messageOnFail={intl.formatMessage({
                            id: I18nKey.AD_OPTIMIZER_FLYOVER_GROUP_EDIT_GROUP_FAILED_MESSAGE,
                          })}
                        />
                      )}
                    </div>
                    {!isRemoveMode && editingGroupId !== group.groupId && (
                      <div
                        onClick={() => {
                          setEditingGroupId(group.groupId || '');
                          updateEditGroupValue(group.label || '');
                        }}
                      >
                        <Icon
                          size={IconSize.Small}
                          svg={
                            recentlyAddedGroupValue === group.label ||
                            recentlyUpdatedGroupValue === group.label
                              ? CheckmarkIcon
                              : PencilIcon
                          }
                          className={
                            'text-grey-900 group-hover:text-purple-500 hover:cursor-pointer'
                          }
                        />
                      </div>
                    )}
                    {recentlyAddedGroupValue === group.label && (
                      <div className="text-sm leading-tight font-normal text-grey-700">
                        {intl.formatMessage({
                          id: I18nKey.AD_OPTIMIZER_FLYOVER_GROUP_ADDED,
                        })}
                      </div>
                    )}
                    {recentlyUpdatedGroupValue === group.label && (
                      <div className="text-sm leading-tight font-normal text-grey-700">
                        {intl.formatMessage({
                          id: I18nKey.AD_OPTIMIZER_FLYOVER_GROUP_UPDATED,
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <div
          className={classNames(
            'flex flex-col items-center justify-center w-full',
            'text-sm leading-none font-normal',
            'bottom-0 absolute gap-16',
            {
              'px-24 pb-24': !isRemoveMode,
            }
          )}
        >
          <TextLink
            href={CAMPAIGN_GROUPS_LEARN_MORE_LINK}
            openNewTab={true}
            size={TextLinkSize.Small}
            className={'leading-none font-normal capitalize'}
            textLabel={intl.formatMessage({
              id: I18nKey.GENERIC_LEARN_MORE,
            })}
            dataTestId={'editGroup_slideout_learn_more_button'}
          />
          {!!groupsData.length && !isRemoveMode && (
            <div className="px-24 py-12 bg-grey-50 w-full flex justify-center">
              <TextLink
                disabled={isAddMode || !!editingGroupId}
                openNewTab={false}
                size={TextLinkSize.Small}
                className={classNames('leading-none font-normal', {
                  'cursor-not-allowed pointer-events-auto':
                    isAddMode || !!editingGroupId,
                })}
                onClick={() => {
                  onCancelAddNewGroup();
                  onCancelEditing();
                  setIsRemoveMode(true);
                }}
                textLabel={intl.formatMessage({
                  id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_BUTTON,
                })}
                dataTestId={'editGroup_slideout_remove_group_button'}
              />
            </div>
          )}
          {isRemoveMode && (
            <div
              className={classNames(
                'px-12 py-12 w-full',
                'flex justify-end gap-12',
                'border-t-1 border-solid border-grey-200'
              )}
            >
              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.BlackAndWhite}
                state={ButtonState.Active}
                label={intl.formatMessage({
                  id: I18nKey.GENERIC_CANCEL_LABEL,
                })}
                iconClassName="text-grey-600"
                onClick={onRemoveCancel}
                dataTestId={'editGroup_slideout_cancel_add_button'}
              />
              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.Primary}
                state={
                  groupsData.some((item) => item.isSelected)
                    ? ButtonState.Active
                    : ButtonState.Disabled
                }
                label={intl.formatMessage({
                  id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_CONFIRM_REMOVE_BUTTON,
                })}
                onClick={() => {
                  setShowRemoveConfirmationModal(true);
                }}
                dataTestId={'editGroup_slideout_confirm_add_button'}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

CreateEditGroupSlideover.displayName = 'CreateEditGroupSlideover';

interface TextInputWitSaveAndCancelProps {
  readonly testInputState: TextInputState;
  readonly textInputPlaceholder?: string;
  readonly textInputValue: string;
  readonly updateTextInputValue: (val: string) => void;
  readonly onApply: () => void;
  readonly onCancel: () => void;
  readonly isLoading: boolean;
  readonly isSaveFailed: boolean;
  readonly isCurrentEditingGroup: boolean;
  readonly messageOnFail?: string;
}

const TextInputWitSaveAndCancel: React.FC<TextInputWitSaveAndCancelProps> = ({
  testInputState = TextInputState.Default,
  textInputPlaceholder,
  textInputValue,
  updateTextInputValue,
  onApply,
  onCancel,
  isLoading,
  isSaveFailed,
  isCurrentEditingGroup,
  messageOnFail,
}) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row gap-4 w-full">
        <div className=" flex flex-col w-full">
          <Tooltip
            overwrittenTooltipClassnames="w-auto min-w-max max-w-440"
            theme={Theme.Dark}
            content={intl.formatMessage({
              id: I18nKey.AD_OPTIMIZER_FLYOVER_GROUP_ADD_GROUP_SAME_GROUP_EXISTS,
            })}
            disabled={
              isCurrentEditingGroup || testInputState !== TextInputState.Error
            }
          >
            <TextInput
              className="max-h-32 w-full"
              value={textInputValue}
              inputState={isCurrentEditingGroup ? undefined : testInputState}
              onKeyPress={(e) => {
                const isEnter = e.key === 'Enter';
                isEnter && onApply();
              }}
              onChange={updateTextInputValue}
              disabled={false}
              placeholder={
                textInputPlaceholder ??
                intl.formatMessage({
                  id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_ADD_GROUP_INPUT_PLACEHOLDER,
                })
              }
            />
          </Tooltip>
        </div>
        {isLoading ? (
          <div className="w-72 m-auto">
            <div className="relative flex items-center justify-center">
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <div className="w-32">
              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.Primary}
                state={
                  !isSaveFailed &&
                  textInputValue.trim().length &&
                  testInputState !== TextInputState.Error
                    ? ButtonState.Active
                    : ButtonState.Disabled
                }
                svgIcon={CheckmarkIcon}
                onClick={onApply}
                dataTestId={'editGroup_slideout_confirm_add_button'}
              />
            </div>
            <div className="w-32">
              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.BlackAndWhiteBorder}
                state={ButtonState.Active}
                svgIcon={CrossMiniIcon}
                iconClassName="text-grey-600"
                onClick={onCancel}
                dataTestId={'editGroup_slideout_cancel_add_button'}
              />
            </div>
          </>
        )}
      </div>
      {isSaveFailed && (
        <div className="flex pt-8 gap-4">
          <p className="text-sm leading-none font-normal text-orange-700">
            {messageOnFail}
          </p>
          <TextLink
            openNewTab={true}
            size={TextLinkSize.Small}
            className={'leading-none font-normal capitalize'}
            onClick={onApply}
            textLabel={intl.formatMessage({
              id: I18nKey.GENERIC_TRY_AGAIN,
            })}
            dataTestId={'editGroup_slideout_learn_more_button'}
          />
        </div>
      )}
    </div>
  );
};
TextInputWitSaveAndCancel.displayName = 'TextInputWitSaveAndCancel';
