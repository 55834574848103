import { Factory } from 'fishery';

import { RecentUploads, RecentUploadsResponse } from '../../types/BulkActions';
import { UPLOAD_STATUS, UPLOAD_TYPE } from '../../../modules/bulkActions/types';
import { PaginatedResult } from '../../../lib/clients/types';

export const EMAIL = 'fakeemail@teikametrics.com';

export const RecentUploadsAPIResponse = Factory.define<RecentUploadsResponse>(
  () => ({
    items: [
      {
        user_email: EMAIL,
        file_name: 'update_sba_campaign-template_2.csv',
        upload_time: '2024-01-10T12:14:52.000Z',
        upload_type: UPLOAD_TYPE.WALMART_SBA_EDIT_CREATE_UPDATE,
        status: UPLOAD_STATUS.INVALID_DOC,
        details: '{"failure" : ["Missing columns: [store_id, name]"]}',
        id: 1,
        is_base_variant_check_complete: false,
        is_download_visible: false,
      },
      {
        user_email: EMAIL,
        file_name: 'sba_keyword-template_2.csv',
        upload_time: '2024-01-10T12:05:43.114Z',
        upload_type: UPLOAD_TYPE.WALMART_SBA_CREATE_UPDATE_KEYWORDS_AND_BIDS,
        status: UPLOAD_STATUS.INVALID_DOC,
        details:
          '{"failure" : ["AttributeError(NoneType object has no attribute context)"]}',
        id: 2,
        is_base_variant_check_complete: false,
        is_download_visible: false,
      },
      {
        user_email: EMAIL,
        file_name: 'create_sba_campaign-template_5.csv',
        upload_time: '2024-01-10T12:04:20.747Z',
        upload_type: UPLOAD_TYPE.WALMART_SBA_CREATE_IN_ONE_GO,
        status: UPLOAD_STATUS.INVALID_DOC,
        details: '{"failure" : ["Missing columns: [campaign_type, name]"]}',
        id: 3,
        is_base_variant_check_complete: false,
        is_download_visible: false,
      },
    ],
    total_count: 5,
  })
);

export const RecentUploadsPaginatedResult = Factory.define<
  PaginatedResult<RecentUploads>
>(() => ({
  items: [
    {
      user_email: EMAIL,
      file_name: 'update_sba_campaign-template_2.csv',
      upload_time: '2024-01-10T12:14:52.000Z',
      upload_type: UPLOAD_TYPE.WALMART_SBA_EDIT_CREATE_UPDATE,
      status: UPLOAD_STATUS.INVALID_DOC,
      details: '{"failure" : ["Missing columns: [store_id, name]"]}',
      id: 1,
      is_base_variant_check_complete: false,
      is_download_visible: false,
    },
    {
      user_email: EMAIL,
      file_name: 'sba_keyword-template_2.csv',
      upload_time: '2024-01-10T12:05:43.114Z',
      upload_type: UPLOAD_TYPE.WALMART_SBA_CREATE_UPDATE_KEYWORDS_AND_BIDS,
      status: UPLOAD_STATUS.INVALID_DOC,
      details:
        '{"failure" : ["AttributeError(NoneType object has no attribute context)"]}',
      id: 2,
      is_base_variant_check_complete: false,
      is_download_visible: false,
    },
    {
      user_email: EMAIL,
      file_name: 'create_sba_campaign-template_5.csv',
      upload_time: '2024-01-10T12:04:20.747Z',
      upload_type: UPLOAD_TYPE.WALMART_SBA_CREATE_IN_ONE_GO,
      status: UPLOAD_STATUS.INVALID_DOC,
      details: '{"failure" : ["Missing columns: [campaign_type, name]"]}',
      id: 3,
      is_base_variant_check_complete: false,
      is_download_visible: false,
    },
  ],
  totalItems: 5,
}));
