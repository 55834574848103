import React from 'react';

import { ReactComponent as NoDataIcon } from '../../img/icons_deprecated/no-data.svg';

interface Props {
  readonly tryAgainClickHandler: () => void;
}

const NoData: React.FC<Props> = ({ tryAgainClickHandler }) => (
  <div className="table__no-data">
    <NoDataIcon />
    <p>No results could be found</p>
    <button
      onClick={tryAgainClickHandler}
      className="flywheel"
      data-test-id={'noDataTryAgain'}
    >
      Try Again
    </button>
  </div>
);
NoData.displayName = 'NoData';
export default NoData;
