import {
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';
import debounce from 'lodash/debounce';
import lowerCase from 'lodash/lowerCase';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeExternalTextLinkColumn,
  makeTextInputWithPortaledTooltipColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupReviewStatusType,
  ProfileApiDataDetails,
  ProfileStatus,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { getInputState } from '../adgroupTableColumns/adgroupName';
import { ADS_MANAGER_PROFILES_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import {
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  EDIT_PROFILE_API_COLUMN_DATA_MAPPING,
  PROFILES_API_COLUMN_NAME,
  TABLE_UNIQ_KEY,
  decodeUrl,
  hasTypeheadParam,
  isInvalidClickUrl,
} from '../utils';
import { TooltipContentProps, getIsColumnEditDisabled } from './brand';

export const getIsInputInvalid = (value: string) => {
  return isInvalidClickUrl(value) || hasTypeheadParam(value);
};

export const getTooltipContent = ({
  intl,
  showTooltip,
  value,
  campaignStatus,
  adGroupReviewStatus,
  profileStatus,
}: TooltipContentProps) => {
  let messageId: I18nKey | undefined;
  let data: { column: string; max?: number } = {
    column: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICK_URL,
    }),
  };

  if (
    adGroupReviewStatus === AdGroupReviewStatusType.InProgress ||
    adGroupReviewStatus === AdGroupReviewStatusType.Pending
  ) {
    messageId =
      I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_ONGOING_REVIEW_TOOLTIP_CONTENT;
    data = {
      ...data,
      column: lowerCase(data.column),
    };
  } else if (
    profileStatus === ProfileStatus.Enabled &&
    campaignStatus?.toString() !== 'Paused' &&
    campaignStatus?.toString() !== 'Proposal'
  ) {
    messageId =
      I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_NOT_PAUSED_TOOLTIP_CONTENT;
  } else if (showTooltip) {
    if (!value) {
      messageId =
        I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_EMPTY_TOOLTIP_CONTENT;
    } else if (isInvalidClickUrl(value)) {
      messageId =
        I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_INVALID_COLUMN_TOOLTIP_CONTENT;
    } else if (hasTypeheadParam(value)) {
      messageId =
        I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICK_URL_TYPEAHEAD_TOOLTIP_CONTENT;
    }
  }

  if (messageId) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({ id: messageId }, data)}
      </p>
    );
  }
  return <></>;
};

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = ({
  profileId,
  profileDetails,
  channelSettings,
  profilePerformance,
  isEditMode,
  pendingFields,
}) => {
  const profileData: ProfileApiDataDetails = {
    profileId,
    profileDetails,
    channelSettings,
    profilePerformance,
  };
  const intl: IntlShape = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);

  const currentPage = useSelector<WithTable<ProfileApiDataDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_PROFILES_TABLE_ID
      )
  );

  const changedValue = decodeUrl(
    useSelector<WithTable<ProfileApiDataDetails>, string>(({ tableState }) =>
      tableSelectors.getCellValueSelector(
        profileId,
        PROFILES_API_COLUMN_NAME.ClickURL,
        EDIT_PROFILE_API_COLUMN_DATA_MAPPING,
        TABLE_UNIQ_KEY[ADS_MANAGER_PROFILES_TABLE_ID]
      )(tableState, ADS_MANAGER_PROFILES_TABLE_ID)
    )
  );

  const existingValue = decodeUrl(channelSettings.clickUrl || '');
  const clickUrl = changedValue ?? existingValue;
  const [value, setValue] = useState<string>(clickUrl);

  useEffect(() => {
    isEditMode && setValue(clickUrl);
  }, [isEditMode, currentPage]);

  const updateCellValue = (inputValue: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_PROFILES_TABLE_ID,
        rowId: profileId,
        columnName: PROFILES_API_COLUMN_NAME.ClickURL,
        value: inputValue,
        existingValue: existingValue,
      })
    );
  };

  const debounceCellValueUpdate = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [profileId]
  );

  if (isEditMode) {
    const isDisabled = getIsColumnEditDisabled(
      channelSettings.profileStatus,
      profileDetails.adGroupReviewStatus?.reviewStatus,
      profileDetails.campaignStatus
    );
    const isInputInvalid = getIsInputInvalid(value);

    const onClickUrlChange = (newValue: string) => {
      setValue(newValue);
      debounceCellValueUpdate(newValue?.trim());
    };

    const onInputFocus = () => setFocussed(true);
    const onInputBlur = () => setFocussed(false);

    const showTooltip = isDisabled || (focussed && isInputInvalid);

    return makeTextInputWithPortaledTooltipColumn(() => ({
      value,
      inputState: getInputState(isInputInvalid),
      onChange: onClickUrlChange,
      onFocus: onInputFocus,
      onBlur: onInputBlur,
      isDirty: value !== existingValue,
      tooltipContent: getTooltipContent({
        intl,
        showTooltip,
        value,
        campaignStatus: profileDetails?.campaignStatus,
        adGroupReviewStatus: profileDetails.adGroupReviewStatus?.reviewStatus,
        profileStatus: channelSettings?.profileStatus,
      }),
      tooltipDisabled: !showTooltip,
      overwrittenTooltipClassnames: 'w-full',
      tooltipTheme: PortaledTooltipTheme.Dark,
      portaledTooltipPlacement: PortaledTooltipPlacement.Bottom,
      disabled: isDisabled,
      dataTestId: 'click_url',
    }))(profileData);
  }

  return makeExternalTextLinkColumn(
    ({ channelSettings: profileChannelSetting }: ProfileApiDataDetails) =>
      decodeUrl(profileChannelSetting.clickUrl || ''),
    ({ channelSettings: profileChannelSetting }: ProfileApiDataDetails) =>
      decodeUrl(profileChannelSetting?.clickUrl || ''),
    pendingFields?.includes(PROFILES_API_COLUMN_NAME.ClickURL)
  )(profileData);
};
RowCellElement.displayName = 'ProfileClickUrlCell';

export const clickUrlColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.ClickURL,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICK_URL,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const clickUrlFilterForProfile = createStringDataFieldFilter(
  PROFILES_API_COLUMN_NAME.ClickURL,
  I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICK_URL,
  isValidString()
);
