import {
  Alignment,
  ChartTime,
  KPICardV2HeaderType,
  KPICardV2Props,
  MetricCardV2CardState,
  MetricCardV2Props,
  NumberFormat,
  PeriodLabels,
  Placement,
  SelectV2OptionProps,
  Theme,
} from '@teikametrics/tm-design-system';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import { HeroMetricType, Metric } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { Money } from '../../../../../lib/types/Money';
import {
  AggregationPeriodType,
  SlideoutProductMetrics,
  SlideoutProductsGraphDataResponse,
  SlideoutProductsHeroMetricsData,
} from '../../../../../lib/types/SKUSharedTypes';
import {
  AsyncRequest,
  asyncRequestIsComplete,
  asyncRequestIsLoading,
} from '../../../../../lib/utilities/asyncRequest';
import {
  CONVERTED_DATE_FORMAT_FOR_GRAPH,
  TOOLTIP_DATE_FORMAT_FOR_GRAPH,
  getCoordinateValue,
} from '../../../../advertisingOptimization/components/ViewTrends/Performance/utils';
import {
  getCurrentValueContent,
  getStatus,
  getTooltipContentForMetric,
} from '../../../../advertisingOptimization/components/utils';
import { PlotDataItem } from '../../../../compass/containers/compassDashboard/businessGraphV2';
import {
  PURPLE_300,
  PURPLE_600,
} from '../../../../compass/containers/compassDashboard/businessGraphV2/types';
import { getDateForMiniMetricProp } from '../../../../compass/containers/compassDashboard/utils/business';
import { ChartTrendMetricFormatSelector } from '../../../../compass/types';
import { convertAmountToNumber } from '../../../../compass/utils';
import { getPillText } from '../../../../inventoryOptimization/containers/inventoryDashboard/utils';
import {
  MONETARY_FRACTION_DIGITS,
  NO_FRACTION_DIGITS,
  PERCENTAGE_FRACTION_DIGITS,
} from '../../../../../lib/types/CommonSharedTypes';
import { CurrencyCode } from '../../../../../lib/utilities/currency';

export const SLIDEOUT_PRODUCT_METRICS_TO_I18NKEY: Record<
  SlideoutProductMetrics,
  I18nKey
> = {
  [SlideoutProductMetrics.ACoS]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_ACOS,
  [SlideoutProductMetrics.AdSales]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SALES_TOTAL,
  [SlideoutProductMetrics.AdSpend]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SPEND,
  [SlideoutProductMetrics.Clicks]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CLICKS,
  [SlideoutProductMetrics.ROAS]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_ROAS,
  [SlideoutProductMetrics.TotalSales]:
    I18nKey.AO_DASHBOARD_TABLE_COLUMN_TOTAL_SALES,
  [SlideoutProductMetrics.UnitsSold]:
    I18nKey.PRODUCTS_SKU_CATALOG_TABLE_UNITS_SOLD,
  [SlideoutProductMetrics.AdUnitsSold]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_UNITS_SOLD,
  [SlideoutProductMetrics.SellThroughRate]:
    I18nKey.PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_SELL_THROUGH_RATE,
  [SlideoutProductMetrics.SalesRatio]:
    I18nKey.INVENTORY_DASHBOARD_METRIC_CARD_INVENTORY_SALES_RATIO_LABEL,
  [SlideoutProductMetrics.DaysOfInventory]:
    I18nKey.INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_DAYS_OF_INVENTORY,
  [SlideoutProductMetrics.InventoryAvailableQuantity]:
    I18nKey.INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_INVENTORY_AVAILABLE_LABEL,
  [SlideoutProductMetrics.EstGrossProfit]:
    I18nKey.CATALOG_PERFORMANCE_TABLE_COLUMN_EST_GROSS_PROFIT,
  [SlideoutProductMetrics.AggregateCOGS]:
    I18nKey.PRODUCTS_SKU_CATALOG_TABLE_COLUMN_COGS,
  [SlideoutProductMetrics.TACoS]: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_TACOS,
};

export const SLIDEOUT_PRODUCT_METRICS_TO_HERO_METRIC_TYPE: Record<
  SlideoutProductMetrics,
  HeroMetricType
> = {
  [SlideoutProductMetrics.ACoS]: HeroMetricType.Percent,
  [SlideoutProductMetrics.AdSales]: HeroMetricType.Money,
  [SlideoutProductMetrics.AdSpend]: HeroMetricType.Money,
  [SlideoutProductMetrics.Clicks]: HeroMetricType.Numeric,
  [SlideoutProductMetrics.ROAS]: HeroMetricType.Numeric,
  [SlideoutProductMetrics.TACoS]: HeroMetricType.Percent,
  [SlideoutProductMetrics.TotalSales]: HeroMetricType.Money,
  [SlideoutProductMetrics.UnitsSold]: HeroMetricType.Numeric,
  [SlideoutProductMetrics.AdUnitsSold]: HeroMetricType.Numeric,
  [SlideoutProductMetrics.SellThroughRate]: HeroMetricType.Percent,
  [SlideoutProductMetrics.SalesRatio]: HeroMetricType.Numeric,
  [SlideoutProductMetrics.DaysOfInventory]: HeroMetricType.Numeric,
  [SlideoutProductMetrics.InventoryAvailableQuantity]: HeroMetricType.Numeric,
  [SlideoutProductMetrics.EstGrossProfit]: HeroMetricType.Money,
  [SlideoutProductMetrics.AggregateCOGS]: HeroMetricType.Money,
};

export const getXAxisLabels = (
  response: SlideoutProductsGraphDataResponse,
  chartTime: ChartTime,
  intl: IntlShape,
  includeYearInLabels: boolean
) => {
  const dates =
    (chartTime === ChartTime.Weekly ? response.weekly : response.daily)?.map(
      (item) => item.date
    ) || [];
  if (chartTime === ChartTime.Weekly) {
    return dates.map((date): string => {
      return intl.formatMessage(
        { id: I18nKey.COMPASS_DASHBOARD_CHART_MINI_METRIC_WEEKLY_DATE_FORMAT },
        {
          startDateOfWeek: DateTime.fromISO(date)
            .startOf('week')
            .toFormat(CONVERTED_DATE_FORMAT_FOR_GRAPH),
          endDateOfWeek: DateTime.fromISO(date)
            .endOf('week')
            .toFormat(CONVERTED_DATE_FORMAT_FOR_GRAPH),
        }
      );
    });
  }
  const requiredDailyFormat = includeYearInLabels
    ? TOOLTIP_DATE_FORMAT_FOR_GRAPH
    : CONVERTED_DATE_FORMAT_FOR_GRAPH;
  return dates.map((date) => {
    return DateTime.fromISO(date).toFormat(requiredDailyFormat);
  });
};

export const getMetricsOptions = (
  intl: IntlShape,
  selectedMetrics: SlideoutProductMetrics[]
): SelectV2OptionProps<SlideoutProductMetrics>[] => {
  const metricKeys: SlideoutProductMetrics[] = [
    SlideoutProductMetrics.ACoS,
    SlideoutProductMetrics.AdSales,
    SlideoutProductMetrics.AdSpend,
    SlideoutProductMetrics.Clicks,
    SlideoutProductMetrics.ROAS,
    SlideoutProductMetrics.TotalSales,
    SlideoutProductMetrics.UnitsSold,
    SlideoutProductMetrics.AdUnitsSold,
    // ...(isInventoryOptimizationSubscribed
    //   ? [SlideoutProductMetrics.DaysOfInventory]
    //   : []),
    // SlideoutProductMetrics.InventoryAvailableQuantity,
    SlideoutProductMetrics.EstGrossProfit,
    SlideoutProductMetrics.AggregateCOGS,
    SlideoutProductMetrics.TACoS,
  ];

  return metricKeys.map((metricKey) => ({
    label: SLIDEOUT_PRODUCT_METRICS_TO_I18NKEY[metricKey]
      ? intl.formatMessage({
          id: SLIDEOUT_PRODUCT_METRICS_TO_I18NKEY[metricKey],
        })
      : SLIDEOUT_PRODUCT_METRICS_TO_I18NKEY[metricKey],
    value: metricKey,
    disabled: selectedMetrics.includes(metricKey),
  }));
};

const getMetricValueFractions = (
  metricType: SlideoutProductMetrics
): HeroMetricType => {
  switch (metricType) {
    case SlideoutProductMetrics.DaysOfInventory:
    case SlideoutProductMetrics.InventoryAvailableQuantity:
    case SlideoutProductMetrics.Clicks:
      return NO_FRACTION_DIGITS;
    case SlideoutProductMetrics.ACoS:
    case SlideoutProductMetrics.TACoS:
    case SlideoutProductMetrics.SellThroughRate:
      return PERCENTAGE_FRACTION_DIGITS;
    default:
      return MONETARY_FRACTION_DIGITS;
  }
};
const calculateDelta = (
  heroMetricType: HeroMetricType,
  metricType: SlideoutProductMetrics,
  currentValue?: Metric,
  previousValue?: Metric
): number => {
  const maxDecimalPlaces = getMetricValueFractions(metricType);
  if (isNil(previousValue) || isNil(currentValue)) return 0;
  let currentNumericValue = -1;
  let previousNumericValue = -1;
  if (heroMetricType !== HeroMetricType.Money) {
    currentNumericValue = currentValue as number;
    previousNumericValue = previousValue as number;
  }
  switch (heroMetricType) {
    case HeroMetricType.Money:
      const previousAmount = convertAmountToNumber(previousValue);
      const divideBy = previousAmount === 0 ? 1 : previousAmount;

      return (convertAmountToNumber(currentValue) - previousAmount) / divideBy;
    case HeroMetricType.Percent:
      return (
        (Number((currentNumericValue * 100).toFixed(maxDecimalPlaces)) -
          Number((previousNumericValue * 100).toFixed(maxDecimalPlaces))) /
        (Number((previousNumericValue * 100).toFixed(maxDecimalPlaces)) === 0
          ? 1
          : Number((previousNumericValue * 100).toFixed(maxDecimalPlaces)))
      );
    case HeroMetricType.Numeric:
    default:
      return (
        (Number(currentNumericValue.toFixed(maxDecimalPlaces)) -
          Number(previousNumericValue.toFixed(maxDecimalPlaces))) /
        (Number(previousNumericValue.toFixed(maxDecimalPlaces)) === 0
          ? 1
          : Number(previousNumericValue.toFixed(maxDecimalPlaces)))
      );
  }
};

export const getKPICards = (
  intl: IntlShape,
  selectedMetrics: SlideoutProductMetrics[],
  performanceMetricResponse: {
    readonly current?: SlideoutProductsHeroMetricsData;
    readonly previous?: SlideoutProductsHeroMetricsData;
  },
  isLoading: boolean,
  selectedMetricIndex: number,
  showPreviousData?: boolean
): KPICardV2Props[] => {
  return selectedMetrics.map((metric, index): KPICardV2Props => {
    const currentValue = performanceMetricResponse?.current?.[metric] as Metric;
    const previousValue = performanceMetricResponse?.previous?.[
      metric
    ] as Metric;
    const content = getCurrentValueContent(
      intl,
      SLIDEOUT_PRODUCT_METRICS_TO_HERO_METRIC_TYPE[metric],
      metric,
      currentValue
    );
    const delta = calculateDelta(
      SLIDEOUT_PRODUCT_METRICS_TO_HERO_METRIC_TYPE[metric],
      metric as any,
      currentValue,
      previousValue
    );
    const status = getStatus(delta);
    const state = isLoading
      ? MetricCardV2CardState.Loading
      : MetricCardV2CardState.Success;
    return {
      content,
      cardTrend: {
        pillText: getPillText(intl, delta),
        status: status,
      },
      isActive: index === selectedMetricIndex,
      dataTestId: 'poducts_slideout_kpi_v2_card',
      previousValue: showPreviousData
        ? getCurrentValueContent(
            intl,
            SLIDEOUT_PRODUCT_METRICS_TO_HERO_METRIC_TYPE[metric],
            metric,
            previousValue
          )
        : undefined,
      state,
      primaryMetric: true,
      isFooterActive: showPreviousData,
      previousDaysLabel: intl.formatMessage({
        id: I18nKey.GENERIC_PREV,
      }),
      headerType: KPICardV2HeaderType.Select,
      options: getMetricsOptions(intl, selectedMetrics),
      selectPlaceholder: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_TARGET_LABELS_NO_TAG,
      }),
      header: metric,
      isNegativeUpTrend: [
        SlideoutProductMetrics.ACoS,
        SlideoutProductMetrics.TACoS,
      ].includes(metric),
      showInfoIcon: true,
      showHideActionTooltip: false,
      tooltipProps: {
        content: getTooltipContentForMetric(metric),
        position: {
          placement: Placement.Bottom,
          alignment: Alignment.Center,
          placementOffsetInPixels: 8,
        },
        theme: Theme.Dark,
      },
    };
  });
};

const getAggregatedDataMetrics = (
  response: SlideoutProductsGraphDataResponse
) => {
  const initialData: any = {
    adSales: null,
    adSpend: null,
    totalSales: null,
    aggregatedCogs: null,
    estimatedGrossProfit: null,
    inventoryQuantity: null,
    daysOfInventory: null,
    clicks: null,
    roas: null,
    acos: null,
    tacos: null,
    unitSold: null,
    adUnitsSold: null,
  };

  return response.daily.reduce((aggregatedData, item) => {
    Object.keys(aggregatedData).forEach((key) => {
      const isMoney =
        SLIDEOUT_PRODUCT_METRICS_TO_HERO_METRIC_TYPE[
          key as SlideoutProductMetrics
        ] === HeroMetricType.Money;
      if (item[key] && aggregatedData[key]) {
        if (isMoney) {
          aggregatedData = {
            ...aggregatedData,
            [key]: {
              ...aggregatedData[key],
              amount:
                (aggregatedData[key] as Money).amount +
                (item[key] as Money).amount,
            },
          };
        } else {
          aggregatedData = {
            ...aggregatedData,
            [key]: aggregatedData[key] + item[key],
          };
        }
      } else if (item[key]) {
        aggregatedData = {
          ...aggregatedData,
          [key]: item[key],
        };
      }
    });
    return {
      ...aggregatedData,
      tacos:
        aggregatedData?.adSpend?.amount && aggregatedData?.totalSales?.amount
          ? aggregatedData?.adSpend?.amount / aggregatedData?.totalSales?.amount
          : null,
      roas:
        aggregatedData?.adSpend?.amount && aggregatedData?.adSales?.amount
          ? aggregatedData?.adSales?.amount / aggregatedData?.adSpend?.amount
          : null,
      acos:
        aggregatedData?.adSpend?.amount && aggregatedData?.adSales?.amount
          ? aggregatedData?.adSpend?.amount / aggregatedData?.adSales?.amount
          : null,
    };
  }, initialData);
};

export const getAggregatedHeroMetrics = (graphData: {
  current: SlideoutProductsGraphDataResponse;
  previous?: SlideoutProductsGraphDataResponse;
}) => {
  return {
    current: getAggregatedDataMetrics({ ...graphData.current }),
    previous: graphData.previous
      ? getAggregatedDataMetrics({ ...graphData.previous })
      : undefined,
  };
};

export const getYAxisFormat = (
  selectedMetrics: SlideoutProductMetrics[],
  selectedMetricIndex: number
) => {
  switch (
    SLIDEOUT_PRODUCT_METRICS_TO_HERO_METRIC_TYPE[
      selectedMetrics[selectedMetricIndex]
    ]
  ) {
    case HeroMetricType.Numeric:
      return NumberFormat.Number;
    case HeroMetricType.Percent:
      return NumberFormat.Percent;
    case HeroMetricType.Money:
    default:
      return NumberFormat.Currency;
  }
};

export const isAllPerformanceMetricsEmpty = (
  data?: SlideoutProductsGraphDataResponse
) => {
  if (data?.daily) {
    for (let key in data.daily) {
      if (data.daily[key] && key !== 'date') {
        return false;
      }
    }
  }

  if (data?.weekly) {
    for (let key in data.weekly) {
      if (data.weekly[key] && key !== 'date') {
        return false;
      }
    }
  }
  return true;
};

export const getChartMetrics = (
  graphDataRequest: AsyncRequest<{
    current: SlideoutProductsGraphDataResponse;
    previous?: SlideoutProductsGraphDataResponse;
  }>,
  comparePreviousPeriod: boolean | undefined,
  selectedMetrics: SlideoutProductMetrics[],
  selectedMetricIndex: number,
  chartTimeFormat: ChartTime,
  intl: IntlShape,
  currency: CurrencyCode,
  maxLabels: number
) => {
  if (asyncRequestIsLoading(graphDataRequest)) {
    return [];
  }
  if (asyncRequestIsComplete(graphDataRequest)) {
    return [
      {
        className: 'bg-orange-500',
        header: intl.formatMessage({
          id: SLIDEOUT_PRODUCT_METRICS_TO_I18NKEY[
            selectedMetrics[selectedMetricIndex]
          ],
        }),
        data: [
          {
            label: PeriodLabels.Current,
            data: {
              color: PURPLE_600,
              type: 'spline',
              yAxis: 0,
              name: intl.formatMessage({
                id: SLIDEOUT_PRODUCT_METRICS_TO_I18NKEY[
                  selectedMetrics[selectedMetricIndex]
                ],
              }),
              data: [
                ...(
                  graphDataRequest.result.current?.[
                    chartTimeFormat === ChartTime.Weekly ? 'weekly' : 'daily'
                  ] || []
                )
                  .map((graphItem) => {
                    const metric = selectedMetrics[selectedMetricIndex];
                    const metricType =
                      SLIDEOUT_PRODUCT_METRICS_TO_HERO_METRIC_TYPE[metric];
                    const value =
                      metricType === HeroMetricType.Money
                        ? (graphItem[metric] as Money)?.amount
                        : graphItem[metric];
                    const updatedValue = !isNil(value)
                      ? (value as string)
                      : undefined;
                    let style: 'currency' | 'decimal' | 'percent' | 'units' =
                      metricType === HeroMetricType.Money
                        ? 'currency'
                        : 'decimal';

                    if (metricType === HeroMetricType.Percent) {
                      style = 'percent';
                    }

                    return {
                      custom: {
                        customDate: getDateForMiniMetricProp(
                          intl,
                          graphItem.date,
                          chartTimeFormat === ChartTime.Weekly
                            ? ChartTrendMetricFormatSelector.Week
                            : ChartTrendMetricFormatSelector.Day
                        ),
                        name: intl.formatMessage({
                          id: SLIDEOUT_PRODUCT_METRICS_TO_I18NKEY[metric],
                        }),
                        chartDataPeriod: AggregationPeriodType.Current,
                        total:
                          !isNil(updatedValue) &&
                          !isNaN(parseFloat(updatedValue)) &&
                          intl.formatNumber(parseFloat(updatedValue), {
                            currency: currency,
                            style,
                            maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                            minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                          }),
                      },
                      y:
                        !isNil(updatedValue) && !isNaN(parseFloat(updatedValue))
                          ? getCoordinateValue(
                              parseFloat(updatedValue),
                              getYAxisFormat(
                                selectedMetrics,
                                selectedMetricIndex
                              )
                            )
                          : null,
                    };
                  })
                  .slice(0, maxLabels),
              ],
            },
          },
          {
            data: {
              color: PURPLE_300,
              type: 'spline',
              yAxis: 0,
              name: intl.formatMessage({
                id: SLIDEOUT_PRODUCT_METRICS_TO_I18NKEY[
                  selectedMetrics[selectedMetricIndex]
                ],
              }),
              visible: comparePreviousPeriod,
              data: [
                ...(
                  graphDataRequest.result.previous?.[
                    chartTimeFormat === ChartTime.Weekly ? 'weekly' : 'daily'
                  ] || []
                )
                  .map((graphItem) => {
                    const metric = selectedMetrics[selectedMetricIndex];
                    const metricType =
                      SLIDEOUT_PRODUCT_METRICS_TO_HERO_METRIC_TYPE[metric];
                    const value =
                      metricType === HeroMetricType.Money
                        ? (graphItem[metric] as Money)?.amount
                        : graphItem[metric];
                    const updatedValue = !isNil(value)
                      ? (value as string)
                      : undefined;
                    let style: 'currency' | 'decimal' | 'percent' | 'units' =
                      metricType === HeroMetricType.Money
                        ? 'currency'
                        : 'decimal';

                    if (metricType === HeroMetricType.Percent) {
                      style = 'percent';
                    }

                    return {
                      custom: {
                        customDate: getDateForMiniMetricProp(
                          intl,
                          graphItem.date,
                          chartTimeFormat === ChartTime.Weekly
                            ? ChartTrendMetricFormatSelector.Week
                            : ChartTrendMetricFormatSelector.Day
                        ),
                        name: intl.formatMessage({
                          id: SLIDEOUT_PRODUCT_METRICS_TO_I18NKEY[metric],
                        }),
                        chartDataPeriod: AggregationPeriodType.Previous,
                        total:
                          !isNil(updatedValue) &&
                          !isNaN(parseFloat(updatedValue)) &&
                          intl.formatNumber(parseFloat(updatedValue), {
                            currency: currency,
                            style,
                            maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                            minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                          }),
                      },
                      y:
                        !isNil(updatedValue) && !isNaN(parseFloat(updatedValue))
                          ? getCoordinateValue(
                              parseFloat(updatedValue),
                              getYAxisFormat(
                                selectedMetrics,
                                selectedMetricIndex
                              )
                            )
                          : undefined,
                    };
                  })
                  .slice(0, maxLabels),
                ,
              ],
            },
          },
        ],
      },
    ];
  }
  return [];
};

export const checkIfNoDataPoints = (
  options: MetricCardV2Props[],
  comparePreviousPeriod?: boolean
) => {
  if (!options.length) {
    return true;
  }

  return (
    !options[0]?.data?.[0]?.data?.data?.filter(
      (point: PlotDataItem) => !!point.y
    ).length &&
    (comparePreviousPeriod
      ? !options[0]?.data?.[1]?.data?.data?.filter(
          (point: PlotDataItem) => !!point.y
        ).length
      : true)
  );
};

export const getYAxisMinValue = (options: MetricCardV2Props[]) => {
  let minValue = 0;
  options?.forEach(({ data }) => {
    data?.forEach((value) => {
      value.data?.data?.forEach((_val: any) => {
        if (_val.y && minValue > _val.y) {
          minValue = _val.y;
        }
      });
    });
  });

  return minValue;
};
