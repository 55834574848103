import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { TargetsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { TARGETS_API_COLUMN_NAME } from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = ({
  merchantCountry,
  ...targetDetails
}) => {
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  return makeCurrencyColumn<TargetsDetails>(
    ({ targetDetails: { campaignTotalBudget } }) =>
      campaignTotalBudget && {
        amount: Number(campaignTotalBudget.amount),
        currency: currencyCode || campaignTotalBudget.currency || CURRENCY_CODE,
      },
    targetDetails.pendingFields?.includes(TARGETS_API_COLUMN_NAME.TotalBudget)
  )(targetDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const totalBudgetColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.TotalBudget,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_LIFETIME_BUDGET,
  RowCellElement,
  gridColumnWidth: '160px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const totalBudgetFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    TARGETS_API_COLUMN_NAME.TotalBudget,
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_LIFETIME_BUDGET,
    currency,
    isValidNumber(0)
  );
