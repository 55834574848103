import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';
import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { CAMPAIGNS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) =>
  makeCurrencyColumn<CampaignDetails>(
    ({
      campaignPerformance: { relatedClickRevenue },
      channelSettings: { startDate },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        relatedClickRevenue?.amount
          ? Number(relatedClickRevenue.amount)
          : undefined
      );

      return isNil(performanceMetricValue)
        ? undefined
        : {
            amount: performanceMetricValue,
            currency:
              getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
              relatedClickRevenue?.currency ||
              CURRENCY_CODE,
          };
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const relatedClickRevenueColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.RelatedClickRevenue,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_RELATED_CLICK_REVENUE,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const relatedClickRevenueFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.RelatedClickRevenue,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_RELATED_CLICK_REVENUE,
    currency,
    isValidNumber(0)
  );
