import { useIntl } from 'react-intl';

import {
  PlugDisconnectedIcon,
  TableEmptyStateProps,
  TableProperties,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { LiteTable } from '../../../../../containers/tableV2/liteTable';
import { MerchantsGroup } from '../authProductData';
import { GetAccountListColumnsDefinition } from './tableColumns/columns';

export enum MerchantCardVariant {
  Default = 'Default',
  Completed = 'Completed',
  Disabled = 'Disabled',
}

const emptyStateProps: TableEmptyStateProps = {
  titleI18nKey: I18nKey.PAGINATED_TABLE_NO_DATA_HEADER,
  descriptionI18nKey: I18nKey.TABLE_NO_DATA_WITH_FILTER_DESCRIPTION_TRY_NEW,
  icon: PlugDisconnectedIcon,
  className: 'h-240 w-full',
};

export interface AuthorizeMerchantTableProps {
  readonly handleOauthPopup: (data: any) => void;
  readonly merchants: MerchantsGroup[];
}

export interface AuthorizeMerchantTableConnect extends Record<string, unknown> {
  readonly handleOauthPopup: (data: any) => void;
}

export const TABLE_ID = 'AUTHORIZE_MERCHANT_TABLE';

export const AuthorizeMerchantTable: React.FC<AuthorizeMerchantTableProps> = (
  props
) => {
  const intl = useIntl();
  const columns = GetAccountListColumnsDefinition(intl);
  const { merchants } = props;

  return (
    <LiteTable
      columns={
        [
          {
            id: 'FLASH_MERCHANTS_AUTHORIZE_MERCHANT_CARD_TABLE_ID',
            columns,
          },
        ] as TableProperties<MerchantsGroup>['columns']
      }
      tableId={TABLE_ID}
      tableData={{
        handleOauthPopup: props.handleOauthPopup,
      }}
      tableClassName="border-solid overflow-hidden flex grow shrink-0 basis-0"
      rowData={merchants}
      currencyCode="USD"
      headerClass="bg-white h-42"
      tableEmptyStateClass={'w-full'}
      emptyState={emptyStateProps}
    />
  );
};
