import { useLocation, useNavigate } from 'react-router-dom';
import { Type } from '@teikametrics/tm-design-system';
import noop from 'lodash/noop';
import { createContext, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import polling from 'rx-polling';
import { Subscription, defer } from 'rxjs';
import { NavPaths } from '../../../../../../NavPaths';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../../../../containers/notificationProvider';
import { getCurrentAccountFromContext } from '../../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../../containers/userProvider/userProvider';
import { TargetSegments } from '../../../../../../core/campaignFlexibility/types';
import { createAOApiClient } from '../../../../../../lib/clients/AOApiClient';
import {
  AdType,
  AddedBy,
  Brand,
  ChatGPTKeywordsRequest,
  ChatGPTRequestKeywordResponse,
  CreateTargetKeywordsRequest,
  MAP_ADTYPE_TO_ADVERTISING_TYPE,
} from '../../../../../../lib/types/AOSharedTypes';
import {
  MatchType,
  TargetInfo,
  TargetSegment,
} from '../../../../../../lib/types/CampaignFlexibilitySharedTypes';
import { MerchantType } from '../../../../../../lib/types/Fam';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { KeywordAdgroupInfo } from '../../../../../../lib/types/KeywordAction';
import { AMAZON_SALES_CHANNEL_ID } from '../../../../../../lib/types/SalesChannels';
import { getDefaultBrandTags } from '../../utils/keywordUtils';
import { KeywordType } from '../createKeywordsMenu';
import { transformKeywords } from './utils';

const SYNC_STATUS_POLLING_INTERVAL = 5000; // 5s
const SYNC_STATUS_POLLING_MAX_TRIES = 50;

export enum KeywordsRequestState {
  None,
  Success,
  Failed,
  Loading,
  NoResult,
}

export interface CreateChatGPTKeywordsProviderProps {
  readonly merchantCountryId: string;
  readonly merchantType: MerchantType;
  readonly salesChannelId: string;
  readonly children: JSX.Element;
  readonly adType: AdType;
}

export interface CreateChatGPTKeywordsState {
  readonly brands: Brand[];
  readonly isBrandsLoading: boolean;
  readonly createBrands: (brandNames: string) => void;
  readonly updateBrand: (brandId: string, brandName: string) => void;
  readonly deleteBrand: (brand: Brand) => void;
  readonly selectedAdGroup: KeywordAdgroupInfo | undefined;
  readonly setSelectedAdGroup: (adGroup: KeywordAdgroupInfo) => void;
  readonly brandTags: MatchType[];
  readonly targetSegments: TargetSegment[];
  readonly setBrandTags: (brandTags: MatchType[]) => void;
  readonly setTargetSegments: (targetSegments: TargetSegment[]) => void;
  readonly keywordsRequestState: KeywordsRequestState;
  readonly setKeywordsRequestState: (state: KeywordsRequestState) => void;
  readonly requestKeywords: () => void;
  readonly requestKeywordsResponse?: ChatGPTRequestKeywordResponse;
  readonly keywords: TargetInfo[];
  readonly setKeywords: (targets: TargetInfo[]) => void;
  readonly getKeywordsResult: () => void;
  readonly resetKeywords: () => void;
  readonly resetState: () => void;
  readonly submitKeywords: () => Promise<void>;
  readonly merchantCountryId: string;
  readonly merchantType: MerchantType;
  readonly salesChannelId: string;
  readonly adType: AdType;
  readonly isFeedbackSubmitted: boolean;
  readonly isKeywordsSubmitted: boolean;
  readonly setIsFeedbackSubmitted: (isSubmitted: boolean) => void;
  readonly setIsKeywordsSubmitted: (isSubmitted: boolean) => void;
}

const initialState: CreateChatGPTKeywordsState = {
  brands: [],
  isBrandsLoading: false,
  createBrands: noop,
  updateBrand: noop,
  deleteBrand: noop,
  selectedAdGroup: undefined,
  setSelectedAdGroup: noop,
  brandTags: [],
  targetSegments: [],
  setBrandTags: noop,
  setTargetSegments: noop,
  keywordsRequestState: KeywordsRequestState.None,
  setKeywordsRequestState: noop,
  requestKeywords: noop,
  keywords: [],
  setKeywords: noop,
  getKeywordsResult: noop,
  resetKeywords: noop,
  resetState: noop,
  submitKeywords: () => Promise.resolve(),
  merchantCountryId: '',
  merchantType: MerchantType.Seller,
  salesChannelId: '',
  adType: AdType.SponsoredProducts,
  isFeedbackSubmitted: false,
  isKeywordsSubmitted: false,
  setIsFeedbackSubmitted: noop,
  setIsKeywordsSubmitted: noop,
};

export const CreateChatGPTKeywordsContext =
  createContext<CreateChatGPTKeywordsState>(initialState);
CreateChatGPTKeywordsContext.displayName = 'CreateChatGPTKeywordsContext';
const { Provider } = CreateChatGPTKeywordsContext;

export const CreateChatGPTKeywordsProvider: React.FC<
  CreateChatGPTKeywordsProviderProps
> = ({ merchantCountryId, merchantType, salesChannelId, adType, children }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const userContext = useContext<UserContextState>(UserContext);
  const toasts = useContext<NotificationContextState>(NotificationContext);
  const location = useLocation();
  const { id: accountId } = getCurrentAccountFromContext(userContext)!;
  const [brands, setBrands] = useState<Brand[]>([]);
  const [isBrandsLoading, setIsBrandsLoading] = useState<boolean>(false);
  const [selectedAdGroup, setSelectedAdGroup] = useState<KeywordAdgroupInfo>();
  const [brandTags, setBrandTags] = useState<MatchType[]>(
    getDefaultBrandTags(KeywordType.ChatGPT)
  );
  const [targetSegments, setTargetSegments] = useState<TargetSegment[]>([]);
  const [keywordsRequestState, setKeywordsRequestState] =
    useState<KeywordsRequestState>(KeywordsRequestState.None);
  const [requestKeywordsResponse, setRequestKeywordsResponse] =
    useState<ChatGPTRequestKeywordResponse>();
  const [keywords, setKeywords] = useState<TargetInfo[]>([]);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [isKeywordsSubmitted, setIsKeywordsSubmitted] = useState(false);
  const aoClient = createAOApiClient(userContext.userInfo.idToken!);

  const loadBrands = () => {
    setIsBrandsLoading(true);
    aoClient
      .getBrands(accountId, merchantCountryId)
      .then((brands) => {
        setIsBrandsLoading(false);
        setBrands(brands);
      })
      .catch(() => {
        setIsBrandsLoading(false);
      });
  };

  const createBrands = (brandNames: string) => {
    aoClient
      .createBrands(accountId, {
        merchantCountryId,
        brandNames,
      })
      .then((response) => {
        setBrands([...brands, ...response]);
      });
  };

  const deleteBrand = (brand: Brand) => {
    aoClient
      .deleteBrand(accountId, {
        merchantCountryId: merchantCountryId,
        brandDetail: brand,
      })
      .then(() => {
        setBrands(brands.filter((b) => b.brandId !== brand.brandId));
      });
  };

  const updateBrand = (brandId: string, brandName: string) => {
    aoClient
      .updateBrand(accountId, {
        merchantCountryId: merchantCountryId,
        brandDetail: {
          brandId,
          brandName,
        },
      })
      .then((updatedBrand) => {
        const brandIndex = brands.findIndex(
          (brand) => brand.brandId === brandId
        );
        setBrands((brands) => [
          ...brands.slice(0, brandIndex),
          updatedBrand,
          ...brands.slice(brandIndex + 1),
        ]);
      });
  };

  const requestKeywords = () => {
    const request: ChatGPTKeywordsRequest = {
      adGroups: [
        {
          adGroupId: selectedAdGroup!.id,
          campaignId: selectedAdGroup!.campaign!.id,
        },
      ],
      matchTypes: brandTags as string[],
      advertisingType: adType,
      tags: targetSegments as string[],
      merchantCountryId,
      salesChannelId,
      merchantType,
    };

    aoClient
      .requestChatGPTKeywords(accountId, request)
      .then((response) => {
        setRequestKeywordsResponse(response);
      })
      .catch(() => {
        setKeywordsRequestState(KeywordsRequestState.Failed);
      });
  };

  const getKeywordsResult = () => {
    const request: ChatGPTRequestKeywordResponse = {
      ...requestKeywordsResponse!,
      matchTypes: brandTags,
    };
    aoClient.getChatGPTKeywords(accountId, request).then((response) => {
      const newKeywords = transformKeywords(
        response.keywords.map((keyword) => keyword.targetText),
        brandTags
      );

      setKeywords(newKeywords);
      setKeywordsRequestState(
        response.keywords.length > 1
          ? KeywordsRequestState.Success
          : KeywordsRequestState.NoResult
      );
      toasts.addNotification({
        headline: intl.formatMessage({
          id: I18nKey.GENERIC_SUCCESS,
        }),
        description: intl.formatMessage(
          {
            id: I18nKey.CREATE_KEYWORDS_MODAL_KEYWORDS_GENERATED,
          },
          {
            keywords: newKeywords.length,
          }
        ),
        type: Type.Success,
        dataTestId: 'CreateKeywordsSuccess',
      });
    });
  };

  useEffect(() => {
    let subscription: Subscription;

    if (!requestKeywordsResponse) {
      return;
    }

    (async () => {
      const keywordsRequestStatus$ = defer(() =>
        aoClient.getChatGPTKeywordsStatus(accountId, requestKeywordsResponse)
      );

      subscription = polling(keywordsRequestStatus$, {
        interval: SYNC_STATUS_POLLING_INTERVAL,
        attempts: SYNC_STATUS_POLLING_MAX_TRIES,
      }).subscribe(
        (response) => {
          if (response.statusDetail.status === 'success') {
            subscription.unsubscribe();
            getKeywordsResult();
          } else if (
            ['failed', 'error'].includes(response.statusDetail.status)
          ) {
            setKeywordsRequestState(KeywordsRequestState.Failed);
            subscription.unsubscribe();
          }
        },
        () => {
          setKeywordsRequestState(KeywordsRequestState.Failed);
          subscription.unsubscribe();
        }
      );
    })();

    return () => {
      return subscription?.unsubscribe();
    };
  }, [requestKeywordsResponse]);

  const resetKeywords = () => {
    setKeywords([]);
    setRequestKeywordsResponse(undefined);
    setKeywordsRequestState(KeywordsRequestState.None);
  };

  const resetState = () => {
    setSelectedAdGroup(undefined);
    setKeywords([]);
    setRequestKeywordsResponse(undefined);
    setKeywordsRequestState(KeywordsRequestState.None);
    setBrandTags(getDefaultBrandTags(KeywordType.ChatGPT));
    setTargetSegments([]);
    setIsFeedbackSubmitted(false);
    setIsKeywordsSubmitted(false);
  };

  const submitKeywords = () => {
    const request: CreateTargetKeywordsRequest = {
      targets: keywords.map((keyword) => ({
        searchValue: keyword.searchText,
        matchType: keyword.matchType,
        adGroup: {
          adGroupId: selectedAdGroup?.id!,
          campaignId: selectedAdGroup?.campaign?.id!,
          targetSegments: selectedAdGroup?.targetSegments! as TargetSegments[],
        },
      })),
      advertisingType: MAP_ADTYPE_TO_ADVERTISING_TYPE[adType],
      merchantCountryId: merchantCountryId,
      salesChannelId: salesChannelId,
      merchantType,
      addedBy: AddedBy.ChatGPT,
      isNegativeKeywords: false,
    };
    return aoClient.createTargets(accountId, false, request);
  };

  useEffect(() => {
    if (merchantCountryId && accountId) {
      loadBrands();
    }
  }, [merchantCountryId, accountId]);

  useEffect(() => {
    if (!location.pathname.includes('create-chatgpt')) {
      resetState();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location && location.pathname.includes('create-chatgpt')) {
      navigate(
        salesChannelId === AMAZON_SALES_CHANNEL_ID
          ? NavPaths.AoAmazonSponsoredProductsKeywordTargetsChatGPT
          : NavPaths.AoWalmartSponsoredProductsKeywordTargetsChatGPT
      );
    }
  }, []);

  return (
    <Provider
      value={{
        brands,
        isBrandsLoading,
        createBrands,
        updateBrand,
        deleteBrand,
        selectedAdGroup,
        setSelectedAdGroup,
        brandTags,
        targetSegments,
        setBrandTags,
        setTargetSegments,
        keywordsRequestState,
        setKeywordsRequestState,
        requestKeywords,
        requestKeywordsResponse,
        keywords,
        setKeywords,
        getKeywordsResult,
        resetKeywords,
        resetState,
        submitKeywords,
        merchantCountryId,
        merchantType,
        salesChannelId,
        adType,
        isFeedbackSubmitted,
        isKeywordsSubmitted,
        setIsFeedbackSubmitted,
        setIsKeywordsSubmitted,
      }}
    >
      {children}
    </Provider>
  );
};
