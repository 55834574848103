import { useIntl } from 'react-intl';

import {
  PortaledTooltipPlacement,
  createMultiSelectDataFieldFilter,
  AlertTriangleIcon as warningSVG,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeTextColumn,
  makeTextWithIconColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TooltipText } from '../reviewStatusTooltipText';
import { ADGROUP_REVIEW_STATUS_MAPPER, TableDataAdsManager } from '../types';
import { PROFILES_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = (props) => {
  const intl = useIntl();

  const getColumnText = ({ profileDetails }: ProfileApiDataDetails) =>
    profileDetails?.adGroupReviewStatus
      ? intl.formatMessage({
          id: ADGROUP_REVIEW_STATUS_MAPPER[
            profileDetails?.adGroupReviewStatus?.reviewStatus
          ],
        })
      : undefined;

  const showAdgroupReviewStatusTooltip =
    props.profileDetails?.adGroupReviewStatus?.reviewStatus &&
    props.profileDetails?.adGroupReviewStatus?.reviewReason?.length;

  if (showAdgroupReviewStatusTooltip) {
    const tooltipText =
      props?.profileDetails?.adGroupReviewStatus?.reviewReason;
    return makeTextWithIconColumn(
      getColumnText,
      warningSVG,
      <TooltipText comments={tooltipText} />,
      true,
      PortaledTooltipPlacement.Right
    )(props);
  }

  return makeTextColumn(getColumnText, false, 'normal-case')(props);
};
RowCellElement.displayName = 'RowCellElement';

export const adGroupReviewStatusColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.AdGroupReviewStatus,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_ADGROUP_REVIEW_STATUS,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '200px',
  className: 'text-right',
};

export const adGroupReviewStatusFilterProfile =
  createMultiSelectDataFieldFilter(
    PROFILES_API_COLUMN_NAME.AdGroupReviewStatus,
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_ADGROUP_REVIEW_STATUS,
    [
      SelectFilterOption.AdGroupReviewStatusPending,
      SelectFilterOption.AdGroupReviewStatusInProgress,
      SelectFilterOption.AdGroupReviewStatusCompleted,
      SelectFilterOption.AdGroupReviewStatusCancelled,
    ]
  );
