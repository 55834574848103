import isUndefined from 'lodash/isUndefined';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  CrossMiniIcon,
  LightningIcon,
  Modal,
  Spinner,
  TextLink,
} from '@teikametrics/tm-design-system';

import { getCurrentAccountFromContext } from '../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../containers/userProvider/userProvider';
import { createBillingApiClient } from '../../../lib/clients/BillingApiClient';
import { createFAMApiClient } from '../../../lib/clients/FAMApiClient';
import { PaginatedRequest } from '../../../lib/clients/types';
import { ZuoraSignature } from '../../../lib/types/Billing';
import { HOW_TO_CHANGE_PLAN_LINK } from '../../../lib/types/CommonSharedTypes';
import { Role } from '../../../lib/types/Fam';
import I18nKey from '../../../lib/types/I18nKey';
import { SortOrder } from '../../../lib/types/Sort';
import { ShadowyAlertBox } from '../../../modules/account/components/disableAutomationModal';
import PayOutstandingBalance from './PayOutstandingBalance';
import {
  ZuoraResponseError,
  removeZuoraIframe,
  renderZuoraIframe,
} from '../../../lib/utilities/zuora';

export enum PaymentButtonStatus {
  AddNewPayment = 'AddNewPayment',
  PayBalance = 'PayBalance',
  Awesome = 'Awesome',
}

export enum DelinquentAccountExperience {
  Enforced,
  Delinquent,
  Locked,
}

interface OutstandingBalanceModalProps {
  readonly onContinueClicked?: () => void;
  readonly onCancelClicked?: () => void;
  readonly experience: DelinquentAccountExperience;
  readonly showIframe: boolean;
  readonly loadingIframe: boolean;
  readonly showErrorText: boolean;
}

interface DelinquentAccountModalWrapperProps {
  readonly onCancelClicked?: () => void;
  readonly experience: DelinquentAccountExperience;
}

export const DelinquentAccountModalWrapper: React.FC<
  DelinquentAccountModalWrapperProps
> = ({ onCancelClicked, experience }) => {
  const [showIframe, setShowIframe] = useState(false);
  const [refId, setRefId] = useState<string>('');
  const [loadingIframe, setLoadingIframe] = useState<boolean>(false);
  const [zuoraSignature, setZuoraSignature] = useState<ZuoraSignature>();
  const [zuoraSuccess, setZuoraSuccess] = useState<boolean>(false);
  const [zuoraError, setZuoraError] = useState<ZuoraResponseError>();
  const [showErrorText, setShowErrorText] = useState<boolean>(false);

  const userContext = useContext<UserContextState>(UserContext);
  const account = getCurrentAccountFromContext(userContext)!;
  const famClient = createFAMApiClient(userContext.userInfo.idToken!);
  const billingClient = createBillingApiClient(userContext.userInfo.idToken!);

  const onClose = () => {
    removeZuoraIframe();
    setShowIframe(false);
    onCancelClicked && onCancelClicked();
  };

  const onAddPayment = async () => {
    setShowIframe(true);
    setLoadingIframe(true);

    try {
      const signature = await billingClient.getZuoraSignature();
      setZuoraSignature(signature);
    } catch (err) {
      setLoadingIframe(false);
    }
  };

  const onIframeLoad = () => {
    setLoadingIframe(false);
  };

  useEffect(() => {
    if (zuoraSignature) {
      renderZuoraIframe(
        zuoraSignature,
        onIframeLoad,
        setZuoraSuccess,
        setRefId,
        setZuoraError
      );
    }
  }, [zuoraSignature]);

  useEffect(() => {
    if (!isUndefined(zuoraError) && !zuoraError?.success) {
      handlePaymentError();
    }
    if (zuoraSuccess && refId) {
      createZuoraAccount().then((success) => {
        if (success) {
          onClose();
        } else {
          handlePaymentError();
        }
      });
    }
  }, [zuoraSuccess, refId, zuoraError]);

  const handlePaymentError = () => {
    setLoadingIframe(false);
    setShowErrorText(true);
    setZuoraSuccess(false);
    removeZuoraIframe();
    setShowIframe(false);
  };

  const createZuoraAccount = async () => {
    setShowErrorText(false);
    setLoadingIframe(true);
    const request: PaginatedRequest = {
      itemsPerPage: 100,
      page: 1,
      sorts: [{ column: 'role', direction: SortOrder.Asc }],
      filters: [],
    };
    const users = await famClient.getUsers(account.id)(request);
    const billingContact = users.items.find(
      (item) => item.role.role === Role.ACCOUNT_OWNER
    );
    return billingClient
      .createZuoraAccount(account.id, refId, {
        firstName: billingContact?.user.firstName!,
        lastName: billingContact?.user.lastName!,
        email: billingContact?.user.email!,
      })
      .then(() => true)
      .catch(() => false);
  };

  return (
    <DelinquentAccountModal
      experience={experience}
      onCancelClicked={onClose}
      onContinueClicked={onAddPayment}
      showIframe={showIframe}
      loadingIframe={loadingIframe}
      showErrorText={showErrorText}
    />
  );
};

export const DelinquentAccountModal: React.FC<OutstandingBalanceModalProps> = ({
  onCancelClicked,
  onContinueClicked,
  experience,
  showIframe,
  loadingIframe,
  showErrorText,
}) => {
  const intl = useIntl();

  let textKeys: I18nKey[] = [];
  let alertKeys: I18nKey[] = [];
  switch (experience) {
    case DelinquentAccountExperience.Delinquent:
      textKeys = [
        I18nKey.BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_TITLE,
        I18nKey.BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_BODY,
        I18nKey.BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_NOTE,
        I18nKey.BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_LEARN_MORE,
        I18nKey.BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_BUTTON,
      ];
      alertKeys = [
        I18nKey.BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_ALERT_NOTE,
        I18nKey.BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_ALERT_NOTE_BOLD,
      ];
      break;
    case DelinquentAccountExperience.Locked:
      textKeys = [
        I18nKey.BILLING_ACCOUNT_LOCKED_OUT_MODAL_TITLE,
        I18nKey.BILLING_ACCOUNT_LOCKED_OUT_MODAL_BODY,
        I18nKey.BILLING_ACCOUNT_LOCKED_OUT_MODAL_NOTE,
        I18nKey.BILLING_ACCOUNT_LOCKED_OUT_MODAL_LEARN_MORE,
        I18nKey.BILLING_ACCOUNT_LOCKED_OUT_MODAL_BUTTON,
      ];
      alertKeys = [
        I18nKey.BILLING_ACCOUNT_LOCKED_OUT_MODAL_ALERT_NOTE,
        I18nKey.BILLING_ACCOUNT_LOCKED_OUT_MODAL_ALERT_NOTE_BOLD,
      ];
      break;
    case DelinquentAccountExperience.Enforced:
      textKeys = [
        I18nKey.BILLING_ACCOUNT_DELINQUENT_ENFORCED_TITLE,
        I18nKey.BILLING_ACCOUNT_DELINQUENT_ENFORCED_BODY,
        I18nKey.BILLING_ACCOUNT_DELINQUENT_ENFORCED_NOTE,
        I18nKey.BILLING_ACCOUNT_DELINQUENT_ENFORCED_LEARN_MORE,
        I18nKey.BILLING_ACCOUNT_DELINQUENT_ENFORCED_BUTTON,
      ];
      alertKeys = [
        I18nKey.BILLING_ACCOUNT_DELINQUENT_ENFORCED_ALERT_NOTE,
        I18nKey.BILLING_ACCOUNT_DELINQUENT_ENFORCED_ALERT_NOTE_BOLD,
      ];
      break;
  }

  const [
    MODAL_TITLE,
    TRIAL_ENDED_MODAL_BODY,
    TRIAL_ENDED_NOTE,
    LEARN_MORE,
    BUTTON_TEXT,
  ] = textKeys.map((id) => intl.formatMessage({ id }));

  const [ENFORCED_NOTE, ENFORCED_NOTE_BOLD] = alertKeys.map((id) =>
    intl.formatMessage({ id })
  );

  return (
    <Modal
      showModal={true}
      className="w-500 max-w-500 min-h-224 bg-white"
      dataTestId="delinquent_account_modal_wrapper"
    >
      {experience !== DelinquentAccountExperience.Locked && (
        <div className="flex justify-end -mt-24 -mr-24">
          <Button
            size={ButtonSize.Medium}
            variant={ButtonVariant.BlackAndWhite}
            svgIcon={CrossMiniIcon}
            onClick={onCancelClicked}
            className="text-grey-900"
          />
        </div>
      )}
      {showIframe ? (
        <>
          {loadingIframe && <Spinner centerAlign={true} />}
          <div
            id="zuora_payment"
            className="fs-exclude overflow-y-scroll w-full scrollbar scrollbar-thin scrollbar-thumb-grey-400 scrollbar-track-grey-100 scrollbar-thumb-rounded-full max-h-500"
          />
        </>
      ) : (
        <>
          <div className="flex flex-col items-center gap-y-16">
            <div
              className={`flex items-center justify-center w-40 h-40
                  rounded-full bg-gradient-to-r from-magenta-500 to-orange-500`}
            >
              <LightningIcon className="fill-current h-20 w-20 text-white" />
            </div>

            <div className="text-grey-900 font-semibold text-xl leading-tight text-center whitespace-pre-line">
              {MODAL_TITLE}
            </div>
            <div
              className={`text-grey-600 font-normal text-base leading-tight text-center pt-8 whitespace-pre-line`}
            >
              {TRIAL_ENDED_MODAL_BODY}
            </div>
          </div>

          <ShadowyAlertBox shouldCenterIcon={true}>
            {ENFORCED_NOTE}
            <span className="font-medium">{ENFORCED_NOTE_BOLD}</span>
          </ShadowyAlertBox>

          {(experience === DelinquentAccountExperience.Delinquent ||
            experience === DelinquentAccountExperience.Locked) && (
            <PayOutstandingBalance />
          )}

          <div className="flex flex-col items-center pt-16">
            <TextLink
              openNewTab
              textLabel={LEARN_MORE}
              href={HOW_TO_CHANGE_PLAN_LINK}
              dataTestId="trial-ended_learn-more-link"
            />
            <div
              className={`text-grey-700 font-normal text-sm leading-tight text-center mt-12 mb-24 whitespace-pre-line`}
            >
              {TRIAL_ENDED_NOTE}
            </div>
            <Button
              size={ButtonSize.Medium}
              variant={ButtonVariant.Primary}
              label={BUTTON_TEXT}
              onClick={onContinueClicked}
            />
            {showErrorText && (
              <div className="font-semibold text-base mt-8">
                {intl.formatMessage({ id: I18nKey.ERROR_MESSAGE })}
              </div>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

DelinquentAccountModal.displayName = 'DelinquentAccountModal';

export default DelinquentAccountModalWrapper;
