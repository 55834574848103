import { Dispatch, SetStateAction } from 'react';
import { IntlShape } from 'react-intl';
import { BillingApiClient } from '../../../../lib/clients/BillingApiClient';
import { MIApiClient } from '../../../../lib/clients/MIApiClient';
import { BillingEstimateResponseType } from '../../../../lib/types/Billing';
import { Account } from '../../../../lib/types/Fam';
import {
  AddNewSearchTermsResponse,
  AddNewSearchTermsStatusResponse,
  CheckedSearchTermsData,
  EditGroupData,
  PlatformDataWithCountries,
  SearchTermSource,
  SearchTermsData,
  SuggestedKeywords,
  SuggestedSearchTermRequestData,
  SuggestedTermsSelectedRowData,
  SummaryData,
  TermCountryChannel,
} from '../../../../lib/types/MISharedTypes';
import { Sort } from '../../../../lib/types/Sort';
import { CustomSelectItemProps } from '../../components';
import { MerchantCountryCode } from '../../../../lib/types/AOSharedTypes';

export const MARKET_INTELLIGENCE_SEARCH_TERMS_TABLE =
  'MARKET_INTELLIGENCE_SEARCH_TERMS_TABLE';

export const MARKET_INTELLIGENCE_GROUPS_TABLE =
  'MARKET_INTELLIGENCE_GROUPS_TABLE';

export const DELETE_SEARCH_TERM_NOTIFICATION =
  'DELETE_SEARCH_TERM_NOTIFICATION';

export const SEARCH_TERM_ADDED_NOTIFICATION = 'SEARCH_TERM_ADDED_NOTIFICATION';

export const ADD_NEW_SEARCH_TERMS_SUCCESS_OR_ERROR_NOTIFICATION =
  'ADD_NEW_SEARCH_TERMS_SUCCESS_OR_ERROR_NOTIFICATION';

export const IGNORE_SUGGESTED_SEARCH_TERMS_SUCCESS_OR_ERROR_NOTIFICATION =
  'IGNORE_SUGGESTED_SEARCH_TERMS_SUCCESS_OR_ERROR_NOTIFICATION';

export const GROUP_ACTIONS_NOTIFICATION = 'GROUP_ACTIONS_NOTIFICATION';

export const UNGROUPED = 'UNGROUPED';

export enum SearchTermStatus {
  Fetching = 'Fetching',
  Active = 'Active',
}

export enum GroupsToolbarState {
  DELETE_GROUP = 'DELETE_GROUP',
  CREATE_GROUP = 'CREATE_GROUP',
  ADD_TO_GROUP = 'ADD_TO_GROUP',
  REMOVE_FROM_GROUP = 'REMOVE_FROM_GROUP',
  CREATE_GROUP_AND_ADD_TO_GROUP = 'CREATE_GROUP_AND_ADD_TO_GROUP',
  INVALID_SELECTION_COMBO = 'INVALID_SELECTION_COMBO',
  EDIT_GROUP = 'EDIT_GROUP',
}

export enum GroupActionsError {
  DELETE_GROUP = 'DELETE_GROUP',
  CREATE_GROUP = 'CREATE_GROUP',
  ADD_SEARCH_TERMS = 'ADD_SEARCH_TERMS',
  REMOVE_SEARCH_TERMS = 'REMOVE_SEARCH_TERMS',
  DELETE_SEARCH_TERMS = 'DELETE_SEARCH_TERMS',
}

export enum DeleteGroupState {
  GROUP_WITH_TERMS = 'GROUP_WITH_TERMS',
  ONLY_GROUP = 'ONLY_GROUP',
}

export type PlatformType =
  | 'Amazon'
  | 'Walmart'
  | 'Target'
  | 'Google Shopping'
  | 'eBay';

export enum NoTrackingTermsType {
  Page = 'Page',
  Modal = 'Modal',
}

export interface SearchTermTableData {
  readonly onDeleteTermClick: (
    params: Readonly<{
      searchTerm: string;
      localeShort: string;
      platform: PlatformType;
    }>
  ) => void;
  readonly checkedRows: CheckedSearchTermsData[];
  readonly onGroupToolbarCancel: () => void;
  readonly dataTestId?: string;
  readonly groupsToolbarState: GroupsToolbarState | null;
  readonly intl: IntlShape;
  readonly loading: boolean;
  readonly isExpanded?: boolean;
  readonly toggleRowExpanded?: (expanded?: boolean) => void;
  readonly canExpand?: boolean;
  readonly depth?: number;
  readonly accountId: string;
  readonly isUserRoleViewOnly?: boolean;
}

export interface SearchTermGroupTableData {
  readonly onDeleteTermClick: (
    params: Readonly<{
      searchTerm: string;
      localeShort: string;
      platform: PlatformType;
    }>
  ) => void;
  readonly checkedRows: CheckedSearchTermsData[];
  readonly onEditGroupClick: (group: EditGroupData) => void;
  readonly onGroupToolbarCancel: () => void;
  readonly onUpdateGroup: () => void;
  readonly dataTestId?: string;
  readonly groupsToolbarState: GroupsToolbarState | null;
  readonly groupName: string;
  readonly setGroupName: React.Dispatch<React.SetStateAction<string>>;
  readonly editGroupData: EditGroupData | null;
  readonly intl: IntlShape;
  readonly loading: boolean;
  readonly handleDeleteGroupClick: (params: SearchTermsData) => void;
  readonly isExpanded?: boolean;
  readonly toggleRowExpanded?: (expanded?: boolean) => void;
  readonly canExpand?: boolean;
  readonly depth?: number;
  readonly accountId: string;
  readonly isUserRoleViewOnly?: boolean;
}

export type RowCellElementProps = SearchTermsData & SearchTermTableData;

export interface SearchTermDeleteModalData {
  readonly searchTerm: string;
  readonly localeShort: string;
  readonly platform: PlatformType;
}

export type Closed = 'closed';
export const CLOSED: Closed = 'closed';

export type Opened = 'opened';
export const OPENED: Opened = 'opened';

export enum UploadTermsState {
  ChooseFile = 'ChooseFile',
  StartUpload = 'StartUpload',
  ProcessingFile = 'ProcessingFile',
  ProcessedFile = 'ProcessedFile',
  CompleteUpload = 'CompleteUpload',
  ErrorUnknown = 'ErrorUnknown',
  ErrorTermsExceeded = 'ErrorTermsExceeded',
  ErrorCsv = 'ErrorCsv',
  NoNewTermsIdentified = 'NoNewTermsIdentified',
  MissingHeader = 'MissingHeader',
}

export enum HttpErrorCode {
  PayloadTooLarge = 413,
  UnprocessableEntity = 422,
}

export enum AddNewTermsTabsIndex {
  AddManually = 0,
  BulkUpload = 1,
}

export interface AddNewTermsProps {
  readonly addNewTermsActiveTabIndex: number;
  readonly isAddNewTermsAccordionOpened: boolean;
  readonly onAccordionIconClicked: () => void;
  readonly onAddNewTermsTabChange: (selectedTabIndex: number) => void;
  readonly showAddFirstTermGradientBar: boolean;
  readonly searchTermsLimit: number;
  readonly totalTermsAdded: number;
  readonly isSearchTermNumberUnlimited: boolean;
  readonly getNewSearchTermsStatus: (
    searchTerms: TermCountryChannel[]
  ) => Promise<AddNewSearchTermsStatusResponse>;
  readonly showManualUploadFailedNotification: () => void;
  readonly addNewSearchTerms: (
    searchTerms: TermCountryChannel[],
    source: SearchTermSource,
    SuggestedSearchTermData?: SuggestedSearchTermRequestData
  ) => Promise<AddNewSearchTermsResponse>;
  readonly afterSuccessfulManualUpload: (
    newTermsCount: number,
    existingTermsCount: any
  ) => void;
  readonly goToTab?: (tabNumber: number) => void;
  readonly trackedTermsTab?: number;
  readonly platforms: PlatformDataWithCountries[];
}

export interface AddTermsTableHeaderDataType extends TemplateRowData {
  readonly onHeaderSearchTermInputChange: (searchTerm: string) => void;
  readonly onHeaderSalesChannelDropdownChange: (
    o?: CustomSelectItemProps
  ) => void;
  readonly onHeaderCountryDropdownChange: (o?: CustomSelectItemProps) => void;
  readonly onHeaderAddTermButtonClick: () => void;
}

export interface TemplateRowData {
  readonly searchTerm: string;
  readonly selectedSalesChannelValue: string;
  readonly selectedCountryValueShort: string;
  readonly selectedCountryValueFull: string;
  readonly isSearchTermValid: boolean;
}

export interface AddTermsTableRowDataType {
  readonly id: string;
  readonly searchTerm: string;
  readonly selectedSalesChannelValue: string;
  readonly selectedCountryValueShort: string;
  readonly selectedCountryValueFull: string;
  readonly error?: AddTermsRowError;
}

export enum AddTermsRowError {
  TermIsAKeyword = 'TermIsAKeyword',
}

export interface AddTermsTableDataType {
  readonly salesChannels: PlatformDataWithCountries[];
  readonly availableTermsLimit: number;
  readonly searchTermsLimit: number;
  readonly isSearchTermNumberUnlimited: boolean;
  readonly rowData: AddTermsTableRowDataType[];
  readonly onRowSearchTermInputChange: (id: string, value: string) => void;
  readonly onRowSalesChannelDropdownChange: (
    id: string,
    o?: CustomSelectItemProps
  ) => void;
  readonly onRowCountryDropdownChange: (
    id: string,
    o?: CustomSelectItemProps
  ) => void;
  readonly onRowDuplicateButtonClick: (id: string) => void;
  readonly onRowDeleteButtonClick: (id: string) => void;
  readonly dataTestId?: string;
}

export type ColumnsRowCellElement = AddTermsTableRowDataType &
  AddTermsTableDataType;
export type ColumnsHeaderCellElement = AddTermsTableHeaderDataType &
  AddTermsTableDataType;

export interface AddNewTermsFooterProps {
  readonly currentSearchTermsCount: number;
  readonly availableTermsLimit: number;
  readonly searchTermsLimit: number;
  readonly onCancelButtonClick: () => void;
  readonly onSubmitButtonClick: () => void;
  readonly manualUploadInProgress: boolean;
  readonly rowData: AddTermsTableRowDataType[];
  readonly isSearchTermNumberUnlimited: boolean;
}

export const SAMPLE_CSV_DOWNLOAD_FILENAME_HEADER: string =
  'search_terms_upload_sample_filename';

export const SAMPLE_CSV_DOWNLOAD_DEFAULT_FILENAME: string =
  'market-intelligence-template.csv';

export const EXPORT_SEARCH_TERMS_CSV_DOWNLOAD_FILENAME_HEADER: string =
  'export_search_terms_filename';

export const EXPORT_SEARCH_TERMS_CSV_DOWNLOAD_DEFAULT_FILENAME: string =
  'Tracked Terms.csv';

export const DOWNLOAD_SAMPLE_NOTIFICATION: string =
  'DOWNLOAD_SAMPLE_NOTIFICATION';

export const SEARCH_TERM_TARGETING_AUTO: string = 'targeting_auto';

export interface SuggestedTermsProps {
  readonly getNewSearchTermsStatus: (
    searchTerms: TermCountryChannel[]
  ) => Promise<AddNewSearchTermsStatusResponse>;
  readonly showManualUploadFailedNotification: () => void;
  readonly addNewSearchTerms: (
    searchTerms: TermCountryChannel[],
    source: SearchTermSource,
    SuggestedSearchTermData?: SuggestedSearchTermRequestData
  ) => Promise<AddNewSearchTermsResponse>;
  readonly afterSuccessfulManualUpload: (
    newTermsCount: number,
    existingTermsCount: any
  ) => void;
  readonly ignoreSuggestedSearchTermsNotifications: (
    error: boolean,
    count: number
  ) => void;
  readonly goToTab: (tabNumber: number) => void;
  readonly trackedTermsTab: number;
  readonly ignoreSelectedSearchTerms: (
    searchTerms: string[],
    mcid: string
  ) => Promise<void>;
  readonly hasMerchantData: boolean;
  readonly activeTabIndex: number;
  readonly miApiClient: MIApiClient;
  readonly billingApiClient: BillingApiClient;
  readonly platforms: PlatformDataWithCountries[];
  readonly termsTableLastUpdatedAt: string;
  readonly suggestedTermsAvailableForMCIDs: string[];
  readonly suggestedTermsAvailableForMCIDsLoading: boolean;
}

export interface SuggestedTermsStepComponentsProps {
  readonly activeStep: number;
  readonly loading: boolean;
  readonly suggestedKeywords: SuggestedKeywords[];
  readonly rowIdsSelected: string[];
  readonly setRowIdsSelected: Dispatch<SetStateAction<string[]>>;
  readonly summaryData: SummaryData[];
  readonly selectedRowData: SuggestedTermsSelectedRowData[];
  readonly setSelectedRowData: Dispatch<
    SetStateAction<SuggestedTermsSelectedRowData[]>
  >;
  readonly searchTermsForAllPlatforms: SearchTermsData[];
  readonly currentSort: Sort;
  readonly setCurrentSort: Dispatch<SetStateAction<Sort>>;
  readonly platforms: PlatformDataWithCountries[];
  readonly error: boolean;
  readonly billingEstimate: BillingEstimateResponseType | null;
  readonly account: Account | undefined;
  readonly hasNoRecommendations: boolean;
  readonly merchantCountryCode: MerchantCountryCode;
  readonly billingEstimateLoading: boolean;
  readonly isUserRoleViewOnly?: boolean;
}

export const DEFAULT_MAX_FRACTION_DIGITS = 2;
export const CHARGES_PER_SEARCH_TERM = 0.3;
export const FREE_TERMS_PER_CHANNEL = 50;
