import { FlywheelTableColumnGroup } from '../../../../../containers/table/UpdatedFlywheelTable';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { acosDirectColumn } from './acosDirect';
import { acosTotalColumn } from './acosTotal';
import { adConversionRateColumn } from './adConversionRate';
import { adConversionsColumn } from './adConversions';
import { adGroupNameColumn } from './adGroupName';
import { adGroupReviewStatusColumn } from './adGroupReviewStatus';
import { adGroupStatusColumn } from './adGroupStatus';
import { adSalesDirectColumn } from './adSalesDirect';
import { adSalesTotalColumn } from './adSalesTotal';
import { adSpendColumn } from './adSpend';
import { adUnitsSoldColumn } from './adUnitsSold';
import { bidColumn } from './bid';
import { campaignCostTypeColumn } from './campaignCostType';
import { campaignNameColumn } from './campaignName';
import { campaignStatusColumn } from './campaignStatus';
import { campaignTacticColumn } from './campaignTactic';
import { campaignTargetingTypeColumn } from './campaignTargetingType';
import { campaignTypeColumn } from './campaignType';
import { clicksColumn } from './clicks';
import { clickThroughRateColumn } from './clickThroughRate';
import { costPerClickColumn } from './costPerClick';
import { dailyBudgetColumn } from './dailyBudget';
import { impressionsColumn } from './impressions';
import { merchantNameColumn } from './merchantName';
import { newToBrandAdSalesColumn } from './newToBrandAdSales';
import { newToBrandAdUnitsSoldColumn } from './newToBrandAdUnitsSold';
import { newToBrandOrdersColumn } from './newToBrandOrders';
import { portfolioNameColumn } from './portfolioName';
import { productAdStatusColumn } from './productAdStatus';
import { productNameColumn } from './productName';
import { productReviewStatusColumn } from './productReviewStatus';
import { relatedClickRevenueColumn } from './relatedClickRevenue';
import { roasDirectColumn } from './roasDirect';
import { roasTotalColumn } from './roasTotal';
import { totalBudgetColumn } from './totalBudget';
import { CheckboxCheckedState } from '@teikametrics/tm-design-system';
import { advertisedSKUUnitsColumn } from './advertisedSkuUnits';
import { otherSKUUnitsColumn } from './otherSkuUnits';
import { addedByColumn } from './addedBy';

export const generateProductAdsColumns = (
  isEditMode: boolean,
  headerExtraProps?: {
    onSelectChange: React.MouseEventHandler<HTMLElement>;
    checked: CheckboxCheckedState;
  }
): FlywheelTableColumnGroup<ProductAdDetails, TableDataAdsManager>[] => [
  {
    groupingHeaderI18nKey: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_AD_ITEM_DETAILS,
    columnsInGroup: [
      {
        ...productNameColumn,
        ...(headerExtraProps
          ? {
              showCheckBox: isEditMode,
              checked: headerExtraProps.checked,
              onSelectChange: headerExtraProps.onSelectChange,
            }
          : {}),
      },
    ],
  },
  {
    columnsInGroup: [
      merchantNameColumn,
      productReviewStatusColumn,
      adGroupNameColumn,
      adGroupStatusColumn,
      adGroupReviewStatusColumn,
      campaignNameColumn,
      campaignTypeColumn,
      campaignTargetingTypeColumn,
      addedByColumn,
      campaignTacticColumn,
      dailyBudgetColumn,
      totalBudgetColumn,
      campaignStatusColumn,
      portfolioNameColumn,
      campaignCostTypeColumn,
    ],
  },
  {
    groupingHeaderI18nKey: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_CHANNEL_SETTINGS,
    columnsInGroup: [productAdStatusColumn, bidColumn],
  },
  {
    groupingHeaderI18nKey: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_PERFORMANCE,
    columnsInGroup: [
      adSalesTotalColumn,
      adSpendColumn,
      acosTotalColumn,
      roasTotalColumn,
      adConversionsColumn,
      adConversionRateColumn,
      adUnitsSoldColumn,
      impressionsColumn,
      clicksColumn,
      costPerClickColumn,
      clickThroughRateColumn,
      adSalesDirectColumn,
      acosDirectColumn,
      roasDirectColumn,
      relatedClickRevenueColumn,
      newToBrandOrdersColumn,
      newToBrandAdSalesColumn,
      newToBrandAdUnitsSoldColumn,
      advertisedSKUUnitsColumn,
      otherSKUUnitsColumn,
    ],
  },
];
