import capitalize from 'lodash/capitalize';

import {
  MultiSelectOption,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  CampaignDetails,
  ChannelSettings,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CAMPAIGNS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<CampaignDetails> = (props) => {
  return makeTextColumn<ChannelSettings>(({ budgetType }) =>
    budgetType ? capitalize(budgetType) : undefined
  )(props.channelSettings);
};

RowCellElement.displayName = 'RowCellElement';

export const budgetTypeColumn: FlywheelTableColumn<CampaignDetails> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.BudgetType,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE,
  RowCellElement,
  gridColumnWidth: '140px',
};

export const budgetTypeFilter = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
) =>
  createMultiSelectDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.BudgetType,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE,
    getBudgetTypeOptions(selectedSalesChannel, selectedAdType)
  );

export const getBudgetTypeOptions = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
): MultiSelectOption[] => {
  switch (selectedSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      return [
        SelectFilterOption.BudgetTypeDaily,
        SelectFilterOption.BudgetTypeTotal,
        SelectFilterOption.BudgetTypeBoth,
      ];
    case FlywheelSalesChannel.Amazon:
      switch (selectedAdType) {
        case AdType.SponsoredProducts:
        case AdType.SponsoredBrands:
          return [
            SelectFilterOption.BudgetTypeDaily,
            SelectFilterOption.BudgetTypeLifetime,
          ];
        // The fallback default should never occur hence safe to return [], can handle with default options in future
        default:
          return [];
      }
  }
};
