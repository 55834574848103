import { Factory } from 'fishery';

import {
  AdvertisingGoal,
  AmazonBiddingStrategy,
  BudgetType,
  CampaignAdFormat,
  CampaignCostType,
  CampaignDetails,
  CampaignServingStatus,
  CampaignStatus,
  CampaignTactic,
  CampaignTargetingType,
  PlacementStatus,
} from '../../types/AOSharedTypes';

export interface Response {
  readonly elements: CampaignDetails[];
  readonly filteredElements: number;
}

export default Factory.define<Response>(() => ({
  elements: [
    {
      campaignId: '584bea86-6053-551b-a20f-ec80ae01e74c',
      campaignDetails: {
        campaignName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
        campaignAdFormat: CampaignAdFormat.ProductCollection,
        campaignTactic: CampaignTactic.Product,
        campaignPortfolioName: 'TK - K2D3',
        campaignCostType: CampaignCostType.VCPM,
        targetingType: CampaignTargetingType.Manual,
        advertisingGoal: AdvertisingGoal.ProductLaunch,
      },
      channelSettings: {
        campaignType: 'sponsoredProducts',
        campaignServingStatus: CampaignServingStatus.Delivering,
        startDate: '2021-11-11',
        endDate: '2021-11-20',
        status: CampaignStatus.Enabled,
        dailyBudget: {
          amount: '4000.00',
          currency: 'USD',
        },
        totalBudget: {
          amount: '12000.00',
          currency: 'USD',
        },
        placementBidMultiplier: {
          buyBoxBidMultiplier: 0.6393,
          homePageBidMultiplier: 0.452,
          searchIngridBidMultiplier: 0.3857,
          stockUpBidMultiplier: 0.0829,
          productPageBidMultiplier: 0,
          topOfSearchBidMultiplier: 0.5,
          belowTopOfSearchBidMultiplier: -0.7496,
        },
        platformBidMultiplier: {
          appBidMultiplier: 0.2648,
          mobileBidMultiplier: 0.3563,
          desktopBidMultiplier: 0.6124,
        },
        biddingStrategy: AmazonBiddingStrategy.DynamicBidsDownOnly,
        bidOptimization: true,
        budgetRollover: true,
        itemBuyBox: PlacementStatus.Excluded,
        itemCarousel: PlacementStatus.Included,
        carouselStatus: PlacementStatus.Excluded,
        budgetType: BudgetType.Total,
      },
      campaignPerformance: {
        newToBrandSales: {
          amount: '20',
          currency: 'USD',
        },
        newToBrandSalesPercentage: 0.2,
        newToBrandOrdersPercentage: 0.2,
        newToBrandAdUnitsSoldPercentage: 0.2,
        newToBrandOrders: 2,
        newToBrandOrderRate: 0.2,
        adSalesTotal: {
          amount: '29661.79',
          currency: 'USD',
        },
        adSpend: {
          amount: '3495.37',
          currency: 'USD',
        },
        unitsSold: 275,
        roasTotal: 8.486022938916339,
        acosTotal: 0.11784083158838357,
        conversionRate: 0.054005579912315665,
        clicks: 5018,
        impressions: 1026024,
        clickThroughRate: 0.004890723803731687,
        avgCostPerClick: {
          amount: '0.6965663611000399',
          currency: 'USD',
        },
        adConversions: 271,
        acosDirect: 0.9,
        adSalesDirect: {
          amount: '1533.76',
          currency: 'USD',
        },
        newToBrandAdUnitsSold: 2,
        brandClickRevenue: {
          amount: '1913.76',
          currency: 'USD',
        },
        relatedClickRevenue: {
          amount: '1915.76',
          currency: 'USD',
        },
        roasDirect: 6.432,
        advertisedSkuUnits: 10,
        otherSkuUnits: 10,
      },
      pendingFields: ['budgetRollover'],
      flywheelSettings: {
        automationStatus: false,
        minBid: undefined,
        maxBid: undefined,
        macsTarget: undefined,
      },
    },
    {
      campaignId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec17',
      campaignDetails: {
        campaignName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        campaignAdFormat: CampaignAdFormat.ProductCollection,
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
      },
      channelSettings: {
        startDate: '2020-05-16',
        campaignStatus: CampaignStatus.Pending,
      },
      campaignPerformance: {},
      flywheelSettings: {
        automationStatus: undefined,
        minBid: undefined,
        maxBid: {
          amount: '1.6',
          currency: 'USD',
        },
        macsTarget: 0.3,
      },
      pendingFields: [],
    },
    {
      campaignId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec17',
      campaignDetails: {
        campaignName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        campaignAdFormat: CampaignAdFormat.ProductCollection,
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
        targetingType: CampaignTargetingType.Auto,
      },
      channelSettings: {
        budgetRollover: false,
        startDate: '2020-05-16',
        campaignStatus: CampaignStatus.Pending,
        status: CampaignStatus.Archived,
        bidOptimization: true,
        placementBidMultiplier: {
          buyBoxBidMultiplier: 0.6393,
          homePageBidMultiplier: 0.452,
          searchIngridBidMultiplier: 0,
          stockUpBidMultiplier: 0.0829,
          productPageBidMultiplier: 0,
          topOfSearchBidMultiplier: 0.5,
          belowTopOfSearchBidMultiplier: -0.7496,
        },
      },
      campaignPerformance: {},
      flywheelSettings: {
        automationStatus: undefined,
        minBid: {
          amount: '1.6',
          currency: 'USD',
        },
        maxBid: undefined,
        macsTarget: 0.3,
      },
      pendingFields: [],
    },
    {
      campaignId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec17',
      campaignDetails: {
        status: CampaignStatus.Rescheduled,
        campaignName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        campaignAdFormat: CampaignAdFormat.ProductCollection,
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
      },
      channelSettings: {
        startDate: '2020-05-16',
        campaignStatus: CampaignStatus.Pending,
      },
      campaignPerformance: {},
      flywheelSettings: {
        automationStatus: undefined,
        minBid: {
          amount: '1.6',
          currency: 'USD',
        },
        maxBid: {
          amount: '1.9',
          currency: 'USD',
        },
        macsTarget: undefined,
      },
      pendingFields: [],
    },
    {
      campaignId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec17',
      campaignDetails: {
        status: CampaignStatus.Completed,
        campaignName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        campaignAdFormat: CampaignAdFormat.Video,
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
      },
      channelSettings: {
        startDate: '2020-05-16',
        campaignStatus: CampaignStatus.Pending,
      },
      campaignPerformance: {},
      flywheelSettings: {
        automationStatus: false,
        minBid: {
          amount: '1.20',
          currency: 'USD',
        },
        maxBid: {
          amount: '1.60',
          currency: 'USD',
        },
        macsTarget: 0.3,
      },
      pendingFields: [],
    },
    {
      campaignId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec17',
      campaignDetails: {
        campaignName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
        campaignAdFormat: CampaignAdFormat.ProductCollection,
        merchantCountryId: '16fe821b-76df-446b-9c1f-b3206f779472',
      },
      channelSettings: {
        startDate: '2020-05-16',
        campaignStatus: CampaignStatus.Pending,
      },
      campaignPerformance: {},
      flywheelSettings: {
        automationStatus: false,
        minBid: {
          amount: '1.20',
          currency: 'USD',
        },
        maxBid: {
          amount: '1.60',
          currency: 'USD',
        },
        macsTarget: 0.3,
      },
      pendingFields: [],
    },
    {
      campaignId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec17',
      campaignDetails: {
        campaignName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
      },
      channelSettings: {
        startDate: '2020-05-16',
        campaignStatus: CampaignStatus.Archived,
        status: CampaignStatus.Archived,
      },
      campaignPerformance: {},
      flywheelSettings: {},
      pendingFields: [],
    },
    {
      campaignId: 'cfa0e8ec-db55-57bb-9629-a4a58c51ec18',
      campaignDetails: {
        campaignName:
          'L.O.L. Surprise LOL (16 Pack) Popcorn Treat Favor Party Boxes',
      },
      channelSettings: {
        startDate: '2020-05-16',
        campaignStatus: CampaignStatus.Completed,
        status: CampaignStatus.Completed,
      },
      campaignPerformance: {},
      flywheelSettings: {},
      pendingFields: [],
    },
  ],
  filteredElements: 1,
}));
