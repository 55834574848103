import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CheckboxCheckedState,
  CheckboxLabel,
  CheckmarkIcon,
  CrossIcon,
  EmojiGrin,
  EmojiNeutral,
  EmojiSad,
  EmojiSmile,
  EmojiUnamused,
  Modal,
  RatingBar,
  TextArea,
  Typography,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { useIntl } from 'react-intl';
import { EmptyState } from './emptyState';
import { AOApiClient } from '../../../../../../lib/clients/AOApiClient';
import { FeedbackSubmitRequest } from '../../../../../../lib/types/AOSharedTypes';
import { CreateChatGPTKeywordsContext } from './createChatGPTKeywordsProvider';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../../lib/utilities/intercom';

export interface FeedbackSlideoutProps {
  readonly onClose: (isFeedbackSubmitted?: boolean) => void;
  readonly aoApiClient: AOApiClient;
  readonly accountId: string;
  readonly isSlideOut?: boolean;
}

const getCheckboxState = (isChecked: boolean) =>
  isChecked ? CheckboxCheckedState.Checked : CheckboxCheckedState.Unchecked;

export const FeedbackSlideout: React.FC<FeedbackSlideoutProps> = ({
  onClose,
  aoApiClient,
  accountId,
  isSlideOut = true,
}) => {
  const intl = useIntl();
  const [selectedRating, setSelectedRating] = useState<number>();
  const [selectedSuggestions, setSelectedSuggestions] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [otherText, setOtherText] = useState('');
  const {
    isFeedbackSubmitted,
    setIsFeedbackSubmitted,
    requestKeywordsResponse,
  } = useContext(CreateChatGPTKeywordsContext);

  const [
    REALLY_BAD,
    BAD,
    OKAY,
    GOOD,
    GREAT,
    FEEDBACK_OPTION_1,
    FEEDBACK_OPTION_2,
    FEEDBACK_OPTION_3,
    FEEDBACK_OPTION_4,
    FEEDBACK_OPTION_5,
    FEEDBACK_OPTION_6,
    SUBMIT_FEEDBACK,
    CANCEL,
    CLOSE,
    TYPE_HERE,
    HEADER,
    SELECT_ONE,
    THANK_YOU,
  ] = [
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_REALLY_BAD,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_BAD,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_OKAY,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_GOOD,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_GREAT,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_1,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_2,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_3,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_4,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_5,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_6,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_SUBMIT_FEEDBACK,
    I18nKey.CANCEL,
    I18nKey.CLOSE,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_TYPE_HERE,
    I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_SELECT_ALL,
    I18nKey.USER_MANAGEMENT_CREATE_NEW_USER_FORM_ROLE_PLACEHOLDER,
    I18nKey.GENERIC_THANK_YOU,
  ].map((id) => intl.formatMessage({ id }));

  const ratingOptions = [
    {
      label: REALLY_BAD,
      icon: EmojiUnamused,
      rating: 1,
    },
    {
      label: BAD,
      icon: EmojiSad,
      rating: 2,
    },
    {
      label: OKAY,
      icon: EmojiNeutral,
      rating: 3,
    },
    {
      label: GOOD,
      icon: EmojiSmile,
      rating: 4,
    },
    {
      label: GREAT,
      icon: EmojiGrin,
      rating: 5,
    },
  ];

  const feedbackOptions = [
    FEEDBACK_OPTION_1,
    FEEDBACK_OPTION_2,
    FEEDBACK_OPTION_3,
    FEEDBACK_OPTION_4,
    FEEDBACK_OPTION_5,
    FEEDBACK_OPTION_6,
  ];

  const handleOptionClick = (option: string) => {
    if (selectedSuggestions.includes(option)) {
      setSelectedSuggestions(
        selectedSuggestions.filter((item) => item !== option)
      );
    } else {
      setSelectedSuggestions([...selectedSuggestions, option]);
    }
  };

  const submitFeedback = () => {
    setIsSaving(true);

    const isOtherSelected = selectedSuggestions.includes(FEEDBACK_OPTION_6);

    const feedback: FeedbackSubmitRequest = {
      ...requestKeywordsResponse!,
      feedback: {
        rating: selectedRating!,
        suggestions: [
          ...selectedSuggestions.filter((item) => item !== FEEDBACK_OPTION_6),
          ...(isOtherSelected ? [otherText] : []),
        ],
      },
    };
    aoApiClient.submitFeedback(accountId, feedback).then(
      () => {
        if (!isSlideOut) {
          onClose(true);
        }

        setIsSaving(false);
        setIsFeedbackSubmitted(true);
      },
      () => {
        setIsSaving(false);
      }
    );
  };

  const submitButtonState = useMemo(() => {
    if (isSaving) {
      return ButtonState.Loading;
    } else if (
      !selectedRating ||
      (selectedSuggestions.includes(FEEDBACK_OPTION_6) && !otherText) ||
      !selectedSuggestions.length
    ) {
      return ButtonState.Disabled;
    }
    return ButtonState.Enabled;
  }, [isSaving, selectedRating, otherText, selectedSuggestions]);

  useEffect(() => {
    if (isSlideOut) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [isSlideOut]);

  return (
    <Modal
      className={classNames({
        'h-592 relative': !isSlideOut,
        'min-w-556 w-556': isSlideOut,
        'min-w-512 w-512': !isSlideOut,
      })}
      showModal
      slideOutModal={isSlideOut}
      showOVerlay={true}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      hidePadding={true}
      dataTestId="feedback_slideout"
    >
      <div
        className={classNames('flex flex-col', {
          'p-24 pr-16 h-full': !isSlideOut,
          'h-screen': isSlideOut,
        })}
      >
        {isSlideOut && (
          <div
            className={classNames(
              'flex flex-row',
              'border-solid border-b border-grey-200',
              'p-16'
            )}
          >
            <div
              className="hover:cursor-pointer"
              onClick={() => onClose(isFeedbackSubmitted)}
            >
              <Button
                svgIcon={CrossIcon}
                size={ButtonSize.Small}
                variant={ButtonVariant.BlackAndWhite}
              />
            </div>
            <Typography
              size={TypographySize.lg}
              weight={TypographyWeight.medium}
              className="w-full text-center"
            >
              {intl.formatMessage({
                id: I18nKey.GENERIC_FEEDBACK,
              })}
            </Typography>
          </div>
        )}
        {!isFeedbackSubmitted && (
          <div
            className={classNames('flex flex-col', {
              'pt-48 px-24': isSlideOut,
            })}
          >
            <p className="text-xl leading-tight font-medium text-grey-900">
              {intl.formatMessage({
                id: I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_HEADER,
              })}
            </p>
            <div
              className={classNames('flex flex-col gap-12 py-12', {
                'border border-solid border-grey-300 rounded-4 mt-16 pl-24 pr-16':
                  isSlideOut,
              })}
            >
              <p className="text-base leading-normal font-medium">
                {intl.formatMessage({
                  id: I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_HEADER,
                })}
              </p>
              <div className="flex items-stretch">
                <RatingBar
                  options={ratingOptions}
                  placeholder={SELECT_ONE}
                  rating={selectedRating}
                  onChange={(rating?: number | string) =>
                    setSelectedRating(rating as number)
                  }
                />
              </div>
              <p className="text-base leading-normal font-medium">{HEADER}</p>
              {feedbackOptions.map((option, index) => (
                <CheckboxLabel
                  key={option}
                  label={option}
                  labelClassname="text-base leading-tight font-normal text-grey-900"
                  checked={getCheckboxState(
                    selectedSuggestions.includes(option)
                  )}
                  onClick={() => handleOptionClick(option)}
                />
              ))}
              <TextArea
                className={classNames(
                  'text-base leading-normal font-normal w-full px-12 pt-12 h-104 resize-none',
                  'focus:border-purple-500 rounded outline-none focus:border focus:border-solid'
                )}
                rows={2}
                maxLength={200}
                disabled={!selectedSuggestions.includes(FEEDBACK_OPTION_6)}
                onChange={(e: string) => setOtherText(e)}
                defaultValue={otherText}
                placeholder={TYPE_HERE}
              />
            </div>
          </div>
        )}

        {isFeedbackSubmitted && (
          <EmptyState
            containerClassName="p-24 w-556"
            titleClassName="mt-8 text-base leading-normal font-semibold"
            descriptionClassName="text-base leading-normal font-normal"
            title={THANK_YOU}
            description={
              intl.formatMessage(
                {
                  id: I18nKey.CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_SUCCESS_MESSAGE,
                },
                {
                  br: <br />,
                }
              ) as string
            }
            icon={CheckmarkIcon}
            iconClassName="text-green-700"
          />
        )}

        <div
          className={classNames(
            'gap-12 absolute w-full left-0 bottom-0',
            'flex items-center justify-end',
            {
              'box-border border-t border-solid border-grey-200 p-12':
                isSlideOut,
              'pb-24 pl-24 pr-16': !isSlideOut,
            }
          )}
        >
          {isFeedbackSubmitted && (
            <Button
              size={ButtonSize.Medium}
              variant={ButtonVariant.Primary}
              label={CLOSE}
              onClick={() => onClose(isFeedbackSubmitted)}
              dataTestId={`feedback_close`}
            />
          )}
          {!isFeedbackSubmitted && (
            <>
              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.BlackAndWhiteBorder}
                label={CANCEL}
                onClick={() => onClose(isFeedbackSubmitted)}
                dataTestId={`feedback_cancel`}
              />
              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.Primary}
                state={submitButtonState}
                label={SUBMIT_FEEDBACK}
                onClick={() => submitFeedback()}
                dataTestId={`feedback_submit`}
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
