import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '@teikametrics/tm-design-system';

import { AccountSwitcher } from './AccountSwitcher';
import { AppModules } from './AppModules';
import { AxiosProvider } from './containers/axiosProvider';
import { CustomUploadProvider } from './containers/customUploadProvider/customUploadProvider';
import { DataSyncProvider } from './containers/dataSyncInfoProvider/dataSyncInfoProvider';
import { MerchantCountriesProvider } from './containers/merchantCountriesProvider/merchantCountriesProvider';
import { NotificationProvider } from './containers/notificationProvider';
import { OptimizelyProvider } from './containers/optimizelyProvider/optimizelyProvider';
import { RecommendationsProvider } from './containers/recommendationsProvider/recommendationsProvider';
import { SalesChannelProvider } from './containers/salesChannelProvider';
import { SaveChangesFlagProvider } from './containers/saveChangesFlagProvider';
import { SubscriptionProvider } from './containers/subscriptionProvider';
import {
  getCurrentAccountFromContext,
  getCurrentAccountPermissions,
  getSignUpData,
  isAIPlanEnabled,
  isInTrial,
} from './containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
  UserInfo,
} from './containers/userProvider/userProvider';
import { createBillingApiClient } from './lib/clients/BillingApiClient';
import { FAMApiClient, createFAMApiClient } from './lib/clients/FAMApiClient';
import {
  AccountStatusResponse,
  BillingEstimateResponseType,
} from './lib/types/Billing';
import { Account, CreationMethod, Role, UserDetails } from './lib/types/Fam';
import {
  getAuthenticationDate,
  getZuoraAccountInfo,
} from './lib/utilities/tracking';
import {
  WORKFLOW_PARAM,
  WORKFLOW_REDIRECTIONS,
  handleSocialSignOn,
} from './lib/utilities/userTrackingUtilities';

import { OauthPopup } from './OauthPopup';
import { createAppStore } from './redux/store';
import { AppAction, AppState } from './redux/types';
import { getItemsPerPageByTableIdFromStorage } from './containers/tableV2/utils';
import { getUserId } from './modules/advertisingOptimization';
import { SALES_CHANNELS_TABLE } from './modules/account/containers/salesChannels/types';
import { USER_MANAGEMENT_TABLE_ID } from './modules/account/containers/users/types';
import { DEFAULT_PAGE_SIZE, StringMap } from './lib/types';
import LogRocket from 'logrocket';
import { PageHeaderProvider } from './containers/pageHeaderProvider';
import { BROADCAST_ACCOUNT_SWITCH_NAME } from './lib/utilities/commonUtils';
import { ViewTrendsContextProvider } from './modules/advertisingOptimization/components/ViewTrends/ViewTrendsProvider';
import { FWCookie, PERSISTED_KEYS } from './lib/utilities/fwCookie';
import {
  AsyncRequest,
  AsyncRequestCompleted,
  AsyncRequestFailed,
  AsyncRequestNotStarted,
  asyncRequestIsComplete,
} from './lib/utilities/asyncRequest';
import {
  segmentIdentify,
  segmentReset,
  segmentSetAnonymousId,
} from './lib/utilities/segment';
import { intercomUserLoggedIn } from './lib/utilities/intercom';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

interface HandleUserSignInResp {
  readonly redirectTo: string | undefined;
  readonly excludeSearch?: boolean;
}

const onAccountReset = (
  token: IdToken,
  userContext: UserContextState,
  userDetails: UserDetails,
  accountId: string,
  channel: BroadcastChannel
) => {
  FWCookie.saveCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID, accountId);
  userContext.updateBillingInfo(AsyncRequestNotStarted());
  userContext.updateBillingEstimate(AsyncRequestNotStarted());
  userContext.updateUserInfo({
    idToken: token,
    userDetails,
    currentAccountId: accountId,
  });
  channel.postMessage(accountId);
  segmentReset();
  if (userContext.userInfo.userDetails) {
    segmentIdentify(
      userContext.userInfo.userDetails.id,
      userContext.userInfo.userDetails.email,
      {
        fw2_last_login_date: DateTime.utc().toISO(),
      }
    );
  }
};

const LoggedIn: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)?.id;
  const { getIdTokenClaims } = useAuth0();
  const params = new URLSearchParams(location.search);
  const accountPermissions = getCurrentAccountPermissions(
    userContext.userInfo.userDetails
  );
  const userId = getUserId(userContext);
  const channel = new BroadcastChannel(BROADCAST_ACCOUNT_SWITCH_NAME);

  const redirectUrlFromUrlParams = params.get('redirect');
  const accountIdFromUrlParams = params.get('accountId') || '';

  const getUserIdToken = async () => {
    const idToken = await getIdTokenClaims();
    userContext.updateUserInfo({
      idToken: idToken,
    });

    return idToken;
  };

  const shouldRedirectToAccountSwitcher = (userDetails: UserDetails) => {
    return (
      userDetails.accountPermissions.length > 1 &&
      !FWCookie.readCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID)
    );
  };

  const getUserDetails = async (): Promise<
    [IdToken, UserDetails | undefined]
  > => {
    const idToken = await getUserIdToken();
    const famClient = createFAMApiClient(idToken!);

    // 1. Accept Any Invitations
    await acceptInvitationIfPresent(famClient, params);

    // 2. Get the users information
    let userDetails = await famClient.getUserDetails();
    return [idToken!, userDetails];
  };

  const handleUserSignIn = async (): Promise<
    HandleUserSignInResp | undefined
  > => {
    // 1. Get the users information
    const [idToken, userDetails] = await getUserDetails();

    // 2. Emit the users email verified status to hubspot for tracking.
    // Should only fire the very first time the user logs in as verified.
    if (!userDetails && idToken.email && idToken.email_verified) {
      segmentIdentify(undefined, idToken.email, {
        fw2_email_verified_date: DateTime.now().toISO(),
      });
    }

    // Create the users account if they are new or are no longer in an account.
    if (!userDetails || !userDetails.accountPermissions.length) {
      const newUserDetails = await createAccountForNewUser(
        createFAMApiClient(idToken)
      );
      segmentIdentify(newUserDetails.id, newUserDetails.email, {
        fw2_last_login_date: DateTime.utc().toISO(),
      });
      return handleUserWithAccount(idToken, newUserDetails);
    }

    segmentIdentify(userDetails.id, userDetails.email, {
      fw2_last_login_date: DateTime.utc().toISO(),
    });
    return handleUserWithAccount(idToken, userDetails);
  };

  const getCreationMethod = () => {
    const workflow = params.get(WORKFLOW_PARAM);
    const creationMethod = workflow
      ?.toLowerCase()
      .includes(CreationMethod.MARKETING)
      ? CreationMethod.MARKETING
      : CreationMethod.STANDARD;
    return creationMethod;
  };

  const createAccountForNewUser = async (famClient: FAMApiClient) => {
    const creationMethod = getCreationMethod();
    const [idToken] = await getUserDetails();
    return (await createNewAccount(famClient, idToken, creationMethod))[1]!;
  };

  const handleUserWithAccount = async (
    token: IdToken,
    userDetails: UserDetails
  ): Promise<HandleUserSignInResp | undefined> => {
    const famClient = createFAMApiClient(token);

    // 2. Ensure Email is verified.
    userDetails = await verifyEmail(famClient, token, userDetails);

    // 3. Get knock auth token
    const { knockToken } = await famClient.getKnockToken(userDetails.email!);

    // 4. Set the user context
    userContext.updateUserInfo({
      idToken: token,
      userDetails,
      knockToken,
    });

    // 5. Check if the URL contains account info and if so, set that account by default into context and so on.
    if (accountIdFromUrlParams) {
      /*
      Before setting the accountId for further proceedings check if the user has access to this account from the query param.
      If not move the user into switch_account page.
      And in case the user has only one account detault to that account.
      */
      const verifiedAccountId =
        userDetails.accountPermissions.find(
          (item) => item.account.id === accountIdFromUrlParams
        )?.account.id ||
        (userDetails.accountPermissions.length === 1 &&
          userDetails.accountPermissions[0].account.id);

      if (verifiedAccountId) {
        onAccountReset(
          token,
          userContext,
          userDetails,
          verifiedAccountId,
          channel
        );
        if (!redirectUrlFromUrlParams) {
          return { redirectTo: '/' };
        }
      } else {
        return { redirectTo: `/switch_account` };
      }
    }

    /* 6. Check if a specified redirectUrl is given through the query params during login
          If so, redirect specifically to that page.
          This can be used for pointing to any page in our App after selecting the right account if given in point 4 above.
          If Account is not given It would point to Account switcher and after account selection it redirectes to this page specified by this param.
          NOTE: This should be a full URL with query params in the `encodedUrl` format
          Eg: If the redirect URL is `products/catalog/seller` its encoded Url would be `products%2Fcatalog%2Fseller`
    */
    if (redirectUrlFromUrlParams) {
      return { redirectTo: redirectUrlFromUrlParams, excludeSearch: true };
    }

    // 7. If on account switcher page short circuit here.
    if (location.pathname === '/switch_account') {
      return;
    }

    // 8. Show Account Switcher if needed
    if (shouldRedirectToAccountSwitcher(userDetails)) {
      userContext.updateBillingInfo(AsyncRequestNotStarted());
      userContext.updateBillingEstimate(AsyncRequestNotStarted());

      return { redirectTo: '/switch_account' };
    }

    // 9. Update user context with currentAccountId from cookie, or first account if one is not found
    const cookie = FWCookie.readCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID);
    const currentAccountId = (
      userDetails.accountPermissions.find(
        (item) => item.account.id === cookie
      ) || userDetails.accountPermissions[0]
    )?.account.id;

    userContext.updateUserInfo({
      idToken: token,
      userDetails,
      currentAccountId,
      knockToken,
    });

    // 10. Show current page
  };

  const onUserInfoUpdate = async () => {
    // Once we have a user with an account, we identify you as user
    const currentRole = accountPermissions?.role || Role.ACCOUNT_OWNER;

    if (
      userContext.userInfo.idToken &&
      userContext.userInfo.currentAccountId &&
      currentRole !== Role.VIEW_ONLY
    ) {
      const billingInfo: AsyncRequest<AccountStatusResponse, void> =
        await createBillingApiClient(userContext.userInfo.idToken)
          .getAccountStatus(userContext.userInfo.currentAccountId)
          .then((response) => AsyncRequestCompleted(response))
          .catch(() => AsyncRequestFailed(undefined));

      const billingEstimate: AsyncRequest<BillingEstimateResponseType> =
        await createBillingApiClient(userContext.userInfo.idToken)
          .getBillingEstimate(userContext.userInfo.currentAccountId)
          .then((response) => AsyncRequestCompleted(response))
          .catch(() => AsyncRequestFailed(undefined));

      userContext.updateBillingInfo(billingInfo);
      userContext.updateBillingEstimate(billingEstimate);
      asyncRequestIsComplete(billingEstimate);
      const billingData = asyncRequestIsComplete(billingInfo)
        ? billingInfo.result
        : undefined;

      operationBigBrother(userContext.userInfo, billingData);
    }
  };

  useEffect(() => {
    segmentTracking();
    onUserInfoUpdate();
  }, [userContext.userInfo]);

  const segmentTracking = async () => {
    if (userContext.userInfo.idToken && userContext.userInfo.currentAccountId) {
      const { idToken: token, currentAccountId: id } = userContext.userInfo;

      const auth = await getAuthenticationDate(token, id);

      const currentRole = accountPermissions?.role || Role.ACCOUNT_OWNER;
      const zuoraAccountInfo =
        currentRole === Role.VIEW_ONLY
          ? {}
          : await getZuoraAccountInfo(token, id);

      const auth0UserId = token.sub;
      if (auth0UserId) {
        segmentSetAnonymousId(auth0UserId);
      }

      segmentIdentify(
        userContext.userInfo.userDetails?.id,
        userContext.userInfo.userDetails?.email!,
        { ...auth, ...zuoraAccountInfo }
      );
    }
  };

  const operationBigBrother = async (
    userInfo: UserInfo,
    billingAccountInfo?: AccountStatusResponse
  ) => {
    const accountPermissions = getCurrentAccountPermissions(
      userInfo.userDetails
    );
    const currentAccount = accountPermissions?.account;
    const currentRole = accountPermissions?.role || Role.ACCOUNT_OWNER;
    if (
      userInfo.idToken &&
      userInfo.userDetails &&
      userInfo.currentAccountId &&
      currentAccount
    ) {
      const famClient = createFAMApiClient(userInfo.idToken);

      intercomUserLoggedIn(userInfo.idToken, userInfo.userDetails);
      const creationMethod = getCreationMethod();

      const accountInfo = getCurrentAccountPermissions(userInfo.userDetails)!;
      const { id: userId, firstName, lastName, email } = userInfo.userDetails;

      LogRocket.identify(userId, {
        email,
        name: `${firstName} ${lastName}`,
        companyName: accountInfo.account.companyName,
        accountType: accountInfo.account.accountType,
        accountId: accountId!!,
        creationMethod: creationMethod,
      });

      const ftStartDate = currentAccount.freeTrialStartedAt;
      const ftEndDate = currentAccount.freeTrialEndsAt;
      const inTrial = isInTrial(currentAccount);

      const authProvider =
        userInfo?.idToken['https://flywheel.teikametrics.com/identities']?.[0]
          ?.provider;

      // auth0 post-registration hook sets these, so only set for social sign-on
      const { utmParams, workflow } =
        authProvider !== 'auth0'
          ? await handleSocialSignOn(params, userInfo.userDetails, famClient)
          : { utmParams: {}, workflow: null };

      const auth0UserId = userInfo?.idToken.sub;
      if (auth0UserId) {
        segmentSetAnonymousId(auth0UserId);
      }

      const traits: StringMap<any> = {
        rop_account_id: currentAccount.id,
        fam_id: currentAccount.id,
        salesforceaccountid: currentAccount.id,
        agreed_to_terms_of_service_and_privacy: currentAccount.onboardedAt,
        account_created_at: currentAccount.onboardedAt,
        company: {
          company_id: currentAccount.id,
          name: currentAccount.companyName,
        },
        contact_name: firstName + ' ' + lastName,
        first_name: firstName,
        last_name: lastName,
        account_name: currentAccount.companyName,
        fw2_email_verified_date: currentAccount.onboardedAt,
        fw2_registration_date: currentAccount.onboardedAt,
        fw2_automation_status: isAIPlanEnabled(userContext),
        fw2_trial_status: inTrial,
        fw2_trial_start_date: ftStartDate,
        fw2_trial_end_date: ftEndDate,
        fw2_billing_status: billingAccountInfo?.hasPayment
          ? 'paying'
          : 'not_paying',
        first_credit_card_added_date: billingAccountInfo?.createdAt,
        fw_signup_method: authProvider,
        account_role: currentRole,
        ...utmParams,
      };

      if (workflow) {
        traits['fw_signup_workflow'] = workflow;
      }

      segmentIdentify(userId, email, traits);
    }
  };

  useEffect(() => {
    let isMounted = true;
    handleUserSignIn().then((resp) => {
      if (resp) {
        const { redirectTo, excludeSearch } = resp;
        if (redirectTo && isMounted) {
          return navigate(
            `${redirectTo}${excludeSearch ? '' : location.search}`
          );
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const [appStore, setAppStore] = useState<Store<AppState, AppAction>>();

  const salesChannelPageSize = getItemsPerPageByTableIdFromStorage({
    userId,
    tableId: SALES_CHANNELS_TABLE,
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });
  const usersTablePageSize = getItemsPerPageByTableIdFromStorage({
    userId,
    tableId: USER_MANAGEMENT_TABLE_ID,
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  useEffect(() => {
    if (userContext.userInfo.idToken) {
      setAppStore(
        createAppStore(
          userContext.userInfo.idToken,
          salesChannelPageSize,
          usersTablePageSize
        )
      );
    }
  }, [userContext.userInfo.idToken, salesChannelPageSize, usersTablePageSize]);

  if (!appStore || !userContext.userInfo.userDetails) {
    return <Spinner />;
  }

  const getRedirectUrl = () => {
    if (redirectUrlFromUrlParams) {
      return decodeURIComponent(redirectUrlFromUrlParams);
    }

    const workflow = params.get(WORKFLOW_PARAM) as string;
    if (workflow && WORKFLOW_REDIRECTIONS[workflow]) {
      return `${WORKFLOW_REDIRECTIONS[workflow]}${location.search}`;
    }

    return location.search;
  };

  return (
    <Provider store={appStore}>
      <OptimizelyProvider userInfo={userContext.userInfo}>
        <PageHeaderProvider>
          <SaveChangesFlagProvider>
            <SalesChannelProvider
              idToken={userContext.userInfo.idToken!!}
              accountId={accountId}
            >
              <MerchantCountriesProvider>
                <NotificationProvider>
                  <AxiosProvider>
                    <SubscriptionProvider
                      idToken={userContext.userInfo.idToken!!}
                    >
                      <DataSyncProvider>
                        <CustomUploadProvider>
                          <RecommendationsProvider>
                            <ViewTrendsContextProvider>
                              <Routes>
                                <Route
                                  path="/switch_account"
                                  element={
                                    <AccountSwitcher
                                      redirectUrl={`/${getRedirectUrl()}`}
                                      dataTestId={'switchAccount'}
                                      channel={channel}
                                    />
                                  }
                                />
                                <Route
                                  path="amz-sp-api/callback"
                                  element={<OauthPopup />}
                                />
                                <Route
                                  path="auth/wmt-products/callback"
                                  element={<OauthPopup />}
                                />
                                <Route
                                  path="*"
                                  element={
                                    <AppModules
                                      token={userContext.userInfo.idToken!!}
                                      permissions={accountPermissions}
                                      channel={channel}
                                    />
                                  }
                                />
                              </Routes>
                            </ViewTrendsContextProvider>
                          </RecommendationsProvider>
                        </CustomUploadProvider>
                      </DataSyncProvider>
                    </SubscriptionProvider>
                  </AxiosProvider>
                </NotificationProvider>
              </MerchantCountriesProvider>
            </SalesChannelProvider>
          </SaveChangesFlagProvider>
        </PageHeaderProvider>
      </OptimizelyProvider>
    </Provider>
  );
};
LoggedIn.displayName = 'LoggedIn';

/**
 * If the 'accept_account_invite' query param is present this will
 * clear the current account cookie so that the account switcher if forced once
 * the user accepts the invite.
 */
const acceptInvitationIfPresent = async (
  famClient: FAMApiClient,
  params: URLSearchParams
): Promise<void> => {
  const invitedAccountId = params.get('accept_account_invite');
  if (invitedAccountId) {
    FWCookie.deleteCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID);
    return famClient.acceptInvite(invitedAccountId);
  }

  return Promise.resolve();
};

const verifyEmail = async (
  famClient: FAMApiClient,
  idToken: IdToken,
  userDetails: UserDetails
) => {
  // Check if the users email was verified. If so update our system to show the user as verified.
  if (userDetails && !userDetails?.emailVerified && idToken.email_verified) {
    famClient.emailVerified().catch(noop);
    userDetails.emailVerified = true;
  }
  return userDetails;
};

const createNewAccount = async (
  famClient: FAMApiClient,
  token: IdToken,
  creationMethod: string
): Promise<[Account, UserDetails | undefined]> => {
  // Create new account for user.
  const signUpData = getSignUpData(token);
  const account = await famClient.createAccount(creationMethod, signUpData);

  // Load the users new data.
  const refreshedDetails = await famClient.getUserDetails();
  return [account, refreshedDetails];
};

export default LoggedIn;
