import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  FlywheelTableSortableTableHeaderComponent,
  SortableTableHeader as SortableTableHeaderComponent,
} from '../../components/TableHeader/sortableTableHeader';
import { Sort } from '../../lib/types/Sort';
import { tableActions, tableSelectors } from './ducks';
import { TableAction } from './ducks/actions';
import { WithTable } from './ducks/types';

interface OwnProps {
  readonly columnName: string;
  readonly tableId: string;
  readonly children?: ReactNode;
}

interface StateProps {
  readonly currentSort: Sort;
}

interface DispatchProps {
  readonly applySort: (sort: Sort) => void;
  readonly removeSort: (column: string) => void;
}

type SortableHeaderProps = OwnProps & StateProps & DispatchProps;

const SortableHeaderImpl: React.FC<SortableHeaderProps> = ({
  children,
  ...props
}) => (
  <SortableTableHeaderComponent
    applySort={props.applySort}
    removeSort={props.removeSort}
    currentSort={props.currentSort}
    columnName={props.columnName}
  >
    {children}
  </SortableTableHeaderComponent>
);
SortableHeaderImpl.displayName = 'SortableHeaderImpl';

const FlywheelTableSortableHeaderImpl: React.FC<SortableHeaderProps> = ({
  children,
  ...props
}) => (
  <FlywheelTableSortableTableHeaderComponent
    applySort={props.applySort}
    removeSort={props.removeSort}
    currentSort={props.currentSort}
    columnName={props.columnName}
    tableId={props.tableId}
  >
    {children}
  </FlywheelTableSortableTableHeaderComponent>
);
FlywheelTableSortableHeaderImpl.displayName = 'FlywheelTableSortableHeaderImpl';

function makeMapStateToProps<S extends WithTable<T, F>, T, F>() {
  const tableSelector = tableSelectors.getTableSelector<T, F>();

  return (state: S, ownProps: OwnProps): StateProps => ({
    currentSort: tableSelector(state.tableState, ownProps.tableId).sorts[0],
  });
}

function mapDispatchToProps<S extends WithTable<T, F>, T, F, E>(
  dispatch: ThunkDispatch<S, E, TableAction>,
  ownProps: OwnProps
): DispatchProps {
  return {
    applySort: (sort: Sort) => {
      dispatch(tableActions.sortApplied({ sort, tableId: ownProps.tableId }));
    },
    removeSort: (column: string) => {
      dispatch(tableActions.sortRemoved({ column, tableId: ownProps.tableId }));
    },
  };
}

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SortableHeaderImpl);

export const ConnectedFlywheelTableSortableHeaderImpl = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(FlywheelTableSortableHeaderImpl);
