import { findFirst } from 'fp-ts/lib/Array';
import { Option, fromNullable } from 'fp-ts/lib/Option';
import debounce from 'lodash/fp/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { SearchInput, SearchInputSize } from '@teikametrics/tm-design-system';

import I18nKey from '../../lib/types/I18nKey';
import { INITIAL_LOAD_KEY } from '../tableV2/ducks/types';
import { isTableLoading } from '../tableV2/utils';
import { tableActions, tableSelectors } from './ducks';
import { TableAction } from './ducks/actions';
import { WithTable } from './ducks/types';
import { Filter, FilterOps, LikeFilter } from '../../lib/types/Filter';
import { asyncRequestIdentifiedBy } from '../../lib/utilities/asyncRequest';
import { isLikeFilter } from '../../lib/utilities/filter';

interface FlywheelTableSearchInputProps {
  readonly tableId: string;
  readonly inputSearchColumnName: string;
  readonly shouldAddWildcardsToFilterValue?: boolean;
  readonly searchInputSize?: SearchInputSize;
  readonly searchInputPlaceholder?: string;
  readonly onFilterUpdate?: (filters: Filter[]) => void;
  readonly upgradeFiltersInStorage: (filters: Filter[]) => void;
  readonly hasSearchInputWithButton?: boolean;
  readonly dataTestId?: string;
}

const FlywheelSearchInput: React.FC<FlywheelTableSearchInputProps> = ({
  tableId,
  inputSearchColumnName,
  shouldAddWildcardsToFilterValue,
  searchInputSize = SearchInputSize.Medium,
  searchInputPlaceholder,
  onFilterUpdate,
  upgradeFiltersInStorage,
  hasSearchInputWithButton = false,
  dataTestId,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const table = useSelector((state: any) =>
    tableSelectors.getTableSelector()(state.tableState, tableId)
  );
  const pageOfData = fromNullable(table.pages[0]).getOrElse(
    asyncRequestIdentifiedBy<any[], void>(
      INITIAL_LOAD_KEY
    ).AsyncRequestNotStarted()
  );

  const currentTableFilters = useSelector<WithTable<any, any>, Filter[]>(
    (state) => tableSelectors.getFiltersSelector()(state.tableState, tableId)
  );

  const currentSearchFilter = useSelector<
    WithTable<any, any>,
    Option<LikeFilter>
  >((state) => {
    const currentTable = tableSelectors.getTableSelector()(
      state.tableState,
      tableId
    );
    return findFirst(
      currentTable.filters,
      (filter) =>
        filter.op === FilterOps.like && filter.field === inputSearchColumnName
    ).filter(isLikeFilter);
  }, shallowEqual);
  const currentSearchFilterValue = currentSearchFilter
    .map((f) => f.value)
    .getOrElse('');

  const [searchValue, setSearchValue] = useState<string>(
    currentSearchFilterValue
  );

  const createSearchFilter =
    (columnName: string, addWildcardsToFilterValue?: boolean) =>
    (newValue: string): Option<LikeFilter> => {
      const trimmedNewValue = newValue.trim();
      const trimmedValueWithWildcards = addWildcardsToFilterValue
        ? `%${trimmedNewValue}%`
        : trimmedNewValue;
      const isSearchCleared = trimmedNewValue === '';
      const newFilter: LikeFilter | undefined = isSearchCleared
        ? undefined
        : {
            op: FilterOps.like,
            field: columnName,
            value: trimmedValueWithWildcards,
          };
      return fromNullable(newFilter);
    };

  const updateTableFilters = (
    dispatchThunk: ThunkDispatch<{}, {}, TableAction>,
    newValue: string,
    filters: Filter[]
  ) => {
    const newFilter = createSearchFilter(
      inputSearchColumnName,
      shouldAddWildcardsToFilterValue
    )(newValue);
    const dispatchAction = onSearchInputFilterChangeWrapper(
      tableId,
      inputSearchColumnName,
      filters
    );
    dispatchAction(newFilter, dispatchThunk);
  };

  const onSearchInputFilterChangeWrapper =
    (id: string, columnName: string, filters: Filter[]) =>
    (
      maybeNewFilter: Option<LikeFilter>,
      dispatchThunk: ThunkDispatch<{}, {}, TableAction>
    ): void => {
      const action = maybeNewFilter
        .map<TableAction>((newFilter) => {
          fromNullable(onFilterUpdate)
            // eslint-disable-next-line array-callback-return
            .map((fn) => {
              // exclude previous search filter and call the handler with existing filters and new search filter
              const newFilters = filters
                .filter(
                  (filter) =>
                    filter.field !== columnName && filter.op !== FilterOps.like
                )
                .concat(newFilter);
              upgradeFiltersInStorage(newFilters);
              fn(newFilters);
            })
            .toUndefined();
          return tableActions.updateFilters({
            tableId: id,
            filters: [newFilter],
            replace: false,
          });
        })
        .getOrElseL(() => {
          fromNullable(onFilterUpdate)
            // eslint-disable-next-line array-callback-return
            .map((fn) => {
              const newFilters = filters.filter(
                (filter) =>
                  filter.field !== columnName && filter.op !== FilterOps.like
              );
              fn(newFilters);
              upgradeFiltersInStorage(newFilters);
            })
            .toUndefined();
          return tableActions.clearFilter({
            field: columnName,
            tableId,
          });
        });
      dispatchThunk(action);
    };

  const onSearchInputChangeHandler = useMemo(() => {
    // Updating filters causes the underlying table data to update.
    // Updating table data requires an API request.
    // We do not wanna fire off API requests on every keystroke.
    // Therefore debounce the actual dispatching of the filter updates
    const debouncedTableFiltersUpdate = debounce(500, updateTableFilters);

    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      // We maintain the input value separate from the current filter value
      // because the filter value is debounced
      setSearchValue(newValue);
      debouncedTableFiltersUpdate(dispatch, newValue, currentTableFilters);
    };
  }, [inputSearchColumnName, dispatch, currentTableFilters]);

  const onSearchButtonClick = useMemo(() => {
    return (newValue: string) => {
      setSearchValue(newValue);
      updateTableFilters(dispatch, newValue, currentTableFilters);
    };
  }, [inputSearchColumnName, dispatch, currentTableFilters]);

  const onChange = useMemo(() => {
    return (newValue: string) => {
      setSearchValue(newValue);
      if (currentSearchFilterValue && !newValue) {
        onSearchInputClear();
      }
    };
  }, [currentSearchFilterValue]);

  const onSearchInputClear = () => {
    updateTableFilters(dispatch, '', currentTableFilters);
    setSearchValue('');
  };

  const searchButtonInputProps = {
    label: intl.formatMessage({ id: I18nKey.SEARCH }),
    loading: isTableLoading(pageOfData.kind),
    onSearchButtonClick,
    onChange,
  };

  useEffect(() => {
    setSearchValue(currentSearchFilterValue);
  }, [currentSearchFilterValue]);

  return (
    <SearchInput
      value={searchValue}
      size={searchInputSize}
      onSearchInputClear={onSearchInputClear}
      placeholder={searchInputPlaceholder}
      dataTestId={`${dataTestId}_flywheelSearchInput`}
      onDebounceChange={
        hasSearchInputWithButton ? undefined : onSearchInputChangeHandler
      }
      searchButtonProps={
        hasSearchInputWithButton ? searchButtonInputProps : undefined
      }
    />
  );
};
FlywheelSearchInput.displayName = 'FlywheelSearchInput';
export default FlywheelSearchInput;
