import { Factory } from 'fishery';
import {
  AdGroupReviewStatusType,
  AdGroupStatus,
  CampaignStatus,
  CampaignTargetingType,
  ProfileApiDataDetails,
  ProfileStatus,
  ReviewStatus,
} from '../../types/AOSharedTypes';

export interface Response {
  readonly elements: ProfileApiDataDetails[];
  readonly filteredElements: number;
}

export default Factory.define<Response>(() => ({
  elements: [
    {
      profileId: '4cc103ef-9323-58b6-bba3-da29ee609611',
      profileDetails: {
        merchantCountryId: '32869590-a45e-4041-bd2b-538844a1781b',
        adGroupName: 'Bath Accessories|UnaCanBrightColors10in|Non-Brand|SP-KW',
        adGroupStatus: AdGroupStatus.Enabled,
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.Complete,
          reviewReason: [
            {
              commentType: 'Brand',
              comments:
                'Brand name does not match with the logo w.r.t. Relevancy. For example, the Brand name states "Cuisinart Outdoor Grilling" while the logo has "Cuisinart" which has no relevancy.',
            },
          ],
          reviewComments:
            'We are rejecting a bunch of keywords for the following reasons:\na) Certain sets of keywords are irrelevant to the product/brand which is submitted (For example puyopuyo ps5,sackboy ps5, etc...)\nb) Certain set of keywords not meeting our conquesting policy guidelines (For example seagate 2tb game drive, etc...)\nPlease refer to the Guideline and Best Practices document for more information.',
        },
        campaignName: 'Bath Accessories|Non-Brand|SP-KW',
        campaignStatus: CampaignStatus.Enabled,
        campaignTargetingType: CampaignTargetingType.Manual,
        campaignDailyBudget: {
          amount: '3000.00',
          currency: 'USD',
        },
        getCampaignLifetimeBudget: {
          amount: '53000.00',
          currency: 'USD',
        },
        brand: 'some-brand',
        profileReviewStatus: {
          reviewStatus: ReviewStatus.Rejected,
          reviewReason:
            'The brand name does not match with the logo w.r.t. Relevancy. For example, the Brand name states "Cuisinart Outdoor Grilling" while the logo has "Cuisinart" which has no relevancy.',
        },
      },
      channelSettings: {
        headline: 'Explore creativity with LEGO Classic sets',
        clickUrl:
          'https://www.walmart.com/browse/lego/lego-classic/4171_4186_1105635_8491615',
        logo: 'https://i5.walmartimages.com/dfw/9fa19e5c-ad/k2-_b4269be2-51d8-44ed-964f-315b47c8f775.v1.png',
        profileStatus: ProfileStatus.Enabled,
      },
      profilePerformance: {
        adSalesTotal: {
          amount: '1259.68',
          currency: 'USD',
        },
        adSpend: {
          amount: '350.00',
          currency: 'USD',
        },
        roasTotal: 3.59908571,
        acosTotal: 0.27784834,
        conversionRate: 0.01818182,
        unitsSold: 32,
        impressions: 1773407,
        clicks: 1791,
        clickThroughRate: 0.00100992,
        avgCostPerClick: {
          amount: 0.19542155,
          currency: 'USD',
        },
        adSalesDirect: {
          amount: 959.76,
          currency: 'USD',
        },
        acosDirect: 0.3646745,
        roasDirect: 2.74217143,
        relatedClickRevenue: {
          amount: 59.98,
          currency: 'USD',
        },
        brandClickRevenue: {
          amount: 239.94,
          currency: 'USD',
        },
        advertisedSkuUnits: 10,
        otherSkuUnits: 10,
      },
    },
    {
      profileId: '4cc103ef-9323-58b6-bba3-da29ee609611',
      profileDetails: {
        merchantCountryId: '02869590-a45e-4041-bd2b-538844a1781c',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.InProgress,
          reviewComments:
            'We are rejecting a bunch of keywords for the following reasons:\na) Certain sets of keywords are irrelevant to the product/brand which is submitted (For example puyopuyo ps5,sackboy ps5, etc...)\nb) Certain set of keywords not meeting our conquesting policy guidelines (For example seagate 2tb game drive, etc...)\nPlease refer to the Guideline and Best Practices document for more information.',
        },
        brand: 'some-brand',
        profileReviewStatus: {
          reviewStatus: ReviewStatus.Rejected,
          reviewReason: '',
        },
      },
      channelSettings: {
        logo: 'https://i5.walmartimages.com/dfw/9fa19e5c-ad/k2-_b4269be2-51d8-44ed-964f-315b47c8f775.v1.png',
        profileStatus: ProfileStatus.Disabled,
      },
      profilePerformance: {},
    },
    {
      profileId: '4cc103ef-9323-58b6-bba3-da29ee609611',
      profileDetails: {
        merchantCountryId: '02869590-a45e-4041-bd2b-538844a1781c',
        adGroupReviewStatus: {
          reviewStatus: AdGroupReviewStatusType.Complete,
          reviewComments:
            'We are rejecting a bunch of keywords for the following reasons:\na) Certain sets of keywords are irrelevant to the product/brand which is submitted (For example puyopuyo ps5,sackboy ps5, etc...)\nb) Certain set of keywords not meeting our conquesting policy guidelines (For example seagate 2tb game drive, etc...)\nPlease refer to the Guideline and Best Practices document for more information.',
        },
        brand: 'some-brand',
        profileReviewStatus: {
          reviewStatus: ReviewStatus.Approved,
          reviewReason: '',
        },
        campaignStatus: CampaignStatus.Enabled,
      },
      channelSettings: {
        profileStatus: ProfileStatus.Disabled,
        logo: 'https://i5.walmartimages.com/dfw/9fa19e5c-ad/k2-_b4269be2-51d8-44ed-964f-315b47c8f775.v1.png',
      },
      profilePerformance: {},
    },
  ],
  filteredElements: 1,
}));
