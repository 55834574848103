import {
  ContentStyle,
  ControlledTooltip,
  PaletteColor,
  Pill,
  PillSize,
  Placement,
  SelectProps,
  SelectShowSearch,
  SelectState,
  SelectVariant,
  Theme,
  Tooltip,
  TruncatedText,
  createMultiSelectDataFieldFilter,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import classNames from 'classnames';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { makeSelectColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  CampaignDetails,
  Groups,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import {
  AdLevelI8nKeyMapper,
  NO_GROUP_VALUE,
  TableDataAdsManager,
} from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  getTooltipContentForArchivedEntity,
  isCampaignStatusArchived,
} from '../utils';

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) => {
  const {
    isEditMode,
    aoApiClient,
    groupNames,
    openCreateEditGroupSlideover,
    selectedMerchantCountries,
    ...campaignDetails
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const currentTableId = ADS_MANAGER_CAMPAIGNS_TABLE_ID;

  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.Group
      )(tableState, currentTableId)
  );

  const existingGroupLabels = campaignDetails.campaignDetails.groupLabel;
  const existingGroupIds = groupNames
    ?.filter(
      (group) => group.groupId && existingGroupLabels?.includes(group.name)
    )
    .map((item) => item.groupId);

  const existingGroupNames = existingGroupIds?.join(',') || '';

  const value = changedValue || existingGroupNames || NO_GROUP_VALUE;

  const updateCellValue = (newValue: string | string[]) => {
    const saveValue =
      isArray(newValue) &&
      newValue?.includes(NO_GROUP_VALUE) &&
      !value.includes(NO_GROUP_VALUE) &&
      (!changedValue ||
        (changedValue && !changedValue?.includes(NO_GROUP_VALUE)))
        ? [NO_GROUP_VALUE]
        : isArray(newValue)
        ? newValue.filter((item) => item !== NO_GROUP_VALUE)
        : newValue;

    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.Group,
        rowId: campaignDetails.campaignId,
        tableId: currentTableId,
        value: isArray(saveValue) ? saveValue.join(',') : saveValue,
        existingValue: existingGroupNames,
      })
    );
  };

  const isEditableGroupName = () => !isEmpty(groupNames);

  if (isEditMode && isEditableGroupName()) {
    const options =
      groupNames?.map(({ name, groupId }, i) => {
        return {
          tooltip: {
            content: name,
            disabled: name.length < 17,
          },
          label: name,
          value: groupId || name,
          groupName:
            i === 0
              ? intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_EDIT_PORTFOLIO_GROUP_HEADER,
                })
              : '',
        };
      }) || [];

    const groupNameSelectProps: SelectProps<string> = {
      options,
      placeholder: '',
      value: value && value?.split(','),
      isDirty: value !== existingGroupNames,
      onChange: updateCellValue,
      showSearch: SelectShowSearch.Show,
      className: 'w-200',
      state: isCampaignStatusArchived(campaignDetails.channelSettings.status)
        ? SelectState.Disabled
        : SelectState.Default,
      hasStickyScroll: true,
      menuClassName: 'max-h-240',
      selectMenuZIndexClass: 'z-20 max-w-200',
      dataTestId: 'group_names',
      variant: SelectVariant.TextWithCheckbox,
      showGroupDivider: true,
      minOptionsForSearch: 6,
      useV2Header: true,
      hasSelectHeader: true,
      showV2SearchWithoutHeader: true,
      isMaxWidthMenu: false,
      optionHoverClassName: 'hover:bg-grey-50',
      multiSelectAdditionalText: 'more',
      customFooterElementMenu: (
        <div className="pb-8">
          <div className="border-t border-grey-200 border-solid pb-8" />
          <div
            className={classNames(
              'px-12 py-8',
              'text-base leading-none font-normal',
              'cursor-pointer hover:bg-grey-50'
            )}
            onClick={openCreateEditGroupSlideover}
          >
            {intl.formatMessage({
              id:
                options.length > 1
                  ? I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_HEADING
                  : I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_CREATE_BUTTON,
            })}
          </div>
        </div>
      ),
    };

    return (
      <>
        {makeSelectColumn<CampaignDetails>(
          (_campaignDetails) => groupNameSelectProps,
          ({ channelSettings: { status } }) => {
            const isCampaignArchived = isCampaignStatusArchived(status);

            const tooltipContent = () => {
              if (isCampaignArchived) {
                return getTooltipContentForArchivedEntity(
                  intl.formatMessage({
                    id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
                  }),
                  intl.formatMessage({
                    id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_GROUP,
                  }),
                  intl
                );
              }
            };

            return {
              tooltipContent: tooltipContent(),
              controlledTooltip: isCampaignArchived
                ? ControlledTooltip.None
                : ControlledTooltip.Hide,
              tooltipPlacement: Placement.Bottom,
              style: ContentStyle.Bold,
            };
          }
        )(campaignDetails)}
      </>
    );
  }

  const labels = campaignDetails.campaignDetails?.groupLabel || [];
  const displayValue = labels?.[0];
  const moreText = intl.formatMessage(
    {
      id: I18nKey.AD_OPTIMIZER_TABLE_GROUP_MORE_TEXT,
    },
    {
      count: labels.length - 1,
    }
  );

  return (
    <div
      className={classNames(
        'flex items-center justify-between gap-8',
        'h-full w-full p-12',
        'text-base leading-tight font-normal'
      )}
    >
      <TruncatedText
        text={
          displayValue ||
          intl.formatMessage({
            id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_NO_GROUP,
          })
        }
        fontClassNames="text-base leading-tight font-normal"
        hoverClassNames="text-base leading-tight font-normal"
      />
      {labels.length > 1 && (
        <div>
          <Tooltip
            overwrittenTooltipClassnames="w-auto max-w-400"
            theme={Theme.Dark}
            content={getTooltipContent(labels)}
            disabled={false}
          >
            <Pill
              color={PaletteColor.greyV2}
              pillSize={PillSize.Small}
              text={moreText}
            />
          </Tooltip>
        </div>
      )}
      {/* {displayValue && <ViewTrendLink />} */}
    </div>
  );
};
RowCellElement.displayName = 'RowCellElement';

export const groupColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.Group,
  isSortable: false,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_GROUP,
  RowCellElement,
  gridColumnWidth: '240px',
};

export const groupFilter = (groups?: Groups[] | undefined) => {
  if (!groups || !groups.length) {
    return createStringDataFieldFilter(
      CAMPAIGNS_API_COLUMN_NAME.Group,
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_GROUP,
      isValidString()
    );
  }

  const options = groups.map((group, index) => ({
    value: index !== 0 ? group.name : NO_GROUP_VALUE,
    displayI18nKey: group.name || '-',
  }));
  return createMultiSelectDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.Group,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_GROUP,
    [...options]
  );
};

export const getTooltipContent = (labels: string[]) => {
  return (
    <div className="flex flex-col text-sm leading-tight font-normal break-all">
      {labels.map((label) => {
        return (
          <div className="flex flex-row gap-4 items-start">
            <div className="h-4 w-4 text-white">•</div>
            <div>{label}</div>
          </div>
        );
      })}
    </div>
  );
};
