import React from 'react';

import {
  ColumnAlign,
  TableCellV2,
  TableCellV2Type,
} from '@teikametrics/tm-design-system';

import { FlywheelTableV2ColumnProps } from '../../../../../../containers/tableV2/types';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { BusinessPerformanceColumnName } from '../../utils/constants';
import {
  CampaignCellData,
  CampaignRowData,
  PerformanceTableExtraProps,
} from '../../utils/performance';

export const RowCellElement: React.FC<CampaignCellData> = ({ rank }) => (
  <TableCellV2
    tableCellData={{
      currentValue: String(rank),
      tableCellType: TableCellV2Type.Number,
    }}
  />
);

export const rank: FlywheelTableV2ColumnProps<
  CampaignRowData,
  {},
  PerformanceTableExtraProps
> = {
  header: I18nKey.COMPASS_DASHBOARD_PERFORMANCE_TABLE_RANK,
  sortable: false,
  accessorKey: BusinessPerformanceColumnName.Rank,
  size: 64,
  minSize: 64,
  align: ColumnAlign.Left,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <RowCellElement {...original} {...meta} />,
};
