import classNames from 'classnames';
import React from 'react';
import useResizeObserver from 'use-resize-observer';

import { ColumnGrouping, GRID_TABLE_CLASSES } from './types';

const getGroupDetails = (
  columnGroups: Array<ColumnGrouping<any, any, any, any>>
): Array<
  Readonly<{
    groupingHeaderComponent: JSX.Element | undefined;
    gridColumnStart: number;
    gridColumnEnd: number;
  }>
> => {
  let gridColumnIdx = 1;

  return columnGroups.map((columnGroup) => {
    const { columnsInGroup, groupingHeaderComponent } = columnGroup;
    const gridColumnStart = gridColumnIdx;
    const gridColumnEnd = gridColumnStart + columnsInGroup.length;
    gridColumnIdx = gridColumnEnd;

    return {
      gridColumnStart,
      gridColumnEnd,
      groupingHeaderComponent,
    };
  });
};

const ColumnGroupsRow: React.FC<
  Readonly<{
    columnGroups: Array<ColumnGrouping<any, any, any, any>>;
    onKnownHeight: (height: number) => void;
  }>
> = ({ columnGroups, onKnownHeight }) => {
  const columnsToRender = getGroupDetails(columnGroups);
  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height }) => {
      if (height) {
        onKnownHeight(height);
      }
    },
  });

  return (
    <>
      {columnsToRender.map(
        (
          { groupingHeaderComponent, gridColumnStart, gridColumnEnd },
          colIdx
        ) => {
          const isLeftMostCell = colIdx === 0;
          const isRightMostCell = colIdx === columnGroups.length - 1;
          // All cells in row should have same height so only watching first
          // cell's height
          const refProp = isLeftMostCell ? { ref } : {};

          return (
            <div
              key={`group-${gridColumnStart}__${gridColumnEnd}`}
              style={{ gridColumnStart, gridColumnEnd }}
              className={classNames(
                'grid-table__grouped-header-cell-container',
                {
                  [GRID_TABLE_CLASSES.LEFT_MOST_COLUMN]: isLeftMostCell,
                  [GRID_TABLE_CLASSES.RIGHT_MOST_COLUMN]: isRightMostCell,
                }
              )}
              {...refProp}
            >
              <div className="grid-table__grouped-header-cell">
                {groupingHeaderComponent}
              </div>
            </div>
          );
        }
      )}
    </>
  );
};
ColumnGroupsRow.displayName = 'ColumnGroupsRow';

export default ColumnGroupsRow;
