import {
  Icon,
  IconSize,
  PaletteColor,
  Pill,
  PillSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { MerchantCountry, MerchantType } from '../../../../lib/types/Fam';
import { MAP_SALES_CHANNEL_ID_TO_ICON_MAPPER } from '../../../../lib/types/SalesChannels';
import { getCountryFlagIcon } from '../../../../lib/utilities/countryFlags';
import classNames from 'classnames';
import { SellerType } from '../../../account/containers/utils';
import { UserAutoNegateSettings } from 'lib/types/AOSharedTypes';

export const getMerchantCountryDetailsVeiw = (merchant: MerchantCountry) => (
  <p className="flex items-center gap-x-8">
    <Icon
      size={IconSize.Medium}
      svg={MAP_SALES_CHANNEL_ID_TO_ICON_MAPPER[merchant.salesChannelId!]}
    />
    <Icon size={IconSize.Medium} svg={getCountryFlagIcon(merchant.country)} />
    <Pill
      pillSize={PillSize.Small}
      color={PaletteColor.empty}
      text={getMerchantTypeIndicator(merchant.merchantType)}
      isBorderRounded={false}
      className={classNames(
        'border border-solid border-grey-200',
        'rounded p-0 h-16 text-grey-500 w-24',
        'flex justify-center items-center'
      )}
    />
    <Typography
      size={TypographySize.base}
      lineHeight={TypographyLineHeight.none}
      weight={TypographyWeight.regular}
    >
      {merchant.merchantName}
    </Typography>
  </p>
);

export const getMerchantTypeIndicator = (mcType: MerchantType) =>
  mcType === MerchantType.Vendor ? SellerType['1P'] : SellerType['3P'];

export const getMerchantsByChannel = (
  merchants: MerchantCountry[],
  channelId: string
) => merchants.filter((mc) => mc.salesChannelId === channelId);

export const getAutoNegateStatus = (
  merchantStatuses: UserAutoNegateSettings[],
  mcId: string
) =>
  !!merchantStatuses.find((mc) => mc.merchantCountryId === mcId)
    ?.isAutoNegateEnabled;

export const sortMerchantsByName = (
  mc1: MerchantCountry,
  mc2: MerchantCountry
) => {
  const mc1Name = mc1.merchantName.toLowerCase();
  const mc2Name = mc2.merchantName.toLowerCase();
  if (mc1Name < mc2Name) {
    return -1;
  }
  if (mc1Name > mc2Name) {
    return 1;
  }
  return 0;
};

export const getAutoNegateStatusForMerchant = (
  mcId: string,
  userSettings: UserAutoNegateSettings[],
  isManaged?: boolean
) => {
  const merchantSetting = userSettings.find(
    (mc) => mc.merchantCountryId === mcId
  );
  return merchantSetting ? merchantSetting.isAutoNegateEnabled : !!isManaged;
};
