import {
  KeywordCampaign,
  SovKeyword,
  SovKeywordCampaignsRequest,
  SovKeywordRequest,
  SovMerchantsRequest,
  SovMerchantsResponse,
  SovProductHierarchy,
  SovProductsRequest,
  SovProductsResponse,
  SovTopProductResponse,
  SovTopProductsRequest,
} from './../types/MISharedTypes';
import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';

import { IdToken } from '@auth0/auth0-react';
import { IntlShape } from 'react-intl';

import { sortDataForBrandCoverageOnSearchTable } from '../../modules/marketIntelligence/containers/reporting/utils';
import { mapCountryCodeToCountryName } from '../../modules/marketIntelligence/containers/settings/utils';
import {
  filterEmptyValues,
  getPaginationParamsFromRequest,
} from '../factories/apiDataFetcherFactory';
import { DEFAULT_RETRY_INTERVAL } from '../types/HttpErrorCodes';
import {
  AddNewSearchTermsRequestPayload,
  AddNewSearchTermsResponse,
  AddNewSearchTermsStatusRequestPayload,
  AddNewSearchTermsStatusResponse,
  AddNewSearchTermsV3RequestPayload,
  AddNewSearchTermsV3Response,
  AddOrRemoveSearchTermsToOrFromGroupRequestPayload,
  AddOrRemoveSearchTermsToOrFromGroupResponse,
  AutoCompleteSearchTermsRequestPayload,
  AverageProductRankData,
  BCSBackgroundRequests,
  BCSChartsDataResponse,
  BrandCoverageOnSearchData,
  BulkExportRequestParamsV2,
  CreateGroupRequestPayload,
  DeleteGroupRequestPayload,
  GroupsData,
  IgnoreSuggestedSearchTermsRequestPayload,
  MIPublicAPISearchTermsAndGroupsResponse,
  MIPublicAPISearchTermsResponse,
  MISearchTermsPaginatedResult,
  MI_BASE_URL,
  PlatformData,
  PlatformDataWithCountries,
  PublicAPISearchTermsRequest,
  PublicAPISearchTermsResponse,
  ReportStatusAndData,
  ReportingRequestParams,
  SearchTermSource,
  SearchTermsChartsQueryParams,
  SearchTermsDataResponse,
  SovExportRequest,
  SovKeywordsResponse,
  SovProductsTrendResponse,
  SovTrendRequest,
  SuggestedSearchTermsCountRequestPayload,
  SuggestedSearchTermsCountResponse,
  SuggestedSearchTermsRequestPayload,
  SuggestedSearchTermsRequestResponse,
  TermCountryChannel,
  UpdateGroupRequestPayload,
  UploadSearchTermsStatusResponse,
} from '../types/MISharedTypes';
import { getDefaultHeaders } from './index';
import { PaginatedRequest, PaginatedResult } from './types';
import {
  getReducedFilterParams,
  getTransformedCampaignData,
  getTransformedGroupsData,
  getTransformedSearchTermsAndGroupsData,
  getTransformedSearchTermsData,
  showErrorToast,
  transformSortMIRequest,
} from '../../lib/utilities/miUtilities';
import I18nKey from '../../lib/types/I18nKey';
import { ToastProps } from '@teikametrics/tm-design-system';
import { buildUrlWithQueryParams } from '../utilities/buildUrlUtilities';

export interface UploadSearchTermsResponse {
  readonly n_search_terms: number;
  readonly last_updated: string;
}

interface SearchTermsTableQueryParams {
  readonly limit?: number;
  readonly offset?: number;
  readonly sort_by?: string;
  readonly sort_order?: string;
  readonly search?: string;
}

interface DeleteTermInputParameters {
  readonly searchTerm: string;
  readonly locale: string;
  readonly platform: string;
  readonly ropId: string;
}

interface MIPlatformDataResponse {
  readonly sales_channels: PlatformData[];
}

export const PATHS = Object.freeze({
  LIST_SEARCH_TERMS: (ropId: string) => `/search-terms/${ropId}`,
  LIST_SEARCH_TERMS_V3: (ropId: string) => `/search-terms/v3/${ropId}`,
  DELETE_SEARCH_TERMS: (ropId: string) => `/search-terms/${ropId}`,
  EXPORT_SEARCH_TERMS: (ropId: string) => `/search-terms/export/${ropId}`,
  EXPORT_SEARCH_TERMS_TEMPLATE: '/search-terms/sample-file',
  LIST_PLATFORMS: '/sales-channels',
  BCS_CHARTS_DATA_V3: (ropId: string) => `/bcs/v3/${ropId}`,
  LIST_PRODUCTS_V3: (ropId: string) => `/bcs/v3/products/${ropId}`,
  LIST_BRANDS_V3: (ropId: string) => `/bcs/v3/brands/${ropId}`,
  EXPORT_BULK_BCS_ZIP_V3: (ropId: string) => `/bcs/v3/export-bulk/${ropId}`,
  EXPORT_BULK_BCS_ZIP_ASYNC: (ropId: string) =>
    `/bcs/export-bulk-async/${ropId}`,
  ADD_NEW_SEARCH_TERMS_STATUS: `/search-terms/status`,
  ADD_NEW_SEARCH_TERMS: `/search-terms`,
  IGNORED_SUGGESTED_SEARCH_TERMS: `/search-terms/ignore`,
  LIST_SUGGESTED_SEARCH_TERMS: (ropId: string) =>
    `/search-terms/suggestions/${ropId}`,
  SUGGESTED_SEARCH_TERMS_COUNT: (ropId: string) =>
    `/search-terms/suggestions-count/${ropId}`,
  LIST_GROUPS_V2: (ropId: string) => `/search-terms-groups/v2/${ropId}`,
  CREATE_GROUP_V2: (ropId: string) => `/search-terms-groups/v2/${ropId}`,
  ADD_OR_REMOVE_SEARCH_TERMS_TO_OR_FROM_GROUP_V2: (
    ropId: string,
    groupId: string
  ) => `/search-terms-groups/v2/${ropId}/${groupId}`,
  UPDATE_GROUP: (ropId: string, groupId: string) =>
    `/search-terms-groups/${ropId}/${groupId}`,
  AUTOCOMPLETE_SEARCH_TERMS: (ropId: string) =>
    `/search-terms/autocomplete/${ropId}`,
  DELETE_GROUP_V2: (ropId: string, groupId: string) =>
    `/search-terms-groups/v2/${ropId}/${groupId}`,
  ADD_NEW_SEARCH_TERMS_V3: `/search-terms/v3`,
  BCS_BACKGROUND_REQUESTS: (ropId: string) => `/bcs/v3/job/${ropId}`,
  BCS_REPOTING_QUERY: (ropId: string) => `/bcs/v3/job/precompute-job/${ropId}`,
  BCS_REPOTING_STATUS_DATA: (ropId: string) =>
    `/bcs/v3/precomputed/status/${ropId}`,
  LIST_SOV_PRODUCTS: (accountId: string, merchantCountryId: string) =>
    `/v1/sov/accounts/${accountId}/mcids/${merchantCountryId}/pcids`,
  LIST_SOV_KEYWORDS: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string
  ) =>
    `/v1/sov/accounts/${accountId}/mcids/${merchantCountryId}/pcids/${productCatalogId}/keywords`,
  SOV_PRODUCTS_TREND: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string
  ) =>
    `/v1/sov/accounts/${accountId}/mcids/${merchantCountryId}/pcids/${productCatalogId}/trend`,

  SOV_KEYWORDS_TREND: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string
  ) =>
    `/v1/sov/accounts/${accountId}/mcids/${merchantCountryId}/pcids/${productCatalogId}/keyword/trend`,

  SOV_PRODUCTS_EXPORT: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string
  ) =>
    `/v1/sov/accounts/${accountId}/mcids/${merchantCountryId}/pcids/${productCatalogId}/export`,

  SOV_KEYWORDS_EXPORT: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string
  ) =>
    `/v1/sov/accounts/${accountId}/mcids/${merchantCountryId}/pcids/${productCatalogId}/keyword/export`,
  SOV_KEYWORD_CAMPAIGNS: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string
  ) =>
    `/v1/sov/accounts/${accountId}/mcids/${merchantCountryId}/pcids/${productCatalogId}/keyword/campaigns`,
  SOV_TOP_PRODUCTS: (accountId: string) =>
    `/v1/sov/accounts/${accountId}/pcids`,
  SOV_PRODUCT_HIERARCY: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string
  ) =>
    `/v1/sov/accounts/${accountId}/mcids/${merchantCountryId}/pcids/${productCatalogId}/hierarchy`,
  SOV_MERCHANTS: (accountId: string) => `/v1/sov/accounts/${accountId}/mcids`,
});

export interface MIApiClient {
  readonly getSearchTermsWithoutPagination: (
    ropId: string,
    shouldRetry?: () => boolean
  ) => Promise<MIPublicAPISearchTermsResponse>;
  readonly getSearchTerms: (
    ropId: string
  ) => (request: PaginatedRequest) => Promise<MISearchTermsPaginatedResult>;
  readonly getSearchTermsAndGroups: (
    ropId: string
  ) => (request: PaginatedRequest) => Promise<MISearchTermsPaginatedResult>;
  readonly getAllSearchTermsForPlatform: (
    ropId: string,
    params: PublicAPISearchTermsRequest
  ) => Promise<PublicAPISearchTermsResponse>;
  readonly getAllSearchTermsAndGroupsForPlatform: (
    ropId: string,
    params: PublicAPISearchTermsRequest
  ) => Promise<PublicAPISearchTermsResponse>;
  readonly deleteSearchTerm: (
    params: Readonly<DeleteTermInputParameters>
  ) => Promise<void>;
  readonly uploadSearchTermsStatus: (
    file: File,
    ropId: string
  ) => Promise<UploadSearchTermsStatusResponse>;
  readonly uploadSearchTerms: (
    ropId: string,
    searchTerms: TermCountryChannel[]
  ) => Promise<void>;
  readonly getFilteredSearchTerms: (
    ropId: string,
    searchTermsTableQueryParams?: SearchTermsTableQueryParams
  ) => Promise<SearchTermsDataResponse>;
  readonly getBCSChartsData: (
    ropId: string,
    searchTermsChartsQueryParams: SearchTermsChartsQueryParams
  ) => Promise<BCSChartsDataResponse>;
  readonly getPlatforms: () => Promise<PlatformDataWithCountries[]>;
  readonly getProducts: (
    params: ReportingRequestParams
  ) => (
    request: PaginatedRequest
  ) => Promise<PaginatedResult<AverageProductRankData>>;
  readonly getBrands: (
    params: ReportingRequestParams
  ) => (
    request: PaginatedRequest
  ) => Promise<PaginatedResult<BrandCoverageOnSearchData>>;
  readonly exportBulkBcsZipAsync: (
    params: BulkExportRequestParamsV2
  ) => Promise<AxiosResponse<void>>;
  readonly requestBlobResponse: (url: string) => Promise<AxiosResponse<Blob>>;
  readonly getNewSearchTermsStatus: (
    payload: AddNewSearchTermsStatusRequestPayload
  ) => Promise<AddNewSearchTermsStatusResponse>;
  readonly addNewSearchTerms: (
    payload: AddNewSearchTermsRequestPayload
  ) => Promise<AddNewSearchTermsResponse>;
  readonly addIgnoredSuggestedSearchTerms: (
    payload: IgnoreSuggestedSearchTermsRequestPayload
  ) => Promise<void>;
  readonly getSuggestedSearchTerms: (
    ropId: string,
    request: PaginatedRequest,
    payload: SuggestedSearchTermsRequestPayload
  ) => Promise<SuggestedSearchTermsRequestResponse>;
  readonly getSuggestedSearchTermsCount: (
    payload: SuggestedSearchTermsCountRequestPayload,
    shouldRetry?: () => boolean
  ) => Promise<SuggestedSearchTermsCountResponse[]>;
  readonly getGroups: (ropId: string) => Promise<GroupsData[]>;
  readonly createGroup: (
    ropId: string,
    payload: CreateGroupRequestPayload
  ) => Promise<void>;
  readonly updateGroup: (
    ropId: string,
    groupId: string,
    payload: UpdateGroupRequestPayload
  ) => Promise<void>;
  readonly deleteGroup: (
    ropId: string,
    groupId: string,
    payload: DeleteGroupRequestPayload
  ) => Promise<void>;
  readonly addOrRemoveSearchTermsToOrFromGroup: (
    ropId: string,
    groupId: string,
    payload: AddOrRemoveSearchTermsToOrFromGroupRequestPayload
  ) => Promise<AddOrRemoveSearchTermsToOrFromGroupResponse>;
  readonly getAutoCompleteSearchTerms: (
    payload: AutoCompleteSearchTermsRequestPayload
  ) => Promise<string[]>;
  readonly addNewSearchTermsV3: (
    payload: AddNewSearchTermsV3RequestPayload
  ) => Promise<AddNewSearchTermsV3Response>;
  readonly getBCSBackgroundRequests: (
    ropId: string,
    backgroundJobId?: string
  ) => Promise<BCSBackgroundRequests[]>;
  readonly runReportingQueryInBackground: (
    ropId: string,
    params: ReportingRequestParams
  ) => Promise<void>;
  readonly getReportStatusAndData: (
    ropId: string,
    reportId: string,
    payload: ReportingRequestParams
  ) => Promise<ReportStatusAndData>;

  readonly getSovProducts: (
    accountId: string,
    merchantCountryId: string,
    payload: SovProductsRequest
  ) => (paginatedRequest: PaginatedRequest) => Promise<SovProductsResponse>;

  readonly getSovKeywords: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    payload: SovKeywordRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<SovKeyword>>;

  readonly getSovProductsTrend: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    payload: SovTrendRequest
  ) => Promise<SovProductsTrendResponse>;

  readonly getSovKeywordsTrend: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    payload: SovTrendRequest
  ) => Promise<SovProductsTrendResponse>;

  readonly exportSovProductsCsvData: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    payload: SovExportRequest
  ) => Promise<AxiosResponse<Blob>>;

  readonly exportSovkeywordsCsvData: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    payload: SovExportRequest
  ) => Promise<AxiosResponse<Blob>>;

  readonly getSovKeywordCampaigns: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    payload: SovKeywordCampaignsRequest
  ) => (
    paginatedRequest: PaginatedRequest
  ) => Promise<PaginatedResult<KeywordCampaign>>;

  readonly getSovTopProducts: (
    accountId: string,
    payload: SovTopProductsRequest
  ) => Promise<SovTopProductResponse>;

  readonly getSovProductHierarchy: (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    salesChannelId: string
  ) => Promise<SovProductHierarchy>;
  readonly getSovMerchants: (
    accountId: string,
    payload: SovMerchantsRequest
  ) => Promise<SovMerchantsResponse>;
}

export const createMIApiClient = (
  token: IdToken | null,
  addNotification?: (info: ToastProps) => void,
  intl?: IntlShape
): MIApiClient => {
  const config = {
    headers: {
      ...getDefaultHeaders(),
      Authorization: `Bearer ${token?.__raw}`,
    },
    baseURL: MI_BASE_URL,
  };

  const axiosInstance = axios.create(config);

  const getSearchTermsWithoutPagination = async (
    ropId: string,
    shouldRetry?: () => boolean
  ): Promise<MIPublicAPISearchTermsResponse> => {
    let axiosInstance = axios.create();
    axiosRetry(axiosInstance, {
      retries: 3,
      retryDelay: (retryCount: number) => retryCount * DEFAULT_RETRY_INTERVAL,
      retryCondition: shouldRetry,
    });

    const { data } = await axiosInstance.get<MIPublicAPISearchTermsResponse>(
      PATHS.LIST_SEARCH_TERMS(ropId),
      {
        ...config,
      }
    );

    return data;
  };

  const getGroups = async (ropId: string): Promise<GroupsData[]> => {
    const { data } = await axiosInstance.get<GroupsData[]>(
      PATHS.LIST_GROUPS_V2(ropId),
      {
        ...config,
        params: { sort: 'groupName,ASC' },
      }
    );
    return data.map((item: GroupsData) => ({
      ...item,
      noOfTerms: Number(item.noOfTerms),
    }));
  };

  const getSearchTerms =
    (ropId: string) =>
    async (
      request: PaginatedRequest
    ): Promise<MISearchTermsPaginatedResult> => {
      const transformedRequest = transformSortMIRequest(request);
      const allParams = getPaginationParamsFromRequest(transformedRequest);
      const params = filterEmptyValues(allParams);

      const {
        data: {
          filteredElements,
          elements,
          last_updated,
          n_search_terms,
          n_search_terms_limit,
          n_free_search_terms,
          is_search_term_number_unlimited,
        },
      } = await axiosInstance
        .get<MIPublicAPISearchTermsResponse>(PATHS.LIST_SEARCH_TERMS(ropId), {
          ...config,
          params,
        })
        .then((res) => ({
          data: {
            filteredElements: res.data.n_filtered_search_terms,
            elements: getTransformedSearchTermsData(res.data.search_terms),
            last_updated: res.data.last_updated,
            n_search_terms: res.data.n_search_terms,
            n_search_terms_limit: res.data.max_total_tracked_search_term,
            n_free_search_terms: res.data.max_free_tracked_search_term,
            is_search_term_number_unlimited:
              res.data.is_search_term_number_unlimited,
          },
        }));

      return {
        totalItems: filteredElements,
        items: elements,
        lastUpdated: last_updated,
        totalSearchTerms: n_search_terms,
        searchTermsLimit: n_search_terms_limit,
        freeSearchTermsLimit: n_free_search_terms,
        isSearchTermNumberUnlimited: is_search_term_number_unlimited,
        totalGroups: 0,
        filteredSearchTermsAndGroups: 0,
      };
    };

  const getSearchTermsAndGroups =
    (ropId: string) =>
    async (
      request: PaginatedRequest
    ): Promise<MISearchTermsPaginatedResult> => {
      const transformedRequest = transformSortMIRequest(request);
      const allParams = getPaginationParamsFromRequest(transformedRequest);
      const params = filterEmptyValues(allParams);
      const {
        data: {
          totalItems,
          items,
          lastUpdated,
          totalSearchTerms,
          searchTermsLimit,
          freeSearchTermsLimit,
          isSearchTermNumberUnlimited,
          totalGroups,
          filteredSearchTermsAndGroups,
        },
      } = await axiosInstance
        .get<MIPublicAPISearchTermsAndGroupsResponse>(
          PATHS.LIST_SEARCH_TERMS_V3(ropId),
          {
            ...config,
            params: {
              ...params,
              show_empty_groups: true,
              show_search_terms_first: false,
              show_only_groups: true,
            },
          }
        )
        .then((res) => ({
          data: {
            totalItems: res.data.n_total_rows,
            items: getTransformedGroupsData(res.data.search_terms_and_groups),
            lastUpdated: res.data.last_updated,
            totalSearchTerms: res.data.n_search_terms,
            searchTermsLimit: res.data.max_total_tracked_search_term,
            freeSearchTermsLimit: res.data.max_free_tracked_search_term,
            isSearchTermNumberUnlimited:
              res.data.is_search_term_number_unlimited,
            totalGroups: res.data.n_groups,
            filteredSearchTermsAndGroups:
              res.data.n_filtered_search_terms_and_groups,
          },
        }));

      return {
        totalItems,
        items,
        lastUpdated,
        totalSearchTerms,
        searchTermsLimit,
        freeSearchTermsLimit,
        isSearchTermNumberUnlimited,
        totalGroups,
        filteredSearchTermsAndGroups,
      };
    };

  const getAllSearchTermsForPlatform = async (
    ropId: string,
    params: PublicAPISearchTermsRequest
  ): Promise<PublicAPISearchTermsResponse> => {
    try {
      const {
        data: { elements, totalSearchTerms },
      } = await axiosInstance
        .get<MIPublicAPISearchTermsResponse>(PATHS.LIST_SEARCH_TERMS(ropId), {
          ...config,
          params,
        })
        .then((res) => ({
          data: {
            elements: getTransformedSearchTermsData(res.data.search_terms),
            totalSearchTerms: res.data.n_search_terms,
          },
        }));
      return { searchTerms: elements, totalSearchTerms };
    } catch (error) {
      showErrorToast(
        error as AxiosError,
        addNotification,
        intl,
        I18nKey.MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_SEARCH_TERMS_ERROR
      );
      return { searchTerms: [], totalSearchTerms: 0 };
    }
  };

  const getAllSearchTermsAndGroupsForPlatform = async (
    ropId: string,
    params: PublicAPISearchTermsRequest
  ): Promise<PublicAPISearchTermsResponse> => {
    try {
      const {
        data: { elements, totalSearchTerms },
      } = await axiosInstance
        .get<MIPublicAPISearchTermsAndGroupsResponse>(
          PATHS.LIST_SEARCH_TERMS_V3(ropId),
          {
            ...config,
            params: {
              ...params,
              show_empty_groups: false,
              show_search_terms_first: true,
              show_only_groups: false,
            },
          }
        )
        .then((res) => ({
          data: {
            elements: getTransformedSearchTermsAndGroupsData(
              res.data.search_terms_and_groups
            ),
            totalSearchTerms: res.data.n_search_terms,
          },
        }));
      return { searchTerms: elements, totalSearchTerms };
    } catch (error) {
      showErrorToast(
        error as AxiosError,
        addNotification,
        intl,
        I18nKey.MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_SEARCH_TERMS_ERROR
      );
      return { searchTerms: [], totalSearchTerms: 0 };
    }
  };

  const deleteSearchTerm = async ({
    ropId,
    searchTerm,
    locale,
    platform,
  }: Readonly<DeleteTermInputParameters>): Promise<void> => {
    try {
      await axiosInstance.delete(`${PATHS.DELETE_SEARCH_TERMS(ropId)}`, {
        ...config,
        params: {
          search_term: searchTerm,
          country: locale,
          sales_channel: platform,
        },
      });
    } catch (error) {
      showErrorToast(
        error as AxiosError,
        addNotification,
        intl,
        I18nKey.MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_REMOVE_SEARCH_TERMS_ERROR
      );
      throw error;
    }
  };

  const uploadSearchTermsStatus = async (
    file: File,
    ropId: string
  ): Promise<UploadSearchTermsStatusResponse> => {
    const formData = new FormData();
    formData.append('search_terms_file', file);
    formData.append('account_id', ropId);

    const { data } = await axiosInstance.post<UploadSearchTermsStatusResponse>(
      PATHS.ADD_NEW_SEARCH_TERMS_STATUS,
      formData,
      {
        ...config,
        headers: { ...config.headers, 'Content-Type': 'multipart/form-data' },
      }
    );
    return data;
  };

  const uploadSearchTerms = async (
    ropId: string,
    searchTerms: TermCountryChannel[]
  ): Promise<void> => {
    await axiosInstance.post(
      PATHS.ADD_NEW_SEARCH_TERMS,
      {
        account_id: ropId,
        search_terms: searchTerms,
        source: SearchTermSource.Bulk,
      },
      {
        ...config,
      }
    );
  };

  const getFilteredSearchTerms = async (
    ropId: string,
    searchTermsTableQueryParams?: SearchTermsTableQueryParams
  ): Promise<SearchTermsDataResponse> => {
    const requiredQueryParams = Object.entries(
      searchTermsTableQueryParams || {}
    ).reduce(getReducedFilterParams, {});

    const queryString: string = new URLSearchParams(
      requiredQueryParams
    ).toString();

    const response = await axiosInstance.get<SearchTermsDataResponse>(
      PATHS.LIST_SEARCH_TERMS(ropId).concat(`?${queryString}`),
      {
        ...config,
      }
    );
    return response.data;
  };

  const requestBlobResponse = async (url: string) => {
    return axiosInstance.get(url, {
      ...config,
      responseType: 'blob',
    });
  };

  const exportBulkBcsZipAsync = async ({
    ropId,
    ...payload
  }: BulkExportRequestParamsV2) => {
    try {
      return await axiosInstance.post(
        PATHS.EXPORT_BULK_BCS_ZIP_V3(ropId),
        payload,
        config
      );
    } catch (error) {
      showErrorToast(
        error as AxiosError,
        addNotification,
        intl,
        I18nKey.MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_EXPORT_BCS_ERROR
      );
      throw error;
    }
  };

  const getBCSChartsData = async (
    ropId: string,
    searchTermsChartsQueryParams: SearchTermsChartsQueryParams
  ): Promise<BCSChartsDataResponse> => {
    const requiredQueryParams = Object.entries(
      searchTermsChartsQueryParams || {}
    ).reduce(getReducedFilterParams, {});

    //#TODO: Refactor - MI-423
    const transformedMIRequiredParams = {
      search_terms: requiredQueryParams.search_term,
      sales_channel: requiredQueryParams.platform,
      country: requiredQueryParams.locale,
      start_date: requiredQueryParams.start_date,
      end_date: requiredQueryParams.end_date,
      includeUnIndentifiedBrands:
        requiredQueryParams.includeUnIndentifiedBrands,
    };

    const endpoint = buildUrlWithQueryParams(
      PATHS.BCS_CHARTS_DATA_V3(ropId),
      transformedMIRequiredParams
    );
    try {
      const { data } = await axiosInstance.get<BCSChartsDataResponse>(
        endpoint,
        {
          ...config,
        }
      );
      return data;
    } catch (error) {
      showErrorToast(error as AxiosError, addNotification, intl);
      throw error;
    }
  };

  const getPlatforms = async (): Promise<PlatformDataWithCountries[]> => {
    try {
      const {
        data: { sales_channels },
      } = await axiosInstance.get<MIPlatformDataResponse>(
        PATHS.LIST_PLATFORMS,
        {
          ...config,
        }
      );
      return mapCountryCodeToCountryName(sales_channels);
    } catch (error) {
      showErrorToast(
        error as AxiosError,
        addNotification,
        intl,
        I18nKey.MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_PLATFORM_ERROR
      );
      return [];
    }
  };

  const getProducts =
    ({
      end_date,
      locale: country,
      platform: sales_channel,
      rop_id,
      search_term,
      start_date,
    }: ReportingRequestParams) =>
    async (
      request: PaginatedRequest
    ): Promise<PaginatedResult<AverageProductRankData>> => {
      try {
        const endpoint = buildUrlWithQueryParams(
          PATHS.LIST_PRODUCTS_V3(rop_id),
          {
            end_date,
            country,
            sales_channel,
            search_terms: search_term,
            start_date,
          }
        );
        const allParams = getPaginationParamsFromRequest(request);
        const params = filterEmptyValues(allParams);
        const response = await axiosInstance.get(endpoint, {
          ...config,
          params,
        });
        return {
          items: response.data.products_bcs,
          totalItems: response.data.n_of_products as number,
        };
      } catch (error) {
        showErrorToast(
          error as AxiosError,
          addNotification,
          intl,
          I18nKey.MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_PRODUCT_ERROR
        );
        throw error;
      }
    };

  const getBrands =
    ({
      end_date,
      locale: country,
      platform: sales_channel,
      rop_id,
      search_term,
      start_date,
      includeUnIndentifiedBrands,
    }: ReportingRequestParams) =>
    async (
      request: PaginatedRequest
    ): Promise<PaginatedResult<BrandCoverageOnSearchData>> => {
      try {
        const endpoint = buildUrlWithQueryParams(PATHS.LIST_BRANDS_V3(rop_id), {
          end_date,
          country,
          sales_channel,
          search_terms: search_term,
          start_date,
          includeUnIndentifiedBrands,
        });
        const allParams = getPaginationParamsFromRequest(request);
        const params = filterEmptyValues(allParams);
        const response = await axiosInstance.get(endpoint, {
          ...config,
          params,
        });
        return {
          items: sortDataForBrandCoverageOnSearchTable(
            response.data.brands_bcs
          ),
          totalItems: response.data.n_of_brands as number,
        };
      } catch (error) {
        showErrorToast(
          error as AxiosError,
          addNotification,
          intl,
          I18nKey.MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_BCS_ERROR
        );
        throw error;
      }
    };

  const getNewSearchTermsStatus = async (
    payload: AddNewSearchTermsStatusRequestPayload
  ): Promise<AddNewSearchTermsStatusResponse> => {
    const { data } = await axiosInstance.post<AddNewSearchTermsStatusResponse>(
      PATHS.ADD_NEW_SEARCH_TERMS_STATUS,
      payload,
      config
    );

    return data;
  };

  const addNewSearchTerms = async (
    payload: AddNewSearchTermsRequestPayload
  ): Promise<AddNewSearchTermsResponse> => {
    const { data } = await axiosInstance.post<AddNewSearchTermsResponse>(
      PATHS.ADD_NEW_SEARCH_TERMS,
      payload,
      config
    );
    return data;
  };

  const addIgnoredSuggestedSearchTerms = async (
    payload: IgnoreSuggestedSearchTermsRequestPayload
  ): Promise<void> => {
    try {
      const { data } = await axiosInstance.post<void>(
        PATHS.IGNORED_SUGGESTED_SEARCH_TERMS,
        payload,
        config
      );
      return data;
    } catch (error) {
      showErrorToast(
        error as AxiosError,
        addNotification,
        intl,
        I18nKey.MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_IGNORE_SUGGESTION_ERROR
      );
      throw error;
    }
  };

  const getSuggestedSearchTerms = async (
    ropId: string,
    request: PaginatedRequest,
    payload: SuggestedSearchTermsRequestPayload
  ): Promise<SuggestedSearchTermsRequestResponse> => {
    try {
      const allParams = getPaginationParamsFromRequest(request);
      const params = filterEmptyValues(allParams);

      const { data } =
        await axiosInstance.post<SuggestedSearchTermsRequestResponse>(
          PATHS.LIST_SUGGESTED_SEARCH_TERMS(ropId),
          payload,
          { ...config, params }
        );
      return data;
    } catch (error) {
      showErrorToast(
        error as AxiosError,
        addNotification,
        intl,
        I18nKey.MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_SUGGESTIONS_ERROR
      );
      throw error;
    }
  };

  const getSuggestedSearchTermsCount = async (
    {
      ropId,
      mcids,
      endDate,
      startDate,
    }: SuggestedSearchTermsCountRequestPayload,
    shouldRetry?: () => boolean
  ): Promise<SuggestedSearchTermsCountResponse[]> => {
    try {
      let axiosInstance = axios.create();
      axiosRetry(axiosInstance, {
        retries: 3,
        retryDelay: (retryCount: number) => retryCount * DEFAULT_RETRY_INTERVAL,
        retryCondition: shouldRetry,
      });

      const { data } = await axiosInstance.post<
        SuggestedSearchTermsCountResponse[]
      >(
        PATHS.SUGGESTED_SEARCH_TERMS_COUNT(ropId),
        {
          mcids,
          startDate,
          endDate,
        },
        config
      );
      return data;
    } catch (error) {
      showErrorToast(error as AxiosError, addNotification, intl);
      throw error;
    }
  };

  const createGroup = async (
    ropId: string,
    payload: CreateGroupRequestPayload
  ): Promise<void> => {
    await axiosInstance.post<void>(
      PATHS.CREATE_GROUP_V2(ropId),
      payload,
      config
    );
  };

  const updateGroup = async (
    ropId: string,
    groupId: string,
    payload: UpdateGroupRequestPayload
  ): Promise<void> => {
    await axiosInstance.patch<void>(
      PATHS.UPDATE_GROUP(ropId, groupId),
      payload,
      config
    );
  };

  const deleteGroup = async (
    ropId: string,
    groupId: string,
    payload: DeleteGroupRequestPayload
  ): Promise<void> => {
    await axiosInstance.delete(PATHS.DELETE_GROUP_V2(ropId, groupId), {
      ...config,
      data: payload,
    });
  };

  const addOrRemoveSearchTermsToOrFromGroup = async (
    ropId: string,
    groupId: string,
    payload: AddOrRemoveSearchTermsToOrFromGroupRequestPayload
  ): Promise<AddOrRemoveSearchTermsToOrFromGroupResponse> => {
    const { data } =
      await axiosInstance.put<AddOrRemoveSearchTermsToOrFromGroupResponse>(
        PATHS.ADD_OR_REMOVE_SEARCH_TERMS_TO_OR_FROM_GROUP_V2(ropId, groupId),
        payload,
        config
      );
    return data;
  };

  const getAutoCompleteSearchTerms = async ({
    ropId,
    search_term,
    limit,
  }: AutoCompleteSearchTermsRequestPayload): Promise<string[]> => {
    try {
      const params = { search_term, limit };
      const { data } = await axiosInstance.get<string[]>(
        PATHS.AUTOCOMPLETE_SEARCH_TERMS(ropId),
        {
          params,
          ...config,
        }
      );
      return data || [];
    } catch {
      return [];
    }
  };

  const addNewSearchTermsV3 = async (
    payload: AddNewSearchTermsV3RequestPayload
  ): Promise<AddNewSearchTermsV3Response> => {
    const { data } = await axiosInstance.post<AddNewSearchTermsV3Response>(
      PATHS.ADD_NEW_SEARCH_TERMS_V3,
      payload,
      config
    );
    return data;
  };

  const getBCSBackgroundRequests = async (
    ropId: string,
    backgroundJobId?: string
  ): Promise<BCSBackgroundRequests[]> => {
    const params = { background_job_id: backgroundJobId };
    const { data } = await axios.get<BCSBackgroundRequests[]>(
      PATHS.BCS_BACKGROUND_REQUESTS(ropId),
      {
        params,
        ...config,
      }
    );
    return data;
  };

  const runReportingQueryInBackground = async (
    ropId: string,
    params: ReportingRequestParams
  ): Promise<void> => {
    const transformedMIRequiredParams = {
      search_terms: params.search_term,
      group_name: params.group_name || '',
      sales_channel: params.platform,
      country: params.locale,
      start_date: params.start_date,
      end_date: params.end_date,
    };
    const endpoint = buildUrlWithQueryParams(
      PATHS.BCS_REPOTING_QUERY(ropId),
      transformedMIRequiredParams
    );
    await axiosInstance.post<void>(endpoint, {}, config);
  };

  const getReportStatusAndData = async (
    ropId: string,
    reportId: string,
    payload: ReportingRequestParams
  ): Promise<ReportStatusAndData> => {
    const transformedMIRequiredParams = {
      search_terms: payload.search_term,
      group_name: payload.group_name || '',
      sales_channel: payload.platform,
      country: payload.locale,
      start_date: payload.start_date,
      end_date: payload.end_date,
    };
    const endpoint = buildUrlWithQueryParams(
      PATHS.BCS_REPOTING_STATUS_DATA(ropId),
      { report_id: reportId }
    );
    const { data } = await axiosInstance.post<ReportStatusAndData>(
      endpoint,
      transformedMIRequiredParams,
      config
    );
    return data;
  };

  const getSovProducts =
    (
      accountId: string,
      merchantCountryId: string,
      payload: SovProductsRequest
    ) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<SovProductsResponse> => {
      const paginationData = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(paginationData);
      const { data } = await axiosInstance.post(
        PATHS.LIST_SOV_PRODUCTS(accountId, merchantCountryId),
        payload,
        {
          ...config,
          params,
        }
      );
      return data;
    };

  const getSovKeywords =
    (
      accountId: string,
      merchantCountryId: string,
      productCatalogId: string,
      payload: SovKeywordRequest
    ) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<SovKeyword>> => {
      const paginationData = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(paginationData);
      const { data } = await axiosInstance.post<SovKeywordsResponse>(
        PATHS.LIST_SOV_KEYWORDS(accountId, merchantCountryId, productCatalogId),
        payload,
        {
          ...config,
          params,
        }
      );
      return {
        items: data?.keywords ?? [],
        totalItems: data?.total_keywords ?? 0,
      };
    };

  const getSovProductsTrend = async (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    payload: SovTrendRequest
  ): Promise<SovProductsTrendResponse> => {
    const { data } = await axiosInstance.post<SovProductsTrendResponse>(
      PATHS.SOV_PRODUCTS_TREND(accountId, merchantCountryId, productCatalogId),
      payload,
      {
        ...config,
      }
    );
    return data;
  };

  const getSovKeywordsTrend = async (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    payload: SovTrendRequest
  ): Promise<SovProductsTrendResponse> => {
    const { data } = await axiosInstance.post<SovProductsTrendResponse>(
      PATHS.SOV_KEYWORDS_TREND(accountId, merchantCountryId, productCatalogId),
      payload,
      {
        ...config,
      }
    );
    return data;
  };

  const exportSovProductsCsvData = async (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    payload: SovExportRequest
  ): Promise<AxiosResponse<Blob>> => {
    const res = await axiosInstance.post(
      PATHS.SOV_PRODUCTS_EXPORT(accountId, merchantCountryId, productCatalogId),
      payload,
      {
        ...config,
      }
    );
    return res;
  };

  const exportSovkeywordsCsvData = async (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    payload: SovExportRequest
  ): Promise<AxiosResponse<Blob>> => {
    const res = await axiosInstance.post(
      PATHS.SOV_KEYWORDS_EXPORT(accountId, merchantCountryId, productCatalogId),
      payload,
      {
        ...config,
      }
    );
    return res;
  };

  const getSovKeywordCampaigns =
    (
      accountId: string,
      merchantCountryId: string,
      productCatalogId: string,
      payload: SovKeywordCampaignsRequest
    ) =>
    async (
      paginatedRequest: PaginatedRequest
    ): Promise<PaginatedResult<KeywordCampaign>> => {
      const paginationData = getPaginationParamsFromRequest(paginatedRequest);
      const params = filterEmptyValues(paginationData);
      const { data } = await axiosInstance.post(
        PATHS.SOV_KEYWORD_CAMPAIGNS(
          accountId,
          merchantCountryId,
          productCatalogId
        ),
        payload,
        {
          ...config,
          params,
        }
      );
      return {
        items: getTransformedCampaignData(data.campaigns ?? []),
        totalItems: data.total_campaigns ?? 0,
      };
    };

  const getSovTopProducts = async (
    accountId: string,
    payload: SovTopProductsRequest
  ): Promise<SovTopProductResponse> => {
    const { data } = await axiosInstance.post<SovTopProductResponse>(
      PATHS.SOV_TOP_PRODUCTS(accountId),
      payload,
      {
        ...config,
      }
    );
    return data;
  };

  const getSovProductHierarchy = async (
    accountId: string,
    merchantCountryId: string,
    productCatalogId: string,
    salesChannelId: string
  ): Promise<SovProductHierarchy> => {
    const { data } = await axiosInstance.get<SovProductHierarchy>(
      PATHS.SOV_PRODUCT_HIERARCY(
        accountId,
        merchantCountryId,
        productCatalogId
      ),
      { ...config, params: { sales_channel_id: salesChannelId } }
    );
    return data;
  };

  const getSovMerchants = async (
    accountId: string,
    payload: SovMerchantsRequest
  ): Promise<SovMerchantsResponse> => {
    const { data } = await axiosInstance.post<SovMerchantsResponse>(
      PATHS.SOV_MERCHANTS(accountId),
      payload,
      {
        ...config,
      }
    );
    return data;
  };

  return {
    getSearchTerms,
    getAllSearchTermsForPlatform,
    deleteSearchTerm,
    uploadSearchTermsStatus,
    uploadSearchTerms,
    getFilteredSearchTerms,
    getBCSChartsData,
    getPlatforms,
    getProducts,
    getBrands,
    exportBulkBcsZipAsync,
    getNewSearchTermsStatus,
    addNewSearchTerms,
    requestBlobResponse,
    getSearchTermsWithoutPagination,
    addIgnoredSuggestedSearchTerms,
    getSuggestedSearchTerms,
    getSuggestedSearchTermsCount,
    getAllSearchTermsAndGroupsForPlatform,
    getSearchTermsAndGroups,
    getGroups,
    createGroup,
    updateGroup,
    deleteGroup,
    addOrRemoveSearchTermsToOrFromGroup,
    getAutoCompleteSearchTerms,
    addNewSearchTermsV3,
    getBCSBackgroundRequests,
    runReportingQueryInBackground,
    getReportStatusAndData,
    getSovProducts,
    getSovKeywords,
    getSovProductsTrend,
    getSovKeywordsTrend,
    exportSovProductsCsvData,
    exportSovkeywordsCsvData,
    getSovKeywordCampaigns,
    getSovTopProducts,
    getSovProductHierarchy,
    getSovMerchants,
  };
};
