import { Fw2LocalStorage } from './fw2LocalStorage';
import isNumber from 'lodash/isNumber';
import { Duration } from 'luxon';
import forIn from 'lodash/forIn';
import startsWith from 'lodash/startsWith';
import { SKU_STORAGE_KEY } from '../../modules/products/containers/utils/localStorage';
import { ADSMANAGER_STORAGE_KEY } from '../../modules/advertisingOptimization/containers/adsManager/utils/storageUtils';
import { Fw2SessionStorage } from './fw2SessionStorage';

export const removeAllUserSelectionFromLocalStorageSku = (): void => {
  forIn(window.localStorage, (value: string, objKey: string) => {
    if (startsWith(objKey, `${SKU_STORAGE_KEY}`)) {
      Fw2LocalStorage.removeItem(objKey);
    }
  });
};

export const removeAllUserSelectionFromLocalStorageAdsManager = (): void => {
  forIn(window.localStorage, (value: string, objKey: string) => {
    if (startsWith(objKey, `${ADSMANAGER_STORAGE_KEY}`)) {
      Fw2LocalStorage.removeItem(objKey);
    }
  });
};

const TWELEVE_HOURS_IN_MINUTES = 720;
const FW2_STORAGE_TIMEOUT_KEY = 'fw2_storage_timeout';

export const getSessionStorageKeyForTableCurrentPage = (
  userId: string,
  currentAccountId: string
) => `${userId}_${currentAccountId}_current_page`;

export const getCurrentPageFromSessionStorage = (
  userId: string,
  accountId: string
): string => {
  const adLevelStorageKey = getSessionStorageKeyForTableCurrentPage(
    userId,
    accountId
  );
  const adLevelFromSessionStorage =
    Fw2SessionStorage.getItem(adLevelStorageKey);
  try {
    return JSON.parse(adLevelFromSessionStorage as string);
  } catch (e) {
    return '';
  }
};

export const storeCurrentPageToSessionStorage = (
  userId: string,
  accountId: string,
  page: number
): void => {
  const adLevelStorageKey = getSessionStorageKeyForTableCurrentPage(
    userId,
    accountId
  );
  Fw2SessionStorage.setItem(adLevelStorageKey, JSON.stringify(page));
};

const DISMISSED_BANNERS = 'dismissedBanners';
const DISMISS_DAYS = Duration.fromObject({ days: 7 }).toMillis();

export type DismissedBanner = { name: string; date: number };

export const getDismissedBannersFromStorage = () => {
  const savedBanners = Fw2LocalStorage.getItem(DISMISSED_BANNERS);

  try {
    const banners = savedBanners ? JSON.parse(savedBanners) : [];

    const currentTimestamp = new Date().getTime();

    const dismissedBanners = banners.filter(
      (banner: DismissedBanner) =>
        isNumber(banner.date) && currentTimestamp - banner.date < DISMISS_DAYS
    );

    Fw2LocalStorage.setItem(
      DISMISSED_BANNERS,
      JSON.stringify(dismissedBanners)
    );

    return dismissedBanners.map((banner: DismissedBanner) => banner.name);
  } catch {
    return [];
  }
};

export const addDismissedBannerToStorage = (bannerName: string) => {
  const savedBanners = Fw2LocalStorage.getItem(DISMISSED_BANNERS);

  try {
    const banners = savedBanners ? JSON.parse(savedBanners) : [];

    const updatedBanners = [
      ...banners.filter(
        (banner: DismissedBanner) => banner.name !== bannerName
      ),
      { name: bannerName, date: new Date().getTime() },
    ];

    Fw2LocalStorage.setItem(DISMISSED_BANNERS, JSON.stringify(updatedBanners));
  } catch {}
};

export const onIdleTimeout = () => {
  removeAllUserSelectionFromLocalStorageAdsManager();
  removeAllUserSelectionFromLocalStorageSku();
};

export const isIdleTimeExpired = () => {
  const currentTime = new Date();
  const timeoutInLocalStorage = localStorage.getItem(FW2_STORAGE_TIMEOUT_KEY);
  const expiryDate = new Date(timeoutInLocalStorage!);

  return timeoutInLocalStorage && expiryDate < currentTime;
};

export const setIdleTimeout = () => {
  // set the idle timeout in local storage for 12 hours
  isIdleTimeExpired() && onIdleTimeout();
  const expiryDate = new Date();
  expiryDate.setMinutes(expiryDate.getMinutes() + TWELEVE_HOURS_IN_MINUTES);
  localStorage.setItem(FW2_STORAGE_TIMEOUT_KEY, expiryDate.toString());
};
