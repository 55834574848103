import React, { useEffect } from 'react';
export const OauthPopup: React.FC = () => {
  // Gets the query params from the URL, posts a MessageEvent with the params,
  // and closes the window.
  useEffect(() => {
    const params = window.location.search;
    if (window.opener) {
      window.opener.postMessage(params, window.opener.origin);
      window.close();
    }
  }, []);
  return <></>;
};
OauthPopup.displayName = 'OauthPopup';
