import {
  AdLevel,
  AdLevelConfig,
  AdType,
  FlywheelSalesChannel,
  MerchantCountryAdTypeConfig,
  MerchantCountryCode,
} from '../../../../lib/types/AOSharedTypes';
import { DataInspectorColumnManagerConfigProps } from './types';

export enum DataInspectorColumnIdentifier {
  CampaignType = 'CampaignType',
  CampaignTargetingType = 'CampaignTargetingType',
  CampaignStatus = 'CampaignStatus',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  BudgetType = 'BudgetType',
  DailyBudget = 'DailyBudget',
  LifetimeBudget = 'LifetimeBudget',
  BudgetRollover = 'BudgetRollover',
  SearchCarouselInclusion = 'SearchCarouselInclusion',
  ItemBuyBoxPlacement = 'ItemBuyBoxPlacement',
  ItemCarouselPlacement = 'ItemCarouselPlacement',
  BuyBoxBidMultiplier = 'BuyBoxBidMultiplier',
  SearchIngridBidMultiplier = 'SearchIngridBidMultiplier',
  HomePageBidMultiplier = 'HomePageBidMultiplier',
  StockUpBidMultiplier = 'StockUpBidMultiplier',
  DesktopBidMultiplier = 'DesktopBidMultiplier',
  MobileBidMultiplier = 'MobileBidMultiplier',
  AppBidMultiplier = 'AppBidMultiplier',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  AdSpend = 'AdSpend',
  AdSales = 'AdSales',
  AdUnitsSold = 'AdUnitsSold',
  ConversionRate = 'ConversionRate',
  ACOS = 'ACOS',
  ROAS = 'ROAS',
  ClickThroughRate = 'ClickThroughRate',
  CostPerClick = 'CostPerClick',
  DirectAdSales = 'DirectAdSales',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  RelatedClickRevenue = 'RelatedClickRevenue',
  TotalConversions = 'TotalConversions',
  AdGroupName = 'AdGroupName',
  AdGroupStatus = 'AdGroupStatus',
  BidAutomationStatus = 'BidAutomationStatus',
  MACSTarget = 'MACSTarget',
  MinBid = 'MinBid',
  MaxBid = 'MaxBid',
  CampaignName = 'CampaignName',
  ProductName = 'ProductName',
  ProductAdStatus = 'ProductAdStatus',
  Bid = 'Bid',
  TargetText = 'TargetText',
  TargetLabels = 'TargetLabels',
  AddedBy = 'AddedBy',
  DateAdded = 'DateAdded',
  MatchType = 'MatchType',
  TargetStatus = 'TargetStatus',
  AdItemsCampaignStatus = 'AdItemsCampaignStatus',
  Portfolio = 'Portfolio',
  Group = 'Group',
  AmazonBiddingStrategy = 'AmazonBiddingStrategy',
  ProductPageBidMultiplier = 'ProductPageBidMultiplier',
  TopOfSearchBidMultiplier = 'TopOfSearchBidMultiplier',
  AdConversions = 'AdConversions',
  AdgroupDefaultBid = 'AdgroupDefaultBid',
  TargetingExpression = 'TargetingExpression',
  CampaignTactic = 'CampaignTactic',
  ExpressionType = 'ExpressionType',
  NewToBrandSales = 'NewToBrandSales',
  NewToBrandAdConversions = 'NewToBrandAdConversions',
  NewToBrandAdUnitsSold = 'NewToBrandAdUnitsSold',
  CampaignAdFormat = 'CampaignAdFormat',
  CampaignServingStatus = 'CampaignServingStatus',
  AmazonBidOptimization = 'AmazonBidOptimization',
  BelowTopOfSearchBidMultiplier = 'BelowTopOfSearchBidMultiplier',
  NewToBrandOrders = 'NewToBrandOrders',
  PercentageOfOrdersNewToBrand = 'PercentageOfOrdersNewToBrand',
  NewToBrandOrderRate = 'NewToBrandOrderRate',
  PercentageOfSalesNewToBrand = 'PercentageOfSalesNewToBrand',
  PercentageOfAdUnitsSoldNewToBrand = 'PercentageOfAdUnitsSoldNewToBrand',
  TargetsDailyBudget = 'TargetsDailyBudget',
  CampaignDailyBudget = 'CampaignDailyBudget',
  MerchantName = 'MerchantName',
  CampaignCostType = 'CampaignCostType',
  AdGroupReviewStatus = 'AdGroupReviewStatus',
  ProductReviewStatus = 'ProductReviewStatus',
  ProfileReviewStatus = 'ProfileReviewStatus',
  ProfileStatus = 'ProfileStatus',
  KeywordReviewStatus = 'KeywordReviewStatus',
  Headline = 'Headline',
  ClickURL = 'ClickURL',
  Logo = 'Logo',
  AiRecommendationKeywordSetting = 'AiRecommendationKeywordSetting',
  Tags = 'Tags',
  KeywordType = 'KeywordType',
  CreativeName = 'CreativeName',
  CreativeType = 'CreativeType',
  CreativeServingStatus = 'CreativeServingStatus',
  AdStatus = 'AdStatus',
  CreatedAt = 'CreatedAt',
  AdvertisedSkuUnits = 'AdvertisedSkuUnits',
  OtherSkuUnits = 'OtherSkuUnits',
  AdvertisingGoal = 'AdvertisingGoal',
  SmartStructure = 'SmartStructure',
  TotalAttributedSales = 'TotalAttributedSales',
  Orders = 'Orders',
  VideoImpressions = 'VideoImpressions',
  ViewableImpressions = 'ViewableImpressions',
  ViewThroughRate = 'ViewThroughRate',
  ClickThroughRateForViews = 'ClickThroughRateForViews',
  ViewThroughAttributedSales = 'ViewThroughAttributedSales',
  ViewThroughOrders = 'ViewThroughOrders',
  ViewThroughUnitsSold = 'ViewThroughUnitsSold',
  TotalCompleteViewAttributedSales = 'TotalCompleteViewAttributedSales',
  AdvertisedCompleteViewAttributedSales = 'AdvertisedCompleteViewAttributedSales',
  OtherCompleteViewAttributedSales = 'OtherCompleteViewAttributedSales',
  CompleteViewOrders = 'CompleteViewOrders',
  CompleteViewUnitsSold = 'CompleteViewUnitsSold',
  VideoFirstQuartileViews = 'VideoFirstQuartileViews',
  VideoMidpointViews = 'VideoMidpointViews',
  VideoThirdQuartileViews = 'VideoThirdQuartileViews',
  VideoCompleteViews = 'VideoCompleteViews',
  VideoUnmutes = 'VideoUnmutes',
  FiveSecondViews = 'FiveSecondViews',
  FiveSecondViewRate = 'FiveSecondViewRate',
  Name = 'Name',
}

export const DATA_INSPECTOR_COLUMN_NAME: Record<
  DataInspectorColumnIdentifier,
  string
> = Object.freeze({
  [DataInspectorColumnIdentifier.AdvertisingGoal]: 'Advertising Goal',
  [DataInspectorColumnIdentifier.SmartStructure]: 'Smart Structure',
  [DataInspectorColumnIdentifier.CampaignType]: 'Campaign Type',
  [DataInspectorColumnIdentifier.CampaignTargetingType]:
    'Campaign Targeting Type',
  [DataInspectorColumnIdentifier.CampaignStatus]: 'Campaign Status',
  [DataInspectorColumnIdentifier.StartDate]: 'Start Date',
  [DataInspectorColumnIdentifier.EndDate]: 'End Date',
  [DataInspectorColumnIdentifier.BudgetType]: 'Campaign budget type',
  [DataInspectorColumnIdentifier.DailyBudget]: 'Campaign Daily Budget',
  [DataInspectorColumnIdentifier.LifetimeBudget]: 'Campaign lifetime budget',
  [DataInspectorColumnIdentifier.BudgetRollover]: 'Budget rollover',
  [DataInspectorColumnIdentifier.SearchCarouselInclusion]:
    'Search carousel placement',
  [DataInspectorColumnIdentifier.ItemBuyBoxPlacement]: 'Item buybox placement',
  [DataInspectorColumnIdentifier.ItemCarouselPlacement]:
    'Item carousel placement',
  [DataInspectorColumnIdentifier.BuyBoxBidMultiplier]: 'Buy box bid multiplier',
  [DataInspectorColumnIdentifier.SearchIngridBidMultiplier]:
    'Search ingrid bid multiplier',
  [DataInspectorColumnIdentifier.HomePageBidMultiplier]:
    'Home page bid multiplier',
  [DataInspectorColumnIdentifier.StockUpBidMultiplier]:
    'Stock-up bid multiplier',
  [DataInspectorColumnIdentifier.DesktopBidMultiplier]:
    'Desktop bid multiplier',
  [DataInspectorColumnIdentifier.AppBidMultiplier]: 'App bid multiplier',
  [DataInspectorColumnIdentifier.MobileBidMultiplier]: 'Mobile bid multiplier',
  [DataInspectorColumnIdentifier.Impressions]: 'Impressions',
  [DataInspectorColumnIdentifier.Clicks]: 'Clicks',
  [DataInspectorColumnIdentifier.AdSpend]: 'Ad Spend',
  [DataInspectorColumnIdentifier.AdSales]: 'Ad Sales',
  [DataInspectorColumnIdentifier.AdUnitsSold]: 'Ad Units Sold',
  [DataInspectorColumnIdentifier.ConversionRate]: 'Ad Conversion Rate',
  [DataInspectorColumnIdentifier.ACOS]: 'ACoS',
  [DataInspectorColumnIdentifier.ROAS]: 'ROAS',
  [DataInspectorColumnIdentifier.ClickThroughRate]: 'Click Through Rate',
  [DataInspectorColumnIdentifier.CostPerClick]: 'Cost Per Click',
  [DataInspectorColumnIdentifier.DirectAdSales]: 'Advertised SKU sales',
  [DataInspectorColumnIdentifier.DirectACOS]: 'Direct ACoS',
  [DataInspectorColumnIdentifier.DirectROAS]: 'Direct ROAS',
  [DataInspectorColumnIdentifier.RelatedClickRevenue]: 'Other SKU sales',
  [DataInspectorColumnIdentifier.TotalConversions]: 'Conversions',
  [DataInspectorColumnIdentifier.CampaignName]: 'Campaign Name',
  [DataInspectorColumnIdentifier.AdGroupName]: 'Ad Group Name',
  [DataInspectorColumnIdentifier.AdGroupStatus]: 'Ad Group Status',
  [DataInspectorColumnIdentifier.BidAutomationStatus]: 'Automated Bidding',
  [DataInspectorColumnIdentifier.MACSTarget]: 'ACoS Target',
  [DataInspectorColumnIdentifier.MinBid]: 'Min bid',
  [DataInspectorColumnIdentifier.MaxBid]: 'Max bid',
  [DataInspectorColumnIdentifier.ProductName]: 'Product name',
  [DataInspectorColumnIdentifier.ProductAdStatus]: 'Product Ad Status',
  [DataInspectorColumnIdentifier.Bid]: 'Bid',
  [DataInspectorColumnIdentifier.TargetText]: 'Target text',
  [DataInspectorColumnIdentifier.TargetLabels]: 'Labels',
  [DataInspectorColumnIdentifier.Tags]: 'Tags',
  [DataInspectorColumnIdentifier.AddedBy]: 'Added By',
  [DataInspectorColumnIdentifier.MatchType]: 'Match Type',
  [DataInspectorColumnIdentifier.DateAdded]: 'Date Added',
  [DataInspectorColumnIdentifier.KeywordType]: 'Target Type',
  [DataInspectorColumnIdentifier.TargetStatus]: 'Target status',
  [DataInspectorColumnIdentifier.AdItemsCampaignStatus]: 'Campaign status',
  [DataInspectorColumnIdentifier.Portfolio]: 'Portfolio',
  [DataInspectorColumnIdentifier.Group]: 'Group',
  [DataInspectorColumnIdentifier.AmazonBiddingStrategy]:
    'Amazon Bidding Strategy',
  [DataInspectorColumnIdentifier.ProductPageBidMultiplier]:
    'Product Page Bid Multiplier',
  [DataInspectorColumnIdentifier.TopOfSearchBidMultiplier]:
    'Top of Search Bid Multiplier',
  [DataInspectorColumnIdentifier.AdConversions]: 'Ad Conversions',
  [DataInspectorColumnIdentifier.CampaignTactic]: 'Campaign tactic',
  [DataInspectorColumnIdentifier.AdgroupDefaultBid]: 'Ad Group Default Bid',
  [DataInspectorColumnIdentifier.NewToBrandSales]: 'Ad sales new to brand',
  [DataInspectorColumnIdentifier.NewToBrandAdConversions]:
    'Ad conversions new to brand',
  [DataInspectorColumnIdentifier.TargetingExpression]: 'Targeting expression',
  [DataInspectorColumnIdentifier.ExpressionType]: 'Expression type',
  [DataInspectorColumnIdentifier.CampaignAdFormat]: 'Campaign ad format',
  [DataInspectorColumnIdentifier.CampaignServingStatus]:
    'Campaign serving status',
  [DataInspectorColumnIdentifier.AmazonBidOptimization]:
    'Amazon bid optimization',
  [DataInspectorColumnIdentifier.BelowTopOfSearchBidMultiplier]:
    'Below top-of-search bid multiplier',
  [DataInspectorColumnIdentifier.NewToBrandOrders]: 'Ad orders new to brand',
  [DataInspectorColumnIdentifier.PercentageOfOrdersNewToBrand]:
    '% of ad orders new to brand',
  [DataInspectorColumnIdentifier.NewToBrandOrderRate]:
    'Order rate new to brand',
  [DataInspectorColumnIdentifier.PercentageOfSalesNewToBrand]:
    '% of ad sales new to brand',
  [DataInspectorColumnIdentifier.NewToBrandAdUnitsSold]:
    'Ad units sold new to brand',
  [DataInspectorColumnIdentifier.PercentageOfAdUnitsSoldNewToBrand]:
    '% of ad units sold new to brand',
  [DataInspectorColumnIdentifier.TargetsDailyBudget]: 'Campaign Daily Budget',
  [DataInspectorColumnIdentifier.CampaignDailyBudget]: 'Campaign Daily Budget',
  [DataInspectorColumnIdentifier.MerchantName]: 'Merchant name',
  [DataInspectorColumnIdentifier.CampaignCostType]: 'Campaign cost type',
  [DataInspectorColumnIdentifier.AdGroupReviewStatus]: 'Ad group review status',
  [DataInspectorColumnIdentifier.ProductReviewStatus]: 'Product review status',
  [DataInspectorColumnIdentifier.ProfileReviewStatus]: 'Profile review status',
  [DataInspectorColumnIdentifier.KeywordReviewStatus]: 'Keyword review status',
  [DataInspectorColumnIdentifier.ProfileStatus]: 'Profile status',
  [DataInspectorColumnIdentifier.Headline]: 'Headline',
  [DataInspectorColumnIdentifier.ClickURL]: 'Click URL',
  [DataInspectorColumnIdentifier.Logo]: 'Logo',
  [DataInspectorColumnIdentifier.AiRecommendationKeywordSetting]:
    'Ai Recommended Keyword',
  [DataInspectorColumnIdentifier.CreativeName]: 'Name',
  [DataInspectorColumnIdentifier.CreativeServingStatus]: 'Serving Status',
  [DataInspectorColumnIdentifier.CreativeType]: 'Creative Type',
  [DataInspectorColumnIdentifier.AdStatus]: 'Ad Status',
  [DataInspectorColumnIdentifier.CreatedAt]: 'Created at',
  [DataInspectorColumnIdentifier.AdvertisedSkuUnits]: 'Advertised SKU units',
  [DataInspectorColumnIdentifier.OtherSkuUnits]: 'Other SKU units',
  [DataInspectorColumnIdentifier.TotalAttributedSales]: 'Ad Sales',
  [DataInspectorColumnIdentifier.Orders]: 'Orders',
  [DataInspectorColumnIdentifier.VideoImpressions]: 'Video Impressions',
  [DataInspectorColumnIdentifier.ViewableImpressions]: 'Viewable Impressions',
  [DataInspectorColumnIdentifier.ViewThroughRate]: 'View Through Rate',
  [DataInspectorColumnIdentifier.ClickThroughRateForViews]:
    'Click Through Rate For Views',
  [DataInspectorColumnIdentifier.ViewThroughAttributedSales]:
    'View Through Attributed Sales',
  [DataInspectorColumnIdentifier.ViewThroughOrders]: 'View Through Orders',
  [DataInspectorColumnIdentifier.ViewThroughUnitsSold]:
    'View Through Units Sold',
  [DataInspectorColumnIdentifier.TotalCompleteViewAttributedSales]:
    'Total Complete View Attributed Sales',
  [DataInspectorColumnIdentifier.AdvertisedCompleteViewAttributedSales]:
    'Advertised Complete View Attributed Sales',
  [DataInspectorColumnIdentifier.OtherCompleteViewAttributedSales]:
    'Other Complete View Attributed Sales',
  [DataInspectorColumnIdentifier.CompleteViewOrders]: 'Complete View Orders',
  [DataInspectorColumnIdentifier.CompleteViewUnitsSold]:
    'Complete View Units Sold',
  [DataInspectorColumnIdentifier.VideoFirstQuartileViews]:
    'Video First Quartile Views',
  [DataInspectorColumnIdentifier.VideoMidpointViews]: 'Video Midpoint Views',
  [DataInspectorColumnIdentifier.VideoThirdQuartileViews]:
    'Video Third Quartile Views',
  [DataInspectorColumnIdentifier.VideoCompleteViews]: 'Video Complete Views',
  [DataInspectorColumnIdentifier.VideoUnmutes]: 'Video Unmutes',
  [DataInspectorColumnIdentifier.FiveSecondViews]: '5 Second Views',
  [DataInspectorColumnIdentifier.FiveSecondViewRate]: '5 Second View Rate',
  [DataInspectorColumnIdentifier.Name]: 'Name',
});

type AdTypeConfigProps = {
  readonly [salesChannel in FlywheelSalesChannel]: MerchantCountryAdTypeConfig;
};

export const AD_TYPE_CONFIG: AdTypeConfigProps = {
  [FlywheelSalesChannel.Amazon]: {
    [MerchantCountryCode.US]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.BE]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.CA]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.MX]: [
      AdType.SponsoredProducts,
      AdType.SponsoredDisplay,
      AdType.SponsoredBrands,
    ],
    [MerchantCountryCode.BR]: [
      AdType.SponsoredProducts,
      AdType.SponsoredDisplay,
      AdType.SponsoredBrands,
    ],
    [MerchantCountryCode.UK]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.GB]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.DE]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.FR]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.ES]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.IT]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.NL]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.AE]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.KSA]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
    ],
    [MerchantCountryCode.IN]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.JP]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.AU]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
      AdType.SponsoredDisplay,
    ],
    [MerchantCountryCode.SG]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
    ],
    [MerchantCountryCode.SE]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
    ],
    [MerchantCountryCode.SA]: [
      AdType.SponsoredProducts,
      AdType.SponsoredBrands,
    ],
  },
  [FlywheelSalesChannel.Walmart]: {
    [MerchantCountryCode.US]: [
      AdType.SponsoredProducts,
      AdType.SearchBrandAmplifier,
      AdType.SponsoredVideos,
    ],
  },
};

type AdLevelConfigProps = {
  readonly [salesChannel in FlywheelSalesChannel]: AdLevelConfig;
};

export const AD_LEVEL_CONFIG: AdLevelConfigProps = {
  [FlywheelSalesChannel.Amazon]: {
    [AdType.SponsoredProducts]: [
      AdLevel.Campaigns,
      AdLevel.AdGroups,
      AdLevel.ProductAds,
      AdLevel.KeywordTargets,
      AdLevel.ProductTargets,
    ],
    [AdType.SponsoredBrands]: [
      AdLevel.Campaigns,
      AdLevel.AdGroups,
      AdLevel.SbAds,
      AdLevel.KeywordTargets,
      AdLevel.ProductTargets,
    ],
    [AdType.SponsoredDisplay]: [
      AdLevel.Campaigns,
      AdLevel.AdGroups,
      AdLevel.ProductAds,
      AdLevel.Targets,
    ],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdType.SponsoredProducts]: [
      AdLevel.Campaigns,
      AdLevel.AdGroups,
      AdLevel.ProductAds,
      AdLevel.KeywordTargets,
    ],
    [AdType.SearchBrandAmplifier]: [
      AdLevel.Campaigns,
      AdLevel.AdGroups,
      AdLevel.ProductAds,
      AdLevel.Profiles,
      AdLevel.KeywordTargets,
    ],
    [AdType.SponsoredVideos]: [
      AdLevel.Campaigns,
      AdLevel.AdGroups,
      AdLevel.SvAds,
      AdLevel.KeywordTargets,
    ],
  },
};

export enum DataInspectionColumnGroupIdentifier {
  Details = 'Details',
  ChannelSettings = 'Channel settings',
  Performance = 'Performance',
  FlywheelSettings = 'Flywheel settings',
}

export const DATA_INSPECTION_COLUMN_MANAGER_CONFIG: DataInspectorColumnManagerConfigProps =
  {
    [FlywheelSalesChannel.Amazon]: [
      {
        adType: AdType.SponsoredProducts,
        config: [
          {
            adLevel: AdLevel.Campaigns,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignType]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisingGoal]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.SmartStructure]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CreatedAt]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.StartDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.EndDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AmazonBiddingStrategy]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ProductPageBidMultiplier]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.TopOfSearchBidMultiplier]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.ProductAds,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.ProductAdStatus]: {
                  isSelected: true,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.ProductTargets,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.TargetLabels]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.DateAdded]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.KeywordType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.TargetStatus]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Bid]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.AdGroups,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignDailyBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Tags]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AiRecommendationKeywordSetting]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: {
                  isSelected: true,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdgroupDefaultBid]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CreatedAt]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.KeywordTargets,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MatchType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Tags]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.TargetLabels]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.DateAdded]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.KeywordType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.TargetStatus]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Bid]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
              },
            },
          },
        ],
      },
      {
        adType: AdType.SponsoredBrands,
        config: [
          {
            adLevel: AdLevel.Campaigns,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignServingStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.StartDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.EndDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.BudgetType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AmazonBidOptimization]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.BelowTopOfSearchBidMultiplier]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfOrdersNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrderRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfSalesNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
                  {
                    isSelected: false,
                  },
              },
            },
          },
          {
            adLevel: AdLevel.ProductTargets,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.KeywordType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.TargetLabels]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.TargetStatus]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Bid]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfOrdersNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrderRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfSalesNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
                  {
                    isSelected: false,
                  },
              },
            },
          },
          {
            adLevel: AdLevel.KeywordTargets,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MatchType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.TargetLabels]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.KeywordType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.TargetStatus]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Bid]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfOrdersNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrderRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfSalesNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
                  {
                    isSelected: false,
                  },
              },
            },
          },
          {
            adLevel: AdLevel.AdGroups,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignDailyBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: {
                  isSelected: true,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: true,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.SbAds,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.CreativeName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CreativeType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CreativeServingStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignDailyBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdStatus]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfOrdersNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrderRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfSalesNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
                  {
                    isSelected: false,
                  },
              },
            },
          },
        ],
      },
      {
        adType: AdType.SponsoredDisplay,
        config: [
          {
            adLevel: AdLevel.AdGroups,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignDailyBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignCostType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: {
                  isSelected: true,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdgroupDefaultBid]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: true,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.Campaigns,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignCostType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.StartDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.EndDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.Targets,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.TargetLabels]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.KeywordType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignCostType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.TargetStatus]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Bid]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.ProductAds,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignCostType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: { isSelected: false },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.ProductAdStatus]: {
                  isSelected: true,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdConversions]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]: {
                  isSelected: false,
                },
              },
            },
          },
        ],
      },
    ],
    [FlywheelSalesChannel.Walmart]: [
      {
        adType: AdType.SponsoredProducts,
        config: [
          {
            adLevel: AdLevel.Campaigns,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MerchantName]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignType]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisingGoal]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.SmartStructure]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CreatedAt]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.StartDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.EndDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.BudgetType]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.BudgetRollover]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.SearchCarouselInclusion]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ItemBuyBoxPlacement]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ItemCarouselPlacement]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.BuyBoxBidMultiplier]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.SearchIngridBidMultiplier]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.HomePageBidMultiplier]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.StockUpBidMultiplier]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DesktopBidMultiplier]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AppBidMultiplier]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.MobileBidMultiplier]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.AdGroups,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MerchantName]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Tags]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AiRecommendationKeywordSetting]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: {
                  isSelected: true,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CreatedAt]: { isSelected: false },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.ProductAds,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MerchantName]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdItemsCampaignStatus]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.ProductAdStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Bid]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.KeywordTargets,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MerchantName]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.MatchType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Tags]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.TargetLabels]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.DateAdded]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.KeywordType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.TargetStatus]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Bid]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
              },
            },
          },
        ],
      },
      {
        adType: AdType.SearchBrandAmplifier,
        config: [
          {
            adLevel: AdLevel.Campaigns,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MerchantName]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.StartDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.EndDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.BudgetType]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.BudgetRollover]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfOrdersNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfSalesNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
                  {
                    isSelected: false,
                  },
              },
            },
          },
          {
            adLevel: AdLevel.AdGroups,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MerchantName]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: {
                  isSelected: true,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.ProductAds,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MerchantName]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ProductReviewStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdItemsCampaignStatus]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.ProductAdStatus]: {
                  isSelected: true,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.Profiles,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MerchantName]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ProfileReviewStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.ProfileStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Headline]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ClickURL]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Logo]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.KeywordTargets,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MerchantName]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.MatchType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.KeywordReviewStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.TargetStatus]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Bid]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
              },
            },
          },
        ],
      },
      {
        adType: AdType.SponsoredVideos,
        config: [
          {
            adLevel: AdLevel.Campaigns,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.StartDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.EndDate]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.BudgetType]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.BudgetRollover]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfOrdersNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfSalesNewToBrand]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
                  { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Orders]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ViewableImpressions]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ViewThroughOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ViewThroughUnitsSold]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CompleteViewOrders]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.VideoFirstQuartileViews]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.VideoMidpointViews]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.VideoThirdQuartileViews]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.VideoCompleteViews]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.VideoUnmutes]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.FiveSecondViews]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.AdGroups,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MerchantName]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Group]: {
                  isSelected: true,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CreatedAt]: { isSelected: false },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Orders]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: { isSelected: false },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
              },
            },
          },
          {
            adLevel: AdLevel.KeywordTargets,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.MerchantName]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.MatchType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.KeywordReviewStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.KeywordType]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignDailyBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.TargetStatus]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Bid]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdSales]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.Orders]: { isSelected: true },
              },
            },
          },
          {
            adLevel: AdLevel.SvAds,
            columns: {
              [DataInspectionColumnGroupIdentifier.Details]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignName]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignDailyBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]: {
                  isSelected: false,
                },
              },
              [DataInspectionColumnGroupIdentifier.ChannelSettings]: {
                [DATA_INSPECTOR_COLUMN_NAME.AdStatus]: { isSelected: true },
              },
              [DataInspectionColumnGroupIdentifier.Performance]: {
                [DATA_INSPECTOR_COLUMN_NAME.TotalAttributedSales]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ACOS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ROAS]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Orders]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Impressions]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.Clicks]: { isSelected: true },
                [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]: {
                  isSelected: true,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]: {
                  isSelected: false,
                },
                [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]: {
                  isSelected: false,
                },
              },
            },
          },
        ],
      },
    ],
  };
