import {
  KeywordActionColumns,
  KeywordRecommendationsData,
} from '../../../../../lib/types/KeywordAction';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import {
  ColumnAlign,
  TableCellV2,
  TableCellV2Type,
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../../../lib/types/I18nKey';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';

export const Cell: React.FC<KeywordRecommendationsData> = ({
  performance: { roasTotal },
}) => {
  const intl = useIntl();
  const currentValue = isNil(roasTotal)
    ? null
    : intl.formatNumber(roasTotal, {
        style: 'decimal',
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      });

  return (
    <TableCellV2
      tableCellData={{
        tableCellType: TableCellV2Type.Number,
        currentValue: currentValue,
      }}
      className="h-full flex justify-center"
    />
  );
};
Cell.displayName = 'KWACellROAS';

export const roas: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  {},
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_ROAS_HEADER,
  accessorKey: KeywordActionColumns.ROAS,
  sortable: true,
  size: 120,
  minSize: 120,
  align: ColumnAlign.Right,
  cell: ({ row: { original } }) => <Cell {...original} />,
};

export const roasFilter = createNumericDataFieldFilter(
  KeywordActionColumns.ROAS,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_ROAS_HEADER,
  isValidNumber(0)
);
