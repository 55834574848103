import { MoneyWithAmountInString } from './Money';
import { AdType } from './AOSharedTypes';
import { MerchantCountry } from './Fam';

export const enum TypeBudgetAllocation {
  MERCHANT = 'MERCHANT',
  GROUP = 'CAMPAIGN_GROUP',
}
export interface BudgetAllocation {
  readonly merchantCountryId: string;
  readonly campaignGroupId?: string;
  readonly campaignGroupLabel?: string;
  readonly campaignCount?: number;
  readonly isUpdated?: boolean;
  readonly budget?: MoneyWithAmountInString;
  readonly isBudgetCapEnabled?: boolean;
  readonly updatedAt?: string;
  readonly budgetAllocationType?: TypeBudgetAllocation;
}

export interface BudgetAllocationsResponseRequest {
  readonly startDate: string;
  readonly endDate: string;
  readonly allocations: BudgetAllocation[];
}

export interface BudgetInsightsCampaignDataResponse {
  readonly items: BudgetResponseType[];
  readonly totalItems: number;
}

export interface BudgetInsightsCampaignData {
  readonly elements: BudgetResponseType[];
  readonly elemefilteredElementsnts: number;
}

export type BudgetResponseType = {
  readonly id: string;
  readonly merchantCountryId: string;
  readonly name: string;
  readonly status: string;
  readonly advertisingType: string;
  readonly budgetType: string;
  readonly targetingType: string;
  readonly budget: MoneyWithAmountInString;
  readonly timeFrameBudget: MoneyWithAmountInString;
  readonly adSpend: MoneyWithAmountInString;
  readonly budgetUtilisation: string;
  readonly action?: string;
};
export type BudgetResponseTypeBudget = {
  readonly merchantCountryId: string;
  readonly isBudgetCapEnabled: boolean;
  readonly updatedAt: string;
  readonly budget: MoneyWithAmountInString;
  readonly budgetAllocationType?: TypeBudgetAllocation;
  readonly campaignCount?: number;
  readonly campaignGroupLabel?: string;
  readonly campaignGroupId?: string;
};

export interface BudgetInsightsMerchantDataResponse {
  readonly items: BudgetResponseMerchantPageType[];
  readonly totalItems: number;
}

export interface BudgetInsightsGroupDataResponse {
  readonly items: BudgetResponseGroupPageType[];
  readonly totalItems: number;
}

export interface BudgetCampaignDataResponse {
  readonly items: BudgetResponseMerchantType[];
  readonly totalItems: number;
}

export interface BudgetInsightsMerchantTypenData {
  readonly elements: BudgetResponseMerchantType[];
  readonly filteredElements: number;
}

export type BudgetResponseMerchantType = {
  readonly monthlyBudgetAllocation: MoneyWithAmountInString;
  readonly mtdBudgetAllocation: MoneyWithAmountInString;
  readonly mtdAdSpend: MoneyWithAmountInString;
  readonly mtdBudgetUtilization: number;
  readonly dailyBudgetAllocation: MoneyWithAmountInString;
  readonly avgDailyAdSpendRequired?: MoneyWithAmountInString;
  readonly avgDailyAdSpend3d: MoneyWithAmountInString;
  readonly dailyAdSpendRequired: MoneyWithAmountInString;
  readonly forecastAdSpend3d: MoneyWithAmountInString;
  readonly forecastAdSpend7d: MoneyWithAmountInString;
  readonly action?: string;
  readonly campaignName?: string;
  readonly roas?: string;
  readonly campaignId?: string;
  readonly merchantCountryId?: string;
  readonly advertisingType?: AdType;
  readonly groupLevelBudgeted?: boolean;
  readonly budgetAllocationType?: TypeBudgetAllocation;
  readonly campaignGroupLabel?: string;
  readonly campaignCount?: number;
  readonly campaignGroupId?: string;
  readonly mtdRoas?: number;
  readonly mtdAcos?: number;
  readonly mtdAdsales?: number;
  readonly acos30d?: number;
  readonly adSales30d?: MoneyWithAmountInString;
  readonly pendingFields?: string[];
  readonly campaignStatus?: string;
  readonly campaignTargetingType?: string;
  readonly mtdAdSales?: MoneyWithAmountInString;
  readonly clicks30d?: number;
  readonly clickThroughRate30d?: number;
  readonly impressions30d?: number;
  readonly costPerClick30d?: MoneyWithAmountInString;
  readonly unitsSold30d?: number;
  readonly adConversion30d?: number;
  readonly adConversionRate30d?: number;
  readonly mtdClicks?: number;
  readonly mtdClickThroughRate?: number;
  readonly mtdImpressions?: number;
  readonly mtdCostPerClick?: MoneyWithAmountInString;
  readonly mtdUnitsSold?: number;
  readonly mtdAdConversion?: number;
  readonly mtdAdConversionRate?: number;
};

export type BudgetResponseMerchantPageType = {
  readonly mtdBudgetAllocation: MoneyWithAmountInString;
  readonly mtdAdSpend: MoneyWithAmountInString;
  readonly mtdBudgetUtilization: number;
  readonly monthlyBudgetAllocation: MoneyWithAmountInString;
  readonly dailyBudgetAllocation: MoneyWithAmountInString;
  readonly dailyAdSpendRequired: MoneyWithAmountInString;
  readonly avgDailyAdSpend3d: MoneyWithAmountInString;
  readonly merchantCountryId: string;
  readonly budgetAllocationType: TypeBudgetAllocation;
  readonly forecastAdSpend3d: MoneyWithAmountInString;
  readonly forecastAdSpend7d: MoneyWithAmountInString;
  readonly roas?: string;
  readonly action?: string;
  readonly mtdTacos?: number;
  readonly tacos30d?: number;
  readonly mtdAcos?: number;
  readonly acos30d?: number;
  readonly mtdRoas?: number;
  readonly adSales30d?: MoneyWithAmountInString;
  readonly mtdAdSales?: MoneyWithAmountInString;
};

export type BudgetResponseGroupPageType = {
  readonly monthlyBudgetAllocation: MoneyWithAmountInString;
  readonly mtdBudgetAllocation: MoneyWithAmountInString;
  readonly mtdAdSpend: MoneyWithAmountInString;
  readonly mtdBudgetUtilization: number;
  readonly dailyBudgetAllocation: MoneyWithAmountInString;
  readonly avgDailyAdSpend3d: MoneyWithAmountInString;
  readonly dailyAdSpendRequired: MoneyWithAmountInString;
  readonly forecastAdSpend3d: MoneyWithAmountInString;
  readonly forecastAdSpend7d: MoneyWithAmountInString;
  readonly action?: string;
  readonly campaignName?: string;
  readonly roas?: string;
  readonly campaignId?: string;
  readonly merchantCountryId?: string;
  readonly advertisingType?: AdType;
  readonly groupLevelBudgeted?: boolean;
  readonly budgetAllocationType?: TypeBudgetAllocation;
  readonly campaignGroupLabel?: string;
  readonly campaignCount?: number;
  readonly campaignGroupId?: string;
};

export interface BudgetInsightMerchantDataRequest {
  readonly startDate: string;
  readonly endDate: string;
  readonly merchantCountryIds: string[];
}

interface allocationsRequest {
  merchantCountryId?: string;
  budgetAllocationType?: TypeBudgetAllocation;
  merchantCountryBudget?: MoneyWithAmountInString;
  campaignGroupBudgets?: {
    budget: MoneyWithAmountInString;
    campaignGroupId?: string;
  }[];
}
export interface SetBudgetGroupAllocationsRequest {
  startDate: string;
  endDate: string;
  allocations: allocationsRequest[];
}

export interface BudgetInsightsMerchantRequest {
  startDate: string;
  endDate: string;
  reportEndDate: string;
  merchantCountryIds: MerchantCountry[] | string[];
  channelId?: string;
  merchantType?: string;
  BudgetPacingMerchantData?: any[];
}

export interface BudgetInsightsCampaignGroupRequest {
  startDate: string;
  endDate: string;
  reportEndDate: string;
  channelId: string;
  merchantType: string;
  merchantCountryId: string;
}

export interface BudgetAllocationsDataRequest {
  startDate: string;
  endDate: string;
  merchantCountryIds: string[];
}

interface UpdatesRequest {
  merchantCountryId: string;
  budgetCapEnabled?: boolean;
}
export interface UpdateBudgetCapAllocationsRequest {
  startDate: string;
  endDate: string;
  updates: UpdatesRequest[];
}

export interface UpdateAllocationsResponses {
  status: string;
}

export interface BudgetAllocationsGroupRequest {
  startDate: string;
  endDate: string;
  reportEndDate: string;
  merchantCountryId: string;
  campaignGroupId?: string;
}

export interface BudgetInsightsCampaignDataRequest {
  startDate: string;
  endDate: string;
  reportEndDate: string;
  channelId?: string;
  merchantType?: string;
  merchantCountryId?: string;
  campaignGroupId?: string;
}

export interface BudgetAllocationsGroupRespons {
  budget: MoneyWithAmountInString;
  campaignCount: number;
  campaignGroupId: string;
  campaignGroupLabel: string;
  merchantCountryId: string;
  updatedAt: string;
}

export interface BudgetAllocationsMerchantRespons {
  budget: MoneyWithAmountInString;
  budgetAllocationType: TypeBudgetAllocation;
  isBudgetCapEnabled: boolean;
  merchantCountryId: string;
  updatedAt: string;
  isUpdated?: boolean;
}

export interface GetBudgetAllocationsGroupRespons {
  budget: MoneyWithAmountInString;
  merchantCountryId: string;
  updatedAt: string;
  campaignCount?: number;
  campaignGroupId?: string;
  campaignGroupLabel?: string;
}

export interface BudgetAllocationsDataAllResponse {
  items: BudgetAllocationsMerchantRespons[];
}

export interface ExportCampaignBudgetCsvRequest {
  readonly startDate: string;
  readonly endDate: string;
  readonly reportEndDate: string;
  readonly merchantCountryId: string;
  readonly channelId: string;
  readonly merchantType: string;
  readonly campaignGroupId?: string;
}
export interface CampaignBudgetUploadResponse {
  readonly jobId: number;
  readonly status: string;
}
