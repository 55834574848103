import { IntlShape } from 'react-intl';
import { FlywheelTableV2ColumnGroupProps } from '../../../../../../containers/tableV2/types';
import { regionColumn } from './region';
import { statusColumn } from './status';
import { MerchantsGroup } from '../../authProductData';
import { AuthorizeMerchantTableConnect } from '..';

export const GetAccountListColumnsDefinition = (
  intl: IntlShape
): FlywheelTableV2ColumnGroupProps<
  MerchantsGroup,
  {},
  AuthorizeMerchantTableConnect,
  {}
>[] =>
  [regionColumn, statusColumn].map((column) => {
    if (column.header) {
      return {
        ...column,
        header: intl.formatMessage({ id: column.header }),
      };
    }
    return column;
  });
