import { fromNullable } from 'fp-ts/lib/Option';

import {
  BadgeV2,
  BadgeV2Types,
  ButtonProps,
  CheckboxLabelProps,
  CheckboxProps,
  CheckboxWithTextInputProps,
  CountryProps,
  CycleDotIcon,
  DisplayExpression2Props,
  EditCalendarProps,
  ExpressionProps,
  ExternalLinkText,
  FormToggleProps,
  Icon,
  IconPlacement,
  IconSize,
  NumericInputProps,
  PillAndButtonProps,
  PillProps,
  Placement,
  PortaledTooltipPlacement,
  SelectProps,
  SvgIcon,
  TableCell,
  TableCellType,
  TableCellV2,
  TableCellV2Type,
  TextInputProps,
  TextWithTooltipProps,
  TruncatedText,
  TypedPortaledTooltipProps,
  TypedTooltipProps,
  UserCardProps,
} from '@teikametrics/tm-design-system';

import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Money } from '../../../lib/types/Money';
import {
  AdType,
  AdvertisingGoal,
  CampaignTargetingType,
  MAP_ADVERTISING_GOAL_TO_CAMPAIGN_GOAL,
  MAP_ADVERTISING_GOAL_TO_CAMPAIGN_TYPE,
} from '../../../lib/types/AOSharedTypes';

export function makeLongTextColumn<T>(getColumn: (o: T) => string | undefined) {
  return (props: T) => {
    return (
      <TableCell
        i18nKeyOrLabel={getColumn(props)}
        tableCellType={TableCellType.LongText}
        useCommonPadding
      />
    );
  };
}

export function makeExpressionColumn<T>(
  getExpressionProps: (o: T) => ExpressionProps | undefined,
  getCheckboxProps?: (o: T) => CheckboxProps | undefined
) {
  return (props: T) => {
    return (
      <TableCell
        expressionProps={getExpressionProps(props)}
        checkboxProps={fromNullable(getCheckboxProps)
          .map((fn) => fn(props))
          .toUndefined()}
        tableCellType={TableCellType.Expression}
      />
    );
  };
}

export function makeExpressionV2Column<T>(
  getExpressionV2Props: (o: T) => DisplayExpression2Props | undefined,
  getCheckboxProps?: (o: T) => CheckboxProps | undefined,
  useCommonPadding?: boolean
) {
  return (props: T) => (
    <TableCell
      expressionV2Props={getExpressionV2Props(props)}
      checkboxProps={fromNullable(getCheckboxProps)
        .map((fn) => fn(props))
        .toUndefined()}
      tableCellType={TableCellType.ExpressionV2}
      useCommonPadding={useCommonPadding}
    />
  );
}

export function makeTextColumn<T>(
  getColumn: (o: T) => string | undefined,
  isInSyncVariant?: boolean,
  className?: string,
  useCommonPadding?: boolean,
  dataTestId?: string
) {
  return (props: T) => {
    return (
      <TableCell
        i18nKeyOrLabel={getColumn(props)}
        tableCellType={TableCellType.Text}
        isInSyncVariant={isInSyncVariant}
        className={className}
        useCommonPadding={useCommonPadding}
        dataTestId={dataTestId}
      />
    );
  };
}

export function makeToggleColumn<T>(getColumn: (o: T) => FormToggleProps) {
  return (props: T) => {
    const b = getColumn(props);
    return (
      <TableCell
        tableCellProps={{ tableCellType: TableCellType.Toggle, ...b }}
      />
    );
  };
}

export function makeToggleWithLabelColumn<T>(
  getColumn: (o: T) => FormToggleProps
) {
  return (props: T) => {
    const b = getColumn(props);
    return (
      <TableCell
        tableCellProps={{ tableCellType: TableCellType.ToggleWithLabel, ...b }}
      />
    );
  };
}

export function makeUserCardColumn<T>(
  getUserCardProps: (o: T) => UserCardProps
) {
  return (props: T) => {
    const b = getUserCardProps(props);
    return (
      <TableCell
        tableCellProps={{ tableCellType: TableCellType.UserCard, ...b }}
      />
    );
  };
}

export function makeButtonColumn<T>(getButtonProps: (o: T) => ButtonProps) {
  return (props: T) => {
    const b = getButtonProps(props);
    return (
      <TableCell
        tableCellProps={{ tableCellType: TableCellType.Button, ...b }}
      />
    );
  };
}

export function makePillColumn<T>(getPillProps: (o: T) => PillProps) {
  return (props: T) => {
    const b = getPillProps(props);
    return (
      <TableCell tableCellProps={{ tableCellType: TableCellType.Pill, ...b }} />
    );
  };
}

export function makeMultiplePillsColumn<T>(
  getPillProps: (o: T) => PillProps[],
  showNoTag?: boolean
) {
  return (props: T) => {
    const b = getPillProps(props);
    return (
      <TableCell
        tableCellProps={{
          tableCellType: TableCellType.Pills,
          pillsProps: b,
          showNoTag,
        }}
      />
    );
  };
}

export function makePillWithButtonColumn<T>(
  getProps: (o: T) => PillAndButtonProps
) {
  return (props: T) => {
    const b = getProps(props);
    return (
      <TableCell
        tableCellProps={{ tableCellType: TableCellType.PillWithButton, ...b }}
      />
    );
  };
}

export const makeCurrencyColumn =
  <T extends {}>(
    getMoney: (o: T) => Money | undefined,
    isInSyncVariant?: boolean
  ) =>
  (props: T) =>
    (
      <TableCell
        tableCellType={TableCellType.Money}
        money={getMoney(props)}
        isInSyncVariant={isInSyncVariant}
      />
    );

export const makeTextLinkColumn =
  <T extends {}>(
    getText: (o: T) => string | undefined,
    getNavPath: (o: T) => string,
    isInSyncVariant?: boolean,
    rightHandContent?: React.ReactNode
  ) =>
  (props: T) =>
    (
      <div className="flex justify-between">
        <TableCell
          tableCellType={TableCellType.TextLink}
          i18nKeyOrLabel={getText(props)}
          navPath={getNavPath(props)}
          isInSyncVariant={isInSyncVariant}
        />
        {rightHandContent && rightHandContent}
      </div>
    );

export const makeTruncatedTextLinkColumn =
  <T extends {}>(
    getText: (o: T) => string | undefined,
    getNavPath: (o: T) => string,
    isInSyncVariant?: boolean,
    rightHandContent?: React.ReactNode,
    leadColumn?: boolean,
    smartCamaignComponent?: React.ReactNode
  ) =>
  (props: T) =>
    (
      <div
        className={classNames('items-start', {
          'pr-32': rightHandContent,
          'px-12 py-8': !leadColumn,
          'py-8': leadColumn,
        })}
      >
        <TableCellV2
          tableCellData={{
            currentValue: getText(props) ? (
              <div className="flex justify-between">
                <div className="flex">
                  <div className="flex items-start">
                    {smartCamaignComponent}
                    {isInSyncVariant && (
                      <Icon
                        className="text-grey-500 mr-8"
                        svg={CycleDotIcon}
                        size={IconSize.Small}
                      />
                    )}
                  </div>
                  <Link to={getNavPath(props) as string}>
                    <TruncatedText
                      text={getText(props)}
                      fontClassNames="underline flex items-center"
                      hoverClassNames="w-fit -mt-6 -ml-4"
                    />
                  </Link>
                </div>
                {rightHandContent && rightHandContent}
              </div>
            ) : (
              '—'
            ),
            tableCellType: TableCellV2Type.Text,
            className: 'w-full',
          }}
          className="bg-transparent bg-green-500 h-full"
        />
      </div>
    );

export function makeExternalTextLinkColumn<T>(
  getLabelProps: (o: T) => string,
  getExternalUrl: (o: T) => string,
  isInSyncVariant?: boolean
) {
  return (props: T) => {
    const label = getLabelProps(props);
    const externalUrl = getExternalUrl(props);
    return (
      <div className="px-12 py-8 flex items-start bg-transparent">
        {isInSyncVariant ? (
          <>
            <ExternalLinkText
              className="text-grey-500"
              label={label}
              url={externalUrl}
            />
            <Icon
              className="text-grey-500 mt-4 ml-8 min-w-12"
              svg={CycleDotIcon}
              size={IconSize.Small}
            />
          </>
        ) : (
          <ExternalLinkText label={label} url={externalUrl} />
        )}
      </div>
    );
  };
}

export const makePercentColumn =
  <T extends {}>(
    getFractionalValue: (o: T) => string | undefined,
    isInSyncVariant?: boolean
  ) =>
  (props: T) =>
    (
      <TableCell
        tableCellType={TableCellType.Percent}
        i18nKeyOrLabel={getFractionalValue(props)}
        isInSyncVariant={isInSyncVariant}
      />
    );

export const makeNumericColumn =
  <T extends {}>(
    getNumericValue: (o: T) => string | undefined,
    isInSyncVariant?: boolean,
    isValueInteger?: boolean
  ) =>
  (props: T) =>
    (
      <TableCell
        tableCellType={TableCellType.Number}
        i18nKeyOrLabel={getNumericValue(props)}
        isInSyncVariant={isInSyncVariant}
        isValueInteger={isValueInteger}
      />
    );

export const makeImageColumn =
  <T extends {}>(getImageComponent: (o: T) => string) =>
  (props: T) =>
    (
      <TableCell
        tableCellType={TableCellType.Image}
        imageUrl={getImageComponent(props)}
      />
    );

export const makeTextWithSubtextAndImageColumn =
  <T extends {}>(
    getText: (o: T) => string,
    getSubText: (o: T) => string,
    getImageUrl: (o: T) => string,
    getProductUrl?: (o: T) => string | undefined,
    useCommonPadding?: boolean
  ) =>
  (props: T) =>
    (
      <TableCell
        tableCellType={TableCellType.TextWithSubtextImage}
        imageUrl={getImageUrl(props)}
        i18nKeyOrLabel={getText(props)}
        i18nKeyOrSubLabel={getSubText(props)}
        productUrl={getProductUrl && getProductUrl(props)}
        useCommonPadding={useCommonPadding}
      />
    );

export const makeTextWithIconColumn =
  <T extends {}>(
    getText: (o: T) => string | undefined,
    icon: SvgIcon,
    tooltip: string | JSX.Element,
    showTooltipOnClick?: boolean,
    tooltipPlacement?: PortaledTooltipPlacement,
    iconPlacement?: IconPlacement
  ) =>
  (props: T) =>
    (
      <TableCell
        tableCellType={TableCellType.TextWithIcon}
        i18nKeyOrLabel={getText(props)}
        icon={icon}
        tooltip={tooltip}
        showTooltipOnClick={showTooltipOnClick}
        tooltipPlacement={tooltipPlacement}
        dataTestId="text_column_with_icon"
        iconPlacement={iconPlacement}
      />
    );

export function makeNumericInputColumn<T>(
  getNumericInputProps: (o: T) => NumericInputProps,
  useCommonPadding?: boolean
) {
  return (props: T) => {
    const b = getNumericInputProps(props);
    return (
      <TableCell
        tableCellProps={{ tableCellType: TableCellType.NumericInput, ...b }}
        useCommonPadding={useCommonPadding}
      />
    );
  };
}

export function makeTextInputColumn<T>(
  getTextInputProps: (o: T) => TextInputProps
) {
  return (props: T) => {
    const b = getTextInputProps(props);
    return (
      <TableCell
        tableCellProps={{ tableCellType: TableCellType.TextInput, ...b }}
      />
    );
  };
}

export function makeTextWithTooltipColumn<T>(
  getTextWithTooltipProps: (o: T) => TextWithTooltipProps
) {
  return (props: T) => {
    const b = getTextWithTooltipProps(props);
    return (
      <TableCell
        tableCellProps={{ tableCellType: TableCellType.TextWithTooltip, ...b }}
      />
    );
  };
}

export function makeSelectColumn<T>(
  getSelectProps: (o: T) => SelectProps<number | string>,
  getTooltipProps?: (o: T) => TypedTooltipProps
) {
  return (props: T) => {
    const selectProps = getSelectProps(props);
    const tooltipProps = fromNullable(getTooltipProps)
      .map((func) => func(props))
      .toUndefined();
    return (
      <TableCell
        tableCellProps={{
          ...selectProps,
          ...tooltipProps,
          selectMenuZIndexClass: 'z-1',
          tableCellType: TableCellType.Select,
        }}
      />
    );
  };
}

export function makeCheckboxLabelColumn<T>(
  getCheckboxLabelProps: (o: T) => CheckboxLabelProps
) {
  return (props: T) => {
    const b = getCheckboxLabelProps(props);

    return (
      <TableCell
        tableCellProps={{ tableCellType: TableCellType.CheckboxLabel, ...b }}
      />
    );
  };
}

export function makeTextInputWithPortaledTooltipColumn<T>(
  getTextInputWithPortaledTooltipProps: (
    o: T
  ) => TextInputProps & TypedPortaledTooltipProps
) {
  return (props: T) => {
    const b = getTextInputWithPortaledTooltipProps(props);
    return (
      <TableCell
        tableCellProps={{
          tableCellType: TableCellType.TextInputWithPortaledTooltip,
          ...b,
        }}
      />
    );
  };
}

export function makeCheckboxWithTextInputColumn<T>(
  getCheckboxWithTextInputProps: (o: T) => CheckboxWithTextInputProps
) {
  return (props: T) => {
    const b = getCheckboxWithTextInputProps(props);

    return (
      <TableCell
        tableCellProps={{
          tableCellType: TableCellType.CheckboxWithTextInput,
          ...b,
        }}
      />
    );
  };
}

export const makeCalendarWithDatePickerColumn =
  <T extends {}>(getCalendarWithDatePickerProps: (o: T) => EditCalendarProps) =>
  (props: T) =>
    (
      <TableCell
        tableCellProps={{
          tableCellType: TableCellType.CalendarWithDatePicker,
          ...getCalendarWithDatePickerProps(props),
        }}
      />
    );

export const makeCountryColumn =
  <T extends {}>(getCountryProp: (o: T) => CountryProps | undefined) =>
  (props: T) => {
    return (
      <TableCell
        tableCellProps={{
          tableCellType: TableCellType.Country,
          ...getCountryProp(props),
        }}
      />
    );
  };

export const representSmartCampaign = (
  advertisingGoal?: AdvertisingGoal,
  targetingType?: CampaignTargetingType,
  adType?: AdType,
  showSmartCampaign?: boolean,
  showTooltipTowardsRight?: boolean
) => {
  if (!showSmartCampaign) {
    return <></>;
  }
  return (
    <>
      {targetingType && (
        <div className="mr-8">
          <BadgeV2
            campaignGoal={
              MAP_ADVERTISING_GOAL_TO_CAMPAIGN_GOAL[
                advertisingGoal || AdvertisingGoal.External
              ]
            }
            campaignType={MAP_ADVERTISING_GOAL_TO_CAMPAIGN_TYPE[targetingType]}
            badgeType={BadgeV2Types.CampaignType}
            tooltipPlacement={
              showTooltipTowardsRight ? Placement.Right : Placement.Bottom
            }
          />
        </div>
      )}
    </>
  );
};
