import { DateTime } from 'luxon';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  CalendarIcon,
  CrossMiniIcon,
  Icon,
  IconSize,
  LightningIcon,
  Modal,
  Spinner,
  TextLink,
} from '@teikametrics/tm-design-system';

import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { createAOApiClient } from '../../lib/clients/AOApiClient';
import { createSKUApiClient } from '../../lib/clients/SKUApiClient';
import {
  AdLevelApiEndpoint,
  AdType,
  FlywheelAIEnabled,
  TargetsDataRequest,
  TargetsDetails,
} from '../../lib/types/AOSharedTypes';
import { HOW_TO_CHANGE_PLAN_LINK } from '../../lib/types/CommonSharedTypes';
import I18nKey from '../../lib/types/I18nKey';
import { TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION } from '../../modules/advertisingOptimization/containers/adsManager/types';
import {
  ALL_AD_LEVELS_FILTER_FILED_MAPPER,
  TARGETS_API_COLUMN_NAME,
  getTargetingExpressionAndTargetLabelFilters,
} from '../../modules/advertisingOptimization/containers/adsManager/utils';
import {
  FlywheelSalesChannel,
  MerchantCountry,
  MerchantType,
} from '../../lib/types/Fam';
import {
  MerchantCountrySalesChannel,
  SkuCatalogSummary,
} from '../../lib/types/SKUSharedTypes';
import { CurrencyCode } from '../../lib/utilities/currency';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../lib/types/SalesChannels';
import { PaginatedRequest } from '../../lib/clients/types';
import { FilterOps } from '../../lib/types/Filter';
import { billing30DayRange } from '../../lib/utilities/billingDateRange';

export interface TrialEndedModalProps {
  readonly onCancelClicked?: () => void;
  readonly onContinueClicked?: () => void;
  readonly merchantCountries?: MerchantCountry[];
}

export const TrialEndedModal: React.FC<TrialEndedModalProps> = ({
  onCancelClicked,
  onContinueClicked,
  merchantCountries = [],
}) => {
  const intl = useIntl();
  const userContext = useContext<UserContextState>(UserContext);

  const account = getCurrentAccountFromContext(userContext)!;
  const [billingDates, setBillingDates] = useState<
    | {
        startDate: DateTime;
        endDate: DateTime;
      }
    | undefined
  >();
  const [keywordsAdded, setKeywordsAdded] = useState<number | undefined>();
  const [skuCatalogSummary, setSkuCatalogSummary] = useState<
    SkuCatalogSummary | undefined
  >();
  const [loadingSummaryData, setLoadingSummaryData] = useState(false);

  const ioClient = createSKUApiClient(userContext.userInfo.idToken!);
  const aoClient = createAOApiClient(userContext.userInfo.idToken!);

  useEffect(() => {
    if (!merchantCountries?.length && userContext.userInfo.currentAccountId) {
      setLoadingSummaryData(true);
      getMetricsData();
    }
  }, [merchantCountries, userContext.userInfo.currentAccountId]);

  const getMetricsData = async () => {
    // map the FAM MerchantCountry type to the format expected in the IO request
    const merchantCountySalesChannel: MerchantCountrySalesChannel[] =
      merchantCountries.map((merchant) => ({
        merchantCountryId: merchant.merchantCountryId,
        salesChannelId: merchant.salesChannelId || '',
      }));

    const requestBody = {
      startDate: DateTime.now().minus({ days: 31 }).toISODate(),
      endDate: DateTime.now().minus({ days: 1 }).toISODate(),
      currency: CurrencyCode.USD,
      advertisementFields: ['adSales', 'adSpend'],
      inventoryFields: [],
      platformFeesFields: ['cogs'],
      profitabilityFields: ['totalSales'],
      productFields: [],
      skuDetailsFields: [
        'sku',
        'name',
        'imageUrl',
        'extItemId',
        'extItemType',
        'salesChannelId',
        'salesChannelName',
      ],
      merchantCountries: merchantCountySalesChannel,
      metricsPeriodType: 'CURRENT',
      previousPeriodIncluded: false,
    };

    const [skuCatalogSummaryResponse, amazonKeywords, walmartKeywords] =
      await Promise.all([
        ioClient
          .getSkuCatalogSummary(
            userContext.userInfo.currentAccountId!,
            requestBody,
            {}
          )({ filters: [] })
          .catch(() => undefined),
        getAOMetricsData(
          FlywheelSalesChannel.Amazon,
          AMAZON_SALES_CHANNEL_ID
        ).catch(() => 0),
        getAOMetricsData(
          FlywheelSalesChannel.Walmart,
          WALMART_SALES_CHANNEL_ID
        ).catch(() => 0),
      ]);

    setLoadingSummaryData(false);
    setSkuCatalogSummary(skuCatalogSummaryResponse);
    const totalKeywords = (amazonKeywords || 0) + (walmartKeywords || 0);
    if (totalKeywords > 0) {
      setKeywordsAdded(totalKeywords);
    }
  };

  const getAOMetricsData = async (
    salesChannelName: FlywheelSalesChannel,
    salesChannelId: string
  ) => {
    const merchantCountyIds = merchantCountries
      .filter((merchant) => merchant.salesChannelId === salesChannelId)
      .map((mc) => mc.merchantCountryId);

    if (merchantCountyIds.length === 0) {
      return;
    }

    const requestBody = {
      merchantCountryIds: merchantCountyIds,
      targetDetailsFields: ['targetText', 'adGroupId', 'campaignId'],
      channelSettingsFields: ['targetStatus', 'bid'],
      performanceFields: [
        'adConversions',
        'conversionRate',
        'unitsSold',
        'impressions',
        'clicks',
      ],
      startDate: DateTime.fromISO(
        account.freeTrialStartedAt!
      ).toISODate() as string,
      endDate: DateTime.fromISO(account.freeTrialEndsAt!).toISODate() as string,
      channelId: salesChannelName,
      advertisingType: AdType.SponsoredProducts,
      salesChannelId,
      merchantType: MerchantType.Seller,
    };

    const request: PaginatedRequest = {
      filters: [],
      itemsPerPage: 1,
      page: 1,
      sorts: [],
    };
    const response = await aoClient.getAdLevelData<
      TargetsDataRequest,
      TargetsDetails
    >(
      account.id,
      AdLevelApiEndpoint.KeywordTargets,
      requestBody,
      [
        {
          field: TARGETS_API_COLUMN_NAME.FlywheelAiEnabled,
          op: FilterOps.eq,
          value: FlywheelAIEnabled.FlywheelAIAdded,
        },
      ],
      ALL_AD_LEVELS_FILTER_FILED_MAPPER,
      getTargetingExpressionAndTargetLabelFilters(intl, [
        TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION,
      ])
    )(request);
    return response.totalItems;
  };

  useEffect(() => {
    let billingDates = billing30DayRange(
      userContext?.billingInfo?.nextBillingDate
    );
    if (!billingDates && account.freeTrialStartedAt) {
      billingDates = {
        startDate: DateTime.fromISO(account.freeTrialStartedAt),
        endDate: DateTime.fromISO(account.freeTrialEndsAt!),
      };
    }
    setBillingDates(billingDates);
  }, []);

  const [
    MODAL_TITLE,
    TRIAL_ENDED_METRICS_TITLE,
    TRIAL_ENDED_METRICS_TIME_PERIOD,
    TRIAL_ENDED_METRICS_LARGE_1,
    TRIAL_ENDED_METRICS_LARGE_2,
    TRIAL_ENDED_METRICS_SMALL_1,
    TRIAL_ENDED_METRICS_SMALL_2,
    BUTTON_TEXT,
  ] = [
    I18nKey.TRIAL_ENDED_MODAL_TITLE,
    I18nKey.TRIAL_ENDED_METRICS_TITLE,
    I18nKey.TRIAL_ENDED_METRICS_TIME_PERIOD,
    I18nKey.TRIAL_ENDED_METRICS_LARGE_1,
    I18nKey.TRIAL_ENDED_METRICS_LARGE_2,
    I18nKey.TRIAL_ENDED_METRICS_SMALL_1,
    I18nKey.TRIAL_ENDED_METRICS_SMALL_2,
    I18nKey.BILLING_PAYMENT_DETAIL_CARD_ADD_CARD_BUTTON,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <Modal
      showModal={true}
      className="w-500 max-w-500"
      dataTestId="trial_ended_modal"
    >
      <div className="flex justify-end -mt-24 -mr-24">
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.BlackAndWhite}
          svgIcon={CrossMiniIcon}
          onClick={onCancelClicked}
          className="text-grey-900"
        />
      </div>
      <div className="flex flex-col items-center gap-y-16">
        <div
          className={`flex items-center justify-center w-40 h-40
            rounded-full bg-gradient-to-r from-magenta-500 to-orange-500`}
        >
          <LightningIcon className="fill-current h-20 w-20 text-white" />
        </div>

        <div className="text-grey-900 font-semibold text-xl leading-tight text-center whitespace-pre-line">
          {MODAL_TITLE}
        </div>
        <div
          className={`text-grey-900 font-normal text-base leading-tight text-center pt-8 whitespace-pre-line`}
        >
          {intl.formatMessage<ReactNode>(
            { id: I18nKey.TRIAL_ENDED_MODAL_BODY },
            {
              link: (content) => (
                <TextLink
                  openNewTab
                  textLabel={content}
                  href={HOW_TO_CHANGE_PLAN_LINK}
                  className="text-grey-900"
                  dataTestId="trial-ended_learn-more-link"
                />
              ),
            }
          )}
        </div>
      </div>
      {loadingSummaryData && (
        <div className="min-h-96 relative">
          <Spinner />
        </div>
      )}
      {skuCatalogSummary && (
        <>
          <div className="flex flex-row justify-between mt-24">
            <div className="text-grey-900 font-semibold text-lg leading-tight">
              {TRIAL_ENDED_METRICS_TITLE}
            </div>
            <div className="flex flex-row items-center leading-none">
              <Icon
                svg={CalendarIcon}
                size={IconSize.Small}
                className="text-grey-500 mr-4"
              />
              <div className="text-grey-600 text-sm leading-none">
                {`${billingDates?.startDate.toFormat(
                  'MMM dd, yyyy'
                )} - ${billingDates?.endDate.toFormat('MMM dd, yyyy')}`}
              </div>
            </div>
          </div>

          <div className="border border-solid border-grey-400 rounded-8 p-16 text-base my-16">
            <div className="flex flex-row items-center justify-center h-64">
              {keywordsAdded && (
                <>
                  <div className="w-80 text-right pr-16 leading-tight">
                    {TRIAL_ENDED_METRICS_LARGE_1}
                  </div>
                  <div className="flex flex-col justify-center gap-y-8 border-solid border-l-1 border-purple-400 pl-12 h-full">
                    <div className="text-xl font-semibold leading-none">
                      {keywordsAdded}
                    </div>
                    <div className="text-sm text-purple-500 leading-none">
                      {TRIAL_ENDED_METRICS_TIME_PERIOD}
                    </div>
                  </div>
                </>
              )}
              {skuCatalogSummary?.advertisementFields.acos && (
                <div className="flex flex-row items-center justify-center h-64">
                  <div className="w-80 text-right pr-16 leading-tight">
                    {TRIAL_ENDED_METRICS_LARGE_2}
                  </div>
                  <div className="flex flex-col justify-center gap-y-8 border-solid border-l-1 border-purple-400 pl-12 h-full">
                    <div className="text-xl font-semibold leading-none">
                      {(
                        skuCatalogSummary.advertisementFields.acos * 100
                      ).toFixed(1)}
                      %
                    </div>
                    <div className="text-sm text-purple-500 leading-none">
                      {TRIAL_ENDED_METRICS_TIME_PERIOD}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="text-sm text-center">
              {skuCatalogSummary?.advertisementFields.adSales && (
                <>
                  {TRIAL_ENDED_METRICS_SMALL_1}:
                  <span className="font-semibold px-12">
                    {skuCatalogSummary.advertisementFields.adSales.amount}
                  </span>
                </>
              )}
              {skuCatalogSummary?.profitabilityFields && (
                <>
                  {TRIAL_ENDED_METRICS_SMALL_2}:
                  <span className="font-semibold px-12">
                    {skuCatalogSummary.profitabilityFields.unitsSold || 0}
                  </span>
                </>
              )}
            </div>
          </div>
        </>
      )}

      <div className="flex flex-col items-center mt-24">
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Primary}
          label={BUTTON_TEXT}
          onClick={onContinueClicked}
        />
      </div>
    </Modal>
  );
};
