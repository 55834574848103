import { useIntl } from 'react-intl';

import {
  PortaledTooltipPlacement,
  createMultiSelectDataFieldFilter,
  AlertTriangleIcon as warningSVG,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeTextColumn,
  makeTextWithIconColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  ProductAdDetails,
  ProductReviewStatusType,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TooltipText } from '../reviewStatusTooltipText';
import { PRODUCT_REVIEW_STATUS_MAPPER, TableDataAdsManager } from '../types';
import { PRODUCT_ADS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) => {
  const intl = useIntl();

  const getColumnText = ({ adItemDetails }: ProductAdDetails) =>
    adItemDetails?.adItemReviewStatus
      ? intl.formatMessage({
          id: PRODUCT_REVIEW_STATUS_MAPPER[
            adItemDetails?.adItemReviewStatus?.reviewStatus
          ],
        })
      : undefined;

  if (
    props.adItemDetails?.adItemReviewStatus?.reviewStatus ===
    ProductReviewStatusType.Rejected
  ) {
    return makeTextWithIconColumn(
      getColumnText,
      warningSVG,
      <TooltipText
        comments={props.adItemDetails?.adItemReviewStatus?.reviewComments}
      />,
      true,
      PortaledTooltipPlacement.Right
    )(props);
  }

  return makeTextColumn(getColumnText, false)(props);
};
RowCellElement.displayName = 'RowCellElement';

export const productReviewStatusColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: PRODUCT_ADS_API_COLUMN_NAME.ProductReviewStatus,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_PRODUCT_REVIEW_STATUS,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '180px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const productReviewStatusFilter = createMultiSelectDataFieldFilter(
  PRODUCT_ADS_API_COLUMN_NAME.ProductReviewStatus,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_PRODUCT_REVIEW_STATUS,
  [
    SelectFilterOption.ProductReviewStatusPending,
    SelectFilterOption.ProductReviewStatusApproved,
    SelectFilterOption.ProductReviewStatusRejected,
  ]
);
