import { FlywheelTableColumnGroup } from '../../../../../containers/table/UpdatedFlywheelTable';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { acosColumn } from './acos';
import { acosDirectColumn } from './acosDirect';
import { adConversionsRateColumn } from './adConversionsRate';
import { adGroupNameColumn } from './adGroupName';
import { adGroupReviewStatusColumn } from './adGroupReviewStatus';
import { adgroupStatusColumn } from './adgroupStatus';
import { adSalesColumn } from './adSales';
import { adSalesDirectColumn } from './adSalesDirect';
import { adSpendColumn } from './adSpend';
import { adUnitsSoldColumn } from './adUnitsSold';
import { advertisedSKUUnitsColumn } from './advertisedSkuUnits';
import { avgCostPerClickColumn } from './avgCostPerClick';
import { brandColumn } from './brand';
import { campaignDailyBudgetColumn } from './campaignDailyBudget';
import { campaignNameColumn } from './campaignName';
import { campaignStatusColumn } from './campaignStatus';
import { campaignTargetingTypeColumn } from './campaignTargetingType';
import { campaignTotalBudgetColumn } from './campaignTotalBudget';
import { clicksColumn } from './clicks';
import { clickThroughRateColumn } from './clickThroughRate';
import { clickUrlColumn } from './clickUrl';
import { headlineColumn } from './headline';
import { impressionsColumn } from './impressions';
import { logoColumn } from './logo';
import { merchantNameColumn } from './merchantName';
import { otherSKUUnitsColumn } from './otherSkuUnits';
import { profileReviewStatusColumn } from './profileReviewStatus';
import { profileStatusColumn } from './profileStatus';
import { relatedClickRevenueColumn } from './relatedClickRevenue';
import { roasColumn } from './roas';
import { roasDirectColumn } from './roasDirect';

export const generateProfilesColumns = (): FlywheelTableColumnGroup<
  ProfileApiDataDetails,
  TableDataAdsManager
>[] => {
  return [
    {
      groupingHeaderI18nKey: I18nKey.ADS_MANAGER_PROFILES_TABLE_GROUP_DETAILS,
      columnsInGroup: [brandColumn],
    },
    {
      columnsInGroup: [
        merchantNameColumn,
        profileReviewStatusColumn,
        adGroupNameColumn,
        adgroupStatusColumn,
        adGroupReviewStatusColumn,
        campaignNameColumn,
        campaignTargetingTypeColumn,
        campaignDailyBudgetColumn,
        campaignTotalBudgetColumn,
        campaignStatusColumn,
      ],
    },
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_PROFILES_TABLE_GROUP_CHANNEL_SETTING,
      columnsInGroup: [
        profileStatusColumn,
        headlineColumn,
        clickUrlColumn,
        logoColumn,
      ],
    },
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_PROFILES_TABLE_GROUP_PERFORMANCE,
      columnsInGroup: [
        adSalesColumn,
        adSpendColumn,
        acosColumn,
        roasColumn,
        adConversionsRateColumn,
        adUnitsSoldColumn,
        impressionsColumn,
        clicksColumn,
        avgCostPerClickColumn,
        clickThroughRateColumn,
        adSalesDirectColumn,
        acosDirectColumn,
        roasDirectColumn,
        relatedClickRevenueColumn,
        advertisedSKUUnitsColumn,
        otherSKUUnitsColumn,
      ],
    },
  ];
};
