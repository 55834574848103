import { SelectMetricsType } from '../../../../../../lib/types/SKUSharedTypes';
import I18nKey from '../../../../../../lib/types/I18nKey';

export const EM_DASH = '--';

export const MAX_DAY_LIMIT = 365;
export const MAX_PERCENTAGE_VALUE = 9999;
export const MIN_PERCENTAGE_VALUE = -9999;
export const MAX_UNIT_VALUE = 99999;

export enum CreateQueryTabs {
  CREATE_QUERY = 'CreateQuery',
  QUERY_SNAPSHOP = 'QuerySnapshot',
}

export const MAP_CREATE_QUERY_TABS_TO_I18N: Record<CreateQueryTabs, I18nKey> = {
  [CreateQueryTabs.CREATE_QUERY]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_HEADING,
  [CreateQueryTabs.QUERY_SNAPSHOP]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_HEADING_QUERY_SNAPSHOT,
};

export const MAP_CREATE_QUERY_TABS_TO_NUMBER: Record<CreateQueryTabs, number> =
  {
    [CreateQueryTabs.CREATE_QUERY]: 1,
    [CreateQueryTabs.QUERY_SNAPSHOP]: 2,
  };

export const MAP_SELECTED_METRIC_TO_I18N: Record<SelectMetricsType, I18nKey> = {
  [SelectMetricsType.RATE_OF_SALE]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_RATE_OF_SALE,
  [SelectMetricsType.REORDER]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_REORDER,
  [SelectMetricsType.STOCK_OUTS]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_STOCK_OUT,
};

export const MAP_SELECTED_METRIC_TO_DESCRIPTION_I18N: Record<
  SelectMetricsType,
  I18nKey
> = {
  [SelectMetricsType.RATE_OF_SALE]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_RATE_OF_SALE,
  [SelectMetricsType.REORDER]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_REORDER,
  [SelectMetricsType.STOCK_OUTS]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_STOCK_OUT,
};

export enum RadioButtonValues {
  Manually = 'custom',
  ChnageRateDetection = 'ai',
}

export enum DefineMetricsPrimaryOptionsValues {
  VARIANCE = 'rate_of_sale_change_percent',
  UNIT_SOLD_PER_DAY = 'rate_of_sale_change_value',
}

export const AnomalyMerticsPrimaryOption = 'rate_of_sale_cpd';

export enum DefineMetricsSecondaryOptionsValues {
  CHANGES = 'cb',
  INCREASES = 'increases',
  DECREASES = 'decreases',
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
  EQUAL_TO = 'eq',
  BETWEEN = 'bt',
}

export const MAP_THRESHOLDDROPDOWN_OPTIONS_TO_MATH_SYMBOL: Record<
  DefineMetricsSecondaryOptionsValues,
  string
> = {
  [DefineMetricsSecondaryOptionsValues.CHANGES]: '±',
  [DefineMetricsSecondaryOptionsValues.INCREASES]: '+',
  [DefineMetricsSecondaryOptionsValues.DECREASES]: '-',
  [DefineMetricsSecondaryOptionsValues.GREATER_THAN]: '>',
  [DefineMetricsSecondaryOptionsValues.LESS_THAN]: '<',
  [DefineMetricsSecondaryOptionsValues.EQUAL_TO]: '=',
  [DefineMetricsSecondaryOptionsValues.BETWEEN]: '>',
};

export enum MetricQuerInputType {
  PERCENT = 'percent',
  NUMBER = 'number',
}

export const MAP_METRIC_INPUT_TYPE_TO_I18_MESSAGE: Record<
  MetricQuerInputType,
  I18nKey
> = {
  [MetricQuerInputType.PERCENT]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_ERROR_TOOLTIP_BETWEEN,
  [MetricQuerInputType.NUMBER]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_BETWEEN_MESSAGE_1,
};

export const MetricQueryAsPerMetricType: Record<SelectMetricsType, any> = {
  [SelectMetricsType.RATE_OF_SALE]: {
    primaryDropDownOptions: {
      [DefineMetricsPrimaryOptionsValues.VARIANCE]: {
        secondaryDropDownOptions: {
          [DefineMetricsSecondaryOptionsValues.CHANGES]: {
            inputType: MetricQuerInputType.PERCENT,
          },
          [DefineMetricsSecondaryOptionsValues.GREATER_THAN]: {
            inputType: MetricQuerInputType.PERCENT,
          },
          [DefineMetricsSecondaryOptionsValues.LESS_THAN]: {
            inputType: MetricQuerInputType.PERCENT,
          },
          [DefineMetricsSecondaryOptionsValues.EQUAL_TO]: {
            inputType: MetricQuerInputType.PERCENT,
          },
          [DefineMetricsSecondaryOptionsValues.BETWEEN]: {
            inputType: MetricQuerInputType.PERCENT,
          },
        },
      },
      [DefineMetricsPrimaryOptionsValues.UNIT_SOLD_PER_DAY]: {
        secondaryDropDownOptions: {
          [DefineMetricsSecondaryOptionsValues.CHANGES]: {
            inputType: MetricQuerInputType.NUMBER,
          },
          [DefineMetricsSecondaryOptionsValues.GREATER_THAN]: {
            inputType: MetricQuerInputType.NUMBER,
          },
          [DefineMetricsSecondaryOptionsValues.LESS_THAN]: {
            inputType: MetricQuerInputType.NUMBER,
          },
          [DefineMetricsSecondaryOptionsValues.EQUAL_TO]: {
            inputType: MetricQuerInputType.NUMBER,
          },
          [DefineMetricsSecondaryOptionsValues.BETWEEN]: {
            inputType: MetricQuerInputType.NUMBER,
          },
        },
      },
    },
  },
  [SelectMetricsType.REORDER]: {},
  [SelectMetricsType.STOCK_OUTS]: {},
};

export const RATE_OF_SALE_THRESHOLD_OPERAND = 'units_sold';
export const REORDER_AND_STOCK_OUT_OPERAND = 'days_of_inventory';
export const REORDER_OPERATOR = 'eq';
export const STOCK_OUT_OPERATOR = 'sm';

export enum PLACEHOLDERS_NUM {
  TEN = '10',
  FIFTEEN = '15',
  SIXTY = '60',
}

export const MAP_PRIMARY_OPTION_TO_I18N: Record<
  DefineMetricsPrimaryOptionsValues,
  I18nKey
> = {
  [DefineMetricsPrimaryOptionsValues.VARIANCE]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_VARIANCE,
  [DefineMetricsPrimaryOptionsValues.UNIT_SOLD_PER_DAY]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_UNIT_SOLD,
};

export const MAP_SECONDARY_OPTIONS_TO_I18N: Record<
  DefineMetricsSecondaryOptionsValues,
  I18nKey
> = {
  [DefineMetricsSecondaryOptionsValues.CHANGES]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_CHANGES,
  [DefineMetricsSecondaryOptionsValues.INCREASES]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_INCREASES,
  [DefineMetricsSecondaryOptionsValues.DECREASES]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_DECREASES,
  [DefineMetricsSecondaryOptionsValues.GREATER_THAN]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_GREATER_THAN,
  [DefineMetricsSecondaryOptionsValues.LESS_THAN]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_LESS_THAN,
  [DefineMetricsSecondaryOptionsValues.EQUAL_TO]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_EQUAL_TO,
  [DefineMetricsSecondaryOptionsValues.BETWEEN]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_BETWEEN,
};

export enum ThresholdOptions {
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
  EQUAL_TO = 'eq',
  BETWEEN = 'bt',
}

export const MAP_THRESHOLD_DROPDOWN_OPTIONS_TO_MATH_SYMBOL: Record<
  ThresholdOptions,
  string
> = {
  [ThresholdOptions.GREATER_THAN]: '>',
  [ThresholdOptions.LESS_THAN]: '<',
  [ThresholdOptions.EQUAL_TO]: '=',
  [ThresholdOptions.BETWEEN]: '>',
};

export const MAP_THRESHOLDDROPDOWN_OPTIONS_TO_I18N: Record<
  ThresholdOptions,
  I18nKey
> = {
  [ThresholdOptions.GREATER_THAN]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_GREATER_THAN,
  [ThresholdOptions.LESS_THAN]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_LESS_THAN,
  [ThresholdOptions.EQUAL_TO]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_EQUAL_TO,
  [ThresholdOptions.BETWEEN]:
    I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_BETWEEN,
};

export enum BetweenInputs {
  INPUT_1 = '1',
  INPUT_2 = '2',
}

export const MAP_BETWEEN_INPUTS_TO_I18_MESSAGE: Record<BetweenInputs, I18nKey> =
  {
    [BetweenInputs.INPUT_1]:
      I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_BETWEEN_MESSAGE_1,
    [BetweenInputs.INPUT_2]:
      I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_BETWEEN_MESSAGE_2,
  };

export const BETWEEN_2ND_SYMBOL = '& <';

export enum UnionDropdownOptions {
  AND = 'AND',
  OR = 'OR',
}

export const MAP_UNION_DROPDOWN_OPTIONS_TO_I18N: Record<
  UnionDropdownOptions,
  I18nKey
> = {
  [UnionDropdownOptions.AND]: I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_ADD,
  [UnionDropdownOptions.OR]: I18nKey.PRODUCT_INSIGHTS_CREATE_INSIGHTS_OR,
};

export enum OverInDaysDropdownOptions {
  Days_15 = '15',
  Days_30 = '30',
  Days_45 = '45',
  Days_60 = '60',
}

export const MAP_OVER_DROPDOWN_OPTIONS_TO_DOUBLE_NUMBER: Record<
  OverInDaysDropdownOptions,
  string
> = {
  [OverInDaysDropdownOptions.Days_15]: '30',
  [OverInDaysDropdownOptions.Days_30]: '60',
  [OverInDaysDropdownOptions.Days_45]: '90',
  [OverInDaysDropdownOptions.Days_60]: '120',
};

export interface ConditionType {
  order: string;
  primaryDropDown: boolean;
  primaryOption?: DefineMetricsPrimaryOptionsValues;
  secondaryDropdown: boolean;
  secondaryOption?: DefineMetricsSecondaryOptionsValues;
  inputField: boolean;
  inputValue1?: string;
  inputValue2?: string;
  unionInputValue?: string;
}

// TODO: remove this, its in sku shared type as CustomInsightAlertType
export enum AlertTypes {
  APP = 'app',
  EMAIL = 'email',
}

export enum DescriptionType {
  When = 'When',
  Over = 'Over',
  ApplyTo = 'ApplyTo',
  FlagProducts = 'FlagProducts',
}

export interface AllCreateQueryValueInterface {
  readonly selectedMetric?: SelectMetricsType;
  readonly determineMetric?: RadioButtonValues;
  readonly insightName?: string;
  readonly insightDescription?: string;
  readonly conditions?: ConditionType[];
  readonly overDropdownValue?: OverInDaysDropdownOptions;
  readonly showQueryThresholdOptions?: boolean;
  readonly selectedThresholdOption?: ThresholdOptions;
  readonly thresholdInputValue?: string;
  readonly thresholdInputValue2?: string;
  readonly receiveDailyReportToggle?: boolean;
  readonly emailReportSelected?: boolean;
  readonly metricsInputValue1?: string;
  readonly metricsInputValue2?: string;
  readonly userName?: string;
}
