import {
  KeywordActionColumns,
  KeywordRecommendationsData,
} from '../../../../../lib/types/KeywordAction';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import {
  ColumnAlign,
  TableCellV2,
  TableCellV2Type,
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../../../lib/types/I18nKey';

export const Cell: React.FC<KeywordRecommendationsData> = ({
  performance: { adConversions },
}) => {
  return (
    <TableCellV2
      tableCellData={{
        tableCellType: TableCellV2Type.Number,
        currentValue: adConversions,
      }}
      className="h-full flex justify-center"
    />
  );
};
Cell.displayName = 'KWACellAdConversions';

export const adConversions: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  {},
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_AD_CONVERSIONS_HEADER,
  accessorKey: KeywordActionColumns.AD_CONVERSIONS,
  sortable: true,
  size: 160,
  minSize: 160,
  align: ColumnAlign.Right,
  cell: ({ row: { original } }) => <Cell {...original} />,
};

export const adConversionsFilter = createNumericDataFieldFilter(
  KeywordActionColumns.AD_CONVERSIONS,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_AD_CONVERSIONS_HEADER,
  isValidNumber(0)
);
