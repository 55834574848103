import {
  AdgroupActionType,
  BadgeDisplayMode,
  BadgeType,
  SuggestedAdGroupV2,
} from '@teikametrics/tm-design-system';
import {
  AdType,
  CampaignTargetingType,
} from '../../../../lib/types/AOSharedTypes';
import { TargetSegments } from '../../../../lib/types/KeywordAction';
import { AssociatedCampaigns } from '../../../../lib/types/SKUSharedTypes';
import { ViewTrendLink } from '../../../advertisingOptimization/components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../advertisingOptimization/components/ViewTrends';
import { MerchantCountry } from '../../../../lib/types/Fam';

interface CampaignViewProps {
  readonly campaign: AssociatedCampaigns;
  readonly className?: string;
  readonly adgroupDetailsClassname?: string;
  readonly adType?: AdType;
  readonly merchant?: MerchantCountry;
  readonly associatedCampaigns?: AssociatedCampaigns[];
  readonly additionalOnTrendsClick?: () => void;
}

export const CampaignView: React.FC<CampaignViewProps> = (props) => {
  const { campaign, adgroupDetailsClassname, additionalOnTrendsClick } = props;
  return (
    <SuggestedAdGroupV2
      action={AdgroupActionType.None}
      classname="w-full"
      adGroupName={campaign.adGroupName}
      campaignName={campaign.campaignName}
      targetingType={{
        badgeType:
          campaign.targetingType === CampaignTargetingType.Auto
            ? BadgeType.CampaignTypeAuto
            : BadgeType.CampaignTypeManual,
        className: 'mr-4',
        badgeNameDisplayMode: BadgeDisplayMode.OnlyInitial,
      }}
      targetSegments={getTargetSegmentPills(campaign.targetSegments)}
      key={campaign.campaignId}
      showBorder={true}
      adgroupDetailsClassname={adgroupDetailsClassname}
      adGroups={props.associatedCampaigns?.map((c) => ({
        targetSegments: getTargetSegmentPills(c.targetSegments),
        adGroupName: c.adGroupName,
        campaignName: c.campaignName,
        rightSideElements: getViewTrendsButtton(
          ViewTrendsEntityType.ADGROUP,
          campaign,
          props.adType,
          props.merchant,
          additionalOnTrendsClick
        ),
      }))}
      rightSideElements={getViewTrendsButtton(
        ViewTrendsEntityType.CAMPAIGN,
        campaign,
        props.adType,
        props.merchant,
        additionalOnTrendsClick
      )}
    />
  );
};

export const getViewTrendsButtton = (
  entityType: ViewTrendsEntityType,
  campaign: AssociatedCampaigns,
  adType?: AdType,
  merchant?: MerchantCountry,
  additionalOnTrendsClick?: () => void
) => {
  return (
    <div className="relative ml-32">
      <ViewTrendLink
        dataTestId="product_catalog_campaigns_slideout"
        adType={adType}
        campaignId={campaign.campaignId}
        campaignName={campaign.campaignName}
        entityId={
          entityType === ViewTrendsEntityType.ADGROUP
            ? campaign.adGroupId
            : campaign.campaignId
        }
        entityName={
          entityType === ViewTrendsEntityType.ADGROUP
            ? campaign.adGroupName
            : campaign.campaignName
        }
        entityType={entityType}
        merchantCountryId={merchant?.merchantCountryId}
        merchantCountryName={merchant?.merchantName}
        merchantType={merchant?.merchantType}
        salesChannelId={merchant?.salesChannelId}
        targetingType={campaign.targetingType}
        additionalOnTrendsClick={additionalOnTrendsClick}
      />
    </div>
  );
};

export const getTargetSegmentPills = (targetSegments?: TargetSegments[]) =>
  targetSegments && targetSegments.length
    ? targetSegments.map((targetSegment, index) => ({
        badgeType: getSegmentBadgeType(targetSegment),
        dataTestId: `targetSegment_relatedCampaign_${index}`,
        className: 'mr-4',
      }))
    : [
        {
          badgeType: BadgeType.TagGeneric,
          className: 'mr-4',
        },
      ];

export const getSegmentBadgeType = (targetSegment: TargetSegments) => {
  switch (targetSegment.toLowerCase()) {
    case TargetSegments.Auto.toLowerCase():
      return BadgeType.TagAutomatic;
    case TargetSegments.Brand.toLowerCase():
      return BadgeType.TagBrand;
    case TargetSegments.Competitor.toLowerCase():
      return BadgeType.TagCompetitor;
    case TargetSegments.Generic.toLowerCase():
    case TargetSegments.Unknown.toLowerCase():
    default:
      return BadgeType.TagGeneric;
  }
};
