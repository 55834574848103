import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  CreativeType,
  SbAdsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { SB_ADS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { useIntl } from 'react-intl';

const CREATIVE_TYPE_I18NKEY_MAPPER: Record<CreativeType, I18nKey> = {
  [CreativeType.ProductCollection]:
    I18nKey.ADS_MANAGER_CREATIVE_TYPE_PRODUCT_COLLECTION,
  [CreativeType.Video]: I18nKey.ADS_MANAGER_CREATIVE_TYPE_VIDEO,
  [CreativeType.StoreSpotlight]:
    I18nKey.ADS_MANAGER_CREATIVE_TYPE_STORE_SPOTLIGHT,
};

export const RowCellElement: React.FC<SbAdsDetails> = ({
  creativeDetails,
  creativePerformance,
  creativeId,
  channelSettings,
}) => {
  const intl = useIntl();
  const creativeData: SbAdsDetails = {
    creativeDetails,
    creativePerformance,
    creativeId,
    channelSettings,
  };

  return makeTextColumn<SbAdsDetails>(
    ({ creativeDetails: { creativeType } }) =>
      creativeType &&
      intl.formatMessage({
        id:
          CREATIVE_TYPE_I18NKEY_MAPPER?.[creativeType] ??
          I18nKey.ADS_MANAGER_CREATIVE_SERVING_STATUS_UNKNOWN,
      })
  )(creativeData);
};
RowCellElement.displayName = 'RowCellElement';

export const creativeTypeColumn: FlywheelTableColumn<SbAdsDetails> = {
  columnName: SB_ADS_API_COLUMN_NAME.CreativeType,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_SB_ADS_TABLE_COLUMN_CREATIVE_TYPE,
  RowCellElement,
  gridColumnWidth: '240px',
};

export const creativeTypeFilter = () =>
  createMultiSelectDataFieldFilter(
    SB_ADS_API_COLUMN_NAME.CreativeType,
    I18nKey.ADS_MANAGER_SB_ADS_TABLE_COLUMN_CREATIVE_TYPE,
    [
      SelectFilterOption.CreativeTypeProductCollection,
      SelectFilterOption.CreativeTypeVideo,
      SelectFilterOption.CreativeTypeStoreSpotlight,
    ]
  );
