import capitalize from 'lodash/capitalize';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  MultiSelectOption,
  Placement,
  SelectState,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  AdGroupStatus,
  AdLevel,
  AdType,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
  TABLE_UNIQ_KEY,
  getAdGroupStatusOptionsBySelectedChannel,
  getTooltipContentForArchivedEntity,
  isAdGroupStatusArchived,
  isCampaignStatusArchived,
  isMerchantTypeSeller,
  isMerchantTypeVendor,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { getCurrentRowIndex } from './utils';

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  adGroupId,
  adGroupDetails,
  channelSettings,
  flywheelSettings,
  adGroupPerformance,
  isEditMode,
  pendingFields,
  salesChannel,
  selectedAdType,
  merchantType,
}) => {
  const data: AdGroupDetails = {
    adGroupId,
    adGroupDetails,
    channelSettings,
    flywheelSettings,
    adGroupPerformance,
    pendingFields,
  };
  const value = useSelector<WithTable<AdGroupDetails, any>, string>(
    ({ tableState }) =>
      tableSelectors.getCellValueSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
        EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
        TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const currentTargetStatus = channelSettings.status as AdGroupStatus;

  const currentRowIndex = useSelector<WithTable<AdGroupDetails>, number>(
    getCurrentRowIndex(data)
  );

  const dispatch = useDispatch();
  const intl = useIntl();

  const isEditAllowed =
    (isMerchantTypeSeller(merchantType) ||
      (isMerchantTypeVendor(merchantType) &&
        selectedAdType !== AdType.SponsoredDisplay)) &&
    channelSettings.isEditable;

  if (getIsEditAllowed(isEditMode, isEditAllowed, selectedAdType)) {
    const options = getAdGroupStatusOptionsBySelectedChannel(
      salesChannel,
      intl
    );

    return makeSelectColumn(
      (details: AdGroupDetails) => ({
        options,
        placeholder: '',
        value: value.toLowerCase(),
        className: 'w-110',
        isDirty:
          details.channelSettings.status?.toLowerCase() !== value.toLowerCase(),
        onChange: (inputValue: string) => {
          dispatch(
            tableActions.updateCell({
              tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
              columnName: ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
              rowId: data.adGroupId,
              value: inputValue,
              existingValue: currentTargetStatus.toLocaleLowerCase(),
            })
          );
        },
        state:
          isAdGroupStatusArchived(data.channelSettings.status) ||
          isCampaignStatusArchived(data.adGroupDetails.campaignStatus)
            ? SelectState.Disabled
            : SelectState.Default,
        showOnTop: currentRowIndex > 6,
      }),
      () => {
        const isAdGroupArchived =
          isAdGroupStatusArchived(channelSettings.status) ||
          isCampaignStatusArchived(adGroupDetails.campaignStatus);

        return {
          tooltipContent: getTooltipContent(isAdGroupArchived, intl),
          controlledTooltip: getControlledTooltipStatus(isAdGroupArchived),
          tooltipPlacement: Placement.Bottom,
          style: ContentStyle.Bold,
        };
      }
    )(data);
  }

  return makeTextColumn(
    (details: AdGroupDetails) =>
      data.channelSettings.status
        ? capitalize(details.channelSettings.status)
        : undefined,
    pendingFields?.includes(ADGROUPS_API_COLUMN_NAME.AdGroupStatus)
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

const getControlledTooltipStatus = (isAdGroupArchived: boolean) =>
  isAdGroupArchived ? ControlledTooltip.None : ControlledTooltip.Hide;

const getTooltipContent = (isAdGroupArchived: boolean, intl: IntlShape) => {
  if (isAdGroupArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.AdGroups],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_STATUS,
      }),
      intl
    );
  }
};

export const adgroupStatusColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_STATUS,
  RowCellElement,
  gridColumnWidth: '160px',
};

export const adgroupStatusFilter = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
) =>
  createMultiSelectDataFieldFilter(
    ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_STATUS,
    getCampaignStatusOptions(selectedSalesChannel, selectedAdType)
  );

export const getCampaignStatusOptions = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType
): MultiSelectOption[] => {
  switch (selectedSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      return [
        SelectFilterOption.AdgroupStatusEnabled,
        SelectFilterOption.AdgroupStatusDisabled,
      ];
    case FlywheelSalesChannel.Amazon:
      switch (selectedAdType) {
        case AdType.SponsoredProducts:
        case AdType.SponsoredBrands:
        case AdType.SponsoredDisplay:
          return [
            SelectFilterOption.CampaignStatusEnabled,
            SelectFilterOption.CampaignStatusPaused,
            SelectFilterOption.CampaignStatusArchived,
          ];
        // The fallback default should never occur hence safe to return [], can handle with default options in future
        default:
          return [];
      }
    /**
      The fallback default should never occur as Sales Channel will always fall in one of above 
      cases hence safe to return [], can handle with default options in future
    * 
    */
    default:
      return [];
  }
};

const getIsEditAllowed = (
  isEditMode: boolean,
  isEditAllowed: boolean,
  selectedAdType: AdType
) => {
  if (selectedAdType === AdType.SearchBrandAmplifier) {
    return isEditMode && isEditAllowed;
  }
  return isEditMode && isEditAllowed;
};
