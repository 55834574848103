import {
  Button,
  ButtonSize,
  ButtonVariant,
  CogLinearIcon,
  Icon,
  IconSize,
  KeywordIcon,
  Layout,
  Spinner,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutoNegateSettingsSlideover } from '../../../components/AutoNegateSettings/autoNegateSettingsSlideover';
import EmptyState from '../../../components/EmptyState';
import {
  useEmptyStateData,
  useMerchantsSyncing,
} from '../../../components/EmptyState/hooks';
import { PageType } from '../../../components/EmptyState/types';
import {
  PageHeaderContext,
  PageHeaderContextState,
} from '../../../containers/pageHeaderProvider';
import { MerchantCountry, MerchantType } from '../../../lib/types/Fam';
import I18nKey from '../../../lib/types/I18nKey';
import { sortMerchantsByNameV1 } from '../utils';
import { MCIDDashboardLink } from './MCIDDashboardLink';
import { RecommendationsCountContext } from './RecommendationsCountProvider';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../lib/utilities/intercom';

export enum KeywordLevel {
  Keywords = 'Keywords',
  NegativeKeywords = 'NegativeKeywords',
}

export const MAP_KEYWORD_TABS_TO_I18nKEY: Record<KeywordLevel, I18nKey> = {
  [KeywordLevel.Keywords]:
    I18nKey.AI_RECOMMENDED_KEYWORD_AD_SALES_KEYWORDS_TO_ADD,
  [KeywordLevel.NegativeKeywords]:
    I18nKey.AI_RECOMMENDED_KEYWORD_AD_SALES_NEGATIVE_KEYWORDS_TO_ADD,
};

export enum KeywordTabPaths {
  KeywordsToAdd = 'keywords-to-add',
  KeywordsToNegate = 'keywords-to-negate',
  PatToAdd = 'pat-to-add',
}

export interface MerchantCountryWithCount {
  readonly merchantCountryId: string;
  readonly merchantName: string;
  readonly salesChannelId?: string;
  readonly merchantType: MerchantType;
  readonly country: string;
  readonly extMerchantId?: string;
  readonly keywordCount: number;
  readonly negativeKeywordCount: number;
  readonly keywordLastUpdatedAt?: DateTime;
  readonly negativeKeywordLastUpdatedAt?: DateTime;
  readonly negativeKeywordUserLastUpdatedAt?: DateTime;
  readonly smartCampaignCount: number;
  readonly nonSmartCampaignCount: number;
  readonly byPerformanceCount?: number;
  readonly byChannelCount?: number;
  readonly negativeSmartCampaignCount?: number;
  readonly negativeNonSmartCampaignCount?: number;
  readonly negativeByPerformanceCount?: number;
  readonly negativeByChannelCount?: number;
}

type KeywordsDashboard2Props = {
  merchantCountriesWithRecommendationCount: MerchantCountry[];
  dataTestId?: string;
};

export const KeywordsDashboard2: React.FC<KeywordsDashboard2Props> = ({
  merchantCountriesWithRecommendationCount,
}) => {
  const intl = useIntl();
  const [TITLE] = [I18nKey.KEYWORD_ACTIONS_TITLE].map((id) =>
    intl.formatMessage({ id })
  );

  const { recommendationsCount, isRecommendationsCountLoading } = useContext(
    RecommendationsCountContext
  );

  const [openAutoNegateSettingsSlideover, setOpenAutoNegateSettingsSlideover] =
    useState(false);

  const merchantsSyncing = useMerchantsSyncing();

  const pageData = useEmptyStateData(
    PageType.KeywordTargetActionsV2,
    merchantsSyncing.isProductConnected,
    merchantsSyncing.isAdsConnected
  );

  const { updatePageHeaderData } =
    useContext<PageHeaderContextState>(PageHeaderContext);

  useEffect(() => {
    updatePageHeaderData({
      title: (
        <div className="flex items-center font-normal">
          <span className="font-semibold">{TITLE}</span>
        </div>
      ),
      titleString: TITLE,
      dataTestId: 'keyword_actions_v2_dashboard',
    });
  }, []);

  useEffect(() => {
    if (openAutoNegateSettingsSlideover) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [openAutoNegateSettingsSlideover]);

  if (!merchantsSyncing.isMerchantSyncLoaded || isRecommendationsCountLoading) {
    return <Spinner />;
  }

  const merchantWithCountriesHasCount =
    merchantCountriesWithRecommendationCount.some((merchantCountry) => {
      const currentRecommendation = recommendationsCount.find(
        (recommendation) =>
          recommendation.merchantCountryId === merchantCountry.merchantCountryId
      );
      return currentRecommendation?.data.some((item) => item.count > 0);
    });

  const allMerchantWithCountriesHasCount =
    merchantCountriesWithRecommendationCount.every((merchantCountry) => {
      const currentRecommendation = recommendationsCount.find(
        (recommendation) =>
          recommendation.merchantCountryId === merchantCountry.merchantCountryId
      );
      return currentRecommendation?.data.some((item) => item.count > 0);
    });

  if (!pageData.isVisible) {
    return (
      <EmptyState
        title={TITLE}
        isInSync={merchantsSyncing.isInSync}
        pageType={PageType.KeywordTargetActions}
        merchantsSyncing={merchantsSyncing}
      />
    );
  }

  const openAutoNegateSlideover = () => {
    setOpenAutoNegateSettingsSlideover(true);
  };

  return (
    <Layout classNames="mt-0">
      <AutoNegateSettingsSlideover
        isOpen={openAutoNegateSettingsSlideover}
        onClose={() => setOpenAutoNegateSettingsSlideover(false)}
      />
      <main className="mx-16 mt-8">
        <div className="flex flex-col gap-32 w-3/4 mx-auto">
          <div className="flex justify-between">
            <div className="flex gap-8">
              <Icon
                size={IconSize.Large}
                svg={KeywordIcon}
                className="text-grey-400 text-size-20 mr-6 mb-4 w-24 h-24"
              />
              <Typography
                size={TypographySize.xl}
                lineHeight={TypographyLineHeight.tight}
                weight={TypographyWeight.semibold}
              >
                <FormattedMessage
                  id={I18nKey.AI_RECOMMENDED_KEYWORD_AD_SALES_DASHBOARD_TITLE}
                />
              </Typography>
            </div>
            <Button
              size={ButtonSize.Large}
              variant={ButtonVariant.BlackAndWhiteBorder}
              svgIcon={CogLinearIcon}
              label={intl.formatMessage({
                id: I18nKey.KWA_AUTO_NEGATE_SETTINGS_HEADER,
              })}
              onClick={openAutoNegateSlideover}
              className="h-32"
            />
          </div>
          {merchantWithCountriesHasCount && (
            <div className="flex flex-col gap-8">
              <Typography
                size={TypographySize.lg}
                lineHeight={TypographyLineHeight.tight}
                weight={TypographyWeight.semibold}
              >
                {intl.formatMessage({
                  id: I18nKey.AI_RECOMMENDED_KEYWORD_AD_SALES_DASHBOARD_READY_TO_REVIEW,
                })}
              </Typography>
              <div className="flex flex-col border-solid border border-grey-200 rounded-4">
                {merchantCountriesWithRecommendationCount
                  .filter((merchant) => {
                    const currentMerchant = recommendationsCount.find(
                      (recommendation) =>
                        recommendation.merchantCountryId ===
                        merchant.merchantCountryId
                    );
                    return (
                      !isNil(currentMerchant) &&
                      currentMerchant?.data.some((item) => item.count > 0)
                    );
                  })
                  .sort(sortMerchantsByNameV1)
                  .map((merchant) => {
                    return (
                      <MCIDDashboardLink
                        merchant={merchant}
                        key={merchant.merchantCountryId}
                      />
                    );
                  })}
              </div>
            </div>
          )}
          {!allMerchantWithCountriesHasCount && (
            <div className="flex flex-col gap-8">
              <Typography
                size={TypographySize.lg}
                lineHeight={TypographyLineHeight.tight}
                weight={TypographyWeight.semibold}
              >
                {intl.formatMessage({
                  id: I18nKey.AI_RECOMMENDED_KEYWORD_AD_SALES_DASHBOARD_NO_RECOMMENDATIONS,
                })}
              </Typography>
              <div className="flex flex-col border-solid border border-grey-200 rounded-4">
                {merchantCountriesWithRecommendationCount
                  .filter((merchant) => {
                    const currentMerchant = recommendationsCount.find(
                      (recommendation) =>
                        recommendation.merchantCountryId ===
                        merchant.merchantCountryId
                    );

                    return (
                      isNil(currentMerchant) ||
                      currentMerchant?.data.every((item) => item.count === 0)
                    );
                  })
                  .sort(sortMerchantsByNameV1)
                  .map((merchant) => {
                    return (
                      <MCIDDashboardLink
                        merchant={merchant}
                        key={merchant.merchantCountryId}
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
};

KeywordsDashboard2.displayName = 'KeywordsDashboard2';
