import {
  AdsManagerAction,
  SET_FLYWHEEL_SALES_CHANNELS,
  SET_SELECTED_SALES_CHANNEL,
} from './actions';
import { AdsManagerState } from './types';

export const initialState: AdsManagerState = {
  selectedSalesChannel: '',
  flywheelSalesChannels: [],
};

const reducer = (
  state: AdsManagerState = initialState,
  action: AdsManagerAction
): AdsManagerState => {
  switch (action.type) {
    case SET_SELECTED_SALES_CHANNEL:
      return {
        ...state,
        selectedSalesChannel: action.payload,
      };
    case SET_FLYWHEEL_SALES_CHANNELS:
      return {
        ...state,
        flywheelSalesChannels: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
