import { TargetInfo } from '../../lib/types/CampaignFlexibilitySharedTypes';
import React, { useState } from 'react';
import { getTargetSegmentPills } from '../../modules/campaignFlexibility/components/utils/keywordUtils';
import {
  AdgroupActionType,
  CrossMiniIcon,
  Icon,
  IconSize,
  SuggestedAdgroup,
  TextLink,
} from '@teikametrics/tm-design-system';
import first from 'lodash/first';
import { AdGroupInfoV2 } from '../../lib/types/KeywordAction';
import { KeywordsForAdGroupModal } from './KeywordsForAdGroupModal';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/types/I18nKey';
import { KeywordType } from '../../modules/advertisingOptimization/containers/adsManager/createKeywords/createKeywordsMenu';

export interface GroupKeywordRowProps {
  readonly keywordType: KeywordType;
  readonly keywords: TargetInfo[];
  readonly dataTestId?: string;
  readonly onDelete: (adGroupId: string, targetId?: string) => void;
}

export const GroupByAdGroupRow: React.FC<GroupKeywordRowProps> = ({
  keywordType,
  keywords,
  dataTestId,
  onDelete,
}) => {
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();
  const adGroup: AdGroupInfoV2 = first(keywords)?.adgroupInfo;

  return (
    <>
      {showModal && (
        <KeywordsForAdGroupModal
          keywordType={keywordType}
          keywords={keywords}
          adGroup={adGroup}
          onClose={() => setShowModal(false)}
          onDeleteKeyword={onDelete}
        />
      )}
      <div className="border-b border-solid border-grey-200 py-8 w-full">
        <div
          className="flex flex-col items-start"
          data-test-id={`${dataTestId}_TagRow`}
        >
          <div className="flex gap-16 justify-between w-full">
            <SuggestedAdgroup
              action={AdgroupActionType.None}
              classname="min-w-0 ml-0 overflow-hidden"
              adGroupName={adGroup.name}
              targetSegments={getTargetSegmentPills(adGroup.targetSegments)}
            />
            <Icon
              size={IconSize.Small}
              svg={CrossMiniIcon}
              onClick={() => onDelete(adGroup.id)}
              className={classNames(
                'text-grey-500 min-w-20 mt-2 cursor-pointer'
              )}
            />
          </div>
        </div>
        <div className="py-4">
          <TextLink
            className="text-grey-900 leading-tight"
            onClick={() => setShowModal(true)}
            textLabel={intl.formatMessage(
              {
                id: I18nKey.CREATE_KEYWORDS_PLACEHOLDER_KEYWORDS_COUNT,
              },
              {
                keywordType,
                count: keywords.length,
              }
            )}
            dataTestId={`Edit_keywords`}
          />
        </div>
      </div>
    </>
  );
};
