import classNames from 'classnames';
import React from 'react';

export interface BlogProps {
  readonly blogImage: string;
  readonly blogHeader: string;
  readonly blogDescription: string;
  readonly blogUrl: string;
}

export const Blog: React.FC<BlogProps> = (props) => {
  const { blogImage, blogHeader, blogDescription, blogUrl } = props;

  return (
    <div className="flex flex-row items-start justify-center h-96 w-1/3">
      <div className="flex self-stretch h-full w-fit">
        <img src={blogImage} className="min-w-180 max-w-180 h-auto rounded-8" />
      </div>
      <div className="flex flex-col w-fit h-96 pl-16">
        <a
          href={blogUrl}
          className={classNames(
            'flex w-fit h-36 p-0 m-0 mb-4',
            'text-base leading-tight font-medium text-grey-800',
            'text-ellipsis overflow-hidden line-clamp-2'
          )}
          target="_blank"
          dangerouslySetInnerHTML={{ __html: blogHeader }}
          rel="noreferrer"
        ></a>
        <label
          className={classNames(
            'flex w-fit h-54 mt-4',
            'text-sm leading-tight font-normal text-grey-800',
            'text-ellipsis overflow-hidden line-clamp-3'
          )}
          dangerouslySetInnerHTML={{ __html: blogDescription }}
        ></label>
      </div>
    </div>
  );
};

Blog.displayName = 'Blog';
