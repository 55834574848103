import classNames from 'classnames';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CalendarWithDatePickerProps,
  ContentStyle,
  ControlledTooltip,
  ORIGINAL_DATE_FORMAT,
  Placement,
  TextInputProps,
  TextInputState,
  TypedTooltipProps,
  createDateFieldFilter,
  isValidDate,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeCalendarWithDatePickerColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  AdType,
  BudgetType,
  CampaignDetails,
  CampaignStatus,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import {
  AdLevelI8nKeyMapper,
  CAMPAIGN_DATE_FORMAT,
  CAMPAIGN_DISPLAY_DATE_FORMAT,
  CAMPAIGN_FILTER_DATE_FORMAT_MOMENT,
  END_DATE_PLACEHOLDER,
  TableDataAdsManager,
} from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getTooltipContentForArchivedEntity,
  isCampaignStatusArchived,
  isCampaignStatusCompleted,
  isDateSmallerThanToday,
  isFirstDateSmallerThanSecondDate,
} from '../utils';

const EMPTY_VALUE = 'EmptyValue';

const getEndDate = (
  salesChannel: FlywheelSalesChannel,
  changedEndDate: string,
  endDate: string | undefined
) => {
  if (salesChannel === FlywheelSalesChannel.Walmart) {
    return (changedEndDate ?? (endDate === '9999-12-30' ? '' : endDate)) || '';
  }
  return (changedEndDate ?? endDate) || '';
};

const isEditModeOrValidEndDate = (
  isEditMode: boolean,
  endDate: string | undefined
) => isEditMode || !isNil(endDate);

const isChangedDateOrEndDateValid = (
  changedEndDate: string,
  endDate: string | undefined
) => changedEndDate || endDate;

const getEndDateOrEmptyString = (endDate: string | undefined) => endDate || '';

const getEndDateValue = (localEndDate: DateTime, newValue: string) =>
  localEndDate.isValid
    ? DateTime.fromFormat(newValue, ORIGINAL_DATE_FORMAT).toFormat(
        CAMPAIGN_DATE_FORMAT
      )
    : newValue;

const setDate = (
  isEditMode: boolean,
  endDate: string,
  setValue: React.Dispatch<React.SetStateAction<string>>
) => {
  if (isEditModeOrValidEndDate(isEditMode, endDate)) {
    const endDateTime = DateTime.fromFormat(endDate, CAMPAIGN_DATE_FORMAT);
    endDateTime.isValid
      ? setValue(endDateTime.toFormat(ORIGINAL_DATE_FORMAT))
      : setValue(endDate);
  }
};

const isDateInvalid = (value: string) => {
  if (value === '') {
    return false;
  } else {
    const dateToBeChecked = DateTime.fromFormat(value, ORIGINAL_DATE_FORMAT);
    return !dateToBeChecked.isValid;
  }
};

const isNewEndDateSmallerThanToday = (value: string) =>
  isDateSmallerThanToday(DateTime.fromFormat(value, ORIGINAL_DATE_FORMAT));

const isNewEndDateSmallerThanStartDate = (
  value: string,
  startDateTime: DateTime
) =>
  isFirstDateSmallerThanSecondDate(
    DateTime.fromFormat(value, ORIGINAL_DATE_FORMAT),
    startDateTime
  );

const getTooltipContent = (
  endDateValue: string,
  intl: IntlShape,
  isCampaignCompletedForWalmart: boolean,
  isCampaignArchived: boolean,
  shouldEndDateBeExtended: boolean,
  changedEndDate: string,
  startDateTime: DateTime
) => {
  if (isCampaignCompletedForWalmart) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADVERTISING_OPTIMIZATION_CAMPAIGN_COMPLETED_TOOLTIP,
        })}
      </p>
    );
  }

  if (isCampaignArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_END_DATE,
      }),
      intl
    );
  }
  if (shouldEndDateBeExtended) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_EXTEND,
        })}
      </p>
    );
  }

  if (endDateValue === EMPTY_VALUE && !isNil(changedEndDate)) {
    return (
      <p className="w-108 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_BLANK,
        })}
      </p>
    );
  }

  if (isNewEndDateSmallerThanToday(endDateValue) && !isNil(changedEndDate)) {
    return (
      <p className="w-108 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_SMALLER_THAN_CURRENT_DATE,
        })}
      </p>
    );
  }

  if (
    isNewEndDateSmallerThanStartDate(endDateValue, startDateTime) &&
    !isNil(changedEndDate)
  ) {
    return (
      <p className="w-108 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_SMALLER_THAN_START_DATE,
        })}
      </p>
    );
  }

  if (isDateInvalid(endDateValue) && !isNil(changedEndDate)) {
    return (
      <p className="w-108 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_FORMAT,
        })}
      </p>
    );
  }
};

const getMinDate = (campaignDetails: CampaignDetails) => {
  if (campaignDetails.channelSettings.startDate) {
    const campaignStartDate = DateTime.fromISO(
      campaignDetails.channelSettings.startDate
    );
    return campaignStartDate > DateTime.local()
      ? campaignStartDate
      : DateTime.local();
  } else {
    return DateTime.local();
  }
};

const getAvailableDate = (
  changedEndDate: string,
  endDate: string | undefined,
  startDate: string
) => changedEndDate ?? (endDate || startDate || '');

const getTextInputValue = (value: string, intl: IntlShape) => {
  if (value === EMPTY_VALUE) {
    return '';
  } else if (value === '' || value === '9999-12-30') {
    return intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NO_END_DATE,
    });
  } else {
    return value;
  }
};

const getCurrentOrStartDate = (startDateTime: DateTime) => {
  const now = DateTime.local().startOf('day');
  return now > startDateTime ? now : startDateTime;
};

export const isEndDateChanged = (
  changedEndDate: string,
  endDate: string | undefined
) => {
  if (isNil(changedEndDate)) {
    return false;
  } else if (changedEndDate === '' && !isNil(endDate)) {
    return true;
  } else {
    return (
      DateTime.fromFormat(
        getEndDateOrEmptyString(endDate),
        ORIGINAL_DATE_FORMAT
      ).toString() !==
      DateTime.fromFormat(changedEndDate, CAMPAIGN_DATE_FORMAT).toString()
    );
  }
};

const getValueOrEmpltyValue = (value: string) =>
  value === '' ? EMPTY_VALUE : value;

export const handleExtendCampaignStatusValidations = (
  changedCampaignStatus: string,
  endDate: string | undefined,
  changedEndDate: string | undefined
) => {
  if (changedCampaignStatus !== CampaignStatus.Extend || !endDate) {
    return false;
  }

  const now = DateTime.local().startOf('day');
  let shouldEndDateBeExtended: boolean;
  if (changedEndDate === '') {
    // indefinite running campaign
    shouldEndDateBeExtended = false;
  } else if (isNil(changedEndDate)) {
    // User has updated campaign status to extended, but did not update end date
    const originalEndDateInDateTimeFormat = DateTime.fromFormat(
      endDate,
      CAMPAIGN_DATE_FORMAT
    );

    /*
     not a valid date if current date - original date > 0 days
              Now           Original      Desired Result
              14-05-2021    13-05-2021    Extend the date
              14-05-2021    14-05-2021    Original End date is valid
              14-05-2021    15-05-2021    Original End date is valid
     */
    shouldEndDateBeExtended =
      now.diff(originalEndDateInDateTimeFormat, 'days').days > 0;
  } else {
    // User has updated campaign status, and updated end date too
    const changedEndDateInDateTimeFormat = DateTime.fromFormat(
      changedEndDate,
      CAMPAIGN_DATE_FORMAT
    );

    /*
     not a valid date if current date - changed date > 0 days
              Now           New Changed Date      Desired Result
              14-05-2021    13-05-2021            Extend the date
              14-05-2021    14-05-2021            Changed date is valid
              14-05-2021    15-05-2021            Changed date is valid
     */
    shouldEndDateBeExtended =
      now.diff(changedEndDateInDateTimeFormat, 'days').days > 0;
  }
  return shouldEndDateBeExtended;
};

export const getTextInputState = (
  value: string,
  changedCampaignStatus: string,
  startDateTime: DateTime,
  endDateTime: DateTime | null,
  changedEndDate: string,
  endDate: string | undefined,
  shouldEndDateBeExtended: boolean
) => {
  let textState: TextInputState;

  if (value === EMPTY_VALUE || isDateInvalid(value)) {
    return TextInputState.Error;
  }

  if (shouldEndDateBeExtended) {
    textState = TextInputState.Error;
  } else if (endDateTime && startDateTime && !isNil(changedEndDate)) {
    const currentOrStartDate = getCurrentOrStartDate(startDateTime);
    textState =
      endDateTime.diff(currentOrStartDate, 'days').days < 0
        ? TextInputState.Error
        : TextInputState.Default;
  } else {
    textState = TextInputState.Default;
  }
  return textState;
};

const getControlledTooltipState = (
  value: string,
  startDateTime: DateTime,
  isOpenDatePicker: boolean,
  shouldEndDateBeExtended: boolean
) => {
  const showTooltip =
    isOpenDatePicker &&
    (shouldEndDateBeExtended ||
      isDateInvalid(value) ||
      isNewEndDateSmallerThanStartDate(value, startDateTime) ||
      isNewEndDateSmallerThanToday(value));

  return showTooltip ? ControlledTooltip.Show : ControlledTooltip.Hide;
};

const getEndDateLabel =
  (intl: IntlShape, salesChannel: FlywheelSalesChannel) =>
  ({ channelSettings: { endDate } }: CampaignDetails) => {
    if (
      salesChannel === FlywheelSalesChannel.Walmart &&
      endDate === '9999-12-30'
    ) {
      return intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NO_END_DATE,
      });
    }

    return endDate
      ? DateTime.fromFormat(endDate, CAMPAIGN_DATE_FORMAT).toFormat(
          CAMPAIGN_DISPLAY_DATE_FORMAT
        )
      : intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NO_END_DATE,
        });
  };

const getCalendarFooterButtonState = (
  salesChannel: FlywheelSalesChannel,
  maybeSponsoredBrands: AdType | undefined,
  campaignDetails: CampaignDetails
) =>
  salesChannel === FlywheelSalesChannel.Amazon &&
  maybeSponsoredBrands &&
  campaignDetails.channelSettings.budgetType === BudgetType.Lifetime
    ? ButtonState.Disabled
    : ButtonState.Enabled;

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, salesChannel, adTypes, ...campaignDetails }) => {
  const changedEndDate = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.EndDate
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const changedCampaignStatus = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.CampaignStatus
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const intl = useIntl();

  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);

  const campaignEndDate = getEndDate(
    salesChannel,
    changedEndDate,
    campaignDetails.channelSettings.endDate
  );

  const [value, setValue] = useState<string>(campaignEndDate);

  useEffect(() => {
    setDate(isEditMode, campaignEndDate, setValue);
  }, [isEditMode, changedEndDate, currentPage]);

  const dispatch = useDispatch();

  const updateCellValue = (newValue: string) => {
    const localEndDate = DateTime.fromFormat(newValue, ORIGINAL_DATE_FORMAT);
    dispatch(
      tableActions.updateCell({
        rowId: campaignDetails.campaignId,
        columnName: CAMPAIGNS_API_COLUMN_NAME.EndDate,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        existingValue: getEndDateOrEmptyString(campaignEndDate),
        value: getEndDateValue(localEndDate, newValue),
      })
    );
  };

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignDetails.campaignId]
  );

  const getEndDateProps = ({
    campaignId,
    channelSettings: { endDate, startDate },
  }: CampaignDetails) => {
    let startDateTime: DateTime = DateTime.fromFormat(
      startDate || '',
      CAMPAIGN_DATE_FORMAT
    );
    let endDateTime: DateTime | null = null;
    let shouldEndDateBeExtended = handleExtendCampaignStatusValidations(
      changedCampaignStatus,
      endDate,
      changedEndDate
    );

    if (isChangedDateOrEndDateValid(changedEndDate, endDate)) {
      endDateTime = DateTime.fromFormat(
        getAvailableDate(changedEndDate, endDate, startDate),
        CAMPAIGN_DATE_FORMAT
      );
    }

    const isCampaignArchived = isCampaignStatusArchived(
      campaignDetails.channelSettings.status
    );

    const isCampaignCompletedForWalmart =
      salesChannel === FlywheelSalesChannel.Walmart &&
      isCampaignStatusCompleted(
        campaignDetails.channelSettings.status,
        changedCampaignStatus as CampaignStatus
      );

    const textInputOnChange = (textInputValue: string) => {
      const formattedValue = getValueOrEmpltyValue(textInputValue);
      setValue(formattedValue);
      debouncedUpdateCellValue(formattedValue);
    };

    const isCampaignArchivedOrCompleted =
      isCampaignArchived || isCampaignCompletedForWalmart;

    const textInputProps: TextInputProps = {
      value: getTextInputValue(value, intl),
      placeholder: END_DATE_PLACEHOLDER,
      inputState: getTextInputState(
        value,
        changedCampaignStatus,
        startDateTime,
        endDateTime,
        changedEndDate,
        endDate,
        shouldEndDateBeExtended
      ),
      onChange: textInputOnChange,
      onClick: () => setIsOpenDatePicker(true),
      disabled: isCampaignArchivedOrCompleted,
      isDirty: isEndDateChanged(changedEndDate, endDate),
      dataTestId: 'end_date',
    };

    const onClickNoEndDate = () => {
      setIsOpenDatePicker(false);
      dispatch(
        tableActions.updateCell({
          rowId: campaignId,
          columnName: CAMPAIGNS_API_COLUMN_NAME.EndDate,
          tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
          existingValue: getEndDateOrEmptyString(endDate),
          value: '',
        })
      );
    };

    const tooltipProps: TypedTooltipProps = {
      tooltipContent: getTooltipContent(
        value,
        intl,
        isCampaignCompletedForWalmart,
        isCampaignArchived,
        shouldEndDateBeExtended,
        changedEndDate,
        startDateTime
      ),
      controlledTooltip: isCampaignArchivedOrCompleted
        ? ControlledTooltip.None
        : getControlledTooltipState(
            value,
            startDateTime,
            isOpenDatePicker,
            shouldEndDateBeExtended
          ),
      tooltipPlacement: isCampaignArchivedOrCompleted
        ? Placement.Bottom
        : Placement.Right,
      style: ContentStyle.Bold,
    };

    const calendarWithDatePickerProps: CalendarWithDatePickerProps = {
      currentDate: endDateTime || getMinDate(campaignDetails),
      highlightToday: endDateTime?.isValid || false,
      showCalendarViewForDate: endDateTime?.isValid ? endDateTime : undefined,
      onDateChange: (date: Date) => {
        setIsOpenDatePicker(false);
        dispatch(
          tableActions.updateCell({
            rowId: campaignId,
            columnName: CAMPAIGNS_API_COLUMN_NAME.EndDate,
            tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
            existingValue: getEndDateOrEmptyString(endDate),
            value: DateTime.fromJSDate(date).toFormat(CAMPAIGN_DATE_FORMAT),
          })
        );
      },
      onClickOutside: () => setIsOpenDatePicker(false),
      minDate: getMinDate(campaignDetails),
      dataTestId: 'end_date_calender',
    };

    const maybeSponsoredBrands = adTypes.find(
      (adType) => adType === AdType.SponsoredBrands
    );

    const calendarFooter = (
      <div className={classNames('flex flex-row justify-end', 'pt-12')}>
        <Button
          variant={ButtonVariant.BlackAndWhiteBorder}
          size={ButtonSize.Small}
          label={intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_SET_NO_END_DATE,
          })}
          onClick={onClickNoEndDate}
          state={getCalendarFooterButtonState(
            salesChannel,
            maybeSponsoredBrands,
            campaignDetails
          )}
          dataTestId={'ao_setNoEndDate'}
        />
      </div>
    );

    return {
      textInputProps,
      calendarWithDatePickerProps,
      isOpenDatePicker,
      tooltipProps,
      calendarFooter,
    };
  };

  if (isEditMode) {
    return makeCalendarWithDatePickerColumn<CampaignDetails>(getEndDateProps)(
      campaignDetails
    );
  }

  return makeTextColumn<CampaignDetails>(
    getEndDateLabel(intl, salesChannel),
    campaignDetails.pendingFields?.includes(CAMPAIGNS_API_COLUMN_NAME.EndDate)
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const endDateColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.EndDate,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_END_DATE,
  RowCellElement,
  gridColumnWidth: '160px',
};

export const endDateFilter = createDateFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.EndDate,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_END_DATE,
  isValidDate(CAMPAIGN_FILTER_DATE_FORMAT_MOMENT)
);
