import { TmIndexDataResponse } from '../../../modules/compass';

export const getBenchmarkDataResponse = (): TmIndexDataResponse => {
  return {
    currency: 'USD',
    elements: [
      {
        salesChannelId: '820d060b-1d53-4aab-99c2-03a12e3dcf29',
        tacos: {
          graphDataPoints: {
            daily: [
              {
                date: '2023-10-18',
                totalValue: '13.114',
              },
              {
                date: '2023-10-17',
                totalValue: '9.114',
              },
              {
                date: '2023-10-16',
                totalValue: '.1114',
              },
              {
                date: '2023-10-15',
                totalValue: '7.114',
              },
              {
                date: '2023-10-14',
                totalValue: '4.114',
              },
              {
                date: '2023-10-13',
                totalValue: '.5114',
              },
              {
                date: '2023-10-12',
                totalValue: '6.114',
              },
            ],
          },
        },
        roas: {
          graphDataPoints: {
            daily: [
              {
                date: '2023-10-18',
                totalValue: '5.678',
              },
              {
                date: '2023-10-17',
                totalValue: '4.678',
              },
              {
                date: '2023-10-16',
                totalValue: '2.678',
              },
              {
                date: '2023-10-15',
                totalValue: '3.678',
              },
              {
                date: '2023-10-14',
                totalValue: '1.678',
              },
              {
                date: '2023-10-13',
                totalValue: '6.678',
              },
              {
                date: '2023-10-12',
                totalValue: '7.678',
              },
            ],
          },
        },
        acos: {
          graphDataPoints: {
            daily: [
              {
                date: '2023-10-18',
                totalValue: '0.4645',
              },
              {
                date: '2023-10-17',
                totalValue: '0.5645',
              },
              {
                date: '2023-10-16',
                totalValue: '0.1645',
              },
              {
                date: '2023-10-15',
                totalValue: '0.7645',
              },
              {
                date: '2023-10-14',
                totalValue: '0.8645',
              },
              {
                date: '2023-10-13',
                totalValue: '0.9645',
              },
              {
                date: '2023-10-12',
                totalValue: '0.3645',
              },
            ],
          },
        },
        costPerClick: {
          graphDataPoints: {
            daily: [
              {
                date: '2023-10-18',
                totalValue: '45.2',
              },
              {
                date: '2023-10-17',
                totalValue: '27.3',
              },
              {
                date: '2023-10-16',
                totalValue: '4.3',
              },
              {
                date: '2023-10-15',
                totalValue: '1.3',
              },
              {
                date: '2023-10-14',
                totalValue: '5.6',
              },
              {
                date: '2023-10-13',
                totalValue: '10.3',
              },
              {
                date: '2023-10-12',
                totalValue: '50.4',
              },
            ],
          },
        },
      },
      {
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        tacos: {
          graphDataPoints: {
            daily: [
              {
                date: '2023-10-18',
                totalValue: '9.114',
              },
              {
                date: '2023-10-17',
                totalValue: '5.114',
              },
              {
                date: '2023-10-16',
                totalValue: '3.1114',
              },
              {
                date: '2023-10-15',
                totalValue: '6.114',
              },
              {
                date: '2023-10-14',
                totalValue: '8.114',
              },
              {
                date: '2023-10-13',
                totalValue: '2.5114',
              },
              {
                date: '2023-10-12',
                totalValue: '7.114',
              },
            ],
          },
        },
        roas: {
          graphDataPoints: {
            daily: [
              {
                date: '2023-10-18',
                totalValue: '8.678',
              },
              {
                date: '2023-10-17',
                totalValue: '9.678',
              },
              {
                date: '2023-10-16',
                totalValue: '10.678',
              },
              {
                date: '2023-10-15',
                totalValue: '7.678',
              },
              {
                date: '2023-10-14',
                totalValue: '3.678',
              },
              {
                date: '2023-10-13',
                totalValue: '4.678',
              },
              {
                date: '2023-10-12',
                totalValue: '3.678',
              },
            ],
          },
        },
        acos: {
          graphDataPoints: {
            daily: [
              {
                date: '2023-10-18',
                totalValue: '0.1645',
              },
              {
                date: '2023-10-17',
                totalValue: '0.2645',
              },
              {
                date: '2023-10-16',
                totalValue: '0.4645',
              },
              {
                date: '2023-10-15',
                totalValue: '0.3645',
              },
              {
                date: '2023-10-14',
                totalValue: '0.6645',
              },
              {
                date: '2023-10-13',
                totalValue: '0.8645',
              },
              {
                date: '2023-10-12',
                totalValue: '0.3645',
              },
            ],
          },
        },
        costPerClick: {
          graphDataPoints: {
            daily: [
              {
                date: '2023-10-18',
                totalValue: '35.2',
              },
              {
                date: '2023-10-17',
                totalValue: '17.3',
              },
              {
                date: '2023-10-16',
                totalValue: '14.3',
              },
              {
                date: '2023-10-15',
                totalValue: '4.3',
              },
              {
                date: '2023-10-14',
                totalValue: '15.6',
              },
              {
                date: '2023-10-13',
                totalValue: '20.3',
              },
              {
                date: '2023-10-12',
                totalValue: '30.4',
              },
            ],
          },
        },
      },
    ],
  };
};
