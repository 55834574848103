import { FAMApiClient } from '../../lib/clients/FAMApiClient';
import { MetadataTags } from '../../lib/types/Fam';

export const getMetadataTagsStatus = (
  famApiClient: FAMApiClient,
  accountId: string,
  tag: MetadataTags
) =>
  famApiClient
    .getMetadataTags(accountId, tag)
    .then((resp) => {
      if (resp[0] && resp[0].value === 'enabled') {
        return true;
      }
    })
    .catch(() => {
      return false;
    });
