import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  AdLevel,
  AdType,
  AddedBy,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TARGETS_API_COLUMN_NAME } from '../utils';
import {
  makeTextColumn,
  makeTextWithIconColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import { IntlShape, useIntl } from 'react-intl';
import {
  ArrowBendLeftIcon,
  CheckmarkCircleIcon,
  IconPlacement,
  LightningIcon,
  SparkleIcon,
  UserIcon,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { ReactNode } from 'react';

const AddedByI8nKeyMapper: Record<string, I18nKey> = {
  [AddedBy.FlywheelAutoNegate]:
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_AUTO_NEGATE,
  [AddedBy.CampaignCreatorV1]:
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_AI_REC_SEPARATOR,
  [AddedBy.FlywheelAutomated]:
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_AI_REC_SEPARATOR,
  [AddedBy.FlywheelAccepted]:
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_TRE_REC_SEPARATOR,
  [AddedBy.AdsManagerOnDemand]:
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_ON_DEMAND_SEPARATOR,
  [AddedBy.FlywheelOnDemand]:
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_ON_DEMAND_SEPARATOR,
  [AddedBy.CampaignCreatorOnDemand]:
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_FW_ON_DEMAND_SEPARATOR,
  [AddedBy.ChatGPT]: I18nKey.ADS_MANAGER_TARGET_ADDEDBY_CHAT_GPT,
  [AddedBy.CampaignCreatorAiRecommended]:
    I18nKey.ADS_MANAGER_TARGET_ADDEDBY_TRE_REC_SEPARATOR,
  [AddedBy.External]: I18nKey.GENERIC_EXTERNAL,
};

const getAddedByText = (intl: IntlShape, addedBy?: AddedBy) => {
  const separator = {
    separator: <br />,
  };

  if (isNull(addedBy) || isUndefined(addedBy)) {
    return {
      icon: ArrowBendLeftIcon,
      text: intl.formatMessage<ReactNode>({ id: I18nKey.GENERIC_EXTERNAL }),
    };
  }

  if (
    addedBy === AddedBy.FlywheelAutomated ||
    addedBy === AddedBy.CampaignCreatorV1 ||
    addedBy === AddedBy.FlywheelAutoNegate
  ) {
    return {
      icon: LightningIcon,
      text: intl.formatMessage<ReactNode>(
        { id: AddedByI8nKeyMapper[addedBy] },
        separator
      ),
    };
  } else if (
    addedBy === AddedBy.FlywheelAccepted ||
    addedBy === AddedBy.CampaignCreatorAiRecommended
  ) {
    return {
      icon: CheckmarkCircleIcon,
      text: intl.formatMessage<ReactNode>(
        { id: AddedByI8nKeyMapper[addedBy] },
        separator
      ),
    };
  } else if (
    addedBy === AddedBy.FlywheelOnDemand ||
    addedBy === AddedBy.AdsManagerOnDemand ||
    addedBy === AddedBy.CampaignCreatorOnDemand
  ) {
    return {
      icon: UserIcon,
      text: intl.formatMessage<ReactNode>(
        { id: AddedByI8nKeyMapper[addedBy] },
        separator
      ),
    };
  } else if (addedBy === AddedBy.ChatGPT) {
    return {
      icon: SparkleIcon,
      text: intl.formatMessage<ReactNode>(
        { id: AddedByI8nKeyMapper[addedBy] },
        separator
      ),
    };
  }
};

export const RowCellElement: React.FC<TargetsDetails> = (targetsDetails) => {
  const intl = useIntl();
  const {
    targetDetails: { addedBy },
  } = targetsDetails;

  const textAndIcon = getAddedByText(intl, addedBy);

  if (!isUndefined(textAndIcon)) {
    return makeTextWithIconColumn(
      () => {
        return textAndIcon?.text as any;
      },
      textAndIcon?.icon,
      '',
      undefined,
      undefined,
      IconPlacement.Left
    )(targetsDetails);
  }

  return makeTextColumn(() => {
    return undefined;
  })(targetsDetails);
};

export const addedByColumn: FlywheelTableColumn<TargetsDetails> = {
  columnName: TARGETS_API_COLUMN_NAME.AddedBy,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_ADDED_BY,
  RowCellElement,
  gridColumnWidth: '144px',
};

export const addedByFilter = (adType: AdType, adLevel: AdLevel) =>
  createMultiSelectDataFieldFilter(
    TARGETS_API_COLUMN_NAME.AddedBy,
    I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_ADDED_BY,
    getFilterOptions(adType, adLevel)
  );

const getFilterOptions = (adType: AdType, adLevel: AdLevel) => {
  switch (adType) {
    case AdType.SponsoredProducts:
      return adLevel === AdLevel.KeywordTargets
        ? [
            SelectFilterOption.External,
            SelectFilterOption.ChatGPT,
            SelectFilterOption.FlywheelAccepted,
            SelectFilterOption.FlywheelOnDemand,
            SelectFilterOption.FlywheelAutomated,
            SelectFilterOption.FlywheelAutoNegate,
          ]
        : [SelectFilterOption.External, SelectFilterOption.FlywheelOnDemand];
    case AdType.SponsoredBrands:
    case AdType.SponsoredDisplay:
    default:
      return [SelectFilterOption.External, SelectFilterOption.FlywheelOnDemand];
  }
};
