import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';

interface ModalFooterProps {
  goBack?: () => void;
  onConnectAds: () => Promise<void>;
  updatingProfile: boolean;
  hasSelectedMerchants: boolean;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  goBack,
  onConnectAds,
  updatingProfile,
  hasSelectedMerchants,
}) => {
  const intl = useIntl();
  const [buttonState, setButtonState] = useState(ButtonState.Disabled);
  const [BACK, CONNECT] = [I18nKey.BACK, I18nKey.CONNECT].map((id) =>
    intl.formatMessage({ id })
  );

  const getConnectButtonState = () => {
    const connectButtonState = hasSelectedMerchants
      ? ButtonState.Active
      : ButtonState.Disabled;
    setButtonState(updatingProfile ? ButtonState.Loading : connectButtonState);
  };

  useEffect(() => {
    getConnectButtonState();
  }, [hasSelectedMerchants, updatingProfile]);

  return (
    <div className="flex justify-end mx-8">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.BlackAndWhite}
        label={BACK}
        svgIcon={ArrowLeftIcon}
        onClick={goBack}
        dataTestId={`flash1_amz_select_ads_profile_back`}
        className="mr-12"
      />
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.Primary}
        label={CONNECT}
        onClick={onConnectAds}
        state={buttonState}
      />
    </div>
  );
};
