import { AllSalesChannel } from '../../../../../lib/types/Fam';

export const EST_AD_GROSS_MARGIN_TABLE_ADS_ID =
  'EST_AD_GROSS_MARGIN_TABLE_ADS_ID';
export const ADS_MANAGER_CAMPAIGNS_TABLE_ID = 'ADS_MANAGER_CAMPAIGNS_TABLE_ID';
export const ADS_MANAGER_ADGROUP_TABLE_ID = 'ADS_MANAGER_ADGROUP_TABLE_ID';
export const ADS_MANAGER_PROFILES_TABLE_ID = 'ADS_MANAGER_PROFILES_TABLE_ID';
export const ADS_MANAGER_TARGETS_TABLE_ID = 'ADS_MANAGER_TARGETS_TABLE_ID';
export const ADS_MANAGER_AD_ITEMS_TABLE_ID = 'ADS_MANAGER_ADITEMS_TABLE_ID';
export const ADS_MANAGER_SB_ADS_TABLE_ID = 'ADS_MANAGER_SB_ADS_TABLE_ID';
export const ADS_MANAGER_SV_ADS_TABLE_ID = 'ADS_MANAGER_SV_ADS_TABLE_ID';

export interface AdsManagerState {
  readonly selectedSalesChannel: string;
  readonly flywheelSalesChannels: AllSalesChannel[];
}
