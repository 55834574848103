import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { CAMPAIGNS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = (props) =>
  makeNumericColumn<ProfileApiDataDetails>(
    ({ profilePerformance: { roasTotal } }) => {
      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        undefined,
        roasTotal
      );

      return performanceMetricValue?.toString();
    },
    false,
    true
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const roasColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.ROAS,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_TOTAL_ROAS,
  RowCellElement,
  gridColumnWidth: '100px',
  className: 'text-right',
};

export const roasFilterProfiles = createNumericDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.ROAS,
  I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_TOTAL_ROAS,
  isValidNumber(0)
);
