import {
  BulkEditValues,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CheckmarkIcon,
  CrossMiniIcon,
  LinkBrokenIcon,
  TableActionRowV2,
  TableActionRowV2Props,
  TableEmptyStateProps,
  ToastVariant,
  Type,
} from '@teikametrics/tm-design-system';
import { AxiosResponse } from 'axios';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ViewSkuAndKeywordSlideOutV2 } from '../../../../components/ViewSkuAndKeywordSlideoutV2';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../../containers/notificationProvider';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';
import { tableSelectors } from '../../../../containers/table/ducks';
import {
  TableDisplayState,
  WithTable,
} from '../../../../containers/table/ducks/types';
import {
  tableActions,
  tableThunks,
} from '../../../../containers/tableV2/ducks';
import { FlywheelTableV2 } from '../../../../containers/tableV2/flywheelTable';
import { getCurrentAccountFromContext } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { LevelType } from '../../../../core/campaignFlexibility/types';
import { createAOApiClient } from '../../../../lib/clients/AOApiClient';
import { HttpCode } from '../../../../lib/clients/utils';
import {
  AdType,
  FlywheelSalesChannel,
  MatchType,
} from '../../../../lib/types/AOSharedTypes';
import { MerchantCountry } from '../../../../lib/types/Fam';
import { Filter } from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  ActionType,
  CampaignInfo,
  DropDownType,
  KeywordActionColumns,
  KeywordActionSubmissionResponse,
  KeywordActionSubmissionResponseStatus,
  KeywordAdgroupInfo,
  KeywordRecommendationsData,
  RecommendationsAction,
  SelectedRecommendationsV2,
  TargetingType,
} from '../../../../lib/types/KeywordAction';
import { OptimizelyFlags } from '../../../../lib/types/OptimizelyFlags';
import { AMAZON_SALES_CHANNEL_ID } from '../../../../lib/types/SalesChannels';
import { CurrencyCode } from '../../../../lib/utilities/currency';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../lib/utilities/intercom';
import { ViewTrends } from '../../../advertisingOptimization/components/ViewTrends';
import { ViewTrendsContext } from '../../../advertisingOptimization/components/ViewTrends/ViewTrendsProvider';
import { SelectedTrendsProduct } from '../../../products/containers/skuCatalog';
import { PerformanceSlideover } from '../../../products/containers/skuCatalog/performanceSlideover';
import { PerformanceSlideoverContextProvider } from '../../../products/containers/skuCatalog/performanceSlideover/performanceSlideoverContextProvider';
import {
  getKeywordsToNegateFiltersFromLocalStorage,
  setKeywordsToNegateFiltersInLocalStorage,
} from '../../../products/containers/utils/localStorage';
import {
  getUpdatedAdgroupsFromLocalStorage,
  storeSelectedColumnsRecommendationsToLocalStorage,
  storeUpdatedAdgroupsToLocalStorage,
} from '../../storageUtils';
import { DEFAULT_CURRENCY, KeywordLevel } from '../../utils';
import { RecommendationsCampaignType } from '../KeywordActionV4';
import {
  KeywordTableStateIndicator,
  KeywordTableStateIndicatorType,
} from '../KeywordTableStateIndicator';
import { RecommendationsCountContext } from '../RecommendationsCountProvider';
import {
  getNegativeRecommendationsDefaultSelectedOptionsV2,
  rowsPerPageOptions,
} from './utils';
import { ActionModal } from './actionModal';
import { getNKWAColumnsDefinition } from '../RecommendationsTableV3/columns';
import {
  capitalizeFirstLetter,
  getNKWRFilterColumnDefinitionsV2,
} from '../RecommendationsTableV3/columns/utils';
import { getColumnManagerOptionsNegativeRecommendationsTableV2 } from '../RecommendationsTableV3/negativeColumnManagerConfig';
import {
  ActionRowDataProps,
  NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V3_ID,
  RecommendationsTableV2ExtraProps,
} from '../RecommendationsTableV2/types';
import {
  MAX_ROWS,
  OVERSCAN_ROWS,
  dataFetcherNegativeRecommendationsTable,
  markAllAdgroupsUnchecked,
  submitNegativeRecommendations,
} from '../RecommendationsTableV2/utils';
import { RecommendationTableBulkEditModal } from '../RecommendationsTableV3/bulkEditModal';

interface NegativeRecommendationsTableV2Props {
  readonly merchantCountry: MerchantCountry;
  readonly hardRefreshTable: boolean;
}

export const getSelectedKeywords = (
  selectedRows: SelectedRecommendationsV2
) => {
  return Object.keys(selectedRows).reduce((acc, item) => {
    if (selectedRows[item]['isChecked']) {
      acc[item] = selectedRows[item];
    }
    return acc;
  }, {} as SelectedRecommendationsV2);
};

export const getEditActions = (
  selectedRows: SelectedRecommendationsV2,
  alertDialogMode: ActionType | undefined,
  merchantCountry: MerchantCountry
) => {
  const actions: RecommendationsAction[] = Object.keys(
    getSelectedKeywords(selectedRows)
  ).map((item) => {
    const keywordAction = selectedRows[item];
    return {
      recommendationId: keywordAction.recommendationId,
      adGroupId: keywordAction.adGroup?.id!,
      campaignId: keywordAction.campaign?.id!,
      matchType: capitalizeFirstLetter(keywordAction.matchType),
      action: alertDialogMode!,
      merchantCountryId: merchantCountry.merchantCountryId,
      salesChannelId: merchantCountry.salesChannelId!,
      tag: keywordAction.tag,
      recommendationType: 'Negative Keyword Targets',
      merchantType: merchantCountry.merchantType,
    };
  });
  return actions;
};

export const NegativeRecommendationsTableV2: React.FC<
  NegativeRecommendationsTableV2Props
> = ({ merchantCountry, hardRefreshTable }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userContext = useContext<UserContextState>(UserContext);
  const { id: accountId } = getCurrentAccountFromContext(userContext)!;
  const userId = userContext.userInfo.userDetails?.id || '';
  const aoApiClient = createAOApiClient(userContext.userInfo.idToken!);
  const toasts = useContext<NotificationContextState>(NotificationContext);

  const location = useLocation();
  const selectedLevel = location.pathname.includes('keywords-by-channel')
    ? 'keywordsByChannel'
    : 'performance';

  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);
  const keywordActionsColumnEnabled =
    optimizelyContext.featureFlags[OptimizelyFlags.KeywordActionsIndividualRow];

  const viewTrendsContext = useContext(ViewTrendsContext);

  const [
    SEARCH_PLACEHOLDER,
    DISMISS,
    ADD_KEYWORDS,
    SUBMISSION_FAILURE_DESCRIPTION,
    ADDING_KEYWORDS,
    DISMISS_KEYWORDS,
    FAILURE_ERROR_DESCRIPTION,
    SINGLE_SELECT_CHECKBOX_TOOLTIP,
    HEADER_CHECKBOX_TOOLTIP,
  ] = [
    I18nKey.GENERIC_SEARCH_LABEL,
    I18nKey.RECOMMENDATION_PAGE_AUTOMATED_BIDDING_DISMISS,
    I18nKey.KEYWORD_RECOMMENDATIONS_ADD_KEYWORDS,
    I18nKey.NEGATIVE_KEYWORD_RECOMMENDATIONS_EDIT_KEYWORDS_FAILURE,
    I18nKey.NEGATIVE_KEYWORD_RECOMMENDATIONS_ADDING_KEYWORDS,
    I18nKey.NEGATIVE_KEYWORD_RECOMMENDATIONS_DISMISS_KEYWORDS,
    I18nKey.NEGATIVE_KEYWORD_RECOMMENDATIONS_EDIT_KEYWORDS_FAILURE,
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_CHECKBOX_SINGLE_SELECT_TOOLTIP,
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_CHECKBOX_HEADER_TOOLTIP,
  ].map((id) => intl.formatMessage({ id }));

  const [selectedColumns, setSelectedColumns] = useState<
    KeywordActionColumns[]
  >(
    getNegativeRecommendationsDefaultSelectedOptionsV2(userId, accountId, intl)
  );
  const [selectedRows, setSelectedRows] = useState<SelectedRecommendationsV2>(
    markAllAdgroupsUnchecked(
      getUpdatedAdgroupsFromLocalStorage({ userId, accountId })
    ) || {}
  );
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>();
  const [showEditSlideOut, setShowEditSlideOut] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>();
  const [alertDialogMode, setAlertDialogMode] = useState<ActionType>();

  const {
    negativeKeywordsByChannelCount,
    negativeKeywordsByPerformanceCount,
    refreshRecommendationsCount,
  } = useContext(RecommendationsCountContext);

  const updateAdgroupsInLocalStorage = (adGroups: SelectedRecommendationsV2) =>
    storeUpdatedAdgroupsToLocalStorage({
      userId,
      accountId,
      adGroups,
    });

  const onRowsSelection = (
    rowsId: string[],
    checked: boolean,
    original?: Map<string, KeywordRecommendationsData>
  ) => {
    if (!original) return;
    const updatedRowsSelectState = { ...selectedRows };
    rowsId.forEach(
      (id) =>
        (updatedRowsSelectState[id] = {
          recommendationId: id,
          matchType:
            selectedRows[id]?.matchType ||
            original?.get(id)?.keyword?.suggestedMatchType,
          adGroup:
            selectedRows[id]?.adGroup ||
            original?.get(id)?.suggestedDestination.adGroup,
          campaign:
            selectedRows[id]?.campaign ||
            original?.get(id)?.suggestedDestination.adGroup.campaign,
          isChecked: checked,
          tag: selectedRows[id]?.tag || original?.get(id)?.keyword.tag!,
        })
    );
    setSelectedRows(updatedRowsSelectState);
    updateAdgroupsInLocalStorage(updatedRowsSelectState);
  };

  const onPaginationChange = () => {
    const updatedRowsSelectState = Object.keys(selectedRows).reduce(
      (acc: any, rowId: string) => {
        acc[rowId] = { ...selectedRows[rowId], isChecked: false };
        return acc;
      },
      {}
    );
    setSelectedRows(updatedRowsSelectState);
    updateAdgroupsInLocalStorage(updatedRowsSelectState);
  };

  const TABLE_ROW_DATA_FETCHER = dataFetcherNegativeRecommendationsTable(
    accountId,
    merchantCountry.merchantCountryId,
    DEFAULT_CURRENCY,
    aoApiClient,
    RecommendationsCampaignType.All,
    true,
    selectedLevel === 'keywordsByChannel'
  );

  const refreshTable = () => {
    const dataFetcher = dataFetcherNegativeRecommendationsTable(
      accountId,
      merchantCountry.merchantCountryId,
      DEFAULT_CURRENCY,
      aoApiClient,
      RecommendationsCampaignType.All,
      true,
      selectedLevel === 'keywordsByChannel'
    );
    dispatch(
      tableThunks.refreshTable(
        NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V3_ID,
        dataFetcher
      )
    );
  };

  useEffect(() => {
    refreshTable();
  }, [merchantCountry.merchantCountryId, hardRefreshTable]);

  const keywordDisplayState = useSelector<
    WithTable<KeywordRecommendationsData>,
    TableDisplayState
  >(({ tableState }) =>
    tableSelectors.getTableDisplayStateSelector()(
      tableState,
      NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V3_ID
    )
  );

  const keywordsShowNoData =
    keywordDisplayState !== TableDisplayState.DataLoadNotStarted &&
    keywordDisplayState !== TableDisplayState.LoadingInitialData &&
    keywordDisplayState !== TableDisplayState.Error &&
    ((selectedLevel !== 'performance' && negativeKeywordsByChannelCount <= 0) ||
      (selectedLevel === 'performance' &&
        negativeKeywordsByPerformanceCount <= 0));

  const onColumnSelectionChange = (selectedValues: string[]) => {
    const newlySelectedColumns = selectedValues as KeywordActionColumns[];
    setSelectedColumns(newlySelectedColumns);
    storeSelectedColumnsRecommendationsToLocalStorage({
      userId,
      accountId,
      values: newlySelectedColumns,
      isV2: true,
    });
  };

  const selectedRowsLength = Object.keys(
    getSelectedKeywords(selectedRows)
  ).length;

  const negativeRecommendationTableData = useSelector<
    WithTable<KeywordRecommendationsData>,
    KeywordRecommendationsData[]
  >(({ tableState }) =>
    tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<KeywordRecommendationsData>()(
        tableState,
        NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V3_ID
      )
    )
  );

  const columns = getNKWAColumnsDefinition(
    selectedColumns,
    keywordActionsColumnEnabled
  );

  const noDataState: TableEmptyStateProps = {
    icon: LinkBrokenIcon,
    titleI18nKey: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_NO_DATA_TITLE,
    descriptionI18nKey:
      I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_NO_DATA_DESCRIPTION,
    className: 'pt-140 mb-20',
  };

  const onBulkEditClick = () => {
    setShowEditSlideOut(true);
    hideIntercomLauncher();
  };

  const actionRowProps: TableActionRowV2Props = useMemo(() => {
    return {
      isActive: !!selectedRowsLength,
      isLoading: saving,
      loadingText:
        alertDialogMode === ActionType.Accepted
          ? ADDING_KEYWORDS
          : DISMISS_KEYWORDS,
      showEditButton: !!selectedRowsLength,
      onEditClick: onBulkEditClick,
      showCancelButton: false,
      showSelectedCount: true,
      selectedCount: selectedRowsLength,
      totalCount: negativeRecommendationTableData.length,
      primaryButtonProps: {
        label: ADD_KEYWORDS,
        svgIcon: CheckmarkIcon,
        variant: ButtonVariant.Primary,
        size: ButtonSize.Large,
        state: !!selectedRowsLength ? ButtonState.Active : ButtonState.Disabled,
        iconClassName: !!selectedRowsLength ? 'text-green-500' : '',
        onClick: () => {
          setAlertDialogMode(ActionType.Accepted);
          onApplyClick(ActionType.Accepted);
        },
        dataTestId: 'add_negative_keyword_btn',
      },
      secondaryButtonProps: {
        label: DISMISS,
        svgIcon: CrossMiniIcon,
        variant: ButtonVariant.BlackAndWhiteBorder,
        size: ButtonSize.Large,
        state: !!selectedRowsLength ? ButtonState.Active : ButtonState.Disabled,
        iconClassName: 'text-orange-500',
        onClick: () => {
          setAlertDialogMode(ActionType.Rejected);
          setShowAlertDialog(true);
        },
        dataTestId: 'dismiss_negative_keyword_btn',
      },
      className: 'border border-t-0 sticky top-0 z-5',
      roundedTop: true,
      dataTestId: 'negative_keyword_action_row',
      overrideActionButtonPaddingClassName: 'gap-8',
    };
  }, [
    selectedRowsLength,
    negativeRecommendationTableData,
    saving,
    alertDialogMode,
    onBulkEditClick,
  ]);

  // Handle dropdown select changes
  const handleDropdownSelection = (
    row: KeywordRecommendationsData,
    option: DropDownType,
    value?: MatchType | CampaignInfo | KeywordAdgroupInfo
  ) => {
    const initialRow = {
      recommendationId: row.id,
      matchType: row?.keyword.suggestedMatchType,
      campaign: row.suggestedDestination?.adGroup.campaign,
      adGroup: row.suggestedDestination?.adGroup,
      isChecked: false,
      tag: row.keyword.tag,
    };

    const prevRow = selectedRows[row.id];

    selectedRows[row.id] = {
      ...initialRow,
      ...prevRow,
      [option]: value,
    };
    setSelectedRows({
      ...selectedRows,
    });
    updateAdgroupsInLocalStorage({
      ...selectedRows,
    });
  };

  const onSubmitSuccess = (
    resp: AxiosResponse<KeywordActionSubmissionResponse, any>,
    rowIds: string[],
    actionType?: ActionType
  ) => {
    const relevantMode = actionType ? actionType : alertDialogMode;
    if (
      resp.data.status === KeywordActionSubmissionResponseStatus.Success ||
      resp.status === HttpCode.ACCEPTED
    ) {
      toasts.addNotification({
        headline: intl.formatMessage(
          {
            id:
              relevantMode === ActionType.Accepted
                ? I18nKey.NEGATIVE_KEYWORD_RECOMMENDATIONS_ACCEPT_KEYWORDS_SUCCESS
                : I18nKey.NEGATIVE_KEYWORD_RECOMMENDATIONS_DISMISS_KEYWORDS_SUCCESS,
          },
          {
            count: rowIds.length,
          }
        ),
        description: '',
        onClose: noop,
        type: Type.Success,
        variant: ToastVariant.Simple,
      });
      setSelectedRows(() => ({}));
      updateAdgroupsInLocalStorage({});
      if (relevantMode && keywordActionsColumnEnabled) {
        updateAndDeleteProcessedIds(rowIds, relevantMode);
      } else {
        refreshTable();
      }
      refreshRecommendationsCount();
    } else if (
      resp.data.status === KeywordActionSubmissionResponseStatus.Error
    ) {
      toasts.addNotification({
        headline: FAILURE_ERROR_DESCRIPTION,
        description: '',
        onClose: noop,
        type: Type.Attention,
        variant: ToastVariant.Simple,
      });
      refreshTable();
    }
  };

  const onSubmitFailure = () => {
    toasts.addNotification({
      headline: SUBMISSION_FAILURE_DESCRIPTION,
      description: '',
      onClose: noop,
      type: Type.Attention,
      variant: ToastVariant.Simple,
    });
  };

  const onApplyClick = async (actionType?: ActionType) => {
    setShowAlertDialog(false);
    setSaving(true);

    const requiredActionType =
      typeof actionType === 'string' ? actionType : alertDialogMode;

    if (requiredActionType && keywordActionsColumnEnabled) {
      updateProcessingIds(Object.keys(selectedRows), requiredActionType);
    }

    const actions = getEditActions(
      selectedRows,
      requiredActionType,
      merchantCountry
    );

    await submitNegativeRecommendations(
      onSubmitSuccess,
      onSubmitFailure,
      aoApiClient,
      accountId,
      actions,
      setSaving,
      actionType
    );
  };

  const onBulkEditSlideOutClose = () => {
    setShowEditSlideOut(false);
    showIntercomLauncher();
  };

  const onBulkEditSave = (changes: BulkEditValues) => {
    const newRows = Object.keys(selectedRows).reduce((acc, item) => {
      acc[item] = {
        ...selectedRows[item],
        ...changes,
      };
      return acc;
    }, {} as SelectedRecommendationsV2);
    setSelectedRows(newRows);
    updateAdgroupsInLocalStorage(newRows);
  };

  const [actionRowDataProps, setActionRowDataProps] =
    useState<ActionRowDataProps>({
      dismissedRows: [],
      dismissProcessingRows: [],
      acceptedRows: [],
      acceptProcessingRows: [],
    });

  const dispatchDeleteRecords = (rowIds: string[]) => {
    dispatch(
      tableActions.deleteRecordsInTable({
        tableId: NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V3_ID,
        itemIdentifier: (item: KeywordRecommendationsData) => {
          return rowIds.includes(item.id);
        },
      })
    );
  };

  const updateProcessingIds = (rowIds: string[], action: ActionType) => {
    const updatedActionData: ActionRowDataProps = {
      ...actionRowDataProps,
      dismissProcessingRows:
        action === ActionType.Rejected
          ? [...actionRowDataProps.dismissProcessingRows, ...rowIds]
          : actionRowDataProps.dismissProcessingRows,
      acceptProcessingRows:
        action === ActionType.Accepted
          ? [...actionRowDataProps.acceptProcessingRows, ...rowIds]
          : actionRowDataProps.acceptProcessingRows,
    };
    setActionRowDataProps(updatedActionData);
  };

  const updateAndDeleteProcessedIds = (
    rowIds: string[],
    action: ActionType
  ) => {
    const updatedActionData: ActionRowDataProps = {
      ...actionRowDataProps,
      dismissedRows:
        action === ActionType.Rejected
          ? [...actionRowDataProps.dismissedRows, ...rowIds]
          : actionRowDataProps.dismissedRows,
      acceptedRows:
        action === ActionType.Accepted
          ? [...actionRowDataProps.acceptedRows, ...rowIds]
          : actionRowDataProps.acceptedRows,
    };
    setActionRowDataProps(updatedActionData);
    dispatchDeleteRecords(rowIds);
  };

  const onActionRowClick = (
    rowIds: string[],
    actionType: ActionType,
    original: KeywordRecommendationsData
  ) => {
    updateProcessingIds(rowIds, actionType);
    const selectedRowInfo = selectedRows[original.id];
    const actions = getEditActions(
      {
        [original.id]: {
          recommendationId: original.id,
          matchType: selectedRowInfo
            ? selectedRowInfo.matchType
            : (original.keyword.suggestedMatchType as MatchType),
          adGroup: selectedRowInfo
            ? selectedRowInfo.adGroup
            : original.suggestedDestination.adGroup,
          campaign: selectedRowInfo
            ? selectedRowInfo.campaign
            : original.suggestedDestination.adGroup.campaign,
          isChecked: true,
          tag: selectedRowInfo ? selectedRowInfo.tag : original.keyword.tag!,
        },
      },
      actionType,
      merchantCountry
    );
    submitNegativeRecommendations(
      onSubmitSuccess,
      onSubmitFailure,
      aoApiClient,
      accountId,
      actions,
      setSaving,
      actionType
    );
  };

  const rowIdsTobeProcessedAndDisabled = [
    ...actionRowDataProps.acceptProcessingRows,
    ...actionRowDataProps.acceptedRows,
    ...actionRowDataProps.dismissProcessingRows,
    ...actionRowDataProps.dismissedRows,
  ];

  const storeFiltersInLocalStorage = (filters: Filter[]) => {
    setKeywordsToNegateFiltersInLocalStorage(
      {
        accountId,
        userId: userContext.userInfo.userDetails?.id!,
      },
      filters
    );
  };

  const filtersFromBrowserStorage = getKeywordsToNegateFiltersFromLocalStorage({
    accountId,
    userId: userContext.userInfo.userDetails?.id!,
  });

  const [showTrendsSlideover, setShowTrendsSlideover] =
    useState<boolean>(false);

  const [selectedTrendsProduct, setSelectedTrendsProduct] =
    useState<SelectedTrendsProduct>();

  const onViewTrendsSlideoverClose = () => {
    setShowTrendsSlideover(false);
    setSelectedTrendsProduct(undefined);
  };

  const onViewTrendsSlideoverClick = (product: SelectedTrendsProduct) => {
    setSelectedTrendsProduct(product);
    setShowTrendsSlideover(true);
    if (viewTrendsContext.closeViewTrends) {
      viewTrendsContext.closeViewTrends();
    }
  };

  const getTableActionRow = () => <TableActionRowV2 {...actionRowProps} />;

  const onFilterUpdate = (filters: Filter[]) => {
    storeFiltersInLocalStorage(filters);
  };

  if (keywordsShowNoData) {
    return (
      <div className="flex items-center justify-center h-keywordActionsTable w-keywordActionsTableWidth">
        <KeywordTableStateIndicator
          tableStateType={KeywordTableStateIndicatorType.KeywordNoData}
        />
      </div>
    );
  }

  return (
    <div className="w-keywordActionsTableWidth mt-16">
      <FlywheelTableV2<
        KeywordRecommendationsData,
        {},
        RecommendationsTableV2ExtraProps,
        {}
      >
        tableId={NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_V3_ID}
        tableData={{
          handleDropdownSelection,
          selectedRows,
          actionRowDataProps,
          onActionRowClick,
          merchantCountry,
        }}
        virtualizationProps={{
          estimateSize: MAX_ROWS,
          overscan: OVERSCAN_ROWS,
        }}
        currencyCode={DEFAULT_CURRENCY}
        dataFetcher={TABLE_ROW_DATA_FETCHER}
        columns={columns}
        selectedColumns={selectedColumns}
        noDataState={noDataState}
        hasSearchInput
        dataFields={getNKWRFilterColumnDefinitionsV2(DEFAULT_CURRENCY)}
        onColumnSelectionChange={onColumnSelectionChange}
        columnManagerOptions={getColumnManagerOptionsNegativeRecommendationsTableV2(
          intl
        )}
        searchInputClassName="w-256"
        searchInputPlaceholder={SEARCH_PLACEHOLDER}
        inputSearchColumnName={KeywordActionColumns.KEYWORD}
        hasSearchInputWithButton
        showBulkSelection
        rowIdKeyName="id"
        rowIdsSelected={Object.entries(selectedRows)
          .filter(([key, { isChecked }]) => isChecked)
          .map(([key]) => key)}
        filtersContainerClass="left-0"
        actionRowFullWidthElementIndex={1}
        filtersFromBrowserStorage={filtersFromBrowserStorage}
        onFilterUpdate={onFilterUpdate}
        filterFieldMapper={[
          {
            alias: 'targetText',
            field: 'targetText',
          },
        ]}
        storeFiltersInBrowserStorage={storeFiltersInLocalStorage}
        columnManagerTooltipClass="right-0"
        loadingAndEmptyStateClassName="m-auto"
        tableClassName="border-solid border-b-0 min-h-320"
        tableEmptyStateClass="w-keywordActionsTableWidth"
        onRowsSelection={onRowsSelection}
        onPaginationChange={onPaginationChange}
        rowsPerPageOptions={rowsPerPageOptions}
        headerClass="-mt-4"
        rowCheckboxClassName="-mt-4"
        roundedTop
        bulkSelectCellTooltip={SINGLE_SELECT_CHECKBOX_TOOLTIP}
        bulkSelectHeaderTooltip={HEADER_CHECKBOX_TOOLTIP}
        rowIdsProcessing={rowIdsTobeProcessedAndDisabled}
        rowIdsDisabled={rowIdsTobeProcessedAndDisabled}
        hideColumnManagerText
        allowStickyHeader
        customStickyHeaderClassName="top-48"
        additionalActionRow={getTableActionRow}
        minColumnSize={100}
      />
      {showAlertDialog && alertDialogMode && (
        <ActionModal
          selectedCount={selectedRowsLength}
          actionType={alertDialogMode}
          onApplyClick={onApplyClick}
          onCancelClick={() => setShowAlertDialog(false)}
          keywordLevel={KeywordLevel.NegativeKeywords}
        />
      )}
      {showEditSlideOut && (
        <RecommendationTableBulkEditModal
          onClose={onBulkEditSlideOutClose}
          onSubmit={onBulkEditSave}
          merchantCountryId={merchantCountry.merchantCountryId}
          isNegativeKeyword
        />
      )}
      {viewTrendsContext.isViewTrendsOpen && (
        <ViewTrends
          selectedDateRange={{
            initialEndDate: DateTime.now().minus({ days: 1 }),
            initialStartDate: DateTime.now().minus({ days: 8 }),
            minDate: DateTime.now().minus({ days: 99999 }),
            maxDate: DateTime.now().minus({ days: 1 }),
          }}
          adType={viewTrendsContext.adType as AdType}
          aoApiClient={aoApiClient}
          salesChannelId={viewTrendsContext.channelId}
        />
      )}
      {viewTrendsContext.isSKUSlideoverOpen &&
        viewTrendsContext.isViewTrendsOpen && (
          <ViewSkuAndKeywordSlideOutV2
            adGroupId={viewTrendsContext.entityId as string}
            isOpen={viewTrendsContext.isSKUSlideoverOpen as boolean}
            merchantCountry={merchantCountry}
            onClose={() =>
              viewTrendsContext.toggleSkuSlideover &&
              viewTrendsContext.toggleSkuSlideover()
            }
            adTypeDropDownValue={viewTrendsContext.adType as AdType}
            onTextLinkClick={() =>
              viewTrendsContext.onTextLinkClick &&
              viewTrendsContext.onTextLinkClick(
                merchantCountry.salesChannelId === AMAZON_SALES_CHANNEL_ID
                  ? FlywheelSalesChannel.Amazon
                  : FlywheelSalesChannel.Walmart,
                viewTrendsContext.adType as AdType,
                LevelType.Keywords
              )()
            }
            suggestedAdgroup={{
              id: viewTrendsContext.entityId as string,
              campaign: {
                campaignType: viewTrendsContext.adType as AdType,
                id: viewTrendsContext.campaignId as string,
                name: viewTrendsContext.campaignName as string,
                targetingType: String(
                  viewTrendsContext.targetingType
                ) as TargetingType,
              },
              name: viewTrendsContext.entityName,
              targetSegments: viewTrendsContext.targetSegments,
            }}
            selectedSalesChannelName={
              viewTrendsContext.channelId === AMAZON_SALES_CHANNEL_ID
                ? FlywheelSalesChannel.Amazon
                : FlywheelSalesChannel.Walmart
            }
            onSKUTrendsLinkClick={onViewTrendsSlideoverClick}
            textLinkContent={intl.formatMessage({
              id: I18nKey.SKU_CATALOG_RELATED_FLYOVER_FOOTER_PRIMARY_BUTTON_TEXT,
            })}
          />
        )}
      {showTrendsSlideover && selectedTrendsProduct && (
        <PerformanceSlideoverContextProvider
          currency={CurrencyCode.USD}
          defaultDateRange={{
            startDate: DateTime.now().minus({ days: 7 }),
            endDate: DateTime.now().minus({ days: 1 }),
          }}
          {...selectedTrendsProduct}
        >
          <PerformanceSlideover onClose={onViewTrendsSlideoverClose} />
        </PerformanceSlideoverContextProvider>
      )}
    </div>
  );
};

NegativeRecommendationsTableV2.displayName = 'NegativeRecommendationsTableV2';
