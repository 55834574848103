import { Factory } from 'fishery';

import {
  MIPublicAPISearchTermsResponse,
  SearchTermStatus,
} from '../../types/MISharedTypes';

export default Factory.define<MIPublicAPISearchTermsResponse>(() => ({
  is_search_term_number_unlimited: false,
  n_search_terms: 2,
  max_free_tracked_search_term: 20,
  max_total_tracked_search_term: 0,
  search_terms: [
    {
      search_term: 'prestiges',
      country: 'US',
      created_at: '2021-02-04, at 22:58:52 PST',
      status: SearchTermStatus.Active,
      sales_channel: 'Amazon',
      search_term_id: '85d2662f-ced7-5f16-b2ba-71abfabdac6d',
    },
    {
      search_term: 'temper',
      country: 'US',
      created_at: '2021-02-04, at 21:44:45 PST',
      status: SearchTermStatus.Fetching,
      sales_channel: 'Amazon',
      search_term_id: '344c3af2-7009-55ee-a3a5-8b53a42b78f4',
    },
  ],
  n_filtered_search_terms: 0,
  last_updated: '2021-02-10, at 02:51:01 PST',
}));
