import { Modal } from '@teikametrics/tm-design-system';
import { ManagedEdition, ManagedEditionSize } from '../ManagedEdition';
import { ReactComponent as FlywheelLogo } from '../../img/logo.svg';
import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { useContext, useEffect, useState } from 'react';
import { AnalystProfile } from '../AnalystProfile';
import classNames from 'classnames';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { AccountLeadAnalysts } from '../../lib/types/Fam';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../lib/utilities/intercom';

export interface ManagedEditionSlideOutProps {
  readonly showManagedEditionSlideOut: boolean;
  readonly closeManagedEditionSlideOut: () => void;
}

export const ManagedEditionSlideOut: React.FC<ManagedEditionSlideOutProps> = ({
  showManagedEditionSlideOut,
  closeManagedEditionSlideOut,
}) => {
  const [accountLeadAnalysts, setAccountLeadAnalysts] =
    useState<AccountLeadAnalysts>();
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)?.id!;
  const famApiClient = createFAMApiClient(userContext.userInfo.idToken!);

  useEffect(() => {
    const getAccountDetails = async (accountId: string) => {
      const data = await famApiClient.getLeadAnalysts(accountId);
      setAccountLeadAnalysts(data);
    };
    if (accountId) {
      getAccountDetails(accountId);
    }
  }, [accountId]);

  useEffect(() => {
    if (showManagedEditionSlideOut) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [showManagedEditionSlideOut]);

  return (
    <Modal
      className="flex flex-col h-screen w-420 border-0"
      showModal={showManagedEditionSlideOut}
      slideOutModal={true}
      hidePadding={true}
      secondaryAction={{
        label: '',
        onClick: closeManagedEditionSlideOut,
      }}
      dataTestId="managed_edition_slide_out"
    >
      <div className="flex flex-col h-screen overflow-hidden bg-managed-edition-slide-out bg-no-repeat bg-grey-900">
        <div className="w-full bg-blue-gradient h-4"></div>
        <header className="flex flex-col items-center justify-center h-190 gap-12">
          <FlywheelLogo className="text-white" />
          <ManagedEdition size={ManagedEditionSize.xl} />
        </header>
        <main
          className={classNames(
            'flex-1 flex flex-col items-center justify-start p-16 gap-48 overflow-y-auto',
            'scrollbar scrollbar-thin scrollbar-thumb-grey-400 scrollbar-track-grey-100 scrollbar-thumb-rounded-full'
          )}
        >
          {accountLeadAnalysts?.leadAnalysts.map((analyst) => (
            <AnalystProfile
              analyst={analyst}
              count={accountLeadAnalysts?.leadAnalysts.length}
            />
          ))}
        </main>
      </div>
    </Modal>
  );
};

ManagedEditionSlideOut.displayName = 'ManagedEditionSlideOut';
