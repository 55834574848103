import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import first from 'lodash/first';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';

import {
  BulkEditModalNumericValueOperator,
  BulkEditValues,
  EditControlProps,
  NumericInputType,
  ORIGINAL_DATE_FORMAT,
  Type as toastType,
} from '@teikametrics/tm-design-system';

import { MoneyWithAmountInString } from 'lib/types/Money';
import { NotificationContextState } from '../../../../../containers/notificationProvider';
import { tableActions } from '../../../../../containers/table/ducks';
import {
  TableCellChange,
  TableChange,
} from '../../../../../containers/table/ducks/types';
import {
  AdType,
  AdvertisingGoal,
  BudgetType,
  CampaignAdFormat,
  CampaignDetails,
  CampaignStatus,
  CampaignTargetingType,
  ChannelSettings,
  FlywheelSalesChannel,
  PlacementBidMultiplier,
  PlacementStatus,
  PlatformBidMultiplier,
} from '../../../../../lib/types/AOSharedTypes';
import {
  MONETARY_FRACTION_DIGITS,
  NO_FRACTION_DIGITS,
} from '../../../../../lib/types/CommonSharedTypes';
import { MerchantCountry, MerchantType } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { CAMPAIGN_DATE_FORMAT, CampaignBulkEditControls } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  EDIT_CAMPAIGNS_API_COLUMN_DATA_MAPPING,
  TABLE_UNIQ_KEY,
  getMaxDateBetweenTwoDates,
  getUpdatedValueFromOperator,
  isAutoCampaign,
  isCampaignAdFormatProductCollectionOrSpotlight,
  isCampaignStatusArchived,
  isCampaignStatusCompleted,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
  isManualCampaign,
  isValueBetweenMinAndMaxValue,
} from '../utils';
import { isBelowTopOfTheSearchBidMultiplierInvalid } from './belowTopOfTheSearchBidMultiplier';
import {
  ADTYPES_ARRAY_SB_SBV,
  ADTYPES_ARRAY_SP_SD_SB_SBV,
  BOOLEAN_COLUMNS,
  CampaignBulkEditModalValues,
  PERCENTAGE_BASED_COLUMNS,
  endDateOptions,
} from './campaignName';
import { isMacsTargetInvalid } from './macsTarget';
import { isProductPageBidMultiplierInvalid } from './productPageBidMultiplier';
import { isTotalBudgetInValid } from './totalBudget';
import { SMART_GOALS } from '../adgroupTableColumns/utils';

interface HandleCampaignsBulkEditApplyFunctionProps {
  intl: IntlShape;
  bulkMenuValue: CampaignBulkEditModalValues | null;
  tableChange: TableChange;
  campaignsTableData: CampaignDetails[];
  salesChannel: FlywheelSalesChannel | null;
  adType: AdType | null;
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  campaignsTableDataForAllPages: CampaignDetails[];
  allMerchants: MerchantCountry[];
  minDailyBudgetWalmartVendor: number;
  minTotalBudgetWalmartVendor: number;
  minDailyBudgetWalmartSeller: number;
  minTotalBudgetWalmartSeller: number;
  maxDailyBudgetWalmartVendor: number;
  maxTotalBudgetWalmartVendor: number;
  maxDailyBudgetWalmartSeller: number;
  maxTotalBudgetWalmartSeller: number;
  minDailyBudgetAmazonSPSB: number;
  maxDailyBudgetAmazonSPSB: number;
  totalBudgetMin: number;
  totalBudgetMax: number;
  minMacsTarget: number;
  maxMacsTarget: number;
  minBidMinAmazonSB: number;
  maxBidMinAmazonSB: number;
  minBidMinAmazonSBV: number;
  maxBidMinAmazonSBV: number;
  minBidMaxAmazonSB: number;
  maxBidMaxAmazonSB: number;
  minBidMaxAmazonSBV: number;
  maxBidMaxAmazonSBV: number;
  minProductMultiplier: number;
  maxProductMultiplier: number;
  belowTopOfSearchBidMultiplierMinAmazonSB: number;
  belowTopOfSearchBidMultiplierMaxAmazonSB: number;
  placementBidMultiplierMinAmazonSP: number;
  placementBidMultiplierMaxAmazonSP: number;
  toasts: NotificationContextState;
  endDateOption: string;
  updateCells: TableCellChange;
  dispatch: React.Dispatch<any>;
  handleCampaignsBulkEditClose: () => void;
}

interface HandleControlKeyProps {
  bulkEditChanges: TableCellChange;
  campaignId: string;
  tableChange: TableChange;
  bulkEditValues: BulkEditValues;
  controlKey: string;
  targetingType?: CampaignTargetingType;
  placementBidMultiplier?: PlacementBidMultiplier;
  platformBidMultiplier?: PlatformBidMultiplier;
  selectedSalesChannel: FlywheelSalesChannel;
  selectedAdType: AdType;
  budgetType?: string;
  dailyBudget?: MoneyWithAmountInString;
  totalBudget?: MoneyWithAmountInString;
  campaignAdFormat?: CampaignAdFormat;
  status?: CampaignStatus;
  advertisingGoal?: AdvertisingGoal;
}

interface HandleColumnKeyProps {
  bulkEditChanges: TableCellChange;
  campaignId: string;
  tableChange: TableChange;
  bulkEditValues: BulkEditValues;
  controlKey: string;
  targetingType?: CampaignTargetingType;
  placementBidMultiplier?: PlacementBidMultiplier;
}

export const handleAmazonChannelWithAdtype = (
  salesChannel: FlywheelSalesChannel | null,
  adType: AdType | null,
  matchAdType: readonly AdType[]
) =>
  salesChannel === FlywheelSalesChannel.Amazon &&
  adType &&
  matchAdType.includes(adType);

export const getCampaignsRowDataWithUpdatedBidOptimization = (
  updateCells: TableCellChange,
  rowData?: CampaignDetails
) => {
  if (rowData?.campaignId && !isUndefined(updateCells[rowData.campaignId])) {
    const updatedCell = updateCells[rowData.campaignId];
    if (!isUndefined(updatedCell['bidOptimization'])) {
      const updatedChannelSettings: ChannelSettings = {
        ...rowData.channelSettings,
        bidOptimization: updatedCell['bidOptimization'] === 'true',
      };
      return { ...rowData, channelSettings: updatedChannelSettings };
    }
  }
  return rowData;
};

export const handleDailyBudgetModalvalueBulkChanges = ({
  rowId,
  currentBulkChanges,
  campaignsTableData,
  salesChannel,
  values,
  operators,
  campaignsTableDataForAllPages,
  allMerchants,
  minDailyBudgetWalmartVendor,
  minDailyBudgetWalmartSeller,
  maxDailyBudgetWalmartVendor,
  maxDailyBudgetWalmartSeller,
  minDailyBudgetAmazonSPSB,
  maxDailyBudgetAmazonSPSB,
  updatedValueChangesCount,
}: {
  rowId: string;
  currentBulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  salesChannel: FlywheelSalesChannel | null;
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  campaignsTableDataForAllPages: CampaignDetails[];
  allMerchants: MerchantCountry[];
  minDailyBudgetWalmartVendor: number;
  minDailyBudgetWalmartSeller: number;
  maxDailyBudgetWalmartVendor: number;
  maxDailyBudgetWalmartSeller: number;
  minDailyBudgetAmazonSPSB: number;
  maxDailyBudgetAmazonSPSB: number;
  updatedValueChangesCount: number;
}) => {
  const changes = cloneDeep(currentBulkChanges);
  let count = updatedValueChangesCount;
  if (isNil(changes[rowId])) {
    changes[rowId] = {};
  }

  const rowDataFromAdGroupDataForAllPages = find(
    campaignsTableDataForAllPages,
    {
      [TABLE_UNIQ_KEY[ADS_MANAGER_CAMPAIGNS_TABLE_ID]]: rowId,
    }
  );

  const updatedValueWithDecimals = parseFloat(
    getUpdatedValueFromOperator(
      rowDataFromAdGroupDataForAllPages?.channelSettings?.dailyBudget?.amount,
      changes[rowId][CAMPAIGNS_API_COLUMN_NAME.DailyBudget],
      values[0],
      first(operators)
    )
  ).toFixed(MONETARY_FRACTION_DIGITS);

  changes[rowId][CAMPAIGNS_API_COLUMN_NAME.DailyBudget] =
    updatedValueWithDecimals;

  const totalBudget =
    rowDataFromAdGroupDataForAllPages?.channelSettings.totalBudget?.amount;
  const updatedTotalBudget =
    changes[rowId][CAMPAIGNS_API_COLUMN_NAME.TotalBudget];
  const dailyBudget = changes[rowId][CAMPAIGNS_API_COLUMN_NAME.DailyBudget];

  const requiredTotalBudget = updatedTotalBudget || totalBudget;

  const isDailyBudgetGreaterThanTotalBudget =
    !isNil(requiredTotalBudget) &&
    !isNil(dailyBudget) &&
    isCurrentValueGreaterThanRequiredMaxValue(
      Number(requiredTotalBudget),
      dailyBudget
    );

  const isDailyBudgetInRange =
    isValueBetweenMinAndMaxValue(
      minDailyBudgetAmazonSPSB,
      maxDailyBudgetAmazonSPSB,
      dailyBudget
    ) || salesChannel === FlywheelSalesChannel.Walmart;

  if (isDailyBudgetGreaterThanTotalBudget || !isDailyBudgetInRange) {
    count++;
  } else if (
    !isNil(dailyBudget) &&
    salesChannel === FlywheelSalesChannel.Walmart
  ) {
    const currentMerchantCountryId = campaignsTableData.find(
      ({ campaignId }) => campaignId === rowId
    )?.campaignDetails.merchantCountryId;
    const merchantType = allMerchants.find(
      ({ merchantCountryId }) => merchantCountryId === currentMerchantCountryId
    )?.merchantType;

    const requiredMinValue =
      merchantType === MerchantType.Vendor
        ? minDailyBudgetWalmartVendor
        : minDailyBudgetWalmartSeller;

    const requiredMaxValue =
      merchantType === MerchantType.Vendor
        ? maxDailyBudgetWalmartVendor
        : maxDailyBudgetWalmartSeller;

    const isBudgetInRange = isValueBetweenMinAndMaxValue(
      requiredMinValue,
      requiredMaxValue,
      dailyBudget
    );

    if (!isBudgetInRange) {
      count++;
    }
  }
  return { changes, count };
};

export const handleDailyBudgetModalvalue = ({
  rowId,
  bulkChanges,
  bulkMenuValue,
  campaignsTableData,
  salesChannel,
  adType,
  values,
  operators,
  campaignsTableDataForAllPages,
  allMerchants,
  minDailyBudgetWalmartVendor,
  minDailyBudgetWalmartSeller,
  maxDailyBudgetWalmartVendor,
  maxDailyBudgetWalmartSeller,
  minDailyBudgetAmazonSPSB,
  maxDailyBudgetAmazonSPSB,
  inValidChangesCount,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  bulkMenuValue: CampaignBulkEditModalValues | null;
  campaignsTableData: CampaignDetails[];
  salesChannel: FlywheelSalesChannel | null;
  adType: AdType | null;
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  campaignsTableDataForAllPages: CampaignDetails[];
  allMerchants: MerchantCountry[];
  minDailyBudgetWalmartVendor: number;
  minDailyBudgetWalmartSeller: number;
  maxDailyBudgetWalmartVendor: number;
  maxDailyBudgetWalmartSeller: number;
  minDailyBudgetAmazonSPSB: number;
  maxDailyBudgetAmazonSPSB: number;
  inValidChangesCount: number;
}) => {
  let currentBulkChanges = cloneDeep(bulkChanges);
  let updatedValueChangesCount = inValidChangesCount;
  if (bulkMenuValue === CampaignBulkEditModalValues.DailyBudget) {
    const rowData = campaignsTableData.find(
      (data) => data.campaignId === rowId
    );
    if (rowData) {
      const {
        channelSettings: { budgetType },
      } = rowData;

      if (
        (salesChannel === FlywheelSalesChannel.Walmart &&
          (budgetType === BudgetType.Daily ||
            budgetType === BudgetType.Both)) ||
        handleAmazonChannelWithAdtype(
          salesChannel,
          adType,
          ADTYPES_ARRAY_SP_SD_SB_SBV
        )
      ) {
        const { changes, count } = handleDailyBudgetModalvalueBulkChanges({
          rowId,
          currentBulkChanges,
          campaignsTableData,
          salesChannel,
          values,
          operators,
          campaignsTableDataForAllPages,
          allMerchants,
          minDailyBudgetWalmartVendor,
          minDailyBudgetWalmartSeller,
          maxDailyBudgetWalmartVendor,
          maxDailyBudgetWalmartSeller,
          minDailyBudgetAmazonSPSB,
          maxDailyBudgetAmazonSPSB,
          updatedValueChangesCount,
        });
        currentBulkChanges = cloneDeep(changes);
        updatedValueChangesCount = count;
      }
    }
  }
  return {
    currentBulkChanges,
    updatedValueChangesCount,
  };
};

export const handleLifetimeBudgetModalvalueBulkChanges = ({
  rowId,
  currentBulkChanges,
  campaignsTableData,
  salesChannel,
  values,
  operators,
  campaignsTableDataForAllPages,
  allMerchants,
  minTotalBudgetWalmartVendor,
  minTotalBudgetWalmartSeller,
  maxTotalBudgetWalmartVendor,
  maxTotalBudgetWalmartSeller,
  totalBudgetMin,
  totalBudgetMax,
  updatedValueChangesCount,
}: {
  rowId: string;
  currentBulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  salesChannel: FlywheelSalesChannel | null;
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  campaignsTableDataForAllPages: CampaignDetails[];
  allMerchants: MerchantCountry[];
  minTotalBudgetWalmartVendor: number;
  minTotalBudgetWalmartSeller: number;
  maxTotalBudgetWalmartVendor: number;
  maxTotalBudgetWalmartSeller: number;
  totalBudgetMin: number;
  totalBudgetMax: number;
  updatedValueChangesCount: number;
}) => {
  const changes = cloneDeep(currentBulkChanges);
  let count = updatedValueChangesCount;
  if (isNil(changes[rowId])) {
    changes[rowId] = {};
  }
  const rowDataFromAdGroupDataForAllPages = find(
    campaignsTableDataForAllPages,
    {
      [TABLE_UNIQ_KEY[ADS_MANAGER_CAMPAIGNS_TABLE_ID]]: rowId,
    }
  );

  changes[rowId][CAMPAIGNS_API_COLUMN_NAME.TotalBudget] =
    getUpdatedValueFromOperator(
      rowDataFromAdGroupDataForAllPages?.channelSettings?.totalBudget?.amount,
      changes[rowId]?.[CAMPAIGNS_API_COLUMN_NAME.TotalBudget],
      values[0],
      first(operators)
    );

  const dailyBudget =
    rowDataFromAdGroupDataForAllPages?.channelSettings.dailyBudget?.amount;
  const updatedDailyBudget =
    changes[rowId][CAMPAIGNS_API_COLUMN_NAME.DailyBudget];
  const totalBudget = changes[rowId][CAMPAIGNS_API_COLUMN_NAME.TotalBudget];

  const requiredDailyBudget = updatedDailyBudget || dailyBudget;

  const isTotalBudgetSmallerThanDailyBudget =
    !isNil(requiredDailyBudget) &&
    !isNil(totalBudget) &&
    isCurrentValueLessThanRequiredMinValue(
      Number(requiredDailyBudget),
      totalBudget
    );

  const isTotalBudgetInvalid =
    isTotalBudgetInValid(
      FlywheelSalesChannel.Amazon,
      totalBudget,
      totalBudgetMin,
      totalBudgetMax
    ) && salesChannel === FlywheelSalesChannel.Amazon;

  if (isTotalBudgetSmallerThanDailyBudget || isTotalBudgetInvalid) {
    count++;
  } else if (
    !isNil(totalBudget) &&
    salesChannel === FlywheelSalesChannel.Walmart
  ) {
    const currentMerchantCountryId = campaignsTableData.find(
      ({ campaignId }) => campaignId === rowId
    )?.campaignDetails.merchantCountryId;
    const merchantType = allMerchants.find(
      ({ merchantCountryId }) => merchantCountryId === currentMerchantCountryId
    )?.merchantType;

    const requiredMinValue =
      merchantType === MerchantType.Vendor
        ? minTotalBudgetWalmartVendor
        : minTotalBudgetWalmartSeller;

    const requiredMaxValue =
      merchantType === MerchantType.Vendor
        ? maxTotalBudgetWalmartVendor
        : maxTotalBudgetWalmartSeller;

    const isTotalBudgetInRange = isValueBetweenMinAndMaxValue(
      requiredMinValue,
      requiredMaxValue,
      totalBudget
    );

    if (!isTotalBudgetInRange) {
      count++;
    }
  }
  return { changes, count };
};

export const handleLifetimeBudgetModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  salesChannel,
  values,
  operators,
  campaignsTableDataForAllPages,
  allMerchants,
  minTotalBudgetWalmartVendor,
  minTotalBudgetWalmartSeller,
  maxTotalBudgetWalmartVendor,
  maxTotalBudgetWalmartSeller,
  totalBudgetMin,
  totalBudgetMax,
  inValidChangesCount,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  salesChannel: FlywheelSalesChannel | null;
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  campaignsTableDataForAllPages: CampaignDetails[];
  allMerchants: MerchantCountry[];
  minTotalBudgetWalmartVendor: number;
  minTotalBudgetWalmartSeller: number;
  maxTotalBudgetWalmartVendor: number;
  maxTotalBudgetWalmartSeller: number;
  totalBudgetMin: number;
  totalBudgetMax: number;
  inValidChangesCount: number;
}) => {
  let currentBulkChanges = cloneDeep(bulkChanges);
  let updatedValueChangesCount = inValidChangesCount;
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);
  if (rowData) {
    const {
      channelSettings: { budgetType },
    } = rowData;

    if (
      budgetType === BudgetType.Total ||
      budgetType === BudgetType.Both ||
      budgetType === BudgetType.Lifetime
    ) {
      const { changes, count } = handleLifetimeBudgetModalvalueBulkChanges({
        rowId,
        currentBulkChanges,
        campaignsTableData,
        salesChannel,
        values,
        operators,
        campaignsTableDataForAllPages,
        allMerchants,
        minTotalBudgetWalmartVendor,
        minTotalBudgetWalmartSeller,
        maxTotalBudgetWalmartVendor,
        maxTotalBudgetWalmartSeller,
        totalBudgetMin,
        totalBudgetMax,
        updatedValueChangesCount,
      });
      currentBulkChanges = cloneDeep(changes);
      updatedValueChangesCount = count;
    }
  }
  return {
    currentBulkChanges,
    updatedValueChangesCount,
  };
};

export const handleBudgetRolloverModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  values,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  values: string[];
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);

  if (
    rowData &&
    rowData.channelSettings.budgetRollover?.toString() !== values[0]
  ) {
    if (isNil(currentBulkChanges[rowId])) {
      currentBulkChanges[rowId] = {};
    }
    currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.BudgetRollover] =
      values[0];
  }
  return {
    currentBulkChanges,
  };
};

export const handleAmazonBiddingStrategyModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  values,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  values: string[];
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);

  if (rowData) {
    if (isNil(currentBulkChanges[rowId])) {
      currentBulkChanges[rowId] = {};
    }
    currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy] =
      values[0];
  }
  return { currentBulkChanges };
};

export const handlePortfolioModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  values,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  values: string[];
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);

  if (rowData) {
    if (isNil(currentBulkChanges[rowId])) {
      currentBulkChanges[rowId] = {};
    }
    currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.Portfolio] = values[0];
  }
  return { currentBulkChanges };
};

export const handlePlacementBidMultipliersAmazonSBModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  updateCells,
  values,
  operators,
  belowTopOfSearchBidMultiplierMinAmazonSB,
  belowTopOfSearchBidMultiplierMaxAmazonSB,
  minProductMultiplier,
  maxProductMultiplier,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  updateCells: TableCellChange;
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  belowTopOfSearchBidMultiplierMinAmazonSB: number;
  belowTopOfSearchBidMultiplierMaxAmazonSB: number;
  minProductMultiplier: number;
  maxProductMultiplier: number;
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);
  let invalidCount = 0;
  let updatedRowData = getCampaignsRowDataWithUpdatedBidOptimization(
    updateCells,
    rowData
  );

  let belowTopOfSearchValue = getUpdatedValueFromOperator(
    updatedRowData?.channelSettings.placementBidMultiplier
      ?.belowTopOfSearchBidMultiplier,
    updateCells[rowId]?.[
      CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier
    ],
    values[0],
    first(operators),
    NumericInputType.Percent
  );

  if (isNil(currentBulkChanges[rowId])) {
    currentBulkChanges[rowId] = {};
  }

  if (updatedRowData && belowTopOfSearchValue) {
    const updatedValueWithDecimals = parseFloat(belowTopOfSearchValue).toFixed(
      NO_FRACTION_DIGITS
    );
    if (
      isCampaignAdFormatProductCollectionOrSpotlight(
        updatedRowData?.campaignDetails?.campaignAdFormat
      ) &&
      updatedRowData?.channelSettings?.bidOptimization === false
    ) {
      currentBulkChanges[rowId][
        CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier
      ] = updatedValueWithDecimals;
    }

    if (
      isBelowTopOfTheSearchBidMultiplierInvalid(
        updatedValueWithDecimals,
        belowTopOfSearchBidMultiplierMaxAmazonSB,
        belowTopOfSearchBidMultiplierMinAmazonSB
      )
    ) {
      invalidCount = 1;
    }
  }

  let productPageBidMultiplierValue = getUpdatedValueFromOperator(
    updatedRowData?.channelSettings?.placementBidMultiplier
      ?.productPageBidMultiplier,
    updateCells[rowId]?.[CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier],
    values[0],
    first(operators),
    NumericInputType.Percent
  );

  if (updatedRowData && productPageBidMultiplierValue) {
    const updatedValueWithDecimals = parseFloat(
      productPageBidMultiplierValue
    ).toFixed(NO_FRACTION_DIGITS);

    currentBulkChanges[rowId][
      CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier
    ] = updatedValueWithDecimals;

    if (
      isProductPageBidMultiplierInvalid(
        updatedValueWithDecimals,
        minProductMultiplier,
        maxProductMultiplier
      )
    ) {
      invalidCount = 1;
    }
  }
  return { currentBulkChanges, invalidCount };
};

export const handlePlacementBidMultipliersAmazonSPModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  values,
  operators,
  placementBidMultiplierMinAmazonSP,
  placementBidMultiplierMaxAmazonSP,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  placementBidMultiplierMinAmazonSP: number;
  placementBidMultiplierMaxAmazonSP: number;
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);
  let invalidCount = 0;

  if (isNil(currentBulkChanges[rowId])) {
    currentBulkChanges[rowId] = {};
  }

  let belowTopOfSearchValue = getUpdatedValueFromOperator(
    rowData?.channelSettings?.placementBidMultiplier?.topOfSearchBidMultiplier,
    bulkChanges[rowId]?.[CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier],
    values[1],
    operators?.[1],
    NumericInputType.Percent
  );

  if (rowData && belowTopOfSearchValue) {
    const updatedValueWithDecimals = parseFloat(belowTopOfSearchValue).toFixed(
      NO_FRACTION_DIGITS
    );

    currentBulkChanges[rowId][
      CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier
    ] = updatedValueWithDecimals;

    if (
      !isValueBetweenMinAndMaxValue(
        placementBidMultiplierMinAmazonSP,
        placementBidMultiplierMaxAmazonSP,
        belowTopOfSearchValue
      )
    ) {
      invalidCount = 1;
    }
  }

  let productPageBidMultiplierValue = getUpdatedValueFromOperator(
    rowData?.channelSettings?.placementBidMultiplier?.productPageBidMultiplier,
    bulkChanges[rowId]?.[CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier],
    values[0],
    operators?.[0],
    NumericInputType.Percent
  );

  if (rowData && productPageBidMultiplierValue) {
    const updatedValueWithDecimals = parseFloat(
      productPageBidMultiplierValue
    ).toFixed(NO_FRACTION_DIGITS);

    currentBulkChanges[rowId][
      CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier
    ] = updatedValueWithDecimals;

    if (
      !isValueBetweenMinAndMaxValue(
        placementBidMultiplierMinAmazonSP,
        placementBidMultiplierMaxAmazonSP,
        productPageBidMultiplierValue
      )
    ) {
      invalidCount = 1;
    }
  }
  return { currentBulkChanges, invalidCount };
};

export const handleEndDateModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  salesChannel,
  values,
  adType,
  endDateOption,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  salesChannel: FlywheelSalesChannel | null;
  values: string[];
  adType: AdType | null;
  endDateOption: string;
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);

  if (
    salesChannel === FlywheelSalesChannel.Walmart ||
    handleAmazonChannelWithAdtype(
      salesChannel,
      adType,
      ADTYPES_ARRAY_SP_SD_SB_SBV
    )
  ) {
    const campaignStartDate = rowData?.channelSettings.startDate;
    const status = rowData?.channelSettings.status;

    if (!currentBulkChanges[rowId]) {
      currentBulkChanges[rowId] = {};
    }
    let value = first(values) ?? '';
    const updatedStatus =
      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.CampaignStatus];
    const isCampaignCompleted = isCampaignStatusCompleted(
      status,
      updatedStatus as CampaignStatus
    );

    if (endDateOption === endDateOptions.NoEndDate && !isCampaignCompleted) {
      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.EndDate] = '';
      return { currentBulkChanges };
    } else {
      value = DateTime.fromFormat(value, ORIGINAL_DATE_FORMAT).toFormat(
        CAMPAIGN_DATE_FORMAT
      );
    }

    const isValidEndDate =
      campaignStartDate &&
      getMaxDateBetweenTwoDates(
        DateTime.fromISO(campaignStartDate),
        DateTime.local(),
        CAMPAIGN_DATE_FORMAT
      ) <= value;

    const shouldAssignEndDate =
      salesChannel === FlywheelSalesChannel.Walmart
        ? !isCampaignCompleted && isValidEndDate
        : isValidEndDate;

    if (shouldAssignEndDate) {
      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.EndDate] = value;
    }
  }
  return { currentBulkChanges };
};

export const handleAmazonBidOptimizationModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  salesChannel,
  values,
  adType,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  salesChannel: FlywheelSalesChannel | null;
  values: string[];
  adType: AdType | null;
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);
  if (!isNil(rowData)) {
    if (
      adType &&
      salesChannel === FlywheelSalesChannel.Amazon &&
      ADTYPES_ARRAY_SB_SBV.includes(adType)
    ) {
      if (isNil(currentBulkChanges[rowId])) {
        currentBulkChanges[rowId] = {};
      }
      if (
        isCampaignAdFormatProductCollectionOrSpotlight(
          rowData.campaignDetails.campaignAdFormat
        )
      )
        currentBulkChanges[rowId][
          CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization
        ] = values[0];
    }
  }
  return { currentBulkChanges };
};

const handleBulkEditViaControlKey = ({
  bulkEditChanges,
  campaignId,
  tableChange,
  bulkEditValues,
  controlKey,
  targetingType,
  platformBidMultiplier,
  selectedSalesChannel,
  budgetType,
  totalBudget,
  selectedAdType,
  dailyBudget,
  status,
  campaignAdFormat,
  advertisingGoal,
}: HandleControlKeyProps) => {
  switch (controlKey) {
    case CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier:
    case CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier:
    case CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier:
    case CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier:
    case CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier:
      updateBulkEditNumericValue(
        bulkEditChanges,
        campaignId,
        controlKey,
        isNil(platformBidMultiplier)
          ? ''
          : platformBidMultiplier[controlKey] ?? '',
        tableChange,
        NumericInputType.Percent,
        bulkEditValues[controlKey]
      );
      break;
    case CampaignBulkEditModalValues.PlacementInclusion:
      updatePlacementInclusionBidMultipliers(
        bulkEditChanges,
        campaignId,
        bulkEditValues,
        controlKey,
        targetingType
      );
      break;
    case CampaignBulkEditModalValues.LifetimeBudget:
      updateLifetimeBudget({
        bulkEditChanges,
        campaignId,
        bulkEditValues,
        selectedSalesChannel,
        tableChange,
        controlKey,
        budgetType,
        totalBudget,
      });
      break;
    case CAMPAIGNS_API_COLUMN_NAME.DailyBudget:
      updateDailyBudget({
        bulkEditChanges,
        campaignId,
        bulkEditValues,
        selectedSalesChannel,
        selectedAdType,
        tableChange,
        controlKey,
        budgetType,
        dailyBudget,
      });
      break;
    case CAMPAIGNS_API_COLUMN_NAME.EndDate:
      updateEndDate(bulkEditChanges, campaignId, bulkEditValues);
      break;
    case CAMPAIGNS_API_COLUMN_NAME.CampaignStatus:
      updateCampaignStatus(bulkEditChanges, campaignId, bulkEditValues, status);
      break;
    case CAMPAIGNS_API_COLUMN_NAME.BudgetRollover:
      updateBudgetRollover(bulkEditChanges, campaignId, bulkEditValues);
      break;
    case CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy:
      updateAmazonBiddingStrategy(bulkEditChanges, campaignId, bulkEditValues);
      break;
    case CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization:
      updateAmazonBidOptimization(
        bulkEditChanges,
        campaignId,
        bulkEditValues,
        selectedAdType,
        campaignAdFormat,
        status
      );
      break;
    case CAMPAIGNS_API_COLUMN_NAME.Portfolio:
      updatePortfolio(bulkEditChanges, campaignId, bulkEditValues);
      break;
    case CAMPAIGNS_API_COLUMN_NAME.Group:
      updateGroups(bulkEditChanges, campaignId, bulkEditValues);
      break;
    case CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal:
      updateAdvertisingGoal(
        bulkEditChanges,
        campaignId,
        bulkEditValues,
        advertisingGoal
      );
      break;
  }
};

const handleBulkEditViaColumnKey = ({
  bulkEditChanges,
  campaignId,
  tableChange,
  bulkEditValues,
  controlKey,
  targetingType,
  placementBidMultiplier,
}: HandleColumnKeyProps) => {
  const columnKey = controlKey.split('-')[0];
  const editControlKey = controlKey.split('-')[1];
  switch (columnKey) {
    case CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier:
      updateBuyBoxBidMultiplier({
        bulkEditChanges,
        campaignId,
        tableChange,
        bulkEditValues,
        controlKey,
        editControlKey,
        targetingType,
        placementBidMultiplier,
      });
      break;
    case CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier:
      updateSearchIngridBidMultiplier({
        bulkEditChanges,
        campaignId,
        tableChange,
        bulkEditValues,
        controlKey,
        editControlKey,
        targetingType,
        placementBidMultiplier,
      });
      break;
    case CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier:
      updateHomePageBidMultipliers(
        bulkEditChanges,
        campaignId,
        tableChange,
        bulkEditValues,
        controlKey,
        targetingType,
        placementBidMultiplier
      );
      break;
    case CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier:
      updateStockUpBidMultipliers(
        bulkEditChanges,
        campaignId,
        tableChange,
        bulkEditValues,
        controlKey,
        targetingType,
        placementBidMultiplier
      );
      break;
  }
};

export const handleCampaignStatusModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  salesChannel,
  values,
  adType,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  salesChannel: FlywheelSalesChannel | null;
  values: string[];
  adType: AdType | null;
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);
  if (!isNil(rowData)) {
    const {
      channelSettings: { status },
    } = rowData;
    if (
      (salesChannel === FlywheelSalesChannel.Walmart &&
        status !== CampaignStatus.Completed) ||
      (adType &&
        salesChannel === FlywheelSalesChannel.Amazon &&
        ADTYPES_ARRAY_SP_SD_SB_SBV.includes(adType))
    ) {
      if (isNil(currentBulkChanges[rowId])) {
        currentBulkChanges[rowId] = {};
      }

      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.CampaignStatus] =
        values[0];
    }
  }
  return { currentBulkChanges };
};

export const handleAutomationStatusModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  salesChannel,
  values,
  adType,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  salesChannel: FlywheelSalesChannel | null;
  values: string[];
  adType: AdType | null;
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);
  if (!isNil(rowData)) {
    if (
      adType &&
      salesChannel === FlywheelSalesChannel.Amazon &&
      ADTYPES_ARRAY_SB_SBV.includes(adType)
    ) {
      if (isNil(currentBulkChanges[rowId])) {
        currentBulkChanges[rowId] = {};
      }
      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.AutomationStatus] =
        values[0];
    }
  }
  return { currentBulkChanges };
};

export const handleMacsTargetModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  salesChannel,
  values,
  operators,
  adType,
  minMacsTarget,
  maxMacsTarget,
  inValidChangesCount,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  salesChannel: FlywheelSalesChannel | null;
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  adType: AdType | null;
  minMacsTarget: number;
  maxMacsTarget: number;
  inValidChangesCount: number;
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);
  let updatedValueChangesCount = inValidChangesCount;
  let value = getUpdatedValueFromOperator(
    rowData?.flywheelSettings?.macsTarget,
    bulkChanges[rowId]?.[CAMPAIGNS_API_COLUMN_NAME.MACSTarget],
    values[0],
    first(operators),
    NumericInputType.Percent
  );

  if (!isNil(rowData)) {
    if (
      salesChannel === FlywheelSalesChannel.Amazon &&
      adType &&
      ADTYPES_ARRAY_SB_SBV.includes(adType)
    ) {
      if (isNil(currentBulkChanges[rowId])) {
        currentBulkChanges[rowId] = {};
      }
      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MACSTarget] =
        parseFloat(value).toFixed(MONETARY_FRACTION_DIGITS);
    }

    updatedValueChangesCount = isMacsTargetInvalid(
      value,
      minMacsTarget,
      maxMacsTarget
    )
      ? inValidChangesCount + 1
      : inValidChangesCount;
  }
  return { currentBulkChanges, updatedValueChangesCount };
};

const getBidMultiplierInvalidCount = (
  updatedValue: string,
  invalidCount: number
) => (Number(updatedValue) < 0 ? 1 : invalidCount);

export const handlePlacementBidMultipliersModalvalueChangesHelper = ({
  rowId,
  values,
  operators,
  rowData,
  currentBulkChanges,
}: {
  rowId: string;
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  rowData: CampaignDetails | undefined;
  currentBulkChanges: TableCellChange;
}) => {
  let changes = cloneDeep(currentBulkChanges);
  let invalidCount = 0;
  if (values[1]) {
    const updatedValue = getUpdatedValueFromOperator(
      rowData?.channelSettings?.placementBidMultiplier?.buyBoxBidMultiplier,
      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier],
      values[1],
      operators?.[1],
      NumericInputType.Percent
    );
    changes[rowId][CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier] =
      updatedValue;

    invalidCount = getBidMultiplierInvalidCount(updatedValue, invalidCount);
  }
  if (values[2]) {
    const updatedValue = getUpdatedValueFromOperator(
      rowData?.channelSettings?.placementBidMultiplier?.homePageBidMultiplier,
      currentBulkChanges[rowId][
        CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier
      ],
      values[2],
      operators?.[2],
      NumericInputType.Percent
    );
    changes[rowId][CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier] =
      updatedValue;

    invalidCount = getBidMultiplierInvalidCount(updatedValue, invalidCount);
  }
  if (values[3]) {
    const updatedValue = getUpdatedValueFromOperator(
      rowData?.channelSettings?.placementBidMultiplier?.stockUpBidMultiplier,
      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier],
      values[3],
      operators?.[3],
      NumericInputType.Percent
    );
    changes[rowId][CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier] =
      updatedValue;
    invalidCount = getBidMultiplierInvalidCount(updatedValue, invalidCount);
  }
  if (values[4]) {
    const updatedValue = getUpdatedValueFromOperator(
      rowData?.channelSettings?.placementBidMultiplier
        ?.searchIngridBidMultiplier,
      currentBulkChanges[rowId][
        CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier
      ],
      values[4],
      operators?.[4],
      NumericInputType.Percent
    );
    changes[rowId][CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier] =
      updatedValue;
    invalidCount = getBidMultiplierInvalidCount(updatedValue, invalidCount);
  }
  return { changes, invalid: invalidCount };
};

export const handlePlacementBidMultipliersModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  values,
  operators,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
}) => {
  let currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find(
    (row: CampaignDetails) => row.campaignId === rowId
  );
  let invalidCount = 0;
  if (!isNil(rowData)) {
    const {
      campaignDetails: { targetingType },
    } = rowData;
    if (isNil(currentBulkChanges[rowId])) {
      currentBulkChanges[rowId] = {};
    }
    if (isManualCampaign(targetingType)) {
      if (values[0]) {
        const updatedValue = getUpdatedValueFromOperator(
          rowData?.channelSettings?.placementBidMultiplier
            ?.searchIngridBidMultiplier,
          currentBulkChanges[rowId][
            CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier
          ],
          values[0],
          first(operators),
          NumericInputType.Percent
        );
        currentBulkChanges[rowId][
          CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier
        ] = updatedValue;

        invalidCount = getBidMultiplierInvalidCount(updatedValue, invalidCount);
      }
      if (values[5]) {
        const updatedValue = getUpdatedValueFromOperator(
          rowData?.channelSettings?.placementBidMultiplier?.buyBoxBidMultiplier,
          currentBulkChanges[rowId][
            CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier
          ],
          values[5],
          operators?.[5],
          NumericInputType.Percent
        );
        currentBulkChanges[rowId][
          CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier
        ] = updatedValue;

        invalidCount = getBidMultiplierInvalidCount(updatedValue, invalidCount);
      }
    } else {
      const { changes, invalid } =
        handlePlacementBidMultipliersModalvalueChangesHelper({
          rowId,
          values,
          currentBulkChanges,
          operators,
          rowData,
        });
      currentBulkChanges = cloneDeep(changes);
      invalidCount = invalid;
    }
  }
  return { currentBulkChanges, invalidCount };
};

export const handlePlacementInclusionModalvalue = ({
  rowId,
  bulkChanges,
  campaignsTableData,
  values,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  campaignsTableData: CampaignDetails[];
  values: string[];
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  const rowData = campaignsTableData.find(
    (row: CampaignDetails) => row.campaignId === rowId
  );

  if (!isNil(rowData)) {
    const {
      campaignDetails: { targetingType },
    } = rowData;
    if (isManualCampaign(targetingType)) {
      if (isNil(currentBulkChanges[rowId])) {
        currentBulkChanges[rowId] = {};
      }
      currentBulkChanges[rowId][
        CAMPAIGNS_API_COLUMN_NAME.SearchCarouselInclusion
      ] = values[0];
      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.ItemBuyBoxPlacement] =
        values[1];
      currentBulkChanges[rowId][
        CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement
      ] = values[2];
    }
  }
  return { currentBulkChanges };
};

export const handlePlatformBidMultipliersModalvalue = ({
  rowId,
  bulkChanges,
  values,
  operators,
  rowData,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  rowData: CampaignDetails | undefined;
}) => {
  let invalidCount = 0;
  const currentBulkChanges = cloneDeep(bulkChanges);
  if (isNil(currentBulkChanges[rowId])) {
    currentBulkChanges[rowId] = {};
  }

  if (values[0]) {
    const updatedValue = getUpdatedValueFromOperator(
      rowData?.channelSettings?.platformBidMultiplier?.desktopBidMultiplier,
      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier],
      values[0],
      first(operators),
      NumericInputType.Percent
    );
    currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier] =
      updatedValue;
    invalidCount = getBidMultiplierInvalidCount(updatedValue, invalidCount);
  }

  if (values[1]) {
    const updatedValue = getUpdatedValueFromOperator(
      rowData?.channelSettings?.platformBidMultiplier?.appBidMultiplier,
      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier],
      values[1],
      operators?.[1],
      NumericInputType.Percent
    );
    currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier] =
      updatedValue;
    invalidCount = getBidMultiplierInvalidCount(updatedValue, invalidCount);
  }

  if (values[2]) {
    const updatedValue = getUpdatedValueFromOperator(
      rowData?.channelSettings?.platformBidMultiplier?.mobileBidMultiplier,
      currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier],
      values[2],
      operators?.[2],
      NumericInputType.Percent
    );
    currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier] =
      updatedValue;
    invalidCount = getBidMultiplierInvalidCount(updatedValue, invalidCount);
  }
  return { currentBulkChanges, invalidCount };
};

const getMinBidInvalidCount = (
  minBid: string,
  minBidMinRequiredValue: number,
  minBidMaxRequiredValue: number,
  inValidChangesCount: number,
  requiredMaxBid?: string
) =>
  !isNil(requiredMaxBid) &&
  !isNil(minBid) &&
  (isCurrentValueGreaterThanRequiredMaxValue(Number(requiredMaxBid), minBid) ||
    !isValueBetweenMinAndMaxValue(
      minBidMinRequiredValue,
      minBidMaxRequiredValue,
      minBid
    ))
    ? inValidChangesCount + 1
    : inValidChangesCount;

const getMaxBidInvalidCount = (
  maxBid: string,
  maxBidMinRequiredValue: number,
  maxBidMaxRequiredValue: number,
  inValidChangesCount: number,
  requiredMinBid?: string
) =>
  !isNil(requiredMinBid) &&
  !isNil(maxBid) &&
  (isCurrentValueGreaterThanRequiredMaxValue(Number(requiredMinBid), maxBid) ||
    !isValueBetweenMinAndMaxValue(
      maxBidMinRequiredValue,
      maxBidMaxRequiredValue,
      maxBid
    ))
    ? inValidChangesCount + 1
    : inValidChangesCount;

export const handleMinBidModalvalue = ({
  rowId,
  bulkChanges,
  values,
  operators,
  adType,
  salesChannel,
  campaignsTableData,
  campaignsTableDataForAllPages,
  inValidChangesCount,
  minBidMinAmazonSBV,
  maxBidMinAmazonSBV,
  minBidMinAmazonSB,
  maxBidMinAmazonSB,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  adType: AdType | null;
  salesChannel: FlywheelSalesChannel | null;
  campaignsTableData: CampaignDetails[];
  campaignsTableDataForAllPages: CampaignDetails[];
  inValidChangesCount: number;
  minBidMinAmazonSB: number;
  minBidMinAmazonSBV: number;
  maxBidMinAmazonSB: number;
  maxBidMinAmazonSBV: number;
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  let updatedValueChangesCount = inValidChangesCount;
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);
  if (!isNil(rowData)) {
    const rowDataFromAdGroupDataForAllPages = find(
      campaignsTableDataForAllPages,
      {
        [TABLE_UNIQ_KEY[ADS_MANAGER_CAMPAIGNS_TABLE_ID]]: rowId,
      }
    );
    if (
      handleAmazonChannelWithAdtype(salesChannel, adType, ADTYPES_ARRAY_SB_SBV)
    ) {
      if (isNil(currentBulkChanges[rowId])) {
        currentBulkChanges[rowId] = {};
      }
      if (
        isCampaignAdFormatProductCollectionOrSpotlight(
          rowData.campaignDetails.campaignAdFormat
        )
      ) {
        currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MinBid] =
          getUpdatedValueFromOperator(
            rowData?.flywheelSettings?.minBid?.amount,
            currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MinBid],
            values[0],
            first(operators)
          );
      } else {
        currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MinBid] =
          getUpdatedValueFromOperator(
            rowData?.flywheelSettings?.minBid?.amount,
            currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MinBid],
            values[1],
            operators?.[1]
          );
      }
      const maxBid =
        rowDataFromAdGroupDataForAllPages?.flywheelSettings.maxBid?.amount;
      const updatedMaxBid =
        currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MaxBid];
      const minBid =
        currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MinBid];

      const requiredMaxBid = updatedMaxBid || maxBid;

      let minBidMinRequiredValue = minBidMinAmazonSB;
      let minBidMaxRequiredValue = maxBidMinAmazonSB;

      if (adType === AdType.SponsoredBrandsVideo) {
        minBidMinRequiredValue = minBidMinAmazonSBV;
        minBidMaxRequiredValue = maxBidMinAmazonSBV;
      }
      updatedValueChangesCount = getMinBidInvalidCount(
        minBid,
        minBidMinRequiredValue,
        minBidMaxRequiredValue,
        inValidChangesCount,
        requiredMaxBid
      );
    }
  }
  return {
    currentBulkChanges,
    updatedValueChangesCount,
  };
};

export const handleMaxBidModalvalue = ({
  rowId,
  bulkChanges,
  values,
  operators,
  adType,
  salesChannel,
  campaignsTableData,
  campaignsTableDataForAllPages,
  inValidChangesCount,
  minBidMaxAmazonSB,
  minBidMaxAmazonSBV,
  maxBidMaxAmazonSB,
  maxBidMaxAmazonSBV,
}: {
  rowId: string;
  bulkChanges: TableCellChange;
  values: string[];
  operators?: BulkEditModalNumericValueOperator[];
  adType: AdType | null;
  salesChannel: FlywheelSalesChannel | null;
  campaignsTableData: CampaignDetails[];
  campaignsTableDataForAllPages: CampaignDetails[];
  inValidChangesCount: number;
  minBidMaxAmazonSB: number;
  minBidMaxAmazonSBV: number;
  maxBidMaxAmazonSB: number;
  maxBidMaxAmazonSBV: number;
}) => {
  const currentBulkChanges = cloneDeep(bulkChanges);
  let updatedValueChangesCount = inValidChangesCount;
  const rowData = campaignsTableData.find((data) => data.campaignId === rowId);
  const rowDataFromAdGroupDataForAllPages = find(
    campaignsTableDataForAllPages,
    {
      [TABLE_UNIQ_KEY[ADS_MANAGER_CAMPAIGNS_TABLE_ID]]: rowId,
    }
  );

  if (!isNil(rowData)) {
    if (
      handleAmazonChannelWithAdtype(salesChannel, adType, ADTYPES_ARRAY_SB_SBV)
    ) {
      if (isNil(currentBulkChanges[rowId])) {
        currentBulkChanges[rowId] = {};
      }
      if (
        isCampaignAdFormatProductCollectionOrSpotlight(
          rowData.campaignDetails.campaignAdFormat
        )
      ) {
        currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MaxBid] =
          getUpdatedValueFromOperator(
            rowData?.flywheelSettings?.maxBid?.amount,
            currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MaxBid],
            values[0],
            first(operators)
          );
      } else {
        currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MaxBid] =
          getUpdatedValueFromOperator(
            rowData?.flywheelSettings?.maxBid?.amount,
            currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MaxBid],
            values[1],
            operators?.[1]
          );
      }

      const minBid =
        rowDataFromAdGroupDataForAllPages?.flywheelSettings.minBid?.amount;
      const updatedMinBid =
        currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MinBid];
      const maxBid =
        currentBulkChanges[rowId][CAMPAIGNS_API_COLUMN_NAME.MaxBid];

      const requiredMinBid = updatedMinBid || minBid;

      let maxBidMinRequiredValue = minBidMaxAmazonSB;
      let maxBidMaxRequiredValue = maxBidMaxAmazonSB;

      if (adType === AdType.SponsoredBrandsVideo) {
        maxBidMinRequiredValue = minBidMaxAmazonSBV;
        maxBidMaxRequiredValue = maxBidMaxAmazonSBV;
      }

      updatedValueChangesCount = getMaxBidInvalidCount(
        maxBid,
        maxBidMinRequiredValue,
        maxBidMaxRequiredValue,
        inValidChangesCount,
        requiredMinBid
      );
    }
  }
  return {
    currentBulkChanges,
    updatedValueChangesCount,
  };
};

export const handleCampaignsBulkEditApplyFunction = ({
  intl,
  bulkMenuValue,
  tableChange,
  campaignsTableData,
  salesChannel,
  adType,
  values,
  operators,
  campaignsTableDataForAllPages,
  allMerchants,
  totalBudgetMin,
  totalBudgetMax,
  minDailyBudgetWalmartVendor,
  minTotalBudgetWalmartVendor,
  minDailyBudgetWalmartSeller,
  minTotalBudgetWalmartSeller,
  maxDailyBudgetWalmartVendor,
  maxTotalBudgetWalmartVendor,
  maxDailyBudgetWalmartSeller,
  maxTotalBudgetWalmartSeller,
  minDailyBudgetAmazonSPSB,
  maxDailyBudgetAmazonSPSB,
  minBidMinAmazonSB,
  maxBidMinAmazonSB,
  minBidMinAmazonSBV,
  maxBidMinAmazonSBV,
  minBidMaxAmazonSB,
  maxBidMaxAmazonSB,
  minBidMaxAmazonSBV,
  maxBidMaxAmazonSBV,
  minMacsTarget,
  maxMacsTarget,
  minProductMultiplier,
  maxProductMultiplier,
  belowTopOfSearchBidMultiplierMinAmazonSB,
  belowTopOfSearchBidMultiplierMaxAmazonSB,
  placementBidMultiplierMinAmazonSP,
  placementBidMultiplierMaxAmazonSP,
  toasts,
  endDateOption,
  updateCells,
  dispatch,
  handleCampaignsBulkEditClose,
}: HandleCampaignsBulkEditApplyFunctionProps) => {
  if (isNil(bulkMenuValue)) {
    return;
  }
  let inValidChangesCount = 0;
  const selectedRowsCount = tableChange.select.rows.length;
  let bulkChanges = cloneDeep(tableChange.cell);
  // eslint-disable-next-line max-statements
  tableChange.select.rows.forEach((rowId: string) => {
    switch (bulkMenuValue) {
      case CampaignBulkEditModalValues.DailyBudget: {
        const { currentBulkChanges, updatedValueChangesCount } =
          handleDailyBudgetModalvalue({
            rowId,
            bulkChanges,
            bulkMenuValue,
            campaignsTableData,
            salesChannel,
            adType,
            values,
            operators,
            campaignsTableDataForAllPages,
            allMerchants,
            minDailyBudgetWalmartVendor,
            minDailyBudgetWalmartSeller,
            maxDailyBudgetWalmartVendor,
            maxDailyBudgetWalmartSeller,
            minDailyBudgetAmazonSPSB,
            maxDailyBudgetAmazonSPSB,
            inValidChangesCount,
          });
        bulkChanges = cloneDeep(currentBulkChanges);
        inValidChangesCount = updatedValueChangesCount;
        break;
      }
      case CampaignBulkEditModalValues.LifetimeBudget: {
        const { currentBulkChanges, updatedValueChangesCount } =
          handleLifetimeBudgetModalvalue({
            rowId,
            bulkChanges,
            campaignsTableData,
            salesChannel,
            values,
            operators,
            campaignsTableDataForAllPages,
            allMerchants,
            minTotalBudgetWalmartVendor,
            minTotalBudgetWalmartSeller,
            maxTotalBudgetWalmartVendor,
            maxTotalBudgetWalmartSeller,
            totalBudgetMin,
            totalBudgetMax,
            inValidChangesCount,
          });
        bulkChanges = cloneDeep(currentBulkChanges);
        inValidChangesCount = updatedValueChangesCount;
        break;
      }
      case CampaignBulkEditModalValues.BudgetRollover: {
        const { currentBulkChanges } = handleBudgetRolloverModalvalue({
          rowId,
          bulkChanges,
          campaignsTableData,
          values,
        });
        bulkChanges = cloneDeep(currentBulkChanges);
        break;
      }
      case CampaignBulkEditModalValues.AmazonBiddingStrategy: {
        const { currentBulkChanges } = handleAmazonBiddingStrategyModalvalue({
          rowId,
          bulkChanges,
          campaignsTableData,
          values,
        });
        bulkChanges = cloneDeep(currentBulkChanges);
        break;
      }
      case CampaignBulkEditModalValues.Portfolio: {
        const { currentBulkChanges } = handlePortfolioModalvalue({
          rowId,
          bulkChanges,
          campaignsTableData,
          values,
        });
        bulkChanges = cloneDeep(currentBulkChanges);
        break;
      }
      case CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSB: {
        const { currentBulkChanges, invalidCount } =
          handlePlacementBidMultipliersAmazonSBModalvalue({
            rowId,
            bulkChanges,
            campaignsTableData,
            updateCells,
            values,
            operators,
            belowTopOfSearchBidMultiplierMinAmazonSB,
            belowTopOfSearchBidMultiplierMaxAmazonSB,
            minProductMultiplier,
            maxProductMultiplier,
          });
        bulkChanges = cloneDeep(currentBulkChanges);
        inValidChangesCount += invalidCount;
        break;
      }
      case CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSP: {
        const { currentBulkChanges, invalidCount } =
          handlePlacementBidMultipliersAmazonSPModalvalue({
            rowId,
            bulkChanges,
            campaignsTableData,
            values,
            operators,
            placementBidMultiplierMinAmazonSP,
            placementBidMultiplierMaxAmazonSP,
          });
        bulkChanges = cloneDeep(currentBulkChanges);
        inValidChangesCount += invalidCount;
        break;
      }
      case CampaignBulkEditModalValues.EndDate: {
        const { currentBulkChanges } = handleEndDateModalvalue({
          rowId,
          bulkChanges,
          campaignsTableData,
          salesChannel,
          values,
          adType,
          endDateOption,
        });
        bulkChanges = cloneDeep(currentBulkChanges);
        break;
      }
      case CampaignBulkEditModalValues.AmazonBidOptimization: {
        const { currentBulkChanges } = handleAmazonBidOptimizationModalvalue({
          rowId,
          bulkChanges,
          campaignsTableData,
          salesChannel,
          values,
          adType,
        });
        bulkChanges = cloneDeep(currentBulkChanges);
        break;
      }
      case CampaignBulkEditModalValues.CampaignStatus: {
        const { currentBulkChanges } = handleCampaignStatusModalvalue({
          rowId,
          bulkChanges,
          campaignsTableData,
          salesChannel,
          values,
          adType,
        });
        bulkChanges = cloneDeep(currentBulkChanges);
        break;
      }
      case CampaignBulkEditModalValues.AutomationStatus: {
        const { currentBulkChanges } = handleAutomationStatusModalvalue({
          rowId,
          bulkChanges,
          campaignsTableData,
          salesChannel,
          values,
          adType,
        });
        bulkChanges = cloneDeep(currentBulkChanges);
        break;
      }
      case CampaignBulkEditModalValues.MacsTarget: {
        const { currentBulkChanges, updatedValueChangesCount } =
          handleMacsTargetModalvalue({
            rowId,
            bulkChanges,
            campaignsTableData,
            salesChannel,
            values,
            operators,
            adType,
            minMacsTarget,
            maxMacsTarget,
            inValidChangesCount,
          });
        bulkChanges = cloneDeep(currentBulkChanges);
        inValidChangesCount = updatedValueChangesCount;
        break;
      }
      case CampaignBulkEditModalValues.PlacementBidMultipliers: {
        const { currentBulkChanges, invalidCount } =
          handlePlacementBidMultipliersModalvalue({
            rowId,
            bulkChanges,
            campaignsTableData,
            values,
            operators,
          });
        bulkChanges = cloneDeep(currentBulkChanges);
        inValidChangesCount += invalidCount;
        break;
      }
      case CampaignBulkEditModalValues.PlacementInclusion: {
        const { currentBulkChanges } = handlePlacementInclusionModalvalue({
          rowId,
          bulkChanges,
          campaignsTableData,
          values,
        });
        bulkChanges = cloneDeep(currentBulkChanges);
        break;
      }
      case CampaignBulkEditModalValues.PlatformBidMultipliers: {
        const { currentBulkChanges, invalidCount } =
          handlePlatformBidMultipliersModalvalue({
            rowId,
            bulkChanges,
            values,
            operators,
            rowData: campaignsTableData.find(
              (data) => data.campaignId === rowId
            ),
          });
        bulkChanges = cloneDeep(currentBulkChanges);
        inValidChangesCount += invalidCount;
        break;
      }
      case CampaignBulkEditModalValues.MinBid: {
        const { currentBulkChanges, updatedValueChangesCount } =
          handleMinBidModalvalue({
            rowId,
            bulkChanges,
            values,
            operators,
            adType,
            salesChannel,
            campaignsTableData,
            campaignsTableDataForAllPages,
            minBidMinAmazonSBV,
            maxBidMinAmazonSBV,
            minBidMinAmazonSB,
            maxBidMinAmazonSB,
            inValidChangesCount,
          });
        bulkChanges = cloneDeep(currentBulkChanges);
        inValidChangesCount = updatedValueChangesCount;
        break;
      }
      case CampaignBulkEditModalValues.MaxBid: {
        const { currentBulkChanges, updatedValueChangesCount } =
          handleMaxBidModalvalue({
            rowId,
            bulkChanges,
            values,
            operators,
            adType,
            salesChannel,
            campaignsTableData,
            campaignsTableDataForAllPages,
            inValidChangesCount,
            maxBidMaxAmazonSB,
            minBidMaxAmazonSBV,
            minBidMaxAmazonSB,
            maxBidMaxAmazonSBV,
          });
        bulkChanges = cloneDeep(currentBulkChanges);
        inValidChangesCount = updatedValueChangesCount;
        break;
      }
      default:
    }
  });

  dispatch(
    tableActions.applyBulkChanges({
      tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
      bulkChanges,
      columnDataMapping: EDIT_CAMPAIGNS_API_COLUMN_DATA_MAPPING,
      uniqKey: TABLE_UNIQ_KEY[ADS_MANAGER_CAMPAIGNS_TABLE_ID],
      percentageBasedColumns: PERCENTAGE_BASED_COLUMNS,
      booleanColumns: BOOLEAN_COLUMNS,
    })
  );

  if (inValidChangesCount && selectedRowsCount) {
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.ADVERTISING_OPTIMIZATION_BULKEDIT_MODAL_ERROR_TOAST_TITLE,
      }),
      description: intl.formatMessage(
        {
          id: I18nKey.ADVERTISING_OPTIMIZATION_BULKEDIT_MODAL_ERROR_TOAST_MESSAGE,
        },
        {
          invalidNumber: inValidChangesCount,
          totalNumber: selectedRowsCount,
        }
      ),
      type: toastType.Attention,
      dataTestId: 'ao_campaignNameIncompleteChangesError',
    });
    inValidChangesCount = 0;
  }
  handleCampaignsBulkEditClose();
};

const updateBulkEditNumericValue = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  columnName: string,
  currentValue: string | number,
  tableChange: TableChange,
  numericInputType: NumericInputType,
  value?: any
) => {
  if (isNil(bulkEditChanges[campaignId])) {
    bulkEditChanges[campaignId] = {};
  }
  bulkEditChanges[campaignId][columnName] = getUpdatedValueFromOperator(
    currentValue,
    tableChange.cell[campaignId]
      ? tableChange.cell[campaignId][columnName]
      : '',
    value?.value,
    value?.operator,
    numericInputType
  );
};

const updateBuyBoxBidMultiplier = (data: {
  bulkEditChanges: TableCellChange;
  campaignId: string;
  tableChange: TableChange;
  bulkEditValues: BulkEditValues;
  controlKey: string;
  editControlKey: string;
  targetingType?: CampaignTargetingType;
  placementBidMultiplier?: PlacementBidMultiplier;
}) => {
  if (
    (data.editControlKey ===
      CampaignBulkEditControls.ManualCampaignsPlacementBidMultipliers &&
      isManualCampaign(data.targetingType)) ||
    (data.editControlKey ===
      CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers &&
      isAutoCampaign(data.targetingType))
  ) {
    updateBulkEditNumericValue(
      data.bulkEditChanges,
      data.campaignId,
      CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier,
      data.placementBidMultiplier?.buyBoxBidMultiplier ?? '',
      data.tableChange,
      NumericInputType.Percent,
      data.bulkEditValues[data.controlKey]
    );
  }
};

const updateSearchIngridBidMultiplier = (data: {
  bulkEditChanges: TableCellChange;
  campaignId: string;
  tableChange: TableChange;
  bulkEditValues: BulkEditValues;
  controlKey: string;
  editControlKey: string;
  targetingType?: CampaignTargetingType;
  placementBidMultiplier?: PlacementBidMultiplier;
}) => {
  if (
    (data.editControlKey ===
      CampaignBulkEditControls.ManualCampaignsPlacementBidMultipliers &&
      isManualCampaign(data.targetingType)) ||
    (data.editControlKey ===
      CampaignBulkEditControls.AutoCampaignsPlacementBidMultipliers &&
      isAutoCampaign(data.targetingType))
  ) {
    updateBulkEditNumericValue(
      data.bulkEditChanges,
      data.campaignId,
      CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier,
      data.placementBidMultiplier?.searchIngridBidMultiplier ?? '',
      data.tableChange,
      NumericInputType.Percent,
      data.bulkEditValues[data.controlKey]
    );
  }
};

const updateHomePageBidMultipliers = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  tableChange: TableChange,
  bulkEditValues: BulkEditValues,
  controlKey: string,
  targetingType?: CampaignTargetingType,
  placementBidMultiplier?: PlacementBidMultiplier
) => {
  if (isAutoCampaign(targetingType)) {
    updateBulkEditNumericValue(
      bulkEditChanges,
      campaignId,
      CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier,
      placementBidMultiplier?.homePageBidMultiplier ?? '',
      tableChange,
      NumericInputType.Percent,
      bulkEditValues[controlKey]
    );
  }
};

const updateStockUpBidMultipliers = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  tableChange: TableChange,
  bulkEditValues: BulkEditValues,
  controlKey: string,
  targetingType?: CampaignTargetingType,
  placementBidMultiplier?: PlacementBidMultiplier
) => {
  if (isAutoCampaign(targetingType)) {
    updateBulkEditNumericValue(
      bulkEditChanges,
      campaignId,
      CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier,
      placementBidMultiplier?.stockUpBidMultiplier ?? '',
      tableChange,
      NumericInputType.Percent,
      bulkEditValues[controlKey]
    );
  }
};

const updatePlacementInclusionBidMultipliers = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  bulkEditValues: BulkEditValues,
  controlKey: string,
  targetingType?: CampaignTargetingType
) => {
  if (isManualCampaign(targetingType)) {
    if (isNil(bulkEditChanges[campaignId])) {
      bulkEditChanges[campaignId] = {};
    }
    bulkEditChanges[campaignId][
      CAMPAIGNS_API_COLUMN_NAME.SearchCarouselInclusion
    ] = bulkEditValues[controlKey].includes(
      CAMPAIGNS_API_COLUMN_NAME.SearchCarouselInclusion
    )
      ? PlacementStatus.Included
      : PlacementStatus.Excluded;
    bulkEditChanges[campaignId][CAMPAIGNS_API_COLUMN_NAME.ItemBuyBoxPlacement] =
      bulkEditValues[controlKey].includes(
        CAMPAIGNS_API_COLUMN_NAME.ItemBuyBoxPlacement
      )
        ? PlacementStatus.Included
        : PlacementStatus.Excluded;
    bulkEditChanges[campaignId][
      CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement
    ] = bulkEditValues[controlKey].includes(
      CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement
    )
      ? PlacementStatus.Included
      : PlacementStatus.Excluded;
  }
};

const updateLifetimeBudget = (data: {
  bulkEditChanges: TableCellChange;
  campaignId: string;
  bulkEditValues: BulkEditValues;
  selectedSalesChannel: string;
  tableChange: TableChange;
  controlKey: string;
  budgetType?: string;
  totalBudget?: MoneyWithAmountInString;
}) => {
  if (
    ([BudgetType.Total, BudgetType.Both].includes(
      data.budgetType as BudgetType
    ) &&
      data.selectedSalesChannel === FlywheelSalesChannel.Walmart) ||
    ([BudgetType.Total, BudgetType.Lifetime].includes(
      data.budgetType as BudgetType
    ) &&
      data.selectedSalesChannel === FlywheelSalesChannel.Amazon)
  ) {
    if (isNil(data.bulkEditChanges[data.campaignId])) {
      data.bulkEditChanges[data.campaignId] = {};
    }
    data.bulkEditChanges[data.campaignId][
      CAMPAIGNS_API_COLUMN_NAME.TotalBudget
    ] = getUpdatedValueFromOperator(
      isNil(data.totalBudget) ? '' : data.totalBudget.amount,
      data.tableChange.cell[data.campaignId]
        ? data.tableChange.cell[data.campaignId][
            CAMPAIGNS_API_COLUMN_NAME.TotalBudget
          ]
        : '',
      data.bulkEditValues[data.controlKey]?.value,
      data.bulkEditValues[data.controlKey]?.operator,
      NumericInputType.Currency
    );
  }
};

const updateDailyBudget = (data: {
  bulkEditChanges: TableCellChange;
  campaignId: string;
  bulkEditValues: BulkEditValues;
  selectedSalesChannel: FlywheelSalesChannel;
  selectedAdType: AdType;
  tableChange: TableChange;
  controlKey: string;
  budgetType?: string;
  dailyBudget?: MoneyWithAmountInString;
}) => {
  if (
    (data.selectedSalesChannel === FlywheelSalesChannel.Walmart &&
      (data.budgetType === BudgetType.Daily ||
        data.budgetType === BudgetType.Both)) ||
    handleAmazonChannelWithAdtype(
      data.selectedSalesChannel,
      data.selectedAdType,
      ADTYPES_ARRAY_SP_SD_SB_SBV
    )
  ) {
    if (isNil(data.bulkEditChanges[data.campaignId])) {
      data.bulkEditChanges[data.campaignId] = {};
    }

    data.bulkEditChanges[data.campaignId][
      CAMPAIGNS_API_COLUMN_NAME.DailyBudget
    ] = getUpdatedValueFromOperator(
      isNil(data.dailyBudget) ? '' : data.dailyBudget.amount,
      data.tableChange.cell[data.campaignId]
        ? data.tableChange.cell[data.campaignId][
            CAMPAIGNS_API_COLUMN_NAME.DailyBudget
          ]
        : '',
      data.bulkEditValues[data.controlKey]?.value,
      data.bulkEditValues[data.controlKey]?.operator,
      NumericInputType.Currency
    );
  }
};

const updateEndDate = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  bulkEditValues: BulkEditValues
) => {
  if (!isNil(bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.EndDate])) {
    if (isNil(bulkEditChanges[campaignId])) {
      bulkEditChanges[campaignId] = {};
    }
    bulkEditChanges[campaignId][CAMPAIGNS_API_COLUMN_NAME.EndDate] =
      bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.EndDate] === 'NoDate'
        ? ''
        : DateTime.fromJSDate(
            bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.EndDate]
          ).toFormat(CAMPAIGN_DATE_FORMAT);
  }
};

const updateAdvertisingGoal = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  bulkEditValues: BulkEditValues,
  advertisingGoal?: AdvertisingGoal
) => {
  if (advertisingGoal && SMART_GOALS.includes(advertisingGoal)) {
    if (isNil(bulkEditChanges[campaignId])) {
      bulkEditChanges[campaignId] = {};
    }
    bulkEditChanges[campaignId][CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal] =
      bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal];
  }
};

const updateCampaignStatus = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  bulkEditValues: BulkEditValues,
  status?: CampaignStatus
) => {
  if (!isCampaignStatusArchived(status)) {
    if (isNil(bulkEditChanges[campaignId])) {
      bulkEditChanges[campaignId] = {};
    }
    bulkEditChanges[campaignId][CAMPAIGNS_API_COLUMN_NAME.CampaignStatus] =
      bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.CampaignStatus];
  }
};

const updateBudgetRollover = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  bulkEditValues: BulkEditValues
) => {
  if (isNil(bulkEditChanges[campaignId])) {
    bulkEditChanges[campaignId] = {};
  }
  bulkEditChanges[campaignId][CAMPAIGNS_API_COLUMN_NAME.BudgetRollover] =
    bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.BudgetRollover];
};

const updateAmazonBiddingStrategy = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  bulkEditValues: BulkEditValues
) => {
  if (isNil(bulkEditChanges[campaignId])) {
    bulkEditChanges[campaignId] = {};
  }
  bulkEditChanges[campaignId][CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy] =
    bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy];
};

const updateAmazonBidOptimization = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  bulkEditValues: BulkEditValues,
  selectedAdType: AdType,
  campaignAdFormat?: CampaignAdFormat,
  status?: CampaignStatus
) => {
  if (
    ADTYPES_ARRAY_SB_SBV.includes(selectedAdType) ||
    (campaignAdFormat === CampaignAdFormat.ProductCollection &&
      !isCampaignStatusArchived(status) &&
      status !==
        bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization])
  ) {
    if (isNil(bulkEditChanges[campaignId])) {
      bulkEditChanges[campaignId] = {};
    }
    bulkEditChanges[campaignId][
      CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization
    ] = bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization];
  }
};

const updatePortfolio = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  bulkEditValues: BulkEditValues
) => {
  if (isNil(bulkEditChanges[campaignId])) {
    bulkEditChanges[campaignId] = {};
  }
  bulkEditChanges[campaignId][CAMPAIGNS_API_COLUMN_NAME.Portfolio] =
    bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.Portfolio];
};

const updateGroups = (
  bulkEditChanges: TableCellChange,
  campaignId: string,
  bulkEditValues: BulkEditValues
) => {
  if (isNil(bulkEditChanges[campaignId])) {
    bulkEditChanges[campaignId] = {};
  }
  bulkEditChanges[campaignId][CAMPAIGNS_API_COLUMN_NAME.Group] =
    bulkEditValues[CAMPAIGNS_API_COLUMN_NAME.Group];
};

const determineBulkEditKeyHandling = ({
  controlKeys,
  selectedCampaigns,
  bulkEditValues,
  bulkEditChanges,
  tableChange,
  selectedSalesChannel,
  selectedAdType,
}: {
  controlKeys: string[];
  selectedCampaigns: CampaignDetails[];
  bulkEditChanges: TableCellChange;
  tableChange: TableChange;
  bulkEditValues: BulkEditValues;
  selectedSalesChannel: FlywheelSalesChannel;
  selectedAdType: AdType;
}) => {
  for (const element of controlKeys) {
    const controlKey = element;
    for (const element of selectedCampaigns) {
      const {
        campaignId,
        campaignDetails: { targetingType, campaignAdFormat, advertisingGoal },
        channelSettings: {
          placementBidMultiplier,
          platformBidMultiplier,
          dailyBudget,
          budgetType,
          totalBudget,
          status,
        },
      } = element;
      if (
        controlKey.indexOf('-') !== -1 &&
        !isCampaignStatusArchived(status) &&
        !isNil(bulkEditValues[controlKey])
      ) {
        handleBulkEditViaColumnKey({
          bulkEditChanges,
          campaignId,
          tableChange,
          bulkEditValues,
          controlKey,
          targetingType,
          placementBidMultiplier,
        });
      } else if (
        !isCampaignStatusArchived(status) &&
        !isNil(bulkEditValues[controlKey])
      ) {
        handleBulkEditViaControlKey({
          bulkEditChanges,
          campaignId,
          tableChange,
          bulkEditValues,
          controlKey,
          targetingType,
          placementBidMultiplier,
          platformBidMultiplier,
          selectedSalesChannel,
          budgetType,
          totalBudget,
          selectedAdType,
          dailyBudget,
          status,
          campaignAdFormat,
          advertisingGoal,
        });
      }
    }
  }
};

export const updateCampaignsBulkEditChanges = ({
  bulkEditChanges,
  bulkEditValues,
  campaignsBulkEditControlCongiguration,
  selectedAdType,
  selectedCampaigns,
  selectedSalesChannel,
  selectionEntries,
  tableChange,
}: {
  selectionEntries: [key: string, value: any][];
  campaignsBulkEditControlCongiguration: EditControlProps[];
  selectedCampaigns: CampaignDetails[];
  bulkEditChanges: TableCellChange;
  tableChange: TableChange;
  bulkEditValues: BulkEditValues;
  selectedSalesChannel: FlywheelSalesChannel;
  selectedAdType: AdType;
}) => {
  for (const element of selectionEntries) {
    const [editKey, value] = element;
    if (value) {
      const controlKeys = campaignsBulkEditControlCongiguration
        .find((bulkEditControl) => editKey === bulkEditControl.editKey)
        ?.controls?.map(({ columnKey }) => columnKey);
      if (controlKeys) {
        determineBulkEditKeyHandling({
          controlKeys,
          selectedCampaigns,
          bulkEditValues,
          bulkEditChanges,
          tableChange,
          selectedSalesChannel,
          selectedAdType,
        });
      }
    }
  }
};
