import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { FAMApiClient } from '../../../../../lib/clients/FAMApiClient';
import {
  FlywheelSalesChannel,
  MerchantType,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../../ConnectModalWrapper';
import {
  ConnectWalmartAdsData,
  DEFAULT_CONNECT_ADS_DATA,
  ModalContent,
} from './content';
import { ModalFooter } from './footer';
import { WalmartOAuthStatus } from '../walmartFlashScreens';

export interface ConnectAdsDataWalmartModalProps {
  readonly famClient: FAMApiClient;
  readonly accountId: string;
  readonly goBack: () => void;
  readonly authorize: (
    correlationId?: string,
    _?: ConnectWalmartAdsData
  ) => void;
  readonly closeModal?: () => void;
  readonly advertisingId?: string;
  readonly extMerchantId?: string;
  readonly walmartMerchantType: MerchantType;
  readonly walmartOAuthStatus: WalmartOAuthStatus;
  readonly errorMessage?: string;
}

export const ConnectAdsDataWalmartModal: React.FC<
  ConnectAdsDataWalmartModalProps
> = ({
  famClient,
  accountId,
  goBack,
  authorize,
  closeModal,
  advertisingId,
  extMerchantId,
  walmartMerchantType,
  walmartOAuthStatus,
  errorMessage,
}) => {
  const intl = useIntl();
  const [HEADER] = [I18nKey.FLASH_MODALS_WALMART_MODAL_HEADER].map((id) =>
    intl.formatMessage({ id })
  );

  const [isValid, setIsValid] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [tryAgain, setTryAgain] = useState<boolean>(false);

  const [connectWalmartAdsData, setConnectWalmartAdsData] =
    useState<ConnectWalmartAdsData>(DEFAULT_CONNECT_ADS_DATA);

  useEffect(() => {
    setIsValid(
      connectWalmartAdsData.advertiserId !== '' &&
        (connectWalmartAdsData.sellerId !== '' ||
          connectWalmartAdsData.supplierId !== '')
    );
  }, [connectWalmartAdsData]);

  const connect = async () => {
    setIsProcessing(true);
    setTryAgain(false);
    let correlationId = '';
    try {
      if (connectWalmartAdsData.sellerId) {
        correlationId = await famClient
          .connectWalmartAdData(accountId, {
            advertiserId: connectWalmartAdsData.advertiserId,
            sellerId: connectWalmartAdsData.sellerId,
          })
          .then((res) => res.id);
      } else if (connectWalmartAdsData.supplierId) {
        correlationId = await famClient
          .connectWalmartAdData(accountId, {
            advertiserId: connectWalmartAdsData.advertiserId,
            supplierId: connectWalmartAdsData.supplierId,
          })
          .then((res) => res.id);
      }
      authorize(correlationId, connectWalmartAdsData);
    } catch {
      setTryAgain(true);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <ConnectModalWrapper
      dataTestId="connect_ads_data_walmart_modal"
      isOpen
      closeModal={closeModal}
      headerText={HEADER}
      content={
        <ModalContent
          connectWalmartAdsData={connectWalmartAdsData}
          setConnectWalmartAdsData={setConnectWalmartAdsData}
          advertisingId={advertisingId}
          extMerchantId={extMerchantId}
          walmartMerchantType={walmartMerchantType}
          walmartOAuthStatus={walmartOAuthStatus}
          errorMessage={errorMessage}
        />
      }
      footer={
        <ModalFooter
          goBack={goBack}
          authorize={connect}
          isProcessing={
            isProcessing ||
            walmartOAuthStatus === WalmartOAuthStatus.IN_PROGRESS
          }
          isValid={isValid}
          tryAgain={tryAgain}
        />
      }
      headerChannelType={FlywheelSalesChannel.Walmart}
    />
  );
};

ConnectAdsDataWalmartModal.displayName = 'ConnectAdsDataWalmartModal';
