import { OutOfStockProductsSummaryResponseV2 } from '../../types/SKUSharedTypes';
import { Factory } from 'fishery';

export const OutOfStockTopProductsResponse =
  Factory.define<OutOfStockProductsSummaryResponseV2>(() => ({
    performanceAggregate: {
      estimatedMissedSales: {
        amount: '18452.71',
        currency: 'USD',
      },
    },
    products: [
      {
        productCatalogId: '575ead97-6b39-5003-b13f-9f007c12b8dd',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        merchantCountryId: 'a13d7f23-5930-4d8b-bfe9-4aa08c69f548',
        skuDetailsFields: {
          sku: '174-0834-000068FBA',
          name: 'Flower House FHPC100-DCBLK Pumpkin Loveseat, Black/Sand Cover',
          imageUrl: 'https://m.media-amazon.com/images/I/81iXlmOyHcL.jpg',
          extItemDetail: {
            extItemId: 'B08JD1B9NM',
            extItemType: 'ASIN',
            salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
            salesChannelName: 'Amazon',
          },
        },
        outOfStockDate: '2023-12-22',
        lastSalePrice: {
          amount: '499.99',
          currency: 'USD',
        },
        advertisedDetails: {
          campaignCount: true,
          associatedCampaignIds: [],
        },
        performance: {
          rateOfSale: 7.13,
          unitsSold: 214,
          totalSales: {
            amount: '87892.91',
            currency: 'USD',
          },
          estimatedMissedSales: {
            amount: '14259.71',
            currency: 'USD',
          },
        },
      },
      {
        productCatalogId: '77ed9c84-8ad0-588f-afd6-92eb2f6ba7ee',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        merchantCountryId: 'a13d7f23-5930-4d8b-bfe9-4aa08c69f548',
        parentProductCatalogId: '299d4e2b-c7c1-569b-9866-ff7c6c5c2417',
        skuDetailsFields: {
          sku: '195-0833-000061FBA',
          name: 'Pulsar Products GD400BN, 4000W Portable super-quiet Dual Fuel & Parallel Capability, RV-Ready, CARB Compliant',
          imageUrl: 'https://m.media-amazon.com/images/I/71hFcELrhoL.jpg',
          extItemDetail: {
            extItemId: 'B0BHXLT5D9',
            extItemType: 'ASIN',
            salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
            salesChannelName: 'Amazon',
          },
        },
        outOfStockDate: '2023-12-24',
        lastSalePrice: {
          amount: '599.0',
          currency: 'USD',
        },
        advertisedDetails: {
          campaignCount: true,
          associatedCampaignIds: [],
        },
        performance: {
          rateOfSale: 3.5,
          unitsSold: 105,
          totalSales: {
            amount: '55457.86',
            currency: 'USD',
          },
          estimatedMissedSales: {
            amount: '4193.0',
            currency: 'USD',
          },
        },
      },
    ],
  }));
