import { Factory } from 'fishery';

import { BacnTagsResponse } from '../../types/AIPoweredSharedTypes';

export const BrandsAndCompetitors = Factory.define<BacnTagsResponse>(() => ({
  brand: [
    {
      id: '1',
      name: 'Brand 1',
    },
    {
      id: '2',
      name: 'Brand 2',
    },
    {
      id: '3',
      name: 'Brand 3',
    },
    {
      id: '4',
      name: 'Brand 4',
    },
    {
      id: '5',
      name: 'Brand 5',
    },
  ],
  competitor: [
    {
      id: '1',
      name: 'Competitor 1',
    },
    {
      id: '2',
      name: 'Competitor 2',
    },
    {
      id: '3',
      name: 'Competitor 3',
    },
    {
      id: '4',
      name: 'Competitor 4',
    },
    {
      id: '5',
      name: 'Competitor 5',
    },
  ],
}));
