import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const BlogLoadingState: React.FC = () => {
  return (
    <div className="flex flex-row items-start justify-center w-434 h-96 mr-56">
      <div className="flex self-stretch h-full w-180 items-center">
        <Skeleton height={94} width={180} />
      </div>
      <div className="flex flex-col w-236 h-96 px-16">
        <Skeleton height={30} width={236} className="mb-8" />
        <Skeleton height={52} width={236} />
      </div>
    </div>
  );
};

BlogLoadingState.displayName = 'BlogLoadingState';
