import {
  AlertTriangleIcon,
  Icon,
  IconSize,
  TextLink,
  TextLinkSize,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { isHeroMetricsResultUndefined } from './utils';
import {
  AsyncRequest,
  asyncRequestIsComplete,
} from '../../../lib/utilities/asyncRequest';
import { HeroMetricsData } from 'lib/types/AOSharedTypes';
import { Filter } from '../../../lib/types/Filter';
import I18nKey from '../../../lib/types/I18nKey';

const MAX_LOAD_HERO_METRICS_ATTEMPTS = 1;

interface HeroMetricsFailedProps {
  readonly heroMetricsLoadAttempts: number;
  readonly currentMetricRequest: AsyncRequest<HeroMetricsData>;
  readonly tableFilters: Filter[];
  readonly retryHeroMetricsLoad: () => void;
  readonly contactSupport: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  readonly dataTestId?: string;
}
const getErrorMessageTitleI18nKey = (
  isFiltersAppliedAndCurrentPeriodDataUndefined: boolean,
  isCurrentPeriodDataUndefined: boolean,
  areHeroMetricsLoadAttemptsMoreThanMax: boolean
) => {
  if (
    isFiltersAppliedAndCurrentPeriodDataUndefined ||
    isCurrentPeriodDataUndefined
  )
    return I18nKey.HERO_METRICS_CURRENT_PERIOD_UNAVAILABLE_ERROR_HEADER;
  if (areHeroMetricsLoadAttemptsMoreThanMax)
    return I18nKey.HERO_METRICS_LOAD_ERROR_AFTER_RELOAD;

  return I18nKey.HERO_METRICS_LOAD_ERROR_BEFORE_RELOAD;
};

const getErrorMessageTextI18nKey = (
  isFiltersAppliedAndCurrentPeriodDataUndefined: boolean,
  isCurrentPeriodDataUndefined: boolean,
  areHeroMetricsLoadAttemptsMoreThanMax: boolean
) => {
  if (isFiltersAppliedAndCurrentPeriodDataUndefined)
    return I18nKey.HERO_METRICS_CURRENT_FILTER_DATA_UNAVAILABLE_ERROR_SUBTEXT;
  if (isCurrentPeriodDataUndefined)
    return I18nKey.HERO_METRICS_CURRENT_PERIOD_UNAVAILABLE_ERROR_SUBTEXT;
  if (areHeroMetricsLoadAttemptsMoreThanMax)
    return I18nKey.HERO_METRICS_CONTACT_SUPPORT_BUTTON_TEXT;

  return I18nKey.HERO_METRICS_RETRY_BUTTON_TEXT;
};

export const HeroMetricsFailed: React.FC<HeroMetricsFailedProps> = ({
  heroMetricsLoadAttempts,
  currentMetricRequest,
  contactSupport,
  retryHeroMetricsLoad,
  tableFilters,
  dataTestId,
}) => {
  const isCurrentPeriodDataUndefined =
    asyncRequestIsComplete(currentMetricRequest) &&
    isHeroMetricsResultUndefined(currentMetricRequest.result);

  const isFiltersAppliedAndCurrentPeriodDataUndefined =
    isCurrentPeriodDataUndefined && !!tableFilters.length;
  const areHeroMetricsLoadAttemptsMoreThanMax =
    heroMetricsLoadAttempts > MAX_LOAD_HERO_METRICS_ATTEMPTS;

  const errorMessageTitleI18nKey = getErrorMessageTitleI18nKey(
    isFiltersAppliedAndCurrentPeriodDataUndefined,
    isCurrentPeriodDataUndefined,
    areHeroMetricsLoadAttemptsMoreThanMax
  );

  const errorMessageTextI18nKey = getErrorMessageTextI18nKey(
    isFiltersAppliedAndCurrentPeriodDataUndefined,
    isCurrentPeriodDataUndefined,
    areHeroMetricsLoadAttemptsMoreThanMax
  );

  const onLinkClick = areHeroMetricsLoadAttemptsMoreThanMax
    ? contactSupport
    : retryHeroMetricsLoad;

  return (
    <div
      className={classNames(
        'my-16 p-16',
        'border-solid border-grey-200 border',
        'bg-white',
        'flex',
        'flex-1',
        'flex-col'
      )}
    >
      <div className="flex m-auto">
        <div
          className={classNames(
            'flex',
            'm-auto w-48 h-48',
            'bg-grey-100',
            'rounded-3xl'
          )}
        >
          <Icon
            className="m-auto text-grey-500"
            svg={AlertTriangleIcon}
            size={IconSize.Large}
          />
        </div>
      </div>
      <div className="flex m-auto mt-8">
        <h3
          className={classNames(
            'text-base font-semibold text-grey-900 text-center',
            'm-auto'
          )}
        >
          <FormattedMessage id={errorMessageTitleI18nKey} />
        </h3>
      </div>
      <div className={classNames('flex justify-center mt-8')}>
        {isCurrentPeriodDataUndefined ? (
          <span className="text-base">
            <FormattedMessage id={errorMessageTextI18nKey} />
          </span>
        ) : (
          <TextLink
            openNewTab={false}
            size={TextLinkSize.Default}
            onClick={onLinkClick}
            textLabel={<FormattedMessage id={errorMessageTextI18nKey} />}
            dataTestId={`${dataTestId}_heroMetricsFailed`}
          />
        )}
      </div>
    </div>
  );
};
HeroMetricsFailed.displayName = 'HeroMetricsFailed';
