import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import uuid from 'uuid';

import {
  FilteredDataFetcher,
  FilteredRequest,
  PaginatedDataFetcher,
  PaginatedRequest,
  PaginatedResult,
  TotalCountFetcher,
} from '../../../lib/clients/types';
import {
  TableAction,
  additionalAggregationDataLoadComplete,
  additionalAggregationDataLoadFailed,
  additionalAggregationDataLoadInitiated,
  aggregationDataLoadComplete,
  aggregationDataLoadFailed,
  aggregationDataLoadInitiated,
  dataLoadBufferComplete,
  dataLoadComplete,
  dataLoadFailed,
  dataLoadInitiated,
  dataReset,
  totalCount,
  totalCountFailure,
  totalCountSuccess,
} from './actions';
import { tableSelectors } from './index';
import { WithTable } from './types';
import { AsyncRequestKinds } from '../../../lib/utilities/asyncRequest';

export function loadPageOfData<S extends WithTable<R, F>, R, F, E>(
  tableId: string,
  paginatedRequest: PaginatedRequest,
  dataFetcher: PaginatedDataFetcher<R>
): ThunkAction<Promise<PaginatedResult<R>>, S, E, TableAction> {
  return (
    dispatch: ThunkDispatch<S, E, TableAction>
  ): Promise<PaginatedResult<R>> => {
    const requestId = uuid.v4();

    dispatch(
      dataLoadInitiated({
        tableId,
        requestId,
        page: paginatedRequest.page,
      })
    );

    return dataFetcher(paginatedRequest)
      .then((result) => {
        dispatch(
          dataLoadComplete({
            tableId,
            page: paginatedRequest.page,
            requestId,
            items: result.items,
            lastSyncedAt: result.lastSyncedAt,
            totalItems: result.totalItems,
          })
        );
        return result;
      })
      .catch((e) => {
        dispatch(
          dataLoadFailed({
            page: paginatedRequest.page,
            tableId,
            requestId,
          })
        );
        return e;
      });
  };
}

export function refreshBufferData<S extends WithTable<R, F>, R, F, E>(
  tableId: string,
  paginatedRequest: PaginatedRequest,
  dataFetcher: PaginatedDataFetcher<R>
): ThunkAction<Promise<PaginatedResult<R>>, S, E, TableAction> {
  return (
    dispatch: ThunkDispatch<S, E, TableAction>,
    getState: () => S
  ): Promise<PaginatedResult<R>> => {
    const requestId = uuid.v4();
    const state = getState();
    const { pages, visiblePage } = tableSelectors.getTableSelector()(
      state.tableState,
      tableId
    );
    if (pages.length > visiblePage) {
      const requiredData = pages[visiblePage];
      if (requiredData.kind === AsyncRequestKinds.Completed) {
        dispatch(
          dataLoadBufferComplete({
            tableId,
            requestId,
            items: requiredData.result,
          })
        );
      }
      return new Promise(() => {}).then((res) => {
        return { items: [], totalItems: 0 };
      });
    } else {
      return dataFetcher(paginatedRequest)
        .then((result) => {
          dispatch(
            dataLoadBufferComplete({
              tableId,
              requestId,
              items: result.items,
            })
          );
          return result;
        })
        .catch((e) => {
          return e;
        });
    }
  };
}

export function refreshTable<S extends WithTable<T, F>, T, F, E>(
  tableId: string,
  dataFetcher: PaginatedDataFetcher<T>,
  aggregationDataFetcher?: FilteredDataFetcher<F>,
  additionalAggregationDataFetcher?: FilteredDataFetcher<E>,
  callBackOnRequestComplete?: () => void,
  fetchAndStoreTotalKey?: (aggregateData: F) => number
): ThunkAction<Promise<PaginatedResult<T>>, S, E, TableAction> {
  return (
    dispatch: ThunkDispatch<S, E, TableAction>,
    getState: () => S
  ): Promise<PaginatedResult<T>> => {
    const requestId = uuid.v4();
    const page = 1;
    const state = getState();

    const {
      sorts,
      filters,
      extraPaginationParams,
      itemsPerPage,
      bufferItemsToLoad,
    } = tableSelectors.getTableSelector()(state.tableState, tableId);

    dispatch(
      dataReset({
        tableId,
      })
    );

    dispatch(dataLoadInitiated({ tableId, requestId, page }));

    let aggregationDataPromise = Promise.resolve({}) as Promise<F>;
    if (aggregationDataFetcher) {
      aggregationDataPromise = loadAggregationData(
        tableId,
        aggregationDataFetcher,
        fetchAndStoreTotalKey
      )(dispatch, getState, {});
    }
    if (additionalAggregationDataFetcher) {
      loadAdditionalAggregationData(tableId, additionalAggregationDataFetcher)(
        dispatch,
        getState,
        {}
      );
    }

    if (bufferItemsToLoad) {
      refreshBufferData(
        tableId,
        {
          filters,
          extraParams: extraPaginationParams,
          sorts,
          itemsPerPage: bufferItemsToLoad,
          page: page + 1,
        },
        dataFetcher
      );
    }

    return dataFetcher({
      filters,
      extraParams: extraPaginationParams,
      sorts,
      itemsPerPage,
      page,
    })
      .then(async (result) => {
        dispatch(
          dataLoadComplete({
            tableId,
            page,
            requestId,
            lastSyncedAt: result.lastSyncedAt,
            items: result.items,
            totalItems: result.totalItems,
          })
        );

        await aggregationDataPromise;
        return result;
      })
      .catch(async (e) => {
        dispatch(
          dataLoadFailed({
            tableId,
            page,
            requestId,
          })
        );

        await aggregationDataPromise;
        return e;
      })
      .finally(() => callBackOnRequestComplete && callBackOnRequestComplete());
  };
}

export function loadAggregationData<S extends WithTable<T, F>, T, F, E>(
  tableId: string,
  aggregationDataFetcher: FilteredDataFetcher<F>,
  fetchAndStoreTotalKey?: (aggregateData: F) => number
): ThunkAction<Promise<F>, S, E, TableAction> {
  return (
    dispatch: ThunkDispatch<S, E, TableAction>,
    getState: () => S
  ): Promise<F> => {
    const requestId = uuid.v4();
    const { filters, extraPaginationParams, sorts } =
      tableSelectors.getTableSelector()(getState().tableState, tableId);

    dispatch(aggregationDataLoadInitiated({ tableId, requestId }));
    fetchAndStoreTotalKey && dispatch(totalCount({ tableId, requestId }));

    return aggregationDataFetcher({
      filters,
      extraParams: extraPaginationParams,
      sorts,
    })
      .then((aggregationData) => {
        dispatch(
          aggregationDataLoadComplete({ tableId, aggregationData, requestId })
        );
        fetchAndStoreTotalKey &&
          dispatch(
            totalCountSuccess({
              totalCount: fetchAndStoreTotalKey(aggregationData),
              tableId,
              requestId,
            })
          );
        return aggregationData;
      })
      .catch((e) => {
        dispatch(aggregationDataLoadFailed({ tableId, requestId }));
        fetchAndStoreTotalKey &&
          dispatch(
            totalCountFailure({ requestId, tableId, error: (e as any).message })
          );
        return e;
      });
  };
}

export function loadAdditionalAggregationData<
  S extends WithTable<T, F>,
  T,
  F,
  E
>(
  tableId: string,
  additionalAggregationDataFetcher: FilteredDataFetcher<F>
): ThunkAction<Promise<F>, S, E, TableAction> {
  return (
    dispatch: ThunkDispatch<S, E, TableAction>,
    getState: () => S
  ): Promise<F> => {
    const requestId = uuid.v4();
    const { filters, extraPaginationParams, sorts, extraQueryParams } =
      tableSelectors.getTableSelector()(getState().tableState, tableId);

    dispatch(additionalAggregationDataLoadInitiated({ tableId, requestId }));

    return additionalAggregationDataFetcher({
      sorts,
      filters,
      extraParams: { ...extraQueryParams, ...extraPaginationParams },
    })
      .then((additionalAggregationData) => {
        dispatch(
          additionalAggregationDataLoadComplete({
            requestId,
            tableId,
            additionalAggregationData,
          })
        );
        return additionalAggregationData;
      })
      .catch((e) => {
        dispatch(additionalAggregationDataLoadFailed({ tableId, requestId }));
        return e;
      });
  };
}

export const loadTotalCount = (
  tableId: string,
  filteredRequest: FilteredRequest,
  totalCountFetcher: TotalCountFetcher
) => {
  return async (dispatch: ThunkDispatch<{}, {}, TableAction>) => {
    const requestId = uuid.v4();
    try {
      dispatch(totalCount({ tableId, requestId }));
      const totalCountResponse = await totalCountFetcher(filteredRequest);
      dispatch(
        totalCountSuccess({
          totalCount: totalCountResponse.fullCount,
          tableId,
          requestId,
        })
      );
    } catch (e) {
      dispatch(
        totalCountFailure({ requestId, tableId, error: (e as any).message })
      );
    }
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loadAggregationData,
  loadAdditionalAggregationData,
  loadPageOfData,
  refreshBufferData,
  refreshTable,
  loadTotalCount,
};
