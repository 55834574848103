import {
  BulkEditModalElementType,
  BulkEditModalNumericValueOperator,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CheckboxCheckedState,
  Control,
  DualModes,
  EditControlProps,
  HelpPromptProps,
  HelpPromptVariant,
  PortaledTooltipTheme,
  RadioButtonProps,
  Variant,
} from '@teikametrics/tm-design-system';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { IntlShape } from 'react-intl';
import {
  TableCellChange,
  TableChange,
} from '../../../../containers/table/ducks/types';
import {
  AdGroupDetails,
  AdGroupReviewStatusType,
  AdGroupStatus,
  AdGroupUseCaseType,
  AdType,
  AdvertisingGoal,
  AiBiddingValues,
  AiRecommendedKeywordType,
  AutomationStatus,
  CampaignCostType,
  CampaignTargetingType,
  FlywheelSalesChannel,
  ProductAdDetails,
  TargetType,
} from '../../../../lib/types/AOSharedTypes';
import {
  MONETARY_FRACTION_DIGITS,
  PERCENTAGE_FRACTION_DIGITS,
} from '../../../../lib/types/CommonSharedTypes';
import { MerchantType } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  getBidPlaceholderInModal,
  getCurrentValueBeingEdited,
  getProductAdsModelDescriptionI18nKey,
} from './adItemsTableColumns/productName';
import {
  BulkEditMenuValue as AdGroupBulkEditMenuValue,
  RowsCampaignTargetingType,
  getAdgroupDefaultBid,
  getInValidChangesCount,
  getInValidChangesCounts,
  getMacsTargetInvalidCount,
  getMaxBid,
  getMaxBidInvalidChangesCount,
  getMinBid,
  getMinBidInvalidChangesCount,
  isFlywheelSettingsColumn,
  isTableChangeSelectRows,
  setIsCampaignCostTypeVCPMAndFlywheelSettingIsSelected,
} from './adgroupTableColumns/adgroupName';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from './ducks/types';
import { BulkEditMenuValue, ExtendedEditControlProps } from './tableAdItems';
import { ExtendedAdGroupEditControlProps } from './tableAdgroup';
import {
  ADITEM_BID_AUTO_MIN_VALUE,
  ADITEM_BID_MAX_VALUE,
  BulkEditModalNumericTypeValue,
  BulkEditModalValues,
  BulkEditSelectedOptions,
  NumericValueConfig,
} from './types';
import {
  ADGROUPS_API_COLUMN_NAME,
  EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
  PRODUCT_ADS_API_COLUMN_NAME,
  ProductAdStatus,
  ProductAdStatusOptions,
  ProductAdStatusValue,
  TABLE_UNIQ_KEY,
  areAllSelectedCampaignCostTypeCpc,
  areAllSelectedCampaignCostTypeVcpm,
  getNumericValidators,
  getOrDefaultIsBiddableFlag,
  getUpdatedValueFromOperator,
  isAutoCampaign,
  isCampaignCostTypeVcpm,
  isManualCampaign,
  isMerchantTypeSeller,
  isMerchantTypeVendor,
  isValueBetweenMinAndMaxValue,
  roundValuesToDecimalPlaces,
} from './utils';
import { SMART_GOALS } from './adgroupTableColumns/utils';
import isUndefined from 'lodash/isUndefined';

export const getFilteredBulkEditOptions = (
  bulkEditMenuOptionsList: ExtendedEditControlProps[],
  adType: AdType | undefined,
  salesChannel: FlywheelSalesChannel
) =>
  bulkEditMenuOptionsList.filter(({ visibilityCriteria }) => {
    return (
      adType &&
      salesChannel &&
      visibilityCriteria?.salesChannel.includes(salesChannel) &&
      visibilityCriteria.adTypes.includes(adType)
    );
  });

const getBulkMenuOptionExtraPropsForAdStatus = (
  tableChange: TableChange,
  intl: IntlShape,
  salesChannel: FlywheelSalesChannel,
  valueBeingEdited: string,
  productAdTableData: ProductAdDetails[]
) => {
  const ENABLED = intl.formatMessage({
    id: getProductAdsModelDescriptionI18nKey(
      salesChannel,
      ProductAdStatus.Enabled
    ),
  });

  const PAUSED = intl.formatMessage({
    id: getProductAdsModelDescriptionI18nKey(
      salesChannel,
      ProductAdStatus.Paused
    ),
  });

  const DISABLED = intl.formatMessage({
    id: getProductAdsModelDescriptionI18nKey(
      salesChannel,
      ProductAdStatus.Disabled
    ),
  });

  const ARCHIVED = intl.formatMessage({
    id: getProductAdsModelDescriptionI18nKey(
      salesChannel,
      ProductAdStatus.Archived
    ),
  });

  const currentValueBeingEdited = getCurrentValueBeingEdited(
    tableChange,
    productAdTableData,
    [valueBeingEdited]
  );

  const menuOptions: RadioButtonProps[] = [
    {
      label: ENABLED,
      disabled: false,
      selected:
        first(currentValueBeingEdited) ===
        ProductAdStatusOptions.Enabled?.value,
      onChange: noop,
      value: ProductAdStatusValue.Enabled,
    },
  ];
  if (salesChannel === FlywheelSalesChannel.Amazon) {
    menuOptions.push(
      ...[
        {
          label: PAUSED,
          disabled: false,
          selected:
            first(currentValueBeingEdited) ===
            ProductAdStatusOptions.Paused?.value,
          onChange: noop,
          value: ProductAdStatusValue.Paused,
        },
        {
          label: ARCHIVED,
          disabled: false,
          labelClassname: 'w-340',
          selected:
            first(currentValueBeingEdited) ===
            ProductAdStatusOptions.Archived?.value,
          onChange: noop,
          value: ProductAdStatusValue.Archived,
        },
      ]
    );
  } else if (salesChannel === FlywheelSalesChannel.Walmart) {
    menuOptions.push({
      label: DISABLED,
      disabled: false,
      selected:
        first(currentValueBeingEdited) ===
        ProductAdStatusOptions.Disabled?.value,
      onChange: noop,
      value: ProductAdStatusValue.Disabled,
    });
  }
  return menuOptions;
};

export const getEditControls = (
  intl: IntlShape,
  valueBeingEdited: BulkEditModalValues,
  tableChange: TableChange,
  currencySymbol: string,
  salesChannel: FlywheelSalesChannel,
  onBulkEditOptionSelection: (key: string, value: boolean) => void,
  productAdTableData: ProductAdDetails[]
): ExtendedEditControlProps[] => {
  const status = valueBeingEdited[PRODUCT_ADS_API_COLUMN_NAME.Status] as string;

  const bidErrorMessage = intl.formatMessage(
    {
      id: I18nKey.ADS_MANAGER_AD_ITEMS_INVALID_BID,
    },
    {
      minValue: intl.formatNumber(ADITEM_BID_AUTO_MIN_VALUE, {
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      }),
      maxValue: intl.formatNumber(ADITEM_BID_MAX_VALUE, {
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      }),
      currency: currencySymbol,
    }
  );

  return [
    {
      controls: [
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION,
          }),
          type: BulkEditModalElementType.Radio,
          element: {
            validators: [
              {
                validator: (value: string) => !!value,
              },
            ],
          },
          options: getBulkMenuOptionExtraPropsForAdStatus(
            tableChange,
            intl,
            salesChannel,
            status,
            productAdTableData
          ),
          columnKey: PRODUCT_ADS_API_COLUMN_NAME.Status,
          value: first(
            getCurrentValueBeingEdited(tableChange, productAdTableData, [
              status,
            ])
          )!,
        },
      ],
      headerText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_PRODUCT_AD_STATUS,
      }),
      editKey: PRODUCT_ADS_API_COLUMN_NAME.Status,
      onSelect: (v: boolean) =>
        onBulkEditOptionSelection(PRODUCT_ADS_API_COLUMN_NAME.Status, v),
      visibilityCriteria: {
        adTypes: [
          AdType.SponsoredDisplay,
          AdType.SponsoredProducts,
          AdType.SearchBrandAmplifier,
          AdType.SponsoredVideos,
        ],
        salesChannel: [
          FlywheelSalesChannel.Amazon,
          FlywheelSalesChannel.Walmart,
        ],
      },
    },
    {
      headerText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_BID,
      }),
      onSelect: (v: boolean) =>
        onBulkEditOptionSelection(PRODUCT_ADS_API_COLUMN_NAME.Bid, v),
      editKey: PRODUCT_ADS_API_COLUMN_NAME.Bid,
      controls: [
        {
          type: BulkEditModalElementType.Numeric,
          element: {
            value: '',
            extraProps: {
              placeholder: getBidPlaceholderInModal(
                tableChange,
                productAdTableData
              ),
              prependedElement: currencySymbol,
              acceptOnlyPositiveNumbers: true,
            },
            validators: [
              {
                validator: (value: string) =>
                  !!value &&
                  Number(value) >= ADITEM_BID_AUTO_MIN_VALUE &&
                  Number(value) <= ADITEM_BID_MAX_VALUE,

                errorMessage: bidErrorMessage,
              },
            ],
            onChange: noop,
          },
          columnKey: PRODUCT_ADS_API_COLUMN_NAME.Bid,
          value: '',
        },
      ],
      visibilityCriteria: {
        adTypes: [AdType.SponsoredProducts],
        salesChannel: [FlywheelSalesChannel.Walmart],
      },
    },
  ];
};

export const getBulkDataForProductAdStatus = (
  tableChange: TableChange,
  bulkMenuValue: BulkEditMenuValue,
  valueBeingEdited: string[],
  productAdTableData: ProductAdDetails[],
  adType?: AdType
) => {
  const bulkChanges = cloneDeep(tableChange.cell);
  tableChange.select.rows.forEach((rowId: string) => {
    if (!bulkChanges[rowId]) {
      bulkChanges[rowId] = {};
    }

    if (adType === AdType.SearchBrandAmplifier) {
      const adGroupDataForRow = productAdTableData.find(
        ({ adItemId }) => adItemId === rowId
      );

      if (
        adGroupDataForRow?.channelSettings?.status ===
        ProductAdStatusValue.Disabled
      ) {
        if (
          adGroupDataForRow?.adItemDetails.adGroupReviewStatus?.reviewStatus !==
            AdGroupReviewStatusType.Pending &&
          adGroupDataForRow?.adItemDetails.adGroupReviewStatus?.reviewStatus !==
            AdGroupReviewStatusType.InProgress
        ) {
          bulkChanges[rowId][bulkMenuValue] = valueBeingEdited[0];
        } else {
          delete bulkChanges[rowId][bulkMenuValue];
        }
      } else {
        bulkChanges[rowId][bulkMenuValue] = valueBeingEdited[0];
      }
    } else {
      bulkChanges[rowId][bulkMenuValue] = first(valueBeingEdited)!;
    }
  });
  return bulkChanges;
};

export const getBulkDataForSVAdStatus = (
  tableChange: TableChange,
  bulkMenuValue: BulkEditMenuValue,
  valueBeingEdited: string[],
  svAdsTableData: ProductAdDetails[],
  adType?: AdType
) => {
  const bulkChanges = cloneDeep(tableChange.cell);
  tableChange.select.rows.forEach((rowId: string) => {
    if (!bulkChanges[rowId]) {
      bulkChanges[rowId] = {};
    }

    if (adType === AdType.SponsoredVideos) {
      const adItemRowData = svAdsTableData.find(
        ({ adItemId }) => adItemId === rowId
      );

      if (
        adItemRowData?.channelSettings?.status === ProductAdStatusValue.Disabled
      ) {
        if (
          adItemRowData?.adItemDetails?.adGroupReviewStatus?.reviewStatus !==
            AdGroupReviewStatusType.Pending &&
          adItemRowData?.adItemDetails.adGroupReviewStatus?.reviewStatus !==
            AdGroupReviewStatusType.InProgress
        ) {
          bulkChanges[rowId][bulkMenuValue] = valueBeingEdited[0];
        } else {
          delete bulkChanges[rowId][bulkMenuValue];
        }
      } else {
        bulkChanges[rowId][bulkMenuValue] = valueBeingEdited[0];
      }
    } else {
      bulkChanges[rowId][bulkMenuValue] = first(valueBeingEdited)!;
    }
  });
  return bulkChanges;
};

export const getBulkDataForBid = (
  tableChange: TableChange,
  productAdTableData: ProductAdDetails[],
  bulkMenuValue: BulkEditMenuValue,
  valueBeingEdited: string[],
  operator?: BulkEditModalNumericValueOperator
) => {
  const bulkChanges = cloneDeep(tableChange.cell);
  const allProductAdsSelectedIds = tableChange.select.rows;
  const validProductAdIds = productAdTableData
    .filter(
      (productAd) =>
        !isManualCampaign(productAd.adItemDetails.campaignTargetingType)
    )
    .map((productAd) => productAd.adItemId);

  const productAdsMap: { [id: string]: ProductAdDetails } = {};
  productAdTableData
    .filter(
      (productAd) =>
        !isManualCampaign(productAd.adItemDetails.campaignTargetingType)
    )
    .forEach((ad) => (productAdsMap[ad.adItemId] = ad));

  const productAdsToBeUpdated = intersection(
    allProductAdsSelectedIds,
    validProductAdIds
  );

  productAdsToBeUpdated.forEach((rowId: string) => {
    if (!bulkChanges[rowId]) {
      bulkChanges[rowId] = {};
    }

    const updatedValue = getUpdatedValueFromOperator(
      productAdsMap[rowId].channelSettings?.bid?.amount,
      tableChange.cell[rowId]?.[PRODUCT_ADS_API_COLUMN_NAME.Bid],
      first(valueBeingEdited)!,
      operator
    );

    bulkChanges[rowId][bulkMenuValue] = updatedValue;
  });
  return bulkChanges;
};

const isBiddableColumn = (column: string, isBiddable: boolean): boolean => {
  return isFlywheelSettingsColumn(column) ? isBiddable : true;
};

const getMACSTarget = (
  intl: IntlShape,
  tableChange: TableChange,
  updatedAdGroupId: string,
  adGroupData: AdGroupDetails
) => {
  return Number(
    tableChange.cell[updatedAdGroupId]?.macsTarget ??
      (adGroupData.flywheelSettings.macsTarget
        ? intl.formatNumber(adGroupData.flywheelSettings.macsTarget * 100, {
            minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
            maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
          })
        : '')
  );
};

const getCurrentValueCondition = (
  column: string,
  updatedAdGroupId: string,
  adGroupData: AdGroupDetails,
  tableChange: TableChange,
  intl: IntlShape,
  campaignCostType?: CampaignCostType
) => {
  let currentValue: number = 0;
  switch (column) {
    case ADGROUPS_API_COLUMN_NAME.MinBid:
      currentValue = getMinBid(tableChange, updatedAdGroupId, adGroupData);
      break;
    case ADGROUPS_API_COLUMN_NAME.MaxBid:
      currentValue = getMaxBid(tableChange, updatedAdGroupId, adGroupData);
      break;
    case ADGROUPS_API_COLUMN_NAME.MACSTarget:
      currentValue = getMACSTarget(
        intl,
        tableChange,
        updatedAdGroupId,
        adGroupData
      );
      break;
    case ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid:
      if (
        !adGroupData.adGroupDetails.campaignCostType ||
        (campaignCostType &&
          adGroupData.adGroupDetails.campaignCostType === campaignCostType)
      ) {
        currentValue = getAdgroupDefaultBid(
          tableChange,
          updatedAdGroupId,
          adGroupData
        );
      }
      break;
  }
  return currentValue;
};

const getfirstSelectedValue = (
  isFirstSelectedValueSet: boolean,
  firstSelectedValue: number,
  currentValue: number
) => {
  if (!isFirstSelectedValueSet) {
    firstSelectedValue = currentValue;
  }
  return firstSelectedValue;
};

const getIsFirstSelectedValueSet = (isFirstSelectedValueSet: boolean) => {
  if (!isFirstSelectedValueSet) {
    isFirstSelectedValueSet = true;
  }
  return isFirstSelectedValueSet;
};

const getAreAllValuesSame = (
  isFirstSelectedValueSet: boolean,
  firstSelectedValue: number,
  currentValue: number,
  areAllValuesSame: boolean
) => {
  if (isFirstSelectedValueSet) {
    areAllValuesSame = currentValue === firstSelectedValue;
  }
  return areAllValuesSame;
};

const getFlywheelSettingsColumn = (
  column: string,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  intl: IntlShape,
  campaignCostType?: CampaignCostType
) => {
  let firstSelectedValue: number = 0;
  let isFirstSelectedValueSet: boolean = false;
  let areAllValuesSame: boolean = true;
  if (isTableChangeSelectRows(tableChange)) {
    for (let updatedAdGroupId of tableChange.select.rows) {
      const adGroupData = adGroupTableData.find(
        ({ adGroupId, adGroupDetails }) =>
          isEqual(updatedAdGroupId, adGroupId) &&
          isBiddableColumn(
            column,
            getOrDefaultIsBiddableFlag(adGroupDetails.isBiddable)
          ) &&
          (!adGroupDetails.campaignCostType ||
            !campaignCostType ||
            adGroupDetails.campaignCostType === campaignCostType)
      );
      if (adGroupData) {
        let currentValue = getCurrentValueCondition(
          column,
          updatedAdGroupId,
          adGroupData,
          tableChange,
          intl,
          campaignCostType
        );
        firstSelectedValue = getfirstSelectedValue(
          isFirstSelectedValueSet,
          firstSelectedValue,
          currentValue
        );
        isFirstSelectedValueSet = getIsFirstSelectedValueSet(
          isFirstSelectedValueSet
        );
        areAllValuesSame = getAreAllValuesSame(
          isFirstSelectedValueSet,
          firstSelectedValue,
          currentValue,
          areAllValuesSame
        );
        if (!areAllValuesSame) {
          break;
        }
      }
    }
  }

  return {
    firstSelectedValue,
    areAllValuesSame,
  };
};

const getFlywheelSettingsColumnSelected = (
  areAllValuesSame: boolean,
  firstSelectedValue: number,
  defaultValue: string
) => {
  return areAllValuesSame && (firstSelectedValue || firstSelectedValue === 0)
    ? firstSelectedValue.toString()
    : defaultValue;
};

type FLYWHEEL_SETTINGS_COLUMNS =
  | typeof ADGROUPS_API_COLUMN_NAME.MinBid
  | typeof ADGROUPS_API_COLUMN_NAME.MaxBid
  | typeof ADGROUPS_API_COLUMN_NAME.MACSTarget
  | typeof ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid;

const getFlywheelSettingsColumnSelectedDefaultValue = (
  column: FLYWHEEL_SETTINGS_COLUMNS,
  defaultValue: string,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  intl: IntlShape,
  campaignCostType?: CampaignCostType
): string => {
  const { areAllValuesSame, firstSelectedValue } = getFlywheelSettingsColumn(
    column,
    tableChange,
    adGroupTableData,
    intl,
    campaignCostType
  );
  return getFlywheelSettingsColumnSelected(
    areAllValuesSame,
    firstSelectedValue,
    defaultValue
  );
};

const getFirsAdGroupStatus = (
  firstEditedAdGroupCellValues: Record<string, string>,
  firstSelectedAdGroup?: AdGroupDetails
) => {
  return (
    firstEditedAdGroupCellValues?.status ||
    firstSelectedAdGroup?.channelSettings.status
  );
};

export interface ValidationConfig {
  readonly min: number;
  readonly max: number;
  readonly default: number;
}

const getPlaceholderForAdgroupsBulkEdit = (
  valueBeingEdited: BulkEditModalValues,
  bulkEditValue: AdGroupBulkEditMenuValue,
  adType: AdType,
  salesChannel: FlywheelSalesChannel,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  intl: IntlShape,
  validationConfig: {
    adgroupMacsTargetValidationConfig: ValidationConfig;
    minBidAutoValidationConfig: ValidationConfig;
    minBidManualValidationConfig: ValidationConfig;
    maxBidAutoValidationConfig: ValidationConfig;
    adgroupDefaultBidValidationConfigVCPM: ValidationConfig;
    adgroupDefaultBidValidationConfigCPC: ValidationConfig;
  }
) => {
  const selectedRowsIds = tableChange.select.rows;
  const firstSelectedAdGroupId: string = first(selectedRowsIds)!;

  const firstSelectedAdGroup: AdGroupDetails | undefined =
    adGroupTableData.find(
      ({ adGroupId }) => adGroupId === first(selectedRowsIds)!
    );
  const firstEditedAdGroupCellValues = tableChange.cell[firstSelectedAdGroupId];

  const selectedAdGroups: AdGroupDetails[] = getSelectedAdGroups(
    selectedRowsIds,
    adGroupTableData
  );

  switch (bulkEditValue) {
    case ADGROUPS_API_COLUMN_NAME.MACSTarget:
      const roundedMacsTargetValues = roundValuesToDecimalPlaces(
        [
          getFlywheelSettingsColumnSelectedDefaultValue(
            ADGROUPS_API_COLUMN_NAME.MACSTarget,
            intl.formatNumber(
              validationConfig.adgroupMacsTargetValidationConfig.default
            ),
            tableChange,
            adGroupTableData,
            intl
          ),
        ],
        PERCENTAGE_FRACTION_DIGITS
      );

      return roundedMacsTargetValues;

    case ADGROUPS_API_COLUMN_NAME.MinBid:
      const roundedMinBidValues = roundValuesToDecimalPlaces(
        [
          getFlywheelSettingsColumnSelectedDefaultValue(
            ADGROUPS_API_COLUMN_NAME.MinBid,
            intl.formatNumber(
              validationConfig.minBidAutoValidationConfig.default
            ),
            tableChange,
            adGroupTableData,
            intl
          ),
          getFlywheelSettingsColumnSelectedDefaultValue(
            ADGROUPS_API_COLUMN_NAME.MinBid,
            intl.formatNumber(
              validationConfig.minBidManualValidationConfig.default
            ),
            tableChange,
            adGroupTableData,
            intl
          ),
        ],
        MONETARY_FRACTION_DIGITS
      );

      return roundedMinBidValues;
    case ADGROUPS_API_COLUMN_NAME.MaxBid:
      const roundedMaxBidValues = roundValuesToDecimalPlaces(
        [
          getFlywheelSettingsColumnSelectedDefaultValue(
            ADGROUPS_API_COLUMN_NAME.MaxBid,
            intl.formatNumber(
              validationConfig.maxBidAutoValidationConfig.default
            ),
            tableChange,
            adGroupTableData,
            intl
          ),
        ],
        MONETARY_FRACTION_DIGITS
      );

      return roundedMaxBidValues;

    case ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid:
      const roundedAdGroupDefaultBidValues = roundValuesToDecimalPlaces(
        [
          getFlywheelSettingsColumnSelectedDefaultValue(
            ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
            intl.formatNumber(
              validationConfig.adgroupDefaultBidValidationConfigVCPM.default
            ),
            tableChange,
            adGroupTableData,
            intl,
            CampaignCostType.VCPM
          ),
          getFlywheelSettingsColumnSelectedDefaultValue(
            ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
            intl.formatNumber(
              validationConfig.adgroupDefaultBidValidationConfigCPC.default
            ),
            tableChange,
            adGroupTableData,
            intl,
            CampaignCostType.CPC
          ),
        ],
        MONETARY_FRACTION_DIGITS
      );
      return roundedAdGroupDefaultBidValues;

    case ADGROUPS_API_COLUMN_NAME.AdGroupStatus:
      const firsAdGroupStatus = getFirsAdGroupStatus(
        firstEditedAdGroupCellValues,
        firstSelectedAdGroup
      );
      const areAllStatusEqual = selectedAdGroups.every((row) => {
        const currentAdGroupStatus =
          tableChange.cell[row.adGroupId]?.status || row.channelSettings.status;
        return currentAdGroupStatus === firsAdGroupStatus;
      });

      if (areAllStatusEqual && firsAdGroupStatus) {
        return firsAdGroupStatus;
      } else if (adType && salesChannel === FlywheelSalesChannel.Amazon) {
        return AdGroupStatus.Enabled;
      }
      break;
  }
};

const getGroupStatusExtraPropsForSalesChannel = (
  intl: IntlShape,
  flywheelSalesChannel: FlywheelSalesChannel,
  valueBeingEdited: BulkEditModalValues
) => {
  const value = valueBeingEdited[ADGROUPS_API_COLUMN_NAME.AdGroupStatus];
  switch (flywheelSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      return [
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ENABLED_LABEL,
          }),
          selected: value === AdGroupStatus.Enabled,
          onChange: noop,
          value: AdGroupStatus.Enabled,
        },
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_DISABLED_LABEL,
          }),
          selected: value === AdGroupStatus.Disabled,
          onChange: noop,
          value: AdGroupStatus.Disabled,
        },
      ];

    case FlywheelSalesChannel.Amazon:
      return [
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AMAZON_ENABLED_LABEL,
          }),
          selected: value === AdGroupStatus.Enabled,
          onChange: noop,
          value: AdGroupStatus.Enabled,
        },
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AMAZON_PAUSED_LABEL,
          }),
          selected: value === AdGroupStatus.Paused,
          onChange: noop,
          value: AdGroupStatus.Paused,
        },
        {
          label: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AMAZON_ARCHIVED_LABEL,
          }),
          selected: value === AdGroupStatus.Archived,
          onChange: noop,
          value: AdGroupStatus.Archived,
        },
      ];
  }
};

const getAiRecommendedKeywordProps = (
  intl: IntlShape,
  valueBeingEdited: BulkEditModalValues
) => {
  return [
    {
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AI_KEYWORD_ADD_AUTOMATICALLY,
      }),
      selected:
        (valueBeingEdited[
          ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting
        ] as string) === AiRecommendedKeywordType.AddAutomatically,
      onChange: noop,
      value: AiRecommendedKeywordType.AddAutomatically,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AI_KEYWORD_REVIEW_RECOMMENDATION,
      }),
      selected:
        (valueBeingEdited[
          ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting
        ] as string) === AiRecommendedKeywordType.ReviewRecommendations,
      onChange: noop,
      value: AiRecommendedKeywordType.ReviewRecommendations,
    },
  ];
};

const selectedMerchantCountryIdsFromSelectedRows = (
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
) =>
  tableChange.select.rows.map(
    (rowId) =>
      adGroupTableData.find(({ adGroupId }) => adGroupId === rowId)
        ?.adGroupDetails.merchantCountryId ?? ''
  );

const getMacsTargetValidationBySalesChannel = (
  flywheelSalesChannel: FlywheelSalesChannel,
  intl: IntlShape,
  adgroupMacsTargetValidationConfig: ValidationConfig
) => {
  switch (flywheelSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      return [
        {
          validator: (value: string) =>
            Number(value) > adgroupMacsTargetValidationConfig.min,
          errorMessage: intl.formatMessage(
            {
              id: I18nKey.VALIDATION_GREATER_THAN,
            },
            {
              value: intl.formatNumber(adgroupMacsTargetValidationConfig.min, {
                style: 'percent',
                minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
              }),
            }
          ),
        },
      ];

    case FlywheelSalesChannel.Amazon:
      return [
        {
          validator: (value: string) =>
            Number(value) >= adgroupMacsTargetValidationConfig.min &&
            Number(value) <= adgroupMacsTargetValidationConfig.max,
          errorMessage: intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_MACS_VALUE,
            },
            {
              minValue: intl.formatNumber(
                adgroupMacsTargetValidationConfig.min,
                {
                  minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                  maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                }
              ),
              maxValue: intl.formatNumber(
                adgroupMacsTargetValidationConfig.max,
                {
                  minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                  maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                }
              ),
            }
          ),
        },
      ];
  }
};

const isAutomationDisabledForAllSelectedRows = (
  merchantsWithAutomationEnabled: string[],
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
) =>
  merchantsWithAutomationEnabled?.filter((merchantCountryId) =>
    selectedMerchantCountryIdsFromSelectedRows(
      tableChange,
      adGroupTableData
    ).includes(merchantCountryId)
  ).length === 0;

const getAdGroupBidValidators = (
  intl: IntlShape,
  minLimit: number,
  maxLimit: number,
  currencySymbol: string | null
) => [
  {
    validator: (value: string) =>
      value !== '' && isValueBetweenMinAndMaxValue(minLimit, maxLimit, value),
    errorMessage: intl.formatMessage(
      {
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_BID,
      },
      {
        minValue: intl.formatNumber(minLimit, {
          minimumFractionDigits: MONETARY_FRACTION_DIGITS,
          maximumFractionDigits: MONETARY_FRACTION_DIGITS,
        }),
        maxValue: intl.formatNumber(maxLimit),
        currencySymbol,
      }
    ),
  },
];

const adGroupAutoMinBidElement = (
  intl: IntlShape,
  currencySymbol: string,
  placeholder: string,
  valueBeingEdited: BulkEditModalValues,
  minBidAutoValidationConfig: ValidationConfig,
  maxBidAutoValidationConfig: ValidationConfig,
  disabled: boolean,
  selectedCount: number,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  showSmartCampaign?: boolean
) => {
  return {
    helpInfo: getHelpGuide(
      ADGROUPS_API_COLUMN_NAME.MinBid,
      intl,
      selectedCount,
      tableChange,
      adGroupTableData,
      showSmartCampaign
    ),
    columnKey: ADGROUPS_API_COLUMN_NAME.MinBidAuto,
    type: BulkEditModalElementType.Numeric,
    value: '',
    element: {
      subHeaderText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AUTO_AD_GROUPS,
      }),
      value: '',
      extraProps: {
        prependedElement: currencySymbol,
        placeholder,
        acceptOnlyPositiveNumbers: true,
        minFractionDigits: MONETARY_FRACTION_DIGITS,
        maxFractionDigits: MONETARY_FRACTION_DIGITS,
      },
      validators: getAdGroupBidValidators(
        intl,
        minBidAutoValidationConfig.min,
        maxBidAutoValidationConfig.max,
        currencySymbol
      ),
    },
    disabled,
  };
};

const adGroupManualMinBidElement = (
  intl: IntlShape,
  currencySymbol: string,
  placeholder: string,
  valueBeingEdited: BulkEditModalValues,
  minBidManualValidationConfig: ValidationConfig,
  maxBidManualValidationConfig: ValidationConfig,
  disabled: boolean,
  selectedCount: number,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  showSmartCampaign?: boolean
) => {
  return {
    helpInfo: getHelpGuide(
      ADGROUPS_API_COLUMN_NAME.MinBid,
      intl,
      selectedCount,
      tableChange,
      adGroupTableData,
      showSmartCampaign
    ),
    columnKey: ADGROUPS_API_COLUMN_NAME.MinBidManual,
    type: BulkEditModalElementType.Numeric,
    value: '',
    element: {
      subHeaderText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_KEYWORD_AD_GROUPS,
      }),
      value: '',
      extraProps: {
        prependedElement: currencySymbol,
        placeholder,
        acceptOnlyPositiveNumbers: true,
        minFractionDigits: MONETARY_FRACTION_DIGITS,
        maxFractionDigits: MONETARY_FRACTION_DIGITS,
      },
      validators: getAdGroupBidValidators(
        intl,
        minBidManualValidationConfig.min,
        maxBidManualValidationConfig.max,
        currencySymbol
      ),
    },
    disabled,
  };
};

const adGroupMinBidElement = (
  intl: IntlShape,
  currencySymbol: string,
  placeholder: string,
  valueBeingEdited: BulkEditModalValues,
  minBidManualValidationConfig: ValidationConfig,
  maxBidManualValidationConfig: ValidationConfig,
  disabled: boolean,
  selectedCount: number,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  showSmartCampaign?: boolean
) => {
  return {
    helpInfo: getHelpGuide(
      ADGROUPS_API_COLUMN_NAME.MinBid,
      intl,
      selectedCount,
      tableChange,
      adGroupTableData,
      showSmartCampaign
    ),
    columnKey: ADGROUPS_API_COLUMN_NAME.MinBid,
    type: BulkEditModalElementType.Numeric,
    value: '',
    element: {
      value: '',
      extraProps: {
        prependedElement: currencySymbol,
        placeholder,
        acceptOnlyPositiveNumbers: true,
        minFractionDigits: MONETARY_FRACTION_DIGITS,
        maxFractionDigits: MONETARY_FRACTION_DIGITS,
      },
      validators: getAdGroupBidValidators(
        intl,
        minBidManualValidationConfig.min,
        maxBidManualValidationConfig.max,
        currencySymbol
      ),
    },
    disabled,
  };
};

const getMinBidElementForChannel = (
  flywheelSalesChannel: FlywheelSalesChannel,
  intl: IntlShape,
  currencySymbol: string,
  placeholder: string[],
  valueBeingEdited: BulkEditModalValues,
  validationConfig: {
    minBidAutoValidationConfig: ValidationConfig;
    maxBidAutoValidationConfig: ValidationConfig;
    minBidManualValidationConfig: ValidationConfig;
    maxBidManualValidationConfig: ValidationConfig;
  },
  selectedRowsCampaignTargetingType: RowsCampaignTargetingType,
  disabled: boolean,
  selectedCount: number,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  showSmartCampaign?: boolean
): Control[] => {
  switch (flywheelSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      if (
        selectedRowsCampaignTargetingType === RowsCampaignTargetingType.Both
      ) {
        return [
          adGroupAutoMinBidElement(
            intl,
            currencySymbol,
            placeholder[0],
            valueBeingEdited,
            validationConfig.minBidAutoValidationConfig,
            validationConfig.maxBidAutoValidationConfig,
            disabled,
            selectedCount,
            tableChange,
            adGroupTableData,
            showSmartCampaign
          ),
          adGroupManualMinBidElement(
            intl,
            currencySymbol,
            placeholder[1],
            valueBeingEdited,
            validationConfig.minBidManualValidationConfig,
            validationConfig.maxBidManualValidationConfig,
            disabled,
            selectedCount,
            tableChange,
            adGroupTableData,
            showSmartCampaign
          ),
        ];
      }
      if (
        selectedRowsCampaignTargetingType === RowsCampaignTargetingType.Auto
      ) {
        return [
          adGroupAutoMinBidElement(
            intl,
            currencySymbol,
            placeholder[0],
            valueBeingEdited,
            validationConfig.minBidAutoValidationConfig,
            validationConfig.maxBidAutoValidationConfig,
            disabled,
            selectedCount,
            tableChange,
            adGroupTableData,
            showSmartCampaign
          ),
        ];
      }

      return [
        adGroupManualMinBidElement(
          intl,
          currencySymbol,
          placeholder[1],
          valueBeingEdited,
          validationConfig.minBidManualValidationConfig,
          validationConfig.maxBidManualValidationConfig,
          disabled,
          selectedCount,
          tableChange,
          adGroupTableData,
          showSmartCampaign
        ),
      ];

    case FlywheelSalesChannel.Amazon:
      return [
        adGroupMinBidElement(
          intl,
          currencySymbol,
          placeholder[0],
          valueBeingEdited,
          validationConfig.minBidManualValidationConfig,
          validationConfig.maxBidManualValidationConfig,
          disabled,
          selectedCount,
          tableChange,
          adGroupTableData,
          showSmartCampaign
        ),
      ];
  }
};

const adGroupAutoMaxBidElement = (
  intl: IntlShape,
  currencySymbol: string,
  placeholder: string,
  valueBeingEdited: BulkEditModalValues,
  minBidAutoValidationConfig: ValidationConfig,
  maxBidAutoValidationConfig: ValidationConfig,
  disabled: boolean,
  selectedCount: number,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  showSmartCampaign?: boolean
) => {
  return {
    helpInfo: getHelpGuide(
      ADGROUPS_API_COLUMN_NAME.MaxBid,
      intl,
      selectedCount,
      tableChange,
      adGroupTableData,
      showSmartCampaign
    ),
    columnKey: ADGROUPS_API_COLUMN_NAME.MaxBidAuto,
    type: BulkEditModalElementType.Numeric,
    value: '',
    element: {
      subHeaderText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AUTO_AD_GROUPS,
      }),
      value: '',
      extraProps: {
        prependedElement: currencySymbol,
        placeholder,
        acceptOnlyPositiveNumbers: true,
        minFractionDigits: MONETARY_FRACTION_DIGITS,
        maxFractionDigits: MONETARY_FRACTION_DIGITS,
      },
      validators: getAdGroupBidValidators(
        intl,
        minBidAutoValidationConfig.min,
        maxBidAutoValidationConfig.max,
        currencySymbol
      ),
    },
    disabled,
  };
};

const adGroupManualMaxBidElement = (
  intl: IntlShape,
  currencySymbol: string,
  placeholder: string,
  valueBeingEdited: BulkEditModalValues,
  minBidManualValidationConfig: ValidationConfig,
  maxBidManualValidationConfig: ValidationConfig,
  disabled: boolean,
  selectedCount: number,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  showSmartCampaign?: boolean
) => {
  return {
    helpInfo: getHelpGuide(
      ADGROUPS_API_COLUMN_NAME.MaxBid,
      intl,
      selectedCount,
      tableChange,
      adGroupTableData,
      showSmartCampaign
    ),
    columnKey: ADGROUPS_API_COLUMN_NAME.MaxBidManual,
    type: BulkEditModalElementType.Numeric,
    value: '',
    element: {
      subHeaderText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_KEYWORD_AD_GROUPS,
      }),
      value: '',
      extraProps: {
        prependedElement: currencySymbol,
        placeholder,
        acceptOnlyPositiveNumbers: true,
        minFractionDigits: MONETARY_FRACTION_DIGITS,
        maxFractionDigits: MONETARY_FRACTION_DIGITS,
      },
      validators: getAdGroupBidValidators(
        intl,
        minBidManualValidationConfig.min,
        maxBidManualValidationConfig.max,
        currencySymbol
      ),
    },
    disabled,
  };
};

const adGroupMaxBidElement = (
  intl: IntlShape,
  currencySymbol: string,
  placeholder: string,
  valueBeingEdited: BulkEditModalValues,
  minBidManualValidationConfig: ValidationConfig,
  maxBidManualValidationConfig: ValidationConfig,
  disabled: boolean,
  selectedCount: number,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  showSmartCampaign?: boolean
) => {
  return {
    helpInfo: getHelpGuide(
      ADGROUPS_API_COLUMN_NAME.MaxBid,
      intl,
      selectedCount,
      tableChange,
      adGroupTableData,
      showSmartCampaign
    ),
    columnKey: ADGROUPS_API_COLUMN_NAME.MaxBid,
    type: BulkEditModalElementType.Numeric,
    value: '',
    element: {
      value: '',
      extraProps: {
        prependedElement: currencySymbol,
        placeholder,
        acceptOnlyPositiveNumbers: true,
        minFractionDigits: MONETARY_FRACTION_DIGITS,
        maxFractionDigits: MONETARY_FRACTION_DIGITS,
      },
      validators: getAdGroupBidValidators(
        intl,
        minBidManualValidationConfig.min,
        maxBidManualValidationConfig.max,
        currencySymbol
      ),
    },
    disabled,
  };
};

const getMaxBidElementForChannel = (
  flywheelSalesChannel: FlywheelSalesChannel,
  intl: IntlShape,
  currencySymbol: string,
  placeholder: string[],
  valueBeingEdited: BulkEditModalValues,
  validationConfig: {
    minBidAutoValidationConfig: ValidationConfig;
    maxBidAutoValidationConfig: ValidationConfig;
    minBidManualValidationConfig: ValidationConfig;
    maxBidManualValidationConfig: ValidationConfig;
  },
  selectedRowsCampaignTargetingType: RowsCampaignTargetingType,
  disabled: boolean,
  selectedCount: number,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  showSmartCampaign?: boolean
): Control[] => {
  switch (flywheelSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      if (
        selectedRowsCampaignTargetingType === RowsCampaignTargetingType.Both
      ) {
        return [
          adGroupAutoMaxBidElement(
            intl,
            currencySymbol,
            placeholder[0],
            valueBeingEdited,
            validationConfig.minBidAutoValidationConfig,
            validationConfig.maxBidAutoValidationConfig,
            disabled,
            selectedCount,
            tableChange,
            adGroupTableData,
            showSmartCampaign
          ),
          adGroupManualMaxBidElement(
            intl,
            currencySymbol,
            placeholder[0],
            valueBeingEdited,
            validationConfig.minBidManualValidationConfig,
            validationConfig.maxBidManualValidationConfig,
            disabled,
            selectedCount,
            tableChange,
            adGroupTableData,
            showSmartCampaign
          ),
        ];
      }
      if (
        selectedRowsCampaignTargetingType === RowsCampaignTargetingType.Auto
      ) {
        return [
          adGroupAutoMaxBidElement(
            intl,
            currencySymbol,
            placeholder[0],
            valueBeingEdited,
            validationConfig.minBidAutoValidationConfig,
            validationConfig.maxBidAutoValidationConfig,
            disabled,
            selectedCount,
            tableChange,
            adGroupTableData,
            showSmartCampaign
          ),
        ];
      }

      return [
        adGroupManualMaxBidElement(
          intl,
          currencySymbol,
          placeholder[0],
          valueBeingEdited,
          validationConfig.minBidManualValidationConfig,
          validationConfig.maxBidManualValidationConfig,
          disabled,
          selectedCount,
          tableChange,
          adGroupTableData,
          showSmartCampaign
        ),
      ];

    case FlywheelSalesChannel.Amazon:
      return [
        adGroupMaxBidElement(
          intl,
          currencySymbol,
          placeholder[0],
          valueBeingEdited,
          validationConfig.minBidManualValidationConfig,
          validationConfig.maxBidManualValidationConfig,
          disabled,
          selectedCount,
          tableChange,
          adGroupTableData,
          showSmartCampaign
        ),
      ];
  }
};

const selectedRowsCampaignTargetingType = (
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
) => {
  const selectedRowsData = filter(adGroupTableData, (data: AdGroupDetails) =>
    tableChange.select.rows.includes(data.adGroupId)
  );

  const autoCount = filter(
    selectedRowsData,
    (data: AdGroupDetails) =>
      data.adGroupDetails.campaignTargetingType === CampaignTargetingType.Auto
  ).length;

  const manualCount = filter(
    selectedRowsData,
    (data: AdGroupDetails) =>
      data.adGroupDetails.campaignTargetingType === CampaignTargetingType.Manual
  ).length;

  const totalCount = selectedRowsData.length;

  if (autoCount === totalCount) {
    return RowsCampaignTargetingType.Auto;
  }

  if (manualCount === totalCount) {
    return RowsCampaignTargetingType.Manual;
  }

  return RowsCampaignTargetingType.Both;
};

const getDefaultBidBulkMenuOptionElements = (
  vcpmOption: Control,
  cpcOption: Control,
  adType: AdType,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
) => {
  if (areAllSelectedCampaignCostTypeVcpm(tableChange, adGroupTableData)) {
    return [vcpmOption];
  } else if (areAllSelectedCampaignCostTypeCpc(tableChange, adGroupTableData)) {
    return [cpcOption];
  } else if (adType === AdType.SponsoredDisplay) {
    return [vcpmOption, cpcOption];
  } else {
    return [cpcOption];
  }
};

const getBulkEditMenuWalmart = (
  adType: AdType,
  adGroupDefaultBidMenu: ExtendedAdGroupEditControlProps,
  adGroupReviewStatusMenu: ExtendedAdGroupEditControlProps,
  adGroupTagsMenu: ExtendedAdGroupEditControlProps,
  adGroupFwSettingsMenu: ExtendedAdGroupEditControlProps[]
) => {
  if (adType === AdType.SearchBrandAmplifier) {
    return [adGroupDefaultBidMenu, adGroupReviewStatusMenu];
  } else if (adType === AdType.SponsoredProducts) {
    return [adGroupTagsMenu, adGroupDefaultBidMenu, ...adGroupFwSettingsMenu];
  } else {
    return [adGroupDefaultBidMenu, ...adGroupFwSettingsMenu];
  }
};

const getSelectedAdGroups = (
  selectedRowsIds: string[],
  adGroupTableData: AdGroupDetails[]
) =>
  adGroupTableData.filter(({ adGroupId }) =>
    selectedRowsIds.includes(adGroupId)
  );

const isAtleastOneSelectedRowBiddable = (
  selectedRowsIds: string[],
  tableData: AdGroupDetails[]
) => {
  const selectedAdGroups: AdGroupDetails[] = getSelectedAdGroups(
    selectedRowsIds,
    tableData
  );
  return selectedAdGroups.some(({ adGroupDetails: { isBiddable } }) =>
    getOrDefaultIsBiddableFlag(isBiddable)
  );
};

const getInitialBulkMenuAmz = (
  adType: AdType,
  adGroupTagsMenu: ExtendedAdGroupEditControlProps,
  adGroupDefaultBidMenu: ExtendedAdGroupEditControlProps
) => {
  if (adType === AdType.SponsoredProducts)
    return [adGroupTagsMenu, adGroupDefaultBidMenu];

  if (adType === AdType.SponsoredDisplay) {
    return [adGroupDefaultBidMenu];
  }

  return [];
};

export const getEditControlsForAdGroupTable = (
  intl: IntlShape,
  adType: AdType,
  flywheelSalesChannel: FlywheelSalesChannel,
  valueBeingEdited: BulkEditModalValues,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  isAIPlan: boolean,
  currencySymbol: string,
  merchantsWithAutomationEnabled: string[],
  validationConfig: {
    adgroupMacsTargetValidationConfig: ValidationConfig;
    minBidAutoValidationConfig: ValidationConfig;
    minBidManualValidationConfig: ValidationConfig;
    maxBidAutoValidationConfig: ValidationConfig;
    adgroupDefaultBidValidationConfigVCPM: ValidationConfig;
    adgroupDefaultBidValidationConfigCPC: ValidationConfig;
    maxBidManualValidationConfig: ValidationConfig;
  },
  onBulkEditOptionSelection: (key: string, value: boolean) => void,
  merchantType?: MerchantType,
  showSmartCampaign?: boolean
): EditControlProps[] => {
  const [
    ACTIVE,
    INACTIVE,
    NEW_VALUE,
    CANCELLED,
    VALUE_FOR_VCPM,
    VALUE_FOR_CPC,
    AUTO,
    BRAND,
    COMPETITOR,
    GENERIC,
    AI_BIDDING,
    NO_AI,
  ] = [
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ACTIVE_LABEL,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_INACTIVE_LABEL,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_NEW_VALUE,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AMAZON_CANCELLED_LABEL,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_VALUE_FOR_VCPM,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_VALUE_FOR_CPC,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_LABELS_OPTION_AUTO,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_LABELS_OPTION_BRAND,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_LABELS_OPTION_COMPETITOR,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_LABELS_OPTION_GENERIC,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING_BIDDING_ONLY,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING_NO_AI,
  ].map((id) => intl.formatMessage({ id }));

  const selectedCount = tableChange.select.rows.length;
  const automationDisabledForAllSelectedRows =
    isAutomationDisabledForAllSelectedRows(
      merchantsWithAutomationEnabled,
      tableChange,
      adGroupTableData
    );

  const ADGROUP_DEFAULT_BID_STATUS_BULK_MENU_OPTION = {
    controls: [
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION,
        }),
        type: BulkEditModalElementType.Radio,
        element: {
          validators: [
            {
              validator: (value: string) => !!value,
            },
          ],
        },
        options: getGroupStatusExtraPropsForSalesChannel(
          intl,
          flywheelSalesChannel,
          valueBeingEdited
        ),
        columnKey: ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
        value: getPlaceholderForAdgroupsBulkEdit(
          valueBeingEdited,
          ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
          adType,
          flywheelSalesChannel,
          tableChange,
          adGroupTableData,
          intl,
          validationConfig
        ),
      },
    ],
    headerText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_STATUS,
    }),
    editKey: ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
    onSelect: (v: boolean) =>
      onBulkEditOptionSelection(ADGROUPS_API_COLUMN_NAME.AdGroupStatus, v),
  };

  const ADGROUP_REVIEW_STATUS_BULK_MENU_OPTION = {
    controls: [
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION,
        }),
        helpInfo: getHelpGuide(
          ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus,
          intl,
          selectedCount,
          tableChange,
          adGroupTableData
        ),
        value: '',
        type: BulkEditModalElementType.Radio,
        element: {
          validators: [
            {
              validator: (value: string) => !!value,
            },
          ],
        },
        options: [
          {
            label: CANCELLED,
            selected:
              (valueBeingEdited[
                ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus
              ] as string) === AdGroupReviewStatusType.Cancelled,
            onChange: noop,
            value: AdGroupReviewStatusType.Cancelled,
          },
        ],
        columnKey: ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus,
      },
    ],
    headerText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_REVIEW_STATUS,
    }),
    editKey: ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus,
    onSelect: (v: boolean) =>
      onBulkEditOptionSelection(
        ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus,
        v
      ),
  };

  const AI_RECOMMENDED_KEYWORD_BULK_MENU_OPTION = {
    headerText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_AI_RECOMMENDED_KEYWORD,
    }),
    editKey: ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
    onSelect: (v: boolean) =>
      onBulkEditOptionSelection(
        ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
        v
      ),
    controls: [
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION,
        }),
        helpInfo: getHelpGuide(
          ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
          intl,
          selectedCount,
          tableChange,
          adGroupTableData
        ),
        type: BulkEditModalElementType.Radio,
        options: getAiRecommendedKeywordProps(intl, valueBeingEdited),
        value: valueBeingEdited[
          ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting
        ] as string,
        validators: [],
        onChange: noop,
        columnKey: ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
        disabled: automationDisabledForAllSelectedRows,
      },
    ],
    visibilityCriteria: {
      enabledByFeatureFlag: true,
    },
  };

  const isAiBiddingOptionDisabled =
    getValidAiBiddingTableRows(tableChange, adGroupTableData).length === 0;

  const isBidModifierOptionDisabled =
    getValidBidModifierTableRows(tableChange, adGroupTableData).length === 0;

  const isAcosTargetOptionDisabled =
    getValidAcosBidModifierTableRows(tableChange, adGroupTableData).length ===
      0 && showSmartCampaign === true;

  const isMinMaxBidOptionDisabled =
    getValidMinMaxBidTableRows(tableChange, adGroupTableData).length === 0 &&
    showSmartCampaign === true;

  const ADGROUP_FW_SETTINGS_BULK_MENU_OPTIONS = isAIPlan
    ? [
        {
          visibilityCriteria: {
            enabledByFeatureFlag: !!showSmartCampaign,
          },
          disabled: isAiBiddingOptionDisabled,
          tooltipProps: isAiBiddingOptionDisabled
            ? {
                content: intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AI_BIDDING_OPTION_DISABLE_TOOLTIP,
                }),
                theme: PortaledTooltipTheme.Dark,
              }
            : undefined,
          headerText: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING,
          }),
          editKey: ADGROUPS_API_COLUMN_NAME.AiBidding,
          onSelect: (v: boolean) =>
            onBulkEditOptionSelection(ADGROUPS_API_COLUMN_NAME.AiBidding, v),
          controls: [
            {
              label: intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION,
              }),
              helpInfo: getHelpGuide(
                ADGROUPS_API_COLUMN_NAME.AiBidding,
                intl,
                selectedCount,
                tableChange,
                adGroupTableData
              ),
              type: BulkEditModalElementType.Radio,
              options: [
                {
                  label: AI_BIDDING,
                  selected:
                    (valueBeingEdited[
                      ADGROUPS_API_COLUMN_NAME.AiBidding
                    ] as string) === AiBiddingValues.Ai,
                  onChange: noop,
                  value: AiBiddingValues.Ai,
                },
                {
                  label: NO_AI,
                  selected:
                    (valueBeingEdited[
                      ADGROUPS_API_COLUMN_NAME.AiBidding
                    ] as string) === AiBiddingValues.NotEnabled,
                  onChange: noop,
                  value: AiBiddingValues.NotEnabled,
                },
              ],
              value: valueBeingEdited[
                ADGROUPS_API_COLUMN_NAME.AiBidding
              ] as string,
              columnKey: ADGROUPS_API_COLUMN_NAME.AiBidding,
              disabled: automationDisabledForAllSelectedRows,
            },
          ],
        },
        {
          visibilityCriteria: {
            enabledByFeatureFlag: !showSmartCampaign,
          },
          headerText: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_AUTOMATION_STATUS,
          }),
          editKey: ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
          onSelect: (v: boolean) =>
            onBulkEditOptionSelection(
              ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
              v
            ),
          controls: [
            {
              label: intl.formatMessage({
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION,
              }),
              helpInfo: getHelpGuide(
                ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
                intl,
                selectedCount,
                tableChange,
                adGroupTableData
              ),
              type: BulkEditModalElementType.Radio,
              options: [
                {
                  label: ACTIVE,
                  selected:
                    (valueBeingEdited[
                      ADGROUPS_API_COLUMN_NAME.BidAutomationStatus
                    ] as string) === AutomationStatus.Active,
                  onChange: noop,
                  value: AutomationStatus.Active,
                },
                {
                  label: INACTIVE,
                  selected:
                    (valueBeingEdited[
                      ADGROUPS_API_COLUMN_NAME.BidAutomationStatus
                    ] as string) === AutomationStatus.Inactive,
                  onChange: noop,
                  value: AutomationStatus.Inactive,
                },
              ],
              value: valueBeingEdited[
                ADGROUPS_API_COLUMN_NAME.BidAutomationStatus
              ] as string,
              columnKey: ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
              disabled: automationDisabledForAllSelectedRows,
            },
          ],
        },
        {
          disabled: isAcosTargetOptionDisabled,
          tooltipProps: isAcosTargetOptionDisabled
            ? {
                content: intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ACOS_LIMIT_OPTION_DISABLE_TOOLTIP,
                }),
                theme: PortaledTooltipTheme.Dark,
              }
            : undefined,
          headerText: intl.formatMessage({
            id: showSmartCampaign
              ? I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_ACOS_LIMIT
              : I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MACS_TARGET,
          }),
          editKey: ADGROUPS_API_COLUMN_NAME.MACSTarget,
          onSelect: (v: boolean) =>
            onBulkEditOptionSelection(ADGROUPS_API_COLUMN_NAME.MACSTarget, v),
          controls: [
            {
              value: '',
              type: BulkEditModalElementType.Numeric,
              columnKey: ADGROUPS_API_COLUMN_NAME.MACSTarget,
              element: {
                value: '',
                extraProps: {
                  appendedElement: '%',
                  acceptOnlyPositiveNumbers: true,
                  minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                  maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                  placeholder: getPlaceholderForAdgroupsBulkEdit(
                    valueBeingEdited,
                    ADGROUPS_API_COLUMN_NAME.MACSTarget,
                    adType,
                    flywheelSalesChannel,
                    tableChange,
                    adGroupTableData,
                    intl,
                    validationConfig
                  ),
                },
                validators: getMacsTargetValidationBySalesChannel(
                  flywheelSalesChannel,
                  intl,
                  validationConfig.adgroupMacsTargetValidationConfig
                ),
                onChange: noop,
              },
              disabled: automationDisabledForAllSelectedRows,
              helpInfo: getHelpGuide(
                ADGROUPS_API_COLUMN_NAME.MACSTarget,
                intl,
                selectedCount,
                tableChange,
                adGroupTableData,
                showSmartCampaign
              ),
            },
          ],
        },
        {
          visibilityCriteria: {
            enabledByFeatureFlag: showSmartCampaign === true,
          },
          disabled: isBidModifierOptionDisabled,
          tooltipProps: isBidModifierOptionDisabled
            ? {
                content: intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_BID_MODIFIER_OPTION_DISABLE_TOOLTIP,
                }),
                theme: PortaledTooltipTheme.Dark,
              }
            : undefined,
          headerText: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_BID_MODIFIER,
          }),
          editKey: ADGROUPS_API_COLUMN_NAME.BidModifier,
          onSelect: (v: boolean) =>
            onBulkEditOptionSelection(ADGROUPS_API_COLUMN_NAME.BidModifier, v),
          controls: [
            {
              helpInfo: getHelpGuide(
                ADGROUPS_API_COLUMN_NAME.BidModifier,
                intl,
                selectedCount,
                tableChange,
                adGroupTableData
              ),
              value: '',
              type: BulkEditModalElementType.Numeric,
              columnKey: ADGROUPS_API_COLUMN_NAME.BidModifier,
              element: {
                value: '',
                extraProps: {
                  appendedElement: '%',
                  acceptOnlyPositiveNumbers: true,
                  minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                  maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
                  placeholder: getPlaceholderForAdgroupsBulkEdit(
                    valueBeingEdited,
                    ADGROUPS_API_COLUMN_NAME.BidModifier,
                    adType,
                    flywheelSalesChannel,
                    tableChange,
                    adGroupTableData,
                    intl,
                    validationConfig
                  ),
                },
                validators: getMacsTargetValidationBySalesChannel(
                  flywheelSalesChannel,
                  intl,
                  validationConfig.adgroupMacsTargetValidationConfig
                ),
                onChange: noop,
              },
              disabled: automationDisabledForAllSelectedRows,
            },
          ],
        },
        {
          disabled: isMinMaxBidOptionDisabled,
          tooltipProps: isMinMaxBidOptionDisabled
            ? {
                content: intl.formatMessage(
                  {
                    id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_MIN_MAX_BID_OPTION_DISABLE_TOOLTIP,
                  },
                  {
                    field: intl.formatMessage({
                      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MINIMUM_BID,
                    }),
                  }
                ),
                theme: PortaledTooltipTheme.Dark,
              }
            : undefined,
          headerText: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MINIMUM_BID,
          }),
          editKey: ADGROUPS_API_COLUMN_NAME.MinBid,
          onSelect: (v: boolean) =>
            onBulkEditOptionSelection(ADGROUPS_API_COLUMN_NAME.MinBid, v),
          controls: getMinBidElementForChannel(
            flywheelSalesChannel,
            intl,
            currencySymbol,
            getPlaceholderForAdgroupsBulkEdit(
              valueBeingEdited,
              ADGROUPS_API_COLUMN_NAME.MinBid,
              adType,
              flywheelSalesChannel,
              tableChange,
              adGroupTableData,
              intl,
              validationConfig
            ) as string[],
            valueBeingEdited,
            {
              minBidAutoValidationConfig:
                validationConfig.minBidAutoValidationConfig,
              minBidManualValidationConfig:
                validationConfig.minBidManualValidationConfig,
              maxBidAutoValidationConfig:
                validationConfig.maxBidAutoValidationConfig,
              maxBidManualValidationConfig:
                validationConfig.maxBidManualValidationConfig,
            },
            selectedRowsCampaignTargetingType(tableChange, adGroupTableData),
            automationDisabledForAllSelectedRows,
            selectedCount,
            tableChange,
            adGroupTableData,
            showSmartCampaign
          ),
        },
        {
          disabled: isMinMaxBidOptionDisabled,
          tooltipProps: isMinMaxBidOptionDisabled
            ? {
                content: intl.formatMessage(
                  {
                    id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_MIN_MAX_BID_OPTION_DISABLE_TOOLTIP,
                  },
                  {
                    field: intl.formatMessage({
                      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MAXIMUM_BID,
                    }),
                  }
                ),
                theme: PortaledTooltipTheme.Dark,
              }
            : undefined,
          headerText: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MAXIMUM_BID,
          }),
          editKey: ADGROUPS_API_COLUMN_NAME.MaxBid,
          onSelect: (v: boolean) =>
            onBulkEditOptionSelection(ADGROUPS_API_COLUMN_NAME.MaxBid, v),
          controls: getMaxBidElementForChannel(
            flywheelSalesChannel,
            intl,
            currencySymbol,
            getPlaceholderForAdgroupsBulkEdit(
              valueBeingEdited,
              ADGROUPS_API_COLUMN_NAME.MaxBid,
              adType,
              flywheelSalesChannel,
              tableChange,
              adGroupTableData,
              intl,
              validationConfig
            ) as string[],
            valueBeingEdited,
            {
              minBidAutoValidationConfig:
                validationConfig.minBidAutoValidationConfig,
              minBidManualValidationConfig:
                validationConfig.minBidManualValidationConfig,
              maxBidAutoValidationConfig:
                validationConfig.maxBidAutoValidationConfig,
              maxBidManualValidationConfig:
                validationConfig.maxBidManualValidationConfig,
            },
            selectedRowsCampaignTargetingType(tableChange, adGroupTableData),
            automationDisabledForAllSelectedRows,
            selectedCount,
            tableChange,
            adGroupTableData,
            showSmartCampaign
          ),
        },
        ...(adType === AdType.SponsoredProducts
          ? [AI_RECOMMENDED_KEYWORD_BULK_MENU_OPTION]
          : []),
      ]
    : [
        ...(adType === AdType.SponsoredProducts
          ? [AI_RECOMMENDED_KEYWORD_BULK_MENU_OPTION]
          : []),
      ];

  const DEFAULT_BID_BULK_MENU_OPTION_VCPM = {
    type: BulkEditModalElementType.Numeric,
    columnKey: ADGROUPS_API_COLUMN_NAME.AdGroupDefaultBidVcpm,
    value: '',
    element: {
      subHeaderText: VALUE_FOR_VCPM,
      value: '',
      extraProps: {
        prependedElement: currencySymbol,
        placeholder: first(
          getPlaceholderForAdgroupsBulkEdit(
            valueBeingEdited,
            ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
            adType,
            flywheelSalesChannel,
            tableChange,
            adGroupTableData,
            intl,
            validationConfig
          )
        ),
        acceptOnlyPositiveNumbers: true,
        minFractionDigits: MONETARY_FRACTION_DIGITS,
        maxFractionDigits: MONETARY_FRACTION_DIGITS,
      },
      validators: getNumericValidators(
        [
          {
            validator: (value: string) =>
              Number(value) >=
              validationConfig.adgroupDefaultBidValidationConfigVCPM.min,
            errorMessage: intl.formatMessage(
              {
                id: I18nKey.VALIDATION_AT_LEAST,
              },
              {
                value: intl.formatNumber(
                  validationConfig.adgroupDefaultBidValidationConfigVCPM.min,
                  {
                    minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                    maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                  }
                ),
                currencyCode: currencySymbol,
              }
            ),
          },
          {
            validator: (value: string) =>
              Number(value) <=
              validationConfig.adgroupDefaultBidValidationConfigVCPM.max,
            errorMessage: intl.formatMessage(
              {
                id: I18nKey.VALIDATION_LESS_THAN,
              },
              {
                value: intl.formatNumber(
                  validationConfig.adgroupDefaultBidValidationConfigVCPM.max,
                  {
                    minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                    maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                  }
                ),
                currencyCode: currencySymbol,
              }
            ),
          },
        ],
        intl,
        (
          valueBeingEdited[
            ADGROUPS_API_COLUMN_NAME.AdGroupDefaultBidVcpm
          ] as BulkEditModalNumericTypeValue
        )?.operator
      ),
      onChange: noop,
    },
  };

  const adGroupDefaultBidCPCPlaceholder = getPlaceholderForAdgroupsBulkEdit(
    valueBeingEdited,
    ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    adType,
    flywheelSalesChannel,
    tableChange,
    adGroupTableData,
    intl,
    validationConfig
  );

  const DEFAULT_BID_BULK_MENU_OPTION_CPC = {
    type: BulkEditModalElementType.Numeric,
    columnKey: ADGROUPS_API_COLUMN_NAME.AdGroupDefaultBidCPC,
    value: '',
    element: {
      subHeaderText:
        adType === AdType.SponsoredDisplay ? VALUE_FOR_CPC : NEW_VALUE,
      value: '',
      extraProps: {
        prependedElement: currencySymbol,
        placeholder: adGroupDefaultBidCPCPlaceholder
          ? adGroupDefaultBidCPCPlaceholder[1]
          : undefined,
        acceptOnlyPositiveNumbers: true,
        minFractionDigits: MONETARY_FRACTION_DIGITS,
        maxFractionDigits: MONETARY_FRACTION_DIGITS,
      },
      validators: getNumericValidators(
        [
          {
            validator: (value: string) =>
              Number(value) >=
              validationConfig.adgroupDefaultBidValidationConfigCPC.min,
            errorMessage: intl.formatMessage(
              {
                id: I18nKey.VALIDATION_AT_LEAST,
              },
              {
                value: intl.formatNumber(
                  validationConfig.adgroupDefaultBidValidationConfigCPC.min,
                  {
                    minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                    maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                  }
                ),
                currencyCode: currencySymbol,
              }
            ),
          },
          {
            validator: (value: string) =>
              Number(value) <=
              validationConfig.adgroupDefaultBidValidationConfigCPC.max,
            errorMessage: intl.formatMessage(
              {
                id: I18nKey.VALIDATION_LESS_THAN,
              },
              {
                value: intl.formatNumber(
                  validationConfig.adgroupDefaultBidValidationConfigCPC.max,
                  {
                    minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                    maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                  }
                ),
                currencyCode: currencySymbol,
              }
            ),
          },
        ],
        intl,
        (
          valueBeingEdited[
            ADGROUPS_API_COLUMN_NAME.AdGroupDefaultBidCPC
          ] as BulkEditModalNumericTypeValue
        )?.operator
      ),
    },
    onChange: noop,
  };

  const ADGROUP_DEFAULT_BID_BULK_MENU_OPTION = {
    headerText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_DEFAULT_BID,
    }),
    editKey: ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    onSelect: (v: boolean) =>
      onBulkEditOptionSelection(ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid, v),
    controls: getDefaultBidBulkMenuOptionElements(
      DEFAULT_BID_BULK_MENU_OPTION_VCPM,
      DEFAULT_BID_BULK_MENU_OPTION_CPC,
      adType,
      tableChange,
      adGroupTableData
    ),
  };

  const showAutoTargetsOptions = adGroupTableData
    .filter(({ adGroupId }) => tableChange.select.rows.includes(adGroupId))
    .every(({ adGroupDetails: { campaignTargetingType } }) =>
      isAutoCampaign(campaignTargetingType)
    );

  const ADGROUP_TAGS_BULK_MENU_OPTION = {
    headerText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TAGS,
    }),
    editKey: ADGROUPS_API_COLUMN_NAME.Tags,
    onSelect: (v: boolean) =>
      onBulkEditOptionSelection(ADGROUPS_API_COLUMN_NAME.Tags, v),
    controls: [
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_LABELS,
        }),
        value: '',
        type: BulkEditModalElementType.CheckBox,
        columnKey: ADGROUPS_API_COLUMN_NAME.Tags,
        options: [
          ...(showAutoTargetsOptions
            ? [
                {
                  label: AUTO,
                  value: TargetType.Auto,
                },
              ]
            : []),
          {
            label: BRAND,
            value: TargetType.Brand,
          },
          {
            label: COMPETITOR,
            value: TargetType.Competitor,
          },
          {
            label: GENERIC,
            value: TargetType.Generic,
          },
        ],
      },
    ],
    visibilityCriteria: {
      enabledByFeatureFlag: true,
    },
  };

  switch (flywheelSalesChannel) {
    case FlywheelSalesChannel.Walmart:
      return getBulkEditMenuWalmart(
        adType,
        ADGROUP_DEFAULT_BID_STATUS_BULK_MENU_OPTION,
        ADGROUP_REVIEW_STATUS_BULK_MENU_OPTION,
        ADGROUP_TAGS_BULK_MENU_OPTION,
        ADGROUP_FW_SETTINGS_BULK_MENU_OPTIONS
      ).filter((e) => {
        if (e.visibilityCriteria) {
          return e.visibilityCriteria.enabledByFeatureFlag;
        } else {
          return true;
        }
      });

    case FlywheelSalesChannel.Amazon:
      let bulkMenuOptions: ExtendedAdGroupEditControlProps[] =
        getInitialBulkMenuAmz(
          adType,
          ADGROUP_TAGS_BULK_MENU_OPTION,
          ADGROUP_DEFAULT_BID_BULK_MENU_OPTION
        );
      if (
        (adType !== AdType.SponsoredDisplay &&
          isMerchantTypeVendor(merchantType)) ||
        isMerchantTypeSeller(merchantType)
      ) {
        bulkMenuOptions.push(ADGROUP_DEFAULT_BID_STATUS_BULK_MENU_OPTION);
      }
      if (
        !areAllSelectedCampaignCostTypeVcpm(tableChange, adGroupTableData) &&
        isAtleastOneSelectedRowBiddable(
          tableChange.select.rows,
          adGroupTableData
        )
      ) {
        bulkMenuOptions.push(...ADGROUP_FW_SETTINGS_BULK_MENU_OPTIONS);
      }
      return bulkMenuOptions.filter((e) => {
        if (e.visibilityCriteria) {
          return e.visibilityCriteria.enabledByFeatureFlag;
        } else {
          return true;
        }
      });
  }
};

export const getIdsEligibleForUpdate = (
  isBulkMenuValueAdGroupStatusOrDefaultBid: boolean,
  isBulkMenuValueAdGroupReviewStatus: boolean,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  merchantsWithAutomationEnabled: string[] | undefined,
  isAdgroupTags?: boolean,
  isAiKeywordRecommendationSetting?: boolean,
  isBidModifierSettings?: boolean,
  isAiBiddingSetting?: boolean,
  isMinBidSetting?: boolean,
  isMaxBidSetting?: boolean
) => {
  return tableChange.select.rows.filter((rowId: string) => {
    if (isBulkMenuValueAdGroupStatusOrDefaultBid || isAdgroupTags) {
      return true;
    }

    const adGroupDataForRow = adGroupTableData.find(
      ({ adGroupId }) => adGroupId === rowId
    );

    if (isBidModifierSettings) {
      const updatedAiBidding =
        tableChange.cell[rowId]?.aiBidding ??
        adGroupDataForRow?.flywheelSettings.aiBidding;
      return updatedAiBidding === AiBiddingValues.Ai;
    }

    if (
      (isMinBidSetting || isMaxBidSetting) &&
      !isUndefined(adGroupDataForRow?.flywheelSettings.aiBidding)
    ) {
      const updatedAiBidding =
        tableChange.cell[rowId]?.aiBidding ??
        adGroupDataForRow?.flywheelSettings.aiBidding;
      return updatedAiBidding === AiBiddingValues.Ai;
    }

    if (isAiBiddingSetting) {
      const updatedAiBidding =
        tableChange.cell[rowId]?.aiBidding ??
        adGroupDataForRow?.flywheelSettings.aiBidding;
      if (
        SMART_GOALS.includes(
          adGroupDataForRow?.flywheelSettings.advertisingGoal ||
            AdvertisingGoal.External
        ) &&
        updatedAiBidding === AiBiddingValues.NotEnabled
      ) {
        return false;
      }
      return updatedAiBidding !== AiBiddingValues.Smart;
    }

    if (isAiKeywordRecommendationSetting) {
      return (
        adGroupDataForRow?.adGroupDetails?.adGroupUseCase !==
        AdGroupUseCaseType.ProductAttributeTargeting
      );
    }

    if (isBulkMenuValueAdGroupReviewStatus) {
      return (
        adGroupDataForRow?.channelSettings.adGroupReviewStatus?.reviewStatus ===
          AdGroupReviewStatusType.InProgress ||
        adGroupDataForRow?.channelSettings.adGroupReviewStatus?.reviewStatus ===
          AdGroupReviewStatusType.Pending
      );
    }

    if (
      !getOrDefaultIsBiddableFlag(adGroupDataForRow?.adGroupDetails.isBiddable)
    ) {
      return false;
    }

    return merchantsWithAutomationEnabled?.includes(
      adGroupDataForRow?.adGroupDetails.merchantCountryId ?? ''
    );
  });
};

export const getIsCampaignCostTypeVCPMAndFlywheelSettingIsSelected = (
  bulkMenuValue: string,
  rowData?: AdGroupDetails
) => {
  return (
    isCampaignCostTypeVcpm(rowData?.adGroupDetails.campaignCostType) &&
    (bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MinBid ||
      bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MaxBid ||
      bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MACSTarget ||
      bulkMenuValue === ADGROUPS_API_COLUMN_NAME.CampaignStatus)
  );
};

export const getTableChangeBulkMenuValue = (
  bulkMenuValue: string,
  salesChannel: FlywheelSalesChannel
) => {
  return (
    (bulkMenuValue !== ADGROUPS_API_COLUMN_NAME.MinBid &&
      salesChannel === FlywheelSalesChannel.Walmart &&
      bulkMenuValue !== ADGROUPS_API_COLUMN_NAME.MaxBid) ||
    salesChannel !== FlywheelSalesChannel.Walmart
  );
};

export const getUpdatedCampaignTargetingTypeRowValue = (
  value: string,
  bulkChanges: string,
  currentValue?: string,
  operator?: BulkEditModalNumericValueOperator
) => {
  const updatedValue = getUpdatedValueFromOperator(
    currentValue,
    bulkChanges,
    value,
    operator
  );
  return !isNaN(Number(updatedValue)) ? updatedValue : currentValue!;
};

interface AdGroupColumnsInvalidChangeCountProps {
  readonly inValidChangesCount: number;
  readonly bulkMenuValue: string;
  readonly bulkChanges: TableCellChange;
  readonly rowId: string;
  readonly adType: AdType;
  readonly value: string;
  readonly rowData?: AdGroupDetails;
  readonly operator?: BulkEditModalNumericValueOperator;
  readonly adgroupDefaultBidValidationConfigCPC: NumericValueConfig;
  readonly adgroupDefaultBidValidationConfigVCPM: NumericValueConfig;
  readonly minBidAutoValidationConfig: NumericValueConfig;
  readonly minBidManualValidationConfig: NumericValueConfig;
  readonly maxBidManualValidationConfig: NumericValueConfig;
  readonly maxBidAutoValidationConfig: NumericValueConfig;
  readonly adgroupMacsTargetValidationConfig: NumericValueConfig;
  readonly isVCPM?: boolean;
}

export const getCampaignCostTypeValue = (
  value: string,
  bulkChanges: string,
  currentValue?: string,
  operator?: BulkEditModalNumericValueOperator
) => {
  const updatedValue = getUpdatedValueFromOperator(
    currentValue,
    bulkChanges,
    value,
    operator
  );
  return !isNaN(Number(updatedValue)) ? updatedValue : currentValue!;
};

function handleAdGroupDefaultBidChange({
  rowData,
  bulkChanges,
  rowId,
  bulkMenuValue,
  value,
  adType,
  isVCPM,
  operator,
  inValidChangesCount,
  adgroupDefaultBidValidationConfigCPC,
  adgroupDefaultBidValidationConfigVCPM,
}: AdGroupColumnsInvalidChangeCountProps) {
  const campaignCostType = rowData?.adGroupDetails.campaignCostType;
  const campaignDailyBudget =
    rowData?.adGroupDetails.campaignDailyBudget?.amount;
  const currentAdGroupDefaultBid = rowData?.channelSettings.defaultBid?.amount;
  const adGroupDefaultBid =
    bulkChanges[rowId][ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid];

  if (
    (isVCPM && campaignCostType === CampaignCostType.VCPM) ||
    (!isVCPM && campaignCostType !== CampaignCostType.VCPM)
  ) {
    bulkChanges[rowId][bulkMenuValue] = getCampaignCostTypeValue(
      value,
      bulkChanges[rowId][bulkMenuValue],
      currentAdGroupDefaultBid,
      operator
    );
  }

  if (!bulkChanges[rowId][bulkMenuValue]) {
    delete bulkChanges[rowId][bulkMenuValue];
  }

  return getInValidChangesCount(
    inValidChangesCount,
    adGroupDefaultBid,
    adType as AdType.SponsoredProducts | AdType.SponsoredDisplay,
    campaignCostType,
    campaignDailyBudget,
    currentAdGroupDefaultBid,
    {
      adgroupDefaultBidValidationConfigCPC,
      adgroupDefaultBidValidationConfigVCPM,
    }
  );
}

const inValidChangesCountADGROUPS_API_COLUMN_NAME = ({
  inValidChangesCount,
  bulkMenuValue,
  bulkChanges,
  rowId,
  adType,
  value,
  rowData,
  operator,
  adgroupDefaultBidValidationConfigCPC,
  adgroupDefaultBidValidationConfigVCPM,
  minBidAutoValidationConfig,
  minBidManualValidationConfig,
  maxBidManualValidationConfig,
  maxBidAutoValidationConfig,
  adgroupMacsTargetValidationConfig,
  isVCPM,
}: AdGroupColumnsInvalidChangeCountProps) => {
  if (
    bulkMenuValue === ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid &&
    (adType === AdType.SponsoredDisplay || adType === AdType.SponsoredProducts)
  ) {
    inValidChangesCount = handleAdGroupDefaultBidChange({
      inValidChangesCount,
      bulkMenuValue,
      bulkChanges,
      rowId,
      adType,
      value,
      rowData,
      operator,
      adgroupDefaultBidValidationConfigCPC,
      adgroupDefaultBidValidationConfigVCPM,
      minBidAutoValidationConfig,
      minBidManualValidationConfig,
      maxBidManualValidationConfig,
      maxBidAutoValidationConfig,
      adgroupMacsTargetValidationConfig,
      isVCPM,
    });
  }

  if (bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MinBid) {
    inValidChangesCount = getMinBidInvalidChangesCount(
      rowId,
      bulkChanges,
      minBidManualValidationConfig,
      minBidAutoValidationConfig,
      inValidChangesCount,
      rowData
    );
  }

  if (bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MaxBid) {
    inValidChangesCount = getMaxBidInvalidChangesCount(
      rowId,
      bulkChanges,
      maxBidManualValidationConfig,
      maxBidAutoValidationConfig,
      inValidChangesCount,
      rowData
    );
  }

  if (bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MACSTarget) {
    const macsTarget = bulkChanges[rowId][ADGROUPS_API_COLUMN_NAME.MACSTarget];

    inValidChangesCount = getMacsTargetInvalidCount(
      macsTarget,
      adgroupMacsTargetValidationConfig,
      inValidChangesCount
    );
  }

  const isNoTag =
    bulkMenuValue === ADGROUPS_API_COLUMN_NAME.Tags &&
    value === TargetType.NoTag;
  const hasNoTargetSegments = !rowData?.adGroupDetails.targetSegments;

  if (isNoTag && hasNoTargetSegments) {
    bulkChanges[rowId][bulkMenuValue] = '';
  }

  return inValidChangesCount;
};

const handleApplyBulkChange =
  (
    tableChange: TableChange,
    adGroupTableData: AdGroupDetails[],
    merchantsWithAutomationEnabled: string[],
    adGroupTableDataForAllPages: AdGroupDetails[],
    selectedSalesChannel: FlywheelSalesChannel,
    selectedAdType: AdType,
    validationConfig: {
      adgroupMacsTargetValidationConfig: ValidationConfig;
      minBidAutoValidationConfig: ValidationConfig;
      minBidManualValidationConfig: ValidationConfig;
      maxBidAutoValidationConfig: ValidationConfig;
      adgroupDefaultBidValidationConfigVCPM: ValidationConfig;
      adgroupDefaultBidValidationConfigCPC: ValidationConfig;
      maxBidManualValidationConfig: ValidationConfig;
    }
  ) =>
  (
    bulkMenuValue: string,
    bulkChanges: TableCellChange,
    value: string,
    operator?: BulkEditModalNumericValueOperator,
    isBidAuto?: boolean,
    isVCPM?: boolean
  ): { bulkChanges: TableCellChange; inValidChangesCount: number } => {
    const {
      adgroupDefaultBidValidationConfigCPC,
      adgroupDefaultBidValidationConfigVCPM,
      adgroupMacsTargetValidationConfig,
      maxBidAutoValidationConfig,
      maxBidManualValidationConfig,
      minBidAutoValidationConfig,
      minBidManualValidationConfig,
    } = validationConfig;

    const isBulkMenuValueAdGroupStatusOrDefaultBid = [
      ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    ].includes(bulkMenuValue);

    const isBulkMenuValueAdGroupReviewStatus =
      bulkMenuValue === ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus;
    const isAdgroupTags = bulkMenuValue === ADGROUPS_API_COLUMN_NAME.Tags;
    const isAiKeywordRecommendationSetting =
      bulkMenuValue === ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting;
    const isBidModifierSetting =
      bulkMenuValue === ADGROUPS_API_COLUMN_NAME.BidModifier;
    const isAiBiddingSetting =
      bulkMenuValue === ADGROUPS_API_COLUMN_NAME.AiBidding;
    const isMinBidSetting = bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MinBid;
    const isMaxBidSetting = bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MaxBid;

    let inValidChangesCount = 0;

    const rowIdsEligibleForUpdate = getIdsEligibleForUpdate(
      isBulkMenuValueAdGroupStatusOrDefaultBid,
      isBulkMenuValueAdGroupReviewStatus,
      tableChange,
      adGroupTableData,
      merchantsWithAutomationEnabled,
      isAdgroupTags,
      isAiKeywordRecommendationSetting,
      isBidModifierSetting,
      isAiBiddingSetting,
      isMinBidSetting,
      isMaxBidSetting
    );

    if (getTableChangeBulkMenuValue(bulkMenuValue, selectedSalesChannel)) {
      rowIdsEligibleForUpdate.forEach((rowId: string) => {
        if (!bulkChanges[rowId]) {
          bulkChanges[rowId] = {};
        }
        const rowData = find(adGroupTableDataForAllPages, {
          [TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]]: rowId,
        });

        // Do not assign value to flywheel setting if campaign cost type is VCPM
        const isCampaignCostTypeVCPMAndFlywheelSettingIsSelected =
          getIsCampaignCostTypeVCPMAndFlywheelSettingIsSelected(
            bulkMenuValue,
            rowData
          );
        setIsCampaignCostTypeVCPMAndFlywheelSettingIsSelected(
          isCampaignCostTypeVCPMAndFlywheelSettingIsSelected,
          bulkChanges,
          rowId,
          bulkMenuValue,
          [value],
          rowData,
          operator
        );

        // Ad group default bid should be less than half of Campaign daily budget for SD adGroups
        // and should be less than campaign daily budget for SP adGroups
        inValidChangesCount = inValidChangesCountADGROUPS_API_COLUMN_NAME({
          inValidChangesCount,
          bulkMenuValue,
          bulkChanges,
          rowId,
          adType: selectedAdType,
          value,
          rowData,
          operator,
          adgroupDefaultBidValidationConfigVCPM,
          adgroupDefaultBidValidationConfigCPC,
          adgroupMacsTargetValidationConfig,
          maxBidAutoValidationConfig,
          maxBidManualValidationConfig,
          minBidManualValidationConfig,
          minBidAutoValidationConfig,
          isVCPM,
        });
      });
    } else {
      rowIdsEligibleForUpdate.forEach((rowId: string) => {
        if (!bulkChanges[rowId]) {
          bulkChanges[rowId] = {};
        }

        const rowData = find(adGroupTableData, {
          [TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]]: rowId,
        });
        const campaignTargetingType =
          rowData?.adGroupDetails.campaignTargetingType;

        if (
          (isBidAuto && campaignTargetingType === CampaignTargetingType.Auto) ||
          (!isBidAuto && campaignTargetingType !== CampaignTargetingType.Auto)
        ) {
          bulkChanges[rowId][bulkMenuValue] =
            getUpdatedCampaignTargetingTypeRowValue(
              value,
              bulkChanges[rowId][bulkMenuValue],
              (get(
                rowData,
                EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING[bulkMenuValue]
              ) as string) || '0',
              operator
            );
        }

        const rowDataFromAdGroupDataForAllPages = find(
          adGroupTableDataForAllPages,
          {
            [TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]]: rowId,
          }
        );
        inValidChangesCount = getInValidChangesCounts(
          inValidChangesCount,
          bulkChanges,
          rowId,
          bulkMenuValue,
          rowData,
          rowDataFromAdGroupDataForAllPages,
          {
            maxBidAutoValidationConfig,
            maxBidManualValidationConfig,
            minBidManualValidationConfig,
            minBidAutoValidationConfig,
          }
        );
      });
    }

    return { bulkChanges, inValidChangesCount };
  };

export const setBulkChanges = (
  bulkChanges: TableCellChange,
  bulkEditValues: BulkEditModalValues,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  merchantsWithAutomationEnabled: string[],
  adGroupTableDataForAllPages: AdGroupDetails[],
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdType: AdType,
  validationConfig: {
    adgroupMacsTargetValidationConfig: ValidationConfig;
    minBidAutoValidationConfig: ValidationConfig;
    minBidManualValidationConfig: ValidationConfig;
    maxBidAutoValidationConfig: ValidationConfig;
    adgroupDefaultBidValidationConfigVCPM: ValidationConfig;
    adgroupDefaultBidValidationConfigCPC: ValidationConfig;
    maxBidManualValidationConfig: ValidationConfig;
  }
) => {
  const applyBulkChanges = handleApplyBulkChange(
    tableChange,
    adGroupTableData,
    merchantsWithAutomationEnabled,
    adGroupTableDataForAllPages,
    selectedSalesChannel,
    selectedAdType,
    validationConfig
  );

  Object.entries(bulkEditValues).forEach(([key, val]) => {
    if (key === ADGROUPS_API_COLUMN_NAME.MinBid) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.MinBid,
        bulkChanges,
        (val as BulkEditModalNumericTypeValue).value!,
        (val as BulkEditModalNumericTypeValue).operator
      );
    }

    if (key === ADGROUPS_API_COLUMN_NAME.MaxBid) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.MaxBid,
        bulkChanges,
        (val as BulkEditModalNumericTypeValue).value!,
        (val as BulkEditModalNumericTypeValue).operator
      );
    }

    if (key === ADGROUPS_API_COLUMN_NAME.MinBidAuto) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.MinBid,
        bulkChanges,
        (val as BulkEditModalNumericTypeValue).value!,
        (val as BulkEditModalNumericTypeValue).operator,
        true
      );
    }

    if (key === ADGROUPS_API_COLUMN_NAME.MaxBidAuto) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.MaxBid,
        bulkChanges,
        (val as BulkEditModalNumericTypeValue).value!,
        (val as BulkEditModalNumericTypeValue).operator,
        true
      );
    }

    if (key === ADGROUPS_API_COLUMN_NAME.MinBidManual) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.MinBid,
        bulkChanges,
        (val as BulkEditModalNumericTypeValue).value!,
        (val as BulkEditModalNumericTypeValue).operator,
        false
      );
    }

    if (key === ADGROUPS_API_COLUMN_NAME.MaxBidManual) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.MaxBid,
        bulkChanges,
        (val as BulkEditModalNumericTypeValue).value!,
        (val as BulkEditModalNumericTypeValue).operator,
        false
      );
    }

    if (key === ADGROUPS_API_COLUMN_NAME.AdGroupDefaultBidVcpm) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
        bulkChanges,
        (val as BulkEditModalNumericTypeValue).value!,
        (val as BulkEditModalNumericTypeValue).operator,
        false,
        true
      );
    }

    if (key === ADGROUPS_API_COLUMN_NAME.AdGroupDefaultBidCPC) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
        bulkChanges,
        (val as BulkEditModalNumericTypeValue).value!,
        (val as BulkEditModalNumericTypeValue).operator,
        false,
        false
      );
    }

    if (key === ADGROUPS_API_COLUMN_NAME.Tags) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.Tags,
        bulkChanges,
        (val as string[]).join(',')
      );
    }

    if (key === ADGROUPS_API_COLUMN_NAME.MACSTarget) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.MACSTarget,
        bulkChanges,
        (val as BulkEditModalNumericTypeValue).value!,
        (val as BulkEditModalNumericTypeValue).operator
      );
    }

    if (key === ADGROUPS_API_COLUMN_NAME.BidModifier) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.BidModifier,
        bulkChanges,
        (val as BulkEditModalNumericTypeValue).value!,
        (val as BulkEditModalNumericTypeValue).operator
      );
    }

    if (key === ADGROUPS_API_COLUMN_NAME.AiBidding) {
      applyBulkChanges(
        ADGROUPS_API_COLUMN_NAME.AiBidding,
        bulkChanges,
        val as string
      );
    }

    if (
      [
        ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
        ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
        ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus,
        ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
      ].includes(key)
    ) {
      applyBulkChanges(key, bulkChanges, val as string);
    }
  });

  return bulkChanges;
};

export const getSelectedBulkMenuValues = (
  bulkEditValues: BulkEditModalValues,
  bulkEditSelectedOptions: BulkEditSelectedOptions
) => {
  Object.entries(bulkEditSelectedOptions).forEach(([k, v]) => {
    if (!v) {
      delete bulkEditValues[k];
      if (k === ADGROUPS_API_COLUMN_NAME.MaxBid) {
        delete bulkEditValues[ADGROUPS_API_COLUMN_NAME.MaxBidAuto];
        delete bulkEditValues[ADGROUPS_API_COLUMN_NAME.MaxBidManual];
      }

      if (k === ADGROUPS_API_COLUMN_NAME.MinBid) {
        delete bulkEditValues[ADGROUPS_API_COLUMN_NAME.MinBidAuto];
        delete bulkEditValues[ADGROUPS_API_COLUMN_NAME.MinBidManual];
      }

      if (k === ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid) {
        delete bulkEditValues[ADGROUPS_API_COLUMN_NAME.AdGroupDefaultBidCPC];
        delete bulkEditValues[ADGROUPS_API_COLUMN_NAME.AdGroupDefaultBidVcpm];
      }
    }
  });

  return bulkEditValues;
};

export const getCheckBoxState = (totalRows: number, selectedRows: number) => {
  if (selectedRows === 0) return CheckboxCheckedState.Unchecked;

  if (selectedRows < totalRows) return CheckboxCheckedState.Some;

  return CheckboxCheckedState.Checked;
};

const getValidSelectedRowsBidAutomationCount = (
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
): number =>
  adGroupTableData.filter(({ adGroupId, flywheelSettings }) => {
    return (
      tableChange.select.rows.includes(adGroupId) &&
      (!isNil(flywheelSettings.automationStatus) ||
        !isNil(tableChange.cell[adGroupId]?.automationStatus))
    );
  }).length;

const getValidSelectedRowsAiRecommendationKeywordSettingCount = (
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
): number =>
  adGroupTableData.filter(({ adGroupId, adGroupDetails }) => {
    return (
      tableChange.select.rows.includes(adGroupId) &&
      adGroupDetails?.adGroupUseCase !==
        AdGroupUseCaseType.ProductAttributeTargeting &&
      (!isNil(adGroupDetails.aiRecommendationKeywordSetting) ||
        !isNil(tableChange.cell[adGroupId]?.aiRecommendationKeywordSetting))
    );
  }).length;

const getValidSelectedRowsAutomationStatusCount = (
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
): number =>
  adGroupTableData.filter(({ adGroupId, channelSettings }) => {
    return (
      tableChange.select.rows.includes(adGroupId) &&
      (channelSettings.adGroupReviewStatus?.reviewStatus ===
        AdGroupReviewStatusType.InProgress ||
        channelSettings.adGroupReviewStatus?.reviewStatus ===
          AdGroupReviewStatusType.Pending)
    );
  }).length;

const getValidBiddableSelectedRows = (
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
) => {
  const selectedTargets: AdGroupDetails[] = getSelectedAdGroups(
    tableChange.select.rows,
    adGroupTableData
  );
  return selectedTargets.filter(({ adGroupDetails: { isBiddable } }) =>
    getOrDefaultIsBiddableFlag(isBiddable)
  ).length;
};

const getValidAiBiddingTableRows = (
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
) => {
  const selectedAdGroups: AdGroupDetails[] = getSelectedAdGroups(
    tableChange.select.rows,
    adGroupTableData
  );
  return selectedAdGroups.filter(
    ({ flywheelSettings: { aiBidding, advertisingGoal }, adGroupId }) => {
      const updatedAiBidding =
        tableChange.cell[adGroupId]?.aiBidding ?? aiBidding;
      return (
        updatedAiBidding !== AiBiddingValues.Smart &&
        (advertisingGoal ? !SMART_GOALS.includes(advertisingGoal) : true)
      );
    }
  );
};

const getValidBidModifierTableRows = (
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
) => {
  const selectedAdGroups: AdGroupDetails[] = getSelectedAdGroups(
    tableChange.select.rows,
    adGroupTableData
  );
  return selectedAdGroups.filter(
    ({ flywheelSettings: { aiBidding }, adGroupId }) => {
      const updatedAiBidding =
        tableChange.cell[adGroupId]?.aiBidding ?? aiBidding;
      return updatedAiBidding === AiBiddingValues.Ai;
    }
  );
};

const getValidAcosBidModifierTableRows = (
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
) => {
  const selectedAdGroups: AdGroupDetails[] = getSelectedAdGroups(
    tableChange.select.rows,
    adGroupTableData
  );
  return selectedAdGroups.filter(
    ({ flywheelSettings: { aiBidding }, adGroupId }) => {
      const updatedAiBidding =
        tableChange.cell[adGroupId]?.aiBidding ?? aiBidding;
      return updatedAiBidding !== AiBiddingValues.NotEnabled;
    }
  );
};

const getValidMinMaxBidTableRows = (
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[]
) => {
  const selectedAdGroups: AdGroupDetails[] = getSelectedAdGroups(
    tableChange.select.rows,
    adGroupTableData
  );
  return selectedAdGroups.filter(
    ({ flywheelSettings: { aiBidding }, adGroupId }) => {
      const updatedAiBidding =
        tableChange.cell[adGroupId]?.aiBidding ?? aiBidding;
      return updatedAiBidding === AiBiddingValues.Ai;
    }
  );
};

export const getHelpGuide = (
  bulkMenuValue: string,
  intl: IntlShape,
  selectedCount: number,
  tableChange: TableChange,
  adGroupTableData: AdGroupDetails[],
  showSmartCampaign?: boolean
): HelpPromptProps | undefined => {
  if (bulkMenuValue === ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus) {
    const validCount = getValidSelectedRowsAutomationStatusCount(
      tableChange,
      adGroupTableData
    );
    const isAtleastOneSelectedBidAutomationStatusUnset =
      selectedCount !== validCount;

    if (isAtleastOneSelectedBidAutomationStatusUnset) {
      return {
        text: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_BID_HELP_TEXT,
          },
          {
            totalSelectedCount: selectedCount,
            validCount,
          }
        ),
        content: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_ADGROUP_REVIEW_STATUS_HELP_CONTENT,
        }),
        variant: HelpPromptVariant.Dropdown,
      };
    }
  }

  if (bulkMenuValue === ADGROUPS_API_COLUMN_NAME.BidAutomationStatus) {
    const validCount = getValidSelectedRowsBidAutomationCount(
      tableChange,
      adGroupTableData
    );
    const isAtleastOneSelectedBidAutomationStatusUnset =
      selectedCount !== validCount;

    if (isAtleastOneSelectedBidAutomationStatusUnset) {
      return {
        text: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_AUTOMATION_STATUS_HELP_TEXT,
          },
          {
            totalSelectedCount: selectedCount,
            validCount,
          }
        ),
        content: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_AUTOMATION_STATUS_HELP_CONTENT,
        }),
        variant: HelpPromptVariant.Dropdown,
      };
    }
  }

  if (
    bulkMenuValue === ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting
  ) {
    const validCount = getValidSelectedRowsAiRecommendationKeywordSettingCount(
      tableChange,
      adGroupTableData
    );
    const isAtleastOneSelectedAiRecommendationKeywordSettingPAT =
      selectedCount !== validCount;
    if (isAtleastOneSelectedAiRecommendationKeywordSettingPAT) {
      return {
        text: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_AUTOMATION_STATUS_HELP_TEXT,
          },
          {
            totalSelectedCount: selectedCount,
            validCount: validCount,
          }
        ),
        content: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_KEYWORD_RECOMMENDATION_HELP_CONTENT,
        }),
        variant: HelpPromptVariant.Dropdown,
      };
    }
  }

  if (isFlywheelSettingsColumn(bulkMenuValue) && !showSmartCampaign) {
    const validCount = getValidBiddableSelectedRows(
      tableChange,
      adGroupTableData
    );
    if (validCount !== selectedCount) {
      return {
        text: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_SELECTION_COUNT,
          },
          {
            totalSelectedCount: selectedCount,
            validCount,
          }
        ),
        content: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_FLYWHEEL_SETTINGS_DISABLED_FOR_LEGACY_CAMPAIGNS,
        }),
        variant: HelpPromptVariant.Dropdown,
      };
    }
  }
  if (bulkMenuValue === ADGROUPS_API_COLUMN_NAME.AiBidding) {
    const validCount = getValidAiBiddingTableRows(
      tableChange,
      adGroupTableData
    ).length;
    const nonValidCount = selectedCount - validCount;
    if (validCount !== selectedCount) {
      return {
        text: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AI_BIDDING_NOT_APPLICABLE_HEADER,
          },
          {
            selectedCount,
            validCount,
          }
        ),
        content: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AI_BIDDING_NOT_APPLICABLE_REASON,
          },
          {
            separator: <br />,
            nonValidCount,
            validCount,
          }
        ),
        variant: HelpPromptVariant.Dropdown,
      };
    }
  }
  if (bulkMenuValue === ADGROUPS_API_COLUMN_NAME.BidModifier) {
    const validCount = getValidBidModifierTableRows(
      tableChange,
      adGroupTableData
    ).length;
    if (validCount !== selectedCount) {
      return {
        text: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_BID_MODIFIER_NOT_APPLICABLE_HEADER,
          },
          {
            selectedCount,
            validCount,
          }
        ),
        content: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_BID_MODIFIER_NOT_APPLICABLE_REASON,
          },
          {
            selectedCount,
            validCount,
          }
        ),
        variant: HelpPromptVariant.Dropdown,
      };
    }
  }
  if (
    bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MACSTarget &&
    showSmartCampaign === true
  ) {
    const validCount = getValidAcosBidModifierTableRows(
      tableChange,
      adGroupTableData
    ).length;
    if (validCount !== selectedCount) {
      return {
        text: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ACOS_LIMIT_NOT_APPLICABLE_HEADER,
          },
          {
            selectedCount,
            validCount,
          }
        ),
        content: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ACOS_LIMIT_NOT_APPLICABLE_REASON,
          },
          {
            selectedCount,
            validCount,
          }
        ),
        variant: HelpPromptVariant.Dropdown,
      };
    }
  }
  if (
    [ADGROUPS_API_COLUMN_NAME.MinBid, ADGROUPS_API_COLUMN_NAME.MaxBid].includes(
      bulkMenuValue
    ) &&
    showSmartCampaign === true
  ) {
    const validCount = getValidMinMaxBidTableRows(
      tableChange,
      adGroupTableData
    ).length;
    if (validCount !== selectedCount) {
      return {
        text: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_MIN_MAX_BID_NOT_APPLICABLE_HEADER,
          },
          {
            selectedCount,
            validCount,
            field: intl.formatMessage({
              id:
                bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MinBid
                  ? I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_BID
                  : I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MAX_BID,
            }),
          }
        ),
        content: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_MIN_MAX_BID_NOT_APPLICABLE_REASON,
          },
          {
            selectedCount,
            validCount,
            field: intl.formatMessage({
              id:
                bulkMenuValue === ADGROUPS_API_COLUMN_NAME.MinBid
                  ? I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_BID
                  : I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MAX_BID,
            }),
          }
        ),
        variant: HelpPromptVariant.Dropdown,
      };
    }
  }
};

export const getActionRowButtons = (
  tableMode: DualModes,
  intl: IntlShape,
  getSaveButtonState: ButtonState,
  onActionClick: (actionType: Variant) => void
) => {
  return tableMode === DualModes.Edit
    ? {
        primaryButtonProps: {
          label: intl.formatMessage({
            id: I18nKey.GENERIC_SAVE_CHANGES,
          }),
          size: ButtonSize.Medium,
          state: getSaveButtonState,
          variant: ButtonVariant.Primary,
          onClick: () => onActionClick(Variant.Apply),
          dataTestId: 'ao_adItemsTableSave',
        },
        showCancelButton: true,
        onCancel: () => onActionClick(Variant.Discard),
      }
    : {};
};
