import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { PRODUCT_ADS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) =>
  makeNumericColumn<ProductAdDetails>(
    ({ adItemPerformance: { advertisedSkuUnits } }) => {
      return advertisedSkuUnits?.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const advertisedSKUUnitsColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: PRODUCT_ADS_API_COLUMN_NAME.AdvertisedSkuUnits,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ADVERTISEDSKUUNITS,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const advertisedSKUFilter = createNumericDataFieldFilter(
  PRODUCT_ADS_API_COLUMN_NAME.AdvertisedSkuUnits,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ADVERTISEDSKUUNITS,
  isValidNumber(0)
);
