import React from 'react';
import { useIntl } from 'react-intl';

import { ColumnAlign } from '@teikametrics/tm-design-system';

import { FlywheelTableV2ColumnProps } from '../../../../../../containers/tableV2/types';
import { HomepageResponseMetricKeys } from '../../../../../../lib/types/CompassSharedTypes';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { makeNumberWithProgressBarColumn } from '../../../../../products/containers/utils/makeTableCellsUtils';
import { BusinessPerformanceColumnName } from '../../utils/constants';
import {
  PerformanceTableExtraProps,
  ProductCellData,
  ProductRowData,
  getNumberWithProgressBarColumnProps,
} from '../../utils/performance';

export const RowCellElement: React.FC<ProductCellData> = (data) => {
  const intl = useIntl();
  return makeNumberWithProgressBarColumn<ProductCellData>(
    ({ currentMetrics, adSales, activeColumn }) => {
      const currentValue = +(
        currentMetrics?.advertisementFields?.[
          HomepageResponseMetricKeys.AdSales
        ]?.amount || 0
      );
      const adSalesAmount = +(adSales?.amount || 0);

      return getNumberWithProgressBarColumnProps(
        intl,
        currentValue,
        adSalesAmount,
        activeColumn === BusinessPerformanceColumnName.AdSales,
        currentMetrics?.advertisementFields?.[
          HomepageResponseMetricKeys.AdSales
        ]?.currency
      );
    }
  )(data);
};

export const adSales: FlywheelTableV2ColumnProps<
  ProductRowData,
  {},
  PerformanceTableExtraProps
> = {
  header: I18nKey.ADVERTISING_OPTIMIZATION_CAMPAIGNS_AD_SALES,
  sortable: false,
  size: 135,
  minSize: 135,
  accessorKey: BusinessPerformanceColumnName.AdSales,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <RowCellElement {...original} {...meta} />,
};
