import { useKnockFeed } from '@knocklabs/react-notification-feed';
import { BellIcon, Icon, IconSize } from '@teikametrics/tm-design-system';
import React, { useContext, useEffect, useState } from 'react';

import { KnockProvider } from '../../containers/knockProvider';
import { UserContext } from '../../containers/userProvider/userProvider';
import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import useNotificationSettings from './useNotificationSettings';
import NotificationPanel from './NotificationPanel';
import { parseKnockNotifications } from './helpers';
import { useIntl } from 'react-intl';

const NotificationBadge: React.FC = () => {
  const intl = useIntl();
  const { feedClient, useFeedStore } = useKnockFeed();
  const userContext = useContext(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)?.id || '';
  const notifications = useFeedStore((state) =>
    parseKnockNotifications(
      state.items.filter((item) => item?.data?.accountId === accountId)
    )
  );
  const unreadNotifications = notifications.filter(
    (notification) => !notification.isRead
  );

  const { settings } = useNotificationSettings(intl);

  const [showPanel, setShowPanel] = useState<boolean>(false);

  useEffect(() => {
    feedClient.fetch({});
  }, [feedClient]);

  const handleReadAll = () => {
    feedClient.markAllAsRead();
  };

  if (!settings?.enabled) {
    return null;
  }

  return (
    <>
      <div
        className={`flex items-center justify-center
        w-40 h-40 relative cursor-pointer`}
        data-test-id="notification-badge"
        onClick={() => setShowPanel(true)}
      >
        <Icon svg={BellIcon} size={IconSize.Large} />

        {unreadNotifications.length > 0 && (
          <div
            className={`flex items-center justify-center
          w-16 h-16 bg-orange-500 rounded-full
          text-sm leading-none text-white
          absolute right-0 top-0`}
          >
            {unreadNotifications.length}
          </div>
        )}
      </div>
      {showPanel && (
        <NotificationPanel
          notifications={notifications}
          onReadAll={handleReadAll}
          onClose={() => setShowPanel(false)}
        />
      )}
    </>
  );
};

NotificationBadge.displayName = 'NotificationBadge';

const NotificationBadgeWrapper: React.FC = () => (
  <KnockProvider>
    <NotificationBadge />
  </KnockProvider>
);

export default NotificationBadgeWrapper;
