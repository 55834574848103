import { KnockFeedProvider } from '@knocklabs/react-notification-feed';
import React, { useContext } from 'react';

import { UserContext } from '../userProvider/userProvider';

const API_KEY = process.env.REACT_APP_KNOCK_API_KEY;
const CHANNEL_ID = process.env.REACT_APP_KNOCK_CHANNEL_ID;

export interface KnockProviderProps {
  readonly children: JSX.Element;
}

export const KnockProvider: React.FC<KnockProviderProps> = ({ children }) => {
  const userContext = useContext(UserContext);

  if (!userContext.userInfo.userDetails || !userContext.userInfo.knockToken) {
    return null;
  }

  return (
    <KnockFeedProvider
      apiKey={API_KEY!}
      feedId={CHANNEL_ID!}
      userId={userContext.userInfo.userDetails.id}
      userToken={userContext.userInfo.knockToken}
    >
      {children}
    </KnockFeedProvider>
  );
};
