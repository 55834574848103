import React from 'react';
import { useIntl } from 'react-intl';

import {
  Icon,
  IconSize,
  RadioButton,
  Spinner,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { getCountryFlagIcon } from '../../../../../lib/utilities/countryFlags';
import { StrictStringMap } from '../../../../../lib/types';
import { SalesChannelData } from '../../../../../lib/types/Fam';

interface ModalContentProps {
  loading: boolean;
  adAccounts: StrictStringMap<SalesChannelData[]> | undefined;
  profileIdSelected: string;
  setProfileIdSelected: (id: string) => void;
}

export const ModalContent: React.FC<ModalContentProps> = ({
  loading,
  adAccounts,
  profileIdSelected,
  setProfileIdSelected,
}) => {
  const intl = useIntl();
  const [
    SELECT_ADS_PROFILES_MODAL_AMAZON_HEADER,
    SELECT_ADS_PROFILES_MODAL_AMAZON_DESCRIPTION,
  ] = [
    I18nKey.SELECT_ADS_PROFILES_MODAL_AMAZON_HEADER,
    I18nKey.SELECT_ADS_PROFILES_MODAL_AMAZON_DESCRIPTION,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <>
      <div className="pb-24 mt-24 text-left">
        <div className="text-lg text-grey-900 font-semibold leading-none">
          {SELECT_ADS_PROFILES_MODAL_AMAZON_HEADER}
        </div>
        <div className="pt-12 text-base text-grey-600 leading-normal mb-16">
          {SELECT_ADS_PROFILES_MODAL_AMAZON_DESCRIPTION}
        </div>
      </div>
      <div className="mb-16 overflow-y-auto">
        {loading && <Spinner />}
        {adAccounts && (
          <div className="flex flex-col border-1 border-solid border-grey-200 rounded max-h-400 scrollbar scrollbar-thin scrollbar-thumb-grey-400 scrollbar-track-grey-100 scrollbar-thumb-rounded-full">
            {Object.keys(adAccounts).map((items, index) => {
              return (
                <div key={`merchant_country_${items}_index_${index}`}>
                  <div className="flex flex-row items-center px-12 pt-12 ">
                    <Icon
                      svg={getCountryFlagIcon(items)}
                      size={IconSize.Medium}
                      className="mr-16"
                    />
                    <div className="flex flex-row items-center flex-grow justify-between">
                      <div className="capitalize text-grey-900 font-medium text-base">
                        {items}
                      </div>
                    </div>
                  </div>
                  <div className="p-12">
                    {adAccounts[items].map((item: any) => (
                      <div
                        className="flex flex-row flex-grow-1 justify-between"
                        key={`merchant_country_${items}_mcid_${item.id}`}
                      >
                        <div className="text-grey-900 text-base my-8">
                          <RadioButton
                            label={item.extMerchantId}
                            disabled={false}
                            onChange={() => setProfileIdSelected(item.id)}
                            selected={item.id === profileIdSelected}
                            labelClassname="ml-12 text-base leading-tight font-normal text-grey-900"
                            dataTestId={`amazon_select_ads_profile_1p_${item.extMerchantId}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  {index < Object.keys(adAccounts).length - 1 && (
                    <hr className="text-grey-200 mb-8 mt-14 " />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
