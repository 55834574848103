import capitalize from 'lodash/capitalize';
import React from 'react';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { AdGroupDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADGROUPS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<AdGroupDetails> = (adGroupDetails) => {
  return makeTextColumn((campaign: AdGroupDetails) =>
    campaign.adGroupDetails.campaignTargetingType
      ? capitalize(campaign.adGroupDetails.campaignTargetingType)
      : undefined
  )(adGroupDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignTargetingTypeColumn: FlywheelTableColumn<AdGroupDetails> =
  {
    columnName: ADGROUPS_API_COLUMN_NAME.CampaignTargetingType,
    i18nKeyOrLabel:
      I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE,
    isSortable: true,
    RowCellElement,
    gridColumnWidth: '140px',
  };

export const campaignTargetingTypeFilter = createMultiSelectDataFieldFilter(
  ADGROUPS_API_COLUMN_NAME.CampaignTargetingType,
  I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE,
  [
    SelectFilterOption.CampaignTargetingTypeAuto,
    SelectFilterOption.CampaignTargetingTypeManual,
  ]
);
