import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  AlertTriangleIcon,
  LightningIcon,
  Modal,
} from '@teikametrics/tm-design-system';

import {
  MerchantCountriesContext,
  MerchantCountriesContextState,
} from '../../containers/merchantCountriesProvider/merchantCountriesProvider';
import { COMMON_ONLY_DATE_FORMAT } from '../../lib/types/CommonSharedTypes';
import { FlywheelSalesChannel } from '../../lib/types/Fam';
import I18nKey from '../../lib/types/I18nKey';
import { ShadowyAlertBox } from '../../modules/account/components/disableAutomationModal';
import { ConnectedMerchants } from './connectedMerchants';
import { Footer } from './footer';
import { LearnMore } from './learnMore';

export enum SwitchPlanMode {
  Upgrade = 'Upgrade',
  Downgrade = 'Downgrade',
}

export interface SwitchPlanModalProps {
  readonly show: boolean;
  readonly salesChannel: FlywheelSalesChannel;
  readonly mode?: SwitchPlanMode;
  readonly onCancelClick: () => void;
  readonly onContinueClick: () => Promise<void>;
}

export const SwitchPlanModal: React.FC<SwitchPlanModalProps> = ({
  show,
  salesChannel,
  mode = SwitchPlanMode.Upgrade,
  onCancelClick,
  onContinueClick,
}) => {
  const intl = useIntl();
  const merchantCountriesContext = useContext<MerchantCountriesContextState>(
    MerchantCountriesContext
  );
  const merchantCountries = merchantCountriesContext.merchantCountries!;

  const [
    UPGRADE_TITLE,
    DOWNGRADE_TITLE,
    AI_POWERED_PLAN,
    GENERIC_TRIAL,
    FREE_TRIAL_STARTED,
    UPGRADE_BODY,
    DOWNGRADE_BODY,
    NOTE,
  ] = [
    I18nKey.PLAN_SWITCH_MODAL_UPGRADE_TITLE,
    I18nKey.PLAN_SWITCH_MODAL_DOWNGRADE_TITLE,
    I18nKey.PLAN_SWITCH_MODAL_AI_POWERED_PLAN,
    I18nKey.GENERIC_TRIAL,
    I18nKey.PLAN_SWITCH_MODAL_FREE_TRIAL_STARTED,
    I18nKey.PLAN_SWITCH_MODAL_UPGRADE_BODY,
    I18nKey.PLAN_SWITCH_MODAL_DOWNGRADE_BODY,
    I18nKey.BILLING_DOWNGRADE_PLAN_NOTE,
  ].map((id) =>
    intl.formatMessage(
      { id },
      { date: DateTime.now().toFormat(COMMON_ONLY_DATE_FORMAT) }
    )
  );

  return (
    <Modal
      showModal={show}
      className="w-500 max-w-500"
      dataTestId="switch_plan_modal"
    >
      <div className="flex flex-col items-center gap-y-16">
        <div
          className={`flex items-center justify-center w-56 h-56
            rounded-full bg-gradient-to-r from-magenta-500 to-orange-500`}
        >
          {mode === SwitchPlanMode.Upgrade ? (
            <LightningIcon className="fill-current h-32 w-32 text-white" />
          ) : (
            <AlertTriangleIcon className="fill-current h-32 w-32 text-white" />
          )}
        </div>
        <div className="text-grey-900 font-semibold text-xl leading-tight text-center whitespace-pre-line">
          {mode === SwitchPlanMode.Upgrade ? UPGRADE_TITLE : DOWNGRADE_TITLE}
        </div>
        {mode === SwitchPlanMode.Downgrade && (
          <ShadowyAlertBox shouldCenterIcon={true}>{NOTE}</ShadowyAlertBox>
        )}
        {mode === SwitchPlanMode.Upgrade && (
          <div className="flex flex-row justify-around w-full items-center pt-16">
            <div className="text-grey-900 font-semibold text-lg leading-none">
              {AI_POWERED_PLAN}
              <span
                className={`inline-flex items-center rounded-full h-20 ml-8 px-8
                text-sm leading-none text-white font-medium
                whitespace-nowrap bg-rainbow-gradient`}
              >
                {GENERIC_TRIAL}
              </span>
            </div>
            {
              <div className="text-purple-500 font-normal text-base leading-none">
                {FREE_TRIAL_STARTED}
              </div>
            }
          </div>
        )}
        <div
          className={`text-grey-600 font-normal text-base leading-tight text-center pt-8 whitespace-pre-line`}
        >
          {mode === SwitchPlanMode.Upgrade ? UPGRADE_BODY : DOWNGRADE_BODY}
        </div>
        <LearnMore />
        <ConnectedMerchants
          salesChannel={salesChannel}
          mcList={merchantCountries}
        />
        <Footer
          onCancelClick={onCancelClick}
          onContinueClick={onContinueClick}
        />
      </div>
    </Modal>
  );
};
