import { DataFieldFilter } from '@teikametrics/tm-design-system';
import isNil from 'lodash/isNil';
import {
  AdLevel,
  AdType,
  FlywheelSalesChannel,
  Groups,
} from '../../../../../lib/types/AOSharedTypes';
import { MerchantCountry, MerchantType } from '../../../../../lib/types/Fam';
import { acosDirectFilter as acosDirectFilterAdItem } from '../adItemsTableColumns/acosDirect';
import { acosTotalFilter as acosTotalFilterAdItem } from '../adItemsTableColumns/acosTotal';
import { adConversionRateFilter as adConversionRateFilterAdItem } from '../adItemsTableColumns/adConversionRate';
import { adConversionsFilter as adConversionsFilterAdItem } from '../adItemsTableColumns/adConversions';
import { adGroupNameFilter as adGroupNameFilterAdItem } from '../adItemsTableColumns/adGroupName';
import { adGroupReviewStatusFilter as adGroupReviewStatusFilterAdItem } from '../adItemsTableColumns/adGroupReviewStatus';
import { adGroupStatusFilter as adGroupStatusFilterAdItem } from '../adItemsTableColumns/adGroupStatus';
import { adSalesDirectFilter as adSalesDirectFilterAdItem } from '../adItemsTableColumns/adSalesDirect';
import { adSalesTotalFilter as adSalesTotalFilterAdItem } from '../adItemsTableColumns/adSalesTotal';
import { adSpendFilter as adSpendFilterAdItem } from '../adItemsTableColumns/adSpend';
import { adUnitsSoldFilter as adUnitsSoldFilterAdItem } from '../adItemsTableColumns/adUnitsSold';
import { addedByFilter as addedByFilterAdItems } from '../adItemsTableColumns/addedBy';
import { advertisedSKUFilter as advertisedSKUFilterAdItems } from '../adItemsTableColumns/advertisedSkuUnits';
import { bidFilter as bidFilterAdItem } from '../adItemsTableColumns/bid';
import { campaignCostTypeFilter as campaignCostTypeFilterAdItem } from '../adItemsTableColumns/campaignCostType';
import { campaignNameFilter as campaignNameFilterAdItem } from '../adItemsTableColumns/campaignName';
import { campaignStatusFilter as campaignStatusFilterAdItem } from '../adItemsTableColumns/campaignStatus';
import { campaignTacticFilter as campaignTacticFilterAdItem } from '../adItemsTableColumns/campaignTactic';
import { campaignTargetingTypeFilter as campaignTargetingTypeFilterAdItem } from '../adItemsTableColumns/campaignTargetingType';
import { clickThroughRateFilter as clickThroughRateFilterAdItem } from '../adItemsTableColumns/clickThroughRate';
import { clicksFilter as clicksFilterAdItem } from '../adItemsTableColumns/clicks';
import { costPerClickFilter as costPerClickFilterAdItem } from '../adItemsTableColumns/costPerClick';
import { campaignDailyBudgetFilter as campaignDailyBudgetFilterAdItem } from '../adItemsTableColumns/dailyBudget';
import { impressionsFilter as impressionsFilterAdItem } from '../adItemsTableColumns/impressions';
import { newToBrandAdSalesFilter as newToBrandAdSalesFilterAdItem } from '../adItemsTableColumns/newToBrandAdSales';
import { newToBrandAdUnitsSoldFilter as newToBrandAdUnitsSoldFilterAdItem } from '../adItemsTableColumns/newToBrandAdUnitsSold';
import { newToBrandOrdersFilter as newToBrandOrdersFilterAdItem } from '../adItemsTableColumns/newToBrandOrders';
import { otherSKUUnitsFilter as otherSKUUnitsFilterAdItems } from '../adItemsTableColumns/otherSkuUnits';
import { portfolioNameFilter as portfolioNameFilterAdItem } from '../adItemsTableColumns/portfolioName';
import { productAdStatusFilter as productAdStatusFilterAdItem } from '../adItemsTableColumns/productAdStatus';
import {
  asinFilter,
  extAdItemIdProductAdsFilter,
  productNameFilter as productNameFilterAdItem,
  skuFilter,
} from '../adItemsTableColumns/productName';
import { productReviewStatusFilter as productReviewStatusFilterAdItem } from '../adItemsTableColumns/productReviewStatus';
import { relatedClickRevenueFilter as relatedClickRevenueFilterAdItem } from '../adItemsTableColumns/relatedClickRevenue';
import { roasDirectFilter as roasDirectFilterAdItem } from '../adItemsTableColumns/roasDirect';
import { roasTotalFilter as roasTotalFilterAdItem } from '../adItemsTableColumns/roasTotal';
import { acosFilter as acosFilterAdGroup } from '../adgroupTableColumns/acos';
import { acosDirectFilter as acosDirectFilterAdGroup } from '../adgroupTableColumns/acosDirect';
import { acosLimitFilter } from '../adgroupTableColumns/acosLimit';
import { adConversionsFilter as adConversionsFilterAdGroup } from '../adgroupTableColumns/adConversions';
import { adGroupReviewStatusFilter as adGroupReviewTableStatusFilter } from '../adgroupTableColumns/adGroupReviewStatus';
import { adSalesFilter as adSalesFilterAdGroup } from '../adgroupTableColumns/adSales';
import { adSalesDirectFilter as adSalesDirectFilterAdGroup } from '../adgroupTableColumns/adSalesDirect';
import { adSpendFilter as adSpendFilterAdGroup } from '../adgroupTableColumns/adSpend';
import { addedByFilter as addedByFilterAdGroup } from '../adgroupTableColumns/addedBy';
import { adgroupDefaultBidFilter as adgroupDefaultBidFilterAdGroup } from '../adgroupTableColumns/adgroupDefaultBid';
import { adGroupNameFilter as adGroupNameFilterAdGroup } from '../adgroupTableColumns/adgroupName';
import { adgroupStatusFilter as adgroupStatusFilterAdGroup } from '../adgroupTableColumns/adgroupStatus';
import { advertisedSKUFilter as advertisedSKUFilterAdgroups } from '../adgroupTableColumns/advertisedSkuUnits';
import { aiBiddingFilter } from '../adgroupTableColumns/aiBidding';
import { aiRecommendedKeywordFilter as aiRecommendedKeywordFilterAdGroup } from '../adgroupTableColumns/aiRecommendedKeyword';
import { avgCostPerClickFilter as avgCostPerClickFilterAdGroup } from '../adgroupTableColumns/avgCostPerClick';
import { bidAutomationStatusFilter as bidAutomationStatusFilterAdGroup } from '../adgroupTableColumns/bidAutomationStatus';
import { bidModifierFilter } from '../adgroupTableColumns/bidModifier';
import { campaignCostTypeFilter as campaignCostTypeFilterAdGroup } from '../adgroupTableColumns/campaignCostType';
import { campaignDailyBudgetFilter as campaignDailyBudgetFilterAdGroup } from '../adgroupTableColumns/campaignDailyBudget';
import { campaignNameFilter as campaignNameFilterAdGroup } from '../adgroupTableColumns/campaignName';
import { campaignStatusFilter as campaignStatusFilterAdGroup } from '../adgroupTableColumns/campaignStatus';
import { campaignTacticFilter as campaignTacticFilterAdGroup } from '../adgroupTableColumns/campaignTactic';
import { campaignTargetingTypeFilter as campaignTargetingTypeFilterAdGroup } from '../adgroupTableColumns/campaignTargetingType';
import { clickThroughRateFilter as clickThroughRateFilterAdGroup } from '../adgroupTableColumns/clickThroughRate';
import { clicksFilter as clicksFilterAdGroup } from '../adgroupTableColumns/clicks';
import { conversionRateFilter as conversionRateFilterAdGroup } from '../adgroupTableColumns/conversionRate';
import { createdAtFilter as createdAtFilterAdGroup } from '../adgroupTableColumns/createdAt';
import { estimatedPreAdGrossMarginFilter } from '../adgroupTableColumns/estimatedPreAdGrossMargin';
import { groupFilter as groupFilterAdGroup } from '../adgroupTableColumns/group';
import { impressionsFilter as impressionsFilterAdGroup } from '../adgroupTableColumns/impressions';
import { labelsFilter as labelsFilterAdGroup } from '../adgroupTableColumns/labels';
import { macsTargetFilter as macsTargetFilterAdGroup } from '../adgroupTableColumns/macsTarget';
import { maxBidFilter as maxBidFilterAdGroup } from '../adgroupTableColumns/maxBid';
import { minBidFilter as minBidFilterAdGroup } from '../adgroupTableColumns/minBid';
import { newToBrandAdConversionsFilter as newToBrandAdConversionsFilterAdGroup } from '../adgroupTableColumns/newToBrandAdOrders';
import { newToBrandAdSalesFilter as newToBrandAdSalesFilterAdGroup } from '../adgroupTableColumns/newToBrandAdSales';
import { newToBrandAdUnitsSoldFilter as newToBrandAdUnitsSoldFilterAdGroup } from '../adgroupTableColumns/newToBrandAdUnitsSold';
import { otherSKUUnitsFilter as otherSKUUnitsFilterAdgroups } from '../adgroupTableColumns/otherSkuUnits';
import { portfolioNameFilter as portfolioNameFilterAdGroup } from '../adgroupTableColumns/portfolioName';
import { relatedClickRevenueFilter as relatedClickRevenueFilterAdGroup } from '../adgroupTableColumns/relatedClickRevenue';
import { roasFilter as roasFilterAdGroup } from '../adgroupTableColumns/roas';
import { roasDirectFilter as roasDirectFilterAdGroup } from '../adgroupTableColumns/roasDirect';
import { totalBudgetFilter as adGroupTotalBudgetFilter } from '../adgroupTableColumns/totalBudget';
import { unitsSoldFilter as unitsSoldFilterAdGroup } from '../adgroupTableColumns/unitsSold';
import { acosFilter as acosFilterCampaign } from '../campaignsTableColumns/acos';
import { acosDirectFilter as acosDirectFilterCampaign } from '../campaignsTableColumns/acosDirect';
import { adConversionsFilter as adConversionsFilterCampaign } from '../campaignsTableColumns/adConversions';
import { adSalesFilter as adSalesFilterCampaign } from '../campaignsTableColumns/adSales';
import { adSalesDirectFilter as adSalesDirectFilterCampaign } from '../campaignsTableColumns/adSalesDirect';
import { adSpendFilter as adSpendFilterCampaign } from '../campaignsTableColumns/adSpend';
import { addedByFilter as addedByFilterCampaign } from '../campaignsTableColumns/addedBy';
import { advertisedSKUFilter as advertisedSKUFilterCampaigns } from '../campaignsTableColumns/advertisedSkuUnits';
import { advertisingGoalFilter } from '../campaignsTableColumns/advertisingGoal';
import { amazonBidOptimizationFilter as amazonBidOptimizationFilterCampaign } from '../campaignsTableColumns/amazonBidOptimization';
import { amazonBiddingStrategyFilter as amazonBiddingStrategyFilterCampaign } from '../campaignsTableColumns/amazonBiddingStrategy';
import { appBidMultiplierColumnFilterCampaign } from '../campaignsTableColumns/appBidMultiplier';
import { avgCostPerClickFilter as avgCostPerClickFilterCampaign } from '../campaignsTableColumns/avgCostPerClick';
import { belowTopOfTheSearchBidMultiplierFilter as belowTopOfTheSearchBidMultiplierFilterCampaign } from '../campaignsTableColumns/belowTopOfTheSearchBidMultiplier';
import { budgetRolloverFilter as budgetRolloverFilterCampaign } from '../campaignsTableColumns/budgetRollover';
import { budgetTypeFilter as budgetTypeFilterCampaign } from '../campaignsTableColumns/budgetType';
import { buyBoxBidMultiplierFilter as buyBoxBidMultiplierFilterCampaign } from '../campaignsTableColumns/buyBoxBidMultiplier';
import { campaignCostTypeFilter as campaignCostTypeFilterCampaign } from '../campaignsTableColumns/campaignCostType';
import { campaignNameFilter as campaignNameFilterCampaign } from '../campaignsTableColumns/campaignName';
import { campaignServingStatusFilter as campaignServingStatusFilterCampaign } from '../campaignsTableColumns/campaignServingStatus';
import { campaignStatusFilter as campaignStatusFilterCampaign } from '../campaignsTableColumns/campaignStatus';
import { campaignTacticFilter as campaignTacticFilterCampaign } from '../campaignsTableColumns/campaignTactic';
import { campaignTargetingTypeFilter as campaignTargetingTypeFilterCampaign } from '../campaignsTableColumns/campaignTargetingType';
import { clickThroughRateFilter as clickThroughRateFilterCampaign } from '../campaignsTableColumns/clickThroughRate';
import { clicksFilter as clicksFilterCampaign } from '../campaignsTableColumns/clicks';
import { conversionRateFilter as conversionRateFilterCampaign } from '../campaignsTableColumns/conversionRate';
import { createdAtFilter } from '../campaignsTableColumns/createdAt';
import { dailyBudgetFilter as dailyBudgetFilterCampaign } from '../campaignsTableColumns/dailyBudget';
import { desktopBidMultiplierFilter as desktopBidMultiplierFilterCampaign } from '../campaignsTableColumns/desktopBidMultiplier';
import { endDateFilter as endDateFilterCampaign } from '../campaignsTableColumns/endDate';
import { groupFilter as groupFilterCampaign } from '../campaignsTableColumns/group';
import { homePageBidMultiplierFilter as homePageBidMultiplierFilterCampaign } from '../campaignsTableColumns/homePageBidMultiplier';
import { impressionsFilter as impressionsFilterCampaign } from '../campaignsTableColumns/impressions';
import { itemBuyBoxPlacementFilterCampaign } from '../campaignsTableColumns/itemBuyBoxPlacement';
import { itemCarouselPlacementFilterCampaign } from '../campaignsTableColumns/itemCarouselPlacement';
import { mobileBidMultiplierFilter as mobileBidMultiplierFilterCampaign } from '../campaignsTableColumns/mobileBidMultiplier';
import { newToBrandAdUnitsSoldFilter as newToBrandAdUnitsSoldFilterCampaign } from '../campaignsTableColumns/newToBrandAdUnitsSold';
import { newToBrandOrdersFilter as newToBrandOrdersFilterCampaign } from '../campaignsTableColumns/newToBrandOrders';
import { newToBrandOrdersRateFilter as newToBrandOrdersRateFilterCampaign } from '../campaignsTableColumns/newToBrandOrdersRate';
import { newToBrandAdSalesFilter as newToBrandAdSalesFilterCampaign } from '../campaignsTableColumns/newToBrandSales';
import { otherSKUUnitsFilter as otherSKUUnitsFilterCampaigns } from '../campaignsTableColumns/otherSkuUnits';
import { percentageOfAdUnitsSoldNewToBrandFilter as percentageOfAdUnitsSoldNewToBrandFilterCampaign } from '../campaignsTableColumns/percentageOfAdUnitsSoldNewToBrand';
import { percentageOfOrdersNewToBrandFilter as percentageOfOrdersNewToBrandFilterCampaign } from '../campaignsTableColumns/percentageOfOrdersNewToBrand';
import { percentageOfSalesNewToBrandFilter as percentageOfSalesNewToBrandFilterCampaign } from '../campaignsTableColumns/percentageOfSalesNewToBrand';
import { portfolioNameFilter as portfolioNameFilterCampaign } from '../campaignsTableColumns/portfolioName';
import { productPageBidMultiplierFilter as productPageBidMultiplierFilterCampaign } from '../campaignsTableColumns/productPageBidMultiplier';
import { relatedClickRevenueFilter as relatedClickRevenueFilterCampaign } from '../campaignsTableColumns/relatedClickRevenue';
import { roasFilter as roasFilterCampaign } from '../campaignsTableColumns/roas';
import { roasDirectFilter as roasDirectFilterCampaign } from '../campaignsTableColumns/roasDirect';
import { searchCarouselInclusionFilter as searchCarouselInclusionFilterCampaign } from '../campaignsTableColumns/searchCarouselInclusion';
import { searchIngridBidMultiplierFilter as searchIngridBidMultiplierFilterCampaign } from '../campaignsTableColumns/searchIngridBidMultiplier';
import { smartStructureFilter } from '../campaignsTableColumns/smartStructure';
import { startDateFilter as startDateFilterCampaign } from '../campaignsTableColumns/startDate';
import { stockUpBidMultiplierFilter as stockUpBidMultiplierFilterCampaign } from '../campaignsTableColumns/stockUpBidMultiplier';
import { topOfSearchBidMultiplierFilter as topOfSearchBidMultiplierFilterCampaign } from '../campaignsTableColumns/topOfSearchBidMultiplier';
import {
  totalBudgetFilter,
  totalBudgetFilter as totalBudgetFilterCampaign,
} from '../campaignsTableColumns/totalBudget';
import { unitsSoldFilter as unitsSoldFilterCampaign } from '../campaignsTableColumns/unitsSold';
import { acosFilterProfile } from '../profilesTableColumns/acos';
import { acosDirectFilterForProfiles } from '../profilesTableColumns/acosDirect';
import { adConversionsRateFilterForProfile } from '../profilesTableColumns/adConversionsRate';
import { adGroupNameFilterProfile } from '../profilesTableColumns/adGroupName';
import { adGroupReviewStatusFilterProfile } from '../profilesTableColumns/adGroupReviewStatus';
import { adSalesFilterProfile } from '../profilesTableColumns/adSales';
import { adSalesDirectFilterForProfiles } from '../profilesTableColumns/adSalesDirect';
import { adSpendFilterProfile } from '../profilesTableColumns/adSpend';
import { adUnitsSoldFilterForProfiles } from '../profilesTableColumns/adUnitsSold';
import { adgroupStatusFilterProfile } from '../profilesTableColumns/adgroupStatus';
import { advertisedSKUFilter as advertisedSKUFilterProfiles } from '../profilesTableColumns/advertisedSkuUnits';
import { avgCostPerClickFilterForProfiles } from '../profilesTableColumns/avgCostPerClick';
import { profileBrandFilter } from '../profilesTableColumns/brand';
import { campaignDailyBudgetFilterForProfiles } from '../profilesTableColumns/campaignDailyBudget';
import { profileCampaignNameFilter } from '../profilesTableColumns/campaignName';
import { campaignStatusFilterForProfiles } from '../profilesTableColumns/campaignStatus';
import { campaignTargetingTypeFilterForProfiles } from '../profilesTableColumns/campaignTargetingType';
import { campaignTotalBudgetFilterForProfile } from '../profilesTableColumns/campaignTotalBudget';
import { clickThroughRateFilterForProfiles } from '../profilesTableColumns/clickThroughRate';
import { clickUrlFilterForProfile } from '../profilesTableColumns/clickUrl';
import { clicksFilterForProfiles } from '../profilesTableColumns/clicks';
import { headlineFilterForProfiles } from '../profilesTableColumns/headline';
import { impressionsFilterForProfiles } from '../profilesTableColumns/impressions';
import { logoFilterForProfile } from '../profilesTableColumns/logo';
import { otherSKUUnitsFilter as otherSKUUnitsFilterProfiles } from '../profilesTableColumns/otherSkuUnits';
import { profileProfileReviewStatusFilter } from '../profilesTableColumns/profileReviewStatus';
import { profileStatusFilter } from '../profilesTableColumns/profileStatus';
import { relatedClickRevenueFilterForProfiles } from '../profilesTableColumns/relatedClickRevenue';
import { roasFilterProfiles } from '../profilesTableColumns/roas';
import { roasDirectFilterForProfiles } from '../profilesTableColumns/roasDirect';
import { acosFilter as acosFilterForSbAds } from '../sbAdsTableColumns/acos';
import { adConversionsFilter as adConversionsFilterForSbAds } from '../sbAdsTableColumns/adConversions';
import { adConversionsRateFilterForSbAds } from '../sbAdsTableColumns/adConversionsRate';
import { adGroupNameFilter as adGroupNameFilterForSbAds } from '../sbAdsTableColumns/adGroupName';
import { adGroupStatusFilter as adGroupStatusFilterForSbAds } from '../sbAdsTableColumns/adGroupStatus';
import { adSalesFilter as adSalesFilterForSbAds } from '../sbAdsTableColumns/adSales';
import { adSpendFilter as adSpendFilterForSbAds } from '../sbAdsTableColumns/adSpend';
import { adStatusFilter as adStatusFilterForSbAds } from '../sbAdsTableColumns/adStatus';
import { averageCostPerClickFilter as averageCostPerClickFilterForSbAds } from '../sbAdsTableColumns/averageCostPerClick';
import { campaignDailyBudgetFilterForSbAds } from '../sbAdsTableColumns/campaignDailyBudget';
import { campaignNameFilter as campaignNameFilterForSbAds } from '../sbAdsTableColumns/campaignName';
import { campaignStatusFilter as campaignStatusFilterForSbAds } from '../sbAdsTableColumns/campaignStatus';
import { clickThroughRateFilter as clickThroughRateFilterForSbAds } from '../sbAdsTableColumns/clickThroughRate';
import { clicksFilter as clicksFilterForSbAds } from '../sbAdsTableColumns/clicks';
import { creativeNameFilter } from '../sbAdsTableColumns/creativeName';
import { servingStatusFilter } from '../sbAdsTableColumns/creativeServingStatus';
import { creativeTypeFilter } from '../sbAdsTableColumns/creativeType';
import { impressionsFilter as impressionsFilterForSbAds } from '../sbAdsTableColumns/impressions';
import { newToBrandAdSalesFilter as newToBrandAdSalesFilterForSbAds } from '../sbAdsTableColumns/newToBrandAdSales';
import { newToBrandAdUnitsSoldFilter as newToBrandAdUnitsSoldFilterForSbAds } from '../sbAdsTableColumns/newToBrandAdUnitsSold';
import { newToBrandOrdersFilter as newToBrandOrdersFilterForSbAds } from '../sbAdsTableColumns/newToBrandOrders';
import { newToBrandOrdersRateFilter as newToBrandOrdersRateFilterForSbAds } from '../sbAdsTableColumns/newToBrandOrdersRate';
import { percentageOfAdUnitsSoldNewToBrandFilter as percentageOfAdUnitsSoldNewToBrandFilterForSbAds } from '../sbAdsTableColumns/percentageOfAdUnitsSoldNewToBrand';
import { percentageOfOrdersNewToBrandFilter as percentageOfOrdersNewToBrandFilterForSbAds } from '../sbAdsTableColumns/percentageOfOrdersNewToBrand';
import { percentageOfSalesNewToBrandFilter as percentageOfSalesNewToBrandFilterForSbAds } from '../sbAdsTableColumns/percentageOfSalesNewToBrand';
import { portfolioNameFilter as portfolioNameFilterForSbAds } from '../sbAdsTableColumns/portfolioName';
import { roasFilter as roasFilterForSbAds } from '../sbAdsTableColumns/roas';
import { unitsSoldFilter as unitsSoldFilterForSbAds } from '../sbAdsTableColumns/unitsSold';
import { adConversionRateFilter as adConversionRateFilterTarget } from '../targetsTableColumns/adConversionRate';
import { adConversionsFilter as adConversionsFilterTarget } from '../targetsTableColumns/adConversions';
import { adGroupNameFilter as adGroupNameFilterTarget } from '../targetsTableColumns/adGroupName';
import { adGroupReviewStatusFilter as adGroupReviewStatusFilterTarget } from '../targetsTableColumns/adGroupReviewStatus';
import { adGroupStatusFilter as adGroupStatusFilterTarget } from '../targetsTableColumns/adGroupStatus';
import { adSpendFilter as adSpendFilterTarget } from '../targetsTableColumns/adSpend';
import { addedByFilter } from '../targetsTableColumns/addedBy';
import { advertisedSKUFilter as advertisedSKUFilterTargets } from '../targetsTableColumns/advertisedSkuUnits';
import { averageCostPerClickFilter as averageCostPerClickFilterTarget } from '../targetsTableColumns/averageCostPerClick';
import { bidFilter as bidFilterTarget } from '../targetsTableColumns/bidColumn';
import { campaignCostTypeFilter as campaignCostTypeFilterTarget } from '../targetsTableColumns/campaignCostType';
import { campaignNameFilter as campaignNameFilterTarget } from '../targetsTableColumns/campaignName';
import { campaignStatusFilter as campaignStatusFilterTarget } from '../targetsTableColumns/campaignStatus';
import { campaignTacticFilter as campaignTacticFitlerTarget } from '../targetsTableColumns/campaignTactic';
import { campaignTargetingTypeFilter as campaignTargetingTypeFilterTarget } from '../targetsTableColumns/campaignTargetingType';
import { clickThroughRateFilter as clickThroughRateFilterTarget } from '../targetsTableColumns/clickThroughRate';
import { clicksFilter as clicksFilterTarget } from '../targetsTableColumns/clicks';
import { dailyBudgetFilter as dailyBudgetFilterTarget } from '../targetsTableColumns/dailyBudget';
import { dateAddedFilter as dateAddedFilterTarget } from '../targetsTableColumns/dateAdded';
import { directAcosFilter as directAcosFilterTarget } from '../targetsTableColumns/directAcos';
import {
  directAdSalesFilter,
  directAdSalesFilter as directAdSalesFilterTarget,
} from '../targetsTableColumns/directAdSales';
import { directRoasFilter as directRoasFilterTarget } from '../targetsTableColumns/directRoas';
import { impressionsFilter as impressionsFilterTarget } from '../targetsTableColumns/impressions';
import { keywordFilter as keywordFitlerTarget } from '../targetsTableColumns/keyword';
import { keywordReviewStatusFilter as keywordReviewStatusFilterTarget } from '../targetsTableColumns/keywordReviewStatus';
import { matchTypeFilter as matchTypeFilterTarget } from '../targetsTableColumns/matchType';
import { newToBrandAdUnitsSoldFilter as newToBrandAdUnitsSoldFilterTarget } from '../targetsTableColumns/newToBrandAdUnitsSold';
import { newToBrandOrdersFilter as newToBrandOrdersFilterTarget } from '../targetsTableColumns/newToBrandOrders';
import { newToBrandOrdersRateFilter as newToBrandOrdersRateFilterTarget } from '../targetsTableColumns/newToBrandOrdersRate';
import { newToBrandSalesFilter as newToBrandSalesFilterTarget } from '../targetsTableColumns/newToBrandSales';
import { otherSKUUnitsFilter as otherSKUUnitsFilterTargets } from '../targetsTableColumns/otherSkuUnits';
import { percentageOfAdUnitsSoldNewToBrandFilter as percentageOfAdUnitsSoldNewToBrandFilterTarget } from '../targetsTableColumns/percentageOfAdUnitsSoldNewToBrand';
import { percentageOfOrdersNewToBrandFilter as percentageOfOrdersNewToBrandFilterTarget } from '../targetsTableColumns/percentageOfOrdersNewToBrand';
import { percentageOfSalesNewToBrandFilter as percentageOfSalesNewToBrandFilterTarget } from '../targetsTableColumns/percentageOfSalesNewToBrand';
import { portfolioNameFilter as portfolioNameFilterTarget } from '../targetsTableColumns/portfolioName';
import { relatedClickRevenueFilter as relatedClickRevenueFilterTarget } from '../targetsTableColumns/relatedClickRevenue';
import { getTagsFilter } from '../targetsTableColumns/tags';
import { getTargetLabelsFilter } from '../targetsTableColumns/targetLabels';
import { getTargetStatusFilter } from '../targetsTableColumns/targetStatus';
import { targetTextFilter as targetTextFilterTarget } from '../targetsTableColumns/targetText';
import { getTargetTypeFilter } from '../targetsTableColumns/targetType';
import { totalAcosFilter as totalAcosFilterTarget } from '../targetsTableColumns/totalAcos';
import { totalAdSalesFilter as totalAdSalesFilterTarget } from '../targetsTableColumns/totalAdSales';
import { totalBudgetFilter as totalBudgetFilterTarget } from '../targetsTableColumns/totalBudget';
import { totalRoasFilter as totalRoasFilterTarget } from '../targetsTableColumns/totalRoas';
import {
  unitsSoldFilter as adUnitsSoldFilterTarget,
  unitsSoldFilter as unitsSoldFilterTarget,
} from '../targetsTableColumns/unitsSold';
import { isMerchantTypeSeller } from '.';

import { ordersFilter as adgroupordersFilter } from '../adgroupTableColumns/orders';

import { completeViewOrdersFilter as completeViewOrdersFilterForCampaigns } from '../campaignsTableColumns/completeViewOrders';
import { fiveSecondViewsFilter as fiveSecondViewsFilterForCampaigns } from '../campaignsTableColumns/fiveSecondViews';
import { ordersFilter as ordersFilterForCampaigns } from '../campaignsTableColumns/orders';
import { videoCompleteViewsFilter as videoCompleteViewsFilterForCampaigns } from '../campaignsTableColumns/videoCompleteViews';
import { videoFirstQuartileViewsFilter as videoFirstQuartileViewsFilterForCampaigns } from '../campaignsTableColumns/videoFirstQuartileViews';
import { videoMidpointViewsFilter as videoMidpointViewsFilterForCampaigns } from '../campaignsTableColumns/videoMidpointViews';
import { videoThirdQuartileViewsFilter as videoThirdQuartileViewsFilterForCampaigns } from '../campaignsTableColumns/videoThirdQuartileViews';
import { videoUnmutesFilter as videoUnmutesFilterForCampaigns } from '../campaignsTableColumns/videoUnmutes';
import { viewThroughOrdersFilter as viewThroughOrdersFilterForCampaigns } from '../campaignsTableColumns/viewThroughOrders';
import { viewThroughUnitsSoldFilter as viewThroughUnitsSoldFilterForCampaigns } from '../campaignsTableColumns/viewThroughUnitsSold';
import { viewableImpressionsFilter as viewableImpressionsFilterForCampaigns } from '../campaignsTableColumns/viewableImpressions';
import { acosTotalFilter as acosTotalFilterForSvAds } from '../svAdsColumns/acos';
import { adGroupNameFilter as adGroupNameFilterForSvAds } from '../svAdsColumns/adGroupName';
import { adGroupReviewStatusFilter as adGroupReviewStatusFilterForSvAds } from '../svAdsColumns/adGroupReviewStatus';
import { adSpendFilter as adSpendFilterForSvAds } from '../svAdsColumns/adSpend';
import { adStatusFilter as adStatusFilterForSvAds } from '../svAdsColumns/adStatus';
import { adGroupStatusFilter as adGroupStatusFilterForSvAds } from '../svAdsColumns/adgroupStatus';
import { advertisedSKUFilter as advertisedSKUFilterForSvAds } from '../svAdsColumns/advertisedSkuUnits';
import { averageCostPerClickFilter as averageCostPerClickFilterForSvAds } from '../svAdsColumns/averageCostPerClick';
import { campaignDailyBudgetFilterForSvAds } from '../svAdsColumns/campaignDailyBudget';
import { campaignLifetimeBudgetFilterForSvAds } from '../svAdsColumns/campaignLifetimeBudget';
import { campaignNameFilter as campaignNameFilterForSvAds } from '../svAdsColumns/campaignName';
import { campaignStatusFilter as campaignStatusFilterForSvAds } from '../svAdsColumns/campaignStatus';
import { campaignTargetingTypeFilter } from '../svAdsColumns/campaignTargetingType';
import { clickThroughRateFilter as clickThroughRateFilterForSvAds } from '../svAdsColumns/clickThroughRate';
import { clicksFilter as clicksFilterForSvAds } from '../svAdsColumns/clicks';
import { conversionRateFilter as conversionRateFilterForSvAds } from '../svAdsColumns/conversionRate';
import { directAcosFilter as directAcosFilterForSvAds } from '../svAdsColumns/directAcos';
import { directAdSalesFilter as directAdSalesFilterForSVAds } from '../svAdsColumns/directAdSales';
import { directRoasFilter as directRoasFilterForSvAds } from '../svAdsColumns/directRoas';
import { impressionsFilter as impressionsFilterForSvAds } from '../svAdsColumns/impressions';
import { nameFilter as nameFilterForSvAds } from '../svAdsColumns/name';
import { ordersFilter as ordersFilterForSvAds } from '../svAdsColumns/orders';
import { relatedClickRevenueFilter as relatedClickRevenueFilterForSvAds } from '../svAdsColumns/relatedClickRevenue';
import { roasFilter as roasFilterForSvAds } from '../svAdsColumns/roas';
import { otherSKUUnitsFilter as otherSKUUnitsFilterForSvAds } from '../svAdsColumns/svAdsOtherSkuUnits';
import { totalAttributedSalesFilter as totalAttributedSalesFilterForSvAds } from '../svAdsColumns/totalAttirbutedSales';
import { unitsSoldFilter as unitsSoldFilterForSvAds } from '../svAdsColumns/unitsSold';
import { viewableImpressionsFilter as viewableImpressionsFilterForSvAds } from '../svAdsColumns/viewableImpressions';
import { ordersFilter as targetsordersFilter } from '../targetsTableColumns/orders';

type Filters = Partial<OptionalSalesChannels>;

type OptionalSalesChannels = {
  readonly [salesChannel in FlywheelSalesChannel]: Partial<OptionalAdTypes>;
};

type OptionalAdTypes = {
  readonly [adType in AdType]: Partial<OptionalAdLevels>;
};

type OptionalAdLevels = {
  readonly [adLevel in AdLevel]: DataFieldFilter[];
};

const getTargetTypeFilters = (
  isManageTreAutomationEnabled: boolean | undefined,
  adType: AdType,
  adLevel: AdLevel
) =>
  isManageTreAutomationEnabled ? [getTargetTypeFilter(adType, adLevel)] : [];

const getSPKeywordTargetsColumns = (
  currency: string,
  salesChannel: FlywheelSalesChannel,
  adType: AdType,
  adLevel: AdLevel,
  isTargetSummaryEnabled?: boolean,
  campaignType?: string,
  isAiEnabled?: boolean,
  hasCampaignNegativeTargets?: boolean,
  isManageTreAutomationEnabled?: boolean
): DataFieldFilter[] => {
  const filters: DataFieldFilter[] = [
    keywordFitlerTarget,
    matchTypeFilterTarget(salesChannel, campaignType),
    ...(isAiEnabled && isManageTreAutomationEnabled
      ? [addedByFilter(adType, adLevel)]
      : []),
    isManageTreAutomationEnabled
      ? getTagsFilter(salesChannel, adType, AdLevel.KeywordTargets, isAiEnabled)
      : getTargetLabelsFilter(
          salesChannel,
          adType,
          AdLevel.KeywordTargets,
          isAiEnabled
        ),
    ...getTargetTypeFilters(isManageTreAutomationEnabled, adType, adLevel),
    ...(!hasCampaignNegativeTargets
      ? [
          adGroupNameFilterTarget,
          adGroupStatusFilterTarget(salesChannel, adType),
        ]
      : []),
    campaignNameFilterTarget,
    campaignTargetingTypeFilterTarget,
    dailyBudgetFilterTarget(currency),
    campaignStatusFilterTarget(salesChannel, adType),
    portfolioNameFilterTarget,
    getTargetStatusFilter(salesChannel),
    bidFilterTarget(currency),
    totalAdSalesFilterTarget(currency),
    adSpendFilterTarget(currency),
    totalAcosFilterTarget,
    totalRoasFilterTarget,
    adConversionsFilterTarget,
    adConversionRateFilterTarget,
    adUnitsSoldFilterTarget,
    impressionsFilterTarget,
    clicksFilterTarget,
    averageCostPerClickFilterTarget(currency),
    clickThroughRateFilterTarget,
  ];

  if (isTargetSummaryEnabled) {
    // insert dateAddedFilterTarget after matchTypeFilterTarget
    filters.splice(
      isManageTreAutomationEnabled ? 4 : 3,
      0,
      dateAddedFilterTarget
    );
    return filters;
  }

  return filters;
};

const getSbKeywordTargetsColumns = (
  currency: string,
  salesChannel: FlywheelSalesChannel,
  adType: AdType,
  adLevel: AdLevel,
  isTargetSummaryEnabled?: boolean,
  campaignType?: string,
  isAiEnabled?: boolean,
  isManageTreAutomationEnabled?: boolean
): DataFieldFilter[] => {
  return [
    keywordFitlerTarget,
    matchTypeFilterTarget(salesChannel, campaignType),
    ...(isManageTreAutomationEnabled
      ? []
      : [
          getTargetLabelsFilter(
            salesChannel,
            adType,
            AdLevel.ProductTargets,
            isAiEnabled
          ),
        ]),
    ...(isAiEnabled && isManageTreAutomationEnabled
      ? [addedByFilter(adType, adLevel)]
      : []),
    ...getTargetTypeFilters(isManageTreAutomationEnabled, adType, adLevel),
    adGroupNameFilterTarget,
    adGroupStatusFilterTarget(salesChannel, adType),
    campaignNameFilterTarget,
    dailyBudgetFilterTarget(currency),
    totalBudgetFilterTarget(currency),
    campaignStatusFilterTarget(salesChannel, adType),
    portfolioNameFilterTarget,
    getTargetStatusFilter(salesChannel),
    bidFilterTarget(currency),
    totalAdSalesFilterTarget(currency),
    adSpendFilterTarget(currency),
    totalAcosFilterTarget,
    totalRoasFilterTarget,
    adConversionsFilterTarget,
    adConversionRateFilterTarget,
    adUnitsSoldFilterTarget,
    impressionsFilterTarget,
    clicksFilterTarget,
    averageCostPerClickFilterTarget(currency),
    clickThroughRateFilterTarget,
    newToBrandOrdersFilterTarget,
    percentageOfOrdersNewToBrandFilterTarget,
    newToBrandOrdersRateFilterTarget,
    newToBrandSalesFilterTarget(currency),
    percentageOfSalesNewToBrandFilterTarget,
    newToBrandAdUnitsSoldFilterTarget,
    percentageOfAdUnitsSoldNewToBrandFilterTarget,
  ];
};

const tagsOrLabelsFilters = (
  isAiEnabled: boolean | undefined,
  isManageTreAutomationEnabled: boolean | undefined,
  adType: AdType,
  salesChannel: FlywheelSalesChannel,
  adLevel: AdLevel
) => {
  if (isAiEnabled) {
    return isManageTreAutomationEnabled &&
      [AdType.SponsoredProducts, AdType.SponsoredBrands].includes(adType)
      ? [
          addedByFilter(adType, adLevel),
          getTagsFilter(
            salesChannel,
            adType,
            AdLevel.KeywordTargets,
            isAiEnabled
          ),
        ]
      : [
          getTargetLabelsFilter(
            salesChannel,
            adType,
            AdLevel.KeywordTargets,
            isAiEnabled
          ),
        ];
  }

  return [];
};

const getAmazonSponsoredBrandCampaignsColumns = (
  currency: string,
  salesChannel: FlywheelSalesChannel,
  adType: AdType,
  groups?: Groups[]
) => [
  campaignNameFilterCampaign,
  portfolioNameFilterCampaign,
  groupFilterCampaign(groups),
  campaignStatusFilterCampaign(salesChannel, adType),
  campaignServingStatusFilterCampaign,
  startDateFilterCampaign,
  endDateFilterCampaign,
  budgetTypeFilterCampaign(salesChannel, adType),
  dailyBudgetFilterCampaign(currency),
  totalBudgetFilter(currency),
  amazonBidOptimizationFilterCampaign,
  belowTopOfTheSearchBidMultiplierFilterCampaign,
  adSalesFilterCampaign(currency),
  adSpendFilterCampaign(currency),
  acosFilterCampaign,
  roasFilterCampaign,
  adConversionsFilterCampaign,
  conversionRateFilterCampaign,
  unitsSoldFilterCampaign,
  impressionsFilterCampaign,
  clicksFilterCampaign,
  avgCostPerClickFilterCampaign(currency),
  clickThroughRateFilterCampaign,
  newToBrandOrdersFilterCampaign,
  percentageOfOrdersNewToBrandFilterCampaign,
  newToBrandOrdersRateFilterCampaign,
  newToBrandAdSalesFilterCampaign(currency),
  percentageOfSalesNewToBrandFilterCampaign,
  newToBrandAdUnitsSoldFilterCampaign,
  percentageOfAdUnitsSoldNewToBrandFilterCampaign,
];

export const getFilterColumnDefinitons = (
  currency: string,
  salesChannel: FlywheelSalesChannel | undefined,
  adType: AdType | undefined,
  adLevel: AdLevel | undefined,
  merchantDetails: MerchantCountry[],
  isManageTreAutomationEnabled?: boolean,
  merchantType?: MerchantType,
  isTargetSummaryEnabled?: boolean,
  campaignType?: string,
  isAiEnabled?: boolean,
  hasCampaignNegativeTargets?: boolean,
  groups?: Groups[],
  showSmartCampaign?: boolean,
  isSponsoredVideosEditable?: boolean
): DataFieldFilter[] => {
  if (!salesChannel || !adType || !adLevel) {
    return [];
  }
  const ALL_FILTERS_PER_AD_LEVEL = {
    [AdLevel.Campaigns]: [
      campaignNameFilterCampaign,
      campaignTargetingTypeFilterCampaign,
      ...(showSmartCampaign
        ? [advertisingGoalFilter, smartStructureFilter]
        : [addedByFilterCampaign(adType)]),
      groupFilterCampaign(groups),
      campaignStatusFilterCampaign(salesChannel, adType),
      createdAtFilter,
      startDateFilterCampaign,
      endDateFilterCampaign,
      budgetTypeFilterCampaign(salesChannel, adType),
      dailyBudgetFilterCampaign(currency),
      totalBudgetFilterCampaign(currency),
      budgetRolloverFilterCampaign,
      searchCarouselInclusionFilterCampaign,
      itemBuyBoxPlacementFilterCampaign,
      itemCarouselPlacementFilterCampaign,
      buyBoxBidMultiplierFilterCampaign,
      searchIngridBidMultiplierFilterCampaign,
      homePageBidMultiplierFilterCampaign,
      stockUpBidMultiplierFilterCampaign,
      desktopBidMultiplierFilterCampaign,
      mobileBidMultiplierFilterCampaign,
      appBidMultiplierColumnFilterCampaign,
      adSalesFilterCampaign(currency),
      adSpendFilterCampaign(currency),
      acosFilterCampaign,
      roasFilterCampaign,
      conversionRateFilterCampaign,
      unitsSoldFilterCampaign,
      impressionsFilterCampaign,
      clicksFilterCampaign,
      avgCostPerClickFilterCampaign(currency),
      clickThroughRateFilterCampaign,
      adSalesDirectFilterCampaign(currency),
      acosDirectFilterCampaign,
      roasDirectFilterCampaign,
      relatedClickRevenueFilterCampaign(currency),
      advertisedSKUFilterCampaigns,
      otherSKUUnitsFilterCampaigns,
      viewableImpressionsFilterForCampaigns,
      viewThroughOrdersFilterForCampaigns,
      viewThroughUnitsSoldFilterForCampaigns,
      completeViewOrdersFilterForCampaigns,
      videoFirstQuartileViewsFilterForCampaigns,
      videoMidpointViewsFilterForCampaigns,
      videoThirdQuartileViewsFilterForCampaigns,
      videoCompleteViewsFilterForCampaigns,
      videoUnmutesFilterForCampaigns,
      fiveSecondViewsFilterForCampaigns,
    ],
    [AdLevel.AdGroups]: [
      adGroupNameFilterAdGroup,
      campaignNameFilterAdGroup,
      campaignTargetingTypeFilterAdGroup,
      ...(isManageTreAutomationEnabled ? [labelsFilterAdGroup] : []),
      ...(showSmartCampaign ? [] : [addedByFilterAdGroup(adType)]),
      dailyBudgetFilterCampaign(currency),
      totalBudgetFilterCampaign(currency),
      campaignStatusFilterAdGroup(salesChannel, adType),
      groupFilterAdGroup(groups),
      adgroupStatusFilterAdGroup(salesChannel, adType),
      createdAtFilterAdGroup,
      ...(showSmartCampaign
        ? [
            aiBiddingFilter(adType),
            estimatedPreAdGrossMarginFilter,
            acosLimitFilter,
            bidModifierFilter,
          ]
        : [bidAutomationStatusFilterAdGroup, macsTargetFilterAdGroup]),
      minBidFilterAdGroup(currency),
      maxBidFilterAdGroup(currency),
      ...(isManageTreAutomationEnabled
        ? [aiRecommendedKeywordFilterAdGroup()]
        : []),
      adSalesFilterAdGroup(currency),
      adSpendFilterAdGroup(currency),
      acosFilterAdGroup,
      roasFilterAdGroup,
      conversionRateFilterAdGroup,
      unitsSoldFilterAdGroup,
      impressionsFilterAdGroup,
      clicksFilterAdGroup,
      avgCostPerClickFilterAdGroup(currency),
      clickThroughRateFilterAdGroup,
      adSalesDirectFilterAdGroup(currency),
      acosDirectFilterAdGroup,
      roasDirectFilterAdGroup,
      relatedClickRevenueFilterAdGroup(currency),
      advertisedSKUFilterAdgroups,
      otherSKUUnitsFilterAdgroups,
    ],
    [AdLevel.ProductAds]: [
      extAdItemIdProductAdsFilter,
      productNameFilterAdItem,
      addedByFilterAdItems(adType),
      adGroupNameFilterAdItem,
      adGroupStatusFilterAdItem,
      campaignNameFilterAdItem,
      campaignTargetingTypeFilterAdItem,
      dailyBudgetFilterCampaign(currency),
      totalBudgetFilterCampaign(currency),
      campaignStatusFilterAdItem(salesChannel, adType),
      productAdStatusFilterAdItem(salesChannel),
      bidFilterAdItem(currency),
      adSalesTotalFilterAdItem(currency),
      adSpendFilterAdItem(currency),
      acosTotalFilterAdItem,
      roasTotalFilterAdItem,
      adConversionRateFilterAdItem,
      adUnitsSoldFilterAdItem,
      impressionsFilterAdItem,
      clicksFilterAdItem,
      costPerClickFilterAdItem(currency),
      clickThroughRateFilterAdItem,
      adSalesDirectFilterAdItem(currency),
      acosDirectFilterAdItem,
      roasDirectFilterAdItem,
      relatedClickRevenueFilterAdItem(currency),
      advertisedSKUFilterAdItems,
      otherSKUUnitsFilterAdItems,
    ],
    [AdLevel.Profiles]: [
      profileBrandFilter,
      profileProfileReviewStatusFilter(salesChannel, adType),
      adGroupNameFilterProfile,
      adgroupStatusFilterProfile(salesChannel, adType),
      adGroupReviewStatusFilterProfile,
      profileCampaignNameFilter,
      campaignTargetingTypeFilterForProfiles,
      campaignDailyBudgetFilterForProfiles(currency),
      campaignTotalBudgetFilterForProfile(currency),
      campaignStatusFilterForProfiles(salesChannel, adType),
      profileStatusFilter,
      headlineFilterForProfiles,
      clickUrlFilterForProfile,
      logoFilterForProfile,
      adSalesFilterProfile(currency),
      adSpendFilterProfile(currency),
      acosFilterProfile,
      roasFilterProfiles,
      adConversionsRateFilterForProfile,
      adUnitsSoldFilterForProfiles,
      impressionsFilterForProfiles,
      clicksFilterForProfiles,
      avgCostPerClickFilterForProfiles(currency),
      clickThroughRateFilterForProfiles,
      adSalesDirectFilterForProfiles(currency),
      acosDirectFilterForProfiles,
      roasDirectFilterForProfiles,
      relatedClickRevenueFilterForProfiles(currency),
      advertisedSKUFilterProfiles,
      otherSKUUnitsFilterProfiles,
    ],
    [AdLevel.KeywordTargets]: [
      targetTextFilterTarget,
      matchTypeFilterTarget(salesChannel),
      ...tagsOrLabelsFilters(
        isAiEnabled,
        isManageTreAutomationEnabled,
        adType,
        salesChannel,
        adLevel
      ),
      ...(isTargetSummaryEnabled ? [dateAddedFilterTarget] : []),
      ...getTargetTypeFilters(isManageTreAutomationEnabled, adType, adLevel),
      adGroupNameFilterTarget,
      adGroupStatusFilterTarget(salesChannel, adType),
      campaignNameFilterTarget,
      campaignTargetingTypeFilterTarget,
      dailyBudgetFilterTarget(currency),
      totalBudgetFilterTarget(currency),
      campaignStatusFilterTarget(salesChannel, adType),
      getTargetStatusFilter(salesChannel),
      bidFilterTarget(currency),
      totalAdSalesFilterTarget(currency),
      adSpendFilterTarget(currency),
      totalAcosFilterTarget,
      totalRoasFilterTarget,
      adConversionRateFilterTarget,
      unitsSoldFilterTarget,
      impressionsFilterTarget,
      clicksFilterTarget,
      averageCostPerClickFilterTarget(currency),
      clickThroughRateFilterTarget,
      directAdSalesFilterTarget(currency),
      directAcosFilterTarget,
      directRoasFilterTarget,
      relatedClickRevenueFilterTarget(currency),
      advertisedSKUFilterTargets,
      otherSKUUnitsFilterTargets,
    ],
    [AdLevel.SvAds]: [
      nameFilterForSvAds,
      adGroupNameFilterForSvAds,
      adGroupStatusFilterForSvAds,
      adGroupReviewStatusFilterForSvAds,
      campaignDailyBudgetFilterForSvAds,
      campaignStatusFilterForSvAds,
      campaignNameFilterForSvAds,
      adStatusFilterForSvAds,
      impressionsFilterForSvAds,
      clicksFilterForSvAds,
      clickThroughRateFilterForSvAds,
      averageCostPerClickFilterForSvAds,
      adSpendFilterForSvAds,
      roasFilterForSvAds,
      totalAttributedSalesFilterForSvAds,
      ordersFilterForSvAds,
      unitsSoldFilterForSvAds,
      conversionRateFilterForSvAds,
      viewableImpressionsFilterForSvAds,
      directAdSalesFilterForSVAds,
      directAcosFilterForSvAds,
      relatedClickRevenueFilterForSvAds,
      advertisedSKUFilterForSvAds,
      otherSKUUnitsFilterForSvAds,
    ],
  };
  const filters: Filters = {
    [FlywheelSalesChannel.Walmart]: {
      [AdType.SponsoredProducts]: {
        [AdLevel.Campaigns]: ALL_FILTERS_PER_AD_LEVEL[AdLevel.Campaigns],
        [AdLevel.AdGroups]: ALL_FILTERS_PER_AD_LEVEL[AdLevel.AdGroups],
        [AdLevel.ProductAds]: ALL_FILTERS_PER_AD_LEVEL[AdLevel.ProductAds],
        [AdLevel.KeywordTargets]:
          ALL_FILTERS_PER_AD_LEVEL[AdLevel.KeywordTargets],
      },
      [AdType.SponsoredVideos]: getSponosredVidosFilter(
        salesChannel,
        adType,
        currency,
        adLevel,
        campaignType,
        groups,
        isSponsoredVideosEditable
      ),
      [AdType.SearchBrandAmplifier]: {
        [AdLevel.Campaigns]: [
          campaignNameFilterCampaign,
          campaignTargetingTypeFilterCampaign,
          campaignStatusFilterCampaign(salesChannel, adType),
          startDateFilterCampaign,
          endDateFilterCampaign,
          budgetTypeFilterCampaign(salesChannel, adType),
          dailyBudgetFilterCampaign(currency),
          totalBudgetFilterCampaign(currency),
          budgetRolloverFilterCampaign,
          adSalesFilterCampaign(currency),
          adSpendFilterCampaign(currency),
          acosFilterCampaign,
          roasFilterCampaign,
          conversionRateFilterCampaign,
          unitsSoldFilterCampaign,
          impressionsFilterCampaign,
          clicksFilterCampaign,
          avgCostPerClickFilterCampaign(currency),
          clickThroughRateFilterCampaign,
          adSalesDirectFilterCampaign(currency),
          acosDirectFilterCampaign,
          roasDirectFilterCampaign,
          relatedClickRevenueFilterCampaign(currency),
          advertisedSKUFilterCampaigns,
          otherSKUUnitsFilterCampaigns,
          newToBrandOrdersFilterCampaign,
          percentageOfOrdersNewToBrandFilterCampaign,
          newToBrandAdSalesFilterCampaign(currency),
          percentageOfSalesNewToBrandFilterCampaign,
          newToBrandAdUnitsSoldFilterCampaign,
          percentageOfAdUnitsSoldNewToBrandFilterCampaign,
        ],
        [AdLevel.ProductAds]: [
          extAdItemIdProductAdsFilter,
          productNameFilterAdItem,
          productReviewStatusFilterAdItem,
          adGroupNameFilterAdItem,
          adGroupStatusFilterAdItem,
          adGroupReviewStatusFilterAdItem,
          campaignNameFilterAdItem,
          campaignTargetingTypeFilterAdItem,
          dailyBudgetFilterCampaign(currency),
          totalBudgetFilterCampaign(currency),
          campaignStatusFilterAdItem(salesChannel, adType),
          productAdStatusFilterAdItem(salesChannel),
          adSalesTotalFilterAdItem(currency),
          adSpendFilterAdItem(currency),
          acosTotalFilterAdItem,
          roasTotalFilterAdItem,
          adConversionRateFilterAdItem,
          adUnitsSoldFilterAdItem,
          impressionsFilterAdItem,
          clicksFilterAdItem,
          costPerClickFilterAdItem(currency),
          clickThroughRateFilterAdItem,
          adSalesDirectFilterAdItem(currency),
          acosDirectFilterAdItem,
          roasDirectFilterAdItem,
          relatedClickRevenueFilterAdItem(currency),
          advertisedSKUFilterAdItems,
          otherSKUUnitsFilterAdItems,
        ],
        [AdLevel.AdGroups]: [
          adGroupNameFilterAdGroup,
          campaignNameFilterAdGroup,
          campaignTargetingTypeFilterAdGroup,
          dailyBudgetFilterCampaign(currency),
          totalBudgetFilterCampaign(currency),
          campaignStatusFilterAdGroup(salesChannel, adType),
          groupFilterAdGroup(groups),
          adgroupStatusFilterAdGroup(salesChannel, adType),
          adGroupReviewTableStatusFilter,
          bidAutomationStatusFilterAdGroup,
          macsTargetFilterAdGroup,
          minBidFilterAdGroup(currency),
          maxBidFilterAdGroup(currency),
          adSalesFilterAdGroup(currency),
          adSpendFilterAdGroup(currency),
          acosFilterAdGroup,
          roasFilterAdGroup,
          conversionRateFilterAdGroup,
          unitsSoldFilterAdGroup,
          impressionsFilterAdGroup,
          clicksFilterAdGroup,
          avgCostPerClickFilterAdGroup(currency),
          clickThroughRateFilterAdGroup,
          adSalesDirectFilterAdGroup(currency),
          acosDirectFilterAdGroup,
          roasDirectFilterAdGroup,
          relatedClickRevenueFilterAdGroup(currency),
          advertisedSKUFilterAdgroups,
          otherSKUUnitsFilterAdItems,
        ],
        [AdLevel.Profiles]: ALL_FILTERS_PER_AD_LEVEL[AdLevel.Profiles],
        [AdLevel.KeywordTargets]: [
          targetTextFilterTarget,
          matchTypeFilterTarget(salesChannel),
          keywordReviewStatusFilterTarget,
          ...(!hasCampaignNegativeTargets
            ? [
                adGroupNameFilterTarget,
                adGroupStatusFilterTarget(salesChannel, adType),
              ]
            : []),
          adGroupReviewStatusFilterTarget,
          campaignNameFilterTarget,
          campaignTargetingTypeFilterTarget,
          dailyBudgetFilterTarget(currency),
          totalBudgetFilterTarget(currency),
          campaignStatusFilterTarget(salesChannel, adType),
          getTargetStatusFilter(salesChannel),
          bidFilterTarget(currency),
          totalAdSalesFilterTarget(currency),
          adSpendFilterTarget(currency),
          totalAcosFilterTarget,
          totalRoasFilterTarget,
          adConversionRateFilterTarget,
          unitsSoldFilterTarget,
          impressionsFilterTarget,
          clicksFilterTarget,
          averageCostPerClickFilterTarget(currency),
          clickThroughRateFilterTarget,
          directAdSalesFilterTarget(currency),
          directAcosFilterTarget,
          directRoasFilterTarget,
          relatedClickRevenueFilterTarget(currency),
          advertisedSKUFilterTargets,
          otherSKUUnitsFilterTargets,
        ],
      },
    },
    [FlywheelSalesChannel.Amazon]: {
      [AdType.SponsoredBrands]: {
        [AdLevel.Campaigns]: getAmazonSponsoredBrandCampaignsColumns(
          currency,
          salesChannel,
          adType,
          groups
        ),
        [AdLevel.ProductTargets]: [
          ...(isManageTreAutomationEnabled
            ? []
            : [
                getTargetLabelsFilter(
                  salesChannel,
                  adType,
                  AdLevel.ProductTargets,
                  isAiEnabled
                ),
              ]),
          ...(isAiEnabled && isManageTreAutomationEnabled
            ? [addedByFilter(adType, adLevel)]
            : []),
          ...getTargetTypeFilters(
            isManageTreAutomationEnabled,
            adType,
            adLevel
          ),
          adGroupNameFilterTarget,
          adGroupStatusFilterTarget(salesChannel, adType),
          campaignNameFilterTarget,
          dailyBudgetFilterTarget(currency),
          totalBudgetFilterTarget(currency),
          campaignStatusFilterTarget(salesChannel, adType),
          portfolioNameFilterTarget,
          getTargetStatusFilter(salesChannel),
          bidFilterTarget(currency),
          totalAdSalesFilterTarget(currency),
          adSpendFilterTarget(currency),
          totalAcosFilterTarget,
          totalRoasFilterTarget,
          adConversionsFilterTarget,
          adConversionRateFilterTarget,
          adUnitsSoldFilterTarget,
          impressionsFilterTarget,
          clicksFilterTarget,
          averageCostPerClickFilterTarget(currency),
          clickThroughRateFilterTarget,
          newToBrandOrdersFilterTarget,
          percentageOfOrdersNewToBrandFilterTarget,
          newToBrandOrdersRateFilterTarget,
          newToBrandSalesFilterTarget(currency),
          percentageOfSalesNewToBrandFilterTarget,
          newToBrandAdUnitsSoldFilterTarget,
          percentageOfAdUnitsSoldNewToBrandFilterTarget,
        ],
        [AdLevel.AdGroups]: [
          adGroupNameFilterAdGroup,
          campaignNameFilterAdGroup,
          campaignDailyBudgetFilterAdGroup(currency),
          campaignStatusFilterAdGroup(salesChannel, adType),
          portfolioNameFilterAdGroup,
          groupFilterAdGroup(groups),
          adgroupStatusFilterAdGroup(salesChannel, adType),
          ...(showSmartCampaign
            ? [
                aiBiddingFilter(adType),
                estimatedPreAdGrossMarginFilter,
                acosLimitFilter,
                bidModifierFilter,
              ]
            : [bidAutomationStatusFilterAdGroup, macsTargetFilterAdGroup]),
          minBidFilterAdGroup(currency),
          maxBidFilterAdGroup(currency),
          adSalesFilterAdGroup(currency),
          adSpendFilterAdGroup(currency),
          acosFilterAdGroup,
          roasFilterAdGroup,
          adConversionsFilterAdGroup,
          conversionRateFilterAdGroup,
          unitsSoldFilterAdGroup,
          impressionsFilterAdGroup,
          clicksFilterAdGroup,
          avgCostPerClickFilterAdGroup(currency),
          clickThroughRateFilterAdGroup,
        ],
        [AdLevel.KeywordTargets]: getSbKeywordTargetsColumns(
          currency,
          salesChannel,
          adType,
          adLevel,
          isTargetSummaryEnabled,
          campaignType,
          isAiEnabled,
          isManageTreAutomationEnabled
        ),
        [AdLevel.SbAds]: [
          creativeNameFilter,
          creativeTypeFilter(),
          servingStatusFilter(),
          adGroupNameFilterForSbAds,
          adGroupStatusFilterForSbAds(salesChannel, adType),
          campaignNameFilterForSbAds,
          campaignDailyBudgetFilterForSbAds(currency),
          campaignStatusFilterForSbAds(salesChannel, adType),
          portfolioNameFilterForSbAds,
          adStatusFilterForSbAds(),
          adSalesFilterForSbAds(currency),
          adSpendFilterForSbAds(currency),
          acosFilterForSbAds,
          roasFilterForSbAds,
          adConversionsFilterForSbAds,
          adConversionsRateFilterForSbAds,
          unitsSoldFilterForSbAds,
          impressionsFilterForSbAds,
          clicksFilterForSbAds,
          averageCostPerClickFilterForSbAds(currency),
          clickThroughRateFilterForSbAds,
          newToBrandOrdersFilterForSbAds,
          percentageOfOrdersNewToBrandFilterForSbAds,
          newToBrandOrdersRateFilterForSbAds,
          newToBrandAdSalesFilterForSbAds(currency),
          percentageOfSalesNewToBrandFilterForSbAds,
          newToBrandAdUnitsSoldFilterForSbAds,
          percentageOfAdUnitsSoldNewToBrandFilterForSbAds,
        ],
      },
      [AdType.SponsoredDisplay]: {
        [AdLevel.Campaigns]: [
          campaignNameFilterCampaign,
          campaignTacticFilterCampaign,
          campaignCostTypeFilterCampaign,
          portfolioNameFilterCampaign,
          groupFilterCampaign(groups),
          campaignStatusFilterCampaign(salesChannel, adType),
          startDateFilterCampaign,
          endDateFilterCampaign,
          dailyBudgetFilterCampaign(currency),
          adSalesFilterCampaign(currency),
          adSpendFilterCampaign(currency),
          acosFilterCampaign,
          roasFilterCampaign,
          adConversionsFilterCampaign,
          conversionRateFilterCampaign,
          unitsSoldFilterCampaign,
          impressionsFilterCampaign,
          clicksFilterCampaign,
          avgCostPerClickFilterCampaign(currency),
          clickThroughRateFilterCampaign,
          newToBrandAdSalesFilterCampaign(currency),
          newToBrandOrdersFilterCampaign,
          newToBrandAdUnitsSoldFilterCampaign,
        ],
        [AdLevel.AdGroups]: [
          adGroupNameFilterAdGroup,
          campaignNameFilterAdGroup,
          campaignTacticFilterAdGroup,
          campaignDailyBudgetFilterAdGroup(currency),
          campaignStatusFilterAdGroup(salesChannel, adType),
          campaignCostTypeFilterAdGroup,
          portfolioNameFilterCampaign,
          groupFilterAdGroup(groups),
          adgroupDefaultBidFilterAdGroup(currency),
          adgroupStatusFilterAdGroup(salesChannel, adType),
          bidAutomationStatusFilterAdGroup,
          macsTargetFilterAdGroup,
          minBidFilterAdGroup(currency),
          maxBidFilterAdGroup(currency),
          adSalesFilterAdGroup(currency),
          adSpendFilterAdGroup(currency),
          acosFilterAdGroup,
          roasFilterAdGroup,
          adConversionsFilterAdGroup,
          conversionRateFilterAdGroup,
          unitsSoldFilterAdGroup,
          impressionsFilterAdGroup,
          clicksFilterAdGroup,
          avgCostPerClickFilterAdGroup(currency),
          clickThroughRateFilterAdGroup,
          newToBrandAdConversionsFilterAdGroup,
          newToBrandAdSalesFilterAdGroup(currency),
          newToBrandAdUnitsSoldFilterAdGroup,
        ],
        [AdLevel.ProductAds]: getAmazonProductAdsSponsoredDisplayFilter(
          currency,
          salesChannel,
          adType,
          merchantType
        ),
        [AdLevel.Targets]: [
          ...(isAiEnabled && isManageTreAutomationEnabled
            ? [addedByFilter(adType, adLevel)]
            : []),
          ...(isManageTreAutomationEnabled
            ? []
            : [
                getTargetLabelsFilter(
                  salesChannel,
                  adType,
                  AdLevel.ProductTargets,
                  isAiEnabled
                ),
              ]),
          ...getTargetTypeFilters(
            isManageTreAutomationEnabled,
            adType,
            adLevel
          ),
          adGroupNameFilterTarget,
          adGroupStatusFilterTarget(salesChannel, adType),
          campaignNameFilterTarget,
          campaignTacticFitlerTarget,
          dailyBudgetFilterTarget(currency),
          campaignStatusFilterTarget(salesChannel, adType),
          campaignCostTypeFilterTarget,
          portfolioNameFilterCampaign,
          getTargetStatusFilter(salesChannel),
          bidFilterTarget(currency),
          totalAdSalesFilterTarget(currency),
          adSpendFilterTarget(currency),
          totalAcosFilterTarget,
          totalRoasFilterTarget,
          adConversionsFilterTarget,
          adConversionRateFilterTarget,
          unitsSoldFilterTarget,
          impressionsFilterTarget,
          clicksFilterTarget,
          averageCostPerClickFilterTarget(currency),
          clickThroughRateFilterTarget,
          newToBrandOrdersFilterTarget,
          newToBrandSalesFilterTarget(currency),
          newToBrandAdUnitsSoldFilterTarget,
        ],
      },
      [AdType.SponsoredProducts]: {
        [AdLevel.AdGroups]: [
          adGroupNameFilterAdGroup,
          campaignNameFilterAdGroup,
          campaignTargetingTypeFilterAdGroup,
          ...(isManageTreAutomationEnabled ? [labelsFilterAdGroup] : []),
          ...(showSmartCampaign ? [] : [addedByFilterAdGroup(adType)]),
          campaignDailyBudgetFilterAdGroup(currency),
          campaignStatusFilterAdGroup(salesChannel, adType),
          portfolioNameFilterAdGroup,
          groupFilterAdGroup(groups),
          adgroupDefaultBidFilterAdGroup(currency),
          adgroupStatusFilterAdGroup(salesChannel, adType),
          createdAtFilterAdGroup,
          ...(showSmartCampaign
            ? [
                aiBiddingFilter(adType),
                estimatedPreAdGrossMarginFilter,
                acosLimitFilter,
                bidModifierFilter,
              ]
            : [bidAutomationStatusFilterAdGroup, macsTargetFilterAdGroup]),
          minBidFilterAdGroup(currency),
          maxBidFilterAdGroup(currency),
          ...(isManageTreAutomationEnabled
            ? [aiRecommendedKeywordFilterAdGroup()]
            : []),
          adSalesFilterAdGroup(currency),
          adSpendFilterAdGroup(currency),
          acosFilterAdGroup,
          roasFilterAdGroup,
          adConversionsFilterAdGroup,
          conversionRateFilterAdGroup,
          unitsSoldFilterAdGroup,
          impressionsFilterAdGroup,
          clicksFilterAdGroup,
          avgCostPerClickFilterAdGroup(currency),
          clickThroughRateFilterAdGroup,
        ],
        [AdLevel.Campaigns]: [
          campaignNameFilterCampaign,
          campaignTargetingTypeFilterCampaign,
          ...(showSmartCampaign
            ? [advertisingGoalFilter, smartStructureFilter]
            : [addedByFilterCampaign(adType)]),
          portfolioNameFilterCampaign,
          groupFilterCampaign(groups),
          campaignStatusFilterCampaign(salesChannel, adType),
          createdAtFilter,
          startDateFilterCampaign,
          endDateFilterCampaign,
          dailyBudgetFilterCampaign(currency),
          amazonBiddingStrategyFilterCampaign,
          productPageBidMultiplierFilterCampaign,
          topOfSearchBidMultiplierFilterCampaign,
          adSalesFilterCampaign(currency),
          adSpendFilterCampaign(currency),
          acosFilterCampaign,
          roasFilterCampaign,
          adConversionsFilterCampaign,
          conversionRateFilterCampaign,
          unitsSoldFilterCampaign,
          impressionsFilterCampaign,
          clicksFilterCampaign,
          avgCostPerClickFilterCampaign(currency),
          clickThroughRateFilterCampaign,
        ],
        [AdLevel.ProductTargets]: [
          ...(isManageTreAutomationEnabled
            ? []
            : [
                getTargetLabelsFilter(
                  salesChannel,
                  adType,
                  AdLevel.ProductTargets,
                  isAiEnabled
                ),
              ]),
          ...(isTargetSummaryEnabled ? [dateAddedFilterTarget] : []),
          ...(isAiEnabled && isManageTreAutomationEnabled
            ? [addedByFilter(adType, adLevel)]
            : []),
          ...getTargetTypeFilters(
            isManageTreAutomationEnabled,
            adType,
            adLevel
          ),
          adGroupNameFilterTarget,
          adGroupStatusFilterTarget(salesChannel, adType),
          campaignNameFilterTarget,
          campaignTargetingTypeFilterTarget,
          dailyBudgetFilterTarget(currency),
          campaignStatusFilterTarget(salesChannel, adType),
          portfolioNameFilterTarget,
          getTargetStatusFilter(salesChannel),
          bidFilterTarget(currency),
          totalAdSalesFilterTarget(currency),
          adSpendFilterTarget(currency),
          totalAcosFilterTarget,
          totalRoasFilterTarget,
          adConversionsFilterTarget,
          adConversionRateFilterTarget,
          adUnitsSoldFilterTarget,
          impressionsFilterTarget,
          clicksFilterTarget,
          averageCostPerClickFilterTarget(currency),
          clickThroughRateFilterTarget,
        ],
        [AdLevel.KeywordTargets]: getSPKeywordTargetsColumns(
          currency,
          salesChannel,
          adType,
          adLevel,
          isTargetSummaryEnabled,
          campaignType,
          isAiEnabled,
          hasCampaignNegativeTargets,
          isManageTreAutomationEnabled
        ),
        [AdLevel.ProductAds]: getAmazonProductAdsSponsoredProductsFilter(
          currency,
          salesChannel,
          adType,
          merchantType
        ),
      },
    },
  };

  return filters?.[salesChannel]?.[adType]?.[adLevel] || [];
};
const getAmazonProductAdsSponsoredDisplayFilter = (
  currency: string,
  salesChannel: FlywheelSalesChannel | null,
  adType: AdType | null,
  merchantType?: MerchantType
): DataFieldFilter[] => {
  const amazonProductAdsSponsoredDisplayFilter: DataFieldFilter[] = [
    productNameFilterAdItem,
  ];
  if (isMerchantTypeSeller(merchantType)) {
    amazonProductAdsSponsoredDisplayFilter.push(skuFilter);
  } else {
    amazonProductAdsSponsoredDisplayFilter.push(asinFilter);
  }
  amazonProductAdsSponsoredDisplayFilter.push(
    adGroupNameFilterAdItem,
    adGroupStatusFilterAdItem,
    campaignNameFilterAdItem,
    campaignTacticFilterAdItem
  );

  if (!isNil(salesChannel) && !isNil(adType)) {
    amazonProductAdsSponsoredDisplayFilter.push(
      campaignStatusFilterAdItem(salesChannel, adType),
      campaignDailyBudgetFilterAdItem(currency)
    );
  }

  amazonProductAdsSponsoredDisplayFilter.push(
    campaignCostTypeFilterAdItem,
    portfolioNameFilterCampaign
  );

  if (!isNil(salesChannel) && !isNil(adType)) {
    amazonProductAdsSponsoredDisplayFilter.push(
      productAdStatusFilterAdItem(salesChannel)
    );
  }

  amazonProductAdsSponsoredDisplayFilter.push(
    adSalesTotalFilterAdItem(currency),
    adSpendFilterAdItem(currency),
    acosTotalFilterAdItem,
    roasTotalFilterAdItem,
    adUnitsSoldFilterAdItem,
    adConversionRateFilterAdItem,
    impressionsFilterAdItem,
    clicksFilterAdItem,
    costPerClickFilterAdItem(currency),
    clickThroughRateFilterAdItem,
    newToBrandAdSalesFilterAdItem(currency),
    newToBrandOrdersFilterAdItem,
    newToBrandAdUnitsSoldFilterAdItem
  );

  return amazonProductAdsSponsoredDisplayFilter;
};
const getAmazonProductAdsSponsoredProductsFilter = (
  currency: string,
  salesChannel: FlywheelSalesChannel | null,
  adType: AdType | null,
  merchantType?: MerchantType
): DataFieldFilter[] => {
  const amazonProductAdsSponsoredProductsFilter: DataFieldFilter[] = [
    productNameFilterAdItem,
  ];

  if (isMerchantTypeSeller(merchantType)) {
    amazonProductAdsSponsoredProductsFilter.push(skuFilter);
  } else {
    amazonProductAdsSponsoredProductsFilter.push(asinFilter);
  }

  amazonProductAdsSponsoredProductsFilter.push(
    adGroupNameFilterAdItem,
    adGroupStatusFilterAdItem,
    campaignNameFilterAdItem,
    campaignTargetingTypeFilterAdItem,
    campaignDailyBudgetFilterAdItem(currency)
  );

  if (adType !== null) {
    amazonProductAdsSponsoredProductsFilter.push(addedByFilterAdItems(adType));
  }

  if (salesChannel !== null && adType !== null) {
    amazonProductAdsSponsoredProductsFilter.push(
      campaignStatusFilterAdItem(salesChannel, adType)
    );
  }

  amazonProductAdsSponsoredProductsFilter.push(portfolioNameFilterAdItem);

  if (salesChannel !== null && adType !== null) {
    amazonProductAdsSponsoredProductsFilter.push(
      productAdStatusFilterAdItem(salesChannel)
    );
  }

  amazonProductAdsSponsoredProductsFilter.push(
    adSalesTotalFilterAdItem(currency),
    adSpendFilterAdItem(currency),
    acosTotalFilterAdItem,
    roasTotalFilterAdItem,
    adConversionsFilterAdItem,
    adConversionRateFilterAdItem,
    adUnitsSoldFilterAdItem,
    impressionsFilterAdItem,
    clicksFilterAdItem,
    costPerClickFilterAdItem(currency),
    clickThroughRateFilterAdItem
  );

  return amazonProductAdsSponsoredProductsFilter;
};

const getSponosredVidosFilter = (
  salesChannel: FlywheelSalesChannel,
  adType: AdType,
  currency: string,
  adLevel: AdLevel,
  campaignType?: string,
  groups?: Groups[],
  isSponsoredVideosEditable?: boolean
): Record<string, DataFieldFilter[]> =>
  isSponsoredVideosEditable
    ? {
        [AdLevel.Campaigns]: [
          campaignNameFilterCampaign,
          campaignTargetingTypeFilterCampaign,
          groupFilterCampaign(groups),
          campaignStatusFilterCampaign(salesChannel, adType),
          startDateFilterCampaign,
          endDateFilterCampaign,
          budgetTypeFilterCampaign(salesChannel, adType),
          dailyBudgetFilterCampaign(currency),
          totalBudgetFilterCampaign(currency),
          budgetRolloverFilterCampaign,
          adSalesFilterCampaign(currency),
          adSpendFilterCampaign(currency),
          acosFilterCampaign,
          roasFilterCampaign,
          conversionRateFilterCampaign,
          unitsSoldFilterCampaign,
          impressionsFilterCampaign,
          clicksFilterCampaign,
          avgCostPerClickFilterCampaign(currency),
          clickThroughRateFilterCampaign,
          adSalesDirectFilterCampaign(currency),
          acosDirectFilterCampaign,
          roasDirectFilterCampaign,
          relatedClickRevenueFilterCampaign(currency),
          newToBrandOrdersFilterCampaign,
          percentageOfOrdersNewToBrandFilterCampaign,
          newToBrandAdSalesFilterCampaign(currency),
          percentageOfSalesNewToBrandFilterCampaign,
          newToBrandAdUnitsSoldFilterCampaign,
          percentageOfAdUnitsSoldNewToBrandFilterCampaign,
          advertisedSKUFilterCampaigns,
          otherSKUUnitsFilterCampaigns,
          ordersFilterForCampaigns,
          viewableImpressionsFilterForCampaigns,
          viewThroughOrdersFilterForCampaigns,
          viewThroughUnitsSoldFilterForCampaigns,
          completeViewOrdersFilterForCampaigns,
          videoFirstQuartileViewsFilterForCampaigns,
          videoMidpointViewsFilterForCampaigns,
          videoThirdQuartileViewsFilterForCampaigns,
          videoCompleteViewsFilterForCampaigns,
          videoUnmutesFilterForCampaigns,
          fiveSecondViewsFilterForCampaigns,
        ],
        [AdLevel.AdGroups]: [
          adGroupNameFilterAdGroup,
          campaignTargetingTypeFilterAdGroup,
          campaignNameFilterAdGroup,
          campaignStatusFilterAdGroup(salesChannel, adType),
          adgroupStatusFilterAdGroup(salesChannel, adType),
          campaignDailyBudgetFilterAdGroup(currency),
          adGroupTotalBudgetFilter(currency),
          adGroupReviewTableStatusFilter,
          adSalesFilterAdGroup(currency),
          adSpendFilterAdGroup(currency),
          acosFilterAdGroup,
          roasFilterAdGroup,
          adgroupordersFilter,
          conversionRateFilterAdGroup,
          adSalesDirectFilterAdGroup(currency),
          acosDirectFilterAdGroup,
          roasDirectFilterAdGroup,
          relatedClickRevenueFilterAdGroup(currency),
          advertisedSKUFilterAdgroups,
          otherSKUUnitsFilterAdgroups,
          unitsSoldFilterAdGroup,
          impressionsFilterAdGroup,
          clicksFilterAdGroup,
          avgCostPerClickFilterAdGroup(currency),
          clickThroughRateFilterAdGroup,
        ],
        [AdLevel.KeywordTargets]: [
          matchTypeFilterTarget(salesChannel, campaignType),
          keywordReviewStatusFilterTarget,
          getTargetTypeFilter(adType, adLevel),
          adGroupNameFilterTarget,
          adGroupStatusFilterTarget(salesChannel, adType),
          adGroupReviewStatusFilterTarget,
          campaignNameFilterTarget,
          campaignTargetingTypeFilterTarget,
          dailyBudgetFilterTarget(currency),
          totalBudgetFilterTarget(currency),
          campaignStatusFilterTarget(salesChannel, adType),
          getTargetStatusFilter(salesChannel),
          bidFilterTarget(currency),
          adSalesFilterAdGroup(currency),
          adSpendFilterTarget(currency),
          totalAcosFilterTarget,
          totalRoasFilterTarget,
          adConversionRateFilterTarget,
          adUnitsSoldFilterTarget,
          impressionsFilterTarget,
          clicksFilterTarget,
          averageCostPerClickFilterTarget(currency),
          clickThroughRateFilterTarget,
          directAdSalesFilter(currency),
          directAcosFilterTarget,
          directRoasFilterTarget,
          relatedClickRevenueFilterTarget(currency),
          advertisedSKUFilterTargets,
          otherSKUUnitsFilterTargets,
          targetsordersFilter,
        ],
        [AdLevel.SvAds]: [
          nameFilterForSvAds,
          adGroupStatusFilterForSvAds(salesChannel, adType),
          adGroupReviewStatusFilterForSvAds,
          adGroupNameFilterForSvAds,
          campaignNameFilterForSvAds,
          campaignTargetingTypeFilter,
          campaignDailyBudgetFilterForSvAds(currency),
          campaignLifetimeBudgetFilterForSvAds(currency),
          campaignStatusFilterForSvAds(salesChannel, adType),
          adStatusFilterForSvAds,
          totalAttributedSalesFilterForSvAds(currency),
          adSpendFilterForSvAds(currency),
          acosTotalFilterForSvAds,
          roasFilterForSvAds,
          conversionRateFilterForSvAds,
          unitsSoldFilterForSvAds,
          ordersFilterForSvAds,
          impressionsFilterForSvAds,
          clicksFilterForSvAds,
          averageCostPerClickFilterForSvAds(currency),
          clickThroughRateFilterForSvAds,
          directAdSalesFilterForSVAds(currency),
          directAcosFilterForSvAds,
          directRoasFilterForSvAds,
          relatedClickRevenueFilterForSvAds(currency),
          advertisedSKUFilterForSvAds,
          otherSKUUnitsFilterForSvAds,
        ],
      }
    : {
        [AdLevel.Campaigns]: [
          campaignNameFilterCampaign,
          campaignStatusFilterCampaign(salesChannel, adType),
          startDateFilterCampaign,
          endDateFilterCampaign,
          dailyBudgetFilterCampaign(currency),
          impressionsFilterCampaign,
          clicksFilterCampaign,
          clickThroughRateFilterCampaign,
          avgCostPerClickFilterCampaign(currency),
          adSalesFilterCampaign(currency),
          adSpendFilterCampaign(currency),
          acosFilterCampaign,
          roasFilterCampaign,
          unitsSoldFilterCampaign,
          ordersFilterForCampaigns,
          conversionRateFilterCampaign,
          viewableImpressionsFilterForCampaigns,
          viewThroughOrdersFilterForCampaigns,
          viewThroughUnitsSoldFilterForCampaigns,
          completeViewOrdersFilterForCampaigns,
          videoFirstQuartileViewsFilterForCampaigns,
          videoMidpointViewsFilterForCampaigns,
          videoThirdQuartileViewsFilterForCampaigns,
          videoCompleteViewsFilterForCampaigns,
          videoUnmutesFilterForCampaigns,
          fiveSecondViewsFilterForCampaigns,
        ],
        [AdLevel.AdGroups]: [
          adGroupNameFilterAdGroup,
          campaignNameFilterAdGroup,
          campaignStatusFilterAdGroup(salesChannel, adType),
          adgroupStatusFilterAdGroup(salesChannel, adType),
          adSalesFilterAdGroup(currency),
          adSpendFilterAdGroup(currency),
          acosFilterAdGroup,
          roasFilterAdGroup,
          conversionRateFilterAdGroup,
          unitsSoldFilterAdGroup,
          impressionsFilterAdGroup,
          clicksFilterAdGroup,
          avgCostPerClickFilterAdGroup(currency),
          clickThroughRateFilterAdGroup,
          adgroupordersFilter,
        ],
        [AdLevel.KeywordTargets]: [
          matchTypeFilterTarget(salesChannel, campaignType),
          addedByFilter(adType, adLevel),
          getTargetTypeFilter(adType, adLevel),
          adGroupNameFilterTarget,
          campaignNameFilterTarget,
          getTargetStatusFilter(salesChannel),
          bidFilterTarget(currency),
          adSalesFilterAdGroup(currency),
          adSpendFilterTarget(currency),
          totalAcosFilterTarget,
          totalRoasFilterTarget,
          adConversionRateFilterTarget,
          adUnitsSoldFilterTarget,
          impressionsFilterTarget,
          clicksFilterTarget,
          averageCostPerClickFilterTarget(currency),
          clickThroughRateFilterTarget,
          targetsordersFilter,
        ],
        [AdLevel.SvAds]: [
          nameFilterForSvAds,
          adGroupNameFilterForSvAds,
          campaignNameFilterForSvAds,
          adStatusFilterForSvAds,
          totalAttributedSalesFilterForSvAds(currency),
          adSpendFilterForSvAds(currency),
          acosTotalFilterForSvAds,
          roasFilterForSvAds,
          conversionRateFilterForSvAds,
          unitsSoldFilterForSvAds,
          impressionsFilterForSvAds,
          clicksFilterForSvAds,
          averageCostPerClickFilterForSvAds(currency),
          clickThroughRateFilterForSvAds,
          ordersFilterForSvAds,
        ],
      };
