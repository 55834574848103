import { FlywheelTableColumnGroup } from '../../../../../containers/table/UpdatedFlywheelTable';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { acosTotalColumn } from './acos';
import { adGroupName } from './adGroupName';
import { adSpend } from './adSpend';
import { adStatus } from './adStatus';
import { adgroupStatusColumn } from './adgroupStatus';
import { averageCostPerClick } from './averageCostPerClick';
import { campaignName } from './campaignName';
import { clickThroughRate } from './clickThroughRate';
import { clicks } from './clicks';
import { conversionRate } from './conversionRate';
import { impressionsColumn } from './impressions';
import { name } from './name';
import { orders } from './orders';
import { roas } from './roas';
import { totalAttributedSales } from './totalAttirbutedSales';
import { unitsSold } from './unitsSold';
import { viewableImpressions } from './viewableImpressions';
import { CheckboxCheckedState } from '@teikametrics/tm-design-system';
import isNil from 'lodash/isNil';
import { adGroupReviewStatusColumn } from './adGroupReviewStatus';
import { campaignTargetingTypeColumn } from './campaignTargetingType';
import { campaignDailyBudgetColumn } from './campaignDailyBudget';
import { campaignLifetimeBudgetColumn } from './campaignLifetimeBudget';
import { campaignStatusColumn } from './campaignStatus';
import { directAdSalesColumn } from './directAdSales';
import { directAcosColumn } from './directAcos';
import { directRoasColumn } from './directRoas';
import { otherSKUUnitsColumn } from './svAdsOtherSkuUnits';
import { advertisedSKUUnitsColumn } from './advertisedSkuUnits';
import { relatedClickRevenueColumn } from './relatedClickRevenue';

export const getColumns = (
  isEditMode: boolean,
  headerExtraProps?: {
    readonly checked: CheckboxCheckedState;
    readonly onSelectChange: () => void;
  }
): FlywheelTableColumnGroup<ProductAdDetails, TableDataAdsManager>[] => {
  const additionalHeaderProps = () => {
    return {
      ...(!isNil(headerExtraProps)
        ? {
            showCheckBox: isEditMode,
            checked: headerExtraProps?.checked,
            onSelectChange: headerExtraProps?.onSelectChange,
          }
        : {}),
    };
  };
  return [
    {
      columnsInGroup: [{ ...name, ...additionalHeaderProps() }],
      groupingHeaderI18nKey: I18nKey.GENERIC_DETAILS,
    },
    {
      columnsInGroup: [
        adGroupName,
        adgroupStatusColumn,
        adGroupReviewStatusColumn,
        campaignName,
        campaignTargetingTypeColumn,
        campaignDailyBudgetColumn,
        campaignLifetimeBudgetColumn,
        campaignStatusColumn,
      ],
    },
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_CHANNEL_SETTINGS,
      columnsInGroup: [adStatus],
    },
    {
      groupingHeaderI18nKey: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_PERFORMANCE,
      columnsInGroup: [
        totalAttributedSales,
        adSpend,
        acosTotalColumn,
        roas,
        conversionRate,
        unitsSold,
        orders,
        impressionsColumn,
        clicks,
        averageCostPerClick,
        clickThroughRate,
        viewableImpressions,
        directAdSalesColumn,
        directAcosColumn,
        directRoasColumn,
        relatedClickRevenueColumn,
        advertisedSKUUnitsColumn,
        otherSKUUnitsColumn,
      ],
    },
  ];
};
