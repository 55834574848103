import { PointOptionsObject } from 'highcharts';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import I18nKey from '../../../../lib/types/I18nKey';
import { TargetSegment } from '../../../../lib/types/KeywordAction';
import { MAP_SALES_CHANNEL_ID_TO_NAME } from '../../../../lib/types/SalesChannels';
import { PROFILES_API_COLUMN_NAME } from '../../../advertisingOptimization/containers/adsManager/utils';
import {
  REQUEST_DATE_FORMAT,
  REQUEST_START_DATE_WRT_CURRENT_DATE_IN_DAYS,
} from './const';
import {
  KeywordsPieChartDataProps,
  MAP_TARGET_SEGMENT_TO_PIE_CHART_COLOR,
  MAP_TARGET_SEGMENT_TO_RAW_PIE_CHART_COLOR,
} from './types';
import {
  AdType,
  AddedBy,
  TargetsDetails,
} from '../../../../lib/types/AOSharedTypes';
import {
  LightningIcon,
  SparkleIcon,
  UserIcon,
} from '@teikametrics/tm-design-system';
import { EqualToFilter, FilterOps } from '../../../../lib/types/Filter';

export const getAddedByIcon = (addedBy: string) => {
  if (addedBy === AddedBy.FlywheelAutomated) {
    return LightningIcon;
  } else if (addedBy === AddedBy.ChatGPT) {
    return SparkleIcon;
  }
  return UserIcon;
};
export const getTabs = (intl: IntlShape) => {
  return [
    {
      label: intl.formatMessage({
        id: I18nKey.KEYWORD_RECOMMENDATIONS_VIEW_SKUS_KEYWORDS_SLIDEOUT_SKUS,
      }),
    },
    {
      label: intl.formatMessage({
        id: I18nKey.KEYWORD_RECOMMENDATIONS_VIEW_SKUS_KEYWORDS_SLIDEOUT_KEYWORDS,
      }),
    },
  ];
};

export const getTargetSegmentCount = (
  allKeywordsData: TargetsDetails[]
): KeywordsPieChartDataProps[] => {
  return Object.values(TargetSegment).map((eachSegment) => ({
    type: eachSegment,
    count: allKeywordsData.filter(
      (keywordData) => keywordData.targetDetails.targetType === eachSegment
    ).length,
  }));
};

export const getTargetSegmentColor = (
  targetSegment: TargetSegment,
  count: number
) => {
  if (count) {
    return `bg-purple-${MAP_TARGET_SEGMENT_TO_PIE_CHART_COLOR[targetSegment]}`;
  } else {
    return 'bg-grey-200';
  }
};

export const getPieSliceData = (
  pieFormattedData: KeywordsPieChartDataProps[],
  totalCount: number
) => {
  let pieSliceData: PointOptionsObject[] = [];
  pieFormattedData.forEach((pieData) =>
    pieSliceData.push({
      name: pieData.type,
      y: pieData.count / totalCount,
      color: MAP_TARGET_SEGMENT_TO_RAW_PIE_CHART_COLOR[pieData.type],
    })
  );
  return pieSliceData;
};

const getEntityTypeRequestDates = () => {
  const now = DateTime.now();
  return {
    startDate: now
      .minus({ days: REQUEST_START_DATE_WRT_CURRENT_DATE_IN_DAYS })
      .toFormat(REQUEST_DATE_FORMAT),
    endDate: now.toFormat(REQUEST_DATE_FORMAT),
  };
};

export const getCommonEnityRequestFields = (
  merchantCountryId: string,
  salesChannelId: string,
  merchantType: string
) => {
  const dates = getEntityTypeRequestDates();

  return {
    startDate: dates.startDate,
    endDate: dates.endDate,
    merchantCountryIds: [merchantCountryId],
    channelId: MAP_SALES_CHANNEL_ID_TO_NAME[salesChannelId],
    advertisingType: AdType.SponsoredProducts,
    salesChannelId: salesChannelId,
    merchantType: merchantType,
  };
};

export const getAdGroupFilter = (adGroupId: string): EqualToFilter[] => {
  return [
    {
      op: FilterOps.eq,
      field: PROFILES_API_COLUMN_NAME.AdGroupId,
      value: adGroupId,
    },
  ];
};
