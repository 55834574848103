import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  TextLink,
  TextLinkProps,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import { FlywheelSalesChannel } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../ConnectModalWrapper';
import { NeedHelp } from '../../../../components/NeedHelp';
import { StepsComplete } from '../../../../components/StepsComplete';
import {
  INTERCOM_AMAZON_CONNECT_ACCOUNT_HELP,
  INTERCOM_AMAZON_CONNECT_ACCOUNT_HELP_ALREADY_CONNECTED,
} from '../constants';
import { MAP_FLYWHEEL_SALES_CHANNEL_TO_I18NKEY } from '../../../../lib/types/SalesChannels';

interface DataBoxes {
  isItemComplated: boolean;
  primaryText: I18nKey;
  secondaryText?: I18nKey;
}

interface ButtonProps {
  label: string;
  onClick: Function;
  variant?: ButtonVariant;
}

interface ConnectionSuccessModalProps {
  readonly isNextStepPresent?: boolean;
  readonly dataBoxes?: DataBoxes[];
  readonly primaryButtonProps?: ButtonProps;
  readonly secondryButtonProps?: ButtonProps;
  readonly channel?: FlywheelSalesChannel;
  readonly closeModal?: () => void;
  readonly primaryButtonDataTestId?: string;
  readonly secondryButtonDataTestId?: string;
  readonly textLinkProps?: TextLinkProps;
}

export const ConnectionSuccessModal: React.FC<ConnectionSuccessModalProps> = ({
  isNextStepPresent,
  dataBoxes = [],
  primaryButtonProps,
  secondryButtonProps,
  textLinkProps,
  channel,
  closeModal,
  primaryButtonDataTestId,
  secondryButtonDataTestId,
}) => {
  const intl = useIntl();

  return (
    <ConnectModalWrapper
      dataTestId="connection_success_modal2"
      isOpen
      closeModal={closeModal}
      stepText={intl.formatMessage(
        {
          id: I18nKey.SETUP_FLYWHEEL_STEPS_TEXT_WITH_TOTAL,
        },
        {
          number: isNextStepPresent ? 1 : 2,
          total: 2,
        }
      )}
      headerText={
        channel === FlywheelSalesChannel.Walmart
          ? intl.formatMessage({
              id: I18nKey.CONNECT_ADS_MODAL_WALMART_HEADER_DATA,
            })
          : intl.formatMessage({
              id: I18nKey.CONNECT_ADS_MODAL_AMAZON_HEADER_DATA,
            })
      }
      headerChannelType={channel}
      content={
        <ModalContent isNextStepPresent={isNextStepPresent} channel={channel} />
      }
      showFooterSeparator
      footer={
        <ModalFooter
          primaryButtonProps={primaryButtonProps}
          secondryButtonProps={secondryButtonProps}
          primaryButtonDataTestId={primaryButtonDataTestId}
          secondryButtonDataTestId={secondryButtonDataTestId}
        />
      }
    />
  );
};

export interface ConnectionSuccessModalContentProps {
  readonly isNextStepPresent?: boolean;
  readonly channel?: FlywheelSalesChannel;
}

const ModalContent: React.FC<ConnectionSuccessModalContentProps> = ({
  isNextStepPresent,
  channel,
}) => {
  const intl = useIntl();

  const [SUCCESS_HEADER] = [I18nKey.AD_OPTIMIZER_WIZARD_SUCCESS_TITLE].map(
    (id) =>
      intl.formatMessage(
        { id },
        {
          channel: channel
            ? intl.formatMessage({
                id: MAP_FLYWHEEL_SALES_CHANNEL_TO_I18NKEY[channel],
              })
            : '',
        }
      )
  );

  return (
    <>
      <div className="flex flex-col justify-content gap-24 pb-16 grow basis-0 shrink-0">
        <div className="flex flex-col justify-center items-center grow basis-0 shrink-0 gap-12">
          <StepsComplete completed={isNextStepPresent ? 1 : 2} total={2} />
          <Typography
            size={TypographySize.xl}
            lineHeight={TypographyLineHeight.tight}
            weight={TypographyWeight.semibold}
          >
            {SUCCESS_HEADER}
          </Typography>
        </div>
        {isNextStepPresent && (
          <NeedHelp
            url={
              isNextStepPresent
                ? INTERCOM_AMAZON_CONNECT_ACCOUNT_HELP_ALREADY_CONNECTED
                : INTERCOM_AMAZON_CONNECT_ACCOUNT_HELP
            }
            dataTestId="connection_success_modal2"
            channel={channel}
          />
        )}
      </div>
    </>
  );
};

export interface ConnectionSuccessModalFooterProps {
  readonly primaryButtonProps?: ButtonProps;
  readonly secondryButtonProps?: ButtonProps;
  readonly primaryButtonDataTestId?: string;
  readonly secondryButtonDataTestId?: string;
  readonly textLinkProps?: TextLinkProps;
}

const ModalFooter: React.FC<ConnectionSuccessModalFooterProps> = ({
  primaryButtonProps,
  secondryButtonProps,
  primaryButtonDataTestId,
  secondryButtonDataTestId,
  textLinkProps,
}) => {
  return (
    <div
      className={classNames('flex flex-row', {
        'justify-between': primaryButtonProps && secondryButtonProps,
        'justify-start': !primaryButtonProps && secondryButtonProps,
        'justify-end': primaryButtonProps && !secondryButtonProps,
      })}
    >
      {secondryButtonProps && (
        <div
          className={classNames({
            'text-left': primaryButtonProps,
            'text-center': !primaryButtonProps,
          })}
        >
          <Button
            label={secondryButtonProps.label}
            size={ButtonSize.Large}
            variant={
              secondryButtonProps.variant || ButtonVariant.BlackAndWhiteBorder
            }
            onClick={() => secondryButtonProps.onClick()}
            dataTestId={secondryButtonDataTestId}
          />
        </div>
      )}
      {textLinkProps && <TextLink {...textLinkProps} />}
      {primaryButtonProps && (
        <div
          className={classNames({
            'text-right': secondryButtonProps,
            'text-center': !secondryButtonProps,
          })}
        >
          <Button
            label={primaryButtonProps.label}
            size={ButtonSize.Large}
            variant={primaryButtonProps.variant || ButtonVariant.Primary}
            onClick={() => primaryButtonProps.onClick()}
            dataTestId={primaryButtonDataTestId}
          />
        </div>
      )}
    </div>
  );
};
ConnectionSuccessModal.displayName = 'ConnectionSuccessModal';
