import { Factory } from 'fishery';
import { UnsoldInventoryDetailsData } from '../../../lib/types/SKUSharedTypes';
import { CurrencyCode } from '../../utilities/currency';

export const UnsoldInventoryDataResponse = Factory.define<
  UnsoldInventoryDetailsData[]
>(() => [
  {
    merchantCountryId: '01cc0972-96e2-4cee-9f17-10fa9453c7da',
    skuDetailsFields: {
      extItemId: 'B00ISAPPLI',
      sku: 'S6-UJM9-A3QC',
      name: 'SKU test name 1',
      imageUrl: 'https://m.media-amazon.com/images/I/71iWVq0hEJL.jpg',
      salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
      productCatalogId: 'TEST_PRODUCT_CATALOG_ID',
      extItemDetail: {
        extItemId: 'B00ISAPPLI',
        extItemType: 'ASIN',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        salesChannelName: 'amazon',
      },
    },
    performance: {
      inventoryValue: {
        amount: 1250,
        currency: CurrencyCode.USD,
      },
      daysOfInventory: 366,
      inventorySalesRatio: 0.5,
      inventoryQuantity: 245,
      sellThroughRate: 0.21,
      rateOfSale: 0.2,
      campaignCount: true,
      inventoryAge: {
        invAge0To90Days: 12,
        invAge91To180Days: 25,
        invAge181To270Days: 37,
        invAge271To365Days: 8,
        invAge365PlusDays: 122,
      },
    },
    productCatalogId: '11cc0972-96e2-4cee-9f17-10fa9453c7db',
    productGroupId: '21cc0972-96e2-4cee-9f17-10fa9453c7dc',
  },
  {
    merchantCountryId: '01cc0972-96e2-4cee-9f17-10fa9453c7da',
    skuDetailsFields: {
      extItemId: 'B00ISAPPLI',
      sku: 'S6-UJM9-A3QC',
      name: 'SKU test name 1',
      imageUrl: 'https://m.media-amazon.com/images/I/71iWVq0hEJL.jpg',
      salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
      productCatalogId: 'TEST_PRODUCT_CATALOG_ID',
      extItemDetail: {
        extItemId: 'B00ISAPPLI',
        extItemType: 'ASIN',
        salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
        salesChannelName: 'amazon',
      },
    },
    performance: {
      inventoryAge: {},
    },
    productCatalogId: '11cc0972-96e2-4cee-9f17-10fa9453c7db',
    productGroupId: '21cc0972-96e2-4cee-9f17-10fa9453c7dc',
  },
]);
