import { DateTime } from 'luxon';

import {
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makePercentColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { ADGROUPS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = (
  props
) =>
  makePercentColumn<AdGroupDetails>(
    ({ adGroupDetails: { startDate }, adGroupPerformance: { acosTotal } }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        Number(acosTotal)
      );

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const acosColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.ACOS,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ACOS,
  RowCellElement,
  gridColumnWidth: '100px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const acosFilter = createPercentDataFieldFilter(
  ADGROUPS_API_COLUMN_NAME.ACOS,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_ACOS,
  isValidNumber()
);
