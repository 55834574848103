import { PaginatedResult } from '../clients/types';
import { PaymentPlanType } from './Billing';

export enum FlywheelSalesChannel {
  Amazon = 'amazon',
  Walmart = 'walmart',
}

export interface UserDetails {
  id: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  accountPermissions: AccountPermission[];
  lookerRwPermissions?: string[];
}

export type UserResponseType = {
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    state: UserStatus;
    notificationsEnabled: boolean;
  };
  role: {
    role: Role;
    id: string;
  };
};

export interface UserCreateRequestType {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly roleId: string;
  readonly notificationsEnabled?: boolean;
}

export interface SalesChannelsResponseType {
  readonly salesChannels: Array<SalesChannel>;
}

export interface SalesChannel {
  readonly salesChannelId: string;
  readonly name: string;
  readonly merchantCountries: Array<MerchantCountry>;
}

export interface AllSalesChannel {
  readonly id: string;
  readonly name: string;
  readonly createdAt: string;
}

export interface MerchantsBySalesChannel {
  readonly flywheelSalesChannel: FlywheelSalesChannel;
  readonly merchants: SalesChannelData[];
}

export enum MerchantType {
  Seller = 'seller',
  Vendor = 'vendor',
}

export enum WalmartSellerType {
  DSV = 'DSV',
  Warehouse = 'Warehouse',
}
export interface MerchantCountry {
  readonly merchantCountryId: string;
  readonly merchantName: string;
  readonly salesChannelId?: string;
  readonly merchantType: MerchantType;
  readonly country: string;
  readonly extMerchantId?: string;
}

export enum MerchantCardVariant {
  Default = 'Default',
  Completed = 'Completed',
  Disabled = 'Disabled',
}

export interface MerchantClaimResponseType {
  readonly merchantCountriesNotClaimed?: string[];
}

export interface MerchantCorrelationResponseType {
  readonly status: FAMConnectionStatus;
  readonly merchantCountries: Array<MerchantCountries>;
  readonly errorMessage?: string;
}

export interface MerchantCountries {
  countryCode: string;
  extMerchantCountryId: string;
  extMerchantId: string;
  merchantCountryId: string;
  merchantType: string;
  owningAccount: OwningAccount | null;
}

export interface OwningAccount {
  companyName: string;
  id: string;
  onboardedAt: Date | string; // TODO: remove when FD-681 has been incorporated
}

export type UserFetchedResponseType = UserResponseType | null;

export interface AccountPermission {
  account: Account;
  role: Role;
  subscriptionPermissions: SubscriptionPermission[];
}

export interface ChannelAuthenticationTimes {
  firstAuthenticatedAt: string;
  salesChannelId: string;
}

// TODO: the timestamps should probably be dates rather than strings
export interface Account {
  id: string;
  companyName: string;
  onboardedAt: string;
  freeTrialStartedAt?: string;
  freeTrialEndsAt?: string;
  accountType: PaymentPlanType;
  lockedAt?: string;
  // TODO: remove this field https://teikametrics.atlassian.net/browse/PS-1609
  isManaged: boolean;
  enforcedAt?: string;
  channelAuthenticationTimes?: ChannelAuthenticationTimes[];
  setupDismissed?: string;
  creationMethod?: string;
  parentId?: string;
}

export interface ChildAccount {
  account: Account;
  createdAt: string;
}

export interface ChildAccounts {
  childAccounts: ChildAccount[];
}

/**
 * @see https://github.com/teikametrics/flywheel-account-model/blob/master/flywheel_account_model/models/role.py
 */
export enum Role {
  ACCOUNT_OWNER = 'account_owner',
  ADMIN = 'admin',
  ANALYST = 'analyst', // NOTE: This role is not implemented as of 2021-03-03
  EDITOR = 'editor',
  VIEW_ONLY = 'viewer',
}

/**
 * @see https://github.com/teikametrics/flywheel-account-model/blob/master/flywheel_account_model/models/user_account_state.py
 */
export enum UserStatus {
  Expired = 'expired', // 'Invitation expired',
  InviteSent = 'pending', // 'Invitation sent',
  Verified = 'active', // 'Verified',
}

export enum CreationMethod {
  STANDARD = 'standard',
  MARKETING = 'marketing',
  SALES = 'sales',
}

export interface SubscriptionPermission {
  merchantCountries: string[];
  subscription: Subscription;
}

export interface Subscription {
  accountId: string;
  productVertical: ProductVertical;
}

// TODO: Use PRODUCT_SUBSCRIPTIONS strong type
export interface ProductVertical {
  id: string;
  name: string;
}

// TODO: remove AdConnectionStatus and ProductConnectionStatus so we can
// standardize statuses in https://teikametrics.atlassian.net/browse/FD-714

/**
 * See: https://github.com/teikametrics/flywheel-account-model/blob/master/flywheel_account_model/models/channel_api_connection.py#L15
 */
/* Changed from AdConnectionStatus to FAMConnectionStatus to be in sync with the enum
 * that the backend is using since this enum used for product and ads
 */

/**
 * For more detail information regarding this FAMConnectionStatus enum please read the details below:
 * ApiConnectionStatus(Enum) from backend is located here  flywheel_account_model/models/channel_api_connection.py:16
 * Connection status combinations: Onboarding Page Truth Table -->
 * https://docs.google.com/spreadsheets/d/1pW9xfaaK7d4AVborEFc0nUtMCLQ2PNoTUvsRgY2WymU/edit#gid=0
 * */
export enum FAMConnectionStatus {
  Processing = 'PendingUser', // User sent email
  Syncing = 'PendingVerify', // Data sent to Channel integration to get merchant countries
  PendingWalmart = 'PendingWalmart', // Waiting on walmart to give us access
  Active = 'Active', // Connection succeeded
  Inactive = 'Inactive', // Permissions to the API are revoked
  Failed = 'Failed', // Connection failed
}

export enum ProductConnectionStatus {
  Syncing = 'Syncing',
  Connected = 'Connected',
  Error = 'Error',
}

export enum ConnectionStatus {
  Syncing = 'Syncing',
  Processing = 'Processing',
  Connected = 'Connected',
  ConnectedNoData = 'ConnectedNoData',
  NotConnected = 'NotConnected',
  Failed = 'Failed',
  Validating = 'Validating',
  Active = 'Active', // TODO: Can I add this
}

export type SalesChannelData = {
  readonly id: string;
  readonly name: string;
  readonly country: string;
  readonly extMerchantId: string;
  readonly aoEnabled: boolean;
  readonly aiEnabled: boolean;
  readonly adConnectionStatus: string;
  readonly productConnectionStatus: string;
  readonly advertisingId?: string;
  readonly extMerchantType?: string;
  readonly updatedAt: Date;
  readonly merchantId?: string;
  readonly merchantType?: string;
  readonly salesChannelId?: string;
};

export interface SalesChannelUpdateData {
  readonly name?: string;
  readonly aoEnabled?: boolean;
  readonly aiEnabled?: boolean;
}

export type AccountSalesChannelPaginatedResult =
  PaginatedResult<SalesChannelData>;

export interface SalesChannelResponse {
  readonly filteredElements: number;
  readonly elements: SalesChannelData[];
}

export interface AmazonAdsConnectResult {
  readonly id: string;
}

export interface WalmartProductConnectResult {
  readonly id: string;
}
export interface WalmartProductConnectResult1P {
  readonly statusCode: string;
  readonly correlationId: string;
}

export enum DistributorAction {
  Added = 'ADDED',
  Removed = 'REMOVED',
}

export interface ProductDataSyncRequestType {
  readonly id: string; //For both Customer ID and Client ID
  readonly sellerId?: string;
  readonly secretKey: string;
  readonly api: string; //Indicates whether 1P ro 3P Seller Type
  readonly country: string;
  readonly supplierId?: string;
  readonly distributorIds?: string[];
  readonly distributorAction?: DistributorAction;
}

export interface ProductDataSyncRequestType1P {
  readonly clientId: string; //For both Customer ID and Client ID
  readonly clientSecret: string;
  readonly sellerType: WalmartSellerType;
  readonly vendorId: string;
  readonly country?: string;
  readonly supplierId?: string;
  readonly distributorIds?: string[];
  readonly distributorAction?: DistributorAction;
  readonly extAdvertiserId?: string;
}

export interface ProductSubscriptionEventResponse {
  readonly accountId: string;
  readonly productVerticalId: string;
  readonly accountEvents: AccountEvent[];
  readonly merchantCountryEvents: MerchantCountryEvent[];
}

export interface AccountEvent {
  readonly action: SubscriptionActionType;
  readonly date: string;
}

export interface MerchantCountryEvent extends AccountEvent {
  readonly merchantCountryId: string;
}

export enum SubscriptionActionType {
  Created = 'Created',
  Deleted = 'Deleted',
}

export interface LookerEmbedUrlResponse {
  readonly url: string;
}

export enum EntityType {
  Ads = 'ads',
  Products = 'products',
}
export interface SalesChannelLocationState {
  readonly entity?: EntityType;
}

export interface CreateLookerReportRequestBody {
  readonly date: string;
  readonly numberOfDays: number;
}

export enum ReportState {
  Pending = 'Pending',
  Complete = 'Complete',
  Failed = 'Failed',
}

export interface LookerReportResponseBody {
  readonly accountId: string;
  readonly createdAt: string;
  readonly createdBy: string;
  readonly dashboardId: string;
  readonly id: string;
  readonly renderTaskId: string;
  readonly reportDays: number;
  readonly reportFormat: string;
  readonly reportStartDate: string;
  readonly state: ReportState;
  readonly updatedAt: string;
}

export interface DownloadLookerReportApiProps {
  readonly accountId: string;
  readonly reportId: string;
}

export interface AllLookerReportsResponseBody {
  readonly reports: LookerReportResponseBody[];
}

export interface WalmartSellerProductsRequestBody {
  country?: string;
  extAdvertiserId: string;
  oauthCode: string;
  sellerId: string;
  redirectUri?: string;
}

export interface WalmartSellerProductsResponseType {
  readonly status: number;
  readonly correlationId: string;
}

export type MerchantCountryIngestionData = {
  ingestions: {
    [EntityType.Products]?: MerchantCountryIngestionState;
    [EntityType.Ads]?: MerchantCountryIngestionState;
  };
};

export type DataSyncStatusResponseType = Record<
  string,
  MerchantCountryIngestionData
>;

export enum IngestionStatus {
  NoData = 'NO_DATA',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
}

export interface MerchantCountryIngestionState {
  readonly updatedAt: string;
  readonly merchantCountryId: string;
  readonly ingestionType: EntityType;
  readonly ingestionStatus: IngestionStatus;
}

export interface LatestConnection {
  readonly accountId: string;
  readonly effected: boolean;
  readonly extMerchantId: string;
  readonly latestConnectionDate: string;
  readonly merchantCountryId: string;
}
export interface AccountLeadAnalyst {
  readonly salesChannelId: string;
  readonly userId: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
}
export interface AccountLeadAnalysts {
  readonly leadAnalysts: AccountLeadAnalyst[];
}

export enum GenerateReportState {
  NotStarted = 'NotStarted',
  Downloading = 'Downloading',
  ReadyToDownload = 'ReadyToDownload',
}

export interface BIDashboardsResponseBody {
  dashboards: BIDashboards[];
}

export interface BICustomDashboardsResponseBody {
  readonly dashboards: BICustomDashboards[];
  readonly dataDictionaryUrl: string;
  readonly dataDictionaryUnsignedUrl: string;
}

export interface BIDashboards {
  dashboardId: string;
  description: string;
  thumbnailUrl: string;
  title: string;
  dashboardUrl: string;
}
export interface BICustomDashboards extends BIDashboards {
  readonly dashboardType: string;
  readonly updatedAt: string;
}
export interface NotificationAlert {
  displayName: string;
  key: string;
  enabled: boolean;
  channel: NotificationChannel;
  subscriptionId?: string;
  visible?: boolean;
  /**
   * To identify IO module notifications for segregation.
   * And also to accomodate the subscription description to view in UI
   */
  isIoModule?: boolean;
  description?: string;
}

export interface NotificationSettingsResponse {
  enabled: boolean;
  alerts: NotificationAlert[];
}

export interface NotificationAccountSettingsResponse {
  accountNotificationSettings: NotificationAlert[];
}

export interface WalmartAdsConnectRequest {
  advertiserId: string;
  sellerId?: string;
  supplierId?: string;
}

export interface WalmartAdsConnectResponse {
  readonly id: string;
}

export interface KnockTokenResponse {
  knockToken: string;
}

export interface MetadataResponse {
  readonly accountId: string;
  readonly tag: string;
  readonly createdAt: string;
  readonly value: string;
  readonly updatedAt?: string;
}

export enum MetadataTags {
  UpsellInventoryModule = 'upsell_inventory_module',
  HideFreeTrialContent = 'hide_free_trial_content',
}

export enum NotificationChannel {
  Email = 'email',
  Slack = 'slack',
}

export interface NotificationAlertUpdateRequestPayload {
  readonly channelType: NotificationChannel;
  readonly displayName: string;
  readonly enabled: boolean;
}

export enum DashboardType {
  PERSONAL = 'personal',
  LEGACY = 'legacy',
  BENCHMARK = 'benchmark',
  CUSTOM = 'custom',
  TEMPLATE = 'template',
  AMC_TEMPLATE = 'amc_template',
  AMC_PERSONAL = 'amc_personal',
  AMC_CUSTOM = 'amc_custom',
  AMC_TEMPLATE_RELEASE_2 = 'amc_template_release_2',
}

export interface BIReportLocationState {
  readonly settingsTab?: number;
  readonly dashboardTab?: number;
  readonly fromAnalystsView?: boolean;
  readonly selectedSidebarOption?: BISidebarOption;
  readonly isAMCDashboard?: boolean;
}

export enum BIFolderType {
  PERSONAL_FOLDER = 'personal_folder',
  ACCOUNT_FOLDER = 'account_folder',
}

export enum BISidebarOption {
  TEIKAMETRICS_DASHBOARDS_SETTINGS = 'teikametrics_settings',
  TEIKAMETRICS_DASHBOARDS = 'teikametrics',
  AMC_DASHBOARDS = 'amc',
  AMC_DASHBOARDS_SETTINGS = 'amc_settings',
}

export interface BIAmcInstance {
  readonly amcMerchantCountryId: string;
  readonly countryCode: string;
  readonly customerName: string;
  readonly requestTriggeredBy: string;
}

export interface BIAmcInstanceResponse {
  readonly amcInstances: BIAmcInstance[];
}
