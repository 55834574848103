import {
  Alignment,
  Badge,
  BadgeType,
  ChartGraphLinearIcon,
  ChartTime,
  CompassEmptyState,
  CompassLinearIcon,
  DotsCircleIcon,
  EventLogPoint,
  KPICardV2HeaderType,
  KPICardV2Props,
  TableEmptyState as LoadingState,
  MetricCardV2CardState,
  MetricCardV2Props,
  NumberFormat,
  Placement,
  SelectV2OptionProps,
  Theme,
} from '@teikametrics/tm-design-system';
import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';
import { talkToUs } from '../../../../../lib/utilities/intercom';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import { CampaignsAdGroupsSelectOptionProps } from '../../../../../components/ViewTrendsSlideover/CampaignSelect';
import {
  AOKPIMetrics,
  AdLevel,
  CampaignResponse,
  DateRange,
  GraphItem,
  HeroMetricType,
  HeroMetricsData,
  Metric,
  SALES_CHANNEL_TO_PERFORMANCE_FIELD_MAPPER_V2,
  ViewTrendsGraphDataResponse,
} from '../../../../../lib/types/AOSharedTypes';
import { SeasonalEvent } from '../../../../../lib/types/CompassSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TargetSegment } from '../../../../../lib/types/KeywordAction';
import { CampaignTargetingType } from '../../../containers/adsManager/types';
import {
  calculateDelta,
  getCurrentValueContent,
  getPillText,
  getStatus,
  getTooltipContentForMetric,
} from '../../utils';

export const CONVERTED_DATE_FORMAT_FOR_GRAPH = 'MMM dd';
export const TOOLTIP_DATE_FORMAT_FOR_GRAPH = 'MMM dd, yyyy';

export const AOKPIMETRICS_TO_I18NKEY: Record<AOKPIMetrics, I18nKey> = {
  [AOKPIMetrics.ACoS]: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_ACOS,
  [AOKPIMetrics.ACoSDirect]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ACOS_DIRECT,
  [AOKPIMetrics.AdConversions]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_CONVERSIONS,
  [AOKPIMetrics.AdSales]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SALES_TOTAL,
  [AOKPIMetrics.AdSalesDirect]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SALES_DIRECT,
  [AOKPIMetrics.AdSpend]: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SPEND,
  [AOKPIMetrics.ClickThroughRate]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_CTR,
  [AOKPIMetrics.Clicks]: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CLICKS,
  [AOKPIMetrics.ConversionRate]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_CONVERSION_RATE,
  [AOKPIMetrics.CostPerClick]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_AVG_CPC,
  [AOKPIMetrics.Impressions]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_IMPRESSIONS,
  [AOKPIMetrics.ROAS]: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_ROAS,
  [AOKPIMetrics.ROASDirect]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ROAS_DIRECT,
  [AOKPIMetrics.TotalSales]: I18nKey.AO_DASHBOARD_TABLE_COLUMN_TOTAL_SALES,
  [AOKPIMetrics.UnitsSold]:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_UNITS_SOLD,
};

export const AOKPIMETRICS_TO_HERO_METRIC_TYPE: Record<
  AOKPIMetrics,
  HeroMetricType
> = {
  [AOKPIMetrics.ACoS]: HeroMetricType.Percent,
  [AOKPIMetrics.ACoSDirect]: HeroMetricType.Percent,
  [AOKPIMetrics.AdConversions]: HeroMetricType.Numeric,
  [AOKPIMetrics.AdSales]: HeroMetricType.Money,
  [AOKPIMetrics.AdSalesDirect]: HeroMetricType.Money,
  [AOKPIMetrics.AdSpend]: HeroMetricType.Money,
  [AOKPIMetrics.ClickThroughRate]: HeroMetricType.Percent,
  [AOKPIMetrics.Clicks]: HeroMetricType.Numeric,
  [AOKPIMetrics.ConversionRate]: HeroMetricType.Percent,
  [AOKPIMetrics.CostPerClick]: HeroMetricType.Money,
  [AOKPIMetrics.Impressions]: HeroMetricType.Numeric,
  [AOKPIMetrics.ROAS]: HeroMetricType.Numeric,
  [AOKPIMetrics.ROASDirect]: HeroMetricType.Numeric,
  [AOKPIMetrics.TotalSales]: HeroMetricType.Money,
  [AOKPIMetrics.UnitsSold]: HeroMetricType.Numeric,
};

export const AOKPIMETRICS_TO_DECIMAL_POINT_LIMIT: Record<AOKPIMetrics, number> =
  {
    [AOKPIMetrics.ACoS]: 1,
    [AOKPIMetrics.ACoSDirect]: 1,
    [AOKPIMetrics.AdConversions]: 0,
    [AOKPIMetrics.AdSales]: 2,
    [AOKPIMetrics.AdSalesDirect]: 2,
    [AOKPIMetrics.AdSpend]: 2,
    [AOKPIMetrics.ClickThroughRate]: 1,
    [AOKPIMetrics.Clicks]: 0,
    [AOKPIMetrics.ConversionRate]: 1,
    [AOKPIMetrics.CostPerClick]: 2,
    [AOKPIMetrics.Impressions]: 0,
    [AOKPIMetrics.ROAS]: 1,
    [AOKPIMetrics.ROASDirect]: 1,
    [AOKPIMetrics.TotalSales]: 2,
    [AOKPIMetrics.UnitsSold]: 0,
  };

export const getXAxisLabels = (
  intl: IntlShape,
  dates: string[],
  chartTime: ChartTime,
  includeYearInLabels: boolean
) => {
  if (dates.length)
    if (chartTime === ChartTime.Weekly) {
      return dates.map((date): string => {
        return intl.formatMessage(
          {
            id: I18nKey.COMPASS_DASHBOARD_CHART_MINI_METRIC_WEEKLY_DATE_FORMAT,
          },
          {
            startDateOfWeek: DateTime.fromISO(date)
              .startOf('week')
              .toFormat(CONVERTED_DATE_FORMAT_FOR_GRAPH),
            endDateOfWeek: DateTime.fromISO(date)
              .endOf('week')
              .toFormat(CONVERTED_DATE_FORMAT_FOR_GRAPH),
          }
        );
      });
    }
  const requiredDailyFormat = includeYearInLabels
    ? TOOLTIP_DATE_FORMAT_FOR_GRAPH
    : CONVERTED_DATE_FORMAT_FOR_GRAPH;
  return dates.map((date) => {
    return DateTime.fromISO(date).toFormat(requiredDailyFormat);
  });
};

export const getMetricsOptions = (
  intl: IntlShape,
  selectedMetrics: AOKPIMetrics[]
): SelectV2OptionProps<AOKPIMetrics>[] => {
  const metricKeys =
    SALES_CHANNEL_TO_PERFORMANCE_FIELD_MAPPER_V2[FlywheelSalesChannel.Amazon];

  return metricKeys.map((metricKey) => ({
    label: AOKPIMETRICS_TO_I18NKEY[metricKey]
      ? intl.formatMessage({
          id: AOKPIMETRICS_TO_I18NKEY[metricKey],
        })
      : AOKPIMETRICS_TO_I18NKEY[metricKey],
    value: metricKey,
    disabled: selectedMetrics.includes(metricKey),
  }));
};

export const getCampaignSelectOptions = (
  intl: IntlShape,
  campaignResponse: CampaignResponse,
  targetingType?: CampaignTargetingType
): CampaignsAdGroupsSelectOptionProps[] => {
  const options: CampaignsAdGroupsSelectOptionProps[] = [];

  options.push({
    campaignId: campaignResponse.campaignId,
    campaignName: campaignResponse.campaignName,
    adLevel: AdLevel.Campaigns,
    targetingType,
    status: campaignResponse.status,
    statusString: capitalize(campaignResponse.status),
  });

  options.push(
    ...campaignResponse.adGroups.map(
      (adGroup): CampaignsAdGroupsSelectOptionProps => ({
        adGroupId: adGroup.adGroupId,
        campaignId: campaignResponse.campaignId,
        campaignName: campaignResponse.campaignName,
        adGroupName: adGroup.name,
        adLevel: AdLevel.AdGroups,
        status: adGroup.status.toLowerCase(),
        statusString: adGroup.status,
        targetingType: campaignResponse.targetingType,
        targetSegments:
          adGroup.targetSegments && adGroup.targetSegments.length > 0
            ? adGroup.targetSegments
            : [TargetSegment.Generic],
        tags:
          adGroup.targetSegments && adGroup.targetSegments.length > 0
            ? adGroup.targetSegments.map((targetSegment) =>
                getBadgeType(targetSegment)
              )
            : [<Badge badgeType={BadgeType.TagGeneric} />],
      })
    )
  );

  return options;
};

const getBadgeType = (targetSegment: string) => {
  switch (targetSegment) {
    case TargetSegment.Brand:
      return <Badge badgeType={BadgeType.TagBrand} />;
    case TargetSegment.Competitor:
      return <Badge badgeType={BadgeType.TagCompetitor} />;
    case TargetSegment.Generic:
    case TargetSegment.Unknown:
    default:
      return <Badge badgeType={BadgeType.TagGeneric} />;
  }
};

export const getKPICards = (
  intl: IntlShape,
  flywheelSalesChannel: FlywheelSalesChannel,
  selectedMetrics: AOKPIMetrics[],
  performanceMetricResponse: {
    readonly current?: HeroMetricsData;
    readonly previous?: HeroMetricsData;
  },
  isLoading: boolean,
  selectedMetricIndex: number,
  showPreviousData?: boolean
): KPICardV2Props[] => {
  return selectedMetrics.map((metric, index): KPICardV2Props => {
    const currentValue = performanceMetricResponse.current?.performanceMetrics[
      metric
    ] as Metric;
    const previousValue = performanceMetricResponse.previous
      ?.performanceMetrics[metric] as Metric;
    const content = getCurrentValueContent(
      intl,
      AOKPIMETRICS_TO_HERO_METRIC_TYPE[metric],
      metric,
      currentValue
    );
    const delta = calculateDelta(
      AOKPIMETRICS_TO_HERO_METRIC_TYPE[metric],
      metric,
      currentValue,
      previousValue
    );
    const status = getStatus(delta);
    const state = isLoading
      ? MetricCardV2CardState.Loading
      : MetricCardV2CardState.Success;
    return {
      content,
      cardTrend: {
        pillText: getPillText(intl, delta),
        status: status,
      },
      isActive: index === selectedMetricIndex,
      dataTestId: 'kpi_v2_card',
      previousValue: showPreviousData
        ? getCurrentValueContent(
            intl,
            AOKPIMETRICS_TO_HERO_METRIC_TYPE[metric],
            metric,
            previousValue
          )
        : undefined,
      state,
      primaryMetric: true,
      isFooterActive: showPreviousData,
      previousDaysLabel: intl.formatMessage({
        id: I18nKey.GENERIC_PREV,
      }),
      headerType: KPICardV2HeaderType.Select,
      options: getMetricsOptions(intl, selectedMetrics),
      header: metric,
      tooltipProps: {
        content: getTooltipContentForMetric(metric),
        position: {
          placement: Placement.Bottom,
          alignment: Alignment.Center,
          placementOffsetInPixels: 8,
        },
        theme: Theme.Dark,
      },
      isNegativeUpTrend:
        metric === AOKPIMetrics.ACoS || metric === AOKPIMetrics.CostPerClick,
      showInfoIcon: true,
      showHideActionTooltip: false,
      className: 'w-252',
    };
  });
};
export const getWeeklyBuckets = (
  events: SeasonalEvent[],
  selectedDateRange: DateRange
) => {
  const startDate = selectedDateRange.initialStartDate;
  const endDate = selectedDateRange.initialEndDate;

  const weeklyBuckets = [];
  let currentWeek = [];

  for (let date = startDate; date <= endDate; date = date.plus({ days: 1 })) {
    const currentEvents = events.filter(
      (event) =>
        DateTime.fromISO(event.eventDate).toISODate() === date.toISODate()
    );
    if (currentEvents) {
      currentWeek.push(...currentEvents);
    }

    if (date.weekday === 7 && currentWeek.length > 0) {
      weeklyBuckets.push(currentWeek);
      currentWeek = [];
    }
  }

  if (currentWeek.length > 0) {
    weeklyBuckets.push(currentWeek);
  }
  return weeklyBuckets;
};

export const getDailyBuckets = (
  events: SeasonalEvent[],
  selectedDateRange: DateRange
) => {
  const startDate = selectedDateRange.initialStartDate;
  const endDate = selectedDateRange.initialEndDate;

  const dailyBuckets = [];
  let currentDay = [];

  for (let date = startDate; date <= endDate; date = date.plus({ days: 1 })) {
    const currentEvents = events.filter(
      (event) =>
        DateTime.fromISO(event.eventDate).toISODate() === date.toISODate()
    );
    if (currentEvents) {
      currentDay.push(...currentEvents);
    }

    dailyBuckets.push(currentDay);
    currentDay = [];
  }

  return dailyBuckets;
};

export const getSeasonalEventsData = (
  selectedDateRange: DateRange,
  events: SeasonalEvent[],
  dataPointType: ChartTime,
  intl: IntlShape
) => {
  if (dataPointType === ChartTime.Weekly) {
    const weeklyBuckets = getWeeklyBuckets(events, selectedDateRange);
    return getLogsPlotPointsForWeeklyChart(
      weeklyBuckets,
      selectedDateRange.initialStartDate,
      intl
    );
  }

  if (dataPointType === ChartTime.Daily) {
    const dailyBuckets = getDailyBuckets(events, selectedDateRange);
    return getLogsPlotPointsForDailyChart(dailyBuckets, intl);
  }
};

const getLogsPlotPointsForWeeklyChart = (
  weeklyBuckets: SeasonalEvent[][],
  startDate: DateTime,
  intl: IntlShape
) => {
  const eventLogs: EventLogPoint[] = [];

  weeklyBuckets.forEach((bucket, i) => {
    const firstEvent = bucket[0];
    const firstEventDate = DateTime.fromISO(firstEvent.eventDate);
    const weeksDiff = firstEventDate
      .startOf('week')
      .diff(startDate.startOf('week'), 'weeks').weeks;

    const xPoint = Number(weeksDiff.toFixed(0));

    eventLogs.push(
      getEventLogsPointData(xPoint, bucket, firstEventDate, intl, false)
    );
  });

  return eventLogs;
};

const getLogsPlotPointsForDailyChart = (
  dailyBuckets: SeasonalEvent[][],
  intl: IntlShape
) => {
  const eventLogs: EventLogPoint[] = [];

  dailyBuckets.forEach((bucket, index) => {
    if (bucket.length) {
      const firstEventDate = DateTime.fromISO(bucket[0].eventDate);
      eventLogs.push(
        getEventLogsPointData(index, bucket, firstEventDate, intl, true)
      );
    }
  });

  return eventLogs;
};

const NINE_PLUS = '9+';

const getEventLogsPointData = (
  xPoint: number,
  bucket: SeasonalEvent[],
  date: DateTime,
  intl: IntlShape,
  isDailyEvents?: boolean
) => ({
  x: xPoint,
  countText: bucket.length > 9 ? NINE_PLUS : String(bucket.length),
  tooltip: {
    categories: { 'Seasonal Event': bucket.map((e) => e.name) },
    dateRange: {
      startDate: isDailyEvents ? date : date.startOf('week'),
      endDate: isDailyEvents ? undefined : date.endOf('week'),
    },
    secondryTooltipContent: intl.formatMessage({
      id: I18nKey.PRODUCTS_EVENT_LOG_SECONDRY_POPUP_TEXT,
    }),
  },
});

export const getInterval = (xAxisCount: number) => {
  const r = xAxisCount % 15;
  const interval = (xAxisCount - r) / 15;

  return interval;
};

export const getShowLoading = (isLoading: boolean) => {
  return (
    <div
      className="flex flex-col w-full bg-grey-50
      h-280 items-center mt-16"
    >
      <div className="mt-40 w-2/4">
        {isLoading && (
          <LoadingState
            icon={DotsCircleIcon}
            className="bg-grey-50"
            iconClassName="text-magenta-500"
            titleI18nKey={I18nKey.COMPASS_TABS_LOADING_TEXT}
            descriptionI18nKey={I18nKey.COMPASS_TABS_LOADING_ADDITIONAL_TEXT}
          />
        )}
      </div>
    </div>
  );
};

export const isAllPerformanceMetricsEmpty = (
  chartTime: ChartTime,
  data?: ViewTrendsGraphDataResponse
) => {
  if (data?.daily && chartTime === ChartTime.Daily) {
    for (let key in data.daily) {
      if (data.daily[key] && key !== 'reportDate') {
        return false;
      }
    }
  }

  if (data?.weekly && chartTime === ChartTime.Weekly) {
    for (let key in data.weekly) {
      if (data.weekly[key] && key !== 'reportDate') {
        return false;
      }
    }
  }
  return true;
};

export const getErrorEmptyState = () => {
  return (
    <CompassEmptyState
      {...{
        titleI18nKey: I18nKey.COMPASS_TABS_ERROR_TEXT,
        descriptionI18nKey:
          I18nKey.COMPASS_TABS_ERROR_CONTACT_SUPPORT_DESCRIPTION,
        icon: ChartGraphLinearIcon,
        showPrimaryButton: true,
        primaryButtonI18nKey: I18nKey.COMPASS_TABS_ERROR_CONTACT_SUPPORT,
        onClickPrimaryButton: () => talkToUs(),
        dataTestId: '1com_business_contact_support',
      }}
    />
  );
};

export const getNoDataEmptyState = (handleButtonClick: () => void) => {
  return (
    <CompassEmptyState
      {...{
        titleI18nKey: I18nKey.COMPASS_CHART_NO_DATA_HEADER,
        descriptionI18nKey: I18nKey.COMPASS_CHART_NO_DATA_DESCRIPTION,
        icon: CompassLinearIcon,
        iconClassName: 'text-purple-500',
        showPrimaryButton: true,
        primaryButtonI18nKey: I18nKey.COMPASS_CHART_NO_DATA_CONNECT_PRODUCT,
        onClickPrimaryButton: () => handleButtonClick(),
        dataTestId: '1com_business_no_data',
      }}
    />
  );
};

export const getYAxisFormat = (
  selectedMetrics: AOKPIMetrics[],
  selectedMetricIndex: number
) => {
  switch (
    AOKPIMETRICS_TO_HERO_METRIC_TYPE[selectedMetrics[selectedMetricIndex]]
  ) {
    case HeroMetricType.Numeric:
      return NumberFormat.Number;
    case HeroMetricType.Percent:
      return NumberFormat.Percent;
    case HeroMetricType.Money:
    default:
      return NumberFormat.Currency;
  }
};

export const getCoordinateValue = (
  value: number,
  numberFormat: NumberFormat
) => {
  return numberFormat === NumberFormat.Percent ? value * 100 : value;
};

export const rotationXaxis = (ListData: string[] | number[]) => {
  if (ListData.length > 5) {
    return -45;
  }
  return 0;
};

export const hasSelectedMetricHaveData = (
  selectedMetrics: AOKPIMetrics[],
  selectedMetricIndex: number,
  data?: GraphItem[]
) => {
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i][selectedMetrics[selectedMetricIndex]]) {
        return true;
      }
    }
  }
  return false;
};

export const getYAxisMinValue = (options: MetricCardV2Props[]) => {
  let minValue = 0;
  options.forEach(({ data }) => {
    data?.forEach((value) => {
      value.data?.data?.forEach((_val: any) => {
        if (_val.y && minValue > _val.y) {
          minValue = _val.y;
        }
      });
    });
  });

  return minValue;
};
