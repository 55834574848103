import {
  ChartTime,
  Formula,
  FormulaType,
  MetricCardV2CardTrend,
  MetricCardV2Props,
  MetricCardV2Status,
  NumberFormat,
} from '@teikametrics/tm-design-system';
import {
  DecimalNumericColumn,
  HeroMetricType,
} from '../../../../../lib/types/AOSharedTypes';
import {
  MONETARY_FRACTION_DIGITS,
  NO_FRACTION_DIGITS,
  PERCENTAGE_FRACTION_DIGITS,
} from '../../../../../lib/types/CommonSharedTypes';
import { MoneyWithAmountInString } from '../../../../../lib/types/Money';
import flatten from 'lodash/flatten';
import { isUndefined } from 'lodash/fp';
import isNil from 'lodash/isNil';
import { FormattedMessage, IntlShape } from 'react-intl';
import {
  HomepageDataRequestResponse,
  TmIndexDataResponse,
  TmIndexResponse,
} from '../../..';
import {
  AggregationPeriodType,
  HomepageResponseMetricKeys,
  OverviewTimePeriod,
  PerformanceMetrics,
  SalesChannelTmIndexKeys,
} from '../../../../../lib/types/CompassSharedTypes';
import {
  FlywheelSalesChannel,
  MerchantCountry,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { MetricsData } from '../../../components/CustomizeMetricsSlideover';
import { ChartTrendMetricFormatSelector, CompassState } from '../../../types';
import { convertAmountToNumber } from '../../../utils';
import {
  getGatheringDataForRoas,
  getGatheringDataForTacos,
  getGatheringDataPoints,
  getGraphDataPoints,
  getGraphDataPointsForRoas,
  getGraphDataPointsTacos,
} from '../utils/business';
import {
  ACTIVE_TM_INDEX_METRICS,
  BLUE_500,
  DEFAULT_MAX_METRICS_VISIBLE,
  PURPLE_300,
  PURPLE_600,
  YELLOW_500,
} from './types';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../../lib/types/SalesChannels';
import { CurrencyCode } from '../../../../../lib/utilities/currency';

export const getDefaultMetricsConfiguration = (
  intl: IntlShape
): MetricsData[] => {
  const [
    ACOS,
    AD_SALES,
    AD_CONVERSIONS,
    AD_SPEND,
    AVERAGE_SALES_PRICE,
    CLICKS,
    CLICK_THROUGH_RATE,
    COST_PER_CLICK,
    CONVERSION_RATE,
    IMPRESSIONS,
    ROAS,
    UNITS_SOLD,
    TACOS,
    TOTAL_SALES,
  ] = [
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ACOS,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADSALES,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_AD_CONVERSIONS,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADSPEND,
    I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_AVERAGE_SALES_PRICE,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CLICKS,
    I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_CLICK_THROUGH_RATE,
    I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_COST_PER_CLICK,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CONVERSION_RATE,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_IMPRESSIONS,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ROAS,
    I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_UNITS_SOLD,
    I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_TACOS,
    I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_TOTAL_SALES,
  ].map((id) => intl.formatMessage({ id }));

  const commonMetrics = [
    {
      isChecked: false,
      label: ACOS,
      metricsId: HomepageResponseMetricKeys.ACoSTotal,
    },
    {
      isChecked: true,
      label: AD_SALES,
      metricsId: HomepageResponseMetricKeys.AdSales,
    },
    {
      isChecked: true,
      label: AD_SPEND,
      metricsId: HomepageResponseMetricKeys.AdSpend,
    },
    {
      isChecked: false,
      label: AD_CONVERSIONS,
      metricsId: HomepageResponseMetricKeys.AdConversions,
    },
    {
      isChecked: false,
      label: AVERAGE_SALES_PRICE,
      metricsId: HomepageResponseMetricKeys.AverageSalesPrice,
    },
    {
      isChecked: false,
      label: CLICKS,
      metricsId: HomepageResponseMetricKeys.Clicks,
    },
    {
      isChecked: false,
      label: CLICK_THROUGH_RATE,
      metricsId: HomepageResponseMetricKeys.ClickThroughRate,
    },
    {
      isChecked: false,
      label: COST_PER_CLICK,
      metricsId: HomepageResponseMetricKeys.CPC,
    },
    {
      isChecked: false,
      label: CONVERSION_RATE,
      metricsId: HomepageResponseMetricKeys.ConversionRate,
    },
    {
      isChecked: false,
      label: IMPRESSIONS,
      metricsId: HomepageResponseMetricKeys.Impressions,
    },
    {
      isChecked: true,
      label: ROAS,
      metricsId: HomepageResponseMetricKeys.Roas,
    },
    {
      isChecked: false,
      label: UNITS_SOLD,
      metricsId: HomepageResponseMetricKeys.UnitsSold,
    },
    {
      isChecked: true,
      label: TACOS,
      metricsId: HomepageResponseMetricKeys.Tacos,
    },
    {
      isChecked: true,
      label: TOTAL_SALES,
      metricsId: HomepageResponseMetricKeys.TotalSales,
    },
  ];

  return [...commonMetrics];
};

export const getSelectedMetricsId = (metrics: MetricsData[]) =>
  metrics
    .filter((metric) => metric.isChecked)
    .map((metric) => metric.metricsId);

export const hasExactlyMaxCheckedItems = (arr: MetricsData[]) =>
  arr.filter((metric) => metric.isChecked).length ===
  DEFAULT_MAX_METRICS_VISIBLE;

export const oneToMaxMetricsChecked = (arr: MetricsData[]) => {
  const checkedMetricsLength = arr.filter((metric) => metric.isChecked).length;
  return (
    checkedMetricsLength <= DEFAULT_MAX_METRICS_VISIBLE &&
    checkedMetricsLength > 0
  );
};

export const getCompassKPIMetricValue = (
  metricData: PerformanceMetrics,
  metricType: HomepageResponseMetricKeys
): number | MoneyWithAmountInString | undefined => {
  switch (metricType) {
    case HomepageResponseMetricKeys.ACoSTotal:
      return metricData?.acos;
    case HomepageResponseMetricKeys.DirectACoS:
      return metricData?.acosDirect;
    case HomepageResponseMetricKeys.AdSales:
      return metricData?.adSales;
    case HomepageResponseMetricKeys.DirectAdSales:
      return metricData?.adSalesDirect;
    case HomepageResponseMetricKeys.AdSpend:
      return metricData?.adSpend;
    case HomepageResponseMetricKeys.AdConversions:
      return metricData?.adConversions;
    case HomepageResponseMetricKeys.AverageSalesPrice:
      return metricData?.averageOrderPrice;
    case HomepageResponseMetricKeys.ConversionRate:
      return metricData?.conversionRate;
    case HomepageResponseMetricKeys.Clicks:
      return metricData?.clicks;
    case HomepageResponseMetricKeys.ClickThroughRate:
      return metricData?.clickThroughRate;
    case HomepageResponseMetricKeys.CPC:
      return metricData?.costPerClick;
    case HomepageResponseMetricKeys.Impressions:
      return metricData?.impressions;
    case HomepageResponseMetricKeys.Roas:
      return metricData?.roas;
    case HomepageResponseMetricKeys.DirectROAS:
      return metricData?.roasDirect;
    case HomepageResponseMetricKeys.TotalSales:
      return metricData?.totalSales;
    case HomepageResponseMetricKeys.UnitsSold:
      return metricData?.unitsSold;
    case HomepageResponseMetricKeys.Tacos:
      return metricData?.tacos;
  }
};

export const getHeroMetricType = (
  metricType: HomepageResponseMetricKeys
): HeroMetricType => {
  switch (metricType) {
    case HomepageResponseMetricKeys.AdSales:
    case HomepageResponseMetricKeys.DirectAdSales:
    case HomepageResponseMetricKeys.AdSpend:
    case HomepageResponseMetricKeys.AverageSalesPrice:
    case HomepageResponseMetricKeys.CPC:
    case HomepageResponseMetricKeys.TotalSales:
      return HeroMetricType.Money;
    case HomepageResponseMetricKeys.ACoSTotal:
    case HomepageResponseMetricKeys.DirectACoS:
    case HomepageResponseMetricKeys.ConversionRate:
    case HomepageResponseMetricKeys.ClickThroughRate:
    case HomepageResponseMetricKeys.Tacos:
      return HeroMetricType.Percent;
    case HomepageResponseMetricKeys.AdConversions:
    case HomepageResponseMetricKeys.Clicks:
    case HomepageResponseMetricKeys.Impressions:
    case HomepageResponseMetricKeys.UnitsSold:
    case HomepageResponseMetricKeys.Roas:
    case HomepageResponseMetricKeys.DirectROAS:
    default:
      return HeroMetricType.Numeric;
  }
};

export const getMetricValueFractions = (
  metricType: HomepageResponseMetricKeys
): HeroMetricType => {
  switch (metricType) {
    case HomepageResponseMetricKeys.AdSales:
    case HomepageResponseMetricKeys.DirectAdSales:
    case HomepageResponseMetricKeys.AdSpend:
    case HomepageResponseMetricKeys.AverageSalesPrice:
    case HomepageResponseMetricKeys.CPC:
    case HomepageResponseMetricKeys.TotalSales:
      return MONETARY_FRACTION_DIGITS;
    case HomepageResponseMetricKeys.ConversionRate:
    case HomepageResponseMetricKeys.ACoSTotal:
    case HomepageResponseMetricKeys.DirectACoS:
    case HomepageResponseMetricKeys.Roas:
    case HomepageResponseMetricKeys.DirectROAS:
    case HomepageResponseMetricKeys.ClickThroughRate:
    case HomepageResponseMetricKeys.Tacos:
      return PERCENTAGE_FRACTION_DIGITS;
    case HomepageResponseMetricKeys.AdConversions:
    case HomepageResponseMetricKeys.Clicks:
    case HomepageResponseMetricKeys.Impressions:
    case HomepageResponseMetricKeys.UnitsSold:
    default:
      return NO_FRACTION_DIGITS;
  }
};

export const getCurrentValueContent = (
  intl: IntlShape,
  heroMetricType: HeroMetricType,
  metricName: HomepageResponseMetricKeys,
  currentValue?: number | MoneyWithAmountInString
): string => {
  if (!isNil(currentValue)) {
    switch (heroMetricType) {
      case HeroMetricType.Money:
        const amount = convertAmountToNumber(currentValue);
        return intl.formatNumber(amount, {
          style: 'currency',
          currency: (currentValue as MoneyWithAmountInString).currency,
          minimumFractionDigits: MONETARY_FRACTION_DIGITS,
          maximumFractionDigits: MONETARY_FRACTION_DIGITS,
        });
      case HeroMetricType.Numeric:
        return intl.formatNumber(currentValue as number, {
          minimumFractionDigits: Object.keys(DecimalNumericColumn).includes(
            metricName
          )
            ? PERCENTAGE_FRACTION_DIGITS
            : 0,
          maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        });
      case HeroMetricType.Percent:
        return intl.formatNumber(currentValue as number, {
          style: 'percent',
          minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
          maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        });
      default:
        return '';
    }
  } else {
    return intl.formatMessage({
      id: I18nKey.GENERIC_EM_DASH,
    });
  }
};

export const getPillText = (intl: IntlShape, delta: number): string => {
  return `${intl.formatNumber(delta < 0 ? -1 * delta : delta, {
    style: 'percent',
    minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
    maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
  })}`;
};

export const getStatus = (delta: number): MetricCardV2Status => {
  if (isNaN(delta)) {
    return MetricCardV2Status.Negative;
  }
  if (delta === 0) {
    return MetricCardV2Status.Neutral;
  }
  return delta < 0 ? MetricCardV2Status.Negative : MetricCardV2Status.Positive;
};

export const getYAxisFormatV2 = (metricId: HomepageResponseMetricKeys) => {
  const metricType = getHeroMetricType(metricId);
  if (metricType === HeroMetricType.Money) {
    return NumberFormat.CurrencyWith2Digit;
  } else {
    return metricType === HeroMetricType.Percent
      ? NumberFormat.Percent
      : NumberFormat.Number;
  }
};

export const getYAxisMaxValue = (
  data: HomepageDataRequestResponse,
  tmIndexDataResponse: TmIndexDataResponse,
  currentChartTimeFormat: ChartTime,
  shouldShowAmazonData: boolean,
  shouldShowWalmartData: boolean,
  activeMetric?: HomepageResponseMetricKeys
): number => {
  if (activeMetric) {
    const maxCurrentValue = Math.max(
      ...flatten(
        data.current[activeMetric]?.graphDataPoints?.[
          OverviewTimePeriod[currentChartTimeFormat]
        ]?.flatMap((e) => Number(e.totalValue))
      )
    );
    const maxPrevValue = Math.max(
      ...flatten(
        data.previous[activeMetric]?.graphDataPoints?.[
          OverviewTimePeriod[currentChartTimeFormat]
        ]?.flatMap((e) => Number(e.totalValue))
      )
    );

    let maxAmazonBenchmarkValue: number = 0;
    let maxWalmartBenchmarkValue: number = 0;
    if (shouldShowAmazonData) {
      const amazonTmIndexResponse = findChannelSpecificBenchmarkResponse(
        tmIndexDataResponse,
        AMAZON_SALES_CHANNEL_ID
      );

      if (amazonTmIndexResponse) {
        maxAmazonBenchmarkValue = Math.max(
          ...flatten(
            extractMetricResponseFromIndexResponse(
              amazonTmIndexResponse,
              activeMetric
            )?.[OverviewTimePeriod[currentChartTimeFormat]]?.flatMap((e) =>
              Number(e.totalValue)
            )
          )
        );
      }
    }
    if (shouldShowWalmartData) {
      const walmartTmIndexResponse = findChannelSpecificBenchmarkResponse(
        tmIndexDataResponse,
        WALMART_SALES_CHANNEL_ID
      );

      if (walmartTmIndexResponse) {
        maxWalmartBenchmarkValue = Math.max(
          ...flatten(
            extractMetricResponseFromIndexResponse(
              walmartTmIndexResponse,
              activeMetric
            )?.[OverviewTimePeriod[currentChartTimeFormat]]?.flatMap((e) =>
              Number(e.totalValue)
            )
          )
        );
      }
    }

    const metricType = getHeroMetricType(activeMetric);

    const maxYAxisValue = Math.max(
      maxCurrentValue,
      maxPrevValue,
      maxAmazonBenchmarkValue,
      maxWalmartBenchmarkValue
    );

    if (maxCurrentValue === 0) {
      return 100;
    }

    if (metricType === HeroMetricType.Percent) {
      return Math.ceil(maxYAxisValue * 100);
    } else {
      return Math.ceil(maxYAxisValue);
    }
  }
  return 100;
};

export const calculateDelta = (
  heroMetricType: HeroMetricType,
  metricType: HomepageResponseMetricKeys,
  currentValue?: number | MoneyWithAmountInString,
  previousValue?: number | MoneyWithAmountInString
): number => {
  const maxDecimalPlaces = getMetricValueFractions(metricType);
  if (isNil(previousValue) || isNil(currentValue)) return 0;
  let currentNumericValue = -1;
  let previousNumericValue = -1;
  if (heroMetricType !== HeroMetricType.Money) {
    currentNumericValue = currentValue as number;
    previousNumericValue = previousValue as number;
  }
  switch (heroMetricType) {
    case HeroMetricType.Money:
      const previousAmount = convertAmountToNumber(previousValue);
      const divideBy = previousAmount === 0 ? 1 : previousAmount;

      return (convertAmountToNumber(currentValue) - previousAmount) / divideBy;
    case HeroMetricType.Percent:
      return (
        (Number((currentNumericValue * 100).toFixed(maxDecimalPlaces)) -
          Number((previousNumericValue * 100).toFixed(maxDecimalPlaces))) /
        (Number((previousNumericValue * 100).toFixed(maxDecimalPlaces)) === 0
          ? 1
          : Number((previousNumericValue * 100).toFixed(maxDecimalPlaces)))
      );
    case HeroMetricType.Numeric:
    default:
      return (
        (Number(currentNumericValue.toFixed(maxDecimalPlaces)) -
          Number(previousNumericValue.toFixed(maxDecimalPlaces))) /
        (Number(previousNumericValue.toFixed(maxDecimalPlaces)) === 0
          ? 1
          : Number(previousNumericValue.toFixed(maxDecimalPlaces)))
      );
  }
};

export const getCardFooterProps = (
  intl: IntlShape,
  heroMetricType: HeroMetricType,
  metricName: HomepageResponseMetricKeys,
  currentValue?: number | MoneyWithAmountInString,
  previousValue?: number | MoneyWithAmountInString
): MetricCardV2CardTrend => {
  return {
    pillText: getPillText(
      intl,
      calculateDelta(heroMetricType, metricName, currentValue, previousValue)
    ),
    status: getStatus(
      calculateDelta(heroMetricType, metricName, currentValue, previousValue)
    ),
  };
};

// return amazon if atleast 1 mcid belongs to amazon channel
export const getSelectedSalesChannel = (
  merchants: MerchantCountry[],
  selectedMerchantsId: string[]
) => {
  if (!selectedMerchantsId || selectedMerchantsId.length === 0) {
    return FlywheelSalesChannel.Amazon;
  }

  return merchants
    .filter((mc) => selectedMerchantsId.includes(mc.merchantCountryId))
    .find((merchant) => merchant.salesChannelId === AMAZON_SALES_CHANNEL_ID)
    ? FlywheelSalesChannel.Amazon
    : FlywheelSalesChannel.Walmart;
};

const getMetricByMetricType = (
  intl: IntlShape,
  data: HomepageDataRequestResponse,
  selectedCurrency: CurrencyCode,
  chartTime: ChartTime,
  trendFormatSelector: ChartTrendMetricFormatSelector,
  compassState: CompassState,
  xAxisLabels: string[] = [],
  metrictype: HeroMetricType,
  aggregationType: AggregationPeriodType,
  isCurrent: boolean,
  metric: MetricsData
) => {
  switch (metrictype) {
    case HeroMetricType.Money:
      return getGraphDataPoints({
        intl,
        dataType: aggregationType,
        selectedCurrency,
        xAxisLabels,
        chartsTabs: chartTime,
        name: metric.label,
        trendFormatSelector,
        dataGraphPoints: isCurrent
          ? data?.current?.[metric.metricsId]?.graphDataPoints
          : data?.previous?.[metric.metricsId]?.graphDataPoints,
        opposingDataGraphPoints: !isCurrent
          ? data?.current?.[metric.metricsId]?.graphDataPoints
          : data?.previous?.[metric.metricsId]?.graphDataPoints,
        compassState,
        isCurrent,
      });
    case HeroMetricType.Percent:
      return getGraphDataPointsTacos({
        intl,
        dataType: aggregationType,
        selectedCurrency,
        xAxisLabels,
        chartsTabs: chartTime,
        name: metric.label,
        trendFormatSelector,
        dataGraphPoints: isCurrent
          ? data?.current?.[metric.metricsId]?.graphDataPoints
          : data?.previous?.[metric.metricsId]?.graphDataPoints,
        opposingDataGraphPoints: !isCurrent
          ? data?.current?.[metric.metricsId]?.graphDataPoints
          : data?.previous?.[metric.metricsId]?.graphDataPoints,
        isCurrent,
        compassState,
      });
    case HeroMetricType.Numeric:
      return getGraphDataPointsForRoas({
        intl,
        dataType: aggregationType,
        selectedCurrency,
        xAxisLabels,
        chartsTabs: chartTime,
        name: metric.label,
        trendFormatSelector,
        dataGraphPoints: isCurrent
          ? data?.current?.[metric.metricsId]?.graphDataPoints
          : data?.previous?.[metric.metricsId]?.graphDataPoints,
        opposingDataGraphPoints: !isCurrent
          ? data?.current?.[metric.metricsId]?.graphDataPoints
          : data?.previous?.[metric.metricsId]?.graphDataPoints,
        isCurrent,
        compassState,
      });
  }
};

const extractMetricResponseFromIndexResponse = (
  indexResponse: TmIndexResponse,
  metricKey: HomepageResponseMetricKeys
) => {
  if (metricKey === HomepageResponseMetricKeys.Roas) {
    return indexResponse.roas?.graphDataPoints;
  }
  if (metricKey === HomepageResponseMetricKeys.Tacos) {
    return indexResponse.tacos?.graphDataPoints;
  }
  if (metricKey === HomepageResponseMetricKeys.CPC) {
    return indexResponse.costPerClick?.graphDataPoints;
  }
  if (metricKey === HomepageResponseMetricKeys.ACoSTotal) {
    return indexResponse.acos?.graphDataPoints;
  }
};

const getMetricByMetricTypeFromTmResponse = (
  intl: IntlShape,
  data: TmIndexResponse,
  selectedCurrency: CurrencyCode,
  chartTime: ChartTime,
  trendFormatSelector: ChartTrendMetricFormatSelector,
  compassState: CompassState,
  xAxisLabels: string[] = [],
  metrictype: HeroMetricType,
  aggregationType: AggregationPeriodType,
  isCurrent: boolean,
  metric: MetricsData,
  label: string
) => {
  const graphDataPoints = extractMetricResponseFromIndexResponse(
    data,
    metric.metricsId
  );
  if (!isUndefined(graphDataPoints)) {
    switch (metrictype) {
      case HeroMetricType.Money:
        return getGraphDataPoints({
          intl,
          dataType: aggregationType,
          selectedCurrency,
          xAxisLabels,
          chartsTabs: chartTime,
          name: label,
          trendFormatSelector,
          dataGraphPoints: graphDataPoints,
          compassState,
          isCurrent,
        });
      case HeroMetricType.Percent:
        return getGraphDataPointsTacos({
          intl,
          dataType: aggregationType,
          selectedCurrency,
          xAxisLabels,
          chartsTabs: chartTime,
          name: label,
          trendFormatSelector,
          dataGraphPoints: graphDataPoints,
          isCurrent,
          compassState,
        });
      case HeroMetricType.Numeric:
        return getGraphDataPointsForRoas({
          intl,
          dataType: aggregationType,
          selectedCurrency,
          xAxisLabels,
          chartsTabs: chartTime,
          name: label,
          trendFormatSelector,
          dataGraphPoints: graphDataPoints,
          isCurrent,
          compassState,
        });
    }
  }
};

const getGatheringDataByMetricType = (
  intl: IntlShape,
  data: HomepageDataRequestResponse,
  selectedCurrency: CurrencyCode,
  chartTime: ChartTime,
  trendFormatSelector: ChartTrendMetricFormatSelector,
  compassState: CompassState,
  xAxisLabels: string[] = [],
  metrictype: HeroMetricType,
  aggregationType: AggregationPeriodType,
  metric: MetricsData
) => {
  switch (metrictype) {
    case HeroMetricType.Money:
      return getGatheringDataPoints({
        intl,
        dataType: aggregationType,
        selectedCurrency,
        xAxisLabels,
        chartsTabs: chartTime,
        name: metric.label,
        trendFormatSelector,
        dataGraphPoints: data?.current?.[metric.metricsId]?.graphDataPoints,
        opposingDataGraphPoints:
          data?.previous?.[metric.metricsId]?.graphDataPoints,
        compassState,
      });
    case HeroMetricType.Percent:
      return getGatheringDataForTacos({
        intl,
        dataType: aggregationType,
        selectedCurrency,
        xAxisLabels,
        chartsTabs: chartTime,
        name: metric.label,
        trendFormatSelector,
        dataGraphPoints: data?.current?.[metric.metricsId]?.graphDataPoints,
        opposingDataGraphPoints:
          data?.previous?.[metric.metricsId]?.graphDataPoints,
        compassState,
      });
    case HeroMetricType.Numeric:
      return getGatheringDataForRoas({
        intl,
        dataType: aggregationType,
        selectedCurrency,
        xAxisLabels,
        chartsTabs: chartTime,
        name: metric.label,
        trendFormatSelector,
        dataGraphPoints: data?.current?.[metric.metricsId]?.graphDataPoints,
        opposingDataGraphPoints:
          data?.previous?.[metric.metricsId]?.graphDataPoints,
        compassState,
      });
  }
};

const getGatheringDataByMetricTypeFromTmResponse = (
  intl: IntlShape,
  data: TmIndexResponse,
  selectedCurrency: CurrencyCode,
  chartTime: ChartTime,
  trendFormatSelector: ChartTrendMetricFormatSelector,
  compassState: CompassState,
  xAxisLabels: string[] = [],
  metrictype: HeroMetricType,
  aggregationType: AggregationPeriodType,
  metric: MetricsData,
  label: string
) => {
  const graphDataPoints = extractMetricResponseFromIndexResponse(
    data,
    metric.metricsId
  );
  if (graphDataPoints) {
    switch (metrictype) {
      case HeroMetricType.Money:
        return getGatheringDataPoints({
          intl,
          dataType: aggregationType,
          selectedCurrency,
          xAxisLabels,
          chartsTabs: chartTime,
          name: label,
          trendFormatSelector,
          dataGraphPoints: graphDataPoints,
          compassState,
        });
      case HeroMetricType.Percent:
        return getGatheringDataForTacos({
          intl,
          dataType: aggregationType,
          selectedCurrency,
          xAxisLabels,
          chartsTabs: chartTime,
          name: label,
          trendFormatSelector,
          dataGraphPoints: graphDataPoints,
          compassState,
          opposingDataGraphPoints: graphDataPoints,
        });
      case HeroMetricType.Numeric:
        return getGatheringDataForRoas({
          intl,
          dataType: aggregationType,
          selectedCurrency,
          xAxisLabels,
          chartsTabs: chartTime,
          name: label,
          trendFormatSelector,
          dataGraphPoints: graphDataPoints,
          compassState,
          opposingDataGraphPoints: graphDataPoints,
        });
    }
  }
};

export const findChannelSpecificBenchmarkResponse = (
  tmIndexDataResponse: TmIndexDataResponse,
  channelId: string
) => {
  return tmIndexDataResponse?.elements.find(
    (e) => e.salesChannelId === channelId
  );
};

export const metricDataProcessor = (
  intl: IntlShape,
  data: HomepageDataRequestResponse,
  tmIndexDataResponse: TmIndexDataResponse,
  selectedCurrency: CurrencyCode,
  chartTime: ChartTime,
  trendFormatSelector: ChartTrendMetricFormatSelector,
  compassState: CompassState,
  showPreviousData: boolean,
  xAxisLabels: string[] = [],
  primaryMetric?: MetricsData,
  teikaAmazonBenchmarkMetric?: boolean,
  teikaWalmartBenchmarkMetric?: boolean
): MetricCardV2Props[] => {
  const currentMetricsDataPrimary = primaryMetric
    ? getMetricByMetricType(
        intl,
        data,
        selectedCurrency,
        chartTime,
        trendFormatSelector,
        compassState,
        xAxisLabels,
        getHeroMetricType(primaryMetric.metricsId),
        AggregationPeriodType.Current,
        true,
        primaryMetric
      )
    : [];
  const previousMetricsDataPrimary =
    primaryMetric && showPreviousData
      ? getMetricByMetricType(
          intl,
          data,
          selectedCurrency,
          chartTime,
          trendFormatSelector,
          compassState,
          xAxisLabels,
          getHeroMetricType(primaryMetric.metricsId),
          AggregationPeriodType.Previous,
          false,
          primaryMetric
        )
      : [];
  const gatheringMetricsDataPrimary = primaryMetric
    ? getGatheringDataByMetricType(
        intl,
        data,
        selectedCurrency,
        chartTime,
        trendFormatSelector,
        compassState,
        xAxisLabels,
        getHeroMetricType(primaryMetric.metricsId),
        AggregationPeriodType.Current,
        primaryMetric
      )
    : [];

  const amazonTmIndexResponse = findChannelSpecificBenchmarkResponse(
    tmIndexDataResponse,
    AMAZON_SALES_CHANNEL_ID
  );

  const amazonBenchamrkMetricsData =
    teikaAmazonBenchmarkMetric && amazonTmIndexResponse && primaryMetric
      ? getMetricByMetricTypeFromTmResponse(
          intl,
          amazonTmIndexResponse,
          selectedCurrency,
          chartTime,
          trendFormatSelector,
          compassState,
          xAxisLabels,
          getHeroMetricType(primaryMetric?.metricsId),
          AggregationPeriodType.Current,
          true,
          primaryMetric,
          intl.formatMessage({
            id: I18nKey.COMPASS_TM_INDEX_AMAZON_METRICS_LABEL,
          })
        )
      : [];

  const amazonBenchmarkGatheringMetricsData =
    teikaAmazonBenchmarkMetric && amazonTmIndexResponse && primaryMetric
      ? getGatheringDataByMetricTypeFromTmResponse(
          intl,
          amazonTmIndexResponse,
          selectedCurrency,
          chartTime,
          trendFormatSelector,
          compassState,
          xAxisLabels,
          getHeroMetricType(primaryMetric.metricsId),
          AggregationPeriodType.Current,
          primaryMetric,
          intl.formatMessage({
            id: I18nKey.COMPASS_TM_INDEX_AMAZON_METRICS_LABEL,
          })
        )
      : [];

  const walmartTmIndexResponse = findChannelSpecificBenchmarkResponse(
    tmIndexDataResponse,
    WALMART_SALES_CHANNEL_ID
  );

  const walmartBenchamrkMetricsData =
    teikaWalmartBenchmarkMetric && walmartTmIndexResponse && primaryMetric
      ? getMetricByMetricTypeFromTmResponse(
          intl,
          walmartTmIndexResponse,
          selectedCurrency,
          chartTime,
          trendFormatSelector,
          compassState,
          xAxisLabels,
          getHeroMetricType(primaryMetric?.metricsId),
          AggregationPeriodType.Current,
          true,
          primaryMetric,
          intl.formatMessage({
            id: I18nKey.COMPASS_TM_INDEX_WALMART_METRICS_LABEL,
          })
        )
      : [];

  const walmartBenchmarkGatheringMetricsData =
    teikaWalmartBenchmarkMetric && walmartTmIndexResponse && primaryMetric
      ? getGatheringDataByMetricTypeFromTmResponse(
          intl,
          walmartTmIndexResponse,
          selectedCurrency,
          chartTime,
          trendFormatSelector,
          compassState,
          xAxisLabels,
          getHeroMetricType(primaryMetric.metricsId),
          AggregationPeriodType.Current,
          primaryMetric,
          intl.formatMessage({
            id: I18nKey.COMPASS_TM_INDEX_WALMART_METRICS_LABEL,
          })
        )
      : [];

  const chartsData = [];
  const primaryMetricCharts = {
    isActive: true,
    header: primaryMetric?.label,
    className: 'bg-purple-600',
    data: [
      {
        label: intl.formatMessage({
          id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD,
        }),
        dataTestId: 'primary_metric_current_graph_points',
        data: {
          name: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD,
          }),
          color: PURPLE_600,
          type: 'spline',
          visible: true,
          yAxis: 0,
          marker: {
            symbol: 'circle',
          },
          data: currentMetricsDataPrimary,
        },
      },
      {
        label: intl.formatMessage({
          id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
        }),
        dataTestId: 'primary_metric_gathering_graph_points',
        data: {
          name: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
          }),
          color: PURPLE_600,
          type: 'line',
          visible: true,
          yAxis: 0,
          marker: {
            symbol: 'circle',
          },
          dashStyle: 'ShortDot',
          data: gatheringMetricsDataPrimary,
        },
      },
      {
        label: intl.formatMessage({
          id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_PREVIOUS_PERIOD,
        }),
        dataTestId: 'primary_metric_previous_period_graph_points',
        data: {
          name: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_PREVIOUS_PERIOD,
          }),
          color: PURPLE_300,
          type: 'spline',
          visible: true,
          yAxis: 0,
          marker: {
            symbol: 'circle',
          },
          data: previousMetricsDataPrimary,
        },
      },
    ],
  };

  const amazonBenchamarkMetricCharts = {
    isActive: teikaAmazonBenchmarkMetric,
    header: teikaAmazonBenchmarkMetric
      ? intl.formatMessage({
          id: I18nKey.COMPASS_TM_INDEX_AMAZON_METRICS_LABEL,
        })
      : '',
    className: 'bg-blue-500',
    data: [
      {
        label: intl.formatMessage({
          id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD,
        }),
        dataTestId: 'amazon_metric_current_graph_points',
        data: {
          name: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD,
          }),
          color: BLUE_500,
          type: 'spline',
          visible: teikaAmazonBenchmarkMetric,
          yAxis: 0,
          marker: {
            symbol: 'circle',
          },
          data: amazonBenchamrkMetricsData,
        },
      },
      {
        label: intl.formatMessage({
          id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
        }),
        dataTestId: 'amazon_metric_gathering_graph_points',
        data: {
          name: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
          }),
          color: BLUE_500,
          type: 'line',
          visible: teikaAmazonBenchmarkMetric,
          yAxis: 0,
          marker: {
            symbol: 'circle',
          },
          dashStyle: 'ShortDot',
          data: amazonBenchmarkGatheringMetricsData,
        },
      },
    ],
  };

  const walmartBenchamarkMetricCharts = {
    isActive: teikaWalmartBenchmarkMetric,
    header: teikaWalmartBenchmarkMetric
      ? intl.formatMessage({
          id: I18nKey.COMPASS_TM_INDEX_WALMART_METRICS_LABEL,
        })
      : '',
    className: 'bg-yellow-500',
    data: [
      {
        label: intl.formatMessage({
          id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD,
        }),
        dataTestId: 'walmart_metric_current_graph_points',
        data: {
          name: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD,
          }),
          color: YELLOW_500,
          type: 'spline',
          visible: teikaWalmartBenchmarkMetric,
          yAxis: 0,
          marker: {
            symbol: 'circle',
          },
          data: walmartBenchamrkMetricsData,
        },
      },
      {
        label: intl.formatMessage({
          id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
        }),
        dataTestId: 'walmart_metric_gathering_graph_points',
        data: {
          name: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
          }),
          color: YELLOW_500,
          type: 'line',
          visible: teikaWalmartBenchmarkMetric,
          yAxis: 0,
          marker: {
            symbol: 'circle',
          },
          dashStyle: 'ShortDot',
          data: walmartBenchmarkGatheringMetricsData,
        },
      },
    ],
  };

  if (primaryMetric) {
    chartsData.push(primaryMetricCharts);
  }

  chartsData.push(amazonBenchamarkMetricCharts);

  chartsData.push(walmartBenchamarkMetricCharts);

  return [...chartsData];
};

export const getSecondMetricsField = (
  config: MetricsData[],
  firstMetric?: HomepageResponseMetricKeys,
  secondMetric?: HomepageResponseMetricKeys
) => {
  let currentSecondMetric: HomepageResponseMetricKeys | undefined;

  if (secondMetric) {
    currentSecondMetric = secondMetric;
  }

  return currentSecondMetric &&
    checkMetricExistInConfig(config, currentSecondMetric)
    ? currentSecondMetric
    : undefined;
};

export const getFirstMetricsField = (
  config: MetricsData[],
  firstMetric?: HomepageResponseMetricKeys
) => {
  if (firstMetric) {
    return firstMetric;
  } else {
    return checkMetricExistInConfig(config, HomepageResponseMetricKeys.Roas)
      ? HomepageResponseMetricKeys.Roas
      : undefined;
  }
};

export const getTooltipContentForMetric = (
  metricId: HomepageResponseMetricKeys
) => {
  const descriptionId = getCardPopupDescription(metricId);
  const formula = getCardPopupFormula(metricId);
  return descriptionId ? (
    <>
      <div className="leading-tight">
        <FormattedMessage id={descriptionId} />
      </div>
      {formula && (
        <div className="mt-8 flex flex-wrap rounded py-4 px-8 bg-grey-800">
          <FormattedMessage
            id={formula}
            values={{
              br: <div className="h-32"></div>,
              multiply: <Formula type={FormulaType.Multiply} />,
              divide: <Formula type={FormulaType.Divide} />,
              plus: <Formula type={FormulaType.Add} />,
              minus: <Formula type={FormulaType.Subtract} />,
              openParentheses: (
                <span className="text-grey-500 mx-4">{'('}</span>
              ),
              closeParentheses: (
                <span className="text-grey-500 mx-4">{')'}</span>
              ),
            }}
          />
        </div>
      )}
    </>
  ) : undefined;
};

export const getCardPopupFormula = (
  metricType: HomepageResponseMetricKeys
): I18nKey | undefined => {
  switch (metricType) {
    case HomepageResponseMetricKeys.AdSales:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_AD_SALES;
    case HomepageResponseMetricKeys.AdSpend:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_AD_SPEND;
    case HomepageResponseMetricKeys.ACoSTotal:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_ACOS;
    case HomepageResponseMetricKeys.AdConversions:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_AD_CONVERSIONS;
    case HomepageResponseMetricKeys.AverageSalesPrice:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_AVERAGE_SALES_PRICE;
    case HomepageResponseMetricKeys.Clicks:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_CLICKS;
    case HomepageResponseMetricKeys.ClickThroughRate:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_CLICK_THROUGH_RATE;
    case HomepageResponseMetricKeys.ConversionRate:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_CONVERSION_RATE;
    case HomepageResponseMetricKeys.CPC:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_CPC;
    case HomepageResponseMetricKeys.Impressions:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_IMPRESSIONS;
    case HomepageResponseMetricKeys.Roas:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_ROAS;
    case HomepageResponseMetricKeys.Tacos:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_TACOS;
    case HomepageResponseMetricKeys.TotalSales:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_TOTAL_SALES;
    case HomepageResponseMetricKeys.UnitsSold:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_UNITS_SOLD;
    case HomepageResponseMetricKeys.DirectACoS:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_DIRECT_ACOS;
    case HomepageResponseMetricKeys.DirectAdSales:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_DIRECT_AD_SALES;
    case HomepageResponseMetricKeys.DirectROAS:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_DIRECT_ROAS;
    default:
      return undefined;
  }
};

export const getCardPopupDescription = (
  metricType: HomepageResponseMetricKeys
): I18nKey | undefined => {
  switch (metricType) {
    case HomepageResponseMetricKeys.AdSales:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_AD_SALES;
    case HomepageResponseMetricKeys.AdSpend:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_AD_SPEND;
    case HomepageResponseMetricKeys.ACoSTotal:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_ACOS;
    case HomepageResponseMetricKeys.AdConversions:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_AD_CONVERSIONS;
    case HomepageResponseMetricKeys.AverageSalesPrice:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_AVERAGE_SALES_PRICE;
    case HomepageResponseMetricKeys.Clicks:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_CLICKS;
    case HomepageResponseMetricKeys.ClickThroughRate:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_CLICK_THROUGH_RATE;
    case HomepageResponseMetricKeys.ConversionRate:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_CONVERSION_RATE;
    case HomepageResponseMetricKeys.CPC:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_CPC;
    case HomepageResponseMetricKeys.Impressions:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_IMPRESSIONS;
    case HomepageResponseMetricKeys.Roas:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_ROAS;
    case HomepageResponseMetricKeys.Tacos:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_TACOS;
    case HomepageResponseMetricKeys.TotalSales:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_TOTAL_SALES;
    case HomepageResponseMetricKeys.UnitsSold:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_UNITS_SOLD;
    case HomepageResponseMetricKeys.DirectACoS:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_DIRECT_ACOS;
    case HomepageResponseMetricKeys.DirectAdSales:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_DIRECT_AD_SALES;
    case HomepageResponseMetricKeys.DirectROAS:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_DIRECT_ROAS;
    default:
      return undefined;
  }
};

export const checkMetricExistInConfig = (
  config: MetricsData[],
  metricId: HomepageResponseMetricKeys
) =>
  config
    .filter((metric) => metric.isChecked)
    .map((metric) => metric.metricsId)
    .includes(metricId);

const MAP_SALES_CHANNEL_INDEX_KEY_TO_I18NKEY: Record<
  SalesChannelTmIndexKeys,
  I18nKey
> = {
  [SalesChannelTmIndexKeys.Amazon]: I18nKey.GENERIC_AMAZON,
  [SalesChannelTmIndexKeys.Walmart]: I18nKey.GENERIC_WALMART,
};

const MAP_SALES_CHANNEL_INDEX_KEY_TO_TOOLTIP_I18NKEY: Record<
  SalesChannelTmIndexKeys,
  I18nKey
> = {
  [SalesChannelTmIndexKeys.Amazon]:
    I18nKey.COMPASS_TM_INDEX_COMPARE_AMAZON_TOOLTIP,
  [SalesChannelTmIndexKeys.Walmart]:
    I18nKey.COMPASS_TM_INDEX_COMPARE_WALMART_TOOLTIP,
};

const MAP_SALES_CHANNEL_INDEX_KEY_TO_COLOR: Record<
  SalesChannelTmIndexKeys,
  string
> = {
  [SalesChannelTmIndexKeys.Amazon]: 'bg-blue-500',
  [SalesChannelTmIndexKeys.Walmart]: 'bg-yellow-500',
};

export const getKpiBenchmarkCardProps = (
  salesChannelIndexKey: SalesChannelTmIndexKeys,
  intl: IntlShape,
  salesChannelTmIndicesSelected: SalesChannelTmIndexKeys[],
  isDisabled: boolean,
  isDisabledCozOfNonUsCountrySelected?: boolean
) => {
  const isChannelIncluded =
    salesChannelTmIndicesSelected.includes(salesChannelIndexKey);

  const getTooltipText = () => {
    if (isDisabled) {
      return I18nKey.COMPASS_TM_INDEX_COMPARISION_UNAVAILABLE_TOOLTIP;
    } else if (isDisabledCozOfNonUsCountrySelected) {
      return I18nKey.COMPASS_TM_INDEX_COMPARISION_UNAVAILABLE_WALMART_TOOLTIP;
    } else {
      return MAP_SALES_CHANNEL_INDEX_KEY_TO_TOOLTIP_I18NKEY[
        salesChannelIndexKey
      ];
    }
  };

  return {
    dataTestId: salesChannelIndexKey,
    header: intl.formatMessage({
      id: MAP_SALES_CHANNEL_INDEX_KEY_TO_I18NKEY[salesChannelIndexKey],
    }),
    isActive: isChannelIncluded,
    id: salesChannelIndexKey,
    highlightColor: MAP_SALES_CHANNEL_INDEX_KEY_TO_COLOR[salesChannelIndexKey],
    isDisabled: isDisabled || isDisabledCozOfNonUsCountrySelected,
    tooltipProps:
      isChannelIncluded && !isDisabled && !isDisabledCozOfNonUsCountrySelected
        ? undefined
        : {
            content: intl.formatMessage({
              id: getTooltipText(),
            }),
            overwrittenTooltipClassnames: 'min-w-236 text-center',
          },
  };
};

export const isTmIndexMetricDisabledForMetric = (
  activeMetric?: HomepageResponseMetricKeys
): boolean =>
  activeMetric ? !ACTIVE_TM_INDEX_METRICS.includes(activeMetric) : true;

export const isAtleastOneCountryUs = (countries?: string[]): boolean =>
  countries?.includes('US') || false;

export const shouldShowTmIndexDataOnGraph = (
  salesChannelIndexKey: SalesChannelTmIndexKeys,
  salesChannelTmIndicesSelected: SalesChannelTmIndexKeys[],
  activeMetric?: HomepageResponseMetricKeys,
  selectedCountries?: string[]
): boolean => {
  const isChannelIncluded =
    salesChannelTmIndicesSelected.includes(salesChannelIndexKey);
  if (
    activeMetric &&
    isChannelIncluded &&
    ACTIVE_TM_INDEX_METRICS.includes(activeMetric)
  ) {
    if (salesChannelIndexKey === SalesChannelTmIndexKeys.Walmart) {
      return isAtleastOneCountryUs(selectedCountries);
    }
    return true;
  }
  return false;
};

export const getTickerIntervalPositions = (maxYAxisValue: number) => {
  if (maxYAxisValue < 2) {
    return [0, 1];
  }
  const numElements = 5;
  const step = Math.ceil(maxYAxisValue / (numElements - 1));
  return Array.from({ length: numElements }, (_, index) => index * step);
};
