import { SkuCatalogApiColumnValue } from '../skuCatalog/types';
import { Filter, FilterOps } from '../../../../lib/types/Filter';
import { Location } from 'react-router-dom';

enum EventNotificationType {
  OutOfStock = 'outofstock',
}

const NOTIFICATION_EVENT_URL_KEY = 'event';
const FROM_EMAIL_REDIRECT_URL_KEY = 'redirectedFromEmail';

export const getSkuCatalogFiltersFromUrl = (location: Location): Filter[] => {
  const filtersToBeAdded: Filter[] = [];
  const urlParams = new URLSearchParams(location.search);
  const eventNotificationType = urlParams.get(
    NOTIFICATION_EVENT_URL_KEY
  ) as EventNotificationType;
  if (eventNotificationType === EventNotificationType.OutOfStock) {
    filtersToBeAdded.push(
      {
        op: FilterOps.eq,
        field: SkuCatalogApiColumnValue.CurrentInventoryQuantity,
        value: '0',
      },
      {
        op: FilterOps.gt,
        field: SkuCatalogApiColumnValue.UnitsSold,
        value: 1,
      }
    );
  }
  return filtersToBeAdded;
};

export const doesUrlContainAnyEventNotificationParam = (location: Location) => {
  const urlParams = new URLSearchParams(location.search);
  return urlParams.get(NOTIFICATION_EVENT_URL_KEY) !== null;
};

export const isUrlRedirectedFromEmail = (location: Location) => {
  const urlParams = new URLSearchParams(location.search);
  return (
    urlParams.get(FROM_EMAIL_REDIRECT_URL_KEY)?.toLocaleLowerCase() === 'true'
  );
};
