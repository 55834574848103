import { Factory } from 'fishery';

import { FAMConnectionStatus } from '../../../lib/types/Fam';
import { AMAZON_SALES_CHANNEL_ID } from '../../../lib/types/SalesChannels';
import { SalesChannelDataWithChannelName } from '../../../modules/products/containers/skuCatalog/types';

export default Factory.define<SalesChannelDataWithChannelName>(
  ({ sequence }) => ({
    id: AMAZON_SALES_CHANNEL_ID,
    name: `Foobar Enterprise ${sequence}`,
    country: 'US',
    extMerchantId: 'ABC123',
    aoEnabled: false,
    aiEnabled: false,
    adConnectionStatus: '',
    productConnectionStatus: FAMConnectionStatus.Active,
    extMerchantType: 'vendor',
    updatedAt: new Date(),
    salesChannelName: 'walmart',
  })
);
