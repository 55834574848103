import { createContext, useContext, useEffect, useState } from 'react';

import { createAOApiClient } from '../../lib/clients/AOApiClient';
import { createSKUApiClient } from '../../lib/clients/SKUApiClient';
import { DataSyncInfoResponse } from '../../lib/types/AOSharedTypes';
import { DataAvailabilityResponse } from '../../lib/types/SKUSharedTypes';
import { getDataAvailabilityInfo } from '../../lib/utilities/connectionStatus';
import { SubscriptionContext } from '../subscriptionProvider';
import { UserContext, UserContextState } from '../userProvider/userProvider';
import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import {
  DataSyncStatusResponseType,
  SalesChannelData,
} from '../../lib/types/Fam';
import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';
import { NavPaths } from '../../NavPaths';
import { AccountSalesChannelPaginatedResultWithError } from '../../modules/products/containers/skuCatalog/types';
import { useQuery } from '@tanstack/react-query';
import { GeneralQueryKeys } from '../../lib/types/ReactQueryKeys';

export interface DataSyncInfoContextState {
  readonly skuDataAvailibilityInfo?: DataAvailabilityResponse;
  readonly aoDataSyncInfo?: DataSyncInfoResponse;
  readonly dataSyncStatus?: DataSyncStatusResponseType;
  isDataSyncInfoProviderLoaded: boolean;
  readonly refreshData: () => void;
}

export interface SyncInfo {
  readonly skuDataAvailibilityInfo?: DataAvailabilityResponse;
  readonly aoDataSyncInfo?: DataSyncInfoResponse;
}

const initialState: DataSyncInfoContextState = {
  isDataSyncInfoProviderLoaded: false,
  refreshData: () => {},
};

const DataSyncInfoContext =
  createContext<DataSyncInfoContextState>(initialState);

DataSyncInfoContext.displayName = 'DataSyncInfoContext';

export const useDataSyncInfoContext = () => {
  const context = useContext(DataSyncInfoContext);
  if (!context) {
    throw new Error(
      'useDataSyncInfoContext must be used within a DataSyncProvider'
    );
  }
  return context;
};

const { Provider } = DataSyncInfoContext;

export interface DataSyncProviderProps {
  readonly children: JSX.Element;
}

export interface PreviousUserChannelInfo {
  readonly accountId: string;
  readonly salesChannelData: AccountSalesChannelPaginatedResultWithError[];
}

const DataSyncProvider: React.FC<DataSyncProviderProps> = ({ children }) => {
  const { salesChannelData, isSubscriptionInformationLoaded } =
    useContext(SubscriptionContext);
  const [dataSyncInfo, setDataSyncInfo] = useState<SyncInfo>({});
  const [dataSyncStatus, setDataSyncStatus] =
    useState<DataSyncStatusResponseType>({} as DataSyncStatusResponseType);
  const [isDataSyncInfoLoaded, setIsDataSyncLoaded] = useState(false);
  const [isDataStatusLoaded, setIsDataStatusLoaded] = useState(false);

  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;

  const famClient = createFAMApiClient(userContext.userInfo.idToken!);
  const aoClient = createAOApiClient(userContext.userInfo.idToken!);
  const skuApiClient = createSKUApiClient(userContext.userInfo.idToken!);
  const location = useLocation();

  const [previousUserChannelInfo, setPreviousUserChannelInfo] =
    useState<PreviousUserChannelInfo>();

  const { refetch: fetchDataSyncInfo } = useQuery({
    queryKey: [GeneralQueryKeys.GetDataSyncInfo, accountId],
    queryFn: () => famClient.getDataSyncStatus(accountId),
    enabled: false,
  });

  const setData = (dataAvailibilityInfo?: {
    aoDataSyncInfo: DataSyncInfoResponse;
    skuDataAvailibilityInfo: DataAvailabilityResponse;
  }) => {
    if (dataAvailibilityInfo) {
      setDataSyncInfo({
        aoDataSyncInfo: dataAvailibilityInfo.aoDataSyncInfo,
        skuDataAvailibilityInfo: dataAvailibilityInfo.skuDataAvailibilityInfo,
      });
    }
  };

  const getDataSyncStatus = async () => {
    try {
      setIsDataStatusLoaded(false);
      const { data: syncStatus } = await fetchDataSyncInfo();
      if (syncStatus) {
        setDataSyncStatus(syncStatus);
        setIsDataStatusLoaded(true);
      }
    } catch (err) {
      setIsDataStatusLoaded(true);
      console.error(err);
    }
  };

  const getDataSyncInfo = async () => {
    try {
      setIsDataSyncLoaded(false);
      const allSalesChannelData: SalesChannelData[] = [];
      salesChannelData.forEach((channelData) => {
        allSalesChannelData.push(...channelData.items);
      });

      const dataAvailibilityInfo = await getDataAvailabilityInfo(
        aoClient,
        skuApiClient,
        accountId,
        allSalesChannelData
      );

      setData(dataAvailibilityInfo);
      setIsDataSyncLoaded(true);

      setPreviousUserChannelInfo({ accountId, salesChannelData });
    } catch (err) {
      console.error(err);
      setIsDataSyncLoaded(true);
    }
  };

  const allowDataSyncRefresh = () => {
    /**
     * If the user is on the Account switcher page.
     * Don't call the data sync APIs as they are not required.
     * As soon as they are in. It will be called due to account/MC changes
     */
    if (location.pathname.includes(NavPaths.SwitchAccount)) {
      return false;
    }

    const previousDataExists =
      previousUserChannelInfo?.accountId &&
      previousUserChannelInfo.salesChannelData.length > 0;

    const isStaleSalesChannelData = isEqual(
      previousUserChannelInfo?.salesChannelData,
      salesChannelData
    );

    const isStaleAccountId = previousUserChannelInfo?.accountId === accountId;

    const shouldRefreshDataSyncInfo =
      !previousDataExists ||
      (accountId && !isStaleSalesChannelData && !isStaleAccountId);
    return shouldRefreshDataSyncInfo;
  };

  const refreshData = () => {
    if (allowDataSyncRefresh()) {
      getDataSyncInfo();
      getDataSyncStatus();
    }
  };

  useEffect(() => {
    if (isSubscriptionInformationLoaded) {
      refreshData();
    }
  }, [salesChannelData, isSubscriptionInformationLoaded]);

  return (
    <Provider
      value={{
        aoDataSyncInfo: dataSyncInfo?.aoDataSyncInfo,
        skuDataAvailibilityInfo: dataSyncInfo?.skuDataAvailibilityInfo,
        dataSyncStatus,
        isDataSyncInfoProviderLoaded:
          isDataStatusLoaded && isDataSyncInfoLoaded,
        refreshData,
      }}
    >
      {children}
    </Provider>
  );
};
DataSyncProvider.displayName = 'DataSyncProvider';
export { DataSyncInfoContext, DataSyncProvider };
