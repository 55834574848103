export const FLOATING_POINT_PRECISION = 2;

export const MI_CARD_ADD_TERMS_SHOW_ME_HOW_LINK =
  'https://intercom.help/flywheel-20/en/articles/4931480-getting-started-add-terms-to-track';

export const BUSINESS_PERFORMANCE_MAX_ROWS_COUNT = 5;

export enum BusinessPerformanceColumnName {
  MerchantDetails = 'merchantCountryName',
  CampaignDetails = 'campaignDetails',
  TotalSales = 'totalSales',
  AdSales = 'adSales',
  AdSalesTotal = 'adSalesTotal',
  ACoSTotal = 'acosTotal',
  TACoS = 'tacos',
  AdSpend = 'adSpend',
  Rank = 'rank',
  ProductDetails = 'productDetails',
}

export const MERCHANT_COUNTRY_ID_PARAM = 'merchant_country_id';
