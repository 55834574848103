import {
  ArrowsNextIcon,
  Icon,
  IconSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React from 'react';
import { CampaignsAdGroupsSelectOptionProps } from '.';
import { getCampaignStatusMarkerColor } from './CampaignsDisplayLabel';

export const AdGroupSelectOption: React.FC<
  CampaignsAdGroupsSelectOptionProps & {
    readonly isLast: boolean;
  }
> = (props) => {
  return (
    <div
      className={classNames(
        'flex w-full pl-28 px-12 py-8 border-t border-solid border-grey-200 gap-12 bg-white hover:bg-grey-50 justify-between',
        {
          'rounded-b-lg': props.isLast,
        }
      )}
      onClick={() => props.onChange && props.onChange(props.adGroupId)}
    >
      <div className="flex flex-col items-center gap-8 py-12 px-32">
        <div className="flex w-full items-center gap-8">
          <Icon
            size={IconSize.Small}
            svg={ArrowsNextIcon}
            className="text-grey-500"
          />
          <Typography
            lineHeight={TypographyLineHeight.none}
            size={TypographySize.base}
            weight={TypographyWeight.regular}
            className="text-grey-900"
          >
            {props.adGroupName}
          </Typography>
        </div>
        {props.tags ? (
          <div className="flex w-full gap-12 items-center pl-24">
            {props.tags.map((tag) => tag)}
          </div>
        ) : null}
      </div>
      <div className="flex items-center gap-8 w-152">
        <div
          className={classNames(
            'inline-block p-4 rounded-full',
            getCampaignStatusMarkerColor(props.status)
          )}
        ></div>
        <Typography
          lineHeight={TypographyLineHeight.none}
          size={TypographySize.base}
          weight={TypographyWeight.regular}
          className="text-grey-900 capitalize"
        >
          {props.statusString}
        </Typography>
      </div>
    </div>
  );
};
