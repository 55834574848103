import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { TargetsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { TARGETS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = ({
  merchantCountry,
  ...targetDetails
}) => {
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  return makeCurrencyColumn<TargetsDetails>(
    ({ targetDetails: { campaignDailyBudget } }) =>
      campaignDailyBudget && {
        amount: Number(campaignDailyBudget.amount),
        currency: currencyCode || campaignDailyBudget.currency || CURRENCY_CODE,
      },
    targetDetails.pendingFields?.includes(TARGETS_API_COLUMN_NAME.DailyBudget)
  )(targetDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const dailyBudgetColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.DailyBudget,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_DAILY_BUDGET,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const dailyBudgetFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    TARGETS_API_COLUMN_NAME.DailyBudget,
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_DAILY_BUDGET,
    currency,
    isValidNumber(0)
  );
