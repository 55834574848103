import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  CampaignDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  isNumericValueWithCampaignTacticAndCampaignAdFormatValid,
} from '../utils';

export const RowCellElement: React.FC<CampaignDetails> = (props) => {
  const isSponseredBrandsAdTypeOrSV =
    props.selectedAdType === AdType.SponsoredBrands ||
    props.selectedAdType === AdType.SearchBrandAmplifier ||
    props.selectedAdType === AdType.SponsoredVideos;

  return makeNumericColumn<CampaignDetails>(
    ({
      campaignPerformance: { newToBrandOrders },
      campaignDetails: { campaignTactic },
    }) =>
      isNumericValueWithCampaignTacticAndCampaignAdFormatValid(
        newToBrandOrders,
        campaignTactic,
        isSponseredBrandsAdTypeOrSV
      )
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const newToBrandOrdersColumn: FlywheelTableColumn<CampaignDetails> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrders,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS,
  RowCellElement,
  gridColumnWidth: '124px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const newToBrandOrdersFilter = createNumericDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrders,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS,
  isValidNumber(0)
);
