import {
  ArrowBendRightIcon,
  BadgeCampaignGoal,
  BadgeCampaignType,
  CampaignGoalGrowIcon,
  CampaignGoalLiquidateIcon,
  CampaignGoalProductLaunchIcon,
  CampaignGoalProfitIcon,
  CampaignGoalROASIcon,
  CampaignGoalTempOverrideIcon,
  DualModes,
  LightningFWGradientIcon,
  LightningPurpleGradientIcon,
  NotEligileIcon,
  SvgIcon,
} from '@teikametrics/tm-design-system';
import { DateTime } from 'luxon';
import { Filter } from '../../lib/types/Filter';
import { TargetSegments } from '../../lib/types/KeywordAction';
import { ProductAdStatusValue } from '../../modules/advertisingOptimization/containers/adsManager/utils';
import { IngestionStatus, MerchantCountry, MerchantType } from './Fam';
import I18nKey from './I18nKey';
import {
  Money as MoneyWithAmountInNumber,
  MoneyWithAmountInString,
} from './Money';

export const AO_BASE_URL = process.env.REACT_APP_AO_HOST;
export const REQUEST_DATE_FORMAT = 'yyyy-MM-dd';
export const EMPTY_STRING = '';

export type CampaignsChannelSettingsEditInfoKeys =
  | 'campaignName'
  | 'mobileBidMultiplier'
  | 'appBidMultiplier'
  | 'homePageBidMultiplier'
  | 'desktopBidMultiplier'
  | 'endDate'
  | 'buyBoxBidMultiplier'
  | 'belowTopOfSearchBidMultiplier'
  | 'bidOptimization'
  | 'searchIngridBidMultiplier'
  | 'budgetRollover'
  | 'stockUpBidMultiplier'
  | 'topOfSearchBidMultiplier'
  | 'carouselStatus'
  | 'itemBuyBox'
  | 'itemCarousel'
  | 'status'
  | 'biddingStrategy'
  | 'totalBudget'
  | 'dailyBudget'
  | 'productPageBidMultiplier';

export interface Range {
  readonly startDate: string;
  readonly endDate: string;
}

export enum MerchantSalesChannel {
  Walmart = 'walmart',
  Amazon = 'amazon',
}

export enum CampaignTargetingType {
  Auto = 'auto',
  Manual = 'manual',
}

// The more accurate name for this enum is `ProductVerticalName`
export enum PRODUCT_SUBSCRIPTIONS {
  AO = 'AO',
  MI = 'MI',
  IO = 'IO',
  AI = 'AI',
  INVENTORY_MODULE = 'INVENTORY_MODULE',
  BI = 'BI',
  AMC = 'AMC',
}

export enum MetricType {
  'adSpend' = 'adSpend',
  'adSalesTotal' = 'adSalesTotal',
  'roasTotal' = 'roasTotal',
  'unitsSold' = 'unitsSold',
  'conversionRate' = 'conversionRate',
  'adConversions' = 'adConversions',
}

export enum AOKPIMetrics {
  ACoS = 'acosTotal',
  ACoSDirect = 'acosDirect',
  AdSales = 'adSalesTotal',
  AdSalesDirect = 'adSalesDirect',
  AdConversions = 'adConversions',
  Impressions = 'impressions',
  Clicks = 'clicks',
  ClickThroughRate = 'clickThroughRate',
  ConversionRate = 'conversionRate',
  CostPerClick = 'avgCostPerClick',
  AdSpend = 'adSpend',
  ROASDirect = 'roasDirect',
  ROAS = 'roasTotal',
  TotalSales = 'totalSales',
  UnitsSold = 'unitsSold',
}

export enum DecimalNumericColumn {
  'roasTotal' = 'roasTotal',
  'roasDirect' = 'roasDirect',
}

export type Metric = number | MoneyWithAmountInString;
export type MerchantCountryId = Array<string | number> | string;
export type SalesChannelId = string | number;

export enum HeroMetricsAggregationPeriodType {
  Current = 'current',
  Previous = 'previous',
}

// Update the interface to add more performance fields
export interface HeroMetric {
  readonly [key: string]: number | MoneyWithAmountInString | undefined | Range;
  readonly acosTotal?: number;
  readonly acosDirect?: number;
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSalesDirect?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly avgCostPerClick?: MoneyWithAmountInString;
  readonly unitsSold?: number;
  readonly roasTotal?: number;
  readonly conversionRate?: number;
  readonly adConversions?: number;
  readonly impressions?: number;
  readonly clicks?: number;
  readonly clickThroughRate?: number;
  readonly roasDirect?: number;
  readonly range: Range;
}

export interface HeroMetricsData {
  readonly aggregationPeriodType: HeroMetricsAggregationPeriodType;
  readonly performanceMetrics: HeroMetric;
}

export enum HeroMetricType {
  Numeric,
  Money,
  Percent,
}

export enum CampaignServingStatus {
  AsinNotBuyable = 'asinNotBuyable',
  BillingError = 'billingError',
  Ended = 'ended',
  LandingPageNotAvailable = 'landingPageNotAvailable',
  OutOfBudget = 'outOfBudget',
  Paused = 'paused',
  PendingReview = 'pendingReview',
  Ready = 'ready',
  Rejected = 'rejected',
  Delivering = 'running',
  Scheduled = 'scheduled',
  Terminated = 'terminated',
  Ineligible = 'INELIGIBLE',
  CampaignArchived = 'CAMPAIGN_ARCHIVED',
  CampaignAdsNotDelivering = 'CAMPAIGN_ADS_NOT_DELIVERING',
  PortfolioOutOfBudget = 'PORTFOLIO_OUT_OF_BUDGET',
  AccountOutOfBudget = 'ACCOUNT_OUT_OF_BUDGET',
  PendingStartDate = 'PENDING_START_DATE',
  PortfolioEnded = 'PORTFOLIO_ENDED',
  CampaignStatusEnabled = 'CAMPAIGN_STATUS_ENABLED',
  AdvertiserPaymentFailure = 'ADVERTISER_PAYMENT_FAILURE',
  CampaignOutOfBudget = 'CAMPAIGN_OUT_OF_BUDGET',
  Unknown = 'UNKNOWN',
  CampaignIncomplete = 'CAMPAIGN_INCOMPLETE',
  CampaignPaused = 'CAMPAIGN_PAUSED',
  PortfolioPendingStartDate = 'PORTFOLIO_PENDING_START_DATE',
  AdvertiserArchived = 'ADVERTISER_ARCHIVED',
  Other = 'other',
}

export enum AmazonBidOptimization {
  True = 'true',
  False = 'false',
}

export interface HeroMetricsItem {
  readonly metricName: MetricType;
  readonly previousValue?: Metric;
  readonly currentValue?: Metric;
  readonly heroMetricType: HeroMetricType;
}

export interface HeroMetricsItemV2 {
  readonly label?: string;
  readonly isChecked?: boolean;
  readonly metricName: AOKPIMetrics;
  readonly previousValue?: Metric;
  readonly currentValue?: Metric;
  readonly heroMetricType: HeroMetricType;
}

export interface PerformanceMetricsRequest {
  readonly merchantCountryIds: string[];
  readonly performanceFields: MetricType[] | AOKPIMetrics[];
  readonly startDate: string;
  readonly endDate: string;
  readonly aggregationPeriodType: HeroMetricsAggregationPeriodType;
  readonly channelId: string;
  readonly filters: Filter[];
  readonly advertisingType: AdType;
  readonly targetType?: EntityTypeRequestParameterForTargets;
  readonly merchantType?: MerchantType;
}

export enum EntityTypeRequestParameterForTargets {
  KeywordTargeting = 'Keyword Targets',
  ProductTargetting = 'Product Targets',
  Targets = 'Targets',
}

export interface CampaignDataRequest {
  readonly merchantCountryIds: string[];
  readonly campaignDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly placementSettingsFields: string[];
  readonly flywheelSettingsFields: string[];
  readonly performanceFields: string[];
  readonly startDate: string;
  readonly endDate: string;
}

export interface CampaignDetails extends Record<string, unknown> {
  readonly campaignId: string;
  readonly campaignDetails: Details;
  readonly channelSettings: ChannelSettings;
  readonly campaignPerformance: CampaignPerformance;
  readonly flywheelSettings: TeikaSettingsCampaigns;
  readonly pendingFields?: string[];
}

export interface TeikaSettingsCampaigns {
  readonly automationStatus?: boolean;
  readonly macsTarget?: number;
  readonly minBid?: MoneyWithAmountInString;
  readonly maxBid?: MoneyWithAmountInString;
}

interface Details {
  readonly advertisingGoal?: AdvertisingGoal;
  readonly smartStructure?: SmartStructure;
  readonly campaignName?: string;
  readonly merchantCountryId?: string;
  readonly campaignPortfolioName?: string;
  readonly campaignTactic?: CampaignTactic;
  readonly campaignAdFormat?: CampaignAdFormat;
  readonly targetingType?: CampaignTargetingType;
  readonly campaignCostType?: CampaignCostType;
  readonly addedBy?: AddedBy;
  readonly groupLabel?: string[];
}

export enum CampaignAdFormat {
  ProductCollection = 'productCollection',
  Video = 'video',
  StoreSpotlight = 'storeSpotlight',
}

export interface ChannelSettings {
  readonly campaignType?: string;
  readonly status?: CampaignStatus;
  readonly budgetType?: string;
  readonly dailyBudget?: MoneyWithAmountInString;
  readonly totalBudget?: MoneyWithAmountInString;
  readonly budgetRollover?: boolean;
  readonly carouselStatus?: string;
  readonly itemBuyBox?: string;
  readonly itemCarousel?: string;
  readonly placementBidMultiplier?: PlacementBidMultiplier;
  readonly platformBidMultiplier?: PlatformBidMultiplier;
  readonly campaignServingStatus?: CampaignServingStatus;
  readonly bidOptimization?: boolean;
  readonly createdAt?: string;
  readonly startDate: string;
  readonly endDate?: string;
  readonly biddingStrategy?: AmazonBiddingStrategy;
}

export interface PlacementBidMultiplier {
  readonly buyBoxBidMultiplier: number;
  readonly homePageBidMultiplier: number;
  readonly searchIngridBidMultiplier: number;
  readonly stockUpBidMultiplier: number;
  readonly productPageBidMultiplier?: number;
  readonly topOfSearchBidMultiplier?: number;
  readonly belowTopOfSearchBidMultiplier?: number;
}

export interface PlatformBidMultiplier {
  readonly [key: string]: number;
  readonly mobileBidMultiplier: number;
  readonly desktopBidMultiplier: number;
  readonly appBidMultiplier: number;
}

interface CampaignPerformance {
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly roasTotal?: number;
  readonly acosTotal?: number;
  readonly unitsSold?: number;
  readonly conversionRate?: number;
  readonly clicks?: number;
  readonly impressions?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
  readonly adSalesDirect?: MoneyWithAmountInString;
  readonly roasDirect?: number;
  readonly acosDirect?: number;
  readonly relatedClickRevenue?: MoneyWithAmountInString;
  readonly brandClickRevenue?: MoneyWithAmountInString;
  readonly adConversions?: number;
  readonly newToBrandOrders?: number;
  readonly newToBrandOrdersPercentage?: number;
  readonly newToBrandOrderRate?: number;
  readonly newToBrandSales?: MoneyWithAmountInString;
  readonly newToBrandSalesPercentage?: number;
  readonly newToBrandAdUnitsSold?: number;
  readonly newToBrandAdUnitsSoldPercentage?: number;
  readonly advertisedSkuUnits?: number;
  readonly otherSkuUnits?: number;
  readonly videoImpressions?: number;
  readonly viewableImpressions?: number;
  readonly viewThroughRate?: number;
  readonly clickThroughRateForViews?: number;
  readonly viewThroughAttributedSales?: MoneyWithAmountInString;
  readonly viewThroughUnitsSold?: number;
  readonly viewThroughOrders?: number;
  readonly viewThroughOrderRate?: number;
  readonly totalCompleteViewAttributedSales?: MoneyWithAmountInString;
  readonly advertisedViewCompleteAttributedSales?: MoneyWithAmountInString;
  readonly otherCompleteViewAttributedSales?: MoneyWithAmountInString;
  readonly completeViewOrders?: number;
  readonly completeViewUnitsSold?: number;
  readonly videoFirstQuartileViews?: number;
  readonly videoMidpointViews?: number;
  readonly videoThirdQuartileViews?: number;
  readonly videoCompleteViews?: number;
  readonly videoUnmutes?: number;
  readonly videoFiveSecondViews?: number;
  readonly videoFiveSecondViewsRate?: number;
  readonly orders?: number;
}

export interface SVAdsDetails {
  readonly name?: string;
  readonly creativeType?: string;
  readonly servingStatus?: string;
  readonly adGroupName?: string;
  readonly campaignName?: string;
  readonly adStatus?: string;
  readonly adGroupId: string;
  readonly campaignId: string;
  readonly startDate?: string;
}

export interface SVAdsPerformance {
  readonly impressions?: number;
  readonly clicks?: number;
  readonly clickThroughRate?: number;
  readonly averageCostPerClick?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly roas?: number;
  readonly totalAttributedSales?: MoneyWithAmountInString;
  readonly orders?: number;
  readonly unitsSold?: number;
  readonly conversionRate?: number;
  readonly videoImpressions?: number;
  readonly viewableImpressions?: number;
  readonly viewThroughRate?: number;
  readonly clickThroughRateForViews?: number;
  readonly viewThroughAttributedSales?: MoneyWithAmountInString;
  readonly viewThroughOrders?: number;
  readonly viewThroughUnitsSold?: number;
  readonly totalCompleteViewAttributedSales?: MoneyWithAmountInString;
  readonly advertisedCompleteViewsAttributedSales?: MoneyWithAmountInString;
  readonly otherCompleteViewAttributedSales?: MoneyWithAmountInString;
  readonly completeViewOrders?: number;
  readonly completeViewUnitsSold?: number;
  readonly videoFirstQuartileViews?: number;
  readonly videoMidpointViews?: number;
  readonly videoThirdQuartileViews?: number;
  readonly videoCompleteViews?: number;
  readonly videoUnmutes?: number;
  readonly videoFiveSecondViews?: number;
  readonly videoFiveSecondViewsRate?: number;
}

export interface SVAdsEntity extends Record<string, unknown> {
  readonly adId: string;
  readonly svAdsDetails: SVAdsDetails;
  readonly svAdsPerformance: SVAdsPerformance;
  readonly pendingFields?: string[];
}

type DetailsType =
  | string
  | CampaignTargetingType
  | CampaignTactic
  | MoneyWithAmountInString
  | ProductReviewStatus
  | AdGroupReviewStatus
  | undefined
  | boolean
  | string[];

type DetailTargetsType =
  | string
  | CampaignTactic
  | TargetTextExpressionTypeView[]
  | TargetTextExpressionTypeViewV2[]
  | MoneyWithAmountInString
  | CampaignAdFormat
  | KeywordReviewStatus
  | AdGroupReviewStatus
  | undefined
  | boolean;

type ProfileDetailsType =
  | string
  | CampaignTargetingType
  | CampaignTactic
  | MoneyWithAmountInString
  | AdGroupStatus
  | CampaignStatus
  | AdGroupReviewStatus
  | ProfileReviewStatus
  | undefined;

interface ItemDetails {
  readonly [key: string]: DetailsType;

  readonly productName: string;
  readonly productImage: string;
  readonly addedBy?: string;
  readonly adGroupName: string;
  readonly adGroupId: string;
  readonly adGroupStatus: AdGroupStatus;
  readonly campaignName: string;
  readonly campaignId: string;
  readonly campaignType: string;
  readonly campaignTargetingType: CampaignTargetingType;
  readonly campaignStatus?: CampaignStatus;
  readonly merchantCountryId: string;
  readonly itemPageUrl?: string;
  readonly extItemId?: string;
  readonly sku?: string;
  readonly campaignTactic?: CampaignTactic;
  readonly campaignDailyBudget?: MoneyWithAmountInString;
  readonly campaignTotalBudget?: MoneyWithAmountInString;
  readonly startDate?: string;
  readonly campaignPortfolioName?: string;
  readonly campaignCostType?: CampaignCostType;
  readonly adItemReviewStatus?: ProductReviewStatus;
  readonly adGroupReviewStatus?: AdGroupReviewStatus;
  readonly advertisingGoal?: AdvertisingGoal;
}

export enum FlywheelSalesChannel {
  Walmart = 'walmart',
  Amazon = 'amazon',
}

interface ItemSettings {
  readonly status: ProductAdStatusValue;
  readonly bid?: MoneyWithAmountInString;
}

interface ProductAdPerformance {
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly roasTotal?: number;
  readonly acosTotal?: number;
  readonly unitsSold?: number;
  readonly conversionRate?: number;
  readonly clicks?: number;
  readonly impressions?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
  readonly adSalesDirect?: MoneyWithAmountInString;
  readonly roasDirect?: number;
  readonly acosDirect?: number;
  readonly relatedClickRevenue?: MoneyWithAmountInString;
  readonly brandClickRevenue?: MoneyWithAmountInString;
  readonly adConversions?: number;
  readonly newToBrandSales?: MoneyWithAmountInString;
  readonly newToBrandAdUnitsSold?: number;
  readonly newToBrandOrders?: number;
  readonly advertisedSkuUnits?: number;
  readonly otherSkuUnits?: number;
}

export interface ProductAdsDataRequest {
  readonly merchantCountryIds: string[];
  readonly adItemDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly performanceFields: string[];
  readonly startDate: string;
  readonly endDate: string;
}

export interface ProductAdDetails extends Record<any, unknown> {
  readonly adItemId: string;
  readonly adItemDetails: ItemDetails & SVAdsDetails;
  readonly channelSettings: ItemSettings;
  readonly adItemPerformance: ProductAdPerformance & SVAdsPerformance;
  readonly pendingFields?: string[];
}

export enum MerchantCountryCode {
  AE = 'AE',
  AU = 'AU',
  BR = 'BR',
  CA = 'CA',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  IN = 'IN',
  IT = 'IT',
  JP = 'JP',
  KSA = 'KSA',
  MX = 'MX',
  NL = 'NL',
  SG = 'SG',
  UK = 'UK',
  US = 'US',
  GB = 'GB',
  SE = 'SE',
  SA = 'SA',
  BE = 'BE',
}

export enum AdLevel {
  Campaigns = 'campaigns',
  AdGroups = 'adGroups',
  Targets = 'targets',
  ProductAds = 'productAds',
  Profiles = 'profiles',
  KeywordTargets = 'keywordTargets',
  ProductTargets = 'productTargets',
  SbAds = 'sbAds',
  SvAds = 'svAds',
}

export enum AdType {
  'SponsoredProducts' = 'sponsoredProducts',
  'SearchBrandAmplifier' = 'searchBrandAmplifier',
  'SponsoredBrands' = 'sponsoredBrands',
  'SponsoredDisplay' = 'sponsoredDisplay',
  'SponsoredBrandsVideo' = 'sponsoredBrandsVideo',
  'SponsoredVideos' = 'sponsoredVideo',
}

export enum AutomationStatus {
  Inactive = 'false',
  Active = 'true',
  Unset = 'Unset',
}

export enum AmazonBiddingStrategy {
  'DynamicBidsDownOnly' = 'legacyForSales',
  'DynamicBidsUpAndDown' = 'autoForSales',
  'FixedBid' = 'manual',
}

export enum AutomationStatusFilterValue {
  Active = 'true',
  Inactive = 'false',
  Unset = 'unset',
}

export enum MatchType {
  Exact = 'exact',
  Phrase = 'phrase',
  Broad = 'broad',
  NegativeExact = 'negativeExact',
  NegativePhrase = 'negativePhrase',
}

export enum TargetType {
  Generic = 'Generic',
  Competitor = 'Competitor',
  Brand = 'Brand',
  Auto = 'Auto',
  Empty = 'empty',
  NoTag = 'NoTag',
}

export enum TargetTypeFilter {
  Generic = 'generic',
  Competitor = 'competitor',
  Brand = 'brand',
  Auto = 'auto',
  NoTag = 'NoTag',
  NoLabel = 'noLabel',
}

export enum FlywheelAIEnabled {
  FlywheelAIAdded = 'true',
  ManuallyAdded = 'false',
}

export enum TargetStatus {
  Enabled = 'enabled',
  Paused = 'paused',
  Archived = 'archived',
  Deleted = 'deleted',
  Pending = 'pending',
}

export enum AdGroupStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Paused = 'paused',
  Archived = 'archived',
}

export enum CampaignStatus {
  Enabled = 'enabled',
  Paused = 'paused',
  Completed = 'completed',
  Live = 'live',
  Extend = 'extend',
  Rescheduled = 'rescheduled',
  Scheduled = 'scheduled',
  Proposal = 'proposal',
  Archived = 'archived',
  Pending = 'pending',
  Draft = 'draft',
}

export enum ProfileStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export enum BudgetType {
  Daily = 'daily',
  Total = 'total',
  Both = 'both',
  Lifetime = 'lifetime',
}

export enum BudgetRollover {
  True = 'true',
  False = 'false',
}

export enum PlacementStatus {
  Excluded = 'excluded',
  Included = 'included',
}

export interface LabelValue<T> {
  readonly label: string;
  readonly value: T;
}

export type MerchantCountryAdTypeConfig = Partial<{
  readonly [merchantCountry in MerchantCountryCode]: AdType[];
}>;

export type AdLevelConfig = Partial<{ readonly [adType in AdType]: AdLevel[] }>;

export interface DataInspectionRequest {
  readonly salesChannel: FlywheelSalesChannel;
  readonly adsManagerDetailsPageFlag: boolean;
  readonly adLevel: AdLevel;
  readonly showSponsoredVideos?: boolean;
}

export interface DataInspectionResponse {
  readonly dataInspection: DataInspectionList[];
}

export interface DataInspectionList {
  readonly merchantCountryCode: MerchantCountryCode;
  readonly advertisingTypeInfo: AdvertisingTypeInfo[];
}

export interface DataAvailabilityInfoResponseType {
  readonly syncPerMerchantIds: DataAvailabilityInfo[];
}

export interface DataAvailabilityInfoApiResponse {
  readonly syncPerMerchantIds: DataAvailabilityInfoResponse[];
}

export interface DataAvailabilityInfoResponse {
  readonly merchantCountryId: string;
  readonly earliestAvailableDate: string;
  readonly latestAvailableDate: string;
  readonly lastSyncedAt: string;
}

export interface DataAvailabilityInfo {
  readonly merchantCountryId: string;
  readonly earliestAvailableDate: Date;
  readonly latestAvailableDate: Date;
  readonly lastSyncedAt: Date; //not currently being used an other part of the application.placefolder for value returned in response.
}

export interface PortfolioResponse {
  readonly portfolios: Portfolio[];
}

export interface Portfolio {
  readonly name: string;
  readonly merchantCountryId?: string;
  readonly portFolioId?: string;
}

export interface TargetTypes {
  readonly targetTypes: string[];
}

export interface SyncDate {
  readonly earliestAvailableDate?: string;
  readonly latestAvailableDate?: string;
  readonly lastSyncedAt?: string;
}

export interface MerchantSyncDate extends SyncDate {
  readonly merchantCountryId: string;
  readonly ingestionStatus?: IngestionStatus;
}

export interface MerchantSyncData {
  readonly adConnectionData?: MerchantSyncDate;
  readonly productConnectionData?: MerchantSyncDate;
}

export interface DataSyncInfoResponse extends SyncDate {
  readonly syncPerMerchantIds?: Array<MerchantSyncDate>;
}

export interface AdvertisingTypeInfo {
  readonly adType: AdType;
  readonly adLevels: AdLevel[];
}

export enum AdLevelApiEndpoint {
  Campaigns = 'campaigns',
  Adgroups = 'adgroups',
  ProductAds = 'aditems',
  Targets = 'targets',
  PageType = 'pageType',
  Platform = 'platform',
  KeywordTargets = 'targets',
  ProductTargets = 'productTargets',
  Profiles = 'profiles',
  SbAds = 'ad-creatives',
  SvAds = 'aditems',
}

export interface AdGroupDataRequest {
  readonly merchantCountryIds: string[];
  readonly adGroupDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly flywheelSettingsFields: string[];
  readonly performanceFields: string[];
  readonly startDate: string;
  readonly endDate: string;
  readonly smartAcosFieldsIncluded?: boolean;
}

export interface UpdateCampaignChannelSettingsRequest {
  readonly updates: CampaignsChannelSettingsUpdateInfo[];
  readonly channelId: string;
}

export interface UpdateAdgroupChannelSettingsRequest {
  readonly updates: EditAdgroupsChannelSettingRow[];
  readonly extrenalServiceRequest?: boolean;
}

export interface UpdateFlywheelSettingsRequest {
  readonly channelId: string;
  readonly creates: Partial<FlywheelSettingsUpdateInfo>[];
  readonly updates: Partial<FlywheelSettingsUpdateInfo>[];
  readonly merchantCountryId?: string;
}

export interface CampaignsChannelSettingsEditInfo {
  readonly buyBoxBidMultiplier?: number;
  readonly campaignName?: string;
  readonly carouselStatus?: string;
  readonly itemBuyBox?: string;
  readonly itemCarousel?: string;
  readonly dailyBudget?: MoneyWithAmountInString;
  readonly desktopBidMultiplier?: number;
  readonly endDate?: string | null;
  readonly bidOptimization?: boolean;
  readonly belowTopOfSearchBidMultiplier?: number;
  readonly homePageBidMultiplier?: number;
  readonly appBidMultiplier?: number;
  readonly mobileBidMultiplier?: number;
  readonly searchIngridBidMultiplier?: number;
  readonly status?: string;
  readonly stockUpBidMultiplier?: number;
  readonly totalBudget?: MoneyWithAmountInString;
  readonly budgetRollover?: boolean;
  readonly portfolio?: string | null;
  readonly biddingStrategy?: string;
  readonly topOfSearchBidMultiplier?: number;
  readonly productPageBidMultiplier?: number;
  readonly noPortfolio?: boolean;
  readonly advertisingGoal?: string;
}

export interface CampaignsChannelSettingsUpdateInfo {
  readonly campaignId: string;
  readonly edits: CampaignsChannelSettingsEditInfo;
}

export enum EntityType {
  Campaign = 'campaign',
  AdGroup = 'adgroup',
}

export interface FlywheelSettingsUpdateInfo {
  readonly entityId: string;
  readonly bidAutomationEnabled: boolean;
  readonly macsTarget: number;
  readonly minBid: MoneyWithAmountInString;
  readonly maxBid: MoneyWithAmountInString;
  readonly entityType: EntityType;
  readonly aiRecommendationKeywordSetting?: string;
  readonly aiBidding: string;
  readonly bidModifier: number;
  readonly overrideEndTime: string;
  readonly overrideBidModifier: number;
  readonly forceMakeAdGroupFlywheelAi: boolean;
  readonly minBidOverrideEnabled?: boolean;
  readonly maxBidOverrideEnabled?: boolean;
}

export interface EditAdgroupsChannelSettingRow {
  readonly adGroupId: string;
  readonly edits: EditAdgroupsChannelSettingFields;
  readonly merchantCountryId?: string;
  readonly updatedBy?: string;
  readonly salesChannelId?: string;
}

export interface EditTargetChannelSettingRow {
  readonly targetId: string;
  readonly edits: EditTargetFields;
}

export interface EditAdgroupsChannelSettingFields {
  readonly name?: string;
  readonly status?: string;
  readonly defaultBid?: MoneyWithAmountInString;
  readonly adGroupReviewStatus?: string;
  readonly targetSegments?: string[];
  readonly aiRecommendationKeywordSetting?: string;
}

export interface AdGroupDetails extends Record<any, unknown> {
  readonly adGroupId: string;
  readonly adGroupDetails: DetailAdGroup;
  readonly channelSettings: ChannelSettingsAdgroup;
  readonly flywheelSettings: TeikaSettingsAdgroup;
  readonly adGroupPerformance: PerformanceAdgroup;
  readonly pendingFields?: string[];
}

export interface DetailAdGroup {
  readonly [key: string]: DetailsType;

  readonly adGroupName?: string;
  readonly adGroupType?: string;
  readonly campaignName?: string;
  readonly campaignId?: string;
  readonly campaignType?: string;
  readonly campaignTargetingType?: CampaignTargetingType;
  readonly campaignStatus?: CampaignStatus;
  readonly campaignTactic?: CampaignTactic;
  readonly campaignDailyBudget?: MoneyWithAmountInString;
  readonly campaignTotalBudget?: MoneyWithAmountInString;
  readonly campaignAdFormat?: CampaignAdFormat;
  readonly merchantCountryId?: string;
  readonly startDate?: string;
  readonly campaignPortfolioName?: string;
  readonly campaignCostType?: CampaignCostType;
  readonly isBiddable?: boolean;
  readonly adGroupLabel?: string[];
  readonly targetSegments?: string[];
  readonly aiRecommendationKeywordSetting?: AiRecommendedKeywordType;
  readonly adGroupUseCase?: AdGroupUseCaseType;
  readonly addedBy?: string;
  readonly groupLabel?: string[];
}

export enum AdGroupUseCaseType {
  ProductAttributeTargeting = 'product_attribute_targeting',
  KeywordTargeting = 'keyword_targeting',
}

export interface ChannelSettingsAdgroup {
  readonly status?: AdGroupStatus;
  readonly defaultBid?: MoneyWithAmountInString;
  readonly adGroupReviewStatus?: AdGroupReviewStatus;
  readonly createdAt?: string;
  readonly isEditable: boolean;
}

export interface TeikaSettingsAdgroup {
  readonly automationStatus?: boolean;
  readonly macsTarget?: number;
  readonly minBid?: MoneyWithAmountInString;
  readonly maxBid?: MoneyWithAmountInString;
  readonly aiBidding?: AiBiddingValues;
  readonly bidModifier?: number;
  readonly avgPreAdGrossMargin?: number;
  readonly isCogs?: boolean;
  readonly isOverrideBidModifier?: boolean;
  readonly overrideEndTime?: string;
  readonly advertisingGoal?: AdvertisingGoal;
  readonly minBidRestrictedEntities?: number;
  readonly maxBidRestrictedEntities?: number;
  readonly minBidOverrideEnabled?: boolean;
  readonly maxBidOverrideEnabled?: boolean;
}

export interface SmartAcosFwSettingParams {
  readonly aiBidding: string;
  readonly acosLimit: string;
  readonly bidModifier: string;
}

export enum AiBiddingValues {
  Smart = '1',
  Ai = '3',
  NotEnabled = '4',
}

interface PerformanceAdgroup {
  readonly impressions?: number;
  readonly clicks?: number;
  readonly adSpend?: MoneyWithAmountInString;
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly unitsSold?: number;
  readonly conversionRate?: number;
  readonly acosTotal?: number;
  readonly roasTotal?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
  readonly adSalesDirect?: MoneyWithAmountInString;
  readonly acosDirect?: number;
  readonly roasDirect?: number;
  readonly relatedClickRevenue?: MoneyWithAmountInString;
  readonly brandClickRevenue?: MoneyWithAmountInString;
  readonly totalConversions?: number;
  readonly adConversions?: number;
  readonly newToBrandSales?: MoneyWithAmountInString;
  readonly newToBrandAdUnitsSold?: number;
  readonly newToBrandOrders?: number;
  readonly advertisedSkuUnits?: number;
  readonly otherSkuUnits?: number;
  readonly totalAttributedSales?: number;
  readonly orders?: number;
  readonly videoImpressions?: number;
  readonly viewableImpressions?: number;
  readonly viewThroughRate?: number;
  readonly clickThroughRateForViews?: number;
  readonly viewThroughAttributedSales?: MoneyWithAmountInString;
  readonly viewThroughOrders?: number;
  readonly viewThroughUnitsSold?: number;
  readonly totalCompleteViewAttributedSales?: MoneyWithAmountInString;
  readonly advertisedCompleteViewAttributedSales?: MoneyWithAmountInString;
  readonly otherCompleteViewAttributedSales?: MoneyWithAmountInString;
  readonly completeViewOrders?: number;
  readonly completeViewUnitsSold?: number;
  readonly videoFirstQuartileViews?: number;
  readonly videoMidpointViews?: number;
  readonly videoThirdQuartileViews?: number;
  readonly videoCompleteViews?: number;
  readonly videoUnmutes?: number;
  readonly videoFiveSecondViews?: number;
  readonly videoFiveSecondViewsRate?: number;
}

export type FlywheelTableRowCell<T> = {
  isEditMode: boolean;
  data: T;
};

export interface DateRange {
  readonly initialStartDate: DateTime;
  readonly initialEndDate: DateTime;
  readonly minDate: DateTime;
  readonly maxDate: DateTime;
}

export interface TargetsDataRequest {
  readonly merchantCountryIds: string[];
  readonly targetDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly performanceFields: string[];
  readonly startDate: string;
  readonly endDate: string;
}

export enum ExpressionType {
  Purchases = 'purchases',
  Views = 'views',
  Audience = 'audience',
  AsinSameAs = 'asinSameAs',
  ASIN_SAME_AS = 'ASIN_SAME_AS',
  AsinExpandedFrom = 'asinExpandedFrom',
  ASIN_EXPANDED_FROM = 'ASIN_EXPANDED_FROM',
  AsinCategorySameAs = 'asinCategorySameAs',
  ASIN_CATEGORY_SAME_AS = 'ASIN_CATEGORY_SAME_AS',
  AsinBrandSameAs = 'asinBrandSameAs',
  ASIN_BRAND_SAME_AS = 'ASIN_BRAND_SAME_AS',
  AsinPriceLessThan = 'asinPriceLessThan',
  ASIN_PRICE_LESS_THAN = 'ASIN_PRICE_LESS_THAN',
  AsinPriceGreaterThan = 'asinPriceGreaterThan',
  ASIN_PRICE_GREATER_THAN = 'ASIN_PRICE_GREATER_THAN',
  AsinPriceBetween = 'asinPriceBetween',
  ASIN_PRICE_BETWEEN = 'ASIN_PRICE_BETWEEN',
  ASIN_REVIEW_RATING_LESS_THAN = 'ASIN_REVIEW_RATING_LESS_THAN',
  AsinReviewRatingLessThan = 'asinReviewRatingLessThan',
  ASIN_REVIEW_RATING_GREATER_THAN = 'ASIN_REVIEW_RATING_GREATER_THAN',
  AsinReviewRatingGreaterThan = 'asinReviewRatingGreaterThan',
  ASIN_REVIEW_RATING_BETWEEN = 'ASIN_REVIEW_RATING_BETWEEN',
  AsinReviewRatingBetween = 'asinReviewRatingBetween',
  AsinIsPrimeShippingAvailable = 'asinIsPrimeShippingAvailable',
  AsinAgeRangeSameAs = 'asinAgeRangeSameAs',
  AsinGenreSameAs = 'asinGenreSameAs',
  Lookback = 'lookback',
  AsinSubstituteRelated = 'asinSubstituteRelated',
  ASIN_SUBSTITUTE_RELATED = 'ASIN_SUBSTITUTE_RELATED',
  AsinAccessoryRelated = 'asinAccessoryRelated',
  ASIN_ACCESSORY_RELATED = 'ASIN_ACCESSORY_RELATED',
  QueryBroadRelMatches = 'queryBroadRelMatches',
  QUERY_BROAD_REL_MATCHES = 'QUERY_BROAD_REL_MATCHES',
  QueryHighRelMatches = 'queryHighRelMatches',
  QUERY_HIGH_REL_MATCHES = 'QUERY_HIGH_REL_MATCHES',
  SimilarProduct = 'similarProduct',
  ExactProduct = 'exactProduct',
  AsinIsPrimeShippingEligible = 'asinIsPrimeShippingEligible',
  AudienceSameAs = 'audienceSameAs',
  RelatedProduct = 'relatedProduct',
}

export interface TargetTextExpressionTypeView {
  readonly type: ExpressionType;
  readonly value?: string;
}

export interface TargetTextExpressionTypeViewV2 {
  readonly type?: ExpressionType;
  readonly value: {
    readonly type: ExpressionType;
    readonly value?: string;
  }[];
}

export interface TargetsDetails extends Record<string, unknown> {
  readonly targetId: string;
  readonly targetDetails: DetailTargets;
  readonly channelSettings: ChannelSettingsTargets;
  readonly targetPerformance: PerformanceTargets;
  readonly pendingFields?: string[];
}

export enum ProductReviewStatusType {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

interface ProductReviewStatus {
  readonly reviewStatus: ProductReviewStatusType;
  readonly reviewComments?: string;
}

export enum KeywordReviewStatusType {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum AdGroupReviewStatusType {
  Pending = 'pending',
  InProgress = 'inprogress',
  Complete = 'complete',
  Cancelled = 'cancelled',
}

interface KeywordReviewStatus {
  readonly reviewStatus: KeywordReviewStatusType;
  readonly reviewComments?: string;
}

export interface AdGroupReviewReason {
  readonly commentType: string;
  readonly comments: string;
}

export interface AdGroupReviewStatus {
  readonly reviewStatus: AdGroupReviewStatusType;
  readonly reviewReason?: AdGroupReviewReason[];
  readonly reviewComments?: string;
}

export enum AddedBy {
  FlywheelAccepted = 'tre_recommendation_user_accepted',
  FlywheelAutomated = 'flywheel_ai',
  CampaignCreatorAiRecommended = 'cc_ai_recommended',
  AdsManagerOnDemand = 'ads_manager_on_demand',
  CampaignCreatorOnDemand = 'cc_on_demand',
  ChatGPT = 'chat_gpt',
  FlywheelOnDemand = 'flywheel_on_demand', // will be removed in future
  External = 'external',
  CampaignCreatorV1 = 'campaign_creator_v1',
  FlywheelAutoNegate = 'flywheel_auto_negate',
}

export enum KeywordTargetType {
  Keyword = 'Keyword',
  NegativeKeyword = 'Negative keyword',
  CampaignNegativeKeyword = 'Campaign negative keyword',
  NegativeProductTarget = 'Negative product target',
  AudienceTarget = 'Audience target',
  ProductTarget = 'Product target',
  ProductAttribute = 'Product Attribute',
}

export interface DetailTargets {
  readonly [key: string]: DetailTargetsType;

  readonly adGroupName?: string;
  readonly adGroupStatus?: AdGroupStatus;
  readonly campaignName?: string;
  readonly campaignStatus?: CampaignStatus;
  readonly campaignTargetingType?: string;
  readonly campaignType?: string;
  readonly matchType?: MatchType;
  readonly targetType?: string;
  readonly createdAt?: string;
  readonly flywheelAiEnabled?: boolean;
  readonly addedBy?: AddedBy;
  readonly targetText?: string;
  readonly campaignTactic?: CampaignTactic;
  readonly entityType?: string;
  readonly targetingExpressionType?: string;
  readonly viewsTargetText?: TargetTextExpressionTypeView[];
  readonly viewsTargetTextV1?: TargetTextExpressionTypeViewV2[];
  readonly campaignDailyBudget?: MoneyWithAmountInString;
  readonly genre?: string;
  readonly ageRange?: string;
  readonly brand?: string;
  readonly targetingClause?: string;
  readonly dailyBudget?: MoneyWithAmountInString;
  readonly campaignTotalBudget?: MoneyWithAmountInString;
  readonly nativeLanguageKeyword?: string;
  readonly campaignAdFormat?: CampaignAdFormat;
  readonly merchantCountryId?: string;
  readonly adGroupId?: string;
  readonly campaignId?: string;
  readonly startDate?: string;
  readonly campaignPortfolioName?: string;
  readonly campaignCostType?: CampaignCostType;
  readonly keywordReviewStatus?: KeywordReviewStatus;
  readonly adGroupReviewStatus?: AdGroupReviewStatus;
  readonly isBiddable?: boolean;
  readonly keywordType?: KeywordTargetType;
  readonly advertisingGoal?: AdvertisingGoal;
}

interface ChannelSettingsTargets {
  readonly bid?: MoneyWithAmountInString;
  readonly status?: TargetStatus;
}

interface PerformanceTargets {
  readonly impressions?: number;
  readonly clicks?: number;
  readonly adSpend?: MoneyWithAmountInString;
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly unitsSold?: number;
  readonly conversionRate?: number;
  readonly acosTotal?: number;
  readonly roasTotal?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
  readonly adSalesDirect?: MoneyWithAmountInString;
  readonly acosDirect?: number;
  readonly roasDirect?: number;
  readonly relatedClickRevenue?: MoneyWithAmountInString;
  readonly brandClickRevenue?: MoneyWithAmountInString;
  readonly adConversions?: number;
  readonly newToBrandOrders?: number;
  readonly newToBrandAdUnitsSold?: number;
  readonly adConversionRate?: number;
  readonly newToBrandOrdersPercentage?: number;
  readonly newToBrandOrderRate?: number;
  readonly newToBrandSales?: MoneyWithAmountInString;
  readonly newToBrandSalesPercentage?: number;
  readonly newToBrandAdUnitsSoldPercentage?: number;
  readonly advertisedSkuUnits?: number;
  readonly otherSkuUnits?: number;
  readonly totalAttributedSales?: number;
  readonly orders?: number;
  readonly videoImpressions?: number;
  readonly viewableImpressions?: number;
  readonly viewThroughRate?: number;
  readonly clickThroughRateForViews?: number;
  readonly viewThroughAttributedSales?: MoneyWithAmountInString;
  readonly viewThroughOrders?: number;
  readonly viewThroughUnitsSold?: number;
  readonly totalCompleteViewAttributedSales?: MoneyWithAmountInString;
  readonly advertisedCompleteViewAttributedSales?: MoneyWithAmountInString;
  readonly otherCompleteViewAttributedSales?: MoneyWithAmountInString;
  readonly completeViewOrders?: number;
  readonly completeViewUnitsSold?: number;
  readonly videoFirstQuartileViews?: number;
  readonly videoMidpointViews?: number;
  readonly videoThirdQuartileViews?: number;
  readonly videoCompleteViews?: number;
  readonly videoUnmutes?: number;
  readonly videoFiveSecondViews?: number;
  readonly videoFiveSecondViewsRate?: number;
}

export interface EditProductAdRequest {
  readonly updates: EditProductAdRow[];
}

export interface EditProfileRequest {
  readonly updates: EditProfileRow[];
}

export interface EditProductAdRow {
  readonly adItemId: string;
  readonly edits: EditProductAdFields;
  readonly merchantCountryId: string;
}

export interface EditProfileRow {
  readonly profileId: string;
  readonly edits: EditProfileFields;
}

export interface EditProfileFields {
  readonly profileStatus?: string;
  readonly brand?: string;
  readonly clickUrl?: string;
  readonly headline?: string;
}

export interface EditProductAdFields {
  readonly status?: string;
  readonly bid?: MoneyWithAmountInString;
}

export interface EditTargetFields {
  readonly status?: string;
  readonly bid?: MoneyWithAmountInString;
  readonly targetSegment?: string;
}

export interface EditTargetsRow {
  readonly targetId: string;
  readonly edits: EditTargetFields;
  readonly merchantCountryId?: string;
}

export interface EditTargetsRequest {
  readonly updates: EditTargetsRow[];
}

export interface FilterFieldMapper {
  readonly field: string;
  readonly alias: string;
}

export enum MerchantPickerType {
  Single = 'Single',
  Multi = 'Multi',
}

export const FLYWHEEL_CHANNEL_TO_MERCHANT_PICKER_TYPE_MAPPER: Record<
  FlywheelSalesChannel,
  MerchantPickerType
> = {
  [FlywheelSalesChannel.Amazon]: MerchantPickerType.Single,
  [FlywheelSalesChannel.Walmart]: MerchantPickerType.Multi,
};

export const SALES_CHANNEL_TO_PERFORMANCE_FIELD_MAPPER_V2: Record<
  FlywheelSalesChannel,
  AOKPIMetrics[]
> = {
  [FlywheelSalesChannel.Amazon]: [
    AOKPIMetrics.ACoS,
    AOKPIMetrics.AdSales,
    AOKPIMetrics.AdSpend,
    AOKPIMetrics.AdConversions,
    AOKPIMetrics.ConversionRate,
    AOKPIMetrics.Clicks,
    AOKPIMetrics.ClickThroughRate,
    AOKPIMetrics.CostPerClick,
    AOKPIMetrics.Impressions,
    AOKPIMetrics.ROAS,
    AOKPIMetrics.UnitsSold,
  ],
  [FlywheelSalesChannel.Walmart]: [
    AOKPIMetrics.ACoS,
    AOKPIMetrics.ACoSDirect,
    AOKPIMetrics.AdSales,
    AOKPIMetrics.AdSalesDirect,
    AOKPIMetrics.AdSpend,
    AOKPIMetrics.AdConversions,
    AOKPIMetrics.ConversionRate,
    AOKPIMetrics.ClickThroughRate,
    AOKPIMetrics.CostPerClick,
    AOKPIMetrics.Clicks,
    AOKPIMetrics.Impressions,
    AOKPIMetrics.ROAS,
    AOKPIMetrics.ROASDirect,
    AOKPIMetrics.UnitsSold,
  ],
};

export enum CampaignTactic {
  Legacy = 'T00001',
  Product = 'T00020',
  Audiences = 'T00030',
  Views = 'remarketing',
}

type AdLevelDetailsProps = Partial<{
  readonly [adLevel in AdLevel]: AdLevel[];
}>;

type AdTypeDetailsProps = Partial<{
  readonly [adType in AdType]: AdLevelDetailsProps;
}>;

type SalesChannelDetailsProps = {
  readonly [salesChannel in FlywheelSalesChannel]: AdTypeDetailsProps;
};

export const SALES_CHANNEL_DETAILS_AD_LEVEL_CONFIG: SalesChannelDetailsProps = {
  [FlywheelSalesChannel.Walmart]: {
    [AdType.SponsoredProducts]: {
      [AdLevel.Campaigns]: [AdLevel.AdGroups, AdLevel.KeywordTargets],
      [AdLevel.AdGroups]: [AdLevel.ProductAds, AdLevel.KeywordTargets],
      [AdLevel.ProductAds]: [AdLevel.ProductAds, AdLevel.KeywordTargets],
      [AdLevel.KeywordTargets]: [AdLevel.KeywordTargets],
    },
    [AdType.SearchBrandAmplifier]: {
      [AdLevel.Campaigns]: [AdLevel.AdGroups],
      [AdLevel.AdGroups]: [
        AdLevel.Profiles,
        AdLevel.ProductAds,
        AdLevel.KeywordTargets,
      ],
    },
    [AdType.SponsoredVideos]: {
      [AdLevel.Campaigns]: [AdLevel.AdGroups],
      [AdLevel.AdGroups]: [AdLevel.SvAds, AdLevel.KeywordTargets],
    },
  },
  [FlywheelSalesChannel.Amazon]: {
    [AdType.SponsoredProducts]: {
      [AdLevel.Campaigns]: [AdLevel.AdGroups, AdLevel.KeywordTargets],
      [AdLevel.AdGroups]: [
        AdLevel.ProductAds,
        AdLevel.KeywordTargets,
        AdLevel.ProductTargets,
      ],
      [AdLevel.ProductAds]: [
        AdLevel.ProductAds,
        AdLevel.KeywordTargets,
        AdLevel.ProductTargets,
      ],
      [AdLevel.KeywordTargets]: [AdLevel.KeywordTargets],
      [AdLevel.ProductTargets]: [AdLevel.ProductTargets],
    },
    [AdType.SponsoredBrands]: {
      [AdLevel.Campaigns]: [
        AdLevel.AdGroups,
        AdLevel.SbAds,
        AdLevel.KeywordTargets,
        AdLevel.ProductTargets,
      ],
      [AdLevel.AdGroups]: [AdLevel.KeywordTargets, AdLevel.ProductTargets],
      [AdLevel.KeywordTargets]: [AdLevel.KeywordTargets],
      [AdLevel.ProductTargets]: [AdLevel.ProductTargets],
    },
    [AdType.SponsoredDisplay]: {
      [AdLevel.Campaigns]: [AdLevel.AdGroups],
      [AdLevel.AdGroups]: [AdLevel.ProductAds, AdLevel.Targets],
      [AdLevel.ProductAds]: [AdLevel.ProductAds, AdLevel.Targets],
      [AdLevel.Targets]: [AdLevel.Targets],
    },
  },
};

export interface ValidateUniqueValuesRequest {
  readonly merchantCountryId: string;
  readonly entityType: EntityType;
  readonly value: string;
  readonly column: string;
  readonly parentEntityId?: string;
}

export interface BidConstraintsRequest {
  readonly salesChannelIds: string[];
}

export interface CreateTargetKeywordsRequest {
  readonly targets: CreateTargetKeywordsRequestTargetInfo[];
  readonly advertisingType: string;
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly merchantType: MerchantType;
  readonly isNegativeKeywords: boolean;
  readonly addedBy?: AddedBy;
}

export interface ChatGPTKeywordsRequest {
  readonly adGroups: {
    readonly adGroupId: string;
    readonly campaignId: string;
  }[];
  readonly matchTypes: string[];
  readonly tags: string[];
  readonly advertisingType: string;
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly merchantType: MerchantType;
}

export interface CreateTargetKeywordsRequestTargetInfo {
  readonly asin?: string;
  readonly searchValue?: string;
  readonly matchType: string;
  readonly adGroup: {
    readonly adGroupId: string;
    readonly campaignId: string;
    readonly targetSegments: TargetSegments[];
  };
}

export interface ValidateUniqueValuesResponse {
  readonly entityType: EntityType;
  readonly value: string;
  readonly column: string;
  readonly isValid: boolean;
}

export type MerchantInfo = Pick<
  MerchantCountry,
  'merchantCountryId' | 'salesChannelId' | 'merchantType'
> & {
  readonly merchantCountryName: string;
  readonly salesChannelName: string;
};

export interface SkuAdPerformanceRequest {
  readonly merchantCountries: MerchantInfo[];
  readonly advertisingFields: string[];
  readonly startDate: string;
  readonly endDate: string;
  readonly currency: string;
  readonly previousPeriodIncluded: boolean;
}

export type SkuAdPerformanceData = {
  readonly productCatalogId: string;
  readonly advertisingDetails: SkuAdPerformanceAdvertisingDetails;
  readonly currentMetrics: SkuAdPerformanceMetrics;
  readonly previousMetrics?: SkuAdPerformanceMetrics;
};

export type SkuAdPerformanceAdvertisingDetails = {
  readonly salesChannelId?: string;
  readonly merchantCountryId?: string;
  readonly campaignName?: string;
  readonly adGroupName?: string;
  readonly campaignType?: string;
  readonly adStatus?: string;
};

export type SkuAdPerformanceMetrics = {
  readonly clicks?: number;
  readonly impressions?: number;
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly acosTotal?: number;
  readonly adConversions?: number;
  readonly conversionRate?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
};

export type SkuAdPerformanceSummary = {
  readonly currentMetrics: SkuAdPerformanceMetrics;
  readonly previousMetrics: SkuAdPerformanceMetrics;
};
export const ADLEVEL_TO_ADLEVEL_IN_MANAGE_BIDDING_UPLOAD_REQUEST_MAPPER: Partial<
  Record<AdLevel, string>
> = {
  [AdLevel.Campaigns]: 'campaign',
  [AdLevel.AdGroups]: 'adgroup',
};

export interface ManageBiddingSecureUrlResponse {
  readonly url: string;
}

export interface ManageBiddingUploadStatusResponse {
  readonly jobId: number;
  readonly status: string;
}

export interface ManageBiddingProcessUploadResponse {
  readonly jobId: number;
  readonly statusApiUrl: string;
}

export interface ManageBiddingValidationResponse {
  readonly status: string;
  readonly message: string;
  readonly errorCode?: string;
  readonly validationErrorFileName?: string;
  readonly successRecordFileName?: string;
  readonly totalRows: number;
  readonly invalidRows: number;
}

export enum CampaignCostType {
  CPC = 'cpc',
  VCPM = 'vcpm',
}

export enum CompassActionCardType {
  MissingAcosTargetData = 'MissingAcosTargetData',
}

export interface MissingAcosTargetData {
  readonly completedAcosTargetCount: number;
  readonly totalAcosTargetCount: number;
  readonly missingAcos: MissingAcos[];
}

export interface MissingAcos {
  readonly salesChannelId: string;
  readonly salesChannelName: FlywheelSalesChannel;
  readonly adsDataPerMerchantCountry: AdsDataPerMerchantCountry[];
}

export enum AdvertisingType {
  'SponsoredProducts' = 'SponsoredProducts',
  'SponsoredBrands' = 'SponsoredBrands',
  'SponsoredDisplay' = 'SponsoredDisplay',
  'SponsoredVideos' = 'SponsoredVideo',
}

export interface AdsDataPerMerchantCountry {
  readonly actionCardType?: string;
  readonly merchantCountryId: string;
  readonly missingCount: number;
  readonly advertisingType: AdvertisingType;
  readonly entityType: EntityType;
  readonly country: string;
  readonly merchantName: string;
}

export const MAP_ENTITY_TYPE_TO_AD_LEVEL: Record<EntityType, AdLevel> = {
  [EntityType.Campaign]: AdLevel.Campaigns,
  [EntityType.AdGroup]: AdLevel.AdGroups,
};

export const MAP_ADVERTISING_TYPE_TO_ADTYPE: Record<AdvertisingType, AdType> = {
  [AdvertisingType.SponsoredProducts]: AdType.SponsoredProducts,
  [AdvertisingType.SponsoredDisplay]: AdType.SponsoredDisplay,
  [AdvertisingType.SponsoredBrands]: AdType.SponsoredBrands,
  [AdvertisingType.SponsoredVideos]: AdType.SponsoredVideos,
};

export const MAP_ADTYPE_TO_ADVERTISING_TYPE: Record<AdType, AdvertisingType> = {
  [AdType.SponsoredProducts]: AdvertisingType.SponsoredProducts,
  [AdType.SponsoredDisplay]: AdvertisingType.SponsoredDisplay,
  [AdType.SponsoredBrands]: AdvertisingType.SponsoredBrands,
  [AdType.SearchBrandAmplifier]: AdvertisingType.SponsoredBrands,
  [AdType.SponsoredBrandsVideo]: AdvertisingType.SponsoredBrands,
  [AdType.SponsoredVideos]: AdvertisingType.SponsoredVideos,
};

export interface RedirectStateParams {
  readonly tableMode?: DualModes;
  readonly filters?: Filter[];
}

// start of profiles data type for SBA

export interface ProfileApiDataRequest {
  readonly startDate: string;
  readonly endDate: string;
  readonly merchantCountryIds: string[];
  readonly channelId: string;
  readonly advertisingType: AdType;
  readonly salesChannelId: string;
  readonly merchantType?: MerchantType;
  readonly profileDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly performanceFields: string[];
}

export interface ProfileApiDataDetails extends Record<string, unknown> {
  readonly profileId: string;
  readonly profileDetails: ProfileDetails;
  readonly channelSettings: ProfileChannelSettings;
  readonly profilePerformance: ProfileAdGroupPerformance;
  readonly pendingFields?: string[];
}

export interface ProfileAdGroupPerformance {
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly roasTotal?: number;
  readonly acosTotal?: number;
  readonly conversionRate?: number;
  readonly clicks?: number;
  readonly impressions?: number;
  readonly unitsSold?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInNumber;
  readonly adSalesDirect?: MoneyWithAmountInNumber;
  readonly acosDirect?: number;
  readonly roasDirect?: number;
  readonly relatedClickRevenue?: MoneyWithAmountInNumber;
  readonly brandClickRevenue?: MoneyWithAmountInNumber;
  readonly advertisedSkuUnits?: number;
  readonly otherSkuUnits?: number;
}

export interface ProfileChannelSettings {
  readonly profileStatus?: ProfileStatus;
  readonly headline?: string;
  readonly clickUrl?: string;
  readonly logo?: string;
}

export interface ProfileDetails {
  readonly [key: string]: ProfileDetailsType;

  readonly brand: string;
  readonly merchantCountryId?: string;
  readonly adGroupName?: string;
  readonly adGroupId?: string;
  readonly adGroupStatus?: AdGroupStatus;
  readonly adGroupReviewStatus?: AdGroupReviewStatus;
  readonly campaignName?: string;
  readonly campaignId?: string;
  readonly campaignStatus?: CampaignStatus;
  readonly campaignTargetingType?: CampaignTargetingType;
  readonly campaignDailyBudget?: MoneyWithAmountInString;
  readonly getCampaignLifetimeBudget?: MoneyWithAmountInString;
  readonly profileReviewStatus?: ProfileReviewStatus;
  readonly advertisingGoal?: AdvertisingGoal;
}

export interface ProfileReviewStatus {
  readonly reviewStatus: ReviewStatus;
  readonly reviewReason: string;
}

export enum ReviewStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

// end of profiles data type for SBA
export const SUPPORTED_MERCHANT_COUNTRIES_PER_CHANNEL: Record<
  FlywheelSalesChannel,
  MerchantCountryCode[]
> = {
  [FlywheelSalesChannel.Amazon]: [
    MerchantCountryCode.BR,
    MerchantCountryCode.CA,
    MerchantCountryCode.ES,
    MerchantCountryCode.SG,
    MerchantCountryCode.US,
    MerchantCountryCode.FR,
    MerchantCountryCode.JP,
    MerchantCountryCode.IT,
    MerchantCountryCode.GB,
    MerchantCountryCode.AE,
    MerchantCountryCode.AU,
    MerchantCountryCode.MX,
    MerchantCountryCode.NL,
    MerchantCountryCode.IN,
    MerchantCountryCode.KSA,
    MerchantCountryCode.SA,
    MerchantCountryCode.DE,
    MerchantCountryCode.SE,
  ],
  [FlywheelSalesChannel.Walmart]: [MerchantCountryCode.US],
};

export enum TargetLabels {
  AIAdded = 'flywheelAiEnabled',
  NegTarget = 'negative',
  NoLabels = 'noLabel',
  NoTags = 'noTags',
}

export interface TreStatusCheckResponse {
  readonly status: string;
}

export enum AiRecommendedKeywordType {
  AddAutomatically = 'add_automatically',
  ReviewRecommendations = 'review_recommendations',
}

export enum CreativeType {
  ProductCollection = 'SBcreative',
  Video = 'SBVideocreative',
  StoreSpotlight = 'SBStoreSpotlight',
}

export enum AdStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Paused = 'paused',
  Archived = 'archived',
}

export enum CreativeServingStatus {
  AdStatusLive = 'AD_STATUS_LIVE',
  AdPolicingPendingReview = 'AD_POLICING_PENDING_REVIEW',
  AdPolicingSuspended = 'AD_POLICING_SUSPENDED',
  AdPaused = 'AD_PAUSED',
  AdArchived = 'AD_ARCHIVED',
  AdGroupStatusEnabled = 'AD_GROUP_STATUS_ENABLED',
  AdGroupPaused = 'AD_GROUP_PAUSED',
  AdGroupArchived = 'AD_GROUP_ARCHIVED',
  AdGroupIncomplete = 'AD_GROUP_INCOMPLETE',
  AdGroupPolicingPendingReview = 'AD_GROUP_POLICING_PENDING_REVIEW',
  AdGroupPolicingCreativeRejected = 'AD_GROUP_POLICING_CREATIVE_REJECTED',
  AdGroupLowBid = 'AD_GROUP_LOW_BID',
  AdvertiserStatusEnabled = 'ADVERTISER_STATUS_ENABLED',
  AdvertiserPolicingPendingReview = 'ADVERTISER_POLICING_PENDING_REVIEW',
  AdvertiserPolicingSuspended = 'ADVERTISER_POLICING_SUSPENDED',
  AdvertiserPaused = 'ADVERTISER_PAUSED',
  AdvertiserArchived = 'ADVERTISER_ARCHIVED',
  AdvertiserPaymentFailure = 'ADVERTISER_PAYMENT_FAILURE',
  AdvertiserAccountOutOfBudget = 'ADVERTISER_ACCOUNT_OUT_OF_BUDGET',
  AdvertiserOutOfPrepayBalance = 'ADVERTISER_OUT_OF_PREPAY_BALANCE',
  AdvertiserExceedSpendsLimit = 'ADVERTISER_EXCEED_SPENDS_LIMIT',
  CampaignStatusEnabled = 'CAMPAIGN_STATUS_ENABLED',
  CampaignPaused = 'CAMPAIGN_PAUSED',
  CampaignArchived = 'CAMPAIGN_ARCHIVED',
  CampaignIncomplete = 'CAMPAIGN_INCOMPLETE',
  CampaignOutOfBudget = 'CAMPAIGN_OUT_OF_BUDGET',
  PortfolioStatusEnabled = 'PORTFOLIO_STATUS_ENABLED',
  PortfolioPaused = 'PORTFOLIO_PAUSED',
  PortfolioArchived = 'PORTFOLIO_ARCHIVED',
  PortfolioOutOfBudget = 'PORTFOLIO_OUT_OF_BUDGET',
  PortfolioPendingStartDate = 'PORTFOLIO_PENDING_START_DATE',
  PortfolioEnded = 'PORTFOLIO_ENDED',
  Ineligible = 'INELIGIBLE',
  Eligible = 'ELIGIBLE',
  Ended = 'ENDED',
  PendingReview = 'PENDING_REVIEW',
  PendingStartDate = 'PENDING_START_DATE',
  Rejected = 'REJECTED',
  Unknown = 'UNKNOWN',
}

export interface SbAdsDataRequest {
  readonly channelId: string;
  readonly advertisingType: AdType;
  readonly salesChannelId: string;
  readonly merchantType?: MerchantType;
  readonly merchantCountryIds: string[];
  readonly creativeDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly performanceFields: string[];
  readonly startDate: string;
  readonly endDate: string;
}

export interface SvAdsDataRequest {
  readonly channelId: string;
  readonly advertisingType: AdType;
  readonly salesChannelId: string;
  readonly merchantType?: MerchantType;
  readonly merchantCountryIds: string[];
  readonly creativeDetailsFields: string[];
  readonly channelSettingsFields: string[];
  readonly performanceFields: string[];
  readonly startDate: string;
  readonly endDate: string;
}

export interface CreativeDetails {
  readonly creativeName?: string;
  readonly creativeType?: CreativeType;
  readonly creativeServingStatus?: CreativeServingStatus;
  readonly campaignDailyBudget?: MoneyWithAmountInString;
  readonly campaignName?: string;
  readonly campaignId?: string;
  readonly campaignStatus?: CampaignStatus;
  readonly campaignTargetingType?: string;
  readonly adgroupId?: string;
  readonly adGroupName?: string;
  readonly adGroupStatus?: AdGroupStatus;
  readonly merchantCountryId?: string;
  readonly portfolio?: string;
  readonly advertisingGoal?: AdvertisingGoal;
}

export interface CreativeChannelSettings {
  readonly status?: AdStatus;
}

export interface CreativePerformance {
  readonly adConversions?: number;
  readonly adSalesTotal?: MoneyWithAmountInString;
  readonly adSpend?: MoneyWithAmountInString;
  readonly roasTotal?: number;
  readonly acosTotal?: number;
  readonly unitsSold?: number;
  readonly conversionRate?: number;
  readonly clicks?: number;
  readonly impressions?: number;
  readonly clickThroughRate?: number;
  readonly avgCostPerClick?: MoneyWithAmountInString;
  readonly adSalesDirect?: MoneyWithAmountInString;
  readonly roasDirect?: number;
  readonly acosDirect?: number;
  readonly relatedClickRevenue?: MoneyWithAmountInString;
  readonly brandClickRevenue?: MoneyWithAmountInString;
  readonly newToBrandOrders?: number;
  readonly newToBrandOrdersPercentage?: number;
  readonly newToBrandOrderRate?: number;
  readonly newToBrandSales?: MoneyWithAmountInString;
  readonly newToBrandSalesPercentage?: number;
  readonly newToBrandAdUnitsSold?: number;
  readonly newToBrandAdUnitsSoldPercentage?: number;
}

export interface SbAdsDetails extends Record<string, unknown> {
  readonly creativeId: string;
  readonly creativeDetails: CreativeDetails;
  readonly channelSettings: CreativeChannelSettings;
  readonly creativePerformance: CreativePerformance;
}

export interface Brand {
  readonly brandId: string;
  readonly brandName: string;
}

export interface BrandCreateRequest {
  readonly brandNames: string;
  readonly merchantCountryId: string;
}

export interface BrandUpdateRequest {
  readonly merchantCountryId: string;
  readonly brandDetail: Brand;
}

export interface ChatGPTRequestKeywordResponse {
  readonly merchantCountryId: string;
  readonly conversationId: string;
  readonly completionId: string;
  readonly matchTypes?: string[];
}
export interface ChatGPTRequestKeywordStatus {
  readonly conversationId: string;
  readonly completionId: string;
  readonly statusDetail: {
    readonly status: string;
    readonly error?: {
      readonly code: string;
      readonly message: string;
    };
  };
}
export interface ChatGPTRequestKeywordResult {
  readonly conversationId: string;
  readonly completionId: string;
  readonly merchantCountryId: string;
  readonly keywords: {
    readonly targetText: string;
  }[];
  readonly matchTypes: MatchType[];
}

export interface ChatGPTFeedback {
  readonly rating: number;
  readonly suggestions: string[];
}

export interface FeedbackSubmitRequest {
  readonly merchantCountryId: string;
  readonly conversationId: string;
  readonly completionId: string;
  readonly feedback: ChatGPTFeedback;
}

export enum UserPreferenceType {
  Negative = 'negative',
  KeywordRecommendationSettings = 'KEYWORD_RECOMMENDATION_SETTING',
}

export interface UserPrefernceSettingsCommon {
  readonly name: string;
  readonly merchantCountryId: string;
  readonly duration: number;
  readonly adConversions: number;
  readonly clicks: number;
  readonly adSpend: MoneyWithAmountInString;
  readonly autoNegateEnabled?: boolean;
}

export interface UserPrefernceSettingsResponse
  extends UserPrefernceSettingsCommon {
  readonly lastUpdatedAt: string;
}

export interface UserPrefernceSettingsRequest
  extends UserPrefernceSettingsCommon {}

export interface UserPreferenceCommon {
  readonly criteriaType: UserPreferenceType;
}

export interface GetUserPreferenceResponse extends UserPreferenceCommon {
  readonly settings: UserPrefernceSettingsResponse[];
}

export interface SaveUserPreferenceRequest extends UserPreferenceCommon {
  readonly userId: string;
  readonly settings: UserPrefernceSettingsRequest[];
}

export interface UserAutoNegateSettings {
  readonly merchantCountryId: string;
  readonly isAutoNegateEnabled: boolean;
}

export interface SaveAutoNegateSettings {
  readonly userId: string;
  readonly settings: UserAutoNegateSettings[];
}

export interface GroupsResponse {
  readonly campaignGroups: Record<string, string>;
}

export interface Groups {
  readonly name: string;
  readonly groupId?: string;
  readonly merchantCountryId?: string;
}

export interface CampaignGroupEditRequest {
  readonly campaignGroupId: string;
  readonly newCampaignGroupName: string;
  readonly merchantCountryId: string;
}

export interface CampaignGroupCreateRequest {
  readonly campaignGroups: string[];
  readonly merchantCountryId: string;
}

export interface DeleteCampaignGroupRequest {
  readonly campaignGroups: Record<string, string>;
  readonly merchantCountryId: string;
}

export interface DeleteCampaignGroupResponse {
  readonly detailedMessage: string;
  readonly message: string;
  readonly status: string;
}

export interface AssignCampaignGroupElement {
  readonly campaignId: string;
  readonly campaignGroupId: string;
}

export interface CampaignGroupAssignRequest {
  readonly mapCampaignGroups?: AssignCampaignGroupElement[];
  readonly removeCampaignGroups?: AssignCampaignGroupElement[];
  readonly merchantCountryId: string;
}

export interface AdGroup {
  readonly name: string;
  readonly status: AdGroupStatus;
  readonly adGroupId: string;
  readonly targetSegments?: string[];
}

export interface CampaignResponse {
  readonly merchantCountryId: string;
  readonly campaignId: string;
  readonly campaignName: string;
  readonly status: CampaignStatus;
  readonly adGroups: AdGroup[];
  readonly targetingType: CampaignTargetingType;
}

export interface CampaignRequest {
  readonly merchantCountryId: string;
  readonly campaignId: string;
}

export enum ViewTrendsPriod {
  Current = 'current',
  Previous = 'previous',
}

export interface ViewTrendsGraphDataRequest {
  readonly merchantCountryId: string;
  readonly entityId: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly entityType: string;
  readonly aggregationPeriodType: ViewTrendsPriod;
  readonly channelId: string;
  readonly merchantType: MerchantType;
  readonly advertisingType: AdType;
}

export interface GraphItem {
  readonly [key: string]: string | MoneyWithAmountInString | number;
  readonly reportDate: string;
  readonly adSalesTotal: MoneyWithAmountInString;
  readonly adSpend: MoneyWithAmountInString;
  readonly roas: number;
  readonly acos: number;
  readonly adConversions: number;
  readonly conversionRate: number;
  readonly impressions: number;
  readonly clicks: number;
  readonly costPerClick: MoneyWithAmountInString;
  readonly clickThroughRate: number;
  readonly unitsSold: number;
}

export interface ViewTrendsGraphDataResponse {
  readonly [key: string]: string | ViewTrendsPriod | GraphItem[];
  readonly merchantCountryId: string;
  readonly aggregationPeriodType: ViewTrendsPriod;
  readonly daily: GraphItem[];
  readonly weekly: GraphItem[];
}

// Should be in sync with SelectMetricsType in CampaignFlexibilitySharedTypes
export enum AdvertisingGoal {
  ProductLaunch = 'launch',
  Grow = 'grow',
  Profit = 'profit',
  ROAS = 'roas',
  Liquidate = 'liquidate',
  TempOverride = 'tempOverride',
  ExternalWithAi = 'externalWithAi',
  External = 'external',
}

export const MAP_ADVERTISING_GOAL_TO_CAMPAIGN_GOAL: Record<
  AdvertisingGoal,
  BadgeCampaignGoal
> = {
  [AdvertisingGoal.ProductLaunch]: BadgeCampaignGoal.ProductLaunch,
  [AdvertisingGoal.Grow]: BadgeCampaignGoal.Grow,
  [AdvertisingGoal.Profit]: BadgeCampaignGoal.Profit,
  [AdvertisingGoal.ROAS]: BadgeCampaignGoal.ROAS,
  [AdvertisingGoal.Liquidate]: BadgeCampaignGoal.Liquidate,
  [AdvertisingGoal.TempOverride]: BadgeCampaignGoal.TempOverride,
  [AdvertisingGoal.ExternalWithAi]: BadgeCampaignGoal.ExternalWithAi,
  [AdvertisingGoal.External]: BadgeCampaignGoal.None,
};

export const MAP_ADVERTISING_GOAL_TO_II8NKEY: Record<AdvertisingGoal, I18nKey> =
  {
    [AdvertisingGoal.ProductLaunch]:
      I18nKey.GENERIC_ADVERTISING_GOAL_PRODUCT_LAUNCH,
    [AdvertisingGoal.Grow]: I18nKey.GENERIC_ADVERTISING_GOAL_GROW,
    [AdvertisingGoal.Profit]: I18nKey.GENERIC_ADVERTISING_GOAL_PROFIT,
    [AdvertisingGoal.ROAS]: I18nKey.GENERIC_ADVERTISING_GOAL_ROAS,
    [AdvertisingGoal.Liquidate]: I18nKey.GENERIC_ADVERTISING_GOAL_LIQUIDATE,
    [AdvertisingGoal.TempOverride]:
      I18nKey.GENERIC_ADVERTISING_GOAL_TEMP_OVERIDE,
    [AdvertisingGoal.ExternalWithAi]: I18nKey.GENERIC_NOT_AVAILABLE,
    [AdvertisingGoal.External]: I18nKey.GENERIC_NOT_AVAILABLE,
  };

export const MAP_ADVERTISING_GOAL_TO_DESCRIPTION_II8NKEY: Record<
  AdvertisingGoal,
  I18nKey
> = {
  [AdvertisingGoal.ProductLaunch]:
    I18nKey.GENERIC_ADVERTISING_GOAL_PRODUCT_LAUNCH_DESCRIPTION,
  [AdvertisingGoal.Grow]: I18nKey.GENERIC_ADVERTISING_GOAL_GROW_DESCRIPTION,
  [AdvertisingGoal.Profit]: I18nKey.GENERIC_ADVERTISING_GOAL_PROFIT_DESCRIPTION,
  [AdvertisingGoal.ROAS]: I18nKey.GENERIC_ADVERTISING_GOAL_ROAS_DESCRIPTION,
  [AdvertisingGoal.Liquidate]:
    I18nKey.GENERIC_ADVERTISING_GOAL_LIQUIDATE_DESCRIPTION,
  [AdvertisingGoal.TempOverride]: I18nKey.GENERIC_ADVERTISING_GOAL_TEMP_OVERIDE,
  [AdvertisingGoal.ExternalWithAi]: I18nKey.GENERIC_EXTERNAL,
  [AdvertisingGoal.External]: I18nKey.GENERIC_EXTERNAL,
};

export const MAP_ADVERTISING_GOAL_TO_ICON: Record<AdvertisingGoal, SvgIcon> = {
  [AdvertisingGoal.ProductLaunch]: CampaignGoalProductLaunchIcon,
  [AdvertisingGoal.Grow]: CampaignGoalGrowIcon,
  [AdvertisingGoal.Profit]: CampaignGoalProfitIcon,
  [AdvertisingGoal.ROAS]: CampaignGoalROASIcon,
  [AdvertisingGoal.Liquidate]: CampaignGoalLiquidateIcon,
  [AdvertisingGoal.TempOverride]: CampaignGoalTempOverrideIcon,
  [AdvertisingGoal.ExternalWithAi]: LightningFWGradientIcon,
  [AdvertisingGoal.External]: NotEligileIcon,
};

export const MAP_ADVERTISING_GOAL_TO_CAMPAIGN_TYPE: Record<
  CampaignTargetingType,
  BadgeCampaignType
> = {
  [CampaignTargetingType.Auto]: BadgeCampaignType.Auto,
  [CampaignTargetingType.Manual]: BadgeCampaignType.Manual,
};

export enum CampaignType {
  External = 'external',
  Smart = 'smart',
}

export const MAP_CAMPAIGN_TYPE_TO_ICON: Record<CampaignType, SvgIcon> = {
  [CampaignType.External]: ArrowBendRightIcon,
  [CampaignType.Smart]: LightningPurpleGradientIcon,
};
export const MAP_CAMPAIGN_TYPE_TO_I18NKEY: Record<CampaignType, I18nKey> = {
  [CampaignType.External]: I18nKey.GENERIC_EXTERNAL,
  [CampaignType.Smart]: I18nKey.GENERIC_SMART,
};

export enum SmartStructure {
  Basic = 'basic',
  Intermediate = 'intermediate',
  Advanced = 'advanced',
  Custom = 'custom',
  External = 'external',
}

export const MAP_SMART_STRUCTURE_TO_II8NKEY: Record<SmartStructure, I18nKey> = {
  [SmartStructure.Basic]: I18nKey.GENERIC_SMART_STRUCTURE_BASIC,
  [SmartStructure.Intermediate]: I18nKey.GENERIC_SMART_STRUCTURE_INTERMEDIATE,
  [SmartStructure.Advanced]: I18nKey.GENERIC_SMART_STRUCTURE_ADVANCED,
  [SmartStructure.Custom]: I18nKey.GENERIC_SMART_STRUCTURE_CUSTOM,
  [SmartStructure.External]: I18nKey.GENERIC_EXTERNAL,
};

export interface CampaignMissingCogsRequest {
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly merchantType: MerchantType;
  readonly campaignIds: string[];
}

export interface CampaignMissingCogsResponse {
  readonly campaignMissingCogs: {
    readonly isMissingCogs: boolean;
    readonly campaignId: string;
  }[];
}
export interface AdLevelCogsDataRequest {
  readonly merchantType: MerchantType;
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly entityId: string;
  readonly entityType: string;
}

export interface UpdateCogsRequest {
  readonly merchantCountryId: string;
  readonly updates: UpdateCogsData[];
}

export interface UpdateCogsData {
  readonly productCatalogId: string;
  readonly productId: string;
  readonly sku: string;
  readonly unitCogs: number;
}

export interface MultipleCampaignsCogsDataRequest {
  readonly campaignIds: string[];
  readonly merchantType: MerchantType;
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
}
