import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import uuid from 'uuid';

import { getCurrentAccountPermissions } from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import { FAMApiClient } from '../../../../../lib/clients/FAMApiClient';
import {
  FlywheelSalesChannel,
  MerchantCardVariant,
  MerchantCountries,
  MerchantType,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { SellerType } from '../../../containers/utils';
import { ConnectModalWrapper } from '../../ConnectModalWrapper';
import { RowDataProps } from '../../types';
import { SELLING_PARTNER_APP_ID } from '../../utils/AmazonAuth';
import { AuthorizeProductsData } from './authorizeProductsData';
import { ModalFooter } from './footer';
import { Merchants } from '../../constants';
import { AmazonLwaStatus } from '../../../../../lib/utilities/amazonLWA';
import { openSignInWindow } from '../../../../../lib/utilities/popupWindow';

export interface AuthorizeProductsDataModalProps {
  readonly channel: FlywheelSalesChannel;
  readonly famClient: FAMApiClient;
  readonly isAllMerchantsConnected?: boolean;
  readonly next: () => void;
  readonly setError: (val: boolean) => void;
  readonly error: boolean;
  readonly saveLWACorrelationId: (correlationId: string) => void;
  readonly setSelectedMerchantRegion: (region: Region) => void;
  readonly selectedMerchantRegion: Region;
  readonly connectedProductsCount: Record<string, number>;
  readonly closeModal?: () => void;
  readonly connectedMerchants: any[];
  readonly notOwnedMerchantAccount: RowDataProps[];
  readonly ownedMerchantAccounts: MerchantCountries[];
  readonly setSellingPartnerId: (sellingPartnerId: string | null) => void;
  readonly sellingPartnerId: string | null | undefined;
  readonly sellingPartnerType: SellerType;
  readonly setSellingPartnerType: (type: SellerType) => void;
  readonly spapi_oauth_code: string | null | undefined;
  readonly setSpapiOauthCode: (code: string | null) => void;
  readonly vendorProfileConnected: boolean;
  readonly setVendorProfileConnected: (status: boolean) => void;
  readonly goBack?: () => void;
}

export interface Region {
  readonly name: string;
  readonly enabled: boolean;
  readonly regionId: string;
  readonly baseUrl?: string;
  readonly url: string[];
  readonly countryName?: string;
}

export type MerchantsGroup = {
  name: string;
  merchantCount: number;
  merchantType: MerchantType;
  variant: MerchantCardVariant;
  regionId?: string;
  url?: string[];
  country_code: string[];
  key: I18nKey;
};

export const AuthorizeProductsDataModal: React.FC<
  AuthorizeProductsDataModalProps
> = ({
  channel,
  famClient,
  isAllMerchantsConnected = false,
  saveLWACorrelationId,
  setSelectedMerchantRegion,
  selectedMerchantRegion,
  connectedProductsCount,
  connectedMerchants,
  notOwnedMerchantAccount,
  error,
  setError,
  next,
  closeModal,
  sellingPartnerId,
  setSellingPartnerId,
  sellingPartnerType,
  setSellingPartnerType,
  spapi_oauth_code,
  setSpapiOauthCode,
  vendorProfileConnected,
  setVendorProfileConnected,
  goBack,
}) => {
  const intl = useIntl();

  const userContext = useContext<UserContextState>(UserContext);
  const accountInfo = getCurrentAccountPermissions(
    userContext.userInfo.userDetails
  )!;
  const [isAllConnected, setAllMerchantsConnected] = useState<boolean>(
    isAllMerchantsConnected
  );
  const [regionAuthed, setRegionAuthed] = useState<Region>();
  const [amazonLwaStatus, setAmazonLwaStatus] = useState<AmazonLwaStatus>();
  const [merchants, setMerchants] = useState<MerchantsGroup[]>(Merchants);

  const BASE_URL = window.location.origin;

  const spStateToken: string = uuid();

  const nextAction = (regionAuthed?: Region) => {
    let merchantsData: MerchantsGroup[] = [
      ...merchants.map((item: MerchantsGroup) => {
        let data = {
          ...item,
        };
        if (
          regionAuthed?.url === data.url &&
          regionAuthed?.name === data.name
        ) {
          data.variant = MerchantCardVariant.Completed;
        }
        return data;
      }),
    ];
    setMerchants(merchantsData);
    if (productConnect(merchantsData)) {
      setAllMerchantsConnected(true);
    }
  };

  const getAvailableMerchantsRegion = (
    merchantsItem: MerchantsGroup[],
    connectedMerchants: any[]
  ) => {
    const merchantsOwnedMerchantAccount = notOwnedMerchantAccount.filter(
      (item) => connectedMerchants.includes(item.merchantCountryId)
    );

    const merchantsItemData = merchantsItem.map(
      (itemMerchants: MerchantsGroup) => {
        let data = { ...itemMerchants, merchantCount: 0 };
        merchantsOwnedMerchantAccount.forEach((item) => {
          if (
            item.merchantType === data.merchantType &&
            data.country_code.includes(item.country)
          ) {
            data.merchantCount = data.merchantCount + 1;
          }
        });
        return data;
      }
    );

    return merchantsItemData.filter(
      (itemMerchants: MerchantsGroup) => itemMerchants.merchantCount > 0
    );
  };

  useEffect(() => {
    setMerchants(
      getAvailableMerchantsRegion([...Merchants], connectedMerchants)
    );
  }, [connectedMerchants, notOwnedMerchantAccount]);

  const productConnect = (merchants: MerchantsGroup[]) => {
    let flag = false;
    merchants.forEach((item: MerchantsGroup) => {
      if (item.variant === MerchantCardVariant.Completed) {
        flag = true;
      }
    });
    return flag;
  };

  useEffect(() => {
    if (amazonLwaStatus === AmazonLwaStatus.Success && nextAction) {
      if (regionAuthed) {
        setSelectedMerchantRegion(regionAuthed);
      }
      nextAction(regionAuthed);
    }
    if (amazonLwaStatus === AmazonLwaStatus.Error) {
      setError(true);
    }
  }, [amazonLwaStatus]);

  useEffect(() => {
    if (spapi_oauth_code && sellingPartnerId) {
      window.removeEventListener('message', receiveMessage);
      if (sellingPartnerType === SellerType['1P'] && !vendorProfileConnected) {
        next();
      } else if (sellingPartnerType === SellerType['3P']) {
        sendOauthCode();
      }
    }
  }, [spapi_oauth_code, sellingPartnerId]);

  const handleOauthPopup = (region: Region & { merchantType: string }) => {
    setAmazonLwaStatus(undefined);
    setVendorProfileConnected(false);
    if (!region.url && !region.url) {
      return;
    }
    if (sellingPartnerType === SellerType['1P']) {
      setSelectedMerchantRegion(region);
    }
    setRegionAuthed(region);
    openSignInWindow(
      encodeURI(getSellingPartnerAppUrl(region)),
      'sellingPartnerLoginWindow',
      receiveMessage
    );
  };

  const getSellingPartnerAppUrl = (region: Region) => {
    return `https://${region.url[0]}/apps/authorize/consent?application_id=${SELLING_PARTNER_APP_ID}&state=${spStateToken}&redirect_uri=${BASE_URL}/amz-sp-api/callback`;
  };

  const sendOauthCode = async () => {
    const result = await famClient.amazonSellingPartnerConnect(
      accountInfo.account.id,
      regionAuthed!.regionId,
      sellingPartnerId!,
      sellingPartnerType,
      spapi_oauth_code!
    );
    if (result && result.id) {
      saveLWACorrelationId(result.id);
      setAmazonLwaStatus(AmazonLwaStatus.Success);
    } else {
      setError(true);
      setAmazonLwaStatus(AmazonLwaStatus.Error);
    }
  };

  const receiveMessage = (event: MessageEvent) => {
    if (event.origin !== BASE_URL) {
      return;
    }
    const params = new URLSearchParams(event.data);
    if (params.get('state') === spStateToken) {
      setSellingPartnerId(params.get('selling_partner_id'));
      setSpapiOauthCode(params.get('spapi_oauth_code'));
    }
  };

  useEffect(() => {
    if (merchants.length === 0) {
      return;
    }

    setSellingPartnerType(
      merchants[0].merchantType === MerchantType.Seller
        ? SellerType['3P']
        : SellerType['1P']
    );
  }, [merchants]);

  return (
    <ConnectModalWrapper
      dataTestId="authorize_products_data_modal"
      isOpen
      stepText={intl.formatMessage(
        {
          id: I18nKey.SETUP_FLYWHEEL_STEPS_TEXT_WITH_TOTAL,
        },
        {
          number: 2,
          total: 2,
        }
      )}
      headerText={
        channel === FlywheelSalesChannel.Amazon
          ? intl.formatMessage({
              id: I18nKey.FLASH_MODALS_AMAZON_MODAL_HEADER,
            })
          : intl.formatMessage({
              id: I18nKey.FLASH_MODALS_WALMART_MODAL_HEADER,
            })
      }
      content={
        <AuthorizeProductsData
          channel={channel}
          error={error}
          merchants={merchants}
          handleOauthPopup={handleOauthPopup}
        />
      }
      showFooterSeparator={true}
      footer={
        <ModalFooter
          next={next}
          goBack={goBack}
          error={error}
          isAllMerchantsConnected={isAllConnected}
        />
      }
      headerChannelType={channel}
      closeModal={closeModal}
    />
  );
};

AuthorizeProductsDataModal.displayName = 'AuthorizeProductsDataModal';
