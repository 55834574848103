import { MerchantSyncData } from '../../../../../lib/types/AOSharedTypes';
import { SalesChannelData } from '../../../../../lib/types/Fam';
import { SalesChannelProps } from '../../../containers/salesChannels/types';
import { MerchantCountrySyncStatusCard } from './MerchantCountrySyncStatusCard';

export interface MerchantCountryListProps extends SalesChannelProps {
  readonly salesChannelData?: Array<SalesChannelData & MerchantSyncData>;
  readonly refreshList: () => Promise<void>;
  readonly showDataTimingAndSyncSlideout?: () => void;
}

export const MerchantCountryList: React.FC<MerchantCountryListProps> = ({
  salesChannelData,
  salesChannel,
  refreshList,
  showDataTimingAndSyncSlideout,
}) => {
  return (
    <div className="flex flex-col w-full items-start gap-12">
      {salesChannelData?.map((item, index) => {
        return (
          <MerchantCountrySyncStatusCard
            salesChannelData={item}
            salesChannel={salesChannel}
            refreshList={refreshList}
            key={`${item.merchantId}${index}_salesChannel_slide_out_merchant_country`}
            showDataTimingAndSyncSlideout={showDataTimingAndSyncSlideout}
          />
        );
      })}
    </div>
  );
};
MerchantCountryList.displayName = 'MerchantCountryList';
