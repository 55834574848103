import classNames from 'classnames';

import {
  AlertTriangleIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Icon,
  IconSize,
  TrashIcon,
} from '@teikametrics/tm-design-system';
import { useMemo } from 'react';

interface DistributorIdInputProps {
  readonly index: number;
  readonly showDeleteButton: boolean;
  readonly prefixLabel: string;
  readonly distributorId: string;
  readonly error?: string;
  readonly isProductConnectionLoading?: boolean;
  readonly onChange: (id: string, index: number) => void;
  readonly onRemove: (index: number) => void;
}

export const DistributorIdInput: React.FC<DistributorIdInputProps> = (
  props
) => {
  const {
    index,
    showDeleteButton,
    prefixLabel,
    distributorId,
    isProductConnectionLoading = false,
    onChange,
    onRemove,
  } = props;

  const error = useMemo(() => {
    if (distributorId === '') {
      return '';
    }

    if (!/^\d+$/.test(distributorId)) {
      return 'Distributor IDs contain only numbers';
    }

    return '';
  }, [distributorId]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onChange(event.target.value, index);
  };

  const removeInput = () => onRemove(index);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex gap-8 h-32">
        <div
          className={`flex flex-1 border border-solid border-grey-300 rounded`}
        >
          <label
            className={`flex items-center justify-center w-32 border-r border-solid border-grey-300
          bg-grey-200 text-grey-600 text-sm leading-none font-medium`}
          >
            {prefixLabel}
          </label>
          <input
            type="text"
            className={classNames(
              'flex w-full rounded-tr-4 rounded-br-4',
              'outline-none px-12 text-sm text-grey-900'
            )}
            onChange={onChangeHandler}
            value={distributorId}
            disabled={isProductConnectionLoading}
          />
        </div>
        {showDeleteButton && (
          <Button
            size={ButtonSize.Medium}
            variant={ButtonVariant.BlackAndWhiteBorder}
            onClick={removeInput}
            svgIcon={TrashIcon}
            state={
              isProductConnectionLoading
                ? ButtonState.Disabled
                : ButtonState.Enabled
            }
          />
        )}
      </div>
      {error && (
        <div
          className={classNames(
            `flex items-center gap-8 h-32 ml-32 px-8
         bg-orange-100 text-sm leading-none font-medium rounded`,
            {
              'mr-44': showDeleteButton,
            }
          )}
        >
          <Icon
            size={IconSize.Medium}
            svg={AlertTriangleIcon}
            className="text-orange-500"
          />
          {error}
        </div>
      )}
    </div>
  );
};
