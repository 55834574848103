import {
  Alignment,
  BadgeV2Types,
  ColumnAlign,
  KeywordRoutingTagBadge,
  KeywordRoutingType,
  MultiSelectOption,
  Placement,
  TruncatedText,
  createMultiSelectDataFieldFilter,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';
import React from 'react';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  KeywordActionColumns,
  KeywordRecommendationsData,
  SelectKeywordTagOption,
} from '../../../../../lib/types/KeywordAction';

const NegativeKeywordRecommendationColumn: React.FC<
  KeywordRecommendationsData
> = ({ keyword: { text, tag } }) => {
  return (
    <div className="flex py-8 items-center justify-start gap-8">
      <KeywordRoutingTagBadge
        badgeType={BadgeV2Types.KeywordRoutingTags}
        keywordRoutingTypes={tag ? ([tag] as KeywordRoutingType[]) : []}
        tooltipPosition={{
          placement: Placement.Right,
          alignment: Alignment.Center,
        }}
      />
      <TruncatedText text={text} customLineClamp={2} />
    </div>
  );
};
NegativeKeywordRecommendationColumn.displayName =
  NegativeKeywordRecommendationColumn.name;

export const negativeKeywordRecommendation: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  {},
  {}
> = {
  header:
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_NEGATIVE_KEYWORD_RECOMMENDATION_HEADER,
  accessorKey: KeywordActionColumns.NEGATIVE_KEYWORD,
  sortable: true,
  size: 300,
  minSize: 300,
  maxSize: 300,
  align: ColumnAlign.Left,
  cell: ({ row: { original } }) => (
    <NegativeKeywordRecommendationColumn {...original} />
  ),
};

export const negativeKeywordRecommendationFilter = createStringDataFieldFilter(
  KeywordActionColumns.NEGATIVE_KEYWORD,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_NEGATIVE_KEYWORD_RECOMMENDATION_HEADER,
  isValidString()
);

const getKeywordTagOptions = (): MultiSelectOption[] => [
  SelectKeywordTagOption.Brand,
  SelectKeywordTagOption.Competitor,
  SelectKeywordTagOption.Generic,
  SelectKeywordTagOption.Unknown,
];

export const negativeKeywordTagFilter = createMultiSelectDataFieldFilter(
  KeywordActionColumns.KEYWORD_TAG,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_NEGATIVE_KEYWORD_TAG_HEADER,
  getKeywordTagOptions()
);
