import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputState,
  Placement,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeNumericInputColumn,
  makePercentColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import { CampaignDetails } from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  isManualCampaign,
} from '../utils';
import { isDirty } from './totalBudget';

export const MIN_STOCKUP_BID = 0;

const tooltipContent = (invalidInput: boolean, intl: IntlShape) => {
  if (invalidInput) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          { id: I18nKey.ADS_MANAGER_INVALID_NUMERIC_ATLEAST_PERCENT },
          {
            minValue: intl.formatNumber(MIN_STOCKUP_BID, {
              minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
              maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
            }),
          }
        )}
      </p>
    );
  }
};

export const getStockUpBidMultiplierValue = ({
  channelSettings: { placementBidMultiplier },
  campaignDetails: { targetingType },
}: CampaignDetails) => {
  if (isManualCampaign(targetingType)) {
    return undefined;
  }
  return placementBidMultiplier &&
    !isNil(placementBidMultiplier.stockUpBidMultiplier)
    ? placementBidMultiplier.stockUpBidMultiplier.toString()
    : '0';
};

export const getControlledTooltipState = (
  focussed: boolean,
  state: NumericInputState
) =>
  focussed && state === NumericInputState.Error
    ? ControlledTooltip.Show
    : ControlledTooltip.Hide;

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, ...campaignDetails }) => {
  const intl = useIntl();

  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const existingValue =
    (campaignDetails.channelSettings.placementBidMultiplier?.stockUpBidMultiplier?.toString() &&
      (
        campaignDetails.channelSettings.placementBidMultiplier
          ?.stockUpBidMultiplier * 100
      ).toFixed(PERCENTAGE_FRACTION_DIGITS)) ||
    '';

  const stockUpBidMultiplier = changedValue ?? existingValue;

  const [value, setValue] = useState<string>(stockUpBidMultiplier);
  const [isInputEdited, setIsInputEdited] = useState<boolean>(false);

  const onValueAndPageChange = () => {
    if (
      isEditMode || //Changing between modes
      (!isNil(changedValue) && changedValue !== value) // Changes done due to bulk update. changedValue is latest, but state variable is not
    ) {
      setValue(stockUpBidMultiplier);
    }
    return () => setIsInputEdited(false);
  };

  useEffect(onValueAndPageChange, [isEditMode, changedValue, currentPage]);

  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);

  const updateCellValue = (newValue: string) =>
    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier,
        rowId: campaignDetails.campaignId,
        existingValue,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        value: newValue,
        numericValue: true,
      })
    );

  const debounceCellValueUpdate = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignDetails.campaignId]
  );

  let state = NumericInputState.Default;
  const isInputInvalid: boolean =
    (Number(value) < MIN_STOCKUP_BID ||
      isNaN(Number(value)) ||
      isEmpty(value)) &&
    (isInputEdited || isDirty(value, existingValue));

  if (isInputInvalid) {
    state = NumericInputState.Error;
  }

  const onInputFocus = () => setFocussed(true);

  const onInputBlur = () => {
    if (!isEmpty(value)) {
      debounceCellValueUpdate.cancel();
      const formattedValue = Number.parseFloat(value).toFixed(
        PERCENTAGE_FRACTION_DIGITS
      );
      setValue(formattedValue);
      updateCellValue(formattedValue);
    }
    setFocussed(false);
  };

  if (
    isEditMode &&
    !isManualCampaign(campaignDetails.campaignDetails.targetingType)
  ) {
    return makeNumericInputColumn<CampaignDetails>(() => {
      return {
        dataTestId: CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier,
        value,
        state,
        appendedElement: '%',
        onChange: (inputValue: string) => {
          setValue(inputValue);
          debounceCellValueUpdate(inputValue);
          setIsInputEdited(true);
        },
        tooltipContent: tooltipContent(isInputInvalid, intl),
        controlledTooltip: getControlledTooltipState(focussed, state),
        tooltipPlacement: Placement.Bottom,
        style: ContentStyle.Bold,
        onInputFocus,
        onInputBlur,
        acceptOnlyPositiveNumbers: true,
        isDirty: isDirty(value, existingValue),
        placeholder: MIN_STOCKUP_BID.toFixed(PERCENTAGE_FRACTION_DIGITS),
        minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      };
    })(campaignDetails);
  }

  return makePercentColumn<CampaignDetails>(
    getStockUpBidMultiplierValue,
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const stockUpBidMultiplierColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_STOCK_UP,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const stockUpBidMultiplierFilter = createPercentDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_STOCK_UP,
  isValidNumber()
);
