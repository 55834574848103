import {
  ArrowsNextIcon,
  Badge,
  BadgeDisplayMode,
  BadgeType,
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  ListIcon,
  PortaledTooltip,
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { CampaignTargetingType } from '../../../lib/types/AOSharedTypes';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { CampaignsAdGroupsSelectOptionProps } from '.';
import { getCampaignStatusMarkerColor } from './CampaignsDisplayLabel';

export const AdGroupDisplayLabel: React.FC<
  CampaignsAdGroupsSelectOptionProps & {
    readonly onAdGroupClick?: (adGroupId?: string) => void;
    readonly onClick?: () => void;
  }
> = (props) => {
  return (
    <>
      <div className="flex flex-col gap-8" onClick={props.onClick}>
        <div className="flex items-center gap-8">
          <Badge
            badgeType={
              props.targetingType === CampaignTargetingType.Manual
                ? BadgeType.CampaignTypeManual
                : BadgeType.CampaignTypeAuto
            }
            badgeNameDisplayMode={BadgeDisplayMode.OnlyInitial}
          />
          <Typography
            lineHeight={TypographyLineHeight.tight}
            size={TypographySize.base}
            weight={TypographyWeight.regular}
          >
            {props.campaignName}
          </Typography>
        </div>
        <div className="flex gap-8 items-center">
          <Icon
            size={IconSize.Small}
            svg={ArrowsNextIcon}
            className="ml-12 mr-6 text-grey-500"
          />
          <Typography
            lineHeight={TypographyLineHeight.tight}
            size={TypographySize.base}
            weight={TypographyWeight.medium}
          >
            {props.adGroupName}
          </Typography>
        </div>
        <div className="flex pl-40 gap-8">{props.tags?.map((tag) => tag)}</div>
      </div>
      <div className="flex w-152 gap-12">
        <div className="flex items-center gap-4 w-88">
          <div
            className={classNames(
              'inline-block p-4 rounded-full m-4',
              getCampaignStatusMarkerColor(props.status)
            )}
          ></div>
          <Typography
            lineHeight={TypographyLineHeight.none}
            size={TypographySize.base}
            weight={TypographyWeight.regular}
            className="capitalize"
          >
            {props.statusString}
          </Typography>
        </div>
        <div className="flex items-center justify-center w-52">
          <PortaledTooltip
            content="SKUs and Keywords"
            position={{
              placement: PortaledTooltipPlacement.Bottom,
            }}
            theme={PortaledTooltipTheme.DarkV2}
          >
            <Button
              size={ButtonSize.Medium}
              variant={ButtonVariant.ActionWithoutBorder}
              svgIcon={ListIcon}
              className="bg-transparent mx-8"
              onClick={() =>
                props.onAdGroupClick && props.onAdGroupClick(props.adGroupId)
              }
              iconClassName="hover:text-purple-500"
            />
          </PortaledTooltip>
        </div>
      </div>
    </>
  );
};

export const AdGroupLoadingSkeleten: React.FC = () => {
  return (
    <div className="flex w-full gap-40">
      <div className="flex flex-col w-full gap-8">
        <div className="flex gap-8 w-full">
          <Skeleton width={24} height={20} />
          <Skeleton width={640} height={20} />
        </div>
        <div className="flex gap-8">
          <Icon svg={ArrowsNextIcon} size={IconSize.Medium} className="ml-12" />
          <Skeleton width={480} height={20} />
        </div>
        <div className="flex gap-8 pl-48">
          <Skeleton width={80} height={20} />
        </div>
      </div>
      <div className="flex items-center justify-start">
        <Skeleton width={88} height={20} />
      </div>
    </div>
  );
};

AdGroupDisplayLabel.displayName = 'AdGroupDisplayLabel';
