import './styles.scss';

import React from 'react';

import { ReactComponent as ExternalLinkSvg } from '../../../../img/icons_deprecated/External.svg';

interface ProductDetailsWithLinkProps {
  readonly name: string;
  readonly identifier: string;
  readonly imageUrl: string;
  readonly externalLink: string;
}

export const ProductDetailsWithLink: React.FC<ProductDetailsWithLinkProps> = ({
  name,
  imageUrl,
  identifier,
  externalLink,
}) => (
  <div className="product-details-with-link">
    <div className="product-details-with-link__image">
      {<img src={imageUrl} alt="Product Details" />}
    </div>
    <div className="product-details-with-link__text flex-1 overflow-hidden">
      <a
        href={externalLink}
        target="_blank"
        className="product-details-with-link__link"
        rel="noreferrer"
      >
        <span className="product-details-with-link__identifier">
          {identifier}
        </span>
        <span className="product-details-with-link__external-link">
          <ExternalLinkSvg />
        </span>
      </a>
      <div className="overflow-ellipsis overflow-hidden mt-5 text-xs text-gray-500">
        {name}
      </div>
    </div>
  </div>
);
ProductDetailsWithLink.displayName = 'ProductDetailsWithLink';
