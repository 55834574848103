import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

/*
  Reselect selector creator that recomputes based off of object equality instead
  of address equality. Should only be used when put against a wall.
*/
export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
);
