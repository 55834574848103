import { Factory } from 'fishery';
import { AIProductSettingSummaryResponse } from '../../types/AIPoweredSharedTypes';

export default Factory.define<AIProductSettingSummaryResponse>(() => ({
  adSalesTotal: {
    amount: '4803.45',
    currency: 'USD',
  },
  adSpend: {
    amount: '4972.77',
    currency: 'USD',
  },
  acosTotal: 1.0352496643037818,
  dailyBudget: {
    amount: '0.00',
    currency: 'USD',
  },
  competitorTotal: 0,
  brandTotal: 0,
  totalCatalogProducts: 0,
  acosTarget: 0,
}));
