import { Factory } from 'fishery';

import {
  AdLevel,
  AdType,
  DataInspectionResponse,
  FlywheelSalesChannel,
  MerchantCountryCode,
} from '../../types/AOSharedTypes';

interface DataInspectionTransientParams {
  readonly salesChannel: FlywheelSalesChannel;
}

export default Factory.define<
  DataInspectionResponse,
  DataInspectionTransientParams
>(({ transientParams }) => {
  const { salesChannel } = transientParams;

  if (salesChannel === FlywheelSalesChannel.Walmart) {
    return {
      dataInspection: [
        {
          merchantCountryCode: MerchantCountryCode.US,
          advertisingTypeInfo: [
            {
              adType: AdType.SponsoredProducts,
              adLevels: [
                AdLevel.Campaigns,
                AdLevel.AdGroups,
                AdLevel.ProductAds,
                AdLevel.KeywordTargets,
              ],
            },
          ],
        },
      ],
    };
  }
  if (salesChannel === FlywheelSalesChannel.Amazon) {
    const AMAZON_SP_AD_LEVELS = [
      AdLevel.Campaigns,
      AdLevel.AdGroups,
      AdLevel.ProductAds,
      AdLevel.KeywordTargets,
      AdLevel.ProductTargets,
    ];
    const AMAZON_SB_AD_LEVELS = [
      AdLevel.Campaigns,
      AdLevel.AdGroups,
      AdLevel.KeywordTargets,
      AdLevel.ProductTargets,
    ];
    const AMAZON_SD_AD_LEVELS = [
      AdLevel.Campaigns,
      AdLevel.AdGroups,
      AdLevel.ProductAds,
      AdLevel.Targets,
    ];

    const AMAZON_SP_AND_SB_AD_LEVELS = [
      {
        adType: AdType.SponsoredProducts,
        adLevels: AMAZON_SP_AD_LEVELS,
      },
      {
        adType: AdType.SponsoredBrands,
        adLevels: AMAZON_SB_AD_LEVELS,
      },
    ];

    const AMAZON_ALL_AD_LEVELS = [
      ...AMAZON_SP_AND_SB_AD_LEVELS,
      {
        adType: AdType.SponsoredDisplay,
        adLevels: AMAZON_SD_AD_LEVELS,
      },
    ];

    return {
      dataInspection: [
        {
          merchantCountryCode: MerchantCountryCode.US,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.CA,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.MX,
          advertisingTypeInfo: AMAZON_SP_AND_SB_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.BR,
          advertisingTypeInfo: AMAZON_SP_AND_SB_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.UK,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.GB,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.DE,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.FR,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.ES,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.IT,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.NL,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.AE,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.KSA,
          advertisingTypeInfo: AMAZON_SP_AND_SB_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.SA,
          advertisingTypeInfo: AMAZON_SP_AND_SB_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.IN,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.JP,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.AU,
          advertisingTypeInfo: AMAZON_ALL_AD_LEVELS,
        },
        {
          merchantCountryCode: MerchantCountryCode.SG,
          advertisingTypeInfo: AMAZON_SP_AND_SB_AD_LEVELS,
        },
      ],
    };
  }
  return { dataInspection: [] };
});
