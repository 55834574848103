import { TMTableMeta, Table } from '@teikametrics/tm-design-system';
import { useMemo } from 'react';

import { FlywheelTableV2AggregationCellProps, LiteTableProps } from './types';
import { getCurrentActiveSortType } from './utils';

export const transformColumns = <
  T extends Record<string, unknown>,
  R extends TMTableMeta<T> = {}
>(
  columns: any[]
) => {
  return columns.map((column) => {
    let newColumn = {
      ...column,
      header: column.header ?? undefined,
    };

    if (column.accessorKey) {
      newColumn = {
        ...newColumn,
        id: column.id ?? column.accessorKey,
      };
    }

    if ((column as any)?.columns?.length) {
      newColumn = {
        ...newColumn,
        columns: transformColumns<T, R>((column as any)?.columns || []),
      };
    }

    return newColumn;
  });
};

export const LiteTable = <
  T extends Record<string, unknown>,
  A = {},
  R extends TMTableMeta<T> = {},
  S = {}
>(
  props: LiteTableProps<T, A, R, S>
) => {
  const columns = useMemo(() => {
    if (!props) {
      return [];
    }
    return transformColumns<T, R>(props.columns);
  }, [props.columns]);

  return (
    <Table<T, FlywheelTableV2AggregationCellProps<A, S>, R>
      columns={columns}
      data={props.rowData}
      meta={props.tableData}
      dataTestId={props.tableId}
      onSort={props.onSort}
      activeSortType={getCurrentActiveSortType(props.currentSort)}
      activeSortColumnAccessor={props?.currentSort?.column}
      className={props.tableClassName}
      headerClass={props.headerClass}
      tableEmptyStateClass={props.tableEmptyStateClass}
      total={props.total}
      allowStickyHeader={props.allowStickyHeader}
      customStickyHeaderClassName={props.customStickyHeaderClassName}
      rowIdKeyName={props.rowIdKeyName}
      rowIdsSelected={props.rowIdsSelected}
      showBulkSelection={props.showBulkSelection}
      activeSortCellClassName={props.activeSortCellClassName}
      activeSortHeaderClassName={props.activeSortHeaderClassName}
      cellClassName={props.cellClassName}
      subCellClassName={props.subCellClassName}
      emptyState={props.emptyState}
      onRowsSelection={props.onRowsSelection}
      expandSubRows={props.expandSubRows}
      infiniteScrollProps={props.infiniteScrollProps}
      virtualizationProps={props.virtualizationProps}
      expandableSubRows={props.expandableSubRows}
      enableExpandSubRows={props.enableExpandSubRows}
      showExpandAllRowsButton={props.showExpandAllRowsButton}
      expandedRows={props.expandedRows}
      applyStickyOnOverflow={props.applyStickyOnOverflow}
      tableHeaderBackgroundClassName={props.tableHeaderBackgroundClassName}
      rowCheckboxClassName={props.rowCheckboxClassName}
    />
  );
};

LiteTable.displayName = 'LiteTable';
