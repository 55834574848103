import {
  ChevronDownIcon,
  Icon,
  IconSize,
  ListIcon,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const ProductDropdownSkeleton: React.FC = () => {
  return (
    <div
      className={classNames(
        'flex flex-row p-12 items-center gap-8 w-full',
        'border border-solid border-grey-200 rounded-lg leading-none'
      )}
    >
      <div className={classNames('flex w-32 h-32 items-center')}>
        <Icon svg={ChevronDownIcon} size={IconSize.Medium} />
      </div>
      <Skeleton height={64} width={64} />
      <div className="flex flex-row gap-8 w-full justify-between">
        <div className="flex flex-col gap-4 pr-32">
          <Skeleton borderRadius={0} height={12} width={200} />
          <Skeleton borderRadius={0} height={18} width={200} />
          <div className="flex flex-row gap-16">
            <Skeleton borderRadius={0} width={108} height={15} />
            <Skeleton borderRadius={0} width={108} height={15} />
          </div>
        </div>
        <div className="flex flex-row gap-12 items-center">
          <Skeleton borderRadius={0} width={144} height={14} />
          <div className="w-52 h-full flex flex-row items-center justify-center px-8 bg-grey-100">
            <Icon
              svg={ListIcon}
              size={IconSize.Medium}
              className="text-grey-400"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
