import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  AlertCircleIcon,
  BreadCrumbLink,
  BreadCrumbs,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  ConfirmationModal,
  PaletteColor,
  Pill,
  PillSize,
  SparkleIcon,
  Tooltip,
  Type,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../../../../containers/notificationProvider';
import {
  SaveChangesFlagContext,
  SaveChangesFlagContextState,
} from '../../../../../../containers/saveChangesFlagProvider';
import { tableActions } from '../../../../../../containers/table/ducks';
import { getCurrentAccountFromContext } from '../../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../../containers/userProvider/userProvider';
import { createAOApiClient } from '../../../../../../lib/clients/AOApiClient';
import { AdLevel, AdType } from '../../../../../../lib/types/AOSharedTypes';
import {
  MatchType,
  TargetSegment,
} from '../../../../../../lib/types/CampaignFlexibilitySharedTypes';
import { Filter, FilterOps } from '../../../../../../lib/types/Filter';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { KeywordAdgroupInfo } from '../../../../../../lib/types/KeywordAction';
import { MAP_SALES_CHANNEL_ID_TO_NAME } from '../../../../../../lib/types/SalesChannels';
import { AdGroupSelectionSlideoutModalV2 } from '../../../../../keywordAction/components/adGroupSelectionSlideoutV2';
import { ADS_MANAGER_TARGETS_TABLE_ID } from '../../ducks/types';
import { TARGETS_API_COLUMN_NAME } from '../../utils';
import { SelectFilterOption } from '../../utils/selectFilterOptions';
import { storeFiltersInLocalStorage } from '../../utils/storageUtils';
import { KeywordType } from '../createKeywordsMenu';
import { ConfigureSettings } from './configureSettings';
import {
  CreateChatGPTKeywordsContext,
  KeywordsRequestState,
} from './createChatGPTKeywordsProvider';
import { EmptyState } from './emptyState';
import { FeedbackSlideout } from './feedbackSlideout';
import { SkuAndKeywordSlideOut } from './skuAndKeywordSlideOut';
import { getButtonTooltipProps } from './utils';
import {
  PageHeaderContext,
  PageHeaderContextState,
} from '../../../../../../containers/pageHeaderProvider';

const getNewKeywordTypes = (
  targetSegments: TargetSegment[],
  value: TargetSegment
) => {
  const data = [...targetSegments];
  if (includes(targetSegments, value)) {
    remove(data, (Item: TargetSegment) => Item === value);
  } else {
    data.push(value);
  }
  return data;
};

const initBeforeUnLoad = (isEditing: boolean) => {
  window.onbeforeunload = (event: Event) => {
    if (isEditing) {
      event.preventDefault();
      return '';
    }
  };
};
export interface CreateChatGPTKeywordsModalProps {
  readonly dataTestId?: string;
}

export const CreateChatGPTKeywordModal: React.FC<
  CreateChatGPTKeywordsModalProps
> = ({ dataTestId }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    brands,
    isBrandsLoading,
    selectedAdGroup,
    setSelectedAdGroup,
    targetSegments,
    brandTags,
    setTargetSegments,
    setBrandTags,
    requestKeywords,
    keywordsRequestState,
    requestKeywordsResponse,
    keywords,
    getKeywordsResult,
    setKeywordsRequestState,
    resetKeywords,
    resetState,
    submitKeywords,
    merchantCountryId,
    salesChannelId,
    merchantType,
    isFeedbackSubmitted,
    isKeywordsSubmitted,
    setIsKeywordsSubmitted,
  } = useContext(CreateChatGPTKeywordsContext);
  const toasts = useContext<NotificationContextState>(NotificationContext);
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [showEditAdgroupAlert, setShowEditAdgroupAlert] = useState(false);
  const [showEditKeywordTypeAlert, setShowEditKeywrodTypeAlert] =
    useState(false);
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const saveChangesFlagContext = useContext<SaveChangesFlagContextState>(
    SaveChangesFlagContext
  );
  const [newTargetSegments, setNewTargetSegments] = useState<TargetSegment[]>(
    []
  );
  const userContext = useContext<UserContextState>(UserContext);
  const { id: accountId } = getCurrentAccountFromContext(userContext)!;

  const aoClient = createAOApiClient(userContext.userInfo.idToken!);

  const location = useLocation();
  const BASE_URL = useMemo(
    () =>
      location.pathname
        .split('/')
        .filter(
          (value) =>
            ![
              'select-ad-group',
              'configure',
              'feedback',
              'ad-group-details',
            ].includes(value)
        )
        .join('/'),
    [location.pathname]
  );

  const [
    TOAST_SUCCESS_HEADLINE,
    CREATE_KEYWORDS_SUCCESS_TOAST,
    NO_ADGROUP_MESSAGE,
    EDIT_ADGROUP_HEADER,
    EDIT_ADGROUP_MESSAGE,
    CANCEL_ALERT_HEADER,
    CANCEL_ALERT_MESSAGE,
    EDIT_TAG_TYPE_HEADER,
  ] = [
    I18nKey.GENERIC_SUCCESS,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_CREATE_KEYWORDS_SUCCESS_TOAST,
    I18nKey.CREATE_KEYWORDS_MODAL_NO_ADGROUP_CHAT_GPT,
    I18nKey.CREATE_KEYWORDS_MODAL_EDIT_ADGROUP_HEADER,
    I18nKey.CREATE_KEYWORDS_MODAL_EDIT_ADGROUP_MESSAGE,
    I18nKey.CREATE_KEYWORDS_MODAL_CANCEL_HEADER,
    I18nKey.CREATE_KEYWORDS_MODAL_CANCEL_MESSAGE,
    I18nKey.CREATE_KEYWORDS_MODAL_EDIT_TAG_TYPE_HEADER,
  ].map((id) =>
    intl.formatMessage(
      { id },
      {
        keywordType: KeywordType.Keyword,
        isNegative: false,
        br: <br />,
        keywords: keywords.length,
        adGroups: 1,
      }
    )
  );

  const onClose = () => {
    if (isKeywordsSubmitted) {
      const newFilters: Filter[] = [
        {
          field: TARGETS_API_COLUMN_NAME.AddedBy.toString(),
          op: FilterOps.in,
          value: [SelectFilterOption.ChatGPT.value],
        },
      ];
      dispatch(
        tableActions.updateFilters({
          tableId: ADS_MANAGER_TARGETS_TABLE_ID,
          filters: newFilters,
          replace: true,
        })
      );
      storeFiltersInLocalStorage(
        userContext.userInfo.userDetails?.id!,
        accountId,
        AdType.SponsoredProducts!,
        AdLevel.KeywordTargets,
        newFilters,
        salesChannelId,
        undefined
      );
    }

    navigate(
      `/ads-optimization/ads-manager/${MAP_SALES_CHANNEL_ID_TO_NAME[salesChannelId]}/sponsored-products/keyword-targets`,
      {
        replace: true,
      }
    );
  };

  const navigateToAdGroupModal = () => {
    navigate(BASE_URL + '/select-ad-group' + location.search);
  };

  const navigateToFeedback = () => {
    navigate(BASE_URL + '/feedback' + location.search);
  };

  const navigateToChatGPT = (isAdGroupSelected?: boolean) => {
    if (selectedAdGroup || isAdGroupSelected) {
      navigate(BASE_URL + '/configure' + location.search, {
        replace: true,
      });
    } else {
      navigate(BASE_URL, {
        replace: true,
      });
    }
  };

  const isKeywordsLoading =
    keywordsRequestState === KeywordsRequestState.Loading;

  useEffect(() => {
    initBeforeUnLoad(isKeywordsLoading || keywords.length > 0);

    if (isKeywordsLoading || keywords.length > 0) {
      editDataFlagWithOutSave();
    }
  }, [isKeywordsLoading, keywords]);

  const editDataFlagWithOutSave = () => {
    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: true,
      navPath: BASE_URL,
    });
  };

  const handleCancelNo = () => {
    if (showCancelAlert) {
      setShowCancelAlert(false);
    } else {
      saveChangesFlagContext.updateSaveChangesData({
        ...saveChangesFlagContext.saveChangesData,
        editDataFlag: true,
        saveChangesModalIsOpen: false,
        navPath: BASE_URL + '/configure',
      });
    }
  };
  const handleCancelYes = () => {
    if (showCancelAlert) {
      setShowCancelAlert(false);
      resetState();
      navigate(BASE_URL, {
        replace: true,
      });
    } else {
      saveChangesFlagContext.updateSaveChangesData({
        ...saveChangesFlagContext.saveChangesData,
        editDataFlag: false,
        saveChangesModalIsOpen: false,
        aoAdLevel: undefined,
      });
      navigate(saveChangesFlagContext.saveChangesData.navPath);
    }
  };

  const getSubmitButtonState = () => {
    if (
      isKeywordsLoading ||
      !keywords.length ||
      !selectedAdGroup ||
      (showBrandNamesContainer && !brands.length)
    ) {
      return ButtonState.Disabled;
    } else if (isSaving || isBrandsLoading) {
      return ButtonState.Loading;
    }
    return ButtonState.Active;
  };

  const onSubmit = () => {
    setIsSaving(true);
    submitKeywords()
      .then(() => {
        setIsSaving(false);
        setIsKeywordsSubmitted(true);
        toasts.addNotification({
          headline: TOAST_SUCCESS_HEADLINE,
          description: CREATE_KEYWORDS_SUCCESS_TOAST,
          type: Type.Success,
          dataTestId: 'CreateKeywordsSuccess',
        });

        if (!isFeedbackSubmitted) {
          navigateToFeedback();
        } else {
          onClose();
        }
      })
      .catch(() => setIsSaving(false));
  };

  const handleKeywordTypeChange = (value: TargetSegment) => {
    const data = getNewKeywordTypes(targetSegments, value);
    if (keywords.length > 0) {
      setShowEditKeywrodTypeAlert(true);
      setNewTargetSegments(data);
    } else {
      setTargetSegments(data);
    }
  };

  const handleEditKeywordTypeConfirmation = () => {
    setShowEditKeywrodTypeAlert(false);
    setTargetSegments(newTargetSegments);
    setNewTargetSegments([]);
    resetKeywords();
  };

  const handleMatchTypeCheckbox = (value: MatchType) => {
    const data = [...brandTags];
    if (includes(brandTags, value)) {
      remove(data, (Item: string) => Item === value);
    } else {
      data.push(value);
    }
    setBrandTags(data);
  };

  const handleAdGroupChange = (adGroup: KeywordAdgroupInfo) => {
    if (adGroup.id !== selectedAdGroup?.id) {
      resetKeywords();
      setSelectedAdGroup(adGroup);
      setTargetSegments(
        (adGroup?.targetSegments?.length
          ? adGroup.targetSegments
          : [
              TargetSegment.Brand,
              TargetSegment.Competitor,
              TargetSegment.Generic,
            ]) as TargetSegment[]
      );
    }
  };

  const handleCountClick = (adGroup: KeywordAdgroupInfo) => {
    navigate(`${BASE_URL + '/ad-group-details'}?adGroupId=${adGroup.id}`, {
      state: {
        suggestedAdgroup: adGroup,
      },
    });
  };

  const handleSkuAndKeywordSlideoutClose = () => {
    window.history.back();
  };

  const handleCancel = () => {
    if (isKeywordsLoading || keywords.length > 0) {
      setShowCancelAlert(true);
    } else if (selectedAdGroup) {
      resetState();
      navigateToChatGPT();
    } else {
      onClose();
    }
  };

  const handleGenerateKeywordsClick = () => {
    setKeywordsRequestState(KeywordsRequestState.Loading);
    if (!keywords.length) {
      requestKeywords();
    } else {
      getKeywordsResult();
    }
  };

  const handleEditAdGroupClick = () => {
    if (isKeywordsLoading || isSaving) {
      return;
    }

    if (!keywords.length) {
      navigateToAdGroupModal();
    } else {
      setShowEditAdgroupAlert(true);
    }
  };

  const showBrandNamesContainer =
    includes(targetSegments, TargetSegment.Brand) ||
    includes(targetSegments, TargetSegment.Competitor);

  const handleFeedbackSlideoutClose = (submitted?: boolean) => {
    if (isKeywordsSubmitted) {
      onClose();
    } else {
      navigateToChatGPT();
    }
  };

  const { updatePageHeaderData } =
    useContext<PageHeaderContextState>(PageHeaderContext);

  const pageTitle = intl.formatMessage({
    id: I18nKey.KEYWORD_RECOMMENDATIONS_VIEW_SKUS_KEYWORDS_SLIDEOUT_KEYWORDS,
  });

  useEffect(() => {
    updatePageHeaderData({
      title: (
        <div className="flex items-center gap-8">
          <span className="font-semibold text-lg leading-tight">
            {pageTitle}
          </span>
          <Pill
            color={PaletteColor.blue}
            text={intl.formatMessage({
              id: I18nKey.CREATE_KEYWORDS_MENU_CHAT_GPT_BETA,
            })}
            isBorderRounded={true}
            pillSize={PillSize.Small}
            className="bg-rainbow-gradient"
            textClassName="text-white text-sm leading-none font-normal"
          />
        </div>
      ),
      titleString: pageTitle as string,
      breadCrumbs: (
        <BreadCrumbs>
          <BreadCrumbLink
            onClick={(e) => {
              e.preventDefault();
              navigate(
                `/ads-optimization/ads-manager/${MAP_SALES_CHANNEL_ID_TO_NAME[salesChannelId]}/sponsored-products/keyword-targets`,
                {
                  replace: true,
                }
              );
            }}
            textLabel={
              <FormattedMessage
                id={I18nKey.EMPTY_STATES_ADS_MANAGER_PAGE_HEADER}
              />
            }
            dataTestId="keyword_targeting_breadcrumbs"
          />
          <BreadCrumbLink
            onClick={(e) => {
              e.preventDefault();
            }}
            textLabel={
              <FormattedMessage
                id={I18nKey.CREATE_KEYWORDS_MODAL_CREATE_KEYWORDS}
              />
            }
            dataTestId="keyword_targeting_breadcrumbs"
          />
        </BreadCrumbs>
      ),
      dataTestId: 'ao_adsManagerCreateChatGptKeywordsModal',
    });
  }, []);

  return (
    <>
      <div
        className={classNames(
          'absolute top-72 right-0 bottom-0 h-min-fit w-subscriptionUsageDetails bg-white z-13',
          'box-borderfocus:outline-none'
        )}
      >
        <>
          <ConfirmationModal
            icon={AlertCircleIcon}
            showModal={
              saveChangesFlagContext.saveChangesData.saveChangesModalIsOpen ||
              showCancelAlert
            }
            onKeepEditing={handleCancelYes}
            onDiscard={handleCancelNo}
            header={CANCEL_ALERT_HEADER as string}
            body={CANCEL_ALERT_MESSAGE as string}
            buttonPrimaryText={intl.formatMessage({
              id: I18nKey.GENERAL_YES,
            })}
            buttonSecondaryText={intl.formatMessage({
              id: I18nKey.GENERAL_NO,
            })}
          />

          {showEditAdgroupAlert && (
            <ConfirmationModal
              icon={AlertCircleIcon}
              showModal={true}
              onKeepEditing={() => {
                navigateToAdGroupModal();
                setShowEditAdgroupAlert(false);
              }}
              onDiscard={() => setShowEditAdgroupAlert(false)}
              header={EDIT_ADGROUP_HEADER as string}
              body={EDIT_ADGROUP_MESSAGE as string}
              buttonPrimaryText={intl.formatMessage({
                id: I18nKey.GENERAL_YES,
              })}
              buttonSecondaryText={intl.formatMessage({
                id: I18nKey.GENERAL_NO,
              })}
            />
          )}
          {showEditKeywordTypeAlert && (
            <ConfirmationModal
              icon={AlertCircleIcon}
              showModal={true}
              onKeepEditing={handleEditKeywordTypeConfirmation}
              onDiscard={() => {
                setShowEditKeywrodTypeAlert(false);
                setNewTargetSegments([]);
              }}
              header={EDIT_TAG_TYPE_HEADER as string}
              body={EDIT_ADGROUP_MESSAGE as string}
              buttonPrimaryText={intl.formatMessage({
                id: I18nKey.GENERAL_YES,
              })}
              buttonSecondaryText={intl.formatMessage({
                id: I18nKey.GENERAL_NO,
              })}
            />
          )}
          <Routes>
            <Route
              path={'select-ad-group/*'}
              element={
                <AdGroupSelectionSlideoutModalV2
                  merchantCountryId={merchantCountryId}
                  isOpen={true}
                  onClose={(adGroupSelected?: boolean) =>
                    navigateToChatGPT(adGroupSelected)
                  }
                  heading={intl.formatMessage({
                    id: I18nKey.KEYWORD_RECOMMENDATIONS_AD_GROUP_SELECTION_COLUMN_PLACEHOLDER,
                  })}
                  onApplyChange={handleAdGroupChange}
                  onCountClick={handleCountClick}
                  defaultAdGroup={selectedAdGroup}
                />
              }
            />

            <Route
              path={'ad-group-details'}
              element={
                <SkuAndKeywordSlideOut
                  aoApiClient={aoClient}
                  accountId={accountId}
                  merchantCountry={{
                    salesChannelId,
                    merchantCountryId,
                    merchantType,
                    merchantName: '',
                    country: '',
                  }}
                  onClose={handleSkuAndKeywordSlideoutClose}
                />
              }
            />

            {requestKeywordsResponse && (
              <Route
                path={'feedback'}
                element={
                  <FeedbackSlideout
                    isSlideOut={!isKeywordsSubmitted}
                    accountId={accountId}
                    aoApiClient={aoClient}
                    onClose={handleFeedbackSlideoutClose}
                  />
                }
              />
            )}
          </Routes>
          <div
            className="flex flex-col overflow-hidden gap-24 h-full"
            data-test-id={`${dataTestId}_open`}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <EmptyState
                    title={NO_ADGROUP_MESSAGE as string}
                    icon={SparkleIcon}
                    containerClassName="mx-64 mb-80 gap-8 border border-dashed border-grey-300"
                    showButton={true}
                    buttonLabel={intl.formatMessage({
                      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECT_AD_GROUP,
                    })}
                    onClick={() =>
                      navigate(BASE_URL + '/select-ad-group' + location.search)
                    }
                  />
                }
              />

              <Route
                path="configure"
                element={
                  <ConfigureSettings
                    selectedAdGroup={selectedAdGroup}
                    showBrandNamesContainer={showBrandNamesContainer}
                    isSaving={isSaving}
                    onAdGroupEditClick={handleEditAdGroupClick}
                    onCountClick={handleCountClick}
                    onGenerateKeywordsClick={handleGenerateKeywordsClick}
                    onKeywordTypeChange={handleKeywordTypeChange}
                    onMatchTypeChange={handleMatchTypeCheckbox}
                    baseUrl={BASE_URL}
                  />
                }
              />
            </Routes>

            <div
              className={classNames(
                'flex items-center justify-center',
                'bg-white gap-12 absolute bottom-0 left-0 right-0 p-12',
                'box-border border-t border-solid border-grey-200'
              )}
            >
              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.BlackAndWhiteBorder}
                label={intl.formatMessage({
                  id: I18nKey.CANCEL,
                })}
                onClick={handleCancel}
                dataTestId={`${dataTestId}_cancel`}
              />

              <Tooltip
                {...getButtonTooltipProps(
                  brandTags,
                  showBrandNamesContainer,
                  brands,
                  targetSegments,
                  intl,
                  true,
                  keywords
                )}
                disabled={getSubmitButtonState() !== ButtonState.Disabled}
              >
                <Button
                  size={ButtonSize.Medium}
                  variant={ButtonVariant.Primary}
                  label={intl.formatMessage({
                    id: I18nKey.GENERIC_SUBMIT,
                  })}
                  state={getSubmitButtonState()}
                  onClick={onSubmit}
                  dataTestId={`${dataTestId}_submit`}
                />
              </Tooltip>
            </div>
          </div>
        </>
      </div>
    </>
  );
};
