import { DateTime } from 'luxon';
import React from 'react';
import { IntlShape, useIntl } from 'react-intl';

import {
  Alignment,
  ColumnAlign,
  IconSize,
  LightningGradientIcon,
  LightningSlashIcon,
  Placement,
  TableCellV2,
  TableCellV2Type,
  Theme,
  TooltipProps,
} from '@teikametrics/tm-design-system';

import { FlywheelTableV2ColumnProps } from '../../../../../../containers/tableV2/types';
import { getChannelIcon } from '../../../../../../lib/clients/utils';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { getMerchantName } from '../../../../../advertisingOptimization/containers/adsManager/utils';
import { BusinessPerformanceColumnName } from '../../utils/constants';
import {
  MerchantCellData,
  MerchantMetrics,
  PerformanceTableExtraProps,
} from '../../utils/performance';

const flywheelAIAddedDuration = (aiAddedDate: string) =>
  DateTime.fromISO(aiAddedDate).toRelativeCalendar()?.replace('ago', '');

export const getTooltipProps = (
  intl: IntlShape,
  flywheelAiAddedDate?: string
): TooltipProps => {
  return {
    content: intl.formatMessage(
      {
        id: flywheelAiAddedDate
          ? I18nKey.COMPASS_FLYWHEEL_AI_ENABLED_TOOLTIP_MESSAGE
          : I18nKey.COMPASS_FLYWHEEL_AI_DISABLED_TOOLTIP_MESSAGE,
      },
      {
        period: flywheelAiAddedDate
          ? flywheelAIAddedDuration(flywheelAiAddedDate)
          : undefined,
      }
    ),
    theme: Theme.Dark,
    dataTestId: 'flywheel_ai_added',
    showOnClick: true,
    overwrittenTooltipClassnames: 'max-w-132 whitespace-normal',
    position: {
      placementOffsetInPixels: 12,
      placement: Placement.Right,
      alignment: Alignment.Center,
    },
  };
};

export const RowCellElement: React.FC<MerchantCellData> = ({
  merchantCountryDetails,
  merchants,
  flywheelAiAddedDate,
}) => {
  const intl = useIntl();
  return (
    <TableCellV2
      tableCellData={{
        tableCellType: TableCellV2Type.MerchantCountryWithText,
        title: getMerchantName(
          merchants,
          merchantCountryDetails.merchantCountryId
        ),
        country: merchants.find(
          (merchant) =>
            merchant.merchantCountryId ===
            merchantCountryDetails.merchantCountryId
        )?.country,
        merchantIcon: {
          size: IconSize.Medium,
          svg: getChannelIcon(merchantCountryDetails.salesChannelName)!,
        },
        suffixIcon: {
          svg: flywheelAiAddedDate ? LightningGradientIcon : LightningSlashIcon,
          size: IconSize.Medium,
        },
        suffixTooltipProps: getTooltipProps(intl, flywheelAiAddedDate),
      }}
    />
  );
};

export const merchantDetails: FlywheelTableV2ColumnProps<
  MerchantMetrics,
  {},
  PerformanceTableExtraProps
> = {
  header: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MERCHANT_NAME,
  sortable: false,
  size: 320,
  align: ColumnAlign.Left,
  accessorKey: BusinessPerformanceColumnName.MerchantDetails,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <RowCellElement {...original} {...meta} />,
};
