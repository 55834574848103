import I18nKey from '../../lib/types/I18nKey';
import {
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

export interface GetStartedProps {
  readonly name?: string;
  readonly fade?: string;
}

export const GetStarted: React.FC<GetStartedProps> = ({ name, fade }) => {
  return (
    <div className={classNames('flex flex-col items-center', fade)}>
      <Typography
        size={TypographySize.xl}
        lineHeight={TypographyLineHeight.tight}
        weight={TypographyWeight.semibold}
        className="text-purple-600"
      >
        <FormattedMessage
          id={I18nKey.SUCCESS_HEADER}
          values={{
            name: name,
          }}
        />
      </Typography>
      <Typography
        size={TypographySize.xl}
        lineHeight={TypographyLineHeight.tight}
        weight={TypographyWeight.semibold}
        className="text-grey-900 motion-safe:animate-fadeInUp"
      >
        <FormattedMessage id={I18nKey.SUCCESS_SUB_HEADER} />
      </Typography>
    </div>
  );
};

GetStarted.displayName = 'GetStarted';
