import { fromNullable } from 'fp-ts/lib/Option';
import groupBy from 'lodash/groupBy';

import { MerchantCountryCode } from '../../../../../lib/types/CommonSharedTypes';
import {
  FlywheelSalesChannel,
  MerchantCountry,
  MerchantType,
} from '../../../../../lib/types/Fam';
import {
  EventLogCategoryConfiguration,
  EventLogCategoryNames,
  EventLogConfig,
  ProductDetailsSummaryResponse,
  SkuViewType,
} from '../../../../../lib/types/SKUSharedTypes';
import { getProductPageBaseUrlBySalesChannelAndMerchantCountry } from '../../../../../lib/utilities/baseUrlBySalesChannelAndMerchantCountry';
import { ChannelInfoProps } from '../skuDetailsSidebar/channel';
import { getMerchantCountryByCountryId } from '../skuDetailsSidebar/utils';
import {
  MERCHANT_COUNTRY_ID_QUERY_PARAM_KEY,
  PRODUCT_CATALOG_ID_QUERY_PARAM_KEY,
  PRODUCT_GROUP_ID_QUERY_PARAM_KEY,
  RowType,
  SkuDetailSidebarSummaryProps,
} from '../types';
import { IntlShape } from 'react-intl';
import I18nKey from '../../../../../lib/types/I18nKey';
import { NavPaths } from '../../../../../NavPaths';
import { parseQuery } from '../../../../../lib/utilities/queryString';
import { WALMART_SALES_CHANNEL_ID } from '../../../../../lib/types/SalesChannels';
import { Location } from 'react-router-dom';

export const generateSidebarPanelState = (
  sidebarData: ProductDetailsSummaryResponse,
  merchants: MerchantCountry[]
): SkuDetailSidebarSummaryProps => {
  const dataGroupedBySalesChannel = groupBy(
    sidebarData.elements,
    (item) => item.salesChannelName
  );
  const channelsForSummaryProps: ChannelInfoProps[] = [];

  for (const salesChannel in dataGroupedBySalesChannel) {
    const skuWithMerchantInfo = dataGroupedBySalesChannel[salesChannel];

    const channelInfo: ChannelInfoProps = {
      name: salesChannel as FlywheelSalesChannel,
      merchants: skuWithMerchantInfo?.map(
        ({
          merchantCountryId,
          merchantCountryName,
          extItemId,
          productCategory,
        }) => {
          const merchantCountryCode = getMerchantCountryByCountryId(
            merchantCountryId,
            merchants
          )?.country;

          const productPageUrl =
            getProductPageBaseUrlBySalesChannelAndMerchantCountry(
              salesChannel as FlywheelSalesChannel,
              merchantCountryCode as MerchantCountryCode
            );

          return {
            name: merchantCountryName,
            categories: productCategory ? productCategory.split(',') : [],
            countryCode: merchantCountryCode,
            link: fromNullable(productPageUrl)
              .map((baseUrl) => ({
                name: extItemId,
                url: `${baseUrl}${extItemId}`,
              }))
              .toUndefined(),
          };
        }
      ),
    };

    channelsForSummaryProps.push(channelInfo);
  }

  return {
    imageUrl: sidebarData.imageUrl,
    skuName: sidebarData.sku,
    skuTitle: sidebarData.name,
    channels: channelsForSummaryProps,
  };
};

export const getProductGroupIdFromUrl = (location: Location) => {
  const searchParams = parseQuery(location.search); // ?product_group_id=12345
  return searchParams[PRODUCT_GROUP_ID_QUERY_PARAM_KEY] ?? '';
};

export const getMerchantCountryIdFromUrl = (location: Location) => {
  const searchParams = parseQuery(location.search); // ?merchant_country_id=12345
  return searchParams[MERCHANT_COUNTRY_ID_QUERY_PARAM_KEY] ?? '';
};

export const getProductCatalogIdFromUrl = (location: Location) => {
  const searchParams = parseQuery(location.search); // ?product_catalog_id=12345
  return searchParams[PRODUCT_CATALOG_ID_QUERY_PARAM_KEY] ?? '';
};

export const getSkuViewSelectOptions = (intl: IntlShape) => {
  const [
    PARENT_CHILD_TITLE,
    PARENT_CHILD_DESC,
    INDIVIDUAL_CHILD_TITLE,
    INDIVIDUAL_CHILD_DESC,
  ] = [
    I18nKey.PRODUCTS_SKU_VIEW_TYPE_PARENT_CHILD_TITLE,
    I18nKey.PRODUCTS_SKU_VIEW_TYPE_PARENT_CHILD_DESC,
    I18nKey.PRODUCTS_SKU_VIEW_TYPE_INDIVIDUAL_CHILD_TITLE,
    I18nKey.PRODUCTS_SKU_VIEW_TYPE_INDIVIDUAL_CHILD_DESC,
  ].map((key) => intl.formatMessage({ id: key }));

  const currentViews = [
    {
      value: SkuViewType.ParantChild,
      label: PARENT_CHILD_DESC,
      groupName: PARENT_CHILD_TITLE,
    },
  ];

  currentViews.push({
    value: SkuViewType.IndividualSKU,
    label: INDIVIDUAL_CHILD_DESC,
    groupName: INDIVIDUAL_CHILD_TITLE,
  });
  return currentViews;
};

export const getRequiredRowType = (view: SkuViewType, depth?: number) => {
  if (view === SkuViewType.IndividualSKU) {
    return RowType.Child;
  } else if (view === SkuViewType.ParantChild) {
    switch (depth) {
      case 0:
        return RowType.Parent;
      case 1:
        return RowType.Child;
      default:
        return RowType.Parent;
    }
  } else if (view === SkuViewType.MultiChannel) {
    switch (depth) {
      case 0:
        return RowType.CustomTeikaGroup;
      case 1:
        return RowType.Parent;
      case 2:
        return RowType.Child;
      default:
        return RowType.Child;
    }
  }
};

export const isRowCustomTeikaGroup = (view: SkuViewType, depth?: number) =>
  getRequiredRowType(view, depth) === RowType.CustomTeikaGroup;

export const isRowParent = (view: SkuViewType, depth?: number) =>
  getRequiredRowType(view, depth) === RowType.Parent;

export const isRowChild = (view: SkuViewType, depth?: number) =>
  getRequiredRowType(view, depth) === RowType.Child;

export const getUrlParamsForDetailsPage = (
  productCatalogId?: string,
  merchantCountryId?: string
): Record<string, string> => ({
  [PRODUCT_CATALOG_ID_QUERY_PARAM_KEY]: productCatalogId || '',
  [MERCHANT_COUNTRY_ID_QUERY_PARAM_KEY]: merchantCountryId || '',
});

export const getBasePath = (merchant?: MerchantCountry) => {
  let basePath = NavPaths.IOSku;

  basePath =
    merchant?.merchantType === MerchantType.Vendor &&
    merchant.salesChannelId !== WALMART_SALES_CHANNEL_ID
      ? NavPaths.IOSkuVendor
      : NavPaths.IOSkuSeller;

  return basePath;
};

export const getFilteredEventConfigs = (
  config: EventLogConfig
): EventLogConfig => {
  const updatedConfig: EventLogCategoryConfiguration[] = [];
  config.eventLogCategoryConfiguration.forEach((e) => {
    const configCategoryName = e.name as EventLogCategoryNames;
    if ([EventLogCategoryNames.Content].includes(configCategoryName)) {
      updatedConfig.push(e);
    } else if (
      [EventLogCategoryNames.SeasonalEvent].includes(configCategoryName)
    ) {
      updatedConfig.push(e);
    } else if (
      [EventLogCategoryNames.ProductCatalog].includes(configCategoryName)
    ) {
      updatedConfig.push(e);
    } else if ([EventLogCategoryNames.Inventory].includes(configCategoryName)) {
      updatedConfig.push(e);
    }
  });
  config.eventLogCategoryConfiguration = updatedConfig;
  return config;
};
