import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@teikametrics/tm-design-system';

import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../containers/optimizelyProvider/optimizelyProvider';
import {
  SaveChangesFlagContext,
  SaveChangesFlagContextState,
} from '../../containers/saveChangesFlagProvider';
import { useUserCredentials } from '../../lib/hooks/useUserCredentials';
import { OptimizelyFlags } from '../../lib/types/OptimizelyFlags';

import { NavPaths } from '../../NavPaths';
import { AdditionalTags } from '../AdditionalTags';
import NotificationBadge from '../Notifications/NotificationBadge';

import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import {
  getCurrentAccountFromContext,
  isAgencyAccount,
} from '../../containers/userProvider/selectors';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/types/I18nKey';
import {
  PageHeaderContext,
  PageHeaderContextState,
} from '../../containers/pageHeaderProvider';
import { scrollToRefInstant } from '../../lib/utilities/commonUtils';
import { MetadataTags } from '../../lib/types/Fam';

export interface MainPageHeaderProps {}

export const MainPageHeader: React.FC<MainPageHeaderProps> = ({}) => {
  const intl = useIntl();
  const userCredentials = useUserCredentials();
  const userContext = useContext<UserContextState>(UserContext);
  const currentAccount = getCurrentAccountFromContext(userContext);

  const {
    pageHeaderData: {
      title,
      breadCrumbs,
      dataTestId,
      returnLink,
      buttonLink,
      titleString,
    },
    scrollToTopToggle,
  } = useContext<PageHeaderContextState>(PageHeaderContext);

  const saveChangesFlagContext = useContext<SaveChangesFlagContextState>(
    SaveChangesFlagContext
  );
  const navigate = useNavigate();

  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);

  const notificationInboxFlag =
    optimizelyContext.featureFlags[OptimizelyFlags.NotificationInbox];
  const [hideFreeTrialContent, setHideFreeTrialContent] = useState<boolean>();

  useEffect(() => {
    const hideFreeTrialTag = userContext.accountMetadata?.find(
      (tag) => tag.tag === MetadataTags.HideFreeTrialContent
    );
    setHideFreeTrialContent(hideFreeTrialTag?.value === 'enabled');
  }, [userContext.accountMetadata]);

  const onManualPageRedirection = (path: string) => {
    if (saveChangesFlagContext.saveChangesData.editDataFlag) {
      saveChangesFlagContext.updateSaveChangesData({
        ...saveChangesFlagContext.saveChangesData,
        navPath: path,
        saveChangesModalIsOpen: true,
      });
    } else {
      navigate(path);
    }
  };

  const onLogout = () => {
    onManualPageRedirection(NavPaths.Logout);
  };

  const onEditProfile = () => {
    onManualPageRedirection(NavPaths.UserProfile);
  };

  const onSwitchAccount = () => {
    onManualPageRedirection(NavPaths.SwitchAccount);
  };

  const ref = useRef(null);

  useEffect(() => {
    scrollToRefInstant(ref);
  }, [scrollToTopToggle]);
  const intercomId = typeof title === 'string' ? title : titleString;

  const convertedIntercomId = intercomId?.toLowerCase()?.split(' ')?.join('-');

  return (
    <div ref={ref}>
      <PageHeader
        intercomId={convertedIntercomId}
        title={title as any}
        companyName={userCredentials.companyName}
        firstName={userCredentials.userDetails.firstName}
        lastName={userCredentials.userDetails.lastName}
        userRole={userCredentials.userRole}
        showAccountSwitcher={
          userCredentials.userDetails?.accountPermissions.length > 1 ||
          isAgencyAccount(currentAccount)
        }
        breadCrumbs={breadCrumbs}
        dataTestId={dataTestId}
        onLogout={onLogout}
        onEditProfile={onEditProfile}
        onSwitchAccount={onSwitchAccount}
        returnLink={returnLink}
        buttonLink={buttonLink}
        additional={
          <div className="flex items-center mr-4">
            {/**
             * DO NOT REMOVE below element with class beamerTrigger
             * It is used for Beamer to display updates
             */}
            <div
              className={classNames(
                'beamerTrigger',
                'text-sm leading-none font-normal mr-8',
                'border-b border-solid border-grey-400'
              )}
            >
              {intl.formatMessage({
                id: I18nKey.GENERIC_WHATS_NEW,
              })}
            </div>
            <AdditionalTags hideFreeTrialContent={hideFreeTrialContent} />
            {notificationInboxFlag && <NotificationBadge />}
          </div>
        }
      />
    </div>
  );
};
MainPageHeader.displayName = 'MainPageHeader';
