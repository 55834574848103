import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { IdToken } from '@auth0/auth0-react';

import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { AccountSalesChannelPaginatedResultWithError } from '../../modules/products/containers/skuCatalog/types';
import { SalesChannelContext } from '../salesChannelProvider';
import { UserContext } from '../userProvider/userProvider';
import { NavPaths } from '../../NavPaths';
import { useLocation } from 'react-router-dom';

export interface SubscriptionState {
  readonly salesChannelData: AccountSalesChannelPaginatedResultWithError[];
  readonly isSubscriptionInformationLoaded: boolean;
  readonly updateSubscriptionInformation: () => void;
  readonly clearSubscriptionContext?: () => void;
}

const initialState: SubscriptionState = {
  salesChannelData: [],
  isSubscriptionInformationLoaded: false,
  updateSubscriptionInformation: noop,
  clearSubscriptionContext: noop,
};

export const SubscriptionContext =
  createContext<SubscriptionState>(initialState);

SubscriptionContext.displayName = 'SubscriptionContext';

const { Provider } = SubscriptionContext;

export const SubscriptionProvider: React.FC<
  Readonly<
    {
      idToken: IdToken;
    } & PropsWithChildren
  >
> = ({ children, idToken }) => {
  const location = useLocation();
  const [salesChannelData, setSalesChannelData] = useState<
    AccountSalesChannelPaginatedResultWithError[]
  >([]);
  const [isSubscriptionInformationLoaded, setSubscriptionInformationLoaded] =
    useState(false);

  const salesChannelContext = useContext(SalesChannelContext);
  const { salesChannels, areSalesChannelsLoaded } = salesChannelContext;

  const famAPIClient = createFAMApiClient(idToken);

  const { userInfo } = useContext(UserContext);
  const currentAccountId = userInfo?.currentAccountId;

  useEffect(() => {
    /**
     * If the user is on the Account switcher page.
     * Don't call the data sync APIs as they are not required.
     * As soon as they are in. It will be called due to account/MC changes
     */
    if (location.pathname.includes(NavPaths.SwitchAccount)) {
      return;
    }
    setSubscriptionInformation();
  }, [areSalesChannelsLoaded, salesChannels, currentAccountId]);

  const setSubscriptionInformation = () => {
    if (areSalesChannelsLoaded && !isNil(currentAccountId)) {
      const salesChannelDataPromises = salesChannels.map(({ id, name }) =>
        getSalesChannelsForCurrentAccount(currentAccountId, id, name)
      );
      setSubscriptionInformationLoaded(false);

      Promise.all(salesChannelDataPromises)
        .then((response) => {
          const merchantsFromResponse = response.reduce<
            AccountSalesChannelPaginatedResultWithError[]
          >((prevValue, currentValue) => {
            prevValue.push(currentValue);
            return prevValue;
          }, []);

          setSalesChannelData(merchantsFromResponse);
          setSubscriptionInformationLoaded(true);
        })
        .catch(() => {
          setSubscriptionInformationLoaded(true);
          setSalesChannelData([]);
        });
    }
  };

  const updateSubscriptionInformation = () => setSubscriptionInformation();

  const clearSubscriptionContext = () => {
    setSubscriptionInformationLoaded(true);
    setSalesChannelData([]);
  };

  const getSalesChannelsForCurrentAccount = async (
    accountId: string,
    salesChannelId: string,
    salesChannelName: string
  ): Promise<AccountSalesChannelPaginatedResultWithError> => {
    try {
      const merchantConnectionDetails =
        await famAPIClient.getSalesChannelTableData(
          accountId,
          salesChannelId
        )({
          filters: [],
          sorts: [],
          page: 1,
          itemsPerPage: 10000,
        });
      return { ...merchantConnectionDetails, salesChannel: salesChannelName };
    } catch {
      return {
        items: [],
        totalItems: 0,
        isError: true,
      };
    }
  };

  return (
    <Provider
      value={{
        salesChannelData,
        isSubscriptionInformationLoaded,
        updateSubscriptionInformation,
        clearSubscriptionContext,
      }}
    >
      {children}
    </Provider>
  );
};
SubscriptionProvider.displayName = 'SubscriptionProvider';
