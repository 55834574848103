import { DateTime } from 'luxon';

export const formatDateRange = (
  startDate: DateTime,
  endDate: DateTime,
  format?: string
) => {
  const actualFormat = format || 'yyyy-mm-dd';
  const startString = startDate.toFormat(actualFormat);
  const endString = endDate.toFormat(actualFormat);
  return [startString, endString].join(' - ');
};
export function billing30DayRange(
  nextBillingDate: string | undefined = ''
): { startDate: DateTime; endDate: DateTime } | undefined {
  if (DateTime.fromISO(nextBillingDate).isValid) {
    const startDate = DateTime.fromISO(nextBillingDate)
      .minus({ days: 4 })
      .minus({ months: 1 })
      .plus({ days: 1 });

    const endDate = DateTime.fromISO(nextBillingDate).minus({ days: 4 });

    return {
      startDate,
      endDate,
    };
  }

  return undefined;
}

export function billing30DayRangeFormatted(date: string | undefined): {
  startDate: string;
  endDate: string;
} {
  const dateRange = billing30DayRange(date);

  return dateRange
    ? {
        startDate: dateRange.startDate.toFormat('MMM dd'),
        endDate: dateRange.endDate.toFormat('MMM dd'),
      }
    : {
        startDate: '',
        endDate: '',
      };
}

export const billingDateLongFormatted = (date: string): string => {
  return DateTime.fromISO(date).toFormat('MMM dd, yyyy');
};
