import React from 'react';

import { ReactComponent as FilterIcon } from '../../img/icons_deprecated/filtered.svg';

interface Props {
  readonly tryAgainClickHandler: () => void;
}

const FiltersWithNoData: React.FC<Props> = ({ tryAgainClickHandler }) => (
  <div className="table__no-data-with-filters">
    <FilterIcon />
    <p>No results match your filters</p>
    <button
      onClick={tryAgainClickHandler}
      className="flywheel"
      data-test-id={'filtersWithNoDataTryAgain'}
    >
      Try Again
    </button>
  </div>
);
FiltersWithNoData.displayName = 'FiltersWithNoData';
export default FiltersWithNoData;
