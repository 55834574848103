import {
  ColumnAlign,
  EmDash,
  TableCellV2,
  TableCellV2Type,
} from '@teikametrics/tm-design-system';
import { DateTime } from 'luxon';
import React from 'react';
import { FlywheelTableV2ColumnProps } from '../../../containers/tableV2/types';
import {
  EstPreAdGrossMarginItem,
  EstPreAdGrossMarginTableFields,
} from '../../../lib/types/CampaignFlexibilitySharedTypes';
import I18nKey from '../../../lib/types/I18nKey';
import { TableExtraProps } from '../EstAdGrossMarginTable';
import Skeleton from 'react-loading-skeleton';

const LAST_DATE_FORMAT = 'MMM dd, yyyy';

export const Cell: React.FC<EstPreAdGrossMarginItem & TableExtraProps> = ({
  cogsLastUpdatedAt,
  bulkUpdateInProgress,
}) => {
  return (
    <TableCellV2
      tableCellData={{
        tableCellType: TableCellV2Type.Text,
        currentValue: bulkUpdateInProgress ? (
          <Skeleton height={14} width={48} />
        ) : cogsLastUpdatedAt ? (
          DateTime.fromISO(cogsLastUpdatedAt).toFormat(LAST_DATE_FORMAT)
        ) : (
          <EmDash />
        ),
        className: 'break-normal line-clamp-3 whitespace-pre-wrap text-right',
      }}
      className="h-full flex justify-center items-end"
    />
  );
};

Cell.displayName = 'estPreAdGrossMargin_lastUpdated';

export const lastUpdatedColumn: FlywheelTableV2ColumnProps<
  EstPreAdGrossMarginItem,
  {},
  TableExtraProps,
  {}
> = {
  header:
    I18nKey.CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_TABLE_COLUMN_LAST_UPDATED,
  accessorKey: EstPreAdGrossMarginTableFields.lastUpdated,
  size: 96,
  maxSize: 96,
  minSize: 96,
  sortable: false,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell {...original} {...meta} />,
};
