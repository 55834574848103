import { IntlShape } from 'react-intl';

import I18nKey from '../../lib/types/I18nKey';
import { MetadataType } from '../../lib/types/SKUSharedTypes';

export const TEMPLATES_ALL = (intl: IntlShape, showDSP?: boolean) => [
  {
    label: intl.formatMessage({
      id: I18nKey.CUSTOM_UPLOAD_TEMPLATE_DROPDOWN_PRODUCT,
    }),
    value: MetadataType.PRODUCT,
    disabled: false,
  },
  {
    label: intl.formatMessage({
      id: I18nKey.CUSTOM_UPLOAD_TEMPLATE_DROPDOWN_ADS,
    }),
    value: MetadataType.ADS,
    disabled: false,
  },
  {
    label: intl.formatMessage({
      id: I18nKey.CUSTOM_UPLOAD_TEMPLATE_DROPDOWN_BUDGET,
    }),
    value: MetadataType.BUDGET,
    disabled: false,
  },
  ...(showDSP
    ? [
        {
          label: intl.formatMessage({
            id: I18nKey.CUSTOM_UPLOAD_TEMPLATE_DROPDOWN_DSP,
          }),
          value: MetadataType.DSP,
          disabled: false,
        },
      ]
    : []),
];

export const DATE_FORMAT = 'yyyyMMdd';
export const SYNC_STATUS_POLLING_INTERVAL = 5000; // 5s
export const SYNC_STATUS_POLLING_MAX_TRIES = 50;
