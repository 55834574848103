import intersection from 'lodash/intersection';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';

import {
  HelpPromptProps,
  HelpPromptVariant,
  ORIGINAL_DATE_FORMAT,
} from '@teikametrics/tm-design-system';

import {
  TableCellChange,
  TableChange,
  TableSelectSettings,
} from '../../../../../containers/table/ducks/types';
import {
  AdType,
  CampaignDetails,
  CampaignStatus,
  ChannelSettings,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  getIncompleteCampaignCount,
  getValidDateCount,
  isBudgetTypeDaily,
  isBudgetTypeLifetime,
  isCampaignAdFormatProductCollectionOrSpotlight,
  isManualCampaign,
} from '../utils';
import {
  ADTYPES_ARRAY_SB_SBV,
  CampaignBulkEditModalValues,
  endDateOptions,
} from './campaignName';
import { SMART_GOALS } from '../adgroupTableColumns/utils';

export const getValidCampaignStatusCount = (
  campaignsTableData: CampaignDetails[],
  tableChange: TableChange
) =>
  campaignsTableData.filter(
    ({ channelSettings: { status }, campaignId }) =>
      status !== CampaignStatus.Completed &&
      tableChange.select.rows.includes(campaignId)
  ).length;

export const getValidAutomationStatusCount = (
  campaignsTableData: CampaignDetails[],
  tableChange: TableChange,
  selectSettings: TableSelectSettings
) => {
  // selected rows with automation status != Unset
  const selectedRowsWithSetAutomationStatus = selectSettings.rows.filter(
    (rowId) => {
      const editedRow = tableChange.cell[rowId];
      return (
        !isNil(editedRow) &&
        !isNil(editedRow[CAMPAIGNS_API_COLUMN_NAME.AutomationStatus])
      );
    }
  );

  // rows from the original data with automation status != Unset and are not modified
  const allCampaignIdsWithAutomationStatusSet = campaignsTableData
    .filter(
      ({ flywheelSettings: { automationStatus }, campaignId }) =>
        !isNil(automationStatus) &&
        !selectedRowsWithSetAutomationStatus.includes(campaignId)
    )
    .map(({ campaignId }) => campaignId);

  const commonAutomationStatusCampaignIds = intersection(
    allCampaignIdsWithAutomationStatusSet,
    selectSettings.rows
  );

  return (
    selectedRowsWithSetAutomationStatus.length +
    commonAutomationStatusCampaignIds.length
  );
};

export const getHelpGuideEndDateView = ({
  intl,
  selectSettings,
  endDateOption,
  campaignsTableData,
  tableChange,
  values,
  isSelectedSalesChannelWalmart,
}: {
  intl: IntlShape;
  selectSettings: TableSelectSettings;
  endDateOption: string;
  campaignsTableData: CampaignDetails[];
  tableChange: TableChange;
  values: string[];
  isSelectedSalesChannelWalmart: boolean;
}) => {
  const totalSelectedCount = selectSettings.rows.length;
  if (endDateOption === endDateOptions.NoEndDate) {
    const validNoEndDateCount = getIncompleteCampaignCount(
      campaignsTableData,
      selectSettings,
      tableChange
    );

    if (validNoEndDateCount !== totalSelectedCount) {
      return {
        variant: HelpPromptVariant.Dropdown,
        text: (
          <>
            {intl.formatMessage(
              {
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_STATUS_APPLICABLE_MESSAGE,
              },
              {
                totalSelectedCount,
                validCount: validNoEndDateCount,
              }
            )}
          </>
        ),
        content: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_NO_END_DATE_APPLICABLE_REASON,
          },
          {
            totalSelectedCount,
            validCount: validNoEndDateCount,
          }
        ),
      };
    }
  }

  if (endDateOption === endDateOptions.SetEndDate) {
    const endDateAllowedLimit = DateTime.fromFormat(
      values[0],
      ORIGINAL_DATE_FORMAT
    );
    const validEndDateCount = getValidDateCount(
      endDateAllowedLimit,
      campaignsTableData,
      selectSettings,
      tableChange
    );

    if (
      validEndDateCount !== totalSelectedCount &&
      endDateAllowedLimit.isValid
    ) {
      return {
        variant: HelpPromptVariant.Dropdown,
        text: (
          <>
            {intl.formatMessage(
              {
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_STATUS_APPLICABLE_MESSAGE,
              },
              {
                totalSelectedCount,
                validCount: validEndDateCount,
              }
            )}
          </>
        ),
        content: intl.formatMessage(
          {
            id: isSelectedSalesChannelWalmart
              ? I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_WALMART_BULK_EDIT_MODAL_END_DATE_APPLICABLE_REASON
              : I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_END_DATE_APPLICABLE_REASON,
          },
          {
            totalSelectedCount,
            validCount: validEndDateCount,
          }
        ),
      };
    }
  }
};

export const getHelpGuideCampaignStatus = ({
  intl,
  campaignsTableData,
  selectedCount,
  tableChange,
  selectSettings,
}: {
  intl: IntlShape;
  campaignsTableData: CampaignDetails[];
  selectedCount: number;
  tableChange: TableChange;
  selectSettings: TableSelectSettings;
}) => {
  const areSomeCompleted = campaignsTableData
    .filter(({ campaignId }: CampaignDetails) =>
      selectSettings.rows.includes(campaignId)
    )
    .some(
      ({ channelSettings: { status } }: CampaignDetails) =>
        status === CampaignStatus.Completed
    );

  return areSomeCompleted
    ? {
        text: (
          <>
            {intl.formatMessage(
              {
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_STATUS_APPLICABLE_MESSAGE,
              },
              {
                totalSelectedCount: selectedCount,
                validCount: getValidCampaignStatusCount(
                  campaignsTableData,
                  tableChange
                ),
              }
            )}
          </>
        ),
        content: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_STATUS_APPLICABLE_REASON,
        }),
        variant: HelpPromptVariant.Dropdown,
      }
    : undefined;
};

export const getCampaignsTableDataWithUpdatedBidOptimization = (
  allCampaignsTableData: CampaignDetails[],
  updateCells: TableCellChange
) => {
  return allCampaignsTableData.map((detail) => {
    if (!isUndefined(updateCells[detail.campaignId])) {
      const updatedCell = updateCells[detail.campaignId];
      if (!isUndefined(updatedCell['bidOptimization'])) {
        const updatedChannelSettings: ChannelSettings = {
          ...detail.channelSettings,
          bidOptimization: updatedCell['bidOptimization'] === 'true',
        };
        return { ...detail, channelSettings: updatedChannelSettings };
      }
    }
    return detail;
  });
};

export const getHelpGuide = ({
  intl,
  salesChannel,
  bulkMenuValue,
  adType,
  selectSettings,
  endDateOption,
  campaignsTableData,
  tableChange,
  values,
  isSelectedSalesChannelWalmart,
  selectedCount,
  updateCells,
}: {
  intl: IntlShape;
  salesChannel: FlywheelSalesChannel | null;
  bulkMenuValue: CampaignBulkEditModalValues | null;
  adType: AdType | null;
  selectSettings: TableSelectSettings;
  endDateOption: string;
  campaignsTableData: CampaignDetails[];
  tableChange: TableChange;
  values: string[];
  isSelectedSalesChannelWalmart: boolean;
  selectedCount: number;
  updateCells: TableCellChange;
}): HelpPromptProps | undefined => {
  const getHelpGuideEndDate = () =>
    getHelpGuideEndDateView({
      intl,
      selectSettings,
      endDateOption,
      campaignsTableData,
      tableChange,
      values,
      isSelectedSalesChannelWalmart,
    });

  const helpGuideCampaignStatus = () =>
    getHelpGuideCampaignStatus({
      intl,
      campaignsTableData,
      selectedCount,
      tableChange,
      selectSettings,
    });

  const getHelpGuidePlacementInclusionStatus = () => {
    const allManualCampaignIds = campaignsTableData
      .filter(({ campaignDetails: { targetingType } }) =>
        isManualCampaign(targetingType)
      )
      .map(({ campaignId }) => campaignId);
    const commonManualCampaignsIds = intersection(
      allManualCampaignIds,
      selectSettings.rows
    );

    const validCount = commonManualCampaignsIds.length;
    const totalSelectedCount = selectSettings.rows.length;

    if (validCount !== totalSelectedCount) {
      return {
        variant: HelpPromptVariant.Dropdown,
        text: (
          <>
            {intl.formatMessage(
              {
                id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_STATUS_APPLICABLE_MESSAGE,
              },
              {
                totalSelectedCount,
                validCount,
              }
            )}
          </>
        ),
        content: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SEARCH_CAROUSEL_INCLUSION_APPLICABLE_REASON,
          },
          {
            totalSelectedCount,
            validCount,
          }
        ),
      };
    }
  };

  const helpGuideAdvertisingGoal = () => {
    const validSmartAdveritsingGoals =
      campaignsTableData?.filter(
        ({ campaignDetails: { advertisingGoal } }) =>
          advertisingGoal && SMART_GOALS.includes(advertisingGoal)
      ).length ?? 0;
    const numberOfCampaignsSelected = campaignsTableData?.length ?? 0;
    if (validSmartAdveritsingGoals < numberOfCampaignsSelected) {
      return {
        text: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_ADVERTISING_GOAL_APPLICABLE_HEADER,
          },
          { validSmartAdveritsingGoals, numberOfCampaignsSelected }
        ),
        content: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_ADVERTISING_GOAL_APPLICABLE_REASON,
          },
          {
            validSmartAdveritsingGoals,
            numberOfCampaignsSelected,
            nonSmartCampaignCount:
              numberOfCampaignsSelected - validSmartAdveritsingGoals,
          }
        ),
        variant: HelpPromptVariant.Dropdown,
      };
    }
  };

  if (bulkMenuValue === CampaignBulkEditModalValues.AdvertisingGoal) {
    return helpGuideAdvertisingGoal();
  }

  if (salesChannel === FlywheelSalesChannel.Walmart) {
    switch (bulkMenuValue) {
      case CampaignBulkEditModalValues.CampaignStatus:
        return helpGuideCampaignStatus();

      case CampaignBulkEditModalValues.PlacementInclusion:
        return getHelpGuidePlacementInclusionStatus();

      case CampaignBulkEditModalValues.EndDate:
        return getHelpGuideEndDate();

      default:
        return undefined;
    }
  } else if (salesChannel === FlywheelSalesChannel.Amazon) {
    return getReturnValueForAmazonChannel({
      intl,
      adType,
      bulkMenuValue,
      campaignsTableData,
      selectSettings,
      updateCells,
      tableChange,
      getHelpGuideEndDate,
    });
  }
  return undefined;
};

export const getReturnValueForAmazonChannel = ({
  intl,
  adType,
  bulkMenuValue,
  campaignsTableData,
  selectSettings,
  updateCells,
  tableChange,
  getHelpGuideEndDate,
}: {
  intl: IntlShape;
  adType: AdType | null;
  bulkMenuValue: CampaignBulkEditModalValues | null;
  campaignsTableData: CampaignDetails[];
  selectSettings: TableSelectSettings;
  updateCells: TableCellChange;
  tableChange: TableChange;
  getHelpGuideEndDate: () =>
    | { variant: HelpPromptVariant; text: JSX.Element; content: string }
    | undefined;
}) => {
  if (bulkMenuValue === CampaignBulkEditModalValues.EndDate) {
    return getHelpGuideEndDate();
  }
  if (!adType) {
    return undefined;
  }
  switch (bulkMenuValue) {
    case CampaignBulkEditModalValues.DailyBudget:
      if (ADTYPES_ARRAY_SB_SBV.includes(adType)) {
        return getHelpGuideDailyBudget({
          intl,
          campaignsTableData,
          selectSettings,
        });
      }
      break;
    case CampaignBulkEditModalValues.LifetimeBudget:
      if (ADTYPES_ARRAY_SB_SBV.includes(adType)) {
        return getHelpGuideLifetimeBudget({
          intl,
          campaignsTableData,
          selectSettings,
        });
      }
      break;
    case CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSP:
    case CampaignBulkEditModalValues.PlacementBidMultipliersAmazonSB:
      if (ADTYPES_ARRAY_SB_SBV.includes(adType)) {
        return getHelpGuidePlacementBidMultiplier({
          intl,
          campaignsTableData,
          selectSettings,
          updateCells,
        });
      }
      break;
    case CampaignBulkEditModalValues.AutomationStatus:
      if (ADTYPES_ARRAY_SB_SBV.includes(adType)) {
        return getHelpGuideAutomationStatusMultiplier({
          intl,
          campaignsTableData,
          selectSettings,
          tableChange,
        });
      }
      break;
    case CampaignBulkEditModalValues.AmazonBidOptimization:
      if (ADTYPES_ARRAY_SB_SBV.includes(adType)) {
        return getHelpGuideAmazonBidOptimization({
          intl,
          campaignsTableData,
          selectSettings,
          updateCells,
        });
      }
      break;
    default:
      return undefined;
  }
};

export const getHelpGuideDailyBudget = ({
  intl,
  campaignsTableData,
  selectSettings,
}: {
  intl: IntlShape;
  campaignsTableData: CampaignDetails[];
  selectSettings: TableSelectSettings;
}) => {
  const allDaiyBudgetCampaignIds = campaignsTableData
    .filter(({ channelSettings: { budgetType } }) =>
      isBudgetTypeDaily(budgetType)
    )
    .map(({ campaignId }) => campaignId);

  const commonDailyBudgetCampaignsIds = intersection(
    allDaiyBudgetCampaignIds,
    selectSettings.rows
  );

  const validCount = commonDailyBudgetCampaignsIds.length;
  const totalSelectedCount = selectSettings.rows.length;
  if (validCount !== totalSelectedCount) {
    return {
      variant: HelpPromptVariant.Dropdown,
      text: (
        <>
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_DAILY_BUDGET_APPLICABLE_MESSAGE,
            },
            {
              totalSelectedCount,
              validCount,
            }
          )}
        </>
      ),
      content: intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_DAILY_BUDGET_APPLICABLE_REASON,
        },
        {
          totalSelectedCount,
          validCount,
        }
      ),
    };
  }
};

export const getHelpGuideLifetimeBudget = ({
  intl,
  campaignsTableData,
  selectSettings,
}: {
  intl: IntlShape;
  campaignsTableData: CampaignDetails[];
  selectSettings: TableSelectSettings;
}) => {
  const allLifetimeBudgetCampaignIds = campaignsTableData
    .filter(({ channelSettings: { budgetType } }) =>
      isBudgetTypeLifetime(budgetType)
    )
    .map(({ campaignId }) => campaignId);

  const commonDailyBudgetCampaignsIds = intersection(
    allLifetimeBudgetCampaignIds,
    selectSettings.rows
  );

  const validCount = commonDailyBudgetCampaignsIds.length;
  const totalSelectedCount = selectSettings.rows.length;
  if (validCount !== totalSelectedCount) {
    return {
      variant: HelpPromptVariant.Dropdown,
      text: (
        <>
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_LIFETIME_APPLICABLE_MESSAGE,
            },
            {
              totalSelectedCount,
              validCount,
            }
          )}
        </>
      ),
      content: intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_LIFETIME_BUDGET_APPLICABLE_REASON,
        },
        {
          totalSelectedCount,
          validCount,
        }
      ),
    };
  }
};

export const getHelpGuidePlacementBidMultiplier = ({
  intl,
  campaignsTableData,
  selectSettings,
  updateCells,
}: {
  intl: IntlShape;
  campaignsTableData: CampaignDetails[];
  selectSettings: TableSelectSettings;
  updateCells: TableCellChange;
}) => {
  const selectedRowData = campaignsTableData.filter((data) =>
    selectSettings.rows.includes(data.campaignId)
  );
  const currentCampaignsTableData =
    getCampaignsTableDataWithUpdatedBidOptimization(
      selectedRowData,
      updateCells
    );
  const allCampaignIdsWithBidOptimizationTurnedOff = currentCampaignsTableData
    .filter(
      ({ channelSettings: { bidOptimization } }) => bidOptimization === false
    )
    .map(({ campaignId }) => campaignId);

  const allCampaignIdsWithAdFormatProduct = currentCampaignsTableData
    .filter(({ campaignDetails: { campaignAdFormat } }) =>
      isCampaignAdFormatProductCollectionOrSpotlight(campaignAdFormat)
    )
    .map(({ campaignId }) => campaignId);

  const commonPlacementBidMultiplierCampaignIds = intersection(
    allCampaignIdsWithBidOptimizationTurnedOff,
    allCampaignIdsWithAdFormatProduct
  );

  const validCount = commonPlacementBidMultiplierCampaignIds.length;
  const totalSelectedCount = selectSettings.rows.length;
  if (validCount !== totalSelectedCount) {
    return {
      variant: HelpPromptVariant.Dropdown,
      text: (
        <>
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIER_APPLICABLE_MESSAGE,
            },
            {
              totalSelectedCount,
              validCount,
            }
          )}
        </>
      ),
      content: intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIER_APPLICABLE_REASON,
        },
        {
          totalSelectedCount,
          validCount,
        }
      ),
    };
  }
};

export const getHelpGuideAutomationStatusMultiplier = ({
  intl,
  campaignsTableData,
  selectSettings,
  tableChange,
}: {
  intl: IntlShape;
  campaignsTableData: CampaignDetails[];
  selectSettings: TableSelectSettings;
  tableChange: TableChange;
}) => {
  const validCount = getValidAutomationStatusCount(
    campaignsTableData,
    tableChange,
    selectSettings
  );
  const totalSelectedCount = selectSettings.rows.length;

  if (validCount !== totalSelectedCount) {
    return {
      variant: HelpPromptVariant.Dropdown,
      text: (
        <>
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTOMATION_STATUS_APPLICABLE_MESSAGE,
            },
            {
              totalSelectedCount,
              validCount,
            }
          )}
        </>
      ),
      content: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTOMATION_STATUS_APPLICABLE_REASON,
      }),
    };
  }
};

export const getHelpGuideAmazonBidOptimization = ({
  intl,
  campaignsTableData,
  selectSettings,
  updateCells,
}: {
  intl: IntlShape;
  campaignsTableData: CampaignDetails[];
  selectSettings: TableSelectSettings;
  updateCells: TableCellChange;
}) => {
  const selectedRowData = campaignsTableData.filter((data) =>
    selectSettings.rows.includes(data.campaignId)
  );
  const currentCampaignTableData =
    getCampaignsTableDataWithUpdatedBidOptimization(
      selectedRowData,
      updateCells
    );
  const selectedCampaignIdsWithNonVideoAdFormat = currentCampaignTableData
    .filter(({ campaignDetails: { campaignAdFormat } }) =>
      isCampaignAdFormatProductCollectionOrSpotlight(campaignAdFormat)
    )
    .map(({ campaignId }) => campaignId);

  const validCount = selectedCampaignIdsWithNonVideoAdFormat.length;
  const totalSelectedCount = selectSettings.rows.length;
  if (validCount !== totalSelectedCount) {
    return {
      variant: HelpPromptVariant.Dropdown,
      text: (
        <>
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AMAZON_BID_OPTIMIZATION_APPLICABLE_MESSAGE,
            },
            {
              totalSelectedCount,
              validCount,
            }
          )}
        </>
      ),
      content: intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AMAZON_BID_OPTIMIZATION_APPLICABLE_REASON,
        },
        {
          totalSelectedCount,
          validCount,
        }
      ),
    };
  }
};
