import {
  ChevronDownIcon,
  ChevronUpIcon,
  Icon,
  IconSize,
  SearchInput,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import React, { useEffect, useMemo, useState } from 'react';
import { ViewTrendsEntityType } from '../../../modules/advertisingOptimization/components/ViewTrends';
import {
  AdGroupDisplayLabel,
  AdGroupLoadingSkeleten,
} from './AdGroupDisplayLabel';
import { AdGroupSelectOption } from './AdGroupSelectOption';
import {
  CampaignsDisplayLabel,
  CampaignsLoadingSkeleton,
} from './CampaignsDisplayLabel';
import { CampaignsSelectOption } from './CampaignsSelectOption';
import { AdLevel } from '../../../lib/types/AOSharedTypes';

export interface CampaignsAdGroupsSelectOptionProps {
  readonly campaignName?: string;
  readonly adGroupName?: string;
  readonly targetingType?: string;
  readonly status?: string;
  readonly statusString?: string;
  readonly adGroupId?: string;
  readonly campaignId?: string;
  readonly adLevel?: string;
  readonly onChange?: (value?: string) => void;
  readonly selected?: boolean;
  readonly tags?: React.ReactNode[];
  readonly skuKeywordCountLabel?: string;
  readonly targetSegments?: string[];
}

export interface CampaignsSelectProps {
  readonly options?: CampaignsAdGroupsSelectOptionProps[];
  readonly value?: string;
  readonly onChange?: (value?: string) => void;
  readonly onAdGroupClick?: () => void;
  readonly onToggle?: (value: boolean) => void;
  readonly isLoading?: boolean;
  readonly entityType?: ViewTrendsEntityType;
  readonly dataTestId?: string;
}

export enum CampaignAdGroupAdvertisingStatus {
  Paused = 'paused',
  Enabled = 'enabled',
  Partial = 'partial',
  NotEligible = 'NotEligible',
}

export const CampaignsSelect: React.FC<CampaignsSelectProps> = (props) => {
  const {
    options,
    value,
    onChange,
    onAdGroupClick,
    isLoading,
    onToggle,
    dataTestId,
  } = props;
  const [search, setSearch] = useState<string>('');

  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    onToggle && onToggle(isOpen);
  }, [isOpen]);

  const selectedOption: CampaignsAdGroupsSelectOptionProps | undefined =
    useMemo(() => {
      return options?.find(
        ({ campaignId, adGroupId }) =>
          adGroupId === value || campaignId === value
      );
    }, [value, options]);

  const filteredOptions = useMemo(
    () =>
      options
        ?.filter((option) => {
          if (isNil(option.adGroupId)) {
            return option.campaignId !== value;
          }

          return option.adGroupId !== value;
        })
        .filter(({ adGroupName, campaignName }) => {
          if (search === '') return true;
          if (isNil(adGroupName)) {
            return campaignName?.toLowerCase().includes(search.toLowerCase());
          }
          return adGroupName?.toLowerCase().includes(search.toLowerCase());
        }),
    [value, search, options]
  );

  return (
    <div
      className={classNames('relative w-full', {
        'rounded-lg': !isOpen,
        'rounded-t-lg': isOpen,
      })}
    >
      <div
        className={classNames(
          'flex w-full pl-16 px-12 py-8 gap-16 cursor-pointer border border-solid border-grey-200',
          {
            'bg-white hover:bg-grey-50 rounded-lg': !isOpen,
            'bg-grey-50 rounded-t-lg border-b-0': isOpen,
          }
        )}
      >
        <div
          className={classNames('flex min-h-full items-center cursor-pointer')}
          onClick={() => setOpen((prevValue) => !prevValue)}
          data-test-id={`${dataTestId}_campaign_select`}
        >
          <Icon
            svg={isOpen ? ChevronUpIcon : ChevronDownIcon}
            size={IconSize.Medium}
          />
        </div>
        {isLoading ? (
          <div className="flex w-full">
            {props.entityType === ViewTrendsEntityType.ADGROUP ? (
              <AdGroupLoadingSkeleten />
            ) : (
              <CampaignsLoadingSkeleton />
            )}
          </div>
        ) : (
          <div className="flex w-full justify-between gap-40">
            {selectedOption?.adLevel === AdLevel.Campaigns ? (
              <CampaignsDisplayLabel
                {...selectedOption}
                onClick={() => setOpen((prevValue) => !prevValue)}
              />
            ) : (
              <AdGroupDisplayLabel
                {...selectedOption}
                onAdGroupClick={onAdGroupClick}
                onClick={() => setOpen((prevValue) => !prevValue)}
              />
            )}
          </div>
        )}
      </div>
      {isOpen ? (
        <div className="absolute left-0 right-0 text-sm box-border border border-solid border-grey-200 bg-white z-20 cursor-pointer min-w-max">
          {options && options.length > 10 && (
            <div className="px-12 py-12 border-solid border-b border-grey-200">
              <SearchInput
                onSearchInputClear={() => setSearch('')}
                value={search}
                onChange={(val: string) => setSearch(val)}
              />
            </div>
          )}
          <div className="overflow-auto max-h-560">
            {filteredOptions?.map((option, index) =>
              option.adLevel === AdLevel.Campaigns ? (
                <CampaignsSelectOption
                  {...option}
                  onChange={(campaignId) => {
                    onChange && onChange(campaignId);
                    setOpen(false);
                  }}
                  selected={option.campaignId === value}
                  isLast={filteredOptions.length - 1 === index}
                />
              ) : (
                <AdGroupSelectOption
                  {...option}
                  onChange={(adGroupId) => {
                    onChange && onChange(adGroupId);
                    setOpen(false);
                  }}
                  isLast={filteredOptions.length - 1 === index}
                />
              )
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

CampaignsSelect.displayName = 'CampaignsSelect';
