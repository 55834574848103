import compact from 'lodash/compact';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';

/* eslint-disable max-lines */
import { IdToken } from '@auth0/auth0-spa-js';

import { EntityType } from '../../modules/account/containers/utils';
import { AccountSalesChannelPaginatedResultWithError } from '../../modules/products/containers/skuCatalog/types';
import { AOApiClient, createAOApiClient } from '../clients/AOApiClient';
import { FAMApiClient, createFAMApiClient } from '../clients/FAMApiClient';
import { SKUApiClient, createSKUApiClient } from '../clients/SKUApiClient';
import { MerchantSyncDate } from '../types/AOSharedTypes';
import { DataConnectionStatus } from '../types/DataConnectionStatus';
import {
  ConnectionStatus,
  DataSyncStatusResponseType,
  FAMConnectionStatus,
  FlywheelSalesChannel,
  IngestionStatus,
  MerchantType,
  SalesChannelData,
} from '../types/Fam';
import { ConnectionStatusDetails } from '../types/FlywheelSetupSharedTypes';

export enum SalesChannelConnectionStatus {
  NONE = 'NONE',
  SOME = 'SOME',
  ALL = 'ALL',
}

export interface SalesChannelConnectionResponse {
  ads: SalesChannelConnectionStatus;
  products: SalesChannelConnectionStatus;
}

export interface DataConnectionStatusResponse {
  ads: DataConnectionStatus;
  products: DataConnectionStatus;
}

export const getAdConnectionStatus = (data: SalesChannelData[]) => {
  const count = data.filter(
    (item) => item.adConnectionStatus === FAMConnectionStatus.Active
  ).length;

  return connectionStatusFromCount(count, data.length);
};

export const getAdConnectionStatusWithCount = (data: SalesChannelData[]) => {
  const count = data.filter(
    (item) => item.adConnectionStatus === FAMConnectionStatus.Active
  ).length;

  return {
    Status: connectionStatusFromCount(count, data.length),
    activeCount: count,
    totalCount: data.length,
  };
};

export const getProductConnectionStatus = (
  data: SalesChannelData[],
  ignore1P: boolean = true
) => {
  // count the number of connected merchant countries, ignoring non-sellers
  // since vendors (1P) are not yet implemented
  const filtered3pData = ignore1P
    ? data.filter((item) =>
        (item.extMerchantType || '').toLowerCase().includes('seller')
      )
    : data;
  const count = filtered3pData.filter(
    (item) =>
      item.productConnectionStatus === FAMConnectionStatus.Active ||
      item.productConnectionStatus === DataConnectionStatus.Connected
  ).length;

  return connectionStatusFromCount(count, filtered3pData.length);
};

export const getProductConnectionStatusWithCount = (
  data: SalesChannelData[]
) => {
  // count the number of connected merchant countries, ignoring non-sellers
  // since vendors (1P) are not yet implemented
  const count = data.filter(
    (item) =>
      item.productConnectionStatus === FAMConnectionStatus.Active ||
      item.productConnectionStatus === DataConnectionStatus.Connected
  ).length;

  return {
    Status: connectionStatusFromCount(count, data.length),
    activeCount: count,
    totalCount: data.length,
  };
};

export const areAllConnectionsVendor = (data: SalesChannelData[]) =>
  data.every((item) =>
    (item.extMerchantType || '').toLowerCase().includes('vendor')
  );

export function connectionStatusFromCount(
  count: number,
  totalMerchantCountries: number
): SalesChannelConnectionStatus {
  if (count === 0) {
    return SalesChannelConnectionStatus.NONE;
  } else if (count === totalMerchantCountries) {
    return SalesChannelConnectionStatus.ALL;
  }

  return SalesChannelConnectionStatus.SOME;
}

export const getConnectionStatus = async (
  token: IdToken,
  accountId: string,
  salesChannelData?: AccountSalesChannelPaginatedResultWithError[]
) => {
  const amazonData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Amazon
  ) ?? { items: [] };

  const walmartData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Walmart
  ) ?? { items: [] };

  const adsConnected = getAdConnectionStatus([
    ...amazonData.items,
    ...walmartData.items,
  ]);

  const productsConnected = getProductConnectionStatus([
    ...amazonData.items,
    ...walmartData.items,
  ]);
  const connection: SalesChannelConnectionResponse = {
    ads: adsConnected,
    products: productsConnected,
  };

  return connection;
};

const getConnectionStatusWalmart = (connectionStatus: string) =>
  connectionStatus === FAMConnectionStatus.Active
    ? ConnectionStatus.Connected
    : ConnectionStatus.Syncing;

export const getAdsAndProductDataConnectionStatusDataForSetup = (
  aoDataAvailabilityInfo: MerchantSyncDate[],
  skuDataAvailabilityInfo: MerchantSyncDate[],
  salesChannelData?: AccountSalesChannelPaginatedResultWithError[]
) => {
  const amazonData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Amazon
  ) ?? { items: [] };
  const walmartData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Walmart
  ) ?? { items: [] };

  const connectionsDetails: ConnectionStatusDetails = {
    [FlywheelSalesChannel.Amazon]: {
      syncing: 0,
      connected: 0,
      total: 0,
      connectedData: [],
      syncingData: [],
    },
    [FlywheelSalesChannel.Walmart]: {
      syncing: 0,
      connected: 0,
      total: 0,
      connectedData: [],
      syncingData: [],
    },
  };

  amazonData.items.forEach((item) => {
    const adsStatus = getDataAvailability(
      item.adConnectionStatus,
      item.id,
      aoDataAvailabilityInfo
    );

    const productStatus = getDataAvailability(
      item.productConnectionStatus,
      item.id,
      skuDataAvailabilityInfo
    );

    if (!adsStatus && !productStatus) {
      return;
    }

    if (
      adsStatus === ConnectionStatus.Connected &&
      productStatus === ConnectionStatus.Connected
    ) {
      connectionsDetails[FlywheelSalesChannel.Amazon].connected += 1;
      connectionsDetails[FlywheelSalesChannel.Amazon].connectedData.push({
        ...item,
        adsDataConnectionStatus: ConnectionStatus.Connected,
        productsDataConnectionStatus: ConnectionStatus.Connected,
      });
    }

    if (
      (adsStatus !== ConnectionStatus.Connected ||
        productStatus !== ConnectionStatus.Connected) &&
      adsStatus !== ConnectionStatus.ConnectedNoData
    ) {
      connectionsDetails[FlywheelSalesChannel.Amazon].syncing += 1;
      connectionsDetails[FlywheelSalesChannel.Amazon].syncingData.push({
        ...item,
        adsDataConnectionStatus: adsStatus as ConnectionStatus,
        productsDataConnectionStatus: productStatus as ConnectionStatus,
      });
    }
  });

  // walmart does not need the data availability api, just use the returned status
  walmartData.items.forEach((item) => {
    if (item.adConnectionStatus === FAMConnectionStatus.Active) {
      connectionsDetails[FlywheelSalesChannel.Walmart].connected += 1;
      connectionsDetails[FlywheelSalesChannel.Walmart].connectedData.push({
        ...item,
        adsDataConnectionStatus: ConnectionStatus.Connected,
        productsDataConnectionStatus: item.productConnectionStatus
          ? ConnectionStatus.Connected
          : ConnectionStatus.NotConnected,
      });
    }

    if (item.adConnectionStatus !== FAMConnectionStatus.Active) {
      connectionsDetails[FlywheelSalesChannel.Walmart].syncing += 1;
      connectionsDetails[FlywheelSalesChannel.Walmart].syncingData.push({
        ...item,
        adsDataConnectionStatus: getConnectionStatusWalmart(
          item.adConnectionStatus
        ),
        productsDataConnectionStatus: item.productConnectionStatus
          ? getConnectionStatusWalmart(item.productConnectionStatus)
          : ConnectionStatus.NotConnected,
      });
    }
  });

  connectionsDetails[FlywheelSalesChannel.Amazon].total =
    connectionsDetails[FlywheelSalesChannel.Amazon].connected +
    connectionsDetails[FlywheelSalesChannel.Amazon].syncing;
  connectionsDetails[FlywheelSalesChannel.Walmart].total =
    connectionsDetails[FlywheelSalesChannel.Walmart].connected +
    connectionsDetails[FlywheelSalesChannel.Walmart].syncing;

  return connectionsDetails;
};

export const getAdsDataConnectionStatusForCompass = (
  aoDataAvailabilityInfo: MerchantSyncDate[],
  salesChannelData?: AccountSalesChannelPaginatedResultWithError[]
) => {
  const amazonData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Amazon
  ) ?? { items: [] };
  const walmartData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Walmart
  ) ?? { items: [] };

  const connectionsDetails: ConnectionStatusDetails = {
    [FlywheelSalesChannel.Amazon]: {
      syncing: 0,
      connected: 0,
      total: 0,
      connectedData: [],
      syncingData: [],
    },
    [FlywheelSalesChannel.Walmart]: {
      syncing: 0,
      connected: 0,
      total: 0,
      connectedData: [],
      syncingData: [],
    },
  };

  amazonData.items.forEach((item) => {
    const adsStatus = getDataAvailability(
      item.adConnectionStatus,
      item.id,
      aoDataAvailabilityInfo
    );

    if (!adsStatus) {
      return;
    }

    if (adsStatus === ConnectionStatus.Connected) {
      connectionsDetails[FlywheelSalesChannel.Amazon].connected += 1;
      connectionsDetails[FlywheelSalesChannel.Amazon].connectedData.push({
        ...item,
        adsDataConnectionStatus: ConnectionStatus.Connected,
        productsDataConnectionStatus: ConnectionStatus.Connected,
      });
    }

    if (
      adsStatus !== ConnectionStatus.Connected &&
      adsStatus !== ConnectionStatus.ConnectedNoData
    ) {
      connectionsDetails[FlywheelSalesChannel.Amazon].syncing += 1;
      connectionsDetails[FlywheelSalesChannel.Amazon].syncingData.push({
        ...item,
        adsDataConnectionStatus: adsStatus as ConnectionStatus,
        productsDataConnectionStatus: ConnectionStatus.NotConnected,
      });
    }
  });

  // walmart does not need the data availability api, just use the returned status
  walmartData.items.forEach((item) => {
    if (item.adConnectionStatus === FAMConnectionStatus.Active) {
      connectionsDetails[FlywheelSalesChannel.Walmart].connected += 1;
      connectionsDetails[FlywheelSalesChannel.Walmart].connectedData.push({
        ...item,
        adsDataConnectionStatus: ConnectionStatus.Connected,
        productsDataConnectionStatus: item.productConnectionStatus
          ? ConnectionStatus.Connected
          : ConnectionStatus.NotConnected,
      });
    }

    if (item.adConnectionStatus !== FAMConnectionStatus.Active) {
      connectionsDetails[FlywheelSalesChannel.Walmart].syncing += 1;
      connectionsDetails[FlywheelSalesChannel.Walmart].syncingData.push({
        ...item,
        adsDataConnectionStatus: getConnectionStatusWalmart(
          item.adConnectionStatus
        ),
        productsDataConnectionStatus: item.productConnectionStatus
          ? getConnectionStatusWalmart(item.productConnectionStatus)
          : ConnectionStatus.NotConnected,
      });
    }
  });

  connectionsDetails[FlywheelSalesChannel.Amazon].total =
    connectionsDetails[FlywheelSalesChannel.Amazon].connected +
    connectionsDetails[FlywheelSalesChannel.Amazon].syncing;
  connectionsDetails[FlywheelSalesChannel.Walmart].total =
    connectionsDetails[FlywheelSalesChannel.Walmart].connected +
    connectionsDetails[FlywheelSalesChannel.Walmart].syncing;

  return connectionsDetails;
};

export const getDataAvailability = (
  connectionStatus: string,
  merchantCountryId: string,
  availabilityInfo: MerchantSyncDate[]
): string => {
  const availability = availabilityInfo.find(
    (info) => info.merchantCountryId === merchantCountryId
  );

  if (availability?.ingestionStatus === IngestionStatus.NoData) {
    return ConnectionStatus.ConnectedNoData;
  }

  if (connectionStatus === FAMConnectionStatus.Active) {
    if (
      availability?.earliestAvailableDate ||
      availability?.latestAvailableDate ||
      availability?.lastSyncedAt
    ) {
      return ConnectionStatus.Connected;
    }
  }

  return connectionStatus;
};

export const hasAtLeastOneConnectedMerchant = (
  aoDataAvailabilityInfo: MerchantSyncDate[],
  skuDataAvailabilityInfo: MerchantSyncDate[],
  salesChannelData?: AccountSalesChannelPaginatedResultWithError[]
) => {
  const amazonData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Amazon
  ) ?? { items: [] };
  const walmartData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Walmart
  ) ?? { items: [] };

  const amazonSalesChannelData = amazonData.items
    .filter((item) => !!item.id)
    .map((item) => ({
      ...item,
      isAdConnected:
        getDataAvailability(
          item.adConnectionStatus,
          item.id,
          aoDataAvailabilityInfo
        ) === ConnectionStatus.Connected,
      isProductConnected:
        getDataAvailability(
          item.productConnectionStatus,
          item.id,
          skuDataAvailabilityInfo
        ) === ConnectionStatus.Connected,
    }));

  const walmartSalesChannelData = walmartData.items
    .filter((item) => !!item.id)
    .map((item) => ({
      ...item,
      isAdConnected:
        item.adConnectionStatus === ConnectionStatus.Connected ||
        item.adConnectionStatus === ConnectionStatus.Validating ||
        item.adConnectionStatus === FAMConnectionStatus.Active,
      isProductConnected:
        item.productConnectionStatus === ConnectionStatus.Connected ||
        item.productConnectionStatus === ConnectionStatus.Validating ||
        item.productConnectionStatus === FAMConnectionStatus.Active,
    }));

  const isAmazonHasConnection = amazonSalesChannelData.some(
    (item) => item.isAdConnected
  );
  const isWalmartHasConnection = walmartSalesChannelData.some(
    (item) => item.isAdConnected && item.isProductConnected
  );

  return isAmazonHasConnection || isWalmartHasConnection;
};

export const getAllDataConnectionStatusesGroupedByType = async (
  token: IdToken,
  accountId: string,
  salesChannelData?: AccountSalesChannelPaginatedResultWithError[] | undefined
) => {
  const {
    amazonAdsConnected,
    amazonProductConnected,
    walmartAdsConnected,
    walmartProductConnected,
  } = await getMerchantCountryDataConnectionBySalesChannel(
    token,
    accountId,
    salesChannelData
  );

  const merchantAdsStatus = getCountForStatus(
    amazonAdsConnected,
    walmartAdsConnected
  );
  const merchantProductsStatus = getCountForStatus(
    amazonProductConnected,
    walmartProductConnected
  );
  return { merchantAdsStatus, merchantProductsStatus };
};

const getCountForStatus = (amazonData: string[], walmartData: string[]) => {
  const connected =
    amazonData.filter((item) => item === DataConnectionStatus.Connected)
      .length +
    walmartData.filter((item) => item === FAMConnectionStatus.Active).length;

  const syncing =
    amazonData.filter((item) => item === FAMConnectionStatus.Active).length +
    walmartData.filter(
      (item) =>
        item !== FAMConnectionStatus.Active &&
        item !== FAMConnectionStatus.Failed &&
        item !== FAMConnectionStatus.Inactive &&
        item !== null
    ).length;

  const failed = [...amazonData, ...walmartData].filter(
    (item) => item === FAMConnectionStatus.Failed
  ).length;

  const total = [...amazonData, ...walmartData].length;

  return {
    syncing,
    connected,
    failed,
    total,
  };
};

export const getDataAvailabilityInfo = async (
  aoClient: AOApiClient,
  skuApiClient: SKUApiClient,
  accountId: string,
  items: SalesChannelData[]
) => {
  const merchantCountryIdsWithAo = items
    .filter((item) => item.aoEnabled)
    .map((item) => item.id);

  if (merchantCountryIdsWithAo.length > 0) {
    const aoAvailability = aoClient.getDataSyncInfo(
      accountId,
      merchantCountryIdsWithAo
    );

    const skuAvailability = skuApiClient.getDataAvailabilityInfo(
      accountId,
      merchantCountryIdsWithAo
    );

    const [aoDataSyncInfo, skuDataAvailibilityInfo] = await Promise.all([
      aoAvailability,
      skuAvailability,
    ]);

    return { aoDataSyncInfo, skuDataAvailibilityInfo };
  }
};

export const addIngestionStatusToDataAvailabilityInfo = (
  dataSyncStatus: DataSyncStatusResponseType,
  dataAvailabilityInfo: MerchantSyncDate[],
  entityType: EntityType
) => {
  const merchantCountryIds = uniq(
    keys(dataSyncStatus).concat(
      dataAvailabilityInfo.map((item) => item.merchantCountryId)
    )
  );

  return compact(
    merchantCountryIds.map((merchantCountryId) => {
      const item = dataAvailabilityInfo.find(
        (elem) => elem.merchantCountryId === merchantCountryId
      );
      const ingestionStatus =
        dataSyncStatus[merchantCountryId]?.ingestions?.[entityType]
          ?.ingestionStatus;

      const hasData = Boolean(item || ingestionStatus);

      return hasData ? { ...item, merchantCountryId, ingestionStatus } : null;
    })
  );
};

export const getMerchantCountryDataAvailabilityInfo = async (
  famClient: FAMApiClient,
  aoClient: AOApiClient,
  skuApiClient: SKUApiClient,
  accountId: string,
  items: SalesChannelData[]
) => {
  const merchantCountryIdsWithAo = items
    .filter((item) => item.aoEnabled)
    .map((item) => item.id);

  let aoDataAvailabilityInfo: MerchantSyncDate[] = [];
  let skuDataAvailabilityInfo: MerchantSyncDate[] = [];

  if (merchantCountryIdsWithAo.length > 0) {
    try {
      const aoAvailabilityRes = await aoClient.getDataSyncInfo(
        accountId,
        merchantCountryIdsWithAo
      );

      aoDataAvailabilityInfo = aoAvailabilityRes.syncPerMerchantIds || [];
    } catch (e) {
      console.error(e);
    }

    try {
      const skuAvailabilityRes = await skuApiClient.getDataAvailabilityInfo(
        accountId,
        merchantCountryIdsWithAo
      );
      skuDataAvailabilityInfo = skuAvailabilityRes.syncPerMerchantIds || [];
    } catch (e) {
      console.error(e);
    }

    try {
      const dataSyncStatus = await famClient.getDataSyncStatus(accountId);

      aoDataAvailabilityInfo = addIngestionStatusToDataAvailabilityInfo(
        dataSyncStatus,
        aoDataAvailabilityInfo,
        EntityType.Ads
      );
      skuDataAvailabilityInfo = addIngestionStatusToDataAvailabilityInfo(
        dataSyncStatus,
        skuDataAvailabilityInfo,
        EntityType.Products
      );
    } catch (e) {
      console.error(e);
    }
  }

  return { aoDataAvailabilityInfo, skuDataAvailabilityInfo };
};

export const getMerchantCountryDataConnectionBySalesChannel = async (
  token: IdToken,
  accountId: string,
  salesChannelData?: AccountSalesChannelPaginatedResultWithError[]
) => {
  const famClient = createFAMApiClient(token);
  const aoClient = createAOApiClient(token);
  const skuApiClient = createSKUApiClient(token);

  const amazonData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Amazon
  ) ?? { items: [], totalItems: 0 };
  const walmartData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Walmart
  ) ?? { items: [], totalItems: 0 };

  const { aoDataAvailabilityInfo, skuDataAvailabilityInfo } =
    await getMerchantCountryDataAvailabilityInfo(
      famClient,
      aoClient,
      skuApiClient,
      accountId,
      amazonData.items
    );

  // walmart does not need the data availability api, just use the returned status
  const walmartAdsConnected: string[] = walmartData.items.map(
    (item) => item.adConnectionStatus
  );
  const walmartProductConnected: string[] = compact(
    walmartData.items.map((item) => item.productConnectionStatus)
  );

  const amazonAdsConnected: string[] = amazonData.items.map((item) => {
    return getDataAvailability(
      item.adConnectionStatus,
      item.id,
      aoDataAvailabilityInfo
    );
  });
  const amazonProductConnected: string[] = compact(
    amazonData.items.map((item) => {
      return getDataAvailability(
        item.productConnectionStatus,
        item.id,
        skuDataAvailabilityInfo
      );
    })
  );
  return {
    amazonAdsConnected,
    amazonProductConnected,
    walmartAdsConnected,
    walmartProductConnected,
  };
};

export const getConnectionCount = (
  dataStatus: string[],
  options?: {
    isAmazon: boolean;
  }
) => {
  return dataStatus
    .map((elem) =>
      options?.isAmazon
        ? elem === ConnectionStatus.Connected
        : elem === FAMConnectionStatus.Active
    )
    .filter((elem) => !!elem).length;
};

export const getDataConnectionCount = async (
  token: IdToken,
  accountId: string,
  salesChannelData?: AccountSalesChannelPaginatedResultWithError[] | undefined
) => {
  const {
    amazonAdsConnected,
    amazonProductConnected,
    walmartAdsConnected,
    walmartProductConnected,
  } = await getMerchantCountryDataConnectionBySalesChannel(
    token,
    accountId,
    salesChannelData
  );

  return {
    [FlywheelSalesChannel.Amazon]: {
      [EntityType.Ads]: getConnectionCount(amazonAdsConnected, {
        isAmazon: true,
      }),
      [EntityType.Products]: getConnectionCount(amazonProductConnected, {
        isAmazon: true,
      }),
    },
    [FlywheelSalesChannel.Walmart]: {
      [EntityType.Ads]: getConnectionCount(walmartAdsConnected),
      [EntityType.Products]: getConnectionCount(walmartProductConnected),
    },
  };
};

export const getDataConnectionStatus = async (
  token: IdToken,
  accountId: string,
  salesChannelData?: AccountSalesChannelPaginatedResultWithError[]
) => {
  const {
    amazonAdsConnected,
    amazonProductConnected,
    walmartAdsConnected,
    walmartProductConnected,
  } = await getMerchantCountryDataConnectionBySalesChannel(
    token,
    accountId,
    salesChannelData
  );

  // The reason mapDataConnectedStatus being used multiple times is that each sales channel needs to be map
  // separately in order to get the correct data connection state for ads and product
  // before the combined status can be determined

  const walmartAdsMapStatus = mapDataConnectedStatus(walmartAdsConnected, {
    isAmazon: false,
  });

  const amazonAdsMapState = mapDataConnectedStatus(amazonAdsConnected, {
    isAmazon: true,
  });
  const walmartProductMapStatus = mapProductDataConnectedStatus(
    walmartProductConnected
  );

  const amazonProductMapState = mapProductDataConnectedStatus(
    amazonProductConnected
  );

  const adsConnectedStatus = mapDataConnectedStatus(
    [walmartAdsMapStatus, amazonAdsMapState],
    {
      isAmazon: true,
    }
  );

  const productsConnectedStatus = mapProductDataConnectedStatus([
    walmartProductMapStatus,
    amazonProductMapState,
  ]);

  const connectedStatus: DataConnectionStatusResponse = {
    ads: adsConnectedStatus,
    products: productsConnectedStatus,
  };

  return connectedStatus;
};

/**
 * Maps the FAM data connection statuses to statuses shown in the UI
 * @param dataStatus is an array of merchant country data connection statuses
 * @param isAmazon true if the merchant country sales channel is Amazon
 * @returns a DataConnectionStatus used to build the data connection status UI
 */
export const mapDataConnectedStatus = (
  dataStatus: string[],
  options: {
    isAmazon: boolean;
  }
) => {
  const { isAmazon } = options;

  if (!dataStatus.length) {
    return DataConnectionStatus.Initial;
  }

  const isInitial = dataStatus.every(
    (item) => item === DataConnectionStatus.Initial
  );

  if (isInitial) {
    return DataConnectionStatus.Initial;
  }

  if (
    (dataStatus.includes(ConnectionStatus.Connected) && isAmazon) ||
    dataStatus.includes(FAMConnectionStatus.Active)
  ) {
    return DataConnectionStatus.Connected;
  }

  const adsFailed = dataStatus.every((item) => {
    return (
      item === FAMConnectionStatus.Failed ||
      !item ||
      item === FAMConnectionStatus.Inactive
    );
  });

  if (adsFailed) {
    return DataConnectionStatus.Failed;
  }

  return DataConnectionStatus.Syncing;
};

const isConnectionStatusConnected = (dataStatus: string[]) => {
  return (
    dataStatus.includes(ConnectionStatus.Connected) ||
    dataStatus.includes(FAMConnectionStatus.Active)
  );
};

export const mapProductDataConnectedStatus = (dataStatus: string[]) => {
  if (!dataStatus.length) {
    return DataConnectionStatus.Initial;
  }

  const isInitial = dataStatus.every(
    (item) => item === DataConnectionStatus.Initial
  );

  if (isInitial) {
    return DataConnectionStatus.Initial;
  }

  const isConnected = isConnectionStatusConnected(dataStatus);

  if (isConnected) {
    return DataConnectionStatus.Connected;
  }

  const adsFailed = dataStatus.every((item) => {
    return item === FAMConnectionStatus.Failed || !item;
  });

  if (adsFailed) {
    return DataConnectionStatus.Failed;
  }

  const productSyncing = dataStatus.every((item) => {
    return (
      item !== FAMConnectionStatus.Failed &&
      item !== FAMConnectionStatus.Active &&
      item !== DataConnectionStatus.Connected
    );
  });

  if (productSyncing) {
    return DataConnectionStatus.Syncing;
  }
  return DataConnectionStatus.Initial;
};

export const areAllMerchants1PAndAmazon = (
  salesChannelData: AccountSalesChannelPaginatedResultWithError[]
) =>
  !salesChannelData
    ?.flatMap((channel) =>
      channel.items.map((item) => ({
        ...item,
        salesChannel: channel.salesChannel,
      }))
    )
    .some(
      (data) =>
        data.extMerchantType === MerchantType.Seller ||
        (data.extMerchantType === MerchantType.Vendor &&
          data.salesChannel === FlywheelSalesChannel.Walmart)
    );

export const is1PMerchantsPresent = (
  salesChannelData: AccountSalesChannelPaginatedResultWithError[]
) => {
  return salesChannelData
    ?.flatMap((channel) =>
      channel.items.map((item) => ({
        ...item,
        salesChannel: channel.salesChannel,
      }))
    )
    .some(
      ({ extMerchantType, salesChannel }) =>
        extMerchantType === MerchantType.Vendor &&
        salesChannel !== FlywheelSalesChannel.Walmart
    );
};

export const is3PMerchantsPresent = (
  salesChannelData: AccountSalesChannelPaginatedResultWithError[]
) => {
  return salesChannelData
    ?.flatMap((channel) =>
      channel.items.map((item) => ({
        ...item,
        salesChannel: channel.salesChannel,
      }))
    )
    .some(
      ({ extMerchantType, salesChannel }) =>
        extMerchantType === MerchantType.Seller ||
        salesChannel === FlywheelSalesChannel.Walmart
    );
};
