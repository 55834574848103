import capitalize from 'lodash/capitalize';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { PROFILES_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const campaignTargetingTypeColumn: FlywheelTableColumn<ProfileApiDataDetails> =
  {
    columnName: PROFILES_API_COLUMN_NAME.CampaignTargetingType,
    i18nKeyOrLabel:
      I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE,
    isSortable: true,
    RowCellElement: makeTextColumn(
      ({ profileDetails: { campaignTargetingType } }) =>
        campaignTargetingType ? capitalize(campaignTargetingType) : undefined
    ),
    gridColumnWidth: '140px',
  };

export const campaignTargetingTypeFilterForProfiles =
  createMultiSelectDataFieldFilter(
    PROFILES_API_COLUMN_NAME.CampaignTargetingType,
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE,
    [
      SelectFilterOption.CampaignTargetingTypeAuto,
      SelectFilterOption.CampaignTargetingTypeManual,
    ]
  );
