export const SMART_CAMPAIGN_LEARN_MORE_INTERCOM_LINK =
  'https://intercom.help/flywheel-20/en/articles/9547059-what-are-smart-campaign-goals';

export const SMART_CAMPAIGN_LEARN_MORE_MIN_MAX_BID_INTERCOM_LINK =
  'https://intercom.help/flywheel-20/en/articles/9724749-smart-campaigns-min-and-max-bid-automation';

export const SMART_CAMPAIGN_PREVIEW_LEARN_MORE_INTERCOM_LINK =
  'https://intercom.help/flywheel-20/en/collections/9797890-smart-campaigns';

export const SMART_CAMPAIGN_PREVIEW_LEARN_MORE_KEYWORD_RECOMMENDATION =
  'https://intercom.help/flywheel-20/en/articles/5918329-campaign-creator-keyword-recommendations';
