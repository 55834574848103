import {
  KeywordActionColumns,
  KeywordRecommendationsData,
} from '../../../../../lib/types/KeywordAction';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import {
  ColumnAlign,
  TableCellV2,
  TableCellV2Type,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../../../lib/types/I18nKey';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';

export const Cell: React.FC<KeywordRecommendationsData> = ({
  performance: { acosTotal },
}) => {
  const intl = useIntl();
  const currentValue = isNil(acosTotal)
    ? null
    : intl.formatNumber(acosTotal, {
        style: 'percent',
        minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      });
  return (
    <TableCellV2
      tableCellData={{
        tableCellType: TableCellV2Type.Number,
        currentValue,
      }}
      className="h-full flex justify-center"
    />
  );
};
Cell.displayName = 'KWACellACOS';

export const acos: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  {},
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_ACOS_HEADER,
  accessorKey: KeywordActionColumns.ACOS,
  sortable: true,
  size: 125,
  minSize: 125,
  maxSize: 125,
  align: ColumnAlign.Right,
  cell: ({ row: { original } }) => <Cell {...original} />,
};

export const acosFilter = createPercentDataFieldFilter(
  KeywordActionColumns.ACOS,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_ACOS_HEADER,
  isValidNumber(0)
);
