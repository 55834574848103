import { IntlShape } from 'react-intl';

import { TableProperties } from '@teikametrics/tm-design-system';

import {
  BUSINESS_PERFORMANCE_TABLE_ID,
  CampaignRowData,
  PerformanceTableExtraProps,
} from '../../utils/performance';
import { adSales } from './adSales';
import { adSpend } from './adSpend';
import { campaignDetails } from './campaignDetails';
import { rank } from './rank';
import { tacos } from './tacos';

export const getCampaignColumns = (intl: IntlShape) => {
  const columns = [
    {
      id: BUSINESS_PERFORMANCE_TABLE_ID,
      columns: [rank, campaignDetails, adSales, adSpend, tacos].map(
        (column) => ({
          ...column,
          header: intl.formatMessage({ id: column.header }),
        })
      ),
    },
  ] as TableProperties<CampaignRowData, PerformanceTableExtraProps>['columns'];
  return columns;
};
