import {
  Alignment,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CogLinearIcon,
  Icon,
  IconSize,
  Placement,
  StatusIcon,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
  SecondarySyncStatus,
  StatusData,
} from '../containers/salesChannels/types';
import { ViewerRoleTooltip } from '../../../lib/utilities/viewerRoleTooltipWrapper';

export interface ConnectionStatusProps {
  readonly shouldShowConnectLink: boolean;
  readonly textProp: StatusData;
  readonly onLinkClick: () => void;
  readonly status?: SecondarySyncStatus;
  readonly isUserRoleViewOnly?: boolean;
}
export const ConnectionStatus: React.FC<ConnectionStatusProps> = ({
  shouldShowConnectLink,
  status,
  textProp,
  onLinkClick,
  isUserRoleViewOnly,
}) => {
  return (
    <div className="flex flex-col justify-center h-full">
      <div className="flex flex-row items-center h-28">
        <Icon
          size={IconSize.Medium}
          svg={StatusIcon}
          className={classNames('pr-4', textProp.statusIconColor)}
        />
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.tight}
          className="text-grey-900"
          weight={TypographyWeight.regular}
        >
          <FormattedMessage id={textProp.text} />
        </Typography>
        {shouldShowConnectLink && (
          <ViewerRoleTooltip
            position={{
              placement: Placement.Left,
              alignment: Alignment.Center,
            }}
            disabled={!isUserRoleViewOnly}
          >
            <Button
              className="text-grey-500"
              size={ButtonSize.Small}
              variant={ButtonVariant.ActionWithoutBorder}
              svgIcon={CogLinearIcon}
              onClick={onLinkClick}
              dataTestId={`settings_sales-channel`}
              state={
                isUserRoleViewOnly ? ButtonState.Disabled : ButtonState.Enabled
              }
            />
          </ViewerRoleTooltip>
        )}
      </div>
      {textProp.showSecondaryDescription && status && (
        <div className="flex flex-row items-center gap-8">
          {status.icon && (
            <Icon
              size={IconSize.Small}
              svg={status.icon}
              className="text-grey-500 flex-shrink-0"
            />
          )}
          {status.label && (
            <Typography
              size={TypographySize.sm}
              lineHeight={TypographyLineHeight.tight}
              className="text-grey-500 flex-nowrap"
              weight={TypographyWeight.regular}
            >
              <FormattedMessage id={status.label} />
            </Typography>
          )}
          {status.value && (
            <Typography
              size={TypographySize.sm}
              lineHeight={TypographyLineHeight.tight}
              className="text-grey-700 flex-nowrap"
              weight={TypographyWeight.regular}
            >
              {status.value}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

ConnectionStatus.displayName = 'ConnectionStatus';
