import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { CAMPAIGNS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) =>
  makeCurrencyColumn<CampaignDetails>(
    ({
      campaignPerformance: { adSalesDirect },
      channelSettings: { startDate },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        adSalesDirect?.amount ? Number(adSalesDirect.amount) : undefined
      );

      return isNil(performanceMetricValue)
        ? undefined
        : {
            amount: performanceMetricValue,
            currency:
              getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
              adSalesDirect?.currency ||
              CURRENCY_CODE,
          };
    }
  )(props);

export const adSalesDirectColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.DirectAdSales,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SALES_DIRECT,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};
RowCellElement.displayName = 'RowCellElement';

export const adSalesDirectFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.DirectAdSales,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SALES_DIRECT,
    currency,
    isValidNumber(0)
  );
