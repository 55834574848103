import React from 'react';
import { useIntl } from 'react-intl';

import {
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { AuthorizeWalmartModalOldProps } from './';

export const ModalFooter: React.FC<
  Pick<AuthorizeWalmartModalOldProps, 'goBack'>
> = ({ goBack }) => {
  const intl = useIntl();

  return (
    <div className="flex justify-center mx-8">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.BlackAndWhiteBorder}
        label={intl.formatMessage({ id: I18nKey.BACK })}
        svgIcon={ArrowLeftIcon}
        onClick={goBack}
        dataTestId={`flash1_walmart_ads_auth_form_back`}
      />
    </div>
  );
};
