import React from 'react';
import { useIntl } from 'react-intl';

import { ColumnAlign } from '@teikametrics/tm-design-system';

import { FlywheelTableV2ColumnProps } from '../../../../../../containers/tableV2/types';
import { HomepageResponseMetricKeys } from '../../../../../../lib/types/CompassSharedTypes';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { makeNumberWithProgressBarColumn } from '../../../../../products/containers/utils/makeTableCellsUtils';
import { BusinessPerformanceColumnName } from '../../utils/constants';
import {
  CampaignCellData,
  CampaignRowData,
  PerformanceTableExtraProps,
  getNumberWithProgressBarColumnProps,
} from '../../utils/performance';

export const RowCellElement: React.FC<CampaignCellData> = (data) => {
  const intl = useIntl();
  return makeNumberWithProgressBarColumn<CampaignCellData>(
    ({ campaignPerformance, adSalesTotal, activeColumn }) => {
      const currentValue = +(
        campaignPerformance?.[HomepageResponseMetricKeys.AdSalesTotal]
          ?.amount || 0
      );
      const adSalesAmount = +(adSalesTotal?.amount || 0);

      return getNumberWithProgressBarColumnProps(
        intl,
        currentValue,
        adSalesAmount,
        activeColumn === BusinessPerformanceColumnName.AdSalesTotal,
        campaignPerformance?.[HomepageResponseMetricKeys.AdSalesTotal]?.currency
      );
    }
  )(data);
};

export const adSales: FlywheelTableV2ColumnProps<
  CampaignRowData,
  {},
  PerformanceTableExtraProps
> = {
  header: I18nKey.ADVERTISING_OPTIMIZATION_CAMPAIGNS_AD_SALES,
  sortable: false,
  size: 135,
  minSize: 135,
  accessorKey: BusinessPerformanceColumnName.AdSalesTotal,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <RowCellElement {...original} {...meta} />,
};
