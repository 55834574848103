import { DateTime } from 'luxon';

import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  REQUEST_DATE_FORMAT,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { TARGETS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) =>
  makeNumericColumn<TargetsDetails>(
    ({ targetDetails: { startDate }, targetPerformance: { orders } }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        orders
      );

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'OrdersRowCellElement';

export const ordersColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.Orders,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ORDERS,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const ordersFilter = createNumericDataFieldFilter(
  TARGETS_API_COLUMN_NAME.Orders,
  I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ORDERS,
  isValidNumber(0)
);
