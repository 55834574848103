import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputState,
  Placement,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeNumericInputColumn,
  makePercentColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignDetails,
  PlatformBidMultiplier,
} from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
} from '../utils';
import { isDirty } from './totalBudget';

export const MIN_DESKTOP_BID = 0;

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, ...campaignDetails }) => {
  const intl = useIntl();

  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const existingValue = getExistingValue(
    campaignDetails.channelSettings.platformBidMultiplier?.desktopBidMultiplier
  );

  let desktopBidMultiplier = changedValue ?? existingValue;

  const [value, setValue] = useState<string>(desktopBidMultiplier);
  const [isInputEdited, setIsInputEdited] = useState<boolean>(false);

  useEffect(() => {
    if (isValueChanged(isEditMode, changedValue, value)) {
      setValue(desktopBidMultiplier);
    }
    return () => {
      if (!isEditMode) {
        setIsInputEdited(false);
      }
    };
  }, [isEditMode, changedValue, currentPage]);

  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);

  const updateCellValue = (newValue: string) =>
    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier,
        rowId: campaignDetails.campaignId,
        existingValue,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        value: newValue,
        numericValue: true,
      })
    );

  const debounceCellValueUpdate = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignDetails.campaignId]
  );

  if (isEditMode) {
    const onInputFocus = () => setFocussed(true);
    const onInputBlur = () => {
      if (!isEmpty(value)) {
        debounceCellValueUpdate.cancel();
        const formattedValue = Number.parseFloat(value).toFixed(
          PERCENTAGE_FRACTION_DIGITS
        );
        setValue(formattedValue);
        updateCellValue(formattedValue);
      }
      setFocussed(false);
    };

    return makeNumericInputColumn<CampaignDetails>(() => {
      let state = NumericInputState.Default;
      const isInputInvalid: boolean = getIsInputInvalid(
        isInputEdited,
        value,
        existingValue
      );

      if (isInputInvalid) {
        state = NumericInputState.Error;
      }

      return {
        dataTestId: CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier,
        value,
        state,
        appendedElement: '%',
        onChange: (inputValue: string) => {
          setValue(inputValue);
          debounceCellValueUpdate(inputValue);
          setIsInputEdited(true);
        },
        tooltipContent: tooltipContent(intl, isInputInvalid),
        controlledTooltip: getTooltipState(state, focussed),
        tooltipPlacement: Placement.Bottom,
        style: ContentStyle.Bold,
        onInputFocus,
        onInputBlur,
        acceptOnlyPositiveNumbers: true,
        isDirty: isDirty(value, existingValue),
        placeholder: MIN_DESKTOP_BID.toFixed(PERCENTAGE_FRACTION_DIGITS),
        minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      };
    })(campaignDetails);
  }

  return makePercentColumn<CampaignDetails>(
    ({ channelSettings: { platformBidMultiplier } }) =>
      getColumnValue(platformBidMultiplier),
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const desktopBidMultiplierColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_DESKTOP,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const desktopBidMultiplierFilter = createPercentDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_DESKTOP,
  isValidNumber()
);

export const tooltipContent = (intl: IntlShape, invalidInput: boolean) => {
  if (invalidInput) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          { id: I18nKey.ADS_MANAGER_INVALID_NUMERIC_ATLEAST_PERCENT },
          {
            minValue: intl.formatNumber(MIN_DESKTOP_BID, {
              minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
              maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
            }),
          }
        )}
      </p>
    );
  }
};

export const getTooltipState = (
  state: NumericInputState,
  focussed?: boolean
) => {
  return focussed && state === NumericInputState.Error
    ? ControlledTooltip.Show
    : ControlledTooltip.Hide;
};

export const getColumnValue = (
  platformBidMultiplier?: PlatformBidMultiplier
) => {
  return platformBidMultiplier &&
    !isNil(platformBidMultiplier.desktopBidMultiplier)
    ? platformBidMultiplier.desktopBidMultiplier.toString()
    : '0';
};

export const getExistingValue = (desktopBidMultiplier?: number) =>
  (desktopBidMultiplier?.toString() &&
    (desktopBidMultiplier * 100).toFixed(PERCENTAGE_FRACTION_DIGITS)) ||
  '';

export const getIsInputInvalid = (
  isInputEdited: boolean,
  value: string,
  existingValue: string
) =>
  (Number(value) < MIN_DESKTOP_BID || isNaN(Number(value)) || isEmpty(value)) &&
  (isInputEdited || isDirty(value, existingValue));

export const isValueChanged = (
  isEditMode: boolean,
  changedValue: string,
  value: string
) =>
  isEditMode || //Changing between modes
  (!isNil(changedValue) && changedValue !== value); // Changes done due to bulk update. changedValue is latest, but state variable is not
