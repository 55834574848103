import { useContext } from 'react';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { AdGroupDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  MerchantContext,
  MerchantContextState,
} from '../../../merchantsProvider';
import { ADGROUPS_API_COLUMN_NAME, getMerchantName } from '../utils';

export const RowCellElement: React.FC<AdGroupDetails> = ({
  adGroupDetails,
  adGroupPerformance,
  flywheelSettings,
  adGroupId,
  channelSettings,
}) => {
  const adGroupData: AdGroupDetails = {
    adGroupDetails,
    adGroupPerformance,
    flywheelSettings,
    adGroupId,
    channelSettings,
  };

  const merchantContext = useContext<MerchantContextState>(MerchantContext);

  const merchantDetails = merchantContext.merchantDetails;

  return makeTextColumn((data: AdGroupDetails) =>
    getMerchantName(merchantDetails, data.adGroupDetails.merchantCountryId)
  )(adGroupData);
};
RowCellElement.displayName = 'RowCellElement';

export const merchantNameColumn: FlywheelTableColumn<AdGroupDetails> = {
  columnName: ADGROUPS_API_COLUMN_NAME.MerchantName,
  isSortable: false,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MERCHANT_NAME,
  RowCellElement,
  gridColumnWidth: '240px',
};
