import {
  Button,
  ButtonSize,
  ButtonVariant,
  CheckmarkIcon,
  GraphTrendUpIcon,
  Icon,
  IconSize,
  LightningIcon,
  PortaledTooltip,
  PortaledTooltipAlignment,
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
  ResetIcon,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import I18nKey from '../../../../lib/types/I18nKey';

interface AiStatsBannerProps {
  readonly loadingBidsRequest: boolean;
  readonly failedBidsRequest: Error | null;
  readonly openSlideOver: (e: React.MouseEvent<HTMLButtonElement>) => void;
  readonly onErrorReload: () => void;
  readonly totalBids?: number;
}
export const AiStatsBanner: React.FC<AiStatsBannerProps> = ({
  loadingBidsRequest,
  failedBidsRequest,
  totalBids,
  openSlideOver,
  onErrorReload,
}) => {
  const intl = useIntl();
  const areValidBids = !failedBidsRequest && !isUndefined(totalBids);

  const [loaded, setLoaded] = useState<boolean>(false);

  const stillLoading = loaded || loadingBidsRequest;

  useEffect(() => {
    if (areValidBids && totalBids > 0) {
      setTimeout(() => {
        setLoaded(false);
      }, 1000);
      return setLoaded(true);
    }
  }, [areValidBids]);

  const getCount = () => {
    if (stillLoading) {
      return <Skeleton width={88} height={20} />;
    } else if (failedBidsRequest) {
      return <></>;
    } else if (areValidBids) {
      return (
        <Typography
          size={TypographySize.lg}
          lineHeight={TypographyLineHeight.none}
          weight={TypographyWeight.semibold}
          className=" text-grey-900"
        >
          {intl.formatNumber(totalBids)}
        </Typography>
      );
    }
  };

  const getText = () => {
    if (stillLoading) {
      return <Skeleton width={200} height={20} />;
    } else if (failedBidsRequest) {
      return <></>;
    } else if (areValidBids) {
      return (
        <Typography
          size={TypographySize.lg}
          lineHeight={TypographyLineHeight.none}
          weight={TypographyWeight.regular}
          className="text-grey-900"
        >
          {intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_AI_STATISTICS_TITLE,
          })}
        </Typography>
      );
    }
  };

  const getRequiredIconOrLoadingState = () => {
    if (stillLoading) {
      return (
        <div
          className={classNames(
            'flex flex-row gap-4 pl-12 items-center justify-center',
            {
              'animate-removeOut': !loadingBidsRequest && !failedBidsRequest,
            }
          )}
        >
          <Icon
            svg={loadingBidsRequest ? ResetIcon : CheckmarkIcon}
            size={IconSize.Small2}
            className="text-grey-600"
          />
          <Typography
            size={TypographySize.sm}
            lineHeight={TypographyLineHeight.none}
            weight={TypographyWeight.regular}
            className="text-grey-600"
          >
            {intl.formatMessage({
              id: loadingBidsRequest
                ? I18nKey.GENERIC_AI_THINKING
                : I18nKey.GENERIC_SUCCESSFULLY_LOADED,
            })}
          </Typography>
        </div>
      );
    } else if (areValidBids && totalBids > 0) {
      return (
        <>
          <PortaledTooltip
            content={intl.formatMessage({ id: I18nKey.GENERIC_VIEW_TRENDS })}
            position={{
              placement: PortaledTooltipPlacement.Right,
              alignment: PortaledTooltipAlignment.Center,
            }}
            theme={PortaledTooltipTheme.DarkV2}
            dataTestId={'ai-stats-view-trends-slideover-tooltip'}
          >
            <Button
              size={ButtonSize.InlineIconMedium}
              svgIcon={GraphTrendUpIcon}
              iconSize={IconSize.Medium}
              variant={ButtonVariant.BlackAndWhiteBorder}
              onClick={openSlideOver}
              className="ml-12"
            />
          </PortaledTooltip>
        </>
      );
    } else if (failedBidsRequest) {
      return <></>;
    }
  };

  return (
    <div className="p-8 bg-grey-50 w-full mt-12">
      <div className="h-32 flex flex-row gap-8 items-center justify-center">
        {failedBidsRequest ? (
          <div className="group">
            <div
              className={classNames(
                'flex gap-4 items-center justify-center',
                'px-20 py-4',
                'bg-white group-hover:bg-purple-500',
                'rounded-full shadow-sm',
                'cursor-pointer'
              )}
              onClick={onErrorReload}
            >
              <Icon
                svg={ResetIcon}
                size={IconSize.Small2}
                className="text-purple-500 group-hover:text-white"
              />
              <Typography
                size={TypographySize.sm}
                lineHeight={TypographyLineHeight.none}
                weight={TypographyWeight.regular}
                className="text-purple-500 group-hover:text-white"
              >
                {intl.formatMessage({
                  id: I18nKey.GENERIC_ERROR_LOADING_TRY_AGAIN,
                })}
              </Typography>
            </div>
          </div>
        ) : (
          <>
            <Icon
              svg={LightningIcon}
              size={IconSize.Large}
              className={classNames({
                'text-purple-500': !stillLoading,
                'text-grey-400': stillLoading,
                hidden: failedBidsRequest,
              })}
            />
            {getCount()}
            {getText()}
            {getRequiredIconOrLoadingState()}
          </>
        )}
      </div>
    </div>
  );
};
