import React, { ReactNodeArray, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Context } from 'vm';

/* eslint-disable max-lines */
import { useNavigate } from 'react-router-dom';
import {
  Alignment,
  ColumnAlign,
  FormToggleProps,
  FormToggleSize,
  FormToggleState,
  Placement,
  Type,
} from '@teikametrics/tm-design-system';

import { SubscriptionEndedModal } from '../../../../../components/SubscriptionEndedModal';
import { SwitchPlanModal } from '../../../../../components/SwitchPlanModal';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../../../containers/notificationProvider';
import { SubscriptionContext } from '../../../../../containers/subscriptionProvider';
import { makeToggleColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  tableActions,
  tableThunks,
} from '../../../../../containers/tableV2/ducks';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import {
  getCurrentAccountFromContext,
  getCurrentAccountSubscriptions,
  showSwitchPlanModal,
  showTrialEndedPaymentNeeded,
} from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import {
  FAMApiClient,
  createFAMApiClient,
} from '../../../../../lib/clients/FAMApiClient';
import { PaginatedRequest } from '../../../../../lib/clients/types';
import { SALES_CHANNEL_IDS } from '../../../../../lib/factories';
import { PRODUCT_SUBSCRIPTIONS } from '../../../../../lib/types/AOSharedTypes';
import {
  ConnectionStatus,
  FAMConnectionStatus,
  FlywheelSalesChannel,
  SalesChannelData,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  DisableAutomationModal,
  EnableAutomationModal,
  PlanChangedAdvisoryModal,
} from '../../../components/disableAutomationModal';
import { getSalesChannelId } from '../../utils';
import {
  RowCellElementProps,
  SALES_CHANNELS_TABLE,
  SalesChannelTableData,
} from '../types';
import { equalToFilter } from '../../../../../lib/utilities/filter';
import { ViewerRoleTooltip } from '../../../../../lib/utilities/viewerRoleTooltipWrapper';

const COLUMN_NAME = 'ai_enabled';

const checkForLastAiDisabled = async (
  accountId: string,
  famApiClient: FAMApiClient
) => {
  let request: PaginatedRequest = {
    filters: [equalToFilter(COLUMN_NAME, 'true')],
    itemsPerPage: 1,
    page: 1,
    sorts: [],
  };

  const walmartRequest = famApiClient.getSalesChannelTableData(
    accountId,
    SALES_CHANNEL_IDS.WALMART
  )(request);

  const amazonRequest = famApiClient.getSalesChannelTableData(
    accountId,
    SALES_CHANNEL_IDS.AMAZON
  )(request);

  return Promise.all([walmartRequest, amazonRequest]).then(
    ([walmartResponse, amazonResponse]) => {
      return walmartResponse.totalItems + amazonResponse.totalItems <= 0;
    }
  );
};

const getHeaderI18nKey = (aiEnabled: boolean) =>
  !aiEnabled
    ? I18nKey.ACCOUNT_SALES_CHANNELS_TOAST_AI_ENABLED
    : I18nKey.ACCOUNT_SALES_CHANNELS_TOAST_AI_DISABLED;

const getDescriptionI18nKey = (aiEnabled: boolean) =>
  !aiEnabled
    ? I18nKey.ACCOUNT_SALES_CHANNELS_TOAST_AI_ENABLED_DESCRIPTION
    : I18nKey.ACCOUNT_SALES_CHANNELS_TOAST_AI_DISABLED_DESCRIPTION;

const getNotificationType = (aiEnabled: boolean) =>
  !aiEnabled ? Type.Success : Type.Attention;

// Ads must be connected with data synced
const shouldEnableToggle = (
  adConnectionStatus: string,
  isAmazon: boolean,
  aiEnabled: boolean,
  isDelinquent?: boolean,
  isUserRoleViewOnly?: boolean
) =>
  !isUserRoleViewOnly &&
  !isDelinquent &&
  ((isAmazon && adConnectionStatus === ConnectionStatus.Connected) ||
    (!isAmazon && adConnectionStatus === FAMConnectionStatus.Active) ||
    aiEnabled);

const getFormToggleState = (aiEnabled: boolean) =>
  aiEnabled ? FormToggleState.On : FormToggleState.Off;

const getMerchantName = (extMerchantId: string, merchantName?: string) =>
  merchantName ? merchantName : extMerchantId;

const getMacsEditingUrl = (isAmazon: boolean, merchantCountryId: string) =>
  isAmazon
    ? `/ads-optimization/ads-manager/amazon/sponsored-products/ad-groups?merchantCountryIds=${merchantCountryId}`
    : `/ads-optimization/ads-manager/walmart/sponsored-products/ad-groups?merchantCountryIds=${merchantCountryId}`;

const isLastMcDisabled = async (
  userContext: Context,
  accountId: string,
  famApiClient: FAMApiClient
) => {
  const userDetails = userContext.userInfo.userDetails;

  if (!userDetails) {
    return;
  }

  const lastMcWasDisabled = await checkForLastAiDisabled(
    accountId,
    famApiClient
  );

  if (lastMcWasDisabled) {
    const aiSubscriptionId: string | undefined = getCurrentAccountSubscriptions(
      PRODUCT_SUBSCRIPTIONS.AI,
      userDetails
    )?.id;
    await famApiClient.removeProductSubscription(accountId, aiSubscriptionId!);
    // update context with subscription permissions
    userContext.refreshUserContext();
  }

  return lastMcWasDisabled;
};

export const AutomationRowCell: React.FC<
  RowCellElementProps & { isAmazon: boolean; isUserRoleViewOnly?: boolean }
> = ({
  aiEnabled,
  id: merchantCountryId,
  adConnectionStatus,
  name,
  extMerchantId,
  isAmazon,
  isUserRoleViewOnly,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const userContext = React.useContext<UserContextState>(UserContext);
  const userAccount = getCurrentAccountFromContext(userContext)!;

  const famApiClient = createFAMApiClient(userContext.userInfo.idToken!);
  const toasts =
    React.useContext<NotificationContextState>(NotificationContext);

  const { updateSubscriptionInformation } =
    React.useContext(SubscriptionContext);

  const isDelinquent = userContext?.billingInfo?.isDelinquent;

  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
  const [shouldShowConfirmAiModal, setShouldShowConfirmAiModal] =
    useState<boolean>(false);
  const [shouldShowPlanChangedModal, setShowPlanChangedModal] =
    useState<boolean>(false);
  const [
    shouldShowSubscriptionEndedModal,
    setShouldShowSubscriptionEndedModal,
  ] = useState<boolean>(false);
  const [shouldShowSwitchPlanModal, setShouldShowSwitchPlanModal] =
    useState<boolean>(false);

  const showDisableAutomationModal = () => {
    setShouldShowModal(true);
  };

  const hideDisableAutomationModal = async () => {
    setShouldShowModal(false);
    const lastMcWasDisabled = await isLastMcDisabled(
      userContext,
      accountId,
      famApiClient
    );
    if (lastMcWasDisabled) {
      setShowPlanChangedModal(true);
    }
  };

  const accountId = userAccount?.id;

  const onToggleClick = async () => {
    if (aiEnabled) {
      showDisableAutomationModal();
    } else {
      showEnableAutomationModal();
    }
  };

  const showEnableAutomationModal = () => {
    if (showTrialEndedPaymentNeeded(userContext)) {
      setShouldShowSubscriptionEndedModal(true);
      return;
    }

    if (showSwitchPlanModal(userContext)) {
      setShouldShowSwitchPlanModal(true);
      return;
    }

    setShouldShowConfirmAiModal(true);
  };

  // TODO: When there's more than amazon and walmart, this will need to be updated
  const macsEdittingUrl = getMacsEditingUrl(isAmazon, merchantCountryId);

  const navigate = useNavigate();

  const toggleAI = async () => {
    try {
      await famApiClient.updateSalesChannel(
        { aiEnabled: !aiEnabled },
        userAccount.id,
        merchantCountryId
      );
      userContext.refreshUserContext();

      toasts.addNotification({
        headline: intl.formatMessage(
          { id: I18nKey.ACCOUNT_SALES_CHANNELS_TOAST_AI_HEADER },
          {
            status: intl.formatMessage({
              id: getHeaderI18nKey(aiEnabled),
            }),
          }
        ),
        description: intl.formatMessage(
          {
            id: getDescriptionI18nKey(aiEnabled),
          },
          {
            merchantName: getMerchantName(merchantCountryId, name),
            em: (...chunks: ReactNodeArray) =>
              chunks.map((e, i) => (
                <span className="font-medium" key={i}>
                  {e}
                </span>
              )),
          }
        ),
        type: getNotificationType(aiEnabled),
        dataTestId: 'salesChannelsAutomationSuccessStatus',
      });
      dispatch(
        tableActions.updateTableCell({
          tableId: SALES_CHANNELS_TABLE,
          rowId: merchantCountryId,
          columnName: 'aiEnabled',
          value: !aiEnabled,
        })
      );
      updateSubscriptionInformation();
      if (shouldShowSwitchPlanModal) {
        setShouldShowSwitchPlanModal(false);
      }
      if (shouldShowSubscriptionEndedModal) {
        setShouldShowSubscriptionEndedModal(false);
      }
      if (shouldShowConfirmAiModal) {
        setShouldShowConfirmAiModal(false);
      }
    } catch (err) {
      toasts.addNotification({
        headline: intl.formatMessage({ id: I18nKey.ERROR }),
        description: intl.formatMessage({
          id: I18nKey.ERROR_MESSAGE,
        }),
        type: Type.Attention,
        dataTestId: 'salesChannelsAutomationErrorStatus',
      });
      dispatch(
        tableThunks.refreshTable(
          SALES_CHANNELS_TABLE,
          famApiClient.getSalesChannelTableData(
            userAccount.id,
            getSalesChannelId(isAmazon)
          )
        )
      );
    }
  };

  const toggleCellProps: FormToggleProps = {
    size: FormToggleSize.Small,
    state: getFormToggleState(aiEnabled),
    disabled: !shouldEnableToggle(
      adConnectionStatus,
      isAmazon,
      aiEnabled,
      isDelinquent,
      isUserRoleViewOnly
    ),
    onClick: onToggleClick,
    label: getFormToggleState(aiEnabled),
  };

  return (
    <>
      {shouldShowModal && (
        <DisableAutomationModal
          onCancel={() => setShouldShowModal(false)}
          disableAction={toggleAI}
          afterDisable={hideDisableAutomationModal}
        />
      )}
      {shouldShowConfirmAiModal && (
        <EnableAutomationModal
          onCancel={() => {
            setShouldShowConfirmAiModal(false);
            toggleAI();
          }}
          enableAction={toggleAI}
          afterEnable={() => navigate(macsEdittingUrl)}
          merchantName={getMerchantName(extMerchantId, name)}
        />
      )}
      {shouldShowPlanChangedModal && <PlanChangedAdvisoryModal />}
      {shouldShowSubscriptionEndedModal && (
        <SubscriptionEndedModal
          onClose={() => setShouldShowSubscriptionEndedModal(false)}
        />
      )}
      {shouldShowSwitchPlanModal && (
        <SwitchPlanModal
          show
          salesChannel={FlywheelSalesChannel.Amazon}
          onCancelClick={() => setShouldShowSwitchPlanModal(false)}
          onContinueClick={toggleAI}
        />
      )}
      <ViewerRoleTooltip
        position={{
          placement: Placement.Left,
          alignment: Alignment.Center,
        }}
        disabled={!isUserRoleViewOnly}
      >
        {makeToggleColumn(() => toggleCellProps)({})}
      </ViewerRoleTooltip>
    </>
  );
};
AutomationRowCell.displayName = 'AutomationRowCell';

export const automationColumn: FlywheelTableV2ColumnProps<
  SalesChannelData,
  {},
  SalesChannelTableData,
  {}
> = {
  header: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_AI,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => {
    return (
      <AutomationRowCell
        {...original}
        isAmazon={meta.isAmazon || false}
        isUserRoleViewOnly={meta.isUserRoleViewOnly || false}
      />
    );
  },
  accessorKey: 'ai_enabled',
  size: 100,
  sortable: true,
  align: ColumnAlign.Left,
};
