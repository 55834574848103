import './styles.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import I18nKey from '../../../../lib/types/I18nKey';

export interface GradientBarProps {
  /**
   * The i18n key used for the text.
   * If not provided then nothing will be displayed.
   */
  readonly i18nKey?: I18nKey;

  /**
   * The JSX element that will be placed in the header.
   * Custom content will not be shown if it is not present.
   */
  readonly content?: JSX.Element;

  /**
   * theme of gradient bar
   * @default GradientBarTheme.FlywheelGradient
   */
  readonly theme?: GradientBarTheme;

  /**
   * Icon before text
   */
  readonly icon?: JSX.Element;
}

export enum GradientBarTheme {
  FlywheelGradient = 'FlywheelGradient',
  Orange = 'Orange',
}

export const GradientBar: React.FC<GradientBarProps> = ({
  i18nKey,
  content,
  icon,
  theme = GradientBarTheme.FlywheelGradient,
}) => (
  <div
    className={classNames('gradient-bar', {
      'gradient-bar__theme--flywheel-gradient':
        theme === GradientBarTheme.FlywheelGradient,
      'gradient-bar__theme--orange': theme === GradientBarTheme.Orange,
    })}
  >
    {icon && <div className="gradient-bar__icon">{icon}</div>}
    {i18nKey && <FormattedMessage id={i18nKey} />}
    {content}
  </div>
);
