import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  MerchantCountriesContext,
  MerchantCountriesContextState,
} from '../../../containers/merchantCountriesProvider/merchantCountriesProvider';
import { getCurrentAccountFromContext } from '../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../containers/userProvider/userProvider';
import { createAOApiClient } from '../../../lib/clients/AOApiClient';
import {
  KeywordType,
  RecommendationCount,
  RecommendationType,
  TargetRecommendationCountResponseV2,
} from '../../../lib/types/KeywordAction';
import { OptimizelyContext } from '../../../containers/optimizelyProvider/optimizelyProvider';
import { AdType } from '../../../lib/types/AOSharedTypes';

export interface RecommendationsCountState {
  readonly spKeywordsToAddCount: number;
  readonly spKeywordsToNegateCount: number;
  readonly spPatKeywordsToAddCount: number;
  readonly spKeywordsByPerformanceCount: number;
  readonly spKeywordsByChannelCount: number;
  readonly sbKeywordsToAddCount: number;
  readonly sbKeywordsToNegateCount: number;
  readonly sbPatKeywordsToAddCount: number;
  readonly sbKeywordsByPerformanceCount: number;
  readonly sbKeywordsByChannelCount: number;
  readonly smartKeywordsByPerformanceCount: number;
  readonly smartKeywordsByChannelCount: number;
  readonly nonSmartKeywordsByPerformanceCount: number;
  readonly nonSmartKeywordsByChannelCount: number;
  readonly negativeKeywordsByPerformanceCount: number;
  readonly negativeKeywordsByChannelCount: number;
  readonly smartNegativeKeywordsByPerformanceCount: number;
  readonly smartNegativeKeywordsByChannelCount: number;
  readonly nonSmartNegativeKeywordsByPerformanceCount: number;
  readonly nonSmartNegativeKeywordsByChannelCount: number;
  readonly setMerchantCountryId: (value: string) => void;
  readonly recommendationsCount: TargetRecommendationCountResponseV2[];
  readonly refreshRecommendationsCount: () => void;
  readonly isRecommendationsCountLoading?: boolean;
}

export const RecommendationsCountContext =
  createContext<RecommendationsCountState>({
    spKeywordsToAddCount: 0,
    spKeywordsToNegateCount: 0,
    spPatKeywordsToAddCount: 0,
    spKeywordsByChannelCount: 0,
    spKeywordsByPerformanceCount: 0,
    sbKeywordsToAddCount: 0,
    sbKeywordsToNegateCount: 0,
    sbPatKeywordsToAddCount: 0,
    sbKeywordsByChannelCount: 0,
    sbKeywordsByPerformanceCount: 0,
    negativeKeywordsByChannelCount: 0,
    negativeKeywordsByPerformanceCount: 0,
    nonSmartKeywordsByChannelCount: 0,
    nonSmartKeywordsByPerformanceCount: 0,
    nonSmartNegativeKeywordsByChannelCount: 0,
    nonSmartNegativeKeywordsByPerformanceCount: 0,
    smartKeywordsByChannelCount: 0,
    smartKeywordsByPerformanceCount: 0,
    smartNegativeKeywordsByChannelCount: 0,
    smartNegativeKeywordsByPerformanceCount: 0,
    setMerchantCountryId: noop,
    recommendationsCount: [],
    refreshRecommendationsCount: noop,
  });

const { Provider } = RecommendationsCountContext;

export const RecommendationsCountProvider: React.FC<{
  readonly children?: JSX.Element;
}> = ({ children }) => {
  const userContext = useContext<UserContextState>(UserContext);
  const { merchantCountries } = useContext<MerchantCountriesContextState>(
    MerchantCountriesContext
  );
  const {
    featureFlags: { sp_pat_kwa },
  } = useContext(OptimizelyContext);
  const [recommendationsCount, setRecommendationsCount] = useState<
    TargetRecommendationCountResponseV2[]
  >([]);

  const [merchantCountryId, setMerchantCountryId] = useState<string>('');

  const [isRecommendationsCountLoading, setRecommendationsCountLoading] =
    useState<boolean>(true);

  const accountId = getCurrentAccountFromContext(userContext)?.id;

  const aoApiClient = createAOApiClient(userContext.userInfo.idToken ?? null);

  const refreshRecommendationsCount = () => {
    if (
      !isNil(accountId) &&
      !isNil(merchantCountries) &&
      merchantCountries.length > 0
    ) {
      setRecommendationsCountLoading(true);
      aoApiClient
        .getTargetRecommendationCountV3_1(
          accountId,
          merchantCountries.map(
            (merchantCountry) => merchantCountry.merchantCountryId
          )
        )
        .then((merchantRecommendations) => {
          setRecommendationsCount(merchantRecommendations);
          setRecommendationsCountLoading(false);
        })
        .catch(() => {
          setRecommendationsCountLoading(false);
        });
    }
  };

  useEffect(() => {
    refreshRecommendationsCount();
  }, [accountId, merchantCountries]);

  return (
    <Provider
      value={{
        isRecommendationsCountLoading,
        recommendationsCount,
        setMerchantCountryId: (value: string) => setMerchantCountryId(value),
        spKeywordsToAddCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.KEYWORD,
          (acc, item) => acc + item.count,
          undefined,
          AdType.SponsoredProducts
        ),
        spKeywordsToNegateCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.NEGATIVE_KEYWORD,
          (acc, item) => acc + item.count,
          undefined,
          AdType.SponsoredProducts
        ),
        spPatKeywordsToAddCount: sp_pat_kwa
          ? getKeywordCount(
              recommendationsCount,
              merchantCountryId,
              RecommendationType.PAT,
              (acc, item) => acc + item.count,
              undefined,
              AdType.SponsoredProducts
            )
          : 0,
        spKeywordsByChannelCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.KEYWORD,
          (acc, item) => acc + item.byChannelCount,
          undefined,
          AdType.SponsoredProducts
        ),
        spKeywordsByPerformanceCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.KEYWORD,
          (acc, item) => acc + item.byPerformanceCount,
          undefined,
          AdType.SponsoredProducts
        ),
        negativeKeywordsByChannelCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.NEGATIVE_KEYWORD,
          (acc, item) => acc + item.byChannelCount
        ),
        negativeKeywordsByPerformanceCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.NEGATIVE_KEYWORD,
          (acc, item) => acc + item.byPerformanceCount
        ),
        sbKeywordsToAddCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.KEYWORD,
          (acc, item) => acc + item.count,
          undefined,
          AdType.SponsoredBrands
        ),
        sbKeywordsToNegateCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.NEGATIVE_KEYWORD,
          (acc, item) => acc + item.count,
          undefined,
          AdType.SponsoredBrands
        ),
        sbPatKeywordsToAddCount: sp_pat_kwa
          ? getKeywordCount(
              recommendationsCount,
              merchantCountryId,
              RecommendationType.PAT,
              (acc, item) => acc + item.count,
              undefined,
              AdType.SponsoredBrands
            )
          : 0,
        sbKeywordsByChannelCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.KEYWORD,
          (acc, item) => acc + item.byChannelCount,
          undefined,
          AdType.SponsoredBrands
        ),
        sbKeywordsByPerformanceCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.KEYWORD,
          (acc, item) => acc + item.byPerformanceCount,
          undefined,
          AdType.SponsoredBrands
        ),
        nonSmartKeywordsByChannelCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.KEYWORD,
          (acc, item) => acc + item.byChannelCount,
          KeywordType.Basic
        ),
        nonSmartKeywordsByPerformanceCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.KEYWORD,
          (acc, item) => acc + item.byPerformanceCount,
          KeywordType.Basic
        ),
        nonSmartNegativeKeywordsByChannelCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.NEGATIVE_KEYWORD,
          (acc, item) => acc + item.byChannelCount,
          KeywordType.Basic
        ),
        nonSmartNegativeKeywordsByPerformanceCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.NEGATIVE_KEYWORD,
          (acc, item) => acc + item.byPerformanceCount,
          KeywordType.Basic
        ),
        smartKeywordsByChannelCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.KEYWORD,
          (acc, item) => acc + item.byChannelCount,
          KeywordType.Smart
        ),
        smartKeywordsByPerformanceCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.KEYWORD,
          (acc, item) => acc + item.byPerformanceCount,
          KeywordType.Smart
        ),
        smartNegativeKeywordsByChannelCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.NEGATIVE_KEYWORD,
          (acc, item) => acc + item.byChannelCount,
          KeywordType.Smart
        ),
        smartNegativeKeywordsByPerformanceCount: getKeywordCount(
          recommendationsCount,
          merchantCountryId,
          RecommendationType.NEGATIVE_KEYWORD,
          (acc, item) => acc + item.byPerformanceCount,
          KeywordType.Smart
        ),
        refreshRecommendationsCount,
      }}
    >
      {children}
    </Provider>
  );
};

export const getKeywordCount = (
  recommendationsCount: TargetRecommendationCountResponseV2[],
  merchantCountryId: string,
  type: RecommendationType,
  reduce: (acc: number, item: RecommendationCount) => number,
  campaignType?: KeywordType,
  advertisingType?: AdType
) => {
  const recommendation = recommendationsCount.find(
    (recommendationCount) =>
      recommendationCount.merchantCountryId === merchantCountryId
  );

  if (isNil(recommendation)) {
    return 0;
  }

  if (isNil(campaignType)) {
    if (advertisingType) {
      return recommendation.data
        .filter(
          (item) =>
            item.recommendationType.toLowerCase() === type.toLowerCase() &&
            advertisingType.toLowerCase() === item.advertisingType.toLowerCase()
        )
        .reduce(reduce, 0);
    }
    return recommendation.data
      .filter(
        (item) => item.recommendationType.toLowerCase() === type.toLowerCase()
      )
      .reduce(reduce, 0);
  }

  if (advertisingType) {
    return recommendation.data
      .filter(
        (item) =>
          item.type === campaignType &&
          item.recommendationType.toLowerCase() === type.toLowerCase() &&
          advertisingType.toLowerCase() === item.advertisingType.toLowerCase()
      )
      .reduce(reduce, 0);
  }

  return recommendation.data
    .filter(
      (item) =>
        item.type === campaignType &&
        item.recommendationType.toLowerCase() === type.toLowerCase()
    )
    .reduce(reduce, 0);
};
