import first from 'lodash/first';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import { DateTime, PossibleDaysInMonth } from 'luxon';
import { IntlShape } from 'react-intl';

import {
  AIDisabledIcon,
  AIEnabledIcon,
  ActivatedAIMarkerIcon as ActivateAIMarkerIcon,
  CardState,
  CardsProps,
  ChartGraphLinearIcon,
  ChartTime,
  CompassEmptyState,
  CompassLinearIcon,
  DataIntegratedIcon,
  DeactivatedAIMarkerIcon,
  DotsCircleIcon,
  EventLogPoint,
  LegendProps,
  TableEmptyState as LoadingState,
  MarkerType,
  NoDataIcon,
  NullPoints,
  NumberFormat,
  PlotBandPoints,
  PlotBandType,
  QuestionCircleLinearIcon,
  ToggleTab,
  ToggleTabTheme,
} from '@teikametrics/tm-design-system';

import { HomepageDataRequestResponse } from '../../../';
import {
  AggregationPeriodType,
  GraphDataPoints,
  HomePageData,
  HomepageResponseMetricKeys,
  MetricSummary,
  OverviewSummary,
  OverviewTimePeriod,
  PerformanceMetrics,
  SeasonalEvent,
  TimePeriodGraphDataPoints,
} from '../../../../../lib/types/CompassSharedTypes';
import {
  AccountEvent,
  ConnectionStatus,
  FlywheelSalesChannel,
  ProductSubscriptionEventResponse,
  SubscriptionActionType,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  SalesChannelConnectionResponse,
  SalesChannelConnectionStatus,
  getAdConnectionStatus,
  getAdsAndProductDataConnectionStatusDataForSetup,
  getProductConnectionStatus,
} from '../../../../../lib/utilities/connectionStatus';
import { AccountSalesChannelPaginatedResultWithError } from '../../../../products/containers/skuCatalog/types';
import { UserInfo, getCompassAPIFailCount } from '../../../storageUtils';
import {
  COMPASS_API_REQUEST_DATE_FORMAT,
  ChartTrendMetricFormatSelector,
  CompassState,
  DateRange,
  KpiMetric,
  MAP_CHART_TREND_METRIC_FORMAT_SELECTOR_TO_DATE_TIME_READABLE_FORMAT_SELECTOR,
  MetricTabsData,
  MetricTabsName,
  ORIGINAL_TREND_DATE_FORMAT,
} from '../../../types';
import {
  CompassChartColors,
  CompassPathParams,
  CustomHoverGraphDataPointsData,
  GraphDataPointsPeriodType,
  GraphPoint,
  LeftSideLegendGraphData,
  MAP_CHART_METRIC_TO_BAR_CHART_COLOR,
  MAP_CHART_TREND_METRIC_TO_OVERVIEW_TIME_PERIOD,
  MAP_COMPASS_PATH_PARAMS_TO_HOMEPAGE_RESPONSE_METRIC_KEYS,
  MetricCardsGroupHeaders,
} from '../../compassDashboard/types';
import { FLOATING_POINT_PRECISION } from './constants';
import { CurrencyCode } from '../../../../../lib/utilities/currency';
import { MerchantSyncDate } from '../../../../../lib/types/AOSharedTypes';
import { talkToUs } from '../../../../../lib/utilities/intercom';
import { redirect } from 'react-router-dom';

export enum AiActivityType {
  Added = 'added',
  Removed = 'removed',
}

export interface AccountEventPoint {
  readonly type: AiActivityType;
  readonly date: string;
  readonly position: number;
}

interface AccountEvents {
  readonly actualDate: string;
  readonly action: SubscriptionActionType;
  readonly roundOffDate: string;
}

interface TransformDataPointsParams {
  readonly intl: IntlShape;
  readonly trendFormatSelector: ChartTrendMetricFormatSelector;
  readonly chartsTabs: ChartTime;
  readonly current?: TimePeriodGraphDataPoints;
  readonly previous?: TimePeriodGraphDataPoints;
  readonly subscriptionData?: ProductSubscriptionEventResponse;
  readonly pathParam?: CompassPathParams;
  readonly selectedDateRange?: DateRange;
}

const NINE_PLUS = '9+';

export const getChartDataMarkerPoints = (
  accountEventPoints: AccountEventPoint[]
) => {
  const commonPoints = intersection(
    accountEventPoints
      .filter((point) => point.type === AiActivityType.Added)
      .map((point) => point.position),
    accountEventPoints
      .filter((point) => point.type === AiActivityType.Removed)
      .map((point) => point.position)
  );
  return accountEventPoints.map((point) => {
    return {
      marker:
        point.type === AiActivityType.Added
          ? ActivateAIMarkerIcon
          : DeactivatedAIMarkerIcon,
      markerColor:
        MAP_CHART_METRIC_TO_BAR_CHART_COLOR[
          point.type === AiActivityType.Added
            ? CompassChartColors.WithAI
            : CompassChartColors.WithoutAI
        ],
      markerPointLocation: point.position,
      markerType:
        point.type === AiActivityType.Added
          ? MarkerType.ActivatedAI
          : MarkerType.DeactivatedAI,
      isStackedPoint: commonPoints.includes(point.position),
    };
  });
};

const getChartMetrics = (
  responseMetricKeys: MetricTabsName,
  metricTabsData: MetricTabsData
) => {
  let { pillText, comparisonTrend, currentValue, previousValue } =
    metricTabsData?.[responseMetricKeys] || {};

  return {
    cardBody: {
      content: currentValue,
    },
    cardTrend: {
      pillText: pillText,
      status: comparisonTrend,
    },

    dataTestId: `1_5com_${responseMetricKeys}`,
    previousValue: previousValue,
    state: isNil(metricTabsData?.[responseMetricKeys]?.currentValue)
      ? CardState.Loading
      : CardState.Success,
    showFooterText: !!previousValue,
    isNegativeUpTrend: responseMetricKeys === MetricTabsName.tacos,
  };
};

export const getIsMetricActive = (
  compassState: CompassState,
  metricTabsData: MetricTabsData,
  metricName: MetricTabsName
) => {
  if (compassState !== CompassState.Success) {
    return false;
  }

  switch (metricName) {
    case MetricTabsName.totalSales:
      return !!metricTabsData[MetricTabsName.totalSales]?.currentValue;
    case MetricTabsName.adSales:
      return (
        !metricTabsData[MetricTabsName.totalSales]?.currentValue &&
        !!metricTabsData[MetricTabsName.adSales]?.currentValue
      );
    case MetricTabsName.adSpend:
      return (
        !metricTabsData[MetricTabsName.totalSales]?.currentValue &&
        !metricTabsData[MetricTabsName.adSales]?.currentValue &&
        !!metricTabsData[MetricTabsName.adSpend]?.currentValue
      );
    default:
      return (
        !metricTabsData[MetricTabsName.totalSales]?.currentValue &&
        !metricTabsData[MetricTabsName.adSales]?.currentValue &&
        !metricTabsData[MetricTabsName.adSpend]?.currentValue &&
        !!metricTabsData[MetricTabsName.tacos].currentValue
      );
  }
};

export const getGraphPoint = ({
  intl,
  data,
  selectedCurrency,
  xAxisLabels,
  chartsTabs,
  trendFormatSelector,
  compassState,
  metricTabsData,
}: GraphPoint): any[] => {
  const currentTotalSalesDataPoints = getGraphDataPoints({
    intl,
    dataType: AggregationPeriodType.Current,
    selectedCurrency,
    xAxisLabels,
    chartsTabs,
    name: intl.formatMessage({
      id: MetricCardsGroupHeaders.TotalSales,
    }),
    trendFormatSelector,
    dataGraphPoints:
      data?.current?.[HomepageResponseMetricKeys.TotalSales]?.graphDataPoints,
    isCurrent: true,
    compassState,
  });

  const totalSalesGatheringDataPoints = getGatheringDataPoints({
    intl,
    dataType: AggregationPeriodType.Current,
    selectedCurrency,
    xAxisLabels,
    chartsTabs,
    name: intl.formatMessage({
      id: MetricCardsGroupHeaders.TotalSales,
    }),
    trendFormatSelector,
    dataGraphPoints:
      data?.current?.[HomepageResponseMetricKeys.TotalSales]?.graphDataPoints,
    compassState,
  });

  const previousTotalSalesDataPoints = getGraphDataPoints({
    intl,
    dataType: AggregationPeriodType.Previous,
    selectedCurrency,
    xAxisLabels,
    chartsTabs,
    trendFormatSelector,
    name: intl.formatMessage({
      id: MetricCardsGroupHeaders.TotalSales,
    }),
    dataGraphPoints:
      data?.previous?.[HomepageResponseMetricKeys.TotalSales]?.graphDataPoints,
    isCurrent: false,
    compassState,
  });

  const currentAdSalesDataPoints = getGraphDataPoints({
    intl,
    dataType: AggregationPeriodType.Current,
    selectedCurrency,
    xAxisLabels,
    chartsTabs,
    name: intl.formatMessage({
      id: MetricCardsGroupHeaders.AdSales,
    }),
    trendFormatSelector,
    dataGraphPoints:
      data?.current?.[HomepageResponseMetricKeys.AdSales]?.graphDataPoints,
    isCurrent: true,
    compassState,
  });

  const adSalesGatheringDataEndpoints = getGatheringDataPoints({
    intl,
    dataType: AggregationPeriodType.Current,
    selectedCurrency,
    xAxisLabels,
    chartsTabs,
    name: intl.formatMessage({
      id: MetricCardsGroupHeaders.AdSales,
    }),
    trendFormatSelector,
    dataGraphPoints:
      data?.current?.[HomepageResponseMetricKeys.AdSales]?.graphDataPoints,
    compassState,
  });

  const adSalesPreviousPeriodDataPoints = getGraphDataPoints({
    intl,
    dataType: AggregationPeriodType.Previous,
    selectedCurrency,
    xAxisLabels,
    chartsTabs,
    trendFormatSelector,
    name: intl.formatMessage({
      id: MetricCardsGroupHeaders.AdSales,
    }),
    dataGraphPoints:
      data?.previous?.[HomepageResponseMetricKeys.AdSales]?.graphDataPoints,
    isCurrent: false,
    compassState,
  });

  const currentAdSpendDataPoints = getGraphDataPoints({
    intl,
    dataType: AggregationPeriodType.Current,
    selectedCurrency,
    xAxisLabels,
    chartsTabs,

    name: intl.formatMessage({
      id: MetricCardsGroupHeaders.AdSpend,
    }),
    trendFormatSelector,
    dataGraphPoints:
      data?.current?.[HomepageResponseMetricKeys.AdSpend]?.graphDataPoints,
    isCurrent: true,
    compassState,
  });

  const adSpendGatheringDataPoints = getGatheringDataPoints({
    intl,
    dataType: AggregationPeriodType.Current,
    selectedCurrency,
    xAxisLabels,
    chartsTabs,
    name: intl.formatMessage({
      id: MetricCardsGroupHeaders.AdSpend,
    }),
    trendFormatSelector,
    dataGraphPoints:
      data?.current?.[HomepageResponseMetricKeys.AdSpend]?.graphDataPoints,
    compassState,
  });

  const adSpendPreviousPeriodDataPoints = getGraphDataPoints({
    intl,
    dataType: AggregationPeriodType.Previous,
    selectedCurrency,
    xAxisLabels,
    chartsTabs,
    trendFormatSelector,
    name: intl.formatMessage({
      id: MetricCardsGroupHeaders.AdSpend,
    }),
    dataGraphPoints:
      data?.previous?.[HomepageResponseMetricKeys.AdSpend]?.graphDataPoints,
    isCurrent: false,
    compassState,
  });

  return [
    {
      ...getChartMetrics(MetricTabsName.totalSales, metricTabsData),
      isActive: getIsMetricActive(
        compassState,
        metricTabsData,
        MetricTabsName.totalSales
      ),
      header: intl.formatMessage({ id: MetricCardsGroupHeaders.TotalSales }),
      className: 'bg-purple-500',
      data: [
        {
          label: 'Current period',
          dataTestId: 'current-period-data-id',
          data: {
            name: 'Current period',
            color: '#4B25EA',
            type: 'spline',
            visible: true,
            yAxis: 0,
            marker: {
              symbol: 'circle',
            },
            data: currentTotalSalesDataPoints,
          },
        },
        {
          label: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
          }),
          dataTestId: 'current-period-data-id',
          data: {
            name: intl.formatMessage({
              id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
            }),
            color: '#4B25EA',
            type: 'line',
            visible: true,
            yAxis: 0,
            marker: {
              symbol: 'circle',
            },
            dashStyle: 'ShortDot',
            data: totalSalesGatheringDataPoints,
          },
        },
        {
          label: 'Previous period',
          data: {
            name: 'Previous period',
            color: '#748494',
            type: 'spline',
            visible: false,
            yAxis: 0,
            marker: {
              symbol: 'circle',
            },
            dashStyle: 'ShortDash',
            data: previousTotalSalesDataPoints,
          },
        },
      ],
    },
    {
      header: intl.formatMessage({ id: MetricCardsGroupHeaders.AdSales }),
      ...getChartMetrics(MetricTabsName.adSales, metricTabsData),
      isActive: getIsMetricActive(
        compassState,
        metricTabsData,
        MetricTabsName.adSales
      ),
      className: 'bg-magenta-500',
      data: [
        {
          label: 'Current period',
          dataTestId: 'current-period-data-id',
          data: {
            name: 'Current period',
            color: '#BD18E6',
            type: 'spline',
            visible: false,
            yAxis: 0,
            marker: {
              symbol: 'circle',
            },
            data: currentAdSalesDataPoints,
          },
        },
        {
          label: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
          }),
          dataTestId: 'current-period-data-id',
          data: {
            name: intl.formatMessage({
              id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
            }),
            color: '#BD18E6',
            type: 'line',
            visible: false,
            yAxis: 0,
            marker: {
              symbol: 'circle',
            },
            dashStyle: 'ShortDot',
            data: adSalesGatheringDataEndpoints,
          },
        },
        {
          label: 'Previous period',
          data: {
            name: 'Previous period',
            color: '#748494',
            type: 'spline',
            visible: false,
            dashStyle: 'ShortDash',
            yAxis: 0,
            marker: {
              symbol: 'circle',
            },
            data: adSalesPreviousPeriodDataPoints,
          },
        },
      ],
      // dataTestId: `1com_${MAP_COMPASS_PATH_PARAMS_TO_DATA_TEST_ID_FOR_TAB_GROUP[pathParam]}_CurrentPeriod_legend`,
    },
    {
      header: intl.formatMessage({ id: MetricCardsGroupHeaders.AdSpend }),
      ...getChartMetrics(MetricTabsName.adSpend, metricTabsData),
      isActive: getIsMetricActive(
        compassState,
        metricTabsData,
        MetricTabsName.adSpend
      ),
      className: 'bg-blue-500',
      data: [
        {
          label: 'Current period',
          dataTestId: 'current-period-data-id',
          data: {
            name: 'Current period',
            color: '#0AC2FF',
            type: 'spline',
            visible: false,
            yAxis: 0,
            marker: {
              symbol: 'circle',
            },
            data: currentAdSpendDataPoints,
          },
        },
        {
          label: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
          }),
          dataTestId: 'current-period-data-id',
          data: {
            name: intl.formatMessage({
              id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
            }),
            color: '#0AC2FF',
            type: 'line',
            visible: false,
            yAxis: 0,
            marker: {
              symbol: 'circle',
            },
            dashStyle: 'ShortDot',
            data: adSpendGatheringDataPoints,
          },
        },
        {
          label: 'Previous period',
          data: {
            name: 'Previous period',
            color: '#748494',
            type: 'spline',
            visible: false,
            dashStyle: 'ShortDash',
            yAxis: 0,
            marker: {
              symbol: 'circle',
            },
            data: adSpendPreviousPeriodDataPoints,
          },
        },
      ],
      // dataTestId: `1com_${MAP_COMPASS_PATH_PARAMS_TO_DATA_TEST_ID_FOR_TAB_GROUP[pathParam]}_CurrentPeriod_legend`,
    },
    {
      header: intl.formatMessage({ id: MetricCardsGroupHeaders.Tacos }),
      ...getChartMetrics(MetricTabsName.tacos, metricTabsData),
      isActive: getIsMetricActive(
        compassState,
        metricTabsData,
        MetricTabsName.tacos
      ),
      className: 'bg-green-500',
      data: [
        {
          label: 'Current period',
          dataTestId: 'current-period-data-id',
          data: {
            name: 'Current period',
            color: '#35C477',
            type: 'spline',
            visible: false,
            yAxis: 1,
            marker: {
              symbol: 'circle',
            },
            data: getGraphDataPointsTacos({
              intl,
              dataType: AggregationPeriodType.Current,
              selectedCurrency,
              xAxisLabels,
              chartsTabs,
              name: intl.formatMessage({
                id: MetricCardsGroupHeaders.Tacos,
              }),
              trendFormatSelector,
              dataGraphPoints:
                data?.current?.[HomepageResponseMetricKeys.Tacos]
                  ?.graphDataPoints,
              opposingDataGraphPoints:
                data?.previous?.[HomepageResponseMetricKeys.Tacos]
                  ?.graphDataPoints,
              isCurrent: true,
              compassState,
            }),
          },
        },
        {
          label: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
          }),
          dataTestId: 'current-period-data-id',
          data: {
            name: intl.formatMessage({
              id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
            }),
            color: '#35C477',
            type: 'line',
            visible: false,
            yAxis: 1,
            marker: {
              symbol: 'circle',
            },
            dashStyle: 'ShortDot',
            data: getGatheringDataForTacos({
              intl,
              dataType: AggregationPeriodType.Current,
              selectedCurrency,
              xAxisLabels,
              chartsTabs,
              name: intl.formatMessage({
                id: MetricCardsGroupHeaders.Tacos,
              }),
              trendFormatSelector,
              dataGraphPoints:
                data?.current?.[HomepageResponseMetricKeys.Tacos]
                  ?.graphDataPoints,
              compassState,
            }),
          },
        },
        {
          label: 'Previous period',
          data: {
            name: 'Previous period',
            color: '#748494',
            type: 'spline',
            visible: false,
            dashStyle: 'ShortDash',
            yAxis: 1,
            marker: {
              symbol: 'circle',
            },
            data: getGraphDataPointsTacos({
              intl,
              dataType: AggregationPeriodType.Previous,
              selectedCurrency,
              xAxisLabels,
              chartsTabs,
              trendFormatSelector,
              name: intl.formatMessage({
                id: MetricCardsGroupHeaders.Tacos,
              }),
              dataGraphPoints:
                data?.previous?.[HomepageResponseMetricKeys.Tacos]
                  ?.graphDataPoints,
              opposingDataGraphPoints:
                data?.current?.[HomepageResponseMetricKeys.Tacos]
                  ?.graphDataPoints,
              compassState,
            }),
          },
        },
      ],
    },
    {
      header: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_ROAS,
      }),
      ...getChartMetrics(MetricTabsName.roas, metricTabsData),
      isActive: getIsMetricActive(
        compassState,
        metricTabsData,
        MetricTabsName.tacos
      ),
      className: 'bg-grey-900',
      data: [
        {
          label: 'Current period',
          dataTestId: 'current-period-data-id',
          data: {
            name: 'Current period',
            color: '#0E151B',
            type: 'spline',
            visible: false,
            yAxis: 1,
            marker: {
              symbol: 'circle',
            },
            data: getGraphDataPointsForRoas({
              intl,
              dataType: AggregationPeriodType.Current,
              selectedCurrency,
              xAxisLabels,
              chartsTabs,
              name: intl.formatMessage({
                id: MetricCardsGroupHeaders.Roas,
              }),
              trendFormatSelector,
              dataGraphPoints:
                data?.current?.[HomepageResponseMetricKeys.Roas]
                  ?.graphDataPoints,
              isCurrent: true,
              compassState,
            }),
          },
        },
        {
          label: intl.formatMessage({
            id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
          }),
          dataTestId: 'current-period-data-id',
          data: {
            name: intl.formatMessage({
              id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA,
            }),
            color: '#0E151B',
            type: 'line',
            visible: false,
            yAxis: 1,
            marker: {
              symbol: 'circle',
            },
            dashStyle: 'ShortDot',
            data: getGatheringDataForRoas({
              intl,
              dataType: AggregationPeriodType.Current,
              selectedCurrency,
              xAxisLabels,
              chartsTabs,
              name: intl.formatMessage({
                id: MetricCardsGroupHeaders.Roas,
              }),
              trendFormatSelector,
              dataGraphPoints:
                data?.current?.[HomepageResponseMetricKeys.Roas]
                  ?.graphDataPoints,
              compassState,
              opposingDataGraphPoints:
                data?.previous?.[HomepageResponseMetricKeys.Roas]
                  ?.graphDataPoints,
            }),
          },
        },
        {
          label: 'Previous period',
          data: {
            name: 'Previous period',
            color: '#748494',
            type: 'spline',
            visible: false,
            dashStyle: 'ShortDash',
            yAxis: 1,
            marker: {
              symbol: 'circle',
            },
            data: getGraphDataPointsForRoas({
              intl,
              dataType: AggregationPeriodType.Previous,
              selectedCurrency,
              xAxisLabels,
              chartsTabs,
              trendFormatSelector,
              name: intl.formatMessage({
                id: MetricCardsGroupHeaders.Roas,
              }),
              dataGraphPoints:
                data?.previous?.[HomepageResponseMetricKeys.Roas]
                  ?.graphDataPoints,
              compassState,
            }),
          },
        },
      ],
    },
  ];
};

export const getCustomGraphDataPointsData = ({
  intl,
  total,
  selectedCurrency,
  date,
  name,
  trendFormatSelector,
  chartDataPeriod,
  opposingDate,
}: CustomHoverGraphDataPointsData) => {
  let formatCustomDate;
  let formatCustomOpposingDate;
  if (date) {
    const requiredDateTime: DateTime = DateTime.fromFormat(
      date,
      ORIGINAL_TREND_DATE_FORMAT
    );
    const requiredOpposingDateTime: DateTime = DateTime.fromFormat(
      opposingDate ?? '',
      ORIGINAL_TREND_DATE_FORMAT
    );
    if (
      trendFormatSelector === ChartTrendMetricFormatSelector.Week &&
      trendFormatSelector
    ) {
      formatCustomDate = datefromFormatWeek(
        intl,
        requiredDateTime,
        trendFormatSelector
      );
      formatCustomOpposingDate = datefromFormatWeek(
        intl,
        requiredOpposingDateTime,
        trendFormatSelector
      );
    } else {
      if (trendFormatSelector) {
        formatCustomDate = datefromFormatDaysMonths(
          requiredDateTime,
          trendFormatSelector
        );
        formatCustomOpposingDate = datefromFormatDaysMonths(
          requiredOpposingDateTime,
          trendFormatSelector
        );
      }
    }
  }

  const customOpposingDateValid =
    formatCustomOpposingDate &&
    !formatCustomOpposingDate?.toLowerCase().includes('invalid');

  return {
    total: intl.formatNumber(Number(total), {
      style: 'currency',
      currency: CurrencyCode[selectedCurrency],
      minimumFractionDigits: FLOATING_POINT_PRECISION,
      maximumFractionDigits: FLOATING_POINT_PRECISION,
    }),
    customDate: formatCustomDate,
    name: name,
    ...(chartDataPeriod && { chartDataPeriod }),
    ...(customOpposingDateValid && {
      customOpposingDate: formatCustomOpposingDate,
    }),
  };
};

export const getCustomGraphDataTacosPointsData = ({
  intl,
  total,
  date,
  name,
  trendFormatSelector,
  chartDataPeriod,
  opposingDate,
}: CustomHoverGraphDataPointsData) => {
  let formatCustomDate;
  let formatCustomOpposingDate;
  if (date) {
    const requiredDateTime: DateTime = DateTime.fromFormat(
      date,
      ORIGINAL_TREND_DATE_FORMAT
    );

    const requiredOpposingDateTime: DateTime = DateTime.fromFormat(
      opposingDate ?? '',
      ORIGINAL_TREND_DATE_FORMAT
    );

    if (
      trendFormatSelector === ChartTrendMetricFormatSelector.Week &&
      trendFormatSelector
    ) {
      formatCustomDate = datefromFormatWeek(
        intl,
        requiredDateTime,
        trendFormatSelector
      );
      formatCustomOpposingDate = datefromFormatWeek(
        intl,
        requiredOpposingDateTime,
        trendFormatSelector
      );
    } else {
      if (trendFormatSelector) {
        formatCustomDate = datefromFormatDaysMonths(
          requiredDateTime,
          trendFormatSelector
        );
        formatCustomOpposingDate = datefromFormatDaysMonths(
          requiredOpposingDateTime,
          trendFormatSelector
        );
      }
    }
  }

  const customOpposingDateValid =
    formatCustomOpposingDate &&
    !formatCustomOpposingDate?.toLowerCase().includes('invalid');

  return {
    total: intl.formatNumber(Number(total), {
      style: 'percent',
      minimumFractionDigits: FLOATING_POINT_PRECISION,
      maximumFractionDigits: FLOATING_POINT_PRECISION,
    }),
    customDate: formatCustomDate,
    name: name,
    ...(chartDataPeriod && { chartDataPeriod }),
    ...(customOpposingDateValid && {
      customOpposingDate: formatCustomOpposingDate,
    }),
  };
};

export const getCustomGraphDataRoasPointsData = ({
  intl,
  total,
  date,
  name,
  trendFormatSelector,
  chartDataPeriod,
  opposingDate,
}: CustomHoverGraphDataPointsData) => {
  let formatCustomDate;
  let formatCustomOpposingDate;
  if (date) {
    const requiredDateTime: DateTime = DateTime.fromFormat(
      date,
      ORIGINAL_TREND_DATE_FORMAT
    );

    const requiredOpposingDateTime: DateTime = DateTime.fromFormat(
      opposingDate ?? '',
      ORIGINAL_TREND_DATE_FORMAT
    );

    if (
      trendFormatSelector === ChartTrendMetricFormatSelector.Week &&
      trendFormatSelector
    ) {
      formatCustomDate = datefromFormatWeek(
        intl,
        requiredDateTime,
        trendFormatSelector
      );
      formatCustomOpposingDate = datefromFormatWeek(
        intl,
        DateTime.fromFormat(opposingDate ?? '', ORIGINAL_TREND_DATE_FORMAT),
        trendFormatSelector
      );
    } else {
      if (trendFormatSelector) {
        formatCustomDate = datefromFormatDaysMonths(
          requiredDateTime,
          trendFormatSelector
        );
        formatCustomOpposingDate = datefromFormatDaysMonths(
          requiredOpposingDateTime,
          trendFormatSelector
        );
      }
    }
  }

  const customOpposingDateValid =
    formatCustomOpposingDate &&
    !formatCustomOpposingDate?.toLowerCase().includes('invalid');

  return {
    total: intl.formatNumber(Number(total), {
      style: 'decimal',
      minimumFractionDigits: FLOATING_POINT_PRECISION,
      maximumFractionDigits: FLOATING_POINT_PRECISION,
    }),
    customDate: formatCustomDate,
    name: name,
    ...(chartDataPeriod && { chartDataPeriod }),
    ...(customOpposingDateValid && {
      customOpposingDate: formatCustomOpposingDate,
    }),
  };
};

const CHART_TIME_TREND_FORMAT_MAP = {
  [ChartTime.Daily]: ChartTrendMetricFormatSelector.Day,
  [ChartTime.Weekly]: ChartTrendMetricFormatSelector.Week,
  [ChartTime.Monthly]: ChartTrendMetricFormatSelector.Month,
};

export const getGraphDataPoints = ({
  intl,
  selectedCurrency,
  xAxisLabels,
  dataGraphPoints,
  chartsTabs,
  name,
  isCurrent = false,
  compassState,
  opposingDataGraphPoints,
}: GraphDataPointsPeriodType) => {
  if (compassState !== CompassState.Success) {
    return [];
  }

  const dataGraphPointsDataMap = new Array(xAxisLabels.length).fill({});
  if (dataGraphPoints) {
    let dataItem = dataGraphPoints[OverviewTimePeriod[chartsTabs]];
    let opposingDataItem =
      opposingDataGraphPoints?.[OverviewTimePeriod[chartsTabs]];

    dataItem?.forEach((graphPoint: GraphDataPoints, index) => {
      if (xAxisLabels.length > index) {
        dataGraphPointsDataMap[index] = {
          y: getYAxisPointValue(index, graphPoint, isCurrent),
          custom: getCustomGraphDataPointsData({
            intl,
            total: graphPoint.totalValue || '',
            selectedCurrency,
            date: graphPoint.date,
            name: name || '',
            trendFormatSelector: CHART_TIME_TREND_FORMAT_MAP[chartsTabs],
            chartDataPeriod: isCurrent
              ? AggregationPeriodType.Current
              : AggregationPeriodType.Previous,
            opposingDate: opposingDataItem?.[index]?.date,
          }),
        };
      }
    });
  }
  return dataGraphPointsDataMap.reverse();
};

export const getYAxisPointValue = (
  index: number,
  graphPoint: GraphDataPoints,
  isCurrent: boolean,
  convertToPercent?: boolean
) =>
  (index >= 0 || !isCurrent) && !isNil(graphPoint.totalValue)
    ? parseFloat(graphPoint.totalValue) * (convertToPercent ? 100 : 1)
    : undefined;

export const getGatheringDataPoints = ({
  intl,
  selectedCurrency,
  xAxisLabels,
  dataGraphPoints,
  chartsTabs,
  name,
  opposingDataGraphPoints,
}: GraphDataPointsPeriodType) => {
  let dataGraphPointsDataMap = new Array(xAxisLabels.length).fill({});
  let prevChartDataPoint: string | undefined = undefined;
  let i: number = -1;
  const chartData = dataGraphPoints
    ? dataGraphPoints[OverviewTimePeriod[chartsTabs]]
    : [];
  const opposingChartData = opposingDataGraphPoints
    ? opposingDataGraphPoints[OverviewTimePeriod[chartsTabs]]
    : [];
  const len = chartData?.length ?? 0;
  if (chartData) {
    for (i = 0; i < len; i++) {
      if (!isNil(chartData[i].totalValue)) {
        prevChartDataPoint = chartData[i].totalValue;
        break;
      }
    }
  }

  chartData &&
    chartData.forEach((graphPoint: GraphDataPoints, index) => {
      dataGraphPointsDataMap[index] = {
        y:
          index < i + 1
            ? prevChartDataPoint && parseFloat(prevChartDataPoint)
            : undefined,
        custom: getCustomGraphDataPointsData({
          intl,
          total: graphPoint.totalValue || '',
          selectedCurrency,
          date: graphPoint.date,
          name: name || '',
          trendFormatSelector: CHART_TIME_TREND_FORMAT_MAP[chartsTabs],
          opposingDate: opposingChartData?.[index]?.date,
        }),
      };
    });

  return dataGraphPointsDataMap.reverse();
};

export const getGraphDataPointsTacos = ({
  intl,
  selectedCurrency,
  xAxisLabels,
  dataGraphPoints,
  chartsTabs,
  name,
  trendFormatSelector,
  isCurrent = false,
  opposingDataGraphPoints,
}: GraphDataPointsPeriodType) => {
  let dataGraphPointsDataMap = new Array(xAxisLabels.length).fill({});
  if (dataGraphPoints) {
    let dataItem = dataGraphPoints[OverviewTimePeriod[chartsTabs]];
    let opposingDataItem =
      opposingDataGraphPoints?.[OverviewTimePeriod[chartsTabs]];
    dataItem?.forEach((graphPoint: GraphDataPoints, index) => {
      if (xAxisLabels.length > index) {
        dataGraphPointsDataMap[index] = {
          y: getYAxisPointValue(index, graphPoint, isCurrent, true),
          custom: getCustomGraphDataTacosPointsData({
            intl,
            total: graphPoint.totalValue || '',
            selectedCurrency,
            date: graphPoint.date,
            name,
            trendFormatSelector,
            chartDataPeriod: isCurrent
              ? AggregationPeriodType.Current
              : AggregationPeriodType.Previous,
            opposingDate: opposingDataItem?.[index]?.date,
          }),
        };
      }
    });
  }
  return dataGraphPointsDataMap.reverse();
};

export const getGatheringDataForTacos = ({
  intl,
  selectedCurrency,
  xAxisLabels,
  dataGraphPoints,
  chartsTabs,
  name,
  trendFormatSelector,
  opposingDataGraphPoints,
}: GraphDataPointsPeriodType) => {
  let dataGraphPointsDataMap = new Array(xAxisLabels.length).fill({});
  let prevChartDataPoint: string | undefined = undefined;
  let i: number = -1;
  const chartData = getGraphDataPointsForTimePeriod(
    chartsTabs,
    dataGraphPoints
  );
  const len = chartData?.length ?? 0;
  if (chartData) {
    for (i = 0; i < len; i++) {
      if (!isNil(chartData[i].totalValue)) {
        prevChartDataPoint = chartData[i].totalValue;
        break;
      }
    }
  }

  if (dataGraphPoints) {
    let dataItem = dataGraphPoints[OverviewTimePeriod[chartsTabs]];
    let opposingDataItem =
      opposingDataGraphPoints?.[OverviewTimePeriod[chartsTabs]];

    dataItem?.forEach((graphPoint: GraphDataPoints, index) => {
      if (xAxisLabels.length > index) {
        dataGraphPointsDataMap[index] = {
          y: getChartDataPoint(index, i, prevChartDataPoint),
          custom: getCustomGraphDataTacosPointsData({
            intl,
            total: graphPoint.totalValue || '',
            selectedCurrency,
            date: graphPoint.date,
            name,
            trendFormatSelector,
            opposingDate: opposingDataItem?.[index]?.date,
          }),
        };
      }
    });
  }
  return dataGraphPointsDataMap.reverse();
};

export const getGraphDataPointsForRoas = ({
  intl,
  dataType,
  selectedCurrency,
  xAxisLabels,
  dataGraphPoints,
  chartsTabs,
  name,
  trendFormatSelector,
  isCurrent = false,
  opposingDataGraphPoints,
}: GraphDataPointsPeriodType) => {
  let dataGraphPointsDataMap = new Array(xAxisLabels.length).fill({});
  if (dataGraphPoints) {
    let dataItem = dataGraphPoints[OverviewTimePeriod[chartsTabs]];
    let opposingDataItem =
      opposingDataGraphPoints?.[OverviewTimePeriod[chartsTabs]];

    dataItem?.forEach((graphPoint: GraphDataPoints, index) => {
      if (xAxisLabels.length > index) {
        dataGraphPointsDataMap[index] = {
          y: getYAxisPointValue(index, graphPoint, isCurrent),
          custom: getCustomGraphDataRoasPointsData({
            intl,
            total: graphPoint.totalValue || '',
            selectedCurrency,
            date: graphPoint.date,
            name,
            trendFormatSelector,
            chartDataPeriod: dataType,
            opposingDate: opposingDataItem?.[index]?.date,
          }),
        };
      }
    });
  }
  return dataGraphPointsDataMap.reverse();
};

export const getGatheringDataForRoas = ({
  intl,
  selectedCurrency,
  xAxisLabels,
  dataGraphPoints,
  chartsTabs,
  name,
  trendFormatSelector,
  opposingDataGraphPoints,
}: GraphDataPointsPeriodType) => {
  let dataGraphPointsDataMap = new Array(xAxisLabels.length).fill({});
  let prevChartDataPoint: string | undefined = undefined;
  let i: number = -1;
  const chartData = getGraphDataPointsForTimePeriod(
    chartsTabs,
    dataGraphPoints
  );
  const len = chartData?.length ?? 0;
  if (chartData) {
    for (i = 0; i < len; i++) {
      if (!isNil(chartData[i].totalValue)) {
        prevChartDataPoint = chartData[i].totalValue;
        break;
      }
    }
  }

  if (dataGraphPoints) {
    let dataItem = dataGraphPoints[OverviewTimePeriod[chartsTabs]];
    let opposingDataItem =
      opposingDataGraphPoints?.[OverviewTimePeriod[chartsTabs]];
    dataItem?.forEach((graphPoint: GraphDataPoints, index) => {
      if (xAxisLabels.length > index) {
        dataGraphPointsDataMap[index] = {
          y: getChartDataPoint(index, i, prevChartDataPoint),
          custom: getCustomGraphDataRoasPointsData({
            intl,
            total: graphPoint.totalValue || '',
            selectedCurrency,
            date: graphPoint.date,
            name,
            trendFormatSelector,
            opposingDate: opposingDataItem?.[index]?.date,
          }),
        };
      }
    });
  }
  return dataGraphPointsDataMap.reverse();
};

export const getHeader = (intl: IntlShape) => {
  return intl.formatMessage({
    id: I18nKey.COMPASS_DASHBOARD_CHART_OVERVIEW,
  });
};

export const getRightSideLegends = (
  intl: IntlShape,
  accountEventPoints: AccountEventPoint[],
  markerPointsDataConnected: number[],
  nullDataPoints: NullPoints[] = []
) => {
  const legends: LegendProps[] = [];

  if (markerPointsDataConnected.length) {
    legends.push({
      label: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_DATA_CONNECTED,
      }),
      icon: DataIntegratedIcon,
      associatedMarkerType: MarkerType.DataConnect,
      dataTestId: `1com_DataConnected_legend`,
    });
  }

  if (accountEventPoints.find((event) => event.type === AiActivityType.Added)) {
    legends.push({
      label: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_ACTIVATED_AI,
      }),
      icon: AIEnabledIcon,
      associatedMarkerType: MarkerType.ActivatedAI,
      dataTestId: `1com__AIActivated_legend`,
    });
  }

  if (
    accountEventPoints.find((event) => event.type === AiActivityType.Removed)
  ) {
    legends.push({
      label: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_DEACTIVATED_AI,
      }),
      icon: AIDisabledIcon,
      associatedMarkerType: MarkerType.DeactivatedAI,
      dataTestId: `1com_AIDeactivated_legend`,
    });
  }

  if (nullDataPoints.length) {
    legends.push({
      label: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_CHART_LEGEND_NO_DATA,
      }),
      icon: NoDataIcon,
      associatedMarkerType: MarkerType.NoData,
      dataTestId: `1com_NoData_legend`,
    });
  }

  return legends;
};

export const getChartMetricsData = ({
  intl,
  data,
  selectedDateRangeDiffInDays,
  selectedCurrency,
  xAxisLabels,
  trendFormatSelector,
  chartsTabs,
  compassState,
  metricTabsData,
}: LeftSideLegendGraphData): any[] => {
  return getGraphPoint({
    intl,
    data,
    selectedDateRangeDiffInDays,
    selectedCurrency,
    xAxisLabels,
    trendFormatSelector,
    chartsTabs,
    compassState,
    metricTabsData,
  });
};

export const transformDataPointDatesToRequiredDateFormat = ({
  intl,
  trendFormatSelector,
  chartsTabs,
  current,
  previous,
  subscriptionData,
  pathParam,
  selectedDateRange,
}: TransformDataPointsParams): {
  formattedXAxisLabels: string[] | number[];
  accountEventPoints: AccountEventPoint[];
  bandPoints: PlotBandPoints[];
} => {
  let currentDataPoint;
  let previousDataPoint;
  currentDataPoint = current && current[OverviewTimePeriod[chartsTabs]];
  previousDataPoint = previous && previous[OverviewTimePeriod[chartsTabs]];
  return transformDataPointDatesToRequiredXAxisLabelFormat({
    intl,
    trendFormatSelector,
    currentDataPoint,
    previousDataPoint,
    chartsTabs,
    subscriptionData,
    pathParam,
    selectedDateRange,
  });
};

export const getXAxisLabels = (
  intl: IntlShape,
  metricResponse: HomepageDataRequestResponse,
  trendFormatSelector: ChartTrendMetricFormatSelector,
  chartsTabs: ChartTime,
  subscriptionData: ProductSubscriptionEventResponse,
  compassState: CompassState,
  selectedDateRange: DateRange,
  addExtraSpaceOnLeft?: boolean
) => {
  if (compassState !== CompassState.Success) {
    return {};
  }
  const respectiveMetricKeyInResponse =
    MAP_COMPASS_PATH_PARAMS_TO_HOMEPAGE_RESPONSE_METRIC_KEYS[
      CompassPathParams.TotalSales
    ];
  const dateTimeFormat = transformDataPointDatesToRequiredDateFormat({
    intl,
    trendFormatSelector,
    chartsTabs,
    current:
      metricResponse.current[respectiveMetricKeyInResponse]?.graphDataPoints,
    previous:
      metricResponse.previous[respectiveMetricKeyInResponse]?.graphDataPoints,
    subscriptionData,
    pathParam: CompassPathParams.TotalSales,
    selectedDateRange,
  });
  const xAxisLabelsWithExtraSpace =
    arrayRemoveDuplicate(dateTimeFormat?.formattedXAxisLabels as string[])
      .concat([' '])
      .reverse() || [];

  return {
    xAxisDataPoints: addExtraSpaceOnLeft
      ? xAxisLabelsWithExtraSpace
      : arrayRemoveDuplicate(
          dateTimeFormat?.formattedXAxisLabels as string[]
        ).reverse() || [],
    aiActivityBandPoints: dateTimeFormat?.bandPoints || [],
    markerPointsDataConnected: [],
  };
};

export const datefromFormatWeek = (
  intl: IntlShape,
  requiredDateTime: DateTime,
  formatSelector: ChartTrendMetricFormatSelector
) => {
  if (
    requiredDateTime.weekdayLong !== 'Monday' &&
    requiredDateTime.weekdayLong !== 'Tuesday'
  ) {
    return '';
  }

  return intl.formatMessage(
    { id: I18nKey.COMPASS_DASHBOARD_CHART_MINI_METRIC_WEEKLY_DATE_FORMAT },
    {
      startDateOfWeek: requiredDateTime
        .startOf('week')
        .toFormat(
          MAP_CHART_TREND_METRIC_FORMAT_SELECTOR_TO_DATE_TIME_READABLE_FORMAT_SELECTOR[
            formatSelector
          ]
        ),
      endDateOfWeek: requiredDateTime
        .endOf('week')
        .toFormat(
          MAP_CHART_TREND_METRIC_FORMAT_SELECTOR_TO_DATE_TIME_READABLE_FORMAT_SELECTOR[
            formatSelector
          ]
        ),
    }
  );
};

export const datefromFormatDaysMonths = (
  requiredDateTime: DateTime,
  formatSelector: ChartTrendMetricFormatSelector
) =>
  requiredDateTime.toFormat(
    MAP_CHART_TREND_METRIC_FORMAT_SELECTOR_TO_DATE_TIME_READABLE_FORMAT_SELECTOR[
      formatSelector
    ]
  );

export const getDateForMiniMetricProp = (
  intl: IntlShape,
  value: string | undefined,
  formatSelector: ChartTrendMetricFormatSelector
): string => {
  if (value) {
    const requiredDateTime: DateTime = DateTime.fromFormat(
      value,
      ORIGINAL_TREND_DATE_FORMAT
    );
    if (formatSelector === ChartTrendMetricFormatSelector.Week) {
      return datefromFormatWeek(intl, requiredDateTime, formatSelector);
    }
    return datefromFormatDaysMonths(requiredDateTime, formatSelector);
  } else {
    return '';
  }
};

/**
 * Format selector for the overview, the xAxisLabels and also tooltips.
 **/
export const getChartTrendFormatSelector = (
  diffInDays: number
): ChartTrendMetricFormatSelector => {
  /**
   * Removing the logic to formatSelector as we decide to show only day
   * Previous logic in below. Once a decision is taken which timeframes to show
   * Update this logic accordingly.
   */
  if (diffInDays <= 90) {
    return ChartTrendMetricFormatSelector.Day;
  } else {
    return ChartTrendMetricFormatSelector.Week;
  }
};

/**
 * @param diffInDays [number]
 * @returns [ToggleTab]
 */
export const getChartTimeTabOptions = (
  intl: IntlShape,
  diffInDays: number
): ToggleTab[] => {
  /**
   * Removing other options as mentioned in the comments of the function
   * getChartTrendFormatSelector
   */
  const CHART_TAB_DAILY_OPTION = {
    theme: ToggleTabTheme.Purple100,
    nameI18nKey: intl.formatMessage({
      id: I18nKey.COMPASS_CHART_CONTEXT_DAILY,
    }),
  };
  const CHART_TAB_WEEKLY_OPTION = {
    theme: ToggleTabTheme.Purple100,
    nameI18nKey: intl.formatMessage({
      id: I18nKey.COMPASS_CHART_CONTEXT_WEEKLY,
    }),
  };

  const CHART_TAB_MONTHLY_OPTION = {
    theme: ToggleTabTheme.Purple100,
    nameI18nKey: intl.formatMessage({
      id: I18nKey.COMPASS_CHART_CONTEXT_MONTHLY,
    }),
  };
  if (diffInDays <= 21) {
    return [CHART_TAB_DAILY_OPTION];
  } else if (diffInDays > 21 && diffInDays <= 90) {
    return [CHART_TAB_DAILY_OPTION, CHART_TAB_WEEKLY_OPTION];
  } else {
    return [CHART_TAB_WEEKLY_OPTION, CHART_TAB_MONTHLY_OPTION];
  }
};

export const maybeRoundOfDate = (
  isEntityTacosMonthly: boolean,
  isEntityTacosWeekly: boolean,
  date: string
) => {
  if (isEntityTacosMonthly) {
    return DateTime.fromISO(date, { zone: 'utc' })
      .startOf('month')

      .toFormat(COMPASS_API_REQUEST_DATE_FORMAT);
  } else if (isEntityTacosWeekly) {
    return DateTime.fromISO(date, { zone: 'utc' })
      .startOf('week')

      .toFormat(COMPASS_API_REQUEST_DATE_FORMAT);
  } else {
    return DateTime.fromISO(date, { zone: 'utc' }).toFormat(
      COMPASS_API_REQUEST_DATE_FORMAT
    );
  }
};

const getMarkerRelativePosition = (
  isMonthly: boolean,
  markerDate: string,
  indexDate: string
) =>
  +(
    DateTime.fromISO(markerDate).diff(DateTime.fromISO(indexDate), 'days')
      .days / (isMonthly ? 30 : 7)
  ).toFixed(isMonthly ? 2 : 3);

const getReadableDateString = (date: string) =>
  DateTime.fromISO(date).toFormat('D MMM Y');

const getFormattedXAxisLabels = ({
  intl,
  trendFormatSelector,
  dataPoint,
  subscriptionData,
  pathParam,
  selectedDateRange,
}: {
  intl: IntlShape;
  trendFormatSelector: ChartTrendMetricFormatSelector;
  dataPoint: GraphDataPoints[];
  subscriptionData?: ProductSubscriptionEventResponse;
  pathParam?: CompassPathParams;
  selectedDateRange?: DateRange;
}) => {
  /**
   * Logic to skip random xAxisLabels to facilitate daily for all date ranges.
   * NOTE: 30 xAxis Labels looks clean, any amount of labels above trim it to
   * 30 labels at max.
   */
  const pointsToSkip = Math.ceil(dataPoint.length / 30);
  const formattedXAxisLabels: string[] = [];
  dataPoint.forEach((c: GraphDataPoints, index) =>
    getFormattedXAxisLabelsHelper(
      intl,
      c,
      index,
      formattedXAxisLabels,
      pointsToSkip,
      trendFormatSelector
    )
  );
  const currentDataPointDate: string[] = [];

  const isMonthly =
    trendFormatSelector === ChartTrendMetricFormatSelector.Month;
  const isWeekly = trendFormatSelector === ChartTrendMetricFormatSelector.Week;

  const accountEvents = getAccountEventsOrEmptyArray(
    subscriptionData?.accountEvents
  )
    .filter((event) => !!event.date)
    .map((event) => {
      const roundOffDate = maybeRoundOfDate(isMonthly, isWeekly, event.date);
      return {
        actualDate: event.date,
        action: event.action,
        roundOffDate: roundOffDate,
      };
    });
  let eventPoints: AccountEventPoint[] = [];

  dataPoint.forEach((c: GraphDataPoints) => {
    const dateLabels: string = getDateForMiniMetricProp(
      intl,
      c.date,
      trendFormatSelector
    );
    currentDataPointDate.push(dateLabels);

    const filteredEvents = accountEvents.filter((event) =>
      getFilteredEvents(event, c, selectedDateRange)
    );

    if (c.date && filteredEvents.length) {
      filteredEvents.forEach((event) => {
        eventPoints.push({
          type: getEventActionType(event.action),
          date: event.actualDate,
          position:
            dataPoint.length -
            currentDataPointDate.length +
            getMarkerRelativePosition(isMonthly, event.actualDate, c.date!),
        });
      });
    }
  });
  let eventsMap: {
    [position: number]: AccountEventPoint[];
  } = {};
  eventPoints.forEach((point) => {
    if (!eventsMap[point.position]) {
      eventsMap[point.position] = [point];
    } else {
      eventsMap[point.position].push(point);
    }
  });
  let filteredEvents: AccountEventPoint[] = [];
  Object.keys(eventsMap).forEach((position: string) =>
    pushFilteredEvents(eventsMap, filteredEvents, position)
  );

  filteredEvents = sortBy(filteredEvents, 'position');
  let bandPoints: PlotBandPoints[] = [];
  if (first(filteredEvents)?.type === AiActivityType.Removed) {
    const fromIndex = sortBy(accountEvents, 'actualDate').findIndex(
      (e: any) => first(filteredEvents)!.date === e.actualDate
    );
    bandPoints.push({
      type: PlotBandType.AiActivity,
      from: -Infinity,
      to: first(filteredEvents)?.position,
      size: formattedXAxisLabels.length,
      fromReadableString: getPlotPointLabel(fromIndex, accountEvents),
      toReadableString: getReadableDateString(first(filteredEvents)!.date),
    });
  } else if (first(filteredEvents)?.type === AiActivityType.Added) {
    bandPoints.push({
      type: PlotBandType.AiActivity,
      from: first(filteredEvents)!.position,
      to: filteredEvents[1]?.position || undefined,
      size: formattedXAxisLabels.length,
      fromReadableString: getReadableDateString(first(filteredEvents)!.date),
      toReadableString: getReadableDate(filteredEvents),
    });
  }

  for (let i = 1; i < filteredEvents.length - 1; i++) {
    if (getIsAIPlotBand(filteredEvents, i)) {
      bandPoints.push({
        type: PlotBandType.AiActivity,
        from: filteredEvents[i - 1].position,
        to: filteredEvents[i].position,
        size: formattedXAxisLabels.length,
        fromReadableString: getReadableDateString(filteredEvents[i - 1].date),
        toReadableString: getReadableDateString(filteredEvents[i].date),
      });
    }
  }

  if (isAIActivityTypeEqual(filteredEvents, AiActivityType.Added)) {
    bandPoints.push({
      type: PlotBandType.AiActivity,
      from: last(filteredEvents)?.position,
      to: Infinity,
      size: formattedXAxisLabels.length,
      fromReadableString: getReadableDateString(last(filteredEvents)!.date),
    });
  } else if (isAIActivityTypeEqual(filteredEvents, AiActivityType.Removed)) {
    bandPoints.push({
      type: PlotBandType.AiActivity,
      from: filteredEvents[filteredEvents.length - 2]?.position || undefined,
      to: last(filteredEvents)?.position,
      size: formattedXAxisLabels.length,
      fromReadableString: getReadableDateForAIRemoved(filteredEvents),
      toReadableString: getReadableDateString(last(filteredEvents)!.date),
    });
  }
  return {
    formattedXAxisLabels,
    accountEventPoints: filteredEvents,
    bandPoints,
  };
};

const getXAxisLabelsDataPoint = (currentDataPoint?: GraphDataPoints[]) => {
  return currentDataPoint ? currentDataPoint : [];
};

const transformDataPointDatesToRequiredXAxisLabelFormat = ({
  intl,
  trendFormatSelector,
  currentDataPoint,
  previousDataPoint,
  chartsTabs,
  subscriptionData,
  pathParam,
  selectedDateRange,
}: {
  intl: IntlShape;
  trendFormatSelector: ChartTrendMetricFormatSelector;
  currentDataPoint?: GraphDataPoints[];
  previousDataPoint?: GraphDataPoints[];
  chartsTabs: ChartTime;
  subscriptionData?: ProductSubscriptionEventResponse;
  pathParam?: CompassPathParams;
  selectedDateRange?: DateRange;
}) => {
  const dataPoint: GraphDataPoints[] =
    getXAxisLabelsDataPoint(currentDataPoint);

  if (chartsTabs === ChartTime.Weekly) {
    if (dataPoint) {
      return getFormattedXAxisLabels({
        intl,
        trendFormatSelector,
        dataPoint,
        subscriptionData,
        pathParam,
        selectedDateRange,
      });
    }
  }
  if (chartsTabs === ChartTime.Daily) {
    if (dataPoint) {
      return getFormattedXAxisLabels({
        intl,
        trendFormatSelector,
        dataPoint,
        subscriptionData,
        pathParam,
        selectedDateRange,
      });
    }
  }
  if (chartsTabs === ChartTime.Monthly) {
    if (dataPoint) {
      return getFormattedXAxisLabels({
        intl,
        trendFormatSelector,
        dataPoint,
        subscriptionData,
        pathParam,
        selectedDateRange,
      });
    }
  }
  return {
    formattedXAxisLabels: [],
    accountEventPoints: [],
    bandPoints: [],
  };
};

export const isAllPerformanceMetricsEmpty = (data: HomePageData) => {
  if (data.performanceMetrics) {
    for (let key in data.performanceMetrics) {
      let keyTypeCast = key as HomepageResponseMetricKeys;
      if (data?.performanceMetrics?.[keyTypeCast] && key !== 'range') {
        return false;
      }
    }
  }
  return true;
};

export const isDataConnectedForAllSelectedMerchants = (
  selectedMerchants: string[],
  aoDataAvailabilityInfo: MerchantSyncDate[],
  skuDataAvailabilityInfo: MerchantSyncDate[],
  salesChannelData: AccountSalesChannelPaginatedResultWithError[] = []
) => {
  const selectedMerchantsData = salesChannelData.map((data) => {
    const items = data.items.filter((item) => {
      return selectedMerchants.includes(item.id);
    });

    return {
      items,
      salesChannel: data.salesChannel,
    };
  });

  const connectionData = getAdsAndProductDataConnectionStatusDataForSetup(
    aoDataAvailabilityInfo,
    skuDataAvailabilityInfo,
    selectedMerchantsData as AccountSalesChannelPaginatedResultWithError[]
  );

  return [
    ...connectionData[FlywheelSalesChannel.Amazon].syncingData,
    ...connectionData[FlywheelSalesChannel.Walmart].syncingData,
  ].every((item) => {
    return (
      item.adsDataConnectionStatus === ConnectionStatus.Connected &&
      item.productsDataConnectionStatus === ConnectionStatus.Connected
    );
  });
};

export const isTabChartsMetricsEmpty = (
  data: HomePageData,
  pathParam: CompassPathParams,
  trendFormatSelector: ChartTrendMetricFormatSelector
) => {
  const respectiveMetricKeyInResponse =
    MAP_COMPASS_PATH_PARAMS_TO_HOMEPAGE_RESPONSE_METRIC_KEYS[pathParam];
  if (data) {
    if (!data.performanceMetrics?.[respectiveMetricKeyInResponse]) {
      return true;
    }
  }
  if (pathParam === CompassPathParams.Tacos) {
    const currentOverviewTimePeriod: OverviewTimePeriod =
      MAP_CHART_TREND_METRIC_TO_OVERVIEW_TIME_PERIOD[trendFormatSelector];
    const graphDataPoints: GraphDataPoints[] | undefined =
      data[respectiveMetricKeyInResponse]?.graphDataPoints?.[
        currentOverviewTimePeriod
      ];
    if (graphDataPoints) {
      for (let i = 0; i < graphDataPoints?.length; i++) {
        if (
          graphDataPoints[i].totalValue ||
          graphDataPoints[i].adSpend ||
          graphDataPoints[i].totalSales
        ) {
          return false;
        }
      }
    }
    return true;
  } else {
    return isEmptyChartsDaily(data, respectiveMetricKeyInResponse);
  }
};

export const isEmptyChartsDaily = (
  data: HomePageData,
  respectiveMetricKeyInResponse: HomepageResponseMetricKeys
) => {
  const graphDataPoints: GraphDataPoints[] | undefined =
    data[respectiveMetricKeyInResponse]?.graphDataPoints?.[
      OverviewTimePeriod.Daily
    ];
  if (graphDataPoints) {
    for (let i = 0; i < graphDataPoints?.length; i++) {
      if (graphDataPoints[i].totalValue) {
        return false;
      }
    }
  }
  return true;
};

export const rotationXaxis = (ListData: string[] | number[]) => {
  if (ListData.length > 5) {
    return -45;
  }
  return 0;
};

export const arrayRemoveDuplicate = (arraySrtingDate: string[] = []) => {
  const removedDuplicateDate: string[] = [];
  arraySrtingDate.forEach((item) => {
    if (removedDuplicateDate.includes(item)) {
      removedDuplicateDate.push('');
    } else {
      removedDuplicateDate.push(item);
    }
  });
  return removedDuplicateDate;
};

export const getPlotBandPoints = (data: LeftSideLegendGraphData) => {
  const nullPlotBandPoints = getNullPoints(data);
  return [...nullPlotBandPoints];
};

export const getNullPoints = ({
  intl,
  data,
  selectedCurrency,
  xAxisLabels,
  chartsTabs,
  compassState,
}: LeftSideLegendGraphData) => {
  if (compassState === CompassState.Success) {
    const metricResponseKeyAsPerPath =
      MAP_COMPASS_PATH_PARAMS_TO_HOMEPAGE_RESPONSE_METRIC_KEYS[
        CompassPathParams.TotalSales
      ];
    const currentDataGraphPoints =
      data.current[metricResponseKeyAsPerPath]?.graphDataPoints;
    const currentPeriodPointvalue = getGraphDataPoints({
      intl,
      dataType: AggregationPeriodType.Current,
      selectedCurrency,
      chartsTabs,
      xAxisLabels,
      dataGraphPoints: currentDataGraphPoints,
    });
    const nullPointsData: Array<boolean | string> = [];
    currentPeriodPointvalue.forEach((data) => {
      if (data.y === 0 && !data.custom.previous) {
        nullPointsData.push(data.custom.customDate);
      } else {
        nullPointsData.push(false);
      }
    });
    return getNullPointFormater(nullPointsData, xAxisLabels);
  }
  return [];
};

export const getNullPointFormater = (
  nullPointsData: Array<boolean | string>,
  xAxisLabels: string[]
) => {
  const data = [];
  const nullPointStart: any = {};
  const nullPointEnd: any = {};

  if (nullPointsData[0]) {
    nullPointStart['from'] = 0;
    nullPointStart['type'] = PlotBandType.NoData;
    nullPointStart['size'] = xAxisLabels.length;
    for (let i = 1; i < nullPointsData.length; i++) {
      if (nullPointsData[i]) {
        nullPointStart['to'] = i;
      } else {
        break;
      }
    }

    setNullPointToOne(nullPointStart);

    data.push(nullPointStart as PlotBandPoints);
  }

  if (nullPointsData[nullPointsData.length - 1]) {
    nullPointEnd['to'] = xAxisLabels.length - 1;
    nullPointEnd['size'] = xAxisLabels.length;
    nullPointStart['type'] = PlotBandType.NoData;
    for (let i = nullPointsData.length - 1; 0 < i; i--) {
      if (nullPointsData[i]) {
        nullPointEnd['from'] = i;
      } else {
        break;
      }
    }
    data.push(nullPointEnd as PlotBandPoints);
  }
  return data;
};

export const showYAxisOnLeft = (kpiCards: CardsProps[] | CardsProps) => {
  if (isArray(kpiCards)) {
    return kpiCards
      .filter((kpiCard) =>
        [KpiMetric.TotalSales, KpiMetric.AdSales, KpiMetric.AdSpend].includes(
          (kpiCard.header as KpiMetric) ?? ''
        )
      )
      .some((kpiCard) => kpiCard.isActive);
  }
  return kpiCards.isActive;
};

export const showYAxisOnRight = (kpiCards: CardsProps[] | CardsProps) => {
  if (isArray(kpiCards)) {
    return kpiCards
      .filter((kpiCard) =>
        [KpiMetric.TACoS, 'ROAS'].includes((kpiCard.header as KpiMetric) ?? '')
      )
      .some((kpiCard) => kpiCard.isActive);
  }
  return kpiCards.isActive;
};

export const getYAxisFormat = (kpiCards: CardsProps[] | CardsProps) => {
  if (
    (isArray(kpiCards) &&
      kpiCards.find((kpiCard) => kpiCard.header === KpiMetric.TACoS)
        ?.isActive) ||
    (kpiCards as CardsProps).isActive
  ) {
    return NumberFormat.Percent;
  }

  return NumberFormat.Decimal;
};

export const getShowLoading = (compassState: CompassState) => {
  return (
    <div
      className="flex flex-col w-full bg-grey-50
      h-280 items-center mt-16"
    >
      <div className="mt-40 w-2/4">
        {compassState === CompassState.Loading && (
          <LoadingState
            icon={DotsCircleIcon}
            className="bg-grey-50"
            iconClassName="text-magenta-500"
            titleI18nKey={I18nKey.COMPASS_TABS_LOADING_TEXT}
            descriptionI18nKey={I18nKey.COMPASS_TABS_LOADING_ADDITIONAL_TEXT}
          />
        )}
      </div>
    </div>
  );
};

export const getEmptyMerchantsState = () => {
  const handleButtonClick = async () => {
    await redirect('/');
  };
  return (
    <div className="flex w-full h-compassOverview border border-solid border-grey-200 rounded">
      <div className="flex w-full items-center justify-center">
        <CompassEmptyState
          {...{
            titleI18nKey: I18nKey.COMPASS_CHART_EMPTY_MERCHANT_HEADER,
            icon: CompassLinearIcon,
            iconClassName: 'text-purple-500',
            showPrimaryButton: true,
            primaryButtonI18nKey: I18nKey.COMPASS_CHART_EMPTY_MERCHANT_BUTTON,
            onClickPrimaryButton: () => handleButtonClick(),
            dataTestId: '1com_business_empty_merchant',
          }}
        />
      </div>
    </div>
  );
};

export const getNoMerchantsSelectionState = () => (
  <div className="flex w-full h-compassOverview border border-solid border-grey-200 rounded">
    <div className="flex w-full items-center justify-center">
      <CompassEmptyState
        {...{
          titleI18nKey: I18nKey.NO_MERCHANTS_SELECTED,
          icon: QuestionCircleLinearIcon,
          iconClassName: 'text-grey-500',
          showPrimaryButton: false,
          descriptionI18nKey: I18nKey.MISSING_DATA_SUGGESTION,
          dataTestId: '1com_business_missing_merchant',
        }}
      />
    </div>
  </div>
);

export const getShowDataEmpty = (
  compassState: CompassState,
  userInfo: UserInfo,
  handleButtonClick: Function,
  isAllMerchantsDataConnected: boolean
) => {
  const errorCount = getCompassAPIFailCount(userInfo);

  return (
    <div className="flex flex-col w-full bg-cover h-screen items-center">
      <div className="mt-40 w-2/4">
        {compassState === CompassState.Error && !errorCount && (
          <CompassEmptyState
            {...{
              titleI18nKey: I18nKey.COMPASS_TABS_ERROR_TEXT,
              descriptionI18nKey: I18nKey.COMPASS_TABS_ERROR_ADDITIONAL_TEXT,
              icon: ChartGraphLinearIcon,
              iconClassName: 'text-purple-500',
              showPrimaryButton: true,
              primaryButtonI18nKey: I18nKey.COMPASS_TABS_ERROR_RETRY_TEXT,
              onClickPrimaryButton: () => window.location.reload(),
              dataTestId: '1com_business_try_again',
            }}
          />
        )}

        {compassState === CompassState.Error && errorCount > 0 && (
          <CompassEmptyState
            {...{
              titleI18nKey: I18nKey.COMPASS_TABS_ERROR_TEXT,
              descriptionI18nKey:
                I18nKey.COMPASS_TABS_ERROR_CONTACT_SUPPORT_DESCRIPTION,
              icon: ChartGraphLinearIcon,
              showPrimaryButton: true,
              primaryButtonI18nKey: I18nKey.COMPASS_TABS_ERROR_CONTACT_SUPPORT,
              onClickPrimaryButton: () => talkToUs(),
              dataTestId: '1com_business_contact_support',
            }}
          />
        )}

        {compassState === CompassState.NoData && (
          <>
            {isAllMerchantsDataConnected ? (
              <CompassEmptyState
                {...{
                  titleI18nKey: I18nKey.COMPASS_CHART_NO_DATA_CONNECTED_HEADER,
                  descriptionI18nKey:
                    I18nKey.COMPASS_CHART_NO_DATA_CONNECTED_DESCRIPTION,
                  icon: CompassLinearIcon,
                  iconClassName: 'text-purple-500',
                  dataTestId: '1com_business_no_data_connected',
                }}
              />
            ) : (
              <CompassEmptyState
                {...{
                  titleI18nKey: I18nKey.COMPASS_CHART_NO_DATA_HEADER,
                  descriptionI18nKey: I18nKey.COMPASS_CHART_NO_DATA_DESCRIPTION,
                  icon: CompassLinearIcon,
                  iconClassName: 'text-purple-500',
                  showPrimaryButton: true,
                  primaryButtonI18nKey:
                    I18nKey.COMPASS_CHART_NO_DATA_CONNECT_PRODUCT,
                  onClickPrimaryButton: () => handleButtonClick(),
                  dataTestId: '1com_business_no_data',
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const getChartDataPoint = (
  index: number,
  i: number,
  prevChartDataPoint?: string
) =>
  index < i + 1
    ? !isNil(prevChartDataPoint) && parseFloat(prevChartDataPoint)
    : undefined;

export const getGraphDataPointsForTimePeriod = (
  chartsTabs: ChartTime,
  dataGraphPoints?: TimePeriodGraphDataPoints
) => (dataGraphPoints ? dataGraphPoints[OverviewTimePeriod[chartsTabs]] : []);

export const getFormattedXAxisLabelsHelper = (
  intl: IntlShape,
  graphDataPoints: GraphDataPoints,
  index: number,
  formattedXAxisLabels: string[],
  pointsToSkip: number,
  trendFormatSelector: ChartTrendMetricFormatSelector
) => {
  const xAxisLabel: string = getDateForMiniMetricProp(
    intl,
    graphDataPoints.date,
    trendFormatSelector
  );

  if (pointsToSkip > 1) {
    formattedXAxisLabels.push(index % pointsToSkip === 0 ? xAxisLabel : '');
  } else {
    formattedXAxisLabels.push(xAxisLabel);
  }
};

export const getFilteredEvents = (
  event: AccountEvents,
  graphDataPoints: GraphDataPoints,
  selectedDateRange?: DateRange
) =>
  event.roundOffDate === graphDataPoints.date &&
  (!selectedDateRange ||
    (selectedDateRange?.startDate &&
      selectedDateRange?.endDate &&
      selectedDateRange?.startDate < DateTime.fromISO(event.actualDate) &&
      selectedDateRange?.endDate > DateTime.fromISO(event.actualDate)));

export const getEventActionType = (eventAction: SubscriptionActionType) =>
  eventAction === SubscriptionActionType.Created
    ? AiActivityType.Added
    : AiActivityType.Removed;

export const pushFilteredEvents = (
  eventsMap: {
    [position: number]: AccountEventPoint[];
  },
  filteredEvents: AccountEventPoint[],
  position: string
) => {
  if (eventsMap[+position].length === 1) {
    filteredEvents.push(first(eventsMap[+position])!);
  } else if (
    !!first(eventsMap[+position]) &&
    first(eventsMap[+position])?.type !== last(eventsMap[+position])?.type
  ) {
    filteredEvents.push(
      first(eventsMap[+position])!,
      last(eventsMap[+position])!
    );
  }
};

export const getPlotPointLabel = (
  fromIndex: number,
  accountEvents: AccountEvents[]
) =>
  fromIndex > 0
    ? getReadableDateString(accountEvents[fromIndex - 1]!.actualDate)
    : undefined;

export const getReadableDate = (filteredEvents: AccountEventPoint[]) =>
  filteredEvents[1]?.date
    ? getReadableDateString(filteredEvents[1].date)
    : undefined;

export const getReadableDateForAIRemoved = (
  filteredEvents: AccountEventPoint[]
) =>
  filteredEvents[filteredEvents.length - 2]?.date
    ? getReadableDateString(last(filteredEvents)!.date)
    : undefined;

export const setNullPointToOne = (nullPointStart: any) => {
  if (!nullPointStart['to']) {
    nullPointStart['to'] = 1;
  }
};

export const getAccountEventsOrEmptyArray = (accountEvents?: AccountEvent[]) =>
  accountEvents || [];

export const getIsAIPlotBand = (
  filteredEvents: AccountEventPoint[],
  i: number
) =>
  filteredEvents[i].type === AiActivityType.Removed &&
  filteredEvents[i - 1].type === AiActivityType.Added &&
  filteredEvents[i].position !== filteredEvents[i - 1].position;

export const isAIActivityTypeEqual = (
  filteredEvents: AccountEventPoint[],
  activityType: AiActivityType
) => last(filteredEvents)?.type === activityType;

export const getHighestMetricSummary = (
  adSalesOverviewSummary?: OverviewSummary,
  adSpendOverviewSummary?: OverviewSummary
): MetricSummary => ({
  timePeriod: adSalesOverviewSummary?.highest?.timePeriod ?? '',
  value: `${
    parseFloat(adSalesOverviewSummary?.highest?.value ?? '0') /
    parseFloat(adSpendOverviewSummary?.highest?.value ?? '1')
  }`,
});

export const getLowestMetricSummary = (
  adSalesOverviewSummary?: OverviewSummary,
  adSpendOverviewSummary?: OverviewSummary
): MetricSummary => ({
  timePeriod: adSalesOverviewSummary?.lowest?.timePeriod ?? '',
  value: `${
    parseFloat(adSalesOverviewSummary?.lowest?.value ?? '0') /
    parseFloat(adSpendOverviewSummary?.lowest?.value ?? '1')
  }`,
});

export const calculateDataPointForRoas =
  (adSpends: GraphDataPoints[]) => (adSale: GraphDataPoints, index: number) => {
    const adSpend = adSpends[index];
    const totalValue = `${
      parseFloat(adSale?.totalValue ?? '0') /
      parseFloat(adSpend?.totalValue ?? '1')
    }`;
    return {
      totalValue,
      channels: adSale.channels,
      date: adSale.date,
    };
  };

export const modifyPerformanceMetrics = (
  performanceMetrics?: PerformanceMetrics
): PerformanceMetrics => {
  let roasValue =
    parseFloat(performanceMetrics?.adSales?.amount ?? '0') /
    parseFloat(performanceMetrics?.adSpend?.amount ?? '1');
  return {
    ...performanceMetrics,
    roas: isNaN(roasValue) ? undefined : roasValue,
  };
};

const getConnectionStatus = (
  salesChannelData: AccountSalesChannelPaginatedResultWithError[]
) => {
  const amazonData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Amazon
  ) ?? { items: [] };

  const walmartData = salesChannelData?.find(
    (channelData) => channelData.salesChannel === FlywheelSalesChannel.Walmart
  ) ?? { items: [] };

  const adsConnected = getAdConnectionStatus([
    ...amazonData.items,
    ...walmartData.items,
  ]);

  const productsConnected = getProductConnectionStatus([
    ...amazonData.items,
    ...walmartData.items,
  ]);
  const connection: SalesChannelConnectionResponse = {
    ads: adsConnected,
    products: productsConnected,
  };

  return connection;
};

export const getI18nKeyForEmptyState = (
  salesChannelData: AccountSalesChannelPaginatedResultWithError[]
) => {
  const connectionStatus = getConnectionStatus(salesChannelData);

  if (
    connectionStatus.ads !== SalesChannelConnectionStatus.NONE &&
    connectionStatus.products === SalesChannelConnectionStatus.NONE
  ) {
    return {
      titleI18nKey: I18nKey.COMPASS_CHART_NO_DATA_HEADER,
      descriptionI18nKey: I18nKey.COMPASS_CHART_NO_DATA_DESCRIPTION,
    };
  }

  return {
    titleI18nKey: I18nKey.COMPASS_FLYWHEEL_NO_DATA_HEADLINE,
    descriptionI18nKey: I18nKey.COMPASS_FLYWHEEL_NO_DATA_DESCRIPTION,
  };
};

const getEventLogsPointData = (
  xPoint: number,
  bucket: SeasonalEvent[],
  date: DateTime,
  intl: IntlShape,
  isDailyEvents?: boolean
) => ({
  x: xPoint,
  countText: bucket.length > 9 ? NINE_PLUS : String(bucket.length),
  tooltip: {
    categories: { 'Seasonal Event': bucket.map((e) => e.name) },
    dateRange: {
      startDate: isDailyEvents ? date : date.startOf('week'),
      endDate: isDailyEvents ? undefined : date.endOf('week'),
    },
    secondryTooltipContent: intl.formatMessage({
      id: I18nKey.PRODUCTS_EVENT_LOG_SECONDRY_POPUP_TEXT,
    }),
  },
});

const getLogsPlotPointsForMonthlyChart = (
  weeklyBuckets: SeasonalEvent[][],
  startDate: DateTime,
  intl: IntlShape
) => {
  const eventLogs: EventLogPoint[] = [];

  weeklyBuckets.forEach((bucket) => {
    const firstEvent = bucket[0];

    const firstDate = DateTime.fromISO(firstEvent.eventDate);
    const firstDayOfWeek = firstDate?.startOf('week');
    const numDays = DateTime.local(firstDayOfWeek.year, firstDayOfWeek.month, 1)
      .daysInMonth as PossibleDaysInMonth;

    const diffMonths = Math.floor(
      firstDayOfWeek.startOf('month').diff(startDate.startOf('month'), 'months')
        .months
    );

    //(1 / (numDays - 1)) calculates a fraction that represents the distance between each day on the x-axis of the graph. Since there are numDays days in the month, but only numDays - 1 spaces between them, we need to divide by numDays - 1 to get the width of each space.
    //(firstDayOfWeek.day - 1) calculates the number of spaces from the left edge of the graph to the first date. We subtract 1 because the first day of the month is at position 0, not position 1.
    //Finally, we add diffMonths to the number of spaces from the left edge to get the final x-coordinate of the point.

    const xPoint = diffMonths + (1 / (numDays - 1)) * (firstDayOfWeek.day - 1);

    eventLogs.push(
      getEventLogsPointData(xPoint, bucket, firstDate, intl, false)
    );
  });

  return eventLogs;
};

const getLogsPlotPointsForWeeklyChart = (
  weeklyBuckets: SeasonalEvent[][],
  startDate: DateTime,
  intl: IntlShape
) => {
  const eventLogs: EventLogPoint[] = [];

  weeklyBuckets.forEach((bucket, i) => {
    const firstEvent = bucket[0];
    const firstEventDate = DateTime.fromISO(firstEvent.eventDate);
    const weeksDiff = firstEventDate
      .startOf('week')
      .diff(startDate.startOf('week'), 'weeks').weeks;

    const xPoint = Number(weeksDiff.toFixed(0));

    eventLogs.push(
      getEventLogsPointData(xPoint, bucket, firstEventDate, intl, false)
    );
  });

  return eventLogs;
};

const getLogsPlotPointsForDailyChart = (
  dailyBuckets: SeasonalEvent[][],
  intl: IntlShape
) => {
  const eventLogs: EventLogPoint[] = [];

  dailyBuckets.forEach((bucket, index) => {
    if (bucket.length) {
      const firstEventDate = DateTime.fromISO(bucket[0].eventDate);
      eventLogs.push(
        getEventLogsPointData(index, bucket, firstEventDate, intl, true)
      );
    }
  });

  return eventLogs;
};

export const getWeeklyBuckets = (
  events: SeasonalEvent[],
  selectedDateRange: DateRange
) => {
  const startDate = selectedDateRange.startDate;
  const endDate = selectedDateRange.endDate;

  const weeklyBuckets = [];
  let currentWeek = [];

  for (let date = startDate; date <= endDate; date = date.plus({ days: 1 })) {
    const currentEvents = events.filter(
      (event) =>
        DateTime.fromISO(event.eventDate).toISODate() === date.toISODate()
    );
    if (currentEvents) {
      currentWeek.push(...currentEvents);
    }

    if (date.weekday === 7 && currentWeek.length > 0) {
      weeklyBuckets.push(currentWeek);
      currentWeek = [];
    }
  }

  if (currentWeek.length > 0) {
    weeklyBuckets.push(currentWeek);
  }
  return weeklyBuckets;
};

export const getDailyBuckets = (
  events: SeasonalEvent[],
  selectedDateRange: DateRange
) => {
  const startDate = selectedDateRange.startDate;
  const endDate = selectedDateRange.endDate;

  const dailyBuckets = [];
  let currentDay = [];

  for (let date = startDate; date <= endDate; date = date.plus({ days: 1 })) {
    const currentEvents = events.filter(
      (event) =>
        DateTime.fromISO(event.eventDate).toISODate() === date.toISODate()
    );
    if (currentEvents) {
      currentDay.push(...currentEvents);
    }

    dailyBuckets.push(currentDay);
    currentDay = [];
  }

  return dailyBuckets;
};

export const getSeasonalEventsData = (
  selectedDateRange: DateRange,
  events: SeasonalEvent[],
  dataPointType: ChartTime,
  intl: IntlShape
) => {
  if (dataPointType === ChartTime.Monthly) {
    const weeklyBuckets = getWeeklyBuckets(events, selectedDateRange);
    return getLogsPlotPointsForMonthlyChart(
      weeklyBuckets,
      selectedDateRange.startDate,
      intl
    );
  }

  if (dataPointType === ChartTime.Weekly) {
    const weeklyBuckets = getWeeklyBuckets(events, selectedDateRange);
    return getLogsPlotPointsForWeeklyChart(
      weeklyBuckets,
      selectedDateRange.startDate,
      intl
    );
  }

  if (dataPointType === ChartTime.Daily) {
    const dailyBuckets = getDailyBuckets(events, selectedDateRange);
    return getLogsPlotPointsForDailyChart(dailyBuckets, intl);
  }
};
