import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCurrencyCodeFromMerchantCountryCode,
  getCurrencySymbol,
  getCurrencySymbolFromMerchantCountryCode,
} from '../../../../../lib/utilities/currency';
import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import {
  makeCurrencyColumn,
  makeNumericInputColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  AdType,
  BudgetType,
  CampaignDetails,
  CampaignStatus,
  FlywheelSalesChannel,
} from '../../../../../lib/types/AOSharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { MoneyWithAmountInString } from '../../../../../lib/types/Money';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import {
  AdLevelI8nKeyMapper,
  CURRENCY_CODE,
  TableDataAdsManager,
} from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getTooltipContentForArchivedEntity,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
  isInputValueNumber,
} from '../utils';
import toLower from 'lodash/toLower';

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({
  isEditMode,
  salesChannel,
  merchantCountry,
  selectedAdType,
  allMerchants,
  ...campaignDetails
}) => {
  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.DailyBudget
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );
  const bidConstraintsData = useBidConstraintsContext();

  const currentMerchantType = allMerchants.find(
    ({ merchantCountryId }) =>
      merchantCountryId === campaignDetails.campaignDetails.merchantCountryId!
  )?.merchantType;

  const { minDailyBudget = 0, maxDailyBudget = Infinity } = getBidConstraint(
    bidConstraintsData.constraints,
    selectedAdType,
    salesChannel,
    CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
    merchantCountry,
    undefined,
    salesChannel === FlywheelSalesChannel.Walmart
      ? currentMerchantType
      : undefined
  );

  const currency = getCurrencySymbolFromMerchantCountryCode(merchantCountry);
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  const existingValue = getExistingValue(
    campaignDetails.channelSettings.dailyBudget?.amount
  );

  let dailyBudgetValue = getCurrentDailyBudget(existingValue, changedValue);

  const [value, setValue] = useState<string>(dailyBudgetValue);

  useEffect(() => {
    if (
      isEditMode || //Changing between modes
      (!isNil(changedValue) && changedValue !== value) // Changes done due to bulk update. changedValue is latest, but state variable is not
    ) {
      setValue(dailyBudgetValue);
    }
  }, [isEditMode, changedValue, currentPage]);

  const updatedTotalBudget = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.TotalBudget
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );
  const existingTotalBudget =
    campaignDetails.channelSettings.totalBudget?.amount;

  const totalBudget = getCurrentTotalBudget(
    updatedTotalBudget,
    existingTotalBudget
  );

  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);
  const intl = useIntl();

  const currencySymbol = getCurrencySymbol(
    currency ||
      campaignDetails.channelSettings?.dailyBudget?.currency ||
      CURRENCY_CODE
  );

  const updateCellValue = (newValue: string) => {
    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
        rowId: campaignDetails.campaignId,
        existingValue,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        value: newValue,
        numericValue: true,
      })
    );
  };

  const debounceCellValueUpdate = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignDetails.campaignId]
  );

  const isCampaignArchived = isCampaignStatusArchived(
    campaignDetails.channelSettings.status
  );

  if (
    isEditMode &&
    isDailyBudgetEditable(
      salesChannel,
      selectedAdType,
      campaignDetails.channelSettings.budgetType
    )
  ) {
    const onInputFocus = () => setFocussed(true);

    const onInputBlur = () => {
      if (!isEmpty(value)) {
        debounceCellValueUpdate.cancel();
        const formattedValue = parseFloat(value).toFixed(
          MONETARY_FRACTION_DIGITS
        );
        setValue(formattedValue);
        updateCellValue(formattedValue);
      }
      setFocussed(false);
    };

    const onDailyBudgetChange = (inputValue: string) => {
      if (isInputValueNumber(inputValue)) {
        setValue(inputValue);
        debounceCellValueUpdate(inputValue);
      }
    };

    const budgetType = campaignDetails.channelSettings.budgetType;

    const isLessThanMinLimit: boolean = isCurrentValueLessThanRequiredMinValue(
      minDailyBudget,
      value
    );

    const isGreaterThanMaxLimit: boolean =
      isCurrentValueGreaterThanRequiredMaxValue(maxDailyBudget, value);

    const isDailyBudgetGreaterThanTotalBudget =
      checkIfDailyBudgetGreaterThanLifetimeBudget(
        salesChannel,
        value,
        totalBudget,
        budgetType
      );

    const isInputInvalid: boolean = isDailyBudgetInvalid(
      value,
      getTotalBudgetValueBySalesChannel(salesChannel, totalBudget, budgetType),
      minDailyBudget,
      maxDailyBudget
    );

    const state = getNumericInputState(
      isInputInvalid,
      campaignDetails.channelSettings.status
    );

    const dailyBudgetDailyNumericInputProps: NumericInputProps &
      TypedTooltipProps = {
      value,
      prependedElement: currencySymbol,
      placeholder: getPlaceholderText(intl, minDailyBudget),
      isDirty: Number(existingValue) !== Number(value),
      onChange: onDailyBudgetChange,
      tooltipContent: tooltipContent({
        isLessThanMinLimit,
        isGreaterThanMaxLimit,
        isDailyBudgetGreaterThanTotalBudget,
        isCampaignArchived,
        intl,
        isInputInvalid,
        minDailyBudget,
        maxDailyBudget,
        currencySymbol,
        value,
      }),
      controlledTooltip: getTooltipState(state, focussed, isCampaignArchived),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus,
      onInputBlur,
      acceptOnlyPositiveNumbers: true,
      minFractionDigits: MONETARY_FRACTION_DIGITS,
      maxFractionDigits: MONETARY_FRACTION_DIGITS,
      state,
      dataTestId: 'daily_budget',
    };

    return makeNumericInputColumn<CampaignDetails>(
      () => dailyBudgetDailyNumericInputProps
    )(campaignDetails);
  }

  return makeCurrencyColumn<CampaignDetails>(({ channelSettings }) => {
    return getColumnValue(
      salesChannel,
      selectedAdType,
      currencyCode,
      channelSettings.dailyBudget,
      channelSettings.budgetType
    );
  }, campaignDetails.pendingFields?.includes(CAMPAIGNS_API_COLUMN_NAME.DailyBudget))(
    campaignDetails
  );
};
RowCellElement.displayName = 'RowCellElement';

export const dailyBudgetColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_DAILY_BUDGET,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const dailyBudgetFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_DAILY_BUDGET,
    currency,
    isValidNumber(0)
  );

export function getTotalBudgetValueBySalesChannel(
  salesChannel: FlywheelSalesChannel,
  totalBudget: string,
  budgetType?: string
): string | undefined {
  switch (salesChannel) {
    case FlywheelSalesChannel.Walmart:
      return budgetType === BudgetType.Both ? totalBudget : undefined;
    case FlywheelSalesChannel.Amazon:
    default:
      return totalBudget;
  }
}

function checkIfDailyBudgetGreaterThanLifetimeBudget(
  salesChannel: FlywheelSalesChannel,
  dailyBudget: string,
  totalBudget: string,
  budgetType?: string
): boolean {
  switch (salesChannel) {
    case FlywheelSalesChannel.Walmart:
      return (
        budgetType === BudgetType.Both &&
        Number(dailyBudget) > Number(totalBudget)
      );
    case FlywheelSalesChannel.Amazon:
    default:
      return totalBudget ? Number(dailyBudget) > Number(totalBudget) : false;
  }
}

export const isDailyBudgetInvalid = (
  value: string,
  totalBudget?: string,
  minDailyBudget?: number,
  maxDailyBudget?: number,
  budgetType?: BudgetType
): boolean => {
  const isLessThanMinLimit: boolean = isCurrentValueLessThanRequiredMinValue(
    minDailyBudget,
    value
  );
  const isGreaterThanMaxLimit: boolean =
    isCurrentValueGreaterThanRequiredMaxValue(maxDailyBudget, value);
  const isDailyBudgetGreaterThanTotalBudget =
    totalBudget && budgetType === BudgetType.Both
      ? Number(value) > Number(totalBudget)
      : false;
  return (
    isLessThanMinLimit ||
    isGreaterThanMaxLimit ||
    isDailyBudgetGreaterThanTotalBudget ||
    isEmpty(value)
  );
};

const getExistingValue = (dailyBudget?: string) => {
  return dailyBudget?.toString() || '';
};

const getCurrentDailyBudget = (
  existingValue: string,
  changedValue?: string
) => {
  return changedValue ?? existingValue;
};

const getCurrentTotalBudget = (
  updatedTotalBudget: string,
  existingTotalBudget?: string
) => {
  return updatedTotalBudget ?? existingTotalBudget;
};

const tooltipContent = (args: {
  isLessThanMinLimit: boolean;
  isGreaterThanMaxLimit: boolean;
  isDailyBudgetGreaterThanTotalBudget: boolean;
  isCampaignArchived: boolean;
  intl: IntlShape;
  isInputInvalid: boolean;
  currencySymbol: string;
  value: string;
  minDailyBudget?: number;
  maxDailyBudget?: number;
}) => {
  const {
    isLessThanMinLimit,
    isGreaterThanMaxLimit,
    isDailyBudgetGreaterThanTotalBudget,
    isCampaignArchived,
    intl,
    isInputInvalid,
    currencySymbol,
    value,
    minDailyBudget,
    maxDailyBudget,
  } = args;
  if (isCampaignArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_DAILY_BUDGET,
      }),
      intl
    );
  } else if (isDailyBudgetGreaterThanTotalBudget) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_DAILY_BUDGET_GREATER_THAN_LIFETIME_BUDGET,
        })}
      </p>
    );
  } else if (
    isInputInvalid &&
    !isNil(minDailyBudget) &&
    !isNil(maxDailyBudget) &&
    maxDailyBudget !== Infinity
  ) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_DAILY_BUDGET_AMAZON_ERROR_MESSAGE,
          },
          {
            currencyCode: currencySymbol,
            minValue:
              minDailyBudget &&
              intl.formatNumber(minDailyBudget, {
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
            maxValue:
              maxDailyBudget &&
              intl.formatNumber(maxDailyBudget, {
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
          }
        )}
      </p>
    );
  } else if (isLessThanMinLimit || isEmpty(value)) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          { id: I18nKey.ADS_MANAGER_INVALID_NUMERIC_ATLEAST },
          {
            currency: currencySymbol,
            minValue:
              minDailyBudget &&
              intl.formatNumber(minDailyBudget, {
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
          }
        )}
      </p>
    );
  } else if (isGreaterThanMaxLimit) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          { id: I18nKey.ADS_MANAGER_INVALID_NUMERIC_GREATER_THAN },
          {
            currency: currencySymbol,
            maxValue:
              maxDailyBudget &&
              intl.formatNumber(maxDailyBudget, {
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
          }
        )}
      </p>
    );
  }
};

export const getTooltipState = (
  state: NumericInputState,
  focussed?: boolean,
  isCampaignArchived?: boolean
) => {
  if (isCampaignArchived) {
    return ControlledTooltip.None;
  } else {
    return focussed && state === NumericInputState.Error
      ? ControlledTooltip.Show
      : ControlledTooltip.Hide;
  }
};

const getNumericInputState = (
  isInputInvalid: boolean,
  campaignStatus?: CampaignStatus
) => {
  if (isCampaignStatusArchived(campaignStatus)) {
    return NumericInputState.Disabled;
  } else {
    return isInputInvalid ? NumericInputState.Error : NumericInputState.Default;
  }
};

const getPlaceholderText = (intl: IntlShape, minDailyBudget?: number) => {
  return minDailyBudget
    ? intl.formatNumber(minDailyBudget, {
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      })
    : '';
};

export const getColumnValue = (
  salesChannel: FlywheelSalesChannel,
  selectedAdType: AdType,
  currencyCode: string | null,
  dailyBudget?: MoneyWithAmountInString,
  budgetType?: string
) => {
  if (
    salesChannel === FlywheelSalesChannel.Amazon &&
    (selectedAdType === AdType.SponsoredBrands ||
      selectedAdType === AdType.SponsoredBrandsVideo) &&
    budgetType?.toLowerCase() !== BudgetType.Daily
  ) {
    return undefined;
  } else {
    return (
      dailyBudget && {
        amount: Number(dailyBudget.amount),
        currency: currencyCode || dailyBudget.currency || CURRENCY_CODE,
      }
    );
  }
};

export const isDailyBudgetEditable = (
  salesChannel: FlywheelSalesChannel,
  selectedAdType: AdType,
  budgetType?: string
): boolean => {
  switch (salesChannel) {
    case FlywheelSalesChannel.Walmart:
      return budgetType === BudgetType.Both || budgetType === BudgetType.Daily;
    case FlywheelSalesChannel.Amazon:
      if (
        selectedAdType === AdType.SponsoredBrands ||
        selectedAdType === AdType.SponsoredBrandsVideo
      ) {
        return toLower(budgetType) === toLower(BudgetType.Daily);
      } else {
        return true;
      }
    default:
      return true;
  }
};
