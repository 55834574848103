import {
  CheckmarkIcon,
  Icon,
  IconSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import I18nKey from '../../lib/types/I18nKey';
import range from 'lodash/range';
import classNames from 'classnames';

export interface StepsCompleteProps {
  readonly completed: number;
  readonly total: number;
}

export const StepsComplete: React.FC<StepsCompleteProps> = ({
  completed,
  total,
}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-12">
      <div className="flex flex-row gap-8">
        {range(1, total + 1, 1).map((step) => (
          <div
            className="flex flex-row justify-center items-center gap-8"
            key={`${step}_step`}
          >
            <div
              className={classNames(
                'rounded-full w-32 h-32 flex items-center justify-center gap-8',
                {
                  'bg-grey-200 text-grey-500': step > completed,
                  'bg-green-500 text-white': step <= completed,
                }
              )}
            >
              {step <= completed ? (
                <Icon size={IconSize.Medium} svg={CheckmarkIcon} />
              ) : (
                <Typography
                  size={TypographySize.sm}
                  lineHeight={TypographyLineHeight.none}
                  weight={TypographyWeight.semibold}
                >
                  <FormattedNumber value={step} />
                </Typography>
              )}
            </div>
            {step < total && <hr className="w-16 h-1 text-grey-300" />}
          </div>
        ))}
      </div>
      <Typography
        size={TypographySize.base}
        lineHeight={TypographyLineHeight.tight}
        weight={TypographyWeight.medium}
      >
        <FormattedMessage
          id={I18nKey.SETUP_FLYWHEEL_STEPS_TEXT_WITH_TOTAL_COMPLETE}
          values={{
            number: completed,
            total: total,
          }}
        />
      </Typography>
    </div>
  );
};

StepsComplete.displayName = 'StepsComplete';
