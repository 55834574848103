import { IdToken } from '@auth0/auth0-react';

import { AccountSalesChannelPaginatedResultWithError } from '../../modules/products/containers/skuCatalog/types';
import { createBillingApiClient } from '../clients/BillingApiClient';
import { createFAMApiClient } from '../clients/FAMApiClient';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../types/SalesChannels';

/**
 * Parses salesChannelData merchant countries to get automation status for each
 * sales channel. Any MC with `aiEnabled` will return `true` for the sales channel
 * @param salesChannelData the data returned from the SubscriptionContext
 * @returns segment traits object for tracking
 */
export const getAutomationStatusByChannel = (
  salesChannelData: AccountSalesChannelPaginatedResultWithError[]
) => {
  const amazonStatus = salesChannelData
    .find((data) => data.salesChannel === 'amazon')
    ?.items.some((item) => item.aiEnabled);
  const walmartStatus = salesChannelData
    .find((data) => data.salesChannel === 'walmart')
    ?.items.some((item) => item.aiEnabled);
  return {
    amazon_automation_status: amazonStatus ? 'Yes' : 'No',
    walmart_automation_status: walmartStatus ? 'Yes' : 'No',
  };
};

/**
 * Gets Zuora info from Billing API
 * @returns an object with data formatted for Segment tracking
 */
export const getZuoraAccountInfo = async (
  token: IdToken,
  accountId: string
) => {
  const { zuoraAccountId } = await createBillingApiClient(token).getBillingInfo(
    accountId
  );
  return {
    zuora_account_id__url_: zuoraAccountId,
  };
};

/**
 * Gets the date when each sales channel was first authenticated for the
 * current account.
 * @param token
 * @param accountId
 * @returns an object with data formatted for Segment tracking
 */
export const getAuthenticationDate = async (
  token: IdToken,
  accountId: string
) => {
  const account = await createFAMApiClient(token)
    .getAccountDetails(accountId)
    .catch(undefined);
  if (!account) {
    return {};
  }
  const { channelAuthenticationTimes } = account;
  const amazonAuthTime = channelAuthenticationTimes?.find(
    (cat) => cat.salesChannelId === AMAZON_SALES_CHANNEL_ID
  );
  const walmartAuthTime = channelAuthenticationTimes?.find(
    (cat) => cat.salesChannelId === WALMART_SALES_CHANNEL_ID
  );
  return {
    amazon_first_authentication_date: amazonAuthTime?.firstAuthenticatedAt,
    walmart_first_authentication_date: walmartAuthTime?.firstAuthenticatedAt,
  };
};

/**
 * Encodes a string to sha1 hex. THIS IS NOT CRYPTOGRAPHICALLY SECURE. Used for a tracking spec.
 * @param str
 * @returns sha1 hex encoded string
 */
export const sha1Encode = async (str: string) => {
  const enc = new TextEncoder();
  const hash = await crypto.subtle.digest('SHA-1', enc.encode(str));
  return Array.from(new Uint8Array(hash))
    .map((v) => v.toString(16).padStart(2, '0'))
    .join('');
};
