import capitalize from 'lodash/capitalize';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { TargetsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TARGETS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<TargetsDetails> = (targetsDetails) => {
  return makeTextColumn((data: TargetsDetails) =>
    data.targetDetails.campaignTargetingType
      ? capitalize(data.targetDetails.campaignTargetingType)
      : undefined
  )(targetsDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignTargetingTypeColumn: FlywheelTableColumn<TargetsDetails> =
  {
    columnName: TARGETS_API_COLUMN_NAME.CampaignTargetingType,
    isSortable: true,
    i18nKeyOrLabel:
      I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE,
    RowCellElement,
    gridColumnWidth: '140px',
  };

export const campaignTargetingTypeFilter = createMultiSelectDataFieldFilter(
  TARGETS_API_COLUMN_NAME.CampaignTargetingType,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE,
  [
    SelectFilterOption.CampaignTargetingTypeAuto,
    SelectFilterOption.CampaignTargetingTypeManual,
  ]
);
