import startCase from 'lodash/startCase';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { TargetsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TARGETS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<TargetsDetails> = (targetDetails) =>
  makeTextColumn((data: TargetsDetails) =>
    data.targetDetails?.campaignType
      ? startCase(data.targetDetails?.campaignType)
      : undefined
  )(targetDetails);

export const campaignTypeColumn: FlywheelTableColumn<TargetsDetails> = {
  columnName: TARGETS_API_COLUMN_NAME.CampaignType,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_TYPE,
  RowCellElement,
  gridColumnWidth: '200px',
};
