import { AllSalesChannel } from '../../../../../lib/types/Fam';
import { SPAction } from '../../../../../lib/types/TeikaReduxTypes';

export const SET_SELECTED_SALES_CHANNEL = 'app/adsManager/setSalesChannels';
export const SET_AD_TYPES = 'app/adsManager/setAdTypes';
export const SET_FLYWHEEL_SALES_CHANNELS =
  'app/adsManager/setFlywheelSalesChannels';

export const setSelectedSalesChannel = (
  payload: string
): SPAction<typeof SET_SELECTED_SALES_CHANNEL, string> => ({
  type: SET_SELECTED_SALES_CHANNEL,
  payload,
});

export const setFlywheelSalesChannelsInfo = (
  payload: AllSalesChannel[]
): SPAction<typeof SET_FLYWHEEL_SALES_CHANNELS, AllSalesChannel[]> => ({
  type: SET_FLYWHEEL_SALES_CHANNELS,
  payload,
});

export type AdsManagerAction =
  | ReturnType<typeof setSelectedSalesChannel>
  | ReturnType<typeof setFlywheelSalesChannelsInfo>;

export default {
  SET_SELECTED_SALES_CHANNEL,
  SET_FLYWHEEL_SALES_CHANNELS,
  setSelectedSalesChannel,
  setFlywheelSalesChannelsInfo,
};
