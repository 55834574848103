import { createContext, useContext, useState } from 'react';
import { createAOApiClient } from '../../lib/clients/AOApiClient';
import { BidContraints } from './biddingConstraints';
import { UserContext, UserContextState } from '../userProvider/userProvider';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../lib/types/SalesChannels';
import noop from 'lodash/noop';
import { Fw2LocalStorage } from '../../lib/utilities/fw2LocalStorage';

const LOCAL_STORAGE_KEY = 'bidConstraints';

export type BidConstraintsContextState = {
  constraints: BidContraints[];
  loadConstraints: () => void;
};

const initialState: BidConstraintsContextState = {
  constraints: [],
  loadConstraints: noop,
};

const BidConstraintsContext =
  createContext<BidConstraintsContextState>(initialState);

BidConstraintsContext.displayName = 'BidConstraintsContext';

export const useBidConstraintsContext = () => {
  const context = useContext(BidConstraintsContext);
  if (!context) {
    throw new Error(
      'useBidConstraintsContext must be used within a BidConstraintsProvider'
    );
  }
  return context;
};

const { Provider } = BidConstraintsContext;

export interface BidConstraintsProviderProps {
  readonly children: JSX.Element;
}

const BidConstraintsProvider: React.FC<BidConstraintsProviderProps> = ({
  children,
}) => {
  const [bidConstraints, setBidConstraints] = useState<BidContraints[]>([]);

  const {
    userInfo: { idToken, currentAccountId },
  } = useContext<UserContextState>(UserContext);

  const aoClient = createAOApiClient(idToken!);

  const fetchBidConstraints = async () => {
    try {
      const localBidConstraints = Fw2LocalStorage.getItem(LOCAL_STORAGE_KEY);
      if (localBidConstraints) {
        setBidConstraints(JSON.parse(localBidConstraints));
      }

      const data = await aoClient.getBidConstraints(currentAccountId!, {
        salesChannelIds: [AMAZON_SALES_CHANNEL_ID, WALMART_SALES_CHANNEL_ID],
      });
      setBidConstraints(data);
      Fw2LocalStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Provider
      value={{
        constraints: bidConstraints,
        loadConstraints: fetchBidConstraints,
      }}
    >
      {children}
    </Provider>
  );
};
BidConstraintsProvider.displayName = 'BidConstraintsProvider';
export { BidConstraintsContext, BidConstraintsProvider };
