import React from 'react';
import { useIntl } from 'react-intl';

import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  CrossMiniIcon,
  Icon,
  IconSize,
  LightningIcon,
  Modal,
  TextLink,
} from '@teikametrics/tm-design-system';

import { HOW_TO_CHANGE_PLAN_LINK } from '../../lib/types/CommonSharedTypes';
import I18nKey from '../../lib/types/I18nKey';
import { NavPaths } from '../../NavPaths';

export interface SubscriptionEndedModalProps {
  onClose: () => void;
}

export const SubscriptionEndedModal: React.FC<SubscriptionEndedModalProps> = ({
  onClose,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [TITLE, LEARN_MORE_LINK, SUBTITLE, ADD_CARD_BUTTON] = [
    I18nKey.SUBSCRIPTION_ENDED_MODAL_TITLE,
    I18nKey.SUBSCRIPTION_ENDED_MODAL_LEARN_MORE_LINK,
    I18nKey.SUBSCRIPTION_ENDED_MODAL_SUBTITLE,
    I18nKey.SUBSCRIPTION_ENDED_MODAL_ADD_CARD_BUTTON,
  ].map((id) => intl.formatMessage({ id }));

  const navigateToBilling = () => {
    onClose();
    navigate(NavPaths.Billing, {
      state: { openiFrame: true },
    });
  };

  return (
    <Modal
      showModal
      className="max-w-500 relative"
      dataTestId="subscription_ended_modal"
    >
      <Icon
        svg={CrossMiniIcon}
        size={IconSize.Large}
        className="absolute right-20 top-12 cursor-pointer"
        onClick={onClose}
      />
      <div
        className={`flex flex-col items-center pt-24 pb-40
        text-center whitespace-pre-line leading-tight`}
      >
        <div
          className={`flex items-center justify-center w-64 h-64
            rounded-full bg-gradient-to-r from-magenta-500 to-orange-500`}
        >
          <LightningIcon className="fill-current h-36 w-36 text-white" />
        </div>
        <div className="text-grey-900 font-semibold text-xl mt-10">{TITLE}</div>
        <TextLink
          openNewTab
          textLabel={LEARN_MORE_LINK}
          href={HOW_TO_CHANGE_PLAN_LINK}
          dataTestId="trial-ended_learn-more-link"
          className="mt-24"
        />
        <div className="font-normal text-sm mt-20">{SUBTITLE}</div>
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Primary}
          label={ADD_CARD_BUTTON}
          className="mt-20"
          onClick={navigateToBilling}
        />
      </div>
    </Modal>
  );
};
SubscriptionEndedModal.displayName = 'SubscriptionEndedModal';
