import { fromNullable } from 'fp-ts/lib/Option';
import isUndefined from 'lodash/isUndefined';

import { SubRows } from './types';
import { isNotUndefined } from '../../lib/utilities/typeGuards';
import { AsyncRequestKinds } from '../../lib/utilities/asyncRequest';

interface CalculateNumberOfSubRowsForRowArgs<
  RowDataType,
  SubRowDataType,
  SubRowChildRowDataType,
  TableDataType
> {
  readonly maybeSubRows?: SubRows<RowDataType, SubRowDataType, TableDataType>;
  readonly maybeSubRowChildRows?: SubRows<
    SubRowDataType,
    SubRowChildRowDataType,
    TableDataType
  >;
  readonly rowData: RowDataType;
  readonly tableData?: TableDataType;
}

// For a given row, calculate the number sub-rows,
// sub rows + sub row children + loading rows + error rows + sub-row header rows
function calculateNumberOfSubRowsForRow<
  RowDataType,
  SubRowDataType,
  SubRowChildRowDataType,
  TableDataType
>(
  params: CalculateNumberOfSubRowsForRowArgs<
    RowDataType,
    SubRowDataType,
    SubRowChildRowDataType,
    TableDataType
  >
): number {
  const { maybeSubRows, maybeSubRowChildRows, rowData, tableData } = params;
  const maybeVisibleSubRows = fromNullable(maybeSubRows).filter((subRow) =>
    subRow.areSubRowsVisible(rowData, tableData)
  );

  const numberOfHeaderRows = maybeSubRows?.columns.some((column) =>
    isNotUndefined(column.HeaderCellElement)
  )
    ? 1
    : 0;

  return maybeVisibleSubRows
    .map((subRow) => {
      const asyncDataForSubRow = subRow.dataResolver(rowData, tableData);

      switch (asyncDataForSubRow.kind) {
        // When the sub row data is loaded
        case AsyncRequestKinds.Completed:
          // calculate the number of child-rows there are for the given sub-row
          const numberOfChildrenInSubRow = fromNullable(maybeSubRowChildRows)
            .map((subRowChildRows) =>
              asyncDataForSubRow.result.reduce(
                (acc, subRowData) =>
                  acc +
                  calculateNumberOfSubRowsForRow({
                    maybeSubRows: subRowChildRows,
                    rowData: subRowData,
                    tableData,
                  }),
                0
              )
            )
            .getOrElse(0);

          // return the number of rows for the given sub-row,
          // number of rows = number of sub rows + number of child rows associated with the sub row
          return asyncDataForSubRow.result.length + numberOfChildrenInSubRow;

        // When the sub row data failed to load
        case AsyncRequestKinds.Failed:
          const subRowDisplaysAnErrorRow = !isUndefined(
            subRow.maybeErrorComponent
          );

          // only include a row if we are displaying an error row
          return subRowDisplaysAnErrorRow ? 1 : 0;

        //  When the sub row data has not yet started loading or is loading
        case AsyncRequestKinds.NotStarted:
        case AsyncRequestKinds.Loading:
        default:
          const subRowDisplaysALoadingRow = !isUndefined(
            subRow.maybeLoadingComponent
          );

          // only include a row if we are displaying a loading row
          return subRowDisplaysALoadingRow ? 1 : 0;
      }
    })
    .map((rows) => rows + numberOfHeaderRows) // Include the header row in the count of rows for the given sub-row
    .getOrElse(0); // If the sub row is not visible, return 0
}

export interface CalculateNumberOfTableRowsArgs<
  RowDataType,
  SubRowDataType,
  SubRowChildRowDataType,
  TableDataType
> {
  readonly maybeSubRows?: SubRows<RowDataType, SubRowDataType, TableDataType>;
  readonly maybeSubRowChildRows?: SubRows<
    SubRowDataType,
    SubRowChildRowDataType,
    TableDataType
  >;
  readonly allRowData: RowDataType[];
  readonly tableData?: TableDataType;
}

function calculateNumberOfTableRows<
  RowDataType,
  SubRowDataType,
  SubRowChildRowDataType,
  TableDataType
>(
  params: CalculateNumberOfTableRowsArgs<
    RowDataType,
    SubRowDataType,
    SubRowChildRowDataType,
    TableDataType
  >
): number {
  const { maybeSubRows, maybeSubRowChildRows, allRowData, tableData } = params;

  return (
    allRowData.length +
    allRowData.reduce(
      (acc, rowData) =>
        acc +
        calculateNumberOfSubRowsForRow({
          tableData,
          rowData,
          maybeSubRows,
          maybeSubRowChildRows,
        }),
      0
    )
  );
}

export default calculateNumberOfTableRows;
