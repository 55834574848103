import {
  LinearAlert,
  LinearAlertRed,
  LinearCheckmark,
} from '@teikametrics/tm-design-system';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import uuid from 'uuid';
import { MerchantSyncDate } from '../../../../../lib/types/AOSharedTypes';
import {
  ConnectionStatus,
  EntityType,
  FAMConnectionStatus,
  IngestionStatus,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { SELLING_PARTNER_APP_ID } from '../../../components/utils/AmazonAuth';
import { DATE_FORMAT, SecondarySyncStatus, StatusData } from '../types';
import { Merchants } from '../../../components/constants';
import { openSignInWindow } from '../../../../../lib/utilities/popupWindow';

const getConnectedI18nKey = (lastSyncDate: string) => {
  return isDataSyncBehind(lastSyncDate)
    ? I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_DATA_BEHIND
    : I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_LATEST_DATA_AVAILABLE;
};

export const isDataSyncBehind = (lastSyncDate: string) => {
  const lastSyncDateFromString = DateTime.fromISO(lastSyncDate);
  const currentDate = DateTime.now();
  return currentDate.diff(lastSyncDateFromString, ['days']).days > 1;
};

export const getStatusData = (
  connectionStatus: string,
  isAmazon: boolean,
  lastSyncDate?: string
): StatusData => {
  switch (connectionStatus) {
    case FAMConnectionStatus.Processing:
    case FAMConnectionStatus.Syncing:
    case FAMConnectionStatus.PendingWalmart:
    case ConnectionStatus.Processing:
      return {
        text: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_PROCESSING,
        statusIconColor: 'text-yellow-500',
        showSecondaryDescription: false,
      };

    case ConnectionStatus.Syncing:
      return {
        text: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_SYNCING,
        statusIconColor: 'text-yellow-500',
        showSecondaryDescription: false,
      };

    case FAMConnectionStatus.Active:
    case ConnectionStatus.Validating:
      return isAmazon
        ? {
            text: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_SYNCING,
            statusIconColor: 'text-yellow-500',
            showSecondaryDescription: false,
          }
        : {
            text: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_CONNECTED,
            statusIconColor: 'text-green-500',
            showSecondaryDescription: true,
          };

    case ConnectionStatus.Connected:
      return {
        text: getConnectedI18nKey(lastSyncDate ?? ''),
        statusIconColor: 'text-green-500',
        showSecondaryDescription: true,
      };
    case ConnectionStatus.ConnectedNoData:
      return {
        text: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_CONNECTED_NO_DATA,
        statusIconColor: 'text-yellow-500',
        showSecondaryDescription: true,
      };

    case ConnectionStatus.Failed:
      return {
        text: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_FAILED,
        statusIconColor: 'text-red-500',
        showSecondaryDescription: true,
      };

    case FAMConnectionStatus.Inactive:
    case ConnectionStatus.NotConnected:
    default:
      return {
        text: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_NOT_CONNECTED,
        statusIconColor: 'text-orange-500',
        showSecondaryDescription: true,
      };
  }
};

const getConnectedNoDataIcon = (
  intl: IntlShape,
  entity?: EntityType,
  adConnectionData?: MerchantSyncDate,
  productConnectionData?: MerchantSyncDate
) => {
  const lastSyncedAt =
    entity === EntityType.Ads
      ? adConnectionData?.lastSyncedAt
      : productConnectionData?.lastSyncedAt;
  const earliestSyncDate =
    entity === EntityType.Products
      ? adConnectionData?.earliestAvailableDate
      : productConnectionData?.earliestAvailableDate;

  return {
    icon: LinearAlertRed,
    label: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_STATUS_LAST_SYNC,
    value: lastSyncedAt
      ? DateTime.fromISO(lastSyncedAt).toFormat(DATE_FORMAT)
      : intl.formatMessage({
          id: I18nKey.GENERIC_EM_DASH,
        }),
    earliestSyncDateValue: earliestSyncDate
      ? DateTime.fromISO(earliestSyncDate).toFormat('MMM dd, yyyy')
      : intl.formatMessage({ id: I18nKey.GENERIC_EM_DASH }),
  };
};

const getLastSyncedAtIcon = (
  lastSyncedAt: string,
  earliestAvailableDate?: string
) => {
  return {
    icon: isDataSyncBehind(lastSyncedAt) ? LinearAlert : LinearCheckmark,
    label: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_STATUS_LAST_SYNC,
    value: DateTime.fromISO(lastSyncedAt).toFormat(DATE_FORMAT),
    earliestSyncDateValue: DateTime.fromISO(
      earliestAvailableDate ?? ''
    ).toFormat('MMM dd, yyyy'),
  };
};

const isIngestionInProgress = (
  entity?: EntityType,
  adConnectionData?: MerchantSyncDate,
  productConnectionData?: MerchantSyncDate
) => {
  return (
    (entity === EntityType.Ads &&
      adConnectionData?.ingestionStatus === IngestionStatus.InProgress) ||
    (entity === EntityType.Products &&
      productConnectionData?.ingestionStatus === IngestionStatus.InProgress)
  );
};

export const getSyncStatusIcon = (
  connectionStatus: string,
  retryConnection: boolean,
  intl: IntlShape,
  entity?: EntityType,
  adConnectionData?: MerchantSyncDate,
  productConnectionData?: MerchantSyncDate
): SecondarySyncStatus | undefined => {
  const shouldShowConnectLink = !connectionStatus || retryConnection;
  if (
    shouldShowConnectLink ||
    connectionStatus === ConnectionStatus.ConnectedNoData
  ) {
    return getConnectedNoDataIcon(
      intl,
      entity,
      adConnectionData,
      productConnectionData
    );
  } else if (entity === EntityType.Ads && adConnectionData?.lastSyncedAt) {
    return getLastSyncedAtIcon(
      adConnectionData.lastSyncedAt,
      adConnectionData.earliestAvailableDate
    );
  } else if (
    entity === EntityType.Products &&
    productConnectionData?.lastSyncedAt
  ) {
    return {
      icon: isDataSyncBehind(productConnectionData.lastSyncedAt)
        ? LinearAlert
        : LinearCheckmark,
      label: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_STATUS_LAST_SYNC,
      value: DateTime.fromISO(productConnectionData?.lastSyncedAt).toFormat(
        DATE_FORMAT
      ),
      earliestSyncDateValue: DateTime.fromISO(
        productConnectionData?.earliestAvailableDate ?? ''
      ).toFormat('MMM dd, yyyy'),
    };
  } else if (
    isIngestionInProgress(entity, adConnectionData, productConnectionData)
  ) {
    return {
      icon: LinearAlert,
      label: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_STATUS_INITIAL_SYNC,
    };
  } else if (
    [
      FAMConnectionStatus.PendingWalmart,
      FAMConnectionStatus.Processing,
      FAMConnectionStatus.Syncing,
      ConnectionStatus.Processing,
    ].includes(connectionStatus as ConnectionStatus)
  ) {
    return {
      icon: LinearAlert,
      label: I18nKey.PROCESSING,
      earliestSyncDateValue: intl.formatMessage({
        id: I18nKey.GENERIC_EM_DASH,
      }),
      value: intl.formatMessage({
        id: I18nKey.GENERIC_EM_DASH,
      }),
    };
  } else if (
    [
      FAMConnectionStatus.Inactive,
      ConnectionStatus.NotConnected,
      null,
    ].includes(connectionStatus as ConnectionStatus | null)
  ) {
    return {
      icon: LinearAlertRed,
      label: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_NOT_CONNECTED,
    };
  }
};

export const onOpenOauthPopup = (
  country: string,
  baseURL: string,
  extMerchantType?: string
) => {
  const merchantRegion = Merchants.find(
    (merchant) =>
      merchant.merchantType === extMerchantType &&
      merchant.country_code.includes(country)
  );

  if (!merchantRegion?.url) {
    return;
  }

  const spStateToken = uuid();

  const sellingPartnerAppUrl = `https://${merchantRegion.url[0]}/apps/authorize/consent?application_id=${SELLING_PARTNER_APP_ID}&state=${spStateToken}&redirect_uri=${baseURL}/amz-sp-api/callback`;

  openSignInWindow(
    encodeURI(sellingPartnerAppUrl),
    'sellingPartnerLoginWindow',
    () => {}
  );
};

export const isMerchantVendor = (extMerchantType?: string) =>
  (extMerchantType || '').toLowerCase().includes('vendor');

export const getConnectionStatus = (
  entity: EntityType,
  adConnectionStatus: string,
  productConnectionStatus: string
) => (entity === EntityType.Ads ? adConnectionStatus : productConnectionStatus);

export const getRetryConnection = (connectionStatus: string) =>
  connectionStatus === FAMConnectionStatus.Failed ||
  connectionStatus === FAMConnectionStatus.Inactive;
