import React from 'react';
import { useIntl } from 'react-intl';

import {
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';

export const Footer: React.FC<{
  onButtonClick: () => void;
  onlyBackButton: boolean;
}> = ({ onButtonClick, onlyBackButton }) => {
  const intl = useIntl();

  return (
    <div className="flex justify-between items-center mx-8">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.BlackAndWhiteBorder}
        label={intl.formatMessage({
          id: I18nKey.BACK,
        })}
        svgIcon={ArrowLeftIcon}
        onClick={onButtonClick}
        dataTestId={`flash1_amazon_ads_unavailable_merchants_back`}
      />
      {!onlyBackButton && (
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Primary}
          label={intl.formatMessage({
            id: I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_CONNECT_BUTTON,
          })}
          state={ButtonState.Disabled}
        />
      )}
    </div>
  );
};
