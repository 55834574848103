import {
  KeywordAdgroupInfo,
  TargetSegments,
  TargetingType,
} from '../../../../../../lib/types/KeywordAction';
import { MerchantCountry } from '../../../../../../lib/types/Fam';
import { useIntl } from 'react-intl';
import React, { useEffect, useMemo, useState } from 'react';
import { SlideOutTab } from '../../../../../keywordAction/components/viewSkuAndKeywordSlideout/types';
import {
  ArrowLeftIcon,
  BadgeDisplayMode,
  BadgeType,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Modal,
  SuggestedAdgroupCell,
  SuggestedAdgroupCellProps,
  TabsSimple,
  Typography,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { SkuTab } from '../../../../../keywordAction/components/viewSkuAndKeywordSlideout/skuTab';
import classNames from 'classnames';
import { getTabs } from '../../../../../keywordAction/components/viewSkuAndKeywordSlideout/utils';
import { AOApiClient } from '../../../../../../lib/clients/AOApiClient';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { KeywordsTabV2 } from './keywordsTabV2';
import { useLocation } from 'react-router-dom';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../../lib/utilities/intercom';
import { getTargetSegmentPills } from '../../../../../keywordAction/components/RecommendationsTableV3/utils';

export interface SkuAndKeywordSlideOutProps {
  readonly merchantCountry: MerchantCountry;
  readonly onClose: () => void;
  readonly aoApiClient: AOApiClient;
  readonly accountId: string;
}

interface ParamProps {
  readonly suggestedAdgroup: KeywordAdgroupInfo;
}

export const SkuAndKeywordSlideOut: React.FC<SkuAndKeywordSlideOutProps> = ({
  merchantCountry,
  onClose,
  aoApiClient,
  accountId,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(SlideOutTab.Sku);
  const defaultAdGroupId = useMemo(() => {
    return new URLSearchParams(location.search).get('adGroupId') || '';
  }, [location.pathname]);

  const suggestedAdgroupProps: SuggestedAdgroupCellProps | undefined =
    useMemo(() => {
      const suggestedAdgroup = (location.state as ParamProps).suggestedAdgroup;

      if (!suggestedAdgroup) {
        return;
      }

      return {
        campaignName: suggestedAdgroup?.campaign?.name,
        adGroupName: suggestedAdgroup?.name,
        targetSegments: getTargetSegmentPills(
          suggestedAdgroup?.targetSegments as TargetSegments[]
        ),
        targetingType: {
          badgeType:
            suggestedAdgroup?.campaign?.targetingType === TargetingType.Auto
              ? BadgeType.CampaignTypeAuto
              : BadgeType.CampaignTypeManual,
          badgeNameDisplayMode: BadgeDisplayMode.OnlyInitial,
        },
        dataTestId: 'keywords_on_demand_sku_slideout',
      };
    }, [location.state]);
  const onTabSelect = (newIndex: number) => setActiveTabIndex(newIndex);

  const getTabsData = () => {
    if (activeTabIndex === SlideOutTab.Sku) {
      return (
        <SkuTab
          aoApiClient={aoApiClient}
          accountId={accountId}
          merchantCountryId={merchantCountry.merchantCountryId}
          adGroupId={defaultAdGroupId}
          salesChannelId={merchantCountry.salesChannelId || ''}
          merchantType={merchantCountry.merchantType}
        />
      );
    } else if (activeTabIndex === SlideOutTab.Keyword) {
      return (
        <KeywordsTabV2
          aoApiClient={aoApiClient}
          accountId={accountId}
          merchantCountryId={merchantCountry.merchantCountryId}
          adGroupId={defaultAdGroupId}
          salesChannelId={merchantCountry.salesChannelId || ''}
          merchantType={merchantCountry.merchantType}
        />
      );
    }
  };

  useEffect(() => {
    hideIntercomLauncher();
    return () => showIntercomLauncher();
  }, []);

  return (
    <Modal
      className="flex flex-col w-512"
      showModal
      slideOutModal={true}
      showOVerlay={true}
      formActionRowClassName="sticky border-t-1 border-grey-200 border-solid py-12 absolute bottom-0 px-12 bg-white"
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      hidePadding={true}
      dataTestId="sku_and_keyword_slide_out"
    >
      <div className="flex flex-col">
        <div
          className={classNames(
            'flex flex-row',
            'border-solid border-b border-grey-200',
            'p-16'
          )}
        >
          <div className="hover:cursor-pointer" onClick={onClose}>
            <Button
              label={intl.formatMessage({
                id: I18nKey.BACK,
              })}
              svgIcon={ArrowLeftIcon}
              size={ButtonSize.Medium}
              variant={ButtonVariant.BlackAndWhite}
              className="text-grey-900"
            />
          </div>
          <Typography
            size={TypographySize.lg}
            weight={TypographyWeight.medium}
            className="w-full text-center"
          >
            {intl.formatMessage({
              id: I18nKey.GENERIC_DETAILS,
            })}
          </Typography>
        </div>
        <div className="px-24 py-20 flex flex-col gap-24">
          {suggestedAdgroupProps && (
            <SuggestedAdgroupCell
              {...suggestedAdgroupProps}
              adgroupDetailsClassname="w-420"
            />
          )}
          <div className="border-solid border-b border-grey-200">
            <TabsSimple
              onTabSelect={onTabSelect}
              defaultTabIndex={activeTabIndex}
              tabs={getTabs(intl)}
              dataTestId={'view_create_in_agroup_tabs'}
            />
          </div>
          {getTabsData()}
        </div>
        <div
          className={classNames(
            'absolute bottom-0',
            'bg-white',
            'w-full p-12',
            'border-t-1 border-grey-200 border-solid'
          )}
        >
          <div className="flex justify-end">
            <Button
              state={ButtonState.Enabled}
              label={intl.formatMessage({ id: I18nKey.CLOSE })}
              onClick={onClose}
              variant={ButtonVariant.BlackAndWhiteBorder}
              size={ButtonSize.Large}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
