import isNil from 'lodash/isNil';
import { IntlShape } from 'react-intl';

import {
  Alignment,
  CardsProps,
  Formula,
  FormulaType,
  MetricCardsv2State,
  MetricCardsv2Status,
  Placement,
} from '@teikametrics/tm-design-system';

import { ReactNode } from 'react';
import I18nKey from '../../../../lib/types/I18nKey';
import { MetricTabsData, MetricTabsName } from '../../types';
import { MetricCardsGroupHeaders, TabPaths } from '../compassDashboard/types';

export const isMetricActive = (metricPath: string, pathname: string) =>
  metricPath === pathname;
export const metricTabsCards = (
  intl: IntlShape,
  metricTabsData: MetricTabsData | undefined,
  tabPaths: TabPaths,
  pathname: string,
  MetricsState: MetricCardsv2State | undefined
): CardsProps[] => [
  {
    cardBody: {
      content: metricTabsData?.totalSales.currentValue as string,
    },
    header: intl.formatMessage({ id: MetricCardsGroupHeaders.TotalSales }),
    cardTrend: {
      pillText: metricTabsData?.totalSales.pillText,
      status: metricTabsData?.totalSales.comparisonTrend as MetricCardsv2Status,
      caption: intl.formatMessage(
        { id: I18nKey.COMPASS_DASHBOARD_METRIC_TABS_CAPTION },
        { value: metricTabsData?.totalSales.previousValue }
      ),
    },
    isActive: isMetricActive(tabPaths[MetricTabsName.totalSales], pathname),
    path: tabPaths[MetricTabsName.totalSales],
    popOverLink: {
      header: intl.formatMessage({ id: MetricCardsGroupHeaders.TotalSales }),
      description: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_TOTAL_SALES_TOOLTIP_DESCRIPTION,
      }),
      position: {
        placement: Placement.Bottom,
        alignment: Alignment.Left,
      },
      showOnClick: true,
    },
    state: isNil(metricTabsData?.totalSales.currentValue)
      ? MetricCardsv2State.Loading
      : MetricsState,
    dataTestId: '1com_TotalSalesOverview',
  },
  {
    cardBody: {
      content: metricTabsData?.adSales.currentValue as string,
    },
    header: intl.formatMessage({ id: MetricCardsGroupHeaders.AdSales }),
    cardTrend: {
      pillText: metricTabsData?.adSales.pillText,
      status: metricTabsData?.adSales.comparisonTrend as MetricCardsv2Status,
      caption: intl.formatMessage(
        { id: I18nKey.COMPASS_DASHBOARD_METRIC_TABS_CAPTION },
        { value: metricTabsData?.adSales.previousValue }
      ),
    },
    isActive: isMetricActive(tabPaths[MetricTabsName.adSales], pathname),
    path: tabPaths[MetricTabsName.adSales],
    popOverLink: {
      header: intl.formatMessage({ id: MetricCardsGroupHeaders.AdSales }),
      description: intl.formatMessage<ReactNode>(
        { id: I18nKey.COMPASS_DASHBOARD_AD_SALES_TOOLTIP_DESCRIPTION },
        {
          span: (text: ReactNode) => (
            <span className="block mt-10">{text}</span>
          ),
        }
      ) as string,
      position: {
        placement: Placement.Bottom,
        alignment: Alignment.Left,
      },
      showOnClick: true,
    },
    state: isNil(metricTabsData?.adSales.currentValue)
      ? MetricCardsv2State.Loading
      : MetricsState,
    dataTestId: '1com_AdSalesOverview',
  },
  {
    header: intl.formatMessage({ id: MetricCardsGroupHeaders.AdSpend }),
    cardBody: {
      content: metricTabsData?.adSpend.currentValue as string,
    },
    cardTrend: {
      pillText: metricTabsData?.adSpend.pillText,
      status: metricTabsData?.adSpend.comparisonTrend as MetricCardsv2Status,
      caption: intl.formatMessage(
        { id: I18nKey.COMPASS_DASHBOARD_METRIC_TABS_CAPTION },
        { value: metricTabsData?.adSpend.previousValue }
      ),
    },
    isActive: isMetricActive(tabPaths[MetricTabsName.adSpend], pathname),
    path: tabPaths[MetricTabsName.adSpend],
    popOverLink: {
      header: intl.formatMessage({ id: MetricCardsGroupHeaders.AdSpend }),
      description: intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_AD_SPEND_TOOLTIP_DESCRIPTION,
      }),
      position: {
        placement: Placement.Bottom,
        alignment: Alignment.Left,
      },
      showOnClick: true,
    },
    state: isNil(metricTabsData?.adSpend.currentValue)
      ? MetricCardsv2State.Loading
      : MetricsState,
    dataTestId: '1com_AdSpendOverview',
  },
  {
    header: intl.formatMessage({ id: MetricCardsGroupHeaders.Tacos }),
    cardBody: {
      content: metricTabsData?.tacos.currentValue as string,
    },
    cardTrend: {
      pillText: metricTabsData?.tacos.pillText,
      status: metricTabsData?.tacos.comparisonTrend as MetricCardsv2Status,
      caption: intl.formatMessage(
        { id: I18nKey.COMPASS_DASHBOARD_METRIC_TABS_CAPTION },
        { value: metricTabsData?.tacos.previousValue }
      ),
    },
    isActive: isMetricActive(tabPaths[MetricTabsName.tacos], pathname),
    isNegativeUpTrend: true,
    path: tabPaths[MetricTabsName.tacos],
    popOverLink: {
      header: intl.formatMessage({ id: MetricCardsGroupHeaders.Tacos }),
      description: intl.formatMessage<ReactNode>(
        { id: I18nKey.COMPASS_DASHBOARD_TACOS_TOOLTIP_DESCRIPTION },
        {
          span: (text: ReactNode) => (
            <span className="block mt-10">{text}</span>
          ),
        }
      ) as string,
      formula: (
        <>
          <Formula
            type={FormulaType.Property}
            property={intl.formatMessage({
              id: MetricCardsGroupHeaders.AdSpend,
            })}
          />
          <Formula type={FormulaType.Divide} />
          <Formula
            type={FormulaType.Property}
            property={intl.formatMessage({
              id: MetricCardsGroupHeaders.TotalSales,
            })}
          />
        </>
      ),
      position: {
        placement: Placement.Bottom,
        alignment: Alignment.Left,
      },
      showOnClick: true,
    },
    state: isNil(metricTabsData?.tacos.currentValue)
      ? MetricCardsv2State.Loading
      : MetricsState,
    dataTestId: '1com_TACoSOverview',
  },
  {
    header: intl.formatMessage({ id: MetricCardsGroupHeaders.Roas }),
    cardBody: {
      content: metricTabsData?.roas?.currentValue as string,
    },
    cardTrend: {
      pillText: metricTabsData?.roas?.pillText,
      status: metricTabsData?.tacos.comparisonTrend as MetricCardsv2Status,
      caption: intl.formatMessage(
        { id: I18nKey.COMPASS_DASHBOARD_METRIC_TABS_CAPTION },
        { value: metricTabsData?.roas?.previousValue }
      ),
    },
    isActive: isMetricActive(tabPaths[MetricTabsName.roas], pathname),
    isNegativeUpTrend: true,
    path: tabPaths[MetricTabsName.roas],
    popOverLink: {
      header: intl.formatMessage({ id: MetricCardsGroupHeaders.Roas }),
      description: intl.formatMessage<ReactNode>(
        { id: I18nKey.COMPASS_DASHBOARD_TACOS_TOOLTIP_DESCRIPTION },
        {
          span: (text: ReactNode) => (
            <span className="block mt-10">{text}</span>
          ),
        }
      ) as string,
      formula: (
        <>
          <Formula
            type={FormulaType.Property}
            property={intl.formatMessage({
              id: MetricCardsGroupHeaders.AdSpend,
            })}
          />
          <Formula type={FormulaType.Divide} />
          <Formula
            type={FormulaType.Property}
            property={intl.formatMessage({
              id: MetricCardsGroupHeaders.Roas,
            })}
          />
        </>
      ),
      position: {
        placement: Placement.Bottom,
        alignment: Alignment.Left,
      },
      showOnClick: true,
    },
    state:
      isNil(metricTabsData?.roas?.currentValue) ||
      isNaN(Number(metricTabsData?.roas?.currentValue))
        ? MetricCardsv2State.Loading
        : MetricsState,
    dataTestId: '1com_RoasOverview',
  },
];

const MAX_PERCENTAGE_TO_SHOW_ON_COMPASS_PROGRESS_BAR = 99;
const MIN_PERCENTAGE_TO_SHOW_ON_COMPASS_PROGRESS_BAR = 1;

export const roundOfPercentageToFloorOrCeil = (value: number): number => {
  if (value > MAX_PERCENTAGE_TO_SHOW_ON_COMPASS_PROGRESS_BAR) {
    return Math.floor(value);
  } else if (value < MIN_PERCENTAGE_TO_SHOW_ON_COMPASS_PROGRESS_BAR) {
    return Math.ceil(value);
  }
  return Math.round(value);
};

export const getAORedirectURL = (
  salesChannel: string,
  merchantCountryId: string,
  adType: string,
  adLevel: string,
  startDate?: string,
  endDate?: string
) => {
  let url = `/ads-optimization/ads-manager/${salesChannel}/${
    adType === 'sponsored-video' ? 'sponsored-videos' : adType
  }/${adLevel}?merchantCountryIds=${merchantCountryId}`;
  if (startDate && endDate) {
    url += `&startDate=${startDate}&endDate=${endDate}`;
  }
  return url;
};
