import React from 'react';
import { useIntl } from 'react-intl';

import { ColumnAlign } from '@teikametrics/tm-design-system';

import { FlywheelTableV2ColumnProps } from '../../../../../../containers/tableV2/types';
import { HomepageResponseMetricKeys } from '../../../../../../lib/types/CompassSharedTypes';
import I18nKey from '../../../../../../lib/types/I18nKey';
import { makeNumberWithProgressBarColumn } from '../../../../../products/containers/utils/makeTableCellsUtils';
import { BusinessPerformanceColumnName } from '../../utils/constants';
import {
  MerchantCellData,
  MerchantRowData,
  PerformanceTableExtraProps,
  getNumberWithProgressBarColumnProps,
} from '../../utils/performance';

export const RowCellElement: React.FC<MerchantCellData> = (data) => {
  const intl = useIntl();
  return makeNumberWithProgressBarColumn<MerchantCellData>(
    ({ performanceMetrics, adSpend, activeColumn }) => {
      const currentValue = +(
        performanceMetrics?.[HomepageResponseMetricKeys.AdSpend]?.amount || 0
      );
      const adSpendAmount = +(adSpend?.amount || 0);

      return getNumberWithProgressBarColumnProps(
        intl,
        currentValue,
        adSpendAmount,
        activeColumn === BusinessPerformanceColumnName.AdSpend,
        performanceMetrics?.[HomepageResponseMetricKeys.AdSpend]?.currency
      );
    }
  )(data);
};

export const adSpend: FlywheelTableV2ColumnProps<
  MerchantRowData,
  {},
  PerformanceTableExtraProps
> = {
  header: I18nKey.ADVERTISING_OPTIMIZATION_CAMPAIGNS_AD_SPEND,
  sortable: false,
  size: 135,
  minSize: 135,
  accessorKey: BusinessPerformanceColumnName.AdSpend,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <RowCellElement {...original} {...meta} />,
};
