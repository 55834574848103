import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Checkbox,
  CheckboxCheckedState,
  PortaledTooltip,
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
} from '@teikametrics/tm-design-system';

import I18nKey, { isI18nKey } from '../../lib/types/I18nKey';
import { ConnectedFlywheelTableSortableHeaderImpl as SortableHeader } from './sortableHeader';

const FlywheelTableHeader: React.FC<
  Readonly<{
    columnName: string;
    i18nKeyOrLabel: I18nKey | string;
    tableId: string;
    isSortable: boolean;
    separator?: JSX.Element;
    toolTipContent?: string | JSX.Element;
    dataTestId?: string;
    showCheckBox?: boolean;
    checked?: CheckboxCheckedState;
    onSelectChange?: React.MouseEventHandler<HTMLElement>;
    columnHeaderClassName?: string;
  }>
> = ({
  columnName,
  i18nKeyOrLabel,
  tableId,
  separator,
  isSortable,
  toolTipContent,
  dataTestId,
  checked,
  onSelectChange,
  showCheckBox,
  columnHeaderClassName,
}) => {
  const headerText = isI18nKey(i18nKeyOrLabel) ? (
    <FormattedMessage id={i18nKeyOrLabel} values={{ separator }} />
  ) : (
    <>{i18nKeyOrLabel}</>
  );

  const headerTextComponent = toolTipContent ? (
    <span className="flex items-center">
      {showCheckBox && (
        <Checkbox
          onClick={onSelectChange}
          checked={checked}
          dataTestId={dataTestId}
          className="mr-8"
        />
      )}
      <PortaledTooltip
        theme={PortaledTooltipTheme.Dark}
        content={toolTipContent}
        position={{
          placement: PortaledTooltipPlacement.Bottom,
          alignmentOffsetInPixels: 10,
        }}
        dataTestId={`${dataTestId}_flywheelTable`}
      >
        {headerText}
      </PortaledTooltip>
    </span>
  ) : (
    headerText
  );

  return isSortable ? (
    <span className={`flex items-center ${columnHeaderClassName}`}>
      {showCheckBox && (
        <Checkbox
          onClick={onSelectChange}
          checked={checked}
          dataTestId="flywheelTable_header"
          className="mr-8"
        />
      )}
      <SortableHeader columnName={columnName} tableId={tableId}>
        {headerTextComponent}
      </SortableHeader>
    </span>
  ) : (
    headerTextComponent
  );
};
FlywheelTableHeader.displayName = 'FlywheelTableHeader';
export default FlywheelTableHeader;
