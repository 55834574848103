import React from 'react';

import {
  AlertTriangleIcon,
  TableEmptyState,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../lib/types/I18nKey';

export const NoFilteredDataFoundTable: React.FC = () => (
  <TableEmptyState
    descriptionI18nKey={
      I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_NO_MATCHED_RESULTS_DESCRIPTION
    }
    icon={AlertTriangleIcon}
    titleI18nKey={I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_NO_MATCHED_RESULTS_TITLE}
    className="absolute top-250 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
  />
);
NoFilteredDataFoundTable.displayName = 'NoFilteredDataFoundTable';
