import { IconPlacement } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextWithIconColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AddedBy,
  AdvertisingGoal,
  CampaignDetails,
  MAP_CAMPAIGN_TYPE_TO_I18NKEY,
  MAP_CAMPAIGN_TYPE_TO_ICON,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  getCampaignTypeFromAddedBy,
} from '../utils';
import { useIntl } from 'react-intl';

export const RowCellElement: React.FC<CampaignDetails> = ({
  ...campaignDetails
}) => {
  const intl = useIntl();
  const goal =
    campaignDetails.campaignDetails.advertisingGoal || AdvertisingGoal.External;
  const campaignType = getCampaignTypeFromAddedBy(
    goal !== AdvertisingGoal.External
      ? AddedBy.FlywheelAutomated
      : campaignDetails.campaignDetails.addedBy
  );
  return makeTextWithIconColumn(
    () =>
      intl.formatMessage({ id: MAP_CAMPAIGN_TYPE_TO_I18NKEY[campaignType] }),
    MAP_CAMPAIGN_TYPE_TO_ICON[campaignType],
    '',
    undefined,
    undefined,
    IconPlacement.Left
  )(campaignDetails);
};
RowCellElement.displayName = 'CampaignType';

export const campaignTypeColumn: FlywheelTableColumn<CampaignDetails> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.CampaignType,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TYPE,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '120px',
};
