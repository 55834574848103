import { IntlShape } from 'react-intl';
import I18nKey from '../../../../lib/types/I18nKey';
import { KeywordActionColumns } from '../../../../lib/types/KeywordAction';
import { ColumnManagerOptionWithIsSelected } from './types';

export const getColumnManagerOptionsRecommendationsTableV2 = (
  intl: IntlShape,
  newKWAUI?: boolean
): ColumnManagerOptionWithIsSelected[] => {
  const reasonColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_DETAILS_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_REASON_HEADER,
    }),
    value: KeywordActionColumns.REASON,
    isSelected: true,
  };
  const sourceColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_DETAILS_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SOURCE_HEADER,
    }),
    value: KeywordActionColumns.SOURCE,
    isSelected: true,
  };

  const adSpendColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_AD_SPEND_HEADER,
    }),
    value: KeywordActionColumns.AD_SPEND,
    isSelected: true,
  };

  const adSalesColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_AD_SALES_HEADER,
    }),
    value: KeywordActionColumns.AD_SALES,
    isSelected: true,
  };

  const acosColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_ACOS_HEADER,
    }),
    value: KeywordActionColumns.ACOS,
    isSelected: true,
  };

  const roasColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_ROAS_HEADER,
    }),
    value: KeywordActionColumns.ROAS,
    isSelected: false,
  };

  const adConversionsColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_AD_CONVERSIONS_HEADER,
    }),
    value: KeywordActionColumns.AD_CONVERSIONS,
    isSelected: true,
  };

  const conversionRateColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_AD_CONVERSION_RATE_HEADER,
    }),
    value: KeywordActionColumns.CONVERSION_RATE,
    isSelected: false,
  };

  const ctrColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_CLICK_THROUGH_RATE_HEADER,
    }),
    value: KeywordActionColumns.CTR,
    isSelected: false,
  };

  const cpcColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_COST_PER_CLICK_HEADER,
    }),
    value: KeywordActionColumns.CPC,
    isSelected: false,
  };

  const impressionsColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_IMPRESSIONS_HEADER,
    }),
    value: KeywordActionColumns.IMPRESSIONS,
    isSelected: false,
  };

  const clicksColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_CLICKS_HEADER,
    }),
    value: KeywordActionColumns.CLICKS,
    isSelected: true,
  };

  if (newKWAUI) {
    return [
      adSalesColumn,
      adSpendColumn,
      acosColumn,
      roasColumn,
      conversionRateColumn,
      impressionsColumn,
      cpcColumn,
      ctrColumn,
    ];
  }

  return [
    reasonColumn,
    sourceColumn,
    adSalesColumn,
    adSpendColumn,
    acosColumn,
    roasColumn,
    adConversionsColumn,
    conversionRateColumn,
    impressionsColumn,
    clicksColumn,
    cpcColumn,
    ctrColumn,
  ];
};
