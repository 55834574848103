import { Factory } from 'fishery';

import { SkuCatalogSummary } from '../../../lib/types/SKUSharedTypes';
import { PaginatedResult } from '../../clients/types';

export const productPerformanceResponse = Factory.define<PaginatedResult<any>>(
  () => ({
    items: [
      {
        productGroupId: 'aaefeb4b-7480-5bcb-8c86-bc86a368dc95',
        merchantCountryId: 'b885c24c-4e92-4a30-a659-8b58223efd6d',
        skuDetailsFields: {
          sku: '2261MDFT',
          name: 'product1',
          imageUrl: 'https://m.media-amazon.com/images/I/41QThapRCfL.jpg',
          extItemDetails: [
            {
              extItemId: 'B01FXSYJ7S',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: 'B07HK4Y2SR',
            },
            {
              extItemId: 'B01FXSYJ7S',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: 'B084BW1517',
            },
            {
              extItemId: 'B01FXSYJ7S',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: undefined,
            },
          ],
          status: undefined,
          productCategory: undefined,
          categoryPath: undefined,
          primaryVariant: undefined,
        },
        currentMetrics: {
          productFields: {
            tacos: 0.05082243100554352,
            averageOrderPrice: undefined,
            category: undefined,
            status: undefined,
            categoryPath: undefined,
          },
          profitabilityFields: {
            totalSales: {
              amount: '117183.69',
              currency: 'USD',
            },
            estimatedGrossProfit: undefined,
            unitsSold: undefined,
            estimatedGrossMargin: undefined,
            estimatedPreAdGrossMargin: undefined,
          },
          platformFeesFields: {
            cogs: undefined,
            estimatedFees: undefined,
          },
          advertisementFields: {
            adSales: {
              amount: '26980.86',
              currency: 'USD',
            },
            adSpend: {
              amount: ' 5955.56',
              currency: 'USD',
            },
            acos: undefined,
            conversionRate: undefined,
            campaignCount: undefined,
            adGroupCount: undefined,
            clicks: undefined,
            adConversions: undefined,
            costPerClick: {
              amount: '1.1232666918144096',
              currency: 'USD',
            },
            roas: undefined,
            impressions: 1407672,
            clickThroughRate: 0.003766502423860104,
            adUnitsSold: undefined,
          },
          inventoryFields: {
            inventoryValue: undefined,
            sellThroughRate: undefined,
            inventoryQuantity: undefined,
          },
        },
      },
      {
        productGroupId: '291cbe9f-56e0-56dd-a389-25b1cbcd5741',
        merchantCountryId: 'b885c24c-4e92-4a30-a659-8b58223efd6d',
        skuDetailsFields: {
          sku: '00747MDG',
          name: 'product2',
          imageUrl: 'https://m.media-amazon.com/images/I/413V1NTsTXL.jpg',
          extItemDetails: [
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'walmart',
              extParentItemId: undefined,
            },
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: 'B081K6T7RQ',
            },
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: 'B08J2C2J5K',
            },
          ],
          status: undefined,
          productCategory: undefined,
          categoryPath: undefined,
          primaryVariant: undefined,
        },
        currentMetrics: {
          productFields: {
            tacos: 0.03284177514807401,
            averageOrderPrice: undefined,
            category: undefined,
            status: undefined,
            categoryPath: undefined,
          },
          profitabilityFields: {
            totalSales: {
              amount: '97930.76',
              currency: 'USD',
            },
            estimatedGrossProfit: undefined,
            unitsSold: undefined,
            estimatedGrossMargin: undefined,
            estimatedPreAdGrossMargin: undefined,
          },
          platformFeesFields: {
            cogs: undefined,
            estimatedFees: undefined,
          },
          advertisementFields: {
            adSales: {
              amount: '27378.44',
              currency: 'USD',
            },
            adSpend: {
              amount: '3216.22',
              currency: 'USD',
            },
            acos: undefined,
            conversionRate: undefined,
            campaignCount: undefined,
            adGroupCount: undefined,
            clicks: undefined,
            adConversions: undefined,
            costPerClick: {
              amount: '0.4584122006841505',
              currency: 'USD',
            },
            roas: undefined,
            impressions: 674961,
            clickThroughRate: 0.01039467465527638,
            adUnitsSold: undefined,
          },
          inventoryFields: {
            inventoryValue: undefined,
            sellThroughRate: undefined,
            inventoryQuantity: undefined,
          },
        },
      },
      {
        productGroupId: '271cbe9f-56e0-56dd-a389-25b1cbcd5741',
        merchantCountryId: 'b885c24c-4e92-4a30-a659-8b58223efd6d',
        skuDetailsFields: {
          sku: '00747MDG',
          name: 'product2',
          imageUrl: 'https://m.media-amazon.com/images/I/413V1NTsTXL.jpg',
          extItemDetails: [
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: undefined,
            },
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: 'B081K6T7RQ',
            },
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: 'B08J2C2J5K',
            },
          ],
          status: undefined,
          productCategory: undefined,
          categoryPath: undefined,
          primaryVariant: undefined,
        },
        currentMetrics: {
          productFields: {
            tacos: 0.03284177514807401,
            averageOrderPrice: undefined,
            category: undefined,
            status: undefined,
            categoryPath: undefined,
          },
          profitabilityFields: {
            totalSales: {
              amount: '97930.76',
              currency: 'USD',
            },
            estimatedGrossProfit: undefined,
            unitsSold: undefined,
            estimatedGrossMargin: undefined,
            estimatedPreAdGrossMargin: undefined,
          },
          platformFeesFields: {
            cogs: undefined,
            estimatedFees: undefined,
          },
          advertisementFields: {
            adSales: {
              amount: '27378.44',
              currency: 'USD',
            },
            adSpend: {
              amount: '3216.22',
              currency: 'USD',
            },
            acos: undefined,
            conversionRate: undefined,
            campaignCount: undefined,
            adGroupCount: undefined,
            clicks: undefined,
            adConversions: undefined,
            costPerClick: {
              amount: '0.4584122006841505',
              currency: 'USD',
            },
            roas: undefined,
            impressions: 674961,
            clickThroughRate: 0.01039467465527638,
            adUnitsSold: undefined,
          },
          inventoryFields: {
            inventoryValue: undefined,
            sellThroughRate: undefined,
            inventoryQuantity: undefined,
          },
        },
      },
      {
        productGroupId: '292cbe9f-56e0-56dd-a389-25b1cbcd5741',
        merchantCountryId: 'b885c24c-4e92-4a30-a659-8b58223efd6d',
        skuDetailsFields: {
          sku: '00747MDG',
          name: 'product2',
          imageUrl: 'https://m.media-amazon.com/images/I/413V1NTsTXL.jpg',
          extItemDetails: [
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'walmart',
              extParentItemId: undefined,
            },
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: 'B081K6T7RQ',
            },
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: 'B08J2C2J5K',
            },
          ],
          status: undefined,
          productCategory: undefined,
          categoryPath: undefined,
          primaryVariant: undefined,
        },
        currentMetrics: {
          productFields: {
            tacos: 0.03284177514807401,
            averageOrderPrice: undefined,
            category: undefined,
            status: undefined,
            categoryPath: undefined,
          },
          profitabilityFields: {
            totalSales: {
              amount: '97930.76',
              currency: 'USD',
            },
            estimatedGrossProfit: undefined,
            unitsSold: undefined,
            estimatedGrossMargin: undefined,
            estimatedPreAdGrossMargin: undefined,
          },
          platformFeesFields: {
            cogs: undefined,
            estimatedFees: undefined,
          },
          advertisementFields: {
            adSales: {
              amount: '27378.44',
              currency: 'USD',
            },
            adSpend: {
              amount: '3216.22',
              currency: 'USD',
            },
            acos: undefined,
            conversionRate: undefined,
            campaignCount: undefined,
            adGroupCount: undefined,
            clicks: undefined,
            adConversions: undefined,
            costPerClick: {
              amount: '0.4584122006841505',
              currency: 'USD',
            },
            roas: undefined,
            impressions: 674961,
            clickThroughRate: 0.01039467465527638,
            adUnitsSold: undefined,
          },
          inventoryFields: {
            inventoryValue: undefined,
            sellThroughRate: undefined,
            inventoryQuantity: undefined,
          },
        },
      },
      {
        productGroupId: '291cbs9f-56e0-56dd-a389-25b1cbcd5741',
        merchantCountryId: 'b885c24c-4e92-4a30-a659-8b58223efd6d',
        skuDetailsFields: {
          sku: '00747MDG',
          name: 'product2',
          imageUrl: 'https://m.media-amazon.com/images/I/413V1NTsTXL.jpg',
          extItemDetails: [
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: undefined,
            },
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: 'B081K6T7RQ',
            },
            {
              extItemId: 'B077Z7DC8G',
              extItemType: 'ASIN',
              salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
              salesChannelName: 'amazon',
              extParentItemId: 'B08J2C2J5K',
            },
          ],
          status: undefined,
          productCategory: undefined,
          categoryPath: undefined,
          primaryVariant: undefined,
        },
        currentMetrics: {
          productFields: {
            tacos: 0.03284177514807401,
            averageOrderPrice: undefined,
            category: undefined,
            status: undefined,
            categoryPath: undefined,
          },
          profitabilityFields: {
            totalSales: {
              amount: '97930.76',
              currency: 'USD',
            },
            estimatedGrossProfit: undefined,
            unitsSold: undefined,
            estimatedGrossMargin: undefined,
            estimatedPreAdGrossMargin: undefined,
          },
          platformFeesFields: {
            cogs: undefined,
            estimatedFees: undefined,
          },
          advertisementFields: {
            adSales: {
              amount: '27378.44',
              currency: 'USD',
            },
            adSpend: {
              amount: '3216.22',
              currency: 'USD',
            },
            acos: undefined,
            conversionRate: undefined,
            campaignCount: undefined,
            adGroupCount: undefined,
            clicks: undefined,
            adConversions: undefined,
            costPerClick: {
              amount: '0.4584122006841505',
              currency: 'USD',
            },
            roas: undefined,
            impressions: 674961,
            clickThroughRate: 0.01039467465527638,
            adUnitsSold: undefined,
          },
          inventoryFields: {
            inventoryValue: undefined,
            sellThroughRate: undefined,
            inventoryQuantity: undefined,
          },
        },
      },
    ],
    totalItems: 41582,
  })
);

export const productPerformanceSummaryResponse =
  Factory.define<SkuCatalogSummary>(() => ({
    productFields: {
      tacos: 0.08566504190294992,
      averageOrderPrice: {
        amount: '25.324519374277315',
        currency: 'USD',
      },
      category: undefined,
      status: undefined,
      categoryPath: undefined,
    },
    profitabilityFields: {
      totalSales: {
        amount: '14914901.01',
        currency: 'USD',
      },
      estimatedGrossProfit: {
        amount: '16225.15',
        currency: 'USD',
      },
      unitsSold: 588951,
      estimatedGrossMargin: 0.0010878483195511332,
      estimatedPreAdGrossMargin: 0.0011883826777070914,
    },
    platformFeesFields: {
      cogs: {
        amount: '10233.91',
        currency: 'USD',
      },
      estimatedFees: {
        amount: '6851696.08',
        currency: 'USD',
      },
    },
    advertisementFields: {
      adSales: {
        amount: '7522064.66',
        currency: 'USD',
      },
      adSpend: {
        amount: '1277685.62',
        currency: 'USD',
      },
      acos: 0.16985836705104845,
      conversionRate: 0.08283520011724507,
      campaignCount: 466731,
      adGroupCount: 516335,
      clicks: 2947672,
      adConversions: 244171,
      costPerClick: {
        amount: '3910.8142768397647',
        currency: 'USD',
      },
      roas: 1060398.3629009912,
      impressions: 728187700,
      clickThroughRate: 67.45754166378734,
      adUnitsSold: 588951,
    },
    inventoryFields: {
      inventoryValue: {
        amount: '11372258.909188',
        currency: 'USD',
      },
      sellThroughRate: undefined,
      inventoryQuantity: 3611178,
    },
    metricsPeriodType: 'current',
    totalSkuCount: 41582,
  }));
