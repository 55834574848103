import { Factory } from 'fishery';
import { DateTime } from 'luxon';

import {
  ProductSubscriptionEventResponse,
  SubscriptionActionType,
} from '../../types/Fam';

export const GetProductSubscriptionEventResponse =
  Factory.define<ProductSubscriptionEventResponse>(() => ({
    accountId: 'account-id-uuid',
    productVerticalId: 'product-vertical-id-uuid',
    accountEvents: [
      {
        action: SubscriptionActionType.Deleted,
        date: DateTime.local().minus({ day: 5 }).toISODate().toString(),
      },
      {
        action: SubscriptionActionType.Deleted,
        date: DateTime.local().minus({ day: 12 }).toISODate().toString(),
      },
      {
        action: SubscriptionActionType.Deleted,
        date: DateTime.local().minus({ day: 16 }).toISODate().toString(),
      },
      {
        action: SubscriptionActionType.Deleted,
        date: DateTime.local().minus({ day: 33 }).toISODate().toString(),
      },
      {
        action: SubscriptionActionType.Deleted,
        date: DateTime.local().minus({ day: 68 }).toISODate().toString(),
      },
    ],
    merchantCountryEvents: [
      {
        merchantCountryId: 'merchant-country-id-uuid',
        action: SubscriptionActionType.Deleted,
        date: DateTime.local().minus({ day: 5 }).toISODate().toString(),
      },
    ],
  }));
