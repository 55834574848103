import { useContext } from 'react';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { CampaignDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  MerchantContext,
  MerchantContextState,
} from '../../../merchantsProvider';
import { CAMPAIGNS_API_COLUMN_NAME, getMerchantName } from '../utils';

export const RowCellElement: React.FC<CampaignDetails> = ({
  campaignDetails,
  campaignPerformance,
  campaignId,
  channelSettings,
  flywheelSettings,
}) => {
  const campaignData: CampaignDetails = {
    campaignDetails,
    campaignPerformance,
    campaignId,
    channelSettings,
    flywheelSettings,
  };

  const merchantContext = useContext<MerchantContextState>(MerchantContext);

  const merchantDetails = merchantContext.merchantDetails;

  return makeTextColumn((data: CampaignDetails) =>
    getMerchantName(merchantDetails, data.campaignDetails.merchantCountryId)
  )(campaignData);
};
RowCellElement.displayName = 'RowCellElement';

export const merchantNameColumn: FlywheelTableColumn<CampaignDetails> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.MerchantName,
  isSortable: false,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MERCHANT_NAME,
  RowCellElement,
  gridColumnWidth: '240px',
};
