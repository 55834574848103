import { useIntl } from 'react-intl';

import {
  PortaledTooltipPlacement,
  createMultiSelectDataFieldFilter,
  AlertTriangleIcon as warningSVG,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeTextColumn,
  makeTextWithIconColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  KeywordReviewStatusType,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TooltipText } from '../reviewStatusTooltipText';
import { KEYWORD_REVIEW_STATUS_MAPPER, TableDataAdsManager } from '../types';
import { TARGETS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) => {
  const intl = useIntl();

  const getColumnText = ({ targetDetails }: TargetsDetails) =>
    targetDetails?.keywordReviewStatus &&
    KEYWORD_REVIEW_STATUS_MAPPER[
      targetDetails?.keywordReviewStatus?.reviewStatus
    ]
      ? intl.formatMessage({
          id: KEYWORD_REVIEW_STATUS_MAPPER[
            targetDetails?.keywordReviewStatus?.reviewStatus
          ],
        })
      : undefined;

  if (
    props.targetDetails?.keywordReviewStatus?.reviewStatus ===
    KeywordReviewStatusType.Rejected
  ) {
    return makeTextWithIconColumn(
      getColumnText,
      warningSVG,
      <TooltipText
        comments={props.targetDetails?.keywordReviewStatus?.reviewComments}
      />,
      true,
      PortaledTooltipPlacement.Right
    )(props);
  }

  return makeTextColumn(getColumnText, false)(props);
};
RowCellElement.displayName = 'RowCellElement';

export const keywordReviewStatusColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.KeywordReviewStatus,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_KEYWORD_REVIEW_STATUS,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '180px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const keywordReviewStatusFilter = createMultiSelectDataFieldFilter(
  TARGETS_API_COLUMN_NAME.KeywordReviewStatus,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_KEYWORD_REVIEW_STATUS,
  [
    SelectFilterOption.KeywordReviewStatusPending,
    SelectFilterOption.KeywordReviewStatusApproved,
    SelectFilterOption.KeywordReviewStatusRejected,
  ]
);
