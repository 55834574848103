import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { PRODUCT_ADS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) => {
  return makeTextColumn(
    ({ adItemDetails }: ProductAdDetails) =>
      adItemDetails?.campaignPortfolioName,
    props.pendingFields?.includes(PRODUCT_ADS_API_COLUMN_NAME.Portfolio)
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const portfolioNameColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: PRODUCT_ADS_API_COLUMN_NAME.Portfolio,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_PORTFOLIO_NAME,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const portfolioNameFilter = createStringDataFieldFilter(
  PRODUCT_ADS_API_COLUMN_NAME.Portfolio,
  I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_PORTFOLIO_NAME,
  isValidString()
);
