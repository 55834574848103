import I18nKey from '../../../../../lib/types/I18nKey';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  CrossMiniIcon,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { FormattedMessage } from 'react-intl';

export interface SalesChannelSyncStatusHeaderProps {
  readonly closeSalesChannelSyncStatusSlideOut: () => void;
}
export const SalesChannelSyncStatusHeader: React.FC<
  SalesChannelSyncStatusHeaderProps
> = ({ closeSalesChannelSyncStatusSlideOut }) => {
  return (
    <div className="flex flex-row items-center justify-center h-48 px-12 gap-8">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.ActionWithoutBorder}
        svgIcon={CrossMiniIcon}
        onClick={closeSalesChannelSyncStatusSlideOut}
        className="flex flex-row items-start"
        dataTestId={`sync_status_slide_out_close`}
      />
      <Typography
        size={TypographySize.base}
        lineHeight={TypographyLineHeight.none}
        className="text-grey-900 w-full flex flex-row items-center justify-center"
        weight={TypographyWeight.medium}
      >
        <FormattedMessage id={I18nKey.SYNC_STATUS_SLIDE_OUT_HEADER} />
      </Typography>
    </div>
  );
};

SalesChannelSyncStatusHeader.displayName = 'SalesChannelSyncStatusHeader';
