import React, { useContext, useEffect, useRef, useState } from 'react';

import { IdToken } from '@auth0/auth0-react';
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import {
  AsyncRequest,
  AsyncRequestCompleted,
  AsyncRequestFailed,
  AsyncRequestLoading,
  AsyncRequestNotStarted,
  asyncRequestIsComplete,
} from '../../lib/utilities/asyncRequest';

import { useQuery } from '@tanstack/react-query';
import { CancelTokenSource } from 'axios';
import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { createAOApiClient } from '../../lib/clients/AOApiClient';
import { createCompassApiClient } from '../../lib/clients/CompassApiClient';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { createMIApiClient } from '../../lib/clients/MIApiClient';
import { createSKUApiClient } from '../../lib/clients/SKUApiClient';
import { PRODUCT_SUBSCRIPTIONS } from '../../lib/types/AOSharedTypes';
import {
  AggregationPeriodType,
  HomePageData,
  HomepageResponseMetricKeys,
  MetricData,
} from '../../lib/types/CompassSharedTypes';
import { ProductSubscriptionEventResponse } from '../../lib/types/Fam';
import { CompassQueryKeys } from '../../lib/types/ReactQueryKeys';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../lib/types/SalesChannels';
import { CurrencyCode } from '../../lib/utilities/currency';
import { ViewTrendsContextProvider } from '../advertisingOptimization/components/ViewTrends/ViewTrendsProvider';
import { CompassContextSetterContext } from './compassContextSetterProvider';
import { CompassDashboard } from './containers/compassDashboard';
import { Ads } from './containers/compassDashboard/ads';
import { Business } from './containers/compassDashboard/business';
import { ALL_COMPASS_METRIC_FIELDS } from './containers/compassDashboard/businessGraphV2/types';
import { Products } from './containers/compassDashboard/products';
import {
  BusinessPerformanceRoutes,
  CompassRoutes,
  CompassTabDefaultRoutes,
  DashboardRoutes,
} from './containers/compassDashboard/types';
import {
  clearCompassAPIFailCount,
  incrementCompassAPIFailCount,
} from './storageUtils';
import {
  COMPASS_API_REQUEST_DATE_FORMAT,
  DateRange,
  HomePageDataRequestExceptMetricAndPerformance,
} from './types';

export interface HomepageDataRequestResponse {
  readonly current: HomePageData;
  readonly previous: HomePageData;
}

export interface TmIndexResponse {
  readonly salesChannelId: string;
  readonly [HomepageResponseMetricKeys.Roas]?: MetricData;
  readonly [HomepageResponseMetricKeys.Tacos]?: MetricData;
  readonly [HomepageResponseMetricKeys.CPC]?: MetricData;
  readonly [HomepageResponseMetricKeys.ACoSTotal]?: MetricData;
}

export interface TmIndexDataResponse {
  readonly currency: string;
  readonly elements: TmIndexResponse[];
}

interface OwnProps {
  readonly idToken: IdToken | null;
}

export const Compass: React.FC<OwnProps> = (props) => {
  const [homepageDataRequest, setHomepageDataRequest] = useState<
    AsyncRequest<HomepageDataRequestResponse>
  >(AsyncRequestNotStarted());

  const currentApiCount = useRef<number>(0);
  const previousApiCount = useRef<number>(0);
  const [productSubscriptionEventData, setProductSubscriptionEventData] =
    useState<AsyncRequest<ProductSubscriptionEventResponse>>(
      AsyncRequestNotStarted()
    );
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;
  const aoApiClient = createAOApiClient(props.idToken);
  const skuApiClient = createSKUApiClient(props.idToken);
  const famApiClient = createFAMApiClient(props.idToken!);
  const miApiClient = createMIApiClient(props.idToken);
  const compassApiClient = createCompassApiClient(props.idToken);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    contextSetterValues: {
      selectedMerchantsId,
      selectedDateRange,
      selectedCountries,
      selectedCurrency,
    },
    isContextDataLoading,
  } = useContext(CompassContextSetterContext);

  const compassBusinessRouteMatch = useMatch(CompassTabDefaultRoutes.Business);
  const compassAdsRouteMatch = useMatch(CompassTabDefaultRoutes.Ads);
  const compassProductsRouteMatch = useMatch(CompassTabDefaultRoutes.Products);
  const doesCompassRouteMatch =
    compassBusinessRouteMatch ||
    compassAdsRouteMatch ||
    compassProductsRouteMatch;

  const userInfo = {
    userId: userContext.userInfo.userDetails?.id ?? '',
    accountId,
  };

  const params = new URLSearchParams(location.search);
  const productTourId = params.get('product_tour_id');

  const {
    isLoading: isLoadingMetricsData,
    isError: isErrorMetricsData,
    data: metricsData,
  } = useQuery({
    queryKey: [
      CompassQueryKeys.GetMetricsData,
      selectedCountries,
      selectedCurrency,
      selectedMerchantsId,
      selectedDateRange,
      accountId,
    ],
    queryFn: () => {
      try {
        const tmIndexDataResponse = compassApiClient.getTmIndexMetricsData(
          accountId
        )({
          startDate: selectedDateRange.startDate.toISODate() as string,
          endDate: selectedDateRange.endDate.toISODate() as string,
          currency: selectedCurrency || CurrencyCode.USD,
          countries: selectedCountries as string[],
          salesChannelIds: [AMAZON_SALES_CHANNEL_ID, WALMART_SALES_CHANNEL_ID],
        });
        return tmIndexDataResponse
          .then((res) => {
            return res;
          })
          .catch(() => {
            return undefined;
          });
      } catch (err) {
        return undefined;
      }
    },
    enabled: !!(
      !isContextDataLoading &&
      selectedDateRange &&
      selectedCountries &&
      selectedCountries.length > 0
    ),
  });

  const { data: verticals } = useQuery({
    queryKey: [CompassQueryKeys.GetProductVerticals, accountId],
    queryFn: () => famApiClient.getProductVerticals(),
  });

  const { data: subscriptionInformation } = useQuery({
    queryKey: [
      CompassQueryKeys.GetSubscriptionInformation,
      verticals,
      accountId,
    ],
    queryFn: () => {
      const maybeAiVerticalId = verticals?.find(
        (v) => v.name === PRODUCT_SUBSCRIPTIONS.AI
      )?.id;
      if (maybeAiVerticalId) {
        return famApiClient.getProductSubscriptionEvents(
          accountId,
          maybeAiVerticalId as string
        );
      } else {
        return Promise.resolve({} as ProductSubscriptionEventResponse);
      }
    },
  });

  const getHomepageData = async (
    requestData: HomePageDataRequestExceptMetricAndPerformance,
    controller?: AbortController,
    source?: CancelTokenSource,
    clearController?: () => void
  ) => {
    const allPerformanceFieldsForCompassBusinessTab = ALL_COMPASS_METRIC_FIELDS;
    try {
      setHomepageDataRequest(AsyncRequestLoading());
      const currentData = {
        ...requestData,
        metricsPeriodType: AggregationPeriodType.Current,
        performanceFields: allPerformanceFieldsForCompassBusinessTab,
      };
      const homepageDataCurrentDataRequest = compassApiClient.getHomePageData(
        accountId
      )(currentData, controller, source);
      const previousData = {
        ...requestData,
        metricsPeriodType: AggregationPeriodType.Previous,
        performanceFields: allPerformanceFieldsForCompassBusinessTab,
      };
      const homepageDataPreviousDataRequest = compassApiClient.getHomePageData(
        accountId
      )(previousData, controller, source);

      const [
        homepageDataCurrentDataResponse,
        homepageDataPreviousDataResponse,
      ] = await Promise.all([
        homepageDataCurrentDataRequest,
        homepageDataPreviousDataRequest,
      ]);

      if (homepageDataCurrentDataResponse.aoRefreshNeeded) {
        getHomePageDataFromAORefresh(
          requestData,
          AggregationPeriodType.Current
        );
      }

      if (homepageDataPreviousDataResponse.aoRefreshNeeded) {
        getHomePageDataFromAORefresh(
          requestData,
          AggregationPeriodType.Previous
        );
      }

      setHomepageDataRequest(
        AsyncRequestCompleted({
          current: homepageDataCurrentDataResponse,
          previous: homepageDataPreviousDataResponse,
        })
      );

      if (subscriptionInformation) {
        setProductSubscriptionEventData(
          AsyncRequestCompleted(subscriptionInformation)
        );
      }
      clearCompassAPIFailCount(userInfo);
      clearController && clearController();
    } catch (err: any) {
      if (err.message !== 'canceled') {
        setHomepageDataRequest(AsyncRequestFailed(undefined));
        setProductSubscriptionEventData(AsyncRequestFailed(undefined));
        incrementCompassAPIFailCount(userInfo);
      }
    }
  };

  const getHomePageDataFromAORefresh = async (
    requestData: HomePageDataRequestExceptMetricAndPerformance,
    aggregationPeriodType: AggregationPeriodType
  ) => {
    const allPerformanceFieldsForCompassBusinessTab = ALL_COMPASS_METRIC_FIELDS;
    try {
      const currentData = {
        ...requestData,
        metricsPeriodType: aggregationPeriodType,
        performanceFields: allPerformanceFieldsForCompassBusinessTab,
      };
      const homepageDataDataRequest =
        compassApiClient.getHomePageData(accountId)(currentData);

      const responseFromIO: HomePageData | undefined = asyncRequestIsComplete(
        homepageDataRequest
      )
        ? homepageDataRequest.result[aggregationPeriodType]
        : undefined;
      const [homePageDataResponse] = await Promise.all([
        homepageDataDataRequest,
      ]);

      if (homePageDataResponse.aoRefreshNeeded) {
        if (
          aggregationPeriodType === AggregationPeriodType.Current &&
          currentApiCount.current < 3
        ) {
          getHomePageDataFromAORefresh(requestData, aggregationPeriodType);
          currentApiCount.current++;
        }
        if (
          aggregationPeriodType === AggregationPeriodType.Previous &&
          previousApiCount.current < 3
        ) {
          getHomePageDataFromAORefresh(requestData, aggregationPeriodType);
          previousApiCount.current++;
        }
      }

      if (!responseFromIO) return;

      setHomepageDataRequest(
        AsyncRequestCompleted({
          current:
            aggregationPeriodType === AggregationPeriodType.Current
              ? homePageDataResponse
              : responseFromIO,
          previous:
            aggregationPeriodType === AggregationPeriodType.Previous
              ? homePageDataResponse
              : responseFromIO,
        })
      );
    } catch (err) {
      setHomepageDataRequest(AsyncRequestFailed(undefined));
    }
  };

  useEffect(() => {
    if (!doesCompassRouteMatch) {
      navigate(BusinessPerformanceRoutes.Business + location.search);
    }

    if (productTourId) {
      famApiClient.addAccountMetaData(
        accountId,
        'opportunity_analyzer_emails',
        'CTA_CLICKED'
      );
    }
  }, [location.pathname]);

  const getAiStatisticsDataV2 = async (
    merchantCountryIds: string[],
    dateRange: DateRange
  ) => {
    try {
      const aiStatisticsRequestV2 =
        await compassApiClient.getAiStatisticsDataV2(
          accountId,
          merchantCountryIds,
          dateRange.startDate.toFormat(COMPASS_API_REQUEST_DATE_FORMAT),
          dateRange.endDate.toFormat(COMPASS_API_REQUEST_DATE_FORMAT)
        );
      return aiStatisticsRequestV2;
    } catch {
      return undefined;
    }
  };

  const [toggleReloadAiStatsV2, setToggleReloadAiStatsV2] =
    useState<boolean>(false);

  const {
    error: isErrorAIStatisticsV2,
    isLoading: isLoadingAIStatisticsV2,
    data: aiStatisticeV2,
  } = useQuery({
    queryKey: [
      CompassQueryKeys.GetBids,
      toggleReloadAiStatsV2,
      isContextDataLoading,
      selectedMerchantsId,
      selectedDateRange,
      accountId,
    ],
    queryFn: () => {
      return getAiStatisticsDataV2(selectedMerchantsId, selectedDateRange);
    },
    enabled:
      !!(!isContextDataLoading && selectedMerchantsId.length) ||
      selectedMerchantsId.length > 0,
  });

  const reloadAiStatsRequestV2 = () => {
    setToggleReloadAiStatsV2(!toggleReloadAiStatsV2);
  };

  return (
    <ViewTrendsContextProvider>
      <Routes>
        <Route
          path={DashboardRoutes.Dashboard}
          element={
            <CompassDashboard
              aoApiClient={aoApiClient}
              skuApiClient={skuApiClient}
              miApiClient={miApiClient}
              getHomepageData={getHomepageData}
              getAiStatisticsDataV2={getAiStatisticsDataV2}
              reloadAiStatisticsDataV2={toggleReloadAiStatsV2}
            />
          }
        >
          <Route
            path={CompassRoutes.Business}
            element={
              <Business
                homepageDataRequest={homepageDataRequest}
                isLoadingMetricsData={isLoadingMetricsData}
                isErrorMetricsData={isErrorMetricsData}
                metricsData={metricsData as TmIndexDataResponse}
                aiStatisticeV2={aiStatisticeV2}
                isLoadingAIStatisticsV2={isLoadingAIStatisticsV2}
                isErrorAIStatisticsV2={isErrorAIStatisticsV2}
                reloadAiStatisticsRequestV2={reloadAiStatsRequestV2}
                productSubscriptionEventData={productSubscriptionEventData}
                compassApiClient={compassApiClient}
                skuApiClient={skuApiClient}
                aoApiClient={aoApiClient}
              />
            }
          />
          <Route path={CompassRoutes.Ads} element={<Ads />} />
          <Route path={CompassRoutes.Products} element={<Products />} />
        </Route>
      </Routes>
    </ViewTrendsContextProvider>
  );
};
Compass.displayName = 'Compass';

export default Compass;
