import {
  Badge,
  Button,
  ButtonSize,
  ButtonVariant,
  CrossIcon,
  CrossMiniIcon,
  Icon,
  IconSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactModal from 'react-modal';
import {
  MatchType,
  TargetInfo,
} from '../../lib/types/CampaignFlexibilitySharedTypes';
import I18nKey from '../../lib/types/I18nKey';
import { MATCH_TYPE_I18N_MAPPER } from '../../modules/advertisingOptimization/containers/adsManager/utils/keywordUtils';
import { getTargetSegmentPills } from '../../modules/campaignFlexibility/components/utils/keywordUtils';

interface AdGroupForKeywordsModalProps {
  readonly dataTestId?: string;
  readonly keywords: TargetInfo[];
  readonly onClose: () => void;
  readonly onDeleteKeyword: (adGroupId: string, targetId?: string) => void;
}

export const AdGroupForKeywordsModal: React.FC<
  AdGroupForKeywordsModalProps
> = ({ onClose, dataTestId = '', keywords, onDeleteKeyword }) => {
  const intl = useIntl();

  const [AD_GROUPS, CLOSE] = [
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_ADGROUPS,
    I18nKey.CLOSE,
  ].map((id) => intl.formatMessage({ id }));

  const getHeader = () => (
    <div className="flex items-center px-12 py-8 border-b border-solid border-grey-200">
      <Button
        size={ButtonSize.Small}
        variant={ButtonVariant.BlackAndWhite}
        svgIcon={CrossIcon}
        onClick={onClose}
        dataTestId={`${dataTestId}_close`}
      />
      <div className="w-full text-center text-base leading-none font-medium text-grey-900">
        <FormattedMessage
          id={I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_ADGROUPS}
        />
      </div>
    </div>
  );

  const getFooter = () => (
    <div className="mt-auto flex items-center justify-end p-12 box-border border-t border-solid border-grey-200">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.Primary}
        label={CLOSE}
        onClick={onClose}
        dataTestId={`${dataTestId}_close`}
      />
    </div>
  );

  const getBody = () => {
    return (
      <>
        <div
          className={classNames(
            'flex flex-row w-full gap-8 mb-12 text-base leading-tight font-normal text-grey-900 mx-12 mt-24'
          )}
        >
          <span>{keywords[0].searchText}</span>|
          <span>
            {intl.formatMessage({
              id: MATCH_TYPE_I18N_MAPPER[keywords[0].matchType as MatchType],
            })}
          </span>
        </div>
        <div
          className={classNames(
            'flex flex-row items-center',
            'bg-grey-50 mx-12 p-12',
            'rounded-t border border-solid border-grey-200'
          )}
        >
          <Typography
            lineHeight={TypographyLineHeight.normal}
            size={TypographySize.base}
            weight={TypographyWeight.regular}
            className="flex items-center text-grey-600 leading-tight pr-8 w-410"
          >
            <span>{AD_GROUPS}</span>
          </Typography>
        </div>
        <div className="mx-12 px-12 flex flex-col gap-12 overflow-y-scroll rounded-b border border-t-0 border-solid border-grey-200">
          {keywords.map((tagData: TargetInfo, index) => {
            return (
              <div
                data-test-id={`${dataTestId}_suggestedAdgroup`}
                className={classNames('py-16 items-start gap-8 flex flex-row', {
                  'border-b border-solid border-grey-200':
                    index !== keywords.length - 1,
                })}
              >
                <div className="flex flex-1 flex-col gap-8 min-w-0">
                  <span className={classNames('flex justify-between')}>
                    <span className="leading-tight font-normal text-base">
                      {tagData.adgroupInfo.name}
                    </span>
                    <Icon
                      className="mx-2 cursor-pointer text-grey-500"
                      size={IconSize.Small}
                      svg={CrossMiniIcon}
                      onClick={() =>
                        onDeleteKeyword(tagData.adgroupId!, tagData.targetId)
                      }
                      dataTestId={`${dataTestId}_TagRow_Delete`}
                    />
                  </span>
                  {tagData.adgroupInfo.targetSegments?.length > 0 && (
                    <>
                      <div className="flex flex-auto justify-between items-center leading-none">
                        <div>
                          {getTargetSegmentPills(
                            tagData.adgroupInfo.targetSegments
                          )?.map((badgeProp) => (
                            <Badge
                              {...badgeProp}
                              className={classNames(badgeProp.className)}
                            />
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <ReactModal
      isOpen
      className={classNames(
        'absolute SlideOutModal top-0 right-0 bottom-0 max-h-full w-1/2 bg-white shadow-2xl',
        'box-border border border-solid border-grey-200 focus:outline-none'
      )}
      overlayClassName="bg-opacity-40 fixed inset-0 z-50 bg-grey-800"
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      preventScroll
    >
      <div className="flex flex-col h-full" data-test-id={`${dataTestId}_open`}>
        {getHeader()}
        {getBody()}
        {getFooter()}
      </div>
    </ReactModal>
  );
};
