import {
  BadgeV2,
  BadgeV2Types,
  ContentStyle,
  ControlledTooltip,
  CycleDotIcon,
  KeywordRoutingType,
  MultiSelectDataFieldFilter,
  PaletteColor,
  PillProps,
  Placement,
  SelectMenuVariant,
  SelectOptionProps,
  SelectState,
  StarIcon,
  TagLinearIcon,
  TargetIcon,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';
import equals from 'lodash/fp/equals';
import sortBy from 'lodash/sortBy';
import upperFirst from 'lodash/upperFirst';
import { useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeMultiplePillsColumn,
  makeSelectColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  AdGroupStatus,
  AdLevel,
  CampaignTargetingType,
  DetailAdGroup,
  TargetType,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
  TABLE_UNIQ_KEY,
  getAutomationDisabledForLegacyCampaignTooltipMessage,
  getAutomationDisabledTooltipMessage,
  getOrDefaultIsBiddableFlag,
  getTooltipContentForArchivedEntity,
  isAdGroupStatusArchived,
  isCampaignCostTypeVcpm,
  isCampaignStatusArchived,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import {
  getCurrentRowIndex,
  getLatestLabelValues,
  getTargetTypes,
  isAutomationDisabledForMerchant,
} from './utils';

const checkIfSelectIsDisabled = (
  adGroupStatus: AdGroupStatus | undefined,
  adGroupDetails: DetailAdGroup,
  isCampaignCostTypeEqualToVcpm: boolean,
  automationDisabledForMerchant: boolean,
  automationDisabledForLegacyRow: boolean
) => {
  return (
    isAdGroupStatusArchived(adGroupStatus) ||
    isCampaignStatusArchived(adGroupDetails.campaignStatus) ||
    isCampaignCostTypeEqualToVcpm ||
    automationDisabledForMerchant ||
    automationDisabledForLegacyRow
  );
};

const getTooltipContent = (
  isDisabled: boolean,
  isCampaignCostTypeEqualToVcpm: boolean,
  automationDisabledForMerchant: boolean,
  automationDisabledForLegacyRow: boolean,
  intl: IntlShape,
  adLevel?: AdLevel
) => {
  if (automationDisabledForMerchant) {
    return getAutomationDisabledTooltipMessage(
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TAGS,
      })
    );
  }

  if (automationDisabledForLegacyRow) {
    return getAutomationDisabledForLegacyCampaignTooltipMessage();
  }

  if (isCampaignCostTypeEqualToVcpm) {
    return (
      <p className="w-200 text-center">
        <FormattedMessage
          id={I18nKey.ADS_MANAGER_FLYWHEEL_SETTINGS_UNSET_TOOLTIP}
        />
      </p>
    );
  }

  if (isDisabled) {
    return getTooltipContentForArchivedEntity(
      adLevel
        ? intl.formatMessage({
            id: AdLevelI8nKeyMapper[adLevel],
          })
        : '',
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TAGS,
      }),
      intl
    );
  }
};

const getDropdownStatus = (
  adGroupStatus: AdGroupStatus | undefined,
  adGroupDetails: DetailAdGroup,
  isCampaignCostTypeEqualToVcpm: boolean,
  automationDisabledForMerchant: boolean,
  automationDisabledForLegacyRow: boolean,
  intl: IntlShape,
  adLevel?: AdLevel
) => {
  const isDisabled = checkIfSelectIsDisabled(
    adGroupStatus,
    adGroupDetails,
    isCampaignCostTypeEqualToVcpm,
    automationDisabledForMerchant,
    automationDisabledForLegacyRow
  );

  if (isDisabled) {
    return {
      status: SelectState.Disabled,
      tooltipContent: getTooltipContent(
        isDisabled,
        isCampaignCostTypeEqualToVcpm,
        automationDisabledForMerchant,
        automationDisabledForLegacyRow,
        intl,
        adLevel
      ),
    };
  }

  return {
    status: SelectState.Default,
  };
};

const getTooltipState = (dropDownStatus: {
  status: SelectState;
  tooltipContent?: JSX.Element;
}) =>
  dropDownStatus.tooltipContent
    ? ControlledTooltip.None
    : ControlledTooltip.Hide;

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = (
  props
) => {
  const {
    isEditMode,
    adGroupDetails,
    adGroupId,
    isManageTreAutomationEnabled,
    channelSettings,
    adLevel,
    showSmartCampaign,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const targetTypes = getTargetTypes(adGroupDetails.targetSegments);

  const currentRowIndex = useSelector<WithTable<AdGroupDetails>, number>(
    getCurrentRowIndex(props)
  );

  const updatedValue = useSelector<WithTable<AdGroupDetails, any>, string>(
    ({ tableState }) =>
      tableSelectors.getCellValueSelector(
        adGroupId,
        ADGROUPS_API_COLUMN_NAME.Tags,
        EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
        TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const [selectedTargetTypeValues, setSelectedTargetTypeValues] = useState<
    string[]
  >(targetTypes || []);

  useEffect(() => {
    if (!isEditMode) {
      setSelectedTargetTypeValues(targetTypes || []);
    }
  }, [isEditMode]);

  if (isEditMode) {
    const newValue = getLatestLabelValues(
      selectedTargetTypeValues,
      updatedValue
    );

    const automationDisabledForMerchant = isAutomationDisabledForMerchant(
      props.merchantsWithAutomationEnabled,
      adGroupDetails
    );

    const automationDisabledForLegacyRow = !getOrDefaultIsBiddableFlag(
      adGroupDetails.isBiddable
    );

    const dropDownStatus = getDropdownStatus(
      channelSettings.status,
      adGroupDetails,
      isCampaignCostTypeVcpm(adGroupDetails.campaignCostType),
      automationDisabledForMerchant,
      automationDisabledForLegacyRow,
      intl,
      adLevel
    );

    const isDisabled = checkIfSelectIsDisabled(
      channelSettings.status,
      adGroupDetails,
      isCampaignCostTypeVcpm(adGroupDetails.campaignCostType),
      automationDisabledForMerchant,
      automationDisabledForLegacyRow
    );

    return makeSelectColumn(
      (data: AdGroupDetails) => {
        return {
          options: getLabelsSelectOptions(
            intl,
            data.adGroupDetails.campaignTargetingType
          ),
          value: newValue,
          placeholder: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_TARGET_LABELS_NO_TAG,
          }),
          state: isDisabled ? SelectState.Disabled : SelectState.Default,
          onChange: (value: string[]) => {
            setSelectedTargetTypeValues(value);
            dispatch(
              tableActions.updateCell({
                tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
                columnName: ADGROUPS_API_COLUMN_NAME.Tags,
                rowId: data.adGroupId,
                value: value.toString(),
                existingValue: targetTypes.toString(),
                numericValue: false,
              })
            );
          },
          variant: SelectMenuVariant.TextWithCheckbox,
          className: 'w-148',
          isDirty: getIsInputDirty(targetTypes, newValue),
          showOnTop: currentRowIndex > 6,
          showAllSelected: false,
        };
      },
      () => {
        return {
          tooltipContent: dropDownStatus.tooltipContent,
          tooltipPlacement: Placement.Bottom,
          style: ContentStyle.Bold,
          controlledTooltip: getTooltipState(dropDownStatus),
        };
      }
    )(props);
  }
  if (showSmartCampaign) {
    const orderedTargetTypes = sortBy(targetTypes as KeywordRoutingType[]);
    return (
      <BadgeV2
        badgeType={BadgeV2Types.KeywordRoutingTags}
        keywordRoutingTypes={orderedTargetTypes}
        classes="flex px-12 py-8"
      />
    );
  }
  return makeMultiplePillsColumn(() => {
    let pills: PillProps[] = [];
    const orderedTargetTypes = sortBy(targetTypes as string[]);

    if (orderedTargetTypes && orderedTargetTypes.length > 0) {
      orderedTargetTypes?.forEach((targetType) =>
        pills.push({
          isHoverable: false,
          color: getColorForTargetTypePill(targetType),
          text: getPillsLabel(intl, targetType),
          icon: getIconForTargetTypePill(targetType),
          className: getClassesForTargetTypePill(pills.length),
        })
      );
    }
    return pills;
  }, isManageTreAutomationEnabled)(props);
};

RowCellElement.displayName = 'LabelsRowCellElement';

export const labelsColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.Tags,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TAGS,
  RowCellElement,
  gridColumnWidth: '220px',
};

export const labelsColumnUpdatedHeader: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.Tags,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_KEYWORDS_COLUMN_TAGS,
  RowCellElement,
  gridColumnWidth: '150px',
};

export const labelsFilter: MultiSelectDataFieldFilter =
  createMultiSelectDataFieldFilter(
    ADGROUPS_API_COLUMN_NAME.Tags,
    I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TAGS,
    [
      SelectFilterOption.TargetTypeAuto,
      SelectFilterOption.TargetTypeBrand,
      SelectFilterOption.TargetTypeCompetitor,
      SelectFilterOption.TargetTypeGeneric,
      SelectFilterOption.NoTag,
    ]
  );

export const getIconForTargetTypePill = (targetType: string) => {
  switch (targetType) {
    case TargetType.Auto:
      return CycleDotIcon;
    case TargetType.Brand:
      return StarIcon;
    case TargetType.Generic:
      return TagLinearIcon;
    case TargetType.Competitor:
      return TargetIcon;
    default:
      return StarIcon;
  }
};

export const getColorForTargetTypePill = (targetType: string) => {
  switch (targetType) {
    case TargetType.Auto:
      return PaletteColor.blue;
    case TargetType.Brand:
      return PaletteColor.magenta;
    case TargetType.Generic:
      return PaletteColor.greyV2;
    case TargetType.Competitor:
      return PaletteColor.yellow;
    default:
      return PaletteColor.magenta;
  }
};

export const getClassesForTargetTypePill = (pillsLength: number) => {
  switch (pillsLength) {
    case 0:
      return '';
    case 1:
    case 3:
      return 'ml-4';
    case 2:
      return 'mt-4';
    default:
      return '';
  }
};

const getLabelsSelectOptions = (
  intl: IntlShape,
  campaignTargetingType?: CampaignTargetingType
): SelectOptionProps<string | number>[] => {
  return [
    ...(campaignTargetingType === CampaignTargetingType.Auto
      ? [
          {
            label: intl.formatMessage({
              id: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_AUTO,
            }),
            value: TargetType.Auto,
          },
        ]
      : []),
    {
      label: intl.formatMessage({
        id: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_BRAND,
      }),
      value: TargetType.Brand,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_COMPETITOR,
      }),
      value: TargetType.Competitor,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_GENERIC,
      }),
      value: TargetType.Generic,
    },
  ];
};

const getPillsLabel = (intl: IntlShape, targetType?: string) =>
  targetType === TargetType.Auto
    ? intl.formatMessage({
        id: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_AUTO,
      })
    : upperFirst(targetType);

const getIsInputDirty = (targetTypes: string[], newValue: string[]) =>
  !equals(sortBy(targetTypes), sortBy(newValue));
