import ReactModal from 'react-modal';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  Alignment,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CrossIcon,
  Placement,
  SliderGroup,
  SliderSize,
  TabsSimple,
  TextLink,
  TextLinkSize,
  Theme,
  Tooltip,
  TooltipProps,
  Type,
} from '@teikametrics/tm-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  MatchType,
  TargetInfo,
} from '../../../../../lib/types/CampaignFlexibilitySharedTypes';
import { SelectedAdgroupsList } from './selectedAdgroupsList';
import { CreateKeywordsContext } from './createKeywordsProvider';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../../../containers/notificationProvider';
import { BrandAndCompetitorLists } from '../../../../../components/KeywordManagement/BrandAndCompetitorLists';
import { KeywordType } from './createKeywordsMenu';
import { GroupByAdGroupRow } from '../../../../../components/KeywordManagement/GroupByAdGroupRow';
import { GroupByKeywordRow } from '../../../../../components/KeywordManagement/GroupByKeywordRow';
import { getDefaultBrandTags } from '../utils/keywordUtils';
import { useParams } from 'react-router-dom';
import { CampaignInfoWithAdGroups } from '../../../../../lib/types/KeywordAction';
import { SelectedAdgroupsModal } from './selectedAdgroupsModal';
import { CampaignWithAdgroupOptionV2 } from './campaignWithAdgroupOptionV2';

interface CreateKeywordsModalProps {
  readonly onClose: (success?: boolean) => void;
  readonly onSelectAdgroupClick: () => void;
  readonly dataTestId?: string;
}

export const CreateKeywordsModal: React.FC<CreateKeywordsModalProps> = ({
  onClose,
  dataTestId,
  onSelectAdgroupClick,
}) => {
  const intl = useIntl();
  const [tabSelect, setTabSelect] = useState(0);
  const [newTagValue, setNewTagValue] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const [activeSliderIndex, setActiveSliderIndex] = useState(0);
  const {
    keywords,
    keywordType,
    setKeywords,
    submitKeywords,
    selectedCampaigns,
    selectedCampaignsMap,
    adType,
    campaigns,
    onCampaignSelectionChange,
  } = useContext(CreateKeywordsContext);
  const toasts = useContext<NotificationContextState>(NotificationContext);

  const [brandTags, setBrandTags] = useState<MatchType[]>(
    getDefaultBrandTags(keywordType, adType)
  );

  const [showSelectedAdgroups, setShowSelectedAdgroups] = useState(false);

  const onToggleShowSelectedAdgroups = () => {
    setShowSelectedAdgroups((current) => !current);
  };

  const selectedAdGroups = useMemo(() => {
    return Object.values(selectedCampaignsMap).flatMap((campaign) =>
      campaign.adGroups
        .filter((adGroup) => campaign.selectedAdGroupIds?.includes(adGroup.id))
        .map((adGroup) => ({
          ...adGroup,
          campaign,
        }))
    );
  }, [selectedCampaignsMap]);

  const { adGroupsId: adGroupsIdUrlParam } = useParams();

  useEffect(() => {
    if (adGroupsIdUrlParam && !!campaigns.length && !selectedCampaigns.length) {
      const relevantCampaign = campaigns.find((campaign) =>
        campaign.adGroups
          .map((adGroup) => adGroup.id)
          .includes(adGroupsIdUrlParam)
      ) as CampaignInfoWithAdGroups;
      if (relevantCampaign) {
        onCampaignSelectionChange(relevantCampaign, adGroupsIdUrlParam, true);
      }
    }
  }, [campaigns]);

  const adGroupKeywordsMap: { [adGroupId: string]: TargetInfo[] } =
    useMemo(() => {
      return keywords.reduce((a: any, b: any) => {
        let id = b.adgroupId!;
        if (!!a[id]) {
          return {
            ...a,
            [id]: [...a[id], b],
          };
        }
        return {
          ...a,
          [id]: [b],
        };
      }, {});
    }, [keywords]);

  const keywordAdGroupMap: { [keyword: string]: TargetInfo[] } = useMemo(() => {
    return keywords.reduce((a: any, b: any) => {
      let id = `${b.searchText}-${b.matchType}`;
      if (!!a[id]) {
        return {
          ...a,
          [id]: [...a[id], b],
        };
      }
      return {
        ...a,
        [id]: [b],
      };
    }, {});
  }, [keywords]);

  const [
    TOAST_ERROR_HEADLINE,
    TOAST_ERROR_MESSAGE,
    TOAST_SUCCESS_HEADLINE,
    TOAST_SUCCESS_MESSAGE,
    TOOLTIP_MATCH_TYPE,
    TOOLTIP_ENTER_KEYWORD,
    MODAL_HEADER,
    VIEW_BY,
    AD_GROUPS,
    KEYWORDS,
    ENTER_LIST,
    ASIN_TARGETS,
    TARGETS,
  ] = [
    I18nKey.ERROR_HEADLINE,
    I18nKey.ERROR_MESSAGE,
    I18nKey.GENERIC_SUCCESS,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_CREATE_KEYWORDS_SUCCESS_TOAST,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_MATCH_TYPE,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_ENTER_KEYWORD,
    I18nKey.CREATE_KEYWORDS_MODAL_HEADER,
    I18nKey.GENERIC_VIEW_BY,
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_ADGROUPS,
    I18nKey.KEYWORD_RECOMMENDATIONS_VIEW_SKUS_KEYWORDS_SLIDEOUT_KEYWORDS,
    I18nKey.CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_SUBLEVEL_USER_KEYWORDS,
    I18nKey.CREATE_KEYWORDS_MODAL_ASIN_TARGETS,
    I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_TARGETS,
  ].map((id) =>
    intl.formatMessage(
      { id },
      {
        keywords: keywords.length,
        adGroups: Object.keys(adGroupKeywordsMap).length,
        keywordType,
        isNegative: keywordType === KeywordType.NegativeKeyword,
      }
    )
  );
  const errorTooltipProps: TooltipProps = {
    content: (
      <ul className="list-disc list-inside">
        {!brandTags.length && <li>{TOOLTIP_MATCH_TYPE}</li>}
        {!newTagValue.length && <li>{TOOLTIP_ENTER_KEYWORD}</li>}
      </ul>
    ),
    theme: Theme.Dark,
    position: {
      placement: Placement.Top,
      alignment: Alignment.Center,
      placementOffsetInPixels: 12,
    },
  };

  const isProductTargeting =
    keywordType === KeywordType.Product ||
    keywordType === KeywordType.Contextual;

  const getHeader = () => (
    <div className="flex items-center px-12 py-8 border-b border-solid border-grey-200">
      <Button
        size={ButtonSize.Small}
        variant={ButtonVariant.BlackAndWhite}
        svgIcon={CrossIcon}
        onClick={() => onClose()}
        dataTestId={`${dataTestId}_close`}
      />
      <div className="w-full text-center text-base leading-none font-medium text-grey-900">
        <FormattedMessage id={MODAL_HEADER} />
      </div>
    </div>
  );

  const getSubmitButtonState = () => {
    if (isSaving) {
      return ButtonState.Loading;
    } else if (!keywords.length) {
      return ButtonState.Disabled;
    }
    return ButtonState.Active;
  };

  const onSubmit = async () => {
    setIsSaving(true);
    submitKeywords()
      .then(() => {
        toasts.addNotification({
          headline: TOAST_SUCCESS_HEADLINE,
          description: TOAST_SUCCESS_MESSAGE,
          type: Type.Success,
          dataTestId: 'CreateKeywordsSuccess',
        });
        onClose(true);
      })
      .catch(() => {
        toasts.addNotification({
          headline: TOAST_ERROR_HEADLINE,
          description: TOAST_ERROR_MESSAGE,
          type: Type.Attention,
          dataTestId: 'CreateKeywordsError',
        });
      })
      .finally(() => setIsSaving(false));
  };

  const submitButton = (
    <Button
      size={ButtonSize.Medium}
      variant={ButtonVariant.Primary}
      label={intl.formatMessage({
        id: I18nKey.GENERIC_SUBMIT,
      })}
      state={getSubmitButtonState()}
      onClick={onSubmit}
      dataTestId={`${dataTestId}_submit`}
    />
  );

  const getFooter = () => (
    <div className="gap-12 mt-auto flex items-center justify-end p-12 box-border border-t border-solid border-grey-200">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.BlackAndWhiteBorder}
        label={intl.formatMessage({
          id: I18nKey.CANCEL,
        })}
        onClick={() => onClose()}
        dataTestId={`${dataTestId}_cancel`}
      />
      {getSubmitButtonState() === ButtonState.Disabled ? (
        <Tooltip {...errorTooltipProps}>{submitButton}</Tooltip>
      ) : (
        submitButton
      )}
    </div>
  );

  const deleteTags = (adGroupId: string, targetId?: string) => {
    setKeywords(
      keywords.filter((keyword) => {
        return !targetId
          ? keyword.adgroupId !== adGroupId
          : keyword.targetId !== targetId;
      })
    );
  };

  const deleteTagsByKeyword = (searchText: string, matchType: string) => {
    setKeywords(
      keywords.filter((keyword) => {
        return (
          keyword.searchText !== searchText || keyword.matchType !== matchType
        );
      })
    );
  };

  const highlightSelectAdGroupsButton = useMemo(() => {
    if (newTagValue === '') {
      return false;
    }
    return !!newTagValue && !selectedCampaigns.length;
  }, [keywords, newTagValue, selectedCampaigns]);

  const onAddKeywords = (data: TargetInfo[]) => {
    setKeywords(data);
  };

  const sliders = [
    {
      text: isProductTargeting ? TARGETS : KEYWORDS,
    },
    {
      text: AD_GROUPS,
    },
  ];

  const selectedAdgroupsCount = Object.values(selectedCampaignsMap).reduce(
    (prev, curr) => {
      return prev + (curr?.selectedAdGroupIds || []).length;
    },
    0
  );

  const selectedCampaignWithFilteredAdGroup =
    selectedCampaigns.length > 0
      ? {
          ...selectedCampaigns[0],
          adGroups: selectedCampaigns[0]?.adGroups?.filter(
            (adGroup) =>
              adGroup.id === adGroupsIdUrlParam ||
              selectedCampaigns[0]?.selectedAdGroupIds?.includes(adGroup.id)
          ),
        }
      : undefined;

  const minimizeBrandsAndCompetitorsHeight =
    !!adGroupsIdUrlParam || selectedAdgroupsCount > 0;

  return (
    <ReactModal
      isOpen
      className={classNames(
        'absolute SlideOutModal top-0 right-0 bottom-0 max-h-full w-1/2 bg-white shadow-2xl',
        'box-border border border-solid border-grey-200 focus:outline-none'
      )}
      overlayClassName="fixed inset-0 z-50 bg-opacity-60 bg-grey-900"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      onRequestClose={() => onClose()}
      preventScroll
    >
      <div
        className="flex flex-1 flex-col h-screen"
        data-test-id={`${dataTestId}_open`}
      >
        {getHeader()}
        <div
          className="p-16 flex flex-1 flex-col gap-12"
          data-test-id={`${dataTestId}_open`}
        >
          <TabsSimple
            onTabSelect={setTabSelect}
            defaultTabIndex={tabSelect}
            tabs={[
              {
                label: isProductTargeting ? ASIN_TARGETS : ENTER_LIST,
              },
            ]}
            dataTestId={`${dataTestId}_dataInspector`}
          />
          <div className="flex flex-1 flex-col">
            <SelectedAdgroupsList
              hasErrors={highlightSelectAdGroupsButton}
              onSelectAdgroupClick={onSelectAdgroupClick}
            />
            {selectedCampaigns.length > 0 &&
              selectedCampaignWithFilteredAdGroup && (
                <div className="mt-16">
                  <CampaignWithAdgroupOptionV2
                    key={selectedCampaignWithFilteredAdGroup.id}
                    campaign={selectedCampaignWithFilteredAdGroup}
                    onChange={onCampaignSelectionChange}
                    minimizePadding={!!adGroupsIdUrlParam}
                  />
                  {selectedAdgroupsCount > 1 && (
                    <div
                      data-test-id="create-keyword-modal-show-selected-adgroups"
                      className="flex flex-row items-center gap-4 cursor-pointer w-fit"
                      onClick={onToggleShowSelectedAdgroups}
                    >
                      <TextLink
                        size={TextLinkSize.Small}
                        textLabel={intl.formatMessage(
                          {
                            id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECTED_COUNT_KNOWN_AD_GROUP,
                          },
                          {
                            count: selectedAdgroupsCount - 1,
                          }
                        )}
                      />
                    </div>
                  )}
                  {showSelectedAdgroups && (
                    <SelectedAdgroupsModal
                      onCampaignSelectionChange={onCampaignSelectionChange}
                      selectedCampaigns={selectedCampaigns}
                      selectedAdgroupsCount={selectedAdgroupsCount}
                      onClose={() => {
                        setShowSelectedAdgroups(false);
                      }}
                    />
                  )}
                </div>
              )}

            <div className="flex flex-col justify-stretch flex-1">
              <BrandAndCompetitorLists
                keywordType={keywordType}
                targets={[]}
                selectedAdGroups={selectedAdGroups}
                userCreated={keywords}
                setUserCreated={onAddKeywords}
                onRemoveAll={() => setKeywords([])}
                setNewTagValue={setNewTagValue}
                newTagValue={newTagValue}
                brandTags={brandTags}
                useAdGroups={true}
                adType={adType}
                setBrandTags={setBrandTags}
                minimizeHeight={minimizeBrandsAndCompetitorsHeight}
              >
                <div
                  className={classNames([
                    'flex flex-col',
                    'gap-4 px-12 pt-4',
                    'relative w-full h-full border-solid border-grey-200 overflow-auto ',
                    'scrollbar-thin scrollbar-thumb-grey-300 scrollbar-track-grey-100 scrollbar-thumb-rounded-full',
                  ])}
                >
                  {keywords.length > 0 && (
                    <div className="mb-20 mt-16 flex flex-row items-center gap-12">
                      <span className="text-sm leading-none font-normal text-grey-900">
                        {VIEW_BY}
                      </span>

                      <div className="w-212">
                        <SliderGroup
                          fillContainerWidth={true}
                          sliderSize={SliderSize.Small}
                          sliders={sliders}
                          activeSliderIndex={activeSliderIndex}
                          onClick={(index) => setActiveSliderIndex(index)}
                        />
                      </div>
                    </div>
                  )}

                  {activeSliderIndex === 1 &&
                    Object.entries(adGroupKeywordsMap).map(
                      ([id, value], index) => (
                        <GroupByAdGroupRow
                          key={`tag-row-${id}`}
                          keywords={value}
                          keywordType={keywordType}
                          onDelete={deleteTags}
                          dataTestId={dataTestId}
                        />
                      )
                    )}
                  {activeSliderIndex === 0 &&
                    Object.entries(keywordAdGroupMap).map(
                      ([id, value], index) => (
                        <GroupByKeywordRow
                          key={`tag-row-${id}`}
                          keywords={value}
                          onDeleteKeyword={deleteTags}
                          onDelete={deleteTagsByKeyword}
                          dataTestId={dataTestId}
                        />
                      )
                    )}
                </div>
              </BrandAndCompetitorLists>
            </div>
          </div>
        </div>
        {getFooter()}
      </div>
    </ReactModal>
  );
};
