import {
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Modal,
  SearchInput,
  SearchInputSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  FetchingData,
  FetchingDataType,
} from '../../../../keywordAction/components/adGroupSelectionSlideoutV2/fetchignData';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  CreateKeywordsContext,
  MAX_SLIDEOUT_RECORDS,
} from './createKeywordsProvider';
import { CampaignInfoWithAdGroups } from '../../../../../lib/types/KeywordAction';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../lib/utilities/intercom';
import { AD_GROUP_NAME } from '../types';
import { useParams } from 'react-router-dom';
import { CampaignWithAdgroupOptionV2 } from './campaignWithAdgroupOptionV2';

export interface SelectAdgroupsListProps {
  readonly onClose: () => void;
  readonly onBack: () => void;
  readonly showModal?: boolean;
}

const getApplyButtonState = (
  isLoading: boolean | undefined,
  userEdits: Record<string, CampaignInfoWithAdGroups>
) => {
  if (
    !isLoading &&
    !!Object.keys(userEdits).filter(
      (key) => !!(userEdits[key] || {})?.adGroups?.length
    ).length
  ) {
    return ButtonState.Enabled;
  }

  return ButtonState.Disabled;
};

export const SelectAdgroupsList: React.FC<SelectAdgroupsListProps> = ({
  onClose,
  onBack,
  showModal = true,
}) => {
  const {
    selectedCampaigns,
    campaigns,
    searching,
    isLoading,
    onSearchButtonClick,
    onSearchInputClear,
    onSearchValueChange,
    searchValue,
    selectedCampaignsMap,
    onApplyCampaignSelection,
    totalCampaignsCount,
  } = useContext(CreateKeywordsContext);

  const intl = useIntl();

  const appendedParams = new URLSearchParams(window.location.search);

  const urlAdGroupName = appendedParams.get(AD_GROUP_NAME);

  const { adGroupsId } = useParams();

  const [userEdits, setUserEdits] = useState<
    Record<string, CampaignInfoWithAdGroups>
  >(selectedCampaignsMap || {});

  useEffect(() => {
    setUserEdits(selectedCampaignsMap || {});
  }, [selectedCampaignsMap]);

  useEffect(() => {
    onSearchInputClear();
    if (urlAdGroupName && adGroupsId) {
      //Pagination limit at 100: in case ad group is not present in the first 100, trigger search in order to find and select url ad group
      onSearchButtonClick(urlAdGroupName, true);
    }
  }, []);

  const [
    SEARCH_INPUT_PLACEHOLDER,
    APPLY_LABEL,
    CANCEL_LABEL,
    USE_SEARCH_BAR,
    BACK,
    HEADER,
  ] = [
    I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_PLACEHOLDER,
    I18nKey.GENERIC_APPLY,
    I18nKey.GENERIC_CANCEL_LABEL,
    I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_USE_SEARCH_BAR,
    I18nKey.BACK,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECT_AD_GROUPS,
  ].map((id) =>
    intl.formatMessage({
      id,
    })
  );

  const SHOWING_MAX_RESULTS = intl.formatMessage(
    {
      id: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_SHOWING_RESULTS_MAX,
    },
    {
      currentResults: MAX_SLIDEOUT_RECORDS,
      totalResults: totalCampaignsCount,
    }
  );

  const searchButtonInputProps = {
    label: intl.formatMessage({ id: I18nKey.SEARCH }),
    loading: !!searching,
    onSearchButtonClick,
    onChange: onSearchValueChange,
    size: ButtonSize.Default,
  };

  const showSearchBarText = totalCampaignsCount > MAX_SLIDEOUT_RECORDS;

  const searchingOrLoading = searching || isLoading;

  const onApplySuggestion = () => {
    onApplyCampaignSelection(userEdits);
    onBack();
  };

  const onCampaignSelectionChange = (
    campaign: CampaignInfoWithAdGroups,
    adGroupId: string,
    selected?: boolean
  ) => {
    const updatedCampaign = userEdits[campaign.id] ?? campaign;

    if (selected) {
      setUserEdits((current) => ({
        ...current,
        [campaign.id]: {
          ...updatedCampaign,
          selectedAdGroupIds: [
            ...(userEdits[campaign.id]?.selectedAdGroupIds || []),
            adGroupId,
          ],
        },
      }));
    } else {
      setUserEdits((current) => ({
        ...current,
        [campaign.id]: {
          ...updatedCampaign,
          selectedAdGroupIds: (
            userEdits[campaign.id]?.selectedAdGroupIds || []
          ).filter((id) => id !== adGroupId),
        },
      }));
    }
  };

  useEffect(() => {
    hideIntercomLauncher();
    return () => showIntercomLauncher();
  }, []);

  return (
    <Modal
      className="z-50 w-1/2 max-w-full"
      showModal={showModal}
      slideOutModal={true}
      showOVerlay={false}
      formActionRowClassName="sticky border-t-1 border-grey-200 border-solid py-12 absolute bottom-0 px-12 bg-white"
      hidePadding={true}
      dataTestId="select_adgroups_list"
    >
      <div className="flex flex-col">
        <div
          className={classNames(
            'flex flex-row',
            'border-solid border-b border-grey-200',
            'p-16'
          )}
        >
          <div className="hover:cursor-pointer" onClick={onBack}>
            <Button
              label={BACK}
              svgIcon={ArrowLeftIcon}
              size={ButtonSize.Medium}
              variant={ButtonVariant.BlackAndWhite}
              className="text-grey-900"
            />
          </div>
          <Typography
            size={TypographySize.lg}
            weight={TypographyWeight.medium}
            className="w-full text-center"
          >
            {HEADER}
          </Typography>
        </div>
        <div className="p-16 bg-grey-50">
          <SearchInput
            inputClassName={'w-full'}
            value={searchValue}
            size={SearchInputSize.Default}
            onSearchInputClear={onSearchInputClear}
            placeholder={SEARCH_INPUT_PLACEHOLDER}
            dataTestId={'kwa_slideout_search_input'}
            onDebounceChange={undefined}
            searchButtonProps={searchButtonInputProps}
          />
        </div>
        <div className="flex flex-col h-screen overflow-auto pb-192 bg-grey-50 px-16 gap-16">
          {searchingOrLoading ? (
            <FetchingData
              type={searching ? FetchingDataType.search : FetchingDataType.load}
            />
          ) : (
            <>
              <div className="flex flex-col gap-16 pb-20">
                {selectedCampaigns?.map((eachDestination, index) => (
                  <CampaignWithAdgroupOptionV2
                    campaign={eachDestination}
                    selectable
                    key={eachDestination.id}
                    onChange={onCampaignSelectionChange}
                    selectionMap={userEdits}
                  />
                ))}
                {campaigns
                  ?.filter(
                    (campaign) =>
                      !(
                        selectedCampaignsMap?.[campaign.id]
                          ?.selectedAdGroupIds || []
                      ).length
                  )
                  .map((eachDestination, index) => (
                    <CampaignWithAdgroupOptionV2
                      campaign={eachDestination}
                      selectable
                      key={eachDestination.id}
                      onChange={onCampaignSelectionChange}
                      selectionMap={userEdits}
                    />
                  ))}
              </div>
              {showSearchBarText ? (
                <div className="flex flex-col items-center justify-center gap-8 pb-74">
                  <Typography
                    size={TypographySize.sm}
                    weight={TypographyWeight.regular}
                    lineHeight={TypographyLineHeight.none}
                    className="text-grey-900"
                  >
                    {SHOWING_MAX_RESULTS}
                  </Typography>
                  <Typography
                    size={TypographySize.sm}
                    weight={TypographyWeight.regular}
                    lineHeight={TypographyLineHeight.none}
                    className="text-grey-900"
                  >
                    {USE_SEARCH_BAR}
                  </Typography>
                </div>
              ) : null}
            </>
          )}
        </div>
        <div
          className={classNames(
            'absolute bottom-0',
            'bg-white',
            'w-full p-12',
            'border-t-1 border-grey-200 border-solid'
          )}
        >
          <div className="flex gap-8 justify-end">
            <Button
              state={
                searchingOrLoading ? ButtonState.Disabled : ButtonState.Enabled
              }
              label={CANCEL_LABEL}
              onClick={onClose}
              dataTestId={'kwa_slideout_cancel_button'}
              variant={ButtonVariant.BlackAndWhiteBorder}
              size={ButtonSize.Large}
            />
            <Button
              dataTestId={'kwa_slideout_apply_button'}
              state={getApplyButtonState(searchingOrLoading, userEdits)}
              label={APPLY_LABEL}
              onClick={onApplySuggestion}
              variant={ButtonVariant.Primary}
              size={ButtonSize.Large}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
