import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { TableChange } from '../../../../../containers/table/ducks/types';
import {
  AdGroupDetails,
  AdLevel,
  AdType,
  AiBiddingValues,
  AssignCampaignGroupElement,
  AutomationStatus,
  CampaignDetails,
  CampaignGroupAssignRequest,
  CampaignsChannelSettingsEditInfo,
  CampaignsChannelSettingsEditInfoKeys,
  CampaignsChannelSettingsUpdateInfo,
  EditAdgroupsChannelSettingRow,
  EditProductAdRequest,
  EditProductAdRow,
  EditProfileRequest,
  EditProfileRow,
  EditTargetsRequest,
  EditTargetsRow,
  EntityType,
  FlywheelSalesChannel,
  FlywheelSettingsUpdateInfo,
  Groups,
  MerchantCountryCode,
  Portfolio,
  TargetType,
  UpdateAdgroupChannelSettingsRequest,
  UpdateCampaignChannelSettingsRequest,
  UpdateFlywheelSettingsRequest,
} from '../../../../../lib/types/AOSharedTypes';
import { AMAZON_SALES_CHANNEL_ID } from '../../../../../lib/types/SalesChannels';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';
import {
  CURRENCY_CODE,
  NO_GROUP_VALUE,
  TableCellChangeAdGroup,
  TableCellChangeCampaign,
  TableCellChangeProductAd,
  TableCellChangeProfile,
  TableCellChangeTarget,
} from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  encodeUrl,
  isCampaignAdFormatEqualToVideo,
} from '.';
import { DateTime } from 'luxon';
import {
  BidContraints,
  getBidConstraint,
} from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import { MerchantType } from '../../../../../lib/types/Fam';
import {
  SMART_GOALS,
  transformAdvertisingGoal,
} from '../adgroupTableColumns/utils';

export const NO_END_DATE_AMAZON = null;
export const NO_END_DATE_WALMART = '9999-12-30';

export const getEditsForCampaignUpdates = (
  campaignUpdates: CampaignsChannelSettingsEditInfo
) => {
  const keys = Object.keys(
    campaignUpdates
  ) as CampaignsChannelSettingsEditInfoKeys[];
  for (let item of keys) {
    if (isUndefined(campaignUpdates[item])) {
      delete campaignUpdates[item];
    }
  }
  return campaignUpdates;
};

export const getComputedBudget = (
  merchantCountryCode: MerchantCountryCode,
  campaignBudget?: string
) => {
  if (campaignBudget) {
    return {
      amount: campaignBudget,
      currency:
        getCurrencyCodeFromMerchantCountryCode(merchantCountryCode) ||
        CURRENCY_CODE,
    };
  }
};

export const formatNumericValue = (campaignValue?: string) => {
  if (!isNil(campaignValue)) {
    return Number(campaignValue) / 100;
  }
};

export const getEndDateForRequest = (
  currentFlywheelSalesChannel: FlywheelSalesChannel,
  endDate?: string
) => {
  if (!isNil(endDate)) {
    return (
      endDate ||
      (currentFlywheelSalesChannel === FlywheelSalesChannel.Amazon
        ? NO_END_DATE_AMAZON
        : NO_END_DATE_WALMART)
    );
  } else {
    return undefined;
  }
};

export const getUpdateGroupsPayload = (
  tableChange: TableChange,
  tableData: CampaignDetails[],
  groupsData: Groups[],
  selectedMerchantCountryId: string
): CampaignGroupAssignRequest => {
  const updatedCells: TableCellChangeCampaign =
    tableChange.cell as TableCellChangeCampaign;
  const mapCampaignGroups: AssignCampaignGroupElement[] = [];
  const removeCampaignGroups: AssignCampaignGroupElement[] = [];

  Object.entries(updatedCells).forEach(([campaignId, { groupLabel }]) => {
    const allGroupValues = groupLabel?.split(',') || [];

    const rowInfo: CampaignDetails | undefined = tableData.find(
      (currentRow: CampaignDetails) => currentRow.campaignId === campaignId
    );
    const currentRowGroups = rowInfo?.campaignDetails.groupLabel || [];

    allGroupValues
      .filter((item) => item !== NO_GROUP_VALUE)
      .forEach((group) => {
        mapCampaignGroups.push({
          campaignId,
          campaignGroupId: group,
        });
      });

    const removeGroups = groupsData
      .filter((item) => {
        return (
          item.groupId &&
          !allGroupValues.includes(item.groupId) &&
          currentRowGroups.includes(item.name)
        );
      })
      .map((item) => item.groupId);

    removeGroups.forEach((group) => {
      removeCampaignGroups.push({
        campaignId,
        campaignGroupId: group!,
      });
    });
  });

  return {
    merchantCountryId: selectedMerchantCountryId,
    mapCampaignGroups,
    removeCampaignGroups,
  };
};

export const getUpdateCampaignChannelSettingsRequest = (
  tableChange: TableChange,
  merchantCountryCode: MerchantCountryCode,
  channelId: string,
  portfolios: Portfolio[],
  currentFlywheelSalesChannel: FlywheelSalesChannel
): UpdateCampaignChannelSettingsRequest => {
  const updatedCells: TableCellChangeCampaign =
    tableChange.cell as TableCellChangeCampaign;
  const updates = Object.entries(updatedCells).map(
    ([
      campaignId,
      {
        campaignName,
        buyBoxBidMultiplier,
        carouselStatus,
        itemBuyBox,
        itemCarousel,
        campaignDailyBudget,
        desktopBidMultiplier,
        endDate,
        bidOptimization,
        belowTopOfSearchBidMultiplier,
        homePageBidMultiplier,
        appBidMultiplier,
        mobileBidMultiplier,
        searchIngridBidMultiplier,
        status,
        stockUpBidMultiplier,
        totalBudget,
        budgetRollover,
        portfolio,
        biddingStrategy,
        productPageBidMultiplier,
        topOfSearchBidMultiplier,
        advertisingGoal,
      },
    ]): CampaignsChannelSettingsUpdateInfo => {
      let campaignUpdates: CampaignsChannelSettingsUpdateInfo = {
        campaignId,
        edits: {},
      };
      campaignUpdates = produce(campaignUpdates, (campaignWithUpdatedValue) => {
        campaignWithUpdatedValue.edits = getEditsForCampaignUpdates({
          campaignName,
          carouselStatus,
          itemBuyBox,
          itemCarousel,
          status,
          biddingStrategy,
          bidOptimization: isNil(bidOptimization)
            ? undefined
            : bidOptimization === 'true',
          belowTopOfSearchBidMultiplier: formatNumericValue(
            belowTopOfSearchBidMultiplier
          ),
          buyBoxBidMultiplier: formatNumericValue(buyBoxBidMultiplier),
          endDate: getEndDateForRequest(currentFlywheelSalesChannel, endDate),
          desktopBidMultiplier: formatNumericValue(desktopBidMultiplier),
          homePageBidMultiplier: formatNumericValue(homePageBidMultiplier),
          appBidMultiplier: formatNumericValue(appBidMultiplier),
          mobileBidMultiplier: formatNumericValue(mobileBidMultiplier),
          searchIngridBidMultiplier: formatNumericValue(
            searchIngridBidMultiplier
          ),
          stockUpBidMultiplier: formatNumericValue(stockUpBidMultiplier),
          budgetRollover: isNil(budgetRollover)
            ? undefined
            : budgetRollover === 'true',
          topOfSearchBidMultiplier: formatNumericValue(
            topOfSearchBidMultiplier
          ),
          productPageBidMultiplier: formatNumericValue(
            productPageBidMultiplier
          ),
          dailyBudget: getComputedBudget(
            merchantCountryCode,
            campaignDailyBudget
          ),
          totalBudget: getComputedBudget(merchantCountryCode, totalBudget),
          advertisingGoal,
        });
      });

      //Portfolio is only available in Amazon
      if (isEqual(AMAZON_SALES_CHANNEL_ID, channelId) && portfolio) {
        campaignUpdates = produce(
          campaignUpdates,
          (campaignWithUpdatedValue) => {
            campaignWithUpdatedValue.edits.portfolio = portfolios.find(
              ({ name }) => name === portfolio
            )?.portFolioId;
            if (campaignWithUpdatedValue.edits.portfolio) {
              campaignWithUpdatedValue.edits.noPortfolio = false;
            } else {
              campaignWithUpdatedValue.edits.noPortfolio = true;
            }
          }
        );
      }

      return campaignUpdates;
    }
  );
  return { updates, channelId };
};

export const getEditProductAdRequest = (
  updatedCells: TableCellChangeProductAd,
  currencyCode: { [key: string]: string },
  merchantCountryId: string
): EditProductAdRequest => {
  const updates = Object.entries(updatedCells).map(
    ([adItemId, { bid, status }]) => {
      let updatedAdItem: EditProductAdRow = {
        adItemId,
        edits: {},
        merchantCountryId,
      };

      if (status) {
        updatedAdItem = produce(updatedAdItem, (adItemWithUpdatedStatus) => {
          adItemWithUpdatedStatus.edits.status = status;
          return adItemWithUpdatedStatus;
        });
      }

      if (bid) {
        updatedAdItem = produce(updatedAdItem, (adItemWithUpdatedBid) => {
          adItemWithUpdatedBid.edits.bid = {
            amount: bid,
            currency: currencyCode[adItemId] || CURRENCY_CODE,
          };
          return adItemWithUpdatedBid;
        });
      }

      return updatedAdItem;
    }
  );

  return { updates };
};

export const getEditProfileRequest = (
  updatedCells: TableCellChangeProfile
): EditProfileRequest => {
  const updates = Object.entries(updatedCells).map(
    ([profileId, { profileStatus, brand, headline, clickUrl }]) => {
      let updatedProfile: EditProfileRow = { profileId, edits: {} };

      if (profileStatus) {
        updatedProfile = produce(updatedProfile, (itemWithUpdatedStatus) => {
          itemWithUpdatedStatus.edits.profileStatus = profileStatus;
          return itemWithUpdatedStatus;
        });
      }

      if (brand) {
        updatedProfile = produce(updatedProfile, (itemWithUpdatedBrand) => {
          itemWithUpdatedBrand.edits.brand = brand;
          return itemWithUpdatedBrand;
        });
      }

      if (headline) {
        updatedProfile = produce(updatedProfile, (itemWithUpdatedHeadline) => {
          itemWithUpdatedHeadline.edits.headline = headline;
          return itemWithUpdatedHeadline;
        });
      }

      if (clickUrl) {
        updatedProfile = produce(updatedProfile, (itemWithUpdatedUrl) => {
          const [url, params] = clickUrl.split('?');
          itemWithUpdatedUrl.edits.clickUrl =
            url + (params ? '?' + encodeUrl(params) : '');
          return itemWithUpdatedUrl;
        });
      }

      return updatedProfile;
    }
  );

  return { updates };
};

export const getEditTargetRequest = (
  updatedCells: TableCellChangeTarget,
  currencyCode: { [key: string]: string },
  merchantCountryId?: string
): EditTargetsRequest => {
  const updates = Object.entries(updatedCells).map(
    ([targetId, { bid, targetStatus, targetLabel }]) => {
      let updatedTarget: EditTargetsRow = {
        targetId,
        edits: {},
        merchantCountryId,
      };

      if (targetLabel) {
        updatedTarget = produce(updatedTarget, (targetWithUpdatedStatus) => {
          targetWithUpdatedStatus.edits.targetSegment = targetLabel;
          return targetWithUpdatedStatus;
        });
      }

      if (targetStatus) {
        updatedTarget = produce(updatedTarget, (targetWithUpdatedStatus) => {
          targetWithUpdatedStatus.edits.status = targetStatus;
          return targetWithUpdatedStatus;
        });
      }
      if (bid) {
        updatedTarget = produce(updatedTarget, (targetWithUpdatedBid) => {
          targetWithUpdatedBid.edits.bid = {
            amount: bid,
            currency: currencyCode[targetId] || CURRENCY_CODE,
          };
          return targetWithUpdatedBid;
        });
      }
      return updatedTarget;
    }
  );

  return { updates };
};

export const getUpdateAdgroupsChannelSettingsRequest = (
  tableChange: TableChange,
  merchantCountryCode: MerchantCountryCode,
  merchantCountryId?: string,
  salesChannelId?: string
): UpdateAdgroupChannelSettingsRequest => {
  const updatedCells: TableCellChangeAdGroup =
    tableChange.cell as TableCellChangeAdGroup;
  const updates: EditAdgroupsChannelSettingRow[] = [];
  const currencyCode =
    getCurrencyCodeFromMerchantCountryCode(merchantCountryCode) ||
    CURRENCY_CODE;

  Object.entries(updatedCells).forEach(
    ([
      adGroupId,
      {
        adGroupName,
        status,
        adGroupDefaultBid,
        adGroupReviewStatus,
        adGroupLabel,
      },
    ]) => {
      let updatedAdGroup: EditAdgroupsChannelSettingRow = {
        adGroupId,
        edits: {},
        merchantCountryId,
        updatedBy: 'FW_USER_UPDATED',
        salesChannelId,
      };

      if (
        isNil(status) &&
        isNil(adGroupName) &&
        isNil(adGroupDefaultBid) &&
        isNil(adGroupReviewStatus) &&
        isNil(adGroupLabel)
      ) {
        return;
      } else {
        if (adGroupReviewStatus) {
          updatedAdGroup = produce(
            updatedAdGroup,
            (adGroupWithUpdatedStatus) => {
              adGroupWithUpdatedStatus.edits.adGroupReviewStatus =
                adGroupReviewStatus;
            }
          );
        }

        if (status) {
          updatedAdGroup = produce(
            updatedAdGroup,
            (adGroupWithUpdatedStatus) => {
              adGroupWithUpdatedStatus.edits.status = status;
            }
          );
        }

        if (adGroupName) {
          updatedAdGroup = produce(updatedAdGroup, (adGroupWithUpdatedName) => {
            adGroupWithUpdatedName.edits.name = adGroupName;
          });
        }

        if (adGroupDefaultBid) {
          updatedAdGroup = produce(updatedAdGroup, (adGroupWithUpdatedName) => {
            adGroupWithUpdatedName.edits.defaultBid = {
              amount: adGroupDefaultBid,
              currency: currencyCode,
            };
          });
        }

        if (!isUndefined(adGroupLabel)) {
          updatedAdGroup = produce(
            updatedAdGroup,
            (adGroupWithUpdatedLabels) => {
              adGroupWithUpdatedLabels.edits.targetSegments =
                getTargetSegmentValue(adGroupLabel);
            }
          );
        }

        return updates.push(updatedAdGroup);
      }
    }
  );

  return { updates, extrenalServiceRequest: true };
};

const getTargetSegmentValue = (adGroupLabel: string) =>
  adGroupLabel === '' ? [TargetType.NoTag] : adGroupLabel.split(',');

export const getUpdateFlywheelSettingsRequestForCampaigns = (
  tableChange: TableChange,
  tableData: CampaignDetails[],
  adLevel: AdLevel,
  merchantCountryCode: MerchantCountryCode,
  channelId: string,
  merchantCountryId?: string
): UpdateFlywheelSettingsRequest => {
  const updatedCells: TableCellChangeAdGroup =
    tableChange.cell as TableCellChangeAdGroup;
  const creates: Partial<FlywheelSettingsUpdateInfo>[] = [];
  const updates: Partial<FlywheelSettingsUpdateInfo>[] = [];
  const currencyCode =
    getCurrencyCodeFromMerchantCountryCode(merchantCountryCode) ||
    CURRENCY_CODE;
  const entityTypeForRequest = getEnityTypeForEditRequestByAdLevel(adLevel);
  Object.entries(updatedCells).forEach(
    ([campaignId, { automationStatus, macsTarget, minBid, maxBid }]) => {
      const rowInfo: CampaignDetails | undefined = tableData.find(
        (currentRow: CampaignDetails) => currentRow.campaignId === campaignId
      );

      const nothingToUpdate =
        isNil(automationStatus) &&
        isNil(macsTarget) &&
        isNil(minBid) &&
        isNil(maxBid);

      const shouldCreateOrUpdateRequired = !nothingToUpdate;

      if (shouldCreateOrUpdateRequired) {
        const previousFlywhellSettings = rowInfo?.flywheelSettings;
        if (
          isNil(previousFlywhellSettings?.automationStatus) &&
          isNil(previousFlywhellSettings?.minBid) &&
          isNil(previousFlywhellSettings?.maxBid) &&
          isNil(previousFlywhellSettings?.macsTarget)
        ) {
          const createFlywheelEntry =
            getFlywheelSettingsUpdatesSectionEntryForCampaign(
              rowInfo,
              campaignId,
              automationStatus,
              macsTarget,
              minBid,
              maxBid,
              currencyCode,
              entityTypeForRequest
            );
          if (createFlywheelEntry && !isEmpty(createFlywheelEntry)) {
            creates.push(createFlywheelEntry);
          }
        } else {
          const updateFlyWheelEntry =
            getFlywheelSettingsUpdatesSectionEntryForCampaign(
              rowInfo,
              campaignId,
              automationStatus,
              macsTarget,
              minBid,
              maxBid,
              currencyCode,
              entityTypeForRequest
            );
          if (updateFlyWheelEntry) {
            updates.push(updateFlyWheelEntry);
          }
        }
      }
    }
  );
  return { creates, updates, channelId, merchantCountryId };
};

export const getUpdateFlywheelSettingsRequest = (
  tableChange: TableChange,
  tableData: AdGroupDetails[],
  adLevel: AdLevel,
  merchantCountryCode: MerchantCountryCode,
  channelId: string,
  constraints: BidContraints[],
  selectedAdType: AdType,
  salesChannel: FlywheelSalesChannel,
  showSmartCampaigns: boolean,
  merchantType?: MerchantType,
  merchantCountryId?: string
): UpdateFlywheelSettingsRequest => {
  const updatedCells: TableCellChangeAdGroup =
    tableChange.cell as TableCellChangeAdGroup;
  const creates: Partial<FlywheelSettingsUpdateInfo>[] = [];
  const updates: Partial<FlywheelSettingsUpdateInfo>[] = [];
  const currencyCode =
    getCurrencyCodeFromMerchantCountryCode(merchantCountryCode) ||
    CURRENCY_CODE;
  const entityTypeForRequest = getEnityTypeForEditRequestByAdLevel(adLevel);

  Object.entries(updatedCells).forEach(
    ([
      adGroupId,
      {
        automationStatus,
        macsTarget,
        minBid,
        maxBid,
        aiRecommendationKeywordSetting,
        aiBidding,
        bidModifier,
        isOverrideBidModifier,
        overrideEndTime,
        minBidOverrideEnabled,
        maxBidOverrideEnabled,
      },
    ]) => {
      const rowInfo: AdGroupDetails | undefined = tableData.find(
        (currentRow: AdGroupDetails) => currentRow.adGroupId === adGroupId
      );

      const nothingToUpdate =
        isNil(automationStatus) &&
        isNil(macsTarget) &&
        isNil(minBid) &&
        isNil(maxBid) &&
        isNil(aiRecommendationKeywordSetting) &&
        isNil(bidModifier) &&
        isNil(aiBidding) &&
        isNil(isOverrideBidModifier) &&
        isNil(overrideEndTime) &&
        isNil(minBidOverrideEnabled) &&
        isNil(maxBidOverrideEnabled);

      const isCreateOrUpdateOperationRequired = !nothingToUpdate;

      if (isCreateOrUpdateOperationRequired) {
        const previousFlywhellSettings = rowInfo?.flywheelSettings;

        if (
          isNil(previousFlywhellSettings?.automationStatus) &&
          isNil(previousFlywhellSettings?.minBid) &&
          isNil(previousFlywhellSettings?.maxBid) &&
          isNil(previousFlywhellSettings?.macsTarget)
        ) {
          const createFlyWheelEntry = getFlywheelSettingsUpdatesSectionEntry(
            rowInfo,
            merchantType,
            showSmartCampaigns,
            constraints,
            merchantCountryCode,
            selectedAdType,
            salesChannel,
            adGroupId,
            automationStatus,
            macsTarget,
            minBid,
            maxBid,
            aiBidding,
            bidModifier,
            isOverrideBidModifier,
            overrideEndTime,
            currencyCode,
            entityTypeForRequest,
            aiRecommendationKeywordSetting,
            minBidOverrideEnabled,
            maxBidOverrideEnabled
          );
          if (createFlyWheelEntry && !isEmpty(createFlyWheelEntry)) {
            creates.push(createFlyWheelEntry);
          }
        } else {
          const updateFlyWheelEntry = getFlywheelSettingsUpdatesSectionEntry(
            rowInfo,
            merchantType,
            showSmartCampaigns,
            constraints,
            merchantCountryCode,
            selectedAdType,
            salesChannel,
            adGroupId,
            automationStatus,
            macsTarget,
            minBid,
            maxBid,
            aiBidding,
            bidModifier,
            isOverrideBidModifier,
            overrideEndTime,
            currencyCode,
            entityTypeForRequest,
            aiRecommendationKeywordSetting,
            minBidOverrideEnabled,
            maxBidOverrideEnabled
          );
          if (updateFlyWheelEntry) {
            updates.push(updateFlyWheelEntry);
          }
        }
      }
    }
  );
  return { creates, updates, channelId, merchantCountryId };
};

export const getFlywheelSettingsUpdatesSectionEntry = (
  ...restParameters: [
    AdGroupDetails | undefined,
    MerchantType | undefined,
    boolean,
    BidContraints[],
    MerchantCountryCode,
    AdType,
    FlywheelSalesChannel,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    EntityType,
    string,
    string,
    string
  ]
): Partial<FlywheelSettingsUpdateInfo> | undefined => {
  const [
    rowInfo,
    merchantType,
    showSmartCampaigns,
    constraints,
    merchantCountryCode,
    selectedAdType,
    salesChannel,
    adGroupId,
    automationStatus,
    paramMacsTarget,
    minBid,
    maxBid,
    aiBidding,
    paramBidModifier,
    paramIsOverrideBidModifier,
    overrideEndTime,
    currencyCode,
    entityType,
    aiRecommendationKeywordSetting,
    minBidOverrideEnabled,
    maxBidOverrideEnabled,
  ] = restParameters;
  let updatedAdGroup: Partial<FlywheelSettingsUpdateInfo> = {
    entityId: adGroupId,
  };

  const defaultAiBidding =
    rowInfo?.flywheelSettings.aiBidding ?? AiBiddingValues.NotEnabled;

  const advertisingGoal = transformAdvertisingGoal(
    defaultAiBidding,
    rowInfo?.flywheelSettings.advertisingGoal,
    rowInfo?.flywheelSettings.isOverrideBidModifier
  );
  const isSmartGoal = SMART_GOALS.includes(advertisingGoal);

  const macsTarget: number = paramMacsTarget
    ? Number(paramMacsTarget) / 100
    : Number(rowInfo?.flywheelSettings.macsTarget);

  /**
   * Bid Modifier is considered as go ahead in case of a special case as per this function.
   * isNoAiToSmartAiTransition
   */
  const bidModifier: number = paramBidModifier
    ? Number(paramBidModifier) / 100
    : Number(rowInfo?.flywheelSettings.bidModifier);

  const isOverrideBidModifier: boolean | undefined =
    paramIsOverrideBidModifier === 'true'
      ? true
      : paramIsOverrideBidModifier === 'false'
      ? false
      : undefined;

  const updatedMinBidOverrideEnabled = minBidOverrideEnabled
    ? minBidOverrideEnabled
    : rowInfo?.flywheelSettings.minBidOverrideEnabled?.toString();

  const updatedMaxBidOverrideEnabled = maxBidOverrideEnabled
    ? maxBidOverrideEnabled
    : rowInfo?.flywheelSettings?.maxBidOverrideEnabled?.toString();

  let minBidAmount = minBid ? minBid : rowInfo?.flywheelSettings.minBid?.amount;
  let maxBidAmount = maxBid ? maxBid : rowInfo?.flywheelSettings.maxBid?.amount;

  /**
   * If Smart Campaigns are enabled.
   * And if min/max Bid is null still which means, there was no change or not found in the data API,
   * Then do the following.
   * 1. For minBid = Get it from the config as in the minBidNewColumn.
   * 2. For maxBid = Get it from the config as in the maxBidNewColumn.
   */
  if (isUndefined(minBidAmount) && showSmartCampaigns) {
    minBidAmount = getBidConstraint(
      constraints,
      isCampaignAdFormatEqualToVideo(rowInfo?.adGroupDetails.campaignAdFormat)
        ? AdType.SponsoredBrandsVideo
        : selectedAdType,
      salesChannel,
      ADGROUPS_API_COLUMN_NAME.MinBid,
      merchantCountryCode,
      rowInfo?.adGroupDetails.campaignTargetingType,
      merchantType,
      rowInfo?.adGroupDetails.campaignCostType
    ).defaultMinBid.toString();
  }

  if (isUndefined(maxBidAmount) && showSmartCampaigns) {
    maxBidAmount = getBidConstraint(
      constraints,
      isCampaignAdFormatEqualToVideo(rowInfo?.adGroupDetails.campaignAdFormat)
        ? AdType.SponsoredBrandsVideo
        : selectedAdType,
      salesChannel,
      ADGROUPS_API_COLUMN_NAME.MaxBid,
      merchantCountryCode,
      rowInfo?.adGroupDetails.campaignTargetingType,
      merchantType
    ).defaultMaxBid.toString();
  }

  if (automationStatus) {
    updatedAdGroup = produce(
      updatedAdGroup,
      (adGroupWithUpdatedBidAutomation) => {
        adGroupWithUpdatedBidAutomation.bidAutomationEnabled =
          automationStatus === AutomationStatus.Active;
      }
    );
  }

  if (aiBidding) {
    updatedAdGroup = produce(
      updatedAdGroup,
      (adGroupWithUpdatedBidAutomation) => {
        adGroupWithUpdatedBidAutomation.bidAutomationEnabled =
          aiBidding === AiBiddingValues.Ai ||
          aiBidding === AiBiddingValues.Smart;
      }
    );
  }

  if (isOverrideBidModifier === false) {
    // Send a now to end the override
    updatedAdGroup = produce(
      updatedAdGroup,
      (adGroupWithUpdatedBidAutomation) => {
        adGroupWithUpdatedBidAutomation.overrideEndTime =
          DateTime.now().setZone('utc').toISO() || '';
      }
    );
  } else if (
    isOverrideBidModifier === true ||
    rowInfo?.flywheelSettings.isOverrideBidModifier
  ) {
    updatedAdGroup = produce(
      updatedAdGroup,
      (adGroupWithUpdatedBidAutomation) => {
        adGroupWithUpdatedBidAutomation.overrideBidModifier = bidModifier;
      }
    );
  }

  if (overrideEndTime) {
    updatedAdGroup = produce(
      updatedAdGroup,
      (adGroupWithUpdatedBidAutomation) => {
        adGroupWithUpdatedBidAutomation.overrideEndTime = overrideEndTime;
      }
    );
  }

  if (
    bidModifier &&
    !isOverrideBidModifier &&
    !rowInfo?.flywheelSettings.isOverrideBidModifier
  ) {
    updatedAdGroup = produce(
      updatedAdGroup,
      (adGroupWithUpdatedBidModifier) => {
        adGroupWithUpdatedBidModifier.bidModifier = bidModifier;
      }
    );
  }

  if (
    isSmartGoal &&
    defaultAiBidding === AiBiddingValues.NotEnabled &&
    aiBidding === AiBiddingValues.Smart
  ) {
    updatedAdGroup = produce(updatedAdGroup, (adGroupWithForceFlywheelAi) => {
      adGroupWithForceFlywheelAi.forceMakeAdGroupFlywheelAi = true;
    });
  }
  if (macsTarget) {
    updatedAdGroup = produce(updatedAdGroup, (adGroupWithUpdatedMacsTagret) => {
      adGroupWithUpdatedMacsTagret.macsTarget = macsTarget;
    });
  }
  if (minBidAmount) {
    updatedAdGroup = produce(updatedAdGroup, (adGroupWithUpdatedMinBid) => {
      if (minBidAmount) {
        adGroupWithUpdatedMinBid.minBid = {
          amount: minBidAmount,
          currency: currencyCode,
        };
      }
    });
  }

  if (isSmartGoal) {
    if (updatedMinBidOverrideEnabled) {
      updatedAdGroup = produce(
        updatedAdGroup,
        (adGroupWithUpdatedMinBidOverrideEnabled) => {
          adGroupWithUpdatedMinBidOverrideEnabled.minBidOverrideEnabled =
            updatedMinBidOverrideEnabled === 'true';
        }
      );
    }
    if (updatedMaxBidOverrideEnabled) {
      updatedAdGroup = produce(
        updatedAdGroup,
        (adGroupWithUpdatedMaxBidOverrideEnabled) => {
          adGroupWithUpdatedMaxBidOverrideEnabled.maxBidOverrideEnabled =
            updatedMaxBidOverrideEnabled === 'true';
        }
      );
    }
  }

  if (maxBidAmount) {
    updatedAdGroup = produce(updatedAdGroup, (adGroupWithUpdatedMaxBid) => {
      if (maxBidAmount) {
        adGroupWithUpdatedMaxBid.maxBid = {
          amount: maxBidAmount,
          currency: currencyCode,
        };
      }
    });
  }

  if (aiRecommendationKeywordSetting) {
    updatedAdGroup = produce(
      updatedAdGroup,
      (adGroupWithUpdatedKeywordSetting) => {
        adGroupWithUpdatedKeywordSetting.aiRecommendationKeywordSetting =
          aiRecommendationKeywordSetting;
      }
    );
  }

  if (!isEmpty(updatedAdGroup)) {
    return {
      entityId: adGroupId,
      ...updatedAdGroup,
      entityType,
    };
  }
};

export const getFlywheelSettingsUpdatesSectionEntryForCampaign = (
  ...restParameters: [
    CampaignDetails | undefined,
    string,
    string,
    string,
    string,
    string,
    string,
    EntityType
  ]
): Partial<FlywheelSettingsUpdateInfo> | undefined => {
  const [
    rowInfo,
    campaignId,
    automationStatus,
    paramMacsTarget,
    minBid,
    maxBid,
    currencyCode,
    entityType,
  ] = restParameters;

  let updatedCampaign: Partial<FlywheelSettingsUpdateInfo> = {
    entityId: campaignId,
  };

  const macsTarget: number = paramMacsTarget
    ? Number(paramMacsTarget) / 100
    : Number(rowInfo?.flywheelSettings.macsTarget);

  const minBidAmount = minBid
    ? minBid
    : rowInfo?.flywheelSettings.minBid?.amount;
  const maxBidAmount = maxBid
    ? maxBid
    : rowInfo?.flywheelSettings.maxBid?.amount;

  if (automationStatus) {
    updatedCampaign = produce(
      updatedCampaign,
      (campaignWithUpdatedBidAutomation) => {
        campaignWithUpdatedBidAutomation.bidAutomationEnabled =
          automationStatus === AutomationStatus.Active;
      }
    );
  }

  if (macsTarget) {
    updatedCampaign = produce(
      updatedCampaign,
      (campaignWithUpdatedMacsTagret) => {
        campaignWithUpdatedMacsTagret.macsTarget = macsTarget;
      }
    );
  }
  if (minBidAmount) {
    updatedCampaign = produce(updatedCampaign, (campaignWithUpdatedMinBid) => {
      campaignWithUpdatedMinBid.minBid = {
        amount: minBidAmount,
        currency: currencyCode,
      };
    });
  }

  if (maxBidAmount) {
    updatedCampaign = produce(updatedCampaign, (campaignWithUpdatedMaxBid) => {
      campaignWithUpdatedMaxBid.maxBid = {
        amount: maxBidAmount,
        currency: currencyCode,
      };
    });
  }
  if (!isEmpty(updatedCampaign)) {
    return {
      entityId: campaignId,
      ...updatedCampaign,
      entityType,
    };
  }
};

export const getEnityTypeForEditRequestByAdLevel = (
  adLevel: AdLevel
): EntityType => {
  switch (adLevel) {
    case AdLevel.Campaigns:
      return EntityType.Campaign;
    case AdLevel.AdGroups:
    default:
      return EntityType.AdGroup;
  }
};

export const isCampaignDailyBudgetColumnMandatoryForAdGroups = (
  salesChannel: FlywheelSalesChannel,
  apiRequestColumns: string[],
  adType?: AdType
) =>
  salesChannel === FlywheelSalesChannel.Amazon &&
  (adType === AdType.SponsoredDisplay || adType === AdType.SponsoredProducts) &&
  apiRequestColumns.includes(ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid);
