import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeNumericInputColumn,
  makePercentColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  CampaignDetails,
  CampaignStatus,
  PlacementBidMultiplier,
} from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getTooltipContentForArchivedEntity,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
} from '../utils';

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({
  isEditMode,
  selectedAdType,
  salesChannel,
  merchantCountry,
  ...campaignDetails
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);
  const [isInputEdited, setInputEdited] = useState<boolean>(false);

  const campaignId = campaignDetails.campaignId;
  const currentTableId = ADS_MANAGER_CAMPAIGNS_TABLE_ID;
  const bidConstraintsData = useBidConstraintsContext();

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(tableState, currentTableId)
  );

  const updatedProductPageBidMultiplier = useSelector<
    WithTable<CampaignDetails>,
    string
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      campaignId,
      CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier
    )(tableState, currentTableId)
  );

  const existingValue = getExistingValue(
    intl,
    campaignDetails.channelSettings.placementBidMultiplier
      ?.productPageBidMultiplier
  );

  let productPageBidMultiplier =
    updatedProductPageBidMultiplier ?? existingValue;

  if (isNil(productPageBidMultiplier) && isInputEdited) {
    productPageBidMultiplier = '';
  }

  const [value, setValue] = useState<string>(productPageBidMultiplier);

  useEffect(() => {
    if (
      isEditMode ||
      (!isNil(updatedProductPageBidMultiplier) &&
        updatedProductPageBidMultiplier !== value)
    ) {
      setValue(productPageBidMultiplier);
    }

    return () => {
      if (!isEditMode) {
        setInputEdited(false);
      }
    };
  }, [isEditMode, currentPage, updatedProductPageBidMultiplier]);

  const updateCellValue = (newValue: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: currentTableId,
        columnName: CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier,
        rowId: campaignId,
        value: newValue,
        existingValue: existingValue || '',
        numericValue: true,
      })
    );
  };

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignId]
  );

  if (isEditMode) {
    const {
      minProductPageBidMultiplier: minProductMulitplierValue,
      maxProductPageBidMultiplier: maxProductMulitplierValue,
    } = getBidConstraint(
      bidConstraintsData.constraints,
      selectedAdType,
      salesChannel,
      CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier,
      merchantCountry
    );

    const isInvalidProductPageBidMuliplier = isProductPageBidMultiplierInvalid(
      value,
      minProductMulitplierValue,
      maxProductMulitplierValue
    );

    const productPageBidMultiplierInputState = getNumericInputState(
      isInputEdited,
      value !== existingValue,
      isInvalidProductPageBidMuliplier,
      campaignDetails.channelSettings.status
    );

    const onProductPageBidMultiplierChange = (inputValue: string) => {
      const formattedInputValue = inputValue.replaceAll(/[.]/g, '');
      setInputEdited(true);
      setValue(formattedInputValue);
      debouncedUpdateCellValue(formattedInputValue);
    };

    const isCampaignArchived = isCampaignStatusArchived(
      campaignDetails.channelSettings.status
    );

    const onInputFocus = () => setFocussed(true);
    const onInputBlur = () => {
      setFocussed(false);
    };

    const productPageBidMultiplierInputProps: NumericInputProps &
      TypedTooltipProps = {
      value,
      state: productPageBidMultiplierInputState,
      appendedElement: '%',
      onInputBlur: onInputBlur,
      onChange: onProductPageBidMultiplierChange,
      tooltipContent: tooltipContent(
        isCampaignArchived,
        intl,
        isInvalidProductPageBidMuliplier,
        minProductMulitplierValue,
        maxProductMulitplierValue
      ),
      controlledTooltip: getTooltipState(
        isCampaignArchived,
        focussed,
        productPageBidMultiplierInputState
      ),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus: onInputFocus,
      isDirty: value !== existingValue,
      acceptOnlyPositiveNumbers: true,
      minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      acceptOnlyIntegers: true,
      dataTestId: 'product_page_bid_multiplier',
    };

    return makeNumericInputColumn(
      (_campaignDetails: CampaignDetails) => productPageBidMultiplierInputProps
    )(campaignDetails);
  }

  return makePercentColumn<CampaignDetails>(
    ({ channelSettings: { placementBidMultiplier } }) =>
      getColumnValue(placementBidMultiplier),
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const productPageBidMultiplierColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PRODUCT_PAGE_BID_MULTIPILER,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const productPageBidMultiplierFilter = createPercentDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PRODUCT_PAGE_BID_MULTIPILER,
  isValidNumber(),
  true
);

export const isProductPageBidMultiplierInvalid = (
  value: string,
  minProductMulitplierValue?: number,
  maxProductMulitplierValue?: number
): boolean => {
  const isProductPageBidMuliplierLessThanRequiredValue: boolean =
    isCurrentValueLessThanRequiredMinValue(minProductMulitplierValue, value);

  const isProductPageBidMuliplierGreaterThanRequiredValue: boolean =
    isCurrentValueGreaterThanRequiredMaxValue(maxProductMulitplierValue, value);

  return (
    isProductPageBidMuliplierLessThanRequiredValue ||
    isProductPageBidMuliplierGreaterThanRequiredValue ||
    isNaN(Number(value)) ||
    isEmpty(value)
  );
};

const getExistingValue = (intl: IntlShape, productPageBidMultiplier?: number) =>
  (productPageBidMultiplier?.toString() &&
    intl.formatNumber(productPageBidMultiplier * 100)) ||
  '';

const getNumericInputState = (
  isInputEdited: boolean,
  isDirty: boolean,
  isInvalidProductPageBidMuliplier: boolean,
  campaignStatus?: CampaignStatus
) => {
  if (isCampaignStatusArchived(campaignStatus)) {
    return NumericInputState.Disabled;
  } else {
    return (isInputEdited || isDirty) && isInvalidProductPageBidMuliplier
      ? NumericInputState.Error
      : NumericInputState.Default;
  }
};

const tooltipContent = (
  isCampaignArchived: boolean,
  intl: IntlShape,
  isInvalidProductPageBidMuliplier: boolean,
  minProductMulitplierValue?: number,
  maxProductMulitplierValue?: number
) => {
  if (isCampaignArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PRODUCT_PAGE_BID_MULTIPILER,
      }),
      intl
    );
  } else if (isInvalidProductPageBidMuliplier) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIER_AMAZON_ERROR_MESSAGE,
          },
          {
            minValue:
              !isNil(minProductMulitplierValue) &&
              intl.formatNumber(minProductMulitplierValue),
            maxValue:
              !isNil(maxProductMulitplierValue) &&
              intl.formatNumber(maxProductMulitplierValue),
          }
        )}
      </p>
    );
  }
};

const getTooltipState = (
  isCampaignArchived: boolean,
  focussed: boolean,
  productPageBidMultiplierInputState: NumericInputState
) => {
  if (isCampaignArchived) {
    return ControlledTooltip.None;
  } else {
    return focussed &&
      productPageBidMultiplierInputState === NumericInputState.Error
      ? ControlledTooltip.Show
      : ControlledTooltip.Hide;
  }
};

const getColumnValue = (placementBidMultiplier?: PlacementBidMultiplier) =>
  placementBidMultiplier &&
  !isNil(placementBidMultiplier.productPageBidMultiplier)
    ? placementBidMultiplier.productPageBidMultiplier.toString()
    : undefined;
