import {
  KeywordActionColumns,
  KeywordRecommendationsData,
} from '../../../../../lib/types/KeywordAction';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import {
  ColumnAlign,
  TableCellV2,
  TableCellV2Type,
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../../../lib/types/I18nKey';

export const Cell: React.FC<KeywordRecommendationsData> = ({
  performance: { impressions },
}) => {
  return (
    <TableCellV2
      tableCellData={{
        tableCellType: TableCellV2Type.Number,
        currentValue: impressions,
      }}
      className="h-full flex justify-center"
    />
  );
};
Cell.displayName = 'KWACellImpressions';

export const impressions: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  {},
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_IMPRESSIONS_HEADER,
  accessorKey: KeywordActionColumns.IMPRESSIONS,
  sortable: true,
  size: 135,
  minSize: 135,
  align: ColumnAlign.Right,
  cell: ({ row: { original } }) => <Cell {...original} />,
};

export const impressionsFilter = createNumericDataFieldFilter(
  KeywordActionColumns.IMPRESSIONS,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_IMPRESSIONS_HEADER,
  isValidNumber(0)
);
