import { useIntl } from 'react-intl';

import {
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { PromptAuthFailed } from '../promptAuthFailed';
import { MerchantsGroup } from './index';
import { NeedHelp } from '../../../../../components/NeedHelp';
import { AuthorizeMerchantTable } from '../AuthorizeMerchantTable';
import { INTERCOM_AMAZON_ONBOARDING_HELP } from '../../constants';
import React from 'react';
import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';

export interface AuthorizeProductsDataProps {
  readonly merchants: MerchantsGroup[];
  readonly handleOauthPopup: (data: any) => void;
  readonly error: boolean;
  readonly channel?: FlywheelSalesChannel;
}

export const AuthorizeProductsData: React.FC<AuthorizeProductsDataProps> = (
  props
) => {
  const { merchants, error } = props;
  const intl = useIntl();

  const [ADD_PRODUCT_DATA_LABEL, AUTHORIZE_FLYWHEEL_TEXT] = [
    I18nKey.CONNECT_SELLING_PARTNER,
    I18nKey.CONNECT_SELLING_PARTNER_MESSAGE,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <div className="text-left text-base text-grey-900 flex flex-col pt-24 pb-16 overflow-y-auto min-h-420 gap-16">
      <Typography
        size={TypographySize.xl}
        lineHeight={TypographyLineHeight.tight}
        weight={TypographyWeight.semibold}
      >
        {ADD_PRODUCT_DATA_LABEL}
      </Typography>

      <Typography
        size={TypographySize.base}
        lineHeight={TypographyLineHeight.normal}
        weight={TypographyWeight.regular}
      >
        {AUTHORIZE_FLYWHEEL_TEXT}
      </Typography>
      <div className="flex flex-col grow basis-0 shrink-0 gap-12">
        {!error && merchants.length > 0 && (
          <AuthorizeMerchantTable
            handleOauthPopup={props.handleOauthPopup}
            merchants={merchants}
          />
        )}
        {error && (
          <div className="flex mb-16">
            <PromptAuthFailed
              mainTextId={
                I18nKey.CONNECT_ADS_MODAL_AMAZON_ERROR_PROMPT_MAIN_MESSAGE
              }
              subTextId={
                I18nKey.CONNECT_ADS_MODAL_AMAZON_ERROR_PROMPT_SUB_MESSAGE
              }
            />
          </div>
        )}
      </div>
      <NeedHelp
        url={INTERCOM_AMAZON_ONBOARDING_HELP}
        dataTestId="authorize_products_data"
        channel={props.channel}
      />
    </div>
  );
};

AuthorizeProductsData.displayName = 'AuthorizeProductsData';
