import {
  KeywordActionColumns,
  KeywordRecommendationsData,
} from '../../../../../lib/types/KeywordAction';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import {
  ColumnAlign,
  TableCellV2,
  TableCellV2Type,
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../../../lib/types/I18nKey';
import isNil from 'lodash/isNil';
import { useIntl } from 'react-intl';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';

export const Cell: React.FC<KeywordRecommendationsData> = ({
  performance: { adSalesTotal },
}) => {
  const intl = useIntl();
  const currentValue = isNil(adSalesTotal)
    ? null
    : intl.formatNumber(parseFloat(adSalesTotal.amount), {
        style: 'currency',
        currency: adSalesTotal.currency,
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      });

  return (
    <TableCellV2
      tableCellData={{
        tableCellType: TableCellV2Type.Number,
        currentValue,
      }}
      className="h-full flex justify-center"
    />
  );
};
Cell.displayName = 'KWACellAdSales';

export const adSales: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  {},
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_AD_SALES_HEADER,
  accessorKey: KeywordActionColumns.AD_SALES,
  sortable: true,
  size: 135,
  minSize: 135,
  align: ColumnAlign.Right,
  cell: ({ row: { original } }) => <Cell {...original} />,
};

export const adSalesFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    KeywordActionColumns.AD_SALES,
    I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_AD_SALES_HEADER,
    currency,
    isValidNumber(0)
  );
