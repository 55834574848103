import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  SvgIcon,
} from '@teikametrics/tm-design-system';
import React from 'react';
import classNames from 'classnames';

export interface EmptyStateProps {
  readonly title: string;
  readonly description?: string;
  readonly icon: SvgIcon;
  readonly showButton?: boolean;
  readonly buttonLabel?: string;
  readonly onClick?: () => void;
  readonly containerClassName?: string;
  readonly titleClassName?: string;
  readonly descriptionClassName?: string;
  readonly iconClassName?: string;
  readonly iconContainerClassName?: string;
  readonly dataTestId?: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  description,
  icon,
  showButton,
  buttonLabel,
  onClick,
  titleClassName = 'text-lg leading-tight font-normal',
  containerClassName = 'gap-8',
  descriptionClassName = 'text-base leading-tight font-medium ',
  iconClassName = 'text-grey-500',
  iconContainerClassName = 'bg-grey-100',
  dataTestId,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center flex-1',
        containerClassName
      )}
    >
      <div
        className={classNames(
          'w-56 h-56 flex flex-col items-center justify-center rounded-32',
          iconContainerClassName
        )}
      >
        <Icon size={IconSize.Large} className={iconClassName} svg={icon} />
      </div>
      <span className={classNames('text-center text-grey-900', titleClassName)}>
        {title}
      </span>
      {!!description && (
        <span
          className={classNames(
            'text-center text-grey-900',
            descriptionClassName
          )}
        >
          {description}
        </span>
      )}

      {showButton && (
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Primary}
          label={buttonLabel}
          onClick={onClick}
          dataTestId={dataTestId}
        />
      )}
    </div>
  );
};
