import { SelectV2OptionProps, titleCase } from '@teikametrics/tm-design-system';
import { AxiosResponse } from 'axios';
import { IntlShape } from 'react-intl';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import {
  PaginatedRequest,
  PaginatedResult,
} from '../../../../lib/clients/types';
import { AddedBy } from '../../../../lib/types/AOSharedTypes';
import {
  Filter,
  FilterOps,
  InFilter,
  NotInFilter,
} from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  ActionType,
  KeywordActionColumns,
  KeywordActionSubmissionResponse,
  KeywordRecommendationsData,
  RecommendationSourceV2,
  RecommendationsAction,
  SelectedRecommendationsV2,
} from '../../../../lib/types/KeywordAction';
import { RecommendationsCampaignType } from '../KeywordActionV4';
import {
  DESTINATION_AD_GROUP_SORT_KEY,
  SOURCE_KEYWORD_TEXT_SORT_FIELD,
} from './types';

export const MAX_ROWS = 50;
export const OVERSCAN_ROWS = 50;

export const dataFetcherRecommendationsTableV2 =
  (
    accountId: string,
    mcid: string,
    currency: string,
    aoApiClient: AOApiClient,
    campaignType: RecommendationsCampaignType,
    keywordSuggestionsByChannel?: boolean
  ) =>
  async (
    paginatedRequest: PaginatedRequest
  ): Promise<PaginatedResult<KeywordRecommendationsData>> => {
    if (!mcid) {
      return Promise.resolve({
        items: [],
        totalItems: 0,
      });
    }

    try {
      const campaignFilter: Filter = {
        field: 'createdBy',
        op: FilterOps.in,
        value:
          campaignType === RecommendationsCampaignType.Smart
            ? [AddedBy.CampaignCreatorV1, AddedBy.FlywheelAutomated]
            : [AddedBy.External],
      };
      const { items, totalItems } = await aoApiClient.getRecommendationsV2(
        accountId,
        mcid,
        currency,
        undefined,
        [
          {
            field: KeywordActionColumns.AD_CONVERSIONS,
            alias: KeywordActionColumns.REASON,
          },
          {
            field: DESTINATION_AD_GROUP_SORT_KEY,
            alias: KeywordActionColumns.CAMPAIGN_ADGROUP_NAME,
          },
          {
            field: SOURCE_KEYWORD_TEXT_SORT_FIELD,
            alias: KeywordActionColumns.SOURCE,
          },
        ],
        transformFilterKWAv2,
        keywordSuggestionsByChannel
      )(
        campaignType === RecommendationsCampaignType.All
          ? paginatedRequest
          : {
              ...paginatedRequest,
              filters: [...paginatedRequest.filters, campaignFilter],
            }
      );

      return {
        items,
        totalItems,
      };
    } catch {
      return Promise.reject({
        items: [],
        totalItems: 0,
      });
    }
  };

export const dataFetcherNegativeRecommendationsTable =
  (
    accountId: string,
    mcid: string,
    currency: string,
    aoApiClient: AOApiClient,
    campaignType: RecommendationsCampaignType = RecommendationsCampaignType.All,
    includeSourceFilter?: boolean,
    fetchKeywordsByChannel?: boolean
  ) =>
  async (
    paginatedRequest: PaginatedRequest
  ): Promise<PaginatedResult<KeywordRecommendationsData>> => {
    if (!mcid) {
      return Promise.resolve({
        items: [],
        totalItems: 0,
      });
    }

    try {
      const campaignFilter: Filter = {
        field: 'createdBy',
        op: FilterOps.in,
        value:
          campaignType === RecommendationsCampaignType.Smart
            ? [AddedBy.CampaignCreatorV1, AddedBy.FlywheelAutomated]
            : [AddedBy.External],
      };
      const sourceFilter: Filter = {
        field: 'sources',
        op: fetchKeywordsByChannel ? FilterOps.notIn : FilterOps.in,
        value: [
          'AMZ_SP_SEARCH_TERM_REPORT',
          'AMZ_SP_TARGET_SEARCH_TERM_REPORT',
          'WMT_SP_ITEM_KEYWORD_REPORT',
          'WMT_SP_KEYWORD_PERFORMANCE_REPORT',
        ],
      };
      const { items, totalItems } =
        await aoApiClient.getNegativeRecommendations(
          accountId,
          mcid,
          currency,
          undefined,
          [
            {
              field: KeywordActionColumns.AD_SPEND,
              alias: KeywordActionColumns.REASON,
            },
            {
              field: DESTINATION_AD_GROUP_SORT_KEY,
              alias: KeywordActionColumns.CAMPAIGN_ADGROUP_NAME,
            },
            {
              field: SOURCE_KEYWORD_TEXT_SORT_FIELD,
              alias: KeywordActionColumns.SOURCE,
            },
          ],
          transformFilterKWAv2
        )(
          campaignType === RecommendationsCampaignType.All
            ? {
                ...paginatedRequest,
                filters: [
                  ...paginatedRequest.filters,
                  ...(includeSourceFilter ? [sourceFilter] : []),
                ],
              }
            : {
                ...paginatedRequest,
                filters: [
                  ...paginatedRequest.filters,
                  ...(includeSourceFilter ? [sourceFilter] : []),
                  campaignFilter,
                ],
              }
        );

      return {
        items,
        totalItems,
      };
    } catch {
      return Promise.reject({
        items: [],
        totalItems: 0,
      });
    }
  };

export const getRecommendationSourceDisplay = (
  intl: IntlShape,
  source?: string,
  isNewColumn?: boolean
) => {
  const [
    AO,
    AMZ_SP_SEARCH_TERM_REPORT,
    AMZ_SP_TARGET_SEARCH_TERM_REPORT,
    AMZ_SP_SUGGESTED_KEYWORD,
    WMT_SP_ITEM_KEYWORD_REPORT,
    WMT_SP_KEYWORD_ANALYTIC,
    WMT_SP_KEYWORD_PERFORMANCE_REPORT,
    OTHER,
  ] = [
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_AO,
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_AMZ_SP_SEARCH_TERM_REPORT,
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_AMZ_SP_SEARCH_TERM_REPORT,
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_AMZ_SP_SUGGESTED_KEYWORD,
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_WMT_SP_ITEM_KEYWORD_REPORT,
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_WMT_SP_KEYWORD_ANALYTIC,
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_WMT_SP_KEYWORD_PERFORMANCE_REPORT,
    I18nKey.KEYWORD_RECOMMENDATIONS_SOURCE_OTHER,
  ].map((id) => intl.formatMessage({ id }));

  let reportName: string;

  switch (source) {
    case RecommendationSourceV2.AO:
      reportName = AO;
      break;
    case RecommendationSourceV2.AMZ_SP_SEARCH_TERM_REPORT:
      reportName = AMZ_SP_SEARCH_TERM_REPORT;
      break;
    case RecommendationSourceV2.AMZ_SP_TARGET_SEARCH_TERM_REPORT:
      reportName = AMZ_SP_TARGET_SEARCH_TERM_REPORT;
      break;
    case RecommendationSourceV2.AMZ_SP_SUGGESTED_KEYWORD:
      reportName = AMZ_SP_SUGGESTED_KEYWORD;
      break;
    case RecommendationSourceV2.WMT_SP_ITEM_KEYWORD_REPORT:
      reportName = WMT_SP_ITEM_KEYWORD_REPORT;
      break;
    case RecommendationSourceV2.WMT_SP_KEYWORD_ANALYTIC:
      reportName = WMT_SP_KEYWORD_ANALYTIC;
      break;
    case RecommendationSourceV2.WMT_SP_KEYWORD_PERFORMANCE_REPORT:
      reportName = WMT_SP_KEYWORD_PERFORMANCE_REPORT;
      break;
    default:
      reportName = OTHER;
      break;
  }

  return titleCase(isNewColumn ? reportName + ':' : reportName);
};

export const submitRecommendations = async (
  onSuccess: (
    resp: AxiosResponse<KeywordActionSubmissionResponse, any>,
    rowIds: string[],
    actionType?: ActionType
  ) => void,
  onFailure: () => void,
  aoApiClient: AOApiClient,
  accountId: string,
  actions: RecommendationsAction[],
  setSaving: (value: React.SetStateAction<boolean | undefined>) => void,
  actionType?: ActionType
) => {
  await aoApiClient
    .submitRecommendations(accountId, actions)
    .then((resp) =>
      onSuccess(
        resp,
        actions.map((a) => a.recommendationId),
        actionType
      )
    )
    .catch(() => onFailure())
    .finally(() => {
      setSaving(false);
    });
};

export const submitNegativeRecommendations = async (
  onSuccess: (
    resp: AxiosResponse<KeywordActionSubmissionResponse, any>,
    rowIds: string[],
    actionType?: ActionType
  ) => void,
  onFailure: () => void,
  aoApiClient: AOApiClient,
  accountId: string,
  actions: RecommendationsAction[],
  setSaving: (value: React.SetStateAction<boolean | undefined>) => void,
  actionType?: ActionType
) => {
  await aoApiClient
    .submitNegativeRecommendations(accountId, actions)
    .then((resp) =>
      onSuccess(
        resp,
        actions.map((a) => a.recommendationId),
        actionType
      )
    )
    .catch(() => onFailure())
    .finally(() => {
      setSaving(false);
    });
};

export const transformFilterKWAv2 = (filters: Filter[]): Filter[] => {
  const filterIndexForSourcesColumn = filters.findIndex(
    (filter) => filter.field === KeywordActionColumns.SOURCE
  );

  if (
    filterIndexForSourcesColumn === -1 ||
    !(filters[filterIndexForSourcesColumn].value as string[]).includes(
      RecommendationSourceV2.AMZ_SP_SEARCH_TERM_REPORT
    )
  ) {
    return filters;
  } else {
    return filters.map((filter) => {
      if (filter.field === KeywordActionColumns.SOURCE) {
        return {
          ...filter,
          value: [
            ...(filter.value as string[]),
            RecommendationSourceV2.AMZ_SP_TARGET_SEARCH_TERM_REPORT as string,
          ],
        } as InFilter | NotInFilter;
      } else {
        return filter;
      }
    });
  }
};

export const markAllAdgroupsUnchecked = (
  adGroups: SelectedRecommendationsV2
) => {
  const newAdGroups: SelectedRecommendationsV2 = {};
  for (let adGroup in adGroups) {
    newAdGroups[adGroup] = { ...adGroups[adGroup], isChecked: false };
  }
  return newAdGroups;
};

export const rowsPerPageOptions: SelectV2OptionProps<number>[] = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
];
