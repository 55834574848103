import { Factory } from 'fishery';

import {
  BusinessPerformanceData,
  SkuMetrics,
} from '../../../modules/compass/containers/compassDashboard/utils/performance';

export const SkuPerformanceResponse = Factory.define<
  BusinessPerformanceData<SkuMetrics>
>(() =>
  JSON.parse(
    JSON.stringify({
      range: {
        startDate: '2022-06-11',
        endDate: '2022-07-10',
      },
      currency: 'USD',
      elements: [
        {
          productGroupId: 'a3a4131b-31e5-5bb1-9867-a50453893bea',
          merchantCountryId: '92faa498-eccd-4c59-999f-9fec646a8967',
          skuDetailsFields: {
            sku: 'W00038',
            name: 'TevraPet FirstAct Plus Flea and Tick Medicine for Cats - 6 Months Topical Prevention and Treatment',
            imageUrl:
              'https://i5.walmartimages.com/asr/254996b3-96f8-4b4d-9f29-fa439636ca12.995d29ab7c77748f75ae240e586eb659.png',
            extItemDetails: [
              {
                extItemId: '444519276',
                extItemType: 'walmart itemId',
                salesChannelId: '820d060b-1d53-4aab-99c2-03a12e3dcf29',
                salesChannelName: 'walmart',
                extParentItemId: null,
              },
            ],
            status: null,
            productCategory: null,
            categoryPath: null,
            primaryVariant: null,
          },
          currentMetrics: {
            productFields: {
              tacos: 0.6702753790246953,
              averageOrderPrice: null,
              category: null,
              status: null,
              categoryPath: null,
            },
            profitabilityFields: {
              totalSales: {
                amount: 4094.72,
                currency: 'USD',
              },
              estimatedGrossProfit: null,
              unitsSold: null,
              estimatedGrossMargin: null,
              estimatedPreAdGrossMargin: null,
            },
            platformFeesFields: {
              cogs: null,
              estimatedFees: null,
            },
            advertisementFields: {
              adSales: {
                amount: 3897.76,
                currency: 'USD',
              },
              adSpend: {
                amount: 2744.59,
                currency: 'USD',
              },
              acos: 0.15,
              conversionRate: null,
              campaignCount: null,
              adGroupCount: null,
              clicks: null,
              adConversions: null,
              costPerClick: {
                amount: 1.9059652777777778,
                currency: 'USD',
              },
              roas: null,
              impressions: 1719038,
              clickThroughRate: 0.0008376778174769842,
              adUnitsSold: null,
            },
            inventoryFields: {
              inventoryValue: null,
              sellThroughRate: null,
              inventoryQuantity: null,
            },
          },
        },
        {
          productGroupId: '1ba226f8-13ab-56bd-94a8-5781e24d456f',
          merchantCountryId: 'ba09e2f1-c328-49d6-8e68-de03ccbeca09',
          skuDetailsFields: {
            sku: 'NuClarity_FBA',
            name: 'NuClarity - Premium Nootropic Brain Supplement - Focus, Energy, Memory Booster - Mental Clarity & Cognitive Support - Ginkgo Biloba, Bacopa Monnieri, Alpha-GPC, Phosphatidylserine, Rhodiola Rosea',
            imageUrl: 'https://m.media-amazon.com/images/I/81i6FPeoF+L.jpg',
            extItemDetails: [
              {
                extItemId: 'B075RS7MR8',
                extItemType: 'ASIN',
                salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
                salesChannelName: 'amazon',
                extParentItemId: null,
              },
            ],
            status: null,
            productCategory: null,
            categoryPath: null,
            primaryVariant: null,
          },
          currentMetrics: {
            productFields: {
              averageOrderPrice: null,
              category: null,
              status: null,
              categoryPath: null,
            },
            profitabilityFields: {
              estimatedGrossProfit: null,
              unitsSold: null,
              estimatedGrossMargin: null,
              estimatedPreAdGrossMargin: null,
            },
            platformFeesFields: {
              cogs: null,
              estimatedFees: null,
            },
            advertisementFields: {
              acos: null,
              conversionRate: null,
              campaignCount: null,
              adGroupCount: null,
              clicks: null,
              adConversions: null,
              costPerClick: {
                amount: 1.5907352941176471,
                currency: 'USD',
              },
              roas: null,
              impressions: 20593,
              clickThroughRate: 0.0033020929442043414,
              adUnitsSold: null,
            },
            inventoryFields: {
              inventoryValue: null,
              sellThroughRate: null,
              inventoryQuantity: null,
            },
          },
        },
        {
          productGroupId: 'f0aa6394-fa77-5102-8fb9-fa6596edb8c6',
          merchantCountryId: 'ba09e2f1-c328-49d6-8e68-de03ccbeca09',
          skuDetailsFields: {
            sku: 'Multi-Collagen_FBA',
            name: 'Multi Collagen Pills with Hyaluronic Acid and Vitamin C, Biotin - Type I, II, III, V, X Hydrolyzed Collagen Protein; Healthy Hair, Skin, Nails, Joints - Collagen Peptides Capsules for Women & Men',
            imageUrl: 'https://m.media-amazon.com/images/I/61J9OrL02yL.jpg',
            extItemDetails: [
              {
                extItemId: 'B084NXMNQT',
                extItemType: 'ASIN',
                salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
                salesChannelName: 'amazon',
                extParentItemId: null,
              },
            ],
            status: null,
            productCategory: null,
            categoryPath: null,
            primaryVariant: null,
          },
          currentMetrics: {
            productFields: {
              tacos: 0.2677513160292491,
              averageOrderPrice: null,
              category: null,
              status: null,
              categoryPath: null,
            },
            profitabilityFields: {
              totalSales: {
                amount: 3896.19,
                currency: 'USD',
              },
              estimatedGrossProfit: null,
              unitsSold: null,
              estimatedGrossMargin: null,
              estimatedPreAdGrossMargin: null,
            },
            platformFeesFields: {
              cogs: null,
              estimatedFees: null,
            },
            advertisementFields: {
              adSales: {
                amount: 1507.84,
                currency: 'USD',
              },
              adSpend: {
                amount: 1043.21,
                currency: 'USD',
              },
              acos: null,
              conversionRate: null,
              campaignCount: null,
              adGroupCount: null,
              clicks: null,
              adConversions: null,
              costPerClick: {
                amount: 1.7832649572649573,
                currency: 'USD',
              },
              roas: null,
              impressions: 158593,
              clickThroughRate: 0.0036886873947778276,
              adUnitsSold: null,
            },
            inventoryFields: {
              inventoryValue: null,
              sellThroughRate: null,
              inventoryQuantity: null,
            },
          },
        },
        {
          productGroupId: '1f6fa805-fb2a-5063-80e8-2337498e687f',
          merchantCountryId: 'ba09e2f1-c328-49d6-8e68-de03ccbeca09',
          skuDetailsFields: {
            sku: 'NMN_FBA',
            name: 'NMN + Resveratrol Supplement - NAD+ Booster, DNA & Cellular Health, Longevity, Vitality. 5-in-1 Anti Aging Formula w/ Nicotinamide Mononucleotide, Trans Resveratrol, Vitamin C, Grape Seed, Pine Bark',
            imageUrl: 'https://m.media-amazon.com/images/I/31YHPrXsDOL.jpg',
            extItemDetails: [
              {
                extItemId: 'B09RDS92C8',
                extItemType: 'ASIN',
                salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
                salesChannelName: 'amazon',
                extParentItemId: null,
              },
            ],
            status: null,
            productCategory: null,
            categoryPath: null,
            primaryVariant: null,
          },
          currentMetrics: {
            productFields: {
              tacos: 1.1145107985775273,
              averageOrderPrice: null,
              category: null,
              status: null,
              categoryPath: null,
            },
            profitabilityFields: {
              totalSales: {
                amount: 3422.21,
                currency: 'USD',
              },
              estimatedGrossProfit: null,
              unitsSold: null,
              estimatedGrossMargin: null,
              estimatedPreAdGrossMargin: null,
            },
            platformFeesFields: {
              cogs: null,
              estimatedFees: null,
            },
            advertisementFields: {
              adSales: {
                amount: 3507.66,
                currency: 'USD',
              },
              adSpend: {
                amount: 3814.09,
                currency: 'USD',
              },
              acos: null,
              conversionRate: null,
              campaignCount: null,
              adGroupCount: null,
              clicks: null,
              adConversions: null,
              costPerClick: {
                amount: 2.9589526764934058,
                currency: 'USD',
              },
              roas: null,
              impressions: 335262,
              clickThroughRate: 0.003844754251898515,
              adUnitsSold: null,
            },
            inventoryFields: {
              inventoryValue: null,
              sellThroughRate: null,
              inventoryQuantity: null,
            },
          },
        },
        {
          productGroupId: '0cf2a97e-75af-5af5-81e7-94fe0891cf33',
          merchantCountryId: 'ba09e2f1-c328-49d6-8e68-de03ccbeca09',
          skuDetailsFields: {
            sku: 'Collagen_FBA',
            name: 'Hydrolyzed Collagen Powder - Pure Unflavored Collagen Protein Powder - Type 1 and 3 Collagen Peptides for Women & Men - Grass Fed, Keto, Gluten Free - Supports Healthy Hair, Skin, Nails - 41 Servings',
            imageUrl: 'https://m.media-amazon.com/images/I/61ix89CsDeL.jpg',
            extItemDetails: [
              {
                extItemId: 'B07BZYLXDZ',
                extItemType: 'ASIN',
                salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
                salesChannelName: 'amazon',
                extParentItemId: null,
              },
            ],
            status: null,
            productCategory: null,
            categoryPath: null,
            primaryVariant: null,
          },
          currentMetrics: {
            productFields: {
              tacos: 0.22546853821290794,
              averageOrderPrice: null,
              category: null,
              status: null,
              categoryPath: null,
            },
            profitabilityFields: {
              totalSales: {
                amount: 3069.12,
                currency: 'USD',
              },
              estimatedGrossProfit: null,
              unitsSold: null,
              estimatedGrossMargin: null,
              estimatedPreAdGrossMargin: null,
            },
            platformFeesFields: {
              cogs: null,
              estimatedFees: null,
            },
            advertisementFields: {
              adSales: {
                amount: 959.1,
                currency: 'USD',
              },
              adSpend: {
                amount: 691.99,
                currency: 'USD',
              },
              acos: null,
              conversionRate: null,
              campaignCount: null,
              adGroupCount: null,
              clicks: null,
              adConversions: null,
              costPerClick: {
                amount: 2.2037898089171977,
                currency: 'USD',
              },
              roas: null,
              impressions: 68685,
              clickThroughRate: 0.004571594962510009,
              adUnitsSold: null,
            },
            inventoryFields: {
              inventoryValue: null,
              sellThroughRate: null,
              inventoryQuantity: null,
            },
          },
        },
        {
          productGroupId: '00ca1069-9c0a-53eb-900f-919ba2246b5c',
          merchantCountryId: 'ba09e2f1-c328-49d6-8e68-de03ccbeca09',
          skuDetailsFields: {
            sku: 'Thyroid_FBA',
            name: 'Thyroid Support Complex Supplement - Thyroid Support for Women and Men - Boost Energy, Focus, Metabolism - Iodine Supplements for Thyroid + B12, Selenium, Zinc, Copper, Ashwagandha, L-Tyrosine & More',
            imageUrl: 'https://m.media-amazon.com/images/I/718DEjn8v5L.jpg',
            extItemDetails: [
              {
                extItemId: 'B07CSHF769',
                extItemType: 'ASIN',
                salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
                salesChannelName: 'amazon',
                extParentItemId: null,
              },
            ],
            status: null,
            productCategory: null,
            categoryPath: null,
            primaryVariant: null,
          },
          currentMetrics: {
            productFields: {
              tacos: 0.2211253946309556,
              averageOrderPrice: null,
              category: null,
              status: null,
              categoryPath: null,
            },
            profitabilityFields: {
              totalSales: {
                amount: 2423.15,
                currency: 'USD',
              },
              estimatedGrossProfit: null,
              unitsSold: null,
              estimatedGrossMargin: null,
              estimatedPreAdGrossMargin: null,
            },
            platformFeesFields: {
              cogs: null,
              estimatedFees: null,
            },
            advertisementFields: {
              adSales: {
                amount: 360.43,
                currency: 'USD',
              },
              adSpend: {
                amount: 535.82,
                currency: 'USD',
              },
              acos: null,
              conversionRate: null,
              campaignCount: null,
              adGroupCount: null,
              clicks: null,
              adConversions: null,
              costPerClick: {
                amount: 1.734045307443366,
                currency: 'USD',
              },
              roas: null,
              impressions: 119816,
              clickThroughRate: 0.0025789543967416704,
              adUnitsSold: null,
            },
            inventoryFields: {
              inventoryValue: null,
              sellThroughRate: null,
              inventoryQuantity: null,
            },
          },
        },
      ],
      performanceSummary: {
        totalSales: {
          amount: 38493.05,
          currency: 'USD',
        },
        adSpend: {
          amount: 17716.14,
          currency: 'USD',
        },
        adSales: {
          amount: 24121.7,
          currency: 'USD',
        },
        tacos: 0.4602425632679146,
      },
    })
  )
);
