import { Factory } from 'fishery';
import { CurrencyCode } from '../../utilities/currency';

import {
  BusinessPerformanceData,
  CampaignMetrics,
} from '../../../modules/compass/containers/compassDashboard/utils/performance';
import { AdType } from '../../types/AOSharedTypes';
import { HomepageResponseMetricKeys } from '../../types/CompassSharedTypes';
import { MerchantType } from '../../types/Fam';

export const CampaignPerformanceResponse = Factory.define<
  BusinessPerformanceData<CampaignMetrics>
>(
  (): BusinessPerformanceData<CampaignMetrics> => ({
    range: {
      startDate: '2022-04-27',
      endDate: '2022-05-03',
    },
    currency: CurrencyCode.USD,
    performanceSummary: {
      [HomepageResponseMetricKeys.TotalSales]: {
        amount: '500000',
        currency: CurrencyCode.USD,
      },
      [HomepageResponseMetricKeys.AdSalesTotal]: {
        amount: '200000',
        currency: CurrencyCode.USD,
      },
      [HomepageResponseMetricKeys.AdSpend]: {
        amount: '300000',
        currency: CurrencyCode.USD,
      },
    },
    elements: [
      {
        campaignId: 'fd50d8f1-5a28-5444-a49f-3de552c07f75',
        campaignDetails: {
          campaignName: 'Kitchen|Auto|SP-KW',
          advertisingType: AdType.SponsoredProducts,
        },
        merchantCountryDetails: {
          merchantCountryId: 'ba09e2f1-c328-49d6-8e68-de03ccbeca09',
          salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
          salesChannelName: 'amazon',
          merchantType: MerchantType.Seller,
        },
        campaignPerformance: {
          [HomepageResponseMetricKeys.AdSalesTotal]: {
            amount: '13515.805599104146',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.TotalSales]: {
            amount: '13515.805599104146',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.AdSpend]: {
            amount: '2713.2067189249724',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.ACoSTotal]: 0.20074324826814682,
          [HomepageResponseMetricKeys.ACoS]: 0.20074324826814682,
        },
      },
      {
        campaignId: '403d1d25-30fb-5ae5-9594-fa912214f11e',
        campaignDetails: {
          campaignName: 'Kitchen|Non-Brand|SP-KW',
          advertisingType: AdType.SponsoredBrands,
        },
        merchantCountryDetails: {
          merchantCountryId: 'ba09e2f1-c328-49d6-8e68-de03ccbeca09',
          salesChannelId: '820d060b-1d53-4aab-99c2-03a12e3dcf29',
          salesChannelName: 'walmart',
          merchantType: MerchantType.Seller,
        },
        campaignPerformance: {
          [HomepageResponseMetricKeys.TotalSales]: {
            amount: '13256.736058230686',
            currency: CurrencyCode.USD,
          },
        },
      },
      {
        campaignId: 'f64bb951-c7f9-52f8-84e5-28351ba0b366',
        campaignDetails: {
          campaignName: 'Kitchen|Auto|SP-PT',
          advertisingType: AdType.SponsoredProducts,
        },
        merchantCountryDetails: {
          merchantCountryId: 'ba09e2f1-c328-49d6-8e68-de03ccbeca09',
          salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
          salesChannelName: 'amazon',
          merchantType: MerchantType.Seller,
        },
        campaignPerformance: {
          [HomepageResponseMetricKeys.TotalSales]: {
            amount: '12742.399216125421',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.AdSpend]: {
            amount: '2069.9070548712207',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.AdSalesTotal]: {
            amount: '515.805599104146',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.ACoSTotal]: 0.16244248981398787,
        },
      },
      {
        campaignId: 'b0289d29-a6ac-5af5-b6d5-b235f0e7c84b',
        campaignDetails: {
          campaignName: 'Closet Organization|Auto|SP-PT',
          advertisingType: AdType.SponsoredProducts,
        },
        merchantCountryDetails: {
          merchantCountryId: 'ba09e2f1-c328-49d6-8e68-de03ccbeca09',
          salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
          salesChannelName: 'amazon',
          merchantType: MerchantType.Seller,
        },
        campaignPerformance: {
          [HomepageResponseMetricKeys.AdSalesTotal]: {
            amount: '1795.805599104146',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.TotalSales]: {
            amount: '7921.280291153416',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.AdSpend]: {
            amount: '1174.503359462486',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.ACoSTotal]: 0.14827191013227822,
        },
      },
      {
        campaignId: '86f49633-0fd8-5611-a3b6-6ee3fd76a66c',
        campaignDetails: {
          campaignName: 'Bath Storage|Auto|SP-PT',
          advertisingType: AdType.SponsoredProducts,
        },
        merchantCountryDetails: {
          merchantCountryId: 'ba09e2f1-c328-49d6-8e68-de03ccbeca09',
          salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
          salesChannelName: 'amazon',
          merchantType: MerchantType.Seller,
        },
        campaignPerformance: {
          [HomepageResponseMetricKeys.AdSalesTotal]: {
            amount: '1125.805599104146',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.TotalSales]: {
            amount: '6501.666517357222',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.AdSpend]: {
            amount: '1032.1657334826427',
            currency: CurrencyCode.USD,
          },
          [HomepageResponseMetricKeys.ACoSTotal]: 0.15875402571434782,
        },
      },
    ],
  })
);
