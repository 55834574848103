import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  AdGroupDetails,
  AiBiddingValues,
  MAP_ADVERTISING_GOAL_TO_CAMPAIGN_GOAL,
  MAP_ADVERTISING_GOAL_TO_ICON,
  MAP_ADVERTISING_GOAL_TO_II8NKEY,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  ADGROUPS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getOrDefaultIsBiddableFlag,
  isAdGroupStatusArchived,
  isCampaignStatusArchived,
  isInputValueNumber,
} from '../utils';
import {
  BadgeCampaignGoal,
  BidModifier,
  ButtonState,
  ButtonVariant,
  CampaignGoalTempOverrideIcon,
  Icon,
  IconSize,
  Modal,
  NumericInput,
  NumericInputSize,
  NumericInputState,
  PERCENTAGE_FRACTION_DIGITS,
  SelectV3,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';
import { TableDataAdsManager } from '../types';
import { DateTime } from 'luxon';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { WithTable } from 'containers/table/ducks/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import { SMART_GOALS, transformAdvertisingGoal } from './utils';

enum OverrideHours {
  TwentyFour = '24',
  FourtyEight = '48',
  SeventyTwo = '72',
}

const MAX_BID_MODIFIER = 1000;

export const shouldUpdateValue = (
  isEditMode: boolean,
  updatedValue: string,
  value: string
) => {
  return isEditMode || (!isNil(updatedValue) && updatedValue !== value);
};

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  channelSettings: { status: adGroupStatus },
  adGroupDetails: { campaignStatus, isBiddable },
  aiEnabled,
  aiDisabledForAllSelectedMerchants,
  adGroupId,
  isEditMode,
  flywheelSettings: {
    bidModifier,
    aiBidding,
    isOverrideBidModifier,
    overrideEndTime,
    advertisingGoal: goal,
  },
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isAiDisabled = !aiEnabled || aiDisabledForAllSelectedMerchants || false;

  const [showTurnOnOverrideModal, setShowTurnOnOverrideModal] =
    useState<boolean>(false);

  const currentPage = useSelector<WithTable<AdGroupDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_ADGROUP_TABLE_ID
      )
  );

  const existingValue = bidModifier
    ? (bidModifier * 100).toFixed(PERCENTAGE_FRACTION_DIGITS)
    : '';

  const updatedBidModifier = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        adGroupId,
        ADGROUPS_API_COLUMN_NAME.BidModifier
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const updateCellValue = (inputValue: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
        rowId: adGroupId,
        columnName: ADGROUPS_API_COLUMN_NAME.BidModifier,
        value: inputValue,
        existingValue,
        numericValue: true,
      })
    );
  };

  const finalBidModifier = updatedBidModifier ?? existingValue;
  const [value, setValue] = useState<string>(finalBidModifier);

  useEffect(() => {
    if (shouldUpdateValue(isEditMode, updatedBidModifier, value)) {
      setValue(finalBidModifier);
    }
  }, [isEditMode, currentPage, updatedBidModifier]);

  const [hasBidBeenOverridden, setHasBidBeenOverridden] = useState<boolean>(
    isOverrideBidModifier ?? false
  );

  const updateCellValueForOverRide = (shouldOverRide: boolean) => {
    setHasBidBeenOverridden(shouldOverRide);
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
        rowId: adGroupId,
        columnName: ADGROUPS_API_COLUMN_NAME.BidModifierOverRide,
        value: shouldOverRide ? 'true' : 'false',
        existingValue: isOverrideBidModifier ? 'true' : 'false',
        numericValue: false,
      })
    );
  };

  const updateCellValueForOverRideHours = (value?: OverrideHours) => {
    onOverRideHoursChange(value);
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
        rowId: adGroupId,
        columnName: ADGROUPS_API_COLUMN_NAME.BidModifierOverRideEndTime,
        value: value
          ? DateTime.now()
              .plus({ hours: Number(overRideHours) })
              .toISO()
          : '',
        existingValue: '',
        numericValue: false,
      })
    );
  };

  const getOverRideUntil = () => {
    if (overRideHours) {
      return DateTime.now()
        .plus({
          hours: Number(overRideHours),
        })
        .setZone('gmt');
    } else if (overrideEndTime) {
      return DateTime.fromISO(overrideEndTime, { zone: 'gmt' });
    }
  };

  const onTurnOnOverride = (
    adGroupId: string,
    goal: BadgeCampaignGoal,
    value?: string
  ) => {
    setShowTurnOnOverrideModal(true);
  };
  const onCancelOverride = () => {
    updateCellValue(existingValue);
    setShowTurnOnOverrideModal(false);
    updateCellValueForOverRideHours();
  };
  const onSubmitOverride = () => {
    updateCellValueForOverRide(true);
    setShowTurnOnOverrideModal(false);
    updateCellValueForOverRideHours(overRideHours);
  };
  const onOverrideEnd = (adGroupId: string) => {
    updateCellValueForOverRide(false);
    updateCellValueForOverRideHours();
    updateCellValue(existingValue);
  };

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [adGroupId]
  );

  const onBidChange = (value: string) => {
    if (Number(value.length) <= MAX_BID_MODIFIER && isInputValueNumber(value)) {
      setValue(value);
      debouncedUpdateCellValue(value);
    }
  };

  const [overRideHours, setOverrideHours] = useState<OverrideHours>();

  const onOverRideHoursChange = (value?: OverrideHours) => {
    setOverrideHours(value);
  };

  const updatedAiBiddingValue = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        adGroupId,
        ADGROUPS_API_COLUMN_NAME.AiBidding
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  ) as AiBiddingValues;
  const aiBiddingValue = updatedAiBiddingValue ?? aiBidding;

  const advertisingGoal = transformAdvertisingGoal(
    aiBiddingValue,
    goal,
    hasBidBeenOverridden
  );

  const isValidSettings = useMemo(() => {
    if (aiBiddingValue === AiBiddingValues.Ai) {
      if (value === '') {
        return false;
      }
      return true;
    }
    return true;
  }, [value, aiBiddingValue]);

  const getOverRideModalSubmitButtonState = () => {
    if (value && overRideHours) {
      return ButtonState.Active;
    }
    return ButtonState.Disabled;
  };

  const isDirty = isValueDirty(value, existingValue);

  const isArchivedAdGroup =
    isAdGroupStatusArchived(adGroupStatus) ||
    isCampaignStatusArchived(campaignStatus);

  const automationDisabledForLegacyRow =
    !getOrDefaultIsBiddableFlag(isBiddable);

  const isSmartGoal = SMART_GOALS.includes(advertisingGoal);

  return (
    <div className="flex items-start px-12 py-8 justify-end w-full">
      <Modal
        className="w-420"
        overlayZindexClassName="z-20"
        showModal={showTurnOnOverrideModal}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onClose={() => {
          setShowTurnOnOverrideModal(false);
        }}
        dataTestId="override_bid_multiplier"
        mainAction={{
          label: I18nKey.GENERIC_SUBMIT,
          state: getOverRideModalSubmitButtonState(),
          onClick: onSubmitOverride,
        }}
        secondaryAction={{
          label: I18nKey.GENERIC_CANCEL,
          onClick: onCancelOverride,
          variant: ButtonVariant.BlackAndWhiteBorder,
        }}
      >
        <div className="flex flex-col gap-8">
          <Icon svg={CampaignGoalTempOverrideIcon} size={IconSize.Medium} />
          <Typography
            weight={TypographyWeight.semibold}
            lineHeight={TypographyLineHeight.tight}
            size={TypographySize.xl}
            className="text-grey-900"
          >
            {intl.formatMessage({
              id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_BID_MODIFIER_MODAL_HEADER,
            })}
          </Typography>
          <div className="flex flex-col gap-4 mt-8">
            <div className="flex flex-row justify-between items-center gap-16">
              <Typography
                weight={TypographyWeight.regular}
                lineHeight={TypographyLineHeight.tight}
                size={TypographySize.base}
                className="text-grey-900"
              >
                {intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_BID_MODIFIER_MODAL_TIME_PERIOD,
                })}
              </Typography>
              <Typography
                weight={TypographyWeight.regular}
                lineHeight={TypographyLineHeight.tight}
                size={TypographySize.base}
                className="text-grey-900"
              >
                {intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_BID_MODIFIER,
                })}
              </Typography>
            </div>
            <div className="flex flex-row justify-between items-center gap-16">
              <div className="flex-grow">
                <SelectV3
                  value={overRideHours}
                  options={[
                    {
                      label: `${
                        OverrideHours.TwentyFour
                      } - ${intl.formatMessage({
                        id: I18nKey.GENERIC_HOURS,
                      })}`,
                      value: OverrideHours.TwentyFour,
                    },
                    {
                      label: `${
                        OverrideHours.FourtyEight
                      } - ${intl.formatMessage({
                        id: I18nKey.GENERIC_HOURS,
                      })}`,
                      value: OverrideHours.FourtyEight,
                    },
                    {
                      label: `${
                        OverrideHours.SeventyTwo
                      } - ${intl.formatMessage({
                        id: I18nKey.GENERIC_HOURS,
                      })}`,
                      value: OverrideHours.SeventyTwo,
                    },
                  ]}
                  placeholder=""
                  onChange={onOverRideHoursChange}
                />
              </div>

              <NumericInput
                value={value.toString()}
                acceptOnlyPositiveNumbers={true}
                appendedElement={'%'}
                className="w-80"
                numericInputSize={NumericInputSize.Medium}
                min={1}
                max={999}
                step={10}
                onChange={onBidChange}
                placeholder="100"
              />
            </div>
          </div>

          <div className="flex items-start gap-12 p-12 mt-8 bg-grey-50 border border-solid border-grey-200 rounded-4">
            <Icon
              svg={MAP_ADVERTISING_GOAL_TO_ICON[advertisingGoal]}
              size={IconSize.Medium}
              className="min-w-16"
            />
            <Typography
              weight={TypographyWeight.regular}
              lineHeight={TypographyLineHeight.normal}
              size={TypographySize.sm}
              className="text-grey-900"
            >
              {intl.formatMessage<ReactNode>(
                {
                  id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_BID_MODIFIER_MODAL_INFO_MESSAGE,
                },
                {
                  bold: (content) => (
                    <span className="font-medium">{content}</span>
                  ),
                  advertisingGoal: intl.formatMessage({
                    id: MAP_ADVERTISING_GOAL_TO_II8NKEY[advertisingGoal],
                  }),
                }
              )}
            </Typography>
          </div>
        </div>
      </Modal>
      <BidModifier
        id={adGroupId}
        campaignGoal={MAP_ADVERTISING_GOAL_TO_CAMPAIGN_GOAL[advertisingGoal]}
        value={isEditMode ? value : existingValue}
        isEditable={
          isEditMode &&
          (isSmartGoal && aiBidding === AiBiddingValues.NotEnabled
            ? false
            : aiBiddingValue.toString() !== AiBiddingValues.NotEnabled)
        }
        overrideUntil={getOverRideUntil()}
        onChange={onBidChange}
        onTurnOnOverride={onTurnOnOverride}
        onOverrideEnd={onOverrideEnd}
        placeholder="100"
        inputState={getBidModifierInputState(
          value,
          isAiDisabled || isArchivedAdGroup || automationDisabledForLegacyRow,
          isValidSettings,
          updatedBidModifier !== ''
        )}
        isDirty={isDirty}
      />
    </div>
  );
};
RowCellElement.displayName = 'BidModifier';

export const bidModifierColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.BidModifier,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_BID_MODIFIER,
  RowCellElement,
  gridColumnWidth: '150px',
  columnHeaderClassName: 'ml-8',
};

const getBidModifierInputState = (
  value: string,
  isInputDisabled: boolean,
  isValidSettings: boolean,
  isInputEdited: boolean
) => {
  if (isInputDisabled) {
    return NumericInputState.Disabled;
  }

  if (!isValidSettings || Number(value) === 0) {
    return NumericInputState.Error;
  }

  if (isInputEdited) {
    return NumericInputState.Default;
  }

  return NumericInputState.Default;
};

const isValueDirty = (value: string, existingValue: string): boolean =>
  value || existingValue ? value !== existingValue : false;

export const bidModifierFilter = createPercentDataFieldFilter(
  ADGROUPS_API_COLUMN_NAME.BidModifier,
  I18nKey.ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_BID_MODIFIER,
  isValidNumber(),
  true
);
