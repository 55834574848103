import noop from 'lodash/noop';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Alignment,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  ColumnAlign,
  Icon,
  IconSize,
  OffClickable,
  PencilIcon,
  Placement,
  PortaledTooltip,
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
  TableInput,
  Type,
  saveButtonState,
} from '@teikametrics/tm-design-system';

import {
  NotificationContext,
  NotificationContextState,
} from '../../../../../containers/notificationProvider';
import { tableSelectors } from '../../../../../containers/tableV2/ducks';
import { updateCell } from '../../../../../containers/tableV2/ducks/actions';
import {
  TableCell,
  WithTable,
} from '../../../../../containers/tableV2/ducks/types';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import {
  getCurrentAccountFromContext,
  getCurrentAccountPermissions,
} from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import { createFAMApiClient } from '../../../../../lib/clients/FAMApiClient';
import { Role, SalesChannelData } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  RowCellElementProps,
  SALES_CHANNELS_TABLE,
  SalesChannelTableData,
} from '../types';
import { ViewerRoleTooltip } from '../../../../../lib/utilities/viewerRoleTooltipWrapper';
import classNames from 'classnames';

const MIN_NAME_LENGTH = 1;
const MAX_NAME_LENGTH = 50;

const getSaveButtonState = (
  loading: boolean,
  newName: string,
  disabledSaveButton: boolean
) => {
  if (loading) {
    return saveButtonState.Loading;
  }

  return disabledSaveButton || newName.length < MIN_NAME_LENGTH
    ? saveButtonState.Disabled
    : saveButtonState.Enabled;
};

export const NameCellElement: React.FC<
  RowCellElementProps & { isUserRoleViewOnly?: boolean }
> = ({ id, name: initialName, isUserRoleViewOnly }) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const userContext = useContext<UserContextState>(UserContext);
  const toasts = useContext<NotificationContextState>(NotificationContext);

  const [showTableInput, setShowTableInput] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>(initialName || '');
  const [name, setCurrentName] = useState<string>(initialName || '');
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(false);
  const [duplicateNameError, setDuplicateNameError] = useState<boolean>(false);

  const famApiClient = createFAMApiClient(userContext.userInfo.idToken!);

  const userAccount = getCurrentAccountFromContext(userContext)!;
  const role = getCurrentAccountPermissions(
    userContext.userInfo.userDetails
  )?.role;

  const [
    ERROR,
    ERROR_MESSAGE,
    SUCCESS,
    TOAST_NAME_SUCCESS,
    ADD_NAME_PLACEHOLDER,
    TABLE_DUPLICATE_NAME_TEXT,
    NAME_BUTTON_TEXT,
  ] = [
    I18nKey.ERROR,
    I18nKey.ERROR_MESSAGE,
    I18nKey.SUCCESS,
    I18nKey.ACCOUNT_SALES_CHANNELS_TOAST_NAME_SUCCESS,
    I18nKey.ACCOUNT_SALES_CHANNELS_ADD_NAME_PLACEHOLDER,
    I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_DUPLICATE_NAME_TEXT,
    I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_NAME_BUTTON_TEXT,
  ].map((id) => intl.formatMessage({ id }));

  const [TOOLTIP_TEXT, DISABLED_TOOLTIP_TEXT] = [
    I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_NAME_TOOLTIP_TEXT,
    I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_NAME_DISABLE_TOOLTIP_TEXT,
  ].map((id) => intl.formatMessage({ id }, { separator: <br /> }));

  const onClick = () => {
    if (role === Role.VIEW_ONLY) {
      return;
    }
    setNewName(name || '');
    setShowTableInput(true);
  };

  const onCancel = () => {
    setShowTableInput(false);
    setLoading(false);
    setDisabledSaveButton(false);
    setDuplicateNameError(false);
  };

  const onChange = (salesChannelName: string) => {
    setNewName(salesChannelName);
  };

  const failureToast = () => {
    toasts.addNotification({
      headline: ERROR,
      description: ERROR_MESSAGE,
      type: Type.Attention,
      onClose: noop,
      dataTestId: 'salesChannelsNameChangeError',
    });
  };

  const successToast = () => {
    toasts.addNotification({
      headline: SUCCESS,
      description: TOAST_NAME_SUCCESS,
      type: Type.Success,
      onClose: noop,
      dataTestId: 'salesChannelsNameChangeSuccess',
    });
  };

  const changedValue = useSelector<WithTable<SalesChannelData>, TableCell>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(id, 'name')(
        tableState,
        SALES_CHANNELS_TABLE
      )
  );

  useEffect(() => {
    setCurrentName(changedValue?.value ?? name);
  }, [changedValue]);

  const onSave = () => {
    setLoading(true);

    famApiClient
      .updateSalesChannel({ name: newName }, userAccount.id, id)
      .then(() => {
        const payload = {
          tableId: SALES_CHANNELS_TABLE,
          columnName: 'name',
          rowId: id,
          isValid: true,
          numericValue: false,
          isDirty: true,
          existingValue: name,
          value: newName,
        };
        dispatch(updateCell(payload));
        setLoading(false);
        setShowTableInput(false);
        setNewName('');
        successToast();
      })
      .catch((err) => {
        setLoading(false);

        if (err.response) {
          const serverErrorPattern = /^5\d{2}$/;
          if (serverErrorPattern.test(err.response.status)) {
            failureToast();
          }

          const duplicateNameErrorPattern = /409/;
          if (duplicateNameErrorPattern.test(err.response.status)) {
            setDuplicateNameError(true);
            setDisabledSaveButton(true);
          }
        } else {
          failureToast();
        }
      });
  };

  const TOOLTIP = id ? TOOLTIP_TEXT : DISABLED_TOOLTIP_TEXT;

  const errorElement = duplicateNameError ? (
    <div className="h-11 text-orange-500 text-sm">
      {TABLE_DUPLICATE_NAME_TEXT}
    </div>
  ) : undefined;

  const nameButtonState =
    id && role !== Role.EDITOR && role !== Role.VIEW_ONLY
      ? ButtonState.Enabled
      : ButtonState.Disabled;

  const addNameButton = () => {
    return (
      // {/* padding to fix tooltip bug as mouseleave event does not fire on disabled elements */}
      <div className="p-12">
        <Button
          label={NAME_BUTTON_TEXT}
          size={ButtonSize.Small}
          state={nameButtonState}
          svgIcon={PencilIcon}
          variant={ButtonVariant.BlackAndWhiteBorder}
          dataTestId="ss_addName"
          onClick={onClick}
        />
      </div>
    );
  };

  return (
    <div className="flex items-start bg-transparent">
      {name ? (
        <div className="flex items-center p-12">
          <span>{name}</span>
          {role !== Role.EDITOR && (
            <ViewerRoleTooltip
              position={{
                placement: Placement.Right,
                alignment: Alignment.Center,
              }}
              disabled={role !== Role.VIEW_ONLY}
            >
              <span
                className={classNames('cursor-pointer ml-8 h-auto flex', {
                  'text-grey-400 cursor-not-allowed': role === Role.VIEW_ONLY,
                })}
                data-test-id="ss_updateNameIcon"
                onClick={onClick}
              >
                <Icon
                  svg={PencilIcon}
                  size={IconSize.Small}
                  className={classNames({
                    'text-grey-300 hover:text-grey-500':
                      role !== Role.VIEW_ONLY,
                    'text-grey-400 cursor-not-allowed': role === Role.VIEW_ONLY,
                  })}
                />
              </span>
            </ViewerRoleTooltip>
          )}
        </div>
      ) : role !== Role.VIEW_ONLY ? (
        <PortaledTooltip
          content={TOOLTIP}
          position={{ placement: PortaledTooltipPlacement.Right }}
          theme={PortaledTooltipTheme.Dark}
          dataTestId="salesChannelsAddCustomNameForConnection"
        >
          {addNameButton()}
        </PortaledTooltip>
      ) : (
        <ViewerRoleTooltip
          position={{
            placement: Placement.Right,
            alignment: Alignment.Center,
          }}
          disabled={role !== Role.VIEW_ONLY}
        >
          {addNameButton()}
        </ViewerRoleTooltip>
      )}
      {showTableInput && (
        <OffClickable
          className={`flex items-center px-12
            absolute left-0 top-0 right-0 bottom-0`}
          offClickHandler={onCancel}
        >
          <TableInput
            value={newName}
            saveButtonState={getSaveButtonState(
              loading,
              newName,
              disabledSaveButton
            )}
            maxLength={MAX_NAME_LENGTH}
            minLength={MIN_NAME_LENGTH}
            disabled={loading}
            placeholder={ADD_NAME_PLACEHOLDER}
            errorElement={errorElement}
            onChange={onChange}
            onSave={onSave}
            onCancel={onCancel}
          />
        </OffClickable>
      )}
    </div>
  );
};
NameCellElement.displayName = 'NameCellElement';

export const nameColumn: FlywheelTableV2ColumnProps<
  SalesChannelData,
  {},
  SalesChannelTableData,
  {}
> = {
  header: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_NAME,
  cell: ({ row: { original } }) => <NameCellElement {...original} />,
  accessorKey: 'name',
  size: 340,
  sortable: true,
  align: ColumnAlign.Left,
};
