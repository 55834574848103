import React from 'react';
import { useIntl } from 'react-intl';

import {
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { ConnectionInitialModalProps } from './';
import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';

export const ModalFooter: React.FC<ConnectionInitialModalProps> = ({
  goBack,
  next,
  channel,
}) => {
  const intl = useIntl();

  const [BACK, CONTINUE] = [
    I18nKey.BACK,
    channel === FlywheelSalesChannel.Walmart
      ? I18nKey.CONTINUE
      : I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_CONNECT_ADVERTISING,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <div className="flex justify-between mx-8">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.BlackAndWhite}
        label={BACK}
        svgIcon={ArrowLeftIcon}
        onClick={goBack}
        dataTestId={`flash1_${channel}_initial_connect_back`}
      />
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.Primary}
        label={CONTINUE}
        onClick={next}
        dataTestId={`flash1_${channel}_initial_connect_continue`}
      />
    </div>
  );
};
