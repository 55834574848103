import './styles.scss';

import classNames from 'classnames';
import { fromNullable } from 'fp-ts/lib/Option';
import flatMap from 'lodash/flatMap';
import React from 'react';

import calculateGridTemplateRows from './calculateGridTemplateRows';
import calculateNumberOfTableRows from './calculateNumberOfTableRows';
import ColumnGroupsRow from './columnGroupsRow';
import getColumnsWithGroupingBorder from './getColumnsWithGroupingBorder';
import Row from './row';
import SubRowsContainer from './subRowsContainer';
import {
  Column,
  ColumnDataForRow,
  GridTableProps,
  TableDensity,
  isArrayOfColumnGroups,
} from './types';

function GridTable<
  RowDataType,
  SubRowDataType = {},
  SubRowChildRowDataType = {},
  HeaderDataType = {},
  FooterDataType = {},
  TableDataType = {}
>(
  props: GridTableProps<
    RowDataType,
    SubRowDataType,
    SubRowChildRowDataType,
    HeaderDataType,
    FooterDataType,
    TableDataType
  >
) {
  const [maybeHeaderCellStyles, setMaybeHeaderCellStyles] =
    React.useState<React.CSSProperties>({});

  const {
    tableDensity = TableDensity.Compact,
    headerData,
    rowData,
    footerData,
    tableData,
    hasStickyHeader,
    hasStickyLeftColumn,
    hasStickyFooter,
    maybeSubRows,
    maybeSubRowChildRows,
    noDataDisplayComponent,
    tableId,
  } = props;

  const onColumnGroupingRowHeightChange = (height: number) => {
    if (hasStickyHeader) {
      setMaybeHeaderCellStyles({ top: `${height}px` });
    }
  };

  const [columns, columnsWithGroupingBorder, columnGroupingRow]: [
    Array<Column<RowDataType, HeaderDataType, FooterDataType, TableDataType>>,
    Set<number> | undefined,
    JSX.Element | undefined
  ] = isArrayOfColumnGroups<
    RowDataType,
    HeaderDataType,
    FooterDataType,
    TableDataType
  >(props.columns)
    ? [
        flatMap(props.columns, (cg) => cg.columnsInGroup),
        getColumnsWithGroupingBorder(
          props.columns.map((c) => ({
            numberOfColumnsInGroup: c.columnsInGroup.length,
          }))
        ),
        <ColumnGroupsRow
          key="column-grouping-row"
          columnGroups={props.columns}
          onKnownHeight={onColumnGroupingRowHeightChange}
        />,
      ]
    : [props.columns, undefined, undefined];

  const hasHeader = columns.some((c) => c.HeaderCellElement !== undefined);
  const hasFooter = columns.some((c) => c.FooterCellElement !== undefined);
  const tableHasGroupedColumns = columnGroupingRow !== undefined;

  const gridTemplateColumns = columns
    .map((column) => `minmax(${column.gridColumnWidth}, auto)` || 'auto')
    .join(' ');

  const numberOfDataRows = calculateNumberOfTableRows({
    allRowData: rowData,
    maybeSubRowChildRows,
    maybeSubRows,
    tableData,
  });

  const gridTemplateRows = calculateGridTemplateRows({
    numberOfRowsInTable: numberOfDataRows,
    tableHasHeader: hasHeader,
    tableHasFooter: hasFooter,
    tableHasNoDataComponent: noDataDisplayComponent !== undefined,
    tableHasGroupedColumns,
  });

  let tableIdClass: string = '';

  if (hasStickyLeftColumn) {
    tableIdClass = `${tableId}`;
  }

  return (
    <div
      className={classNames(
        'grid-table overflow-auto scrollbar scrollbar-thin scrollbar-thumb-grey-400 scrollbar-track-grey-100 scrollbar-thumb-rounded-full',
        {
          'grid-table--sticky-header': hasStickyHeader,
          'grid-table--sticky-left-column': hasStickyLeftColumn,
          'grid-table--sticky-footer': hasStickyFooter,
          'grid-table--grouped-columns': tableHasGroupedColumns,
          'grid-table--no-data': rowData.length === 0,
          'grid-table--with-no-data-component':
            noDataDisplayComponent !== undefined,
          'grid-table--comfortable': tableDensity === TableDensity.Comfortable,
        },
        tableIdClass
      )}
      style={{ gridTemplateColumns, gridTemplateRows }}
    >
      {columnGroupingRow}
      <Row
        columns={columns.map((c) => ({
          columnComponent: c.HeaderCellElement,
          columnClassName: c.className,
        }))}
        rowData={headerData}
        tableData={tableData}
        cellContainerClassName="grid-table__header-cell-container"
        cellStyles={maybeHeaderCellStyles}
        columnsWithGroupingBorder={columnsWithGroupingBorder}
      />
      {rowData.map((row, rowIdx) => {
        const rowColumns: Array<ColumnDataForRow<RowDataType, TableDataType>> =
          columns.map((c) => ({
            columnComponent: c.RowCellElement,
            columnClassName: c.className,
            onCellClick: c.onCellClick,
          }));

        return (
          <div
            key={`grid-table__row-${rowIdx}`}
            className={classNames('grid-table__row-container', {
              'grid-table__row--first': rowIdx === 0,
              'grid-table__row--last': rowIdx === rowData.length - 1,
              'grid-table__row--with-sub-rows': maybeSubRows !== undefined,
            })}
          >
            <Row
              columns={rowColumns}
              rowData={row}
              tableData={tableData}
              cellContainerClassName="grid-table__row-cell-container"
              columnsWithGroupingBorder={columnsWithGroupingBorder}
            />
            {fromNullable(maybeSubRows)
              .map((subRows) => (
                <SubRowsContainer
                  key="sub-rows"
                  tableData={tableData}
                  parentRowData={row}
                  subRows={subRows}
                  subRowChildRows={maybeSubRowChildRows}
                />
              ))
              .toUndefined()}
          </div>
        );
      })}
      {noDataDisplayComponent !== undefined && rowData.length === 0 && (
        <div className="grid-table__full-width-column">
          {fromNullable<JSX.Element>(noDataDisplayComponent)
            .map((noDataComponent) => noDataComponent)
            .toUndefined()}
        </div>
      )}
      {/*
        This spacer will take up any remaining space leftover in the table
        container. For example, if there is only 2 rows of data in the table
        we do not want those 2 rows to expand and fill the entire table and look wonky
        this spacer will be the grid element that will be greedy and take up the
        remaining space not occupied by the first 2 rows
      */}
      <div className="grid-table__spacer grid-table__full-width-column" />
      {hasFooter && (
        <Row
          columns={columns.map((c) => ({
            columnComponent: c.FooterCellElement,
            columnClassName: c.className,
          }))}
          rowData={footerData}
          tableData={tableData}
          cellContainerClassName="grid-table__footer-cell-container"
          columnsWithGroupingBorder={columnsWithGroupingBorder}
        />
      )}
    </div>
  );
}
GridTable.displayName = 'GridTable';

export default GridTable;
