import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';

import {
  CheckboxCheckedState,
  ComparisonTrend,
  GroupingActionBarState,
  DateRange as PreviousDateRange,
  PrimaryDropdownProps,
  SelectMenuVariant,
  SelectOptionProps,
  SelectV2OptionProps,
} from '@teikametrics/tm-design-system';

import {
  MONETARY_FRACTION_DIGITS,
  PERCENTAGE_FRACTION_DIGITS,
} from '../../../../lib/types/CommonSharedTypes';
import { FAMConnectionStatus } from '../../../../lib/types/Fam';
import { MoneyWithAmountInString } from '../../../../lib/types/Money';
import { NavPaths } from '../../../../NavPaths';
import {
  getColumnManagerOptionsSkuAdPerformance,
  getColumnManagerOptionsSkuCatalog,
  getColumnManagerOptionsSkuOverview,
} from '../skuCatalog/columnManagerConfig';
import {
  AccountSalesChannelPaginatedResultWithChannelName,
  ColumnManagerOptionWithIsSelected,
  DateRange,
  METRIC_TYPE_TO_DECIMAL_PLACE_LIMIT,
  Metric,
  MetricType,
  SKU_CATALOG_TABLE_SEARCH_BY_KEY,
  SkuAdPerformanceApiColumnValue,
  SkuCatalogApiColumnValue,
  SkuOverviewApiColumnValue,
  SkuOverviewColumnManagerOptionWithIsSelected,
} from '../skuCatalog/types';
import {
  UserInfo,
  getSelectedColumnsSkuAdPerformanceFromLocalStorage,
  getSelectedColumnsSkuCatalogFromLocalStorage,
  getSelectedColumnsSkuOverviewFromLocalStorage,
  getSkuCatalogColumnManagerConfigFromLocalStorage,
  getSkuCatalogCurrencyFromLocalStorage,
  getSkuCatalogDateRangeFromLocalStorage,
  getSkuCatalogMerchantsFromLocalStorage,
  resetLocalStorageForSku,
  storeSelectedColumnsAdPerformanceInLocalStorage,
  storeSkuCatalogColumnManagerConfigToLocalStorage,
} from './localStorage';
import {
  EventLogCategoryConfiguration,
  ExistingSkuGroups,
  SkuCatalogData,
} from '../../../../lib/types/SKUSharedTypes';
import { MissingRequiredField } from '../../../../containers/tableV2/ducks/types';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  EventsLogsFiltersData,
  SkuDetailsTab,
} from '../skuCatalog/skuCatalogDetails2/types';
import { doesUrlContainAnyEventNotificationParam } from './urlExtracts';
import { Location } from 'react-router-dom';
import { Fw2LocalStorage } from '../../../../lib/utilities/fw2LocalStorage';
import { CurrencyCode } from '../../../../lib/utilities/currency';
import { PaginatedResponse } from '../../../../lib/clients/types';
import { Filter } from '../../../../lib/types/Filter';

type StringNumberArray = string | number | (string | number)[];

export function convertAmountToNumber(metric: Metric): number {
  const amount = (metric as MoneyWithAmountInString).amount;
  return parseFloat(amount);
}

export const calculateDelta = (
  metricType: MetricType,
  currentValue: Metric | null,
  previousValue: Metric | null
): number => {
  const maxDecimalPlaces = METRIC_TYPE_TO_DECIMAL_PLACE_LIMIT[metricType];
  if (isNil(previousValue) || isNil(currentValue)) return 0;
  let currentNumericValue = -1;
  let previousNumericValue = -1;

  if (metricType !== MetricType.Money) {
    currentNumericValue = currentValue as number;
    previousNumericValue = previousValue as number;
  }

  switch (metricType) {
    case MetricType.Money:
      const previousAmount = convertAmountToNumber(previousValue);
      const divideBy = previousAmount === 0 ? 1 : Math.abs(previousAmount);

      return (convertAmountToNumber(currentValue) - previousAmount) / divideBy;
    case MetricType.Percent:
      return (
        (Number((currentNumericValue * 100).toFixed(maxDecimalPlaces)) -
          Number((previousNumericValue * 100).toFixed(maxDecimalPlaces))) /
        (Number((previousNumericValue * 100).toFixed(maxDecimalPlaces)) === 0
          ? 1
          : Number(
              (Math.abs(previousNumericValue) * 100).toFixed(maxDecimalPlaces)
            ))
      );
    case MetricType.Numeric:
    default:
      return (
        (Number(currentNumericValue.toFixed(maxDecimalPlaces)) -
          Number(previousNumericValue.toFixed(maxDecimalPlaces))) /
        (Number(previousNumericValue.toFixed(maxDecimalPlaces)) === 0
          ? 1
          : Number(Math.abs(previousNumericValue).toFixed(maxDecimalPlaces)))
      );
  }
};

export const getPillTextBasedOnDelta = (
  delta: number,
  intl: IntlShape
): string => {
  return `${delta > 0 ? '+' : ''}${intl.formatNumber(delta, {
    style: 'percent',
    minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
    maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
  })}`;
};

export const getNeutralTrendBasedOnDelta = (delta: number): ComparisonTrend => {
  if (delta > 0) {
    return ComparisonTrend.NeutralUp;
  } else if (delta < 0) {
    return ComparisonTrend.NeutralDown;
  } else {
    return ComparisonTrend.NeutralNoChange;
  }
};

export const getPositiveUpTrendBasedOnDelta = (
  delta: number
): ComparisonTrend => {
  if (delta > 0) {
    return ComparisonTrend.PositiveUp;
  } else if (delta < 0) {
    return ComparisonTrend.NegativeDown;
  } else {
    return ComparisonTrend.NeutralNoChange;
  }
};

export const getNegativeUpTrendBasedOnDelta = (
  delta: number
): ComparisonTrend => {
  if (delta > 0) {
    return ComparisonTrend.NegativeUp;
  } else if (delta < 0) {
    return ComparisonTrend.PositiveDown;
  } else {
    return ComparisonTrend.NeutralNoChange;
  }
};

export type CalculateComparisionTrendFunction = (
  delta: number
) => ComparisonTrend;

export const getPercentageTableCellComparisionProps = (
  intl: IntlShape,
  calculateComparisionTrendFunction: CalculateComparisionTrendFunction,
  current?: number,
  previous?: number
) => {
  const currentPercentValue = isNil(current) ? null : current;
  const previousPercentValue = isNil(previous) ? null : previous;

  const delta = calculateDelta(
    MetricType.Percent,
    currentPercentValue,
    previousPercentValue
  );
  const pillText = getPillTextBasedOnDelta(delta, intl);
  const comparisonTrend = calculateComparisionTrendFunction(delta);

  const currentValue = isNil(currentPercentValue)
    ? null
    : intl.formatNumber(currentPercentValue, {
        style: 'percent',
        minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      });

  const previousValue = isNil(previousPercentValue)
    ? null
    : intl.formatNumber(previousPercentValue, {
        style: 'percent',
        minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      });

  return { pillText, comparisonTrend, currentValue, previousValue };
};

export const getNumericTableCellComparisionProps = (
  intl: IntlShape,
  calculateComparisionTrendFunction: CalculateComparisionTrendFunction,
  current?: number,
  previous?: number,
  allowedDigits: number = PERCENTAGE_FRACTION_DIGITS
) => {
  const currentPercentValue = isNil(current) ? null : current;
  const previousPercentValue = isNil(previous) ? null : previous;

  const delta = calculateDelta(
    MetricType.Numeric,
    currentPercentValue,
    previousPercentValue
  );
  const pillText = getPillTextBasedOnDelta(delta, intl);
  const comparisonTrend = calculateComparisionTrendFunction(delta);

  const currentValue = isNil(currentPercentValue)
    ? null
    : intl.formatNumber(currentPercentValue, {
        minimumFractionDigits: allowedDigits,
        maximumFractionDigits: allowedDigits,
      });

  const previousValue = isNil(previousPercentValue)
    ? null
    : intl.formatNumber(previousPercentValue, {
        minimumFractionDigits: allowedDigits,
        maximumFractionDigits: allowedDigits,
      });

  return { pillText, comparisonTrend, currentValue, previousValue };
};

export const getMoneyTableCellComparisionProps = (
  intl: IntlShape,
  calculateComparisionTrendFunction: CalculateComparisionTrendFunction,
  current?: MoneyWithAmountInString,
  previous?: MoneyWithAmountInString
) => {
  const currentMoneyValue = isNil(current) ? null : current;
  const previousMoneyValue = isNil(previous) ? null : previous;

  const delta = calculateDelta(
    MetricType.Money,
    currentMoneyValue,
    previousMoneyValue
  );
  const pillText = getPillTextBasedOnDelta(delta, intl);
  const comparisonTrend = calculateComparisionTrendFunction(delta);

  const currentValue = isNil(currentMoneyValue)
    ? null
    : intl.formatNumber(parseFloat(currentMoneyValue.amount), {
        style: 'currency',
        currency: currentMoneyValue.currency,
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      });

  const previousValue = isNil(previousMoneyValue)
    ? null
    : intl.formatNumber(parseFloat(previousMoneyValue.amount), {
        style: 'currency',
        currency: previousMoneyValue.currency,
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      });

  return { pillText, comparisonTrend, currentValue, previousValue };
};

/* 
  On initial load, default columns will come from the config-old.
  On subsequent load, default columns will come from local storage.
  If the column manager config-old is changed (removal of column / addition of column / change in label name etc), then default columns will come from the config-old.
*/
export const getSkuCatalogDefaultSelectedOptions = (
  userId: string,
  accountId: string,
  intl: IntlShape,
  showGmroiColumn?: boolean,
  show1P?: boolean
): SkuCatalogApiColumnValue[] => {
  const options = getColumnManagerOptionsSkuCatalog(
    intl,
    showGmroiColumn,
    show1P
  );
  const optionsFromLocalStorage =
    getSkuCatalogColumnManagerConfigFromLocalStorage({
      userId,
      accountId,
      show1P,
    });

  const defaultSelectedOptions = options
    .filter(({ isSelected }) => isSelected)
    .map(({ value }) => value);

  if (isEqual(options, optionsFromLocalStorage)) {
    const selectedColumnsFromLocalStorage =
      getSelectedColumnsSkuCatalogFromLocalStorage({
        userId,
        accountId,
        show1P,
      });
    return selectedColumnsFromLocalStorage.length > 0
      ? selectedColumnsFromLocalStorage
      : defaultSelectedOptions;
  } else {
    storeSkuCatalogColumnManagerConfigToLocalStorage({
      userId,
      accountId,
      values: options,
      show1P,
    });
    return defaultSelectedOptions;
  }
};

export const getSkuOverviewDefaultSelectedOptions = (
  userId: string,
  accountId: string,
  intl: IntlShape,
  show1PColumns?: boolean
): SkuOverviewApiColumnValue[] => {
  const options = getColumnManagerOptionsSkuOverview(intl, show1PColumns);
  const optionsFromLocalStorage =
    getSkuCatalogColumnManagerConfigFromLocalStorage({
      userId,
      accountId,
    });
  const defaultSelectedOptions = options
    .filter(({ isSelected }) => isSelected)
    .map(({ value }) => value);

  if (isEqual(options, optionsFromLocalStorage)) {
    const selectedColumnsFromLocalStorage =
      getSelectedColumnsSkuOverviewFromLocalStorage({ userId, accountId });
    return selectedColumnsFromLocalStorage.length > 0
      ? selectedColumnsFromLocalStorage
      : defaultSelectedOptions;
  } else {
    storeSkuCatalogColumnManagerConfigToLocalStorage({
      userId,
      accountId,
      values: options,
      show1P: show1PColumns,
    });
    return defaultSelectedOptions;
  }
};

export const getPreviousPeriodDateRange = (
  startDate: DateTime,
  endDate: DateTime
): PreviousDateRange => {
  const previousPeriodEndDate: DateTime = startDate.minus({ days: 1 });
  const rangeBetweenDates = endDate.diff(startDate, 'days');
  const previousPeriodStartDate = previousPeriodEndDate.minus({
    days: rangeBetweenDates.days,
  });

  return {
    from: previousPeriodStartDate as DateTime,
    to: previousPeriodEndDate as DateTime,
  };
};

const getLocalStorageKeyForPreviousPeriodState = (
  userId: string,
  accountId: string,
  page: string
) => `${userId}_${accountId}_${page}_previous_period_toggle_state`;

export const getPreviousPeriodStateFromLocalStorage = (
  userId: string,
  accountId: string,
  page: string
): boolean => {
  const prevPeriodStateStorageKey = getLocalStorageKeyForPreviousPeriodState(
    userId,
    accountId,
    page
  );
  const prevPeriodStateFromLocalStorage = Fw2LocalStorage.getItem(
    prevPeriodStateStorageKey
  );
  try {
    return (
      JSON.parse(prevPeriodStateFromLocalStorage as string).toString() ===
      'true'
    );
  } catch (e) {
    return false;
  }
};

export const storePreviousPeriodStateToLocalStorage = (
  userId: string,
  accountId: string,
  state: boolean,
  page: string
) => {
  const prevPeriodStateStorageKey = getLocalStorageKeyForPreviousPeriodState(
    userId,
    accountId,
    page
  );
  Fw2LocalStorage.setItem(prevPeriodStateStorageKey, state.toString());
};

export const checkIfSalesChannelInSync = (
  salesChannelsTableData: AccountSalesChannelPaginatedResultWithChannelName
): boolean => {
  const syncingSalesChannels = salesChannelsTableData.items.filter(
    (salesChannel) =>
      salesChannel.productConnectionStatus === FAMConnectionStatus.Syncing ||
      salesChannel.productConnectionStatus === FAMConnectionStatus.Processing ||
      salesChannel.productConnectionStatus ===
        FAMConnectionStatus.PendingWalmart
  );

  // If atleast one sales channel is syncing then return "Connection In Progress"
  return syncingSalesChannels.length > 0;
};

export const checkIfSalesChannelNotConnected = (
  salesChannelsTableData: AccountSalesChannelPaginatedResultWithChannelName
): boolean => {
  // If productStatus is null/ Failed / Inactive return 'No connection'
  const notConnectedSalesChannels = salesChannelsTableData.items.every(
    (salesChannel) =>
      salesChannel.productConnectionStatus === FAMConnectionStatus.Failed ||
      salesChannel.productConnectionStatus === FAMConnectionStatus.Inactive
  );

  // If there are not a single connections available then return "No Connection" banner OR
  return (
    (salesChannelsTableData?.totalItems === 0 &&
      !salesChannelsTableData.isError) ||
    notConnectedSalesChannels
  );
};

export const getPreviousCurrencySelection = (
  currency: CurrencyCode,
  userInfo: UserInfo
): CurrencyCode => {
  const selectedCurrencyFromLocalStorage =
    getSkuCatalogCurrencyFromLocalStorage(userInfo);

  return selectedCurrencyFromLocalStorage ?? currency;
};

export const getPreviousMerchantSelection = (
  merchantCountryIds: string[],
  userInfo: UserInfo,
  location: Location
): string[] => {
  const selectedMerchantsIdFromLocalStorage =
    getSkuCatalogMerchantsFromLocalStorage(userInfo);

  if (
    selectedMerchantsIdFromLocalStorage &&
    !doesUrlContainAnyEventNotificationParam(location)
  ) {
    return selectedMerchantsIdFromLocalStorage.filter((merchantCountryId) =>
      merchantCountryIds.includes(merchantCountryId)
    );
  }

  if (doesUrlContainAnyEventNotificationParam(location)) {
    return merchantCountryIds;
  }
  // Default selection should only be 1 merchantCountry for better performance.
  return merchantCountryIds.slice(0, 1);
};

export const getPreviousDateSelection = (
  dateRange: DateRange,
  userInfo: UserInfo,
  location: Location
): DateRange => {
  const selectedDateRangeFromLocalStorage =
    getSkuCatalogDateRangeFromLocalStorage(userInfo);

  if (
    !isNil(selectedDateRangeFromLocalStorage) &&
    !doesUrlContainAnyEventNotificationParam(location)
  ) {
    return {
      ...selectedDateRangeFromLocalStorage,
    };
  }

  return dateRange;
};

export const getPreviousSkuAdPerformanceSelectedColumns = (
  intl: IntlShape,
  userInfo: UserInfo
): SkuAdPerformanceApiColumnValue[] => {
  const selectedColumnsFromLocalStorage =
    getSelectedColumnsSkuAdPerformanceFromLocalStorage(userInfo);

  const options = getColumnManagerOptionsSkuAdPerformance(intl);
  const defaultSelectedColumns = options
    .filter(({ isSelected }) => isSelected)
    .map(({ value }) => value);

  if (isNil(selectedColumnsFromLocalStorage)) {
    storeSelectedColumnsAdPerformanceInLocalStorage(
      userInfo,
      defaultSelectedColumns
    );

    return defaultSelectedColumns;
  } else {
    return selectedColumnsFromLocalStorage.filter((column) =>
      options.map(({ value }) => value).includes(column)
    );
  }
};

/* 
  If Product catalog or Product Overview column manager has changed, 
  or selected columns are corrupted in local storage, 
  then reset local storage for sku: filters, selected columns, etc. 
*/
export const maybeResetLocalStorageForSku = (
  userId: string,
  accountId: string,
  intl: IntlShape,
  showGmroiColumn?: boolean,
  show1PColumns?: boolean
) => {
  const skuCatalogOriginalOptions = getColumnManagerOptionsSkuCatalog(
    intl,
    showGmroiColumn,
    show1PColumns
  );
  const skuOverviewOriginalOptions = getColumnManagerOptionsSkuOverview(
    intl,
    show1PColumns
  );
  const skuCatalogOrOverviewOptionsFromLocalStorage =
    getSkuCatalogColumnManagerConfigFromLocalStorage({
      userId,
      accountId,
    });

  if (
    areColumnManagerOptionsCorruptedInLocalStorage(
      skuCatalogOriginalOptions,
      skuCatalogOrOverviewOptionsFromLocalStorage
    ) ||
    areSkuCatalogSelectedValuesCorruptedInLocalStorage(
      userId,
      accountId,
      skuCatalogOriginalOptions,
      show1PColumns
    ) ||
    areSkuOverviewSelectedValuesCorruptedInLocalStorage(
      userId,
      accountId,
      skuOverviewOriginalOptions
    )
  ) {
    resetLocalStorageForSku(userId, accountId);
  }
};

export const areColumnManagerOptionsCorruptedInLocalStorage = (
  skuCatalogOriginalOptions: ColumnManagerOptionWithIsSelected[],
  skuCatalogOrOverviewOptionsFromLocalStorage: ColumnManagerOptionWithIsSelected[]
) =>
  !isEqual(
    skuCatalogOriginalOptions,
    skuCatalogOrOverviewOptionsFromLocalStorage
  );

export const areSkuCatalogSelectedValuesCorruptedInLocalStorage = (
  userId: string,
  accountId: string,
  skuCatalogOriginalOptions: ColumnManagerOptionWithIsSelected[],
  show1P?: boolean
) => {
  const skuCatalogSelectedColumnsFromLocalStorage =
    getSelectedColumnsSkuCatalogFromLocalStorage({ userId, accountId, show1P });

  const skuCatalogAllowedValuesForSelectedColumns =
    skuCatalogOriginalOptions.map(({ value }) => value);

  for (const skuCatalogValue of skuCatalogSelectedColumnsFromLocalStorage) {
    if (!skuCatalogAllowedValuesForSelectedColumns.includes(skuCatalogValue)) {
      return true;
    }
  }

  return false;
};

export const areSkuOverviewSelectedValuesCorruptedInLocalStorage = (
  userId: string,
  accountId: string,
  skuOverviewOriginalOptions: SkuOverviewColumnManagerOptionWithIsSelected[]
) => {
  const skuOverviewSelectedColumnsFromLocalStorage =
    getSelectedColumnsSkuOverviewFromLocalStorage({ userId, accountId });

  const skuOverviewAllowedValuesForSelectedColumns =
    skuOverviewOriginalOptions.map(({ value }) => value);

  for (const skuOverviewValue of skuOverviewSelectedColumnsFromLocalStorage) {
    if (
      !skuOverviewAllowedValuesForSelectedColumns.includes(skuOverviewValue)
    ) {
      return true;
    }
  }

  return false;
};

export const getProductCatalogNavPath = (
  has1PMerchants?: boolean,
  has3PMerchants?: boolean
) => {
  if (has1PMerchants && !has3PMerchants) {
    return NavPaths.IOSkuVendor;
  } else {
    return NavPaths.IOSkuSeller;
  }
};

export const getValueOrEmptyString = (value?: string) => value ?? '';

export const getSecondLevelChildrenRowIds = (
  skuCatalogData: SkuCatalogData[]
) =>
  skuCatalogData.flatMap(
    (skuCatalog) =>
      skuCatalog.subRows?.flatMap((subRow) => subRow.rowId ?? '') ?? ''
  );

export const getDefaultMissingFields = (selectedMerchantsId: string[]) =>
  selectedMerchantsId && selectedMerchantsId.length === 0
    ? [MissingRequiredField.MerchantCountries]
    : [];

export const getProductCatalogIds = (
  visibleData: SkuCatalogData[],
  id: string
) =>
  visibleData
    .filter(
      ({ productCatalogId, merchantCountryId, skuChildren }) =>
        productCatalogId &&
        merchantCountryId === id &&
        (!skuChildren || skuChildren.elements.length === 0)
    )
    .map(({ productCatalogId }) => productCatalogId ?? '');

export const getFiltersAppliedExcludingSearch = (filtersFromStore: Filter[]) =>
  filtersFromStore.filter(
    (filter) => filter.field !== SKU_CATALOG_TABLE_SEARCH_BY_KEY
  ).length > 0;

export const getProductCatalogIdsForGmroi = (
  skuChildren?: PaginatedResponse<SkuCatalogData>
) =>
  skuChildren?.elements
    ?.map(({ productCatalogId }) => productCatalogId ?? '')
    .filter((id) => id) ?? [];

export const getSliderGroupsOptions = (intl: IntlShape) => [
  {
    text: intl.formatMessage({
      id: I18nKey.REACT_SKU_DETAILS_SLIDERGROUP_PARENT,
    }),
  },
  {
    text: intl.formatMessage({
      id: I18nKey.REACT_SKU_DETAILS_SLIDERGROUP_SKUS,
    }),
  },
];

export const showContextSetter = (currentTab?: SkuDetailsTab) =>
  currentTab !== SkuDetailsTab.EventLogs &&
  currentTab !== SkuDetailsTab.ProductSettings;

export const getPrimaryDropdownProps = (
  intl: IntlShape,
  currencyPickerOptions: SelectOptionProps<string>[],
  selectedCurrency: StringNumberArray,
  onCurrencyChange: (currency: StringNumberArray) => void,
  merchantPickerOptions: SelectOptionProps<string | number>[],
  selectedMerchantIds: StringNumberArray,
  onMerchantChange: (merchantCountryId: StringNumberArray) => void,
  isSkuDetailsV2Page?: boolean
): PrimaryDropdownProps =>
  isSkuDetailsV2Page
    ? {
        primaryDropdownLabel: intl.formatMessage({
          id: I18nKey.CONTEXT_SETTER_SALES_CURRENCY_PICKER_CHANNEL,
        }),
        primaryDropdownOptions: currencyPickerOptions as SelectV2OptionProps<
          string | number
        >[],
        primaryDropdownValue: selectedCurrency,
        onSelectPrimaryDropdown: onCurrencyChange,
        primaryDropdownShowSearch: true,
        primaryDropDownAddValueToDataTestId: true,
        primaryDropdownPlaceholder: '',
        primaryDropdownVariant: SelectMenuVariant.TextWithIcon,
        primaryDropdownClassnames: 'pl-12',
      }
    : {
        primaryDropdownLabel: intl.formatMessage({
          id: I18nKey.CONTEXT_SETTER_SALES_CHANNEL_AND_PICKER_MERCHANT,
        }),
        primaryDropdownPlaceholder: '',
        primaryDropdownOptions: merchantPickerOptions as SelectV2OptionProps<
          string | number
        >[],
        primaryDropdownValue: selectedMerchantIds,
        primaryDropdownShowSearch: true,
        onSelectPrimaryDropdown: onMerchantChange,
      };

export const getSecondaryDropdownProps = (
  intl: IntlShape,
  currencyPickerOptions: SelectOptionProps<string>[],
  selectedCurrency: string | number,
  onCurrencyChange: (currency: StringNumberArray) => void,
  isSkuDetailsV2Page?: boolean
) =>
  isSkuDetailsV2Page
    ? undefined
    : {
        secondaryDropdownLabel: intl.formatMessage({
          id: I18nKey.CONTEXT_SETTER_SALES_CURRENCY_PICKER_CHANNEL,
        }),
        secondaryDropdownOptions: currencyPickerOptions as SelectV2OptionProps<
          string | number
        >[],
        secondaryDropdownValue: selectedCurrency,
        onSelectSecondaryDropdown: onCurrencyChange,
        secondaryDropdownShowSearch: true,
        secondaryDropDownAddValueToDataTestId: true,
        secondaryDropdownPlaceholder: '',
      };

export const getSkuGroupsDropdownOptions = (
  productGroups: ExistingSkuGroups[]
) =>
  productGroups.map((group: any) => ({
    id: group.teikaGroupId,
    name: group.teikaGroupName,
  }));

export const getActionBarState = (selectedProductGroupIds: string[]) =>
  selectedProductGroupIds.length > 0
    ? GroupingActionBarState.DeleteGroup
    : GroupingActionBarState.SelectAction;

export const getActionBarLabel = (selectedProductGroupIds: string[]) =>
  selectedProductGroupIds.length > 0
    ? I18nKey.SKU_CATALOG_SKU_GROUPING_ACTION_BAR_GROUPS_LABEL
    : I18nKey.SKU_CATALOG_SKU_GROUPING_ACTION_BAR_SKUS_LABEL;

export const getActionBarCount = (
  selectedProductGroupIds: string[],
  selectedProductCatalogIds: string[],
  nonRemovableParentIds: string[]
) =>
  selectedProductGroupIds.length > 0
    ? selectedProductGroupIds.length
    : selectedProductCatalogIds.length + nonRemovableParentIds.length;

export const isSingleParentRow = (
  rowIds: string[],
  original?: Map<string, SkuCatalogData>
) => original && original.get(rowIds[0])?.isSingleParent;

export const isCustomGroupChecked = (checked: boolean, depth?: number) =>
  depth === 0 && checked;

export const isCustomGroupUnChecked = (checked: boolean, depth?: number) =>
  depth === 0 && !checked;

export const isParentChecked = (checked: boolean, depth?: number) =>
  depth === 1 && checked;

export const isParentUnChecked = (checked: boolean, depth?: number) =>
  depth === 1 && !checked;

export const getEventLogFilterInitialData = (
  data: EventLogCategoryConfiguration[]
): EventsLogsFiltersData => {
  const eventByCategory = data.reduce(
    (result, obj) => {
      const { name, viewName, eventTypeConfiguration } = obj;
      if (!result[name]) {
        result[name] = {
          eventTypes: [],
          viewName,
        };
      }

      eventTypeConfiguration.forEach(
        ({ name: eventType, viewName: eventTypeViewName }) => {
          result[name].eventTypes.push({
            eventType,
            viewName: eventTypeViewName,
          });
        }
      );
      return result;
    },
    {} as {
      [key: string]: {
        eventTypes: { eventType: string; viewName: string }[];
        viewName: string;
      };
    }
  );

  return Object.keys(eventByCategory).reduce((res, curr) => {
    const eventTypes = eventByCategory[curr].eventTypes;
    res[curr] = {
      eventTypes: eventTypes.map((e) => ({
        [e.eventType]: { ...e, isChecked: true },
      })),
      expanded: true,
      selected: CheckboxCheckedState.Checked,
      selectedCount: eventTypes.length,
      viewName: eventByCategory[curr].viewName,
    };

    return res;
  }, {} as EventsLogsFiltersData);
};

export const getFiltersHeaderCount = (data: EventsLogsFiltersData) => {
  const categories = Object.keys(data);
  let selected = 0;
  let total = 0;

  categories.forEach((c) => {
    const eventTypes = data[c].eventTypes;
    eventTypes.forEach((e) => {
      const key = Object.keys(e)[0];
      if (e[key].isChecked) {
        selected += 1;
      }
      total += 1;
    });
  });

  return {
    selected,
    total,
  };
};
