import {
  AlertTriangleIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CrossIcon,
  Icon,
  IconSize,
  Modal,
  NumericInput,
  NumericInputSize,
  NumericInputState,
  TextLink,
  TextLinkSize,
  getCurrencySymbol,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useAxios } from '../../containers/axiosProvider';
import { tableActions, tableSelectors } from '../../containers/tableV2/ducks';
import { tableActions as tableActionsV1 } from '../../containers/table/ducks';
import {
  TableCellChange,
  TableChange,
  WithTable,
} from '../../containers/tableV2/ducks/types';
import { TableCellChange as TableCellChangeV1 } from '../../containers/table/ducks/types';
import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { createCampaignFlexibilityApiClient } from '../../lib/clients/CampaignFlexibilityClient';
import { createFlowV2ApiClient } from '../../lib/clients/FlowV2ApiClient';
import { PaginatedRequest, PaginatedResult } from '../../lib/clients/types';
import { MerchantCountryCode } from '../../lib/types/AOSharedTypes';
import {
  EstPreAdGrossMarginItem,
  EstPreAdGrossMarginRequest,
  EstPreAdGrossMarginTableFields,
} from '../../lib/types/CampaignFlexibilitySharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../lib/types/CommonSharedTypes';
import I18nKey from '../../lib/types/I18nKey';
import {
  CurrencyCode,
  getCurrencyCodeFromMerchantCountryCode,
} from '../../lib/utilities/currency';
import { createAOApiClient } from '../../lib/clients/AOApiClient';
import { MerchantCountry } from '../../lib/types/Fam';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../lib/utilities/intercom';
import { EstAdGrossMarginTable } from './EstAdGrossMarginTable';
import {
  DEFAULT_BULK_INPUT_VALUE,
  DEFAULT_CURRENCY_EST_PRE_AD_MARGIN,
  MAP_MERCHANT_TYPE_TO_VALUE,
} from './EstAdGrossMarginTypes';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';

interface commonProps {
  readonly tableId: string;
  readonly closeModal: () => void;
  readonly selectedMerchant: MerchantCountry;
  readonly fromAdsManager?: boolean;
  readonly onSaveSuccess?: () => void;
  readonly additionalCallAfterSave?: (
    isBulkUpload: boolean,
    totalEstPreAdGrossMargin?: number,
    bulkInputValue?: string
  ) => void;
}

// This to handle how to access the data for the table.
// Don't add any new params here unless it's required for the data to be loaded in the table.
type optionalProps =
  | {
      readonly productCatalogIds: string[];
      readonly dataFetcher?: never;
    }
  | {
      readonly productCatalogIds?: never;
      readonly dataFetcher: (
        paginatedRequest: PaginatedRequest
      ) => Promise<PaginatedResult<EstPreAdGrossMarginItem>>;
    };

export type EstAdGrossMarginSlideoverProps = commonProps & optionalProps;

const EstAdGrossMarginSlideover: React.FC<EstAdGrossMarginSlideoverProps> = ({
  tableId,
  productCatalogIds,
  closeModal,
  dataFetcher,
  selectedMerchant,
  fromAdsManager,
  onSaveSuccess,
  additionalCallAfterSave,
}) => {
  const intl = useIntl();
  const axios = useAxios();
  const dispatch = useDispatch();
  const userContext = useContext<UserContextState>(UserContext);
  const idToken = userContext.userInfo.idToken!;
  const campaignFlexibilityClient = createCampaignFlexibilityApiClient(idToken);
  const flowV2ApiClient = createFlowV2ApiClient(idToken, axios);
  const aoApiClient = createAOApiClient(idToken);
  const accountId = getCurrentAccountFromContext(userContext)!.id;

  const [isSubmitInProgress, setIsSubmitInProgress] = useState<boolean>(false);
  const [showBulkUpdate, setShowBulkUpdate] = useState<boolean>(false);
  const [bulkInputValue, setBulkInputValue] = useState<string>();

  const merchantCountryId = selectedMerchant?.merchantCountryId;

  const [primaryButtonState, setPrimaryButtonState] = useState<ButtonState>(
    ButtonState.Disabled
  );

  const selectedCurrency =
    (getCurrencyCodeFromMerchantCountryCode(
      selectedMerchant?.country as MerchantCountryCode
    ) as CurrencyCode) || CurrencyCode.USD;

  const clearAllChanges = () => {
    dispatch(
      tableActions.clearAllChanges({
        tableId: tableId,
      })
    );
  };

  useEffect(() => {
    return () => clearAllChanges();
  }, []);

  const visibleData = useSelector<WithTable<any>, EstPreAdGrossMarginItem[]>(
    ({ tableState }: { tableState: any }) =>
      tableSelectors.getVisibleData(
        tableSelectors.getTableSelector<EstPreAdGrossMarginItem>()(
          tableState,
          tableId
        )
      )
  );

  const tableChange = useSelector<
    WithTable<EstPreAdGrossMarginItem>,
    TableChange
  >(({ tableState }) =>
    tableSelectors.getChangeSelector()(tableState, tableId)
  );

  useEffect(() => {
    const updatedCells = tableChange.cell;
    const updatedCellsKeys = Object.keys(updatedCells);
    const filteredKeys = updatedCellsKeys.filter((key) => {
      /**
       * From ads manager means we are using tableV1 reducer.
       * Hence don't need to use `.value`
       */
      const value = fromAdsManager
        ? updatedCells[key]?.[EstPreAdGrossMarginTableFields.unitCogs]
        : updatedCells[key]?.[EstPreAdGrossMarginTableFields.unitCogs]?.value;
      const currValue = visibleData.find((data) => {
        return data.sku === key;
      })?.unitCogs;
      return value && Number(value) > 0 && Number(currValue) !== Number(value);
    });

    if (filteredKeys.length > 0) {
      setPrimaryButtonState(ButtonState.Enabled);
    } else {
      setPrimaryButtonState(ButtonState.Disabled);
    }
  }, [tableChange]);

  const saveCogsChanges = async () => {
    try {
      setIsSubmitInProgress(true);
      const updatedCells = tableChange.cell;
      const updatedCellsData = Object.keys(updatedCells);

      if (fromAdsManager) {
        const filteredCellData = updatedCellsData.filter(
          (item) =>
            updatedCells[item]?.[EstPreAdGrossMarginTableFields.unitCogs]
        );
        const updates = filteredCellData.map((item) => {
          const currItem = visibleData.find((data) => {
            return data.sku === item;
          });
          return {
            unitCogs: Number(
              updatedCells[item]?.[EstPreAdGrossMarginTableFields.unitCogs]
            ),
            productCatalogId: currItem?.productCatalogId!,
            productId: currItem?.productId!,
            sku: item,
          };
        });

        const payload = {
          merchantCountryId: merchantCountryId!,
          updates: updates,
        };
        await aoApiClient?.saveCogsDetailsForAdLevel(accountId, payload);
        onSaveSuccess && onSaveSuccess();
      } else {
        const filteredCellData = updatedCellsData.filter(
          (item) =>
            updatedCells[item]?.[EstPreAdGrossMarginTableFields.unitCogs]?.value
        );
        const updates = filteredCellData.map((item) => {
          const currItem = visibleData.find((data) => {
            return data.sku === item;
          });
          return {
            cogs: {
              amount: Number(
                updatedCells[item]?.[EstPreAdGrossMarginTableFields.unitCogs]
                  ?.value
              ),
              currency: selectedCurrency,
            },
            productCatalogId: currItem?.productCatalogId!,
            productId: currItem?.productId!,
            sku: item,
          };
        });

        const payload = {
          merchantCountryId: merchantCountryId!,
          updates: updates,
        };
        await flowV2ApiClient?.updateCogs(accountId, payload);
        await makeAdditionalCoSaveApiCall(false);
      }
      closeModal();
      setIsSubmitInProgress(false);
    } catch {
      setIsSubmitInProgress(false);
    }
  };

  const makeAdditionalCoSaveApiCall = async (isBulkUpload: boolean) => {
    if (!additionalCallAfterSave) return;

    return additionalCallAfterSave(
      isBulkUpload,
      totalEstPreAdGrossMargin,
      bulkInputValue
    );
  };

  const applyBulkUpload = () => {
    if (fromAdsManager) {
      /**
       * Needs to add and update using the tableV1. So using the same ChangeV1 and also tableActions from V1
       */
      const bulkEditChanges: TableCellChangeV1 = cloneDeep(
        tableChange.cell
      ) as any;

      visibleData.forEach((item) => {
        if (isNil(bulkEditChanges[item.sku])) {
          bulkEditChanges[item.sku] = {};
        }

        bulkEditChanges[item.sku][EstPreAdGrossMarginTableFields.unitCogs] =
          bulkInputValue || '';
      });
      dispatch(
        tableActionsV1.applyBulkChanges({
          tableId: tableId,
          bulkChanges: { ...tableChange.cell, ...bulkEditChanges },
          columnDataMapping: {},
          uniqKey: 'sku',
        })
      );
    } else {
      const bulkEditChanges: TableCellChange = cloneDeep(tableChange.cell);

      visibleData.forEach((item) => {
        if (isNil(bulkEditChanges[item.sku])) {
          bulkEditChanges[item.sku] = {};
        }

        bulkEditChanges[item.sku][EstPreAdGrossMarginTableFields.unitCogs] = {
          value: bulkInputValue || '',
          isValid: true,
          isDirty: true,
        };
      });

      dispatch(
        tableActions.applyBulkChanges({
          tableId: tableId,
          bulkChanges: { ...tableChange.cell, ...bulkEditChanges },
        })
      );
    }

    setShowBulkUpdate(false);
    setBulkInputValue(undefined);
  };

  const defaultDataFetcher = (paginatedRequest: PaginatedRequest) => {
    const request = {
      productCatalogIds: productCatalogIds,
      merchantCountryId: merchantCountryId,
      salesChannelId: selectedMerchant?.salesChannelId,
      merchantType: MAP_MERCHANT_TYPE_TO_VALUE[selectedMerchant?.merchantType!],
    } as EstPreAdGrossMarginRequest;

    return campaignFlexibilityClient.getEstPreAdGrossMarginProducts(
      accountId,
      request
    )(paginatedRequest);
  };

  const bulkInputState = bulkInputValue
    ? ButtonState.Enabled
    : ButtonState.Disabled;

  useEffect(() => {
    hideIntercomLauncher();
    return () => showIntercomLauncher();
  }, []);

  const totalEstPreAdGrossMargin = useMemo(() => {
    let showAggregateCogs = true;
    let marginSum = 0;
    let shippedCogsSum = 0;

    const updatedCells = tableChange.cell;
    const updatedCellsData = Object.keys(updatedCells);

    const updates = updatedCellsData.filter((item) =>
      fromAdsManager
        ? updatedCells[item]?.[EstPreAdGrossMarginTableFields.unitCogs]
        : updatedCells[item]?.[EstPreAdGrossMarginTableFields.unitCogs]?.value
    );

    visibleData.forEach((item) => {
      const { sku, avgSellingPrice, platformFees, unitCogs, dailyRateOfSales } =
        item;
      const updatedCellCogs = updates.includes(sku)
        ? Number(
            fromAdsManager
              ? updatedCells[sku]?.[EstPreAdGrossMarginTableFields.unitCogs]
              : updatedCells[sku]?.[EstPreAdGrossMarginTableFields.unitCogs]
                  ?.value
          )
        : undefined;

      const displayCogs = Number(
        (showBulkUpdate ? bulkInputValue : updatedCellCogs) || unitCogs
      );
      if (!avgSellingPrice || !platformFees || !displayCogs) {
        showAggregateCogs = false;
        return;
      }
      marginSum +=
        (avgSellingPrice - platformFees - displayCogs) *
        (dailyRateOfSales || 1);
      shippedCogsSum += avgSellingPrice * (dailyRateOfSales || 1);
    });
    return showAggregateCogs && shippedCogsSum > 0
      ? marginSum / shippedCogsSum
      : undefined;
  }, [visibleData, tableChange, showBulkUpdate, bulkInputValue]);

  const usedDataFetcher = dataFetcher ? dataFetcher : defaultDataFetcher;

  return (
    <Modal
      className="min-w-1024 max-w-1024"
      showModal
      overlayZindexClassName={'z-10'}
      slideOutModal={true}
      showOVerlay={true}
      formActionRowClassName="sticky border-t-1 border-grey-200 border-solid py-12 absolute bottom-0 px-12 bg-white"
      hidePadding={true}
      dataTestId="EstAdGrossMarginSlideOver_Modal"
    >
      <div
        className={classNames(
          'flex flex-row gap-8',
          'border-solid border-b border-grey-200',
          'px-16 py-8'
        )}
      >
        <div className="hover:cursor-pointer" onClick={closeModal}>
          <Button
            svgIcon={CrossIcon}
            size={ButtonSize.Small}
            variant={ButtonVariant.BlackAndWhite}
            className="text-grey-600"
          />
        </div>
        <div className="flex justify-center items-center w-full text-base leading-none font-medium">
          {intl.formatMessage({
            id: I18nKey.CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_SLIDEOVER_HEADING,
          })}
        </div>
        <div className="w-28 min-w-28" />
      </div>
      <div className="flex flex-col gap-16 p-24 justify-center">
        <div
          className={classNames(
            'flex gap-8 px-16 py-12',
            'border border-solid border-purple-200',
            'bg-purple-50 items-center justify-center'
          )}
        >
          <span className="text-base leading-tight font-normal mr-8">
            {intl.formatMessage({
              id: I18nKey.CAMPAIGN_CREATOR_SLIDEOVER_BANNER_TEXT_1,
            })}
          </span>
          {!!totalEstPreAdGrossMargin ? (
            <div className="text-xl leading-tight font-semibold">
              <FormattedNumber
                value={totalEstPreAdGrossMargin}
                style="percent"
                minimumFractionDigits={PERCENTAGE_FRACTION_DIGITS}
                maximumFractionDigits={PERCENTAGE_FRACTION_DIGITS}
              />
            </div>
          ) : (
            <>
              <span
                className={classNames(
                  'flex items-center justify-center h-20 w-20',
                  'rounded-full bg-yellow-100',
                  'border border-solid border-yellow-200'
                )}
              >
                <Icon
                  svg={AlertTriangleIcon}
                  className="text-yellow-700"
                  size={IconSize.Small}
                />
              </span>
              <span className="text-base leading-tight font-medium">
                {intl.formatMessage({
                  id: I18nKey.CAMPAIGN_CREATOR_SLIDEOVER_BANNER_TEXT_2,
                })}
              </span>
            </>
          )}
        </div>
        <div
          className={classNames('flex', {
            'justify-end': !showBulkUpdate,
            'justify-center': showBulkUpdate,
          })}
        >
          {showBulkUpdate ? (
            <div className="flex w-full justify-center">
              <div className="flex w-full gap-16 items-center justify-center">
                <div className="text-base leading-tight font-regular-tabular-nums">
                  {intl.formatMessage({
                    id: I18nKey.CAMPAIGN_CREATOR_SLIDEOVER_BANNER_TEXT_3,
                  })}
                </div>
                <div className="flex gap-8">
                  <div>
                    <NumericInput
                      placeholder={DEFAULT_BULK_INPUT_VALUE}
                      value={bulkInputValue}
                      onChange={setBulkInputValue}
                      className="w-116"
                      numericInputSize={NumericInputSize.Medium}
                      state={NumericInputState.Default}
                      acceptOnlyPositiveNumbers
                      minFractionDigits={2}
                      prependedElement={getCurrencySymbol(
                        DEFAULT_CURRENCY_EST_PRE_AD_MARGIN
                      )}
                      min={1}
                      dataTestId="estAdGrossMarginSlideover_bulkInput"
                    />
                  </div>
                  <div>
                    <Button
                      size={ButtonSize.Default}
                      variant={ButtonVariant.Primary}
                      state={bulkInputState}
                      label={intl.formatMessage({
                        id: I18nKey.GENERIC_APPLY,
                      })}
                      onClick={applyBulkUpload}
                      dataTestId={`bulk_submit`}
                    />
                  </div>
                </div>
              </div>
              <div
                className="flex items-center justify-center"
                onClick={() => {
                  setBulkInputValue('');
                  setShowBulkUpdate(false);
                }}
              >
                <Icon
                  svg={CrossIcon}
                  className="text-grey-500"
                  size={IconSize.Small}
                />
              </div>
            </div>
          ) : (
            <TextLink
              textLabel={intl.formatMessage({
                id: I18nKey.CAMPAIGN_CREATOR_BULK_EDIT_LINK_TEXT,
              })}
              size={TextLinkSize.Default}
              className="text-base leading-normal font-normal"
              onClick={() => setShowBulkUpdate(true)}
              dataTestId="bulkUpdateAllCogs"
            />
          )}
        </div>
        <div>
          <EstAdGrossMarginTable
            tableId={tableId}
            selectedMerchant={selectedMerchant}
            dataFetcher={usedDataFetcher}
            bulkInputValue={bulkInputValue}
            bulkUpdateInProgress={showBulkUpdate}
          />
        </div>
      </div>
      <div
        className={classNames(
          'flex gap-12 p-12 w-full justify-end',
          'border-t border-solid border-grey-200',
          'absolute bottom-0'
        )}
      >
        <Button
          variant={ButtonVariant.BlackAndWhiteBorder}
          size={ButtonSize.Medium}
          label={intl.formatMessage({ id: I18nKey.CANCEL })}
          state={ButtonState.Active}
          onClick={closeModal}
          dataTestId={'EstAdGrossMarginSlideover_cancel'}
        />
        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.Medium}
          label={intl.formatMessage({ id: I18nKey.GENERIC_SUBMIT })}
          state={
            isSubmitInProgress
              ? ButtonState.Loading
              : showBulkUpdate
              ? ButtonState.Disabled
              : primaryButtonState
          }
          onClick={saveCogsChanges}
          dataTestId={'EstAdGrossMarginSlideover_submit'}
        />
      </div>
    </Modal>
  );
};

EstAdGrossMarginSlideover.displayName = 'EstAdGrossMarginSlideover';

export default EstAdGrossMarginSlideover;
