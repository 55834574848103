import { findFirst } from 'fp-ts/lib/Array';
import { Option, fromNullable } from 'fp-ts/lib/Option';
import debounce from 'lodash/fp/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  ButtonSize,
  SearchInput,
  SearchInputSize,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../lib/types/I18nKey';
import { tableSelectors } from './ducks';
import { INITIAL_LOAD_KEY, WithTable } from './ducks/types';
import { updateTableFilters } from './searchUtils';
import { FlywheelTableSearchInputProps } from './types';
import { isTableLoading } from './utils';
import { asyncRequestIdentifiedBy } from '../../lib/utilities/asyncRequest';
import { Filter, FilterOps, LikeFilter } from '../../lib/types/Filter';
import { isLikeFilter } from '../../lib/utilities/filter';

export const FlywheelSearchInput: React.FC<FlywheelTableSearchInputProps> = ({
  tableId,
  inputSearchColumnName,
  shouldAddWildcardsToFilterValue,
  searchInputSize = SearchInputSize.Medium,
  searchInputPlaceholder,
  clearSelectedRows,
  onFilterUpdate,
  upgradeFiltersInStorage,
  dataTestId,
  searchInputClassName,
  hasSearchInputWithButton = false,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const table = useSelector((state: any) =>
    tableSelectors.getTableSelector()(state.tableState, tableId)
  );
  const pageOfData = fromNullable(table.pages[0]).getOrElse(
    asyncRequestIdentifiedBy<any[], void>(
      INITIAL_LOAD_KEY
    ).AsyncRequestNotStarted()
  );

  const currentTableFilters = useSelector<WithTable<any, any>, Filter[]>(
    (state) => tableSelectors.getFiltersSelector()(state.tableState, tableId)
  );

  const currentSearchFilter = useSelector<
    WithTable<any, any>,
    Option<LikeFilter>
  >((state) => {
    const currentTable = tableSelectors.getTableSelector()(
      state.tableState,
      tableId
    );
    return findFirst(
      currentTable.filters,
      (filter) =>
        filter.op === FilterOps.like && filter.field === inputSearchColumnName
    ).filter(isLikeFilter);
  }, shallowEqual);
  const currentSearchFilterValue = currentSearchFilter
    .map((f) => f.value)
    .getOrElse('');

  const [searchValue, setSearchValue] = useState<string>(
    currentSearchFilterValue
  );

  const onSearchInputChangeHandler = useMemo(() => {
    // Updating filters causes the underlying table data to update.
    // Updating table data requires an API request.
    // We do not wanna fire off API requests on every keystroke.
    // Therefore debounce the actual dispatching of the filter updates
    const debouncedTableFiltersUpdate = debounce(500, updateTableFilters);

    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      // We maintain the input value separate from the current filter value
      // because the filter value is debounced
      setSearchValue(newValue);
      clearSelectedRows?.();
      debouncedTableFiltersUpdate(
        dispatch,
        newValue,
        currentTableFilters,
        inputSearchColumnName,
        tableId,
        upgradeFiltersInStorage,
        onFilterUpdate,
        shouldAddWildcardsToFilterValue
      );
    };
  }, [inputSearchColumnName, dispatch, currentTableFilters]);

  const onSearchInputClear = () => {
    clearSelectedRows?.();
    updateTableFilters(
      dispatch,
      '',
      currentTableFilters,
      inputSearchColumnName,
      tableId,
      upgradeFiltersInStorage,
      onFilterUpdate,
      shouldAddWildcardsToFilterValue
    );
    setSearchValue('');
  };

  const onSearchButtonClick = useMemo(() => {
    return (newValue: string) => {
      clearSelectedRows?.();
      setSearchValue(newValue);
      updateTableFilters(
        dispatch,
        newValue,
        currentTableFilters,
        inputSearchColumnName,
        tableId,
        upgradeFiltersInStorage,
        onFilterUpdate,
        shouldAddWildcardsToFilterValue
      );
    };
  }, [inputSearchColumnName, dispatch, currentTableFilters]);

  const onChange = useCallback(
    (newValue: string) => {
      setSearchValue(newValue);
      if (currentSearchFilterValue && !newValue) {
        onSearchInputClear();
      }
    },
    [currentSearchFilterValue]
  );

  const searchButtonInputProps = {
    label: intl.formatMessage({ id: I18nKey.SEARCH }),
    loading: isTableLoading(pageOfData.kind),
    onSearchButtonClick,
    onChange,
    size: ButtonSize.Default,
  };

  useEffect(() => {
    setSearchValue(currentSearchFilterValue);
  }, [currentSearchFilterValue]);

  return (
    <SearchInput
      inputClassName={searchInputClassName}
      value={searchValue}
      size={searchInputSize}
      onSearchInputClear={onSearchInputClear}
      placeholder={searchInputPlaceholder}
      dataTestId={`${dataTestId}_flywheelSearchInput`}
      onDebounceChange={
        hasSearchInputWithButton ? undefined : onSearchInputChangeHandler
      }
      searchButtonProps={
        hasSearchInputWithButton ? searchButtonInputProps : undefined
      }
    />
  );
};
FlywheelSearchInput.displayName = 'FlywheelSearchInput';
