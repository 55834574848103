import { MerchantCountryCode } from '../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../lib/types/I18nKey';
import { MerchantCardVariant } from '../AmazonModalSteps/AuthorizeMerchantTable';
import { MerchantsGroup } from '../AmazonModalSteps/authProductData';
import { MerchantType } from '../../../../lib/types/Fam';

export const INTERCOM_AMAZON_ONBOARDING_HELP =
  'https://intercom.help/flywheel-20/en/articles/8340776-onboarding-to-teikametrics-flywheel-2-0';

export const INTERCOM_AMAZON_CONNECT_ACCOUNT_HELP =
  'https://intercom.help/flywheel-20/en/articles/5554704-connecting-your-amazon-account-to-flywheel-2-0';

export const INTERCOM_AMAZON_CONNECT_ACCOUNT_HELP_ALREADY_CONNECTED =
  'https://intercom.help/flywheel-20/en/articles/8377392-what-do-i-do-iflywheel-says-my-merchants-are-already-connected';

export const INTERCOM_AMAZON_ONBOARDING_ADS_HELP =
  'https://intercom.help/flywheel-20/en/articles/5554704-connecting-your-amazon-account-to-flywheel-2-0#h_04aa77b658';

export const INTERCOM_AMAZON_ONBOARDING_PRODUCTS_HELP =
  'https://intercom.help/flywheel-20/en/articles/5554704-connecting-your-amazon-account-to-flywheel-2-0#h_65b71f17b8';

export const INTERCOM_WALMART_ONBOARDING_ADS_HELP =
  'https://intercom.help/flywheel-20/en/articles/8814348-walmart-3p-sellers-how-to-connect-ads-product-data-to-flywheel-2-0';

export const INTERCOM_WALMART_ONBOARDING_PRODUCTS_HELP =
  'https://intercom.help/flywheel-20/en/articles/8814348-walmart-3p-sellers-how-to-connect-ads-product-data-to-flywheel-2-0';

export const WALMART_SELLER_CENTER_LINK = `https://intercom.help/flywheel-20/en/collections/5917517-connecting-walmart-data`;

export const WALMART_SELLER_CENTRAL_SHOW_ME_HOW_LINK =
  'https://intercom.help/flywheel-20/en/articles/8814348-walmart-3p-sellers-how-to-connect-ads-product-data-to-flywheel-2-0#h_c22854c80b';

export const WALMART_FIND_3P_SELLER_ID_LINK =
  'https://intercom.help/flywheel-20/en/articles/8814348-walmart-3p-sellers-how-to-connect-ads-product-data-to-flywheel-2-0#h_c4042eebc3';

export const WALMART_FIND_1P_SUPPLIER_ID_LINK =
  'https://intercom.help/flywheel-20/en/articles/8202190-walmart-1p-supplier-dsv-how-to-connect-ads-product-data-to-flywheel-2-0#h_c320db2002';

export const Merchants: MerchantsGroup[] = [
  {
    name: 'North America',
    key: I18nKey.CONNECT_PRODUCTS_MODAL_AMAZON_REGION_NORTH_AMERICA,
    merchantCount: 0,
    merchantType: MerchantType.Seller,
    variant: MerchantCardVariant.Default,
    regionId: 'NA',
    url: [
      'sellercentral.amazon.com',
      'sellercentral.amazon.com.mx',
      'sellercentral.amazon.com.br',
      'sellercentral.amazon.ca',
    ],
    country_code: [
      MerchantCountryCode.BR,
      MerchantCountryCode.CA,
      MerchantCountryCode.MX,
      MerchantCountryCode.US,
    ],
  },
  {
    name: 'North America',
    key: I18nKey.CONNECT_PRODUCTS_MODAL_AMAZON_REGION_NORTH_AMERICA,
    merchantCount: 0,
    merchantType: MerchantType.Vendor,
    variant: MerchantCardVariant.Disabled,
    regionId: 'NA',
    url: [
      'vendorcentral.amazon.com',
      'vendorcentral.amazon.ca',
      'vendorcentral.amazon.com.mx',
      'vendorcentral.amazon.com.br',
    ],
    country_code: [
      MerchantCountryCode.BR,
      MerchantCountryCode.CA,
      MerchantCountryCode.MX,
      MerchantCountryCode.US,
    ],
  },
  {
    name: 'Europe',
    key: I18nKey.CONNECT_PRODUCTS_MODAL_AMAZON_REGION_EUROPE,
    merchantCount: 0,
    merchantType: MerchantType.Seller,
    variant: MerchantCardVariant.Default,
    regionId: 'EU',
    url: [
      'sellercentral-europe.amazon.com',
      'sellercentral.amazon.nl',
      'sellercentral.amazon.se',
      'sellercentral.amazon.pl',
      'sellercentral.amazon.eg',
      'sellercentral.amazon.com.tr',
      'sellercentral.amazon.com.sa',
      'sellercentral.amazon.ae',
      'sellercentral.amazon.in',
      'sellercentral.amazon.com.be',
      'sellercentral.amazon.de',
      'sellercentral.amazon.es',
      'sellercentral.amazon.it',
      'sellercentral.amazon.fr',
      'sellercentral.amazon.co.uk',
    ],
    country_code: [
      MerchantCountryCode.AE,
      MerchantCountryCode.DE,
      MerchantCountryCode.ES,
      MerchantCountryCode.FR,
      MerchantCountryCode.GB,
      MerchantCountryCode.IN,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
      MerchantCountryCode.SE,
      'PL',
      'BE',
      'EG',
      'SA',
      'TR',
    ],
  },
  // https://developer-docs.amazon.com/sp-api/docs/vendor-central-urls
  {
    name: 'Europe',
    key: I18nKey.CONNECT_PRODUCTS_MODAL_AMAZON_REGION_EUROPE,
    merchantCount: 0,
    merchantType: MerchantType.Vendor,
    variant: MerchantCardVariant.Default,
    regionId: 'EU',
    url: [
      'vendorcentral.amazon.co.uk',
      'vendorcentral.amazon.es',
      'vendorcentral.amazon.fr',
      'vendorcentral.amazon.com.be',
      'vendorcentral.amazon.nl',
      'vendorcentral.amazon.de',
      'vendorcentral.amazon.it',
      'vendorcentral.amazon.se',
      'vendorcentral.amazon.pl',
      'vendorcentral.amazon.me',
      'vendorcentral.amazon.com.tr',
      'vendorcentral.amazon.me',
      'www.vendorcentral.in',
    ],
    country_code: [
      MerchantCountryCode.AE,
      MerchantCountryCode.DE,
      MerchantCountryCode.ES,
      MerchantCountryCode.FR,
      MerchantCountryCode.GB,
      MerchantCountryCode.IN,
      MerchantCountryCode.IT,
      MerchantCountryCode.NL,
      MerchantCountryCode.SE,
      'PL',
      'BE',
      'EG',
      'SA',
      'TR',
    ],
  },
  {
    name: 'Far East',
    key: I18nKey.CONNECT_PRODUCTS_MODAL_AMAZON_REGION_FAR_EAST,
    merchantCount: 0,
    merchantType: MerchantType.Seller,
    variant: MerchantCardVariant.Default,
    regionId: 'FE',
    url: [
      'sellercentral.amazon.co.jp',
      'sellercentral.amazon.sg',
      'sellercentral.amazon.com.au',
      'sellercentral-japan.amazon.com',
    ],
    country_code: [
      MerchantCountryCode.JP,
      MerchantCountryCode.AU,
      MerchantCountryCode.SG,
    ],
  },
  {
    name: 'Far East',
    key: I18nKey.CONNECT_PRODUCTS_MODAL_AMAZON_REGION_FAR_EAST,
    merchantCount: 0,
    merchantType: MerchantType.Vendor,
    variant: MerchantCardVariant.Disabled,
    regionId: 'FE',
    url: [
      'vendorcentral.amazon.co.jp',
      'vendorcentral.amazon.com.sg',
      'vendorcentral.amazon.com.au',
    ],
    country_code: [
      MerchantCountryCode.JP,
      MerchantCountryCode.AU,
      MerchantCountryCode.SG,
    ],
  },
];
