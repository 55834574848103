import React, { ReactNode, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  DataSyncInfoContextState,
  useDataSyncInfoContext,
} from '../../containers/dataSyncInfoProvider/dataSyncInfoProvider';
import { AccountSalesChannelPaginatedResultWithError } from '../../modules/products/containers/skuCatalog/types';

import {
  BoxCubeLinearIcon,
  BullhornLinearIcon,
  ChartGraphLinearIcon,
  ChatBubbleIcon,
  CompassSolidIcon,
  DollyLinearIcon,
  LightbulbIcon,
  LockClosedIcon,
  PlugConnectedIcon,
} from '@teikametrics/tm-design-system';
import { EntityType } from '../../modules/account/containers/utils';
import isEmpty from 'lodash/isEmpty';

import { SubscriptionContext } from '../../containers/subscriptionProvider';
import {
  addIngestionStatusToDataAvailabilityInfo,
  getAdsAndProductDataConnectionStatusDataForSetup,
} from '../../lib/utilities/connectionStatus';

import I18nKey from '../../lib/types/I18nKey';
import CompassBgImg from '../../img/empty_states/compass/bg.png';
import CompassImg01 from '../../img/empty_states/compass/compass-1.png';
import CompassImg02 from '../../img/empty_states/compass/compass-2.png';
import CompassImg03 from '../../img/empty_states/compass/compass-3.png';
import AdsManagerBgImg from '../../img/empty_states/adsManager/bg.png';
import AdsManagerImg01 from '../../img/empty_states/adsManager/adsManager-1.png';
import AdsManagerImg02 from '../../img/empty_states/adsManager/adsManager-2.png';
import AdsManagerImg03 from '../../img/empty_states/adsManager/adsManager-3.png';
import ProductCatalogBgImg from '../../img/empty_states/productCatalog/bg.png';
import ProductCatalogImg01 from '../../img/empty_states/productCatalog/productCatalog-1.png';
import ProductCatalogImg02 from '../../img/empty_states/productCatalog/productCatalog-2.png';
import ProductCatalogImg03 from '../../img/empty_states/productCatalog/productCatalog-3.png';
import RecommendationHubBgImg from '../../img/empty_states/recommendationHub/bg.png';
import RecommendationHubImg01 from '../../img/empty_states/recommendationHub/recommendationHub-1.png';
import RecommendationHubImg02 from '../../img/empty_states/recommendationHub/recommendationHub-2.png';
import RecommendationHubImg03 from '../../img/empty_states/recommendationHub/recommendationHub-3.png';
import NewRecommendationHubBgImg from '../../img/empty_states/recommendationHub/background-fullwidth.png';
import NewRecommendationHubImg01 from '../../img/empty_states/recommendationHub/newRecommendationHub-1.png';
import NewRecommendationHubImg02 from '../../img/empty_states/recommendationHub/newRecommendationHub-2.png';

import CampaignCreatorBgImg from '../../img/empty_states/campaignCreator/bg.png';
import CampaignCreatorImg01 from '../../img/empty_states/campaignCreator/campaignCreator-1.png';
import CampaignCreatorImg02 from '../../img/empty_states/campaignCreator/campaignCreator-2.png';
import CampaignCreatorImg03 from '../../img/empty_states/campaignCreator/campaignCreator-3.png';
import AIRecommendedKeywordsBgImg from '../../img/empty_states/aiRecommendedKeywords/bg.png';
import AIRecommendedKeywordsImg01 from '../../img/empty_states/aiRecommendedKeywords/aiRecommendedKeywords-1.png';
import AIRecommendedKeywordsImg02 from '../../img/empty_states/aiRecommendedKeywords/aiRecommendedKeywords-2.png';
import BusinessIntelligenceBgImg from '../../img/empty_states/businessIntelligence/bg.png';
import BusinessIntelligenceImg01 from '../../img/empty_states/businessIntelligence/businessIntelligence-1.png';
import BusinessIntelligenceImg02 from '../../img/empty_states/businessIntelligence/businessIntelligence-2.png';
import BusinessIntelligenceImg03 from '../../img/empty_states/businessIntelligence/businessIntelligence-3.png';
import KeywordAndTargetingBgImg from '../../img/empty_states/keywordAndTargeting/bg.png';
import KeywordAndTargetingImg01 from '../../img/empty_states/keywordAndTargeting/keywordAndTargeting-1.png';
import KeywordAndTargetingImg02 from '../../img/empty_states/keywordAndTargeting/keywordAndTargeting-2.png';
import KeywordAndTargetingImg03 from '../../img/empty_states/keywordAndTargeting/keywordAndTargeting-3.png';
import BudgetPacingBgImg from '../../img/empty_states/budgetPacing/bg.png';
import BudgetPacingImg01 from '../../img/empty_states/budgetPacing/budgetPacing-1.png';
import BudgetPacingImg02 from '../../img/empty_states/budgetPacing/budgetPacing-2.png';

import InventoryOptimizationHeroImage from '../../img/empty_states/inventoryOptimization/hero_image.png';
import InventoryOptimizationDemandForecastImage from '../../img/empty_states/inventoryOptimization/demand_forecast.png';
import InventoryOptimizationRateOfSaleImage from '../../img/empty_states/inventoryOptimization/rate_of_sale.png';
import InventoryOptimizationOutOfStockImage from '../../img/empty_states/inventoryOptimization/out_of_stock.png';
import InventoryOptimizationExcessStockImage from '../../img/empty_states/inventoryOptimization/excess_stock.png';
import InventoryOptimizationInventoryHealthImage from '../../img/empty_states/inventoryOptimization/inventory_health.png';
import InventoryOptimizationStockoutReorderImage from '../../img/empty_states/inventoryOptimization/stockout_reorder.png';
import InventoryOptimizationHiiveClearanceImage from '../../img/empty_states/inventoryOptimization/hiive_clearance.png';

import { PageData, PageType } from './types';
import { ConnectionStatus, FlywheelSalesChannel } from '../../lib/types/Fam';
import {
  INVENTORY_OPTIMIZATION_DEMAND_FORECAST_HELP_CENTER_LINK,
  INVENTORY_OPTIMIZATION_EXCESS_STOCK_HELP_CENTER_LINK,
  INVENTORY_OPTIMIZATION_INVENTORY_AGING_HELP_CENTER_LINK,
  INVENTORY_OPTIMIZATION_OUT_OF_STOCK_HELP_CENTER_LINK,
  INVENTORY_OPTIMIZATION_RATE_OF_SALE_HELP_CENTER_LINK,
  INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_HELP_CENTER_LINK,
} from './consts';

export const CHAT_LINK =
  'https://go.teikametrics.com/teikametrics-managed-services-request';

export const HELP_LINK =
  'https://intercom.help/flywheel-20/en/collections/2824366-getting-started-with-flywheel-2-0';

export interface UseMerchantSyncingReturnProps {
  isNoData: boolean;
  isInSync: boolean;
  isAdsConnected: boolean;
  isProductConnected: boolean;
  isMerchantSyncLoaded: boolean;
}

export const useEmptyStateData = (
  pageType: PageType,
  isProductConnected: boolean,
  isAdsConnected: boolean,
  aiEnabled: boolean = false
) => {
  const intl = useIntl();
  const [
    COMPASS_HERO_TITLE,
    COMPASS_HERO_SUBTITLE,
    COMPASS_BANNER_TITLE,
    COMPASS_PROPOSITION1_TITLE,
    COMPASS_PROPOSITION1_SUBTITLE,
    COMPASS_PROPOSITION2_TITLE,
    COMPASS_PROPOSITION2_SUBTITLE,
    COMPASS_PROPOSITION3_TITLE,
    COMPASS_PROPOSITION3_SUBTITLE,
    COMPASS_WALKTHROUGH_TITLE,
    COMPASS_WALKTHROUGH_DEEP_DIVE_HELP,
    COMPASS_WALKTHROUGH_SLIDE1_TITLE,
    COMPASS_WALKTHROUGH_SLIDE1_SUBTITLE,
    COMPASS_WALKTHROUGH_SLIDE2_TITLE,
    COMPASS_WALKTHROUGH_SLIDE2_SUBTITLE,
    COMPASS_WALKTHROUGH_SLIDE3_TITLE,
    COMPASS_WALKTHROUGH_SLIDE3_SUBTITLE,

    ADS_MANAGER_HERO_TITLE,
    ADS_MANAGER_HERO_SUBTITLE,
    ADS_MANAGER_BANNER_TITLE,
    ADS_MANAGER_PROPOSITION1_TITLE,
    ADS_MANAGER_PROPOSITION1_SUBTITLE,
    ADS_MANAGER_PROPOSITION2_TITLE,
    ADS_MANAGER_PROPOSITION2_SUBTITLE,
    ADS_MANAGER_PROPOSITION3_TITLE,
    ADS_MANAGER_PROPOSITION3_SUBTITLE,
    ADS_MANAGER_WALKTHROUGH_TITLE,
    ADS_MANAGER_WALKTHROUGH_DEEP_DIVE_HELP,
    ADS_MANAGER_WALKTHROUGH_SLIDE1_TITLE,
    ADS_MANAGER_WALKTHROUGH_SLIDE1_SUBTITLE,
    ADS_MANAGER_WALKTHROUGH_SLIDE2_TITLE,
    ADS_MANAGER_WALKTHROUGH_SLIDE2_SUBTITLE,
    ADS_MANAGER_WALKTHROUGH_SLIDE3_TITLE,
    ADS_MANAGER_WALKTHROUGH_SLIDE3_SUBTITLE,

    PRODUCT_CATALOG_HERO_TITLE,
    PRODUCT_CATALOG_HERO_SUBTITLE,
    PRODUCT_CATALOG_BANNER_TITLE,
    PRODUCT_CATALOG_PROPOSITION1_TITLE,
    PRODUCT_CATALOG_PROPOSITION1_SUBTITLE,
    PRODUCT_CATALOG_PROPOSITION2_TITLE,
    PRODUCT_CATALOG_PROPOSITION2_SUBTITLE,
    PRODUCT_CATALOG_PROPOSITION3_TITLE,
    PRODUCT_CATALOG_PROPOSITION3_SUBTITLE,
    PRODUCT_CATALOG_WALKTHROUGH_TITLE,
    PRODUCT_CATALOG_WALKTHROUGH_DEEP_DIVE_HELP,
    PRODUCT_CATALOG_WALKTHROUGH_SLIDE1_TITLE,
    PRODUCT_CATALOG_WALKTHROUGH_SLIDE1_SUBTITLE,
    PRODUCT_CATALOG_WALKTHROUGH_SLIDE2_TITLE,
    PRODUCT_CATALOG_WALKTHROUGH_SLIDE2_SUBTITLE,
    PRODUCT_CATALOG_WALKTHROUGH_SLIDE3_TITLE,
    PRODUCT_CATALOG_WALKTHROUGH_SLIDE3_SUBTITLE,

    RECOMMENDATION_HUB_HERO_TITLE,
    RECOMMENDATION_HUB_HERO_SUBTITLE,
    RECOMMENDATION_HUB_BANNER_TITLE,
    RECOMMENDATION_HUB_PROPOSITION1_TITLE,
    RECOMMENDATION_HUB_PROPOSITION1_SUBTITLE,
    RECOMMENDATION_HUB_PROPOSITION2_TITLE,
    RECOMMENDATION_HUB_PROPOSITION2_SUBTITLE,
    RECOMMENDATION_HUB_WALKTHROUGH_TITLE,
    RECOMMENDATION_HUB_WALKTHROUGH_DEEP_DIVE_HELP,
    RECOMMENDATION_HUB_WALKTHROUGH_SLIDE1_TITLE,
    RECOMMENDATION_HUB_WALKTHROUGH_SLIDE1_SUBTITLE,
    RECOMMENDATION_HUB_WALKTHROUGH_SLIDE2_TITLE,
    RECOMMENDATION_HUB_WALKTHROUGH_SLIDE2_SUBTITLE,
    RECOMMENDATION_HUB_WALKTHROUGH_SLIDE3_TITLE,
    RECOMMENDATION_HUB_WALKTHROUGH_SLIDE3_SUBTITLE,

    CAMPAIGN_CREATOR_HERO_TITLE,
    CAMPAIGN_CREATOR_HERO_SUBTITLE,
    CAMPAIGN_CREATOR_BANNER_TITLE,
    CAMPAIGN_CREATOR_PROPOSITION1_TITLE,
    CAMPAIGN_CREATOR_PROPOSITION1_SUBTITLE,
    CAMPAIGN_CREATOR_PROPOSITION2_TITLE,
    CAMPAIGN_CREATOR_PROPOSITION2_SUBTITLE,
    CAMPAIGN_CREATOR_PROPOSITION3_TITLE,
    CAMPAIGN_CREATOR_PROPOSITION3_SUBTITLE,
    CAMPAIGN_CREATOR_WALKTHROUGH_TITLE,
    CAMPAIGN_CREATOR_WALKTHROUGH_DEEP_DIVE_HELP,
    CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE1_TITLE,
    CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE1_SUBTITLE,
    CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE2_TITLE,
    CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE2_SUBTITLE,
    CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE3_TITLE,
    CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE3_SUBTITLE,

    AI_RECOMMENDED_KEYWORDS_HERO_TITLE,
    AI_RECOMMENDED_KEYWORDS_HERO_SUBTITLE,
    AI_RECOMMENDED_KEYWORDS_BANNER_TITLE,
    AI_RECOMMENDED_KEYWORDS_PROPOSITION1_TITLE,
    AI_RECOMMENDED_KEYWORDS_PROPOSITION1_SUBTITLE,
    AI_RECOMMENDED_KEYWORDS_PROPOSITION2_TITLE,
    AI_RECOMMENDED_KEYWORDS_PROPOSITION2_SUBTITLE,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_TITLE,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_TITLE_V2,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_DEEP_DIVE_HELP,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_DEEP_DIVE_HELP_V2,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_TITLE,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_SUBTITLE,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_TITLE,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_SUBTITLE,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_V2_TITLE,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_V2_SUBTITLE,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_V2_TITLE,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_V2_SUBTITLE,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE3_TITLE,
    AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE3_SUBTITLE,

    BUSINESS_INTELLIGENCE_HERO_TITLE,
    BUSINESS_INTELLIGENCE_HERO_SUBTITLE,
    BUSINESS_INTELLIGENCE_BANNER_TITLE,
    BUSINESS_INTELLIGENCE_PROPOSITION1_TITLE,
    BUSINESS_INTELLIGENCE_PROPOSITION1_SUBTITLE,
    BUSINESS_INTELLIGENCE_PROPOSITION2_TITLE,
    BUSINESS_INTELLIGENCE_PROPOSITION2_SUBTITLE,
    BUSINESS_INTELLIGENCE_PROPOSITION3_TITLE,
    BUSINESS_INTELLIGENCE_PROPOSITION3_SUBTITLE,

    KEYWORD_TARGET_ACTIONS_HERO_TITLE,
    KEYWORD_TARGET_ACTIONS_HERO_SUBTITLE,
    KEYWORD_TARGET_ACTIONS_BANNER_TITLE,
    KEYWORD_TARGET_ACTIONS_PROPOSITION1_TITLE,
    KEYWORD_TARGET_ACTIONS_PROPOSITION1_SUBTITLE,
    KEYWORD_TARGET_ACTIONS_PROPOSITION2_TITLE,
    KEYWORD_TARGET_ACTIONS_PROPOSITION2_SUBTITLE,
    KEYWORD_TARGET_ACTIONS_PROPOSITION3_TITLE,
    KEYWORD_TARGET_ACTIONS_PROPOSITION3_SUBTITLE,

    BUDGET_PACING_HERO_TITLE,
    BUDGET_PACING_HERO_SUBTITLE,
    BUDGET_PACING_BANNER_TITLE,
    BUDGET_PACING_BANNER_SUBTITLE,
    BUDGET_PACING_PROPOSITION1_TITLE,
    BUDGET_PACING_PROPOSITION1_SUBTITLE,
    BUDGET_PACING_PROPOSITION2_TITLE,
    BUDGET_PACING_PROPOSITION2_SUBTITLE,
    BUDGET_PACING_WALKTHROUGH_TITLE,
    BUDGET_PACING_WALKTHROUGH_DEEP_DIVE_HELP,
    BUDGET_PACING_WALKTHROUGH_SLIDE1_TITLE,
    BUDGET_PACING_WALKTHROUGH_SLIDE1_SUBTITLE,
    BUDGET_PACING_WALKTHROUGH_SLIDE2_TITLE,
    BUDGET_PACING_WALKTHROUGH_SLIDE2_SUBTITLE,

    HERO_CONNECT_BUTTON_LABEL,
    HERO_CHAT_BUTTON_LABEL,

    RECOMMENDATION_HUB_PROPOSITION1_TITLE_NEW,
    RECOMMENDATION_HUB_PROPOSITION1_SUBTITLE_NEW,
    RECOMMENDATION_HUB_PROPOSITION2_TITLE_NEW,
    RECOMMENDATION_HUB_PROPOSITION2_SUBTITLE_NEW,
    RECOMMENDATION_HUB_HERO_SUBTITLE_NEW,

    INVENTORY_OPTIMIZATION_HERO_TITLE,
    INVENTORY_OPTIMIZATION_HERO_PILL_TEXT,
    INVENTORY_OPTIMIZATION_HERO_SUBTITLE,
    INVENTORY_OPTIMIZATION_HERO_RISK_FREE_TRIAL_BANNER,
    INVENTORY_OPTIMIZATION_DEMAND_FORECAST_TITLE,
    INVENTORY_OPTIMIZATION_DEMAND_FORECAST_DESCRIPTION,
    INVENTORY_OPTIMIZATION_RATE_OF_SALE_TITLE,
    INVENTORY_OPTIMIZATION_RATE_OF_SALE_DESCRIPTION,
    INVENTORY_OPTIMIZATION_OUT_OF_STOCK_TITLE,
    INVENTORY_OPTIMIZATION_OUT_OF_STOCK_DESCRIPTION,
    INVENTORY_OPTIMIZATION_EXCESS_STOCK_TITLE,
    INVENTORY_OPTIMIZATION_EXCESS_STOCK_DESCRIPTION,
    INVENTORY_OPTIMIZATION_AGING_INVENTORY_TITLE,
    INVENTORY_OPTIMIZATION_AGING_INVENTORY_DESCRIPTION,
    INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_TITLE,
    INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_DESCRIPTION,
    INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_TITLE,
    INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_DESCRIPTION,
    INVENTORY_OPTIMIZATION_CTA_BANNER_TITLE,
    INVENTORY_OPTIMIZATION_CTA_BANNER_DESCRIPTION,
  ] = [
    I18nKey.EMPTY_STATES_COMPASS_HERO_TITLE,
    I18nKey.EMPTY_STATES_COMPASS_HERO_SUBTITLE,
    I18nKey.EMPTY_STATES_COMPASS_BANNER_TITLE,
    I18nKey.EMPTY_STATES_COMPASS_PROPOSITION1_TITLE,
    I18nKey.EMPTY_STATES_COMPASS_PROPOSITION1_SUBTITLE,
    I18nKey.EMPTY_STATES_COMPASS_PROPOSITION2_TITLE,
    I18nKey.EMPTY_STATES_COMPASS_PROPOSITION2_SUBTITLE,
    I18nKey.EMPTY_STATES_COMPASS_PROPOSITION3_TITLE,
    I18nKey.EMPTY_STATES_COMPASS_PROPOSITION3_SUBTITLE,
    I18nKey.EMPTY_STATES_COMPASS_WALKTHROUGH_TITLE,
    I18nKey.EMPTY_STATES_COMPASS_WALKTHROUGH_DEEP_DIVE_HELP,
    I18nKey.EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE1_TITLE,
    I18nKey.EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE1_SUBTITLE,
    I18nKey.EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE2_TITLE,
    I18nKey.EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE2_SUBTITLE,
    I18nKey.EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE3_TITLE,
    I18nKey.EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE3_SUBTITLE,

    I18nKey.EMPTY_STATES_ADS_MANAGER_HERO_TITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_HERO_SUBTITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_BANNER_TITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_PROPOSITION1_TITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_PROPOSITION1_SUBTITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_PROPOSITION2_TITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_PROPOSITION2_SUBTITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_PROPOSITION3_TITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_PROPOSITION3_SUBTITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_TITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_DEEP_DIVE_HELP,
    I18nKey.EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE1_TITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE1_SUBTITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE2_TITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE2_SUBTITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE3_TITLE,
    I18nKey.EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE3_SUBTITLE,

    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_HERO_TITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_HERO_SUBTITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_BANNER_TITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION1_TITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION1_SUBTITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION2_TITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION2_SUBTITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION3_TITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION3_SUBTITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_TITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_DEEP_DIVE_HELP,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE1_TITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE1_SUBTITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE2_TITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE2_SUBTITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE3_TITLE,
    I18nKey.EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE3_SUBTITLE,

    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_HERO_TITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_HERO_SUBTITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_BANNER_TITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION1_TITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION1_SUBTITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION2_TITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION2_SUBTITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_TITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_DEEP_DIVE_HELP,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE1_TITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE1_SUBTITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE2_TITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE2_SUBTITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE3_TITLE,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE3_SUBTITLE,

    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_HERO_TITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_HERO_SUBTITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_BANNER_TITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION1_TITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION1_SUBTITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION2_TITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION2_SUBTITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION3_TITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION3_SUBTITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_TITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_DEEP_DIVE_HELP,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE1_TITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE1_SUBTITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE2_TITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE2_SUBTITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE3_TITLE,
    I18nKey.EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE3_SUBTITLE,

    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_HERO_TITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_HERO_SUBTITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_BANNER_TITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_PROPOSITION1_TITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_PROPOSITION1_SUBTITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_PROPOSITION2_TITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_PROPOSITION2_SUBTITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_TITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_TITLE_V2,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_DEEP_DIVE_HELP,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_DEEP_DIVE_HELP_V2,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_TITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_SUBTITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_TITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_SUBTITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_V2_TITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_V2_SUBTITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_V2_TITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_V2_SUBTITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE3_TITLE,
    I18nKey.EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE3_SUBTITLE,

    I18nKey.EMPTY_STATES_BUSINESS_INTELLIGENCE_HERO_TITLE,
    I18nKey.EMPTY_STATES_BUSINESS_INTELLIGENCE_HERO_SUBTITLE,
    I18nKey.EMPTY_STATES_BUSINESS_INTELLIGENCE_BANNER_TITLE,
    I18nKey.EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION1_TITLE,
    I18nKey.EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION1_SUBTITLE,
    I18nKey.EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION2_TITLE,
    I18nKey.EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION2_SUBTITLE,
    I18nKey.EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION3_TITLE,
    I18nKey.EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION3_SUBTITLE,

    I18nKey.EMPTY_STATES_KEYWORD_TARGET_ACTIONS_HERO_TITLE,
    I18nKey.EMPTY_STATES_KEYWORD_TARGET_ACTIONS_HERO_SUBTITLE,
    I18nKey.EMPTY_STATES_KEYWORD_TARGET_ACTIONS_BANNER_TITLE,
    I18nKey.EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION1_TITLE,
    I18nKey.EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION1_SUBTITLE,
    I18nKey.EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION2_TITLE,
    I18nKey.EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION2_SUBTITLE,
    I18nKey.EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION3_TITLE,
    I18nKey.EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION3_SUBTITLE,

    I18nKey.EMPTY_STATES_BUDGET_PACING_HERO_TITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_HERO_SUBTITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_BANNER_TITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_BANNER_SUBTITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_PROPOSITION1_TITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_PROPOSITION1_SUBTITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_PROPOSITION2_TITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_PROPOSITION2_SUBTITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_TITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_DEEP_DIVE_HELP,
    I18nKey.EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_SLIDE1_TITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_SLIDE1_SUBTITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_SLIDE2_TITLE,
    I18nKey.EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_SLIDE2_SUBTITLE,

    I18nKey.EMPTY_STATES_HERO_CONNECT_BUTTON_LABEL,
    I18nKey.EMPTY_STATES_HERO_CHAT_BUTTON_LABEL,

    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION1_TITLE_NEW,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION1_SUBTITLE_NEW,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION2_TITLE_NEW,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION2_SUBTITLE_NEW,
    I18nKey.EMPTY_STATES_RECOMMENDATION_HUB_HERO_SUBTITLE_NEW,

    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_HERO_TITLE,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_HERO_PILL_TEXT,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_HERO_SUBTITLE,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_FREE_TRIAL_BANNER,
    I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_DEMAND_FORECAST_TITLE,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_DEMAND_FORECAST_DESCRIPTION,
    I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_RATE_OF_SALE_TITLE,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_RATE_OF_SALE_DESCRIPTION,
    I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_OUT_OF_STOCK_TITLE,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_OUT_OF_STOCK_DESCRIPTION,
    I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_EXCESS_STOCK_TITLE,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_EXCESS_STOCK_DESCRIPTION,
    I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_AGING_INVENTORY_TITLE,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_AGING_INVENTORY_DESCRIPTION,
    I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_TITLE,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_DESCRIPTION,
    I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_TITLE,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_DESCRIPTION,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_LEARN_MORE_HEADER,
    I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_LEARN_MORE_DESCRIPTION,
  ].map((id) =>
    intl.formatMessage<ReactNode>(
      {
        id,
      },
      {
        b: (text) => <b>{text}</b>,
      }
    )
  );

  const EMPTY_STATE_DATA: Record<PageType, PageData> = {
    [PageType.Compass]: {
      bgImage: CompassBgImg,
      isVisible: isAdsConnected,
      hero: {
        title: COMPASS_HERO_TITLE,
        subtitle: COMPASS_HERO_SUBTITLE,
        icon: CompassSolidIcon,
        buttonIcon: PlugConnectedIcon,
        buttonLabel: HERO_CONNECT_BUTTON_LABEL,
      },
      banner: {
        title: COMPASS_BANNER_TITLE,
      },
      propositions: [
        {
          title: COMPASS_PROPOSITION1_TITLE,
          subtitle: COMPASS_PROPOSITION1_SUBTITLE,
          imageSource: CompassImg01,
        },
        {
          title: COMPASS_PROPOSITION2_TITLE,
          subtitle: COMPASS_PROPOSITION2_SUBTITLE,
          imageSource: CompassImg02,
        },
        {
          title: COMPASS_PROPOSITION3_TITLE,
          subtitle: COMPASS_PROPOSITION3_SUBTITLE,
          imageSource: CompassImg03,
        },
      ],
      walkthroughModal: {
        title: COMPASS_WALKTHROUGH_TITLE,
        deepDiveHelp: COMPASS_WALKTHROUGH_DEEP_DIVE_HELP,
        type: PageType.Compass,
        slides: [
          {
            title: COMPASS_WALKTHROUGH_SLIDE1_TITLE,
            subtitle: COMPASS_WALKTHROUGH_SLIDE1_SUBTITLE,
            imageSource: CompassImg01,
          },
          {
            title: COMPASS_WALKTHROUGH_SLIDE2_TITLE,
            subtitle: COMPASS_WALKTHROUGH_SLIDE2_SUBTITLE,
            imageSource: CompassImg02,
          },
          {
            title: COMPASS_WALKTHROUGH_SLIDE3_TITLE,
            subtitle: COMPASS_WALKTHROUGH_SLIDE3_SUBTITLE,
            imageSource: CompassImg03,
          },
        ],
      },
    },

    [PageType.AdsManager]: {
      bgImage: AdsManagerBgImg,
      isVisible: isAdsConnected,
      hero: {
        title: ADS_MANAGER_HERO_TITLE,
        subtitle: ADS_MANAGER_HERO_SUBTITLE,
        icon: BullhornLinearIcon,
        buttonIcon: PlugConnectedIcon,
        buttonLabel: HERO_CONNECT_BUTTON_LABEL,
      },
      banner: {
        title: ADS_MANAGER_BANNER_TITLE,
      },
      propositions: [
        {
          title: ADS_MANAGER_PROPOSITION1_TITLE,
          subtitle: ADS_MANAGER_PROPOSITION1_SUBTITLE,
          imageSource: AdsManagerImg01,
        },
        {
          title: ADS_MANAGER_PROPOSITION2_TITLE,
          subtitle: ADS_MANAGER_PROPOSITION2_SUBTITLE,
          imageSource: AdsManagerImg02,
        },
        {
          title: ADS_MANAGER_PROPOSITION3_TITLE,
          subtitle: ADS_MANAGER_PROPOSITION3_SUBTITLE,
          imageSource: AdsManagerImg03,
        },
      ],
      walkthroughModal: {
        title: ADS_MANAGER_WALKTHROUGH_TITLE,
        deepDiveHelp: ADS_MANAGER_WALKTHROUGH_DEEP_DIVE_HELP,
        type: PageType.AdsManager,
        slides: [
          {
            title: ADS_MANAGER_WALKTHROUGH_SLIDE1_TITLE,
            subtitle: ADS_MANAGER_WALKTHROUGH_SLIDE1_SUBTITLE,
            imageSource: AdsManagerImg01,
          },
          {
            title: ADS_MANAGER_WALKTHROUGH_SLIDE2_TITLE,
            subtitle: ADS_MANAGER_WALKTHROUGH_SLIDE2_SUBTITLE,
            imageSource: AdsManagerImg02,
          },
          {
            title: ADS_MANAGER_WALKTHROUGH_SLIDE3_TITLE,
            subtitle: ADS_MANAGER_WALKTHROUGH_SLIDE3_SUBTITLE,
            imageSource: AdsManagerImg03,
          },
        ],
      },
    },

    [PageType.ProductCatalog]: {
      bgImage: ProductCatalogBgImg,
      isVisible: isAdsConnected && isProductConnected,
      hero: {
        title: PRODUCT_CATALOG_HERO_TITLE,
        subtitle: PRODUCT_CATALOG_HERO_SUBTITLE,
        icon: BoxCubeLinearIcon,
        buttonIcon: PlugConnectedIcon,
        buttonLabel: HERO_CONNECT_BUTTON_LABEL,
      },
      banner: {
        title: PRODUCT_CATALOG_BANNER_TITLE,
      },
      propositions: [
        {
          title: PRODUCT_CATALOG_PROPOSITION1_TITLE,
          subtitle: PRODUCT_CATALOG_PROPOSITION1_SUBTITLE,
          imageSource: ProductCatalogImg01,
        },
        {
          title: PRODUCT_CATALOG_PROPOSITION2_TITLE,
          subtitle: PRODUCT_CATALOG_PROPOSITION2_SUBTITLE,
          imageSource: ProductCatalogImg02,
        },
        {
          title: PRODUCT_CATALOG_PROPOSITION3_TITLE,
          subtitle: PRODUCT_CATALOG_PROPOSITION3_SUBTITLE,
          imageSource: ProductCatalogImg03,
        },
      ],
      walkthroughModal: {
        title: PRODUCT_CATALOG_WALKTHROUGH_TITLE,
        deepDiveHelp: PRODUCT_CATALOG_WALKTHROUGH_DEEP_DIVE_HELP,
        type: PageType.ProductCatalog,
        slides: [
          {
            title: PRODUCT_CATALOG_WALKTHROUGH_SLIDE1_TITLE,
            subtitle: PRODUCT_CATALOG_WALKTHROUGH_SLIDE1_SUBTITLE,
            imageSource: ProductCatalogImg01,
          },
          {
            title: PRODUCT_CATALOG_WALKTHROUGH_SLIDE2_TITLE,
            subtitle: PRODUCT_CATALOG_WALKTHROUGH_SLIDE2_SUBTITLE,
            imageSource: ProductCatalogImg02,
          },
          {
            title: PRODUCT_CATALOG_WALKTHROUGH_SLIDE3_TITLE,
            subtitle: PRODUCT_CATALOG_WALKTHROUGH_SLIDE3_SUBTITLE,
            imageSource: ProductCatalogImg03,
          },
        ],
      },
    },

    [PageType.RecommendationHub]: {
      bgImage: RecommendationHubBgImg,
      isVisible: isAdsConnected,
      hero: {
        title: RECOMMENDATION_HUB_HERO_TITLE,
        subtitle: RECOMMENDATION_HUB_HERO_SUBTITLE,
        icon: LightbulbIcon,
        buttonIcon: PlugConnectedIcon,
        buttonLabel: HERO_CONNECT_BUTTON_LABEL,
      },
      banner: {
        title: RECOMMENDATION_HUB_BANNER_TITLE,
      },
      propositions: [
        {
          title: RECOMMENDATION_HUB_PROPOSITION1_TITLE,
          subtitle: RECOMMENDATION_HUB_PROPOSITION1_SUBTITLE,
          imageSource: RecommendationHubImg01,
        },
        {
          title: RECOMMENDATION_HUB_PROPOSITION2_TITLE,
          subtitle: RECOMMENDATION_HUB_PROPOSITION2_SUBTITLE,
          imageSource: RecommendationHubImg02,
        },
      ],
      walkthroughModal: {
        title: RECOMMENDATION_HUB_WALKTHROUGH_TITLE,
        deepDiveHelp: RECOMMENDATION_HUB_WALKTHROUGH_DEEP_DIVE_HELP,
        type: PageType.RecommendationHub,
        slides: [
          {
            title: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE1_TITLE,
            subtitle: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE1_SUBTITLE,
            imageSource: RecommendationHubImg01,
          },
          {
            title: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE2_TITLE,
            subtitle: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE2_SUBTITLE,
            imageSource: RecommendationHubImg02,
          },
          {
            title: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE3_TITLE,
            subtitle: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE3_SUBTITLE,
            imageSource: RecommendationHubImg03,
          },
        ],
      },
    },

    [PageType.NewRecommendationHub]: {
      bgImage: NewRecommendationHubBgImg,
      isVisible: isAdsConnected && !!aiEnabled,
      hero: {
        title: RECOMMENDATION_HUB_HERO_TITLE,
        subtitle: RECOMMENDATION_HUB_HERO_SUBTITLE_NEW,
        icon: LightbulbIcon,
        buttonIcon: PlugConnectedIcon,
        buttonLabel: HERO_CONNECT_BUTTON_LABEL,
      },
      banner: {
        title: RECOMMENDATION_HUB_BANNER_TITLE,
      },
      propositions: [
        {
          title: RECOMMENDATION_HUB_PROPOSITION1_TITLE_NEW,
          subtitle: RECOMMENDATION_HUB_PROPOSITION1_SUBTITLE_NEW,
          imageSource: NewRecommendationHubImg01,
        },
        {
          title: RECOMMENDATION_HUB_PROPOSITION2_TITLE_NEW,
          subtitle: RECOMMENDATION_HUB_PROPOSITION2_SUBTITLE_NEW,
          imageSource: NewRecommendationHubImg02,
        },
      ],
      walkthroughModal: {
        title: RECOMMENDATION_HUB_WALKTHROUGH_TITLE,
        deepDiveHelp: RECOMMENDATION_HUB_WALKTHROUGH_DEEP_DIVE_HELP,
        type: PageType.RecommendationHub,
        slides: [
          {
            title: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE1_TITLE,
            subtitle: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE1_SUBTITLE,
            imageSource: RecommendationHubImg01,
          },
          {
            title: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE2_TITLE,
            subtitle: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE2_SUBTITLE,
            imageSource: RecommendationHubImg02,
          },
          {
            title: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE3_TITLE,
            subtitle: RECOMMENDATION_HUB_WALKTHROUGH_SLIDE3_SUBTITLE,
            imageSource: RecommendationHubImg03,
          },
        ],
      },
    },

    [PageType.CampaignCreator]: {
      bgImage: CampaignCreatorBgImg,
      isVisible: isAdsConnected && isProductConnected,
      hero: {
        title: CAMPAIGN_CREATOR_HERO_TITLE,
        subtitle: CAMPAIGN_CREATOR_HERO_SUBTITLE,
        icon: BullhornLinearIcon,
        buttonIcon: PlugConnectedIcon,
        buttonLabel: HERO_CONNECT_BUTTON_LABEL,
      },
      banner: {
        title: CAMPAIGN_CREATOR_BANNER_TITLE,
      },
      propositions: [
        {
          title: CAMPAIGN_CREATOR_PROPOSITION1_TITLE,
          subtitle: CAMPAIGN_CREATOR_PROPOSITION1_SUBTITLE,
          imageSource: CampaignCreatorImg01,
        },
        {
          title: CAMPAIGN_CREATOR_PROPOSITION2_TITLE,
          subtitle: CAMPAIGN_CREATOR_PROPOSITION2_SUBTITLE,
          imageSource: CampaignCreatorImg02,
        },
        {
          title: CAMPAIGN_CREATOR_PROPOSITION3_TITLE,
          subtitle: CAMPAIGN_CREATOR_PROPOSITION3_SUBTITLE,
          imageSource: CampaignCreatorImg03,
        },
      ],
      walkthroughModal: {
        title: CAMPAIGN_CREATOR_WALKTHROUGH_TITLE,
        deepDiveHelp: CAMPAIGN_CREATOR_WALKTHROUGH_DEEP_DIVE_HELP,
        type: PageType.CampaignCreator,
        slides: [
          {
            title: CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE1_TITLE,
            subtitle: CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE1_SUBTITLE,
            imageSource: CampaignCreatorImg01,
          },
          {
            title: CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE2_TITLE,
            subtitle: CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE2_SUBTITLE,
            imageSource: CampaignCreatorImg02,
          },
          {
            title: CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE3_TITLE,
            subtitle: CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE3_SUBTITLE,
            imageSource: CampaignCreatorImg03,
          },
        ],
      },
    },

    [PageType.AIRecommendedKeywords]: {
      bgImage: AIRecommendedKeywordsBgImg,
      isVisible: isAdsConnected && isProductConnected,
      hero: {
        title: AI_RECOMMENDED_KEYWORDS_HERO_TITLE,
        subtitle: AI_RECOMMENDED_KEYWORDS_HERO_SUBTITLE,
        icon: BullhornLinearIcon,
        buttonIcon: PlugConnectedIcon,
        buttonLabel: HERO_CONNECT_BUTTON_LABEL,
      },
      banner: {
        title: AI_RECOMMENDED_KEYWORDS_BANNER_TITLE,
      },
      propositions: [
        {
          title: AI_RECOMMENDED_KEYWORDS_PROPOSITION1_TITLE,
          subtitle: AI_RECOMMENDED_KEYWORDS_PROPOSITION1_SUBTITLE,
          imageSource: AIRecommendedKeywordsImg01,
        },
        {
          title: AI_RECOMMENDED_KEYWORDS_PROPOSITION2_TITLE,
          subtitle: AI_RECOMMENDED_KEYWORDS_PROPOSITION2_SUBTITLE,
          imageSource: AIRecommendedKeywordsImg02,
        },
      ],
      walkthroughModal: {
        title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_TITLE,
        deepDiveHelp: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_DEEP_DIVE_HELP,
        type: PageType.AIRecommendedKeywords,
        slides: [
          {
            title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_TITLE,
            subtitle: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_SUBTITLE,
            imageSource: AIRecommendedKeywordsImg01,
          },
          {
            title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_TITLE,
            subtitle: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_SUBTITLE,
            imageSource: AIRecommendedKeywordsImg02,
          },
        ],
      },
    },

    [PageType.AIRecommendedKeywordsV2]: {
      bgImage: AIRecommendedKeywordsBgImg,
      isVisible: isAdsConnected && isProductConnected,
      hero: {
        title: AI_RECOMMENDED_KEYWORDS_HERO_TITLE,
        subtitle: AI_RECOMMENDED_KEYWORDS_HERO_SUBTITLE,
        icon: BullhornLinearIcon,
        buttonIcon: PlugConnectedIcon,
        buttonLabel: HERO_CONNECT_BUTTON_LABEL,
      },
      banner: {
        title: AI_RECOMMENDED_KEYWORDS_BANNER_TITLE,
      },
      propositions: [
        {
          title: AI_RECOMMENDED_KEYWORDS_PROPOSITION1_TITLE,
          subtitle: AI_RECOMMENDED_KEYWORDS_PROPOSITION1_SUBTITLE,
          imageSource: AIRecommendedKeywordsImg01,
        },
        {
          title: AI_RECOMMENDED_KEYWORDS_PROPOSITION2_TITLE,
          subtitle: AI_RECOMMENDED_KEYWORDS_PROPOSITION2_SUBTITLE,
          imageSource: AIRecommendedKeywordsImg02,
        },
      ],
      walkthroughModal: {
        title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_TITLE_V2,
        deepDiveHelp: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_DEEP_DIVE_HELP_V2,
        type: PageType.AIRecommendedKeywordsV2,
        slides: [
          {
            title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_V2_TITLE,
            subtitle: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_V2_SUBTITLE,
            imageSource: KeywordAndTargetingImg01,
          },
          {
            title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_V2_TITLE,
            subtitle: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_V2_SUBTITLE,
            imageSource: KeywordAndTargetingImg02,
          },
          {
            title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE3_TITLE,
            subtitle: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE3_SUBTITLE,
            imageSource: KeywordAndTargetingImg03,
          },
        ],
      },
    },

    [PageType.BusinessIntelligence]: {
      bgImage: BusinessIntelligenceBgImg,
      isVisible: isAdsConnected,
      hero: {
        title: BUSINESS_INTELLIGENCE_HERO_TITLE,
        subtitle: BUSINESS_INTELLIGENCE_HERO_SUBTITLE,
        icon: ChartGraphLinearIcon,
        buttonIcon: ChatBubbleIcon,
        buttonLabel: HERO_CHAT_BUTTON_LABEL,
      },
      banner: {
        title: BUSINESS_INTELLIGENCE_BANNER_TITLE,
      },
      propositions: [
        {
          title: BUSINESS_INTELLIGENCE_PROPOSITION1_TITLE,
          subtitle: BUSINESS_INTELLIGENCE_PROPOSITION1_SUBTITLE,
          imageSource: BusinessIntelligenceImg01,
        },
        {
          title: BUSINESS_INTELLIGENCE_PROPOSITION2_TITLE,
          subtitle: BUSINESS_INTELLIGENCE_PROPOSITION2_SUBTITLE,
          imageSource: BusinessIntelligenceImg02,
        },
        {
          title: BUSINESS_INTELLIGENCE_PROPOSITION3_TITLE,
          subtitle: BUSINESS_INTELLIGENCE_PROPOSITION3_SUBTITLE,
          imageSource: BusinessIntelligenceImg03,
        },
      ],
    },

    [PageType.KeywordTargetActions]: {
      bgImage: KeywordAndTargetingBgImg,
      isVisible: isAdsConnected && isProductConnected,
      hero: {
        title: KEYWORD_TARGET_ACTIONS_HERO_TITLE,
        subtitle: KEYWORD_TARGET_ACTIONS_HERO_SUBTITLE,
        icon: BullhornLinearIcon,
        buttonIcon: PlugConnectedIcon,
        buttonLabel: HERO_CONNECT_BUTTON_LABEL,
      },
      banner: {
        title: KEYWORD_TARGET_ACTIONS_BANNER_TITLE,
      },
      propositions: [
        {
          title: KEYWORD_TARGET_ACTIONS_PROPOSITION1_TITLE,
          subtitle: KEYWORD_TARGET_ACTIONS_PROPOSITION1_SUBTITLE,
          imageSource: KeywordAndTargetingImg01,
        },
        {
          title: KEYWORD_TARGET_ACTIONS_PROPOSITION2_TITLE,
          subtitle: KEYWORD_TARGET_ACTIONS_PROPOSITION2_SUBTITLE,
          imageSource: KeywordAndTargetingImg02,
        },
        {
          title: KEYWORD_TARGET_ACTIONS_PROPOSITION3_TITLE,
          subtitle: KEYWORD_TARGET_ACTIONS_PROPOSITION3_SUBTITLE,
          imageSource: KeywordAndTargetingImg03,
        },
      ],
      walkthroughModal: {
        title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_TITLE,
        deepDiveHelp: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_DEEP_DIVE_HELP,
        type: PageType.KeywordTargetActions,
        slides: [
          {
            title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_TITLE,
            subtitle: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_SUBTITLE,
            imageSource: AIRecommendedKeywordsImg01,
          },
          {
            title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_TITLE,
            subtitle: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_SUBTITLE,
            imageSource: AIRecommendedKeywordsImg02,
          },
        ],
      },
    },

    [PageType.KeywordTargetActionsV2]: {
      bgImage: KeywordAndTargetingBgImg,
      isVisible: isAdsConnected && isProductConnected,
      hero: {
        title: KEYWORD_TARGET_ACTIONS_HERO_TITLE,
        subtitle: KEYWORD_TARGET_ACTIONS_HERO_SUBTITLE,
        icon: BullhornLinearIcon,
        buttonIcon: PlugConnectedIcon,
        buttonLabel: HERO_CONNECT_BUTTON_LABEL,
      },
      banner: {
        title: KEYWORD_TARGET_ACTIONS_BANNER_TITLE,
      },
      propositions: [
        {
          title: KEYWORD_TARGET_ACTIONS_PROPOSITION1_TITLE,
          subtitle: KEYWORD_TARGET_ACTIONS_PROPOSITION1_SUBTITLE,
          imageSource: KeywordAndTargetingImg01,
        },
        {
          title: KEYWORD_TARGET_ACTIONS_PROPOSITION2_TITLE,
          subtitle: KEYWORD_TARGET_ACTIONS_PROPOSITION2_SUBTITLE,
          imageSource: KeywordAndTargetingImg02,
        },
        {
          title: KEYWORD_TARGET_ACTIONS_PROPOSITION3_TITLE,
          subtitle: KEYWORD_TARGET_ACTIONS_PROPOSITION3_SUBTITLE,
          imageSource: KeywordAndTargetingImg03,
        },
      ],
      walkthroughModal: {
        title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_TITLE_V2,
        deepDiveHelp: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_DEEP_DIVE_HELP_V2,
        type: PageType.KeywordTargetActionsV2,
        slides: [
          {
            title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_V2_TITLE,
            subtitle: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_V2_SUBTITLE,
            imageSource: KeywordAndTargetingImg01,
          },
          {
            title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_V2_TITLE,
            subtitle: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_V2_SUBTITLE,
            imageSource: KeywordAndTargetingImg02,
          },
          {
            title: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE3_TITLE,
            subtitle: AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE3_SUBTITLE,
            imageSource: KeywordAndTargetingImg03,
          },
        ],
      },
    },

    [PageType.BudgetPacing]: {
      bgImage: BudgetPacingBgImg,
      isVisible: isAdsConnected,
      hero: {
        title: BUDGET_PACING_HERO_TITLE,
        subtitle: BUDGET_PACING_HERO_SUBTITLE,
        icon: BullhornLinearIcon,
        buttonIcon: PlugConnectedIcon,
        buttonLabel: HERO_CONNECT_BUTTON_LABEL,
      },
      banner: {
        title: BUDGET_PACING_BANNER_TITLE,
        subtitle: BUDGET_PACING_BANNER_SUBTITLE,
      },
      propositions: [
        {
          title: BUDGET_PACING_PROPOSITION1_TITLE,
          subtitle: BUDGET_PACING_PROPOSITION1_SUBTITLE,
          imageSource: BudgetPacingImg01,
        },
        {
          title: BUDGET_PACING_PROPOSITION2_TITLE,
          subtitle: BUDGET_PACING_PROPOSITION2_SUBTITLE,
          imageSource: BudgetPacingImg02,
        },
      ],
      walkthroughModal: {
        title: BUDGET_PACING_WALKTHROUGH_TITLE,
        deepDiveHelp: BUDGET_PACING_WALKTHROUGH_DEEP_DIVE_HELP,
        type: PageType.BudgetPacing,
        slides: [
          {
            title: BUDGET_PACING_WALKTHROUGH_SLIDE1_TITLE,
            subtitle: BUDGET_PACING_WALKTHROUGH_SLIDE1_SUBTITLE,
            imageSource: BudgetPacingImg01,
          },
          {
            title: BUDGET_PACING_WALKTHROUGH_SLIDE2_TITLE,
            subtitle: BUDGET_PACING_WALKTHROUGH_SLIDE2_SUBTITLE,
            imageSource: BudgetPacingImg02,
          },
        ],
      },
    },

    [PageType.InventoryOptimization]: {
      isVisible: true,
      hero: {
        title: INVENTORY_OPTIMIZATION_HERO_TITLE,
        subtitle: INVENTORY_OPTIMIZATION_HERO_SUBTITLE,
        icon: DollyLinearIcon,
        buttonIcon: LockClosedIcon,
        buttonLabel: INVENTORY_OPTIMIZATION_HERO_PILL_TEXT,
        riskFreeTrialMessage:
          INVENTORY_OPTIMIZATION_HERO_RISK_FREE_TRIAL_BANNER,
        imgPath: InventoryOptimizationHeroImage,
      },
      banner: {
        title: INVENTORY_OPTIMIZATION_CTA_BANNER_TITLE,
        subtitle: INVENTORY_OPTIMIZATION_CTA_BANNER_DESCRIPTION,
      },
      propositions: [
        {
          title: INVENTORY_OPTIMIZATION_DEMAND_FORECAST_TITLE,
          subtitle: INVENTORY_OPTIMIZATION_DEMAND_FORECAST_DESCRIPTION,
          imageSource: InventoryOptimizationDemandForecastImage,
          textLinkProps: {
            textLabel: <FormattedMessage id={I18nKey.GENERIC_LEARN_MORE} />,
            href: INVENTORY_OPTIMIZATION_DEMAND_FORECAST_HELP_CENTER_LINK,
            openNewTab: true,
          },
        },
        {
          title: INVENTORY_OPTIMIZATION_RATE_OF_SALE_TITLE,
          subtitle: INVENTORY_OPTIMIZATION_RATE_OF_SALE_DESCRIPTION,
          imageSource: InventoryOptimizationRateOfSaleImage,
          textLinkProps: {
            textLabel: <FormattedMessage id={I18nKey.GENERIC_LEARN_MORE} />,
            href: INVENTORY_OPTIMIZATION_RATE_OF_SALE_HELP_CENTER_LINK,
            openNewTab: true,
          },
        },
        {
          title: INVENTORY_OPTIMIZATION_OUT_OF_STOCK_TITLE,
          subtitle: INVENTORY_OPTIMIZATION_OUT_OF_STOCK_DESCRIPTION,
          imageSource: InventoryOptimizationOutOfStockImage,
          textLinkProps: {
            textLabel: <FormattedMessage id={I18nKey.GENERIC_LEARN_MORE} />,
            href: INVENTORY_OPTIMIZATION_OUT_OF_STOCK_HELP_CENTER_LINK,
            openNewTab: true,
          },
        },
        {
          title: INVENTORY_OPTIMIZATION_EXCESS_STOCK_TITLE,
          subtitle: INVENTORY_OPTIMIZATION_EXCESS_STOCK_DESCRIPTION,
          imageSource: InventoryOptimizationExcessStockImage,
          textLinkProps: {
            textLabel: <FormattedMessage id={I18nKey.GENERIC_LEARN_MORE} />,
            href: INVENTORY_OPTIMIZATION_EXCESS_STOCK_HELP_CENTER_LINK,
            openNewTab: true,
          },
        },
        {
          title: INVENTORY_OPTIMIZATION_AGING_INVENTORY_TITLE,
          subtitle: INVENTORY_OPTIMIZATION_AGING_INVENTORY_DESCRIPTION,
          imageSource: InventoryOptimizationInventoryHealthImage,
          textLinkProps: {
            textLabel: <FormattedMessage id={I18nKey.GENERIC_LEARN_MORE} />,
            href: INVENTORY_OPTIMIZATION_INVENTORY_AGING_HELP_CENTER_LINK,
            openNewTab: true,
          },
        },
        {
          title: INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_TITLE,
          subtitle: INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_DESCRIPTION,
          imageSource: InventoryOptimizationStockoutReorderImage,
          textLinkProps: {
            textLabel: <FormattedMessage id={I18nKey.GENERIC_LEARN_MORE} />,
            href: INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_HELP_CENTER_LINK,
            openNewTab: true,
          },
        },
        {
          title: INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_TITLE,
          subtitle: INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_DESCRIPTION,
          imageSource: InventoryOptimizationHiiveClearanceImage,
        },
      ],
    },
  };

  return EMPTY_STATE_DATA[pageType];
};

export const getAdsProductConnectionData = ({
  dataSyncInfo,
  salesChannelData,
}: {
  dataSyncInfo: DataSyncInfoContextState;
  salesChannelData: AccountSalesChannelPaginatedResultWithError[];
}) => {
  const aoDataAvailabilityInfo = addIngestionStatusToDataAvailabilityInfo(
    dataSyncInfo.dataSyncStatus || {},
    dataSyncInfo?.aoDataSyncInfo?.syncPerMerchantIds || [],
    EntityType.Ads
  );

  const skuDataAvailabilityInfo = addIngestionStatusToDataAvailabilityInfo(
    dataSyncInfo.dataSyncStatus || {},
    dataSyncInfo?.skuDataAvailibilityInfo?.syncPerMerchantIds || [],
    EntityType.Products
  );

  const connectionData = getAdsAndProductDataConnectionStatusDataForSetup(
    aoDataAvailabilityInfo || [],
    skuDataAvailabilityInfo || [],
    salesChannelData
  );

  const amazonData = connectionData[FlywheelSalesChannel.Amazon];
  const walmartData = connectionData[FlywheelSalesChannel.Walmart];

  const amazonAdsConnected = [
    ...amazonData.connectedData,
    ...amazonData.syncingData,
  ].find((item) => item.adsDataConnectionStatus === ConnectionStatus.Connected);

  const amazonProductConnected = [
    ...amazonData.connectedData,
    ...amazonData.syncingData,
  ].find(
    (item) => item.productsDataConnectionStatus === ConnectionStatus.Connected
  );

  const amazonAdsSyncing = [
    ...amazonData.connectedData,
    ...amazonData.syncingData,
  ].find(
    (item) =>
      item.adsDataConnectionStatus === ConnectionStatus.Syncing ||
      item.adsDataConnectionStatus === ConnectionStatus.Active
  );

  const amazonProductsSyncing = [
    ...amazonData.connectedData,
    ...amazonData.syncingData,
  ].find(
    (item) =>
      item.productsDataConnectionStatus === ConnectionStatus.Syncing ||
      item.productsDataConnectionStatus === ConnectionStatus.Active
  );

  const walmartAdsSyncing = [
    ...walmartData.connectedData,
    ...walmartData.syncingData,
  ].find(
    (item) =>
      item.adsDataConnectionStatus === ConnectionStatus.Syncing ||
      item.adsDataConnectionStatus === ConnectionStatus.Active
  );

  const walmartProductsSyncing = [
    ...walmartData.connectedData,
    ...walmartData.syncingData,
  ].find(
    (item) =>
      item.productsDataConnectionStatus === ConnectionStatus.Syncing ||
      item.productsDataConnectionStatus === ConnectionStatus.Active
  );

  const walmartAdsConnected = [
    ...walmartData.connectedData,
    ...walmartData.syncingData,
  ].find((item) => item.adsDataConnectionStatus === ConnectionStatus.Connected);

  const walmartProductConnected = [
    ...walmartData.connectedData,
    ...walmartData.syncingData,
  ].find(
    (item) => item.productsDataConnectionStatus === ConnectionStatus.Connected
  );

  return {
    amazonData,
    walmartData,
    amazonAdsConnected,
    amazonProductConnected,
    amazonAdsSyncing,
    amazonProductsSyncing,
    walmartAdsSyncing,
    walmartProductsSyncing,
    walmartAdsConnected,
    walmartProductConnected,
  };
};

export const useMerchantsSyncing = (): UseMerchantSyncingReturnProps => {
  const dataSyncInfo = useDataSyncInfoContext();

  const subscriptionContext = useContext(SubscriptionContext);
  const { salesChannelData, isSubscriptionInformationLoaded } =
    subscriptionContext;

  const isMerchantSyncLoaded =
    isSubscriptionInformationLoaded &&
    dataSyncInfo.isDataSyncInfoProviderLoaded;

  useEffect(() => {
    dataSyncInfo.refreshData();
    if (!subscriptionContext.isSubscriptionInformationLoaded) {
      subscriptionContext.updateSubscriptionInformation();
    }
  }, []);

  const {
    amazonData,
    walmartData,
    amazonAdsConnected,
    walmartAdsConnected,
    amazonProductConnected,
    walmartProductConnected,
  } = getAdsProductConnectionData({ dataSyncInfo, salesChannelData });

  const isAdsSyncing = amazonData.syncing > 0 || walmartData.syncing > 0;
  const isAdsConnected =
    !isEmpty(amazonAdsConnected) || !isEmpty(walmartAdsConnected);
  const isProductConnected =
    !isEmpty(amazonProductConnected) || !isEmpty(walmartProductConnected);

  // Account has no MCIDs connected
  if (amazonData?.total === 0 && walmartData?.total === 0) {
    return {
      isNoData: true,
      isInSync: false,
      isAdsConnected,
      isProductConnected,
      isMerchantSyncLoaded,
    };
  }

  // Account is in syncing status
  if (!isAdsConnected && !isProductConnected && isAdsSyncing) {
    return {
      isInSync: true,
      isNoData: false,
      isAdsConnected,
      isProductConnected,
      isMerchantSyncLoaded,
    };
  }

  /*
    All other cases will have showEmptyState set to true
    so that the component using this hook will in conjuction use
    useEmptyStateData hook will handle these cases
  */
  return {
    isInSync: false,
    isNoData: false,
    isAdsConnected,
    isProductConnected,
    isMerchantSyncLoaded,
  };
};
