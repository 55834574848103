import { IntlShape, useIntl } from 'react-intl';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  AdType,
  KeywordTargetType,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TARGETS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import {
  KEYWORD_TARGET_TYPE_TO_TARGET_TYPE_I18NKEY_MAPPER,
  TableDataAdsManager,
} from '../types';

const getText = (intl: IntlShape, keywordType?: KeywordTargetType) => {
  if (
    keywordType &&
    KEYWORD_TARGET_TYPE_TO_TARGET_TYPE_I18NKEY_MAPPER[keywordType]
  ) {
    return intl.formatMessage({
      id: KEYWORD_TARGET_TYPE_TO_TARGET_TYPE_I18NKEY_MAPPER[keywordType],
    });
  }
};

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) => {
  const intl = useIntl();
  const { targetDetails } = props;
  const value = getText(
    intl,
    props?.targetDetails?.keywordType as KeywordTargetType
  );

  return makeTextColumn(() => {
    return value;
  })(targetDetails);
};

RowCellElement.displayName = 'RowCellElement';

export const targetTypeColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.KeywordType,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TYPE,
  RowCellElement,
  gridColumnWidth: '220px',
};

export const getTargetTypeFilter = (adType: AdType, adLevel: AdLevel) =>
  createMultiSelectDataFieldFilter(
    TARGETS_API_COLUMN_NAME.KeywordType,
    I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TYPE,
    getFilterOptions(adType, adLevel)
  );

const getFilterOptions = (adType: AdType, adLevel: AdLevel) => {
  if (adType === AdType.SponsoredProducts) {
    if (adLevel === AdLevel.ProductTargets) {
      return [
        SelectFilterOption.ProductTarget,
        SelectFilterOption.NegativeProductTarget,
      ];
    }

    if (adLevel === AdLevel.KeywordTargets) {
      return [
        SelectFilterOption.Keyword,
        SelectFilterOption.Negativekeyword,
        SelectFilterOption.CampaignNegativeKeyword,
      ];
    }
  }

  if (adType === AdType.SponsoredBrands) {
    if (adLevel === AdLevel.ProductTargets) {
      return [
        SelectFilterOption.ProductTarget,
        SelectFilterOption.NegativeProductTarget,
      ];
    }

    if (adLevel === AdLevel.KeywordTargets) {
      return [SelectFilterOption.Keyword, SelectFilterOption.Negativekeyword];
    }
  }

  if (adType === AdType.SponsoredDisplay) {
    if (adLevel === AdLevel.Targets) {
      return [
        SelectFilterOption.ProductTarget,
        SelectFilterOption.NegativeProductTarget,
        SelectFilterOption.AudienceTarget,
        SelectFilterOption.ContextualTarget,
      ];
    }
  }

  return [];
};
