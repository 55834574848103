import { IntlShape } from 'react-intl';

import I18nKey from '../../../../lib/types/I18nKey';
import {
  ColumnManagerOptionWithIsSelected,
  SkuAdPerformanceApiColumnValue,
  SkuAdPerformanceColumnManagerOptionsWithIsSelected,
  SkuCatalogApiColumnValue,
  SkuOverviewApiColumnValue,
  SkuOverviewColumnManagerOptionWithIsSelected,
} from './types';

export const getColumnManagerOptionsSkuCatalog = (
  intl: IntlShape,
  showGmroiColumn?: boolean,
  show1PColumns?: boolean
): ColumnManagerOptionWithIsSelected[] => {
  return [
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_PROFITABILITY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_TOTAL_SALES,
      }),
      value: SkuCatalogApiColumnValue.TotalSales,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_PROFITABILITY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_ESTIMATED_GROSS_PROFIT,
      }),
      value: SkuCatalogApiColumnValue.EstimatedGrossProfit,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_PROFITABILITY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_UNITS_SOLD,
      }),
      value: SkuCatalogApiColumnValue.UnitsSold,
      isSelected: true,
    },
    ...(show1PColumns
      ? [
          {
            groupName: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_PROFITABILITY,
            }),
            label: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_SHIPPED_COGS,
            }),
            value: SkuCatalogApiColumnValue.ShippedCogs,
            isSelected: true,
          },
          {
            groupName: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_PROFITABILITY,
            }),
            label: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_SHIPPED_UNITS,
            }),
            value: SkuCatalogApiColumnValue.ShippedUnits,
            isSelected: true,
          },
        ]
      : []),
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_PROFITABILITY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_ESTIMATED_GROSS_MARGIN,
      }),
      value: SkuCatalogApiColumnValue.EstimatedGrossMargin,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_PROFITABILITY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_ESTIMATED_PRE_AD_GROSSMARGIN,
      }),
      value: SkuCatalogApiColumnValue.EstimatedPreAdGrossMargin,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADVERTISED,
      }),
      value: SkuCatalogApiColumnValue.AdvertisedStatus,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ACTIVE_CAMPAIGN_COUNT,
      }),
      value: SkuCatalogApiColumnValue.CampaignCount,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_AD_SALES,
      }),
      value: SkuCatalogApiColumnValue.AdSales,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_AD_SPEND,
      }),
      value: SkuCatalogApiColumnValue.AdSpend,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ACOS,
      }),
      value: SkuCatalogApiColumnValue.ACOS,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ROAS,
      }),
      value: SkuCatalogApiColumnValue.ROAS,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_AD_CONVERSIONS,
      }),
      value: SkuCatalogApiColumnValue.AdConversions,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_CONVERSION_RATE,
      }),
      value: SkuCatalogApiColumnValue.ConversionRate,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_IMPRESSIONS,
      }),
      value: SkuCatalogApiColumnValue.Impressions,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_CLICKS,
      }),
      value: SkuCatalogApiColumnValue.Clicks,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_COST_PER_CLICK,
      }),
      value: SkuCatalogApiColumnValue.CostPerClick,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_CATALOG_TABLE_COLUMN_CLICK_THROUGH_RATE,
      }),
      value: SkuCatalogApiColumnValue.ClickThroughRate,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_PRODUCT,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_AVERAGE_ORDER_PRICE,
      }),
      value: SkuCatalogApiColumnValue.AverageOrderPrice,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_PRODUCT,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_TACOS,
      }),
      value: SkuCatalogApiColumnValue.Tacos,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_COLUMN_HEADER_FEES,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_COLUMN_COGS,
      }),
      value: SkuCatalogApiColumnValue.Cogs,
      isSelected: true,
    },
    ...(!show1PColumns
      ? [
          {
            groupName: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_COLUMN_HEADER_FEES,
            }),
            label: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_COLUMN_ESTIMATED_FEES,
            }),
            value: SkuCatalogApiColumnValue.EstimatedFees,
            isSelected: true,
          },
        ]
      : []),
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_COLUMN_HEADER_INVENTORY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_COLUMN_INVENTORY_VALUE,
      }),
      value: SkuCatalogApiColumnValue.InventoryValue,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_COLUMN_HEADER_INVENTORY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_COLUMN_CURRENT_INVENTORY_QUANTITY,
      }),
      value: SkuCatalogApiColumnValue.CurrentInventoryQuantity,
      isSelected: true,
    },
    ...(showGmroiColumn
      ? [
          {
            groupName: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_COLUMN_HEADER_INVENTORY,
            }),
            label: intl.formatMessage({
              id: I18nKey.SKU_CATALOG_TABLE_COLUMN_GMROI,
            }),
            value: SkuCatalogApiColumnValue.GMROI,
            isSelected: true,
          },
          {
            groupName: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_COLUMN_HEADER_INVENTORY,
            }),
            label: intl.formatMessage({
              id: I18nKey.SKU_CATALOG_TABLE_COLUMN_DAYS_IN_INVENTORY,
            }),
            value: SkuCatalogApiColumnValue.DaysInInventory,
            isSelected: true,
          },
        ]
      : []),
  ];
};

export const getColumnManagerOptionsSkuOverview = (
  intl: IntlShape,
  show1PColumns?: boolean
): SkuOverviewColumnManagerOptionWithIsSelected[] => {
  return [
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_PROFITABILITY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_TOTAL_SALES,
      }),
      value: SkuOverviewApiColumnValue.TotalSales,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_PROFITABILITY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_GROSS_PROFIT,
      }),
      value: SkuOverviewApiColumnValue.EstimatedGrossProfit,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_PROFITABILITY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_UNITS_SOLD,
      }),
      value: SkuOverviewApiColumnValue.UnitsSold,
      isSelected: true,
    },
    ...(show1PColumns
      ? [
          {
            groupName: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_PROFITABILITY,
            }),
            label: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_SHIPPED_COGS,
            }),
            value: SkuOverviewApiColumnValue.ShippedCogs,
            isSelected: true,
          },
          {
            groupName: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_PROFITABILITY,
            }),
            label: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_CATALOG_TABLE_SHIPPED_UNITS,
            }),
            value: SkuOverviewApiColumnValue.ShippedUnits,
            isSelected: true,
          },
        ]
      : []),
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_PROFITABILITY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_GROSS_MARGIN,
      }),
      value: SkuOverviewApiColumnValue.EstimatedGrossMargin,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_PROFITABILITY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_PRE_AD_GROSS_MARGIN,
      }),
      value: SkuOverviewApiColumnValue.EstimatedPreAdGrossMargin,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_AD_SALES,
      }),
      value: SkuOverviewApiColumnValue.AdSales,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_AD_SPEND,
      }),
      value: SkuOverviewApiColumnValue.AdSpend,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_ACOS,
      }),
      value: SkuOverviewApiColumnValue.ACOS,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_OVERVIEW_TABLE_COLUMN_ROAS,
      }),
      value: SkuOverviewApiColumnValue.ROAS,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_OVERVIEW_TABLE_COLUMN_AD_CONVERSIONS,
      }),
      value: SkuOverviewApiColumnValue.AdConversions,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_CONVERSION_RATE,
      }),
      value: SkuOverviewApiColumnValue.ConversionRate,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_OVERVIEW_TABLE_COLUMN_IMPRESSIONS,
      }),
      value: SkuOverviewApiColumnValue.Impressions,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_OVERVIEW_TABLE_COLUMN_CLICKS,
      }),
      value: SkuOverviewApiColumnValue.Clicks,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_OVERVIEW_TABLE_COLUMN_COST_PER_CLICK,
      }),
      value: SkuOverviewApiColumnValue.CostPerClick,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_OVERVIEW_TABLE_COLUMN_CLICK_THROUGH_RATE,
      }),
      value: SkuOverviewApiColumnValue.ClickThroughRate,
      isSelected: false,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_PRODUCT,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_AVERAGE_ORDER_PRICE,
      }),
      value: SkuOverviewApiColumnValue.AverageOrderPrice,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_PRODUCT,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_TACOS,
      }),
      value: SkuOverviewApiColumnValue.TACOS,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_FEES,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_COGS,
      }),
      value: SkuOverviewApiColumnValue.COGS,
      isSelected: true,
    },
    ...(!show1PColumns
      ? [
          {
            groupName: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_FEES,
            }),
            label: intl.formatMessage({
              id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_FEES,
            }),
            value: SkuOverviewApiColumnValue.EstimatedFees,
            isSelected: true,
          },
        ]
      : []),
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_INVENTORY,
      }),
      label: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_INVENTORY_VALUE,
      }),
      value: SkuOverviewApiColumnValue.InventoryValue,
      isSelected: true,
    },
    {
      groupName: intl.formatMessage({
        id: I18nKey.PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_INVENTORY,
      }),
      label: intl.formatMessage({
        id: I18nKey.SKU_OVERVIEW_TABLE_COLUMN_CURRENT_INVENTORY_QUANTITY,
      }),
      value: SkuOverviewApiColumnValue.CurrentInventoryQuantity,
      isSelected: true,
    },
  ];
};

export const getColumnManagerOptionsSkuAdPerformance = (
  intl: IntlShape
): SkuAdPerformanceColumnManagerOptionsWithIsSelected[] => [
  {
    groupName: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_DETAILS,
    }),
    label: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CAMPAIGN,
    }),
    value: SkuAdPerformanceApiColumnValue.CampaignName,
    isSelected: false,
  },
  {
    groupName: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_DETAILS,
    }),
    label: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_ADGROUP,
    }),
    value: SkuAdPerformanceApiColumnValue.AdGroupName,
    isSelected: false,
  },
  {
    groupName: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_DETAILS,
    }),
    label: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CAMPAIGN_TYPE,
    }),
    value: SkuAdPerformanceApiColumnValue.CampaignType,
    isSelected: true,
  },
  {
    groupName: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_DETAILS,
    }),
    label: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_STATUS,
    }),
    value: SkuAdPerformanceApiColumnValue.AdStatus,
    isSelected: true,
  },
  {
    groupName: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_PERFORMANCE,
    }),
    label: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_IMPRESSIONS,
    }),
    value: SkuAdPerformanceApiColumnValue.Impressions,
    isSelected: true,
  },
  {
    groupName: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_PERFORMANCE,
    }),
    label: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CLICKS,
    }),
    value: SkuAdPerformanceApiColumnValue.Clicks,
    isSelected: true,
  },
  {
    groupName: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_PERFORMANCE,
    }),
    label: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_SALES,
    }),
    value: SkuAdPerformanceApiColumnValue.AdSales,
    isSelected: true,
  },
  {
    groupName: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_PERFORMANCE,
    }),
    label: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_SPEND,
    }),
    value: SkuAdPerformanceApiColumnValue.AdSpend,
    isSelected: true,
  },
  {
    groupName: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_PERFORMANCE,
    }),
    label: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_ACOS,
    }),
    value: SkuAdPerformanceApiColumnValue.ACOS,
    isSelected: true,
  },
  {
    groupName: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_PERFORMANCE,
    }),
    label: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_CONVERSIONS,
    }),
    value: SkuAdPerformanceApiColumnValue.AdConversions,
    isSelected: false,
  },
  {
    groupName: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_PERFORMANCE,
    }),
    label: intl.formatMessage({
      id: I18nKey.PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CONVERSION_RATE,
    }),
    value: SkuAdPerformanceApiColumnValue.ConversionRate,
    isSelected: false,
  },
];
