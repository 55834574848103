import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../lib/types/SalesChannels';

export const WALMART_COUNTRY = 'US';

export enum WalmartSCSModalScreens {
  AdDataSyncBeginProcess = 'AdDataSyncBeginProcess',
  AdDataSyncSentEmailSuccessfully = 'AdDataSyncSentEmailSuccessfully',
  ProductDataSyncForm = 'ProductDataSyncForm',
  ProductDataSyncSuccess = 'ProductDataSyncSuccess',
}

export enum AmazonSCSModalScreens {
  ConnectAds = 'ConnectAds',
  ConnectProducts = 'ConnectProducts',
  SelectAdsMerchantCountries = 'SelectAdsMerchantCountries',
  SelectProductsMerchantCountries = 'SelectProductsMerchantCountries',
  UnavailableAdsMerchants = 'UnavailableAdsMerchants',
  UnavailableProductsMerchants = 'UnavailableProductsMerchants',
  AlreadyConnectedAdsMerchants = 'AlreadyConnectedAdsMerchants',
  AlreadyConnectedProductsMerchants = 'AlreadyConnectedProductsMerchants',
  ConnectionSuccess = 'ConnectionSuccess',
  SelectAdsProfile = 'SelectAdsProfile',
}

export enum SellerType {
  '1P' = '1P',
  '3P' = '3P',
}

export enum EntityType {
  Ads = 'ads',
  Products = 'products',
}

export const sellerTypeOptions = [
  { value: SellerType['3P'], label: SellerType['3P'] },
  { value: SellerType['1P'], label: SellerType['1P'] },
];

export const getSalesChannelId = (isAmazon: boolean) =>
  isAmazon ? AMAZON_SALES_CHANNEL_ID : WALMART_SALES_CHANNEL_ID;
