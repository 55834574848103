import classNames from 'classnames';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Icon,
  IconSize,
  LockShieldIcon,
  TextInput,
  TextInputState,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../../lib/types/I18nKey';
import { AuthorizeWalmartModalOldProps, isEmailValid } from './';

export const ModalContent: React.FC<
  Omit<AuthorizeWalmartModalOldProps, 'goBack'>
> = ({ famClient, accountId, authorize }) => {
  const intl = useIntl();
  const [
    HEADER,
    DESCRIPTION,
    EMIAL_ADDR_LABEL,
    EXAMPLE_EMAIL_ID,
    SEND_AUTH_BTN,
    VALIDATION_ERROR_MESSAGE,
    API_ERROR_MESSAGE,
  ] = [
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_HEADER_OLD,
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_DESCRIPTION_OLD,
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_EMIAL_ADDR_LABEL_OLD,
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_EXAMPLE_EMAIL_ID_OLD,
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_SEND_AUTH_BTN_OLD,
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_EMAIL_VALIDATION_ERROR_MESSAGE_OLD,
    I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_API_ERROR_MESSAGE_OLD,
  ].map((id) => intl.formatMessage({ id }));

  const [emailId, setEmailId] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [showApiError, setShowApiError] = useState<boolean>(false);
  const [authorizeBtnState, setAuthorizeBtnState] = useState<ButtonState>(
    ButtonState.Disabled
  );
  const [inputState, setInputState] = useState<TextInputState>(
    TextInputState.Default
  );

  const onChange = (email: string) => {
    setInputState(TextInputState.Default);
    setShowError(false);
    setEmailId(email);
    if (email !== '' && isEmailValid(email)) {
      setAuthorizeBtnState(ButtonState.Enabled);
    } else {
      setAuthorizeBtnState(ButtonState.Disabled);
    }
  };

  const onBlur = () => {
    if (emailId === '' || (emailId !== '' && isEmailValid(emailId))) {
      setShowError(false);
    } else {
      setShowError(true);
      setInputState(TextInputState.Error);
    }
  };

  const onAuthorize = () => {
    setShowApiError(false);
    setAuthorizeBtnState(ButtonState.Loading);
    if (emailId !== '' && isEmailValid(emailId)) {
      famClient
        .sendInitialWalmartSyncEmail(accountId, emailId)
        .then(() => {
          authorize();
        })
        .catch(() => {
          setShowApiError(true);
          setTimeout(() => setShowApiError(false), 5000);
        });
    }
    setAuthorizeBtnState(ButtonState.Enabled);
    setEmailId('');
  };

  return (
    <div className="text-left text-base text-grey-900 mt-16">
      <div
        className={classNames(
          'bg-purple-100 flex flex-col items-center',
          'px-12 py-16 rounded mx-8 mt-16 mb-24'
        )}
      >
        {showApiError && (
          <p className="flex bg-yellow-200 rounded w-full font-bold items-center justify-center h-28">
            {API_ERROR_MESSAGE}
          </p>
        )}
        <div
          className={classNames(
            'flex items-center justify-center',
            'bg-flash-modal-purple-magenta-gradient',
            'w-56 h-56 rounded-full mt-8'
          )}
        >
          <Icon
            svg={LockShieldIcon}
            size={IconSize.Large}
            className="text-white"
          />
        </div>
        <p className="font-semibold mt-16 text-lg">{HEADER}</p>
        <p className="mt-16 text-base text-center">{DESCRIPTION}</p>
        <p className="mt-40 mb-12 font-bold">{EMIAL_ADDR_LABEL}</p>
        <TextInput
          placeholder={EXAMPLE_EMAIL_ID}
          inputState={inputState}
          value={emailId}
          className="block text-center w-full"
          onChange={onChange}
          onBlur={onBlur}
        />
        <p className="text-orange-600 h-20">
          {showError && VALIDATION_ERROR_MESSAGE}
        </p>
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Primary}
          state={authorizeBtnState}
          label={SEND_AUTH_BTN}
          onClick={onAuthorize}
          className="w-full mt-4"
          dataTestId={`flash1_walmart_ads_auth_form_send_form`}
        />
      </div>
    </div>
  );
};
