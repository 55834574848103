import { Factory } from 'fishery';
import { SkuCatalogGmroiResponse } from '../../types/SKUSharedTypes';

export const GMROIReseponse = Factory.define<SkuCatalogGmroiResponse>(() => ({
  skuCatalogGmroiList: [
    {
      productCatalogId: '2b5dfcb9-c08b-5637-b327-7fba64814236',
      gmroi: 4.9,
      daysInInventory: 50,
    },
    {
      productCatalogId: 'd478cc75-ea37-5dc3-b6f1-37ef8e5ac6d1',
      gmroi: 4.9,
      daysInInventory: 50,
    },
    {
      productCatalogId: '446cf137-cdce-5025-a853-338221ffa543',
      gmroi: 4.9,
      daysInInventory: 50,
    },
    {
      productCatalogId: '2ff03aa6-02a8-5297-8a7f-8e13f6769ae8',
      gmroi: 4.9,
      daysInInventory: 50,
    },
    {
      productCatalogId: '40628bb1-043f-539b-942d-58b1f8903ebe',
      gmroi: 4.9,
      daysInInventory: 50,
    },
  ],
}));
