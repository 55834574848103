import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  ProductAdDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { getPerformanceMetricValue } from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) =>
  makeCurrencyColumn<ProductAdDetails>((data) => {
    const campaignStartDate = data.adItemDetails.startDate
      ? DateTime.fromFormat(data.adItemDetails.startDate, REQUEST_DATE_FORMAT)
      : undefined;

    const performanceMetricValue = getPerformanceMetricValue(
      props.selectedEndDate,
      campaignStartDate,
      data.adItemPerformance.adSalesDirect?.amount
        ? Number(data.adItemPerformance.adSalesDirect?.amount)
        : undefined
    );

    return isNil(performanceMetricValue)
      ? undefined
      : {
          amount: performanceMetricValue,
          currency:
            getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
            data.adItemPerformance.adSalesDirect?.currency ||
            CURRENCY_CODE,
        };
  })(props);
RowCellElement.displayName = 'RowCellElement';

export const directAdSalesColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: SV_ADS_API_COLUMN_NAME.DirectAdSales,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_AD_SALES,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const directAdSalesFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    SV_ADS_API_COLUMN_NAME.DirectAdSales,
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_AD_SALES,
    currency,
    isValidNumber(0)
  );
