import { useNavigate } from 'react-router-dom';
import {
  Alignment,
  Icon,
  IconSize,
  Modal,
  Placement,
  PopOutIcon,
  TextLink,
  TextLinkSize,
  Tooltip,
  TooltipSize,
} from '@teikametrics/tm-design-system';
import { useIntl } from 'react-intl';

import I18nKey from '../../lib/types/I18nKey';
import { NavPaths } from '../../NavPaths';
import NotificationList from './NotificationList';
import { Notification } from './types';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../lib/utilities/intercom';
import { useEffect } from 'react';

interface NotificationPanelProps {
  notifications: Notification[];
  onReadAll: () => void;
  onClose: () => void;
}

const NotificationPanel: React.FC<NotificationPanelProps> = ({
  notifications,
  onReadAll,
  onClose,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [TITLE, MARK_ALL_READ, VIEW_INBOX] = [
    I18nKey.NOTIFICATIONS_PANEL_TITLE,
    I18nKey.NOTIFICATIONS_PANEL_MARK_ALL_READ,
    I18nKey.NOTIFICATIONS_PANEL_VIEW_INBOX,
  ].map((id) => intl.formatMessage({ id }));

  const handleViewAll = () => {
    onClose();
    navigate(NavPaths.Notifications);
  };

  useEffect(() => {
    hideIntercomLauncher();
    return () => showIntercomLauncher();
  }, []);

  return (
    <Modal
      showModal
      slideOutModal
      hidePadding
      contentHeight="h-full"
      secondaryAction={{
        label: '',
        onClick: onClose,
      }}
    >
      <div className="flex flex-col justify-between h-full w-512 overflow-hidden">
        <div
          className={`flex items-center flex-shrink-0 gap-8
          h-48 w-full px-12 border-solid border-b border-grey-200`}
        >
          <div
            className={`flex items-center justify-between flex-1 text-center`}
          >
            <span className="text-grey-900 text-base font-medium leading-none">
              {TITLE}
            </span>

            <TextLink
              textLabel={MARK_ALL_READ}
              size={TextLinkSize.Small}
              dataTestId="read-all-link"
              onClick={onReadAll}
            />
          </div>
        </div>

        <NotificationList
          notifications={notifications}
          action={{
            classNames: 'w-24 h-24',
            element: (
              <Tooltip
                content={VIEW_INBOX}
                tooltipSize={TooltipSize.Small}
                position={{
                  placement: Placement.Left,
                  alignment: Alignment.Center,
                }}
                hideArrow
              >
                <Icon
                  svg={PopOutIcon}
                  size={IconSize.Small}
                  dataTestId="view-all-icon"
                  onClick={handleViewAll}
                />
              </Tooltip>
            ),
          }}
          onViewAll={handleViewAll}
        />
      </div>
    </Modal>
  );
};

NotificationPanel.displayName = 'NotificationPanel';

export default NotificationPanel;
