export enum NavPathsTypes {
  Users = 'Users',
  UserEdit = 'UserEdit',
  InviteUser = 'InviteUser',
  Company = 'Company',
  Billing = 'Billing',
  UserProfile = 'UserProfile',
  MarketIntelligenceDashboard = 'MarketIntelligenceDashboard',
  MarketIntelligenceTrackedTerms = 'MarketIntelligenceTrackedTerms',
  MarketIntelligenceMarketInsightsDashboard = 'MarketIntelligenceMarketInsightsDashboard',
  SalesChannels = 'SalesChannels',
  AoWalmartSponsoredProductsCampaigns = 'AoWalmartSponsoredProductsCampaigns',
  AoWalmartSponsoredProductsAdGroup = 'AoWalmartSponsoredProductsAdGroup',
  AoWalmartSponsoredProductsProductAd = 'AoWalmartSponsoredProductsProductAd',
  AoWalmartSponsoredBrandsProductAd = 'AoWalmartSponsoredBrandsProductAd',
  AoWalmartSponsoredProductsTarget = 'AoWalmartSponsoredProductsTarget',
  AoWalmartSponsoredVideosCampaigns = 'AoWalmartSponsoredVideosCampaigns',
  AoWalmartSponsoredVideosAdGroups = 'AoWalmartSponsoredVideosAdGroups',
  AoWalmartSponsoredVideosTargets = 'AoWalmartSponsoredVideosTargets',
  AoAmazonSponsoredProductsCampaigns = 'AoAmazonSponsoredProductsCampaigns',
  AoAmazonSponsoredProductsAdGroup = 'AoAmazonSponsoredProductsAdGroup',
  AoAmazonSponsoredProductsProductAds = 'AoAmazonSponsoredProductsProductAds',
  AoAmazonSponsoredProductsTarget = 'AoAmazonSponsoredProductsTarget',
  AoAmazonSponsoredProductsKeywordTargets = 'AoAmazonSponsoredProductsKeywrodTargets',
  AoAmazonSponsoredProductsKeywordTargetsChatGPT = 'AoAmazonSponsoredProductsKeywordTargetsChatGPT',
  AoWalmartSponsoredProductsKeywordTargetsChatGPT = 'AoWalmartSponsoredProductsKeywordTargetsChatGPT',
  AoAmazonSponsoredBrandsProductsTarget = 'AoAmazonSponsoredBrandsProductsTarget',
  AoAmazonSponsoredBrandsCampaigns = 'AoAmazonSponsoredBrandsCampaigns',
  AoAmazonSponsoredBrandsKeywordTargets = 'AoAmazonSponsoredBrandsKeywordTargets',
  AoAmazonSponsoredDisplayCampaigns = 'AoAmazonSponsoredDisplayCampaigns',
  AoAmazonSponsoredDisplayTargets = 'AoAmazonSponsoredDisplayTargets',
  AoAmazonSponsoredDisplayProductAds = 'AoAmazonSponsoredDisplayProductAds',
  AoAmazonSponsoredBrandsSBAds = 'AoAmazonSponsoredBrandsSBAds',
  AoWalmartSponsoredVideoAdGroup = 'AoWalmartSponsoredVideoAdGroup',
  AoWalmartSponsoredVideoKeywordTargets = 'AoWalmartSponsoredVideoKeywordTargets',
  AdvertisingManager = 'AdvertisingManager',
  AOAdsManager = 'AOAdsManager',
  KeywordActions = 'KeywordActions',
  Recommendations = 'Recommendations',
  AoAmazonSponsoredProductsProductsTarget = 'AoAmazonSponsoredProductsProductsTarget',
  InventoryOptimization = 'InventoryOptimization',
  InventoryOptimizationInsights = 'InventoryOptimizationInsights',
  InventoryOptimizationInsightsDetails = 'InventoryOptimizationInsightsDetails',
  InventoryOptimizationDashboard = 'InventoryOptimizationDashboard',
  InventoryDashboardUnsoldInventoryDetails = 'InventoryDashboardUnsoldInventoryDetails',
  InventoryOptimizationDemandForecasting = 'InventoryOptimizationDemandForecasting',
  InventoryOptimizationHiiveClearance = 'InventoryOptimizationHiiveClearance',
  InventoryOptimizationCreateYourQuery = 'InventoryOptimizationCreateYourQuery',
  IOSku = 'IOSku',
  IOSkuSeller = 'IOSkuSeller',
  IOSkuVendor = 'IOSkuVendor',
  IOSkuDetailsSeller = 'IOSkuDetailsSeller',
  IOSkuDetails = 'IOSkuDetails',
  IOSkuDetailsVendor = 'IOSkuDetailsVendor',
  IODashboard = 'IODashboard',
  AIPoweredProductSettingsV2 = 'AIPoweredProductSettingsV2',
  AIProductGroupSettings = 'AIProductGroupSettings',
  Compass = 'Compass',
  BIMainNavigation = 'BIMainNavigation',
  BusinessIntelligence = 'BusinessIntelligence',
  UploadCustomData = 'UploadCustomData',
  Logout = 'Logout',
  SwitchAccount = 'SwitchAccount',
  EventLog = 'EventLog',
  Recommendation = 'Recommendation',
  RecommendationAutomatedBidding = 'RecommendationAutomatedBidding',
  RecommendationEnterCogs = 'RecommendationEnterCogs',
  CampaignFlexibility = 'CampaignFlexibility',
  Campaign = 'Campaign',
  BudgetInsights = 'BudgetInsights',
  Notifications = 'Notifications',
  KeywordsTargets = 'KeywordsTargets',
  KeywordTargetsDashboard = 'KeywordTargetsDashboard',
  KeywordSettings = 'KeywordSettings',
  InventoryDashboardActiveInventory = 'InventoryDashboardActiveInventory',
  InventoryDashboardOOS = 'InventoryDashboardOOS',
  BulkActions = 'BulkActions',
  AoDashboard = 'AoDashboard',
  BrandAndCompetitors = 'BrandAndCompetitors',
}

export const NavPaths: Record<NavPathsTypes, string> = {
  [NavPathsTypes.Users]: '/account/users',
  [NavPathsTypes.UserEdit]: '/account/users/edit',
  [NavPathsTypes.InviteUser]: '/account/users/invite',
  [NavPathsTypes.Company]: '/account/company',
  [NavPathsTypes.Billing]: '/account/billing',
  [NavPathsTypes.UserProfile]: '/user-profile',
  [NavPathsTypes.SwitchAccount]: '/switch_account',
  [NavPathsTypes.Logout]: '/logout',
  [NavPathsTypes.MarketIntelligenceDashboard]: '/market-intelligence',
  [NavPathsTypes.MarketIntelligenceTrackedTerms]:
    '/market-intelligence/tracked-terms',
  [NavPathsTypes.MarketIntelligenceMarketInsightsDashboard]:
    '/market-intelligence/share-of-voice',
  [NavPathsTypes.SalesChannels]: '/account/sales-channels',
  [NavPathsTypes.AoDashboard]: '/ads-optimization/dashboard',
  [NavPathsTypes.AdvertisingManager]: 'ads-manager/*',
  [NavPathsTypes.AOAdsManager]: '/ads-optimization/ads-manager',
  [NavPathsTypes.KeywordActions]: '/ads-optimization/keywords',
  [NavPathsTypes.KeywordsTargets]: '/ads-optimization/keyword-target-actions',
  [NavPathsTypes.KeywordTargetsDashboard]: 'keyword-target-actions/*',
  [NavPathsTypes.KeywordSettings]:
    '/ads-optimization/keyword-target-actions/settings',
  [NavPathsTypes.Recommendations]: 'keywords/*',
  [NavPathsTypes.AoWalmartSponsoredProductsCampaigns]:
    '/ads-optimization/ads-manager/walmart/sponsored-products/campaigns',
  [NavPathsTypes.AoWalmartSponsoredProductsAdGroup]:
    '/ads-optimization/ads-manager/walmart/sponsored-products/ad-groups',
  [NavPathsTypes.AoWalmartSponsoredProductsProductAd]:
    '/ads-optimization/ads-manager/walmart/sponsored-products/product-ads',
  [NavPathsTypes.AoWalmartSponsoredBrandsProductAd]:
    '/ads-optimization/ads-manager/walmart/search-brand-amplifier/product-ads',
  [NavPathsTypes.AoWalmartSponsoredProductsTarget]:
    '/ads-optimization/ads-manager/walmart/sponsored-products/keyword-targets',
  [NavPathsTypes.AoWalmartSponsoredVideosCampaigns]:
    '/ads-optimization/ads-manager/walmart/sponsored-campaigns/campaigns',
  [NavPathsTypes.AoWalmartSponsoredVideosAdGroups]:
    '/ads-optimization/ads-manager/walmart/sponsored-campaigns/ad-groups',
  [NavPathsTypes.AoWalmartSponsoredVideosTargets]:
    '/ads-optimization/ads-manager/walmart/sponsored-campaigns/keyword-targets',
  [NavPathsTypes.AoAmazonSponsoredProductsCampaigns]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/campaigns',
  [NavPathsTypes.AoAmazonSponsoredProductsAdGroup]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/ad-groups',
  [NavPathsTypes.AoAmazonSponsoredProductsProductAds]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/product-ads',
  [NavPathsTypes.AoAmazonSponsoredBrandsSBAds]:
    '/ads-optimization/ads-manager/amazon/sponsored-brands/sb-ads',
  [NavPathsTypes.AoAmazonSponsoredProductsTarget]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets',
  [NavPathsTypes.AoAmazonSponsoredBrandsProductsTarget]:
    '/ads-optimization/ads-manager/amazon/sponsored-brands/product-targets',
  [NavPathsTypes.AoAmazonSponsoredProductsProductsTarget]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/product-targets',
  [NavPathsTypes.AoAmazonSponsoredProductsKeywordTargets]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets',
  [NavPathsTypes.AoWalmartSponsoredProductsKeywordTargetsChatGPT]:
    '/ads-optimization/ads-manager/walmart/sponsored-products/keyword-targets/create-chatgpt',
  [NavPathsTypes.AoAmazonSponsoredProductsKeywordTargetsChatGPT]:
    '/ads-optimization/ads-manager/amazon/sponsored-products/keyword-targets/create-chatgpt',
  [NavPathsTypes.AoAmazonSponsoredBrandsCampaigns]:
    '/ads-optimization/ads-manager/amazon/sponsored-brands/campaigns',
  [NavPathsTypes.AoAmazonSponsoredBrandsKeywordTargets]:
    '/ads-optimization/ads-manager/amazon/sponsored-brands/keyword-targets',
  [NavPathsTypes.AoAmazonSponsoredDisplayTargets]:
    '/ads-optimization/ads-manager/amazon/sponsored-display/targets',
  [NavPathsTypes.AoAmazonSponsoredDisplayCampaigns]:
    '/ads-optimization/ads-manager/amazon/sponsored-display/campaigns',
  [NavPathsTypes.AoAmazonSponsoredDisplayProductAds]:
    '/ads-optimization/ads-manager/amazon/sponsored-display/product-ads',
  [NavPathsTypes.AoWalmartSponsoredVideoAdGroup]:
    '/ads-optimization/ads-manager/walmart/sponsored-videos/ad-groups',
  [NavPathsTypes.AoWalmartSponsoredVideoKeywordTargets]:
    '/ads-optimization/ads-manager/walmart/sponsored-videos/keyword-targets',
  [NavPathsTypes.InventoryOptimization]: '/inventory-optimization',
  [NavPathsTypes.InventoryOptimizationInsights]:
    '/inventory-optimization/inventory-insights',
  [NavPathsTypes.InventoryOptimizationInsightsDetails]:
    '/inventory-optimization/inventory-insights/details',
  [NavPathsTypes.InventoryOptimizationDashboard]:
    '/inventory-optimization/inventory-dashboard',
  [NavPathsTypes.InventoryDashboardUnsoldInventoryDetails]:
    '/inventory-optimization/inventory-dashboard/unsoldl90',
  [NavPathsTypes.InventoryOptimizationDemandForecasting]:
    '/inventory-optimization/demand-forecasting',
  [NavPathsTypes.InventoryOptimizationHiiveClearance]:
    '/inventory-optimization/hiive-clearance',
  [NavPathsTypes.InventoryOptimizationCreateYourQuery]:
    '/inventory-optimization/inventory-insights/create-your-query',
  [NavPathsTypes.IOSku]: '/products/catalog',
  [NavPathsTypes.IOSkuDetails]: '/products/catalog/*',
  [NavPathsTypes.IOSkuSeller]: '/products/catalog/seller',
  [NavPathsTypes.IOSkuVendor]: '/products/catalog/vendor',
  [NavPathsTypes.IOSkuDetailsSeller]: '/products/catalog/seller/*',
  [NavPathsTypes.IOSkuDetailsVendor]: '/products/catalog/vendor/*',
  [NavPathsTypes.IODashboard]: '/products/dashboard',
  [NavPathsTypes.AIPoweredProductSettingsV2]: '/ai-powered/v2/ai-settings/',
  [NavPathsTypes.AIProductGroupSettings]:
    '/ai-powered/v2/ai-settings/product-group',
  [NavPathsTypes.Compass]: '/compass',
  [NavPathsTypes.BIMainNavigation]: '/main-navigation-business-intelligence',
  [NavPathsTypes.BusinessIntelligence]: '/business-intelligence',
  [NavPathsTypes.CampaignFlexibility]: '/campaign-creator',
  [NavPathsTypes.Campaign]: '/campaign',
  [NavPathsTypes.UploadCustomData]: '/upload-custom-data',
  [NavPathsTypes.EventLog]: '/account/event-log',
  [NavPathsTypes.Recommendation]: '/recommendations',
  [NavPathsTypes.RecommendationAutomatedBidding]:
    '/recommendations/auto-bidding',
  [NavPathsTypes.RecommendationEnterCogs]: '/recommendations/enter-cogs',
  [NavPathsTypes.BudgetInsights]: '/budget-insights',
  [NavPathsTypes.Notifications]: '/notifications',
  [NavPathsTypes.InventoryDashboardActiveInventory]:
    '/inventory-optimization/inventory-dashboard/inventory-health',
  [NavPathsTypes.InventoryDashboardOOS]:
    '/inventory-optimization/inventory-dashboard/estimated-missed-sales',
  [NavPathsTypes.BulkActions]: '/bulk-actions',
  [NavPathsTypes.BrandAndCompetitors]: '/products/brands-and-competitors',
};

export const PathsWithBackButton = [
  NavPathsTypes.UserEdit,
  NavPathsTypes.InviteUser,
].map((path) => NavPaths[path]);

export const viewOnlyUnsafePaths = [
  NavPaths.CampaignFlexibility,
  NavPaths.KeywordsTargets,
  NavPaths.Recommendation,
  NavPaths.InventoryOptimizationCreateYourQuery,
];
