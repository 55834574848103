import {
  AO_DEFAULT_MAX_FRACTION_DIGITS,
  CheckboxCheckedState,
  DisplayExpression2Props,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';
import isNil from 'lodash/isNil';
import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import {
  TableSelectSettings,
  WithTable,
} from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeExpressionV2Column } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupStatus,
  AdType,
  CampaignStatus,
  EMPTY_STRING,
  ExpressionType,
  FlywheelSalesChannel,
  MerchantCountryCode,
  TargetStatus as TargetStatusValues,
  TargetTextExpressionTypeViewV2,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { BulkEditModalElement } from '../bulkEditModal';
import { ADS_MANAGER_TARGETS_TABLE_ID } from '../ducks/types';
import {
  EXPRESSION_TYPE_TO_CONDITIONAL_TEXT_I18NKEY_MAPPER,
  EXPRESSION_TYPE_TO_I18NKEY_MAPPER_WITHOUT_EXPRESSION_VALUE,
  TableDataAdsManager,
} from '../types';
import {
  TARGETS_API_COLUMN_NAME,
  TargetStatus,
  TargetStatusOptions,
  getCheckBoxLabelStateForTargets,
} from '../utils';

type BulkEditMenuValue =
  | typeof TARGETS_API_COLUMN_NAME.TargetStatus
  | typeof TARGETS_API_COLUMN_NAME.Bid;

interface VisibilityCriteria {
  readonly adTypes: AdType[];
  readonly salesChannel: FlywheelSalesChannel[];
  readonly isNegativeKeyword?: boolean;
}

export interface BulkEditMenuOption {
  readonly label: string;
  readonly value: BulkEditMenuValue;
  readonly elements: BulkEditModalElement[];
  readonly visibilityCriteria: VisibilityCriteria;
}

export const ADTYPES_ARRAY_SB_SBV: readonly AdType[] = [
  AdType.SponsoredBrands,
  AdType.SponsoredBrandsVideo,
];

const getTableSettings = ({ tableState }: WithTable<TargetsDetails>) => {
  return tableSelectors.getSelectInfoSelector()(
    tableState,
    ADS_MANAGER_TARGETS_TABLE_ID
  );
};

const getState = (data: TargetsDetails) => {
  if (data.channelSettings.status === TargetStatusValues.Archived) {
    return String(data.channelSettings.status);
  }
  if (data.targetDetails.adGroupStatus === AdGroupStatus.Archived) {
    return String(data.targetDetails.adGroupStatus);
  }
  if (data.targetDetails.campaignStatus === CampaignStatus.Archived) {
    return String(data.targetDetails.campaignStatus);
  }
  return EMPTY_STRING;
};

const onSelectChange = (onChange: () => void, target: TargetsDetails) => {
  if (getState(target) !== TargetStatusOptions.Archived.value) {
    onChange();
  }
};

const getPriceValue = (
  intl: IntlShape,
  expressionType: ExpressionType,
  currency: string,
  value?: string
) => {
  if (isNil(value)) {
    return intl.formatMessage({
      id: I18nKey.GENERIC_EM_DASH,
    });
  }
  if (
    [
      ExpressionType.AsinPriceGreaterThan,
      ExpressionType.AsinPriceLessThan,
    ].includes(expressionType)
  ) {
    return intl.formatNumber(parseFloat(value), {
      currency,
      maximumFractionDigits: AO_DEFAULT_MAX_FRACTION_DIGITS,
      style: 'currency',
    });
  }

  return `
      ${intl.formatNumber(parseFloat(value.split('-')[0]), {
        currency,
        maximumFractionDigits: AO_DEFAULT_MAX_FRACTION_DIGITS,
        style: 'currency',
      })} - ${intl.formatNumber(parseFloat(value.split('-')[1]), {
    currency,
    maximumFractionDigits: AO_DEFAULT_MAX_FRACTION_DIGITS,
    style: 'currency',
  })}
    `;
};

const isExpressionOfType = (
  type: ExpressionType,
  types: ExpressionType[]
): boolean => {
  return types.includes(type);
};

const getHeaderValue = (
  expressionType: ExpressionType,
  intl: IntlShape
): string | undefined => {
  const types = [
    ExpressionType.Purchases,
    ExpressionType.Views,
    ExpressionType.Audience,
  ];

  if (isExpressionOfType(expressionType, types)) {
    return intl.formatMessage({
      id: EXPRESSION_TYPE_TO_I18NKEY_MAPPER_WITHOUT_EXPRESSION_VALUE[
        expressionType
      ],
    });
  }
  return undefined;
};

const parseValue = (value: any): any => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const getRowLabel = (
  childViewType: ExpressionType,
  intl: IntlShape
): string => {
  const expressionLabelI18nKey =
    EXPRESSION_TYPE_TO_I18NKEY_MAPPER_WITHOUT_EXPRESSION_VALUE[childViewType] ||
    childViewType;

  const conditionalText = EXPRESSION_TYPE_TO_CONDITIONAL_TEXT_I18NKEY_MAPPER[
    childViewType
  ]
    ? ' ' +
      intl.formatMessage({
        id: EXPRESSION_TYPE_TO_CONDITIONAL_TEXT_I18NKEY_MAPPER[childViewType],
      })
    : '';

  return (expressionLabelI18nKey as any) !== childViewType
    ? intl.formatMessage({ id: expressionLabelI18nKey }) + conditionalText
    : childViewType;
};

const getRowValue = (
  childViewType: ExpressionType,
  childViewValue: string | undefined,
  merchantCountry: MerchantCountryCode,
  intl: IntlShape
): string => {
  const priceTypes = [
    ExpressionType.AsinPriceBetween,
    ExpressionType.ASIN_PRICE_BETWEEN,
    ExpressionType.AsinPriceGreaterThan,
    ExpressionType.ASIN_PRICE_GREATER_THAN,
    ExpressionType.AsinPriceLessThan,
    ExpressionType.ASIN_PRICE_LESS_THAN,
  ];

  const specialTypes = [
    ExpressionType.QueryHighRelMatches,
    ExpressionType.QUERY_HIGH_REL_MATCHES,
    ExpressionType.QueryBroadRelMatches,
    ExpressionType.QUERY_BROAD_REL_MATCHES,
    ExpressionType.AsinSubstituteRelated,
    ExpressionType.ASIN_SUBSTITUTE_RELATED,
    ExpressionType.ASIN_ACCESSORY_RELATED,
    ExpressionType.AsinAccessoryRelated,
    ExpressionType.ExactProduct,
    ExpressionType.SimilarProduct,
    ExpressionType.RelatedProduct,
  ];

  if (isExpressionOfType(childViewType, priceTypes)) {
    return getPriceValue(
      intl,
      childViewType,
      getCurrencyCodeFromMerchantCountryCode(merchantCountry) ?? '',
      childViewValue
    );
  } else if (isExpressionOfType(childViewType, specialTypes)) {
    return getRowLabel(childViewType, intl);
  }

  const starTypes = [
    ExpressionType.ASIN_REVIEW_RATING_LESS_THAN,
    ExpressionType.ASIN_REVIEW_RATING_GREATER_THAN,
    ExpressionType.ASIN_REVIEW_RATING_BETWEEN,
    ExpressionType.AsinReviewRatingBetween,
    ExpressionType.AsinReviewRatingGreaterThan,
    ExpressionType.AsinReviewRatingLessThan,
  ];

  if (isExpressionOfType(childViewType, starTypes)) {
    return intl.formatMessage({
      id: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_POST_FIX_STARS,
    });
  }

  if (childViewType === ExpressionType.ASIN_SAME_AS) {
    return childViewValue?.toUpperCase() ?? '';
  }

  return childViewValue ?? '';
};

const getTargetingExpressionV2CellProps = (
  intl: IntlShape,
  merchantCountry: MerchantCountryCode,
  viewsTargetTextV2?: TargetTextExpressionTypeViewV2[]
): DisplayExpression2Props | undefined => {
  if (!viewsTargetTextV2 || viewsTargetTextV2.length === 0) return;
  const { value } = viewsTargetTextV2[0];

  const typesForParsing = [
    ExpressionType.Purchases,
    ExpressionType.Views,
    ExpressionType.Audience,
  ];
  let parsedValue = value;

  if (isExpressionOfType(value[0].type, typesForParsing) && value[0].value) {
    parsedValue = parseValue(value[0].value);
  }

  return {
    header: getHeaderValue(value[0].type, intl),
    rows: parsedValue.map((childView) => {
      return {
        label: getRowLabel(childView.type, intl),
        value: getRowValue(
          childView.type,
          childView.value,
          merchantCountry,
          intl
        ),
      };
    }),
  };
};

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = ({
  targetDetails,
  targetId,
  targetPerformance,
  channelSettings,
  isEditMode,
  salesChannel,
  merchantCountry,
}) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const selectSettings = useSelector<
    WithTable<TargetsDetails>,
    TableSelectSettings
  >(getTableSettings);

  const targetData: TargetsDetails = {
    targetDetails,
    targetId,
    channelSettings,
    targetPerformance,
  };

  const isRowSelected = selectSettings.rows.includes(targetId);

  if (isEditMode) {
    return makeExpressionV2Column<TargetsDetails>(
      ({ targetDetails: { viewsTargetTextV2 } }) =>
        getTargetingExpressionV2CellProps(
          intl,
          merchantCountry,
          viewsTargetTextV2 as TargetTextExpressionTypeViewV2[]
        ),
      (data: TargetsDetails) => ({
        checked: isRowSelected
          ? CheckboxCheckedState.Checked
          : CheckboxCheckedState.Unchecked,
        state: getCheckBoxLabelStateForTargets(salesChannel, getState(data)),
        onClick: () => {
          onSelectChange(() => {
            dispatch(
              tableActions.toggleSelectedItem({
                tableId: ADS_MANAGER_TARGETS_TABLE_ID,
                rowId: data.targetId,
              })
            );
          }, data);
        },
      }),
      true
    )(targetData);
  }

  return makeExpressionV2Column<TargetsDetails>(
    ({ targetDetails: { viewsTargetTextV2 } }) =>
      getTargetingExpressionV2CellProps(
        intl,
        merchantCountry,
        viewsTargetTextV2 as TargetTextExpressionTypeViewV2[]
      ),
    undefined,
    true
  )(targetData);
};
RowCellElement.displayName = 'RowCellElement';

export const targetingExpressionColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.TargetingExpression,
  overrideColumnName: TARGETS_API_COLUMN_NAME.ExpressionType,
  isSortable: true,
  RowCellElement,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION,
  gridColumnWidth: '660px',
};

export const targetingExpressionFilter = createStringDataFieldFilter(
  TARGETS_API_COLUMN_NAME.TargetText,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION,
  isValidString()
);

export const WALMART_TARGET_STATUS_OPTIONS_TO_I18KEY_MAPPER: Record<
  TargetStatus,
  I18nKey
> = {
  [TargetStatus.Enabled]: I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ENABLED,
  [TargetStatus.Paused]: I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_PAUSED,
  [TargetStatus.Archived]:
    I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ARCHIVED,
  [TargetStatus.Deleted]: I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_DELETED,
};

export const AMAZON_TARGET_STATUS_OPTIONS_TO_I18KEY_MAPPER: Record<
  TargetStatus,
  I18nKey
> = {
  [TargetStatus.Enabled]:
    I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ENABLED_PRODUCTTARGETS,
  [TargetStatus.Paused]:
    I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_PAUSED_PRODUCTTARGETS,
  [TargetStatus.Archived]:
    I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ARCHIVED_PRODUCTTARGETS,
  [TargetStatus.Deleted]: I18nKey.ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_DELETED,
};
