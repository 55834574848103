import noop from 'lodash/noop';
import React, { createContext, useState } from 'react';

import { IdToken } from '@auth0/auth0-react';

import { createBillingApiClient } from '../../lib/clients/BillingApiClient';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import {
  AccountStatusResponse,
  BillingEstimateResponseType,
} from '../../lib/types/Billing';
import { MetadataResponse, Role, UserDetails } from '../../lib/types/Fam';
import { getCurrentAccountPermissions, isAOSubscribed } from './selectors';
import { PlatformDataWithCountries } from '../../lib/types/MISharedTypes';
import {
  AsyncRequest,
  AsyncRequestCompleted,
  AsyncRequestFailed,
  AsyncRequestNotStarted,
  asyncRequestIsComplete,
  asyncRequestIsFailed,
} from '../../lib/utilities/asyncRequest';

export interface UserInfo {
  idToken?: IdToken;
  userDetails?: UserDetails;
  currentAccountId?: string;
  knockToken?: string;
}

export interface UserContextState {
  userInfo: UserInfo;
  billingInfo?: AccountStatusResponse;
  billingEstimate?: BillingEstimateResponseType;
  aoSubscribed?: boolean;
  updateUserInfo: (info: UserInfo) => void;
  updateBillingInfo: (info: AsyncRequest<AccountStatusResponse>) => void;
  updateBillingEstimate: (
    estimate: AsyncRequest<BillingEstimateResponseType>
  ) => void;
  refreshUserContext: () => void;
  isBillingLoaded: boolean;
  platformsList: PlatformDataWithCountries[];
  updatePlatformList: (
    platformDataWithCountries: PlatformDataWithCountries[]
  ) => void;
  updateAccountMetadata: (metadata: MetadataResponse[]) => void;
  accountMetadata?: MetadataResponse[];
}

export interface TeikaUserProviderProps {
  children: JSX.Element;
}

const initialState = {
  userInfo: {},
  aoSubscribed: false,
  updateUserInfo: noop,
  updateBillingInfo: noop,
  updateBillingEstimate: noop,
  refreshUserContext: noop,
  billingInfo: undefined,
  billingEstimate: undefined,
  isBillingLoaded: false,
  platformsList: [],
  updatePlatformList: noop,
  updateAccountMetadata: noop,
  accountMetadata: [],
};

const UserContext = createContext<UserContextState>(initialState);
UserContext.displayName = 'UserContext';

const { Provider } = UserContext;

const UserProvider: React.FC<TeikaUserProviderProps> = ({ children }) => {
  const [userInfo, updateUserInfo] = useState<UserInfo>({});
  const [billingInfo, updateBillingInfo] = useState<
    AsyncRequest<AccountStatusResponse>
  >(AsyncRequestNotStarted());

  const [billingEstimate, updateBillingEstimate] = useState<
    AsyncRequest<BillingEstimateResponseType>
  >(AsyncRequestNotStarted());

  const [platformsList, updatePlatformList] = useState<
    PlatformDataWithCountries[]
  >([]);
  const aoSubscribed = isAOSubscribed(userInfo.userDetails);

  const isBillingLoaded =
    asyncRequestIsComplete(billingInfo) || asyncRequestIsFailed(billingInfo);

  const billingData = asyncRequestIsComplete(billingInfo)
    ? billingInfo.result
    : undefined;

  const billingEstimateData = asyncRequestIsComplete(billingEstimate)
    ? billingEstimate.result
    : undefined;

  const [accountMetadata, updateAccountMetadata] = useState<MetadataResponse[]>(
    []
  );

  const refreshUserContext = () => {
    if (userInfo.idToken && userInfo.currentAccountId) {
      const accountPermissions = getCurrentAccountPermissions(
        userInfo.userDetails
      );
      const currentRole = accountPermissions?.role || Role.ACCOUNT_OWNER;

      const billingApiClient = createBillingApiClient(userInfo.idToken!);
      const famApiClient = createFAMApiClient(userInfo.idToken!);
      if (currentRole !== Role.VIEW_ONLY) {
        billingApiClient
          .getAccountStatus(userInfo.currentAccountId)
          .then((billingInfo) => {
            updateBillingInfo(AsyncRequestCompleted(billingInfo));
          })
          .catch(() => {
            updateBillingInfo(AsyncRequestFailed(undefined));
          });

        billingApiClient
          .getBillingEstimate(userInfo.currentAccountId)
          .then((billingEstimate) => {
            updateBillingEstimate(AsyncRequestCompleted(billingEstimate));
          })
          .catch(() => {
            updateBillingInfo(AsyncRequestFailed(undefined));
          });
      }
      famApiClient.getUserDetails().then((userDetails) =>
        updateUserInfo({
          ...userInfo,
          userDetails,
        })
      );

      famApiClient
        .getAllMetadataTags(userInfo.currentAccountId)
        .then((resp) => {
          updateAccountMetadata(resp);
        });
    }
  };

  return (
    <Provider
      value={{
        userInfo,
        aoSubscribed,
        updateUserInfo,
        updateBillingInfo,
        updateBillingEstimate,
        billingInfo: billingData,
        billingEstimate: billingEstimateData,
        refreshUserContext,
        isBillingLoaded,
        platformsList,
        updatePlatformList,
        accountMetadata,
        updateAccountMetadata,
      }}
    >
      {children}
    </Provider>
  );
};
UserProvider.displayName = 'UserProvider';
export { UserContext, UserProvider };
