import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import {
  makeCurrencyColumn,
  makeNumericInputColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  AdType,
  CampaignAdFormat,
  CampaignDetails,
  CampaignStatus,
} from '../../../../../lib/types/AOSharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { MoneyWithAmountInString } from '../../../../../lib/types/Money';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import {
  ADGROUP_DEFAULT_BID_FALLBACK_PLACEHOLDER_VALUE,
  AdLevelI8nKeyMapper,
  CURRENCY_CODE,
  NumericValueConfig,
  TableDataAdsManager,
} from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getAutomationDisabledTooltipMessage,
  getTooltipContentForArchivedEntity,
  isCampaignAdFormatProductCollectionOrSpotlight,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
  isInputValueNumber,
  isValidFlywheelSetting,
} from '../utils';
import {
  getCurrencyCodeFromMerchantCountryCode,
  getCurrencySymbol,
  getCurrencySymbolFromMerchantCountryCode,
} from '../../../../../lib/utilities/currency';

const checkValidFlywheelSettings = (
  props: CampaignDetails & TableDataAdsManager,
  _currentBidAutomationStatus?: string,
  maxBid?: string,
  minBid?: string,
  currentMacsTarget?: string
) =>
  isValidFlywheelSetting(
    props.flywheelSettings,
    {
      minBid,
      maxBid,
      macsTarget: currentMacsTarget,
    },
    maxBid
  );

const checkAutomationDisabledForMerchant = (
  props: CampaignDetails & TableDataAdsManager
) =>
  !props.aiEnabled &&
  !props.merchantsWithAutomationEnabled?.includes(
    props.campaignDetails?.merchantCountryId ?? ''
  );

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) => {
  const { isEditMode } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);
  const bidConstraintsData = useBidConstraintsContext();

  const { targetingType } = props.campaignDetails;

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );
  let updatedMaxBid = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MaxBid
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const maxBidConfig = (() => {
    const constraints = getBidConstraint(
      bidConstraintsData.constraints,
      getCurrentAdType(
        props.selectedAdType,
        props.campaignDetails.campaignAdFormat
      ),
      props.salesChannel,
      CAMPAIGNS_API_COLUMN_NAME.MaxBid,
      props.merchantCountry,
      targetingType,
      props.merchantType
    );

    return {
      min: constraints.minBid,
      default: constraints.defaultMaxBid,
      max: constraints.maxBid,
    };
  })();

  const merchantCurrency = getCurrencySymbolFromMerchantCountryCode(
    props.merchantCountry
  );

  const currencySymbol = getCurrencySymbol(
    merchantCurrency || props.flywheelSettings.minBid?.currency || CURRENCY_CODE
  );
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(
    props.merchantCountry
  );

  const existingValue = getExistingValue(
    props.flywheelSettings?.maxBid?.amount
  );

  let maxBid = getCurrentMaxBid(updatedMaxBid, existingValue);

  const isInputEdited = existingValue !== maxBid;

  const [value, setValue] = useState<string>(maxBid);

  useEffect(() => {
    if (isEditModeOrIsValueEdited(isEditMode, updatedMaxBid, value)) {
      setValue(maxBid);
    }
  }, [isEditMode, currentPage, updatedMaxBid]);

  const currentMinBid = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MinBid
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentBidAutomationStatus = useSelector<
    WithTable<CampaignDetails>,
    string
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      props.campaignId,
      CAMPAIGNS_API_COLUMN_NAME.AutomationStatus
    )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentMacsTarget = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MACSTarget
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const updatedCurrentMinBidValue =
    currentMinBid || props.flywheelSettings.minBid?.amount || '';

  const updateCellValue = (updateValue: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        rowId: props.campaignId,
        columnName: CAMPAIGNS_API_COLUMN_NAME.MaxBid,
        value: updateValue,
        existingValue,
        numericValue: true,
      })
    );
  };

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [props.campaignId]
  );

  const automationDisabledForMerchant =
    checkAutomationDisabledForMerchant(props);

  const isValidSettings = useMemo(
    () =>
      checkValidFlywheelSettings(
        props,
        currentBidAutomationStatus,
        maxBid,
        currentMinBid,
        currentMacsTarget
      ),
    [
      props,
      currentBidAutomationStatus,
      maxBid,
      currentMinBid,
      currentMacsTarget,
    ]
  );

  if (isEditMode) {
    let maxBidNumericInputState: NumericInputState;
    let isMaxBidValid = !isMaxBidInvalid(
      value,
      updatedCurrentMinBidValue,
      maxBidConfig.max,
      maxBidConfig.min,
      existingValue
    );

    maxBidNumericInputState = getNumericInputState(
      automationDisabledForMerchant,
      isInputEdited,
      isMaxBidValid,
      props.channelSettings.status
    );

    if (!isValidSettings) {
      maxBidNumericInputState = NumericInputState.Error;
      isMaxBidValid = false;
    }

    const onMaxBidInputChange = (inputValue: string) => {
      if (isInputValueNumber(inputValue)) {
        setValue(inputValue);
        debouncedUpdateCellValue(inputValue);
      }
    };

    const isCampaignArchived = isCampaignStatusArchived(
      props.channelSettings.status
    );

    const onInputFocus = () => setFocussed(true);
    const onInputBlur = () => {
      if (!isEmpty(value)) {
        debouncedUpdateCellValue.cancel();
        const formattedValue = parseFloat(value).toFixed(
          MONETARY_FRACTION_DIGITS
        );
        setValue(formattedValue);
        updateCellValue(formattedValue);
      }
      setFocussed(false);
    };

    const placeholderValue =
      maxBidConfig.default || ADGROUP_DEFAULT_BID_FALLBACK_PLACEHOLDER_VALUE;

    const maxBidNumericInputProps: NumericInputProps & TypedTooltipProps = {
      value,
      state: maxBidNumericInputState,
      prependedElement: currencySymbol,
      onChange: onMaxBidInputChange,
      isDirty: isDirtyCheck(value, existingValue),
      tooltipContent: getTooltipContent({
        automationDisabledForMerchant,
        intl,
        isCampaignArchived,
        isMaxBidValid,
        currentMinBid: updatedCurrentMinBidValue,
        value,
        maxBidConfig,
        currencySymbol,
        isValueDirty: isDirtyCheck(value, existingValue),
        isValidSetting: isValidSettings,
      }),
      controlledTooltip: getTooltipState(
        isCampaignArchived,
        focussed,
        maxBidNumericInputState
      ),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus: onInputFocus,
      onInputBlur: onInputBlur,
      acceptOnlyPositiveNumbers: true,
      placeholder: intl.formatNumber(placeholderValue, {
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      }),
      minFractionDigits: MONETARY_FRACTION_DIGITS,
      maxFractionDigits: MONETARY_FRACTION_DIGITS,
      dataTestId: 'max_bid',
    };

    return makeNumericInputColumn(() => maxBidNumericInputProps)(props);
  }

  return makeCurrencyColumn((data: CampaignDetails) =>
    getColumnValue(currencyCode, data.flywheelSettings?.maxBid)
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

const getColumnValue = (
  currencyCode: string | null,
  maxBid?: MoneyWithAmountInString
) =>
  maxBid && {
    amount: Number(maxBid.amount),
    currency: currencyCode || maxBid.currency || CURRENCY_CODE,
  };

const getTooltipState = (
  isCampaignArchived: boolean,
  focussed: boolean,
  maxBidNumericInputState: NumericInputState
) => {
  if (isCampaignArchived) {
    return ControlledTooltip.None;
  } else {
    return focussed && maxBidNumericInputState === NumericInputState.Error
      ? ControlledTooltip.Show
      : ControlledTooltip.Hide;
  }
};

const isDirtyCheck = (value: string, existingValue: string) =>
  value || existingValue
    ? parseFloat(value) !== parseFloat(existingValue)
    : false;

const getCurrentAdType = (
  selectedAdType: AdType,
  campaignAdFormat?: CampaignAdFormat
) =>
  isCampaignAdFormatProductCollectionOrSpotlight(campaignAdFormat)
    ? selectedAdType
    : AdType.SponsoredBrandsVideo;

const getNumericInputState = (
  automationDisabledForMerchant: boolean,
  isInputEdited: boolean,
  isMaxBidValid: boolean,
  campaignStatus?: CampaignStatus
) => {
  if (
    isCampaignStatusArchived(campaignStatus) ||
    automationDisabledForMerchant
  ) {
    return NumericInputState.Disabled;
  } else if (isInputEdited) {
    return isMaxBidValid ? NumericInputState.Default : NumericInputState.Error;
  } else {
    return NumericInputState.Default;
  }
};

const getExistingValue = (maxBid?: string) => maxBid?.toString() || '';

const getCurrentMaxBid = (updatedMaxBid: string, existingValue: string) =>
  updatedMaxBid ?? existingValue;

const isEditModeOrIsValueEdited = (
  isEditMode: boolean,
  updatedDefaultBid: string,
  value: string
) =>
  isEditMode || //Changing between modes
  (!isNil(updatedDefaultBid) && updatedDefaultBid !== value); // Changes done due to bulk update. updatedMinBid is latest, but state variable is not

const getTooltipContent = (args: {
  automationDisabledForMerchant: boolean;
  intl: IntlShape;
  isCampaignArchived: boolean;
  isMaxBidValid: boolean;
  currentMinBid: string;
  value: string;
  maxBidConfig: NumericValueConfig;
  currencySymbol: string;
  isValueDirty: boolean;
  isValidSetting: boolean;
}) => {
  const {
    automationDisabledForMerchant,
    intl,
    isCampaignArchived,
    isMaxBidValid,
    currentMinBid,
    value,
    maxBidConfig,
    currencySymbol,
    isValueDirty,
    isValidSetting,
  } = args;
  if (automationDisabledForMerchant) {
    return getAutomationDisabledTooltipMessage(
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MAX_BID,
      })
    );
  }

  if (isCampaignArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MAX_BID,
      }),
      intl
    );
  } else if (isValueDirty || !isValidSetting) {
    if (
      !isMaxBidValid &&
      currentMinBid &&
      isCurrentValueLessThanRequiredMinValue(Number(currentMinBid), value)
    ) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGNS_MAXBID_SHOULD_BE_GREATER_THAN_MIN_BID,
          })}
        </p>
      );
    } else if (!isMaxBidValid && maxBidConfig.min && maxBidConfig.max) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_BID_BETWEEN,
            },
            {
              minValue: intl.formatNumber(maxBidConfig.min, {
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
              maxValue: intl.formatNumber(maxBidConfig.max, {
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
              currencySymbol,
            }
          )}
        </p>
      );
    } else if (
      !isMaxBidValid &&
      maxBidConfig.max &&
      isCurrentValueGreaterThanRequiredMaxValue(maxBidConfig.max, value)
    ) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_INVALID_MAXBID,
            },
            {
              maxValue: intl.formatNumber(maxBidConfig.max, {
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
              currencySymbol,
            }
          )}
        </p>
      );
    } else if (
      maxBidConfig.min &&
      isCurrentValueLessThanRequiredMinValue(maxBidConfig.min, value)
    ) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_CAMPAIGNS_MAXBID_LESS_THAN_MIN_LIMIT,
            },
            {
              minValue: intl.formatNumber(maxBidConfig.min, {
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
              currencySymbol,
            }
          )}
        </p>
      );
    }
  }
};

export const maxBidColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.MaxBid,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MAX_BID,
  RowCellElement,
  gridColumnWidth: '160px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const maxBidFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.MaxBid,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MAX_BID,
    currency,
    isValidNumber(0),
    true
  );

export function isMaxBidInvalid(
  maxBidValue: string,
  minBid?: string,
  maxMaxBidLimit?: number,
  minBidLimit?: number,
  existingValue?: string
) {
  if (existingValue === '' && maxBidValue === existingValue) {
    return false;
  } else {
    return (
      !maxBidValue ||
      (minBid
        ? isCurrentValueLessThanRequiredMinValue(Number(minBid), maxBidValue)
        : false) ||
      isCurrentValueGreaterThanRequiredMaxValue(maxMaxBidLimit, maxBidValue) ||
      isCurrentValueLessThanRequiredMinValue(minBidLimit, maxBidValue)
    );
  }
}
