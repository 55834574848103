import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import {
  makeCurrencyColumn,
  makeNumericInputColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  AdLevel,
  AdType,
  CampaignTargetingType,
  ChannelSettingsAdgroup,
  DetailAdGroup,
  FlywheelSalesChannel,
  MerchantCountryCode,
} from '../../../../../lib/types/AOSharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import {
  AdLevelI8nKeyMapper,
  CURRENCY_CODE,
  NumericValueConfig,
  TableDataAdsManager,
} from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getTooltipContentForArchivedEntity,
  isAdGroupStatusArchived,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
} from '../utils';
import {
  getCurrencyCodeFromMerchantCountryCode,
  getCurrencySymbolFromMerchantCountryCode,
} from '../../../../../lib/utilities/currency';

interface TooltipContentProps {
  intl: IntlShape;
  isAdGroupArchived: boolean;
  isCurrentValueMoreThanHalfOfDailyBudget: boolean | '' | undefined;
  isCurrentValueMoreThanDailyBudget: boolean | '' | undefined;
  validationConfig: NumericValueConfig;
  currencySymbol: string | undefined;
  isValueInvalid: boolean;
  isInputEdited?: boolean;
}

const getDefaultBid = (
  updatedDefaultBid: string,
  existingValue: string,
  isInputEdited: boolean
) => {
  let defaultBid = updatedDefaultBid ?? existingValue;

  if (isNil(defaultBid) && isInputEdited) {
    defaultBid = '';
  }

  return defaultBid;
};

const isEditModeOrIsValueEdited = (
  isEditMode: boolean,
  updatedDefaultBid: string,
  value: string
) =>
  isEditMode || //Changing between modes
  (!isNil(updatedDefaultBid) && updatedDefaultBid !== value); // Changes done due to bulk update. updatedMinBid is latest, but state variable is not

const getExtraValidationsForAmazon = (
  value: string,
  selectedAdType: AdType,
  campaignDailyBudget: string | undefined,
  channelSettings: ChannelSettingsAdgroup,
  adGroupDetails: DetailAdGroup
) => {
  if (selectedAdType === AdType.SponsoredProducts) {
    return (
      isCurrentValueLessThanRequiredMinValue(
        Number(campaignDailyBudget),
        value
      ) &&
      !isAdGroupStatusArchived(channelSettings.status) &&
      !isCampaignStatusArchived(adGroupDetails.campaignStatus)
    );
  }
  if (selectedAdType === AdType.SponsoredDisplay) {
    return (
      isCurrentValueLessThanRequiredMinValue(
        Number(campaignDailyBudget) / 2,
        value
      ) &&
      !isAdGroupStatusArchived(channelSettings.status) &&
      !isCampaignStatusArchived(adGroupDetails.campaignStatus)
    );
  }
  return (
    !isAdGroupStatusArchived(channelSettings.status) &&
    !isCampaignStatusArchived(adGroupDetails.campaignStatus)
  );
};

const getExtraValidations = (
  salesChannel: FlywheelSalesChannel,
  value: string,
  selectedAdType: AdType,
  campaignDailyBudget: string | undefined,
  channelSettings: ChannelSettingsAdgroup,
  adGroupDetails: DetailAdGroup
) => {
  if (salesChannel === FlywheelSalesChannel.Amazon) {
    return () =>
      getExtraValidationsForAmazon(
        value,
        selectedAdType,
        campaignDailyBudget,
        channelSettings,
        adGroupDetails
      );
  }
};

const getDefaultBidNumericInputState = (
  channelSettings: ChannelSettingsAdgroup,
  adGroupDetails: DetailAdGroup,
  isInputEdited: boolean,
  isDefaultBidValid: boolean
) => {
  if (
    isAdGroupStatusArchived(channelSettings.status) ||
    isCampaignStatusArchived(adGroupDetails.campaignStatus)
  ) {
    return NumericInputState.Disabled;
  }

  if (isInputEdited) {
    return isDefaultBidValid
      ? NumericInputState.Default
      : NumericInputState.Error;
  }
  return NumericInputState.Default;
};

const getTooltipContent = ({
  intl,
  isAdGroupArchived,
  isCurrentValueMoreThanHalfOfDailyBudget,
  isCurrentValueMoreThanDailyBudget,
  validationConfig,
  currencySymbol,
  isValueInvalid,
  isInputEdited,
}: TooltipContentProps) => {
  if (isAdGroupArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.AdGroups],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_AD_GROUP_DEFAULT_BID,
      }),
      intl
    );
  }

  if (isInputEdited && isCurrentValueMoreThanHalfOfDailyBudget) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_DEFAULT_BID,
        })}
      </p>
    );
  }

  if (isInputEdited && isCurrentValueMoreThanDailyBudget) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_DEFAULT_BID_FOR_SP,
        })}
      </p>
    );
  }

  if (isValueInvalid && validationConfig.min && validationConfig.max) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_BID,
          },
          {
            minValue: intl.formatNumber(validationConfig.min, {
              minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              maximumFractionDigits: MONETARY_FRACTION_DIGITS,
            }),
            maxValue: intl.formatNumber(validationConfig.max, {
              minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              maximumFractionDigits: MONETARY_FRACTION_DIGITS,
            }),
            currencySymbol,
          }
        )}
      </p>
    );
  }
};

const getControlledTooltipState = (
  isAdGroupArchived: boolean,
  focussed: boolean,
  defaultBidNumericInputState: NumericInputState
) => {
  if (isAdGroupArchived) {
    return ControlledTooltip.None;
  } else {
    return focussed && defaultBidNumericInputState === NumericInputState.Error
      ? ControlledTooltip.Show
      : ControlledTooltip.Hide;
  }
};

const getPlaceholderText = (
  intl: IntlShape,
  placeHolderValue: number | undefined
) =>
  placeHolderValue
    ? intl.formatNumber(placeHolderValue, {
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
      })
    : undefined;

const getDefaultBidText =
  (currencyCode: string | null) =>
  ({ channelSettings: { defaultBid } }: AdGroupDetails) =>
    defaultBid
      ? {
          amount: Number(defaultBid.amount),
          currency: currencyCode || defaultBid.currency || CURRENCY_CODE,
        }
      : undefined;

const getCurrencyCode = (
  merchantCountry: MerchantCountryCode,
  channelSettings: ChannelSettingsAdgroup
) =>
  getCurrencySymbolFromMerchantCountryCode(merchantCountry) ||
  channelSettings.defaultBid?.currency;

const isValueEditable = (
  isEditMode: boolean,
  validationConfig: NumericValueConfig,
  isEditable?: boolean
) =>
  isEditMode &&
  !isNil(validationConfig.min) &&
  !isNil(validationConfig.max) &&
  isEditable;

const checkIfCurrentValueMoreThanHalfOfDailyBudget = (
  value: string,
  selectedAdType: AdType,
  campaignDailyBudget: string | undefined
) =>
  selectedAdType === AdType.SponsoredDisplay &&
  campaignDailyBudget &&
  !isEmpty(value) &&
  !isCurrentValueLessThanRequiredMinValue(
    Number(campaignDailyBudget) / 2,
    value
  );

const checkIfCurrentValueMoreThanDailyBudget = (
  value: string,
  salesChannel: FlywheelSalesChannel,
  selectedAdType: AdType,
  campaignDailyBudget: string | undefined
) =>
  salesChannel === FlywheelSalesChannel.Amazon &&
  selectedAdType === AdType.SponsoredProducts &&
  campaignDailyBudget &&
  !isEmpty(value) &&
  !isCurrentValueLessThanRequiredMinValue(Number(campaignDailyBudget), value);

const setUpdatedDefaulBidToValue = (
  isEditMode: boolean,
  updatedDefaultBid: string,
  value: string,
  defaultBid: string,
  setValue: Dispatch<SetStateAction<string>>
) => {
  if (
    isEditModeOrIsValueEdited(isEditMode, updatedDefaultBid, value) &&
    parseFloat(defaultBid) !== parseFloat(value)
  ) {
    setValue(defaultBid);
  }
};

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  adGroupId,
  adGroupDetails,
  channelSettings,
  flywheelSettings,
  adGroupPerformance,
  isEditMode,
  selectedAdType,
  merchantCountry,
  salesChannel,
  merchantType,
  pendingFields,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);
  const [isInputEdited, setInputEdited] = useState<boolean>(false);
  const bidConstraintsData = useBidConstraintsContext();

  const currentPage = useSelector<WithTable<AdGroupDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_ADGROUP_TABLE_ID
      )
  );

  const data: AdGroupDetails = {
    adGroupId,
    adGroupDetails,
    channelSettings,
    flywheelSettings,
    adGroupPerformance,
    pendingFields,
  };

  const updatedDefaultBid = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );
  const campaignTargetingType: CampaignTargetingType | undefined = data
    .adGroupDetails.campaignTargetingType as CampaignTargetingType;

  const currencySymbol = getCurrencyCode(merchantCountry, channelSettings);

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  const existingValue = channelSettings.defaultBid?.amount?.toString() || '';

  const validationConfig = useMemo(() => {
    const constrainsts = getBidConstraint(
      bidConstraintsData.constraints,
      selectedAdType,
      salesChannel,
      ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
      merchantCountry,
      campaignTargetingType,
      merchantType,
      adGroupDetails.campaignCostType
    );

    return {
      default: constrainsts.defaultMinBid,
      min: constrainsts.minBid,
      max: constrainsts.maxBid,
    };
  }, [
    selectedAdType,
    salesChannel,
    merchantCountry,
    campaignTargetingType,
    merchantType,
  ]);

  const campaignDailyBudget = adGroupDetails.campaignDailyBudget?.amount;

  const defaultBid = getDefaultBid(
    updatedDefaultBid,
    existingValue,
    isInputEdited
  );

  const [value, setValue] = useState<string>(defaultBid);

  useEffect(() => {
    setUpdatedDefaulBidToValue(
      isEditMode,
      updatedDefaultBid,
      value,
      defaultBid,
      setValue
    );

    if (updatedDefaultBid && updatedDefaultBid !== value) {
      setInputEdited(true);
    }

    return () => {
      if (!isEditMode) {
        setInputEdited(false);
      }
    };
  }, [isEditMode, currentPage, updatedDefaultBid]);

  const onInputFocus = () => setFocussed(true);

  const onInputBlur = () => {
    if (!isEmpty(value)) {
      debouncedUpdateCellValue.cancel();
      const formattedValue = parseFloat(value).toFixed(
        MONETARY_FRACTION_DIGITS
      );
      setValue(formattedValue);
      updateCellValue(formattedValue);
    }
    setFocussed(false);
  };

  const updateCellValue = (newValue: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
        rowId: data.adGroupId,
        columnName: ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
        value: newValue,
        existingValue,
        numericValue: true,
      })
    );
  };

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [data.adGroupId]
  );

  const isAdGroupDefaultBidEditable = isValueEditable(
    isEditMode,
    validationConfig,
    channelSettings.isEditable
  );

  if (isAdGroupDefaultBidEditable) {
    const isdefaultBidValid = isValidDefaultBid(
      value,
      validationConfig.max,
      validationConfig.min,
      getExtraValidations(
        salesChannel,
        value,
        selectedAdType,
        campaignDailyBudget,
        channelSettings,
        adGroupDetails
      )
    );

    const isValueGreaterThanMaxBidValue =
      isCurrentValueGreaterThanRequiredMaxValue(validationConfig.max, value);

    const isValueLessThanMinBidValue = isCurrentValueLessThanRequiredMinValue(
      validationConfig.min,
      value
    );

    const onDefaultBidInputChange = (inputValue: string) => {
      setInputEdited(true);
      setValue(inputValue);
      debouncedUpdateCellValue(inputValue);
    };

    const isAdGroupArchived =
      isAdGroupStatusArchived(channelSettings.status) ||
      isCampaignStatusArchived(adGroupDetails.campaignStatus);

    const PLACEHOLDER_VALUE =
      validationConfig.default || validationConfig.min || validationConfig.max;

    const PLACEHOLDER_TEXT = getPlaceholderText(intl, PLACEHOLDER_VALUE);

    const isValueInvalid =
      isValueLessThanMinBidValue ||
      isValueGreaterThanMaxBidValue ||
      isEmpty(value);

    const isDirtyChange = parseFloat(value) !== parseFloat(existingValue);
    const defaultBidNumericInputState: NumericInputState =
      getDefaultBidNumericInputState(
        channelSettings,
        adGroupDetails,
        isInputEdited && isDirtyChange,
        isdefaultBidValid
      );

    const defaultBidNumericInputProps: NumericInputProps & TypedTooltipProps = {
      value,
      state: defaultBidNumericInputState,
      prependedElement: currencySymbol,
      onInputBlur: onInputBlur,
      onChange: onDefaultBidInputChange,
      isDirty: isDirtyChange,
      tooltipContent: getTooltipContent({
        intl,
        isAdGroupArchived,
        isCurrentValueMoreThanHalfOfDailyBudget:
          checkIfCurrentValueMoreThanHalfOfDailyBudget(
            value,
            selectedAdType,
            campaignDailyBudget
          ),
        isCurrentValueMoreThanDailyBudget:
          checkIfCurrentValueMoreThanDailyBudget(
            value,
            salesChannel,
            selectedAdType,
            campaignDailyBudget
          ),
        validationConfig,
        currencySymbol,
        isValueInvalid,
        isInputEdited: isInputEdited && isDirtyChange,
      }),
      controlledTooltip: getControlledTooltipState(
        isAdGroupArchived,
        focussed,
        defaultBidNumericInputState
      ),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus: onInputFocus,
      acceptOnlyPositiveNumbers: true,
      placeholder: PLACEHOLDER_TEXT,
      minFractionDigits: MONETARY_FRACTION_DIGITS,
      maxFractionDigits: MONETARY_FRACTION_DIGITS,
      dataTestId: 'adgroup_default_bid',
    };

    return makeNumericInputColumn(() => defaultBidNumericInputProps)(data);
  }

  return makeCurrencyColumn<AdGroupDetails>(
    getDefaultBidText(currencyCode),
    pendingFields?.includes(ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid)
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

export const adgroupDefaultBidColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_AD_GROUP_DEFAULT_BID,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const adgroupDefaultBidFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_AD_GROUP_DEFAULT_BID,
    currency,
    isValidNumber(0)
  );

export const isValidDefaultBid = (
  currentBidValue: string,
  maxValue?: number,
  minValue?: number,
  extraValidation?: () => boolean
): boolean => {
  const isValidBid =
    !isEmpty(currentBidValue) &&
    !isNaN(Number(currentBidValue)) &&
    !isCurrentValueGreaterThanRequiredMaxValue(maxValue, currentBidValue) &&
    !isCurrentValueLessThanRequiredMinValue(minValue, currentBidValue);

  if (extraValidation) {
    return isValidBid && extraValidation();
  }

  return isValidBid;
};
