import { ToastProps, Type } from '@teikametrics/tm-design-system';
import { AxiosError } from 'axios';
import { noop } from 'lodash/fp';
import { IntlShape } from 'react-intl';
import { ClientError } from '../../lib/types/HttpErrorCodes';
import I18nKey from '../../lib/types/I18nKey';
import { UNGROUPED } from '../../modules/marketIntelligence/containers/settings/types';
import {
  KeywordCampaign,
  MITargetSegment,
  MI_BASE_URL,
  PublicAPISearchTermsAndGroupsData,
  PublicAPISearchTermsData,
  SearchTermsData,
  SovProduct,
} from '../../lib/types/MISharedTypes';
import { PATHS } from '../../lib/clients/MIApiClient';
import { TargetSegments } from '../../core/campaignFlexibility/types';
import { PaginatedRequest } from '../clients/types';
import { Sort } from '../types/Sort';
import { ParamsFilterSort, buildUrlWithQueryParams } from './buildUrlUtilities';

export const transformSortMIRequest = (
  request: PaginatedRequest
): PaginatedRequest => {
  const getColumnValueForSort = (sort: Sort) =>
    sort.column === 'locale' ? 'country' : sort.column;

  return {
    ...request,
    sorts: request.sorts.map((sort) => {
      return {
        ...sort,
        column:
          sort.column === 'platform'
            ? 'sales_channel'
            : getColumnValueForSort(sort),
      };
    }),
  };
};

//#TODO: Refactor - MI-423
export const getTransformedSearchTermsData = (
  search_terms: PublicAPISearchTermsData[]
): SearchTermsData[] => {
  return search_terms.map((each_search_term: PublicAPISearchTermsData) => ({
    search_term: each_search_term.search_term,
    locale: each_search_term.country,
    created_at: each_search_term.created_at,
    status: each_search_term.status,
    platform: each_search_term.sales_channel,
    is_group: false,
    is_grouped_term: false,
    group_id: UNGROUPED,
    search_term_id: each_search_term.search_term_id,
  }));
};

export const getTransformedSearchTermsAndGroupsData = (
  search_terms_and_groups: PublicAPISearchTermsAndGroupsData[]
): SearchTermsData[] => {
  const data: SearchTermsData[] = [];
  search_terms_and_groups.forEach(
    ({
      group_id,
      search_term_id,
      group_name,
      country,
      group_created_at,
      status,
      sales_channel,
      search_terms,
      search_term,
      searchterm_created_at,
    }: PublicAPISearchTermsAndGroupsData) => {
      if (group_id !== UNGROUPED) {
        const group = {
          search_term: group_name,
          locale: country,
          created_at: group_created_at,
          status,
          platform: sales_channel,
          is_group: true,
          is_grouped_term: false,
          group_name,
          group_id,
          search_term_id,
        };
        const terms = search_terms.map(
          ({
            search_term,
            country,
            searchterm_created_at,
            status,
            sales_channel,
            search_term_id,
          }) => {
            return {
              search_term,
              locale: country,
              created_at: searchterm_created_at,
              status,
              platform: sales_channel,
              is_group: false,
              is_grouped_term: true,
              group_name,
              group_id,
              search_term_id,
            };
          }
        );
        data.push({ ...group, child_search_terms: terms });
        data.push(...terms);
      } else {
        data.push({
          search_term,
          locale: country,
          created_at: searchterm_created_at,
          status,
          platform: sales_channel,
          is_group: false,
          is_grouped_term: false,
          group_name: '',
          group_id,
          search_term_id,
        });
      }
    }
  );
  return data;
};

export const getReducedFilterParams = (
  accumulatedQueryParameters: Record<string, string>,
  currentQueryParam: [string, string | number]
): Record<string, string> => {
  const filteredParams = { ...accumulatedQueryParameters };
  const [currentQueryParamKey, currentQueryParamValue] = currentQueryParam;

  if (currentQueryParamValue) {
    filteredParams[currentQueryParamKey] = currentQueryParamValue.toString();
  }

  return filteredParams;
};

export const exportSearchTermsUrl = (
  ropId: string,
  params: ParamsFilterSort | Record<string, string>
): string =>
  `${MI_BASE_URL}${buildUrlWithQueryParams(
    PATHS.EXPORT_SEARCH_TERMS(ropId),
    params as Record<string, string>
  )}`;

export const getTransformedGroupsData = (
  search_terms_and_groups: PublicAPISearchTermsAndGroupsData[]
): SearchTermsData[] => {
  const data: SearchTermsData[] = [];
  search_terms_and_groups.forEach(
    ({
      group_id,
      search_term_id,
      group_name,
      country,
      group_created_at,
      status,
      sales_channel,
      search_terms,
    }: PublicAPISearchTermsAndGroupsData) => {
      const group = {
        search_term: group_name,
        locale: country,
        created_at: group_created_at,
        status,
        platform: sales_channel,
        is_group: true,
        is_grouped_term: false,
        group_name,
        group_id,
        search_term_id,
      };
      const subRows = search_terms.map(
        ({
          search_term,
          country,
          searchterm_created_at,
          status,
          sales_channel,
          search_term_id,
        }) => {
          return {
            search_term,
            locale: country,
            created_at: searchterm_created_at,
            status,
            platform: sales_channel,
            is_group: false,
            is_grouped_term: true,
            group_name,
            group_id,
            search_term_id,
            subRows: undefined,
          };
        }
      );
      data.push({ ...group, subRows });
    }
  );
  return data;
};

export const exportSearchTermsTemplateUrl = (): string =>
  `${MI_BASE_URL}${PATHS.EXPORT_SEARCH_TERMS_TEMPLATE}`;

export const showErrorToast = (
  error: Error,
  addNotification?: (info: ToastProps) => void,
  intl?: IntlShape,
  defaultMessage?: string
) => {
  if (!addNotification || !intl) return;

  const axiosError = error as AxiosError<any>;
  const message = axiosError?.response?.data?.message;
  const status = axiosError?.response?.status;
  let description = defaultMessage ?? I18nKey.GENERIC_SOMETHING_WENT_WRONG;

  switch (status) {
    case ClientError.HTTP_400_BAD_REQUEST:
    case ClientError.HTTP_413_PAYLOAD_TOO_LARGE:
      description = message ?? description;
      break;
    case ClientError.HTTP_401_UNAUTHORIZED:
      description =
        I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_401;
      break;
    case ClientError.HTTP_403_FORBIDDEN:
      description =
        I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_403;
      break;
    case ClientError.HTTP_404_NOT_FOUND:
      description =
        I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_404;
      break;
    case ClientError.HTTP_500_INTERNAL_SERVER_ERROR:
      description = defaultMessage ?? I18nKey.GENERIC_SOMETHING_WENT_WRONG;
      break;
    case ClientError.HTTP_503_SERVICE_UNAVAILABLE:
      description =
        I18nKey.GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_503;
      break;
    default:
      description = defaultMessage ?? I18nKey.GENERIC_SOMETHING_WENT_WRONG;
  }
  addNotification({
    headline: intl?.formatMessage({
      id: I18nKey.ERROR,
    }),
    description: intl?.formatMessage({ id: description }),
    type: Type.Attention,
    onClose: noop,
  });
};

export const getTransformedProductsData = (products: SovProduct[]) => {
  return products.map((product) => {
    const subRows = (product.sku_children || []).map((skuChild) => ({
      ...skuChild,
      metadata: {
        ...skuChild.metadata,
        parent_sku: product.metadata.sku,
        parent_asin: product.metadata.ext_item_id,
      },
    }));
    return {
      ...product,
      subRows,
    };
  });
};

export const getTransformedTargetSegments = (targetSegments: string[]) => {
  const targetSegmentMap = {
    [MITargetSegment.BRAND]: TargetSegments.Brand,
    [MITargetSegment.COMPETITOR]: TargetSegments.Competitor,
    [MITargetSegment.GENERIC]: TargetSegments.Generic,
    [MITargetSegment.AUTO]: TargetSegments.Auto,
    [MITargetSegment.UNKNOWN]: TargetSegments.Unknown,
  };

  return targetSegments.map(
    (targetSegment) => targetSegmentMap[targetSegment as MITargetSegment]
  );
};

export const getTransformedCampaignData = (campaigns: KeywordCampaign[]) => {
  return campaigns.map((campagin) => {
    return {
      ...campagin,
      subRows: campagin.ad_groups.map((adGroup) => ({
        ...adGroup,
        target_segments: getTransformedTargetSegments(
          adGroup.target_segments ?? []
        ),
        campaign_id: campagin.campaign_id,
        campaign_targeting_type: campagin.campaign_targeting_type,
      })),
    };
  });
};
