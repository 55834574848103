import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Modal,
  SearchInput,
  SearchInputSize,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import first from 'lodash/first';
import isUndefined from 'lodash/isUndefined';
import { useIntl } from 'react-intl';
import { getCurrentAccountFromContext } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { createAOApiClient } from '../../../../lib/clients/AOApiClient';
import { PaginatedResponse } from '../../../../lib/clients/types';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  AdGroupInfoV2,
  CampaignInfoWithAdGroups,
  KeywordAdgroupInfo,
} from '../../../../lib/types/KeywordAction';
import { DEFAULT_CURRENCY } from '../../utils';
import { FetchingData, FetchingDataType } from './fetchignData';
import { AdGroupSelectionV2 } from './adGroupSelectionV2';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../lib/utilities/intercom';

interface AdGroupSelectionSlideoutModalV2Props {
  readonly merchantCountryId: string;
  readonly isOpen: boolean;
  readonly onClose: (_adGroupSelected?: boolean) => void;
  readonly heading: string;
  readonly onApplyChange: (info: KeywordAdgroupInfo) => void;
  readonly defaultAdGroup?: KeywordAdgroupInfo;
  readonly onCountClick?: (adGroup: KeywordAdgroupInfo) => void;
  readonly isNegativeKeyword?: boolean;
}

const MAX_SLIDEOUT_RECORDS = 100;

export const AdGroupSelectionSlideoutModalV2: React.FC<
  AdGroupSelectionSlideoutModalV2Props
> = ({
  merchantCountryId,
  isOpen,
  onClose,
  heading,
  onApplyChange,
  defaultAdGroup,
  onCountClick,
  isNegativeKeyword,
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const { id: accountId } = getCurrentAccountFromContext(userContext)!;

  const intl = useIntl();

  const [searchValue, setSearchValue] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasSearchResults, setHasSearchResults] = useState<boolean>(false);
  const [selectedCampaignWithAdGroup, setSelectedCampaignWithAdGroup] =
    useState<CampaignInfoWithAdGroups | undefined>();

  const aoClient = createAOApiClient(userContext.userInfo.idToken!);
  const [eligibleAdGroups, setEligibleAdGroups] =
    useState<PaginatedResponse<CampaignInfoWithAdGroups>>();
  const [selectedAdGroup, setSelectedAdGroup] = useState<AdGroupInfoV2>();

  useEffect(() => {
    if (defaultAdGroup) {
      const campaign: any = {
        ...defaultAdGroup?.campaign,
        adGroups: [defaultAdGroup],
        selectedAdGroupIds: [defaultAdGroup.id],
      };

      setSelectedCampaignWithAdGroup(campaign);
    }
  }, [defaultAdGroup]);
  const resetSearchingAndLoading = () => {
    setIsLoading(false);
    setSearching(false);
  };

  const fetchEligibleAdGroups = (searchText?: string) => {
    if (merchantCountryId) {
      setHasSearchResults(!!searchText);
      aoClient
        .eligibleAdGroupsV2ForKeywordTargets(
          accountId,
          merchantCountryId,
          DEFAULT_CURRENCY,
          searchText || '',
          MAX_SLIDEOUT_RECORDS,
          undefined,
          isNegativeKeyword
        )
        .then((resp) => {
          setEligibleAdGroups(resp.data);
          resetSearchingAndLoading();
        })
        .catch(() => {
          resetSearchingAndLoading();
        });
    }
  };

  const [
    SEARCH_INPUT_PLACEHOLDER,
    APPLY_LABEL,
    CANCEL_LABEL,
    USE_SEARCH_BAR,
    BACK,
  ] = [
    I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_PLACEHOLDER,
    I18nKey.GENERIC_APPLY,
    I18nKey.GENERIC_CANCEL_LABEL,
    I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_USE_SEARCH_BAR,
    I18nKey.BACK,
  ].map((id) =>
    intl.formatMessage({
      id,
    })
  );

  const SHOWING_MAX_RESULTS = intl.formatMessage(
    {
      id: I18nKey.KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_SHOWING_RESULTS_MAX,
    },
    {
      currentResults: MAX_SLIDEOUT_RECORDS,
      totalResults: eligibleAdGroups?.filteredElements,
    }
  );

  const onSearchInputClear = () => {
    setIsLoading(true);
    setSearchValue('');
    fetchEligibleAdGroups();
  };

  const onSearchButtonClick = (text: string) => {
    setSearching(true);
    fetchEligibleAdGroups(text);
  };

  const onChange = (newValue: string) => {
    if (!!newValue) {
      setSearchValue(newValue);
    } else {
      onSearchInputClear();
    }
  };

  const searchButtonInputProps = {
    label: intl.formatMessage({ id: I18nKey.SEARCH }),
    loading: searching,
    onSearchButtonClick,
    onChange,
    size: ButtonSize.Default,
  };

  const onApplySuggestion = () => {
    onApplyChange({
      ...selectedAdGroup!,
      campaign: selectedCampaignWithAdGroup,
    });
    onClose(true);
  };

  const onCancelSuggestion = () => {
    onClose();
  };

  useEffect(() => {
    if (isOpen && isUndefined(eligibleAdGroups)) {
      fetchEligibleAdGroups();
    }
  }, [isOpen]);

  const showSearchBarText =
    eligibleAdGroups?.filteredElements &&
    eligibleAdGroups?.filteredElements > MAX_SLIDEOUT_RECORDS;

  const searchingOrLoading = searching || isLoading;

  const onAdgroupSelectionChange = (
    adGroup: AdGroupInfoV2,
    campaign: CampaignInfoWithAdGroups,
    checked?: boolean
  ) => {
    if (!checked) {
      setSelectedCampaignWithAdGroup({
        ...campaign,
        selectedAdGroupIds: [
          ...(campaign.selectedAdGroupIds || []),
          adGroup.id,
        ],
      });
    } else {
      setSelectedCampaignWithAdGroup({
        ...campaign,
        selectedAdGroupIds: (campaign.selectedAdGroupIds || []).filter(
          (id) => id !== adGroup.id
        ),
      });
    }
    setSelectedAdGroup(adGroup);
  };

  const selectedAdgroupId = first(
    selectedCampaignWithAdGroup?.selectedAdGroupIds
  );

  const shouldShowDefaultAdGroup =
    !!selectedAdgroupId &&
    selectedAdgroupId === defaultAdGroup?.id &&
    !hasSearchResults;

  const filteredCampaigns = useMemo(() => {
    if (shouldShowDefaultAdGroup) {
      return eligibleAdGroups?.elements
        .map((campaign) => ({
          ...campaign,
          adGroups: campaign.adGroups.filter(
            (adGroup) => adGroup.id !== selectedAdgroupId
          ),
        }))
        .filter((campaign) => campaign.adGroups.length > 0);
    }

    return eligibleAdGroups?.elements;
  }, [eligibleAdGroups, selectedAdgroupId, shouldShowDefaultAdGroup]);

  useEffect(() => {
    hideIntercomLauncher();
    return () => showIntercomLauncher();
  }, []);

  return (
    <Modal
      className="z-50 min-w-640 max-w-640"
      showModal
      slideOutModal={true}
      showOVerlay={true}
      formActionRowClassName="sticky border-t-1 border-grey-200 border-solid py-12 absolute bottom-0 px-12 bg-white"
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      hidePadding={true}
      dataTestId="ad_group_selection_slideout_modal_v2"
    >
      <div className="flex flex-col">
        <div
          className={classNames(
            'flex flex-row',
            'border-solid border-b border-grey-200',
            'p-16'
          )}
        >
          <div className="hover:cursor-pointer" onClick={() => onClose()}>
            <Button
              label={BACK}
              svgIcon={ArrowLeftIcon}
              size={ButtonSize.Medium}
              variant={ButtonVariant.BlackAndWhite}
              className="text-grey-900"
            />
          </div>
          <Typography
            size={TypographySize.lg}
            weight={TypographyWeight.medium}
            className="w-full text-center"
          >
            {heading}
          </Typography>
        </div>
        <div className="p-16 bg-grey-50">
          <SearchInput
            inputClassName={'w-full'}
            value={searchValue}
            size={SearchInputSize.Default}
            onSearchInputClear={onSearchInputClear}
            placeholder={SEARCH_INPUT_PLACEHOLDER}
            dataTestId={'kwa_slideout_search_input'}
            onDebounceChange={undefined}
            searchButtonProps={searchButtonInputProps}
          />
        </div>
        <div className="flex flex-col h-screen overflow-auto overflow-x-hidden pb-192 bg-grey-50 px-16 gap-16">
          {searchingOrLoading ? (
            <FetchingData
              type={searching ? FetchingDataType.search : FetchingDataType.load}
            />
          ) : (
            <>
              <div className="flex flex-col gap-16 pb-20">
                {shouldShowDefaultAdGroup && (
                  <AdGroupSelectionV2
                    campaign={selectedCampaignWithAdGroup!}
                    selectedAdGroupId={selectedAdgroupId}
                    key={selectedCampaignWithAdGroup?.id}
                    onChange={onAdgroupSelectionChange}
                    onCountClick={onCountClick}
                  />
                )}

                {(filteredCampaigns || []).map((eachDestination, index) => (
                  <AdGroupSelectionV2
                    campaign={eachDestination}
                    selectedAdGroupId={selectedAdgroupId}
                    key={eachDestination.id}
                    onChange={onAdgroupSelectionChange}
                    onCountClick={onCountClick}
                  />
                ))}
              </div>
              {showSearchBarText ? (
                <div className="flex flex-col items-center justify-center gap-8 pb-74">
                  <Typography
                    size={TypographySize.sm}
                    weight={TypographyWeight.regular}
                    lineHeight={TypographyLineHeight.none}
                    className="text-grey-900"
                  >
                    {SHOWING_MAX_RESULTS}
                  </Typography>
                  <Typography
                    size={TypographySize.sm}
                    weight={TypographyWeight.regular}
                    lineHeight={TypographyLineHeight.none}
                    className="text-grey-900"
                  >
                    {USE_SEARCH_BAR}
                  </Typography>
                </div>
              ) : null}
            </>
          )}
        </div>
        <div
          className={classNames(
            'absolute bottom-0',
            'bg-white',
            'w-full p-12',
            'border-t-1 border-grey-200 border-solid'
          )}
        >
          <div className="flex gap-8 justify-end">
            <Button
              state={
                searchingOrLoading ? ButtonState.Disabled : ButtonState.Enabled
              }
              label={CANCEL_LABEL}
              onClick={onCancelSuggestion}
              variant={ButtonVariant.BlackAndWhiteBorder}
              size={ButtonSize.Large}
            />
            <Button
              state={
                searchingOrLoading ||
                isUndefined(selectedAdgroupId) ||
                selectedAdgroupId === defaultAdGroup?.id
                  ? ButtonState.Disabled
                  : ButtonState.Enabled
              }
              label={APPLY_LABEL}
              onClick={onApplySuggestion}
              variant={ButtonVariant.Primary}
              size={ButtonSize.Large}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

AdGroupSelectionSlideoutModalV2.displayName = 'AdGroupSelectionSlideoutModalV2';
