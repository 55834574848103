import { Factory } from 'fishery';

import { ProductDataSyncRequestType } from '../../types/Fam';

export default Factory.define<ProductDataSyncRequestType>(() => ({
  id: '71c28ff9-c40b-40c0-a2ea-09d9accbcd3',
  secretKey: 'a886305c|03c040cea531c50da5c3c8f9',
  sellerId: '71c28ff9-c40b-40c0-a2ea-09d9accbcd4',
  api: '3P',
  country: 'US',
}));
