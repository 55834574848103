import { AdvertisingType } from './AOSharedTypes';
import { MoneyWithAmountInString } from './Money';
import { SkuDetailsFields as BacnSkuDetails } from './SKUSharedTypes';

export interface AIOnboardingRequest {
  readonly version: string;
  readonly merchantCountries?: MerchantCountry[];
}

export enum AIOnboardingStatus {
  DataAvailable = 'data_available',
  DataUnavailable = 'data_unavailable',
  Error = 'error',
  InsufficientData = 'data_insufficient',
  Preparing = 'preparing',
  SubmittingToChannel = 'submitting_to_channel',
  RunComplete = 'run-complete',
}

export enum AdvertisementFieldsFilter {
  AdSales = 'adSales',
  AdSpend = 'adSpend',
  Acos = 'acos',
  AdSalesTotal = 'adSalesTotal',
  AcosTotal = 'acosTotal',
}

export enum AIPoweredSettingsFilter {
  DailyBudget = 'dailyBudget',
  AcosTarget = 'acosTarget',
}

export enum RunStatus {
  QUEUED = 'queued',
  CANCELLED = 'cancelled',
  ORGANIZER_STARTED = 'organizer_started',
  ORGANIZER_SUCCEEDED = 'organizer_succeeded',
  ORAGANIZER_FAILED = 'organizer_failed',
  TRE_STARTED = 'tre_started',
  TRE_SUCCEEDED = 'tre_succeeded',
  TRE_FAILED = 'tre_failed',
  BIDDER_STARTED = 'bidder_started',
  BIDDER_SUCCEEDED = 'bidder_succeeded',
  BIDDER_FAILED = 'bidder_failed',
  AO_STARTED = 'ao_started',
  AO_SUBMISSION_SUCCEEDED = 'ao_submission_succeeded',
  AO_SUBMISSION_PARTIAL_SUCCEEDED = 'ao_submission_partial_succeeded',
  AO_SUBMISSION_FAILED = 'ao_submission_failed',
  CAMPAIGN_CREATION_APPROVAL_REQUIRED = 'campaign_creation_approval_required',
  CAMPAIGN_CREATION_APPROVAL_GRANTED = 'campaign_creation_approval_granted',
  PAUSE_NON_TEIKA_CAMPAIGNS_STARTED = 'pause_non_teika_campaigns_started',
  PAUSE_NON_TEIKA_CAMPAIGNS_SUCCEEDED = 'pause_non_teika_campaigns_succeeded',
  PAUSE_NON_TEIKA_CAMPAIGNS_FAILED = 'pause_non_teika_campaigns_failed',
  ENABLE_AI_SUBSCRIPTION_STARTED = 'enable_ai_subscription_started',
  ENABLE_AI_SUBSCRIPTION_SUCCEEDED = 'enable_ai_subscription_succeeded',
  ENABLE_AI_SUBSCRIPTION_FAILED = 'enable_ai_subscription_failed',
  CHECK_AO_CREATE_STATUS_STARTED = 'check_ao_create_status_started',
  CHECK_AO_CREATE_STATUS_SUCCEEDED = 'check_ao_create_status_succeeded',
  CHECK_AO_CREATE_STATUS_FAILED = 'check_ao_create_status_failed',
  COMPLIANCE_CHECK_STARTED = 'compliance_check_started',
  COMPLIANCE_CHECK_SUCCEEDED = 'compliance_check_succeeded',
  COMPLIANCE_CHECK_FAILED = 'compliance_check_failed',
  TRE_OPTIMIZATION_STARTED = 'tre_optimization_started',
  TRE_OPTIMIZATION_SUCCEEDED = 'tre_optimization_succeeded',
  TRE_OPTIMIZATION_PARTIAL_FAILED = 'tre_optimization_partial_failed',
  TRE_OPTIMIZATION_FAILED = 'tre_optimization_failed',
  RUN_NOT_PRESENT = 'run_not_present',
}

export enum FeedbackStatus {
  Good = 'good',
  Low = 'low',
}

export interface AIOnboardingResponse {
  elements: AIOnboardingData[];
  readonly code: AIOnboardingStatus;
  readonly totalAdvertisedProducts?: number;
  readonly totalCatalogProducts?: number;
  readonly totalDailyBudget?: DailyBudget;
  readonly totalCampaigns?: number;
  readonly totalTargets?: number;
}
export interface AIOnboardingData {
  readonly salesChannelId: string;
  readonly salesChannelName: string;
  readonly merchantCountryId: string;
  merchantCountryName?: string;
  readonly status: AIOnboardingStatus;
  countryCode?: string;
  readonly onboardingData: OnboardingData;
}

export interface OnboardingData {
  readonly advertisedProducts: number;
  readonly catalogProducts: number;
  readonly campaigns: Campaigns;
  readonly targets: Targets;
  readonly dailyBudget: DailyBudget;
  readonly runId: string;
}

export interface DailyBudget {
  readonly value: string;
  readonly currency: string;
}

export interface Targets {
  readonly keywords: Campaigns;
}

export interface Campaigns {
  readonly count: number;
}
export interface MerchantCountry {
  readonly merchantCountryId: string;
  readonly salesChannelId?: string;
}

export interface OnboardMerchantCountries {
  readonly runId: string;
  readonly salesChannelId: string;
  readonly merchantCountryId: string;
}

export interface OnboardingRequest {
  readonly merchantCountries?: OnboardMerchantCountries[];
}

export interface OnboardingResponse {
  readonly elements: OnboardingRunData[];
  readonly onboardedData: OnboardedSummaryData;
}

export interface OnboardingRunData {
  readonly salesChannelId: string;
  readonly merchantCountryId: string;
  readonly run: Run;
}

export interface OnboardedSummaryData {
  readonly totalAdvertisedProducts: number;
  readonly totalCampaigns: number;
  readonly totalAdGroups: number;
  readonly totalTargets: number;
}

export interface Run {
  readonly id: string;
  readonly status: RunStatus;
}

export interface AIProductSettingSummaryRequest {
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly merchantType: string;
  readonly advertisementFields: AdvertisementFieldsFilter[];
  readonly aiPoweredSettings: AIPoweredSettingsFilter[];
}

export interface AIProductSettingSummaryResponse {
  readonly adSalesTotal: MoneyWithAmountInString;
  readonly adSpend: MoneyWithAmountInString;
  readonly acosTotal: number;
  readonly dailyBudget: MoneyWithAmountInString;
  readonly acosTarget: number;
  readonly totalCatalogProducts: number;
  readonly brandTotal: number;
  readonly competitorTotal: number;
}

export interface AIProductSettingsRequest {
  readonly merchantCountryId: string;
  readonly salesChannelId: string;
  readonly merchantType: string;
  readonly advertisementFields: AdvertisementFieldsFilter[];
  readonly aiPoweredSettings: AIPoweredSettingsFilter[];
}

export enum AIProductSettingsEditableFields {
  DailyBudget = 'dailyBudget',
  AcosTarget = 'acosTarget',
  Name = 'name',
}

export type AIProductSettingsResponse = {
  readonly skuDetailsFields: SkuDetailsFields;
  readonly advertisementFields: AdvertisementFields;
  readonly performanceMetrics: PerformanceMetrics;
  readonly aiPoweredSettings: AIPoweredSettings;
  readonly aiTags: AITags;
  readonly pendingFields?: AIProductSettingsEditableFields[];
};

export interface AITags {
  readonly brand: number;
  readonly competitor: number;
}

export interface AIPoweredSettings {
  readonly dailyBudget: MoneyWithAmountInString;
  readonly acosTarget: number;
  readonly dailyBudgetStatus: FeedbackStatus;
  readonly acosTargetStatus: FeedbackStatus;
}

export interface AdvertisementFields {
  readonly campaignIds: string[];
  readonly adGroupIds: string[];
}
export interface PerformanceMetrics {
  readonly adSalesTotal: MoneyWithAmountInString;
  readonly adSpend: MoneyWithAmountInString;
  readonly acosTotal: number;
}

export interface SkuDetailsFields {
  readonly sku: string;
  readonly name: string;
  readonly imageUrl: string;
  readonly extItemDetails: ExtItemDetails[];
  readonly productId: string;
  productGroupId?: string;
}

export interface ExtItemDetails {
  readonly extItemId: string;
  readonly extItemType: string;
  readonly salesChannelId: string;
  readonly salesChannelName: string;
}

export interface AIUpdateSkusData {
  readonly productId: string;
  readonly campaignIds?: string[];
  readonly adGroupIds?: string[];
  readonly sku: string;
  readonly currentDailyBudget?: number;
  readonly newDailyBudget?: number;
  readonly currentAcosTarget?: number;
  readonly newAcosTarget?: number;
}

export interface AIUpdateSkuTargetsRequest {
  readonly merchantCountryId: string;
  readonly updates?: AIUpdateSkusData[];
}

export enum UpdateSkuTargetsStatus {
  Success = 'success',
  Error = 'error',
}

export interface AIUpdateSkuTargetsResponse {
  readonly status: UpdateSkuTargetsStatus;
  readonly errors: Error[];
}

export interface Error {
  readonly code: string;
  readonly message: string;
}

export interface AIOnboardingRunRequest {
  readonly merchantCountries: OnboardMerchantCountries[];
}

export interface AIOnboardingRunResponse {
  readonly elements: AIOnboardingRunData[];
}

export interface AIOnboardingRunData {
  readonly merchantCountryId: string;
  readonly run: Run;
}

export enum TagType {
  Brand = 'brand',
  Competitor = 'competitor',
}

export const TagTypeRecord: Record<string, TagType> = {
  [TagType.Brand]: TagType.Brand,
  [TagType.Competitor]: TagType.Competitor,
};

export enum FlywheelCampaignStatus {
  CampaignsToBeCreated = 'campaignsToBeCreated',
  CampaignsToBePaused = 'campaignsToBePaused',
  CampaignsToBeKept = 'campaignsToBeKept',
  CampaignsToBeModified = 'campaignsToBeModified',
}

export interface TagData {
  id: string;
  name: string;
  tagType?: TagType;
  assignAll?: boolean;
  isDeleted?: boolean;
  isNew?: boolean;
  isEdited?: boolean;
}

export interface AddedTagsResponseV2 {
  readonly brand: TagData[];
  readonly competitor: TagData[];
}

export interface AddedTagsResponse {
  readonly brand: string[];
  readonly competitor: string[];
}

export interface AddedTagsChange {
  readonly tag: string;
  readonly type: TagType;
  isNew?: boolean;
  isSelected?: boolean;
  isDeleted?: boolean;
  isMoved?: boolean;
}

export interface Cogs {
  amount: number;
  currency: string;
}

export interface ProductSettingsCOGS {
  productCatalogId: string;
  cogs: Cogs;
  readonly sku?: string;
}

export interface ProductSettingsV2Request {
  readonly merchantCountries: MerchantCountry[];
}
export interface ProductSettingsV2Response {
  readonly merchantCountries: ProductSettingsV2Data[];
}

export interface ProductSettingsCOGSResponse {
  readonly elements: ProductSettingsCOGS[];
}
export enum FilterType {
  All = 'all',
  Advertised = 'advertised',
  NotAdvertised = 'notAdvertised',
  Suggestions = 'suggestions',
}
export interface FilterTypeCount {
  readonly [FilterType.All]: number;
  readonly [FilterType.Advertised]: number;
  readonly [FilterType.NotAdvertised]: number;
  readonly [FilterType.Suggestions]: number;
}

export interface FlywheelSolution {
  readonly productFamilyId?: string;
  readonly campaignsToBeCreated?: CampaignsData[];
  readonly campaignsToBePaused?: CampaignsData[];
  readonly campaignsToBeKept?: CampaignsData[];
  readonly campaignsToBeModified?: CampaignsData[];
  readonly suggestionId: string;
}

export interface ProductSettingsV2Data extends MerchantCountry {
  readonly productFamilies: ProductGroupData[];
  readonly counts: FilterTypeCount;
}

export enum ProductFamilyType {
  Parent = 'PARENT',
  Category = 'CATEGORY',
  Singleton = 'SINGLETON',
}
export interface ProductGroupData {
  readonly parentDisplayText: string;
  readonly productFamilyId: string;
  readonly parentEntityId: string;
  readonly imageUrl: string;
  readonly productFamilyType: ProductFamilyType;
  readonly productGroupId: string;
  readonly name: string;
  readonly externalItemId: string;
  readonly sku: string;
  readonly members: SkuData[];
  readonly advertised: boolean;
  readonly suggestions: SuggestionType[];
  readonly childPromotedToParent: boolean;
}

export interface BaseSku {
  readonly imageUrl: string;
  readonly name: string;
  readonly sku: string;
  readonly externalItemId: string;
}

export interface SkuData extends BaseSku {
  readonly memberProductCatalogId: string;
  readonly productGroupId: string;
}

export enum SuggestionType {
  AlignCampaigns = 'ALIGN_CAMPAIGNS',
  SetBrandAndCompetitorTags = 'SET_BRAND_COMPETITOR_TAGS',
  SetCogs = 'SET_COGS',
  AddProductAd = 'ADD_PRODUCT_AD',
}

export interface CogsRequest {
  readonly productCatalogIds: string[];
}

export interface UpdateAdsRequest {
  readonly channelId: string;
  readonly campaignUpdates: CampaignEdits[];
  readonly adGroupUpdates: AdGroupEdits[];
}

export interface UpdateCogsRequest {
  readonly merchantCountryId: string;
  readonly updates: CogsUpdateData[];
}

export interface CogsUpdateData {
  readonly productCatalogId: string;
  readonly sku: string;
  readonly cogs: Cogs;
}

export interface UpdateCogsCORequest {
  readonly salesChannelId: string;
  readonly merchantType: string;
  readonly preAdGrossMargin: number;
  readonly updates: CogsUpdateCOData[];
}

export interface CogsUpdateCOData {
  readonly productCatalogId: string;
  readonly preAdGrossMarginFields: PreAdGrossMarginFields;
}

export interface PreAdGrossMarginFields {
  readonly avgSalePrice: number;
  readonly dailyRateOfSales: number;
  readonly platformFees: number;
  readonly cogs: number;
}

export enum GenericStatus {
  Successful = 'successful',
  Error = 'error',
  Success = 'success',
}

export interface GenericStatusResponse {
  readonly status: GenericStatus;
  readonly errors: Error[];
}

export interface AdsRequest {
  readonly merchantCountryId: string;
  readonly skus: string[];
  readonly merchantType?: string;
  readonly extItemIds?: string[];
}

export interface AdsResponse {
  readonly campaigns: CampaignsData[];
  readonly acosTargetMin: number;
  readonly acosTargetMax: number;
  readonly totalBudget: MoneyWithAmountInString;
}

export interface CampaignsData {
  readonly campaignId?: string;
  readonly name: string;
  readonly dailyBudget?: MoneyWithAmountInString;
  readonly targetingType: CampaignTargetType;
  readonly aligned?: boolean;
  readonly targetRecommendationEnabled?: boolean;
  readonly adGroups: AdGroupData[];
  readonly pendingFields?: AIProductSettingsEditableFields[];
  readonly advertisingType?: AdvertisingType;
}

export enum AdGroupState {
  Paused = 'paused',
  Kept = 'kept',
  Added = 'added',
}

export enum TargetEntityType {
  KEYWORD_TARGETING = 'KEYWORD_TARGETING',
  PRODUCT_ATTRIBUTE_TARGETING = 'PRODUCT_ATTRIBUTE_TARGETING',
}

export interface AdGroupData {
  readonly adGroupId?: string;
  readonly bidAutomation?: boolean;
  readonly targetSegments: CampaignTargetSegment[];
  readonly acosTarget?: number;
  readonly maxBid?: number;
  readonly minBid?: number;
  readonly name: string;
  readonly adProducts?: AdProductData[];
  readonly pendingFields?: AIProductSettingsEditableFields[];
  readonly suggestedAcosTarget?: number;
  readonly state?: AdGroupState;
  readonly oldAcosTarget?: number;
  readonly newAdProductCount?: number;
  readonly oldAdProductCount?: number;
  readonly targetEntityType?: TargetEntityType;
  readonly advertisingType?: AdvertisingType;
}

export interface AdProductData extends BaseSku {
  readonly adProductId?: string;
}

export interface ProductGroup {
  readonly productCatalogId: string;
  readonly category: string;
  readonly sku: string;
}

export interface SuggestionResponse extends ProductGroup {
  readonly campaignsToBeCreated: CampaignsData[];
  readonly campaignsToBePaused: CampaignsData[];
}

export interface AlignCampaignsRequest extends ProductGroup {
  readonly campaigns: CampaignsData[];
}

export interface SyncStatusResponse extends ProductGroup {
  readonly synced: boolean;
}

export interface TagsRequest {
  readonly merchantCountryId: string;
  readonly productCatalogIds: string[];
}

export interface TagsData {
  readonly brand: string[];
  readonly competitor: string[];
}

export interface UpdateTagsRequest {
  readonly merchantCountryId: string;
  readonly productCatalogIds: string[];
  readonly creates: TagsData;
  readonly deletes: TagsData;
}

export enum CampaignTargetType {
  Auto = 'auto',
  Manual = 'manual',
}

export enum CampaignTargetSegment {
  Brand = 'brand',
  Competitor = 'competitor',
  Generic = 'generic',
}

export interface CampaignEdits {
  campaignId: string;
  edits: {
    dailyBudget?: MoneyWithAmountInString;
    campaignName?: string;
  };
}

export interface AdGroupEdits {
  adGroupId: string;
  edits?: {
    name: string;
  };
  macsTarget?: number;
  bidAutomationEnabled?: boolean;
  minBid?: MoneyWithAmountInString;
  maxBid?: MoneyWithAmountInString;
  biddingStrategyRequestAction?: BiddingStrategyRequestAction;
}

export enum BiddingStrategyRequestAction {
  Update = 'UPDATE',
  Create = 'CREATE',
}

export interface SyncStatusRequest {
  readonly merchantCountryId: string;
  readonly productFamilyId: string;
}

export enum SyncStatus {
  Suggestion = 'suggestion',
  Syncing = 'syncing',
  Complete = 'complete',
  Error = 'error',
}
export interface SyncStatusResponse {
  readonly productFamilyId: string;
  readonly status: SyncStatus;
  readonly errors: Error[];
}

export interface SuggestionApplyRequest {
  readonly productFamilyId: string;
  readonly suggestionType: SuggestionType;
}

export interface CogsEdit {
  amount?: number;
  currency?: string;
  memberProductCatalogId: string;
  imageUrl: string;
  name: string;
  sku: string;
  externalItemId: string;
}

export enum API_VERSION_ONBOARDING_DATA {
  V1 = 'v1',
  V2 = 'v2',
}

export enum AssignmentMode {
  All = 'assign-all',
  Custom = 'custom',
}

export interface MissingTag {
  readonly productCount: number;
  readonly isOptedOut: boolean;
  readonly assignMode?: AssignmentMode;
  readonly merchantCountryId: string;
}

export interface MissingTagsResponse {
  readonly elements: MissingTag[];
}

export interface BacnTagsResponse {
  readonly brand: TagData[];
  readonly competitor: TagData[];
}

export interface BacnMissingTagsRequest {
  readonly merchantCountryIds: string[];
}

export interface BacnUpdateTagRequest {
  readonly brand: (string | number)[];
  readonly competitor: (string | number)[];
}

export interface BacnProductTagsUpdateRequest {
  readonly creates: BacnUpdateTagRequest;
  readonly deletes: BacnUpdateTagRequest;
  readonly merchantCountryId: string;
  readonly productCatalogIds: string[];
}

export interface TagUpdateRequest {
  readonly id: string;
  readonly name: string;
}

export interface BacnMerchantTagsUpdateRequest {
  readonly merchantCountryIds: string[];
  readonly creates: BacnUpdateTagRequest;
  readonly deletes: BacnUpdateTagRequest;
  readonly updates: TagUpdateRequest[];
}

export interface BacnProduct extends Record<string, unknown> {
  readonly productCatalogId: string;
  readonly productDetails: BacnSkuDetails;
  readonly brand: TagData[];
  readonly competitor: TagData[];
}

export interface BacnProductsResponse {
  readonly elements: BacnProduct[];
}

export interface BacnUpdateSettingsRequest {
  readonly assignMode?: AssignmentMode;
  readonly assignAll?: boolean;
  readonly optOut?: boolean;
}
