import React from 'react';
import { useIntl } from 'react-intl';

import {
  ArrowLeftIcon,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../ConnectModalWrapper';
import { NeedHelp } from '../../../../components/NeedHelp';
import { INTERCOM_AMAZON_ONBOARDING_HELP } from '../constants';
import { FlywheelSalesChannel } from '../../../../lib/types/Fam';

interface NoMerchantsModalProps {
  readonly goBack: () => void;
  readonly closeModal?: () => void;
  readonly channel?: FlywheelSalesChannel;
}

export const NoMerchantsModal: React.FC<NoMerchantsModalProps> = ({
  goBack,
  closeModal,
  channel,
}) => {
  const intl = useIntl();

  return (
    <ConnectModalWrapper
      dataTestId="no_merchants_modal"
      isOpen
      headerText={intl.formatMessage({
        id: I18nKey.FLASH_MODALS_AMAZON_MODAL_HEADER,
      })}
      content={<ModalContent channel={channel} />}
      footer={<ModalFooter goBack={goBack} />}
      closeModal={closeModal}
    />
  );
};
NoMerchantsModal.displayName = 'NoMerchantsModal';

const ModalContent: React.FC<Omit<NoMerchantsModalProps, 'goBack'>> = ({
  channel,
}) => {
  const intl = useIntl();
  const [HEADER, WARNING] = [
    I18nKey.FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_SUBHEADER,
    I18nKey.FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_WARNING_HEADER,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <>
      <div className="text-left text-base my-16 text-grey-900 flex flex-col grow shrink-0 basis-0">
        <Typography
          size={TypographySize.xl}
          lineHeight={TypographyLineHeight.tight}
          weight={TypographyWeight.semibold}
        >
          {HEADER}
        </Typography>
        <Typography
          size={TypographySize.base}
          lineHeight={TypographyLineHeight.normal}
          weight={TypographyWeight.regular}
        >
          {WARNING}
        </Typography>
      </div>
      <div className="pb-16">
        <NeedHelp
          url={INTERCOM_AMAZON_ONBOARDING_HELP}
          dataTestId="no_merchants_modal"
          channel={channel}
        />
      </div>
    </>
  );
};

const ModalFooter: React.FC<
  Omit<NoMerchantsModalProps, 'noMerchants' | 'channel'>
> = ({ goBack }) => {
  const intl = useIntl();

  return (
    <div className="flex justify-between mx-8">
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.BlackAndWhiteBorder}
        label={intl.formatMessage({ id: I18nKey.BACK })}
        svgIcon={ArrowLeftIcon}
        onClick={goBack}
        dataTestId={`flash1_amazon_ads_no_merchants_available_back`}
      />
      <Button
        size={ButtonSize.Medium}
        variant={ButtonVariant.Primary}
        label={intl.formatMessage({
          id: I18nKey.FLASH_MODALS_AMAZON_AUTHORIZE_CONNECT_BUTTON,
        })}
        state={ButtonState.Disabled}
      />
    </div>
  );
};
