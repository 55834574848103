import {
  PortaledTooltipPlacement,
  ProductSku2,
  Spinner,
} from '@teikametrics/tm-design-system';
import { MAP_SALES_CHANNEL_ID_TO_NAME } from '../../../../lib/types/SalesChannels';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PRODUCT_ADS_API_COLUMN_NAME } from '../../../advertisingOptimization/containers/adsManager/utils';
import { SKU_RECORDS_PER_LOAD } from './const';
import { SkuTabProps } from './types';
import { getAdGroupFilter, getCommonEnityRequestFields } from './utils';
import { getAmazonItemPageUrl } from '../../../products/containers/skuCatalog/utils/skuDetailsUtils';
import { getWalmartItemPageUrl } from '../../../../lib/utilities/skuUtils';
import {
  AdLevelApiEndpoint,
  MerchantCountryCode,
  ProductAdDetails,
  ProductAdsDataRequest,
} from '../../../../lib/types/AOSharedTypes';
import { PaginatedRequest } from '../../../../lib/clients/types';
import { SortOrder } from '../../../../lib/types/Sort';
import { FlywheelSalesChannel } from '../../../../lib/types/Fam';

export const SkuTab: React.FC<SkuTabProps> = ({
  accountId,
  aoApiClient,
  merchantCountryId,
  adGroupId,
  salesChannelId,
  merchantType,
  merchantCountryCode,
}) => {
  const [loadingSkus, setLoadingSkus] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [shouldScrollInfinitely, setShouldScrollInfinitely] =
    useState<boolean>(false);

  const [skuData, setSkuData] = useState<ProductAdDetails[] | undefined>();

  const adItemDetailsFields = [
    PRODUCT_ADS_API_COLUMN_NAME.ProductName,
    PRODUCT_ADS_API_COLUMN_NAME.ProductImage,
    PRODUCT_ADS_API_COLUMN_NAME.Sku,
    PRODUCT_ADS_API_COLUMN_NAME.ExtItemId,
  ];

  const AD_ITEM_REQUEST = {
    ...getCommonEnityRequestFields(
      merchantCountryId,
      salesChannelId,
      merchantType
    ),
    adItemDetailsFields,
    channelSettingsFields: [],
    performanceFields: [],
  };

  const AD_ITEM_DATA_FETCHER = aoApiClient.getAdLevelData<
    ProductAdsDataRequest,
    ProductAdDetails
  >(
    accountId,
    AdLevelApiEndpoint.ProductAds,
    AD_ITEM_REQUEST,
    getAdGroupFilter(adGroupId)
  );

  const onLoadMoreClick = async () => {
    setLoadingSkus(true);
    setShouldScrollInfinitely(false);
    const newPageNumber = page + 1;
    setPage(newPageNumber);
    loadAndProcessSkuData(newPageNumber, false);
  };

  const loadSkuData = async (pageNumber: number) => {
    const paginatedRequest: PaginatedRequest = {
      filters: [],
      sorts: [
        {
          column: PRODUCT_ADS_API_COLUMN_NAME.ProductName,
          direction: SortOrder.Asc,
        },
      ],
      itemsPerPage: SKU_RECORDS_PER_LOAD,
      page: pageNumber,
    };
    return AD_ITEM_DATA_FETCHER(paginatedRequest).then((resp) => resp);
  };

  const loadAndProcessSkuData = async (
    pageNumber: number,
    clearExistingData: boolean
  ) => {
    const recentResponseData = await loadSkuData(pageNumber);
    let updatedData: ProductAdDetails[];
    if (clearExistingData) {
      updatedData = recentResponseData.items;
    } else if (skuData) {
      updatedData = [...skuData, ...recentResponseData.items];
    } else {
      updatedData = recentResponseData.items;
    }
    setSkuData(updatedData);
    setShouldScrollInfinitely(
      recentResponseData.totalItems > pageNumber * SKU_RECORDS_PER_LOAD
    );
    setLoadingSkus(false);
  };

  useEffect(() => {
    loadAndProcessSkuData(page, true);
  }, []);

  const onAsinClick = (
    salesChannelName: string,
    merchantCountryCode: MerchantCountryCode,
    extItemId: string
  ) => {
    const url =
      salesChannelName === FlywheelSalesChannel.Amazon
        ? getAmazonItemPageUrl(merchantCountryCode, extItemId)
        : getWalmartItemPageUrl(extItemId);

    window.open(url);
  };

  const getSkuData = () => {
    return (
      <div className="flex flex-col gap-12">
        {skuData &&
          skuData.map((eachSku) => (
            <ProductSku2
              key={eachSku.adItemId}
              dataTestId="product_kwa_2_slideover"
              asin={eachSku.adItemDetails.extItemId}
              canExpand={false}
              count={0}
              expanded={false}
              merchantCountry={merchantCountryId}
              onAsinClick={() => {
                onAsinClick(
                  MAP_SALES_CHANNEL_ID_TO_NAME[salesChannelId],
                  merchantCountryCode as MerchantCountryCode,
                  eachSku.adItemDetails.extItemId || ''
                );
              }}
              productImageUrl={eachSku.adItemDetails.productImage}
              productName={eachSku.adItemDetails.productName}
              salesChannel={MAP_SALES_CHANNEL_ID_TO_NAME[salesChannelId]}
              sku={eachSku.adItemDetails.sku}
              useSku={true}
              useAsin={
                MAP_SALES_CHANNEL_ID_TO_NAME[salesChannelId] ===
                FlywheelSalesChannel.Amazon
              }
              isProductNameClickable={false}
              productNameTooltipPlacement={PortaledTooltipPlacement.Bottom}
              productNameTooltipPlacementOffset={0}
              className="max-w-460"
              skuLabelClassnames="truncate w-160"
              isProductNameLink={false}
            />
          ))}
      </div>
    );
  };

  return (
    <div
      className="h-kwaCreateInAdGroupSlideoutTab overflow-auto"
      id="kwa_2_sku_slideout_content"
    >
      {shouldScrollInfinitely ? (
        <InfiniteScroll
          next={onLoadMoreClick}
          hasMore={shouldScrollInfinitely}
          loader={undefined}
          scrollableTarget="kwa_2_sku_slideout_content"
          dataLength={1}
        >
          {getSkuData()}
        </InfiniteScroll>
      ) : (
        getSkuData()
      )}
      {loadingSkus && (
        <div className="relative mt-12 ml-16 h-32">
          <Spinner />
        </div>
      )}
    </div>
  );
};

SkuTab.displayName = 'ProductDetailsSlideoutSkuTab';
