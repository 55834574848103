import React from 'react';
import { useIntl } from 'react-intl';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { TargetsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  CAMPAIGN_TACTIC_I18NKEY_MAPPER,
  TARGETS_API_COLUMN_NAME,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<TargetsDetails> = (targetsDetails) => {
  const intl = useIntl();

  return makeTextColumn<TargetsDetails>(
    ({ targetDetails: { campaignTactic } }) =>
      (campaignTactic &&
        intl.formatMessage({
          id: CAMPAIGN_TACTIC_I18NKEY_MAPPER[campaignTactic],
        })) ||
      undefined
  )(targetsDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignTacticColumn: FlywheelTableColumn<TargetsDetails> = {
  columnName: TARGETS_API_COLUMN_NAME.CampaignTactic,
  isSortable: true,
  RowCellElement,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_TACTIC,
  gridColumnWidth: '160px',
};

export const campaignTacticFilter = createMultiSelectDataFieldFilter(
  TARGETS_API_COLUMN_NAME.CampaignTactic,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_TACTIC,
  [
    SelectFilterOption.CampaignTacticLegacy,
    SelectFilterOption.CampaignTacticProduct,
    SelectFilterOption.CampaignTacticAudiences,
    SelectFilterOption.CampaignTacticViews,
  ]
);
