import {
  ChartBuilder,
  ChartTime,
  Icon,
  IconSize,
  LightningIcon,
  NumberFormat,
  Slideover,
  Spinner,
  Type,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../../../containers/notificationProvider';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import { createCompassApiClient } from '../../../../../lib/clients/CompassApiClient';
import { COMMON_ONLY_DATE_FORMAT } from '../../../../../lib/types/CommonSharedTypes';
import { AiStatisticsGraphData } from '../../../../../lib/types/CompassSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  COMPASS_API_REQUEST_DATE_FORMAT,
  ChartTrendMetricFormatSelector,
  DateRange,
} from '../../../types';
import { GREY_600 } from '../businessGraphV2/types';
import {
  calculateXAxisLabelTilt,
  getChartMetricsData,
  getMaxYAxisValue,
  getXAxisLabels,
} from './utils';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../lib/utilities/intercom';

interface AiStatsSlideoverProps {
  readonly isOpenAiStatsSlideover: boolean;
  readonly closeSlideOver: () => void;
  readonly totalBids: number;
  readonly dateRange: DateRange;
  readonly selectedMerchantIds: string[];
  readonly currentChartTimeFormat: ChartTime;
  readonly formatSelector: ChartTrendMetricFormatSelector;
}

export const AiStatsSlideover: React.FC<AiStatsSlideoverProps> = ({
  isOpenAiStatsSlideover,
  closeSlideOver,
  totalBids,
  dateRange,
  selectedMerchantIds,
  currentChartTimeFormat,
  formatSelector,
}) => {
  const intl = useIntl();

  const userContext = useContext<UserContextState>(UserContext);
  const idToken = userContext.userInfo.idToken!;
  const accountId = getCurrentAccountFromContext(userContext)!.id;

  const compassApiClient = createCompassApiClient(idToken);

  const [isGraphDataLoading, setIsGraphDataLoading] = useState<boolean>(false);

  const toasts = useContext<NotificationContextState>(NotificationContext);

  const [graphData, setGraphData] = useState<AiStatisticsGraphData>();

  const loadGraphData = () => {
    setIsGraphDataLoading(true);
    compassApiClient
      .getAiStatisticsGraphData(
        accountId,
        selectedMerchantIds,
        dateRange.startDate.toFormat(COMPASS_API_REQUEST_DATE_FORMAT),
        dateRange.endDate.toFormat(COMPASS_API_REQUEST_DATE_FORMAT),
        currentChartTimeFormat.toLowerCase()
      )
      .then((res) => {
        setGraphData(res);
      })
      .catch(() => {
        toasts.addNotification({
          headline: intl.formatMessage({ id: I18nKey.ERROR_HEADLINE }),
          description: intl.formatMessage({ id: I18nKey.ERROR_MESSAGE }),
          type: Type.Attention,
          dataTestId: 'ai_bids_graph_api_error',
        });
      })
      .finally(() => {
        setIsGraphDataLoading(false);
      });
  };

  useEffect(() => {
    if (isOpenAiStatsSlideover) {
      loadGraphData();
    }
  }, [isOpenAiStatsSlideover]);

  const requiredDataPoints = graphData?.graphDataPoints || [];

  const xAxisLabels = getXAxisLabels(intl, requiredDataPoints, formatSelector);

  const maxYAxisValue = getMaxYAxisValue(requiredDataPoints);

  const getSlideoverContent = () => {
    return (
      <div className="flex flex-col gap-12">
        <div className="flex flex-row gap-12 items-start">
          <Icon
            svg={LightningIcon}
            size={IconSize.Large}
            className="text-purple-500 min-w-20"
          />
          <div className="flex flex-col gap-4 items-start">
            <Typography
              size={TypographySize.lg}
              lineHeight={TypographyLineHeight.tight}
              weight={TypographyWeight.regular}
              className="text-grey-900"
            >
              {intl.formatMessage({
                id: I18nKey.COMPASS_DASHBOARD_AI_STATISTICS_SLIDEOVER_TITLE,
              })}
            </Typography>
            <Typography
              size={TypographySize['2xl']}
              lineHeight={TypographyLineHeight.none}
              weight={TypographyWeight.medium}
              className="text-grey-900"
            >
              {intl.formatNumber(totalBids)}
            </Typography>
            <Typography
              size={TypographySize.sm}
              lineHeight={TypographyLineHeight.none}
              weight={TypographyWeight.regular}
              className="text-grey-500 mt-4"
            >
              {intl.formatMessage(
                {
                  id: I18nKey.COMPASS_DASHBOARD_AI_STATISTICS_VALUE_DESCRIPTION_2,
                },
                {
                  startDate: dateRange.startDate.toFormat(
                    COMMON_ONLY_DATE_FORMAT
                  ),
                  endDate: dateRange.endDate.toFormat(COMMON_ONLY_DATE_FORMAT),
                }
              )}
            </Typography>
            <Typography
              size={TypographySize.base}
              lineHeight={TypographyLineHeight.tight}
              weight={TypographyWeight.regular}
              className="text-grey-700 mt-16"
            >
              {intl.formatMessage({
                id: I18nKey.COMPASS_DASHBOARD_AI_STATISTICS_TITLE_DESCRIPTION_V2,
              })}
            </Typography>
          </div>
        </div>
        <div>
          {isGraphDataLoading ? (
            <Spinner />
          ) : (
            graphData && (
              <ChartBuilder
                yAxisFixedHeight={170}
                defaultTime={ChartTime.Daily}
                showGraphLineTooltip={true}
                showPreviousDataOnGraphLineTooltip={false}
                className="mt-44"
                actionButtonTabs={[]}
                onClickChartTime={() => {}}
                PlotBandPoints={[]}
                chartMetricsProps={getChartMetricsData(
                  intl,
                  requiredDataPoints,
                  formatSelector
                )}
                xAxisLabels={xAxisLabels}
                rotationXaxis={calculateXAxisLabelTilt(xAxisLabels)}
                aIActivity={false}
                showYAxisOnLeft={true}
                yAxisLeftLabelColor={GREY_600}
                yAxisLeftMinValue={0}
                yAxisLeftMaxValue={maxYAxisValue}
                yAxisOnLeftFormat={NumberFormat.Number}
                hideLabelColorsOnTooltip={true}
                xAxisMarginBottom={
                  currentChartTimeFormat !== ChartTime.Weekly ? undefined : 4
                }
              />
            )
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (isOpenAiStatsSlideover) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [isOpenAiStatsSlideover]);

  return (
    <Slideover
      isOpen={isOpenAiStatsSlideover}
      onClose={closeSlideOver}
      title={intl.formatMessage({
        id: I18nKey.COMPASS_DASHBOARD_AI_STATISTICS_TITLE,
      })}
      hideFooter={true}
      content={getSlideoverContent()}
      wrapperClassName="min-w-960 max-w-960"
      overlayZindexClassname="z-2"
    />
  );
};
