import { DateTime } from 'luxon';

import {
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makePercentColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  REQUEST_DATE_FORMAT,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import {
  NEGETIVE_TARGETS_MATCH_TYPE,
  TARGETS_API_COLUMN_NAME,
  getPerformanceMetricValue,
} from '../utils';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) =>
  makePercentColumn<TargetsDetails>(
    ({
      targetDetails: { startDate, matchType },
      targetPerformance: { acosDirect },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        acosDirect,
        matchType && NEGETIVE_TARGETS_MATCH_TYPE.includes(matchType)
      );

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const directAcosColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.DirectACOS,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_ACOS,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const directAcosFilter = createPercentDataFieldFilter(
  TARGETS_API_COLUMN_NAME.DirectACOS,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_ACOS,
  isValidNumber()
);
