import React from 'react';
import { useIntl } from 'react-intl';

import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../../ConnectModalWrapper';
import { ModalContent } from './content';
import { ModalFooter } from './footer';

export interface ConnectionInitialModalProps {
  readonly channel: FlywheelSalesChannel;
  readonly goBack: () => void;
  readonly closeModal?: () => void;
  readonly authorize?: () => void;
  readonly next?: () => void;
}

export const ConnectionInitialModal: React.FC<ConnectionInitialModalProps> = ({
  channel,
  goBack,
  authorize,
  next,
  closeModal,
}) => {
  const intl = useIntl();

  return (
    <ConnectModalWrapper
      dataTestId="connection_initial_modal"
      isOpen
      closeModal={closeModal}
      headerText={
        channel === FlywheelSalesChannel.Amazon
          ? intl.formatMessage({
              id: I18nKey.FLASH_MODALS_AMAZON_MODAL_HEADER,
            })
          : intl.formatMessage({
              id: I18nKey.FLASH_MODALS_WALMART_MODAL_HEADER,
            })
      }
      content={<ModalContent channel={channel} />}
      footer={
        <ModalFooter
          goBack={goBack}
          next={authorize || next}
          channel={channel}
        />
      }
      headerChannelType={channel}
    />
  );
};
ConnectionInitialModal.displayName = 'ConnectionInitialModal';
