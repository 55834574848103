import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeNumericInputColumn,
  makePercentColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  CampaignDetails,
} from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { AdLevelI8nKeyMapper, TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getTooltipContentForArchivedEntity,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
} from '../utils';
import { isDirty } from './totalBudget';

const getTopOfSearchBidMultiplier = ({
  channelSettings: { placementBidMultiplier },
}: CampaignDetails) =>
  placementBidMultiplier &&
  !isNil(placementBidMultiplier?.topOfSearchBidMultiplier)
    ? placementBidMultiplier.topOfSearchBidMultiplier.toString()
    : undefined;

const InvalidTopOfSearchMultiplierTooltipComponent: React.FC<{
  readonly minTopOfSearchMulitplierValue?: number;
  readonly maxTopOfSearchMulitplierValue?: number;
}> = ({ minTopOfSearchMulitplierValue, maxTopOfSearchMulitplierValue }) => {
  const intl = useIntl();

  return (
    <p className="w-180 text-center">
      {intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIER_AMAZON_ERROR_MESSAGE,
        },
        {
          minValue:
            !isNil(minTopOfSearchMulitplierValue) &&
            intl.formatNumber(minTopOfSearchMulitplierValue),
          maxValue:
            !isNil(maxTopOfSearchMulitplierValue) &&
            intl.formatNumber(maxTopOfSearchMulitplierValue),
        }
      )}
    </p>
  );
};
InvalidTopOfSearchMultiplierTooltipComponent.displayName =
  'InvalidTopOfSearchMultiplierTooltipComponent';

const getExistingValue = (campaignDetails: CampaignDetails, intl: IntlShape) =>
  (campaignDetails.channelSettings.placementBidMultiplier?.topOfSearchBidMultiplier?.toString() &&
    intl.formatNumber(
      campaignDetails.channelSettings.placementBidMultiplier
        ?.topOfSearchBidMultiplier * 100
    )) ||
  '';

const getControlledTooltipState = (
  isCampaignArchived: boolean,
  focussed: boolean,
  topOfSearchBidMultiplierInputState: NumericInputState
) => {
  let controlledTooltip = isCampaignArchived
    ? ControlledTooltip.None
    : ControlledTooltip.Hide;
  if (
    !isCampaignArchived &&
    focussed &&
    topOfSearchBidMultiplierInputState === NumericInputState.Error
  ) {
    controlledTooltip = ControlledTooltip.Show;
  }
  return controlledTooltip;
};

const getInputState = (
  isCampaignStatusArchived: boolean,
  isInputEdited: boolean,
  isDirty: boolean,
  isInvalidTopOfSearchBidMultiplier: boolean
) => {
  let inputState = isCampaignStatusArchived
    ? NumericInputState.Disabled
    : NumericInputState.Default;

  if ((isInputEdited || isDirty) && isInvalidTopOfSearchBidMultiplier) {
    inputState = NumericInputState.Error;
  }
  return inputState;
};

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({
  isEditMode,
  selectedAdType,
  salesChannel,
  merchantCountry,
  ...campaignDetails
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);
  const [isInputEdited, setInputEdited] = useState<boolean>(false);

  const campaignId = campaignDetails.campaignId;
  const currentTableId = ADS_MANAGER_CAMPAIGNS_TABLE_ID;

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(tableState, currentTableId)
  );

  const updatedTopOfSearchBidMultiplier = useSelector<
    WithTable<CampaignDetails>,
    string
  >(({ tableState }) =>
    tableSelectors.getCellSelector(
      campaignId,
      CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier
    )(tableState, currentTableId)
  );
  const bidConstraintsData = useBidConstraintsContext();

  const existingValue = getExistingValue(campaignDetails, intl);

  let topOfSearchBidMultiplier =
    updatedTopOfSearchBidMultiplier ?? existingValue;

  if (isNil(topOfSearchBidMultiplier) && isInputEdited) {
    topOfSearchBidMultiplier = '';
  }

  const [value, setValue] = useState<string>(topOfSearchBidMultiplier);

  const onTopOfSearchBidMultiplierAndPageUpdate = () => {
    if (
      isEditMode ||
      (!isNil(updatedTopOfSearchBidMultiplier) &&
        updatedTopOfSearchBidMultiplier !== value)
    ) {
      setValue(topOfSearchBidMultiplier);
    }

    return () => setInputEdited(false);
  };

  useEffect(onTopOfSearchBidMultiplierAndPageUpdate, [
    isEditMode,
    currentPage,
    updatedTopOfSearchBidMultiplier,
  ]);

  const updateCellValue = (newValue: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: currentTableId,
        columnName: CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
        rowId: campaignId,
        value: newValue,
        existingValue: existingValue || '',
        numericValue: true,
      })
    );
  };

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignId]
  );

  const {
    minTopOfSearchBidMultiplier: minTopOfSearchMulitplierValue,
    maxTopOfSearchBidMultiplier: maxTopOfSearchMulitplierValue,
  } = getBidConstraint(
    bidConstraintsData.constraints,
    selectedAdType,
    salesChannel,
    CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
    merchantCountry
  );

  const isInvalidTopOfSearchBidMuliplier = isTopOfSearchBidMultiplierInvalid(
    value,
    minTopOfSearchMulitplierValue,
    maxTopOfSearchMulitplierValue
  );

  const isCampaignArchived = isCampaignStatusArchived(
    campaignDetails.channelSettings.status
  );

  let topOfSearchBidMultiplierInputState = getInputState(
    isCampaignArchived,
    isInputEdited,
    isDirty(value, existingValue),
    isInvalidTopOfSearchBidMuliplier
  );

  const controlledTooltip = getControlledTooltipState(
    isCampaignArchived,
    focussed,
    topOfSearchBidMultiplierInputState
  );

  const tooltipContent = () => {
    if (isCampaignArchived) {
      return getTooltipContentForArchivedEntity(
        intl.formatMessage({
          id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
        }),
        intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOP_OF_SEARCH_BID_MULTIPLER,
        }),
        intl
      );
    } else if (isInvalidTopOfSearchBidMuliplier) {
      return (
        <InvalidTopOfSearchMultiplierTooltipComponent
          minTopOfSearchMulitplierValue={minTopOfSearchMulitplierValue}
          maxTopOfSearchMulitplierValue={maxTopOfSearchMulitplierValue}
        />
      );
    }
  };

  if (isEditMode) {
    const onTopOfSearchBidMultiplierChange = (inputValue: string) => {
      const formattedInputValue = inputValue.replaceAll(/[.]/g, '');
      setInputEdited(true);
      setValue(formattedInputValue);
      debouncedUpdateCellValue(formattedInputValue);
    };

    const onInputFocus = () => setFocussed(true);
    const onInputBlur = () => {
      setFocussed(false);
    };

    const topOfSearchBidMultiplierInputProps: NumericInputProps &
      TypedTooltipProps = {
      value,
      state: topOfSearchBidMultiplierInputState,
      appendedElement: '%',
      onInputBlur: onInputBlur,
      onChange: onTopOfSearchBidMultiplierChange,
      tooltipContent: tooltipContent(),
      controlledTooltip,
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus: onInputFocus,
      isDirty: isDirty(value, existingValue),
      acceptOnlyPositiveNumbers: true,
      minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      acceptOnlyIntegers: true,
      dataTestId: 'top_of_search_bid_multiplier',
    };

    return makeNumericInputColumn(() => topOfSearchBidMultiplierInputProps)(
      campaignDetails
    );
  }

  return makePercentColumn<CampaignDetails>(
    getTopOfSearchBidMultiplier,
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const topOfSearchBidMultiplierColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOP_OF_SEARCH_BID_MULTIPLER,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const topOfSearchBidMultiplierFilter = createPercentDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOP_OF_SEARCH_BID_MULTIPLER,
  isValidNumber(),
  true
);

export const isTopOfSearchBidMultiplierInvalid = (
  value?: string,
  minTopOfSearchMulitplierValue?: number,
  maxTopOfSearchMulitplierValue?: number
): boolean => {
  const isTopOfSearchBidMuliplierLessThanRequiredValue: boolean =
    isCurrentValueLessThanRequiredMinValue(
      minTopOfSearchMulitplierValue,
      value
    );

  const isTopOfSearchBidMuliplierGreaterThanRequiredValue: boolean =
    isCurrentValueGreaterThanRequiredMaxValue(
      maxTopOfSearchMulitplierValue,
      value
    );

  return (
    isTopOfSearchBidMuliplierLessThanRequiredValue ||
    isTopOfSearchBidMuliplierGreaterThanRequiredValue ||
    isNaN(Number(value)) ||
    isEmpty(value)
  );
};
