import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  LockClosedIcon,
} from '@teikametrics/tm-design-system';
import React from 'react';
import { useIntl } from 'react-intl';

import I18nKey from '../../lib/types/I18nKey';

interface RiskFreeTrialHeroProps {
  title: React.ReactNode;
  onStart: () => void;
}

export const RiskFreeTrialHero: React.FC<RiskFreeTrialHeroProps> = ({
  title,
  onStart,
}) => {
  const intl = useIntl();

  const [
    RISK_FREE_TRIAL_PILL,
    RISK_FREE_TRIAL_DESCRIPTION,
    RISK_FREE_TRIAL_BUTTON,
  ] = [
    I18nKey.EMPTY_STATES_RISK_FREE_TRIAL_PILL,
    I18nKey.EMPTY_STATES_RISK_FREE_TRIAL_DESCRIPTION,
    I18nKey.RISK_FREE_TRIAL_PILL_START_RISK_FREE_DESCRIPTION,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <div
      className={`flex flex-col items-center
        pt-104 pb-120 text-black text-center`}
    >
      <div className="pt-32 text-xl leading-tight font-semibold xl:text-2xl">
        {title}
      </div>
      <div
        className={`flex items-center gap-8 rounded-56
        mt-8 py-4 pl-4 pr-16 bg-magenta-300
        text-base leading-tight font-medium text-magenta-700`}
      >
        <div
          className={`flex items-center justify-center
          w-24 h-24 rounded-full bg-magenta-500`}
        >
          <Icon
            svg={LockClosedIcon}
            size={IconSize.Small}
            className="text-white"
          />
        </div>
        {RISK_FREE_TRIAL_PILL}
      </div>
      <div className="pt-32 text-lg">{RISK_FREE_TRIAL_DESCRIPTION}</div>
      <Button
        className="mt-8"
        label={RISK_FREE_TRIAL_BUTTON}
        size={ButtonSize.Medium}
        variant={ButtonVariant.Primary}
        onClick={onStart}
      />
    </div>
  );
};

RiskFreeTrialHero.displayName = 'RiskFreeTrialHero';
