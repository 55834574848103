import { useIntl } from 'react-intl';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CAMPAIGN_COST_TYPE_MAPPER, TableDataAdsManager } from '../types';
import { PRODUCT_ADS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) => {
  const intl = useIntl();

  return makeTextColumn(
    ({ adItemDetails }: ProductAdDetails) =>
      adItemDetails?.campaignCostType
        ? intl.formatMessage({
            id: CAMPAIGN_COST_TYPE_MAPPER[adItemDetails?.campaignCostType],
          })
        : undefined,
    false,
    'normal-case'
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignCostTypeColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: PRODUCT_ADS_API_COLUMN_NAME.CampaignCostType,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_COST_TYPE,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const campaignCostTypeFilter = createMultiSelectDataFieldFilter(
  PRODUCT_ADS_API_COLUMN_NAME.CampaignCostType,
  I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_COST_TYPE,
  [
    SelectFilterOption.CampaignCostTypeCPC,
    SelectFilterOption.CampaignCostTypeVCPM,
  ]
);
