import React from 'react';
import { useIntl } from 'react-intl';

import { HOW_TO_CHANGE_PLAN_LINK } from '../../lib/types/CommonSharedTypes';
import I18nKey from '../../lib/types/I18nKey';

export const LearnMore: React.FC = () => {
  const intl = useIntl();
  return (
    <a
      className={`text-base text-purple-500
        custom-underline-purple-500 leading-none font-normal`}
      href={HOW_TO_CHANGE_PLAN_LINK}
      rel="noreferrer"
      target="_blank"
    >
      {intl.formatMessage({
        id: I18nKey.PLAN_SWITCH_MODAL_LEARN_MORE,
      })}
    </a>
  );
};
