import { DateTime } from 'luxon';

import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  ProductAdDetails,
  REQUEST_DATE_FORMAT,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { getPerformanceMetricValue } from '../utils';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) =>
  makeNumericColumn<ProductAdDetails>(
    ({ adItemDetails, adItemPerformance }) => {
      const campaignStartDate = adItemDetails.startDate
        ? DateTime.fromFormat(adItemDetails.startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        adItemPerformance.roasDirect
      );

      return performanceMetricValue?.toString();
    },
    false,
    true
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const directRoasColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: SV_ADS_API_COLUMN_NAME.DirectROAS,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_ROAS,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const directRoasFilter = createNumericDataFieldFilter(
  SV_ADS_API_COLUMN_NAME.DirectROAS,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_ROAS,
  isValidNumber(0)
);
