import { IntlShape } from 'react-intl';
import I18nKey from '../../../../lib/types/I18nKey';
import { KeywordActionColumns } from '../../../../lib/types/KeywordAction';
import { ColumnManagerOptionWithIsSelected } from './types';

export const getColumnManagerOptionsNegativeRecommendationsTableV2 = (
  intl: IntlShape
): ColumnManagerOptionWithIsSelected[] => {
  const adSalesColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_AD_SALES_HEADER,
    }),
    value: KeywordActionColumns.AD_SALES,
    isSelected: false,
  };

  const acosColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_ACOS_HEADER,
    }),
    value: KeywordActionColumns.ACOS,
    isSelected: false,
  };

  const roasColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_ROAS_HEADER,
    }),
    value: KeywordActionColumns.ROAS,
    isSelected: false,
  };

  const conversionRateColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_AD_CONVERSION_RATE_HEADER,
    }),
    value: KeywordActionColumns.CONVERSION_RATE,
    isSelected: false,
  };

  const ctrColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_CLICK_THROUGH_RATE_HEADER,
    }),
    value: KeywordActionColumns.CTR,
    isSelected: false,
  };

  const cpcColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_COST_PER_CLICK_HEADER,
    }),
    value: KeywordActionColumns.CPC,
    isSelected: false,
  };

  const impressionsColumn = {
    groupName: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER,
    }),
    label: intl.formatMessage({
      id: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_IMPRESSIONS_HEADER,
    }),
    value: KeywordActionColumns.IMPRESSIONS,
    isSelected: false,
  };

  return [
    adSalesColumn,
    acosColumn,
    roasColumn,
    conversionRateColumn,
    impressionsColumn,
    cpcColumn,
    ctrColumn,
  ];
};
