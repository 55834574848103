import { DateTime } from 'luxon';

const AO_ROAS_TOTAL = 'roasTotal';
const AO_ROAS_DIRECT = 'roasDirect';

export const COLUMNS_WITH_SINGLE_DECIMAL_FILTER = [
  AO_ROAS_TOTAL,
  AO_ROAS_DIRECT,
];
export interface SingleSelectOption {
  readonly value: string;
  readonly displayI18nKey: string;
}

export enum FilterOps {
  like = '~',
  notLike = '!~',
  gte = '>=',
  lte = '<=',
  gt = '>',
  lt = '<',
  eq = '=',
  in = '||=',
  notIn = '=||',
  is = '==',
  isNot = '!=',
}

export interface LikeFilter {
  readonly op: FilterOps.like;
  readonly field: string;
  readonly value: string;
}

export interface NotLikeFilter {
  readonly op: FilterOps.like;
  readonly field: string;
  readonly value: string;
}
export interface GreaterThanOrEqualFilter {
  readonly op: FilterOps.gte;
  readonly field: string;
  readonly value: number | DateTime;
}

export interface LessThanOrEqualFilter {
  readonly op: FilterOps.lte;
  readonly field: string;
  readonly value: number | DateTime;
}

export interface GreaterThanFilter {
  readonly op: FilterOps.gt;
  readonly field: string;
  readonly value: number | DateTime;
}

export interface LessThanFilter {
  readonly op: FilterOps.lt;
  readonly field: string;
  readonly value: number | DateTime;
}

export interface EqualToFilter {
  readonly op: FilterOps.eq;
  readonly field: string;
  readonly value: number | string | DateTime;
}

export interface InFilter {
  readonly op: FilterOps.in;
  readonly field: string;
  readonly value: string[];
}

export interface NotInFilter {
  readonly op: FilterOps.notIn;
  readonly field: string;
  readonly value: string[];
}

export interface IsFilter {
  readonly op: FilterOps.is;
  readonly field: string;
  readonly value: SingleSelectOption;
}

export interface IsNotFilter {
  readonly op: FilterOps.isNot;
  readonly field: string;
  readonly value: string;
}

export interface ContainsFilter {
  readonly op: FilterOps.like;
  readonly field: string;
  readonly value: string;
}

export interface DoesNotContainFilter {
  readonly op: FilterOps.notLike;
  readonly field: string;
  readonly value: string;
}

export type Filter =
  | LikeFilter
  | GreaterThanOrEqualFilter
  | LessThanOrEqualFilter
  | GreaterThanFilter
  | LessThanFilter
  | EqualToFilter
  | InFilter
  | NotInFilter
  | IsFilter
  | IsNotFilter
  | ContainsFilter
  | DoesNotContainFilter;

export interface FilterFieldMapper {
  readonly field: string;
  readonly alias: string;
}
