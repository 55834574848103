/* eslint-disable no-control-regex */
import {
  AIHintStatus,
  AmazonLogomarkEnabledIcon,
  BulkEditModalElementValidator,
  BulkEditModalNumericValueOperator,
  CheckboxState,
  ColumnManagerOption,
  HelpPromptProps,
  HelpPromptVariant,
  NumericInputType,
  PaletteColor,
  SegmentedLinks,
  SelectOptionProps,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';
import { fromNullable } from 'fp-ts/lib/Option';
import find from 'lodash/find';
import first from 'lodash/first';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import lowerCase from 'lodash/lowerCase';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import { FormattedMessage, IntlShape } from 'react-intl';
import { OptimizelyContextState } from '../../../../../containers/optimizelyProvider/optimizelyProvider';
import { tableSelectors } from '../../../../../containers/table/ducks';
import {
  TableChange,
  TableSelectSettings,
  WithTable,
} from '../../../../../containers/table/ducks/types';
import { getChannelIcon } from '../../../../../lib/clients/utils';
import {
  AdGroupDetails,
  AdGroupStatus,
  AdLevel,
  AdType,
  AddedBy,
  AiBiddingValues,
  BudgetType,
  CampaignAdFormat,
  CampaignCostType,
  CampaignDetails,
  CampaignStatus,
  CampaignTactic,
  CampaignTargetingType,
  CampaignType,
  DataAvailabilityInfo,
  DataInspectionList,
  DateRange,
  ExpressionType,
  FilterFieldMapper,
  FlywheelSalesChannel,
  Groups,
  LabelValue,
  MatchType,
  MerchantCountryCode,
  Portfolio,
  ProductAdDetails,
  SUPPORTED_MERCHANT_COUNTRIES_PER_CHANNEL,
  SmartAcosFwSettingParams,
  TargetStatus as TargetStatusValues,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import {
  MONETARY_FRACTION_DIGITS,
  PERCENTAGE_FRACTION_DIGITS,
} from '../../../../../lib/types/CommonSharedTypes';
import {
  FAMConnectionStatus,
  MerchantCountry,
  MerchantType,
  MerchantsBySalesChannel,
  SalesChannel,
} from '../../../../../lib/types/Fam';
import { Filter, FilterOps } from '../../../../../lib/types/Filter';
import I18nKey from '../../../../../lib/types/I18nKey';
import { OptimizelyFlags } from '../../../../../lib/types/OptimizelyFlags';
import { getCountryFlagIcon } from '../../../../../lib/utilities/countryFlags';

import { Location } from 'react-router-dom';
import { transFormAdItemsFilters } from '../../../utils';
import {
  BulkEditModalElement,
  BulkEditModalGroupedElement,
} from '../bulkEditModal';
import {
  DATA_INSPECTION_COLUMN_MANAGER_CONFIG,
  DATA_INSPECTOR_COLUMN_NAME,
  DataInspectorColumnIdentifier,
} from '../dataInspectorConfig';
import {
  ADS_MANAGER_ADGROUP_TABLE_ID,
  ADS_MANAGER_AD_ITEMS_TABLE_ID,
  ADS_MANAGER_CAMPAIGNS_TABLE_ID,
  ADS_MANAGER_PROFILES_TABLE_ID,
  ADS_MANAGER_SB_ADS_TABLE_ID,
  ADS_MANAGER_SV_ADS_TABLE_ID,
  ADS_MANAGER_TARGETS_TABLE_ID,
} from '../ducks/types';
import {
  ADDED_BY_FILTER,
  ADGROUP_TABLE_SEARCH_KEY,
  ADS_MANAGER_AD_LEVEL_PAGES,
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  ADS_MANAGER_PAGE_TO_AD_LEVEL_MAPPER,
  ADS_MANAGER_PAGE_TO_AD_TYPE_MAPPER,
  ADS_MANAGER_PAGE_TO_SALESCHANNEL_MAPPER,
  ADS_MANAGER_PAGE_URI_TO_AD_LEVEL_MAPPER,
  AD_ITEM_SEARCH_KEY_FIELD,
  AD_LEVEL_EXTERNAL_ID_MAPPER,
  AD_LEVEL_INTERNAL_ID_MAPPER,
  AdLevelI8nKeyMapper,
  AdTypeI8nKeyMapper,
  AdgroupDataApiColumnGroupIdentifier,
  CAMPAIGN_DATE_FORMAT,
  CAMPAIGN_LIVE_FILTER,
  CAMPAIGN_NAME_FILTER,
  CAMPAIGN_STATUS,
  CAMPAIGN_TABLE_SEARCH_KEY,
  CampaignDataApiColumnGroupIdentifier,
  CampaignTypeQuickFilterKey,
  Checkbox,
  DataInspection,
  DataInspectionAdLevelConfigProps,
  DataInspectorColumnManagerConfigProps,
  EXPRESSION_TYPE_TO_FILTER_I18NKEY_MAPPER,
  PRODUCTAD_TABLE_SEARCH_KEY,
  PRODUCT_AD_STATUS,
  PROFILE_TABLE_SEARCH_KEY,
  ProductAdsApiColumnGroupIdentifier,
  ProfileDataApiColumnGroupIdentifier,
  SALES_CHANNEL_NAME,
  SB_ADS_TABLE_SEARCH_KEY,
  SEARCH_TEXT,
  SORT_BY,
  SORT_ORDER,
  SP_CAMPAIGN_NEGATIVE_KEYWORD,
  SbAdsApiColumnGroupIdentifier,
  SvAdApiColumnGroupIdentifier,
  TARGETS_TABLE_SEARCH_KEY,
  TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION,
  TARGET_ADLEVEL_LIST,
  TabCheckbox,
  TargetsDataApiColumnGroupIdentifier,
} from '../types';
import { filterTargetLabelandAddedByColumn } from './columnManagerUtils';
import {
  DATE_RANGE_CONFIG_FOR_DATA_TRACKING,
  PredefinedDateRange,
} from './dateRangeConfig';
import {
  getTabCheckboxColumnSelectionInfoInLocalStorage,
  storeTabCheckboxColumnSelectionInfoInLocalStorage,
} from './storageUtils';
import { SV_ADS_API_COLUMN_NAME } from './svAdsTypes';

const TARGETING_EXPRESSION_VALUE_KEY = 'targetingExpressionValue';

interface DropDownMetaDataForDataInspection {
  intl: IntlShape;
  dataInspectionList: DataInspectionList[];
  countryCode: MerchantCountryCode;
  specificTargetTypes: AdLevel[];
  salesChannel: FlywheelSalesChannel;
  targetingType?: CampaignTargetingType;
  shouldShowSBA?: boolean;
  shouldHideKeywordTargets?: boolean;
  adsManagerDetailsPageFlag?: boolean;
  identifiedAdLevel?: AdLevel;
  urlPathNameList: string[];
}

type BidHelpGuideType = {
  bulkMenuValue: string | null;
  negativeKeywordIds: string[];
  nonNegativeKeywordIds: string[];
  isKeywordTarget: boolean;
  intl: IntlShape;
  selectedCount?: number;
  validCount?: number;
  numberOfNegativeTargets?: number;
  selectedAdType?: AdType;
  selectedValue?: string;
  numberOfCampaignNegativeTargets?: number;
};

export const getSalesChannelsOptions = (
  salesChannels: SalesChannel[]
): SelectOptionProps<string>[] =>
  salesChannels.map((channel: SalesChannel) => ({
    label: startCase(channel.name),
    value: channel.salesChannelId,
    icon: getChannelIcon(channel.name),
  }));

export const sortSalesChannelsByName = (salesChannels: SalesChannel[]) =>
  sortBy(salesChannels, [(channel) => channel.name]);

export const sortPortfoliosByName = (portfolios: Portfolio[]) =>
  sortBy(portfolios, [(portfolio) => portfolio.name]);

export const sortGroupsByName = (groups: Groups[]) =>
  sortBy(groups, [(group) => group.name]);

export const isMerchantCountryEnabled = (
  salesChannel: FlywheelSalesChannel,
  country: MerchantCountryCode
): boolean =>
  SUPPORTED_MERCHANT_COUNTRIES_PER_CHANNEL[salesChannel].includes(country);

export const getMerchantCountriesForSalesChannel = (
  salesChannel: SalesChannel
): MerchantCountry[] => {
  const currentChannelsMerchantCountries: MerchantCountry[] =
    salesChannel.merchantCountries.map((merchantCountry) => ({
      ...merchantCountry,
      salesChannelId: salesChannel.salesChannelId,
    }));
  return currentChannelsMerchantCountries;
};

// Date Picker Helpers
export const presentDay: DateTime = DateTime.local();
export const previousDayDate: DateTime = presentDay.minus({ days: 1 });
export const sevenDayBackDate: DateTime = presentDay.minus({ days: 7 });

export const getDaysBetweenDates = (
  startDate: DateTime,
  endDate: DateTime
): number => {
  const { days } = endDate.diff(startDate, ['days']);
  return isUndefined(days) ? 0 : Math.ceil(days) + 1;
};

export const areGivenDatesEqual = (date1: DateTime, date2: DateTime) =>
  date1.startOf('day').equals(date2.startOf('day'));

export const getPermittedDateRange = (
  data: Array<DataAvailabilityInfo>,
  selectedMerchantsIds: Array<string>
): DateRange => {
  const selectedMerchantDataAvailabilityInfo: Array<DataAvailabilityInfo> =
    data.filter((entry: DataAvailabilityInfo) =>
      selectedMerchantsIds.includes(entry.merchantCountryId)
    );
  const earliestPossibleDate: Date = selectedMerchantDataAvailabilityInfo
    .map((info: DataAvailabilityInfo) => info.earliestAvailableDate)
    .sort((dateA: Date, dateB: Date) => dateA.getTime() - dateB.getTime())[0];

  const latestPossibleDate: Date = selectedMerchantDataAvailabilityInfo
    .map((info: DataAvailabilityInfo) => info.latestAvailableDate)
    .sort((dateA: Date, dateB: Date) => dateA.getTime() - dateB.getTime())[
    selectedMerchantDataAvailabilityInfo.length - 1
  ];

  const isLatestPossibleDateEqualToToday =
    latestPossibleDate &&
    areGivenDatesEqual(presentDay, DateTime.fromJSDate(latestPossibleDate));

  let permittedDateRange: DateRange = {
    initialStartDate: DateTime.fromJSDate(sevenDayBackDate.toJSDate()),
    initialEndDate: DateTime.fromJSDate(latestPossibleDate),
    minDate: DateTime.fromJSDate(earliestPossibleDate),
    maxDate: isLatestPossibleDateEqualToToday ? presentDay : previousDayDate,
  };

  if (!earliestPossibleDate) {
    permittedDateRange = {
      ...permittedDateRange,
      initialStartDate: sevenDayBackDate,
      minDate: sevenDayBackDate,
    };
  }
  if (!latestPossibleDate) {
    permittedDateRange = {
      ...permittedDateRange,
      initialEndDate: previousDayDate,
      maxDate: previousDayDate,
    };
  }

  // if start date is more than end date, set start date to previous date minus 7 days and end date to previous date
  if (
    latestPossibleDate &&
    permittedDateRange.initialStartDate >
      DateTime.fromJSDate(latestPossibleDate)
  ) {
    permittedDateRange = {
      ...permittedDateRange,
      initialStartDate: sevenDayBackDate,
      initialEndDate: previousDayDate,
    };
  }

  // If there is no data for previous days
  // Check if earliest possible date is more than latest possible date minus 6 days and set start date to latest possible date minus 6 days and end date latest possible date
  // otherwise set start date to earliest date and end date to latest date

  const sixDaysBackFromLatestPossibleDate =
    latestPossibleDate &&
    DateTime.fromJSDate(latestPossibleDate).minus({ days: 6 });

  const isLatestSyncDateLessThanPreviousDay =
    latestPossibleDate &&
    earliestPossibleDate &&
    DateTime.fromJSDate(latestPossibleDate).startOf('day') <
      previousDayDate.startOf('day');
  if (isLatestSyncDateLessThanPreviousDay) {
    if (
      sixDaysBackFromLatestPossibleDate >
      DateTime.fromJSDate(earliestPossibleDate)
    ) {
      permittedDateRange = {
        ...permittedDateRange,
        initialStartDate: sixDaysBackFromLatestPossibleDate,
        initialEndDate: DateTime.fromJSDate(latestPossibleDate),
      };
    } else {
      permittedDateRange = {
        ...permittedDateRange,
        initialStartDate: DateTime.fromJSDate(earliestPossibleDate),
        initialEndDate: DateTime.fromJSDate(latestPossibleDate),
      };
    }
  }

  return permittedDateRange;
};

// Data Inspection Helpers
const getIntlStringForAdType = (intl: IntlShape, adType: AdType) => {
  const id = AdTypeI8nKeyMapper[adType];
  return intl.formatMessage({ id });
};

const getIntlStringForAdLevel = (intl: IntlShape, adLevel: AdLevel) => {
  const id = AdLevelI8nKeyMapper[adLevel];
  return intl.formatMessage({ id });
};

export const getAdLevelWithFilteredTargets = (
  availableTargetTypes: AdLevel[],
  adLevelList: AdLevel[]
): AdLevel[] => {
  return !isEmpty(availableTargetTypes)
    ? adLevelList.filter((adLevel) => {
        if (TARGET_ADLEVEL_LIST.includes(adLevel)) {
          return availableTargetTypes.includes(adLevel);
        }
        return true;
      })
    : adLevelList;
};

export const getDropDownMetaDataForDataInspection = ({
  intl,
  dataInspectionList,
  countryCode,
  specificTargetTypes,
  salesChannel,
  targetingType,
  shouldShowSBA,
  shouldHideKeywordTargets,
  adsManagerDetailsPageFlag,
  identifiedAdLevel,
  urlPathNameList,
}: DropDownMetaDataForDataInspection): DataInspection[] => {
  const allAdvertisingTypeInfo = dataInspectionList
    .filter((info) => info.merchantCountryCode === countryCode)
    .map((info) => info.advertisingTypeInfo)
    .flat();

  const filteredAdTypes = uniq(
    allAdvertisingTypeInfo
      .filter((adTypeInfo) =>
        adTypeInfo.adType === AdType.SearchBrandAmplifier ? shouldShowSBA : true
      )
      .map((info) => info.adType)
  ).sort((a, b) => a.localeCompare(b));

  return filteredAdTypes.map((type) => {
    const adLevels = uniq(
      allAdvertisingTypeInfo
        .filter((info) => info.adType === type)
        .map((info) =>
          getAdLevelWithFilteredTargets(specificTargetTypes, info.adLevels)
        )
        .flat()
    ).map((adLevel) => {
      return {
        label: getIntlStringForAdLevel(intl, adLevel),
        value: adLevel,
      };
    });

    // Auto campaigns can only have Product Targets. Hence filtering out Keyword targets and targets from the dropdown list.
    // For SponsoredProducts their can be Negative Keyword in Auto campaign so ignore filter in case of SponsoredProducts
    // For Walmart we don't want to show keyword targets in campaign details
    const adLevelsBasedOnCampaignTargetingType =
      (isAutoCampaign(targetingType) && type !== AdType.SponsoredProducts) ||
      (identifiedAdLevel === AdLevel.Campaigns &&
        salesChannel === FlywheelSalesChannel.Walmart &&
        !urlPathNameList.includes('create-chatgpt') &&
        adsManagerDetailsPageFlag) ||
      (isAutoCampaign(targetingType) &&
        salesChannel === FlywheelSalesChannel.Walmart) ||
      shouldHideKeywordTargets
        ? adLevels.filter(
            (adLevel) =>
              adLevel.value !== AdLevel.KeywordTargets &&
              adLevel.value !== AdLevel.Targets
          )
        : [...adLevels];

    return {
      parent: {
        label: getIntlStringForAdType(intl, type),
        value: type,
      },
      child: adLevelsBasedOnCampaignTargetingType,
    };
  });
};

// For SBA advertising type returning SponseredBrands as BE and Sangam understands it in the same way
export const getRequestAdvertisingType = (currentAdType: AdType) => {
  return currentAdType === AdType.SearchBrandAmplifier
    ? AdType.SponsoredBrands
    : currentAdType;
};

// Ad-Level Campaigns Helpers
export enum CampaignsApiColumnIdentifier {
  CampaignName = 'CampaignName',
  CampaignType = 'CampaignType',
  CampaignTargetingType = 'CampaignTargetingType',
  CampaignStatus = 'CampaignStatus',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  BudgetType = 'BudgetType',
  DailyBudget = 'DailyBudget',
  TotalBudget = 'TotalBudget',
  BudgetRollover = 'BudgetRollover',
  SearchCarouselInclusion = 'SearchCarouselInclusion',
  BuyBoxBidMultiplier = 'BuyBoxBidMultiplier',
  SearchIngridBidMultiplier = 'SearchIngridBidMultiplier',
  HomePageBidMultiplier = 'HomePageBidMultiplier',
  StockUpBidMultiplier = 'StockUpBidMultiplier',
  DesktopBidMultiplier = 'DesktopBidMultiplier',
  AppBidMultiplier = 'AppBidMultiplier',
  MobileBidMultiplier = 'MobileBidMultiplier',
  AutomationStatus = 'AutomationStatus',
  MACSTarget = 'MACSTarget',
  MinBid = 'MinBid',
  MaxBid = 'MaxBid',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  AdSpend = 'AdSpend',
  AdSales = 'AdSales',
  AdUnitsSold = 'AdUnitsSold',
  ConversionRate = 'ConversionRate',
  ACOS = 'ACOS',
  ROAS = 'ROAS',
  ClickThroughRate = 'ClickThroughRate',
  CostPerClick = 'CostPerClick',
  DirectAdSales = 'DirectAdSales',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  RelatedClickRevenue = 'RelatedClickRevenue',
  Group = 'Group',
  Orders = 'Orders',
  //amazon specific columns
  CampaignAdFormat = 'CampaignAdFormat',
  AmazonBidOptimization = 'AmazonBidOptimization',
  CampaignServingStatus = 'CampaignServingStatus',
  BelowTopOfTheSearchBidMultiplier = 'BelowTopOfTheSearchBidMultiplier',
  Portfolio = 'Portfolio',
  AmazonBiddingStrategy = 'AmazonBiddingStrategy',
  ProductPageBidMultiplier = 'ProductPageBidMultiplier',
  TopOfSearchBidMultiplier = 'TopOfSearchBidMultiplier',
  AdConversions = 'AdConversions',
  CampaignTactic = 'CampaignTactic',
  NewToBrandOrders = 'NewToBrandOrders',
  PercentageOfOrdersNewToBrand = 'PercentageOfOrdersNewToBrand',
  NewToBrandOrdersRate = 'NewToBrandOrdersRate',
  NewToBrandSales = 'NewToBrandSales',
  PercentageOfSalesNewToBrand = 'PercentageOfSalesNewToBrand',
  NewToBrandAdUnitsSold = 'NewToBrandAdUnitsSold',
  PercentageOfAdUnitsSoldNewToBrand = 'PercentageOfAdUnitsSoldNewToBrand',
  MerchantName = 'MerchantName',
  ItemBuyBoxPlacement = 'ItemBuyBoxPlacement',
  ItemCarouselPlacement = 'ItemCarouselPlacement',
  CampaignCostType = 'CampaignCostType',
  MerchantType = 'MerchantType',
  AddedBy = 'AddedBy',
  CreatedAt = 'CreatedAt',
  AdvertisedSkuUnits = 'AdvertisedSkuUnits',
  OtherSkuUnits = 'OtherSkuUnits',
  AdvertisingGoal = 'AdvertisingGoal',
  SmartStructure = 'SmartStructure',
  VideoImpressions = 'VideoImpressions',
  ViewableImpressions = 'ViewableImpressions',
  ViewThroughRate = 'ViewThroughRate',
  ClickThroughRateForViews = 'ClickThroughRateForViews',
  ViewThroughAttributedSales = 'ViewThroughAttributedSales',
  ViewThroughOrders = 'ViewThroughOrders',
  ViewThroughUnitsSold = 'ViewThroughUnitsSold',
  TotalCompleteViewAttributedSales = 'TotalCompleteViewAttributedSales',
  AdvertisedCompleteViewAttributedSales = 'AdvertisedCompleteViewAttributedSales',
  OtherCompleteViewAttributedSales = 'OtherCompleteViewAttributedSales',
  CompleteViewOrders = 'CompleteViewOrders',
  CompleteViewUnitsSold = 'CompleteViewUnitsSold',
  VideoFirstQuartileViews = 'VideoFirstQuartileViews',
  VideoMidpointViews = 'VideoMidpointViews',
  VideoThirdQuartileViews = 'VideoThirdQuartileViews',
  VideoCompleteViews = 'VideoCompleteViews',
  VideoUnmutes = 'VideoUnmutes',
  FiveSecondViews = 'FiveSecondViews',
  FiveSecondViewRate = 'FiveSecondViewRate',
  TotalAttributedSales = 'TotalAttributedSales',
}

export const CAMPAIGNS_API_COLUMN_NAME: Record<
  CampaignsApiColumnIdentifier,
  string
> = Object.freeze({
  [CampaignsApiColumnIdentifier.AdvertisingGoal]: 'advertisingGoal',
  [CampaignsApiColumnIdentifier.SmartStructure]: 'smartStructure',
  [CampaignsApiColumnIdentifier.CampaignName]: 'campaignName',
  [CampaignsApiColumnIdentifier.CampaignType]: 'campaignType',
  [CampaignsApiColumnIdentifier.CampaignTargetingType]: 'targetingType',
  [CampaignsApiColumnIdentifier.CampaignStatus]: 'status',
  [CampaignsApiColumnIdentifier.StartDate]: 'startDate',
  [CampaignsApiColumnIdentifier.EndDate]: 'endDate',
  [CampaignsApiColumnIdentifier.BudgetType]: 'budgetType',
  [CampaignsApiColumnIdentifier.DailyBudget]: 'campaignDailyBudget',
  [CampaignsApiColumnIdentifier.TotalBudget]: 'totalBudget',
  [CampaignsApiColumnIdentifier.BudgetRollover]: 'budgetRollover',
  [CampaignsApiColumnIdentifier.SearchCarouselInclusion]: 'carouselStatus',
  [CampaignsApiColumnIdentifier.ItemBuyBoxPlacement]: 'itemBuyBox',
  [CampaignsApiColumnIdentifier.ItemCarouselPlacement]: 'itemCarousel',
  [CampaignsApiColumnIdentifier.BuyBoxBidMultiplier]: 'buyBoxBidMultiplier',
  [CampaignsApiColumnIdentifier.SearchIngridBidMultiplier]:
    'searchIngridBidMultiplier',
  [CampaignsApiColumnIdentifier.HomePageBidMultiplier]: 'homePageBidMultiplier',
  [CampaignsApiColumnIdentifier.StockUpBidMultiplier]: 'stockUpBidMultiplier',
  [CampaignsApiColumnIdentifier.DesktopBidMultiplier]: 'desktopBidMultiplier',
  [CampaignsApiColumnIdentifier.AppBidMultiplier]: 'appBidMultiplier',
  [CampaignsApiColumnIdentifier.MobileBidMultiplier]: 'mobileBidMultiplier',
  [CampaignsApiColumnIdentifier.AutomationStatus]: 'automationStatus',
  [CampaignsApiColumnIdentifier.MACSTarget]: 'macsTarget',
  [CampaignsApiColumnIdentifier.MinBid]: 'minBid',
  [CampaignsApiColumnIdentifier.MaxBid]: 'maxBid',
  [CampaignsApiColumnIdentifier.Impressions]: 'impressions',
  [CampaignsApiColumnIdentifier.Clicks]: 'clicks',
  [CampaignsApiColumnIdentifier.AdSpend]: 'adSpend',
  [CampaignsApiColumnIdentifier.AdSales]: 'adSalesTotal',
  [CampaignsApiColumnIdentifier.AdUnitsSold]: 'unitsSold',
  [CampaignsApiColumnIdentifier.ConversionRate]: 'conversionRate',
  [CampaignsApiColumnIdentifier.ACOS]: 'acosTotal',
  [CampaignsApiColumnIdentifier.ROAS]: 'roasTotal',
  [CampaignsApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
  [CampaignsApiColumnIdentifier.CostPerClick]: 'avgCostPerClick',
  [CampaignsApiColumnIdentifier.DirectAdSales]: 'adSalesDirect',
  [CampaignsApiColumnIdentifier.DirectACOS]: 'acosDirect',
  [CampaignsApiColumnIdentifier.DirectROAS]: 'roasDirect',
  [CampaignsApiColumnIdentifier.RelatedClickRevenue]: 'relatedClickRevenue',
  [CampaignsApiColumnIdentifier.Group]: 'groupLabel',
  //amazon specific
  [CampaignsApiColumnIdentifier.CampaignAdFormat]: 'campaignAdFormat',
  [CampaignsApiColumnIdentifier.AmazonBidOptimization]: 'bidOptimization',
  [CampaignsApiColumnIdentifier.CampaignServingStatus]: 'campaignServingStatus',
  [CampaignsApiColumnIdentifier.BelowTopOfTheSearchBidMultiplier]:
    'belowTopOfSearchBidMultiplier',
  [CampaignsApiColumnIdentifier.Portfolio]: 'portfolio',
  [CampaignsApiColumnIdentifier.AmazonBiddingStrategy]: 'biddingStrategy',
  [CampaignsApiColumnIdentifier.ProductPageBidMultiplier]:
    'productPageBidMultiplier',
  [CampaignsApiColumnIdentifier.TopOfSearchBidMultiplier]:
    'topOfSearchBidMultiplier',
  [CampaignsApiColumnIdentifier.AdConversions]: 'adConversions',
  [CampaignsApiColumnIdentifier.NewToBrandAdUnitsSold]: 'newToBrandAdUnitsSold',
  [CampaignsApiColumnIdentifier.CampaignTactic]: 'campaignTactic',
  [CampaignsApiColumnIdentifier.NewToBrandOrders]: 'newToBrandOrders',
  [CampaignsApiColumnIdentifier.PercentageOfOrdersNewToBrand]:
    'newToBrandOrdersPercentage',
  [CampaignsApiColumnIdentifier.NewToBrandOrdersRate]: 'newToBrandOrderRate',
  [CampaignsApiColumnIdentifier.NewToBrandSales]: 'newToBrandSales',
  [CampaignsApiColumnIdentifier.PercentageOfSalesNewToBrand]:
    'newToBrandSalesPercentage',
  [CampaignsApiColumnIdentifier.PercentageOfAdUnitsSoldNewToBrand]:
    'newToBrandAdUnitsSoldPercentage',
  [CampaignsApiColumnIdentifier.MerchantName]: 'merchantCountryId',
  [CampaignsApiColumnIdentifier.CampaignCostType]: 'campaignCostType',
  [CampaignsApiColumnIdentifier.MerchantType]: 'merchantType',
  [CampaignsApiColumnIdentifier.AddedBy]: 'addedBy',
  [CampaignsApiColumnIdentifier.CreatedAt]: 'createdAt',
  [CampaignsApiColumnIdentifier.AdvertisedSkuUnits]: 'advertisedSkuUnits',
  [CampaignsApiColumnIdentifier.OtherSkuUnits]: 'otherSkuUnits',
  [CampaignsApiColumnIdentifier.VideoImpressions]: 'videoImpressions',
  [CampaignsApiColumnIdentifier.ViewableImpressions]: 'viewableImpressions',
  [CampaignsApiColumnIdentifier.ViewThroughRate]: 'viewThroughRate',
  [CampaignsApiColumnIdentifier.ClickThroughRateForViews]:
    'clickThroughRateForViews',
  [CampaignsApiColumnIdentifier.ViewThroughAttributedSales]:
    'viewThroughAttributedSales',
  [CampaignsApiColumnIdentifier.ViewThroughOrders]: 'viewThroughOrders',
  [CampaignsApiColumnIdentifier.ViewThroughUnitsSold]: 'viewThroughUnitsSold',
  [CampaignsApiColumnIdentifier.TotalCompleteViewAttributedSales]:
    'totalCompleteViewAttributedSales',
  [CampaignsApiColumnIdentifier.AdvertisedCompleteViewAttributedSales]:
    'advertisedCompleteViewAttributedSales',
  [CampaignsApiColumnIdentifier.OtherCompleteViewAttributedSales]:
    'otherCompleteViewAttributedSales',
  [CampaignsApiColumnIdentifier.CompleteViewOrders]: 'completeViewOrder',
  [CampaignsApiColumnIdentifier.CompleteViewUnitsSold]: 'completeViewUnitsSold',
  [CampaignsApiColumnIdentifier.VideoFirstQuartileViews]:
    'videoFirstQuartileViews',
  [CampaignsApiColumnIdentifier.VideoMidpointViews]: 'videoMidpointViews',
  [CampaignsApiColumnIdentifier.VideoThirdQuartileViews]:
    'videoThirdQuartileViews',
  [CampaignsApiColumnIdentifier.VideoCompleteViews]: 'videoCompleteViews',
  [CampaignsApiColumnIdentifier.VideoUnmutes]: 'videoUnmutes',
  [CampaignsApiColumnIdentifier.FiveSecondViews]: 'videoFiveSecondViews',
  [CampaignsApiColumnIdentifier.FiveSecondViewRate]: 'videoFiveSecondViewsRate',
  [CampaignsApiColumnIdentifier.TotalAttributedSales]: 'adSalesTotal',
  [CampaignsApiColumnIdentifier.Orders]: 'orders',
});

export const SALES_CHANNEL_NAME_TO_I18NKEY_MAPPER = {
  [FlywheelSalesChannel.Amazon]: I18nKey.ADVERTISING_OPTIMIZATION_AMAZON,
  [FlywheelSalesChannel.Walmart]: I18nKey.ADVERTISING_OPTIMIZATION_WALMART,
};

export const SALES_CHANNEL_TO_ICON_MAPPER = {
  [FlywheelSalesChannel.Amazon]: AmazonLogomarkEnabledIcon,
  [FlywheelSalesChannel.Walmart]: WalmartLogomarkEnabledIcon,
};

export const createMerchantDropdownOptionData = (
  merchant: MerchantCountry,
  salesChannels: SalesChannel[]
): Pick<SelectOptionProps<string>, 'groupName' | 'secondaryIcon' | 'icon'> => {
  const maybeMerchantSalesChannel = salesChannels.find(
    (salesChannel) => salesChannel.salesChannelId === merchant.salesChannelId
  );

  return {
    groupName: !isUndefined(maybeMerchantSalesChannel)
      ? SALES_CHANNEL_NAME_TO_I18NKEY_MAPPER[
          maybeMerchantSalesChannel.name as FlywheelSalesChannel
        ]
      : undefined,
    icon: !isUndefined(maybeMerchantSalesChannel)
      ? SALES_CHANNEL_TO_ICON_MAPPER[
          maybeMerchantSalesChannel.name as FlywheelSalesChannel
        ]
      : undefined,
    secondaryIcon: !isUndefined(maybeMerchantSalesChannel)
      ? getCountryFlagIcon(merchant.country)
      : undefined,
  };
};

export const generateMerchantPickerOptions = (
  merchants: MerchantCountry[],
  salesChannels: SalesChannel[],
  intl: IntlShape
): SelectOptionProps<string>[] => {
  return merchants.map((merchant) => {
    const { groupName, icon, secondaryIcon } = createMerchantDropdownOptionData(
      merchant,
      salesChannels
    );
    return {
      value: merchant.merchantCountryId,
      label: merchant.merchantName,
      groupName: groupName && intl.formatMessage({ id: groupName }),
      icon,
      secondaryIcon,
      pillProps: {
        text: merchant.merchantType === MerchantType.Seller ? '3P' : '1P',
        color: PaletteColor.empty,
      },
    };
  });
};

export const CAMPAIGNS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  CampaignDataApiColumnGroupIdentifier
> = Object.freeze({
  [CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.SmartStructure]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignName]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignAdFormat]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.Portfolio]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.Group]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignTactic]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.MerchantName]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignCostType]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.StartDate]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.EndDate]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CreatedAt]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignType]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignTargetingType]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.AddedBy]:
    CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignStatus]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.BudgetType]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.DailyBudget]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.TotalBudget]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.BudgetRollover]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.SearchCarouselInclusion]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.ItemBuyBoxPlacement]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.CampaignServingStatus]:
    CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier]:
    CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.AutomationStatus]:
    CampaignDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.MACSTarget]:
    CampaignDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.MinBid]:
    CampaignDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.MaxBid]:
    CampaignDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [CAMPAIGNS_API_COLUMN_NAME.Impressions]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.Clicks]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdSpend]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdSales]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdUnitsSold]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdConversions]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ConversionRate]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ACOS]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ROAS]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ClickThroughRate]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.CostPerClick]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.DirectAdSales]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.DirectACOS]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.DirectROAS]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.RelatedClickRevenue]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdConversions]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.NewToBrandSales]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrders]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.NewToBrandAdUnitsSold]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.PercentageOfOrdersNewToBrand]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrdersRate]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.PercentageOfSalesNewToBrand]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdvertisedSkuUnits]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.OtherSkuUnits]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.VideoImpressions]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ViewableImpressions]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ViewThroughRate]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ClickThroughRateForViews]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ViewThroughAttributedSales]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ViewThroughOrders]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.ViewThroughUnitsSold]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.TotalCompleteViewAttributedSales]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.AdvertisedCompleteViewAttributedSales]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.OtherCompleteViewAttributedSales]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.CompleteViewOrders]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.CompleteViewUnitsSold]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.VideoFirstQuartileViews]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.VideoMidpointViews]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.VideoThirdQuartileViews]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.VideoCompleteViews]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.VideoUnmutes]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.FiveSecondViews]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.FiveSecondViewRate]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.TotalAttributedSales]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
  [CAMPAIGNS_API_COLUMN_NAME.Orders]:
    CampaignDataApiColumnGroupIdentifier.PerformanceFields,
});

export const EDIT_CAMPAIGNS_API_COLUMN_DATA_MAPPING: Record<string, string> =
  Object.freeze({
    [CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal]:
      'campaignDetails.advertisingGoal',
    [CAMPAIGNS_API_COLUMN_NAME.CampaignStatus]: 'channelSettings.status',
    [CAMPAIGNS_API_COLUMN_NAME.DailyBudget]:
      'channelSettings.dailyBudget.amount',
    [CAMPAIGNS_API_COLUMN_NAME.TotalBudget]:
      'channelSettings.totalBudget.amount',
    [CAMPAIGNS_API_COLUMN_NAME.BudgetRollover]:
      'channelSettings.budgetRollover',
    [CAMPAIGNS_API_COLUMN_NAME.StartDate]: 'channelSettings.startDate',
    [CAMPAIGNS_API_COLUMN_NAME.EndDate]: 'channelSettings.endDate',
    [CAMPAIGNS_API_COLUMN_NAME.SearchCarouselInclusion]:
      'channelSettings.carouselStatus',
    [CAMPAIGNS_API_COLUMN_NAME.ItemBuyBoxPlacement]:
      'channelSettings.itemBuyBox',
    [CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement]:
      'channelSettings.itemCarousel',
    [CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier]:
      'channelSettings.placementBidMultiplier.searchIngridBidMultiplier',
    [CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier]:
      'channelSettings.placementBidMultiplier.buyBoxBidMultiplier',
    [CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier]:
      'channelSettings.placementBidMultiplier.homePageBidMultiplier',
    [CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier]:
      'channelSettings.placementBidMultiplier.stockUpBidMultiplier',
    [CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier]:
      'channelSettings.platformBidMultiplier.desktopBidMultiplier',
    [CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier]:
      'channelSettings.platformBidMultiplier.appBidMultiplier',
    [CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier]:
      'channelSettings.platformBidMultiplier.mobileBidMultiplier',
    [CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy]:
      'channelSettings.biddingStrategy',
    [CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier]:
      'channelSettings.placementBidMultiplier.productPageBidMultiplier',
    [CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier]:
      'channelSettings.placementBidMultiplier.topOfSearchBidMultiplier',
    [CAMPAIGNS_API_COLUMN_NAME.Portfolio]:
      'campaignDetails.campaignPortfolioName',
    [CAMPAIGNS_API_COLUMN_NAME.Group]: 'campaignDetails.groupLabel',
    [CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization]:
      'channelSettings.bidOptimization',
    [CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier]:
      'channelSettings.placementBidMultiplier.belowTopOfSearchBidMultiplier',
    [CAMPAIGNS_API_COLUMN_NAME.AutomationStatus]:
      'flywheelSettings.automationStatus',
    [CAMPAIGNS_API_COLUMN_NAME.MACSTarget]: 'flywheelSettings.macsTarget',
    [CAMPAIGNS_API_COLUMN_NAME.MinBid]: 'flywheelSettings.minBid.amount',
    [CAMPAIGNS_API_COLUMN_NAME.MaxBid]: 'flywheelSettings.maxBid.amount',
    [CAMPAIGNS_API_COLUMN_NAME.Orders]: 'orders',
  });

//Ad-Level AdGroup Helpers
export enum AdgroupsApiColumnIdentifier {
  AdGroupName = 'AdGroupName',
  CampaignName = 'CampaignName',
  CampaignType = 'CampaignType',
  CampaignTargetingType = 'CampaignTargetingType',
  Tags = 'Tags',
  CampaignStatus = 'CampaignStatus',
  AdGroupStatus = 'AdGroupStatus',
  AiRecommendationKeywordSetting = 'AiRecommendationKeywordSetting',
  AdGroupUseCase = 'AdGroupUseCase',
  BidAutomationStatus = 'BidAutomationStatus',
  MACSTarget = 'MACSTarget',
  MinBid = 'MinBid',
  MaxBid = 'MaxBid',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  AdSpend = 'AdSpend',
  AdSales = 'AdSales',
  UnitsSold = 'UnitsSold',
  ConversionRate = 'ConversionRate',
  ACOS = 'ACOS',
  ROAS = 'ROAS',
  ClickThroughRate = 'ClickThroughRate',
  AverageCostPerClick = 'AverageCostPerClick',
  DirectAdSales = 'DirectAdSales',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  RelatedClickRevenue = 'RelatedClickRevenue',
  Group = 'Group',
  //amazon specific columns
  CampaignTactic = 'CampaignTactic',
  CampaignDailyBudget = 'CampaignDailyBudget',
  TotalBudget = 'TotalBudget',
  AdgroupDefaultBid = 'AdgroupDefaultBid',
  AdConversions = 'AdConversions',
  NewToBrandSales = 'NewToBrandSales',
  NewToBrandOrders = 'NewToBrandOrders',
  NewToBrandAdUnitsSold = 'NewToBrandAdUnitsSold',
  MerchantName = 'MerchantName',
  StartDate = 'StartDate',
  Portfolio = 'Portfolio',
  CampaignCostType = 'CampaignCostType',
  ReviewStatus = 'AdGroupReviewStatus',
  IsBiddable = 'IsBiddable',
  CampaignAdFormat = 'CampaignAdFormat',
  MerchantType = 'MerchantType',
  AddedBy = 'AddedBy',
  CreatedAt = 'CreatedAt',

  TotalAttributedSales = 'TotalAttributedSales',
  Orders = 'Orders',
  VideoImpressions = 'VideoImpressions',
  ViewableImpressions = 'ViewableImpressions',
  ViewThroughRate = 'ViewThroughRate',
  ClickThroughRateForViews = 'ClickThroughRateForViews',
  ViewThroughAttributedSales = 'ViewThroughAttributedSales',
  ViewThroughOrders = 'ViewThroughOrders',
  ViewThroughUnitsSold = 'ViewThroughUnitsSold',
  TotalCompleteViewAttributedSales = 'TotalCompleteViewAttributedSales',
  AdvertisedCompleteViewAttributedSales = 'AdvertisedCompleteViewAttributedSales',
  OtherCompleteViewAttributedSales = 'OtherCompleteViewAttributedSales',
  CompleteViewOrders = 'CompleteViewOrders',
  CompleteViewUnitsSold = 'CompleteViewUnitsSold',
  VideoFirstQuartileViews = 'VideoFirstQuartileViews',
  VideoMidpointViews = 'VideoMidpointViews',
  VideoThirdQuartileViews = 'VideoThirdQuartileViews',
  VideoCompleteViews = 'VideoCompleteViews',
  VideoUnmutes = 'VideoUnmutes',
  FiveSecondViews = 'FiveSecondViews',
  FiveSecondViewRate = 'FiveSecondViewRate',

  // extra keys for bulk edit modal
  MinBidAuto = 'MinBidAuto',
  MinBidManual = 'MinBidManual',
  MaxBidAuto = 'MaxBidAuto',
  MaxBidManual = 'MaxBidManual',
  AdGroupDefaultBidVcpm = 'AdGroupDefaultBidVcpm',
  AdGroupDefaultBidCPC = 'AdGroupDefaultBidCPC',
  AdvertisedSkuUnits = 'AdvertisedSkuUnits',
  OtherSkuUnits = 'OtherSkuUnits',
  AiBidding = 'AiBidding',
  EstimatedPreAdGrossMargin = 'EstimatedPreAdGrossMargin',
  IsCogs = 'IsCogs',
  BidModifier = 'BidModifier',
  BidModifierOverRide = 'BidModifierOverRide',
  BidModifierOverRideEndTime = 'BidModifierOverRideEndTime',
  MinBidOverrideEnabled = 'MinBidOverrideEnabled',
  MaxBidOverrideEnabled = 'MaxBidOverrideEnabled',
  MinBidRestrictedEntities = 'MinBidRestrictedEntities',
  MaxBidRestrictedEntities = 'MaxBidRestrictedEntities',
}

export const ADGROUPS_API_COLUMN_NAME: Record<
  AdgroupsApiColumnIdentifier,
  string
> = Object.freeze({
  [AdgroupsApiColumnIdentifier.AdGroupName]: 'adGroupName',
  [AdgroupsApiColumnIdentifier.CampaignName]: 'campaignName',
  [AdgroupsApiColumnIdentifier.CampaignType]: 'campaignType',
  [AdgroupsApiColumnIdentifier.CampaignTargetingType]: 'campaignTargetingType',
  [AdgroupsApiColumnIdentifier.Tags]: 'adGroupLabel',
  [AdgroupsApiColumnIdentifier.CampaignStatus]: 'campaignStatus',
  [AdgroupsApiColumnIdentifier.AdGroupStatus]: 'status',
  [AdgroupsApiColumnIdentifier.AiRecommendationKeywordSetting]:
    'aiRecommendationKeywordSetting',
  [AdgroupsApiColumnIdentifier.AdGroupUseCase]: 'adGroupUseCase',
  [AdgroupsApiColumnIdentifier.BidAutomationStatus]: 'automationStatus',
  [AdgroupsApiColumnIdentifier.MACSTarget]: 'macsTarget',
  [AdgroupsApiColumnIdentifier.MinBid]: 'minBid',
  [AdgroupsApiColumnIdentifier.MaxBid]: 'maxBid',
  [AdgroupsApiColumnIdentifier.Impressions]: 'impressions',
  [AdgroupsApiColumnIdentifier.Clicks]: 'clicks',
  [AdgroupsApiColumnIdentifier.AdSpend]: 'adSpend',
  [AdgroupsApiColumnIdentifier.AdSales]: 'adSalesTotal',
  [AdgroupsApiColumnIdentifier.UnitsSold]: 'unitsSold',
  [AdgroupsApiColumnIdentifier.ConversionRate]: 'conversionRate',
  [AdgroupsApiColumnIdentifier.ACOS]: 'acosTotal',
  [AdgroupsApiColumnIdentifier.ROAS]: 'roasTotal',
  [AdgroupsApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
  [AdgroupsApiColumnIdentifier.AverageCostPerClick]: 'avgCostPerClick',
  [AdgroupsApiColumnIdentifier.DirectAdSales]: 'adSalesDirect',
  [AdgroupsApiColumnIdentifier.DirectACOS]: 'acosDirect',
  [AdgroupsApiColumnIdentifier.DirectROAS]: 'roasDirect',
  [AdgroupsApiColumnIdentifier.RelatedClickRevenue]: 'relatedClickRevenue',
  [AdgroupsApiColumnIdentifier.Group]: 'groupLabel',
  //amazon specific columns
  [AdgroupsApiColumnIdentifier.CampaignTactic]: 'campaignTactic',
  [AdgroupsApiColumnIdentifier.CampaignDailyBudget]: 'campaignDailyBudget',
  [AdgroupsApiColumnIdentifier.TotalBudget]: 'totalBudget',
  [AdgroupsApiColumnIdentifier.AdgroupDefaultBid]: 'adGroupDefaultBid',
  [AdgroupsApiColumnIdentifier.AdConversions]: 'adConversions',
  [AdgroupsApiColumnIdentifier.NewToBrandSales]: 'newToBrandSales',
  [AdgroupsApiColumnIdentifier.NewToBrandOrders]: 'newToBrandOrders',
  [AdgroupsApiColumnIdentifier.NewToBrandAdUnitsSold]: 'newToBrandAdUnitsSold',
  [AdgroupsApiColumnIdentifier.MerchantName]: 'merchantCountryId',
  [AdgroupsApiColumnIdentifier.StartDate]: 'startDate',
  [AdgroupsApiColumnIdentifier.Portfolio]: 'portfolio',
  [AdgroupsApiColumnIdentifier.CampaignCostType]: 'campaignCostType',
  [AdgroupsApiColumnIdentifier.ReviewStatus]: 'adGroupReviewStatus',
  [AdgroupsApiColumnIdentifier.IsBiddable]: 'isBiddable',
  [AdgroupsApiColumnIdentifier.CampaignAdFormat]: 'campaignAdFormat',
  [AdgroupsApiColumnIdentifier.MerchantType]: 'merchantType',
  [AdgroupsApiColumnIdentifier.AddedBy]: 'addedBy',
  [AdgroupsApiColumnIdentifier.CreatedAt]: 'createdAt',

  [AdgroupsApiColumnIdentifier.TotalAttributedSales]: 'adSalesTotal',
  [AdgroupsApiColumnIdentifier.Orders]: 'orders',
  [AdgroupsApiColumnIdentifier.VideoImpressions]: 'videoImpressions',
  [AdgroupsApiColumnIdentifier.ViewableImpressions]: 'viewableImpressions',
  [AdgroupsApiColumnIdentifier.ViewThroughRate]: 'viewThroughRate',
  [AdgroupsApiColumnIdentifier.ClickThroughRateForViews]:
    'clickThroughRateForViews',
  [AdgroupsApiColumnIdentifier.ViewThroughAttributedSales]:
    'viewThroughAttributedSales',
  [AdgroupsApiColumnIdentifier.ViewThroughOrders]: 'viewThroughOrders',
  [AdgroupsApiColumnIdentifier.ViewThroughUnitsSold]: 'viewThroughUnitsSold',
  [AdgroupsApiColumnIdentifier.TotalCompleteViewAttributedSales]:
    'totalCompleteViewAttributedSales',
  [AdgroupsApiColumnIdentifier.AdvertisedCompleteViewAttributedSales]:
    'advertisedCompleteViewAttributedSales',
  [AdgroupsApiColumnIdentifier.OtherCompleteViewAttributedSales]:
    'otherCompleteViewAttributedSales',
  [AdgroupsApiColumnIdentifier.CompleteViewOrders]: 'completeViewOrder',
  [AdgroupsApiColumnIdentifier.CompleteViewUnitsSold]: 'completeViewUnitsSold',
  [AdgroupsApiColumnIdentifier.VideoFirstQuartileViews]:
    'videoFirstQuartileViews',
  [AdgroupsApiColumnIdentifier.VideoMidpointViews]: 'videoMidpointViews',
  [AdgroupsApiColumnIdentifier.VideoThirdQuartileViews]:
    'videoThirdQuartileViews',
  [AdgroupsApiColumnIdentifier.VideoCompleteViews]: 'videoCompleteViews',
  [AdgroupsApiColumnIdentifier.VideoUnmutes]: 'videoUnmutes',
  [AdgroupsApiColumnIdentifier.FiveSecondViews]: 'videoFiveSecondViews',
  [AdgroupsApiColumnIdentifier.FiveSecondViewRate]: 'videoFiveSecondViewsRate',

  // values for bulk edit modal
  [AdgroupsApiColumnIdentifier.MinBidAuto]: 'minBidAuto',
  [AdgroupsApiColumnIdentifier.MinBidManual]: 'minBidManual',
  [AdgroupsApiColumnIdentifier.MaxBidAuto]: 'maxBidAuto',
  [AdgroupsApiColumnIdentifier.MaxBidManual]: 'maxBidManual',
  [AdgroupsApiColumnIdentifier.AdGroupDefaultBidVcpm]: 'adGroupDefaultBidVcpm',
  [AdgroupsApiColumnIdentifier.AdGroupDefaultBidCPC]: 'adGroupDefaultBidCPC',
  [AdgroupsApiColumnIdentifier.AdvertisedSkuUnits]: 'advertisedSkuUnits',
  [AdgroupsApiColumnIdentifier.OtherSkuUnits]: 'otherSkuUnits',

  [AdgroupsApiColumnIdentifier.AiBidding]: 'aiBidding',
  [AdgroupsApiColumnIdentifier.EstimatedPreAdGrossMargin]:
    'avgPreAdGrossMargin',
  [AdgroupsApiColumnIdentifier.IsCogs]: 'isCogs',
  [AdgroupsApiColumnIdentifier.BidModifier]: 'bidModifier',
  [AdgroupsApiColumnIdentifier.BidModifierOverRide]: 'isOverrideBidModifier',
  [AdgroupsApiColumnIdentifier.BidModifierOverRideEndTime]: 'overrideEndTime',
  [AdgroupsApiColumnIdentifier.MinBidOverrideEnabled]: 'minBidOverrideEnabled',
  [AdgroupsApiColumnIdentifier.MaxBidOverrideEnabled]: 'maxBidOverrideEnabled',
  [AdgroupsApiColumnIdentifier.MinBidRestrictedEntities]:
    'minBidRestrictedEntities',
  [AdgroupsApiColumnIdentifier.MaxBidRestrictedEntities]:
    'maxBidRestrictedEntities',
});

export const ADGROUP_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  AdgroupDataApiColumnGroupIdentifier
> = Object.freeze({
  [ADGROUPS_API_COLUMN_NAME.AdGroupName]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignName]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignType]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignTargetingType]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.Tags]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.AddedBy]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignStatus]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignTactic]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.TotalBudget]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.MerchantName]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.Portfolio]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.Group]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.CampaignCostType]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting]:
    AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields,
  [ADGROUPS_API_COLUMN_NAME.AdGroupStatus]:
    AdgroupDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.AdGroupReviewStatus]:
    AdgroupDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid]:
    AdgroupDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.CreatedAt]:
    AdgroupDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.BidAutomationStatus]:
    AdgroupDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.MACSTarget]:
    AdgroupDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.MinBid]:
    AdgroupDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.MaxBid]:
    AdgroupDataApiColumnGroupIdentifier.PlacementSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.Impressions]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.Clicks]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.AdSpend]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.AdSales]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.UnitsSold]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ConversionRate]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ACOS]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ROAS]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ClickThroughRate]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.AverageCostPerClick]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.DirectAdSales]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.DirectACOS]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.DirectROAS]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.RelatedClickRevenue]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.AdConversions]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.NewToBrandSales]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.NewToBrandOrders]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.NewToBrandAdUnitsSold]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.OtherSkuUnits]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.AdvertisedSkuUnits]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.TotalAttributedSales]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.Orders]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.VideoImpressions]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ViewableImpressions]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ViewThroughRate]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ClickThroughRateForViews]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ViewThroughAttributedSales]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ViewThroughOrders]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.ViewThroughUnitsSold]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.TotalCompleteViewAttributedSales]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.AdvertisedCompleteViewAttributedSales]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.OtherCompleteViewAttributedSales]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.CompleteViewOrders]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.CompleteViewUnitsSold]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.VideoFirstQuartileViews]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.VideoMidpointViews]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.VideoThirdQuartileViews]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.VideoCompleteViews]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.VideoUnmutes]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.FiveSecondViews]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.FiveSecondViewRate]:
    AdgroupDataApiColumnGroupIdentifier.PerformanceFields,
  [ADGROUPS_API_COLUMN_NAME.AiBidding]:
    AdgroupDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.EstimatedPreAdGrossMargin]:
    AdgroupDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.BidModifier]:
    AdgroupDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.MinBidOverrideEnabled]:
    AdgroupDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.MaxBidOverrideEnabled]:
    AdgroupDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.MinBidRestrictedEntities]:
    AdgroupDataApiColumnGroupIdentifier.FlywheelSettingsFields,
  [ADGROUPS_API_COLUMN_NAME.MaxBidRestrictedEntities]:
    AdgroupDataApiColumnGroupIdentifier.FlywheelSettingsFields,
});

export const EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING: Record<string, string> =
  Object.freeze({
    [ADGROUPS_API_COLUMN_NAME.AdGroupName]: 'adGroupDetails.adGroupName',
    [ADGROUPS_API_COLUMN_NAME.Tags]: 'adGroupDetails.targetSegments',
    [ADGROUPS_API_COLUMN_NAME.AdGroupStatus]: 'channelSettings.status',
    [ADGROUPS_API_COLUMN_NAME.BidAutomationStatus]:
      'flywheelSettings.automationStatus',
    [ADGROUPS_API_COLUMN_NAME.MACSTarget]: 'flywheelSettings.macsTarget',
    [ADGROUPS_API_COLUMN_NAME.MinBid]: 'flywheelSettings.minBid.amount',
    [ADGROUPS_API_COLUMN_NAME.MaxBid]: 'flywheelSettings.maxBid.amount',
    [ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid]:
      'channelSettings.defaultBid.amount',
    [ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting]:
      'adGroupDetails.aiRecommendationKeywordSetting',
    [ADGROUPS_API_COLUMN_NAME.BidModifier]: 'flywheelSettings.bidModifier',
  });

//Ad-Level AdItems Helpers
export enum ProductAdsApiColumnIdentifier {
  ProductName = 'ProductName',
  ProductImage = 'ProductImage',
  AddedBy = 'AddedBy',
  AdGroupName = 'AdGroupName',
  AdGroupStatus = 'AdGroupStatus',
  CampaignName = 'CampaignName',
  CampaignType = 'CampaignType',
  CampaignTargetingType = 'CampaignTargetingType',
  CampaignStatus = 'AdItemsCampaignStatus',
  ProductAdStatus = 'Status',
  Bid = 'Bid',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  AdSpend = 'AdSpend',
  TotalAdSales = 'TotalAdSales',
  DirectAdSales = 'DirectAdSales',
  AdUnitsSold = 'AdUnitsSold',
  TotalConversions = 'TotalConversions',
  ConversionRate = 'ConversionRate',
  TotalACOS = 'TotalACOS',
  TotalROAS = 'TotalROAS',
  ClickThroughRate = 'ClickThroughRate',
  AvgCostPerClick = 'AvgCostPerClick',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  RelatedClickRevenue = 'RelatedClickRevenue',
  CampaignTactic = 'CampaignTactic',
  DailyBudget = 'DailyBudget',
  TotalBudget = 'TotalBudget',
  AdConversions = 'AdConversions',
  NewToBrandSales = 'NewToBrandSales',
  NewToBrandOrders = 'NewToBrandOrders',
  NewToBrandAdUnitsSold = 'NewToBrandAdUnitsSold',
  Sku = 'Sku',
  ExtItemId = 'ExtItemId',
  MerchantName = 'MerchantName',
  StartDate = 'StartDate',
  Portfolio = 'Portfolio',
  CampaignCostType = 'CampaignCostType',
  ProductReviewStatus = 'ProductReviewStatus',
  AdGroupReviewStatus = 'AdGroupReviewStatus',
  AdvertisedSkuUnits = 'AdvertisedSkuUnits',
  OtherSkuUnits = 'OtherSkuUnits',
}

export const PRODUCT_ADS_API_COLUMN_NAME: Record<
  ProductAdsApiColumnIdentifier,
  string
> = {
  [ProductAdsApiColumnIdentifier.ProductName]: 'productName',
  [ProductAdsApiColumnIdentifier.ProductImage]: 'productImage',
  [ProductAdsApiColumnIdentifier.AddedBy]: 'addedBy',
  [ProductAdsApiColumnIdentifier.AdGroupName]: 'adGroupName',
  [ProductAdsApiColumnIdentifier.AdGroupStatus]: 'adGroupStatus',
  [ProductAdsApiColumnIdentifier.CampaignName]: 'campaignName',
  [ProductAdsApiColumnIdentifier.CampaignStatus]: 'campaignStatus',
  [ProductAdsApiColumnIdentifier.CampaignTargetingType]:
    'campaignTargetingType',
  [ProductAdsApiColumnIdentifier.CampaignType]: 'campaignType',
  [ProductAdsApiColumnIdentifier.ProductAdStatus]: 'status',
  [ProductAdsApiColumnIdentifier.Bid]: 'bid',
  [ProductAdsApiColumnIdentifier.Impressions]: 'impressions',
  [ProductAdsApiColumnIdentifier.Clicks]: 'clicks',
  [ProductAdsApiColumnIdentifier.AdSpend]: 'adSpend',
  [ProductAdsApiColumnIdentifier.TotalAdSales]: 'adSalesTotal',
  [ProductAdsApiColumnIdentifier.AdUnitsSold]: 'unitsSold',
  [ProductAdsApiColumnIdentifier.TotalConversions]: 'totalConversions',
  [ProductAdsApiColumnIdentifier.ConversionRate]: 'conversionRate',
  [ProductAdsApiColumnIdentifier.TotalACOS]: 'acosTotal',
  [ProductAdsApiColumnIdentifier.TotalROAS]: 'roasTotal',
  [ProductAdsApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
  [ProductAdsApiColumnIdentifier.AvgCostPerClick]: 'avgCostPerClick',
  [ProductAdsApiColumnIdentifier.DirectACOS]: 'acosDirect',
  [ProductAdsApiColumnIdentifier.DirectROAS]: 'roasDirect',
  [ProductAdsApiColumnIdentifier.RelatedClickRevenue]: 'relatedClickRevenue',
  [ProductAdsApiColumnIdentifier.DirectAdSales]: 'adSalesDirect',
  [ProductAdsApiColumnIdentifier.CampaignTactic]: 'campaignTactic',
  [ProductAdsApiColumnIdentifier.DailyBudget]: 'campaignDailyBudget',
  [ProductAdsApiColumnIdentifier.TotalBudget]: 'totalBudget',
  [ProductAdsApiColumnIdentifier.AdConversions]: 'adConversions',
  [ProductAdsApiColumnIdentifier.NewToBrandSales]: 'newToBrandSales',
  [ProductAdsApiColumnIdentifier.NewToBrandOrders]: 'newToBrandOrders',
  [ProductAdsApiColumnIdentifier.NewToBrandAdUnitsSold]:
    'newToBrandAdUnitsSold',
  [ProductAdsApiColumnIdentifier.Sku]: 'sku',
  [ProductAdsApiColumnIdentifier.ExtItemId]: 'extItemId',
  [ProductAdsApiColumnIdentifier.MerchantName]: 'merchantCountryId',
  [ProductAdsApiColumnIdentifier.StartDate]: 'startDate',
  [ProductAdsApiColumnIdentifier.Portfolio]: 'portfolio',
  [ProductAdsApiColumnIdentifier.CampaignCostType]: 'campaignCostType',
  [ProductAdsApiColumnIdentifier.ProductReviewStatus]: 'adItemReviewStatus',
  [ProductAdsApiColumnIdentifier.AdGroupReviewStatus]: 'adGroupReviewStatus',
  [CampaignsApiColumnIdentifier.AdvertisedSkuUnits]: 'advertisedSkuUnits',
  [CampaignsApiColumnIdentifier.OtherSkuUnits]: 'otherSkuUnits',
};

export const AD_ITEMS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  ProductAdsApiColumnGroupIdentifier
> = Object.freeze({
  [PRODUCT_ADS_API_COLUMN_NAME.ProductName]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.ProductImage]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Sku]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.ExtItemId]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.MerchantName]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AddedBy]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdGroupStatus]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdGroupName]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.CampaignName]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.CampaignType]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.CampaignTargetingType]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.CampaignTactic]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.DailyBudget]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.TotalBudget]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Portfolio]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.CampaignCostType]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.ProductReviewStatus]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdGroupReviewStatus]:
    ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Status]:
    ProductAdsApiColumnGroupIdentifier.ChannelSettingsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Bid]:
    ProductAdsApiColumnGroupIdentifier.ChannelSettingsFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Impressions]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.Clicks]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.TotalAdSales]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdSpend]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.TotalAdSales]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdUnitsSold]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.ConversionRate]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.TotalACOS]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.TotalROAS]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.ClickThroughRate]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AvgCostPerClick]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.DirectAdSales]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.DirectACOS]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.DirectROAS]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.RelatedClickRevenue]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdConversions]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.NewToBrandSales]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.NewToBrandOrders]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.NewToBrandAdUnitsSold]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.AdvertisedSkuUnits]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [PRODUCT_ADS_API_COLUMN_NAME.OtherSkuUnits]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [SV_ADS_API_COLUMN_NAME.ViewableImpressions]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [SV_ADS_API_COLUMN_NAME.TotalAttributedSales]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
  [SV_ADS_API_COLUMN_NAME.Orders]:
    ProductAdsApiColumnGroupIdentifier.PerformanceFields,
});

export const EDIT_AD_ITEMS_API_COLUMN_DATA_MAPPING: Record<string, string> =
  Object.freeze({
    [PRODUCT_ADS_API_COLUMN_NAME.Status]: 'channelSettings.status',
    [PRODUCT_ADS_API_COLUMN_NAME.Bid]: 'channelSettings.bid.amount',
  });

// Ad-Level Profiles Helper
export enum ProfilesApiColumnIdentifier {
  // Details
  Brand = 'Brand',
  MerchantName = 'MerchantName',
  ProfileStatus = 'ProfileStatus',
  ProfileReviewStatus = 'ProfileReviewStatus',
  AdGroupName = 'AdGroupName',
  AdGroupStatus = 'AdGroupStatus',
  AdGroupReviewStatus = 'AdGroupReviewStatus',
  CampaignName = 'CampaignName',
  CampaignTargetingType = 'CampaignTargetingType',
  DailyBudget = 'DailyBudget',
  TotalBudget = 'TotalBudget',
  CampaignStatus = 'CampaignStatus',
  // Channel Setting
  HeadLine = 'HeadLine',
  ClickURL = 'ClickURL',
  Logo = 'Logo',
  // Performance
  AdSales = 'AdSales',
  AdSpend = 'AdSpend',
  ACOS = 'ACOS',
  ROAS = 'ROAS',
  ConversionRate = 'ConversionRate',
  AdUnitsSold = 'AdUnitsSold',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  CostPerClick = 'CostPerClick',
  ClickThroughRate = 'ClickThroughRate',
  DirectAdSales = 'DirectAdSales',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  RelatedClickRevenue = 'RelatedClickRevenue',
  CampaignId = 'CampaignId',
  AdGroupId = 'AdGroupId',
  AdvertisedSkuUnits = 'AdvertisedSkuUnits',
  OtherSkuUnits = 'OtherSkuUnits',
}

export const PROFILES_API_COLUMN_NAME: Record<
  ProfilesApiColumnIdentifier,
  string
> = Object.freeze({
  [ProfilesApiColumnIdentifier.Brand]: 'brand',
  [ProfilesApiColumnIdentifier.MerchantName]: 'merchantCountryId',
  [ProfilesApiColumnIdentifier.CampaignName]: 'campaignName',
  [ProfilesApiColumnIdentifier.CampaignId]: 'campaignId',
  [ProfilesApiColumnIdentifier.ProfileStatus]: 'profileStatus',
  [ProfilesApiColumnIdentifier.ProfileReviewStatus]: 'profileReviewStatus',
  [ProfilesApiColumnIdentifier.AdGroupName]: 'adGroupName',
  [ProfilesApiColumnIdentifier.AdGroupId]: 'adGroupId',
  [ProfilesApiColumnIdentifier.AdGroupStatus]: 'adGroupStatus',
  [ProfilesApiColumnIdentifier.AdGroupReviewStatus]: 'adGroupReviewStatus',
  [ProfilesApiColumnIdentifier.CampaignTargetingType]: 'campaignTargetingType',
  [ProfilesApiColumnIdentifier.CampaignStatus]: 'campaignStatus',
  [ProfilesApiColumnIdentifier.DailyBudget]: 'campaignDailyBudget',
  [ProfilesApiColumnIdentifier.TotalBudget]: 'totalBudget',
  [ProfilesApiColumnIdentifier.HeadLine]: 'headline',
  [ProfilesApiColumnIdentifier.ClickURL]: 'clickUrl',
  [ProfilesApiColumnIdentifier.Logo]: 'logo',
  [ProfilesApiColumnIdentifier.AdSales]: 'adSalesTotal',
  [ProfilesApiColumnIdentifier.AdSpend]: 'adSpend',
  [ProfilesApiColumnIdentifier.ACOS]: 'acosTotal',
  [ProfilesApiColumnIdentifier.ROAS]: 'roasTotal',
  [ProfilesApiColumnIdentifier.ConversionRate]: 'conversionRate',
  [ProfilesApiColumnIdentifier.AdUnitsSold]: 'unitsSold',
  [ProfilesApiColumnIdentifier.Impressions]: 'impressions',
  [ProfilesApiColumnIdentifier.Clicks]: 'clicks',
  [ProfilesApiColumnIdentifier.CostPerClick]: 'avgCostPerClick',
  [ProfilesApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
  [ProfilesApiColumnIdentifier.DirectAdSales]: 'adSalesDirect',
  [ProfilesApiColumnIdentifier.DirectACOS]: 'acosDirect',
  [ProfilesApiColumnIdentifier.DirectROAS]: 'roasDirect',
  [ProfilesApiColumnIdentifier.RelatedClickRevenue]: 'relatedClickRevenue',
  [ProfilesApiColumnIdentifier.AdvertisedSkuUnits]: 'advertisedSkuUnits',
  [ProfilesApiColumnIdentifier.OtherSkuUnits]: 'otherSkuUnits',
});

export const PROFILE_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  ProfileDataApiColumnGroupIdentifier
> = Object.freeze({
  [PROFILES_API_COLUMN_NAME.Brand]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.MerchantName]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.CampaignName]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.CampaignId]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.ProfileReviewStatus]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.AdGroupName]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.AdGroupId]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.AdGroupStatus]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.AdGroupReviewStatus]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.CampaignTargetingType]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.CampaignStatus]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.DailyBudget]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.TotalBudget]:
    ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields,
  [PROFILES_API_COLUMN_NAME.ProfileStatus]:
    ProfileDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [PROFILES_API_COLUMN_NAME.HeadLine]:
    ProfileDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [PROFILES_API_COLUMN_NAME.ClickURL]:
    ProfileDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [PROFILES_API_COLUMN_NAME.Logo]:
    ProfileDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [PROFILES_API_COLUMN_NAME.AdSales]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.AdSpend]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.ACOS]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.ROAS]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.ConversionRate]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.AdUnitsSold]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.Impressions]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.Clicks]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.CostPerClick]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.ClickThroughRate]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.DirectAdSales]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.DirectACOS]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.DirectROAS]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.RelatedClickRevenue]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.AdvertisedSkuUnits]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
  [PROFILES_API_COLUMN_NAME.OtherSkuUnits]:
    ProfileDataApiColumnGroupIdentifier.PerformanceFields,
});

export const EDIT_PROFILE_API_COLUMN_DATA_MAPPING: Record<string, string> =
  Object.freeze({
    [PROFILES_API_COLUMN_NAME.ProfileReviewStatus]:
      'profileDetails.profileReviewStatus.reviewStatus',
    [PROFILES_API_COLUMN_NAME.ProfileStatus]: 'channelSettings.profileStatus',
    [PROFILES_API_COLUMN_NAME.Brand]: 'profileDetails.brand',
    [PROFILES_API_COLUMN_NAME.HeadLine]: 'channelSettings.headline',
    [PROFILES_API_COLUMN_NAME.ClickURL]: 'channelSettings.clickUrl',
  });

//Ad-Level Targets Helpers
export enum TargetsApiColumnIdentifier {
  TargetText = 'TargetText',
  MatchType = 'MatchType',
  FlywheelAiEnabled = 'FlywheelAiEnabled',
  DateAdded = 'DateAdded',
  AdGroupName = 'AdGroupName',
  AdGroupStatus = 'AdGroupStatus',
  CampaignName = 'CampaignName',
  CampaignType = 'CampaignType',
  CampaignTargetingType = 'CampaignTargetingType',
  CampaignStatus = 'CampaignStatus',
  TargetStatus = 'TargetStatus',
  Bid = 'Bid',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  AdSpend = 'AdSpend',
  ACOS = 'ACOS',
  ROAS = 'ROAS',
  CostPerClick = 'CostPerClick',
  TotalAdSales = 'TotalAdSales',
  UnitsSold = 'UnitsSold',
  TotalConversionRate = 'TotalConversionRate',
  TotalACOS = 'TotalACOS',
  TotalROAS = 'TotalROAS',
  ClickThroughRate = 'ClickThroughRate',
  AverageCostPerClick = 'AverageCostPerClick',
  DirectAdSales = 'DirectAdSales',
  DirectACOS = 'DirectACOS',
  DirectROAS = 'DirectROAS',
  RelatedClickRevenue = 'RelatedClickRevenue',
  TargetingExpression = 'TargetingExpression',
  ExpressionType = 'ExpressionType',
  CampaignTactic = 'CampaignTactic',
  ConversionRate = 'ConversionRate',
  AdUnitsSold = 'AdUnitsSold',
  KeywordReviewStatus = 'KeywordReviewStatus',
  AdGroupReviewStatus = 'AdGroupReviewStatus',
  // Amazon specific columns
  DailyBudget = 'DailyBudget',
  TotalBudget = 'TotalBudget',
  Keyword = 'Keyword',
  CampaignAdFormat = 'CampaignAdFormat',
  AdConversions = 'AdConversions',
  NewToBrandOrders = 'NewToBrandOrders',
  PercentageOfOrdersNewToBrand = 'PercentageOfOrdersNewToBrand',
  NewToBrandOrdersRate = 'NewToBrandOrdersRate',
  NewToBrandSales = 'NewToBrandSales',
  PercentageOfSalesNewToBrand = 'PercentageOfSalesNewToBrand',
  NewToBrandAdUnitsSold = 'NewToBrandAdUnitsSold',
  PercentageOfAdUnitsSoldNewToBrand = 'PercentageOfAdUnitsSoldNewToBrand',
  MerchantName = 'MerchantName',
  ViewsTargetText = 'ViewsTargetText',
  StartDate = 'StartDate',
  Portfolio = 'Portfolio',
  CampaignCostType = 'CampaignCostType',
  IsBiddable = 'IsBiddable',
  EntityType = 'EntityType',
  TargetLabels = 'TargetLabels',
  AddedBy = 'AddedBy',
  KeywordType = 'KeywordType',
  AdvertisedSkuUnits = 'AdvertisedSkuUnits',
  OtherSkuUnits = 'OtherSkuUnits',

  TotalAttributedSales = 'TotalAttributedSales',
  Orders = 'Orders',
  VideoImpressions = 'VideoImpressions',
  ViewableImpressions = 'ViewableImpressions',
  ViewThroughRate = 'ViewThroughRate',
  ClickThroughRateForViews = 'ClickThroughRateForViews',
  ViewThroughAttributedSales = 'ViewThroughAttributedSales',
  ViewThroughOrders = 'ViewThroughOrders',
  ViewThroughUnitsSold = 'ViewThroughUnitsSold',
  TotalCompleteViewAttributedSales = 'TotalCompleteViewAttributedSales',
  AdvertisedCompleteViewAttributedSales = 'AdvertisedCompleteViewAttributedSales',
  OtherCompleteViewAttributedSales = 'OtherCompleteViewAttributedSales',
  CompleteViewOrders = 'CompleteViewOrders',
  CompleteViewUnitsSold = 'CompleteViewUnitsSold',
  VideoFirstQuartileViews = 'VideoFirstQuartileViews',
  VideoMidpointViews = 'VideoMidpointViews',
  VideoThirdQuartileViews = 'VideoThirdQuartileViews',
  VideoCompleteViews = 'VideoCompleteViews',
  VideoUnmutes = 'VideoUnmutes',
  FiveSecondViews = 'FiveSecondViews',
  FiveSecondViewRate = 'FiveSecondViewRate',
}

export const TARGETS_API_COLUMN_NAME: Record<
  TargetsApiColumnIdentifier,
  string
> = Object.freeze({
  [TargetsApiColumnIdentifier.ViewsTargetText]: 'viewsTargetText',
  [TargetsApiColumnIdentifier.TargetText]: 'targetText',
  [TargetsApiColumnIdentifier.MatchType]: 'matchType',
  [TargetsApiColumnIdentifier.KeywordReviewStatus]: 'keywordReviewStatus',
  [TargetsApiColumnIdentifier.DateAdded]: 'createdAt',
  [TargetsApiColumnIdentifier.FlywheelAiEnabled]: 'flywheelAiEnabled',
  [TargetsApiColumnIdentifier.AdGroupName]: 'adGroupName',
  [TargetsApiColumnIdentifier.AdGroupStatus]: 'adGroupStatus',
  [TargetsApiColumnIdentifier.AdGroupReviewStatus]: 'adGroupReviewStatus',
  [TargetsApiColumnIdentifier.CampaignName]: 'campaignName',
  [TargetsApiColumnIdentifier.CampaignType]: 'campaignType',
  [TargetsApiColumnIdentifier.CampaignTargetingType]: 'campaignTargetingType',
  [TargetsApiColumnIdentifier.CampaignStatus]: 'campaignStatus',
  [TargetsApiColumnIdentifier.TargetStatus]: 'targetStatus',
  [TargetsApiColumnIdentifier.Bid]: 'bid',
  [TargetsApiColumnIdentifier.Impressions]: 'impressions',
  [TargetsApiColumnIdentifier.Clicks]: 'clicks',
  [TargetsApiColumnIdentifier.AdSpend]: 'adSpend',
  [TargetsApiColumnIdentifier.ACOS]: 'ACOS',
  [TargetsApiColumnIdentifier.ROAS]: 'ROAS',
  [TargetsApiColumnIdentifier.CostPerClick]: 'avgCostPerClick',
  [TargetsApiColumnIdentifier.TotalAdSales]: 'adSalesTotal',
  [TargetsApiColumnIdentifier.UnitsSold]: 'unitsSold',
  [TargetsApiColumnIdentifier.TotalConversionRate]: 'conversionRate',
  [TargetsApiColumnIdentifier.AdUnitsSold]: 'adUnitsSold',
  [TargetsApiColumnIdentifier.TotalACOS]: 'acosTotal',
  [TargetsApiColumnIdentifier.TotalROAS]: 'roasTotal',
  [TargetsApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
  [TargetsApiColumnIdentifier.AverageCostPerClick]: 'avgCostPerClick',
  [TargetsApiColumnIdentifier.DirectAdSales]: 'adSalesDirect',
  [TargetsApiColumnIdentifier.DirectACOS]: 'acosDirect',
  [TargetsApiColumnIdentifier.DirectROAS]: 'roasDirect',
  [TargetsApiColumnIdentifier.RelatedClickRevenue]: 'relatedClickRevenue',
  [TargetsApiColumnIdentifier.ExpressionType]: 'targetingExpressionType',
  [TargetsApiColumnIdentifier.CampaignTactic]: 'campaignTactic',
  [TargetsApiColumnIdentifier.NewToBrandSales]: 'newToBrandSales',
  [TargetsApiColumnIdentifier.ConversionRate]: 'conversionRate',
  [TargetsApiColumnIdentifier.TargetingExpression]: 'targetingExpression',
  [TargetsApiColumnIdentifier.DailyBudget]: 'campaignDailyBudget',
  [TargetsApiColumnIdentifier.TotalBudget]: 'totalBudget',
  [TargetsApiColumnIdentifier.Keyword]: 'nativeLanguageKeyword',
  [TargetsApiColumnIdentifier.CampaignAdFormat]: 'campaignAdFormat',
  [TargetsApiColumnIdentifier.AdConversions]: 'adConversions',
  [TargetsApiColumnIdentifier.NewToBrandOrders]: 'newToBrandOrders',
  [TargetsApiColumnIdentifier.PercentageOfOrdersNewToBrand]:
    'newToBrandOrdersPercentage',
  [TargetsApiColumnIdentifier.NewToBrandOrdersRate]: 'newToBrandOrderRate',
  [TargetsApiColumnIdentifier.PercentageOfSalesNewToBrand]:
    'newToBrandSalesPercentage',
  [TargetsApiColumnIdentifier.NewToBrandAdUnitsSold]: 'newToBrandAdUnitsSold',
  [TargetsApiColumnIdentifier.PercentageOfAdUnitsSoldNewToBrand]:
    'newToBrandAdUnitsSoldPercentage',
  [TargetsApiColumnIdentifier.MerchantName]: 'merchantCountryId',
  [TargetsApiColumnIdentifier.StartDate]: 'startDate',
  [TargetsApiColumnIdentifier.Portfolio]: 'portfolio',
  [TargetsApiColumnIdentifier.CampaignCostType]: 'campaignCostType',
  [TargetsApiColumnIdentifier.IsBiddable]: 'isBiddable',
  [TargetsApiColumnIdentifier.EntityType]: 'entityType',
  [TargetsApiColumnIdentifier.TargetLabels]: 'targetLabel',
  [TargetsApiColumnIdentifier.AddedBy]: 'addedBy',
  [TargetsApiColumnIdentifier.KeywordType]: 'keywordType',
  [CampaignsApiColumnIdentifier.AdvertisedSkuUnits]: 'advertisedSkuUnits',
  [CampaignsApiColumnIdentifier.OtherSkuUnits]: 'otherSkuUnits',

  [TargetsApiColumnIdentifier.TotalAttributedSales]: 'adSalesTotal',
  [TargetsApiColumnIdentifier.Orders]: 'orders',
  [TargetsApiColumnIdentifier.VideoImpressions]: 'videoImpressions',
  [TargetsApiColumnIdentifier.ViewableImpressions]: 'viewableImpressions',
  [TargetsApiColumnIdentifier.ViewThroughRate]: 'viewThroughRate',
  [TargetsApiColumnIdentifier.ClickThroughRateForViews]:
    'clickThroughRateForViews',
  [TargetsApiColumnIdentifier.ViewThroughAttributedSales]:
    'viewThroughAttributedSales',
  [TargetsApiColumnIdentifier.ViewThroughOrders]: 'viewThroughOrders',
  [TargetsApiColumnIdentifier.ViewThroughUnitsSold]: 'viewThroughUnitsSold',
  [TargetsApiColumnIdentifier.TotalCompleteViewAttributedSales]:
    'totalCompleteViewAttributedSales',
  [TargetsApiColumnIdentifier.AdvertisedCompleteViewAttributedSales]:
    'advertisedCompleteViewAttributedSales',
  [TargetsApiColumnIdentifier.OtherCompleteViewAttributedSales]:
    'otherCompleteViewAttributedSales',
  [TargetsApiColumnIdentifier.CompleteViewOrders]: 'completeViewOrder',
  [TargetsApiColumnIdentifier.CompleteViewUnitsSold]: 'completeViewUnitsSold',
  [TargetsApiColumnIdentifier.VideoFirstQuartileViews]:
    'videoFirstQuartileViews',
  [TargetsApiColumnIdentifier.VideoMidpointViews]: 'videoMidpointViews',
  [TargetsApiColumnIdentifier.VideoThirdQuartileViews]:
    'videoThirdQuartileViews',
  [TargetsApiColumnIdentifier.VideoCompleteViews]: 'videoCompleteViews',
  [TargetsApiColumnIdentifier.VideoUnmutes]: 'videoUnmutes',
  [TargetsApiColumnIdentifier.FiveSecondViews]: 'videoFiveSecondViews',
  [TargetsApiColumnIdentifier.FiveSecondViewRate]: 'videoFiveSecondViewsRate',
});

//SB ADs Helpers
export enum SbAdsApiColumnIdentifier {
  // Details
  Name = 'Name',
  CreativeType = 'CreativeType',
  ServingStatus = 'ServingStatus',
  CampaignName = 'CampaignName',
  CampaignStatus = 'CampaignStatus',
  DailyBudget = 'DailyBudget',
  Portfolio = 'Portfolio',
  AdGroupName = 'AdGroupName',
  AdGroupStatus = 'AdGroupStatus',

  // Channel settings
  AdStatus = 'AdStatus',

  // Performance
  AdSales = 'AdSales',
  AdSpend = 'AdSpend',
  ACOS = 'ACOS',
  ROAS = 'ROAS',
  AdConversions = 'AdConversions',
  ConversionRate = 'ConversionRate',
  AdUnitsSold = 'AdUnitsSold',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  CostPerClick = 'CostPerClick',
  ClickThroughRate = 'ClickThroughRate',
  NewToBrandOrders = 'NewToBrandOrders',
  PercentageOfOrdersNewToBrand = 'PercentageOfOrdersNewToBrand',
  NewToBrandOrdersRate = 'NewToBrandOrdersRate',
  NewToBrandSales = 'NewToBrandSales',
  PercentageOfSalesNewToBrand = 'PercentageOfSalesNewToBrand',
  NewToBrandAdUnitsSold = 'NewToBrandAdUnitsSold',
  PercentageOfAdUnitsSoldNewToBrand = 'PercentageOfAdUnitsSoldNewToBrand',
  MerchantName = 'MerchantName',
}

export const SB_ADS_API_COLUMN_NAME: Record<SbAdsApiColumnIdentifier, string> =
  Object.freeze({
    [SbAdsApiColumnIdentifier.Name]: 'creativeName',
    [SbAdsApiColumnIdentifier.CreativeType]: 'creativeType',
    [SbAdsApiColumnIdentifier.ServingStatus]: 'creativeServingStatus',
    [SbAdsApiColumnIdentifier.CampaignName]: 'campaignName',
    [SbAdsApiColumnIdentifier.CampaignStatus]: 'campaignStatus',
    [SbAdsApiColumnIdentifier.DailyBudget]: 'campaignDailyBudget',
    [SbAdsApiColumnIdentifier.Portfolio]: 'portfolio',
    [SbAdsApiColumnIdentifier.AdGroupName]: 'adGroupName',
    [SbAdsApiColumnIdentifier.AdGroupStatus]: 'adGroupStatus',
    [SbAdsApiColumnIdentifier.AdStatus]: 'creativeExtStatus',
    [SbAdsApiColumnIdentifier.AdSales]: 'adSalesTotal',
    [SbAdsApiColumnIdentifier.AdSpend]: 'adSpend',
    [SbAdsApiColumnIdentifier.ACOS]: 'acosTotal',
    [SbAdsApiColumnIdentifier.ROAS]: 'roasTotal',
    [SbAdsApiColumnIdentifier.AdConversions]: 'adConversions',
    [SbAdsApiColumnIdentifier.ConversionRate]: 'conversionRate',
    [SbAdsApiColumnIdentifier.AdUnitsSold]: 'unitsSold',
    [SbAdsApiColumnIdentifier.Impressions]: 'impressions',
    [SbAdsApiColumnIdentifier.Clicks]: 'clicks',
    [SbAdsApiColumnIdentifier.CostPerClick]: 'avgCostPerClick',
    [SbAdsApiColumnIdentifier.ClickThroughRate]: 'clickThroughRate',
    [SbAdsApiColumnIdentifier.NewToBrandOrders]: 'newToBrandOrders',
    [SbAdsApiColumnIdentifier.PercentageOfOrdersNewToBrand]:
      'newToBrandOrdersPercentage',
    [SbAdsApiColumnIdentifier.NewToBrandOrdersRate]: 'newToBrandOrderRate',
    [SbAdsApiColumnIdentifier.NewToBrandSales]: 'newToBrandSales',
    [SbAdsApiColumnIdentifier.PercentageOfSalesNewToBrand]:
      'newToBrandSalesPercentage',
    [SbAdsApiColumnIdentifier.NewToBrandAdUnitsSold]: 'newToBrandAdUnitsSold',
    [SbAdsApiColumnIdentifier.PercentageOfAdUnitsSoldNewToBrand]:
      'newToBrandAdUnitsSoldPercentage',
    [SbAdsApiColumnIdentifier.MerchantName]: 'merchantCountryId',
  });

export const SB_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  SbAdsApiColumnGroupIdentifier
> = Object.freeze({
  [SB_ADS_API_COLUMN_NAME.Name]:
    SbAdsApiColumnGroupIdentifier.CreativeDetailsFields,
  [SB_ADS_API_COLUMN_NAME.CreativeType]:
    SbAdsApiColumnGroupIdentifier.CreativeDetailsFields,
  [SB_ADS_API_COLUMN_NAME.ServingStatus]:
    SbAdsApiColumnGroupIdentifier.CreativeDetailsFields,
  [SB_ADS_API_COLUMN_NAME.AdGroupName]:
    SbAdsApiColumnGroupIdentifier.CreativeDetailsFields,
  [SB_ADS_API_COLUMN_NAME.AdGroupStatus]:
    SbAdsApiColumnGroupIdentifier.CreativeDetailsFields,
  [SB_ADS_API_COLUMN_NAME.CampaignName]:
    SbAdsApiColumnGroupIdentifier.CreativeDetailsFields,
  [SB_ADS_API_COLUMN_NAME.CampaignStatus]:
    SbAdsApiColumnGroupIdentifier.CreativeDetailsFields,
  [SB_ADS_API_COLUMN_NAME.DailyBudget]:
    SbAdsApiColumnGroupIdentifier.CreativeDetailsFields,
  [SB_ADS_API_COLUMN_NAME.Portfolio]:
    SbAdsApiColumnGroupIdentifier.CreativeDetailsFields,
  [SB_ADS_API_COLUMN_NAME.AdStatus]:
    SbAdsApiColumnGroupIdentifier.ChannelSettingsFields,
  [SB_ADS_API_COLUMN_NAME.Impressions]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.ConversionRate]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.AdUnitsSold]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.Clicks]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.AdSpend]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.AdSales]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.ACOS]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.ROAS]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.CostPerClick]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.ClickThroughRate]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.AdConversions]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.NewToBrandOrders]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.PercentageOfOrdersNewToBrand]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.NewToBrandOrdersRate]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.NewToBrandSales]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.PercentageOfSalesNewToBrand]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.NewToBrandAdUnitsSold]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
  [SB_ADS_API_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
    SbAdsApiColumnGroupIdentifier.PerformanceFields,
});

export const SV_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  SvAdApiColumnGroupIdentifier
> = Object.freeze({
  [SV_ADS_API_COLUMN_NAME.Name]:
    SvAdApiColumnGroupIdentifier.CreativeDetailsFields,
  [SV_ADS_API_COLUMN_NAME.CreativeType]:
    SvAdApiColumnGroupIdentifier.CreativeDetailsFields,
  [SV_ADS_API_COLUMN_NAME.ServingStatus]:
    SvAdApiColumnGroupIdentifier.CreativeDetailsFields,
  [SV_ADS_API_COLUMN_NAME.AdGroupName]:
    SvAdApiColumnGroupIdentifier.CreativeDetailsFields,
  [SV_ADS_API_COLUMN_NAME.CampaignName]:
    SvAdApiColumnGroupIdentifier.CreativeDetailsFields,
  [SV_ADS_API_COLUMN_NAME.AdStatus]:
    SvAdApiColumnGroupIdentifier.ChannelSettingsFields,
  [SV_ADS_API_COLUMN_NAME.Impressions]:
    SvAdApiColumnGroupIdentifier.PerformanceFields,
  [SV_ADS_API_COLUMN_NAME.Clicks]:
    SvAdApiColumnGroupIdentifier.PerformanceFields,
});

export const MANDATORY_TARGETS_COLUMNS_TARGET_DETAILS: Record<
  FlywheelSalesChannel,
  Record<AdLevel, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdLevel.Campaigns]: [],
    [AdLevel.AdGroups]: [],
    [AdLevel.ProductAds]: [],
    [AdLevel.Targets]: [
      TARGETS_API_COLUMN_NAME.TargetText,
      TARGETS_API_COLUMN_NAME.ExpressionType,
      TARGETS_API_COLUMN_NAME.CampaignAdFormat,
      TARGETS_API_COLUMN_NAME.DailyBudget,
      TARGETS_API_COLUMN_NAME.ViewsTargetText,
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
      TARGETS_API_COLUMN_NAME.EntityType,
    ],

    [AdLevel.KeywordTargets]: [
      TARGETS_API_COLUMN_NAME.CampaignAdFormat,
      TARGETS_API_COLUMN_NAME.DailyBudget,
      TARGETS_API_COLUMN_NAME.AdGroupName,
      TARGETS_API_COLUMN_NAME.AdGroupStatus,
      TARGETS_API_COLUMN_NAME.CampaignStatus,
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
      TARGETS_API_COLUMN_NAME.IsBiddable,
      TARGETS_API_COLUMN_NAME.EntityType,
    ],
    [AdLevel.ProductTargets]: [
      TARGETS_API_COLUMN_NAME.TargetText,
      TARGETS_API_COLUMN_NAME.ExpressionType,
      TARGETS_API_COLUMN_NAME.CampaignAdFormat,
      TARGETS_API_COLUMN_NAME.DailyBudget,
      TARGETS_API_COLUMN_NAME.AdGroupName,
      TARGETS_API_COLUMN_NAME.AdGroupStatus,
      TARGETS_API_COLUMN_NAME.ViewsTargetText,
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
      TARGETS_API_COLUMN_NAME.EntityType,
    ],
    [AdLevel.Profiles]: [],
    [AdLevel.SbAds]: [],
    [AdLevel.SvAds]: [],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdLevel.Campaigns]: [],
    [AdLevel.AdGroups]: [],
    [AdLevel.ProductAds]: [],
    [AdLevel.KeywordTargets]: [
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
    ],
    [AdLevel.ProductTargets]: [
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
    ],
    [AdLevel.Targets]: [
      TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      TARGETS_API_COLUMN_NAME.StartDate,
    ],
    [AdLevel.Profiles]: [],
    [AdLevel.SbAds]: [],
    [AdLevel.SvAds]: [],
  },
};

export const MANDATORY_CHANNEL_SETTINGS_TARGETS_COLUMNS: Record<
  FlywheelSalesChannel,
  Record<AdType, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [TARGETS_API_COLUMN_NAME.TargetStatus],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [],
    [AdType.SponsoredProducts]: [TARGETS_API_COLUMN_NAME.TargetStatus],
    [AdType.SponsoredVideos]: [],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [],
    [AdType.SponsoredProducts]: [],
    [AdType.SponsoredVideos]: [],
  },
};

export const AD_LEVEL_API_COLUMNS_TO_EXCLUDE: Record<
  FlywheelSalesChannel,
  Record<AdLevel, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdLevel.Campaigns]: [],
    [AdLevel.AdGroups]: [],
    [AdLevel.ProductAds]: [],
    [AdLevel.Targets]: [
      TARGETS_API_COLUMN_NAME.TargetingExpression,
      TARGETS_API_COLUMN_NAME.TargetLabels,
    ],
    [AdLevel.KeywordTargets]: [TARGETS_API_COLUMN_NAME.TargetingExpression],
    [AdLevel.ProductTargets]: [
      TARGETS_API_COLUMN_NAME.TargetingExpression,
      TARGETS_API_COLUMN_NAME.TargetLabels,
    ],
    [AdLevel.Profiles]: [],
    [AdLevel.SbAds]: [],
    [AdLevel.SvAds]: [],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdLevel.Campaigns]: [],
    [AdLevel.AdGroups]: [ADGROUPS_API_COLUMN_NAME.MerchantName],
    [AdLevel.ProductAds]: [],
    [AdLevel.KeywordTargets]: [],
    [AdLevel.ProductTargets]: [TARGETS_API_COLUMN_NAME.TargetLabels],
    [AdLevel.Targets]: [
      TARGETS_API_COLUMN_NAME.MerchantName,
      TARGETS_API_COLUMN_NAME.TargetLabels,
    ],
    [AdLevel.Profiles]: [],
    [AdLevel.SbAds]: [SB_ADS_API_COLUMN_NAME.MerchantName],
    [AdLevel.SvAds]: [],
  },
};

export const TARGETS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER: Record<
  string,
  TargetsDataApiColumnGroupIdentifier
> = Object.freeze({
  [TARGETS_API_COLUMN_NAME.TargetText]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.TargetLabels]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.AddedBy]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.MerchantName]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.MatchType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.DateAdded]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.KeywordType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.AdGroupName]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.AdGroupStatus]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignName]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignTargetingType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignStatus]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.TargetingExpression]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.ExpressionType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignTactic]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.DailyBudget]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.TotalBudget]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.Portfolio]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignCostType]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.KeywordReviewStatus]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.AdGroupReviewStatus]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.TargetStatus]:
    TargetsDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [TARGETS_API_COLUMN_NAME.Bid]:
    TargetsDataApiColumnGroupIdentifier.ChannelSettingsFields,
  [TARGETS_API_COLUMN_NAME.Impressions]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.ConversionRate]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.AdUnitsSold]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.Clicks]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.AdSpend]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.TotalAdSales]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.UnitsSold]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.TotalConversionRate]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.TotalACOS]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.TotalROAS]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.ClickThroughRate]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.AverageCostPerClick]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.DirectAdSales]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.DirectACOS]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.DirectROAS]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.RelatedClickRevenue]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.DailyBudget]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.TotalBudget]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.Keyword]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.CampaignAdFormat]:
    TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields,
  [TARGETS_API_COLUMN_NAME.AdConversions]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.NewToBrandOrders]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.PercentageOfOrdersNewToBrand]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.NewToBrandOrdersRate]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.NewToBrandSales]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.PercentageOfSalesNewToBrand]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.NewToBrandAdUnitsSold]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.AdvertisedSkuUnits]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.OtherSkuUnits]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,

  [TARGETS_API_COLUMN_NAME.TotalAttributedSales]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.Orders]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.VideoImpressions]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.ViewableImpressions]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.ViewThroughRate]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.ClickThroughRateForViews]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.ViewThroughAttributedSales]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.ViewThroughOrders]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.ViewThroughUnitsSold]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.TotalCompleteViewAttributedSales]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.AdvertisedCompleteViewAttributedSales]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.OtherCompleteViewAttributedSales]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.CompleteViewOrders]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.CompleteViewUnitsSold]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.VideoFirstQuartileViews]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.VideoMidpointViews]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.VideoThirdQuartileViews]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.VideoCompleteViews]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.VideoUnmutes]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.FiveSecondViews]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
  [TARGETS_API_COLUMN_NAME.FiveSecondViewRate]:
    TargetsDataApiColumnGroupIdentifier.PerformanceFields,
});

export const EDIT_TARGETS_API_COLUMN_DATA_MAPPING: Record<string, string> =
  Object.freeze({
    [TARGETS_API_COLUMN_NAME.TargetStatus]: 'channelSettings.status',
    [TARGETS_API_COLUMN_NAME.Bid]: 'channelSettings.bid.amount',
    [TARGETS_API_COLUMN_NAME.TargetLabels]: 'targetDetails.targetType',
  });

//Editable Columns
export enum EditableColumnsCampaign {
  buyBoxBidMultiplier = 'buyBoxBidMultiplier',
  campaignName = 'campaignName',
  carouselStatus = 'carouselStatus',
  itemBuyBox = 'itemBuyBox',
  itemCarousel = 'itemCarousel',
  campaignDailyBudget = 'campaignDailyBudget',
  desktopBidMultiplier = 'desktopBidMultiplier',
  endDate = 'endDate',
  bidOptimization = 'bidOptimization',
  belowTopOfSearchBidMultiplier = 'belowTopOfSearchBidMultiplier',
  homePageBidMultiplier = 'homePageBidMultiplier',
  appBidMultiplier = 'appBidMultiplier',
  mobileBidMultiplier = 'mobileBidMultiplier',
  searchIngridBidMultiplier = 'searchIngridBidMultiplier',
  status = 'status',
  stockUpBidMultiplier = 'stockUpBidMultiplier',
  totalBudget = 'totalBudget',
  budgetRollover = 'budgetRollover',
  automationStatus = 'automationStatus',
  macsTarget = 'macsTarget',
  minBid = 'minBid',
  maxBid = 'maxBid',
  portfolio = 'portfolio',
  groupLabel = 'groupLabel',
  biddingStrategy = 'biddingStrategy',
  topOfSearchBidMultiplier = 'topOfSearchBidMultiplier',
  productPageBidMultiplier = 'productPageBidMultiplier',
  advertisingGoal = 'advertisingGoal',
}

export enum EditableColumnsAdGroup {
  automationStatus = 'automationStatus',
  aiBidding = 'aiBidding',
  macsTarget = 'macsTarget',
  bidModifier = 'bidModifier',
  isOverrideBidModifier = 'isOverrideBidModifier',
  overrideEndTime = 'overrideEndTime',
  minBid = 'minBid',
  minBidOverrideEnabled = 'minBidOverrideEnabled',
  maxBid = 'maxBid',
  maxBidOverrideEnabled = 'maxBidOverrideEnabled',
  name = 'adGroupName',
  status = 'status',
  adGroupDefaultBid = 'adGroupDefaultBid',
  adGroupReviewStatus = 'adGroupReviewStatus',
  aiRecommendationKeywordSetting = 'aiRecommendationKeywordSetting',
  adGroupLabel = 'adGroupLabel',
  // This field is used only for getting the old fields and not really edit in adgroups section
  advertisingGoal = 'advertisingGoal',
}

export enum EditableColumnsAdItem {
  bid = 'bid',
  status = 'status',
}

export enum EditableColumnsTarget {
  bid = 'bid',
  status = 'targetStatus',
  label = 'targetLabel',
}

export enum EditableColumnsProfile {
  brand = 'brand',
  headline = 'headline',
  clickUrl = 'clickUrl',
  profileStatus = 'profileStatus',
}

export enum EditableColumnsSbAd {
  creativeName = 'creativeName',
  adStatus = 'creativeExtStatus',
}

export enum EditableColumnsSvAds {
  status = 'status',
}

export const TABLE_UNIQ_KEY: Record<string, string> = Object.freeze({
  [ADS_MANAGER_CAMPAIGNS_TABLE_ID]: 'campaignId',
  [ADS_MANAGER_ADGROUP_TABLE_ID]: 'adGroupId',
  [ADS_MANAGER_AD_ITEMS_TABLE_ID]: 'adItemId',
  [ADS_MANAGER_PROFILES_TABLE_ID]: 'profileId',
  [ADS_MANAGER_TARGETS_TABLE_ID]: 'targetId',
  [ADS_MANAGER_SB_ADS_TABLE_ID]: 'creativeId',
});

// Sales channels mapping to default ad level columns
export const FLYWHEEL_SALES_CHANNEL_TO_DEFAULT_AD_LEVEL_COLUMNS_MAPPER: Record<
  FlywheelSalesChannel,
  Record<AdLevel, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdLevel.Campaigns]: [CAMPAIGNS_API_COLUMN_NAME.CampaignName],
    [AdLevel.AdGroups]: [ADGROUPS_API_COLUMN_NAME.AdGroupName],
    [AdLevel.KeywordTargets]: [TARGETS_API_COLUMN_NAME.Keyword],
    [AdLevel.Targets]: [TARGETS_API_COLUMN_NAME.TargetingExpression],
    [AdLevel.ProductAds]: [
      PRODUCT_ADS_API_COLUMN_NAME.ProductName,
      PRODUCT_ADS_API_COLUMN_NAME.ProductImage,
    ],
    [AdLevel.ProductTargets]: [TARGETS_API_COLUMN_NAME.TargetingExpression],
    [AdLevel.Profiles]: [], // TODO: as part of IFD-229
    [AdLevel.SbAds]: [SB_ADS_API_COLUMN_NAME.Name],
    [AdLevel.SvAds]: [],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdLevel.Campaigns]: [CAMPAIGNS_API_COLUMN_NAME.CampaignName],
    [AdLevel.AdGroups]: [ADGROUPS_API_COLUMN_NAME.AdGroupName],
    [AdLevel.ProductAds]: [
      PRODUCT_ADS_API_COLUMN_NAME.ProductName,
      PRODUCT_ADS_API_COLUMN_NAME.ProductImage,
    ],
    [AdLevel.KeywordTargets]: [TARGETS_API_COLUMN_NAME.TargetText],
    [AdLevel.ProductTargets]: [],
    [AdLevel.Targets]: [],
    [AdLevel.Profiles]: [PROFILES_API_COLUMN_NAME.Brand],
    [AdLevel.SbAds]: [],
    [AdLevel.SvAds]: [SV_ADS_API_COLUMN_NAME.Name],
  },
};

export const MANDATORY_CAMPAIGN_COLUMNS_CHANNEL_SETTINGS: Record<
  FlywheelSalesChannel,
  Record<AdType, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [
      CAMPAIGNS_API_COLUMN_NAME.StartDate,
      CAMPAIGNS_API_COLUMN_NAME.EndDate,
      CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
      CAMPAIGNS_API_COLUMN_NAME.BudgetType,
    ],
    [AdType.SponsoredBrandsVideo]: [
      CAMPAIGNS_API_COLUMN_NAME.StartDate,
      CAMPAIGNS_API_COLUMN_NAME.EndDate,
      CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
      CAMPAIGNS_API_COLUMN_NAME.BudgetType,
    ],
    [AdType.SponsoredDisplay]: [
      CAMPAIGNS_API_COLUMN_NAME.StartDate,
      CAMPAIGNS_API_COLUMN_NAME.EndDate,
      CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
    ],
    [AdType.SponsoredProducts]: [
      CAMPAIGNS_API_COLUMN_NAME.StartDate,
      CAMPAIGNS_API_COLUMN_NAME.EndDate,
      CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
    ],
    [AdType.SponsoredVideos]: [],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [],
    [AdType.SponsoredProducts]: [
      CAMPAIGNS_API_COLUMN_NAME.BudgetType,
      CAMPAIGNS_API_COLUMN_NAME.StartDate,
      CAMPAIGNS_API_COLUMN_NAME.EndDate,
      CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
    ],
    [AdType.SponsoredVideos]: [],
  },
};

export const MANDATORY_CAMPAIGN_COLUMNS_CAMPAIGN_DETAILS: Record<
  FlywheelSalesChannel,
  Record<AdType, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [],
    [AdType.SponsoredBrandsVideo]: [CAMPAIGNS_API_COLUMN_NAME.CampaignAdFormat],
    [AdType.SponsoredDisplay]: [CAMPAIGNS_API_COLUMN_NAME.CampaignTactic],
    [AdType.SponsoredVideos]: [],
    [AdType.SponsoredProducts]: [CAMPAIGNS_API_COLUMN_NAME.AddedBy],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [],
    [AdType.SponsoredProducts]: [
      CAMPAIGNS_API_COLUMN_NAME.CampaignTargetingType,
      CAMPAIGNS_API_COLUMN_NAME.AddedBy,
    ],
    [AdType.SponsoredVideos]: [],
  },
};

export const MANDATORY_AD_ITEMS_COLUMNS_AD_ITEM_DETAILS: Record<
  FlywheelSalesChannel,
  Record<AdType, string[]>
> = {
  [FlywheelSalesChannel.Amazon]: {
    [AdType.SearchBrandAmplifier]: [],
    [AdType.SponsoredBrands]: [],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [
      PRODUCT_ADS_API_COLUMN_NAME.Sku,
      PRODUCT_ADS_API_COLUMN_NAME.ExtItemId,
      PRODUCT_ADS_API_COLUMN_NAME.StartDate,
    ],
    [AdType.SponsoredProducts]: [
      PRODUCT_ADS_API_COLUMN_NAME.CampaignTargetingType,
      PRODUCT_ADS_API_COLUMN_NAME.Sku,
      PRODUCT_ADS_API_COLUMN_NAME.ExtItemId,
      PRODUCT_ADS_API_COLUMN_NAME.StartDate,
    ],
    [AdType.SponsoredVideos]: [],
  },
  [FlywheelSalesChannel.Walmart]: {
    [AdType.SearchBrandAmplifier]: [PRODUCT_ADS_API_COLUMN_NAME.Sku],
    [AdType.SponsoredBrands]: [],
    [AdType.SponsoredBrandsVideo]: [],
    [AdType.SponsoredDisplay]: [],
    [AdType.SponsoredProducts]: [
      PRODUCT_ADS_API_COLUMN_NAME.Sku,
      PRODUCT_ADS_API_COLUMN_NAME.CampaignTargetingType,
      PRODUCT_ADS_API_COLUMN_NAME.StartDate,
    ],
    [AdType.SponsoredVideos]: [
      PRODUCT_ADS_API_COLUMN_NAME.StartDate,
      PRODUCT_ADS_API_COLUMN_NAME.Sku,
    ],
  },
};

// Ad Level to Table Id mapper
export const getTableIdByAdLevel = (adLevel: string): string => {
  if (adLevel) {
    switch (adLevel) {
      case AdLevel.Campaigns:
        return ADS_MANAGER_CAMPAIGNS_TABLE_ID;
      case AdLevel.AdGroups:
        return ADS_MANAGER_ADGROUP_TABLE_ID;
      case AdLevel.ProductAds:
        return ADS_MANAGER_AD_ITEMS_TABLE_ID;
      case AdLevel.KeywordTargets:
      case AdLevel.ProductTargets:
      case AdLevel.Targets:
        return ADS_MANAGER_TARGETS_TABLE_ID;
      case AdLevel.Profiles:
        return ADS_MANAGER_PROFILES_TABLE_ID;
      case AdLevel.SbAds:
        return ADS_MANAGER_SB_ADS_TABLE_ID;
      case AdLevel.SvAds:
        return ADS_MANAGER_SV_ADS_TABLE_ID;
    }
  }
  return ADS_MANAGER_CAMPAIGNS_TABLE_ID;
};

export enum ProductAdStatus {
  Enabled = 'Enabled',
  Paused = 'Paused',
  Archived = 'Archived',
  Disabled = 'Disabled',
}

export enum TargetStatus {
  Enabled = 'Enabled',
  Paused = 'Paused',
  Archived = 'Archived',
  Deleted = 'Deleted',
}

export interface DropdownMetadata {
  readonly label: string;
  readonly value: string;
}

export enum ProductAdStatusValue {
  Enabled = 'enabled',
  Paused = 'paused',
  Archived = 'archived',
  Disabled = 'disabled',
}

export const ProductAdStatusOptions: Record<ProductAdStatus, DropdownMetadata> =
  {
    [ProductAdStatus.Enabled]: {
      label: ProductAdStatus.Enabled,
      value: ProductAdStatusValue.Enabled,
    },
    [ProductAdStatus.Paused]: {
      label: ProductAdStatus.Paused,
      value: ProductAdStatusValue.Paused,
    },
    [ProductAdStatus.Archived]: {
      label: ProductAdStatus.Archived,
      value: ProductAdStatusValue.Archived,
    },
    [ProductAdStatus.Disabled]: {
      label: ProductAdStatus.Disabled,
      value: ProductAdStatusValue.Disabled,
    },
  };

export const TargetStatusOptions: Record<TargetStatus, DropdownMetadata> = {
  [TargetStatus.Enabled]: {
    label: TargetStatus.Enabled,
    value: 'enabled',
  },
  [TargetStatus.Paused]: {
    label: TargetStatus.Paused,
    value: 'paused',
  },
  [TargetStatus.Archived]: {
    label: TargetStatus.Archived,
    value: 'archived',
  },
  [TargetStatus.Deleted]: {
    label: TargetStatus.Deleted,
    value: 'deleted',
  },
};

// Breadcrumb helpers
/**
 *
 * @param currentUrl
 * @returns previous URL by splicing the last token off
 *
 */
export const getPreviousUrl = (currentUrl: string): string => {
  const urlTokens = currentUrl.split('/');
  urlTokens.splice(urlTokens.length - 1, 1);
  return urlTokens.join('/');
};

export interface AdLevelFromPathInfo {
  readonly identifiedAdLevel: AdLevel;
  readonly adsManagerDetailsPageFlag: boolean;
  readonly adsManagerDetailsPageName: string;
  readonly rootAdLevelId?: string;
  readonly currentAdLevelId?: string;
}

/**
 * This function identifies the details page root for the current
 * adType | adLevel from the url path
 * @param urlPathNameList
 * @param levelDifference
 * @returns
 */
const getAdLevelDetailsRoot = (
  urlPathNameList: string[],
  levelDifference: number
): ADS_MANAGER_AD_LEVEL_PAGES_URI => {
  if (
    urlPathNameList.includes(ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups) ||
    levelDifference === 2
  ) {
    return ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups;
  }
  return ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
};

/**
 * This function identifies the ad Level type in campaign details flow
 * Flow : Campaigns -> Ad Groups -> Product ads
 */
//TODO: Need to make this generic getCampaignDetailsAdLevel -> getDetailsAdLevel after we have suffiecient/required flow
export const getCampaignDetailsAdLevel = (
  urlPathNameList: string[],
  indexOfCampaign: number
) => {
  if (urlPathNameList.length - indexOfCampaign - 1 === 1) {
    return ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
  } else if (urlPathNameList.length - indexOfCampaign - 1 === 2) {
    return ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups;
  } else {
    return ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
  }
};

export const getAdLevelInfo = (
  urlPathNameList: string[]
): AdLevelFromPathInfo => {
  const levelsUri = Object.values(ADS_MANAGER_AD_LEVEL_PAGES_URI);
  let passedAdLevel: string = ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
  let adlevelPosition = -1;
  let adsManagerDetailsPageFlag = false;
  let adsManagerDetailsPageName = ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
  let urlAdLevel: string = ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns;
  urlPathNameList = urlPathNameList.filter((ele) => !isEmpty(ele));

  /**
   * Below for is to identify if the url ends with either
   *  a.'campaigns',
   *  b.'ad-groups',
   *  c.'ad-items',
   *  d.'targets' or identifier(eg:campaignId,adgroupId),
   * This will set the adsManagerDetailsPageFlag to true if identifier is passed
   * i.e if we are drillingdown through campaigns -> adgroups -> ad-items -> targets.
   */
  for (const uriPart of levelsUri) {
    const currentIndex = urlPathNameList.indexOf(uriPart);
    if (currentIndex !== -1) {
      passedAdLevel = uriPart;
      urlAdLevel = uriPart;
      if (uriPart === ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns) {
        passedAdLevel = getCampaignDetailsAdLevel(
          urlPathNameList,
          currentIndex
        );
      }
      adlevelPosition = currentIndex;
      if (adlevelPosition !== urlPathNameList.length - 1) {
        // urlPathNameList will always contain 5 elements. Indexes 2, 3 and 4 will be Sales channel,
        // Ad type and Ad level respectively and it will always be the case going by the Navigation logic we have written.
        // For 'create-chatgpt' navigation from details page the position of 'create-chatgpt' value
        // will be on index 6 in the array, hence this logic.
        if (urlPathNameList[5] !== 'create-chatgpt') {
          adsManagerDetailsPageFlag = true;
        }
        adsManagerDetailsPageName = getAdLevelDetailsRoot(
          urlPathNameList,
          urlPathNameList.length - 1 - adlevelPosition
        );
      }
      break;
    }
  }
  //root AdLevelId from URL
  const indexOfCampaignId =
    urlPathNameList.indexOf(urlAdLevel as ADS_MANAGER_AD_LEVEL_PAGES_URI) + 1;
  const rootAdLevelId = adsManagerDetailsPageFlag
    ? urlPathNameList[indexOfCampaignId]
    : undefined;

  const adLevelId = urlPathNameList.includes('create-chatgpt')
    ? urlPathNameList[urlPathNameList.indexOf('create-chatgpt') - 1]
    : urlPathNameList[urlPathNameList.length - 1];

  const currentAdLevelId = adsManagerDetailsPageFlag ? adLevelId : undefined;
  return {
    identifiedAdLevel:
      ADS_MANAGER_PAGE_URI_TO_AD_LEVEL_MAPPER[
        passedAdLevel as ADS_MANAGER_AD_LEVEL_PAGES_URI
      ],
    adsManagerDetailsPageFlag,
    rootAdLevelId,
    adsManagerDetailsPageName,
    currentAdLevelId,
  };
};

// Generic
export const DATA_INSPECTOR_COLUMN_TO_API_COLUMN_MAPPER: Record<
  string,
  string
> = Object.freeze({
  [DATA_INSPECTOR_COLUMN_NAME.CampaignType]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignType,
  [DATA_INSPECTOR_COLUMN_NAME.AdvertisingGoal]:
    CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal,
  [DATA_INSPECTOR_COLUMN_NAME.SmartStructure]:
    CAMPAIGNS_API_COLUMN_NAME.SmartStructure,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignTargetingType,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignTactic,
  [DATA_INSPECTOR_COLUMN_NAME.Portfolio]: CAMPAIGNS_API_COLUMN_NAME.Portfolio,
  [DATA_INSPECTOR_COLUMN_NAME.Group]: CAMPAIGNS_API_COLUMN_NAME.Group,
  [DATA_INSPECTOR_COLUMN_NAME.StartDate]: CAMPAIGNS_API_COLUMN_NAME.StartDate,
  [DATA_INSPECTOR_COLUMN_NAME.EndDate]: CAMPAIGNS_API_COLUMN_NAME.EndDate,
  [DATA_INSPECTOR_COLUMN_NAME.BudgetType]: CAMPAIGNS_API_COLUMN_NAME.BudgetType,
  [DATA_INSPECTOR_COLUMN_NAME.AmazonBiddingStrategy]:
    CAMPAIGNS_API_COLUMN_NAME.AmazonBiddingStrategy,
  [DATA_INSPECTOR_COLUMN_NAME.ProductPageBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.ProductPageBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.TopOfSearchBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.TopOfSearchBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.AdConversions]:
    CAMPAIGNS_API_COLUMN_NAME.AdConversions,
  [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]:
    CAMPAIGNS_API_COLUMN_NAME.DailyBudget,
  [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]:
    CAMPAIGNS_API_COLUMN_NAME.TotalBudget,
  [DATA_INSPECTOR_COLUMN_NAME.BudgetRollover]:
    CAMPAIGNS_API_COLUMN_NAME.BudgetRollover,
  [DATA_INSPECTOR_COLUMN_NAME.SearchCarouselInclusion]:
    CAMPAIGNS_API_COLUMN_NAME.SearchCarouselInclusion,
  [DATA_INSPECTOR_COLUMN_NAME.ItemBuyBoxPlacement]:
    CAMPAIGNS_API_COLUMN_NAME.ItemBuyBoxPlacement,
  [DATA_INSPECTOR_COLUMN_NAME.ItemCarouselPlacement]:
    CAMPAIGNS_API_COLUMN_NAME.ItemCarouselPlacement,
  [DATA_INSPECTOR_COLUMN_NAME.BuyBoxBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.SearchIngridBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.SearchIngridBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.HomePageBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.HomePageBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.StockUpBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.StockUpBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.DesktopBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.DesktopBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.AppBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.MobileBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.MACSTarget]: CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
  [DATA_INSPECTOR_COLUMN_NAME.MinBid]: CAMPAIGNS_API_COLUMN_NAME.MinBid,
  [DATA_INSPECTOR_COLUMN_NAME.MaxBid]: CAMPAIGNS_API_COLUMN_NAME.MaxBid,
  [DATA_INSPECTOR_COLUMN_NAME.Impressions]:
    CAMPAIGNS_API_COLUMN_NAME.Impressions,
  [DATA_INSPECTOR_COLUMN_NAME.Clicks]: CAMPAIGNS_API_COLUMN_NAME.Clicks,
  [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: CAMPAIGNS_API_COLUMN_NAME.AdSpend,
  [DATA_INSPECTOR_COLUMN_NAME.AdSales]: CAMPAIGNS_API_COLUMN_NAME.AdSales,
  [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]:
    CAMPAIGNS_API_COLUMN_NAME.AdUnitsSold,
  [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
    CAMPAIGNS_API_COLUMN_NAME.ConversionRate,
  [DATA_INSPECTOR_COLUMN_NAME.ACOS]: CAMPAIGNS_API_COLUMN_NAME.ACOS,
  [DATA_INSPECTOR_COLUMN_NAME.ROAS]: CAMPAIGNS_API_COLUMN_NAME.ROAS,
  [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRate]:
    CAMPAIGNS_API_COLUMN_NAME.ClickThroughRate,
  [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]:
    CAMPAIGNS_API_COLUMN_NAME.CostPerClick,
  [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]:
    CAMPAIGNS_API_COLUMN_NAME.DirectAdSales,
  [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]: CAMPAIGNS_API_COLUMN_NAME.DirectACOS,
  [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]: CAMPAIGNS_API_COLUMN_NAME.DirectROAS,
  [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]:
    CAMPAIGNS_API_COLUMN_NAME.RelatedClickRevenue,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]:
    CAMPAIGNS_API_COLUMN_NAME.NewToBrandSales,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignAdFormat,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignServingStatus]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignServingStatus,
  [DATA_INSPECTOR_COLUMN_NAME.AmazonBidOptimization]:
    CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization,
  [DATA_INSPECTOR_COLUMN_NAME.BelowTopOfSearchBidMultiplier]:
    CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]:
    CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrders,
  [DATA_INSPECTOR_COLUMN_NAME.PercentageOfOrdersNewToBrand]:
    CAMPAIGNS_API_COLUMN_NAME.PercentageOfOrdersNewToBrand,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrderRate]:
    CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrdersRate,
  [DATA_INSPECTOR_COLUMN_NAME.PercentageOfSalesNewToBrand]:
    CAMPAIGNS_API_COLUMN_NAME.PercentageOfSalesNewToBrand,
  [DATA_INSPECTOR_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand]:
    CAMPAIGNS_API_COLUMN_NAME.PercentageOfAdUnitsSoldNewToBrand,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
    ADGROUPS_API_COLUMN_NAME.CampaignName,
  [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
    ADGROUPS_API_COLUMN_NAME.AdGroupName,
  [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
    ADGROUPS_API_COLUMN_NAME.AdGroupStatus,
  [DATA_INSPECTOR_COLUMN_NAME.BidAutomationStatus]:
    ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
  [DATA_INSPECTOR_COLUMN_NAME.MACSTarget]: ADGROUPS_API_COLUMN_NAME.MACSTarget,
  [DATA_INSPECTOR_COLUMN_NAME.MaxBid]: ADGROUPS_API_COLUMN_NAME.MaxBid,
  [DATA_INSPECTOR_COLUMN_NAME.MinBid]: ADGROUPS_API_COLUMN_NAME.MinBid,
  [DATA_INSPECTOR_COLUMN_NAME.TotalConversions]:
    PRODUCT_ADS_API_COLUMN_NAME.TotalConversions,
  [DATA_INSPECTOR_COLUMN_NAME.ProductName]:
    PRODUCT_ADS_API_COLUMN_NAME.ProductName,
  [DATA_INSPECTOR_COLUMN_NAME.ProductAdStatus]:
    PRODUCT_ADS_API_COLUMN_NAME.Status,
  [DATA_INSPECTOR_COLUMN_NAME.Bid]: PRODUCT_ADS_API_COLUMN_NAME.Bid,
  [DATA_INSPECTOR_COLUMN_NAME.ProductReviewStatus]:
    PRODUCT_ADS_API_COLUMN_NAME.ProductReviewStatus,
  [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]:
    PRODUCT_ADS_API_COLUMN_NAME.AdGroupReviewStatus,
  [DATA_INSPECTOR_COLUMN_NAME.TargetText]: TARGETS_API_COLUMN_NAME.TargetText,
  [DATA_INSPECTOR_COLUMN_NAME.TargetLabels]:
    TARGETS_API_COLUMN_NAME.TargetLabels,
  [DATA_INSPECTOR_COLUMN_NAME.Tags]: TARGETS_API_COLUMN_NAME.TargetLabels,
  [DATA_INSPECTOR_COLUMN_NAME.MatchType]: TARGETS_API_COLUMN_NAME.MatchType,
  [DATA_INSPECTOR_COLUMN_NAME.DateAdded]: TARGETS_API_COLUMN_NAME.DateAdded,
  [DATA_INSPECTOR_COLUMN_NAME.KeywordType]: TARGETS_API_COLUMN_NAME.KeywordType,
  [DATA_INSPECTOR_COLUMN_NAME.TargetStatus]:
    TARGETS_API_COLUMN_NAME.TargetStatus,
  [DATA_INSPECTOR_COLUMN_NAME.TargetingExpression]:
    TARGETS_API_COLUMN_NAME.TargetingExpression,
  [DATA_INSPECTOR_COLUMN_NAME.AdgroupDefaultBid]:
    ADGROUPS_API_COLUMN_NAME.AdgroupDefaultBid,
  [DATA_INSPECTOR_COLUMN_NAME.AiRecommendationKeywordSetting]:
    ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]:
    ADGROUPS_API_COLUMN_NAME.NewToBrandSales,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdConversions]:
    ADGROUPS_API_COLUMN_NAME.NewToBrandOrders,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]:
    ADGROUPS_API_COLUMN_NAME.NewToBrandAdUnitsSold,
  [DATA_INSPECTOR_COLUMN_NAME.ExpressionType]:
    TARGETS_API_COLUMN_NAME.ExpressionType,
  [DATA_INSPECTOR_COLUMN_NAME.TargetsDailyBudget]:
    TARGETS_API_COLUMN_NAME.DailyBudget,
  [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]:
    TARGETS_API_COLUMN_NAME.NewToBrandAdUnitsSold,
  [DATA_INSPECTOR_COLUMN_NAME.MerchantName]:
    CAMPAIGNS_API_COLUMN_NAME.MerchantName,
  [DATA_INSPECTOR_COLUMN_NAME.CampaignCostType]:
    CAMPAIGNS_API_COLUMN_NAME.CampaignCostType,
  [DATA_INSPECTOR_COLUMN_NAME.KeywordReviewStatus]:
    TARGETS_API_COLUMN_NAME.KeywordReviewStatus,
  [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]:
    TARGETS_API_COLUMN_NAME.AdGroupReviewStatus,
  [DATA_INSPECTOR_COLUMN_NAME.Tags]: ADGROUPS_API_COLUMN_NAME.Tags,
  [DATA_INSPECTOR_COLUMN_NAME.MerchantName]:
    CAMPAIGNS_API_COLUMN_NAME.MerchantName,
  [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: CAMPAIGNS_API_COLUMN_NAME.AddedBy,
  [DATA_INSPECTOR_COLUMN_NAME.CreatedAt]: CAMPAIGNS_API_COLUMN_NAME.CreatedAt,
  [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]:
    CAMPAIGNS_API_COLUMN_NAME.AdvertisedSkuUnits,
  [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]:
    CAMPAIGNS_API_COLUMN_NAME.OtherSkuUnits,
  [DATA_INSPECTOR_COLUMN_NAME.TotalAttributedSales]:
    ADGROUPS_API_COLUMN_NAME.TotalAttributedSales,
  [DATA_INSPECTOR_COLUMN_NAME.Orders]: ADGROUPS_API_COLUMN_NAME.Orders,
  [DATA_INSPECTOR_COLUMN_NAME.VideoImpressions]:
    ADGROUPS_API_COLUMN_NAME.VideoImpressions,
  [DATA_INSPECTOR_COLUMN_NAME.ViewableImpressions]:
    ADGROUPS_API_COLUMN_NAME.ViewableImpressions,
  [DATA_INSPECTOR_COLUMN_NAME.ViewThroughRate]:
    ADGROUPS_API_COLUMN_NAME.ViewThroughRate,
  [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRateForViews]:
    ADGROUPS_API_COLUMN_NAME.ClickThroughRateForViews,
  [DATA_INSPECTOR_COLUMN_NAME.ViewThroughAttributedSales]:
    ADGROUPS_API_COLUMN_NAME.ViewThroughAttributedSales,
  [DATA_INSPECTOR_COLUMN_NAME.ViewThroughOrders]:
    ADGROUPS_API_COLUMN_NAME.ViewThroughOrders,
  [DATA_INSPECTOR_COLUMN_NAME.ViewThroughUnitsSold]:
    ADGROUPS_API_COLUMN_NAME.ViewThroughUnitsSold,
  [DATA_INSPECTOR_COLUMN_NAME.TotalCompleteViewAttributedSales]:
    ADGROUPS_API_COLUMN_NAME.TotalCompleteViewAttributedSales,
  [DATA_INSPECTOR_COLUMN_NAME.AdvertisedCompleteViewAttributedSales]:
    ADGROUPS_API_COLUMN_NAME.AdvertisedCompleteViewAttributedSales,
  [DATA_INSPECTOR_COLUMN_NAME.OtherCompleteViewAttributedSales]:
    ADGROUPS_API_COLUMN_NAME.OtherCompleteViewAttributedSales,
  [DATA_INSPECTOR_COLUMN_NAME.CompleteViewOrders]:
    ADGROUPS_API_COLUMN_NAME.CompleteViewOrders,
  [DATA_INSPECTOR_COLUMN_NAME.CompleteViewUnitsSold]:
    ADGROUPS_API_COLUMN_NAME.CompleteViewUnitsSold,
  [DATA_INSPECTOR_COLUMN_NAME.VideoFirstQuartileViews]:
    ADGROUPS_API_COLUMN_NAME.VideoFirstQuartileViews,
  [DATA_INSPECTOR_COLUMN_NAME.VideoMidpointViews]:
    ADGROUPS_API_COLUMN_NAME.VideoMidpointViews,
  [DATA_INSPECTOR_COLUMN_NAME.VideoThirdQuartileViews]:
    ADGROUPS_API_COLUMN_NAME.VideoThirdQuartileViews,
  [DATA_INSPECTOR_COLUMN_NAME.VideoCompleteViews]:
    ADGROUPS_API_COLUMN_NAME.VideoCompleteViews,
  [DATA_INSPECTOR_COLUMN_NAME.VideoUnmutes]:
    ADGROUPS_API_COLUMN_NAME.VideoUnmutes,
  [DATA_INSPECTOR_COLUMN_NAME.FiveSecondViews]:
    ADGROUPS_API_COLUMN_NAME.FiveSecondViews,
  [DATA_INSPECTOR_COLUMN_NAME.FiveSecondViewRate]:
    ADGROUPS_API_COLUMN_NAME.FiveSecondViewRate,
  [DATA_INSPECTOR_COLUMN_NAME.Orders]: CAMPAIGNS_API_COLUMN_NAME.Orders,
});

export const mapDataInspectorColumnToApiColumn = (
  tabCheckboxMetadata: TabCheckbox,
  adLevel?: AdLevel
): string[] => {
  const { flattenDataInspectorColumns, apiColumnNames } = getApiColumnNames(
    tabCheckboxMetadata,
    adLevel
  );

  return Object.keys(flattenDataInspectorColumns)
    .filter((columnName) => flattenDataInspectorColumns[columnName].isSelected)
    .map((columnName) => apiColumnNames[columnName]);
};

export const getAllApiColumns = (
  tabCheckboxMetadata: TabCheckbox,
  adLevel?: AdLevel
) => {
  const { flattenDataInspectorColumns, apiColumnNames } = getApiColumnNames(
    tabCheckboxMetadata,
    adLevel
  );

  return Object.keys(flattenDataInspectorColumns).map(
    (columnName) => apiColumnNames[columnName]
  );
};

export const getColumnManagerOptions = (
  currentTabCheckboxMetadata: TabCheckbox
) => {
  if (!currentTabCheckboxMetadata) {
    return [];
  }

  const options: ColumnManagerOption[] = [];
  Object.keys(currentTabCheckboxMetadata).forEach((group) => {
    Object.keys(currentTabCheckboxMetadata[group]).forEach((column) => {
      options.push({
        groupName: group,
        label: column,
        value: column,
        disabled:
          currentTabCheckboxMetadata?.[group]?.[column]?.isDisabled ||
          undefined,
      });
    });
  });
  return options;
};

const getApiColumnNames = (
  tabCheckboxMetadata: TabCheckbox,
  adLevel?: AdLevel
) => {
  const flattenDataInspectorColumns: Checkbox = {};

  Object.keys(tabCheckboxMetadata).forEach((key) => {
    merge(flattenDataInspectorColumns, tabCheckboxMetadata[key]);
  });

  const apiColumnNames = getApiColumnNamesForAdLevel(adLevel);

  return {
    flattenDataInspectorColumns,
    apiColumnNames,
  };
};

export const getApiColumnNamesForAdLevel = (adLevel?: AdLevel) => {
  const apiColumnNames = { ...DATA_INSPECTOR_COLUMN_TO_API_COLUMN_MAPPER };

  switch (adLevel) {
    case AdLevel.AdGroups:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          ADGROUPS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          ADGROUPS_API_COLUMN_NAME.CampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          ADGROUPS_API_COLUMN_NAME.CampaignTargetingType,
        [DATA_INSPECTOR_COLUMN_NAME.Group]: ADGROUPS_API_COLUMN_NAME.Group,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignDailyBudget]:
          ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget,
        [DATA_INSPECTOR_COLUMN_NAME.AdConversions]:
          ADGROUPS_API_COLUMN_NAME.AdConversions,
        [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
          ADGROUPS_API_COLUMN_NAME.ConversionRate,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdConversions]:
          ADGROUPS_API_COLUMN_NAME.NewToBrandOrders,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]:
          ADGROUPS_API_COLUMN_NAME.NewToBrandSales,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandAdUnitsSold]:
          ADGROUPS_API_COLUMN_NAME.NewToBrandAdUnitsSold,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]:
          ADGROUPS_API_COLUMN_NAME.CampaignTactic,
      };
    case AdLevel.Targets:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.Tags]: TARGETS_API_COLUMN_NAME.TargetLabels,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          TARGETS_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          TARGETS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          TARGETS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          TARGETS_API_COLUMN_NAME.CampaignTargetingType,
        [DATA_INSPECTOR_COLUMN_NAME.AdConversions]:
          TARGETS_API_COLUMN_NAME.AdConversions,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]:
          TARGETS_API_COLUMN_NAME.NewToBrandOrders,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]:
          TARGETS_API_COLUMN_NAME.CampaignTactic,
        [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
          TARGETS_API_COLUMN_NAME.ConversionRate,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          TARGETS_API_COLUMN_NAME.CampaignStatus,
      };
    case AdLevel.KeywordTargets:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.AddedBy]: TARGETS_API_COLUMN_NAME.AddedBy,
        [DATA_INSPECTOR_COLUMN_NAME.Tags]: TARGETS_API_COLUMN_NAME.TargetLabels,
        [DATA_INSPECTOR_COLUMN_NAME.KeywordReviewStatus]:
          TARGETS_API_COLUMN_NAME.KeywordReviewStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          TARGETS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.DateAdded]:
          TARGETS_API_COLUMN_NAME.DateAdded,
        [DATA_INSPECTOR_COLUMN_NAME.KeywordType]:
          TARGETS_API_COLUMN_NAME.KeywordType,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          TARGETS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          TARGETS_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus]:
          TARGETS_API_COLUMN_NAME.AdGroupReviewStatus,
        [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]:
          TARGETS_API_COLUMN_NAME.TotalBudget,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          TARGETS_API_COLUMN_NAME.CampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          TARGETS_API_COLUMN_NAME.CampaignTargetingType,
        [DATA_INSPECTOR_COLUMN_NAME.AdConversions]:
          TARGETS_API_COLUMN_NAME.AdConversions,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]:
          TARGETS_API_COLUMN_NAME.NewToBrandOrders,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTactic]:
          TARGETS_API_COLUMN_NAME.CampaignTactic,
        [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
          TARGETS_API_COLUMN_NAME.ConversionRate,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          TARGETS_API_COLUMN_NAME.CampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat]:
          TARGETS_API_COLUMN_NAME.CampaignAdFormat,
        [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]:
          TARGETS_API_COLUMN_NAME.UnitsSold,
        [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]:
          TARGETS_API_COLUMN_NAME.DirectAdSales,
        [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]:
          TARGETS_API_COLUMN_NAME.DirectACOS,
        [DATA_INSPECTOR_COLUMN_NAME.DirectROAS]:
          TARGETS_API_COLUMN_NAME.DirectROAS,
        [DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue]:
          TARGETS_API_COLUMN_NAME.RelatedClickRevenue,
        [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]:
          TARGETS_API_COLUMN_NAME.AdvertisedSkuUnits,
        [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]:
          TARGETS_API_COLUMN_NAME.OtherSkuUnits,
      };
    case AdLevel.ProductTargets:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.Tags]: TARGETS_API_COLUMN_NAME.TargetLabels,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          TARGETS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          TARGETS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.DateAdded]:
          TARGETS_API_COLUMN_NAME.DateAdded,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignAdFormat]:
          TARGETS_API_COLUMN_NAME.CampaignAdFormat,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          TARGETS_API_COLUMN_NAME.CampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdConversions]:
          TARGETS_API_COLUMN_NAME.AdConversions,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          TARGETS_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          TARGETS_API_COLUMN_NAME.CampaignTargetingType,
      };
    case AdLevel.Profiles:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.ProfileStatus]:
          PROFILES_API_COLUMN_NAME.ProfileStatus,
        [DATA_INSPECTOR_COLUMN_NAME.ProfileReviewStatus]:
          PROFILES_API_COLUMN_NAME.ProfileReviewStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          PROFILES_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.ClickURL]:
          PROFILES_API_COLUMN_NAME.ClickURL,
        [DATA_INSPECTOR_COLUMN_NAME.Logo]: PROFILES_API_COLUMN_NAME.Logo,
        [DATA_INSPECTOR_COLUMN_NAME.Headline]:
          PROFILES_API_COLUMN_NAME.HeadLine,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          PROFILES_API_COLUMN_NAME.CampaignTargetingType,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          PROFILES_API_COLUMN_NAME.CampaignStatus,
      };
    case AdLevel.ProductAds:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          PRODUCT_ADS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          PRODUCT_ADS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.DailyBudget]:
          PRODUCT_ADS_API_COLUMN_NAME.DailyBudget,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          PRODUCT_ADS_API_COLUMN_NAME.CampaignTargetingType,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          PRODUCT_ADS_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]:
          PRODUCT_ADS_API_COLUMN_NAME.NewToBrandSales,
        [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
          PRODUCT_ADS_API_COLUMN_NAME.ConversionRate,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]:
          PRODUCT_ADS_API_COLUMN_NAME.NewToBrandOrders,
        [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]:
          PRODUCT_ADS_API_COLUMN_NAME.AdUnitsSold,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandSales]:
          PRODUCT_ADS_API_COLUMN_NAME.NewToBrandSales,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          PRODUCT_ADS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.ProductAdStatus]:
          PRODUCT_ADS_API_COLUMN_NAME.Status,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignType]:
          PRODUCT_ADS_API_COLUMN_NAME.CampaignType,
        [DATA_INSPECTOR_COLUMN_NAME.AdItemsCampaignStatus]:
          PRODUCT_ADS_API_COLUMN_NAME.AdItemsCampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          PRODUCT_ADS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.Name]: SV_ADS_API_COLUMN_NAME.Name,
        [DATA_INSPECTOR_COLUMN_NAME.CreativeType]:
          SV_ADS_API_COLUMN_NAME.CreativeType,
        [DATA_INSPECTOR_COLUMN_NAME.CreativeServingStatus]:
          SV_ADS_API_COLUMN_NAME.ServingStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          SV_ADS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          SV_ADS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.AdStatus]: SV_ADS_API_COLUMN_NAME.AdStatus,
        [DATA_INSPECTOR_COLUMN_NAME.Impressions]:
          SV_ADS_API_COLUMN_NAME.Impressions,
        [DATA_INSPECTOR_COLUMN_NAME.Clicks]: SV_ADS_API_COLUMN_NAME.Clicks,
        [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]:
          PRODUCT_ADS_API_COLUMN_NAME.AvgCostPerClick,
        [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: SV_ADS_API_COLUMN_NAME.AdSpend,
        [DATA_INSPECTOR_COLUMN_NAME.ROAS]:
          PRODUCT_ADS_API_COLUMN_NAME.TotalROAS,
        [DATA_INSPECTOR_COLUMN_NAME.TotalAttributedSales]:
          SV_ADS_API_COLUMN_NAME.TotalAttributedSales,
        [DATA_INSPECTOR_COLUMN_NAME.Orders]: SV_ADS_API_COLUMN_NAME.Orders,
        [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]:
          SV_ADS_API_COLUMN_NAME.UnitsSold,
        [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
          SV_ADS_API_COLUMN_NAME.ConversionRate,
        [DATA_INSPECTOR_COLUMN_NAME.VideoImpressions]:
          SV_ADS_API_COLUMN_NAME.VideoImpressions,
        [DATA_INSPECTOR_COLUMN_NAME.ViewableImpressions]:
          SV_ADS_API_COLUMN_NAME.ViewableImpressions,
        [DATA_INSPECTOR_COLUMN_NAME.ViewThroughRate]:
          SV_ADS_API_COLUMN_NAME.ViewThroughRate,
        [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRateForViews]:
          SV_ADS_API_COLUMN_NAME.ClickThroughRateForViews,
        [DATA_INSPECTOR_COLUMN_NAME.ViewThroughAttributedSales]:
          SV_ADS_API_COLUMN_NAME.ViewThroughAttributedSales,
        [DATA_INSPECTOR_COLUMN_NAME.ViewThroughOrders]:
          SV_ADS_API_COLUMN_NAME.ViewThroughOrders,
        [DATA_INSPECTOR_COLUMN_NAME.ViewThroughUnitsSold]:
          SV_ADS_API_COLUMN_NAME.ViewThroughUnitsSold,
        [DATA_INSPECTOR_COLUMN_NAME.TotalCompleteViewAttributedSales]:
          SV_ADS_API_COLUMN_NAME.TotalCompleteViewAttributedSales,
        [DATA_INSPECTOR_COLUMN_NAME.AdvertisedCompleteViewAttributedSales]:
          SV_ADS_API_COLUMN_NAME.AdvertisedCompleteViewsAttributedSales,
        [DATA_INSPECTOR_COLUMN_NAME.OtherCompleteViewAttributedSales]:
          SV_ADS_API_COLUMN_NAME.OtherCompleteViewAttributedSales,
        [DATA_INSPECTOR_COLUMN_NAME.CompleteViewOrders]:
          SV_ADS_API_COLUMN_NAME.CompleteViewOrders,
        [DATA_INSPECTOR_COLUMN_NAME.CompleteViewUnitsSold]:
          SV_ADS_API_COLUMN_NAME.CompleteViewUnitsSold,
        [DATA_INSPECTOR_COLUMN_NAME.VideoFirstQuartileViews]:
          SV_ADS_API_COLUMN_NAME.VideoFirstQuartileViews,
        [DATA_INSPECTOR_COLUMN_NAME.VideoMidpointViews]:
          SV_ADS_API_COLUMN_NAME.VideoMidpointViews,
        [DATA_INSPECTOR_COLUMN_NAME.VideoThirdQuartileViews]:
          SV_ADS_API_COLUMN_NAME.VideoThirdQuartileViews,
        [DATA_INSPECTOR_COLUMN_NAME.VideoCompleteViews]:
          SV_ADS_API_COLUMN_NAME.VideoCompleteViews,
        [DATA_INSPECTOR_COLUMN_NAME.VideoUnmutes]:
          SV_ADS_API_COLUMN_NAME.VideoUnmutes,
        [DATA_INSPECTOR_COLUMN_NAME.FiveSecondViews]:
          SV_ADS_API_COLUMN_NAME.FiveSecondViews,
        [DATA_INSPECTOR_COLUMN_NAME.FiveSecondViewRate]:
          SV_ADS_API_COLUMN_NAME.FiveSecondViewRate,
      };
    case AdLevel.SbAds:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.CreativeName]: SB_ADS_API_COLUMN_NAME.Name,
        [DATA_INSPECTOR_COLUMN_NAME.CreativeType]:
          SB_ADS_API_COLUMN_NAME.CreativeType,
        [DATA_INSPECTOR_COLUMN_NAME.CreativeServingStatus]:
          SB_ADS_API_COLUMN_NAME.ServingStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          SB_ADS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          SB_ADS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          SB_ADS_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdStatus]: SB_ADS_API_COLUMN_NAME.AdStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdConversions]:
          SB_ADS_API_COLUMN_NAME.AdConversions,
        [DATA_INSPECTOR_COLUMN_NAME.NewToBrandOrders]:
          SB_ADS_API_COLUMN_NAME.NewToBrandOrders,
        [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
          SB_ADS_API_COLUMN_NAME.ConversionRate,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          SB_ADS_API_COLUMN_NAME.CampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]:
          SB_ADS_API_COLUMN_NAME.AdUnitsSold,
      };
    case AdLevel.SvAds:
      return {
        ...apiColumnNames,
        [DATA_INSPECTOR_COLUMN_NAME.Name]: SV_ADS_API_COLUMN_NAME.Name,
        [DATA_INSPECTOR_COLUMN_NAME.CreativeType]:
          SV_ADS_API_COLUMN_NAME.CreativeType,
        [DATA_INSPECTOR_COLUMN_NAME.CreativeServingStatus]:
          SV_ADS_API_COLUMN_NAME.ServingStatus,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupName]:
          SV_ADS_API_COLUMN_NAME.AdGroupName,
        [DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus]:
          SV_ADS_API_COLUMN_NAME.AdGroupStatus,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType]:
          SV_ADS_API_COLUMN_NAME.CampaignTargetingType,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignDailyBudget]:
          SV_ADS_API_COLUMN_NAME.CampaignDailyBudget,
        [DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget]:
          SV_ADS_API_COLUMN_NAME.LifetimeBudget,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignStatus]:
          SV_ADS_API_COLUMN_NAME.CampaignStatus,
        [DATA_INSPECTOR_COLUMN_NAME.DirectAdSales]:
          SV_ADS_API_COLUMN_NAME.DirectAdSales,
        [DATA_INSPECTOR_COLUMN_NAME.DirectACOS]:
          SV_ADS_API_COLUMN_NAME.DirectACOS,
        [DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits]:
          SV_ADS_API_COLUMN_NAME.OtherSkuUnits,
        [DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits]:
          SV_ADS_API_COLUMN_NAME.AdvertisedSkuUnits,
        [DATA_INSPECTOR_COLUMN_NAME.CampaignName]:
          SV_ADS_API_COLUMN_NAME.CampaignName,
        [DATA_INSPECTOR_COLUMN_NAME.AdStatus]: SV_ADS_API_COLUMN_NAME.AdStatus,
        [DATA_INSPECTOR_COLUMN_NAME.Impressions]:
          SV_ADS_API_COLUMN_NAME.Impressions,
        [DATA_INSPECTOR_COLUMN_NAME.Clicks]: SV_ADS_API_COLUMN_NAME.Clicks,
        [DATA_INSPECTOR_COLUMN_NAME.CostPerClick]:
          SV_ADS_API_COLUMN_NAME.AverageCostPerClick,
        [DATA_INSPECTOR_COLUMN_NAME.AdSpend]: SV_ADS_API_COLUMN_NAME.AdSpend,
        [DATA_INSPECTOR_COLUMN_NAME.ROAS]: SV_ADS_API_COLUMN_NAME.ROAS,
        [DATA_INSPECTOR_COLUMN_NAME.TotalAttributedSales]:
          SV_ADS_API_COLUMN_NAME.TotalAttributedSales,
        [DATA_INSPECTOR_COLUMN_NAME.Orders]: SV_ADS_API_COLUMN_NAME.Orders,
        [DATA_INSPECTOR_COLUMN_NAME.AdUnitsSold]:
          SV_ADS_API_COLUMN_NAME.UnitsSold,
        [DATA_INSPECTOR_COLUMN_NAME.ConversionRate]:
          SV_ADS_API_COLUMN_NAME.ConversionRate,
        [DATA_INSPECTOR_COLUMN_NAME.VideoImpressions]:
          SV_ADS_API_COLUMN_NAME.VideoImpressions,
        [DATA_INSPECTOR_COLUMN_NAME.ViewableImpressions]:
          SV_ADS_API_COLUMN_NAME.ViewableImpressions,
        [DATA_INSPECTOR_COLUMN_NAME.ViewThroughRate]:
          SV_ADS_API_COLUMN_NAME.ViewThroughRate,
        [DATA_INSPECTOR_COLUMN_NAME.ClickThroughRateForViews]:
          SV_ADS_API_COLUMN_NAME.ClickThroughRateForViews,
        [DATA_INSPECTOR_COLUMN_NAME.ViewThroughAttributedSales]:
          SV_ADS_API_COLUMN_NAME.ViewThroughAttributedSales,
        [DATA_INSPECTOR_COLUMN_NAME.ViewThroughOrders]:
          SV_ADS_API_COLUMN_NAME.ViewThroughOrders,
        [DATA_INSPECTOR_COLUMN_NAME.ViewThroughUnitsSold]:
          SV_ADS_API_COLUMN_NAME.ViewThroughUnitsSold,
        [DATA_INSPECTOR_COLUMN_NAME.TotalCompleteViewAttributedSales]:
          SV_ADS_API_COLUMN_NAME.TotalCompleteViewAttributedSales,
        [DATA_INSPECTOR_COLUMN_NAME.AdvertisedCompleteViewAttributedSales]:
          SV_ADS_API_COLUMN_NAME.AdvertisedCompleteViewsAttributedSales,
        [DATA_INSPECTOR_COLUMN_NAME.OtherCompleteViewAttributedSales]:
          SV_ADS_API_COLUMN_NAME.OtherCompleteViewAttributedSales,
        [DATA_INSPECTOR_COLUMN_NAME.CompleteViewOrders]:
          SV_ADS_API_COLUMN_NAME.CompleteViewOrders,
        [DATA_INSPECTOR_COLUMN_NAME.CompleteViewUnitsSold]:
          SV_ADS_API_COLUMN_NAME.CompleteViewUnitsSold,
        [DATA_INSPECTOR_COLUMN_NAME.VideoFirstQuartileViews]:
          SV_ADS_API_COLUMN_NAME.VideoFirstQuartileViews,
        [DATA_INSPECTOR_COLUMN_NAME.VideoMidpointViews]:
          SV_ADS_API_COLUMN_NAME.VideoMidpointViews,
        [DATA_INSPECTOR_COLUMN_NAME.VideoThirdQuartileViews]:
          SV_ADS_API_COLUMN_NAME.VideoThirdQuartileViews,
        [DATA_INSPECTOR_COLUMN_NAME.VideoCompleteViews]:
          SV_ADS_API_COLUMN_NAME.VideoCompleteViews,
        [DATA_INSPECTOR_COLUMN_NAME.VideoUnmutes]:
          SV_ADS_API_COLUMN_NAME.VideoUnmutes,
        [DATA_INSPECTOR_COLUMN_NAME.FiveSecondViews]:
          SV_ADS_API_COLUMN_NAME.FiveSecondViews,
        [DATA_INSPECTOR_COLUMN_NAME.FiveSecondViewRate]:
          SV_ADS_API_COLUMN_NAME.FiveSecondViewRate,
      };
    default:
      return apiColumnNames;
  }
};

export const isManualCampaign = (
  campaignTargetingType?: CampaignTargetingType
) => campaignTargetingType === CampaignTargetingType.Manual;

export const isAutoCampaign = (campaignTargetingType?: CampaignTargetingType) =>
  campaignTargetingType === CampaignTargetingType.Auto;

export const isBudgetTypeDaily = (budgetType?: string) =>
  budgetType === BudgetType.Daily;

export const isBudgetTypeLifetime = (budgetType?: string) =>
  budgetType === BudgetType.Lifetime;

export const isCampaignAdFormatProductCollectionOrSpotlight = (
  campaignAdFormat?: CampaignAdFormat
) =>
  campaignAdFormat === CampaignAdFormat.ProductCollection ||
  campaignAdFormat === CampaignAdFormat.StoreSpotlight;

export const isCampaignAdFormatEqualToVideo = (
  campaignAdFormat?: CampaignAdFormat
) => campaignAdFormat === CampaignAdFormat.Video;

export const isCampaignTacticsNotEqualToLegacyOrViews = (
  campaignTactic?: CampaignTactic
) =>
  campaignTactic !== CampaignTactic.Legacy &&
  campaignTactic !== CampaignTactic.Views;

export const DEBOUNCE_AFTER_IN_MILLISECONDS = 800;

export const replaceAllBooleanValuesFromString = (
  unsanitizedString: string
): string =>
  unsanitizedString
    .replace(/:true/g, '')
    .replace(/:false/g, '')
    .replace(/,"isDisabled"/g, '');

export const isValidDataInspectionConfig = (
  existingConfig: DataInspectorColumnManagerConfigProps,
  isAiEnabled?: boolean
): boolean => {
  const updatedExistingConfig = filterTargetLabelandAddedByColumn(
    isAiEnabled,
    existingConfig
  );
  const updatedLocalStorageConfig = filterTargetLabelandAddedByColumn(
    isAiEnabled,
    DATA_INSPECTION_COLUMN_MANAGER_CONFIG
  );

  const sanitizedExistingConfig = replaceAllBooleanValuesFromString(
    JSON.stringify(updatedExistingConfig)
  );
  const sanitzedLocalStorageConfig = replaceAllBooleanValuesFromString(
    JSON.stringify(updatedLocalStorageConfig)
  );

  return sanitizedExistingConfig === sanitzedLocalStorageConfig;
};

export const getAllAdLevelTabCheckboxesMetaData = (
  userId: string,
  accountId: string,
  isAiEnabled?: boolean
) => {
  const existingColumnData = getTabCheckboxColumnSelectionInfoInLocalStorage(
    userId,
    accountId
  );

  let finalConfig: DataInspectorColumnManagerConfigProps;
  if (
    existingColumnData &&
    isValidDataInspectionConfig(existingColumnData, isAiEnabled)
  ) {
    finalConfig = existingColumnData;
  } else {
    storeTabCheckboxColumnSelectionInfoInLocalStorage(
      userId,
      accountId,
      DATA_INSPECTION_COLUMN_MANAGER_CONFIG
    );
    finalConfig = DATA_INSPECTION_COLUMN_MANAGER_CONFIG;
  }

  return finalConfig;
};

export const checkIfAdLevelPathsAreOfSameLevel = (
  prevLocation: Location,
  currentLocation: Location
) => {
  const prevLocationPathTokens = prevLocation.pathname
    .split('/')
    .filter((subPath) => subPath);

  const currentLocationPathTokens = currentLocation.pathname
    .split('/')
    .filter((subPath) => subPath);

  return isEqual(
    prevLocationPathTokens.length,
    currentLocationPathTokens.length
  );
};

export const checkIfUrlPathsAreOfSameAdLevel = (
  prevLocation: string,
  currentLocation: string
) => {
  const prevLocationPathTokens = prevLocation
    .split('/')
    .filter((subPath) => subPath);

  const currentLocationPathTokens = currentLocation
    .split('/')
    .filter((subPath) => subPath);

  const adLevelUriArray = Object.values(ADS_MANAGER_AD_LEVEL_PAGES_URI).map(
    (adLevel) => adLevel.toString()
  );

  const prevAdLevel = prevLocationPathTokens.find((token) =>
    adLevelUriArray.includes(token)
  );

  const currentAdLevel = currentLocationPathTokens.find((token) =>
    adLevelUriArray.includes(token)
  );

  return prevAdLevel === currentAdLevel;
};

// AdGroup Flywheel settings validations
export const isValidMinBid = (
  currentMinBid: string,
  maxValue: string,
  minValue: string,
  currentMaxBid: string,
  extraValidation?: () => boolean,
  existingValue?: string
): boolean => {
  const isValidBid =
    existingValue === '' && currentMinBid === existingValue
      ? true
      : !isNaN(Number(currentMinBid)) &&
        Number(currentMinBid) <= Number(maxValue) &&
        Number(currentMinBid) >= Number(minValue) &&
        Number(currentMinBid) <= Number(currentMaxBid);

  if (extraValidation) {
    return isValidBid && extraValidation();
  }

  return isValidBid;
};

export const isValidMaxBid = (
  currentMaxBid: string,
  maxValue: string,
  minValue: string,
  currentMinBid: string,
  extraValidation?: () => boolean,
  existingValue?: string
): boolean => {
  const isValidBid =
    existingValue === '' && currentMaxBid === existingValue
      ? true
      : !isNaN(Number(currentMaxBid)) &&
        Number(currentMaxBid) <= Number(maxValue) &&
        Number(currentMaxBid) >= Number(minValue) &&
        Number(currentMaxBid) >= Number(currentMinBid);

  if (extraValidation) {
    return isValidBid && extraValidation();
  }

  return isValidBid;
};

export const isValidMacsTarget = (
  currentMacs: string | undefined,
  extraValidation?: () => boolean,
  existingValue?: string
): boolean => {
  const isValidTarget =
    existingValue === '' && currentMacs === existingValue
      ? true
      : !isNaN(Number(currentMacs)) && Number(currentMacs) > 0;
  if (extraValidation) {
    return isValidTarget && extraValidation();
  }
  return isValidTarget;
};

export const ALL_AD_LEVELS_FILTER_FILED_MAPPER: FilterFieldMapper[] = [
  {
    alias: CAMPAIGN_TABLE_SEARCH_KEY,
    field: CAMPAIGNS_API_COLUMN_NAME.CampaignName,
  },
  {
    alias: ADGROUP_TABLE_SEARCH_KEY,
    field: ADGROUPS_API_COLUMN_NAME.AdGroupName,
  },
  {
    alias: PRODUCTAD_TABLE_SEARCH_KEY,
    field: AD_ITEM_SEARCH_KEY_FIELD,
  },
  {
    alias: PROFILE_TABLE_SEARCH_KEY,
    field: PROFILES_API_COLUMN_NAME.Brand,
  },
  {
    alias: TARGETS_TABLE_SEARCH_KEY,
    field: TARGETS_API_COLUMN_NAME.TargetText,
  },
  {
    alias: TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION,
    field: TARGETS_API_COLUMN_NAME.TargetingExpression,
  },
  { alias: SB_ADS_TABLE_SEARCH_KEY, field: SB_ADS_API_COLUMN_NAME.Name },
];

export const getUniqueFilters = (filters: Filter[]) => {
  const stringifiedFilters = filters.map((e) => JSON.stringify(e));
  const stringifiedFilterSet = new Set(stringifiedFilters);
  const stringifiedFilterArray = Array.from(stringifiedFilterSet);
  return stringifiedFilterArray.map((e) => JSON.parse(e));
};
//Edit Campaign Name and AdGroup Name Utils

const VALID_ASCII_REGEX = /^[\x00-\x7F]*$/;

const ONLY_SPECIAL_CHARACTERS_REGEX = /^[ -/:-@[-`{-~®©™]+$/;

const WALMART_ONLY_SPECIAL_CHARACTERS_REGEX =
  /^([^\x20-\x7E]|[ -@[-`{-~®©™])+$/;

const SPECIAL_CHARACTERS_REGEX = /[^\w\s]/;

const AMAZON_VALID_ASCII_REGEX =
  /^[\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEFÁÍÑÓÚáéíñóúÄÖßäöÀÂÆÇÈÉÊËÎÏÔÙÛÜŒŸàâæçèêëîïôùûüÿœ®\x00-\x7F]+$/;

const AMAZON_VALID_UNICODE_REGEX =
  /[^\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEFÁÉÍÑÓÚÜáéíñóúüÄÖßäöÀÂÆÇÈÊËÎÏÔÙÛŒŸàâæçèêëîïôùûÿœ®\x00-\x7F]/gu;

const WALMART_VALID_CHARACTERS_REGEX =
  /[^\x20-\x7FÆæŒœÐðÞþŊŋẞßſİıĸə©®℠™₥¢¤€£₡¥₦₨₩฿₫₠₣₤₧₢ÀÈÌÒÙỲǸẀÁÉÍÓÚÝĆǴḰĹḾŃṔŔŚẂŹÂÊÎÔÛŶĈĜĤĴŜŴẐÃẼĨÕŨỸÑṼÄËÏÖÜŸḦẄẌẗÅŮẘẙĀĒĪŌŪȲḠĂĔĬŎŬĞĄĘĮǪŲǍĚǏǑǓČĎǦȞǰǨĽŇŘŠŤŽƗØƀĐǤĦŁŦƵȨÇḐĢḨĶĻŅŖŞŢŐŰǖǘǚǜ]/gi;

const VALID_URL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?$/gi;

export const isValidAscii = (
  value: string,
  salesChannel: FlywheelSalesChannel
): boolean => {
  const regex = new RegExp(VALID_ASCII_REGEX);
  switch (salesChannel) {
    case FlywheelSalesChannel.Walmart:
      return !new RegExp(WALMART_VALID_CHARACTERS_REGEX).test(value);
    case FlywheelSalesChannel.Amazon:
      return (
        regex.test(value) || new RegExp(AMAZON_VALID_ASCII_REGEX).test(value)
      );
  }
};

export const hasOnlySpecialCharacters = (
  value: string,
  salesChannel?: FlywheelSalesChannel
) => {
  switch (salesChannel) {
    case FlywheelSalesChannel.Walmart:
      return WALMART_ONLY_SPECIAL_CHARACTERS_REGEX.test(value);

    case FlywheelSalesChannel.Amazon:
    default:
      return ONLY_SPECIAL_CHARACTERS_REGEX.test(value);
  }
};

export const hasSpecialCharacters = (value?: string) =>
  SPECIAL_CHARACTERS_REGEX.test(value || '');

export const isInvalidAdGroupName = (
  salesChannel: FlywheelSalesChannel,
  value?: string
): boolean =>
  !value ||
  !isValidAscii(value, salesChannel) ||
  hasOnlySpecialCharacters(value, salesChannel);

const MAX_ADGROUP_NAME_LENGTH = 255;
export const MAX_BRAND_NAME_LENGTH = 35;
export const MAX_HEADLINE_LENGTH = 45;

export const isValidUrl = (value: string) =>
  new RegExp(VALID_URL_REGEX).test(value);

export const isValidAdGroupNameCharacterLength = (value: string) =>
  value.length <= MAX_ADGROUP_NAME_LENGTH;

export const isValidBrandNameCharacterLength = (value?: string) =>
  (value?.length ?? 0) <= MAX_BRAND_NAME_LENGTH;

export const isValidHeadlineCharacterLength = (value?: string) =>
  (value?.length ?? 0) <= MAX_HEADLINE_LENGTH;

export const isInvalidHeadline = (value?: string): boolean =>
  !value || hasOnlySpecialCharacters(value);

export const isInvalidBrandName = (value?: string): boolean =>
  !value || hasOnlySpecialCharacters(value);

export const isInvalidClickUrl = (value?: string): boolean =>
  !value ||
  !isValidAscii(value, FlywheelSalesChannel.Walmart) ||
  hasOnlySpecialCharacters(value) ||
  !isValidUrl(value);

export const decodeUrl = (url: string) =>
  url.replace(/%20/g, '+').replace(/%3A/g, ':').replace(/%7C/g, '|');

export const encodeUrl = (url: string) =>
  url.replace(/\+/g, '%20').replace(/:/g, '%3A').replace(/\|/g, '%7C');

export const hasTypeheadParam = (value?: string) =>
  value?.includes('typehead') || false;

export const MAX_CAMPAIGN_NAME_LENGTH = 255;

export const isInvalidCampaignName = (
  salesChannel: FlywheelSalesChannel,
  campaignValue?: string,
  isSBA?: boolean
) =>
  !campaignValue ||
  (!isSBA && !isValidAscii(campaignValue, salesChannel)) ||
  hasOnlySpecialCharacters(campaignValue, salesChannel);

export const isMerchantTypeSeller = (merchantType?: MerchantType) =>
  merchantType?.toLowerCase() === MerchantType.Seller;

export const isMerchantTypeVendor = (merchantType?: MerchantType) =>
  merchantType?.toLowerCase() === MerchantType.Vendor;

export const getMaxLengthForCampaignName = (
  salesChannel?: FlywheelSalesChannel,
  merchantType?: MerchantType
): number => {
  if (salesChannel === FlywheelSalesChannel.Amazon) {
    const MAX_CAMPAIGN_NAME_LENGTH_FOR_AMAZON_SELLER = 128;
    const MAX_CAMPAIGN_NAME_LENGTH_FOR_AMAZON_VENDOR = 116;
    if (isMerchantTypeSeller(merchantType)) {
      return MAX_CAMPAIGN_NAME_LENGTH_FOR_AMAZON_SELLER;
    } else {
      return MAX_CAMPAIGN_NAME_LENGTH_FOR_AMAZON_VENDOR;
    }
  }
  return MAX_CAMPAIGN_NAME_LENGTH;
};

export const isValidCampaignNameCharacterLength = (
  value: string,
  maxLength: number = MAX_CAMPAIGN_NAME_LENGTH
) => value.length <= maxLength;

export const fetchNonAsciiCharacters = (
  value: string,
  salesChannel: FlywheelSalesChannel
) => {
  const regex =
    salesChannel === FlywheelSalesChannel.Walmart
      ? WALMART_VALID_CHARACTERS_REGEX
      : AMAZON_VALID_UNICODE_REGEX;
  return value.match(regex);
};

export const isFirstDateSmallerThanSecondDate = (
  firstDate: DateTime,
  secondDate: DateTime
) => firstDate.diff(secondDate, 'days').days < 0;

export const isDateSmallerThanToday = (date: DateTime) =>
  date.diff(DateTime.local().startOf('day'), 'days').days < 0;

export const getAdLevelColumnManagerCheckboxData = (
  tabCheckboxMetadata: DataInspectorColumnManagerConfigProps,
  selectedSalesChannel: FlywheelSalesChannel,
  adType?: AdType,
  adLevel?: AdLevel
): TabCheckbox => {
  const adTypeFilteredTabCheckboxData = tabCheckboxMetadata[
    selectedSalesChannel
  ].filter((adTypeConfig) => adTypeConfig.adType === adType);

  const adLevelFilteredTabCheckboxData = first(
    adTypeFilteredTabCheckboxData
  )?.config.filter((adLevelConfig) => adLevelConfig.adLevel === adLevel);

  return first(adLevelFilteredTabCheckboxData)?.columns || {};
};

export const isAdTypeAvailableInMetadata = (
  dropdownMetadata: DataInspection[],
  adType: AdType
): boolean => {
  const currentAdTypeSelection = dropdownMetadata.find(
    (data: DataInspection) => data.parent.value === adType
  );
  return currentAdTypeSelection ? true : false;
};

export const isAdLevelAvailableInMetadata = (
  dropdownMetadata: DataInspection[],
  adType: AdType,
  adLevel: AdLevel
): boolean => {
  const adLevelDropdownMetaData = dropdownMetadata.find(
    (data) => data.parent.value === adType
  );
  const adLevels = adLevelDropdownMetaData?.child;
  let currentAdLevelSelected;
  if (adLevels) {
    currentAdLevelSelected = adLevels.find(
      (data: LabelValue<AdLevel>) => data.value === adLevel
    );
  }
  return currentAdLevelSelected ? true : false;
};

export const getAdGroupStatusOptionsBySelectedChannel = (
  selectedSalesChannelName: string,
  intl: IntlShape
) => {
  const [ENABLED, DISABLED, ARCHIVED, PAUSED] = [
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED,
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_DISABLED,
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ARCHIVED,
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PAUSED,
  ].map((id) => intl.formatMessage({ id }));

  const commonOptions = [
    {
      label: ENABLED,
      value: AdGroupStatus.Enabled,
    },
  ];

  switch (selectedSalesChannelName) {
    case FlywheelSalesChannel.Amazon:
      return [
        ...commonOptions,
        {
          label: PAUSED,
          value: AdGroupStatus.Paused,
        },
        {
          label: ARCHIVED,
          value: AdGroupStatus.Archived,
        },
      ];
    case FlywheelSalesChannel.Walmart:
      return [
        ...commonOptions,
        {
          label: DISABLED,
          value: AdGroupStatus.Disabled,
        },
      ];
    default:
      return [];
  }
};

export const findSalesChannelByChannelId = (
  salesChannels: SalesChannel[],
  channelId: string | null | undefined
): SalesChannel | undefined =>
  salesChannels.find((channel) => channel.salesChannelId === channelId);

export const getFlywheelChannelFromSalesChannelId = (
  salesChannelId: string,
  salesChannels: SalesChannel[]
): FlywheelSalesChannel =>
  findSalesChannelByChannelId(salesChannels, salesChannelId)
    ?.name as FlywheelSalesChannel;

export const CAMPAIGN_TACTIC_I18NKEY_MAPPER: Record<CampaignTactic, I18nKey> = {
  [CampaignTactic.Legacy]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_LEGACY,
  [CampaignTactic.Product]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_PRODUCT,
  [CampaignTactic.Audiences]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_AUDIENCES,
  [CampaignTactic.Views]:
    I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_VIEWS,
};

export const isCampaignStatusArchived = (campaignStatus?: CampaignStatus) =>
  campaignStatus === CampaignStatus.Archived;

export const isAdGroupStatusArchived = (adGroupStatus?: AdGroupStatus) =>
  adGroupStatus === AdGroupStatus.Archived;

export const isAdItemStatusArchived = (adItemStatus?: ProductAdStatusValue) =>
  adItemStatus === ProductAdStatusValue.Archived;

export const isCampaignStatusCompleted = (
  campaignStatus?: CampaignStatus,
  updatedCampaignStatus?: CampaignStatus
) =>
  campaignStatus
    ? campaignStatus === CampaignStatus.Completed &&
      isNil(updatedCampaignStatus)
    : false;

export const isTargetStatusArchived = (status?: string) =>
  status === TargetStatusValues.Archived ||
  status === TargetStatusValues.Deleted;

export const isProductAdStatusArchived = (status?: ProductAdStatusValue) =>
  status === ProductAdStatusValue.Archived;

export const isNegativeKeywordTarget = (matchType?: string) =>
  matchType === 'negativePhrase' || matchType === 'negativeExact';

export const isNegativeProductTarget = (entityType?: string) =>
  entityType?.toLowerCase().includes('negative');

export const isNegativeCampaignTarget = (entityType?: string) =>
  entityType === SP_CAMPAIGN_NEGATIVE_KEYWORD;

export const isCurrentValueLessThanRequiredMinValue = (
  minValue?: number,
  value?: string
): boolean => {
  return value && !isNil(minValue) ? Number(value) < minValue : false;
};

export const isCurrentValueLessThanOrEqualToRequiredMinValue = (
  minValue?: number,
  value?: string
): boolean => {
  return value && !isNil(minValue) ? Number(value) <= minValue : false;
};

export const isValueBetweenMinAndMaxValue = (
  minValue: number,
  maxValue: number,
  value?: string
): boolean =>
  value ? Number(value) >= minValue && Number(value) <= maxValue : false;

export const isCurrentValueGreaterThanRequiredMaxValue = (
  maxValue?: number,
  value?: string
): boolean => {
  return value && !isNil(maxValue) ? Number(value) > maxValue : false;
};

// If isCampaignTacticsNotEqualToLegacyOrViews/isSponseredBrandsAdType = true -> show value received from the backend. If value is null/undefined/0, UI should show 0.
// If isCampaignTacticsNotEqualToLegacyOrViews and isSponseredBrandsAdType = false -> show EmDash, hence undefined
export const isNumericValueWithCampaignTacticAndCampaignAdFormatValid = (
  value?: string | number,
  campaignTactic?: CampaignTactic,
  isSponseredBrandsAdType?: boolean
) =>
  (((campaignTactic &&
    isCampaignTacticsNotEqualToLegacyOrViews(campaignTactic)) ||
    isSponseredBrandsAdType) &&
    (value?.toString() || '0')) ||
  undefined;

export const getCheckBoxLabelStateForProductAds = (
  salesChannel: FlywheelSalesChannel | undefined,
  adStatus: string
): CheckboxState =>
  salesChannel === FlywheelSalesChannel.Amazon &&
  adStatus === ProductAdStatusOptions.Archived.value
    ? CheckboxState.Disabled
    : CheckboxState.Default;

export const getCheckBoxLabelStateForTargets = (
  salesChannel: FlywheelSalesChannel | undefined,
  targetStatus: string
): CheckboxState =>
  salesChannel === FlywheelSalesChannel.Amazon &&
  (targetStatus === TargetStatusOptions.Archived.value ||
    targetStatus === TargetStatusOptions.Deleted.value)
    ? CheckboxState.Disabled
    : CheckboxState.Default;

export const isBulkEditModalDisabledForPlacementBidMultipliersAmazonSP = (
  filteredElementsForBulkEditModal:
    | (BulkEditModalElement | BulkEditModalGroupedElement)[]
    | undefined
): boolean => {
  const areAllValuesEmpty = filteredElementsForBulkEditModal?.every(
    (singleElement: BulkEditModalElement | BulkEditModalGroupedElement) => {
      return (singleElement as BulkEditModalElement).value === '';
    }
  );

  if (areAllValuesEmpty) {
    return true;
  } else {
    const areAllMultipliersValid = filteredElementsForBulkEditModal?.every(
      (singleElement: BulkEditModalElement | BulkEditModalGroupedElement) => {
        if ((singleElement as BulkEditModalElement).value === '') {
          return true;
        } else {
          return (singleElement as BulkEditModalElement).validators.every(
            ({ validator }) =>
              validator((singleElement as BulkEditModalElement).value)
          );
        }
      }
    );
    return !areAllMultipliersValid;
  }
};

export const getDateCountGreaterThanEqualToMaxOfStartDateAndCurrentDate = (
  date: DateTime,
  campaignsTableData: CampaignDetails[],
  selectSettings: TableSelectSettings
) => {
  return campaignsTableData.filter(
    ({ campaignId, channelSettings: { startDate } }) => {
      const isCampaignPresent = selectSettings.rows.indexOf(campaignId) !== -1;
      const isStartDatePresent = !isNil(startDate);
      const isDateValid = date.isValid;

      const compareBetweenDates =
        isCampaignPresent && isStartDatePresent && isDateValid;

      const minDateBetweenStartDateAndCurrentDate = getMaxDateBetweenTwoDates(
        DateTime.fromISO(startDate),
        DateTime.local(),
        CAMPAIGN_DATE_FORMAT
      );

      return (
        compareBetweenDates &&
        minDateBetweenStartDateAndCurrentDate <=
          date.toFormat(CAMPAIGN_DATE_FORMAT)
      );
    }
  ).length;
};

export const getValidDateCount = (
  date: DateTime,
  campaignsTableData: CampaignDetails[],
  selectSettings: TableSelectSettings,
  tableChange?: TableChange
) => {
  return campaignsTableData.filter(
    ({ campaignId, channelSettings: { startDate, status } }) => {
      const isCampaignPresent = selectSettings.rows.indexOf(campaignId) !== -1;
      const isStartDatePresent = !isNil(startDate);
      const isDateValid = date.isValid;
      const compareBetweenDates =
        isCampaignPresent && isStartDatePresent && isDateValid;
      const updatedRow = tableChange?.cell[campaignId];
      const updatedStatus =
        updatedRow?.[CAMPAIGNS_API_COLUMN_NAME.CampaignStatus];

      const minDateBetweenStartDateAndCurrentDate = getMaxDateBetweenTwoDates(
        DateTime.fromISO(startDate),
        DateTime.local(),
        CAMPAIGN_DATE_FORMAT
      );

      // check if date is greater than or equal to Max. Of start date and current date
      const isDateApplicable =
        compareBetweenDates &&
        minDateBetweenStartDateAndCurrentDate <=
          date.toFormat(CAMPAIGN_DATE_FORMAT);

      // check if campaign status is incomplete for the row
      const isCampaignIncomplete = !isCampaignStatusCompleted(
        status,
        updatedStatus as CampaignStatus
      );

      return isCampaignPresent && isCampaignIncomplete && isDateApplicable;
    }
  ).length;
};

export const getIncompleteCampaignCount = (
  campaignsTableData: CampaignDetails[],
  selectSettings: TableSelectSettings,
  tableChange?: TableChange
) =>
  campaignsTableData.filter(({ campaignId, channelSettings: { status } }) => {
    const isCampaignPresent = selectSettings.rows.indexOf(campaignId) !== -1;
    const updatedRow = tableChange?.cell[campaignId];
    const updatedStatus =
      updatedRow?.[CAMPAIGNS_API_COLUMN_NAME.CampaignStatus];

    // check if campaign status is incomplete for the row
    const isCampaignIncomplete = !isCampaignStatusCompleted(
      status,
      updatedStatus as CampaignStatus
    );

    return isCampaignPresent && isCampaignIncomplete;
  }).length;

export const getMaxDateBetweenTwoDates = (
  firstDate: DateTime,
  secondDate: DateTime,
  dateFormat: string
) =>
  firstDate > secondDate
    ? firstDate.toFormat(dateFormat)
    : secondDate.toFormat(dateFormat);

export const roundValuesToDecimalPlaces = (
  values: string[],
  decimalPlaces: number
) =>
  values.map((value) =>
    value ? parseFloat(value).toFixed(decimalPlaces) : value
  );

export const isStringValuePresent = (value?: string) =>
  value !== '' && !isNil(value);

export const getSearchInputPlaceholder = (
  adType: AdType,
  adLevel: AdLevel,
  intl: IntlShape
) => {
  const [CAMPAIGNS, ADGROUPS, PRODUCT, KEYWORD_TARGETS, TARGETS, DEFAULT] = [
    I18nKey.SEARCH_INPUT_PLACEHOLDER_CAMPAIGNS,
    I18nKey.SEARCH_INPUT_PLACEHOLDER_ADGROUPS,
    I18nKey.SEARCH_INPUT_PLACEHOLDER_PRODUCT,
    I18nKey.SEARCH_INPUT_PLACEHOLDER_KEYWORD_TARGETS,
    I18nKey.SEARCH_INPUT_PLACEHOLDER_TARGETS,
    I18nKey.SEARCH_INPUT_PLACEHOLDER,
  ].map((id) => intl.formatMessage({ id }));

  switch (adType + adLevel) {
    case AdType.SponsoredProducts + AdLevel.Campaigns:
    case AdType.SponsoredBrands + AdLevel.Campaigns:
    case AdType.SponsoredDisplay + AdLevel.Campaigns:
      return CAMPAIGNS;
    case AdType.SponsoredDisplay + AdLevel.AdGroups:
    case AdType.SponsoredProducts + AdLevel.AdGroups:
      return ADGROUPS;
    case AdType.SponsoredProducts + AdLevel.KeywordTargets:
    case AdType.SponsoredBrands + AdLevel.KeywordTargets:
      return KEYWORD_TARGETS;
    case AdType.SponsoredProducts + AdLevel.ProductAds:
    case AdType.SponsoredDisplay + AdLevel.ProductAds:
      return PRODUCT;
    case AdType.SponsoredDisplay + AdLevel.Targets:
    case AdType.SponsoredProducts + AdLevel.ProductTargets:
    case AdType.SponsoredBrands + AdLevel.ProductTargets:
      return TARGETS;
    default:
      return DEFAULT;
  }
};

export const getDropdownValuesFromPathname = (pathname: string) => {
  const salesChannelFromPathname: FlywheelSalesChannel | undefined =
    ADS_MANAGER_PAGE_TO_SALESCHANNEL_MAPPER[
      pathname as ADS_MANAGER_AD_LEVEL_PAGES
    ];

  const adTypeFromPathname: AdType =
    ADS_MANAGER_PAGE_TO_AD_TYPE_MAPPER[pathname as ADS_MANAGER_AD_LEVEL_PAGES];

  const adLevelFromPathname: AdLevel =
    ADS_MANAGER_PAGE_TO_AD_LEVEL_MAPPER[pathname as ADS_MANAGER_AD_LEVEL_PAGES];

  return {
    salesChannelFromPathname,
    adTypeFromPathname,
    adLevelFromPathname,
  };
};

export const getSortingParamsFromUrl = (urlQuery: string) => {
  const urlSearchParams = new URLSearchParams(decodeURIComponent(urlQuery));
  return {
    sortOrder: urlSearchParams.get(SORT_ORDER),
    sortBy: urlSearchParams.get(SORT_BY),
    campaignLiveFilter: urlSearchParams.has(CAMPAIGN_LIVE_FILTER),
    salesChannelName: urlSearchParams.get(SALES_CHANNEL_NAME),
    addedByFilter: urlSearchParams.get(ADDED_BY_FILTER),
    campaignName: urlSearchParams.get(CAMPAIGN_NAME_FILTER),
  };
};

export const getSearchTextFromUrl = (urlQuery: string) => {
  const urlSearchParams = new URLSearchParams(decodeURIComponent(urlQuery));
  return urlSearchParams.get(SEARCH_TEXT);
};

export const getCampaignStatusFromUrl = (urlQuery: string) => {
  const urlSearchParams = new URLSearchParams(decodeURIComponent(urlQuery));
  return urlSearchParams.get(CAMPAIGN_STATUS);
};

export const getSKUFromUrl = (urlQuery: string) => {
  const urlSearchParams = new URLSearchParams(decodeURIComponent(urlQuery));
  return urlSearchParams.get('sku');
};

export const getProductAdStatusFromUrl = (urlQuery: string) => {
  const urlSearchParams = new URLSearchParams(decodeURIComponent(urlQuery));
  return urlSearchParams.get(PRODUCT_AD_STATUS);
};

export const cleanUpExpressionValue = (expressionValue: string) =>
  expressionValue.startsWith('is:')
    ? expressionValue.replace('is:', '')
    : expressionValue;

export const findFuzzyExpressionTypeInFilter = (
  expressionText: string,
  expressionTypeDescription: string
): string | null => {
  const EXPRESSION_TYPE_SPECIAL_CHARACTERS = ['>', '<'];

  let probablyExpressionType = '';
  EXPRESSION_TYPE_SPECIAL_CHARACTERS.every((expressionSpecialCharacter) => {
    if (expressionText.includes(expressionSpecialCharacter)) {
      probablyExpressionType =
        first(expressionText.split(expressionSpecialCharacter)) +
        expressionSpecialCharacter;
      return false;
    }
    return true;
  });

  if (!probablyExpressionType) {
    const allPossibleExpressionTypes = expressionText.split(' ');
    probablyExpressionType = allPossibleExpressionTypes[0];
  }

  if (probablyExpressionType) {
    return expressionTypeDescription.includes(probablyExpressionType)
      ? `${probablyExpressionType}${
          expressionTypeDescription.split(probablyExpressionType)[1]
        }`
      : null;
  }
  return null;
};

const expressionTypes = (
  expressionText: string,
  intl: IntlShape,
  remainingExpressions: string[]
) => {
  let expressionTypeDescription;
  const EXPRESSION_TYPE_LIST: ExpressionType[] = [
    ExpressionType.AsinAgeRangeSameAs,
    ExpressionType.AsinBrandSameAs,
    ExpressionType.AsinCategorySameAs,
    ExpressionType.AsinGenreSameAs,
    ExpressionType.AsinIsPrimeShippingEligible,
    ExpressionType.AsinPriceBetween,
    ExpressionType.AsinPriceGreaterThan,
    ExpressionType.AsinPriceLessThan,
    ExpressionType.AsinReviewRatingBetween,
    ExpressionType.AsinReviewRatingGreaterThan,
    ExpressionType.AsinReviewRatingLessThan,
    ExpressionType.AsinSameAs,
    ExpressionType.Lookback,
    ExpressionType.AudienceSameAs,
    ExpressionType.ExactProduct,
    ExpressionType.SimilarProduct,
    ExpressionType.QueryBroadRelMatches,
    ExpressionType.QueryHighRelMatches,
    ExpressionType.AsinAccessoryRelated,
    ExpressionType.AsinSubstituteRelated,
  ];
  return EXPRESSION_TYPE_LIST.filter((expressionType: ExpressionType) => {
    expressionTypeDescription = intl
      .formatMessage({
        id: EXPRESSION_TYPE_TO_FILTER_I18NKEY_MAPPER[expressionType],
      })
      .toLowerCase();

    const fuzzyExpressionType = findFuzzyExpressionTypeInFilter(
      expressionText,
      expressionTypeDescription
    );

    const isExpressionTypeRequiredInFilter =
      expressionText.toLowerCase().indexOf(expressionTypeDescription) === 0 ||
      expressionTypeDescription.indexOf(expressionText.toLowerCase()) !== -1 ||
      fuzzyExpressionType;

    const subStringToBeReplaced =
      fuzzyExpressionType || expressionTypeDescription;

    if (isExpressionTypeRequiredInFilter) {
      remainingExpressions.push(
        expressionText.substring(subStringToBeReplaced.length).trim()
      );
    }
    return isExpressionTypeRequiredInFilter;
  });
};

const getRemainingExpression = (remainingExpressions: string[]) => {
  return remainingExpressions.length > 1
    ? remainingExpressions[1]
    : remainingExpressions[0];
};

const getSubStringEnd = (requiredExpressionTypes: ExpressionType[]): number => {
  let endSubStr = 0;
  let isAtleastUndefinedOrNotEqual = false;
  while (isAtleastUndefinedOrNotEqual === false) {
    const values: string[] = [];
    for (let expressionType of requiredExpressionTypes) {
      values.push(expressionType[endSubStr]);
    }
    const firstVal = first(values);
    for (let i = 1; i < values.length; i++) {
      if (firstVal?.toLowerCase() !== values[i]?.toLowerCase()) {
        isAtleastUndefinedOrNotEqual = true;
      }
    }
    endSubStr++;
  }
  return endSubStr;
};

export const getRequiredExpressionType = (
  requiredExpressionTypes: ExpressionType[]
) => {
  const COMPARBLE_EXPRESSION_TYPE_LIST: ExpressionType[] = [
    ExpressionType.AsinPriceBetween,
    ExpressionType.AsinPriceGreaterThan,
    ExpressionType.AsinPriceLessThan,
    ExpressionType.AsinReviewRatingBetween,
    ExpressionType.AsinReviewRatingGreaterThan,
    ExpressionType.AsinReviewRatingLessThan,
  ];
  let requiredExpressionType;

  if (requiredExpressionTypes.length === 1) {
    requiredExpressionType = first(requiredExpressionTypes);
  }

  if (requiredExpressionTypes.length > 1) {
    requiredExpressionType = first(requiredExpressionTypes);
    if (
      COMPARBLE_EXPRESSION_TYPE_LIST.includes(
        requiredExpressionType as ExpressionType
      )
    ) {
      let endSubStr: number = getSubStringEnd(requiredExpressionTypes);
      requiredExpressionType = requiredExpressionTypes[0].substr(
        0,
        endSubStr - 1
      );
    }
  }
  return requiredExpressionType;
};

const getAddedByFilter = (filter: Filter): Filter => {
  let newValue: string[] = [];

  if ((filter.value as string[]).includes(AddedBy.FlywheelOnDemand)) {
    newValue = [
      AddedBy.CampaignCreatorOnDemand,
      AddedBy.FlywheelOnDemand,
      AddedBy.AdsManagerOnDemand,
    ];
  }

  if ((filter.value as string[]).includes(AddedBy.FlywheelAutomated)) {
    newValue.push(AddedBy.FlywheelAutomated, AddedBy.CampaignCreatorV1);
  }

  if ((filter.value as string[]).includes(AddedBy.FlywheelAccepted)) {
    newValue.push(
      AddedBy.FlywheelAccepted,
      AddedBy.CampaignCreatorAiRecommended
    );
  }

  if ((filter.value as string[]).includes(AddedBy.External)) {
    newValue.push(AddedBy.External);
  }

  if ((filter.value as string[]).includes(AddedBy.ChatGPT)) {
    newValue.push(AddedBy.ChatGPT);
  }

  if ((filter.value as string[]).includes(AddedBy.FlywheelAutoNegate)) {
    newValue.push(AddedBy.FlywheelAutoNegate);
  }

  return {
    ...filter,
    op: filter.op === FilterOps.in ? FilterOps.in : FilterOps.notIn,
    value: newValue,
  };
};

export const getTargetingExpressionAndTargetLabelFilters =
  (intl: IntlShape, filterFields: string[]) =>
  (originalFilters: Filter[]): Filter[] => {
    const transformedOriginalFilters: Filter[] = originalFilters.map(
      (filter) => {
        if (filter.field === TARGETS_API_COLUMN_NAME.AddedBy) {
          return getAddedByFilter(filter);
        }
        return filter;
      }
    );
    const filtersToBeTransformed = transformedOriginalFilters.filter((filter) =>
      filterFields.includes(filter.field)
    );

    if (filtersToBeTransformed.length === 0) {
      return transformedOriginalFilters;
    }
    let newFilters: Filter[] = [];

    filtersToBeTransformed.forEach((filter) => {
      const expressionText = cleanUpExpressionValue(filter.value as string);
      const remainingExpressions: string[] = [expressionText];
      const requiredExpressionTypes = expressionTypes(
        expressionText,
        intl,
        remainingExpressions
      );
      /**
       * This takes care of the scenario where search query may match to more than
       * one expression type and comaparable expressionTypes are taken care of separately.
       * For example `Star rating` will match `asinReviewRatingBetween` and
       * `asinReviewRatingLessThan`.
       * But for example `me shipping eligible` will match asinIsPrimeShippingEligible exactly
       * and doesn't need to go through the comparable logic since its not like starRating
       */

      const requiredExpressionType = getRequiredExpressionType(
        requiredExpressionTypes
      );
      if (requiredExpressionType) {
        newFilters.push({
          field: TARGETS_API_COLUMN_NAME.ExpressionType,
          op: FilterOps.like,
          value: requiredExpressionType,
        });
      }

      let remainingExpression = getRemainingExpression(remainingExpressions);
      if (remainingExpression) {
        newFilters.push({
          field: TARGETING_EXPRESSION_VALUE_KEY,
          op: FilterOps.like,
          value: remainingExpression,
        });
      }
    });

    const transformedFilters = transformedOriginalFilters.filter(
      (filter) => !filterFields.includes(filter.field)
    );

    transformedFilters.push(...newFilters);

    return transformedFilters;
  };

export const getTransformedFilters = (intl: IntlShape, adLevel?: AdLevel) => {
  switch (adLevel) {
    case AdLevel.Campaigns:
    case AdLevel.AdGroups:
      return getTransformedAddedByFilter;
    case AdLevel.ProductAds:
      return transFormAdItemsFilters;
    default:
      return getTargetingExpressionAndTargetLabelFilters(intl, [
        TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION,
      ]);
  }
};

export const getTransformedAddedByFilter = (filters: Filter[]): Filter[] => {
  return filters.map((filter) => {
    if (filter.field === CAMPAIGNS_API_COLUMN_NAME.AddedBy) {
      let newValue: string[] = [];
      if ((filter.value as string[]).includes(AddedBy.FlywheelAutomated)) {
        newValue = [AddedBy.CampaignCreatorV1, AddedBy.FlywheelAutomated];
      }

      if ((filter.value as string[]).includes(AddedBy.FlywheelOnDemand)) {
        newValue = [
          AddedBy.FlywheelOnDemand,
          AddedBy.CampaignCreatorOnDemand,
          AddedBy.AdsManagerOnDemand,
        ];
      }

      if ((filter.value as string[]).includes(AddedBy.External)) {
        newValue.push(AddedBy.External);
      }

      if ((filter.value as string[]).includes(AddedBy.ChatGPT)) {
        newValue.push('chat_gpt');
      }

      return {
        ...filter,
        op: filter.op === FilterOps.in ? FilterOps.in : FilterOps.notIn,
        value: newValue,
      };
    }
    return filter;
  });
};

export const getTooltipContentForArchivedEntity = (
  adLevel: string,
  columnName: string,
  intl: IntlShape,
  addedBy?: AddedBy
) => {
  if (addedBy === AddedBy.FlywheelAutomated) {
    return (
      <FormattedMessage
        id={I18nKey.ADS_MANAGER_TARGETS_TAGS_TOOLTIP_NO_TAG_DISABLED_MESSAGE}
      />
    );
  }
  return (
    <p className="w-180 text-center">
      {intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_COLUMNS_ARCHIVED_ENTITY_ERROR_TOOLTIP,
        },
        {
          adLevel: lowerCase(adLevel),
          columnName: columnName,
        }
      )}
    </p>
  );
};

export const getPerformanceMetricValue = (
  selectedEndDate?: DateTime,
  campaignStartDate?: DateTime,
  performanceMetricValue?: number,
  isNegetiveTarget?: boolean
) => {
  if (isNegetiveTarget) {
    return;
  }

  if (
    isNil(campaignStartDate) ||
    isNil(selectedEndDate) ||
    (performanceMetricValue && !isNaN(performanceMetricValue))
  ) {
    return performanceMetricValue;
  }

  const daysDiff = campaignStartDate.diff(selectedEndDate, ['days']).days;

  return daysDiff <= 0 ? 0 : undefined;
};

export const shouldShowManageBidding = (
  optimizelyContext: OptimizelyContextState,
  adType: AdType
) =>
  optimizelyContext.featureFlags[OptimizelyFlags.AOManageBidding] &&
  adType !== AdType.SponsoredVideos;

export const shouldShowSmartCampaigns = (
  optimizelyContext: OptimizelyContextState
) =>
  optimizelyContext.featureFlags[OptimizelyFlags.SmartCampaignCreator] || false;

export const campaignDetailsFieldsForCSVExport: string[] = [
  CAMPAIGNS_API_COLUMN_NAME.MerchantName,
  AD_LEVEL_INTERNAL_ID_MAPPER[AdLevel.Campaigns] || '',
  AD_LEVEL_EXTERNAL_ID_MAPPER[AdLevel.Campaigns][0],
  CAMPAIGNS_API_COLUMN_NAME.CampaignName,
];

export const campaignflywheelSettingsFieldsForCSVExport: string[] = [
  CAMPAIGNS_API_COLUMN_NAME.AutomationStatus,
  CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
  CAMPAIGNS_API_COLUMN_NAME.MinBid,
  CAMPAIGNS_API_COLUMN_NAME.MaxBid,
];

export const adGroupDetailsFieldsForCSVExport: string[] = [
  ADGROUPS_API_COLUMN_NAME.MerchantName,
  AD_LEVEL_EXTERNAL_ID_MAPPER[AdLevel.AdGroups][0],
  AD_LEVEL_EXTERNAL_ID_MAPPER[AdLevel.Campaigns][0],
  AD_LEVEL_INTERNAL_ID_MAPPER[AdLevel.Campaigns] || '',
  ADGROUPS_API_COLUMN_NAME.CampaignName,
  AD_LEVEL_INTERNAL_ID_MAPPER[AdLevel.AdGroups] || '',
  ADGROUPS_API_COLUMN_NAME.AdGroupName,
];

export const adGroupflywheelSettingsFieldsForCSVExport: string[] = [
  ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
  ADGROUPS_API_COLUMN_NAME.MACSTarget,
  ADGROUPS_API_COLUMN_NAME.MinBid,
  ADGROUPS_API_COLUMN_NAME.MaxBid,
];

export const adGroupSmartAcosFieldsForCsvExport: string[] = [
  ADGROUPS_API_COLUMN_NAME.AiBidding,
  ADGROUPS_API_COLUMN_NAME.EstimatedPreAdGrossMargin,
  ADGROUPS_API_COLUMN_NAME.BidModifier,
  CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal,
  ADGROUPS_API_COLUMN_NAME.BidModifierOverRide,
  ADGROUPS_API_COLUMN_NAME.BidModifierOverRideEndTime,
];

export const getMerchantCountryNameForCSVDownload = (
  selectedMerchantCountries: Array<number | string> | string,
  selectedMerchantCountryInfo?: MerchantCountry
) =>
  isArray(selectedMerchantCountries) && selectedMerchantCountries.length > 1
    ? undefined
    : selectedMerchantCountryInfo?.merchantName;

export const getMerchantName = (
  merchantDetails: MerchantCountry[],
  merchantId?: string
): string | undefined =>
  merchantId
    ? merchantDetails.find(
        (merchant) => merchant.merchantCountryId === merchantId
      )?.merchantName
    : undefined;

export const getMerchantCountry = (
  merchantDetails: MerchantCountry[],
  merchantId?: string
): string | undefined =>
  merchantId
    ? merchantDetails.find(
        (merchant) => merchant.merchantCountryId === merchantId
      )?.country
    : undefined;

export const isInputValueNumber = (inputValue: string) =>
  !isNaN(Number(inputValue));

export const getEditModePlacementStatusOption = (intl: IntlShape) => [
  {
    value: 'excluded',
    label: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS_FILTER_EXCLUDED,
    }),
  },
  {
    value: 'included',
    label: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS_FILTER_INCLUDED,
    }),
  },
];

export const getAutomationDisabledTooltipMessage = (value: string) => (
  <p className="w-200 text-center">
    <FormattedMessage
      id={I18nKey.ADS_MANAGER_TOOLTIP_MESSAGE_AUTOMATION_DISABLED}
      values={{
        value,
      }}
    />
  </p>
);

export const getAutomationDisabledForLegacyCampaignTooltipMessage = () => (
  <p className="w-200 text-center">
    <FormattedMessage
      id={
        I18nKey.ADS_MANAGER_TOOLTIP_MESSAGE_AUTOMATION_DISABLED_FOR_LEGANCY_CAMPAIGN
      }
    />
  </p>
);

export const isDatelessThanGivenDate = (date1: DateTime, date2: DateTime) =>
  date1.startOf('day') < date2.startOf('day');

export const areAllSelectedCampaignCostTypeVcpm = (
  tableChange: TableChange,
  tableData: AdGroupDetails[]
) =>
  tableChange.select.rows.every((rowId: string) => {
    const rowData = find(tableData, {
      [TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]]: rowId,
    });
    return isCampaignCostTypeVcpm(rowData?.adGroupDetails.campaignCostType);
  });

export const areAllSelectedCampaignCostTypeCpc = (
  tableChange: TableChange,
  tableData: AdGroupDetails[]
) =>
  tableChange.select.rows.every((rowId: string) => {
    const rowData = find(tableData, {
      [TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]]: rowId,
    });
    return isCampaignCostTypeCPC(rowData?.adGroupDetails.campaignCostType);
  });

export const isCampaignCostTypeVcpm = (
  campaignCostType: CampaignCostType | undefined
) => campaignCostType === CampaignCostType.VCPM;

export const isCampaignCostTypeCPC = (
  campaignCostType: CampaignCostType | undefined
) => campaignCostType === CampaignCostType.CPC;

/*
Target types API is to be called only in the following conditions.

1. channel = Amazon
2. It should be a details page
3. Campaigns & SB / AdGroups & SP / AdGroups & SD / Campaigns & SP
Entity in the above message is actually the details page entity. Eg: Campaign means it's for Campaign details page.
*/

export const shouldFetchSpecificTargetTypes = (
  identifiedAdLevel: AdLevel,
  adTypeDropDownValue: AdType | undefined,
  selectedFlywheelChannel: FlywheelSalesChannel,
  adsManagerDetailsPageFlag: boolean
) =>
  ((identifiedAdLevel === AdLevel.Campaigns &&
    (adTypeDropDownValue === AdType.SponsoredProducts ||
      adTypeDropDownValue === AdType.SponsoredBrands)) ||
    (identifiedAdLevel === AdLevel.AdGroups &&
      (adTypeDropDownValue === AdType.SponsoredProducts ||
        adTypeDropDownValue === AdType.SponsoredDisplay ||
        adTypeDropDownValue === AdType.SponsoredBrands))) &&
  selectedFlywheelChannel === FlywheelSalesChannel.Amazon &&
  adsManagerDetailsPageFlag;

export const isFlyWheelSettingsField = (field: string) => {
  const MAP = {
    [DATA_INSPECTOR_COLUMN_NAME[
      DataInspectorColumnIdentifier.BidAutomationStatus
    ]]: true,
    [DATA_INSPECTOR_COLUMN_NAME[DataInspectorColumnIdentifier.MACSTarget]]:
      true,
    [DATA_INSPECTOR_COLUMN_NAME[DataInspectorColumnIdentifier.MinBid]]: true,
    [DATA_INSPECTOR_COLUMN_NAME[DataInspectorColumnIdentifier.MaxBid]]: true,
  };
  return MAP[field];
};

const getNewColumn = (
  column: string,
  selected: boolean,
  disabled?: boolean
) => {
  if (isFlyWheelSettingsField(column)) {
    return {
      [column]: {
        isSelected: selected || false,
        isDisabled: disabled,
      },
    };
  }

  return {
    [column]: {
      isSelected: selected || false,
    },
  };
};

export const getNewTabCheckboxValues = (
  selectedColumns: string[],
  adLevelConfig: DataInspectionAdLevelConfigProps,
  oldCheckboxConfig: TabCheckbox
) => {
  const selectedColumnsMap: {
    [column: string]: boolean;
  } = reduce(
    selectedColumns,
    (acc, column) => ({ ...acc, [column]: true }),
    {}
  );
  const newColumns: {
    [group: string]: {
      [column: string]: {
        isSelected: boolean;
        isDisabled?: boolean;
      };
    };
  } = {};
  Object.keys(adLevelConfig.columns).forEach((group) => {
    if (!newColumns[group]) {
      newColumns[group] = {};
    }
    Object.keys(adLevelConfig.columns[group]).forEach((column) => {
      newColumns[group][column] = getNewColumn(
        column,
        selectedColumnsMap[column],
        oldCheckboxConfig?.[group]?.[column]?.isDisabled
      )[column];
    });
  });

  return newColumns;
};

export const isBetweenMinAndMaxValue = (
  value: number,
  minValue: number,
  maxValue: number
): boolean => value >= minValue && value <= maxValue;

export const getDatePresentRangeForTracking = (diffDays: number) => {
  const keys: PredefinedDateRange[] = Object.keys(
    DATE_RANGE_CONFIG_FOR_DATA_TRACKING
  ) as PredefinedDateRange[];
  return keys.find((preDefinedRange) => {
    if (
      isBetweenMinAndMaxValue(
        diffDays,
        DATE_RANGE_CONFIG_FOR_DATA_TRACKING[preDefinedRange].min,
        DATE_RANGE_CONFIG_FOR_DATA_TRACKING[preDefinedRange].max
      )
    ) {
      return preDefinedRange;
    }
    return false;
  });
};

export const isValidFlywheelSetting = (
  initialSettings:
    | AdGroupDetails['flywheelSettings']
    | CampaignDetails['flywheelSettings']
    | undefined,
  currentSettings: {
    minBid?: string;
    maxBid?: string;
    macsTarget?: string | number;
  },
  currentFieldValue?: string
) => {
  // if automationStatus is set then the default settings will apply, so returning true.
  // if current field has value, then no need to check
  // if nothing has updated in any of 3 fields then no need to check
  if (
    !isNil(initialSettings?.automationStatus) ||
    currentFieldValue ||
    isEqual(
      {
        minBid: initialSettings?.minBid?.amount,
        maxBid: initialSettings?.maxBid?.amount,
        macsTarget: initialSettings?.macsTarget,
      },
      currentSettings
    )
  ) {
    return true;
  }

  // As per ticket IFD-416
  // will only return false if current field has no value & any of other fields have value
  // Ex: minBid: unset, maxBid: 1 & if acosTarget is unset, then acosTarget has invalid state
  // @ts-ignore
  return !Object.keys(currentSettings).filter((key) => !!currentSettings[key])
    .length;
};

export const getUpdatedValueFromOperator = (
  existingValue: number | string | undefined,
  changes: string | number,
  bulkValue: string | number,
  operator?: BulkEditModalNumericValueOperator,
  inputType?: NumericInputType
) => {
  let fractionDigits = MONETARY_FRACTION_DIGITS;
  let currentValue = Number(changes || existingValue) || 0;
  if (inputType === NumericInputType.Percent) {
    currentValue = Number(changes || Number(existingValue) * 100) || 0;
    fractionDigits = PERCENTAGE_FRACTION_DIGITS;
  }
  if (!operator) {
    return Number(currentValue).toFixed(fractionDigits);
  }

  if (bulkValue === '' || isNil(bulkValue)) {
    return Number(changes ?? existingValue).toFixed(fractionDigits);
  }
  let newValue = Number(bulkValue);
  let negativeFlag = currentValue < 0 ? -1 : 1;
  switch (operator) {
    case BulkEditModalNumericValueOperator.INCREASE_BY_PERCENT: {
      newValue = (1 + (negativeFlag * newValue) / 100) * currentValue;
      break;
    }
    case BulkEditModalNumericValueOperator.DECREASE_BY_PERCENT: {
      newValue = (1 - (negativeFlag * newValue) / 100) * currentValue;
      break;
    }
    case BulkEditModalNumericValueOperator.INCREASE_BY_UNIT: {
      newValue += currentValue;
      break;
    }
    case BulkEditModalNumericValueOperator.DECREASE_BY_UNIT: {
      newValue = currentValue - newValue;
      break;
    }
  }
  return newValue.toFixed(fractionDigits);
};

export const getNumericValidators = (
  defaultValidators: BulkEditModalElementValidator[],
  intl: IntlShape,
  operator?: BulkEditModalNumericValueOperator
) => {
  if (!operator || operator === BulkEditModalNumericValueOperator.SET_TO) {
    return defaultValidators;
  }

  return [
    {
      validator: (value: string) => {
        return !!value && Number(value) > 0;
      },
      errorMessage: intl.formatMessage({
        id: I18nKey.BULKEDIT_MODAL_NO_VALUE_MESSAGE,
      }),
    },
  ];
};

export const getNewOperators = (
  rowIndex: number,
  previousOperators: BulkEditModalNumericValueOperator[],
  operator?: BulkEditModalNumericValueOperator
) => {
  const newOperators = [...previousOperators];
  if (operator) {
    newOperators[rowIndex] = operator;
  }
  return newOperators;
};

export const getOrDefaultIsBiddableFlag = (isBiddable?: boolean): boolean =>
  fromNullable(isBiddable).getOrElse(true);

export const NEGETIVE_TARGETS_MATCH_TYPE: MatchType[] = [
  MatchType.NegativeExact,
  MatchType.NegativePhrase,
];

export const getBidHelpGuide = ({
  bulkMenuValue,
  negativeKeywordIds,
  nonNegativeKeywordIds,
  isKeywordTarget,
  intl,
  selectedCount,
  validCount,
  numberOfNegativeTargets,
  selectedAdType,
  selectedValue,
  numberOfCampaignNegativeTargets,
}: BidHelpGuideType): HelpPromptProps | undefined => {
  if (
    bulkMenuValue === TARGETS_API_COLUMN_NAME.Bid &&
    negativeKeywordIds.length > 0 &&
    nonNegativeKeywordIds.length > 0
  ) {
    let targetLabelI18nKey = getTargetsLabel(isKeywordTarget);
    if (selectedAdType === AdType.SponsoredDisplay) {
      targetLabelI18nKey =
        I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_TARGETS;
    }
    return {
      text: intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_BID_HELP_TEXT,
        },
        {
          totalSelectedCount:
            negativeKeywordIds.length + nonNegativeKeywordIds.length,
          validCount: nonNegativeKeywordIds.length,
        }
      ),
      content: intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_BID_HELP_CONTENT,
        },
        {
          label: intl.formatMessage({
            id: targetLabelI18nKey,
          }),
        }
      ),
      variant: HelpPromptVariant.Dropdown,
    };
  } else if (
    bulkMenuValue === TARGETS_API_COLUMN_NAME.Bid &&
    !isUndefined(validCount) &&
    !isUndefined(selectedCount) &&
    validCount !== selectedCount
  ) {
    return {
      text: intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_SELECTION_COUNT,
        },
        {
          totalSelectedCount: selectedCount,
          validCount,
        }
      ),
      content: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_BID_LEGACY_NOT_BIDDABLE,
      }),
      variant: HelpPromptVariant.Dropdown,
    };
  }

  if (
    isTargetStatusPaused(
      bulkMenuValue,
      selectedAdType,
      numberOfNegativeTargets,
      selectedValue
    )
  ) {
    return {
      text: intl.formatMessage(
        {
          id: I18nKey.ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_SELECTION_COUNT,
        },
        {
          totalSelectedCount: selectedCount,
          validCount: (selectedCount ?? 0) - (numberOfNegativeTargets || 0),
        }
      ),
      content: intl.formatMessage({
        id: getI18nKeyForHelpGuide(selectedAdType!, isKeywordTarget, false),
      }),
      variant: HelpPromptVariant.Dropdown,
    };
  } else if (
    bulkMenuValue === TARGETS_API_COLUMN_NAME.TargetStatus &&
    numberOfCampaignNegativeTargets &&
    numberOfCampaignNegativeTargets > 0
  ) {
    if (selectedValue === 'paused') {
      return {
        text: intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_SELECTION_COUNT,
          },
          {
            totalSelectedCount: selectedCount,
            validCount: (selectedCount ?? 0) - numberOfCampaignNegativeTargets,
          }
        ),
        content: intl.formatMessage({
          id: getI18nKeyForHelpGuide(selectedAdType!, false, true),
        }),
        variant: HelpPromptVariant.Dropdown,
      };
    }
  }
};

export const getI18nKeyForHelpGuide = (
  adType: AdType,
  isKeywordTarget: boolean,
  isCampaignNegativeTarget: boolean
) => {
  if (isCampaignNegativeTarget) {
    return I18nKey.ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NEGATIVE_CAMPAIGN_KEYWORD_TARGETS_CANNOT_BE_PAUSED;
  }

  if (isKeywordTarget) {
    return I18nKey.ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NEGATIVE_KEYWORD_TARGETS_CANNOT_BE_PAUSED;
  }

  return adType === AdType.SponsoredDisplay
    ? I18nKey.ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NEGATIVE_TARGETS_CANNOT_BE_PAUSED
    : I18nKey.ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NEGATIVE_PRODUCT_TARGETS_CANNOT_BE_PAUSED;
};

export const getFlywheelSettingsColumn = (
  adLevel?: AdLevel | string,
  isAIPlanEnabled?: boolean,
  shouldShowSmartCampaigns?: boolean,
  showSmartAdGroupBidOverrides?: boolean
) => {
  if (!isAIPlanEnabled || adLevel !== AdLevel.AdGroups) {
    return [];
  }

  if (adLevel === AdLevel.AdGroups) {
    let defaultFwSettingColumns = [
      ADGROUPS_API_COLUMN_NAME.BidAutomationStatus,
      ADGROUPS_API_COLUMN_NAME.MACSTarget,
      ADGROUPS_API_COLUMN_NAME.MinBid,
      ADGROUPS_API_COLUMN_NAME.MaxBid,
    ];
    if (shouldShowSmartCampaigns) {
      return [
        ...defaultFwSettingColumns,
        ADGROUPS_API_COLUMN_NAME.AiBidding,
        ADGROUPS_API_COLUMN_NAME.EstimatedPreAdGrossMargin,
        ADGROUPS_API_COLUMN_NAME.IsCogs,
        ADGROUPS_API_COLUMN_NAME.BidModifier,
        CAMPAIGNS_API_COLUMN_NAME.AdvertisingGoal,
        ADGROUPS_API_COLUMN_NAME.BidModifierOverRide,
        ADGROUPS_API_COLUMN_NAME.BidModifierOverRideEndTime,
        ...(showSmartAdGroupBidOverrides
          ? [
              ADGROUPS_API_COLUMN_NAME.MinBidOverrideEnabled,
              ADGROUPS_API_COLUMN_NAME.MinBidRestrictedEntities,
              ADGROUPS_API_COLUMN_NAME.MaxBidOverrideEnabled,
              ADGROUPS_API_COLUMN_NAME.MaxBidRestrictedEntities,
            ]
          : []),
      ];
    }
    return defaultFwSettingColumns;
  } else {
    return [
      CAMPAIGNS_API_COLUMN_NAME.AutomationStatus,
      CAMPAIGNS_API_COLUMN_NAME.MACSTarget,
      CAMPAIGNS_API_COLUMN_NAME.MinBid,
      CAMPAIGNS_API_COLUMN_NAME.MaxBid,
    ];
  }
};

export const getAiHintStatusIfAdgroupOrCampaginSBType = (
  adLevelDropDownSelectedValues: '' | AdLevel,
  adTypeDropDownValue?: AdType,
  showAIIconForSV?: boolean
) => {
  if (adTypeDropDownValue === AdType.SponsoredVideos && !showAIIconForSV) {
    return undefined;
  }

  return adLevelDropDownSelectedValues === AdLevel.AdGroups ||
    (adLevelDropDownSelectedValues === AdLevel.Campaigns &&
      adTypeDropDownValue === AdType.SponsoredBrands)
    ? AIHintStatus.Active
    : AIHintStatus.Initial;
};

export const TargetStatusMapping: { [status: string]: TargetStatus } = {
  deleted: TargetStatus.Archived,
};

export const isAIEnabledForMerchants = (
  selectedMerchantCountries: (string | number)[] | (string | number),
  selectedFlywheelChannel?: FlywheelSalesChannel,
  merchantsBySalesChannel?: MerchantsBySalesChannel[]
) => {
  if (
    selectedFlywheelChannel === FlywheelSalesChannel.Amazon &&
    merchantsBySalesChannel
  ) {
    const merchantCountryInfo = merchantsBySalesChannel
      .find(
        ({ flywheelSalesChannel }) =>
          flywheelSalesChannel === selectedFlywheelChannel
      )
      ?.merchants.find(({ id }) => selectedMerchantCountries === id);
    return merchantCountryInfo?.aiEnabled;
  }

  if (
    selectedFlywheelChannel === FlywheelSalesChannel.Walmart &&
    merchantsBySalesChannel &&
    isArray(selectedMerchantCountries)
  ) {
    return merchantsBySalesChannel
      .find(
        ({ flywheelSalesChannel }) =>
          flywheelSalesChannel === selectedFlywheelChannel
      )
      ?.merchants.filter(({ id }) => selectedMerchantCountries.includes(id))
      .some(
        ({ adConnectionStatus, aiEnabled }) =>
          aiEnabled === true &&
          adConnectionStatus === FAMConnectionStatus.Active
      );
  }

  return true;
};

export const getTargetsLabel = (isKeywordTarget: boolean) =>
  isKeywordTarget
    ? I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_KEYWORDS_ADS
    : I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PRODUCT_TARGETS;

export const isTargetStatusPaused = (
  bulkMenuValue: string | null,
  selectedAdType?: AdType,
  numberOfNegativeTargets?: number,
  selectedValue?: string
) =>
  bulkMenuValue === TARGETS_API_COLUMN_NAME.TargetStatus &&
  selectedAdType &&
  [AdType.SponsoredBrands, AdType.SponsoredDisplay].includes(selectedAdType) &&
  numberOfNegativeTargets &&
  numberOfNegativeTargets > 0 &&
  selectedValue === 'paused';

export const getMerchantAsArray = (firstMerchantFromList?: string | number) =>
  firstMerchantFromList ? [firstMerchantFromList] : [];

export const getCurrentRowIndexForTargetTable =
  (targetDetails: TargetsDetails) =>
  ({ tableState }: WithTable<TargetsDetails>) => {
    const visibleData = tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<TargetsDetails, void>()(
        tableState,
        ADS_MANAGER_TARGETS_TABLE_ID
      )
    );
    const index = (function () {
      for (let i = 0; i < visibleData.length; i++) {
        if (visibleData[i].targetId === targetDetails.targetId) {
          return i;
        }
      }
      return -1;
    })();

    return index;
  };

export const getCurrentRowIndexForSVAds =
  (adGroupDetails: ProductAdDetails) =>
  ({ tableState }: WithTable<ProductAdDetails>) => {
    const visibleData = tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<ProductAdDetails, void>()(
        tableState,
        ADS_MANAGER_ADGROUP_TABLE_ID
      )
    );
    const index = (function () {
      for (let i = 0; i < visibleData.length; i++) {
        if (visibleData[i].adItemId === adGroupDetails.adItemId) {
          return i;
        }
      }
      return -1;
    })();

    return index;
  };

export const getSelectedMerchantCountryId = (
  merchantCountries: Array<number | string> | string
) => {
  return isArray(merchantCountries)
    ? first(merchantCountries as string[])
    : merchantCountries;
};

export const getCampaignTypeFromAddedBy = (addedBy?: AddedBy): CampaignType => {
  if (addedBy) {
    switch (addedBy) {
      case AddedBy.External:
        return CampaignType.External;
      default:
        return CampaignType.Smart;
    }
  } else {
    return CampaignType.External;
  }
};

export const getCampaignTypeQuickFilters = (
  intl: IntlShape,
  adType: AdType,
  defaultKey: string,
  onClick: (key: string) => void,
  showFilters: boolean,
  smartCount?: number,
  externalCount?: number
) => {
  if (adType !== AdType.SponsoredProducts || !showFilters) {
    return <></>;
  }
  return (
    <SegmentedLinks
      value={defaultKey}
      onChange={onClick}
      defaultSelectedKey={defaultKey}
      links={[
        {
          count:
            !isNil(smartCount) && !isNil(externalCount)
              ? (smartCount + externalCount).toString()
              : undefined,
          key: CampaignTypeQuickFilterKey.All,
          label: intl.formatMessage({ id: I18nKey.GENERIC_ALL }),
          onClick: () => {
            onClick(CampaignTypeQuickFilterKey.All);
          },
        },
        {
          count: !isNil(smartCount) ? smartCount.toString() : undefined,
          key: CampaignTypeQuickFilterKey.Smart,
          label: intl.formatMessage({ id: I18nKey.GENERIC_SMART }),
          showIcon: true,
          onClick: () => {
            onClick(CampaignTypeQuickFilterKey.Smart);
          },
        },
        {
          count: !isNil(externalCount) ? externalCount.toString() : undefined,
          key: CampaignTypeQuickFilterKey.External,
          label: intl.formatMessage({ id: I18nKey.GENERIC_EXTERNAL }),
          onClick: () => {
            onClick(CampaignTypeQuickFilterKey.External);
          },
        },
      ]}
    />
  );
};

export const isValidSmartAcosFwSetting = (
  params: SmartAcosFwSettingParams
): boolean => {
  if (params.aiBidding?.toString() === AiBiddingValues.NotEnabled) {
    return true;
  } else if (
    params.aiBidding?.toString() === AiBiddingValues.Ai ||
    AiBiddingValues.Smart
  ) {
    return (
      !isNil(params.acosLimit) &&
      !isNil(params.bidModifier) &&
      Number(params.acosLimit) !== 0 &&
      Number(params.bidModifier) !== 0
    );
  }
  return false;
};
