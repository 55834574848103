import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputState,
  Placement,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeNumericInputColumn,
  makePercentColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import { CampaignDetails } from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
} from '../utils';
import { isDirty } from './totalBudget';

export const MIN_BUY_BOX_BID = 0;

const isInvalidInput = (
  value: string,
  existingValue: string,
  isInputEdited: boolean
) => {
  return (
    (Number(value) < MIN_BUY_BOX_BID ||
      isNaN(Number(value)) ||
      isEmpty(value)) &&
    (isInputEdited || isDirty(value, existingValue))
  );
};

export const getControlledTooltip = (
  state: NumericInputState,
  focused: boolean
) =>
  state === NumericInputState.Error && focused
    ? ControlledTooltip.Show
    : ControlledTooltip.Hide;

export const getBuyBoxBidMultiplier = ({
  channelSettings: { placementBidMultiplier },
}: CampaignDetails) => {
  if (
    placementBidMultiplier &&
    !isNil(placementBidMultiplier.buyBoxBidMultiplier)
  ) {
    return placementBidMultiplier.buyBoxBidMultiplier.toString();
  }
  return '';
};

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, ...campaignDetails }) => {
  const intl = useIntl();

  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const existingValue =
    (campaignDetails.channelSettings.placementBidMultiplier?.buyBoxBidMultiplier?.toString() &&
      (
        campaignDetails.channelSettings.placementBidMultiplier
          ?.buyBoxBidMultiplier * 100
      ).toFixed(PERCENTAGE_FRACTION_DIGITS)) ||
    '';
  let buyBoxBidMultiplier = changedValue ?? existingValue;

  const [value, setValue] = useState<string>(buyBoxBidMultiplier);
  const [isInputEdited, setIsInputEdited] = useState<boolean>(false);

  const onComponentUnmount = () => {
    if (!isEditMode) {
      setIsInputEdited(false);
    }
  };

  useEffect(() => {
    if (
      isEditMode ||
      (!isNil(changedValue) && changedValue !== value) // Changes done due to bulk update. changedValue is latest, but state variable is not
    ) {
      setValue(buyBoxBidMultiplier);
    }

    return onComponentUnmount;
  }, [isEditMode, changedValue, currentPage]);

  const dispatch = useDispatch();

  const [focussed, setFocussed] = useState<boolean>(false);

  const updateCellValue = (newValue: string) =>
    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier,
        rowId: campaignDetails.campaignId,
        existingValue,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        value: newValue,
        numericValue: true,
      })
    );
  const debounceCellValueUpdate = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignDetails.campaignId]
  );

  const isInputInvalid: boolean = isInvalidInput(
    value,
    existingValue,
    isInputEdited
  );

  const onInputFocus = () => setFocussed(true);
  const onInputBlur = () => {
    if (!isEmpty(value)) {
      debounceCellValueUpdate.cancel();
      const formattedValue = Number.parseFloat(value).toFixed(
        PERCENTAGE_FRACTION_DIGITS
      );
      setValue(formattedValue);
      updateCellValue(formattedValue);
    }
    setFocussed(false);
  };

  const state = isInputInvalid
    ? NumericInputState.Error
    : NumericInputState.Default;

  const controlledTooltip = getControlledTooltip(state, focussed);

  const numericInputProps = {
    value,
    state,
    dataTestId: CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier,
    appendedElement: '%',
    placeholder: MIN_BUY_BOX_BID.toFixed(PERCENTAGE_FRACTION_DIGITS),
    onChange: (inputValue: string) => {
      setIsInputEdited(true);
      setValue(inputValue);
      debounceCellValueUpdate(inputValue);
    },
    tooltipContent: getTooltipContent(isInputInvalid, intl),
    controlledTooltip,
    tooltipPlacement: Placement.Bottom,
    style: ContentStyle.Bold,
    onInputFocus,
    onInputBlur,
    acceptOnlyPositiveNumbers: true,
    isDirty: isDirty(value, existingValue),
    minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
    maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
  };
  if (isEditMode) {
    return makeNumericInputColumn<CampaignDetails>(() => numericInputProps)(
      campaignDetails
    );
  }

  return makePercentColumn<CampaignDetails>(
    getBuyBoxBidMultiplier,
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const buyBoxBidMultiplierColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_BUY_BOX,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const buyBoxBidMultiplierFilter = createPercentDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.BuyBoxBidMultiplier,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_BUY_BOX,
  isValidNumber()
);

const getTooltipContent = (invalidInput: boolean, intl: IntlShape) => {
  if (invalidInput) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          { id: I18nKey.ADS_MANAGER_INVALID_NUMERIC_ATLEAST_PERCENT },
          {
            minValue: intl.formatNumber(MIN_BUY_BOX_BID, {
              minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
              maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
            }),
          }
        )}
      </p>
    );
  }
};
