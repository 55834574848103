import React from 'react';
import { useIntl } from 'react-intl';

import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../../ConnectModalWrapper';
import { ModalContent } from './content';
import { ModalFooter } from './footer';

export interface AmazonAuthFailedModalProps {
  readonly channel: FlywheelSalesChannel;
  readonly goBack: () => void;
  readonly authorize: () => void;
  readonly closeModal?: () => void;
}

export const AmazonAuthFailedModal: React.FC<AmazonAuthFailedModalProps> = ({
  channel,
  goBack,
  authorize,
  closeModal,
}) => {
  const intl = useIntl();

  return (
    <ConnectModalWrapper
      dataTestId="amazon_auth_failed_modal"
      isOpen
      headerText={intl.formatMessage({
        id: I18nKey.FLASH_MODALS_AMAZON_MODAL_HEADER,
      })}
      headerChannelType={channel}
      content={<ModalContent />}
      footer={<ModalFooter goBack={goBack} authorize={authorize} />}
      closeModal={closeModal}
    />
  );
};
AmazonAuthFailedModal.displayName = 'AmazonAuthFailedModal';
