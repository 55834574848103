import { ColumnManagerOption } from '@teikametrics/tm-design-system';

import {
  KeywordActionColumns,
  SelectedRecommendationsV2,
} from '../../lib/types/KeywordAction';
import { ColumnManagerOptionWithIsSelected } from '../products/containers/skuCatalog/types';
import { UserInfo } from '../products/containers/utils/localStorage';
import { UserProps } from '../advertisingOptimization/containers/adsManager/utils/storageUtils';
import { Fw2LocalStorage } from '../../lib/utilities/fw2LocalStorage';
import { MerchantCountryId } from '../../lib/types/AOSharedTypes';

const getLocalStorageKeyForNKWRColumnManagerConfig = ({
  userId,
  accountId,
}: UserInfo) =>
  `${userId}_${accountId}_nkwr_column_manager_config
  }`;

export const getNKWRColumnManagerConfigFromLocalStorage = ({
  userId,
  accountId,
}: UserInfo): ColumnManagerOptionWithIsSelected[] => {
  try {
    const key = getLocalStorageKeyForNKWRColumnManagerConfig({
      userId,
      accountId,
    });
    const selectedColumns = Fw2LocalStorage.getItem(key) || JSON.stringify([]);
    return JSON.parse(selectedColumns);
  } catch (error) {
    return [];
  }
};

export const storeNKWRColumnManagerConfigToLocalStorage = ({
  userId,
  accountId,
  values,
}: UserInfo & Readonly<{ values: ColumnManagerOption[] }>) => {
  const key = getLocalStorageKeyForNKWRColumnManagerConfig({
    userId,
    accountId,
  });
  Fw2LocalStorage.setItem(key, JSON.stringify(values));
};

const getLocalStorageKeyNameForRecommendationsColumnManagerConfig = ({
  userId,
  accountId,
  isV2,
}: UserInfo) =>
  `${userId}_${accountId}_recommendations_column_manager_config${
    isV2 ? '_v2' : ''
  }`;

export const getRecommendationsColumnManagerConfigFromLocalStorage = ({
  userId,
  accountId,
  isV2,
}: UserInfo): ColumnManagerOptionWithIsSelected[] => {
  try {
    const key = getLocalStorageKeyNameForRecommendationsColumnManagerConfig({
      userId,
      accountId,
      isV2,
    });
    const selectedColumns = Fw2LocalStorage.getItem(key) || JSON.stringify([]);
    return JSON.parse(selectedColumns);
  } catch (error) {
    return [];
  }
};

export const storeRecommendationsColumnManagerConfigToLocalStorage = ({
  userId,
  accountId,
  values,
  isV2,
}: UserInfo & Readonly<{ values: ColumnManagerOption[] }>) => {
  const key = getLocalStorageKeyNameForRecommendationsColumnManagerConfig({
    userId,
    accountId,
    isV2,
  });
  Fw2LocalStorage.setItem(key, JSON.stringify(values));
};

const getLocalStorageKeyNameForSelectedColumnsRecommendations = ({
  userId,
  accountId,
  isV2,
}: UserInfo) =>
  `${userId}_${accountId}_recommendations_column_manager_selected_columns${
    isV2 ? '_v2' : ''
  }`;

export const getSelectedColumnsRecommendationsFromLocalStorage = ({
  userId,
  accountId,
  isV2,
}: UserInfo): KeywordActionColumns[] => {
  try {
    const key = getLocalStorageKeyNameForSelectedColumnsRecommendations({
      userId,
      accountId,
      isV2,
    });
    const selectedColumns = Fw2LocalStorage.getItem(key) || JSON.stringify([]);
    return JSON.parse(selectedColumns);
  } catch (error) {
    return [];
  }
};

export const storeSelectedColumnsRecommendationsToLocalStorage = ({
  userId,
  accountId,
  values,
  isV2,
}: UserInfo & Readonly<{ values: KeywordActionColumns[] }>) => {
  const key = getLocalStorageKeyNameForSelectedColumnsRecommendations({
    userId,
    accountId,
    isV2,
  });
  Fw2LocalStorage.setItem(key, JSON.stringify(values));
};

const getLocalStorageKeyNameForSelectedMerchantRecommendations = ({
  userId,
  accountId,
}: UserInfo & Readonly<{ isV2Page?: boolean }>) =>
  `${userId}_${accountId}_recommendations_table_selected_merchant_v2`;

const getLocalStorageKeyNameForSelectedMerchantViewRecommendations = ({
  userId,
  accountId,
}: UserInfo) =>
  `${userId}_${accountId}_view_recommendations_table_selected_merchants`;

const getLocalStorageKeyNameForSelectedMerchantEnterCogsRecommendations = ({
  userId,
  accountId,
}: UserInfo) =>
  `${userId}_${accountId}_enter_cogs_recommendations_table_selected_merchants`;

export const storeSelectedMerchantRecommendationsToLocalStorage = ({
  userId,
  accountId,
  merchantId,
}: UserInfo &
  Readonly<{ merchantId: MerchantCountryId; isV2Page?: boolean }>) => {
  const key = getLocalStorageKeyNameForSelectedMerchantRecommendations({
    userId,
    accountId,
  });
  Fw2LocalStorage.setItem(key, JSON.stringify(merchantId));
};

export const getSelectedMerchantRecommendationsFromLocalStorage = ({
  userId,
  accountId,
}: UserInfo & Readonly<{ isV2Page?: boolean }>): string => {
  try {
    const key = getLocalStorageKeyNameForSelectedMerchantRecommendations({
      userId,
      accountId,
    });
    const selectedMerchant = Fw2LocalStorage.getItem(key) || JSON.stringify('');
    return JSON.parse(selectedMerchant);
  } catch (error) {
    return '';
  }
};

export const storeSelectedMerchantsViewRecommendationsToLocalStorage = ({
  userId,
  accountId,
  merchantIds,
}: UserInfo & Readonly<{ merchantIds: MerchantCountryId[] }>) => {
  const key = getLocalStorageKeyNameForSelectedMerchantViewRecommendations({
    userId,
    accountId,
  });
  Fw2LocalStorage.setItem(key, JSON.stringify(merchantIds));
};

export const storeSelectedMerchantsForEnterCogsRecommendationsToLocalStorage =
  ({
    userId,
    accountId,
    merchantIds,
  }: UserInfo & Readonly<{ merchantIds: MerchantCountryId[] }>) => {
    const key =
      getLocalStorageKeyNameForSelectedMerchantEnterCogsRecommendations({
        userId,
        accountId,
      });
    Fw2LocalStorage.setItem(key, JSON.stringify(merchantIds));
  };

export const getSelectedMerchantsForEnterCogsRecommendationsToLocalStorage = ({
  userId,
  accountId,
}: UserInfo): string[] => {
  try {
    const key =
      getLocalStorageKeyNameForSelectedMerchantEnterCogsRecommendations({
        userId,
        accountId,
      });
    const selectedMerchants = Fw2LocalStorage.getItem(key);
    return selectedMerchants ? JSON.parse(selectedMerchants) : [];
  } catch (error) {
    return [];
  }
};

export const getSelectedMerchantsViewRecommendationsFromLocalStorage = ({
  userId,
  accountId,
}: UserInfo): string[] => {
  try {
    const key = getLocalStorageKeyNameForSelectedMerchantViewRecommendations({
      userId,
      accountId,
    });
    const selectedMerchants = Fw2LocalStorage.getItem(key);
    return selectedMerchants ? JSON.parse(selectedMerchants) : [];
  } catch (error) {
    return [];
  }
};

export const getLocalStorageKeyForRecommendationAdTypes = ({
  userId,
  accountId,
}: UserProps) => `recommendations_${userId}_${accountId}_adTypes`;

export const getRecommendationAdTypesFromLocalStorage = ({
  userId,
  accountId,
}: UserProps): string[] => {
  try {
    const key = getLocalStorageKeyForRecommendationAdTypes({
      userId,
      accountId,
    });
    const adTypes = Fw2LocalStorage.getItem(key);
    return adTypes ? JSON.parse(adTypes) : [];
  } catch (error) {
    return [];
  }
};

export const storeRecommendationAdTypesToLocalStorage = (
  userId: string,
  accountId: string,
  adTypeValues: string[]
): void => {
  const adTypeStorageKey = getLocalStorageKeyForRecommendationAdTypes({
    userId,
    accountId,
  });
  Fw2LocalStorage.setItem(adTypeStorageKey, JSON.stringify(adTypeValues));
};

export const getLocalStorageKeyNameForUpdatedAdgroups = ({
  userId,
  accountId,
}: UserInfo) =>
  `${userId}_${accountId}_view_recommendations_table_updated_adgroups`;

export const storeUpdatedAdgroupsToLocalStorage = ({
  userId,
  accountId,
  adGroups,
}: UserInfo & Readonly<{ adGroups: SelectedRecommendationsV2 }>) => {
  const key = getLocalStorageKeyNameForUpdatedAdgroups({
    userId,
    accountId,
  });
  Fw2LocalStorage.setItem(key, JSON.stringify(adGroups));
};

export const getUpdatedAdgroupsFromLocalStorage = ({
  userId,
  accountId,
}: UserInfo): SelectedRecommendationsV2 => {
  try {
    const key = getLocalStorageKeyNameForUpdatedAdgroups({
      userId,
      accountId,
    });
    const adGroups = Fw2LocalStorage.getItem(key);
    return adGroups ? JSON.parse(adGroups) : {};
  } catch (error) {
    return {};
  }
};
