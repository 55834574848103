import { IntlShape } from 'react-intl';

import {
  TableCellNumberWithProgressBar,
  TableCellV2,
  TableCellV2Type,
} from '@teikametrics/tm-design-system';

import {
  MONETARY_FRACTION_DIGITS,
  NO_FRACTION_DIGITS,
  PERCENTAGE_FRACTION_DIGITS,
} from '../../../../lib/types/CommonSharedTypes';
import { MoneyWithAmountInString } from '../../../../lib/types/Money';
import {
  CalculateComparisionTrendFunction,
  getMoneyTableCellComparisionProps,
  getNumericTableCellComparisionProps,
  getPercentageTableCellComparisionProps,
} from './';

export function makeMoneyTableCellWithOrWithoutComparision({
  showPreviousData,
  current,
  previous,
  intl,
  className,
  calculateComparisionTrendFunction,
}: Readonly<{
  current?: MoneyWithAmountInString;
  previous?: MoneyWithAmountInString;
  showPreviousData: boolean;
  intl: IntlShape;
  className?: string;
  calculateComparisionTrendFunction: CalculateComparisionTrendFunction;
}>) {
  const { pillText, comparisonTrend, currentValue, previousValue } =
    getMoneyTableCellComparisionProps(
      intl,
      calculateComparisionTrendFunction,
      current,
      previous
    );

  return (
    <TableCellV2
      className={className}
      tableCellData={{
        tableCellType: showPreviousData
          ? TableCellV2Type.Comparison
          : TableCellV2Type.Number,
        comparisonTrend,
        currentValue,
        previousValue,
        text: pillText,
      }}
    />
  );
}

export function makePercentTableCellWithOrWithoutComparision({
  showPreviousData,
  current,
  previous,
  intl,
  className,
  calculateComparisionTrendFunction,
}: Readonly<{
  current?: number;
  previous?: number;
  showPreviousData: boolean;
  intl: IntlShape;
  className?: string;
  calculateComparisionTrendFunction: CalculateComparisionTrendFunction;
}>) {
  const { pillText, comparisonTrend, currentValue, previousValue } =
    getPercentageTableCellComparisionProps(
      intl,
      calculateComparisionTrendFunction,
      current,
      previous
    );

  return (
    <TableCellV2
      className={className}
      tableCellData={{
        tableCellType: showPreviousData
          ? TableCellV2Type.Comparison
          : TableCellV2Type.Number,
        comparisonTrend,
        currentValue,
        previousValue,
        text: pillText,
      }}
    />
  );
}

export function makeNumericTableCellWithOrWithoutComparision({
  current,
  previous,
  allowedDigits,
  showPreviousData,
  intl,
  className,
  calculateComparisionTrendFunction,
}: Readonly<{
  current?: number;
  previous?: number;
  showPreviousData: boolean;
  allowedDigits?: number;
  intl: IntlShape;
  className?: string;
  calculateComparisionTrendFunction: CalculateComparisionTrendFunction;
}>) {
  const { pillText, comparisonTrend, currentValue, previousValue } =
    getNumericTableCellComparisionProps(
      intl,
      calculateComparisionTrendFunction,
      current,
      previous,
      allowedDigits
    );

  return (
    <TableCellV2
      className={className}
      tableCellData={{
        tableCellType: showPreviousData
          ? TableCellV2Type.Comparison
          : TableCellV2Type.Number,
        comparisonTrend,
        currentValue,
        previousValue,
        text: pillText,
      }}
    />
  );
}

export const makeNumberWithProgressBarColumn =
  <T extends {}>(
    getProps: (o: T) => {
      className?: string;
      numericStyle: 'currency' | 'percent';
      currency?: string;
      intl: IntlShape;
    } & Omit<TableCellNumberWithProgressBar, 'tableCellType'>
  ) =>
  (props: T) => {
    const cellProps = getProps(props);
    const style = cellProps.numericStyle;
    const currentValue = cellProps.intl.formatNumber(
      parseFloat(cellProps.currentValue as string),
      {
        minimumFractionDigits:
          style === 'currency'
            ? NO_FRACTION_DIGITS
            : PERCENTAGE_FRACTION_DIGITS,
        maximumFractionDigits:
          style === 'currency'
            ? MONETARY_FRACTION_DIGITS
            : PERCENTAGE_FRACTION_DIGITS,
        style,
        currency: cellProps.currency,
      }
    );

    return (
      <TableCellV2
        className={cellProps?.className}
        tableCellData={{
          ...cellProps,
          currentValue,
          tableCellType: TableCellV2Type.NumberWithProgressBar,
        }}
      />
    );
  };
