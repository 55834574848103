import { Factory } from 'fishery';
import { MultichannelAdvertisingPenetrationProductDataResponse } from '../../../modules/products/containers/dashboard/types';
import { AMAZON_SALES_CHANNEL_ID } from '../../../lib/types/SalesChannels';

interface Response {
  readonly data: MultichannelAdvertisingPenetrationProductDataResponse;
}

export default Factory.define<Response>(() => ({
  data: {
    metricType: '',
    currency: 'USD',
    latestTimeStamp: '2024-06-06',
    summaryMetrics: {
      activeSkus: '1',
      totalSkus: '2',
      unadvertisedActiveSku: 1,
    },
    channelMetrics: [
      {
        salesChannelId: AMAZON_SALES_CHANNEL_ID,
        salesChannelName: 'Amazon',
        performance: {
          current: {
            activeSku: '1',
            unAdvertisedActiveSkuPercentage: '1',
            totalSku: '2',
            unAdvertisedActiveSku: '1',
          },
          previous: {},
        },
        merchantTypeMetrics: [
          {
            merchantType: 'seller',
            merchantCountries: [
              {
                merchantCountryId: 'merchant_country_id_1',
                merchantName: 'Merchant Country 1',
                merchantType: 'seller',
                salesChannelId: AMAZON_SALES_CHANNEL_ID,
                country: 'USD',
                performance: {
                  current: {
                    activeSku: '1',
                    unAdvertisedActiveSkuPercentage: '1',
                    totalSku: '2',
                    unAdvertisedActiveSku: '1',
                  },
                  previous: {},
                },
              },
            ],
            performance: {
              current: {
                activeSku: '1',
                unAdvertisedActiveSkuPercentage: '1',
                totalSku: '2',
                unAdvertisedActiveSku: '1',
              },
              previous: {},
            },
          },
        ],
      },
      {
        salesChannelId: AMAZON_SALES_CHANNEL_ID,
        salesChannelName: 'Amazon',
        performance: {
          current: {
            activeSku: '',
            unAdvertisedActiveSkuPercentage: '',
            totalSku: '',
            unAdvertisedActiveSku: '',
          },
          previous: {},
        },
        merchantTypeMetrics: [
          {
            merchantType: 'seller',
            merchantCountries: [
              {
                merchantCountryId: 'merchant_country_id_1',
                merchantName: 'Merchant Country 1',
                merchantType: 'seller',
                salesChannelId: AMAZON_SALES_CHANNEL_ID,
                country: 'USD',
                performance: {
                  current: {},
                  previous: {},
                },
              },
            ],
            performance: {
              current: {},
              previous: {},
            },
          },
        ],
      },
    ],
  },
}));
