/**
 * Calculates the location to open the popup window so that it is centered
 * within the parent window.
 * @param parentWindow parent window that is opening the popup
 * @param w width of popup window
 * @param h height of popup window
 * @returns string in the format of window features
 */
export const centerPopupInParent = (
  parentWindow: Window,
  w: number,
  h: number
): string => {
  const y =
    parentWindow!.top!.outerHeight / 2 + parentWindow!.top!.screenY - h / 2;
  const x =
    parentWindow!.top!.outerWidth / 2 + parentWindow!.top!.screenX - w / 2;
  return `width=${w},height=${h},top=${y},left=${x}`;
};

let windowObjectReference: Window | null = null;
let previousUrl: string | null = null;

export const openSignInWindow = (
  url: string,
  name: string,
  callback: (event: MessageEvent) => void
) => {
  window.removeEventListener('message', callback);
  const strWindowFeatures = `toolbar=no,menubar=no,${centerPopupInParent(
    window,
    800,
    940
  )}`;

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
    or if such pointer exists but the window was closed */
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
    then we load it in the already opened secondary window and then
    we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference?.focus();
  } else {
    /* else the window reference must exist and the window
    is not closed; therefore, we can bring it back on top of any other
    window with the focus() method. There would be no need to re-create
    the window or to reload the referenced resource. */
    windowObjectReference.focus();
  }

  window.addEventListener('message', (event) => callback(event), false);
  previousUrl = url;
};
