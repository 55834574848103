import './styles.scss';

import noop from 'lodash/noop';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';

import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../lib/types/I18nKey';

export interface VerifyEmailModalProps {
  readonly onClick: () => void;
}

export const VerifyEmailModal: React.FC<VerifyEmailModalProps> = ({
  onClick,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    onClick();
    navigate('/logout');
  };

  return (
    <Modal
      style={{
        content: {},
      }}
      isOpen={true}
      className="vem__modal"
      overlayClassName="vem__overlay"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={true}
      onRequestClose={noop}
    >
      <div className="vem__content">
        <div
          className="vem__title"
          style={{
            display: 'flex',
          }}
        >
          <FormattedMessage
            id={I18nKey.VERIFY_EMAIL_MODAL_TITLE}
            defaultMessage="Email address not verified"
          />
        </div>
        <div
          className="vem__subtitle"
          style={{
            display: 'flex',
          }}
        >
          <FormattedMessage
            id={I18nKey.VERIFY_EMAIL_MODAL_SUBTITLE}
            defaultMessage="We need to verify your email to enable your Flywheel account."
          />
        </div>
        <div
          className="vem__resend_container"
          style={{
            display: 'flex',
          }}
        >
          <Button
            size={ButtonSize.Large}
            variant={ButtonVariant.Primary}
            label={
              <FormattedMessage
                id={I18nKey.VERIFY_EMAIL_MODAL_BUTTON}
                defaultMessage="Resend verification email"
              />
            }
            dataTestId="resendVerificationEmail"
            onClick={handleClick}
          />
        </div>
      </div>
    </Modal>
  );
};
VerifyEmailModal.displayName = 'VerifyEmailModal';
