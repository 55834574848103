import isArray from 'lodash/isArray';

import { AdType } from '../../../../../lib/types/AOSharedTypes';
import {
  AD_GROUP_NAME,
  AD_TYPE,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
  TARGETING_TYPE,
} from '../types';

export const appendTargetingTypeToQueryParams = (
  hasExistingParams: boolean,
  targetingType?: string
) => {
  if (targetingType) {
    return hasExistingParams
      ? `&${TARGETING_TYPE}=${targetingType}`
      : `?${TARGETING_TYPE}=${targetingType}`;
  } else {
    return '';
  }
};

export const appendAdGroupNameToQueryParams = (
  hasExistingParams: boolean,
  adGroupName?: string
) => {
  if (adGroupName) {
    return hasExistingParams
      ? `&${AD_GROUP_NAME}=${adGroupName}`
      : `?${AD_GROUP_NAME}=${adGroupName}`;
  } else {
    return '';
  }
};

export const getAdsManagerDetailsQueryParams = (
  salesChannel: string | number | undefined,
  merchantCountries: (string | number)[] | string,
  adTypes: AdType[],
  targetingType?: string,
  adGroupName?: string
) =>
  salesChannel && merchantCountries && adTypes
    ? '?' +
      encodeURIComponent(
        `${SALES_CHANNEL_ID}=${salesChannel}&${MERCHANT_COUNTRY_IDS}=${getMerchantsAsString(
          merchantCountries
        )}&${AD_TYPE}=${adTypes.join(',')}`
      ) +
      appendTargetingTypeToQueryParams(true, targetingType) +
      appendAdGroupNameToQueryParams(true, adGroupName)
    : '' +
      appendTargetingTypeToQueryParams(false, targetingType) +
      appendAdGroupNameToQueryParams(true, adGroupName);

export const getMerchantsAsString = (
  merchantCountries: string | (string | number)[]
) =>
  isArray(merchantCountries) ? merchantCountries.join(',') : merchantCountries;
