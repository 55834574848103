import './tableSbAds.scss';

import { fromNullable } from 'fp-ts/lib/Option';
import first from 'lodash/first';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alignment,
  ButtonSize,
  ButtonState,
  ColumnManager,
  ColumnManagerOption,
  ColumnManagerVariant,
  DualModes,
  FiltersMenu,
  Placement,
  SearchInputSize,
  TableActionRow,
  Theme,
  Tooltip,
  TooltipSize,
} from '@teikametrics/tm-design-system';

import { useLocation } from 'react-router-dom';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';
import { tableActions, tableThunks } from '../../../../containers/table/ducks';
import { WithTable } from '../../../../containers/table/ducks/types';
import UpdatedFlywheelTable, {
  FlywheelTableColumnGroup,
} from '../../../../containers/table/UpdatedFlywheelTable';
import { FlywheelSearchInput } from '../../../../containers/tableV2/flywheelSearchInput';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { FAMApiClient } from '../../../../lib/clients/FAMApiClient';
import { PaginatedDataFetcher } from '../../../../lib/clients/types';
import { useTime } from '../../../../lib/hooks/useTime';
import {
  AdLevel,
  AdType,
  FlywheelSalesChannel,
  MerchantCountryCode,
  Portfolio,
  REQUEST_DATE_FORMAT,
  SbAdsDataRequest,
  SbAdsDetails,
} from '../../../../lib/types/AOSharedTypes';
import { MerchantCountry, MerchantType } from '../../../../lib/types/Fam';
import {
  COLUMNS_WITH_SINGLE_DECIMAL_FILTER,
  EqualToFilter,
  Filter,
} from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  CurrencyCode,
  getCurrencyCodeFromMerchantCountryCode,
} from '../../../../lib/utilities/currency';
import { MerchantContext, MerchantContextState } from '../../merchantsProvider';
import { ADS_MANAGER_SB_ADS_TABLE_ID } from './ducks/types';
import { ErrorTable } from './errorTable';
import ExportAdLevelData from './exportAdLevelData';
import { NoDataFoundTable } from './noDataFoundTable';
import { NoFilteredDataFoundTable } from './noFilteredDataFound';
import { generateSbAdsColumns } from './sbAdsTableColumns';
import {
  ApiColumnsWithAdLevel,
  CURRENCY_CODE,
  EXT_ADGROUP_ID,
  EXT_CAMPAIGN_ID,
  EXT_CREATIVE_ID,
  EditedRowInfo,
  REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES,
  SB_ADS_TABLE_SEARCH_KEY,
  SbAdsApiColumnGroupIdentifier,
  TableDataAdsManager,
} from './types';
import {
  ALL_AD_LEVELS_FILTER_FILED_MAPPER,
  SB_ADS_API_COLUMN_NAME,
  SB_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  getSearchInputPlaceholder,
  shouldShowSmartCampaigns,
} from './utils';
import { getFilterColumnDefinitons } from './utils/filterDefinitions';

const TABLE_ID = ADS_MANAGER_SB_ADS_TABLE_ID;

export interface TableSbAdsProps {
  readonly apiColumnsWithAdLevel: ApiColumnsWithAdLevel;
  readonly tableMode: DualModes;
  readonly aoApiClient: AOApiClient;
  readonly famApiClient: FAMApiClient;
  readonly exportButtonState: ButtonState;
  readonly adLevelDropDownSelectedValues: '' | AdLevel;
  readonly selectedSalesChannel: FlywheelSalesChannel;
  readonly selectedAdType: AdType;
  readonly selectedSalesChannelId: string;
  readonly activeSliderIndex: number;
  readonly request: () => SbAdsDataRequest;
  readonly dataFetcher: PaginatedDataFetcher<SbAdsDetails>;
  readonly setExportButtonState: React.Dispatch<
    React.SetStateAction<ButtonState>
  >;
  readonly toggleTableMode: () => void;
  readonly selectedSalesChannelName: string;
  readonly onEditSave: () => void;
  readonly onFilterUpdate?: (filters: Filter[]) => void;
  readonly filtersFromBrowserStorage?: Filter[];
  readonly storeFiltersInBrowserStorage?: (filters: Filter[]) => void;
  readonly adTypes: AdType[];
  readonly portfolioNames: Portfolio[];
  readonly merchantCountry: MerchantCountryCode;
  readonly merchantType?: MerchantType;
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly selectedMerchantCountries: Array<number | string> | string;
  readonly merchantCountryNameForCSVDownload?: string;
  readonly allMerchants: MerchantCountry[];
  readonly aiEnabled: boolean;
  readonly merchantsWithAutomationEnabled: string[];
  readonly onColumnSelectionChange?: (selectedColumns: string[]) => void;
  readonly selectedColumns?: string[];
  readonly columnManagerOptions?: ColumnManagerOption[];
  readonly switchLevelController: (adLevel: string) => void;
  readonly campaignDetailFilters?: EqualToFilter[];
}

export const TableSbAds: React.FC<TableSbAdsProps> = ({
  apiColumnsWithAdLevel,
  tableMode,
  aoApiClient,
  exportButtonState,
  adLevelDropDownSelectedValues,
  selectedSalesChannel,
  dataFetcher,
  request,
  setExportButtonState,
  selectedSalesChannelName,
  onFilterUpdate,
  filtersFromBrowserStorage,
  storeFiltersInBrowserStorage,
  selectedAdType,
  adTypes,
  portfolioNames,
  merchantType,
  merchantCountry,
  startDate,
  endDate,
  selectedMerchantCountries,
  allMerchants,
  aiEnabled,
  merchantsWithAutomationEnabled,
  columnManagerOptions,
  onColumnSelectionChange = noop,
  selectedColumns,
  campaignDetailFilters,
}) => {
  const [editedCampaignsInfo, setEditedCampaignsInfo] = useState<
    EditedRowInfo[]
  >([]);

  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef<boolean>(false);
  const firstApiCall = useRef<boolean>(true);

  const merchantContext = useContext<MerchantContextState>(MerchantContext);
  const merchantDetails = merchantContext.merchantDetails;

  const currentTableFilters = useSelector<WithTable<SbAdsDetails>, Filter[]>(
    ({ tableState }) => tableState[TABLE_ID]?.filters || {}
  );

  const updateEditedRecords = useCallback(
    (editedCampaignInfo: EditedRowInfo) => {
      const resultsWithoutEditedCampaign = editedCampaignsInfo.filter(
        (rowInfo) => rowInfo.rowId !== editedCampaignInfo.rowId
      );
      setEditedCampaignsInfo([
        ...resultsWithoutEditedCampaign,
        editedCampaignInfo,
      ]);
    },
    [editedCampaignsInfo]
  );

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  const actionRowV2Props = {
    showSlider: false,
  };

  const { hasTimeElapsed, updateTime } = useTime(
    parseInt(
      process.env.REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES ||
        REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES
    )
  );

  const getVisibleColumns = (): FlywheelTableColumnGroup<
    SbAdsDetails,
    TableDataAdsManager
  >[] => {
    const SB_ADS_COLUMNS = generateSbAdsColumns();
    return SB_ADS_COLUMNS.map((column) => {
      const columnInGroups = column.columnsInGroup.filter((subColumn) =>
        apiColumnsWithAdLevel.columns.includes(subColumn.columnName)
      );
      return {
        ...column,
        columnsInGroup: columnInGroups,
      };
    }).filter((column) => column.columnsInGroup.length > 0);
  };

  const sbAdsDataRequest = request();

  const creativeChannelSettingWithoutMandatoryColumns = [
    ...apiColumnsWithAdLevel.columns.filter(
      (column) =>
        SB_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        SbAdsApiColumnGroupIdentifier.ChannelSettingsFields
    ),
  ];

  let creativeDetailsWithoutMandatoryColumns = [
    EXT_CREATIVE_ID,
    SB_ADS_API_COLUMN_NAME.Name,
    EXT_CAMPAIGN_ID,
    EXT_ADGROUP_ID,
    ...apiColumnsWithAdLevel.columns.filter(
      (column) =>
        column !== SB_ADS_API_COLUMN_NAME.Name &&
        SB_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
          SbAdsApiColumnGroupIdentifier.CreativeDetailsFields
    ),
  ];

  const getExportRequest = () => {
    const { performanceFields } = sbAdsDataRequest;

    return {
      ...sbAdsDataRequest,
      creativeDetailsFields: creativeDetailsWithoutMandatoryColumns,
      channelSettingsFields: creativeChannelSettingWithoutMandatoryColumns,
      performanceFields: performanceFields.filter((column) =>
        apiColumnsWithAdLevel.columns.includes(column)
      ),
    };
  };

  const viewModeRightSideComponents = [
    <Tooltip
      overwrittenTooltipClassnames="w-auto max-w-400"
      theme={Theme.Dark}
      content={intl.formatMessage({
        id: I18nKey.GENERIC_DOWNLOAD_CSV,
      })}
      position={{
        placement: Placement.Bottom,
        alignment: Alignment.Left,
      }}
      tooltipSize={TooltipSize.Small}
      hideArrow
    >
      <ExportAdLevelData<SbAdsDataRequest>
        aoApiClient={aoApiClient}
        buttonState={exportButtonState}
        setButtonState={setExportButtonState}
        salesChannel={selectedSalesChannelName}
        request={getExportRequest()}
        adLevelDropDownSelectedValue={
          adLevelDropDownSelectedValues || undefined
        }
        filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
        startDate={startDate}
        endDate={endDate}
        adType={selectedAdType}
        hideExportText={true}
        additionalFilters={campaignDetailFilters}
      />
    </Tooltip>,
  ];

  const tableActionRowRightSideElements = viewModeRightSideComponents;

  const refreshTable = () =>
    dispatch(tableThunks.refreshTable(TABLE_ID, dataFetcher));

  useEffect(() => {
    if (!firstApiCall.current) {
      refreshTable();
    } else {
      firstApiCall.current = false;
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (ref.current && hasTimeElapsed()) {
      updateTime();
      refreshTable();
    } else {
      ref.current = true;
      if (location.state && location.state.filter) {
        let { filter } =
          (location.state && (location.state as { filter: Filter[] })) || [];
        dispatch(
          tableActions.updateFilters({
            tableId: TABLE_ID,
            filters: filter || currentTableFilters,
            replace: true,
          })
        );
      }
    }
  }, [apiColumnsWithAdLevel.columns]);

  const dataFields = getFilterColumnDefinitons(
    CURRENCY_CODE,
    selectedSalesChannelName as FlywheelSalesChannel,
    first(adTypes),
    adLevelDropDownSelectedValues || undefined,
    merchantDetails,
    false,
    undefined,
    false,
    undefined,
    false,
    false
  );

  let currentFilters = [
    ...currentTableFilters,
    ...(filtersFromBrowserStorage ?? []),
  ];

  const upgradeFiltersInStorage = (filters: Filter[]) => {
    fromNullable(storeFiltersInBrowserStorage)
      .map((fn) => fn(filters))
      .toUndefined();
  };

  const updateFilters = (filters: Filter[]) => {
    const existingSearchFilters: Filter[] = currentFilters.filter(
      (currentFilter) =>
        ALL_AD_LEVELS_FILTER_FILED_MAPPER.find(
          (filter) => filter.alias === currentFilter.field
        )
    );
    const newFilters: Filter[] = [...existingSearchFilters, ...filters];
    dispatch(
      tableActions.updateFilters({
        tableId: TABLE_ID,
        filters: newFilters,
        replace: true,
      })
    );
    upgradeFiltersInStorage(newFilters);
    if (onFilterUpdate) {
      onFilterUpdate(newFilters);
    }
  };

  const [isFilterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);
  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);

  return (
    <>
      <TableActionRow
        className="mt-16"
        leftSideElements={[
          <FlywheelSearchInput
            tableId={TABLE_ID}
            inputSearchColumnName={SB_ADS_TABLE_SEARCH_KEY}
            shouldAddWildcardsToFilterValue
            searchInputSize={SearchInputSize.Medium}
            searchInputPlaceholder={getSearchInputPlaceholder(
              selectedAdType,
              adLevelDropDownSelectedValues as AdLevel,
              intl
            )}
            onFilterUpdate={onFilterUpdate}
            upgradeFiltersInStorage={upgradeFiltersInStorage}
            hasSearchInputWithButton
            searchInputClassName="w-480"
            dataTestId={`${TABLE_ID}_search_input`}
          />,
          <FiltersMenu
            currency={currencyCode as CurrencyCode}
            dataFields={dataFields}
            currentFilters={currentFilters}
            handleSave={updateFilters}
            filterButtonSize={ButtonSize.Medium}
            isOpen={isFilterMenuOpen}
            setOpen={setFilterMenuOpen}
            filterDateFormat={REQUEST_DATE_FORMAT}
            singleDecimalColumns={COLUMNS_WITH_SINGLE_DECIMAL_FILTER}
            dataTestId={`${TABLE_ID}_filters_menu`}
            filtersContainerClass={
              'transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:left-0'
            }
          />,
        ]}
        rightSideElements={[
          <Tooltip
            overwrittenTooltipClassnames="w-auto max-w-400"
            theme={Theme.Dark}
            content={intl.formatMessage({
              id: I18nKey.GENERIC_COLUMNS,
            })}
            tooltipSize={TooltipSize.Small}
            hideArrow
          >
            <ColumnManager
              variant={ColumnManagerVariant.ColumnGroupWithHeader}
              values={selectedColumns ?? []}
              onChange={onColumnSelectionChange}
              options={columnManagerOptions}
              tooltipClass="right-0"
              hideColumnText={true}
              dataTestId={`${TABLE_ID}_column_manager`}
            />
          </Tooltip>,
          ...viewModeRightSideComponents,
        ]}
        fullWidthElementIndex={-1}
      />
      <UpdatedFlywheelTable<SbAdsDetails, TableDataAdsManager>
        tableData={{
          isEditMode: tableMode === DualModes.Edit,
          adTypes,
          salesChannel: selectedSalesChannel,
          portfolioNames,
          merchantType,
          merchantCountry,
          selectedAdType,
          selectedMerchantCountries,
          aoApiClient,
          updateEditedRecords,
          selectedEndDate: endDate,
          allMerchants,
          aiEnabled,
          merchantsWithAutomationEnabled,
          showSmartCampaign: shouldShowSmartCampaigns(optimizelyContext),
        }}
        filtersContainerClass="transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:right-0"
        columns={getVisibleColumns()}
        tableId={TABLE_ID}
        currencyCode={currencyCode || CURRENCY_CODE}
        dataFetcher={dataFetcher}
        footerTotalItemsI18nKey={
          I18nKey.ADS_MANAGER_SB_ADS_TABLE_FOOTER_TOTAL_ITEMS_TEXT
        }
        hasStickyHeader
        hasStickyLeftColumn
        dataFields={dataFields}
        actionRowRightSideComponents={tableActionRowRightSideElements}
        errorComponent={<ErrorTable />}
        yesFilterNoDataDisplayComponent={<NoFilteredDataFoundTable />}
        noFilterNoDataDisplayComponent={<NoDataFoundTable />}
        inputSearchColumnName={SB_ADS_TABLE_SEARCH_KEY}
        onFilterUpdate={onFilterUpdate}
        filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
        filtersFromBrowserStorage={filtersFromBrowserStorage}
        storeFiltersInBrowserStorage={storeFiltersInBrowserStorage}
        filterDateFormat={REQUEST_DATE_FORMAT}
        searchInputSize={SearchInputSize.Medium}
        hasSearchInputWithButton
        searchInputPlaceholder={getSearchInputPlaceholder(
          selectedAdType,
          adLevelDropDownSelectedValues as AdLevel,
          intl
        )}
        selectedColumns={selectedColumns}
        columnManagerOptions={columnManagerOptions}
        onColumnSelectionChange={onColumnSelectionChange}
        dataTestId="table_sb_ads"
        showTableActionRow2={true}
        actionRowV2Props={actionRowV2Props}
        openFilterMenuOnPillClick={() => setFilterMenuOpen(true)}
      />
    </>
  );
};
TableSbAds.displayName = 'TableSbAds';
