import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { FWCookie, PERSISTED_KEYS } from './lib/utilities/fwCookie';
import { segmentReset } from './lib/utilities/segment';

const Logout: React.FC = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    FWCookie.deleteCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID);
    segmentReset();
    setTimeout(
      () => logout({ logoutParams: { returnTo: window.location.origin } }),
      500
    );
  }, [logout]);

  return <></>;
};
Logout.displayName = 'Logout';

export default Logout;
