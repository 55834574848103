import './updatedFlywheelTable.scss';

import { fromNullable } from 'fp-ts/lib/Option';
import includes from 'lodash/includes';
import noop from 'lodash/noop';
import xor from 'lodash/xor';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ButtonSize,
  CheckboxCheckedState,
  ColumnManager,
  ColumnManagerOption,
  ColumnManagerVariant,
  ConditionalType,
  DataFieldFilter,
  FilterDataType,
  FilterRow,
  FiltersMenu,
  HelpProps,
  IconProps,
  RowData,
  SearchInputSize,
  TableActionRow,
  TableActionRowV2,
  TableActionRowV2Props,
  TableHeaderGroupWrapper,
  convertToMap,
  filterOpsToRows,
} from '@teikametrics/tm-design-system';

import {
  Column,
  ColumnGrouping,
  GridTableProps,
} from '../../components/GridTable/types';
import { FilterFieldMapper } from '../../lib/types/AOSharedTypes';
import I18nKey from '../../lib/types/I18nKey';
import { getUniqueFilters } from '../../modules/advertisingOptimization/containers/adsManager/utils';
import { PaginatedTable } from './';
import CollapsableHeader, { ColumnGroupingState } from './collapsableHeader';
import { tableActions } from './ducks';
import { WithTable } from './ducks/types';
import FlywheelSearchInput from './flywheelSearchInput';
import FlywheelTableHeader from './flywheelTableHeader';
import { PaginatedTableProps } from './paginatedTable';
import UpdatedFlywheelTablePaginationRow from './updatedFlywheelTablePaginationRow';
import {
  COLUMNS_WITH_SINGLE_DECIMAL_FILTER,
  Filter,
  FilterOps,
} from '../../lib/types/Filter';

export interface ColumnHeaderData {
  readonly columnName: string;
  readonly overrideColumnName?: string;
  readonly i18nKeyOrLabel: I18nKey | string;
  readonly additionalHeader?: JSX.Element;
  readonly separator?: JSX.Element;
  readonly isSortable?: boolean;
  readonly toolTipContent?: string | JSX.Element;
  readonly showCheckBox?: boolean;
  readonly checked?: CheckboxCheckedState;
  readonly onSelectChange?: React.MouseEventHandler<HTMLElement>;
  readonly columnHeaderClassName?: string;
}

type SlimmedTableColumn<R, T> = Omit<Column<R, {}, {}, T>, 'HeaderCellElement'>;

export type FlywheelTableColumn<R, T = {}> = SlimmedTableColumn<R, T> &
  ColumnHeaderData;

interface ColumnGroupNoHeader<R, T = {}> {
  readonly columnsInGroup: Array<FlywheelTableColumn<R, T>>;
  readonly areColumnsInitiallyHidden?: boolean;
}

interface ColumnGroupWithHeader<R, T = {}> extends ColumnGroupNoHeader<R, T> {
  readonly columnsInGroup: Array<FlywheelTableColumn<R, T>>;
  readonly groupingHeaderI18nKey: I18nKey;
  readonly separator?: JSX.Element;
  readonly toolTipContent?: string | JSX.Element;
  readonly help?: HelpProps;
  readonly icon?: IconProps;
}

interface ColumnGroupWithCollapsableHeader<R, T = {}>
  extends ColumnGroupWithHeader<R, T> {
  readonly columnsToHideOnToggle: string[];
}

export type FlywheelTableColumnGroup<R, T = {}> =
  | ColumnGroupNoHeader<R, T>
  | ColumnGroupWithHeader<R, T>
  | ColumnGroupWithCollapsableHeader<R, T>;

const isCollapsableColumnGroup = <R, T>(
  value: FlywheelTableColumnGroup<R, T>
): value is ColumnGroupWithCollapsableHeader<R, T> =>
  'groupingHeaderI18nKey' in value && 'columnsToHideOnToggle' in value;

const isColumnGroupWithHeader = <R, T>(
  value: FlywheelTableColumnGroup<R, T>
): value is ColumnGroupWithHeader<R, T> => 'groupingHeaderI18nKey' in value;

function isColumnGrouping<R, T>(
  columnOrColumnGrouping:
    | FlywheelTableColumnGroup<R, T>
    | FlywheelTableColumn<R, T>
): columnOrColumnGrouping is FlywheelTableColumnGroup<R, T> {
  return 'columnsInGroup' in columnOrColumnGrouping;
}

function isArrayOfColumnGroups<R, T>(
  columnsOrColumnGroupings:
    | Array<FlywheelTableColumnGroup<R, T>>
    | Array<FlywheelTableColumn<R, T>>
): columnsOrColumnGroupings is Array<FlywheelTableColumnGroup<R, T>> {
  return (
    columnsOrColumnGroupings.length === 0 ||
    isColumnGrouping(columnsOrColumnGroupings[0])
  );
}

const createHeaderElement =
  (data: ColumnHeaderData, tableId: string): React.FC =>
  () =>
    (
      <>
        <FlywheelTableHeader
          tableId={tableId}
          columnName={data.columnName}
          i18nKeyOrLabel={data.i18nKeyOrLabel}
          isSortable={data.isSortable === true}
          separator={data.separator}
          toolTipContent={data.toolTipContent}
          checked={data.checked}
          onSelectChange={data.onSelectChange}
          showCheckBox={data.showCheckBox}
          columnHeaderClassName={data.columnHeaderClassName}
        />
        {data.additionalHeader}
      </>
    );

const getColumnGroupingState = (
  params: Readonly<{
    currentlyHiddenColumns: string[];
    columnsToHideOnToggle: string[];
  }>
): ColumnGroupingState => {
  const { columnsToHideOnToggle, currentlyHiddenColumns } = params;
  const columnGroupingHasColumnsHidden = columnsToHideOnToggle.some((column) =>
    includes(currentlyHiddenColumns, column)
  );

  return columnGroupingHasColumnsHidden
    ? ColumnGroupingState.Contracted
    : ColumnGroupingState.Expanded;
};

const getTableColumn =
  (tableId: string) =>
  <R extends {}, T extends {}>(c: FlywheelTableColumn<R, T>) => ({
    ...c,
    HeaderCellElement: createHeaderElement(
      {
        columnName: c.overrideColumnName ?? c.columnName,
        i18nKeyOrLabel: c.i18nKeyOrLabel,
        additionalHeader: c.additionalHeader,
        isSortable: c.isSortable,
        separator: c.separator,
        toolTipContent: c.toolTipContent,
        checked: c.checked,
        onSelectChange: c.onSelectChange,
        showCheckBox: c.showCheckBox,
        columnHeaderClassName: c.columnHeaderClassName,
      },
      tableId
    ),
  });

function convertColumns<R extends {} = {}, T extends {} = {}>(
  params: Readonly<{
    tableId: string;
    hiddenColumns: string[];
    onColumnGroupToggle: (columns: string[]) => () => void;
    columns: TheNewTableProps<R, T>['columns'];
  }>
): GridTableProps<R, void, void, {}, {}, T>['columns'] {
  const { tableId, hiddenColumns, onColumnGroupToggle, columns } = params;

  if (isArrayOfColumnGroups(columns)) {
    return columns.map<ColumnGrouping<R, {}, {}, T>>((cg) => {
      let groupingHeaderComponent;

      if (isCollapsableColumnGroup(cg)) {
        groupingHeaderComponent = (
          <CollapsableHeader
            i18nKey={cg.groupingHeaderI18nKey}
            onToggle={onColumnGroupToggle(cg.columnsToHideOnToggle)}
            separator={cg.separator}
            state={getColumnGroupingState({
              columnsToHideOnToggle: cg.columnsToHideOnToggle,
              currentlyHiddenColumns: hiddenColumns,
            })}
            toolTipContent={cg.toolTipContent}
          />
        );
      } else if (isColumnGroupWithHeader(cg)) {
        groupingHeaderComponent = (
          <TableHeaderGroupWrapper
            title={
              <FormattedMessage
                id={cg.groupingHeaderI18nKey}
                values={{ separator: cg.separator }}
              />
            }
            icon={cg.icon}
            help={getTableHelp(cg.help)}
          />
        );
      }

      return {
        columnsInGroup: cg.columnsInGroup
          .filter((c) => !includes(hiddenColumns, c.columnName))
          .map<Column<R, {}, {}, T>>(getTableColumn(tableId)),
        groupingHeaderComponent,
      };
    });
  } else {
    return columns.map(getTableColumn(tableId));
  }
}

const getTableHelp = (help?: HelpProps) => {
  if (help) {
    return {
      title: <FormattedMessage id={help?.title as string} />,
      description: <FormattedMessage id={help?.description as string} />,
      link: help?.link && {
        text: <FormattedMessage id={help?.link?.text as string} />,
        url: help?.link?.url,
      },
    };
  }
};

const getUpdatedFilterList = (filter: Filter, field: string, row: RowData) => {
  if (
    field === filter.field &&
    (filter.op === FilterOps.in || filter.op === FilterOps.notIn)
  ) {
    const value = filter.value.filter(
      (val: string) => val !== row.singleSelectedValue!.value
    );
    return {
      ...filter,
      value,
    };
  }

  return filter;
};

const isInOrNotInFilter = (filter: Filter, field: string) => {
  return (
    ((filter.op === FilterOps.in || filter.op === FilterOps.notIn) &&
      filter.value.length > 0) ||
    (filter.op !== FilterOps.in &&
      filter.op !== FilterOps.notIn &&
      filter.field !== field)
  );
};

interface TheNewTableProps<R, T> {
  readonly tableId: PaginatedTableProps<R, void, void, {}, {}, T>['tableId'];
  readonly dataFetcher: PaginatedTableProps<
    R,
    void,
    void,
    {},
    {},
    T
  >['dataFetcher'];
  readonly columns:
    | Array<FlywheelTableColumn<R, T>>
    | Array<FlywheelTableColumnGroup<R, T>>;
  readonly currencyCode: string;
  readonly tableData: T;
  readonly dataFields?: DataFieldFilter[];
  readonly yesFilterNoDataDisplayComponent?: JSX.Element;
  readonly noFilterNoDataDisplayComponent?: JSX.Element;
  readonly areFilterPillsClearable?: boolean;
  readonly actionRowRightSideComponents?: JSX.Element[];
  readonly footerTotalItemsI18nKey: I18nKey;
  readonly hasStickyHeader?: boolean;
  readonly hasStickyLeftColumn?: boolean;
  readonly dataStatusComponent?: JSX.Element;
  readonly dualModeSliderComponent?: JSX.Element;
  readonly treUploadComponent?: JSX.Element;
  readonly createKeywordsComponent?: JSX.Element;
  readonly errorComponent?: JSX.Element;
  readonly inputSearchColumnName?: string;
  readonly shouldAddWildcardsToFilterValue?: boolean;
  readonly searchInputSize?: SearchInputSize;
  readonly searchInputPlaceholder?: string;
  readonly roundedTop?: boolean;
  readonly hasSearchInput?: boolean;
  readonly hasSearchInputWithButton?: boolean;
  readonly onFilterUpdate?: (filters: Filter[]) => void;
  readonly storeFiltersInBrowserStorage?: (filters: Filter[]) => void;
  readonly filterFieldMapper?: FilterFieldMapper[];
  readonly filtersFromBrowserStorage?: Filter[];
  readonly filterDateFormat?: string;
  readonly onColumnSelectionChange?: (selectedColumns: string[]) => void;
  readonly selectedColumns?: string[];
  readonly columnManagerOptions?: ColumnManagerOption[];
  readonly dataTestId?: string;
  readonly filtersContainerClass: string;
  readonly showTableActionRow2?: boolean;
  readonly actionRowV2Props?: TableActionRowV2Props;
  readonly openFilterMenuOnPillClick?: () => void;
  readonly callBackOnFilterUpdate?: () => void;
}

function UpdatedFlywheelTable<
  R extends Record<string, unknown>,
  T extends {} = {}
>(props: TheNewTableProps<R, T>) {
  const {
    dataFields = [],
    areFilterPillsClearable = true,
    inputSearchColumnName = 'name_or_title',
    roundedTop = true,
    hasSearchInput = true,
    filterFieldMapper = [],
    filtersFromBrowserStorage = [],
    storeFiltersInBrowserStorage,
    filterDateFormat,
    onColumnSelectionChange = noop,
    selectedColumns = [],
    hasSearchInputWithButton = false,
    dataTestId,
    filtersContainerClass = 'right-0',
    showTableActionRow2 = false,
    actionRowV2Props,
    openFilterMenuOnPillClick,
    callBackOnFilterUpdate,
  } = props;

  const upgradeFiltersInStorage = (filters: Filter[]) => {
    fromNullable(storeFiltersInBrowserStorage)
      .map((fn) => fn(filters))
      .toUndefined();
  };

  const initiallyHiddenColumns = isArrayOfColumnGroups(props.columns)
    ? props.columns.reduce(
        (acc, cg) =>
          cg.areColumnsInitiallyHidden && isCollapsableColumnGroup(cg)
            ? [...acc, ...cg.columnsToHideOnToggle]
            : acc,
        new Array<string>()
      )
    : [];
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    initiallyHiddenColumns
  );
  const [isFilterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);

  const onColumnGroupToggle = (toggledColumns: string[]) => () => {
    setHiddenColumns((currentlyHiddenColumns) =>
      xor(currentlyHiddenColumns, toggledColumns)
    );
  };

  const columns = convertColumns<R, T>({
    tableId: props.tableId,
    columns: props.columns,
    hiddenColumns,
    onColumnGroupToggle,
  });

  const dispatch = useDispatch();

  const currentTableFilters: Filter[] = useSelector<
    WithTable<T, Filter>,
    Filter[]
  >((state) => {
    return state.tableState[props.tableId].filters;
  });

  let currentFilters = [...currentTableFilters, ...filtersFromBrowserStorage];

  currentFilters = getUniqueFilters(currentFilters);

  const updateFilters = (filters: Filter[]) => {
    const existingSearchFilters: Filter[] = currentFilters.filter(
      (currentFilter) =>
        filterFieldMapper.find((filter) => filter.alias === currentFilter.field)
    );
    const newFilters: Filter[] = [...existingSearchFilters, ...filters];
    dispatch(
      tableActions.updateFilters({
        tableId: props.tableId,
        filters: newFilters,
        replace: true,
      })
    );
    upgradeFiltersInStorage(newFilters);
    if (props.onFilterUpdate) {
      props.onFilterUpdate(newFilters);
    }
  };

  const clearAllFilters = () => {
    const existingSearchFilters: Filter[] = currentFilters.filter(
      (currentFilter) =>
        filterFieldMapper.find((filter) => filter.alias === currentFilter.field)
    );
    dispatch(
      tableActions.updateFilters({
        tableId: props.tableId,
        filters: existingSearchFilters,
        replace: true,
      })
    );
    upgradeFiltersInStorage(existingSearchFilters);

    if (props.onFilterUpdate) {
      props.onFilterUpdate(existingSearchFilters);
    }
  };

  const onPillClose = (row: RowData) => {
    const field = row.dataField?.field;

    /**
     * If FilterDataType a `singleSelect` and `Is` OR `IsNot` Filter being removed
     * Then remove only that paricular filter-value from the currentFilters
     */
    if (
      field &&
      (row.conditional === ConditionalType.Is ||
        row.conditional === ConditionalType.IsNot) &&
      row.dataField?.dataType === FilterDataType.SingleSelect
    ) {
      dispatch(
        tableActions.clearSingleFilterOfMultiSelctionFilters({
          field,
          value: row.singleSelectedValue!.value,
          tableId: props.tableId,
        })
      );

      if (props.onFilterUpdate) {
        const newFilters = currentFilters
          .map((filter: Filter) => getUpdatedFilterList(filter, field, row))
          .filter((filter) => isInOrNotInFilter(filter, field));
        props.onFilterUpdate(newFilters);
        upgradeFiltersInStorage(newFilters);
      }
    } else {
      const updatedFilters = currentFilters.filter(
        (filter) => filter.field !== field
      );
      dispatch(
        tableActions.updateFilters({
          tableId: props.tableId,
          filters: updatedFilters,
          replace: true,
        })
      );

      if (props.onFilterUpdate) {
        props.onFilterUpdate(updatedFilters);
      }
      upgradeFiltersInStorage(updatedFilters);
    }
  };

  const onPillClick = () => {
    setFilterMenuOpen(true);
    !!openFilterMenuOnPillClick && openFilterMenuOnPillClick();
  };

  const dataFieldMap = convertToMap(dataFields);
  const filterRows = filterOpsToRows(dataFieldMap, true)(currentFilters);

  const tableActionRowFilterComponent = dataFields.length > 0 && (
    <FiltersMenu
      currency={props.currencyCode}
      dataFields={dataFields}
      currentFilters={currentFilters}
      handleSave={updateFilters}
      filterButtonSize={
        hasSearchInputWithButton ? ButtonSize.Medium : ButtonSize.Large
      }
      isOpen={isFilterMenuOpen}
      setOpen={setFilterMenuOpen}
      filterDateFormat={filterDateFormat}
      singleDecimalColumns={COLUMNS_WITH_SINGLE_DECIMAL_FILTER}
      dataTestId={`${dataTestId}_filters_menu`}
      filtersContainerClass={filtersContainerClass}
    />
  );

  const columnManagerComponent = (
    <ColumnManager
      variant={ColumnManagerVariant.ColumnGroupWithHeader}
      values={selectedColumns}
      onChange={onColumnSelectionChange}
      options={props.columnManagerOptions}
      dataTestId={`${dataTestId}_column_manager`}
    />
  );

  const tableActionRowLeftSideElements: JSX.Element[] = [];

  /**
   * Preference of tableActionRow Left side components are as (if any)
   * 1. DataStatus
   * 2. Slider Group
   * 3. Search Input
   * 4. Filter (if slidergroup is present)
   */
  props.dataStatusComponent &&
    tableActionRowLeftSideElements.push(props.dataStatusComponent);

  props.dualModeSliderComponent &&
    tableActionRowLeftSideElements.push(props.dualModeSliderComponent);
  const fullWidthElementIndex =
    hasSearchInputWithButton && hasSearchInput
      ? tableActionRowLeftSideElements.length
      : -1;
  hasSearchInput &&
    tableActionRowLeftSideElements.push(
      <FlywheelSearchInput
        tableId={props.tableId}
        inputSearchColumnName={inputSearchColumnName}
        shouldAddWildcardsToFilterValue={props.shouldAddWildcardsToFilterValue}
        searchInputSize={props.searchInputSize}
        searchInputPlaceholder={props.searchInputPlaceholder}
        onFilterUpdate={props.onFilterUpdate}
        upgradeFiltersInStorage={upgradeFiltersInStorage}
        hasSearchInputWithButton={hasSearchInputWithButton}
        dataTestId={props.dataTestId}
      />
    );

  props.createKeywordsComponent &&
    tableActionRowLeftSideElements.push(props.createKeywordsComponent);

  props.treUploadComponent &&
    tableActionRowLeftSideElements.push(props.treUploadComponent);

  tableActionRowFilterComponent &&
    tableActionRowLeftSideElements.push(tableActionRowFilterComponent);

  props.columnManagerOptions?.length &&
    tableActionRowLeftSideElements.push(columnManagerComponent);

  return (
    <div
      className="updated-table pt-0"
      data-test-id={`${props.dataTestId}_updated_table`}
    >
      {showTableActionRow2 ? (
        <TableActionRowV2
          showSlider
          {...actionRowV2Props}
          className="border-solid border-r border-t-0 border-l border-b-0 border-grey-200 bg-pruple-400"
          roundedTop
        />
      ) : (
        <TableActionRow
          roundedTop={roundedTop}
          leftSideElements={tableActionRowLeftSideElements}
          rightSideElements={props.actionRowRightSideComponents}
          fullWidthElementIndex={fullWidthElementIndex}
        />
      )}
      <FilterRow
        filterRows={filterRows}
        onPillClose={areFilterPillsClearable ? onPillClose : undefined}
        clearAllFilters={clearAllFilters}
        onPillClick={onPillClick}
        currencyCode={props.currencyCode}
        customRowClassname="border-b-0"
        filterDateFormat={filterDateFormat}
        dataTestId={props.dataTestId}
      />
      <div className="table-wrapper border-solid border-l border-t border-r border-grey-200">
        <PaginatedTable<R, void, void, {}, {}, T>
          columns={columns}
          tableData={props.tableData}
          tableId={props.tableId}
          dataFetcher={props.dataFetcher}
          hasStickyHeader={props.hasStickyHeader}
          hasStickyLeftColumn={props.hasStickyLeftColumn}
          yesFilterNoDataDisplayComponent={
            props.yesFilterNoDataDisplayComponent
          }
          noFilterNoDataDisplayComponent={props.noFilterNoDataDisplayComponent}
          errorComponent={props.errorComponent}
          callBackOnFilterUpdate={callBackOnFilterUpdate}
        />
      </div>
      <UpdatedFlywheelTablePaginationRow
        tableId={props.tableId}
        totalItemsI18nKey={props.footerTotalItemsI18nKey}
        dataTestId={props.dataTestId}
      />
    </div>
  );
}
UpdatedFlywheelTable.displayName = 'UpdatedFlywheelTable';
UpdatedFlywheelTable.defaultProps = {
  hasStickyHeader: false,
  hasStickyLeftColumn: false,
};

export default UpdatedFlywheelTable;
