import React from 'react';

import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { TargetsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TARGETS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<TargetsDetails> = (targetsDetails) => {
  return makeTextColumn<TargetsDetails>(
    ({ targetDetails: { targetingExpressionType } }) =>
      targetingExpressionType ? targetingExpressionType : undefined
  )(targetsDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const expressionTypeColumn: FlywheelTableColumn<TargetsDetails> = {
  columnName: TARGETS_API_COLUMN_NAME.ExpressionType,
  isSortable: true,
  RowCellElement,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_EXPRESSION_TYPE,
  gridColumnWidth: '140px',
};

export const expressionTypeFilter = createMultiSelectDataFieldFilter(
  TARGETS_API_COLUMN_NAME.ExpressionType,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_EXPRESSION_TYPE,
  [
    SelectFilterOption.ExpressionTypeAsinAgeRangeSameAs,
    SelectFilterOption.ExpressionTypeAsinBrandSameAs,
    SelectFilterOption.ExpressionTypeAsinCategorySameAs,
    SelectFilterOption.ExpressionTypeAsinGenreSameAs,
    SelectFilterOption.ExpressionTypeAsinIsPrimeShippingAvailable,
    SelectFilterOption.ExperssionTypeAsinPriceBetween,
    SelectFilterOption.ExpressionTypeAsinPriceGreaterThan,
    SelectFilterOption.ExpressionTypeAsinPriceLessThan,
    SelectFilterOption.ExpressionTypeAsinReviewRatingBetween,
    SelectFilterOption.ExpressionTypeAsinReviewRatingGreaterThan,
    SelectFilterOption.ExpressionTypeAsinReviewRatingLessThan,
    SelectFilterOption.ExpressionTypeAsinSameAs,
  ]
);
