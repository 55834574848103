import {
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makePercentColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { PROFILES_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = (props) =>
  makePercentColumn<ProfileApiDataDetails>(
    ({ profilePerformance: { acosDirect } }) => {
      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        undefined,
        acosDirect
      );

      return performanceMetricValue?.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const acosDirectColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.DirectACOS,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_DIRECT_ACOS,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const acosDirectFilterForProfiles = createPercentDataFieldFilter(
  PROFILES_API_COLUMN_NAME.DirectACOS,
  I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_DIRECT_ACOS,
  isValidNumber()
);
