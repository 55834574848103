import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ButtonVariant } from '@teikametrics/tm-design-system';

import { getCurrentAccountFromContext } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { createFAMApiClient } from '../../../../lib/clients/FAMApiClient';
import {
  FAMConnectionStatus,
  FlywheelSalesChannel,
  MerchantType,
  WalmartSellerProductsRequestBody,
  WalmartSellerType,
} from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { ConnectionInitialModal } from '../../components/AmazonModalSteps/ConnectInitialModal';
import { ConnectionSuccessModal } from '../../components/AmazonModalSteps/connectionSuccess';
import { SalesChannel } from '../../components/connectSalesChannelModal';
import { AuthorizeWalmartModal } from '../../components/WalmartModalSteps/AuthorizeWalmartModal';
import { WalmartProductSyncFormForSupplierDetails } from '../../components/WalmartModalSteps/productDataSyncFormForSupplierDetails';
import { WalmartProductDataSyncForm } from '../../components/WalmartModalSteps/productDataSyncFormV2';
import { ProductsDataConnectionWarningPopup } from '../ProductsDataConnectionWarningPopup';
import { ConnectAdsDataWalmartModal } from './ConnectAdsData';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';
import { OptimizelyFlags } from '../../../../lib/types/OptimizelyFlags';
import { AuthorizeWalmartModalOld } from './AuthorizeWalmartModalOld';
import { ConnectWalmartAdsData } from './ConnectAdsData/content';
import uuid from 'uuid';
import { generateRandomString } from '../../../../lib/types/fixtures/Utilities.fixture';
import { openSignInWindow } from '../../../../lib/utilities/popupWindow';

const WALMART_CLIENT_ID = process.env.REACT_APP_WALMART_CLIENT_ID;
const PUBLIC_URL = process.env.PUBLIC_URL;
const MAX_RETRY_COUNT = 60;
const MERCHANT_CONNECT_INTERVAL = 1000;

export interface WalmartFlashProps {
  readonly onClose: (openInitialModal?: boolean) => void;
  readonly initialScreen?: WALMART_FLASH_MODAL_SCREENS;
  readonly salesChannel?: SalesChannel;
  readonly refreshTable?: () => void;
  readonly defaultMerchantType?: MerchantType;
  readonly advertisingId?: string;
  readonly extMerchantId?: string;
}

export enum WALMART_FLASH_MODAL_SCREENS {
  WMTAuthorizationScreen = 'WMTAuthorizationScreen',
  WMTInitialConnectScreen = 'WMTInitialConnectScreen',
  WMTConnectAdsDataScreen = 'WMTConnectAdsDataScreen',
  WMTAuthorizationSuccessScreen = 'WMTAuthorizationSuccessScreen',
  WMTSelectMerchantTypeScreen = 'WMTSelectMerchantTypeScreen',
  WMTProductsFormScreen = 'WMTProductsFormScreen',
  WMTProductsSuccessScreen = 'WMTProductsSuccessScreen',
}

const DATA_BOXES_WALMART_ADS_SUCCESS = [
  {
    isItemComplated: true,
    primaryText:
      I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_ADS_PRIMARY_TEXT,
    secondaryText:
      I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_ADS_SECONDARY_TEXT,
  },
  {
    isItemComplated: false,
    primaryText:
      I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_PRODUCTS_PRIMARY_TEXT,
    secondaryText:
      I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_PRODUCTS_SECONDARY_TEXT,
  },
];

const DATA_BOXES_WALMART_ADS_SUCCESS_OLD = [
  {
    isItemComplated: true,
    primaryText:
      I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_ADS_PRIMARY_TEXT_OLD,
    secondaryText:
      I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_ADS_SECONDARY_TEXT_OLD,
  },
  {
    isItemComplated: false,
    primaryText:
      I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_PRODUCTS_PRIMARY_TEXT,
    secondaryText:
      I18nKey.FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_PRODUCTS_SECONDARY_TEXT,
  },
];

const DATA_BOXES_WALMART_PRODUCTS_SUCCESS = [
  {
    isItemComplated: true,
    primaryText: I18nKey.SALES_CHANNELS_TITLE_PENDING_DATA_WALMART,
    secondaryText: I18nKey.SALES_CHANNELS_WALMART_SUB_TITLE_DATA_24HOUR,
  },
  {
    isItemComplated: true,
    primaryText: I18nKey.SALES_CHANNELS_AIREADY_TITLE_DATA_24HOUR,
    secondaryText: I18nKey.SALES_CHANNELS_AIREADY_SUB_TITLE_DATA_24HOUR,
  },
];

export enum WalmartOAuthStatus {
  NOT_STARTED,
  IN_PROGRESS,
  SUCCESS,
  FAILED,
}

export const WalmartFlashScreens: React.FC<WalmartFlashProps> = ({
  onClose,
  initialScreen,
  salesChannel,
  refreshTable,
  defaultMerchantType,
  advertisingId,
  extMerchantId,
}) => {
  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);
  const isNewWalmartAdvertisingConnection =
    optimizelyContext.featureFlags[
      OptimizelyFlags.WalmartAdvertisingConnection
    ];
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;

  const intl = useIntl();
  const famClient = createFAMApiClient(userContext.userInfo.idToken!);
  const stateToken = uuid();

  const [currentModal, setCurrentModal] =
    useState<WALMART_FLASH_MODAL_SCREENS | null>(initialScreen || null);
  const [selectedChannel, setSelectedChannel] = useState<SalesChannel | null>(
    salesChannel || null
  );
  const [walmartMerchantType, setWalmartMerchantType] = useState<MerchantType>(
    defaultMerchantType || MerchantType.Seller
  );

  const [
    showMissingMerchantConnectionsWarningPopup,
    setShowMissingMerchantConnectionsWarningPopup,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (salesChannel) setSelectedChannel(salesChannel);
  }, [salesChannel]);

  const [walmartSellerId, setWalmartSellerId] = useState<string>(
    extMerchantId ?? ''
  );
  const [wmtMarketplaceOAuthCode, setWmtMarketplaceOAuthCode] = useState<
    string | null
  >(null);

  const [walmartClientId, setWalmartClientId] = useState<string>('');
  const [walmartClientSecret, setWalmartClientSecret] = useState<string>('');
  const [walmartSellerType, setWalmartSellerType] = useState<WalmartSellerType>(
    WalmartSellerType.DSV
  );
  const [walmartDistibutorIds, setWalmartDistibutorIds] = useState<string[]>([
    '',
  ]);

  const walmartAdsData: ConnectWalmartAdsData = {
    advertiserId: advertisingId ?? '',
    sellerId: extMerchantId ?? '',
    supplierId: extMerchantId ?? '',
  };

  const [walmartConnectAdsData, setWalmartConnectAdsData] =
    useState<ConnectWalmartAdsData>(walmartAdsData);

  const [walmartOAuthStatus, setWalmartOAuthStatus] =
    useState<WalmartOAuthStatus>(WalmartOAuthStatus.NOT_STARTED);

  const [walmartAdsErrorMessage, setWalmartAdsErrorMessage] =
    useState<string>('');

  const [intervalId, setIntervalId] = useState<number | undefined>(undefined);

  useEffect(() => {
    setWalmartDistibutorIds(['']);
  }, [walmartSellerType]);

  const onMerchantTypeChange = (type: MerchantType) => {
    setWalmartMerchantType(type);
  };

  const onSellerIdChange = (id: string) => {
    setWalmartSellerId(id);
  };
  const onClientIdChange = (id: string) => {
    setWalmartClientId(id);
  };
  const onClientSecretChange = (id: string) => {
    setWalmartClientSecret(id);
  };

  const onAddNewDistributorID = () => {
    const currentDistributorIds = [...walmartDistibutorIds];
    currentDistributorIds.push('');
    setWalmartDistibutorIds(currentDistributorIds);
  };

  const onDistributorIDChange = (id: string, index: number) => {
    let currentDistributorIds = [...walmartDistibutorIds];
    currentDistributorIds[index] = id;
    setWalmartDistibutorIds(currentDistributorIds);
  };

  const onRemoveDistributorID = (index: number) => {
    let currentDistributorIds = [...walmartDistibutorIds];
    currentDistributorIds.splice(index, 1);
    setWalmartDistibutorIds(currentDistributorIds);
  };

  const onClearAllProductsChanges = () => {
    setWalmartSellerId('');
    setWalmartClientId('');
    setWmtMarketplaceOAuthCode(null);
    setWalmartClientSecret('');
    setWalmartDistibutorIds(['']);
  };

  const closeModal = () => {
    setCurrentModal(WALMART_FLASH_MODAL_SCREENS.WMTInitialConnectScreen);
    onClose();
  };

  const generateWMTOAuthUrl = () => {
    const nonce = generateRandomString(9, 12);
    return `https://login.account.wal-mart.com/authorize?redirectUri=${PUBLIC_URL}/auth/wmt-products/callback&nonce=${nonce}&clientType=seller&clientId=${WALMART_CLIENT_ID}&state=${stateToken}&responseType=code`;
  };

  const receiveMessage = (event: MessageEvent) => {
    if (event.origin !== PUBLIC_URL) {
      return;
    }

    const params = new URLSearchParams(event.data);
    if (params.get('state') === stateToken) {
      window.removeEventListener('message', receiveMessage);
      setWmtMarketplaceOAuthCode(params.get('code'));
    }
  };

  const connectMarketplaceData = async () => {
    setWalmartOAuthStatus(WalmartOAuthStatus.IN_PROGRESS);
    openSignInWindow(
      encodeURI(generateWMTOAuthUrl()),
      'wmtMarketplaceLoginWindow',
      receiveMessage
    );
  };

  useEffect(() => {
    if (walmartOAuthStatus !== WalmartOAuthStatus.IN_PROGRESS) {
      window.clearInterval(intervalId);
      setIntervalId(undefined);
    }
  }, [walmartOAuthStatus]);

  const checkConectionStatus = (correlationId: string) => {
    let retryCount = 0;
    const id = window.setInterval(() => {
      famClient.merchantConnect(accountId, correlationId).then((res) => {
        if (res.status === FAMConnectionStatus.Active) {
          setWalmartOAuthStatus(WalmartOAuthStatus.SUCCESS);
          setCurrentModal(WALMART_FLASH_MODAL_SCREENS.WMTProductsSuccessScreen);
        } else if (res.status === FAMConnectionStatus.Failed) {
          setWalmartOAuthStatus(WalmartOAuthStatus.FAILED);
        }
      });
      if (retryCount >= MAX_RETRY_COUNT) {
        setWalmartOAuthStatus(WalmartOAuthStatus.FAILED);
      }
      retryCount++;
    }, MERCHANT_CONNECT_INTERVAL);
    setIntervalId(id);
  };

  const sendOauthCode = async () => {
    if (
      !wmtMarketplaceOAuthCode ||
      !walmartConnectAdsData.sellerId ||
      !walmartConnectAdsData.advertiserId
    ) {
      setWalmartOAuthStatus(WalmartOAuthStatus.FAILED);
      return;
    }
    try {
      const requestBody: WalmartSellerProductsRequestBody = {
        oauthCode: wmtMarketplaceOAuthCode,
        sellerId: walmartConnectAdsData.sellerId.trim(),
        extAdvertiserId: walmartConnectAdsData.advertiserId.trim(),
        redirectUri: `${PUBLIC_URL}/auth/wmt-products/callback`,
      };

      const res = await famClient.connectWalmartSellerProducts(
        accountId,
        requestBody
      );
      const { correlationId } = res;
      if (correlationId) {
        checkConectionStatus(correlationId);
      } else {
        setWalmartOAuthStatus(WalmartOAuthStatus.FAILED);
      }
    } catch {
      setWalmartOAuthStatus(WalmartOAuthStatus.FAILED);
    }
  };

  useEffect(() => {
    if (wmtMarketplaceOAuthCode) {
      sendOauthCode();
    }
  }, [wmtMarketplaceOAuthCode]);

  const checkAdsConectionStatus = (correlationId: string) => {
    let retryCount = 0;
    const id = window.setInterval(() => {
      famClient.merchantConnect(accountId, correlationId).then((res) => {
        if (
          res.status === FAMConnectionStatus.Active &&
          res.merchantCountries?.length > 0
        ) {
          const unavailableAccount = res.merchantCountries.filter((item) => {
            return item.owningAccount && item.owningAccount?.id !== accountId;
          });
          if (unavailableAccount.length > 0) {
            setWalmartOAuthStatus(WalmartOAuthStatus.FAILED);
            setWalmartAdsErrorMessage(
              intl.formatMessage({
                id: I18nKey.FLASH_MODALS_WALMART_AUTHORIZE_ERROR_MESSAGE_MERCHANT_OWNED_ANOTHER_ACCOUNT,
              })
            );
          } else {
            setWalmartOAuthStatus(WalmartOAuthStatus.SUCCESS);
            setCurrentModal(
              WALMART_FLASH_MODAL_SCREENS.WMTAuthorizationSuccessScreen
            );
          }
        } else if (res.status === FAMConnectionStatus.Failed) {
          setWalmartOAuthStatus(WalmartOAuthStatus.FAILED);
          // set error message
          if (
            !res.errorMessage ||
            res.errorMessage?.toLowerCase() === 'undefined error'
          ) {
            setWalmartAdsErrorMessage('');
          } else {
            setWalmartAdsErrorMessage(res.errorMessage);
          }
        }
      });
      if (retryCount >= MAX_RETRY_COUNT) {
        setWalmartOAuthStatus(WalmartOAuthStatus.FAILED);
      }
      retryCount++;
    }, MERCHANT_CONNECT_INTERVAL);
    setIntervalId(id);
  };

  const wmtConnectAdsDataScreenAuthorize =
    () => (correlationId?: string, connectAdsData?: ConnectWalmartAdsData) => {
      if (connectAdsData && correlationId) {
        setWalmartOAuthStatus(WalmartOAuthStatus.IN_PROGRESS);
        setWalmartConnectAdsData(connectAdsData);
        if (connectAdsData.sellerId) {
          setWalmartMerchantType(MerchantType.Seller);
        } else if (connectAdsData.supplierId) {
          setWalmartMerchantType(MerchantType.Vendor);
        }
        checkAdsConectionStatus(correlationId);
      }
    };

  const getDisplayScreen = (
    currentModal: WALMART_FLASH_MODAL_SCREENS | null
  ) => {
    switch (currentModal) {
      case WALMART_FLASH_MODAL_SCREENS.WMTInitialConnectScreen:
        return (
          <ConnectionInitialModal
            channel={FlywheelSalesChannel.Walmart}
            goBack={() => {
              setCurrentModal(null);
              onClose(true);
            }}
            next={() =>
              setCurrentModal(
                WALMART_FLASH_MODAL_SCREENS.WMTAuthorizationScreen
              )
            }
            closeModal={closeModal}
          />
        );
      case WALMART_FLASH_MODAL_SCREENS.WMTAuthorizationScreen:
        return isNewWalmartAdvertisingConnection ? (
          <AuthorizeWalmartModal
            famClient={famClient}
            accountId={accountId}
            goBack={() =>
              setCurrentModal(
                WALMART_FLASH_MODAL_SCREENS.WMTInitialConnectScreen
              )
            }
            authorize={() =>
              setCurrentModal(
                WALMART_FLASH_MODAL_SCREENS.WMTConnectAdsDataScreen
              )
            }
            closeModal={closeModal}
          />
        ) : (
          <AuthorizeWalmartModalOld
            famClient={famClient}
            accountId={accountId}
            goBack={() =>
              setCurrentModal(
                WALMART_FLASH_MODAL_SCREENS.WMTInitialConnectScreen
              )
            }
            authorize={() =>
              setCurrentModal(
                WALMART_FLASH_MODAL_SCREENS.WMTAuthorizationSuccessScreen
              )
            }
            closeModal={closeModal}
          />
        );
      case WALMART_FLASH_MODAL_SCREENS.WMTConnectAdsDataScreen:
        return (
          <ConnectAdsDataWalmartModal
            famClient={famClient}
            accountId={accountId}
            goBack={() => {
              setCurrentModal(
                WALMART_FLASH_MODAL_SCREENS.WMTAuthorizationScreen
              );
              setWalmartOAuthStatus(WalmartOAuthStatus.NOT_STARTED);
              setWalmartAdsErrorMessage('');
            }}
            authorize={wmtConnectAdsDataScreenAuthorize()}
            closeModal={closeModal}
            advertisingId={advertisingId}
            extMerchantId={extMerchantId}
            walmartMerchantType={walmartMerchantType}
            walmartOAuthStatus={walmartOAuthStatus}
            errorMessage={walmartAdsErrorMessage}
          />
        );
      case WALMART_FLASH_MODAL_SCREENS.WMTAuthorizationSuccessScreen:
        return (
          <>
            <ConnectionSuccessModal
              isNextStepPresent={true}
              channel={selectedChannel?.name}
              primaryButtonProps={{
                label: intl.formatMessage({
                  id:
                    walmartMerchantType === MerchantType.Seller
                      ? I18nKey.FLASH_MODALS_BTN_CONNECT_MARKETPLACE_DATA
                      : I18nKey.FLASH_MODALS_BTN_CONNECT_SUPPLIER_DATA,
                }),
                variant: ButtonVariant.Primary,
                onClick: () => {
                  setCurrentModal(
                    WALMART_FLASH_MODAL_SCREENS.WMTSelectMerchantTypeScreen
                  );
                },
              }}
              textLinkProps={{
                textLabel: intl.formatMessage({
                  id: I18nKey.FLASH_MODALS_BTN_CANCEL_CONNECT_PRODUCTS_DATA,
                }),
                onClick: () => {
                  setShowMissingMerchantConnectionsWarningPopup(true);
                },
              }}
              dataBoxes={
                isNewWalmartAdvertisingConnection
                  ? DATA_BOXES_WALMART_ADS_SUCCESS
                  : DATA_BOXES_WALMART_ADS_SUCCESS_OLD
              }
              closeModal={() => {
                setShowMissingMerchantConnectionsWarningPopup(true);
              }}
              primaryButtonDataTestId={
                'flash1_walmart_ads_auth_success_authorize_products'
              }
            />
            <ProductsDataConnectionWarningPopup
              isOpen={showMissingMerchantConnectionsWarningPopup}
              onCancel={() => {
                setShowMissingMerchantConnectionsWarningPopup(false);
              }}
              onSubmit={() => {
                setShowMissingMerchantConnectionsWarningPopup(false);
                closeModal();
              }}
            />
          </>
        );
      case WALMART_FLASH_MODAL_SCREENS.WMTSelectMerchantTypeScreen:
        return (
          <WalmartProductDataSyncForm
            merchantType={walmartMerchantType}
            onMerchantTypeSelect={onMerchantTypeChange}
            walmartOAuthStatus={walmartOAuthStatus}
            onContinue={() => {
              if (walmartMerchantType === MerchantType.Seller) {
                connectMarketplaceData();
              } else {
                setCurrentModal(
                  WALMART_FLASH_MODAL_SCREENS.WMTProductsFormScreen
                );
              }
            }}
            closeModal={closeModal}
          />
        );
      case WALMART_FLASH_MODAL_SCREENS.WMTProductsFormScreen:
        return (
          <WalmartProductSyncFormForSupplierDetails
            famClient={famClient}
            accountId={accountId}
            merchantType={walmartMerchantType}
            sellerId={walmartSellerId}
            clientId={walmartClientId}
            clientSecret={walmartClientSecret}
            walmartSellerType={walmartSellerType}
            distributorIds={walmartDistibutorIds}
            onSellerIdChange={onSellerIdChange}
            onClientIdChange={onClientIdChange}
            onClientSecretChange={onClientSecretChange}
            onWalmartSellerTypeChange={setWalmartSellerType}
            onAddNewDistributorId={onAddNewDistributorID}
            onDistributorIdChange={onDistributorIDChange}
            onRemoveDistributorId={onRemoveDistributorID}
            onClearAllChanges={onClearAllProductsChanges}
            onBack={() =>
              setCurrentModal(
                WALMART_FLASH_MODAL_SCREENS.WMTSelectMerchantTypeScreen
              )
            }
            onContinue={() =>
              setCurrentModal(
                WALMART_FLASH_MODAL_SCREENS.WMTProductsSuccessScreen
              )
            }
            closeModal={closeModal}
          />
        );
      case WALMART_FLASH_MODAL_SCREENS.WMTProductsSuccessScreen:
        return (
          <ConnectionSuccessModal
            isNextStepPresent={false}
            channel={selectedChannel?.name}
            dataBoxes={DATA_BOXES_WALMART_PRODUCTS_SUCCESS}
            primaryButtonProps={{
              label: intl.formatMessage({
                id: I18nKey.FLASH_MODALS_BTN_CLOSE_AND_EXIT,
              }),
              onClick: () => {
                setCurrentModal(null);
                onClose();
                if (refreshTable) {
                  refreshTable();
                }
              },
              variant: ButtonVariant.Primary,
            }}
            secondryButtonProps={{
              label: intl.formatMessage({
                id: I18nKey.FLASH_MODALS_BTN_CONNECT_MORE_MERCHANTS,
              }),
              onClick: () =>
                setCurrentModal(
                  WALMART_FLASH_MODAL_SCREENS.WMTSelectMerchantTypeScreen
                ),
              variant: ButtonVariant.BlackAndWhiteBorder,
            }}
            closeModal={closeModal}
            primaryButtonDataTestId={`flash1_walmart_product_success_close_and_exit`}
            secondryButtonDataTestId={`flash1_walmart_product_success_connect_more_merchants`}
          />
        );
      default:
        return <></>;
    }
  };

  return <>{getDisplayScreen(currentModal)}</>;
};
