import {
  ContentStyle,
  ControlledTooltip,
  Placement,
  SelectState,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupReviewStatusType,
  ProfileApiDataDetails,
  ProfileStatus,
  ReviewStatus,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_PROFILES_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import {
  EDIT_PROFILE_API_COLUMN_DATA_MAPPING,
  PROFILES_API_COLUMN_NAME,
  TABLE_UNIQ_KEY,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const getSelectState = (
  profileStatus?: ProfileStatus,
  adGroupReviewStatus?: AdGroupReviewStatusType,
  profileReviewStatus?: ReviewStatus
) => {
  if (profileStatus === ProfileStatus.Enabled) {
    return SelectState.Disabled;
  }

  if (
    adGroupReviewStatus === AdGroupReviewStatusType.InProgress ||
    adGroupReviewStatus === AdGroupReviewStatusType.Pending
  ) {
    return SelectState.Disabled;
  }

  return profileStatus !== ProfileStatus.Disabled ||
    profileReviewStatus === ReviewStatus.Approved
    ? SelectState.Default
    : SelectState.Disabled;
};

const getProfileStatusOptions = (intl: IntlShape) => [
  {
    label: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_PROFILE_STATUS_ENABLED,
    }),
    value: ProfileStatus.Enabled,
  },
  {
    label: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_PROFILE_STATUS_DISABLED,
    }),
    value: ProfileStatus.Disabled,
  },
];

export const getControlledTooltipStatus = (selectState: SelectState) =>
  selectState === SelectState.Disabled
    ? ControlledTooltip.None
    : ControlledTooltip.Hide;

export const getTooltipContent = (
  intl: IntlShape,
  profileStatus?: ProfileStatus,
  adGroupReviewStatus?: AdGroupReviewStatusType,
  profileReviewStatus?: ReviewStatus
) => {
  if (profileStatus === ProfileStatus.Enabled) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_PROFILES_TABLE_PROFILE_STATUS_ENABLED_TOOLTIP,
        })}
      </p>
    );
  }

  if (
    adGroupReviewStatus === AdGroupReviewStatusType.InProgress ||
    adGroupReviewStatus === AdGroupReviewStatusType.Pending
  ) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          {
            id: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_ONGOING_REVIEW_TOOLTIP_CONTENT,
          },
          {
            column: intl.formatMessage({
              id: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_PROFILE_STATUS,
            }),
          }
        )}
      </p>
    );
  } else if (
    profileStatus === ProfileStatus.Disabled &&
    profileReviewStatus !== ReviewStatus.Approved
  ) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_PROFILE_NOT_APPROVED_TOOLTIP_CONTENT,
        })}
      </p>
    );
  }
};

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = ({
  profileDetails,
  profilePerformance,
  profileId,
  channelSettings,
  isEditMode,
  pendingFields,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const value = useSelector<WithTable<ProfileApiDataDetails, any>, string>(
    ({ tableState }) =>
      tableSelectors.getCellValueSelector(
        profileId,
        PROFILES_API_COLUMN_NAME.ProfileStatus,
        EDIT_PROFILE_API_COLUMN_DATA_MAPPING,
        TABLE_UNIQ_KEY[ADS_MANAGER_PROFILES_TABLE_ID]
      )(tableState, ADS_MANAGER_PROFILES_TABLE_ID)
  );
  const profileData: ProfileApiDataDetails = {
    profileDetails,
    profilePerformance,
    profileId,
    channelSettings,
  };

  const currentRowIndex = useSelector<WithTable<ProfileApiDataDetails>, number>(
    ({ tableState }) => {
      const visibleData = tableSelectors.getVisibleData(
        tableSelectors.getTableSelector<ProfileApiDataDetails, void>()(
          tableState,
          ADS_MANAGER_PROFILES_TABLE_ID
        )
      );
      const index = (function () {
        for (let i = 0; i < visibleData.length; i++) {
          if (visibleData[i].profileId === profileId) {
            return i;
          }
        }
        return -1;
      })();

      return index;
    }
  );

  if (isEditMode) {
    const selectState = getSelectState(
      channelSettings?.profileStatus,
      profileDetails.adGroupReviewStatus?.reviewStatus,
      profileDetails.profileReviewStatus?.reviewStatus
    );

    return makeSelectColumn(
      (details: ProfileApiDataDetails) => {
        return {
          options: getProfileStatusOptions(intl),
          placeholder: '',
          value: value.toLowerCase(),
          className: 'w-108',
          isDirty:
            details.channelSettings?.profileStatus?.toLowerCase() !==
            value.toLowerCase(),
          onChange: (inputValue: string) => {
            dispatch(
              tableActions.setCellChange({
                tableId: ADS_MANAGER_PROFILES_TABLE_ID,
                rowId: details.profileId,
                columnName: PROFILES_API_COLUMN_NAME.ProfileStatus,
                columnDataMapping: EDIT_PROFILE_API_COLUMN_DATA_MAPPING,
                uniqKey: TABLE_UNIQ_KEY[ADS_MANAGER_PROFILES_TABLE_ID],
                value: inputValue,
              })
            );
          },
          state: selectState,
          dataTestId: 'ao_profile_status',
          showOnTop: currentRowIndex > 6,
        };
      },
      () => ({
        tooltipContent: getTooltipContent(
          intl,
          channelSettings?.profileStatus,
          profileDetails.adGroupReviewStatus?.reviewStatus,
          profileDetails.profileReviewStatus?.reviewStatus
        ),
        controlledTooltip: getControlledTooltipStatus(selectState),
        tooltipPlacement: Placement.Bottom,
        style: ContentStyle.Bold,
      })
    )(profileData);
  }

  const getColumnText = (data: ProfileApiDataDetails) =>
    data.channelSettings.profileStatus;

  return makeTextColumn(
    (data: ProfileApiDataDetails) => getColumnText(data),
    pendingFields?.includes(PROFILES_API_COLUMN_NAME.ProfileStatus)
  )(profileData);
};
RowCellElement.displayName = 'ProfileStatusCell';

export const profileStatusColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.ProfileStatus,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_PROFILE_STATUS,
  RowCellElement,
  gridColumnWidth: '180px',
};

export const profileStatusFilter = createMultiSelectDataFieldFilter(
  PROFILES_API_COLUMN_NAME.ProfileStatus,
  I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_PROFILE_STATUS,
  [
    SelectFilterOption.ProfileStatusEnabled,
    SelectFilterOption.ProfileStatusDisabled,
  ]
);
