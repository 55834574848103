import { useIntl } from 'react-intl';

import { TextLink } from '@teikametrics/tm-design-system';

import { AdGroupReviewReason } from '../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../lib/types/I18nKey';
import { REVIEW_URLS } from './types';
import { ReactNode } from 'react';

export interface TooltipTextProps {
  readonly comments?: string | AdGroupReviewReason[];
  readonly href?: string;
}

export const TooltipText: React.FC<TooltipTextProps> = ({
  comments,
  href = REVIEW_URLS.SBA_PROFILE_REVIEW_STATUS,
}) => {
  const intl = useIntl();

  const getComments = () => {
    if (comments) {
      if (Array.isArray(comments)) {
        return comments.map((comment, i) => {
          return (
            <div key={i} className="mb-10">
              <div>
                {intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_ADGROUP_REVIEW_STATUS_TOOLTIP_COMMENT_TYPE,
                })}
                : {comment.commentType}
              </div>
              <div>
                {intl.formatMessage({
                  id: I18nKey.ADS_MANAGER_ADGROUP_REVIEW_STATUS_TOOLTIP_COMMENTS,
                })}
                : {comment.comments}{' '}
              </div>
            </div>
          );
        });
      }
      return comments;
    }
  };

  if (comments) {
    return (
      <div
        className="font-semibold max-w-420"
        data-test-id="review_comments_tooltip"
      >
        <span className="whitespace-pre-wrap">{getComments()}</span>
        <div className="mt-10">
          {intl.formatMessage<ReactNode>(
            { id: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_TOOLTIP_FOOTER },
            {
              link: (content) => (
                <TextLink
                  textLabel={content}
                  href={href}
                  openNewTab={true}
                  className="hover:text-white text-white"
                />
              ),
            }
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      {intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_NO_COMMENTS_TOOLTIP_MESSAGE,
      })}
    </div>
  );
};
TooltipText.displayName = 'TooltipText';
