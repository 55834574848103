import {
  Icon,
  IconSize,
  PaletteColor,
  Pill,
  TruncatedText,
} from '@teikametrics/tm-design-system';
import { useIntl } from 'react-intl';
import { MerchantSyncData } from '../../../../../lib/types/AOSharedTypes';
import {
  ConnectionStatus,
  MerchantType,
  SalesChannelData,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { getCountryFlagIcon } from '../../../../../lib/utilities/countryFlags';
import { SalesChannelProps } from '../../../containers/salesChannels/types';
import { ConnectionStatusComponent } from './ConnectionStatusComponent';

interface MerchantCountrySyncStatusCardProps extends SalesChannelProps {
  readonly salesChannelData: SalesChannelData & MerchantSyncData;
  readonly refreshList: () => Promise<void>;
  readonly showDataTimingAndSyncSlideout?: () => void;
}

export const MerchantCountrySyncStatusCard: React.FC<
  MerchantCountrySyncStatusCardProps
> = (props) => {
  const intl = useIntl();
  const { salesChannelData, refreshList, showDataTimingAndSyncSlideout } =
    props;
  const countryFlag = getCountryFlagIcon(salesChannelData?.country ?? 'US');

  return (
    <div className="w-468 h-136 border border-solid border-grey-200 rounded-4 px-16 py-4 gap-12">
      <div className="flex w-full">
        <div className="flex items-center self-stretch">
          <Icon svg={countryFlag} size={IconSize.Medium} />
        </div>
        <h3 className="flex w-1/3 px-8 self-stretch items-center pt-2">
          <TruncatedText
            text={salesChannelData.name}
            fontClassNames="text-base leading-tight font-medium text-grey-900"
          />
        </h3>
        <label className="flex w-1/3 text-sm leading-none font-normal text-grey-600 px-8 self-stretch items-center">
          {salesChannelData.extMerchantId}
        </label>
        <div className="flex w-1/3 self-stretch justify-end">
          <Pill
            color={PaletteColor.greyV2}
            text={intl.formatMessage({
              id:
                salesChannelData.extMerchantType === MerchantType.Seller
                  ? I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_TYPE_SELLER
                  : I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_TYPE_VENDOR,
            })}
          />
        </div>
      </div>
      <div className="flex flex-col w-full">
        <ConnectionStatusComponent
          adConnectionStatus={
            salesChannelData.adConnectionStatus as ConnectionStatus
          }
          productConnectionStatus={
            salesChannelData.productConnectionStatus as ConnectionStatus
          }
          salesChannelData={salesChannelData}
          showDataTimingAndSyncSlideout={showDataTimingAndSyncSlideout}
          refreshList={refreshList}
        />
      </div>
    </div>
  );
};

MerchantCountrySyncStatusCard.displayName = 'MerchantCountrySyncStatusCard';
