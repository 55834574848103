import { Factory } from 'fishery';
import { PaginatedResult } from '../../clients/types';
import { InventoryDashboardOOSData } from '../../types/SKUSharedTypes';

export const OutOfStockTableDataResponse = Factory.define<
  PaginatedResult<InventoryDashboardOOSData>
>(() => ({
  items: [
    {
      productCatalogId: '913239d9-2597-5f8c-9a20-ef46b46cbe5e',
      salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
      merchantCountryId: 'a13d7f23-5930-4d8b-bfe9-4aa08c69f548',
      parentProductCatalogId: '913239d9-2597-5f8c-9a20-ef46b46cbe5e',
      skuDetailsFields: {
        sku: '023-2184-000005FBA',
        name: '[Improved design] Replacement Part Reservoir Valve Rubber Gasket for Waterpik Ultra WP100 WP112 WP113 WP114 WP140 WF06 (Crystal)',
        imageUrl: 'https://m.media-amazon.com/images/I/51jOcc1PfwL.jpg',
        extItemDetail: {
          extItemId: 'B07M7K68RM',
          extItemType: 'ASIN',
          salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
          salesChannelName: 'Amazon',
        },
      },
      outOfStockDate: '2023-12-06',
      lastSalePrice: {
        amount: 18.99,
        currency: 'USD',
      },
      advertisedDetails: {
        campaignCount: false,
        associatedCampaignIds: [],
      },
      performance: {
        rateOfSale: 0.77,
        unitsSold: 23,
        totalSales: {
          amount: 436.77,
          currency: 'USD',
        },
        estimatedMissedSales: {
          amount: 131.6,
          currency: 'USD',
        },
      },
    },
    {
      productCatalogId: '60bd861e-cbd5-5aee-9d58-b108c7342da2',
      salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
      merchantCountryId: 'a13d7f23-5930-4d8b-bfe9-4aa08c69f548',
      parentProductCatalogId: 'd9a77cd1-2244-50ee-99ab-fe7f7d321197',
      skuDetailsFields: {
        sku: '023-2184-000004FBA',
        name: 'Replacement Parts for Waterflosser Oral Irrigator Dental Compatible with Waterpik Sonic Fusion Portable Water Flosser SF-02W SF-02 Reservoir Gasket Valve (SF-02 White Tank)',
        imageUrl: 'https://m.media-amazon.com/images/I/41x1E00gUoL.jpg',
        extItemDetail: {
          extItemId: 'B09QBYZ5BP',
          extItemType: 'ASIN',
          salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
          salesChannelName: 'Amazon',
        },
      },
      outOfStockDate: '',
      lastSalePrice: null,
      advertisedDetails: {
        campaignCount: true,
        associatedCampaignIds: [],
      },
      performance: {
        rateOfSale: null,
        unitsSold: null,
        totalSales: null,
        estimatedMissedSales: null,
      },
    },
  ],
  totalItems: 2,
  lastSyncedAt: '2023-12-15T10:44:08.044Z',
}));
