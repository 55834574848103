import {
  BadgeCampaignType,
  Button,
  ButtonSize,
  ButtonVariant,
  CampaignAdGroup,
  CampaignAdGroupVariant,
  ColumnAlign,
  KebabLinearIcon,
  Tooltip,
} from '@teikametrics/tm-design-system';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  DropDownType,
  KeywordActionColumns,
  KeywordAdgroupInfo,
  KeywordRecommendationsData,
  SelectedRecommendationsV2,
  TargetingType,
} from '../../../../../lib/types/KeywordAction';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../lib/utilities/intercom';
import { ViewTrendLink } from '../../../../advertisingOptimization/components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../../advertisingOptimization/components/ViewTrends';
import { KeywordTabPaths } from '../../KeywordsDashboard2';
import { NewDestinationAdGroupModal } from '../../RecommendationsTableV3/NewDestinationAdGroupModal';
import { getCampaignGoal } from '../../RecommendationsTableV3/columns/createInAdGroup';
import { getKeywordTargetingTypes } from '../../RecommendationsTableV3/columns/keywordRecommendation';
import { RecommendationsTableV2ExtraProps } from '../types';

const getAdGroup = (
  original: KeywordRecommendationsData,
  selectedRows: SelectedRecommendationsV2
) => {
  const selectedAdGroup = selectedRows?.[original.id]?.adGroup;
  return selectedAdGroup ?? original.suggestedDestination.adGroup;
};

export const Cell: React.FC<{
  original: KeywordRecommendationsData;
  meta: RecommendationsTableV2ExtraProps;
}> = ({
  original,
  meta: { handleDropdownSelection, selectedRows, merchantCountry },
}) => {
  const adGroup = getAdGroup(original, selectedRows);

  const { name, campaign, targetSegments } = adGroup;
  const intl = useIntl();

  const [isAdgroupSelectionSlideOutOpen, setIsAdgroupSelectionSlideOutOpen] =
    useState<boolean>(false);

  const location = useLocation();

  const isNegativeKeyword = location.pathname.includes(
    KeywordTabPaths.KeywordsToNegate
  );

  const onAdGroupModalOpen = () => {
    setIsAdgroupSelectionSlideOutOpen(true);
    hideIntercomLauncher();
  };

  const onAdGroupModalClose = () => {
    setIsAdgroupSelectionSlideOutOpen(false);
    showIntercomLauncher();
  };

  const onAdgroupChange = (info: KeywordAdgroupInfo) => {
    handleDropdownSelection(original, DropDownType.CAMPAIGN, info.campaign);
    handleDropdownSelection(original, DropDownType.AD_GROUP, info);
    setIsAdgroupSelectionSlideOutOpen(false);
    showIntercomLauncher();
  };

  return (
    <div className="flex justify-between items-center h-full mt-4">
      {isAdgroupSelectionSlideOutOpen && (
        <NewDestinationAdGroupModal
          isOpen={isAdgroupSelectionSlideOutOpen}
          onClose={onAdGroupModalClose}
          keyword={original.keyword.text ?? ''}
          merchantCountryId={merchantCountry.merchantCountryId}
          defaultAdGroup={adGroup}
          isNegativeKeyword={isNegativeKeyword}
          onApplyChange={onAdgroupChange}
        />
      )}
      <div className="flex w-full h-full items-center justify-between">
        <CampaignAdGroup
          variant={CampaignAdGroupVariant.View}
          campaignType={
            campaign?.targetingType === TargetingType.Auto
              ? BadgeCampaignType.Auto
              : BadgeCampaignType.Manual
          }
          campaignGoal={getCampaignGoal(adGroup.campaign?.createdBy)}
          campaignName={campaign?.name ?? ''}
          adGroupName={name ?? ''}
          keywordRoutingTypes={getKeywordTargetingTypes(targetSegments)}
          hidePadding
        />
      </div>
      <div className="flex">
        <ViewTrendLink
          dataTestId="keyword_recommendations_ad_group_name"
          adType={campaign?.campaignType}
          campaignId={campaign?.id}
          campaignName={campaign?.name}
          entityId={adGroup.id}
          entityName={adGroup.name}
          entityType={ViewTrendsEntityType.ADGROUP}
          merchantCountryId={merchantCountry.merchantCountryId}
          merchantCountryName={merchantCountry.merchantName}
          merchantType={merchantCountry.merchantType}
          salesChannelId={merchantCountry.salesChannelId}
          targetingType={campaign?.targetingType}
          classnames="relative"
        />
        <Tooltip
          content={intl.formatMessage({
            id: I18nKey.KWA_CHANGE_DESTINATION_TOOLTIP,
          })}
          delayShow={750}
        >
          <Button
            size={ButtonSize.InlineIcon}
            variant={ButtonVariant.BlackAndWhite}
            svgIcon={KebabLinearIcon}
            onClick={onAdGroupModalOpen}
            dataTestId="open-add-group-modal"
          />
        </Tooltip>
      </div>
    </div>
  );
};
Cell.displayName = 'KWACellImpressions';

export const createInAdGroup: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  RecommendationsTableV2ExtraProps,
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_CREATE_IN_AD_GROUP_HEADER,
  accessorKey: KeywordActionColumns.CAMPAIGN_ADGROUP_NAME,
  sortable: true,
  size: 620,
  minSize: 620,
  overrideCellPaddingClassName: 'px-20 py-0',
  align: ColumnAlign.Left,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell original={original} meta={meta} />,
};
