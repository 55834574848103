import { IdToken } from '@auth0/auth0-react';
import { Spinner } from '@teikametrics/tm-design-system';
import { DateTime } from 'luxon';
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { NavPaths, viewOnlyUnsafePaths } from './NavPaths';
import { Navbar } from './Navbar';
import DelinquentAccountModal, {
  DelinquentAccountExperience,
} from './components/BillingModals/DelinquentAccountModal';
import { TrialEndedModal } from './components/TrialEndModal';
import { VerifyEmailModal } from './components/VerifyEmailModal';
import { SubscriptionContext } from './containers/subscriptionProvider';
import {
  MerchantCountriesContext,
  MerchantCountriesContextState,
} from './containers/merchantCountriesProvider/merchantCountriesProvider';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from './containers/optimizelyProvider/optimizelyProvider';
import {
  getCurrentAccountFromContext,
  getUserRoleMatch,
  hasPaymentInfo,
  isAIPlanEnabled,
  isAccountEnforced,
  isManagedAccount,
  isSelfServiceAccount,
  showTrialEndedPaymentNeeded,
} from './containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from './containers/userProvider/userProvider';
import { createFAMApiClient } from './lib/clients/FAMApiClient';
import {
  AccountPermission,
  MetadataTags,
  Role,
  UserDetails,
} from './lib/types/Fam';
import { OptimizelyFlags } from './lib/types/OptimizelyFlags';
import { getAutomationStatusByChannel } from './lib/utilities/tracking';
import { useSpapi } from './lib/utilities/useSpapi';
import SalesChannels from './modules/account/containers/salesChannels';
import { MainPageHeader } from './components/MainPageHeader';
import { EmptyStateBanner } from './components/EmptyStateBanner';
import { TrialBanner } from './components/TrialBanner';
import { AccountSwitchedModal } from './AccountSwitchedModal';
import { getMetadataTagsStatus } from './lib/utilities/inventoryUtils';
import { FWCookie, PERSISTED_KEYS } from './lib/utilities/fwCookie';
import { segmentIdentify } from './lib/utilities/segment';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Compass from './modules/compass';
import { useQuery } from '@tanstack/react-query';
import { CompassContextSetterDataProvider } from './modules/compass/compassContextSetterProvider';
import { GeneralQueryKeys } from './lib/types/ReactQueryKeys';

const AccountModule = lazy(() => import('./modules/account'));
const AdvertisingOptimization = lazy(
  () => import('./modules/advertisingOptimization')
);
const InventoryOptimization = lazy(
  () => import('./modules/inventoryOptimization')
);
const MarketIntelligence = lazy(() => import('./modules/marketIntelligence'));
const UserProfile = lazy(() => import('./modules/userProfile'));
const Products = lazy(() => import('./modules/products'));
const Recommendations = lazy(() => import('./modules/recommendations'));
const BusinessIntelligence = lazy(
  () => import('./modules/businessIntelligence')
);
const CampaignFlexibility = lazy(() => import('./modules/campaignFlexibility'));
const BudgetInsightsV2 = lazy(() => import('./modules/budgetInsightsV2'));
const Notifications = lazy(() => import('./modules/notifications'));
const BulkActions = lazy(() => import('./modules/bulkActions'));
const CampaignEdit = lazy(() => import('./modules/campaignEdit'));

const shouldRedirectToAccountSwitcher = (userDetails?: UserDetails) => {
  return (
    userDetails &&
    userDetails.accountPermissions.length > 1 &&
    !FWCookie.readCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID)
  );
};
interface ParamProps {
  readonly key: string;
  readonly openDelinquentModal: boolean;
}

export const AppModules: React.FC<
  Readonly<{
    token: IdToken | null;
    permissions?: AccountPermission;
    channel: BroadcastChannel;
  }>
> = ({ token, permissions, channel }): JSX.Element => {
  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);

  const navigate = useNavigate();
  const location = useLocation();
  const userRole = permissions?.role || Role.ACCOUNT_OWNER;
  const userContext = useContext<UserContextState>(UserContext);
  const hasVerifiedEmail = userContext.userInfo.userDetails?.emailVerified;
  const isDelinquent = userContext?.billingInfo?.isDelinquent;
  const aiEnabled = isAIPlanEnabled(userContext);
  const famApiClient = createFAMApiClient(token!);

  const showInventoryOptimization =
    optimizelyContext.featureFlags[OptimizelyFlags.InventoryOptimization];

  // Used to spapi_oauth_code to the backend on Amazon reconnection
  useSpapi();

  const [showOutstandingBalanceModal, setShowOutstandingBalanceModal] =
    useState(false);

  const [showTrialEndedModal, setShowTrialEndedModal] =
    useState<boolean>(false);

  const [showEnforceDowngradeModal, setShowEnforceDowngradeModal] =
    useState<boolean>(false);

  const [hideEmptyBannerStatus, setHideEmptyBannerStatus] =
    useState<boolean>(false);

  const [isUpsellPathEnabled, setIsUpsellPathEnabled] =
    useState<boolean>(false);

  const currentAccount = getCurrentAccountFromContext(userContext);
  const accountId = currentAccount!.id;
  const isAccountLockedOut = !!currentAccount?.lockedAt;
  const isManaged = isManagedAccount(userContext);
  const isSelfService = isSelfServiceAccount(currentAccount);
  const merchantCountriesContext = useContext<MerchantCountriesContextState>(
    MerchantCountriesContext
  );
  const { accountMetadata, updateAccountMetadata } = userContext;
  const [hideFreeTrialContent, setHideFreeTrialContent] = useState<boolean>();

  const subscriptionContext = useContext(SubscriptionContext);
  const { salesChannelData, isSubscriptionInformationLoaded } =
    subscriptionContext;

  const onShowEmptyBanner = (value: boolean) => setHideEmptyBannerStatus(value);

  const isUserRoleViewOnly = getUserRoleMatch(
    Role.VIEW_ONLY,
    userContext.userInfo.userDetails
  );

  useEffect(() => {
    if (isUserRoleViewOnly) {
      const isUnsafe = viewOnlyUnsafePaths.some((path) =>
        location.pathname.includes(path)
      );
      if (isUnsafe) {
        navigate(-1);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isSubscriptionInformationLoaded) {
      const aiStatus = getAutomationStatusByChannel(salesChannelData);
      segmentIdentify(
        userContext.userInfo.userDetails?.id,
        userContext.userInfo.userDetails?.email!,
        aiStatus
      );
    }
  }, [isSubscriptionInformationLoaded]);

  const navigateToHomeOrIO = () => {
    // we need to check if path is root or setup since isManagedAccount
    // may not be set when this first runs
    if (location.pathname === '/') {
      let navigateTo = NavPaths.Compass;

      if (shouldRedirectToAccountSwitcher(userContext.userInfo.userDetails)) {
        return;
      }
      navigate(navigateTo + location.search);
    }
  };

  const showBillingModalAndUpdateCookie = (showBillingFunc: () => void) => {
    const cookie = FWCookie.readCookie(PERSISTED_KEYS.SHOW_BILLING_MODALS);
    if (cookie === 'true' || cookie == null) {
      FWCookie.saveCookie(
        PERSISTED_KEYS.SHOW_BILLING_MODALS,
        'false',
        DateTime.now().plus({ hours: 2 })
      );
      showBillingFunc();
    }
  };

  useEffect(() => {
    // Modal States
    if (
      (location.state as ParamProps)?.openDelinquentModal ||
      isAccountLockedOut ||
      isDelinquent
    ) {
      // account is either locked or delinquent
      setShowOutstandingBalanceModal(true);
    } else if (
      userContext.isBillingLoaded &&
      !hasPaymentInfo(userContext) &&
      isAccountEnforced(userContext) &&
      isSelfService
    ) {
      // account was enforced and downgraded
      showBillingModalAndUpdateCookie(() => setShowEnforceDowngradeModal(true));
    } else if (
      userContext.isBillingLoaded &&
      !hideFreeTrialContent &&
      showTrialEndedPaymentNeeded(userContext)
    ) {
      // prompt self-service accounts when free trial ends (day 31-60) for payment
      showBillingModalAndUpdateCookie(() => setShowTrialEndedModal(true));
    }
  }, [location.state, isAccountLockedOut, isDelinquent, userContext]);

  useEffect(() => {
    if (optimizelyContext.isClientReady && !!optimizelyContext.featureFlags) {
      navigateToHomeOrIO();
    }
  }, [
    aiEnabled,
    optimizelyContext.isClientReady,
    optimizelyContext.featureFlags,
  ]);

  const { refetch: getMetadataTagStatus } = useQuery({
    queryKey: [GeneralQueryKeys.GetMetadataTagStatus, accountId],
    queryFn: () =>
      getMetadataTagsStatus(
        famApiClient,
        accountId,
        MetadataTags.UpsellInventoryModule
      ),
    enabled: false,
  });

  useEffect(() => {
    const setUpsellStatus = async () => {
      const { data: upsellMetadataTag } = await getMetadataTagStatus();
      setIsUpsellPathEnabled(!!upsellMetadataTag);
    };
    setUpsellStatus();
  }, []);

  useEffect(() => {
    updateMetadata();
  }, []);

  useEffect(() => {
    const hideFreeTrialTag = accountMetadata?.find(
      (tag) => tag.tag === MetadataTags.HideFreeTrialContent
    );
    setHideFreeTrialContent(hideFreeTrialTag?.value === 'enabled');
  }, [accountMetadata]);

  const updateMetadata = async () => {
    await famApiClient.getAllMetadataTags(accountId).then((resp) => {
      updateAccountMetadata(resp);
    });
  };

  const verifyEmail = () => {
    famApiClient.triggerVerificationEmail();
  };

  if (!optimizelyContext.isClientReady) {
    return <Spinner />;
  }

  const showTrialAndOnboarding = !isManaged;

  const navigateToBilling = () => {
    setShowTrialEndedModal(false);
    setShowEnforceDowngradeModal(false);
    navigate(NavPaths.Billing, {
      state: { openiFrame: true },
    });
  };

  return (
    <div className="flywheel-container">
      {!hasVerifiedEmail && <VerifyEmailModal onClick={verifyEmail} />}
      <AccountSwitchedModal channel={channel} />
      <Navbar userRole={userRole} />
      <div className="overflow-auto" id="flywheel-page-content">
        {!hideEmptyBannerStatus ? (
          <EmptyStateBanner
            userContext={userContext}
            onShowEmptyBanner={onShowEmptyBanner}
          />
        ) : (
          token &&
          !hideFreeTrialContent && (
            <TrialBanner idToken={token} userContext={userContext} />
          )
        )}
        <MainPageHeader />
        {
          <Suspense fallback={<Spinner />}>
            {showOutstandingBalanceModal && showTrialAndOnboarding && (
              <DelinquentAccountModal
                onCancelClicked={() => setShowOutstandingBalanceModal(false)}
                experience={
                  isAccountLockedOut
                    ? DelinquentAccountExperience.Locked
                    : DelinquentAccountExperience.Delinquent
                }
              />
            )}

            {showEnforceDowngradeModal && (
              // day 61+ forced downgrade
              <DelinquentAccountModal
                onCancelClicked={() => setShowEnforceDowngradeModal(false)}
                experience={DelinquentAccountExperience.Enforced}
              />
            )}

            {showTrialEndedModal && (
              <TrialEndedModal
                onCancelClicked={() => setShowTrialEndedModal(false)}
                onContinueClicked={navigateToBilling}
                merchantCountries={merchantCountriesContext.merchantCountries}
              />
            )}
            <Routes>
              {userRole === Role.EDITOR ? (
                currentAccount && (
                  <Route
                    path="account/sales-channels"
                    element={<SalesChannels accountId={currentAccount?.id} />}
                  />
                )
              ) : (
                <>
                  <Route
                    path="market-intelligence/*"
                    element={<MarketIntelligence idToken={token} />}
                  />
                  {token && (
                    <Route path="account/*" element={<AccountModule />} />
                  )}
                  <Route path="user-profile" element={<UserProfile />} />
                  <Route
                    path="ads-optimization/*"
                    element={<AdvertisingOptimization idToken={token} />}
                  />
                  <Route
                    path="products/*"
                    element={<Products idToken={token} />}
                  />
                  {(showInventoryOptimization || isUpsellPathEnabled) && (
                    <Route
                      path="inventory-optimization/*"
                      element={<InventoryOptimization idToken={token} />}
                    />
                  )}
                  <Route
                    path="compass/*"
                    element={
                      <CompassContextSetterDataProvider>
                        <Compass idToken={token} />
                      </CompassContextSetterDataProvider>
                    }
                  />
                  <Route
                    path={`${NavPaths.BusinessIntelligence}/*`}
                    element={<BusinessIntelligence />}
                  />
                  <Route
                    path="recommendations/*"
                    element={<Recommendations idToken={token} />}
                  />
                  <Route
                    path={`${NavPaths.CampaignFlexibility}/*`}
                    element={<CampaignFlexibility />}
                  />
                  <Route
                    path={`${NavPaths.Campaign}/*`}
                    element={<CampaignEdit />}
                  />
                  <Route
                    path={`${NavPaths.BudgetInsights}/*`}
                    element={<BudgetInsightsV2 />}
                  />

                  {optimizelyContext.featureFlags[
                    OptimizelyFlags.NotificationInbox
                  ] && (
                    <Route path="notifications" element={<Notifications />} />
                  )}

                  {optimizelyContext.featureFlags[
                    OptimizelyFlags.BulkActions
                  ] && (
                    <Route
                      path={`${NavPaths.BulkActions}`}
                      element={<BulkActions idToken={token} />}
                    />
                  )}
                </>
              )}
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};

AppModules.displayName = 'AppModules';
