/* eslint-disable max-lines */
import includes from 'lodash/includes';
import values from 'lodash/values';

enum I18nKey {
  GENERAL_CLEAR_ALL = 'react.general.clear_all',
  GENERAL_PERIOD = 'react.general.period',
  GENERAL_BOOLEAN_TRUE = 'react.general.boolean.true',
  GENERAL_BOOLEAN_FALSE = 'react.general.boolean.false',
  GENERAL_YES = 'react.general.yes',
  GENERAL_NO = 'react.general.no',
  GENERAL_FILTERS = 'react.general.filters',
  GENERIC_EM_DASH = 'react.generic.em_dash',
  GENERIC_SEARCH_LABEL = 'react.general.search_label',
  GENERIC_CANCEL_LABEL = 'react.general.cancel_label',
  GENERIC_CLEAR_LABEL = 'react.general.clear_label',
  GENERIC_SAVE_LABEL = 'react.general.save_label',
  GENERIC_SAVE_N_CHANGES = 'react.general.save_n_changes_label',
  GENERIC_SAVE_CHANGES = 'react.general.save_changes_label',
  GENERIC_REMOVE = 'react.general.remove_label',
  GENERIC_DELETE = 'react.general.delete',
  GENERIC_RELOAD = 'react.general.reload',
  GENERIC_ASIN = 'react.generic.asin',
  GENERIC_ID = 'react.generic.id',
  GENERIC_SKU = 'react.generic.sku',
  GENERIC_CONFIRM = 'react.generic.confirm',
  GENERIC_DATE = 'react.generic.date',
  GENERIC_TIME = 'react.generic.time',
  GENERIC_SUBMIT = 'react.generic.submit',
  TOTAL = 'react.generic.total',
  NA = 'react.generic.na',
  GENERIC_AVG = 'react.generic.avg',
  GENERIC_EDIT_ALL_SELECTED = 'react.generic.edit_all_selected',
  GENERIC_COLLAPSE_ALL = 'react.generic.collapse_all',
  GENERIC_EXPAND_ALL = 'react.generic.expand_all',
  GENERIC_COLLAPSE = 'react.generic.collapse',
  GENERIC_EXPAND = 'react.generic.expand',
  GENERIC_BY = 'react.generic.by',
  GENERIC_AND = 'react.generic.and',
  GENERIC_SEE_ALL = 'react.generic.see_all',
  GENERIC_VIEW_ALL = 'react.generic.view_all',
  GENERIC_ACTIVE = 'react.generic.active',
  GENERIC_EDIT = 'react.generic.edit',
  GENERIC_MORE = 'react.generic.more',
  GENERIC_MANAGE_ALERTS = 'react.generic.manage_alerts',
  GENERIC_NOTIFICATION_SETTINGS = 'react.generic.notification_settings',
  GENERIC_EMAIL_PREFERENCE_UPDATE_SUCCESS = 'react.generic.email_preference_update_success',
  GENERIC_BACK = 'react.generic.back',
  GENERIC_DATA_DEFINITIONS_TEXT = 'react.generic.data_definitions',
  GENERIC_PROCEED = 'react.generic.proceed',
  GENERIC_SETTINGS = 'react.generic.settings',
  GENERIC_ENABLED = 'react.generic.enabled',
  GENERIC_TRY_AGAIN = 'react.common.merchants_count.try_again',
  GENERIC_REFRESH = 'react.common.refresh',
  GENERIC_LEARN_MORE_IN_HELP_CENTER = 'react.generic.learn_more_help_center',
  GENERIC_LATEST_NEWS_FROM_TEIKAMETRICS = 'react.generic.latest_news_from_teikametrics',
  GENERIC_VIEW_MORE = 'react.generic.view_more',
  GENERIC_OUT_OF_STOCK = 'react.generic.out_of_stock',
  GENERIC_UNSOLD_INVENTORY = 'react.generic.unsold_inventory',
  GENERIC_WHATS_NEW = 'react.generic.whats_new',
  GENERIC_TRY_RISK_FREE_ONE_MONTH = 'react.generic.try_risk_free.one_month',
  GENERIC_NO_THANKS_MAY_BE_LATER = 'react.generic.no_thanks_may_be_later',
  GENERIC_ATTACH_FILE = 'react.generic.attach_file',
  GENERIC_ATTACH_CAPTION_FILE = 'react.generic.attach_caption_file',
  GENERIC_ATTACH_VIDEO_FILE = 'react.generic.attach_video_file',
  GENERIC_CONTEXT_SETTER_MERCHANTS = 'react.generic.context_setter.merchant',
  GENERIC_MANAGE_CONNECTIONS = 'react.generic.manage_connections',
  GENERIC_COLUMNS = 'react.generic.columns',
  GENERIC_DOWNLOAD_CSV = 'react.generic.download_csv',
  GENERIC_SHOW_MORE = 'react.common.show_more',
  GENERIC_SHOW_LESS = 'react.common.show_less',
  GENERIC_LESS_THAN_ONE_PERCENT = 'react.generic.less_than_one_percent',
  GENERIC_NEW_WITH_EXCLAMATION = 'react.generic.new.exclamation',
  GENERIC_CONGRATULATIONS_WITH_EXCLAMATION = 'react.generic.congratulations_with_exclamation',
  GENERIC_TADA = 'react.generic.tada',
  GENERIC_SELLER = 'react.generic.seller',
  GENERIC_VENDOR = 'react.generic.vendor',
  GENERIC_VIEW_TRENDS = 'react.generic.view_trends',
  GENERIC_SOMETHING_WENT_WRONG_TOAST_MESSAGE = 'react.generic.something_went_wrong.toast',
  GENERIC_SUCCESS_2 = 'react.generic.success_2',
  GENERIC_COGS = 'react.generic.cogs',
  GENERIC_EDIT_COGS = 'react.generic.edit_cogs',
  GENERIC_SCANNING_COGS = 'react.generic.scanning_cogs',
  GENERIC_MISSING_COGS = 'react.generic.missing_cogs',
  GENERIC_HOURS = 'react.generic.hours',
  GENERIC_ENTER = 'react.generic.enter',
  GENERIC_LEAVE_PAGE_HEADER = 'react.generic.leave_page_header',
  GENERIC_LEAVE_PAGE_DESCRIPTION = 'react.generic.leave_page_message',
  GENERIC_CHANGE_MERCHANT_HEADER = 'react.generic.change_merchant_header',
  GENERIC_HIGH_PRIORITY = 'react.generic.high_priority',

  ACCOUNT_SWITCHER_PAGE_HEADER = 'react.pages.account_switcher.header',
  ACCOUNT_SWITCHER_PAGE_PILL = 'react.pages.account_switcher.pill',
  ACCOUNT_SWITCHER_PAGE_LOG_OUT = 'react.pages.account_switcher.log_out',
  USER_MANAGEMENT_TABLE_HEADER_USER = 'react.pages.user_management.table_header.user',
  USER_MANAGEMENT_TABLE_HEADER_STATUS = 'react.pages.user_management.table_header.status',
  USER_MANAGEMENT_TABLE_HEADER_ROLE = 'react.pages.user_management.table_header.role',
  USER_MANAGEMENT_TABLE_HEADER_RECEIVE_NOTIFICATION = 'react.pages.user_management.table_header.receive_notifications',

  USER_MANAGEMENT_TABLE_ADD_USER = 'react.pages.user_management.table.add_user',
  USER_MANAGEMENT_TABLE_EDIT = 'react.pages.user_management.table.edit',
  USER_MANAGEMENT_TABLE_RESEND_INVITE = 'react.pages.user_management.table.resend_invite',
  USER_MANAGEMENT_TABLE_RESEND_INVITE_TOAST_SUCCESS_HEADLINE = 'react.pages.user_management.table.resend_invite.toast.success_headline',
  USER_MANAGEMENT_TABLE_RESEND_INVITE_TOAST_SUCCESS_DESCRIPTION = 'react.pages.user_management.table.resend_invite.toast.success_description',
  USER_MANAGEMENT_TABLE_RESEND_INVITE_TOAST_FAILURE_HEADLINE = 'react.pages.user_management.table.resend_invite.toast.failure_headline',
  USER_MANAGEMENT_TABLE_RESEND_INVITE_TOAST_FAILURE_DESCRIPTION = 'react.pages.user_management.table.resend_invite.toast.failure_description',
  USER_MANAGEMENT_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.pages.user_management.table.footer.total_items_text',
  USER_MANAGEMENT_INVITE_STATUS_EXPIRED = 'react.pages.user_management.invite_status.expired',
  USER_MANAGEMENT_INVITE_STATUS_PENDING = 'react.pages.user_management.invite_status.pending',
  USER_MANAGEMENT_INVITE_STATUS_VERIFIED = 'react.pages.user_management.invite_status.active',
  USER_MANAGEMENT_CREATE_NEW_USER_SUBMIT = 'react.pages.user_management.create_new_user.submit',
  USER_MANAGEMENT_CREATE_NEW_USER_CANCEL = 'react.pages.user_management.create_new_user.cancel',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_TITLE = 'react.pages.user_management.create_new_user.modal_title',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_USER_INFO = 'react.pages.user_management.create_new_user.user_info',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_FIRST_NAME = 'react.pages.user_management.create_new_user.form.first_name',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_FIRST_NAME_PLACEHOLDER = 'react.pages.user_management.create_new_user.form.first_name.placeholder',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_LAST_NAME = 'react.pages.user_management.create_new_user.form.last_name',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_LAST_NAME_PLACEHOLDER = 'react.pages.user_management.create_new_user.form.last_name.placeholder',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_EMAIL = 'react.pages.user_management.create_new_user.form.email',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_EMAIL_PLACEHOLDER = 'react.pages.user_management.create_new_user.form.email.placeholder',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_ROLE = 'react.pages.user_management.create_new_user.form.role',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_ROLE_PLACEHOLDER = 'react.pages.user_management.create_new_user.form.role.placeholder',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_OPTION_ADMIN = 'react.pages.user_management.create_new_user.form.role_options.admin',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_OPTION_VIEWER = 'react.pages.user_management.create_new_user.form.role_options.viewer',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_OPTION_ACCOUNT_OWNER = 'react.pages.user_management.create_new_user.form.role_options.account_owner',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_OPTION_ADMIN_DESCRIPTION = 'react.pages.user_management.create_new_user.form.role_options.admin_description',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_OPTION_VIEWER_DESCRIPTION = 'react.pages.user_management.create_new_user.form.role_options.viewer_description',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_OPTION_ADMIN_HELP = 'react.pages.user_management.create_new_user.form.role_options.admin_help',
  USER_MANAGEMENT_CREATE_NEW_USER_FORM_OPTION_VIEWER_HELP = 'react.pages.user_management.create_new_user.form.role_options.viewer_help',
  USER_MANAGEMENT_CREATE_NEW_USER_TOAST_SUCCESS_HEADLINE = 'react.pages.user_management.create_new_user.toast.success_headline',
  USER_MANAGEMENT_CREATE_NEW_USER_TOAST_SUCCESS_DESCRIPTION = 'react.pages.user_management.create_new_user.toast.success_description',
  USER_MANAGEMENT_CREATE_NEW_USER_TOAST_FAILURE_HEADLINE = 'react.pages.user_management.create_new_user.toast.failure_headline',
  USER_MANAGEMENT_CREATE_NEW_USER_TOAST_FAILURE_DESCRIPTION = 'react.pages.user_management.create_new_user.toast.failure_description',
  USER_MANAGEMENT_EDIT_USER_FORM_TITLE = 'react.pages.user_management.edit_user.modal_title',
  USER_MANAGEMENT_EDIT_USER_REMOVE_USER = 'react.pages.user_management.edit_user.remove_user',
  USER_MANAGEMENT_EDIT_USER_REMOVE_USER_WARNING = 'react.pages.user_management.edit_user.remove_user_warning',
  USER_MANAGEMENT_EDIT_USER_REMOVE_USER_SUCCESS = 'react.pages.user_management.edit_user.remove_user_success',
  USER_MANAGEMENT_EDIT_USER_REMOVE_USER_FAILURE = 'react.pages.user_management.edit_user.remove_user_failure',
  USER_MANAGEMENT_EDIT_USER_SELECT_ROLE = 'react.pages.user_management.edit_user.select_role',

  USER_MANAGEMENT_EDIT_USER_RECEIVE_NOTIFICATIONS = 'react.pages.user_management.edit_user.receive_notifications',

  USER_MANAGEMENT_EDIT_USER_ENABLE_NOTIFICATIONS_SUCCESS = 'react.pages.user_management.edit_user.enable_notifications_success',
  USER_MANAGEMENT_EDIT_USER_ROLE_UPDATE_SUCCEESS = 'react.pages.user_management.edit_user.role_update_success',
  USER_MANAGEMENT_EDIT_USER_ENABLE_NOTIFICATIONS_ERROR = 'react.pages.user_management.edit_user.enable_notifications_error',
  USER_MANAGEMENT_EDIT_USER_ROLE_UPDATE_ERROR = 'react.pages.user_management.edit_user.role_update_error',

  NAV_BAR_LABEL_COMPASS = 'react.components.navbar.compass.header',
  NAV_BAR_LABEL_RECOMMENDATIONS = 'react.components.navbar.recommendations.header',
  NAV_BAR_CAMPAIGN_FLEXIBILITY = 'react.components.navbar.CampaignFlexibility.header',
  NAV_BAR_LABEL_BUSINESS_INTELLIGENCE = 'react.components.navbar.business_intelligence',
  NAV_BAR_LABEL_BUSINESS_INTELLIGENCE_DISABLED_TOOLTIP = 'react.components.navbar.business_intelligence.diabled_tooltip',
  NAV_BAR_LABEL_UPLOAD_CUSTOM_DATA = 'react.components.navbar.upload_custom_data',
  NAV_BAR_LABEL_UPLOAD_CUSTOM_DATA_DOWNLOAD_TOOLTIP_MESSAGE = 'react.components.upload_custom_data.download_tooltip_message',
  NAV_BAR_LABEL_UPLOAD_CUSTOM_DATA_UPLOAD_MESSAGE = 'react.components.upload_custom_data.upload_message',
  NAV_BAR_LABEL_ACCOUNT = 'react.components.navbar.account.header',
  NAV_BAR_LABEL_ADVERTISING_OPTIMIZATION = 'react.components.navbar.advertising_optimization.header',
  NAV_BAR_LABEL_PRODUCTS = 'react.components.navbar.products.header',
  NAV_BAR_LABEL_SKU_CATALOG = 'react.components.navbar.sku_catalog.header',
  NAV_BAR_LABEL_BRANDS_AND_COMPETITORS = 'react.components.navbar.brands_and_competitors.header',
  NAV_BAR_LABEL_SKU_CATALOG_SELLER = 'react.components.navbar.sku_catalog.seller_header',
  NAV_BAR_LABEL_SKU_CATALOG_VENDOR = 'react.components.navbar.sku_catalog.vendor_header',
  NAV_BAR_LABEL_INVENTORY_OPTIMIZATION = 'react.components.navbar.inventory_optimization.header',
  NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_DASHBOARD = 'react.components.navbar.inventory_optimization.inventory_dashboard',
  NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_DASHBOARD_OUT_OF_STOCK = 'react.components.navbar.inventory_optimization.inventory_dashboard.out_of_stock',
  NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_INSIGHTS = 'react.components.navbar.inventory_optimization.inventory_insights',
  NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_DEMAND_FORECASTING = 'react.components.navbar.inventory_optimization.demand_forecasting',
  NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE = 'react.components.navbar.inventory_optimization.hiive_clearance',
  NAV_BAR_LABEL_MARKET_INTELLIGENCE = 'react.components.navbar.market_intelligence.header',
  NAV_BAR_LABEL_MARKET_INTELLIGENCE_SETTINGS = 'react.components.navbar.market_intelligence_settings.header',
  NAV_BAR_LABEL_COMMUNITY = 'react.components.navbar.community.header',
  NAV_BAR_LABEL_HELP = 'react.components.navbar.help.header',
  NAV_BAR_LABEL_HELP_TOOLTIP = 'react.components.navbar.help.tooltip',
  NAV_BAR_LABEL_SOV_PRODUCT_DETAILS = 'react.components.navbar.sov_product_details.header',
  NAV_BAR_LABEL_SOV_KEYWORD_INSIGHTS = 'react.components.navbar.sov_keyword_insights.header',
  NAV_BAR_LABEL_BULK_ACTIONS = 'react.components.navbar.bulk_actions.header',
  HOME_PAGE_TITLE = 'react.pages.home.title',

  SETUP_FLYWHEEL_HEADER_TITLE = 'react.pages.setup.header.title',
  SETUP_FLYWHEEL_HEADER_SUB_TITLE = 'react.pages.setup.header.sub_title',
  SETUP_FLYWHEEL_PRODUCTS_DATA_MISSING_WARNING_HEADER = 'react.pages.setup.products_connection_warning.header',
  SETUP_FLYWHEEL_PRODUCTS_DATA_MISSING_WARNING_CONTENT = 'react.pages.setup.products_connection_warning.content',
  SETUP_FLYWHEEL_PRODUCTS_DATA_MISSING_WARNING_SUBMIT_TEXT = 'react.pages.setup.products_connection_warning.submit_text',
  SETUP_FLYWHEEL_MISSING_MERCHANT_CONNECTIONS_WARNING_HEADER = 'react.pages.setup.merchant_connections_saved_warning.header',
  SETUP_FLYWHEEL_MISSING_MERCHANT_CONNECTIONS_WARNING_CONTENT = 'react.pages.setup.merchant_connections_saved_warning.content',
  SETUP_FLYWHEEL_MISSING_MERCHANT_CONNECTIONS_WARNING_SUBMIT_TEXT = 'react.pages.setup.merchant_connections_saved_warning.submit_text',
  SETUP_FLYWHEEL_STEP_2_TITLE = 'react.pages.setup.step_2.title',
  SETUP_FLYWHEEL_STEP_3_TITLE = 'react.pages.setup.step_3.title',
  SETUP_FLYWHEEL_STEP_1_TITLE = 'react.pages.setup.step_1.title',
  SETUP_FLYWHEEL_STEP_1_CONTINUE = 'react.pages.setup.step_1.continue',
  SETUP_FLYWHEEL_STEPS_TEXT = 'react.pages.setup.steps.text',
  SETUP_FLYWHEEL_STEPS_TEXT_WITH_TOTAL = 'react.pages.setup.steps.text.total',
  SETUP_FLYWHEEL_STEPS_TEXT_WITH_TOTAL_COMPLETE = 'react.pages.setup.steps.text.complete',

  SETUP_FLYWHEEL_STEP_1 = 'react.pages.setup.step_1',
  SETUP_FLYWHEEL_STEP_1_SUB_HEADER = 'react.pages.setup.step_1.sub_header',

  SETUP_FLYWHEEL_STEP_1_BUSINESS_NAME_PLACEHOLDER = 'react.pages.setup.step_1.input.business_name.placeholder',
  SETUP_FLYWHEEL_STEP_1_PHONE_NUMBER_PLACEHOLDER = 'react.pages.setup.step_1.input.phone_number.placeholder',
  SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_PLACEHOLDER = 'react.pages.setup.step_1.select.annual_gmv.placeholder',

  SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_OPTION_LESS_THAN_100 = 'react.pages.setup.step_1.select.annual_gmv.option.less_than_100',
  SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_OPTION_BETWEEN_100_AND_1M = 'react.pages.setup.step_1.select.annual_gmv.option.between_100_and_1m',
  SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_OPTION_BETWEEN_1M_AND_5M = 'react.pages.setup.step_1.select.annual_gmv.option.between_1m_and_5m',
  SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_OPTION_BETWEEN_5M_AND_10M = 'react.pages.setup.step_1.select.annual_gmv.option.between_5m_and_10m',
  SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_OPTION_MORE_THAN_10M = 'react.pages.setup.step_1.select.annual_gmv.option.more_than_10m',

  SETUP_FLYWHEEL_FINISH_PROFILE_TITLE = 'react.pages.setup.finish_profile.title',
  SETUP_FLYWHEEL_FINISH_PROFILE_SOCIAL_TITLE = 'react.pages.setup.finish_profile_social.title',
  SETUP_FLYWHEEL_GO_TO_FLYWHEEL_TITLE = 'react.pages.setup.finish_profile.go_to_flywheel.title',

  SETUP_FLYWHEEL_STEP_1_SPEAK_WITH_SALES = 'react.pages.setup.finish_profile.select.speak_with_sales',
  SETUP_FLYWHEEL_STEP_1_OPTIONAL_LABEL = 'react.pages.setup.finish_profile.optional.label',

  SETUP_FLYWHEEL_STEP_2_SUB_TITLE = 'react.pages.setup.step_2.sub_title',
  SETUP_FLYWHEEL_STEP_2_BODY = 'react.pages.setup.step_2.body',
  SETUP_FLYWHEEL_STEP_2_SUCCESSFUL_CONNECTIONS = 'react.pages.setup.step_2.successfull_connections',
  SETUP_FLYWHEEL_STEP_2_PENDING_CONNECTIONS = 'react.pages.setup.step_2.pending_connections',
  SETUP_FLYWHEEL_STEP_2_COMPLETE_TOTAL_CONNECTIONS = 'react.pages.setup.step_2.complete_total_connections',
  SETUP_FLYWHEEL_STEP_2_PENDING_TOTAL_CONNECTIONS = 'react.pages.setup.step_2.pending_total_connections',
  SETUP_FLYWHEEL_STEP_2_COMPLETE_PENDING_CONNECTIONS = 'react.pages.setup.step_2.complete_pending_mix_connections',
  SETUP_FLYWHEEL_STEP_2_PENDING_CONNECTIONS_PARTIAL = 'react.pages.setup.step_2.pending_connections_partial',
  SETUP_FLYWHEEL_STEP_2_NOT_CONNECTED = 'react.pages.setup.step_2.not_connected',
  SETUP_FLYWHEEL_STEP_2_INCOMPLETE = 'react.pages.setup.step_2.incomplete',
  SETUP_FLYWHEEL_STEP_2_FIX_MISSING_PRODUCT_CONNECTIONS = 'react.pages.setup.step_2.fix_missing_product_connections',
  SETUP_FLYWHEEL_STEP_2_SUCCESSFULLY_CONNECTED_SYNCED = 'react.pages.setup.step_2.successfully_connected_synced',
  SETUP_FLYWHEEL_STEP_2_PRODUCTS_DATA_IS_MISSING = 'react.pages.setup.step_2.products_data_is_missing',
  SETUP_FLYWHEEL_STEP_2_PRODUCTS_DATA_IS_MISSING_GENERIC = 'react.pages.setup.step_2.products_data_is_missing_generic',
  SETUP_FLYWHEEL_STEP_2_FLYWHEELS_FULL_AI_FUNCTIONALITY = 'react.pages.setup.step_2.flywheels_full_ai_functionality',
  SETUP_FLYWHEEL_STEP_2_BUTTON_FIX_ISSUE_TEXT = 'react.pages.setup.step_2.button_fix_issue_label',
  SETUP_FLYWHEEL_STEP_2_CONTINUE_WITHOUT_PRODUCTS_DATA = 'react.pages.setup.step_2.continue_without_products_data',
  SETUP_FLYWHEEL_STEP_3_SUB_TITLE = 'react.pages.setup.step_3.sub_title',
  SETUP_FLYWHEEL_STEP_3_BODY = 'react.pages.setup.step_3.body',
  SETUP_FLYWHEEL_STEP_3_NEXT_STEPS_TITLE = 'react.pages.setup.step_3.next_steps.title',
  SETUP_FLYWHEEL_STEP_3_NEXT_STEPS_PROGRESS = 'react.pages.setup.step_3.next_steps.progress',
  SETUP_FLYWHEEL_STEP_3_NEXT_STEPS_LAUNCH_SETUP = 'react.pages.setup.step_3.next_steps.launch_setup',
  SETUP_FLYWHEEL_STEP_3_NEXT_STEPS_LAUNCH_SETUP_STILL_SYNCING = 'react.pages.setup.step_3.next_steps.still_syncing',
  SETUP_FLYWHEEL_STEP_3_NEXT_STEPS_SETUP_DISMISSED = 'react.pages.setup.step_3.next_steps.setup_dismissed',
  SETUP_FLYWHEEL_STEP_3_AI_POWERED_TITLE = 'react.pages.setup.step_3.ai_powered.title',
  SETUP_FLYWHEEL_STEP_3_AI_POWERED_SUB_TITLE = 'react.pages.setup.step_3.ai_powered.sub_title',
  SETUP_FLYWHEEL_STEP_3_AI_POWERED_BODY = 'react.pages.setup.step_3.ai_powered.body',
  SETUP_FLYWHEEL_STEP_3_AI_POWERED_SUCCESS_TITLE = 'react.pages.setup.step_3.ai_powered.success_title',
  SETUP_FLYWHEEL_STEP_3_AI_POWERED_SUCCESS_SUB_TITLE = 'react.pages.setup.step_3.ai_powered.success_sub_title',
  SETUP_FLYWHEEL_STEP_3_AI_POWERED_SUCCESS_NEXT_ACTION = 'react.pages.setup.step_3.ai_powered.success_next_action',

  BUSINESS_INTELLIGENCE_PAGE_TITLE = 'react.business_intelligence.page.title',
  BUSINESS_INTELLIGENCE_PAGE_REPORT_TITLE = 'react.business_intelligence.page.reports.title',
  BUSINESS_INTELLIGENCE_PAGE_FLYWHEEL_REPORT_HEADER = 'react.business_intelligence.page.flywheel_report.header',
  BUSINESS_INTELLIGENCE_DASHBOARD_PAGE_ALL_REPORT_TITLE = 'react.business_intelligence.dashboard.page.all_reports.title',
  BUSINESS_INTELLIGENCE_REPORT_CARD_UPDATED_AT_DATE_DESCRIPTION = 'react.business_intelligence.report_card.updated_at_date.description',

  BUSINESS_INTELLIGENCE_CUSTOM_BI_DASHBOARDS_TITLE = 'react.business_intelligence.custom_bi_dashboards.title',
  BUSINESS_INTELLIGENCE_CUSTOM_BI_DASHBOARDS_DESCRIPTION = 'react.business_intelligence.custom_bi_dashboards.description',
  BUSINESS_INTELLIGENCE_CUSTOM_BI_DASHBOARDS_EMPTY_STATE_TITLE = 'react.business_intelligence.custom_bi_dashboards.empty_state.title',
  BUSINESS_INTELLIGENCE_CUSTOM_BI_DASHBOARDS_EMPTY_STATE_DESCRIPTION = 'react.business_intelligence.custom_bi_dashboards.empty_state.description',
  BUSINESS_INTELLIGENCE_CUSTOM_BI_DASHBOARDS_NO_SEARCH_RESULTS = 'react.business_intelligence.custom_bi_dashboards.no_search_results',
  BUSINESS_INTELLIGENCE_INDEX_REPORTS_TITLE = 'react.business_intelligence.index_reports.title',
  BUSINESS_INTELLIGENCE_INDEX_REPORTS_DESCRIPTION = 'react.business_intelligence.index_reports.description',
  BUSINESS_INTELLIGENCE_GLOSSARY = 'react.business_intelligence.glossary',
  BUSINESS_INTELLIGENCE_DASHBOARD_CREATE_NEW_REPORT = 'react.business_intelligence.dashboard.create_new_report',
  BUSINESS_INTELLIGENCE_DASHBOARD_PUBLISHED_REPORTS = 'react.business_intelligence.dashboard.tab.published_reports',
  BUSINESS_INTELLIGENCE_DASHBOARD_DRAFT_REPORTS = 'react.business_intelligence.dashboard.tab.draft_reports',
  BUSINESS_INTELLIGENCE_DASHBOARD_PUBLISHED_REPORTS_DESCRIPTION = 'react.business_intelligence.dashboard.tab.published_reports.description',
  BUSINESS_INTELLIGENCE_DASHBOARD_DRAFT_REPORTS_DESCRIPTION = 'react.business_intelligence.dashboard.tab.draft_reports.description',
  BUSINESS_INTELLIGENCE_DASHBOARD_DESCRIPTION = 'react.business_intelligence.dashboard.description',
  BUSINESS_INTELLIGENCE_DASHBOARD_NAME = 'react.business_intelligence.dashboard.name',
  BUSINESS_INTELLIGENCE_DASHBOARD_COLUMN_REPORT_TITLE = 'react.business_intelligence.dashboard.column.report_title',
  BUSINESS_INTELLIGENCE_DASHBOARD_COLUMN_LAST_UPDATED = 'react.business_intelligence.dashboard.column.last_updated',
  BUSINESS_INTELLIGENCE_DASHBOARD_CREATE_MODAL_TITLE = 'react.business_intelligence.dashboard.create_modal.title',
  BUSINESS_INTELLIGENCE_DASHBOARD_CREATE_MODAL_PLACEHOLDER = 'react.business_intelligence.dashboard.create_modal.placeholder',
  BUSINESS_INTELLIGENCE_DASHBOARD_CREATE_MODAL_TEXT = 'react.business_intelligence.dashboard.create_modal.text',
  BUSINESS_INTELLIGENCE_DASHBOARD_CREATE_MODAL_GENERATE_BUTTON = 'react.business_intelligence.dashboard.create_modal.generate_button',
  BUSINESS_INTELLIGENCE_DASHBOARD_NO_DATA_DESCRIPTION = 'react.business_intelligence.dashboard.no_data.description',
  BUSINESS_INTELLIGENCE_DASHBOARD_CONFIRMATION_MODAL_PUBLISH_BUTTON = 'react.business_intelligence.dashboard.confirmation_modal.publish_button',
  BUSINESS_INTELLIGENCE_DASHBOARD_CONFIRMATION_MODAL_DELETE_BUTTON = 'react.business_intelligence.dashboard.confirmation_modal.delete_button',
  BUSINESS_INTELLIGENCE_DASHBOARD_CONFIRMATION_MODAL_PUBLISH_TITLE = 'react.business_intelligence.dashboard.confirmation_modal.publish_title',
  BUSINESS_INTELLIGENCE_DASHBOARD_CONFIRMATION_MODAL_DELETE_TITLE = 'react.business_intelligence.dashboard.confirmation_modal.delete_title',
  BUSINESS_INTELLIGENCE_DASHBOARD_CONFIRMATION_MODAL_PUBLISH_DESCRIPTION = 'react.business_intelligence.dashboard.confirmation_modal.publish_description',
  BUSINESS_INTELLIGENCE_DASHBOARD_CONFIRMATION_MODAL_DELETE_DESCRIPTION = 'react.business_intelligence.dashboard.confirmation_modal.delete_description',
  BUSINESS_INTELLIGENCE_DASHBOARD_TOOLTIP_PUBLISH = 'react.business_intelligence.dashboard.tooltip.publish',
  BUSINESS_INTELLIGENCE_DASHBOARD_TOOLTIP_DELETE = 'react.business_intelligence.dashboard.tooltip.delete',
  BUSINESS_INTELLIGENCE_DASHBOARD_TOOLTIP_DELETE_LEGACY_DASHBOARD = 'react.business_intelligence.dashboard.tooltip.delete.legacy_dashboard',
  BUSINESS_INTELLIGENCE_DASHBOARD_SUCCESS_DELETE_MESSAGE = 'react.business_intelligence.dashboard.success.delete.message',
  BUSINESS_INTELLIGENCE_DASHBOARD_SUCCESS_PUBLISH_MESSAGE = 'react.business_intelligence.dashboard.success.publish.message',
  BUSINESS_INTELLIGENCE_DASHBOARD_SUCCESS_CREATE_MESSAGE = 'react.business_intelligence.dashboard.success.create.message',

  BUSINESS_INTELLIGENCE_TEIKAMETRICS_DASHBOARDS = 'react.business_intelligence.teikametrics_dashboards',
  BUSINESS_INTELLIGENCE_AMC_DASHBOARDS = 'react.business_intelligence.amc_dashboards',
  BUSINESS_INTELLIGENCE_ADMIN_SETTINGS = 'react.business_intelligence.admin_settings',
  BUSINESS_INTELLIGENCE_AMAZON_MARKETING_CLOUD = 'react.business_intelligence.amazon_marketing_cloud',
  BUSINESS_INTELLIGENCE_NO_AMC_TITLE = 'react.business_intelligence.no_amc.title',
  BUSINESS_INTELLIGENCE_NO_AMC_DESCRIPTION_PART1 = 'react.business_intelligence.no_amc.description_part1',
  BUSINESS_INTELLIGENCE_NO_AMC_DESCRIPTION_PART2 = 'react.business_intelligence.no_amc.description_part2',
  BUSINESS_INTELLIGENCE_AMC_TITLE = 'react.business_intelligence.amc.title',
  BUSINESS_INTELLIGENCE_AMC_DESCRIPTION = 'react.business_intelligence.amc.description',
  BUSINESS_INTELLIGENCE_AMC_NO_DASHBOARDS = 'react.business_intelligence.amc.no_dashboards',
  BUSINESS_INTELLIGENCE_AMC_READ_MORE_OR_LESS = 'react.business_intelligence.amc.read_more_or_less',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_DESCRIPTION = 'react.business_intelligence.amc_settings.description',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_DESCRIPTION_TOOLTIP_LINE_1 = 'react.business_intelligence.amc_settings.description_tooltip_line_1',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_DESCRIPTION_TOOLTIP_LINE_2 = 'react.business_intelligence.amc_settings.description_tooltip_line_2',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_DESCRIPTION_TOOLTIP_LINE_3 = 'react.business_intelligence.amc_settings.description_tooltip_line_3',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_TEMPLATE_VIEW_USE_WITH_PRESET_DATA_BUTTON = 'react.business_intelligence.amc_settings.template_view.use_with_preset_data_button',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_TEMPLATE_VIEW_CREATE_CUSTOM_REQUEST_BUTTON = 'react.business_intelligence.amc_settings.template_view.create_custom_request_button',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_TEMPLATE_VIEW_AMC_REQUEST_REQUIRED_TEXT = 'react.business_intelligence.amc_settings.template_view.amc_request_required_text',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_TEMPLATE_VIEW_OPEN_EXISTING_DRAFT_DASHBOARD = 'react.business_intelligence.amc_settings.template_view.open_existing_draft_dashboard',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_TEMPLATE_VIEW_CREATE_AND_OPEN_DRAFT_DASHBOARD = 'react.business_intelligence.amc_settings.template_view.create_and_open_draft_dashboard',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_TEMPLATE_VIEW_NO_SEARCH_RESULTS = 'react.business_intelligence.amc_settings.template_view.no_search_results',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_TEMPLATE_VIEW_NO_TEMPLATES = 'react.business_intelligence.amc_settings.template_view.no_templates',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_TEMPLATE_VIEW_AMC_BUTTON_TOOLTIP = 'react.business_intelligence.amc_settings.template_view.amc_request_button_tooltip',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_TEMPLATE_VIEW_PRESET_DATA_BUTTON_TOOLTIP = 'react.business_intelligence.amc_settings.template_view.preset_data_button_tooltip',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_CUSTOM_QUERY_NAME = 'react.business_intelligence.amc_settings.create_query.custom_query_name',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_SELECT_INSTANCE = 'react.business_intelligence.amc_settings.create_query.select_instance',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_REQUEST_DATA = 'react.business_intelligence.amc_settings.create_query.request_data',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_ADD_GROUP = 'react.business_intelligence.amc_settings.create_query.add_group',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_AMZ_DSP_INFO_TEXT = 'react.business_intelligence.amc_settings.create_query.amazon_dsp_info_text',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_SUBMIT_SUCCESS_TOAST_HEADLINE = 'react.business_intelligence.amc_settings.create_query.submit_success_toast_headline',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_SUBMIT_SUCCESS_TOAST_DESCRIPTION = 'react.business_intelligence.amc_settings.create_query.submit_success_toast_description',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_SUBMIT_FAILED_TOAST_HEADLINE = 'react.business_intelligence.amc_settings.create_query.submit_failed_toast_headline',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_SUBMIT_FAILED_TOAST_DESCRIPTION = 'react.business_intelligence.amc_settings.create_query.submit_failed_toast_description',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_VIEW_DESCRIPTION = 'react.business_intelligence.amc_settings.queries_view.description',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_VIEW_COLUMNS_QUERY_NAME = 'react.business_intelligence.amc_settings.queries_view.columns.query_name',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_VIEW_COLUMNS_EXECUTION_DATE = 'react.business_intelligence.amc_settings.queries_view.columns.execution_date',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_VIEW_COLUMNS_CREATED_AT = 'react.business_intelligence.amc_settings.queries_view.columns.created_at',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_VIEW_COLUMNS_INSTANCE_NAME = 'react.business_intelligence.amc_settings.queries_view.columns.instance_name',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_VIEW_COLUMNS_STATUS = 'react.business_intelligence.amc_settings.queries_view.columns.status',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_VIEW_COLUMNS_ACTION = 'react.business_intelligence.amc_settings.queries_view.columns.action',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_VIEW_QUERY_DELETE_SUCCESS = 'react.business_intelligence.amc_settings.queries_view.query_delete_success',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_VIEW_SEARCH_PLACEHOLDER = 'react.business_intelligence.amc_settings.queries_view.search_placeholder',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_DESCRIPTION_TOOLTIP = 'react.business_intelligence.amc_settings.queries_view.description_tooltip',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_CONFIRM_DELETE_HEADER = 'react.business_intelligence.amc_settings.queries_view.confirm_delete_header',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_QUERIES_CONFIRM_DELETE_BODY = 'react.business_intelligence.amc_settings.queries_view.confirm_delete_body',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_ENTER_COMMA_SEPARATED_IDS = 'react.business_intelligence.amc_settings.create_query.enter_comma_separated_ids',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_OPTIONAL = 'react.business_intelligence.amc_settings.create_query.optional',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_TEXT_ERROR_MESSAGE = 'react.business_intelligence.amc_settings.create_query.text_error_message',
  BUSINESS_INTELLIGENCE_AMC_SETTINGS_CREATE_QUERY_ARRAY_ERROR_MESSAGE = 'react.business_intelligence.amc_settings.create_query.array_error_message',

  BUSINESS_INTELLIGENCE_AMC_DASHBOARD_PUBLISHED_REPORTS_DESCRIPTION = 'react.business_intelligence.amc_dashboard.tab.published_reports.description',
  BUSINESS_INTELLIGENCE_AMC_DASHBOARD_DRAFT_REPORTS_DESCRIPTION = 'react.business_intelligence.amc_dashboard.tab.draft_reports.description',
  BUSINESS_INTELLIGENCE_AMC_DASHBOARD_BUTTON_PUBLISH = 'react.business_intelligence.amc_dashboard.button.publish',

  BUSINESS_INTELLIGENCE_SELECT_REPORT = 'react.business_intelligence.select_report',
  BUSINESS_INTELLIGENCE_SELECT_TEMPLATE = 'react.business_intelligence.select_template',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP1_DESCRIPTION = 'react.business_intelligence.upload_custom_data.step1.description',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_DESCRIPTION = 'react.business_intelligence.upload_custom_data.step2.description',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_NOTE = 'react.business_intelligence.upload_custom_data.step2.Note',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_DSP_FIELD1 = 'react.business_intelligence.upload_custom_data.step2_dsp.field1',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_DSP_FIELD2 = 'react.business_intelligence.upload_custom_data.step2_dsp.field2',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_DSP_FIELD3 = 'react.business_intelligence.upload_custom_data.step2_dsp.field3',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_FIELD1 = 'react.business_intelligence.upload_custom_data.step2.field1',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_FIELD2 = 'react.business_intelligence.upload_custom_data.step2.field2',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_FIELD3 = 'react.business_intelligence.upload_custom_data.step2.field3',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_FIELD4 = 'react.business_intelligence.upload_custom_data.step2.field4',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_ADS_FIELD1 = 'react.business_intelligence.upload_custom_data.step2_ads.field1',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_ADS_FIELD2 = 'react.business_intelligence.upload_custom_data.step2_ads.field2',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_ADS_FIELD3 = 'react.business_intelligence.upload_custom_data.step2_ads.field3',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_BUDGET_FIELD3 = 'react.business_intelligence.upload_custom_data.step2_budget.field3',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_BUDGET_FIELD4 = 'react.business_intelligence.upload_custom_data.step2_budget.field4',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP2_BUDGET_FIELD5 = 'react.business_intelligence.upload_custom_data.step2_budget.field5',
  BUSINESS_INTELLIGENCE_UPLOAD_DATA_STEP3_DESCRIPTION = 'react.business_intelligence.upload_custom_data.step3.description',

  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_TITLE1 = 'react.business_intelligence.self_serve.empty_state.title1',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_TAG = 'react.business_intelligence.self_serve.empty_state.tag',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_DESCRIPTION1 = 'react.business_intelligence.self_serve.empty_state.description1',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_TITLE2 = 'react.business_intelligence.self_serve.empty_state.title2',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_DESCRIPTION2 = 'react.business_intelligence.self_serve.empty_state.description2',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_TITLE3 = 'react.business_intelligence.self_serve.empty_state.title3',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_DESCRIPTION3 = 'react.business_intelligence.self_serve.empty_state.description3',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_TITLE4 = 'react.business_intelligence.self_serve.empty_state.title4',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_DESCRIPTION4 = 'react.business_intelligence.self_serve.empty_state.description4',

  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_AMC_TITLE1 = 'react.business_intelligence.self_serve.empty_state.amc.title1',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_AMC_DESCRIPTION1 = 'react.business_intelligence.self_serve.empty_state.amc.description1',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_AMC_TITLE2 = 'react.business_intelligence.self_serve.empty_state.amc.title2',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_AMC_DESCRIPTION2 = 'react.business_intelligence.self_serve.empty_state.amc.description2',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_AMC_TITLE3 = 'react.business_intelligence.self_serve.empty_state.amc.title3',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_AMC_DESCRIPTION3 = 'react.business_intelligence.self_serve.empty_state.amc.description3',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_AMC_TITLE4 = 'react.business_intelligence.self_serve.empty_state.amc.title4',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_AMC_DESCRIPTION4 = 'react.business_intelligence.self_serve.empty_state.amc.description4',
  BUSINESS_INTELLIGENCE_SELF_SERVE_EMPTY_STATE_FOOTER_LEARN_MORE_TEXT = 'react.business_intelligence.self_serve.empty_state.footer_learn_more_text',

  HOME_PAGE_ONBOARDING_GUIDE_HEADLINE_WELCOME = 'react.pages.home.onboarding_guide.headline.welcome',
  HOME_PAGE_ONBOARDING_GUIDE_HEADLINE_START_TRIAL = 'react.pages.home.onboarding_guide.headline.start_trial',
  HOME_PAGE_ONBOARDING_GUIDE_HEADLINE_START_DESCRIPTION = 'react.pages.home.onboarding_guide.description.start_trial',

  HOME_PAGE_ONBOARDING_GUIDE_TITLE = 'react.pages.home.onboarding_guide.title',
  HOME_PAGE_ONBOARDING_GUIDE_STEP_ONE = 'react.pages.home.onboarding_guide.step_one',
  HOME_PAGE_ONBOARDING_GUIDE_STEP_TWO = 'react.pages.home.onboarding_guide.step_two',
  HOME_PAGE_ONBOARDING_GUIDE_STEP_THREE = 'react.pages.home.onboarding_guide.step_three',
  HOME_PAGE_ONBOARDING_GUIDE_AI_STEP = 'react.pages.home.onboarding_guide.ai_step',

  HOME_PAGE_ONBOARDING_GUIDE_ADDITIONAL_STEPS = 'react.pages.home.onboarding_guide.additional_steps',
  HOME_PAGE_ONBOARDING_GUIDE_USERS_LINK = 'react.pages.home.onboarding_guide.users_link',
  HOME_PAGE_ONBOARDING_GUIDE_BILLING_LINK = 'react.pages.home.onboarding_guide.billing_link',
  HOME_PAGE_PAYG_TITLE = 'react.pages.home.payg.title',
  HOME_PAGE_PAYG_DESCRIPTION = 'react.pages.home.payg.description',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_ADS_HEADER = 'react.pages.home.connect_step_details_card.ads.header',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_ADS_INITIAL_DESCRIPTION = 'react.pages.home.connect_step_details_card.ads.initial.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_ADS_INITIAL_BUTTON = 'react.pages.home.connect_step_details_card.ads.initial.button',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_PRODUCTS_HEADER = 'react.pages.home.connect_step_details_card.products.header',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_PRODUCTS_INITIAL_DESCRIPTION = 'react.pages.home.connect_step_details_card.products.initial.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_PRODUCTS_INITIAL_BUTTON = 'react.pages.home.connect_step_details_card.products.initial.button',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_INITIAL_NOTE = 'react.pages.home.connect_step_details_card.initial.note',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_SYNCING_DESCRIPTION = 'react.pages.home.connect_step_details_card.syncing.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_CHECK_STATUS_BUTTON = 'react.pages.home.connect_step_details_card.button',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_SYNCING_NOTE = 'react.pages.home.connect_step_details_card.syncing.note',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_SUCCESS_DESCRIPTION = 'react.pages.home.connect_step_details_card.success.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_FAILED_DESCRIPTION = 'react.pages.home.connect_step_details_card.failed.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_SUCCESSFUL_CONNECTIONS = 'react.pages.home.connect_step_details_card.successful_connections',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_FAILED_CONNECTIONS = 'react.pages.home.connect_step_details_card.failed_connections',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_MANAGE_HEADER = 'react.pages.home.connect_step_details_card.ai_manage.header',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_MANAGE_DESCRIPTION = 'react.pages.home.connect_step_details_card.ai_manage.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_MANAGE_BUTTON = 'react.pages.home.connect_step_details_card.ai_manage.button',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_START_HEADER = 'react.pages.home.connect_step_details_card.ai_start.header',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_START_DESCRIPTION = 'react.pages.home.connect_step_details_card.ai_start.description',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_START_BUTTON = 'react.pages.home.connect_step_details_card.ai_start.button',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_AD_OPTIMIZER = 'react.pages.home.connect_step_details_card.ai_ad_optimizer',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_AD_OPTIMIZER_BUTTON = 'react.pages.home.connect_step_details_card.ai_ad_optimizer.button',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_AD_OPTIMIZER_HEADER = 'react.pages.home.connect_step_details_card.ai_ad_optimizer.header',
  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_AD_OPTIMIZER_DESCRIPTION = 'react.pages.home.connect_step_details_card.ai_ad_optimizer.description',

  SLIDE_OUT_PILL_AI_AD_OPTIMIZER_PILL_HEADER_TITLE = 'react.pages.home.settings_slide_out.ai_ad_optimizer.pill_header_title',
  SLIDE_OUT_PILL_AI_AD_OPTIMIZER_PILL_HEADER_TYPE = 'react.pages.home.settings_slide_out.ai_ad_optimizer.pill_header_type',
  SLIDE_OUT_PILL_AI_AD_OPTIMIZER_AD_GROUP_HEADER = 'react.pages.home.settings_slide_out.ai_ad_optimizer.ad_group_header',

  HOME_PAGE_CONNECT_STEP_DETAIL_CARD_AI_FOOTNOTE = 'react.pages.home.connect_step_details_card.ai.footnote',

  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_HEADER_COUNTRY = 'react.components.merchant_subscription_claim_table.header.country',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_HEADER_ID = 'react.components.merchant_subscription_claim_table.header.id',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_HEADER_TYPE = 'react.components.merchant_subscription_claim_table.header.type',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_CONTINUE = 'react.components.merchant_subscription_claim_table.action_row_continue',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_AUTHORIZES = 'react.components.merchant_subscription_claim_table.action_row_authorizes',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_CANCEL = 'react.components.merchant_subscription_claim_table.action_row_cancel',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_MERCHANT_AVAILABLE_HEADER = 'react.components.merchant_subscription_claim_table.merchant_available_header',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_MERCHANT_SELECTED_HEADER = 'react.components.merchant_subscription_claim_table.merchant_selected_header',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_NO_MERCHANT_HEADER = 'react.components.merchant_subscription_claim_table.no_merchant_header',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_NO_MERCHANT_SUB_TITLE = 'react.components.merchant_subscription_claim_table.no_merchant_sub_title',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_MERCHANT_HEADER = 'react.components.merchant_subscription_claim_table.merchant_header',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_MERCHANT_SUB_TITLE = 'react.components.merchant_subscription_claim_table.merchant_sub_title',
  MERCHANT_SUBSCRIPTION_CLAIM_TABLE_NO_MERCHANTS_HEADER = 'react.components.merchant_subscription_claim_table.no_merchants_header',
  SUB_NAV_NEW_ICON_TEXT = 'react.components.navbar.new',

  FLASH_MODALS_AMAZON_ALREADYCONNECTED_CONTENT_WARNING_HEADER = 'react.flash_modals.amazon.alreadyConnected.warning.header',
  FLASH_MODALS_AMAZON_ALREADYCONNECTED_CONTENT_WARNING_SUBHEADER = 'react.flash_modals.amazon.alreadyConnected.warning.subheader',
  FLASH_MODALS_AMAZON_AUTHORIZED_LABEL = 'react.flash_modals.amazon.authorized_label',

  PRODUCT_SUBSCRIPTIONS_MANAGE_ACCOUNT_BUTTON_TEXT = 'react.pages.product_subscriptions.manage_account_button_text',
  PRODUCT_SUBSCRIPTIONS_MANAGE_ACCOUNT_TOOLTIP_TEXT = 'react.pages.product_subscriptions.manage_account_tooltip_text',

  VERIFY_EMAIL_MODAL_TITLE = 'react.pages.verify_email_modal.title',
  VERIFY_EMAIL_MODAL_SUBTITLE = 'react.pages.verify_email_modal.subtitle',
  VERIFY_EMAIL_MODAL_BUTTON = 'react.pages.verify_email_modal.button',
  CONNECTING_ADS = 'react.pages.connect_sales_channel.connecting_ads',
  BACK_TO_CHANNELS = 'react.pages.connect_sales_channel.back_to_channels',
  CONNECT_SALES_CHANNEL_ROUTE_ADS_HEADER = 'react.pages.connect_sales_channel.route.ads.header',
  CONNECT_SALES_CHANNEL_ROUTE_ADS_DESCRIPTION = 'react.pages.connect_sales_channel.route.ads.description',
  CONNECT_SALES_CHANNEL_ROUTE_ADS_WALMART_NOTE = 'react.pages.connect_sales_channel.route.ads.walmart_note',
  CONNECT_SALES_CHANNEL_ROUTE_PRODUCT_HEADER = 'react.pages.connect_sales_channel.route.product.header',
  CONNECT_SALES_CHANNEL_ROUTE_PRODUCT_DESCRIPTION = 'react.pages.connect_sales_channel.route.product.description',
  CONNECT_SALES_CHANNEL_ROUTE_CONNECTIONS = 'react.pages.connect_sales_channel.route.connections',
  CONNECT_SALES_CHANNEL_ROUTE_CONNECT_PRODUCT_HEADER = 'react.pages.connect_sales_channel.route.connect_product.header',
  CONNECT_SALES_CHANNEL_PILL_START_HERE = 'react.pages.connect_sales_channel.pill.start_here',
  CONNECT_SALES_CHANNEL_PILL_HIGHLY_RECOMMENDED = 'react.pages.connect_sales_channel.pill.highly_recommended',
  CONNECT_SALES_CHANNEL_PILL_ADD_MORE_DATA = 'react.pages.connect_sales_channel.pill.add_more_data',
  CONNECT_SALES_CHANNEL_PILL_NEXT_STEP = 'react.pages.connect_sales_channel.pill.next_step',
  CONNECT_ADS_MODAL_AMAZON_HEADER = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.header',
  CONNECT_ADS_MODAL_AMAZON_HEADER_DATA = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.header_data',
  CONNECT_ADS_MODAL_WALMART_HEADER = 'react.pages.connect_sales_channel.connect_ads_modal.walmart.header',
  CONNECT_ADS_MODAL_WALMART_HEADER_DATA = 'react.pages.connect_sales_channel.connect_ads_modal.walmart.header_data',
  CONNECT_ADS_MODAL_AMAZON_CONTENT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.content',
  CONNECT_ADS_MODAL_AMAZON_BUTTON_TEXT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.button_text',
  CONNECT_ADS_MODAL_AMAZON_NOTE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.note',
  CONNECT_ADS_MODAL_AMAZON_ERROR_HEADING = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.error_heading',
  CONNECT_ADS_MODAL_AMAZON_ERROR_MESSAGE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.message',
  CONNECT_ADS_MODAL_AMAZON_CONNECTED_SUCCESS = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.connected_success',
  CONNECT_ADS_MODAL_AMAZON_SYNC_DATA_ADS = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.syncing_data_ads',
  CONNECT_ADS_MODAL_AMAZON_SYNC_DATA_PRODUCTS = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.syncing_data_products',
  CONNECT_ADS_MODAL_AMAZON_SYNC_ADDITIONAL_MSG = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.syncing_additional_mesage',
  CONNECT_ADS_MODAL_AMAZON_EMAIL_UPON_COMPLETE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.email_upon_complete',
  CONNECT_ADS_MODAL_AMAZON_CONNECTED_SYNCING = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.connected_syncing',
  CONNECT_ADS_MODAL_AMAZON_LOADING_NEXT_STEP = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.loading_next_step',
  CONNECT_ADS_MODAL_AMAZON_NO_MERCHANTS_AVAILABLE_CONNECT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.no_merchants_available_connect',
  CONNECT_ADS_MODAL_AMAZON_MERCHANTS_ALREADY_CONNECT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.merchants_already_connect',
  CONNECT_ADS_MODAL_AMAZON_UNAVAILABLE_MERCHANTS_TO_CONNECT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.unavailable_merchants_to_connect',
  CONNECT_ADS_MODAL_AMAZON_MERCHANTS_ALREADY_CONNECT_HEADER = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.merchants_already_connect_header',
  CONNECT_ADS_MODAL_AMAZON_SOME_UNAVAILABLE_MERCHANTS_HEADER = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.some_unavailable_merchants_header',
  CONNECT_ADS_MODAL_AMAZON_UNAVAILABLE_MERCHANTS_HEADER = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.unavailable_merchants_header',
  CONNECT_ADS_MODAL_AMAZON_UNAVAILABLE_MERCHANTS_SUB_TITLE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.unavailable_merchants_sub_title',
  CONNECT_ADS_MODAL_AMAZON_UNAVAILABLE_MERCHANTS_NOTICE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.unavailable_merchants_notice',
  CONNECT_ADS_MODAL_AMAZON_ERROR_PROMPT_MAIN_MESSAGE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.prompt.main_message',
  CONNECT_ADS_MODAL_AMAZON_ERROR_PROMPT_SUB_MESSAGE = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.prompt.sub_message',
  CONNECT_ADS_MODAL_LOG_IN_WITH_ANOTHER_ACCOUNT = 'react.pages.connect_sales_channel.connect_ads_modal.log_in_with_another_account',
  CONNECT_ADS_MODAL_PARTIAL_CONNECT_MAIN_TEXT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.merchants_partial_connected_main_text',
  CONNECT_ADS_MODAL_PARTIAL_CONNECT_SUB_TEXT = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.merchants_partial_connected_sub_text',
  CONNECT_ADS_MODAL_PARTIAL_CONNECT_SECONDARY_BUTTON = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.merchants_partial_connected_secondary_button',
  CONNECT_ADS_MODAL_PARTIAL_CONNECT_PRIMARYBUTTON = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.merchants_partial_connected_primary_button',

  CONNECT_PRODUCTS_MODAL_AMAZON_ADS_CONNECTED = 'react.pages.connect_sales_channel.connect_products_modal.amazon.ads_connected',
  CONNECT_PRODUCTS_MODAL_AMAZON_CONNECTED_MERCHANTS = 'react.pages.connect_sales_channel.connect_products_modal.amazon.connected_merchants',
  CONNECT_PRODUCTS_MODAL_AMAZON_CONNECTING_PRODUCTS = 'react.pages.connect_sales_channel.connect_products_modal.amazon.connecting_products',
  CONNECT_PRODUCTS_MODAL_AMAZON_REQUIRE_AUTH = 'react.pages.connect_sales_channel.connect_products_modal.amazon.require_auth',
  CONNECT_PRODUCTS_MODAL_AMAZON_SELLER_VENDOR_AUTH = 'react.pages.connect_sales_channel.connect_products_modal.amazon.seller_vendor_auth',
  CONNECT_PRODUCTS_MODAL_AMAZON_3P = 'react.pages.connect_sales_channel.connect_products_modal.amazon.3p_accounts',
  CONNECT_PRODUCTS_MODAL_AMAZON_1P = 'react.pages.connect_sales_channel.connect_products_modal.amazon.1p_accounts',
  CONNECT_PRODUCTS_MODAL_AMAZON_MULTIPLE_REGIONS_NOTE = 'react.pages.connect_sales_channel.connect_products_modal.amazon.multiple_regions_note',
  CONNECT_PRODUCTS_MODAL_AMAZON_REGION_NORTH_AMERICA = 'react.pages.connect_sales_channel.connect_products_modal.amazon.region.north_america',
  CONNECT_PRODUCTS_MODAL_AMAZON_REGION_EUROPE = 'react.pages.connect_sales_channel.connect_products_modal.amazon.region.europe',
  CONNECT_PRODUCTS_MODAL_AMAZON_REGION_FAR_EAST = 'react.pages.connect_sales_channel.connect_products_modal.amazon.region.far_east',
  CONNECT_PRODUCTS_MODAL_AMAZON_COMING_SOON = 'react.pages.connect_sales_channel.connect_products_modal.amazon.coming_soon',
  CONNECT_PRODUCTS_MODAL_AMAZON_LOGIN_BUTTON_TEXT = 'react.pages.connect_sales_channel.connect_products_modal.amazon.login_button_text',
  CONNECT_PRODUCTS_MODAL_AMAZON_ERROR_HEADING = 'react.pages.connect_sales_channel.connect_products_modal.amazon.error_heading',
  CONNECT_PRODUCTS_MODAL_AMAZON_OPTIONS = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.options',
  CONNECT_ADS_MODAL_WALMART_SYNC_ADDITIONAL_MSG = 'react.pages.connect_sales_channel.connect_ads_modal.walmart.syncing_additional_mesage',
  CONNECT_PRODUCTS_MODAL_AMAZON_REQUEST_ACCESS_OPTIONS = 'react.pages.connect_sales_channel.connect_ads_modal.amazon.request_access_options',

  SELECT_ADS_PROFILES_MODAL_AMAZON_HEADER = 'react.pages.select_ads_profile_modal.amazon.header',
  SELECT_ADS_PROFILES_MODAL_AMAZON_DESCRIPTION = 'react.pages.select_ads_profile_modal.amazon.description',
  SELECT_ADS_PROFILES_MODAL_SELECTED_ADS_COUNT = 'react.pages.select_ads_profile_modal.selected_ads_count.description',
  AMAZON_CONNECT_SELLING_PARTNER_BUTTON = 'react.pages.amazon.connect_selling_partner.button',

  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_SUCCESS_CHECK_EMAIL_HEADER = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.success.check_email.header',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_SUCCESS_CHECK_EMAIL_DESCRIPTION = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.success.check_email.description',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_SUCCESS_NEXT_STEP = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.success.next_step',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_sync.title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_1P_SUB_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_sync.1p.sub_title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_3P_SUB_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_sync.3p.sub_title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_SUCCESS_HEADER = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_sync.success.header',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_SUCCESS_API_TYPES = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_sync.success.api_types',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_HEADER = 'react.pages.product_subscriptions.add_connection_modal.header',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_HEADER_CONNECT_DATA = 'react.pages.product_subscriptions.add_connection_modal.header_connect_data',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_SUB_HEADER_CONNECT_DATA = 'react.pages.product_subscriptions.add_connection_modal.sub_header_connect_data',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_HEADER = 'react.pages.product_subscriptions.add_connection_modal.walmart.header',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_SUB_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.sub_title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_AUTHORIZATION_TITLE = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.authorization_title',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_AUTHORIZATION_DESCRIPTION = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.authorization_description',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_BEGIN_PROCESS_BTN = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.begin_process_btn',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_HELP_TEXT = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.help_text',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_DATA_FORM_SELLER_TYPE = 'react.ad_optimization.modal.seller_type',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_FORM_SELLER_ID = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.seller_id',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_FORM_CLIENT_ID = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.client_id',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_FORM_CLIENT_SECRET = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.client_secret',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_FORM_CONSUMER_ID = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.consumer_id',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_SYNC_FORM_PRIVATE_KEY = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.private_key',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_DATA_FORM_1P_HELP_MESSAGE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.1p.help.message',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_DATA_FORM_3P_HELP_MESSAGE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.3p.help.message',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_DATA_FORM_HELP_MESSAGE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.help.message',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WMT_PRD_DATA_FRM_BTN_1P_CONNECT = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.button.1p.connect',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WMT_PRD_DATA_FRM_BTN_3P_CONNECT = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.button.3p.connect',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_SUCCESS_HEADER = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.success_header',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_SUCCESS_SUB_HEADER = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.success_sub_header',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_SUCCESS_SUB_HEADER_ON_COMPLETE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.success_sub_header_complete',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_SUCCESS_MESSAGE_3P = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.success_message_3p',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_SUCCESS_MESSAGE_1P = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.success_message_1p',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_DATA_ERROR_HEADING = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.error_heading',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_PRODUCT_FORM_DATA_ERROR_MESSAGE = 'react.pages.product_subscriptions.add_connection_modal.walmart.product_data_sync_form.error_message',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_WALMART_AD_DATA_ERROR_MESSAGE = 'react.pages.product_subscriptions.add_connection_modal.walmart.ad_data.error_message',
  PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_HEADER_CONNECT_DATA_HELP_SECTION_NEED_HELP = 'react.pages.product_subscriptions.add_connection_modal.help_section_need_help',

  SALES_CHANNELS_TITLE_DATA_24HOUR = 'react.pages.sales_channels.data.title_24hour',
  SALES_CHANNELS_SUB_TITLE_DATA_24HOUR = 'react.pages.sales_channels.data.subtitle_24hour',
  SALES_CHANNELS_TITLE_PENDING_DATA_WALMART = 'react.pages.sales_channels.data.title_pending_data.walmart',
  SALES_CHANNELS_WALMART_SUB_TITLE_DATA_24HOUR = 'react.pages.sales_channels.walmart.data.subtitle_24hour',
  SALES_CHANNELS_AIREADY_TITLE_DATA_24HOUR = 'react.pages.sales_channels.data.aiready_title_24hour',
  SALES_CHANNELS_AIREADY_SUB_TITLE_DATA_24HOUR = 'react.pages.sales_channels.data.aiready_subtitle_24hour',
  ANIMATED_LOADER_BUTTON_TEXT_AWESOME = 'react.animated_loader.button.text.awesome',

  EMPTY_STATES_COMPASS_COMPREHENSIVE_SALES_TITLE = 'react.empty_states.page.compass.proposition.comprehensive_sales.title',
  EMPTY_STATES_COMPASS_TRUE_MULTICHANNEL_TITLE = 'react.empty_states.page.compass.proposition.true_multichannel.title',
  EMPTY_STATES_COMPASS_CAMPAIGN_ANALYSIS_TITLE = 'react.empty_states.page.compass.proposition.campaign_analysis.title',

  EMPTY_STATES_COMPASS_COMPREHENSIVE_SALES_SUBTITLE = 'react.empty_states.page.compass.proposition.comprehensive_sales.subtitle',
  EMPTY_STATES_COMPASS_TRUE_MULTICHANNEL_SUBTITLE = 'react.empty_states.page.compass.proposition.true_multichannel.subtitle',
  EMPTY_STATES_COMPASS_CAMPAIGN_ANALYSIS_SUBTITLE = 'react.empty_states.page.compass.proposition.campaign_analysis.subtitle',

  EMPTY_STATES_ADS_MANAGER_PAGE_HEADER = 'react.empty_states.page.ads_manager.ads_manager.header',
  EMPTY_STATES_ADS_MANAGER_PAGE_SUBTITLE = 'react.empty_states.page.ads_manager.view_performance.subtitle',
  EMPTY_STATES_ADS_MANAGER_INTELLIGENT_AUTOMATION_TITLE = 'react.empty_states.page.ads_manager.proposition.intelligent_automation.title',
  EMPTY_STATES_ADS_MANAGER_EASY_MANAGEMENT_TITLE = 'react.empty_states.page.ads_manager.proposition.easy_management.title',
  EMPTY_STATES_ADS_MANAGER_TREND_OPTIMIZATION_TITLE = 'react.empty_states.page.ads_manager.proposition.trend_optimization.title',
  EMPTY_STATES_ADS_MANAGER_INTELLIGENT_AUTOMATION_SUBTITLE = 'react.empty_states.page.ads_manager.proposition.intelligent_automation.subtitle',
  EMPTY_STATES_ADS_MANAGER_EASY_MANAGEMENT_SUBTITLE = 'react.empty_states.page.ads_manager.proposition.easy_management.subtitle',
  EMPTY_STATES_ADS_MANAGER_TREND_OPTIMIZATION_SUBTITLE = 'react.empty_states.page.ads_manager.proposition.trend_optimization.subtitle',

  BILLING_TERM_FREE = 'react.billing.term.free',
  BILLING_TERM_TRIAL = 'react.billing.term.trial',
  BILLING_TITLE = 'react.billing.page.title',
  BILLING_HEADER_TITLE = 'react.billing.page.header.title',
  BILLING_FLYWHEEL_AI_TITLE = 'react.billing.pre_trial.flywheel_ai.title',
  BILLING_PRE_TRIAL_HEADER_TITLE = 'react.billing.pre_trial.page.header.title',
  BILLING_SUBTITLE_TITLE = 'react.billing.page.header.subtitle',
  BILLING_DOWNGRADE_TITLE = 'react.billing.page.header.downgraded.title',
  BILLING_DOWNGRADE_SUBTITLE = 'react.billing.page.header.downgraded.subtitle',
  BILLING_PRE_TRIAL_SUBTITLE_TITLE = 'react.billing.pre_trial.page.header.subtitle',
  BILLING_PRE_TRIAL_ENABLE_AI_BUTTON = 'react.billing.pre_trial.enable_ai_button',
  BILLING_SECTION_PLAN_DETAIL_TITLE = 'react.billing.page.billing_section.billing_plan_details.title',
  BILLING_SECTION_BILLING_DETAIL_TITLE = 'react.billing.page.billing_section.billing_payment_details.title',
  BILLING_SECTION_TRIAL_END = 'react.billing.page.billing_section.trial_end',
  BILLING_SECTION_TRIAL_AUTOMATIC_DOWNGRADE = 'react.billing.page.billing_section.trial_automatic_downgrade',
  BILLING_DETAIL_CARD_TITLE = 'react.billing.billing_plan_details.card.header.title',
  BILLING_DETAIL_CARD_SUBTITLE = 'react.billing.billing_plan_details.card.header.subtitle',
  BILLING_DETAIL_CARD_FOOTER_QUESTION = 'react.billing.billing_plan_details.card.footer.question',
  BILLING_DETAIL_CARD_FOOTER_LEARN_MORE = 'react.billing.billing_plan_details.card.footer.learn_more',
  BILLING_DETAIL_CARD_FOOTER_MESSAGE = 'react.billing.billing_plan_details.card.footer.message',
  BILLING_PAYMENT_DETAIL_CARD_TITLE = 'react.billing.billing_payment_details.card.header.title',
  BILLING_PAYMENT_DETAIL_CARD_DELINQUENT_TITLE = 'react.billing.billing_payment_details.card.header.delinquent_title',
  BILLING_PAYMENT_DETAIL_CARD_EXPIRY_DATE = 'react.billing.billing_payment_details.card.header.exp_date',
  BILLING_PAYMENT_DETAIL_CARD_SUBTITLE_ADD_DETAILS = 'react.billing.billing_payment_details.card.header.subtitle.add_payment_details',
  BILLING_PAYMENT_DETAIL_CARD_SUBTITLE_PAYMENT_DETAILS = 'react.billing.billing_payment_details.card.header.subtitle.payment_details',
  BILLING_PAYMENT_DETAIL_CARD_ADD_CARD_BUTTON = 'react.billing.billing_payment_details.card.add_card_button',
  BILLING_PAYMENT_DETAIL_CARD_SUBTITLE_SETUP_AUTOPAY = 'react.billing.billing_payment_details.card.header.subtitle.setup_autopay',
  BILLING_PAYMENT_DETAIL_CARD_BACK_BUTTON = 'react.billing.billing_payment_details.card.back_button',
  BILLING_PAYMENT_DETAIL_CARD_NEXT_BILLING_DATE_DETAILS = 'react.billing.billing_payment_details.card.next_billing_date_details',
  BILLING_PAYMENT_DETAIL_CARD_SUBTITLE_PAYMENT_ERROR_DETAILS = 'react.billing.billing_payment_details.card.header.subtitle.payment_error_details',
  BILLING_PAYMENT_DETAIL_CARD_OUTSTANDING_BILLING_DATE_DETAILS = 'react.billing.billing_payment_details.card.outstanding_billing_date_details',
  BILLING_PRE_TRIAL_PAYMENT_DETAIL_PRE_TRIAL_DETAILS = 'react.billing.pre_trial.billing_payment_details.pre_trial.details',
  BILLING_PRE_TRIAL_PAYMENT_DETAIL_PRE_TRIAL_LEARN_MORE = 'react.billing.pre_trial.billing_payment_details.pre_trial.learn_more',

  BILLING_SUBSCRIPTION_PAGE_TITLE = 'react.billing.subscription.page.title',
  BILLING_SUBSCRIPTION_SUBTITLE_TITLE = 'react.billing.subscription.page.header.subtitle',
  BILLING_SUBSCRIPTION_TAB_SUBSCRIPTION_TITLE = 'react.billing.subscription.page.tab.subscription.title',
  BILLING_SUBSCRIPTION_TAB_INVOICES_TITLE = 'react.billing.subscription.page.tab.invoices.title',
  BILLING_SUBSCRIPTION_TAB_PAYMENT_TITLE = 'react.billing.subscription.page.tab.payment.title',
  BILLING_SUBSCRIPTION_PLAN_CARD_YOUR_PLAN_TITLE = 'react.billing.subscription.page.plan_card.header.your_plan.title',
  BILLING_SUBSCRIPTION_PLAN_CARD_AD_MANAGEMENT_TITLE = 'react.billing.subscription.page.plan_card.header.ad_management.title',
  BILLING_SUBSCRIPTION_HEADER_ESTIMATED_AD_SPEND_TITLE = 'react.billing.subscription.page.header.estimated_ad_spend.title',
  BILLING_SUBSCRIPTION_INFO_TIP_MANAGED_PRICING_QUESTIONS_DESCRIPTION = 'react.billing.subscription.page.info_tip.managed_pricing_question.description',
  BILLING_SUBSCRIPTION_HEADER_FLYWHEEL_AI_TITLE = 'react.billing.subscription.page.header.flywheel_ai.title',
  BILLING_SUBSCRIPTION_HEADER_FLYWHEEL_AI_SUBTITLE = 'react.billing.subscription.page.header.flywheel_ai.subtitle',
  BILLING_SUBSCRIPTION_HEADER_AD_SPEND_COLUMN_HEADER = 'react.billing.subscription.page.header.ad_spend.column.header',
  BILLING_SUBSCRIPTION_ESTIMATED_AI_OPTIMIZATION_DESCRIPTION = 'react.billing.subscription.page.estimated_ai_optimization.description',
  BILLING_SUBSCRIPTION_HEADER_MARKET_INTELLIGENCE_TITLE = 'react.billing.subscription.page.header.market_intelligence.title',
  BILLING_SUBSCRIPTION_ESTIMATED_MARKET_INTELLIGENCE_DESCRIPTION = 'react.billing.subscription.page.estimated_mi_cost.description',
  BILLING_SUBSCRIPTION_SEE_DETAILS_DESCRIPTION = 'react.billing.subscription.page.see_details.description',
  BILLING_SUBSCRIPTION_BILLING_PERIOD_DESCRIPTION = 'react.billing.subscription.page.billing_period.description',

  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_TITLE = 'react.billing.subscription.billing_tiers_slide_out.title',
  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_CURRENT_MONTH_AD_SPEND = 'react.billing.subscription.billing_tiers_slide_out.current_month.ad_spend',
  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_PREVIOUS_MONTH_AD_SPEND = 'react.billing.subscription.billing_tiers_slide_out.previous_month.ad_spend',
  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_ANNUAL_TOGGLE_TITLE = 'react.billing.subscription.billing_tiers_slide_out.annual.toggle_title',
  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_MONTHLY_TOGGLE_TITLE = 'react.billing.subscription.billing_tiers_slide_out.monthly.toggle_title',
  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_TIER_1_TITLE = 'react.billing.subscription.billing_tiers_slide_out.tier_1.title',
  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_TIER_2_TITLE = 'react.billing.subscription.billing_tiers_slide_out.tier_2.title',
  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_TIER_3_TITLE = 'react.billing.subscription.billing_tiers_slide_out.tier_3.title',
  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_PER_MONTH_TITLE = 'react.billing.subscription.billing_tiers_slide_out.per_month.title',
  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_ANNUAL_BILLED_UPFRONT = 'react.billing.subscription.billing_tiers_slide_out.annual.billed_upfront',
  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_PERCENTAGE_OF_AD_SPEND = 'react.billing.subscription.billing_tiers_slide_out.3_percentage_of_ad_spend',
  BILLING_SUBSCRIPTION_BILLING_TIERS_SLIDE_OUT_DISCLAIMER = 'react.billing.subscription.billing_tiers_slide_out.disclaimer',

  BILLING_SUBSCRIPTION_TRACKED_TERMS_DESCRIPTION = 'react.billing.subscription.page.tracked_terms.description',
  BILLING_SUBSCRIPTION_TRACKED_TERMS_CURRENT_TOTAL_FEE = 'react.billing.subscription.page.tracked_terms.current_total_fee',
  BILLING_SUBSCRIPTION_TRACKED_TERMS_CALCULATION_DESCRIPTION = 'react.billing.subscription.page.tracked_terms.calculation_description',
  BILLING_SUBSCRIPTION_TRACKED_TERMS_FORMULA = 'react.billing.subscription.page.tracked_terms.formula',

  BILLING_SUBSCRIPTION_HEADER_AD_SPEND = 'react.billing.subscription.page.header.ad_spend',
  BILLING_SUBSCRIPTION_AD_SPEND_USAGE_DURING_CURRENT_PERIOD = 'react.billing.subscription.page.ad_spend.usage_from',
  BILLING_SUBSCRIPTION_AD_SPEND_CURRENT_SALES_CHANNEL_AD_SPEND = 'react.billing.subscription.page.ad_spend.sales_channel.current_ad_spend',
  BILLING_SUBSCRIPTION_AD_SPEND_CURRENT_TOTAL = 'react.billing.subscription.page.ad_spend.current_total',
  BILLING_SUBSCRIPTION_AD_SPEND_CURRENT_FEE = 'react.billing.subscription.page.ad_spend.current_fee',
  BILLING_SUBSCRIPTION_AD_SPEND_CALCULATION_DESCRIPTION = 'react.billing.subscription.page.ad_spend.calculation_description',
  BILLING_SUBSCRIPTION_AD_SPEND_FORMULA = 'react.billing.subscription.page.ad_spend.formula',

  BILLING_SUBSCRIPTION_MONTHLY_FEES_TITLE = 'react.billing.subscription.monthly_fees.title',
  BILLING_SUBSCRIPTION_MONTHLY_FEES_PLATFORM_FEE = 'react.billing.subscription.monthly_fees.platform_fee',
  BILLING_SUBSCRIPTION_MONTHLY_FEES_AD_SPEND_FEE = 'react.billing.subscription.monthly_fees.ad_spend_fee',
  BILLING_SUBSCRIPTION_MONTHLY_FEES_MI_FEE = 'react.billing.subscription.monthly_fees.market_intelligence_fee',
  BILLING_SUBSCRIPTION_MONTHLY_FEES_TOTAL_FEE = 'react.billing.subscription.monthly_fees.total_fee',
  BILLING_SUBSCRIPTION_NO_AD_SPEND_PILL_TEXT = 'react.billing.subscription.no_ad_spend.pill_text',

  BILLING_SUBSCRIPTION_CURRENT_USAGE_TITLE = 'react.billing.subscription.page.current_usage.title',
  BILLING_SUBSCRIPTION_CURRENT_USAGE_BILLING_LEFT_DAYS = 'react.billing.subscription.page.current_usage.billing_left_days',
  BILLING_SUBSCRIPTION_CURRENT_USAGE_AD_OPTIMIZATION_TITLE = 'react.billing.subscription.page.current_usage.ad_optimization.title',
  BILLING_SUBSCRIPTION_CURRENT_USAGE_MARKET_INTELLIGENCE_TITLE = 'react.billing.subscription.page.current_usage.market_intelligence.title',
  BILLING_SUBSCRIPTION_CURRENT_USAGE_HELP_TEXT = 'react.billing.subscription.page.current_usage.help_text',
  BILLING_SUBSCRIPTION_CURRENT_USAGE_CURRENT_AD_SPEND = 'react.billing.subscription.page.current_usage.current_ad_spend',
  BILLING_SUBSCRIPTION_CURRENT_USAGE_CURRENT_TRACKED_TERMS = 'react.billing.subscription.page.current_usage.current_tracked_terms',
  BILLING_SUBSCRIPTION_CURRENT_USAGE_TOTAL_AD_SPEND = 'react.billing.subscription.page.current_usage.total_ad_spend',
  BILLING_SUBSCRIPTION_CURRENT_USAGE_TOTAL_TERMS_TRACKED = 'react.billing.subscription.page.current_usage.total_terms_tracked',
  BILLING_SUBSCRIPTION_PLAN_PRICING_TITLE = 'react.billing.subscription.page.plan_pricing.title',
  BILLING_SUBSCRIPTION_PLAN_PRICING_BASIC_TITLE = 'react.billing.subscription.page.plan_pricing.basic.title',
  BILLING_SUBSCRIPTION_PLAN_PRICING_BASIC_PRICE = 'react.billing.subscription.page.plan_pricing.basic.price',
  BILLING_SUBSCRIPTION_PLAN_PRICING_AI_POWERED_INFO = 'react.billing.subscription.page.plan_pricing.ai_powered.info',
  BILLING_SUBSCRIPTION_PLAN_PRICING_AI_POWERED_MONTHLY_AD_SPEND = 'react.billing.subscription.page.plan_pricing.ai_powered.monthly_ad_spend',
  BILLING_SUBSCRIPTION_PLAN_PRICING_AI_POWERED_UNDER_5K_TITLE = 'react.billing.subscription.page.plan_pricing.ai_powered.under_5k.title',
  BILLING_SUBSCRIPTION_PLAN_PRICING_AI_POWERED_UNDER_5K_PRICE = 'react.billing.subscription.page.plan_pricing.ai_powered.under_5k.price',
  BILLING_SUBSCRIPTION_PLAN_PRICING_AI_POWERED_UNDER_5K_OFFER_PRICE = 'react.billing.subscription.page.plan_pricing.ai_powered.under_5k.offer.price',
  BILLING_SUBSCRIPTION_PLAN_PRICING_AI_POWERED_BETWEEN_5K_AND_10K_TITLE = 'react.billing.subscription.page.plan_pricing.ai_powered.between_5k_and_10k.title',
  BILLING_SUBSCRIPTION_PLAN_PRICING_AI_POWERED_BETWEEN_5K_AND_10K_PRICE = 'react.billing.subscription.page.plan_pricing.ai_powered.between_5k_and_10k.price',
  BILLING_SUBSCRIPTION_PLAN_PRICING_AI_POWERED_BETWEEN_10K_AND_OVER_TITLE = 'react.billing.subscription.page.plan_pricing.ai_powered.10k_and_over.title',
  BILLING_SUBSCRIPTION_PLAN_PRICING_AI_POWERED_BETWEEN_10K_AND_OVER_PRICE = 'react.billing.subscription.page.plan_pricing.ai_powered.10k_and_over.price',
  BILLING_SUBSCRIPTION_PLAN_PRICING_AI_POWERED_BETWEEN_10K_AND_OVER_ADDITIONAL_INFO = 'react.billing.subscription.page.plan_pricing.ai_powered.10k_and_over.additional_info',

  BILLING_SUBSCRIPTION_START_RISK_FREE_TRIAL_TITLE = 'react.billing.subscription.page.start_risk_free_trial.title',

  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_HEADER_PAY_AS_GROW_TITLE = 'react.billing.self_service_subscription.page.header.pay_as_grow.title',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_HEADER_PAY_AS_GROW_SUBTITLE = 'react.billing.self_service_subscription.page.header.pay_as_grow.subtitle',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_HEADER_PAY_AS_GROW_TITLE_NEW = 'react.billing.self_service_subscription.page.header.pay_as_grow.title_new',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_HEADER_PAY_AS_GROW_SUBTITLE_NEW = 'react.billing.self_service_subscription.page.header.pay_as_grow.subtitle_new',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_HEADER_NO_SUBSCRIPTION_TAB_SUBTITLE = 'react.billing.self_service_subscription.page.header.no_subscription_tab.subtitle',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_SELF_SERVICE_ESSESNTIALS = 'react.billing.self_service_subscription.page.self_service_essentials',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_SELF_SERVICE_ESSESNTIALS_SUB_TEXT = 'react.billing.self_service_subscription.page.self_service_essentials.sub_text',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_SWITCH_TO_ANNUAL_TEXT = 'react.billing.self_service_subscription.page.free_trial_running.switch_to_annual_text',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_SWITCH_TO_ANNUAL_BUTTON = 'react.billing.self_service_subscription.page.switch_to_annual_button',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_CANCEL_SUBSCRIPTION = 'react.billing.self_service_subscription.page.cancel_subscription',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_CANCEL_SUBSCRIPTION_BODY = 'react.billing.self_service_subscription.page.cancel_subscription_body',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_FREE_TRIAL_DAYS_LEFT = 'react.billing.self_service_subscription.page.free_trial_days_left',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_UPGRADE_FROM_BASIC = 'react.billing.self_service_subscription.page.upgrade_from_basic',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_BASIC = 'react.billing.self_service_subscription.page.basic',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_START_TRIAL = 'react.billing.self_service_subscription.page.start_trial',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_NO_AD_SPEND = 'react.billing.self_service_subscription.page.no_ad_spend',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_UNDER_5K = 'react.billing.self_service_subscription.page.under_5k',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_10K_AND_OVER = 'react.billing.self_service_subscription.page.10k_and_over',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_BETWEEN_5K_AND_10K = 'react.billing.self_service_subscription.page.between_5k_and_10k',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_NEXT_PAYMENT = 'react.billing.self_service_subscription.page.next_payment',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_FREE_TRIAL_ENDED_SWITCH_TO_ANNUAL_TEXT = 'react.billing.self_service_subscription.page.free_trial_ended.switch_to_annual_text',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_RENEW_SUBSCRIPTION = 'react.billing.self_service_subscription.page.renew_subscription',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_SUBSCRIPTION_END_DATE = 'react.billing.self_service_subscription.page.subscription_end_date',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_SUBSCRIPTION_CANCELLED_INFO = 'react.billing.self_service_subscription.page.subscription_cancelled_info',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_RENEW_YOUR_SUBSCRIPTION = 'react.billing.self_service_subscription.page.renew_your_subscription',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_PLAN_UPDATE_SUCCESS = 'react.billing.self_service_subscription.page.plan_update_success',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_PLAN_UPDATE_INFO = 'react.billing.self_service_subscription.page.plan_update_info',

  BILLING_SELF_SERVICE_SUBSCRIPTION_PLAN_CARD_AI_POWERED_TITLE = 'react.billing.self_service_subscription.page.plan_card.header.ai_powered.title',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PLAN_CARD_BASIC_TITLE = 'react.billing.self_service_subscription.page.plan_card.header.basic.title',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PLAN_CARD_FREE_TRIAL_DESCRIPTION = 'react.billing.self_service_subscription.page.plan_card.free_trial.description',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PLAN_CARD_CURRENT_MONTHLY_GMS_TITLE = 'react.billing.self_service_subscription.page.plan_card.header.current_monthly_gms.title',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PLAN_CARD_ELIGIBLE_FREEMIUM_DESCRIPTION = 'react.billing.self_service_subscription.page.plan_card.header.eligible_freemium.description',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PLAN_CARD_MANAGE_SUBSCRIPTION_DESCRIPTION = 'react.billing.self_service_subscription.page.plan_card.header.manage_subscription.description',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PLAN_CARD_INITIATE_DOWNGRADE_DESCRIPTION = 'react.billing.self_service_subscription.page.plan_card.header.initiate_downgrade.description',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PLAN_CARD_ESTIMATED_TOTAL_COST_DESCRIPTION = 'react.billing.self_service_subscription.page.plan_card.header.estimated_total_cost.description',
  BILLING_SELF_SERVICE_SUBSCRIPTION_PLAN_CARD_YOUR_COST_DESCRIPTION = 'react.billing.self_service_subscription.page.plan_card.header.your_cost.title',
  BILLING_SUBSCRIPTION_USAGE_DETAILS_MODAL_HEADER = 'react.billing.subscription.usage_details_modal.header',
  BILLING_SUBSCRIPTION_USAGE_DETAILS_MODAL_TITLE = 'react.billing.subscription.usage_details_modal.title',
  BILLING_SUBSCRIPTION_USAGE_DETAILS_MODAL_AD_SPEND_USAGE = 'react.billing.subscription.usage_details_modal.ad_spend_usage',

  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_HEADER = 'react.billing.subscription.manage_subscription_modal.header',
  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_PLAN_CARD_CONNECT_AND_ANALYZE_DESCRIPTION = 'react.billing.subscription.manage_subscription_modal.plan_card.connect_and_analyze.description',
  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_PLAN_CARD_DOWNGRADE_WILL_DESCRIPTION = 'react.billing.subscription.manage_subscription_modal.plan_card.downgrade_will.description',
  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_PLAN_CARD_AI_POWERED_BIDDING_DESCRIPTION = 'react.billing.subscription.manage_subscription_modal.plan_card.ai_powered_bidding.description',
  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_PLAN_CARD_AUTOMATED_TARGETING_DESCRIPTION = 'react.billing.subscription.manage_subscription_modal.plan_card.automated_targeting.description',
  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_PLAN_CARD_YOU_WILL_BE_RESPONSIBLE_DESCRIPTION = 'react.billing.subscription.manage_subscription_modal.plan_card.you_will_be_responsible.description',

  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_PLAN_CARD_AUTOMATE_AND_OPTIMIZE_DESCRIPTION = 'react.billing.subscription.manage_subscription_modal.plan_card.automate_and_optimize.description',
  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_PLAN_CARD_AUTOMATE_AND_OPTIMIZE_PILL_FREE_TRIAL_ENDED = 'react.billing.subscription.manage_subscription_modal.plan_card.automate_and_optimize.pill.free_trial_ended',
  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_PLAN_CARD_AUTOMATE_AND_OPTIMIZE_PILL_BEGIN_FREE_TRIAL = 'react.billing.subscription.manage_subscription_modal.plan_card.automate_and_optimize.pill.begin_free_trial',
  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_DOWNGRADE_BUTTON_DESCRIPTION = 'react.billing.subscription.manage_subscription_modal.downgrade.button.description',
  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_UPGRADE_BUTTON_DESCRIPTION = 'react.billing.subscription.manage_subscription_modal.upgrade.button.description',

  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_CANCEL_BUTTON_DESCRIPTION = 'react.billing.subscription.manage_subscription_modal.cancel.button.description',
  BILLING_SUBSCRIPTION_MANAGE_SUBSCRIPTION_MODAL_APPLY_BUTTON_DESCRIPTION = 'react.billing.subscription.manage_subscription_modal.apply.button.description',

  BILLING_INVOICES_PAGE_BILLING_HISTORY_HEADER = 'react.billing.invoices.page.billing_history.header',
  BILLING_INVOICES_TABLE_DATE_HEADER = 'react.billing.invoices.page.invoices_table.date.header',
  BILLING_INVOICES_TABLE_INVOICE_ID_HEADER = 'react.billing.invoices.page.invoices_table.invoice_id.header',
  BILLING_INVOICES_TABLE_STATUS_HEADER = 'react.billing.invoices.page.invoices_table.status.header',
  BILLING_INVOICES_TABLE_TOTAL_HEADER = 'react.billing.invoices.page.invoices_table.total.header',
  BILLING_INVOICES_TABLE_EMPTY_HEADER = 'react.billing.invoices.page.invoices_table.empty.header',
  BILLING_INVOICES_LOOKING_FOR_INVOICES_DESCRIPTION = 'react.billing.invoices.page.looking_for_invoices.description',
  BILLING_INVOICES_HERE_DESCRIPTION = 'react.billing.invoices.page.here.description',
  BILLING_INVOICES_CONTACT_CUSTOMER_SUPPORT_DESCRIPTION = 'react.billing.invoices.page.contact_customer_support.description',
  BILLING_INVOICES_VIEW_PDF_DESCRIPTION = 'react.billing.invoices.page.invoices_table.view_pdf.description',
  BILLING_INVOICES_TABLE_NO_DATA_DESCRIPTION = 'react.billing.invoices.page.invoices_table.no_data_description',

  BILLING_PAYMENT_PAGE_BILLING_DETAILS_HEADER = 'react.billing.payment.page.billing_details.header',
  BILLING_PAYMENT_PAGE_BILLING_ADDRESS_TITLE = 'react.billing.payment.page.billing_address.title',
  BILLING_PAYMENT_PAGE_BUTTON_EDIT_BILLING_DETAILS = 'react.billing.payment.page.button.edit_payment_details',

  BILLING_PAYMENT_ZUORA_ERROR_CARD_TITLE = 'react.billing.billing_payment_zuora_error.card.header.title',
  BILLING_PAYMENT_ZUORA_ERROR_CARD_SUBTITLE = 'react.billing.billing_payment_zuora_error.card.header.subtitle',
  BILLING_PAYMENT_ZUORA_ERROR_CARD_SOMETHING_WENT_WRONG_TITLE = 'react.billing.billing_payment_zuora_error.card.header.title.something_went_wrong',
  BILLING_PAYMENT_ZUORA_ERROR_CARD_ERROR_PAYMENT_GATEWAY_SUBTITLE = 'react.billing.billing_payment_zuora_error.card.header.subtitle.error_payment_gateway',
  BILLING_PAYMENT_ZUORA_ERROR_CARD_BUTTON_TITLE_RETRY = 'react.billing.billing_payment_zuora_error.card.button.title.retry',
  BILLING_PAYMENT_ZUORA_ERROR_CARD_BUTTON_TITLE_CONTACT_SUPPORT = 'react.billing.billing_payment_zuora_error.card.button.title.contact_support',

  BILLING_ESTIMATE_CARD_TITLE = 'react.billing.billing_estimate.card.header.title',
  BILLING_ESTIMATE_CARD_ADVERTISING_OPTIMIZATION_TITLE = 'react.billing.billing_estimate.card.advertising_optimization.title',
  BILLING_ESTIMATE_CARD_MARKET_INTELLIGENCE_TITLE = 'react.billing.billing_estimate.card.market_intelligence.title',
  BILLING_ESTIMATE_CARD_ESTIMATE_TOTAL_TITLE = 'react.billing.billing_estimate.card.estimated_total.title',
  BILLING_ESTIMATE_CARD_TOTAL_AP_SPEND_DESCRIPTION = 'react.billing.billing_estimate.card.total_ad_spend.description',
  BILLING_ESTIMATE_CARD_AP_SPEND_MERCHANTS_DESCRIPTION = 'react.billing.billing_estimate.card.ad_spend_merchants.description',
  BILLING_ESTIMATE_CARD_TOTAL_TRACK_TERMS_DESCRIPTION = 'react.billing.billing_estimate.card.total_tracked_terms.description',
  BILLING_ESTIMATE_CARD_PER_TRACKED_TERMS_DESCRIPTION = 'react.billing.billing_estimate.card.per_tracked_term.description',
  BILLING_ESTIMATE_CARD_LAST_UPDATED_DESCRIPTION = 'react.billing.billing_estimate.card.last_updated.description',

  BILLING_DOWNGRADE_PLAN_TITLE = 'react.billing.downgrade_plan.title',
  BILLING_DOWNGRADE_PLAN_BODY = 'react.billing.downgrade_plan.body',
  BILLING_DOWNGRADE_PLAN_LIST_HEADER = 'react.billing.downgrade_plan.list_header',
  BILLING_DOWNGRADE_PLAN_NOTE = 'react.billing.downgrade_plan.note',
  BILLING_DOWNGRADE_PLAN_NON_TRIAL_NOTE_BOLD = 'react.billing.downgrade_plan.non_trial.note_bold',
  BILLING_DOWNGRADE_PLAN_NON_TRIAL_NOTE = 'react.billing.downgrade_plan.non_trial.note',
  TERMS_DOWNGRADE = 'terms.downgrade',

  BILLING_UPGRADE_PLAN_TRIAL_TITLE = 'react.billing.upgrade_plan.trial.title',
  BILLING_UPGRADE_PLAN_NON_TRIAL_TITLE = 'react.billing.upgrade_plan.non_trial.title',
  BILLING_UPGRADE_PLAN_BODY = 'react.billing.upgrade_plan.body',
  BILLING_UPGRADE_PLAN_LIST_HEADER = 'react.billing.upgrade_plan.list_header',
  BILLING_UPGRADE_PLAN_DESCRIPTION = 'react.billing.upgrade_plan.plan_description',
  BILLING_UPGRADE_PLAN_NON_TRIAL_NOTE = 'react.billing.upgrade_plan.non_trial.note',
  BILLING_UPGRADE_PLAN_TRIAL_NOTE_BOLD = 'react.billing.upgrade_plan.trial.note_bold',
  BILLING_UPGRADE_PLAN_TRIAL_NOTE = 'react.billing.upgrade_plan.trial.note',
  BILLING_UPGRADE_PLAN_START_TRIAL_BUTTON = 'react.billing.upgrade_plan.start_trial_button',
  BILLING_UPGRADE_PLAN_NON_TRIAL_BUTTON = 'react.billing.upgrade_plan.non_trial.button',

  UPGRADE_HAPPENED_TITLE = 'react.upgrade_happened.title',
  UPGRADE_HAPPENED_BODY = 'react.upgrade_happened.body',
  UPGRADE_HAPPENED_NOTE_TITLE = 'react.upgrade_happened.note.title',
  UPGRADE_HAPPENED_NOTE_BODY = 'react.upgrade_happened.note.body',
  UPGRADE_HAPPENED_BUTTON = 'react.upgrade_happened.button',
  UPGRADE_HAPPENED_BODY_LINK = 'react.upgrade_happened.body.link',

  TRIAL_ENDED_METRICS_TITLE = 'react.trial_ended.metrics.title',
  TRIAL_ENDED_METRICS_TIME_PERIOD = 'react.trial_ended.metrics.time_period',
  TRIAL_ENDED_METRICS_LARGE_1 = 'react.trial_ended.metrics.large_1',
  TRIAL_ENDED_METRICS_LARGE_2 = 'react.trial_ended.metrics.large_2',
  TRIAL_ENDED_METRICS_SMALL_1 = 'react.trial_ended.metrics.small_1',
  TRIAL_ENDED_METRICS_SMALL_2 = 'react.trial_ended.metrics.small_2',
  TRIAL_ENDED_METRICS_SMALL_3 = 'react.trial_ended.metrics.small_3',
  TRIAL_ENDED_NOTE = 'react.trial_ended.note',

  TRIAL_ENDED_MODAL_TITLE = 'react.trial_ended.title',
  TRIAL_ENDED_MODAL_BODY = 'react.trial_ended.body',

  // TODO: remove old trial_end modal copy
  TRIAL_ENDED_MODAL_LIST_HEADER = 'react.trial_ended.list_header',
  TRIAL_ENDED_MODAL_SECONDARY_BUTTON = 'react.trial_ended.secondary_button',
  TRIAL_ENDED_MODAL_PRIMARY_BUTTON = 'react.trial_ended.primary_button',

  TRIAL_ENDED_ADD_PAYMENT_TITLE = 'react.trial_ended.add_payment.title',
  TRIAL_ENDED_ADD_PAYMENT_REMINDER_TITLE = 'react.trial_ended.add_payment.reminder.title',
  TRIAL_ENDED_ADD_PAYMENT_BODY = 'react.trial_ended.add_payment.body',
  TRIAL_ENDED_ADD_PAYMENT_REMINDER_BODY = 'react.trial_ended.add_payment.reminder.body',
  TRIAL_ENDED_ADD_PAYMENT_PILL = 'react.trial_ended.add_payment.pill',
  TRIAL_ENDED_ADD_PAYMENT_NOTE = 'react.trial_ended.add_payment.note',

  TRIAL_ENDED_SUCCESSFUL_PAYMENT_TITLE = 'react.trial_ended.successful_payment.title',
  TRIAL_ENDED_SUCCESSFUL_PAYMENT_BODY = 'react.trial_ended.successful_payment.body',
  TRIAL_ENDED_SUCCESSFUL_PAYMENT_METERING_STARTED = 'react.trial_ended.successful_payment.metering_started',
  TRIAL_ENDED_SUCCESSFUL_PAYMENT_BUTTON = 'react.trial_ended.successful_payment.button',

  TRIAL_AUTO_STARTED_MODAL_TITLE = 'react.trial_auto_started_modal.title',
  TRIAL_AUTO_STARTED_MODAL_BODY = 'react.trial_auto_started_modal.body',
  TRIAL_AUTO_STARTED_MODAL_BUTTON = 'react.trial_auto_started_modal.button',

  SUBSCRIPTION_ENDED_MODAL_TITLE = 'react.subscription_ended_modal.title',
  SUBSCRIPTION_ENDED_MODAL_LEARN_MORE_LINK = 'react.subscription_ended_modal.learn_more_link',
  SUBSCRIPTION_ENDED_MODAL_SUBTITLE = 'react.subscription_ended_modal.subtitle',
  SUBSCRIPTION_ENDED_MODAL_ADD_CARD_BUTTON = 'react.subscription_ended_modal.add_card_button',

  BILLING_PLAN_FEATURES_BIDDER = 'react.billing.plan_features.bidder',
  BILLING_PLAN_FEATURES_TRE = 'react.billing.plan_features.tre',
  BILLING_PLAN_FEATURES_CAMPAIGN_ORGANIZER = 'react.billing.plan_features.campaign_organizer',

  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_IMPROVE = 'react.billing.risk_free_trial.modal.improve',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_ROAS = 'react.billing.risk_free_trial.modal.roas',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BY_AVERAGE = 'react.billing.risk_free_trial.modal.by_average',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_TITLE = 'react.billing.risk_free_trial.modal.title',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_SUBTITLE = 'react.billing.risk_free_trial.modal.sub_title',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BREAKDOWN_HEADLINE = 'react.billing.risk_free_trial.modal.breakdown.headline',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BREAKDOWN_HEADER_MONTHLY_AD_SPEND = 'react.billing.risk_free_trial.modal.breakdown.header.monthly_ad_spend',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BREAKDOWN_HEADER_MONTHLY_PRICE = 'react.billing.risk_free_trial.modal.breakdown.header.monthly_price',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_PRICING_BELOW_5K = 'react.billing.risk_free_trial.modal.pricing.below_5k',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_PRICING_BETWEEN_5K_10K = 'react.billing.risk_free_trial.modal.pricing.between_5k_10k',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_PRICING_OVER_10K = 'react.billing.risk_free_trial.modal.pricing.over_10k',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_PRICING_OVER_10K_EXTRA = 'react.billing.risk_free_trial.modal.pricing.over_10k_extra',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_RESULT_DESCRIPTION = 'react.billing.risk_free_trial.modal.result.description',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_NO_THANKS_DESCRIPTION = 'react.billing.risk_free_trial.modal.no_thanks.description',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_ADD_PAYMENT_DESCRIPTION = 'react.billing.risk_free_trial.modal.add_payment.description',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_TRY_AGAIN_DESCRIPTION = 'react.billing.risk_free_trial.modal.try_again.description',

  RISK_FREE_TRIAL_PILL_START_RISK_FREE_DESCRIPTION = 'react.risk_free_trial.pill.start_risk_free.description',

  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_CONGRATULATIONS_TITLE = 'react.billing.risk_free_trial.modal.congrats.title',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_RISK_FREE_STARTED_TITLE = 'react.billing.risk_free_trial.modal.risk_free_started.title',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_RISK_GET_STARTED_DESCRIPTION = 'react.billing.risk_free_trial.modal.get_started.description',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BANNER_TITLE = 'react.billing.risk_free_trial.modal.get_started.banner.title',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BANNER_TITLE_DESCRIPTION = 'react.billing.risk_free_trial.modal.get_started.banner.title.description',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BANNER_TITLE_OFFER = 'react.billing.risk_free_trial.modal.get_started.banner.title.offer',

  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_GET_STARTED_BUTTON = 'react.billing.risk_free_trial.modal.billing_option.get_started.button',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_DUE_TODAY = 'react.billing.risk_free_trial.modal.billing_option.due_today',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_ERROR = 'react.billing.risk_free_trial.modal.billing_option.error',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_HEPER_TEXT_1 = 'react.billing.risk_free_trial.modal.billing_option.helper_text1',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_HEPER_TEXT_2 = 'react.billing.risk_free_trial.modal.billing_option.helper_text2',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_AD_SPEND = 'react.billing.risk_free_trial.modal.billing_option.ad_spend',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_AD_SPEND_BELOW_5K = 'react.billing.risk_free_trial.modal.billing_option.ad_spend.below_5k',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_AD_SPEND_BETWEEN_5K_10K = 'react.billing.risk_free_trial.modal.billing_option.ad_spend.between_5k_10k',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_AD_SPEND_OVER_10K = 'react.billing.risk_free_trial.modal.billing_option.ad_spend.over_10k',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_PER_MONTH = 'react.billing.risk_free_trial.modal.billing_option.per_month',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_ANNUALLY_1 = 'react.billing.risk_free_trial.modal.billing_option.pay_annually_1',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_ANNUALLY_2 = 'react.billing.risk_free_trial.modal.billing_option.pay_annually_2',
  BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BILLING_OPTION_MONTHLY = 'react.billing.risk_free_trial.modal.billing_option.pay_monthly',

  BILLING_ACCOUNT_DELINQUENT_ENFORCED_TITLE = 'react.billing.delinquent.enforced.title',
  BILLING_ACCOUNT_DELINQUENT_ENFORCED_BODY = 'react.billing.delinquent.enforced.body',
  BILLING_ACCOUNT_DELINQUENT_ENFORCED_NOTE = 'react.billing.delinquent.enforced.note',
  BILLING_ACCOUNT_DELINQUENT_ENFORCED_LEARN_MORE = 'react.billing.delinquent.enforced.learn_more',
  BILLING_ACCOUNT_DELINQUENT_ENFORCED_BUTTON = 'react.billing.delinquent.enforced.button',
  BILLING_ACCOUNT_DELINQUENT_ENFORCED_ALERT_NOTE = 'react.billing.delinquent.enforced.alert.note',
  BILLING_ACCOUNT_DELINQUENT_ENFORCED_ALERT_NOTE_BOLD = 'react.billing.delinquent.enforced.alert.note.bold',

  BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_TITLE = 'react.billing.delinquent.cant_charge.title',
  BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_BODY = 'react.billing.delinquent.cant_charge.body',
  BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_NOTE = 'react.billing.delinquent.cant_charge.note',
  BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_LEARN_MORE = 'react.billing.delinquent.cant_charge.learn_more',
  BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_BUTTON = 'react.billing.delinquent.cant_charge.button',
  BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_ALERT_NOTE = 'react.billing.delinquent.cant_charge.alert.note',
  BILLING_ACCOUNT_DELINQUENT_CANT_CHARGE_ALERT_NOTE_BOLD = 'react.billing.delinquent.cant_charge.alert.note.bold',

  BILLING_ACCOUNT_LOCKED_OUT_MODAL_TITLE = 'react.billing.locked_out_modal.title',
  BILLING_ACCOUNT_LOCKED_OUT_MODAL_BODY = 'react.billing.locked_out_modal.body',
  BILLING_ACCOUNT_LOCKED_OUT_MODAL_NOTE = 'react.billing.locked_out_modal.note',
  BILLING_ACCOUNT_LOCKED_OUT_MODAL_LEARN_MORE = 'react.billing.locked_out_modal.learn_more',
  BILLING_ACCOUNT_LOCKED_OUT_MODAL_BUTTON = 'react.billing.locked_out_modal.button',
  BILLING_ACCOUNT_LOCKED_OUT_MODAL_ALERT_NOTE = 'react.billing.locked_out_modal.alert.note',
  BILLING_ACCOUNT_LOCKED_OUT_MODAL_ALERT_NOTE_BOLD = 'react.billing.locked_out_modal.alert.note.bold',

  BILLING_ACCOUNT_SLIDEOVER_PLATFORM_SUBSCRIPTION_TITLE = 'react.billing.slideover.platform_subscription.title',
  BILLING_ACCOUNT_SLIDEOVER_PLATFORM_SUBSCRIPTION_ANNUAL = 'react.billing.slideover.platform_subscription.annual',
  BILLING_ACCOUNT_SLIDEOVER_PLATFORM_SUBSCRIPTION_MONTHLY = 'react.billing.slideover.platform_subscription.monthly',
  BILLING_ACCOUNT_SLIDEOVER_PLATFORM_SUBSCRIPTION_ANNUAL_BEST_VALUE = 'react.billing.slideover.platform_subscription.annual.best_value',
  BILLING_ACCOUNT_SLIDEOVER_PLATFORM_SUBSCRIPTION_TIER_CHARGE_PER_MONTH = 'react.billing.slideover.platform_subscription.tier_charge_per_month',
  BILLING_ACCOUNT_SLIDEOVER_PLATFORM_SUBSCRIPTION_TIER_CHARGE_ANNUAL_UPFRONT = 'react.billing.slideover.platform_subscription.tier_charge_annual_upfront',
  BILLING_ACCOUNT_SLIDEOVER_PLATFORM_SUBSCRIPTION_MONTHLY_TERMS_AND_CONDITION = 'react.billing.slideover.platform_subscription.monthly.terms_&_conditions',
  BILLING_ACCOUNT_SLIDEOVER_PLATFORM_SUBSCRIPTION_ANNUAL_TERMS_AND_CONDITION_1 = 'react.billing.slideover.platform_subscription.annual.terms_&_conditions_1',
  BILLING_ACCOUNT_SLIDEOVER_PLATFORM_SUBSCRIPTION_ANNUAL_TERMS_AND_CONDITION_2 = 'react.billing.slideover.platform_subscription.annual.terms_&_conditions_2',
  BILLING_ACCOUNT_SLIDEOVER_PLATFORM_SUBSCRIPTION_ANNUAL_TERMS_AND_CONDITION_3 = 'react.billing.slideover.platform_subscription.annual.terms_&_conditions_3',
  BILLING_ACCOUNT_SLIDEOVER_PLATFORM_SUBSCRIPTION_ANNUAL_TERMS_AND_CONDITION_FOR_BASIC = 'react.billing.slideover.platform_subscription.annual.terms_&_conditions_for_basic',

  SALES_CHANNELS_TITLE = 'react.pages.sales_channels.title',
  SALES_CHANNELS_TABLE_TAB_WALMART = 'react.pages.sales_channels.table.tab.walmart',
  SALES_CHANNELS_TABLE_TAB_AMAZON = 'react.pages.sales_channels.table.tab.amazon',
  SALES_CHANNELS_ADD_CONNECTION_BUTTON = 'react.pages.sales_channels.add_connection.button',
  SALES_CHANNELS_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.pages.sales_channels.table.footer.total_items_text',
  ACCOUNT_SALES_CHANNELS_TABLE_DUPLICATE_NAME_TEXT = 'react.account.sales_channels.table.duplicate_name.text',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_PROCESSING = 'react.account.sales_channels.column_ad.status.processing',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_TOOLTIP_PROCESSING = 'react.account.sales_channels.column_ad.tooltip.processing',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_SYNCING = 'react.account.sales_channels.column_ad.status.syncing',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_TOOLTIP_SYNCING = 'react.account.sales_channels.column_ad.tooltip.syncing',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_CONNECTED = 'react.account.sales_channels.column_ad.status.connected',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_LATEST_DATA_AVAILABLE = 'react.account.sales_channels.column_ad.status.latest_data_available',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_NO_DATA = 'react.account.sales_channels.column_ad.status.no_data',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_DATA_BEHIND = 'react.account.sales_channels.column_ad.status.data_behind',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_TOOLTIP_CONNECTED = 'react.account.sales_channels.column_ad.tooltip.connected',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_CONNECTED_NO_DATA = 'react.account.sales_channels.column_ad.status.connected_no_data',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_TOOLTIP_CONNECTED_NO_DATA = 'react.account.sales_channels.column_ad.tooltip.connected_no_data',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_NOT_CONNECTED = 'react.account.sales_channels.column_ad.status.not_connected',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_TOOLTIP_NOT_CONNECTED = 'react.account.sales_channels.column_ad.tooltip.not_connected',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_FAILED = 'react.account.sales_channels.column_ad.status.failed',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_TOOLTIP_FAILED = 'react.account.sales_channels.column_ad.tooltip.failed',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_NAME_TOOLTIP_TEXT = 'react.account.sales_channels.column_name.tooltip.text',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_NAME_DISABLE_TOOLTIP_TEXT = 'react.account.sales_channels.column_name.disabled_tooltip.text',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_NAME_BUTTON_TEXT = 'react.account.sales_channels.column_name.button.text',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_TYPE_SELLER = 'react.account.sales_channels.column_type.seller',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_TYPE_VENDOR = 'react.account.sales_channels.column_type.vendor',
  ACCOUNT_SALES_CHANNELS_NO_CONNECTIONS_TABLE_AMAZON_TITLE = 'react.account.sales_channels.no_connections_table.amazon.title',
  ACCOUNT_SALES_CHANNELS_NO_CONNECTIONS_TABLE_WALMART_TITLE = 'react.account.sales_channels.no_connections_table.walmart.title',
  ACCOUNT_SALES_CHANNELS_NO_CONNECTIONS_TABLE_DESCRIPTION = 'react.account.sales_channels.no_connections_table.description',
  ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_NAME = 'react.account.sales_channels.header.column_name',
  ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_SELLER_ID = 'react.account.sales_channels.header.column_seller_id',
  ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_AD = 'react.account.sales_channels.header.column_ad',
  ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_PRODUCT = 'react.account.sales_channels.header.column_product',
  ACCOUNT_SALES_CHANNELS_GROUPING_HEADER_MERCHANT = 'react.account.sales_channels.grouping_header_merchant',
  ACCOUNT_SALES_CHANNELS_GROUPING_HEADER_DATA_CONNECTION = 'react.account.sales_channels.grouping_header_data_connection',
  ACCOUNT_SALES_CHANNELS_TOAST_NAME_SUCCESS = 'react.account.sales_channels.toast.name.success',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_STATUS_LAST_SYNC = 'react.account.sales_channels.column_ad.status.last_sync',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_STATUS_LAST_SYNC_NEVER = 'react.account.sales_channels.column_ad.status.last_sync_never',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_STATUS_INITIAL_SYNC = 'react.account.sales_channels.column_ad.status.initial_sync',
  ACCOUNT_SALES_CHANNELS_TABLE_COLUMN_AD_STATUS_SYNC_FAILED = 'react.account.sales_channels.column_ad.status.sync_failed',

  ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_AI = 'react.account.sales_channels.header.column_ai',
  ACCOUNT_SALES_CHANNELS_GROUPING_HEADER_AUTOMATION = 'react.account.sales_channels.grouping_header_automation',
  ACCOUNT_SALES_CHANNELS_TABLE_AUTOMATION_COUNT = 'react.account.sales_channels.table.automation_count',
  ACCOUNT_SALES_CHANNELS_TOAST_AI_HEADER = 'react.account.sales_channels.toast.ai.header',
  ACCOUNT_SALES_CHANNELS_TOAST_AI_ENABLED = 'react.account.sales_channels.toast.ai.enabled',
  ACCOUNT_SALES_CHANNELS_TOAST_AI_DISABLED = 'react.account.sales_channels.toast.ai.disabled',
  ACCOUNT_SALES_CHANNELS_TOAST_AI_ENABLED_DESCRIPTION = 'react.account.sales_channels.toast.ai.enabled.description',
  ACCOUNT_SALES_CHANNELS_TOAST_AI_DISABLED_DESCRIPTION = 'react.account.sales_channels.toast.ai.disabled.description',

  ACCOUNT_SALES_CHANNELS_AI_DISABLE_AI = 'react.account.sales_channels.ai.disable_ai',
  ACCOUNT_SALES_CHANNELS_AI_LIVE_AUTOMATIONS = 'react.account.sales_channels.ai.live_automations',
  ACCOUNT_SALES_CHANNELS_AI_DISABLING_WHAT = 'react.account.sales_channels.ai.what_disabling_does',
  ACCOUNT_SALES_CHANNELS_AI_PLAN_CHANGED = 'react.account.sales_channels.ai.plan_changed',
  ACCOUNT_SALES_CHANNELS_AI_PLAN_CHANGED_BODY = 'react.account.sales_channels.ai.plan_changed_body',
  ACCOUNT_SALES_CHANNELS_AI_PLAN_CHANGED_DETAILS = 'react.account.sales_channels.ai.plan_changed_details',

  ACCOUNT_SALES_CHANNELS_AI_ENABLE_AI = 'react.account.sales_channels.ai.enable_ai',
  ACCOUNT_SALES_CHANNELS_AI_MERCHANT_POWERED = 'react.account.sales_channels.ai.merchant_powered',
  ACCOUNT_SALES_CHANNELS_AI_ENABLING_WHAT = 'react.account.sales_channels.ai.what_enabling_does',
  ACCOUNT_SALES_CHANNELS_AI_SET_MAC = 'react.account.sales_channels.ai.plan_changed',
  ACCOUNT_SALES_CHANNELS_AI_PLAN_FUEL = 'react.account.sales_channels.ai.plan_fuel',
  ACCOUNT_SALES_CHANNELS_AI_PLAN_SET_AD_MACS = 'react.account.sales_channels.ai.set_ad_macs',

  ACCOUNT_AD_PRODUCT_MODAL_HEADER = 'react.account.ad_product_modal.header',
  ACCOUNT_AD_PRODUCT_MODAL_FOOTER = 'react.account.ad_product_modal.footer',

  ERROR_HEADLINE = 'react.general.error_headline',
  ERROR_MESSAGE = 'react.general.error_message',
  ACCOUNT_SALES_CHANNELS_ADD_NAME_PLACEHOLDER = 'react.account.sales_channels.add.name.placeholder',
  MARKET_INTELLIGENCE_PAGE_HEADER_REPORTING = 'react.market_intelligence.page_header.reporting',
  MARKET_INTELLIGENCE_PAGE_HEADER_SETTINGS = 'react.market_intelligence.page_header.settings',
  MARKET_INTELLIGENCE_PAGE_HEADER_MARKET_INSIGHTS = 'react.market_intelligence.page_header.market_insights',
  MARKET_INTELLIGENCE_SIDEBAR_HEADER_MARKET_INSIGHTS = 'react.market_intelligence.sidebar_header.market_insights',
  MARKET_INTELLIGENCE_PAGE_HEADER_PRODUCT_MARKET_SHARE = 'react.market_intelligence.page_header.product_market_share',
  MARKET_INTELLIGENCE_LEARN_MORE = 'react.market_intelligence.learn_more',
  MARKET_INTELLIGENCE_ADD_TERMS = 'react.market_intelligence.add_terms',
  MARKET_INTELLIGENCE_CLEAR_SEARCH = 'react.market_intelligence.clear_search',
  MARKET_INTELLIGENCE_TRACKING_NO_TERMS = 'react.market_intelligence.tracking_no_terms',
  MARKET_INTELLIGENCE_ERROR_GENERIC = 'react.market_intelligence.error.generic',
  MARKET_INTELLIGENCE_NOTIFICATION_FILE_DOWNLOAD = 'react.market_intelligence.notification.file_download',
  MARKET_INTELLIGENCE_NOTIFICATION_BULK_EXPORT = 'react.market_intelligence.notification.bulk_export',
  MARKET_INTELLIGENCE_NOTIFICATION_BACKGROUND_JOB = 'react.market_intelligence.notification.background_job',
  MARKET_INTELLIGENCE_NOTIFICATION_BACKGROUND_JOB_STATUS_LOADING = 'react.market_intelligence.notification.background_job_status_loading',
  MARKET_INTELLIGENCE_NOTIFICATION_TERM_DELETED_SUCCESS = 'react.market_intelligence.notification.term_deleted_success',
  MARKET_INTELLIGENCE_NOTIFICATION_SEARCH_TERM_SAVED_SUCCESS = 'react.market_intelligence.notification.search_terms_saved_success',
  MARKET_INTELLIGENCE_NOTIFICATION_SEARCH_TERM_SAVED_SUCCESS_IGNORE = 'react.market_intelligence.notification.search_terms_saved_success_ignore',
  MARKET_INTELLIGENCE_NOTIFICATION_SEARCH_TERM_SAVED_SUCCESS_MANUAL = 'react.market_intelligence.notification.search_terms_saved_success_manual',
  MARKET_INTELLIGENCE_NOTIFICATION_SEARCH_TERM_SAVED_SUCCESS_MANUAL_WITH_EXISTING_TERMS = 'react.market_intelligence.notification.search_terms_saved_success_manual_with_existing_terms',
  MARKET_INTELLIGENCE_NOTIFICATION_ADD_TERMS_AND_GROUP_ERROR = 'react.market_intelligence.notification.add_terms_and_group_error',
  MARKET_INTELLIGENCE_NOTIFICATION_DUPLICATE_GROUP_ERROR = 'react.market_intelligence.notification.group_duplicate_error',
  MARKET_INTELLIGENCE_NOTIFICATION_EMPTY_GROUP_NAME_ERROR = 'react.market_intelligence.notification.empty_group_name_error',
  MARKET_INTELLIGENCE_NOTIFICATION_GROUP_ADD_TERMS_SUCCESS = 'react.market_intelligence.notification.group_add_terms_success',
  MARKET_INTELLIGENCE_NOTIFICATION_GROUP_REMOVE_TERMS_SUCCESS = 'react.market_intelligence.notification.group_remove_terms_success',
  MARKET_INTELLIGENCE_NOTIFICATION_GROUP_CREATE_SUCCESS = 'react.market_intelligence.notification.group_create_success',
  MARKET_INTELLIGENCE_NOTIFICATION_GROUP_UPDATE_SUCCESS = 'react.market_intelligence.notification.group_update_success',
  MARKET_INTELLIGENCE_NOTIFICATION_GROUP_DELETE_SUCCESS = 'react.market_intelligence.notification.group_delete_success',
  MARKET_INTELLIGENCE_NOTIFICATION_GROUP_CREATE_ERROR = 'react.market_intelligence.notification.group_create_error',
  MARKET_INTELLIGENCE_NOTIFICATION_GROUP_ADD_TERMS_TO_GROUP_ERROR = 'react.market_intelligence.notification.add_terms_to_group_error',
  MARKET_INTELLIGENCE_NOTIFICATION_GROUP_DELETE_ERROR = 'react.market_intelligence.notification.group_delete_error',
  MARKET_INTELLIGENCE_NOTIFICATION_REMOVE_TERMS_FROM_GROUP_ERROR = 'react.market_intelligence.notification.remove_terms_from_group_error',
  MARKET_INTELLIGENCE_NOTIFICATION_GROUP_DELETE_TERMS_ERROR = 'react.market_intelligence.notification.group_delete_terms_error',
  MARKET_INTELLIGENCE_NOTIFICATION_GROUP_FETCH_ERROR = 'react.market_intelligence.notification.group_fetch_error',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_TERMS_FETCHING_MSG = 'react.market_intelligence.reporting.no_data_error.terms_fetching_message',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_TERMS_FETCHING_DESC = 'react.market_intelligence.reporting.no_data_error.terms_fetching_description',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_DATA_FETCH_MESSAGE = 'react.market_intelligence.reporting.no_data_error.data_fetch_message',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_DATA_FETCH_DESC = 'react.market_intelligence.reporting.no_data_error.data_fetch_description',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_HAVING_ISSUES = 'react.market_intelligence.reporting.no_data_error.having_issues',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_GET_IN_TOUCH = 'react.market_intelligence.reporting.no_data_error.get_in_touch',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_WITH_SUPPORT = 'react.market_intelligence.reporting.no_data_error.with_support',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_TIP = 'react.market_intelligence.reporting.no_data_error.tip',
  MARKET_INTELLIGENCE_REPORTING_NO_DATA_ERROR_CANT_GET_DATA = 'react.market_intelligence.reporting.no_data_error.cant_get_data',
  MARKET_INTELLIGENCE_BUTTON_RUN_QUERY = 'react.market_intelligence.button.run_query',
  MARKET_INTELLIGENCE_BUTTON_RUN_QUERY_BACKGROUND = 'react.market_intelligence.button.run_query_background',
  MARKET_INTELLIGENCE_BUTTON_RUN_QUERY_TOOLTIP = 'react.market_intelligence.button.run_query_tooltip',
  MARKET_INTELLIGENCE_BUTTON_RUN_QUERY_BACKGROUND_TOOLTIP = 'react.market_intelligence.button.run_query_background_tooltip',
  MARKET_INTELLIGENCE_BUTTON_RESET = 'react.market_intelligence.button.reset',
  MARKET_INTELLIGENCE_BUTTON_EXPORT = 'react.market_intelligence.button.export',
  MARKET_INTELLIGENCE_BULK_EXPORT_HEADER = 'react.market_intelligence.bulk_export.header',
  MARKET_INTELLIGENCE_BULK_EXPORT_DESCRIPTION = 'react.market_intelligence.bulk_export.description',
  MARKET_INTELLIGENCE_REPORTING_ERRORS = 'react.market_intelligence.errors',
  MARKET_INTELLIGENCE_REPORTING_SHOW_ME_HOW = 'react.market_intelligence.show_me_how',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM = 'react.market_intelligence.platform',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM_AMAZON = 'react.market_intelligence.platform_options.amazon',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM_WALMART = 'react.market_intelligence.platform_options.walmart',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM_EBAY = 'react.market_intelligence.platform_options.ebay',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM_GOOGLE_SHOPPING = 'react.market_intelligence.platform_options.google_shopping',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM_TARGET = 'react.market_intelligence.platform_options.target',
  MARKET_INTELLIGENCE_REPORTING_SEARCH_TERM = 'react.market_intelligence.search_term',
  MARKET_INTELLIGENCE_REPORTING_SEARCH_TERM_AND_GROUPS = 'react.market_intelligence.search_term_groups',
  MARKET_INTELLIGENCE_REPORTING_SEARCH_TERM_NO_VALUE_PLACEHOLDER = 'react.market_intelligence.search_term.no_value.placeholder',
  MARKET_INTELLIGENCE_REPORTING_TIME_FRAME = 'react.market_intelligence.time_frame',
  MARKET_INTELLIGENCE_DATE_PICKER_OPTION_24_HOURS = 'react.market_intelligence.date_picker_options.24_Hours',
  MARKET_INTELLIGENCE_DATE_PICKER_OPTION_7_DAYS = 'react.market_intelligence.date_picker_options.7_Days',
  MARKET_INTELLIGENCE_DATE_PICKER_OPTION_14_DAYS = 'react.market_intelligence.date_picker_options.14_Days',
  MARKET_INTELLIGENCE_DATE_PICKER_OPTION_30_DAYS = 'react.market_intelligence.date_picker_options.30_Days',
  MARKET_INTELLIGENCE_DATE_PICKER_OPTION_60_DAYS = 'react.market_intelligence.date_picker_options.60_Days',
  MARKET_INTELLIGENCE_DATE_PICKER_OPTION_90_DAYS = 'react.market_intelligence.date_picker_options.90_Days',
  MARKET_INTELLIGENCE_NO_DATA_TRACKED_TERMS_TABLE_TITLE = 'react.market_intelligence.no_data_tracked_terms_table.title',
  MARKET_INTELLIGENCE_NO_DATA_TRACKED_TERMS_TABLE_DESCRIPTION = 'react.market_intelligence.no_data_tracked_terms_table.description',
  MARKET_INTELLIGENCE_CARD_LEARN_MORE_TITLE = 'react.market_intelligence.card.learn_more.title',
  MARKET_INTELLIGENCE_CARD_LEARN_MORE_DESCRIPTION = 'react.market_intelligence.card.learn_more.description',
  MARKET_INTELLIGENCE_CARD_LEARN_MORE_LINK_LEARN_MORE = 'react.market_intelligence.card.learn_more.link_learn_more',
  MARKET_INTELLIGENCE_CARD_SEARCH_TERMS_SEARCH_TERMS_UNLIMITED = 'react.market_intelligence.card.search_terms.search_terms_unlimited',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_TITLE = 'react.market_intelligence.card.track_terms_usage.title',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_SUBTITLE = 'react.market_intelligence.card.track_terms_usage.subtitle',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_DESCRIPTION = 'react.market_intelligence.card.track_terms_usage.description',
  MARKET_INTELLIGENCE_SETTINGS_SUMMARY_LAST_UPDATED = 'react.market_intelligence.settings_summary.last_updated',
  MARKET_INTELLIGENCE_SETTINGS_SUMMARY_TRACKED_TERMS = 'react.market_intelligence.settings_summary.tracked_terms',
  MARKET_INTELLIGENCE_SETTINGS_SUMMARY_GROUPS = 'react.market_intelligence.settings_summary.groups',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_SUMMARY = 'react.market_intelligence.settings_groups_toolbar_summary',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_CREATE_GROUP = 'react.market_intelligence.settings_group_toolbar.create_group',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_ADD_TO_GROUP = 'react.market_intelligence.settings_group_toolbar.add_to_group',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_DELETE_GROUP = 'react.market_intelligence.settings_group_toolbar.delete_group',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_REMOVE_FROM_GROUP = 'react.market_intelligence.settings_group_toolbar.remove_from_group',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_EDIT = 'react.market_intelligence.settings_group_toolbar.editp',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_ADD = 'react.market_intelligence.settings_group_toolbar.add',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_CREATE = 'react.market_intelligence.settings_group_toolbar.create',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_SELECT_GROUP = 'react.market_intelligence.settings_group_toolbar.select_group',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_GROUP_INPUT = 'react.market_intelligence.settings_group_toolbar.group_input',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_GROUP_EDIT = 'react.market_intelligence.settings_group_toolbar.group_edit',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_INVALID_SELECTION = 'react.market_intelligence.settings_group_toolbar.invalid_selection',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_INVALID_SELECTION_TOOLTIP_TEXT1 = 'react.market_intelligence.settings_group_toolbar.invalid_selection.tooltip_text1',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_INVALID_SELECTION_TOOLTIP_TEXT2 = 'react.market_intelligence.settings_group_toolbar.invalid_selection.tooltip_text2',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_INVALID_SELECTION_TOOLTIP_TEXT3 = 'react.market_intelligence.settings_group_toolbar.invalid_selection.tooltip_text3',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_INVALID_SELECTION_TOOLTIP_TEXT4 = 'react.market_intelligence.settings_group_toolbar.invalid_selection.tooltip_text4',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_INVALID_SELECTION_TOOLTIP_TEXT5 = 'react.market_intelligence.settings_group_toolbar.invalid_selection.tooltip_text5',
  MARKET_INTELLIGENCE_SETTINGS_GROUP_TOOLBAR_REMOVE_TERMS_DELETE_GROUP_TEXT = 'react.market_intelligence.settings_group_toolbar.remove_terms.delete_group',
  MARKET_INTELLIGENCE_SETTINGS_GROUPS_AND_TERMS_TOTAL = 'react.market_intelligence.settings_groups_and_terms.total',
  MARKET_INTELLIGENCE_SETTINGS_SEARCH_TERMS = 'react.market_intelligence.search_terms',
  MARKET_INTELLIGENCE_SETTINGS_GROUPS = 'react.market_intelligence.groups',
  MARKET_INTELLIGENCE_SETTINGS_DOWNLOAD_TEMPLATE = 'react.market_intelligence.download_template',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_ACTIONS = 'react.market_intelligence.columns.search_term.actions',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_ADDED = 'react.market_intelligence.columns.search_term.added',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_LOCALE = 'react.market_intelligence.columns.search_term.locale',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_DATE_RANGE = 'react.market_intelligence.columns.search_term.dateRange',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_REPORT_URL = 'react.market_intelligence.columns.search_term.reportUrl',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_CLICK_HERE = 'react.market_intelligence.columns.search_term.clickHere',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_TYPE = 'react.market_intelligence.columns.search_term.type',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_SEARCH_TERM = 'react.market_intelligence.columns.search_term.search_term',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_SEARCH_TERM_AND_GROUP = 'react.market_intelligence.columns.search_term.search_term_and_group',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_GROUP = 'react.market_intelligence.columns.search_term.group',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_STATUS = 'react.market_intelligence.columns.search_term.status',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_PLATFORM = 'react.market_intelligence.columns.search_term.platform',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_SELECT_ALL = 'react.market_intelligence.columns.search_term.select_all',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_UNSELECT_ALL = 'react.market_intelligence.columns.search_term.unselect_all',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_AMAZON = 'react.market_intelligence.columns.search_term.amazon',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_WALAMRT = 'react.market_intelligence.columns.search_term.walmart',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_AD_SALES = 'react.market_intelligence.columns.search_term.adSales',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_AD_SPEND = 'react.market_intelligence.columns.search_term.adSpend',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_ACOS = 'react.market_intelligence.columns.search_term.acos',
  MARKET_INTELLIGENCE_COLUMNS_SEARCH_TERM_ROAS = 'react.market_intelligence.columns.search_term.roas',
  MARKET_INTELLIGENCE_TOOLTIP_SEARCH_TERM_STATUS_ACTIVE_TITLE = 'react.market_intelligence.tooltip.search_term_status.active.title',
  MARKET_INTELLIGENCE_TOOLTIP_SEARCH_TERM_STATUS_ACTIVE_DESCRIPTION = 'react.market_intelligence.tooltip.search_term_status.active.description',
  MARKET_INTELLIGENCE_TOOLTIP_SEARCH_TERM_STATUS_FETCHING_TITLE = 'react.market_intelligence.tooltip.search_term_status.fetching.title',
  MARKET_INTELLIGENCE_TOOLTIP_SEARCH_TERM_STATUS_FETCHING_DESCRIPTION = 'react.market_intelligence.tooltip.search_term_status.fetching.description',
  MARKET_INTELLIGENCE_TOOLTIP_ACTION_BUTTON_VIEW_REPORT = 'react.market_intelligence.tooltip.actions_buttons.view_report',
  MARKET_INTELLIGENCE_TOOLTIP_ACTION_BUTTON_VIEW_REPORT_GROUP = 'react.market_intelligence.tooltip.actions_buttons.view_report.group',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_PRODUCT_DETAILS = 'react.market_intelligence.columns.average_product_rank.product_details',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_BRAND_NAME = 'react.market_intelligence.columns.average_product_rank.brand_name',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_APPEARANCES_PERCENTAGE = 'react.market_intelligence.columns.average_product_rank.appearances_percentage',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_AVG_RANK_TOTAL = 'react.market_intelligence.columns.average_product_rank.avg_rank_total',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_AVG_RANK_ORGANIC = 'react.market_intelligence.columns.average_product_rank.avg_rank_organic',
  MARKET_INTELLIGENCE_COLUMNS_AVERAGE_PRODUCT_RANK_AVG_RANK_PAID = 'react.market_intelligence.columns.average_product_rank.avg_rank_paid',
  MARKET_INTELLIGENCE_SEARCH_BAR_AVERAGE_PRODUCT_RANK = 'react.market_intelligence.search_bar.average_product_rank',
  MARKET_INTELLIGENCE_COLUMNS_BRAND_COVERAGE_ON_SEARCH_BRAND_NAME = 'react.market_intelligence.columns.brand_coverage_on_search.brand_name',
  MARKET_INTELLIGENCE_COLUMNS_BRAND_COVERAGE_ON_SEARCH_APPEARANCES_PERCENTAGE = 'react.market_intelligence.columns.brand_coverage_on_search.appearances_percentage',
  MARKET_INTELLIGENCE_COLUMNS_BRAND_COVERAGE_ON_SEARCH_TOTAL_BCS = 'react.market_intelligence.columns.brand_coverage_on_search.total_bcs',
  MARKET_INTELLIGENCE_COLUMNS_BRAND_COVERAGE_ON_SEARCH_ORGANIC_BCS = 'react.market_intelligence.columns.brand_coverage_on_search.organic_bcs',
  MARKET_INTELLIGENCE_COLUMNS_BRAND_COVERAGE_ON_SEARCH_PAID_BCS = 'react.market_intelligence.columns.brand_coverage_on_search.paid_bcs',
  MARKET_INTELLIGENCE_SEARCH_BAR_BRAND_COVERAGE_ON_SEARCH = 'react.market_intelligence.search_bar.brand_coverage_on_search',
  MARKET_INTELLIGENCE_REPORTING_TABLE_TABS_AVERAGE_PRODUCT_RANK = 'react.market_intelligence.reporting_table_tabs.average_product_rank',
  MARKET_INTELLIGENCE_REPORTING_TABLE_TABS_BRAND_COVERAGE_ON_SEARCH = 'react.market_intelligence.reporting_table_tabs.brand_coverage_on_search',
  MARKET_INTELLIGENCE_DELETE_SEARCH_TERM_HEADER = 'react.market_intelligence.delete_search_term.header',
  MARKET_INTELLIGENCE_DELETE_SEARCH_TERM_DESCRIPTION = 'react.market_intelligence.delete_search_term.description',
  MARKET_INTELLIGENCE_DELETE_GROUP_DESCRIPTION = 'react.market_intelligence.delete_group.description',
  MARKET_INTELLIGENCE_DELETE_GROUP_HEADER = 'react.market_intelligence.delete_group.header',
  MARKET_INTELLIGENCE_DELETE_GROUP_OPTION1 = 'react.market_intelligence.delete_group.option1',
  MARKET_INTELLIGENCE_DELETE_GROUP_OPTION2 = 'react.market_intelligence.delete_group.option2',
  MARKET_INTELLIGENCE_DELETE_GROUP_OPTION1_DESCRIPTION = 'react.market_intelligence.delete_group.option1.description',
  MARKET_INTELLIGENCE_DELETE_GROUP_OPTION2_DESCRIPTION = 'react.market_intelligence.delete_group.option2.description',
  MARKET_INTELLIGENCE_DELETE_SEARCH_TERM_CONFIRMATION = 'react.market_intelligence.delete_search_term.confirmation',
  MARKET_INTELLIGENCE_DELETE_SEARCH_TERM_BUTTON_CANCEL = 'react.market_intelligence.delete_search_term.button.cancel',
  MARKET_INTELLIGENCE_DELETE_SEARCH_TERM_BUTTON_DELETE = 'react.market_intelligence.delete_search_term.button.delete',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_UPLOAD_FILE = 'react.market_intelligence.upload_terms_popover.common.upload_file',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_START_UPLOAD = 'react.market_intelligence.upload_terms_popover.common.start_upload',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_SELECT_ANOTHER_FILE = 'react.market_intelligence.upload_terms_popover.common.select_another_file',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_CSV_FILE_STATUS = 'react.market_intelligence.upload_terms_popover.common.csv_file_status',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_CSV_FILE_STATUS_WITH_ERROR = 'react.market_intelligence.upload_terms_popover.common.csv_file_status_with_error',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMMON_CSV_FILE_STATUS_WITH_ERROR_NEW = 'react.market_intelligence.upload_terms_popover.common.csv_file_status_with_error_new',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_TITLE = 'react.market_intelligence.upload_terms_popover.choose_file.title',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_DOWNLOAD_CSV_TEMPLATE = 'react.market_intelligence.upload_terms_popover.choose_file.download_csv_template',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_SELECT_FILE = 'react.market_intelligence.upload_terms_popover.choose_file.select_file',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_UPLOAD_VALID_CSV = 'react.market_intelligence.upload_terms_popover.choose_file.upload_valid_csv',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_DESCRIPTION_POINT1 = 'react.market_intelligence.upload_terms_popover.choose_file.description.point1',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_CHOOSE_FILE_DESCRIPTION_POINT2 = 'react.market_intelligence.upload_terms_popover.choose_file.description.point2',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_START_UPLOAD_SUBTITLE = 'react.market_intelligence.upload_terms_popover.start_upload.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_PROCESS_FILE_TITLE = 'react.market_intelligence.upload_terms_popover.process_file.title',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_PROCESS_FILE_SUBTITLE_PROCESSED = 'react.market_intelligence.upload_terms_popover.process_file.subtitle.processed',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_PROCESS_FILE_SUBTITLE_PROCESSING = 'react.market_intelligence.upload_terms_popover.process_file.subtitle.processing',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_PROCESS_FILE_BUTTON_PRIMARY_TEXT = 'react.market_intelligence.upload_terms_popover.process_file.buttonPrimaryText',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_COMPLETE_UPLOAD_SUBTITLE = 'react.market_intelligence.upload_terms_popover.complete_upload.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_UNKNOWN_SUBTITLE = 'react.market_intelligence.upload_terms_popover.error_unknown.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_UNKNOWN_DESCRIPTION = 'react.market_intelligence.upload_terms_popover.error_unknown.description',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_GENERIC_DESCRIPTION = 'react.market_intelligence.upload_terms_popover.error_generic.description',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_SUBTITLE = 'react.market_intelligence.upload_terms_popover.error_csv.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_DESCRIPTION = 'react.market_intelligence.upload_terms_popover.error_csv.description',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_BUTTON_PRIMARY_TEXT = 'react.market_intelligence.upload_terms_popover.error_csv.buttonPrimaryText',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_FIX_CSV_ERRORS = 'react.market_intelligence.upload_terms_popover.error_csv.fix_csv_errors',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_MISSING_VALUE = 'react.market_intelligence.upload_terms_popover.error_csv.missing_value',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_CSV_WRONG_VALUE = 'react.market_intelligence.upload_terms_popover.error_csv.wrong_value',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_TERMS_EXCEEDED_SUBTITLE = 'react.market_intelligence.upload_terms_popover.error_terms_exceeded.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_ERROR_TERMS_EXCEEDED_DESCRIPTION = 'react.market_intelligence.upload_terms_popover.error_terms_exceeded.description',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_NO_NEW_TERMS_IDENTIFIED_DESCRIPTION = 'react.market_intelligence.upload_terms_popover.no_new_terms_identified.description',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_MISSING_HEADER_SUBTITLE = 'react.market_intelligence.upload_terms_popover.missing_header.subtitle',
  MARKET_INTELLIGENCE_UPLOAD_TERMS_POPOVER_MISSING_HEADER_DESCRIPTION = 'react.market_intelligence.upload_terms_popover.missing_header.description',
  MARKET_INTELLIGENCE_EXPORT_SEARCH_TERMS = 'react.market_intelligence.export_search_terms',
  MARKET_INTELLIGENCE_BCS_HEADER = 'react.market_intelligence.bcs.header',
  MARKET_INTELLIGENCE_BCS_TABS_TOTAL = 'react.market_intelligence.bcs.tabs.total',
  MARKET_INTELLIGENCE_BCS_TABS_ORGANIC = 'react.market_intelligence.bcs.tabs.organic',
  MARKET_INTELLIGENCE_BCS_TABS_PAID = 'react.market_intelligence.bcs.tabs.paid',
  MARKET_INTELLIGENCE_BCS_HELP_HEADER = 'react.market_intelligence.bcs.help.header',
  MARKET_INTELLIGENCE_BCS_HELP_EXPLANATION = 'react.market_intelligence.bcs.help.explanation',
  MARKET_INTELLIGENCE_BCS_HELP_EXAMPLE_HEADER = 'react.market_intelligence.bcs.help.example_header',
  MARKET_INTELLIGENCE_BCS_HELP_EXAMPLE_EXPLANATION = 'react.market_intelligence.bcs.help.example_explanation',
  MARKET_INTELLIGENCE_BCS_WARNING = 'react.market_intelligence.bcs.warning',
  MARKET_INTELLIGENCE_BCS_WARNING_LINK = 'react.market_intelligence.bcs.warning.link',
  MARKET_INTELLIGENCE_CHARTS_PIE_CHART_HEADING = 'react.market_intelligence.charts.pie_chart.heading',
  MARKET_INTELLIGENCE_CHARTS_PIE_CHART_CENTER_TEXT = 'react.market_intelligence.charts.pie_chart.center_text',
  MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_HEADER_COLUMN_BRAND_NAME = 'react.market_intelligence.charts.data_table.header.column_brand_name',
  MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_HEADER_COLUMN_BCS_PERCENTAGE = 'react.market_intelligence.charts.data_table.header.column_bcs_percentage',
  MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_HEADER_COLUMN_BCS_PERCENTAGE_NO_DATA = 'react.market_intelligence.charts.data_table.column_bcs_percentage_no_data',
  MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_FOOTER_COLUMN_BRAND_NAME_TITLE = 'react.market_intelligence.charts.data_table.footer.column_brand_name_title',
  MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_FOOTER_COLUMN_BRAND_NAME_DESCRIPTION = 'react.market_intelligence.charts.data_table.footer.column_brand_name_description',
  MARKET_INTELLIGENCE_CHARTS_LINE_CHART_HEADER_LEFT = 'react.market_intelligence.charts.line_chart.header.left',
  MARKET_INTELLIGENCE_CHARTS_LINE_CHART_HEADER_RIGHT = 'react.market_intelligence.charts.line_chart.header.right',
  MARKET_INTELLIGENCE_CHARTS_LINE_CHART_NO_DATA_HEADER = 'react.market_intelligence.charts.line_chart.no_data.header',
  MARKET_INTELLIGENCE_CHARTS_LINE_CHART_NO_DATA_DESCRIPTION = 'react.market_intelligence.charts.line_chart.no_data.description',
  MARKET_INTELLIGENCE_REPORTING_PROCESSING_TITLE = 'react.market_intelligence.reporting_processing_title',
  MARKET_INTELLIGENCE_REPORTING_PROCESSING_DESCRIPTION = 'react.market_intelligence.reporting_processing_description',
  MARKET_INTELLIGENCE_REPORTING_SELECT_PLATFORM = 'react.market_intelligence.reporting_select_platform',
  MARKET_INTELLIGENCE_REPORTING_SEARCH_TERMS = 'react.market_intelligence.reporting_search_terms',
  MARKET_INTELLIGENCE_REPORTING_ACCOUNT_NOT_MATCHED_TITLE = 'react.market_intelligence.reporting_account_not_matched_title',
  MARKET_INTELLIGENCE_REPORTING_ACCOUNT_NOT_MATCHED_DESCRIPTION = 'react.market_intelligence.reporting_account_not_matched_description',
  MARKET_INTELLIGENCE_REPORTING_PLATFORM_NOT_MATCHED_DESCRIPTION = 'react.market_intelligence.reporting_platform_not_matched_description',
  MARKET_INTELLIGENCE_REPORTING_TERM_NOT_MATCHED_DESCRIPTION = 'react.market_intelligence.reporting_term_not_matched_description',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_SELECTED_TERMS = 'react.market_intelligence.add_new_terms.selectedTerms',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_SELECTED_TERMS_COUNTER = 'react.market_intelligence.add_new_terms.selectedTermsCounter',
  MARKET_INTELLIGENCE_REPORTING_BCS_TAB = 'react.market_intelligence.reporting_bcs_tab',
  MARKET_INTELLIGENCE_REPORTING_HISTORY_TAB = 'react.market_intelligence.reporting_history_tab',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_REPORT_STATUS = 'react.market_intelligence.notification.generic_error.report_status',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_REPORT_PROCESSING = 'react.market_intelligence.notification.generic_error.report_processing',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_SEARCH_TERMS_ERROR = 'react.market_intelligence.notification.generic_error.search_terms',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_SUGGESTIONS_ERROR = 'react.market_intelligence.notification.generic_error.suggestions',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_ADD_SEARCH_TERMS_ERROR = 'react.market_intelligence.notification.generic_error.add_search_terms',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_REMOVE_SEARCH_TERMS_ERROR = 'react.market_intelligence.notification.generic_error.remove_search_terms',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_IGNORE_SUGGESTION_ERROR = 'react.market_intelligence.notification.generic_error.ignore_suggestion',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_BCS_ERROR = 'react.market_intelligence.notification.generic_error.bcs',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_PRODUCT_ERROR = 'react.market_intelligence.notification.generic_error.avg_product_rank',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_EXPORT_BCS_ERROR = 'react.market_intelligence.notification.generic_error.export_bcs',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_PLATFORM_ERROR = 'react.market_intelligence.notification.generic_error.platform',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_REMOVE_GROUP_ERROR = 'react.market_intelligence.notification.generic_error.remove_group',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_UPDATE_GROUP_ERROR = 'react.market_intelligence.notification.generic_error.update_group',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_ADD_GROUP_TERM_ERROR = 'react.market_intelligence.notification.generic_error.add_group_term',
  MARKET_INTELLIGENCE_NOTIFICATIONS_GENERIC_REMOVE_GROUP_TERM_ERROR = 'react.market_intelligence.notification.generic_error.remove_group_term',

  MARKET_INTELLIGENCE_LANDING_LOCKED = 'react.market_intelligence.landing.locked',
  MARKET_INTELLIGENCE_LANDING_TITLE = 'react.market_intelligence.landing.title',
  MARKET_INTELLIGENCE_LANDING_DESCRIPTION = 'react.market_intelligence.landing.description',
  MARKET_INTELLIGENCE_LANDING_CONNECT_CHANNEL = 'react.market_intelligence.landing.connect_channel',
  MARKET_INTELLIGENCE_LANDING_ADD_TERMS = 'react.market_intelligence.landing.add_terms',
  MARKET_INTELLIGENCE_LANDING_NOTICE = 'react.market_intelligence.landing.notice',
  MARKET_INTELLIGENCE_LANDING_TRACK_TERMS_HEADLINE = 'react.market_intelligence.landing.track_terms.headline',
  MARKET_INTELLIGENCE_LANDING_TRACK_TERMS_BODY = 'react.market_intelligence.landing.track_terms.body',
  MARKET_INTELLIGENCE_LANDING_TRAFFIC_SHARE_HEADLINE = 'react.market_intelligence.landing.traffic_share.headline',
  MARKET_INTELLIGENCE_LANDING_TRAFFIC_SHARE_BODY = 'react.market_intelligence.landing.traffic_share.body',
  MARKET_INTELLIGENCE_LANDING_SEE_IMPACT_HEADLINE = 'react.market_intelligence.landing.see_impact.headline',
  MARKET_INTELLIGENCE_LANDING_SEE_IMPACT_BODY = 'react.market_intelligence.landing.see_impact.body',

  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_TITLE = 'react.market_intelligence.no_active_terms.title',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_DESCRIPTION = 'react.market_intelligence.no_active_terms.description',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_GET_STARTED = 'react.market_intelligence.no_active_terms.get_started',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_START_TRACKING_TERMS = 'react.market_intelligence.no_active_terms.start_tracking_terms',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_LEARN_ABOUT = 'react.market_intelligence.no_active_terms.learn_about',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_ILLUSTRATION_FIRST = 'react.market_intelligence.no_active_terms.illustration.first',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_ILLUSTRATION_SECOND = 'react.market_intelligence.no_active_terms.illustration.second',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_ILLUSTRATION_THIRD = 'react.market_intelligence.no_active_terms.illustration.third',
  MARKET_INTELLIGENCE_FULL_STOP = 'react.market_intelligence.full_stop',
  MARKET_INTELLIGENCE_NUMERIC_ONE = 'react.market_intelligence.numeric.one',
  MARKET_INTELLIGENCE_NUMERIC_TWO = 'react.market_intelligence.numeric.two',
  MARKET_INTELLIGENCE_NUMERIC_THREE = 'react.market_intelligence.numeric.three',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_QUESTIONS_PRICING = 'react.market_intelligence.no_active_terms.questions_pricing',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_QUESTIONS_PRICING_TEIKA_USER = 'react.market_intelligence.no_active_terms.questions_pricing_teika_user',
  MARKET_INTELLIGENCE_NO_ACTIVE_TERMS_QUESTIONS_PRICING_NOT_TEIKA_USER = 'react.market_intelligence.no_active_terms.questions_pricing_not_teika_user',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE__PREPOSITION_OF = 'react.market_intelligence.card.track_terms_usage.preposition.of',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_LEGEND_TRACKED_TERMS = 'react.market_intelligence.card.track_terms_usage.legend_tracked_terms',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_TITLE = 'react.market_intelligence.suggetsed_terms.title',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABLE_NO_MATCHED_RESULTS_TITLE = 'react.market_intelligence.suggetsed_terms.table.no_matched_results.title',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABLE_NO_MATCHED_RESULTS_DESCRIPTION = 'react.market_intelligence.suggetsed_terms.table.no_matched_results.description',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABLE_NO_NEW_RESULTS_DESCRIPTION = 'react.market_intelligence.suggetsed_terms.table.no_new_results.description',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABS_SUGGESTED_TERMS = 'react.market_intelligence.tabs.suggestedTerms',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABS_ADD_NEW_TERMS = 'react.market_intelligence.tabs.addNewTerms',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABS_TRACKED_TERMS = 'react.market_intelligence.tabs.trackedTerms',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABS_GROUPS = 'react.market_intelligence.tabs.groups',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_STEPPER_SELECT_TERMS = 'react.market_intelligence.stepper.selectTerms',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_STEPPER_SELECT_MARKETPLACE = 'react.market_intelligence.stepper.selectMarketplace',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_STEPPER_SUMMARY = 'react.market_intelligence.stepper.summary',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABLE_TOOLTIP = 'react.market_intelligence.suggetsed_terms.table.tooltip',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_SUMMARY_TABLE_TEXT = 'react.market_intelligence.suggetsed_terms.summary_table.text',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_SUMMARY_TABLE_TOOLTIP = 'react.market_intelligence.suggetsed_terms.summary_table.tooltip',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_MARKETPLACE_TABLE_TOOLTIP = 'react.market_intelligence.suggetsed_terms.marketplace_table.tooltip',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_SUMMARY_TABLE_TOTAL_TERMS = 'react.market_intelligence.suggetsed_terms.summary_table.totalTerms',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_BUTTON_NEXT = 'react.market_intelligence.suggetsed_terms.button.next',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_BUTTON_IGNORE = 'react.market_intelligence.suggetsed_terms.button.ignore',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_BUTTON_PREVIOUS = 'react.market_intelligence.suggetsed_terms.button.previous',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_BUTTON_TRACK = 'react.market_intelligence.suggetsed_terms.button.track',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABLE_INFO = 'react.market_intelligence.suggetsed_terms.table.info',
  MARKET_INTELLIGENCE_SUGGESTED_TERMS_TABLE_SELECTION = 'react.market_intelligence.suggetsed_terms.table.selection',
  MARKET_INTELLIGENCE_IGNORE_SUGGESTED_TERMS_HEADER = 'react.market_intelligence.ignore_search_term.header',
  MARKET_INTELLIGENCE_IGNORE_SUGGESTED_TERMS_DESCRIPTION = 'react.market_intelligence.ignore_search_term.description',
  MARKET_INTELLIGENCE_IGNORE_SUGGESTED_TERMS_CONFIRMATION = 'react.market_intelligence.ignore_search_term.button.confirmation',
  MARKET_INTELLIGENCE_IGNORE_SUGGESTED_TERMS_IGNORE = 'react.market_intelligence.ignore_search_term.button.ignore',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_TOOLTIP_DUPLICATE = 'react.market_intelligence.add_new_terms.tooltip.duplicate',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_TOOLTIP_DELETE = 'react.market_intelligence.add_new_terms.tooltip.delete',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_TITLE = 'react.market_intelligence.add_new_terms.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_DESCRIPTION = 'react.market_intelligence.add_new_terms.description',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_TAB_MANUALLY = 'react.market_intelligence.add_new_terms.tab.manually',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_TAB_BULK_UPLOAD = 'react.market_intelligence.add_new_terms.tab.bulk_upload',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NO_TERMS_TITLE = 'react.market_intelligence.add_new_terms.no_terms.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NO_TERMS_DESCRIPTION = 'react.market_intelligence.add_new_terms.no_terms.description',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_COLUMN_ACTION = 'react.market_intelligence.add_new_terms.column.action',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_COLUMN_COUNTRY = 'react.market_intelligence.add_new_terms.column.country',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_COLUMN_SALES_CHANNEL = 'react.market_intelligence.add_new_terms.column.sales_channel',
  MARKET_INTELLIGENCE_TRACKED_TERMS_TITLE = 'react.market_intelligence.tracked_terms.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_COLUMN_ACTION_TOOLTIP_TITLE = 'react.market_intelligence.add_new_terms.column.action.tooltip.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_COLUMN_ACTION_TOOLTIP_DESCRIPTION = 'react.market_intelligence.add_new_terms.column.action.tooltip.description',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_PLACEHOLDER = 'react.market_intelligence.add_new_terms.new_term_placeholder',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_TOOLTIP_TITLE = 'react.market_intelligence.add_new_terms.new_term_tooltip.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_TOOLTIP_ERROR_TITLE = 'react.market_intelligence.add_new_terms.new_term_tooltip.error.title',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_TOOLTIP_DESCRIPTION = 'react.market_intelligence.add_new_terms.new_term_tooltip.description',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_TOOLTIP_ERROR_EMPTY_TERM = 'react.market_intelligence.add_new_terms.new_term_tooltip.error.emptyTerm',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_TOOLTIP_ERROR_MAX_LENGTH = 'react.market_intelligence.add_new_terms.new_term_tooltip.error.maxLength',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_TOOLTIP_ERROR_EMOJI = 'react.market_intelligence.add_new_terms.new_term_tooltip.error.emoji',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_TOOLTIP_ERROR_NO_TERM = 'react.market_intelligence.add_new_terms.new_term_tooltip.error.no_term',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_COUNTRY_LABEL = 'react.market_intelligence.add_new_terms.country_label',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_TOOLTIP = 'react.market_intelligence.add_new_terms.new_term_footer_tooltip',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_TOOLTIP_TM_ACCOUNT = 'react.market_intelligence.add_new_terms.new_term_footer_tooltip_tm_account',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_AVAILABLE_TERMS_TEXT = 'react.market_intelligence.add_new_terms.new_term_footer_available_term_text',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_AVAILABLE_TERMS_TEXT_TM_ACCOUNT = 'react.market_intelligence.add_new_terms.new_term_footer_available_term_text_tm_account',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_CANCEL_BUTTON = 'react.market_intelligence.add_new_terms.new_term_footer_cancel_button',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_FOOTER_SUBMIT_BUTTON = 'react.market_intelligence.add_new_terms.new_term_footer_submit_button',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_LIMIT_GRADIENT = 'react.market_intelligence.card.track_terms_usage.limit_gradient',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_REDIRECT_DASHBOARD_GRADIENT = 'react.market_intelligence.card.track_terms_usage.redirect_dashboard_gradient',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_REDIRECT_LEARN_MORE_GRADIENT = 'react.market_intelligence.card.track_terms_usage.redirect_learn-more_gradient',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_DASHBOARD_LINK = 'react.market_intelligence.tracked_terms_usage.dashboard_link',
  MARKET_INTELLIGENCE_CARD_TRACK_TERMS_USAGE_LEARN_MORE_LINK = 'react.market_intelligence.tracked_terms_usage.learn_more_link',
  MARKET_INTELLIGENCE_SELECT_SALES_CHANNEL_PLACEHOLDER = 'react.market_intelligence.select_sales_channel_placeholder',
  MARKET_INTELLIGENCE_SELECT_COUNTRY_PLACEHOLDER = 'react.market_intelligence.select_country_placeholder',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_NEW_TERM_SEARCH_DROPDOWN_PLACEHOLDER = 'react.market_intelligence.add_new_terms.search_dropdown_placeholder',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_PREVIEWER = 'react.market_intelligence.add_new_terms.terms_previewer',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_APR_APPEARANCE_PERCENTAGE = 'react.market_intelligence.tooltips.table.apr.appearance_percentage',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_APR_AVG_RANK_ORGANIC = 'react.market_intelligence.tooltips.table.apr.avg_rank_organic',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_APR_AVG_RANK_PAID = 'react.market_intelligence.tooltips.table.apr.avg_rank_paid',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_APR_AVG_RANK_TOTAL = 'react.market_intelligence.tooltips.table.apr.avg_rank_total',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_BCS_APPEARANCES_PERCENTAGE = 'react.market_intelligence.tooltips.table.bcs.appearances_percentage',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_BCS_PAID = 'react.market_intelligence.tooltips.table.bcs.paid',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_BCS_ORGANIC = 'react.market_intelligence.tooltips.table.bcs.organic',
  MARKET_INTELLIGENCE_TOOLTIPS_TABLE_BCS_TOTAL = 'react.market_intelligence.tooltips.table.bcs.total',
  MARKET_INTELLIGENCE_ADD_TERMS_CHANNEL_COUNTRY_LABEL = 'react.market_intelligence.add_new_terms.channel_country_label',
  MARKET_INTELLIGENCE_SEARCH_TERMS_NOTE = 'react.market_intelligence.add_new_terms.search_term_note',
  MARKET_INTELLIGENCE_COUNTRY_NOTE = 'react.market_intelligence.add_new_terms.country_note',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_HEADER = 'react.market_intelligence.add_new_terms.header',
  MARKET_INTELLIGENCE_ADD_NEW_TERMS_BUTTON_LABEL = 'react.market_intelligence.add_new_terms.button_label',

  MARKET_INTELLIGENCE_SOV_PRODUCT_PERFORMANCE_OVERTIME = 'react.market_intelligence.sov.product_performace_overtime',
  MARKET_INTELLIGENCE_SOV_PG1_AVERAGE_APPERENCE = 'react.market_intelligence.sov.pg1_average_appearance',
  MARKET_INTELLIGENCE_SOV_PG1_AVERAGE_POSITION = 'react.market_intelligence.sov.pg1_average_position',
  MARKET_INTELLIGENCE_PRODUCT_MARKET_SHARE_PRODUCTS_COLUMN = 'react.market_intelligence.product_market_share.columns.products',
  MARKET_INTELLIGENCE_PRODUCT_MARKET_SHARE_APPEARANCE_PERCENT_COLUMN = 'react.market_intelligence.product_market_share.columns.appearance_percent',
  MARKET_INTELLIGENCE_PRODUCT_MARKET_SHARE_ORGANIC_COLUMN = 'react.market_intelligence.product_market_share.columns.sov_organic',
  MARKET_INTELLIGENCE_PRODUCT_MARKET_SHARE_PAID_COLUMN = 'react.market_intelligence.product_market_share.columns.sov_paid',
  MARKET_INTELLIGENCE_PRODUCT_MARKET_SHARE_TOTAL_COLUMN = 'react.market_intelligence.product_market_share.columns.sov_total',
  MARKET_INTELLIGENCE_PRODUCT_MARKET_SHARE_PRODUCT_DETAILS_COLUMN = 'react.market_intelligence.product_market_share.columns.product_details',
  MARKET_INTELLIGENCE_PRODUCT_MARKET_SHARE_SEARCH_PLACEHOLDER = 'react.market_intelligence.product_market_share.search.placeholder',
  MARKET_INTELLIGENCE_PRODUCT_MARKET_SHARE_PAGE_HEADER = 'react.market_intelligence.product_market_share.page_header',
  MARKET_INTELLIGENCE_PRODUCT_MARKET_SHARE_PAGE_SUB_HEADER = 'react.market_intelligence.product_market_share.page_subheader',
  MARKET_INTELLIGENCE_BREADCRUMBS_SHARE_OF_VOICE = 'react.market_intelligence.breadcrumbs.share_of_voice',
  MARKET_INTELLIGENCE_SOV_RANK_COLUMN = 'react.market_intelligence.sov.columns.rank',
  MARKET_INTELLIGENCE_SOV_KEYWORD_COLUMN = 'react.market_intelligence.sov.columns.keyword',
  MARKET_INTELLIGENCE_SOV_SEARCH_VOLUME_COLUMN = 'react.market_intelligence.sov.columns.search_volume',
  MARKET_INTELLIGENCE_SOV_IMPRESSIONS_COLUMN = 'react.market_intelligence.sov.columns.impressions',
  MARKET_INTELLIGENCE_SOV_APPEARANCE_PERCENT_COLUMN = 'react.market_intelligence.sov.columns.appearance_percent',
  MARKET_INTELLIGENCE_SOV_PG1_AVG_RANK_COLUMN = 'react.market_intelligence.sov.columns.pg1_avg_rank',
  MARKET_INTELLIGENCE_SOV_SHARE_OF_VOICE_COLUMN = 'react.market_intelligence.sov.columns.share_of_voice',
  MARKET_INTELLIGENCE_SOV_PERFORMANCE_COLUMN = 'react.market_intelligence.sov.columns.performance',
  MARKET_INTELLIGENCE_SOV_TOOLTIP_APPEARANCE_PERCENT_ORGANIC = 'react.market_intelligence.sov.tooltip.appearance_percent_organic',
  MARKET_INTELLIGENCE_SOV_TOOLTIP_APPEARANCE_PERCENT_PAID = 'react.market_intelligence.sov.tooltip.appearance_percent_paid',
  MARKET_INTELLIGENCE_SOV_TOOLTIP_PG1_AVG_RANK_ORGANIC = 'react.market_intelligence.sov.tooltip.pg1_avg_rank_organic',
  MARKET_INTELLIGENCE_SOV_TOOLTIP_PG1_AVG_RANK_PAID = 'react.market_intelligence.sov.tooltip.pg1_avg_rank_paid',
  MARKET_INTELLIGENCE_SOV_KEYWORD_CAMPAIGN_SEARCH_PLACEHOLDER = 'react.market_intelligence.keyword_campaign.search.placeholder',
  MARKET_INTELLIGENCE_PRODUCT_MARKET_SHARE_UPDATED_TEXT = 'react.market_intelligence.product_market_share.updated_text',
  MARKET_INTELLIGENCE_SOV_TOOLTIP_AD_SPEND = 'react.market_intelligence.sov.tooltip.ad_spend',
  MARKET_INTELLIGENCE_PRODUCT_MARKET_SHARE_TITLE_KEYWORDS = 'react.market_intelligence.product_market_share.title.keywords',
  MARKET_INTELLIGENCE_SOV_TOOLTIP_KEYWORDS = 'react.market_intelligence.sov.tooltip.keywords',
  MARKET_INTELLIGENCE_SOV_SUBTITLE = 'react.market_intelligence.sov.subtitle',
  MARKET_INTELLIGENCE_SOV_DASHBOARD_LAST_DAYS = 'react.market_intelligence.sov.dashboard.last_days',
  MARKET_INTELLIGENCE_SOV_DASHBOARD_MOST_APPEARANCE = 'react.market_intelligence.sov.dashboard.most_appearance',
  MARKET_INTELLIGENCE_SOV_DASHBOARD_HIGHEST_POSTION = 'react.market_intelligence.sov.dashboard.highest_position',
  MARKET_INTELLIGENCE_SOV_DASHBOARD_PRODUCT_LIST_HEADER = 'react.market_intelligence.sov.dashboard.product_list.header',
  MARKET_INTELLIGENCE_SOV_DASHBOARD_AVG = 'react.market_intelligence.sov.dashboard.avg',
  MARKET_INTELLIGENCE_PRODUCT_HIERARCHY_PARENT = 'react.market_intelligence.product_hierarchy.parent',
  MARKET_INTELLIGENCE_SOV_DASHBORAD_COUNTRIES = 'react.market_intelligence.sov.dashboard.countries',
  MARKET_INTELLIGENCE_SOV_APPEARANCE = 'react.market_intelligence.sov.appearance',
  MARKET_INTELLIGENCE_SOV_AVG_PAGE1 = 'react.market_intelligence.sov.avg_page1',
  MARKET_INTELLIGENCE_SOV_POSTION = 'react.market_intelligence.sov.position',
  MARKET_INTELLIGENCE_SOV_AI_LEARNING_PHASE = 'react.market_intelligence.sov.ai_learning_phase',
  MARKET_INTELLIGENCE_SOV_NO_DATA_AVAILABLE = 'react.market_intelligence.sov.no_data_available',
  MARKET_INTELLIGENCE_SOV_IMPROVE_ADVERTISING = 'react.market_intelligence.sov.improve_Advertising',
  MARKET_INTELLIGENCE_SOV_ACTIVATE_PREDICTIVE_AI_BIDDING = 'react.market_intelligence.sov.activate_predictive_ai_bidding',

  ADVERTISING_OPTIMIZATION_CAMPAIGN_COMPLETED_TOOLTIP = 'react.ad_optimization.ads_manager.edit.campaign_completed_tooltip_for_end_date',

  SKU_CATALOG_TITLE = 'react.pages.sku_catalog.title',
  SKU_CATALOG_1P_NOT_SUPPORTED = 'react.pages.sku_catalog.1P_not_supported_message',

  SKU_CATALOG_RELATED_FLYOVER_HEADING = 'react.pages.sku_catalog.related_campaign_and_adgroup.heading',
  SKU_CATALOG_PARENT_FLYOVER_HEADING = 'react.pages.sku_catalog.parent_campaign_and_adgroup.heading',
  SKU_CATALOG_RELATED_FLYOVER_SUB_HEADING = 'react.pages.sku_catalog.related_campaign_and_adgroup.sub_heading',
  SKU_CATALOG_RELATED_FLYOVER_FOOTER_TEXT = 'react.pages.sku_catalog.related_campaign_and_adgroup.footer.text',
  SKU_CATALOG_RELATED_FLYOVER_FOOTER_PRIMARY_BUTTON_TEXT = 'react.pages.sku_catalog.related_campaign_and_adgroup.footer.primary_button_text',
  SKU_CATALOG_ACTIVE_CAMPAIGN_COLUMN_TOOLTIP = 'react.pages.sku_catalog.active_campaign.tooltip',

  ADVERTISING_OPTIMIZATION_SERVER_ERROR_TEXT = 'react.ad_optimization.server.error.text',
  ADVERTISING_OPTIMIZATION_REFRESH_TEXT = 'react.ad_optimization.refresh.text',
  ADVERTISING_OPTIMIZATION_WELCOME_TEXT = 'react.ad_optimization.welcome.text',
  ADVERTISING_OPTIMIZATION_CONNECT_SALES_CHANNELS_TEXT = 'react.ad_optimization.connect.sales_channels_text',
  ADVERTISING_OPTIMIZATION_HEADER_DATA_STATUS_SPONSORED_API = 'react.pages.ad_optimization.header.data_status_sponsored_api',
  ADVERTISING_OPTIMIZATION_HEADER_DATA_STATUS = 'react.pages.ad_optimization.header.data_status',
  ADVERTISING_OPTIMIZATION_HEADER_DATA_STATUS_TIME_OFFSET = 'react.pages.ad_optimization.header.data_status_time_offset',
  ADVERTISING_OPTIMIZATION_TITLE = 'react.pages.ad_optimization.title',
  ADVERTISING_OPTIMIZATION_TABLE_ACTION_ROW_EXPORT = 'react.pages.ad_optimization.table.action_row.export',
  ADVERTISING_OPTIMIZATION_TABLE_ACTION_ROW_LOADING_TEXT = 'react.pages.ad_optimization.table.action_row.loading_text',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_FILE_DOWNLOAD = 'react.pages.ad_optimization.toast_message.file_download',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_SAVE_EDITED_CHANGES_SUCCESS = 'react.pages.ad_optimization.toast_message.save_changes_success',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_SAVE_EDITED_REQUEST_PROCESSING = 'react.pages.ad_optimization.toast_message.request_processing',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_SAVE_EDITED_CHANGES_FAILURE = 'react.pages.ad_optimization.toast_message.save_changes_failure',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_SAVE_EDITED_CHANGES_FAILURE_HEADLINE = 'react.pages.ad_optimization.toast_message.save_changes_failure_headline',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_EXPORT_FAILED_HEADER = 'react.pages.ad_optimization.toast_message.export_failed_header',
  ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_EXPORT_FAILED = 'react.pages.ad_optimization.toast_message.export_failed',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_AD_SPEND = 'react.ad_optimization.campaigns.hero_metrics.ad_spend',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_AD_SALES = 'react.ad_optimization.campaigns.hero_metrics.ad_sales',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_ROAS = 'react.ad_optimization.campaigns.hero_metrics.roas',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_UNITS_SOLD = 'react.ad_optimization.campaigns.hero_metrics.units_sold',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_CONVERSION_RATE = 'react.ad_optimization.campaigns.hero_metrics.conversion_rate',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_AD_CONVERSIONS = 'react.ad_optimization.campaigns.hero_metrics.ad_conversions',
  ADVERTISING_OPTIMIZATION_CAMPAIGNS_CAPTION_MESSAGE = 'react.ad_optimization.campaigns.hero_metrics.caption_message',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_ANALYZE = 'react.ad_optimization.data_inspector.analyze',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL = 'react.ad_optimization.data_inspector.ad_level',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_MANAGE_COLUMNS = 'react.ad_optimization.data_inspector.manage_columns',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_APPLY_CHANGES = 'react.ad_optimization.data_inspector.apply_changes',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_PRODUCTS = 'react.ad_optimization.data_inspector.ad_type.sponsored_products',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SEARCH_BRAND_AMPLIFIER = 'react.ad_optimization.data_inspector.ad_type.search_brand_amplifier',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_BRANDS = 'react.ad_optimization.data_inspector.ad_type.sponsored_brands',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_DISPLAY = 'react.ad_optimization.data_inspector.ad_type.sponsored_display',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_TYPE_SPONSORED_VIDEOS = 'react.ad_optimization.data_inspector.ad_type.sponsored_videos',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_CAMPAIGNS = 'react.ad_optimization.data_inspector.ad_level.campaigns',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_ADGROUPS = 'react.ad_optimization.data_inspector.ad_level.adGroups',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PRODUCT_ADS = 'react.ad_optimization.data_inspector.ad_level.productAds',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PROFILES = 'react.ad_optimization.data_inspector.ad_level.profiles',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_TARGETS = 'react.ad_optimization.data_inspector.ad_level.targets',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_KEYWORDS_ADS = 'react.ad_optimization.data_inspector.ad_level.keywordAds',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PRODUCT_TARGETS = 'react.ad_optimization.data_inspector.ad_level.productTargets',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_CONTEXTUAL_TARGETS = 'react.ad_optimization.data_inspector.ad_level.contextualTargets',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_SB_ADS = 'react.ad_optimization.data_inspector.ad_level.sbAds',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_SV_ADS = 'react.ad_optimization.data_inspector.ad_level.sv_ads',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PAGETYPE = 'react.ad_optimization.data_inspector.ad_level.pageType',
  ADVERTISING_OPTIMIZATION_WALMART_BUDGET_ROLLOVER_FALSE_VALUE_TOOLTIP = 'react.ad_optimization.walmar.budget_rollover_false_value_tooltip',
  ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_AD_LEVEL_PLATFORM = 'react.ad_optimization.data_inspector.ad_level.platform',
  ADVERTISING_OPTIMIZATION_LEARN_MORE_TEXT = 'react.ad_optimization.data_status.link_learn_more',
  ADVERTISING_OPTIMIZATION_DATA_STATUS_TEXT = 'react.ad_optimization.data_status.body_text',
  ADVERTISING_OPTIMIZATION_AMAZON = 'react.ad_optimization.amazon',
  ADVERTISING_OPTIMIZATION_WALMART = 'react.ad_optimization.walmart',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_PAGE_HEADER_SUBTEXT = 'react.ad_optimization.connection_in_sync_page.header_subtext',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_ANALYZE_AD_PERFORMANCE_HEADER = 'react.ad_optimization.connection_in_sync_page.analyze_ad_performance_header',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_ANALYZE_AD_PERFORMANCE_SUBTEXT = 'react.ad_optimization.connection_in_sync_page.analyze_ad_performance_subtext',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_BID_AUTOMATION_HEADER = 'react.ad_optimization.connection_in_sync_page.bid_automation_header',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_BID_AUTOMATION_SUBTEXT = 'react.ad_optimization.connection_in_sync_page.bid_automation_subtext',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_MANAGE_AD_HEADER = 'react.ad_optimization.connection_in_sync_page.manage_ad_header',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_MANAGE_AD_SUBTEXT = 'react.ad_optimization.connection_in_sync_page.manage_ad_subtext',
  ADVERTISING_OPTIMIZATION_CONNECTION_IN_PROGRESS_CHECK_SYNC_STATUS_BTN = 'react.ad_optimization.connection_in_sync_page.check_status_btn',
  ADVERTISING_OPTIMIZATION_BULKEDIT_MODAL_ERROR_TOAST_TITLE = 'react.ad_optimization.ads_manager.bulk_edit_modal.error_toast_title',
  ADVERTISING_OPTIMIZATION_BULKEDIT_MODAL_ERROR_TOAST_MESSAGE = 'react.ad_optimization.ads_manager.bulk_edit_modal.error_toast_message',

  KEYWORD_ACTIONS_TITLE = 'react.pages.keyword_action.title',
  KEYWORD_ACTIONS_BREADCRUMB_LINK = 'react.pages.keyword_action.breadcrumb_link_text',
  KEYWORD_RECOMMENDATIONS_ADD_KEYWORDS = 'react.keyword_recommendations.table.add_keywords',
  KEYWORD_RECOMMENDATION_DISMISS_KEYWORDS = 'react.keyword_recommendations.table.dismiss_keywords_button',
  KEYWORD_RECOMMENDATIONS_DESTINATION_AD_GROUP = 'react.keyword_recommendations.table.destination_ad_group',
  KEYWORD_RECOMMENDATIONS_EDIT_SELECTED = 'react.keyword_recommendations.table.edit_selected',
  KEYWORD_RECOMMENDATIONS_ADD_KEYWORDS_SUCCESS = 'react.keyword_recommendations.table.add_keywords.success',
  KEYWORD_RECOMMENDATIONS_ADD_ASINS_SUCCESS = 'react.keyword_recommendations.table.add_asins.success',
  KEYWORD_RECOMMENDATIONS_DISMISS_KEYWORDS_SUCCESS = 'react.keyword_recommendations.table.dismiss_keywords.success',
  KEYWORD_RECOMMENDATIONS_DISMISS_ASINS_SUCCESS = 'react.keyword_recommendations.table.dismiss_asins.success',
  KEYWORD_RECOMMENDATIONS_ADDING_KEYWORDS = 'react.keyword_recommendations.table.adding_keywords',
  KEYWORD_RECOMMENDATIONS_ADDING_ASINS = 'react.keyword_recommendations.table.adding_asins',
  KEYWORD_RECOMMENDATIONS_DISMISS_KEYWORDS = 'react.keyword_recommendations.table.dismiss_keywords',
  KEYWORD_RECOMMENDATIONS_DISMISS_ASINS = 'react.keyword_recommendations.table.dismiss_asins',
  KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_ADD_KEYWORDS_HEADER = 'react.keyword_recommendations.table.action_dialog.add_keywords.header',
  KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_ADD_ASINS_HEADER = 'react.keyword_recommendations.table.action_dialog.add_asins.header',
  KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_DISMISS_KEYWORDS_HEADER = 'react.keyword_recommendations.table.action_dialog.dismiss_keywords.header',
  KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_DISMISS_ASINS_HEADER = 'react.keyword_recommendations.table.action_dialog.dismiss_asins.header',
  KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_ADD_KEYWORDS_BODY = 'react.keyword_recommendations.table.action_dialog.add_keywords.body',
  KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_DISMISS_KEYWORDS_BODY = 'react.keyword_recommendations.table.action_dialog.dismiss_keywords.body',
  KEYWORD_RECOMMENDATIONS_TABLE_SELECT_KEYWORDS_HEADER = 'react.keyword_recommendations.table.select_keywords.header',
  KEYWORD_RECOMMENDATIONS_TABLE_CREATE_KEYWORDS_SUCCESS_TOAST = 'react.keyword_recommendations.table.create_keywords.success_toast',
  KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_BODY = 'react.keyword_recommendations.table.select_keywords.select_ad_groups.body',
  KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECTED_BODY = 'react.keyword_recommendations.table.select_keywords.select_ad_groups.selected_body',
  KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECT_AD_GROUP = 'react.keyword_recommendations.table.select_keywords.select_ad_groups.select_adgroup',
  KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECT_AD_GROUPS = 'react.keyword_recommendations.table.select_keywords.select_ad_groups.select_adgroups',
  KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECTED_COUNT = 'react.keyword_recommendations.table.select_keywords.select_ad_groups.selected_count',
  KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_SELECTED_COUNT_KNOWN_AD_GROUP = 'react.keyword_recommendations.table.select_keywords.select_ad_groups.selected_count_known_ad_group',
  KEYWORD_RECOMMENDATIONS_TABLE_SELECT_AD_GROUPS_TOOLTIP = 'react.keyword_recommendations.table.select_keywords.select_ad_groups.tooltip',
  KEYWORD_RECOMMENDATIONS_TABLE_DESTINATION_CAMPAIGN_DETAILS_HEADER = 'react.keyword_recommendations.table.destination_campaign_details.header',
  KEYWORD_RECOMMENDATIONS_TABLE_KEYWORD_HEADER = 'react.keyword_recommendations.table.keywords.header',
  KEYWORD_RECOMMENDATIONS_TABLE_DETAILS_HEADER = 'react.keyword_recommendations.table.details.header',
  KEYWORD_RECOMMENDATIONS_TABLE_MATCH_TYPE_HEADER = 'react.keyword_recommendations.table.match_type.header',
  KEYWORD_RECOMMENDATIONS_TABLE_KEYWORD_TAG_HEADER = 'react.keyword_recommendations.table.keyword_tag.header',
  KEYWORD_RECOMMENDATIONS_TABLE_NEGATIVE_KEYWORD_TAG_HEADER = 'react.keyword_recommendations.table.negative_keyword_tag.header',
  KEYWORD_RECOMMENDATIONS_TABLE_ASSOCIATED_SKU_HEADER = 'react.keyword_recommendations.table.associated_sku.header',
  KEYWORD_RECOMMENDATIONS_TABLE_CAMPAIGN_ADGROUP_HEADER = 'react.keyword_recommendations.table.campaign_adgroup.header',
  KEYWORD_RECOMMENDATIONS_TABLE_CAMPAIGN_HEADER = 'react.keyword_recommendations.table.campaign.header',
  KEYWORD_RECOMMENDATIONS_TABLE_CAMPAIGN_TYPE_HEADER = 'react.keyword_recommendations.table.campaign_type.header',
  KEYWORD_RECOMMENDATIONS_TABLE_CAMPAIGN_TARGETING_TYPE_HEADER = 'react.keyword_recommendations.table.campaign_targeting_type.header',
  KEYWORD_RECOMMENDATIONS_TABLE_AD_GROUP_SKU_HEADER = 'react.keyword_recommendations.table.ad_group.header',
  KEYWORD_RECOMMENDATIONS_TABLE_REPORT_NAME_HEADER = 'react.keyword_recommendations.table.report_name.header',
  KEYWORD_RECOMMENDATIONS_TABLE_TARGET_TEXT_HEADER = 'react.keyword_recommendations.table.target_text.header',
  KEYWORD_RECOMMENDATIONS_TABLE_AD_GROUP_TAG_HEADER = 'react.keyword_recommendations.table.ad_group_tag.header',
  KEYWORD_RECOMMENDATIONS_TABLE_RANK_HEADER = 'react.keyword_recommendations.table.rank.header',
  KEYWORD_RECOMMENDATIONS_TABLE_SKU_HEADER = 'react.keyword_recommendations.table.sku.header',
  KEYWORD_RECOMMENDATIONS_TABLE_SOURCE_HEADER = 'react.keyword_recommendations.table.source.header',
  KEYWORD_RECOMMENDATIONS_TABLE_SOURCE_DETAILS_HEADER = 'react.keyword_recommendations.table.source_details.header',
  KEYWORD_RECOMMENDATIONS_TABLE_AD_SPEND_HEADER = 'react.keyword_recommendations.table.ad_spend.header',
  KEYWORD_RECOMMENDATIONS_TABLE_AD_SALES_HEADER = 'react.keyword_recommendations.table.ad_sales.header',
  KEYWORD_RECOMMENDATIONS_TABLE_ACOS_HEADER = 'react.keyword_recommendations.table.acos.header',
  KEYWORD_RECOMMENDATIONS_TABLE_ROAS_HEADER = 'react.keyword_recommendations.table.roas.header',
  KEYWORD_RECOMMENDATIONS_TABLE_PRODUCT_NAME_HEADER = 'react.keyword_recommendations.table.product_name.header',
  KEYWORD_RECOMMENDATIONS_TABLE_AD_CONVERSIONS_HEADER = 'react.keyword_recommendations.table.ad_conversions.header',
  KEYWORD_RECOMMENDATIONS_TABLE_AD_CONVERSION_RATE_HEADER = 'react.keyword_recommendations.table.ad_conversion_rate.header',
  KEYWORD_RECOMMENDATIONS_TABLE_COST_PER_CLICK_HEADER = 'react.keyword_recommendations.table.cost_per_click.header',
  KEYWORD_RECOMMENDATIONS_TABLE_CLICK_THROUGH_RATE_HEADER = 'react.keyword_recommendations.table.click_through_rate.header',
  KEYWORD_RECOMMENDATIONS_TABLE_IMPRESSIONS_HEADER = 'react.keyword_recommendations.table.impressions.header',
  KEYWORD_RECOMMENDATIONS_TABLE_CLICKS_HEADER = 'react.keyword_recommendations.table.clicks.header',
  KEYWORD_RECOMMENDATIONS_TABLE_TRAILING_PERFORMANCE_HEADER = 'react.keyword_recommendations.table.trail_performance.header',
  KEYWORD_RECOMMENDATIONS_TABLE_AMAZON_EXTERNAL_ITEM_ID_HEADER = 'react.keyword_recommendations.table.amazon.external_item_id.header',
  KEYWORD_RECOMMENDATIONS_TABLE_WALMART_EXTERNAL_ITEM_ID_HEADER = 'react.keyword_recommendations.table.walmart.external_item_id.header',
  KEYWORD_RECOMMENDATIONS_TABLE_NO_DATA_TITLE = 'react.keyword_recommendations.table.no_data.title',
  KEYWORD_RECOMMENDATIONS_TABLE_NO_DATA_DESCRIPTION = 'react.keyword_recommendations.table.no_data.description',
  KEYWORD_RECOMMENDATIONS_BUTTON_ACCEPT_TITLE = 'react.keyword_recommendations.button.accept.title',
  KEYWORD_RECOMMENDATIONS_BUTTON_EDIT_MATCH_TYPE_TITLE = 'react.keyword_recommendations.button.edit_match_type.title',
  KEYWORD_RECOMMENDATIONS_ACTIONS_SUBMISSION_SUCCESS_DESCRIPTION = 'react.keyword_recommendations.action_submission.sucess.description',
  KEYWORD_RECOMMENDATIONS_ACTIONS_SUBMISSION_ERROR_DESCRIPTION = 'react.keyword_recommendations.action_submission.error.description',
  KEYWORD_RECOMMENDATIONS_ACTIONS_SUBMISSION_ERROR_VALIDATION_DESCRIPTION = 'react.keyword_recommendations.action_submission.error.validation.description',
  KEYWORD_RECOMMENDATIONS_TABLE_SEARCH_KEYWORD_PLACEHOLDER_DESCRIPTION = 'react.keyword_recommendations.search_keyword.placeholder.description',
  KEYWORD_RECOMMENDATIONS_TABLE_SOURCES_HEADER = 'react.keyword_recommendations.table.sources.header',
  KEYWORD_RECOMMENDATIONS_TABLE_REASON_HEADER = 'react.keyword_recommendations.table.reason.header',
  KEYWORD_RECOMMENDATIONS_TABLE_CREATE_IN_AD_GROUP_HEADER = 'react.keyword_recommendations.table.create_in_ad_group.header',
  KEYWORD_RECOMMENDATIONS_TABLE_KEYWORD_RECOMMENDATION_HEADER = 'react.keyword_recommendations.table.keyword_recommendation.header',
  KEYWORD_RECOMMENDATIONS_TABLE_KEYWORD_TO_ADD_HEADER = 'react.keyword_recommendations.table.keyword_to_add.header',
  KEYWORD_RECOMMENDATIONS_TABLE_PRODUCT_ASINS_TO_ADD_HEADER = 'react.keyword_recommendations.table.product_asins_to_add.header',
  KEYWORD_RECOMMENDATIONS_TABLE_NEGATIVE_KEYWORD_RECOMMENDATION_HEADER = 'react.keyword_recommendations.table.negative_keyword_recommendation.header',
  KEYWORD_RECOMMENDATIONS_TABLE_REASON_SEARCH_TERMS_GENERATED = 'react.keyword_recommendations.table.reason.search_terms_generated',
  KEYWORD_RECOMMENDATIONS_TABLE_REASON_AD_CONVERSIONS = 'react.keyword_recommendations.table.reason.ad_conversions',
  KEYWORD_RECOMMENDATIONS_TABLE_REASON_CLICKS = 'react.keyword_recommendations.table.reason.clicks',
  KEYWORD_RECOMMENDATIONS_TABLE_DESTINATION_AD_GROUP_NAME_FILTER = 'react.keyword_recommendations.table.create_in_ad_group.filter.ad_group_name',
  KEYWORD_RECOMMENDATIONS_TABLE_DESTINATION_AD_GROUP_TARGETING_TYPE_FILTER = 'react.keyword_recommendations.table.create_in_ad_group.filter.ad_group_targeting_type',
  KEYWORD_RECOMMENDATIONS_TABLE_DESTINATION_AD_GROUP_TAG_FILTER = 'react.keyword_recommendations.table.create_in_ad_group.filter.ad_group_tag',
  KEYWORD_RECOMMENDATIONS_TABLE_SOURCES_KEYWORD_TEXT_FILTER = 'react.keyword_recommendations.table.sources.filter.keyword_text',

  NEGATIVE_KEYWORD_RECOMMENDATIONS_ADDING_KEYWORDS = 'react.negative_keyword_recommendations.table.adding_keywords',
  NEGATIVE_KEYWORD_RECOMMENDATIONS_DISMISS_KEYWORDS = 'react.negative_keyword_recommendations.table.dismiss_keywords',
  NEGATIVE_KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_DISMISS_KEYWORDS_HEADER = 'react.negative_keyword_recommendations.table.action_dialog.dismiss_keywords.header',
  NEGATIVE_KEYWORD_RECOMMENDATIONS_ACCEPT_KEYWORDS_SUCCESS = 'react.negative_keyword_recommendations.table.accept_keywords.success',
  NEGATIVE_KEYWORD_RECOMMENDATIONS_DISMISS_KEYWORDS_SUCCESS = 'react.negative_keyword_recommendations.table.dismiss_keywords.success',
  NEGATIVE_KEYWORD_RECOMMENDATIONS_EDIT_KEYWORDS_FAILURE = 'react.negative_keyword_recommendations.table.edit_keywords.failure',

  KEYWORD_RECOMMENDATIONS_BUTTON_REJECT_TITLE = 'react.keyword_recommendations.button.reject.title',
  KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_BANNER = 'react.keyword_recommendations.keyword_selection_banner',
  KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_HEADER = 'react.keyword_recommendations.slideout.header',
  KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_PLACEHOLDER = 'react.keyword_recommendations.slideout.placeholder',
  KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_SHOWING_RESULTS_MAX = 'react.keyword_recommendations.slideout.showing_results_max',
  KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_USE_SEARCH_BAR = 'react.keyword_recommendations.slideout.use_search_bar',
  KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_SEARCHING_TEXT = 'react.keyword_recommendations.slideout.searching_text',
  KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_SEARCHING_DESCRIPTION = 'react.keyword_recommendations.slideout.searching_description',
  KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_LOADING_TEXT = 'react.keyword_recommendations.slideout.loading_text',
  KEYWORD_RECOMMENDATIONS_KEYWORD_SELECTION_SLIDEOUT_LOADING_DESCRIPTION = 'react.keyword_recommendations.slideout.loading_description',
  KEYWORD_RECOMMENDATIONS_CAMPAIGN_SELECTION_COLUMN_PLACEHOLDER = 'react.keyword_recommendations.campaign_selection_column.placeholder',
  KEYWORD_RECOMMENDATIONS_AD_GROUP_SELECTION_COLUMN_PLACEHOLDER = 'react.keyword_recommendations.ad_group_selection_column.placeholder',
  KEYWORD_RECOMMENDATIONS_VIEW_SKUS_KEYWORDS_SLIDEOUT_HEADER = 'react.keyword_recommendations.view.skus.keywords.slideout.header',
  KEYWORD_RECOMMENDATIONS_VIEW_SKUS_KEYWORDS_SLIDEOUT_SKUS = 'react.keyword_recommendations.view.skus.keywords.slideout.skus',
  KEYWORD_RECOMMENDATIONS_VIEW_SKUS_KEYWORDS_SLIDEOUT_KEYWORDS = 'react.keyword_recommendations.view.skus.keywords.slideout.keywords',
  KEYWORD_RECOMMENDATIONS_VIEW_SKUS_KEYWORDS_SLIDEOUT_CHANGE_AD_GROUP = 'react.keyword_recommendations.view.skus.keywords.slideout.change_ad_group',
  KEYWORD_RECOMMENDATIONS_VIEW_SKUS_KEYWORDS_SLIDEOUT_KEYWORDS_COUNT = 'react.keyword_recommendations.view.skus.keywords.slideout.keywords_count',

  KEYWORD_RECOMMENDATIONS_SOURCE_AO = 'react.keyword_recommendations.source.ao',
  KEYWORD_RECOMMENDATIONS_SOURCE_AMAZON_SEARCH_TERM_REPORT = 'react.keyword_recommendations.source.amazon_search_term_report',
  KEYWORD_RECOMMENDATIONS_SOURCE_AMAZON_SEARCH_TERM_REPORTS = 'react.keyword_recommendations.source.amazon_search_term_reports',
  KEYWORD_RECOMMENDATIONS_SOURCE_AMAZON_SUGGESTED_KEYWORD = 'react.keyword_recommendations.source.amazon_suggested_keyword',
  KEYWORD_RECOMMENDATIONS_SOURCE_WALMART_SUGGESTED_KEYWORD = 'react.keyword_recommendations.source.walmart_suggested_keyword',
  KEYWORD_RECOMMENDATIONS_SOURCE_CHAT_GPT_SUGGESTED_KEYWORD = 'react.keyword_recommendations.source.chat_gpt_suggested_keyword',
  KEYWORD_RECOMMENDATIONS_SOURCE_WALMART_ITEM_KEYWORD_REPORT = 'react.keyword_recommendations.source.walmart_item_keyword_report',
  KEYWORD_RECOMMENDATIONS_SOURCE_WALMART_KEYWORD_ANALYTIC_REPORT = 'react.keyword_recommendations.source.walmart_keyword_analytic_report',
  KEYWORD_RECOMMENDATIONS_SOURCE_OTHER = 'react.keyword_recommendations.source.other',

  KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_BRAND = 'react.keyword_recommendations.keyword_tag.brand',
  KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_COMPETITOR = 'react.keyword_recommendations.keyword_tag.competitor',
  KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_GENERIC = 'react.keyword_recommendations.keyword_tag.generic',
  KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_UNKNOWN = 'react.keyword_recommendations.keyword_tag.unknown',
  KEYWORD_RECOMMENDATIONS_KEYWORD_TAG_AUTO = 'react.keyword_recommendations.keyword_tag.auto',

  KEYWORD_RECOMMENDATIONS_MODAL_EDIT_MATCH_TYPE_HEADER = 'react.keyword_recommendations.modal.edit_match_type.header',
  KEYWORD_RECOMMENDATIONS_MODAL_EDIT_MATCH_TYPE_BODY = 'react.keyword_recommendations.modal.edit_match_type.body',

  KEYWORD_RECOMMENDATIONS_MATCH_TYPE_BROAD = 'react.keyword_recommendations.match_type.broad',
  KEYWORD_RECOMMENDATIONS_MATCH_TYPE_PHRASE = 'react.keyword_recommendations.match_type.phrase',
  KEYWORD_RECOMMENDATIONS_MATCH_TYPE_EXACT = 'react.keyword_recommendations.match_type.exact',

  NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_REASON_HEADER = 'react.negative_keyword_recommendations.table.reason.header',
  NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_REASON_AD_CONVERSIONS = 'react.negative_keyword_recommendations.table.reason.ad_conversions',
  NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_REASON_CLICKS = 'react.negative_keyword_recommendations.table.reason.clicks',
  NEGATIVE_KEYWORD_RECOMMENDATIONS_TABLE_REASON_AD_SPEND = 'react.negative_keyword_recommendations.table.reason.ad_spend',

  BULKEDIT_MODAL_APPLICABLE_COUNT = 'react.bulk_edit_modal.applicable_count',
  BULKEDIT_MODAL_OPERATOR_SET_TO = 'react.bulk_edit_modal.operator_set_to',
  BULKEDIT_MODAL_OPERATOR_INCREASE_BY_UNIT = 'react.bulk_edit_modal.operator_increase_by_unit',
  BULKEDIT_MODAL_OPERATOR_DECREASE_BY_UNIT = 'react.bulk_edit_modal.operator_decrease_by_unit',
  BULKEDIT_MODAL_OPERATOR_INCREASE_BY_PERCENT = 'react.bulk_edit_modal.operator_increase_by_percent',
  BULKEDIT_MODAL_OPERATOR_DECREASE_BY_PERCENT = 'react.bulk_edit_modal.operator_decrease_by_percent',
  BULKEDIT_MODAL_OPERATOR_PP = 'react.bulk_edit_modal.operator_pp',
  BULKEDIT_MODAL_NO_OPERATOR_MESSAGE = 'react.bulk_edit_modal.no_operator_message',
  BULKEDIT_MODAL_NO_VALUE_MESSAGE = 'react.bulk_edit_modal.no_value_message',
  BULKEDIT_MODAL_SET_TO_MESSAGE = 'react.bulk_edit_modal.set_to_message',
  BULKEDIT_MODAL_INCREASE_BY_UNIT_MESSAGE = 'react.bulk_edit_modal.increase_by_unit_message',
  BULKEDIT_MODAL_DECREASE_BY_UNIT_MESSAGE = 'react.bulk_edit_modal.decrease_by_unit_message',
  BULKEDIT_MODAL_INCREASE_BY_PERCENT_MESSAGE = 'react.bulk_edit_modal.increase_by_percent_message',
  BULKEDIT_MODAL_DECREASE_BY_PERCENT_MESSAGE = 'react.bulk_edit_modal.decrease_by_percent_message',

  BULKEDIT_MODAL_INCREASE_BY_UNIT_NO_VALUE_MESSAGE = 'react.bulk_edit_modal.increase_by_unit_no_value_message',
  BULKEDIT_MODAL_SET_TO_NO_VALUE_MESSAGE = 'react.bulk_edit_modal.set_to_no_value_message',
  BULKEDIT_MODAL_DECREASE_BY_UNIT_NO_VALUE_MESSAGE = 'react.bulk_edit_modal.decrease_by_unit_no_value_message',
  BULKEDIT_MODAL_INCREASE_BY_PERCENT_NO_VALUE_MESSAGE = 'react.bulk_edit_modal.increase_by_percent_no_value_message',
  BULKEDIT_MODAL_DECREASE_BY_PERCENT_NO_VALUE_MESSAGE = 'react.bulk_edit_modal.decrease_by_percent_no_value_message',

  ADS_MANAGER_CAMPAIGNS_TABLE_DETAILS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.campaigns_table.details_group',
  ADS_MANAGER_CAMPAIGNS_TABLE_CHANNEL_SETTINGS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.campaigns_table.channel_settings_group',
  ADS_MANAGER_CAMPAIGNS_TABLE_FLYWHEEL_SETTINGS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.campaigns_table.flywheel_settings_group',
  ADS_MANAGER_CAMPAIGNS_TABLE_PERFORMANCE_COLUMN_GROUP = 'react.ad_optimization.ads_manager.campaigns_table.performance_group',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_name',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MERCHANT_NAME = 'react.ad_optimization.ads_manager.campaigns_table.column.merchant_name',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TYPE = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_type',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PORTFOLIO_NAME = 'react.ad_optimization.ads_manager.campaigns_table.column.portfolio_name',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_GROUP = 'react.ad_optimization.ads_manager.campaigns_table.column.group',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_COST_TYPE = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_cost_type',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TACTIC = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_tactic',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BIDDING_STRATEGY = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bidding_strategy',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BIDDING_STRATEGY_TOOLTIP = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bidding_strategy.tooltip',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PRODUCT_PAGE_BID_MULTIPILER = 'react.ad_optimization.ads_manager.campaigns_table.column.product_page_bid_multiplier',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOP_OF_SEARCH_BID_MULTIPLER = 'react.ad_optimization.ads_manager.campaigns_table.column.top_of_search_bid_multiplier',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_CONVERSIONS = 'react.ad_optimization.ads_manager.campaigns_table.column.ad_conversions',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES = 'react.ad_optimization.ads_manager.campaigns_table.column.new_to_brand_ad_sales',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_targeting_type',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ADDED_BY = 'react.ad_optimization.ads_manager.campaigns_table.column.added_by',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ADDED_BY_FLYWHEEL = 'react.ad_optimization.ads_manager.campaigns_table.column.added_by_flywheel',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ADDED_BY_EXTERNAL = 'react.ad_optimization.ads_manager.campaigns_table.column.added_by_external',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CREATED_AT = 'react.ad_optimization.ads_manager.campaigns_table.column.created_at',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_START_DATE = 'react.ad_optimization.ads_manager.campaigns_table.column.start_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_END_DATE = 'react.ad_optimization.ads_manager.campaigns_table.column.end_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_type',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.campaigns_table.column.daily_budget',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_LIFETIME_BUDGET = 'react.ad_optimization.ads_manager.campaigns_table.column.lifetime_budget',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_ROLLOVER = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_rollover',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_inclusion_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ITEM_BUY_BOX_PLACEMENT = 'react.ad_optimization.ads_manager.campaigns_table.column.item_buy_box_placement',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ITEM_CAROUSEL_PLACEMENT = 'react.ad_optimization.ads_manager.campaigns_table.column.item_carousel_placement',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_BUY_BOX = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_buy_box',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_SEARCH_INGRID = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_search_ingrid',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_HOME_PAGE = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_home_page',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_STOCK_UP = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_stock_up',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PBM_DESKTOP = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_desktop',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_APP = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_app',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MOBILE = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_bid_multiplier_mobile',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS = 'react.ad_optimization.ads_manager.campaigns_table.column.new_to_brand_orders',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PERCENTAGE_OF_ORDERS_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.campaigns_table.column.percentage_of_orders_new_to_brand',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS_RATE = 'react.ad_optimization.ads_manager.campaigns_table.column.new_to_brand_orders_rate',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PERCENTAGE_OF_SALES_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.campaigns_table.column.percentage_of_sales_new_to_brand',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_AD_UNITS_SOLD = 'react.ad_optimization.ads_manager.campaigns_table.column.new_to_brand_ad_units_sold',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PERCENTAGE_OF_AD_UNITS_SOLD_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.campaigns_table.column.percentage_of_ad_units_sold_new_to_brand',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AUTOMATION_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.column.automation_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MACS_TARGET = 'react.ad_optimization.ads_manager.campaigns_table.column.macs_target',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MIN_BID = 'react.ad_optimization.ads_manager.campaigns_table.column.min_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MINIMUM_BID = 'react.ad_optimization.ads_manager.campaigns_table.column.minimum_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MAX_BID = 'react.ad_optimization.ads_manager.campaigns_table.column.max_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MAXIMUM_BID = 'react.ad_optimization.ads_manager.campaigns_table.column.maximum_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_IMPRESSIONS = 'react.ad_optimization.ads_manager.campaigns_table.column.impressions',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CLICKS = 'react.ad_optimization.ads_manager.campaigns_table.column.clicks',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SPEND = 'react.ad_optimization.ads_manager.campaigns_table.column.ad_spend',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SALES_TOTAL = 'react.ad_optimization.ads_manager.campaigns_table.column.ad_sales_total',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_UNITS_SOLD = 'react.ad_optimization.ads_manager.campaigns_table.column.units_sold',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ADVERTISEDSKUUNITS = 'react.ad_optimization.ads_manager.campaigns_table.column.advertised_sku_units',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_OTHERSKUUNITS = 'react.ad_optimization.ads_manager.campaigns_table.column.others_sku_units',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_CONVERSION_RATE = 'react.ad_optimization.ads_manager.campaigns_table.column.total_conversion_rate',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_ACOS = 'react.ad_optimization.ads_manager.campaigns_table.column.total_acos',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_ROAS = 'react.ad_optimization.ads_manager.campaigns_table.column.total_roas',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_CTR = 'react.ad_optimization.ads_manager.campaigns_table.column.click_through_rate',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_TOTAL_AVG_CPC = 'react.ad_optimization.ads_manager.campaigns_table.column.avg_cost_per_click',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_SALES_DIRECT = 'react.ad_optimization.ads_manager.campaigns_table.column.ad_sales_direct',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ACOS_DIRECT = 'react.ad_optimization.ads_manager.campaigns_table.column.direct_acos',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ROAS_DIRECT = 'react.ad_optimization.ads_manager.campaigns_table.column.direct_roas',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_RELATED_CLICK_REVENUE = 'react.ad_optimization.ads_manager.campaigns_table.column.related_click_revenue',
  ADS_MANAGER_CAMPAIGNS_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.ad_optimization.ads_manager.campaigns_table.footer.total_items_text',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NO_END_DATE = 'react.ad_optimization.ads_manager.campaigns_table.no_end_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_SET_NO_END_DATE = 'react.ad_optimization.ads_manager.campaigns_table.set_no_end_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AD_FORMAT = 'react.ad_optimization.ads_manager.campaigns_ad_format',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION = 'react.ad_optimization.ads_manager.amazon_bid_optimization',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS = 'react.ad_optimization.ads_manager.campaign_serving_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BELOW_SEARCH_BID_MULTIPLIER = 'react.ad_optimization.ads_manager.below_search_bid_multiplier',
  ADS_MANAGER_CAMPAIGNS_TABLE_NO_MATCHED_RESULTS_TITLE = 'react.ad_optimization.ads_manager.campaigns_table.no_matched_results.title',
  ADS_MANAGER_CAMPAIGNS_TABLE_NO_MATCHED_RESULTS_DESCRIPTION = 'react.ad_optimization.ads_manager.campaigns_table.no_matched_results.description',
  ADS_MANAGER_CAMPAIGNS_TABLE_DATA_CONNECTION_ERROR_TITLE = 'react.ad_optimization.ads_manager.campaigns_table.data_connection_error.title',
  ADS_MANAGER_CAMPAIGNS_TABLE_DATA_CONNECTION_ERROR_DESCRIPTION = 'react.ad_optimization.ads_manager.campaigns_table.data_connection_error.description',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS_FILTER_EXCLUDED = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_inclusion_status.filter.excluded',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_PLACEMENT_INCLUSION_STATUS_FILTER_INCLUDED = 'react.ad_optimization.ads_manager.campaigns_table.column.placement_inclusion_status.filter.included',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE_FILTER_AUTO = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_targeting_type.filter.auto',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE_FILTER_MANUAL = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_targeting_type.filter.manual',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_LIVE = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.live',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_EXTEND = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.extend',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_ENABLED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.enabled',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_PAUSED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.paused',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_ARCHIVED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.archived',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_DRAFT = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.draft',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_PENDING = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.pending',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_COMPLETED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.completed',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_RESCHEDULED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.rescheduled',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_PROPOSAL = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.proposal',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_STATUS_FILTER_SCHEDULED = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_status.filter.scheduled',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_DAILY = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_type.filter.daily',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_LIFETIME = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_type.filter.lifetime',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_TOTAL = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_type.filter.total',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BUDGET_TYPE_FILTER_BOTH = 'react.ad_optimization.ads_manager.campaigns_table.column.budget_type.filter.both',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_campaign_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_DAILY_BUDGET = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_daily_budget',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MIN_BID = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_min_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MAX_BID = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_max_bid',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_LIFETIME_BUDGET = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_lifetime_budget',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_BUDGET_ROLLOVER = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_budget_rollover',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MACS_TARGET = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_macs_target',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AMAZON_BIDDING_STRATEGY = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_amazon_bidding_strategy',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AMAZON_BID_OPTIMIZATION = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_amazon_bid_optimization',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_NEW_VALUE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.new_value',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SB_VIDEO_CAMPAIGNS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.sb_video_campaigns',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SB_CAMPAIGNS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.sb_campaigns',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_END_DATE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_end_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.select_an_option',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_INCLUSION = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_inclusion',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_SEARCH_INGRID = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_search_ingrid',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_SEARCH_INGRID_TOOLTIP = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_search_ingrid_tooltip',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_WITH_DIFFERENT_STATUS_TOOLTIP = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaigns_with_different_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_WITH_ATLEAST_ONE_ADVERTISING_GOAL_TOOLTIP = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaigns_with_atleast_one_smart_campaign',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_SEARCH_CAROUSEL = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_search_carousel',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_ITEM_BUYBOX = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_item_buybox',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_ITEM_CAROUSEL = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_item_carousel',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIERS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_placement_bid_multipliers',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLATFORM_BID_MULTIPLEIRS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_platform_bid_multipliers',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTOMATION_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_automation_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PORTFOLIO = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit_portfolio',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_INCLUSION = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_inclusion',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_bid_multipliers',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLATFORM_BID_MULTIPLEIRS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.platform_bid_multipliers',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_ENABLED = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.enabled',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_PAUSED = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.paused',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_COMPLETED = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.completed',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_ENABLED_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.enabled_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_PAUSED_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.paused_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_CAMPAIGN_STATUS_OPTION_ARCHIVED_STATUS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.campaign_status.archived_status',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_SEARCH_IN_GRID = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.search_in_grid',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_BUY_BOX = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.buy_box',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_HOME_PAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.home_page',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_STOCKUP = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.stockup',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLATFORM_BID_MULTIPLIERS_APP = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.platform_multipliers.app',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLATFORM_BID_MULTIPLIERS_MOBILE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.platform_multipliers.mobile',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLATFORM_BID_MULTIPLIERS_DESKTOP = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.platform_multipliers.desktop',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_AUTO_CAMPAIGNS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.auto_campaigns',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_MANUAL_CAMPAIGNS = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.manual_campaigns',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_TOP_OF_SEARCH = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.top_of_search',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_BELOW_TOP_OF_SEARCH = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.below_top_of_search',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_PLACEMENT_BID_MULTIPLIERS_PRODUCT_PAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_multipliers.product_page',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_AMAZON_BIDDING_STRATEGY = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.amazon_bidding_strategy',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_STATUS_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.campaign_status_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_DAILY_BUDGET_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.daily_budget_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_LIFETIME_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.lifetime_budget_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIER_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.placement_bid_multiplier_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTOMATION_STATUS_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.automation_status_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AMAZON_BID_OPTIMIZATION_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.amazon_bid_automation_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_TARGET_STATUS_APPLICABLE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.target_status_applicable_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_TARGET_STATUS_APPLICABLE_MESSAGE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.target_status_applicable_message_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGN_STATUS_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.campaign_status_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SEARCH_CAROUSEL_INCLUSION_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.search_carousel_inclusion_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_DAILY_BUDGET_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.daily_budget_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_LIFETIME_BUDGET_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.lifetime_budget_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIER_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.placement_bid_multiplier_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_END_DATE_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.end_date_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_NO_END_DATE_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.no_end_date_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_WALMART_BULK_EDIT_MODAL_END_DATE_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.walmart.campaigns_table.bulk_edit_modal.edit.end_date_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTOMATION_STATUS_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.automation_status_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AMAZON_BID_OPTIMIZATION_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.edit.amazon_bid_optimization_applicable_reason',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_BUDGET_MIN_VALUE_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.error_messages.budget.minimum_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_LIFETIME_BUDGET_MIN_VALUE_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.error_messages.lifetime_budget.error',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MACS_TARGET_MIN_VALUE_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.error_messages.macs_target.minimum_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MACS_TARGET_ERROR_BETWEEN_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.error_messages.macs_target.between_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_BUDGET_COMPARE_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.error_messages.budget.compare_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_DAILY_BUDGET_AMAZON_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.daily_budget.amazon.error_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_PLACEMENT_BID_MULTIPLIER_AMAZON_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_bid_multipliers.amazon.error_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_EDIT_PORTFOLIO_GROUP_HEADER = 'react.ad_optimization.ads_manager.campaigns_table.edit_portfolio.group_header',
  ADS_MANAGER_CAMPAIGNS_TABLE_NO_PORTFOLIO_LABEL = 'react.ad_optimization.ads_manager.campaigns_table.portfolio.no_portfolio_label',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MIN_BID_AMAZON_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.min_bid.amazon.error_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MAX_BID_AMAZON_ERROR_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.max_bid.amazon.error_message',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_SET_END_DATE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.set_end_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MANUAL_CAMPAIGNS_PLACEMENT_BID_MULTIPLIER = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_bud_multipliers_for_manual_campaigns',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTO_CAMPAIGNS_PLACEMENT_BID_MULTIPLIER = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.placement_bud_multipliers_for_auto_campaigns',
  ADS_AMANGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_MANUAL_CAMPAIGNS_PLACEMENT_BID_MULTIPLIERS_UPDATE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.manual_campaigns_placement_bid_multipliers_update',
  ADS_AMANGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_AUTO_CAMPAIGNS_PLACEMENT_BID_MULTIPLIERS_UPDATE_MESSAGE = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.auto_campaigns_placement_bid_multipliers_update',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGNS_SLIDEOUT_HEADER = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit_modal.slideout_header',
  ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_EXTEND = 'react.ad_optimization.campaigns_table.invalid.end_date.extend',
  ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_BLANK = 'react.ad_optimization.campaigns_table.invalid.end_date.blank',
  ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_FORMAT = 'react.ad_optimization.campaigns_table.invalid.end_date.format',
  ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_SMALLER_THAN_CURRENT_DATE = 'react.ad_optimization.campaigns_table.invalid.end_date.smaller_than_current_date',
  ADS_MANAGER_CAMPAIGNS_INVALID_END_DATE_SMALLER_THAN_START_DATE = 'react.ad_optimization.campaigns_table.invalid.end_date.smaller_than_start_date',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME_INVALID_CHARACTER_LENGTH = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_name.invalid_characters_length',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME_INVALID_ALL_SPECIAL_CHARACTERS = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_name.invalid_all_special_characters',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CAMPAIGN_NAME_INVALID_CHARACTERS = 'react.ad_optimization.ads_manager.campaigns_table.column.campaign_name.invalid_characters',
  ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_DOWN = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bidding_strategy.dynamic_bids_down',
  ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BID_OPTIMIZATION_OFF = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bid_optimization.off',
  ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BID_OPTIMIZATION_ON = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bid_optimization.on',
  ADS_MANAGER_CAMPAIGNS_TABLE_AUTOMATION_STATUS_ACTIVE = 'react.ad_optimization.ads_manager.campaigns_table.column.automation_status.active',
  ADS_MANAGER_CAMPAIGNS_TABLE_AUTOMATION_STATUS_INACTIVE = 'react.ad_optimization.ads_manager.campaigns_table.column.automation_status.inactive',
  ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_UP_AND_DOWN = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bidding_strategy.dynamic_bids_up_and_down',
  ADS_MANAGER_CAMPAIGNS_TABLE_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_FIXED = 'react.ad_optimization.ads_manager.campaigns_table.column.amazon_bidding_strategy.dynamic_bids_fixed',
  ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_ORDERS = 'react.ad_optimization.ads_manager.campaigns_table.column.orders',
  ADS_MANAGER_CAMPAIGNS_NO_PORTFOLIOS_FOUND = 'react.ad_optimization.ads_manager.campaigns.no_portfolios_found',
  ADS_MANAGER_CAMPAIGNS_TABLE_BUTTON_SAVE_CHANGE = 'react.ad_optimization.ads_manager.campaigns_table.buton.save_change',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_ADVERTISING_GOAL_APPLICABLE_HEADER = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit.advertising_goal.applicable.header',
  ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_ADVERTISING_GOAL_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.campaigns_table.bulk_edit.advertising_goal.applicable.reason',
  ADS_MANAGER_AD_ITEMS_TABLE_BULK_EDIT_MODAL_BODY = 'react.ad_optimization.ads_manager.ad_items.bulk_edit_modal.body',
  ADS_MANAGER_PRODUCT_AD_TABLE_BULK_EDIT_MODAL_BODY = 'react.ad_optimization.ads_manager.product_ads.bulk_edit_modal.body',
  ADS_MANAGER_PRODUCT_AD_TABLE_BULK_EDIT_MODAL_HEADER_TEXT = 'react.ad_optimization.ads_manager.product_ads.bulk_edit_modal.header_text',
  ADS_MANAGER_AD_ITEMS_TABLE_PRODUCT = 'react.ad_optimization.ad_items.product',
  ADS_MANAGER_AD_ITEMS_TABLE_SKU = 'react.ad_optimization.ad_items.product_ad_sku',
  ADS_MANAGER_AD_ITEMS_TABLE_ASIN = 'react.ad_optimization.ad_items.product_ad_asin',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_ITEM_NAME = 'react.ad_optimization.ad_items.ad_item_name',
  ADS_MANAGER_AD_ITEMS_TABLE_MERCHANT_NAME = 'react.ad_optimization.ad_items.merchant_name',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_ITEM_ID = 'react.ad_optimization.ad_items.ad_item_id',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_GROUP_NAME = 'react.ad_optimization.ad_items.ad_group_name',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_GROUP_STATUS = 'react.ad_optimization.ad_items.ad_group_status',
  ADS_MANAGER_AD_ITEMS_TABLE_PRODUCT_AD_STATUS = 'react.ad_optimization.ad_items.product_ad_status',
  ADS_MANAGER_AD_ITEMS_TABLE_PORTFOLIO_NAME = 'react.ad_optimization.ad_items.portfolio_name',
  ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_COST_TYPE = 'react.ad_optimization.ad_items.campaign_cost_type',
  ADS_MANAGER_AD_ITEMS_TABLE_BID = 'react.ad_optimization.ad_items.bid',
  ADS_MANAGER_AD_ITEMS_TABLE_IMPRESSIONS = 'react.ad_optimization.ad_items.impressions',
  ADS_MANAGER_AD_ITEMS_TABLE_CLICKS = 'react.ad_optimization.ad_items.clicks',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_SPEND = 'react.ad_optimization.ad_items.ad_spend',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_UNITS_SOLD = 'react.ad_optimization.ad_items.ad_units_sold',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_CONVERSION_RATE = 'react.ad_optimization.ad_items.ad_conversion_rate',
  ADS_MANAGER_AD_ITEMS_TABLE_TOTAL_ACOS = 'react.ad_optimization.ad_items.acos_total',
  ADS_MANAGER_AD_ITEMS_TABLE_TOTAL_ROAS = 'react.ad_optimization.ad_items.roas_total',
  ADS_MANAGER_AD_ITEMS_TABLE_CLICK_THROUGH_RATE = 'react.ad_optimization.ad_items.click_through_rate',
  ADS_MANAGER_AD_ITEMS_TABLE_COST_PER_CLICK = 'react.ad_optimization.ad_items.cost_per_click',
  ADS_MANAGER_AD_ITEMS_TABLE_DIRECT_AD_SALES = 'react.ad_optimization.ad_items.ad_sales_direct',
  ADS_MANAGER_AD_ITEMS_TABLE_TOTAL_AD_SALES = 'react.ad_optimization.ad_items.ad_sales_total',
  ADS_MANAGER_AD_ITEMS_TABLE_DIRECT_ACOS = 'react.ad_optimization.ad_items.acos_direct',
  ADS_MANAGER_AD_ITEMS_TABLE_DIRECT_ROAS = 'react.ad_optimization.ad_items.roas_direct',
  ADS_MANAGER_AD_ITEMS_TABLE_RELATED_CLICK_REVENUE = 'react.ad_optimization.ad_items.related_click_revenue',
  ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_NAME = 'react.ad_optimization.ad_items.campaign_name',
  ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_AND_AD_GROUP_NAME = 'react.ad_optimization.ad_items.campaign_and_adGroup_name',
  ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_TYPE = 'react.ad_optimization.ad_items.campaign_type',
  ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_TARGETING_TYPE = 'react.ad_optimization.ad_items.campaign_targeting_type',
  ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_STATUS = 'react.ad_optimization.ad_items.campaign_status',
  ADS_MANAGER_AD_ITEMS_TABLE_AD_ITEM_DETAILS = 'react.ad_optimization.ad_items.ad_item_details',
  ADS_MANAGER_AD_ITEMS_TABLE_CHANNEL_SETTINGS = 'react.ad_optimization.ad_items.channel_settings',
  ADS_MANAGER_AD_ITEMS_TABLE_PERFORMANCE = 'react.ad_optimization.ad_items.performance',
  ADS_MANAGER_AD_ITEMS_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.ad_optimization.ads_manager.ad_items_table.footer.total_items_text',
  ADS_MANAGER_AD_ITEMS_TABLE_COLUMN_CAMPAIGN_TACTIC = 'react.ad_optimization.ads_manager.ad_items_table.column.campaign_tactic',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.ad_items_table.column.daily_budget',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_TOTAL_BUDGET = 'react.ad_optimization.ads_manager.ad_items_table.column.total_budget',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_AD_CONVERSIONS = 'react.ad_optimization.ads_manager.ad_items_table.column.campaign_ad_conversions',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES = 'react.ad_optimization.ads_manager.ad_items_table.column.new_to_brand_ad_sales',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_NEW_TO_BRAND_ORDERS = 'react.ad_optimization.ads_manager.ad_items_table.column.new_to_brand_orders',
  ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_NEW_TO_BRAND_AD_UNITS_SOLD = 'react.ad_optimization.ads_manager.ad_items_table.column.new_to_brand_ad_units_sold',
  ADS_MANAGER_AD_ITEMS_INVALID_BID_LESS_THAN = 'react.ad_optimization.ad_items.invalid.bid.less.than',
  ADS_MANAGER_AD_ITEMS_INVALID_BID_MORE_THAN = 'react.ad_optimization.ad_items.invalid.bid.more.than',
  ADS_MANAGER_AD_ITEMS_INVALID_BID = 'react.ad_optimization.ad_items.invalid.bid.value',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ENABLED = 'react.ad_optimization.ad_items.bulk_edit.modal_status_enabled_option',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ENABLED_PRODUCTADS = 'react.ad_optimization.ad_items.bulk_edit.modal_status_enabled_option_productads',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_DISABLED = 'react.ad_optimization.ad_items.bulk_edit.modal_status_disabled_option',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_PAUSED = 'react.ad_optimization.ad_items.bulk_edit.modal_status_paused_option',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_DISABLED_PRODUCTADS = 'react.ad_optimization.ad_items.bulk_edit.modal_status_disabled_option_productads',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_PAUSED_PRODUCTADS = 'react.ad_optimization.ad_items.bulk_edit.modal_status_paused_option_productads',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ARCHIVED = 'react.ad_optimization.ad_items.bulk_edit.modal_status_archived_option',
  ADS_MANAGER_AD_ITEMS_EDIT_MODAL_STATUS_ARCHIVED_PRODUCTADS = 'react.ad_optimization.ad_items.bulk_edit.modal_status_archived_option_productads',
  ADS_MANAGER_AD_ITEMS_TABLE_BULK_EDIT_MODAL_VALIDATION_AT_LEAST = 'react.ad_optimization.ads_manager.ad_items.bulk_edit_modalat_least',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ENABLED = 'react.ad_optimization.targets.bulk_edit.modal_status_enabled_option',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ENABLED_PRODUCTTARGETS = 'react.ad_optimization.targets.bulk_edit.modal_status_enabled_option_product_targets',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_PAUSED = 'react.ad_optimization.targets.bulk_edit.modal_status_paused_option',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_PAUSED_PRODUCTTARGETS = 'react.ad_optimization.targets.bulk_edit.modal_status_paused_option_product_targets',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ARCHIVED = 'react.ad_optimization.targets.bulk_edit.modal_status_archived_option',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_DELETED = 'react.ad_optimization.targets.bulk_edit.modal_status_deleted_option',
  ADS_MANAGER_TARGETS_EDIT_MODAL_STATUS_ARCHIVED_PRODUCTTARGETS = 'react.ad_optimization.targets.bulk_edit.modal_status_archived_option_product_targets',
  ADS_MANAGER_TARGETS_TAGS_TOOLTIP_NO_TAG_DISABLED_MESSAGE = 'react.ad_optimization.targets.tooltip.tags.no_tag_disabled_message',
  ADS_MANAGER_ADGROUP_TABLE_AUTOMATION_COLUMN_UNSET_STATUS_TOOLTIP = 'react.ad_optimization.ads_manager.adsgroup_table.automation_status_unset_tooltip',
  ADS_MANAGER_ADGROUP_TABLE_DETAILS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.adsgroup_table.details_group',
  ADS_MANAGER_ADGROUP_TABLE_CHANNEL_SETTINGS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.adsgroup_table.channel_settings_group',
  ADS_MANAGER_ADGROUP_TABLE_FLYWHEEL_SETTINGS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.adsgroup_table.flywheel_settings_group',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_GROUP = 'react.ad_optimization.ads_manager.adsgroup_table.predictive_ai_column_group',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING = 'react.ad_optimization.ads_manager.adsgroup_table.predictive_ai_column_ai_bidding',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING_SMART = 'react.ad_optimization.ads_manager.adsgroup_table.predictive_ai_column_ai_bidding.smart',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING_BIDDING_ONLY = 'react.ad_optimization.ads_manager.adsgroup_table.predictive_ai_column_ai_bidding.bidding_only',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_AI_BIDDING_NO_AI = 'react.ad_optimization.ads_manager.adsgroup_table.predictive_ai_column_ai_bidding.no_ai',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_ESTIMATED_PRE_AD_GROSS_MARGIN = 'react.ad_optimization.ads_manager.adsgroup_table.estimated_pre_ad_gross_margin.column',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_ESTIMATED_PRE_AD_GROSS_MARGIN_TOOLTIP_NO_PROD_CONNECTION = 'react.ad_optimization.ads_manager.adsgroup_table.estimated_pre_ad_gross_margin.tooltip.no_product_connection',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_ESTIMATED_PRE_AD_GROSS_MARGIN_TOOLTIP_NO_ACTIVE_PRODUCT = 'react.ad_optimization.ads_manager.adsgroup_table.estimated_pre_ad_gross_margin.tooltip.no_active_product',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_ACOS_LIMIT = 'react.ad_optimization.ads_manager.adsgroup_table.column.acos_limit',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_BID_MODIFIER = 'react.ad_optimization.ads_manager.adsgroup_table.column.bid_modifier',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_BID_MODIFIER_MODAL_HEADER = 'react.ad_optimization.ads_manager.adsgroup_table.column.bid_modifier.modal.header',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_BID_MODIFIER_MODAL_TIME_PERIOD = 'react.ad_optimization.ads_manager.adsgroup_table.column.bid_modifier.modal.time_period',
  ADS_MANAGER_ADGROUP_TABLE_PREDICTIVE_AI_COLUMN_BID_MODIFIER_MODAL_INFO_MESSAGE = 'react.ad_optimization.ads_manager.adsgroup_table.column.bid_modifier.modal.info.message',
  ADS_MANAGER_ADGROUP_TABLE_KEYWORDS_COLUMN_GROUP = 'react.ad_optimization.ads_manager.adsgroup_table.keywords_column_group',
  ADS_MANAGER_ADGROUP_TABLE_KEYWORDS_COLUMN_TAGS = 'react.ad_optimization.ads_manager.adsgroup_table.keywords_column_tags',
  ADS_MANAGER_ADGROUP_TABLE_KEYWORDS_COLUMN_RECOMMENDATIONS = 'react.ad_optimization.ads_manager.adsgroup_table.keywords_column_recommendations',
  ADS_MANAGER_ADGROUP_TABLE_PERFORMANCE_COLUMN_GROUP = 'react.ad_optimization.ads_manager.adsgroup_table.performance_group',
  ADS_MANAGER_ADGROUP_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.ad_optimization.ads_manager.adsgroup_table.footer.total_items_text',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_SLIDEOUT_MODAL_TITLE = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_slideout_modal.title',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_NEW_VALUE = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.new_value',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AUTO_AD_GROUPS = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.auto_ad_groups',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_KEYWORD_AD_GROUPS = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.keyword_ad_groups',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_MACS_TARGET = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.macs_target',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_DEFAULT_BID = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.default_bid',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_LABELS = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.labels',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_VALUE_FOR_VCPM = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.value_for_vcpm',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_VALUE_FOR_CPC = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.value_for_cpc',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_MINIMUM_BID = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.minimum_bid',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_MAXIMUM_BID = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.maximum_bid',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_AUTOMATION_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.automation_status',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_ADGROUP_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.adgroup_status',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_ADGROUP_REVIEW_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.adgroup_review_status',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_BID_HELP_TEXT = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.bid_help_text',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_BID_HELP_CONTENT = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.bid_help_content',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_AUTOMATION_STATUS_HELP_TEXT = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.automation_status_help_text',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_AUTOMATION_STATUS_HELP_CONTENT = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.automation_status_help_content',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_KEYWORD_RECOMMENDATION_HELP_CONTENT = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.keyword_recommendation_help_content',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_ADGROUP_REVIEW_STATUS_HELP_CONTENT = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.adgroup_review_status_help_content',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_LABELS_OPTION_AUTO = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.labels.option.auto',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_LABELS_OPTION_BRAND = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.labels.option.brand',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_LABELS_OPTION_COMPETITOR = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.labels.option.competitor',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_EDIT_LABELS_OPTION_GENERIC = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.labels.option.generic',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.select_an_option',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ACTIVE_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.active_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_INACTIVE_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.inactive_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ENABLED_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.enabled_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_DISABLED_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.disabled_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AMAZON_ARCHIVED_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.amazon.archived_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AMAZON_ENABLED_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.amazon.enabled_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AMAZON_PAUSED_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.amazon.paused_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AMAZON_CANCELLED_LABEL = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.amazon.cancelled_label',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AI_KEYWORD_ADD_AUTOMATICALLY = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.ai_recommendation_keyword.add_automatically',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AI_KEYWORD_REVIEW_RECOMMENDATION = 'react.ad_optimization.ads_manager.adsgroup_table.bulk_edit_modal.edit.ai_recommendation_keyword.review_recommendation',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AI_BIDDING_NOT_APPLICABLE_HEADER = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.ai_bidding.applicable.header',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AI_BIDDING_NOT_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.ai_bidding.applicable.reason',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_AI_BIDDING_OPTION_DISABLE_TOOLTIP = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.ai_bidding.option.disable.tooltip',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_BID_MODIFIER_NOT_APPLICABLE_HEADER = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.bid_modifier.applicable.header',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_BID_MODIFIER_NOT_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.bid_modifier.applicable.reason',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_BID_MODIFIER_OPTION_DISABLE_TOOLTIP = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.bid_modifier.option.disable.tooltip',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ACOS_LIMIT_OPTION_DISABLE_TOOLTIP = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.acos_limit.option.disable.tooltip',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ACOS_LIMIT_NOT_APPLICABLE_HEADER = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.acos_limit.applicable.header',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_ACOS_LIMIT_NOT_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.acos_limit.applicable.reason',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_MIN_MAX_BID_OPTION_DISABLE_TOOLTIP = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.min_max_bid.option.disable.tooltip',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_MIN_MAX_BID_NOT_APPLICABLE_HEADER = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.min_max_bid.applicable.header',
  ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_MODAL_MIN_MAX_BID_NOT_APPLICABLE_REASON = 'react.ad_optimization.ads_manager.adgroup_table.bulk_edit.min_max_bid.applicable.reason',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME = 'react.ad_optimization.ads_manager.adsgroup_table.column.adgroup_name',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_GROUP = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_group',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_daily_budget',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_TOTAL_BUDGET = 'react.ad_optimization.ads_manager.adsgroup_table.column.total_budget',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_AD_GROUP_DEFAULT_BID = 'react.ad_optimization.ads_manager.adsgroup_table.column.ad_group_default_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MERCHANT_NAME = 'react.ad_optimization.ads_manager.adsgroup_table.column.merchant_name',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_NAME = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_name',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_TYPE = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_type',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_targeting_type',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_LABELS = 'react.ad_optimization.ads_manager.adgroup_table.column.labels',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_status',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.column.adgroup_status',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_AI_RECOMMENDED_KEYWORD = 'react.ad_optimization.ads_manager.adsgroup_table.column.ai_recommended_keyword',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AI_RECOMMENDED_KEYWORD = 'react.ad_optimization.ads_manager.adsgroup_table.column.edit_ai_recommended_keyword',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_REVIEW_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.column.adgroup_review_status',
  ADS_MANAGER_PRODUCTADS_TABLE_COLUMN_PRODUCT_AD_STATUS_TOOLTIP = 'react.ad_optimization.ads_manager.productads_table.column.product_ad_status_tooltip',
  ADS_MANAGER_PRODUCTADS_TABLE_COLUMN_TARGETSTATUS_TOOLTIP = 'react.ad_optimization.ads_manager.productads_table.column.keyword_status_tooltip',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_PORTFOLIO_NAME = 'react.ad_optimization.ads_manager.adgroup_table.column.portfolio_name',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_COST_TYPE = 'react.ad_optimization.ads_manager.adgroup_table.column.campaign_cost_type',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_AUTOMATION_STATUS = 'react.ad_optimization.ads_manager.adsgroup_table.column.bid_automation_status',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MACS_TARGET = 'react.ad_optimization.ads_manager.adsgroup_table.column.macs_target',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_DEFAULT_BID = 'react.ad_optimization.ads_manager.adsgroup_table.column.default_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_BID = 'react.ad_optimization.ads_manager.adsgroup_table.column.min_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_OPTION_OPTIMIZED = 'react.ad_optimization.ads_manager.adsgroup_table.column.bid_option_optimized',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_OPTION_OPTIMIZED_DESCRIPTION = 'react.ad_optimization.ads_manager.adsgroup_table.column.bid_option_optimized.description',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_OPTION_ADVANCED = 'react.ad_optimization.ads_manager.adsgroup_table.column.bid_option_advanced',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_OPTION_ADVANCED_DESCRIPTION = 'react.ad_optimization.ads_manager.adsgroup_table.column.bid_option_advanced.description',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_MAX_BID_LEARN_MORE_TEXT = 'react.ad_optimization.ads_manager.adsgroup_table.column.min_max_bid_learn_more',

  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_BID_RESTRICT_MODAL_HEADER = 'react.ad_optimization.ads_manager.adsgroup_table.column.min_bid_restricted_modal.header',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_BID_RESTRICT_MODAL_DESCRIPTION = 'react.ad_optimization.ads_manager.adsgroup_table.column.min_bid_restricted_modal.description',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_BID_RESTRICT_MODAL_KEEP_MIN_BID = 'react.ad_optimization.ads_manager.adsgroup_table.column.min_bid_restricted_modal.keep_min_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MAX_BID_RESTRICT_MODAL_HEADER = 'react.ad_optimization.ads_manager.adsgroup_table.column.max_bid_restricted_modal.header',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MAX_BID_RESTRICT_MODAL_DESCRIPTION = 'react.ad_optimization.ads_manager.adsgroup_table.column.max_bid_restricted_modal.description',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MAX_BID_RESTRICT_MODAL_KEEP_MAX_BID = 'react.ad_optimization.ads_manager.adsgroup_table.column.max_bid_restricted_modal.keep_max_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_MAX_BID_RESTRICT_MODAL_RE_ENABLE = 'react.ad_optimization.ads_manager.adsgroup_table.column.max_bid_restricted_modal.re_enable',

  ADS_MANAGER_ADGROUP_TABLE_COLUMN_MAX_BID = 'react.ad_optimization.ads_manager.adsgroup_table.column.max_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_IMPRESSIONS = 'react.ad_optimization.ads_manager.adsgroup_table.column.impressions',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CLICKS = 'react.ad_optimization.ads_manager.adsgroup_table.column.clicks',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADSPEND = 'react.ad_optimization.ads_manager.adsgroup_table.column.adspend',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADSALES = 'react.ad_optimization.ads_manager.adsgroup_table.column.adsales',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_UNITS_SOLD = 'react.ad_optimization.ads_manager.adsgroup_table.column.units_sold',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CONVERSION_RATE = 'react.ad_optimization.ads_manager.adsgroup_table.column.conversion_rate',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ACOS = 'react.ad_optimization.ads_manager.adsgroup_table.column.acos',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ROAS = 'react.ad_optimization.ads_manager.adsgroup_table.column.roas',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CLICK_THROUGH_RATE = 'react.ad_optimization.ads_manager.adsgroup_table.column.click_through_rate',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_AVERAGE_COST_PER_CLICK = 'react.ad_optimization.ads_manager.adsgroup_table.column.average_cost_per_click',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_DIRECT_ADSALES = 'react.ad_optimization.ads_manager.adsgroup_table.column.direct_adsales',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_DIRECT_ACOS = 'react.ad_optimization.ads_manager.adsgroup_table.column.direct_acos',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_DIRECT_ROAS = 'react.ad_optimization.ads_manager.adsgroup_table.column.direct_roas',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_RELATED_CLICK_REVENUE = 'react.ad_optimization.ads_manager.adsgroup_table.column.related_click_revenue',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_AD_CONVERSIONS = 'react.ad_optimization.ads_manager.adsgroup_table.column.ad_conversions',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_NEW_TO_BRAND_AD_ORDERS = 'react.ad_optimization.ads_manager.adsgroup_table.column.new_to_brand_ad_orders',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES = 'react.ad_optimization.ads_manager.adsgroup_table.column.new_to_brand_ad_sales',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_NEW_TO_BRAND_AD_UNITS_SOLD = 'react.ad_optimization.ads_manager.adsgroup_table.column.new_to_brand_ad_units_sold',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CAMPAIGN_TACTIC = 'react.ad_optimization.ads_manager.adsgroup_table.column.campaign_tactic',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_TOTAL_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.adsgroup_table.column.total_attributed_sales',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ORDERS = 'react.ad_optimization.ads_manager.adsgroup_table.column.orders',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_VIDEO_IMPRESSIONS = 'react.ad_optimization.ads_manager.adsgroup_table.column.video_impressions',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_VIEWABLE_IMPRESSIONS = 'react.ad_optimization.ads_manager.adsgroup_table.column.viewable_impressions',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_VIEW_THROUGH_RATE = 'react.ad_optimization.ads_manager.adsgroup_table.column.view_through_rate',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_CLICK_THROUGH_RATE_FOR_VIEWS = 'react.ad_optimization.ads_manager.adsgroup_table.column.click_through_rate_for_views',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_VIEW_THROUGH_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.adsgroup_table.column.view_through_attributed_sales',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_VIEW_THROUGH_ORDERS = 'react.ad_optimization.ads_manager.adsgroup_table.column.view_through_orders',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_VIEW_THROUGH_UNITS_SOLD = 'react.ad_optimization.ads_manager.adsgroup_table.column.view_through_units_sold',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_TOTAL_COMPLETE_VIEW_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.adsgroup_table.column.total_complete_view_attributed_sales',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADVERTISED_COMPLETE_VIEW_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.adsgroup_table.column.advertised_complete_view_attributed_sales',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_OTHER_COMPLETE_VIEW_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.adsgroup_table.column.other_complete_view_attributed_sales',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_COMPLETE_VIEW_ORDERS = 'react.ad_optimization.ads_manager.adsgroup_table.column.complete_view_orders',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_COMPLETE_VIEW_UNITS_SOLD = 'react.ad_optimization.ads_manager.adsgroup_table.column.complete_view_units_sold',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_VIDEO_FIRST_QUARTILE_VIEWS = 'react.ad_optimization.ads_manager.adsgroup_table.column.video_first_quartile_views',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_VIDEO_MIDPOINT_VIEWS = 'react.ad_optimization.ads_manager.adsgroup_table.column.video_midpoint_views',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_VIDEO_THIRD_QUARTILE_VIEWS = 'react.ad_optimization.ads_manager.adsgroup_table.column.video_third_quartile_views',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_VIDEO_COMPLETE_VIEWS = 'react.ad_optimization.ads_manager.adsgroup_table.column.video_complete_views',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_VIDEO_UNMUTES = 'react.ad_optimization.ads_manager.adsgroup_table.column.video_unmutes',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_FIVE_SECOND_VIEWS = 'react.ad_optimization.ads_manager.adsgroup_table.column.five_second_views',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_FIVE_SECOND_VIEW_RATE = 'react.ad_optimization.ads_manager.adsgroup_table.column.five_second_view_rate',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_GREATER_THAN_ERROR_TEXT = 'react.ad_optimization.ads_manager.targets_table.edit_bid.greater_than_error_text',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_LESS_THAN_ERROR_TEXT = 'react.ad_optimization.ads_manager.targets_table.edit_bid.less_than_error_text',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_INVALID_VALUE_ERROR_TEXT = 'react.ad_optimization.ads_manager.targets_table.edit_bid.invalid_value_error_text',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_INVALID_VALUE_GREATER_THAN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.targets_table.edit_bid.greater_than_daily_budget_error_text',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_BID_INVALID_VALUE_GREATER_THAN_HALF_OF_DAILY_BUDGET = 'react.ad_optimization.ads_manager.targets_table.edit_bid.greater_than_half_of_daily_budget_error_text',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_INVALID_MACS_VALUE = 'react.ad_optimization.ads_manager.adgroups_table.column.invalid.macs',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_MACS_VALUE = 'react.ad_optimization.ads_manager.adgroups_table.column.amazon.invalid.macs',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_INVALID_GREATER_THAN_MAX_BID = 'react.ad_optimization.ads_manager.adgroups_table.column.invalid_greater_than_maxbid.minbid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_EMPTY_VALUES = 'react.ad_optimization.ads_manager.adgroups_table.column.empty_values',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_INVALID_LESS_THAN_MIN_BID = 'react.ad_optimization.ads_manager.adgroups_table.column.invalid_less_than_minbid.maxbid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_BID = 'react.ad_optimization.ads_manager.adgroups_table.column.amazon.invalid_less_than_minbid.maxbid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_ACOSBID = 'react.ad_optimization.ads_manager.adgroups_table.column.amazon.invalid_less_than_minbid.acosbid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_DEFAULT_BID = 'react.ad_optimization.ads_manager.adgroups_table.column.amazon.invalid_defaul_bid',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_DEFAULT_BID_FOR_SP = 'react.ad_optimization.ads_manager.adgroups_table.column.amazon.invalid_default_bid_for_sp',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_CHARACTERS = 'react.ad_optimization.ads_manager.adgroups_table.column.adgroup_name.invalid_characters',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_ALL_SPECIAL_CHARACTERS = 'react.ad_optimization.ads_manager.adgroups_table.column.adgroup_name.invalid_all_special_characters',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_CHARACTER_LENGTH = 'react.ad_optimization.ads_manager.adgroups_table.column.adgroup_name.invalid_characters_length',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_INVALID_CHARACTER_LENGTH_AMAZON = 'react.ad_optimization.ads_manager.adgroups_table.column.adgroup_name.amazon.invalid_characters_length',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADGROUP_NAME_DUPLICATE_AMAZON = 'react.ad_optimization.ads_manager.adgroups_table.column.adgroup_name.amazon.duplicate_name',
  ADS_MANAGER_ADGROUP_TABLE_COLUMN_FLYWHEEL_SETTINGS_DISABLED_FOR_LEGACY_CAMPAIGNS = 'react.ad_optimization.ads_manager.adgroups_table.flywheel_settings.disabled.legancy_campaigns',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_CONVERSION_RATE = 'react.ad_optimization.ads_manager.targets_table.column.ad_conversion_rate',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_GROUP_DETAILS = 'react.ad_optimization.ads_manager.targets_table.column.group.details',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_GROUP_CHANNEL_SETTINGS = 'react.ad_optimization.ads_manager.targets_table.column.group.channel_settings',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_GROUP_PERFORMANCE = 'react.ad_optimization.ads_manager.targets_table.column.group.performance',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_BODY = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.body',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_TARGET_STATUS_ENABLED = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.target_status.enabled',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_TARGET_STATUS_PAUSED = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.target_status.paused',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_SELECT_AN_OPTION = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.select_an_option',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NEW_VALUE = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.new_value',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_SELECTION_COUNT = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.selection_count',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_BID_LEGACY_NOT_BIDDABLE = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.bid.legacy_not_biddable',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NEGATIVE_KEYWORD_TARGETS_CANNOT_BE_PAUSED = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.target_status.negative_keyword_target_cannot_be_paused',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NEGATIVE_CAMPAIGN_KEYWORD_TARGETS_CANNOT_BE_PAUSED = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.target_status.negative_campaign_keyword_target_cannot_be_paused',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NEGATIVE_CAMPAIGN_KEYWORD_TARGETS_CANNOT_BE_ARCHIVED = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.target_status.negative_keyword_target_cannot_be_archived',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NON_NEGATIVE_CAMPAIGN_KEYWORD_TARGETS_CANNOT_BE_DELETED = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.target_status.non_negative_keyword_target_cannot_be_deleted',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NEGATIVE_PRODUCT_TARGETS_CANNOT_BE_PAUSED = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.target_status.negative_product_target_cannot_be_paused',
  ADS_MANAGER_TARGETS_TABLE_BULK_EDIT_MODAL_NEGATIVE_TARGETS_CANNOT_BE_PAUSED = 'react.ad_optimization.ads_manager.targets_table.bulk_edit_modal.target_status.negative_target_cannot_be_paused',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_TEXT = 'react.ad_optimization.ads_manager.targets_table.column.target_text',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_MATCH_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.match_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_DATE_ADDED = 'react.ad_optimization.ads_manager.targets_table.column.date_added',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.target_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_FLYWHEEL_AI_ENABLED = 'react.ad_optimization.ads_manager.targets_table.column.flywheel_ai_enabled',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_MERCHANT_NAME = 'react.ad_optimization.ads_manager.targets_table.column.merchant_name',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_GROUP_NAME = 'react.ad_optimization.ads_manager.targets_table.column.ad_group_name',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_GROUP_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.ad_group_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_NAME = 'react.ad_optimization.ads_manager.targets_table.column.campaign_name',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.campaign_targeting_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.campaign_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.campaign_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_PORTFOLIO_NAME = 'react.ad_optimization.ads_manager.targets_table.column.portfolio_name',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_COST_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.campaign_cost_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_KEYWORD_REVIEW_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.keyword_review_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_PRODUCT_REVIEW_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.product_review_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_ADGROUP_REVIEW_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.adgroup_review_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_BID = 'react.ad_optimization.ads_manager.targets_table.column.bid',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGET_STATUS = 'react.ad_optimization.ads_manager.targets_table.column.target_status',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_IMPRESSIONS = 'react.ad_optimization.ads_manager.targets_table.column.impressions',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CLICKS = 'react.ad_optimization.ads_manager.targets_table.column.clicks',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_SPEND = 'react.ad_optimization.ads_manager.targets_table.column.ad_spend',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TOTAL_AD_SALES = 'react.ad_optimization.ads_manager.targets_table.column.total_ad_sales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_UNITS_SOLD = 'react.ad_optimization.ads_manager.targets_table.column.units_sold',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TOTAL_ACOS = 'react.ad_optimization.ads_manager.targets_table.column.total_acos',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TOTAL_ROAS = 'react.ad_optimization.ads_manager.targets_table.column.total_roas',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CLICK_THROUGH_RATE = 'react.ad_optimization.ads_manager.targets_table.column.click_through_rate',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AVERAGE_COST_PER_CLICK = 'react.ad_optimization.ads_manager.targets_table.column.average_cost_per_click',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_AD_SALES = 'react.ad_optimization.ads_manager.targets_table.column.direct_adsales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_ACOS = 'react.ad_optimization.ads_manager.targets_table.column.direct_acos',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_DIRECT_ROAS = 'react.ad_optimization.ads_manager.targets_table.column.direct_roas',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_RELATED_CLICK_REVENUE = 'react.ad_optimization.ads_manager.targets_table.column.related_click_revenue',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_EXPRESSION_TYPE = 'react.ad_optimization.ads_manager.targets_table.column.expression_type',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_TACTIC = 'react.ad_optimization.ads_manager.targets_table.column.campaign_tactic',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_AD_SALES = 'react.ad_optimization.ads_manager.targets_table.column.new_to_brand_ad_sales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.targets_table.column.campaign_daily_budget',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_LIFETIME_BUDGET = 'react.ad_optimization.ads_manager.targets_table.column.campaign_lifetime_budget',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_KEYWORD = 'react.ad_optimization.ads_manager.targets_table.column.keyword',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_AD_FORMAT = 'react.ad_optimization.ads_manager.targets_table.column.campaign_ad_format',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_CONVERSIONS = 'react.ad_optimization.ads_manager.targets_table.column.ad_conversions',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS = 'react.ad_optimization.ads_manager.targets_table.column.new_to_brand_orders',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_PERCENTAGE_OF_ORDERS_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.targets_table.column.percentage_of_orders_new_to_brand',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS_RATE = 'react.ad_optimization.ads_manager.targets_table.column.new_to_brand_orders_rate',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_SALES = 'react.ad_optimization.ads_manager.targets_table.column.new_to_brand_sales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_PERCENTAGE_OF_SALES_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.targets_table.column.percentage_of_sales_new_to_brand',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_NEW_TO_BRAND_AD_UNITS_SOLD = 'react.ad_optimization.ads_manager.targets_table.column.new_to_brand_ad_units_sold',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_PERCENTAGE_OF_AD_UNITS_SOLD_NEW_TO_BRAND = 'react.ad_optimization.ads_manager.targets_table.column.percentage_of_ad_units_sold_new_to_brand',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_VIDEO_IMPRESSIONS = 'react.ad_optimization.ads_manager.targets_table.column.video_impressions',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_VIEWABLE_IMPRESSIONS = 'react.ad_optimization.ads_manager.targets_table.column.viewable_impressions',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_VIEW_THROUGH_RATE = 'react.ad_optimization.ads_manager.targets_table.column.view_through_rate',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_CLICK_THROUGH_RATE_FOR_VIEWS = 'react.ad_optimization.ads_manager.targets_table.column.click_through_rate_for_views',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_VIEW_THROUGH_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.targets_table.column.view_through_attributed_sales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_VIEW_THROUGH_ORDERS = 'react.ad_optimization.ads_manager.targets_table.column.view_through_orders',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_VIEW_THROUGH_UNITS_SOLD = 'react.ad_optimization.ads_manager.targets_table.column.view_through_units_sold',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TOTAL_COMPLETE_VIEW_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.targets_table.column.total_complete_view_attributed_sales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_ADVERTISED_COMPLETE_VIEW_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.targets_table.column.advertised_complete_view_attributed_sales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_OTHER_COMPLETE_VIEW_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.targets_table.column.other_complete_view_attributed_sales',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_COMPLETE_VIEW_ORDERS = 'react.ad_optimization.ads_manager.targets_table.column.complete_view_orders',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_COMPLETE_VIEW_UNITS_SOLD = 'react.ad_optimization.ads_manager.targets_table.column.complete_view_units_sold',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_VIDEO_FIRST_QUARTILE_VIEWS = 'react.ad_optimization.ads_manager.targets_table.column.video_first_quartile_views',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_VIDEO_MID_POINT_VIEWS = 'react.ad_optimization.ads_manager.targets_table.column.video_mid_point_views',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_VIDEO_THIRD_QUARTILE_VIEWS = 'react.ad_optimization.ads_manager.targets_table.column.video_third_quartile_views',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_VIDEO_COMPLETE_VIEWS = 'react.ad_optimization.ads_manager.targets_table.column.video_complete_views',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_VIDEO_UNMUTES = 'react.ad_optimization.ads_manager.targets_table.column.video_unmutes',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_FIVE_SECOND_VIEWS = 'react.ad_optimization.ads_manager.targets_table.column.five_second_views',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_FIVE_SECOND_VIEW_RATE = 'react.ad_optimization.ads_manager.targets_table.column.five_second_view_rate',
  ADS_MANAGER_TARGETS_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.ad_optimization.ads_manager.targets_table.footer.total_items_text',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_same_as',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_AUDEINCE_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.audience_same_as',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_CATEGORY_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_category_same_as',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_BRAND_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_brand_same_as',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_LESS_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_less_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_GREATER_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_greater_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_BETWEEN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_between',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_LESS_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_less_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_GREATER_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_greater_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_BETWEEN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_between',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SHIPPING_IS_PRIME_ELIGIBLE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_shipping_is_prime_eligible',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_AGE_RANGE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_age_rage',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_GENRE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_genre',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_LOOKBACK = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.lookback',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SAME_AS_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_same_as_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_AUDIENCE_SAME_AS_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.audeince_same_as_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_CATEGORY_SAME_AS_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_category_same_as_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_BRAND_SAME_AS_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_brand_same_as_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_LESS_THAN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_less_than_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_GREATER_THAN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_greater_than_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_PRICE_BETWEEN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_price_between_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_LESS_THAN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_less_than_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_GREATER_THAN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_greater_than_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_STAR_RATING_BETWEEN_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_star_rating_between_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SHIPPING_IS_PRIME_ELIGIBLE_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_shipping_is_prime_eligible_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_AGE_RANGE_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_age_rage_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_GENRE_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_genre_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_LOOKBACK_WITHOUT_EXPRESSION_VALUE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.lookback_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_HIGH_REL_MATCHES = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.query_high_rel_matches',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_QUERY_BROAD_REL_MATCHES = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.query_broad_rel_matches',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_SUBSTITUTE_RELATED = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_substitute_related',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_ASIN_ACCESSORY_RELATED = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_accessory_related',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_SIMILAR_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.similar_product',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_EXACT_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.exact_product',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_EXACT_ASIN_IS_PRIME_SHIPPING_ELIGIBLE = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.asin_is_prime_shipping_eligible_as_without_expression_value',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_RELATED_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.expression.related_product',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.conditional_text_is',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_LESS_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.conditional_text_is_less_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_GREATER_THAN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.conditional_text_is_greater_than',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_IS_BETWEEN = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.conditional_text_is_between',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_TEXT_COLON = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.conditional_text_colon',
  ADS_MANAGER_TARGETS_TABLE_COLUMN_TARGETING_EXPRESSION_CONDITIONAL_POST_FIX_STARS = 'react.ad_optimization.ads_manager.targets_table.column.targeting_expression.post_fix_stars',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_AGE_RANGE_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_age_range_same_as',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_same_as',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_EXPANDED_FROM = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_expanded_from',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_PURCHASES = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.purchases',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_VIEWS = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.views',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_AUDIANCE = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.audiance',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_CATEGORY_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_category_same_as',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_BRAND_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_brand_same_as',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_GENRE_SAME_AS = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_genre_same_as',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_IS_PRIME_SHIPPING_AVAILABLE = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_is_prime_shipping_available',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_BETWEEN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_price_between',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_GREATER_THAN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_price_greater_than',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_PRICE_LESS_THAN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_price_less_than',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_BETWEEN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_review_rating_between',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_GREATER_THAN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_review_rating_greater_than',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_REVIEW_RATING_LESS_THAN = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_review_rating_less_than',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_ASIN_LOOKUP = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.asin_lookup',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_SIMILAR_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.similar_product',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_EXACT_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.exact_product',
  ADS_MANAGER_TARGETS_TABLE_FILTER_EXPRESSION_TYPE_RELATED_PRODUCT = 'react.ad_optimization.ads_manager.targets_table.filter.expression_type.related_product',

  ADS_MANAGER_PROFILES_TABLE_GROUP_DETAILS = 'react.ad_optimization.ads_manager.profiles_table.details_group',
  ADS_MANAGER_PROFILES_TABLE_GROUP_CHANNEL_SETTING = 'react.ad_optimization.ads_manager.profiles_table.channel_settings_group',
  ADS_MANAGER_PROFILES_TABLE_GROUP_PERFORMANCE = 'react.ad_optimization.ads_manager.profiles_table.performance_group',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_BRAND = 'react.ad_optimization.ads_manager.profiles_table.column.brand_name',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_MERCHANT_NAME = 'react.ad_optimization.ads_manager.profiles_table.column.merchant_name',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_PROFILE_STATUS = 'react.ad_optimization.ads_manager.profiles_table.column.profile_status',
  ADS_MANAGER_PROFILES_TABLE_PROFILE_STATUS_ENABLED_TOOLTIP = 'react.ad_optimization.ads_manager.profiles_table.column.profile_status_enabled_tooltip',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_ONGOING_REVIEW_TOOLTIP_CONTENT = 'react.ad_optimization.ads_manager.profiles_table.column.ongoing_review_tooltip_content',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_PROFILE_NOT_APPROVED_TOOLTIP_CONTENT = 'react.ad_optimization.ads_manager.profiles_table.column.profile_not_approved_tooltip_content',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_NOT_PAUSED_TOOLTIP_CONTENT = 'react.ad_optimization.ads_manager.profiles_table.column.campaign_not_paused_tooltip_content',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_SPECIAL_CHARACTERS_TOOLTIP_CONTENT = 'react.ad_optimization.ads_manager.profiles_table.column.special_characters_tooltip_content',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_MAX_CHARACTERS_TOOLTIP_CONTENT = 'react.ad_optimization.ads_manager.profiles_table.column.max_characters_tooltip_content',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_EMPTY_TOOLTIP_CONTENT = 'react.ad_optimization.ads_manager.profiles_table.column.empty_tooltip_content',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICK_URL_TYPEAHEAD_TOOLTIP_CONTENT = 'react.ad_optimization.ads_manager.profiles_table.column.click_url_typeahead_tooltip_content',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_INVALID_COLUMN_TOOLTIP_CONTENT = 'react.ad_optimization.ads_manager.profiles_table.column.invalid_column_tooltip_content',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_PROFILE_REVIEW_STATUS = 'react.ad_optimization.ads_manager.profiles_table.column.profileReviewStatus',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_ADGROUP_NAME = 'react.ad_optimization.ads_manager.profiles_table.column.adgroup_name',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_ADGROUP_STATUS = 'react.ad_optimization.ads_manager.profiles_table.column.adgroup_status',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_ADGROUP_REVIEW_STATUS = 'react.ad_optimization.ads_manager.profiles_table.column.adgroup_review_status',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_NAME = 'react.ad_optimization.ads_manager.profiles_table.column.campaign_name',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_TARGETING_TYPE = 'react.ad_optimization.ads_manager.profiles_table.column.campaign_targeting_type',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_DAILY_BUDGET = 'react.ad_optimization.ads_manager.profiles_table.column.daily_budget',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_LIFETIME_BUDGET = 'react.ad_optimization.ads_manager.profiles_table.column.lifetime_budget',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_STATUS = 'react.ad_optimization.ads_manager.profiles_table.column.campaign_status',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_HEADLINE = 'react.ad_optimization.ads_manager.profiles_table.column.headline',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICK_URL = 'react.ad_optimization.ads_manager.profiles_table.column.clickUrl',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_LOGO = 'react.ad_optimization.ads_manager.profiles_table.column.logo',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_AD_SALES_TOTAL = 'react.ad_optimization.ads_manager.profiles_table.column.ad_sales_total',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_AD_SPEND = 'react.ad_optimization.ads_manager.profiles_table.column.ad_spend',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_TOTAL_ACOS = 'react.ad_optimization.ads_manager.profiles_table.column.total_acos',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_TOTAL_ROAS = 'react.ad_optimization.ads_manager.profiles_table.column.total_roas',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_TOTAL_CONVERSION_RATE = 'react.ad_optimization.ads_manager.profiles_table.column.total_conversion_rate',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_UNITS_SOLD = 'react.ad_optimization.ads_manager.profiles_table.column.units_sold',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_IMPRESSIONS = 'react.ad_optimization.ads_manager.profiles_table.column.impressions',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICKS = 'react.ad_optimization.ads_manager.profiles_table.column.clicks',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_AVG_COST_PER_CLICK = 'react.ad_optimization.ads_manager.profiles_table.column.avg_cost_per_click',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_CLICK_THROUGH_RATE = 'react.ad_optimization.ads_manager.profiles_table.column.click_through_rate',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_AD_SALES_DIRECT = 'react.ad_optimization.ads_manager.profiles_table.column.ad_sales_direct',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_DIRECT_ACOS = 'react.ad_optimization.ads_manager.profiles_table.column.direct_acos',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_DIRECT_ROAS = 'react.ad_optimization.ads_manager.profiles_table.column.direct_roas',
  ADS_MANAGER_PROFILES_TABLE_COLUMN_RELATED_CLICK_REVENUE = 'react.ad_optimization.ads_manager.profiles_table.column.related_click_revenue',
  ADS_MANAGER_PROFILES_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.ad_optimization.ads_manager.profiles_table.footer.total_items_text',
  ADS_MANAGER_PROFILE_STATUS_ENABLED = 'react.ad_optimization.ads_manager.profile_status.enabled',
  ADS_MANAGER_PROFILE_STATUS_DISABLED = 'react.ad_optimization.ads_manager.profile_status.disabled',

  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_LEGACY = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_tactic.legacy',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_PRODUCT = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_tactic.product',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_AUDIENCES = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_tactic.audiences',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_TACTIC_VIEWS = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_tactic.views',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ASIN_NOT_BUYABLE = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.asin_not_buyable',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_BILLING_ERROR = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.billing_error',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ENDED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.ended',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_LANDING_PAGE_NOT_AVAILABLE = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.landing_page_not_available',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_OUT_OF_BUDGET = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.out_of_budget',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PAUSED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.paused',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PENDING_REVIEW = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.pending_review',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_READY = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.ready',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_REJECTED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.rejected',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_DELIVERING = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.delivering',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_SCHEDULED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.scheduled',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_TERMINATED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.terminated',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_OTHER = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.other',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_INELIGIBLE = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.ineligible',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_ARCHIVED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.campaign_archived',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_ADS_NOT_DELIVERING = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.campaign_ads_not_delivering',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_OUT_OF_BUDGET = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.portfolio_out_of_budget',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ACCOUNT_OUT_OF_BUDGET = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.account_out_of_budget',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PENDING_START_DATE = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.pending_start_date',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_ENDED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.portfolio_ended',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_STATUS_ENABLED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.campaign_status_enabled',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_ADVERTISER_PAYMENT_FAILURE = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.advertiser_payment_failure',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_UNKNOWN = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.unknown',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_INCOMPLETE = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.campaign_incomplete',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_CAMPAIGN_PAUSED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.campaign_paused',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_PENDING_START_DATE = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.portfolio_pending_start_date',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_SERVING_STATUS_PORTFOLIO_ADVERTISER_ARCHIVED = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_serving_status.advertiser_archived',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION_ON = 'react.ad_optimization.ads_manager.ad_level_table_column.amazon_bid_optimization.on',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_AMAZON_BID_OPTIMIZATION_OFF = 'react.ad_optimization.ads_manager.ad_level_table_column.amazon_bid_optimization.off',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_AD_FORMAT_PRODUCT_COLLECTION = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_ad_format.product_collection',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_AD_FORMAT_VIDEO = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_ad_format.video',
  ADS_MANAGER_AD_LEVEL_TABLE_COLUMN_CAMPAIGN_AD_FORMAT_STORE_SPOTLIGHT = 'react.ad_optimization.ads_manager.ad_level_table_column.campaign_ad_format.store_spotlight',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_SPONSORED_PRODUCTS = 'react.ad_optimization.ads_manager.ad_level_table_filter.sponsored_products',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AUTO = 'react.ad_optimization.ads_manager.ad_level_table_filter.auto',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_MANUAL = 'react.ad_optimization.ads_manager.ad_level_table_filter.manual',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_LIVE = 'react.ad_optimization.ads_manager.ad_level_table_filter.live',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ENABLED = 'react.ad_optimization.ads_manager.ad_level_table_filter.enabled',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PAUSED = 'react.ad_optimization.ads_manager.ad_level_table_filter.paused',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_COMPLETED = 'react.ad_optimization.ads_manager.ad_level_table_filter.completed',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_RESCHEDULED = 'react.ad_optimization.ads_manager.ad_level_table_filter.rescheduled',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PROPOSAL = 'react.ad_optimization.ads_manager.ad_level_table_filter.proposal',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_SCHEDULED = 'react.ad_optimization.ads_manager.ad_level_table_filter.scheduled',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ACTIVE = 'react.ad_optimization.ads_manager.ad_level_table_filter.active',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_INACTIVE = 'react.ad_optimization.ads_manager.ad_level_table_filter.inactive',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_UNSET = 'react.ad_optimization.ads_manager.ad_level_table_filter.unset',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NOT_AVAILABLE = 'react.ad_optimization.ads_manager.ad_level_table_filter.not_available',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_DISABLED = 'react.ad_optimization.ads_manager.ad_level_table_filter.disabled',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXACT = 'react.ad_optimization.ads_manager.ad_level_table_filter.exact',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PHRASE = 'react.ad_optimization.ads_manager.ad_level_table_filter.phrase',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BROAD = 'react.ad_optimization.ads_manager.ad_level_table_filter.broad',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_EXACT = 'react.ad_optimization.ads_manager.ad_level_table_filter.negative_exact',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_PHRASE = 'react.ad_optimization.ads_manager.ad_level_table_filter.negative_phrase',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NEGATIVE_BROAD = 'react.ad_optimization.ads_manager.ad_level_table_filter.negative_broad',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EXPANDED = 'react.ad_optimization.ads_manager.ad_level_table_filter.expanded',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_COMPETITOR = 'react.ad_optimization.ads_manager.ad_level_table_filter.competitor',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_GENERIC = 'react.ad_optimization.ads_manager.ad_level_table_filter.generic',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_BRAND = 'react.ad_optimization.ads_manager.ad_level_table_filter.brand',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_EMPTY = 'react.ad_optimization.ads_manager.ad_level_table_filter.empty',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_FLYWHEEL_AI_ADDED = 'react.ad_optimization.ads_manager.ad_level_table_filter.flywheel_ai_added',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_MANUALLY_ADDED = 'react.ad_optimization.ads_manager.ad_level_table_filter.manually_added',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_ARCHIVED = 'react.ad_optimization.ads_manager.ad_level_table_filter.archived',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_DELETED = 'react.ad_optimization.ads_manager.ad_level_table_filter.deleted',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PENDING = 'react.ad_optimization.ads_manager.ad_level_table_filter.pending',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_LEGACY = 'react.ad_optimization.ads_manager.ad_level_table_filter.legacy',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_PRODUCT = 'react.ad_optimization.ads_manager.ad_level_table_filter.product',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AUDIENCES = 'react.ad_optimization.ads_manager.ad_level_table_filter.audiences',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_VIEWS = 'react.ad_optimization.ads_manager.ad_level_table_filter.views',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_DOWN = 'react.ad_optimization.ads_manager.ad_level_table_filter.amazon_bidding_strategy.dynamic_bids_down',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_UP_AND_DOWN = 'react.ad_optimization.ads_manager.ad_level_table_filter.amazon_bidding_strategy.dynamic_bids_up_and_down',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BIDDING_STARATEGY_DYNAMIC_BIDS_FIXED = 'react.ad_optimization.ads_manager.ad_level_table_filter.amazon_bidding_strategy.dynamic_bids_fixed',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BID_OPTIMIZATION_ON = 'react.ad_optimization.ads_manager.ad_level_table_filter.amazon_bid_optimization.on',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_AMAZON_BID_OPTIMIZATION_OFF = 'react.ad_optimization.ads_manager.ad_level_table_filter.amazon_bid_optimization.off',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_CAMPAIGN_AD_FORMAT_PRODUCT_COLLECTION = 'react.ad_optimization.ads_manager.ad_level_table_filter.campaign_ad_format.product_collection',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_CAMPAIGN_AD_FORMAT_VIDEO = 'react.ad_optimization.ads_manager.ad_level_table_filter.campaign_ad_format.video',
  ADS_MANAGER_AD_LEVEL_TABLE_FILTER_CAMPAIGN_AD_FORMAT_STORE_SPOTLIGHT = 'react.ad_optimization.ads_manager.ad_level_table_filter.campaign_ad_format.store_spotlight',
  ADS_MANAGER_AD_LEVEL_TABLE_NO_MATCHED_RESULTS_TITLE = 'react.ad_optimization.ads_manager.ad_level_table.no_filter_matched_results.title',
  ADS_MANAGER_AD_LEVEL_TABLE_NO_MATCHED_RESULTS_DESCRIPTION = 'react.ad_optimization.ads_manager.ad_level_table.no_filter_matched_results.description',
  ADS_MANAGER_INVALID_NUMERIC_ATLEAST = 'react.ad_optimization.ads_manager.campaigns_table.invalid.daily_budget.atleast',
  ADS_MANAGER_INVALID_NUMERIC_GREATER_THAN = 'react.ad_optimization.ads_manager.campaigns_table.invalid.daily_budget.greaterThan',
  ADS_MANAGER_CAMPAIGNS_INVALID_DAILY_BUDGET_GREATER_THAN_LIFETIME_BUDGET = 'react.ad_optimization.campaigns_table.invalid.daily_budget.daily_budget_greater_than_lifetime_budget',
  ADS_MANAGER_CAMPAIGNS_INVALID_LIFETIME_BUDGET_LESSER_THAN_DAILY_BUDGET = 'react.ad_optimization.campaigns_table.invalid.daily_budget.lifetime_budget_greater_than_daily_budget_error',
  ADS_MANAGER_INVALID_NUMERIC_ATLEAST_PERCENT = 'react.ad_optimization.ads_manager.campaigns_table.invalid.daily_budget.atleast.percent',
  ADS_MANAGER_INVALID_BELOW_TOP_OF_SEARCH_BID_MULTIPLIER = 'react.ad_optimization.campaigns_table.invalid.below_top_of_search_bid_multiplier_error',
  ADS_MANAGER_DISABLED_BELOW_TOP_OF_SEARCH_BID_MULTIPLIER = 'react.ad_optimization.campaigns_table.disabled_below_top_of_search_bid_multiplier',
  ADS_MANAGER_CAMPAIGNS_INVALID_LIFETIME_BUDGET = 'react.ad_optimization.campaigns_table.invalid.lifetime_budget',
  ADS_MANAGER_CAMPAIGNS_INVALID_MINBID = 'react.ad_optimization.campaigns_table.invalid.minbid',
  ADS_MANAGER_CAMPAIGNS_MINBID_SHOULD_BE_LESS_THAN_MAX_BID = 'react.ad_optimization.campaigns_table.minbid_greater_than_maxbid',
  ADS_MANAGER_CAMPAIGNS_INVALID_BID_BETWEEN = 'react.ad_optimization.campaigns_table.invalid.between_bid',
  ADS_MANAGER_CAMPAIGNS_INVALID_MAXBID = 'react.ad_optimization.campaigns_table.invalid.maxbid',
  ADS_MANAGER_CAMPAIGNS_MAXBID_LESS_THAN_MIN_LIMIT = 'react.ad_optimization.campaigns_table.maxbid_less_than_min_limit',
  ADS_MANAGER_CAMPAIGNS_MAXBID_SHOULD_BE_GREATER_THAN_MIN_BID = 'react.ad_optimization.campaigns_table.maxbid_less_than_minbid',
  ADS_MANAGER_CAMPAIGNS_DISABLED_AMAZON_BID_OPTIMIZATION = 'react.ad_optimization.campaigns_table.disabled_amazon_bid_optimization',
  ADS_MANAGER_CAMPAIGNS_DUPLICATE_CAMPAIGN_NAME = 'react.ad_optimization.campaigns_table.duplicate_camaign_name',
  ADS_MANAGER_COLUMNS_ARCHIVED_ENTITY_ERROR_TOOLTIP = 'react.ad_optimization.ads_manager.edit.archived_entity_error_text',
  ADS_MANAGER_MANAGE_BIDDING = 'react.ad_optimization.ads_manager.manage_bidding.heading',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.download_step.header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.download_step.description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_INSTRUCTIONS = 'react.ad_optimization.ads_manager.manage_bidding.download_step.instructions',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_INSTRUCTIONS_SMART_ACOS = 'react.ad_optimization.ads_manager.manage_bidding.download_step.instructions.smart_acos',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_BUTTON = 'react.ad_optimization.ads_manager.manage_bidding.download_step.button',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_ERROR_DOWNLOAD_BUTTON = 'react.ad_optimization.ads_manager.manage_bidding.validation_error_download.button',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_ERROR_DOWNLOAD_BUTTON_TEXT = 'react.ad_optimization.ads_manager.manage_bidding.validation_error_download.button_success_text',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_SUCESS = 'react.ad_optimization.ads_manager.manage_bidding.download_step.success',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_ERROR_HEADLINE = 'react.ad_optimization.ads_manager.manage_bidding.download_step.error_headline',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_ERROR_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.download_step.error_description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.more_info_step.header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.more_info_step.description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_DESCRIPTION_SMART_ACOS = 'react.ad_optimization.ads_manager.manage_bidding.more_info_step.description.smart_acos',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_LINK = 'react.ad_optimization.ads_manager.manage_bidding.more_info_step.link',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_GENERIC_ERROR = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.generic_error',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_COLUMN_MISMATCH_ERROR = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.column_mismatch_error',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_COLUMN_MISMATCH_ERROR_WITH_COLUMNS = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.column_mismatch_error_with_columns',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_COLUMN_MISMATCH_ERROR_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.column_mismatch_error_header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_VALIDATION_ERROR = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.validation_error',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_FILE_TOO_LARGE_ERROR = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.file_too_large_error',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_RETRY_BUTTON = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.retry_button',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_ATTACH_FILE = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.attach_file',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_UPLOAD_BUTTON = 'react.ad_optimization.ads_manager.manage_bidding.upload_file_step.upload_button',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.upload_success.header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.upload_success.description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_BUTTON = 'react.ad_optimization.ads_manager.manage_bidding.upload_success.button',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.validation_step.header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_NO_ERRORS_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.validation_step.no_errors_header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_ERRORS_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.validation_step.errors_header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_ERRORS_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.validation_step.errors_description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_PARTIAL_ERRORS_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.validation_step.partial_errors_description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_BUTTON_TEXT = 'react.ad_optimization.ads_manager.manage_bidding.validation_step.button_text',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_STEP_ERRORS_HEADER = 'react.ad_optimization.ads_manager.manage_bidding.upload_step.errors_header',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_STEP_ERRORS_DESCRIPTION = 'react.ad_optimization.ads_manager.manage_bidding.upload_step.errors_description',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_STEP_ERRORS_BUTTON_TEXT = 'react.ad_optimization.ads_manager.manage_bidding.upload_step.errors_button_text',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_TRY_AGAIN = 'react.ad_optimization.ads_manager.manage_bidding.try_again',
  ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOADING_FILE_MESSAGE = 'react.ad_optimization.ads_manager.manage_bidding.upload_message',
  ADS_MANAGER_TRE_UPLOAD_TOAST_ERROR_BODY = 'react.ad_optimization.ads_manager.tre_upload.toast.error.message',
  ADS_MANAGER_TRE_UPLOAD_TOAST_SUCCESS_BODY = 'react.ad_optimization.ads_manager.tre_upload.toast.success.message',
  ADS_MANAGER_FLYWHEEL_SETTINGS_HELP_TITLE = 'react.ad_optimization.ads_manager.flywheel_settings_help_title',
  ADS_MANAGER_FLYWHEEL_SETTINGS_HELP_DESCRIPTION = 'react.ad_optimization.ads_manager.flywheel_settings_help_description',
  ADS_MANAGER_FLYWHEEL_SETTINGS_HELP_DESCRIPTION_SMART = 'react.ad_optimization.ads_manager.flywheel_settings_help_description_smart',
  ADS_MANAGER_FLYWHEEL_SETTINGS_HELP_LINK_TEXT = 'react.ad_optimization.ads_manager.flywheel_settings_help_link_text',

  ADS_MANAGER_SB_ADS_TABLE_FOOTER_TOTAL_ITEMS_TEXT = 'react.ad_optimization.ads_manager.sb_ads_table.footer.total_items_text',
  ADS_MANAGER_SB_ADS_TABLE_GROUP_DETAILS = 'react.ad_optimization.ads_manager.sb_ads_table.details_group',
  ADS_MANAGER_SB_ADS_TABLE_GROUP_CHANNEL_SETTING = 'react.ad_optimization.ads_manager.sb_ads_table.channel_settings_group',
  ADS_MANAGER_SB_ADS_TABLE_GROUP_PERFORMANCE = 'react.ad_optimization.ads_manager.sb_ads_table.performance_group',
  ADS_MANAGER_SB_ADS_TABLE_COLUMN_CREATIVE_NAME = 'react.ad_optimization.ads_manager.sb_ads_table.column.creative_name',
  ADS_MANAGER_SB_ADS_TABLE_COLUMN_CREATIVE_TYPE = 'react.ad_optimization.ads_manager.sb_ads_table.column.creative_type',
  ADS_MANAGER_SB_ADS_TABLE_COLUMN_SERVING_STATUS = 'react.ad_optimization.ads_manager.sb_ads_table.column.serving_status',
  ADS_MANAGER_SB_ADS_TABLE_COLUMN_AD_STATUS = 'react.ad_optimization.ads_manager.sb_ads_table.column.ad_status',

  ADS_MANAGER_CREATIVE_TYPE_PRODUCT_COLLECTION = 'react.ad_optimization.ads_manager.creative_type.product_collection',
  ADS_MANAGER_CREATIVE_TYPE_STORE_SPOTLIGHT = 'react.ad_optimization.ads_manager.creative_type.store_spotlight',
  ADS_MANAGER_CREATIVE_TYPE_VIDEO = 'react.ad_optimization.ads_manager.creative_type.video',
  ADS_MANAGER_CREATIVE_TYPE_BRAND_VIDEO = 'react.ad_optimization.ads_manager.creative_type.brand_video',

  ADS_MANAGER_AD_STATUS_SUBMITTED_FOR_MODERATION = 'react.ad_optimization.ads_manager.ad_status.submitted_for_moderation',
  ADS_MANAGER_AD_STATUS_PENDING_MODERATION_REVIEW = 'react.ad_optimization.ads_manager.ad_status.pending_moderation_review',
  ADS_MANAGER_AD_STATUS_APPROVED_BY_MODERATION = 'react.ad_optimization.ads_manager.ad_status.approved_by_moderation',
  ADS_MANAGER_AD_STATUS_REJECTED_BY_MODERATION = 'react.ad_optimization.ads_manager.ad_status.rejected_by_moderation',
  ADS_MANAGER_AD_STATUS_PUBLISHED = 'react.ad_optimization.ads_manager.ad_status.published',

  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_STATUS_LIVE = 'react.ad_optimization.ads_manager.creative_serving_status.ad_status_live',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_POLICING_PENDING_REVIEW = 'react.ad_optimization.ads_manager.creative_serving_status.ad_policing_pending_review',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_POLICING_SUSPENDED = 'react.ad_optimization.ads_manager.creative_serving_status.ad_policing_suspended',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_PAUSED = 'react.ad_optimization.ads_manager.creative_serving_status.ad_paused',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_ARCHIVED = 'react.ad_optimization.ads_manager.creative_serving_status.ad_archived',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_STATUS_ENABLED = 'react.ad_optimization.ads_manager.creative_serving_status.ad_group_status_enabled',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_PAUSED = 'react.ad_optimization.ads_manager.creative_serving_status.ad_group_paused',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_ARCHIVED = 'react.ad_optimization.ads_manager.creative_serving_status.ad_group_archived',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_INCOMPLETE = 'react.ad_optimization.ads_manager.creative_serving_status.ad_group_incomplete',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_POLICING_PENDING_REVIEW = 'react.ad_optimization.ads_manager.creative_serving_status.ad_group_policing_pending_review',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_POLICING_CREATIVE_REJECTED = 'react.ad_optimization.ads_manager.creative_serving_status.ad_group_policing_creative_rejected',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_AD_GROUP_LOW_BID = 'react.ad_optimization.ads_manager.creative_serving_status.ad_group_low_bid',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_STATUS_ENABLED = 'react.ad_optimization.ads_manager.creative_serving_status.advertiser_status_enabled',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_POLICING_PENDING_REVIEW = 'react.ad_optimization.ads_manager.creative_serving_status.advertiser_policing_pending_review',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_POLICING_SUSPENDED = 'react.ad_optimization.ads_manager.creative_serving_status.advertiser_policing_suspended',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_PAUSED = 'react.ad_optimization.ads_manager.creative_serving_status.advertiser_paused',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_ARCHIVED = 'react.ad_optimization.ads_manager.creative_serving_status.advertiser_archived',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_PAYMENT_FAILURE = 'react.ad_optimization.ads_manager.creative_serving_status.advertiser_payment_failure',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_ACCOUNT_OUT_OF_BUDGET = 'react.ad_optimization.ads_manager.creative_serving_status.advertiser_account_out_of_budget',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_OUT_OF_PREPAY_BALANCE = 'react.ad_optimization.ads_manager.creative_serving_status.advertiser_out_of_prepay_balance',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_ADVERTISER_EXCEED_SPENDS_LIMIT = 'react.ad_optimization.ads_manager.creative_serving_status.advertiser_exceed_spends_limit',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_STATUS_ENABLED = 'react.ad_optimization.ads_manager.creative_serving_status.campaign_status_enabled',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_PAUSED = 'react.ad_optimization.ads_manager.creative_serving_status.campaign_paused',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_ARCHIVED = 'react.ad_optimization.ads_manager.creative_serving_status.campaign_archived',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_INCOMPLETE = 'react.ad_optimization.ads_manager.creative_serving_status.campaign_incomplete',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_CAMPAIGN_OUT_OF_BUDGET = 'react.ad_optimization.ads_manager.creative_serving_status.campaign_out_of_budget',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_STATUS_ENABLED = 'react.ad_optimization.ads_manager.creative_serving_status.portfolio_status_enabled',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_PAUSED = 'react.ad_optimization.ads_manager.creative_serving_status.portfolio_paused',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_ARCHIVED = 'react.ad_optimization.ads_manager.creative_serving_status.portfolio_archived',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_OUT_OF_BUDGET = 'react.ad_optimization.ads_manager.creative_serving_status.portfolio_out_of_budget',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_PENDING_START_DATE = 'react.ad_optimization.ads_manager.creative_serving_status.portfolio_pending_start_date',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_PORTFOLIO_ENDED = 'react.ad_optimization.ads_manager.creative_serving_status.portfolio_ended',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_INELIGIBLE = 'react.ad_optimization.ads_manager.creative_serving_status.ineligible',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_ELIGIBLE = 'react.ad_optimization.ads_manager.creative_serving_status.eligible',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_ENDED = 'react.ad_optimization.ads_manager.creative_serving_status.ended',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_PENDING_REVIEW = 'react.ad_optimization.ads_manager.creative_serving_status.pending_review',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_PENDING_START_DATE = 'react.ad_optimization.ads_manager.creative_serving_status.pending_start_date',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_REJECTED = 'react.ad_optimization.ads_manager.creative_serving_status.rejected',
  ADS_MANAGER_CREATIVE_SERVING_STATUS_UNKNOWN = 'react.ad_optimization.ads_manager.creative_serving_status.unknown',

  ADS_MANAGER_SV_ADS_TABLE_COLUMN_NAME = 'react.ad_optimization.ads_manager.sv_ads_table.column.name',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_CREATIVE_TYPE = 'react.ad_optimization.ads_manager.sv_ads_table.column.creative_type',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_SERVIING_STATUS = 'react.ad_optimization.ads_manager.sv_ads_table.column.serving_status',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_AD_GROUP_NAME = 'react.ad_optimization.ads_manager.sv_ads_table.column.ad_group_name',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_CAMPAIGN_NAME = 'react.ad_optimization.ads_manager.sv_ads_table.column.campaign_name',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_AD_STATUS = 'react.ad_optimization.ads_manager.sv_ads_table.column.ad_status',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_IMPRESSIONS = 'react.ad_optimization.ads_manager.sv_ads_table.column.impressions',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_CLICKS = 'react.ad_optimization.ads_manager.sv_ads_table.column.clicks',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_CTR = 'react.ad_optimization.ads_manager.sv_ads_table.column.ctr',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_AVERAGE_CPC = 'react.ad_optimization.ads_manager.sv_ads_table.column.average_cpc',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_AD_SPEND = 'react.ad_optimization.ads_manager.sv_ads_table.column.ad_spend',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_ROAS = 'react.ad_optimization.ads_manager.sv_ads_table.column.roas',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_TOTAL_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.sv_ads_table.column.total_attributed_sales',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_ORDERS = 'react.ad_optimization.ads_manager.sv_ads_table.column.orders',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_UNITS_SOLD = 'react.ad_optimization.ads_manager.sv_ads_table.column.units_sold',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_CONVERSION_RATE = 'react.ad_optimization.ads_manager.sv_ads_table.column.conversion_rate',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIDEO_IMPRESSIONS = 'react.ad_optimization.ads_manager.sv_ads_table.column.video_impressions',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIEWABLE_IMPRESSIONS = 'react.ad_optimization.ads_manager.sv_ads_table.column.viewable_impressions',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIEW_THROUGH_RATE = 'react.ad_optimization.ads_manager.sv_ads_table.column.view_through_rate',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_CLICK_THROUGH_RATE_FOR_VIEWS = 'react.ad_optimization.ads_manager.sv_ads_table.column.click_through_rate_for_views',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIEW_THROUGH_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.sv_ads_table.column.view_through_attributed_sales',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIEW_THROUGH_ORDERS = 'react.ad_optimization.ads_manager.sv_ads_table.column.view_through_orders',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIEW_THROUGH_UNITS_SOLD = 'react.ad_optimization.ads_manager.sv_ads_table.column.view_through_units_sold',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_TOTAL_COMPLETE_VIEW_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.sv_ads_table.column.total_complete_view_attributed_sales',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_OTHER_COMPLETE_VIEW_ATTRIBUTED_SALES = 'react.ad_optimization.ads_manager.sv_ads_table.column.other_complete_view_attributed_sales',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_ADVERTISED_COMPLETE_VIEW_ATTRIBUTED_SALE = 'react.ad_optimization.ads_manager.sv_ads_table.column.advertised_complete_view_attributed_sales',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_COMPLETE_VIEW_ORDERS = 'react.ad_optimization.ads_manager.sv_ads_table.column.complete_view_orders',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_COMPLETE_VIEW_UNITS_SOLD = 'react.ad_optimization.ads_manager.sv_ads_table.column.complete_view_units_sold',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIDEO_FIRST_QUARTILE_VIEWS = 'react.ad_optimization.ads_manager.sv_ads_table.column.video_first_quartile_views',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIDEO_MIDPOINT_VIEWS = 'react.ad_optimization.ads_manager.sv_ads_table.column.video_midpoint_views',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIDEO_THIRD_QUARTILE_VIEWS = 'react.ad_optimization.ads_manager.sv_ads_table.column.video_third_quartile_views',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIDEO_COMPLETE_VIEWS = 'react.ad_optimization.ads_manager.sv_ads_table.column.video_complete_views',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_VIDEO_UNMUTES = 'react.ad_optimization.ads_manager.sv_ads_table.column.video_unmutes',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_FIVE_SECOND_VIEWS = 'react.ad_optimization.ads_manager.sv_ads_table.column.five_second_views',
  ADS_MANAGER_SV_ADS_TABLE_COLUMN_FIVE_SECOND_VIEW_RATE = 'react.ad_optimization.ads_manager.sv_ads_table.column.five_second_view_rate',

  ADS_MANAGER_FLYWHEEL_SETTINGS_AUTOMATION_STATUS_UNSET_TOOLTIP = 'react.ad_optimization.ads_manager.edit.flywheel_settings.automation_status.unset_tooltip',
  ADS_MANAGER_FLYWHEEL_SETTINGS_AI_KEYWORD_SETTING_UNSET_TOOLTIP = 'react.ad_optimization.ads_manager.edit.flywheel_settings.ai_recommendation_keyword_setting.unset_tooltip',
  ADS_MANAGER_FLYWHEEL_SETTINGS_UNSET_TOOLTIP = 'react.ad_optimization.ads_manager.edit.flywheel_settings.vcpm_tooltip',
  ADS_MANAGER_UPGRADE_TO_AI_PLAN_BANNER_MESSAGE = 'react.ad_optimization.ads_manager.upgrade_plan_banner_message',
  ADS_MANAGER_UPGRADE_TO_AI_PLAN_BANNER_MESSAGE_TITLE = 'react.ad_optimization.ads_manager.upgrade_plan_banner_message_title',
  ADS_MANAGER_CAMPAIGN_DETAILS_BREADCRUMB_HEADER = 'react.ad_optimization.ads_manager.campaign_detials.breadcrumb_header',
  ADS_MANAGER_ADGROUP_DETAILS_BREADCRUMB_HEADER = 'react.ad_optimization.ads_manager.adgroup_detials.breadcrumb_header',
  ADS_MANAGER_CAMPAIGN_COST_TYPE_CPC = 'react.ad_optimization.ads_manager.campaign_cost_type.cpc',
  ADS_MANAGER_CAMPAIGN_COST_TYPE_VCPM = 'react.ad_optimization.ads_manager.campaign_cost_type.vcpm',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_APPROVED = 'react.ad_optimization.ads_manager.review_status.approved',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_PENDING = 'react.ad_optimization.ads_manager.review_status.pending',
  ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_LABELS = 'react.ad_optimization.ads_manager.keyword_targets.column.target_labels',
  ADS_MANAGER_TARGET_LABELS_AI_ADDED = 'react.ad_optimization.ads_manager.target_labels.ai_added',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_REJECTED = 'react.ad_optimization.ads_manager.review_status.rejected',
  ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TAGS = 'react.ad_optimization.ads_manager.keyword_targets.column.target_tags',
  ADS_MANAGER_KEYWORD_TARGETS_COLUMN_EDIT_TARGET_TAGS = 'react.ad_optimization.ads_manager.keyword_targets.column.edit_target_tags',
  ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_ADDED_BY = 'react.ad_optimization.ads_manager.keyword_targets.column.added_by',
  ADS_MANAGER_TARGET_LABELS_FROM_AUTOMATION = 'react.ad_optimization.ads_manager.target_labels.from_automation',
  ADS_MANAGER_TARGET_LABELS_NEG_TARGET = 'react.ad_optimization.ads_manager.target_labels.neg_target',
  ADS_MANAGER_TARGET_LABELS_NO_TAG = 'react.ad_optimization.ads_manager.target_labels.no_tag',
  ADS_MANAGER_TARGET_LABELS_NO_LABELS = 'react.ad_optimization.ads_manager.target_labels.no_labels',
  ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_TYPE = 'react.ad_optimization.ads_manager.keyword_targets.column.target_type',
  ADS_MANAGER_TARGET_TYPE_KEYWORD = 'react.ad_optimization.ads_manager.target_type.keyword',
  ADS_MANAGER_TARGET_TYPE_NEGATIVE_KEYWORD = 'react.ad_optimization.ads_manager.target_type.negative_keyword',
  ADS_MANAGER_TARGET_TYPE_CAMPAIGN_NEGATIVE_KEYWORD = 'react.ad_optimization.ads_manager.target_type.campaign_negative_keyword',
  ADS_MANAGER_TARGET_TYPE_NEGATIVE_PRODUCT_TARGET = 'react.ad_optimization.ads_manager.target_type.negative_product_target',
  ADS_MANAGER_TARGET_TYPE_AUDIENCE_TARGET = 'react.ad_optimization.ads_manager.target_type.audience_target',
  ADS_MANAGER_TARGET_TYPE_PRODUCT_TARGET = 'react.ad_optimization.ads_manager.target_type.product_target',
  ADS_MANAGER_TARGET_TYPE_PRODUCT_ATTRIBUTE = 'react.ad_optimization.ads_manager.target_type.product_attribute',

  ADS_MANAGER_TARGET_ADDEDBY_TRE_REC = 'react.ad_optimization.ads_manager.target_addedby.tre_rec',
  ADS_MANAGER_TARGET_ADDEDBY_FW_AI_REC = 'react.ad_optimization.ads_manager.target_addedby.fw_ai_rec',
  ADS_MANAGER_TARGET_ADDEDBY_FW_ON_DEMAND = 'react.ad_optimization.ads_manager.target_addedby.fw_on_demand',
  ADS_MANAGER_TARGET_ADDEDBY_USER_ADDED = 'react.ad_optimization.ads_manager.target_addedby.user_added',
  ADS_MANAGER_TARGET_ADDEDBY_CHAT_GPT = 'react.ad_optimization.ads_manager.target_addedby.chatgpt',
  ADS_MANAGER_TARGET_ADDEDBY_FW_AUTO_NEGATE = 'react.ad_optimization.ads_manager.target_addedby.fw_auto_negate',

  ADS_MANAGER_TARGET_SKU_AND_KEYWORDS_MODAL_EMPTY_MESSAGE_TITLE = 'react.ad_optimization.ads_manager.sku_and_keywords_modal.empty_message_title',
  ADS_MANAGER_TARGET_SKU_AND_KEYWORDS_MODAL_EMPTY_MESSAGE = 'react.ad_optimization.ads_manager.sku_and_keywords_modal.empty_message',

  ADS_MANAGER_TARGET_ADDEDBY_TRE_REC_SEPARATOR = 'react.ad_optimization.ads_manager.target_addedby.tre_rec_separator',
  ADS_MANAGER_TARGET_ADDEDBY_FW_AI_REC_SEPARATOR = 'react.ad_optimization.ads_manager.target_addedby.fw_ai_rec_separator',
  ADS_MANAGER_TARGET_ADDEDBY_FW_ON_DEMAND_SEPARATOR = 'react.ad_optimization.ads_manager.target_addedby.fw_on_demand_separator',

  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_INPROGRESS = 'react.ad_optimization.ads_manager.review_status.inprogress',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_COMPLETED = 'react.ad_optimization.ads_manager.review_status.completed',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_CANCELLED = 'react.ad_optimization.ads_manager.review_status.cancelled',

  ADS_MANAGER_CAMPAIGN_AI_RECOMMENDED_KEYWORD_ADD_AUTOMATICALLY = 'react.ad_optimization.ads_manager.ai_recommended_keyword.add_automatically',
  ADS_MANAGER_CAMPAIGN_AI_RECOMMENDED_KEYWORD_REVIEW_RECOMMENDATION = 'react.ad_optimization.ads_manager.ai_recommended_keyword.review_recommendations',

  ADS_MANAGER_ADGROUP_REVIEW_STATUS_TOOLTIP_COMMENT_TYPE = 'react.ad_optimization.ads_manager.adgroup_review_status.tooltip.comment_type',
  ADS_MANAGER_ADGROUP_REVIEW_STATUS_TOOLTIP_COMMENTS = 'react.ad_optimization.ads_manager.adgroup_review_status.tooltip.comments',

  ADS_MANAGER_ADGROUP_REVIEW_STATUS_DISABLED_TOOLTIP = 'react.ad_optimization.ads_manager.adgroup_review_status.disabled.tooltip',
  ADS_MANAGER_ADGROUP_KEYWORD_RECOMMENDATION_DISABLED_TOOLTIP = 'react.ad_optimization.ads_manager.adgroup_keyword_recommendation.disabled.tooltip',
  ADS_MANAGER_ADGROUP_KEYWORD_RECOMMENDATION_ERROR_ADD_TAGS_TOOLTIP = 'react.ad_optimization.ads_manager.adgroup_keyword_recommendation.error.add_tags.tooltip',

  ADS_MANAGER_TABLE_LOOKING_AROUND = 'react.ad_optimization.ads_manager.table.looking_around',
  ADS_MANAGER_TABLE_TAKING_A_LOOK = 'react.ad_optimization.ads_manager.table.taking_a_look',

  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_TOOLTIP_FOOTER = 'react.ad_optimization.ads_manager.review_status.tooltip.footer',
  ADS_MANAGER_CAMPAIGN_REVIEW_STATUS_NO_COMMENTS_TOOLTIP_MESSAGE = 'react.ad_optimization.ads_manager.review_status.no.comments.tooltip.message',

  ADS_MANAGER_TOOLTIP_MESSAGE_AUTOMATION_DISABLED = 'react.ad_optimization.ads_manager.tooltip_messages.bid_automation_status_disabled',
  ADS_MANAGER_TOOLTIP_MESSAGE_MC_DISABLED = 'react.ad_optimization.ads_manager.tooltip_message.mc_disabled',
  ADS_MANAGER_TOOLTIP_MESSAGE_AUTOMATION_DISABLED_FOR_LEGANCY_CAMPAIGN = 'react.ad_optimization.ads_manager.tooltip_messages.bid_automation_status_disabled.legancy_campaign',

  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_HEADER_FEES = 'react.products.sku_catalog_table_column.header_fees',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_HEADER_INVENTORY = 'react.products.sku_catalog_table_column.header_inventory',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_COGS = 'react.products.sku_catalog_table_column.cogs',
  PRODUCTS_PRODUCT_CATALOG_COGS_EMPTY_TOOLTIP = 'react.products.product_catalog_table_column.cogs.empty_tooltip',
  PRODUCTS_PRODUCT_CATALOG_COGS_EMPTY_COGS_TOOLTIP = 'react.products.product_catalog_table_column.cogs.empty_cogs_tooltip',
  PRODUCTS_PRODUCT_CATALOG_COGS_EMPTY_COGS_TOOLTIP_CUSTOM_GROUP = 'react.products.product_catalog_table_column.cogs.empty_cogs_tooltip.custom_group',
  PRODUCTS_PRODUCT_CATALOG_ESTIMATE_GROSS_PROFIT_EMPTY_COGS_TOOLTIP_CUSTOM_GROUP = 'react.products.product_catalog_table_column.estimated_gross_profit.empty_cogs_tooltip.custom_group',
  PRODUCTS_PRODUCT_CATALOG_COGS_EMPTY_UNITS_SOLD_TOOLTIP = 'react.products.product_catalog_table_column.cogs.empty_units_sold_tooltip',
  PRODUCTS_PRODUCT_CATALOG_COGS_EMPTY_EST_GROSS_PROFIT_TOOLTIP = 'react.products.product_catalog_table_column.cogs.empty_est_gross_profit_tooltip',
  PRODUCTS_PRODUCT_CATALOG_COGS_EMPTY_PRE_AD_EST_GROSS_MARGIN_TOOLTIP = 'react.products.product_catalog_table_column.cogs.empty_pre_ad_est_gross_margin_tooltip',
  PRODUCTS_PRODUCT_CATALOG_COGS_NO_UNIT_SOLD_EST_GROSS_PROFIT_TOOLTIP = 'react.products.product_catalog_table_column.cogs.no_unit_sold_est_gross_profit_tooltip',
  PRODUCTS_PRODUCT_CATALOG_COGS_NO_UNIT_SOLD_PRE_AD_EST_GROSS_MARGIN_TOOLTIP = 'react.products.product_catalog_table_column.cogs.no_unit_sold_pre_ad_est_gross_margin_tooltip',
  PRODUCTS_PRODUCT_CATALOG_COGS_ADD_UNIT_COGS = 'react.products.product_catalog_table_column.cogs.add_unit_cogs',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_ESTIMATED_FEES = 'react.products.sku_catalog_table_column.estimated_fees',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_INVENTORY_VALUE = 'react.products.sku_catalog_table_column.inventory_value',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_CURRENT_INVENTORY_QUANTITY = 'react.products.sku_catalog_table_column.current_inventory_quantity',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_TOOLTIP_METRIC_NOT_AVAILABLE = 'react.products.sku_catalog_table_column.tooltip.missing_metric',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_TOOLTIP_METRIC_NOT_AVAILABLE_FOR_CUSTOM_GROUP = 'react.products.sku_catalog_table_column.tooltip.missing_metric.custom_group',
  PRODUCTS_SKU_CATALOG_TABLE_COLUMN_TOOLTIP_PENDING_UPDATE = 'react.products.sku_catalog_table_column.tooltip.pending_update',

  MULTICHANNEL_CATALOG_DASHBOARD_HEADER = 'react.products.multi_channel_catalog.dashboard.header',
  MULTICHANNEL_CATALOG_DASHBOARD_TABLE_HEADER = 'react.products.multi_channel_catalog.dashboard.table_header',

  MULTICHANNEL_CATALOG_DASHBOARD_GLOSSARY_TOTAL_SALES_1P_HEADER = 'react.products.multi_channel_catalog.glossary.total_sales_1p_header',
  MULTICHANNEL_CATALOG_DASHBOARD_GLOSSARY_TOTAL_SALES_3P_HEADER = 'react.products.multi_channel_catalog.glossary.total_sales_3p_header',
  MULTICHANNEL_CATALOG_DASHBOARD_GLOSSARY_ESTIMATED_GROSS_PROFIT_1P_HEADER = 'react.products.multi_channel_catalog.glossary.est_gross_profit_1p_header',
  MULTICHANNEL_CATALOG_DASHBOARD_GLOSSARY_ESTIMATED_GROSS_PROFIT_3P_HEADER = 'react.products.multi_channel_catalog.glossary.est_gross_profit_3p_header',

  MULTICHANNEL_CATALOG_DASHBOARD_COLUMNS_ACTIVE_SKU_EMPTY_MESSAGE = 'react.products.multi_channel_catalog.table.columns.active_sku.empty_message',

  CATALOG_PERFORMANCE_TABLE_COLUMN_EST_GROSS_PROFIT = 'react.products.catalog_performance_table.columns.est_gross_profit',
  CATALOG_PERFORMANCE_TABLE_COLUMN_INVENTORY_UNITS = 'react.products.catalog_performance_table.columns.inventory_units',
  CATALOG_PERFORMANCE_TABLE_COLUMN_PERCENT_OF_TOTAL = 'react.products.catalog_performance_table.columns.percent_of_total',
  CATALOG_PERFORMANCE_TABLE_DATE_RANGE_COMPARED_TO = 'react.products.catalog_performance_table.date_range_compared_to',

  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_COLUMN_HEADER_CHANNELS = 'react.products.advertising_catalog_penetration_by_channel_table_column.header.channels',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_COLUMN_HEADER_TOTAL_SKUS = 'react.products.advertising_catalog_penetration_by_channel_table_column.header.total_skus',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_COLUMN_HEADER_ACTIVE_SKUS = 'react.products.advertising_catalog_penetration_by_channel_table_column.header.active_skus',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_COLUMN_HEADER_UNADVERTISED_ACTIVE_SKUS = 'react.products.advertising_catalog_penetration_by_channel_table_column.header.unadvertised_active_skus',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_COLUMN_HEADER_PERCENT_OF_UNADVERTISED_ACTIVE_SKUS = 'react.products.advertising_catalog_penetration_by_channel_table_column.header.percent_of_unadvertised_active_skus',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_TABLE_EMPTY_STATE_MESSAGE = 'react.products.advertising_catalog_penetration_by_channel_table.empty_state_message',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_TABLE_SUB_HEADER = 'react.products.advertising_catalog_penetration_by_channel_table.sub_header',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_TABLE_SUB_HEADER_MESSAGE = 'react.products.advertising_catalog_penetration_by_channel_table.sub_header_message',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_TOTAL_SKUS_HEADER = 'react.products.advertising_catalog_penetration_by_channel_glossary.total_skus.header',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_TOTAL_SKUS_DESCRIPTION = 'react.products.advertising_catalog_penetration_by_channel_glossary.total_skus.description',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_ACTIVE_SKUS_HEADER = 'react.products.advertising_catalog_penetration_by_channel_glossary.active_sku.header',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_ACTIVE_SKUS_DESCRIPTION = 'react.products.advertising_catalog_penetration_by_channel_glossary.active_sku.description',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_UNADVERTISED_ACTIVE_SKUS_HEADER = 'react.products.advertising_catalog_penetration_by_channel_glossary.unadvertised_active_sku.header',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_UNADVERTISED_ACTIVE_SKUS_DESCRIPTION = 'react.products.advertising_catalog_penetration_by_channel_glossary.unadvertised_active_sku.description',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_UNADVERTISED_ACTIVE_SKUS_PENETRATION_HEADER = 'react.products.advertising_catalog_penetration_by_channel_glossary.unadvertised_active_sku_penetration.header',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_UNADVERTISED_ACTIVE_SKUS_PENETRATION_DESCRIPTION = 'react.products.advertising_catalog_penetration_by_channel_glossary.unadvertised_active_sku_penetration.description',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_UNADVERTISED_ACTIVE_SKUS_PENETRATION_FORMULA = 'react.products.advertising_catalog_penetration_by_channel_glossary.unadvertised_active_sku_penetration.formula',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_AMAZON_1P_UNADVERTISED_ACTIVE_SKUS_HEADER = 'react.products.advertising_catalog_penetration_by_channel_glossary.amazon_1p_unadvertised_active_sku.header',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_AMAZON_1P_UNADVERTISED_ACTIVE_SKUS_DESCRIPTION = 'react.products.advertising_catalog_penetration_by_channel_glossary.amazon_1p_unadvertised_active_sku.description',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_AMAZON_1P_UNADVERTISED_ACTIVE_SKUS_PENETRATION_HEADER = 'react.products.advertising_catalog_penetration_by_channel_glossary.amazon_1p_unadvertised_active_sku_penetration.header',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_AMAZON_1P_UNADVERTISED_ACTIVE_SKUS_PENETRATION_DESCRIPTION = 'react.products.advertising_catalog_penetration_by_channel_glossary.amazon_1p_unadvertised_active_sku_penetration.description',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_AMAZON_1P_UNADVERTISED_ACTIVE_SKUS_PENETRATION_FORMULA = 'react.products.advertising_catalog_penetration_by_channel_glossary.amazon_1p_unadvertised_active_sku_penetration.formula',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_WALMART_1P_UNADVERTISED_ACTIVE_SKUS_HEADER = 'react.products.advertising_catalog_penetration_by_channel_glossary.walmart_1p_unadvertised_active_sku.header',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_WALMART_1P_UNADVERTISED_ACTIVE_SKUS_DESCRIPTION = 'react.products.advertising_catalog_penetration_by_channel_glossary.walmart_1p_unadvertised_active_sku.description',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_WALMART_1P_UNADVERTISED_ACTIVE_SKUS_PENETRATION_HEADER = 'react.products.advertising_catalog_penetration_by_channel_glossary.walmart_1p_unadvertised_active_sku_penetration.header',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_WALMART_1P_UNADVERTISED_ACTIVE_SKUS_PENETRATION_DESCRIPTION = 'react.products.advertising_catalog_penetration_by_channel_glossary.walmart_1p_unadvertised_active_sku_penetration.description',
  ADVERTISING_CATALOG_PENETRATION_BY_CHANNEL_GLOSSARY_WALMART_1P_UNADVERTISED_ACTIVE_SKUS_PENETRATION_FORMULA = 'react.products.advertising_catalog_penetration_by_channel_glossary.walmart_1p_unadvertised_active_sku_penetration.formula',

  CREATE_KEYWORDS_MODAL_HEADER = 'react.create_keywords.modal.header',
  CREATE_KEYWORDS_MODAL_CREATE_KEYWORDS = 'react.create_keywords.create_keywords',
  CREATE_KEYWORDS_CHATGPT_MODAL_HEADER = 'react.create_keywords_chatgpt.modal.header',
  CREATE_KEYWORDS_CHATGPT_MODAL_REQUEST_IN_PROGRESS = 'react.create_keywords_chatgpt.modal.request_in_progress',
  CREATE_KEYWORDS_CHATGPT_MODAL_REQUEST_FAILED_MESSAGE = 'react.create_keywords_chatgpt.modal.request_failed_message',
  CREATE_KEYWORDS_CHATGPT_MODAL_REQUEST_NO_KEYWORD_FOUND_HEADER = 'react.create_keywords_chatgpt.modal.no_keyword_found_header',
  CREATE_KEYWORDS_CHATGPT_MODAL_REQUEST_NO_KEYWORD_FOUND_MESSAGE = 'react.create_keywords_chatgpt.modal.no_keyword_found_message',
  CREATE_KEYWORDS_MODAL_GENERATE_KEYWORDS = 'react.create_keywords.modal.generate_keywords',
  CREATE_KEYWORDS_MODAL_KEYWORD_TYPE = 'react.create_keywords.modal.keyword_type',
  CREATE_KEYWORDS_MODAL_KEYWORDS_EMPTY_MESSAGE = 'react.create_keywords.modal.keywords_empty_message',
  CREATE_KEYWORDS_MODAL_MATCH_TYPE_MESSAGE = 'react.create_keywords.modal.match_type_message',
  CREATE_KEYWORDS_MODAL_KEYWORD_TYPE_MESSAGE = 'react.create_keywords.modal.keyword_type_message',
  CREATE_KEYWORDS_MODAL_NO_KEYWORDS_MESSAGE = 'react.create_keywords.modal.no_keywords_message',
  CREATE_KEYWORDS_MODAL_NO_KEYWORDS_SUB_MESSAGE = 'react.create_keywords.modal.no_keywords_sub_message',
  CREATE_KEYWORDS_MODAL_KEYWORDS_GENERATED = 'react.create_keywords.modal.keywords_generated',
  CREATE_KEYWORDS_MODAL_EDIT_ADGROUP_HEADER = 'react.create_keywords.modal.edit_adgroup_header',
  CREATE_KEYWORDS_MODAL_EDIT_TAG_TYPE_HEADER = 'react.create_keywords.modal.edit_tag_type_header',
  CREATE_KEYWORDS_MODAL_EDIT_ADGROUP_MESSAGE = 'react.create_keywords.modal.edit_adgroup_message',
  CREATE_KEYWORDS_MODAL_INCOMPLETE_ADGROUP_MESSAGE = 'react.create_keywords.modal.incomplete_adgroup_message',
  CREATE_KEYWORDS_MODAL_CANCEL_HEADER = 'react.create_keywords.modal.cancel_header',
  CREATE_KEYWORDS_MODAL_CANCEL_MESSAGE = 'react.create_keywords.modal.cancel_message',
  CREATE_KEYWORDS_MENU_CHAT_GPT_BANNER_MESSAGE = 'react.create_keywords.menu.chat_gpt.banner_message',
  CREATE_KEYWORDS_MENU_CHAT_GPT_BETA = 'react.create_keywords.menu.chat_gpt.beta',
  CREATE_KEYWORDS_MENU_CHAT_GPT_LOADING_TOOLTIP = 'react.create_keywords.menu.chat_gpt.loading_tooltip',
  CREATE_KEYWORDS_MENU_CHAT_GPT_BETA_MESSAGE = 'react.create_keywords.menu.chat_gpt.beta_message',
  CREATE_KEYWORDS_MENU_CHAT_GPT_SKU_AND_KEYWORDS_COUNT = 'react.create_keywords.menu.chat_gpt.sku_and_keywords_count',
  CREATE_KEYWORDS_MENU_CHAT_GPT_ENTER_BRAND_NAME = 'react.create_keywords.menu.chat_gpt.enter_brand_name',
  CREATE_KEYWORDS_MENU_CHAT_GPT_ENTER_BRAND_TOOLTIP = 'react.create_keywords.menu.chat_gpt.enter_brand_tooltip',
  CREATE_KEYWORDS_MENU_CHAT_GPT_HEADER = 'react.create_keywords.menu.chat_gpt_header',
  CREATE_KEYWORDS_MENU_CHAT_GPT_BRAND_NAME_MESSAGE = 'react.create_keywords.modal.chat_gpt.brand_name_message',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_HEADER = 'react.create_keywords.modal.chat_gpt.feedback_header',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_SUCCESS_MESSAGE = 'react.create_keywords.modal.chat_gpt.feedback_success_message',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_REALLY_BAD = 'react.create_keywords.modal.rating_really_bad',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_BAD = 'react.create_keywords.modal.rating_bad',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_OKAY = 'react.create_keywords.modal.rating_okay',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_GOOD = 'react.create_keywords.modal.rating_good',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_GREAT = 'react.create_keywords.modal.rating_great',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_SUBMIT_FEEDBACK = 'react.create_keywords.modal.submit_feedback',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_TYPE_HERE = 'react.create_keywords.modal.type_here',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_GIVE_FEEDBACK = 'react.create_keywords.modal.give_feedback',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_GIVE_FEEDBACK_TOOLTIP = 'react.create_keywords.modal.give_feedback_tooltip',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_1 = 'react.create_keywords.modal.feedback_option_1',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_2 = 'react.create_keywords.modal.feedback_option_2',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_3 = 'react.create_keywords.modal.feedback_option_3',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_4 = 'react.create_keywords.modal.feedback_option_4',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_5 = 'react.create_keywords.modal.feedback_option_5',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_OPTION_6 = 'react.create_keywords.modal.feedback_option_6',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_FEEDBACK_SELECT_ALL = 'react.create_keywords.modal.feedback_select_all',
  CREATE_KEYWORDS_MODAL_CHAT_GPT_RATING_HEADER = 'react.create_keywords.modal.chat_gpt.rating_header',
  CREATE_KEYWORDS_MODAL_NO_ADGROUP_CHAT_GPT = 'react.create_keywords.modal.no_adgroup_chat_gpt',
  CREATE_KEYWORDS_MODAL_GENERATING_KEYWORDS_PRIMARY = 'react.create_keywords.modal.generating_keywords_primary_message',
  CREATE_KEYWORDS_MODAL_GENERATING_KEYWORDS_SECONDARY = 'react.create_keywords.modal.generating_keywords_secondary_message',
  CREATE_KEYWORDS_MENU_FROM_LIST_HEADER = 'react.create_keywords.menu.from_list_header',
  CREATE_KEYWORDS_MODAL_ADD_KEYWORDS = 'react.create_keywords.modal.add_keywords',
  CREATE_KEYWORDS_MODAL_ASIN_TARGETS = 'react.create_keywords.modal.asin_targets',
  CREATE_KEYWORDS_MENU_NEW_KEYWORDS = 'react.create_keywords.menu.new_keywords',
  CREATE_KEYWORDS_MENU_NEGATIVE_KEYWORDS = 'react.create_keywords.menu.negative_keywords',
  CREATE_KEYWORDS_ERROR_TYPE_INVALID_CHARACTERS = 'react.create_keywords.error_type.invalid_characters',
  CREATE_KEYWORDS_ERROR_TYPE_INVALID_ASIN = 'react.create_keywords.error_type.invalid_asin',
  CREATE_KEYWORDS_ERROR_TYPE_DUPLICATE = 'react.create_keywords.error_type.duplicate',
  CREATE_KEYWORDS_ERROR_TYPE_MAX_CHARACTERS = 'react.create_keywords.error_type.max_characters',
  CREATE_KEYWORDS_ERROR_TYPE_MAX_PARTS = 'react.create_keywords.error_type.max_parts',
  CREATE_KEYWORDS_ERROR_TYPE_MAX_PARTS_NEGATIVE = 'react.create_keywords.error_type.max_parts_negative',
  CREATE_KEYWORDS_MATCH_TYPE_ERROR_TOOLTIP = 'react.create_keywords.match_type.error_tooltip',
  CREATE_KEYWORDS_KEYWORD_TYPE_ERROR_TOOLTIP = 'react.create_keywords.keyword_type.error_tooltip',
  CREATE_KEYWORDS_TAG_TYPE_ERROR_TOOLTIP = 'react.create_keywords.tag_type.error_tooltip',
  CREATE_KEYWORDS_TOOLTIP_AD_GROUP = 'react.create_keywords.tooltip.ad_group',
  CREATE_KEYWORDS_TOOLTIP_MATCH_TYPE = 'react.create_keywords.tooltip.match_type',
  CREATE_KEYWORDS_TOOLTIP_KEYWORD_TYPE = 'react.create_keywords.tooltip.keyword_type',
  CREATE_KEYWORDS_TOOLTIP_TAG_TYPE = 'react.create_keywords.tooltip.tag_type',
  CREATE_KEYWORDS_TOOLTIP_ENTER_KEYWORD = 'react.create_keywords.tooltip.enter_keyword',
  CREATE_KEYWORDS_TOOLTIP_ADD_BRANDS = 'react.create_keywords.tooltip.add_brands',
  CREATE_KEYWORDS_TOOLTIP_ENTER_TARGETS = 'react.create_keywords.tooltip.enter_targets',
  CREATE_KEYWORDS_TOOLTIP_ADDED_KEYWORDS = 'react.create_keywords.tooltip.added_keywords',
  CREATE_KEYWORDS_TOOLTIP_ADDED_KEYWORDS_COUNT = 'react.create_keywords.tooltip.added_keywords_count',
  CREATE_KEYWORDS_PLACEHOLDER_ADD_TARGETS = 'react.create_keywords.placeholder.add_targets',
  CREATE_KEYWORDS_PLACEHOLDER_KEYWORDS_COUNT = 'react.create_keywords.placeholder.keywords_count',
  CREATE_KEYWORDS_TOOLTIP_TARGETING_TYPE = 'react.create_keywords.tooltip.targeting_type',
  CREATE_KEYWORDS_TOOLTIP_TARGETING_TYPE_EXACT = 'react.create_keywords.tooltip.targeting_type_exact',
  CREATE_KEYWORDS_TOOLTIP_TARGETING_TYPE_EXPANDED = 'react.create_keywords.tooltip.targeting_type_expanded',

  USER_PROFILE_TITLE = 'react.pages.user_profile.title',
  USER_PROFILE_HEADING = 'react.pages.user_profile.heading',
  USER_PROFILE_DESCRIPTION = 'react.pages.user_profile.description',
  USER_PROFILE_BASIC_INFO_SECTION_TITLE = 'react.pages.user_profile.basic_info_section.title',
  USER_PROFILE_FIRST_NAME_LABEL = 'react.pages.user_profile.first_name_label',
  USER_PROFILE_LAST_NAME_LABEL = 'react.pages.user_profile.last_name_label',
  USER_PROFILE_NAME_UPDATED = 'react.pages.user_profile.name_updated',
  USER_PROFILE_NAME_UPDATED_FAILURE = 'react.pages.user_profile.change_name_failure',
  USER_PROFILE_CHANGE_PASSWORD_CAPTION = 'react.pages.user_profile.change_password_caption',
  USER_PROFILE_CHANGE_PASSWORD_LABEL = 'react.pages.user_profile.change_password_label',
  USER_PROFILE_CHANGE_PASSWORD_SUCCESS = 'react.pages.user_profile.change_password_success',
  USER_PROFILE_CHANGE_PASSWORD_FAILURE = 'react.pages.user_profile.change_password_failure',

  USER_PROFILE_NOTIFICATION_PREFERENCES_EMAIL_TITLE = 'react.pages.user_profile.notification_preferences.email_title',
  USER_PROFILE_NOTIFICATION_PREFERENCES_NOTIFICATION_TITLE = 'react.pages.user_profile.notification_preferences.notification_title',
  USER_PROFILE_NOTIFICATION_PREFERENCES_LEVEL_EMAIL = 'react.pages.user_profile.notification_preferences.level.email',
  USER_PROFILE_NOTIFICATION_PREFERENCES_LEVEL_SLACK = 'react.pages.user_profile.notification_preferences.level.slack',
  USER_PROFILE_NOTIFICATION_PREFERENCES_SLACK_INFO = 'react.pages.user_profile.notification_preferences.slack_info',
  USER_PROFILE_NOTIFICATION_PREFERENCES_PERFORMANCE = 'react.pages.user_profile.notification_preferences.performance_section',
  USER_PROFILE_NOTIFICATION_PREFERENCES_WEEKLY_UPDATE = 'react.pages.user_profile.notification_preferences.weekly_update',
  USER_PROFILE_NOTIFICATION_PREFERENCES_WEEKLY_UPDATE_DESCRIPTION = 'react.pages.user_profile.notification_preferences.weekly_update_description',
  USER_PROFILE_NOTIFICATION_PREFERENCES_UNADVERTISED_PRODUCTS = 'react.pages.user_profile.notification_preferences.unadvertised_products',
  USER_PROFILE_NOTIFICATION_PREFERENCES_UNADVERTISED_PRODUCTS_DESCRIPTION = 'react.pages.user_profile.notification_preferences.unadvertised_products_description',
  USER_PROFILE_NOTIFICATION_PREFERENCES_BUDGET_PACING = 'react.pages.user_profile.notification_preferences.budget_pacing',
  USER_PROFILE_NOTIFICATION_PREFERENCES_BUDGET_PACING_DESCRIPTION = 'react.pages.user_profile.notification_preferences.budget_pacing_description',
  USER_PROFILE_NOTIFICATION_PREFERENCES_UPDATE_SUCCESS = 'react.pages.user_profile.notification_preferences.update_success',
  USER_PROFILE_NOTIFICATION_PREFERENCES_UPDATE_FAIL = 'react.pages.user_profile.notification_preferences.update_fail',

  USER_PROFILE_NOTIFICATION_PREFERENCES_INVENTORY_OPTIMIZATION = 'react.pages.user_profile.notification_preferences.inventory_optimization_section',
  USER_PROFILE_NOTIFICATION_PREFERENCES_OUT_OF_STOCK = 'react.pages.user_profile.notification_preferences.out_of_stock',
  USER_PROFILE_NOTIFICATION_PREFERENCES_OUT_OF_STOCK_DESCRIPTION = 'react.pages.user_profile.notification_preferences.out_of_stock_description',
  USER_PROFILE_NOTIFICATION_PREFERENCES_UNSOLD_PRODUCTS_LAST_90_DAYS = 'react.pages.user_profile.notification_preferences.unsold_products_last_90_days',
  USER_PROFILE_NOTIFICATION_PREFERENCES_UNSOLD_PRODUCTS_LAST_90_DAYS_DESCRIPTION = 'react.pages.user_profile.notification_preferences.unsold_products_last_90_days_description',

  USER_PROFILE_BUSINESS_NAME_LABEL = 'react.pages.user_profile.business_name_label',
  USER_PROFILE_PHONE_NUMBER_LABEL = 'react.pages.user_profile.phone_number_label',
  USER_PROFILE_ANNUAL_GMV_LABEL = 'react.pages.user_profile.annual_gmv_label',
  USER_PROFILE_ANNUAL_MPS_LABEL = 'react.pages.user_profile.annual_mps_label',

  USERS_TITLE = 'react.pages.users.title',
  COMPANY_PAGE_TITLE = 'react.pages.company.title',
  COMPANY_PAGE_CARD_TITLE_COMPANY_NAME = 'react.pages.company.card_title.company_name',
  COMPANY_PAGE_COMPANY_NAME_UPDATE_SUCCESS = 'react.pages.company.company_name.success',
  FILTER_NO_RESULTS = 'react.filter.no_results',
  FILTER_BUTTON_TOOLTIP = 'react.components.filter.button_tooltip',
  FILTER_CONDITIONAL_EQUAL = 'react.filter.conditional.equal',
  FILTER_CONDITIONAL_LESS_THAN = 'react.filter.conditional.less_than',
  FILTER_CONDITIONAL_GREATER_THAN = 'react.filter.conditional.greater_than',
  FILTER_CONDITIONAL_BETWEEN = 'react.filter.conditional.between',
  FILTER_CONDITIONAL_AFTER = 'react.filter.conditional.after',
  FILTER_CONDITIONAL_BEFORE = 'react.filter.conditional.before',
  FILTER_CONDITIONAL_EXACT = 'react.filter.conditional.exact',
  FILTER_CONDITIONAL_IN = 'react.filter.conditional.in',
  FILTER_CONDITIONAL_NOT_IN = 'react.filter.conditional.not_in',
  FILTER_CONDITIONAL_IS = 'react.filter.conditional.is',
  FILTER_CONDITIONAL_IS_NOT = 'react.filter.conditional.is_not',
  FILTER_CONDITIONAL_IS_EMPTY = 'react.filter.conditional.is_empty',
  FILTER_CONDITIONAL_IS_EMPTY_VALUE = 'react.filter.conditional.is_empty_value',
  FILTER_ADD_FILTER = 'react.filter.add_filter',
  FILTER_ADD_FILTERS = 'react.filter.add_filters',
  FILTER_APPLY_FILTER = 'react.filter.apply_filter',
  FILTER_NO_APPLY_FILTER = 'react.filter_row.no_filter_applied',
  FILTER_OPEN_BUTTON = 'react.filter.open_button',
  FILTER_NO_FILTERS = 'react.filter.no_filters',
  FILTER_WHERE = 'react.filter.where',
  FILTER_ROW_CANCEL = 'react.filter_row.cancel_label',
  CUSTOMIZATION_ROW_TITLE = 'react.components.customization_row.title',
  CUSTOMIZATION_ROW_CLEAR_ALL_FILTERS = 'react.components.customization_row.clear_all_filters',
  BULK_ACTION_ROW_SELECTOR_SELECTED = 'react.components.bulk_action_selector.selected',
  BULK_ACTION_ROW_SELECTOR_TOTAL_ROWS = 'react.components.bulk_action_selector.total_rows',
  BULK_ACTION_ROW_SELECTOR_MATCH_RESULTS = 'react.components.bulk_action_selector.match_results',
  BULK_ACTION_ROW_SELECTOR_ROW_SINGULAR = 'react.components.bulk_action_selector.row_singular',
  BULK_ACTION_ROW_SELECTOR_ROWS_PLURAL = 'react.components.bulk_action_selector.rows_plural',
  BULK_ACTION_MODAL_HEADER = 'react.components.bulk_action_selector.modal.header',

  PRODUCTS_SKU_CATALOG_TABLE_PRODUCT = 'react.products.sku_catalog_table.product',
  PRODUCTS_SKU_CATALOG_TABLE_PROFITABILITY = 'react.products.sku_catalog_table.profitability',
  PRODUCTS_SKU_CATALOG_TABLE_TACOS = 'react.products.sku_catalog_table.tacos',
  PRODUCTS_SKU_CATALOG_TABLE_AVERAGE_ORDER_PRICE = 'react.products.sku_catalog_table.average_order_price',
  PRODUCTS_SKU_CATALOG_TABLE_TOTAL_SALES = 'react.products.sku_catalog_table.total_sales',
  PRODUCTS_SKU_CATALOG_TABLE_ESTIMATED_GROSS_PROFIT = 'react.products.sku_catalog_table.estimated_gross_profit',
  PRODUCTS_SKU_CATALOG_TABLE_UNITS_SOLD = 'react.products.sku_catalog_table.units_sold',
  PRODUCTS_SKU_CATALOG_TABLE_SHIPPED_COGS = 'react.products.sku_catalog_table.shipped_cogs',
  PRODUCTS_SKU_CATALOG_TABLE_SHIPPED_UNITS = 'react.products.sku_catalog_table.shipped_units',
  PRODUCTS_SKU_CATALOG_TABLE_ESTIMATED_GROSS_MARGIN = 'react.products.sku_catalog_table.estimated_gross_margin',
  PRODUCTS_SKU_CATALOG_TABLE_ESTIMATED_PRE_AD_GROSSMARGIN = 'react.products.sku_catalog_table.estimated_pre_ad_grossMargin',
  PRODUCTS_TOAST_MESSAGE_FILE_DOWNLOAD = 'react.pages.products.toast_message.file_download',
  PRODUCTS_TOAST_MESSAGE_EXPORT_FAILED_HEADER = 'react.pages.products.toast_message.export_failed_header',
  PRODUCTS_TOAST_MESSAGE_EXPORT_FAILED = 'react.pages.products.toast_message.export_failed',
  PRODUCTS_SKU_VIEW_TYPE_PARENT_CHILD_TITLE = 'react.products.sku.view_type_select.parent_child_title',
  PRODUCTS_SKU_VIEW_TYPE_PARENT_CHILD_DESC = 'react.products.sku.view_type_select.parent_child_desc',
  PRODUCTS_SKU_VIEW_TYPE_MULTI_CHANNEL_TITLE = 'react.products.sku.view_type_select.parent_multi_channel_title',
  PRODUCTS_SKU_VIEW_TYPE_MULTI_CHANNEL_DESC = 'react.products.sku.view_type_select.parent_multi_channel_desc',
  PRODUCTS_SKU_VIEW_TYPE_INDIVIDUAL_CHILD_TITLE = 'react.products.sku.view_type_select.individual_child_channel_title',
  PRODUCTS_SKU_VIEW_TYPE_INDIVIDUAL_CHILD_DESC = 'react.products.sku.view_type_select.individual_child_channel_desc',
  PRODUCTS_SKU_VIEW_TYPE_DROPDOWN_HEADER = 'react.products.sku.view_type.header',
  PRODUCTS_SKU_VIEW_MULTI_CHANNEL_SELECT_BANNER_GROUP_HEADER = 'react.products.sku.multi_channel.select_banner.group.header',
  PRODUCTS_SKU_VIEW_MULTI_CHANNEL_SELECT_BANNER_PARENT_HEADER = 'react.products.sku.multi_channel.select_banner.parent.header',

  PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_PRODUCT = 'react.products.sku_overview_table.header_product',
  PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_PROFITABILITY = 'react.products.sku_overview_table.header_profitability',
  PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_FEES = 'react.products.sku_overview_table.header_fees',
  PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_ADS = 'react.products.sku_overview_table.header_ads',
  PRODUCTS_SKU_OVERVIEW_TABLE_HEADER_INVENTORY = 'react.products.sku_overview_table.header_inventory',

  PRODUCTS_SKU_OVERVIEW_TABLE_AVERAGE_ORDER_PRICE = 'react.products.sku_overview_table.average_order_price',
  PRODUCTS_SKU_OVERVIEW_TABLE_TACOS = 'react.products.sku_overview_table.tacos',
  PRODUCTS_SKU_OVERVIEW_TABLE_TOTAL_SALES = 'react.products.sku_overview_table.total_sales',
  PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_GROSS_PROFIT = 'react.products.sku_overview_table.estimated_gross_profit',
  PRODUCTS_SKU_OVERVIEW_TABLE_UNITS_SOLD = 'react.products.sku_overview_table.units_sold',
  PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_GROSS_MARGIN = 'react.products.sku_overview_table.estimated_gross_margin',
  PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_PRE_AD_GROSS_MARGIN = 'react.products.sku_overview_table.estimated_pre_ad_grossMargin',
  PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_COGS = 'react.products.sku_overview_table.cogs',
  PRODUCTS_SKU_OVERVIEW_TABLE_ESTIMATED_FEES = 'react.products.sku_overview_table.fees',
  PRODUCTS_SKU_OVERVIEW_TABLE_AD_SALES = 'react.products.sku_overview_table.ad_sales',
  PRODUCTS_SKU_OVERVIEW_TABLE_AD_SPEND = 'react.products.sku_overview_table.ad_spend',
  PRODUCTS_SKU_OVERVIEW_TABLE_ACOS = 'react.products.sku_overview_table.acos',
  PRODUCTS_SKU_OVERVIEW_TABLE_CONVERSION_RATE = 'react.products.sku_overview_table.conversion_rate',
  PRODUCTS_SKU_OVERVIEW_TABLE_CAMPAIGN_COUNT = 'react.products.sku_overview_table.campaign_count',
  PRODUCTS_SKU_OVERVIEW_TABLE_AD_GROUP_COUNT = 'react.products.sku_overview_table.ad_group_count',
  PRODUCTS_SKU_OVERVIEW_TABLE_INVENTORY_VALUE = 'react.products.sku_overview_table.inventory_value',
  PRODUCTS_GLOSSARY_TOOLTIP = 'react.products.glossary.tooltip_text',
  PRODUCTS_GLOSSARY_HEADING = 'react.products.glossary.heading',
  PRODUCTS_GLOSSARY_TOTAL_SALES = 'react.products.glossary.total_sales',
  PRODUCTS_GLOSSARY_TOTAL_SALES_1P = 'react.products.glossary.total_sales_1p',
  PRODUCTS_GLOSSARY_TOTAL_SALES_FORMULA = 'react.products.glossary.total_sales.formula',
  PRODUCTS_GLOSSARY_TOTAL_SALES_FORMULA_1P = 'react.products.glossary.total_sales_1p.formula',
  PRODUCTS_GLOSSARY_ESTIMATED_GROSS_PROFIT = 'react.products.glossary.estimated_gross_profit',
  PRODUCTS_GLOSSARY_ESTIMATED_GROSS_PROFIT_1P = 'react.products.glossary.estimated_gross_profit_1p',
  PRODUCTS_GLOSSARY_ESTIMATED_GROSS_PROFIT_FORMULA = 'react.products.glossary.estimated_gross_profit.formula',
  PRODUCTS_GLOSSARY_ESTIMATED_GROSS_PROFIT_FORMULA_1P = 'react.products.glossary.estimated_gross_profit_1p.formula',
  PRODUCTS_GLOSSARY_UNITS_SOLD = 'react.products.glossary.units_sold',
  PRODUCTS_GLOSSARY_SHIPPED_COGS = 'react.products.glossary.shipped_cogs',
  PRODUCTS_GLOSSARY_SHIPPED_COGS_FORMULA = 'react.products.glossary.shipped_cogs.formula',

  PRODUCTS_GLOSSARY_SHIPPED_UNITS = 'react.products.glossary.shipped_units',
  PRODUCTS_GLOSSARY_SHIPPED_UNITS_FORMULA = 'react.products.glossary.shipped_units.formula',
  PRODUCTS_GLOSSARY_UNITS_SOLD_1P = 'react.products.glossary.units_sold_1p',
  PRODUCTS_GLOSSARY_UNITS_SOLD_FORMULA = 'react.products.glossary.units_sold.formula',
  PRODUCTS_GLOSSARY_UNITS_SOLD_FORMULA_1P = 'react.products.glossary.units_sold_1p.formula',
  PRODUCTS_GLOSSARY_ESTIMATED_GROSS_MARGIN = 'react.products.glossary.estimated_gross_margin',
  PRODUCTS_GLOSSARY_ESTIMATED_GROSS_MARGIN_1P = 'react.products.glossary.estimated_gross_margin_1p',
  PRODUCTS_GLOSSARY_ESTIMATED_GROSS_MARGIN_FORMULA = 'react.products.glossary.estimated_gross_margin.formula',
  PRODUCTS_GLOSSARY_ESTIMATED_GROSS_MARGIN_FORMULA_1P = 'react.products.glossary.estimated_gross_margin_1p.formula',
  PRODUCTS_GLOSSARY_ESTIMATED_PRE_AD_GROSS_MARGIN = 'react.products.glossary.estimated_pre_ad_gross_margin',
  PRODUCTS_GLOSSARY_ESTIMATED_PRE_AD_GROSS_MARGIN_1P = 'react.products.glossary.estimated_pre_ad_gross_margin_1p',
  PRODUCTS_GLOSSARY_ESTIMATED_PRE_AD_GROSS_MARGIN_FORMULA = 'react.products.glossary.estimated_pre_ad_gross_margin.formula',
  PRODUCTS_GLOSSARY_ESTIMATED_PRE_AD_GROSS_MARGIN_FORMULA_1P = 'react.products.glossary.estimated_pre_ad_gross_margin_1p.formula',
  PRODUCTS_GLOSSARY_AD_SALES = 'react.products.glossary.ad_sales',
  PRODUCTS_GLOSSARY_AD_SALES_FORMULA = 'react.products.glossary.ad_sales.formula',
  PRODUCTS_GLOSSARY_ADVERTISED = 'react.products.glossary.advertised',
  PRODUCTS_GLOSSARY_ADVERTISED_FORMULA = 'react.products.glossary.advertised.formula',
  PRODUCTS_GLOSSARY_AD_SPEND = 'react.products.glossary.ad_spend',
  PRODUCTS_GLOSSARY_AD_SPEND_FORMULA = 'react.products.glossary.ad_spend.formula',
  PRODUCTS_GLOSSARY_ACOS = 'react.products.glossary.acos',
  PRODUCTS_GLOSSARY_ACOS_FORMULA = 'react.products.glossary.acos.formula',
  PRODUCTS_GLOSSARY_AD_CONVERSION_RATE = 'react.products.glossary.conversion_rate',
  PRODUCTS_GLOSSARY_AD_CONVERSION_RATE_FORMULA = 'react.products.glossary.conversion_rate.formula',
  PRODUCTS_GLOSSARY_CAMPAIGN_COUNT = 'react.products.glossary.campaign_count',
  PRODUCTS_GLOSSARY_CAMPAIGN_COUNT_FORMULA = 'react.products.glossary.campaign_count.formula',
  PRODUCTS_GLOSSARY_ADGROUP_COUNT = 'react.products.glossary.adgroup_count',
  PRODUCTS_GLOSSARY_ADGROUP_COUNT_FORMULA = 'react.products.glossary.adgroup_count.formula',
  PRODUCTS_GLOSSARY_AVERAGE_ORDER_PRICE = 'react.products.glossary.average_order_price',
  PRODUCTS_GLOSSARY_AVERAGE_ORDER_PRICE_FORMULA = 'react.products.glossary.average_order_price.formula',
  PRODUCTS_GLOSSARY_TACOS = 'react.products.glossary.tacos',
  PRODUCTS_GLOSSARY_TACOS_1P = 'react.products.glossary.tacos_1p',
  PRODUCTS_GLOSSARY_TACOS_FORMULA = 'react.products.glossary.tacos.formula',
  PRODUCTS_GLOSSARY_TACOS_FORMULA_1P = 'react.products.glossary.tacos_1p.formula',
  PRODUCTS_GLOSSARY_COGS = 'react.products.glossary.cogs',
  PRODUCTS_GLOSSARY_COGS_FORMULA = 'react.products.glossary.cogs.formula',
  PRODUCTS_GLOSSARY_ESTIMATED_FEES = 'react.products.glossary.estimated_fees',
  PRODUCTS_GLOSSARY_ESTIMATED_FEES_FORMULA = 'react.products.glossary.estimated_fees.formula',
  PRODUCTS_GLOSSARY_INVENTORY_VALUE = 'react.products.glossary.inventorty_value',
  PRODUCTS_GLOSSARY_INVENTORY_VALUE_FORMULA = 'react.products.glossary.inventorty_value.formula',
  PRODUCTS_GLOSSARY_CURRENT_INVENTORY_QUANTITY = 'react.products.glossary.current_inventory_quantity',
  PRODUCTS_GLOSSARY_CURRENT_INVENTORY_QUANTITY_FORMULA = 'react.products.glossary.current_inventory_quantity.formula',
  PRODUCTS_GLOSSARY_ROAS = 'react.products.glossary.roas',
  PRODUCTS_GLOSSARY_ROAS_FORMULA = 'react.products.glossary.roas.formula',
  PRODUCTS_GLOSSARY_AD_CONVERSIONS = 'react.products.glossary.ad_conversions',
  PRODUCTS_GLOSSARY_AD_CONVERSIONS_FORMULA = 'react.products.glossary.ad_conversions.formula',
  PRODUCTS_GLOSSARY_AD_UNITS_SOLD = 'react.products.glossary.ad_units_sold',
  PRODUCTS_GLOSSARY_AD_UNITS_SOLD_FORMULA = 'react.products.glossary.ad_units_sold.formula',
  PRODUCTS_GLOSSARY_IMPRESSIONS = 'react.products.glossary.impressions',
  PRODUCTS_GLOSSARY_IMPRESSIONS_FORMULA = 'react.products.glossary.impressions.formula',
  PRODUCTS_GLOSSARY_CLICKS = 'react.products.glossary.clicks',
  PRODUCTS_GLOSSARY_CLICKS_FORMULA = 'react.products.glossary.clicks.formula',
  PRODUCTS_GLOSSARY_COST_PER_CLICK = 'react.products.glossary.cost_per_click',
  PRODUCTS_GLOSSARY_COST_PER_CLICK_FORMULA = 'react.products.glossary.cost_per_click.formula',
  PRODUCTS_GLOSSARY_CLICK_THROUGH_RATE = 'react.products.glossary.click_through_rate',
  PRODUCTS_GLOSSARY_CLICK_THROUGH_RATE_FORMULA = 'react.products.glossary.click_through_rate.formula',

  PRODUCTS_SKU_DETAILS_SHOW_PREVIOUS = 'react.products.sku_details.show_previous',
  PRODUCTS_SKU_DETAILS_EVENTS = 'react.products.sku_details.events',

  PRODUCTS_EVENT_LOG_POPUP_HEADING = 'react.products.event_log.popup.heading',
  PRODUCTS_EVENT_LOG_POPUP_HEADING_EDIT = 'react.products.event_log.popup.heading_edit',
  PRODUCTS_EVENT_LOG_POPUP_REMOVE_EVENT = 'react.products.event_log.popup.remove_event',
  PRODUCTS_EVENT_LOG_POPUP_EVENT = 'react.products.event_log.popup.event',
  PRODUCTS_EVENT_LOG_POPUP_NOTE = 'react.products.event_log.popup.note',
  PRODUCTS_EVENT_LOG_POPUP_NOTE_PLACEHOLDER = 'react.products.event_log.popup.placeholder_note',
  PRODUCTS_EVENT_LOG_EVENT_SUMMARY = 'react.products.event_log.summary',
  PRODUCTS_EVENT_LOG_EVENT_SUMMARY_STARTING_PLACEHOLDER = 'react.products.event_log.summary.starting_placeholder',
  PRODUCTS_EVENT_LOG_EVENT_SUMMARY_PRICE_UPDATED = 'react.products.event_log.summary.price_updated',
  PRODUCTS_EVENT_LOG_EVENT_SUMMARY_PRICE_CREATED = 'react.products.event_log.summary.price_created',
  PRODUCTS_EVENT_LOG_EVENT_SUMMARY_OUT_OF_STOCK = 'react.products.event_log.summary.out_of_stock',
  PRODUCTS_EVENT_LOG_SECONDRY_POPUP_TEXT = 'react.products.event_log.secondry_popup.text',
  PRODUCTS_EVENT_LOG_LISTING_OPTION_A_MODULE_CREATED = 'react.products.event_log.listing_option.a_module_created',
  PRODUCTS_EVENT_LOG_LISTING_OPTION_A_MODULE_UPDATED = 'react.products.event_log.listing_option.a_module_updated',
  PRODUCTS_EVENT_LOG_LISTING_OPTION_BULLETS_UPDATED = 'react.products.event_log.listing_option.bullets_updated',
  PRODUCTS_EVENT_LOG_LISTING_OPTION_DESCRIPTION_UPDATED = 'react.products.event_log.listing_option.description_updated',
  PRODUCTS_EVENT_LOG_LISTING_OPTION_OTHER_IMAGES_UPDATED = 'react.products.event_log.listing_option.other_images_updated',
  PRODUCTS_EVENT_LOG_LISTING_OPTION_PRIMARY_IMAGES_UPDATED = 'react.products.event_log.listing_option.primary_images_updated',
  PRODUCTS_EVENT_LOG_LISTING_OPTION_TITLE_UPDATED = 'react.products.event_log.listing_option.title_updated',
  PRODUCTS_EVENT_LOG_POPUP_OPTIONAL = 'react.products.event_log.optional',
  PRODUCTS_EVENT_LOG_POPUP_DATE_ERROR_MESSAGE = 'react.products.event_log.popup.date.error.tooltip',
  PRODUCTS_EVENT_LOG_POPUP_TIME_INCOMPLETE_MESSAGE = 'react.products.event_log.popup.time.error.tooltip.incomplete',
  PRODUCTS_EVENT_LOG_POPUP_TIME_INVALID_FORMAT_MESSAGE = 'react.products.event_log.popup.date.error.tooltip.invalid_format',
  PRODUCTS_EVENT_LOG_TOAST_SUCCESS_MESSAGE = 'react.products.event_log.success.toast.message_added',
  PRODUCTS_EVENT_LOG_TOAST_SUCCESS_MESSAGE_EDITED = 'react.products.event_log.success.toast.message_edited',
  PRODUCTS_EVENT_LOG_TOAST_SUCCESS_MESSAGE_REMOVED = 'react.products.event_log.success.toast.message_removed',
  PRODUCTS_EVENT_LOG_TOAST_FAILED_MESSAGE = 'react.products.event_log.failed.toast.message',
  PRODUCTS_EVENT_LOG_SAME_DAY_INFO_MESSAGE = 'react.products.event_log.info_message.same_day',
  PRODUCTS_EVENT_LOG_PENDING = 'react.products.event_log.pending',
  PRODUCTS_EVENT_LOG_REMOVE_POPUP_HEADING = 'react.products.event_log.remove_pop_up.heading',
  PRODUCTS_EVENT_LOG_REMOVE_POPUP_DESCRIPTION = 'react.products.event_log.remove_pop_up.description',
  PRODUCTS_EVENT_FILTER_SLIDEOUT_FILTERS_TITLE = 'react.products.event_log.filter_sildeout_filter_title',

  SKU_MANAGE_COGS = 'react.sku.manage_cogs',
  COGS_MODAL_HEADER = 'react.cogs_modal.header',
  COGS_MODAL_STATE_UPLOADED_HEADER = 'react.cogs_modal.state.uploaded.header',
  COGS_MODAL_STATE_UPLOADED_DESCRIPTION = 'react.cogs_modal.state.uploaded.description',
  COGS_MODAL_STATE_UPLOADED_BUTTON_TEXT = 'react.cogs_modal.state.uploaded.button_text',
  COGS_MODAL_STEP_1_HEADER = 'react.cogs_modal.step_1.header',
  COGS_MODAL_STEP_2_HEADER = 'react.cogs_modal.step_2.header',
  COGS_MODAL_STEP_3_HEADER = 'react.cogs_modal.step_3.header',
  COGS_MODAL_STEP_1_DESCRIPTION = 'react.cogs_modal.step_1.description',
  COGS_MODAL_STEP_2_DESCRIPTION = 'react.cogs_modal.step_2.description',
  COGS_MODAL_STEP_3_DESCRIPTION = 'react.cogs_modal.step_3.description',
  COGS_MODAL_STEP_1_CSV_INSTRUCTION = 'react.cogs_modal.step_1.csv_instruction',
  COGS_MODAL_STEP_1_DOWNLOAD_CSV = 'react.cogs_modal.step_1.download_csv',
  COGS_MODAL_STEP_1_TEMPLATE_DOWNLOADED = 'react.cogs_modal.step_1.template_downloaded',
  COGS_MODAL_STEP_2_MORE_INFO = 'react.cogs_modal.step_2.more_info',
  COGS_MODAL_STEP_3_ATTACH_CSV_FILE = 'react.cogs_modal.step_3.attach_csv_file',
  COGS_MODAL_STEP_3_UPLOAD = 'react.cogs_modal.step_3.upload',
  COGS_MODAL_STEP_3_ERROR_IN_UPLOAD = 'react.cogs_modal.step_3.error_in_upload',
  COGS_MODAL_STEP_3_RETRY = 'react.cogs_modal.step_3.retry',
  COGS_MODAL_STEP_1_DOWNLOAD_HEADLINE = 'react.cogs_modal.step_1.download.headline',
  COGS_MODAL_STEP_1_DOWNLOAD_DESCRIPTION = 'react.cogs_modal.step_1.download.description',
  COGS_MODAL_STEP_1_DOWNLOAD_DESCRIPTION_COGS = 'react.cogs_modal.step_1.download.cogs',
  COGS_MODAL_STEP_1_UPLOAD_FAILED_DESCRIPTION_SKU = 'react.cogs_modal.step_1.upload.failed.description_sku',
  COGS_MODAL_STEP_1_UPLOAD_FAILED_DESCRIPTION = 'react.cogs_modal.step_1.upload.failed.description',
  COGS_MODAL_STEP_1_UPLOAD_SUCCESS_HEADLINE = 'react.cogs_modal.step_1.upload.success.headline',
  COGS_MODAL_STEP_1_UPLOAD_SUCCESS_DESCRIPTION = 'react.cogs_modal.step_1.upload.success.description',
  COGS_MODAL_STEP_1_UPLOAD_SUCCESS_DESCRIPTION_SKU = 'react.cogs_modal.step_1.upload.success.description_2',
  COGS_MODAL_STEP_1_DOWNLOAD_SUCCESS_HEADLINE = 'react.cogs_modal.step_1.download.success.headline',
  COGS_MODAL_STEP_1_DOWNLOAD_SUCCESS_DESCRIPTION = 'react.cogs_modal.step_1.download.success.description',
  CUSTOM_UPLOAD_TEMPLATE_DROPDOWN_PRODUCT = 'react.custom_upload.template_dropdown.product',
  CUSTOM_UPLOAD_TEMPLATE_DROPDOWN_ADS = 'react.custom_upload.template_dropdown.ads',
  CUSTOM_UPLOAD_TEMPLATE_DROPDOWN_BUDGET = 'react.custom_upload.template_dropdown.budget',
  CUSTOM_UPLOAD_TEMPLATE_DROPDOWN_DSP = 'react.custom_upload.template_dropdown.dsp',

  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_DETAILS = 'react.products.ad_performance_table.header.details',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_HEADER_PERFORMANCE = 'react.products.ad_performance_table.header.performance',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CHANNEL = 'react.products.ad_performance_table.channel',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_MERCHANT = 'react.products.ad_performance_table.merchant',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CAMPAIGN = 'react.products.ad_performance_table.campaign',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CAMPAIGN_TYPE = 'react.products.ad_performance_table.campaign_type',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_ADGROUP = 'react.products.ad_performance_table.adgroup',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_STATUS = 'react.products.ad_performance_table.ad_status',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_IMPRESSIONS = 'react.products.ad_performance_table.impressions',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CLICKS = 'react.products.ad_performance_table.clicks',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_SALES = 'react.products.ad_performance_table.ad_sales',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_SPEND = 'react.products.ad_performance_table.ad_spend',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_ACOS = 'react.products.ad_performance_table.acos',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_ROAS = 'react.products.ad_performance_table.roas',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CTR = 'react.products.ad_performance_table.ctr',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CPC = 'react.products.ad_performance_table.cpc',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_AD_CONVERSIONS = 'react.products.ad_performance_table.ad_conversions',
  PRODUCTS_SKU_AD_PERFORMANCE_TABLE_CONVERSION_RATE = 'react.products.ad_performance_table.conversion_rate',
  PRODUCTS_MISSING_CONNECTION_TITLE = 'Missing product data connections',
  PRODUCTS_MISSING_CONNECTION_DESCRIPTION = 'Product data required for Product Catalog',
  PRODUCTS_MISSING_CONNECTION_BUTTON_TEXT = 'Manage connections',
  PRODUCTS_SYNCHING_CONNECTION_TITLE = 'Your data is syncing',
  PRODUCTS_SYNCHING_CONNECTION_DESCRIPTION = 'Product data may take up to 24 hours to complete syncing. Please check back later.',

  USER_DELETE_USER_ACTIONS_CANCEL = 'react.user.delete.actions.cancel',
  USER_GET_DETAILS_CHANGE_PASSWORD_TITLE = 'react.user.get.details.change_password.title',
  USER_GET_DETAILS_CHANGE_PASSWORD_DESCRIPTION = 'react.user.get.details.change_password.description',
  USER_GET_DETAILS_CHANGE_PASSWORD_CONFIRMATION_BUTTON = 'react.user.get.details.change_password.confirmation_button',
  USER_GET_DETAILS_CHANGE_PASSWORD_CANCEL_BUTTON = 'react.user.get.details.change_password.cancel_button',
  USER_GET_DETAILS_CHANGE_PASSWORD_SUCCESS = 'react.user.get.details.change_password.success_message',
  SEARCH_BAR_PLACEHOLDER = 'react.components.search_bar.placeholder',
  PAGINATED_TABLE_MAX_ROWS = 'react.components.paginated_table.max_rows',
  PAGINATED_TABLE_OF_TOTAL_PAGES = 'react.components.paginated_table.of_total_pages',
  PAGINATED_TABLE_NO_DATA_HEADER = 'react.components.paginated_table.no_data.header',
  PAGINATED_TABLE_NO_DATA_DESCRIPTION = 'react.components.paginated_table.no_data.description',
  DATE_PICKER_APPLY = 'react.components.date_picker.apply',
  DATE_PICKER_CANCEL = 'react.components.date_picker.cancel',
  CONTEXT_SETTER_SALES_CHANNEL_PICKER_MERCHANT = 'react.components.context_setter.sales_channel_picker_merchant',
  CONTEXT_SETTER_SALES_CHANNEL_PICKER_MERCHANTS = 'react.components.context_setter.sales_channel_picker_merchants',
  CONTEXT_SETTER_SALES_CHANNEL_PICKER_CHANNEL = 'react.components.context_setter.sales_channel_picker_channel',
  CONTEXT_SETTER_SALES_CHANNEL_AND_PICKER_MERCHANT = 'react.components.context_setter.sales_channel_and_merchant_picker',
  CONTEXT_SETTER_SALES_CURRENCY_PICKER_CHANNEL = 'react.components.context_setter.currency_picker',
  CONTEXT_SETTER_SALES_REPORTING_CURRENCY_PICKER_CHANNEL = 'react.components.context_setter.reporting_currency_picker',
  CONTEXT_SETTER_TIMEFRAME_LABEL = 'react.components.context_setter.timeframe_label',

  HERO_METRICS_FORMULA_AD_SALES = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_sales',
  HERO_METRICS_FORMULA_AD_SALES_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_sales_description',
  HERO_METRICS_FORMULA_AD_SPEND = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_spend',
  HERO_METRICS_FORMULA_AD_SPEND_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_spend_description',
  HERO_METRICS_FORMULA_CONVERSION_RATE = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.conversion_rate',
  HERO_METRICS_FORMULA_CONVERSION_RATE_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.conversion_rate_description',
  HERO_METRICS_FORMULA_ROAS = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.roas',
  HERO_METRICS_FORMULA_ROAS_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.roas_description',
  HERO_METRICS_FORMULA_UNITS_SOLD_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.units_sold_description',
  HERO_METRICS_FORMULA_AD_CONVERSIONS_DESCRIPTION = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_conversions_description',
  HERO_METRICS_FORMULA_DIRECT_AD_SALES = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.direct_ad_sales',
  HERO_METRICS_FORMULA_RELATED_CLICK_AD_SALES = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.related_click_ad_sales',
  HERO_METRICS_FORMULA_AD_CONVERSIONS = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_conversions',
  HERO_METRICS_FORMULA_AD_UNITS_SOLD = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.ad_units_sold',
  HERO_METRICS_FORMULA_CLICKS = 'react.ad_optimization.campaigns.hero_metrics.formula_popover.clicks',
  HERO_METRICS_PREVIOUS_METRIC_LOAD_ERROR = 'react.ad_optimization.ads_manager.hero_metrics.previous_metric.load_error',
  HERO_METRICS_LOAD_ERROR_BEFORE_RELOAD = 'react.ad_optimization.ads_manager.hero_metrics.load_error_before_reload',
  HERO_METRICS_LOAD_ERROR_AFTER_RELOAD = 'react.ad_optimization.ads_manager.hero_metrics.load_error_after_reload',
  HERO_METRICS_CURRENT_PERIOD_UNAVAILABLE_ERROR_HEADER = 'react.ad_optimization.ads_manager.hero_metrics.current_period_unavailable.error_header',
  HERO_METRICS_CURRENT_PERIOD_UNAVAILABLE_ERROR_SUBTEXT = 'react.ad_optimization.ads_manager.hero_metrics.current_period_unavailable.error_sub_text',
  HERO_METRICS_CURRENT_FILTER_DATA_UNAVAILABLE_ERROR_SUBTEXT = 'react.ad_optimization.ads_manager.hero_metrics.current_filter_data_unavailable.error_sub_text',
  HERO_METRICS_RETRY_BUTTON_TEXT = 'react.ad_optimization.ads_manager.hero_metrics.retry',
  HERO_METRICS_CONTACT_SUPPORT_BUTTON_TEXT = 'react.ad_optimization.ads_manager.hero_metrics.contact_support',
  LEARN_MORE = 'terms.learn_more',
  NAME = 'terms.name',
  CHANGE = 'terms.change',
  CHANGES = 'terms.changes',
  BACK = 'terms.back',
  ACCOUNT_OWNER = 'terms.role.account_owner',
  ADMIN = 'terms.role.admin',
  ANALYST = 'terms.role.analyst',
  EDITOR = 'terms.role.editor',
  VIEW_ONLY = 'terms.role.view_only',
  APPLY = 'terms.apply',
  CANCEL = 'terms.cancel',
  DISABLE = 'terms.disable',
  SET_MACS = 'terms.set_macs',
  DO_THIS_LATER = 'terms.do_this_later',
  GOT_IT = 'terms.got_it',
  SUCCESS = 'terms.success',
  SUBMITTED = 'terms.submitted',
  FAILED = 'terms.failed',
  ERROR = 'terms.error',
  UPDATE = 'terms.update',
  REMOVE = 'terms.remove',
  SKIP = 'react.button.skip',
  CLOSE = 'react.button.close',
  SEARCH = 'react.button.search',
  READY = 'terms.ready',
  RETRY = 'terms.retry',
  FINISH = 'terms.finish',
  TURN_OFF = 'terms.turn_off',
  CONNECT = 'terms.connect',
  CONNECT_ADS = 'terms.connect_ads',
  CONTINUE = 'terms.continue',
  REQUIRED = 'terms.required',
  PASSWORD = 'terms.password',
  CONNECTED = 'terms.connected',
  COMPLETE = 'terms.complete',
  SYNCING = 'terms.syncing',
  MISSING = 'terms.missing',
  PROCESSING = 'terms.processing',
  NOT_APPLICABLE = 'terms.not_applicable',
  DESIGN_SYSTEM_COMPONENT_FILTER_ROW_REMOVE_ALL_BUTTON_LABEL = 'react.design_system.component.filter_row.remove_all_button_label',
  SLIDER_TABLE_VIEW = 'react.table.dual_mode.slider.view',
  SLIDER_TABLE_EDIT = 'react.table.dual_mode.slider.edit',
  SELECT_NOT_SET = 'react.components.select.not_set',
  SEARCH_INPUT_PLACEHOLDER = 'react.search_input.placeholder',
  SEARCH_INPUT_PLACEHOLDER_CAMPAIGNS = 'react.search_input.placeholder.campaigns',
  SEARCH_INPUT_PLACEHOLDER_ADGROUPS = 'react.search_input.placeholder.adgroups',
  SEARCH_INPUT_PLACEHOLDER_PRODUCT = 'react.search_input.placeholder.product_ads',
  SEARCH_INPUT_PLACEHOLDER_KEYWORD_TARGETS = 'react.search_input.placeholder.keyword_targets',
  SEARCH_INPUT_PLACEHOLDER_TARGETS = 'react.search_input.placeholder.targets',
  ASYNC_REQUEST_SOMETHING_WRONG_MESSAGE = 'react.async_request.errors.something_wrong_message',
  ASYNC_REQUEST_REFRESH_PAGE_MESSAGE = 'react.async_request.errors.refresh_page_message',
  AO_EDIT_TABLE_SAVE_CHANGES_HEADER = 'react.ad_optimization.edit.table.save_changes_header',
  AO_EDIT_TABLE_SAVE_CHANGES_BODY = 'react.ad_optimization.edit.table.save_changes_body',
  AO_EDIT_TABLE_SAVE_CHANGES_PRIMARY = 'react.ad_optimization.edit.table.save_changes_primary',
  AO_EDIT_TABLE_SAVE_CHANGES_SECONDARY = 'react.ad_optimization.edit.table.save_changes_secondary',
  DATA_CONNECTIONS_HEADER = 'react.data_connections.header',
  DATA_CONNECTIONS_ADS_DATA = 'react.data_connections.ads_data',
  DATA_CONNECTIONS_PRODUCTS_DATA = 'react.data_connections.products_data',
  DATA_CONNECTIONS_CONNECT_BUTTON_TEXT = 'react.data_connections.connect_button_text',
  DATA_CONNECTIONS_TOOLTIP_INITIAL_TITLE = 'react.data_connections.tooltip.initial_title',
  DATA_CONNECTIONS_TOOLTIP_INITIAL_DESCRIPTION = 'react.data_connections.tooltip.initial_description',
  DATA_CONNECTIONS_TOOLTIP_SYNCING_TITLE = 'react.data_connections.tooltip.syncing_title',
  DATA_CONNECTIONS_TOOLTIP_SYNCING_DESCRIPTION = 'react.data_connections.tooltip.syncing_description',
  DATA_CONNECTIONS_TOOLTIP_ADS_SYNCING_DESCRIPTION = 'react.data_connections.tooltip.ads_syncing_description',
  DATA_CONNECTIONS_TOOLTIP_PRODUCTS_SYNCING_DESCRIPTION = 'react.data_connections.tooltip.products_syncing_description',
  DATA_CONNECTIONS_TOOLTIP_ADS_CONNECTED_TITLE = 'react.data_connections.tooltip.ads_connected_title',
  DATA_CONNECTIONS_TOOLTIP_ADS_CONNECTED_DESCRIPTION = 'react.data_connections.tooltip.ads_connected_description',
  DATA_CONNECTIONS_TOOLTIP_DATA_CONNECTIONS_CONNECTED_TITLE = 'react.data_connections.tooltip.data_connections_connected_title',
  DATA_CONNECTIONS_TOOLTIP_DATA_CONNECTIONS_CONNECTED_DESCRIPTION = 'react.data_connections.tooltip.data_connections_connected_description',
  DATA_CONNECTIONS_TOOLTIP_SALES_CHANNEL_LINK = 'react.data_connections.tooltip.sales_channel_link',
  DATA_CONNECTIONS_TOOLTIP_PRODUCTS_CONNECTED_DESCRIPTION = 'react.data_connections.tooltip.products_connected_description',
  DATA_CONNECTIONS_TOOLTIP_PRODUCTS_ONLY_SYNCING_DESCRIPTION = 'react.data_connections.tooltip.products_only_syncing_description',
  DATA_CONNECTIONS_TOOLTIP_ADS_SYNCING_PRODUCTS_CONNECTED_DESCRIPTION = 'react.data_connections.tooltip.ads_syncing_product_connect_description',

  CURRENT_GMS_HEADER = 'react.current_gms.header',
  CURRENT_GMS_PILL_SYNCING_DATA = 'react.current_gms.pill.syncing_data',
  CURRENT_GMS_BUTTON_ADD_PRODUCTS_DATA = 'react.current_gms.button.add_products_data',
  CURRENT_GMS_TEXT_GROWTH = 'react.current_gms.text.growth',
  CURRENT_GMS_TOOLTIP_SYNCING_TITLE = 'react.current_gms.tooltip.syncing_title',
  CURRENT_GMS_TOOLTIP_SYNCING_DESCRIPTION = 'react.current_gms.tooltip.syncing_description',
  CURRENT_GMS_TOOLTIP_INITIAL_TITLE = 'react.current_gms.tooltip.initial_title',
  CURRENT_GMS_TOOLTIP_INITIAL_DESCRIPTION = 'react.current_gms.tooltip.initial_description',
  CURRENT_GMS_TOOLTIP_CONNECTED_TITLE = 'react.current_gms.tooltip.connected_title',
  CURRENT_GMS_TOOLTIP_CONNECTED_DESCRIPTION = 'react.current_gms.tooltip.connected_description',
  CURRENT_GMS_TOOLTIP_CONNECTED_LAST_UPDATED = 'react.current_gms.tooltip.connected_last_updated',

  BANNER_CONNECT_SALES_CHANNELS_LINK = 'react.banner.connect_sales_channels.link',
  BANNER_TRIAL_FIRST_DAY_MESSAGE = 'react.banner.trial.first_day.message',
  BANNER_TRIAL_HAPPENING_PASSIVE_MESSAGE = 'react.banner.trial.happening.passive.message',
  BANNER_TRIAL_HAPPENING_ALERT_MESSAGE = 'react.banner.trial.happening.alert.message',
  BANNER_TRIAL_HAPPENING_ALERT_LINK = 'react.banner.trial.happening.alert.link',
  BANNER_TRIAL_HAPPENING_ALERT_EXTRA = 'react.banner.trial.happening.alert.extra',
  BANNER_TRIAL_AUTOMATIC_DOWNGRADE_LINK = 'react.banner.trial.automatic_downgrade.link',
  BANNER_TRIAL_AUTOMATIC_DOWNGRADE_EXTRA = 'react.banner.trial.automatic_downgrade.extra',
  BANNER_AUTOMATION_IS_DISABLED = 'react.banner.ai_plan.automation_disabled',
  BANNER_AUTOMATION_IS_DISABLED_FOR_FEW_MERCHANTS = 'react.banner.ai_plan.automation_disabled_perticular_merchants',
  BANNER_AUTOMATION_IS_DISABLED_FOR_ALL_MERCHANTS = 'react.banner.ai_plan.automation_disabled_for_all_merchants',
  BANNER_AUTOMATION_ENABLE_AI_PLAN = 'react.banner.ai_plan.enable_ai_plan',
  BANNER_TRIAL_DELINQUENT_LINK = 'react.banner.trial.delinquent.link',
  BANNER_TRIAL_DELINQUENT_MESSAGE = 'react.banner.trial.happening.delinquent.message',
  BANNER_TRIAL_ACCOUNT_LOCKOUT_MESSAGE = 'react.banner.trial.happening.account_lockout.message',
  BANNER_TRIAL_ACCOUNT_LOCKOUT_LINK = 'react.banner.trial.account_lockout.link',
  BANNER_TRIAL_TRIAL_OVER_MESSAGE = 'react.banner.trial.trial_over.message',
  BANNER_TRIAL_TRIAL_OVER_LINK = 'react.banner.trial.trial_over.link',

  BANNER_TRIAL_DOWNGRADED_MESSAGE = 'react.banner.trial.downgraded.message',
  BANNER_TRIAL_DOWNGRADED_LINK = 'react.banner.trial.downgraded.link',

  BANNER_EMPTY_STATE_NO_DATA_MESSAGE = 'react.banner.empty_state.no_data.message',
  BANNER_EMPTY_STATE_NO_DATA_LINK = 'react.banner.empty_state.no_data.link',
  BANNER_EMPTY_STATE_AMAZON_ADS_ONLY_SYNCING_MESSAGE = 'react.banner.empty_state.amazon_ads_only_syncing.message',
  BANNER_EMPTY_STATE_AMAZON_ADS_ONLY_SYNCING_LINK = 'react.banner.empty_state.amazon_ads_only_syncing.link',
  BANNER_EMPTY_STATE_WALMART_ADS_ONLY_SYNCING_MESSAGE = 'react.banner.empty_state.walmart_ads_only_syncing.message',
  BANNER_EMPTY_STATE_WALMART_ADS_ONLY_SYNCING_LINK = 'react.banner.empty_state.walmart_ads_only_syncing.link',
  BANNER_EMPTY_STATE_AMAZON_ADS_ONLY_CONNECTED_MESSAGE = 'react.banner.empty_state.amazon_ads_only_connected.message',
  BANNER_EMPTY_STATE_AMAZON_ADS_ONLY_CONNECTED_LINK = 'react.banner.empty_state.amazon_ads_only_connected.link',
  BANNER_EMPTY_STATE_WALMART_ADS_ONLY_CONNECTED_MESSAGE = 'react.banner.empty_state.walmart_ads_only_connected.message',
  BANNER_EMPTY_STATE_WALMART_ADS_ONLY_CONNECTED_LINK = 'react.banner.empty_state.walmart_ads_only_connected.link',

  BANNER_EMPTY_STATE_AMAZON_DATA_SYNCING_MESSAGE = 'react.banner.empty_state.amazon_data_syncing.message',
  BANNER_EMPTY_STATE_GENERIC_SYNCING_MESSAGE = 'react.banner.empty_state.generic_data_syncing.message',

  ADD_PAYMENT_MODAL_HEADER = 'react.add_payment_modal.header',
  ADD_PAYMENT_MODAL_DESCRIPTION = 'react.add_payment_modal.description',
  ADD_PAYMENT_MODAL_ADD_BUTTON_TEXT = 'react.add_payment_modal.add_button_text',
  ADD_PAYMENT_MODAL_CANCEL_BUTTON_TEXT = 'react.add_payment_modal.cancel_button_text',
  ADD_PAYMENT_TOAST_SUCCESS_HEADLINE = 'react.add_payment.toast.success_headline',
  ADD_PAYMENT_TOAST_SUCCESS_DESCRIPTION = 'react.add_payment.toast.success_description',
  ADD_PAYMENT_MODAL_SUCCESS_HEADER = 'react.add_payment.modal.success_header',
  ADD_PAYMENT_MODAL_SUCCESS_BODY = 'react.add_payment.modal.success_body',
  ADD_PAYMENT_MODAL_SUCCESS_BUTTON_TEXT = 'react.add_payment.modal.success_button_text',

  OUTSTANDING_BALANCE_MODAL_HEADER_PAY_BALANCE = 'react.outstanding_balance_modal.header.pay_balance',
  OUTSTANDING_BALANCE_MODAL_HEADER_SUBTITLE_KEEP_ACCESS = 'react.outstanding_balance_modal.header.subtitle.keep_access',
  OUTSTANDING_BALANCE_MODAL_HEADER_BALANCE_PAID = 'react.outstanding_balance_modal.header.balance_paid',
  OUTSTANDING_BALANCE_MODAL_HEADER_SUBTITLE_THANK_YOU = 'react.outstanding_balance_modal.header.subtitle.thank_you',

  OUTSTANDING_BALANCE_MODAL_TITLE_BALANCE = 'react.outstanding_balance_modal.title.balance',
  OUTSTANDING_BALANCE_MODAL_TITLE_PAYMENT_METHOD = 'react.outstanding_balance_modal.title.payment_method',
  OUTSTANDING_BALANCE_MODAL_PAYMENT_METHOD_DESCRIPTION = 'react.outstanding_balance_modal.payment_method_description',
  OUTSTANDING_BALANCE_MODAL_BUTTON_ADD_NEW_PAYMENT = 'react.outstanding_balance_modal.button.add_new_payment',
  OUTSTANDING_BALANCE_MODAL_DO_THIS_LATER_LINK = 'react.outstanding_balance_modal.do_this_later.link',
  OUTSTANDING_BALANCE_MODAL_CONTACT_SUPPORT_LINK = 'react.outstanding_balance_modal.contact_support.link',
  OUTSTANDING_BALANCE_MODAL_TITLE_ADD_PAYMENT = 'react.outstanding_balance_modal.title.add_payment',
  OUTSTANDING_BALANCE_MODAL_RECEIPT_PAYMENT_DESCRIPTION = 'react.outstanding_balance_modal.receipt_payment_description',
  OUTSTANDING_BALANCE_MODAL_SUBTITLE_ADD_NEW_CREDIT_CARD = 'react.outstanding_balance_modal.subtitle.add_new_credit_card',
  OUTSTANDING_BALANCE_MODAL_BUTTON_PAY_BALANCE = 'react.outstanding_balance_modal.button.pay_balance',

  VALIDATION_GREATER_THAN = 'react.validation.greater_than',
  VALIDATION_LESS_THAN = 'react.validation.less_than',
  VALIDATION_AT_LEAST = 'react.validation.at_least',

  SKU_CATALOG_SEARCH_FOR_CATALOG = 'react.sku_catalog.search.search_for_catalogs',
  SKU_OVERVIEW_DETAILS_SEARCH_PLACEHOLDER = 'react.overview.search.placeholder',
  SKU_AD_PERFORMANCE_DETAILS_SEARCH_PLACEHOLDER = 'react.sku_ad_performance.search.placeholder',
  USER_SEARCH_PLACEHOLDER = 'react.user.search.placeholder',
  ACCOUNT_SWITCHER_SEARCH_PLACEHOLDER = 'react.pages.account_switcher.search.placeholder',
  CREATE_NEW_CLIENT_ACCOUNT = 'react.create_new_client_account',
  CREATE_NEW_CLIENT_ACCOUNT_SUCCESS = 'react.create_new_client_account_success',
  NEW_ACCOUNT_RELATIONSHIP_NAME_LABEL = 'react.new.account.relationship.name.label',
  NEW_ACCOUNT_RELATIONSHIP_CREATED = 'react.new.account.relationship.created',
  SKU_CATALOG_TABLE_COLUMN_AD_SALES = 'react.sku_catalog_table_column.ad_sales',
  SKU_CATALOG_TABLE_COLUMN_AD_SPEND = 'react.sku_catalog_table_column.ad_spend',
  SKU_CATALOG_TABLE_COLUMN_ACOS = 'react.sku_catalog_table_column.acos',
  SKU_CATALOG_TABLE_COLUMN_CONVERSION_RATE = 'react.sku_catalog_table_column.conversion_rate',
  SKU_CATALOG_TABLE_COLUMN_CAMPAIGN_COUNT = 'react.sku_catalog_table_column.campaign_count',
  SKU_CATALOG_TABLE_COLUMN_ACTIVE_CAMPAIGN_COUNT = 'react.sku_catalog_table_column.active_campaign_count',
  SKU_CATALOG_TABLE_COLUMN_AD_GROUP_COUNT = 'react.sku_catalog_table_column.ad_group_count',
  SKU_CATALOG_TABLE_COLUMN_ROAS = 'react.sku_catalog_table_column.roas',
  SKU_CATALOG_TABLE_COLUMN_AD_CONVERSIONS = 'react.sku_catalog_table_column.ad_conversions',
  SKU_CATALOG_TABLE_COLUMN_AD_UNITS_SOLD = 'react.sku_catalog_table_column.ad_units_sold',
  SKU_CATALOG_TABLE_COLUMN_IMPRESSIONS = 'react.sku_catalog_table_column.impressions',
  SKU_CATALOG_TABLE_COLUMN_CLICKS = 'react.sku_catalog_table_column.clicks',
  SKU_CATALOG_TABLE_COLUMN_COST_PER_CLICK = 'react.sku_catalog_table_column.cost_per_click',
  SKU_CATALOG_TABLE_COLUMN_CLICK_THROUGH_RATE = 'react.sku_catalog_table_column.click_through_rate',
  SKU_CATALOG_TABLE_COLUMN_ADS = 'react.sku_catalog_table_column.ads',
  SKU_CATALOG_TABLE_COLUMN_SKU = 'react.sku_catalog.sku_catalog_table_column.sku',
  SKU_CATALOG_TABLE_COLUMN_SKUS = 'react.sku_catalog.sku_catalog_table_column.skus',
  SKU_CATALOG_TABLE_COLUMN_GMROI = 'react.sku_catalog.sku_catalog_table_column.gmroi',
  SKU_CATALOG_TABLE_COLUMN_DAYS_IN_INVENTORY = 'react.sku_catalog.sku_catalog_table_column.days_in_inventory',
  SKU_CATALOG_TABLE_COLUMN_PRODUCT = 'react.sku_catalog.sku_catalog_table_column.product',
  SKU_CATALOG_TABLE_COLUMN_SKU_TOTAL_TEXT = 'react.sku_catalog.sku_catalog_table_column.sku_total_text',
  SKU_CATALOG_TABLE_COLUMN_ADVERTISED = 'react.sku_catalog.sku_catalog_table_column.advertised',
  SKU_DETAILS_V2_TABLE_COLUMN_SKU_TOTAL_TEXT = 'react.sku_catalog.sku_details_v2_table_column.sku_total_text',
  SKU_CATALOG_TABLE_COLUMN_NAME = 'react.sku_catalog.sku_catalog_table_column.name',
  SKU_CATALOG_TABLE_COLUMN_TEIKA_GROUP_NAME = 'react.sku_catalog.sku_catalog_table_column.teika_group_name',
  SKU_CATALOG_TABLE_COLUMN_PRODUCT_ID = 'react.sku_catalog.sku_catalog_table_column.product_id',
  SKU_CATALOG_SKU_DETAILS_V2_BREADCRUMB_ALL_PRODUCTS = 'react.sku_catalog.sku_details_v2.breadcrumb.all_products',
  REACT_COMPONENTS_PAGINATED_TABLE_BUTTON_EXPORT = 'react.components.paginated_table.button.export',
  SKU_CATALOG_SKU_DETAILS_V2_PROFITABILITY_EST_GROSS_PROFIT = 'react.sku_catalog.sku_details_v2.profitability_table.est_gross_profit',
  SKU_CATALOG_SKU_DETAILS_V2_PROFITABILITY_EST_GROSS_MARGIN = 'react.sku_catalog.sku_details_v2.profitability_table.est_gross_margin',
  SKU_CATALOG_SKU_DETAILS_V2_PROFITABILITY_EST_PRE_AD_GROSS_MARGIN = 'react.sku_catalog.sku_details_v2.profitability_table.est_pre_ad_gross_margin',
  SKU_CATALOG_TABLE_COLUMN_ADVERTISED_FILTER_ADVERTISED = 'react.sku_catalog.sku_catalog_table_column.advertised.filter.advertised',
  SKU_CATALOG_TABLE_COLUMN_ADVERTISED_FILTER_NOT_ADVERTISED = 'react.sku_catalog.sku_catalog_table_column.advertised.filter.not_advertised',
  SKU_CATALOG_TABLE_COLUMN_ADVERTISED_FILTER_AD_INELIGIBLE = 'react.sku_catalog.sku_catalog_table_column.advertised.filter.ad_ineligible',
  SKU_CATALOG_TABLE_COLUMN_ADVERTISED_TEXT = 'react.sku_catalog.sku_catalog_table_column.advertised_text',
  SKU_CATALOG_TABLE_COLUMN_ADVERTISED_TEXT_COUNT = 'react.sku_catalog.sku_catalog_table_column.advertised_text_count',
  SKU_CATALOG_TABLE_COLUMN_ADVERTISED_INELIGIBLE = 'react.sku_catalog.sku_catalog_table_column.advertised_ineligible',
  SKU_CATALOG_TABLE_COLUMN_ADVERTISED_INELIGIBLE_TOOLTIP = 'react.sku_catalog.sku_catalog_table_column.advertised_ineligible_tooltip',
  SKU_CATALOG_TABLE_COLUMN_ADVERTISED_OUT_OF_INVENTORY_TOOLTIP = 'react.sku_catalog.sku_catalog_table_column.advertised_out_of_inventory_tooltip',

  SKU_CATALOG_PRODUCT_SETTINGS_COGS_TABLE_LAST_UPDATED_COLUMN_HEADER = 'react.sku_catalog.product_settings.cogs_table.last_updated',
  SKU_CATALOG_PRODUCT_SETTINGS_COGS_TABLE_COGS_COLUMN_HEADER = 'react.sku_catalog.product_settings.cogs_table.cogs',

  SKU_DETAILS_SIDEBAR_FAILED_MESSAGE_DESCRIPTION = 'react.sku_details.sidebar.error.description',

  SKU_OVERVIEW_TABLE_COLUMN_AD_SALES = 'react.sku_overview_table_column.ad_sales',
  SKU_OVERVIEW_TABLE_COLUMN_AD_SPEND = 'react.sku_overview_table_column.ad_spend',
  SKU_OVERVIEW_TABLE_COLUMN_ACOS = 'react.sku_overview_table_column.acos',
  SKU_OVERVIEW_TABLE_COLUMN_CONVERSION_RATE = 'react.sku_overview_table_column.conversion_rate',
  SKU_OVERVIEW_TABLE_COLUMN_CAMPAIGN_COUNT = 'react.sku_overview_table_column.campaign_count',
  SKU_OVERVIEW_TABLE_COLUMN_AD_GROUP_COUNT = 'react.sku_overview_table_column.ad_group_count',
  SKU_OVERVIEW_TABLE_COLUMN_ROAS = 'react.sku_overview_table_column.roas',
  SKU_OVERVIEW_TABLE_COLUMN_AD_CONVERSIONS = 'react.sku_overview_table_column.ad_conversions',
  SKU_OVERVIEW_TABLE_COLUMN_AD_UNITS_SOLD = 'react.sku_overview_table_column.ad_units_sold',
  SKU_OVERVIEW_TABLE_COLUMN_IMPRESSIONS = 'react.sku_overview_table_column.impressions',
  SKU_OVERVIEW_TABLE_COLUMN_CLICKS = 'react.sku_overview_table_column.clicks',
  SKU_OVERVIEW_TABLE_COLUMN_COST_PER_CLICK = 'react.sku_overview_table_column.cost_per_click',
  SKU_OVERVIEW_TABLE_COLUMN_CLICK_THROUGH_RATE = 'react.sku_overview_table_column.click_through_rate',
  REACT_SKU_DETAILS_SLIDERGROUP_PARENT = 'react.sku_details.slidergroup.parent',
  REACT_SKU_DETAILS_SLIDERGROUP_SKUS = 'react.sku_details.slidergroup.skus',
  REACT_SKU_DETAILS_SLIDERGROUP_PROFITABILITY = 'react.sku_details.tabs.profitability',
  REACT_SKU_DETAILS_SLIDERGROUP_PRODUCT_SETTINGS = 'react.sku_details.tabs.product_settings',
  REACT_SKU_DETAILS_SLIDERGROUP_EVENTS = 'react.sku_details.tabs.events',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_BRAND_COMPETITOR = 'react.sku_details.product_settings.tab.brand_competitor',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_COGS = 'react.sku_details.product_settings.tab.cogs',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENT_ADD_EVENT = 'react.sku_details.tabs.events.add_event',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENT_TYPES = 'react.sku_details.tabs.events.event_types',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENT_TYPES_SELECT_ALL = 'react.sku_details.tabs.events.event_types_select_all_text',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENT_LISTING_OPTIMIZATION = 'react.sku_details.tabs.events.listing_optimization',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENT_NO_EVENT_HEADER = 'react.sku_details.tabs.events.no_events.header',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENT_NO_EVENT_DESCRIPTION = 'react.sku_details.tabs.events.no_events.description',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENT_NO_FILTERS_HEADER = 'react.sku_details.tabs.events.no_filters.header',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENT_NO_FILTERS_DESCRIPTION = 'react.sku_details.tabs.events.no_filters.description',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_PROFITABILITY_SLIDE_OUT_HEADER = 'react.sku_details.tabs.profitability.slideout.header',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_PROFITABILITY_SLIDE_OUT_SUB_HEADER = 'react.sku_details.tabs.profitability.slideout.sub_header',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_PROFITABILITY_SLIDE_OUT_VIEW_IN_EVENT_LOG = 'react.sku_details.tabs.profitability.slideout.per_date_link.text',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_PROFITABILITY_SLIDE_OUT_TOTAL_ENTRIES = 'react.sku_details.tabs.profitability.slideout.total_entries.text',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_PROFITABILITY_SLIDE_OUT_LOADING_DESCRIPTION = 'react.sku_details.tabs.profitability.slideout.loading.description',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENTS_EACH_EVENT_CATEGORY_LISTING = 'react.sku_details.tabs.events.each_event.category.listing',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENTS_EACH_EVENT_CATEGORY_TIME_FORMAT = 'react.sku_details.tabs.events.each_event.time_format',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENTS_MY_EVENTS_TOGGLE = 'react.sku_details.tabs.events.my_events_toggle',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENTS_API_EVENTS_TOGGLE = 'react.sku_details.tabs.events.api_events_toggle',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENTS_API_EVENT_TOOLTIP = 'react.sku_details.tabs.events.api_events.tooltip',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENTS_EACH_EVENT_NOTES_HEADER = 'react.sku_details.tabs.events.each_event.note.header',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENTS_EACH_EVENT_EDIT_TOOLTIP = 'react.sku_details.tabs.events.each_event.edit_tooltip',
  REACT_SKU_DETAILS_EVENTS_FILTER_CONTENT = 'react.sku_details.events_filter.content',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENTS_EXPORT_FILENAME = 'react.sku_details.tabs.events.export_filename',
  REACT_SKU_DETAILS_PRODUCT_SETTINGS_TAB_EVENTS_EXPORT_SUCCESS_TOAST_BODY = 'react.sku_details.tabs.events.export.success_toast.body',

  TABLE_LOADING_TEXT = 'react.component.table.loading_text',
  TABLE_NO_DATA_TITLE = 'react.component.table.no_data.title',
  TABLE_NO_DATA_DESCIPTION = 'react.component.table.no_data.description',
  TABLE_API_ERROR_TITLE = 'react.component.table.api_error.title',
  TABLE_API_ERROR_DESCRIPTION = 'react.component.table.api_error.description',
  TABLE_API_ERROR_TRY_AGAIN = 'react.component.table.api_error.try_again',
  TABLE_NO_DATA_WITH_FILTER_TITLE = 'react.component.table.no_data_with_filter.title',
  TABLE_NO_DATA_WITH_FILTER_DESCRIPTION = 'react.component.table.no_data_with_filter.description',
  TABLE_NO_DATA_WITH_FILTER_DESCRIPTION_TRY_NEW = 'react.component.table.no_data_with_filter.description.try_new',

  SKU_OVERVIEW_TABLE_COLUMN_TOTAL_SALES = 'react.sku_overview.table_column.total_sales',
  SKU_OVERVIEW_TABLE_COLUMN_ESTIMATED_GROSS_PROFIT = 'react.sku_overview.table_column.est_gross_profit',
  SKU_OVERVIEW_TABLE_COLUMN_UNITS_SOLD = 'react.sku_overview.table_column.units_sold',
  SKU_OVERVIEW_TABLE_COLUMN_ESTIMATED_GROSS_MARGIN = 'react.sku_overview.table_column.est_gross_margin',
  SKU_OVERVIEW_TABLE_COLUMN_ESTIMATED_PRE_AD_GROSS_MARGIN = 'react.sku_overview.table_column.est_pre_ad_gross_margin',
  SKU_OVERVIEW_TABLE_COLUMN_COGS = 'react.sku_overview.table_column.cogs',
  SKU_OVERVIEW_TABLE_COLUMN_FEES = 'react.sku_overview.table_column.fees',
  SKU_OVERVIEW_TABLE_COLUMN_INVENTORY_VALUE = 'react.sku_overview.table_column.inventory_value',
  SKU_OVERVIEW_TABLE_COLUMN_CURRENT_INVENTORY_QUANTITY = 'react.sku_overview.table_column.current_inventory_quantity',
  SKU_OVERVIEW_TABLE_COLUMN_PRODUCT_GROUP_HEADER = 'react.sku_overview.table_column.product_group_header',
  SKU_OVERVIEW_TABLE_COLUMN_TACOS = 'react.sku_overview.table_column.tacos',
  SKU_OVERVIEW_TABLE_COLUMN_AVERAGE_ORDER_PRICE = 'react.sku_overview.table_column.average_order_price',
  SKU_OVERVIEW_TABLE_COLUMN_MERCHANT = 'react.sku_overview.table_column.merchant',
  SKU_OVERVIEW_TABLE_COLUMN_CHANNEL = 'react.sku_overview.table_column.channel',

  CHANNELS_MERCHANTS = 'react.sku_catalog.channels_merchants',
  CHANNELS_MERCHANT_ID = 'react.sku_catalog.channels_merchants_id',
  CHANNELS_MERCHANT_ASIN = 'react.sku_catalog.channels_merchants_asin',
  CHANNELS_MERCHANT_CATEGORIES = 'react.sku_catalog.channels_merchants_categories',
  SKU_DETAILS_SKU = 'react.sku_catalog.product_sku',
  UNKNOWN_CHANNEL = 'react.sku_catalog.unknown_channel',

  SKU_AD_PERFORMANCE_AD_GROUP_STATUS_FILTER_OPTION_ARCHIVED = 'react.sku_catalog.sku_ad_performance.ad_group_status.filter_option.archived',
  SKU_AD_PERFORMANCE_AD_GROUP_STATUS_FILTER_OPTION_DISABLED = 'react.sku_catalog.sku_ad_performance.ad_group_status.filter_option.disabled',
  SKU_AD_PERFORMANCE_AD_GROUP_STATUS_FILTER_OPTION_ENABLED = 'react.sku_catalog.sku_ad_performance.ad_group_status.filter_option.enabled',
  SKU_AD_PERFORMANCE_AD_GROUP_STATUS_FILTER_OPTION_PAUSED = 'react.sku_catalog.sku_ad_performance.ad_group_status.filter_option.paused',

  PRODUCT_SKU_NO_CONNECTION_SALES_CHANNEL_HEADER_PILL_LABEL = 'react.product.sku.no_connection_page.connect_sales_channel_header_pill_label',
  PRODUCT_SKU_NO_CONNECTION_SALES_CHANNEL_HEADER = 'react.product.sku.no_connection_page.connect_sales_channel_header',
  PRODUCT_SKU_NO_CONNECTION_SALES_CHANNEL_SUBHEADER = 'react.product.sku.no_connection_page.connect_sales_channel_subheader',
  PRODUCT_SKU_NO_CONNECTION_SALES_CHANNEL_LABEL = 'react.product.sku.no_connection_page.connect_sales_channel_label',
  PRODUCT_SKU_NO_CONNECTION_SALES_CHANNEL_SYNC_TEXT = 'react.product.sku.no_connection_page.connect_sales_channel_sync_text',
  PRODUCT_SKU_NO_CONNECTION_VIEW_CATALOG_HEADER = 'react.product.sku.no_connection_page.view_catalog_header',
  PRODUCT_SKU_NO_CONNECTION_VIEW_CATALOG_SUBTEXT = 'react.product.sku.no_connection_page.view_catalog_subtext',
  PRODUCT_SKU_NO_CONNECTION_ANALYZE_PERFORMANCE_HEADER = 'react.product.sku.no_connection_page.analyze_performance_header',
  PRODUCT_SKU_NO_CONNECTION_ANALYZE_PERFORMANCE_SUBTEXT = 'react.product.sku.no_connection_page.analyze_performance_subtext',
  PRODUCT_SKU_NO_CONNECTION_TRACK_PROFITABILITY_HEADER = 'react.product.sku.no_connection_page.track_profitability_header',
  PRODUCT_SKU_NO_CONNECTION_TRACK_PROFITABILITY_SUBTEXT = 'react.product.sku.no_connection_page.track_profitability_subtext',

  BILLING_DETAIL_AI_POWERED_PLAN_TITLE = 'react.billing.plan_details.connect_plan.ai_powered_plan_title',
  BILLING_DETAIL_AI_POWERED_PLAN_SUB_TITLE = 'react.billing.plan_details.connect_plan.ai_powered_plan_sub_title',
  BILLING_DETAIL_BASIC_PLAN_TITLE = 'react.billing.plan_details.connect_plan.basic_plan_title',
  BILLING_DETAIL_BASIC_PLAN_SUB_TITLE = 'react.billing.plan_details.connect_plan.basic_plan_sub_title',
  BILLING_DETAIL_PLAN_UPGRADE = 'react.billing.plan_details.connect_plan.plan_upgrade',
  BILLING_DETAIL_PLAN_DOWNGRADE = 'react.billing.plan_details.connect_plan.plan_downgrade',
  BILLING_DETAIL_PLAN_FREE_KEEP_GROWING = 'react.billing.plan_details.connect_plan.free_keep_growing',

  AD_OPTIMIZER_BUTTON_CONTINUE_OPTIMIZATION = 'react.ad_optimizer.button.continue_optimization',
  AD_OPTIMIZER_HEADER_TITLE_CONTINUE_OPTIMIZATION = 'react.ad_optimizer.header.title.continue_optimization',
  AD_OPTIMIZER_HEADER_SUBTITLE_CONTINUE_OPTIMIZATION = 'react.ad_optimizer.header.subtitle.continue_optimization',
  AD_OPTIMIZER_HEADER_TITLE = 'react.ad_optimizer.header.title',
  AD_OPTIMIZER_HEADER_GO_BACK = 'react.ad_optimizer.header.go_back',
  AD_OPTIMIZER_HEADER_BEGIN_SETUP = 'react.ad_optimizer.header.begin_setup',
  AD_OPTIMIZER_INTRO_BRAIN_SECTION_TITLE = 'react.ad_optimizer.intro.brain_section.title',
  AD_OPTIMIZER_INTRO_BRAIN_SECTION_BODY = 'react.ad_optimizer.intro.brain_section.body',
  AD_OPTIMIZER_INTRO_POWERED_BY_TITLE = 'react.ad_optimizer.intro.powered_by.title',
  AD_OPTIMIZER_INTRO_BODY_CAMPAIGN_OPTIMIZER = 'react.ad_optimizer.intro.body.campaign_alignment',
  AD_OPTIMIZER_INTRO_BODY_AUTOMATED_TARGETING = 'react.ad_optimizer.intro.body.automated_targeting',
  AD_OPTIMIZER_INTRO_BODY_AUTO_BIDDING = 'react.ad_optimizer.intro.body.auto_bidding',

  AD_OPTIMIZER_INTRO_BODY_CAMPAIGN_OPTIMIZER_TITLE = 'react.ad_optimizer.intro.body.campaign_alignment.title',
  AD_OPTIMIZER_INTRO_BODY_AUTOMATED_TARGETING_TITLE = 'react.ad_optimizer.intro.body.automated_targeting.title',
  AD_OPTIMIZER_INTRO_BODY_AUTO_BIDDING_TITLE = 'react.ad_optimizer.intro.body.auto_bidding.title',
  AD_OPTIMIZER_INTRO_BODY_CAMPAIGN_OPTIMIZER_SUB_TITLE = 'react.ad_optimizer.intro.body.campaign_alignment.sub_title',
  AD_OPTIMIZER_INTRO_BODY_AUTOMATED_TARGETING_SUB_TITLE = 'react.ad_optimizer.intro.body.automated_targeting.sub_title',
  AD_OPTIMIZER_INTRO_BODY_AUTO_BIDDING_SUB_TITLE = 'react.ad_optimizer.intro.body.auto_bidding.sub_title',
  AD_OPTIMIZER_INTRO_BODY_HOW_IT_WORKS = 'react.ad_optimizer.intro.body.how_it_works',
  AD_OPTIMIZER_INTRO_BODY_INITIAL_SETUP_TITLE = 'react.ad_optimizer.intro.body.initital_setup.title',
  AD_OPTIMIZER_INTRO_BODY_INITIAL_SETUP_DESCRIPTION = 'react.ad_optimizer.intro.body.initital_setup.description',

  AD_OPTIMIZER_INTRO_BODY_INITIAL_SETUP_STEP_1_TITLE = 'react.ad_optimizer.intro.body.initital_setup.step_1.title',
  AD_OPTIMIZER_INTRO_BODY_INITIAL_SETUP_STEP_1_SUB_TITLE = 'react.ad_optimizer.intro.body.initital_setup.step_1.sub_title',
  AD_OPTIMIZER_INTRO_BODY_INITIAL_SETUP_STEP_2_TITLE = 'react.ad_optimizer.intro.body.initital_setup.step_2.title',
  AD_OPTIMIZER_INTRO_BODY_INITIAL_SETUP_STEP_2_SUB_TITLE = 'react.ad_optimizer.intro.body.initital_setup.step_2.sub_title',
  AD_OPTIMIZER_INTRO_BODY_INITIAL_SETUP_STEP_3_TITLE = 'react.ad_optimizer.intro.body.initital_setup.step_3.title',
  AD_OPTIMIZER_INTRO_BODY_INITIAL_SETUP_STEP_3_SUB_TITLE = 'react.ad_optimizer.intro.body.initital_setup.step_3.sub_title',
  AD_OPTIMIZER_INTRO_BODY_INITIAL_SETUP_STEP_4_TITLE = 'react.ad_optimizer.intro.body.initital_setup.step_4.title',
  AD_OPTIMIZER_INTRO_BODY_INITIAL_SETUP_STEP_4_SUB_TITLE = 'react.ad_optimizer.intro.body.initital_setup.step_4.sub_title',

  AD_OPTIMIZER_INTRO_BODY_ONGOING_TITLE = 'react.ad_optimizer.intro.body.ongoing.title',
  AD_OPTIMIZER_INTRO_BODY_ONGOING_BODY = 'react.ad_optimizer.intro.body.ongoing.body',
  AD_OPTIMIZER_INTRO_BODY_FLYWHEEL_AI_AVAILABILITY_TABLE_TITLE = 'react.ad_optimizer.intro.body.flywheel_ai_availability.table.title',
  AD_OPTIMIZER_INTRO_BODY_FLYWHEEL_AI_AVAILABILITY_TABLE_SUB_TITLE = 'react.ad_optimizer.intro.body.flywheel_ai_availability.table.sub_title',
  AD_OPTIMIZER_INTRO_BODY_FLYWHEEL_AI_AVAILABILITY_TABLE_SALES_CHANNEL_AD_TYPES = 'react.ad_optimizer.intro.body.flywheel_ai_availability.table.sales_channel.ad_types',
  AD_OPTIMIZER_INTRO_BODY_COMMON_QUESTIONS_TITLE = 'react.ad_optimizer.intro.body.common_questions.title',
  AD_OPTIMIZER_INTRO_BODY_COMMON_QUESTIONS_1_QUESTION = 'react.ad_optimizer.intro.body.common_questions.1.question',
  AD_OPTIMIZER_INTRO_BODY_COMMON_QUESTIONS_1_ANSWER = 'react.ad_optimizer.intro.body.common_questions.1.answer',
  AD_OPTIMIZER_INTRO_BODY_COMMON_QUESTIONS_2_QUESTION = 'react.ad_optimizer.intro.body.common_questions.2.question',
  AD_OPTIMIZER_INTRO_BODY_COMMON_QUESTIONS_2_ANSWER = 'react.ad_optimizer.intro.body.common_questions.2.answer',
  AD_OPTIMIZER_INTRO_BODY_COMMON_QUESTIONS_3_QUESTION = 'react.ad_optimizer.intro.body.common_questions.3.question',
  AD_OPTIMIZER_INTRO_BODY_COMMON_QUESTIONS_3_ANSWER = 'react.ad_optimizer.intro.body.common_questions.3.answer',
  AD_OPTIMIZER_INTRO_BODY_COMMON_QUESTIONS_4_QUESTION = 'react.ad_optimizer.intro.body.common_questions.4.question',
  AD_OPTIMIZER_INTRO_BODY_COMMON_QUESTIONS_4_ANSWER = 'react.ad_optimizer.intro.body.common_questions.4.answer',
  AD_OPTIMIZER_SIDE_BAR_EXIT_AD_OPTIMIZER = 'react.ad_optimizer.side_bar.exit_ad_optimizer',
  AD_OPTIMIZER_EXIT_AD_OPTIMIZER_POP_UP_TITlE = 'react.ad_optimizer.exit_ad_optimizer.pop_up.title',
  AD_OPTIMIZER_EXIT_AD_OPTIMIZER_POP_UP_BODY = 'react.ad_optimizer.exit_ad_optimizer.pop_up.body',
  AD_OPTIMIZER_EXIT_AD_OPTIMIZER_POP_UP_PRIMARY_BUTTON = 'react.ad_optimizer.exit_ad_optimizer.pop_up.primary.button',
  AD_OPTIMIZER_SIDE_BAR_SETUP = 'react.ad_optimizer.side_bar.setup',
  AD_OPTIMIZER_SIDE_BAR_STATUS = 'react.ad_optimizer.side_bar.status',
  AD_OPTIMIZER_WIZARD_HEADER_BUTTON_BACK = 'react.ad_optimizer.wizard_header.button.back',
  AD_OPTIMIZER_WIZARD_HEADER_BUTTON_BEGIN = 'react.ad_optimizer.wizard_header.button.begin',
  AD_OPTIMIZER_WIZARD_HEADER_BUTTON_END = 'react.ad_optimizer.wizard_header.button.end',
  AD_OPTIMIZER_WIZARD_HEADER_BUTTON_NEXT = 'react.ad_optimizer.wizard_header.button.next',
  AD_OPTIMIZER_WIZARD_HEADER_BUTTON_SUBMIT = 'react.ad_optimizer.wizard_header.button.submit',
  AD_OPTIMIZER_WIZARD_HEADER_BUTTON_CONTINUE = 'react.ad_optimizer.wizard_header.button.continue',
  AD_OPTIMIZER_WIZARD_HEADER_OPTIMIZATION_SETTINGS = 'react.ad_optimizer.wizard_header.optimization_settings',
  AD_OPTIMIZER_WIZARD_HEADER_PREVIEW_CHANGES = 'react.ad_optimizer.wizard_header.preview_changes',
  AD_OPTIMIZER_WIZARD_INTRO_HEADING = 'react.ad_optimizer.wizard_intro.heading',
  AD_OPTIMIZER_WIZARD_INTRO_SUB_HEADING = 'react.ad_optimizer.wizard_intro.sub_heading',
  AD_OPTIMIZER_WIZARD_INTRO_AD_TYPES_HEADER = 'react.ad_optimizer.wizard_intro.ad_types_header',
  AD_OPTIMIZER_WIZARD_INTRO_AD_TYPES_CONTENT = 'react.ad_optimizer.wizard_intro.ad_types_content',
  AD_OPTIMIZER_WIZARD_INTRO_PRODUCTS_HEADER = 'react.ad_optimizer.wizard_intro.products_header',
  AD_OPTIMIZER_WIZARD_INTRO_PRODUCTS_CONTENT = 'react.ad_optimizer.wizard_intro.products_content',
  AD_OPTIMIZER_WIZARD_INTRO_PRODUCTS_SUB_CONTENT = 'react.ad_optimizer.wizard_intro.products_sub_content',
  AD_OPTIMIZER_WIZARD_INTRO_PREVIEW_HEADER = 'react.ad_optimizer.wizard_intro.preview_header',
  AD_OPTIMIZER_WIZARD_INTRO_PREVIEW_LOADING = 'react.ad_optimizer.wizard_intro.preview_loading',
  AD_OPTIMIZER_WIZARD_INTRO_PREVIEW_CONTENT = 'react.ad_optimizer.wizard_intro.preview_content',
  AD_OPTIMIZER_WIZARD_INTRO_MANAGE_HEADER = 'react.ad_optimizer.wizard_intro.manage_header',
  AD_OPTIMIZER_WIZARD_INTRO_MANAGE_CONTENT = 'react.ad_optimizer.wizard_intro.manage_content',
  AD_OPTIMIZER_OPTIMIZATION_SETTINGS_NOT_AVIALABLE_MODAL_TITLE = 'react.ad_optimizer.optimization_settings.not_avialable_modal_title',
  AD_OPTIMIZER_OPTIMIZATION_SETTINGS_NOT_AVIALABLE_MODAL_CONTENT = 'react.ad_optimizer.optimization_settings.not_avialable_modal_content',
  AD_OPTIMIZER_OPTIMIZATION_SETTINGS_PRODUCT_NOT_CONNECTED_MODAL_TITLE = 'react.ad_optimizer.optimization_settings.product_not_connected_modal_title',
  AD_OPTIMIZER_OPTIMIZATION_SETTINGS_PRODUCT_NOT_CONNECTED_MODAL_CONTENT = 'react.ad_optimizer.optimization_settings.product_not_connected_modal_content',
  AD_OPTIMIZER_WIZARD_OPTIMIZATION_TITLE = 'react.ad_optimizer.wizard_optimization_settings.title',
  AD_OPTIMIZER_WIZARD_OPTIMIZATION_CONTENT = 'react.ad_optimizer.wizard_optimization_settings.content',
  AD_OPTIMIZER_WIZARD_OPTIMIZATION_BUTTON = 'react.ad_optimizer.wizard_optimization_settings.button',
  AD_OPTIMIZER_WIZARD_OPTIMIZATION_STEP = 'react.ad_optimizer.wizard_optimization_settings.step',
  AD_OPTIMIZER_WIZARD_PREVIEW_TITLE = 'react.ad_optimizer.wizard_preview.title',
  AD_OPTIMIZER_OPTIONS_MODAL_TITLE = 'react.ad_optimizer.options_modal.title',
  AD_OPTIMIZER_OPTIONS_MODAL_RECOMMENDED = 'react.ad_optimizer.options_modal.recommended',
  AD_OPTIMIZER_OPTIONS_MODAL_FULL_OPTIMIZATION_TITLE = 'react.ad_optimizer.options_modal.full_optimization.title',
  AD_OPTIMIZER_OPTIONS_MODAL_FULL_OPTIMIZATION_CONTENT = 'react.ad_optimizer.options_modal.full_optimization.content',
  AD_OPTIMIZER_OPTIONS_MODAL_ALIGNMENT_TITLE = 'react.ad_optimizer.options_modal.alignment.title',
  AD_OPTIMIZER_OPTIONS_MODAL_ALIGNMENT_CONTENT = 'react.ad_optimizer.options_modal.alignment.content',
  AD_OPTIMIZER_OPTIONS_MODAL_TARGETING_TITLE = 'react.ad_optimizer.options_modal.targeting.title',
  AD_OPTIMIZER_OPTIONS_MODAL_TARGETING_CONTENT = 'react.ad_optimizer.options_modal.targeting.content',
  AD_OPTIMIZER_OPTIONS_MODAL_BIDDING_TITLE = 'react.ad_optimizer.options_modal.bidding.title',
  AD_OPTIMIZER_OPTIONS_MODAL_BIDDING_CONTENT = 'react.ad_optimizer.options_modal.bidding.content',
  AD_OPTIMIZER_OPTIONS_MODAL_BID_OPTIMIZATION_TITLE = 'react.ad_optimizer.options_modal.bid_optimization.title',
  AD_OPTIMIZER_OPTIONS_MODAL_BID_OPTIMIZATION_CONTENT = 'react.ad_optimizer.options_modal.bid_optimization.content',
  AD_OPTIMIZER_OPTIONS_MODAL_NO_OPTIMIZATION_TITLE = 'react.ad_optimizer.options_modal.no_optimization.title',
  AD_OPTIMIZER_OPTIONS_MODAL_NO_OPTIMIZATION_CONTENT = 'react.ad_optimizer.options_modal.no_optimization.content',
  AD_OPTIMIZER_OPTIONS_MODAL_PRICING_CONTEXT_TITLE = 'react.ad_optimizer.options_modal.pricing_context.title',
  AD_OPTIMIZER_OPTIONS_MODAL_PRICING_CONTEXT_CONTENT = 'react.ad_optimizer.options_modal.pricing_context.content',
  AD_OPTIMIZER_OPTIONS_MODAL_PRICING_CONTEXT_HOW_IT_WORKS = 'react.ad_optimizer.options_modal.pricing_context.how_it_works',
  AD_OPTIMIZER_OPTIONS_MODAL_PRICING_CONTEXT_HOW_IT_WORKS_BODY = 'react.ad_optimizer.options_modal.pricing_context.how_it_works_body',
  AD_OPTIMIZER_OPTIONS_MODAL_PRICING_CONTEXT_LEARN_MORE = 'react.ad_optimizer.options_modal.pricing_context.learn_more',
  AD_OPTIMIZER_OPTIONS_MODAL_NO_OPTIMIZATION_BASIC = 'react.ad_optimizer.options_modal.no_optimization.basic',
  AD_OPTIMIZER_WIZARD_PREVIEW_FULL_OPTIMIZATION_BODY = 'react.ad_optimizer.wizard_preview.full_optimization.body',
  AD_OPTIMIZER_TYPE_AD = 'react.ad_optimizer.type.ad',
  AD_OPTIMIZER_TYPE_PRODUCT = 'react.ad_optimizer.type.product',
  AD_OPTIMIZER_WIZARD_PREVIEW_BID_ONLY_BODY = 'react.ad_optimizer.wizard_preview.bid_only.body',
  AD_OPTIMIZER_WIZARD_PREVIEW_AI_UPGRADE_BUTTON_BASIC = 'react.ad_optimizer.wizard_preview.ai_upgrade.button.basic',
  AD_OPTIMIZER_WIZARD_PREVIEW_AI_UPGRADE_BUTTON_AI_POWERED = 'react.ad_optimizer.wizard_preview.ai_upgrade.button.ai_powered',
  AD_OPTIMIZER_WIZARD_PREVIEW_AI_UPGRADE_TEXT = 'react.ad_optimizer.wizard_preview.ai_upgrade.text',
  AD_OPTIMIZER_WIZARD_PREVIEW_AI_UPGRADE_FREE_TRIAL_INFO = 'react.ad_optimizer.wizard_preview.ai_upgrade.free_trial.info',
  AD_OPTIMIZER_WIZARD_PREVIEW_AI_UPGRADE_FREE_TRIAL_LEARN_MORE = 'react.ad_optimizer.wizard_preview.ai_upgrade.free_trial.learn_more',
  AD_OPTIMIZER_WIZARD_PREVIEW_SLIDE_OUT_EXPLAINER_HEADER = 'react.ad_optimizer.wizard_preview.slide_out.explainer.header',
  AD_OPTIMIZER_WIZARD_PREVIEW_SLIDE_OUT_EXPLAINER_CREATE_HEADER = 'react.ad_optimizer.wizard_preview.slide_out.explainer.create_header',
  AD_OPTIMIZER_WIZARD_PREVIEW_SLIDE_OUT_EXPLAINER_PAUSE_HEADER = 'react.ad_optimizer.wizard_preview.slide_out.explainer.pause_header',
  AD_OPTIMIZER_WIZARD_PREVIEW_SLIDE_OUT_EXPLAINER_LINK = 'react.ad_optimizer.wizard_preview.slide_out.explainer.link',
  AD_OPTIMIZER_WIZARD_PREVIEW_PAUSE_MESSAGE = 'react.ad_optimizer.wizard_preview_pause_message',
  AD_OPTIMIZER_WIZARD_PREVIEW_KEEP_MESSAGE = 'react.ad_optimizer.wizard_preview_keep_message',
  AD_OPTIMIZER_WIZARD_PREVIEW_CREATE_MESSAGE = 'react.ad_optimizer.wizard_preview_create_message',
  AD_OPTIMIZER_WIZARD_FULL_OPTIMIZATION_PREVIEW_UPDATE_QUESTION = 'react.ad_optimizer.wizard_full_optimization_preview_update_question',
  AD_OPTIMIZER_WIZARD_FULL_OPTIMIZATION_PREVIEW_UPDATE_ANSWER = 'react.ad_optimizer.wizard_full_optimization_preview_update_answer',
  AD_OPTIMIZER_WIZARD_FULL_OPTIMIZATION_PREVIEW_UPDATE_ANSWER1 = 'react.ad_optimizer.wizard_full_optimization_preview_update_answer1',
  AD_OPTIMIZER_WIZARD_FULL_OPTIMIZATION_PREVIEW_UPDATE_ANSWER2 = 'react.ad_optimizer.wizard_full_optimization_preview_update_answer2',
  AD_OPTIMIZER_WIZARD_FULL_OPTIMIZATION_PREVIEW_UPDATE_ANSWER3 = 'react.ad_optimizer.wizard_full_optimization_preview_update_answer3',
  AD_OPTIMIZER_WIZARD_BID_OPTIMIZATION_PREVIEW_MESSAGE_1 = 'react.ad_optimizer.wizard_bid_optimization_preview_message_1',
  AD_OPTIMIZER_WIZARD_BID_OPTIMIZATION_PREVIEW_MESSAGE_2 = 'react.ad_optimizer.wizard_bid_optimization_preview_message_2',
  AD_OPTIMIZER_WIZARD_BID_OPTIMIZATION_PREVIEW_MESSAGE_3 = 'react.ad_optimizer.wizard_bid_optimization_preview_message_3',
  AD_OPTIMIZER_WIZARD_BID_OPTIMIZATION_PREVIEW_MESSAGE_4 = 'react.ad_optimizer.wizard_bid_optimization_preview_message_4',
  AD_OPTIMIZER_WIZARD_BID_OPTIMIZATION_PREVIEW_CAMPAIGNS = 'react.ad_optimizer.wizard_bid_optimization_preview_campaigns',
  AD_OPTIMIZER_WIZARD_BID_OPTIMIZATION_PREVIEW_AD_GROUPS = 'react.ad_optimizer.wizard_bid_optimization_preview_ad_groups',
  AD_OPTIMIZER_WIZARD_BID_OPTIMIZATION_PREVIEW_ACOS_RANGE = 'react.ad_optimizer.wizard_bid_optimization_preview_acos_range',
  AD_OPTIMIZER_WIZARD_SUCCESS_TITLE = 'react.ad_optimizer.wizard_success.title',
  AD_OPTIMIZER_WIZARD_SUCCESS_TITLE_WITH_ICON = 'react.ad_optimizer.wizard_success.title_with_icon',
  AD_OPTIMIZER_WIZARD_SUCCESS_SUB_TITLE = 'react.ad_optimizer.wizard_success.sub_title',
  AD_OPTIMIZER_WIZARD_SUCCESS_HOW_TO_MANAGE_TITLE = 'react.ad_optimizer.wizard_success.how_to_manage.title',
  AD_OPTIMIZER_WIZARD_SUCCESS_HOW_TO_MANAGE_SUB_TITLE = 'react.ad_optimizer.wizard_success.how_to_manage.sub_title',
  AD_OPTIMIZER_WIZARD_SUCCESS_BODY = 'react.ad_optimizer.wizard_success.body',
  AD_OPTIMIZER_WIZARD_SUCCESS_NEXT_STEPS_TITLE = 'react.ad_optimizer.wizard_success.next_steps.title',
  AD_OPTIMIZER_WIZARD_SUCCESS_NEXT_STEPS_SUB_TITLE = 'react.ad_optimizer.wizard_success.next_steps.sub_title',
  AD_OPTIMIZER_WIZARD_SUCCESS_NEXT_STEPS_BODY = 'react.ad_optimizer.wizard_success.next_steps.body',
  AD_OPTIMIZER_WIZARD_SUCCESS_AD_TYPE_SUB_TITLE = 'react.ad_optimizer.wizard_success.ad_type.sub_title',
  AD_OPTIMIZER_WIZARD_SUCCESS_AD_TYPE_HELP_NEXT = 'react.ad_optimizer.wizard_success.ad_type.help_next',
  AD_OPTIMIZER_WIZARD_SUCCESS_AD_TYPE_COMPLETED = 'react.ad_optimizer.wizard_success.ad_type.completed',
  AD_OPTIMIZER_WIZARD_SUCCESS_AD_TYPE_HELP_SUGGEST = 'react.ad_optimizer.wizard_success.ad_type.help_suggest',
  AD_OPTIMIZER_SUGGESTIONS_ACOS_TARGET = 'react.ad_optimizer.suggestions.acos_target',
  AD_OPTIMIZER_SUGGESTIONS_DAILY_BUDGET = 'react.ad_optimizer.suggestions.daily_budget',
  AD_OPTIMIZER_SUGGESTIONS_AD_GROUP = 'react.ad_optimizer.suggestions.ad_group',
  AD_OPTIMIZER_SUGGESTIONS_SKUS = 'react.ad_optimizer.suggestions.skus',
  AD_OPTIMIZER_SUGGESTIONS_ASIN = 'react.ad_optimizer.suggestions.asin',
  AD_OPTIMIZER_SUGGESTIONS_ID = 'react.ad_optimizer.suggestions.id',
  AD_OPTIMIZER_SUGGESTIONS_SKU = 'react.ad_optimizer.suggestions.sku',
  AD_OPTIMIZER_SUGGESTIONS_PARENT_SKU = 'react.ad_optimizer.suggestions.parent_sku',
  AD_OPTIMIZER_SUGGESTIONS_CHILD_SKUS = 'react.ad_optimizer.suggestions.child_skus',
  AD_OPTIMIZER_SUGGESTIONS_PAUSE_IN_CAMPAIGNS = 'react.ad_optimizer.suggestions.pause_in_campaigns',
  AD_OPTIMIZER_SUGGESTIONS_UPDATE_EXPLAINER_HEADER = 'react.ad_optimizer.suggestions.updated_explainer.header',
  AD_OPTIMIZER_SUGGESTIONS_UPDATE_EXPLAINER_DESCRIPTION1 = 'react.ad_optimizer.suggestions.updated_explainer.description1',
  AD_OPTIMIZER_SUGGESTIONS_UPDATE_EXPLAINER_DESCRIPTION2 = 'react.ad_optimizer.suggestions.updated_explainer.description2',
  AD_OPTIMIZER_SUGGESTIONS_UPDATE_EXPLAINER_DESCRIPTION3 = 'react.ad_optimizer.suggestions.updated_explainer.description3',
  AD_OPTIMIZER_SUGGESTIONS_UPDATE_EXPLAINER_DESCRIPTION4 = 'react.ad_optimizer.suggestions.updated_explainer.description4',
  AD_OPTIMIZER_SUGGESTIONS_UPDATE_EXPLAINER_DESCRIPTION = 'react.ad_optimizer.suggestions.updated_explainer.description',
  AD_OPTIMIZER_SUGGESTIONS_UPDATE_EXPLAINER_WHATS_CHANGING = 'react.ad_optimizer.suggestions.updated_explainer.whats_changing',
  AD_OPTIMIZER_SUGGESTIONS_UPDATE_EXPLAINER_ACOS = 'react.ad_optimizer.suggestions.updated_explainer.acos',
  AD_OPTIMIZER_SUGGESTIONS_UPDATE_EXPLAINER_SKUS = 'react.ad_optimizer.suggestions.updated_explainer.skus',
  AD_OPTIMIZER_SUGGESTIONS_PAUSED_EXPLAINER = 'react.ad_optimizer.suggestions.paused_explainer',
  AD_OPTIMIZER_SUGGESTIONS_CREATED_STRUCTURE_HEADER = 'react.ad_optimizer.suggestions.created_structure.header',
  AD_OPTIMIZER_SUGGESTIONS_CREATED_STRUCTURE_DESCRIPTION1 = 'react.ad_optimizer.suggestions.created_structure.description1',
  AD_OPTIMIZER_SUGGESTIONS_CREATED_STRUCTURE_DESCRIPTION2 = 'react.ad_optimizer.suggestions.created_structure.description2',
  AD_OPTIMIZER_SUGGESTIONS_CREATED_STRUCTURE_DESCRIPTION3 = 'react.ad_optimizer.suggestions.created_structure.description3',
  AD_OPTIMIZER_SUGGESTIONS_BID_OPTIMIZATION_TITLE = 'react.ad_optimizer.suggestions.bid_optimization.title',
  AD_OPTIMIZER_AD_GROUP_INVALID_BID_VALUE = 'react.ad_optimizer.ad_group.invalid.bid.value',
  AD_OPTIMIZER_SETTINGS_CUSTOMIZE = 'react.ad_optimizer.settings.customize',

  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_HEADING = 'react.ad_optimizer.flyover.group.heading',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_CREATE_BUTTON = 'react.ad_optimizer.flyover.group.create_button',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_ADD_GROUP_TEXT = 'react.ad_optimizer.flyover.group.add_group_text',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_GET_STARTED_ADD_GROUP_TEXT = 'react.ad_optimizer.flyover.group.get_starting_add_group_text',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_ADD_GROUP_INPUT_PLACEHOLDER = 'react.ad_optimizer.flyover.group.add_group_input_placeholder',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_EDIT_GROUP_INPUT_PLACEHOLDER = 'react.ad_optimizer.flyover.group.edit_group_input_placeholder',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_BUTTON = 'react.ad_optimizer.flyover.group.remove_button',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_NO_GROUP = 'react.ad_optimizer.flyover.group.no_group',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_BULK_EDIT_PENCIL_TOOLTIP = 'react.ad_optimizer.flyover.group.bulk_edit.pencil.tooltip',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_BULK_EDIT_DESCRIPTION = 'react.ad_optimizer.flyover.group.bulk_edit.description',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_CONFIRM_REMOVE_BUTTON = 'react.ad_optimizer.flyover.group.bulk_edit.remove_button',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_MODAL_HEADING = 'react.ad_optimizer.flyover.group.bulk_remove.modal.heading',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_MODAL_DESCRIPTION = 'react.ad_optimizer.flyover.group.bulk_remove.modal.description',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_SUB_HEADING = 'react.ad_optimizer.flyover.group.bulk_remove.sub_heading',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_TOAST_SUCCESS = 'react.ad_optimizer.flyover.group.bulk_remove.toast.success',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_TOAST_FAILED = 'react.ad_optimizer.flyover.group.bulk_remove.toast.failed',
  AD_OPTIMIZER_FLYOVER_EDIT_GROUP_REMOVE_TOAST_PARTIAL_FAILED = 'react.ad_optimizer.flyover.group.bulk_remove.toast.partial_failed',
  AD_OPTIMIZER_FLYOVER_GROUP_ADDED = 'react.ad_optimizer.flyover.group.group_added',
  AD_OPTIMIZER_FLYOVER_GROUP_UPDATED = 'react.ad_optimizer.flyover.group.group_updated',
  AD_OPTIMIZER_FLYOVER_GROUP_ADD_GROUP_SAME_GROUP_EXISTS = 'react.ad_optimizer.flyover.group.add_group.same_group_exists',
  AD_OPTIMIZER_FLYOVER_GROUP_ADD_GROUP_FAILED_MESSAGE = 'react.ad_optimizer.flyover.group.add_group.failed',
  AD_OPTIMIZER_FLYOVER_GROUP_EDIT_GROUP_FAILED_MESSAGE = 'react.ad_optimizer.flyover.group.edit_group.failed',
  AD_OPTIMIZER_TABLE_GROUP_MORE_TEXT = 'react.ad_optimizer.table.group.display.more',
  AD_OPTIMIZER_TABLE_GROUP_SAVE_FAILED = 'react.ad_optimizer.table.group.save.failed',

  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_HEADER = 'react.upgrade.self_managed.ai_powered_automation.header',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_GO_BACK = 'react.upgrade.self_managed.ai_powered_automation.go_back',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_NEXT_PREVIEW = 'react.upgrade.self_managed.ai_powered_automation.next_preview',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_LEARN_MORE = 'react.upgrade.self_managed.ai_powered_automation.learn_more',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ALL = 'react.upgrade.self_managed.ai_powered_automation.upgrade_all',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ALL_CONFIRM = 'react.upgrade.self_managed.ai_powered_automation.upgrade_all_confirm',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ADVERTISED_PRODUCT = 'react.upgrade.self_managed.ai_powered_automation.upgrade_advertised_products',

  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_OPPORTUNITY_CALL_OUT_TITLE = 'react.upgrade.self_managed.ai_powered_automation.opportunity_call_out_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_OPPORTUNITY_CALL_OUT_LINK = 'react.upgrade.self_managed.ai_powered_automation.opportunity_call_out_link',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CALL_OUT_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_call_out_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CALL_OUT_SUB_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_call_out_subtitle',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CALL_OUT_LINK = 'react.upgrade.self_managed.ai_powered_automation.preview_call_out_link',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_ADVERTISED_PRODUCTS_SUB_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_advertised_products_sub_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_CAMPAIGNS_SUB_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_campaigns_sub_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_TARGETS_SUB_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_targets_sub_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PREVIEW_DAILY_AD_BUDGET_SUB_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_daily_ad_budget_sub_title',

  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_MODAL_TITLE = 'react.upgrade.self_managed.ai_powered_automation.preview_modal_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_MODAL_CONTENT = 'react.upgrade.self_managed.ai_powered_automation.preview_modal_content',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_MODAL_ACTION_YES = 'react.upgrade.self_managed.ai_powered_automation.preview_modal_action_yes',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_MODAL_ACTION_NO = 'react.upgrade.self_managed.ai_powered_automation.preview_modal_action_no',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PROCESSING_SUMMARY_DESCRIPTION = 'react.upgrade.self_managed.ai_powered_automation.processing_summary_description',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_ADVERTISED_TAG = 'react.upgrade.self_managed.ai_powered_automation.upgrade_advertised_tag',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_PRODUCTS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_products',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_PAUSED = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ul_paused',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_TITLE = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ul_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_NEW = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ul_new',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_UL_AUTOMATE = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ul_automate',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_BENEFITS_UL_TITLE = 'react.upgrade.self_managed.ai_powered_automation.benefits_ul_title',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_BENEFITS_UL_DAILY = 'react.upgrade.self_managed.ai_powered_automation.benefits_ul_daily',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_BENEFITS_UL_BIDDING = 'react.upgrade.self_managed.ai_powered_automation.benefits_ul_bidding',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AD_STRUCTURE = 'react.upgrade.self_managed.ai_powered_automation.ad_structure',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_PREVIEW_TAG = 'react.upgrade.self_managed.ai_powered_automation.preview_tag',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_HOW_AI_WORKS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_how_ai_works',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AFTER_AI = 'react.upgrade.self_managed.ai_powered_automation.upgrade_after_ai',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_CAMPAIGNS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_campaigns',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_RECS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_recs',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_BID = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_bid',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_SETTINGS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_settings',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_HOW_CAMPAIGNS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_how_campaigns',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_AFTER_CAMPAIGNS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_after_campaigns',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_HOW_RECS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_how_recs',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_AFTER_RECS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_after_recs',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_HOW_BID = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_how_bid',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_AFTER_BID = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_after_bid',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_HOW_SETTINGS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_how_settings',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_AI_WORK_AFTER_SETTINGS = 'react.upgrade.self_managed.ai_powered_automation.upgrade_ai_work_after_settings',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_RESULT_SUB_TITLE_ONE = 'react.upgrade.self_managed.ai_powered_automation.success_result_sub_title_one',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_RESULT_HEADER = 'react.upgrade.self_managed.ai_powered_automation.success_result_header',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_COMPLETE = 'react.upgrade.self_managed.ai_powered_automation.success_upgrade_complete',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_WAITING_SUBTITLE = 'react.upgrade.self_managed.ai_powered_automation.success_waiting_subtitle',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_LEARN_PRODUCT_SETTINGS_HEADER = 'react.upgrade.self_managed.ai_powered_automation.success_learn_product_settings_header',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_LEARN_PRODUCT_SETTINGS_TEXT = 'react.upgrade.self_managed.ai_powered_automation.success_learn_product_settings_text',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_LEARN_PRODUCT_SETTINGS_LINK = 'react.upgrade.self_managed.ai_powered_automation.success_learn_product_settings_link',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_PRODUCT_PERFORMANCE_HEADER = 'react.upgrade.self_managed.ai_powered_automation.success_product_performance_header',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_PRODUCT_PERFORMANCE_TEXT = 'react.upgrade.self_managed.ai_powered_automation.success_product_performance_text',
  UPGRADE_SELF_MANAGED_AI_POWERED_AUTOMATION_UPGRADE_SUCCESS_PRODUCT_PERFORMANCE_LINK = 'react.upgrade.self_managed.ai_powered_automation.success_product_performance_link',

  NAV_BAR_LABEL_AI_POWERED = 'react.components.navbar.ai_powered.header',
  NAV_BAR_LABEL_AI_SETTINGS = 'react.components.navbar.ai_settings.header',
  AI_SETTINGS_MANAGE_CARD_BUTTON = 'react.components.ai_settings.manage_card.button',
  AI_SETTINGS_MANAGE_SUB_TITLE_ONE = 'react.components.ai_settings.manage.header_sub_title_one',
  AI_SETTINGS_MANAGE_SUB_TITLE_TWO = 'react.components.ai_settings.manage.header_sub_title_two',

  AI_PRODUCT_SETTINGS_SEARCH_SEARCH_FOR_CATALOGS = 'react.ai_product_settings.search.search_for_catalogs',
  AI_PRODUCT_SETTINGS_AD_SALES_HEADER = 'react.ai_product_settings.ad_sales.header',
  AI_PRODUCT_SETTINGS_AI_SETTINGS_HEADER = 'react.ai_product_settings.ai_settings.header',
  AI_PRODUCT_SETTINGS_TAGS_HEADER = 'react.ai_product_settings.tags.header',
  AI_PRODUCT_SETTINGS_PRODUCT = 'react.ai_product_settings.product',
  AI_PRODUCT_SETTINGS_PRODUCT_SKU = 'react.ai_product_settings.product_sku',
  AI_PRODUCT_SETTINGS_PRODUCT_ID = 'react.ai_product_settings.product_id',
  AI_PRODUCT_SETTINGS_AD_SALES = 'react.ai_product_settings.ad_sales',
  AI_PRODUCT_SETTINGS_AD_SPEND = 'react.ai_product_settings.ad_spend',
  AI_PRODUCT_SETTINGS_ACOS = 'react.ai_product_settings.acos',
  AI_PRODUCT_SETTINGS_DAILY_BUDGET = 'react.ai_product_settings.daily_budget',
  AI_PRODUCT_SETTINGS_ACOS_TARGET = 'react.ai_product_settings.acos_target',
  AI_PRODUCT_SETTINGS_TAGS_MANAGEMENT = 'react.ai_product_settings.tags_management',
  AI_PRODUCT_SETTINGS_BRAND = 'react.ai_product_settings.brand',
  AI_PRODUCT_SETTINGS_COMPETITOR = 'react.ai_product_settings.competitor',
  AI_PRODUCT_SETTINGS_MANAGE = 'react.ai_product_settings.manage',
  AI_PRODUCT_SETTINGS_DAILY_BUDGET_ERROR_MESSAGE = 'react.ai_product_settings.daily_budget.error_message',
  AI_PRODUCT_SETTINGS_ACOS_TARGET_ERROR_MESSAGE = 'react.ai_product_settings.acos_target.error_message',
  AI_PRODUCT_SETTINGS_DAILY_BUDGET_ERROR_MESSAGE_TOOLTIP = 'react.ai_product_settings.daily_budget.error_message.tooltip',
  AI_PRODUCT_SETTINGS_ACOS_TARGET_ERROR_MESSAGE_TOOLTIP = 'react.ai_product_settings.acos_target.error_message.tooltip',
  AI_PRODUCT_SETTINGS_BID_MODIFIER_ERROR_MESSAGE_TOOLTIP = 'react.ai_product_settings.bid_modifier.error_message.tooltip',
  AI_PRODUCT_SETTINGS_EDIT_DAILY_BUDGET = 'react.ai_product_settings.edit.daily_budget',
  AI_PRODUCT_SETTINGS_EDIT_ACOS_TARGET = 'react.ai_product_settings.edit.acos_target',
  AI_PRODUCT_SETTINGS_BUTTON_SAVE_CHANGE = 'react.ai_product_settings.button.save_change',
  AI_PRODUCT_SETTINGS_BUTTON_CANCEL = 'react.ai_product_settings.button.cancel',
  AI_PRODUCT_SETTINGS_TOAST_MESSAGE_SAVE_EDITED_CHANGES_SUCCESS = 'react.pages.ai_product_settings.toast_message.save_changes_success',
  AI_PRODUCT_SETTINGS_TOAST_MESSAGE_SAVE_EDITED_CHANGES_FAILURE = 'react.pages.ai_product_settings.toast_message.save_changes_failure',
  AI_PRODUCT_SETTINGS_TOAST_MESSAGE_SAVE_EDITED_CHANGES_FAILURE_HEADLINE = 'react.pages.ai_product_settings.toast_message.save_changes_failure_headline',
  AI_PRODUCT_SETTINGS_INPUT_PENDING_ACTION_TOOLTIP = 'react.pages.ai_product_settings.input_pending_action.tooltip',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BUTTON_CLOSE = 'react.ai_product_settings.manage_competitor_tag_modal.button_close',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_TITLE = 'react.ai_product_settings.manage_competitor_tag_modal.brand_competitor_tags',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BUTTON_CANCEL = 'react.ai_product_settings.manage_competitor_tag_modal.button_cancel',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BUTTON_SUBMIT = 'react.ai_product_settings.manage_competitor_tag_modal.button_submit',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BUTTON_ADD_MORE = 'react.ai_product_settings.manage_competitor_tag_modal.button_add_more',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_PRODUCT_COUNT = 'react.ai_product_settings.manage_competitor_tag_modal.product_count',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BRAND_TAGS_HEADER_TITLE = 'react.ai_product_settings_manage_competitor_tag_modal.brand_tags_header_title',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BRAND_TAGS_HEADER_SUBTITLE = 'react.ai_product_settings_manage_competitor_tag_modal.brand_tags_header_subtitle',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_COMPETITOR_TAGS_HEADER_TITLE = 'react.ai_product_settings_manage_competitor_tag_modal.competitor_tags_header_title',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_COMPETITOR_TAGS_HEADER_SUBTITLE = 'react.ai_product_settings_manage_competitor_tag_modal.competitor_tags_header_subtitle',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BULK_EDIT_REMOVE = 'react.ai_product_settings_manage_competitor_tag_modal.bulk_edit_remove',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_REMOVE_TAG = 'react.ai_product_settings_manage_competitor_tag_modal.remove_tag',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_ADD_NEW = 'react.ai_product_settings_manage_competitor_tag_modal.add_new',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_EXISTS = 'react.ai_product_settings_manage_competitor_tag_modal.tag_exists',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_ADD = 'react.ai_product_settings_manage_competitor_tag_modal.add',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_MOVE_TO_COMPETITOR = 'react.ai_product_settings_manage_competitor_tag_modal.move_to_competitor',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_MOVE_TO_BRAND = 'react.ai_product_settings_manage_competitor_tag_modal.move_to_brand',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_INFO_HEADER = 'react.ai_product_settings_manage_competitor_tag_modal.info_header',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_BEST_PRACTICES_FOOTER = 'react.ai_product_settings_manage_competitor_tag_modal.best_practices_footer',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_TOAST_SUCCESS_HEADLINE = 'react.ai_product_settings_manage_competitor_tag_modal_toast_success_headline',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_TOAST_SUCCESS_DESCRIPTION = 'react.ai_product_settings_manage_competitor_tag_modal_toast_success_description',
  AI_PRODUCT_SETTINGS_MANAGE_COMPETITOR_TAG_TYPEAHEAD_PLACEHOLDER = 'react.ai_product_settings_manage_competitor_tag_modal_typeahead_placeholder',
  AI_PRODUCT_SETTINGS_ON_BOARDING_ERROR = 'react.ai_product_settings.on_boarding_error',
  AI_PRODUCT_SETTINGS_CONTACT_SUPPORT = 'react.ai_product_settings.contact_support',
  AI_PRODUCT_SETTINGS_BUTTON_UPGRADE = 'react.ai_product_settings.button_upgrade',
  AI_PRODUCT_SETTINGS_COGS_FOOTER = 'react.ai_product_settings_cogs.footer',
  AI_PRODUCT_SETTINGS_COGS_SUGGESTION = 'react.ai_product_settings_cogs.suggestion',
  AI_PRODUCT_SETTINGS_SUGGESTION_LABEL = 'react.ai_product_settings_suggestion.label',
  AI_PRODUCT_SETTINGS_SUGGESTION_CREATE_CAMPAIGN = 'react.ai_product_settings_suggestion.create_campaign',
  AI_PRODUCT_SETTINGS_SUGGESTION_NO_EXISTING_CAMPAIGN = 'react.ai_product_settings_suggestion.no_existing_campaign',
  AI_PRODUCT_SETTINGS_FLYWHEEL_SOLUTION_LABEL = 'react.ai_product_settings_flywheel_solution.label',

  COMPASS_SHOW_SEASONAL_EVENTS = 'react.compass.show_seasonal_events',
  COMPASS_TABS_ERROR_TEXT = 'react.compass.errorStateMainText',
  COMPASS_TABS_ERROR_ADDITIONAL_TEXT = 'react.compass.errorStateSubText',
  COMPASS_TABS_ERROR_RETRY_TEXT = 'react.compass.errorStateRetryText',
  COMPASS_TABS_ERROR_CONTACT_SUPPORT = 'react.compass.errorStateContactSupport',
  COMPASS_TABS_ERROR_CONTACT_SUPPORT_DESCRIPTION = 'react.compass.errorStateContactSupportDescription',
  COMPASS_DASHBOARD_AD_SALES = 'react.compass.dashboard.adSales',
  COMPASS_DASHBOARD_AD_SPEND = 'react.compass.dashboard.adSpend',
  COMPASS_DASHBOARD_TOTAL_SALES = 'react.compass.dashboard.totalSales',
  COMPASS_DASHBOARD_TACOS = 'react.compass.dashboard.tacos',
  COMPASS_DASHBOARD_ROAS = 'react.compass.dashboard.roas',
  COMPASS_DASHBOARD_TOTAL_SALES_TOOLTIP_DESCRIPTION = 'react.compass.dashboard.total_sales_tooltip_description',
  COMPASS_DASHBOARD_AD_SALES_TOOLTIP_DESCRIPTION = 'react.compass.dashboard.ad_sales_tooltip_description',
  COMPASS_DASHBOARD_AD_SPEND_TOOLTIP_DESCRIPTION = 'react.compass.dashboard.ad_spend_tooltip_description',
  COMPASS_DASHBOARD_TACOS_TOOLTIP_DESCRIPTION = 'react.compass.dashboard.tacos_tooltip_description',
  COMPASS_DASHBOARD_METRIC_TABS_CAPTION = 'react.compass.dashboard.metric_tabs_caption',
  COMPASS_DASHBOARD_DISCLAIMER_CAPTION = 'react.compass.dashboard.disclaimer_caption',
  COMPASS_TABS_LOADING_TEXT = 'react.compass.loadingStateMainText',
  COMPASS_TABS_LOADING_ADDITIONAL_TEXT = 'react.compass.loadingStateSubText',
  COMPASS_DASHBOARD_SALES_PERCENT = 'react.compass.dashboard.insights.table_header.sales.percent',
  COMPASS_DASHBOARD_AD_SPEND_PERCENT = 'react.compass.dashboard.insights.table_header.ad_spend.percent',
  COMPASS_DASHBOARD_TACOS_PERCENT = 'react.compass.dashboard.insights.table_header.tacos.percent',
  COMPASS_DASHBOARD_INSIGHTS_TABLE_HEADER_MERCHANT = 'react.compass.dashboard.insights.table_header.merchant',
  COMPASS_DASHBOARD_INSIGHTS_TITLE = 'react.compass.dashboard.insights.title',
  COMPASS_DASHBOARD_INSIGHTS_PREVIOUS_VALUE = 'react.compass.dashboard.insights.previous_value',
  COMPASS_DASHBOARD_INSIGHTS_TABLE_ROW_OTHER = 'react.compass.dashboard.insights.table_row.other',
  COMPASS_DASHBOARD_PERFORMANCE_TABLE_RANK = 'react.compass.dashboard.performance.rank',

  COMPASS_DASHBOARD_EXPORT_DATA_LABEL = 'react.compass.dashboard.export_data.label',
  COMPASS_DASHBOARD_FILE_DOWNLOAD_SUCCESS_TITLE = 'react.compass.dashboard.file_download.success.title',
  COMPASS_DASHBOARD_FILE_DOWNLOAD_FAILD_TITLE = 'react.compass.dashboard.file_download.failed.title',
  COMPASS_DASHBOARD_FILE_DOWNLOAD_SUCCESS_DESCRIPTION = 'react.compass.dashboard.file_download.success.description',
  COMPASS_DASHBOARD_FILE_DOWNLOAD_FAILD_DESCRIPTION = 'react.compass.dashboard.file_download.failed.description',

  COMPASS_DASHBOARD_CHART_DOWNLOAD = 'react.compass.dashboard.chart.download',
  COMPASS_DASHBOARD_CHART_DOWNLOAD_TOOLTIP = 'react.compass.dashboard.chart.downloadTooltip',
  COMPASS_DASHBOARD_CHART_OVERVIEW = 'react.compass.dashboard.chart.overview',
  COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_PERIOD = 'react.compass.dashboard.chart.legend.current_period',
  COMPASS_DASHBOARD_CHART_LEGEND_PREVIOUS_PERIOD = 'react.compass.dashboard.chart.legend.previous_period',
  COMPASS_DASHBOARD_CHART_LEGEND_DATA_CONNECTED = 'react.compass.dashboard.chart.legend.data_connected',
  COMPASS_DASHBOARD_CHART_LEGEND_AD_SPEND = 'react.compass.dashboard.chart.legend.ad_spend',
  COMPASS_DASHBOARD_CHART_LEGEND_TOTAL_SALES = 'react.compass.dashboard.chart.legend.total_sales',
  COMPASS_DASHBOARD_CHART_LEGEND_CURRENT_TACOS = 'react.compass.dashboard.chart.legend.current_tacos',
  COMPASS_DASHBOARD_CHART_LEGEND_PREVIOUS_TACOS = 'react.compass.dashboard.chart.legend.previous_tacos',
  COMPASS_DASHBOARD_CHART_LEGEND_NO_DATA = 'react.compass.dashboard.chart.legend.no_data',
  COMPASS_DASHBOARD_CHART_LEGEND_ACTIVATED_AI = 'react.compass.dashboard.chart.legend.activated_ai',
  COMPASS_DASHBOARD_CHART_LEGEND_DEACTIVATED_AI = 'react.compass.dashboard.chart.legend.deactivated_ai',
  COMPASS_DASHBOARD_CHART_LEGEND_BEFORE_AI = 'react.compass.dashboard.chart.legend.before_ai',
  COMPASS_DASHBOARD_CHART_LEGEND_WITH_AI = 'react.compass.dashboard.chart.legend.with_ai',
  COMPASS_DASHBOARD_CHART_LEGEND_GATHERING_DATA = 'react.compass.dashboard.chart.legend.gathering_data',
  COMPASS_DASHBOARD_CHART_MINI_METRIC_WEEKLY_DATE_FORMAT = 'react.compass.dashboard.chart.mini_metric.weekly.date.format',
  COMPASS_DASHBOARD_SALES_CHANNEL_AMAZON = 'react.compass.dashboard.sales_channel.amazon',
  COMPASS_DASHBOARD_SALES_CHANNEL_WALMART = 'react.compass.dashboard.sales_channel.walmart',
  COMPASS_DASHBOARD_CHART_DETAILS = 'react.compass.dashboard.chart.details',
  COMPASS_DASHBOARD_CHART_DETAILS_PIECHART_TITLE = 'react.compass.dashboard.charts.details.piechart_title',

  COMPASS_DASHBOARD_AI_STATISTICS_TITLE = 'react.compass.dashboard.aiStatistics.title',
  COMPASS_DASHBOARD_AI_STATISTICS_SLIDEOVER_TITLE = 'react.compass.dashboard.aiStatistics.slideover.title',
  COMPASS_DASHBOARD_AI_STATISTICS_TITLE_DESCRIPTION = 'react.compass.dashboard.aiStatistics.title.description',
  COMPASS_DASHBOARD_AI_STATISTICS_TITLE_DESCRIPTION_V2 = 'react.compass.dashboard.aiStatistics.title.description_v2',
  COMPASS_DASHBOARD_AI_STATISTICS_TITLE_DESCRIPTION_LINK_TEXT = 'react.compass.dashboard.aiStatistics.title.description_link_text',
  COMPASS_DASHBOARD_AI_STATISTICS_TITLE_DESCRIPTION_2 = 'react.compass.dashboard.aiStatistics.title.description2',
  COMPASS_DASHBOARD_AI_STATISTICS_VALUE_DESCRIPTION = 'react.compass.dashboard.aiStatistics.value.description',
  COMPASS_DASHBOARD_AI_STATISTICS_VALUE_DESCRIPTION_2 = 'react.compass.dashboard.aiStatistics.value.description2',
  COMPASS_DASHBOARD_AI_STATISTICS_VALUE_TOOLTIP_BID_CHANGES = 'react.compass.dashboard.aiStatistics.tooltip.bid_changes',
  COMPASS_DASHBOARD_AI_STATISTICS_VALUE_TOOLTIP_AD_GROUPS_ENABLED = 'react.compass.dashboard.aiStatistics.tooltip.ad_groups_enabled',

  COMPASS_PRODUCT_ACTION_CARD_CONTENT = 'react.compass.product_action_card.content',
  COMPASS_PRODUCT_ACTION_CARD_BUTTON_TITLE = 'react.compass.product_action_card.button_title',
  COMPASS_PRODUCT_ACTION_CARD_HEADER = 'react.compass.product_action_card.header',
  COMPASS_PAYMENT_ACTION_CARD_BUTTON_TITLE = 'react.compass.payment_action_card.button_title',
  COMPASS_PAYMENT_ACTION_CARD_SUCCESS_HEADER = 'react.compass.payment_action_card.success.header',
  COMPASS_PAYMENT_ACTION_CARD_SUCCESS_DESCRIPTION = 'react.compass.payment_action_card.success.description',
  COMPASS_PAYMENT_ACTION_CARD_SUCCESS_CTA = 'react.compass.payment_action_card.success.cta',
  COMPASS_PAYMENT_ACTION_CARD_BUTTON_SECONDARY_TITLE = 'react.compass.payment_action_card.button_secondary_title',
  COMPASS_ADD_CREDIT_CARD_BUTTON_TITLE = 'react.compass.add_credit_card.button_title',
  COMPASS_ADD_CREDIT_CARD_CONTENT = 'react.compass.add_credit_card.content',
  COMPASS_ADD_CREDIT_CARD_HEADER = 'react.compass.add_credit_card.header',
  COMPASS_ADD_CREDIT_CARD_HEADER_FREE_TRIAL_OVER = 'react.compass.add_credit_card.header_free_trial_over',
  COMPASS_AUTOMATE_ADVERTISING_POPUP_HEADER = 'react.compass.automate_advertising_popup.header',
  COMPASS_AUTOMATE_ADVERTISING_POPUP_SUB_HEADER = 'react.compass.automate_advertising_popup.sub_header',
  COMPASS_AUTOMATE_ADVERTISING_POPUP_BUTTON_CLOSE = 'react.compass.automate_advertising_popup.button.close',
  COMPASS_MISSING_ACOS_TARGETS_AD_GROUP_COUNT = 'react.compass.missing_acos_targets.ad_group_count',
  COMPASS_MISSING_ACOS_TARGETS_CAMPAIGN_COUNT = 'react.compass.missing_acos_targets.campaign_count',
  COMPASS_MISSING_ACOS_ADVERTISING_TYPE_SPONSORED_BRANDS = 'react.compass.missing_acos_targets.advertising_type.sponsored_brands',
  COMPASS_MISSING_ACOS_ADVERTISING_TYPE_SPONSORED_DISPLAY = 'react.compass.missing_acos_targets.advertising_type.sponsored_display',
  COMPASS_MISSING_ACOS_ADVERTISING_TYPE_SPONSORED_PRODUCTS = 'react.compass.missing_acos_targets.advertising_type.sponsored_products',
  COMPASS_TEASER_COMING_SOON_TEXT = 'react.pages.compass.teaser.coming_soon_text',
  COMPASS_TEASER_MORE_ANALYSIS_TOOL_COMMING = 'react.pages.compass.teaser.more_analysis_tool',
  COMPASS_TEASER_GO_TO_AO = 'react.pages.compass.teaser.go_to_AO',
  COMPASS_TEASER_GO_TO_SKU = 'react.pages.compass.teaser.go_to_SKU',
  COMPASS_TEASER_SUBSCRIBE_INTERIM_MESSAGE_FOR_COMING_SOON = 'react.pages.compass.teaser.interim_message_for_comming_soon',
  COMPASS_TEASER_BUTTON_SUBSCRIBE_FOR_UPDATES = 'react.pages.compass.teaser.subscribe_for_updates',
  COMPASS_TEASER_BUTTON_SUBSCRIBED = 'react.pages.compass.teaser.subscribed',
  COMPASS_TEASER_POST_SUBSCRIBE_MESSAGE_HEADER = 'react.pages.compass.teaser.post_subscribe_header',
  COMPASS_TEASER_POST_SUBSCRIBE_MESSAGE_DESCRIPTION = 'react.pages.compass.teaser.post_subscribe_description',
  COMPASS_ADD_COGS_CARD_HEADER = 'react.compass.add_cogs_card.header',
  COMPASS_ADD_COGS_CARD_CONTENT = 'react.compass.add_cogs_card.content',
  COMPASS_ADD_COGS_CARD_BUTTON_TITLE = 'react.compass.add_cogs_card.button_title',
  COMPASS_CAMPAIGN_OPTIMIZER_CARD_HEADER = 'react.compass.campaign_optimizer.card.header',
  COMPASS_CAMPAIGN_OPTIMIZER_CARD_CONTENT = 'react.compass.campaign_optimizer.card.content',
  COMPASS_ADD_PROFILE_COMPLETION_CARD_HEADER = 'react.compass.profile_completion_action_card.header',
  COMPASS_ADD_PROFILE_COMPLETION_CARD_ALERT_SUBHEADER = 'react.compass.profile_completion_action_card.alert_subheader',
  COMPASS_ADD_PROFILE_COMPLETION_CARD_SUCCESS_SUBHEADER = 'react.compass.profile_completion_action_card.success_subheader',
  COMPASS_ADD_PROFILE_COMPLETION_CARD_ALERT_BTN = 'react.compass.profile_completion_action_card.alert_btn_label',
  COMPASS_ADD_PROFILE_COMPLETION_CARD_SUCCESS_BTN = 'react.compass.profile_completion_action_card.success_btn_label',
  COMPASS_ADD_PROFILE_COMPLETION_CARD_TEXT_LINK = 'react.compass.profile_completion_action_card.text_link',
  COMPASS_ADD_PROFILE_COMPLETION_CARD_TOOLTIP = 'react.compass.profile_completion_action_card.tooltip_text',

  COMPASS_RECOMMENDATION_BANNER_GENERAL_TEXT = 'react.compass.recommendation_banner.general.text',
  COMPASS_RECOMMENDATION_BANNER_GENERAL_LINK_TEXT = 'react.compass.recommendation_banner.general.link_text',
  COMPASS_RECOMMENDATION_BANNER_ADVANCED_HEADING = 'react.compass.recommendation_banner.advanced_banner.heading',
  COMPASS_RECOMMENDATION_BANNER_ADVANCED_SEE_ALL = 'react.compass.recommendation_banner.advanced_banner.see_all',
  COMPASS_RECOMMENDATION_BANNER_ADVANCED_MAIN_TEXT = 'react.compass.recommendation_banner.advanced_banner.main_text',
  COMPASS_RECOMMENDATION_BANNER_ADVANCED_SUB_TEXT = 'react.compass.recommendation_banner.advanced_banner.sub_text',
  COMPASS_RECOMMENDATION_BANNER_ADVANCED_PRIMARY_BUTTON = 'react.compass.recommendation_banner.advanced_banner.primary_button',
  COMPASS_RECOMMENDATION_BANNER_ADVANCED_SECONDARY_BUTTON = 'react.compass.recommendation_banner.advanced_banner.secondary_button',
  COMPASS_RECOMMENDATION_BANNER_ADVANCED_DISMISS_BUTTON = 'react.compass.recommendation_banner.advanced_banner.dismiss_button',
  COMPASS_RECOMMENDATION_BANNER_ADVANCED_DISMISS_MODAL_MAIN_TEXT = 'react.compass.recommendation_banner.dismiss_modal.main_text',

  RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_HEADER = 'react.recommendation_hub.card.automated_bidding.header',
  RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_PILL_TEXT = 'react.recommendation_hub.card.automated_bidding.pill_text',
  RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_MAIN_TEXT = 'react.recommendation_hub.card.automated_bidding.main_text',
  RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_MAIN_TEXT_V2 = 'react.recommendation_hub.card.automated_bidding.main_text_v2',
  RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_SUB_TEXT = 'react.recommendation_hub.card.automated_bidding.sub_text',
  RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.automated_bidding.primary_button_text',
  RECOMMENDATION_HUB_CARD_UNADVERTISED_PRODUCT_MAIN_TEXT = 'react.recommendation_hub.card.unadvertised_product.main_text',
  RECOMMENDATION_HUB_CARD_UNADVERTISED_PRODUCT_SUB_TEXT = 'react.recommendation_hub.card.unadvertised_product.sub_text',
  RECOMMENDATION_HUB_CARD_UNADVERTISED_PRODUCT_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.unadvertised_product.primary_button_text',
  RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT = 'react.recommendation_hub.card.connect_data.pill_text',
  RECOMMENDATION_HUB_CARD_PROFITABILITY_PILL_TEXT = 'react.recommendation_hub.card.profitability.pill_text',
  RECOMMENDATION_HUB_CARD_CONNECT_DATA_MAIN_TEXT = 'react.recommendation_hub.card.connect_data.main_text',
  RECOMMENDATION_HUB_CARD_CONNECT_DATA_SUB_TEXT = 'react.recommendation_hub.card.connect_data.sub_text',
  RECOMMENDATION_HUB_CARD_CONNECT_DATA_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.connect_data.primary_button_text',
  RECOMMENDATION_HUB_CARD_MARKET_INTELLIGENCE_MAIN_TEXT = 'react.recommendation_hub.card.market_intelligence.main_text',
  RECOMMENDATION_HUB_CARD_MARKET_INTELLIGENCE_SUGGESTIONS_MAIN_TEXT = 'react.recommendation_hub.card.market_intelligence.suggestions_main_text',
  RECOMMENDATION_HUB_CARD_MARKET_INTELLIGENCE_SUB_TEXT = 'react.recommendation_hub.card.market_intelligence.sub_text',
  RECOMMENDATION_HUB_CARD_MARKET_INTELLIGENCE_SHOW_ME_HOW_TEXT = 'react.recommendation_hub.card.market_intelligence.show_me_how',
  RECOMMENDATION_HUB_CARD_MARKET_INTELLIGENCE_TRACK_TERMS_TEXT = 'react.recommendation_hub.card.market_intelligence.track_terms',
  RECOMMENDATION_HUB_CARD_ADD_COGS_MAIN_TEXT = 'react.recommendation_hub.card.add_cogs.main_text',
  RECOMMENDATION_HUB_CARD_ADD_COGS_MAIN_TEXT_V2 = 'react.recommendation_hub.card.add_cogs.main_text_v2',
  RECOMMENDATION_HUB_CARD_ADD_COGS_SUB_TEXT = 'react.recommendation_hub.card.add_cogs.sub_text',
  RECOMMENDATION_HUB_CARD_ADD_COGS_SUB_TEXT_V2 = 'react.recommendation_hub.card.add_cogs.sub_text_v2',
  RECOMMENDATION_HUB_CARD_ADD_COGS_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.add_cogs.primary_button_text',
  RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_PILL_TEXT = 'react.recommendation_hub.card.opportunity_analyzer_report.pill_text',
  RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_MAIN_TEXT = 'react.recommendation_hub.card.opportunity_analyzer_report.main_text',
  RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_SUB_TEXT = 'react.recommendation_hub.card.opportunity_analyzer_report.sub_text',
  RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_GENERATE_REPORT_TEXT = 'react.recommendation_hub.card.opportunity_analyzer_report.generate_report_text',
  RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_DOWNLOAD_REPORT_TEXT = 'react.recommendation_hub.card.opportunity_analyzer_report.download_report_text',
  RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_FILE_NAME = 'react.recommendation_hub.card.opportunity_analyzer_report.report_file_name',
  RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_UPGRADE_PLAN_TEXT = 'react.recommendation_hub.card.opportunity_analyzer_report.upgrade_plan_text',
  RECOMMENDATION_HUB_CARD_GENERAL_CONTINUE_TEXT = 'react.recommendation_hub.card.general.continue_text',
  RECOMMENDATION_HUB_CARD_GENERAL_FREE_TRIAL_TEXT = 'react.recommendation_hub.card.general.start_free_trial',
  RECOMMENDATION_HUB_CARD_GENERAL_FOOTER_TEXT = 'react.recommendation_hub.card.general.footer_text',
  RECOMMENDATION_HUB_MESSAGE_COMPLETE_COGS_UPDATE = 'react.recommendation_hub.message.complete.cogs_update',
  RECOMMENDATION_HUB_MESSAGE_COMPLETE_COGS_DESCRIPTION = 'react.recommendation_hub.message.complete.cogs_description',
  RECOMMENDATION_HUB_SUB_MESSAGE_COMPLETE_COGS_UPDATE = 'react.recommendation_hub.sub_message.complete.cogs_update',
  RECOMMENDATION_HUB_TABLE_COGS_COMPLETE_MESSAGE = 'react.recommendation_hub.table.cogs.message',
  RECOMMENDATION_HUB_TABLE_COGS_COMPLETE_SUB_MESSAGE = 'react.recommendation_hub.table.cogs.sub_message',
  RECOMMENDATION_HUB_TABLE_COGS_PARTIAL_COMPLETE_MESSAGE = 'react.recommendation_hub.table.cogs.message.partial_complete',
  RECOMMENDATION_HUB_TABLE_COGS_PARTIAL_COMPLETE_SUB_MESSAGE = 'react.recommendation_hub.table.cogs.sub_message.partial_complete',
  RECOMMENDATION_HUB_TOOLTIP_PARTIAL_COMPLETE_MESSAGE = 'react.recommendation_hub.table.cogs.tooltip.partial_complete',
  RECOMMENDATION_HUB_COMPLETED_LINK_TO_RECOMMENDATION = 'react.recommendation_hub.completed.link.go_to_recommendation',
  RECOMMENDATION_HUB_HEADER_BACK_LINK = 'react.recommendation_hub.header.back_link',

  RECOMMENDATION_HUB_CARD_ADD_COGS_V4_HEADING = 'react.recommendation_hub.card.add_cogs.v4.heading',
  RECOMMENDATION_HUB_CARD_ADD_COGS_V4_STEP1_HEADING = 'react.recommendation_hub.card.add_cogs.v4.details.step.1.heading',
  RECOMMENDATION_HUB_CARD_ADD_COGS_V4_STEP1_DESCRIPTION = 'react.recommendation_hub.card.add_cogs.v4.details.step.1.description',
  RECOMMENDATION_HUB_CARD_ADD_COGS_V4_STEP2_HEADING = 'react.recommendation_hub.card.add_cogs.v4.details.step.2.heading',
  RECOMMENDATION_HUB_CARD_ADD_COGS_V4_STEP2_DESCRIPTION = 'react.recommendation_hub.card.add_cogs.v4.details.step.2.description',
  RECOMMENDATION_HUB_CARD_ADD_COGS_V4_STEP3_HEADING = 'react.recommendation_hub.card.add_cogs.v4.details.step.3.heading',
  RECOMMENDATION_HUB_CARD_ADD_COGS_V4_STEP3_DESCRIPTION = 'react.recommendation_hub.card.add_cogs.v4.details.step.3.description',
  RECOMMENDATION_HUB_CARD_ADD_COGS_V4_TOTAL_MISSING_COGS = 'react.recommendation_hub.card.add_cogs.v4.total_missing_cogs',
  RECOMMENDATION_HUB_CARD_ADD_COGS_V4_BULK_MANAGE_SPREADSHEET = 'react.recommendation_hub.card.add_cogs.v4.bulk_manage_spreadsheet',

  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_HEADER = 'react.compass.automate_bidding.video_popup.header',
  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_DESCRIPTION = 'react.compass.automate_bidding.video_popup.description',
  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_SUBHEADER = 'react.compass.automate_bidding.video_popup.subheader',
  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_SUBHEADER_DESCRIPTION = 'react.compass.automate_bidding.video_popup.subheader_description',
  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_BUTTON_LETS_DO_IT = 'react.compass.automate_bidding.video_popup.button.lets_do_it',
  COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_BUTTON_NEXT_STEPS = 'react.compass.automate_bidding.video_popup.button.next_steps',
  COMPASS_TEASER_MISSING_ACOS_TARGET_HEADER = 'react.pages.compass.teaser.missing_acos_target.header',
  COMPASS_TEASER_MISSING_ACOS_TARGET_DESCRIPTION = 'react.pages.compass.teaser.missing_acos_target.description',
  COMPASS_DASHBOARD_ACTION_CARD_PROGRESS_TEXT = 'react.pages.compass.dashboard.action_card_progress_text',
  COMPASS_TEASER_SHOW_ME_HOW = 'react.pages.compass.teaser.show_me_how',
  COMPASS_TEASER_BUTTON_LABEL = 'react.pages.compass.teaser.button_label',
  COMPASS_MI_CARD_DESCRIPTION = 'react.pages.compass.market_intelligence_card_description',
  COMPASS_DASHBOARD_TABS_BUSINESS = 'react.pages.compass.dashboard.tabs.business',
  COMPASS_DASHBOARD_TABS_BUSINESS_PERFORMANCE = 'react.pages.compass.dashboard.tabs.business_performance',
  COMPASS_DASHBOARD_TABS_ADS = 'react.pages.compass.dashboard.tabs.ads',
  COMPASS_DASHBOARD_TABS_PRODUCTS = 'react.pages.compass.dashboard.tabs.products',
  COMPASS_DASHBOARD_ACTION_ITEMS_HEADER = 'react.pages.compass.dashboard.action_items.header',
  COMPASS_DASHBOARD_ACTION_ITEMS_ALL_CLEAR = 'react.pages.compass.dashboard.action_items.all_clear',
  COMPASS_DASHBOARD_ACTION_ITEMS_ALL_CLEAR_DESCRIPTION = 'react.pages.compass.dashboard.action_items.all_clear.description',
  COMPASS_DASHBOARD_MI_ACTION_CARD_HEADER_OLD = 'react.pages.compass.market_intelligence.header.old',
  COMPASS_DASHBOARD_MI_ACTION_CARD_HEADER_NEW = 'react.pages.compass.market_intelligence.header.new',
  COMPASS_DASHBOARD_AI_RECOMMENDATIONS_ACTION_CARD_HEADER = 'react.pages.compass.ai_recommendations.header',

  DASHBOARD_AI_TAGS = 'react.pages.AI_Tags',
  COMPASS_DASHBOARD_AI_AUTOMATED_BIDDING_CARD_HEADER = 'react.pages.compass.AI_automated_bidding_cards.header',
  COMPASS_DASHBOARD_AI_AUTOMATED_BIDDING_CARD_DESCRIPTION = 'react.pages.compass.AI_automated_bidding_cards.description',
  COMPASS_DASHBOARD_AI_POWERED_CAMPAIGN_ORGANIZER_SETUP_CARD_HEADER = 'react.pages.compass.AI_powered_campaign_organizer_setup_cards.header',
  COMPASS_DASHBOARD_AI_POWERED_CAMPAIGN_ORGANIZER_SETUP_CARD_DESCRIPTION = 'react.pages.compass.AI_powered_campaign_organizer_setup_cards.description',
  COMPASS_DASHBOARD_AI_POWERED_FYWHEEL_FREE_TRIAL_CARD_HEADER = 'react.pages.compass.AI_powered_flywheel_free_trial_cards.header',
  COMPASS_DASHBOARD_AI_POWERED_FYWHEEL_FREE_TRIAL_CARD_DESCRIPTION = 'react.pages.compass.AI_powered_flywheel_free_trial_cards.description',
  COMPASS_DASHBOARD_AI_POWERED_FYWHEEL_FREE_TRIAL_CARD_BUTTON = 'react.pages.compass.AI_powered_flywheel_free_trial_cards.button',

  COMPASS_DASHBOARD_SHOW_TOP_ONLY = 'react.pages.compass.show_top_only',

  NAV_BAR_LABEL_PRODUCT_SETTINGS = 'react.components.navbar.product_settings.header',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ROW_ASIN = 'react.components.ai_product_settings_v2_product_settings_row_asin',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ROW_SKU = 'react.components.ai_product_settings_v2_product_settings_row_sku',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ROW_SKU_WALMART = 'react.components.ai_product_settings_v2_product_settings_row_sku_walmart',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ROW_BASE_OR_PARENT_SKU = 'react.components.ai_product_settings_v2_product_settings_row_base_or_parent_sku',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ROW_SINGLETON_SKU = 'react.components.ai_product_settings_v2_product_settings_row_singleton_sku',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ROW_ADVERTISED = 'react.components.ai_product_settings_v2_product_settings_row_advertised',
  COMPASS_CHART_CONTEXT_DAILY = 'react.pages.compass.chart.daily',
  COMPASS_CHART_CONTEXT_WEEKLY = 'react.pages.compass.chart.weekly',
  COMPASS_CHART_CONTEXT_MONTHLY = 'react.pages.compass.chart.monthly',
  COMPASS_CHART_PREVIOUS_TOOLTIP_ACTIVAED_AI_PREVIOUS = 'react.pages.compass.charts.metric.activated_ai.previous',
  COMPASS_CHART_PREVIOUS_TOOLTIP_ACTIVAED_AI_PREVIOUS_TACOS = 'react.pages.compass.charts.metric.activated_ai.previousTacos',
  COMPASS_CHART_PREVIOUS_TOOLTIP_ACTIVAED_AI_TACOS = 'react.pages.compass.charts.metric.activated_ai.value.tacos',
  COMPASS_CHART_PREVIOUS_TOOLTIP_ACTIVAED_AI_LEARN_MORE = 'react.pages.compass.charts.metric.activated_ai.learnmore',
  COMPASS_CHART_PREVIOUS_TOOLTIP_ACTIVAED_AI_LEARN_MORE_URL = 'react.pages.compass.charts.metric.activated_ai.learnmore.url',
  COMPASS_CHART_NO_DATA_HEADER = 'react.pages.compass.charts.no_data.header',
  COMPASS_CHART_NO_DATA_DESCRIPTION = 'react.pages.compass.charts.no_data.discription',
  COMPASS_CHART_NO_DATA_CONNECTED_HEADER = 'react.pages.compass.charts.no_data_connected.header',
  COMPASS_CHART_EMPTY_MERCHANT_HEADER = 'react.pages.compass.charts.empty_merchant.header',
  COMPASS_CHART_EMPTY_MERCHANT_BUTTON = 'react.pages.compass.charts.empty_merchant.button',
  COMPASS_CHART_NO_DATA_CONNECTED_DESCRIPTION = 'react.pages.compass.charts.no_data_connected.discription',
  COMPASS_CHART_NO_DATA_CONNECT_PRODUCT = 'react.pages.compass.charts.no_data.connect_product',
  COMPASS_CHART_DISCLAIMER_BANNER_ADS_MANAGER = 'react.pages.compass.disclaimer_banner.ads_manager',
  COMPASS_CHART_DISCLAIMER_BANNER_SKU_CATALOG = 'react.pages.compass.disclaimer_banner.sku_catalog',
  NAV_BAR_LABEL_PRODUCT_GROUP_SETTINGS = 'react.components.navbar.product_group_settings.header',
  AI_PRODUCT_GROUP_SETTINGS_ADVERTISING_LEVEL = 'react.components.ai_product_group_settings.advertising.level',
  AI_PRODUCT_GROUP_SETTINGS_BRANDS_AND_COMPETITORS_LEVEL = 'react.components.ai_product_group_settings.brands_and_competitors.level',
  AI_PRODUCT_GROUP_SETTINGS_COGS_LEVEL = 'react.components.ai_product_group_settings.cogs.level',
  AI_PRODUCT_SETTINGS_V2_SEARCH_FILTER = 'react.components.ai_product_settings_v2_search_filter',
  AI_PRODUCT_SETTINGS_V2_NO_RESULTS = 'react.components.ai_product_settings_v2_no_results',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_MERCHANT_COUNTRY_PLACEHOLDER = 'react.components.ai_product_settings_v2_merchant_country_placeholder',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_SHOW_PRODUCTS = 'react.components.ai_product_settings_v2_show_products',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_PARENT_PRODUCT = 'react.components.ai_product_settings_v2_product_settings_parent_product',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_PRODUCT_SHELF = 'react.components.ai_product_settings_v2_product_settings_product_shelf',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_BASE_ITEMS = 'react.components.ai_product_settings_v2_product_settings_product_base_items',
  AI_PRODUCT_SETTINGS_V2_ADVERTISING_CHANGES_MODAL_TITLE = 'react.components.ai_product_settings_v2_advertising_changes_modal_title',
  AI_PRODUCT_SETTINGS_V2_ADVERTISING_CHANGES_MODAL_DESCRIPTION = 'react.components.ai_product_settings_v2_advertising_changes_modal_description',
  AI_PRODUCT_SETTINGS_V2_ADVERTISING_CHANGES_MODAL_CANCEL_BUTTON = 'react.components.ai_product_settings_v2_advertising_changes_modal_cancel_button',
  AI_PRODUCT_SETTINGS_V2_ADVERTISING_CHANGES_MODAL_OK_BUTTON = 'react.components.ai_product_settings_v2_advertising_changes_modal_ok_button',

  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_PARENT_SKU = 'react.components.ai_product_settings_v2_product_settings_parent_sku',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_SKU = 'react.components.ai_product_settings_v2_product_settings_sku',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ASIN = 'react.components.ai_product_settings_v2_product_settings_asin',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_MERCHANT = 'react.components.ai_product_settings_v2_merchant',

  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_HEADER_SKU_CHILDREN = 'react.components.ai_product_settings_v2_product_settings_header_sku_children',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_HEADER_SKU_CHILDREN_WALMART = 'react.components.ai_product_settings_v2_product_settings_header_sku_children_walmart',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_CHILD_SKU = 'react.components.ai_product_settings_v2_product_settings_child_sku',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_CHILD_ASIN = 'react.components.ai_product_settings_v2_product_settings_child_asin',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_CHILD_ID = 'react.components.ai_product_settings_v2_product_settings_child_id',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AI_POWER_METER_TITLE = 'react.components.ai_product_settings_v2_product_settings_ai_power_meter_title',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AI_POWER_METER_COUNT = 'react.components.ai_product_settings_v2_product_settings_ai_power_meter_count',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AI_POWER_METER_ADS_SUGGESTION = 'react.components.ai_product_settings_v2_product_settings_ai_power_meter_ads_suggestion',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AI_POWER_METER_TAGS_SUGGESTION = 'react.components.ai_product_settings_v2_product_settings_ai_power_meter_tags_suggestion',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AI_POWER_METER_COGS_SUGGESTION = 'react.components.ai_product_settings_v2_product_settings_ai_power_meter_cogs_suggestion',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_HEADER_CAMPAIGNS = 'react.components.ai_product_settings_v2_product_settings_header_campaigns',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_TITLE_KEYWORD_CAMPAIGNS = 'react.components.ai_product_settings_v2_product_settings_title_keyword_campaigns',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_TITLE_ALIGNED_STRUCTURE = 'react.components.ai_product_settings_v2_product_settings_title_aligned_structure',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_TITLE_ACOS = 'react.components.ai_product_settings_v2_product_settings_title_acos',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_TITLE_DAILY_BUDGET = 'react.components.ai_product_settings_v2_product_settings_title_daily_budget',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ALIGNED_STRUCTURE_AUTOMATIC = 'react.components.ai_product_settings_v2_product_settings_ad_aligned_automatic',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ALIGNED_STRUCTURE_MANUAL_BRAND = 'react.components.ai_product_settings_v2_product_settings_ad_aligned_manual_brand',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ALIGNED_STRUCTURE_MANUAL_COMPETITOR = 'react.components.ai_product_settings_v2_product_settings_ad_aligned_manual_competitor',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ALIGNED_STRUCTURE_MANUAL_GENERIC = 'react.components.ai_product_settings_v2_product_settings_ad_aligned_manual_generic',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_MAIN_LABEL = 'react.ai_product_settings_suggestion.main_label',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_MAIN_HEADER = 'react.ai_product_settings_suggestion.main_header',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_MAIN_HEADER_AD_PRODUCT = 'react.ai_product_settings_suggestion.main_header.ad_product',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_QUESTION = 'react.ai_product_settings_suggestion.question',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_HEADER = 'react.ai_product_settings_suggestion.campaign_header',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_HEADER_WALMART = 'react.ai_product_settings_suggestion.campaign_header_walmart',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_1 = 'react.ai_product_settings_suggestion.campaign_explanation_1',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_2 = 'react.ai_product_settings_suggestion.campaign_explanation_2',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_3 = 'react.ai_product_settings_suggestion.campaign_explanation_3',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_4 = 'react.ai_product_settings_suggestion.campaign_explanation_4',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CAMPAIGN_EXPLANATION_WALMART_2 = 'react.ai_product_settings_suggestion.campaign_explanation_walmart_2',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_STRUCTURE_EXPLANATION_HEADER = 'react.ai_product_settings_suggestion.structure_explanation_header',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_STRUCTURE_EXPLANATION_1 = 'react.ai_product_settings_suggestion.structure_explanation_1',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_STRUCTURE_EXPLANATION_2 = 'react.ai_product_settings_suggestion.structure_explanation_2',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_STRUCTURE_EXPLANATION_3 = 'react.ai_product_settings_suggestion.structure_explanation_3',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_STRUCTURE_EXPLANATION_WALMART_1 = 'react.ai_product_settings_suggestion.structure_explanation_walmart_1',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_STRUCTURE_EXPLANATION_WALMART_2 = 'react.ai_product_settings_suggestion.structure_explanation_walmart_2',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_STRUCTURE_EXPLANATION_AD_PRODUCT = 'react.ai_product_settings_suggestion.structure_explanation.ad_product',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_LEARN_MORE_LINK = 'react.ai_product_settings_suggestion.learn_more_link',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_APPLY_SOLUTION = 'react.ai_product_settings_suggestion.apply_solution',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_PAUSE = 'react.ai_product_settings_suggestion.pause',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_KEEP = 'react.ai_product_settings_suggestion.keep',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_MODIFIED = 'react.ai_product_settings_suggestion.modified',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_CREATE = 'react.ai_product_settings_suggestion.create',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_DAILY_BUDGET = 'react.ai_product_settings_suggestion.daily_budget',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_ACOS_TARGET = 'react.ai_product_settings_suggestion.acos_target',
  AI_PRODUCT_SETTINGS_V2_SUGGESTION_BID_MODIFIER = 'react.ai_product_settings_suggestion.bid_modifier',
  AI_PRODUCT_SETTINGS_V2_ACOS_TARGET_UNSET = 'react.ai_product_settings_.acos_target_unset',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_ALIGNED_STRUCTURE_MANUAL_UNKNOWN = 'react.components.ai_product_settings_v2_product_settings_ad_aligned_manual_unknown',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_ERROR_HELP_LABEL = 'react.components.ai_product_settings_v2_product_settings_ad_error_help_label',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_UPDATING_HELP_LABEL = 'react.components.ai_product_settings_v2_product_settings_updating_help_label',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_ERROR_HELP_SUB_LABEL = 'react.components.ai_product_settings_v2_product_settings_ad_error_help_sub_label',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_UPDATING_HELP_SUB_LABEL = 'react.components.ai_product_settings_v2_product_settings_updating_help_sub_label',

  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_TARGET_RECOMMENDATIONS = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_target_recommendations',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_TARGET_RECOMMENDATIONS_ON = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_target_recommendations_on',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_TARGET_RECOMMENDATIONS_OFF = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_target_recommendations_off',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_NAME = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_name',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_DAILY_BUDGET = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_daily_budget',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_GROUP_NAME = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_ad_group_name',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_ACOS_TARGET = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_ad_acos_target',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_MIN_BID = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_ad_acos_min_bid',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_MAX_BID = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_ad_acos_max_bid',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_MISSING_VALUE = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_missing_value',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_MAX_BID_HELP_DESCRIPTION = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_max_bid_help_description',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_MIN_BID_HELP_DESCRIPTION = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_min_bid_help_description',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_ACOS_TARGET_HELP_DESCRIPTION = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_acos_target_help_description',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_ACOS_TARGET_LINK_TITLE = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_acos_target_link_title',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_AD_MIN_MAX_BID_SUGGESTION = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_min_max_bid_suggestion',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_BID_AUTOMATION = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_bid_automation',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_BID_AUTOMATION_ENABLED = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_bid_automation_enabled',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_BID_AUTOMATION_DISABLED = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_bid_automation_disabled',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_BID_CHILD_SKUS = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_bid_child_skus',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_AD_CAMPAIGN_BID_CHILD_SKUS_WALMART = 'react.components.ai_product_settings_v2_product_settings_ad_campaign_bid_child_skus_walmart',
  AI_PRODUCT_SETTINGS_V2_PRODUCT_SETTINGS_COGS_MISSING_COGS_VALUE = 'react.components.ai_product_settings_v2_product_settings_cogs_missing_cogs_value',
  AI_PRODUCT_SETTINGS_BRANDS_AND_COMPETITORS_SUGGESTION = 'react.components.ai_product_brands_and_competitors_suggestion',

  AI_RECOMMENDED_TABLE_HEADER_RECOMMENDED = 'react.components.ai_recomendded-table_header_recommended',
  AI_RECOMMENDED_TABLE_HEADER_FULL_OPTIMIZATION = 'react.components.ai_recomendded-table_header_full_optimization',
  AI_RECOMMENDED_TABLE_HEADER_BID = 'react.components.ai_recomendded-table_header_bid',
  AI_RECOMMENDED_TABLE_HEADER_BID_ONLY = 'react.components.ai_recomendded-table_header_bid_only',
  AI_RECOMMENDED_TABLE_HEADER_NONE = 'react.components.ai_recomendded-table_header_none',
  AI_RECOMMENDED_TABLE_SUMMARY_PRODUCT_GROUPS = 'react.components.ai_recomendded-table_summary_product_groups',
  AI_RECOMMENDED_TABLE_SUMMARY_CAMPAIGNS = 'react.components.ai_recomendded-table_summary_campaigns',
  AI_RECOMMENDED_TABLE_SUMMARY_AD_GROUPS = 'react.components.ai_recomendded-table_summary_ad_groups',

  PLAN_SWITCH_MODAL_UPGRADE_TITLE = 'react.components.plan_switch.modal.upgrade_title',
  PLAN_SWITCH_MODAL_DOWNGRADE_TITLE = 'react.components.plan_switch.modal.downgrade_title',
  PLAN_SWITCH_MODAL_AI_POWERED_PLAN = 'react.components.plan_switch.modal.ai_powered_plan',
  PLAN_SWITCH_MODAL_BASIC_PLAN = 'react.components.plan_switch.modal.basic_plan',
  PLAN_SWITCH_MODAL_FREE_TRIAL_STARTED = 'react.components.plan_switch.modal.free_trial_started',
  PLAN_SWITCH_MODAL_UPGRADE_BODY = 'react.components.plan_switch.modal.upgrade_body',
  PLAN_SWITCH_MODAL_DOWNGRADE_BODY = 'react.components.plan_switch.modal.downgrade_body',
  PLAN_SWITCH_MODAL_LEARN_MORE = 'react.components.plan_switch.modal.learn_more',
  PLAN_SWITCH_MODAL_CONNECTED_MERCHANTS = 'react.components.plan_switch.modal.connected_merchants',

  COMPASS_RANKED_BY_HIGH_PERFORMERS = 'react.pages.compass.ranked_by.high_performers',
  COMPASS_RANKED_BY_LOW_PERFORMERS = 'react.pages.compass.ranked_by.low_performers',
  COMPASS_RANKED_BY = 'react.pages.compass.ranked_by',
  COMPASS_FLYWHEEL_AI_POWERED_TOOLTIP_MESSAGE = 'react.pages.compass.flywheel_ai_powered_tooltip_message',
  COMPASS_FLYWHEEL_AI_ENABLED_TOOLTIP_MESSAGE = 'react.pages.compass.flywheel_ai_enabled_tooltip_message',
  COMPASS_FLYWHEEL_AI_DISABLED_TOOLTIP_MESSAGE = 'react.pages.compass.flywheel_ai_disabled_tooltip_message',
  COMPASS_FLYWHEEL_RANKED_BY_TOOLTIP_MESSAGE = 'react.pages.compass.ranked_by_tooltip',
  COMPASS_FLYWHEEL_REVENUE_SHARE = 'react.pages.compass.revenue_share',
  COMPASS_FLYWHEEL_NO_DATA_HEADLINE = 'react.pages.compass.no_data_headline',
  COMPASS_FLYWHEEL_NO_DATA_DESCRIPTION = 'react.pages.compass.no_data_description',

  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_HEADLINE = 'react.generic.http_error_response.toast.failure_headline',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION = 'react.generic.http_error_response.toast.failure_description',

  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_400 = 'react.generic.http_error_response.toast.failure_description_400',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_401 = 'react.generic.http_error_response.toast.failure_description_401',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_403 = 'react.generic.http_error_response.toast.failure_description_403',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_404 = 'react.generic.http_error_response.toast.failure_description_404',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_405 = 'react.generic.http_error_response.toast.failure_description_405',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_408 = 'react.generic.http_error_response.toast.failure_description_408',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_414 = 'react.generic.http_error_response.toast.failure_description_414',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_500 = 'react.generic.http_error_response.toast.failure_description_500',
  GENERIC_HTTP_ERROR_RESPONSE_TOAST_FAILURE_DESCRIPTION_503 = 'react.generic.http_error_response.toast.failure_description_503',

  GENERIC_SELECTED_COUNT = 'react.components.select_header.selected',
  GENERIC_TRENDS = 'react.generic.trends',
  GENERIC_PREV = 'react.generic.prev',
  GENERIC_TEMPLATES = 'react.generic.templates',
  GENERIC_QUERIES = 'react.generic.queries',

  GENERIC_SELECT_ALL = 'react.components.select.all',
  GENERIC_VIEW = 'react.common.view',
  GENERIC_THANK_YOU = 'react.common.thank_you',
  GENERIC_SNAPSHOT = 'react.common.snapshot',
  GENERIC_COMING_SOON = 'react.common.coming_soon',
  GENERIC_TOP = 'react.common.top',
  GENERIC_VIEW_BY = 'react.common.view_by',
  GENERIC_CREATE = 'react.common.create',
  GENERIC_CANCEL = 'react.common.cancel',
  GENERIC_YES_CHAMGE = 'react.common.yes_change',
  GENERIC_BOTTOM = 'react.common.bottom',
  GENERIC_SEE_MORE = 'react.common.see_more',
  GENERIC_LOAD_MORE = 'react.common.load_more',
  GENERIC_LOADING = 'react.common.loading',
  GENERIC_AI_THINKING = 'react.common.ai_thinking',
  GENERIC_ERROR_LOADING_TRY_AGAIN = 'react.common.error_loading_try_again',
  GENERIC_SUCCESSFULLY_LOADED = 'react.common.successfully_loaded',
  GENERIC_DETAILS = 'react.common.details',
  GENERIC_DONE = 'react.common.done',
  GENERIC_CONTINUE = 'react.common.continue',
  GENERIC_CONNECT = 'react.common.connect',
  GENERIC_LEARN_MORE = 'react.common.learn_more',
  GENERIC_LEARN_MORE_CAPS = 'react.generic.learn_more',
  GENERIC_TRIAL = 'react.common.trial',
  GENERIC_FREE = 'react.common.free',
  GENERIC_AMAZON = 'react.common.amazon',
  GENERIC_WALMART = 'react.common.walmart',
  GENERIC_NOT_AVAILABLE = 'react.common.not_available',
  GENERIC_SUCCESS = 'react.common.success',
  GENERIC_DOWNLOAD = 'react.common.download',
  GENERIC_EXPORT = 'react.common.export',
  GENERIC_CHANGE = 'react.common.change',
  GENERIC_CHOOSE = 'react.common.choose',
  GENERIC_APPLY = 'react.common.apply',
  GENERIC_EDITED = 'react.common.edited',
  GENERIC_EXTERNAL = 'react.common.external',
  GENERIC_REAUTHORIZE = 'react.common.re_authorize',
  GENERIC_TARGET_SEGMENT_BRAND = 'react.common.target_segment.brand',
  GENERIC_TARGET_SEGMENT_COMPTETITOR = 'react.common.target_segment.competitor',
  GENERIC_TARGET_SEGMENT_GENERIC = 'react.common.target_segment.generic',
  GENERIC_TARGET_SEGMENT_NO_TAG = 'react.common.target_segment.no_tag',
  GENERIC_SOMETHING_WENT_WRONG = 'react.common.something_went_wrong',
  GENERIC_ENTER_VALID_VALUE = 'react.common.enter_valid_value',
  GENERIC_NAME_ALREADY_EXISTS_WENT_WRONG = 'react.common.already_exists_wrong',
  GENERIC_SOMETHING_WENT_WRONG_KEYWORD = 'react.common.something_went_wrong_keyword_1000',
  GENERIC_PLEASE_TRY_AGAIN = 'react.common.please_try_again',
  GENERIC_AD_TYPE_SPONSORED_PRODUCTS = 'react.common.ad_type.sponsored_products',
  GENERIC_AD_TYPE_SPONSORED_PRODUCT = 'react.common.ad_type.sponsored_product',
  GENERIC_AD_TYPE_SHORT_SPONSORED_PRODUCTS = 'react.common.ad_type.short.sponsored_products',
  GENERIC_AD_TYPE_SPONSORED_BRANDS = 'react.common.ad_type.sponsored_brands',
  GENERIC_AD_TYPE_SPONSORED_BRAND = 'react.common.ad_type.sponsored_brand',
  GENERIC_AD_TYPE_SHORT_SPONSORED_BRANDS = 'react.common.ad_type.short.sponsored_brands',
  GENERIC_AD_TYPE_SHORT_SPONSORED_BRAND = 'react.common.ad_type.short.sponsored_brand',
  GENERIC_AD_TYPE_SPONSORED_BRANDS_VIDEO = 'react.common.ad_type.sponsored_brands_video',
  GENERIC_AD_TYPE_SHORT_SPONSORED_BRANDS_VIDEO = 'react.common.ad_type.short.sponsored_brands_video',
  GENERIC_AD_TYPE_SPONSORED_DISPLAY = 'react.common.ad_type.sponsored_display',
  GENERIC_AD_TYPE_SHORT_SPONSORED_DISPLAY = 'react.common.ad_type.short.sponsored_display',
  GENERIC_AD_TYPE_SEARCH_BRAND_AMPLIFIER = 'react.common.ad_type.search_brand_amplifier',
  GENERIC_AD_TYPE_SHORT_SEARCH_BRAND_AMPLIFIER = 'react.common.ad_type.short.search_brand_amplifier',
  GENERIC_AD_TYPE_SPONSORED_VIDEO = 'react.common.ad_type.sponsored_video',
  GENERIC_AD_TYPE_SHORT_SPONSORED_VIDEO = 'react.common.ad_type.short.sponsored_video',
  NO_MERCHANTS_SELECTED = 'react.common.no_merchants_selected',
  MISSING_DATA_SUGGESTION = 'react.common.missing_data.suggestion',
  NO_MATCHES_FOUND = 'react.common.no_matches_found',
  NO_MATCHES_SUGGESTION = 'react.common.no_matches.suggestion',
  GENERIC_MERCHANT_COUNT = 'react.common.merchants_count',
  GENERIC_FEEDBACK = 'react.generic.feedback',
  GENERIC_AUTO = 'react.generic.auto',
  GENERIC_MANUAL = 'react.generic.manual',
  GENERIC_OTHERS = 'react.generic.others',
  GENERIC_VIEW_CAMPAIGNS = 'react.generic.view_campaigns',
  GENERIC_ADVERTISING_GOAL_COLUMN = 'react.generic.advertising_goal.column',
  GENERIC_ADVERTISING_GOAL_PRODUCT_LAUNCH = 'react.generic.advertising_goal.product_launch',
  GENERIC_ADVERTISING_GOAL_PRODUCT_LAUNCH_DESCRIPTION = 'react.generic.advertising_goal.product_launch.description',
  GENERIC_ADVERTISING_GOAL_GROW = 'react.generic.advertising_goal.grow',
  GENERIC_ADVERTISING_GOAL_GROW_DESCRIPTION = 'react.generic.advertising_goal.grow.description',
  GENERIC_ADVERTISING_GOAL_PROFIT = 'react.generic.advertising_goal.profit',
  GENERIC_ADVERTISING_GOAL_PROFIT_DESCRIPTION = 'react.generic.advertising_goal.profit.description',
  GENERIC_ADVERTISING_GOAL_ROAS = 'react.generic.advertising_goal.roas',
  GENERIC_ADVERTISING_GOAL_ROAS_DESCRIPTION = 'react.generic.advertising_goal.roas.description',
  GENERIC_ADVERTISING_GOAL_LIQUIDATE = 'react.generic.advertising_goal.liquidate',
  GENERIC_ADVERTISING_GOAL_LIQUIDATE_DESCRIPTION = 'react.generic.advertising_goal.liquidate.description',
  GENERIC_ADVERTISING_GOAL_TEMP_OVERIDE = 'react.generic.advertising_goal.temp_override',
  GENERIC_SMART_STRUCTURE_COLUMN = 'react.generic.smart_structure.column',
  GENERIC_SMART_STRUCTURE_BASIC = 'react.generic.smart_structure.basic',
  GENERIC_SMART_STRUCTURE_INTERMEDIATE = 'react.generic.smart_structure.intermediate',
  GENERIC_SMART_STRUCTURE_ADVANCED = 'react.generic.smart_structure.advanced',
  GENERIC_SMART_STRUCTURE_CUSTOM = 'react.generic.smart_structure.custom',
  GENERIC_SMART = 'react.generic.smart',
  GENERIC_ALL = 'react.generic.all',
  GENERIC_LEARN_ABOUT_SMART_GOALS = 'react.generic.learn_about_smart_goals',

  CONNECT_SELLING_PARTNER = 'react.flash.connect_selling_partner',
  CONNECT_SELLING_PARTNER_MESSAGE = 'react.flash.connect_selling_partner.message',
  ADD_PRODUCTS_DATA = 'react.flash.add_products_data',
  NAME_YOUR_MERCHANT = 'react.flash.name_your_merchant',
  MERCHANT_SELLER_TYPE = 'react.flash.select_seller_type',
  MERCHANT_NAME_INFO = 'react.flash.merchant_name_info',
  WHAT_IS_THE_DIFFERENCE = 'react.flash.what_is_the_difference',
  WALMART_ADMIN_ACCESS = 'react.flash.walmart_admin_access',
  WALMART_ADMIN_ACCCES_SUB_TEXT = 'react.flash.walmart_admin_access_sub_text',
  ADD_SELLER_ID = 'react.flash.add_seller_id',
  ADD_CLIENT_ID = 'react.flash.add_client_id',
  ADD_CLIENT_SECRET = 'react.flash.add_client_secret',
  HELP_ME_FIND_DETAILS = 'react.flash.help_me_find_details',
  FLYWHEEL_AUTHORIZE_TEXT = 'react.flash.flywheel_authorize_text',
  FLYWHEEL_AUTHORIZE_TEXT_INFO = 'react.flash.flywheel_authorize_text_info',
  FLYWHEEL_AUTHORIZE_HELP_LINK = 'react.flash.flywheel_authorize_help_link',
  FLYWHEEL_AUTHORIZE_ADD_PRODUCT_DATA_TEXT_INFO = 'react.flash.flywheel_authorize.add_product_data_text_info',
  FLYWHEEL_AUTHORIZE_ADD_PRODUCT_DATA_HELP_LINK = 'react.flash.flywheel_authorize.add_product_data_help_link',
  FLYWHEEL_CHOOSE_SELLER_TYPE = 'react.flash.flywheel_choose_your_seller_type',
  FLYWHEEL_SELLER_TYPE_DSV = 'react.flash.seller_type_dsv',
  FLYWHEEL_SELLER_TYPE_WAREHOUSE = 'react.flash.seller_type_warehouse',
  FLYWHEEL_ADD_ALL_DISTRIBUTOR_IDS = 'react.flash.flywheel_add_all_your_distributor_ids',
  FLYWHEEL_ADD_ANOTHER_DISTRIBUTOR = 'react.flash.flywheel_add_another_distributor',
  ADD_SUPPLIER_ID = 'react.flash.add_supplier_id',
  ADD_CONSUMER_ID = 'react.flash.add_consumer_id',
  ADD_PRIVATE_KEY = 'react.flash.add_private_key',
  WALMART_LOGIN_FAILURE = 'react.flash.walmart_login_failure',
  WALMART_LOGIN_FAILURE_DISCLAIMER = 'react.flash.walmart_login_failure_disclaimer',
  FLASH_MODALS_AMAZON_MODAL_HEADER = 'react.flash_modals.amazon.initial_connection.header',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_HEADER = 'react.flash_modals.amazon.initial_connection.header',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_ADS_CARD_HEADER = 'react.flash_modals.amazon.initial_connection.ads_card.header',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_ADS_CARD_SUBHEADER = 'react.flash_modals.amazon.initial_connection.ads_card.subheader',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_PRODUCTS_CARD_HEADER = 'react.flash_modals.amazon.initial_connection.products_card.header',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_PRODUCTS_CARD_SUBHEADER = 'react.flash_modals.amazon.initial_connection.products_card.subheader',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_WARNING_HEADER = 'react.flash_modals.amazon.initial_connection.warning.header',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_WARNING_LINK_TEXT = 'react.flash_modals.amazon.initial_connection.warning.link_text',

  FLASH_MODALS_AMAZON_CONNECTION_HEADER = 'react.flash_modals.amazon.connection.header',
  FLASH_MODALS_AMAZON_CONNECTION_CONTENT_SUBHEADER = 'react.flash_modals.amazon.connection_content.subheader',
  FLASH_MODALS_WALMART_MODAL_HEADER = 'react.flash_modals.walmart.modal.header',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_SUBHEADER = 'react.flash_modals.amazon.initial_connection.subheader',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_ADS_CARD_HEADER = 'react.flash_modals.amazon.initial_connection.ads_card.header',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_ADS_CARD_SUBHEADER = 'react.flash_modals.amazon.initial_connection.ads_card.subheader',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_PRODUCTS_CARD_HEADER = 'react.flash_modals.amazon.initial_connection.products_card.header',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_PRODUCTS_CARD_SUBHEADER = 'react.flash_modals.amazon.initial_connection.products_card.subheader',

  FLASH_MODALS_WALMART_INITIAL_CONNECTION_CONTENT_SUBHEADER = 'react.flash_modals.walmart.initial_connection.subheader',
  FLASH_MODALS_WALMART_INITIAL_CONNECTION_CONTENT_ADS_CARD_HEADER = 'react.flash_modals.walmart.initial_connection.ads_card.header',
  FLASH_MODALS_WALMART_INITIAL_CONNECTION_CONTENT_ADS_CARD_SUBHEADER = 'react.flash_modals.walmart.initial_connection.ads_card.subheader',
  FLASH_MODALS_WALMART_INITIAL_CONNECTION_CONTENT_PRODUCTS_CARD_HEADER = 'react.flash_modals.walmart.initial_connection.products_card.header',
  FLASH_MODALS_WALMART_INITIAL_CONNECTION_CONTENT_PRODUCTS_CARD_SUBHEADER = 'react.flash_modals.walmart.initial_connection.products_card.subheader',

  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_WARNING_HEADER = 'react.flash_modals.amazon.initial_connection.warning.header',
  FLASH_MODALS_WALMART_INITIAL_CONNECTION_CONTENT_WARNING_HEADER = 'react.flash_modals.walmart.initial_connection.warning.header',
  FLASH_MODALS_AMAZON_INITIAL_CONNECTION_CONTENT_WARNING_LINK_TEXT = 'react.flash_modals.amazon.initial_connection.warning.link_text',
  FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_SUBHEADER = 'react.flash_modals.amazon.no_merchants.header',
  FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_WARNING_HEADER = 'react.flash_modals.amazon.no_merchants.warning.header',
  FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_WARNING_SUBHEADER = 'react.flash_modals.amazon.no_merchants.warning.subheader',
  FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_TRY_DIFFERENT_ACCOUNT = 'react.flash_modals.amazon.no_merchants.warning.try_different_account',
  FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_REQUEST_ACCESS_BUTTON = 'react.flash_modals.amazon.no_merchants.request_access',
  FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_REQUESTING_ACCESS_TITLE = 'react.flash_modals.amazon.no_merchants.request_access',
  FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_REQUESTING_ACCESS_DETAILS = 'react.flash_modals.amazon.no_merchants.requesting_access_details',
  FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_UNAVAILABLE_MERCHANTS = 'react.flash_modals.amazon.no_merchants.unavailable_merchants_to_connect_title',
  FLASH_MODALS_AMAZON_NO_MERCHANTS_CONTENT_UNAVAILABLE_MERCHANTS_SUBHEADER = 'react.flash_modals.amazon.no_merchants.unavailable_merchants_to_connect_details',
  FLASH_MODALS_AMAZON_AUTHORIZE_CONTENT_WARNING_HEADER = 'react.flash_modals.amazon.authorize.warning.header',
  FLASH_MODALS_AMAZON_AUTHORIZE_CONTENT_WARNING_SUBHEADER = 'react.flash_modals.amazon.authorize.warning.subheader',
  FLASH_MODALS_AMAZON_AUTHORIZE_CONTENT_HEADER = 'react.flash_modals.amazon.authorize.header',
  FLASH_MODALS_AMAZON_AUTHORIZE_CONTENT_SUBHEADER = 'react.flash_modals.amazon.authorize.subheader',
  FLASH_MODALS_AMAZON_AUTHORIZE_CONNECT_ADVERTISING = 'react.flash_modals.amazon.connect_advertising',
  FLASH_MODALS_AMAZON_AUTHORIZE_CONNECT_BUTTON = 'react.flash_modals.amazon.connect_button',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_HEADER = 'react.flash_modals.walmart.authorize_account.content.header',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_DESCRIPTION = 'react.flash_modals.walmart.authorize_account.content.description',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_SEND_AUTH_BTN = 'react.flash_modals.walmart.authorize_account.content.send_auth_btn',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_SHOW_ME_HOW = 'react.flash_modals.walmart.authorize_account.content.show_me_how',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_CONFIRM_TOOLTIP = 'react.flash_modals.walmart.authorize_account.content.confirm_tooltip',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_CONFIRM_CHECKBOX = 'react.flash_modals.walmart.authorize_account.content.confirm',
  FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_CONTENT_HEADER = 'react.flash_modals.walmart.connect_data.content.header',
  FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_CONTENT_SUB_HEADER = 'react.flash_modals.walmart.connect_data.content.sub_header',
  FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_ADVERTISER_ID = 'react.flash_modals.walmart.connect_data.content.advertiser_id',
  FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_SELLER_ID = 'react.flash_modals.walmart.connect_data.content.seller_id',
  FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_SUPPLIER_ID = 'react.flash_modals.walmart.connect_data.content.supplier_id',
  FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_NOTE_SELLER_LINK = 'react.flash_modals.walmart.connect_data.note_seller_link',
  FLASH_MODALS_WALMART_CONNECT_DATA_MODAL_NOTE_SUPPLIER_LINK = 'react.flash_modals.walmart.connect_data.note_supplier_link',
  FLASH_MODALS_WALMART_AUTHORIZE_ERROR_MESSAGE_MERCHANT_OWNED_ANOTHER_ACCOUNT = 'react.flash_modals.walmart.authorize.error_message.merchant_owned.another_account',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_API_ERROR_MESSAGE = 'react.flash_modals.walmart.authorize_account.content.api_error_message',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_TRY_AGAIN = 'react.flash_modals.walmart.authorize_account.content.try_again',
  FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_ADS_PRIMARY_TEXT = 'react.flash_modals.walmart.authorize_success.content.ads.primary_text',
  FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_ADS_SECONDARY_TEXT = 'react.flash_modals.walmart.authorize_success.content.ads.secondary_text',
  FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_PRODUCTS_PRIMARY_TEXT = 'react.flash_modals.walmart.authorize_success.content.products.primary_text',
  FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_PRODUCTS_SECONDARY_TEXT = 'react.flash_modals.walmart.authorize_success.content.products.secondary_text',
  FLASH_MODALS_AMAZON_AUTHORIZE_FAILED_CONTENT_WARNING_HEADER = 'react.flash_modals.amazon.authorize.failed.warning.header',
  FLASH_MODALS_AMAZON_AUTHORIZE_FAILED_CONTENT_WARNING_SUBHEADER = 'react.flash_modals.amazon.authorize.failed.warning.subheader',
  FLASH_MODALS_AMAZON_AUTHORIZE_FAILED_CONTENT_HEADER = 'react.flash_modals.amazon.authorize.failed.header',
  FLASH_MODALS_AMAZON_AUTHORIZE_FAILED_CONTENT_SUBHEADER = 'react.flash_modals.amazon.authorize.failed.subheader',
  FLASH_MODALS_AMAZON_AUTHORIZE_FAILED_CONTENT_HELP = 'react.flash_modals.amazon.authorize.failed.help',
  FLASH_MODALS_WALMART_3P_SELLERS = 'react.flash_modals.walmart.three_p_sellers',
  FLASH_MODALS_WALMART_1P_SUPPLIER = 'react.flash_modals.walmart.one_p_supplier',
  FLASH_MODALS_BTN_CLOSE_AND_EXIT = 'react.flash_modals.btn.close_and_exit',
  FLASH_MODALS_BTN_CONNECT_MORE_MERCHANTS = 'react.flash_modals.btn.connect_more_merchants',
  FLASH_MODALS_AMAZON_BTN_AUTHORIZE_PRODUCTS = 'react.flash_modals.amazon.btn.authorize_products',
  FLASH_MODALS_BTN_AUTHORIZE_PRODUCTS = 'react.flash_modals.btn.authorize_products',
  FLASH_MODALS_BTN_CONNECT_SUPPLIER_DATA = 'react.flash_modals.btn.connect_supplier_data',
  FLASH_MODALS_BTN_CONNECT_MARKETPLACE_DATA = 'react.flash_modals.btn.connect_marketplace_data',
  FLASH_MODALS_AMAZON_TABLE_REGION = 'react.flash_modals.amazon.table.region',
  FLASH_MODALS_AMAZON_TABLE_MERCHANT = 'react.flash_modals.amazon.table.merchant',
  FLASH_MODALS_AMAZON_AUTHORIZE_CONTENT_WARNING_ALREADY_CONNECT_HEADER = 'react.flash_modals.amazon.connection_content.already.connect.header',
  FLASH_MODALS_AMAZON_AUTHORIZE_CONTENT_ALREADY_CONNECT_BUTTON_DIFFERENT_ACCOUNT = 'react.flash_modals.amazon.connection_content.already.connect.try_different_account',
  FLASH_MODALS_MERCHANT_DATA_CONNECTION_STATUS_TABLE_FOOTER = 'react.flash_modals.merchat_status_data.footer_message',
  FLASH_MODALS_MERCHANT_DATA_CONNECTION_STATUS_TABLE_HEADER_COUNTRY = 'react.flash.merchant_details_table.country',
  FLASH_MODALS_MERCHANT_DATA_CONNECTION_STATUS_TABLE_HEADER_ID = 'react.flash.merchant_details_table.id',
  FLASH_MODALS_MERCHANT_DATA_CONNECTION_STATUS_TABLE_HEADER_ADVERTISING = 'react.flash.merchant_details_table.advertising',
  FLASH_MODALS_MERCHANT_DATA_CONNECTION_STATUS_TABLE_HEADER_PRODUCTS = 'react.flash.merchant_details_table.products',
  FLASH_MODALS_AMAZON_AUTHORIZATION_SUCCESS_ADS_PRIMARY_TEXT = 'react.flash_modals.amazon.authorize_success.content.ads.primary_text',
  FLASH_MODALS_AMAZON_AUTHORIZATION_SUCCESS_ADS_SECONDARY_TEXT = 'react.flash_modals.amazon.authorize_success.content.ads.secondary_text',
  FLASH_MODALS_AMAZON_AUTHORIZATION_SUCCESS_PRODUCTS_PRIMARY_TEXT = 'react.flash_modals.amazon.authorize_success.content.products.primary_text',
  FLASH_MODALS_AMAZON_AUTHORIZATION_SUCCESS_PRODUCTS_SECONDARY_TEXT = 'react.flash_modals.amazon.authorize_success.content.products.secondary_text',
  FLASH_MODALS_AUTHORIZE_TEXT = 'react.flash_modals.flywheel_authorize_text',
  FLASH_MODALS_BTN_CANCEL_CONNECT_PRODUCTS_DATA = 'react.flash_modals.btn.cancel_connect_products_data',
  FLASH_MODALS_WALMART_MERCHANT_TYPE_1P_TOOLTIP_TEXT = 'react.flash_modals.walmart.merchant_type.1p.tooltip_text',
  FLASH_MODALS_WALMART_PRODUCT_CONNECT_3P_SELLER = 'react.flash_modals.walmart.product_connect.3p_seller',
  FLASH_MODALS_WALMART_PRODUCT_CONNECT_1P_SUPPLIER = 'react.flash_modals.walmart.product_connect.1p_supplier',
  FLASH_MODALS_WALMART_PRODUCT_CONNECT_1P_VENDOR = 'react.flash_modals.walmart.product_connect.1p_vendor',

  RECOMMENDATIONS_CONFIRMATION_POPUP_COULUMN_MERCHANT = 'react.recommendations.confirmation_popup_column_merchant',
  RECOMMENDATIONS_CONFIRMATION_POPUP_COULUMN_SP = 'react.recommendations.confirmation_popup_column_sp',
  RECOMMENDATIONS_CONFIRMATION_POPUP_COULUMN_SB = 'react.recommendations.confirmation_popup_column_sb',
  RECOMMENDATIONS_CONFIRMATION_POPUP_COULUMN_SD = 'react.recommendations.confirmation_popup_column_sd',
  RECOMMENDATIONS_CONFIRMATION_POPUP_COULUMN_COUNT = 'react.recommendations.confirmation_popup_column_count',
  RECOMMENDATIONS_CONFIRMATION_POPUP_COULUMN_NA = 'react.recommendations.confirmation_popup_column_NA',
  RECOMMENDATIONS_CONFIRMATION_POPUP_BIDDING_HEADER = 'react.recommendations.confirmation_popup_bidding_header',
  RECOMMENDATIONS_CONFIRMATION_POPUP_BIDDING_CONTENT_HEADER = 'react.recommendations.confirmation_popup_bidding_content_header',
  RECOMMENDATIONS_CONFIRMATION_POPUP_BIDDING_CONTENT_COPY = 'react.recommendations.confirmation_popup_bidding_content_copy',
  RECOMMENDATIONS_CONFIRMATION_POPUP_BIDDING_FOOTER_COPY = 'react.recommendations.confirmation_popup_bidding_footer_copy',
  RECOMMENDATIONS_CONFIRMATION_POPUP_BIDDING_FOOTER_SUPPORT_COPY = 'react.recommendations.confirmation_popup_bidding_footer_support_copy',
  RECOMMENDATIONS_CONFIRMATION_POPUP_ENABLE_ALL_HEADER = 'react.recommendations.confirmation_popup_enable_all_header',
  RECOMMENDATIONS_CONFIRMATION_POPUP_ENABLE_ALL_CONTENT = 'react.recommendations.confirmation_popup_enable_all_content',

  AO_AI_TRE_UPLOAD = 'react.ao.ai.tre_upload.button.text',

  EVENT_LOG_PAGE_TITLE = 'react.pages.event_log.title',
  EVENT_LOG_PAGE_REPORT = 'react.event_page.report',
  EVENT_LOG_PAGE_REPORT_DESCRIPTION = 'react.event_page.report.description',
  EVENT_LOG_PAGE_GENERATE_REPORT = 'react.event_page.generate_report',
  EVENT_LOG_PAGE_GENERATING_REPORT = 'react.event_page.generating_report',
  EVENT_LOG_PAGE_GENERATING_REPORT_DESCRIPTION = 'react.event_page.generating_report.description',
  EVENT_LOG_PAGE_DOWNLOAD_FILE_FORMAT = 'react.event_page.download_file_format',

  RECOMMENDATIONS_HEADER = 'react.components.recommendations.header',
  CAMPAIGN_FLEXIBILITY_TITLE = 'react.pages.campaignFlexibility.title',
  CAMPAIGN_FLEXIBILITY_STEPPER_SETUPCAMPAIGNS = 'react.pages.campaignFlexibility.dashboard.stepper.setupCampaigns',
  CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS = 'react.pages.campaignFlexibility.dashboard.stepper.addProducts',
  CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH = 'react.pages.campaignFlexibility.dashboard.stepper.preview_and_launch',
  CAMPAIGN_FLEXIBILITY_STEPPER_SETUPCAMPAIGNS_HEADER = 'react.pages.campaignFlexibility.dashboard.stepper.setupCampaigns.header',
  CAMPAIGN_FLEXIBILITY_STEPPER_SETUPCAMPAIGNS_SUBHEADER = 'react.pages.campaignFlexibility.dashboard.stepper.setupCampaigns.subheader',
  CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_HEADER = 'react.pages.campaignFlexibility.dashboard.stepper.addProducts.header',
  CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_SUBHEADER = 'react.pages.campaignFlexibility.dashboard.stepper.addProducts.subheader',
  CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_HEADER = 'react.pages.campaignFlexibility.dashboard.stepper.preview_and_launch.header',
  CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_SUBHEADER = 'react.pages.campaignFlexibility.dashboard.stepper.preview_and_launch.subheader',
  CAMPAIGN_FLEXIBILITY_STEPPER_SETUPCAMPAIGNS_BUTTON = 'react.pages.campaignFlexibility.dashboard.stepper.setupCampaigns.button',
  CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS_BUTTON = 'react.pages.campaignFlexibility.dashboard.stepper.addProducts.button',
  CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH_BUTTON = 'react.pages.campaignFlexibility.dashboard.stepper.preview_and_launch.button',
  CAMPAIGN_FLEXIBILITY_SELECT_MERCHANT_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.select_merchant.header',
  CAMPAIGN_FLEXIBILITY_SELECT_MERCHANT_PLACEHOLDER_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.select_merchant.placeholder.header',
  CAMPAIGN_FLEXIBILITY_SELECT_MERCHANT_PLACEHOLDER = 'react.pages.campaignFlexibility.dashboard.step_1.select_merchant.placeholder',
  CAMPAIGN_FLEXIBILITY_SELECT_MERCHANT_EMPTY_BUTTON_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_merchant.empty.button_title',
  CAMPAIGN_FLEXIBILITY_SELECT_MERCHANT_ERROR_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.select_merchant.error.header',
  CAMPAIGN_FLEXIBILITY_SELECT_MERCHANT_ERROR_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.select_merchant.error.description',
  CAMPAIGN_FLEXIBILITY_SELECT_MERCHANT_TOAST_MESSAGE_ERROR = 'react.pages.campaignFlexibility.dashboard.step_1.select_merchant.toast_message.failed',
  CAMPAIGN_FLEXIBILITY_SELECT_DATE_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.select_date.header',
  CAMPAIGN_FLEXIBILITY_SELECT_DATE_HELP_TEXT = 'react.pages.campaignFlexibility.endDate.help.title',
  CAMPAIGN_FLEXIBILITY_SELECT_DATE_HELP_DESCRIPTION = 'react.pages.campaignFlexibility.endDate.help.description',
  CAMPAIGN_FLEXIBILITY_SELECT_DATE_HEADER_STEP3 = 'react.pages.campaignFlexibility.dashboard.step_3.select_date.header',
  CAMPAIGN_FLEXIBILITY_SELECT_DATE_CHECKBOXLABEL = 'react.pages.campaignFlexibility.dashboard.step_1.select_date.checkboxlabel',
  CAMPAIGN_FLEXIBILITY_SELECT_DATE_STARTDATELABEL = 'react.pages.campaignFlexibility.dashboard.step_1.select_date.startdatelabel',
  CAMPAIGN_FLEXIBILITY_SELECT_DATE_ENDDATELABEL = 'react.pages.campaignFlexibility.dashboard.step_1.select_date.enddatelabel',
  CAMPAIGN_FLEXIBILITY_SELECT_CURRENCY_HEADER = 'react.pages.campaignFlexibility.dashboard.step_2.select_currency.header',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.header',

  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_SELECT_PRODUCT_GROUPING_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.campaign_structure.select_product_grouping.header',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_SELECT_TARGETTING_STRATEGY_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.campaign_structure.select_targetting_strategy.header',
  CAMPAIGN_FLEXIBILITY_ADGROUP_STRUCTURE_SELECT_PRODUCT_GROUPING_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.adgroup_structure.select_product_grouping.header',
  CAMPAIGN_FLEXIBILITY_ADGROUP_STRUCTURE_SELECT_TARGETTING_STRATEGY_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.adgroup_structure.select_targetting_strategy.header',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_SELECT_PAT_AUTO_TARGETTING_STRATEGY_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.campaign_structure.select_pat_auto_targetting_strategy.header',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_SELECT_PAT_MANUAL_TARGETTING_STRATEGY_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.campaign_structure.select_pat_manual_targetting_strategy.header',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_SELECT_PAT_TARGETTING_STRATEGY_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.campaign_structure.select_pat_targetting_strategy.header',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_SELECT_PAT_TARGETTING_STRATEGY_FORM_TOGGLE_LABEL = 'react.pages.campaignFlexibility.dashboard.step_1.campaign_structure.select_pat_targetting_strategy.form_toggle_label',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_PAT_MANUAL_TARGETTING = 'react.pages.campaignFlexibility.dashboard.pat_manual_targetting',
  CAMPAIGN_FLEXIBILITY_SELECT_CAMPAIGN_STRUCTURE_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.select_campaign_structure.header',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.header',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_SUBTITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.subtitle',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SETUP_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_setup.header',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SETUP_SUBTITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_setup.subtitle',
  CAMPAIGN_FLEXIBILITY_BANNER_ADVANCED_HEADING = 'react.compass.campaignFlexibility.dashboard.step_3.advanced_banner.heading',
  CAMPAIGN_FLEXIBILITY_BANNER_ADVANCED_MAIN_TEXT = 'react.compass.campaignFlexibility.dashboard.step_3.advanced_banner.main_text',
  CAMPAIGN_FLEXIBILITY_BANNER_ADVANCED_SUB_TEXT = 'react.compass.campaignFlexibility.dashboard.step_3.advanced_banner.sub_text',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_CARD_CAMPAIGN = 'react.compass.campaignFlexibility.dashboard.step_3.card.preview_and_launch.campaigns',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_CARD_ADGROUP = 'react.compass.campaignFlexibility.dashboard.step_3.card.preview_and_launch.adgroup',
  CAMPAIGN_FLEXIBILITY_SKU_DETAILS_MODAL_HEADING = 'react.components.campaignFlexibility.dashboard.step_3.sku_details_modal.heading',
  CAMPAIGN_FLEXIBILITY_LOADINGSCREEN_HEADING = 'react.components.campaignFlexibility.dashboard.loadingScreen.heading',
  CAMPAIGN_FLEXIBILITY_LOADINGSCREEN_DESCRIPTION = 'react.components.campaignFlexibility.dashboard.loadingScreen.description',
  CAMPAIGN_FLEXIBILITY_KEYWORDS_MODAL_HEADING = 'react.components.campaignFlexibility.dashboard.step_3.keywords_modal.heading',
  CAMPAIGN_FLEXIBILITY_PRODUCTS_SEARCH_PLACEHOLDER_PARENT = 'react.pages.campaignFlexibility.dashboard.step_2.products_table.search.placeholder.parent',
  CAMPAIGN_FLEXIBILITY_PRODUCTS_SEARCH_PLACEHOLDER_SKU = 'react.pages.campaignFlexibility.dashboard.step_2.products_table.search.placeholder.sku',
  CAMPAIGN_FLEXIBILITY_PRODUCTS_SEARCH_PLACEHOLDER_CATEGORY = 'react.pages.campaignFlexibility.dashboard.step_2.products_table.search.placeholder.category',
  CAMPAIGN_FLEXIBILITY_PRODUCTS_SEARCH_PLACEHOLDER_SHELF = 'react.pages.campaignFlexibility.dashboard.step_2.products_table.search.placeholder.shelf',
  CAMPAIGN_FLEXIBILITY_PRODUCTS_SEARCH_PLACEHOLDER_BASE_ITEM = 'react.pages.campaignFlexibility.dashboard.step_2.products_table.search.placeholder.baseItem',
  CAMPAIGN_FLEXIBILITY_PRODUCTS_SEARCH_PLACEHOLDER_BASE_ITEM_GROUP = 'react.pages.campaignFlexibility.dashboard.step_2.products_table.search.placeholder.baseItemGroup',
  CAMPAIGN_FLEXIBILITY_PRODUCTS_ROW_STATUS = 'react.components.campaignFlexibility.products.row.status',
  CAMPAIGN_FLEXIBILITY_SUCCESS_BANNER_HEADER = 'react.compass.campaignFlexibility.dashboard.step_4.success.banner.header',
  CAMPAIGN_FLEXIBILITY_SUCCESS_BANNER_SUB_HEADER = 'react.compass.campaignFlexibility.dashboard.step_4.success.banner.sub_header',
  CAMPAIGN_FLEXIBILITY_SUCCESS_BANNER_SUBTITLELABLE = 'react.compass.campaignFlexibility.dashboard.step_4.success.banner.subTitle_lable',
  CAMPAIGN_FLEXIBILITY_SUCCESS_BANNER_SUBTITLELABLE_MORE = 'react.compass.campaignFlexibility.dashboard.step_4.success.banner.subTitle_lable_More',
  CAMPAIGN_FLEXIBILITY_SUCCESS_BANNER_SUBTITLELABLE_NEXTLINE = 'react.compass.campaignFlexibility.dashboard.step_4.success.banner.subTitle_lable_NextLine_More',
  CAMPAIGN_FLEXIBILITY_SUCCESS_BANNER_STARTDATELABEL = 'react.pages.campaignFlexibility.success.startdatelabel',
  CAMPAIGN_FLEXIBILITY_SUCCESS_BANNER_ENDDATELABEL = 'react.pages.campaignFlexibility.success.enddatelabel',
  CAMPAIGN_FLEXIBILITY_SUCCESS_BANNER_MERCHANT = 'react.pages.campaignFlexibility.success.merchant',
  CAMPAIGN_FLEXIBILITY_SUCCESS_BANNER_PRODUCT_GROUPING = 'react.pages.campaignFlexibility.success.product_grouping',
  CAMPAIGN_FLEXIBILITY_SUCCESS_BANNER_CAMPAIGNSTRUCTURE = 'react.pages.campaignFlexibility.success.campaign_structure',
  CAMPAIGN_FLEXIBILITY_SUCCESS_BANNER_NO_END_DATE = 'react.pages.campaignFlexibility.success.no_end_date',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOMANUAL = 'react.campaignFlexibility.CampaignStructure.auto_manual',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOBRANDNONBRAND = 'react.campaignFlexibility.CampaignStructure.auto_brand_nonbrand',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTOBRANDCOMPETITORGENERIC = 'react.campaignFlexibility.CampaignStructure.auto_brand_competitor_generic',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_PRODUCT_ADGROUP_PRODUCTGROUPING_PARENT = 'react.campaignFlexibility.campaign.product.adGroupproductgrouping.parent',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_PRODUCT_ADGROUP_PRODUCTGROUPING_BASE_ITEM_ID = 'react.campaignFlexibility.campaign.product.adGroupproductgrouping.baseItemId',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_PRODUCT_ADGROUP_PRODUCTGROUPING_ITEM_ID = 'react.campaignFlexibility.campaign.product.adGroupproductgrouping.ItemId',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_PRODUCT_ADGROUP_PRODUCTGROUPING_CATEGORY = 'react.campaignFlexibility.campaign.product.adGroupproductgrouping.category',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_PRODUCT_ADGROUP_PRODUCTGROUPING_SKU = 'react.campaignFlexibility.campaign.product.adGroupproductgrouping.sku',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_PRODUCT_ADGROUP_PRODUCTGROUPING_SHELF = 'react.campaignFlexibility.campaign.product.adGroupproductgrouping.shelf',
  CAMPAIGN_FLEXIBILITY_CURRENT_CAMPAIGN_MODAL_HEADER = 'react.campaignFlexibility.dashboard.step_3.current_campaign_modal.header',
  CAMPAIGN_FLEXIBILITY_CURRENT_CAMPAIGN_MODAL_SUBHEADER = 'react.campaignFlexibility.dashboard.step_3.current_campaign_modal.subheader',
  CAMPAIGN_FLEXIBILITY_CURRENT_CAMPAIGN_MODAL_NODATA = 'react.campaignFlexibility.dashboard.step_3.current_campaign_modal.noData',
  CAMPAIGN_FLEXIBILITY_CURRENT_KEYWORDS_MODAL_NODATA = 'react.campaignFlexibility.dashboard.step_3.current_keywords_modal.noData',
  CAMPAIGN_FLEXIBILITY_BANNER_WALMART_ADVANCED_MAIN_TEXT = 'react.compass.campaignFlexibility.dashboard.step_3.walmart.advanced_banner.main_text',
  CAMPAIGN_FLEXIBILITY_BANNER_WALMART_ADVANCED_SUB_TEXT = 'react.compass.campaignFlexibility.dashboard.step_3.walmart.advanced_banner.sub_text',

  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTO_SINGLE_PAT = 'react.campaignFlexibility.CampaignStructure.auto_SinglePAT',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTO_COMPETITOR_CATEGORY = 'react.campaignFlexibility.CampaignStructure.auto_CompetitorPATCategoryPAT',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_AUTO_BRAND_COMPETITOR_CATEGORY = 'react.campaignFlexibility.CampaignStructure.auto_BrandPATCompetitorPATCategoryPAT',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_SINGLE_PAT = 'react.campaignFlexibility.CampaignStructure.singlePAT',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_COMPETITOR_CATEGORY = 'react.campaignFlexibility.CampaignStructure.competitorPATCategoryPAT',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_STRUCTURE_BRAND_COMPETITOR_CATEGORY = 'react.campaignFlexibility.CampaignStructure.brandPATCompetitorPATCategoryPAT',

  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_PARENT_PRODUCT_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.parent_product.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_PARENT_PRODUCT_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.parent_product.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_SKU_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.sku.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_SKU_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.sku.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_CATEGORY_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.category.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_CATEGORY_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.category.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_ITEM_ID_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.item_id.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_ITEM_ID_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.item_id.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_SHELF_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.shelf.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_SHELF_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.shelf.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_BASE_ITEM_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.base_item.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_BASE_ITEM_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.base_item.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_BASE_ITEM_GROUPING_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.base_item_grouping.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PRODUCT_GROUPING_BASE_ITEM_GROUPING_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_product_grouping.base_item_grouping.description',

  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_PARENT_PRODUCT_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.parent_product.title',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_PARENT_PRODUCT_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.parent_product.description',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_SKU_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.sku.title',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_SKU_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.sku.description',

  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_ITEM_ID_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.item_id.title',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_ITEM_ID_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.item_id.description',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_CATEGORY_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.category.title',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_CATEGORY_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.category.description',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_BASE_ITEM_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.base_item.title',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_BASE_ITEM_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.base_item.description',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_BASE_ITEM_GROUPING_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.base_item_grouping.title',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_BASE_ITEM_GROUPING_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.base_item_grouping.description',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_SHELF_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.shelf.title',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SPLIT_SHELF_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_split.shelf.description',

  CAMPAIGN_FLEXIBILITY_STATUS_FILTER_OPTION_UNADVERTIESD = 'react.pages.campaignFlexibility.dashboard.status.filter_option.unadvertised',
  CAMPAIGN_FLEXIBILITY_STATUS_FILTER_OPTION_ADVERTIESD = 'react.pages.campaignFlexibility.dashboard.status.filter_option.advertised',
  CAMPAIGN_FLEXIBILITY_STATUS_FILTER_OPTION_SMART = 'react.pages.campaignFlexibility.dashboard.status.filter_option.not',

  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_HEADING = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.heading',

  CAMPAIGN_FLEXIBILITY_SELECT_CAMPAIGN_STRUCTURE_AUTO_MANUAL_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_campaign_structure.auto_manual.title',
  CAMPAIGN_FLEXIBILITY_SELECT_CAMPAIGN_STRUCTURE_AUTO_MANUAL_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_campaign_structure.auto_manual.description',
  CAMPAIGN_FLEXIBILITY_SELECT_CAMPAIGN_STRUCTURE_AUTO_BRAND_NONBRAND_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_campaign_structure.auto_brand_nonbrand.title',
  CAMPAIGN_FLEXIBILITY_SELECT_CAMPAIGN_STRUCTURE_AUTO_BRAND_NONBRAND_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_campaign_structure.auto_brand_nonbrand.description',
  CAMPAIGN_FLEXIBILITY_SELECT_CAMPAIGN_STRUCTURE_AUTO_BRAND_COMPETITOR_GENERIC_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_campaign_structure.auto_brand_competitor_generic.title',
  CAMPAIGN_FLEXIBILITY_SELECT_CAMPAIGN_STRUCTURE_AUTO_BRAND_COMPETITOR_GENERIC_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_campaign_structure.auto_brand_competitor_generic.description',

  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_AUTO_MANUAL_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_campaign_structure.auto_manual.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_AUTO_MANUAL_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_campaign_structure.auto_manual.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_AUTO_BRAND_NONBRAND_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_campaign_structure.auto_brand_nonbrand.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_AUTO_BRAND_NONBRAND_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_campaign_structure.auto_brand_nonbrand.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_AUTO_BRAND_COMPETITOR_GENERIC_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_campaign_structure.auto_brand_competitor_generic.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_AUTO_BRAND_COMPETITOR_GENERIC_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_campaign_structure.auto_brand_competitor_generic.description',

  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_MANUAL_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_campaign_structure.manual.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_MANUAL_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_campaign_structure.manual.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_BRAND_NONBRAND_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_campaign_structure.brand_nonbrand.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_BRAND_NONBRAND_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_campaign_structure.brand_nonbrand.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_BRAND_COMPETITOR_GENERIC_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_campaign_structure.brand_competitor_generic.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_CAMPAIGN_STRUCTURE_BRAND_COMPETITOR_GENERIC_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_campaign_structure.brand_competitor_generic.description',

  CAMPAIGN_FLEXIBILITY_AD_GROUP_SETUP_SINGLE_AD_GROUP_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_setup.single_ad_group.title',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SETUP_SINGLE_AD_GROUP_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_setup.single_ad_group.description',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SETUP_BRAND_NONBRAND_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_setup.brand_nonbrand.title',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SETUP_BRAND_NONBRAND_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_setup.brand_nonbrand.description',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SETUP_BRAND_COMPETITOR_GENERIC_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_setup.brand_competitor_generic.title',
  CAMPAIGN_FLEXIBILITY_AD_GROUP_SETUP_BRAND_COMPETITOR_GENERIC_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.ad_group_setup.brand_competitor_generic.description',

  CAMPAIGN_FLEXIBILITY_SELECT_PAT_AD_GROUP_STRATEGY_SINGLE_AD_GROUP_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_ad_group_strategy.single_ad_group.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_AD_GROUP_STRATEGY_SINGLE_AD_GROUP_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_ad_group_strategy.single_ad_group.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_AD_GROUP_STRATEGY_MULTIPLE_AD_GROUP_BRAND_NONBRAND_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_ad_group_strategy.brand_nonbrand.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_AD_GROUP_STRATEGY_MULTIPLE_AD_GROUP_BRAND_NONBRAND_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_ad_group_strategy.brand_nonbrand.description',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_AD_GROUP_STRATEGY_MULTIPLE_AD_GROUP_AUTO_BRAND_COMPETITOR_GENERIC_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_ad_group_strategy.brand_competitor_generic.title',
  CAMPAIGN_FLEXIBILITY_SELECT_PAT_AD_GROUP_STRATEGY_MULTIPLE_AD_GROUP_AUTO_BRAND_COMPETITOR_GENERIC_DESC = 'react.pages.campaignFlexibility.dashboard.step_1.select_pat_ad_group_strategy.brand_competitor_generic.description',

  CAMPAIGN_FLEXIBILITY_FRQUENTLY_USED = 'react.pages.campaignFlexibility.dashboard.step_1.frequently_used',
  CAMPAIGN_FLEXIBILITY_PREVIEW_ERROR_DESCRIPTION = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.error.description',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_ACOS_TARGET = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.acos_target',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_ACOS_TARGET_DESCRIPTION = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.acos_target.description',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_DAILYBUDGET = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.dailyBudget',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_DAILYBUDGET_DETAILS = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.dailyBudget.detail',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_ACOS_DETAILS = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.Acos.detail',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_SKUS = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.Skus',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_DAILYBUDGET_DESCRIPTION = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.dailyBudget.description',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_RECOMMENDED_KEYWORDS = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.recommended_keywords',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_RECOMMENDED_KEYWORDS_DESCRIPTION = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.recommended_keywords.description',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_RECOMMENDED_KEYWORDS_AUTO = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.recommended_keywords_label_automatically',

  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_SUMMARY = 'react.pages.campaignFlexibility.campaign.dashboard.card.preview_and_launch.summary',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_DETAILS = 'react.pages.campaignFlexibility.campaign.dashboard.card.preview_and_launch.details',

  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_RECOMMENDED_KEYWORDS_MANUAL = 'react.campaignFlexibility.campaign.dashboard.step_3.card.preview_and_launch.recommended_keywords_label_manually',
  CAMPAIGN_FLEXIBILITY_CAMPAGN_SUCCESS = 'react.campaignFlexibility.dashboard.step_3.campaign_success',
  CAMPAIGN_FLEXIBILITY_CAMPAGN_PROCESSING = 'react.campaignFlexibility.dashboard.step_3.campaign_processing',
  CAMPAIGN_FLEXIBILITY_KEYWORD_CARD_DESCRIPTION = 'react.campaignFlexibility.dashboard.step_3.keywordCard.description',
  CAMPAIGN_FLEXIBILITY_KEYWORD_CARD_CONFORMATION_TRE_HEAD = 'react.campaignFlexibility.dashboard.step_3.keywordCard.conformation.tre.unavailable.head',
  CAMPAIGN_FLEXIBILITY_KEYWORD_CARD_CONFORMATION_OFF_HEAD = 'react.campaignFlexibility.dashboard.step_3.keywordCard.conformation.off.head',
  CAMPAIGN_FLEXIBILITY_KEYWORD_CARD_CONFORMATION_ON_HEAD = 'react.campaignFlexibility.dashboard.step_3.keywordCard.conformation.on.head',
  CAMPAIGN_FLEXIBILITY_KEYWORD_CARD_CONFORMATION_OFF_BODY = 'react.campaignFlexibility.dashboard.step_3.keywordCard.conformation.off.body',
  CAMPAIGN_FLEXIBILITY_KEYWORD_CARD_CONFORMATION_ON_BODY = 'react.campaignFlexibility.dashboard.step_3.keywordCard.conformation.on.body',
  CAMPAIGN_FLEXIBILITY_KEYWORD_CARD_CONFORMATION_TRE_BODY = 'react.campaignFlexibility.dashboard.step_3.keywordCard.conformation.tre.unavailable.body',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_CLICKS = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.clicks',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_CONVERSIONS = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.conversions',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_SUBLEVEL_USER_KEYWORDS = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.subLevel.user',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_SUBLEVEL_RECOMMENDED_KEYWORDS = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.subLevel.recommendedkeywords',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_ADDED_KEYWORDS_DONE = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.added',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_ADDED_KEYWORDS = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.addedkeywords',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_MATCH_TYPE_LABEL = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.matchtype.label',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_TEXTAREA_PLACEHOLDER = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.textarea.placeholder',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_LABEL_REMOVE_ALL = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.label.removeAll',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_LABEL_ADD_KEYWORD = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.label.addkeywords',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_RECOMMENDED_CARD_LABEL = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.recommendedkeywords.label',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_AI_RECOMMENDED_CARD_LABEL = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.airecommendedkeywords.label',
  CAMPAIGN_FLEXIBILITY_SUMMARY_PRODUCTS_ADVERTISED_HEADER = 'react.compass.campaignFlexibility.dashboard.step_3.summary.products_advertised_card.header',
  CAMPAIGN_FLEXIBILITY_SUMMARY_PRODUCTS_ADVERTISED_FOOTER = 'react.compass.campaignFlexibility.dashboard.step_3.summary.products_advertised_card.footer',
  CAMPAIGN_FLEXIBILITY_SUMMARY_PRODUCTS_ADVERTISED_MORE = 'react.compass.campaignFlexibility.dashboard.step_3.summary.products_advertised_card.more',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CAMPAIGN_PREVIEW_LAUNCH_BANNER = 'react.compass.campaignFlexibility.dashboard.step_3.summary.campaign_preview_launch.banner',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CAMPAIGN_SCHEDULE_CARD_HEADER = 'react.compass.campaignFlexibility.dashboard.step_3.summary.campaign_scheduled_card.header',
  CAMPAIGN_FLEXIBILITY_SUMMARY_KEYWORDS_HEADER = 'react.compass.campaignFlexibility.dashboard.step_3.summary.keywords.header',
  CAMPAIGN_FLEXIBILITY_TOTAL_STRUCTURE_SUMMARY_HEADER = 'react.compass.campaignFlexibility.dashboard.step_3.total_campaign_structure.summary.header',
  CAMPAIGN_FLEXIBILITY_TOTAL_STRUCTURE_SUMMARY_HEADER_NEW = 'react.compass.campaignFlexibility.dashboard.step_3.total_campaign_structure.summary.header_v2',
  CAMPAIGN_FLEXIBILITY_SUMMARY_EDITALL = 'react.compass.campaignFlexibility.dashboard.step_3.total_campaign_structure.summary.edit_all',
  CAMPAIGN_FLEXIBILITY_SUMMARY_KEYWORDS_FLYWEEL_RECOMMENDED = 'react.compass.campaignFlexibility.dashboard.step_3.summary.keywords.flywheel_recommended',
  CAMPAIGN_FLEXIBILITY_SUMMARY_KEYWORDS_TOTAL_SELECED_KEYWORDS = 'react.compass.campaignFlexibility.dashboard.step_3.summary.keywords.total_selected_keywords',
  CAMPAIGN_FLEXIBILITY_SUMMARY_KEYWORDS_TOTAL_USER_KEYWORDS = 'react.compass.campaignFlexibility.dashboard.step_3.summary.keywords.total_user_added',
  CAMPAIGN_FLEXIBILITY_SUMMARY_KEYWORDS_FLYWEEL_RECOMMENDED_KEYWORDS = 'react.compass.campaignFlexibility.dashboard.step_3.summary.keywords.flywheel_recommended_keywords',
  CAMPAIGN_FLEXIBILITY_SUMMARY_KEYWORDS_POST_LAUNCH_KEYWORDS_RECOMMENDATION = 'react.compass.campaignFlexibility.dashboard.step_3.summary.keywords.post_launch_keyword_recommendation',
  CAMPAIGN_FLEXIBILITY_SUMMARY_KEYWORDS_EXISTING_PRODUCT_STATUS = 'react.compass.campaignFlexibility.dashboard.step_3.summary.keywords.existing_product_status',
  CAMPAIGN_FLEXIBILITY_SUMMARY_PRODUCTS_HEADER = 'react.compass.campaignFlexibility.dashboard.step_3.summary.products_card.header',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CAMPAIGN_TO_LAUNCH_BIDDING_STRATEGY = 'react.compass.campaignFlexibility.dashboard.step_3.summary.campaign_to_lunch.bidding_strategy',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CAMPAIGN_TO_LAUNCH_CAMPAIGN_STRATEGY = 'react.compass.campaignFlexibility.dashboard.step_3.summary.campaign_to_lunch.campaign_strategy',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CAMPAIGN_TO_LAUNCH_TOTAL_DAILY_BUDGET = 'react.compass.campaignFlexibility.dashboard.step_3.summary.campaign_to_lunch.total_daily_budget:',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CAMPAIGN_TO_LAUNCH_ALL_SKUS_PAUSED = 'react.compass.campaignFlexibility.dashboard.step_3.summary.campaign_to_lunch.allskus_paused',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CAMPAIGN_TO_LAUNCH_ALL_SKUS_RUN_PARALLEL = 'react.compass.campaignFlexibility.dashboard.step_3.summary.campaign_to_lunch.allskus_run_parallel',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CAMPAIGN_TO_LAUNCH_ALL_SKUS_NO_EXISTING = 'react.compass.campaignFlexibility.dashboard.step_3.summary.campaign_to_lunch.allskus_no_existing',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CAMPAIGN_TO_LAUNCH_ALL_SKUS_SOME_PAUSED_SOME_RUN_PARALLEL = 'react.compass.campaignFlexibility.dashboard.step_3.summary.campaign_to_lunch.some_skus_paused',
  CAMPAIGN_FLEXIBILITY_SUMMARY_ADGROUP_TO_LAUNCH_SETUP = 'react.compass.campaignFlexibility.dashboard.step_3.summary.adgroup_to_lunch.setup:',
  CAMPAIGN_FLEXIBILITY_SUMMARY_ADGROUP_TO_LAUNCH_TOTAL_ACOS_TARGET_RANGE = 'react.compass.campaignFlexibility.dashboard.step_3.summary.adgroup_to_lunch.totalACoStarget',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_MODAL_EDIT_ALL = 'react.compass.campaignFlexibility.dashboard.step_3.total_campaign_structure.bulkedit.model.edit_all',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_MODAL_CAMPAIGN_SCHEDULED = 'react.compass.campaignFlexibility.dashboard.step_3.total_campaign_structure.bulkedit.model.campaign_scheduled',
  CAMPAIGN_FLEXIBILITY_LOOKING_AROUND = 'react.campaignFlexibility.no_data_with_filter.title',
  CAMPAIGN_FLEXIBILITY_TAKING_A_LOOK = 'react.campaignFlexibility.no_data_with_filter.description',
  CAMPAIGN_FLEXIBILITY_RECOMMENDED_KEYWORD_TARGETING_HEADER = 'react.campaignFlexibility.dashboard.step_3.recommendedKeywordTargeting.header',

  CAMPAIGN_FLEXIBILITY_BULK_EDIT_MODAL_HEADER = 'react.campaignFlexibility.dashboard.step_3.summary.bulk_edit.header',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_SEARCH_BY_CAMPAIG_NAME_PLACEHOLDER = 'react.campaignFlexibility.dashboard.step_3.campaign.search_placeholder',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_DAILY_BUDGET_MODAL_LABEL_CAMPAIGN = 'react.components.campaignFlexibility.dashboard.step_3.daily_budget_modal.label.campaign',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_DAILY_BUDGET_MODAL_LABEL_DAILYBUDGET = 'react.components.campaignFlexibility.dashboard.step_3.daily_budget_modal.label.daily_budget',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_DAILY_BUDGET_MODAL_LABEL_ADGROUP = 'react.compass.campaignFlexibility.dashboard.step_3.summary.model.adgroup',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_DAILY_BUDGET_MODAL_LABEL_ACOS = 'react.compass.campaignFlexibility.dashboard.step_3.summary.model.acos',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_DAILY_BUDGET_MODAL_LABEL_RUN = 'react.compass.campaignFlexibility.dashboard.step_3.summary.model.run',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_DAILY_BUDGET_MODAL_LABEL_PAUSE = 'react.compass.campaignFlexibility.dashboard.step_3.summary.model.pause',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_SEARCH_BY_ADGROUP_NAME_PLACEHOLDER = 'react.campaignFlexibility.dashboard.step_3.adGroup.search_placeholder',

  CAMPAIGN_FLEXIBILITY_KEYWORD_RECOMMENDATIONS_ERROR_HEADER = 'react.campaignFlexibility.keyword_recommendations.error.header',
  CAMPAIGN_FLEXIBILITY_KEYWORD_RECOMMENDATIONS_ERROR_DESCRIPTION = 'react.campaignFlexibility.keyword_recommendations.error.description',
  CAMPAIGN_FLEXIBILITY_PAT_RECOMMENDATIONS_ERROR_HEADER = 'react.campaignFlexibility.pat_recommendations.error.header',
  CAMPAIGN_FLEXIBILITY_PAT_RECOMMENDATIONS_ERROR_DESCRIPTION = 'react.campaignFlexibility.pat_recommendations.error.description',
  CAMPAIGN_FLEXIBILITY_ASIN_TARGETING_RECOMMENDED_TABLE_TARGET_AD_GROUP = 'react.campaignFlexibility.asin_targeting.recommended.table.target_ad_group.header',
  CAMPAIGN_FLEXIBILITY_KEYWORD_RECOMMENDATIONS_TABLE_KEYWORD_RECOMMENDATION_HEADER = 'react.campaignFlexibility.keyword_recommendations.table.keyword_recommendation.header',
  CAMPAIGN_FLEXIBILITY_KEYWORD_RECOMMENDATIONS_TABLE_MATCH_TYPE_HEADER = 'react.campaignFlexibility.keyword_recommendations.table.match_type.header',
  CAMPAIGN_FLEXIBILITY_KEYWORD_RECOMMENDATIONS_TABLE_REASON_HEADER = 'react.campaignFlexibility.keyword_recommendations.table.reason.header',
  CAMPAIGN_FLEXIBILITY_KEYWORD_RECOMMENDATIONS_TABLE_CREATE_IN_AD_GROUP_HEADER = 'react.campaignFlexibility.keyword_recommendations.table.create_in_ad_group.header',
  CAMPAIGN_FLEXIBILITY_KEYWORD_SELECT_AD_GROUPS_TOOLTIP = 'react.campaignFlexibility_keyword.select_keywords.select_ad_groups.tooltip',
  CAMPAIGN_FLEXIBILITY_KEYWORD_SELECT_AD_GROUPS_BODY = 'react.campaignFlexibility_keyword.select_keywords.select_ad_groups.body',
  CAMPAIGN_FLEXIBILITY_ASIN_SELECT_AD_GROUPS_TOOLTIP = 'react.campaignFlexibility_asin.select_keywords.select_ad_groups.tooltip',
  CAMPAIGN_FLEXIBILITY_ASIN_SELECT_AD_GROUPS_BODY = 'react.campaignFlexibility_asin.select_keywords.select_ad_groups.body',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_LABEL_ADD_ASIN = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.label.addasin',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_KEYWORD_CARD_ADDED_ASIN = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.keywords.addedAsin',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_ASIN_CARD_TEXTAREA_PLACEHOLDER = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.asin.textarea.placeholder',
  CAMPAIGN_FLEXIBILITY_DETAILS_MODAL_PRODUCT_CARD_TEXTAREA_PLACEHOLDER = 'react.components.campaignFlexibility.dashboard.step_3.details_modal.product.textarea.placeholder',
  CAMPAIGN_FLEXIBILITY_KEYWORD_RECOMMENDATIONS_TABLE_NEGATIVE_KEYWORD_RECOMMENDATION_HEADER = 'react.campaignFlexibility.keyword_recommendations.table.negative_keyword_recommendation.header',

  RECOMMENDATION_HUB_RECOMMENDATION_TYPE_ALL = 'react.recommendation_hub.main_page.recommendation_types.all',
  RECOMMENDATION_HUB_RECOMMENDATION_TYPE_IMPROVE_ADVERTISING = 'react.recommendation_hub.main_page.recommendation_types.improve_advertising',
  RECOMMENDATION_HUB_RECOMMENDATION_TYPE_ACCOUNT_SETUP = 'react.recommendation_hub.main_page.recommendation_types.account_setup',
  RECOMMENDATION_HUB_RECOMMENDATION_TYPE_DISMISSED = 'react.recommendation_hub.main_page.recommendation_types.dismissed',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_HEADER = 'react.recommendation_hub.automated_bidding.header',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_SUB_HEADER = 'react.recommendation_hub.automated_bidding.sub_header',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_BENEFIT_OF_ENABLING_AUTOMATED_BIDDING = 'react.recommendation_hub.automated_bidding.benefits_of_enabling',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_SMART_ACOS_TARGETS = 'react.recommendation_hub.automated_bidding.smart_acos_targets',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_AD_SALES = 'react.recommendation_hub.automated_bidding.ad_sales',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_AD_SPEND = 'react.recommendation_hub.automated_bidding.ad_spend',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_AD_TYPE = 'react.recommendation_hub.automated_bidding.ad_type',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_MERCHANT_NAME = 'react.recommendation_hub.automated_bidding.merchant_name',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_CURRENT_ACOS = 'react.recommendation_hub.automated_bidding.current_acos',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_BENEFITS_OF_SMART_ACOS = 'react.recommendation_hub.automated_bidding.smart_acos_benefits',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_LEARN_MORE = 'react.recommendation_hub.automated_bidding.learn_more',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_OR_CUSTOMIZE = 'react.recommendation_hub.automated_bidding.or_customize',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_ENABLE_ALL = 'react.recommendation_hub.automated_bidding.enable_all',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_DISMISS_ALL = 'react.recommendation_hub.automated_bidding.dismiss_all',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_SEARCH_PLACEHOLDER = 'react.recommendation_hub.automated_bidding.search_placeholder',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_TURN_ON_BIDDER = 'react.recommendation_hub.automated_bidding.turn_on_bidder',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_DISMISS = 'react.recommendation_hub.automated_bidding.dismiss',
  RECOMMENDATION_PAGE_AUTOMATED_BIDDING_TOTAL_MESSAGE = 'react.recommendation_hub.automated_bidding.total_message',

  RECOMMENDATION_PAGE_ENTER_COGS_TABLE_COLUMN_SKU = 'react.recommendation_hub.enter_cogs_table_column.sku',
  RECOMMENDATION_PAGE_ENTER_COGS_TABLE_COLUMN_UPDATED = 'react.recommendation_hub.enter_cogs_table_column.updated',
  RECOMMENDATION_PAGE_ENTER_COGS_TABLE_COLUMN_UNIT_COGS = 'react.recommendation_hub.enter_cogs_table_column.unit_cogs',
  RECOMMENDATION_PAGE_ENTER_COGS_PAGE_DESCRIPTION = 'react.recommendation_hub.enter_cogs_page.description_text',
  RECOMMENDATION_PAGE_ENTER_COGS_PAGE_DESCRIPTION_HEADING = 'react.recommendation_hub.enter_cogs_page.description_heading',
  RECOMMENDATION_PAGE_ENTER_COGS_PAGE_DESCRIPTION_WHAT_TO_DO = 'react.recommendation_hub.enter_cogs_page.description_what_to_do',
  RECOMMENDATION_PAGE_CARDS_FOOTER_TEXT = 'react.recommendation_hub.cards.footer_text',
  RECOMMENDATION_PAGE_UPDATE_UNIT_COGS_SUCCESS_MESSAGE = 'react.recommendation_hub.unit_cogs.update.success',

  EMPTY_STATES_HERO_CONNECT_BUTTON_LABEL = 'react.empty_states.hero.connect_button.label',
  EMPTY_STATES_HERO_CHAT_BUTTON_LABEL = 'react.empty_states.hero.chat_button.label',
  EMPTY_STATES_PROPOSITIONS_TITLE = 'react.empty_states.propositions.title',
  EMPTY_STATES_BANNER_SYNCED_TITLE = 'react.empty_states.banner.synced.title',
  EMPTY_STATES_BANNER_SYNCED_SUBTITLE = 'react.empty_states.banner.synced.subtitle',
  EMPTY_STATES_BANNER_LINK_LABEL = 'react.empty_states.banner.link.label',
  EMPTY_STATES_BANNER_COMMUNITY_BUTTON_LABEL = 'react.empty_states.banner.community_button.label',
  EMPTY_STATES_BANNER_CONNECT_BUTTON_LABEL = 'react.empty_states.banner.connect_button.label',
  EMPTY_STATES_BANNER_CHAT_BUTTON_LABEL = 'react.empty_states.banner.chat_button.label',
  EMPTY_STATES_WALKTHROUGH_DEEP_DIVE_TITLE = 'react.empty_states.walkthrough.deep_dive_title',
  EMPTY_STATES_WALKTHROUGH_START_WALKTHROUGH = 'react.empty_states.walkthrough.start_walkthrough',
  EMPTY_STATES_WALKTHROUGH_MAYBE_LATER = 'react.empty_states.walkthrough.maybe_later',
  EMPTY_STATES_RISK_FREE_TRIAL_PILL = 'react.empty_states.risk_free_trial.pill',
  EMPTY_STATES_RISK_FREE_TRIAL_DESCRIPTION = 'react.empty_states.risk_free_trial.description',
  EMPTY_STATES_RISK_FREE_TRIAL_BANNER_TITLE = 'react.empty_states.risk_free_trial.banner.title',
  EMPTY_STATES_RISK_FREE_TRIAL_BANNER_SUBTITLE = 'react.empty_states.risk_free_trial.banner.subtitle',

  EMPTY_STATES_COMPASS_HERO_TITLE = 'react.empty_states.compass.hero.title',
  EMPTY_STATES_COMPASS_HERO_SUBTITLE = 'react.empty_states.compass.hero.subtitle',
  EMPTY_STATES_COMPASS_BANNER_TITLE = 'react.empty_states.compass.banner.title',
  EMPTY_STATES_COMPASS_PROPOSITION1_TITLE = 'react.empty_states.compass.proposition1.title',
  EMPTY_STATES_COMPASS_PROPOSITION1_SUBTITLE = 'react.empty_states.compass.proposition1.subtitle',
  EMPTY_STATES_COMPASS_PROPOSITION2_TITLE = 'react.empty_states.compass.proposition2.title',
  EMPTY_STATES_COMPASS_PROPOSITION2_SUBTITLE = 'react.empty_states.compass.proposition2.subtitle',
  EMPTY_STATES_COMPASS_PROPOSITION3_TITLE = 'react.empty_states.compass.proposition3.title',
  EMPTY_STATES_COMPASS_PROPOSITION3_SUBTITLE = 'react.empty_states.compass.proposition3.subtitle',
  EMPTY_STATES_COMPASS_WALKTHROUGH_TITLE = 'react.empty_states.compass.walkthrough.title',
  EMPTY_STATES_COMPASS_WALKTHROUGH_DEEP_DIVE_HELP = 'react.empty_states.compass.walkthrough.deep_dive_help',
  EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE1_TITLE = 'react.empty_states.compass.walkthrough.slide1.title',
  EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE1_SUBTITLE = 'react.empty_states.compass.walkthrough.slide1.subtitle',
  EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE2_TITLE = 'react.empty_states.compass.walkthrough.slide2.title',
  EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE2_SUBTITLE = 'react.empty_states.compass.walkthrough.slide2.subtitle',
  EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE3_TITLE = 'react.empty_states.compass.walkthrough.slide3.title',
  EMPTY_STATES_COMPASS_WALKTHROUGH_SLIDE3_SUBTITLE = 'react.empty_states.compass.walkthrough.slide3.subtitle',

  EMPTY_STATES_ADS_MANAGER_HERO_TITLE = 'react.empty_states.ads_manager.hero.title',
  EMPTY_STATES_ADS_MANAGER_HERO_SUBTITLE = 'react.empty_states.ads_manager.hero.subtitle',
  EMPTY_STATES_ADS_MANAGER_BANNER_TITLE = 'react.empty_states.ads_manager.banner.title',
  EMPTY_STATES_ADS_MANAGER_PROPOSITION1_TITLE = 'react.empty_states.ads_manager.proposition1.title',
  EMPTY_STATES_ADS_MANAGER_PROPOSITION1_SUBTITLE = 'react.empty_states.ads_manager.proposition1.subtitle',
  EMPTY_STATES_ADS_MANAGER_PROPOSITION2_TITLE = 'react.empty_states.ads_manager.proposition2.title',
  EMPTY_STATES_ADS_MANAGER_PROPOSITION2_SUBTITLE = 'react.empty_states.ads_manager.proposition2.subtitle',
  EMPTY_STATES_ADS_MANAGER_PROPOSITION3_TITLE = 'react.empty_states.ads_manager.proposition3.title',
  EMPTY_STATES_ADS_MANAGER_PROPOSITION3_SUBTITLE = 'react.empty_states.ads_manager.proposition3.subtitle',
  EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_TITLE = 'react.empty_states.ads_manager.walkthrough.title',
  EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_DEEP_DIVE_HELP = 'react.empty_states.ads_manager.walkthrough.deep_dive_help',
  EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE1_TITLE = 'react.empty_states.ads_manager.walkthrough.slide1.title',
  EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE1_SUBTITLE = 'react.empty_states.ads_manager.walkthrough.slide1.subtitle',
  EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE2_TITLE = 'react.empty_states.ads_manager.walkthrough.slide2.title',
  EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE2_SUBTITLE = 'react.empty_states.ads_manager.walkthrough.slide2.subtitle',
  EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE3_TITLE = 'react.empty_states.ads_manager.walkthrough.slide3.title',
  EMPTY_STATES_ADS_MANAGER_WALKTHROUGH_SLIDE3_SUBTITLE = 'react.empty_states.ads_manager.walkthrough.slide3.subtitle',

  EMPTY_STATES_PRODUCT_CATALOG_HERO_TITLE = 'react.empty_states.product_catalog.hero.title',
  EMPTY_STATES_PRODUCT_CATALOG_HERO_SUBTITLE = 'react.empty_states.product_catalog.hero.subtitle',
  EMPTY_STATES_PRODUCT_CATALOG_BANNER_TITLE = 'react.empty_states.product_catalog.banner.title',
  EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION1_TITLE = 'react.empty_states.product_catalog.proposition1.title',
  EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION1_SUBTITLE = 'react.empty_states.product_catalog.proposition1.subtitle',
  EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION2_TITLE = 'react.empty_states.product_catalog.proposition2.title',
  EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION2_SUBTITLE = 'react.empty_states.product_catalog.proposition2.subtitle',
  EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION3_TITLE = 'react.empty_states.product_catalog.proposition3.title',
  EMPTY_STATES_PRODUCT_CATALOG_PROPOSITION3_SUBTITLE = 'react.empty_states.product_catalog.proposition3.subtitle',
  EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_TITLE = 'react.empty_states.product_catalog.walkthrough.title',
  EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_DEEP_DIVE_HELP = 'react.empty_states.product_catalog.walkthrough.deep_dive_help',
  EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE1_TITLE = 'react.empty_states.product_catalog.walkthrough.slide1.title',
  EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE1_SUBTITLE = 'react.empty_states.product_catalog.walkthrough.slide1.subtitle',
  EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE2_TITLE = 'react.empty_states.product_catalog.walkthrough.slide2.title',
  EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE2_SUBTITLE = 'react.empty_states.product_catalog.walkthrough.slide2.subtitle',
  EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE3_TITLE = 'react.empty_states.product_catalog.walkthrough.slide3.title',
  EMPTY_STATES_PRODUCT_CATALOG_WALKTHROUGH_SLIDE3_SUBTITLE = 'react.empty_states.product_catalog.walkthrough.slide3.subtitle',

  EMPTY_STATES_PRODUCT_CATALOG_RISK_FREE_BANNER_TITLE = 'react.empty_states.product_catalog.risk_free.banner.title',
  EMPTY_STATES_PRODUCT_CATALOG_RISK_FREE_BANNER_SUBTITLE = 'react.empty_states.product_catalog.risk_free.banner.subtitle',
  EMPTY_STATES_PRODUCT_CATALOG_RISK_FREE_BANNER_GET_STARTED_DESCRIPTION = 'react.empty_states.product_catalog.risk_free.banner.get_started',

  EMPTY_STATES_RECOMMENDATION_HUB_HERO_TITLE = 'react.empty_states.recommendation_hub.hero.title',
  EMPTY_STATES_RECOMMENDATION_HUB_HERO_SUBTITLE = 'react.empty_states.recommendation_hub.hero.subtitle',
  EMPTY_STATES_RECOMMENDATION_HUB_BANNER_TITLE = 'react.empty_states.recommendation_hub.banner.title',
  EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION1_TITLE = 'react.empty_states.recommendation_hub.proposition1.title',
  EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION1_SUBTITLE = 'react.empty_states.recommendation_hub.proposition1.subtitle',
  EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION2_TITLE = 'react.empty_states.recommendation_hub.proposition2.title',
  EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION2_SUBTITLE = 'react.empty_states.recommendation_hub.proposition2.subtitle',
  EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION3_TITLE = 'react.empty_states.recommendation_hub.proposition3.title',
  EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION3_SUBTITLE = 'react.empty_states.recommendation_hub.proposition3.subtitle',
  EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_TITLE = 'react.empty_states.recommendation_hub.walkthrough.title',
  EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_DEEP_DIVE_HELP = 'react.empty_states.recommendation_hub.walkthrough.deep_dive_help',
  EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE1_TITLE = 'react.empty_states.recommendation_hub.walkthrough.slide1.title',
  EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE1_SUBTITLE = 'react.empty_states.recommendation_hub.walkthrough.slide1.subtitle',
  EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE2_TITLE = 'react.empty_states.recommendation_hub.walkthrough.slide2.title',
  EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE2_SUBTITLE = 'react.empty_states.recommendation_hub.walkthrough.slide2.subtitle',
  EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE3_TITLE = 'react.empty_states.recommendation_hub.walkthrough.slide3.title',
  EMPTY_STATES_RECOMMENDATION_HUB_WALKTHROUGH_SLIDE3_SUBTITLE = 'react.empty_states.recommendation_hub.walkthrough.slide3.subtitle',

  EMPTY_STATES_RECOMMENDATION_HUB_HERO_SUBTITLE_NEW = 'react.empty_states.recommendation_hub.hero.subtitle_new',
  EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION1_TITLE_NEW = 'react.empty_states.recommendation_hub.proposition1.title_new',
  EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION1_SUBTITLE_NEW = 'react.empty_states.recommendation_hub.proposition1.subtitle_new',
  EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION2_TITLE_NEW = 'react.empty_states.recommendation_hub.proposition2.title_new',
  EMPTY_STATES_RECOMMENDATION_HUB_PROPOSITION2_SUBTITLE_NEW = 'react.empty_states.recommendation_hub.proposition2.subtitle_new',

  EMPTY_STATES_CAMPAIGN_CREATOR_HERO_TITLE = 'react.empty_states.campaign_creator.hero.title',
  EMPTY_STATES_CAMPAIGN_CREATOR_HERO_SUBTITLE = 'react.empty_states.campaign_creator.hero.subtitle',
  EMPTY_STATES_CAMPAIGN_CREATOR_BANNER_TITLE = 'react.empty_states.campaign_creator.banner.title',
  EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION1_TITLE = 'react.empty_states.campaign_creator.proposition1.title',
  EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION1_SUBTITLE = 'react.empty_states.campaign_creator.proposition1.subtitle',
  EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION2_TITLE = 'react.empty_states.campaign_creator.proposition2.title',
  EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION2_SUBTITLE = 'react.empty_states.campaign_creator.proposition2.subtitle',
  EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION3_TITLE = 'react.empty_states.campaign_creator.proposition3.title',
  EMPTY_STATES_CAMPAIGN_CREATOR_PROPOSITION3_SUBTITLE = 'react.empty_states.campaign_creator.proposition3.subtitle',
  EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_TITLE = 'react.empty_states.campaign_creator.walkthrough.title',
  EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_DEEP_DIVE_HELP = 'react.empty_states.campaign_creator.walkthrough.deep_dive_help',
  EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE1_TITLE = 'react.empty_states.campaign_creator.walkthrough.slide1.title',
  EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE1_SUBTITLE = 'react.empty_states.campaign_creator.walkthrough.slide1.subtitle',
  EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE2_TITLE = 'react.empty_states.campaign_creator.walkthrough.slide2.title',
  EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE2_SUBTITLE = 'react.empty_states.campaign_creator.walkthrough.slide2.subtitle',
  EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE3_TITLE = 'react.empty_states.campaign_creator.walkthrough.slide3.title',
  EMPTY_STATES_CAMPAIGN_CREATOR_WALKTHROUGH_SLIDE3_SUBTITLE = 'react.empty_states.campaign_creator.walkthrough.slide3.subtitle',

  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_HERO_TITLE = 'react.empty_states.ai_recommended_keywords.hero.title',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_HERO_SUBTITLE = 'react.empty_states.ai_recommended_keywords.hero.subtitle',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_BANNER_TITLE = 'react.empty_states.ai_recommended_keywords.banner.title',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_PROPOSITION1_TITLE = 'react.empty_states.ai_recommended_keywords.proposition1.title',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_PROPOSITION1_SUBTITLE = 'react.empty_states.ai_recommended_keywords.proposition1.subtitle',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_PROPOSITION2_TITLE = 'react.empty_states.ai_recommended_keywords.proposition2.title',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_PROPOSITION2_SUBTITLE = 'react.empty_states.ai_recommended_keywords.proposition2.subtitle',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_TITLE = 'react.empty_states.ai_recommended_keywords.walkthrough.title',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_TITLE_V2 = 'react.empty_states.ai_recommended_keywords.walkthrough.title_v2',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_DEEP_DIVE_HELP = 'react.empty_states.ai_recommended_keywords.walkthrough.deep_dive_help',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_DEEP_DIVE_HELP_V2 = 'react.empty_states.ai_recommended_keywords.walkthrough.deep_dive_help_v2',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_TITLE = 'react.empty_states.ai_recommended_keywords.walkthrough.slide1.title',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_SUBTITLE = 'react.empty_states.ai_recommended_keywords.walkthrough.slide1.subtitle',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_TITLE = 'react.empty_states.ai_recommended_keywords.walkthrough.slide2.title',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_SUBTITLE = 'react.empty_states.ai_recommended_keywords.walkthrough.slide2.subtitle',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_V2_TITLE = 'react.empty_states.ai_recommended_keywords.walkthrough.slide1_v2.title',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE1_V2_SUBTITLE = 'react.empty_states.ai_recommended_keywords.walkthrough.slide1_v2.subtitle',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_V2_TITLE = 'react.empty_states.ai_recommended_keywords.walkthrough.slide2_v2.title',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE2_V2_SUBTITLE = 'react.empty_states.ai_recommended_keywords.walkthrough.slide2_v2.subtitle',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE3_TITLE = 'react.empty_states.ai_recommended_keywords.walkthrough.slide3.title',
  EMPTY_STATES_AI_RECOMMENDED_KEYWORDS_WALKTHROUGH_SLIDE3_SUBTITLE = 'react.empty_states.ai_recommended_keywords.walkthrough.slide3.subtitle',

  EMPTY_STATES_BUSINESS_INTELLIGENCE_HERO_TITLE = 'react.empty_states.business_intelligence.hero.title',
  EMPTY_STATES_BUSINESS_INTELLIGENCE_HERO_SUBTITLE = 'react.empty_states.business_intelligence.hero.subtitle',
  EMPTY_STATES_BUSINESS_INTELLIGENCE_BANNER_TITLE = 'react.empty_states.business_intelligence.banner.title',
  EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION1_TITLE = 'react.empty_states.business_intelligence.proposition1.title',
  EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION1_SUBTITLE = 'react.empty_states.business_intelligence.proposition1.subtitle',
  EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION2_TITLE = 'react.empty_states.business_intelligence.proposition2.title',
  EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION2_SUBTITLE = 'react.empty_states.business_intelligence.proposition2.subtitle',
  EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION3_TITLE = 'react.empty_states.business_intelligence.proposition3.title',
  EMPTY_STATES_BUSINESS_INTELLIGENCE_PROPOSITION3_SUBTITLE = 'react.empty_states.business_intelligence.proposition3.subtitle',

  EMPTY_STATES_KEYWORD_TARGET_ACTIONS_HERO_TITLE = 'react.empty_states.keyword_target_actions.hero.title',
  EMPTY_STATES_KEYWORD_TARGET_ACTIONS_HERO_SUBTITLE = 'react.empty_states.keyword_target_actions.hero.subtitle',
  EMPTY_STATES_KEYWORD_TARGET_ACTIONS_BANNER_TITLE = 'react.empty_states.keyword_target_actions.banner.title',
  EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION1_TITLE = 'react.empty_states.keyword_target_actions.proposition1.title',
  EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION1_SUBTITLE = 'react.empty_states.keyword_target_actions.proposition1.subtitle',
  EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION2_TITLE = 'react.empty_states.keyword_target_actions.proposition2.title',
  EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION2_SUBTITLE = 'react.empty_states.keyword_target_actions.proposition2.subtitle',
  EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION3_TITLE = 'react.empty_states.keyword_target_actions.proposition3.title',
  EMPTY_STATES_KEYWORD_TARGET_ACTIONS_PROPOSITION3_SUBTITLE = 'react.empty_states.keyword_target_actions.proposition3.subtitle',

  EMPTY_STATES_BUDGET_PACING_HERO_TITLE = 'react.empty_states.budget_pacing.hero.title',
  EMPTY_STATES_BUDGET_PACING_HERO_SUBTITLE = 'react.empty_states.budget_pacing.hero.subtitle',
  EMPTY_STATES_BUDGET_PACING_BANNER_TITLE = 'react.empty_states.budget_pacing.banner.title',
  EMPTY_STATES_BUDGET_PACING_BANNER_SUBTITLE = 'react.empty_states.budget_pacing.banner.subtitle',
  EMPTY_STATES_BUDGET_PACING_PROPOSITION1_TITLE = 'react.empty_states.budget_pacing.proposition1.title',
  EMPTY_STATES_BUDGET_PACING_PROPOSITION1_SUBTITLE = 'react.empty_states.budget_pacing.proposition1.subtitle',
  EMPTY_STATES_BUDGET_PACING_PROPOSITION2_TITLE = 'react.empty_states.budget_pacing.proposition2.title',
  EMPTY_STATES_BUDGET_PACING_PROPOSITION2_SUBTITLE = 'react.empty_states.budget_pacing.proposition2.subtitle',
  EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_TITLE = 'react.empty_states.budget_pacing.walkthrough.title',
  EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_DEEP_DIVE_HELP = 'react.empty_states.budget_pacing.walkthrough.deep_dive_help',
  EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_SLIDE1_TITLE = 'react.empty_states.budget_pacing.walkthrough.slide1.title',
  EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_SLIDE1_SUBTITLE = 'react.empty_states.budget_pacing.walkthrough.slide1.subtitle',
  EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_SLIDE2_TITLE = 'react.empty_states.budget_pacing.walkthrough.slide2.title',
  EMPTY_STATES_BUDGET_PACING_WALKTHROUGH_SLIDE2_SUBTITLE = 'react.empty_states.budget_pacing.walkthrough.slide2.subtitle',

  EMPTY_STATES_INVENTORY_OPTIMIZATION_HERO_TITLE = 'react.empty_states.inventory_optimization.hero.title',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_HERO_PILL_TEXT = 'react.empty_states.inventory_optimization.hero.pill_text',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_HERO_SUBTITLE = 'react.empty_states.inventory_optimization.hero.subtitle',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_FREE_TRIAL_BANNER = 'react.empty_states.inventory_optimization.hero.free_trial_banner',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_DEMAND_FORECAST_DESCRIPTION = 'react.empty_states.inventory_optimization.demand_forecast.description',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_RATE_OF_SALE_DESCRIPTION = 'react.empty_states.inventory_optimization.rate_of_sale.description',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_OUT_OF_STOCK_DESCRIPTION = 'react.empty_states.inventory_optimization.out_of_stock.description',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_EXCESS_STOCK_DESCRIPTION = 'react.empty_states.inventory_optimization.excess_stock.description',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_AGING_INVENTORY_DESCRIPTION = 'react.empty_states.inventory_optimization.aging_inventory.description',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_DESCRIPTION = 'react.empty_states.inventory_optimization.stockout_reorder.description',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_DESCRIPTION = 'react.empty_states.inventory_optimization.hiive_clearance.description',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_LEARN_MORE_DESCRIPTION = 'react.empty_states.inventory_optimization.learn_more.description',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_LEARN_MORE_HEADER = 'react.empty_states.inventory_optimization.learn_more.header',
  EMPTY_STATES_INVENTORY_OPTIMIZATION_LEARN_MORE_CTA = 'react.empty_states.inventory_optimization.learn_more.cta_text',

  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_HEADER = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.header',
  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_NEGATIVE_HEADER = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.negative_header',
  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_VIEW = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.view',
  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_AD_CONVERSIONS = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.ad_conversions',
  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_AD_CONVERSIONS_NEG = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.ad_conversions_neg',
  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_CLICKS = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.clicks',
  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_TIME_RANGE = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.time_range',
  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_LAST_UPDATED_DATE = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.last_updated_date',
  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_AD_SPEND = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.ad_spend',
  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_TOOLTIP_HEADER = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.tooltip.header',
  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_TOOLTIP_CONTENT = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.tooltip.content',
  AI_RECOMMENDED_KEYWORD_CREATE_IN_ADGROUP_TOOLTIP_CONTENT = 'react.ai_recommended_keywords.table.create_in_adgroup.tooltip',
  AI_RECOMMENDED_KEYWORD_TABLE_CHECKBOX_SINGLE_SELECT_TOOLTIP = 'react.ai_recommended_keywords.table.bulk_checkbox.single_select.tooltip',
  AI_RECOMMENDED_KEYWORD_TABLE_CHECKBOX_SINGLE_SELECT_ASIN_TOOLTIP = 'react.ai_recommended_keywords.table.bulk_checkbox.single_select_asin.tooltip',
  AI_RECOMMENDED_KEYWORD_TABLE_ASIN_SEARCH_PLACEHOLDER = 'react.keyword_recommendations.table.asin_search.placeholder',
  AI_RECOMMENDED_PAT_TABLE_CHECKBOX_SINGLE_SELECT_TOOLTIP = 'react.ai_recommended_pat.table.bulk_checkbox.single_select.tooltip',
  AI_RECOMMENDED_KEYWORD_TABLE_CHECKBOX_HEADER_TOOLTIP = 'react.ai_recommended_keywords.table.bulk_checkbox.header.tooltip',
  AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_AO = 'react.ai_recommended_keywords.table.source.report.existing_keyword',
  AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_AMZ_SP_SEARCH_TERM_REPORT = 'react.ai_recommended_keywords.table.source.report.amz_search_term_report',
  AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_AMZ_SP_SUGGESTED_KEYWORD = 'react.ai_recommended_keywords.table.source.report.amazon_suggested_keyword_report',
  AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_WMT_SP_ITEM_KEYWORD_REPORT = 'react.ai_recommended_keywords.table.source.report.walmart_item_keyword_report',
  AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_WMT_SP_KEYWORD_ANALYTIC = 'react.ai_recommended_keywords.table.source.report.walmart_sp_keyword_analytic',
  AI_RECOMMENDED_KEYWORD_TABLE_SOURCES_REPORT_WMT_SP_KEYWORD_PERFORMANCE_REPORT = 'react.ai_recommended_keywords.table.source.report.walmart_sp_keyword_performance_report',
  AI_RECOMMENDED_KEYWORD_TABLE_KEYWORDS_BY_PERFORMANCE = 'react.ai_recommended_keywords.table.keywords_by_performance',
  AI_RECOMMENDED_KEYWORD_TABLE_KEYWORDS_BY_CHANNEL = 'react.ai_recommended_keywords.table.keywords_by_channel',
  AI_RECOMMENDED_KEYWORD_AD_SALES_BANNER_CRITERIA = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.criteria',
  AI_RECOMMENDED_KEYWORD_AD_SALES_KEYWORDS_TO_ADD = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.keywords_to_add',
  AI_RECOMMENDED_KEYWORD_AD_SALES_SEARCH_PLACEHOLDER = 'react.ai_recommended_keywords.views.search_placeholder',
  AI_RECOMMENDED_KEYWORD_AD_SALES_CAMPAIGN_TYPES = 'react.ai_recommended_keywords.views.campaign_types',
  AI_RECOMMENDED_KEYWORD_AD_SALES_CAMPAIGN_TYPES_ALL = 'react.ai_recommended_keywords.views.campaign_type_all',
  AI_RECOMMENDED_KEYWORD_AD_SALES_CAMPAIGN_TYPES_SMART = 'react.ai_recommended_keywords.views.campaign_type_smart',
  AI_RECOMMENDED_KEYWORD_AD_SALES_CAMPAIGN_TYPES_EXTERNAL = 'react.ai_recommended_keywords.views.campaign_type_external',
  AI_RECOMMENDED_KEYWORD_AD_SALES_HELP_MESSAGE = 'react.ai_recommended_keywords.views.help_message',
  AI_RECOMMENDED_KEYWORD_AD_SALES_NEGATIVE_KEYWORDS_TO_ADD = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.negative_keywords_to_add',
  AI_RECOMMENDED_KEYWORD_AD_SALES_WALMART_NEGATIVE_KEYWORDS_TO_ADD_TOOLTIP = 'react.ai_recommended_keywords.views.walmart.negative_keywords_to_add.tooltip',
  AI_RECOMMENDED_NEG_KEYWORD_RECENTLY_UPDATED_HEADER = 'react.ai_recommended_neg_keywords.recently_updated.header',
  AI_RECOMMENDED_NEG_KEYWORD_RECENTLY_UPDATED_DESCRIPTION = 'react.ai_recommended_neg_keywords.recently_updated.description',
  AI_RECOMMENDED_NEG_KEYWORD_RECENTLY_UPDATED_DATE_TIME_DATE = 'react.ai_recommended_neg_keywords.recently_updated.date_time.date',
  AI_RECOMMENDED_NEG_KEYWORD_RECENTLY_UPDATED_REFRESHED_24_HOURS = 'react.ai_recommended_neg_keywords.recently_updated.refreshed_24_hours',
  AI_RECOMMENDED_NEG_KEYWORD_EDIT_SLIDEOVER_HEADER = 'react.ai_recommended_neg_keywords.edit_slideover.header',
  AI_RECOMMENDED_NEG_KEYWORD_EDIT_SLIDEOVER_SUB_HEADER = 'react.ai_recommended_neg_keywords.edit_slideover.sub_header',
  AI_RECOMMENDED_NEG_KEYWORD_EDIT_SLIDEOVER_AD_CONVERSIONS = 'react.ai_recommended_neg_keywords.edit_slideover.ad_conversions',
  AI_RECOMMENDED_NEG_KEYWORD_EDIT_SLIDEOVER_CLICKS = 'react.ai_recommended_neg_keywords.edit_slideover.clicks',
  AI_RECOMMENDED_NEG_KEYWORD_EDIT_SLIDEOVER_AD_SPEND = 'react.ai_recommended_neg_keywords.edit_slideover.ad_spend',
  AI_RECOMMENDED_NEG_KEYWORD_EDIT_SLIDEOVER_CLICKS_MIN_ERROR_MESSAGE = 'react.ai_recommended_neg_keywords.edit_slideover.clicks.min_error_message',
  AI_RECOMMENDED_NEG_KEYWORD_EDIT_SLIDEOVER_AD_SPEND_MIN_ERROR_MESSAGE = 'react.ai_recommended_neg_keywords.edit_slideover.ad_spend.min_error_message',
  AI_RECOMMENDED_NEG_KEYWORD_EDIT_SLIDEOVER_DAYS = 'react.ai_recommended_neg_keywords.edit_slideover.days',
  AI_RECOMMENDED_NEG_KEYWORD_EDIT_SLIDEOVER_NOTE = 'react.ai_recommended_neg_keywords.edit_slideover.note',
  AI_RECOMMENDED_NEG_KEYWORD_EDIT_SLIDEOVER_NOTE_DESCRIPTION = 'react.ai_recommended_neg_keywords.edit_slideover.note.description',
  AI_RECOMMENDED_KEYWORD_AD_SALES_DASHBOARD_TITLE = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.dashboard_title',
  AI_RECOMMENDED_KEYWORD_AD_SALES_DASHBOARD_READY_TO_REVIEW = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.dashboard_ready_to_review',
  AI_RECOMMENDED_KEYWORD_AD_SALES_DASHBOARD_NO_RECOMMENDATIONS = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.dashboard_no_recommendations',
  AI_RECOMMENDED_KEYWORD_AD_SALES_DASHBOARD_HEADER = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.dashboard_header',
  AI_RECOMMENDED_KEYWORD_AD_SALES_DASHBOARD_SUBHEADER = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.dashboard_subheader',
  AI_RECOMMENDED_KEYWORD_AD_SALES_DASHBOARD_SECONDARY_HEADER = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.dashboard_secondary_header',
  AI_RECOMMENDED_KEYWORD_AD_SALES_DASHBOARD_SECONDARY_SUBHEADER = 'react.ai_recommended_keywords.views.projected_ad_sales_banner.dashboard_secondary_subheader',

  AI_RECOMMENDED_KEYWORD_TABLE_STATE_INDICATOR_HEADER = 'react.ai_recommended_keywords.views.table_state_indicator.header',
  AI_RECOMMENDED_KEYWORD_TABLE_STATE_INDICATOR_SUBHEADER = 'react.ai_recommended_keywords.views.table_state_indicator.subheader',
  AI_RECOMMENDED_KEYWORD_NEGATIVE_TABLE_STATE_INDICATOR_SUBHEADER = 'react.ai_recommended_keywords.views.negative_table_state_indicator.subheader',

  AI_RECOMMENDED_KEYWORD_SIDE_NAV_SPONSORED_PRODUCT_KEYWORDS = 'react.ai_recommended_keywords.views.side_nav.sponsored_products_keywords',
  AI_RECOMMENDED_KEYWORD_SIDE_NAV_SPONSORED_PRODUCT_PAT = 'react.ai_recommended_keywords.views.side_nav.sponsored_products_pat',
  AI_RECOMMENDED_KEYWORD_SIDE_NAV_TARGETS_TO_ADD = 'react.ai_recommended_keywords.views.side_nav.targets_to_add',
  AI_RECOMMENDED_KEYWORD_SIDE_NAV_TARGETS_TO_NEGATE = 'react.ai_recommended_keywords.views.side_nav.targets_to_negate',
  AI_RECOMMENDED_KEYWORD_SIDE_NAV_KEYWORDS = 'react.ai_recommended_keywords.views.side_nav.keywords',
  AI_RECOMMENDED_KEYWORD_SIDE_NAV_PAT = 'react.ai_recommended_keywords.views.side_nav.pat',

  AI_RECOMMENDED_KEYWORD_TABLE_FILTERS_SOURCES_AD_GROUP_NAME = 'react.keyword_recommendations.table.filters.sources.ad_group_name',
  AI_RECOMMENDED_KEYWORD_TABLE_FILTERS_SOURCES_CAMPAIGN_NAME = 'react.keyword_recommendations.table.filters.sources.campaign_name',
  AI_RECOMMENDED_KEYWORD_TABLE_FILTERS_SOURCES_CAMPAIGN_TARGETING_TYPE = 'react.keyword_recommendations.table.filters.sources.campaign_targeting_type',
  AI_RECOMMENDED_KEYWORD_TABLE_FILTERS_SOURCES_REPORT_NAME = 'react.keyword_recommendations.table.filters.sources.report_name',
  AI_RECOMMENDED_KEYWORD_TABLE_FILTERS_SOURCES_MATCH_TYPE = 'react.keyword_recommendations.table.filters.sources.match_type',

  SYNC_STATUS_SLIDE_OUT_GO_TO_SALES_CHANNEL = 'react.sales_channel.sync_status.slide_out.go_to_sales_channel',
  SYNC_STATUS_SLIDE_OUT_HEADER = 'react.sales_channel.sync_status.slide_out.header',
  SYNC_STATUS_SLIDE_OUT_COLUMN_AD = 'react.sales_channel.sync_status.slide_out.column_ad',
  SYNC_STATUS_SLIDE_OUT_COLUMN_PRODUCT = 'react.sales_channel.sync_status.slide_out.column_product',

  DATA_TIMING_SYNC_STATUS_SLIDE_OUT_HEADER = 'react.sales_channel.data_timing.sync_status.slide_out.header',

  BUDGET_INSIGHTS_PAGE_HEADER = 'react.budget_insights.page_header',
  BUDGET_INSIGHTS_PAGE_HEADER_DASHBOARD = 'react.budget_insights.page_header_bashboard',
  BUDGET_INSIGHTS_PAGE_GROUP_HEADER_GROUP = 'react.budget_insights.page_group_header',
  BUDGET_INSIGHTS_PAGE_DETAILS_HEADER_GROUP = 'react.budget_insights.page_details_header_group',
  BUDGET_INSIGHTS_PAGE_DETAILS_HEADER_MERCHANTS = 'react.budget_insights.page_details_header_merchant',
  BUDGET_INSIGHTS_TABLE_INPUT_PLACE_HOLDER = 'react.budget_insights.table.input_place_holder',
  BUDGET_INSIGHTS_TABLE_INPUT_GROUP_PLACE_HOLDER = 'react.budget_insights.table.group.input_place_holder',
  BUDGET_INSIGHTS_TABLE_COLUMN_CAMPAIGN_NAME = 'react.budget_insights.table.column.campaign_name',
  BUDGET_INSIGHTS_TABLE_COLUMN_CAMPAIGN_TYPE = 'react.budget_insights.table.column.campaign_type',
  BUDGET_INSIGHTS_TABLE_COLUMN_TYPE = 'react.budget_insights.table.column.type',
  BUDGET_INSIGHTS_TABLE_COLUMN_BUDGET = 'react.budget_insights.table.column.budget',
  BUDGET_INSIGHTS_TABLE_COLUMN_AD_SPEND = 'react.budget_insights.table.column.ad_spend',
  BUDGET_INSIGHTS_TABLE_COLUMN_STATUS = 'react.budget_insights.table.column.status',
  BUDGET_INSIGHTS_TABLE_COLUMN_BUDGET_UTILIZATION = 'react.budget_insights.table.column.budget_utilization',
  BUDGET_INSIGHTS_TABLE_COLUMN_PROJECTED_AD_SPEND = 'react.budget_insights.table.column.projected_ad_spend',
  BUDGET_INSIGHTS_CONTEXT_SETTER_MERCHANTS_HEADER = 'react.budget_insights.context_setter.merchants.header',
  BUDGET_INSIGHTS_CONTEXT_SETTER_MERCHANTS_PLACEHOLDER = 'react.budget_insights.context_setter.merchants.placeholder',
  BUDGET_INSIGHTS_PAGE_POPUP_GROUP_HEADER = 'react.budget_insights.popup.page.group.header',

  BUDGET_INSIGHTS_CONTEXT_SETTER_CURRENCY_HEADER = 'react.budget_insights.context_setter.currency.header',
  BUDGET_INSIGHTS_CONTEXT_SETTER_CURRENCY_PLACEHOLDER = 'react.budget_insights.context_setter.currency.placeholder',
  BUDGET_INSIGHTS_BUDGET_CAP_SLIDEOUT_HEADER = 'react.budget_insights.budget_cap_slideout.header',
  BUDGET_INSIGHTS_BUDGET_CAP_SLIDEOUT_SUB_HEADER = 'react.budget_insights.budget_cap_slideout.sub_header',
  BUDGET_INSIGHTS_BUDGET_CAP_SLIDEOUT_INPUT_PLACEHOLDER = 'react.budget_insights.budget_cap_slideout.input.placeholder',
  BUDGET_INSIGHTS_BUDGET_CAP_SLIDEOUT_SUCCESS_TOAST_DESCRIPTION = 'react.budget_insights.budget_cap_slideout.success.toast.description',
  BUDGET_INSIGHTS_BUDGET_CAP_ENTER_BUDGET_BANNER_TEXT = 'react.budget_insights.enter_budget_banner.text',
  BUDGET_INSIGHTS_BUDGET_CAP_ENTER_BUDGET_BANNER_BUTTON_LABEL = 'react.budget_insights.enter_budget_banner.button_label',
  BUDGET_INSIGHTS_PIE_CHART_AD_SPEND_HEADER = 'react.budget_insights.pie_chart.ad_spend.header',
  BUDGET_INSIGHTS_PIE_CHART_AD_SPEND_BUDGET_USAGE = 'react.budget_insights.pie_chart.ad_spend.budget_usage',
  BUDGET_INSIGHTS_PIE_CHART_PROJECTED_AD_SPEND_HEADER = 'react.budget_insights.pie_chart.projected_ad_spend.header',
  BUDGET_INSIGHTS_PIE_CHART_PROJECTED_AD_SPEND_BUDGET_USAGE = 'react.budget_insights.pie_chart.projected_ad_spend.budget_usage',
  BUDGET_INSIGHTS_PIE_CHART_PROJECTED_AD_SPEND_TOOLTIP = 'react.budget_insights.pie_chart.projected_ad_spend.tooltip',
  BUDGET_INSIGHTS_GRAPH_AD_SPEND = 'react.budget_insights.graph.ad_spend',
  BUDGET_INSIGHTS_GRAPH_CUMULATIVE_AD_SPEND = 'react.budget_insights.graph.cumulative_ad_spend',
  BUDGET_INSIGHTS_GRAPH_PROJECTED_CUMULATIVE_AD_SPEND = 'react.budget_insights.graph.projected_cumulative_ad_spend',
  BUDGET_INSIGHTS_GRAPH_PROJECTED_AD_SPEND_BELOW = 'react.budget_insights.graph.projected_ad_spend_below',
  BUDGET_INSIGHTS_GRAPH_PROJECTED_AD_SPEND_ABOVE = 'react.budget_insights.graph.projected_ad_spend_above',

  BUDGET_INSIGHTS_BUDGET_CAP_KEEP_EDIT_MERCHANT_HEADER = 'react.budget_insights.keep_edit.merchant.header',
  BUDGET_INSIGHTS_BUDGET_CAP_KEEP_EDIT_MERCHANT_BOBY = 'react.budget_insights..keep_edit.merchant.body',
  BUDGET_INSIGHTS_BUDGET_CAP_KEEP_EDIT_GROUP_HEADER = 'react.budget_insights.keep_edit.group.header',
  BUDGET_INSIGHTS_BUDGET_CAP_KEEP_EDIT_GROUP_BOBY = 'react.budget_insights..keep_edit.group.body',

  BUDGET_INSIGHTS_BUDGET_EDIT_DETAIL_HEADER = 'react.budget_insights.edit.merchant_detail.header',
  BUDGET_INSIGHTS_BUDGET_EDIT_DETAIL_MONTHLY_BUDGET = 'react.budget_insights.edit.merchant_detail.monthly_budget',
  BUDGET_INSIGHTS_BUDGET_EDIT_DETAIL_MONTHLY_BUDGET_CAP = 'react.budget_insights.edit.merchant_detail.budget_cap',

  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_BUDGET_CAP_ACTION = 'react.budget_insights.merchants.tables.header.budget_cap_action',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_ACTION = 'react.budget_insights.merchants.tables.header.action',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_BUDGET_UPDATED = 'react.budget_insights.merchants.tables.header.budget_updated',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_MERCHANTS = 'react.budget_insights.merchants.tables.header.merchants',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_MONTHLY_BUDGET = 'react.budget_insights.merchants.tables.header.monthly_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_MTD_BUDGET = 'react.budget_insights.merchants.tables.header.mtd_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_MTD_ACTUALS = 'react.budget_insights.merchants.tables.header.mtd_actuals',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_MTD_BUDGET_OF = 'react.budget_insights.merchants.tables.header.mtd_budget_of',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_DAILY_BUDGET = 'react.budget_insights.merchants.tables.header.daily_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_AVG_DAILY_AD_SPEND_L3 = 'react.budget_insights.merchants.tables.header.avg_daily_ad_spend_l3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_DAILY_AD_SPEND_REQ = 'react.budget_insights.merchants.tables.header.daily_ad_spend_req',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_FORECAST_SPEND_T3 = 'react.budget_insights.merchants.tables.header.forecast_Spend_t3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_FORECAST_SPEND_T7 = 'react.budget_insights.merchants.tables.header.forecast_Spend_t7',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_BUDGET_MANAGEMENT = 'react.budget_insights.merchants.tables.header.budget_management',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_BUDGET_MANAGEMENT_TYPE_GROUP = 'react.budget_insights.merchants.tables.budget_management_type.group',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_BUDGET_MANAGEMENT_TYPE_MERCHANT = 'react.budget_insights.merchants.tables.budget_management_type.merchant',

  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_T30_ROAS = 'react.budget_insights.merchants.tables.header.t30_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_T30_ACOS = 'react.budget_insights.merchants.tables.header.t30_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_T30_TACOS = 'react.budget_insights.merchants.tables.header.t30_tacos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_T30_AD_SALES = 'react.budget_insights.merchants.tables.header.t30_ad_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_MTD_ROAS = 'react.budget_insights.merchants.tables.header.mtd_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_MTD_SALES = 'react.budget_insights.merchants.tables.header.mtd_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_MTD_ACOS = 'react.budget_insights.merchants.tables.header.mtd_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_HEADER_COLUMN_MTD_TACOS = 'react.budget_insights.merchants.tables.header.mtd_tacos',

  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MONTHLY_BUDGET = 'react.budget_insights.merchants.tables.definition.monthly_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MANAGEMENT = 'react.budget_insights.merchants.tables.definition.budget_management',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MTD_BUDGET = 'react.budget_insights.merchants.tables.definition.mtd_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MTD_ACTUALS = 'react.budget_insights.merchants.tables.definition.mtd_actuals',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MTD_BUDGET_OF = 'react.budget_insights.merchants.tables.definition.mtd_budget_of',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_DAILY_BUDGET = 'react.budget_insights.merchants.tables.definition.daily_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_AVG_DAILY_AD_SPEND_L3 = 'react.budget_insights.merchants.tables.definition.avg_daily_ad_spend_l3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_DAILY_AD_SPEND_REQ = 'react.budget_insights.merchants.tables.definition.daily_ad_spend_req',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_FORECAST_SPEND_T3 = 'react.budget_insights.merchants.tables.definition.forecast_Spend_t3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_FORECAST_SPEND_T7 = 'react.budget_insights.merchants.tables.definition.forecast_Spend_t7',

  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_T30_TACOS = 'react.budget_insights.merchants.tables.definition.t30_tacos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_T30_ROAS = 'react.budget_insights.merchants.tables.definition.t30_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_T30_ACOS = 'react.budget_insights.merchants.tables.definition.t30_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_T30_AD_SALES = 'react.budget_insights.merchants.tables.definition.t30_ad_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MTD_TACOS = 'react.budget_insights.merchants.tables.definition.mtd_tacos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MTD_ROAS = 'react.budget_insights.merchants.tables.definition.mtd_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MTD_SALES = 'react.budget_insights.merchants.tables.definition.mtd_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MTD_ACOS = 'react.budget_insights.merchants.tables.definition.mtd_acos',

  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_CAMPAIGN_AD_TYPE = 'react.budget_insights.campaigns.tables.definition.campaign_ad_type',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_CAMPAIGN_TARGETING_TYPE = 'react.budget_insights.campaigns.tables.definition.campaign_targeting_type',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_CAMPAIGN_STATUS = 'react.budget_insights.campaigns.tables.definition.campaign_status',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_T30_ACOS = 'react.budget_insights.campaigns.tables.definition.t30_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_T30_AD_SALES = 'react.budget_insights.campaigns.tables.definition.t30_ad_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_ROAS = 'react.budget_insights.campaigns.tables.definition.mtd_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_ACOS = 'react.budget_insights.campaigns.tables.definition.mtd_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_SALES = 'react.budget_insights.campaigns.tables.definition.mtd_sales',

  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_ROAS_T30D = 'react.budget_insights.campaigns.tables.definition.roas_t30d',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MONTHLY_BUDGET = 'react.budget_insights.campaigns.tables.definition.monthly_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_BUDGET = 'react.budget_insights.campaigns.tables.definition.mtd_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_ACTUALS = 'react.budget_insights.campaigns.tables.definition.mtd_actuals',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_BUDGET_OF = 'react.budget_insights.campaigns.tables.definition.mtd_budget_of',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_DAILY_BUDGET = 'react.budget_insights.campaigns.tables.definition.daily_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_DAILY_AD_SPEND_REQ = 'react.budget_insights.campaigns.tables.definition.daily_ad_spend_req',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_FORECAST_SPEND_T3 = 'react.budget_insights.campaigns.tables.definition.forecast_Spend_t3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_FORECAST_SPEND_T7 = 'react.budget_insights.campaigns.tables.definition.forecast_Spend_t7',

  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_AD_CONVERSIONS = 'react.budget_insights.campaigns.tables.definition.mtd_ad_conversions',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_AD_CONVERSION_RATE = 'react.budget_insights.campaigns.tables.definition.mtd_ad_conversion_rate',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_AD_UNITS_SOLD = 'react.budget_insights.campaigns.tables.definition.mtd_ad_units_sold',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_IMPRESSIONS = 'react.budget_insights.campaigns.tables.definition.mtd_impressions',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_CLICKS = 'react.budget_insights.campaigns.tables.definition.mtd_Clicks',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_COST_PER_CLICK = 'react.budget_insights.campaigns.tables.definition.mtd_Cost_per_Click',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_CLICK_THROUGH_RATE = 'react.budget_insights.campaigns.tables.definition.mtd_Click_Through_Rate',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_T30_AD_CONVERSIONS = 'react.budget_insights.campaigns.tables.definition.t30_ad_conversions',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_T30_AD_CONVERSION_RATE = 'react.budget_insights.campaigns.tables.definition.t30_ad_conversion_rate',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_T30_AD_UNITS_SOLD = 'react.budget_insights.campaigns.tables.definition.t30_ad_units_sold',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_T30_IMPRESSIONS = 'react.budget_insights.campaigns.tables.definition.t30_impressions',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_T30_CLICKS = 'react.budget_insights.campaigns.tables.definition.t30_clicks',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_T30_COST_PER_CLICK = 'react.budget_insights.campaigns.tables.definition.t30_Cost_per_Click',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_T30_CLICK_THROUGH_RATE = 'react.budget_insights.campaigns.tables.definition.t30_Click_Through_Rate',

  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_MTD_AD_CONVERSION_RATE = 'react.budget_insights.campaigns.tables.formula.mtd_ad_conversion_rate',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_MTD_COST_PER_CLICK = 'react.budget_insights.campaigns.tables.formula.mtd_Cost_per_Click',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_MTD_CLICK_THROUGH_RATE = 'react.budget_insights.campaigns.tables.formula.mtd_Click_Through_Rate',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_T30_AD_CONVERSION_RATE = 'react.budget_insights.campaigns.tables.formula.t30_ad_conversion_rate',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_T30_COST_PER_CLICK = 'react.budget_insights.campaigns.tables.formula.t30_Cost_per_Click',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_T30_CLICK_THROUGH_RATE = 'react.budget_insights.campaigns.tables.formula.t30_Click_Through_Rate',

  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_NO_DATA_TITLE = 'react.budget_insights.campaigns.tables.no_data.title',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_NO_DATA_DESCRIPTION = 'react.budget_insights.campaigns.tables.no_data.description',

  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_T30_ACOS = 'react.budget_insights.campaigns.tables.formula.t30_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_T30_AD_SALES_AMAZON = 'react.budget_insights.campaigns.tables.formula.t30_ad_sales_amazon',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_T30_AD_SALES_WALMART = 'react.budget_insights.campaigns.tables.formula.t30_ad_sales_walmart',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_MTD_ROAS = 'react.budget_insights.campaigns.tables.formula.mtd_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_MTD_ACOS = 'react.budget_insights.campaigns.tables.formula.mtd_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_MTD_SALES = 'react.budget_insights.campaigns.tables.formula.mtd_sales',

  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_MTD_BUDGET = 'react.budget_insights.campaigns.tables.formula.mtd_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_DAILY_AD_SPEND_REQ = 'react.budget_insights.campaigns.tables.formula.daily_ad_spend_req',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_FORECAST_SPEND_T3 = 'react.budget_insights.campaigns.tables.formula.forecast_Spend_t3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_FORMULA_COLUMN_FORECAST_SPEND_T7 = 'react.budget_insights.campaigns.tables.formula.forecast_Spend_t7',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_BUDGET_OF_HEADER = 'react.budget_insights.campaigns.tables.definition.mtd_budget_of_header',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_DEFINITION_COLUMN_MTD_BUDGET_OF_RED = 'react.budget_insights.campaigns.tables.definition.mtd_budget_of_red',

  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_T30_TACOS = 'react.budget_insights.merchants.tables.formula.t30_tacos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_T30_ROAS = 'react.budget_insights.merchants.tables.formula.t30_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_T30_ACOS = 'react.budget_insights.merchants.tables.formula.t30_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_T30_AD_SALES_AMAZON = 'react.budget_insights.campaigns.tables.formula.t30_ad_sales_amazon',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_T30_AD_SALES_WALMART = 'react.budget_insights.campaigns.tables.formula.t30_ad_sales_walmart',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_MTD_TACOS = 'react.budget_insights.merchants.tables.formula.mtd_tacos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_MTD_ROAS = 'react.budget_insights.merchants.tables.formula.mtd_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_MTD_SALES = 'react.budget_insights.merchants.tables.formula.mtd_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_MTD_ACOS = 'react.budget_insights.merchants.tables.formula.mtd_acos',

  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_MTD_BUDGET = 'react.budget_insights.merchants.tables.formula.mtd_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_DAILY_BUDGET = 'react.budget_insights.merchants.tables.formula.daily_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_AVG_DAILY_AD_SPEND_L3 = 'react.budget_insights.merchants.tables.formula.avg_daily_ad_spend_l3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_FORMULA_COLUMN_DAILY_AD_SPEND_REQ = 'react.budget_insights.merchants.tables.formula.daily_ad_spend_req',

  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MTD_BUDGET_OF_HEADER = 'react.budget_insights.merchants.tables.definition.mtd_budget_of_header',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MTD_BUDGET_OF_RED = 'react.budget_insights.merchants.tables.definition.mtd_budget_of_red',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MTD_BUDGET_OF_YELLOW = 'react.budget_insights.merchants.tables.definition.mtd_budget_of_yellow',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_MTD_BUDGET_OF_GREEN = 'react.budget_insights.merchants.tables.definition.mtd_budget_of_green',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_FORECAST_SPEND_T3_HEADER = 'react.budget_insights.merchants.tables.definition.forecast_Spend_t3_header',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_FORECAST_SPEND_T3_RED = 'react.budget_insights.merchants.tables.definition.forecast_Spend_t3_red',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_FORECAST_SPEND_T3_YELLOW = 'react.budget_insights.merchants.tables.definition.forecast_Spend_t3_yellow',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_FORECAST_SPEND_T3_GREEN = 'react.budget_insights.merchants.tables.definition.forecast_Spend_t3_green',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_FORECAST_SPEND_T7_HEADER = 'react.budget_insights.merchants.tables.definition.forecast_Spend_t7_header',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_FORECAST_SPEND_T7_RED = 'react.budget_insights.merchants.tables.definition.forecast_Spend_t7_red',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_FORECAST_SPEND_T7_YELLOW = 'react.budget_insights.merchants.tables.definition.forecast_Spend_t7_yellow',
  BUDGET_INSIGHTS_CONTEXT_TABLE_MERCHANTS_DEFINITION_COLUMN_FORECAST_SPEND_T7_GREEN = 'react.budget_insights.merchants.tables.definition.forecast_Spend_t7_green',

  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_CAMPAIGN_NAME = 'react.budget_insights.campaigns.tables.header.campaigns_name',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_ROAS_T30D = 'react.budget_insights.campaigns.tables.header.roas_t30d',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MONTHLY_BUDGET = 'react.budget_insights.campaigns.tables.header.monthly_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_BUDGET = 'react.budget_insights.campaigns.tables.header.mtd_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_ACTUALS = 'react.budget_insights.campaigns.tables.header.mtd_actuals',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_BUDGET_OF = 'react.budget_insights.campaigns.tables.header.mtd_budget_of',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_DAILY_BUDGET = 'react.budget_insights.campaigns.tables.header.daily_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_DAILY_AD_SPEND_REQ = 'react.budget_insights.campaigns.tables.header.daily_ad_spend_req',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_FORECAST_SPEND_T3 = 'react.budget_insights.campaigns.tables.header.forecast_Spend_t3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_FORECAST_SPEND_T7 = 'react.budget_insights.campaigns.tables.header.forecast_Spend_t7',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_ROAS = 'react.budget_insights.campaigns.tables.header.mtd_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_SALES = 'react.budget_insights.campaigns.tables.header.mtd_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_ACOS = 'react.budget_insights.campaigns.tables.header.mtd_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_T30_ACOS = 'react.budget_insights.campaigns.tables.header.t30_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_T30_AD_SALES = 'react.budget_insights.campaigns.tables.header.t30_ad_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_CAMPAIGN_STATUS = 'react.budget_insights.campaigns.tables.header.campaign_status',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_CAMPAIGN_AD_TYPE = 'react.budget_insights.campaigns.tables.header.campaign_ad_type',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_CAMPAIGN_TARGETING_TYPE = 'react.budget_insights.campaigns.tables.header.campaign_targeting_type',

  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_AD_CONVERSIONS = 'react.budget_insights.campaigns.tables.header.mtd_ad_conversions',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_AD_CONVERSION_RATE = 'react.budget_insights.campaigns.tables.header.mtd_ad_conversion_rate',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_AD_UNITS_SOLD = 'react.budget_insights.campaigns.tables.header.mtd_ad_units_sold',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_IMPRESSIONS = 'react.budget_insights.campaigns.tables.header.mtd_impressions',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_CLICKS = 'react.budget_insights.campaigns.tables.header.mtd_Clicks',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_COST_PER_CLICK = 'react.budget_insights.campaigns.tables.header.mtd_Cost_per_Click',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_MTD_CLICK_THROUGH_RATE = 'react.budget_insights.campaigns.tables.header.mtd_Click_Through_Rate',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_T30_AD_CONVERSIONS = 'react.budget_insights.campaigns.tables.header.t30_ad_conversions',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_T30_AD_CONVERSION_RATE = 'react.budget_insights.campaigns.tables.header.t30_ad_conversion_rate',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_T30_AD_UNITS_SOLD = 'react.budget_insights.campaigns.tables.header.t30_ad_units_sold',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_T30_IMPRESSIONS = 'react.budget_insights.campaigns.tables.header.t30_impressions',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_T30_CLICKS = 'react.budget_insights.campaigns.tables.header.t30_clicks',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_T30_COST_PER_CLICK = 'react.budget_insights.campaigns.tables.header.t30_Cost_per_Click',
  BUDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_HEADER_COLUMN_T30_CLICK_THROUGH_RATE = 'react.budget_insights.campaigns.tables.header.t30_Click_Through_Rate',

  UDGET_INSIGHTS_CONTEXT_TABLE_CAMPAIGNS_INFO_ICON = 'react.budget_insights.campaigns.tables.info_icon',
  BUDGET_INSIGHTS_TABLE_INPUT_PLACE_HOLDER_MERCHANTS = 'react.budget_insights.table.merchants.input_place_holder',
  BUDGET_INSIGHTS_MESSAGE_GROUP_LINK_DESCRIPTION = 'react.budget_insights.group.links.description',
  BUDGET_INSIGHTS_MESSAGE_MERCHANTS_LINK_DESCRIPTION = 'react.budget_insights.merchants.links.description',

  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_GROUP = 'react.budget_insights.group.tables.header.group',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_CAMPAIGN = 'react.budget_insights.group.tables.header.campaign',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_ROAS_T30D = 'react.budget_insights.group.tables.header.roas_t30d',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_MONTHLY_BUDGET = 'react.budget_insights.group.tables.header.monthly_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_MTD_BUDGET = 'react.budget_insights.group.tables.header.mtd_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_MTD_ACTUALS = 'react.budget_insights.group.tables.header.mtd_actuals',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_MTD_BUDGET_OF = 'react.budget_insights.group.tables.header.mtd_budget_of',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_DAILY_BUDGET = 'react.budget_insights.group.tables.header.daily_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_DAILY_AD_SPEND_REQ = 'react.budget_insights.group.tables.header.daily_ad_spend_req',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_AVG_DAILY_AD_SPEND_L3 = 'react.budget_insights.group.tables.header.avg_daily_ad_spend_l3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_FORECAST_SPEND_T3 = 'react.budget_insights.group.tables.header.forecast_Spend_t3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_FORECAST_SPEND_T7 = 'react.budget_insights.group.tables.header.forecast_Spend_t7',

  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_T30_ACOS = 'react.budget_insights.group.tables.header.t30_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_T30_AD_SALES = 'react.budget_insights.group.tables.header.t30_ad_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_MTD_ROAS = 'react.budget_insights.group.tables.header.mtd_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_MTD_SALES = 'react.budget_insights.group.tables.header.mtd_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_MTD_ACOS = 'react.budget_insights.group.tables.header.mtd_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_HEADER_COLUMN_CAMPAIGNS = 'react.budget_insights.group.tables.header.campaigns',

  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_T30_ACOS = 'react.budget_insights.group.tables.definition.t30_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_T30_AD_SALES = 'react.budget_insights.group.tables.definition.t30_ad_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_MTD_ROAS = 'react.budget_insights.group.tables.definition.mtd_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_MTD_SALES = 'react.budget_insights.group.tables.definition.mtd_sales',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_MTD_ACOS = 'react.budget_insights.group.tables.definition.mtd_acos',

  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_CAMPAIGN = 'react.budget_insights.group.tables.definition.campaign',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_ROAS_T30D = 'react.budget_insights.group.tables.definition.roas_t30d',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_MONTHLY_BUDGET = 'react.budget_insights.group.tables.definition.monthly_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_MTD_BUDGET = 'react.budget_insights.group.tables.definition.mtd_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_MTD_ACTUALS = 'react.budget_insights.group.tables.definition.mtd_actuals',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_MTD_BUDGET_OF = 'react.budget_insights.group.tables.definition.mtd_budget_of',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_DAILY_BUDGET = 'react.budget_insights.group.tables.definition.daily_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_DAILY_AD_SPEND_REQ = 'react.budget_insights.group.tables.definition.daily_ad_spend_req',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_AVG_DAILY_AD_SPEND_L3 = 'react.budget_insights.group.tables.definition.avg_daily_ad_spend_l3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_FORECAST_SPEND_T3 = 'react.budget_insights.group.tables.definition.forecast_Spend_t3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_FORECAST_SPEND_T7 = 'react.budget_insights.group.tables.definition.forecast_Spend_t7',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_NO_DATA_TITLE = 'react.budget_insights.group.tables.no_data.title',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_NO_DATA_DESCRIPTION = 'react.budget_insights.group.tables.no_data.description',

  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_T30_ACOS = 'react.budget_insights.group.tables.formula.t30_acos',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_T30_AD_SALES_AMAZON = 'react.budget_insights.group.tables.formula.t30_ad_sales_amazon',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_T30_AD_SALES_WALMART = 'react.budget_insights.group.tables.formula.t30_ad_sales_walmart',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_MTD_ROAS = 'react.budget_insights.group.tables.formula.mtd_roas',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_MTD_SALES_AMAZON = 'react.budget_insights.group.tables.formula.mtd_sales_amazon',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_MTD_SALES_WALMART = 'react.budget_insights.group.tables.formula.mtd_sales_walmart',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_MTD_ACOS = 'react.budget_insights.group.tables.formula.mtd_acos',

  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_MTD_BUDGET = 'react.budget_insights.group.tables.formula.mtd_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_DAILY_BUDGET = 'react.budget_insights.group.tables.formula.daily_budget',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_AVG_DAILY_AD_SPEND_L3 = 'react.budget_insights.group.tables.formula.avg_daily_ad_spend_l3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_DAILY_AD_SPEND_REQ = 'react.budget_insights.group.tables.formula.daily_ad_spend_req',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_FORECAST_SPEND_T3 = 'react.budget_insights.group.tables.formula.forecast_Spend_t3',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_FORMULA_COLUMN_FORECAST_SPEND_T7 = 'react.budget_insights.group.tables.formula.forecast_Spend_t7',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_MTD_BUDGET_OF_HEADER = 'react.budget_insights.group.tables.definition.mtd_budget_of_header',
  BUDGET_INSIGHTS_CONTEXT_TABLE_GROUP_DEFINITION_COLUMN_MTD_BUDGET_OF_RED = 'react.budget_insights.group.tables.definition.mtd_budget_of_red',

  BUDGET_INSIGHTS_CONTEXT_CARD_BUDGET_PERIOD = 'react.budget_insights.contextSetterCard.budget_period',
  BUDGET_INSIGHTS_CONTEXT_CARD_MONTH_TO_DATE = 'react.budget_insights.contextSetterCard.month_to_date',
  BUDGET_INSIGHTS_CONTEXT_CARD_MONTH_TO_DATE_START = 'react.budget_insights.contextSetterCard.month_to_date_start',
  BUDGET_INSIGHTS_CONTEXT_CARD_MONTH_TO_DATE_END = 'react.budget_insights.contextSetterCard.month_to_date_end',
  BUDGET_INSIGHTS_CONTEXT_CARD_DAYS_REMAINING = 'react.budget_insights.contextSetterCard.days_remaining',
  BUDGET_INSIGHTS_CONTEXT_CARD_DAYS_COMPLETE = 'react.budget_insights.contextSetterCard.days_complete',
  BUDGET_INSIGHTS_CONTEXT_CARD_SUMMARY_MONTHLY_BUDGET = 'react.budget_insights.contextSettercardsummary.monthly_budget',
  BUDGET_INSIGHTS_CONTEXT_CARD_SUMMARY_MONTH_TO_DATE = 'react.budget_insights.contextSettercardsummary.month_to_date',
  BUDGET_INSIGHTS_CONTEXT_CARD_SUMMARY_DAILY_AD_SPEND = 'react.budget_insights.contextSettercardsummary.daily_ad_spend',

  BUDGET_INSIGHTS_CONTEXT_CARD_SUMMARY_FORECAST_TOTAL = 'react.budget_insights.contextSettercardsummary.forecast_total',
  BUDGET_INSIGHTS_CONTEXT_CARD_SUMMARY_DAILY_AD_SPEND_REQ = 'react.budget_insights.contextSettercardsummary.daily_ad_spend_req',
  BUDGET_INSIGHTS_MISSING = 'react.budget_insights.missing',
  BUDGET_INSIGHTS_CONTEXT_SELECT_OPTION_GROUP = 'react.budget_insights.select_options.group',
  BUDGET_INSIGHTS_CONTEXT_SELECT_OPTION_GROUP_DESC = 'react.budget_insights.select_options.group.description',
  BUDGET_INSIGHTS_CONTEXT_SELECT_OPTION_MERCHANTS = 'react.budget_insights.select_options.merchant',
  BUDGET_INSIGHTS_CONTEXT_SELECT_OPTION_MERCHANTS_DESC = 'react.budget_insights.select_options.merchant.description',

  BUDGET_INSIGHTS_COLUMN_MANAGER_GROUPS_BUDGET = 'react.budget_insights.columns_manager.groups.budget',
  BUDGET_INSIGHTS_COLUMN_MANAGER_GROUPS_MONTH_TO_DATE = 'react.budget_insights.columns_manager.groups.month_to_date',
  BUDGET_INSIGHTS_COLUMN_MANAGER_GROUPS_DAILY = 'react.budget_insights.columns_manager.groups.daily',
  BUDGET_INSIGHTS_COLUMN_MANAGER_GROUPS_FORECAST = 'react.budget_insights.columns_manager.groups.forecast',
  BUDGET_INSIGHTS_COLUMN_MANAGER_GROUPS_MERCHANT_OVERVIEW = 'react.budget_insights.columns_manager.groups.merchant_overview',
  BUDGET_INSIGHTS_COLUMN_MANAGER_GROUPS_OVERVIEW_GROUP = 'react.budget_insights.columns_manager.groups.group_overview',
  BUDGET_INSIGHTS_COLUMN_MANAGER_GROUPS_CAMPAIGN_OVERVIEW = 'react.budget_insights.columns_manager.groups.campaign_overview',

  MANAGED_EDITION_TITLE = 'react.managed_edition.title',
  MANAGED_EDITION_SHORT_TITLE = 'react.managed_edition.short.title',
  MANAGED_EDITION_YOUR_ANALYST = 'react.managed_edition.your_analyst',
  MANAGED_EDITION_YOUR_CHANNEL_ANALYST = 'react.managed_edition.your_channel_analyst',

  SKU_CATALOG_SKU_GROUPING_ACTION_BAR_SKUS_LABEL = 'react.sku_catalog.sku_grouping.action_bar.sku_label',
  SKU_CATALOG_SKU_GROUPING_ACTION_BAR_GROUPS_LABEL = 'react.sku_catalog.sku_grouping.action_bar.group_label',
  SKU_CATALOG_SKU_GROUPING_ACTION_DELETE_GROUP_CONFIRMATION_HEADER = 'react.sku_catalog.sku_grouping.action.delete.header',
  SKU_CATALOG_SKU_GROUPING_ACTION_DELETE_GROUP_CONFIRMATION_BODY = 'react.sku_catalog.sku_grouping.action.delete.body',
  SKU_CATALOG_SKU_GROUPING_ACTION_REMOVE_FROM_GROUP_CONFIRMATION_HEADER = 'react.sku_catalog.sku_grouping.action.remove.header',
  SKU_CATALOG_SKU_GROUPING_ACTION_REMOVE_FROM_GROUP_CONFIRMATION_ERROR_HEADER = 'react.sku_catalog.sku_grouping.action.remove.error.header',
  SKU_CATALOG_SKU_GROUPING_ACTION_REMOVE_FROM_GROUP_CONFIRMATION_BODY = 'react.sku_catalog.sku_grouping.action.remove.body',
  SKU_CATALOG_SKU_GROUPING_ACTION_REMOVE_FROM_GROUP_CONFIRMATION_DESCRIPTION_WARNING = 'react.sku_catalog.sku_grouping.action.remove.description_warning',

  MULTIPLE_CATALOG_DASHBOARD_HEADER = 'react.multiple_catalog_dashboard.header',
  MULTIPLE_CATALOG_DASHBOARD_INVENTORY_UNITS_HEADER = 'react.multiple_catalog_dashboard.glossary.inventory_units.header',
  MULTIPLE_CATALOG_DASHBOARD_INVENTORY_UNITS_DESCRIPTION = 'react.multiple_catalog_dashboard.glossary.inventory_units.description',

  SIDEBAR_TITLE = 'react.setup.sidebar.title',
  NON_COMMUNITY_BLOCK_ONE = 'react.setup.sidebar.non_community.block_one',
  NON_COMMUNITY_BLOCK_TWO = 'react.setup.sidebar.non_community.block_two',
  NON_COMMUNITY_BLOCK_THREE = 'react.setup.sidebar.non_community.block_three',
  NON_COMMUNITY_BLOCK_FOUR = 'react.setup.sidebar.non_community.block_four',
  NON_COMMUNITY_BLOCK_FIVE = 'react.setup.sidebar.non_community.block_five',

  COMMUNITY_BLOCK_ONE = 'react.setup.sidebar.community.block_one',
  COMMUNITY_BLOCK_TWO = 'react.setup.sidebar.community.block_two',
  COMMUNITY_BLOCK_THREE = 'react.setup.sidebar.community.block_three',
  COMMUNITY_BLOCK_FOUR = 'react.setup.sidebar.community.block_four',
  COMMUNITY_BLOCK_FIVE = 'react.setup.sidebar.community.block_five',

  SUCCESS_HEADER = 'react.setup.sidebar.success.header',
  SUCCESS_SUB_HEADER = 'react.setup.sidebar.success.sub_header',
  SUCCESS_CONTINUE_HEADER = 'react.setup.sidebar.success.continue_header',
  SUCCESS_CONTINUE_SUB_HEADER = 'react.setup.sidebar.success.continue_sub_header',
  SUCCESS_CONTINUE_TITLE = 'react.setup.sidebar.success.continue_title',
  WELCOME_BACK_TITLE = 'react.setup.sidebar.success.welcome_back_header',

  PRODUCT_INSIGHTS_PAGE_TITLE = 'react.page.product_insights.title',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_TITLE = 'react.page.product_insights.custom_insights.title',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_BREADCRUMB_TITLE = 'react.page.product_insights.custom_insights.breadcrumb.title',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_CREATE_BUTTON_TEXT = 'react.page.product_insights.custom_insights.create_button_text',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_CREATE_NEW_INSIGHT_TEXT = 'react.page.product_insights.custom_insights.create_new_insight_text',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_CARD_METRIC_WHEN = 'react.page.product_insights.custom_insights.card.metric.text.when',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_CARD_METRIC_OVER = 'react.page.product_insights.custom_insights.card.metric.text.over',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_CARD_METRIC_UPDATED_TEXT = 'react.page.product_insights.custom_insights.card.metric.updated_text',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_CARD_ZERO_PRODUCTS_FOUND_TEXT = 'react.page.product_insights.custom_insights.card.zero_products_found_text',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_CARD_METRIC_CREATE_INSIGHT_TITLE = 'react.page.product_insights.custom_insights.card.metric.create_insight_title',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_UPGRADE_CARD_TITLE = 'react.page.product_insights.custom_insights.upgrade_card.title',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_UPGRADE_CARD_DESCRIPTION = 'react.page.product_insights.custom_insights.upgrade_card.description',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_PAGE_NO_DATA_TITLE = 'react.page.product_insights.custom_insights.page.no_data.title',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_CARD_SELECTION_TEXT = 'react.page.product_insights.custom_insights.page.card.selection.text',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_PAGE_NO_DATA_DESCRIPTION = 'react.page.product_insights.custom_insights.page.no_data.description',

  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_NOTIFICATION_SETTINGS_SLIDEROVER_WEEKLY_REPORT_EMAIL_PREFERENCE_UPDATED = 'react.page.product_insights.custom_insights.notification.settings.slideover.weekly_report.email_preference_updated',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_NOTIFICATION_SETTINGS_SLIDEOVER_HEADING = 'react.page.product_insights.custom_insights.notification.settings.slideover.heading',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_NOTIFICATION_SETTINGS_SLIDEROVER_DAILY_REPORT_HEADER = 'react.page.product_insights.custom_insights.notification.settings.slideover.daily_report.header',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_NOTIFICATION_SETTINGS_SLIDEROVER_DAILY_REPORT_DESCRIPTION = 'react.page.product_insights.custom_insights.notification.settings.slideover.daily_report.description',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_NOTIFICATION_SETTINGS_SLIDEROVER_DAILY_REPORT_OPTION_IN_APP = 'react.page.product_insights.custom_insights.notification.settings.slideover.daily_report.option.in_app',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_NOTIFICATION_SETTINGS_SLIDEROVER_DAILY_REPORT_OPTION_EMAIL = 'react.page.product_insights.custom_insights.notification.settings.slideover.daily_report.option.email',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_NOTIFICATION_SETTINGS_GLOSSARY_LABEL = 'react.page.product_insights.custom_insights.notification.settings.slideover.glossary.label',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_NOTIFICATION_SETTINGS_GLOSSARY_DAILY_REPORT_LABEL = 'react.page.product_insights.custom_insights.notification.settings.slideover.glossary.daily_report.heading',
  PRODUCT_INSIGHTS_PAGE_CUSTOM_INSIGHTS_NOTIFICATION_SETTINGS_GLOSSARY_DAILY_REPORT_DESCRIPTION = 'react.page.product_insights.custom_insights.notification.settings.slideover.glossary.daily_report.description',
  PRODUCT_INSIGHTS_PAGE_UPGRADE_TO_MANAGED_FOR_CUSTOM_INSIGHTS = 'react.page.product_insights.custom_insights.page.upgrade_to_managed',
  PRODUCT_INSIGHTS_PAGE_INVENTORY_PIE_HEADER = 'react.page.product_insights.inventory.pie_header',
  PRODUCT_INSIGHTS_PAGE_INVENTORY_PIE_DESCRIPTION = 'react.page.product_insights.inventory.description',
  PRODUCT_INSIGHTS_PAGE_INVENTORY_ACTIVE_STOCK_HEADER = 'react.page.product_insights.inventory.active_stock.header',
  PRODUCT_INSIGHTS_PAGE_INVENTORY_TOTAL_INVENTORY_HEADER = 'react.page.product_insights.inventory.total_inventory.header',
  PRODUCT_INSIGHTS_PAGE_OUT_OF_STOCK_CARD_HEADER = 'react.page.product_insights.out_of_stock_card.header',
  PRODUCT_INSIGHTS_PAGE_OUT_OF_STOCK_CARD_EST_MISSED_7_DAY_SALES = 'react.page.product_insights.out_of_stock_card.est_missed_7_day_sales',
  PRODUCT_INSIGHTS_PAGE_OUT_OF_STOCK_CARD_TOTAL_7_DAY_SALES = 'react.page.product_insights.out_of_stock_card.total_7_day_sales',
  PRODUCT_INSIGHTS_PAGE_OUT_OF_STOCK_CARD_PRODUCTS_IN_STOCK = 'react.page.product_insights.out_of_stock_card.products_in_stock',
  PRODUCT_INSIGHTS_PAGE_OUT_OF_STOCK_CARD_SEE_ALL_LINK = 'react.page.product_insights.out_of_stock_card.see_all_link',
  PRODUCT_INSIGHTS_PAGE_OUT_OF_STOCK_CARD_NO_PRODUCTS = 'react.page.product_insights.out_of_stock_card.no_products',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_PAGE_LABEL_IN_STOCK = 'react.page.product_insights.out_of_stock.label.in_stock',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_PAGE_LABEL_OUT_OF_STOCK = 'react.page.product_insights.out_of_stock.label.out_of_stock',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_PAGE_LABEL_AVAILABLE_TO_SELL = 'react.page.product_insights.out_of_stock.label.available_to_sell',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_PAGE_LABEL_INBOUND = 'react.page.product_insights.out_of_stock.label.inbound',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_PAGE_LABEL_RESERVED = 'react.page.product_insights.out_of_stock.label.reserved',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_PAGE_LABEL_UNFULFILLABLE = 'react.page.product_insights.out_of_stock.label.unfulfillable',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_PAGE_GRAPH_LEGEND_TOTAL_SALES = 'react.page.product_insights.out_of_stock.graph.lagend.total_sales',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_ACCORDION_LABEL = 'react.pages.campaignFlexibility.dashboard.step_1.accordian.label',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_2_ACCORDION_LABEL = 'react.pages.campaignFlexibility.dashboard.step_2.accordian.label',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_3_ACCORDION_LABEL = 'react.pages.campaignFlexibility.dashboard.step_3.accordian.label',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_4_ACCORDION_LABEL = 'react.pages.campaignFlexibility.dashboard.step_4.accordian.label',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_5_ACCORDION_LABEL = 'react.pages.campaignFlexibility.dashboard.step_5.accordian.label',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNTYPE_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.campaignType.title',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNTYPE_KT_SUBHEADER = 'react.pages.campaignFlexibility.dashboard.step_1.campaignType.kt.subheader',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNTYPE_PAT_SUBHEADER = 'react.pages.campaignFlexibility.dashboard.step_1.campaignType.pat.subheader',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNTYPE_KT_PAT_SUBHEADER = 'react.pages.campaignFlexibility.dashboard.step_1.campaignType.kt_pat.subheader',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNTYPE_KT_CONTENT = 'react.pages.campaignFlexibility.dashboard.step_1.campaignType.kt.content',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNTYPE_PAT_CONTENT = 'react.pages.campaignFlexibility.dashboard.step_1.campaignType.pat.content',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNTYPE_KT_PAT_CONTENT = 'react.pages.campaignFlexibility.dashboard.step_1.campaignType.kt_pat.content',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_LINK_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.link.title',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_BASIC_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.basic.title',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_BASIC_CONTENT = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.basic.content',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_BASIC_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.basic.description',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_WALMART_CAMPAIGNSTRATEGY_BASIC_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.walmart.campaignStrategy.basic.description',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_BRAND_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.brand.title',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_BRAND_CONTENT = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.brand.content',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_BRAND_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.brand.description',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_WALMART_CAMPAIGNSTRATEGY_BRAND_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.walmart.campaignStrategy.brand.description',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_ADVANCE_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.advance.title',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_ADVANCE_CONTENT = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.advance.content',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_ADVANCE_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.advance.description',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_WALMART_CAMPAIGNSTRATEGY_ADVANCE_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.walmart.campaignStrategy.advance.description',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_PAT_BASIC_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.pat.basic.title',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_PAT_BASIC_CONTENT = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.pat.basic.content',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_PAT_BASIC_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.pat.basic.description',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_WALMART_CAMPAIGNSTRATEGY_PAT_BASIC_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.walmart.pat.campaignStrategy.basic.description',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_PAT_BRAND_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.pat.brand.title',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_PAT_BRAND_CONTENT = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.pat.brand.content',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_PAT_BRAND_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.pat.brand.description',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_WALMART_CAMPAIGNSTRATEGY_PAT_BRAND_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.walmart.campaignStrategy.pat.brand.description',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_PAT_ADVANCE_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.pat.advance.title',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_PAT_ADVANCE_CONTENT = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.pat.advance.content',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_PAT_ADVANCE_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.pat.advance.description',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_WALMART_CAMPAIGNSTRATEGY_PAT_ADVANCE_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.walmart.campaignStrategy.pat.advance.description',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_CUSTOM_TITLE = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.custom.title',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_CUSTOM_CONTENT = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.custom.content',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGNSTRATEGY_CUSTOM_DESCRIPTION = 'react.pages.campaignFlexibility.dashboard.step_1.campaignStrategy.custom.description',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGN_STRUCTURE_CUSTOM_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.select_custom_campaignStructure.header',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_AD_GROUP_STRUCTURE_CUSTOM_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.select_custom_adGroupStructure.header',
  CAMPAIGN_FLEXIBILITY_SELECT_CAMPAIGN_TYPE_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.select_campaign_type.header',
  CAMPAIGN_FLEXIBILITY_SELECT_CAMPAIGN_STRATEGY_HEADER = 'react.pages.campaignFlexibility.dashboard.step_1.select_campaign_strategy.header',
  CAMPAIGN_FLEXIBILITY_STEPPER_PRE_SETUPCAMPAIGNS = 'react.pages.campaignFlexibility.dashboard.stepper.pre.setupCampaigns',
  CAMPAIGN_FLEXIBILITY_STEPPER_PRE_ADDPRODUCTS = 'react.pages.campaignFlexibility.dashboard.stepper.pre.addProducts',
  CAMPAIGN_FLEXIBILITY_STEPPER_PRE_ADDPRODUCTS_GOAL = 'react.pages.campaignFlexibility.dashboard.stepper.pre.addProducts_Goal',
  CAMPAIGN_FLEXIBILITY_STEPPER_PRE_PREVIEW_AND_LAUNCH = 'react.pages.campaignFlexibility.dashboard.stepper.pre.preview_and_launch',
  CREATE_SP_KEYWORDS_CAMPAIGN_MENU_NEW_KEYWORDS = 'react.create_campaign.menu.sp_keywords_campaign',
  CREATE_SP_KEYWORDS_CAMPAIGN_MENU_NEW_KEYWORDS_DISABLE = 'react.create_keywords.menu.new_keywords.disabled',
  CAMPAIGN_FLEXIBILITY_SAVE_CHANGES_HEADER = 'react.pages.campaignFlexibility.dashboard.save_changes_header',
  CAMPAIGN_FLEXIBILITY_CHANGES_BODY = 'react.pages.campaignFlexibility.dashboard.save_changes_body',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_PRODUCT_EXISTING_CAMPAIGN = 'react.campaignFlexibility.dashboard.product.existing_campaign',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_LOADING_HEADING = 'react.campaignFlexibility.dashboard.product.load_campaigns.heading',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_LOADING_SUB_HEADING = 'react.campaignFlexibility.dashboard.product.load_campaigns.sub_heading',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STATUS_PENDING = 'react.campaignFlexibility.dashboard.product.status.pending',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_3_KEYWORD_NEXT_TOOLTIPCONTENT_LABEL = 'react.pages.campaignFlexibility.dashboard.step_3.nexttooltipContent.keywword_label',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_3_PAT_NEXT_TOOLTIPCONTENT_LABEL = 'react.pages.campaignFlexibility.dashboard.step_3.nexttooltipContent.PAT_label',
  CAMPAIGN_FLEXIBILITY_LAUNCH_WARNING_HEADER = 'react.pages.campaignFlexibility.launch.warning.header',
  CAMPAIGN_FLEXIBILITY_LAUNCH_WARNING_BODY = 'react.pages.campaignFlexibility.launch.warning.body',
  CAMPAIGN_FLEXIBILITY_LAUNCH_WARNING_MAIN_ACTION = 'react.pages.campaignFlexibility.launch.warning.main_action.label',
  CAMPAIGN_FLEXIBILITY_LAUNCH_WARNING_SECONDARY_ACTION = 'react.pages.campaignFlexibility.launch.warning.secondary_action.label',
  CAMPAIGN_FLEXIBILITY_LAUNCH_WARNING_BANNER_ISSUE_COUNT = 'react.pages.campaignFlexibility.launch.warning_banner.issue_count.label',
  CAMPAIGN_FLEXIBILITY_LAUNCH_WARNING_BANNER_NO_KEYWORD = 'react.pages.campaignFlexibility.launch.warning_banner.no_keyword.label',
  CAMPAIGN_FLEXIBILITY_LAUNCH_WARNING_BANNER_MISSING_ACOS_LIMIT = 'react.pages.campaignFlexibility.launch.warning_banner.missing_acos_limit.label',

  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_AD_GROUP_LIMIT_MODAL_HEADER = 'react.pages.campaignFlexibility.dashboard.step_2.products_table.ad_group_limit.modal.header',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_AD_GROUP_LIMIT_MODAL_BODY = 'react.pages.campaignFlexibility.dashboard.step_2.products_table.ad_group_limit.modal.body',
  CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_1_CAMPAIGN_LIMIT_MODAL_BODY = 'react.pages.campaignFlexibility.dashboard.step_2.products_table.campaign_limit.modal.header"',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_AI_RECOMMENDED_LABEL = 'react.pages.campaignFlexibility.summary.keyword.label.ai_recommended',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_TOTAL_SELECTED_LABEL = 'react.pages.campaignFlexibility.summary.keyword.label.total_selected',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_ADGROUP_WITHOUT_LABEL = 'react.pages.campaignFlexibility.summary.keyword.label.without_keyword',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_USERADD_LABEL = 'react.pages.campaignFlexibility.summary.keyword.label.user_Add',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_READY_TO_LAUNCH = 'react.pages.campaignFlexibility.summary.ready_to_launch',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_BREAKDWON_LAUNCH = 'react.pages.campaignFlexibility.summary.breakdown_launch',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_BREAKDWON_LAUNCH_BRAND = 'react.pages.campaignFlexibility.summary.breakdown_launch.brand',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_BREAKDWON_LAUNCH_COMPETITOR = 'react.pages.campaignFlexibility.summary.breakdown_launch.competitor',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_BREAKDWON_LAUNCH_GENERIC = 'react.pages.campaignFlexibility.summary.breakdown_launch.generic',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_BREAKDWON_LAUNCH_NO_TAG = 'react.pages.campaignFlexibility.summary.breakdown_launch.No_tag',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_BREAKDWON_LAUNCH_CATEGORY = 'react.pages.campaignFlexibility.summary.breakdown_launch.category',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_BREAKDWON_LAUNCH_ASIN = 'react.pages.campaignFlexibility.summary.breakdown_launch.asin',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_KEYWORD_SELLER_ADD_LABEL = 'react.pages.campaignFlexibility.summary.keyword.label.seller_Add',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_PAT_WITHOUT_TARGET_LABEL = 'react.pages.campaignFlexibility.summary.pat.label.without_targets',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_PAT_ASIN_TARGET_LABEL = 'react.pages.campaignFlexibility.summary.pat.label.asin_targets',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_PAT_CATEGORY_TARGET_LABEL = 'react.pages.campaignFlexibility.summary.pat.label.category_targets',
  CAMPAIGN_FLEXIBILITY_SUMMARY_CARD_PAT_ASIN_AND_CATEGORY_TARGET_LABEL = 'react.pages.campaignFlexibility.summary.pat.label.asin_and_category_both_targets',
  CAMPAIGN_FLEXIBILITY_PRODCUT_ERROR_HEADING = 'react.pages.campaignFlexibility.product.error.heading',
  CAMPAIGN_FLEXIBILITY_PRODCUT_ERROR_BODAY = 'react.pages.campaignFlexibility.product.error.body',
  CAMPAIGN_FLEXIBILITY_PRODCUT_BULKUPLOAD_CHILD_SKUS = 'react.pages.campaignFlexibility.product.bulkupload.label.child_skus',
  CAMPAIGN_FLEXIBILITY_PRODCUT_BULKUPLOAD_CHILD_EXT_ITEM_ID = 'react.pages.campaignFlexibility.product.bulkupload.label.child_ext_item_id',
  CAMPAIGN_FLEXIBILITY_PRODCUT_BULKUPLOAD_PARTENT_SKUS = 'react.pages.campaignFlexibility.product.bulkupload.label.partent_skus',
  CAMPAIGN_FLEXIBILITY_PRODCUT_BULKUPLOAD_PARTENT_EXT_ITEM_ID = 'react.pages.campaignFlexibility.product.bulkupload.label.partent_ext_item_id',
  CAMPAIGN_FLEXIBILITY_PRODCUT_BULKUPLOAD_WALMART_CHILD_SKUS = 'react.pages.campaignFlexibility.product.bulkupload.walmart.label.child_skus',
  CAMPAIGN_FLEXIBILITY_PRODCUT_BULKUPLOAD_WALMART_CHILD_EXT_ITEM_ID = 'react.pages.campaignFlexibility.product.bulkupload.walmart.label.child_ext_item_id',
  CAMPAIGN_FLEXIBILITY_PRODCUT_BULKUPLOAD_WALMART_PARTENT_SKUS = 'react.pages.campaignFlexibility.product.bulkupload.label.walmart.partent_skus',
  CAMPAIGN_FLEXIBILITY_PRODCUT_BULKUPLOAD_WALMART_PARTENT_EXT_ITEM_ID = 'react.pages.campaignFlexibility.product.bulkupload.walmart.label.partent_ext_item_id',

  CAMPAIGN_FLEXIBILITY_NAME_UPLOAD_BULKUPLOAD_NAME = 'react.pages.campaignFlexibility.nameUpload.bulkupload.name',
  CAMPAIGN_FLEXIBILITY_NAMEUPLOAD_MODAL_DOWNLOAD_STEP_HEADER = 'react.pages.campaignFlexibility.nameUpload.download_step.header',
  CAMPAIGN_FLEXIBILITY_NAMEUPLOAD_MODAL_DOWNLOAD_STEP_DESCRIPTION = 'react.pages.campaignFlexibility.nameUpload.download_step.description',
  CAMPAIGN_FLEXIBILITY_NAMEUPLOAD_MODAL_DOWNLOAD_STEP_INSTRUCTIONS = 'react.pages.campaignFlexibility.nameUpload.download_step.instructions',
  CAMPAIGN_FLEXIBILITY_NAMEUPLOAD_MODAL_DOWNLOAD_STEP_BUTTON = 'react.pages.campaignFlexibility.nameUpload.download_step.button',
  CAMPAIGN_FLEXIBILITY_NAMEUPLOAD_MODAL_MORE_INFO_STEP_HEADER = 'react.pages.campaignFlexibility.nameUpload.more_info_step.header',
  CAMPAIGN_FLEXIBILITY_NAMEUPLOAD_MODAL_MORE_INFO_STEP_DESCRIPTION = 'react.pages.campaignFlexibility.nameUpload.more_info_step.description',
  CAMPAIGN_FLEXIBILITY_NAMEUPLOAD_MODAL_UPLOAD_FILE_STEP_HEADER = 'react.pages.campaignFlexibility.nameUpload..upload_file_step.header',
  CAMPAIGN_FLEXIBILITY_NAMEUPLOAD_MODAL_UPLOAD_FILE_STEP_DESCRIPTION = 'react.pages.campaignFlexibility.nameUpload.upload_file_step.description',

  CAMPAIGN_FLEXIBILITY_PRODCUT_BULKUPLOAD_ADD_BUTTON = 'react.pages.campaignFlexibility.product.bulkupload.label.addButton',
  CAMPAIGN_FLEXIBILITY_PRODCUT_BULKUPLOAD_ADDED = 'react.pages.campaignFlexibility.product.bulkupload.label.added',
  CAMPAIGN_FLEXIBILITY_PRODCUT_BULKUPLOAD_TOOLTIP_ENTER_PRODUCTS = 'react.pages.campaignFlexibility.product.bulkupload.tooltip.enter_product',

  PRODUCT_INSIGHTS_OUT_OF_STOCK_SLIDEOUT_HEADER = 'react.page.product_insights.out_of_stock.slideout.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_SLIDEOUT_DESCRIPTION = 'react.page.product_insights.out_of_stock.slideout.description',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_PRODUCT = 'react.page.product_insights.out_of_stock.table.product.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_EST_MISSED_SALES = 'react.page.product_insights.out_of_stock.table.est_missed_sales.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_OUT_OF_STOCK_DATE = 'react.page.product_insights.out_of_stock.table.out_of_stock_date.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_UNITS_SOLD = 'react.page.product_insights.out_of_stock.table.units_sold.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHTS_TABLE_UNITS_SOLD = 'react.page.product_insights.custom_insights.table.units_sold.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHTS_TABLE_CURRENT_UNITS_SOLD = 'react.page.product_insights.custom_insights.table.current_units_sold.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHTS_TABLE_PRIOR_UNITS_SOLD = 'react.page.product_insights.custom_insights.table.prior_units_sold.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_ACOS = 'react.page.product_insights.out_of_stock.table.acos.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_TACOS = 'react.page.product_insights.out_of_stock.table.tacos.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_AD_SALES = 'react.page.product_insights.out_of_stock.table.ad_sales.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_AD_SPEND = 'react.page.product_insights.out_of_stock.table.ad_spend.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_PERCENTAFE_AD_SPEND = 'react.page.product_insights.out_of_stock.table.percentage_ad_spend.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_PERCENTAGE_AD_SALES = 'react.page.product_insights.out_of_stock.table.percentage_ad_sales.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_PERCENTAGE_TOTAL_SALES = 'react.page.product_insights.out_of_stock.table.percentage_total_sales.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_AD_SPEND_PER_UNIT = 'react.page.product_insights.out_of_stock.table.ad_spend_per_unit.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_CAMPAIGNS = 'react.page.product_insights.out_of_stock.table.campaigns.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_SELL_THROUGH_RATE = 'react.page.product_insights.out_of_stock.table.sell_through_rate.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_HEALTHY_INVENTORY_VALUE = 'react.page.product_insights.out_of_stock.table.healthy_inventory_value.header',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_GLOSSARY_HEADING = 'react.page.product_insights.out_of_stock.table.glossary.heading',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_PRODUCTS_COUNT = 'react.page.product_insights.out_of_stock.table.out_of_stock_table.product_count',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_EST_MISSED_SALES_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.est_missed_sales.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_EST_MISSED_SALES_GLOSSARY_FORMULA = 'react.page.product_insights.out_of_stock.table.est_missed_sales.glossary.formula',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_PERCENT_AD_SPEND_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.percentage_ad_spend.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_PERCENT_AD_SPEND_GLOSSARY_FORMULA = 'react.page.product_insights.out_of_stock.table.percentage_ad_spend.glossary.formula',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_PERCENT_AD_SALES_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.percentage_ad_sales.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_PERCENT_AD_SALES_GLOSSARY_FORMULA = 'react.page.product_insights.out_of_stock.table.percentage_ad_sales.glossary.formula',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_PERCENT_TOTAL_SALES_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.percentage_total_sales.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_PERCENT_TOTAL_SALES_GLOSSARY_FORMULA = 'react.page.product_insights.out_of_stock.table.percentage_total_sales.glossary.formula',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_AD_SPEND_PER_UNIT_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.ad_spend_per_unit.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_AD_SPEND_PER_UNIT_GLOSSARY_FORMULA = 'react.page.product_insights.out_of_stock.table.ad_spend_per_unit.glossary.formula',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_OUT_OF_STOCK_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.out_of_stock.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_OUT_OF_STOCK_DATE_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.out_of_stock_date.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_CAMPAIGNS_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.campaigns.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_SELL_THROUGH_RATE_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.sell_through_rate.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_HEALTHY_INVENTORY_VALUE_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.healthy_inventory_value.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_EMPTY_MESSAGE = 'react.page.product_insights.out_of_stock.table.empty.messgae',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHTS_TABLE_SEARCH_PLACEHOLDER = 'react.page.product_insights.custom_insights.search_placeholder',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_TOTAL_SALES = 'react.page.product_insights.custom_insights.table.total_sales.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_CURRENT_TOTAL_SALES = 'react.page.product_insights.custom_insights.table.current_total_sales.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_PRIOR_TOTAL_SALES = 'react.page.product_insights.custom_insights.table.prior_total_sales.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_AD_SALES = 'react.page.product_insights.custom_insights.table.ad_sales.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_CURRENT_AD_SALES = 'react.page.product_insights.custom_insights.table.current_ad_sales.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_PRIOR_AD_SALES = 'react.page.product_insights.custom_insights.table.prior_ad_sales.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_ACOS = 'react.page.product_insights.custom_insights.table.acos.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_CURRENT_ACOS = 'react.page.product_insights.custom_insights.table.current_acos.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_PRIOR_ACOS = 'react.page.product_insights.custom_insights.table.prior_acos.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_TACOS = 'react.page.product_insights.custom_insights.table.tacos.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_CURRENT_TACOS = 'react.page.product_insights.custom_insights.table.current_tacos.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_PRIOR_TACOS = 'react.page.product_insights.custom_insights.table.prior_tacos.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_EST_GROSS_MARGIN = 'react.page.product_insights.custom_insights.table.est_gross_margin.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_CURRENT_EST_GROSS_MARGIN = 'react.page.product_insights.custom_insights.table.current_est_gross_margin.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_PRIOR_EST_GROSS_MARGIN = 'react.page.product_insights.custom_insights.table.prior_est_gross_margin.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_CLICKS = 'react.page.product_insights.custom_insights.table.clicks.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_CURRENT_CLICKS = 'react.page.product_insights.custom_insights.table.current_clicks.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_PRIOR_CLICKS = 'react.page.product_insights.custom_insights.table.prior_clicks.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_ACTIVE_CAMPAIGNS = 'react.page.product_insights.custom_insights.table.active_campaigns.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_CURRENT_RATE_OF_SALES = 'react.page.product_insights.custom_insights.table.current_rate_of_sales.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_RATE_OF_SALES = 'react.page.product_insights.custom_insights.table.rate_of_sales.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_PRIOR_RATE_OF_SALES = 'react.page.product_insights.custom_insights.table.prior_rate_of_sales.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_UNITS_SOLD_PER_DAY_HEADER = 'react.page.product_insights.custom_insights.table.units_sold_per_day.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_INVENTORY_LEVEL = 'react.page.product_insights.custom_insights.table.inventory_level.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_INVENTORY_LEVEL_RATE_OF_SALE = 'react.page.product_insights.custom_insights.table.inventory_level.header.rate_of_sale',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_PROJECTED_STOCK_OUT_DATE = 'react.page.product_insights.custom_insights.table.projected_stockout_date.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_DAYS_OF_INVENTORY = 'react.page.product_insights.custom_insights.table.days_of_inventory.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_RATE_OF_SALE_CHANGE = 'react.page.product_insights.custom_insights.table.rate_of_sale_change.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_CURRENT_AND_BASELINE_GROUP_HEADER = 'react.page.product_insights.custom_insights.table.current_and_baseline.group.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_HEALTHY_INVENTORY_LEVEL = 'react.page.product_insights.custom_insights.table.healthy_inventory_level.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_SELL_THROUGH_RATE = 'react.page.product_insights.custom_insights.table.sell_through_rate.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_RECOMMENDED_REPLENISH_QUANTITY = 'react.page.product_insights.custom_insights.table.recommended_replenish_quantity.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_DEVIATION = 'react.page.product_insights.custom_insights.table.deviation.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_CURRENT_GROUP_HEADER = 'react.page.product_insights.custom_insights.table.current.group_header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_CURRENT_GROUP_HEADER_DAYS = 'react.page.product_insights.custom_insights.table.current.group_header_days',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_PREVIOUS_GROUP_HEADER = 'react.page.product_insights.custom_insights.table.previous.group_header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_PREVIOUS_30_DAYS_GROUP_HEADER = 'react.page.product_insights.custom_insights.table.previous.30_days.group_header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_EMPTY_HEADER = 'react.page.product_insights.custom_insights.table.empty.header',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_EMPTY_DESCRIPTION = 'react.page.product_insights.custom_insights.table.empty.description',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_EMPTY_EDIT_CTA = 'react.page.product_insights.custom_insights.table.empty.cta_link',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_TABLE_COUNT_TEXT = 'react.page.product_insights.custom_insights.table.count_text',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_DESCRIPTION_TYPE_WHEN = 'react.page.product_insights.custom_insights.details.description_type.when',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_DESCRIPTION_TYPE_OVER = 'react.page.product_insights.custom_insights.details.description_type.over',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_DESCRIPTION_TYPE_APPLY_TO = 'react.page.product_insights.custom_insights.details.description_type.apply_to',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_DESCRIPTION_TYPE_FLAG_PRODUCTS = 'react.page.product_insights.custom_insights.details.description_type.flag_products',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_TABLE_UPDATED_AT_TOOLTIP = 'react.page.product_insights.custom_insights.details.tabel_data_updated_at_tooltip',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_TABLE_PROCESSED_AT_TOOLTIP = 'react.page.product_insights.custom_insights.details.tabel_data_processed_at_tooltip',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_USER_UPDATED_AT_MAIN = 'react.page.product_insights.custom_insights.details.user_updated_at_main_label',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_USER_UPDATED_AT = 'react.page.product_insights.custom_insights.details.user_updated_at_label',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_OVER_LABEL = 'react.page.product_insights.custom_insights.details.over.label',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_APPLY_TO_LABEL = 'react.page.product_insights.custom_insights.details.apply_to.label',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_STOCK_OUT_LABEL = 'react.page.product_insights.custom_insights.details.stock_out.label',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_REORDER_LABEL = 'react.page.product_insights.custom_insights.details.reorder.label',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_SAVE_INSIGHT_SUCCESS_MESSAGE = 'react.page.product_insights.custom_insights.details.save_insight.success.message',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_SAVE_INSIGHT_FAILURE_MESSAGE = 'react.page.product_insights.custom_insights.details.save_insight.failure.message',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_DETAILS_EXPORT_INSIGHT_FAILURE_MESSAGE = 'react.page.product_insights.custom_insights.details.export.success.message',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_ACTIVE_CAMPAIGN_FILTER_YES = 'react.page.product_insights.custom_insights.table.active_campaign_count_filter_options.yes',
  PRODUCT_INSIGHTS_CUSTOM_INSIGHT_ACTIVE_CAMPAIGN_FILTER_NO = 'react.page.product_insights.custom_insights.table.active_campaign_count_filter_options.no',
  PRODUCT_INSIGHTS_GLOSSARY_LOW_STOCK = 'react.page.product_insights.glossary.low_stock',
  PRODUCT_INSIGHTS_GLOSSARY_LOW_STOCK_DESC = 'react.page.product_insights.glossary.low_stock.desc',
  PRODUCT_INSIGHTS_GLOSSARY_OUT_OF_STOCK = 'react.page.product_insights.glossary.out_of_stock',
  PRODUCT_INSIGHTS_GLOSSARY_OUT_OF_STOCK_DESC = 'react.page.product_insights.glossary.out_of_stock.desc',
  PRODUCT_INSIGHTS_GLOSSARY_TOTAL_INVENTORY_VALUE = 'react.page.product_insights.glossary.total_inventary_value',
  PRODUCT_INSIGHTS_GLOSSARY_TOTAL_INVENTORY_VALUE_DESC = 'react.page.product_insights.glossary.total_inventary_value.desc',
  PRODUCT_INSIGHTS_GLOSSARY_AVAILABLE_INVENTORY_VALUE = 'react.page.product_insights.glossary.available_inventary_value',
  PRODUCT_INSIGHTS_GLOSSARY_AVAILABLE_INVENTORY_VALUE_DESC = 'react.page.product_insights.glossary.available_inventary_value.desc',
  PRODUCT_INSIGHTS_GLOSSARY_INBOUND_UNITS = 'react.page.product_insights.glossary.inbound_units',
  PRODUCT_INSIGHTS_GLOSSARY_INBOUND_UNITS_DESC1 = 'react.page.product_insights.glossary.inbound_units.desc1',
  PRODUCT_INSIGHTS_GLOSSARY_INBOUND_UNITS_DESC2 = 'react.page.product_insights.glossary.inbound_units.desc2',
  PRODUCT_INSIGHTS_GLOSSARY_INBOUND_UNITS_FORMULA1 = 'react.page.product_insights.glossary.inbound_units.formula1',
  PRODUCT_INSIGHTS_GLOSSARY_INBOUND_UNITS_FORMULA2 = 'react.page.product_insights.glossary.inbound_units.formula2',
  PRODUCT_INSIGHTS_GLOSSARY_RESERVED = 'react.page.product_insights.glossary.reserved',
  PRODUCT_INSIGHTS_GLOSSARY_RESERVED_DESC = 'react.page.product_insights.glossary.reserved.desc',
  PRODUCT_INSIGHTS_GLOSSARY_RESERVED_FORMULA = 'react.page.product_insights.glossary.reserved.formula',
  PRODUCT_INSIGHTS_GLOSSARY_RESERVED_DESC2 = 'react.page.product_insights.glossary.reserved.desc2',
  PRODUCT_INSIGHTS_GLOSSARY_UNFULLFILABLE = 'react.page.product_insights.glossary.unfullfillable',
  PRODUCT_INSIGHTS_GLOSSARY_UNFULLFILABLE_DESC1 = 'react.page.product_insights.glossary.unfullfillable.desc',
  PRODUCT_INSIGHTS_GLOSSARY_UNFULLFILABLE_DESC2 = 'react.page.product_insights.glossary.unfullfillable.desc2',
  PRODUCT_INSIGHTS_GLOSSARY_LONG_TERM_FEES = 'react.page.product_insights.glossary.long_term_fees',
  PRODUCT_INSIGHTS_GLOSSARY_LONG_TERM_FEES_DESC1 = 'react.page.product_insights.glossary.long_term_fees.desc1',
  PRODUCT_INSIGHTS_GLOSSARY_LONG_TERM_FEES_DESC2 = 'react.page.product_insights.glossary.long_term_fees.desc2',
  PRODUCT_INSIGHTS_GLOSSARY_LONG_TERM_FEES_DESC3 = 'react.page.product_insights.glossary.long_term_fees.desc3',
  PRODUCT_INSIGHTS_GLOSSARY_EXCESS_STOCK = 'react.page.product_insights.glossary.excess_stock',
  PRODUCT_INSIGHTS_GLOSSARY_EXCESS_STOCK_DESC1 = 'react.page.product_insights.glossary.excess_stock.desc1',
  PRODUCT_INSIGHTS_GLOSSARY_EXCESS_STOCK_DESC2 = 'react.page.product_insights.glossary.excess_stock.desc2',

  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_ACOS_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.acos.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_ACOS_GLOSSARY_FORMULA = 'react.page.product_insights.out_of_stock.table.acos.glossary.formula',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_TACOS_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.tacos.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_TACOS_GLOSSARY_FORMULA = 'react.page.product_insights.out_of_stock.table.tacos.glossary.formula',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_UNITS_SOLD_GLOSSARY_DESC = 'react.page.product_insights.out_of_stock.table.units_sold.glossary.desc',
  PRODUCT_INSIGHTS_OUT_OF_STOCK_TABLE_UNITS_SOLD_GLOSSARY_FORMULA = 'react.page.product_insights.out_of_stock.table.units_sold.glossary.formula',

  SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_HEADER = 'react.sales_channels.data_timing_and_syncing.slide_out.header',
  SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_AMAZON_REPORTING_DATA_HEADER = 'react.sales_channels.data_timing_and_syncing.slide_out.amazon_reporting_data.header',
  SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_AMAZON_REPORTING_DATA_CONTENT = 'react.sales_channels.data_timing_and_syncing.slide_out.amazon_reporting_data.content',
  SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_API = 'react.sales_channels.data_timing_and_syncing.slide_out.api',
  SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_AVAILABLE_DATA_DELAY = 'react.sales_channels.data_timing_and_syncing.slide_out.available_data_delay',
  SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_WALMART_REPORTING_DATA_HEADER = 'react.sales_channels.data_timing_and_syncing.slide_out.walmart_reporting_data.header',
  SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_WALMART_REPORTING_DATA_CONTENT = 'react.sales_channels.data_timing_and_syncing.slide_out.walmart_reporting_data.content',
  SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_FLYWHEEL_SYNCING_DATA_HEADER = 'react.sales_channels.data_timing_and_syncing.slide_out.flywheel_data_syncing.header',
  SALES_CHANNELS_DATA_TIMING_AND_SYNCING_SLIDEOUT_FLYWHEEL_SYNCING_DATA_CONTENT = 'react.sales_channels.data_timing_and_syncing.slide_out.flywheel_data_syncing.content',
  SALES_CHANNELS_SYNCING_SLIDEOUT_MORE_INFO = 'react.sales_channels.sales_channel_syncing.slide_out.more_info',
  SALES_CHANNELS_SYNCING_SLIDEOUT_CONNECTION = 'react.sales_channels.sales_channel_syncing.slide_out.connection',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_HEADING = 'react.page.product_insights.create_insights.heading',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_HEADING_QUERY_SNAPSHOT = 'react.page.product_insights.create_insights.heading.query_snapshot',
  PRODUCT_INSIGHTS_OPTIONAL = 'react.page.product_insights.optional',
  PRODUCT_INSIGHTS_FLAG_PRODUCTS = 'react.page.product_insights.flag_products',
  PRODUCT_INSIGHTS_RECOMMENDED = 'react.page.product_insights.recommended',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SUB_HEADING_SELECT_METRICS = 'react.page.product_insights.create_insights.sub_heading.select_metrics',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_PLACEHOLDER = 'react.page.product_insights.create_insights.select_metrics.dropdown.placeholder',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_RATE_OF_SALE = 'react.page.product_insights.create_insights.select_metrics.dropdown.value.rate_of_sale',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_RATE_OF_SALE_CHANGE_IN = 'react.page.product_insights.create_insights.select_metrics.dropdown.value.rate_of_sale_change_in',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_CHANGE_IN = 'react.page.product_insights.create_insights.select_metrics.dropdown.value.change_in',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_REORDER = 'react.page.product_insights.create_insights.select_metrics.dropdown.value.reorder',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_STOCK_OUT = 'react.page.product_insights.create_insights.select_metrics.dropdown.value.stock_out',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_RATE_OF_SALE = 'react.page.product_insights.create_insights.select_metrics.dropdown.description.rate_of_sale',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_REORDER = 'react.page.product_insights.create_insights.select_metrics.dropdown.description.reorder',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_STOCK_OUT = 'react.page.product_insights.create_insights.select_metrics.dropdown.description.stock_out',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_REORDER_DEFINE_METRICS_TEXT = 'react.page.product_insights.create_insights.reorder.define_query.text',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_STOCK_OUT_DEFINE_METRICS_START_TEXT = 'react.page.product_insights.create_insights.stock_out.define_query.start_text',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_STOCK_OUT_DEFINE_METRICS_MIDDLE_TEXT = 'react.page.product_insights.create_insights.stock_out.define_query.middle_text',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_STOCK_OUT_DEFINE_METRICS_END_TEXT = 'react.page.product_insights.create_insights.stock_out.define_query.end_text',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SUB_HEADING_CURRENT_METRICS = 'react.page.product_insights.create_insights.sub_heading.current_mertic',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_CURRENT_METRICS_HEADING1 = 'react.page.product_insights.create_insights.current_metric.radio.heading1',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_CURRENT_METRICS_AI_TAG = 'react.page.product_insights.create_insights.current_metric.ai_tag',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_CURRENT_METRICS_HEADING2 = 'react.page.product_insights.create_insights.current_metric.radio.heading2',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_CURRENT_METRICS_DESCRIPTION1 = 'react.page.product_insights.create_insights.current_metric.radio.description1',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_CURRENT_METRICS_DESCRIPTION2 = 'react.page.product_insights.create_insights.current_metric.radio.description2',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SUB_HEADING_DEFINE_QUERY = 'react.page.product_insights.create_insights.sub_heading.define_query',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN = 'react.page.product_insights.create_insights.define_query.when',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_BETWEEN_MESSAGE_1 = 'react.page.product_insights.create_insights.define_query.when.between.message1',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_BETWEEN_MESSAGE_2 = 'react.page.product_insights.create_insights.define_query.when.between.message2',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_PRIMARY_PLACEHOLDER = 'react.page.product_insights.create_insights.define_query.when.primary.placeholder',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_VARIANCE = 'react.page.product_insights.create_insights.define_query.when.primary.variance',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_UNIT_SOLD = 'react.page.product_insights.create_insights.define_query.when.primary.unit_sold',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_SECONDARY_PLACEHOLDER = 'react.page.product_insights.create_insights.define_query.when.secondary.placeholder',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_CHANGES = 'react.page.product_insights.create_insights.define_query.when.secondary.changes',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_INCREASES = 'react.page.product_insights.create_insights.define_query.when.secondary.increases',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_DECREASES = 'react.page.product_insights.create_insights.define_query.when.secondary.decreases',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_GREATER_THAN = 'react.page.product_insights.create_insights.define_query.when.secondary.greater_than',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_LESS_THAN = 'react.page.product_insights.create_insights.define_query.when.secondary.less_than',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_EQUAL_TO = 'react.page.product_insights.create_insights.define_query.when.secondary.equal_to',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_WHEN_BETWEEN = 'react.page.product_insights.create_insights.define_query.when.secondary.between',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_BUTTON_ADD_PARAMETER = 'react.page.product_insights.create_insights.define_query.when.add_parameter',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_ADD = 'react.page.product_insights.create_insights.define_query.when.union_dropdown.and',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_OR = 'react.page.product_insights.create_insights.define_query.when.union_dropdown.or',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_OVER = 'react.page.product_insights.create_insights.define_query.over',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_OVER_DROPDOWN_PLACEHOLDER = 'react.page.product_insights.create_insights.define_query.over.dropdown.placeholder',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_OVER_DROPDOWN_VALUE = 'react.page.product_insights.create_insights.define_query.over.dropdown.values',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_OVER_BASELINE_DESCRIPTION = 'react.page.product_insights.create_insights.define_query.over.baseline_description',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_TOOLTIP = 'react.page.product_insights.create_insights.define_query.over.tooltip',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DEFINE_QUERY_OVER_INFO = 'react.page.product_insights.create_insights.define_query.over.info',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_QUERY_THRESHOLD = 'react.page.product_insights.create_insights.qury_threshold',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_THRESHOLD_DESCRIPTION = 'react.page.product_insights.create_insights.threshold.description',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_THRESHOLD_INFO = 'react.page.product_insights.create_insights.threshold.info',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SUB_HEADING_NAME_YOUR_INSIGHT = 'react.page.product_insights.create_insights.sub_heading.name_your_insight',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_INSIGHT_NAME = 'react.page.product_insights.create_insights.insight_name',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_INSIGHT_NAME_PLACEHOLDER = 'react.page.product_insights.create_insights.insight_name.placeholder',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DESCRIPTION = 'react.page.product_insights.create_insights.description',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_DESCRIPTION_PLACEHOLDER = 'react.page.product_insights.create_insights.description.placeholder',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SUB_HEADING_NOTIFICATION = 'react.page.product_insights.create_insights.sub_heading.notification',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_RECEIVE_DAILY_INSIGHTS = 'react.page.product_insights.create_insights.receive_daily_report',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_RECEIVE_DAILY_INSIGHTS_DESCRIPTION = 'react.page.product_insights.create_insights.receive_daily_report.description',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_RECEIVE_DAILY_INSIGHTS_OPTION_IN_APP = 'react.page.product_insights.create_insights.receive_daily_report.option.in_app',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_RECEIVE_DAILY_INSIGHTS_OPTION_EMAIL = 'react.page.product_insights.create_insights.receive_daily_report.option.email',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_BUTTON_APPLY = 'react.page.product_insights.create_insights.button.apply',
  PRODUCT_INSIGHTS_QUERY_SNAPSHOT_SUB_HEADING = 'react.page.product_insights.query_snapshot.sub_heading',
  PRODUCT_INSIGHTS_QUERY_SNAPSHOT_APPLY_TO = 'react.page.product_insights.query_snapshot.apply_to',
  PRODUCT_INSIGHTS_QUERY_SNAPSHOT_PRODUCTS_THAT_SELL = 'react.page.product_insights.query_snapshot.products_that_sell',
  PRODUCT_INSIGHTS_QUERY_SNAPSHOT_VALUE_OVER = 'react.page.product_insights.query_snapshot.value.over',
  PRODUCT_INSIGHTS_QUERY_SNAPSHOT_DAILY_REPORT = 'react.page.product_insights.query_snapshot.display_key.daily_report',
  PRODUCT_INSIGHTS_QUERY_SNAPSHOT_DAILY_REPORT_VALUE = 'react.page.product_insights.query_snapshot.display_key.daily_report.value',
  PRODUCT_INSIGHTS_QUERY_SNAPSHOT_DAILY_REPORT_OFF_VALUE = 'react.page.product_insights.query_snapshot.display_key.daily_report.off_value',
  PRODUCT_INSIGHTS_QUERY_SNAPSHOT_FLAG_PRODUCTS_VALUE = 'react.page.product_insights.query_snapshot.display_key.flag_products.value',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_ERROR_TOOLTIP = 'react.page.product_insights.create_insights.error.tooltip',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_THRESHOLD_TOGGLE_TOOLTIP = 'react.page.product_insights.create_insights.threshold.toggle.tooltip',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_BUTTON_SAVE_INSIGHTS = 'react.page.product_insights.create_insights.button.save_insights',
  PRODUCT_INSIGHTS_EDIT_INSIGHTS_NAME_AND_DESCRIPTION = 'react.page.product_insights.edit_insights.name_and_description',
  PRODUCT_INSIGHTS_EDIT_INSIGHTS_DEFINE_METRICS = 'react.page.product_insights.edit_insights.define_metrics',
  PRODUCT_INSIGHTS_EDIT_INSIGHT_NAME_MAX_LENGTH_ERROR = 'react.page.product_insights.edit_insight_name.max_length_error',
  PRODUCT_INSIGHTS_EDIT_INSIGHT_DESCRIPTION_MAX_LENGTH_ERROR = 'react.page.product_insights.edit_insight_description.max_length_error',

  PRODUCT_INSIGHTS_PERFORMANCE_HEADING_RATE_OF_SALE = 'react.page.product_insights.rate_of_sale.performance.heading',
  PRODUCT_INSIGHTS_PERFORMANCE_SUB_HEADING_RATE_OF_SALE = 'react.page.product_insights.rate_of_sale.performance.sub_heading',
  PRODUCT_INSIGHTS_DELETE_MODAL_HEADING = 'react.page.product_insights.delete.modal.heading',
  PRODUCT_INSIGHTS_DELETE_MODAL_BODY = 'react.page.product_insights.delete.modal.body',
  PRODUCT_INSIGHTS_DELETE_SUCCESS_TOAST_MESSAGE = 'react.page.product_insights.delete.success.message',
  PRODUCT_INSIGHTS_CREATE_SUCCESS_TOAST_MESSAGE = 'react.page.product_insights.create.success.message',
  PRODUCT_INSIGHTS_CARD_MORE_COPY_LINK = 'react.page.product_insights.card.more.copy_link',
  PRODUCT_INSIGHTS_CARD_MORE_DELETE = 'react.page.product_insights.card.more.delete',
  PRODUCT_INSIGHTS_TABLE_AI_MESSAGE = 'react.page.product_insights.table.ai.message',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_ERROR_TOOLTIP_BETWEEN = 'react.page.product_insights.create_insights.error.tooltip_between',

  PRODUCT_INSIGHTS_GLOSSARY_HEADING_AVAILABLE_UNITS_TO_SELL = 'react.page.product_insights.glossary.heading.available_to_sell_units',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_AVAILABLE_UNITS_TO_SELL = 'react.page.product_insights.glossary.definition.available_to_sell_units',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_MERCHANT_RECOMMENDED_INVENTORY_LEVEL = 'react.page.product_insights.glossary.heading.merchant_recommended_inventory_level',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_MERCHANT_RECOMMENDED_INVENTORY_LEVEL = 'react.page.product_insights.glossary.definition.merchant_recommended_inventory_level',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_LAST_X_DAYS_STOCK = 'react.page.product_insights.glossary.heading.last_x_days_instock',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_LAST_X_DAYS_STOCK = 'react.page.product_insights.glossary.definition.last_x_days_instock',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_MERCHANT_PREDICTED_OOS_DATE = 'react.page.product_insights.glossary.heading.merchant_predicted_oos_date',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_MERCHANT_PREDICTED_OOS_DATE = 'react.page.product_insights.glossary.definition.merchant_predicted_oos_date',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_EST_MISSED_SALES = 'react.page.product_insights.glossary.heading.est_missed_sales',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_EST_MISSED_SALES = 'react.page.product_insights.glossary.definition.est_missed_sales',
  PRODUCT_INSIGHTS_GLOSSARY_FORMULA_EST_MISSED_SALES = 'react.page.product_insights.glossary.formula.est_missed_sales',
  PRODUCT_INSIGHTS_GLOSSARY_FORMULA_EST_MISSED_SALES_2 = 'react.page.product_insights.glossary.formula.est_missed_sales_2',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_TOTAL_EST_INVENTORY_VALUE = 'react.page.product_insights.glossary.heading.total_est_inventory_value',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_TOTAL_EST_INVENTORY_VALUE = 'react.page.product_insights.glossary.definition.total_est_inventory_value',
  PRODUCT_INSIGHTS_GLOSSARY_FORMULA_TOTAL_EST_INVENTORY_VALUE = 'react.page.product_insights.glossary.formula.total_est_inventory_value',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_MERCHANT_AVAILABLE_INVENTORY = 'react.page.product_insights.glossary.heading.merchant_available_inventory',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_MERCHANT_AVAILABLE_INVENTORY = 'react.page.product_insights.glossary.definition.merchant_available_inventory',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_INVENTORY_AGING_BUCKETS = 'react.page.product_insights.glossary.heading.inventory_aging_buckets',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_INVENTORY_AGING_BUCKETS = 'react.page.product_insights.glossary.definition.inventory_aging_buckets',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_CHANGE_IN_SALES = 'react.page.product_insights.glossary.heading.change_in_sales_rate',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_CHANGE_IN_SALES = 'react.page.product_insights.glossary.definition.change_in_sales_rate',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_ANOMALY_DETECTION = 'react.page.product_insights.glossary.heading.anomaly_detection',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_ANOMALY_DETECTION = 'react.page.product_insights.glossary.definition.anomaly_detection',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_UNITS_SOLD = 'react.page.product_insights.glossary.heading.units_sold',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_UNITS_SOLD = 'react.page.product_insights.glossary.definition.units_sold',
  PRODUCT_INSIGHTS_GLOSSARY_FORMULA_UNITS_SOLD = 'react.page.product_insights.glossary.formula.units_sold',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_REORDER = 'react.page.product_insights.glossary.heading.reorder',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_REORDER = 'react.page.product_insights.glossary.definition.reorder',
  PRODUCT_INSIGHTS_GLOSSARY_FORMULA_REORDER = 'react.page.product_insights.glossary.formula.reorder',
  PRODUCT_INSIGHTS_GLOSSARY_EXAMPLE_TEXT = 'react.page.product_insights.glossary.example',
  PRODUCT_INSIGHTS_GLOSSARY_EXAMPLE_REORDER_1 = 'react.page.product_insights.glossary.example.reorder.1',
  PRODUCT_INSIGHTS_GLOSSARY_EXAMPLE_REORDER_2 = 'react.page.product_insights.glossary.example.reorder.2',
  PRODUCT_INSIGHTS_GLOSSARY_EXAMPLE_REORDER_3 = 'react.page.product_insights.glossary.example.reorder.3',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_STOCK_OUT = 'react.page.product_insights.glossary.heading.stockout',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_STOCK_OUT = 'react.page.product_insights.glossary.definition.stockout',
  PRODUCT_INSIGHTS_GLOSSARY_FORMULA_STOCK_OUT = 'react.page.product_insights.glossary.formula.stockout',
  PRODUCT_INSIGHTS_GLOSSARY_EXAMPLE_STOCK_OUT_1 = 'react.page.product_insights.glossary.example.stockout.1',
  PRODUCT_INSIGHTS_GLOSSARY_EXAMPLE_STOCK_OUT_2 = 'react.page.product_insights.glossary.example.stockout.2',
  PRODUCT_INSIGHTS_GLOSSARY_EXAMPLE_STOCK_OUT_3 = 'react.page.product_insights.glossary.example.stockout.3',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_QUERY_THRESHOLD = 'react.page.product_insights.glossary.heading.query_threshold',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_QUERY_THRESHOLD = 'react.page.product_insights.glossary.definition.query_threshold',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_SUMMARY_ALERTS = 'react.page.product_insights.glossary.heading.summary_alerts',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_SUMMARY_ALERTS = 'react.page.product_insights.glossary.definition.summary_alerts',
  PRODUCT_INSIGHTS_GLOSSARY_HEADING_PINNED_PRODUCTS = 'react.page.product_insights.glossary.heading.pinned_products',
  PRODUCT_INSIGHTS_GLOSSARY_DEFINITION_PINNED_PRODUCTS = 'react.page.product_insights.glossary.definition.pinned_products',
  PRODUCT_INSIGHTS_GLOSSARY_FORMULA_OUT_OF_STOCK = 'react.page.product_insights.glossary.formula.out_of_stock',

  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_HEADER = 'react.page.compass.customize_metrics_slideover.header',
  ADS_MANAGER_CUSTOMIZE_METRICS_SLIDEOVER_HEADER = 'react.page.ads_manager.customize_metrics_slideover.header',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_SELECT_5_LABEL = 'react.page.compass.customize_metrics_slideover.select_5_label',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_ACOS = 'react.page.compass.customize_metrics_slideover.acos',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_AD_CONVERSIONS = 'react.page.compass.customize_metrics_slideover.ad_conversions',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_AD_CONVERSION_RATE = 'react.page.compass.customize_metrics_slideover.ad_conversion_rate',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_AD_SALES = 'react.page.compass.customize_metrics_slideover.ad_sales',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_AD_SPEND = 'react.page.compass.customize_metrics_slideover.ad_spend',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_AVERAGE_SALES_PRICE = 'react.page.compass.customize_metrics_slideover.average_sales_price',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_CLICKS = 'react.page.compass.customize_metrics_slideover.clicks',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_CLICK_THROUGH_RATE = 'react.page.compass.customize_metrics_slideover.click_through_rate',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_COST_PER_CLICK = 'react.page.compass.customize_metrics_slideover.cost_per_click',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_IMPRESSIONS = 'react.page.compass.customize_metrics_slideover.impressions',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_ROAS = 'react.page.compass.customize_metrics_slideover.roas',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_TACOS = 'react.page.compass.customize_metrics_slideover.tacos',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_TOTAL_SALES = 'react.page.compass.customize_metrics_slideover.total_sales',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_UNITS_SOLD = 'react.page.compass.customize_metrics_slideover.units_sold',
  COMPASS_CUSTOMIZE_METRICS_SHOW_PREV_PERIOD = 'react.page.compass.customize_metrics.show_prev_period_label',
  COMPASS_CUSTOMIZE_METRICS_SHOW_SEASONAL_EVENTS = 'react.page.compass.customize_metrics.show_seasonal_events_label',
  COMPASS_CUSTOMIZE_METRICS_NO_SEASONAL_EVENTS_TOOLTIP = 'react.page.compass.customize_metrics.no_seasonal_events_tooltip',
  COMPASS_PERFORMANCE_CUSTOMIZE_METRICS_SHOW_PREV_PERIOD = 'react.page.compass.customize_metrics.show_performance_prev_period_label',
  COMPASS_CUSTOMIZE_METRICS_EXPORT_BTN_TOOLTIP = 'react.page.compass.customize_metrics.export_btn.tooltip',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_ADD_METRIC_LINK = 'react.page.compass.customize_metrics_slideover.add_metric_link',
  COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_PREV_DAYS_LABEL = 'react.page.compass.customize_metrics_slideover.prev_days_label',
  COMPASS_TM_INDEX_AMAZON_METRICS_LABEL = 'react.page.compass.tm_index.amazon.label',
  COMPASS_TM_INDEX_WALMART_METRICS_LABEL = 'react.page.compass.tm_index.walmart.label',
  COMPASS_TM_INDEX_COMPARISION_UNAVAILABLE_TOOLTIP = 'react.page.compass.tm_index.comparision_unavailable.label',
  COMPASS_TM_INDEX_COMPARISION_UNAVAILABLE_WALMART_TOOLTIP = 'react.page.compass.tm_index.comparision_unavailable.walmart.label',
  COMPASS_TM_INDEX_COMPARE_AMAZON_TOOLTIP = 'react.page.compass.tm_index.compare_amazon.tooltip',
  COMPASS_TM_INDEX_COMPARE_WALMART_TOOLTIP = 'react.page.compass.tm_index.compare_walmart.tooltip',
  COMPASS_TM_INDEX_INFO_TOOLTIP = 'react.page.compass.tm_index.info.tooltip',
  COMPASS_TM_INDEX_SHOW_INFO_TEXT = 'react.page.compass.tm_index.show_info.text',

  MANAGE_METRICS_FIELD_DEFINITION_AD_SALES = 'react.page.metrics.ad_sales.definition',
  MANAGE_METRICS_FIELD_DEFINITION_AD_SPEND = 'react.page.metrics.ad_spend.definition',
  MANAGE_METRICS_FIELD_DEFINITION_ACOS = 'react.page.metrics.acos.definition',
  MANAGE_METRICS_FIELD_DEFINITION_AD_CONVERSIONS = 'react.page.metrics.ad_conversions.definition',
  MANAGE_METRICS_FIELD_DEFINITION_CLICKS = 'react.page.metrics.clicks.definition',
  MANAGE_METRICS_FIELD_DEFINITION_CLICK_THROUGH_RATE = 'react.page.metrics.click_through_rate.definition',
  MANAGE_METRICS_FIELD_DEFINITION_CONVERSION_RATE = 'react.page.metrics.conversion_rate.definition',
  MANAGE_METRICS_FIELD_DEFINITION_CPC = 'react.page.metrics.cpc.definition',
  MANAGE_METRICS_FIELD_DEFINITION_IMPRESSIONS = 'react.page.metrics.impressions.definition',
  MANAGE_METRICS_FIELD_DEFINITION_ROAS = 'react.page.metrics.roas.definition',
  MANAGE_METRICS_FIELD_DEFINITION_TACOS = 'react.page.metrics.tacos.definition',
  MANAGE_METRICS_FIELD_DEFINITION_TOTAL_SALES = 'react.page.metrics.total_sales.definition',
  MANAGE_METRICS_FIELD_DEFINITION_UNITS_SOLD = 'react.page.metrics.units_sold.definition',
  MANAGE_METRICS_FIELD_DEFINITION_AD_UNITS_SOLD = 'react.page.metrics.ad_units_sold.definition',
  MANAGE_METRICS_FIELD_DEFINITION_TOTAL_UNITS_SOLD = 'react.page.metrics.total_units_sold.definition',
  MANAGE_METRICS_FIELD_DEFINITION_AVERAGE_SALES_PRICE = 'react.page.metrics.average_sales_price.definition',
  MANAGE_METRICS_FIELD_DEFINITION_DIRECT_ACOS = 'react.page.metrics.direct_acos.definition',
  MANAGE_METRICS_FIELD_DEFINITION_DIRECT_AD_SALES = 'react.page.metrics.direct_ad_sales.definition',
  MANAGE_METRICS_FIELD_DEFINITION_DIRECT_ROAS = 'react.page.metrics.direct_roas.definition',

  MANAGE_METRICS_FIELD_FORMULA_AD_SALES = 'react.page.metrics.ad_sales.formula',
  MANAGE_METRICS_FIELD_FORMULA_AD_SPEND = 'react.page.metrics.ad_spend.formula',
  MANAGE_METRICS_FIELD_FORMULA_ACOS = 'react.page.metrics.acos.formula',
  MANAGE_METRICS_FIELD_FORMULA_AD_CONVERSIONS = 'react.page.metrics.ad_conversions.formula',
  MANAGE_METRICS_FIELD_FORMULA_CLICKS = 'react.page.metrics.clicks.formula',
  MANAGE_METRICS_FIELD_FORMULA_CLICK_THROUGH_RATE = 'react.page.metrics.click_through_rate.formula',
  MANAGE_METRICS_FIELD_FORMULA_CONVERSION_RATE = 'react.page.metrics.conversion_rate.formula',
  MANAGE_METRICS_FIELD_FORMULA_CPC = 'react.page.metrics.cpc.formula',
  MANAGE_METRICS_FIELD_FORMULA_IMPRESSIONS = 'react.page.metrics.impressions.formula',
  MANAGE_METRICS_FIELD_FORMULA_ROAS = 'react.page.metrics.roas.formula',
  MANAGE_METRICS_FIELD_FORMULA_TACOS = 'react.page.metrics.tacos.formula',
  MANAGE_METRICS_FIELD_FORMULA_TOTAL_SALES = 'react.page.metrics.total_sales.formula',
  MANAGE_METRICS_FIELD_FORMULA_UNITS_SOLD = 'react.page.metrics.units_sold.formula',
  MANAGE_METRICS_FIELD_FORMULA_AVERAGE_SALES_PRICE = 'react.page.metrics.average_sales_price.formula',
  MANAGE_METRICS_FIELD_FORMULA_DIRECT_ACOS = 'react.page.metrics.direct_acos.formula',
  MANAGE_METRICS_FIELD_FORMULA_DIRECT_AD_SALES = 'react.page.metrics.direct_ad_sales.formula',
  MANAGE_METRICS_FIELD_FORMULA_DIRECT_ROAS = 'react.page.metrics.direct_roas.formula',

  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_HEADER_OLD = 'react.flash_modals.walmart.authorize_account.content.header_old',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_DESCRIPTION_OLD = 'react.flash_modals.walmart.authorize_account.content.description_old',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_SEND_AUTH_BTN_OLD = 'react.flash_modals.walmart.authorize_account.content.send_auth_btn_old',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_EMIAL_ADDR_LABEL_OLD = 'react.flash_modals.walmart.authorize_account.content.email_addr_label_old',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_EXAMPLE_EMAIL_ID_OLD = 'react.flash_modals.walmart.authorize_account.content.example_email_id_old',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_EMAIL_VALIDATION_ERROR_MESSAGE_OLD = 'react.flash_modals.walmart.authorize_account.content.email_validation_error_message_old',
  FLASH_MODALS_WALMART_AUTHORIZATION_MODAL_API_ERROR_MESSAGE_OLD = 'react.flash_modals.walmart.authorize_account.content.api_error_message_old',
  FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_ADS_PRIMARY_TEXT_OLD = 'react.flash_modals.walmart.authorize_success.content.ads.primary_text_old',
  FLASH_MODALS_WALMART_AUTHORIZATION_SUCCESS_ADS_SECONDARY_TEXT_OLD = 'react.flash_modals.walmart.authorize_success.content.ads.secondary_text_old',
  FLASH_MODALS_WALMART_CONNECT_SELLER_PRODUCTS_SUCCESS = 'react.flash_modals.walmart.connect_seller_products.success',
  FLASH_MODALS_WALMART_CONNECT_SELLER_PRODUCTS_ERROR = 'react.flash_modals.walmart.connect_seller_products.error',
  FLASH_MODALS_WALMART_OAUTH_ERROR_GENERIC = 'react.flash_modals.walmart.oauth_error.generic',
  FLASH_MODALS_WALMART_OAUTH_ERROR_API_RESPONSE = 'react.flash_modals.walmart.oauth_error.api_response',

  FLASH_MODALS_AMAZON_DATA_CONNECTION_TITLE = 'react.flash_modals.amazon.data_connection.title',
  FLASH_MODALS_AMAZON_DATA_CONNECTION_HEADER = 'react.flash_modals.amazon.data_connection.header',
  FLASH_MODALS_AMAZON_DATA_CONNECTION_SUB_HEADER = 'react.flash_modals.amazon.data_connection.sub_header',
  FLASH_MODALS_AMAZON_DATA_CONNECTION_DESCRIPTION = 'react.flash_modals.amazon.data_connection.description',

  DEMAND_FORECAST_TABLE_REMAINING_DAYS_OF_INVENTORY = 'react.pages.demand_forecast.columns.remaining_days_of_inventory',
  DEMAND_FORECAST_TABLE_UNITS_PROJECTED_TO_SELL_IN_30_DAYS = 'react.pages.demand_forecast.columns.units_projected_to_sell_in_30_days',
  DEMAND_FORECAST_TABLE_UNITS_PROJECTED_TO_SELL_IN_60_DAYS = 'react.pages.demand_forecast.columns.units_projected_to_sell_in_60_days',
  DEMAND_FORECAST_TABLE_UNITS_PROJECTED_TO_SELL_IN_90_DAYS = 'react.pages.demand_forecast.columns.units_projected_to_sell_in_90_days',
  DEMAND_FORECAST_TABLE_UNITS_PROJECTED_TO_SELL_IN_180_DAYS = 'react.pages.demand_forecast.columns.units_projected_to_sell_in_180_days',
  DEMAND_FORECAST_TABLE_UNITS_PROJECTED_TO_SELL_IN_270_DAYS = 'react.pages.demand_forecast.columns.units_projected_to_sell_in_270_days',
  DEMAND_FORECAST_TABLE_UNITS_PROJECTED_TO_SELL_IN_365_DAYS = 'react.pages.demand_forecast.columns.units_projected_to_sell_in_365_days',
  DEMAND_FORECAST_TABLE_FILTERS_UNITS_PROJECTED_TO_SELL_IN_30_DAYS = 'react.pages.demand_forecast.filters.units_projected_to_sell_in_30_days',
  DEMAND_FORECAST_TABLE_FILTERS_UNITS_PROJECTED_TO_SELL_IN_60_DAYS = 'react.pages.demand_forecast.filters.units_projected_to_sell_in_60_days',
  DEMAND_FORECAST_TABLE_FILTERS_UNITS_PROJECTED_TO_SELL_IN_90_DAYS = 'react.pages.demand_forecast.filters.units_projected_to_sell_in_90_days',
  DEMAND_FORECAST_TABLE_FILTERS_UNITS_PROJECTED_TO_SELL_IN_180_DAYS = 'react.pages.demand_forecast.filters.units_projected_to_sell_in_180_days',
  DEMAND_FORECAST_TABLE_FILTERS_UNITS_PROJECTED_TO_SELL_IN_270_DAYS = 'react.pages.demand_forecast.filters.units_projected_to_sell_in_270_days',
  DEMAND_FORECAST_TABLE_FILTERS_UNITS_PROJECTED_TO_SELL_IN_365_DAYS = 'react.pages.demand_forecast.filters.units_projected_to_sell_in_365_days',
  DEMAND_FORECAST_TABLE_CURRENT_MERCHANT_INVENTORY_LEVEL = 'react.pages.demand_forecast.columns.current_merchant_inventory_level',
  DEMAND_FORECAST_TABLE_RATE_OF_SALES = 'react.pages.demand_forecast.columns.rate_of_sales',
  DEMAND_FORECAST_TABLE_UNITS_SOLD = 'react.pages.demand_forecast.columns.units_sold',
  DEMAND_FORECAST_TABLE_ACTIVE_PRODUCT_COUNT = 'react.pages.demand_forecast.active_product_count',
  DEMAND_FORECAST_TABLE_SEARCH_PLACEHOLDER = 'react.pages.demand_forecast.search_placeholder',
  DEMAND_FORECAST_TABLE_GLOSSARY_DAYS_OF_INVENTORY = 'react.pages.demand_forecast.glossary.days_of_inventory',
  DEMAND_FORECAST_TABLE_GLOSSARY_CURRENT_INVENTORY_LEVEL = 'react.pages.demand_forecast.glossary.current_inventory_level',
  DEMAND_FORECAST_TABLE_GLOSSARY_CURRENT_INVENTORY_LEVEL_FORMULA = 'react.pages.demand_forecast.glossary.current_inventory_level_formula',
  DEMAND_FORECAST_TABLE_GLOSSARY_RATE_OF_SALE = 'react.pages.demand_forecast.glossary.rate_of_sale',
  DEMAND_FORECAST_TABLE_GLOSSARY_DEMAND_FORECAST_LABEL = 'react.pages.demand_forecast.glossary.demand_forecast.label',
  DEMAND_FORECAST_TABLE_GLOSSARY_DEMAND_FORECAST_DEFINITION = 'react.pages.demand_forecast.glossary.demand_forecast.definition',
  DEMAND_FORECAST_TABLE_GLOSSARY_UNITS_PROJECTED_TO_SELL_LABEL = 'react.pages.demand_forecast.glossary.units_projected_to_sell.label',
  DEMAND_FORECAST_TABLE_GLOSSARY_UNITS_PROJECTED_TO_SELL_DEFINITION = 'react.pages.demand_forecast.glossary.units_projected_to_sell.definition',
  DEMAND_FORECAST_UNITS_PROJECTED_TO_SELL = 'react.pages.demand_forecast.units_projected_to_sell',

  CAMPAIGN_CREATOR_DASHBOARD_HEADER = 'react.campaign_creator.dashboard.header',
  CAMPAIGN_CREATOR_DASHBOARD_UNADVERTISED_PRODUCTS_HEADER = 'react.campaign_creator.dashboard.unadvertised_products.header',
  CAMPAIGN_CREATOR_DASHBOARD_UNADVERTISED_PRODUCTS_SUB_HEADER = 'react.campaign_creator.dashboard.unadvertised_products.sub_header',
  CAMPAIGN_CREATOR_DASHBOARD_NOT_IN_SMART_CAMPAIGNS_PRODUCTS_HEADER = 'react.campaign_creator.dashboard.not_in_smart_campaigns_products.header',
  CAMPAIGN_CREATOR_DASHBOARD_NOT_IN_SMART_CAMPAIGNS_PRODUCTS_SUB_HEADER = 'react.campaign_creator.dashboard.not_in_smart_campaigns_products.sub_header',
  CAMPAIGN_CREATOR_DASHBOARD_ADVERTISED_PRODUCTS_MESSAGE = 'react.campaign_creator.dashboard.advertised_products.message',
  CAMPAIGN_CREATOR_DASHBOARD_IN_SMART_CAMPAIGNS_PRODUCTS_MESSAGE = 'react.campaign_creator.dashboard.in_smart_campaigns_products.message',
  CAMPAIGN_CREATOR_DASHBOARD_CREATE_NEW_CAMPAIGNS_BUTTON_TEXT = 'react.campaign_creator.dashboard.create_new_campaigns_button_text',
  CAMPAIGN_CREATOR_DASHBOARD_CREATE_NEW_CAMPAIGNS_HEADER = 'react.campaign_creator.dashboard.create_new_campaigns_header',
  CAMPAIGN_CREATOR_DASHBOARD_CREATE_NEW_CAMPAIGNS_SUBHEADER = 'react.campaign_creator.dashboard.create_new_campaigns_subheader',
  CAMPAIGN_CREATOR_DASHBOARD_HELP_TEXT = 'react.campaign_creator.dashboard.help_text',
  CAMPAIGN_CREATOR_DASHBOARD_EST_PRE_AD_GROSS_MARGIN = 'react.campaign_creator.table.est_pre_ad_gross_margin',
  CAMPAIGN_CREATOR_DASHBOARD_EST_PRE_AD_GROSS_MARGIN_NA_TOOLTIP = 'react.campaign_creator.table.est_pre_ad_gross_margin_na_tooltip',

  RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_MAIN_TEXT = 'react.recommendation_hub.card.connect_product_data.main_text',
  RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_SUB_TEXT = 'react.recommendation_hub.card.connect_product_data.sub_text',
  RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.connect_product_data.sub_text_completed',
  RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.connect_product_data.primary_button_text',

  RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_MAIN_TEXT = 'react.recommendation_hub.card.connect_api_data.main_text',
  RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_SUB_TEXT = 'react.recommendation_hub.card.connect_api_data.sub_text',
  RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.connect_api_data.sub_text_completed',
  RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.connect_api_data.primary_button_text',

  RECOMMENDATION_HUB_CARD_COGS_DATA_MAIN_TEXT = 'react.recommendation_hub.card.cogs_data.main_text',
  RECOMMENDATION_HUB_CARD_COGS_DATA_SUB_TEXT = 'react.recommendation_hub.card.cogs_data.sub_text',
  RECOMMENDATION_HUB_CARD_COGS_DATA_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.cogs_data.sub_text_completed',
  RECOMMENDATION_HUB_CARD_COGS_DATA_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.cogs_data.primary_button_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_MAIN_TEXT = 'react.recommendation_hub.card.unadvertised_products_data.main_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_SUB_TEXT = 'react.recommendation_hub.card.unadvertised_products_data.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.unadvertised_products_data.sub_text_completed',
  RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.unadvertised_products_data.primary_button_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_MAIN_TEXT = 'react.recommendation_hub.card.not_in_smart_campaign_data.main_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_SUB_TEXT = 'react.recommendation_hub.card.not_in_smart_campaign_data.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.not_in_smart_campaign_data.sub_text_completed',
  RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.not_in_smart_campaign_data.primary_button_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_MAIN_TEXT = 'react.recommendation_hub.card.turn_on_ai_bidding_data.main_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_SUB_TEXT = 'react.recommendation_hub.card.turn_on_ai_bidding_data.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.turn_on_ai_bidding_data.sub_text_completed',
  RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.turn_on_ai_bidding_data.primary_button_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_SECONDARY_BUTTON_TEXT = 'react.recommendation_hub.card.turn_on_ai_bidding_data.secondary_button_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_MAIN_TEXT = 'react.recommendation_hub.card.keyword_target_actions_data.main_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_SUB_TEXT = 'react.recommendation_hub.card.keyword_target_actions_data.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.keyword_target_actions_data.sub_text_completed',
  RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.keyword_target_actions_data.primary_button_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_MAIN_TEXT = 'react.recommendation_hub.card.budget_management_data.main_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT = 'react.recommendation_hub.card.budget_management_data.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.budget_management_data.sub_text_completed',
  RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.budget_management_data.primary_button_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_OUT_OF_STOCK_MAIN_TEXT = 'react.recommendation_hub.card.out_of_stock.main_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_OUT_OF_STOCK_SUB_TEXT = 'react.recommendation_hub.card.out_of_stock.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_OUT_OF_STOCK_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.out_of_stock.sub_text_completed',
  RECOMMENDATION_HUB_CARD_PRODUCTS_OUT_OF_STOCK_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.out_of_stock.primary_button_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISED_PRODUCTS_LESS_THAN_30_INVENTORY_MAIN_TEXT = 'react.recommendation_hub.card.advertised_products_less_than_30_inventory.main_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISED_PRODUCTS_LESS_THAN_30_INVENTORY_SUB_TEXT = 'react.recommendation_hub.card.advertised_products_less_than_30_inventory.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISED_PRODUCTS_LESS_THAN_30_INVENTORY_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.advertised_products_less_than_30_inventory.sub_text_completed',
  RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISED_PRODUCTS_LESS_THAN_30_INVENTORY_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.advertised_products_less_than_30_inventory.primary_button_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_LOW_SELL_THROUGH_RATE_MAIN_TEXT = 'react.recommendation_hub.card.unadvertised_products_low_sell_through_rate.main_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_LOW_SELL_THROUGH_RATE_SUB_TEXT = 'react.recommendation_hub.card.unadvertised_products_low_sell_through_rate.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_LOW_SELL_THROUGH_RATE_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.unadvertised_products_low_sell_through_rate.sub_text_completed',
  RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_LOW_SELL_THROUGH_RATE_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.unadvertised_products_low_sell_through_rate.primary_button_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_UNSOLD_INVENTORY_MAIN_TEXT = 'react.recommendation_hub.card.unsold_inventory.main_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_UNSOLD_INVENTORY_SUB_TEXT = 'react.recommendation_hub.card.unsold_inventory.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_UNSOLD_INVENTORY_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.unsold_inventory.sub_text_completed',
  RECOMMENDATION_HUB_CARD_PRODUCTS_UNSOLD_INVENTORY_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.unsold_inventory.primary_button_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_LIQUIDATE_ON_HIIVE_MAIN_TEXT = 'react.recommendation_hub.card.liquidate_on_hiive.main_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_LIQUIDATE_ON_HIIVE_SUB_TEXT = 'react.recommendation_hub.card.liquidate_on_hiive.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_LIQUIDATE_ON_HIIVE_SUB_TEXT_COMPLETED = 'react.recommendation_hub.card.liquidate_on_hiive.sub_text_completed',
  RECOMMENDATION_HUB_CARD_PRODUCTS_LIQUIDATE_ON_HIIVE_PRIMARY_BUTTON_TEXT = 'react.recommendation_hub.card.liquidate_on_hiive.primary_button_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_INVENTORY_OPTIMIZATION_HEAD_MAIN_TEXT = 'react.recommendation_hub.card.inventory_optimization.head.main_text',
  RECOMMENDATION_HUB_CARD_NEW_TAG = 'react.recommendation_hub.card.new_tag',
  RECOMMENDATION_HUB_CARD_PRODUCTS_INVENTORY_OPTIMIZATION_HEAD_SUB_TEXT = 'react.recommendation_hub.card.inventory_optimization.head.sub_text',

  RECOMMENDATION_HUB_CARD_PRODUCTS_PRODUCTS_CATALOG_HEAD_SUB_TEXT = 'react.recommendation_hub.card.product_catalog.head.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISING_OPTIMIZATION_HEAD_SUB_TEXT = 'react.recommendation_hub.card.advertising_optimization.head.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISING_OPTIMIZATION_MAIN_TEXT = 'react.recommendation_hub.card.advertising_optimization.main_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISING_OPTIMIZATION_SUB_TEXT = 'react.recommendation_hub.card.advertising_optimization.sub_text',
  RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISING_OPTIMIZATION_FLYWEEL_SMART_CAMPAIGNS = 'react.recommendation_hub.card.advertising_optimization.flywheel_Smart_Campaigns',

  KWA_AUTO_NEGATE_HEADER = 'react.kwa.auto_negate.header',
  KWA_AUTO_NEGATE_DESCRIPTION = 'react.kwa.auto_negate.description',
  KWA_AUTO_NEGATE_SLIDEOVER_HEADER = 'react.kwa.auto_negate.slideover.header',
  KWA_AUTO_NEGATE_WALMART_NOT_AVAILABLE_TOOLTIP = 'react.kwa.auto_negate.walmart.not_available.tooltip_text',
  KWA_AUTO_NEGATE_TOAST_AUTO_NEGATE_SUCCESS = 'react.kwa.auto_negate.toast.auto_negate_success',

  KWA_CRITERIA_SETTINGS_HEADER = 'react.kwa.criteria_settings.header',
  KWA_CRITERIA_SETTINGS_AD_CONVERSIONS = 'react.kwa.criteria_settings.ad_conversions',
  KWA_CRITERIA_SETTINGS_AD_CLICKS = 'react.kwa.criteria_settings.clicks',
  KWA_CRITERIA_SETTINGS_OR_MORE = 'react.kwa.criteria_settings.slideover.or_more',
  KWA_CRITERIA_SETTINGS_AD_SPEND = 'react.kwa.criteria_settings.ad_spend',
  KWA_CRITERIA_SETTINGS_PREVIOUS_DAYS = 'react.kwa.criteria_settings.previous_days',
  KWA_CRITERIA_SETTINGS_IN_THE_PAST = 'react.kwa.criteria_settings.past_text',
  KWA_CRITERIA_SETTINGS_NUMBER_OF_DAYS = 'react.kwa.criteria_settings.number_of_days',

  KWA_AUTO_NEGATE_SETTINGS_HEADER = 'react.kwa.auto_negate_settings.header',
  KWA_AUTO_NEGATE_SETTINGS_DISABLED = 'react.kwa.auto_negate_settings.disabled',
  KWA_AUTO_NEGATE_SETTINGS_ENABLED = 'react.kwa.auto_negate_settings.enabled',
  KWA_AUTO_NEGATE_SETTINGS_DESCRIPTION = 'react.kwa.auto_negate_settings.description',
  KWA_AUTO_NEGATE_SETTINGS_CONTENT_HEADER = 'react.kwa.auto_negate_settings.content_header',
  KWA_AUTO_NEGATE_SETTINGS_LEARN_LINK = 'react.kwa.auto_negate_settings.learn_link',
  KWA_AUTO_NEGATE_SETTINGS_TOGGLE_ON = 'react.kwa.auto_negate_settings.toggle_on',
  KWA_AUTO_NEGATE_SETTINGS_TOGGLE_OFF = 'react.kwa.auto_negate_settings.toggle_off',

  KWA_SOURCES_COLUMN = 'react.kwa.sources.column',
  KWA_SOURCES_COUNT = 'react.kwa.sources.count',
  KWA_SOURCES_KEYWORD_SOURCES = 'react.kwa.sources.keyword_sources',
  KWA_SOURCES_PAT_SOURCES = 'react.kwa.sources.pat_sources',
  KWA_SOURCES_VIEW_TOOLTIP = 'react.kwa.sources.view.tooltip',
  KWA_CHANGE_DESTINATION_TOOLTIP = 'react.kwa.destination.change_destination.tooltip',

  NOTIFICATIONS_PANEL_TITLE = 'react.notifications_panel.title',
  NOTIFICATIONS_PANEL_MARK_ALL_READ = 'react.notifications_panel.mark_all_read',
  NOTIFICATIONS_PANEL_TODAY = 'react.notifications_panel.today',
  NOTIFICATIONS_PANEL_YESTERDAY = 'react.notifications_panel.yesterday',
  NOTIFICATIONS_PANEL_THIS_WEEK = 'react.notifications_panel.this_week',
  NOTIFICATIONS_PANEL_LAST_WEEK = 'react.notifications_panel.last_week',
  NOTIFICATIONS_PANEL_OLDER = 'react.notifications_panel.older',
  NOTIFICATIONS_PANEL_VIEW_INBOX = 'react.notifications_panel.view_inbox',
  NOTIFICATIONS_PANEL_VIEW_ALL = 'react.notifications_panel.view_all',
  NOTIFICATIONS_PANEL_MARK_AS_READ = 'react.notifications_panel.mark_as_read',
  NOTIFICATIONS_PANEL_MARK_AS_UNREAD = 'react.notifications_panel.mark_as_unread',

  NOTIFICATIONS_PAGE_TITLE = 'react.pages.notifications.title',
  NOTIFICATIONS_PAGE_SIDEBAR_ALL_TAB = 'react.pages.notifications.sidebar.tab.all',
  NOTIFICATIONS_PAGE_SIDEBAR_PRODUCT_TAB = 'react.pages.notifications.sidebar.tab.product',
  NOTIFICATIONS_PAGE_SIDEBAR_ADVERTISING_TAB = 'react.pages.notifications.sidebar.tab.advertising',
  NOTIFICATIONS_PAGE_SIDEBAR_CUSTOM_TAB = 'react.pages.notifications.sidebar.tab.custom',
  NOTIFICATIONS_PAGE_SIDEBAR_MANAGE_ALERTS_LINK = 'react.pages.notifications.sidebar.link.manage_alerts',
  NOTIFICATIONS_PAGE_SEARCH_INPUT_PLACEHOLDER = 'react.pages.notifications.input.search.placeholder',
  NOTIFICATIONS_PAGE_TOGGLE_SHOW_ALL_UNREAD = 'react.pages.notifications.toggle.show_all_unread',

  INVENTORY_DASHBOARD_RELOAD_TEXT = 'react.inventory_dashboard.dashboard.reload_text',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_HEADER = 'react.inventory_dashboard.dashboard.active_inventory.table_header',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_CURRENTLY = 'react.inventory_dashboard.dashboard.active_inventory.currently',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_GROUP_HEALTH = 'react.inventory_dashboard.dashboard.active_inventory.table.group_health',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_GROUP_PERFORMANCE = 'react.inventory_dashboard.dashboard.active_inventory.table.group_performance',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_GROUP_INVENTORY = 'react.inventory_dashboard.dashboard.active_inventory.table.group_inventory',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_DAYS_OF_INVENTORY = 'react.inventory_dashboard.dashboard.active_inventory.table.column.days_of_inventory',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_INVENTORY_AVAILABLE = 'react.inventory_dashboard.dashboard.active_inventory.table.column.inventory_available',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_RATE_OF_SALE = 'react.inventory_dashboard.dashboard.active_inventory.table.column.rate_of_sale',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_TOTAL_SALES = 'react.inventory_dashboard.dashboard.active_inventory.table.column.total_sales',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_UNITS_SOLD = 'react.inventory_dashboard.dashboard.active_inventory.table.column.units_sold',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_SELL_THROUGH_RATE = 'react.inventory_dashboard.dashboard.active_inventory.table.column.sell_through_rate',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_INVENTORY_SALES_RATIO = 'react.inventory_dashboard.dashboard.active_inventory.table.column.inventory_sales_ratio',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_ACTIVE_CAMPAIGNS = 'react.inventory_dashboard.dashboard.active_inventory.table.column.active_campaigns',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_INVENTORY_AGE_1 = 'react.inventory_dashboard.dashboard.active_inventory.table.column.inventory_age_1',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_INVENTORY_AGE_2 = 'react.inventory_dashboard.dashboard.active_inventory.table.column.inventory_age_2',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_INVENTORY_AGE_3 = 'react.inventory_dashboard.dashboard.active_inventory.table.column.inventory_age_3',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_INVENTORY_AGE_4 = 'react.inventory_dashboard.dashboard.active_inventory.table.column.inventory_age_4',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN_INVENTORY_AGE_5 = 'react.inventory_dashboard.dashboard.active_inventory.table.column.inventory_age_5',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN__FILTER_INVENTORY_AGE_1 = 'react.inventory_dashboard.dashboard.active_inventory.table.column.filter.inventory_age_1',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN__FILTER_INVENTORY_AGE_2 = 'react.inventory_dashboard.dashboard.active_inventory.table.column.filter.inventory_age_2',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN__FILTER_INVENTORY_AGE_3 = 'react.inventory_dashboard.dashboard.active_inventory.table.column.filter.inventory_age_3',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN__FILTER_INVENTORY_AGE_4 = 'react.inventory_dashboard.dashboard.active_inventory.table.column.filter.inventory_age_4',
  INVENTORY_DASHBOARD_ACTIVE_INVENTORY_TABLE_COLUMN__FILTER_INVENTORY_AGE_5 = 'react.inventory_dashboard.dashboard.active_inventory.table.column.filter.inventory_age_5',

  INVENTORY_DASHBOARD_GLOSSARY_DAYS_OF_INVENTORY_DEFINATION = 'react.inventory_dashboard.dashboard.glossary.days_of_inventory.defination',
  INVENTORY_DASHBOARD_GLOSSARY_INVENTORY_AVAILABLE_DEFINATION = 'react.inventory_dashboard.dashboard.glossary.inventory_available.defination',
  INVENTORY_DASHBOARD_GLOSSARY_RATE_OF_SALE_DEFINATION = 'react.inventory_dashboard.dashboard.glossary.rate_of_sale.defination',
  INVENTORY_DASHBOARD_GLOSSARY_RATE_OF_SALE_DEFINATION_2 = 'react.inventory_dashboard.dashboard.glossary.rate_of_sale.defination_2',
  INVENTORY_DASHBOARD_GLOSSARY_RATE_OF_SALE_DEFINATION_3 = 'react.inventory_dashboard.dashboard.glossary.rate_of_sale.defination_3',
  INVENTORY_DASHBOARD_GLOSSARY_UNIT_SOLD_DEFINATION = 'react.inventory_dashboard.dashboard.glossary.unit_sold.defination',
  INVENTORY_DASHBOARD_GLOSSARY_SELL_THROUGH_RATE_DEFINATION = 'react.inventory_dashboard.dashboard.glossary.sell_through_rate.definition',
  INVENTORY_DASHBOARD_GLOSSARY_SELL_THROUGH_RATE_FORMULA = 'react.inventory_dashboard.dashboard.glossary.sell_through_rate.formula',
  INVENTORY_DASHBOARD_GLOSSARY_INVENTORY_SALES_RATIO_DEFINATION = 'react.inventory_dashboard.dashboard.glossary.inventory_sales_ratio.definition',
  INVENTORY_DASHBOARD_GLOSSARY_INVENTORY_SALES_RATIO_FORMULA = 'react.inventory_dashboard.dashboard.glossary.inventory_sales_ratio.formula',
  INVENTORY_DASHBOARD_GLOSSARY_ACTIVE_CAMPAIGNS_DEFINATION = 'react.inventory_dashboard.dashboard.glossary.active_campaigns.definition',
  INVENTORY_DASHBOARD_GLOSSARY_ESTIMATED_MISSED_SALES_HEADING = 'react.inventory_dashboard.dashboard.glossary.estimated_missed_sales.heading',
  INVENTORY_DASHBOARD_GLOSSARY_ESTIMATED_MISSED_SALES_DEFINITION = 'react.inventory_dashboard.dashboard.glossary.estimated_missed_sales.definition',
  INVENTORY_DASHBOARD_GLOSSARY_ESTIMATED_MISSED_SALES_FORMULA = 'react.inventory_dashboard.dashboard.glossary.estimated_missed_sales.formula',
  INVENTORY_DASHBOARD_GLOSSARY_ESTIMATED_MISSED_SALES_EXAMPLE = 'react.inventory_dashboard.dashboard.glossary.estimated_missed_sales.example',
  INVENTORY_DASHBOARD_GLOSSARY_UNSOLD_INVENTORY_HEADING = 'react.inventory_dashboard.dashboard.glossary.unsold_inventory.heading',
  INVENTORY_DASHBOARD_GLOSSARY_UNSOLD_INVENTORY_DEFINITION = 'react.inventory_dashboard.dashboard.glossary.unsold_inventory.definition',
  INVENTORY_DASHBOARD_GLOSSARY_INVENTORY_AGING_HEADING = 'react.inventory_dashboard.dashboard.glossary.inventory_aging.heading',
  INVENTORY_DASHBOARD_GLOSSARY_INVENTORY_AGING_DEFINITION = 'react.inventory_dashboard.dashboard.glossary.inventory_aging.definition',
  INVENTORY_DASHBOARD_GLOSSARY_AVAILABLE_UNITS_TO_SELL_HEADING = 'react.inventory_dashboard.dashboard.glossary.available_units_to_sell.heading',
  INVENTORY_DASHBOARD_GLOSSARY_AVAILABLE_UNITS_TO_SELL_DEFINITION = 'react.inventory_dashboard.dashboard.glossary.available_units_to_sell.definition',
  INVENTORY_DASHBOARD_GLOSSARY_LAST_LIST_PRICE_HEADING = 'react.inventory_dashboard.dashboard.glossary.last_list_price.heading',
  INVENTORY_DASHBOARD_GLOSSARY_LAST_LIST_PRICE_DEFINITION = 'react.inventory_dashboard.dashboard.glossary.last_list_price.definition',
  INVENTORY_DASHBOARD_GLOSSARY_LAST_LIST_PRICE_FORMULA = 'react.inventory_dashboard.dashboard.glossary.last_list_price.formula',
  INVENTORY_DASHBOARD_INVENTORY_AGEING_5 = 'react.inventory_dashboard.breakdown.inventory_age_5',
  INVENTORY_DASHBOARD_INVENTORY_AGEING_4 = 'react.inventory_dashboard.breakdown.inventory_age_4',
  INVENTORY_DASHBOARD_INVENTORY_AGEING_3 = 'react.inventory_dashboard.breakdown.inventory_age_3',
  INVENTORY_DASHBOARD_INVENTORY_AGEING_2 = 'react.inventory_dashboard.breakdown.inventory_age_2',
  INVENTORY_DASHBOARD_INVENTORY_AGEING_1 = 'react.inventory_dashboard.breakdown.inventory_age_1',
  INVENTORY_DASHBOARD_MISSING_COGS_BANNER_TEXT = 'react.inventory_dashboard.missing_cogs_banner.text',
  INVENTORY_DASHBOARD_MISSING_COGS_BANNER_LINK = 'react.inventory_dashboard.missing_cogs_banner.link',
  INVENTORY_DASHBOARD_INVENTORY_HEALTH_EXPORT_SUCCESS_MESSAGE = 'react.inventory_dashboard.inventory_health.details.export.success.message',
  INVENTORY_DASHBOARD_INVENTORY_HEALTH_INVENTORY_VALUE_TOOLTIP = 'react.inventory_dashboard.inventory_health.details.inventory_value.tooltip',

  INVENTORY_DASHBOARD_INVENTORY_HEALTH_HEADER = 'react.inventory_dashboard.dashboard.inventory_health.header',
  INVENTORY_DASHBOARD_INVENTORY_VALUE_BREAKDOWN_HEADER = 'react.inventory_dashboard.dashboard.inventory_value_breakdown.header',
  INVENTORY_DASHBOARD_INVENTORY_HEALTH_DESCRIPTION = 'react.inventory_dashboard.dashboard.inventory_health.description',
  INVENTORY_DASHBOARD_INVENTORY_POTENTIAL_HEADER = 'react.inventory_dashboard.dashboard.inventory_potential.header',
  INVENTORY_DASHBOARD_INVENTORY_POTENTIAL_DESCRIPTION = 'react.inventory_dashboard.dashboard.inventory_potential.description',
  INVENTORY_DASHBOARD_METRIC_CARD_INVENTORY_VALUE_LABEL = 'react.inventory_dashboard.metric_cards.inventory_value.label',
  INVENTORY_DASHBOARD_METRIC_CARD_UNSOLD_INVENTORY_LABEL = 'react.inventory_dashboard.metric_cards.unsold_inventory.label',
  INVENTORY_DASHBOARD_METRIC_CARD_UNSOLD_INVENTORY_SUB_HEADER = 'react.inventory_dashboard.metric_cards.unsold_inventory_value.sub_header',
  INVENTORY_DASHBOARD_METRIC_CARD_UNSOLD_INVENTORY_VALUE_LABEL = 'react.inventory_dashboard.metric_cards.unsold_inventory_value.label',
  INVENTORY_DASHBOARD_METRIC_CARD_OUT_OF_STOCK_LABEL = 'react.inventory_dashboard.metric_cards.out_of_stock.label',
  INVENTORY_DASHBOARD_METRIC_CARD_OUT_OF_STOCK_SUB_HEADER = 'react.inventory_dashboard.metric_cards.out_of_stock.sub_header',
  INVENTORY_DASHBOARD_METRIC_CARD_OUT_OF_STOCK_IN_LAST_30_DAYS = 'react.inventory_dashboard.metric_cards.out_of_stock.last_30_days',
  INVENTORY_DASHBOARD_METRIC_CARD_SELL_THROUGH_RATE_LABEL = 'react.inventory_dashboard.metric_cards.sell_through_rate.label',
  INVENTORY_DASHBOARD_METRIC_CARD_INVENTORY_SALES_RATIO_LABEL = 'react.inventory_dashboard.metric_cards.inventory_sales_ratio.label',
  INVENTORY_DASHBOARD_METRIC_CARD_PREV_90_DAYS_LABEL = 'react.inventory_dashboard.metric_cards.prev_90_days.label',
  INVENTORY_DASHBOARD_METRIC_CARD_INVENTORY_NET_WORTH_LABEL = 'react.inventory_dashboard.metric_cards.inventory_net_worth.label',
  INVENTORY_DASHBOARD_METRIC_CARD_TOTAL_ACTIVE_INVENTORY_VALUE_LABEL = 'react.inventory_dashboard.metric_cards.total_active_inventory_value.label',
  INVENTORY_DASHBOARD_METRIC_CARD_TOTAL_ACTIVE_INVENTORY_VALUE_DEFINITION = 'react.inventory_dashboard.metric_cards.total_active_inventory_value.definition',
  INVENTORY_DASHBOARD_METRIC_CARD_TOTAL_ACTIVE_INVENTORY_VALUE_FORMULA = 'react.inventory_dashboard.metric_cards.total_active_inventory_value.formula',
  INVENTORY_DASHBOARD_METRIC_CARD_SELL_THROUGH_RATE_DEFINITION = 'react.inventory_dashboard.metric_cards.sell_through_rate.definition',
  INVENTORY_DASHBOARD_METRIC_CARD_INVENTORY_SALES_RATIO_DEFINITION = 'react.inventory_dashboard.metric_cards.inventory_sales_ratio.definition',
  INVENTORY_DASHBOARD_METRIC_CARD_UNSOLD_INVENTORY_DEFINITION = 'react.inventory_dashboard.metric_cards.unsold_inventory.definition',
  INVENTORY_DASHBOARD_METRIC_CARD_EST_MISSED_SALES_TOOLTIP_DEFINITION = 'react.inventory_dashboard.metric_cards.est_missed_sales.tooltip.definition',
  INVENTORY_DASHBOARD_METRIC_CARD_EST_MISSED_SALES_PRODUCT_STOCKED_OUT_SUB_HEADER = 'react.inventory_dashboard.metric_cards.est_missed_sales.product.stocked_out.sub_header',

  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_PRODUCT_COUNT = 'react.inventory_dashboard.unsold_inventory_details.product_count',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_LAST_90_days = 'react.inventory_dashboard.unsold_inventory_details.last_90_days',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_SELL_THROUGH_RATE_LABEL = 'react.inventory_dashboard.unsold_inventory_details.column.sell_through_rate.label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_INVENTORY_SALES_RATIO_LABEL = 'react.inventory_dashboard.unsold_inventory_details.column.inventory_sales_ratio.label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_DAYS_OF_INVENTORY_LABEL = 'react.inventory_dashboard.unsold_inventory_details.column.days_of_inventory.label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_INVENTORY_AVAILABLE_LABEL = 'react.inventory_dashboard.unsold_inventory_details.column.inventory_available.label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_RATE_OF_SALE_LABEL = 'react.inventory_dashboard.unsold_inventory_details.column.rate_of_sale.label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_ACTIVE_CAMPAIGNS_LABEL = 'react.inventory_dashboard.unsold_inventory_details.column.active_campaigns.label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_365_PLUS_LABEL = 'react.inventory_dashboard.unsold_inventory_details.column.365_plus.label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_271_365_LABEL = 'react.inventory_dashboard.unsold_inventory_details.column.271_365.label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_181_270_LABEL = 'react.inventory_dashboard.unsold_inventory_details.column.181_270.label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_91_180_LABEL = 'react.inventory_dashboard.unsold_inventory_details.column.91_180.label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_DETAILS_0_90_LABEL = 'react.inventory_dashboard.unsold_inventory_details.column.0_90.label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_PRODUCT_SUBHEADING = 'react.inventory_dashboard.unsold_inventory.product.subheading',
  INVENTORY_DASHBOARD_RATE_OF_SALE_TOO_LOW = 'react.inventory_dashboard.rate_of_sale_too_low',
  INVENTORY_DASHBOARD_RATE_OF_SALE_UNAVAILABLE = 'react.inventory_dashboard.rate_of_sale_unavailable',

  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_LAST_90_DAYS_UNSOLD_INVENTORY = 'react.inventory_dashboard.unsold_inventory_details.column_group.last_90_days_unsold_inventory',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_CURRENT_INVENTORY_HEALTH = 'react.inventory_dashboard.unsold_inventory_details.column_group.current_inventory_health',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_INVENTORY_AGE_BY_DAYS = 'react.inventory_dashboard.unsold_inventory_details.column_group.inventory_age_by_days',
  INVENTORY_DASHBOARD_GLOSSARY_UNSOLD_INVENTORY_L90_DEFINATION = 'react.inventory_dashboard.dashboard.glossary.unsold_inventory_l90.definition',

  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_POTENTIAL_MISSED_SALES = 'react.inventory_dashboard.tables.out_of_stock.potential_missed_sales',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_CURRENT_OUT_OF_STOCK = 'react.inventory_dashboard.tables.out_of_stock.current_out_of_stock',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_THIRTY_DAY_PERFORMANCE = 'react.inventory_dashboard.tables.out_of_stock.last_30_day_performance',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_LAST_LIST_PRICE = 'react.inventory_dashboard.tables.out_of_stock.last_list_price',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_RATE_OF_SALE = 'react.inventory_dashboard.tables.out_of_stock.rate_of_sale',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_PRODUCTS_COUNT = 'react.inventory_dashboard.tables.out_of_stock.products_count',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_EST_MISSED_SALES_NA_TOOLTIP = 'react.inventory_dashboard.tables.out_of_stock.est_missed_sales_na_tooltip',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_DAYS_OOS = 'react.inventory_dashboard.tables.out_of_stock.days_oos',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_CURRENTLY = 'react.inventory_dashboard.tables.out_of_stock.currently',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_ESTIMATED_TOTAL_MISSED_SALES = 'react.inventory_dashboard.tables.out_of_stock.estimated_total_missed_sales',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_EST_MISSED_SALES = 'react.inventory_dashboard.tables.out_of_stock.estimated_missed_sales',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_AVG_RATE_OF_SALES = 'react.inventory_dashboard.tables.out_of_stock.avg_rate_of_sales',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_GRAPH_SUB_HEADER = 'react.inventory_dashboard.tables.out_of_stock.graph_sub_header',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_WITHIN_LAST_90_DAYS = 'react.inventory_dashboard.tables.out_of_stock.within_last_90_days',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_EST_MISSED_SALES_DESC = 'react.inventory_dashboard.tables.out_of_stock.estimated_missed_sales_desc',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_EST_MISSED_SALES_FORMULA = 'react.inventory_dashboard.tables.out_of_stock.estimated_missed_sales_formula',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_OUT_OF_STOCK_DATE_DESC = 'react.inventory_dashboard.tables.out_of_stock.out_of_stock_date_desc',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_LAST_LIST_PRICE_DESC = 'react.inventory_dashboard.tables.out_of_stock.last_list_price_desc',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_LAST_LIST_PRICE_FORMULA = 'react.inventory_dashboard.tables.out_of_stock.last_list_price_formula',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_TOOLTIP_LABEL = 'react.inventory_dashboard.tables.out_of_stock.oos_tooltip_label',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_EST_MISSED_SALES_CARD_TOOLTIP = 'react.inventory_dashboard.tables.out_of_stock.est_missed_sales_card_tooltip',
  INVENTORY_DASHBOARD_TABLE_OUT_OF_STOCK_EST_MISSED_SALES_CARD_FORMULA = 'react.inventory_dashboard.tables.out_of_stock.est_missed_sales_card_formula',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_PRODUCT_EMPTY_LABEL = 'react.inventory_dashboard.unsold_inventory.product.empty_label',
  INVENTORY_DASHBOARD_OUT_OF_STOCK_PRODUCT_EMPTY_LABEL = 'react.inventory_dashboard.out_of_stock.product.empty_label',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_PRODUCT_SLIDEOUT_HEADER = 'react.inventory_dashboard.unsold_inventory.slideout.header',
  INVENTORY_DASHBOARD_UNSOLD_INVENTORY_PRODUCT_SLIDEOUT_DESCRIPTION = 'react.inventory_dashboard.unsold_inventory.slideout.description',
  INVENTORY_DASHBOARD_INVENTORY_AGE_TOOLTIP = 'react.inventory_dashboard.inventory_age.tooltip',
  INVENTORY_DASHBOARD_INVENTORY_AGE_BREAKDOWN_TOOLTIP = 'react.inventory_dashboard.inventory_age_breakdown.tooltip',
  INVENTORY_DASHBOARD_GLOSSARY_ACTIVE_INVENTORY_VALUE_LABEL = 'react.inventory_dashboard.glossary.active_inventory_value.label',
  INVENTORY_DASHBOARD_GLOSSARY_ACTIVE_INVENTORY_VALUE_FORMULA = 'react.inventory_dashboard.glossary.active_inventory_value.formula',
  INVENTORY_DASHBOARD_DATA_SYNCING_BANNER_LABEL = 'react.inventory_optimization.inventory_dashboard.data_syncing_banner.label',

  BULK_ACTIONS_TABS_WALMART_SPA = 'react.bulk_actions.tabs.walmart_sp',
  BULK_ACTIONS_TABS_WALMART_SBA = 'react.bulk_actions.tabs.walmart_sba',
  BULK_ACTIONS_TABS_WALMART_SV = 'react.bulk_actions.tabs.walmart_sv',
  BULK_ACTIONS_TABS_WALMART_BUDGET_MANAGEMENT = 'react.bulk_actions.tabs.walmart_budget_management',
  BULK_ACTIONS_TABS_WALMART_DAY_PARTING = 'react.bulk_actions.tabs.walmart_day_parting',
  BULK_ACTIONS_TABS_WALMART_SP_TITLE = 'react.bulk_actions.walmart_sp.title',
  BULK_ACTIONS_TABS_WALMART_SBA_TITLE = 'react.bulk_actions.walmart_sba.title',
  BULK_ACTIONS_TABS_WALMART_SV_TITLE = 'react.bulk_actions.walmart_sv.title',
  BULK_ACTIONS_TABS_WALMART_BUDGET_MANAGEMENT_TITLE = 'react.bulk_actions.walmart_budget_management.title',
  BULK_ACTIONS_TABS_WALMART_DAY_PARTING_TITLE = 'react.bulk_actions.walmart_day_parting.title',
  BULK_ACTIONS_TABS_WALMART_SP_LEARN_MORE_TITLE = 'react.bulk_actions.walmart_sp.learn_more.title',
  BULK_ACTIONS_TABS_WALMART_SBA_LEARN_MORE_TITLE = 'react.bulk_actions.walmart_sba.learn_more.title',
  BULK_ACTIONS_TABS_WALMART_SV_LEARN_MORE_TITLE = 'react.bulk_actions.walmart_sv.learn_more.title',
  BULK_ACTIONS_TABS_WALMART_BUDGET_MANAGEMENT_LEARN_MORE_TITLE = 'react.bulk_actions.walmart_budget_management.learn_more.title',
  BULK_ACTIONS_TABS_WALMART_DAY_PARTING_LEARN_MORE_TITLE = 'react.bulk_actions.walmart_day_parting.learn_more.title',
  BULK_ACTIONS_TABS_WALMART_SP_CREATE_IN_ONE_GO = 'react.bulk_actions.walmart_sp.create_in_one_go',
  BULK_ACTIONS_TABS_WALMART_SP_EDIT_CREATE_UPDATE = 'react.bulk_actions.walmart_sp.edit_create_update',
  BULK_ACTIONS_TABS_WALMART_SP_CREATE_UPDATE_KEYWORDS = 'react.bulk_actions.walmart_sp.create_update_keywords',
  BULK_ACTIONS_TABS_WALMART_SP_CREATE_UPDATE_BID_MULTIPLIERS = 'react.bulk_actions.walmart_sp.create_update_bid_multipliers',
  BULK_ACTIONS_TABS_WALMART_SP_CHECK_BASE_VARIANT_ITEMS = 'react.bulk_actions.walmart_sp.check_base_variant_items',
  BULK_ACTIONS_TABS_WALMART_SBA_UPLOAD_IMAGE = 'react.bulk_actions.walmart_sba.upload_image',
  BULK_ACTIONS_TABS_WALMART_SBA_CREATE_IN_ONE_GO = 'react.bulk_actions.walmart_sba.create_in_one_go',
  BULK_ACTIONS_TABS_WALMART_SBA_EDIT_CREATE_UPDATE = 'react.bulk_actions.walmart_sba.edit_create_update',
  BULK_ACTIONS_TABS_WALMART_SBA_CREATE_UPDATE_KEYWORDS_AND_BIDS = 'react.bulk_actions.walmart_sba.create_update_keywords_and_bids',
  BULK_ACTIONS_TABS_WALMART_CSV_NOTE = 'react.bulk_actions.walmart_csv.note',
  BULK_ACTIONS_TABS_WALMART_SV_UPLOAD_VIDEO_AND_CAPTION = 'react.bulk_actions.walmart_sv.upload_video_and_caption',
  BULK_ACTIONS_TABS_WALMART_SV_CREATE_SPONSORED_VIDEO = 'react.bulk_actions.walmart_sv.create_sponsored_video',
  BULK_ACTIONS_TABS_WALMART_SV_CREATE_IN_ONE_GO = 'react.bulk_actions.walmart_sv.create_in_one_go',
  BULK_ACTIONS_TABS_WALMART_SV_EDIT_CREATE_UPDATE = 'react.bulk_actions.walmart_sv.edit_create_update',
  BULK_ACTIONS_TABS_WALMART_SV_CREATE_UPDATE_KEYWORDS_AND_BIDS = 'react.bulk_actions.walmart_sv.create_update_keywords_and_bids',
  BULK_ACTIONS_TABS_WALMART_BUDGET_MANAGEMENT_MONTLY = 'react.bulk_actions.walmart_budget_management.monthly_budget',
  BULK_ACTIONS_TABS_WALMART_BUDGET_MANAGEMENT_DAILY = 'react.bulk_actions.walmart_budget_management.daily_budget',
  BULK_ACTIONS_TABS_WALMART_DAY_PARTING_CREATE_UPDATE = 'react.bulk_actions.walmart_day_parting.create_update',
  BULK_ACTIONS_TABLE_RECENT_UPLOADS = 'react.bulk_actions.table.recent_uploads',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_HEADER_SUBMITTED_BY = 'react.bulk_actions.recent_uploads.table.header.submitted_by',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_HEADER_FILE_NAME = 'react.bulk_actions.recent_uploads.table.header.file_name',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_HEADER_UPLOAD_TIME = 'react.bulk_actions.recent_uploads.table.header.upload_time',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_HEADER_UPLOAD_TYPE = 'react.bulk_actions.recent_uploads.table.header.upload_type',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_HEADER_STATUS = 'react.bulk_actions.recent_uploads.table.header.status',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_HEADER_DETAILS = 'react.bulk_actions.recent_uploads.table.header.details',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_HEADER_DOWNLOAD_REPORT = 'react.bulk_actions.recent_uploads.table.header.download_report',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_STATUS_VALIDATED = 'react.bulk_actions.recent_uploads.table.status.validated',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_STATUS_UPLOADED = 'react.bulk_actions.recent_uploads.table.status.uploaded',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_STATUS_INVALID_DOC = 'react.bulk_actions.recent_uploads.table.status.invalid_doc',
  BULK_ACTIONS_UPLOAD_SUCCESS_MESSAGE = 'react.bulk_actions.upload.success_message',
  BULK_ACTIONS_UPLOAD_INFO_MESSAGE_MEDIA = 'react.bulk_actions.upload.info_message_media',
  BULK_ACTIONS_UPLOAD_INFO_MESSAGE_CSV = 'react.bulk_actions.upload.info_message_csv',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_DETAILS_VALID = 'react.bulk_actions.recent_uploads.table.details.valid',
  BULK_ACTIONS_VIDEO_CAPTION_UPLOADS_SUCCESS = 'react.bulk_actions.video_caption_uploads.success',
  BULK_ACTIONS_VIDEO_CAPTION_UPLOADS_INFO_HELP = 'react.bulk_actions.video_caption_uploads.info.help',
  BULK_ACTIONS_VIDEO_CAPTION_UPLOADS_HELP = 'react.bulk_actions.video_caption_uploads.help',
  BULK_ACTIONS_IMAGE_UPLOADS_SUCCESS = 'react.bulk_actions.image_uploads.success',
  BULK_ACTIONS_IMAGE_UPLOADS_INFO_HELP = 'react.bulk_actions.image_uploads.info.help',
  BULK_ACTIONS_IMAGE_UPLOADS_HELP = 'react.bulk_actions.image_uploads.help',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_DOWNLOAD_REPORT_VALIDATION_REPORT = 'react.bulk_actions.recent_uploads.table.download_report.validation_report',
  BULK_ACTIONS_RECENT_UPLOADS_TABLE_DOWNLOAD_REPORT_PROCESSING = 'react.bulk_actions.recent_uploads.table.download_report.processing',
  BULK_ACTIONS_RECENT_UPLOADS_API_ERROR_FILE_SIZE = 'react.bulk_actions.recent_uploads.api.error.file_size',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_TITLE = 'react.bulk_actions.help_text.wbm.abf_title',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_1 = 'react.bulk_actions.help_text.wbm.abf_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_2 = 'react.bulk_actions.help_text.wbm.abf_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_3 = 'react.bulk_actions.help_text.wbm.abf_li3',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_4 = 'react.bulk_actions.help_text.wbm.abf_li4',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_5 = 'react.bulk_actions.help_text.wbm.abf_li5',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_6 = 'react.bulk_actions.help_text.wbm.abf_li6',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_7 = 'react.bulk_actions.help_text.wbm.abf_li7',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_8 = 'react.bulk_actions.help_text.wbm.abf_li8',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_9 = 'react.bulk_actions.help_text.wbm.abf_li9',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_10 = 'react.bulk_actions.help_text.wbm.abf_li10',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_11 = 'react.bulk_actions.help_text.wbm.abf_li11',
  BULK_ACTIONS_HELP_TEXT_WBA_ABF_LI_12 = 'react.bulk_actions.help_text.wbm.abf_li12',
  BULK_ACTIONS_HELP_TEXT_WBA_DBMF_TITLE = 'react.bulk_actions.help_text.wbm.dbmf_title',
  BULK_ACTIONS_HELP_TEXT_WBA_DBMF_LI_1 = 'react.bulk_actions.help_text.wbm.dbmf_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_DBMF_LI_2 = 'react.bulk_actions.help_text.wbm.dbmf_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_DBMF_LI_3 = 'react.bulk_actions.help_text.wbm.dbmf_li3',
  BULK_ACTIONS_HELP_TEXT_WBA_DBMF_LI_4 = 'react.bulk_actions.help_text.wbm.dbmf_li4',
  BULK_ACTIONS_HELP_TEXT_WBA_DBMF_LI_5 = 'react.bulk_actions.help_text.wbm.dbmf_li5',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_TITLE = 'react.bulk_actions.help_text.wbm.sp_title',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_1 = 'react.bulk_actions.help_text.wbm.sp_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_2 = 'react.bulk_actions.help_text.wbm.sp_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_3 = 'react.bulk_actions.help_text.wbm.sp_li3',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_4 = 'react.bulk_actions.help_text.wbm.sp_li4',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_5 = 'react.bulk_actions.help_text.wbm.sp_li5',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_6 = 'react.bulk_actions.help_text.wbm.sp_li6',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_7 = 'react.bulk_actions.help_text.wbm.sp_li7',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_8 = 'react.bulk_actions.help_text.wbm.sp_li8',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_9 = 'react.bulk_actions.help_text.wbm.sp_li9',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_10 = 'react.bulk_actions.help_text.wbm.sp_li10',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_11 = 'react.bulk_actions.help_text.wbm.sp_li11',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_12 = 'react.bulk_actions.help_text.wbm.sp_li12',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_13 = 'react.bulk_actions.help_text.wbm.sp_li13',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_14 = 'react.bulk_actions.help_text.wbm.sp_li14',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_15 = 'react.bulk_actions.help_text.wbm.sp_li15',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_15_1 = 'react.bulk_actions.help_text.wbm.sp_li15_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_15_2 = 'react.bulk_actions.help_text.wbm.sp_li15_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_15_3 = 'react.bulk_actions.help_text.wbm.sp_li15_li3',
  BULK_ACTIONS_HELP_TEXT_WBA_SP_LI_15_4 = 'react.bulk_actions.help_text.wbm.sp_li15_li4',
  BULK_ACTIONS_HELP_TEXT_WBA_NOTES_TITLE = 'react.bulk_actions.help_text.wbm.notes_title',
  BULK_ACTIONS_HELP_TEXT_WBA_NOTES_LI_1 = 'react.bulk_actions.help_text.wbm.notes_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_NOTES_LI_2 = 'react.bulk_actions.help_text.wbm.notes_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_NOTES_LI_3 = 'react.bulk_actions.help_text.wbm.notes_li3',
  BULK_ACTIONS_HELP_TEXT_WBA_NOTES_LI_3_1 = 'react.bulk_actions.help_text.wbm.notes_li3_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_NOTES_LI_3_2 = 'react.bulk_actions.help_text.wbm.notes_li3_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_NOTES_LI_3_3 = 'react.bulk_actions.help_text.wbm.notes_li3_li3',
  BULK_ACTIONS_HELP_TEXT_WBA_NOTES_LI_3_4 = 'react.bulk_actions.help_text.wbm.notes_li3_li4',
  BULK_ACTIONS_HELP_TEXT_WBA_NOTES_LI_4 = 'react.bulk_actions.help_text.wbm.notes_li4',
  BULK_ACTIONS_HELP_TEXT_WBA_NOTES_LI_5 = 'react.bulk_actions.help_text.wbm.notes_li5',
  BULK_ACTIONS_HELP_TEXT_WBA_KF_TITLE = 'react.bulk_actions.help_text.wbm.kf_title',
  BULK_ACTIONS_HELP_TEXT_WBA_KF_LI_1 = 'react.bulk_actions.help_text.wbm.kf_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_KF_LI_2 = 'react.bulk_actions.help_text.wbm.kf_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_KF_LI_3 = 'react.bulk_actions.help_text.wbm.kf_li3',
  BULK_ACTIONS_HELP_TEXT_WBA_KF_LI_4 = 'react.bulk_actions.help_text.wbm.kf_li4',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_TITLE = 'react.bulk_actions.help_text.wbm.dp_title',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_1 = 'react.bulk_actions.help_text.wbm.dp_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_2 = 'react.bulk_actions.help_text.wbm.dp_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_2_1 = 'react.bulk_actions.help_text.wbm.dp_li2_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_2_2 = 'react.bulk_actions.help_text.wbm.dp_li2_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_2_3 = 'react.bulk_actions.help_text.wbm.dp_li2_li3',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_3 = 'react.bulk_actions.help_text.wbm.dp_li3',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_4 = 'react.bulk_actions.help_text.wbm.dp_li4',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_5 = 'react.bulk_actions.help_text.wbm.dp_li5',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_5_1 = 'react.bulk_actions.help_text.wbm.dp_li5_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_5_2 = 'react.bulk_actions.help_text.wbm.dp_li5_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_5_3 = 'react.bulk_actions.help_text.wbm.dp_li5_li3',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_5_4 = 'react.bulk_actions.help_text.wbm.dp_li5_li4',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_5_5 = 'react.bulk_actions.help_text.wbm.dp_li5_li5',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_5_6 = 'react.bulk_actions.help_text.wbm.dp_li5_li6',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_5_7 = 'react.bulk_actions.help_text.wbm.dp_li5_li7',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_6 = 'react.bulk_actions.help_text.wbm.dp_li6',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_7 = 'react.bulk_actions.help_text.wbm.dp_li7',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_8 = 'react.bulk_actions.help_text.wbm.dp_li8',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_LI_9 = 'react.bulk_actions.help_text.wbm.dp_li9',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_NOTES_LI_1 = 'react.bulk_actions.help_text.wbm.dp_notes_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_NOTES_LI_2 = 'react.bulk_actions.help_text.wbm.dp_notes_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_EXAMPLE = 'react.bulk_actions.help_text.wbm.dp_example',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_EXAMPLE_LI_1 = 'react.bulk_actions.help_text.wbm.dp_example_li1',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_EXAMPLE_LI_1_SUBTEXT = 'react.bulk_actions.help_text.wbm.dp_example_li1_subtext',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_EXAMPLE_LI_2 = 'react.bulk_actions.help_text.wbm.dp_example_li2',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_EXAMPLE_LI_2_SUBTEXT = 'react.bulk_actions.help_text.wbm.dp_example_li2_subtext',
  BULK_ACTIONS_HELP_TEXT_WBA_DP_EXAMPLE_LI_2_HELP = 'react.bulk_actions.help_text.wbm.dp_example_li2_help',

  AO_DASHBOARD_HEADER = 'react.ao_dashboard.header',
  AO_DASHBOARD_SUB_HEADER = 'react.ao_dashboard.sub_header',
  AO_DASHBOARD_LAUNCH_CONSOLE_PLACEHOLDER = 'react.ao_dashboard.launch_console.placeholder',
  AO_DASHBOARD_LAUNCH_CONSOLE_OPTION_CAMPAIGN_CREATOR = 'react.ao_dashboard.launch_console.option.campaign_creator',
  AO_DASHBOARD_LAUNCH_CONSOLE_OPTION_AMAZON_CONSOLE = 'react.ao_dashboard.launch_console.option.amazon_console',
  AO_DASHBOARD_LAUNCH_CONSOLE_OPTION_WALMART_CONSOLE = 'react.ao_dashboard.launch_console.option.walmart_console',
  AO_DASHBOARD_TABLE_METRIC_NOT_AVAILABLE_TOOLTIP_AD_TYPE = 'react.ao_dashboard.table.metric_not_available_tooltip_ad_type',
  AO_DASHBOARD_TABLE_METRIC_NOT_AVAILABLE_TOOLTIP_MERCHANT = 'react.ao_dashboard.table.metric_not_available_tooltip_merchant',
  AO_DASHBOARD_TABLE_METRIC_NOT_AVAILABLE_TOOLTIP_CHANNEL = 'react.ao_dashboard.table.metric_not_available_tooltip_channel',
  AO_DASHBOARD_TABLE_CHANNELS_AND_AD_TYPE_COLUMN = 'react.ao_dashboard.table.channel_and_ad_type_column',
  AO_DASHBOARD_TABLE_CHANNELS_AND_AD_TYPE_MERCHANTS_COUNT = 'react.ao_dashboard.table.channel_and_ad_type.merchants_count',
  AO_DASHBOARD_TABLE_DATE_RANGE_COMPARISON = 'react.ao_dashboard.table.date_range_comparison',
  AO_DASHBOARD_TABLE_PERCENT_OF_TOTAL_AD_SPEND_COLUMN = 'react.ao_dashboard.table.percent_of_total_ad_spend_column',
  AO_DASHBOARD_TABLE_MORE_DETAILS_COLUMN = 'react.ao_dashboard.table.more_details_column',
  AO_DASHBOARD_MISSING_CONNECTIONS_HEADER = 'react.ao_dashboard.missing_connections.header',
  AO_DASHBOARD_MISSING_CONNECTIONS_DESCRIPTION = 'react.ao_dashboard.missing_connections.description',
  AO_DASHBOARD_SYNCING_CONNECTION_TITLE = 'react.ao_dashboard.syncing_connection.title',
  AO_DASHBOARD_SYNCING_CONNECTION_DESCRIPTION = 'react.ao_dashboard.syncing_connection.description',
  AO_DASHBOARD_TABLE_COLUMN_TOTAL_SALES = 'react.ao_dashboard.table_column.total_sales',
  AO_DASHBOARD_GLOSSARY_TOTAL_SALES_DEFINITION = 'react.ao_dashboard.glossary.total_sales_definition',
  AO_DASHBOARD_GLOSSARY_TOTAL_SALES_FORMULA = 'react.ao_dashboard.glossary.total_sales_formula',
  AO_DASHBOARD_TABLE_COLUMN_AD_SALES = 'react.ao_dashboard.table_column.ad_sales',
  AO_DASHBOARD_GLOSSARY_AD_SALES_DEFINITION = 'react.ao_dashboard.glossary.ad_sales_definition',
  AO_DASHBOARD_GLOSSARY_AD_SALES_FORMULA = 'react.ao_dashboard.glossary.ad_sales_formula',
  AO_DASHBOARD_TABLE_COLUMN_ROAS = 'react.ao_dashboard.table_column.roas',
  AO_DASHBOARD_GLOSSARY_ROAS_DEFINITION = 'react.ao_dashboard.glossary.roas_definition',
  AO_DASHBOARD_GLOSSARY_ROAS_FORMULA = 'react.ao_dashboard.glossary.roas_formula',
  AO_DASHBOARD_TABLE_COLUMN_TACOS = 'react.ao_dashboard.table_column.tacos',
  AO_DASHBOARD_GLOSSARY_TACOS_DEFINITION = 'react.ao_dashboard.glossary.tacos_definition',
  AO_DASHBOARD_GLOSSARY_TACOS_FORMULA = 'react.ao_dashboard.glossary.tacos_formula',
  AO_DASHBOARD_TABLE_COLUMN_AD_SPEND = 'react.ao_dashboard.table_column.ad_spend',
  AO_DASHBOARD_GLOSSARY_AD_SPEND_DEFINITION = 'react.ao_dashboard.glossary.ad_spend_definition',
  AO_DASHBOARD_GLOSSARY_AD_SPEND_FORMULA = 'react.ao_dashboard.glossary.ad_spend_formula',
  AO_DASHBOARD_TABLE_COLUMN_PERCENTAGE_OF_TOTAL_AD_SPEND = 'react.ao_dashboard.table_column.percentage_of_total_ad_spend',
  AO_DASHBOARD_GLOSSARY_PERCENTAGE_OF_TOTAL_AD_SPEND_FORMULA = 'react.ao_dashboard.glossary.percentage_of_total_ad_spend_formula',

  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_TITLE = 'react.recommendation_hub.inventory_optimization.title',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_DESCRIPTION = 'react.recommendation_hub.inventory_optimization.description',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_DEMAND_FORECAST_TITLE = 'react.recommendation_hub.inventory_optimization.demand_forcast.title',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_DEMAND_FORECAST_DESCRIPTION = 'react.recommendation_hub.inventory_optimization.demand_forcast.description',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_RATE_OF_SALE_TITLE = 'react.recommendation_hub.inventory_optimization.rate_of_sale.title',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_RATE_OF_SALE_DESCRIPTION = 'react.recommendation_hub.inventory_optimization.rate_of_sale.description',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_OUT_OF_STOCK_TITLE = 'react.recommendation_hub.inventory_optimization.out_of_stock.title',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_OUT_OF_STOCK_DESCRIPTION = 'react.recommendation_hub.inventory_optimization.out_of_stock.description',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_EXCESS_STOCK_TITLE = 'react.recommendation_hub.inventory_optimization.excess_stock.title',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_EXCESS_STOCK_DESCRIPTION = 'react.recommendation_hub.inventory_optimization.excess_stock.description',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_AGING_INVENTORY_TITLE = 'react.recommendation_hub.inventory_optimization.aging_inventory.title',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_AGING_INVENTORY_DESCRIPTION = 'react.recommendation_hub.inventory_optimization.aging_inventory.description',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_TITLE = 'react.recommendation_hub.inventory_optimization.stockout_reorder.title',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_DESCRIPTION = 'react.recommendation_hub.inventory_optimization.stockout_reorder.description',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_TITLE = 'react.recommendation_hub.inventory_optimization.hiive_clearance.title',
  RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_DESCRIPTION = 'react.recommendation_hub.inventory_optimization.hiive_clearance.description',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_HEADER = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.header',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_SUB_HEADER = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.sub_header',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_HIGHLIGHT_POINT_1 = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.highlight.point_1',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_HIGHLIGHT_POINT_2 = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.highlight.point_2',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_HIGHLIGHT_POINT_3 = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.highlight.point_3',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_HIGHLIGHT_POINT_4 = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.highlight.point_4',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_HIGHLIGHT_POINT_5 = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.highlight.point_5',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_HIGHLIGHT_POINT_6 = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.highlight.point_6',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_SUCCESS_SUB_HEADER = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.success_sub_header',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_PRICING_HEADER = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.pricing.header',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_PRICING_SUBHEADER = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.pricing.subheader',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_ASK_ACCOUNT_OWNER_HEADER = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.ask_acc_owner.header',
  RECOMMENDATION_TRY_RISK_FREE_MODAL_ASK_ACCOUNT_OWNER_SUBHEADER = 'react.recommendation_hub.inventory_optimization.try_risk_free_modal.ask_acc_owner.subheader',

  BUDGET_PACING_CAMPAIGN_DOWNLOAD_STEP_HEADER = 'react.budget_pacing.campaign.download_step.header',
  BUDGET_PACING_CAMPAIGN_INFO_STEP_HEADER = 'react.budget_pacing.campaign.info_step.header',
  BUDGET_PACING_CAMPAIGN_UPLOAD_STEP_HEADER = 'react.budget_pacing.campaign.upload_step.header',
  BUDGET_PACING_CAMPAIGN_CSV_UPLOAD_TOOLTIP = 'react.budget_pacing.campaign.csv_upload.tooltip',
  BUDGET_PACING_CAMPAIGN_BULK_UPLOAD_HEADER = 'react.budget_pacing.campaign.bulk_upload.header',

  ASIN_RECOMMENDATION_TABLE_NO_DATA_DESCRIPTION = 'react.asin_recommendations.table.no_data.description',
  ASIN_RECOMMENDATION_TABLE_COLUMN_PRODUCT_ASIN = 'react.asin_recommendations.table.column.product_asin',
  ASIN_RECOMMENDATION_TOGGLE_TEXT = 'react.asin_recommendations.toggle.text',
  ASIN_RECOMMENDATION_DETAILS_NO_DATA_TEXT = 'react.asin_recommendations.details.no_data.text',
  ASIN_RECOMMENDATION_PRE_SELECT_PAT_CONFIRMATION_HEAD = 'react.asin_recommendations.pre_select_pat.confirmation.head',
  ASIN_RECOMMENDATION_PRE_SELECT_PAT_CONFIRMATION_DESCRIPTION = 'react.asin_recommendations.pre_select_pat.confirmation.description',
  ASIN_RECOMMENDATION_PRE_SELECT_PAT_CONFIRMATION_OFF_HEAD = 'react.asin_recommendations.pre_select_pat.confirmation.off.head',
  ASIN_RECOMMENDATION_PRE_SELECT_PAT_CONFIRMATION_OFF_DESCRIPTION = 'react.asin_recommendations.pre_select_pat.confirmation.off.description',
  ASIN_RECOMMENDATION_PAT_DETAILS_PAGE_HEADING = 'react.asin_recommendations.pat.details_page.heading',
  ASIN_RECOMMENDATION_PAT_DETAILS_PAGE_SELECTED_PRODUCTS = 'react.asin_recommendations.pat.details_page.selected_products',
  ASIN_RECOMMENDATION_DESCRIPTION_BASED_ON = 'react.asin_recommendations.description.based_on',
  ASIN_RECOMMENDATION_DESCRIPTION_1_OR_MORE = 'react.asin_recommendations.description.1_or_more',
  ASIN_RECOMMENDATION_DESCRIPTION_AD_CONVERSIONS = 'react.asin_recommendations.description.ad_conversions',
  ASIN_RECOMMENDATION_DESCRIPTION_CLICKS = 'react.asin_recommendations.description.clicks',
  ASIN_RECOMMENDATION_DESCRIPTION_IN_LAST = 'react.asin_recommendations.description.in_last',
  ASIN_RECOMMENDATION_DESCRIPTION_30_DAYS = 'react.asin_recommendations.description.30_days',
  ASIN_RECOMMENDATION_RECOMMENDED = 'react.asin_recommendations.recommended',
  ASIN_RECOMMENDATION_ENTER_LIST = 'react.asin_recommendations.enter_list',
  ASIN_RECOMMENDATION_NEGATIVE_DETAILS_EXCLUDE_PRODUCT_SEARCH = 'react.asin_recommendations.negative.details.exclude_product_search',
  ASIN_RECOMMENDATION_NEGATIVE_DETAILS_EXCLUDE_PRODUCT_ENTER_LIST = 'react.asin_recommendations.negative.details.exclude_product_enter_list',
  ASIN_NEGATIVE_ERROR_MESSAGE = 'react.asin.negative.error.message',
  KEYWORD_HELP_LINK = 'react.keywords.help',

  CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_SLIDEOVER_HEADING = 'react.campaign_creator.products.est_ad_gross_margin.slideover.heading',
  CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_TABLE_COLUMN_SKU = 'react.campaign_creator.est_ad_gross_margin.table.column.sku',
  CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_TABLE_COLUMN_UNIT_PRICE = 'react.campaign_creator.est_ad_gross_margin.table.column.unit_price',
  CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_TABLE_COLUMN_UNIT_FEES = 'react.campaign_creator.est_ad_gross_margin.table.column.unit_fees',
  CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_TABLE_COLUMN_LAST_UPDATED = 'react.campaign_creator.est_ad_gross_margin.table.column.last_updated',
  CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_TABLE_COLUMN_UNIT_COGS = 'react.campaign_creator.est_ad_gross_margin.table.column.unit_cogs',
  CAMPAIGN_CREATOR_EST_AD_GROSS_MARGIN_TABLE_COLUMN_EST_PRE_AD_MARGIN = 'react.campaign_creator.est_ad_gross_margin.table.column.est_pre_ad_margin',
  CAMPAIGN_CREATOR_BULK_EDIT_LINK_TEXT = 'react.campaign_creator.est_ad_gross_margin.bulk_edit.link.text',
  CAMPAIGN_CREATOR_BULK_EDIT_SELECT_OPTION_CHANGE_ALL = 'react.campaign_creator.est_ad_gross_margin.bulk_edit.select.option.change_all',
  CAMPAIGN_CREATOR_SLIDEOVER_BANNER_TEXT_1 = 'react.campaign_creator.est_ad_gross_margin.banner.text_1',
  CAMPAIGN_CREATOR_SLIDEOVER_BANNER_TEXT_2 = 'react.campaign_creator.est_ad_gross_margin.banner.text_2',
  CAMPAIGN_CREATOR_SLIDEOVER_BANNER_TEXT_3 = 'react.campaign_creator.est_ad_gross_margin.banner.text_3',
  CAMPAIGN_CREATOR_SLIDEOVER_ENTER_COGS = 'react.campaign_creator.est_ad_gross_margin.enter_cogs',
  CAMPAIGN_CREATOR_EST_PRE_AD_GROSS_MARGIN_TOOLTIP = 'react.campaign_creator.est_ad_gross_margin.column.tooltip',
  CAMPAIGN_CREATOR_EST_PRE_AD_GROSS_MARGIN_COLUMN_AD_GOAL_PROFIT_NA = 'react.campaign_creator.est_ad_gross_margin.column.ad_goal.profit_na',
  CAMPAIGN_CREATOR_PRODUCT_ENTER_LIST_PRODUCT = 'react.campaign_creator.products.enter_list.product',
  CAMPAIGN_CREATOR_PRODUCT_ENTER_LIST_EST_PAGM = 'react.campaign_creator.products.enter_list.est_pagm',
  CAMPAIGN_CREATOR_PRODUCT_ENTER_LIST_AD_GOAL = 'react.campaign_creator.products.enter_list.ad_goal',

  CAMPAIGNS_EDIT_SAVE_SUCCESS_MESSAGE = 'react.campaigns_edit.save.success.description',
  ADS_MANAGER_TABLE_MANAGE_GROUPING_BIDDING_TOOLTIP = 'react.ads_manager.table.manage_grouping.bidding.tooltip',
  ADS_MANAGER_TABLE_MANAGE_GROUPING_MULTIPLE_TOOLTIP = 'react.ads_manager.table.manage_grouping.multi.tooltip',
  PRODUCT_CATALOG_TABLE_MANAGE_COGS_TOOLTIP = 'react.product_catalog.table.manage_cogs.tooltip',
  ADS_MANAGER_CAMPAIGN_GROUPING_MANAGE_GROUPING_LABEL = 'react.ads_manager.campaign_grouping.manage_grouping.label',
  ADS_MANAGER_CAMPAIGN_GROUPING_MANAGE_GROUPING_TOOLTIP = 'react.ads_manager.campaign_grouping.manage_grouping.tooltip',
  ADS_MANAGER_CAMPAIGN_GROUPING_DOWNLOAD_TEMPLATE_NAME = 'react.ads_manager.campaign_grouping.download_step.template_name',
  ADS_MANAGER_CAMPAIGN_GROUPING_DOWNLOAD_STEP_HEADER = 'react.ads_manager.campaign_grouping.download_step.header',
  ADS_MANAGER_CAMPAIGN_GROUPING_DOWNLOAD_STEP_DESCRIPTION = 'react.ads_manager.campaign_grouping.download_step.description',
  ADS_MANAGER_CAMPAIGN_GROUPING_UPDATE_STEP_HEADER = 'react.ads_manager.campaign_grouping.update_step.header',
  ADS_MANAGER_CAMPAIGN_GROUPING_UPLOAD_STEP_HEADER = 'react.ads_manager.campaign_grouping..upload_step.header',
  ADS_MANAGER_CAMPAIGN_GROUPING_UPLOAD_STEP_COLUMN_MISMATCH_ERROR = 'react.ads_manager.campaign_grouping..upload_step.column_mismatch_error',
  HIIVE_CLEARANCE_GROW_MORE_TITLE = 'react.inventory_optimization.hiive_clearance.grow_more_title',
  HIIVE_CLEARANCE_GROW_MORE_DESCRIPTION = 'react.inventory_optimization.hiive_clearance.grow_more_description',
  HIIVE_CLEARANCE_CUSTOMER_ACQUISITION_TITLE = 'react.inventory_optimization.hiive_clearance.customer_acquisition_title',
  HIIVE_CLEARANCE_CUSTOMER_ACQUISITION_DESCRIPTION = 'react.inventory_optimization.hiive_clearance.customer_acquisition_description',
  HIIVE_CLEARANCE_BRAND_MARKETING_TITLE = 'react.inventory_optimization.hiive_clearance.brand_marketing_title',
  HIIVE_CLEARANCE_BRAND_MARKETING_DESCRIPTION = 'react.inventory_optimization.hiive_clearance.brand_marketing_description',
  HIIVE_CLEARANCE_STOCK_CLEARANCE_TITLE = 'react.inventory_optimization.hiive_clearance.stock_clearance_title',
  HIIVE_CLEARANCE_STOCK_CLEARANCE_DESCRIPTION = 'react.inventory_optimization.hiive_clearance.stock_clearance_description',
  HIIVE_CLEARANCE_CREATOR_CONTENT_TITLE = 'react.inventory_optimization.hiive_clearance.creator_content_title',
  HIIVE_CLEARANCE_CREATOR_CONTENT_DESCRIPTION = 'react.inventory_optimization.hiive_clearance.creator_content_description',
  HIIVE_CLEARANCE_LAUNCH_PRODUCTS_TITLE = 'react.inventory_optimization.hiive_clearance.launch_products_title',
  HIIVE_CLEARANCE_LAUNCH_PRODUCTS_DESCRIPTION = 'react.inventory_optimization.hiive_clearance.launch_products_description',
  HIIVE_CLEARANCE_EASY_ONBOARDING_TITLE = 'react.inventory_optimization.hiive_clearance.easy_onboarding_title',
  HIIVE_CLEARANCE_EASY_ONBOARDING_DESCRIPTION = 'react.inventory_optimization.hiive_clearance.easy_onboarding_description',
  HIIVE_CLEARANCE_INTERESTED_BUTTON = 'react.inventory_optimization.hiive_clearance.interested_button',
  HIIVE_CLEARANCE_BANNER_TITLE = 'react.inventory_optimization.hiive_clearance.banner_title',
  HIIVE_CLEARANCE_BANNER_DESCRIPTION = 'react.inventory_optimization.hiive_clearance.banner_description',
  HIIVE_CLEARANCE_BANNER_LEARN_MORE = 'react.inventory_optimization.hiive_clearance.banner_learn_more',
  HIIVE_CLEARANCE_BANNER_CTA = 'react.inventory_optimization.hiive_clearance.banner_cta',

  ACCOUNT_SWITCHED_MODAL_HEADER = 'react.account_switched.header',
  ACCOUNT_SWITCHED_MODAL_DESCRIPTION = 'react.account_switched.description',
  CAMPAIGN_FLEXIBILITY_SMART_CAMPAIGN_BANNER = 'react.pages.campaignFlexibility.dashboard.step_5.smart_campaign.banner',
  CAMPAIGN_FLEXIBILITY_EXISTING_PRODUCT_ADS_BULK_EDIT_BANNER = 'react.pages.campaignFlexibility.dashboard.step_5.existing_product_ads.bulk_edit.header',
  CAMPAIGN_FLEXIBILITY_CAMPAIGN_DAILY_BUDGET_SKU_COUNT = 'react.pages.campaignFlexibility.dashboard.step_5.campaign_daily_budget.sku_count',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_PRODUCT_LAUNCH = 'react.page.product_insights.create_insights.select_metrics.dropdown.value.product_Launch',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_GROW = 'react.page.product_insights.create_insights.select_metrics.dropdown.value.grow',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_PROFIT = 'react.page.product_insights.create_insights.select_metrics.dropdown.value.profit',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_ROAS = 'react.page.product_insights.create_insights.select_metrics.dropdown.value.ROAS',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_VALUE_LIQUIDATA = 'react.page.product_insights.create_insights.select_metrics.dropdown.value.Liquidate',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_PRODUCT_LAUNCH = 'react.page.product_insights.create_insights.select_metrics.dropdown.description.product_Launch',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_GROW = 'react.page.product_insights.create_insights.select_metrics.dropdown.description.grow',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_PROFIT = 'react.page.product_insights.create_insights.select_metrics.dropdown.description.profit',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_PROFIT_NA = 'react.page.product_insights.create_insights.select_metrics.dropdown.description.profit.na',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_ROAS = 'react.page.product_insights.create_insights.select_metrics.dropdown.description.ROAS',
  PRODUCT_INSIGHTS_CREATE_INSIGHTS_SELECT_METRICS_DROPDOWN_DESCRIPTION_LIQUIDATA = 'react.page.product_insights.create_insights.select_metrics.dropdown.description.Liquidate',
  BRANDS_AND_COMPETITORS_ASSIGNMENT_HEADER = 'react.pages.brands_and_competitors.assignment_header',
  BRANDS_AND_COMPETITORS_ASSIGNMENT_DESCRIPTION_1 = 'react.pages.brands_and_competitors.assignment_description_1',
  BRANDS_AND_COMPETITORS_ASSIGNMENT_DESCRIPTION_2 = 'react.pages.brands_and_competitors.assignment_description_2',
  BRANDS_AND_COMPETITORS_ASSIGN_ALL_HEADER = 'react.pages.brands_and_competitors.assign_to_all_header',
  BRANDS_AND_COMPETITORS_ASSIGN_ALL_DESCRIPTION = 'react.pages.brands_and_competitors.assign_to_all_description',
  BRANDS_AND_COMPETITORS_CUSTOM_HEADER = 'react.pages.brands_and_competitors.custom_header',
  BRANDS_AND_COMPETITORS_CUSTOM_DESCRIPTION = 'react.pages.brands_and_competitors.custom_description',
  BRANDS_AND_COMPETITORS_OPT_OUT_LABEL = 'react.pages.brands_and_competitors.opt_out_label',
  BRANDS_AND_COMPETITORS_OPT_OUT_TOOLTIP = 'react.pages.brands_and_competitors.opt_out_tooltip',
  BRANDS_AND_COMPETITORS_CATALOG_PRODUCT_ASSIGNMENT = 'react.pages.brands_and_competitors.catalog_product_assignment',
  BRANDS_AND_COMPETITORS_CATALOG_CLEAR_LIST = 'react.pages.brands_and_competitors.clear_list',
  BRANDS_AND_COMPETITORS_CATALOG_CHANGE_TO_CUSTOM_ASSIGN = 'react.pages.brands_and_competitors.change_to_custom_assign',
  BRANDS_AND_COMPETITORS_CATALOG_CHANGE_TO_ASSIGN_ALL = 'react.pages.brands_and_competitors.change_to_assign_all',
  BRANDS_AND_COMPETITORS_CATALOG_CSV_BULK_MANAGE_GROUPS = 'react.pages.brands_and_competitors.csv_bulk_manage_groups',
  BRANDS_AND_COMPETITORS_CATALOG_BRANDS_TOOLTIP = 'react.pages.brands_and_competitors.brands_tooltip',
  BRANDS_AND_COMPETITORS_CATALOG_COMPETITORS_TOOLTIP = 'react.pages.brands_and_competitors.competitors_tooltip',
  BRANDS_AND_COMPETITORS_CATALOG_BRANDS_HEADER = 'react.pages.brands_and_competitors.brands_header',
  BRANDS_AND_COMPETITORS_CATALOG_COMPETITORS_HEADER = 'react.pages.brands_and_competitors.competitors_header',
  BRANDS_AND_COMPETITORS_CATALOG_CUSTOM_ASSIGN_TO_PRODUCT_HEADER = 'react.pages.brands_and_competitors.custom_assign_to_product_header',
  BRANDS_AND_COMPETITORS_CATALOG_NO_COMPETITORS_HEADER = 'react.pages.brands_and_competitors.no_competitors_header',
  BRANDS_AND_COMPETITORS_CATALOG_NO_COMPETITORS_DESCRIPTION = 'react.pages.brands_and_competitors.no_competitors_description',

  VIEWER_ONLY_MODE_TOOLTIP = 'react.pages.viewer_only_mode.tooltip',
  BRANDS_AND_COMPETITORS_CATALOG_ASSIGN_BACN = 'react.pages.brands_and_competitors.assign_bacn',
  BRANDS_AND_COMPETITORS_CATALOG_ASSIGN_TAGS_TO_PRODUCTS = 'react.pages.brands_and_competitors.assign_tags_to_products',
  BRANDS_AND_COMPETITORS_CATALOG_SWITCH_FROM_ASSIGN_ALL_TO_CUSTOM_HEADER = 'react.pages.brands_and_competitors.switch_from_assign_all_to_custom_header',
  BRANDS_AND_COMPETITORS_CATALOG_SWITCH_FROM_CUSTOM_TO_ASSIGN_ALL_HEADER = 'react.pages.brands_and_competitors.switch_from_custom_to_assign_all_header',
  BRANDS_AND_COMPETITORS_CATALOG_SWITCH_FROM_CUSTOM_TO_ASSIGN_ALL_DESCRIPTION = 'react.pages.brands_and_competitors.switch_from_custom_to_assign_all_description',
  BRANDS_AND_COMPETITORS_CATALOG_SWITCH_FROM_ASSIGN_ALL_TO_CUSTOM_DESCRIPTION = 'react.pages.brands_and_competitors.switch_from_assign_all_to_custom_description',
  BRANDS_AND_COMPETITORS_CATALOGM_ASSIGN_TAGS_TO_ALL_PRODUCTS = 'react.pages.brands_and_competitors.assign_tags_to_all_products',
  BRANDS_AND_COMPETITORS_CATALOG_DO_NOT_ASSIGN_TAGS_TO_ALL_PRODUCTS = 'react.pages.brands_and_competitors.do_not_assign_tags_to_all_products',
  BRANDS_AND_COMPETITORS_RECOMMENDATIONS_HUB_CARD_SUB_TEXT = 'react.pages.brands_and_competitors.recommendation_hub_card_sub_text',
  BRANDS_AND_COMPETITORS_RECOMMENDATIONS_HUB_CARD_SUB_TEXT_COMPLETED = 'react.pages.brands_and_competitors.recommendation_hub_card_sub_text_completed',
  BRANDS_AND_COMPETITORS_CHANGES_SAVED = 'react.pages.brands_and_competitors.changes_saved',
  BRANDS_AND_COMPETITORS_MAX_TAGS_ERROR_MESSAGE = 'react.pages.brands_and_competitors.max_tags_error_message',
  BRANDS_AND_COMPETITORS_ASSIGN_ALL_MESSAGE = 'react.pages.brands_and_competitors.assign_all_message',
  BRANDS_AND_COMPETITORS_OPTED_OUT_MESSAGE = 'react.pages.brands_and_competitors.opted_out_message',
  BRANDS_AND_COMPETITORS_GO_TO_BACN = 'react.pages.brands_and_competitors.go_to_bacn',
  BRANDS_AND_COMPETITORS_SETTINGS_BUTTON = 'react.pages.brands_and_competitors.settings_button',
  BRANDS_AND_COMPETITORS_PRODUCT_SETTINGS_CUSTOM_TEXT = 'react.pages.brands_and_competitors.product_settings_custom_text',
  BRANDS_AND_COMPETITORS_ALL_CHANGES_SAVED = 'react.pages.brands_and_competitors.all_changes_saved',
  BRANDS_AND_COMPETITORS_UPLOAD_MODAL_HEADER = 'react.pages.brands_and_competitors.upload_modal_header',
  BRANDS_AND_COMPETITORS_UPLOAD_MODAL_STEP_1_DESCRIPTION = 'react.pages.brands_and_competitors.upload_modal.step_1_description',
  BRANDS_AND_COMPETITORS_UPLOAD_MODAL_STEP_1_INSTRUCTIONS = 'react.pages.brands_and_competitors.upload_modal.step_1_instructions',
  BRANDS_AND_COMPETITORS_UPLOAD_MODAL_STEP_2_DESCRIPTION = 'react.pages.brands_and_competitors.upload_modal.step_2_description',
  BRANDS_AND_COMPETITORS_UPLOAD_MODAL_STEP_3_DESCRIPTION = 'react.pages.brands_and_competitors.upload_modal.step_3_description',

  CAMPAIGN_FLEXIBILITY_PRODUCTS_TABLE_CAMPAIGNS_HEADER_COLUMN_ADVERTISING_GOAL = 'react.campaignFlexibility.product.tables.header.advertising_goal',
  CAMPAIGN_FLEXIBILITY_PRODUCTS_TABLE_CAMPAIGNS_HEADER_COLUMN_CURRENT_INVENTORY = 'react.campaignFlexibility.product.tables.header.current_inventory',
  CAMPAIGN_FLEXIBILITY_PRODUCTS_TABLE_CAMPAIGNS_HEADER_COLUMN_SKUs = 'react.campaignFlexibility.product.tables.header.SKUs',
  CAMPAIGN_FLEXIBILITY_PRODUCTS_TABLE_CAMPAIGNS_HEADER_COLUMN_STATUS = 'react.campaignFlexibility.product.tables.header.Status',
  CAMPAIGN_FLEXIBILITY_PRODUCTS_TABLE_CAMPAIGNS_HEADER_COLUMN_TOTAL_SALES_L30D = 'react.campaignFlexibility.product.tables.header.total_sales_l30d',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_TARGET_KEYWORD = 'react.campaignFlexibility.preview.keywords.button',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_TARGET_NEGATIVE_KEYWORD = 'react.campaignFlexibility.preview.negative_keywords.button',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_TARGET_ASIN = 'react.campaignFlexibility.preview.ASIN_targeting.button',
  CAMPAIGN_FLEXIBILITY_PREVIEW_AND_LAUNCH_TARGET_EXCLUDE_PRODUCT = 'react.campaignFlexibility.preview.exclude_product.button',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_BULK_MODAL_HEADER_1 = 'react.pages.campaignFlexibility.model.bulk.header_1',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_BULK_MODAL_HEADER_2 = 'react.pages.campaignFlexibility.model.bulk.header_2',
  CAMPAIGN_FLEXIBILITY_BULK_EDIT_BULK_MODAL_HEADER_3 = 'react.pages.campaignFlexibility.model.bulk.header_3',
}

export const isI18nKey = (value: I18nKey | string): value is I18nKey =>
  includes(values(I18nKey), value);

export default I18nKey;
