import { tableSelectors } from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { CampaignDetails } from '../../../../../lib/types/AOSharedTypes';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';

export const getCurrentRowIndex =
  (campaignDetails: CampaignDetails) =>
  ({ tableState }: WithTable<CampaignDetails>) => {
    const visibleData = tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<CampaignDetails, void>()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
    );
    const index = (function () {
      for (let i = 0; i < visibleData.length; i++) {
        if (visibleData[i].campaignId === campaignDetails.campaignId) {
          return i;
        }
      }
      return -1;
    })();

    return index;
  };
