import { Factory } from 'fishery';

import { SALES_CHANNEL_IDS } from '../';
import { FlywheelSalesChannel } from '../../types/AOSharedTypes';
import { MerchantCountry, MerchantType } from '../../types/Fam';

interface MerchantCountryTransientParams {
  readonly unqiueIdentifier: string;
  readonly channelId: string;
}

const getMerchantCountryId = (unqiueIdentifier?: string) =>
  unqiueIdentifier
    ? `merchantCountryId ${unqiueIdentifier}`
    : `some-merchantCountryId`;

const getMerchantName = (
  unqiueIdentifier?: string,
  salesChannel?: FlywheelSalesChannel
) => {
  if (unqiueIdentifier) {
    return `merchantName ${unqiueIdentifier}`;
  } else {
    return salesChannel ? salesChannel : 'merchantName';
  }
};

const getSalesChannelId = (
  channelId: SALES_CHANNEL_IDS,
  unqiueIdentifier?: string
) => (unqiueIdentifier ? `salesChannelId ${unqiueIdentifier}` : channelId);

const getCountryCode = (unqiueIdentifier?: string) =>
  unqiueIdentifier ? `country ${unqiueIdentifier}` : 'US';

export default Factory.define<MerchantCountry, MerchantCountryTransientParams>(
  ({ transientParams }) => {
    const { unqiueIdentifier, channelId } = transientParams;
    if (channelId) {
      switch (channelId) {
        case SALES_CHANNEL_IDS.WALMART:
          return {
            merchantCountryId: getMerchantCountryId(unqiueIdentifier),
            merchantName: getMerchantName(unqiueIdentifier),
            salesChannelId: getSalesChannelId(
              SALES_CHANNEL_IDS.WALMART,
              unqiueIdentifier
            ),
            country: getCountryCode(unqiueIdentifier),
            merchantType: MerchantType.Seller,
          };
        case SALES_CHANNEL_IDS.AMAZON:
          return {
            merchantCountryId: getMerchantCountryId(unqiueIdentifier),
            merchantName: getMerchantName(unqiueIdentifier),
            salesChannelId: getSalesChannelId(
              SALES_CHANNEL_IDS.AMAZON,
              unqiueIdentifier
            ),
            country: getCountryCode(unqiueIdentifier),
            merchantType: MerchantType.Seller,
          };
      }
    }

    return {
      merchantCountryId: getMerchantCountryId(unqiueIdentifier),
      merchantName: getMerchantName(
        unqiueIdentifier,
        FlywheelSalesChannel.Walmart
      ),
      salesChannelId: getSalesChannelId(
        SALES_CHANNEL_IDS.WALMART,
        unqiueIdentifier
      ),
      country: getCountryCode(unqiueIdentifier),
      merchantType: MerchantType.Seller,
    };
  }
);
