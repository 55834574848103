import cn from 'classnames';
import groupBy from 'lodash/groupBy';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Alignment,
  ArrowLeftIcon as ArrowLeft,
  ArrowRightIcon as ArrowRight,
  Button,
  ButtonSize,
  ButtonVariant,
  ExitLinearIcon,
  Flywheel2LogoIcon as FlywheelLogo,
  Icon,
  IconSize,
  Loader,
  PaletteColor,
  Pill,
  Placement,
  PlusSmallLinearIcon,
  SearchInput,
  TextLink,
  TextLinkSize,
  Theme,
  Tooltip,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import { useNavigate } from 'react-router-dom';
import { MerchantCountriesContext } from './containers/merchantCountriesProvider/merchantCountriesProvider';
import { SubscriptionContext } from './containers/subscriptionProvider';
import {
  getCurrentAccount,
  getCurrentAccountParentAccount,
  isAgencyClientAccount,
  mapRoleToI18nKey,
} from './containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from './containers/userProvider/userProvider';
import { CreateNewClientAccount } from './CreateNewClientAccount';
import { Account, AccountPermission, Role } from './lib/types/Fam';
import I18nKey from './lib/types/I18nKey';
import { AsyncRequestNotStarted } from './lib/utilities/asyncRequest';
import { FWCookie, PERSISTED_KEYS } from './lib/utilities/fwCookie';
import { segmentIdentify, segmentReset } from './lib/utilities/segment';

const Gradient: React.FC = () => (
  <div className="h-4 rounded-t bg-gradient-to-r from-purple-500 via-magenta-500 to-orange-500" />
);
Gradient.displayName = 'Gradient';

type AccountSwitcherProps = {
  redirectUrl: string;
  dataTestId?: string;
  channel: BroadcastChannel;
};

export const AccountSwitcher: React.FC<AccountSwitcherProps> = (props) => {
  const userContext = useContext<UserContextState>(UserContext);
  const currentAccount = getCurrentAccount(userContext);
  const accountId = currentAccount?.id;
  const isAgencyClient = isAgencyClientAccount(currentAccount);
  const { clearMerchantCountries } = useContext(MerchantCountriesContext);
  const { clearSubscriptionContext } = useContext(SubscriptionContext);
  const userDetails = userContext.userInfo.userDetails!;
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [showClientModal, setShowClientModal] = useState<boolean>(false);

  const [parentAccount, setParentAccount] = useState<Account>();
  const [parentIds] = useState<string[]>(() => {
    const groups = groupBy(
      userDetails.accountPermissions,
      (a) => a.account.parentId
    );
    return Object.keys(groups);
  });
  const intl = useIntl();

  const [allAccountIds] = useState<string[]>(() => {
    return userDetails.accountPermissions.map((ap) => ap.account.id);
  });

  const [accounts, setAccounts] = useState<AccountPermission[]>(() =>
    userDetails.accountPermissions
      .filter(
        (ap) =>
          ap.account.parentId === null ||
          (ap.account.parentId && !allAccountIds.includes(ap.account.parentId))
      )
      .sort((a, b) =>
        a.account.companyName.localeCompare(b.account.companyName)
      )
  );

  useEffect(() => {
    setSearchValue('');
    getAccountList();
  }, [accountId, isAgencyClient, userDetails.accountPermissions.length]);

  const getAccountList = async () => {
    const group = groupBy(
      userDetails.accountPermissions,
      (a) => a.account.parentId
    );
    if (accountId && parentIds.includes(accountId)) {
      setAccounts(
        group[accountId].sort((a, b) =>
          a.account.companyName.localeCompare(b.account.companyName)
        )
      );
    } else if (
      accountId &&
      isAgencyClient &&
      currentAccount?.parentId &&
      allAccountIds.includes(currentAccount.parentId)
    ) {
      const parentAccountId = userDetails.accountPermissions.find(
        (ap) => ap.account.id === accountId
      )?.account.parentId;
      setParentAccount(getCurrentAccountParentAccount(userContext));
      setAccounts(
        group[parentAccountId!].sort((a, b) =>
          a.account.companyName.localeCompare(b.account.companyName)
        )
      );
    } else {
      setAccounts(
        userDetails.accountPermissions
          .filter(
            (ap) =>
              ap.account.parentId === null ||
              (ap.account.parentId &&
                !allAccountIds.includes(ap.account.parentId))
          )
          .sort((a, b) =>
            a.account.companyName.localeCompare(b.account.companyName)
          )
      );
    }
  };

  const [
    ACCOUNT_SWITCHER_SEARCH_PLACEHOLDER,
    ACCOUNT_SWITCHER_PAGE_LOG_OUT,
    CREATE_NEW_CLIENT_ACCOUNT,
  ] = [
    I18nKey.ACCOUNT_SWITCHER_SEARCH_PLACEHOLDER,
    I18nKey.ACCOUNT_SWITCHER_PAGE_LOG_OUT,
    I18nKey.CREATE_NEW_CLIENT_ACCOUNT,
  ].map((id) => intl.formatMessage({ id }));

  const accountsFiltered = useMemo(() => {
    if (!searchValue) {
      return accounts;
    }

    return accounts.filter((ap) =>
      ap.account.companyName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [accounts, searchValue]);

  const onLogoutClicked = () => {
    navigate('/logout');
  };

  const onAccountClicked = (id: string) => {
    // if agency account is clicked, we show child accounts in account list
    if (parentIds.includes(id)) {
      FWCookie.saveCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID, id);
      userContext.updateUserInfo({
        ...userContext.userInfo,
        currentAccountId: id,
      });
      // If new account selected is different than the current one, clear merchant countries
      if (id !== accountId) {
        clearSubscriptionContext && clearSubscriptionContext();
        clearMerchantCountries();
      }
    } else {
      // otherwise we set the current account to the clicked account
      segmentReset();
      userContext.updateUserInfo({
        ...userContext.userInfo,
        currentAccountId: id,
      });
      // Broadcast a message saying that the account has changed.
      props.channel.postMessage(id);
      if (userContext.userInfo.userDetails) {
        segmentIdentify(
          userContext.userInfo.userDetails.id,
          userContext.userInfo.userDetails.email,
          {
            fw2_last_login_date: DateTime.utc().toISO(),
          }
        );
      }

      // If new account selected is different than the current one, clear merchant countries
      if (id !== accountId) {
        clearMerchantCountries();
      }

      userContext.updateBillingInfo(AsyncRequestNotStarted());
      userContext.updateBillingEstimate(AsyncRequestNotStarted());
      FWCookie.saveCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID, id);
      navigate(props.redirectUrl);
    }
  };

  const clearCurrentAccount = () => {
    FWCookie.deleteCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID);
    userContext.updateUserInfo({
      ...userContext.userInfo,
      currentAccountId: undefined,
    });
    clearMerchantCountries();
  };

  const showAgencyUI =
    accountId &&
    (parentIds.includes(accountId) ||
      (currentAccount.parentId &&
        allAccountIds.includes(currentAccount.parentId)));

  const showBackButton =
    !showClientModal &&
    showAgencyUI &&
    userDetails.accountPermissions.length > accounts.length + 1;

  return (
    <div className="h-screen bg-grey-50 flex justify-center items-center">
      <div className="w-320 md:w-400 bg-white border border-solid border-grey-200 rounded">
        <Gradient />
        <>
          <div className="flex flex-row justify-between items-center px-24 py-16">
            {showBackButton && (
              <Button
                size={ButtonSize.Large}
                variant={ButtonVariant.BlackAndWhiteBorder}
                onClick={() => clearCurrentAccount()}
                svgIcon={ArrowLeft}
                className="text-grey-900"
              />
            )}
            <div className="flex flex-col w-max m-auto items-center justify-center pt-24">
              <FlywheelLogo />
              {showAgencyUI && (
                <>
                  <div className="flex flex-row w-full justify-center items-center gap-8">
                    <hr className="text-purple-200 h-1 flex-1" />
                    <Icon
                      size={IconSize.Small}
                      svg={PlusSmallLinearIcon}
                      className="text-purple-500"
                    />
                    <hr className="text-purple-200 h-1 flex-1" />
                  </div>
                  <Typography
                    size={TypographySize.base}
                    lineHeight={TypographyLineHeight.tight}
                    className="text-grey-900"
                    weight={TypographyWeight.medium}
                  >
                    {isAgencyClient
                      ? parentAccount?.companyName
                      : currentAccount?.companyName}
                  </Typography>
                </>
              )}
            </div>
          </div>
          <div
            className={cn('flex flex-col h-406 w-full overflow-hidden pt-16', {
              'relative ': showClientModal,
            })}
          >
            {!showClientModal && (
              <div
                className={cn(
                  'flex flex-col w-full h-full bg-white overscroll-none'
                )}
              >
                {!userContext.userInfo ? (
                  <div className="flex justify-center items-center w-full h-full">
                    <Loader.default />
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col gap-16 px-24 pb-24 h-full">
                      <div className="flex flex-row gap-12">
                        <SearchInput
                          value={searchValue}
                          onChange={setSearchValue}
                          onSearchInputClear={() => setSearchValue('')}
                          placeholder={ACCOUNT_SWITCHER_SEARCH_PLACEHOLDER}
                        />
                        {showAgencyUI && (
                          <Tooltip
                            content={CREATE_NEW_CLIENT_ACCOUNT}
                            theme={Theme.Dark}
                            position={{
                              placement: Placement.Top,
                              alignment: Alignment.Center,
                            }}
                          >
                            <Button
                              size={ButtonSize.Large}
                              variant={ButtonVariant.BlackAndWhiteBorder}
                              onClick={() => setShowClientModal(true)}
                              svgIcon={PlusSmallLinearIcon}
                              className="text-grey-900"
                            />
                          </Tooltip>
                        )}
                      </div>

                      <div className="border border-solid border-grey-200 rounded overflow-y-scroll h-268">
                        {accountsFiltered?.map((item, index) => (
                          <AccountRow
                            key={`account-${index}`}
                            accountName={item.account.companyName}
                            id={item.account.id}
                            currentAccount={
                              FWCookie.readCookie(
                                PERSISTED_KEYS.CURRENT_ACCOUNT_ID
                              ) === item.account.id
                            }
                            role={item.role}
                            onClick={(id) => onAccountClicked(id)}
                            dataTestId={props.dataTestId}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="flex justify-center w-full items-center py-12 border-t-1 border-solid border-grey-100">
                      <div className="flex flex-row items-center gap-8 justify-center">
                        <Icon
                          size={IconSize.Small}
                          svg={ExitLinearIcon}
                          className="text-grey-400 group-hover:text-purple-500"
                        />
                        <TextLink
                          size={TextLinkSize.Small}
                          textLabel={ACCOUNT_SWITCHER_PAGE_LOG_OUT}
                          onClick={onLogoutClicked}
                          dataTestId={'us_logout'}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {showClientModal && accountId && (
              <CreateNewClientAccount
                showClientModal={showClientModal}
                setShowClientModal={setShowClientModal}
                parentAccountId={
                  isAgencyClient && parentAccount
                    ? parentAccount!.id
                    : accountId
                }
              />
            )}
          </div>
        </>
      </div>
    </div>
  );
};
AccountSwitcher.displayName = 'AccountSwitcher';

interface AccountRowProps {
  accountName: string;
  id: string;
  role: Role;
  currentAccount?: boolean;
  onClick: (id: string) => void;
  dataTestId?: string;
}

const AccountRow: React.FC<AccountRowProps> = ({
  id = '',
  accountName = '',
  role,
  currentAccount,
  onClick,
  dataTestId,
}) => {
  return (
    <div
      onClick={() => onClick(id)}
      className={cn(
        'group flex justify-between items-center cursor-pointer',
        'h-64 px-16 py-13 self-stretch gap-16 bg-white',
        'leading-none text-left',
        'border-b border-solid border-grey-200',
        'hover:bg-purple-50'
      )}
    >
      <div className="flex flex-col">
        <div className="text-lg font-semibold group-hover:text-purple-700 truncate max-w-sm mr-4">
          <Typography
            size={TypographySize.lg}
            lineHeight={TypographyLineHeight.tight}
            weight={TypographyWeight.semibold}
            className="text-grey-900"
          >
            {accountName}
          </Typography>
        </div>
        <div className="flex flex-col flex-start gap-4 grow shrink-0 basis-0 group-hover:text-grey-700">
          <Typography
            size={TypographySize.sm}
            lineHeight={TypographyLineHeight.none}
            weight={TypographyWeight.regular}
            className="text-grey-500"
          >
            <FormattedMessage id={mapRoleToI18nKey(role)} />
          </Typography>
        </div>
      </div>
      {currentAccount ? (
        <Pill
          color={PaletteColor.green}
          text={<FormattedMessage id={I18nKey.ACCOUNT_SWITCHER_PAGE_PILL} />}
          dataTestId={`${dataTestId}_accountSwitcher`}
        />
      ) : (
        <Icon
          size={IconSize.Small}
          svg={ArrowRight}
          className="text-grey-400 group-hover:text-purple-500"
        />
      )}
    </div>
  );
};
AccountRow.displayName = 'AccountRow';
