import {
  ColumnAlign,
  TableCellV2,
  TableCellV2Type,
} from '@teikametrics/tm-design-system';

import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import { SalesChannelData } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { RowCellElementProps, SalesChannelTableData } from '../types';

export const merchantIdColumn: FlywheelTableV2ColumnProps<
  SalesChannelData,
  {},
  SalesChannelTableData,
  {}
> = {
  header: I18nKey.ACCOUNT_SALES_CHANNELS_TABLE_HEADER_COLUMN_SELLER_ID,
  cell: ({ row: { original } }) => <MerchantIdCellElement {...original} />,
  accessorKey: 'ext_merchant_id',
  sortable: true,
  align: ColumnAlign.Left,
};

export const MerchantIdCellElement: React.FC<RowCellElementProps> = ({
  extMerchantId,
}) => {
  return (
    <TableCellV2
      tableCellData={{
        currentValue: extMerchantId || '',
        tableCellType: TableCellV2Type.LongText,
      }}
      className="p-12"
    />
  );
};
