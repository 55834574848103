import { fromNullable } from 'fp-ts/lib/Option';
import produce, { Draft } from 'immer';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import times from 'lodash/times';

import {
  ADDITIONAL_AGGREGATION_DATA_LOAD_COMPLETE,
  ADDITIONAL_AGGREGATION_DATA_LOAD_FAILED,
  ADDITIONAL_AGGREGATION_DATA_LOAD_INITIATED,
  AGGREGATION_DATA_LOAD_COMPLETE,
  AGGREGATION_DATA_LOAD_FAILED,
  AGGREGATION_DATA_LOAD_INITIATED,
  APPLY_BULK_CHANGES,
  CLEAR_ALL_CHANGES,
  CLEAR_BUFFER,
  CLEAR_FILTER,
  CLEAR_FILTERS,
  CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS,
  DATA_LOAD_BUFFER_COMPLETE,
  DATA_LOAD_COMPLETE,
  DATA_LOAD_FAILED,
  DATA_LOAD_INITIATED,
  DATA_RESET,
  DATA_RESET_AGGREGATION,
  DELETE_ITEMS,
  PATCH_SINGLE_ITEM,
  SET_ITEMS_PER_PAGE,
  SET_VISIBLE_PAGE,
  SORT_APPLIED,
  SORT_REMOVED,
  TOTAL_COUNT,
  TOTAL_COUNT_FAILURE,
  TOTAL_COUNT_SUCCESS,
  TableAction,
  TableDataAction,
  UPDATE_CELL,
  UPDATE_EXTRA_PAGINATION_PARAMS,
  UPDATE_EXTRA_QUERY_PARAMS,
  UPDATE_FILTERS,
  UPDATE_TABLE_CELL,
  clearSingleFilterOfMultiSelectionFilters,
  updateCell,
  updateFilters,
  updateTableCell,
} from './actions';
import { DEFAULT_TABLE_CHANGES, DEFAULT_TABLE_STATE } from './index';
import {
  FullCountLoadingState,
  INITIAL_LOAD_KEY,
  Table,
  TableCell,
  TableCellChange,
  TableReducerState,
} from './types';
import { WritableDraft } from 'immer/dist/internal';
import {
  AsyncRequestKinds,
  IdentifiedAsyncRequest,
  asyncRequestIdentifiedBy,
  asyncRequestIsLoading,
} from '../../../lib/utilities/asyncRequest';
import { Filter, FilterOps } from '../../../lib/types/Filter';
import { isNotUndefined } from '../../../lib/utilities/typeGuards';

const initialState = {};

function fillEmptyPages<T>(
  pages: Array<IdentifiedAsyncRequest<T[], void>>,
  asyncRequestToFillWith: IdentifiedAsyncRequest<T[], void>,
  indexToFillTo: number
): Array<IdentifiedAsyncRequest<T[], void>> {
  const pagesCopy = [...pages];
  const pagesToFill = times<number>(indexToFillTo + 1, identity);
  pagesToFill.forEach((pageNumber) => {
    pagesCopy[pageNumber] = pagesCopy[pageNumber] || asyncRequestToFillWith;
  });
  return pagesCopy;
}

function tableDataReducer<T, F>(
  state: TableReducerState<T, F>,
  action: TableDataAction
): TableReducerState<T, F> {
  const identifiedAsyncRequest = asyncRequestIdentifiedBy<
    Array<Draft<T>>,
    void
  >(action.payload.requestId);

  return produce<TableReducerState<T, F>>(state, (tableState) => {
    const tableId = action.payload.tableId;
    const pageIdx = action.payload.page - 1;
    const maybeCurrentPageRequest = fromNullable(tableState[tableId]).chain(
      (table) => fromNullable(table.pages[pageIdx])
    );
    // we should only process a completion or failure if the current page request
    // is loading and the current page request's id matches the id of the new
    // requests result
    const shouldProcessRequestResult = maybeCurrentPageRequest.exists(
      (r) => asyncRequestIsLoading(r) && r.id === action.payload.requestId
    );

    tableState[tableId] = tableState[tableId] || {
      ...DEFAULT_TABLE_STATE,
    };
    tableState[tableId].pages = fillEmptyPages(
      tableState[tableId].pages,
      identifiedAsyncRequest.AsyncRequestNotStarted(),
      pageIdx
    );

    switch (action.type) {
      case DATA_LOAD_INITIATED:
        tableState[tableId].pages[pageIdx] =
          identifiedAsyncRequest.AsyncRequestLoading();
        break;
      case DATA_LOAD_COMPLETE:
        if (shouldProcessRequestResult) {
          tableState[tableId].maybeTotalItems = action.payload.totalItems;
          tableState[tableId].pages[pageIdx] =
            identifiedAsyncRequest.AsyncRequestCompleted(
              action.payload.items as Array<Draft<T>>
            );
          tableState[tableId].lastSyncedAt = action.payload.lastSyncedAt;
        }
        break;
      case DATA_LOAD_FAILED:
        if (shouldProcessRequestResult) {
          tableState[tableId].pages[pageIdx] =
            identifiedAsyncRequest.AsyncRequestFailed(undefined);
        }
        break;
    }

    return tableState;
  });
}

const filterFieldDoesNotMatch =
  (fields: string[]) =>
  (filter: Filter): boolean =>
    !fields.some((field) => field === filter.field);

function getFilters<T, F>(
  table: Table<T, F>,
  action: ReturnType<typeof updateFilters>
): Filter[] {
  const newFilterFields = action.payload.filters.map(
    (filter: { field: any }) => filter.field
  );
  const filterDoesNotMatchFields = filterFieldDoesNotMatch(newFilterFields);

  if (action.payload.replace) {
    return [...action.payload.filters];
  }

  return [
    ...table.filters.filter(filterDoesNotMatchFields),
    ...action.payload.filters,
  ];
}

function getUpdateTable<T, F>(
  tableId: string,
  existingTables: TableReducerState<T, F>,
  tableMerge: (table: Table<T, F>) => Table<T, F>
): Table<T, F> {
  const maybeTable = fromNullable(existingTables[tableId]);
  return maybeTable.fold(tableMerge(DEFAULT_TABLE_STATE), tableMerge);
}

function updateTableCellChanges(cellChanges: TableCellChange) {
  const newCellChanges: Record<string, Record<string, TableCell>> = {};

  keys(cellChanges).forEach((rowId: string) => {
    const rowChanges: Record<string, TableCell> = {};

    keys(cellChanges[rowId]).forEach((columnName: string) => {
      const changedValue = cellChanges[rowId][columnName];
      if (changedValue.isDirty) {
        rowChanges[columnName] = changedValue;
      } else {
        delete rowChanges[columnName];
      }
    });

    if (keys(rowChanges).length) {
      newCellChanges[rowId] = rowChanges;
    }
  });

  return newCellChanges;
}

function clearSingleFilterOfMultiSelectionFiltersReducer<T, F>(
  state: TableReducerState<T, F>,
  action: ReturnType<typeof clearSingleFilterOfMultiSelectionFilters>
) {
  return {
    ...state,
    [action.payload.tableId]: getUpdateTable(
      action.payload.tableId,
      state,
      (table: Table<T, F>) => ({
        ...table,
        filters: table.filters
          .map((filter) => {
            if (
              filter.field === action.payload.field &&
              (filter.op === FilterOps.in || filter.op === FilterOps.notIn)
            ) {
              filter = {
                op: filter.op,
                field: filter.field,
                value: filter.value.filter(
                  (value) => value !== action.payload.value
                ),
              };
            }
            return filter;
          })
          .filter(
            (filter) =>
              ((filter.op === FilterOps.in || filter.op === FilterOps.notIn) &&
                filter.value.length > 0) ||
              (filter.op !== FilterOps.in &&
                filter.op !== FilterOps.notIn &&
                filter.field !== action.payload.field)
          ),
      })
    ),
  };
}

function updateCellReducer<T, F>(
  state: TableReducerState<T, F>,
  action: ReturnType<typeof updateCell>
) {
  return produce<TableReducerState<T, F>>(state, (tableState) => {
    const { tableId, rowId, columnName, value, isValid, isDirty } =
      action.payload;

    const table = tableState[tableId];

    if (!table.changes.cell[rowId]) {
      table.changes.cell[rowId] = {};
    }

    if (!isDirty) {
      if (
        table.changes.cell[rowId] &&
        table.changes.cell[rowId][columnName] !== undefined
      ) {
        delete table.changes.cell[rowId][columnName];
      }
    } else {
      table.changes.cell[rowId][columnName] = { value, isValid, isDirty };
    }
    if (isEmpty(table.changes.cell[rowId])) {
      delete table.changes.cell[rowId];
    }
    return tableState;
  });
}

function updateTableCellReducer<T, F>(
  state: TableReducerState<T, F>,
  action: ReturnType<typeof updateTableCell>
) {
  return produce<TableReducerState<T, F>>(state, (tableState) => {
    const { tableId, rowId, columnName, value } = action.payload;

    const table = tableState[tableId];

    const currentPage = table.pages[table.visiblePage - 1];

    if (currentPage && currentPage.kind === AsyncRequestKinds.Completed) {
      currentPage.result = currentPage.result.map((row: any) =>
        row.id === rowId ? { ...row, [columnName]: value } : row
      );
    }

    return tableState;
  });
}

function deleteRecords<T, F>(
  tableState: WritableDraft<TableReducerState<T, F>>,
  tableId: string,
  itemIdentifier: (item: unknown) => boolean
) {
  const table = tableState[tableId];
  /*Keeps track of the pages to be removed
  Why:->
  When a user loads say for eg: 5 pages.
  The buffer will contain the elements of 6th page.
  Now the user comes back to 3rd page and delete's an item.
  Now we will have to fetch the item from the buffer page which is now moved to 4th page.
  [NOTE: Buffer is updated dynamically when moving between pages]
  And hence we will have to refresh the actual 4th & 5th page to get the new results.
  So clearing those forward left over pages.*/
  let pagesToClear: number = 0;
  table.pages.forEach((page, i) => {
    const toBeRemovedIndices: number[] = [];
    if (
      page.kind === AsyncRequestKinds.Completed &&
      i + 1 === table.visiblePage
    ) {
      page.result.forEach((item, j) => {
        if (itemIdentifier(item)) {
          toBeRemovedIndices.push(j);
        }
      });
      // Sort the indexes in descending order to avoid issues when removing
      toBeRemovedIndices.sort((a, b) => b - a);

      toBeRemovedIndices.forEach((index) => {
        page.result.splice(index, 1);
      });
      // Also add in the same number of indices removed from the buffer
      if (
        toBeRemovedIndices.length > 0 &&
        table.bufferItems &&
        table.maybeTotalItems
      ) {
        page.result.push(
          ...table.bufferItems.splice(0, toBeRemovedIndices.length)
        );
        table.toggleBufferItemsChanged = !table.toggleBufferItemsChanged;
        // Update the maybeTotalItems as we have accepted or rejected a recommendation
        table.maybeTotalItems -= toBeRemovedIndices.length;
        pagesToClear = table.pages.length - table.visiblePage;
      }
      return {
        ...page,
        result: page.result,
      };
    }
  });
  if (pagesToClear) {
    // Remove the number of pages from the right
    // So that next time its loaded using the API
    table.pages = table.pages.slice(0, -pagesToClear);
  }
  return tableState;
}

export function reducer<T, F>(
  state: TableReducerState<T, F> = initialState,
  action: TableAction
): TableReducerState<T, F> {
  switch (action.type) {
    case SET_VISIBLE_PAGE:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const tableId = action.payload.tableId;
        const table = tableState[tableId];

        if (isNotUndefined(table)) {
          table.visiblePage = action.payload.page;
          table?.bufferItemsToLoad &&
            (table.toggleBufferItemsChanged = !table.toggleBufferItemsChanged);
        }

        return tableState;
      });
    case SORT_APPLIED:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            sorts: [action.payload.sort],
          })
        ),
      };
    case SORT_REMOVED:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            sorts: table.sorts.filter(
              (sort) => sort.column !== action.payload.column
            ),
          })
        ),
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            filters: getFilters(table, action),
          })
        ),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            filters: table.filters.filter(
              (filter) => filter.field !== action.payload.field
            ),
          })
        ),
      };
    case CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS:
      return clearSingleFilterOfMultiSelectionFiltersReducer<T, F>(
        state,
        action
      );
    case CLEAR_FILTERS:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            filters: [],
          })
        ),
      };
    case UPDATE_EXTRA_PAGINATION_PARAMS:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            extraPaginationParams: action.payload.extraPaginationParams,
          })
        ),
      };
    case DATA_LOAD_INITIATED:
    case DATA_LOAD_COMPLETE:
    case DATA_LOAD_FAILED:
      return tableDataReducer(state, action);
    case DATA_LOAD_BUFFER_COMPLETE:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, items } = action.payload;
        const table = tableState[tableId];
        table.bufferItems = items as Array<Draft<T>>;
        return tableState;
      });
    case DATA_RESET:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const tableId = action.payload.tableId;

        if (isNotUndefined(tableState[tableId])) {
          tableState[tableId].pages = [];
        }

        return tableState;
      });
    case DATA_RESET_AGGREGATION: {
      const identifiedAsyncRequest = asyncRequestIdentifiedBy<any, void>(
        INITIAL_LOAD_KEY
      );

      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            aggregationData: identifiedAsyncRequest.AsyncRequestNotStarted(),
            additionalAggregationData:
              identifiedAsyncRequest.AsyncRequestNotStarted(),
          })
        ),
      };
    }
    case AGGREGATION_DATA_LOAD_INITIATED: {
      const identifiedAsyncRequest = asyncRequestIdentifiedBy<any, void>(
        action.payload.requestId
      );

      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            aggregationData: identifiedAsyncRequest.AsyncRequestLoading(),
          })
        ),
      };
    }
    case AGGREGATION_DATA_LOAD_COMPLETE: {
      const identifiedAsyncRequest = asyncRequestIdentifiedBy<any, void>(
        action.payload.requestId
      );
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            aggregationData: identifiedAsyncRequest.AsyncRequestCompleted(
              action.payload.aggregationData
            ),
          })
        ),
      };
    }
    case AGGREGATION_DATA_LOAD_FAILED: {
      const identifiedAsyncRequest = asyncRequestIdentifiedBy<any, void>(
        action.payload.requestId
      );

      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            aggregationData:
              identifiedAsyncRequest.AsyncRequestFailed(undefined),
          })
        ),
      };
    }

    case ADDITIONAL_AGGREGATION_DATA_LOAD_INITIATED: {
      const identifiedAsyncRequest = asyncRequestIdentifiedBy<any, void>(
        action.payload.requestId
      );
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            additionalAggregationData:
              identifiedAsyncRequest.AsyncRequestLoading(),
          })
        ),
      };
    }
    case ADDITIONAL_AGGREGATION_DATA_LOAD_COMPLETE: {
      const identifiedAsyncRequest = asyncRequestIdentifiedBy<any, void>(
        action.payload.requestId
      );
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            additionalAggregationData:
              identifiedAsyncRequest.AsyncRequestCompleted(
                action.payload.additionalAggregationData
              ),
          })
        ),
      };
    }
    case ADDITIONAL_AGGREGATION_DATA_LOAD_FAILED: {
      const identifiedAsyncRequest = asyncRequestIdentifiedBy<any, void>(
        action.payload.requestId
      );
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            additionalAggregationData:
              identifiedAsyncRequest.AsyncRequestFailed(undefined),
          })
        ),
      };
    }
    case UPDATE_EXTRA_QUERY_PARAMS:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            extraQueryParams: action.payload.extraQueryParams,
          })
        ),
      };
    case SET_ITEMS_PER_PAGE:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            itemsPerPage: action.payload.itemsPerPage,
          })
        ),
      };
    case UPDATE_CELL:
      return updateCellReducer<T, F>(state, action);
    case UPDATE_TABLE_CELL:
      return updateTableCellReducer<T, F>(state, action);
    case CLEAR_ALL_CHANGES:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId } = action.payload;
        const table = tableState[tableId];
        table.changes = DEFAULT_TABLE_CHANGES;
        return tableState;
      });

    case APPLY_BULK_CHANGES:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, bulkChanges } = action.payload;
        const table = tableState[tableId];
        table.changes.cell = updateTableCellChanges(bulkChanges);
        return tableState;
      });
    case TOTAL_COUNT:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId } = action.payload;
        const table = tableState[tableId];
        table.totalCount = {
          loadingTotalCount: true,
          totalCount: 0,
          loadingState: FullCountLoadingState.InProgress,
        };
        table.visiblePage = 1;
        return tableState;
      });
    case TOTAL_COUNT_SUCCESS:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, totalCount } = action.payload;
        const table = tableState[tableId];
        table.totalCount = {
          totalCount,
          loadingTotalCount: false,
          loadingState: FullCountLoadingState.Completed,
        };
        table.visiblePage = 1;
        return tableState;
      });
    case TOTAL_COUNT_FAILURE:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId } = action.payload;
        const table = tableState[tableId];
        table.totalCount = {
          totalCount: 0,
          loadingTotalCount: false,
          loadingState: FullCountLoadingState.Failed,
        };
        return tableState;
      });
    case PATCH_SINGLE_ITEM:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, itemIdentifier, changes } = action.payload;
        const table = tableState[tableId];
        table.pages.forEach(
          (page) =>
            page.kind === AsyncRequestKinds.Completed &&
            page.result.forEach((item, j) => {
              if (itemIdentifier(item)) {
                page.result[j] = {
                  ...item,
                  ...changes,
                };
              }
            })
        );
        return tableState;
      });
    case DELETE_ITEMS:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, itemIdentifier } = action.payload;
        deleteRecords(tableState, tableId, itemIdentifier);
        return tableState;
      });
    case CLEAR_BUFFER:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId } = action.payload;
        const table = tableState[tableId];
        table.bufferItems = [];
        return tableState;
      });
    default:
      return state;
  }
}
