import { ButtonProps } from '@teikametrics/tm-design-system';
import { AOApiClient } from '../../lib/clients/AOApiClient';
import { MerchantCountry } from '../../lib/types/Fam';
import I18nKey from '../../lib/types/I18nKey';
import {
  KeywordAdgroupInfo,
  TargetSegment,
} from '../../lib/types/KeywordAction';
import { SelectedTrendsProduct } from '../../modules/products/containers/skuCatalog';
import { AdType } from '../../lib/types/AOSharedTypes';

export interface ViewSkuAndKeywordSlideoutModalProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly adGroupId: string;
  readonly suggestedAdgroup?: KeywordAdgroupInfo;
  readonly merchantCountry: MerchantCountry;
  readonly textLinkContent?: string;
  readonly primaryButtonProps?: ButtonProps;
  readonly secondaryButtonProps?: ButtonProps;
  readonly onTextLinkClick?: () => void;
  readonly showTextLink?: boolean;
  readonly selectedSalesChannelName?: string;
  readonly adTypeDropDownValue?: AdType;
  readonly onSKUTrendsLinkClick?: (product: SelectedTrendsProduct) => void;
}

export interface SlideOverCommonProps {
  readonly aoApiClient: AOApiClient;
  readonly accountId: string;
  readonly merchantCountryId: string;
  readonly adGroupId: string;
  readonly salesChannelId: string;
  readonly merchantType: string;
}

export interface SkuTabProps extends SlideOverCommonProps {
  readonly merchantCountryCode?: string;
  readonly skuData?: ProductsData[];
  readonly loadingSkus: boolean;
  readonly onSKUTrendsLinkClick?: (product: SelectedTrendsProduct) => void;
}
export interface KeywordsTabProps extends SlideOverCommonProps {
  readonly keywordsData: TargetsData[];
  readonly loadingKeywords: boolean;
}

export enum SlideOutTab {
  Sku = 0,
  Keyword = 1,
}

export interface KeywordsPieChartDataProps {
  readonly type: TargetSegment;
  readonly count: number;
}

export const MAP_TARGET_SEGMENT_TO_PIE_CHART_COLOR: Record<
  TargetSegment,
  number
> = {
  [TargetSegment.Brand]: 600,
  [TargetSegment.Competitor]: 500,
  [TargetSegment.Generic]: 400,
  [TargetSegment.Unknown]: 300,
};

export const MAP_TARGET_SEGMENT_TO_RAW_PIE_CHART_COLOR: Record<
  TargetSegment,
  string
> = {
  [TargetSegment.Brand]: '#260c8e',
  [TargetSegment.Competitor]: '#2d10bc',
  [TargetSegment.Generic]: '#4b25ea',
  [TargetSegment.Unknown]: '#9892f4',
};

export const MAP_TARGET_SEGMENT_TO_I18NKEYS: Record<string, string> = {
  [TargetSegment.Brand]: I18nKey.GENERIC_TARGET_SEGMENT_BRAND,
  [TargetSegment.Competitor]: I18nKey.GENERIC_TARGET_SEGMENT_COMPTETITOR,
  [TargetSegment.Generic]: I18nKey.GENERIC_TARGET_SEGMENT_GENERIC,
  [TargetSegment.Unknown]: I18nKey.GENERIC_TARGET_SEGMENT_NO_TAG,
};

export interface TargetsData {
  targetId: string;
  searchValue: string;
  tagType: string;
  matchType: string;
  entityType: string;
  status: string;
  keywordType: string;
  totalConversions: number;
  totalClicks: number;
  createdBy: string;
}

export interface KeywordDataResponse {
  readonly merchantCountryId: string;
  readonly targets: TargetsData[];
}

export interface ProductsData {
  name: string;
  sku: string;
  productCatalogId: string;
  extItemId: string;
  imageUrl: string;
  status: string;
}
export interface SKUDataResponse {
  readonly merchantCountryId: string;
  readonly skus: ProductsData[];
}
