import reducer from './reducers';
import { TableChange, VisiblePage, VisiblePageKind } from './types';

export { default as tableActions } from './actions';
export { default as tableSelectors } from './selectors';
export { default as tableThunks } from './thunks';

export const DEFAULT_ITEMS_PER_PAGE = 200;

export const DEFAULT_VISIBLE_PAGE: VisiblePage = {
  kind: VisiblePageKind.AllPages,
};

export const DEFAULT_TABLE_CHANGES: TableChange = {
  select: {
    all: false,
    rows: [],
  },
  global: {},
  cell: {},
};

export const DEFAULT_TABLE_STATE = {
  ...Object.freeze({
    sorts: [],
    filters: [],
    pages: [],
    visiblePage: DEFAULT_VISIBLE_PAGE,
  }),
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
  changes: DEFAULT_TABLE_CHANGES,
};

export default reducer;
