import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  SvgIcon,
} from '@teikametrics/tm-design-system';
import React from 'react';

interface HeroProps {
  icon: SvgIcon;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  buttonProps?: {
    label: React.ReactNode;
    icon: SvgIcon;
    onClick: () => void;
  };
}

export const Hero: React.FC<HeroProps> = ({
  icon,
  title,
  subtitle,
  buttonProps,
}) => {
  return (
    <div className="flex flex-col items-center pt-104 pb-120 text-black text-center">
      <div
        className={`flex items-center justify-center w-40 h-40
          bg-flywheel-orange-magenta-purple-gradient rounded-full`}
      >
        <Icon svg={icon} size={IconSize.Large} className="text-white" />
      </div>
      <div className="pt-32 text-xl leading-tight font-semibold xl:text-2xl">
        {title}
      </div>
      <div className="pt-8 text-base leading-normal font-normal xl:text-lg">
        {subtitle}
      </div>
      {buttonProps && (
        <div className="pt-32">
          <Button
            svgIcon={buttonProps.icon}
            label={buttonProps.label}
            size={ButtonSize.Large}
            variant={ButtonVariant.Primary}
            onClick={buttonProps.onClick}
          />
        </div>
      )}
    </div>
  );
};

Hero.displayName = 'Hero';
