import {
  Alignment,
  BulkEditConfirmationModal,
  BulkEditSlideOut,
  BulkEditValues,
  ButtonSize,
  ButtonState,
  ColumnManager,
  ColumnManagerOption,
  ColumnManagerVariant,
  DualModes,
  FiltersMenu,
  Placement,
  SearchInputSize,
  TableActionRow,
  Theme,
  Tooltip,
  TooltipSize,
  Variant,
} from '@teikametrics/tm-design-system';
import { fromNullable } from 'fp-ts/lib/Option';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';
import {
  SaveChangesFlagContext,
  SaveChangesFlagContextState,
} from '../../../../containers/saveChangesFlagProvider';
import UpdatedFlywheelTable, {
  FlywheelTableColumnGroup,
} from '../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
  tableThunks,
} from '../../../../containers/table/ducks';
import {
  TableChange,
  WithTable,
} from '../../../../containers/table/ducks/types';
import { FlywheelSearchInput } from '../../../../containers/tableV2/flywheelSearchInput';
import { getUserRoleMatch } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { PaginatedDataFetcher } from '../../../../lib/clients/types';
import { useTime } from '../../../../lib/hooks/useTime';
import {
  AdLevel,
  AdType,
  FlywheelSalesChannel,
  MerchantCountryCode,
  ProductAdDetails,
  ProductAdsDataRequest,
  REQUEST_DATE_FORMAT,
  SVAdsEntity,
} from '../../../../lib/types/AOSharedTypes';
import {
  ConnectionStatus,
  MerchantType,
  Role,
} from '../../../../lib/types/Fam';
import {
  COLUMNS_WITH_SINGLE_DECIMAL_FILTER,
  EqualToFilter,
  Filter,
} from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import { OptimizelyFlags } from '../../../../lib/types/OptimizelyFlags';
import {
  CurrencyCode,
  getCurrencySymbolFromMerchantCountryCode,
} from '../../../../lib/utilities/currency';
import { showHideIntercomLauncher } from '../../../../lib/utilities/intercom';
import { SelectedTrendsProduct } from '../../../products/containers/skuCatalog';
import { PerformanceSlideover } from '../../../products/containers/skuCatalog/performanceSlideover';
import { PerformanceSlideoverContextProvider } from '../../../products/containers/skuCatalog/performanceSlideover/performanceSlideoverContextProvider';
import { MerchantContext, MerchantContextState } from '../../merchantsProvider';
import { areNoChangesDone } from '../../utils';
import {
  getActionRowButtons,
  getBulkDataForSVAdStatus,
  getCheckBoxState,
  getEditControls,
  getFilteredBulkEditOptions,
  getSelectedBulkMenuValues,
} from './bulkEditModalUtils';
import { DATA_INSPECTOR_COLUMN_NAME } from './dataInspectorConfig';
import { ADS_MANAGER_SV_ADS_TABLE_ID } from './ducks/types';
import { ErrorTable } from './errorTable';
import ExportAdLevelData from './exportAdLevelData';
import { KeepEditingModal } from './keepEditingModal';
import { NoDataFoundTable } from './noDataFoundTable';
import { NoFilteredDataFoundTable } from './noFilteredDataFound';
import { getColumns } from './svAdsColumns';
import {
  ApiColumnsWithAdLevel,
  BulkEditModalValues,
  BulkEditSelectedOptions,
  CURRENCY_CODE,
  ConfirmationModalClickKind,
  EXT_ADGROUP_ID,
  EXT_CAMPAIGN_ID,
  EXT_CREATIVE_ID,
  REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES,
  SvAdApiColumnGroupIdentifier,
  TableCellChangeSvAd,
  TableDataAdsManager,
} from './types';
import {
  ALL_AD_LEVELS_FILTER_FILED_MAPPER,
  EDIT_AD_ITEMS_API_COLUMN_DATA_MAPPING,
  SV_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  TABLE_UNIQ_KEY,
  getSearchInputPlaceholder,
  isAdItemStatusArchived,
  shouldShowSmartCampaigns,
} from './utils';
import { getFilterColumnDefinitons } from './utils/filterDefinitions';
import { SV_ADS_API_COLUMN_NAME } from './utils/svAdsTypes';

export interface TableSVAdsProps {
  readonly apiColumnsWithAdLevel: ApiColumnsWithAdLevel;
  readonly adType: AdType;
  readonly tableMode: DualModes;
  readonly toggleTableMode: () => void;
  readonly aoApiClient: AOApiClient;
  readonly merchantCountry: MerchantCountryCode;
  readonly salesChannel: FlywheelSalesChannel;
  readonly selectedAdType: AdType;
  readonly selectedMerchantCountries: Array<number | string> | string;
  readonly adLevelDropDownSelectedValues?: AdLevel;
  readonly onFilterUpdate?: (filters: Filter[]) => void;
  readonly storeFiltersInBrowserStorage?: (filters: Filter[]) => void;
  readonly currencyCode?: CurrencyCode;
  readonly filtersFromBrowserStorage?: Filter[];
  readonly exportButtonState: ButtonState;
  readonly setExportButtonState: React.Dispatch<
    React.SetStateAction<ButtonState>
  >;
  readonly selectedSalesChannelName: string;
  readonly request: () => ProductAdsDataRequest;
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly campaignDetailFilters?: EqualToFilter[];
  readonly selectedColumns?: string[];
  readonly onColumnSelectionChange?: (selectedColumns: string[]) => void;
  readonly columnManagerOptions?: ColumnManagerOption[];
  readonly dataFetcher: PaginatedDataFetcher<ProductAdDetails>;
  readonly merchantType?: MerchantType;
  readonly productConnectionStatus?: ConnectionStatus;
  readonly aiEnabled: boolean;
  readonly merchantsWithAutomationEnabled: string[];
  readonly isSaveChangesLoading: boolean;
  readonly onEditSave: () => void;
  readonly switchLevelController: (adLevel: string) => void;
  readonly selectedSalesChannel: FlywheelSalesChannel;
  readonly activeSliderIndex: number;
}

const handleLeaveWithoutSaveClick = (
  toggleTableMode: () => void,
  setClickedOnView: (value: boolean) => void,
  switchLevelController: (adLevel: string) => void,
  saveChangesFlagContext: SaveChangesFlagContextState,
  navigate: NavigateFunction,
  adLevel?: string,
  clickedOnView?: boolean
) => {
  if (clickedOnView) {
    toggleTableMode();
    setClickedOnView(false);
  } else if (saveChangesFlagContext.saveChangesData.aoAdLevel) {
    switchLevelController(adLevel ?? '');
  } else {
    navigate(saveChangesFlagContext.saveChangesData.navPath);
  }
};

const discardCurrentChanges = (
  actionType: Variant,
  updatedCells: TableCellChangeSvAd,
  discardChanges: () => void,
  setActionType: () => void
) => {
  if (actionType === Variant.Discard && areNoChangesDone(updatedCells)) {
    discardChanges();
    return;
  }
  setActionType();
};

const getBulkEditModalConfirmation = (
  confirmationModalVariant: Variant | null,
  confirmationModalClick: (action: ConfirmationModalClickKind) => void
) =>
  confirmationModalVariant && (
    <BulkEditConfirmationModal
      showModal={!!confirmationModalVariant}
      variant={confirmationModalVariant}
      onPrimaryButtonClick={() =>
        confirmationModalClick(ConfirmationModalClickKind.APPLY)
      }
      onSecondryButtonClick={() =>
        confirmationModalClick(ConfirmationModalClickKind.CANCEL)
      }
    />
  );

const showEditButton = (tableMode: DualModes, selectedRowIds: string[]) =>
  tableMode === DualModes.Edit && selectedRowIds.length > 0;

const getSaveButtonState = (changedCount: number, validRowsCount: number) => {
  if (changedCount === 0) {
    return ButtonState.Disabled;
  }

  return validRowsCount === changedCount
    ? ButtonState.Enabled
    : ButtonState.Disabled;
};

const isAdItemIdEligible = ({
  channelSettings: { status },
}: ProductAdDetails) => !isAdItemStatusArchived(status);

const upgradeFiltersInStorage = (
  filters: Filter[],
  storeFiltersInBrowserStorage?: (filters: Filter[]) => void
) => {
  fromNullable(storeFiltersInBrowserStorage)
    .map((fn) => fn(filters))
    .toUndefined();
};

const onKeepEditingClick = (
  saveChangesFlagContext: SaveChangesFlagContextState,
  setClickedOnView: React.Dispatch<React.SetStateAction<boolean>>
) => {
  saveChangesFlagContext.updateSaveChangesData({
    ...saveChangesFlagContext.saveChangesData,
    saveChangesModalIsOpen: false,
    aoAdLevel: undefined,
  });
  setClickedOnView(false);
};

export const TableSVAds: React.FC<TableSVAdsProps> = ({
  apiColumnsWithAdLevel,
  adType,
  aoApiClient,
  merchantCountry,
  salesChannel,
  selectedAdType,
  selectedMerchantCountries,
  adLevelDropDownSelectedValues,
  onFilterUpdate,
  storeFiltersInBrowserStorage,
  currencyCode,
  filtersFromBrowserStorage,
  exportButtonState,
  setExportButtonState,
  selectedSalesChannelName,
  request,
  startDate,
  endDate,
  campaignDetailFilters,
  selectedColumns,
  onColumnSelectionChange = noop,
  columnManagerOptions,
  dataFetcher,
  merchantType,
  productConnectionStatus,
  aiEnabled,
  merchantsWithAutomationEnabled,
  tableMode,
  toggleTableMode,
  isSaveChangesLoading,
  onEditSave,
  switchLevelController,
  selectedSalesChannel,
  activeSliderIndex,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFilterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);

  const [bulkEditSelectedOptions, setBulkEditSelectedOptions] =
    useState<BulkEditSelectedOptions>({});

  const [showTrendsSlideover, setShowTrendsSlideover] =
    useState<boolean>(false);

  const [confirmationModalVariant, setConfirmationModalVariant] =
    useState<Variant | null>(null);

  const [bulkEditValues, setBulkEditValues] = useState<BulkEditModalValues>({});

  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);

  const [isBulkEditSlideOutOpen, setBulkEditModalOpen] =
    useState<boolean>(false);

  const [selectedTrendsProduct, setSelectedTrendsProduct] =
    useState<SelectedTrendsProduct>();

  const [clickedOnView, setClickedOnView] = useState<boolean>(false);

  const merchantContext = useContext<MerchantContextState>(MerchantContext);
  const merchantDetails = merchantContext.merchantDetails;

  const userContext = useContext<UserContextState>(UserContext);

  const location = useLocation();

  const ref = useRef<boolean>(false);
  const firstApiCall = useRef<boolean>(true);

  const currentTableFilters = useSelector<WithTable<SVAdsEntity>, Filter[]>(
    ({ tableState }) => tableState[ADS_MANAGER_SV_ADS_TABLE_ID]?.filters || {}
  );

  const selectedRowIds = useSelector<WithTable<SVAdsEntity>, string[]>(
    ({ tableState }) =>
      tableSelectors.getSelectedRowsSelector()(
        tableState,
        ADS_MANAGER_SV_ADS_TABLE_ID
      )
  );

  const changedCount = useSelector<WithTable<SVAdsEntity>, number>(
    ({ tableState }) => {
      return tableSelectors.getChangedCountSelector()(
        tableState,
        ADS_MANAGER_SV_ADS_TABLE_ID
      );
    }
  );

  const tableChange = useSelector<WithTable<SVAdsEntity>, TableChange>(
    ({ tableState }) =>
      tableSelectors.getChangeSelector()(
        tableState,
        ADS_MANAGER_SV_ADS_TABLE_ID
      )
  );

  const svAdsTableData = useSelector<
    WithTable<ProductAdDetails>,
    ProductAdDetails[]
  >(({ tableState }) =>
    tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<ProductAdDetails, void>()(
        tableState,
        ADS_MANAGER_SV_ADS_TABLE_ID
      )
    )
  );

  const eligibleAdItemIds = useMemo(
    () =>
      svAdsTableData.filter(isAdItemIdEligible).map(({ adItemId }) => adItemId),
    [svAdsTableData]
  );

  const onViewTrendsSlideoverClick = (product: SelectedTrendsProduct) => {
    setSelectedTrendsProduct(product);
    setShowTrendsSlideover(true);
  };

  const refreshTable = () =>
    dispatch(
      tableThunks.refreshTable(ADS_MANAGER_SV_ADS_TABLE_ID, dataFetcher)
    );

  const { hasTimeElapsed, updateTime } = useTime(
    parseInt(
      process.env.REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES ||
        REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES
    )
  );

  const clearAllChanges = () =>
    dispatch(
      tableActions.clearAllChanges({
        tableId: ADS_MANAGER_SV_ADS_TABLE_ID,
      })
    );

  const saveChangesFlagContext = useContext<SaveChangesFlagContextState>(
    SaveChangesFlagContext
  );

  useEffect(() => {
    showHideIntercomLauncher(isBulkEditSlideOutOpen);
  }, [isBulkEditSlideOutOpen]);

  useEffect(() => {
    saveChangesFlagContext.updateSaveChangesData({
      editDataFlag: changedCount > 0,
      saveChangesModalIsOpen: false,
      navPath: saveChangesFlagContext.saveChangesData.navPath,
    });
  }, [changedCount]);

  const isTableDataEdited: boolean =
    saveChangesFlagContext.saveChangesData.editDataFlag;

  const initBeforeUnLoad = (showExitPrompt: boolean) => {
    window.onbeforeunload = (event: Event) => {
      if (showExitPrompt) {
        event.preventDefault();
        return '';
      }
    };
  };

  useEffect(() => {
    // Show prompt if table is edited and user is on edit mode
    initBeforeUnLoad(isTableDataEdited && activeSliderIndex === 1);
  }, [isTableDataEdited]);

  useEffect(() => {
    if (!firstApiCall.current) {
      refreshTable();
    } else {
      firstApiCall.current = false;
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (ref.current && hasTimeElapsed()) {
      updateTime();
      refreshTable();
    } else {
      ref.current = true;
      if (location.state && location.state.filter) {
        let { filter } =
          (location.state && (location.state as { filter: Filter[] })) || [];
        dispatch(
          tableActions.updateFilters({
            tableId: ADS_MANAGER_SV_ADS_TABLE_ID,
            filters: filter || currentTableFilters,
            replace: true,
          })
        );
      }
    }
  }, [apiColumnsWithAdLevel.columns]);

  const resetModal = () => {
    setBulkEditValues({});
    setBulkEditSelectedOptions({});
  };

  const handleApplyBulkChange = (values: BulkEditModalValues): void => {
    let bulkChanges = {};
    values = getSelectedBulkMenuValues(values, bulkEditSelectedOptions);

    if (values[SV_ADS_API_COLUMN_NAME.AdStatus]) {
      bulkChanges = {
        ...bulkChanges,
        ...getBulkDataForSVAdStatus(
          tableChange,
          SV_ADS_API_COLUMN_NAME.AdStatus,
          [values[SV_ADS_API_COLUMN_NAME.AdStatus] as string],
          svAdsTableData,
          selectedAdType
        ),
      };
    }

    dispatch(
      tableActions.applyBulkChanges({
        tableId: ADS_MANAGER_SV_ADS_TABLE_ID,
        bulkChanges,
        columnDataMapping: EDIT_AD_ITEMS_API_COLUMN_DATA_MAPPING,
        uniqKey: TABLE_UNIQ_KEY[ADS_MANAGER_SV_ADS_TABLE_ID],
      })
    );
    resetModal();
  };

  useEffect(() => {
    if (!isEmpty(bulkEditValues)) {
      handleApplyBulkChange(bulkEditValues);
    }
  }, [bulkEditValues]);

  const onBulkEditOptionSelection = (key: string, value: boolean) => {
    setBulkEditSelectedOptions((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const bulkEditModalEditControls = getFilteredBulkEditOptions(
    getEditControls(
      intl,
      bulkEditValues,
      tableChange,
      getCurrencySymbolFromMerchantCountryCode(merchantCountry) ||
        CURRENCY_CODE,
      selectedSalesChannel,
      onBulkEditOptionSelection,
      svAdsTableData
    ),
    selectedAdType,
    selectedSalesChannel
  ).map((editControl, index) => ({
    ...editControl,
    selected: editControl.editKey
      ? bulkEditSelectedOptions[editControl.editKey]
      : false,
    controls: editControl?.controls,
  }));

  const getVisibleColumns = (): FlywheelTableColumnGroup<
    ProductAdDetails,
    TableDataAdsManager
  >[] => {
    const SV_ADS_COLUMNS = getColumns(tableMode === DualModes.Edit, {
      checked: getCheckBoxState(
        eligibleAdItemIds.length,
        selectedRowIds.length
      ),
      onSelectChange: handleSelectChange,
    });
    return SV_ADS_COLUMNS.map((column) => {
      const columnInGroups = column.columnsInGroup.filter((subColumn) =>
        apiColumnsWithAdLevel.columns.includes(subColumn.columnName)
      );
      return {
        ...column,
        columnsInGroup: columnInGroups.filter((col) => {
          if (
            optimizelyContext.featureFlags[
              OptimizelyFlags.SponsoredVideosEditable
            ]
          ) {
            return true;
          }

          return ![
            SV_ADS_API_COLUMN_NAME.AdGroupStatus,
            SV_ADS_API_COLUMN_NAME.AdGroupReviewStatus,
            SV_ADS_API_COLUMN_NAME.CampaignTargetingType,
            SV_ADS_API_COLUMN_NAME.CampaignDailyBudget,
            SV_ADS_API_COLUMN_NAME.LifetimeBudget,
            SV_ADS_API_COLUMN_NAME.CampaignStatus,
            SV_ADS_API_COLUMN_NAME.DirectAdSales,
            SV_ADS_API_COLUMN_NAME.RelatedClickRevenue,
            SV_ADS_API_COLUMN_NAME.DirectACOS,
            SV_ADS_API_COLUMN_NAME.DirectROAS,
            SV_ADS_API_COLUMN_NAME.AdvertisedSkuUnits,
            SV_ADS_API_COLUMN_NAME.OtherSkuUnits,
          ].includes(col.columnName);
        }),
      };
    }).filter((column) => column.columnsInGroup.length > 0);
  };

  const dataFields = getFilterColumnDefinitons(
    CURRENCY_CODE,
    salesChannel as FlywheelSalesChannel,
    adType,
    adLevelDropDownSelectedValues || undefined,
    merchantDetails,
    false,
    undefined,
    false,
    undefined,
    false,
    false,
    undefined,
    undefined,
    optimizelyContext.featureFlags[OptimizelyFlags.SponsoredVideosEditable]
  );

  const updateFilters = (filters: Filter[]) => {
    const existingSearchFilters: Filter[] = currentFilters.filter(
      (currentFilter) =>
        ALL_AD_LEVELS_FILTER_FILED_MAPPER.find(
          (filter) => filter.alias === currentFilter.field
        )
    );
    const newFilters: Filter[] = [...existingSearchFilters, ...filters];
    dispatch(
      tableActions.updateFilters({
        tableId: ADS_MANAGER_SV_ADS_TABLE_ID,
        filters: newFilters,
        replace: true,
      })
    );
    upgradeFiltersInStorage(newFilters, storeFiltersInBrowserStorage);
    if (onFilterUpdate) {
      onFilterUpdate(newFilters);
    }
  };

  let currentFilters = [
    ...currentTableFilters,
    ...(filtersFromBrowserStorage ?? []),
  ];

  const creativeChannelSettingWithoutMandatoryColumns = [
    ...apiColumnsWithAdLevel.columns.filter(
      (column) =>
        SV_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        SvAdApiColumnGroupIdentifier.ChannelSettingsFields
    ),
  ];

  const getExportRequest = () => {
    const { performanceFields } = request();

    let creativeDetailsWithoutMandatoryColumns = [
      EXT_CREATIVE_ID,
      SV_ADS_API_COLUMN_NAME.Name,
      EXT_CAMPAIGN_ID,
      EXT_ADGROUP_ID,
      ...apiColumnsWithAdLevel.columns.filter(
        (column) =>
          column !== SV_ADS_API_COLUMN_NAME.Name &&
          SV_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
            SvAdApiColumnGroupIdentifier.CreativeDetailsFields
      ),
    ];

    return {
      ...request(),
      creativeDetailsFields: creativeDetailsWithoutMandatoryColumns,
      channelSettingsFields: creativeChannelSettingWithoutMandatoryColumns,
      performanceFields: performanceFields.filter((column) =>
        apiColumnsWithAdLevel.columns.includes(column)
      ),
    };
  };

  const viewModeRightSideComponents = [
    <Tooltip
      overwrittenTooltipClassnames="w-auto max-w-400"
      theme={Theme.Dark}
      content={intl.formatMessage({
        id: I18nKey.GENERIC_DOWNLOAD_CSV,
      })}
      position={{
        placement: Placement.Bottom,
        alignment: Alignment.Left,
      }}
      tooltipSize={TooltipSize.Small}
      hideArrow
    >
      <ExportAdLevelData<ProductAdsDataRequest>
        aoApiClient={aoApiClient}
        buttonState={exportButtonState}
        setButtonState={setExportButtonState}
        salesChannel={selectedSalesChannelName}
        request={getExportRequest()}
        adLevelDropDownSelectedValue={
          adLevelDropDownSelectedValues || undefined
        }
        filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
        startDate={startDate}
        endDate={endDate}
        adType={selectedAdType}
        hideExportText={true}
        additionalFilters={campaignDetailFilters}
      />
    </Tooltip>,
  ];

  const discardChanges = () => {
    toggleTableMode();

    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
    });

    clearAllChanges();
  };

  const onActionClick = (actionType: Variant) =>
    discardCurrentChanges(
      actionType,
      tableChange.cell as TableCellChangeSvAd,
      discardChanges,
      () => setConfirmationModalVariant(actionType)
    );

  const validAdStatusCount = Object.values(
    tableChange.cell as TableCellChangeSvAd
  ).filter(({ status }) => !isNil(status)).length;

  const handleOperationsAsPerTableMode = (mode: DualModes) => {
    const isUserRoleViewOnly = getUserRoleMatch(
      Role.VIEW_ONLY,
      userContext.userInfo.userDetails
    );
    if (!isUserRoleViewOnly) {
      if (mode === DualModes.View) {
        setClickedOnView(true);
      }

      if (
        mode === DualModes.View &&
        saveChangesFlagContext.saveChangesData.editDataFlag
      ) {
        saveChangesFlagContext.updateSaveChangesData({
          ...saveChangesFlagContext.saveChangesData,
          saveChangesModalIsOpen: true,
        });
      } else {
        clearAllChanges();
        toggleTableMode();
      }
    }
  };

  const sortedTableChange = [...tableChange.select.rows].sort((a, b) =>
    a.localeCompare(b)
  );
  const sortedEligibleAdIds = [...eligibleAdItemIds].sort((a, b) =>
    String(a).localeCompare(String(b))
  );

  const isAllRowsSelected = isEqual(sortedTableChange, sortedEligibleAdIds);

  const handleSelectChange = () => {
    const requiredSelectedRows = isAllRowsSelected ? [] : eligibleAdItemIds;
    dispatch(
      tableActions.changeSelect({
        tableId: ADS_MANAGER_SV_ADS_TABLE_ID,
        selectedRows: requiredSelectedRows as string[],
      })
    );
  };

  const onConfirmationModalAction = (
    kind: ConfirmationModalClickKind,
    discardOrSaveChanges: () => void
  ) => {
    setConfirmationModalVariant(null);
    if (kind === ConfirmationModalClickKind.APPLY) {
      discardOrSaveChanges();
    }
  };

  const confirmationModalClick = (kind: ConfirmationModalClickKind) => {
    switch (confirmationModalVariant) {
      case Variant.Apply:
        onConfirmationModalAction(kind, onEditSave);
        break;
      case Variant.Discard:
        onConfirmationModalAction(kind, discardChanges);
    }
  };

  const onLeaveWithoutSaveClick = () => {
    const adLevel = saveChangesFlagContext.saveChangesData.aoAdLevel;

    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
      aoAdLevel: undefined,
    });

    handleLeaveWithoutSaveClick(
      toggleTableMode,
      setClickedOnView,
      switchLevelController,
      saveChangesFlagContext,
      navigate,
      adLevel,
      clickedOnView
    );
  };

  return (
    <>
      {showTrendsSlideover && selectedTrendsProduct && (
        <PerformanceSlideoverContextProvider
          currency={currencyCode as CurrencyCode}
          defaultDateRange={{
            startDate,
            endDate,
          }}
          {...selectedTrendsProduct}
        >
          <PerformanceSlideover
            onClose={() => {
              setShowTrendsSlideover(false);
              setSelectedTrendsProduct(undefined);
            }}
          />
        </PerformanceSlideoverContextProvider>
      )}
      <KeepEditingModal
        showModal={
          saveChangesFlagContext.saveChangesData.saveChangesModalIsOpen
        }
        onKeepEditing={() =>
          onKeepEditingClick(saveChangesFlagContext, setClickedOnView)
        }
        onDiscard={onLeaveWithoutSaveClick}
        tableId={ADS_MANAGER_SV_ADS_TABLE_ID}
      />
      <TableActionRow
        className="mt-16"
        leftSideElements={[
          <FlywheelSearchInput
            tableId={ADS_MANAGER_SV_ADS_TABLE_ID}
            inputSearchColumnName={'productName'}
            shouldAddWildcardsToFilterValue
            searchInputSize={SearchInputSize.Medium}
            searchInputPlaceholder={getSearchInputPlaceholder(
              selectedAdType,
              adLevelDropDownSelectedValues as AdLevel,
              intl
            )}
            onFilterUpdate={onFilterUpdate}
            upgradeFiltersInStorage={upgradeFiltersInStorage}
            hasSearchInputWithButton
            searchInputClassName="w-480"
            dataTestId={`${ADS_MANAGER_SV_ADS_TABLE_ID}_search_input`}
          />,
          <FiltersMenu
            currency={currencyCode as CurrencyCode}
            dataFields={dataFields}
            currentFilters={currentFilters}
            handleSave={updateFilters}
            filterButtonSize={ButtonSize.Medium}
            isOpen={isFilterMenuOpen}
            setOpen={setFilterMenuOpen}
            filterDateFormat={REQUEST_DATE_FORMAT}
            singleDecimalColumns={COLUMNS_WITH_SINGLE_DECIMAL_FILTER}
            dataTestId={`${ADS_MANAGER_SV_ADS_TABLE_ID}_filters_menu`}
            filtersContainerClass={
              'transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:left-0'
            }
          />,
        ]}
        rightSideElements={[
          <Tooltip
            overwrittenTooltipClassnames="w-auto max-w-400"
            theme={Theme.Dark}
            content={intl.formatMessage({
              id: I18nKey.GENERIC_COLUMNS,
            })}
            tooltipSize={TooltipSize.Small}
            hideArrow
          >
            <ColumnManager
              variant={ColumnManagerVariant.ColumnGroupWithHeader}
              values={selectedColumns ?? []}
              onChange={onColumnSelectionChange}
              options={columnManagerOptions?.filter((option) => {
                if (
                  optimizelyContext.featureFlags[
                    OptimizelyFlags.SponsoredVideosEditable
                  ]
                ) {
                  return true;
                }

                return ![
                  DATA_INSPECTOR_COLUMN_NAME.AdGroupStatus,
                  DATA_INSPECTOR_COLUMN_NAME.AdGroupReviewStatus,
                  DATA_INSPECTOR_COLUMN_NAME.CampaignTargetingType,
                  DATA_INSPECTOR_COLUMN_NAME.DailyBudget,
                  DATA_INSPECTOR_COLUMN_NAME.LifetimeBudget,
                  DATA_INSPECTOR_COLUMN_NAME.CampaignStatus,
                  DATA_INSPECTOR_COLUMN_NAME.DirectAdSales,
                  DATA_INSPECTOR_COLUMN_NAME.RelatedClickRevenue,
                  DATA_INSPECTOR_COLUMN_NAME.DirectACOS,
                  DATA_INSPECTOR_COLUMN_NAME.DirectROAS,
                  DATA_INSPECTOR_COLUMN_NAME.AdvertisedSkuUnits,
                  DATA_INSPECTOR_COLUMN_NAME.OtherSkuUnits,
                ].includes(option.value);
              })}
              tooltipClass="right-0"
              hideColumnText={true}
              dataTestId={`${ADS_MANAGER_SV_ADS_TABLE_ID}_column_manager`}
            />
          </Tooltip>,
          ...viewModeRightSideComponents,
        ]}
        fullWidthElementIndex={-1}
      />
      {getBulkEditModalConfirmation(
        confirmationModalVariant,
        confirmationModalClick
      )}
      <BulkEditSlideOut
        editControls={bulkEditModalEditControls}
        headerText={intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_BULK_EDIT_MODAL_CAMPAIGNS_SLIDEOUT_HEADER,
        })}
        isOpen={isBulkEditSlideOutOpen}
        onClose={() => setBulkEditModalOpen(false)}
        onSubmit={(bulkEditValues: BulkEditValues) => {
          setBulkEditValues(bulkEditValues as BulkEditModalValues);
          setBulkEditModalOpen(!isBulkEditSlideOutOpen);
        }}
        onCancel={() => {
          setBulkEditModalOpen(!isBulkEditSlideOutOpen);
          resetModal();
        }}
        primaryButtonText={intl.formatMessage({
          id: I18nKey.GENERIC_SUBMIT,
        })}
        secondaryButtonText={intl.formatMessage({
          id: I18nKey.CANCEL,
        })}
      />
      <UpdatedFlywheelTable<ProductAdDetails, TableDataAdsManager>
        hasSearchInput={false}
        columns={getVisibleColumns()}
        currencyCode="USD"
        dataFetcher={dataFetcher}
        dataFields={dataFields}
        filtersContainerClass="w-1/4"
        footerTotalItemsI18nKey={
          I18nKey.ADS_MANAGER_SB_ADS_TABLE_FOOTER_TOTAL_ITEMS_TEXT
        }
        actionRowV2Props={{
          loadingText: intl.formatMessage({
            id: I18nKey.ADVERTISING_OPTIMIZATION_TABLE_ACTION_ROW_LOADING_TEXT,
          }),
          isLoading: isSaveChangesLoading,
          isActive: selectedRowIds.length > 0,
          showSlider:
            optimizelyContext.featureFlags[
              OptimizelyFlags.SponsoredVideosEditable
            ],
          sliderMode: tableMode,
          showEditButton: showEditButton(tableMode, selectedRowIds),
          onSliderModeChange: handleOperationsAsPerTableMode,
          selectedCount: selectedRowIds.length,
          showSelectedCount: tableMode === DualModes.Edit,
          totalCount: svAdsTableData.length,
          onEditClick: () => setBulkEditModalOpen(true),
          ...getActionRowButtons(
            tableMode,
            intl,
            getSaveButtonState(changedCount, validAdStatusCount),
            onActionClick
          ),
        }}
        tableData={{
          isEditMode: tableMode === DualModes.Edit,
          adTypes: [adType],
          allMerchants: [],
          aoApiClient,
          merchantCountry,
          salesChannel,
          selectedAdType,
          selectedMerchantCountries,
          merchantType,
          productConnectionStatus,
          selectedEndDate: endDate,
          onViewTrendsSlideoverClick,
          showSmartCampaign: shouldShowSmartCampaigns(optimizelyContext),
          aiEnabled,
          merchantsWithAutomationEnabled,
        }}
        tableId={ADS_MANAGER_SV_ADS_TABLE_ID}
        hasStickyLeftColumn
        errorComponent={<ErrorTable />}
        yesFilterNoDataDisplayComponent={<NoFilteredDataFoundTable />}
        noFilterNoDataDisplayComponent={<NoDataFoundTable />}
        onFilterUpdate={onFilterUpdate}
        filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
        filtersFromBrowserStorage={filtersFromBrowserStorage}
        showTableActionRow2={true}
      />
    </>
  );
};

TableSVAds.displayName = 'TableSVAds';
