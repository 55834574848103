import {
  CheckmarkIcon,
  ColumnAlign,
  Icon,
  IconSize,
  TableCellV2,
  TableCellV2Type,
  Typography,
  TypographyLineHeight,
  TypographySize,
  TypographyWeight,
} from '@teikametrics/tm-design-system';

import { FlywheelTableV2ColumnProps } from '../../../../../../containers/tableV2/types';
import { SalesChannelData } from '../../../../../../lib/types/Fam';
import { SalesChannelTableData } from '../types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import I18nKey from '../../../../../../lib/types/I18nKey';

export const statusColumn: FlywheelTableV2ColumnProps<
  SalesChannelData & { isConnected?: boolean; isNotAvailable?: boolean },
  {},
  SalesChannelTableData
> = {
  header: '',
  cell: ({ row: { original } }) => (
    <TableCellV2
      tableCellData={{
        currentValue: (
          <div
            className={classNames(
              'flex flex-row gap-4 py-4 px-8 items-center rounded-4',
              {
                'bg-green-100': original.isConnected,
                'bg-orange-200': original.isNotAvailable,
              }
            )}
          >
            {original.isConnected && (
              <>
                <Icon
                  svg={CheckmarkIcon}
                  className="text-green-500"
                  size={IconSize.Medium}
                />

                <Typography
                  size={TypographySize.sm}
                  lineHeight={TypographyLineHeight.none}
                  weight={TypographyWeight.regular}
                  className="text-grey-900"
                >
                  <FormattedMessage id={I18nKey.CONNECTED} />
                </Typography>
              </>
            )}
            {original.isNotAvailable && (
              <Typography
                size={TypographySize.sm}
                lineHeight={TypographyLineHeight.none}
                weight={TypographyWeight.regular}
                className="text-orange-700"
              >
                <FormattedMessage id={I18nKey.GENERIC_NOT_AVAILABLE} />
              </Typography>
            )}
          </div>
        ),
        tableCellType: TableCellV2Type.Text,
      }}
    />
  ),
  accessorKey: 'status',
  sortable: true,
  size: 60,
  minSize: 60,
  align: ColumnAlign.Right,
};
