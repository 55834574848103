import {
  AmazonLogomarkEnabledIcon,
  NumericInputState,
  PaletteColor,
  SelectOptionProps,
  StepStatus,
  TextInputState,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { IntlShape } from 'react-intl';
import polling from 'rx-polling';
import { defer } from 'rxjs';
import { CampaignFlexibilityApiClient } from '../../../lib/clients/CampaignFlexibilityClient';
import {
  AdGroup,
  CampaignProductAdGroupProductGrouping,
  CampaignStructureGenerationStatus,
  CampaignStructureGenerationStatusResponse,
  KeywordTargetsRequest,
  KeywordTargetsResponse,
} from '../../../lib/types/CampaignFlexibilitySharedTypes';
import {
  AllSalesChannel,
  FlywheelSalesChannel,
  MerchantCountry,
  MerchantType,
} from '../../../lib/types/Fam';
import I18nKey from '../../../lib/types/I18nKey';
import {
  AMAZON_SALES_CHANNEL_ID,
  MAP_SALES_CHANNEL_NAME_TO_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../lib/types/SalesChannels';
import {
  EUROPE_CURRENCY_SYMBOL,
  getCountryFlagIcon,
} from '../../../lib/utilities/countryFlags';
import { createMerchantDropdownOptionData } from '../../../modules/compass/utils';
import {
  BID_CONSTRAINTS,
  BidConstraint,
  CampaignFlexibilityStep,
  KeywordErrorType,
  MAP_CAMPAIGN_TO_STEPS,
  MERCHANT_COUNTRY_CODE_TO_BASE_EXTERNAL_URL_MAPPER,
} from '../types';

import first from 'lodash/first';
import isNull from 'lodash/isNull';
import {
  BidContraints,
  MAP_ADTYPE_TO_VARIATION_TYPE,
  MAP_MERCHANT_TYPE_TO_VARIATION_TYPE,
} from '../../../containers/bidConstraintsProvider/biddingConstraints';
import { KeywordType } from '../../../modules/advertisingOptimization/containers/adsManager/createKeywords/createKeywordsMenu';
import {
  CurrencyCode,
  getCurrencySymbol,
} from '../../../lib/utilities/currency';
import { AdType, MerchantCountryCode } from '../../../lib/types/AOSharedTypes';

export interface UserInfo {
  readonly userId: string;
  readonly accountId: string;
}

export const getStepProps = (
  intl: IntlShape,
  activeStep: CampaignFlexibilityStep,
  steps: number
) => {
  return [
    {
      stepName: intl.formatMessage({
        id: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_SETUPCAMPAIGNS,
      }),
      stepLabel: '1',
      stepStatus: getStepStatus(
        activeStep,
        CampaignFlexibilityStep.STEP_1,
        steps,
        MAP_CAMPAIGN_TO_STEPS[CampaignFlexibilityStep.STEP_1]
      ),
      dataTestId: 'cf_stepper_setup_campaigns',
    },
    {
      stepName: intl.formatMessage({
        id: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_ADDPRODUCTS,
      }),
      stepLabel: '2',
      stepStatus: getStepStatus(
        activeStep,
        CampaignFlexibilityStep.STEP_2,
        steps,
        MAP_CAMPAIGN_TO_STEPS[CampaignFlexibilityStep.STEP_2]
      ),
      dataTestId: 'cf_stepper_addproducts',
    },
    {
      stepName: intl.formatMessage({
        id: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PREVIEW_AND_LAUNCH,
      }),
      stepLabel: '3',
      stepStatus: getStepStatus(
        activeStep,
        CampaignFlexibilityStep.STEP_3,
        steps,
        MAP_CAMPAIGN_TO_STEPS[CampaignFlexibilityStep.STEP_3]
      ),
      dataTestId: 'cf_stepper_preview_and_launch',
    },
  ];
};

export const getStepPreSetUpProps = (
  intl: IntlShape,
  activeStep: CampaignFlexibilityStep,
  steps: number,
  salesChannels: FlywheelSalesChannel = FlywheelSalesChannel.Amazon
) => {
  const Label = [
    {
      stepName: intl.formatMessage({
        id: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PRE_SETUPCAMPAIGNS,
      }),
      stepLabel: '1',
      stepStatus: getStepStatus(
        activeStep,
        CampaignFlexibilityStep.STEP_1,
        steps,
        MAP_CAMPAIGN_TO_STEPS[CampaignFlexibilityStep.STEP_1]
      ),
      dataTestId: 'fw_co_setup_campaigns',
    },
    {
      stepName: intl.formatMessage({
        id: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PRE_ADDPRODUCTS_GOAL,
      }),
      stepLabel: '2',
      stepStatus: getStepStatus(
        activeStep,
        CampaignFlexibilityStep.STEP_2,
        steps,
        MAP_CAMPAIGN_TO_STEPS[CampaignFlexibilityStep.STEP_2]
      ),
      dataTestId: 'fw_co_add_products',
    },
    {
      stepName: intl.formatMessage({
        id: I18nKey.CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_4_ACCORDION_LABEL,
      }),
      stepLabel: '3',
      stepStatus: getStepStatus(
        activeStep,
        CampaignFlexibilityStep.STEP_3,
        steps,
        MAP_CAMPAIGN_TO_STEPS[CampaignFlexibilityStep.STEP_3]
      ),
      dataTestId: 'fw_co_KT',
    },
    ...(salesChannels === FlywheelSalesChannel.Amazon
      ? [
          {
            stepName: intl.formatMessage({
              id: I18nKey.CAMPAIGN_FLEXIBILITY_DASHBOARD_STEP_5_ACCORDION_LABEL,
            }),
            stepLabel: '4',
            stepStatus: getStepStatus(
              activeStep,
              CampaignFlexibilityStep.STEP_4,
              steps,
              MAP_CAMPAIGN_TO_STEPS[CampaignFlexibilityStep.STEP_4]
            ),
            dataTestId: 'fw_co_preview_and_launch',
          },
        ]
      : []),
    {
      stepName: intl.formatMessage({
        id: I18nKey.CAMPAIGN_FLEXIBILITY_STEPPER_PRE_PREVIEW_AND_LAUNCH,
      }),
      stepLabel: '5',
      stepStatus: getStepStatus(
        activeStep,
        CampaignFlexibilityStep.STEP_5,
        steps,
        MAP_CAMPAIGN_TO_STEPS[CampaignFlexibilityStep.STEP_5]
      ),
      dataTestId: 'fw_co_preview_and_launch',
    },
  ];
  return Label.map((item, index) => {
    return { ...item, stepLabel: `${index + 1}` };
  });
};
export const getStepStatus = (
  activeStep: CampaignFlexibilityStep,
  activeStepTab: CampaignFlexibilityStep,
  steps: number,
  currentSteps: number
) => {
  if (steps === currentSteps && activeStep === activeStepTab) {
    return StepStatus.Active;
  }
  if (steps > currentSteps) {
    return StepStatus.Complete;
  }
  return StepStatus.Inactive;
};

export const generateMerchantPickerOptions = (
  merchants: MerchantCountry[],
  salesChannels: AllSalesChannel[],
  intl: IntlShape
): SelectOptionProps<string>[] => {
  return merchants.map((merchant) => {
    const { groupName, icon, secondaryIcon } = createMerchantDropdownOptionData(
      merchant,
      salesChannels
    );
    return {
      value: merchant.merchantCountryId,
      label: merchant.merchantName,
      groupName: groupName && intl.formatMessage({ id: groupName }),
      icon,
      secondaryIcon,
      pillProps: {
        text: merchant.merchantType === MerchantType.Seller ? '3P' : '1P',
        color: PaletteColor.grey,
        className: 'ml-8',
      },
    };
  });
};

export const getCurrencyPickerOptions = (): SelectOptionProps<string>[] => {
  const SUPPORTED_CURRENCIES: Array<string> = [
    CurrencyCode.USD,
    CurrencyCode.CAD,
    CurrencyCode.MXN,
    CurrencyCode.BRL,
    CurrencyCode.GBP,
    CurrencyCode.EUR,
    CurrencyCode.JPY,
    CurrencyCode.AUD,
    CurrencyCode.SGD,
    CurrencyCode.AED,
    CurrencyCode.INR,
  ];
  const SUPPORTED_FLAG_BY_CURRENCY: Record<
    string,
    MerchantCountryCode | string
  > = {
    [CurrencyCode.USD]: MerchantCountryCode.US,
    [CurrencyCode.CAD]: MerchantCountryCode.CA,
    [CurrencyCode.MXN]: MerchantCountryCode.MX,
    [CurrencyCode.BRL]: MerchantCountryCode.BR,
    [CurrencyCode.GBP]: MerchantCountryCode.GB,
    [CurrencyCode.EUR]: EUROPE_CURRENCY_SYMBOL,
    [CurrencyCode.JPY]: MerchantCountryCode.JP,
    [CurrencyCode.AUD]: MerchantCountryCode.AU,
    [CurrencyCode.SGD]: MerchantCountryCode.SG,
    [CurrencyCode.AED]: MerchantCountryCode.AE,
    [CurrencyCode.INR]: MerchantCountryCode.IN,
  };
  return SUPPORTED_CURRENCIES.map((code) => ({
    label: `${getCurrencySymbol(code)} ${code}`, // $ USD
    value: code,
    icon: getCountryFlagIcon(SUPPORTED_FLAG_BY_CURRENCY[code]),
  }));
};

export const toTitleCase = (str: string) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

export const getAmazonItemPageUrl = (
  merchantCountryCode?: MerchantCountryCode,
  skuOrAsin?: string
): string | undefined => {
  let pageUrl;
  if (merchantCountryCode && skuOrAsin) {
    pageUrl = !isEmpty(
      MERCHANT_COUNTRY_CODE_TO_BASE_EXTERNAL_URL_MAPPER[merchantCountryCode]
    )
      ? MERCHANT_COUNTRY_CODE_TO_BASE_EXTERNAL_URL_MAPPER[merchantCountryCode] +
        skuOrAsin
      : undefined;
  }
  return pageUrl;
};

export const isCurrentValueGreaterThanRequiredMaxValue = (
  maxValue?: number,
  value?: string
): boolean => {
  return value && !isNil(maxValue) ? Number(value) > maxValue : false;
};

export const isCurrentValueLessThanRequiredMinValue = (
  minValue?: number,
  value?: string
): boolean => {
  return value && !isNil(minValue) ? Number(value) < minValue : false;
};

export const isValidDefaultBid = (
  currentBidValue: string,
  maxValue?: number,
  minValue?: number,
  extraValidation?: () => boolean
): boolean => {
  const isValidBid =
    !isEmpty(currentBidValue) &&
    !isNaN(Number(currentBidValue)) &&
    !isCurrentValueGreaterThanRequiredMaxValue(maxValue, currentBidValue) &&
    !isCurrentValueLessThanRequiredMinValue(minValue, currentBidValue);

  if (extraValidation) {
    return isValidBid && extraValidation();
  }

  return isValidBid;
};

export const getInputState = (isValid: boolean) =>
  isValid ? NumericInputState.Default : NumericInputState.Error;

export const getTextState = (isValid: boolean) =>
  isValid ? TextInputState.Default : TextInputState.Error;

export const stopPollingStatus = (
  response: CampaignStructureGenerationStatusResponse
) => {
  // Polling should stop for at least one run_complete or error
  return (
    response.status === CampaignStructureGenerationStatus.Error ||
    response.status === CampaignStructureGenerationStatus.Ready
  );
};

export const startPollingStatus = (
  campaignFlexibilityClient: CampaignFlexibilityApiClient,
  accountId: string,
  merchantCountryId: string | undefined,
  runId: string | null,
  setValidResponse: (
    response: CampaignStructureGenerationStatusResponse
  ) => void,
  setIsError: (val: string) => void
) => {
  const statusObservable = defer(() =>
    campaignFlexibilityClient.getCampaignStructureGenerationStatus(
      accountId,
      merchantCountryId,
      runId
    )
  );
  const pollingSubscription = polling(statusObservable, {
    interval: 10000,
  }).subscribe((response) => {
    setValidResponse(response);
    setIsError(response.errorMessage);
    const stop = stopPollingStatus(response);
    if (stop) {
      // Cleanup
      pollingSubscription.unsubscribe();
    }
  });
  return pollingSubscription;
};

export const stopPollingTargets = (
  response: KeywordTargetsResponse
): boolean => {
  // Polling should stop for at least one run_complete or error
  return (
    response.status === CampaignStructureGenerationStatus.Error ||
    response.status === CampaignStructureGenerationStatus.Ready
  );
};

export const startPollingTargets = (params: {
  targetsRequest: KeywordTargetsRequest;
  campaignFlexibilityClient: CampaignFlexibilityApiClient;
  accountId: string;
  merchantCountryId: string | undefined;
  runId: string | null;
  setValidResponse: (response: KeywordTargetsResponse) => void;
  setIsError: (val: string) => void;
  isNegative: boolean;
}) => {
  const statusObservable = defer(() =>
    params.campaignFlexibilityClient.getKeywordTargets(
      params.accountId,
      params.merchantCountryId,
      params.runId,
      params.targetsRequest,
      params.isNegative
    )
  );
  const pollingSubscription = polling(statusObservable, {
    interval: 10000,
  }).subscribe((response) => {
    params.setValidResponse(response);
    params.setIsError(response.errorMessage);
    const stop = stopPollingTargets(response);
    if (stop) {
      // Cleanup
      pollingSubscription.unsubscribe();
    }
  });
  return pollingSubscription;
};

export const getBidConstraint = (
  salesChannel: any,
  countryCode: MerchantCountryCode,
  targetingType?: MerchantType
): BidConstraint => {
  if (targetingType && salesChannel === FlywheelSalesChannel.Walmart) {
    return (
      BID_CONSTRAINTS[FlywheelSalesChannel.Walmart][targetingType].find(
        (constraint) => constraint.countryCodes.includes(countryCode)
      ) || {
        minBid: 10,
        maxBid: Infinity,
        minacs: 1,
        maxacs: 999,
        countryCodes: [],
      }
    );
  } else {
    return (
      BID_CONSTRAINTS[FlywheelSalesChannel.Amazon].find((constraint) =>
        constraint.countryCodes.includes(countryCode)
      ) || {
        minBid: 1,
        maxBid: 1000000,
        countryCodes: [],
        minacs: 1,
        maxacs: 999,
      }
    );
  }
};
const getBidConstraintsForWalmart = (
  bidConstraintsData: BidContraints[],
  adType: AdType,
  merchantType: MerchantType
) => {
  if (adType === AdType.SponsoredProducts) {
    return bidConstraintsData.filter(
      (d) =>
        d.variationType ===
        MAP_ADTYPE_TO_VARIATION_TYPE[adType] +
          '_' +
          MAP_MERCHANT_TYPE_TO_VARIATION_TYPE[merchantType]
    );
  }
  return [];
};

export const getBidConstraintsForAmazon = (
  bidConstraintsData: BidContraints[],
  adType: AdType
) => {
  if ([AdType.SponsoredProducts].includes(adType)) {
    return bidConstraintsData.filter(
      (d) => d.variationType === MAP_ADTYPE_TO_VARIATION_TYPE[adType]
    );
  }

  return [];
};

export const getNewBidConstraint = (
  bidConstraintsData: BidContraints[],
  salesChannel: FlywheelSalesChannel,
  countryCode: MerchantCountryCode,
  targetingType: MerchantType
): BidContraints => {
  let bidConstraints: BidContraints[] = [];
  if (MAP_SALES_CHANNEL_NAME_TO_ID[salesChannel] === WALMART_SALES_CHANNEL_ID) {
    bidConstraints = getBidConstraintsForWalmart(
      bidConstraintsData.filter(
        (d: any) =>
          d.salesChannelId === WALMART_SALES_CHANNEL_ID &&
          d.countryCode === countryCode
      ),
      AdType.SponsoredProducts,
      targetingType
    );
  }
  if (MAP_SALES_CHANNEL_NAME_TO_ID[salesChannel] === AMAZON_SALES_CHANNEL_ID) {
    bidConstraints = getBidConstraintsForAmazon(
      bidConstraintsData.filter(
        (d: any) =>
          d.salesChannelId === AMAZON_SALES_CHANNEL_ID &&
          d.countryCode === countryCode
      ),
      AdType.SponsoredProducts
    );
  }
  if (first(bidConstraints)) {
    let constraints = bidConstraints[0] as unknown as Record<
      string,
      number | undefined
    >;

    Object.keys(constraints).forEach((key) => {
      if (constraints[key] === -1) {
        constraints[key] = Infinity;
      }

      if (isNull(constraints[key])) {
        constraints[key] = undefined;
      }
    });
    return constraints as unknown as BidContraints;
  }

  return {
    maxBid: Infinity,
    minBid: 0,
    defaultMinBid: 0,
    defaultMaxBid: 0,
    defaultMacsTarget: 0,
    minMacsTarget: 0,
    maxMacsTarget: Infinity,
    salesChannelId: MAP_SALES_CHANNEL_NAME_TO_ID[salesChannel],
    defaultBelowTopOfTheSearchBidMultiplier: 0,
    defaultDailyBudget: 0,
    defaultProductPageBidMultiplier: 0,
    defaultTopOfSearchBidMultiplier: 0,
    defaultTotalBudget: 0,
    maxBelowTopOfTheSearchBidMultiplier: Infinity,
    maxDailyBudget: Infinity,
    maxProductPageBidMultiplier: Infinity,
    maxTopOfSearchBidMultiplier: Infinity,
    maxTotalBudget: Infinity,
    minBelowTopOfTheSearchBidMultiplier: 0,
    minDailyBudget: 0,
    minProductPageBidMultiplier: 0,
    minTopOfSearchBidMultiplier: 0,
    minTotalBudget: 0,
  };
};

export const getCampaignStatus = (
  CampaignStatus: boolean,
  adGroups: AdGroup[]
) => {
  const adGroupsSelected = adGroups.some((adGroup) => {
    return adGroup.selected;
  });
  if (CampaignStatus && adGroupsSelected) {
    return CampaignStatus;
  }
  return false;
};
export const getSkuHeader = (
  groupType: CampaignProductAdGroupProductGrouping,
  salesChannel: FlywheelSalesChannel
) => {
  switch (groupType) {
    case CampaignProductAdGroupProductGrouping.Parent:
      return salesChannel === FlywheelSalesChannel.Amazon
        ? 'Parent Product'
        : 'Base Item Group';
    case CampaignProductAdGroupProductGrouping.Sku:
      return salesChannel === FlywheelSalesChannel.Amazon ? 'SKU' : 'Item ID';
    case CampaignProductAdGroupProductGrouping.BaseItem:
      return 'Base Item Id';
    case CampaignProductAdGroupProductGrouping.Category:
      return 'Category';
    case CampaignProductAdGroupProductGrouping.Shelf:
      return 'Shelf';
    default:
      return 'SKU';
  }
};

export const isValidACOSTarget = (
  dailyBudgetConstraint: BidContraints,
  value: number,
  salesChannels: string
) => {
  if (salesChannels === FlywheelSalesChannel.Amazon)
    return isValidDefaultBid(
      (value || 0).toString(),
      dailyBudgetConstraint.maxMacsTarget,
      dailyBudgetConstraint.minMacsTarget
    );
  if (salesChannels === FlywheelSalesChannel.Walmart)
    return !isNaN(Number(value)) && Number(value) > 0;

  return true;
};

const isKeywordTypeInvalid = (keyword: string, length: number) => {
  const validCharactersRegex =
    /^[\.&\\\+\-\[\]\t\n\r\s'"0-9a-z-A-Z®ÁÉÍÑÓÚÜáéíñóúüÄÖŒßäöÀ-ÊËÎ-ÏÔÙÛŸà-êëî-ôùûÿœ\b]*$/;

  const specialCharactersOnly = /[&\+\.\-\[\] \t\n\r\s\b'"]/;
  const specialCharactersWithoutSpace = /[&\+\.\-\[\]\t\n\r\b'"]/;

  if (
    !validCharactersRegex.test(keyword) ||
    /(^([+\-.])|([+\-.])$)/.test(keyword)
  ) {
    return KeywordErrorType.Invalid;
  }

  for (let i = 1; i < length; i++) {
    const preChar = keyword[i - 1];
    const currentChar = keyword[i];
    const postChar = keyword[i + 1];

    if (
      ['+', '-'].includes(currentChar) &&
      (specialCharactersOnly.test(preChar) ||
        specialCharactersOnly.test(postChar))
    ) {
      return KeywordErrorType.Invalid;
    } else if (
      currentChar === '.' &&
      (specialCharactersWithoutSpace.test(preChar) ||
        specialCharactersWithoutSpace.test(postChar))
    ) {
      return KeywordErrorType.Invalid;
    }
  }
};

export const validateKeyword = (
  keyword: string,
  isNegativePhrase?: boolean,
  keywordType?: KeywordType
): KeywordErrorType | undefined => {
  const length = keyword.length;

  if (
    keywordType === KeywordType.Product ||
    keywordType === KeywordType.Contextual
  ) {
    const validAsinRegex = /^[0-9a-z-A-Z]*$/;

    if (
      length < 10 ||
      length > 14 ||
      keyword[0].toLowerCase() !== 'b' ||
      !validAsinRegex.test(keyword)
    ) {
      return KeywordErrorType.InvalidAsin;
    }
    return;
  }

  if (length > 80) {
    return KeywordErrorType.MaxCharacters;
  }

  isKeywordTypeInvalid(keyword, length);

  if (keyword.split(/[& +-.]/).length > (isNegativePhrase ? 4 : 10)) {
    return isNegativePhrase
      ? KeywordErrorType.MaxPartsNegative
      : KeywordErrorType.MaxParts;
  }
};

export const getSalesChannelIconFromId = (salesChannelId: string) => {
  switch (salesChannelId) {
    case WALMART_SALES_CHANNEL_ID:
      return WalmartLogomarkEnabledIcon;
    case AMAZON_SALES_CHANNEL_ID:
    default:
      return AmazonLogomarkEnabledIcon;
  }
};
