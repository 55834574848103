import './sass/index.scss';
import './sass/tailwind.output.css';

import React from 'react';
import { debugContextDevtool } from 'react-context-devtool';
import { IntlProvider } from 'react-intl';

import { datadogRum } from '@datadog/browser-rum';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Flywheel, enUS, mergeDeep } from '@teikametrics/tm-design-system';

import App from './App';
import reportWebVitals from './reportWebVitals';
import enMessages from './translations/en.json';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Auth0ProviderWithHistory } from './Auth0ProviderWithHistory';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

if (process.env.REACT_APP_ENV !== 'development') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_RUM_APP_ID!,
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN!,
    site: 'datadoghq.com',
    service: process.env.REACT_APP_DD_RUM_SERVICE_NAME!,
    env: process.env.REACT_APP_ENV,
    traceSampleRate: 100,
    trackUserInteractions: true,
  });
}

const messages = mergeDeep(enUS, enMessages);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  release: process.env.REACT_APP_SENTRY_RELEASE,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

if (process.env.REACT_APP_ENABLE_LOG_ROCKET) {
  LogRocket.init(
    `${process.env.REACT_APP_LOG_ROCKET_APP_ID}/teikametrics-flywheel`
  );
  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL((sessionURL) => {
    const Intercom = window.Intercom;
    Intercom('trackEvent', 'LogRocket', { sessionURL: sessionURL });

    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <IntlProvider locale="en" messages={messages}>
          <Flywheel>
            <Routes>
              <Route path="*" element={<App />} />
            </Routes>
          </Flywheel>
        </IntlProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </QueryClientProvider>
);

if (process.env.NODE_ENV === 'development') {
  document.title = `[DEV] ${document.title}`;
}

debugContextDevtool(rootElement, {});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.ENVIRONMENT !== 'production') {
  reportWebVitals(console.log);
}
