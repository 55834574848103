import { CompassEmptyState, LoadingIcon } from '@teikametrics/tm-design-system';
import { useContext } from 'react';

import I18nKey from '../../../../lib/types/I18nKey';
import { NavPaths } from '../../../../NavPaths';
import { MerchantCountriesContext } from '../../../../containers/merchantCountriesProvider/merchantCountriesProvider';
import { getProductCatalogNavPath } from '../../../products/containers/utils';
import { useNavigate } from 'react-router-dom';

export enum TemplateType {
  AO = 'AO',
  SKU = 'SKU',
}

interface CommingSoonTemplateProps {
  readonly templateType?: TemplateType;
}

const CommingSoonTemplate: React.FC<CommingSoonTemplateProps> = ({
  templateType = TemplateType.AO,
}) => {
  const { has1PMerchants, has3PMerchants } = useContext(
    MerchantCountriesContext
  );
  const navigate = useNavigate();

  const navigateAsPerTemplateType = () => {
    let navigateURL = getProductCatalogNavPath(has1PMerchants, has3PMerchants);

    if (templateType === TemplateType.AO) {
      navigateURL = NavPaths.AOAdsManager;
    }
    navigate(navigateURL, { replace: true });
  };

  return (
    <CompassEmptyState
      className="pt-40"
      titleI18nKey={I18nKey.COMPASS_TEASER_COMING_SOON_TEXT}
      descriptionI18nKey={I18nKey.COMPASS_TEASER_MORE_ANALYSIS_TOOL_COMMING}
      icon={LoadingIcon}
      showPrimaryButton={false}
      primaryButtonI18nKey={I18nKey.COMPASS_TEASER_BUTTON_SUBSCRIBE_FOR_UPDATES}
      footerButtonI18nKey={
        templateType === TemplateType.AO
          ? I18nKey.COMPASS_TEASER_GO_TO_AO
          : I18nKey.COMPASS_TEASER_GO_TO_SKU
      }
      onClickFooterButton={navigateAsPerTemplateType}
      footerTitleI18nKey={
        I18nKey.COMPASS_TEASER_SUBSCRIBE_INTERIM_MESSAGE_FOR_COMING_SOON
      }
      dataTestId="comming-soon-template"
    />
  );
};
CommingSoonTemplate.displayName = 'CommingSoonTemplate';

export default CommingSoonTemplate;
