import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';

import {
  AmazonLogomarkEnabledIcon,
  ComparisonTrend,
  MetricCardsv2Status,
  PaletteColor,
  SelectOptionProps,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';

import { MerchantCountryCode } from '../../lib/types/AOSharedTypes';
import {
  HomepageResponseMetricKeys,
  PerformanceMetrics,
} from '../../lib/types/CompassSharedTypes';
import {
  AllSalesChannel,
  FlywheelSalesChannel,
  MerchantCountry,
  MerchantType,
} from '../../lib/types/Fam';
import I18nKey from '../../lib/types/I18nKey';
import { MoneyWithAmountInString } from '../../lib/types/Money';
import { DataAvailabilityResponse } from '../../lib/types/SKUSharedTypes';
import {
  EUROPE_CURRENCY_SYMBOL,
  getCountryFlagIcon,
} from '../../lib/utilities/countryFlags';
import {
  UserInfo,
  getCompassCatalogCurrencyFromLocalStorage,
  getCompassCatalogDateRangeFromLocalStorage,
  getCompassCatalogMerchantsFromLocalStorage,
} from './storageUtils';
import {
  COMPASS_API_REQUEST_DATE_FORMAT,
  DEFAULT_MAX_FRACTION_DIGITS,
  DateRange,
  METRIC_TYPE_TO_DECIMAL_PLACE_LIMIT,
  MerchantCountriesWithCountryCodes,
  Metric,
  MetricType,
  PERCENTAGE_DECIMAL_PLACE_LIMIT,
  SalesChannelsWithMerchantCountries,
} from './types';
import uniq from 'lodash/uniq';
import { CurrencyCode, getCurrencySymbol } from '../../lib/utilities/currency';

export const LAST_SEVEN_DAYS = 6;
export const LAST_FOURTEEN_DAYS = 13;
export const LAST_THIRTY_DAYS = 29;
export const LAST_SIXTY_DAYS = 59;
export const LAST_NINETY_DAYS = 89;

const today = DateTime.local().startOf('day');
const yesterday = today.minus({ day: 1 });

const lastSevenDays = yesterday.minus({ days: LAST_SEVEN_DAYS });
const lastFourteenDays = yesterday.minus({ days: LAST_FOURTEEN_DAYS });
const lastThirtyDays = yesterday.minus({ days: LAST_THIRTY_DAYS });
const lastSixtyDays = yesterday.minus({ days: LAST_SIXTY_DAYS });
const lastNintyDays = yesterday.minus({ days: LAST_NINETY_DAYS });
const yearToDate = yesterday.startOf('year');

export const presets = [
  yearToDate,
  lastNintyDays,
  lastSixtyDays,
  lastThirtyDays,
  lastFourteenDays,
  lastSevenDays,
];
export const convertAmountToNumber = (metric: Metric): number => {
  const amount = (metric as MoneyWithAmountInString).amount;
  return parseFloat(amount);
};

export const getPillTextBasedOnDelta = (
  delta: number,
  intl: IntlShape
): string => {
  return `${delta > 0 ? '+' : ''}${intl.formatNumber(delta, {
    style: 'percent',
    minimumFractionDigits: PERCENTAGE_DECIMAL_PLACE_LIMIT,
    maximumFractionDigits: PERCENTAGE_DECIMAL_PLACE_LIMIT,
  })}`;
};

export const calculateDelta = (
  metricType: MetricType,
  currentValue: Metric | null,
  previousValue: Metric | null
): number => {
  const maxDecimalPlaces = METRIC_TYPE_TO_DECIMAL_PLACE_LIMIT[metricType];
  if (isNil(previousValue) || isNil(currentValue)) return 0;
  let currentNumericValue = -1;
  let previousNumericValue = -1;

  if (metricType !== MetricType.Money) {
    currentNumericValue = currentValue as number;
    previousNumericValue = previousValue as number;
  }

  switch (metricType) {
    case MetricType.Money:
      const previousAmount = convertAmountToNumber(previousValue);
      const divideBy = previousAmount === 0 ? 1 : Math.abs(previousAmount);

      return (convertAmountToNumber(currentValue) - previousAmount) / divideBy;
    case MetricType.Percent:
      return (
        (Number((currentNumericValue * 100).toFixed(maxDecimalPlaces)) -
          Number((previousNumericValue * 100).toFixed(maxDecimalPlaces))) /
        (Number((previousNumericValue * 100).toFixed(maxDecimalPlaces)) === 0
          ? 1
          : Number(
              (Math.abs(previousNumericValue) * 100).toFixed(maxDecimalPlaces)
            ))
      );
    case MetricType.Numeric:
      return (
        ((currentNumericValue - previousNumericValue) * 100) /
        (previousNumericValue === 0 ? 1 : previousNumericValue)
      );
  }
};

export const getPositiveUpTrendBasedOnDelta = (
  delta: number
): MetricCardsv2Status => {
  if (delta > 0) {
    return MetricCardsv2Status.Positive;
  } else if (delta < 0) {
    return MetricCardsv2Status.Negative;
  } else {
    return MetricCardsv2Status.Neutral;
  }
};

export const getNegativeUpTrendBasedOnDelta = (
  delta: number
): MetricCardsv2Status => {
  if (delta > 0) {
    return MetricCardsv2Status.Negative;
  } else if (delta < 0) {
    return MetricCardsv2Status.Positive;
  } else {
    return MetricCardsv2Status.Neutral;
  }
};

export const getPositiveUpTrendBasedOnDeltaForInsightsTable = (
  delta: number
): ComparisonTrend => {
  if (delta > 0) {
    return ComparisonTrend.PositiveUp;
  } else if (delta < 0) {
    return ComparisonTrend.NegativeDown;
  } else {
    return ComparisonTrend.NeutralNoChange;
  }
};

export const getNegativeUpTrendBasedOnDeltaForInsightsTable = (
  delta: number
): ComparisonTrend => {
  if (delta > 0) {
    return ComparisonTrend.NegativeUp;
  } else if (delta < 0) {
    return ComparisonTrend.PositiveDown;
  } else {
    return ComparisonTrend.NeutralNoChange;
  }
};

export type CalculateComparisionTrendFunction =
  | ((delta: number) => MetricCardsv2Status)
  | ((delta: number) => ComparisonTrend);

export const getMoneyMetricCardProps = (
  intl: IntlShape,
  calculateComparisionTrendFunction: CalculateComparisionTrendFunction,
  current?: MoneyWithAmountInString,
  previous?: MoneyWithAmountInString
) => {
  const currentMoneyValue = !current?.amount || isNil(current) ? null : current;
  const previousMoneyValue =
    !previous?.amount || isNil(previous) ? null : previous;

  const delta = calculateDelta(
    MetricType.Money,
    currentMoneyValue,
    previousMoneyValue
  );
  const pillText = getPillTextBasedOnDelta(delta, intl);
  const comparisonTrend = calculateComparisionTrendFunction(delta);

  const currentValue = isNil(currentMoneyValue)
    ? null
    : intl.formatNumber(parseFloat(currentMoneyValue.amount), {
        style: 'currency',
        minimumFractionDigits: DEFAULT_MAX_FRACTION_DIGITS,
        maximumFractionDigits: DEFAULT_MAX_FRACTION_DIGITS,
        currency: currentMoneyValue.currency,
      });

  const previousValue = isNil(previousMoneyValue)
    ? null
    : intl.formatNumber(parseFloat(previousMoneyValue.amount), {
        style: 'currency',
        minimumFractionDigits: DEFAULT_MAX_FRACTION_DIGITS,
        maximumFractionDigits: DEFAULT_MAX_FRACTION_DIGITS,
        currency: previousMoneyValue.currency,
      });

  return { pillText, comparisonTrend, currentValue, previousValue };
};

export const getPercentMetricCardProps = (
  intl: IntlShape,
  calculateComparisionTrendFunction: CalculateComparisionTrendFunction,
  current?: number,
  previous?: number
) => {
  const currentPercentValue = isNil(current) ? null : current;
  const previousPercentValue = isNil(previous) ? null : previous;

  const delta = calculateDelta(
    MetricType.Percent,
    currentPercentValue,
    previousPercentValue
  );
  const pillText = getPillTextBasedOnDelta(delta, intl);
  const comparisonTrend = calculateComparisionTrendFunction(delta);

  const currentValue = isNil(currentPercentValue)
    ? null
    : intl.formatNumber(currentPercentValue, {
        minimumFractionDigits: PERCENTAGE_DECIMAL_PLACE_LIMIT,
        maximumFractionDigits: PERCENTAGE_DECIMAL_PLACE_LIMIT,
        style: 'percent',
      });

  const previousValue = isNil(previousPercentValue)
    ? null
    : intl.formatNumber(previousPercentValue, {
        minimumFractionDigits: PERCENTAGE_DECIMAL_PLACE_LIMIT,
        maximumFractionDigits: PERCENTAGE_DECIMAL_PLACE_LIMIT,
        style: 'percent',
      });

  return { pillText, comparisonTrend, currentValue, previousValue };
};

const getNumericMetricCardProp = (
  intl: IntlShape,
  calculateComparisionTrendFunction: CalculateComparisionTrendFunction,
  current?: number,
  previous?: number
) => {
  const currentNumericValue = isNil(current) ? null : current;
  const previousNumericValue = isNil(previous) ? null : previous;

  const delta = calculateDelta(
    MetricType.Percent,
    currentNumericValue,
    previousNumericValue
  );
  const pillText = getPillTextBasedOnDelta(delta, intl);
  const comparisonTrend = calculateComparisionTrendFunction(delta);

  const currentValue = isNil(currentNumericValue)
    ? null
    : intl.formatNumber(currentNumericValue, {
        minimumFractionDigits: PERCENTAGE_DECIMAL_PLACE_LIMIT,
        maximumFractionDigits: PERCENTAGE_DECIMAL_PLACE_LIMIT,
        style: 'decimal',
      });

  const previousValue = isNil(previousNumericValue)
    ? null
    : intl.formatNumber(previousNumericValue, {
        minimumFractionDigits: PERCENTAGE_DECIMAL_PLACE_LIMIT,
        maximumFractionDigits: PERCENTAGE_DECIMAL_PLACE_LIMIT,
        style: 'decimal',
      });

  return { pillText, comparisonTrend, currentValue, previousValue };
};

export const getMetricsData = (
  intl: IntlShape,
  currentPerformanceData?: PerformanceMetrics,
  previousPerformanceData?: PerformanceMetrics
) => {
  const totalSales = getMoneyMetricCardProps(
    intl,
    getPositiveUpTrendBasedOnDelta,
    currentPerformanceData?.[HomepageResponseMetricKeys.TotalSales],
    previousPerformanceData?.[HomepageResponseMetricKeys.TotalSales]
  );

  const adSales = getMoneyMetricCardProps(
    intl,
    getPositiveUpTrendBasedOnDelta,
    currentPerformanceData?.[HomepageResponseMetricKeys.AdSales],
    previousPerformanceData?.[HomepageResponseMetricKeys.AdSales]
  );

  const adSpend = getMoneyMetricCardProps(
    intl,
    getPositiveUpTrendBasedOnDelta,
    currentPerformanceData?.[HomepageResponseMetricKeys.AdSpend],
    previousPerformanceData?.[HomepageResponseMetricKeys.AdSpend]
  );

  const tacos = getPercentMetricCardProps(
    intl,
    getNegativeUpTrendBasedOnDelta,
    currentPerformanceData?.[HomepageResponseMetricKeys.Tacos],
    previousPerformanceData?.[HomepageResponseMetricKeys.Tacos]
  );

  const roas = getNumericMetricCardProp(
    intl,
    getPositiveUpTrendBasedOnDelta,
    currentPerformanceData?.[HomepageResponseMetricKeys.Roas],
    previousPerformanceData?.[HomepageResponseMetricKeys.Roas]
  );

  return {
    totalSales,
    adSales,
    adSpend,
    tacos,
    roas,
  };
};

export const mapMerchantCountriesToSalesChannelsIds = (
  merchantCountries: MerchantCountry[]
) => {
  const salesChannelIdsWithMerchantCountries: SalesChannelsWithMerchantCountries =
    {};

  merchantCountries.forEach((merchantCountry) => {
    if (merchantCountry.salesChannelId) {
      if (
        !salesChannelIdsWithMerchantCountries[merchantCountry.salesChannelId]
      ) {
        salesChannelIdsWithMerchantCountries[merchantCountry.salesChannelId] =
          [];
      }
      salesChannelIdsWithMerchantCountries[merchantCountry.salesChannelId].push(
        merchantCountry.merchantCountryId
      );
    }
  });
  return salesChannelIdsWithMerchantCountries;
};

export const mapMerchantCountriesToCountryCode = (
  merchantCountries: MerchantCountry[] | undefined
) => {
  const merchatCountryCodes: MerchantCountriesWithCountryCodes = {};
  if (merchantCountries) {
    merchantCountries.forEach((merchantCountry) => {
      merchatCountryCodes[merchantCountry.merchantCountryId] =
        merchantCountry.country;
    });
  }
  return merchatCountryCodes;
};

export const getPreviousMerchantSelection = (
  merchantCountryIds: string[],
  userInfo: UserInfo
): string[] => {
  const selectedMerchantsIdFromLocalStorage =
    getCompassCatalogMerchantsFromLocalStorage(userInfo);

  if (selectedMerchantsIdFromLocalStorage) {
    return selectedMerchantsIdFromLocalStorage.filter((merchantCountryId) =>
      merchantCountryIds.includes(merchantCountryId)
    );
  }

  // Default selection should be all merchantCountries.
  return merchantCountryIds;
};

export const getMerchantCountriesSelection = (
  merchants: MerchantCountry[],
  merchantCountryIds: string[]
): string[] => {
  const countryCodes: string[] = [];
  merchants.forEach((merchant) => {
    if (merchantCountryIds.includes(merchant.merchantCountryId)) {
      countryCodes.push(merchant.country);
    }
  });
  return uniq(countryCodes).sort((a, b) => a.localeCompare(b));
};

export const getPreviousCurrencySelection = (
  currency: CurrencyCode,
  userInfo: UserInfo
): CurrencyCode => {
  const selectedCurrencyFromLocalStorage =
    getCompassCatalogCurrencyFromLocalStorage(userInfo);

  return selectedCurrencyFromLocalStorage ?? currency;
};

export const getPreviousDateSelection = (
  dateRange: DateRange,
  userInfo: UserInfo
): DateRange => {
  const selectedDateRangeFromLocalStorage =
    getCompassCatalogDateRangeFromLocalStorage(userInfo);

  if (
    !isNil(selectedDateRangeFromLocalStorage) &&
    presets.some(
      (date) =>
        +selectedDateRangeFromLocalStorage?.startDate === +date &&
        +selectedDateRangeFromLocalStorage?.endDate === +yesterday
    )
  ) {
    return {
      ...selectedDateRangeFromLocalStorage,
    };
  }

  return dateRange;
};

export const getEarliestSyncDateForSelectedMerchants = (
  merchantIds: string[],
  dataAvailabilityResponse?: DataAvailabilityResponse
): DateTime | undefined => {
  const todayLocal = DateTime.local();
  let minDate = todayLocal;

  if (
    isNil(dataAvailabilityResponse) ||
    dataAvailabilityResponse?.syncPerMerchantIds.length === 0
  ) {
    return;
  }

  const selectedMerchantsSyncInfo =
    dataAvailabilityResponse.syncPerMerchantIds.filter((syncPerMerchant) =>
      merchantIds.includes(syncPerMerchant.merchantCountryId)
    );

  for (const syncInfo of selectedMerchantsSyncInfo) {
    const date = DateTime.fromFormat(
      syncInfo.earliestAvailableDate,
      COMPASS_API_REQUEST_DATE_FORMAT
    ).toLocal();

    if (date < minDate) {
      minDate = date as DateTime<true>;
    }
  }
  const hasMinDateNotModified =
    minDate.hasSame(todayLocal, 'day') &&
    minDate.hasSame(todayLocal, 'month') &&
    minDate.hasSame(todayLocal, 'year');

  return hasMinDateNotModified ? undefined : minDate;
};

export const getPossibleStartAndEndDateBySyncDate = (
  dateRange: DateRange,
  minDate?: DateTime
): DateRange => {
  const today = DateTime.local().startOf('day');
  const yesterday = today.minus({ day: 1 });

  const lastSevenDays = yesterday.minus({ days: LAST_SEVEN_DAYS });
  const lastFourteenDays = yesterday.minus({ days: LAST_FOURTEEN_DAYS });
  const lastThirtyDays = yesterday.minus({ days: LAST_THIRTY_DAYS });
  const lastSixtyDays = yesterday.minus({ days: LAST_SIXTY_DAYS });
  const lastNintyDays = yesterday.minus({ days: LAST_NINETY_DAYS });
  const yearToDate = yesterday.startOf('year');

  const presets = [
    yearToDate,
    lastNintyDays,
    lastSixtyDays,
    lastThirtyDays,
    lastFourteenDays,
    lastSevenDays,
  ];

  let newDateRange: DateRange = { ...dateRange };

  // current start date is greater than the min date then return the same
  if (minDate && dateRange.startDate > minDate) {
    return {
      ...newDateRange,
      minDate,
    };
  }

  for (const date of presets) {
    if (minDate && minDate < date) {
      newDateRange = { ...dateRange, startDate: date };
      break;
    }
  }

  // chosen predefined date range is greater than min date
  if (minDate && minDate > newDateRange.startDate) {
    newDateRange = { ...dateRange, startDate: minDate };
  }

  // current end date is less than the new min date, return yesterday as new end date
  if (
    (minDate && dateRange.endDate < minDate) ||
    newDateRange.endDate < newDateRange.startDate
  ) {
    newDateRange = { ...newDateRange, endDate: yesterday };
  }

  return {
    ...newDateRange,
    minDate,
  };
};

export const SALES_CHANNEL_NAME_TO_I18NKEY_MAPPER = {
  [FlywheelSalesChannel.Amazon]: I18nKey.ADVERTISING_OPTIMIZATION_AMAZON,
  [FlywheelSalesChannel.Walmart]: I18nKey.ADVERTISING_OPTIMIZATION_WALMART,
};

export const SALES_CHANNEL_TO_ICON_MAPPER = {
  [FlywheelSalesChannel.Amazon]: AmazonLogomarkEnabledIcon,
  [FlywheelSalesChannel.Walmart]: WalmartLogomarkEnabledIcon,
};

export const createMerchantDropdownOptionData = (
  merchant: MerchantCountry,
  salesChannels: AllSalesChannel[]
): Pick<SelectOptionProps<string>, 'groupName' | 'secondaryIcon' | 'icon'> => {
  const maybeMerchantSalesChannel = salesChannels.find(
    (salesChannel) => salesChannel.id === merchant.salesChannelId
  );

  return {
    groupName: !isUndefined(maybeMerchantSalesChannel)
      ? SALES_CHANNEL_NAME_TO_I18NKEY_MAPPER[
          maybeMerchantSalesChannel.name as FlywheelSalesChannel
        ]
      : undefined,
    icon: !isUndefined(maybeMerchantSalesChannel)
      ? SALES_CHANNEL_TO_ICON_MAPPER[
          maybeMerchantSalesChannel.name as FlywheelSalesChannel
        ]
      : undefined,
    secondaryIcon: !isUndefined(maybeMerchantSalesChannel)
      ? getCountryFlagIcon(merchant.country)
      : undefined,
  };
};

export const generateMerchantPickerOptions = (
  merchants: MerchantCountry[],
  salesChannels: AllSalesChannel[],
  intl: IntlShape
): SelectOptionProps<string>[] => {
  return merchants.map((merchant) => {
    const { groupName, icon, secondaryIcon } = createMerchantDropdownOptionData(
      merchant,
      salesChannels
    );
    return {
      value: merchant.merchantCountryId,
      label: merchant.merchantName,
      groupName: groupName && intl.formatMessage({ id: groupName }),
      icon,
      secondaryIcon,
      pillProps: {
        text: merchant.merchantType === MerchantType.Seller ? '3P' : '1P',
        color: PaletteColor.grey,
        className: 'ml-8',
      },
    };
  });
};

export const getCurrencyPickerOptions = (): SelectOptionProps<string>[] => {
  const SUPPORTED_CURRENCIES: Array<string> = [
    CurrencyCode.USD,
    CurrencyCode.CAD,
    CurrencyCode.MXN,
    CurrencyCode.BRL,
    CurrencyCode.GBP,
    CurrencyCode.EUR,
    CurrencyCode.JPY,
    CurrencyCode.AUD,
    CurrencyCode.SGD,
    CurrencyCode.AED,
    CurrencyCode.INR,
  ];
  const SUPPORTED_FLAG_BY_CURRENCY: Record<
    string,
    MerchantCountryCode | string
  > = {
    [CurrencyCode.USD]: MerchantCountryCode.US,
    [CurrencyCode.CAD]: MerchantCountryCode.CA,
    [CurrencyCode.MXN]: MerchantCountryCode.MX,
    [CurrencyCode.BRL]: MerchantCountryCode.BR,
    [CurrencyCode.GBP]: MerchantCountryCode.GB,
    [CurrencyCode.EUR]: EUROPE_CURRENCY_SYMBOL,
    [CurrencyCode.JPY]: MerchantCountryCode.JP,
    [CurrencyCode.AUD]: MerchantCountryCode.AU,
    [CurrencyCode.SGD]: MerchantCountryCode.SG,
    [CurrencyCode.AED]: MerchantCountryCode.AE,
    [CurrencyCode.INR]: MerchantCountryCode.IN,
  };
  return SUPPORTED_CURRENCIES.map((code) => ({
    label: `${getCurrencySymbol(code)} ${code}`, // $ USD
    value: code,
    icon: getCountryFlagIcon(SUPPORTED_FLAG_BY_CURRENCY[code]),
  }));
};
