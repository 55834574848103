import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  Placement,
  SelectState,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeSelectColumn,
  makeTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  AdGroupUseCaseType,
  AdLevel,
  AiRecommendedKeywordType,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import {
  ADGROUP_AI_RECOMMENDATION_KEYWORD_TYPE,
  AdLevelI8nKeyMapper,
  TableDataAdsManager,
} from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
  TABLE_UNIQ_KEY,
  getTooltipContentForArchivedEntity,
  isAdGroupStatusArchived,
  isCampaignStatusArchived,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import {
  getCurrentRowIndex,
  getLatestLabelValues,
  getTargetTypes,
  isStandAloneAutoTargetType,
} from './utils';

const NOT_AVAILABLE = 'not_available';

const getColumn = (
  intl: IntlShape,
  details: AdGroupDetails,
  isAdGroupOfTypePAT: boolean,
  isItStandAloneAutoTargetType: boolean
) => {
  if (isAdGroupOfTypePAT || isItStandAloneAutoTargetType) {
    return intl.formatMessage({
      id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NOT_AVAILABLE,
    });
  } else {
    return details?.adGroupDetails?.aiRecommendationKeywordSetting
      ? intl.formatMessage({
          id: ADGROUP_AI_RECOMMENDATION_KEYWORD_TYPE[
            details?.adGroupDetails?.aiRecommendationKeywordSetting
          ],
        })
      : undefined;
  }
};

const getSelectColumnState = ({
  disabled,
  error,
}: {
  disabled: boolean;
  error: boolean;
}) => {
  if (disabled) {
    return SelectState.Disabled;
  } else if (error) {
    return SelectState.Error;
  }
  return SelectState.Default;
};

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  adGroupId,
  adGroupDetails,
  channelSettings,
  flywheelSettings,
  adGroupPerformance,
  isEditMode,
  pendingFields,
  salesChannel,
  selectedAdType,
  merchantType,
  merchantsWithAutomationEnabled,
}) => {
  const data: AdGroupDetails = {
    adGroupId,
    adGroupDetails,
    channelSettings,
    flywheelSettings,
    adGroupPerformance,
    pendingFields,
  };
  const value = useSelector<WithTable<AdGroupDetails, any>, string>(
    ({ tableState }) =>
      tableSelectors.getCellValueSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
        EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
        TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const currentTargetStatus =
    adGroupDetails.aiRecommendationKeywordSetting as AiRecommendedKeywordType;

  const updatedTagsValue = useSelector<WithTable<AdGroupDetails, any>, string>(
    ({ tableState }) =>
      tableSelectors.getCellValueSelector(
        adGroupId,
        ADGROUPS_API_COLUMN_NAME.Tags,
        EDIT_ADGROUPS_API_COLUMN_DATA_MAPPING,
        TABLE_UNIQ_KEY[ADS_MANAGER_ADGROUP_TABLE_ID]
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );
  const latestTagValues = getLatestLabelValues([], updatedTagsValue);

  const targetTypes = getTargetTypes(adGroupDetails.targetSegments);
  const isItStandAloneAutoTargetType = isStandAloneAutoTargetType(targetTypes);

  const currentRowIndex = useSelector<WithTable<AdGroupDetails>, number>(
    getCurrentRowIndex(data)
  );

  const dispatch = useDispatch();
  const intl = useIntl();

  const automationDisabledForMerchant =
    !merchantsWithAutomationEnabled?.includes(
      adGroupDetails.merchantCountryId ?? ''
    );

  const isAdGroupOfTypePAT =
    adGroupDetails?.adGroupUseCase ===
    AdGroupUseCaseType.ProductAttributeTargeting;

  const isAiRecommendationAutoWithoutTags =
    latestTagValues.length === 0 &&
    value === AiRecommendedKeywordType.AddAutomatically;

  if (isEditMode) {
    const options = getAiRecommendedKeywordOptions(intl, isAdGroupOfTypePAT);

    return makeSelectColumn(
      (details: AdGroupDetails) => ({
        options,
        placeholder: '',
        value: isAdGroupOfTypePAT ? NOT_AVAILABLE : value.toLowerCase(),
        className: 'w-176',
        isDirty:
          details.adGroupDetails.aiRecommendationKeywordSetting?.toLowerCase() !==
          value.toLowerCase(),
        onChange: (inputValue: string) => {
          dispatch(
            tableActions.updateCell({
              tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
              columnName:
                ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
              rowId: data.adGroupId,
              value: inputValue,
              existingValue: currentTargetStatus?.toLocaleLowerCase(),
            })
          );
        },
        state: getSelectColumnState({
          disabled:
            isAdGroupStatusArchived(channelSettings.status) ||
            isCampaignStatusArchived(adGroupDetails.campaignStatus) ||
            automationDisabledForMerchant ||
            isAdGroupOfTypePAT,
          error: isAiRecommendationAutoWithoutTags,
        }),
        showOnTop: currentRowIndex > 6,
      }),
      () => {
        const isAdGroupArchived = isAdGroupStatusArchived(
          channelSettings.status
        );
        const tooltipState =
          isAdGroupArchived || isAiRecommendationAutoWithoutTags
            ? ControlledTooltip.None
            : ControlledTooltip.Hide;

        return {
          tooltipContent: getTooltipContent(
            intl,
            isAdGroupArchived,
            isAdGroupOfTypePAT,
            isAiRecommendationAutoWithoutTags
          ),
          controlledTooltip: tooltipState,
          tooltipPlacement: isAiRecommendationAutoWithoutTags
            ? Placement.Left
            : Placement.Bottom,
          style: ContentStyle.Bold,
        };
      }
    )(data);
  }

  return makeTextColumn(
    (details: AdGroupDetails) =>
      getColumn(
        intl,
        details,
        isAdGroupOfTypePAT,
        isItStandAloneAutoTargetType
      ),
    pendingFields?.includes(
      ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting
    ),
    'normal-case'
  )(data);
};
RowCellElement.displayName = 'AiRecommendedKeyword_RowCellElement';

export const aiRecommendedKeywordColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_AI_RECOMMENDED_KEYWORD,
  RowCellElement,
  gridColumnWidth: '200px',
};

export const aiRecommendedKeywordColumnUpdatedHeader: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_KEYWORDS_COLUMN_RECOMMENDATIONS,
  RowCellElement,
  gridColumnWidth: '200px',
};

const getAiRecommendedKeywordOptions = (
  intl: IntlShape,
  isAdGroupOfTypePAT: boolean
) => {
  if (isAdGroupOfTypePAT) {
    return [
      {
        label: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_AD_LEVEL_TABLE_FILTER_NOT_AVAILABLE,
        }),
        value: NOT_AVAILABLE,
      },
    ];
  }
  return [
    {
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGN_AI_RECOMMENDED_KEYWORD_ADD_AUTOMATICALLY,
      }),
      value: AiRecommendedKeywordType.AddAutomatically,
    },
    {
      label: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGN_AI_RECOMMENDED_KEYWORD_REVIEW_RECOMMENDATION,
      }),
      value: AiRecommendedKeywordType.ReviewRecommendations,
    },
  ];
};

export const aiRecommendedKeywordFilter = () =>
  createMultiSelectDataFieldFilter(
    ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_AI_RECOMMENDED_KEYWORD,
    [
      SelectFilterOption.AddAutomatically,
      SelectFilterOption.ReviewRecommendations,
    ]
  );

const getTooltipContent = (
  intl: IntlShape,
  isArchived: boolean,
  isAdGroupOfTypePAT: boolean,
  isAiRecommendationAutoWithoutTags: boolean
) => {
  if (isArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.AdGroups],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_BID_AUTOMATION_STATUS,
      }),
      intl
    );
  }
  if (isAdGroupOfTypePAT) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_ADGROUP_KEYWORD_RECOMMENDATION_DISABLED_TOOLTIP,
        })}
      </p>
    );
  }
  if (isAiRecommendationAutoWithoutTags) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_ADGROUP_KEYWORD_RECOMMENDATION_ERROR_ADD_TAGS_TOOLTIP,
        })}
      </p>
    );
  }
};
