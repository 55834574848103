import { Factory } from 'fishery';

import { AddTermsTableRowDataType } from '../../../modules/marketIntelligence/containers/settings/types';

export default Factory.define<AddTermsTableRowDataType>(() => ({
  searchTerm: 'Nike',
  selectedCountryValueShort: 'US',
  selectedCountryValueFull: 'United States',
  selectedSalesChannelValue: 'Amazon',
  id: 'bc0f4979-c650-4179-835d-187cd8aed923',
}));
