import { Factory } from 'fishery';
import { AIProductSettingsResponse } from '../../types/AIPoweredSharedTypes';

// @ts-ignore
export default Factory.define<AIProductSettingsResponse>(() => ({
  items: [
    {
      skuDetailsFields: {
        productId: '05acd09b-a321-5ef6-944c-4e8389737381',
        sku: 'NMN_FBA',
        name: 'NMN + Resveratrol Supplement - NAD+ Booster, DNA & Cellular Health, Longevity, Vitality. 5-in-1 Anti Aging Formula w/ Nicotinamide Mononucleotide, Trans Resveratrol, Vitamin C, Grape Seed, Pine Bark',
        imageUrl: 'https://m.media-amazon.com/images/I/31YHPrXsDOL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B09RDS92C8',
            extItemType: 'ASIN',
            salesChannelName: '',
            salesChannelId: '',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '1db3bb62-373c-5348-89c4-5b644e902ad7',
          'ed337668-ad3b-51df-b0c1-331fe1e54982',
        ],
        adGroupIds: [
          '9218533c-9627-5084-a49b-12f1b508aff5',
          '03558513-9b9e-5d23-9911-c094772d947c',
        ],
      },
      performanceMetrics: {
        adSalesTotal: {
          amount: '1299.10',
          currency: 'USD',
        },
        adSpend: {
          amount: '1638.65',
          currency: 'USD',
        },
        acosTotal: 1.2613732584096684,
      },
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '0fd5f07c-48ca-5af4-b8c4-00ad5f1ec7e2',
        sku: 'Multi-Collagen_FBA',
        name: 'Multi Collagen Pills with Hyaluronic Acid and Vitamin C, Biotin - Type I, II, III, V, X Hydrolyzed Collagen Protein; Healthy Hair, Skin, Nails, Joints - Collagen Peptides Capsules for Women & Men',
        imageUrl: 'https://m.media-amazon.com/images/I/31b-O7r1qEL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B084NXMNQT',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          'aae83e92-8a55-5233-af22-8d2767c4accd',
          '31f7756e-86c8-5e83-ae22-f6ba7152ed09',
          '8c2a5bb0-c29b-5e40-8ca1-bfc4fa89e11f',
          '5db4f7bc-35d9-5af9-8f6f-ac1416ad524d',
          'a82c0795-cc42-5bc7-b6e1-5b7b38cd71dd',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          'b8f96659-93fc-5a2a-b835-42628d2033c9',
          'bdea2d85-d0fa-5f0a-a5fd-6e47a07dee40',
          '05036c4c-4ccd-5cf9-a85c-397a85cc44ad',
          'dd64d2dc-0b29-58f5-9810-ea061446ddf9',
        ],
        adGroupIds: [
          '832e41e2-6d17-58b3-988d-4affe6a004e8',
          'bfa0c3de-92c6-5d27-a72b-40bc49d05bab',
          'bd46e00f-8f08-50d1-b01f-37e70da9be64',
          '698b42bb-7b72-5b33-9c7e-f90e33f6abe2',
          'ee4aa103-e769-5e82-89fb-bfd41e83f1a9',
          '286b5594-3ae8-5271-b6b2-ee32e68d53ac',
          '7545ef83-01b6-5afd-b170-8f5a7a52c65e',
          '6b5a40f4-2a49-59ff-b350-ababb3e6eb09',
          '91b044bc-660d-570f-bf92-1221f59c13ac',
          '8f8420f7-a63d-5da4-8485-4a964f4186f5',
        ],
      },
      performanceMetrics: {
        adSalesTotal: {
          amount: '1006.56',
          currency: 'USD',
        },
        adSpend: {
          amount: '817.77',
          currency: 'USD',
        },
        acosTotal: 0.8124403910348117,
      },
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '9a53e4a6-c961-5195-a792-e8b141384068',
        sku: 'NuClarity_FBA',
        name: 'NuClarity - Premium Nootropic Brain Supplement - Focus, Energy, Memory Booster - Mental Clarity & Cognitive Support - Ginkgo Biloba, Bacopa Monnieri, Alpha-GPC, Phosphatidylserine, Rhodiola Rosea',
        imageUrl: 'https://m.media-amazon.com/images/I/41UUHSw0NgL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B075RS7MR8',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '6d94663b-4c4a-5596-839e-afe15324f128',
          'aae83e92-8a55-5233-af22-8d2767c4accd',
          '74b1299e-2fc6-5907-8def-ff6bb8922199',
          '187dc833-332a-508b-88c0-976e1c1855ad',
          '23457bcf-23da-55ce-8104-ceddc33e4e7d',
          'bd1fef4a-119b-5a53-a266-fff1da02a5ef',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          '1b7f82b1-3de9-5b66-8992-1df338f547cd',
          '4a5ea5fc-ace8-5c65-a790-b0605aafb5cc',
          '3cf555ad-48d7-5ff0-945b-926b20216562',
        ],
        adGroupIds: [
          'cd78bbe6-bd0b-5681-a548-b2b51556440f',
          '655b33f6-078a-5594-9483-b82a5666416d',
          '52820f19-2c35-591a-9374-40cebebcabb0',
          'f0c136eb-d461-529b-9af8-a2b0e63e28d2',
          '1890674e-aaf6-53a8-886d-b40bbdd9f9bb',
          '286b5594-3ae8-5271-b6b2-ee32e68d53ac',
          '6b2e422a-7c5c-59be-9b4e-ef3d90a7faca',
          '79bb2662-e166-5786-b27f-0f15f827123d',
          '73243e01-8d42-5cc4-8808-1ade8383712d',
          'cf0505c2-2aa1-5393-9b95-876a74c8267f',
          'a856b4fa-06aa-5821-9eca-0f046b38740c',
        ],
      },
      performanceMetrics: {
        adSalesTotal: {
          amount: '854.13',
          currency: 'USD',
        },
        adSpend: {
          amount: '366.67',
          currency: 'USD',
        },
        acosTotal: 0.42929062320724015,
      },
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'effaba90-95d0-5178-bbd1-4f7e17258944',
        sku: 'Collagen_FBA',
        name: 'Hydrolyzed Collagen Powder - Pure Unflavored Collagen Protein Powder - Type 1 and 3 Collagen Peptides for Women & Men - Grass Fed, Keto, Gluten Free - Supports Healthy Hair, Skin, Nails - 41 Servings',
        imageUrl: 'https://m.media-amazon.com/images/I/4100dj8WDjL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B07BZYLXDZ',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '6c20eef5-ff40-526c-8207-c493bb39e1f4',
          'de2fb015-d456-5d59-a78e-3cea27ee169c',
          'fab0784f-1640-5d47-b9f6-40f9b139f2ca',
          '22865da4-e358-5251-864f-2fd08e7d600d',
          '4b26aa8c-c66d-5a80-aab1-e97cac409c4f',
          '3f67dd07-42f3-5511-a3e1-795e7ce19c79',
          '77be1bf9-5d42-5091-8942-e8b57cf829dd',
          '17df797b-7eb6-5a09-bbac-f38f228be8f4',
          '0858f1c0-adca-58fc-a2d7-a672061288be',
          '45e33d58-d4d7-5039-b4cf-29dbdb0a448c',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          'a0733219-af92-5692-a144-84af64774f3f',
          '8a75d7b5-91bb-5c3f-817e-f4cd2a84fd7f',
          '7627dba1-72b5-5bcc-8ebb-a46f33926f29',
          '823d90cd-59df-58b2-aa24-e1b7002b41b5',
          '3e938657-13ee-5513-950d-0d657a93ac10',
          '93f7c1e8-e81e-5eb1-ac2c-33e8d1b79717',
          '6f8736d6-588e-5823-910c-90d85663b7dc',
          '02bbd285-c751-50a0-8641-f195041aa359',
          '1bda7a63-2faa-5311-aa28-8909d0136cea',
          '04302073-3f61-5035-8e8a-0c730f2a227f',
        ],
        adGroupIds: [
          '165ef945-b311-5e85-b1e6-397b088241f0',
          'c8361f60-023b-5d19-a6c4-5667ac42e0f4',
          '1e7c9aaf-44c7-51bd-95e4-c2b103bdf6a2',
          'c83cacbd-b2a5-5bdf-b136-11fc1f870b32',
          '318bea2b-7ab3-59d7-8e75-7ec8ee78fa06',
          '9f08bef7-e417-5b9f-a305-c0b15c14add0',
          '7545ef83-01b6-5afd-b170-8f5a7a52c65e',
          '94e2ea44-5f23-5977-8d00-241ae5737c96',
          '7cba1526-0028-5318-b173-239617080c2f',
          '7dfb582b-318c-553c-9906-2056c7d0194f',
          'dda8badc-6e3e-53d6-8b9a-c8dc11e1dde9',
          'da757b4e-975b-5b67-9758-a6188f42e436',
          '4cae9173-2f34-59be-9832-40d09fa8b209',
          '254131fc-b331-5a85-bffb-0296ee63ee3f',
          '35450aa0-04df-5451-a35b-c842fa82eaea',
          'd87d20c9-7b4a-5307-b034-7637e28d3b2d',
          'a8fcfa8f-151e-570a-85d5-4430f5bec827',
          'e8dc322d-b05d-53b5-acfa-2d82c06af730',
          '9a36acee-fd63-5d7b-aea7-03c1fa3ebfe4',
          '85fe9faa-0efc-5ad6-9661-d08f51d2b9de',
          '40fab5bd-8770-5bbb-9cbc-54a4d2d3907a',
          '1dcf4f74-16d5-56f3-9e68-41fb9ac107f0',
        ],
      },
      performanceMetrics: {
        adSalesTotal: {
          amount: '543.49',
          currency: 'USD',
        },
        adSpend: {
          amount: '458.35',
          currency: 'USD',
        },
        acosTotal: 0.8433457837310714,
      },
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'f4404697-4705-5edb-93c5-2566c9928dcd',
        sku: 'SeaMoss_FBA',
        name: 'Organic Irish Sea Moss Supplement - Organic Irish Sea Moss + Organic Bladderwrack + Organic Burdock & BioPerine for Max Absorption',
        extItemDetails: [
          {
            extItemId: 'B0B146WT55',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['677e47ff-1eb5-51b7-8aa3-abfbac48b390'],
        adGroupIds: ['cac7164b-7288-5fec-bd6c-2de33bf5097c'],
      },
      performanceMetrics: {
        adSalesTotal: {
          amount: '279.58',
          currency: 'USD',
        },
        adSpend: {
          amount: '522.70',
          currency: 'USD',
        },
        acosTotal: 1.8695900994348669,
      },
      aiPoweredSettings: {
        dailyBudget: {
          amount: '0.00',
          currency: 'USD',
        },
      },
      pendingFields: ['dailyBudget'],
    },
    {
      skuDetailsFields: {
        productId: 'd08a0a49-2408-5f64-a35b-b1c24918f5ce',
        sku: 'K2D3_FBA',
        name: 'Vitamin D3 5000 IU with Vitamin K2 (MK7) Supplement - Premium Immune, Heart & Bone Health Capsules - Vitamin K2 D3 Complex with 5000 IU of Vitamin D3 & 90 mcg of Vitamin K2 mk7 - 60 Count',
        imageUrl: 'https://m.media-amazon.com/images/I/41ZO+edYAaL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B07K1LH5D5',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          'aae83e92-8a55-5233-af22-8d2767c4accd',
          '417f869a-ab5f-531a-a818-99868ba90d32',
          '81edd32f-a7dc-5425-82b6-947d79079f00',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          '9b890ff7-a0d5-5401-908b-9b844b6763fb',
          '8e5d8261-633a-5d31-8fc2-e59d6f0829ba',
          '2d38ea6d-4114-559c-85a2-8e0421a64fd0',
          '867c1fd4-28ef-5d85-a188-cc94cb6da0ad',
        ],
        adGroupIds: [
          'aee5ce41-3072-57cd-be7f-50a31f3b6b99',
          '855bf6d3-01d0-59b4-9460-741b3dcc7ffc',
          '4fdc296d-cade-5d6f-92d0-44d7da82aa42',
          '286b5594-3ae8-5271-b6b2-ee32e68d53ac',
          'a80ed45f-f180-527e-a3b1-b92ce9d48b6e',
          '0ef8304f-9a25-5379-a9f1-b40f9bc99f95',
          '573c4ab7-5954-59af-b08a-1060deca933e',
          '3dfd41bb-9bf9-5bbd-8a4f-2decd76062b1',
          '4cf2baae-dcfb-5daf-9144-eb2f987df5ac',
          'bc04ec11-7122-5aa9-b4c1-a6930342735d',
          '9f10b8eb-2616-5f5e-989f-0a22e319e1c1',
          '3bf0ae28-611d-5dbb-b296-9a94aea4cbe4',
          'd6db184a-f964-5825-9456-030ffec9229d',
        ],
      },
      performanceMetrics: {
        adSalesTotal: {
          amount: '239.55',
          currency: 'USD',
        },
        adSpend: {
          amount: '392.43',
          currency: 'USD',
        },
        acosTotal: 1.6381966186599874,
      },
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'ce486465-d911-5db6-a630-f35ae20a2c10',
        sku: 'Thyroid_FBA',
        name: 'Thyroid Support Complex Supplement - Thyroid Support for Women and Men - Boost Energy, Focus, Metabolism - Iodine Supplements for Thyroid + B12, Selenium, Zinc, Copper, Ashwagandha, L-Tyrosine & More',
        imageUrl: 'https://m.media-amazon.com/images/I/41xYKudXwzL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B07CSHF769',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '325f4f20-c2a9-5e15-b533-0d1eebbd4813',
          'aae83e92-8a55-5233-af22-8d2767c4accd',
          '319d8b18-d42e-59e4-b4eb-7a1f024a7d92',
          '2ca6deb5-d5b2-5ce0-a5d9-b6e600365b0c',
          '4e885b19-5f2c-573b-8784-009863f332de',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          '51f64561-e8d5-5f91-ad29-760f311610aa',
          'a5a57f0e-8848-5969-b414-64e819a8d0a7',
          '7d5b05de-a22d-5773-b0f4-48a625036561',
          'ca08ecd6-b153-579f-98b0-1d874a3fe334',
        ],
        adGroupIds: [
          '7ea066c1-2d31-528a-a8aa-0185bdb5c643',
          '14cc2383-26a8-56e8-a07a-0afc816ea923',
          '18fe8cb0-0add-5605-92e7-b78fb455879d',
          'ac51586d-aeb2-504b-81ab-511e93c38fc5',
          '286b5594-3ae8-5271-b6b2-ee32e68d53ac',
          '95a339d2-7be2-57b2-a505-8152a461c2ed',
          '5f39349a-3939-5446-a720-102ce7685b42',
          '5b41282a-ad4d-54ff-b21c-b22b9fa8812d',
          '7545ef83-01b6-5afd-b170-8f5a7a52c65e',
          '1f299106-3589-547a-b72b-d3fc54713a36',
          'bedda9e5-0078-500c-843f-1f6c2830a050',
        ],
      },
      performanceMetrics: {
        adSalesTotal: {
          amount: '206.67',
          currency: 'USD',
        },
        adSpend: {
          amount: '130.21',
          currency: 'USD',
        },
        acosTotal: 0.6300382251899164,
      },
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'c0e9c5f5-87e0-561c-ae9f-bb0fcac3776c',
        sku: 'EyeHealth_FBA',
        name: 'Eye Health Supplements for Adults - Blue Light Protection & Improved Visual & Mental Clarity; Eye Vitamins for Vision w/ Lutein & Zeaxanthin, Bilberry & More; AREDS2 Vitamins for Macular Degeneration',
        imageUrl: 'https://m.media-amazon.com/images/I/41QzVMXrnBL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B09RDF83GM',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '10217f1b-ac62-5575-ae46-babb357085b0',
          '45fde010-be53-53d8-b299-f75e73546084',
          'a50631a6-3819-5123-aee1-85deab2a81ab',
          'fbff41c9-732a-5298-b3d8-289bda899055',
        ],
        adGroupIds: [
          'd455e414-2335-5c3a-b255-2edadede1d3c',
          'd4abbc19-7253-56f9-8d81-9af1ba7ab17b',
          '1f1bd5aa-2515-5b43-b83c-88fbadc1eb7d',
          'b436ffc8-e504-5287-a17d-80b8daf7c773',
        ],
      },
      performanceMetrics: {
        adSalesTotal: {
          amount: '169.76',
          currency: 'USD',
        },
        adSpend: {
          amount: '414.86',
          currency: 'USD',
        },
        acosTotal: 2.4438030160226205,
      },
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '730d6991-fb69-586e-8ead-629de8f871e4',
        sku: 'NuRest_FBA',
        name: 'Natural Sleep Aid for Adults - Herbal Sleep Aid Supplement - Non Habit Forming Sleeping Pills - Non-Drowsy Formula with Melatonin, Valerian Root, Lemon Balm, Chamomile, GABA',
        imageUrl: 'https://m.media-amazon.com/images/I/41sb5oNwBfL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B077XN74GM',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          'aae83e92-8a55-5233-af22-8d2767c4accd',
          '127dad33-b1a3-5122-a4fd-b4a6fc762330',
          'f15ae4b5-6f21-5539-8706-6ba6a8cd8062',
          'fd4b7c8c-de92-5971-abff-2b6f1a336b7f',
          '6478e563-8c46-5563-9cb4-5875e8c2d044',
          'bd631063-7781-53b9-b2bd-cba84ea24af6',
          'fa225027-518b-5110-a754-764613aaa77c',
          '33e73583-eb79-5d47-9ced-fbbdbe44e965',
        ],
        adGroupIds: [
          'b963084f-3f93-5843-92ed-aacee164bc68',
          '55640b6d-6ea4-54c7-89f8-92fb86dabb30',
          '34e91352-0b10-5873-8d9a-142f57d59e2c',
          'c6ff2924-8da3-5b45-96a6-23a872c9b59c',
          '81f3dd2c-f65a-5a4e-b446-5b6ffb677b13',
          '4742d8e5-f811-5d44-94f2-acf45b963c25',
          '286b5594-3ae8-5271-b6b2-ee32e68d53ac',
          'e5b1e548-c2cf-5b53-86b9-2553df0e9fbd',
          '881af239-56ff-5088-9a0d-c631a7920c54',
        ],
      },
      performanceMetrics: {
        adSalesTotal: {
          amount: '104.79',
          currency: 'USD',
        },
        adSpend: {
          amount: '80.12',
          currency: 'USD',
        },
        acosTotal: 0.7645767725928047,
      },
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '0e2a57ec-48eb-5edd-9c40-c03b00f64319',
        sku: 'Turmeric_FBA',
        name: 'Organic Turmeric & Ginger Capsules - Turmeric Curcumin with Ginger & BioPerine, 95% Curcuminoids, Joint Support & Anti-Inflammation Supplement, Black Pepper Extract for Increased Absorption',
        imageUrl: 'https://m.media-amazon.com/images/I/41W3m0fmSzL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B07BZS9GP8',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '86a0224d-a3e5-55a4-8a92-dca603826f1d',
          'eef77492-4fb7-59a5-b344-1abe57de01d8',
          'f3c950cb-2422-5749-af6e-2e15c192138d',
          'ad85bbc8-39d1-5b7a-bc35-18eedb11b9bc',
          '25303118-b061-57e3-856e-f6f2bf7e5cd8',
          'aae83e92-8a55-5233-af22-8d2767c4accd',
          '5581a378-14f4-53f5-a136-2a3ec172ca11',
          'b031f78c-ff8f-50a8-82c7-610d69b132f2',
          '65bc73e1-c190-575e-9f28-0eb02c885596',
          '4cef0a42-a0f2-5abb-ba56-8229be42abc5',
          '95f9a5b7-d242-5d21-aaad-9419b68d5708',
          'f6539170-6989-50b8-8e53-c9b0047bf52b',
          '1d533ef0-b997-5c58-9346-d94f439cb25a',
          'b435db6d-d976-57bf-b82a-18c735d8e481',
          '069a4809-bcc3-587d-b988-6c24df6ed5c9',
          '0355c423-32e1-5ff1-94f4-424d6a09cd7c',
          'abd71490-d229-5dbf-8672-52d77f7049af',
          '8111eb83-2fce-5364-be0a-0e348a1a0330',
          'e0e4ad94-3d9a-5a32-aa02-46f8431c060e',
          '8b6a6d53-2252-57a9-b34e-747e362e516c',
          '5a7b116d-c7ba-575c-91c3-b61077bab4ab',
          '7043aced-d603-515e-8fd3-ae29697f4f41',
          '9fe41ca4-6143-5433-98a5-c28820e8774b',
          '54618c49-cd3e-59d2-96bc-c6740d142327',
          '6c7e1b38-4954-5934-be64-2223cff1e1d1',
          '0f0a9ac9-9f83-560e-b748-c0b9467d1007',
          'ba3a3426-deeb-5206-8c73-594b4182bd0d',
          'd8c6e243-e35f-58f0-bc74-5463a8ce65b4',
          '3a9905bd-e3dd-5d8a-9908-395443780a1d',
          '0ee66efb-76f0-5385-a969-f2a4f10b46e2',
          '8bb86f23-c5ac-5572-907f-91864d66b316',
          'aeedc20f-b163-5738-9a64-29e8c934becf',
          'ca8f97b9-f6dc-54bc-b624-0950539b9068',
          '33d1736b-59ab-50e9-8058-44c814dac165',
          '26487e6f-725f-508b-aa52-63dc762bb88e',
          '4d887059-0942-53fe-9819-5128c8aeaa29',
          '3bb41ecb-1657-5925-ad4f-b0cdae957b46',
          'f90b393e-ebe0-5f5e-83b4-1cc17d24f705',
          '13c4db58-f1b3-54c9-a574-7b662c3902ad',
          'dbe1df76-d2b1-5c46-ab11-900bb5fb23e0',
          '8e23f4ae-6866-5a97-8145-094769386e25',
          '278da8d9-43db-50c0-a8c1-f9003f4ceaa4',
          '80a7a7c9-b082-553a-a5bb-372892f64bd4',
          '86995fb1-c96c-5735-b08e-8e5e4c5b3591',
          'e48107d1-7756-5620-960d-32825c1c1cd8',
          '88a2c443-76f2-5c36-9573-c6e9f68fe1c2',
          'c87bd5fe-563c-5489-87db-9dd2e6b5c67a',
          '8d6a0b04-152b-51c4-959c-e70b161ec6f0',
          '8de57c59-c2bc-593a-ba1e-e9bcedef9209',
        ],
        adGroupIds: [
          '2a7405b5-1010-52b5-a628-e0bb5313dae5',
          'dd123392-fc72-5043-a9bc-3c4ca8d5401f',
          'f406a413-366b-53df-888c-768c8fd36260',
          'de5fa8d0-f86f-5ca3-b092-bd9a0ba92e76',
          'ac36db14-714b-51a1-86a9-0fe632158108',
          '180c9782-c7f1-59f8-8b6c-7ce304241336',
          '7f57f1f7-d1fe-5d12-b369-5a957f74a1de',
          'e691ec9a-bf02-5647-b330-34c33c34e315',
          '7f3794ca-0c9f-512a-ba1b-c0cda8308ab0',
          '0f61de67-6217-5977-b040-2e574545162b',
          'ce0048af-0aeb-566f-aad3-4365f26629e4',
          '19ae8481-f4cb-57e4-a4d5-8d117709bfed',
          'e9880bd4-6b9d-50e7-9457-d18076e742b5',
          '6a4c1c5a-2c58-5c52-8d7b-54bbdb0e6c71',
          'c9d13776-4eb3-54e2-8e87-814687197b0b',
          '1f21ada3-8a43-53cd-8193-7b195e729d51',
          '2936a78a-3458-554b-977f-876f128fc618',
          '1e237506-29d1-55a2-896f-e1a3d2eef2df',
          '7752fad0-caa0-50b7-9454-04c3c7342a98',
          'de10eb57-d370-5708-bf6d-71a5cb85f546',
          '5005357a-6e51-5f34-a061-8e0c1a50af92',
          '423ae208-6984-539b-9928-18505da4d8c9',
          'fa8182b8-af80-5ce9-a1ed-d7dc6449f415',
          '458c71dd-08b7-5e8c-a0d6-cf9ab080f0e2',
          'ce87bfab-4216-53eb-99e9-7b4e1f6ccc5e',
          'cc10cb6b-8af6-5840-afe8-4b142194bde0',
          '286b5594-3ae8-5271-b6b2-ee32e68d53ac',
          '5849499c-b54b-5e5d-89df-7b9e260212c9',
          '682be8cf-e904-508c-9e61-94282922494d',
          '65929248-995c-553c-8b28-ef16a09d4153',
          'b1cdf8e9-889a-5ffa-80d6-6aedef3d218e',
          'c58a452c-083e-5f2b-8e20-e8e56ecf3cf4',
          'd7f9e013-610a-5c92-9044-74ab3779112a',
          '918ebed4-3b8f-5085-aed3-2272d4464d65',
          '799c56ef-fdc4-558d-a8fc-eeddf5655bab',
          'eb99fc4e-bf55-5727-9db5-0387d78586c7',
          '81337f75-e3b4-5b9a-a280-d9004fea62d5',
          '6ace7fe8-8c02-5d6c-8931-e25f13e2841c',
          '9718e299-d934-5239-9d79-788c1a3be218',
          '7672d81c-b5cb-5c33-a4f8-88cbc2fdadb9',
          '1358c64d-1f60-5d38-9906-a73caddcce4c',
          'a521e22c-e596-5642-b3aa-e5b8a5e323c3',
          '7847ba82-f4f6-5613-b568-0fa05a05e481',
          '1152fdcc-21fc-57f2-8f33-4df7501271ab',
          'f6f515f9-1d11-5e9c-84d6-4b6d78b122ff',
          '47c433cf-d345-5114-adbc-61ae76050fbb',
          'ed441d7e-73a5-5420-947b-8afe9fb881f5',
          '907e8f13-8042-5d32-8e0b-60a4875e741b',
          '49d9e0c4-61fd-5998-be3f-0a82f78c0032',
          '8fac5be2-08ea-59a4-a69d-cf873253f3b6',
        ],
      },
      performanceMetrics: {
        adSalesTotal: {
          amount: '85.85',
          currency: 'USD',
        },
        adSpend: {
          amount: '62.99',
          currency: 'USD',
        },
        acosTotal: 0.7337216074548633,
      },
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'a9e712ad-2c10-58aa-ba8b-bec671dcaf19',
        sku: 'NuBoost_FBA',
        name: 'NuBoost - All Natural Caffeine Pills with L-Theanine + BioPerine - Premium Sustained Energy & Focus Supplement - Nootropic Brain Booster for Smooth Instant Energy - No Crash, No Jitters',
        imageUrl: 'https://m.media-amazon.com/images/I/41Oy9hz6FxL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B076Z59SWZ',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          'aae83e92-8a55-5233-af22-8d2767c4accd',
          '833fde86-be00-5563-add9-7de492de31f8',
          'efbf2be4-5b9e-53c7-9b05-1a7e3398b012',
          '82cd716e-981a-5f8b-96c7-d24bb361d929',
          '4a0adeb3-801a-5ffa-ae85-09913310078a',
          'b18e8cfe-6679-5cf2-adc2-65e5a3f53371',
          '81df95c6-91ea-5f98-ab6f-40d6dd11740a',
          '97540359-35c1-5a6d-bf8b-854c4a9912b6',
          'c1f02dc5-6711-5852-af50-f9ff7c46bf74',
        ],
        adGroupIds: [
          '74b6e866-98e0-5c8d-add2-34516ef37e2d',
          'f6de42b5-a895-5683-8488-3d069e554966',
          '22cd9c90-6a73-526e-81f6-96d9766b36d5',
          '0427658d-413b-54e5-9f64-6ab56bbec00a',
          '78fba52b-84cb-5f51-92f0-cd187c2b1f5d',
          'c30cc56b-706c-58d5-806e-687271837e54',
          'ed9a22c9-aa37-5411-b219-470bc40a968a',
          '286b5594-3ae8-5271-b6b2-ee32e68d53ac',
          'd5c48d50-3a7d-5551-a66c-70980013a6a7',
          'b7f585bc-a99f-511f-8da4-492e979cef22',
        ],
      },
      performanceMetrics: {
        adSalesTotal: {
          amount: '13.97',
          currency: 'USD',
        },
        adSpend: {
          amount: '88.02',
          currency: 'USD',
        },
        acosTotal: 6.3006442376521115,
      },
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '36355541-9072-5dec-957f-715208a570fc',
        sku: 'Collagen_FBM',
        name: 'Hydrolyzed Collagen Powder - Pure Unflavored Collagen Protein Powder - Type 1 and 3 Collagen Peptides for Women & Men - Grass Fed, Keto, Gluten Free - Supports Healthy Hair, Skin, Nails - 41 Servings',
        imageUrl: 'https://m.media-amazon.com/images/I/4100dj8WDjL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B07BZYLXDZ',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '6c20eef5-ff40-526c-8207-c493bb39e1f4',
          'de2fb015-d456-5d59-a78e-3cea27ee169c',
          'fab0784f-1640-5d47-b9f6-40f9b139f2ca',
          '3f67dd07-42f3-5511-a3e1-795e7ce19c79',
          '77be1bf9-5d42-5091-8942-e8b57cf829dd',
          '0858f1c0-adca-58fc-a2d7-a672061288be',
          '45e33d58-d4d7-5039-b4cf-29dbdb0a448c',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          'a0733219-af92-5692-a144-84af64774f3f',
          '8a75d7b5-91bb-5c3f-817e-f4cd2a84fd7f',
          '7627dba1-72b5-5bcc-8ebb-a46f33926f29',
          '823d90cd-59df-58b2-aa24-e1b7002b41b5',
          '3e938657-13ee-5513-950d-0d657a93ac10',
          '93f7c1e8-e81e-5eb1-ac2c-33e8d1b79717',
          '02bbd285-c751-50a0-8641-f195041aa359',
          '04302073-3f61-5035-8e8a-0c730f2a227f',
        ],
        adGroupIds: [
          'c8361f60-023b-5d19-a6c4-5667ac42e0f4',
          '1e7c9aaf-44c7-51bd-95e4-c2b103bdf6a2',
          '318bea2b-7ab3-59d7-8e75-7ec8ee78fa06',
          '9f08bef7-e417-5b9f-a305-c0b15c14add0',
          '7545ef83-01b6-5afd-b170-8f5a7a52c65e',
          '94e2ea44-5f23-5977-8d00-241ae5737c96',
          '7cba1526-0028-5318-b173-239617080c2f',
          '7dfb582b-318c-553c-9906-2056c7d0194f',
          'da757b4e-975b-5b67-9758-a6188f42e436',
          '4cae9173-2f34-59be-9832-40d09fa8b209',
          '254131fc-b331-5a85-bffb-0296ee63ee3f',
          'a8fcfa8f-151e-570a-85d5-4430f5bec827',
          'e8dc322d-b05d-53b5-acfa-2d82c06af730',
          '9a36acee-fd63-5d7b-aea7-03c1fa3ebfe4',
          '85fe9faa-0efc-5ad6-9661-d08f51d2b9de',
          '40fab5bd-8770-5bbb-9cbc-54a4d2d3907a',
          '1dcf4f74-16d5-56f3-9e68-41fb9ac107f0',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'a6ef062e-278f-5d0c-a994-c9f1fe57495c',
        sku: 'B084NXMNQT',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '03c78fbc-553e-55d5-a39c-f6d497c4b835',
        sku: 'B07CSHF769',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'a1b8b4bc-6cf9-5068-acc5-717ac7aa213d',
        sku: 'B086H46BN6',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '51a65b7b-4bcb-52bc-a886-2d6b987300b0',
        sku: 'B0899NFQR9',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '7e9b3f2d-55ad-5b79-b4fa-fc7e472b350f',
        sku: 'I3-YUR3-QJ5H',
        name: 'Brain Booster for Adults - NuClarity: Nootropics Brain Support Supplement | Focus Supplements for Adults | w/ Ginkgo Biloba, Bacopa Monnieri, Alpha-GPC, Rhodiola Rosea, Phosphatidylserine',
        imageUrl: 'https://m.media-amazon.com/images/I/31wnxiV7q3L._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B09WLYCXHG',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          'a8e81d50-32af-5c06-9496-6d23ba4e077b',
          '9ba9ac28-6c94-5363-bd1b-55c36abae6c5',
          'fa9f8714-8e10-5c6e-a7c4-00507b3b58ec',
          'bb84f023-3a9d-5e87-9ffb-77c481bd52a8',
          '28d4e3a8-e622-5563-a459-b4f80edc14b9',
        ],
        adGroupIds: [
          'a036efc0-9050-506a-8cc9-53fe3f453d06',
          '7afcb30b-0dc4-5929-befd-931c5d02ec30',
          '1453f7c1-6e6f-5ee3-9221-23d481a1b991',
          'df097f74-ad24-5e80-bef7-ee092122ff99',
          'a875df8a-1f5c-5d38-9e94-2d3c6b471c39',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '43af15a7-fd5a-561d-a738-3c13939217f9',
        sku: 'B07GX86WM7',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '66cce994-c10c-5b89-bcd4-69ac0043c19d',
        sku: 'Thyroid_FBM',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['51f64561-e8d5-5f91-ad29-760f311610aa'],
        adGroupIds: [
          '7ea066c1-2d31-528a-a8aa-0185bdb5c643',
          'ac51586d-aeb2-504b-81ab-511e93c38fc5',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '1f39d761-fd20-52ec-8f19-798dbcdb2908',
        sku: 'NuRest_FBM',
        name: 'Natural Sleep Aid for Adults - Herbal Sleep Aid Supplement - Non Habit Forming Sleeping Pills - Non-Drowsy Formula with Melatonin, Valerian Root, Lemon Balm, Chamomile, GABA',
        imageUrl: 'https://m.media-amazon.com/images/I/41sb5oNwBfL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B077XN74GM',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '127dad33-b1a3-5122-a4fd-b4a6fc762330',
          'f15ae4b5-6f21-5539-8706-6ba6a8cd8062',
          'fd4b7c8c-de92-5971-abff-2b6f1a336b7f',
          'bd631063-7781-53b9-b2bd-cba84ea24af6',
          'fa225027-518b-5110-a754-764613aaa77c',
          '33e73583-eb79-5d47-9ced-fbbdbe44e965',
        ],
        adGroupIds: [
          '55640b6d-6ea4-54c7-89f8-92fb86dabb30',
          '34e91352-0b10-5873-8d9a-142f57d59e2c',
          'c6ff2924-8da3-5b45-96a6-23a872c9b59c',
          '81f3dd2c-f65a-5a4e-b446-5b6ffb677b13',
          '4742d8e5-f811-5d44-94f2-acf45b963c25',
          '881af239-56ff-5088-9a0d-c631a7920c54',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '57adba36-16b2-5224-98f1-8bc4da0400a9',
        sku: 'MultiCollagen_FBM',
        name: 'Multi Collagen Pills with Hyaluronic Acid and Vitamin C, Biotin - Type I, II, III, V, X Hydrolyzed Collagen Protein; Healthy Hair, Skin, Nails, Joints - Collagen Peptides Capsules for Women & Men',
        imageUrl: 'https://m.media-amazon.com/images/I/31b-O7r1qEL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B084NXMNQT',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '31f7756e-86c8-5e83-ae22-f6ba7152ed09',
          '8c2a5bb0-c29b-5e40-8ca1-bfc4fa89e11f',
          '5db4f7bc-35d9-5af9-8f6f-ac1416ad524d',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          'bdea2d85-d0fa-5f0a-a5fd-6e47a07dee40',
          'dd64d2dc-0b29-58f5-9810-ea061446ddf9',
        ],
        adGroupIds: [
          '832e41e2-6d17-58b3-988d-4affe6a004e8',
          'bfa0c3de-92c6-5d27-a72b-40bc49d05bab',
          '698b42bb-7b72-5b33-9c7e-f90e33f6abe2',
          '7545ef83-01b6-5afd-b170-8f5a7a52c65e',
          '6b5a40f4-2a49-59ff-b350-ababb3e6eb09',
          '91b044bc-660d-570f-bf92-1221f59c13ac',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '78bed69c-3b6e-559e-8845-8c31b4b00a08',
        sku: 'Thyroid_FBM',
        name: 'Thyroid Support Complex Supplement - Thyroid Support for Women and Men - Boost Energy, Focus, Metabolism - Iodine Supplements for Thyroid + B12, Selenium, Zinc, Copper, Ashwagandha, L-Tyrosine & More',
        imageUrl: 'https://m.media-amazon.com/images/I/41xYKudXwzL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B07CSHF769',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '325f4f20-c2a9-5e15-b533-0d1eebbd4813',
          '319d8b18-d42e-59e4-b4eb-7a1f024a7d92',
          '2ca6deb5-d5b2-5ce0-a5d9-b6e600365b0c',
          '4e885b19-5f2c-573b-8784-009863f332de',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          '51f64561-e8d5-5f91-ad29-760f311610aa',
          'a5a57f0e-8848-5969-b414-64e819a8d0a7',
          '7d5b05de-a22d-5773-b0f4-48a625036561',
          'ca08ecd6-b153-579f-98b0-1d874a3fe334',
        ],
        adGroupIds: [
          '7ea066c1-2d31-528a-a8aa-0185bdb5c643',
          '14cc2383-26a8-56e8-a07a-0afc816ea923',
          '18fe8cb0-0add-5605-92e7-b78fb455879d',
          'ac51586d-aeb2-504b-81ab-511e93c38fc5',
          '95a339d2-7be2-57b2-a505-8152a461c2ed',
          '5f39349a-3939-5446-a720-102ce7685b42',
          '5b41282a-ad4d-54ff-b21c-b22b9fa8812d',
          '7545ef83-01b6-5afd-b170-8f5a7a52c65e',
          '1f299106-3589-547a-b72b-d3fc54713a36',
          'bedda9e5-0078-500c-843f-1f6c2830a050',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '3f58fbfe-5b6a-5a5d-9508-e566ac85451a',
        sku: 'KetoBurn_FBA',
        name: '',
        extItemDetails: [
          {
            extItemId: 'B07GX86WM7',
            extItemType: 'ASIN',
          },
        ],
        productGroupId: 'dd763be0-0d3f-5277-ae5e-43802ea4ab6f',
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '5ac7c0ce-0f4a-5754-8c1c-31a7a97acf52',
        sku: 'B077XN74GM',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '2dfaa34b-1a04-5c46-a6a7-e67b6799bab3',
        sku: 'B07K1N5358',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '1e9e5eef-adee-533a-afbd-183b5cc06f1e',
        sku: 'ChocolateBoneBroth_FBA',
        name: 'Bone Broth Protein Powder Chocolate - Grass Fed Collagen Peptides - Protein Powder Without Artificial Sweeteners - No Sugar, Paleo & Keto Friendly -Dairy Free, Gluten Free - 20g Protein, 20 Servings',
        imageUrl: 'https://m.media-amazon.com/images/I/418E1+lUGhL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B09CLVXF13',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          'd139e704-4634-540b-992e-f61bfd3be52c',
          '8d5ad5f0-176b-5087-a485-a612a5ab7e3b',
          '7ad77849-a12e-5333-bd12-f24c3f87367b',
          'd12294a7-ae5b-5e51-bbff-aabf48a92cf2',
          'a214fcbd-5459-5b7c-947d-e4e0fb771e00',
          'c56c7045-f083-5e47-88bb-d3a7bef2d014',
          '48ae8ab5-c74b-5d45-b652-f4efe031cf81',
          '6fd6e4a2-dbd6-51d5-989a-ee4f45ba60d6',
          'da861688-c484-52ee-b235-cdc38fe0d2cc',
          '28138175-e195-5778-a686-5525fdaa1f4c',
          '21daa234-82b1-5808-b4d6-4b26571cd714',
        ],
        adGroupIds: [
          'df528205-5ac5-5e3b-9cc5-937618cd57d6',
          '690e7831-887f-5890-8f52-a662e0e0d2c3',
          '8b2443c4-74b9-566e-9d8a-4f62421d8571',
          '01c0e6e5-9886-59e3-a601-a0f54dcd0d0d',
          'ddb28ba1-23cb-58b2-96b7-64b6de0ff871',
          '370bcc5d-23d5-57a6-991a-b05018da1512',
          '0f11c1bb-11e0-522f-83c7-921436336f7c',
          'ff8fdd4d-bc94-5dd4-b88b-4c6c531f63a0',
          'd51841b6-be72-5a72-8388-c7d0e0dbce16',
          '220998c4-a26b-56ea-ba1c-ccd79f397740',
          'c0a191ac-51c2-5772-a38c-12af2bfd119d',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '162cbdab-0ea0-5da1-a2f6-35c0c9084506',
        sku: 'Elderberry_FBA',
        name: 'Elderberry Immune Support Supplement with Vitamin C, Zinc, Echinacea & More, 9-in-1 Immune System Booster for Adults, 60 Veggie Capsules',
        imageUrl: 'https://m.media-amazon.com/images/I/41xLI5-08DL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B086H46BN6',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '1c0a3d28-5dc4-5005-8700-70d6085480b2',
        sku: 'BoneBroth_FBA',
        name: 'Bone Broth Protein Powder with Organic Turmeric & Collagen Peptides - Grass Fed Beef Bone Broth Collagen - Keto & Paleo Friendly Nutrition - Turmeric Flavor 22g Protein, 20 Servings',
        imageUrl: 'https://m.media-amazon.com/images/I/41ZQvlcYAwL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B07BCBM7RN',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          'd28552e9-1ea2-577b-959c-d25b3d45c3a6',
          'd6146cbc-0919-54c4-a594-ede419da0f4d',
          'a7711b09-ab98-57dc-bc1e-62e006054743',
          '5457bb64-a00b-53b3-8824-f1e577ee8430',
          '7c4a26de-c0b7-5b42-a80c-386e961231e4',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          '5ca091d9-8640-5bec-ba9c-c1bb0e2ddd46',
          '785974d1-ff15-55a7-8d9b-ae26db6528f9',
          'dfb3d865-434b-5191-a723-645cf88741e9',
          'c4e5acef-0869-53c5-a1dc-7ec9b19ce952',
          '3972cbff-584c-5498-9382-11fddb005ba8',
        ],
        adGroupIds: [
          '47db4ecb-5cc5-532f-8aac-7ecff2bdab64',
          '817d6e27-fc94-53d6-9ca9-6ac11c94e985',
          '780e328a-4623-5307-aac8-bbd9673d0088',
          '285be8cf-e8e5-5d63-9583-e8b95e809f42',
          '1ca9d016-7cb4-5a3c-88fa-db2c0edd5a36',
          '7545ef83-01b6-5afd-b170-8f5a7a52c65e',
          'c695679a-e78e-5ca5-8904-7df64f109f2a',
          '488f19e3-bdae-57ba-b397-d393066bc0fb',
          'e153df7c-b8c1-5da3-ba16-c8b4b08cfed5',
          '9350d0f3-9db6-516d-9317-f97ff0a89b8e',
          '0dcce9e8-911c-5c0e-b5ee-f4cc24e71965',
          '6cffa3b2-af37-595d-9fcb-3aceddb393a6',
          '78f4d2df-f1cb-568f-9d55-6b74dec99f5e',
          '3cef00bd-1f98-5adb-a9da-658503bb81ef',
          'c25e6054-d671-5b31-90fd-124e0bce2daa',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '2b299489-b1dc-57c2-aa61-371499809ce0',
        sku: 'Keto_Burn_Ultra_FBA',
        name: 'COGNITUNE Keto Burn Keto Pills – Keto Supplements for Ketosis with Raspberry Ketones, Apple Cider Vinegar, Garcinia Cambogia – Thermogenic Keto Pills for Men, Women to Boost Metabolism, Burn Fat',
        imageUrl: 'https://m.media-amazon.com/images/I/31t1FlD5WJL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B08TY96RVQ',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '83d93a4a-3b69-5b45-a247-3605a5ae49b2',
          '2df768a6-6607-5a98-99e8-6f4798ab2bec',
          '6f931333-a8d8-5dc0-a8ea-cb3afd6b7225',
          '087425d7-0f94-5b5d-8776-809496ab681b',
          '7790003d-d8ef-5e55-b93f-c303e19f6838',
          '7a45dee5-aaae-5abb-905c-6c0e53531309',
          '129a8edb-ee64-5f05-b176-5632bde3a448',
        ],
        adGroupIds: [
          '82ef225b-6c85-51e5-9d02-975ea0f1b339',
          '11e698f3-d3a4-5e34-bf61-deac5ba80502',
          '6e8a3133-b8c6-5679-9047-ee024e3ea0bf',
          '3da00504-9758-56e7-8646-ed6e8a67cdf7',
          '173d70d1-0a77-5740-a5b2-9d69839d2255',
          '4946a9eb-07fe-5f7e-b78e-24df0b1e066b',
          '2a5dddb9-bd9f-57dc-aafd-682eabb470eb',
          '4979fa9b-fe6a-5ae3-abdd-89bdd7637707',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '17669ac1-58ee-599e-9195-10ace7670d2c',
        sku: 'NuClarity_BrainFog',
        name: 'Brain Booster for Brain Fog - NuClarity: Nootropic Brain Fog Supplement with Ginkgo Biloba, Bacopa Monnieri, Alpha-GPC, Rhodiola Rosea, Phosphatidylserine',
        imageUrl: 'https://m.media-amazon.com/images/I/31QaOF-YkpL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B09WLVS7BL',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['e952beda-7f84-5b90-a956-1a9064a4dbde'],
        adGroupIds: ['ddf99c63-3536-5ed4-8a96-db3404745845'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'c6a87f8a-1ad0-57b5-930f-b9cce1753733',
        sku: 'MCTOil_FBM',
        name: '',
        extItemDetails: [
          {
            extItemId: 'B07K1N5358',
            extItemType: 'ASIN',
          },
        ],
        productGroupId: '713b94b8-3b6c-544d-ada6-e16f370e282e',
      },
      advertisementFields: {
        campaignIds: ['584bea86-6053-551b-a20f-ec80ae01e74c'],
        adGroupIds: ['81c35df5-3dd6-5959-b25b-73859a80a8ad'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '07036e37-a856-587f-ac3c-60ceb1dd7d53',
        sku: 'B076Z59SWZ',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '0c14f65c-e3d6-5453-886f-dc0c6204f048',
        sku: 'NuSerenity_FBA',
        name: 'NuSerenity - Natural Stress and Anxiety Relief Supplement - Reduce Stress, Boost Mood, Increase Calm with Premium Formula of B Vitamins, Ashwagandha, Rhodiola, Bacopa, L-Theanine, 5 HTP - 60 Capsules',
        imageUrl: '',
        extItemDetails: [
          {
            extItemId: 'B0899NFQR9',
            extItemType: 'ASIN',
          },
        ],
        productGroupId: '57031c70-eec3-598d-b797-27f43dd34e47',
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '310ec785-ac4f-5a95-b87d-542b4f805253',
        sku: 'GoKeto_FBA',
        name: '',
        extItemDetails: [
          {
            extItemId: 'B07GP8397Z',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '69492557-fa78-5f3b-bbfc-8c3f90f4aaa3',
        sku: 'CHOP_01',
        name: 'Manual Food Processor - Multipurpose Vegetable Chopper, Portable, Quiet and Convenient Mini Food Processor, 2 Cup Capacity, Safely & Easily Chop and Mix Veggies, Fruits, Potatoes, Tomatoes & More',
        imageUrl: 'https://m.media-amazon.com/images/I/41duiy+XDjL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B09YTNZ2KK',
            extItemType: 'ASIN',
          },
        ],
        productGroupId: '068783d2-3a3c-579a-bf58-902f350c3f25',
      },
      advertisementFields: {
        campaignIds: ['423c2bc0-7c79-5d8b-ad24-10026bc5594b'],
        adGroupIds: ['d0dc5df8-8f45-53f5-88e5-f2195f69b285'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'ab780e7e-a5b1-5052-a034-3461eeeb06b2',
        sku: 'NuClarity_Focus',
        name: 'Brain Booster for Focus - NuClarity: Nootropic Brain Supplement for Focus with Ginkgo Biloba, Bacopa Monnieri, Alpha-GPC, Rhodiola Rosea, Phosphatidylserine',
        imageUrl: 'https://m.media-amazon.com/images/I/31XNyv5y1pL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B09WLX9BRH',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          'cf5f685c-5a85-5959-bb07-c5ce56de85c0',
          '08b3f615-5209-5a64-af61-6b5ab6f9e4d0',
          'f03d12e0-eeae-5429-91a2-79571b7949bd',
        ],
        adGroupIds: [
          '1d126c3d-b982-58b3-b179-f9ec841e1c16',
          'eea0bf28-6a62-5d66-a44b-82207147d08d',
          '0a1d308f-22b1-5cde-aafd-ab46b20e4346',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'e5428260-39cf-56eb-b1b4-4c148099af25',
        sku: 'Thyroid_FBA',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['51f64561-e8d5-5f91-ad29-760f311610aa'],
        adGroupIds: [
          '7ea066c1-2d31-528a-a8aa-0185bdb5c643',
          'ac51586d-aeb2-504b-81ab-511e93c38fc5',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '07fb78b6-d137-5294-a550-3af5802a4d14',
        sku: 'Turmeric_FBM',
        name: 'Organic Turmeric & Ginger Capsules - Turmeric Curcumin with Ginger & BioPerine, 95% Curcuminoids, Joint Support & Anti-Inflammation Supplement, Black Pepper Extract for Increased Absorption',
        imageUrl: 'https://m.media-amazon.com/images/I/41W3m0fmSzL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B07BZS9GP8',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '6c7e1b38-4954-5934-be64-2223cff1e1d1',
          '0f0a9ac9-9f83-560e-b748-c0b9467d1007',
          'ba3a3426-deeb-5206-8c73-594b4182bd0d',
          'd8c6e243-e35f-58f0-bc74-5463a8ce65b4',
          '86a0224d-a3e5-55a4-8a92-dca603826f1d',
          '3a9905bd-e3dd-5d8a-9908-395443780a1d',
          'eef77492-4fb7-59a5-b344-1abe57de01d8',
          '0ee66efb-76f0-5385-a969-f2a4f10b46e2',
          '8bb86f23-c5ac-5572-907f-91864d66b316',
          'f3c950cb-2422-5749-af6e-2e15c192138d',
          'aeedc20f-b163-5738-9a64-29e8c934becf',
          'ad85bbc8-39d1-5b7a-bc35-18eedb11b9bc',
          '25303118-b061-57e3-856e-f6f2bf7e5cd8',
          'ca8f97b9-f6dc-54bc-b624-0950539b9068',
          '5581a378-14f4-53f5-a136-2a3ec172ca11',
          'b031f78c-ff8f-50a8-82c7-610d69b132f2',
          '65bc73e1-c190-575e-9f28-0eb02c885596',
          '33d1736b-59ab-50e9-8058-44c814dac165',
          '26487e6f-725f-508b-aa52-63dc762bb88e',
          '4d887059-0942-53fe-9819-5128c8aeaa29',
          '4cef0a42-a0f2-5abb-ba56-8229be42abc5',
          '3bb41ecb-1657-5925-ad4f-b0cdae957b46',
          '95f9a5b7-d242-5d21-aaad-9419b68d5708',
          'f90b393e-ebe0-5f5e-83b4-1cc17d24f705',
          '13c4db58-f1b3-54c9-a574-7b662c3902ad',
          'dbe1df76-d2b1-5c46-ab11-900bb5fb23e0',
          'f6539170-6989-50b8-8e53-c9b0047bf52b',
          '1d533ef0-b997-5c58-9346-d94f439cb25a',
          '8e23f4ae-6866-5a97-8145-094769386e25',
          'b435db6d-d976-57bf-b82a-18c735d8e481',
          '278da8d9-43db-50c0-a8c1-f9003f4ceaa4',
          '069a4809-bcc3-587d-b988-6c24df6ed5c9',
          '80a7a7c9-b082-553a-a5bb-372892f64bd4',
          '0355c423-32e1-5ff1-94f4-424d6a09cd7c',
          'abd71490-d229-5dbf-8672-52d77f7049af',
          '86995fb1-c96c-5735-b08e-8e5e4c5b3591',
          '8111eb83-2fce-5364-be0a-0e348a1a0330',
          'e0e4ad94-3d9a-5a32-aa02-46f8431c060e',
          '8b6a6d53-2252-57a9-b34e-747e362e516c',
          '5a7b116d-c7ba-575c-91c3-b61077bab4ab',
          '88a2c443-76f2-5c36-9573-c6e9f68fe1c2',
          'c87bd5fe-563c-5489-87db-9dd2e6b5c67a',
          '7043aced-d603-515e-8fd3-ae29697f4f41',
          '8d6a0b04-152b-51c4-959c-e70b161ec6f0',
          '8de57c59-c2bc-593a-ba1e-e9bcedef9209',
          '9fe41ca4-6143-5433-98a5-c28820e8774b',
          '54618c49-cd3e-59d2-96bc-c6740d142327',
        ],
        adGroupIds: [
          '2a7405b5-1010-52b5-a628-e0bb5313dae5',
          '458c71dd-08b7-5e8c-a0d6-cf9ab080f0e2',
          'ce87bfab-4216-53eb-99e9-7b4e1f6ccc5e',
          'cc10cb6b-8af6-5840-afe8-4b142194bde0',
          '5849499c-b54b-5e5d-89df-7b9e260212c9',
          '65929248-995c-553c-8b28-ef16a09d4153',
          'b1cdf8e9-889a-5ffa-80d6-6aedef3d218e',
          'c58a452c-083e-5f2b-8e20-e8e56ecf3cf4',
          'd7f9e013-610a-5c92-9044-74ab3779112a',
          '918ebed4-3b8f-5085-aed3-2272d4464d65',
          'f406a413-366b-53df-888c-768c8fd36260',
          'de5fa8d0-f86f-5ca3-b092-bd9a0ba92e76',
          '799c56ef-fdc4-558d-a8fc-eeddf5655bab',
          'eb99fc4e-bf55-5727-9db5-0387d78586c7',
          '81337f75-e3b4-5b9a-a280-d9004fea62d5',
          '6ace7fe8-8c02-5d6c-8931-e25f13e2841c',
          'ac36db14-714b-51a1-86a9-0fe632158108',
          '9718e299-d934-5239-9d79-788c1a3be218',
          '180c9782-c7f1-59f8-8b6c-7ce304241336',
          '7f57f1f7-d1fe-5d12-b369-5a957f74a1de',
          'e691ec9a-bf02-5647-b330-34c33c34e315',
          '7f3794ca-0c9f-512a-ba1b-c0cda8308ab0',
          '0f61de67-6217-5977-b040-2e574545162b',
          'ce0048af-0aeb-566f-aad3-4365f26629e4',
          '19ae8481-f4cb-57e4-a4d5-8d117709bfed',
          '7672d81c-b5cb-5c33-a4f8-88cbc2fdadb9',
          'e9880bd4-6b9d-50e7-9457-d18076e742b5',
          '6a4c1c5a-2c58-5c52-8d7b-54bbdb0e6c71',
          'c9d13776-4eb3-54e2-8e87-814687197b0b',
          '1f21ada3-8a43-53cd-8193-7b195e729d51',
          '1358c64d-1f60-5d38-9906-a73caddcce4c',
          '2936a78a-3458-554b-977f-876f128fc618',
          '1e237506-29d1-55a2-896f-e1a3d2eef2df',
          'a521e22c-e596-5642-b3aa-e5b8a5e323c3',
          '7847ba82-f4f6-5613-b568-0fa05a05e481',
          '1152fdcc-21fc-57f2-8f33-4df7501271ab',
          '7752fad0-caa0-50b7-9454-04c3c7342a98',
          'de10eb57-d370-5708-bf6d-71a5cb85f546',
          'f6f515f9-1d11-5e9c-84d6-4b6d78b122ff',
          '5005357a-6e51-5f34-a061-8e0c1a50af92',
          '47c433cf-d345-5114-adbc-61ae76050fbb',
          'ed441d7e-73a5-5420-947b-8afe9fb881f5',
          '907e8f13-8042-5d32-8e0b-60a4875e741b',
          '423ae208-6984-539b-9928-18505da4d8c9',
          '49d9e0c4-61fd-5998-be3f-0a82f78c0032',
          'fa8182b8-af80-5ce9-a1ed-d7dc6449f415',
          '8fac5be2-08ea-59a4-a69d-cf873253f3b6',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'da107447-4ad0-5198-add9-b76f545f766e',
        sku: 'NuBoost_FBM',
        name: 'NuBoost - All Natural Caffeine Pills with L-Theanine + BioPerine - Premium Sustained Energy & Focus Supplement - Nootropic Brain Booster for Smooth Instant Energy - No Crash, No Jitters',
        imageUrl: 'https://m.media-amazon.com/images/I/41Oy9hz6FxL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B076Z59SWZ',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '833fde86-be00-5563-add9-7de492de31f8',
          'efbf2be4-5b9e-53c7-9b05-1a7e3398b012',
          '82cd716e-981a-5f8b-96c7-d24bb361d929',
          '4a0adeb3-801a-5ffa-ae85-09913310078a',
          'b18e8cfe-6679-5cf2-adc2-65e5a3f53371',
          '81df95c6-91ea-5f98-ab6f-40d6dd11740a',
          'c1f02dc5-6711-5852-af50-f9ff7c46bf74',
        ],
        adGroupIds: [
          '74b6e866-98e0-5c8d-add2-34516ef37e2d',
          'f6de42b5-a895-5683-8488-3d069e554966',
          '22cd9c90-6a73-526e-81f6-96d9766b36d5',
          '0427658d-413b-54e5-9f64-6ab56bbec00a',
          '78fba52b-84cb-5f51-92f0-cd187c2b1f5d',
          'c30cc56b-706c-58d5-806e-687271837e54',
          'b7f585bc-a99f-511f-8da4-492e979cef22',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'fcb72096-e6f8-5c69-b3b3-c26548ef1f10',
        sku: 'BoneBroth_FBM',
        name: 'Bone Broth Protein Powder with Organic Turmeric & Collagen Peptides - Grass Fed Beef Bone Broth Collagen - Keto & Paleo Friendly Nutrition - Turmeric Flavor 22g Protein, 20 Servings',
        imageUrl: 'https://m.media-amazon.com/images/I/41ZQvlcYAwL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B07BCBM7RN',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          'd28552e9-1ea2-577b-959c-d25b3d45c3a6',
          'd6146cbc-0919-54c4-a594-ede419da0f4d',
          'a7711b09-ab98-57dc-bc1e-62e006054743',
          '5457bb64-a00b-53b3-8824-f1e577ee8430',
          '7c4a26de-c0b7-5b42-a80c-386e961231e4',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          '5ca091d9-8640-5bec-ba9c-c1bb0e2ddd46',
          '785974d1-ff15-55a7-8d9b-ae26db6528f9',
          'dfb3d865-434b-5191-a723-645cf88741e9',
          'c4e5acef-0869-53c5-a1dc-7ec9b19ce952',
          '3972cbff-584c-5498-9382-11fddb005ba8',
        ],
        adGroupIds: [
          '47db4ecb-5cc5-532f-8aac-7ecff2bdab64',
          '817d6e27-fc94-53d6-9ca9-6ac11c94e985',
          '9350d0f3-9db6-516d-9317-f97ff0a89b8e',
          '0dcce9e8-911c-5c0e-b5ee-f4cc24e71965',
          '78f4d2df-f1cb-568f-9d55-6b74dec99f5e',
          '285be8cf-e8e5-5d63-9583-e8b95e809f42',
          '3cef00bd-1f98-5adb-a9da-658503bb81ef',
          '7545ef83-01b6-5afd-b170-8f5a7a52c65e',
          'c695679a-e78e-5ca5-8904-7df64f109f2a',
          '488f19e3-bdae-57ba-b397-d393066bc0fb',
          'c25e6054-d671-5b31-90fd-124e0bce2daa',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '37446c4e-2591-5bab-9867-941152118ec5',
        sku: 'B07GP8397Z',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '5e6baec0-65bb-5094-bebc-af6451f4b4fc',
        sku: 'NuClarity_Memory',
        name: 'Brain Booster for Memory - NuClarity: Nootropic Brain Supplement for Memory & Focus with Ginkgo Biloba, Bacopa Monnieri, Alpha-GPC, Rhodiola Rosea, Phosphatidylserine',
        imageUrl: 'https://m.media-amazon.com/images/I/31jtjpmPoYL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B09WLWJ8DX',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '1c3578e5-1f96-56c7-80b0-9d004ced43de',
          'e06eaee6-b24f-5d50-8248-a226e77eb7f0',
        ],
        adGroupIds: [
          '8324c1ea-f773-59b9-8351-7f60c14d0888',
          'e60ec257-8cd2-58f6-9c6c-dcf646b58a45',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '71c6d303-1b0a-597b-8070-349b2111ebba',
        sku: 'NuClarity_FBM',
        name: 'NuClarity - Premium Nootropic Brain Supplement - Focus, Energy, Memory Booster - Mental Clarity & Cognitive Support - Ginkgo Biloba, Bacopa Monnieri, Alpha-GPC, Phosphatidylserine, Rhodiola Rosea',
        imageUrl: 'https://m.media-amazon.com/images/I/41UUHSw0NgL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B075RS7MR8',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '6d94663b-4c4a-5596-839e-afe15324f128',
          '74b1299e-2fc6-5907-8def-ff6bb8922199',
          '187dc833-332a-508b-88c0-976e1c1855ad',
          '23457bcf-23da-55ce-8104-ceddc33e4e7d',
          'bd1fef4a-119b-5a53-a266-fff1da02a5ef',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          '1b7f82b1-3de9-5b66-8992-1df338f547cd',
          '4a5ea5fc-ace8-5c65-a790-b0605aafb5cc',
          '3cf555ad-48d7-5ff0-945b-926b20216562',
        ],
        adGroupIds: [
          'cd78bbe6-bd0b-5681-a548-b2b51556440f',
          '655b33f6-078a-5594-9483-b82a5666416d',
          '52820f19-2c35-591a-9374-40cebebcabb0',
          'f0c136eb-d461-529b-9af8-a2b0e63e28d2',
          '1890674e-aaf6-53a8-886d-b40bbdd9f9bb',
          '6b2e422a-7c5c-59be-9b4e-ef3d90a7faca',
          '79bb2662-e166-5786-b27f-0f15f827123d',
          '73243e01-8d42-5cc4-8808-1ade8383712d',
          'cf0505c2-2aa1-5393-9b95-876a74c8267f',
          'a856b4fa-06aa-5821-9eca-0f046b38740c',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '49badbee-2247-5720-ae3d-8ebac8f713bd',
        sku: 'B07BZS9GP8',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'c261d23f-552f-57c0-b4b6-bbc03263d4e8',
        sku: 'B07K1LH5D5',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '928a95d9-c813-5771-93c6-d0ad7273adb0',
        sku: 'NuClarity_Seniors',
        name: 'Brain Booster for Seniors - NuClarity: Brain Supplement for Memory Focus Brain Fog for Seniors with Ginkgo Biloba, Bacopa Monnieri, Alpha-GPC, Rhodiola Rosea, Phosphatidylserine',
        imageUrl: 'https://m.media-amazon.com/images/I/319pYDVt02L._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B09WLVC2BV',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          'a921ef55-d9bc-5916-9a9a-d4131189286a',
          'ff019788-da79-5cd7-91b5-1010c7839ee9',
          'bdd0711f-eff2-5a6c-8889-3627113e717a',
          '8c0c7b6c-ad9c-5e05-b1a9-349bd5d9d09e',
          '8586d933-c9f0-56e2-ad48-50c522d87eec',
        ],
        adGroupIds: [
          '6d03491e-aeb6-53fa-902d-bb43f2c1f87e',
          'cd496c22-dd95-5c10-a5fe-e23dd450a25c',
          '44594662-622b-529f-ab98-e11789cc3948',
          '9d2b593d-8483-5991-8b1d-3d2a486dc4c5',
          'cf8f3e84-55cd-5e7c-a351-258e3fed6517',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '931d8b89-1893-55c5-832b-4f67c9a7889b',
        sku: 'B075RS7MR8',
        name: '',
        extItemDetails: [
          {
            extItemId: '',
            extItemType: 'SKU',
          },
        ],
      },
      advertisementFields: {
        campaignIds: ['aae83e92-8a55-5233-af22-8d2767c4accd'],
        adGroupIds: ['286b5594-3ae8-5271-b6b2-ee32e68d53ac'],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: 'f75e5c96-31d7-5244-9e99-8f3d0fb00942',
        sku: 'MCTOil_FBA',
        name: '',
        extItemDetails: [
          {
            extItemId: 'B07K1N5358',
            extItemType: 'ASIN',
          },
        ],
        productGroupId: '9db92761-1e4d-5505-b31a-800eda5a183d',
      },
      advertisementFields: {
        campaignIds: [
          'aae83e92-8a55-5233-af22-8d2767c4accd',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
        ],
        adGroupIds: [
          '286b5594-3ae8-5271-b6b2-ee32e68d53ac',
          '81c35df5-3dd6-5959-b25b-73859a80a8ad',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
    {
      skuDetailsFields: {
        productId: '55089795-b5cc-5fa0-8813-b18e33321449',
        sku: 'K2D3_FBM',
        name: 'Vitamin D3 5000 IU with Vitamin K2 (MK7) Supplement - Premium Immune, Heart & Bone Health Capsules - Vitamin K2 D3 Complex with 5000 IU of Vitamin D3 & 90 mcg of Vitamin K2 mk7 - 60 Count',
        imageUrl: 'https://m.media-amazon.com/images/I/41ZO+edYAaL._SS60_.jpg',
        extItemDetails: [
          {
            extItemId: 'B07K1LH5D5',
            extItemType: 'ASIN',
          },
        ],
      },
      advertisementFields: {
        campaignIds: [
          '417f869a-ab5f-531a-a818-99868ba90d32',
          '81edd32f-a7dc-5425-82b6-947d79079f00',
          '584bea86-6053-551b-a20f-ec80ae01e74c',
          '8e5d8261-633a-5d31-8fc2-e59d6f0829ba',
          '2d38ea6d-4114-559c-85a2-8e0421a64fd0',
          '867c1fd4-28ef-5d85-a188-cc94cb6da0ad',
        ],
        adGroupIds: [
          '3dfd41bb-9bf9-5bbd-8a4f-2decd76062b1',
          '4cf2baae-dcfb-5daf-9144-eb2f987df5ac',
          'bc04ec11-7122-5aa9-b4c1-a6930342735d',
          '9f10b8eb-2616-5f5e-989f-0a22e319e1c1',
          '855bf6d3-01d0-59b4-9460-741b3dcc7ffc',
          'a80ed45f-f180-527e-a3b1-b92ce9d48b6e',
          '0ef8304f-9a25-5379-a9f1-b40f9bc99f95',
          '3bf0ae28-611d-5dbb-b296-9a94aea4cbe4',
          '573c4ab7-5954-59af-b08a-1060deca933e',
        ],
      },
      performanceMetrics: {},
      aiPoweredSettings: {},
      pendingFields: [],
    },
  ],
  totalItems: 49,
}));
