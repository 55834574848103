import isNil from 'lodash/isNil';

import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProfileApiDataDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { PROFILES_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = (props) =>
  makeCurrencyColumn<ProfileApiDataDetails>(
    ({ profilePerformance: { adSalesTotal } }) => {
      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        undefined,
        adSalesTotal?.amount ? Number(adSalesTotal.amount) : undefined
      );

      return isNil(performanceMetricValue)
        ? undefined
        : {
            amount: performanceMetricValue,
            currency:
              getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
              adSalesTotal?.currency ||
              CURRENCY_CODE,
          };
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const adSalesColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.AdSales,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_AD_SALES_TOTAL,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
};

export const adSalesFilterProfile = (currency: string) =>
  createMoneyDataFieldFilter(
    PROFILES_API_COLUMN_NAME.AdSales,
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_AD_SALES_TOTAL,
    currency,
    isValidNumber(0)
  );
