import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { PRODUCT_ADS_API_COLUMN_NAME } from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = ({ merchantCountry, ...adItemDetails }) => {
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  return makeCurrencyColumn<ProductAdDetails>(
    ({ adItemDetails: { campaignTotalBudget } }) =>
      campaignTotalBudget && {
        amount: Number(campaignTotalBudget.amount),
        currency: currencyCode || campaignTotalBudget.currency || CURRENCY_CODE,
      },
    adItemDetails.pendingFields?.includes(
      PRODUCT_ADS_API_COLUMN_NAME.TotalBudget
    )
  )(adItemDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const totalBudgetColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: PRODUCT_ADS_API_COLUMN_NAME.TotalBudget,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_TOTAL_BUDGET,
  RowCellElement,
  gridColumnWidth: '160px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const totalBudgetFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    PRODUCT_ADS_API_COLUMN_NAME.TotalBudget,
    I18nKey.ADS_MANAGER_PRODUCTAD_TABLE_COLUMN_TOTAL_BUDGET,
    currency,
    isValidNumber(0)
  );
