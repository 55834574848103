import { SvgIcon, TextLinkProps } from '@teikametrics/tm-design-system';

export enum PageType {
  Compass = 'compass',
  AdsManager = 'ads_manager',
  ProductCatalog = 'product_catalog',
  RecommendationHub = 'recommendation_hub',
  CampaignCreator = 'campaign_creator',
  AIRecommendedKeywords = 'ai_recommended_keywords',
  AIRecommendedKeywordsV2 = 'ai_recommended_keywords_v2',
  BusinessIntelligence = 'business_intelligence',
  KeywordTargetActions = 'keyword_target_actions',
  KeywordTargetActionsV2 = 'keyword_target_actions_v2',
  BudgetPacing = 'budget_pacing',
  NewRecommendationHub = 'newrecommendation_hub',
  InventoryOptimization = 'inventory_optimization',
}

export interface HeroData {
  icon: SvgIcon;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  buttonIcon: SvgIcon;
  buttonLabel: React.ReactNode;
  readonly imgPath?: string;
  readonly riskFreeTrialMessage?: React.ReactNode;
}

export interface BannerData {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
}

export interface PropositionData {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  imageSource: string;
  readonly textLinkProps?: TextLinkProps;
}

export interface WalkthroughModalSlide {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  imageSource: string;
}

export interface WalkthroughModalData {
  title: React.ReactNode;
  deepDiveHelp: React.ReactNode;
  slides: WalkthroughModalSlide[];
  type: PageType;
}

export interface PageData {
  bgImage?: string;
  hero: HeroData;
  banner: BannerData;
  propositions: PropositionData[];
  walkthroughModal?: WalkthroughModalData;
  isVisible: boolean;
}
