import {
  BadgeType,
  CycleDotIcon,
  DataFieldFilter,
  StarIcon,
  SwordsCrossedIcon,
  UpcIcon,
} from '@teikametrics/tm-design-system';
import {
  TargetSegments,
  TargetingType,
} from '../../../../../lib/types/KeywordAction';
import { ActionRowDataProps } from '../types';
import { acosFilter } from './acos';
import { conversionRateFilter } from './adConversionRate';
import { adConversionsFilter } from './adConversions';
import { adSalesFilter } from './adSales';
import { adSpendFilter } from './adSpend';
import { clicksFilter } from './clicks';
import { ctrFilter } from './clickThroughRate';
import { cpcFilter } from './costPerClick';
import {
  destinationAdGroupNameFilter,
  destinationAdGroupTagFilter,
  destinationAdGroupTargetingTypeFilter,
} from './createInAdGroup';
import { impressionsFilter } from './impressions';
import {
  keywordRecommendationFilter,
  keywordTagFilter,
} from './keywordRecommendation';
import { matchTypeFilter } from './matchType';
import {
  negativeKeywordRecommendationFilter,
  negativeKeywordTagFilter,
} from './negativeKeywordV2';
import { negativeMatchTypeFilter } from './negativeMatchType';
import { productAsinsToAddFilter } from './productAsinsToAdd';
import { roasFilter } from './roas';
import { sourceFiltersKWR, sourceFiltersNKWR } from './sources';

export const getTargetingTypeIndicator = (targetingType: TargetingType) =>
  targetingType === TargetingType.Auto ? 'text-blue-500' : 'text-magenta-500';

export const getCapitalizedFirstLetter = (targetingType: TargetingType) =>
  targetingType.charAt(0).toUpperCase();

export const splitByCapitalLetter = (val: string) =>
  val.split(/(?=[A-Z])/).join(' ');

export const capitalizeFirstLetter = (val: string) =>
  val.charAt(0).toUpperCase() + val.slice(1);

export const getNKWRFilterColumnDefinitionsV2 = (
  currency: string
): DataFieldFilter[] => [
  negativeKeywordRecommendationFilter,
  negativeKeywordTagFilter,
  negativeMatchTypeFilter,
  destinationAdGroupNameFilter,
  destinationAdGroupTargetingTypeFilter,
  destinationAdGroupTagFilter,
  ...sourceFiltersNKWR,
  adSalesFilter(currency),
  adSpendFilter(currency),
  acosFilter,
  roasFilter,
  adConversionsFilter,
  conversionRateFilter,
  impressionsFilter,
  clicksFilter,
  cpcFilter(currency),
  ctrFilter,
];

export const getKWRFilterColumnDefinitionsV2 = (
  currency: string,
  isPat?: boolean
): DataFieldFilter[] => [
  ...(isPat ? [productAsinsToAddFilter] : [keywordRecommendationFilter]),
  keywordTagFilter,
  matchTypeFilter(isPat),
  destinationAdGroupNameFilter,
  destinationAdGroupTargetingTypeFilter,
  destinationAdGroupTagFilter,
  ...sourceFiltersKWR,
  adSalesFilter(currency),
  adSpendFilter(currency),
  acosFilter,
  roasFilter,
  adConversionsFilter,
  conversionRateFilter,
  impressionsFilter,
  clicksFilter,
  cpcFilter(currency),
  ctrFilter,
];

export const TARGET_SEGMENT_KEY_TO_BADGE_TYPE_MAPPER: Record<
  TargetSegments,
  BadgeType
> = {
  [TargetSegments.Auto]: BadgeType.TagAutomatic,
  [TargetSegments.Brand]: BadgeType.TagBrand,
  [TargetSegments.Competitor]: BadgeType.TagCompetitor,
  [TargetSegments.Generic]: BadgeType.TagGeneric,
  [TargetSegments.Unknown]: BadgeType.TagGeneric,
  [TargetSegments.Category]: BadgeType.TagCategory,
};

export const getPillIcons = (targetSegment: TargetSegments) => {
  switch (targetSegment) {
    case TargetSegments.Auto:
      return CycleDotIcon;
    case TargetSegments.Brand:
      return StarIcon;
    case TargetSegments.Competitor:
      return SwordsCrossedIcon;
    case TargetSegments.Generic:
      return UpcIcon;
    default:
      return StarIcon;
  }
};

export const isRowProcesing = (
  rowId: string,
  actionRowDataProps?: ActionRowDataProps
) =>
  actionRowDataProps?.dismissProcessingRows.includes(rowId) ||
  actionRowDataProps?.acceptProcessingRows.includes(rowId);
