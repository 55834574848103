import {
  BadgeDisplayMode,
  BadgeType,
  LevelsController,
  Slideover,
  SuggestedAdgroupCell,
  SuggestedAdgroupCellProps,
} from '@teikametrics/tm-design-system';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/types/I18nKey';

import {
  AsyncRequest,
  AsyncRequestCompleted,
  AsyncRequestLoading,
  AsyncRequestNotStarted,
  asyncRequestIsComplete,
  asyncRequestIsLoading,
} from '../../lib/utilities/asyncRequest';
import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../containers/userProvider/userProvider';
import { LevelType } from '../../core/campaignFlexibility/types';
import { createAOApiClient } from '../../lib/clients/AOApiClient';
import { TargetSegments, TargetingType } from '../../lib/types/KeywordAction';
import { ViewTrendsContext } from '../../modules/advertisingOptimization/components/ViewTrends/ViewTrendsProvider';
import { KeywordsTab } from './keywordsTab';
import { SkuTab } from './skuTab';
import {
  ProductsData,
  TargetsData,
  ViewSkuAndKeywordSlideoutModalProps,
} from './types';
import { getTabs } from './utils';
import { MerchantCountryCode } from '../../lib/types/AOSharedTypes';
import { FlywheelSalesChannel } from '../../lib/types/Fam';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../lib/utilities/intercom';

const getTargetSegmentPills = (targetSegments?: TargetSegments[]) =>
  targetSegments
    ? targetSegments
        .map((targetSegment, index) => ({
          badgeType: TARGET_SEGMENT_KEY_TO_BADGE_TYPE_MAPPER[targetSegment],
          dataTestId: `kwa_createInAdgroup_targetSegment_${index}`,
          className: 'mr-4',
        }))
        .sort((a, b) => (a.badgeType < b.badgeType ? -1 : 1))
    : [];

type DataRequest = AsyncRequest<{
  readonly skuData: ProductsData[];
  readonly keywordsData: TargetsData[];
}>;

export const TARGET_SEGMENT_KEY_TO_BADGE_TYPE_MAPPER: Record<
  TargetSegments,
  BadgeType
> = {
  [TargetSegments.Auto]: BadgeType.TagAutomatic,
  [TargetSegments.Brand]: BadgeType.TagBrand,
  [TargetSegments.Competitor]: BadgeType.TagCompetitor,
  [TargetSegments.Generic]: BadgeType.TagGeneric,
  [TargetSegments.Unknown]: BadgeType.TagGeneric,
  [TargetSegments.Category]: BadgeType.TagCategory,
};

export const ViewSkuAndKeywordSlideOutV2: React.FC<
  ViewSkuAndKeywordSlideoutModalProps
> = ({
  isOpen,
  onClose,
  adGroupId,
  suggestedAdgroup,
  merchantCountry,
  textLinkContent,
  primaryButtonProps,
  secondaryButtonProps,
  onTextLinkClick,
  showTextLink = true,
  selectedSalesChannelName,
  adTypeDropDownValue,
  onSKUTrendsLinkClick,
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const account = getCurrentAccountFromContext(userContext)!;
  const aoApiClient = createAOApiClient(userContext.userInfo.idToken!);

  const viewTrendsContext = useContext(ViewTrendsContext);
  const intl = useIntl();

  const [currentLevel, setCurrentLevel] = useState(LevelType.SKUs);

  const [dataRequest, setDataRequest] = useState<DataRequest>(
    AsyncRequestNotStarted()
  );

  const TARGET_DATA_FETCHER = aoApiClient.getKeywordData(
    account.id,
    merchantCountry.merchantCountryId,
    adGroupId
  );

  const loadKeywordsDataData = async () => {
    return TARGET_DATA_FETCHER.then((resp) => resp);
  };

  const SKU_DATA_FETCHER = aoApiClient.getSKUData(
    account.id,
    merchantCountry.merchantCountryId,
    adGroupId
  );

  const loadSkuData = async () => {
    return SKU_DATA_FETCHER.then((resp) => resp);
  };

  useEffect(() => {
    setDataRequest(AsyncRequestLoading());
    Promise.all([loadSkuData(), loadKeywordsDataData()]).then(
      ([skuData, keywordsData]) => {
        setDataRequest(
          AsyncRequestCompleted({
            skuData: skuData.data.skus,
            keywordsData: keywordsData.data.targets,
          })
        );
      }
    );
  }, []);

  const totalKeywordCount = asyncRequestIsComplete(dataRequest)
    ? dataRequest.result.keywordsData.length
    : 0;

  const skuCount = asyncRequestIsComplete(dataRequest)
    ? dataRequest.result.skuData.length
    : 0;

  const suggestedAdgroupProps: SuggestedAdgroupCellProps = {
    campaignName: suggestedAdgroup?.campaign?.name,
    adGroupName: suggestedAdgroup?.name,
    targetSegments: getTargetSegmentPills(
      suggestedAdgroup?.targetSegments as TargetSegments[]
    ),
    targetingType: {
      badgeType:
        suggestedAdgroup?.campaign?.targetingType === TargetingType.Auto
          ? BadgeType.CampaignTypeAuto
          : BadgeType.CampaignTypeManual,
      badgeNameDisplayMode: BadgeDisplayMode.OnlyInitial,
    },
    dataTestId: 'kwa_v2_destination_adgroup',
  };

  const onLevelChange = (newLevel: string) => {
    setCurrentLevel(newLevel as LevelType);
  };

  const getTabsData = () => {
    if (currentLevel === LevelType.SKUs) {
      return (
        <SkuTab
          aoApiClient={aoApiClient}
          accountId={account.id}
          merchantCountryCode={
            merchantCountry?.country || MerchantCountryCode.US
          }
          merchantCountryId={merchantCountry.merchantCountryId}
          adGroupId={adGroupId}
          salesChannelId={merchantCountry.salesChannelId || ''}
          merchantType={merchantCountry.merchantType}
          skuData={
            asyncRequestIsComplete(dataRequest)
              ? dataRequest.result.skuData
              : []
          }
          loadingSkus={asyncRequestIsLoading(dataRequest)}
          onSKUTrendsLinkClick={onSKUTrendsLinkClick}
        />
      );
    } else if (currentLevel === LevelType.Keywords) {
      return (
        <KeywordsTab
          aoApiClient={aoApiClient}
          accountId={account.id}
          merchantCountryId={merchantCountry.merchantCountryId}
          adGroupId={adGroupId}
          salesChannelId={merchantCountry.salesChannelId || ''}
          merchantType={merchantCountry.merchantType}
          loadingKeywords={asyncRequestIsLoading(dataRequest)}
          keywordsData={
            asyncRequestIsComplete(dataRequest)
              ? dataRequest.result.keywordsData
              : []
          }
        />
      );
    }
  };

  const onViewClick = () => {
    if (onTextLinkClick) {
      onTextLinkClick();
    } else {
      if (viewTrendsContext.onTextLinkClick && adTypeDropDownValue) {
        viewTrendsContext.onTextLinkClick(
          selectedSalesChannelName as FlywheelSalesChannel,
          adTypeDropDownValue,
          currentLevel
        )();
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [isOpen]);

  return (
    <Slideover
      isOpen={isOpen}
      contentClassName="p-0"
      onClose={onClose}
      overlayZindexClassname="z-56"
      wrapperClassName="w-512"
      hideHeader={false}
      title={intl.formatMessage({
        id: I18nKey.GENERIC_DETAILS,
      })}
      content={
        <>
          <div className="flex flex-col pt-10">
            <div className="flex flex-col gap-24">
              <div className="px-24">
                {suggestedAdgroup && (
                  <SuggestedAdgroupCell {...suggestedAdgroupProps} />
                )}
              </div>
              <LevelsController
                active={currentLevel}
                levels={getTabs({
                  keywordCount: totalKeywordCount,
                  SKUCount: skuCount,
                })}
                onChange={onLevelChange}
              />
              <div>{getTabsData()}</div>
            </div>
          </div>
        </>
      }
      showTextLink={showTextLink}
      textLinkContent={textLinkContent ? textLinkContent : undefined}
      onViewClick={onViewClick}
      showBackOnHeader
      onBack={onClose}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
      hideFooter={false}
    />
  );
};

ViewSkuAndKeywordSlideOutV2.displayName = 'ViewSkuAndKeywordSlideOutV2';
