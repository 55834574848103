import React from 'react';
import { useIntl } from 'react-intl';

import { FAMApiClient } from '../../../../../lib/clients/FAMApiClient';
import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../../ConnectModalWrapper';
import { ModalContent } from './content';
import { ModalFooter } from './footer';

export interface AuthorizeWalmartModalOldProps {
  readonly famClient: FAMApiClient;
  readonly accountId: string;
  readonly goBack: () => void;
  readonly authorize: () => void;
  readonly closeModal?: () => void;
}

export const AuthorizeWalmartModalOld: React.FC<
  AuthorizeWalmartModalOldProps
> = ({ famClient, accountId, goBack, authorize, closeModal }) => {
  const intl = useIntl();

  return (
    <ConnectModalWrapper
      dataTestId="authorize_walmart_modal_old"
      isOpen
      closeModal={closeModal}
      headerText={intl.formatMessage({
        id: I18nKey.FLASH_MODALS_WALMART_MODAL_HEADER,
      })}
      content={
        <ModalContent
          authorize={authorize}
          famClient={famClient}
          accountId={accountId}
        />
      }
      footer={<ModalFooter goBack={goBack} />}
      headerChannelType={FlywheelSalesChannel.Walmart}
    />
  );
};
AuthorizeWalmartModalOld.displayName = 'AuthorizeWalmartModalOld';

export const isEmailValid = (email: string) => {
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return email.match(emailRegex) !== null;
};
