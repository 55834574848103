import {
  AmazonLogomarkEnabledIcon,
  PaletteColor,
  SelectV2OptionProps,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';
import {
  AllSalesChannel,
  FlywheelSalesChannel,
  MerchantCountry,
  MerchantType,
} from '../types/Fam';
import I18nKey from '../types/I18nKey';
import isUndefined from 'lodash/isUndefined';
import sortBy from 'lodash/sortBy';
import { IntlShape } from 'react-intl';
import { getCountryFlagIcon } from './countryFlags';
import { RefObject } from 'react';

export const BROADCAST_ACCOUNT_SWITCH_NAME =
  process.env.REACT_BROADCAST_ACCOUNT_SWITCH_NAME || '';

export const SALES_CHANNEL_NAME_TO_I18NKEY_MAPPER = {
  [FlywheelSalesChannel.Amazon]: I18nKey.GENERIC_AMAZON,
  [FlywheelSalesChannel.Walmart]: I18nKey.GENERIC_WALMART,
};

const SALES_CHANNEL_TO_ICON_MAPPER = {
  [FlywheelSalesChannel.Amazon]: AmazonLogomarkEnabledIcon,
  [FlywheelSalesChannel.Walmart]: WalmartLogomarkEnabledIcon,
};

export const createMerchantDropdownOptionData = (
  merchant: MerchantCountry,
  salesChannels: AllSalesChannel[]
): Pick<
  SelectV2OptionProps<string>,
  'groupName' | 'secondaryIcon' | 'icon'
> => {
  const maybeMerchantSalesChannel = salesChannels.find(
    (salesChannel) => salesChannel.id === merchant.salesChannelId
  );

  return {
    groupName: !isUndefined(maybeMerchantSalesChannel)
      ? SALES_CHANNEL_NAME_TO_I18NKEY_MAPPER[
          maybeMerchantSalesChannel.name as FlywheelSalesChannel
        ]
      : undefined,
    icon: !isUndefined(maybeMerchantSalesChannel)
      ? SALES_CHANNEL_TO_ICON_MAPPER[
          maybeMerchantSalesChannel.name as FlywheelSalesChannel
        ]
      : undefined,
    secondaryIcon: !isUndefined(maybeMerchantSalesChannel)
      ? getCountryFlagIcon(merchant.country)
      : undefined,
  };
};

export const generateMerchantPickerOptions = (
  merchants: MerchantCountry[] = [],
  salesChannels: AllSalesChannel[],
  intl: IntlShape
): SelectV2OptionProps<string>[] => {
  return sortBy(
    merchants.map((merchant) => {
      const { groupName, icon, secondaryIcon } =
        createMerchantDropdownOptionData(merchant, salesChannels);
      return {
        value: merchant.merchantCountryId,
        label: merchant.merchantName,
        groupName: groupName && intl.formatMessage({ id: groupName }),
        icon,
        secondaryIcon,
        pillProps: {
          text: merchant.merchantType === MerchantType.Seller ? '3P' : '1P',
          color: PaletteColor.grey,
          className: 'ml-8',
        },
      };
    }),
    [({ groupName }) => groupName, ({ label }) => label]
  );
};

export const scrollToRefInstant = (ref: RefObject<HTMLDivElement>) =>
  ref.current?.scrollIntoView({
    block: 'start',
  });
