import { Factory } from 'fishery';

import factories, { SALES_CHANNEL_IDS } from '../';
import { FlywheelSalesChannel } from '../../types/AOSharedTypes';
import { MerchantCountry, SalesChannel } from '../../types/Fam';

interface SaleChannelTransientProps {
  readonly unqiueIdentifier: string;
  channelId: string;
}

export default Factory.define<SalesChannel, SaleChannelTransientProps>(
  ({ transientParams }) => {
    const { unqiueIdentifier, channelId } = transientParams;
    let firstMerchantCountry: MerchantCountry;
    if (channelId) {
      switch (channelId) {
        case SALES_CHANNEL_IDS.WALMART:
          firstMerchantCountry = factories.FAMMerchantCountry.build(
            {},
            {
              transient: {
                channelId: SALES_CHANNEL_IDS.WALMART,
              },
            }
          );
          return {
            salesChannelId: SALES_CHANNEL_IDS.WALMART,
            name: FlywheelSalesChannel.Walmart,
            merchantCountries: [firstMerchantCountry],
          };
        case SALES_CHANNEL_IDS.AMAZON:
          firstMerchantCountry = factories.FAMMerchantCountry.build(
            {},
            {
              transient: {
                channelId: SALES_CHANNEL_IDS.AMAZON,
              },
            }
          );
          return {
            salesChannelId: SALES_CHANNEL_IDS.AMAZON,
            name: FlywheelSalesChannel.Amazon,
            merchantCountries: [firstMerchantCountry],
          };
      }
    }
    firstMerchantCountry = factories.FAMMerchantCountry.build({});
    return {
      salesChannelId: unqiueIdentifier
        ? `salesChannelId ${unqiueIdentifier}`
        : SALES_CHANNEL_IDS.WALMART,
      name: unqiueIdentifier
        ? `name ${unqiueIdentifier}`
        : FlywheelSalesChannel.Walmart,
      merchantCountries: [firstMerchantCountry],
    };
  }
);
