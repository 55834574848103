import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  REQUEST_DATE_FORMAT,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import {
  NEGETIVE_TARGETS_MATCH_TYPE,
  TARGETS_API_COLUMN_NAME,
  getPerformanceMetricValue,
  isNegativeProductTarget,
} from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) =>
  makeCurrencyColumn<TargetsDetails>(
    ({
      targetDetails: { startDate, matchType, entityType },
      targetPerformance: { adSpend },
    }) => {
      const campaignStartDate = startDate
        ? DateTime.fromFormat(startDate, REQUEST_DATE_FORMAT)
        : undefined;

      const isNegetiveTarget = matchType
        ? NEGETIVE_TARGETS_MATCH_TYPE.includes(matchType)
        : !isNil(entityType) && isNegativeProductTarget(entityType);

      const performanceMetricValue = getPerformanceMetricValue(
        props.selectedEndDate,
        campaignStartDate,
        adSpend?.amount ? Number(adSpend?.amount) : undefined,
        isNegetiveTarget
      );

      return isNil(performanceMetricValue)
        ? undefined
        : {
            amount: performanceMetricValue,
            currency:
              getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
              adSpend?.currency ||
              CURRENCY_CODE,
          };
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const adSpendColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.AdSpend,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_SPEND,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const adSpendFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    TARGETS_API_COLUMN_NAME.AdSpend,
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_AD_SPEND,
    currency,
    isValidNumber(0)
  );
