import {
  CampaignTargetType,
  FilterType,
} from '../lib/types/AIPoweredSharedTypes';
import { TableCell } from '../containers/tableV2/ducks/types';
import { PartialRecord } from '../lib/types';
import { MerchantCountryCode } from '../lib/types/CommonSharedTypes';
import { FlywheelSalesChannel } from '../lib/types/Fam';
import { ReactComponent as LightBulb } from '../img/LightBulb.svg';
import { ReactComponent as StatusGreen } from '../img/StatusGreen.svg';
import { ReactComponent as StatusRed } from '../img/StatusRed.svg';

export enum ProductSettingsV2Route {
  AIProductSettingsV2 = 'ai-settings',
  AIProductGroupSettings = 'ai-settings/product-group',
}

export enum ProductGroupSettingsTabValues {
  Advertising = 'advertising',
  BrandsAndCompetitors = 'brands-and-competitors',
  Cogs = 'cogs',
}

export const PRODUCT_GROUP_ID_QUERY_PARAM_KEY = 'product_group_id';

export enum EditableColumnsAIPoweredSettings {
  dailyBudget = 'dailyBudget',
  acosTarget = 'acosTarget',
}
export interface TableCellChangeAIPoweredSettings {
  [key: string]: PartialRecord<EditableColumnsAIPoweredSettings, TableCell>;
}

export const FILTER_BUTTONS = [
  {
    name: 'All',
    value: FilterType.All,
    count: 0,
  },
  {
    name: 'Recommendations',
    value: FilterType.Suggestions,
    count: 0,
    icon: LightBulb,
  },
  {
    name: 'Advertised',
    value: FilterType.Advertised,
    count: 0,
    icon: StatusGreen,
  },
  {
    name: 'Not advertised',
    value: FilterType.NotAdvertised,
    count: 0,
    icon: StatusRed,
  },
];

export const SALES_CHANNEL_ID_PARAM = 'sales_channel_id';
export const MERCHANT_COUNTRY_ID_PARAM = 'merchant_country_id';
export const SKU_PARAM = 'sku';
export const NAME_PARAM = 'name';
export const MIN_BID_DEFAULT = 0.02;
export const MAX_BID_DEFAULT = 5.0;

export interface Link {
  readonly name: string;
  readonly url: string;
}

export interface SuggestionDisplay {
  readonly showSuggestion: boolean;
}

export enum UpdateAction {
  Add = 'add',
  Delete = 'delete',
}

export enum CampaignEditType {
  CampaignName = 'campaignName',
  DailyBudget = 'dailyBudget',
}
export enum AdGroupEditType {
  Name = 'name',
  AcosTarget = 'acosTarget',
  BidAutomationEnabled = 'bidAutomationEnabled',
  MinBid = 'minBid',
  MaxBid = 'maxBid',
  BiddingStrategyRequestAction = 'biddingStrategyRequestAction',
}

export const FLYWHEEL_SETTINGS_HELP_LINK =
  'https://intercom.help/flywheel-20/en/articles/5643813-what-is-an-acos-target';

export const SYNC_STATUS_POLLING_INTERVAL = 2000;

export const BID_CONSTRAINTS = {
  [FlywheelSalesChannel.Amazon]: [
    {
      minBid: 0.02,
      maxBid: 5,
      countryCodes: [
        MerchantCountryCode.US,
        MerchantCountryCode.FR,
        MerchantCountryCode.NL,
        MerchantCountryCode.GB,
        MerchantCountryCode.DE,
        MerchantCountryCode.ES,
        MerchantCountryCode.IT,
        MerchantCountryCode.CA,
        MerchantCountryCode.SG,
      ],
    },
    {
      minBid: 0.1,
      maxBid: 100,
      countryCodes: [MerchantCountryCode.MX],
    },
    {
      minBid: 0.07,
      maxBid: 25,
      countryCodes: [MerchantCountryCode.BR],
    },
    {
      minBid: 0.02,
      maxBid: 20,
      countryCodes: [MerchantCountryCode.PL],
    },
    {
      minBid: 0.18,
      maxBid: 50,
      countryCodes: [MerchantCountryCode.SE],
    },
    {
      minBid: 0.24,
      maxBid: 20,
      countryCodes: [MerchantCountryCode.AE],
    },
    {
      minBid: 1,
      maxBid: 375,
      countryCodes: [MerchantCountryCode.IN],
    },
    {
      minBid: 0.1,
      maxBid: 20,
      countryCodes: [MerchantCountryCode.SA],
    },
    {
      minBid: 0.1,
      maxBid: 5,
      countryCodes: [MerchantCountryCode.AU],
    },
    {
      minBid: 2,
      maxBid: 600,
      countryCodes: [MerchantCountryCode.JP],
    },
  ],
  [FlywheelSalesChannel.Walmart]: {
    [CampaignTargetType.Auto]: [
      {
        minBid: 0.2,
        maxBid: 20,
        countryCodes: [MerchantCountryCode.US],
      },
    ],
    [CampaignTargetType.Manual]: [
      {
        minBid: 0.3,
        maxBid: 20,
        countryCodes: [MerchantCountryCode.US],
      },
    ],
  },
};

export const DEBOUNCE_AFTER_IN_MILLISECONDS = 500;
export const DEFAULT_MIN_BID = 0.02;
export const DEFAULT_MAX_BID = 1000;

export const SELECTED_FILTER = 'selectedFilter';
