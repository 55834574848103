import { useContext } from 'react';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { TargetsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  MerchantContext,
  MerchantContextState,
} from '../../../merchantsProvider';
import { TARGETS_API_COLUMN_NAME, getMerchantName } from '../utils';

export const RowCellElement: React.FC<TargetsDetails> = ({
  targetPerformance,
  targetDetails,
  targetId,
  channelSettings,
}) => {
  const targetsData: TargetsDetails = {
    targetPerformance,
    targetDetails,
    targetId,
    channelSettings,
  };

  const merchantContext = useContext<MerchantContextState>(MerchantContext);

  const merchantDetails = merchantContext.merchantDetails;

  return makeTextColumn((data: TargetsDetails) =>
    getMerchantName(merchantDetails, data.targetDetails.merchantCountryId)
  )(targetsData);
};
RowCellElement.displayName = 'RowCellElement';

export const merchantNameColumn: FlywheelTableColumn<TargetsDetails> = {
  columnName: TARGETS_API_COLUMN_NAME.MerchantName,
  isSortable: false,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_MERCHANT_NAME,
  RowCellElement,
  gridColumnWidth: '240px',
};
