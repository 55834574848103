import {
  AmazonLogomarkDisabledIcon,
  AmazonLogomarkEnabledIcon,
  ColumnManagerOption,
  IconSize,
  MultiStateIconProps,
  WalmartLogomarkDisabledIcon,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';
import { DateTime } from 'luxon';
import { MerchantCountriesResponse } from '../../../../lib/clients/types';
import { MerchantCountryCode } from '../../../../lib/types/AOSharedTypes';
import {
  MONETARY_FRACTION_DIGITS,
  PERCENTAGE_FRACTION_DIGITS,
} from '../../../../lib/types/CommonSharedTypes';
import {
  AccountSalesChannelPaginatedResult,
  FlywheelSalesChannel,
  MerchantCountry,
  SalesChannelData,
} from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { MoneyWithAmountInString } from '../../../../lib/types/Money';
import {
  SkuCatalogData,
  SkuViewType,
} from '../../../../lib/types/SKUSharedTypes';
import { SkuDetailsBottomSectionProps } from './skuDetailsSidebar/skuDetailsBottomSection';
import { SkuDetailsTopSectionProps } from './skuDetailsSidebar/skuDetailsTopSection';
import { CurrencyCode } from '../../../../lib/utilities/currency';
import { FilterFieldMapper } from '../../../../lib/types/Filter';

export const CURRENCY_CODE = 'USD';
export const SKU_CATALOG_EXT_ITEM_ID_FILTER_FIELD = 'extItemId';
export const SKU_CATALOG_TABLE_SEARCH_BY_KEY = 'searchText';
export const SKU_AD_PERFORMANCE_TABLE_SEARCH_BY_KEY = 'searchText';
export const SKU_API_REQUEST_DATE_FORMAT = 'yyyy-MM-dd';

export const PRODUCT_GROUP_ID_QUERY_PARAM_KEY = 'product_group_id';
export const PRODUCT_CATALOG_ID_QUERY_PARAM_KEY = 'product_catalog_id';
export const MERCHANT_COUNTRY_ID_QUERY_PARAM_KEY = 'merchant_country_id';

export interface SkuDetailsLocationState {
  readonly name?: string;
  readonly sku?: string;
  readonly imageUrl?: string;
  readonly salesChannelId?: string;
  readonly asin?: string;
  readonly extItemType?: string;
  readonly channel?: string;
  readonly skuChildren?: SkuCatalogData[];
}

export enum DataFetcherIdentifier {
  TotalCurrent = 'TotalCurrent',
  TotalPrevious = 'TotalPrevious',
}

export interface DateRange {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly minDate?: DateTime;
  readonly maxDate?: DateTime;
}

export const DEFAULT_CURRENCY = 'USD';

export enum SkuCatalogApiColumnValue {
  AdvertisedStatus = 'advertisedStatus',
  AdSales = 'adSales',
  AdSpend = 'adSpend',
  ACOS = 'acos',
  ConversionRate = 'conversionRate',
  CampaignCount = 'campaignCount',
  IsActiveCampaigns = 'isActiveCampaigns',
  AdGroupCount = 'adGroupCount',
  Cogs = 'cogs',
  EstimatedFees = 'estimatedFees',
  InventoryValue = 'inventoryValue',
  CurrentInventoryQuantity = 'inventoryQuantity',
  SKU = 'sku',
  ParentSku = 'parentSku',
  ParentAsin = 'parentAsin',
  Tacos = 'tacos',
  AverageOrderPrice = 'averageOrderPrice',
  TotalSales = 'totalSales',
  EstimatedGrossProfit = 'estimatedGrossProfit',
  UnitsSold = 'unitsSold',
  ShippedCogs = 'shippedCogs',
  ShippedUnits = 'shippedUnits',
  EstimatedGrossMargin = 'estimatedGrossMargin',
  EstimatedPreAdGrossMargin = 'estimatedPreAdGrossMargin',
  Name = 'name',
  ImageUrl = 'imageUrl',
  ExternalItemId = 'extItemId',
  ExternalItemType = 'extItemType',
  SalesChannelId = 'salesChannelId',
  SalesChannelName = 'salesChannelName',
  ROAS = 'roas',
  AdConversions = 'adConversions',
  AdUnitsSold = 'adUnitsSold',
  Impressions = 'impressions',
  Clicks = 'clicks',
  CostPerClick = 'costPerClick',
  ClickThroughRate = 'clickThroughRate',
  GMROI = 'gmroi',
  DaysInInventory = 'daysInInventory',
  TeikaGroupName = 'teikaGroupName',
}

export enum ColumnGroupIdentifier {
  Details = 'Details',
  Product = 'Product',
  Profitability = 'Profitability',
  PlatformFees = 'PlatformFees',
  Advertisement = 'Advertisement',
  Inventory = 'Inventory',
}

export const API_COLUMN_VALUE_TO_COLUMN_GROUP_MAPPER: Record<
  SkuCatalogApiColumnValue,
  ColumnGroupIdentifier
> = {
  [SkuCatalogApiColumnValue.SKU]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.ParentSku]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.ParentAsin]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.Name]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.ImageUrl]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.ExternalItemId]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.ExternalItemType]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.SalesChannelId]: ColumnGroupIdentifier.Details,
  [SkuCatalogApiColumnValue.SalesChannelName]: ColumnGroupIdentifier.Details,

  [SkuCatalogApiColumnValue.Tacos]: ColumnGroupIdentifier.Product,
  [SkuCatalogApiColumnValue.AverageOrderPrice]: ColumnGroupIdentifier.Product,

  [SkuCatalogApiColumnValue.TotalSales]: ColumnGroupIdentifier.Profitability,
  [SkuCatalogApiColumnValue.EstimatedGrossProfit]:
    ColumnGroupIdentifier.Profitability,
  [SkuCatalogApiColumnValue.UnitsSold]: ColumnGroupIdentifier.Profitability,
  [SkuCatalogApiColumnValue.EstimatedGrossMargin]:
    ColumnGroupIdentifier.Profitability,
  [SkuCatalogApiColumnValue.EstimatedPreAdGrossMargin]:
    ColumnGroupIdentifier.Profitability,
  [SkuCatalogApiColumnValue.ShippedCogs]: ColumnGroupIdentifier.Profitability,
  [SkuCatalogApiColumnValue.ShippedUnits]: ColumnGroupIdentifier.Profitability,

  [SkuCatalogApiColumnValue.Cogs]: ColumnGroupIdentifier.PlatformFees,
  [SkuCatalogApiColumnValue.EstimatedFees]: ColumnGroupIdentifier.PlatformFees,

  [SkuCatalogApiColumnValue.AdvertisedStatus]:
    ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.AdSales]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.AdSpend]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.ACOS]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.ConversionRate]:
    ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.CampaignCount]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.IsActiveCampaigns]:
    ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.AdGroupCount]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.ROAS]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.AdConversions]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.AdUnitsSold]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.Impressions]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.Clicks]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.CostPerClick]: ColumnGroupIdentifier.Advertisement,
  [SkuCatalogApiColumnValue.ClickThroughRate]:
    ColumnGroupIdentifier.Advertisement,

  [SkuCatalogApiColumnValue.InventoryValue]: ColumnGroupIdentifier.Inventory,
  [SkuCatalogApiColumnValue.GMROI]: ColumnGroupIdentifier.Inventory,
  [SkuCatalogApiColumnValue.CurrentInventoryQuantity]:
    ColumnGroupIdentifier.Inventory,
  [SkuCatalogApiColumnValue.DaysInInventory]: ColumnGroupIdentifier.Inventory,
  [SkuCatalogApiColumnValue.TeikaGroupName]: ColumnGroupIdentifier.Details,
};

export interface ColumnGrouping {
  readonly [ColumnGroupIdentifier.Details]: SkuCatalogApiColumnValue[];
  readonly [ColumnGroupIdentifier.Advertisement]: SkuCatalogApiColumnValue[];
  readonly [ColumnGroupIdentifier.Inventory]: SkuCatalogApiColumnValue[];
  readonly [ColumnGroupIdentifier.PlatformFees]: SkuCatalogApiColumnValue[];
  readonly [ColumnGroupIdentifier.Product]: SkuCatalogApiColumnValue[];
  readonly [ColumnGroupIdentifier.Profitability]: SkuCatalogApiColumnValue[];
}

export interface ColumnGroupingSkuOverview {
  readonly [ColumnGroupIdentifier.Details]: SkuOverviewApiColumnValue[];
  readonly [ColumnGroupIdentifier.Advertisement]: SkuOverviewApiColumnValue[];
  readonly [ColumnGroupIdentifier.Inventory]: SkuOverviewApiColumnValue[];
  readonly [ColumnGroupIdentifier.PlatformFees]: SkuOverviewApiColumnValue[];
  readonly [ColumnGroupIdentifier.Product]: SkuOverviewApiColumnValue[];
  readonly [ColumnGroupIdentifier.Profitability]: SkuOverviewApiColumnValue[];
}

export enum SkuOverviewApiColumnValue {
  TotalSales = 'totalSales',
  EstimatedGrossProfit = 'estimatedGrossProfit',
  UnitsSold = 'unitsSold',
  ShippedUnits = 'shippedUnits',
  ShippedCogs = 'shippedCogs',
  EstimatedGrossMargin = 'estimatedGrossMargin',
  EstimatedPreAdGrossMargin = 'estimatedPreAdGrossMargin',
  AdSales = 'adSales',
  AdSpend = 'adSpend',
  ACOS = 'acos',
  ConversionRate = 'conversionRate',
  CampaignCount = 'campaignCount',
  AdGroupCount = 'adGroupCount',
  COGS = 'cogs',
  TACOS = 'tacos',
  Channel = 'salesChannelName',
  Merchant = 'merchantCountryName',
  AverageOrderPrice = 'averageOrderPrice',
  EstimatedFees = 'estimatedFees',
  InventoryValue = 'inventoryValue',
  CurrentInventoryQuantity = 'inventoryQuantity',
  ROAS = 'roas',
  AdConversions = 'adConversions',
  AdUnitsSold = 'adUnitsSold',
  Impressions = 'impressions',
  Clicks = 'clicks',
  CostPerClick = 'costPerClick',
  ClickThroughRate = 'clickThroughRate',
}

export const SKU_OVERVIEW_API_COLUMN_VALUE_TO_COLUMN_GROUP_MAPPER: Record<
  SkuOverviewApiColumnValue,
  ColumnGroupIdentifier
> = {
  [SkuOverviewApiColumnValue.TACOS]: ColumnGroupIdentifier.Product,
  [SkuOverviewApiColumnValue.AverageOrderPrice]: ColumnGroupIdentifier.Product,

  [SkuOverviewApiColumnValue.TotalSales]: ColumnGroupIdentifier.Profitability,
  [SkuOverviewApiColumnValue.EstimatedGrossProfit]:
    ColumnGroupIdentifier.Profitability,
  [SkuOverviewApiColumnValue.UnitsSold]: ColumnGroupIdentifier.Profitability,
  [SkuOverviewApiColumnValue.EstimatedGrossMargin]:
    ColumnGroupIdentifier.Profitability,
  [SkuOverviewApiColumnValue.EstimatedPreAdGrossMargin]:
    ColumnGroupIdentifier.Profitability,
  [SkuOverviewApiColumnValue.ShippedCogs]: ColumnGroupIdentifier.Profitability,
  [SkuOverviewApiColumnValue.ShippedUnits]: ColumnGroupIdentifier.Profitability,

  [SkuOverviewApiColumnValue.COGS]: ColumnGroupIdentifier.PlatformFees,
  [SkuOverviewApiColumnValue.EstimatedFees]: ColumnGroupIdentifier.PlatformFees,

  [SkuOverviewApiColumnValue.AdSales]: ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.AdSpend]: ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.ACOS]: ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.ConversionRate]:
    ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.CampaignCount]:
    ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.AdGroupCount]: ColumnGroupIdentifier.Advertisement,

  [SkuOverviewApiColumnValue.InventoryValue]: ColumnGroupIdentifier.Inventory,
  [SkuOverviewApiColumnValue.CurrentInventoryQuantity]:
    ColumnGroupIdentifier.Inventory,

  [SkuOverviewApiColumnValue.Channel]: ColumnGroupIdentifier.Details,
  [SkuOverviewApiColumnValue.Merchant]: ColumnGroupIdentifier.Details,
  [SkuOverviewApiColumnValue.ROAS]: ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.AdConversions]:
    ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.AdUnitsSold]: ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.Impressions]: ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.Clicks]: ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.CostPerClick]: ColumnGroupIdentifier.Advertisement,
  [SkuOverviewApiColumnValue.ClickThroughRate]:
    ColumnGroupIdentifier.Advertisement,
};

export type SkuOverviewTableExtraProps = {
  readonly showPreviousData: boolean;
};

export enum MetricType {
  Numeric,
  Money,
  Percent,
}

export const METRIC_TYPE_TO_DECIMAL_PLACE_LIMIT: Record<MetricType, number> = {
  [MetricType.Numeric]: PERCENTAGE_FRACTION_DIGITS,
  [MetricType.Money]: MONETARY_FRACTION_DIGITS,
  [MetricType.Percent]: PERCENTAGE_FRACTION_DIGITS,
};

export type Metric = number | MoneyWithAmountInString;

export const SALES_CHANNEL_TO_MULTI_STATE_ICON_MAP: Record<
  FlywheelSalesChannel | string,
  MultiStateIconProps
> = {
  [FlywheelSalesChannel.Amazon]: {
    currentState: 'enabled',
    stateIcons: {
      enabled: AmazonLogomarkEnabledIcon,
      disabled: AmazonLogomarkDisabledIcon,
    },
    size: IconSize.Medium,
  },
  [FlywheelSalesChannel.Walmart]: {
    currentState: 'enabled',
    stateIcons: {
      enabled: WalmartLogomarkEnabledIcon,
      disabled: WalmartLogomarkDisabledIcon,
    },
    size: IconSize.Medium,
  },
};

export enum SkuCatalogRoutes {
  Catalog = 'catalog/:merchantType/*',
  CatalogOld = 'catalog/*',
  ProductInsights = 'insights/*',
  IODashboard = 'dashboard',
  BrandAndCompetitors = 'brands-and-competitors/*',
}

export enum ProductInsightsInternalRoutes {
  OutOfStock = '/out-of-stock',
  CreateYourQuery = '/create-your-query',
  CustomInsights = '/custom-insights',
  CustomInsightDetails = '/custom-insights/details',
}

export enum SkuCatalogDetailsRoutes {
  SkuOverview = ':skuId',
}

export type SkuCatalogDetailsRouteParams = {
  skuId: string;
};

export const SKU_DETAILS_ROUTES_LIST = [SkuCatalogDetailsRoutes.SkuOverview];

export interface ColumnManagerOptionWithIsSelected extends ColumnManagerOption {
  readonly isSelected?: boolean;
  readonly value: SkuCatalogApiColumnValue;
}

export interface SalesChannelDataWithChannelName extends SalesChannelData {
  readonly salesChannelName: string;
}

export interface UserAccountSalesChannelsInfo {
  readonly merchantCountriesResponse: MerchantCountriesResponse;
  readonly salesChannelsTableData: AccountSalesChannelPaginatedResultWithChannelName;
}

export interface AccountSalesChannelPaginatedResultWithChannelName
  extends AccountSalesChannelPaginatedResult {
  readonly isError?: boolean;
  readonly items: SalesChannelDataWithChannelName[];
}

export interface AccountSalesChannelPaginatedResultWithError
  extends AccountSalesChannelPaginatedResult {
  readonly isError?: boolean;
  readonly salesChannel?: string;
}

export type SkuAdPerformanceTableExtraProps = {
  readonly showPreviousData: boolean;
  readonly merchantInfo: MerchantCountry[];
};

export enum SkuAdPerformanceApiColumnValue {
  SalesChannelName = 'salesChannelName',
  MerchantCountryName = 'merchantCountryName',
  CampaignName = 'campaignName',
  CampaignType = 'campaignType',
  AdGroupName = 'adGroupName',
  AdStatus = 'adStatus',
  Impressions = 'impressions',
  Clicks = 'clicks',
  ClickThroughRate = 'clickThroughRate',
  CostPerClick = 'avgCostPerClick',
  AdSales = 'adSalesTotal',
  AdSpend = 'adSpend',
  ACOS = 'acosTotal',
  AdConversions = 'adConversions',
  ConversionRate = 'conversionRate',
}

export interface SkuOverviewColumnManagerOptionWithIsSelected
  extends ColumnManagerOption {
  readonly isSelected?: boolean;
  readonly value: SkuOverviewApiColumnValue;
}

export interface SkuAdPerformanceColumnManagerOptionsWithIsSelected
  extends ColumnManagerOption {
  readonly isSelected?: boolean;
  readonly value: SkuAdPerformanceApiColumnValue;
}

export enum SkuPageIdentifier {
  Catalog = 'catalog',
  Overview = 'overview',
  Inventory = 'inventory',
  AdPerformance = 'ad-performance',
}

export interface LoadSkuCatalogDataRequestParams {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly currency: CurrencyCode;
  readonly merchantsId: string[];
  readonly selectedColumns: SkuCatalogApiColumnValue[];
  readonly showPreviousData: boolean;
  readonly skuView: SkuViewType;
}

export type SkuDetailSidebarSummaryProps = SkuDetailsBottomSectionProps &
  SkuDetailsTopSectionProps;

export interface SkuDetailsSidebarData extends SkuDetailSidebarSummaryProps {
  readonly productGroupId: string;
}

export const searchTextfilterFieldMapper: FilterFieldMapper[] = [
  {
    alias: 'searchText',
    field: 'searchText',
  },
];

export const DEFAULT_SKU_ELAPSED_TIME_LIMIT_IN_MINUTES = '60';

export enum SKUCatalogDetailsViews {
  Parent,
  SKUs,
}

export const MAP_INDEX_TO_SKU_CATALOG_DETAILS_VIEW: Record<
  number,
  SKUCatalogDetailsViews
> = {
  0: SKUCatalogDetailsViews.Parent,
  1: SKUCatalogDetailsViews.SKUs,
};

export const skuCatalogColumns = (
  show1PColumns?: boolean
): SkuCatalogApiColumnValue[] => [
  SkuCatalogApiColumnValue.AdSales,
  SkuCatalogApiColumnValue.AdSpend,
  SkuCatalogApiColumnValue.ACOS,
  SkuCatalogApiColumnValue.ROAS,
  SkuCatalogApiColumnValue.AdConversions,
  SkuCatalogApiColumnValue.AdUnitsSold,
  SkuCatalogApiColumnValue.Impressions,
  SkuCatalogApiColumnValue.Clicks,
  SkuCatalogApiColumnValue.CostPerClick,
  SkuCatalogApiColumnValue.ClickThroughRate,
  SkuCatalogApiColumnValue.ConversionRate,
  SkuCatalogApiColumnValue.CampaignCount,
  SkuCatalogApiColumnValue.AdGroupCount,
  SkuCatalogApiColumnValue.Cogs,
  ...(!show1PColumns ? [SkuCatalogApiColumnValue.EstimatedFees] : []),
  SkuCatalogApiColumnValue.InventoryValue,
  SkuCatalogApiColumnValue.CurrentInventoryQuantity,
  SkuCatalogApiColumnValue.SKU,
  SkuCatalogApiColumnValue.Tacos,
  SkuCatalogApiColumnValue.AverageOrderPrice,
  SkuCatalogApiColumnValue.TotalSales,
  SkuCatalogApiColumnValue.EstimatedGrossProfit,
  SkuCatalogApiColumnValue.UnitsSold,
  ...(show1PColumns
    ? [
        SkuCatalogApiColumnValue.ShippedUnits,
        SkuCatalogApiColumnValue.ShippedCogs,
      ]
    : []),
  SkuCatalogApiColumnValue.EstimatedGrossMargin,
  SkuCatalogApiColumnValue.EstimatedPreAdGrossMargin,
  SkuCatalogApiColumnValue.Name,
  SkuCatalogApiColumnValue.ImageUrl,
  SkuCatalogApiColumnValue.ExternalItemId,
  SkuCatalogApiColumnValue.ExternalItemType,
  SkuCatalogApiColumnValue.SalesChannelId,
  SkuCatalogApiColumnValue.SalesChannelName,
];

export const skuOverviewColumns = (
  show1PColumns?: boolean
): SkuOverviewApiColumnValue[] => [
  SkuOverviewApiColumnValue.TotalSales,
  SkuOverviewApiColumnValue.EstimatedGrossProfit,
  SkuOverviewApiColumnValue.UnitsSold,
  ...(show1PColumns
    ? [
        SkuOverviewApiColumnValue.ShippedUnits,
        SkuOverviewApiColumnValue.ShippedCogs,
      ]
    : []),
  SkuOverviewApiColumnValue.EstimatedGrossMargin,
  SkuOverviewApiColumnValue.EstimatedPreAdGrossMargin,
  SkuOverviewApiColumnValue.AdSales,
  SkuOverviewApiColumnValue.AdSpend,
  SkuOverviewApiColumnValue.ACOS,
  SkuOverviewApiColumnValue.ConversionRate,
  SkuOverviewApiColumnValue.CampaignCount,
  SkuOverviewApiColumnValue.AdGroupCount,
  SkuOverviewApiColumnValue.COGS,
  SkuOverviewApiColumnValue.TACOS,
  SkuOverviewApiColumnValue.Channel,
  SkuOverviewApiColumnValue.Merchant,
  SkuOverviewApiColumnValue.AverageOrderPrice,
  ...(!show1PColumns ? [SkuOverviewApiColumnValue.EstimatedFees] : []),
  SkuOverviewApiColumnValue.InventoryValue,
  SkuOverviewApiColumnValue.CurrentInventoryQuantity,
  SkuOverviewApiColumnValue.ROAS,
  SkuOverviewApiColumnValue.AdConversions,
  SkuOverviewApiColumnValue.AdUnitsSold,
  SkuOverviewApiColumnValue.Impressions,
  SkuOverviewApiColumnValue.Clicks,
  SkuOverviewApiColumnValue.CostPerClick,
  SkuOverviewApiColumnValue.ClickThroughRate,
];
export const skuAdPerformanceColumns: SkuAdPerformanceApiColumnValue[] = [
  SkuAdPerformanceApiColumnValue.SalesChannelName,
  SkuAdPerformanceApiColumnValue.MerchantCountryName,
  SkuAdPerformanceApiColumnValue.CampaignName,
  SkuAdPerformanceApiColumnValue.CampaignType,
  SkuAdPerformanceApiColumnValue.AdGroupName,
  SkuAdPerformanceApiColumnValue.AdStatus,
  SkuAdPerformanceApiColumnValue.Impressions,
  SkuAdPerformanceApiColumnValue.Clicks,
  SkuAdPerformanceApiColumnValue.ClickThroughRate,
  SkuAdPerformanceApiColumnValue.CostPerClick,
  SkuAdPerformanceApiColumnValue.AdSales,
  SkuAdPerformanceApiColumnValue.AdSpend,
  SkuAdPerformanceApiColumnValue.ACOS,
  SkuAdPerformanceApiColumnValue.AdConversions,
  SkuAdPerformanceApiColumnValue.ConversionRate,
];

export interface SkuCatalogRedirectLocationState {
  readonly key?: string;
  readonly openManageCogsModal?: boolean;
  readonly merchantCountryIds?: string[];
}

export interface GlossaryColumnDetail {
  readonly description?: I18nKey;
  readonly formula?: I18nKey;
}

export interface GlossaryColumnProps extends GlossaryColumnDetail {
  readonly name?: I18nKey;
  readonly details?: GlossaryColumnDetail[];
}

export interface GlossaryColumnConfigProps {
  readonly columnGroupHeader?: I18nKey;
  readonly columns: GlossaryColumnProps[];
}

export const SYNC_STATUS_POLLING_INTERVAL = 2000;
export const SYNC_STATUS_POLLING_MAX_TRIES = 50;

export enum PageStatus {
  Loading = 'Loading',
  Syncing = 'Syncing',
  MissingConnections = 'MissingConnections',
  DataAvailable = 'DataAvailable',
}

export interface MerchantCountryWithConnectionStatus extends MerchantCountry {
  readonly status: string;
}

export const DATA_AVAILABLE_STATES = ['Connected', 'Active'];
export const DATA_SYNCING_STATES = [
  'Syncing',
  'Validating',
  'Processing',
  'PendingWalmart',
  'PendingVerify',
  'PendingUser',
];

export const MERCHANT_COUNTRY_CODE_TO_BASE_EXTERNAL_URL_MAPPER: Record<
  MerchantCountryCode,
  string
> = {
  [MerchantCountryCode.US]: 'https://amazon.com/dp/',
  [MerchantCountryCode.CA]: 'https://amazon.ca/dp/',
  [MerchantCountryCode.MX]: 'https://amazon.com.mx/dp/',
  [MerchantCountryCode.BR]: 'https://amazon.com.br/dp/',
  [MerchantCountryCode.UK]: 'https://amazon.co.uk/dp/',
  [MerchantCountryCode.GB]: 'https://amazon.co.uk/dp/',
  [MerchantCountryCode.DE]: 'https://amazon.de/dp/',
  [MerchantCountryCode.FR]: 'https://amazon.fr/dp/',
  [MerchantCountryCode.ES]: 'https://amazon.es/dp/',
  [MerchantCountryCode.IT]: 'https://amazon.it/dp/',
  [MerchantCountryCode.NL]: 'https://amazon.nl/dp/',
  [MerchantCountryCode.JP]: 'https://amazon.co.jp/dp/',
  [MerchantCountryCode.AU]: 'https://amazon.com.au/dp/',
  [MerchantCountryCode.SG]: 'https://amazon.sg/dp/',
  [MerchantCountryCode.AE]: 'https://amazon.ae/dp/',
  [MerchantCountryCode.IN]: 'https://amazon.in/dp/',
  [MerchantCountryCode.KSA]: 'https://amazon.sa/dp/',
  [MerchantCountryCode.SA]: 'https://amazon.sa/dp/',
  [MerchantCountryCode.SE]: 'https://amazon.se/dp/',
  [MerchantCountryCode.BE]: 'https://amazon.com.be',
};

export interface UseMatchProps {
  [param: string]: string;
  uri: string;
  path: string;
}

export enum RowType {
  CustomTeikaGroup = 'CustomTeikaGroup',
  Parent = 'Parent',
  Child = 'Child',
}

export interface QuickAccessPcIdMap {
  [pcId: string]: {
    name: string;
    externalId: string;
    sku: string;
  };
}
