import { avgUnitSellPriceColumn } from './avgUnitSellPrice';
import { estPreAdGrossMarginColumn } from './estPreAdGrossMargin';
import { estUnitFeesColumn } from './estUnitFees';
import { lastUpdatedColumn } from './lastUpdated';
import { skuColumn } from './sku';
import { unitCogsColumn } from './unitCogs';

export const getEstAdGrossMarginColumns = () => {
  return [
    {
      header: '',
      columns: [skuColumn],
      highlightHeaderClassName: 'bg-white',
      classNames: '',
    },
    {
      header: '',
      highlightHeaderClassName: 'bg-white',
      columns: [avgUnitSellPriceColumn, estUnitFeesColumn],
    },
    {
      header: '',
      highlightHeaderClassName: 'bg-white',
      columns: [lastUpdatedColumn, unitCogsColumn, estPreAdGrossMarginColumn],
    },
  ];
};
