export enum GeneralQueryKeys {
  GetMetadataTagStatus = 'get_metadata_tag_status',
  GetDataSyncInfo = 'get_data_sync_info',
  GetMerchantCountries = 'get_merchant_countries',
}

export enum RecommendationsQuerykeys {
  GetRecommendationsBiddingCount = 'get_recommendations_bidding_count',
}

export enum CompassQueryKeys {
  GetSeasonalEvents = 'get_seasonal_events',
  GetAllSalesChannels = 'get_all_sales_channels',
  GetAllMerchants = 'get_all_merchants',
  GetMetricsData = 'get_metrics_data',
  GetProductVerticals = 'get_product_verticals',
  GetSubscriptionInformation = 'get_subscription_information',
  GetBids = 'get_bids',
  GetMerchantPerformanceData = 'get_merchant_performance_data',
  GetCampaignPerformanceData = 'get_campaign_performance_data',
  GetProductPerformanceData = 'get_product_performance_data',
}
