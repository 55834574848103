import {
  Icon,
  IconSize,
  QuestionCircleLinearIcon,
  TextLink,
  TextLinkSize,
} from '@teikametrics/tm-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/types/I18nKey';
import { MAP_FLYWHEEL_SALES_CHANNEL_TO_I18NKEY } from '../../lib/types/SalesChannels';
import { FlywheelSalesChannel } from '../../lib/types/Fam';

export interface NeedHelpProps {
  readonly channel?: FlywheelSalesChannel;
  readonly url: string;
  readonly dataTestId?: string;
}
export const NeedHelp: React.FC<NeedHelpProps> = ({
  channel,
  url,
  dataTestId,
}) => {
  const intl = useIntl();

  const [NEED_HELP] = [
    I18nKey.PRODUCT_SUBSCRIPTIONS_ADD_CONNECTION_MODAL_HEADER_CONNECT_DATA_HELP_SECTION_NEED_HELP,
  ].map((id) =>
    intl.formatMessage(
      { id },
      {
        channel: channel
          ? intl.formatMessage({
              id: MAP_FLYWHEEL_SALES_CHANNEL_TO_I18NKEY[channel],
            })
          : '',
      }
    )
  );
  return (
    <div className="flex flex-row p-8 gap-24 h-28 justify-center self-stretch items-center bg-purple-50 rounded-full text-grey-900">
      <Icon
        className="text-purple-500"
        svg={QuestionCircleLinearIcon}
        size={IconSize.Small}
      />
      <div className="flex flex-row justify-center grow shrink-0 basis-0">
        <TextLink
          textLabel={NEED_HELP}
          href={url}
          openNewTab={true}
          size={TextLinkSize.Small}
          dataTestId={`${dataTestId}_need_help`}
        />
      </div>
    </div>
  );
};
