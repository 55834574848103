import isUndefined from 'lodash/isUndefined';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Confetti,
  Icon,
  IconSize,
  Modal,
  Spinner,
  TextLink,
  TextLinkSize,
  ThunderIcon,
  ToastVariant,
  Type,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';

import {
  getCurrentAccountFromContext,
  getSignUpData,
} from '../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../containers/userProvider/userProvider';
import { createBillingApiClient } from '../../../lib/clients/BillingApiClient';
import { createFAMApiClient } from '../../../lib/clients/FAMApiClient';
import { PaginatedRequest } from '../../../lib/clients/types';
import {
  PlatformSubscriptionType,
  ZuoraSignature,
} from '../../../lib/types/Billing';

import { MetadataTags, Role } from '../../../lib/types/Fam';
import I18nKey from '../../../lib/types/I18nKey';
import { SortOrder } from '../../../lib/types/Sort';

import { ReactComponent as Rocket } from './rocket.svg';
import { PRODUCT_SUBSCRIPTIONS } from '../../../lib/types/AOSharedTypes';
import noop from 'lodash/noop';
import { isAIPlanEnabled } from '../../../containers/userProvider/selectors';
import {
  ZuoraResponseError,
  removeZuoraIframe,
  renderZuoraIframe,
} from '../../../lib/utilities/zuora';
import { StringMap } from '../../../lib/types';
import { segmentTrack } from '../../../lib/utilities/segment';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../containers/optimizelyProvider/optimizelyProvider';
import { OptimizelyFlags } from '../../../lib/types/OptimizelyFlags';
import { BillingCadencesModal } from './BillingCadencesModal';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../containers/notificationProvider';

export enum RiskFreeTrialModalCurrentStep {
  GET_STARTED = 'GET_STARTED',
  PAYMENT = 'PAYMENT',
  CONGRATS = 'CONGRATS',
  CONFETTI = 'CONFETTI',
}

interface RiskFreeTrailModalProps {
  readonly onContinueClicked?: () => void;
  readonly onPaymentSuccess?: () => void;
  readonly onCancelClicked?: () => void;
  readonly showIframe: boolean;
  readonly loadingIframe: boolean;
  readonly showErrorText: boolean;
  readonly currentStep: RiskFreeTrialModalCurrentStep;
  readonly onSetCurrentStep: (step: RiskFreeTrialModalCurrentStep) => void;
  readonly onConfettiSuccess?: () => void;
  updatingAccount: boolean;
}

interface RiskFreeModalWrapperProps {
  readonly onContinueClicked?: () => void;
  readonly onCancelClicked?: () => void;
  readonly enableAI?: boolean;
  readonly onPaymentSuccess?: () => void;
  readonly forcedCurrentStep?: RiskFreeTrialModalCurrentStep;
  readonly onConfettiSuccess?: () => void;
}

const DEFAULT_MAX_ITEMS_PER_PAGE = 100;

export const GradientText: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  children,
  ...rest
}) => (
  <div
    className={classNames(
      'text-transparent bg-clip-text bg-flywheel-orange-magenta-purple-gradient',
      className
    )}
    {...rest}
  >
    {children}
  </div>
);

export const RiskFreeModalWrapper: React.FC<RiskFreeModalWrapperProps> = ({
  onContinueClicked,
  onCancelClicked,
  forcedCurrentStep,
  onPaymentSuccess,
  onConfettiSuccess,
}) => {
  const intl = useIntl();
  const toasts = useContext<NotificationContextState>(NotificationContext);

  const [showIframe, setShowIframe] = useState(false);
  const [refId, setRefId] = useState<string>('');
  const [loadingIframe, setLoadingIframe] = useState<boolean>(false);
  const [zuoraSignature, setZuoraSignature] = useState<ZuoraSignature>();
  const [zuoraSuccess, setZuoraSuccess] = useState<boolean>(false);
  const [zuoraError, setZuoraError] = useState<ZuoraResponseError>();
  const [showErrorText, setShowErrorText] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<RiskFreeTrialModalCurrentStep>(
    forcedCurrentStep || RiskFreeTrialModalCurrentStep.GET_STARTED
  );
  const [updatingAccount, setUpdatingAccount] = useState(false);
  const [autoUpgradeAccount, setAutoUpgradeAccount] = useState(false);
  const [selectedPlatformSubscription, setSelectedPlatformSubscription] =
    useState<PlatformSubscriptionType | null>(null);

  const userContext = useContext<UserContextState>(UserContext);
  const account = getCurrentAccountFromContext(userContext)!;
  const accountId = account?.id;

  const famClient = createFAMApiClient(userContext.userInfo.idToken!);
  const billingClient = createBillingApiClient(userContext.userInfo.idToken!);

  const isAiEnabled = isAIPlanEnabled(userContext);
  const [hideFreeTrialContent, setHideFreeTrialContent] = useState<boolean>();

  useEffect(() => {
    const hideFreeTrialTag = userContext.accountMetadata?.find(
      (tag) => tag.tag === MetadataTags.HideFreeTrialContent
    );
    setHideFreeTrialContent(hideFreeTrialTag?.value === 'enabled');
  }, [userContext.accountMetadata]);

  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);
  const showSelfServiceAnnualBilling =
    optimizelyContext.featureFlags[OptimizelyFlags.SelfServiceAnnualBilling];

  const [PLAN_UPDATE_SUCCESS, SOMETHING_WENT_WRONG_TOAST_MESSAGE] = [
    I18nKey.BILLING_SELF_SERVICE_SUBSCRIPTION_PAGE_PLAN_UPDATE_SUCCESS,
    I18nKey.GENERIC_SOMETHING_WENT_WRONG_TOAST_MESSAGE,
  ].map((id) => intl.formatMessage({ id }));

  const trackPaymentAdded = async () => {
    const signupData = getSignUpData(userContext.userInfo.idToken!);
    const properties: StringMap<string> = {};

    if (signupData.query) {
      const impactClickId = signupData.query
        .split('&')
        .find((item) => item.includes('irclickid'));
      if (impactClickId) {
        properties['irclickid'] = impactClickId.split('=')[1];
      }
    }

    segmentTrack('Payment Added', properties);
  };

  const onPlatformSubscriptionTypeChange = (
    value: PlatformSubscriptionType
  ) => {
    setSelectedPlatformSubscription(value);
    setShowErrorText(false);
  };

  const upgradeAccount = async () => {
    if (showSelfServiceAnnualBilling && !selectedPlatformSubscription) return;
    setUpdatingAccount(true);
    const ai = await famClient
      .getProductVerticals()
      .then((verticals) =>
        verticals.find((v) => v.name === PRODUCT_SUBSCRIPTIONS.AI)
      );
    await famClient.subscriptionsAdd(accountId!!, [ai!.id]).catch(noop);
    userContext.refreshUserContext();
    if (onPaymentSuccess) {
      onPaymentSuccess();
    }
    setUpdatingAccount(false);
    setCurrentStep(RiskFreeTrialModalCurrentStep.CONFETTI);
  };

  const updatePlatformSubscriptionForExistingUsers = async () => {
    if (!selectedPlatformSubscription) return;
    try {
      setUpdatingAccount(true);
      await billingClient.updatePlatformSubscription(
        accountId,
        selectedPlatformSubscription
      );
      toasts.addNotification({
        headline: PLAN_UPDATE_SUCCESS,
        description: '',
        type: Type.Success,
        variant: ToastVariant.Simple,
      });
      userContext.refreshUserContext();
    } catch (error) {
      if ((error as any).response.status === 400) {
        toasts.addNotification({
          headline: (error as any).response.data.message,
          description: '',
          type: Type.Attention,
          variant: ToastVariant.Simple,
        });
      } else {
        toasts.addNotification({
          headline: SOMETHING_WENT_WRONG_TOAST_MESSAGE,
          description: '',
          type: Type.Attention,
          variant: ToastVariant.Simple,
        });
      }
      throw error; // Re-throw the error to stop further execution
    } finally {
      setUpdatingAccount(false);
    }
  };

  const onGetStarted = async () => {
    if (showSelfServiceAnnualBilling && !selectedPlatformSubscription) {
      setShowErrorText(true);
    } else if (userContext.billingInfo && isAiEnabled) {
      if (showSelfServiceAnnualBilling) {
        try {
          await updatePlatformSubscriptionForExistingUsers();
        } catch {
          return;
        }
      }
      setCurrentStep(RiskFreeTrialModalCurrentStep.CONFETTI);
    } else if (autoUpgradeAccount) {
      upgradeAccount();
    } else {
      setShowErrorText(false);
      setCurrentStep(RiskFreeTrialModalCurrentStep.PAYMENT);
      setShowIframe(true);
      setLoadingIframe(true);
      onAddPayment();
    }
  };

  const onSetCurrentStep = (step: RiskFreeTrialModalCurrentStep) => {
    setCurrentStep(step);
  };

  const onClose = () => {
    removeZuoraIframe();

    setShowIframe(false);
    onCancelClicked && onCancelClicked();
  };

  const onAddPayment = async () => {
    setShowIframe(true);
    setLoadingIframe(true);

    try {
      const signature = await billingClient.getZuoraSignature();
      setZuoraSignature(signature);
    } catch (err) {
      setLoadingIframe(false);
    }
  };

  const onIframeLoad = () => {
    setLoadingIframe(false);
  };

  useEffect(() => {
    if (userContext.billingInfo && !isAiEnabled) {
      setAutoUpgradeAccount(true);
    }
  }, [userContext, isAiEnabled]);

  useEffect(() => {
    if (zuoraSignature) {
      renderZuoraIframe(
        zuoraSignature,
        onIframeLoad,
        setZuoraSuccess,
        setRefId,
        setZuoraError
      );
    }
  }, [zuoraSignature]);

  useEffect(() => {
    if (!isUndefined(zuoraError) && !zuoraError?.success) {
      handlePaymentError();
    }
    if (zuoraSuccess && refId) {
      createZuoraAccount().then(async (success) => {
        if (success) {
          trackPaymentAdded();
          upgradeAccount();
        } else {
          handlePaymentError();
        }
      });
    }
  }, [zuoraSuccess, refId, zuoraError]);

  const handlePaymentError = () => {
    setLoadingIframe(false);
    setShowErrorText(true);
    setZuoraSuccess(false);
    removeZuoraIframe();
    setShowIframe(false);
  };

  const createZuoraAccount = async () => {
    setShowErrorText(false);
    setLoadingIframe(true);
    const request: PaginatedRequest = {
      itemsPerPage: DEFAULT_MAX_ITEMS_PER_PAGE,
      page: 1,
      sorts: [{ column: 'role', direction: SortOrder.Asc }],
      filters: [],
    };
    const users = await famClient.getUsers(account.id)(request);
    const billingContact = users.items.find(
      (item) => item.role.role === Role.ACCOUNT_OWNER
    );

    return billingClient
      .createZuoraAccount(
        account.id,
        refId,
        {
          firstName: billingContact?.user.firstName!,
          lastName: billingContact?.user.lastName!,
          email: billingContact?.user.email!,
        },
        selectedPlatformSubscription
      )
      .then(() => true)
      .catch(() => false);
  };

  if (hideFreeTrialContent) {
    return null;
  }

  if (showSelfServiceAnnualBilling)
    return (
      <BillingCadencesModal
        onContinueClicked={onGetStarted}
        showIframe={showIframe}
        loadingIframe={loadingIframe}
        showErrorText={showErrorText}
        onCancelClicked={onClose}
        currentStep={currentStep}
        onSetCurrentStep={onSetCurrentStep}
        onConfettiSuccess={onConfettiSuccess}
        updatingAccount={updatingAccount}
        onPlatformSubscriptionTypeChange={onPlatformSubscriptionTypeChange}
        selectedPlatformSubscription={selectedPlatformSubscription}
      />
    );

  return (
    <RiskFreeTrailModal
      onContinueClicked={onGetStarted}
      showIframe={showIframe}
      loadingIframe={loadingIframe}
      showErrorText={showErrorText}
      onCancelClicked={onClose}
      currentStep={currentStep}
      onSetCurrentStep={onSetCurrentStep}
      onConfettiSuccess={onConfettiSuccess}
      updatingAccount={updatingAccount}
    />
  );
};

export const RiskFreeTrailModal: React.FC<RiskFreeTrailModalProps> = ({
  onContinueClicked,
  onCancelClicked,
  loadingIframe,
  currentStep,
  showErrorText,
  onSetCurrentStep,
  onConfettiSuccess,
  updatingAccount,
}) => {
  const intl = useIntl();
  const [successLoading, setSuccessLoading] = useState(false);

  const [
    IMPROVE,
    ROAS,
    BY_AVERAGE,
    TITLE,
    SUBTITLE,
    BREAKDOWN_HEADLINE,
    BREAKDOWN_HEADER_AD_SPEND,
    BREAKDOWN_HEADER_PRICE,
    PRICING_BELOW_5K,
    PRICING_BELOW_BETWEEN_5K_10K,
    PRICING_OVER_10K,
    PRICING_OVER_10K_EXTRA,
    RESULT_DESCRIPTION,
    NO_THANKS_DESCRIPTION,
    ADD_PAYMENT_DESCRIPTION,
    CONGRATULATIONS,
    RISK_FREE_STARTED,
    GET_STARTED,
    CLOSE,
    TRY_AGAIN,
    ERROR_MESSAGE,
    BANNER_TITLE,
    BANNER_TITLE_DESCRIPTION,
    BANNER_TITLE_OFFER,
  ] = [
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_IMPROVE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_ROAS,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BY_AVERAGE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_TITLE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_SUBTITLE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BREAKDOWN_HEADLINE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BREAKDOWN_HEADER_MONTHLY_AD_SPEND,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BREAKDOWN_HEADER_MONTHLY_PRICE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_PRICING_BELOW_5K,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_PRICING_BETWEEN_5K_10K,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_PRICING_OVER_10K,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_PRICING_OVER_10K_EXTRA,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_RESULT_DESCRIPTION,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_NO_THANKS_DESCRIPTION,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_ADD_PAYMENT_DESCRIPTION,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_CONGRATULATIONS_TITLE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_RISK_FREE_STARTED_TITLE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_RISK_GET_STARTED_DESCRIPTION,
    I18nKey.CLOSE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_TRY_AGAIN_DESCRIPTION,
    I18nKey.ERROR_MESSAGE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BANNER_TITLE,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BANNER_TITLE_DESCRIPTION,
    I18nKey.BILLING_ACCOUNT_RISK_FREE_TRIAL_MODAL_BANNER_TITLE_OFFER,
  ].map((id) => intl.formatMessage({ id }));

  const PRICING = [
    { adSpend: PRICING_BELOW_5K, price: '$199', offerPrice: '$99' },
    { adSpend: PRICING_BELOW_BETWEEN_5K_10K, price: '$499' },
    {
      adSpend: PRICING_OVER_10K,
      price: '$499 + 3%',
      extra: PRICING_OVER_10K_EXTRA,
    },
  ];

  return (
    <Modal
      showModal={true}
      className="min-w-640 max-w-800 max-h-580 m-0 rounded-8 border-none"
      hidePadding={true}
      maxModalWidth="max-w-800"
      dataTestId="risk_free_trial_modal"
    >
      <>
        {currentStep === RiskFreeTrialModalCurrentStep.GET_STARTED && (
          <>
            <div
              className="min-w-640
              rounded-4 flex-col px-32 pt-32"
            >
              <div className="relative flex items-center justify-center h-36 bg-purple-100 rounded-4">
                <div className="flex items-center px-36">
                  <Icon
                    className="text-purple-500 mr-4"
                    svg={ThunderIcon}
                    size={IconSize.Small}
                  />
                  <span className="text-purple-500 text-lg leading-none font-medium">
                    {BANNER_TITLE}
                  </span>
                  <span className="text-sm leading-none font-medium text-grey-900 pl-2">
                    {BANNER_TITLE_DESCRIPTION}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="relative px-32 pb-32 pt-20 min-w-640 min-h-580
              rounded-4 border-purple-800 flex flex-col"
            >
              <Rocket className="absolute left-32 top-32" />
              <div
                className={`flex flex-col items-center justify-center
                text-center w-148 h-148 rounded-full bg-orange-100`}
              >
                <div className="text-purple-500 uppercase font-semibold">
                  <div style={{ fontSize: 17.25, lineHeight: '28.75px' }}>
                    {IMPROVE}
                  </div>
                  <div style={{ fontSize: 28.75, lineHeight: '28.75px' }}>
                    {ROAS}
                  </div>
                </div>
                <div
                  className="font-medium text-purple-500"
                  style={{ fontSize: 10, lineHeight: '10px' }}
                >
                  {BY_AVERAGE}
                </div>
                <GradientText
                  className="font-semibold"
                  style={{ fontSize: 30, lineHeight: '34px' }}
                >
                  {`>60%`}
                </GradientText>
              </div>
              <div
                className={`pt-22 pb-20 text-2xl
                leading-tight font-semibold text-center`}
              >
                <GradientText>{TITLE}</GradientText>
                <div className="text-grey-900">{SUBTITLE}</div>
              </div>

              <div className="flex flex-col items-center gap-12 bg-grey-50 py-16">
                <GradientText className="text-lg leading-tight font-semibold">
                  {BREAKDOWN_HEADLINE}
                </GradientText>

                <div className="w-307">
                  <div
                    className={`flex items-center justify-between py-4
                  text-purple-500 text-sm leading-none`}
                  >
                    <div>{BREAKDOWN_HEADER_AD_SPEND}</div>
                    <div>{BREAKDOWN_HEADER_PRICE}</div>
                  </div>

                  {PRICING.map((item, idx) => (
                    <div
                      key={item.adSpend}
                      className={classNames('py-4', {
                        'border-b border-solid border-grey-200':
                          idx < PRICING.length - 1,
                      })}
                    >
                      <div className="flex justify-between py-4text-grey-900 text-base leading-tight font-semibold">
                        <div className="text-left">{item.adSpend}</div>
                        <div className="text-right">
                          <div>
                            <span
                              className={classNames({
                                'line-through text-base leading-tight font-medium':
                                  item.offerPrice,
                              })}
                            >
                              {item.price}
                            </span>
                            <span className="pl-4">{item.offerPrice}</span>
                          </div>
                          {item.extra && (
                            <div className="text-sm font-normal pt-4">
                              {item.extra}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        {item.offerPrice && (
                          <div
                            className={`flex items-center justify-end py-4 text-sm leading-none`}
                          >
                            <div className="text-lg leading-tight text-purple-500 font-medium">
                              <Icon
                                className="text-purple-500 mr-4"
                                svg={ThunderIcon}
                                size={IconSize.Small}
                              />
                              {BANNER_TITLE_OFFER}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className="text-center text-grey-500 pt-8"
                style={{ fontSize: 10, lineHeight: '11px' }}
              >
                {RESULT_DESCRIPTION}
              </div>

              <div className="pt-32 flex items-end justify-between">
                <TextLink
                  textLabel={NO_THANKS_DESCRIPTION}
                  size={TextLinkSize.Small}
                  onClick={onCancelClicked}
                />
                <Button
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.Medium}
                  label={GET_STARTED}
                  onClick={onContinueClicked}
                  state={
                    updatingAccount ? ButtonState.Loading : ButtonState.Active
                  }
                />
              </div>
            </div>
          </>
        )}
        {currentStep === RiskFreeTrialModalCurrentStep.PAYMENT && (
          <div className="p-12 min-h-580 flex flex-col">
            <div className="text-lg leading-normal font-semibold mb-24">
              {ADD_PAYMENT_DESCRIPTION}
            </div>

            {loadingIframe && <Spinner centerAlign={true} />}
            <div
              id="zuora_payment"
              className={`fs-exclude overflow-y-scroll w-full scrollbar-thin
                scrollbar-thumb-grey-400 scrollbar-track-grey-100
                scrollbar-thumb-rounded-full min-h-432 max-h-560`}
            />

            {showErrorText ? (
              <div className="flex flex-row justify-between">
                <div className="font-semibold text-base mt-8">
                  {ERROR_MESSAGE}
                </div>
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Primary}
                  onClick={() =>
                    onSetCurrentStep(RiskFreeTrialModalCurrentStep.GET_STARTED)
                  }
                  className="text-grey-900"
                  label={TRY_AGAIN}
                />
              </div>
            ) : (
              <div className=" flex justify-between flex-1 items-end">
                <div>
                  <TextLink
                    textLabel={NO_THANKS_DESCRIPTION}
                    onClick={onCancelClicked}
                    className="text-sm leading-none font-normal text-grey-900"
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {currentStep === RiskFreeTrialModalCurrentStep.CONFETTI && (
          <div className="min-w-640 max-w-800 min-h-580 p-32">
            {successLoading ? (
              <Spinner centerAlign={true} />
            ) : (
              <Confetti
                onAnimationComplete={async () => {
                  if (onConfettiSuccess) {
                    setSuccessLoading(false);
                    onConfettiSuccess();
                    setSuccessLoading(true); // This is just incase the animation finished before the endpoint call is returned
                  }
                  onSetCurrentStep(RiskFreeTrialModalCurrentStep.CONGRATS);
                }}
              />
            )}
          </div>
        )}
        {currentStep === RiskFreeTrialModalCurrentStep.CONGRATS && (
          <div
            className={`flex flex-col justify-center
            min-w-640 max-w-800 h-580 p-32`}
          >
            <div
              className={`flex justify-center flex-col items-center h-412 mt-32`}
            >
              <div className="flex flex-row items-center">
                <GradientText className="text-2xl leading-normal font-semibold">
                  {CONGRATULATIONS}
                </GradientText>
                <div className="ml-4">🎉</div>
              </div>

              <div className="text-2xl leading-normal font-semibold">
                {RISK_FREE_STARTED}
              </div>
            </div>

            <div className="flex justify-end">
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.Primary}
                onClick={onCancelClicked}
                className="text-grey-900"
                label={CLOSE}
              />
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

RiskFreeModalWrapper.displayName = 'FreeTrialModal';

export default RiskFreeModalWrapper;
