import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/flywheelTable';
import {
  makeCurrencyColumn,
  makeNumericInputColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  CampaignTargetingType,
  ProductAdDetails,
} from '../../../../../lib/types/AOSharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_AD_ITEMS_TABLE_ID } from '../ducks/types';
import {
  ADITEM_BID_AUTO_MIN_VALUE,
  ADITEM_BID_MAX_VALUE,
  CURRENCY_CODE,
  TableDataAdsManager,
} from '../types';
import { PRODUCT_ADS_API_COLUMN_NAME, isManualCampaign } from '../utils';
import {
  getCurrencyCodeFromMerchantCountryCode,
  getCurrencySymbol,
} from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = ({
  adItemPerformance,
  adItemDetails,
  adItemId,
  channelSettings,
  isEditMode,
  pendingFields,
  merchantCountry,
}) => {
  const [focussed, setFocussed] = useState<boolean>(false);

  const dispatch = useDispatch();
  const intl = useIntl();

  const adItemData: ProductAdDetails = {
    adItemPerformance,
    adItemDetails,
    adItemId,
    channelSettings,
    pendingFields,
  };

  const updatedBid = useSelector<WithTable<ProductAdDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(adItemId, PRODUCT_ADS_API_COLUMN_NAME.Bid)(
        tableState,
        ADS_MANAGER_AD_ITEMS_TABLE_ID
      )
  );

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  const currentPage = useSelector<WithTable<ProductAdDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_AD_ITEMS_TABLE_ID
      )
  );

  const existingValue = channelSettings.bid?.amount?.toString() || '';
  let bid = updatedBid ?? existingValue;

  const [value, setValue] = useState<string>(bid);

  const onValueAndPageChange = () => {
    if (
      isEditMode || // Changing between modes
      (!isNil(updatedBid) && updatedBid !== value) // Changes done due to bulk update. updatedBid is latest, but state variable is not
    ) {
      setValue(bid);
    }
  };

  useEffect(onValueAndPageChange, [isEditMode, currentPage, updatedBid]);

  const updateCellValue = (newBid: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_AD_ITEMS_TABLE_ID,
        columnName: PRODUCT_ADS_API_COLUMN_NAME.Bid,
        rowId: adItemId,
        value: newBid,
        existingValue,
        numericValue: true,
      })
    );
  };

  const debouncedUpdateCellValue = useCallback(debounce(updateCellValue, 500), [
    adItemId,
  ]);

  if (isEditMode && !isManualCampaign(adItemDetails.campaignTargetingType)) {
    const currencySymbol = getCurrencySymbol(
      channelSettings?.bid?.currency || CURRENCY_CODE
    );

    const isInputSmallerThanMinLimit =
      Number(value) < ADITEM_BID_AUTO_MIN_VALUE;
    const isInputGreaterThanMaxLimit = Number(value) > ADITEM_BID_MAX_VALUE;
    const isBidNotANumber = isNaN(Number(value));

    const isInputInvalid =
      isInputGreaterThanMaxLimit ||
      isInputSmallerThanMinLimit ||
      isBidNotANumber;

    const numericInputState = getNumericInputState(
      isInputInvalid,
      adItemDetails.campaignTargetingType
    );

    const onInputFocus = () => setFocussed(true);

    const onInputBlur = () => {
      if (!isEmpty(value)) {
        debouncedUpdateCellValue.cancel();
        const formattedValue = parseFloat(value).toFixed(
          MONETARY_FRACTION_DIGITS
        );
        debouncedUpdateCellValue(formattedValue);
      }
      setFocussed(false);
    };

    const numericInputOnChange = (newValue: string) => {
      setValue(newValue);
      debouncedUpdateCellValue(newValue);
    };

    const numericInputProps: NumericInputProps & TypedTooltipProps = {
      value,
      dataTestId: PRODUCT_ADS_API_COLUMN_NAME.Bid,
      state: numericInputState,
      prependedElement: getCurrencySymbol(
        channelSettings.bid?.currency || CURRENCY_CODE
      ),
      onChange: numericInputOnChange,
      isDirty: value !== existingValue,
      onInputBlur,
      tooltipContent: getTooltipContent(
        intl,
        currencySymbol,
        isBidNotANumber,
        isInputGreaterThanMaxLimit,
        isInputSmallerThanMinLimit
      ),
      controlledTooltip: getControlledTooltipState(focussed, numericInputState),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus: onInputFocus,
      acceptOnlyPositiveNumbers: true,
      minFractionDigits: MONETARY_FRACTION_DIGITS,
      maxFractionDigits: MONETARY_FRACTION_DIGITS,
    };

    return makeNumericInputColumn(
      (adItemDetails: ProductAdDetails) => numericInputProps
    )(adItemData);
  }

  return makeCurrencyColumn(
    (data: ProductAdDetails) => getBidValue(data, currencyCode),

    pendingFields?.includes(PRODUCT_ADS_API_COLUMN_NAME.Bid)
  )(adItemData);
};
RowCellElement.displayName = 'RowCellElement';

export const getBidValue = (
  data: ProductAdDetails,
  currencyCode: string | null
) =>
  data.channelSettings.bid &&
  !isManualCampaign(data.adItemDetails.campaignTargetingType)
    ? {
        amount: Number(data.channelSettings.bid.amount),
        currency:
          currencyCode || data.channelSettings.bid.currency || CURRENCY_CODE,
      }
    : undefined;

export const bidColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  isSortable: true,
  columnName: PRODUCT_ADS_API_COLUMN_NAME.Bid,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_BID,
  RowCellElement,
  className: 'text-right',
  gridColumnWidth: '160px',
  columnHeaderClassName: 'justify-end',
};

export const bidFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    PRODUCT_ADS_API_COLUMN_NAME.Bid,
    I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_BID,
    currency,
    isValidNumber(0)
  );

export const getNumericInputState = (
  isInputInvalid: boolean,
  campaignTargetingType: CampaignTargetingType
) => {
  if (isInputInvalid) {
    return NumericInputState.Error;
  } else if (isManualCampaign(campaignTargetingType)) {
    return NumericInputState.Disabled;
  } else {
    return NumericInputState.Default;
  }
};

export const getTooltipContent = (
  intl: IntlShape,
  currencySymbol: string,
  isBidNotANumber: boolean,
  isInputGreaterThanMaxLimit: boolean,
  isInputSmallerThanMinLimit: boolean
) => {
  if (
    isBidNotANumber ||
    isInputGreaterThanMaxLimit ||
    isInputSmallerThanMinLimit
  ) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          { id: I18nKey.ADS_MANAGER_AD_ITEMS_INVALID_BID },
          {
            currency: currencySymbol,
            minValue: intl.formatNumber(ADITEM_BID_AUTO_MIN_VALUE, {
              minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              maximumFractionDigits: MONETARY_FRACTION_DIGITS,
            }),
            maxValue: intl.formatNumber(ADITEM_BID_MAX_VALUE, {
              minimumFractionDigits: MONETARY_FRACTION_DIGITS,
              maximumFractionDigits: MONETARY_FRACTION_DIGITS,
            }),
          }
        )}
      </p>
    );
  }
};

export const getControlledTooltipState = (
  focussed: boolean,
  numericInputState: NumericInputState
) =>
  focussed && numericInputState === NumericInputState.Error
    ? ControlledTooltip.Show
    : ControlledTooltip.Hide;
