/* eslint-disable max-statements */
import './campaignsTable.scss';

import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Alignment,
  BulkEditConfirmationModal,
  BulkEditModalNumericValueOperator,
  BulkEditSlideOut,
  BulkEditValues,
  ButtonSize,
  ButtonState,
  ColumnManager,
  ColumnManagerOption,
  ColumnManagerVariant,
  DualModes,
  EditControlProps,
  FilterIcon,
  FiltersMenu,
  Placement,
  SearchInputSize,
  TableActionRow,
  Theme,
  Tooltip,
  TooltipSize,
  Variant,
} from '@teikametrics/tm-design-system';

import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';
import {
  SaveChangesFlagContext,
  SaveChangesFlagContextState,
} from '../../../../containers/saveChangesFlagProvider';
import UpdatedFlywheelTable, {
  FlywheelTableColumnGroup,
} from '../../../../containers/table/UpdatedFlywheelTable';
import {
  tableActions,
  tableSelectors,
  tableThunks,
} from '../../../../containers/table/ducks';
import {
  TableChange,
  WithTable,
} from '../../../../containers/table/ducks/types';
import { FlywheelSearchInput } from '../../../../containers/tableV2/flywheelSearchInput';
import { getUserRoleMatch } from '../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { PaginatedDataFetcher } from '../../../../lib/clients/types';
import { useTime } from '../../../../lib/hooks/useTime';
import {
  AdLevel,
  AdType,
  FlywheelSalesChannel,
  MerchantCountryCode,
  ProductAdDetails,
  ProductAdsDataRequest,
  REQUEST_DATE_FORMAT,
} from '../../../../lib/types/AOSharedTypes';
import {
  ConnectionStatus,
  MerchantType,
  Role,
} from '../../../../lib/types/Fam';
import {
  COLUMNS_WITH_SINGLE_DECIMAL_FILTER,
  Filter,
} from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  CurrencyCode,
  getCurrencyCodeFromMerchantCountryCode,
  getCurrencySymbolFromMerchantCountryCode,
} from '../../../../lib/utilities/currency';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../lib/utilities/intercom';
import { SelectedTrendsProduct } from '../../../products/containers/skuCatalog';
import { PerformanceSlideover } from '../../../products/containers/skuCatalog/performanceSlideover';
import { PerformanceSlideoverContextProvider } from '../../../products/containers/skuCatalog/performanceSlideover/performanceSlideoverContextProvider';
import { MerchantContext, MerchantContextState } from '../../merchantsProvider';
import {
  areNoChangesDone,
  transFormAdItemsFilters,
  upgradeFiltersInStorage,
} from '../../utils';
import { generateProductAdsColumns } from './adItemsTableColumns';
import {
  VisibilityCriteria,
  getEligibleProductAdIds,
} from './adItemsTableColumns/productName';
import {
  getActionRowButtons,
  getBulkDataForBid,
  getBulkDataForProductAdStatus,
  getCheckBoxState,
  getEditControls,
  getFilteredBulkEditOptions,
  getSelectedBulkMenuValues,
} from './bulkEditModalUtils';
import { ADS_MANAGER_AD_ITEMS_TABLE_ID } from './ducks/types';
import { ErrorTable } from './errorTable';
import ExportAdLevelData from './exportAdLevelData';
import { KeepEditingModal } from './keepEditingModal';
import { NoDataFoundTable } from './noDataFoundTable';
import { NoFilteredDataFoundTable } from './noFilteredDataFound';
import {
  ADITEM_BID_AUTO_MIN_VALUE,
  ADITEM_BID_MAX_VALUE,
  ApiColumnsWithAdLevel,
  BulkEditModalNumericTypeValue,
  BulkEditModalValues,
  BulkEditSelectedOptions,
  CURRENCY_CODE,
  ConfirmationModalClickKind,
  EXT_ADGROUP_ID,
  EXT_CAMPAIGN_ID,
  MERCHANT_NAME_COLUMN_ID_FOR_EXPORT,
  PRODUCTAD_TABLE_SEARCH_KEY,
  REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES,
  TableCellChangeProductAd,
  TableChangeGlobalAdLevelCurrency,
  TableDataAdsManager,
} from './types';
import {
  ALL_AD_LEVELS_FILTER_FILED_MAPPER,
  EDIT_AD_ITEMS_API_COLUMN_DATA_MAPPING,
  PRODUCT_ADS_API_COLUMN_NAME,
  ProductAdsApiColumnIdentifier,
  TABLE_UNIQ_KEY,
  getSearchInputPlaceholder,
  getTransformedAddedByFilter,
  shouldShowSmartCampaigns,
} from './utils';
import { getFilterColumnDefinitons } from './utils/filterDefinitions';

const TABLE_ID = ADS_MANAGER_AD_ITEMS_TABLE_ID;

export type BulkEditMenuValue =
  | typeof PRODUCT_ADS_API_COLUMN_NAME.Status
  | typeof PRODUCT_ADS_API_COLUMN_NAME.Bid;

export interface TableAdItemsProps {
  readonly apiColumnsWithAdLevel: ApiColumnsWithAdLevel;
  readonly tableMode: DualModes;
  readonly aoApiClient: AOApiClient;
  readonly exportButtonState: ButtonState;
  readonly adLevelDropDownSelectedValues: '' | AdLevel;
  readonly request: () => ProductAdsDataRequest;
  readonly setExportButtonState: React.Dispatch<
    React.SetStateAction<ButtonState>
  >;
  readonly toggleTableMode: () => void;
  readonly changeTableMode: (index: number) => void;
  readonly selectedSalesChannelName: string;
  readonly activeSliderIndex: number;
  readonly dataFetcher: PaginatedDataFetcher<ProductAdDetails>;
  readonly onFilterUpdate?: (filters: Filter[]) => void;
  readonly onEditSave: () => void;
  readonly adGroupDetailsFilter?: Filter[];
  readonly filtersFromBrowserStorage?: Filter[];
  readonly storeFiltersInBrowserStorage?: (filters: Filter[]) => void;
  readonly adTypes: AdType[];
  readonly selectedSalesChannel: FlywheelSalesChannel;
  readonly selectedAdType: AdType;
  readonly merchantCountry: MerchantCountryCode;
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly selectedMerchantCountries: Array<number | string> | string;
  merchantType?: MerchantType;
  productConnectionStatus?: ConnectionStatus;
  readonly onColumnSelectionChange?: (selectedColumns: string[]) => void;
  readonly selectedColumns?: string[];
  readonly columnManagerOptions?: ColumnManagerOption[];
  readonly switchLevelController: (adLevel: string) => void;
  readonly isSaveChangesLoading?: boolean;
  readonly aiEnabled: boolean;
  readonly merchantsWithAutomationEnabled: string[];
}

export interface ExtendedEditControlProps extends EditControlProps {
  readonly visibilityCriteria?: VisibilityCriteria;
}

export const getAdItemDetailFieldsForExport = (
  selectedSalesChannel: FlywheelSalesChannel,
  selectedAdItemDetailsFields: string[]
) =>
  selectedSalesChannel === FlywheelSalesChannel.Amazon
    ? selectedAdItemDetailsFields.concat(['extAdItemId'])
    : selectedAdItemDetailsFields;

export const TableProductAds: React.FC<TableAdItemsProps> = ({
  apiColumnsWithAdLevel,
  activeSliderIndex,
  tableMode,
  aoApiClient,
  exportButtonState,
  adLevelDropDownSelectedValues,
  dataFetcher,
  request,
  setExportButtonState,
  toggleTableMode,
  selectedSalesChannelName,
  onFilterUpdate,
  onEditSave,
  adGroupDetailsFilter,
  filtersFromBrowserStorage,
  storeFiltersInBrowserStorage,
  adTypes,
  selectedSalesChannel,
  selectedAdType,
  merchantCountry,
  startDate,
  endDate,
  selectedMerchantCountries,
  merchantType,
  productConnectionStatus,
  columnManagerOptions,
  onColumnSelectionChange = noop,
  selectedColumns,
  switchLevelController,
  isSaveChangesLoading,
  aiEnabled,
  merchantsWithAutomationEnabled,
}) => {
  // This state is used to know if user clicked on View mode explictly or not.
  const [clickedOnView, setClickedOnView] = useState<boolean>(false);
  const [shoWBulkEditSlideOut, setShoWBulkEditSlideOut] =
    useState<boolean>(false);
  const [bulkEditValues, setBulkEditValues] = useState<BulkEditModalValues>({});
  const [bulkEditSelectedOptions, setBulkEditSelectedOptions] =
    useState<BulkEditSelectedOptions>({});
  const [confirmationModalVariant, setConfirmationModalVariant] =
    useState<Variant | null>(null);

  const [isFilterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<boolean>(false);
  const firstApiCall = useRef<boolean>(true);
  const userContext = useContext(UserContext);

  const [showTrendsSlideover, setShowTrendsSlideover] =
    useState<boolean>(false);
  const [selectedTrendsProduct, setSelectedTrendsProduct] =
    useState<SelectedTrendsProduct>();

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);
  const currencySymbol =
    getCurrencySymbolFromMerchantCountryCode(merchantCountry);

  const isUserRoleViewOnly = getUserRoleMatch(
    Role.VIEW_ONLY,
    userContext.userInfo.userDetails
  );

  const changedCount = useSelector<WithTable<ProductAdDetails>, number>(
    ({ tableState }) => {
      return tableSelectors.getChangedCountSelector()(tableState, TABLE_ID);
    }
  );

  const tableChange = useSelector<WithTable<ProductAdDetails>, TableChange>(
    ({ tableState }) =>
      tableSelectors.getChangeSelector()(
        tableState,
        ADS_MANAGER_AD_ITEMS_TABLE_ID
      )
  );

  const selectedRowsCount = tableChange.select.rows.length;

  const productAdTableData = useSelector<
    WithTable<ProductAdDetails>,
    ProductAdDetails[]
  >(({ tableState }) =>
    tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<ProductAdDetails, void>()(
        tableState,
        ADS_MANAGER_AD_ITEMS_TABLE_ID
      )
    )
  );

  const merchantContext = useContext<MerchantContextState>(MerchantContext);

  const merchantDetails = merchantContext.merchantDetails;

  const saveChangesFlagContext = useContext<SaveChangesFlagContextState>(
    SaveChangesFlagContext
  );

  const onBulkEditOptionSelection = (key: string, value: boolean) => {
    setBulkEditSelectedOptions((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    saveChangesFlagContext.updateSaveChangesData({
      editDataFlag: changedCount > 0,
      saveChangesModalIsOpen: false,
      navPath: saveChangesFlagContext.saveChangesData.navPath,
    });
  }, [changedCount]);

  useEffect(() => {
    updateCurrencyCode();
  }, [productAdTableData]);

  const isTableDataEdited: boolean =
    saveChangesFlagContext.saveChangesData.editDataFlag;

  useEffect(() => {
    // Show prompt if table is edited and user is on edit mode
    initBeforeUnLoad(isTableDataEdited && activeSliderIndex === 1);
  }, [isTableDataEdited]);

  const initBeforeUnLoad = (showExitPrompt: boolean) => {
    window.onbeforeunload = (event: Event) => {
      if (showExitPrompt) {
        event.preventDefault();
        return '';
      }
    };
  };

  const updateCurrencyCode = () => {
    const orginalGlobal = cloneDeep(
      tableChange.global
    ) as TableChangeGlobalAdLevelCurrency;

    const currentVisibleCurrencyCode = productAdTableData
      .filter((productAd) => productAd.channelSettings.bid?.amount)
      .reduce((currencyCodes, productAd) => {
        return {
          ...currencyCodes,
          [productAd.adItemId]: productAd.channelSettings.bid?.currency,
        };
      }, {});

    const updatedCurrencyCode = {
      ...orginalGlobal.currencyCode,
      ...currentVisibleCurrencyCode,
    };

    const modifiedGlobal: TableChangeGlobalAdLevelCurrency = {
      ...orginalGlobal,
      currencyCode: updatedCurrencyCode,
    };

    const equal = isEqual(orginalGlobal, modifiedGlobal);

    if (!equal) {
      dispatch(
        tableActions.updateGlobal({
          tableId: TABLE_ID,
          updatedValues: modifiedGlobal,
        })
      );
    }
  };

  const eligibleProductAdIds = getEligibleProductAdIds(productAdTableData);
  const sortedTableChange = [...tableChange.select.rows].sort((a, b) =>
    a.localeCompare(b)
  );
  const sortedEligibleProductAdIds = [...eligibleProductAdIds].sort((a, b) =>
    a.localeCompare(b)
  );
  const isAllRowsSelected = isEqual(
    sortedTableChange,
    sortedEligibleProductAdIds
  );

  const handleSelectChange = (): void => {
    const requiredSelectedRows = isAllRowsSelected ? [] : eligibleProductAdIds;
    dispatch(
      tableActions.changeSelect({
        tableId: ADS_MANAGER_AD_ITEMS_TABLE_ID,
        selectedRows: requiredSelectedRows,
      })
    );
  };

  const getVisibleColumnsAdItems = (): FlywheelTableColumnGroup<
    ProductAdDetails,
    TableDataAdsManager
  >[] => {
    const adItemsTableColumns = generateProductAdsColumns(
      tableMode === DualModes.Edit,
      {
        onSelectChange: handleSelectChange,
        checked: getCheckBoxState(
          eligibleProductAdIds.length,
          tableChange.select.rows.length
        ),
      }
    );

    return adItemsTableColumns
      .map((column) => {
        const columnInGroups = column.columnsInGroup.filter(
          (subColumn) =>
            apiColumnsWithAdLevel.columns.includes(subColumn.columnName) ||
            subColumn.columnName ===
              PRODUCT_ADS_API_COLUMN_NAME[
                ProductAdsApiColumnIdentifier.ProductName
              ]
        );
        return {
          ...column,
          columnsInGroup: columnInGroups,
        };
      })
      .filter((column) => column.columnsInGroup.length > 0);
  };

  const onSubmitHandler = (bulkEditValues: BulkEditValues) => {
    setBulkEditValues(bulkEditValues as BulkEditModalValues);
    setShoWBulkEditSlideOut(!shoWBulkEditSlideOut);
  };

  const resetModal = () => {
    setBulkEditValues({});
    setBulkEditSelectedOptions({});
  };

  const handleApplyBulkChange = (values: BulkEditModalValues): void => {
    let bulkChanges = {};
    values = getSelectedBulkMenuValues(values, bulkEditSelectedOptions);

    if (values[PRODUCT_ADS_API_COLUMN_NAME.Status]) {
      bulkChanges = {
        ...bulkChanges,
        ...getBulkDataForProductAdStatus(
          tableChange,
          PRODUCT_ADS_API_COLUMN_NAME.Status,
          [values[PRODUCT_ADS_API_COLUMN_NAME.Status] as string],
          productAdTableData,
          selectedAdType
        ),
      };
    }

    if (values[PRODUCT_ADS_API_COLUMN_NAME.Bid]) {
      const changes = getBulkDataForBid(
        tableChange,
        productAdTableData,
        PRODUCT_ADS_API_COLUMN_NAME.Bid,
        [
          (
            values[
              PRODUCT_ADS_API_COLUMN_NAME.Bid
            ] as BulkEditModalNumericTypeValue
          )?.value as string,
        ],
        (
          values[
            PRODUCT_ADS_API_COLUMN_NAME.Bid
          ] as BulkEditModalNumericTypeValue
        )?.operator as BulkEditModalNumericValueOperator
      );
      bulkChanges = merge(bulkChanges, changes);
    }

    dispatch(
      tableActions.applyBulkChanges({
        tableId: ADS_MANAGER_AD_ITEMS_TABLE_ID,
        bulkChanges,
        columnDataMapping: EDIT_AD_ITEMS_API_COLUMN_DATA_MAPPING,
        uniqKey: TABLE_UNIQ_KEY[ADS_MANAGER_AD_ITEMS_TABLE_ID],
      })
    );
    resetModal();
  };

  useEffect(() => {
    if (!isEmpty(bulkEditValues)) {
      handleApplyBulkChange(bulkEditValues);
    }
  }, [bulkEditValues]);

  const bulkEditModalEditControls = getFilteredBulkEditOptions(
    getEditControls(
      intl,
      bulkEditValues,
      tableChange,
      currencySymbol || CURRENCY_CODE,
      selectedSalesChannel,
      onBulkEditOptionSelection,
      productAdTableData
    ),
    selectedAdType,
    selectedSalesChannel
  ).map((editControl, index) => ({
    ...editControl,
    selected: editControl.editKey
      ? bulkEditSelectedOptions[editControl.editKey]
      : false,
    controls: editControl?.controls,
  }));

  // Set state of modal to false (saveChangesModalIsOpen)
  const onKeepEditingClick = () => {
    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      saveChangesModalIsOpen: false,
      aoAdLevel: undefined,
    });
    setClickedOnView(false);
  };

  const onLeaveWithoutSaveClick = () => {
    const adLevel = saveChangesFlagContext.saveChangesData.aoAdLevel || '';

    /*
      Set modal state to false (saveChangesModalIsOpen)
      Set data present flag to false (editDataFlag)
  ``*/
    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
      aoAdLevel: undefined,
    });

    /*
      If user had clicked on view slider tab, that means change the mode from edit to view
      Else: Redirect to requested page
    */
    if (clickedOnView) {
      toggleTableMode();
      setClickedOnView(false);
    } else if (saveChangesFlagContext.saveChangesData.aoAdLevel) {
      switchLevelController(adLevel);
    } else {
      navigate(saveChangesFlagContext.saveChangesData.navPath);
    }
  };

  const clearAllChanges = () =>
    dispatch(
      tableActions.clearAllChanges({
        tableId: TABLE_ID,
      })
    );

  const handleOperationsAsPerTableMode = (mode: DualModes) => {
    if (!isUserRoleViewOnly) {
      // If the mode selected by user is view, then update clickedOnView to true.
      if (mode === DualModes.View) {
        setClickedOnView(true);
      }

      /*
        If user clicked on view, and there were unsaved changes, then show the modal
        Else, just change the view
      */
      if (
        mode === DualModes.View &&
        saveChangesFlagContext.saveChangesData.editDataFlag
      ) {
        saveChangesFlagContext.updateSaveChangesData({
          ...saveChangesFlagContext.saveChangesData,
          saveChangesModalIsOpen: true,
        });
      } else {
        clearAllChanges();
        toggleTableMode();
      }
    }
  };

  const discardChanges = () => {
    toggleTableMode();

    saveChangesFlagContext.updateSaveChangesData({
      ...saveChangesFlagContext.saveChangesData,
      editDataFlag: false,
      saveChangesModalIsOpen: false,
    });

    clearAllChanges();
  };

  const updatedCells = tableChange.cell as TableCellChangeProductAd;

  const validBidCount = Object.values(updatedCells).filter(
    ({ bid }) =>
      !isNil(bid) &&
      Number(bid) >= ADITEM_BID_AUTO_MIN_VALUE &&
      Number(bid) <= ADITEM_BID_MAX_VALUE
  ).length;

  const validAdItemStatusCount = Object.values(updatedCells).filter(
    ({ status }) => !isNil(status)
  ).length;

  const validRowsCount = validBidCount + validAdItemStatusCount;

  const getSaveButtonState: ButtonState = useMemo(() => {
    if (changedCount === 0) {
      return ButtonState.Disabled;
    }

    return validRowsCount === changedCount
      ? ButtonState.Enabled
      : ButtonState.Disabled;
  }, [validRowsCount, changedCount]);

  const adItemRequest = request();
  const selectedAdItemDetailsFields = adItemRequest.adItemDetailsFields.filter(
    (column) => apiColumnsWithAdLevel.columns.includes(column)
  );
  const adItemDetailFieldsForExport = getAdItemDetailFieldsForExport(
    selectedSalesChannel,
    selectedAdItemDetailsFields
  );

  // Send external ad group id if ad group name is selected
  const adGroupsIdColumns = apiColumnsWithAdLevel.columns.includes(
    PRODUCT_ADS_API_COLUMN_NAME.AdGroupName
  )
    ? [EXT_ADGROUP_ID]
    : [];

  // Send external campaign id if campaign name is selected
  const campaignsIdColumns = apiColumnsWithAdLevel.columns.includes(
    PRODUCT_ADS_API_COLUMN_NAME.CampaignName
  )
    ? [EXT_CAMPAIGN_ID]
    : [];

  let adItemDetailsForExport = [
    ...adItemDetailFieldsForExport,
    ...adGroupsIdColumns,
    ...campaignsIdColumns,
  ];

  if (
    adItemDetailsForExport.includes(PRODUCT_ADS_API_COLUMN_NAME.MerchantName)
  ) {
    adItemDetailsForExport = adItemDetailsForExport
      .filter((column) => column !== PRODUCT_ADS_API_COLUMN_NAME.MerchantName)
      .concat([MERCHANT_NAME_COLUMN_ID_FOR_EXPORT]);
  }

  const getExportRequest = () => {
    const { channelSettingsFields, performanceFields } = adItemRequest;

    return {
      ...adItemRequest,
      adItemDetailsFields: [
        ...adItemDetailsForExport,
        PRODUCT_ADS_API_COLUMN_NAME.ExtItemId,
      ],
      channelSettingsFields: channelSettingsFields.filter((column) =>
        apiColumnsWithAdLevel.columns.includes(column)
      ),
      performanceFields: performanceFields.filter((column) =>
        apiColumnsWithAdLevel.columns.includes(column)
      ),
    };
  };

  const tableActionRowRightSideElements =
    tableMode === DualModes.View
      ? [
          <Tooltip
            overwrittenTooltipClassnames="w-auto max-w-400"
            theme={Theme.Dark}
            content={intl.formatMessage({
              id: I18nKey.GENERIC_DOWNLOAD_CSV,
            })}
            position={{
              placement: Placement.Bottom,
              alignment: Alignment.Left,
            }}
            tooltipSize={TooltipSize.Small}
            hideArrow
          >
            <ExportAdLevelData<ProductAdsDataRequest>
              key={tableMode}
              aoApiClient={aoApiClient}
              buttonState={exportButtonState}
              setButtonState={setExportButtonState}
              salesChannel={selectedSalesChannelName}
              request={getExportRequest()}
              adLevelDropDownSelectedValue={
                adLevelDropDownSelectedValues || undefined
              }
              additionalFilters={adGroupDetailsFilter}
              filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
              startDate={startDate}
              endDate={endDate}
              adType={selectedAdType}
              transformFilters={(filters: Filter[]) => {
                const newFilters = getTransformedAddedByFilter(filters);
                return transFormAdItemsFilters(newFilters);
              }}
              hideExportText={true}
            />
          </Tooltip>,
        ]
      : [];

  useEffect(() => {
    if (!firstApiCall.current) {
      dispatch(
        tableThunks.refreshTable(ADS_MANAGER_AD_ITEMS_TABLE_ID, dataFetcher)
      );
    } else {
      firstApiCall.current = false;
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (ref.current && hasTimeElapsed()) {
      updateTime();
      dispatch(
        tableThunks.refreshTable(ADS_MANAGER_AD_ITEMS_TABLE_ID, dataFetcher)
      );
    } else {
      ref.current = true;
      if (location.state && location.state.filter) {
        let { filter } =
          (location.state && (location.state as { filter: Filter[] })) || [];
        dispatch(
          tableActions.updateFilters({
            tableId: ADS_MANAGER_AD_ITEMS_TABLE_ID,
            filters: filter || currentTableFilters,
            replace: true,
          })
        );
      }
    }
  }, [apiColumnsWithAdLevel.columns]);

  const { hasTimeElapsed, updateTime } = useTime(
    parseInt(
      process.env.REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES ||
        REACT_APP_AO_ELAPSED_TIME_LIMIT_IN_MINUTES
    )
  );

  const onActionClick = (actionType: Variant) => {
    if (actionType === Variant.Discard && areNoChangesDone(updatedCells)) {
      discardChanges();
      return;
    }
    setConfirmationModalVariant(actionType);
  };

  const confirmationModalClick = (kind: ConfirmationModalClickKind) => {
    switch (confirmationModalVariant) {
      case Variant.Apply:
        if (kind === ConfirmationModalClickKind.APPLY) {
          setConfirmationModalVariant(null);
          onEditSave();
        } else {
          setConfirmationModalVariant(null);
        }
        break;
      case Variant.Discard:
        if (kind === ConfirmationModalClickKind.APPLY) {
          setConfirmationModalVariant(null);
          discardChanges();
        } else {
          setConfirmationModalVariant(null);
        }
    }
  };

  useEffect(() => {
    shoWBulkEditSlideOut ? hideIntercomLauncher() : showIntercomLauncher();
  }, [shoWBulkEditSlideOut]);

  const onModalCancelClick = () => {
    setShoWBulkEditSlideOut(!shoWBulkEditSlideOut);
    resetModal();
  };

  const isEditMode = tableMode === DualModes.Edit;

  const dataFields = getFilterColumnDefinitons(
    CURRENCY_CODE,
    selectedSalesChannelName as FlywheelSalesChannel,
    first(adTypes),
    adLevelDropDownSelectedValues || undefined,
    merchantDetails,
    false, // isManageTreAutomationEnabled is unused in AdItems so sending it as false
    merchantType
  );

  const currentTableFilters = useSelector<
    WithTable<ProductAdDetails>,
    Filter[]
  >(({ tableState }) => tableState[ADS_MANAGER_AD_ITEMS_TABLE_ID].filters);

  let currentFilters = [
    ...currentTableFilters,
    ...(filtersFromBrowserStorage ?? []),
  ];

  const updateFilters = (filters: Filter[]) => {
    const existingSearchFilters: Filter[] = currentFilters.filter(
      (currentFilter) =>
        ALL_AD_LEVELS_FILTER_FILED_MAPPER.find(
          (filter) => filter.alias === currentFilter.field
        )
    );
    const newFilters: Filter[] = [...existingSearchFilters, ...filters];
    dispatch(
      tableActions.updateFilters({
        tableId: ADS_MANAGER_AD_ITEMS_TABLE_ID,
        filters: newFilters,
        replace: true,
      })
    );
    upgradeFiltersInStorage(storeFiltersInBrowserStorage)(newFilters);
    if (onFilterUpdate) {
      onFilterUpdate(newFilters);
    }
  };

  const onViewTrendsSlideoverClick = (product: SelectedTrendsProduct) => {
    setSelectedTrendsProduct(product);
    setShowTrendsSlideover(true);
  };

  const onViewTrendsSlideoverClose = () => {
    setShowTrendsSlideover(false);
    setSelectedTrendsProduct(undefined);
  };

  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);

  return (
    <>
      {showTrendsSlideover && selectedTrendsProduct && (
        <PerformanceSlideoverContextProvider
          currency={currencyCode as CurrencyCode}
          defaultDateRange={{
            startDate,
            endDate,
          }}
          {...selectedTrendsProduct}
        >
          <PerformanceSlideover onClose={onViewTrendsSlideoverClose} />
        </PerformanceSlideoverContextProvider>
      )}
      <KeepEditingModal
        showModal={
          saveChangesFlagContext.saveChangesData.saveChangesModalIsOpen
        }
        onKeepEditing={onKeepEditingClick}
        onDiscard={onLeaveWithoutSaveClick}
        tableId={TABLE_ID}
      />
      <TableActionRow
        className="mt-16"
        leftSideElements={[
          <FlywheelSearchInput
            tableId={ADS_MANAGER_AD_ITEMS_TABLE_ID}
            inputSearchColumnName={PRODUCTAD_TABLE_SEARCH_KEY}
            searchInputSize={SearchInputSize.Medium}
            searchInputPlaceholder={getSearchInputPlaceholder(
              selectedAdType,
              adLevelDropDownSelectedValues as AdLevel,
              intl
            )}
            onFilterUpdate={onFilterUpdate}
            upgradeFiltersInStorage={upgradeFiltersInStorage(
              storeFiltersInBrowserStorage
            )}
            hasSearchInputWithButton
            searchInputClassName="w-480"
            dataTestId={`${ADS_MANAGER_AD_ITEMS_TABLE_ID}_search_input`}
            key={'search_input'}
          />,
          <FiltersMenu
            customFilterIcon={FilterIcon}
            currency={currencyCode as CurrencyCode}
            dataFields={dataFields}
            currentFilters={currentFilters}
            handleSave={updateFilters}
            filterButtonSize={ButtonSize.Medium}
            isOpen={isFilterMenuOpen}
            setOpen={setFilterMenuOpen}
            filterDateFormat={REQUEST_DATE_FORMAT}
            singleDecimalColumns={COLUMNS_WITH_SINGLE_DECIMAL_FILTER}
            dataTestId={`${ADS_MANAGER_AD_ITEMS_TABLE_ID}_filters_menu`}
            filtersContainerClass={
              'transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:left-0'
            }
            key="filters_menu"
          />,
        ]}
        rightSideElements={[
          <Tooltip
            overwrittenTooltipClassnames="w-auto max-w-400"
            theme={Theme.Dark}
            content={intl.formatMessage({
              id: I18nKey.GENERIC_COLUMNS,
            })}
            tooltipSize={TooltipSize.Small}
            hideArrow
          >
            <ColumnManager
              variant={ColumnManagerVariant.ColumnGroupWithHeader}
              values={selectedColumns ?? []}
              onChange={onColumnSelectionChange}
              options={columnManagerOptions}
              tooltipClass="right-0"
              hideColumnText={true}
              dataTestId={`${ADS_MANAGER_AD_ITEMS_TABLE_ID}_column_manager`}
              key="column_manager"
            />
          </Tooltip>,
          ...tableActionRowRightSideElements,
        ]}
        fullWidthElementIndex={-1}
      />
      {confirmationModalVariant && (
        <BulkEditConfirmationModal
          showModal={!!confirmationModalVariant}
          variant={confirmationModalVariant}
          onPrimaryButtonClick={() =>
            confirmationModalClick(ConfirmationModalClickKind.APPLY)
          }
          onSecondryButtonClick={() =>
            confirmationModalClick(ConfirmationModalClickKind.CANCEL)
          }
        />
      )}
      <BulkEditSlideOut
        headerText={intl.formatMessage({
          id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_BULK_EDIT_SLIDEOUT_MODAL_TITLE,
        })}
        isOpen={shoWBulkEditSlideOut}
        primaryButtonText={intl.formatMessage({
          id: I18nKey.GENERIC_SUBMIT,
        })}
        secondaryButtonText={intl.formatMessage({
          id: I18nKey.FILTER_ROW_CANCEL,
        })}
        editControls={bulkEditModalEditControls}
        onSubmit={onSubmitHandler}
        onClose={onModalCancelClick}
        onCancel={onModalCancelClick}
      />
      <UpdatedFlywheelTable<ProductAdDetails, TableDataAdsManager>
        actionRowV2Props={{
          loadingText: intl.formatMessage({
            id: I18nKey.ADVERTISING_OPTIMIZATION_TABLE_ACTION_ROW_LOADING_TEXT,
          }),
          isLoading: isSaveChangesLoading,
          isActive: selectedRowsCount > 0,
          selectedCount: selectedRowsCount,
          totalCount: productAdTableData.length,
          showEditButton: isEditMode && selectedRowsCount > 0,
          showSlider: true,
          sliderMode: tableMode,
          onSliderModeChange: handleOperationsAsPerTableMode,
          showSelectedCount: isEditMode,
          className: 'border border-grey-200 rounded-t-sm',
          onEditClick: () => setShoWBulkEditSlideOut(!shoWBulkEditSlideOut),
          ...getActionRowButtons(
            tableMode,
            intl,
            getSaveButtonState,
            onActionClick
          ),
          viewOnlyMode: !!isUserRoleViewOnly,
        }}
        tableData={{
          isEditMode,
          adTypes,
          salesChannel: selectedSalesChannel,
          selectedAdType,
          merchantCountry,
          selectedMerchantCountries,
          aoApiClient,
          merchantType,
          productConnectionStatus,
          selectedEndDate: endDate,
          allMerchants: [],
          onViewTrendsSlideoverClick,
          showSmartCampaign: shouldShowSmartCampaigns(optimizelyContext),
          aiEnabled,
          merchantsWithAutomationEnabled,
        }}
        filtersContainerClass="transform -translate-x-2/4 lgmax:left-full md_1080:translate-x-0 md_1080:right-0"
        columns={getVisibleColumnsAdItems()}
        tableId={TABLE_ID}
        currencyCode={currencyCode || CURRENCY_CODE}
        dataFetcher={dataFetcher}
        footerTotalItemsI18nKey={
          I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_FOOTER_TOTAL_ITEMS_TEXT
        }
        hasStickyHeader
        hasStickyLeftColumn
        errorComponent={<ErrorTable />}
        yesFilterNoDataDisplayComponent={<NoFilteredDataFoundTable />}
        noFilterNoDataDisplayComponent={<NoDataFoundTable />}
        inputSearchColumnName={PRODUCTAD_TABLE_SEARCH_KEY}
        actionRowRightSideComponents={tableActionRowRightSideElements}
        dataFields={dataFields}
        onFilterUpdate={onFilterUpdate}
        filterFieldMapper={ALL_AD_LEVELS_FILTER_FILED_MAPPER}
        filtersFromBrowserStorage={filtersFromBrowserStorage}
        storeFiltersInBrowserStorage={storeFiltersInBrowserStorage}
        searchInputSize={SearchInputSize.Medium}
        hasSearchInputWithButton
        searchInputPlaceholder={getSearchInputPlaceholder(
          selectedAdType,
          adLevelDropDownSelectedValues as AdLevel,
          intl
        )}
        selectedColumns={selectedColumns}
        columnManagerOptions={columnManagerOptions}
        onColumnSelectionChange={onColumnSelectionChange}
        dataTestId="table_productAds"
        showTableActionRow2={true}
        openFilterMenuOnPillClick={() => setFilterMenuOpen(true)}
      />
    </>
  );
};
TableProductAds.displayName = 'TableProductAds';
