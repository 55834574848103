import {
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makePercentColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdType,
  CampaignDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  isNumericValueWithCampaignTacticAndCampaignAdFormatValid,
} from '../utils';

export const RowCellElement: React.FC<CampaignDetails> = (props) => {
  const isSponseredBrandsAdType =
    props.selectedAdType === AdType.SponsoredBrands;

  return makePercentColumn<CampaignDetails>(
    ({
      campaignPerformance: { newToBrandOrderRate },
      campaignDetails: { campaignTactic },
    }) =>
      isNumericValueWithCampaignTacticAndCampaignAdFormatValid(
        newToBrandOrderRate,
        campaignTactic,
        isSponseredBrandsAdType
      )
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const newToBrandOrdersRateColumn: FlywheelTableColumn<CampaignDetails> =
  {
    columnName: CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrdersRate,
    isSortable: true,
    i18nKeyOrLabel:
      I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS_RATE,
    RowCellElement,
    gridColumnWidth: '130px',
    className: 'text-right',
    columnHeaderClassName: 'justify-end',
  };

export const newToBrandOrdersRateFilter = createPercentDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.NewToBrandOrdersRate,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_NEW_TO_BRAND_ORDERS_RATE,
  isValidNumber()
);
