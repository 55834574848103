import { IntlShape } from 'react-intl';

import { ChartTime, ChartType } from '@teikametrics/tm-design-system';

import { HomepageDataRequestResponse } from '../../';
import {
  AggregationPeriodType,
  GraphDataPoints,
  HomepageResponseMetricKeys,
  OverviewTimePeriod,
  TimePeriodGraphDataPoints,
} from '../../../../lib/types/CompassSharedTypes';
import { FlywheelSalesChannel } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../lib/types/SalesChannels';
import {
  ChartTrendMetricFormatSelector,
  CompassState,
  MetricTabsData,
  MetricTabsName,
} from '../../types';
import { CurrencyCode } from '../../../../lib/utilities/currency';

export enum DashboardRoutes {
  Dashboard = '/',
  CompassDashboard = '/*',
}

export enum CompassTabs {
  Business = 'Business',
  Ads = 'Ads',
  Products = 'Products',
}

export enum CompassRoutes {
  Business = 'business',
  Ads = 'ads',
  Products = 'products',
}

export enum CompassPathParams {
  TotalSales = 'total-sales',
  AdSales = 'ad-sales',
  AdSpend = 'ad-spend',
  Tacos = 'tacos',
  Roas = 'Roas',
}

export const MAP_COMPASS_PATH_PARAMS_TO_METRIC_NAME: Record<
  CompassPathParams,
  string
> = {
  [CompassPathParams.TotalSales]: I18nKey.COMPASS_DASHBOARD_TOTAL_SALES,
  [CompassPathParams.AdSales]: I18nKey.COMPASS_DASHBOARD_AD_SALES,
  [CompassPathParams.AdSpend]: I18nKey.COMPASS_DASHBOARD_AD_SPEND,
  [CompassPathParams.Tacos]: I18nKey.COMPASS_DASHBOARD_TACOS,
  [CompassPathParams.Roas]: I18nKey.COMPASS_DASHBOARD_ROAS,
};

export enum CompassChartColors {
  AdSpend = 'Ad-spend',
  TotalSales = 'Total-Sales',
  Current = 'current',
  Previous = 'previous',
  WithoutAI = 'without-AI',
  WithAI = 'with-AI',
  DataConnected = 'Data-Connected',
}

export const MAP_COMPASS_PATH_PARAMS_TO_HOMEPAGE_RESPONSE_METRIC_KEYS = {
  [CompassPathParams.TotalSales]: HomepageResponseMetricKeys.TotalSales,
  [CompassPathParams.AdSales]: HomepageResponseMetricKeys.AdSales,
  [CompassPathParams.AdSpend]: HomepageResponseMetricKeys.AdSpend,
  [CompassPathParams.Tacos]: HomepageResponseMetricKeys.Tacos,
  [CompassPathParams.Roas]: HomepageResponseMetricKeys.Roas,
};

export const MAP_COMPASS_PATH_PARAMS_TO_DATA_TEST_ID_FOR_TAB_GROUP: Record<
  CompassPathParams,
  string
> = {
  [CompassPathParams.TotalSales]: 'TotalSales',
  [CompassPathParams.AdSales]: 'AdSales',
  [CompassPathParams.AdSpend]: 'AdSpend',
  [CompassPathParams.Tacos]: 'TACoS',
  [CompassPathParams.Roas]: 'roas',
};

export const MAP_COMPASS_PATH_PARAMS_TO_HOMEPAGE_CHART_HEADER_KEYS: Record<
  CompassPathParams,
  I18nKey
> = {
  [CompassPathParams.TotalSales]: I18nKey.COMPASS_DASHBOARD_TOTAL_SALES,
  [CompassPathParams.AdSales]: I18nKey.COMPASS_DASHBOARD_AD_SALES,
  [CompassPathParams.AdSpend]: I18nKey.COMPASS_DASHBOARD_AD_SPEND,
  [CompassPathParams.Tacos]: I18nKey.COMPASS_DASHBOARD_TACOS,
  [CompassPathParams.Roas]: I18nKey.COMPASS_DASHBOARD_ROAS,
};

export const MAP_CHART_TREND_METRIC_TO_OVERVIEW_TIME_PERIOD: Record<
  ChartTrendMetricFormatSelector,
  OverviewTimePeriod
> = {
  [ChartTrendMetricFormatSelector.Day]: OverviewTimePeriod.Daily,
  [ChartTrendMetricFormatSelector.Week]: OverviewTimePeriod.Weekly,
  [ChartTrendMetricFormatSelector.Month]: OverviewTimePeriod.Monthly,
};

export const MAP_COMPASS_PATH_PARAMS_TO_HOMEPAGE_INSIGHTS_TABLE_PERCENT_COLUMN: Record<
  CompassPathParams,
  I18nKey
> = {
  [CompassPathParams.TotalSales]: I18nKey.COMPASS_DASHBOARD_SALES_PERCENT,
  [CompassPathParams.AdSales]: I18nKey.COMPASS_DASHBOARD_SALES_PERCENT,
  [CompassPathParams.AdSpend]: I18nKey.COMPASS_DASHBOARD_AD_SPEND_PERCENT,
  [CompassPathParams.Tacos]: I18nKey.COMPASS_DASHBOARD_TACOS_PERCENT,
  [CompassPathParams.Roas]: I18nKey.COMPASS_DASHBOARD_TACOS_PERCENT,
};

export enum CompassTabDefaultRoutes {
  Business = '/compass/business',
  Ads = '/compass/ads',
  Products = '/compass/products',
}

export enum BusinessPerformanceRoutes {
  Business = '/compass/business',
}

export enum AdsformanceRoutes {
  TotalSales = '/compass/ads/total-sales',
  AdSales = '/compass/ads/ad-sales',
  AdSpend = '/compass/ads/ad-spend',
  Tacos = '/compass/ads/tacos',
}

export enum ProductsformanceRoutes {
  TotalSales = '/compass/products/total-sales',
  AdSales = '/compass/products/ad-sales',
  AdSpend = '/compass/products/ad-spend',
  Tacos = '/compass/products/tacos',
}

export interface CompassRouteParams {
  readonly metric: CompassPathParams;
}

export const MetricCardsGroupHeaders = {
  TotalSales: I18nKey.COMPASS_DASHBOARD_TOTAL_SALES,
  AdSales: I18nKey.COMPASS_DASHBOARD_AD_SALES,
  AdSpend: I18nKey.COMPASS_DASHBOARD_AD_SPEND,
  Tacos: I18nKey.COMPASS_DASHBOARD_TACOS,
  Roas: I18nKey.COMPASS_DASHBOARD_ROAS,
};

export const COMPASS_TAB_VALUE_TO_ROUTE_MAPPER: Record<
  CompassTabs,
  CompassTabDefaultRoutes
> = {
  [CompassTabs.Business]: CompassTabDefaultRoutes.Business,
  [CompassTabs.Ads]: CompassTabDefaultRoutes.Ads,
  [CompassTabs.Products]: CompassTabDefaultRoutes.Products,
};

export const COMPASS_ROUTE_TO_TAB_VALUE_MAPPER: Record<
  CompassRoutes,
  CompassTabs
> = {
  [CompassRoutes.Business]: CompassTabs.Business,
  [CompassRoutes.Ads]: CompassTabs.Ads,
  [CompassRoutes.Products]: CompassTabs.Products,
};

export interface TabPaths {
  readonly [MetricTabsName.totalSales]: string;
  readonly [MetricTabsName.adSpend]: string;
  readonly [MetricTabsName.adSales]: string;
  readonly [MetricTabsName.tacos]: string;
  readonly [MetricTabsName.roas]: string;
}

export enum CompassCharts {
  LineChart = 'LineChart',
  BarChart = 'BarChart',
}

export const COMPASS_CHART_MAPPER: Record<CompassCharts, ChartType> = {
  [CompassCharts.LineChart]: ChartType.LineChart,
  [CompassCharts.BarChart]: ChartType.BarChart,
};

export const MAP_CHART_METRIC_TO_BAR_CHART_COLOR: Record<
  CompassChartColors,
  string
> = {
  [CompassChartColors.AdSpend]: '#99E6FF',
  [CompassChartColors.TotalSales]: '#9892F4',
  [CompassChartColors.Current]: '#4B25EA',
  [CompassChartColors.Previous]: '#B7C2CC',
  [CompassChartColors.WithAI]: '#BD18E6',
  [CompassChartColors.WithoutAI]: '#748494',
  [CompassChartColors.DataConnected]: '#4B25EA',
};

export const MAP_INSIGHTS_TAB_GROUP_INDEX_TO_SALES_CHANNEL_ID: Record<
  number,
  string
> = {
  0: AMAZON_SALES_CHANNEL_ID,
  1: WALMART_SALES_CHANNEL_ID,
};

export const MAP_SALES_CHANNEL_ID_TO_NAME: Record<
  string,
  FlywheelSalesChannel
> = {
  [AMAZON_SALES_CHANNEL_ID]: FlywheelSalesChannel.Amazon,
  [WALMART_SALES_CHANNEL_ID]: FlywheelSalesChannel.Walmart,
};
export interface ChartData {
  readonly x?: string;
  readonly y?: number;
  readonly custom?: any;
}

interface GraphPointCommonProps {
  readonly intl: IntlShape;
  readonly selectedCurrency: CurrencyCode;
  readonly selectedDateRangeDiffInDays: number;
}

export interface GraphPoint extends GraphPointCommonProps {
  readonly data: HomepageDataRequestResponse;
  readonly xAxisLabels: string[];
  readonly chartsTabs: ChartTime;
  readonly trendFormatSelector: ChartTrendMetricFormatSelector;
  readonly compassState: CompassState;
  readonly metricTabsData: MetricTabsData;
}

export interface LeftSideLegendGraphData extends Omit<GraphPoint, 'marker'> {
  readonly chartsTabs: ChartTime;
  readonly activatedAiDataPoints?: string[] | number[];
  readonly deactivatedAiDataPoints?: string[] | number[];
  readonly compassState: CompassState;
  readonly metricTabsData: MetricTabsData;
}

export interface GraphDataPointsPeriodType
  extends Omit<GraphPointCommonProps, 'selectedDateRangeDiffInDays'> {
  readonly dataType: AggregationPeriodType;
  readonly xAxisLabels: string[];
  readonly dataGraphPoints?: TimePeriodGraphDataPoints;
  readonly opposingDataGraphPoints?: TimePeriodGraphDataPoints;
  readonly chartsTabs: ChartTime;
  readonly name?: string;
  readonly trendFormatSelector?: ChartTrendMetricFormatSelector;
  readonly isCurrent?: boolean;
  readonly compassState?: CompassState;
}

export interface CustomHoverGraphDataPointsData
  extends Omit<GraphPointCommonProps, 'selectedDateRangeDiffInDays'> {
  readonly total: string;
  readonly totalSales?: string;
  readonly adSpend?: string;
  readonly date?: string;
  readonly opposingDate?: string;
  readonly currentData?: string;
  readonly previousData?: string;
  readonly showMetricsData?: boolean;
  readonly name?: string;
  readonly trendFormatSelector?: ChartTrendMetricFormatSelector;
  readonly chartDataPeriod?: string;
}

export interface CurrentPeriodTacos {
  c: GraphDataPoints;
  intl: IntlShape;
  selectedCurrency: CurrencyCode;
  marker: string;
  header: string;
  index: number;
  previousDataPoints?: GraphDataPoints[];
}
