import Highcharts from 'highcharts';
import flatten from 'lodash/flatten';
import { DateTime } from 'luxon';
import { IntlShape, useIntl } from 'react-intl';

import { DateRange } from '../../../../lib/clients/types';
import { NumberMap, StrictStringMap, StringMap } from '../../../../lib/types';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  BCSChartsDataResponse,
  BrandCoverageOnSearchData,
  DashboardQueryParams,
  LineChartData,
  LineChartSequenceData,
  PieChartData,
  Platforms,
  SearchTermsData,
} from '../../../../lib/types/MISharedTypes';
import {
  AmazonLogomarkEnabledIcon,
  SvgIcon,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';
import { EmDash, PieSliceData, TabTheme } from '../../components';
import {
  AxiosResponseFile,
  BCSTabTypeEnum,
  GenerateSeriesDataProps,
  PlatformData,
  ReportingQueryParams,
  RowCellBcsChartProps,
  SeriesDataProps,
  YAxisTickerLevels,
} from './types';
import { NavPaths } from '../../../../NavPaths';
import createHash from 'create-hash';

export const getMiBCSTabs = [
  {
    nameI18nKey: I18nKey.MARKET_INTELLIGENCE_BCS_TABS_TOTAL,
    theme: TabTheme.LightPurple,
  },
  {
    nameI18nKey: I18nKey.MARKET_INTELLIGENCE_BCS_TABS_ORGANIC,
    theme: TabTheme.LightPurple,
  },
  {
    nameI18nKey: I18nKey.MARKET_INTELLIGENCE_BCS_TABS_PAID,
    theme: TabTheme.LightPurple,
  },
];

export const MAP_BCS_TAB_INDEX_TO_LABEL: StringMap<string> = {
  0: I18nKey.MARKET_INTELLIGENCE_BCS_TABS_TOTAL,
  1: I18nKey.MARKET_INTELLIGENCE_BCS_TABS_ORGANIC,
  2: I18nKey.MARKET_INTELLIGENCE_BCS_TABS_PAID,
};

export enum TypeOfElement {
  Chart = 'chart',
  Table = 'table',
  ToolTip = 'toolTip',
}

const MAX_NUMBER_OF_BRANDS_SHOWN_ON_BCS_CHARTS: number = 5;
export const UNIDENTIFIED_BRAND_STRING = '----------';
export const UNIDENTIFIED_BRAND_UI_REPRESENTENTATION = 'UNIDENTIFIED BRAND';

const transformPieChartDataForPieChartAndDataTable = (
  intl: IntlShape,
  pieChartData: PieChartData[],
  forPieChart: boolean
) => {
  pieChartData.sort((a, b) => {
    if (
      a.name === UNIDENTIFIED_BRAND_STRING ||
      b.name === UNIDENTIFIED_BRAND_STRING
    ) {
      if (
        a.name === UNIDENTIFIED_BRAND_STRING &&
        b.name === UNIDENTIFIED_BRAND_STRING
      )
        return 0;
      else if (a.name === UNIDENTIFIED_BRAND_STRING) return 1;
      else if (b.name === UNIDENTIFIED_BRAND_STRING) return -1;
    }
    return b.value - a.value;
  });
  const transformedData: PieChartData[] = [...pieChartData];
  const getUnIdentifiedBrandIndex = getIndexOfUnIdentifiedBrand(pieChartData);
  const spliceIndex =
    getUnIdentifiedBrandIndex === -1
      ? MAX_NUMBER_OF_BRANDS_SHOWN_ON_BCS_CHARTS
      : getUnIdentifiedBrandIndex < MAX_NUMBER_OF_BRANDS_SHOWN_ON_BCS_CHARTS
      ? getUnIdentifiedBrandIndex
      : MAX_NUMBER_OF_BRANDS_SHOWN_ON_BCS_CHARTS;
  const otherBrandsDataArray = transformedData.splice(spliceIndex);
  if (otherBrandsDataArray.length) {
    transformedData.push(
      ...[
        {
          name: intl.formatMessage({
            id: I18nKey.MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_FOOTER_COLUMN_BRAND_NAME_TITLE,
          }),
          value: otherBrandsDataArray.reduce(
            (a: number, { value }: any) => a + value,
            0
          ),
          numOfOtherBrands: forPieChart
            ? undefined
            : intl.formatMessage(
                {
                  id: I18nKey.MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_FOOTER_COLUMN_BRAND_NAME_DESCRIPTION,
                },
                { numOfOtherBrands: otherBrandsDataArray.length }
              ),
        },
      ]
    );
  }
  return transformedData;
};

const getIndexOfUnIdentifiedBrand = (pieChartData: PieChartData[]) =>
  pieChartData.findIndex(
    (pieChartDataSingle) =>
      pieChartDataSingle.name === UNIDENTIFIED_BRAND_STRING
  );

export const pieChartAPIDataTransform = (
  intl: IntlShape,
  pieChartData: PieChartData[]
): PieSliceData[] => {
  const transformedData = transformPieChartDataForPieChartAndDataTable(
    intl,
    pieChartData,
    true
  );
  const pieSliceData: PieSliceData[] = transformedData.map(
    (item: PieChartData, index: number) => ({
      ...item,
      color:
        POSITION_TO_PIE_SLICE_COLOR[index] ||
        MAX_NUMBER_OF_BRANDS_SHOWN_ON_BCS_CHARTS.toString(),
      isVisible: true,
      y: item.value,
    })
  );
  return pieSliceData;
};

export const OTHER_BRANDS_COLOR = 'grey';

export const transformAPIResponseToDataTableRowData = (
  intl: IntlShape,
  pieChartData: PieChartData[],
  typeOfElement: string
): RowCellBcsChartProps[] => {
  const transformedData = transformPieChartDataForPieChartAndDataTable(
    intl,
    pieChartData,
    false
  );
  return transformedData.map((item: PieChartData, index: number) => ({
    ...item,
    value: item.value
      ? intl.formatNumber(item.value, {
          style: 'percent',
          minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
          maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        })
      : item.name
      ? intl.formatMessage(
          {
            id: I18nKey.MARKET_INTELLIGENCE_CHARTS_DATA_TABLE_HEADER_COLUMN_BCS_PERCENTAGE_NO_DATA,
          },
          {
            mdash: EmDash,
          }
        )
      : '',
    color:
      typeOfElement === TypeOfElement.ToolTip &&
      index === MAX_NUMBER_OF_BRANDS_SHOWN_ON_BCS_CHARTS
        ? OTHER_BRANDS_COLOR
        : PIE_SLICR_COLOR_TO_CSS_CLASSNAME[
            POSITION_TO_PIE_SLICE_COLOR[index] ||
              MAX_NUMBER_OF_BRANDS_SHOWN_ON_BCS_CHARTS.toString()
          ] || 'white',
    isVisible: true,
  }));
};

export const POSITION_TO_PIE_SLICE_COLOR: StringMap<string> = {
  0: '#4b25ea',
  1: '#35C477',
  2: '#ffce1c',
  3: '#fb590e',
  4: '#0ac2ff',
  5: '#748494',
};

export const PIE_SLICR_COLOR_TO_CSS_CLASSNAME: StringMap<string> = {
  '#ffce1c': 'yellow',
  '#0ac2ff': 'blue',
  '#fb590e': 'orange',
  '#4b25ea': 'purple',
  '#35C477': 'green',
  '#748494': 'grey',
  '#fff': 'white',
};

export const CSS_CLASSNAME_TO_PIE_SLICR_COLOR: StringMap<string> = {
  yellow: '#ffce1c',
  blue: '#0ac2ff',
  orange: '#fb590e',
  purple: '#4b25ea',
  green: '#35C477',
  grey: '#748494',
  white: '#fff',
};

const isDataQueriedForSingleDay = (numOfDaysQueriedFor: number) => {
  return numOfDaysQueriedFor === 2;
};

export const generateSeriesData = ({
  xAxisLabels,
  requiredBrands,
  colorMappingOfBrands,
  chartData,
}: GenerateSeriesDataProps): [SeriesDataProps[], number] => {
  const formatPercentageData = (
    testData: Array<number | null>
  ): Array<number | null> => {
    return testData.map((bcsPercentage) =>
      bcsPercentage ? bcsPercentage * 101 : bcsPercentage
    );
  };

  const getFormattedSequenceData = (
    sequence: LineChartSequenceData[]
  ): Array<number | null> => {
    const chartSequenceObject: { [key: string]: number } = sequence.reduce(
      (obj, item) => Object.assign(obj, { [item.date]: item.value }),
      {}
    );
    return [...Array(xAxisLabels.length)].map((ignore, index: number) => {
      const dateTimeKey = xAxisLabels[index];
      return chartSequenceObject.hasOwnProperty(dateTimeKey)
        ? chartSequenceObject[dateTimeKey]
        : null;
    });
  };

  const lineChartSequence = chartData
    .filter((point: LineChartData) => requiredBrands.includes(point.name))
    .map((point: LineChartData) => ({
      name: point.name,
      xAxis: 0,
      type: 'spline',
      data: formatPercentageData(getFormattedSequenceData(point.sequence)),
      color: colorMappingOfBrands[point.name],
      marker: {
        enabled: false,
        symbol: 'circle',
        fillColor: colorMappingOfBrands[point.name],
        radius: 4,
        lineWidth: 1,
      },
      connectNulls: true,
    }));

  const xAxisDataPoints: number[][] = lineChartSequence.map(({ data }) =>
    data.map((dataPoint) => (typeof dataPoint === 'number' ? dataPoint : 0))
  );

  return [lineChartSequence, Math.max(...flatten(xAxisDataPoints))];
};

const X_AXIS_DATE_LABEL_FORMAT = 'LLL d, y';
const X_AXIS_DATE_LABEL_24_HOUR_FORMAT = 'LLL dd - hh a, yyyy';

export const getXAxisLabels = (
  numOfDays: number,
  startDate: string,
  forSequenceReference: boolean,
  syncTimings: string[],
  showLastTiming: boolean
): string[] => {
  return isDataQueriedForSingleDay(numOfDays)
    ? forSequenceReference
      ? syncTimings
      : syncTimings.map((syncTime: string) =>
          DateTime.fromISO(syncTime.replace(' ', 'T')).toFormat(
            X_AXIS_DATE_LABEL_24_HOUR_FORMAT
          )
        )
    : [...Array(showLastTiming ? numOfDays : numOfDays - 1)].map((e, index) => {
        const date = DateTime.fromISO(startDate).plus({ days: index });
        if (forSequenceReference) {
          return date.toISODate() as string;
        } else {
          return date.toFormat(X_AXIS_DATE_LABEL_FORMAT) as string;
        }
      });
};

export const DEFAULT_Y_AXIS_TICKER_LEVEL = YAxisTickerLevels.TickerLevelTwenty;

const MAP_MAX_Y_AXIS_LABEL_TO_TICK_INTERVAL: NumberMap<YAxisTickerLevels> = {
  20: YAxisTickerLevels.TickerLevelFive,
  40: YAxisTickerLevels.TickerLevelTen,
};

export const getTickInterval = (
  maxYAxisLabel: number,
  divisibleBy: number
): number => {
  return (
    MAP_MAX_Y_AXIS_LABEL_TO_TICK_INTERVAL[
      Math.ceil(maxYAxisLabel / divisibleBy) * divisibleBy
    ] || DEFAULT_Y_AXIS_TICKER_LEVEL
  );
};

export const MAP_TAB_INDEX_TO_API_RESPONSE_KEY: NumberMap<BCSTabTypeEnum> = {
  0: BCSTabTypeEnum.Total,
  1: BCSTabTypeEnum.Organic,
  2: BCSTabTypeEnum.Paid,
};
export const TODAY: DateTime = DateTime.utc().set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});
export const YESTERDAY: DateTime = TODAY.minus({ days: 1 }).set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});
export const WEEK_BEFORE: DateTime = TODAY.minus({ days: 6 }).set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});
export const FORTNIGHT_BEFORE: DateTime = TODAY.minus({ days: 13 }).set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});
export const MONTH_BEFORE: DateTime = TODAY.minus({ days: 29 }).set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});
export const DAYS_30: DateTime = TODAY.minus({ days: 30 }).set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});
export const TWO_MONTHS: DateTime = TODAY.minus({ days: 59 }).set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});
export const THREE_MONTHS: DateTime = TODAY.minus({ days: 89 }).set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});

export const createDateRangeLinks = (showNewOptions?: boolean) => {
  const currentDateNow = TODAY;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatMessage } = useIntl();
  return [
    {
      title: formatMessage({
        id: I18nKey.MARKET_INTELLIGENCE_DATE_PICKER_OPTION_24_HOURS,
      }),
      to: currentDateNow,
      from: YESTERDAY,
    },
    {
      title: formatMessage({
        id: I18nKey.MARKET_INTELLIGENCE_DATE_PICKER_OPTION_7_DAYS,
      }),
      to: currentDateNow,
      from: WEEK_BEFORE,
    },
    {
      title: formatMessage({
        id: I18nKey.MARKET_INTELLIGENCE_DATE_PICKER_OPTION_14_DAYS,
      }),
      to: currentDateNow,
      from: FORTNIGHT_BEFORE,
    },
    {
      title: formatMessage({
        id: I18nKey.MARKET_INTELLIGENCE_DATE_PICKER_OPTION_30_DAYS,
      }),
      to: currentDateNow,
      from: MONTH_BEFORE,
    },
    ...(showNewOptions
      ? [
          {
            title: formatMessage({
              id: I18nKey.MARKET_INTELLIGENCE_DATE_PICKER_OPTION_60_DAYS,
            }),
            to: currentDateNow,
            from: TWO_MONTHS,
          },
          {
            title: formatMessage({
              id: I18nKey.MARKET_INTELLIGENCE_DATE_PICKER_OPTION_90_DAYS,
            }),
            to: currentDateNow,
            from: THREE_MONTHS,
          },
        ]
      : []),
  ];
};

export const reportingTableTabs = [
  I18nKey.MARKET_INTELLIGENCE_REPORTING_TABLE_TABS_BRAND_COVERAGE_ON_SEARCH,
  I18nKey.MARKET_INTELLIGENCE_REPORTING_TABLE_TABS_AVERAGE_PRODUCT_RANK,
];

export const getValueOrDash = (value: string | number) => value || EmDash;

export enum ReportingTableTabsIndex {
  AverageProductRank,
  BrandCoverageOnSearch,
}

export const getExportedFileNameAndDownloadUrl = (
  { data, headers }: AxiosResponseFile,
  filenameResponseHeader: string,
  defaultFileName: string
) => {
  const fileUrl = URL.createObjectURL(new Blob([data]));
  const fileName: string = headers[filenameResponseHeader] || defaultFileName;
  return { fileName, fileUrl };
};

export const getISODateFromDateTimeDate = (date: DateTime) =>
  DateTime.fromISO(date.toISO() as string)
    .toUTC()
    .toISO();

export const isPlatformEqual = (
  platform1?: PlatformData,
  platform2?: PlatformData
): boolean =>
  (platform1 && platform2 && platform1.name === platform2.name) || false;

export const isSearchTermEqual = (
  searchTerm1?: SearchTermsData,
  searchTerm2?: SearchTermsData
): boolean =>
  (searchTerm1 &&
    searchTerm2 &&
    searchTerm1.locale === searchTerm2.locale &&
    searchTerm1.search_term === searchTerm2.search_term) ||
  false;

export const isDateRangeEqual = (
  dateRange1: DateRange,
  dateRange2: DateRange
): boolean =>
  dateRange1.from.toISODate() === dateRange2.from.toISODate() &&
  dateRange1.to.toISODate() === dateRange2.to.toISODate();

export const isReportingQueryParamEqual = (
  param1: ReportingQueryParams,
  param2: ReportingQueryParams
): boolean => {
  const isPrevSelectedPlatform =
    param1.platform &&
    param2.platform &&
    isPlatformEqual(param1.platform, param2.platform);
  const isPrevSelectedSearchTerm =
    param1.searchTerm &&
    param2.searchTerm &&
    isSearchTermEqual(param1.searchTerm, param2.searchTerm);
  const isPrevSelectedDateRange = isDateRangeEqual(
    param1.dateRange,
    param2.dateRange
  );

  return (isPrevSelectedPlatform &&
    isPrevSelectedSearchTerm &&
    isPrevSelectedDateRange)!;
};

const TOTAL_TOOLTIP_PERCENTAGE = 100;

export const calculateOtherBrandsPercentageOnTooltip = (
  points?: Highcharts.TooltipFormatterContextObject[]
): number => {
  return (
    TOTAL_TOOLTIP_PERCENTAGE -
    (points
      ?.map((point) => point.y)
      .reduce((yAxisValue, sum) => (yAxisValue || 0) + (sum || 0)) ||
      TOTAL_TOOLTIP_PERCENTAGE)
  );
};

export const showOtherBrandsPercentageOnTooltip = (
  points?: Highcharts.TooltipFormatterContextObject[]
): boolean => {
  return (
    points?.length === MAX_NUMBER_OF_BRANDS_SHOWN_ON_BCS_CHARTS &&
    calculateOtherBrandsPercentageOnTooltip(points) !== 0
  );
};

export const getPlatformLabels = (intl: IntlShape): StrictStringMap<string> => {
  const PLATFORMS_LABELS: StrictStringMap<string> = {
    Amazon: intl.formatMessage({
      id: I18nKey.MARKET_INTELLIGENCE_REPORTING_PLATFORM_AMAZON,
    }),
    Walmart: intl.formatMessage({
      id: I18nKey.MARKET_INTELLIGENCE_REPORTING_PLATFORM_WALMART,
    }),
    eBay: intl.formatMessage({
      id: I18nKey.MARKET_INTELLIGENCE_REPORTING_PLATFORM_EBAY,
    }),
    Google_Shopping: intl.formatMessage({
      id: I18nKey.MARKET_INTELLIGENCE_REPORTING_PLATFORM_GOOGLE_SHOPPING,
    }),
    Target: intl.formatMessage({
      id: I18nKey.MARKET_INTELLIGENCE_REPORTING_PLATFORM_TARGET,
    }),
  };
  return PLATFORMS_LABELS;
};

export const AVERAGE_PRODUCT_RANK_TABLE_SEARCH_KEY =
  'product_id_title_brand_name';

export const BCS_TABLE_SEARCH_KEY = 'brand_name';

export const getSearchTermsForSelectedPlatform = (
  allSearchTerms: Array<SearchTermsData>,
  platform?: string
) => {
  return allSearchTerms.filter(
    (searchTerms) => searchTerms.platform === platform
  );
};

export const sortDataForBrandCoverageOnSearchTable = (
  brandCoverageOnSearchData: Array<BrandCoverageOnSearchData>
) =>
  brandCoverageOnSearchData.map((currentBrandData) =>
    currentBrandData.brand_name === UNIDENTIFIED_BRAND_STRING
      ? {
          ...currentBrandData,
          brand_name: UNIDENTIFIED_BRAND_UI_REPRESENTENTATION,
        }
      : currentBrandData
  );

export const getRelevantMiBCSTabs = (
  chartData: BCSChartsDataResponse | undefined
) => {
  if (!chartData) return [];
  return getMiBCSTabs.filter((tabInfo, index) => {
    const chartInfoTabType = MAP_TAB_INDEX_TO_API_RESPONSE_KEY[index];
    if (
      chartInfoTabType &&
      chartData[chartInfoTabType] &&
      chartData[chartInfoTabType].bcs &&
      chartData[chartInfoTabType].bcs.length
    )
      return true;
    return false;
  });
};

export const switchToRelevantBcsTab = (
  chartData: BCSChartsDataResponse | undefined,
  bcsChartActiveTabIndex: number
): number => {
  if (!chartData) {
    return bcsChartActiveTabIndex;
  }
  const apiResponseKey =
    MAP_TAB_INDEX_TO_API_RESPONSE_KEY[bcsChartActiveTabIndex];
  if (
    apiResponseKey &&
    chartData[apiResponseKey] &&
    chartData[apiResponseKey].bcs &&
    chartData[apiResponseKey].bcs.length
  ) {
    return bcsChartActiveTabIndex;
  }
  return getMiBCSTabs.findIndex(
    (tab) => tab.nameI18nKey === I18nKey.MARKET_INTELLIGENCE_BCS_TABS_TOTAL
  ); // For the total tab
};

export const platformMap: {
  [key: string]: {
    label: Platforms;
    icon: SvgIcon;
    value: Platforms;
  };
} = {
  [Platforms.Amazon]: {
    label: Platforms.Amazon,
    icon: AmazonLogomarkEnabledIcon,
    value: Platforms.Amazon,
  },
  [Platforms.Walmart]: {
    label: Platforms.Walmart,
    icon: WalmartLogomarkEnabledIcon,
    value: Platforms.Walmart,
  },
};

export const getSalesChannelOptions = (platforms: PlatformData[]) => {
  return platforms
    .filter((platform) => platform.active)
    .map((platform) => platformMap[platform.name]);
};

export const getMIDashboardURL = (
  accountId: string,
  values: DashboardQueryParams
) => {
  return `${NavPaths.MarketIntelligenceDashboard}/${accountId}?searchTerm=${
    values.searchTerm
  }&platform=${values.platform}&country=${values.country}${
    values.startDate ? `&startDate=${values.startDate}` : ''
  }${values.endDate ? `&endDate=${values.endDate}` : ''}${
    values.tab ? `&tab=${values.tab}` : ''
  }`;
};

export const ReportingTabs = [
  I18nKey.MARKET_INTELLIGENCE_PAGE_HEADER_REPORTING,
  I18nKey.MARKET_INTELLIGENCE_REPORTING_HISTORY_TAB,
];

export const generatePreComputeHash = (
  accountId: string,
  salesChannel: string,
  searchTerms: string,
  startDate: string,
  endDate: string,
  country: string,
  groupName: string = ''
): string => {
  const startDateWithZeroTime = DateTime.fromISO(startDate, { zone: 'utc' })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toISO();
  const endDateWithZeroTime = DateTime.fromISO(endDate, { zone: 'utc' }).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const body = [
    accountId,
    salesChannel,
    searchTerms,
    startDateWithZeroTime,
    endDateWithZeroTime,
    country,
    groupName,
  ].join('::');
  const hash = createHash('sha256').update(body).digest('hex');
  return hash;
};

export const sortNumberColumn = (
  arr: any,
  column: string,
  direction: string
) => {
  arr.sort((a: any, b: any) =>
    (a[column] as unknown as number) > (b[column] as unknown as number)
      ? direction === 'desc'
        ? -1
        : 1
      : direction === 'desc'
      ? 1
      : -1
  );
  return arr;
};
