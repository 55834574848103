import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) => {
  const currencyCode = getCurrencyCodeFromMerchantCountryCode(
    props.merchantCountry
  );

  return makeCurrencyColumn<ProductAdDetails>(
    ({ adItemDetails: { campaignTotalBudget } }) =>
      campaignTotalBudget
        ? {
            amount: Number(campaignTotalBudget.amount),
            currency:
              currencyCode || campaignTotalBudget.currency || CURRENCY_CODE,
          }
        : undefined
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignLifetimeBudgetColumn: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: SV_ADS_API_COLUMN_NAME.LifetimeBudget,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_LIFETIME_BUDGET,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
};

export const campaignLifetimeBudgetFilterForSvAds = (currency: string) =>
  createMoneyDataFieldFilter(
    SV_ADS_API_COLUMN_NAME.LifetimeBudget,
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_LIFETIME_BUDGET,
    currency,
    isValidNumber(0)
  );
