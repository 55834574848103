import { MerchantSyncData } from '../../../lib/types/AOSharedTypes';
import {
  EntityType,
  FlywheelSalesChannel,
  MerchantType,
  SalesChannelData,
} from '../../../lib/types/Fam';
import { isMerchantVendor } from '../containers/salesChannels/utils';
import {
  AMAZON_FLASH_MODAL_SCREENS,
  AmazonFlashScreens,
} from './AmazonModalSteps/amazonFlashScreens';
import { DEFAULT_SALES_CHANNELS } from './connectSalesChannelModal';
import {
  WALMART_FLASH_MODAL_SCREENS,
  WalmartFlashScreens,
} from './WalmartModalSteps/walmartFlashScreens';

export interface AmazonWalmartFlashScreenProps
  extends SalesChannelData,
    MerchantSyncData {
  readonly entity: EntityType;
  readonly channel: FlywheelSalesChannel;
  readonly showModal: boolean;
  readonly closeModal: (channel: FlywheelSalesChannel) => void;
}

export const AmazonWalmartFlashScreen: React.FC<
  AmazonWalmartFlashScreenProps
> = ({
  entity,
  merchantId,
  extMerchantId,
  country,
  extMerchantType,
  channel,
  closeModal,
  showModal,
  advertisingId,
}) => {
  const merchantCountries = [
    {
      merchantId: merchantId || '',
      merchantCountryId: extMerchantId,
      country: country,
      merchantType: extMerchantType || '',
    },
  ];
  const isVendor = isMerchantVendor(extMerchantType);
  const connectedMerchantIds = [extMerchantId];

  if (!showModal) {
    return null;
  }

  return (
    <>
      {channel === FlywheelSalesChannel.Amazon && (
        <AmazonFlashScreens
          onClose={() => {
            closeModal(channel);
          }}
          initialScreen={
            entity === EntityType.Ads
              ? AMAZON_FLASH_MODAL_SCREENS.AMZInitialConnectScreen
              : AMAZON_FLASH_MODAL_SCREENS.AMZAmazonProductModalScreen
          }
          notOwnedMerchant={merchantCountries}
          connectedMerchantIds={connectedMerchantIds}
        />
      )}
      {channel === FlywheelSalesChannel.Walmart && (
        <WalmartFlashScreens
          onClose={() => {
            closeModal(channel);
          }}
          initialScreen={
            entity === EntityType.Ads
              ? WALMART_FLASH_MODAL_SCREENS.WMTInitialConnectScreen
              : WALMART_FLASH_MODAL_SCREENS.WMTSelectMerchantTypeScreen
          }
          salesChannel={DEFAULT_SALES_CHANNELS[1]}
          defaultMerchantType={
            isVendor ? MerchantType.Vendor : MerchantType.Seller
          }
          extMerchantId={extMerchantId}
          advertisingId={advertisingId}
        />
      )}
    </>
  );
};

AmazonWalmartFlashScreen.displayName = 'AmazonWalmartFlashScreen';
