import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  CheckboxCheckedState,
  CheckboxLabel,
  FormRow,
  InputTypes,
  TextInput,
} from '@teikametrics/tm-design-system';

import { getSignUpData } from '../../containers/userProvider/selectors';
import { UserContext } from '../../containers/userProvider/userProvider';
import I18nKey from '../../lib/types/I18nKey';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { getCurrentAccountFromContext } from '../../containers/userProvider/selectors';
import { segmentIdentify } from '../../lib/utilities/segment';

const FLYWHEEL_SETUP_HUBSPOT_FORM_ID =
  process.env.REACT_APP_FLYWHEEL_SETUP_HUBSPOT_FORM_ID;

const COMMUNITY_HUBSPOT_FORM_ID =
  process.env.REACT_APP_COMMUNITY_HUBSPOT_FORM_ID;
export interface CollectProfileFormProps {
  readonly isCommunity: boolean;
  readonly setIsProfileUpdated: (flag: boolean) => void;
}

enum AnnualGMVOptions {
  AnnualGMVOption1 = '0-99999',
  AnnualGMVOption2 = '100000-1000000',
  AnnualGMVOption3 = '1000001-5000000',
  AnnualGMVOption4 = '$5,000,001 - $10,000,000',
  AnnualGMVOption5 = 'More than $10,000,000',
}
export const CollectProfileForm: React.FC<CollectProfileFormProps> = ({
  isCommunity,
  setIsProfileUpdated,
}) => {
  const userContext = useContext(UserContext);
  const { userInfo } = userContext;
  const { userDetails } = userInfo;

  const token = userContext.userInfo.idToken!!;
  const famClient = createFAMApiClient(userContext.userInfo.idToken!!);

  const signUpData = getSignUpData(token);

  const [firstName, setFirstName] = useState(
    signUpData?.first_name || token.given_name || ''
  );
  const [lastName, setLastName] = useState(
    signUpData?.last_name || token.family_name || ''
  );
  const [businessName, setBusinessName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [annualGMV, setAnnualGMV] = useState('');
  const [isChecked, setIsChecked] = useState(CheckboxCheckedState.Unchecked);
  const [loadingState, setLoadingState] = useState(ButtonState.Enabled);

  const intl = useIntl();

  const handleOnCheck = () => {
    setIsChecked(
      isChecked === CheckboxCheckedState.Unchecked
        ? CheckboxCheckedState.Checked
        : CheckboxCheckedState.Unchecked
    );
  };
  const [
    firstNameLabel,
    lastNameLabel,
    businessNameLabel,
    phoneNumberLabel,
    annualMPSLabel,
    businessNamePlaceholder,
    phoneNumberPlaceholder,
    annualGMVPlaceholder,
    annualGMVOption1,
    annualGMVOption2,
    annualGMVOption3,
    annualGMVOption4,
    annualGMVOption5,
    speakWithSales,
    optionalLabel,
  ] = [
    I18nKey.USER_PROFILE_FIRST_NAME_LABEL,
    I18nKey.USER_PROFILE_LAST_NAME_LABEL,
    I18nKey.USER_PROFILE_BUSINESS_NAME_LABEL,
    I18nKey.USER_PROFILE_PHONE_NUMBER_LABEL,
    I18nKey.USER_PROFILE_ANNUAL_MPS_LABEL,
    I18nKey.SETUP_FLYWHEEL_STEP_1_BUSINESS_NAME_PLACEHOLDER,
    I18nKey.SETUP_FLYWHEEL_STEP_1_PHONE_NUMBER_PLACEHOLDER,
    I18nKey.SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_PLACEHOLDER,
    I18nKey.SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_OPTION_LESS_THAN_100,
    I18nKey.SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_OPTION_BETWEEN_100_AND_1M,
    I18nKey.SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_OPTION_BETWEEN_1M_AND_5M,
    I18nKey.SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_OPTION_BETWEEN_5M_AND_10M,
    I18nKey.SETUP_FLYWHEEL_STEP_1_ANNUAL_GMV_OPTION_MORE_THAN_10M,
    I18nKey.SETUP_FLYWHEEL_STEP_1_SPEAK_WITH_SALES,
    I18nKey.SETUP_FLYWHEEL_STEP_1_OPTIONAL_LABEL,
  ].map((key) => intl.formatMessage({ id: key }));

  const isValid = firstName && lastName && businessName && annualGMV;

  const annualGMVOptions = [
    { label: annualGMVOption1, value: AnnualGMVOptions.AnnualGMVOption1 },
    { label: annualGMVOption2, value: AnnualGMVOptions.AnnualGMVOption2 },
    { label: annualGMVOption3, value: AnnualGMVOptions.AnnualGMVOption3 },
    { label: annualGMVOption4, value: AnnualGMVOptions.AnnualGMVOption4 },
    { label: annualGMVOption5, value: AnnualGMVOptions.AnnualGMVOption5 },
  ];

  const isGreaterThanMillion =
    annualGMV === AnnualGMVOptions.AnnualGMVOption3 ||
    annualGMV === AnnualGMVOptions.AnnualGMVOption4 ||
    annualGMV === AnnualGMVOptions.AnnualGMVOption5;

  const handleSubmit = async (
    annualGMV: string,
    businessName: string,
    connectToSales: CheckboxCheckedState
  ) => {
    setLoadingState(ButtonState.Loading);
    segmentIdentify(userContext.userInfo.userDetails?.id, token.email!, {
      annual_gmv_range: annualGMV,
      connect_to_sales:
        connectToSales === CheckboxCheckedState.Checked ? 'yes' : 'no',
    });
    await updateProfile(businessName);
    await famClient.sendFormToHubspot(
      isCommunity
        ? COMMUNITY_HUBSPOT_FORM_ID!
        : FLYWHEEL_SETUP_HUBSPOT_FORM_ID!,
      userDetails!,
      {
        annualGMV,
        connectToSales:
          connectToSales === CheckboxCheckedState.Checked ? 'yes' : 'no',
      }
    );
  };

  const updateProfile = async (companyName: string) => {
    const accountId = getCurrentAccountFromContext(userContext)?.id;
    const nameUpdateResult = await famClient.updateUsername(
      firstName.trim(),
      lastName.trim()
    );
    const result = await famClient.updateCompanyName(accountId!, companyName);
    // update user context with company name
    if (result && nameUpdateResult) {
      userContext.updateUserInfo({
        ...userContext.userInfo,
        userDetails: {
          ...userDetails!,
          firstName,
          lastName,
          accountPermissions:
            userDetails!.accountPermissions.map((ap) => {
              if (ap.account.id === result.id) {
                ap.account.companyName = companyName;
              }
              return ap;
            }) || [],
        },
      });
      setIsProfileUpdated(true);
    }
  };

  return (
    <div className="w-full">
      <FormRow
        fields={[
          {
            label: firstNameLabel,
            inputProps: {
              value: firstName,
              inputType: InputTypes.TEXT,
              onChange: setFirstName,
              className: 'w-full',
            },
          },
          {
            label: lastNameLabel,
            inputProps: {
              value: lastName,
              inputType: InputTypes.TEXT,
              onChange: setLastName,
              className: 'w-full',
            },
          },
        ]}
      />
      <div className="mt-8">
        <FormRow
          fields={[
            {
              label: businessNameLabel,
              inputProps: {
                value: businessName,
                inputType: InputTypes.TEXT,
                onChange: setBusinessName,
                placeholder: businessNamePlaceholder,
              },
            },
          ]}
        />
      </div>
      <div className="mt-8">
        <div className="w-full">
          <div className="leading-none text-base text-grey-900 mb-8">
            {phoneNumberLabel}
            <span className="text-grey-500 italic font-normal text-sm ml-8">
              {optionalLabel}
            </span>
          </div>
          <TextInput
            placeholder={phoneNumberPlaceholder}
            onChange={setPhoneNumber}
            value={phoneNumber}
            className="w-full"
          />
        </div>
      </div>
      <div className="mt-8">
        <FormRow
          fields={[
            {
              label: annualMPSLabel,
              inputProps: {
                value: annualGMV,
                inputType: InputTypes.SELECT,
                options: annualGMVOptions,
                onChange: setAnnualGMV,
                placeholder: annualGMVPlaceholder,
              },
            },
          ]}
        />
      </div>
      {isGreaterThanMillion && (
        <div className="my-16">
          <CheckboxLabel
            checked={isChecked}
            label={
              <div className="text-base text-grey-900">{speakWithSales}</div>
            }
            className="flex items-center"
            onClick={handleOnCheck}
          />
        </div>
      )}

      <Button
        size={ButtonSize.Large}
        variant={ButtonVariant.Primary}
        onClick={() => handleSubmit(annualGMV, businessName, isChecked)}
        state={isValid ? loadingState : ButtonState.Disabled}
        label={intl.formatMessage({
          id: I18nKey.GENERIC_SUBMIT,
        })}
        className="w-full mt-16"
      />
    </div>
  );
};
