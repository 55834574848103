import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputState,
  Placement,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeNumericInputColumn,
  makePercentColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import { CampaignDetails } from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
} from '../utils';
import { isDirty } from './totalBudget';

export const MIN_APP_BID = 0;

export const getExistingValue = (campaignDetails: CampaignDetails) =>
  (campaignDetails.channelSettings.platformBidMultiplier?.appBidMultiplier?.toString() &&
    (
      campaignDetails.channelSettings.platformBidMultiplier?.appBidMultiplier *
      100
    ).toFixed(PERCENTAGE_FRACTION_DIGITS)) ||
  '';

const getAppBuildMultiplier = (changedValue: string, existingValue: string) =>
  changedValue ?? existingValue;

const getTooltipContent = (invalidInput: boolean, intl: IntlShape) => {
  if (invalidInput) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          { id: I18nKey.ADS_MANAGER_INVALID_NUMERIC_ATLEAST_PERCENT },
          {
            minValue: intl.formatNumber(MIN_APP_BID, {
              minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
              maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
            }),
          }
        )}
      </p>
    );
  }
};

export const isChangedValueEqualToCurrentValue = (
  isEditMode: boolean,
  changedValue: string,
  value: string
) => isEditMode || (!isNil(changedValue) && changedValue !== value);

export const checkIfInputIsInvalid = (isInputEdited: boolean, value: string) =>
  (Number(value) < MIN_APP_BID || isNaN(Number(value)) || isEmpty(value)) &&
  isInputEdited;

export const getControlledTooltipState = (
  isInputFocussed: boolean,
  state: NumericInputState
) =>
  isInputFocussed && state === NumericInputState.Error
    ? ControlledTooltip.Show
    : ControlledTooltip.Hide;

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, ...campaignDetails }) => {
  const intl = useIntl();

  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const existingValue = getExistingValue(campaignDetails);

  let appBidMultiplier = getAppBuildMultiplier(changedValue, existingValue);

  const [value, setValue] = useState<string>(appBidMultiplier);
  const [isInputEdited, setIsInputEdited] = useState<boolean>(false);

  const resetInputState = () => {
    if (!isEditMode) {
      setIsInputEdited(false);
    }
  };

  useEffect(() => {
    if (
      isChangedValueEqualToCurrentValue(isEditMode, changedValue, value) // Changes done due to bulk update. changedValue is latest, but state variable is not
    ) {
      setValue(appBidMultiplier);
    }
    return resetInputState;
  }, [isEditMode, changedValue, currentPage]);

  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);

  const updateCellValue = (newValue: string) =>
    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier,
        rowId: campaignDetails.campaignId,
        existingValue,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        value: newValue,
      })
    );

  const debounceCellValueUpdate = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignDetails.campaignId]
  );

  const onInputBlur = () => {
    if (!isEmpty(value)) {
      debounceCellValueUpdate.cancel();
      const formattedValue = Number.parseFloat(value).toFixed(
        PERCENTAGE_FRACTION_DIGITS
      );
      setValue(formattedValue);
      updateCellValue(formattedValue);
    }
    setFocussed(false);
  };

  if (isEditMode) {
    const onInputFocus = () => setFocussed(true);

    return makeNumericInputColumn<CampaignDetails>(() => {
      let state = NumericInputState.Default;

      const isInputInvalid: boolean = checkIfInputIsInvalid(
        isInputEdited || isDirty(value, existingValue),
        value
      );

      if (isInputInvalid) {
        state = NumericInputState.Error;
      }

      return {
        value,
        state,
        dataTestId: CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier,
        appendedElement: '%',
        onChange: (inputValue: string) => {
          setValue(inputValue);
          setIsInputEdited(true);
          debounceCellValueUpdate(inputValue);
        },
        tooltipContent: getTooltipContent(isInputInvalid, intl),
        controlledTooltip: getControlledTooltipState(focussed, state),
        tooltipPlacement: Placement.Bottom,
        style: ContentStyle.Bold,
        onInputFocus,
        onInputBlur,
        acceptOnlyPositiveNumbers: true,
        isDirty: isDirty(value, existingValue),
        placeholder: MIN_APP_BID.toFixed(PERCENTAGE_FRACTION_DIGITS),
        minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      };
    })(campaignDetails);
  }

  const getPercentValue = ({
    channelSettings: { platformBidMultiplier },
  }: CampaignDetails) =>
    platformBidMultiplier && !isNil(platformBidMultiplier.appBidMultiplier)
      ? platformBidMultiplier.appBidMultiplier.toString()
      : '0';

  return makePercentColumn<CampaignDetails>(
    getPercentValue,
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const appBidMultiplierColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_APP,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const appBidMultiplierColumnFilterCampaign =
  createPercentDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.AppBidMultiplier,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_APP,
    isValidNumber()
  );
