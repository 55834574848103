import {
  CrossCircleIcon,
  Icon,
  IconSize,
  Modal,
  PlusIcon,
} from '@teikametrics/tm-design-system';
import I18nKey from '../../../../lib/types/I18nKey';
import { ActionType } from '../../../../lib/types/KeywordAction';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { KeywordLevel } from '../KeywordActionV4';

export interface ActionModalProps {
  readonly onApplyClick: () => void;
  readonly onCancelClick: () => void;
  readonly actionType: ActionType;
  readonly selectedCount: number;
  readonly keywordLevel?: KeywordLevel;
}

const getModalHeader = (
  keywordLevel: KeywordLevel | undefined,
  isAccepted: boolean
) => {
  if (isAccepted) {
    switch (keywordLevel) {
      case KeywordLevel.Keywords:
      case KeywordLevel.NegativeKeywords:
        return I18nKey.KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_ADD_KEYWORDS_HEADER;
      default:
        return I18nKey.KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_ADD_ASINS_HEADER;
    }
  }
  switch (keywordLevel) {
    case KeywordLevel.Keywords:
      return I18nKey.KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_DISMISS_KEYWORDS_HEADER;
    case KeywordLevel.NegativeKeywords:
      return I18nKey.NEGATIVE_KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_DISMISS_KEYWORDS_HEADER;
    default:
      return I18nKey.KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_DISMISS_ASINS_HEADER;
  }
};

export const ActionModal: React.FC<ActionModalProps> = ({
  onApplyClick,
  onCancelClick,
  actionType,
  selectedCount,
  keywordLevel,
}) => {
  const intl = useIntl();
  const [DISMISS_BODY, ADD_BODY] = [
    I18nKey.KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_DISMISS_KEYWORDS_BODY,
    I18nKey.KEYWORD_RECOMMENDATIONS_ACTION_DIALOG_ADD_KEYWORDS_BODY,
  ].map((id) =>
    intl.formatMessage(
      { id },
      {
        count: selectedCount,
      }
    )
  );

  const isAccepted = actionType === ActionType.Accepted;

  return (
    <Modal
      showModal
      className="min-w-400"
      icon={
        <Icon
          svg={isAccepted ? PlusIcon : CrossCircleIcon}
          size={IconSize.Large}
          className={classNames('w-388', {
            'text-green-500': isAccepted,
            'text-orange-500': !isAccepted,
          })}
        />
      }
      header={intl.formatMessage(
        { id: getModalHeader(keywordLevel, isAccepted) },
        {
          count: selectedCount,
        }
      )}
      body={isAccepted ? ADD_BODY : DISMISS_BODY}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      mainAction={{
        label: I18nKey.GENERAL_YES,
        onClick: onApplyClick,
      }}
      secondaryAction={{
        label: I18nKey.GENERAL_NO,
        onClick: onCancelClick,
      }}
      dataTestId="action_modal"
    />
  );
};
