import { AxiosResponse } from 'axios';

interface DownloadFileProps<T> {
  readonly asyncFunction: (
    asyncFunctionProps?: T
  ) => Promise<AxiosResponse<Blob>>;
  readonly preDownloadFile: () => void;
  readonly postDownloadFile: () => void;
  readonly onError: () => void;
  readonly fileName: string;
}

export const useDownloadFile = <T extends {}>({
  asyncFunction,
  preDownloadFile,
  postDownloadFile,
  onError,
  fileName,
}: DownloadFileProps<T>) => {
  const downloadFile = async (props?: T) => {
    try {
      preDownloadFile();
      const { data } = await asyncFunction(props);
      const url = URL.createObjectURL(new Blob([data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      postDownloadFile();
    } catch (error) {
      onError();
    }
  };

  return { downloadFile };
};
