import { Factory } from 'fishery';

import {
  BusinessPerformanceData,
  MerchantMetrics,
} from '../../../modules/compass/containers/compassDashboard/utils/performance';
import { HomepageResponseMetricKeys } from '../../types/CompassSharedTypes';
import { MerchantType } from '../../types/Fam';
import { CurrencyCode } from '../../utilities/currency';

export const MerchantPerformanceResponse = Factory.define<
  BusinessPerformanceData<MerchantMetrics>
>(
  (): BusinessPerformanceData<MerchantMetrics> => ({
    range: {
      startDate: '2022-04-27',
      endDate: '2022-05-03',
    },
    currency: CurrencyCode.USD,
    performanceSummary: {
      [HomepageResponseMetricKeys.TotalSales]: {
        amount: '500000',
        currency: CurrencyCode.USD,
      },
      [HomepageResponseMetricKeys.AdSales]: {
        amount: '50000',
        currency: CurrencyCode.USD,
      },
    },
    elements: [
      {
        merchantCountryDetails: {
          merchantCountryId: 'ba09e2f1-c328-49d6-8e68-de03ccbeca09',
          merchantCountryName: 'US',
          salesChannelId: '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774',
          salesChannelName: 'amazon',
          merchantType: MerchantType.Seller,
        },
        performanceMetrics: {
          [HomepageResponseMetricKeys.AdSales]: {
            amount: '5000',
            currency: 'USD',
          },
          [HomepageResponseMetricKeys.AdSpend]: {
            amount: '4000',
            currency: 'USD',
          },
          [HomepageResponseMetricKeys.TotalSales]: {
            amount: '12000',
            currency: 'USD',
          },
          [HomepageResponseMetricKeys.Tacos]: 0.3123,
        },
      },
      {
        merchantCountryDetails: {
          merchantCountryId: '92faa498-eccd-4c59-999f-9fec646a8967',
          merchantCountryName: 'US',
          salesChannelId: '820d060b-1d53-4aab-99c2-03a12e3dcf29',
          salesChannelName: 'walmart',
          merchantType: MerchantType.Seller,
        },
        performanceMetrics: undefined,
      },
    ],
  })
);
