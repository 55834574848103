import { createMultiSelectDataFieldFilter } from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/flywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { ProductAdDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { PRODUCT_ADS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<ProductAdDetails> = (props) => {
  return makeTextColumn<ProductAdDetails>(
    (adItemDetails: ProductAdDetails) =>
      adItemDetails.adItemDetails.campaignTargetingType
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignTargetingTypeColumn: FlywheelTableColumn<ProductAdDetails> =
  {
    isSortable: true,
    columnName: PRODUCT_ADS_API_COLUMN_NAME.CampaignTargetingType,
    i18nKeyOrLabel: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_TARGETING_TYPE,
    RowCellElement,
    gridColumnWidth: '140px',
  };

export const campaignTargetingTypeFilter = createMultiSelectDataFieldFilter(
  PRODUCT_ADS_API_COLUMN_NAME.CampaignTargetingType,
  I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_CAMPAIGN_TARGETING_TYPE,
  [
    SelectFilterOption.CampaignTargetingTypeAuto,
    SelectFilterOption.CampaignTargetingTypeManual,
  ]
);
