import { useContext, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import first from 'lodash/first';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeTruncatedTextLinkColumn,
  representSmartCampaign,
} from '../../../../../containers/table/utils/makeTableCells';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../../containers/userProvider/userProvider';
import {
  AdvertisingGoal,
  AiBiddingValues,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ViewTrendLink } from '../../../components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../components/ViewTrends';
import { getAdManagerUrl, getAdsLevelUri } from '../../../utils';
import {
  getAdType,
  getMerchantCountries,
  getSalesChannel,
} from '../adItemsTableColumns/utils';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE,
  CampaignTargetingType,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
  TableDataAdsManager,
} from '../types';
import {
  TARGETS_API_COLUMN_NAME,
  getSelectedMerchantCountryId,
} from '../utils';
import { getAdsManagerDetailsQueryParams } from '../utils/queryParamsUtils';
import { getSalesChannelFromLocalStorage } from '../utils/storageUtils';
import { transformAdvertisingGoal } from '../adgroupTableColumns/utils';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = ({
  targetId,
  targetDetails,
  channelSettings,
  targetPerformance,
  pendingFields,
  adTypes,
  selectedMerchantCountries,
  merchantType,
  allMerchants,
  showSmartCampaign,
  selectedAdType,
  aiEnabled,
  merchantsWithAutomationEnabled,
}) => {
  const targetsDetails: TargetsDetails = {
    targetId,
    targetDetails,
    channelSettings,
    targetPerformance,
    pendingFields,
  };

  const location = useLocation();
  const baseUrl = useMemo(
    () => getAdManagerUrl(location.pathname),
    [location.pathname]
  );

  const searchQueryParams = new URLSearchParams(
    decodeURIComponent(location.search)
  );

  const userContext = useContext(UserContext);

  const userId = userContext.userInfo.userDetails?.id || '';
  const accountId = getCurrentAccountFromContext(userContext)?.id || '';

  const selectedSalesChannel = getSalesChannelFromLocalStorage(
    userId || '',
    accountId || ''
  );

  const selectedMerchantCountryId = getSelectedMerchantCountryId(
    selectedMerchantCountries
  );

  const salesChannelId = searchQueryParams.get(SALES_CHANNEL_ID);
  const merchantCountryIds = searchQueryParams.get(MERCHANT_COUNTRY_IDS);
  const adTypesFromQueryParams = searchQueryParams.get(AD_TYPE);

  const aiBidding: AiBiddingValues =
    aiEnabled &&
    merchantsWithAutomationEnabled &&
    merchantsWithAutomationEnabled?.length > 0
      ? AiBiddingValues.Ai
      : AiBiddingValues.NotEnabled;

  const goal: AdvertisingGoal = transformAdvertisingGoal(
    aiBidding,
    targetDetails.advertisingGoal
  );

  return makeTruncatedTextLinkColumn(
    ({ targetDetails: details }: TargetsDetails) =>
      details?.campaignName ? details?.campaignName : undefined,
    ({ targetDetails: details }: TargetsDetails) =>
      details?.campaignId !== undefined && details?.campaignId !== 'undefined'
        ? getAdsLevelUri(
            baseUrl,
            ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns,
            details?.campaignId
          ) +
          getAdsManagerDetailsQueryParams(
            getSalesChannel(salesChannelId, selectedSalesChannel),
            getMerchantCountries(
              merchantCountryIds,
              userId,
              accountId,
              salesChannelId,
              selectedSalesChannel
            ),
            getAdType(adTypesFromQueryParams, adTypes)
          )
        : '',
    targetsDetails?.pendingFields &&
      targetsDetails?.pendingFields.includes(
        TARGETS_API_COLUMN_NAME.CampaignName
      ),
    <ViewTrendLink
      dataTestId="targets_campaign_name"
      entityId={targetDetails.campaignId}
      entityType={ViewTrendsEntityType.CAMPAIGN}
      adType={first(adTypes)}
      merchantCountryId={selectedMerchantCountryId}
      campaignId={targetDetails.campaignId}
      merchantType={merchantType}
      campaignName={targetDetails.campaignName}
      entityName={targetDetails.campaignName}
      merchantCountryName={
        allMerchants.find(
          ({ merchantCountryId }) =>
            selectedMerchantCountryId === merchantCountryId
        )?.merchantName
      }
      targetingType={targetDetails.campaignTargetingType}
    />,
    false,
    representSmartCampaign(
      goal,
      (targetDetails.campaignTargetingType as CampaignTargetingType) ||
        CampaignTargetingType.Manual,
      selectedAdType,
      showSmartCampaign
    )
  )(targetsDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const campaignNameColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.CampaignName,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_NAME,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const campaignNameFilter = createStringDataFieldFilter(
  TARGETS_API_COLUMN_NAME.CampaignName,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_CAMPAIGN_NAME,
  isValidString()
);
