import {
  Button,
  ButtonSize,
  ButtonVariant,
  ColumnAlign,
  DataFieldFilter,
  EmDash,
  EyeIcon,
  SelectOptionProps,
  Tooltip,
  createMultiSelectDataFieldFilter,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';
import capitalize from 'lodash/capitalize';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavigateFunction } from 'react-router-dom';
import { NavPaths } from '../../../../../NavPaths';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import { AdType, MatchType } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  KeywordActionColumns,
  KeywordRecommendationsData,
  Sources,
} from '../../../../../lib/types/KeywordAction';
import { MAP_SALES_CHANNEL_ID_TO_NAME } from '../../../../../lib/types/SalesChannels';
import { buildUrlWithQueryParams } from '../../../../../lib/utilities/buildUrlUtilities';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
} from '../../../../advertisingOptimization/containers/adsManager/types';
import { SelectFilterOption } from '../../../../advertisingOptimization/containers/adsManager/utils/selectFilterOptions';
import { SourcesSlideover } from '../../SourcesSlideover';
import {
  RecommendationsTableV2ExtraProps,
  SOURCE_AD_GROUP_TAG_FILTER_FIELD,
  SOURCE_TARGET_TEXT_FILTER_FIELD,
  SelectSourceV2Option,
} from '../types';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from '../../../../../lib/utilities/intercom';

export const Cell: React.FC<
  KeywordRecommendationsData & RecommendationsTableV2ExtraProps
> = ({
  merchantCountry,
  keyword: { sourcesV2, tag, text },
  suggestedDestination: {
    adGroup: { targetSegments },
  },
  isPat,
}) => {
  const intl = useIntl();
  const salesChannel = merchantCountry.salesChannelId!;

  const [showSources, setShowSources] = useState<boolean>(false);

  const onShowClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowSources(true);
  };

  const onClose = () => setShowSources(false);

  useEffect(() => {
    if (showSources) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
    return () => showIntercomLauncher();
  }, [showSources]);

  if (!sourcesV2) {
    return <EmDash />;
  }

  return (
    <>
      <div className="flex h-full items-center justify-end gap-4">
        <Tooltip
          content={intl.formatMessage(
            { id: I18nKey.KWA_SOURCES_VIEW_TOOLTIP },
            { count: sourcesV2.length }
          )}
          delayShow={750}
        >
          <Button
            size={ButtonSize.InlineIcon}
            variant={ButtonVariant.BlackAndWhite}
            svgIcon={EyeIcon}
            className="group"
            iconClassName="text-grey-500 group-hover:text-grey-900"
            onClick={onShowClick}
          />
        </Tooltip>
      </div>
      {showSources && (
        <SourcesSlideover
          isPat={isPat}
          salesChannel={salesChannel}
          keyword={{ tag, text }}
          targetSegments={targetSegments}
          sources={sourcesV2}
          merchantCountry={merchantCountry}
          onClose={onClose}
        />
      )}
    </>
  );
};
Cell.displayName = 'KWACellSourcesV2';

export const sources: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  RecommendationsTableV2ExtraProps,
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SOURCE_DETAILS_HEADER,
  accessorKey: KeywordActionColumns.SOURCE,
  sortable: false,
  size: 80,
  minSize: 80,
  align: ColumnAlign.Right,
  cell: ({
    row: { original },
    table: {
      options: { meta },
    },
  }) => <Cell {...original} {...meta} />,
};

export const navigateToAdsManagerDetailsPage = (
  source: Sources,
  salesChannel: string,
  mcId: string,
  navigate: NavigateFunction
) => {
  navigate(getCampaignUrl(source, salesChannel, mcId));
};

export const getCampaignUrl = (
  source: Sources,
  salesChannel: string,
  mcId: string
) => {
  return buildUrlWithQueryParams(
    `${NavPaths.AOAdsManager}/${MAP_SALES_CHANNEL_ID_TO_NAME[salesChannel]}/${
      AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER[
        source.campaign?.campaignType as AdType
      ]
    }/${ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns}/${encodeURIComponent(
      source.campaign?.id!
    )}/${encodeURIComponent(source.adGroup?.id!)}`,
    {
      [SALES_CHANNEL_ID]: salesChannel,
      [MERCHANT_COUNTRY_IDS]: mcId,
    }
  );
};

export const getMatchTypeOptions = (): SelectOptionProps<string>[] => [
  { label: capitalize(MatchType.Broad), value: MatchType.Broad },
  { label: capitalize(MatchType.Phrase), value: MatchType.Phrase },
  { label: capitalize(MatchType.Exact), value: MatchType.Exact },
];

export const sourceAdGroupNameFilter = createStringDataFieldFilter(
  'sourceAdGroupName',
  I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_FILTERS_SOURCES_AD_GROUP_NAME,
  isValidString()
);
export const sourceKeywordTextFilter = createStringDataFieldFilter(
  'sourceKeywordText',
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_SOURCES_KEYWORD_TEXT_FILTER,
  isValidString()
);
export const sourceCampaignNameFilter = createStringDataFieldFilter(
  'sourceCampaignName',
  I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_FILTERS_SOURCES_CAMPAIGN_NAME,
  isValidString()
);
export const sourceCampaignTargetingTypeFilter =
  createMultiSelectDataFieldFilter(
    'sourceCampaignTargetingType',
    I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_FILTERS_SOURCES_CAMPAIGN_TARGETING_TYPE,
    [
      SelectFilterOption.CampaignTargetingTypeAuto,
      SelectFilterOption.CampaignTargetingTypeManual,
    ]
  );
export const sourceReportNameFilter = createMultiSelectDataFieldFilter(
  'sourceReportName',
  I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_FILTERS_SOURCES_REPORT_NAME,
  [
    SelectSourceV2Option.AO,
    SelectSourceV2Option.AMZ_SP_SEARCH_TERM_REPORT,
    SelectSourceV2Option.AMZ_SP_SUGGESTED_KEYWORD,
    SelectSourceV2Option.WMT_SP_ITEM_KEYWORD_REPORT,
    SelectSourceV2Option.WMT_SP_KEYWORD_ANALYTIC,
    SelectSourceV2Option.WMT_SP_KEYWORD_PERFORMANCE_REPORT,
  ]
);
export const sourceTargetMatchTypeFilter = createMultiSelectDataFieldFilter(
  'sourceTargetMatchType',
  I18nKey.AI_RECOMMENDED_KEYWORD_TABLE_FILTERS_SOURCES_MATCH_TYPE,
  [
    SelectFilterOption.MatchTypeExact,
    SelectFilterOption.MatchTypePhrase,
    SelectFilterOption.MatchTypeBroad,
  ]
);
export const sourceTargetTextFilter = createStringDataFieldFilter(
  SOURCE_TARGET_TEXT_FILTER_FIELD,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_TARGET_TEXT_HEADER,
  isValidString()
);
export const sourceAdGroupTagFilter = createMultiSelectDataFieldFilter(
  SOURCE_AD_GROUP_TAG_FILTER_FIELD,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_AD_GROUP_TAG_HEADER,
  [
    SelectFilterOption.DestinationAdGroupTagBrand,
    SelectFilterOption.DestinationAdGroupTagCompetitor,
    SelectFilterOption.DestinationAdGroupTagGeneric,
    SelectFilterOption.DestinationAdGroupTagNoTag,
  ]
);

export const sourceFiltersKWR: DataFieldFilter[] = [
  sourceTargetTextFilter,
  sourceAdGroupNameFilter,
  sourceCampaignNameFilter,
  sourceCampaignTargetingTypeFilter,
  sourceAdGroupTagFilter,
  sourceReportNameFilter,
  sourceTargetMatchTypeFilter,
];

export const sourceFiltersNKWR: DataFieldFilter[] = [
  sourceTargetTextFilter,
  sourceAdGroupNameFilter,
  sourceCampaignNameFilter,
  sourceCampaignTargetingTypeFilter,
  sourceAdGroupTagFilter,
  sourceTargetMatchTypeFilter,
];
