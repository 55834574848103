// Calculate the CSS grid-template-rows property for a given grid table
// https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows
// for the grid table we want the header row and all the data rows to have
// height = auto, then a single spacer column with 1fr in order for the table
// to fill it's container, followed by a footer value of auto if there is a
// footer for the table. Additionally, if there is no data provided to the table
// and a component to display in place of data is provided, it's counted as one row.
const calculateGridTemplateRows = (
  params: Readonly<{
    numberOfRowsInTable: number;
    tableHasHeader: boolean;
    tableHasFooter: boolean;
    tableHasNoDataComponent: boolean;
    tableHasGroupedColumns: boolean;
  }>
) => {
  const {
    numberOfRowsInTable,
    tableHasHeader,
    tableHasFooter,
    tableHasNoDataComponent,
    tableHasGroupedColumns,
  } = params;
  const numberOfRowsForColumnGrouping = tableHasGroupedColumns ? 1 : 0;
  const numberOfRowsForHeader = tableHasHeader ? 1 : 0;
  // If no data is provided and we have a component to display, it's treated as a row
  const numberOfRowsForNoData =
    tableHasNoDataComponent && numberOfRowsInTable === 0 ? 1 : 0;
  const rowsBeforeSpacer =
    numberOfRowsForColumnGrouping +
    numberOfRowsForHeader +
    numberOfRowsInTable +
    numberOfRowsForNoData;
  const tableRowHeights = `repeat(${rowsBeforeSpacer}, auto)`;
  const spacerHeight = '1fr';
  const footerHeight = tableHasFooter ? 'auto' : '';

  return `${tableRowHeights} ${spacerHeight} ${footerHeight}`.trim();
};

export default calculateGridTemplateRows;
