import { fromNullable } from 'fp-ts/lib/Option';
import union from 'lodash/union';
import { DateTime } from 'luxon';
import { convertToUTCDate } from './datePickerUtilities';
import { isNotUndefined } from './typeGuards';

export enum PERSISTED_KEYS {
  CURRENT_ACCOUNT_ID = 'CURRENT_ACCOUNT_ID',
  SHOW_BILLING_MODALS = 'SHOW_BILLING_MODALS',
  hubspotutk = 'hubspotutk',
}

export interface FWCookieInterface {
  saveCookie: (
    key: PERSISTED_KEY,
    value: string,
    dateCookieExpires?: DateTime
  ) => void;
  readCookie: (cookieKey: PERSISTED_KEY) => string | undefined;
  saveBooleanToCookie: (key: PERSISTED_KEY, val: boolean) => void;
  readBooleanFromCookie: (key: PERSISTED_KEY, defaultVal?: boolean) => boolean;
  deleteCookie: (key: PERSISTED_KEY) => void;
}

// Union type representing keys the app stores in LocalStorage
export type PERSISTED_KEY = keyof typeof PERSISTED_KEYS;

const saveCookie = (
  key: PERSISTED_KEY,
  value: string,
  dateCookieExpires?: DateTime
): void => {
  const maybeExpiry = fromNullable(dateCookieExpires)
    .map((expiryDate: DateTime) => `expires=${convertToUTCDate(expiryDate)}`)
    .getOrElse('');
  document.cookie = `${key}=${value}; ${maybeExpiry}; path=/`;
};

// https://gist.github.com/joduplessis/7b3b4340353760e945f972a69e855d11
const readCookie = (cookieKey: PERSISTED_KEY): string | undefined => {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + cookieKey + '=');

  if (parts.length === 2) {
    const popped = parts.pop();
    return popped !== undefined ? popped.split(';').shift() : undefined;
  }
};

const saveBooleanToCookie = (key: PERSISTED_KEY, val: boolean) =>
  saveCookie(key, val.toString());

const readBooleanFromCookie = (
  key: PERSISTED_KEY,
  defaultVal?: boolean
): boolean => readCookie(key) === 'true' || !!defaultVal;

const deleteCookie = (key: PERSISTED_KEY) => {
  const date = new Date();

  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

  // Set it
  document.cookie = key + '=; expires=' + date.toUTCString() + '; path=/';
};

export const addProfileIdToCookie = (
  key: PERSISTED_KEY,
  profileId: number
): void => {
  const existingProfileIdsForCookie = readCookie(key)?.split('|') ?? [];
  const newProfileIdsForCookie = union(existingProfileIdsForCookie, [
    String(profileId),
  ]);
  saveCookie(key, newProfileIdsForCookie.join('|'));
};

export const removeProfileIdFromCookie = (
  key: PERSISTED_KEY,
  profileId: number
): void => {
  const existingProfiles = readCookie(key)?.split('|') ?? [];
  const remainingProfiles = existingProfiles.filter(
    (id) => id !== String(profileId)
  );

  if (remainingProfiles.length > 0) {
    saveCookie(key, remainingProfiles.join('|'));
  } else {
    deleteCookie(key);
  }
};

export const isProfileIdPresentInCookie = (
  key: PERSISTED_KEY,
  profileId: number
) => {
  const cookieValue = readCookie(key);
  return (
    isNotUndefined(cookieValue) && cookieValue.indexOf(String(profileId)) > -1
  );
};

export const addAccountIdToCookie = (
  key: PERSISTED_KEY,
  accountId: string
): void => {
  const existingAccountIdsForCookie = readCookie(key)?.split('|') ?? [];
  const newAccountIdsForCookie = union(existingAccountIdsForCookie, [
    accountId,
  ]);
  saveCookie(key, newAccountIdsForCookie.join('|'));
};

export const isAccountIdPresentInCookie = (
  key: PERSISTED_KEY,
  accountId: string
) => {
  const cookieValue = readCookie(key);
  return isNotUndefined(cookieValue) && cookieValue.indexOf(accountId) > -1;
};

export const FWCookie: FWCookieInterface = Object.freeze({
  saveCookie,
  readCookie,
  saveBooleanToCookie,
  readBooleanFromCookie,
  deleteCookie,
});
