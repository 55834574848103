import {
  AlertCircleIcon,
  Icon,
  IconSize,
} from '@teikametrics/tm-design-system';
import classNames from 'classnames';
import React from 'react';
import { IntlShape } from 'react-intl';
import I18nKey from '../../lib/types/I18nKey';
import { KeywordErrorType } from '../../core/campaignFlexibility/types';

export interface InvalidTagProps {
  errorType: KeywordErrorType;
  searchText: string;
}

export const KEYWORD_ERROR_TYPE_I18N_MAPPER = {
  [KeywordErrorType.Invalid]:
    I18nKey.CREATE_KEYWORDS_ERROR_TYPE_INVALID_CHARACTERS,
  [KeywordErrorType.Duplicate]: I18nKey.CREATE_KEYWORDS_ERROR_TYPE_DUPLICATE,
  [KeywordErrorType.MaxCharacters]:
    I18nKey.CREATE_KEYWORDS_ERROR_TYPE_MAX_CHARACTERS,
  [KeywordErrorType.MaxParts]: I18nKey.CREATE_KEYWORDS_ERROR_TYPE_MAX_PARTS,
  [KeywordErrorType.MaxPartsNegative]:
    I18nKey.CREATE_KEYWORDS_ERROR_TYPE_MAX_PARTS_NEGATIVE,
  [KeywordErrorType.InvalidAsin]:
    I18nKey.CREATE_KEYWORDS_ERROR_TYPE_INVALID_ASIN,
};

export interface InvalidTagListProps {
  readonly invalidTagProps?: InvalidTagProps[];
  readonly intl: IntlShape;
}

export const InvalidTagList: React.FC<InvalidTagListProps> = ({
  invalidTagProps = [],
  intl,
}) => {
  if (!invalidTagProps.length) {
    return <></>;
  }

  return (
    <div className="flex flex-col">
      {invalidTagProps?.map((tag) => (
        <div
          key={`${tag.searchText}_${tag.errorType}`}
          className={classNames('flex flex-row break-all items-center mb-8')}
        >
          <div className="group cursor-pointer relative w-full">
            <Icon
              className="text-red mr-8"
              size={IconSize.Medium}
              svg={AlertCircleIcon}
            />

            <div className="absolute inline-block w-full">
              <span
                className={classNames(
                  'rounded-4 font-normal',
                  'after:content-[" "] after:absolute after:top-1/2 after:left-0 after:-mt-4 after:-ml-8',
                  'after:border-4 after:border-solid after:border-transparent after:border-r-black',
                  'z-100 fit-content -mt-6 hidden absolute group-hover:inline-block',
                  'h-auto break-words text-sm max-w-250 p-8 bg-grey-900 text-white'
                )}
                contentEditable={false}
              >
                {intl.formatMessage({
                  id: KEYWORD_ERROR_TYPE_I18N_MAPPER[tag.errorType],
                })}
              </span>
            </div>
            {tag.searchText}
          </div>
        </div>
      ))}
    </div>
  );
};
