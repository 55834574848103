import { Factory } from 'fishery';
import {
  AdGroupStatus,
  AdStatus,
  CampaignStatus,
  CreativeServingStatus,
  CreativeType,
  SbAdsDetails,
} from '../../types/AOSharedTypes';

export interface Response {
  readonly elements: SbAdsDetails[];
  readonly filteredElements: number;
}

export default Factory.define<Response>(() => ({
  elements: [
    {
      creativeId: '6dc2ca09-96e4-52b7-adeb-cb4935373046',
      creativeDetails: {
        creativeName: 'Creative 1',
        creativeType: CreativeType.Video,
        creativeServingStatus: CreativeServingStatus.AdStatusLive,
        campaignName: 'Campaign 1',
        campaignStatus: CampaignStatus.Enabled,
        campaignDailyBudget: {
          amount: '100.00',
          currency: 'USD',
        },
        adGroupName: 'Ad Group 1',
        adGroupStatus: AdGroupStatus.Enabled,
        portfolio: 'Evergreen',
        merchantCountryId: 'bea0a83e-4290-46f3-a3ac-5b0f002fe5f3',
      },
      channelSettings: {
        status: AdStatus.Enabled,
      },
      creativePerformance: {
        adSalesTotal: {
          amount: '53',
          currency: 'USD',
        },
        adSpend: {
          amount: '64',
          currency: 'USD',
        },
        roasTotal: 8.3,
        acosTotal: 0.12,
        conversionRate: 0.46,
        clicks: 68,
        impressions: 21,
        clickThroughRate: 0.03,
        avgCostPerClick: {
          amount: '0.94',
          currency: 'USD',
        },
        adConversions: 3165,
        newToBrandOrders: 1252,
        newToBrandOrdersPercentage: 36,
        newToBrandOrderRate: 1669,
        newToBrandSales: {
          amount: '208',
          currency: 'USD',
        },
        newToBrandSalesPercentage: 388,
        newToBrandAdUnitsSold: 12,
        newToBrandAdUnitsSoldPercentage: 399,
        unitsSold: 14,
      },
    },
    {
      creativeId: '1a118cef-7649-5710-8a37-01158e713eb3',
      creativeDetails: {
        creativeName: 'Creative 2',
        creativeType: CreativeType.Video,
        creativeServingStatus: CreativeServingStatus.AdStatusLive,
        merchantCountryId: 'bea0a83e-4290-46f3-a3ac-5b0f002fe5f3',
      },
      channelSettings: {
        status: AdStatus.Enabled,
      },
      creativePerformance: {},
    },
  ],
  filteredElements: 2,
}));
