import { useIntl } from 'react-intl';

import {
  PortaledTooltipPlacement,
  createMultiSelectDataFieldFilter,
  AlertTriangleIcon as warningSVG,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeTextColumn,
  makeTextWithIconColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import { TargetsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TooltipText } from '../reviewStatusTooltipText';
import { ADGROUP_REVIEW_STATUS_MAPPER, TableDataAdsManager } from '../types';
import { TARGETS_API_COLUMN_NAME } from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) => {
  const intl = useIntl();

  const getColumnText = ({ targetDetails }: TargetsDetails) =>
    targetDetails?.adGroupReviewStatus &&
    ADGROUP_REVIEW_STATUS_MAPPER[
      targetDetails?.adGroupReviewStatus?.reviewStatus
    ]
      ? intl.formatMessage({
          id: ADGROUP_REVIEW_STATUS_MAPPER[
            targetDetails?.adGroupReviewStatus?.reviewStatus
          ],
        })
      : undefined;

  if (
    props.targetDetails?.adGroupReviewStatus?.reviewStatus &&
    props.targetDetails?.adGroupReviewStatus?.reviewReason?.length
  ) {
    return makeTextWithIconColumn(
      getColumnText,
      warningSVG,
      <TooltipText
        comments={props.targetDetails?.adGroupReviewStatus?.reviewReason}
      />,
      true,
      PortaledTooltipPlacement.Right
    )(props);
  }

  return makeTextColumn(getColumnText, false, 'normal-case')(props);
};
RowCellElement.displayName = 'RowCellElement';

export const adGroupReviewStatusColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.AdGroupReviewStatus,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_ADGROUP_REVIEW_STATUS,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '200px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const adGroupReviewStatusFilter = createMultiSelectDataFieldFilter(
  TARGETS_API_COLUMN_NAME.AdGroupReviewStatus,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_ADGROUP_REVIEW_STATUS,
  [
    SelectFilterOption.AdGroupReviewStatusPending,
    SelectFilterOption.AdGroupReviewStatusInProgress,
    SelectFilterOption.AdGroupReviewStatusCompleted,
    SelectFilterOption.AdGroupReviewStatusCancelled,
  ]
);
