import {
  AlertCircleIcon,
  AmazonLogomarkEnabledIcon,
  BellIcon,
  BullhornLinearIcon,
  CashLinearIcon,
  CogLinearIcon,
  DataConnectionImage,
  GraphTrendUpIcon,
  GraphUpwardTrendIcon,
  HiiveLinearIcon,
  LightningIcon,
  LightningSlashIcon,
  LockClosedIcon,
  MiImage,
  PlugDisconnectedIcon,
  ReportIcon,
  StartAdvertising,
  SwordsCrossedIcon,
  TargetIcon,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';
import { GenerateReportState } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../lib/types/SalesChannels';
import {
  CardDetails,
  MI_CARD_ADD_TERMS_SHOW_ME_HOW_LINK,
  RecommendationCardDetails,
  RecommendationHubCardTypes,
} from '../../types';
import { ReactComponent as WalmartConnection } from '../../img/walmartConnection.svg';
import { ReactComponent as BudgetLinearIcon } from '../../img/budgetLinearIcon.svg';
import { ReactComponent as SalesChannelIcon } from '../../img/salesChannelIcon.svg';
import { ReactComponent as EyeLinearIcon } from '../../img/eyeLinearIcon.svg';

export const MAP_SALES_CHANNEL_ID_TO_I18_IDS: Record<string, I18nKey> = {
  [AMAZON_SALES_CHANNEL_ID]: I18nKey.GENERIC_AMAZON,
  [WALMART_SALES_CHANNEL_ID]: I18nKey.GENERIC_WALMART,
};

export const getSalesChannelIconFromId = (salesChannelId?: string) => {
  switch (salesChannelId) {
    case WALMART_SALES_CHANNEL_ID:
      return WalmartLogomarkEnabledIcon;
    case AMAZON_SALES_CHANNEL_ID:
    default:
      return AmazonLogomarkEnabledIcon;
  }
};

export const getMapCardTypeToCardProps = (
  hasMISuggestions: boolean,
  state?: GenerateReportState,
  recommendationV2Disabled?: boolean
) => {
  const MAP_CARD_TYPE_TO_CARD_PROPS: Record<
    RecommendationHubCardTypes,
    RecommendationCardDetails
  > = {
    [RecommendationHubCardTypes.AUTOMATED_BIDDING]: {
      displayIcon: StartAdvertising,
      pillIcon: BullhornLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_PILL_TEXT,
      mainText: recommendationV2Disabled
        ? I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_MAIN_TEXT
        : I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_MAIN_TEXT_V2,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_PRIMARY_BUTTON_TEXT,
    },
    [RecommendationHubCardTypes.UNADVERTISED_PRODUCTS]: {
      displayIcon: StartAdvertising,
      pillIcon: BullhornLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_PILL_TEXT,
      mainText: I18nKey.RECOMMENDATION_HUB_CARD_UNADVERTISED_PRODUCT_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_UNADVERTISED_PRODUCT_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_UNADVERTISED_PRODUCT_PRIMARY_BUTTON_TEXT,
      secondaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_PRIMARY_BUTTON_TEXT,
    },
    [RecommendationHubCardTypes.CONNECT_PRODUCTS_DATA]: {
      displayIcon: DataConnectionImage,
      pillIcon: CogLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PRIMARY_BUTTON_TEXT,
    },
    [RecommendationHubCardTypes.MARKET_INTELLIGENCE]: {
      displayIcon: MiImage,
      pillIcon: BullhornLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_PILL_TEXT,
      mainText: hasMISuggestions
        ? I18nKey.RECOMMENDATION_HUB_CARD_MARKET_INTELLIGENCE_SUGGESTIONS_MAIN_TEXT
        : I18nKey.RECOMMENDATION_HUB_CARD_MARKET_INTELLIGENCE_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_MARKET_INTELLIGENCE_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_MARKET_INTELLIGENCE_TRACK_TERMS_TEXT,
      newTabOnSecondryButtonClick: true,
      secondryButtonHref: MI_CARD_ADD_TERMS_SHOW_ME_HOW_LINK,
    },
    [RecommendationHubCardTypes.ADD_COGS]: {
      displayIcon: ReportIcon,
      pillIcon: GraphTrendUpIcon,
      primarySalesChannelIcon: AmazonLogomarkEnabledIcon,
      secondarySalesChannelIcon: WalmartLogomarkEnabledIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_PROFITABILITY_PILL_TEXT,
      mainText: recommendationV2Disabled
        ? I18nKey.RECOMMENDATION_HUB_CARD_ADD_COGS_MAIN_TEXT
        : I18nKey.RECOMMENDATION_HUB_CARD_ADD_COGS_MAIN_TEXT_V2,
      subText: recommendationV2Disabled
        ? I18nKey.RECOMMENDATION_HUB_CARD_ADD_COGS_SUB_TEXT
        : I18nKey.RECOMMENDATION_HUB_CARD_ADD_COGS_SUB_TEXT_V2,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_ADD_COGS_PRIMARY_BUTTON_TEXT,
    },
    [RecommendationHubCardTypes.OPPORTUNITY_ANALYZER_REPORT]: {
      displayIcon: ReportIcon,
      pillIcon: BullhornLinearIcon,
      pillText:
        I18nKey.RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_SUB_TEXT,
      secondaryButtonText:
        state === GenerateReportState.ReadyToDownload
          ? I18nKey.RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_DOWNLOAD_REPORT_TEXT
          : I18nKey.RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_GENERATE_REPORT_TEXT,
      secondaryButtonLoading: state === GenerateReportState.Downloading,
    },
    [RecommendationHubCardTypes.PRODUCTS_CATALOG_CONNECT]: {
      displayIcon: WalmartConnection,
      pillIcon: SalesChannelIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_API_CONNECT]: {
      pillIcon: PlugDisconnectedIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_COGS]: {
      pillIcon: CashLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.RECOMMENDATION_HUB_CARD_COGS_DATA_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_COGS_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_COGS_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_COGS_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_UNADVERTISED_PRODUCTS]: {
      pillIcon: EyeLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_NOT_IN_SMART_CAMPAIGNS]: {
      pillIcon: LightningSlashIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_TURN_ON_AIBIDDING]: {
      pillIcon: LightningIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_PRIMARY_BUTTON_TEXT,
      secondaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_SECONDARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_KEYWORD_AND_TARGET]: {
      pillIcon: TargetIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_BUDGET_MANAGEMENT]: {
      pillIcon: BudgetLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.OUT_OF_STOCK]: {
      pillIcon: LightningIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_OUT_OF_STOCK_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_OUT_OF_STOCK_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_OUT_OF_STOCK_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_OUT_OF_STOCK_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.UNSOLD_INVENTORY]: {
      pillIcon: TargetIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNSOLD_INVENTORY_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNSOLD_INVENTORY_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNSOLD_INVENTORY_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNSOLD_INVENTORY_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.LIQUIDATE_ON_HIIVE]: {
      pillIcon: TargetIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_LIQUIDATE_ON_HIIVE_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_LIQUIDATE_ON_HIIVE_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_LIQUIDATE_ON_HIIVE_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_LIQUIDATE_ON_HIIVE_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.DEMAND_FORECAST]: {
      pillIcon: LightningIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_DEMAND_FORECASTING,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.RATE_OF_SALE_INSIGHTS]: {
      pillIcon: GraphTrendUpIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_DEMAND_FORECASTING,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.OUT_OF_STOCK_DETECTION]: {
      pillIcon: AlertCircleIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_DEMAND_FORECASTING,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.EXCESS_STOCK_RECOGNITION]: {
      pillIcon: TargetIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_DEMAND_FORECASTING,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.ANALYZE_AGING_INVENTORY]: {
      pillIcon: GraphUpwardTrendIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_DEMAND_FORECASTING,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.CUSTOM_STOCKOUT_AND_REORDER_ALERT]: {
      pillIcon: BellIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.NAV_BAR_LABEL_INVENTORY_OPTIMIZATION_DEMAND_FORECASTING,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.HIIVE_CLEARANCE]: {
      pillIcon: HiiveLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_TITLE,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_DESCRIPTION,
    },
    [RecommendationHubCardTypes.ADVERTISED_PRODUCTS_WITH_LESS_THAN_30_DAYS_INV]:
      {
        pillIcon: LightningIcon,
        pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
        mainText:
          I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISED_PRODUCTS_LESS_THAN_30_INVENTORY_MAIN_TEXT,
        subText:
          I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISED_PRODUCTS_LESS_THAN_30_INVENTORY_SUB_TEXT,
        primaryButtonText:
          I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISED_PRODUCTS_LESS_THAN_30_INVENTORY_PRIMARY_BUTTON_TEXT,
        subTextCompleted:
          I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_ADVERTISED_PRODUCTS_LESS_THAN_30_INVENTORY_SUB_TEXT_COMPLETED,
      },
    [RecommendationHubCardTypes.UN_ADVERTISED_PRODUCTS_WITH_LOW_SELL_THROUGH_RATE]:
      {
        pillIcon: LightningIcon,
        pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
        mainText:
          I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_LOW_SELL_THROUGH_RATE_MAIN_TEXT,
        subText:
          I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_LOW_SELL_THROUGH_RATE_SUB_TEXT,
        primaryButtonText:
          I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_LOW_SELL_THROUGH_RATE_PRIMARY_BUTTON_TEXT,
        subTextCompleted:
          I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_LOW_SELL_THROUGH_RATE_SUB_TEXT_COMPLETED,
      },
    [RecommendationHubCardTypes.BRANDS_AND_COMPETITORS]: {
      pillIcon: SwordsCrossedIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.NAV_BAR_LABEL_BRANDS_AND_COMPETITORS,
      subText: I18nKey.BRANDS_AND_COMPETITORS_RECOMMENDATIONS_HUB_CARD_SUB_TEXT,
      subTextCompleted:
        I18nKey.BRANDS_AND_COMPETITORS_RECOMMENDATIONS_HUB_CARD_SUB_TEXT_COMPLETED,
      primaryButtonText: I18nKey.BRANDS_AND_COMPETITORS_CATALOG_ASSIGN_BACN,
    },
  };
  return MAP_CARD_TYPE_TO_CARD_PROPS;
};

export const getMapCardTypeToCardPropsWithAI = (
  hasMISuggestions: boolean,
  recommendationV2Disabled?: boolean
) => {
  const MAP_CARD_TYPE_TO_CARD_PROPS_WITH_AI: Record<
    RecommendationHubCardTypes,
    RecommendationCardDetails
  > = {
    [RecommendationHubCardTypes.AUTOMATED_BIDDING]: {
      displayIcon: StartAdvertising,
      pillIcon: BullhornLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_PILL_TEXT,
      mainText: recommendationV2Disabled
        ? I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_MAIN_TEXT
        : I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_MAIN_TEXT_V2,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_SUB_TEXT,
      secondaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_GENERAL_FREE_TRIAL_TEXT,
      footerIcon: LockClosedIcon,
      footerText: I18nKey.RECOMMENDATION_HUB_CARD_GENERAL_FOOTER_TEXT,
    },
    [RecommendationHubCardTypes.UNADVERTISED_PRODUCTS]: {
      displayIcon: StartAdvertising,
      pillIcon: BullhornLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_AUTOMATED_BIDDING_PILL_TEXT,
      mainText: I18nKey.RECOMMENDATION_HUB_CARD_UNADVERTISED_PRODUCT_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_UNADVERTISED_PRODUCT_SUB_TEXT,
      secondaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_GENERAL_FREE_TRIAL_TEXT,
      footerIcon: LockClosedIcon,
      footerText: I18nKey.RECOMMENDATION_HUB_CARD_GENERAL_FOOTER_TEXT,
    },
    [RecommendationHubCardTypes.CONNECT_PRODUCTS_DATA]: {
      displayIcon: DataConnectionImage,
      pillIcon: CogLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PRIMARY_BUTTON_TEXT,
    },
    [RecommendationHubCardTypes.MARKET_INTELLIGENCE]:
      getMapCardTypeToCardProps(hasMISuggestions)[
        RecommendationHubCardTypes.MARKET_INTELLIGENCE
      ],
    [RecommendationHubCardTypes.ADD_COGS]:
      getMapCardTypeToCardProps(hasMISuggestions)[
        RecommendationHubCardTypes.ADD_COGS
      ],
    [RecommendationHubCardTypes.OPPORTUNITY_ANALYZER_REPORT]: {
      displayIcon: ReportIcon,
      pillIcon: BullhornLinearIcon,
      pillText:
        I18nKey.RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_SUB_TEXT,
      secondaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_OPPORTUNITY_ANALYZER_REPORT_UPGRADE_PLAN_TEXT,
      footerIcon: LockClosedIcon,
      footerText: I18nKey.RECOMMENDATION_HUB_CARD_GENERAL_FOOTER_TEXT,
    },
    [RecommendationHubCardTypes.PRODUCTS_CATALOG_CONNECT]: {
      displayIcon: WalmartConnection,
      pillIcon: SalesChannelIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_PRODUCTS_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_API_CONNECT]: {
      pillIcon: PlugDisconnectedIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_API_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_COGS]: {
      pillIcon: CashLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.RECOMMENDATION_HUB_CARD_COGS_DATA_MAIN_TEXT,
      subText: I18nKey.RECOMMENDATION_HUB_CARD_COGS_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_COGS_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_COGS_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_UNADVERTISED_PRODUCTS]: {
      pillIcon: EyeLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_UNADVERTISED_PRODUCTS_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_NOT_IN_SMART_CAMPAIGNS]: {
      pillIcon: LightningSlashIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_NOT_IN_SMART_CAMPAIGNS_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_TURN_ON_AIBIDDING]: {
      pillIcon: LightningIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_PRIMARY_BUTTON_TEXT,
      secondaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_SECONDARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_TURN_ON_AIBIDDING_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_KEYWORD_AND_TARGET]: {
      pillIcon: TargetIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_KEYWORD_AND_TARGET_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.PRODUCTS_BUDGET_MANAGEMENT]: {
      pillIcon: BudgetLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_MAIN_TEXT,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      primaryButtonText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_PRIMARY_BUTTON_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT_COMPLETED,
    },
    [RecommendationHubCardTypes.ADVERTISED_PRODUCTS_WITH_LESS_THAN_30_DAYS_INV]:
      getMapCardTypeToCardProps(hasMISuggestions)[
        RecommendationHubCardTypes
          .ADVERTISED_PRODUCTS_WITH_LESS_THAN_30_DAYS_INV
      ],
    [RecommendationHubCardTypes.UN_ADVERTISED_PRODUCTS_WITH_LOW_SELL_THROUGH_RATE]:
      getMapCardTypeToCardProps(hasMISuggestions)[
        RecommendationHubCardTypes
          .UN_ADVERTISED_PRODUCTS_WITH_LOW_SELL_THROUGH_RATE
      ],
    [RecommendationHubCardTypes.OUT_OF_STOCK]:
      getMapCardTypeToCardProps(hasMISuggestions)[
        RecommendationHubCardTypes.OUT_OF_STOCK
      ],
    [RecommendationHubCardTypes.UNSOLD_INVENTORY]:
      getMapCardTypeToCardProps(hasMISuggestions)[
        RecommendationHubCardTypes.UNSOLD_INVENTORY
      ],
    [RecommendationHubCardTypes.LIQUIDATE_ON_HIIVE]:
      getMapCardTypeToCardProps(hasMISuggestions)[
        RecommendationHubCardTypes.LIQUIDATE_ON_HIIVE
      ],
    [RecommendationHubCardTypes.DEMAND_FORECAST]: {
      pillIcon: LightningIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_DEMAND_FORECAST_TITLE,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_DEMAND_FORECAST_DESCRIPTION,
      showNewDesign: true,
      cardContainerClasses: 'h-210 w-284',
      cardBgColor: 'bg-white',
    },
    [RecommendationHubCardTypes.RATE_OF_SALE_INSIGHTS]: {
      pillIcon: GraphTrendUpIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_RATE_OF_SALE_TITLE,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_RATE_OF_SALE_DESCRIPTION,
      showNewDesign: true,
      cardContainerClasses: 'h-210 w-284',
      cardBgColor: 'bg-white',
    },
    [RecommendationHubCardTypes.OUT_OF_STOCK_DETECTION]: {
      pillIcon: AlertCircleIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_OUT_OF_STOCK_TITLE,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_OUT_OF_STOCK_DESCRIPTION,
      showNewDesign: true,
      cardContainerClasses: 'h-210 w-284',
      cardBgColor: 'bg-white',
    },
    [RecommendationHubCardTypes.EXCESS_STOCK_RECOGNITION]: {
      pillIcon: TargetIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_EXCESS_STOCK_TITLE,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_EXCESS_STOCK_DESCRIPTION,
      showNewDesign: true,
      cardContainerClasses: 'h-210 w-284',
      cardBgColor: 'bg-white',
    },
    [RecommendationHubCardTypes.ANALYZE_AGING_INVENTORY]: {
      pillIcon: GraphUpwardTrendIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_AGING_INVENTORY_TITLE,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_AGING_INVENTORY_DESCRIPTION,
      showNewDesign: true,
      cardContainerClasses: ' h-210',
      classCardCss: 'w-386',
      cardBgColor: 'bg-white',
    },
    [RecommendationHubCardTypes.CUSTOM_STOCKOUT_AND_REORDER_ALERT]: {
      pillIcon: BellIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_TITLE,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_STOCKOUT_REORDER_DESCRIPTION,
      showNewDesign: true,
      cardContainerClasses: 'h-210',
      classCardCss: 'w-386',
      cardBgColor: 'bg-white',
    },
    [RecommendationHubCardTypes.HIIVE_CLEARANCE]: {
      pillIcon: HiiveLinearIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_TITLE,
      subText:
        I18nKey.RECOMMENDATION_HUB_CARD_PRODUCTS_BUDGET_MANAGEMENT_DATA_SUB_TEXT,
      subTextCompleted:
        I18nKey.RECOMMENDATION_HUB_INVENTORY_OPTIMIZATION_HIIVE_CLEARANCE_DESCRIPTION,
      showNewDesign: true,
      cardContainerClasses: 'h-210',
      classCardCss: 'w-386',
      cardBgColor: 'bg-white',
    },
    [RecommendationHubCardTypes.BRANDS_AND_COMPETITORS]: {
      pillIcon: SwordsCrossedIcon,
      pillText: I18nKey.RECOMMENDATION_HUB_CARD_CONNECT_DATA_PILL_TEXT,
      mainText: I18nKey.NAV_BAR_LABEL_BRANDS_AND_COMPETITORS,
      subText: I18nKey.BRANDS_AND_COMPETITORS_RECOMMENDATIONS_HUB_CARD_SUB_TEXT,
      subTextCompleted:
        I18nKey.BRANDS_AND_COMPETITORS_RECOMMENDATIONS_HUB_CARD_SUB_TEXT_COMPLETED,
      primaryButtonText: I18nKey.BRANDS_AND_COMPETITORS_CATALOG_ASSIGN_BACN,
    },
  };
  return MAP_CARD_TYPE_TO_CARD_PROPS_WITH_AI;
};

export const MAP_RECOMMENDATION_HELP_URL: Record<
  RecommendationHubCardTypes,
  string
> = {
  [RecommendationHubCardTypes.PRODUCTS_BUDGET_MANAGEMENT]:
    'https://intercom.help/flywheel-20/en/articles/8728385-budget-pacing',
  [RecommendationHubCardTypes.PRODUCTS_KEYWORD_AND_TARGET]:
    'https://intercom.help/flywheel-20/en/collections/3118300-keyword-targeting-actions',
  [RecommendationHubCardTypes.PRODUCTS_TURN_ON_AIBIDDING]:
    'https://intercom.help/flywheel-20/en/articles/6804162-recommendation-details-turn-on-automated-bidding-with-smart-acos-targets',
  [RecommendationHubCardTypes.PRODUCTS_NOT_IN_SMART_CAMPAIGNS]:
    'https://intercom.help/flywheel-20/en/articles/8514758-products-not-in-smart-campaigns',
  [RecommendationHubCardTypes.PRODUCTS_UNADVERTISED_PRODUCTS]:
    'https://intercom.help/flywheel-20/en/articles/8470926-campaign-creator-unadvertised-products',
  [RecommendationHubCardTypes.PRODUCTS_COGS]:
    'https://intercom.help/flywheel-20/en/articles/5528132-how-to-add-cost-of-goods-sold-cogs',
  [RecommendationHubCardTypes.PRODUCTS_API_CONNECT]:
    'https://intercom.help/flywheel-20/en/collections/2824366-flywheel-getting-started-guide',
  [RecommendationHubCardTypes.PRODUCTS_CATALOG_CONNECT]:
    'https://intercom.help/flywheel-20/en/articles/8461641-start-selling-on-walmart',
  [RecommendationHubCardTypes.ADVERTISED_PRODUCTS_WITH_LESS_THAN_30_DAYS_INV]:
    'https://intercom.help/flywheel-20/en/articles/8618459-inventory-demand-forecast',
  [RecommendationHubCardTypes.UN_ADVERTISED_PRODUCTS_WITH_LOW_SELL_THROUGH_RATE]:
    'https://intercom.help/flywheel-20/en/articles/8618423-inventory-dashboard-inventory-health-overview',
  [RecommendationHubCardTypes.OUT_OF_STOCK]:
    'https://intercom.help/flywheel-20/en/articles/8618441-inventory-out-of-stock-estimated-missed-sales',
  [RecommendationHubCardTypes.UNSOLD_INVENTORY]:
    'https://intercom.help/flywheel-20/en/articles/8618450-inventory-unsold-inventory-in-l90-days-excess-stock',
  [RecommendationHubCardTypes.LIQUIDATE_ON_HIIVE]:
    'https://www.joinhiive.com/brands',

  [RecommendationHubCardTypes.AUTOMATED_BIDDING]: 'N/A',
  [RecommendationHubCardTypes.UNADVERTISED_PRODUCTS]: 'N/A',
  [RecommendationHubCardTypes.CONNECT_PRODUCTS_DATA]: 'N/A',
  [RecommendationHubCardTypes.MARKET_INTELLIGENCE]: 'N/A',
  [RecommendationHubCardTypes.ADD_COGS]: 'N/A',
  [RecommendationHubCardTypes.OPPORTUNITY_ANALYZER_REPORT]: 'N/A',

  // TODO: Update Hiive help center links
  [RecommendationHubCardTypes.DEMAND_FORECAST]:
    'https://intercom.help/flywheel-20/en/articles/8618459-inventory-demand-forecast',
  [RecommendationHubCardTypes.RATE_OF_SALE_INSIGHTS]:
    'https://intercom.help/flywheel-20/en/articles/8618465-inventory-insights-rate-of-sale',
  [RecommendationHubCardTypes.OUT_OF_STOCK_DETECTION]:
    'https://intercom.help/flywheel-20/en/articles/8618441-inventory-out-of-stock-estimated-missed-sales',
  [RecommendationHubCardTypes.EXCESS_STOCK_RECOGNITION]:
    'https://intercom.help/flywheel-20/en/articles/8618450-inventory-unsold-inventory-in-l90-days-excess-stock',
  [RecommendationHubCardTypes.ANALYZE_AGING_INVENTORY]:
    'https://intercom.help/flywheel-20/en/articles/8618423-inventory-dashboard-inventory-health-overview',
  [RecommendationHubCardTypes.CUSTOM_STOCKOUT_AND_REORDER_ALERT]:
    'https://intercom.help/flywheel-20/en/articles/8618493-inventory-insights-stockout-reorder',
  [RecommendationHubCardTypes.HIIVE_CLEARANCE]: 'N/A',
  [RecommendationHubCardTypes.BRANDS_AND_COMPETITORS]:
    'https://intercom.help/flywheel-20/en/articles/6005429-how-to-add-brand-and-competitor-tags',
};

export const gethelpButtonAction = (
  card: CardDetails,
  navigateHelpConnectPage: (url: string) => void
) => {
  switch (card.type) {
    case RecommendationHubCardTypes.PRODUCTS_TURN_ON_AIBIDDING:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.PRODUCTS_TURN_ON_AIBIDDING
        ]
      );
    case RecommendationHubCardTypes.PRODUCTS_COGS:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[RecommendationHubCardTypes.PRODUCTS_COGS]
      );
    case RecommendationHubCardTypes.PRODUCTS_KEYWORD_AND_TARGET:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.PRODUCTS_KEYWORD_AND_TARGET
        ]
      );
    case RecommendationHubCardTypes.PRODUCTS_API_CONNECT:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.PRODUCTS_API_CONNECT
        ]
      );
    case RecommendationHubCardTypes.PRODUCTS_CATALOG_CONNECT:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.PRODUCTS_CATALOG_CONNECT
        ]
      );
    case RecommendationHubCardTypes.PRODUCTS_BUDGET_MANAGEMENT:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.PRODUCTS_BUDGET_MANAGEMENT
        ]
      );
    case RecommendationHubCardTypes.PRODUCTS_NOT_IN_SMART_CAMPAIGNS:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.PRODUCTS_NOT_IN_SMART_CAMPAIGNS
        ]
      );
    case RecommendationHubCardTypes.PRODUCTS_UNADVERTISED_PRODUCTS:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.PRODUCTS_UNADVERTISED_PRODUCTS
        ]
      );
    case RecommendationHubCardTypes.OUT_OF_STOCK:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[RecommendationHubCardTypes.OUT_OF_STOCK]
      );
    case RecommendationHubCardTypes.UNSOLD_INVENTORY:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[RecommendationHubCardTypes.UNSOLD_INVENTORY]
      );
    case RecommendationHubCardTypes.LIQUIDATE_ON_HIIVE:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.LIQUIDATE_ON_HIIVE
        ]
      );
    case RecommendationHubCardTypes.DEMAND_FORECAST:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[RecommendationHubCardTypes.DEMAND_FORECAST]
      );
    case RecommendationHubCardTypes.RATE_OF_SALE_INSIGHTS:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.RATE_OF_SALE_INSIGHTS
        ]
      );
    case RecommendationHubCardTypes.OUT_OF_STOCK_DETECTION:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.OUT_OF_STOCK_DETECTION
        ]
      );
    case RecommendationHubCardTypes.EXCESS_STOCK_RECOGNITION:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.EXCESS_STOCK_RECOGNITION
        ]
      );
    case RecommendationHubCardTypes.ANALYZE_AGING_INVENTORY:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.ANALYZE_AGING_INVENTORY
        ]
      );
    case RecommendationHubCardTypes.CUSTOM_STOCKOUT_AND_REORDER_ALERT:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.CUSTOM_STOCKOUT_AND_REORDER_ALERT
        ]
      );
    case RecommendationHubCardTypes.HIIVE_CLEARANCE:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[RecommendationHubCardTypes.HIIVE_CLEARANCE]
      );
    case RecommendationHubCardTypes.ADVERTISED_PRODUCTS_WITH_LESS_THAN_30_DAYS_INV:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes
            .ADVERTISED_PRODUCTS_WITH_LESS_THAN_30_DAYS_INV
        ]
      );
    case RecommendationHubCardTypes.UN_ADVERTISED_PRODUCTS_WITH_LOW_SELL_THROUGH_RATE:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes
            .UN_ADVERTISED_PRODUCTS_WITH_LOW_SELL_THROUGH_RATE
        ]
      );
    case RecommendationHubCardTypes.BRANDS_AND_COMPETITORS:
      return navigateHelpConnectPage(
        MAP_RECOMMENDATION_HELP_URL[
          RecommendationHubCardTypes.BRANDS_AND_COMPETITORS
        ]
      );
    default:
      return undefined;
  }
};
