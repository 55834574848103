import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { PageType } from '../types';
import { UseMerchantSyncingReturnProps, useEmptyStateData } from '../hooks';
import { HeroV2 } from './Hero';
import { RiskFreeTrialBanner } from './RiskFreeBanner';
import { Propositions } from '../Propositions';
import { CTABanner } from '../CTABanner';
import { ArrowRightIcon } from '@teikametrics/tm-design-system';
import I18nKey from '../../../lib/types/I18nKey';
import { useIntl } from 'react-intl';
import { INVENTORY_OPTIMIZATION_HELP_CENTER_LINK } from './consts';
import {
  PageHeaderContext,
  PageHeaderContextState,
} from '../../../containers/pageHeaderProvider';

interface OwnProps {
  readonly title: string;
  readonly pageType: PageType;
  readonly merchantsSyncing: UseMerchantSyncingReturnProps;
  readonly riskFreeTrialInit?: () => void;
  readonly riskFreeModal?: JSX.Element;
}

export const EmptyStateV2: React.FC<OwnProps> = ({
  title,
  pageType,
  merchantsSyncing,
  riskFreeTrialInit,
  riskFreeModal,
}) => {
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);
  const isProductConnected = merchantsSyncing.isProductConnected;
  const isAdsConnected = merchantsSyncing.isAdsConnected;

  const pageData = useEmptyStateData(
    pageType,
    isProductConnected,
    isAdsConnected
  );

  const onBannerCTAClick = () => {
    if (pageType === PageType.InventoryOptimization) {
      window.open(INVENTORY_OPTIMIZATION_HELP_CENTER_LINK, '_blank');
    }
  };

  const bannerCTALabel = useMemo(() => {
    if (pageType === PageType.InventoryOptimization) {
      return I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_LEARN_MORE_CTA;
    }

    return I18nKey.EMPTY_STATES_INVENTORY_OPTIMIZATION_LEARN_MORE_CTA;
  }, [pageType]);

  const bannerCTAIcon = useMemo(() => {
    if (pageType === PageType.InventoryOptimization) {
      return ArrowRightIcon;
    }

    return ArrowRightIcon;
  }, [pageType]);

  const bannerCTAIconPlacement = useMemo(() => {
    if (pageType === PageType.InventoryOptimization) {
      return 'right';
    }

    return 'left';
  }, [pageType]);

  useEffect(() => {
    containerRef?.current?.scrollIntoView();
  }, []);

  const { updatePageHeaderData } =
    useContext<PageHeaderContextState>(PageHeaderContext);

  useEffect(() => {
    updatePageHeaderData({
      title: title,
      dataTestId: 'empty_state_v2',
    });
  }, []);

  return (
    <div className="relative mb-40" ref={containerRef}>
      <HeroV2 heroData={pageData.hero} />
      {!!riskFreeTrialInit && (
        <RiskFreeTrialBanner
          riskFreeTrialInit={riskFreeTrialInit}
          riskFreeTrialMessage={pageData.hero.riskFreeTrialMessage}
          riskFreeModal={riskFreeModal}
        />
      )}
      <Propositions propositions={pageData.propositions} />
      <CTABanner
        title={pageData.banner.title}
        subtitle={pageData.banner.subtitle}
        buttonProps={{
          label: intl.formatMessage({ id: bannerCTALabel }),
          icon: bannerCTAIcon,
          iconPosition: bannerCTAIconPlacement,
          onClick: onBannerCTAClick,
        }}
      />
    </div>
  );
};
EmptyStateV2.displayName = 'EmptyStateV2';
