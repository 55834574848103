import isNil from 'lodash/isNil';
import { useContext, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import first from 'lodash/first';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTruncatedTextLinkColumn } from '../../../../../containers/table/utils/makeTableCells';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../../containers/userProvider/userProvider';
import {
  AdType,
  FlywheelSalesChannel,
  ProfileApiDataDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ViewTrendLink } from '../../../components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../components/ViewTrends';
import { getAdManagerUrl, getAdsLevelUri } from '../../../utils';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
  TableDataAdsManager,
} from '../types';
import {
  PROFILES_API_COLUMN_NAME,
  getSelectedMerchantCountryId,
} from '../utils';
import { getAdsManagerDetailsQueryParams } from '../utils/queryParamsUtils';
import {
  getMerchantFromLocalStorage,
  getSalesChannelFromLocalStorage,
} from '../utils/storageUtils';
import {
  AMAZON_SALES_CHANNEL_ID,
  WALMART_SALES_CHANNEL_ID,
} from '../../../../../lib/types/SalesChannels';

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = ({
  profileDetails,
  profilePerformance,
  profileId,
  channelSettings,
  adTypes,
  selectedMerchantCountries,
  merchantType,
  allMerchants,
  salesChannel,
}) => {
  const campaignData: ProfileApiDataDetails = {
    profileDetails,
    profilePerformance,
    profileId,
    channelSettings,
  };

  const location = useLocation();

  const baseUrl = useMemo(
    () => getAdManagerUrl(location.pathname),
    [location.pathname]
  );

  const searchQueryParams = new URLSearchParams(
    decodeURIComponent(location.search)
  );

  const userContext = useContext(UserContext);

  const userId = userContext.userInfo.userDetails?.id || '';
  const accountId = getCurrentAccountFromContext(userContext)?.id || '';

  const salesChannelId = searchQueryParams.get(SALES_CHANNEL_ID);
  const merchantCountryIds = searchQueryParams.get(MERCHANT_COUNTRY_IDS);
  const adTypesFromQueryParams = searchQueryParams.get(AD_TYPE);

  const getText = (data: ProfileApiDataDetails) =>
    data?.profileDetails?.adGroupName ?? '';

  const selectedSalesChannel = getSalesChannelFromLocalStorage(
    userId || '',
    accountId || ''
  );
  const selectedMerchantCountryId = getSelectedMerchantCountryId(
    selectedMerchantCountries
  );

  const getNavPath = (data: ProfileApiDataDetails) => {
    const salesChannel = isNil(salesChannelId)
      ? String(selectedSalesChannel)
      : salesChannelId;

    const merchantCountries = isNil(merchantCountryIds)
      ? (getMerchantFromLocalStorage({
          userId,
          accountId,
          salesChannelId: salesChannelId || String(selectedSalesChannel),
        }) as (string | number)[])
      : merchantCountryIds.split(',');

    const adType = isNil(adTypesFromQueryParams)
      ? adTypes
      : (adTypesFromQueryParams.split(',') as AdType[]);

    return data.profileDetails?.adGroupId !== undefined
      ? getAdsLevelUri(
          baseUrl,
          ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups,
          data.profileDetails?.adGroupId
        ) +
          getAdsManagerDetailsQueryParams(
            salesChannel,
            merchantCountries,
            adType,
            data.profileDetails.campaignTargetingType,
            data.profileDetails.adGroupName
          )
      : '';
  };

  return makeTruncatedTextLinkColumn(
    getText,
    getNavPath,
    undefined,
    <ViewTrendLink
      dataTestId="profiles_ad_group_name"
      campaignId={campaignData.profileDetails.campaignId}
      merchantCountryId={selectedMerchantCountryId}
      entityId={campaignData.profileDetails.adGroupId}
      entityType={ViewTrendsEntityType.ADGROUP}
      adType={first(adTypes)}
      merchantType={merchantType}
      campaignName={campaignData.profileDetails.campaignName}
      entityName={campaignData.profileDetails.adGroupName}
      merchantCountryName={
        allMerchants.find(
          (merchant) => merchant.merchantCountryId === selectedMerchantCountryId
        )?.merchantName
      }
      salesChannelId={
        salesChannel === FlywheelSalesChannel.Amazon
          ? AMAZON_SALES_CHANNEL_ID
          : WALMART_SALES_CHANNEL_ID
      }
      targetingType={campaignData.profileDetails.campaignTargetingType}
    />
  )(campaignData);
};
RowCellElement.displayName = 'RowCellElement';

export const adGroupNameColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.AdGroupName,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_ADGROUP_NAME,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const adGroupNameFilterProfile = createStringDataFieldFilter(
  PROFILES_API_COLUMN_NAME.AdGroupName,
  I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_ADGROUP_NAME,
  isValidString()
);
