import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputState,
  Placement,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeNumericInputColumn,
  makePercentColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import { CampaignDetails } from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
} from '../utils';
import { isDirty } from './totalBudget';

export const MIN_MOBILE_BID = 0;

export const getExistingValue = (campaignDetails: CampaignDetails) =>
  (campaignDetails.channelSettings.platformBidMultiplier?.mobileBidMultiplier?.toString() &&
    (
      campaignDetails.channelSettings.platformBidMultiplier
        ?.mobileBidMultiplier * 100
    ).toFixed(PERCENTAGE_FRACTION_DIGITS)) ||
  '';

export const isEditModeOrIsValueEdited = (
  isEditMode: boolean,
  changedValue: string,
  value: string
) =>
  isEditMode || //Changing between modes
  (!isNil(changedValue) && changedValue !== value); // Changes done due to bulk update. changedValue is latest, but state variable is not

export const tooltipContent = (invalidInput: boolean, intl: IntlShape) => {
  if (invalidInput) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          { id: I18nKey.ADS_MANAGER_INVALID_NUMERIC_ATLEAST_PERCENT },
          {
            minValue: intl.formatNumber(MIN_MOBILE_BID, {
              minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
              maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
            }),
          }
        )}
      </p>
    );
  }
};

export const checkIfInputIsInvalid = (
  value: string,
  isInputEdited: boolean,
  existingValue: string
) =>
  (Number(value) < MIN_MOBILE_BID || isNaN(Number(value)) || isEmpty(value)) &&
  (isInputEdited || isDirty(value, existingValue));

export const getControlledTooltipState = (
  focussed: boolean,
  state: NumericInputState
) =>
  focussed && state === NumericInputState.Error
    ? ControlledTooltip.Show
    : ControlledTooltip.Hide;

const getMobileBidMultiplierValue = (
  changedValue: string,
  existingValue: string
) => changedValue ?? existingValue;

export const RowCellElement: React.FC<
  CampaignDetails & TableDataAdsManager
> = ({ isEditMode, ...campaignDetails }) => {
  const intl = useIntl();

  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        campaignDetails.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const existingValue = getExistingValue(campaignDetails);

  let mobileBidMultiplier = getMobileBidMultiplierValue(
    changedValue,
    existingValue
  );

  const [value, setValue] = useState<string>(mobileBidMultiplier);
  const [isInputEdited, setIsInputEdited] = useState<boolean>(false);

  useEffect(() => {
    if (isEditModeOrIsValueEdited(isEditMode, changedValue, value)) {
      setValue(mobileBidMultiplier);
    }
    return () => {
      if (!isEditMode) {
        setIsInputEdited(false);
      }
    };
  }, [isEditMode, changedValue, currentPage]);

  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);

  const updateCellValue = (newValue: string) =>
    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier,
        rowId: campaignDetails.campaignId,
        existingValue,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        value: newValue,
        numericValue: true,
      })
    );

  const debounceCellValueUpdate = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [campaignDetails.campaignId]
  );

  if (isEditMode) {
    const onInputFocus = () => setFocussed(true);

    const onInputBlur = () => {
      if (!isEmpty(value)) {
        debounceCellValueUpdate.cancel();
        const formattedValue = Number.parseFloat(value).toFixed(
          PERCENTAGE_FRACTION_DIGITS
        );
        setValue(formattedValue);
        updateCellValue(formattedValue);
      }
      setFocussed(false);
    };

    return makeNumericInputColumn<CampaignDetails>(() => {
      let state = NumericInputState.Default;
      const isInputInvalid: boolean = checkIfInputIsInvalid(
        value,
        isInputEdited,
        existingValue
      );

      if (isInputInvalid) {
        state = NumericInputState.Error;
      }

      return {
        dataTestId: CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier,
        value,
        state,
        appendedElement: '%',
        onChange: (inputValue: string) => {
          setIsInputEdited(true);
          setValue(inputValue);
          debounceCellValueUpdate(inputValue);
        },
        tooltipContent: tooltipContent(isInputInvalid, intl),
        controlledTooltip: getControlledTooltipState(focussed, state),
        tooltipPlacement: Placement.Bottom,
        style: ContentStyle.Bold,
        onInputFocus,
        onInputBlur,
        acceptOnlyPositiveNumbers: true,
        isDirty: isDirty(value, existingValue),
        placeholder: MIN_MOBILE_BID.toFixed(PERCENTAGE_FRACTION_DIGITS),
        minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      };
    })(campaignDetails);
  }

  return makePercentColumn<CampaignDetails>(
    ({ channelSettings: { platformBidMultiplier } }) =>
      platformBidMultiplier && !isNil(platformBidMultiplier.mobileBidMultiplier)
        ? platformBidMultiplier.mobileBidMultiplier.toString()
        : '0',
    campaignDetails.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier
    )
  )(campaignDetails);
};
RowCellElement.displayName = 'RowCellElement';

export const mobileBidMultiplierColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MOBILE,
  RowCellElement,
  gridColumnWidth: '140px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const mobileBidMultiplierFilter = createPercentDataFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.MobileBidMultiplier,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_MOBILE,
  isValidNumber()
);
