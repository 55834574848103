import React, { useContext, useState } from 'react';
import {
  AmazonLogomarkEnabledIcon,
  Button,
  ButtonSize,
  ButtonVariant,
  ListIcon,
  PortaledTooltip,
  PortaledTooltipAlignment,
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
  ProductSelectLabel,
  ProductSelectLabelProps,
  ProductSelectOption,
  WalmartLogomarkEnabledIcon,
} from '@teikametrics/tm-design-system';
import {
  SkuDetailsFields,
  SlideoutProductDetails,
} from '../../../../../lib/types/SKUSharedTypes';
import { MerchantCountriesContext } from '../../../../../containers/merchantCountriesProvider/merchantCountriesProvider';
import { MerchantCountry } from '../../../../../lib/types/Fam';
import {
  AMAZON_SALES_CHANNEL_ID,
  MAP_SALES_CHANNEL_ID_TO_NAME,
} from '../../../../../lib/types/SalesChannels';
import classNames from 'classnames';
import { AdvertisedStatus } from './advertisedStatus';
import { useIntl } from 'react-intl';
import I18nKey from '../../../../../lib/types/I18nKey';
import { RelatedCampaignsAndAdGroupFlyover } from '../../../components/RelatedCampaignsAndAdGroupFlyover';
import { SKUApiClient } from '../../../../../lib/clients/SKUApiClient';
import { getAmazonItemPageUrl } from '../utils/skuDetailsUtils';
import { MerchantCountryCode } from '../../../../../lib/types/AOSharedTypes';
import { getWalmartItemPageUrl } from '../../../../../lib/utilities/skuUtils';
import { getBasePath, getUrlParamsForDetailsPage } from '../utils';
import { SkuDetailsLocationState } from '../types';
import { useNavigate } from 'react-router-dom';
import { buildUrlWithQueryParams } from '../../../../../lib/utilities/buildUrlUtilities';

const getVariationType = (variationType?: string) => {
  if (variationType === 'DUMMY_PARENT') {
    return 'PARENT';
  }

  return variationType;
};
const getProductOptions = (
  productDetails: SlideoutProductDetails,
  merchant?: MerchantCountry
) => {
  const options: (ProductSelectLabelProps & {
    skuCount?: number;
    advertisedSkuCount?: number;
  })[] = [];

  options.push({
    asin: productDetails.skuParentDetails.extItemDetail?.extItemId,
    productName: productDetails.skuParentDetails.name,
    imageUrl: productDetails.skuParentDetails.imageUrl,
    sku: productDetails.skuParentDetails.sku,
    merchantCountry: merchant?.country,
    merchantName: merchant?.merchantName,
    salesChannelIcon:
      merchant?.salesChannelId === AMAZON_SALES_CHANNEL_ID
        ? AmazonLogomarkEnabledIcon
        : WalmartLogomarkEnabledIcon,
    useAsin: merchant?.salesChannelId === AMAZON_SALES_CHANNEL_ID,
    className: '',
    variationType: getVariationType(
      productDetails.skuParentDetails.variationType
    ),
    useSku: true,
    productCatalogId: productDetails.skuParentDetails.productCatalogId,
    skuCount:
      getVariationType(productDetails.skuParentDetails.variationType) ===
      'PARENT'
        ? productDetails.skuChildren?.length
        : 1,
    count: productDetails.skuChildren?.length,
    advertisedSkuCount: productDetails.skuParentDetails.advertisedSkuCount,
  });

  options.push(
    ...(productDetails.skuChildren?.map((child) => ({
      asin: child.extItemDetail?.extItemId,
      productName: child.name,
      imageUrl: child.imageUrl,
      sku: child.sku,
      merchantCountry: merchant?.country,
      merchantName: merchant?.merchantName,
      salesChannelIcon:
        merchant?.salesChannelId === AMAZON_SALES_CHANNEL_ID
          ? AmazonLogomarkEnabledIcon
          : WalmartLogomarkEnabledIcon,
      useAsin: merchant?.salesChannelId === AMAZON_SALES_CHANNEL_ID,
      className: '',
      variationType: getVariationType(child.variationType),
      productCatalogId: child.productCatalogId,
      useSku: true,
      skuCount: child.skuCount || 1,
      count: child.skuCount,
      advertisedSkuCount: child.advertisedSkuCount,
    })) ?? [])
  );

  return options;
};

export interface ProductDropdownProps {
  readonly isProductExpanded?: boolean;
  readonly onExpandToggle: () => void;
  readonly productDetails: SlideoutProductDetails;
  readonly selectedProductId?: string;
  readonly onProductSelect: (productCatalogId: string) => void;
  readonly skuApiClient: SKUApiClient;
  readonly closeTrendsSlideover?: () => void;
}
export const ProductDropdown: React.FC<ProductDropdownProps> = ({
  isProductExpanded,
  onExpandToggle,
  productDetails,
  selectedProductId,
  onProductSelect,
  skuApiClient,
  closeTrendsSlideover,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { merchantCountries } = useContext(MerchantCountriesContext);

  const [
    selectedProductForRelatedCampaigns,
    setSelectedProductForRelatedCampaigns,
  ] = useState<SkuDetailsFields>();

  const merchant = merchantCountries?.find(
    (merchant) =>
      merchant.merchantCountryId === productDetails?.merchantCountryId
  );
  const options = getProductOptions(productDetails, merchant);

  const selectedOption = options.find(
    (option) => option.productCatalogId === selectedProductId
  );

  const filteredOptions = options.filter(
    (option) => option.productCatalogId !== selectedProductId
  );

  const onActiveCampaignsClick = (pcId: string) => {
    const product =
      productDetails.skuParentDetails.productCatalogId === pcId
        ? productDetails.skuParentDetails
        : productDetails.skuChildren?.find(
            (child) => child.productCatalogId === pcId
          );
    setSelectedProductForRelatedCampaigns(product!);
  };

  const onAsinClick = (extItemId: string) => {
    const url =
      merchant?.salesChannelId === AMAZON_SALES_CHANNEL_ID
        ? getAmazonItemPageUrl(
            (merchant?.country as MerchantCountryCode) ??
              MerchantCountryCode.US,
            extItemId
          )
        : getWalmartItemPageUrl(extItemId);
    window.open(url);
  };

  const basePath = getBasePath(merchant);

  const onProductNameClick = (productCatalogId: string) => {
    const product =
      productDetails.skuParentDetails.productCatalogId === productCatalogId
        ? productDetails.skuParentDetails
        : productDetails.skuChildren?.find(
            (child) => child.productCatalogId === productCatalogId
          );

    const skuDetailsPageUrl = buildUrlWithQueryParams(
      `${basePath}/${encodeURIComponent(product?.sku!)}`,
      getUrlParamsForDetailsPage(
        product?.parentProductCatalogId
          ? product.parentProductCatalogId
          : productCatalogId,
        merchant?.merchantCountryId ?? ''
      )
    );
    const locationState: SkuDetailsLocationState = {
      name: product?.name,
      sku: product?.sku,
      imageUrl: product?.imageUrl,
      salesChannelId: product?.extItemDetail?.salesChannelId,
      asin: product?.extItemDetail?.extItemId,
      extItemType: product?.extItemDetail?.extItemType,
      channel:
        MAP_SALES_CHANNEL_ID_TO_NAME[product?.extItemDetail?.salesChannelId!],
    };
    return navigate(skuDetailsPageUrl, {
      state: locationState,
    });
  };

  const hasChildren = filteredOptions.length > 0;

  const closeAllOnNavigate = () => {
    setSelectedProductForRelatedCampaigns(undefined);
    closeTrendsSlideover?.();
  };

  return (
    <>
      {selectedProductForRelatedCampaigns && (
        <RelatedCampaignsAndAdGroupFlyover
          closeCurrentTrendsSlideover={closeTrendsSlideover}
          isOpen={true}
          onClose={() => setSelectedProductForRelatedCampaigns(undefined)}
          closeAllOnNavigate={closeAllOnNavigate}
          productGroupId={
            selectedProductForRelatedCampaigns?.parentProductCatalogId || ''
          }
          productCatalogId={
            selectedProductForRelatedCampaigns?.productCatalogId!
          }
          merchantCountryId={merchant?.merchantCountryId!}
          salesChannelId={merchant?.salesChannelId!}
          skuDetailsFields={{
            name: selectedProductForRelatedCampaigns.name,
            sku: selectedProductForRelatedCampaigns.sku,
            extItemDetail: {
              extItemId:
                selectedProductForRelatedCampaigns?.extItemDetail?.extItemId ||
                '',
              extItemType:
                selectedProductForRelatedCampaigns.extItemDetail?.extItemType ||
                '',
              salesChannelId: merchant?.salesChannelId!,
              salesChannelName:
                MAP_SALES_CHANNEL_ID_TO_NAME[merchant?.salesChannelId!],
            },
            imageUrl: selectedProductForRelatedCampaigns.imageUrl,
          }}
          skuApiClient={skuApiClient}
          skus={
            selectedProductForRelatedCampaigns.variationType === 'PARENT'
              ? productDetails.skuChildren?.map((child) => child.sku)
              : [selectedProductForRelatedCampaigns.sku]
          }
          productNameTooltipPlacement={PortaledTooltipPlacement.Bottom}
          header={intl.formatMessage({
            id: I18nKey.SKU_CATALOG_PARENT_FLYOVER_HEADING,
          })}
          campaignChildView
        />
      )}
      <div
        className={classNames('w-full flex flex-col items-stretch', {
          'h-eventListingHeight overflow-auto': isProductExpanded,
        })}
      >
        {selectedOption && (
          <ProductSelectLabel
            {...selectedOption}
            isOpen={isProductExpanded}
            onProductNameClick={() =>
              onProductNameClick(selectedOption.productCatalogId!)
            }
            onAsinClick={() => onAsinClick(selectedOption.asin!)}
            showExpandIcon={hasChildren}
            onExpandToggle={() => {
              if (hasChildren) {
                onExpandToggle();
              }
            }}
            showCount={selectedOption.variationType === 'PARENT'}
            className={classNames('border border-solid border-grey-200', {
              'rounded-t-8': isProductExpanded,
              'rounded-lg': !isProductExpanded,
            })}
            dataTestId="product-select-label"
          >
            <div className="flex flex-row items-center justify-end">
              <AdvertisedStatus
                advertisedSkuCount={selectedOption.advertisedSkuCount}
                skuCount={selectedOption.skuCount}
                showCount={selectedOption.variationType === 'PARENT'}
              />
              <div className="flex items-center ml-24">
                <PortaledTooltip
                  content={intl.formatMessage({
                    id: I18nKey.SKU_CATALOG_PARENT_FLYOVER_HEADING,
                  })}
                  position={{
                    placement: PortaledTooltipPlacement.Bottom,
                    alignment: PortaledTooltipAlignment.Center,
                    placementOffsetInPixels: 0,
                  }}
                  theme={PortaledTooltipTheme.DarkV2}
                >
                  <Button
                    size={ButtonSize.Large}
                    variant={ButtonVariant.ActionWithoutBorder}
                    svgIcon={ListIcon}
                    className="bg-transparent mr-8"
                    iconClassName="hover:text-purple-500"
                    onClick={(event) => {
                      event.stopPropagation();
                      onActiveCampaignsClick(selectedOption.productCatalogId!);
                    }}
                    dataTestId="active-campaigns-button"
                  />
                </PortaledTooltip>
              </div>
            </div>
          </ProductSelectLabel>
        )}
        {isProductExpanded &&
          filteredOptions.map((option, index) => (
            <ProductSelectOption
              {...option}
              useSku={true}
              className={classNames(
                'border-solid border border-t-0 border-grey-200 cursor-pointer',
                {
                  'rounded-b-8': index === filteredOptions.length - 1,
                }
              )}
              onClick={() => {
                onProductSelect(option.productCatalogId!);
              }}
              onAsinClick={() => onAsinClick(option.asin!)}
              isChild={true}
              dataTestId="product_select"
            >
              <div className="flex flex-row items-center justify-end">
                <AdvertisedStatus
                  advertisedSkuCount={option.advertisedSkuCount}
                  skuCount={
                    option.variationType === 'PARENT'
                      ? productDetails.skuChildren?.length
                      : 1
                  }
                  showCount={option.variationType === 'PARENT'}
                />
                <div className="flex items-center ml-24">
                  <PortaledTooltip
                    content={intl.formatMessage({
                      id: I18nKey.SKU_CATALOG_PARENT_FLYOVER_HEADING,
                    })}
                    position={{
                      placement: PortaledTooltipPlacement.Bottom,
                      alignment: PortaledTooltipAlignment.Center,
                    }}
                    theme={PortaledTooltipTheme.DarkV2}
                  >
                    <Button
                      size={ButtonSize.Large}
                      variant={ButtonVariant.ActionWithoutBorder}
                      svgIcon={ListIcon}
                      className="bg-transparent mr-8"
                      iconClassName="hover:text-purple-500"
                      onClick={(event) => {
                        event.stopPropagation();
                        onActiveCampaignsClick(option.productCatalogId!);
                      }}
                    />
                  </PortaledTooltip>
                </div>
              </div>
            </ProductSelectOption>
          ))}
      </div>
    </>
  );
};
