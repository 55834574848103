import {
  createNumericDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeNumericColumn } from '../../../../../containers/table/utils/makeTableCells';
import { TargetsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { TARGETS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) =>
  makeNumericColumn<TargetsDetails>(
    ({ targetPerformance: { otherSkuUnits } }) => {
      return otherSkuUnits?.toString();
    }
  )(props);
RowCellElement.displayName = 'RowCellElement';

export const otherSKUUnitsColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.OtherSkuUnits,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_OTHERSKUUNITS,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const otherSKUUnitsFilter = createNumericDataFieldFilter(
  TARGETS_API_COLUMN_NAME.OtherSkuUnits,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_OTHERSKUUNITS,
  isValidNumber(0)
);
