import { DateTime } from 'luxon';
import sortBy from 'lodash/sortBy';
import { StringMap } from '../types';
import {
  ContainsFilter,
  DoesNotContainFilter,
  EqualToFilter,
  Filter,
  FilterOps,
  GreaterThanFilter,
  GreaterThanOrEqualFilter,
  InFilter,
  IsFilter,
  IsNotFilter,
  LessThanFilter,
  LessThanOrEqualFilter,
  LikeFilter,
  NotInFilter,
  SingleSelectOption,
} from '../types/Filter';
import { SortOrder } from '../types/Sort';

export const DATE_QUERY_STRING_FORMAT = 'yyyy-MM-dd';
export const AMPERSAND_ESCAPE_SEQUENCE = '|||';

export type compareFunction<RowData> = (a: RowData, b: RowData) => number;
export type CustomSortMap<RowData> = StringMap<compareFunction<RowData>>;

export const likeFilter = (field: string, value: string): LikeFilter => ({
  op: FilterOps.like,
  field,
  value,
});

export const isLikeFilter = (filter: Filter): filter is LikeFilter =>
  filter.op === FilterOps.like;

export const greaterThanOrEqualFilter = (
  field: string,
  value: number | DateTime
): GreaterThanOrEqualFilter => ({
  op: FilterOps.gte,
  field,
  value,
});

export const lessThanOrEqualFilter = (
  field: string,
  value: number | DateTime
): LessThanOrEqualFilter => ({
  op: FilterOps.lte,
  field,
  value,
});

export const greaterThanFilter = (
  field: string,
  value: number | DateTime
): GreaterThanFilter => ({
  op: FilterOps.gt,
  field,
  value,
});

export const lessThanFilter = (
  field: string,
  value: number | DateTime
): LessThanFilter => ({
  op: FilterOps.lt,
  field,
  value,
});

export const equalToFilter = (
  field: string,
  value: number | string | DateTime
): EqualToFilter => ({
  op: FilterOps.eq,
  field,
  value,
});

export const singleSelectFilter = (
  field: string,
  value: SingleSelectOption
): IsFilter => ({
  op: FilterOps.is,
  field,
  value,
});

export const isNotFilter = (field: string, value: string): IsNotFilter => ({
  op: FilterOps.isNot,
  field,
  value,
});

export const containsFilter = (
  field: string,
  value: string
): ContainsFilter => ({
  op: FilterOps.like,
  field,
  value,
});

export const doesNotContainFilter = (
  field: string,
  value: string
): DoesNotContainFilter => ({
  op: FilterOps.notLike,
  field,
  value,
});

export const inFilter = (field: string, value: string[]): InFilter => ({
  op: FilterOps.in,
  field,
  value,
});

export const notInFilter = (field: string, value: string[]): NotInFilter => ({
  op: FilterOps.notIn,
  field,
  value,
});

export function getSortedRowData<ColumnIds extends string, RowData>({
  columnId,
  direction,
  rowData,
  customSortMap,
}: {
  columnId: ColumnIds;
  direction: SortOrder;
  rowData: RowData[];
  customSortMap?: CustomSortMap<RowData>;
}) {
  const potentialCustomSort = customSortMap
    ? customSortMap[columnId]
    : undefined;
  if (potentialCustomSort) {
    return direction === SortOrder.Asc
      ? rowData.sort(potentialCustomSort)
      : rowData.sort(potentialCustomSort).reverse();
  } else {
    return direction === SortOrder.Asc
      ? sortBy(rowData, columnId)
      : sortBy(rowData, columnId).reverse();
  }
}
