import {
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';

import first from 'lodash/first';
import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeTruncatedTextLinkColumn,
  representSmartCampaign,
} from '../../../../../containers/table/utils/makeTableCells';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../../containers/userProvider/userProvider';
import {
  AdvertisingGoal,
  AiBiddingValues,
  ProductAdDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ViewTrendLink } from '../../../components/ViewTrendLink';
import { ViewTrendsEntityType } from '../../../components/ViewTrends';
import { getAdManagerUrl, getAdsLevelUri } from '../../../utils';
import {
  getAdType,
  getMerchantCountries,
  getSalesChannel,
} from '../adItemsTableColumns/utils';
import { transformAdvertisingGoal } from '../adgroupTableColumns/utils';
import {
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  AD_TYPE,
  CampaignTargetingType,
  MERCHANT_COUNTRY_IDS,
  SALES_CHANNEL_ID,
  TableDataAdsManager,
} from '../types';
import { getSelectedMerchantCountryId } from '../utils';
import { getAdsManagerDetailsQueryParams } from '../utils/queryParamsUtils';
import { getSalesChannelFromLocalStorage } from '../utils/storageUtils';
import { SV_ADS_API_COLUMN_NAME } from '../utils/svAdsTypes';

export const RowCellElement: React.FC<
  ProductAdDetails & TableDataAdsManager
> = (props) => {
  const data: ProductAdDetails = { ...props };

  const location = useLocation();
  const baseUrl = useMemo(
    () => getAdManagerUrl(location.pathname),
    [location.pathname]
  );

  const searchQueryParams = new URLSearchParams(
    decodeURIComponent(location.search)
  );

  const userContext = useContext(UserContext);

  const userId = userContext.userInfo.userDetails?.id || '';
  const accountId = getCurrentAccountFromContext(userContext)?.id || '';

  const salesChannelId = searchQueryParams.get(SALES_CHANNEL_ID);
  const merchantCountryIds = searchQueryParams.get(MERCHANT_COUNTRY_IDS);
  const adTypesFromQueryParams = searchQueryParams.get(AD_TYPE);

  const selectedSalesChannel = getSalesChannelFromLocalStorage(
    userId || '',
    accountId || ''
  );
  const selectedMerchantCountryId = getSelectedMerchantCountryId(
    props.selectedMerchantCountries
  );

  const aiBidding: AiBiddingValues =
    props.aiEnabled &&
    props.merchantsWithAutomationEnabled &&
    props.merchantsWithAutomationEnabled?.length > 0
      ? AiBiddingValues.Ai
      : AiBiddingValues.NotEnabled;

  const goal: AdvertisingGoal = transformAdvertisingGoal(
    aiBidding,
    data.adItemDetails.advertisingGoal
  );

  return makeTruncatedTextLinkColumn(
    ({ adItemDetails }: ProductAdDetails) => adItemDetails?.campaignName ?? '',
    ({ adItemDetails }: ProductAdDetails) =>
      adItemDetails?.campaignId !== undefined
        ? getAdsLevelUri(
            baseUrl,
            ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns,
            adItemDetails?.campaignId
          ) +
          getAdsManagerDetailsQueryParams(
            getSalesChannel(salesChannelId, selectedSalesChannel),
            getMerchantCountries(
              merchantCountryIds,
              userId,
              accountId,
              salesChannelId,
              selectedSalesChannel
            ),
            getAdType(adTypesFromQueryParams, props.adTypes)
          )
        : '',
    props.pendingFields?.includes(SV_ADS_API_COLUMN_NAME.CampaignName),
    <ViewTrendLink
      dataTestId={'product_ads_campaign_name'}
      entityId={data.adItemDetails.campaignId}
      entityType={ViewTrendsEntityType.CAMPAIGN}
      adType={first(props.adTypes)}
      merchantCountryId={selectedMerchantCountryId}
      campaignId={data.adItemDetails.campaignId}
      merchantType={props.merchantType}
      campaignName={data.adItemDetails.campaignName}
      entityName={data.adItemDetails.campaignName}
      merchantCountryName={
        props.allMerchants?.find(
          ({ merchantCountryId }) =>
            merchantCountryId === selectedMerchantCountryId
        )?.merchantName
      }
      salesChannelId={salesChannelId as string}
      targetingType={data.adItemDetails.campaignTargetingType}
    />,
    false,
    representSmartCampaign(
      goal,
      data.adItemDetails.campaignTargetingType || CampaignTargetingType.Manual,
      props.selectedAdType,
      props.showSmartCampaign
    )
  )(props);
};

RowCellElement.displayName = 'CampaignNameColumn';

export const campaignName: FlywheelTableColumn<
  ProductAdDetails,
  TableDataAdsManager
> = {
  columnName: SV_ADS_API_COLUMN_NAME.CampaignName,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_CAMPAIGN_NAME,
  isSortable: true,
  RowCellElement,
  gridColumnWidth: '280px',
};

export const campaignNameFilter = createStringDataFieldFilter(
  SV_ADS_API_COLUMN_NAME.CampaignName,
  I18nKey.ADS_MANAGER_SV_ADS_TABLE_COLUMN_CAMPAIGN_NAME,
  isValidString()
);
