import {
  MatchType,
  TargetInfo,
  TargetSegment,
} from '../../../../../../lib/types/CampaignFlexibilitySharedTypes';
import uuid from 'uuid';
import { brandTagsSortingOrder } from '../../utils/keywordUtils';
import {
  Alignment,
  Placement,
  Theme,
  TooltipProps,
} from '@teikametrics/tm-design-system';
import { Brand } from '../../../../../../lib/types/AOSharedTypes';
import { IntlShape } from 'react-intl';
import I18nKey from '../../../../../../lib/types/I18nKey';

export const defaultKeywordCheckboxes = [
  TargetSegment.Brand,
  TargetSegment.Competitor,
  TargetSegment.Generic,
];

export const defaultMatchTypeCheckboxes = [
  MatchType.Exact,
  MatchType.Phrase,
  MatchType.Broad,
];

export const transformKeywords = (
  keywords: string[],
  matchTypes: MatchType[]
) => {
  const sortedMatchTypes = matchTypes.sort(
    (a, b) => brandTagsSortingOrder[a] - brandTagsSortingOrder[b]
  );

  const transformedKeywords: TargetInfo[] = [];
  keywords.forEach((keyword) => {
    sortedMatchTypes.forEach((matchType) => {
      transformedKeywords.push({
        searchText: keyword,
        matchType,
        selected: true,
        targetId: uuid.v4().toString(),
      });
    });
  });
  return transformedKeywords;
};

export const getButtonTooltipProps = (
  brandTags: MatchType[],
  showBrandNamesContainer: boolean,
  brands: Brand[],
  targetSegments: TargetSegment[],
  intl: IntlShape,
  includeKeywordsTooltip = false,
  keywords?: TargetInfo[]
): TooltipProps => {
  const [
    TOOLTIP_MATCH_TYPE,
    TOOLTIP_TAG_TYPE,
    ADD_BRANDS_TOOLTIP,
    TOOLTIP_ENTER_KEYWORD,
  ] = [
    I18nKey.CREATE_KEYWORDS_TOOLTIP_MATCH_TYPE,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_TAG_TYPE,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_ADD_BRANDS,
    I18nKey.CREATE_KEYWORDS_TOOLTIP_ENTER_KEYWORD,
  ].map((id) => intl.formatMessage({ id }));
  return {
    content: (
      <ul className="list-disc list-inside">
        {!brandTags.length && <li>{TOOLTIP_MATCH_TYPE}</li>}
        {!targetSegments.length && <li>{TOOLTIP_TAG_TYPE}</li>}
        {showBrandNamesContainer && !brands.length && (
          <li>{ADD_BRANDS_TOOLTIP}</li>
        )}
        {includeKeywordsTooltip && !keywords?.length && (
          <li>{TOOLTIP_ENTER_KEYWORD}</li>
        )}
      </ul>
    ),
    theme: Theme.Dark,
    position: {
      placement: Placement.Top,
      alignment: Alignment.Center,
      placementOffsetInPixels: 12,
    },
  };
};
