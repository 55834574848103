import {
  AmazonLogomarkEnabledIcon as AmazonIcon,
  WalmartLogomarkEnabledIcon as WalmartIcon,
} from '@teikametrics/tm-design-system';
import { SalesChannel } from '../../../components/connectSalesChannelModal';
import I18nKey from '../../../../../lib/types/I18nKey';
import { HeaderInfo } from '../types';
import { FlywheelSalesChannel } from '../../../../../lib/types/Fam';

export const headerInfo: {
  [key: string]: HeaderInfo;
} = {
  [FlywheelSalesChannel.Amazon]: {
    header: I18nKey.SALES_CHANNELS_TABLE_TAB_AMAZON,
    salesChannelIcon: AmazonIcon,
    emptyStateTitleI18nKey:
      I18nKey.ACCOUNT_SALES_CHANNELS_NO_CONNECTIONS_TABLE_AMAZON_TITLE,
  },
  [FlywheelSalesChannel.Walmart]: {
    header: I18nKey.SALES_CHANNELS_TABLE_TAB_WALMART,
    salesChannelIcon: WalmartIcon,
    emptyStateTitleI18nKey:
      I18nKey.ACCOUNT_SALES_CHANNELS_NO_CONNECTIONS_TABLE_WALMART_TITLE,
  },
};

export const getCurrentSalesChannel = (
  salesChannels: SalesChannel[],
  salesChannelName: FlywheelSalesChannel
) => {
  return salesChannels.find(
    (salesChannel) => salesChannel.name === salesChannelName
  );
};
