import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  GenericError,
  Loader,
} from '@teikametrics/tm-design-system';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getCurrentAccountFromContext } from '../../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../../containers/userProvider/userProvider';
import {
  PaginatedRequest,
  PaginatedResult,
} from '../../../../../lib/clients/types';
import { MerchantSyncData } from '../../../../../lib/types/AOSharedTypes';
import {
  FlywheelSalesChannel,
  SalesChannelData,
} from '../../../../../lib/types/Fam';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  AsyncRequest,
  AsyncRequestCompleted,
  AsyncRequestFailed,
  AsyncRequestLoading,
  AsyncRequestNotStarted,
  asyncRequestIsComplete,
  asyncRequestIsFailed,
  asyncRequestIsLoading,
  asyncRequestIsNotStarted,
} from '../../../../../lib/utilities/asyncRequest';
import {
  PAGE_SIZE,
  SalesChannelProps,
} from '../../../containers/salesChannels/types';
import { headerInfo } from '../../../containers/salesChannels/utils/constants';
import { dataFetcherSalesChannelsTable } from '../../../containers/salesChannels/utils/hooks';
import { MerchantCountryList } from './MerchantCountryList';
import { SalesChannelHeader } from './SalesChannelHeader';

export const SalesChannelSyncStatus: React.FC<SalesChannelProps> = ({
  salesChannel,
  showDataTimingAndSyncSlideout,
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const currentAccount = getCurrentAccountFromContext(userContext);
  const [salesChannelData, setSalesChannelData] = useState<
    Array<SalesChannelData & MerchantSyncData>
  >([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [salesChannelDataRequest, setSalesChannelDataRequest] = useState<
    AsyncRequest<PaginatedResult<SalesChannelData & MerchantSyncData>>
  >(AsyncRequestNotStarted());
  const DATA_FETCHER = dataFetcherSalesChannelsTable(
    userContext,
    currentAccount?.id!,
    salesChannel.id,
    salesChannel.name as FlywheelSalesChannel
  );
  const getMerchantCountriesSyncData = async () => {
    const request: PaginatedRequest = {
      itemsPerPage: PAGE_SIZE,
      page: Math.ceil(salesChannelData.length / PAGE_SIZE) + 1,
      sorts: [],
      filters: [],
    };
    setSalesChannelDataRequest(AsyncRequestLoading());
    const salesChannelDataRequest: AsyncRequest<
      PaginatedResult<SalesChannelData & MerchantSyncData>,
      void
    > = await DATA_FETCHER(request)
      .then((response) => AsyncRequestCompleted(response))
      .catch(() => AsyncRequestFailed(undefined));
    setSalesChannelDataRequest(salesChannelDataRequest);
    const data = asyncRequestIsComplete(salesChannelDataRequest)
      ? salesChannelDataRequest.result
      : undefined;
    setTotalItems(data?.totalItems || 0);
    setSalesChannelData([
      ...salesChannelData,
      ...(data && data.items ? data.items : []),
    ]);
  };

  useEffect(() => {
    if (headerInfo[salesChannel.name] && salesChannel) {
      getMerchantCountriesSyncData();
    }
  }, [salesChannel]);

  return (
    <>
      {headerInfo[salesChannel.name] && (
        <div className="flex flex-col items-start gap-12 p-24 w-full">
          <SalesChannelHeader
            header={headerInfo[salesChannel.name]}
            totalItems={totalItems}
            showDataSyncingAndTimingSlideout={showDataTimingAndSyncSlideout}
          />
          {salesChannelData.length === 0 &&
            (asyncRequestIsNotStarted(salesChannelDataRequest) ||
              asyncRequestIsLoading(salesChannelDataRequest)) && (
              <div className="flex justify-center items-center w-full h-syncSlideOut">
                <Loader.default />
              </div>
            )}
          {asyncRequestIsFailed(salesChannelDataRequest) && (
            <GenericError tryAgain={getMerchantCountriesSyncData} />
          )}
          <div className="flex flex-col w-full">
            <MerchantCountryList
              salesChannelData={salesChannelData}
              salesChannel={salesChannel}
              refreshList={getMerchantCountriesSyncData}
              showDataTimingAndSyncSlideout={showDataTimingAndSyncSlideout}
            />
            {salesChannelData.length < totalItems && (
              <div className="flex flex-row justify-center mt-16 py-12">
                <Button
                  state={
                    asyncRequestIsLoading(salesChannelDataRequest)
                      ? ButtonState.Loading
                      : ButtonState.Enabled
                  }
                  label={<FormattedMessage id={I18nKey.GENERIC_LOAD_MORE} />}
                  size={ButtonSize.Large}
                  variant={ButtonVariant.Primary}
                  onClick={getMerchantCountriesSyncData}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

SalesChannelSyncStatus.displayName = 'SalesChannelSyncStatus';
