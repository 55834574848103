import {
  Alignment,
  BadgeV2Types,
  ColumnAlign,
  KeywordRoutingTagBadge,
  KeywordRoutingType,
  MultiSelectOption,
  Placement,
  TruncatedText,
  createMultiSelectDataFieldFilter,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';
import isNil from 'lodash/isNil';
import { FlywheelTableV2ColumnProps } from '../../../../../containers/tableV2/types';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  KeywordActionColumns,
  KeywordRecommendationsData,
  SelectKeywordTagOption,
  TargetSegments,
} from '../../../../../lib/types/KeywordAction';

export const Cell: React.FC<KeywordRecommendationsData> = ({
  keyword: { text },
  suggestedDestination: { adGroup },
}) => {
  return (
    <div className="flex py-8 items-center justify-start gap-8">
      <KeywordRoutingTagBadge
        badgeType={BadgeV2Types.KeywordRoutingTags}
        keywordRoutingTypes={getKeywordTargetingTypes(adGroup?.targetSegments)}
        tooltipPosition={{
          placement: Placement.Right,
          alignment: Alignment.Center,
        }}
      />
      <TruncatedText text={text} customLineClamp={2} />
    </div>
  );
};
Cell.displayName = 'KWACellKeywordRecommendation';

export const getKeywordTargetingTypes = (
  targetSegments?: string[]
): KeywordRoutingType[] => {
  const keywordRoutingTypes: KeywordRoutingType[] = [];
  if (targetSegments?.includes(TargetSegments.Auto)) {
    keywordRoutingTypes.push(KeywordRoutingType.Auto);
  }
  if (targetSegments?.includes(TargetSegments.Brand)) {
    keywordRoutingTypes.push(KeywordRoutingType.Brand);
  }
  if (
    targetSegments?.includes(TargetSegments.Generic) ||
    isNil(targetSegments) ||
    targetSegments?.length === 0
  ) {
    keywordRoutingTypes.push(KeywordRoutingType.Generic);
  }
  if (targetSegments?.includes(TargetSegments.Competitor)) {
    keywordRoutingTypes.push(KeywordRoutingType.Competitor);
  }
  return keywordRoutingTypes;
};

export const keywordRecommendation: FlywheelTableV2ColumnProps<
  KeywordRecommendationsData,
  {},
  {},
  {}
> = {
  header: I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_KEYWORD_TO_ADD_HEADER,
  accessorKey: KeywordActionColumns.KEYWORD,
  sortable: true,
  size: 300,
  minSize: 300,
  maxSize: 300,
  align: ColumnAlign.Left,
  cell: ({ row: { original } }) => <Cell {...original} />,
};

export const keywordRecommendationFilter = createStringDataFieldFilter(
  KeywordActionColumns.KEYWORD,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_KEYWORD_RECOMMENDATION_HEADER,
  isValidString()
);

const getKeywordTagOptions = (): MultiSelectOption[] => [
  SelectKeywordTagOption.Brand,
  SelectKeywordTagOption.Competitor,
  SelectKeywordTagOption.Generic,
  SelectKeywordTagOption.Unknown,
];

export const keywordTagFilter = createMultiSelectDataFieldFilter(
  KeywordActionColumns.KEYWORD_TAG,
  I18nKey.KEYWORD_RECOMMENDATIONS_TABLE_KEYWORD_TAG_HEADER,
  getKeywordTagOptions()
);
