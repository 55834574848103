import { DateTime, Interval } from 'luxon';

import { IdToken } from '@auth0/auth0-react';

import { PRODUCT_SUBSCRIPTIONS } from '../../lib/types/AOSharedTypes';
import {
  BillingBalanceResponse,
  BillingEstimateResponseType,
  PaymentPlanType,
} from '../../lib/types/Billing';
import {
  Account,
  ProductVertical,
  Role,
  SubscriptionPermission,
  UserDetails,
} from '../../lib/types/Fam';
import I18nKey from '../../lib/types/I18nKey';
import { UserContextState } from './userProvider';
import { FWCookie, PERSISTED_KEYS } from '../../lib/utilities/fwCookie';
import { isNotUndefined } from '../../lib/utilities/typeGuards';
import { formatDateRange } from '../../lib/utilities/billingDateRange';

const TEIKAMETRICS_DOMAIN_IDENTIFIER = '@teikametrics.com';

export const getCurrentAccountFromContext = (userContext: UserContextState) => {
  return getCurrentAccountPermissions(userContext.userInfo.userDetails)
    ?.account;
};

export const getCurrentAccountPermissions = (userDetails?: UserDetails) => {
  if (userDetails?.accountPermissions.length === 1) {
    return userDetails?.accountPermissions[0];
  }
  // TODO: change to comparing with userContext.userInfo.currentAccountId
  // https://teikametrics.atlassian.net/browse/PS-438
  const cookie = FWCookie.readCookie(PERSISTED_KEYS.CURRENT_ACCOUNT_ID);
  return (
    userDetails?.accountPermissions.find(
      (item) => item.account.id === cookie
    ) || userDetails?.accountPermissions[0]
  );
};

export const getCurrentAccount = (userContext: UserContextState) => {
  return userContext.userInfo.userDetails?.accountPermissions.find(
    (ap) => ap.account.id === userContext.userInfo.currentAccountId
  )?.account;
};

export const getCurrentAccountParentAccount = (
  userContext: UserContextState
) => {
  const currentAccount = getCurrentAccount(userContext);
  return userContext.userInfo.userDetails?.accountPermissions.find(
    (ap) => ap.account.id === currentAccount?.parentId
  )?.account;
};

export const isTeikametricsUser = (userDetails: UserDetails) =>
  userDetails.email.endsWith(TEIKAMETRICS_DOMAIN_IDENTIFIER);

export const getCurrentAccountSubscriptions = (
  productVerticalName: string,
  userDetails: UserDetails
): ProductVertical | undefined => {
  return getCurrentAccountPermissions(
    userDetails
  )?.subscriptionPermissions.find(
    (subscription) =>
      subscription.subscription.productVertical.name === productVerticalName
  )?.subscription.productVertical;
};

export const getCurrentAccountSubscribedMerchantCountries = (
  userDetails: UserDetails,
  productVertical: PRODUCT_SUBSCRIPTIONS
): string[] => {
  const currentAccountPermissions = getCurrentAccountPermissions(userDetails);
  if (userDetails && currentAccountPermissions) {
    const currentAiSubscriptions =
      currentAccountPermissions.subscriptionPermissions.find(
        (permission: SubscriptionPermission) =>
          permission.subscription.productVertical.name === productVertical
      );

    if (isNotUndefined(currentAiSubscriptions)) {
      return currentAiSubscriptions.merchantCountries;
    }
  }
  return [];
};

export const isProductSubscribed = (
  productVerticalName: ProductVertical['name'],
  userDetails: UserDetails
) =>
  getCurrentAccountSubscriptions(productVerticalName, userDetails)?.name ===
  productVerticalName;

export const isAOSubscribed = (
  userDetails?: UserDetails
): boolean | undefined => {
  const accountPermissions = getCurrentAccountPermissions(userDetails);
  const subscriptionPermissions = accountPermissions?.subscriptionPermissions;
  return subscriptionPermissions?.some(
    (sub) => sub.subscription.productVertical.name === PRODUCT_SUBSCRIPTIONS.AO
  );
};

export const getCurrentUserFullName = (userDetails?: UserDetails): string => {
  const firstName = userDetails?.firstName;
  const lastName = userDetails?.lastName;
  return `${firstName || ''} ${lastName || ''}`;
};

export const getSignUpData = (token: IdToken): SignUpData => {
  return token['https://flywheel.teikametrics.com/metadata'];
};

export interface SignUpData {
  first_name: string;
  last_name: string;
  business_name?: string;
  query?: string;
}

export enum SignUpMethod {
  UserPasswordAuthentication = 'user-password-authentication',
  GoogleOauth2 = 'google-oauth2',
}

export const getSignUpMethod = (token: IdToken) => {
  const identities = token['https://flywheel.teikametrics.com/identities'];
  if (!identities || !Array.isArray(identities) || identities.length === 0) {
    return null;
  }

  const identity = identities[0];

  if (identity?.connection === 'Username-Password-Authentication') {
    return SignUpMethod.UserPasswordAuthentication;
  }

  if (identity?.connection === 'google-oauth2') {
    return SignUpMethod.GoogleOauth2;
  }

  return null;
};

export const hasStartedTrial = (account?: Account): boolean => {
  return !!account?.freeTrialStartedAt;
};

export const isInTrial = (account?: Account) => {
  const trialDaysLeft = getFreeTrialDaysRemaining(account);
  return !!trialDaysLeft && trialDaysLeft > 0;
};

export const getOutstandingBalanceDateRange = (
  balance: BillingBalanceResponse | undefined
) => {
  if (!balance) {
    return null;
  }
  return formatDateRange(
    DateTime.fromISO(balance.startDate),
    DateTime.fromISO(balance.endDate),
    'MMM d'
  );
};

export const getGracePeriodDate = (userContext?: UserContextState) => {
  if (!userContext?.billingInfo?.delinquentSince) {
    return null;
  }
  return DateTime.fromISO(userContext?.billingInfo?.delinquentSince)
    .plus({ days: 7 })
    .toFormat('MMM d');
};

export const firstDayOfFreeTrial = (account?: Account) => {
  if (!account || !account.freeTrialStartedAt) {
    return false;
  }

  const freeTrialStartedAt = DateTime.fromISO(account.freeTrialStartedAt);

  if (!freeTrialStartedAt.isValid) {
    return false;
  }

  return (
    freeTrialStartedAt.toFormat('yyyy-MM-dd') ===
    DateTime.local().toFormat('yyyy-MM-dd')
  );
};

export const getDaysFromTrialEndUntilNextBilling = (
  account?: Account,
  nextBillingDate?: string
) => {
  if (!account || !account.freeTrialEndsAt || !nextBillingDate) {
    return 0;
  }

  const trialEndedAt = DateTime.fromISO(account.freeTrialEndsAt);
  const nextBilling = DateTime.fromISO(nextBillingDate);

  if (!trialEndedAt.isValid || !nextBilling.isValid) {
    return 0;
  }

  const days = Math.floor(trialEndedAt.until(nextBilling).length('days'));

  if (isNaN(days)) {
    return 0;
  }

  return days;
};

export const getDaysSinceTrialEnded = (account?: Account) => {
  if (!account || !account.freeTrialEndsAt) {
    return 0;
  }

  const days = Math.floor(
    (
      DateTime.fromISO(account.freeTrialEndsAt).until(
        DateTime.local()
      ) as Interval<true>
    ).length('days')
  );

  if (isNaN(days) || days < 0) {
    return 0;
  }

  return days;
};

export const getFreeTrialDaysRemaining = (account?: Account) => {
  if (!account) {
    return 0;
  }

  const trialExpiration = account.freeTrialEndsAt;
  if (!trialExpiration) {
    return 0;
  }

  const daysLeft = Math.ceil(
    DateTime.local().until(DateTime.fromISO(trialExpiration)).length('days')
  );

  // NaN is returned if free trial has already elapsed
  if (isNaN(daysLeft)) {
    return 0;
  }
  return Math.min(30, daysLeft);
};

export const getBillingDaysRemaining = (
  billingEstimate: BillingEstimateResponseType | null
) => {
  if (!billingEstimate?.endDate) {
    return 0;
  }

  const daysLeft = Math.ceil(
    DateTime.local()
      .until(DateTime.fromISO(billingEstimate.endDate))
      .length('days') + 1
  );

  return daysLeft;
};

export const mapRoleToI18nKey = (userRole: Role): I18nKey => {
  return {
    [Role.ACCOUNT_OWNER]: I18nKey.ACCOUNT_OWNER,
    [Role.ADMIN]: I18nKey.ADMIN,
    [Role.ANALYST]: I18nKey.ANALYST,
    [Role.EDITOR]: I18nKey.EDITOR,
    [Role.VIEW_ONLY]: I18nKey.VIEW_ONLY,
  }[userRole];
};

export const isAIPlanEnabled = (userContext: UserContextState) =>
  userContext.userInfo.userDetails
    ? isProductSubscribed(
        PRODUCT_SUBSCRIPTIONS.AI,
        userContext.userInfo.userDetails
      )
    : false;

export const isManagedAccount = (userContext?: UserContextState) => {
  if (!userContext) {
    return false;
  }
  const account = getCurrentAccountFromContext(userContext);
  return account?.accountType === PaymentPlanType.Managed;
};

export const isAiSubscribed = (userContext?: UserContextState) => {
  return !!getCurrentAccountSubscriptions(
    PRODUCT_SUBSCRIPTIONS.AI,
    userContext?.userInfo.userDetails!
  )?.id;
};

export const showTrial = (userContext?: UserContextState) => {
  const account = getCurrentAccountFromContext(userContext!);

  return (
    !isManagedAccount(userContext) &&
    isInTrial(account) &&
    isAiSubscribed(userContext)
  );
};

export const showTrialEndedPaymentNeeded = (userContext?: UserContextState) => {
  const currentAccount = getCurrentAccountFromContext(userContext!);

  return (
    currentAccount?.accountType === PaymentPlanType.SelfService &&
    hasStartedTrial(currentAccount) &&
    getFreeTrialDaysRemaining(currentAccount) === 0 &&
    !hasPaymentInfo(userContext)
  );
};

export const showTrialEnded = (userContext?: UserContextState) => {
  const currentAccount = getCurrentAccountFromContext(userContext!);

  return (
    currentAccount?.accountType === PaymentPlanType.SelfService &&
    hasStartedTrial(currentAccount) &&
    getFreeTrialDaysRemaining(currentAccount) === 0
  );
};

export const isAccountEnforced = (userContext?: UserContextState) => {
  const currentAccount = getCurrentAccountFromContext(userContext!);
  return !!currentAccount?.enforcedAt;
};

export const hasPaymentInfo = (userContext?: UserContextState): boolean => {
  return userContext?.billingInfo?.hasPayment ?? false;
};

export const isLegacySelfServiceAccount = (
  userContext?: UserContextState
): boolean => {
  const currentAccount = getCurrentAccountFromContext(userContext!);
  return currentAccount?.accountType === PaymentPlanType.LegacySelfService;
};

/**
 * @param userContext
 * @returns boolean
 *
 * Logic for showing the paymentReminderCard for an account is
 * 1. should be a self-service account
 * 2. shouldn't have payment info
 * 3. Time to end the free trial is within 10days
 */
export const shouldShowAddPaymentReminderCard = (
  userContext?: UserContextState
): boolean => {
  if (!userContext) {
    return false;
  }
  const hasGivenPayment = hasPaymentInfo(userContext);
  const isManaged = isManagedAccount(userContext);
  return !hasGivenPayment && !isManaged;
};

/**
 *
 * @param userContext
 * @returns boolean
 *
 * Returns to show switch plan modal or upgrade plan modal
 */
export const showSwitchPlanModal = (
  userContext?: UserContextState
): boolean => {
  const currentAccount = getCurrentAccountFromContext(userContext!);
  return (
    !isManagedAccount(userContext) &&
    (!currentAccount?.freeTrialStartedAt || isInTrial(currentAccount)) &&
    !isAIPlanEnabled(userContext!)
  );
};

export const isSelfServiceAccount = (currentAccount?: Account): boolean => {
  return (
    currentAccount?.accountType === PaymentPlanType.SelfService ||
    currentAccount?.accountType === PaymentPlanType.LegacySelfService
  );
};

export const isManagedOrAgencyAccount = (currentAccount?: Account): boolean => {
  return (
    currentAccount?.accountType === PaymentPlanType.Managed ||
    currentAccount?.accountType === PaymentPlanType.Agency ||
    currentAccount?.accountType === PaymentPlanType.AgencyClient
  );
};

export const isAgencyAccount = (currentAccount?: Account): boolean => {
  return currentAccount?.accountType === PaymentPlanType.Agency;
};

export const isAgencyClientAccount = (currentAccount?: Account): boolean => {
  return currentAccount?.accountType === PaymentPlanType.AgencyClient;
};

export const getUserRoleMatch = (role: Role, userDetails?: UserDetails) => {
  const currentAccountPermissions = getCurrentAccountPermissions(userDetails);
  return role === currentAccountPermissions?.role;
};
