import Axios, { AxiosError, AxiosInstance } from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import React, { useContext, useEffect } from 'react';

import {
  ClientError,
  DEFAULT_RETRY,
  DEFAULT_RETRY_INTERVAL,
} from '../../lib/types/HttpErrorCodes';
import { handleErrorResponse } from '../../lib/utilities/errorHandler';
import { useNavigate } from 'react-router-dom';

export const dummyAxiosInstance = undefined as unknown as AxiosInstance;

export const AxiosContext =
  React.createContext<AxiosInstance>(dummyAxiosInstance);

export function AxiosProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();

  useEffect(() => {
    Axios.interceptors.response.use(
      (res) => res,
      (error: AxiosError) => {
        if (error.response?.status === ClientError.HTTP_401_UNAUTHORIZED) {
          navigate('/logout');
        }
        return Promise.reject(error);
      }
    );
  }, []);
  const axios = React.useMemo(() => {
    const axiosInstance = Axios.create();

    axiosRetry(axiosInstance, {
      retries: DEFAULT_RETRY,
      retryDelay: (retryCount: number) => retryCount * DEFAULT_RETRY_INTERVAL,
      retryCondition: (error: AxiosError) => {
        if (
          isNetworkOrIdempotentRequestError(error) ||
          error?.response?.status === ClientError.HTTP_429_TOO_MANY_REQUESTS ||
          error?.response?.status === ClientError.HTTP_503_SERVICE_UNAVAILABLE
        ) {
          return true;
        } else {
          handleErrorResponse(error as any);
          return false;
        }
      },
    });

    return axiosInstance;
  }, []);

  return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
}
AxiosProvider.displayName = 'AxiosProvider';
export const useAxios = () => useContext(AxiosContext);
