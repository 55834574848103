import { DateTime } from 'luxon';

import {
  ComparisonTrend,
  MetricCardsv2Status,
} from '@teikametrics/tm-design-system';

import { HomePageDataRequest } from '../../lib/types/CompassSharedTypes';
import { MoneyWithAmountInString } from '../../lib/types/Money';

export const INSIGHTS_TABLE_ID = 'INSIGHTS_TABLE_ID';
export const MAX_ROWS_IN_INSIGHT_TABLE = 5;
export const DEFAULT_MAX_FRACTION_DIGITS = 2;
export const PERCENTAGE_DECIMAL_PLACE_LIMIT = 1;
export const NUMERIC_DECIMAL_PLACE_LIMIT = 1;
export const NO_DECIMAL_PLACES = 0;
export const COMPASS_API_REQUEST_DATE_FORMAT = 'yyyy-MM-dd';

export enum MetricType {
  Money,
  Percent,
  Numeric,
}

export enum CompassState {
  Loading = 'Loading',
  Error = 'Error',
  Success = 'Success',
  NoData = 'NoData',
  EmptyMerchants = 'EmptyMerchants',
  NoMerchantsSelected = 'NoMerchantsSelected',
}

export type Metric = number | MoneyWithAmountInString;

export const METRIC_TYPE_TO_DECIMAL_PLACE_LIMIT: Record<MetricType, number> = {
  [MetricType.Money]: DEFAULT_MAX_FRACTION_DIGITS,
  [MetricType.Percent]: PERCENTAGE_DECIMAL_PLACE_LIMIT,
  [MetricType.Numeric]: NUMERIC_DECIMAL_PLACE_LIMIT,
};

export interface MetricTab {
  readonly pillText: string;
  readonly comparisonTrend: MetricCardsv2Status | ComparisonTrend;
  readonly currentValue: string | null;
  readonly previousValue: string | null;
}

export enum MetricTabsName {
  totalSales = 'totalSales',
  adSales = 'adSales',
  adSpend = 'adSpend',
  tacos = 'tacos',
  roas = 'roas',
}

export interface MetricTabsData {
  readonly [MetricTabsName.totalSales]: MetricTab;
  readonly [MetricTabsName.adSales]: MetricTab;
  readonly [MetricTabsName.adSpend]: MetricTab;
  readonly [MetricTabsName.tacos]: MetricTab;
  readonly [MetricTabsName.roas]?: MetricTab;
}

export interface ChartConfiguration {
  readonly activatedAiDataPoints?: number[];
  readonly deactivatedAiDataPoints?: number[];
}

export interface DateRange {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
  readonly minDate?: DateTime;
  readonly maxDate?: DateTime;
}

export enum ChartTrendMetricFormatSelector {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export enum KpiMetric {
  TotalSales = 'Total sales',
  AdSales = 'Ad sales',
  AdSpend = 'Ad spend',
  TACoS = 'TACoS',
  Roas = 'roas',
}

export const MAP_CHART_TREND_METRIC_FORMAT_SELECTOR_TO_DATE_TIME_READABLE_FORMAT_SELECTOR: Record<
  ChartTrendMetricFormatSelector,
  string
> = {
  [ChartTrendMetricFormatSelector.Day]: 'MMM dd, yyyy',
  [ChartTrendMetricFormatSelector.Week]: 'MMM dd',
  [ChartTrendMetricFormatSelector.Month]: 'MMM,yyyy',
};

export const ORIGINAL_TREND_DATE_FORMAT = 'yyyy-MM-dd';

export interface SalesChannelsWithMerchantCountries {
  [salesChannelId: string]: string[];
}

export interface MerchantCountriesWithCountryCodes {
  [merchantCountryId: string]: string;
}

export interface HomePageDataRequestExceptMetricAndPerformance
  extends Omit<
    HomePageDataRequest,
    'metricsPeriodType' | 'performanceFields'
  > {}
