import I18nKey from '../../../lib/types/I18nKey';
import { FormattedMessage, IntlShape } from 'react-intl';
import { MetricsData } from './ManageMetricsSlideover';
import {
  AOKPIMetrics,
  DecimalNumericColumn,
  HeroMetric,
  HeroMetricType,
  HeroMetricsData,
  Metric,
} from '../../../lib/types/AOSharedTypes';
import isNil from 'lodash/isNil';
import { convertAmountToNumber } from '../../compass/utils';
import { DateTime } from 'luxon';
import {
  Formula,
  FormulaType,
  MetricCardV2CardTrend,
  MetricCardV2Status,
} from '@teikametrics/tm-design-system';
import { isDatelessThanGivenDate } from '../containers/adsManager/utils';
import { DEFAULT_MAX_METRICS_VISIBLE } from '../containers/adsManager/types';
import { SlideoutProductMetrics } from '../../../lib/types/SKUSharedTypes';
import {
  MONETARY_FRACTION_DIGITS,
  NO_FRACTION_DIGITS,
  PERCENTAGE_FRACTION_DIGITS,
} from '../../../lib/types/CommonSharedTypes';
import { MoneyWithAmountInString } from '../../../lib/types/Money';

export const getDefaultMetricsConfigurationAO = (
  intl: IntlShape
): MetricsData[] => {
  const [
    ACOS,
    AD_SALES,
    AD_CONVERSIONS,
    AD_SPEND,
    CLICKS,
    CLICK_THROUGH_RATE,
    COST_PER_CLICK,
    CONVERSION_RATE,
    IMPRESSIONS,
    ROAS,
    UNITS_SOLD,
  ] = [
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ACOS,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADSALES,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_AD_CONVERSIONS,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ADSPEND,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CLICKS,
    I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_CLICK_THROUGH_RATE,
    I18nKey.COMPASS_CUSTOMIZE_METRICS_SLIDEOVER_COST_PER_CLICK,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_CONVERSION_RATE,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_IMPRESSIONS,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_ROAS,
    I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_AD_UNITS_SOLD,
  ].map((id) => intl.formatMessage({ id }));

  const commonMetrics = [
    { isChecked: false, label: ACOS, metricsId: AOKPIMetrics.ACoS },
    { isChecked: true, label: AD_SALES, metricsId: AOKPIMetrics.AdSales },
    {
      isChecked: true,
      label: AD_CONVERSIONS,
      metricsId: AOKPIMetrics.AdConversions,
    },
    { isChecked: true, label: AD_SPEND, metricsId: AOKPIMetrics.AdSpend },
    { isChecked: false, label: CLICKS, metricsId: AOKPIMetrics.Clicks },
    {
      isChecked: false,
      label: CLICK_THROUGH_RATE,
      metricsId: AOKPIMetrics.ClickThroughRate,
    },
    {
      isChecked: false,
      label: COST_PER_CLICK,
      metricsId: AOKPIMetrics.CostPerClick,
    },
    {
      isChecked: true,
      label: CONVERSION_RATE,
      metricsId: AOKPIMetrics.ConversionRate,
    },
    {
      isChecked: false,
      label: IMPRESSIONS,
      metricsId: AOKPIMetrics.Impressions,
    },
    { isChecked: true, label: ROAS, metricsId: AOKPIMetrics.ROAS },
    { isChecked: false, label: UNITS_SOLD, metricsId: AOKPIMetrics.UnitsSold },
  ];

  return [...commonMetrics];
};

export const hasExactlyMaxCheckedItems = (arr: MetricsData[]) =>
  arr.filter((metric) => metric.isChecked).length ===
  DEFAULT_MAX_METRICS_VISIBLE;

export const oneToMaxMetricsChecked = (arr: MetricsData[]) => {
  const checkedMetricsLength = arr.filter((metric) => metric.isChecked).length;
  return (
    checkedMetricsLength <= DEFAULT_MAX_METRICS_VISIBLE &&
    checkedMetricsLength > 0
  );
};

export const getAOKPIMetricValue = (
  metricData: HeroMetric,
  metricType: AOKPIMetrics
): Metric | undefined => {
  switch (metricType) {
    case AOKPIMetrics.ACoS:
      return metricData.acosTotal;
    case AOKPIMetrics.ACoSDirect:
      return metricData.acosDirect;
    case AOKPIMetrics.AdSales:
      return metricData.adSalesTotal;
    case AOKPIMetrics.AdSalesDirect:
      return metricData.adSalesDirect;
    case AOKPIMetrics.AdSpend:
      return metricData.adSpend;
    case AOKPIMetrics.AdConversions:
      return metricData.adConversions;
    case AOKPIMetrics.ConversionRate:
      return metricData.conversionRate;
    case AOKPIMetrics.Clicks:
      return metricData.clicks;
    case AOKPIMetrics.ClickThroughRate:
      return metricData.clickThroughRate;
    case AOKPIMetrics.CostPerClick:
      return metricData.avgCostPerClick;
    case AOKPIMetrics.Impressions:
      return metricData.impressions;
    case AOKPIMetrics.ROAS:
      return metricData.roasTotal;
    case AOKPIMetrics.ROASDirect:
      return metricData.roasDirect;
    case AOKPIMetrics.UnitsSold:
      return metricData.unitsSold;
  }
};

export const getHeroMetricType = (metricType: AOKPIMetrics): HeroMetricType => {
  switch (metricType) {
    case AOKPIMetrics.AdSales:
    case AOKPIMetrics.AdSalesDirect:
    case AOKPIMetrics.AdSpend:
    case AOKPIMetrics.CostPerClick:
      return HeroMetricType.Money;
    case AOKPIMetrics.ACoS:
    case AOKPIMetrics.ACoSDirect:
    case AOKPIMetrics.ConversionRate:
    case AOKPIMetrics.ClickThroughRate:
      return HeroMetricType.Percent;
    case AOKPIMetrics.AdConversions:
    case AOKPIMetrics.Clicks:
    case AOKPIMetrics.Impressions:
    case AOKPIMetrics.UnitsSold:
    case AOKPIMetrics.ROAS:
    case AOKPIMetrics.ROASDirect:
    default:
      return HeroMetricType.Numeric;
  }
};

export const getMetricValueFractions = (
  metricType: AOKPIMetrics
): HeroMetricType => {
  switch (metricType) {
    case AOKPIMetrics.AdSales:
    case AOKPIMetrics.AdSalesDirect:
    case AOKPIMetrics.AdSpend:
    case AOKPIMetrics.CostPerClick:
      return MONETARY_FRACTION_DIGITS;
    case AOKPIMetrics.ConversionRate:
    case AOKPIMetrics.ACoS:
    case AOKPIMetrics.ACoSDirect:
    case AOKPIMetrics.ROAS:
    case AOKPIMetrics.ROASDirect:
    case AOKPIMetrics.ClickThroughRate:
      return PERCENTAGE_FRACTION_DIGITS;
    case AOKPIMetrics.AdConversions:
    case AOKPIMetrics.Clicks:
    case AOKPIMetrics.Impressions:
    case AOKPIMetrics.UnitsSold:
    default:
      return NO_FRACTION_DIGITS;
  }
};

export const getCurrentValueContent = (
  intl: IntlShape,
  heroMetricType: HeroMetricType,
  metricName: AOKPIMetrics | SlideoutProductMetrics,
  currentValue?: Metric,
  currencyCode?: string | null
): string => {
  if (!isNil(currentValue)) {
    switch (heroMetricType) {
      case HeroMetricType.Money:
        const amount = convertAmountToNumber(currentValue);
        return intl.formatNumber(amount, {
          style: 'currency',
          currency:
            currencyCode || (currentValue as MoneyWithAmountInString).currency,
          minimumFractionDigits: MONETARY_FRACTION_DIGITS,
          maximumFractionDigits: MONETARY_FRACTION_DIGITS,
        });
      case HeroMetricType.Numeric:
        return intl.formatNumber(currentValue as number, {
          minimumFractionDigits: Object.keys(DecimalNumericColumn).includes(
            metricName
          )
            ? PERCENTAGE_FRACTION_DIGITS
            : 0,
          maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        });
      case HeroMetricType.Percent:
        return intl.formatNumber(currentValue as number, {
          style: 'percent',
          minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
          maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
        });
      default:
        return '';
    }
  } else {
    return intl.formatMessage({
      id: I18nKey.GENERIC_EM_DASH,
    });
  }
};

export const getPillText = (intl: IntlShape, delta: number): string => {
  return `${intl.formatNumber(delta < 0 ? -1 * delta : delta, {
    style: 'percent',
    minimumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
    maximumFractionDigits: PERCENTAGE_FRACTION_DIGITS,
  })}`;
};

export const getStatus = (delta: number): MetricCardV2Status => {
  if (isNaN(delta)) {
    return MetricCardV2Status.Negative;
  }
  if (delta === 0) {
    return MetricCardV2Status.Neutral;
  }
  return delta < 0 ? MetricCardV2Status.Negative : MetricCardV2Status.Positive;
};

export const calculateDelta = (
  heroMetricType: HeroMetricType,
  metricType: AOKPIMetrics,
  currentValue?: Metric,
  previousValue?: Metric
): number => {
  const maxDecimalPlaces = getMetricValueFractions(metricType);
  if (isNil(previousValue) || isNil(currentValue)) return 0;
  let currentNumericValue = -1;
  let previousNumericValue = -1;
  if (heroMetricType !== HeroMetricType.Money) {
    currentNumericValue = currentValue as number;
    previousNumericValue = previousValue as number;
  }
  switch (heroMetricType) {
    case HeroMetricType.Money:
      const previousAmount = convertAmountToNumber(previousValue);
      const divideBy = previousAmount === 0 ? 1 : previousAmount;

      return (convertAmountToNumber(currentValue) - previousAmount) / divideBy;
    case HeroMetricType.Percent:
      return (
        (Number((currentNumericValue * 100).toFixed(maxDecimalPlaces)) -
          Number((previousNumericValue * 100).toFixed(maxDecimalPlaces))) /
        (Number((previousNumericValue * 100).toFixed(maxDecimalPlaces)) === 0
          ? 1
          : Number((previousNumericValue * 100).toFixed(maxDecimalPlaces)))
      );
    case HeroMetricType.Numeric:
    default:
      return (
        (Number(currentNumericValue.toFixed(maxDecimalPlaces)) -
          Number(previousNumericValue.toFixed(maxDecimalPlaces))) /
        (Number(previousNumericValue.toFixed(maxDecimalPlaces)) === 0
          ? 1
          : Number(previousNumericValue.toFixed(maxDecimalPlaces)))
      );
  }
};

export const getCardFooterProps = (
  intl: IntlShape,
  heroMetricType: HeroMetricType,
  metricName: AOKPIMetrics,
  currentValue: Metric | undefined,
  previousValue: Metric | undefined,
  prevMetricResult: HeroMetricsData | false,
  minDate: DateTime
): MetricCardV2CardTrend => {
  let isPreviousMetricStartDateLessThanDataAvailibilityMinDate = false;
  if (prevMetricResult) {
    isPreviousMetricStartDateLessThanDataAvailibilityMinDate =
      isDatelessThanGivenDate(
        DateTime.fromISO(prevMetricResult.performanceMetrics.range.endDate),
        minDate
      );
  }

  if (
    isNil(previousValue) ||
    isPreviousMetricStartDateLessThanDataAvailibilityMinDate
  ) {
    return {
      pillText: undefined,
      status: undefined,
    };
  }

  return {
    pillText: getPillText(
      intl,
      calculateDelta(heroMetricType, metricName, currentValue, previousValue)
    ),
    status: getStatus(
      calculateDelta(heroMetricType, metricName, currentValue, previousValue)
    ),
  };
};

export const getTooltipContentForMetric = (
  metricId: AOKPIMetrics | SlideoutProductMetrics
) => {
  const descriptionId = getCardPopupDescription(metricId);
  const formula = getCardPopupFormula(metricId);
  return descriptionId ? (
    <>
      <div className="leading-tight">
        <FormattedMessage id={descriptionId} />
      </div>
      {formula && (
        <div className="mt-8 flex flex-wrap rounded py-4 px-8 bg-grey-800">
          <FormattedMessage
            id={formula}
            values={{
              br: <div className="h-32"></div>,
              multiply: <Formula type={FormulaType.Multiply} />,
              divide: <Formula type={FormulaType.Divide} />,
              plus: <Formula type={FormulaType.Add} />,
              minus: <Formula type={FormulaType.Subtract} />,
              openParentheses: (
                <span className="text-grey-500 mx-4">{'('}</span>
              ),
              closeParentheses: (
                <span className="text-grey-500 mx-4">{')'}</span>
              ),
            }}
          />
        </div>
      )}
    </>
  ) : undefined;
};

export const getCardPopupFormula = (
  metricType: AOKPIMetrics | SlideoutProductMetrics
): I18nKey | undefined => {
  switch (metricType) {
    case SlideoutProductMetrics.AdSales:
    case AOKPIMetrics.AdSales:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_AD_SALES;
    case AOKPIMetrics.AdSpend:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_AD_SPEND;
    case SlideoutProductMetrics.ACoS:
    case AOKPIMetrics.ACoS:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_ACOS;
    case AOKPIMetrics.AdConversions:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_AD_CONVERSIONS;
    case AOKPIMetrics.Clicks:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_CLICKS;
    case AOKPIMetrics.ClickThroughRate:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_CLICK_THROUGH_RATE;
    case AOKPIMetrics.ConversionRate:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_CONVERSION_RATE;
    case AOKPIMetrics.CostPerClick:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_CPC;
    case AOKPIMetrics.Impressions:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_IMPRESSIONS;
    case SlideoutProductMetrics.ROAS:
    case AOKPIMetrics.ROAS:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_ROAS;
    case AOKPIMetrics.TotalSales:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_TOTAL_SALES;
    case SlideoutProductMetrics.AdUnitsSold:
    case SlideoutProductMetrics.UnitsSold:
    case AOKPIMetrics.UnitsSold:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_UNITS_SOLD;
    case AOKPIMetrics.ACoSDirect:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_DIRECT_ACOS;
    case AOKPIMetrics.AdSalesDirect:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_DIRECT_AD_SALES;
    case AOKPIMetrics.ROASDirect:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_DIRECT_ROAS;
    case SlideoutProductMetrics.TACoS:
      return I18nKey.MANAGE_METRICS_FIELD_FORMULA_TACOS;
    case SlideoutProductMetrics.AggregateCOGS:
      return I18nKey.PRODUCTS_GLOSSARY_COGS_FORMULA;
    case SlideoutProductMetrics.EstGrossProfit:
      return I18nKey.PRODUCTS_GLOSSARY_ESTIMATED_GROSS_PROFIT_FORMULA;
    case SlideoutProductMetrics.InventoryAvailableQuantity:
      return I18nKey.PRODUCTS_GLOSSARY_CURRENT_INVENTORY_QUANTITY_FORMULA;
    default:
      return undefined;
  }
};

export const getCardPopupDescription = (
  metricType: AOKPIMetrics | SlideoutProductMetrics
): I18nKey | undefined => {
  switch (metricType) {
    case SlideoutProductMetrics.AdSales:
    case AOKPIMetrics.AdSales:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_AD_SALES;
    case AOKPIMetrics.AdSpend:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_AD_SPEND;
    case SlideoutProductMetrics.ACoS:
    case AOKPIMetrics.ACoS:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_ACOS;
    case AOKPIMetrics.AdConversions:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_AD_CONVERSIONS;
    case AOKPIMetrics.Clicks:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_CLICKS;
    case AOKPIMetrics.ClickThroughRate:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_CLICK_THROUGH_RATE;
    case AOKPIMetrics.ConversionRate:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_CONVERSION_RATE;
    case AOKPIMetrics.CostPerClick:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_CPC;
    case AOKPIMetrics.Impressions:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_IMPRESSIONS;
    case SlideoutProductMetrics.ROAS:
    case AOKPIMetrics.ROAS:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_ROAS;
    case AOKPIMetrics.TotalSales:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_TOTAL_SALES;
    case SlideoutProductMetrics.AdUnitsSold:
    case AOKPIMetrics.UnitsSold:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_AD_UNITS_SOLD;
    case SlideoutProductMetrics.UnitsSold:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_TOTAL_UNITS_SOLD;
    case AOKPIMetrics.ACoSDirect:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_DIRECT_ACOS;
    case AOKPIMetrics.AdSalesDirect:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_DIRECT_AD_SALES;
    case AOKPIMetrics.ROASDirect:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_DIRECT_ROAS;
    case SlideoutProductMetrics.TACoS:
      return I18nKey.MANAGE_METRICS_FIELD_DEFINITION_TACOS;
    case SlideoutProductMetrics.AggregateCOGS:
      return I18nKey.PRODUCTS_GLOSSARY_COGS;
    case SlideoutProductMetrics.EstGrossProfit:
      return I18nKey.PRODUCTS_GLOSSARY_ESTIMATED_GROSS_PROFIT;
    case SlideoutProductMetrics.InventoryAvailableQuantity:
      return I18nKey.PRODUCTS_GLOSSARY_CURRENT_INVENTORY_QUANTITY;
    case SlideoutProductMetrics.DaysOfInventory:
      return I18nKey.INVENTORY_DASHBOARD_GLOSSARY_DAYS_OF_INVENTORY_DEFINATION;
    default:
      return undefined;
  }
};

export function toTitleCase(str?: string) {
  return str?.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const getUpdatedMetricsConfig = (
  intl: IntlShape,
  config?: MetricsData[] | null
): MetricsData[] | undefined => {
  return config
    ? config.map((metric) => {
        if (metric.metricsId === AOKPIMetrics.UnitsSold) {
          return {
            ...metric,
            label: intl.formatMessage({
              id: I18nKey.ADS_MANAGER_AD_ITEMS_TABLE_AD_UNITS_SOLD,
            }),
          };
        }
        return { ...metric };
      })
    : undefined;
};

export const isHeroMetricsResultUndefined = (
  currentMetricData: HeroMetricsData
) => Object.keys(currentMetricData.performanceMetrics).length <= 1; // if data is unavailable we only receive 'range' in api response
