import debounce from 'lodash/debounce';
import { isUndefined } from 'lodash/fp';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createPercentDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeNumericInputColumn,
  makePercentColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  AmazonBidOptimization,
  CampaignDetails,
} from '../../../../../lib/types/AOSharedTypes';
import { PERCENTAGE_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../ducks/types';
import {
  AdLevelI8nKeyMapper,
  NumericValueConfig,
  TableDataAdsManager,
} from '../types';
import {
  CAMPAIGNS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getTooltipContentForArchivedEntity,
  isCampaignAdFormatProductCollectionOrSpotlight,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
  isInputValueNumber,
} from '../utils';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';

const DEFAULT_PLACEHOLDER_VALUE = 0;

const getExistingValue = (
  existingBelowTopOfSearchBidMultiplier: number | undefined
) =>
  (!isNil(existingBelowTopOfSearchBidMultiplier) &&
    ((existingBelowTopOfSearchBidMultiplier ?? 0) * 100).toString()) ||
  '';

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) => {
  const { isEditMode } = props;
  const [focussed, setFocussed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const bidConstraintsData = useBidConstraintsContext();

  const changedValue = useSelector<WithTable<CampaignDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        props.campaignId,
        CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier
      )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID)
  );

  const currentPage = useSelector<WithTable<CampaignDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      )
  );

  const changedAmazonBidOptimizationValue = useSelector<
    WithTable<CampaignDetails>,
    string
  >(({ tableState }) => {
    return tableSelectors.getCellSelector(
      props.campaignId,
      CAMPAIGNS_API_COLUMN_NAME.AmazonBidOptimization
    )(tableState, ADS_MANAGER_CAMPAIGNS_TABLE_ID);
  });

  const validationConfig = (() => {
    const constraints = getBidConstraint(
      bidConstraintsData.constraints,
      props.selectedAdType,
      props.salesChannel,
      CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier,
      props.merchantCountry
    );

    return {
      min: constraints.minBelowTopOfTheSearchBidMultiplier,
      max: constraints.maxBelowTopOfTheSearchBidMultiplier,
      default: constraints.defaultBelowTopOfTheSearchBidMultiplier,
    };
  })();

  const existingValue = getExistingValue(
    props.channelSettings.placementBidMultiplier?.belowTopOfSearchBidMultiplier
  );
  let belowTopOfTheSearchBidMultiplier = changedValue ?? existingValue;

  const [bidMultiplierValue, setBidMultiplierValue] = useState<string>(
    belowTopOfTheSearchBidMultiplier
  );

  const updateCellValue = (newValue: string) => {
    dispatch(
      tableActions.updateCell({
        columnName: CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier,
        rowId: props.campaignId,
        existingValue,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
        value: newValue,
        numericValue: true,
      })
    );
  };
  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [props.campaignId]
  );

  const onValueAndPageChange = () => {
    if (
      isEditMode || //Changing between modes
      (!isNil(changedValue) && changedValue !== bidMultiplierValue) // Changes done due to bulk update. changedValue is latest, but state variable is not
    ) {
      setBidMultiplierValue(belowTopOfTheSearchBidMultiplier);
    }
  };

  useEffect(onValueAndPageChange, [isEditMode, changedValue, currentPage]);

  useEffect(() => {
    if (changedAmazonBidOptimizationValue === AmazonBidOptimization.True) {
      dispatch(
        tableActions.updateCell({
          tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
          rowId: props.campaignId,
          columnName:
            CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier,
          existingValue,
          value: existingValue,
          numericValue: false,
        })
      );
    }
  }, [changedAmazonBidOptimizationValue]);

  if (
    isEditMode &&
    isCampaignAdFormatProductCollectionOrSpotlight(
      props.campaignDetails.campaignAdFormat
    )
  ) {
    const onNumericInputChange = (newValue: string) => {
      if (isInputValueNumber(newValue)) {
        const formattedInputValue = newValue.replaceAll(/[.]/g, '');
        setBidMultiplierValue(formattedInputValue);
        debouncedUpdateCellValue(formattedInputValue);
      }
    };

    const onInputFocus = () => setFocussed(true);
    const onInputBlur = () => setFocussed(false);

    const isBidOptimizationEnabled = getIsBidOptimizationEnabled(
      changedAmazonBidOptimizationValue,
      props.channelSettings.bidOptimization
    );

    const isCampaignArchived = isCampaignStatusArchived(
      props.channelSettings.status
    );

    const isInputInvalid: boolean = isInputBidInvalid(
      bidMultiplierValue,
      existingValue,
      validationConfig.max,
      validationConfig.min
    );

    const numericInputState = getNumericInputState(
      isBidOptimizationEnabled,
      isCampaignArchived,
      isInputInvalid
    );

    const controlledTooltipState: ControlledTooltip = getControlledTooltipState(
      isCampaignArchived,
      numericInputState,
      isBidOptimizationEnabled,
      focussed
    );

    const belowTopOfSearchBidMultiplierPlaceholder = intl.formatNumber(
      validationConfig.default ?? DEFAULT_PLACEHOLDER_VALUE
    );

    const belowTopOfSearchBidMultiplierNumericInputProps: NumericInputProps &
      TypedTooltipProps = {
      appendedElement: '%',
      dataTestId: CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier,
      value: bidMultiplierValue,
      onChange: onNumericInputChange,
      onInputFocus,
      onInputBlur,
      controlledTooltip: controlledTooltipState,
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      state: numericInputState,
      tooltipContent: getTooltipContent(
        isBidOptimizationEnabled,
        intl,
        isInputInvalid,
        validationConfig,
        isCampaignArchived
      ),
      isDirty: existingValue !== bidMultiplierValue,
      placeholder: belowTopOfSearchBidMultiplierPlaceholder,
      minFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      maxFractionDigits: PERCENTAGE_FRACTION_DIGITS,
      acceptOnlyIntegers: true,
    };

    return makeNumericInputColumn<CampaignDetails>(
      () => belowTopOfSearchBidMultiplierNumericInputProps
    )(props);
  }

  return makePercentColumn<CampaignDetails>(
    getBelowTopOfTheSearchBidMultiplierValue,
    props.pendingFields?.includes(
      CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier
    )
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

const getBelowTopOfTheSearchBidMultiplierValue = ({
  channelSettings: { placementBidMultiplier },
  campaignDetails: { campaignAdFormat },
}: CampaignDetails) => {
  if (!isCampaignAdFormatProductCollectionOrSpotlight(campaignAdFormat)) {
    return;
  }
  return !isNil(placementBidMultiplier?.belowTopOfSearchBidMultiplier)
    ? placementBidMultiplier?.belowTopOfSearchBidMultiplier.toString()
    : undefined;
};

export const getTooltipContent = (
  isBidOptimizationEnabled: boolean,
  intl: IntlShape,
  isCurrentValueInvalid: boolean,
  validationsConfig: NumericValueConfig,
  isCampaignArchived: boolean
) => {
  if (isCampaignArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.Campaigns],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BELOW_SEARCH_BID_MULTIPLIER,
      }),
      intl
    );
  } else {
    if (isBidOptimizationEnabled) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage({
            id: I18nKey.ADS_MANAGER_DISABLED_BELOW_TOP_OF_SEARCH_BID_MULTIPLIER,
          })}
        </p>
      );
    }
    if (isCurrentValueInvalid) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_INVALID_BELOW_TOP_OF_SEARCH_BID_MULTIPLIER,
            },
            {
              minValue: validationsConfig.min,
              maxValue: validationsConfig.max,
            }
          )}
        </p>
      );
    }
  }
};

export const belowTopOfTheSearchBidMultiplierColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier,
  isSortable: true,
  i18nKeyOrLabel:
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BELOW_SEARCH_BID_MULTIPLIER,
  RowCellElement,
  gridColumnWidth: '180px',
};

export const belowTopOfTheSearchBidMultiplierFilter =
  createPercentDataFieldFilter(
    CAMPAIGNS_API_COLUMN_NAME.BelowTopOfTheSearchBidMultiplier,
    I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_BELOW_SEARCH_BID_MULTIPLIER,
    isValidNumber()
  );

export function isBelowTopOfTheSearchBidMultiplierInvalid(
  bidMultiplierValue: string,
  maxLimit?: number,
  minLimit?: number
) {
  return (
    isCurrentValueLessThanRequiredMinValue(minLimit, bidMultiplierValue) ||
    isCurrentValueGreaterThanRequiredMaxValue(maxLimit, bidMultiplierValue)
  );
}

export const getNumericInputState = (
  isBidOptimizationEnabled: boolean,
  isCampaignArchived: boolean,
  isInputInvalid: boolean
) => {
  if (isBidOptimizationEnabled || isCampaignArchived) {
    return NumericInputState.Disabled;
  } else if (isInputInvalid) {
    return NumericInputState.Error;
  } else {
    return NumericInputState.Default;
  }
};

export const getControlledTooltipState = (
  isCampaignArchived: boolean,
  numericInputState: NumericInputState,
  isBidOptimizationEnabled: boolean,
  focussed: boolean
): ControlledTooltip => {
  if (isCampaignArchived) {
    return ControlledTooltip.None;
  } else if (
    numericInputState === NumericInputState.Disabled &&
    !isBidOptimizationEnabled
  ) {
    return ControlledTooltip.Hide;
  } else if (focussed && numericInputState === NumericInputState.Error) {
    return ControlledTooltip.Show;
  } else if (isBidOptimizationEnabled) {
    return ControlledTooltip.None;
  } else {
    return ControlledTooltip.Hide;
  }
};

export const getIsBidOptimizationEnabled = (
  changedAmazonBidOptimizationValue: string,
  bidOptimization: boolean | undefined
) =>
  !isUndefined(changedAmazonBidOptimizationValue)
    ? changedAmazonBidOptimizationValue === AmazonBidOptimization.True
    : bidOptimization === true;

export const isInputBidInvalid = (
  bidMultiplierValue: string,
  existingValue: string,
  maxLimit?: number,
  minLimit?: number
) =>
  (!isEmpty(bidMultiplierValue) &&
    isBelowTopOfTheSearchBidMultiplierInvalid(
      bidMultiplierValue,
      maxLimit,
      minLimit
    )) ||
  (!isEmpty(existingValue) && isEmpty(bidMultiplierValue));
