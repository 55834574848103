import classNames from 'classnames';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SearchTermsData } from '../../../../lib/types/MISharedTypes';
import {
  ReportingFilterStatus,
  ReportingQueryParams,
} from '../../containers/reporting/types';
import SearchPickerDropdown from './searchPickerDropdown';
import SearchPickerLabel from './searchPickerLabel';
import { DatePicker } from '../datePicker';
import { MIApiClient } from '../../../../lib/clients/MIApiClient';
import { MerchantCountryCode } from '../../../../lib/types/AOSharedTypes';

interface SearchTermPickerProps {
  readonly searchTerms: SearchTermsData[];
  readonly queryParams: ReportingQueryParams;
  readonly setQueryParams: (queryParams: ReportingQueryParams) => void;
  readonly toggleResetRunqueryButtonState: (
    currentProps: ReportingQueryParams
  ) => void;
  readonly searchTermChanged: ReportingFilterStatus;
  readonly hasMerchantData: boolean;
  readonly useApiForSearch: boolean;
  readonly datePickerRef: RefObject<DatePicker>;
  readonly miApiClient: MIApiClient;
}

export const SearchTermPicker: React.FC<SearchTermPickerProps> = ({
  searchTerms,
  queryParams,
  setQueryParams,
  toggleResetRunqueryButtonState,
  searchTermChanged,
  hasMerchantData,
  useApiForSearch,
  datePickerRef,
  miApiClient,
}) => {
  const dropdownRef = useRef<null | HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedValue = useMemo(() => {
    if (
      searchTerms.find(
        (item) =>
          item.search_term === queryParams.searchTerm?.search_term &&
          item.locale === queryParams.searchTerm?.locale
      )
    ) {
      return queryParams.searchTerm;
    }
    return undefined;
  }, [searchTerms, queryParams.searchTerm]);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSelect = useCallback(
    (option: SearchTermsData) => {
      const currentProps = {
        ...queryParams,
        searchTerm: option,
      };
      setQueryParams(currentProps);
      toggleResetRunqueryButtonState(currentProps);
      setShowDropdown(false);
    },
    [queryParams]
  );

  useEffect(() => {
    return datePickerRef.current?.setState({
      from: queryParams.dateRange.from,
      to: queryParams.dateRange.to,
    });
  }, [queryParams.dateRange]);

  const dropdownMenu = useMemo(() => {
    if (!showDropdown) {
      return null;
    }

    return (
      <div
        className={classNames(
          'rounded border border-solid border-grey-200 shadow text-base text-grey-900',
          'absolute flex flex-col bg-white z-20 top-full h-300 w-full mt-4 py-12'
        )}
        data-test-id="searchTermPicker_dropdown"
      >
        <SearchPickerDropdown
          initialOptions={searchTerms}
          selectedTerm={selectedValue}
          onOptionSelect={handleSelect}
          hasMerchantData={hasMerchantData}
          useApiForSearch={useApiForSearch}
          platform={queryParams.platform}
          miApiClient={miApiClient}
        />
      </div>
    );
  }, [
    showDropdown,
    searchTerms,
    queryParams,
    hasMerchantData,
    useApiForSearch,
    miApiClient,
  ]);

  return (
    <div
      className={classNames(
        'relative h-40 w-240 flex flex-col border border-solid border-grey-200 rounded hover:border-purple-500',
        {
          'border-purple-500':
            searchTermChanged === ReportingFilterStatus.Changed,
          'border-orange-500':
            searchTermChanged === ReportingFilterStatus.Error,
        }
      )}
      ref={dropdownRef}
    >
      <SearchPickerLabel
        locale={selectedValue?.locale as MerchantCountryCode}
        isGroup={selectedValue?.is_group}
        value={
          selectedValue?.is_group
            ? selectedValue.group_name
            : selectedValue?.search_term
        }
        onClick={handleDropdownToggle}
      />
      {dropdownMenu}
    </div>
  );
};

SearchTermPicker.displayName = 'SearchTermPicker';
