import classNames from 'classnames';
import noop from 'lodash/noop';
import { useIntl } from 'react-intl';

import {
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Icon,
  IconSize,
  LockCircleIcon,
  RadioButton,
} from '@teikametrics/tm-design-system';

import { FlywheelSalesChannel, MerchantType } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import { ConnectModalWrapper } from '../ConnectModalWrapper';
import { WalmartOAuthStatus } from './walmartFlashScreens';
import { WalmartCommonFailureBanner } from './walmartCommonFailureBanner';

interface ProductDataSyncFormV2Props {
  readonly merchantType: MerchantType;
  readonly onMerchantTypeSelect: (type: MerchantType) => void;
  readonly walmartOAuthStatus: WalmartOAuthStatus;
}
interface WalmartProductDataSyncFormProps extends ProductDataSyncFormV2Props {
  readonly onContinue: () => void;
  readonly closeModal?: () => void;
  readonly walmartOAuthStatus: WalmartOAuthStatus;
}

const WALMART_DIFFERENCE_IN_1P_3P = `https://intercom.help/flywheel-20/en/articles/6339664-what-s-the-difference-between-3p-and-1p`;

export const WalmartProductDataSyncForm: React.FC<
  WalmartProductDataSyncFormProps
> = ({
  merchantType,
  onContinue,
  onMerchantTypeSelect,
  closeModal,
  walmartOAuthStatus,
}) => {
  const intl = useIntl();

  return (
    <ConnectModalWrapper
      dataTestId="walmart_product_data_sync_form"
      isOpen
      closeModal={closeModal}
      headerText={intl.formatMessage({
        id: I18nKey.FLASH_MODALS_WALMART_MODAL_HEADER,
      })}
      content={
        <ProductDataSyncFormV2
          merchantType={merchantType}
          onMerchantTypeSelect={onMerchantTypeSelect}
          walmartOAuthStatus={walmartOAuthStatus}
        />
      }
      footer={
        <div className="flex justify-center mx-8">
          <Button
            size={ButtonSize.Medium}
            variant={ButtonVariant.Primary}
            label={intl.formatMessage({ id: I18nKey.CONTINUE })}
            onClick={onContinue}
            state={
              WalmartOAuthStatus.IN_PROGRESS === walmartOAuthStatus
                ? ButtonState.Loading
                : ButtonState.Enabled
            }
            data-test-id={`flash1_walmart_select_merchant_type_continue`}
          />
        </div>
      }
      headerChannelType={FlywheelSalesChannel.Walmart}
    />
  );
};

export const ProductDataSyncFormV2: React.FC<ProductDataSyncFormV2Props> = ({
  merchantType,
  onMerchantTypeSelect,
  walmartOAuthStatus,
}) => {
  const intl = useIntl();

  const addProductsDataLabel = intl.formatMessage({
    id: I18nKey.ADD_PRODUCTS_DATA,
  });
  const merchantTypeLabel = intl.formatMessage({
    id: I18nKey.MERCHANT_SELLER_TYPE,
  });

  const sellerMerchantLabel = intl.formatMessage({
    id: I18nKey.FLASH_MODALS_WALMART_PRODUCT_CONNECT_3P_SELLER,
  });
  const venderMerchantLabel = intl.formatMessage({
    id: I18nKey.FLASH_MODALS_WALMART_PRODUCT_CONNECT_1P_SUPPLIER,
  });

  const merchantTypeDifferenceLabel = intl.formatMessage({
    id: I18nKey.WHAT_IS_THE_DIFFERENCE,
  });
  const walmartSellerCenterRequirementLabel = intl.formatMessage({
    id: I18nKey.WALMART_ADMIN_ACCESS,
  });
  const walmartSellerCenterDisclaimerLabel = intl.formatMessage({
    id: I18nKey.WALMART_ADMIN_ACCCES_SUB_TEXT,
  });

  return (
    <div className="flex flex-col justify-between mt-24 h-380">
      <div>
        <h2
          className={classNames(
            'flex mt-8',
            'text-lg leading-none font-semibold text-grey-900'
          )}
        >
          {addProductsDataLabel}
        </h2>
        <label
          className={classNames(
            'flex mt-20',
            'text-base leading-tight font-medium'
          )}
        >
          {merchantTypeLabel}
        </label>
        <div className="flex flex-row border-box mt-12">
          <div
            className={classNames(
              'flex w-1/2 mr-4 p-12 border border-solid border-grey-300 rounded-4',
              {
                'bg-purple-50 border-purple-300':
                  merchantType === MerchantType.Seller,
              }
            )}
          >
            <RadioButton
              label={sellerMerchantLabel}
              disabled={merchantType !== MerchantType.Seller}
              onChange={noop}
              selected={merchantType === MerchantType.Seller}
              labelClassname="ml-12 text-base leading-tight font-normal text-grey-900"
              dataTestId={`flash1_walmart_select_merchant_type_3p`}
            />
          </div>
          <div
            className={classNames(
              'flex w-1/2 ml-4 p-12 border border-solid border-grey-300 rounded-4',
              {
                'bg-purple-50 border-purple-300':
                  merchantType === MerchantType.Vendor,
              }
            )}
          >
            <RadioButton
              label={venderMerchantLabel}
              disabled={merchantType !== MerchantType.Vendor}
              onChange={noop}
              selected={merchantType === MerchantType.Vendor}
              labelClassname={classNames(
                'ml-12',
                'text-base leading-tight font-normal text-grey-900'
              )}
              dataTestId={`flash1_walmart_select_merchant_type_1p`}
            />
          </div>
        </div>
        <a
          href={WALMART_DIFFERENCE_IN_1P_3P}
          className={classNames(
            'flex mt-12',
            'text-grey-600 text-sm leading-none font-normal underline'
          )}
          target="_blank"
          data-test-id={`flash1_walmart_select_merchant_type_help_link`}
          rel="noreferrer"
        >
          {merchantTypeDifferenceLabel}
        </a>
      </div>
      <div>
        {walmartOAuthStatus === WalmartOAuthStatus.FAILED && (
          <WalmartCommonFailureBanner />
        )}
        <div
          className={classNames(
            'flex flex-row items-start',
            'bg-yellow-100 py-16 px-12 mt-16 rounded-4'
          )}
        >
          <div className="flex mr-12">
            <Icon
              size={IconSize.Large}
              svg={LockCircleIcon}
              className="text-yellow-700"
            />
          </div>
          <div className="flex flex-col">
            <label
              className={classNames(
                'flex items-start justify-start',
                'text-left text-base leading-normal font-medium text-grey-900'
              )}
            >
              {walmartSellerCenterRequirementLabel}
            </label>
            <label
              className={classNames(
                'flex items-start justify-start',
                'text-left text-sm leading-normal font-normal text-grey-900'
              )}
            >
              {walmartSellerCenterDisclaimerLabel}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
