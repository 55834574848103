import { StickyAlignment } from '@teikametrics/tm-design-system';
import { FlywheelTableV2ColumnGroupProps } from '../../../../../containers/tableV2/types';
import {
  KeywordActionColumns,
  KeywordRecommendationsData,
} from '../../../../../lib/types/KeywordAction';
import { RecommendationsTableV2ExtraProps } from '../types';
import { acos } from './acos';
import { actions } from './actions';
import { adConversionRate } from './adConversionRate';
import { adConversions } from './adConversions';
import { adSales } from './adSales';
import { adSpend } from './adSpend';
import { clickThroughRate } from './clickThroughRate';
import { clicks } from './clicks';
import { costPerClick } from './costPerClick';
import { createInAdGroup } from './createInAdGroup';
import { impressions } from './impressions';
import { keywordRecommendation } from './keywordRecommendation';
import { matchType } from './matchType';
import { negativeKeywordRecommendation as negativeKeywordRecommendationV2 } from './negativeKeywordV2';
import { negativeMatchType } from './negativeMatchType';
import { sources as sourcesV2 } from './sourcesV2';
import { createInAdGroup as destinationAdGroup } from './destinationAdGroup';

import { roas } from './roas';
import { sources } from './sources';
import { productAsinsToAdd } from './productAsinsToAdd';

export const getKWAColumnsDefinition = (
  selectedColumns: string[],
  keywordActionsColumnEnabled?: boolean,
  hidePerformanceColumn?: boolean,
  isPat?: boolean
): FlywheelTableV2ColumnGroupProps<
  KeywordRecommendationsData,
  {},
  RecommendationsTableV2ExtraProps,
  {}
>[] => {
  const columnDefinitions = [
    {
      columns: [isPat ? productAsinsToAdd : keywordRecommendation],
      sticky: StickyAlignment.Left,
    },
    {
      columns: [matchType, createInAdGroup],
    },
    ...(hidePerformanceColumn
      ? []
      : [
          { columns: [sources, adConversions, clicks] },
          {
            columns: [
              adSales,
              adSpend,
              acos,
              roas,
              adConversionRate,
              impressions,
              costPerClick,
              clickThroughRate,
            ],
          },
        ]),
    {
      columns: [actions],
      sticky: StickyAlignment.Right,
    },
  ];

  const availableColumns = [
    KeywordActionColumns.MATCH_TYPE,
    KeywordActionColumns.CAMPAIGN_ADGROUP_NAME,
    KeywordActionColumns.PRODUCT_ASIN,
    KeywordActionColumns.KEYWORD,
    KeywordActionColumns.ACTIONS,
    KeywordActionColumns.SOURCE,
    KeywordActionColumns.CLICKS,
    KeywordActionColumns.AD_CONVERSIONS,
    ...selectedColumns,
  ];

  return columnDefinitions
    .map((group) => {
      const filteredColumns = group.columns.filter(({ accessorKey }) => {
        if (keywordActionsColumnEnabled) {
          return accessorKey && availableColumns.includes(accessorKey);
        } else {
          return (
            accessorKey &&
            availableColumns.includes(accessorKey) &&
            accessorKey !== KeywordActionColumns.ACTIONS
          );
        }
      });

      return { ...group, columns: filteredColumns };
    }, [])
    .filter(({ columns }) => columns.length);
};

export const getNKWAColumnsDefinition = (
  selectedColumns: string[],
  keywordActionsColumnEnabled?: boolean
): FlywheelTableV2ColumnGroupProps<
  KeywordRecommendationsData,
  {},
  RecommendationsTableV2ExtraProps,
  {}
>[] => {
  const columnDefinitions = [
    {
      columns: [negativeKeywordRecommendationV2],
      sticky: StickyAlignment.Left,
    },
    {
      columns: [negativeMatchType, destinationAdGroup],
    },
    {
      columns: [sourcesV2, adSpend, clicks, adConversions],
    },
    {
      columns: [
        adSales,
        acos,
        roas,
        adConversionRate,
        impressions,
        costPerClick,
        clickThroughRate,
      ],
    },
    {
      columns: [actions],
      sticky: StickyAlignment.Right,
    },
  ];

  const negativeAvailableColumns = [
    KeywordActionColumns.MATCH_TYPE,
    KeywordActionColumns.CAMPAIGN_ADGROUP_NAME,
    KeywordActionColumns.NEGATIVE_KEYWORD,
    KeywordActionColumns.ACTIONS,
    KeywordActionColumns.SOURCE,
    KeywordActionColumns.AD_SPEND,
    KeywordActionColumns.CLICKS,
    KeywordActionColumns.AD_CONVERSIONS,
    ...selectedColumns,
  ];

  return columnDefinitions
    .map((group) => {
      const filteredColumns = group.columns.filter(({ accessorKey }) => {
        if (keywordActionsColumnEnabled) {
          return accessorKey && negativeAvailableColumns.includes(accessorKey);
        } else {
          return (
            accessorKey &&
            negativeAvailableColumns.includes(accessorKey) &&
            accessorKey !== KeywordActionColumns.ACTIONS
          );
        }
      });

      return { ...group, columns: filteredColumns };
    }, [])
    .filter(({ columns }) => columns.length);
};
