import {
  PortaledTooltipPlacement,
  PortaledTooltipTheme,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';
import debounce from 'lodash/debounce';
import lowerCase from 'lodash/lowerCase';
import React, { useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeTextColumn,
  makeTextInputWithPortaledTooltipColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  AdGroupReviewStatusType,
  CampaignStatus,
  ProfileApiDataDetails,
  ProfileStatus,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { getInputState } from '../adgroupTableColumns/adgroupName';
import { ADS_MANAGER_PROFILES_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import {
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  EDIT_PROFILE_API_COLUMN_DATA_MAPPING,
  MAX_BRAND_NAME_LENGTH,
  PROFILES_API_COLUMN_NAME,
  TABLE_UNIQ_KEY,
  hasSpecialCharacters,
  isInvalidBrandName,
  isValidBrandNameCharacterLength,
} from '../utils';

export interface TooltipContentProps {
  readonly intl: IntlShape;
  readonly showTooltip: boolean;
  readonly value: string;
  readonly profileStatus?: ProfileStatus;
  readonly adGroupReviewStatus?: AdGroupReviewStatusType;
  readonly campaignStatus?: CampaignStatus;
}

export const getIsInputInvalid = (value: string) =>
  isInvalidBrandName(value) || !isValidBrandNameCharacterLength(value.trim());

export const getTooltipContent = ({
  intl,
  showTooltip,
  value,
  campaignStatus,
  adGroupReviewStatus,
  profileStatus,
}: TooltipContentProps) => {
  let messageId: I18nKey | undefined;
  let data: { column: string; max?: number } = {
    column: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_BRAND,
    }),
  };

  if (
    adGroupReviewStatus === AdGroupReviewStatusType.InProgress ||
    adGroupReviewStatus === AdGroupReviewStatusType.Pending
  ) {
    messageId =
      I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_ONGOING_REVIEW_TOOLTIP_CONTENT;
    data = {
      ...data,
      column: lowerCase(data.column),
    };
  } else if (
    profileStatus === ProfileStatus.Enabled &&
    campaignStatus?.toString() !== 'Paused' &&
    campaignStatus?.toString() !== 'Proposal'
  ) {
    messageId =
      I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_CAMPAIGN_NOT_PAUSED_TOOLTIP_CONTENT;
  } else if (showTooltip) {
    if (!value) {
      messageId =
        I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_EMPTY_TOOLTIP_CONTENT;
    } else if (!isValidBrandNameCharacterLength(value)) {
      messageId =
        I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_MAX_CHARACTERS_TOOLTIP_CONTENT;
      data = {
        ...data,
        max: MAX_BRAND_NAME_LENGTH,
      };
    } else if (isInvalidBrandName(value)) {
      messageId =
        I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_INVALID_COLUMN_TOOLTIP_CONTENT;
    } else if (hasSpecialCharacters(value)) {
      messageId =
        I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_SPECIAL_CHARACTERS_TOOLTIP_CONTENT;
      data = {
        ...data,
        column: lowerCase(data.column),
      };
    }
  }

  if (messageId) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage(
          {
            id: messageId,
          },
          { ...data }
        )}
      </p>
    );
  }
  return <></>;
};

export const getIsColumnEditDisabled = (
  profileStatus?: ProfileStatus,
  adGroupReviewStatus?: AdGroupReviewStatusType,
  campaignStatus?: CampaignStatus
) => {
  if (
    adGroupReviewStatus === AdGroupReviewStatusType.InProgress ||
    adGroupReviewStatus === AdGroupReviewStatusType.Pending
  ) {
    return true;
  }

  return (
    profileStatus === ProfileStatus.Enabled &&
    campaignStatus?.toString() !== 'Paused' &&
    campaignStatus?.toString() !== 'Proposal'
  );
};

export const RowCellElement: React.FC<
  ProfileApiDataDetails & TableDataAdsManager
> = ({
  profileDetails,
  profilePerformance,
  profileId,
  channelSettings,
  isEditMode,
  pendingFields,
}) => {
  const profileData: ProfileApiDataDetails = {
    profileDetails,
    profilePerformance,
    profileId,
    channelSettings,
  };

  const intl: IntlShape = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);

  const currentPage = useSelector<WithTable<ProfileApiDataDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_PROFILES_TABLE_ID
      )
  );

  const changedValue = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellValueSelector(
        profileId,
        PROFILES_API_COLUMN_NAME.Brand,
        EDIT_PROFILE_API_COLUMN_DATA_MAPPING,
        TABLE_UNIQ_KEY[ADS_MANAGER_PROFILES_TABLE_ID]
      )(tableState, ADS_MANAGER_PROFILES_TABLE_ID)
  );

  const existingValue = profileDetails.brand || '';
  const brand = changedValue ?? existingValue;
  const [value, setValue] = useState<string>(brand);

  useEffect(() => {
    if (isEditMode) {
      setValue(brand);
    }
  }, [isEditMode, currentPage]);

  const updateCellValue = (inputValue: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_PROFILES_TABLE_ID,
        rowId: profileId,
        columnName: PROFILES_API_COLUMN_NAME.Brand,
        value: inputValue,
        existingValue: existingValue,
      })
    );
  };

  const debounceCellValueUpdate = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [profileData.profileId]
  );

  if (isEditMode) {
    const isDisabled = getIsColumnEditDisabled(
      channelSettings.profileStatus,
      profileDetails.adGroupReviewStatus?.reviewStatus,
      profileDetails.campaignStatus
    );
    const isInputInvalid = getIsInputInvalid(value);

    const onBrandNameChange = (newValue: string) => {
      setValue(newValue);
      debounceCellValueUpdate(newValue);
    };

    const onInputFocus = () => setFocussed(true);
    const onInputBlur = () => {
      debounceCellValueUpdate.cancel();
      setFocussed(false);
      updateCellValue(value);
    };

    const showTooltip =
      isDisabled ||
      (focussed && (isInputInvalid || hasSpecialCharacters(value)));

    return makeTextInputWithPortaledTooltipColumn(() => ({
      value,
      inputState: getInputState(isInputInvalid),
      onChange: onBrandNameChange,
      onFocus: onInputFocus,
      onBlur: onInputBlur,
      onKeyPress: onInputFocus,
      isDirty: value !== existingValue,
      tooltipContent: getTooltipContent({
        intl,
        showTooltip,
        value,
        campaignStatus: profileDetails.campaignStatus,
        adGroupReviewStatus: profileDetails.adGroupReviewStatus?.reviewStatus,
        profileStatus: channelSettings.profileStatus,
      }),
      overwrittenTooltipClassnames: 'w-full',
      tooltipTheme: PortaledTooltipTheme.Dark,
      tooltipDisabled: !showTooltip,
      portaledTooltipPlacement: PortaledTooltipPlacement.Bottom,
      disabled: isDisabled,
      dataTestId: 'brand',
    }))(profileData);
  }

  return makeTextColumn(
    (data: ProfileApiDataDetails) => data.profileDetails.brand,
    pendingFields?.includes(PROFILES_API_COLUMN_NAME.Brand)
  )(profileData);
};
RowCellElement.displayName = 'ProfileBrandCell';

export const brandColumn: FlywheelTableColumn<
  ProfileApiDataDetails,
  TableDataAdsManager
> = {
  columnName: PROFILES_API_COLUMN_NAME.Brand,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_BRAND,
  RowCellElement,
  gridColumnWidth: '360px',
};

export const profileBrandFilter = createStringDataFieldFilter(
  PROFILES_API_COLUMN_NAME.Brand,
  I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_BRAND,
  isValidString()
);
