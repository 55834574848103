import { DateTime } from 'luxon';

import {
  createDateFieldFilter,
  isValidDate,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeTextColumn } from '../../../../../containers/table/utils/makeTableCells';
import { CampaignDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  CAMPAIGN_DATE_FORMAT,
  CAMPAIGN_DISPLAY_DATE_FORMAT,
  CAMPAIGN_FILTER_DATE_FORMAT_MOMENT,
  TableDataAdsManager,
} from '../types';
import { CAMPAIGNS_API_COLUMN_NAME } from '../utils';

export const RowCellElement: React.FC<CampaignDetails & TableDataAdsManager> = (
  props
) => {
  return makeTextColumn(({ channelSettings: { createdAt } }: CampaignDetails) =>
    createdAt
      ? DateTime.fromFormat(createdAt, CAMPAIGN_DATE_FORMAT).toFormat(
          CAMPAIGN_DISPLAY_DATE_FORMAT
        )
      : undefined
  )(props);
};
RowCellElement.displayName = 'RowCellElement';

export const createdAtColumn: FlywheelTableColumn<
  CampaignDetails,
  TableDataAdsManager
> = {
  columnName: CAMPAIGNS_API_COLUMN_NAME.CreatedAt,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CREATED_AT,
  RowCellElement,
  gridColumnWidth: '160px',
};

export const createdAtFilter = createDateFieldFilter(
  CAMPAIGNS_API_COLUMN_NAME.CreatedAt,
  I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_COLUMN_CREATED_AT,
  isValidDate(CAMPAIGN_FILTER_DATE_FORMAT_MOMENT)
);
