import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { SwitchPlanModal } from '../../../../components/SwitchPlanModal';
import { TrialEndedModal } from '../../../../components/TrialEndModal';
import {
  getCurrentAccountFromContext,
  getFreeTrialDaysRemaining,
  showSwitchPlanModal,
} from '../../../../containers/userProvider/selectors';
import { UserContext } from '../../../../containers/userProvider/userProvider';
import { FAMApiClient } from '../../../../lib/clients/FAMApiClient';
import I18nKey from '../../../../lib/types/I18nKey';
import { NavPaths } from '../../../../NavPaths';
import { UpgradePlanModal } from '../../../account/components/upgradePlanModal';
import { AutomateVideoPopup } from '../../../compass/components/AutomateVideoPopup';
import { UpgradeBanner } from './upgradeBanner';
import RiskFreeTrialModal from '../../../../components/BillingModals/RiskFreeTrialModal/RiskFreeTrialModal';
import { FlywheelSalesChannel } from '../../../../lib/types/Fam';
import { useNavigate } from 'react-router-dom';
import { AIAnimatedLoader } from '@teikametrics/tm-design-system';

interface UpgradePlanWrapperProps {
  readonly famApiClient: FAMApiClient;
  readonly afterPlanUpgrade: () => void;
  readonly updateMerchantsInfoAfterUpdate: () => void;
}

export const UpgradePlanWrapper: React.FC<UpgradePlanWrapperProps> = ({
  famApiClient,
  afterPlanUpgrade,
  updateMerchantsInfoAfterUpdate,
}) => {
  const [showPlanModal, setShowPlanModal] = useState<boolean>(false);
  const [showWizardModal, setShowWizardModal] = useState<boolean>(false);
  const [showTrialEndedModalFlow, setShowTrialEndedModalFlow] =
    useState<boolean>(false);
  const [showAutomateAdsModalFlow, setShowAutomateAdsModalFlow] =
    useState<boolean>(false);
  const [showRiskFreeTrial, setShowRiskFreeTrial] = useState(false);
  const navigate = useNavigate();

  const userContext = useContext(UserContext);

  const currentAccount = getCurrentAccountFromContext(userContext);

  const daysRemainingInTrial = getFreeTrialDaysRemaining(currentAccount);

  const hasUsedFreeTrial =
    currentAccount?.freeTrialStartedAt && daysRemainingInTrial === 0;

  const upgradePlan = async () => {
    setShowPlanModal(false);
    setShowWizardModal(true);
    updateMerchantsInfoAfterUpdate();
  };

  const closeWizardModal = () => {
    setShowWizardModal(false);
    setShowAutomateAdsModalFlow(true);
  };

  const onUpgradePlanButtonClick = () => {
    setShowRiskFreeTrial(true);
  };

  const closeAutomateAdsModal = () => {
    afterPlanUpgrade();
    userContext.refreshUserContext();
    setShowAutomateAdsModalFlow(false);
  };

  const intl = useIntl();

  const shouldShowAutomateAdsModalFlow = showAutomateAdsModalFlow;

  const modalType = showSwitchPlanModal(userContext)
    ? 'switchPlanModal'
    : 'upgradePlanModal';

  const navigateToBilling = () => {
    setShowTrialEndedModalFlow(false);
    navigate(NavPaths.Billing, {
      state: { openiFrame: true },
    });
  };

  return (
    <>
      <UpgradeBanner>
        <span className="text-center text-blue-700 text-sm font-normal">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_UPGRADE_TO_AI_PLAN_BANNER_MESSAGE,
            },
            {
              title: (
                <span
                  className="font-semibold underline cursor-pointer"
                  onClick={onUpgradePlanButtonClick}
                >
                  {intl.formatMessage({
                    id: I18nKey.ADS_MANAGER_UPGRADE_TO_AI_PLAN_BANNER_MESSAGE_TITLE,
                  })}
                </span>
              ),
            }
          )}
        </span>
      </UpgradeBanner>
      {showTrialEndedModalFlow && (
        <TrialEndedModal
          onCancelClicked={() => setShowTrialEndedModalFlow(false)}
          onContinueClicked={navigateToBilling}
        />
      )}
      {modalType === 'switchPlanModal' && (
        <SwitchPlanModal
          show={showPlanModal}
          salesChannel={FlywheelSalesChannel.Amazon}
          onCancelClick={() => setShowPlanModal(false)}
          onContinueClick={upgradePlan}
        />
      )}
      {modalType === 'upgradePlanModal' && showPlanModal && (
        <UpgradePlanModal
          trialDaysRemaining={daysRemainingInTrial}
          freeTrialAvailable={!hasUsedFreeTrial}
          onUpgrade={upgradePlan}
          onCancel={() => {
            setShowPlanModal(false);
          }}
          dataTestId="ao_upgrade-plan"
        />
      )}
      {showWizardModal && (
        <AIAnimatedLoader
          name={userContext.userInfo.userDetails?.firstName!!}
          companyName={currentAccount?.companyName!!}
          showModal={showWizardModal}
          onClose={closeWizardModal}
          nextStepsButtonText={intl.formatMessage({
            id: I18nKey.COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_BUTTON_NEXT_STEPS,
          })}
        />
      )}
      {shouldShowAutomateAdsModalFlow && (
        <AutomateVideoPopup
          isOpen={showAutomateAdsModalFlow}
          onClose={closeAutomateAdsModal}
        />
      )}
      {showRiskFreeTrial && (
        <RiskFreeTrialModal
          onCancelClicked={() => setShowRiskFreeTrial(false)}
          onPaymentSuccess={updateMerchantsInfoAfterUpdate}
        />
      )}
    </>
  );
};
UpgradePlanWrapper.displayName = 'UpgradePlanWrapper';
