import {
  CheckboxCheckedState,
  createStringDataFieldFilter,
  isValidString,
} from '@teikametrics/tm-design-system';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import {
  TableSelectSettings,
  WithTable,
} from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeCheckboxLabelColumn,
  makeLongTextColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupStatus,
  AdType,
  CampaignStatus,
  EMPTY_STRING,
  MatchType,
  TargetStatus as TargetStatusValues,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_TARGETS_TABLE_ID } from '../ducks/types';
import { TableDataAdsManager } from '../types';
import {
  TARGETS_API_COLUMN_NAME,
  getCheckBoxLabelStateForTargets,
  isNegativeKeywordTarget,
  isTargetStatusArchived,
} from '../utils';

const ADTYPES_ARRAY_SB_SBV: readonly AdType[] = [
  AdType.SponsoredBrands,
  AdType.SponsoredBrandsVideo,
];

const getSelectSettings = ({ tableState }: WithTable<TargetsDetails>) => {
  return tableSelectors.getSelectInfoSelector()(
    tableState,
    ADS_MANAGER_TARGETS_TABLE_ID
  );
};

const getState = (data: TargetsDetails) => {
  if (
    data.channelSettings.status === TargetStatusValues.Archived ||
    data.channelSettings.status === TargetStatusValues.Deleted
  ) {
    return data.channelSettings.status;
  }
  if (data.targetDetails.adGroupStatus === AdGroupStatus.Archived) {
    return data.targetDetails.adGroupStatus;
  }
  if (data.targetDetails.campaignStatus === CampaignStatus.Archived) {
    return data.targetDetails.campaignStatus;
  }
  return EMPTY_STRING;
};

export const isValidNegativeKeywordTargetStatusOption = (
  adType: AdType,
  matchType?: MatchType
) => {
  if (adType !== AdType.SponsoredBrands) {
    return false;
  }

  return isNegativeKeywordTarget(matchType);
};

export const addProductCollectionBidElement = (
  commonCampaignIdsWithAdFormatAsProductCollection: string[],
  adType: AdType
) =>
  commonCampaignIdsWithAdFormatAsProductCollection.length > 0 ||
  !ADTYPES_ARRAY_SB_SBV.includes(adType);

export const getAdTypesVisibilityCriteria = (adType: AdType) =>
  ADTYPES_ARRAY_SB_SBV.includes(adType)
    ? [
        AdType.SponsoredBrands,
        AdType.SponsoredProducts,
        ...ADTYPES_ARRAY_SB_SBV,
      ]
    : [AdType.SponsoredDisplay, AdType.SponsoredProducts];

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = ({
  targetDetails,
  targetId,
  targetPerformance,
  channelSettings,
  isEditMode,
  salesChannel,
}) => {
  const dispatch = useDispatch();
  const selectSettings = useSelector<
    WithTable<TargetsDetails>,
    TableSelectSettings
  >(getSelectSettings);

  const targetData: TargetsDetails = {
    targetDetails,
    targetId,
    channelSettings,
    targetPerformance,
  };

  const isRowSelected = selectSettings.rows.includes(targetId);
  if (isEditMode) {
    return makeCheckboxLabelColumn((data: TargetsDetails) => {
      const checkboxLabel = data.targetDetails.targetText;
      return {
        label: checkboxLabel,
        checked: isRowSelected
          ? CheckboxCheckedState.Checked
          : CheckboxCheckedState.Unchecked,

        state: getCheckBoxLabelStateForTargets(salesChannel, getState(data)),
        onClick: () => {
          if (!isTargetStatusArchived(getState(data))) {
            dispatch(
              tableActions.toggleSelectedItem({
                tableId: ADS_MANAGER_TARGETS_TABLE_ID,
                rowId: data.targetId,
              })
            );
          }
        },
        classNameLabel: 'flex-1',
        className: 'align-start',
        dataTestId: 'keyword-target_tableCell',
      };
    })(targetData);
  }

  const column = makeLongTextColumn;

  return column((data: TargetsDetails) =>
    data.targetDetails?.targetText
      ? data.targetDetails?.targetText?.toString()
      : undefined
  )(targetData);
};
RowCellElement.displayName = 'RowCellElement';

export const keywordColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  isSortable: true,
  columnName: TARGETS_API_COLUMN_NAME.Keyword,
  overrideColumnName: TARGETS_API_COLUMN_NAME.TargetText,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_KEYWORD,
  RowCellElement,
  gridColumnWidth: '360px',
};

export const keywordFilter = createStringDataFieldFilter(
  TARGETS_API_COLUMN_NAME.TargetText,
  I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_KEYWORD,
  isValidString()
);
