import {
  CheckboxLabel,
  CheckboxLabelProps,
  Tooltip,
  TooltipProps,
} from '@teikametrics/tm-design-system';
import React from 'react';

export interface CheckboxLabelWithTooltipProps {
  readonly checkboxProps: CheckboxLabelProps;
  readonly tooltipProps: TooltipProps;
  readonly tooltipEnabled?: boolean;
}

export const CheckboxLabelWithTooltip: React.FC<
  CheckboxLabelWithTooltipProps
> = ({ checkboxProps, tooltipEnabled, tooltipProps }) => {
  const checkbox = <CheckboxLabel {...checkboxProps} />;

  return (
    <>
      {tooltipEnabled ? (
        <Tooltip {...tooltipProps}>{checkbox}</Tooltip>
      ) : (
        checkbox
      )}
    </>
  );
};
