import * as Sentry from '@sentry/react';
import type { AxiosError } from 'axios';

import cloneDeep from 'lodash/cloneDeep';

const sanitizeBeforeLog = (axiosError: AxiosError) => {
  const config = cloneDeep(axiosError.config);
  if (config?.headers?.Authorization) {
    delete config.headers.Authorization;
  }
  const response = cloneDeep(axiosError.response);
  if (response?.config?.headers?.Authorization) {
    delete response.config.headers.Authorization;
  }
  Sentry.captureMessage(
    `Error: ${axiosError?.code}; errorMessage: ${
      axiosError?.message
    }; config: ${JSON.stringify(config)}; responseFrom: ${JSON.stringify(
      response
    )};`,
    'error'
  );
};

export const handleErrorResponse = (axiosError: AxiosError<any>) => {
  sanitizeBeforeLog(axiosError);
};
