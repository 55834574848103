import { fromNullable } from 'fp-ts/lib/Option';
import { Dispatch } from 'react';
import { IntlShape } from 'react-intl';

import { SortingType, TMTableMeta } from '@teikametrics/tm-design-system';

import { Sort, SortOrder } from '../../lib/types/Sort';
import {
  AsyncRequestKinds,
  IdentifiedAsyncRequest,
  asyncRequestIsComplete,
} from '../../lib/utilities/asyncRequest';
import { Fw2LocalStorage } from '../../lib/utilities/fw2LocalStorage';
import { tableActions } from './ducks';
import { MissingRequiredField, Table } from './ducks/types';
import {
  FlywheelTableV2ColumnGroupProps,
  PageSizeInStorage,
  PaginatedTableV2Props,
  StorageKeyIdentifiers,
} from './types';

const prepareColumns = <
  T extends Record<string, unknown>,
  A = {},
  R extends TMTableMeta<T> = {},
  S = {}
>(
  newHeaderGroup: FlywheelTableV2ColumnGroupProps<T, A, R, S>
) => {
  if (newHeaderGroup.accessorKey || newHeaderGroup.header) {
    newHeaderGroup = {
      ...(newHeaderGroup as any),
      accessorKey: newHeaderGroup.accessorKey ?? newHeaderGroup.header,
      id:
        newHeaderGroup.accessorKey ??
        newHeaderGroup.id ??
        newHeaderGroup.header,
    };
  }
  return newHeaderGroup;
};

export const convertColumns = <
  T extends Record<string, unknown>,
  A = {},
  R extends TMTableMeta<T> = {},
  S = {}
>(params: {
  tableId: string;
  columns: FlywheelTableV2ColumnGroupProps<T, A, R, S>[];
  intl: IntlShape;
}): PaginatedTableV2Props<T>['columns'] => {
  const { columns, intl } = params;
  return columns.map((headerGroup) => {
    let newHeaderGroup = {
      ...(headerGroup as any),
      header: headerGroup.header
        ? intl.formatMessage({ id: headerGroup.header })
        : ' ',
    };
    newHeaderGroup = prepareColumns(newHeaderGroup);
    if (headerGroup?.columns?.length) {
      newHeaderGroup = {
        ...(newHeaderGroup as any),
        columns: headerGroup?.columns?.map((cellHeader) => {
          let newCellHeader = {
            ...(cellHeader as any),
            header: cellHeader.header
              ? intl.formatMessage({ id: cellHeader.header }, { br: '\n' })
              : ' ',
            secondaryHeaderText: cellHeader.secondaryHeader
              ? intl.formatMessage({ id: cellHeader.secondaryHeader })
              : '',
          };

          newCellHeader = prepareColumns(newCellHeader);
          return newCellHeader;
        }),
      };
    }
    return newHeaderGroup;
  });
};

export const resetTableData = (
  table: Table<any, any>,
  dispatch: Dispatch<any>,
  tableId: string,
  shouldResetAggregationHeader: boolean = false
) => {
  // We do not want to reset pagination on initial render (undefined -> default)
  if (table.pages.length > 0) {
    dispatch(tableActions.dataReset({ tableId: tableId }));
    dispatch(tableActions.setVisiblePage({ page: 1, tableId: tableId }));
    if (shouldResetAggregationHeader) {
      dispatch(tableActions.dataResetAggregationHeader({ tableId: tableId }));
    }
  }
};

export const isTableWithAggregationHeader = (table: Table<any, any>): boolean =>
  table.hasOwnProperty('aggregationData');

export const getCurrentActiveSortType = (currentSort?: Sort): SortingType => {
  if (currentSort?.direction === SortOrder.Asc) {
    return SortingType.Ascending;
  } else if (currentSort?.direction === SortOrder.Desc) {
    return SortingType.Descending;
  }
  return SortingType.None;
};

export const isTableDataEmpty = <T, E>(
  tableData: IdentifiedAsyncRequest<Array<T>, E>
): boolean =>
  asyncRequestIsComplete(tableData) && tableData.result.length === 0;

export const isTableLoading = (asyncRequestKind: AsyncRequestKinds): boolean =>
  asyncRequestKind === AsyncRequestKinds.Loading;

export const isTableDataApiFailed = (
  asyncRequestKind: AsyncRequestKinds
): boolean => asyncRequestKind === AsyncRequestKinds.Failed;

export const isTableMissingMerchantCountries = (
  missingRequiredFields: MissingRequiredField[]
): boolean =>
  missingRequiredFields.includes(MissingRequiredField.MerchantCountries);

const getItemsPerPageStorageKey = ({
  userId,
  tableId,
}: Pick<PageSizeInStorage, StorageKeyIdentifiers>) =>
  `${userId}_${tableId}_itemsPerPage`;

export const storeItemsPerPageInStorage = ({
  itemsPerPage,
  tableId,
  userId,
}: PageSizeInStorage): void => {
  const itemsPerPageStorageKey = getItemsPerPageStorageKey({
    userId,
    tableId,
  });
  Fw2LocalStorage.setItem(itemsPerPageStorageKey, itemsPerPage);
};

const getItemsPerPageFromStorage = ({
  userId,
  tableId,
}: Pick<PageSizeInStorage, StorageKeyIdentifiers>): string | null => {
  const itemsPerPageStorageKey = getItemsPerPageStorageKey({
    userId,
    tableId,
  });
  return Fw2LocalStorage.getItem(itemsPerPageStorageKey);
};

export const getItemsPerPageByTableIdFromStorage = ({
  tableId,
  userId,
  defaultPageSize,
}: Pick<PageSizeInStorage, StorageKeyIdentifiers> &
  Readonly<{ defaultPageSize: number }>) => {
  const maybePageSize = getItemsPerPageFromStorage({
    tableId,
    userId,
  });

  return fromNullable(maybePageSize)
    .map((pageSize: string) => parseInt(pageSize))
    .getOrElse(defaultPageSize);
};

/**
 * Returns the new sort order if it's in expected format, else returns the descending order.
 * @param {string} [type] - Sorting Type
 */
export const getSortDirection = (type: string) => {
  return type === SortingType.Descending ? SortOrder.Asc : SortOrder.Desc;
};

export const isLastPage = (table: Table<any, any>) => {
  if (table.maybeTotalItems) {
    return table.maybeTotalItems - table.itemsPerPage * table.visiblePage <= 0;
  } else {
    return false;
  }
};
