import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  ArrowsDownUpIcon,
  Icon,
  IconSize,
  LogomarkIcon as TeikaLogoIcon,
} from '@teikametrics/tm-design-system';

import { MAP_SALES_CHANNEL_TO_AI_AVAILABILITY_TABLE_ICON } from '../../lib/types/CommonSharedTypes';
import { FlywheelSalesChannel, MerchantCountry } from '../../lib/types/Fam';
import I18nKey from '../../lib/types/I18nKey';
import { McListRow } from './mcListRow';

interface ConnectedMerchantsProps {
  readonly salesChannel: FlywheelSalesChannel;
  readonly mcList: MerchantCountry[];
}

export const ConnectedMerchants: React.FC<ConnectedMerchantsProps> = ({
  salesChannel,
  mcList,
}) => {
  const intl = useIntl();
  return (
    <div
      className={`flex flex-row items-center w-full h-88 bg-grey-50
        border border-solid border-grey-200 rounded`}
    >
      <div
        className={`flex self-stretch w-1/2 pr-8
          border-r border-solid border-grey-300`}
      >
        <div className="flex flex-col items-center justify-center w-full gap-y-8">
          <div className="flex flex-row gap-x-12 items-center">
            <Icon svg={TeikaLogoIcon} size={IconSize.Large} />
            <Icon
              svg={ArrowsDownUpIcon}
              size={IconSize.Small}
              className={'text-grey-500 origin-center transform rotate-90'}
            />
            <Icon
              svg={
                MAP_SALES_CHANNEL_TO_AI_AVAILABILITY_TABLE_ICON[salesChannel]
              }
              size={IconSize.Large}
            />
          </div>
          <div className="text-gray-900 font-normal text-base leading-none">
            {intl.formatMessage(
              {
                id: I18nKey.PLAN_SWITCH_MODAL_CONNECTED_MERCHANTS,
              },
              { noOfConnectedMerchants: mcList.length }
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'flex flex-col items-center px-8 pt-4 gap-y-16',
          'h-full w-1/2',
          {
            'scrollbar scrollbar-thin scrollbar-thumb-grey-400 scrollbar-track-grey-100 scrollbar-thumb-rounded-full':
              mcList.length > 2,
          }
        )}
      >
        {mcList.map((mc: MerchantCountry) => (
          <McListRow key={mc.merchantCountryId} merchantCountry={mc} />
        ))}
      </div>
    </div>
  );
};
