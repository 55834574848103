import classNames from 'classnames';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ReactModal from 'react-modal';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  LightningIcon,
} from '@teikametrics/tm-design-system';

import I18nKey from '../../../lib/types/I18nKey';
import { useScript } from '../../../lib/hooks/useScript';

export interface AutomateVideoPopupProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

export const AutomateVideoPopup: React.FC<AutomateVideoPopupProps> = ({
  isOpen,
  onClose,
}) => {
  const intl = useIntl();

  const [
    HEADER,
    DESCRIPTION,
    SUBHEADER,
    SUBHEADER_DESCRIPTION,
    CLOSE_BUTTON_TEXT,
  ] = [
    I18nKey.COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_HEADER,
    I18nKey.COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_DESCRIPTION,
    I18nKey.COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_SUBHEADER,
    I18nKey.COMPASS_AUTOMATE_BIDDING_VIDEO_POPUP_SUBHEADER_DESCRIPTION,
    I18nKey.CLOSE,
  ].map((id) => intl.formatMessage({ id }));

  return (
    <ReactModal
      isOpen={isOpen}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      overlayClassName="fixed inset-0 z-50 bg-grey-900 bg-opacity-60"
      data-test-id="automate-video-popup"
    >
      <div
        className={classNames(
          'relative p-24 bg-white w-400 text-center',
          'rounded border border-solid border-grey-200'
        )}
      >
        <div
          className={classNames(
            'inline-block h-32 w-32 bg-purple-100 rounded-full',
            'bg-gradient-to-r from-purple-500 via-magenta-500 to-orange-500'
          )}
        >
          <span className="align-middle">
            <Icon
              className="text-white"
              svg={LightningIcon}
              size={IconSize.Medium}
            />
          </span>
        </div>
        <div className="text-xl text-grey-900 font-semibold pt-8 leading-none">
          {HEADER}
        </div>
        <div className="text-sm text-grey-600 pt-12 mb-12">{DESCRIPTION}</div>
        <AutomateAdvertisingVideoPlayer />
        <div
          className={classNames(
            'bg-purple-50 mt-12 p-12',
            'border border-solid border-grey-200 rounded'
          )}
        >
          <div className="font-semibold text-base">{SUBHEADER}</div>
          <div className="text-sm text-grey-700 mt-4">
            {SUBHEADER_DESCRIPTION}
          </div>
        </div>
        <div className="pt-24">
          <Button
            label={CLOSE_BUTTON_TEXT}
            variant={ButtonVariant.BlackAndWhiteBorder}
            size={ButtonSize.Large}
            onClick={onClose}
          />
        </div>
      </div>
    </ReactModal>
  );
};
AutomateVideoPopup.displayName = 'AutomateVideoPopup';

const AutomateAdvertisingVideoPlayer: React.FC = () => {
  useScript('https://fast.wistia.com/embed/medias/epoqygritc.jsonp');
  useScript('https://fast.wistia.com/assets/external/E-v1.js');

  const [opacity, setOpacity] = useState('0');

  return (
    <div
      className="wistia_responsive_padding relative com_automateAdsTutorial"
      style={{ padding: '56.25% 0 0 0' }}
      data-test-id="com_automateAdsTutorial"
    >
      <div className="wistia_responsive_wrapper h-full left-0 absolute top-0 w-full">
        <div className="wistia_embed wistia_async_epoqygritc videoFoam=true h-full relative w-full">
          <div
            className="wistia_swatch h-full left-0 overflow-hidden absolute top-0 w-full"
            style={{
              opacity,
              transition: 'opacity 200ms',
            }}
          >
            <img
              src="https://fast.wistia.com/embed/medias/epoqygritc/swatch"
              style={{
                filter: 'blur(5px)',
              }}
              className="w-full h-full object-contain"
              alt=""
              aria-hidden="true"
              onLoad={() => setOpacity('1')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
AutomateAdvertisingVideoPlayer.displayName = 'AutomateAdvertisingVideoPlayer';
