import { SalesChannelData } from '../../../../../lib/types/Fam';

export const SALES_CHANNELS_TABLE = 'SALES_CHANNELS_TABLE';

export interface SalesChannelTableData {
  dataTestId?: string;
  isAmazon?: boolean;
}

export type RowCellElementProps = SalesChannelData & SalesChannelTableData;
