import {
  AmazonLogomarkEnabledIcon as AmazonIcon,
  SvgIcon,
  WalmartLogomarkEnabledIcon as WalmartIcon,
} from '@teikametrics/tm-design-system';

import { FlywheelSalesChannel } from './Fam';
import I18nKey from './I18nKey';

export const WALMART_SALES_CHANNEL_ID = '820d060b-1d53-4aab-99c2-03a12e3dcf29';
export const AMAZON_SALES_CHANNEL_ID = '8414e1d4-3f48-4f11-9ffe-ac3e4f6dc774';

export const MAP_SALES_CHANNEL_ID_TO_NAME: Record<
  string,
  FlywheelSalesChannel
> = {
  [AMAZON_SALES_CHANNEL_ID]: FlywheelSalesChannel.Amazon,
  [WALMART_SALES_CHANNEL_ID]: FlywheelSalesChannel.Walmart,
};

export const MAP_SALES_CHANNEL_NAME_TO_ID: Record<
  FlywheelSalesChannel,
  string
> = {
  [FlywheelSalesChannel.Amazon]: AMAZON_SALES_CHANNEL_ID,
  [FlywheelSalesChannel.Walmart]: WALMART_SALES_CHANNEL_ID,
};

export const MAP_FLYWHEEL_SALES_CHANNEL_TO_I18NKEY: Record<
  FlywheelSalesChannel,
  I18nKey
> = {
  [FlywheelSalesChannel.Amazon]: I18nKey.GENERIC_AMAZON,
  [FlywheelSalesChannel.Walmart]: I18nKey.GENERIC_WALMART,
};

export const MAP_FLYWHEEL_SALES_CHANNEL_TO_ICON: Record<
  FlywheelSalesChannel,
  SvgIcon
> = {
  [FlywheelSalesChannel.Amazon]: AmazonIcon,
  [FlywheelSalesChannel.Walmart]: WalmartIcon,
};

export const MAP_SALES_CHANNEL_ID_TO_ICON_MAPPER: Record<string, SvgIcon> = {
  [AMAZON_SALES_CHANNEL_ID]: AmazonIcon,
  [WALMART_SALES_CHANNEL_ID]: WalmartIcon,
};
