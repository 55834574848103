import { fromNullable } from 'fp-ts/lib/Option';
import produce, { Draft } from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import find from 'lodash/find';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import times from 'lodash/times';

import {
  AsyncRequestCompleted,
  AsyncRequestFailed,
  AsyncRequestKinds,
  AsyncRequestLoading,
  IdentifiedAsyncRequest,
  asyncRequestIdentifiedBy,
  asyncRequestIsLoading,
} from '../../../lib/utilities/asyncRequest';

import {
  APPLY_BULK_CHANGES,
  CHANGE_SELECT,
  CLEAR_ALL_CHANGES,
  CLEAR_BUFFER,
  CLEAR_FILTER,
  CLEAR_FILTERS,
  CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS,
  DATA_LOAD_BUFFER_COMPLETE,
  DATA_LOAD_COMPLETE,
  DATA_LOAD_FAILED,
  DATA_LOAD_INITIATED,
  DATA_RESET,
  DELETE_ITEMS,
  FOOTER_DATA_LOAD_COMPLETE,
  FOOTER_DATA_LOAD_FAILED,
  FOOTER_DATA_LOAD_INITIATED,
  PATCH_SINGLE_ITEM,
  SELECT_ALL_ITEMS,
  SET_CELL_CHANGE,
  SET_ITEMS_PER_PAGE,
  SET_UPDATED_ITEMS,
  SET_VISIBLE_PAGE,
  SORT_APPLIED,
  SORT_REMOVED,
  TOGGLE_SELECTED_ITEM,
  TableAction,
  TableDataAction,
  UPDATE_CELL,
  UPDATE_EXTRA_PAGINATION_PARAMS,
  UPDATE_FILTERS,
  UPDATE_GLOBAL,
  updateFilters,
} from './actions';
import {
  DEFAULT_TABLE_CHANGES,
  DEFAULT_TABLE_STATE,
  tableSelectors,
} from './index';
import {
  PercentageBasedColumnInfo,
  Table,
  TableCellChange,
  TableReducerState,
  UPDATE_CELL_VALIDATION_DATA_KEY,
  UpdateCellPayload,
  VisiblePageKind,
} from './types';
import sortBy from 'lodash/sortBy';
import { isEqual, isNotUndefined } from '../../../lib/utilities/typeGuards';
import { Filter, FilterOps } from '../../../lib/types/Filter';

const initialState = {};

function fillEmptyPages<T>(
  pages: Array<IdentifiedAsyncRequest<T[], void>>,
  asyncRequestToFillWith: IdentifiedAsyncRequest<T[], void>,
  indexToFillTo: number
): Array<IdentifiedAsyncRequest<T[], void>> {
  const pagesCopy = [...pages];
  const pagesToFill = times<number>(indexToFillTo + 1, identity);
  pagesToFill.forEach((pageNumber) => {
    pagesCopy[pageNumber] = pagesCopy[pageNumber] || asyncRequestToFillWith;
  });
  return pagesCopy;
}

function tableDataReducer<T, F>(
  state: TableReducerState<T, F>,
  action: TableDataAction
): TableReducerState<T, F> {
  const identifiedAsyncRequest = asyncRequestIdentifiedBy<
    Array<Draft<T>>,
    void
  >(action.payload.requestId);

  return produce<TableReducerState<T, F>>(state, (tableState) => {
    const tableId = action.payload.tableId;
    const pageIdx = action.payload.page - 1;
    const maybeCurrentPageRequest = fromNullable(tableState[tableId]).chain(
      (table) => fromNullable(table.pages[pageIdx])
    );
    // we should only process a completion or failure if the current page request
    // is loading and the current page request's id matches the id of the new
    // requests result
    const shouldProcessRequestResult = maybeCurrentPageRequest.exists(
      (r) => asyncRequestIsLoading(r) && r.id === action.payload.requestId
    );

    tableState[tableId] = tableState[tableId] || {
      ...DEFAULT_TABLE_STATE,
    };
    tableState[tableId].pages = fillEmptyPages(
      tableState[tableId].pages,
      identifiedAsyncRequest.AsyncRequestNotStarted(),
      pageIdx
    );

    switch (action.type) {
      case DATA_LOAD_INITIATED:
        tableState[tableId].pages[pageIdx] =
          identifiedAsyncRequest.AsyncRequestLoading();
        break;
      case DATA_LOAD_COMPLETE:
        if (shouldProcessRequestResult) {
          tableState[tableId].maybeTotalItems = action.payload.totalItems;
          tableState[tableId].pages[pageIdx] =
            identifiedAsyncRequest.AsyncRequestCompleted(
              action.payload.items as Array<Draft<T>>
            );
        }
        break;
      case DATA_LOAD_FAILED:
        if (shouldProcessRequestResult) {
          tableState[tableId].pages[pageIdx] =
            identifiedAsyncRequest.AsyncRequestFailed(undefined);
        }
        break;
    }

    return tableState;
  });
}

const filterFieldDoesNotMatch =
  (fields: string[]) =>
  (filter: Filter): boolean =>
    !fields.some((field) => field === filter.field);

function getFilters<T, F>(
  table: Table<T, F>,
  action: ReturnType<typeof updateFilters>
): Filter[] {
  const newFilterFields = action.payload.filters.map(
    (filter: { field: any }) => filter.field
  );
  const filterDoesNotMatchFields = filterFieldDoesNotMatch(newFilterFields);

  if (action.payload.replace) {
    return [...action.payload.filters];
  }

  return [
    ...table.filters.filter(filterDoesNotMatchFields),
    ...action.payload.filters,
  ];
}

function getUpdateTable<T, F>(
  tableId: string,
  existingTables: TableReducerState<T, F>,
  tableMerge: (table: Table<T, F>) => Table<T, F>
): Table<T, F> {
  const maybeTable = fromNullable(existingTables[tableId]);
  return maybeTable.fold(tableMerge(DEFAULT_TABLE_STATE), tableMerge);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function sanitizeTableCellChanges<T, F>(
  cellChanges: TableCellChange,
  pageData: T[],
  columnDataMapping: Record<string, string>,
  uniqKey: string,
  percentageBasedColumns?: PercentageBasedColumnInfo[],
  booleanColumns?: string[],
  arrayBasedColumns?: string[]
) {
  const newCellChanges: Record<string, Record<string, string>> = {};

  keys(cellChanges).forEach((rowId: string) => {
    const rowData = find(pageData, { [uniqKey]: rowId });

    const rowChanges: Record<string, string> = {};

    keys(cellChanges[rowId]).forEach((columnName: string) => {
      let changedValue = cellChanges[rowId][columnName] ?? '';
      let originalValue = get(rowData, columnDataMapping[columnName]) ?? '';

      const maybePercentageBasedColumn = percentageBasedColumns?.find(
        (item) => item.columnName === columnName
      );

      const maybeBooleanColumn = booleanColumns?.find(
        (item) => item === columnName
      );

      const maybeArrayBasedColumn = arrayBasedColumns?.find(
        (item) => item === columnName
      );

      // Need to multiply original value by 100 since we store values in redux like 3.5 and not 0.035.
      if (!isUndefined(maybePercentageBasedColumn) && originalValue !== '') {
        originalValue = (originalValue * 100).toFixed(
          maybePercentageBasedColumn.maxDecimalDigits
        );
      }

      // Need to transform the values for comparison since API returns boolean values but we store string vales in the redux store.
      if (!isUndefined(maybeBooleanColumn) && !isNil(originalValue)) {
        originalValue = originalValue.toString();
      }

      // Needs to transform the values to string after parsing since the API Returns JSON and we have array of strings on changedValue
      if (!isUndefined(maybeArrayBasedColumn) && !isNil(originalValue)) {
        originalValue = sortBy(
          JSON.parse(JSON.stringify(originalValue))
        ).toString();
        changedValue = sortBy(changedValue.split(',')).toString();
      }

      if (!isEqual(changedValue, originalValue)) {
        rowChanges[columnName] = changedValue;
      } else {
        delete rowChanges[columnName];
      }
    });

    if (keys(rowChanges).length) {
      newCellChanges[rowId] = rowChanges;
    }
  });

  return newCellChanges;
}

const areUpdatedAndOriginalValuesEqual = (
  numericValue: boolean | undefined,
  value: string,
  existingValue: string
) => {
  if (numericValue) {
    /* 
      Convert to number and check only when both updated value and existing value are present. 
      Else the expression will result true when the existing value is 0 and updated value is '' which gets converted to 0 due to Number(value)
    */
    if (isEmpty(value) && isEmpty(existingValue)) {
      return true;
    } else if (value && existingValue) {
      return Number(value) === Number(existingValue);
    } else {
      return false;
    }
  } else {
    return value === existingValue;
  }
};

const isInOrNotInFilter = (filter: Filter, field: string) =>
  ((filter.op === FilterOps.in || filter.op === FilterOps.notIn) &&
    filter.value.length > 0) ||
  (filter.op !== FilterOps.in &&
    filter.op !== FilterOps.notIn &&
    filter.field !== field);

const getUpdatedFilterList = (filter: Filter, field: string, value: string) => {
  if (
    filter.field === field &&
    (filter.op === FilterOps.in || filter.op === FilterOps.notIn)
  ) {
    filter = {
      op: filter.op,
      field: filter.field,
      value: filter.value.filter((filterValue) => filterValue !== value),
    };
  }
  return filter;
};

function getVisiblePage<T, F>(
  tableState: WritableDraft<TableReducerState<T, F>>,
  tableId: string,
  page: number
) {
  if (isNotUndefined(tableState[tableId])) {
    const table = tableState[tableId];
    if (isNumber(table.visiblePage)) {
      table.visiblePage = page;
    } else {
      table.visiblePage = {
        kind: VisiblePageKind.SinglePage,
        page: page,
      };
    }
    if (table.bufferItemsToLoad) {
      table.toggleBufferItemsChanged = !table.toggleBufferItemsChanged;
    }
  }
  return tableState;
}

function patchSingleItem<T, F>(
  tableState: WritableDraft<TableReducerState<T, F>>,
  tableId: string,
  itemIdentifier: (item: unknown) => boolean,
  changes: any
) {
  const table = tableState[tableId];
  table.pages.forEach(
    (page, i) =>
      page.kind === AsyncRequestKinds.Completed &&
      page.result.forEach((item, j) => {
        if (itemIdentifier(item)) {
          page.result[j] = {
            ...item,
            ...changes,
          };
        }
      })
  );
  return tableState;
}

function deleteRecords<T, F>(
  tableState: WritableDraft<TableReducerState<T, F>>,
  tableId: string,
  itemIdentifier: (item: unknown) => boolean
) {
  const table = tableState[tableId];
  /*Keeps track of the pages to be removed
  Why:->
  When a user loads say for eg: 5 pages.
  The buffer will contain the elements of 6th page.
  Now the user comes back to 3rd page and delete's an item.
  Now we will have to fetch the item from the buffer page which is now moved to 4th page.
  [NOTE: Buffer is updated dynamically when moving between pages]
  And hence we will have to refresh the actual 4th & 5th page to get the new results.
  So clearing those forward left over pages.*/
  let pagesToClear: number = 0;
  table.pages.forEach((page, i) => {
    const toBeRemovedIndices: number[] = [];
    if (
      page.kind === AsyncRequestKinds.Completed &&
      i + 1 === table.visiblePage
    ) {
      page.result.forEach((item, j) => {
        if (itemIdentifier(item)) {
          toBeRemovedIndices.push(j);
        }
      });
      // Sort the indexes in descending order to avoid issues when removing
      toBeRemovedIndices.sort((a, b) => b - a);

      toBeRemovedIndices.forEach((index) => {
        page.result.splice(index, 1);
      });
      // Also add in the same number of indices removed from the buffer
      if (
        toBeRemovedIndices.length > 0 &&
        table.bufferItems &&
        table.maybeTotalItems
      ) {
        page.result.push(
          ...table.bufferItems.splice(0, toBeRemovedIndices.length)
        );
        table.toggleBufferItemsChanged = !table.toggleBufferItemsChanged;
        // Update the maybeTotalItems as we have accepted or rejected a recommendation
        table.maybeTotalItems -= toBeRemovedIndices.length;
        pagesToClear = table.pages.length - table.visiblePage;
      }
      return {
        ...page,
        result: page.result,
      };
    }
  });
  if (pagesToClear) {
    // Remove the number of pages from the right
    // So that next time its loaded using the API
    table.pages = table.pages.slice(0, -pagesToClear);
  }
  return tableState;
}

function updateCell<T, F>(
  tableState: WritableDraft<TableReducerState<T, F>>,
  payload: UpdateCellPayload
) {
  const {
    numericValue,
    value,
    existingValue,
    columnName,
    validationData,
    tableId,
    rowId,
  } = payload;
  const table = tableState[tableId];

  if (!table.changes.cell[rowId]) {
    table.changes.cell[rowId] = {};
  }

  const areValueEqual = areUpdatedAndOriginalValuesEqual(
    numericValue,
    value,
    existingValue
  );

  if (areValueEqual) {
    if (
      table.changes.cell[rowId] &&
      table.changes.cell[rowId][columnName] !== undefined
    ) {
      delete table.changes.cell[rowId][columnName];

      if (isEmpty(table.changes.cell[rowId])) {
        delete table.changes.cell[rowId];
      }
    }
  } else {
    table.changes.cell[rowId][columnName] = value;
    if (validationData) {
      table.changes.cell[rowId][UPDATE_CELL_VALIDATION_DATA_KEY] =
        validationData;
    }
  }
  return tableState;
}

function resetData<T, F>(
  tableState: WritableDraft<TableReducerState<T, F>>,
  tableId: string
) {
  if (isNotUndefined(tableState[tableId])) {
    tableState[tableId].pages = [];
  }

  return tableState;
}

function reducer<T, F>(
  state: TableReducerState<T, F> = initialState,
  action: TableAction
): TableReducerState<T, F> {
  switch (action.type) {
    case SET_VISIBLE_PAGE:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        return getVisiblePage(
          tableState,
          action.payload.tableId,
          action.payload.page
        );
      });
    case SORT_APPLIED:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            sorts: [action.payload.sort],
          })
        ),
      };
    case SORT_REMOVED:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            sorts: table.sorts.filter(
              (sort) => sort.column !== action.payload.column
            ),
          })
        ),
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            filters: getFilters(table, action),
          })
        ),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            filters: table.filters.filter(
              (filter) => filter.field !== action.payload.field
            ),
          })
        ),
      };
    case CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            filters: table.filters
              .map((filter) =>
                getUpdatedFilterList(
                  filter,
                  action.payload.field,
                  action.payload.value
                )
              )
              .filter((filter) =>
                isInOrNotInFilter(filter, action.payload.field)
              ),
          })
        ),
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            filters: [],
          })
        ),
      };
    case UPDATE_EXTRA_PAGINATION_PARAMS:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            extraPaginationParams: action.payload.extraPaginationParams,
          })
        ),
      };
    case DATA_LOAD_INITIATED:
    case DATA_LOAD_COMPLETE:
    case DATA_LOAD_FAILED:
      return tableDataReducer(state, action);
    case DATA_RESET:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        return resetData(tableState, action.payload.tableId);
      });
    case FOOTER_DATA_LOAD_INITIATED:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            footerData: AsyncRequestLoading(),
          })
        ),
      };
    case FOOTER_DATA_LOAD_COMPLETE:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            footerData: AsyncRequestCompleted(action.payload.footerData),
          })
        ),
      };
    case FOOTER_DATA_LOAD_FAILED:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            footerData: AsyncRequestFailed(undefined),
          })
        ),
      };
    case SET_ITEMS_PER_PAGE:
      return {
        ...state,
        [action.payload.tableId]: getUpdateTable(
          action.payload.tableId,
          state,
          (table: Table<T, F>) => ({
            ...table,
            itemsPerPage: action.payload.itemsPerPage,
          })
        ),
      };

    case SET_UPDATED_ITEMS:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const tableId = action.payload.tableId;
        const pageNo = action.payload.page;
        if (
          isNotUndefined(tableState[tableId]) &&
          isNotUndefined(tableState[tableId].pages[pageNo]) &&
          tableState[tableId].pages[pageNo].kind === AsyncRequestKinds.Completed
        ) {
          const identifiedAsyncRequest = asyncRequestIdentifiedBy<
            Array<Draft<T>>,
            void
          >(tableState[tableId].pages[pageNo].id);

          tableState[tableId].pages[pageNo] =
            identifiedAsyncRequest.AsyncRequestCompleted(
              action.payload.updatedItems as Array<Draft<T>>
            );
        }
        return tableState;
      });
    case PATCH_SINGLE_ITEM:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, itemIdentifier, changes } = action.payload;
        return patchSingleItem(tableState, tableId, itemIdentifier, changes);
      });
    case DELETE_ITEMS:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, itemIdentifier } = action.payload;
        deleteRecords(tableState, tableId, itemIdentifier);
        return tableState;
      });
    case SET_CELL_CHANGE:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const {
          tableId,
          rowId,
          columnName,
          columnDataMapping,
          uniqKey,
          value,
        } = action.payload;
        const table = tableState[tableId];
        if (!table.changes.cell[rowId]) {
          table.changes.cell[rowId] = {};
        }
        table.changes.cell[rowId][columnName] = value;
        const pageData = tableSelectors.getVisibleData(table);
        const tableCellChanges = sanitizeTableCellChanges(
          table.changes.cell,
          pageData,
          columnDataMapping,
          uniqKey
        );
        table.changes.cell = tableCellChanges;
        return tableState;
      });
    case CLEAR_ALL_CHANGES:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId } = action.payload;
        const table = tableState[tableId];
        table.changes = {
          ...DEFAULT_TABLE_CHANGES,
          global: table.changes.global,
        };
        return tableState;
      });
    case TOGGLE_SELECTED_ITEM:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, rowId } = action.payload;
        const table = tableState[tableId];

        if (table.changes.select.rows.includes(rowId)) {
          table.changes.select.rows = table.changes.select.rows.filter(
            (selectedRowId) => selectedRowId !== rowId
          );
        } else {
          table.changes.select.rows.push(rowId);
        }

        return tableState;
      });
    case APPLY_BULK_CHANGES:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const {
          tableId,
          bulkChanges,
          columnDataMapping,
          uniqKey,
          percentageBasedColumns,
          booleanColumns,
          arrayBasedColumns,
        } = action.payload;
        const table = tableState[tableId];
        const pageData = tableSelectors.getVisibleData(table);
        table.changes.cell = sanitizeTableCellChanges(
          bulkChanges,
          pageData,
          columnDataMapping,
          uniqKey,
          percentageBasedColumns,
          booleanColumns,
          arrayBasedColumns
        );
        return tableState;
      });
    case CHANGE_SELECT:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, selectedRows } = action.payload;
        const table = tableState[tableId];
        table.changes.select.rows = selectedRows;
        return tableState;
      });
    case SELECT_ALL_ITEMS:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, rowIds } = action.payload;
        const table = tableState[tableId];

        if (table.changes.select.rows) {
          table.changes.select.rows = rowIds;
        }

        return tableState;
      });
    case UPDATE_CELL:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        return updateCell(tableState, action.payload);
      });
    case UPDATE_GLOBAL:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, updatedValues } = action.payload;
        const table = tableState[tableId];
        table.changes.global = updatedValues;
        return tableState;
      });
    case DATA_LOAD_BUFFER_COMPLETE:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId, items } = action.payload;
        const table = tableState[tableId];
        table.bufferItems = items as Array<Draft<T>>;
        return tableState;
      });
    case CLEAR_BUFFER:
      return produce<TableReducerState<T, F>>(state, (tableState) => {
        const { tableId } = action.payload;
        const table = tableState[tableId];
        table.bufferItems = [];
        return tableState;
      });
    default:
      return state;
  }
}

export default reducer;
