import isNil from 'lodash/isNil';

import { AdType } from '../../../../../lib/types/AOSharedTypes';
import { getMerchantFromLocalStorage } from '../utils/storageUtils';

export const getSalesChannel = (
  salesChannelId: string | null,
  selectedSalesChannel: string | null
) => (isNil(salesChannelId) ? String(selectedSalesChannel) : salesChannelId);

export const getMerchantCountries = (
  merchantCountryIds: string | null,
  userId: string,
  accountId: string,
  salesChannelId: string | null,
  selectedSalesChannel: string | null
) =>
  isNil(merchantCountryIds)
    ? (getMerchantFromLocalStorage({
        userId,
        accountId,
        salesChannelId: salesChannelId || String(selectedSalesChannel),
      }) as (string | number)[])
    : merchantCountryIds.split(',');

export const getAdType = (
  adTypesFromQueryParams: string | null,
  adTypes: AdType[]
) =>
  isNil(adTypesFromQueryParams)
    ? adTypes
    : (adTypesFromQueryParams.split(',') as AdType[]);
