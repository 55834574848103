import {
  Alignment,
  BulkManageLinear,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  DownloadFileStepProps,
  FileUploadDownloadModal,
  FileUploadDownloadModalState,
  MoreInfoStepProps,
  Placement,
  Theme,
  Tooltip,
  TooltipSize,
  Type,
  UploadErrorProps,
  UploadFileStepProps,
  UploadSuccessStepProps,
  ValidationErrorsProps,
  ValidationProgressProps,
} from '@teikametrics/tm-design-system';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../containers/notificationProvider';
import { getCurrentAccountFromContext } from '../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../containers/userProvider/userProvider';
import I18nKey from '../../../lib/types/I18nKey';
import React, {
  ChangeEvent,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDownloadFile } from '../../../lib/hooks/downloadFile';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '../../../containers/customUploadProvider/customUploadHelper';
import { AOApiClient } from '../../../lib/clients/AOApiClient';
import { AxiosError, AxiosResponse } from 'axios';
import polling from 'rx-polling';
import { defer } from 'rxjs';
import {
  AdType,
  CampaignDataRequest,
  CampaignStatus,
  ManageBiddingUploadStatusResponse,
} from '../../../lib/types/AOSharedTypes';
import first from 'lodash/first';
import {
  CSV_UPLOAD_SYNC_STATUS_POLLING_INTERVAL,
  CSV_UPLOAD_SYNC_STATUS_POLLING_MAX_TRIES,
  UploadStatusResponses,
  ValidateUploadErrorResponsesStructure,
  ValidationCount,
} from '../containers/adsManager/types';
import { MerchantType } from '../../../lib/types/Fam';
import { useSelector } from 'react-redux';
import { AdOptimizationState } from '../redux/types';
import { tableSelectors } from '../../../containers/table/ducks';
import { ADS_MANAGER_CAMPAIGNS_TABLE_ID } from '../containers/adsManager/ducks/types';
import { Sort } from '../../../lib/types/Sort';
import { CAMPAIGNS_API_COLUMN_NAME } from '../containers/adsManager/utils';
import {
  Filter,
  FilterFieldMapper,
  FilterOps,
  NotInFilter,
} from '../../../lib/types/Filter';
import { WALMART_SALES_CHANNEL_ID } from '../../../lib/types/SalesChannels';
import { HttpResponseCode } from '../../../lib/clients/types';
import { ViewerRoleTooltip } from '../../../lib/utilities/viewerRoleTooltipWrapper';

const initialErrorAndTotalCount: ValidationCount = {
  errorCount: 0,
  totalCount: 0,
};

export const getUploadValidDataButtonState = (
  areThereNoValidationErrors: boolean,
  isValidDataUploading: boolean
) => {
  const buttonStateForValidationErrors = areThereNoValidationErrors
    ? ButtonState.Active
    : ButtonState.Disabled;
  return isValidDataUploading
    ? ButtonState.Loading
    : buttonStateForValidationErrors;
};

export const getUploadErrorMessage = (
  intl: IntlShape,
  isColumnMismatchValidationError: boolean
) => {
  if (isColumnMismatchValidationError) {
    return intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_COLUMN_MISMATCH_ERROR,
    });
  } else {
    return intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_GENERIC_ERROR,
    });
  }
};

export const getValidDataUploadButtonState = (
  errorAndTotalCount: ValidationCount,
  uploadingValidData: boolean
) => {
  const uploadingButtonState = uploadingValidData
    ? ButtonState.Loading
    : ButtonState.Active;

  const areAllRowsInvalid =
    errorAndTotalCount.totalCount - errorAndTotalCount.errorCount === 0;

  return areAllRowsInvalid ? ButtonState.Disabled : uploadingButtonState;
};

interface OwnProps {
  readonly aoApiClient: AOApiClient;
  readonly merchantCountryId: string;
  readonly merchantType?: MerchantType;
  readonly salesChannelId: string;
  readonly adType: AdType;
  readonly filterFieldMapper?: FilterFieldMapper[];
  readonly refreshTable: () => void;
  readonly campaignExportRequest: () => CampaignDataRequest;
  readonly isUserRoleViewOnly?: boolean;
}

export const CampaignGroupingModal: React.FC<OwnProps> = ({
  merchantCountryId,
  aoApiClient,
  merchantType,
  salesChannelId,
  adType,
  filterFieldMapper,
  refreshTable,
  campaignExportRequest,
  isUserRoleViewOnly = false,
}) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [campaignModalState, setCampaignModalState] =
    useState<FileUploadDownloadModalState>(
      FileUploadDownloadModalState.Default
    );
  const [isFileDownloaded, setIsFileDownloaded] = useState<boolean>(false);
  const [isErrorFileDownloaded, setIsErrorFileDownloaded] =
    useState<boolean>(false);
  const [isErrorFileDownloading, setIsErrorFileDownloading] =
    useState<boolean>(false);

  const [errorAndTotalCount, setErrorAndTotalCount] = useState<ValidationCount>(
    initialErrorAndTotalCount
  );
  const [noValidationError, setNoValidationError] = useState<boolean>(false);
  const [validRecordsFileName, setValidRecordsFileName] = useState<string>('');
  const [invalidRecordsFileName, setInvalidRecordsFileName] =
    useState<string>('');

  const [isColumnMismatchValidationError, setIsColumnMismatchValidationError] =
    useState<boolean>(false);

  const [uploadingValidData, setUploadingValidData] = useState<boolean>(false);

  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);

  const toasts = useContext<NotificationContextState>(NotificationContext);
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;

  const campaignExport = campaignExportRequest();

  const templateFileName = `campaign-grouping-${DateTime.local().toFormat(
    DATE_FORMAT
  )}.csv`;
  const templateErrorFileName = `campaign-grouping-${DateTime.local().toFormat(
    DATE_FORMAT
  )}-errors.csv`;

  const currentTableFilters = useSelector<AdOptimizationState, Filter[]>(
    ({ tableState }) => {
      return tableSelectors.getFiltersSelector()(
        tableState,
        ADS_MANAGER_CAMPAIGNS_TABLE_ID
      );
    }
  );

  const campaignStatusToBeExcluded = useMemo(() => {
    return salesChannelId === WALMART_SALES_CHANNEL_ID
      ? [CampaignStatus.Completed]
      : [CampaignStatus.Archived];
  }, [salesChannelId]);

  const filters: Filter[] = useMemo(() => {
    let updatedFilters;
    const campaignStatusDefaultFilter = {
      field: CAMPAIGNS_API_COLUMN_NAME.CampaignStatus,
      op: FilterOps.notIn,
      value: campaignStatusToBeExcluded,
    } as NotInFilter;

    // if no filters applied, return just status != archived
    if (!currentTableFilters || currentTableFilters?.length === 0) {
      return [campaignStatusDefaultFilter];
    }
    const isCampaignStatusFilterPresent = currentTableFilters?.findIndex(
      (filter) => filter.field === CAMPAIGNS_API_COLUMN_NAME.CampaignStatus
    );

    if (isCampaignStatusFilterPresent === -1) {
      // if status filter doesn't exist in current filters, add in status != archived
      updatedFilters = [...currentTableFilters, campaignStatusDefaultFilter];
    } else {
      // if status filter does exist in current filters, overwrite with status != archived
      updatedFilters = currentTableFilters.map((filter) => {
        if (filter.field === CAMPAIGNS_API_COLUMN_NAME.CampaignStatus) {
          return campaignStatusDefaultFilter;
        } else {
          return { ...filter };
        }
      });
    }

    return updatedFilters;
  }, [currentTableFilters]);

  const sorts = useSelector<AdOptimizationState, Sort[]>(({ tableState }) => {
    return tableSelectors.getSortsSelector()(
      tableState,
      ADS_MANAGER_CAMPAIGNS_TABLE_ID
    );
  });

  const failureDownloadToast = () =>
    toasts.addNotification({
      headline: intl.formatMessage({
        id: I18nKey.COGS_MODAL_STEP_1_DOWNLOAD_HEADLINE,
      }),
      description: intl.formatMessage(
        {
          id: I18nKey.COGS_MODAL_STEP_1_DOWNLOAD_DESCRIPTION,
        },
        {
          template: intl.formatMessage({
            id: I18nKey.ADS_MANAGER_CAMPAIGN_GROUPING_DOWNLOAD_TEMPLATE_NAME,
          }),
        }
      ),
      type: Type.Attention,
      dataTestId: 'am_campaignGroupingDownloadError',
    });

  const onClickCampaignGroupingBtn = () => setShowModal(true);

  const onUploadSuccess = () => {
    refreshTable();
    cleanUpModal();
  };

  const cleanUpModal = () => {
    resetState();
    setShowModal(false);
  };

  const resetState = () => {
    setIsFileDownloaded(false);
    setCampaignModalState(FileUploadDownloadModalState.Default);
    setUploadedFile(null);
    setErrorAndTotalCount(initialErrorAndTotalCount);
    setIsColumnMismatchValidationError(false);
    setNoValidationError(false);
    setIsErrorFileDownloaded(false);
  };

  const exportTemplateApi = () => {
    return aoApiClient.exportCampaignGroupingCsv(
      accountId,
      merchantCountryId,
      salesChannelId,
      adType,
      campaignExport,
      filters,
      sorts,
      filterFieldMapper,
      merchantType
    );
  };

  const exportUploadErrorFileApi = () => {
    return aoApiClient.exportCampaignGroupingErrorCsv(
      accountId,
      invalidRecordsFileName
    );
  };

  const onErrorDownloadFile = () => {
    setCampaignModalState(FileUploadDownloadModalState.Default);
    failureDownloadToast();
  };

  const postDownloadFile = () => setIsFileDownloaded(true);

  const preDownloadErrorFile = () => setIsErrorFileDownloading(true);
  const postDownloadErrorFile = () => {
    setIsErrorFileDownloading(false);
    setIsErrorFileDownloaded(true);
  };

  const onFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const files: FileList = (event.target.files as FileList) || [];
    const selectedFile = first(files);
    if (selectedFile) {
      setUploadedFile(selectedFile);
      setCampaignModalState(FileUploadDownloadModalState.Default);
    }
  };

  const onFileRemove = () => {
    setCampaignModalState(FileUploadDownloadModalState.Default);
    setUploadedFile(null);
  };

  const { downloadFile } = useDownloadFile({
    asyncFunction: exportTemplateApi,
    preDownloadFile: () => {},
    postDownloadFile,
    onError: onErrorDownloadFile,
    fileName: templateFileName,
  });

  const { downloadFile: downloadErrorFile } = useDownloadFile({
    asyncFunction: exportUploadErrorFileApi,
    preDownloadFile: preDownloadErrorFile,
    postDownloadFile: postDownloadErrorFile,
    onError: onErrorDownloadFile,
    fileName: templateErrorFileName,
  });

  const onDownloadClick = () => {
    setCampaignModalState(FileUploadDownloadModalState.Downloading);
    downloadFile();
  };

  const onClickUploadFile = () => {
    if (uploadedFile) {
      setCampaignModalState(FileUploadDownloadModalState.Uploading);
      getSecureUrl(uploadedFile);
    }
  };

  const onCompleteUploadClick = (validDataFileName: string) => {
    setUploadingValidData(true);
    aoApiClient
      .customGroupingValidDataUpload(accountId, validDataFileName)
      .then((res) => {
        setUploadingValidData(false);
        setCampaignModalState(FileUploadDownloadModalState.Uploading);

        const uploadStatus = () =>
          aoApiClient.customGroupingS3FileUploadStatus(
            accountId,
            String(res.data.jobId)
          );

        const syncStatusRunsObservable = defer(() => uploadStatus());
        const subscription = polling(syncStatusRunsObservable, {
          interval: CSV_UPLOAD_SYNC_STATUS_POLLING_INTERVAL,
          attempts: CSV_UPLOAD_SYNC_STATUS_POLLING_MAX_TRIES,
        }).subscribe(
          (response: AxiosResponse<ManageBiddingUploadStatusResponse, any>) => {
            const status = response.data.status;
            if (status === UploadStatusResponses.SUCCESS) {
              subscription.unsubscribe();
              setCampaignModalState(FileUploadDownloadModalState.Uploaded);
            }
            if (
              status === UploadStatusResponses.FAILED ||
              status === UploadStatusResponses.UNKNOWN
            ) {
              subscription.unsubscribe();
              setCampaignModalState(FileUploadDownloadModalState.ErrorInUpload);
            }
          }
        );

        return () => subscription.unsubscribe();
      })
      .catch(() => {
        setCampaignModalState(
          FileUploadDownloadModalState.ErrorInValidDataUpload
        );
        setUploadingValidData(false);
      });
  };

  const validateUpload = async (fileName: string) => {
    setCampaignModalState(FileUploadDownloadModalState.Validating);
    aoApiClient
      .campaignGroupingValidationRequest(accountId, fileName)
      .then((res) => {
        if (res.data.invalidRows) {
          setCampaignModalState(FileUploadDownloadModalState.ValidationError);
          setErrorAndTotalCount({
            errorCount: res.data.invalidRows,
            totalCount: res.data.totalRows,
          });
          setInvalidRecordsFileName(res.data.validationErrorFileName!);
        } else {
          setCampaignModalState(FileUploadDownloadModalState.Validating);
          setNoValidationError(true);
        }
        setValidRecordsFileName(res.data.successRecordFileName!);
      })
      .catch((err: AxiosError<ValidateUploadErrorResponsesStructure>) => {
        if (err.response?.status === HttpResponseCode.BadRequest) {
          setIsColumnMismatchValidationError(true);
        }
        setCampaignModalState(
          FileUploadDownloadModalState.ErrorInValidDataUpload
        );
      });
  };

  const uploadCampaignGroupingFile = async (
    file: File,
    fileName: string,
    uploadUrl: string
  ) => {
    aoApiClient
      .manageBiddingS3FileUpload(uploadUrl, file)
      .then((_res) => validateUpload(fileName))
      .catch(() =>
        setCampaignModalState(
          FileUploadDownloadModalState.ErrorInValidDataUpload
        )
      );
  };

  const getSecureUrl = async (file: File) => {
    const timeInSeconds = DateTime.local().toMillis();
    const uniqueFileName = `${accountId}_${timeInSeconds}_${file.name}`;
    setCampaignModalState(FileUploadDownloadModalState.Uploading);
    aoApiClient
      .getCampaignGroupingS3SecureUrl(accountId, uniqueFileName)
      .then((res) =>
        uploadCampaignGroupingFile(file, uniqueFileName, res.data.url)
      )
      .catch((err) =>
        setCampaignModalState(
          FileUploadDownloadModalState.ErrorInValidDataUpload
        )
      );
  };

  const downloadStepProps: DownloadFileStepProps = {
    header: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_HEADER,
    }),
    description: intl.formatMessage<ReactNode>(
      {
        id: I18nKey.ADS_MANAGER_CAMPAIGN_GROUPING_DOWNLOAD_STEP_HEADER,
      },
      {
        bold: (content) => <span className="font-semibold">{content}</span>,
      }
    ),
    instructions: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGN_GROUPING_DOWNLOAD_STEP_DESCRIPTION,
    }),
    buttonText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_BUTTON,
    }),
    downloadSuccessText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_DOWNLOAD_STEP_SUCESS,
    }),
    downloadButtonSize: ButtonSize.Small,
  };

  const moreInfoStepProps: MoreInfoStepProps = {
    header: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_HEADER,
    }),
    description: intl.formatMessage<ReactNode>(
      {
        id: I18nKey.ADS_MANAGER_CAMPAIGN_GROUPING_UPDATE_STEP_HEADER,
      },
      {
        bold: (content) => <span className="font-semibold">{content}</span>,
      }
    ),
    linkText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_MORE_INFO_STEP_LINK,
    }),
  };

  const uploadFileStepProps: UploadFileStepProps = {
    header: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_HEADER,
    }),
    description: intl.formatMessage<ReactNode>(
      {
        id: I18nKey.ADS_MANAGER_CAMPAIGN_GROUPING_UPLOAD_STEP_HEADER,
      },
      {
        bold: (content) => <span className="font-semibold">{content}</span>,
      }
    ),
    errorText: getUploadErrorMessage(intl, isColumnMismatchValidationError),
    retryButtonText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_RETRY_BUTTON,
    }),
    attachFileText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_ATTACH_FILE,
    }),
    uploadFileText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_FILE_STEP_UPLOAD_BUTTON,
    }),
    contentUploadingMessage: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOADING_FILE_MESSAGE,
    }),
  };

  const uploadSuccessStepProps: UploadSuccessStepProps = {
    header: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_HEADER,
    }),
    description: intl.formatMessage<ReactNode>(
      {
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_DESCRIPTION,
      },
      {
        bold: (content) => <span className="font-semibold">{content}</span>,
      }
    ),
    buttonText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_SUCCESS_BUTTON,
    }),
  };

  const getValidationErrorsProps = (
    validationCount: ValidationCount
  ): ValidationErrorsProps => ({
    header: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_ERRORS_HEADER,
    }),
    description: intl.formatMessage(
      {
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_ERRORS_DESCRIPTION,
      },
      {
        validCount: validationCount.totalCount - validationCount.errorCount,
        totalCount: validationCount.totalCount,
      }
    ),
    downloadFileButtonProps: {
      buttonState: isErrorFileDownloading
        ? ButtonState.Loading
        : ButtonState.Active,
      buttonText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_ERROR_DOWNLOAD_BUTTON,
      }),
      downloadSuccessText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_ERROR_DOWNLOAD_BUTTON_TEXT,
      }),
      isFileDownloaded: isErrorFileDownloaded,
      onClickDownloadFile: downloadErrorFile,
      downloadButtonSize: ButtonSize.Large,
    },
    uploadFileButtonProps: {
      onUploadValidClick: () => onCompleteUploadClick(validRecordsFileName),
      uploadButtonState: getValidDataUploadButtonState(
        errorAndTotalCount,
        uploadingValidData
      ),
      uploadButtonText: intl.formatMessage({
        id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_STEP_ERRORS_BUTTON_TEXT,
      }),
    },
  });

  const getValidationProgressProps = (
    areThereNoValidationErrors: boolean,
    isValidDataUploading: boolean
  ): ValidationProgressProps => ({
    buttonText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_BUTTON_TEXT,
    }),
    onCompleteUploadClick: () => onCompleteUploadClick(validRecordsFileName),
    header: areThereNoValidationErrors
      ? intl.formatMessage({
          id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_NO_ERRORS_HEADER,
        })
      : intl.formatMessage({
          id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_VALIDATION_STEP_HEADER,
        }),
    buttonVariant: ButtonVariant.Primary,
    noValidationErrors: areThereNoValidationErrors,
    buttonState: getUploadValidDataButtonState(
      areThereNoValidationErrors,
      isValidDataUploading
    ),
  });

  const getUploadErrorProps = (): UploadErrorProps => ({
    buttonText: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_TRY_AGAIN,
    }),
    description: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_CAMPAIGN_GROUPING_UPLOAD_STEP_COLUMN_MISMATCH_ERROR,
    }),
    header: intl.formatMessage({
      id: I18nKey.ADS_MANAGER_MANAGE_BIDDING_MODAL_UPLOAD_STEP_ERRORS_HEADER,
    }),
    onTryAgainClick: resetState,
  });

  return (
    <>
      <Tooltip
        content={intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGN_GROUPING_MANAGE_GROUPING_TOOLTIP,
        })}
        overwrittenTooltipClassnames="w-auto max-w-400"
        theme={Theme.Dark}
        tooltipSize={TooltipSize.Small}
        hideArrow
        disabled={!!isUserRoleViewOnly}
      >
        <ViewerRoleTooltip
          position={{ placement: Placement.Left, alignment: Alignment.Center }}
          disabled={!isUserRoleViewOnly}
        >
          <Button
            svgIcon={BulkManageLinear}
            onClick={
              isUserRoleViewOnly ? undefined : onClickCampaignGroupingBtn
            }
            size={ButtonSize.Medium}
            state={
              isUserRoleViewOnly ? ButtonState.Disabled : ButtonState.Enabled
            }
            variant={ButtonVariant.BlackAndWhiteBorder}
            dataTestId={'CSV_bulkEdit_campaign-groups'}
          />
        </ViewerRoleTooltip>
      </Tooltip>
      <FileUploadDownloadModal
        showModal={showModal}
        onClickDownloadFile={onDownloadClick}
        externalUrl={''}
        onClickUploadFile={onClickUploadFile}
        uploadedStateOnClick={onUploadSuccess}
        onFileSelected={onFileSelected}
        onFileRemove={onFileRemove}
        onClose={cleanUpModal}
        onClickRetry={resetState}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        modalState={campaignModalState}
        isFileDownloaded={isFileDownloaded}
        selectedFileName={uploadedFile?.name}
        modalHeader={intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGN_GROUPING_MANAGE_GROUPING_LABEL,
        })}
        uploadSuccessStepProps={uploadSuccessStepProps}
        downloadFileStepProps={downloadStepProps}
        moreInfoStepProps={moreInfoStepProps}
        uploadFileStepProps={uploadFileStepProps}
        validationErrorsProps={getValidationErrorsProps(errorAndTotalCount)}
        validationProgressProps={getValidationProgressProps(
          noValidationError,
          uploadingValidData
        )}
        uploadErrorProps={getUploadErrorProps()}
      />
    </>
  );
};
CampaignGroupingModal.displayName = 'CampaignGroupingModal';
