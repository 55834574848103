import { Factory } from 'fishery';

import {
  AssignmentMode,
  MissingTagsResponse,
} from '../../types/AIPoweredSharedTypes';

export const MerchantsWithMissingTagCount = Factory.define<MissingTagsResponse>(
  () => ({
    elements: [
      {
        isOptedOut: false,
        productCount: 55,
        merchantCountryId: '1c497a44-34dc-49ec-8cc2-e1937fe515e0',
        assignMode: AssignmentMode.All,
      },
      {
        isOptedOut: false,
        productCount: 55,
        merchantCountryId: '3419da8c-a617-4fe1-b15a-6f9470cc832d',
        assignMode: AssignmentMode.All,
      },
      {
        isOptedOut: true,
        productCount: 55,
        merchantCountryId: '3c12642d-a484-41c8-b807-fa0dc040631e',
        assignMode: AssignmentMode.All,
      },
      {
        merchantCountryId: '528c26e9-d52d-49d4-8feb-6487ca5f1f6a',
        assignMode: AssignmentMode.All,
        isOptedOut: false,
        productCount: 5,
      },
    ],
  })
);
