import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContentStyle,
  ControlledTooltip,
  NumericInputProps,
  NumericInputState,
  Placement,
  TypedTooltipProps,
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { useBidConstraintsContext } from '../../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { getBidConstraint } from '../../../../../containers/bidConstraintsProvider/biddingConstraints';
import {
  tableActions,
  tableSelectors,
} from '../../../../../containers/table/ducks';
import { WithTable } from '../../../../../containers/table/ducks/types';
import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  makeCurrencyColumn,
  makeNumericInputColumn,
} from '../../../../../containers/table/utils/makeTableCells';
import {
  AdGroupDetails,
  AdGroupStatus,
  AdLevel,
  AdType,
  CampaignTargetingType,
  ChannelSettingsAdgroup,
  DetailAdGroup,
  FlywheelSalesChannel,
  MerchantCountryCode,
  TeikaSettingsAdgroup,
} from '../../../../../lib/types/AOSharedTypes';
import { MONETARY_FRACTION_DIGITS } from '../../../../../lib/types/CommonSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { ADS_MANAGER_ADGROUP_TABLE_ID } from '../ducks/types';
import {
  AdLevelI8nKeyMapper,
  CURRENCY_CODE,
  NumericValueConfig,
  TableDataAdsManager,
} from '../types';
import {
  ADGROUPS_API_COLUMN_NAME,
  DEBOUNCE_AFTER_IN_MILLISECONDS,
  getAutomationDisabledForLegacyCampaignTooltipMessage,
  getAutomationDisabledTooltipMessage,
  getOrDefaultIsBiddableFlag,
  getTooltipContentForArchivedEntity,
  isAdGroupStatusArchived,
  isCampaignAdFormatEqualToVideo,
  isCampaignCostTypeVcpm,
  isCampaignStatusArchived,
  isCurrentValueGreaterThanRequiredMaxValue,
  isCurrentValueLessThanRequiredMinValue,
  isInputValueNumber,
  isValidFlywheelSetting,
} from '../utils';
import {
  getCurrencyCodeFromMerchantCountryCode,
  getCurrencySymbolFromMerchantCountryCode,
} from '../../../../../lib/utilities/currency';

const getControlledTooltipState = (
  isAdGroupArchived: boolean,
  focussed: boolean,
  numericInputState: NumericInputState
) => {
  if (isAdGroupArchived) {
    return ControlledTooltip.None;
  }

  return focussed && numericInputState === NumericInputState.Error
    ? ControlledTooltip.Show
    : ControlledTooltip.Hide;
};

const getTooltipContent = (
  intl: IntlShape,
  value: string,
  isInValidSetting: boolean,
  validationConfig: NumericValueConfig,
  tooltipParameters: {
    isCampaignCostTypeEqualToVcpm: boolean;
    automationDisabledForMerchant: boolean;
    automationDisabledForLegacyRow: boolean;
    isAdGroupArchived: boolean;
    isValueLessThanMinBidValue: boolean;
    isValueGreaterThanCurrentMaxBid: boolean;
    isValueGreaterThanMaxBidValue: boolean;
  },
  currencySymbol: string | undefined
) => {
  if (tooltipParameters.isCampaignCostTypeEqualToVcpm) {
    return (
      <p className="w-180 text-center">
        {intl.formatMessage({
          id: I18nKey.ADS_MANAGER_FLYWHEEL_SETTINGS_UNSET_TOOLTIP,
        })}
      </p>
    );
  }

  if (tooltipParameters.automationDisabledForMerchant) {
    return getAutomationDisabledTooltipMessage(
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_BID,
      })
    );
  }

  if (tooltipParameters.automationDisabledForLegacyRow) {
    return getAutomationDisabledForLegacyCampaignTooltipMessage();
  }

  if (tooltipParameters.isAdGroupArchived) {
    return getTooltipContentForArchivedEntity(
      intl.formatMessage({
        id: AdLevelI8nKeyMapper[AdLevel.AdGroups],
      }),
      intl.formatMessage({
        id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_BID,
      }),
      intl
    );
  } else if (isInValidSetting) {
    if (tooltipParameters.isValueGreaterThanCurrentMaxBid) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage({
            id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_INVALID_GREATER_THAN_MAX_BID,
          })}
        </p>
      );
    }
    if (
      validationConfig.min &&
      validationConfig.max &&
      (tooltipParameters.isValueLessThanMinBidValue ||
        tooltipParameters.isValueGreaterThanMaxBidValue ||
        isEmpty(value))
    ) {
      return (
        <p className="w-180 text-center">
          {intl.formatMessage(
            {
              id: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_EDIT_AMAZON_INVALID_BID,
            },
            {
              minValue: intl.formatNumber(validationConfig.min, {
                minimumFractionDigits: MONETARY_FRACTION_DIGITS,
                maximumFractionDigits: MONETARY_FRACTION_DIGITS,
              }),
              maxValue: intl.formatNumber(validationConfig.max),
              currencySymbol,
            }
          )}
        </p>
      );
    }
  }
};

const getExtraValidations = (
  flywheelSalesChannel: FlywheelSalesChannel,
  adGroupStatus: AdGroupStatus | undefined,
  adGroupDetails: DetailAdGroup
) => {
  if (flywheelSalesChannel === FlywheelSalesChannel.Amazon) {
    return () =>
      !isAdGroupStatusArchived(adGroupStatus) &&
      !isCampaignStatusArchived(adGroupDetails.campaignStatus);
  }
};

const getMinBidNumericInputState = (
  bidValue: { value: string; existingValue: string },
  validationConfig: NumericValueConfig,
  updatedCurrentMaxBid: string,
  isInputDisabled: boolean,
  isValidSettings: boolean,
  isInputEdited: boolean,
  extraValidations: (() => boolean) | undefined
) => {
  if (isInputDisabled) {
    return NumericInputState.Disabled;
  }

  if (!isValidSettings) {
    return NumericInputState.Error;
  }

  if (isInputEdited) {
    return isMinBidValid(
      bidValue.value,
      updatedCurrentMaxBid,
      validationConfig.min,
      validationConfig.max,
      extraValidations,
      bidValue.existingValue
    )
      ? NumericInputState.Default
      : NumericInputState.Error;
  }

  return NumericInputState.Default;
};

const getPlaceholderValue = (validationConfig: NumericValueConfig) => {
  let placeholderValue;
  if (!isUndefined(validationConfig.default)) {
    placeholderValue = validationConfig.default;
  } else if (!isUndefined(validationConfig.min)) {
    placeholderValue = validationConfig.min;
  } else if (!isUndefined(validationConfig.max)) {
    placeholderValue = validationConfig.max;
  }

  return placeholderValue;
};

const getPlaceholderText = (
  intl: IntlShape,
  placeholderValue: number | undefined
) =>
  placeholderValue
    ? intl.formatNumber(placeholderValue, {
        minimumFractionDigits: MONETARY_FRACTION_DIGITS,
        maximumFractionDigits: MONETARY_FRACTION_DIGITS,
      })
    : undefined;

const getCurrencyCode = (
  currencyCode: string | null,
  details: AdGroupDetails
) =>
  currencyCode || details?.flywheelSettings?.minBid?.currency || CURRENCY_CODE;

const checkIsDirty = (value: string, existingValue: string) =>
  value || existingValue
    ? parseFloat(value) !== parseFloat(existingValue)
    : false;

const checkIsAdGroupArchived = (
  channelSettings: ChannelSettingsAdgroup,
  adGroupDetails: DetailAdGroup
) =>
  isAdGroupStatusArchived(channelSettings.status) ||
  isCampaignStatusArchived(adGroupDetails.campaignStatus);

const getCurrencySymbol = (
  merchantCountry: MerchantCountryCode,
  flywheelSettings: TeikaSettingsAdgroup
) =>
  getCurrencySymbolFromMerchantCountryCode(merchantCountry) ||
  flywheelSettings.minBid?.currency;

const checkIsAutomationDisabledForMerchant = (
  merchantsWithAutomationEnabled: string[] | undefined,
  adGroupDetails: DetailAdGroup
) =>
  !merchantsWithAutomationEnabled?.includes(
    adGroupDetails.merchantCountryId ?? ''
  );

const getExistingValue = (flywheelSettings: TeikaSettingsAdgroup) =>
  flywheelSettings.minBid?.amount?.toString() || '';

const getUpdatedCurrentMaxBid = (
  currentMaxBid: string,
  flywheelSettings: TeikaSettingsAdgroup
) => currentMaxBid || flywheelSettings.maxBid?.amount || '';

export const shouldUpdateValue = (
  isEditMode: boolean,
  updatedMinBid: string,
  value: string
) => {
  return (
    isEditMode || //Changing between modes
    (!isNil(updatedMinBid) && updatedMinBid !== value) // Changes done due to bulk update. updatedMinBid is latest, but state variable is not
  );
};

export const RowCellElement: React.FC<AdGroupDetails & TableDataAdsManager> = ({
  adGroupId,
  adGroupDetails,
  channelSettings,
  flywheelSettings,
  adGroupPerformance,
  isEditMode,
  selectedAdType,
  merchantCountry,
  salesChannel,
  merchantType,
  merchantsWithAutomationEnabled,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [focussed, setFocussed] = useState<boolean>(false);

  const bidConstraintsData = useBidConstraintsContext();

  const currentPage = useSelector<WithTable<AdGroupDetails>, number>(
    ({ tableState }) =>
      tableSelectors.getCurrentPageSelector()(
        tableState,
        ADS_MANAGER_ADGROUP_TABLE_ID
      )
  );

  const data: AdGroupDetails = {
    adGroupId,
    adGroupDetails,
    channelSettings,
    flywheelSettings,
    adGroupPerformance,
  };
  const { campaignCostType } = adGroupDetails;
  const updatedMinBid = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MinBid
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );
  const campaignTargetingType: CampaignTargetingType | undefined = data
    .adGroupDetails.campaignTargetingType as CampaignTargetingType;

  const validationConfig = useMemo(() => {
    const constrainsts = getBidConstraint(
      bidConstraintsData.constraints,
      isCampaignAdFormatEqualToVideo(adGroupDetails.campaignAdFormat)
        ? AdType.SponsoredBrandsVideo
        : selectedAdType,
      salesChannel,
      ADGROUPS_API_COLUMN_NAME.MinBid,
      merchantCountry,
      campaignTargetingType,
      merchantType,
      campaignCostType
    );

    return {
      default: constrainsts.defaultMinBid,
      min: constrainsts.minBid,
      max: constrainsts.maxBid,
    };
  }, [
    selectedAdType,
    salesChannel,
    merchantCountry,
    campaignTargetingType,
    merchantType,
    adGroupDetails.campaignAdFormat,
  ]);

  const isCampaignCostTypeEqualToVcpm =
    isCampaignCostTypeVcpm(campaignCostType);
  const currencySymbol = getCurrencySymbol(merchantCountry, flywheelSettings);

  const currencyCode = getCurrencyCodeFromMerchantCountryCode(merchantCountry);

  const existingValue = getExistingValue(flywheelSettings);

  let minBid = updatedMinBid ?? existingValue;

  const isInputEdited = existingValue !== minBid;

  const [value, setValue] = useState<string>(minBid);

  const isDirty = checkIsDirty(value, existingValue);

  useEffect(() => {
    if (shouldUpdateValue(isEditMode, updatedMinBid, value)) {
      setValue(minBid);
    }
  }, [isEditMode, currentPage, updatedMinBid]);

  const currentMaxBid = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MaxBid
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const updatedCurrentMaxBid = getUpdatedCurrentMaxBid(
    currentMaxBid,
    flywheelSettings
  );

  const currentMacsTarget = useSelector<WithTable<AdGroupDetails>, string>(
    ({ tableState }) =>
      tableSelectors.getCellSelector(
        data.adGroupId,
        ADGROUPS_API_COLUMN_NAME.MACSTarget
      )(tableState, ADS_MANAGER_ADGROUP_TABLE_ID)
  );

  const adGroupStatus = channelSettings.status;

  const updateCellValue = (inputValue: string) => {
    dispatch(
      tableActions.updateCell({
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
        rowId: data.adGroupId,
        columnName: ADGROUPS_API_COLUMN_NAME.MinBid,
        value: inputValue,
        existingValue,
        numericValue: true,
      })
    );
  };

  const debouncedUpdateCellValue = useCallback(
    debounce(updateCellValue, DEBOUNCE_AFTER_IN_MILLISECONDS),
    [data.adGroupId]
  );

  const isValidSettings = useMemo(
    () =>
      isValidFlywheelSetting(
        flywheelSettings,
        {
          minBid: minBid,
          maxBid: currentMaxBid,
          macsTarget: currentMacsTarget,
        },
        minBid
      ),
    [flywheelSettings, minBid, currentMaxBid, currentMacsTarget]
  );

  if (isEditMode) {
    const isValueGreaterThanMaxBidValue =
      isCurrentValueGreaterThanRequiredMaxValue(validationConfig.max, value);

    const isValueLessThanMinBidValue = isCurrentValueLessThanRequiredMinValue(
      validationConfig.min,
      value
    );

    const isValueGreaterThanCurrentMaxBid =
      isCurrentValueGreaterThanRequiredMaxValue(
        Number(updatedCurrentMaxBid),
        value
      );

    const automationDisabledForMerchant = checkIsAutomationDisabledForMerchant(
      merchantsWithAutomationEnabled,
      adGroupDetails
    );

    const automationDisabledForLegacyRow = !getOrDefaultIsBiddableFlag(
      adGroupDetails.isBiddable
    );

    const isInputDisabled =
      isAdGroupStatusArchived(adGroupStatus) ||
      isCampaignStatusArchived(adGroupDetails.campaignStatus) ||
      automationDisabledForMerchant ||
      isCampaignCostTypeEqualToVcpm ||
      automationDisabledForLegacyRow;

    const minBidNumericInputState: NumericInputState =
      getMinBidNumericInputState(
        { value, existingValue },
        validationConfig,
        updatedCurrentMaxBid,
        isInputDisabled,
        isValidSettings,
        isInputEdited,
        getExtraValidations(salesChannel, adGroupStatus, adGroupDetails)
      );
    const onMinBidInputChange = (inputValue: string) => {
      if (isInputValueNumber(inputValue)) {
        setValue(inputValue);
        debouncedUpdateCellValue(inputValue);
      }
    };
    const isAdGroupArchived = checkIsAdGroupArchived(
      channelSettings,
      adGroupDetails
    );

    const onInputFocus = () => setFocussed(true);
    const onInputBlur = () => {
      if (!isEmpty(value)) {
        debouncedUpdateCellValue.cancel();
        const formattedValue = parseFloat(value).toFixed(
          MONETARY_FRACTION_DIGITS
        );
        setValue(formattedValue);
        updateCellValue(formattedValue);
      }
      setFocussed(false);
    };

    let placeholderValue: number | undefined =
      getPlaceholderValue(validationConfig);

    const PLACEHOLDER_TEXT = getPlaceholderText(intl, placeholderValue);

    const minBidNumericInputProps: NumericInputProps & TypedTooltipProps = {
      value: value,
      state: minBidNumericInputState,
      prependedElement: currencySymbol,
      onInputBlur: onInputBlur,
      onChange: onMinBidInputChange,
      isDirty,
      tooltipContent: getTooltipContent(
        intl,
        value,
        isDirty || !isValidSettings,
        validationConfig,
        {
          automationDisabledForLegacyRow,
          automationDisabledForMerchant,
          isAdGroupArchived,
          isCampaignCostTypeEqualToVcpm,
          isValueGreaterThanCurrentMaxBid,
          isValueGreaterThanMaxBidValue,
          isValueLessThanMinBidValue,
        },
        currencySymbol
      ),
      controlledTooltip: getControlledTooltipState(
        isAdGroupArchived,
        focussed,
        minBidNumericInputState
      ),
      tooltipPlacement: Placement.Bottom,
      style: ContentStyle.Bold,
      onInputFocus: onInputFocus,
      acceptOnlyPositiveNumbers: true,
      placeholder: PLACEHOLDER_TEXT,
      minFractionDigits: MONETARY_FRACTION_DIGITS,
      maxFractionDigits: MONETARY_FRACTION_DIGITS,
      dataTestId: 'max_bid',
    };

    return makeNumericInputColumn(() => minBidNumericInputProps)(data);
  }

  return makeCurrencyColumn(
    (details: AdGroupDetails) =>
      details.flywheelSettings.minBid && {
        amount: Number(details.flywheelSettings.minBid.amount),
        currency: getCurrencyCode(currencyCode, details),
      }
  )(data);
};
RowCellElement.displayName = 'RowCellElement';

export const minBidColumn: FlywheelTableColumn<
  AdGroupDetails,
  TableDataAdsManager
> = {
  columnName: ADGROUPS_API_COLUMN_NAME.MinBid,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_BID,
  RowCellElement,
  gridColumnWidth: '160px',
  className: 'text-right',
  columnHeaderClassName: 'justify-end',
};

export const minBidFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    ADGROUPS_API_COLUMN_NAME.MinBid,
    I18nKey.ADS_MANAGER_ADGROUP_TABLE_COLUMN_MIN_BID,
    currency,
    isValidNumber(0),
    true
  );

export function isMinBidValid(
  currentMinBidValue: string,
  currentMaxBidValue: string,
  minLimit?: number,
  maxLimit?: number,
  extraValidation?: () => boolean,
  existingValue?: string
) {
  const isValid =
    currentMinBidValue &&
    !isCurrentValueLessThanRequiredMinValue(minLimit, currentMinBidValue) &&
    !isCurrentValueGreaterThanRequiredMaxValue(maxLimit, currentMinBidValue) &&
    (isEmpty(currentMaxBidValue)
      ? true
      : !isCurrentValueGreaterThanRequiredMaxValue(
          Number(currentMaxBidValue),
          currentMinBidValue
        ));

  if (existingValue === '' && currentMinBidValue === existingValue) {
    return extraValidation ? extraValidation() : true;
  } else if (extraValidation) {
    return isValid && extraValidation();
  } else {
    return isValid;
  }
}
