import noop from 'lodash/noop';
import React, { createContext, useState } from 'react';

import { MerchantCountry } from '../../lib/types/Fam';

export interface MerchantContextState {
  merchantDetails: MerchantCountry[];
  updateMerchantDetails: (merchantDetails: MerchantCountry[]) => void;
}

export interface MerchantProviderProps {
  children: JSX.Element;
}

const initialState = {
  merchantDetails: [],
  updateMerchantDetails: noop,
};

const MerchantContext = createContext<MerchantContextState>(initialState);
MerchantContext.displayName = 'MerchantContext';

const { Provider } = MerchantContext;

const MerchantProvider: React.FC<MerchantProviderProps> = ({ children }) => {
  const [merchantDetails, updateMerchantDetails] = useState<MerchantCountry[]>(
    []
  );

  return (
    <Provider value={{ merchantDetails, updateMerchantDetails }}>
      {children}
    </Provider>
  );
};
MerchantProvider.displayName = 'MerchantProvider';

export { MerchantContext, MerchantProvider };
