import './flywheelTablePagination.scss';

import React, { useContext, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { TableFooter } from '@teikametrics/tm-design-system';

import I18nKey from '../../lib/types/I18nKey';
import { storeMaxRowPerPageInStorage } from '../../modules/advertisingOptimization/containers/adsManager/utils/storageUtils';
import { getCurrentAccountFromContext } from '../userProvider/selectors';
import { UserContext, UserContextState } from '../userProvider/userProvider';
import { tableActions, tableSelectors } from './ducks';
import { VisiblePage, VisiblePageKind, WithTable } from './ducks/types';
import { storeCurrentPageToSessionStorage } from '../../lib/utilities/storageUtils';
import isNumber from 'lodash/isNumber';

interface UpdatedFlywheelTablePaginationRowProps {
  readonly tableId: string;
  readonly totalItemsI18nKey: I18nKey;
  readonly dataTestId?: string;
}

interface StateFromRedux {
  readonly itemsPerPage: number;
  readonly currentPage: number;
  readonly totalElements: number;
}

const maxRows: number[] = [25, 50, 100, 150, 200];

export const getCurrentVisiblePage = (visiblePage: VisiblePage) => {
  if (isNumber(visiblePage)) {
    return visiblePage;
  } else {
    return visiblePage.kind === VisiblePageKind.SinglePage
      ? visiblePage.page
      : 1;
  }
};

function UpdatedFlywheelTablePaginationRow<T>(
  props: UpdatedFlywheelTablePaginationRowProps
) {
  const dispatch = useDispatch();
  const userContext = useContext<UserContextState>(UserContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;
  const userId = userContext.userInfo.userDetails?.id || '';

  const { itemsPerPage, currentPage, totalElements } = useSelector<
    WithTable<T, StateFromRedux>,
    StateFromRedux
  >((state) => {
    const table = tableSelectors.getTableSelector()(
      state.tableState,
      props.tableId
    );

    return {
      currentPage: getCurrentVisiblePage(table.visiblePage),
      itemsPerPage: table.itemsPerPage,
      totalElements: table.maybeTotalItems ?? 0,
    };
  }, shallowEqual);

  const [currentPageInputValue, setCurrentPageInputValue] = useState<
    number | undefined
  >();

  // When the user uses the "Arrow Navigation" to change pages we need to sync
  // up the value of the input
  React.useEffect(() => {
    setCurrentPageInputValue(currentPage);
  }, [currentPage]);

  const loadPreviousPage = () => {
    dispatch(
      tableActions.setVisiblePage({
        page: currentPage - 1,
        tableId: props.tableId,
      })
    );
    storeCurrentPageToSessionStorage(userId, accountId, currentPage - 1);
  };

  const loadNextPage = () => {
    dispatch(
      tableActions.setVisiblePage({
        page: currentPage + 1,
        tableId: props.tableId,
      })
    );
    storeCurrentPageToSessionStorage(userId, accountId, currentPage + 1);
  };

  const totalPages = Math.ceil(totalElements / itemsPerPage) || 1;

  const onPageChange = (nextValue: string) => {
    setCurrentPageInputValue(Number(nextValue));
    storeCurrentPageToSessionStorage(userId, accountId, Number(nextValue));
  };

  const handlePageInputBlurEvent = () => {
    const maybeValidNextPage = tableSelectors.getPageValueForInputValue({
      pageInputValue: currentPageInputValue,
      totalPages,
    });

    if (maybeValidNextPage && maybeValidNextPage !== currentPage) {
      dispatch(
        tableActions.setVisiblePage({
          page: maybeValidNextPage,
          tableId: props.tableId,
        })
      );
    } else {
      setCurrentPageInputValue(currentPage);
    }
  };

  const onPageSizeOptionChange = (pageSize: string) => {
    storeMaxRowPerPageInStorage(userId, pageSize);
    dispatch(
      tableActions.setItemsPerPage({
        itemsPerPage: Number(pageSize),
        tableId: props.tableId,
      })
    );
  };
  return (
    <TableFooter
      currentPage={currentPage}
      totalPages={totalPages}
      loadNextPage={loadNextPage}
      loadPreviousPage={loadPreviousPage}
      totalItems={totalElements}
      onPageInputChange={onPageChange}
      currentSelectValue={itemsPerPage}
      onSelectOptionChange={onPageSizeOptionChange}
      selectOptions={maxRows.map((value) => ({
        label: value.toString(),
        value,
      }))}
      totalItemsTextI18nKey={props.totalItemsI18nKey}
      currentPageInputValue={
        currentPageInputValue ? currentPageInputValue.toString() : ''
      }
      onBlurInput={handlePageInputBlurEvent}
      dataTestId={props.dataTestId}
    />
  );
}

UpdatedFlywheelTablePaginationRow.displayName =
  'UpdatedFlywheelTablePaginationRow';
export default UpdatedFlywheelTablePaginationRow;
