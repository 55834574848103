import { CheckboxCheckedState } from '@teikametrics/tm-design-system';
import isNil from 'lodash/isNil';
import { FlywheelTableColumnGroup } from '../../../../../containers/table/UpdatedFlywheelTable';
import {
  FlywheelSalesChannel,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { TableDataAdsManager } from '../types';
import { adConversionRateColumn } from './adConversionRate';
import { adConversionsColumn } from './adConversions';
import { adGroupNameColumn } from './adGroupName';
import { adGroupReviewStatusColumn } from './adGroupReviewStatus';
import { adGroupStatusColumn } from './adGroupStatus';
import { adSpendColumn } from './adSpend';
import { addedByColumn } from './addedBy';
import { advertisedSKUUnitsColumn } from './advertisedSkuUnits';
import { averageCostPerClickColumn } from './averageCostPerClick';
import { bidColumn } from './bidColumn';
import { campaignCostTypeColumn } from './campaignCostType';
import { campaignNameColumn } from './campaignName';
import { campaignStatusColumn } from './campaignStatus';
import { campaignTacticColumn } from './campaignTactic';
import { campaignTargetingTypeColumn } from './campaignTargetingType';
import { campaignTypeColumn } from './campaignType';
import { clickThroughRateColumn } from './clickThroughRate';
import { clicksColumn } from './clicks';
import { dailyBudgetColumn } from './dailyBudget';
import { dateAddedColumn } from './dateAdded';
import { directAcosColumn } from './directAcos';
import { directAdSalesColumn } from './directAdSales';
import { directRoasColumn } from './directRoas';
import { expressionTypeColumn } from './expressionType';
import { impressionsColumn } from './impressions';
import { keywordColumn } from './keyword';
import { keywordReviewStatusColumn } from './keywordReviewStatus';
import { matchTypeColumn } from './matchType';
import { merchantNameColumn } from './merchantName';
import { newToBrandAdSalesColumn } from './newToBrandAdSales';
import { newToBrandAdUnitsSoldColumn } from './newToBrandAdUnitsSold';
import { newToBrandOrdersColumn } from './newToBrandOrders';
import { newToBrandOrdersRateColumn } from './newToBrandOrdersRate';
import { ordersColumn } from './orders';
import { otherSKUUnitsColumn } from './otherSkuUnits';
import { percentageOfAdUnitsSoldNewToBrandColumn } from './percentageOfAdUnitsSoldNewToBrand';
import { percentageOfOrdersNewToBrandColumn } from './percentageOfOrdersNewToBrand';
import { percentageOfSalesNewToBrandColumn } from './percentageOfSalesNewToBrand';
import { portfolioNameColumn } from './portfolioName';
import { relatedClickRevenueColumn } from './relatedClickRevenue';
import { tagsColumn } from './tags';
import { targetStatusColumn } from './targetStatus';
import { targetTextColumn } from './targetText';
import { targetTypeColumn } from './targetType';
import { targetingExpressionColumn } from './targetingExpression';
import { totalAcosColumn } from './totalAcos';
import { totalAdSalesColumn } from './totalAdSales';
import { totalBudgetColumn } from './totalBudget';
import { totalRoasColumn } from './totalRoas';
import { unitsSoldColumn } from './unitsSold';

export const generatedTargetsColumns = (
  isEditMode: boolean,
  salesChannel: FlywheelSalesChannel,
  inCludeTargetSummeryColumns: boolean = false,
  hasCampaignNegativeTargets = false,
  isAiEnabled?: boolean,
  headerExtraProps?: {
    readonly checked: CheckboxCheckedState;
    readonly onSelectChange: () => void;
  }
): FlywheelTableColumnGroup<TargetsDetails, TableDataAdsManager>[] => {
  const additionalHeaderProps = () => {
    return {
      ...(!isNil(headerExtraProps)
        ? {
            showCheckBox: isEditMode,
            checked: headerExtraProps.checked,
            onSelectChange: headerExtraProps.onSelectChange,
          }
        : {}),
    };
  };

  return [
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_GROUP_DETAILS,
      columnsInGroup: [
        {
          ...targetTextColumn,
          ...additionalHeaderProps(),
        },
        {
          ...targetingExpressionColumn,
          ...additionalHeaderProps(),
        },
        {
          ...keywordColumn,
          ...additionalHeaderProps(),
        },
      ],
    },
    {
      columnsInGroup: [
        expressionTypeColumn,
        merchantNameColumn,
        matchTypeColumn,
        ...(!isAiEnabled && salesChannel === FlywheelSalesChannel.Walmart
          ? []
          : [addedByColumn, tagsColumn]),
        ...(inCludeTargetSummeryColumns ? [dateAddedColumn] : []),
        targetTypeColumn,
        keywordReviewStatusColumn,
        ...(!hasCampaignNegativeTargets
          ? [adGroupNameColumn, adGroupStatusColumn]
          : []),
        adGroupReviewStatusColumn,
        campaignNameColumn,
        campaignTacticColumn,
        campaignTypeColumn,
        campaignTargetingTypeColumn,
        dailyBudgetColumn,
        totalBudgetColumn,
        campaignStatusColumn,
        portfolioNameColumn,
        campaignCostTypeColumn,
      ],
    },
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_GROUP_CHANNEL_SETTINGS,
      columnsInGroup: [targetStatusColumn, bidColumn],
    },
    {
      groupingHeaderI18nKey:
        I18nKey.ADS_MANAGER_TARGETS_TABLE_COLUMN_GROUP_PERFORMANCE,
      columnsInGroup: [
        totalAdSalesColumn,
        adSpendColumn,
        totalAcosColumn,
        totalRoasColumn,
        adConversionsColumn,
        adConversionRateColumn,
        unitsSoldColumn,
        impressionsColumn,
        clicksColumn,
        averageCostPerClickColumn,
        clickThroughRateColumn,
        directAdSalesColumn,
        directAcosColumn,
        directRoasColumn,
        relatedClickRevenueColumn,
        newToBrandOrdersColumn,
        percentageOfOrdersNewToBrandColumn,
        newToBrandOrdersRateColumn,
        newToBrandAdSalesColumn,
        percentageOfSalesNewToBrandColumn,
        newToBrandAdUnitsSoldColumn,
        percentageOfAdUnitsSoldNewToBrandColumn,
        advertisedSKUUnitsColumn,
        otherSKUUnitsColumn,
        ordersColumn,
      ],
    },
  ];
};
