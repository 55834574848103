import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  ChartGraphLinearIcon,
  ChartTime,
  Icon,
  IconSize,
  ToggleTab,
} from '@teikametrics/tm-design-system';

import { NavPaths } from '../../../../NavPaths';
import { SubscriptionContext } from '../../../../containers/subscriptionProvider';
import { useDataSyncInfoContext } from '../../../../containers/dataSyncInfoProvider/dataSyncInfoProvider';
import { getCurrentAccountFromContext } from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { CompassApiClient } from '../../../../lib/clients/CompassApiClient';
import { SKUApiClient } from '../../../../lib/clients/SKUApiClient';
import {
  API_REQUEST_DATE_FORMAT,
  AggregationPeriodType,
  AiStatisticsDataV2,
  ExportBusinessMetricsRequest,
  PerformanceFieldsBusiness,
} from '../../../../lib/types/CompassSharedTypes';
import { ProductSubscriptionEventResponse } from '../../../../lib/types/Fam';
import I18nKey from '../../../../lib/types/I18nKey';
import {
  AsyncRequest,
  asyncRequestIsComplete,
  asyncRequestIsFailed,
} from '../../../../lib/utilities/asyncRequest';
import {
  HomepageDataRequestResponse,
  TmIndexDataResponse,
} from '../../../compass';
import { CompassContextSetterContext } from '../../compassContextSetterProvider';
import {
  ChartTrendMetricFormatSelector,
  CompassState,
  DateRange,
} from '../../types';

import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { useActionAnimationDisable } from '../../../../lib/hooks/useActionAnimationDisable';
import { IBlog } from '../../../../lib/types/RSSFeed';
import { AiStatsBanner } from '../../components/Banners/AiStatsBanner';
import { TeikametricsBlogsBanner } from '../../components/TeikametricsBlogsBanner';
import { AiStatsSlideover } from './aiStatsWithSlideover';
import { BusinessGraphV2 } from './businessGraphV2';
import BusinessPerformanceTable from './businessPerformance';
import {
  getChartTimeTabOptions,
  getChartTrendFormatSelector,
  getEmptyMerchantsState,
  getNoMerchantsSelectionState,
  getShowDataEmpty,
  isAllPerformanceMetricsEmpty,
  isDataConnectedForAllSelectedMerchants,
} from './utils/business';
import { CurrencyCode } from '../../../../lib/utilities/currency';

const BLOG_HOME_PAGE_URL = 'https://hubs.ly/Q02cx7gD0';

interface OwnProps {
  readonly homepageDataRequest: AsyncRequest<HomepageDataRequestResponse>;
  readonly isLoadingMetricsData: boolean;
  readonly isErrorMetricsData: boolean;
  readonly metricsData: TmIndexDataResponse;
  readonly isErrorAIStatisticsV2: Error | null;
  readonly isLoadingAIStatisticsV2: boolean;
  readonly reloadAiStatisticsRequestV2: () => void;
  readonly productSubscriptionEventData: AsyncRequest<ProductSubscriptionEventResponse>;
  readonly compassApiClient: CompassApiClient;
  readonly skuApiClient: SKUApiClient;
  readonly aoApiClient?: AOApiClient;
  readonly aiStatisticeV2?: AiStatisticsDataV2;
}

interface RenderGraphProps {
  readonly metricResponse: HomepageDataRequestResponse;
  readonly tmIndexMetricsResponse: TmIndexDataResponse;
  readonly trendFormatSelector: ChartTrendMetricFormatSelector;
  readonly currentChartTimeFormat: ChartTime;
  readonly subscriptionData: ProductSubscriptionEventResponse;
  readonly compassState: CompassState;
  readonly selectedDateRange: DateRange;
  readonly selectedCurrency: CurrencyCode;
  readonly onClickChartTime: Function;
  readonly chartsTabs: ToggleTab[];
  readonly selectedMerchantsId: string[];
  readonly skuApiClient: SKUApiClient;
  readonly accountId: string;
  readonly isAnimationDisabled: boolean;
  readonly disableAnimationOnAction: () => void;
}

const RenderGraph: React.FC<RenderGraphProps> = ({
  metricResponse,
  tmIndexMetricsResponse,
  trendFormatSelector,
  currentChartTimeFormat,
  subscriptionData,
  compassState,
  selectedDateRange,
  selectedCurrency,
  onClickChartTime,
  chartsTabs,
  selectedMerchantsId,
  skuApiClient,
  accountId,
  isAnimationDisabled,
  disableAnimationOnAction,
}) => {
  const apiDefinition = async (values: string[]) => {
    const requestBody: ExportBusinessMetricsRequest = {
      merchantCountryIds: selectedMerchantsId,
      performanceFields: values as PerformanceFieldsBusiness[],
      metricsPeriodType: AggregationPeriodType.Current,
      startDate: selectedDateRange.startDate.toFormat(API_REQUEST_DATE_FORMAT),
      endDate: selectedDateRange.endDate.toFormat(API_REQUEST_DATE_FORMAT),
      currency: selectedCurrency,
    };

    return skuApiClient.exportCompassBusinessPerformanceCsvData(
      accountId,
      requestBody
    );
  };

  return (
    <>
      <BusinessGraphV2
        apiDefinition={apiDefinition}
        currentChartTimeFormat={currentChartTimeFormat}
        onClickChartTime={onClickChartTime}
        chartsTabs={chartsTabs}
        compassState={compassState}
        metricResponse={metricResponse}
        tmIndexMetricsResponse={tmIndexMetricsResponse}
        trendFormatSelector={trendFormatSelector}
        subscriptionData={subscriptionData}
        isAnimationDisabled={isAnimationDisabled}
        disableAnimationOnAction={disableAnimationOnAction}
      />
    </>
  );
};

export const Business: React.FC<OwnProps> = ({
  homepageDataRequest,
  isLoadingMetricsData,
  isErrorAIStatisticsV2,
  isErrorMetricsData,
  reloadAiStatisticsRequestV2,
  productSubscriptionEventData,
  skuApiClient,
  compassApiClient,
  aoApiClient,
  metricsData,
  aiStatisticeV2,
  isLoadingAIStatisticsV2,
}) => {
  const {
    merchants,
    contextSetterValues: {
      selectedDateRange,
      selectedMerchantsId,
      selectedDateRangeDiffInDays,
      selectedCurrency,
    },
    isContextDataLoading,
  } = useContext(CompassContextSetterContext);
  const [currentChartTimeFormat, setCurrentChartTimeFormat] =
    useState<ChartTime>(ChartTime.Monthly);
  const intl = useIntl();
  const userContext = useContext<UserContextState>(UserContext);
  const currentAccount = getCurrentAccountFromContext(userContext);
  const accountId = currentAccount!.id;

  const [chartsTabs, setChartsTabs] = useState<ToggleTab[]>(
    getChartTimeTabOptions(intl, selectedDateRangeDiffInDays)
  );
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = {
    userId: userContext.userInfo.userDetails?.id ?? '',
    accountId,
  };

  const dataSyncInfo = useDataSyncInfoContext();
  const subscriptionContext = useContext(SubscriptionContext);

  const { salesChannelData } = subscriptionContext;

  const isAllSelectedMerchantsDataConnected =
    isDataConnectedForAllSelectedMerchants(
      selectedMerchantsId,
      dataSyncInfo?.aoDataSyncInfo?.syncPerMerchantIds || [],
      dataSyncInfo?.skuDataAvailibilityInfo?.syncPerMerchantIds || [],
      salesChannelData
    );

  const [trendFormatSelector, setTrendFormatSelector] =
    useState<ChartTrendMetricFormatSelector>(
      getChartTrendFormatSelector(selectedDateRangeDiffInDays)
    );

  const { isAnimationDisabled, disableAnimationOnAction } =
    useActionAnimationDisable();

  const metricResponse: HomepageDataRequestResponse = asyncRequestIsComplete(
    homepageDataRequest
  )
    ? homepageDataRequest.result
    : ({} as HomepageDataRequestResponse);

  const tmIndexMetricsDataResponse: TmIndexDataResponse = !isLoadingMetricsData
    ? metricsData
    : ({} as TmIndexDataResponse);

  const getCompassState = (): CompassState => {
    if (selectedMerchantsId.length === 0 && !isContextDataLoading) {
      return CompassState.NoMerchantsSelected;
    } else if (
      asyncRequestIsFailed(homepageDataRequest) ||
      isErrorMetricsData
    ) {
      return CompassState.Error;
    } else if (
      asyncRequestIsComplete(homepageDataRequest) &&
      !isLoadingMetricsData
    ) {
      if (isAllPerformanceMetricsEmpty(homepageDataRequest.result.current)) {
        return CompassState.NoData;
      }
      return CompassState.Success;
    }
    if (!isContextDataLoading) {
      if (merchants.length === 0) {
        return CompassState.EmptyMerchants;
      }
    }
    return CompassState.Loading;
  };

  const [feeds, setFeeds] = useState<IBlog[]>([]);
  const [blogsLoading, setBlogsLoading] = useState<boolean>(false);
  const [errorLoadingFeeds, setErrorLoadingFeeds] = useState<boolean>(false);

  const loadFeeds = () => {
    setErrorLoadingFeeds(false);
    setBlogsLoading(true);
    compassApiClient
      .getBlogsRSSFeed()
      .then((blogs: IBlog[]) => {
        setFeeds(blogs.slice(0, 3));
        setBlogsLoading(false);
      })
      .catch(() => {
        setErrorLoadingFeeds(true);
        setBlogsLoading(false);
      });
  };

  useEffect(() => {
    loadFeeds();
  }, []);

  useEffect(() => {
    const updatedTrendFormatSelector = getChartTrendFormatSelector(
      selectedDateRangeDiffInDays
    );
    if (updatedTrendFormatSelector === ChartTrendMetricFormatSelector.Day) {
      setCurrentChartTimeFormat(ChartTime.Daily);
    } else if (
      updatedTrendFormatSelector === ChartTrendMetricFormatSelector.Week
    ) {
      setCurrentChartTimeFormat(ChartTime.Weekly);
    } else if (
      updatedTrendFormatSelector === ChartTrendMetricFormatSelector.Month
    ) {
      setCurrentChartTimeFormat(ChartTime.Monthly);
    }
    setChartsTabs(getChartTimeTabOptions(intl, selectedDateRangeDiffInDays));
    setTrendFormatSelector(updatedTrendFormatSelector);
  }, [selectedDateRange]);

  const onClickChartTime = (toggleTabData: ToggleTab) => {
    disableAnimationOnAction();
    const tabLabel = intl.formatMessage({
      id: toggleTabData.nameI18nKey,
    }) as ChartTime;
    setCurrentChartTimeFormat(tabLabel);
    if (tabLabel === ChartTime.Monthly) {
      setTrendFormatSelector(ChartTrendMetricFormatSelector.Month);
    } else if (tabLabel === ChartTime.Weekly) {
      setTrendFormatSelector(ChartTrendMetricFormatSelector.Week);
    } else if (tabLabel === ChartTime.Daily) {
      setTrendFormatSelector(ChartTrendMetricFormatSelector.Day);
    }
  };

  const handleButtonClick = async () => {
    await navigate(
      `${NavPaths.SalesChannels}?scsRedirect=${location.pathname}&connect=1`
    );
  };

  const subscriptionData: ProductSubscriptionEventResponse =
    asyncRequestIsComplete(productSubscriptionEventData)
      ? productSubscriptionEventData.result
      : ({} as ProductSubscriptionEventResponse);
  const compassState: CompassState = getCompassState();

  const aiStatisticsResponseV2: AiStatisticsDataV2 =
    !isLoadingAIStatisticsV2 && aiStatisticeV2
      ? aiStatisticeV2
      : ({} as AiStatisticsDataV2);

  const [isOpenAiStatsSlideover, setIsOpenAiStatsSlideover] =
    useState<boolean>(false);

  const openSlideOver = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();
    setIsOpenAiStatsSlideover(true);
  };
  const closeSlideOver = () => setIsOpenAiStatsSlideover(false);

  const handleStatisticsBannerViewV2 = () => {
    return (
      <AiStatsBanner
        loadingBidsRequest={isLoadingAIStatisticsV2}
        failedBidsRequest={isErrorAIStatisticsV2}
        totalBids={aiStatisticsResponseV2.aiBids}
        openSlideOver={openSlideOver}
        onErrorReload={reloadAiStatisticsRequestV2}
      />
    );
  };

  return (
    <div className="flex flex-row w-full">
      <div className="w-full">
        {compassState === CompassState.EmptyMerchants &&
          getEmptyMerchantsState()}
        {compassState === CompassState.NoMerchantsSelected &&
          getNoMerchantsSelectionState()}
        {(compassState === CompassState.Error ||
          compassState === CompassState.NoData) &&
          getShowDataEmpty(
            compassState,
            userInfo,
            handleButtonClick,
            isAllSelectedMerchantsDataConnected
          )}
        {compassState !== CompassState.NoData &&
          compassState !== CompassState.Error &&
          compassState !== CompassState.EmptyMerchants &&
          compassState !== CompassState.NoMerchantsSelected && (
            <>
              <RenderGraph
                metricResponse={metricResponse}
                tmIndexMetricsResponse={tmIndexMetricsDataResponse}
                trendFormatSelector={trendFormatSelector}
                chartsTabs={chartsTabs}
                onClickChartTime={onClickChartTime}
                selectedCurrency={selectedCurrency}
                selectedDateRange={selectedDateRange}
                selectedMerchantsId={selectedMerchantsId}
                accountId={accountId}
                skuApiClient={skuApiClient}
                compassState={compassState}
                subscriptionData={subscriptionData}
                currentChartTimeFormat={currentChartTimeFormat}
                isAnimationDisabled={isAnimationDisabled}
                disableAnimationOnAction={disableAnimationOnAction}
              />
              <div>
                {handleStatisticsBannerViewV2()}
                <AiStatsSlideover
                  isOpenAiStatsSlideover={isOpenAiStatsSlideover}
                  closeSlideOver={closeSlideOver}
                  totalBids={aiStatisticsResponseV2.aiBids}
                  dateRange={selectedDateRange}
                  selectedMerchantIds={selectedMerchantsId}
                  currentChartTimeFormat={currentChartTimeFormat}
                  formatSelector={trendFormatSelector}
                />
                <TeikametricsBlogsBanner
                  feeds={feeds}
                  blogsHomeUrl={BLOG_HOME_PAGE_URL}
                  blogsLoading={blogsLoading}
                  errorLoadingFeeds={errorLoadingFeeds}
                  refreshFeed={loadFeeds}
                />
                <div className={classNames('flex flex-row items-center gap-8')}>
                  <div
                    className={classNames(
                      'flex flex-row items-center justify-center p-6 gap-8 w-24 h-24 bg-purple-100 rounded-20 text-purple-500'
                    )}
                  >
                    <Icon svg={ChartGraphLinearIcon} size={IconSize.Small} />
                  </div>
                  <p className="font-medium leading-none text-base text-grey-900">
                    {intl.formatMessage({
                      id: I18nKey.GENERIC_SNAPSHOT,
                    })}
                  </p>
                </div>
                <div className={classNames('mt-16')}>
                  <BusinessPerformanceTable
                    merchants={merchants}
                    merchantCountryEvents={
                      subscriptionData?.merchantCountryEvents || []
                    }
                    selectedMerchantIds={selectedMerchantsId}
                    compassApiClient={compassApiClient}
                    skuApiClient={skuApiClient}
                    accountId={accountId}
                    eventsLoading={
                      (compassState as string) === CompassState.Loading
                    }
                    selectedCurrency={selectedCurrency}
                    selectedDateRange={selectedDateRange}
                    aoApiClient={aoApiClient}
                  />
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  );
};
Business.displayName = 'Business';
