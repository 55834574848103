import upperFirst from 'lodash/upperFirst';
import { useIntl } from 'react-intl';

import {
  ConditionalType,
  EmDash,
  LightningIcon,
  MultiSelectDataFieldFilter,
  PaletteColor,
  PillProps,
  StarIcon,
  TagLinearIcon,
  TargetIcon,
  createMultiSelectDataFieldFilter,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeMultiplePillsColumn } from '../../../../../containers/table/utils/makeTableCells';
import {
  AdLevel,
  AdType,
  FlywheelSalesChannel,
  TargetType,
  TargetsDetails,
} from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import {
  TARGETS_API_COLUMN_NAME,
  isNegativeKeywordTarget,
  isNegativeProductTarget,
} from '../utils';
import { SelectFilterOption } from '../utils/selectFilterOptions';
import { TableDataAdsManager } from '../types';

export const RowCellElement: React.FC<TargetsDetails & TableDataAdsManager> = (
  props
) => {
  const intl = useIntl();
  const { isManageTreAutomationEnabled } = props;

  return makeMultiplePillsColumn((data: TargetsDetails) => {
    let pills: PillProps[] = [];
    if (data.targetDetails.flywheelAiEnabled) {
      pills.push({
        isHoverable: false,
        color: PaletteColor.purple,
        text: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_TARGET_LABELS_AI_ADDED,
        }),
        icon: LightningIcon,
      });
    }

    if (
      isNegativeKeywordTarget(data.targetDetails.matchType) ||
      isNegativeProductTarget(data.targetDetails.entityType)
    ) {
      pills.push({
        isHoverable: false,
        color: PaletteColor.blue,
        text: (
          <>
            <EmDash className="text-blue-800" />
            &nbsp;
            {intl.formatMessage({
              id: I18nKey.ADS_MANAGER_TARGET_LABELS_NEG_TARGET,
            })}
          </>
        ),
        className: pills.length ? 'ml-4' : undefined,
      });
    }

    if (data.targetDetails.targetType) {
      pills.push({
        isHoverable: false,
        color: getColorForTargetTypePill(data.targetDetails.targetType),
        text: upperFirst(data.targetDetails.targetType),
        icon: getIconForTargetTypePill(data.targetDetails.targetType),
        className: getClassesForTargetTypePill(pills),
      });
    }
    return pills;
  }, isManageTreAutomationEnabled)(props);
};

RowCellElement.displayName = 'RowCellElement';

export const targetLabelsColumn: FlywheelTableColumn<
  TargetsDetails,
  TableDataAdsManager
> = {
  columnName: TARGETS_API_COLUMN_NAME.TargetLabels,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_LABELS,
  RowCellElement,
  gridColumnWidth: '220px',
};

export const getTargetLabelsFilter = (
  salesChannel: FlywheelSalesChannel,
  adType: AdType,
  adLevel: AdLevel,
  isAiEnabled?: boolean
): MultiSelectDataFieldFilter =>
  createMultiSelectDataFieldFilter(
    TARGETS_API_COLUMN_NAME.TargetLabels,
    I18nKey.ADS_MANAGER_KEYWORD_TARGETS_COLUMN_TARGET_LABELS,
    getTargetLabelFilterOptions(salesChannel, adType, adLevel, isAiEnabled),
    {
      [ConditionalType.In]: ConditionalType.Contain,
      [ConditionalType.NotIn]: ConditionalType.DoesntContain,
      [ConditionalType.Is]: ConditionalType.Contain,
      [ConditionalType.IsNot]: ConditionalType.DoesntContain,
    }
  );

export const getTargetLabelFilterOptions = (
  salesChannel: FlywheelSalesChannel,
  adType: AdType,
  adLevel: AdLevel,
  isAiEnabled?: boolean
) => {
  if (salesChannel === FlywheelSalesChannel.Amazon) {
    return adType === AdType.SponsoredProducts &&
      adLevel === AdLevel.KeywordTargets &&
      isAiEnabled
      ? [
          SelectFilterOption.TargetLabelAIAdded,
          SelectFilterOption.TargetLabelNegTarget,
          SelectFilterOption.TargetTypeCompetitor,
          SelectFilterOption.TargetTypeGeneric,
          SelectFilterOption.TargetTypeBrand,
          SelectFilterOption.TargetLabelNoLabels,
        ]
      : [
          SelectFilterOption.TargetLabelNegTarget,
          SelectFilterOption.TargetLabelNoLabels,
        ];
  } else {
    return isAiEnabled
      ? [
          SelectFilterOption.TargetLabelAIAdded,
          SelectFilterOption.TargetTypeCompetitor,
          SelectFilterOption.TargetTypeGeneric,
          SelectFilterOption.TargetTypeBrand,
          SelectFilterOption.TargetLabelNoLabels,
        ]
      : [];
  }
};

const getIconForTargetTypePill = (targetType: string) => {
  switch (targetType) {
    case TargetType.Brand:
      return StarIcon;
    case TargetType.Generic:
      return TagLinearIcon;
    case TargetType.Competitor:
      return TargetIcon;
    default:
      return StarIcon;
  }
};

const getColorForTargetTypePill = (targetType: string) => {
  switch (targetType) {
    case TargetType.Brand:
      return PaletteColor.magenta;
    case TargetType.Generic:
      return PaletteColor.greyV2;
    case TargetType.Competitor:
      return PaletteColor.yellow;
    default:
      return PaletteColor.magenta;
  }
};

const getClassesForTargetTypePill = (pills: PillProps[]) => {
  switch (pills.length) {
    case 0:
      return '';
    case 1:
    case 3:
      return 'ml-4';
    case 2:
      return 'mt-4';
    default:
      return '';
  }
};
