import noop from 'lodash/noop';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { SalesChannelContext } from '../salesChannelProvider';
import { createFAMApiClient } from '../../lib/clients/FAMApiClient';
import { MerchantCountry, MerchantType } from '../../lib/types/Fam';
import { getCurrentAccountFromContext } from '../userProvider/selectors';
import { UserContext, UserContextState } from '../userProvider/userProvider';
import { WALMART_SALES_CHANNEL_ID } from '../../lib/types/SalesChannels';
import { updateIntercomMerchantCountry } from '../../lib/utilities/intercom';
import { useQuery } from '@tanstack/react-query';
import { GeneralQueryKeys } from '../../lib/types/ReactQueryKeys';

export interface MerchantCountriesContextState {
  readonly merchantCountries?: MerchantCountry[];
  readonly clearMerchantCountries: () => void;
  readonly isContextDataLoading: boolean;

  readonly has1PMerchants?: boolean;
  readonly has3PMerchants?: boolean;
}

export interface MerchantCountriesProviderProps {
  children: JSX.Element;
}

const initialState = {
  merchantCountries: [],
  clearMerchantCountries: noop,
  isContextDataLoading: true,
  has1PMerchants: false,
  has3PMerchants: false,
};

const MerchantCountriesContext =
  createContext<MerchantCountriesContextState>(initialState);
MerchantCountriesContext.displayName = 'MerchantCountriesContext';

const { Provider } = MerchantCountriesContext;

export const useMerchantCountriesContext = () => {
  const context = useContext(MerchantCountriesContext);
  if (!context) {
    throw new Error(
      'useMerchantCountriesContext must be used within a MerchantCountriesProvider'
    );
  }
  return context;
};

const MerchantCountriesProvider: React.FC<MerchantCountriesProviderProps> = ({
  children,
}) => {
  const [merchantCountries, setMerchantCountries] =
    useState<MerchantCountry[]>();
  const [isContextDataLoading, setIsContextDataLoading] =
    useState<boolean>(true);
  const userContext = useContext<UserContextState>(UserContext);
  const account = getCurrentAccountFromContext(userContext)!;
  const famApiClient = createFAMApiClient(userContext.userInfo.idToken!);
  const salesChannelContext = useContext(SalesChannelContext);

  const { salesChannels } = salesChannelContext;

  const salesChannelsIds = useMemo(() => {
    return salesChannels.map(({ id }) => id);
  }, [salesChannels]);

  const { refetch } = useQuery({
    queryKey: [GeneralQueryKeys.GetMerchantCountries, account.id],
    queryFn: () =>
      famApiClient.getMerchantCountries(account.id, salesChannelsIds),
    enabled: !!salesChannelsIds.length,
  });

  const getMerchantCountries = async () => {
    setIsContextDataLoading(true);

    const { data: merchantCountriesResponse } = await refetch();

    if (merchantCountriesResponse?.merchantCountryInfoItems) {
      updateIntercomMerchantCountry(
        merchantCountriesResponse?.merchantCountryInfoItems
      );
    }
    setMerchantCountries(merchantCountriesResponse?.merchantCountryInfoItems);
    setIsContextDataLoading(false);
  };

  const clearMerchantCountries = () => {
    setMerchantCountries([]);
  };

  useEffect(() => {
    if (!userContext.userInfo.idToken || !salesChannelsIds.length) return;
    getMerchantCountries();
  }, [userContext.userInfo.idToken, salesChannelsIds, account.id]);

  const has1PMerchants = useMemo(() => {
    return merchantCountries?.some(
      (mc) =>
        mc.salesChannelId !== WALMART_SALES_CHANNEL_ID &&
        mc.merchantType === MerchantType.Vendor
    );
  }, [merchantCountries]);

  const has3PMerchants = useMemo(() => {
    return merchantCountries?.some(
      (mc) =>
        mc.merchantType === MerchantType.Seller ||
        mc.salesChannelId === WALMART_SALES_CHANNEL_ID
    );
  }, [merchantCountries]);

  return (
    <Provider
      value={{
        merchantCountries,
        clearMerchantCountries,
        isContextDataLoading,
        has1PMerchants,
        has3PMerchants,
      }}
    >
      {children}
    </Provider>
  );
};
MerchantCountriesProvider.displayName = 'MerchantCountriesProvider';
export { MerchantCountriesContext, MerchantCountriesProvider };
