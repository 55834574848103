import { SPAction } from '../../../lib/types/TeikaReduxTypes';
import {
  ApplyBulkChangesPayload,
  ChangeSelectPayload,
  ClearAllChangesPayload,
  ClearBufferPayload,
  ClearFilterPayload,
  ClearFiltersPayload,
  ClearSingleFilterOfMultiSelectionFiltersPayload,
  DataLoadBufferCompletePayload,
  DataLoadCompletePayload,
  DataLoadFailedPayload,
  DataLoadInitiatedPayload,
  DataResetPayload,
  DeleteItemPayload,
  FooterDataLoadCompletePayload,
  FooterDataLoadFailedPayload,
  FooterDataLoadInitiatedPayload,
  PatchItemPayload,
  SelectAllItemsPayload,
  SetCellChangePayload,
  SetItemsPerPagePayload,
  SetUpdatedItemsPayload,
  SetVisiblePagePayload,
  SortAppliedPayload,
  SortRemovedPayload,
  ToggleSelectedItemPayload,
  UpdateCellPayload,
  UpdateExtraPaginationParamsPayload,
  UpdateFiltersPayload,
  UpdateGlobalPayload,
} from './types';

export const SET_VISIBLE_PAGE = 'app/table/setVisiblePage';
export const SORT_APPLIED = 'app/table/sortApplied';
export const SORT_REMOVED = 'app/table/sortRemoved';
export const UPDATE_FILTERS = 'app/table/updateFilters';
export const CLEAR_FILTER = 'app/table/clearFilter';
export const CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS =
  'app/table/clearSingleFilterOfMultiSelctionFilters';
export const CLEAR_FILTERS = 'app/table/clearFilters';
export const UPDATE_EXTRA_PAGINATION_PARAMS =
  'app/table/updateExtraPaginationParams';
export const DATA_RESET = 'app/table/dataReset';
export const DATA_LOAD_INITIATED = 'app/table/dataLoadInitiated';
export const DATA_LOAD_COMPLETE = 'app/table/dataLoadComplete';
export const DATA_LOAD_FAILED = 'app/table/dataLoadFailed';
export const FOOTER_DATA_LOAD_INITIATED = 'app/table/footerDataLoadInitiated';
export const FOOTER_DATA_LOAD_COMPLETE = 'app/table/footerDataLoadComplete';
export const FOOTER_DATA_LOAD_FAILED = 'app/table/footerDataLoadFailed';
export const SET_ITEMS_PER_PAGE = 'app/table/setItemsPerPage';
export const SET_UPDATED_ITEMS = 'app/table/setUpdatedItems';
export const PATCH_SINGLE_ITEM = 'app/table/patchSingleItem';
export const SET_CELL_CHANGE = 'app/table/setCellChange';
export const UPDATE_CELL = 'app/table/updateCell';
export const CLEAR_ALL_CHANGES = 'app/table/clearAllChanges';
export const TOGGLE_SELECTED_ITEM = 'app/table/toggleSelectedItem';
export const APPLY_BULK_CHANGES = 'app/table/applyBulkChanges';
export const SELECT_ALL_ITEMS = 'app/table/selectAllItems';
export const CHANGE_SELECT = 'app/table/changeSelect';
export const UPDATE_GLOBAL = 'app/table/updateGlobal';
export const DELETE_ITEMS = 'app/table/deleteItems';
export const DATA_LOAD_BUFFER_COMPLETE = 'app/table/dataLoadBufferComplete';
export const CLEAR_BUFFER = 'app/table/clearBuffer';

export const setVisiblePage = (
  payload: SetVisiblePagePayload
): SPAction<typeof SET_VISIBLE_PAGE, SetVisiblePagePayload> => ({
  type: SET_VISIBLE_PAGE,
  payload,
});

export function dataLoadComplete<T>(
  payload: DataLoadCompletePayload<T>
): SPAction<typeof DATA_LOAD_COMPLETE, DataLoadCompletePayload<T>> {
  return {
    type: DATA_LOAD_COMPLETE,
    payload,
  };
}

export const dataReset = (
  payload: DataResetPayload
): SPAction<typeof DATA_RESET, DataResetPayload> => ({
  type: DATA_RESET,
  payload,
});

export const sortApplied = (
  payload: SortAppliedPayload
): SPAction<typeof SORT_APPLIED, SortAppliedPayload> => ({
  type: SORT_APPLIED,
  payload,
});

export const sortRemoved = (
  payload: SortRemovedPayload
): SPAction<typeof SORT_REMOVED, SortRemovedPayload> => ({
  type: SORT_REMOVED,
  payload,
});

export const updateFilters = (
  payload: UpdateFiltersPayload
): SPAction<typeof UPDATE_FILTERS, UpdateFiltersPayload> => ({
  type: UPDATE_FILTERS,
  payload,
});

export const clearFilter = (
  payload: ClearFilterPayload
): SPAction<typeof CLEAR_FILTER, ClearFilterPayload> => ({
  type: CLEAR_FILTER,
  payload,
});

export const clearSingleFilterOfMultiSelctionFilters = (
  payload: ClearSingleFilterOfMultiSelectionFiltersPayload
): SPAction<
  typeof CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS,
  ClearSingleFilterOfMultiSelectionFiltersPayload
> => ({
  type: CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS,
  payload,
});

export const clearFilters = (
  payload: ClearFiltersPayload
): SPAction<typeof CLEAR_FILTERS, ClearFiltersPayload> => ({
  type: CLEAR_FILTERS,
  payload,
});

export const updateExtraPaginationParams = (
  payload: UpdateExtraPaginationParamsPayload
): SPAction<
  typeof UPDATE_EXTRA_PAGINATION_PARAMS,
  UpdateExtraPaginationParamsPayload
> => ({
  type: UPDATE_EXTRA_PAGINATION_PARAMS,
  payload,
});

export const dataLoadInitiated = (
  payload: DataLoadInitiatedPayload
): SPAction<typeof DATA_LOAD_INITIATED, DataLoadInitiatedPayload> => ({
  type: DATA_LOAD_INITIATED,
  payload,
});

export const dataLoadFailed = (
  payload: DataLoadFailedPayload
): SPAction<typeof DATA_LOAD_FAILED, DataLoadFailedPayload> => ({
  type: DATA_LOAD_FAILED,
  payload,
});

export const footerDataLoadInitiated = (
  payload: FooterDataLoadInitiatedPayload
): SPAction<
  typeof FOOTER_DATA_LOAD_INITIATED,
  FooterDataLoadInitiatedPayload
> => ({
  type: FOOTER_DATA_LOAD_INITIATED,
  payload,
});

export function footerDataLoadComplete<F>(
  payload: FooterDataLoadCompletePayload<F>
): SPAction<
  typeof FOOTER_DATA_LOAD_COMPLETE,
  FooterDataLoadCompletePayload<F>
> {
  return {
    type: FOOTER_DATA_LOAD_COMPLETE,
    payload,
  };
}

export const footerDataLoadFailed = (
  payload: FooterDataLoadFailedPayload
): SPAction<typeof FOOTER_DATA_LOAD_FAILED, FooterDataLoadFailedPayload> => ({
  type: FOOTER_DATA_LOAD_FAILED,
  payload,
});

export const setItemsPerPage = (
  payload: SetItemsPerPagePayload
): SPAction<typeof SET_ITEMS_PER_PAGE, SetItemsPerPagePayload> => ({
  type: SET_ITEMS_PER_PAGE,
  payload,
});

export function setUpdatedItems<T>(
  payload: SetUpdatedItemsPayload<T>
): SPAction<typeof SET_UPDATED_ITEMS, SetUpdatedItemsPayload<T>> {
  return {
    type: SET_UPDATED_ITEMS,
    payload,
  };
}

export function patchItemInTable<T>(
  payload: PatchItemPayload<T>
): SPAction<typeof PATCH_SINGLE_ITEM, PatchItemPayload<T>> {
  return {
    type: PATCH_SINGLE_ITEM,
    payload,
  };
}

export function setCellChange(
  payload: SetCellChangePayload
): SPAction<typeof SET_CELL_CHANGE, SetCellChangePayload> {
  return {
    type: SET_CELL_CHANGE,
    payload,
  };
}

export function updateCell(
  payload: UpdateCellPayload
): SPAction<typeof UPDATE_CELL, UpdateCellPayload> {
  return {
    type: UPDATE_CELL,
    payload,
  };
}

export function clearAllChanges(
  payload: ClearAllChangesPayload
): SPAction<typeof CLEAR_ALL_CHANGES, ClearAllChangesPayload> {
  return {
    type: CLEAR_ALL_CHANGES,
    payload,
  };
}

export function toggleSelectedItem(
  payload: ToggleSelectedItemPayload
): SPAction<typeof TOGGLE_SELECTED_ITEM, ToggleSelectedItemPayload> {
  return {
    type: TOGGLE_SELECTED_ITEM,
    payload,
  };
}

export function selectAllItems(
  payload: SelectAllItemsPayload
): SPAction<typeof SELECT_ALL_ITEMS, SelectAllItemsPayload> {
  return {
    type: SELECT_ALL_ITEMS,
    payload,
  };
}

export function applyBulkChanges(
  payload: ApplyBulkChangesPayload
): SPAction<typeof APPLY_BULK_CHANGES, ApplyBulkChangesPayload> {
  return {
    type: APPLY_BULK_CHANGES,
    payload,
  };
}

export function changeSelect(
  payload: ChangeSelectPayload
): SPAction<typeof CHANGE_SELECT, ChangeSelectPayload> {
  return {
    type: CHANGE_SELECT,
    payload,
  };
}

export function updateGlobal(
  payload: UpdateGlobalPayload
): SPAction<typeof UPDATE_GLOBAL, UpdateGlobalPayload> {
  return {
    type: UPDATE_GLOBAL,
    payload,
  };
}

export function deleteRecordsInTable(
  payload: DeleteItemPayload<any>
): SPAction<typeof DELETE_ITEMS, DeleteItemPayload<any>> {
  return {
    type: DELETE_ITEMS,
    payload,
  };
}

export function dataLoadBufferComplete<T>(
  payload: DataLoadBufferCompletePayload<T>
): SPAction<
  typeof DATA_LOAD_BUFFER_COMPLETE,
  DataLoadBufferCompletePayload<T>
> {
  return {
    type: DATA_LOAD_BUFFER_COMPLETE,
    payload,
  };
}

export function clearBuffer(
  payload: ClearBufferPayload
): SPAction<typeof CLEAR_BUFFER, ClearBufferPayload> {
  return {
    type: CLEAR_BUFFER,
    payload,
  };
}

export type TableDataAction =
  | ReturnType<typeof dataLoadComplete>
  | ReturnType<typeof dataLoadInitiated>
  | ReturnType<typeof dataLoadFailed>;

export type TableAction =
  | TableDataAction
  | ReturnType<typeof setVisiblePage>
  | ReturnType<typeof dataReset>
  | ReturnType<typeof sortApplied>
  | ReturnType<typeof sortRemoved>
  | ReturnType<typeof updateFilters>
  | ReturnType<typeof clearFilter>
  | ReturnType<typeof clearFilters>
  | ReturnType<typeof clearSingleFilterOfMultiSelctionFilters>
  | ReturnType<typeof updateExtraPaginationParams>
  | ReturnType<typeof footerDataLoadInitiated>
  | ReturnType<typeof footerDataLoadFailed>
  | ReturnType<typeof footerDataLoadComplete>
  | ReturnType<typeof setItemsPerPage>
  | ReturnType<typeof setUpdatedItems>
  | ReturnType<typeof patchItemInTable>
  | ReturnType<typeof setCellChange>
  | ReturnType<typeof clearAllChanges>
  | ReturnType<typeof toggleSelectedItem>
  | ReturnType<typeof applyBulkChanges>
  | ReturnType<typeof selectAllItems>
  | ReturnType<typeof changeSelect>
  | ReturnType<typeof updateCell>
  | ReturnType<typeof deleteRecordsInTable>
  | ReturnType<typeof dataLoadBufferComplete>
  | ReturnType<typeof clearBuffer>
  | ReturnType<typeof updateGlobal>;

export default {
  SET_VISIBLE_PAGE,
  SORT_APPLIED,
  SORT_REMOVED,
  CLEAR_FILTER,
  CLEAR_SINGLE_FILTER_OF_MULTI_SELECTION_FILTERS,
  UPDATE_FILTERS,
  CLEAR_FILTERS,
  UPDATE_EXTRA_PAGINATION_PARAMS,
  DATA_LOAD_INITIATED,
  DATA_LOAD_COMPLETE,
  DATA_LOAD_FAILED,
  DATA_RESET,
  FOOTER_DATA_LOAD_INITIATED,
  FOOTER_DATA_LOAD_COMPLETE,
  FOOTER_DATA_LOAD_FAILED,
  SET_UPDATED_ITEMS,
  UPDATE_GLOBAL,
  CLEAR_BUFFER,
  setVisiblePage,
  sortApplied,
  sortRemoved,
  clearFilter,
  clearSingleFilterOfMultiSelctionFilters,
  updateFilters,
  clearFilters,
  updateExtraPaginationParams,
  dataLoadInitiated,
  dataLoadComplete,
  dataLoadFailed,
  dataReset,
  footerDataLoadInitiated,
  footerDataLoadFailed,
  footerDataLoadComplete,
  setItemsPerPage,
  setUpdatedItems,
  patchItemInTable,
  setCellChange,
  clearAllChanges,
  toggleSelectedItem,
  applyBulkChanges,
  selectAllItems,
  changeSelect,
  updateCell,
  updateGlobal,
  deleteRecordsInTable,
  clearBuffer,
};
