import isNil from 'lodash/isNil';

import {
  createMoneyDataFieldFilter,
  isValidNumber,
} from '@teikametrics/tm-design-system';

import { FlywheelTableColumn } from '../../../../../containers/table/UpdatedFlywheelTable';
import { makeCurrencyColumn } from '../../../../../containers/table/utils/makeTableCells';
import { SbAdsDetails } from '../../../../../lib/types/AOSharedTypes';
import I18nKey from '../../../../../lib/types/I18nKey';
import { CURRENCY_CODE, TableDataAdsManager } from '../types';
import { SB_ADS_API_COLUMN_NAME, getPerformanceMetricValue } from '../utils';
import { getCurrencyCodeFromMerchantCountryCode } from '../../../../../lib/utilities/currency';

export const RowCellElement: React.FC<SbAdsDetails & TableDataAdsManager> = (
  props
) =>
  makeCurrencyColumn<SbAdsDetails>(({ creativePerformance: { adSpend } }) => {
    const performanceMetricValue = getPerformanceMetricValue(
      props.selectedEndDate,
      undefined,
      adSpend?.amount ? Number(adSpend.amount) : undefined
    );

    return isNil(performanceMetricValue)
      ? undefined
      : {
          amount: performanceMetricValue,
          currency:
            getCurrencyCodeFromMerchantCountryCode(props.merchantCountry) ||
            adSpend?.currency ||
            CURRENCY_CODE,
        };
  })(props);
RowCellElement.displayName = 'RowCellElement';

export const adSpendColumn: FlywheelTableColumn<
  SbAdsDetails,
  TableDataAdsManager
> = {
  columnName: SB_ADS_API_COLUMN_NAME.AdSpend,
  isSortable: true,
  i18nKeyOrLabel: I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_AD_SPEND,
  RowCellElement,
  gridColumnWidth: '120px',
  className: 'text-right',
};

export const adSpendFilter = (currency: string) =>
  createMoneyDataFieldFilter(
    SB_ADS_API_COLUMN_NAME.AdSpend,
    I18nKey.ADS_MANAGER_PROFILES_TABLE_COLUMN_AD_SPEND,
    currency,
    isValidNumber(0)
  );
