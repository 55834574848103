import './paginatedTableStyles.scss';
import './styles.scss';

import { fromNullable } from 'fp-ts/lib/Option';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { SearchIcon, TableEmptyState } from '@teikametrics/tm-design-system';

import GridTable from '../../components/GridTable';
import {
  GridTableProps,
  SubRows,
  TableDensity,
} from '../../components/GridTable/types';
import { PaginatedDataFetcher } from '../../lib/clients/types';
import I18nKey from '../../lib/types/I18nKey';
import { tableActions, tableSelectors, tableThunks } from './ducks';
import { Table, WithTable } from './ducks/types';
import Error from './error';
import FiltersWithNoData from './filtersWithNoData';
import { getCurrentVisiblePage } from './updatedFlywheelTablePaginationRow';
import NoData from './noData';
import {
  AsyncRequestKinds,
  IdentifiedAsyncRequest,
  asyncRequestIdentifiedBy,
  asyncRequestIsComplete,
  asyncRequestIsNotStarted,
} from '../../lib/utilities/asyncRequest';
import { isNotUndefined } from '../../lib/utilities/typeGuards';

// See `GridTableProps` for additional reference type param details
export interface PaginatedTableProps<
  RowDataType, // type associated with the data being rendered in the table rows
  SubRowDataType, // type associated with the data being rendered in the table sub rows
  SubRowChildRowDataType, // type associated with the data being rendered in the table sub row child rows
  HeaderDataType, // type associated with the data being included with Header component props
  FooterDataType, // type associated with the data being included with Footer component props
  TableDataType // type associated with the data being available to ALL table components (header, footer, row, sub row, sub row children)
> {
  readonly tableId: string;
  readonly dataFetcher: PaginatedDataFetcher<RowDataType>;
  readonly tableData?: TableDataType;
  readonly columns: GridTableProps<
    RowDataType,
    SubRowDataType,
    SubRowChildRowDataType,
    HeaderDataType,
    FooterDataType,
    TableDataType
  >['columns'];
  readonly maybeSubRows?: SubRows<RowDataType, SubRowDataType, TableDataType>;
  readonly maybeSubRowChildRows?: SubRows<
    SubRowDataType,
    SubRowChildRowDataType,
    TableDataType
  >;
  readonly hasStickyHeader?: boolean;
  readonly hasStickyLeftColumn?: boolean;
  readonly hasStickyFooter?: boolean;
  readonly tableDensity?: TableDensity;
  readonly onPaginationReset?: () => void;
  readonly noFilterNoDataDisplayComponent?: JSX.Element;
  readonly yesFilterNoDataDisplayComponent?: JSX.Element;
  readonly errorComponent?: JSX.Element;
  readonly callBackOnFilterUpdate?: () => void;
}

function PaginatedTable<R, SR = void, SRC = void, H = {}, F = {}, T = {}>(
  props: PaginatedTableProps<R, SR, SRC, H, F, T>
) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const table = useSelector<WithTable<R, any>, Table<R, void>>((state) =>
    tableSelectors.getTableSelector<R>()(state.tableState, props.tableId)
  );
  const visiblePage = getCurrentVisiblePage(table.visiblePage);
  const pageOfData = fromNullable(table.pages[visiblePage - 1]).getOrElse(
    asyncRequestIdentifiedBy<R[], void>('initialLoad').AsyncRequestNotStarted()
  );

  // Load data when the current page has not started loading data
  useEffect(() => {
    if (asyncRequestIsNotStarted(pageOfData)) {
      loadCurrentPage();
    }
  });

  // Reset pagination if the sorts, filters or extra params change
  useEffect(() => {
    // We do not want to reset pagination on initial render (undefined -> default)
    if (table.pages.length > 0) {
      const actions = [
        tableActions.dataReset({ tableId: props.tableId }),
        tableActions.setVisiblePage({ page: 1, tableId: props.tableId }),
      ];
      actions.forEach(dispatch);
      if (isNotUndefined(props.onPaginationReset)) {
        props.onPaginationReset();
      }
    }
  }, [
    table.sorts,
    table.filters,
    table.extraPaginationParams,
    table.itemsPerPage,
  ]);

  useEffect(() => {
    props.callBackOnFilterUpdate?.();
  }, [table.filters]);

  const rowData = asyncRequestIsComplete(pageOfData) ? pageOfData.result : [];

  const loadPage = (page: number): void => {
    dispatch(
      tableThunks.loadPageOfData(
        props.tableId,
        {
          sorts: table.sorts,
          filters: table.filters,
          extraParams: table.extraPaginationParams,
          itemsPerPage: table.itemsPerPage,
          page,
        },
        props.dataFetcher
      )
    );
  };

  const loadCurrentPage = () => {
    loadPage(visiblePage);
  };

  const getTableStateElement = (
    page: IdentifiedAsyncRequest<R[], void>
  ): JSX.Element => {
    switch (page.kind) {
      case AsyncRequestKinds.NotStarted:
      case AsyncRequestKinds.Loading:
        return (
          <TableEmptyState
            titleI18nKey={intl.formatMessage({
              id: I18nKey.ADS_MANAGER_TABLE_LOOKING_AROUND,
            })}
            descriptionI18nKey={intl.formatMessage({
              id: I18nKey.ADS_MANAGER_TABLE_TAKING_A_LOOK,
            })}
            icon={SearchIcon}
            className="paginated-table__loading"
          />
        );
      case AsyncRequestKinds.Failed:
        return props.errorComponent ? (
          props.errorComponent
        ) : (
          <Error tryAgainClickHandler={loadCurrentPage} />
        );
      case AsyncRequestKinds.Completed:
        const tableHasNoData = page.result.length === 0;
        const filtersAreApplied = table.filters.length > 0;

        if (tableHasNoData && !filtersAreApplied) {
          return props.noFilterNoDataDisplayComponent ? (
            props.noFilterNoDataDisplayComponent
          ) : (
            <NoData tryAgainClickHandler={loadCurrentPage} />
          );
        } else if (tableHasNoData && filtersAreApplied) {
          return props.yesFilterNoDataDisplayComponent ? (
            props.yesFilterNoDataDisplayComponent
          ) : (
            <FiltersWithNoData tryAgainClickHandler={loadCurrentPage} />
          );
        } else {
          return <></>;
        }
    }
  };

  return (
    <div className="paginated-table">
      <GridTable<R, SR, SRC, H, F, T>
        columns={props.columns}
        rowData={rowData}
        hasStickyHeader={props.hasStickyHeader}
        hasStickyLeftColumn={props.hasStickyLeftColumn}
        hasStickyFooter={props.hasStickyFooter}
        tableData={props.tableData}
        maybeSubRows={props.maybeSubRows}
        maybeSubRowChildRows={props.maybeSubRowChildRows}
        tableDensity={props.tableDensity}
        noDataDisplayComponent={getTableStateElement(pageOfData)}
        tableId={props.tableId}
      />
    </div>
  );
}
PaginatedTable.displayName = 'PaginatedTable';
export default PaginatedTable;
