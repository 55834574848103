import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppMessageBanner,
  AppMessageBannerVariant,
} from '@teikametrics/tm-design-system';

import { SubscriptionContext } from '../../containers/subscriptionProvider';
import { UserContextState } from '../../containers/userProvider/userProvider';
import I18nKey from '../../lib/types/I18nKey';
import { NavPaths } from '../../NavPaths';
import { EmptyBannerStatus, getEmptyBannerStatus } from './emptyBannerStatus';
import { useDataSyncInfoContext } from '../../containers/dataSyncInfoProvider/dataSyncInfoProvider';
import { EntityType } from '../../modules/account/containers/utils';
import { ReactComponent as LockIcon } from '../../img/icons/emptyStateBanner/lock-icon.svg';
import { ReactComponent as ClockIcon } from '../../img/icons/emptyStateBanner/clock-icon.svg';
import { FlywheelSalesChannel } from '../../lib/types/Fam';

export interface EmptyStateBannerProps {
  userContext: UserContextState;
  onShowEmptyBanner: (value: boolean) => void;
}

export const EmptyStateBanner: React.FC<EmptyStateBannerProps> = ({
  userContext,
  onShowEmptyBanner,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const dataSyncInfo = useDataSyncInfoContext();

  const [error, setError] = useState<boolean>(false);

  const { salesChannelData, isSubscriptionInformationLoaded } =
    useContext(SubscriptionContext);

  const [emptyBannerStatus, setEmptyBannerStatus] =
    useState<EmptyBannerStatus | null>(null);

  const isMerchantSyncLoaded =
    isSubscriptionInformationLoaded &&
    dataSyncInfo.isDataSyncInfoProviderLoaded;

  const [
    BANNER_EMPTY_STATE_NO_DATA_MESSAGE,
    NO_DATA_LINK,
    AMAZON_ADS_ONLY_SYNCING_MESSAGE,
    AMAZON_ADS_ONLY_SYNCING_LINK,
    WALMART_ADS_ONLY_SYNCING_MESSAGE,
    WALMART_ADS_ONLY_SYNCING_LINK,
    AMAZON_ADS_ONLY_CONNECTED_MESSAGE,
    AMAZON_ADS_ONLY_CONNECTED_LINK,
    WALMART_ADS_ONLY_CONNECTED_MESSAGE,
    WALMART_ADS_ONLY_CONNECTED_LINK,
    AMAZON_DATA_SYNCING_MESSAGE,
    GENERIC_SYNCING_MESSAGE,
  ] = [
    I18nKey.BANNER_EMPTY_STATE_NO_DATA_MESSAGE,
    I18nKey.BANNER_EMPTY_STATE_NO_DATA_LINK,
    I18nKey.BANNER_EMPTY_STATE_AMAZON_ADS_ONLY_SYNCING_MESSAGE,
    I18nKey.BANNER_EMPTY_STATE_AMAZON_ADS_ONLY_SYNCING_LINK,
    I18nKey.BANNER_EMPTY_STATE_WALMART_ADS_ONLY_SYNCING_MESSAGE,
    I18nKey.BANNER_EMPTY_STATE_WALMART_ADS_ONLY_SYNCING_LINK,
    I18nKey.BANNER_EMPTY_STATE_AMAZON_ADS_ONLY_CONNECTED_MESSAGE,
    I18nKey.BANNER_EMPTY_STATE_AMAZON_ADS_ONLY_CONNECTED_LINK,
    I18nKey.BANNER_EMPTY_STATE_WALMART_ADS_ONLY_CONNECTED_MESSAGE,
    I18nKey.BANNER_EMPTY_STATE_WALMART_ADS_ONLY_CONNECTED_LINK,
    I18nKey.BANNER_EMPTY_STATE_AMAZON_DATA_SYNCING_MESSAGE,
    I18nKey.BANNER_EMPTY_STATE_GENERIC_SYNCING_MESSAGE,
  ].map((id) => intl.formatMessage({ id }));

  const handleLinkClick = (salesChannel?: FlywheelSalesChannel) => {
    navigate(
      `${NavPaths.SalesChannels}?scsRedirect=${location.pathname}${
        salesChannel ? `&channel=${salesChannel}` : ''
      }`
    );
  };

  const EMPTY_BANNER_PROPS = {
    [EmptyBannerStatus.NoData]: {
      message: BANNER_EMPTY_STATE_NO_DATA_MESSAGE,
      link: NO_DATA_LINK,
      afterLink: '',
      variant: AppMessageBannerVariant.Purple,
      entity: undefined,
      salesChannel: undefined,
    },
    [EmptyBannerStatus.AdsOnlyAmazonSyncing]: {
      message: AMAZON_ADS_ONLY_SYNCING_MESSAGE,
      link: AMAZON_ADS_ONLY_SYNCING_LINK,
      afterLink: '',
      variant: AppMessageBannerVariant.Blue,
      entity: EntityType.Products,
      salesChannel: FlywheelSalesChannel.Amazon,
    },
    [EmptyBannerStatus.AdsOnlyWalmartSyncing]: {
      message: WALMART_ADS_ONLY_SYNCING_MESSAGE,
      link: WALMART_ADS_ONLY_SYNCING_LINK,
      afterLink: '',
      variant: AppMessageBannerVariant.Purple,
      entity: EntityType.Products,
      salesChannel: FlywheelSalesChannel.Walmart,
    },
    [EmptyBannerStatus.AmazonAdsNoProducts]: {
      message: AMAZON_ADS_ONLY_CONNECTED_MESSAGE,
      link: AMAZON_ADS_ONLY_CONNECTED_LINK,
      afterLink: '',
      variant: AppMessageBannerVariant.Purple,
      entity: EntityType.Products,
      salesChannel: FlywheelSalesChannel.Amazon,
    },
    [EmptyBannerStatus.WalmartAdsNoProducts]: {
      message: WALMART_ADS_ONLY_CONNECTED_MESSAGE,
      link: WALMART_ADS_ONLY_CONNECTED_LINK,
      afterLink: '',
      variant: AppMessageBannerVariant.Purple,
      entity: EntityType.Products,
      salesChannel: FlywheelSalesChannel.Walmart,
    },
    [EmptyBannerStatus.AmazonDataSyncing]: {
      message: AMAZON_DATA_SYNCING_MESSAGE,
      link: '',
      afterLink: '',
      variant: AppMessageBannerVariant.Blue,
      entity: undefined,
      salesChannel: undefined,
    },
    [EmptyBannerStatus.GenericDataSyncing]: {
      message: GENERIC_SYNCING_MESSAGE,
      link: '',
      afterLink: '',
      variant: AppMessageBannerVariant.Blue,
      entity: undefined,
      salesChannel: undefined,
    },
  };

  useEffect(() => {
    if (isMerchantSyncLoaded && salesChannelData.length > 0) {
      loadConnectionStatus();
    }
  }, [userContext, salesChannelData, isMerchantSyncLoaded]);

  const loadConnectionStatus = async () => {
    setError(false);
    try {
      const emptyBannerStatus = getEmptyBannerStatus({
        dataSyncInfo,
        salesChannelData,
      });

      onShowEmptyBanner(emptyBannerStatus === EmptyBannerStatus.None);
      setEmptyBannerStatus(emptyBannerStatus);
    } catch (error) {
      setError(true);
    }
  };

  if (error || !emptyBannerStatus || !isMerchantSyncLoaded) {
    return null;
  }

  if (emptyBannerStatus === EmptyBannerStatus.None) {
    return <></>;
  }

  const { message, link, variant, afterLink, salesChannel } =
    EMPTY_BANNER_PROPS[emptyBannerStatus];

  return (
    <div id="empty-state-banner">
      <AppMessageBanner
        dismissable={false}
        variant={variant}
        content={
          <>
            {variant === AppMessageBannerVariant.Blue ? (
              <ClockIcon className="mr-6" />
            ) : (
              <LockIcon className="mr-6" />
            )}
            {message && <span className="font-medium">{message}</span>}
            <a
              className="mx-4 mt font-semibold underline cursor-pointer hover:text-purple-800"
              onClick={() => handleLinkClick(salesChannel)}
            >
              {link}
            </a>
            {afterLink && <span className="font-medium">{afterLink}</span>}
          </>
        }
      />
    </div>
  );
};

EmptyStateBanner.displayName = 'EmptyStateBanner';
